import { action, computed, makeObservable, observable } from 'mobx';
import { BookmarkBrowserState } from '../../components/bookmarks/bookmarkBrowserState';
import { Nullable } from '../../core';
import { BookmarkList } from '../../entities';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { Error, pageError } from '../../core/error';

export type BookmarksPageMode =
  'bookmarkListCatalog' |
  'bookmarkCatalog';

export class BookmarksPageState
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  @observable isLoading = false;
  @observable error: Error | null = null;

  @observable mode: Nullable<BookmarksPageMode> = null;
  @observable targetId: Nullable<string> = null;
  @observable pageSize: number = 20;
  readonly browser = new BookmarkBrowserState(this.store);

  @computed
  get bookmarkList(): Nullable<BookmarkList> {
    const id = this.targetId;
    if (!id || !this.store.hasBookmarkList(id))
      return null;
    return this.store.getBookmarkList(id);
  }

  @computed
  get catalogBookmarkLists(): BookmarkList[] {
    return this.store.bookmarkLists
      .sort((a, b) => b.createdAtTimestamp - a.createdAtTimestamp);
  }

  @action
  async pageMounted(mode: BookmarksPageMode, targetId?: string) {

    this.reset();
    this.isLoading = true;

    this.mode = mode;
    this.targetId = targetId || null;

    switch (mode) {
      case 'bookmarkListCatalog': {
        const [, err1] = await this.store.apiFetchBookmarkLists();
        if (err1)
          return this.setError();

        const bookmarkLists = this.store.bookmarkLists;
        if (bookmarkLists.length === 1) {
          const [, err2] = await this.store.apiFetchBookmarks({ listId: bookmarkLists[0].id });
          if (err2)
            return this.setError();
        }
      } break;

      case 'bookmarkCatalog': {
        if (!targetId)
          return this.setError();

        const [, err1] = await this.store.apiFetchBookmarkList(targetId);
        const [, err2] = await this.store.apiFetchBookmarks({ listId: targetId });

        if (err1 || err2 || !this.bookmarkList)
          return this.setError();

      } break;
    }

    this.setLoaded();
  }

  @action
  async setPageSize(pageSize: number) {
    if (this.pageSize !== pageSize) {
      this.pageSize = pageSize;
      switch (this.mode) {
        case 'bookmarkListCatalog': {
          const bookmarkLists = this.store.bookmarkLists;
          if (bookmarkLists.length === 1) {
            const [, err2] = await this.store.apiFetchBookmarks({
              listId: bookmarkLists[0].id,
              first: pageSize
            });
            if (err2)
              return this.setError();
          }
        } break;
        case 'bookmarkCatalog': {
          if (!this.targetId)
            return this.setError();

          const [, err1] = await this.store.apiFetchBookmarkList(this.targetId);
          const [, err2] = await this.store.apiFetchBookmarks({
            listId: this.targetId,
            first: pageSize
          });

          if (err1 || err2 || !this.bookmarkList)
            return this.setError();

        } break;
      }
    }
  }

  @action
  pageUnmounted() {
    this.reset();
  }


  // #region State helpers
  @action
  reset() {
    this.mode = null;
    this.targetId = null;
    this.isLoading = false;
    this.error = null;
  }

  @action
  private setError(error?: Error) {
    if (!error)
      error = pageError();

    this.isLoading = false;
    this.error = error;
  }

  @action
  private setLoaded() {
    this.isLoading = false;
    this.error = null;
  }
  // #endregion
}