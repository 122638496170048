import { action, computed, makeObservable, observable, ObservableMap, ObservableSet } from 'mobx';
import { Store } from '../../../store/store';
import { StoreNode } from '../../../store/storeNode';
import { LineSeriesProps } from './xySeriesChartSchema';

type DataKey = string;
type Color = string;
type XYSeriesChartStateProps = {
  series?: LineSeriesProps[];
  colors?: string[];
};

export class XYSeriesChartState extends StoreNode {
  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  readonly initialSeries: ObservableMap<DataKey, LineSeriesProps> =
    observable.map<DataKey, LineSeriesProps>();
  readonly initialColors: ObservableMap<DataKey, Color> =
    observable.map<DataKey, Color>();
  readonly selectedKeys: ObservableSet<DataKey> = observable.set<DataKey>();

  @computed get initialSeriesArr(): LineSeriesProps[] {
    return [...this.initialSeries.values()];
  }

  @computed get availableSeriesArr(): LineSeriesProps[] {
    const newSeries = new Map(this.initialSeries);

    this.selectedKeys.forEach((key) => {
      newSeries.delete(key);
    });

    return [...newSeries.values()];
  }

  @computed get availableColorsArr(): Color[] {
    const newColors = new Map(this.initialColors);

    this.selectedKeys.forEach((key) => {
      newColors.delete(key);
    });

    return [...newColors.values()];
  }

  @action
  mounted({ series, colors }: XYSeriesChartStateProps) {
    this.loadSeries(series || []);
    this.loadColors(colors || []);
  }

  @action
  unmounted() {
    this.selectedKeys.clear();
  }

  @action
  loadSeries(series: LineSeriesProps[]) {
    this.initialSeries.replace(series.map((data) => [data.dataKey, data]));
  }

  @action
  loadColors(colors: Color[]) {
    const colorsSet = new Set(colors);
    const colorsIterator = colorsSet.values();

    this.initialSeries.forEach(({ dataKey }) => {
      this.initialColors.set(dataKey, colorsIterator.next().value);
    });
  }

  @action
  handleClickLegendItem = (selectedKey: string) => {
    this.toggleDatum(selectedKey);
  };

  @action
  toggleDatum(selectedKey: string) {
    if (this.selectedKeys.has(selectedKey)) {
      this.selectedKeys.delete(selectedKey);
    } else {
      this.selectedKeys.add(selectedKey);
    }
  }
}
