import React from 'react';

export function defaultRenderTooltip({ tooltipData }: any) {
  return (
    <>
      {tooltipData?.originalText}: <strong>{tooltipData?.value}</strong>
    </>
  );
}

export function getRotationDegree() {
  const rand = Math.random();
  const degree = rand > 0.5 ? 60 : -60;
  return rand * degree;
}