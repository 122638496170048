import { action, computed, makeObservable, observable } from 'mobx';
import { FormEvent, ReactNode } from 'react';
import { isJobPosterCompatible } from '../../core';
import { notifyError } from '../../services/notifications';
import { Store } from '../../store/store';
import { StoreNode } from '../../store/storeNode';
import { InputState, InputStateProps } from './inputState';

const FILE_SIZE_IN_MB = 2;
const PHOTO_MAX_SIZE = FILE_SIZE_IN_MB * 1024 * 1024; // 10Mb 
const FILE_TYPES = ['image/jpeg', 'image/png'];

// WIP - only supports 1 image (jpeg, png) uploads / small layout
export class FileInputState
  extends InputState {

  constructor(store: Store, props: InputStateProps) {
    super(store, props);
    makeObservable(this);
  }

  get error(): ReactNode | null {
    if (this.fileError)
      return this.fileError;

    return this.getResolvedProp('error',
      (this.isRequired && this.file === null && this.previewUrl === null) ? 'Required field' : null);
  }

  @computed get multiple(): boolean {
    return this.resolvedProps.multiple || false;
  }

  @observable fileError: string | null = null;
  @observable isLoading: boolean = false;
  @observable isDragHovered: boolean = false;
  @observable dragItem: DataTransferItem | null = null;
  @observable previewUrl: string | null = null;
  @observable file: File | null = null;

  // TEMP: the general rule at the moment is that the initial value is actualy the preview url
  // if file is valued then it is dirty
  get isDirty(): boolean {
    return !!this.file;
  }

  @computed
  get dragError(): boolean {
    return !!this.dragErrorMessage;
  }

  @computed
  get dragErrorMessage(): string | null {
    if (this.dragItem && !isJobPosterCompatible(this.dragItem)) return 'The accepted formats are JPEG and PNG';

    return null;
  }

  @action
  handleDragEnter = (evt: DragEvent) => {
    this.isDragHovered = true;
    const item = evt.dataTransfer?.items[0];

    if (item) {
      this.dragItem = item;
    }
  }

  @action
  handleDragLeave = (evt: DragEvent) => {
    this.isDragHovered = false;
  }
  @action
  handleDrop = (evt: DragEvent) => {
    this.isDragHovered = false;
    this.dragItem = null;
  }

  @action
  private handleError = (msg: string) => {
    this.isLoading = false;
    // this.fileError = msg;
    //@ts-ignore
    notifyError(this, msg);
  }

  @action
  handleFileClick = (evt: PointerEvent) => {
    // evt.stopPropagation();
    // evt.preventDefault();
  }

  @action
  handleFileChange = (e: FormEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const fileList: FileList | null = target.files;
    if (fileList && fileList.length > 0) {
      const [file] = fileList;

      if (!FILE_TYPES.includes(file.type)) {
        this.handleError('The accepted formats are JPEG and PNG.');
        return;
      }
      if (file.size >= PHOTO_MAX_SIZE) {
        this.handleError(`Photo size should be smaller than ${FILE_SIZE_IN_MB}Mb.`);
        return;
      }

      const reader = new FileReader();
      // eslint-disable-next-line
      const url = reader.readAsDataURL(file);

      reader.onloadend = () => this.loadPreviewUrl(reader.result as string);

      this.file = file;
    }
  }

  clear() {
    this.reset();

    Object.assign(this, {
      isFocused: false,
      isHovered: false
    });

    this.value = null;
    this.file = null;
    this.setProp('initialValue', null);

    this.fileError = null;
    this.isLoading = false;
    this.isDragHovered = false;
    this.dragItem = null;
    this.previewUrl = null;
  }

  export(): any {
    return this.getResolvedProp('export', this.file);
  }

  @action
  loadFile(file: File | null) {
    this.file = file;
    this.setProp('initialFile', file);

    if (!file)
      return;

    const reader = new FileReader();
    // eslint-disable-next-line
    const url = reader.readAsDataURL(file);

    reader.onloadend = () => this.previewUrl = reader.result as string;
  }

  loadPreviewUrl(url: string | null): any {
    this.isLoadingValue = !!url && this.previewUrl !== url;
    this.previewUrl = url || null;
  }

  clearError() {
    this.fileError = null;
  }
}

/** Helper method for creating a new FileInputState object for the provided StoreNode. */
export const fileInput = (node: StoreNode | Store, props: InputStateProps) => {
  let store: Store;
  if (node instanceof StoreNode)
    store = node.store;
  else
    store = node;

  return new FileInputState(store, props);
}