import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { hasKey } from '../../core';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { Team } from '../../entities/team';
import { InputState, inputGroup, InputGroupState, input } from '../input';
import { notifyError, notifySuccess } from '../../services/notifications';
import { TeamMemberCatalogSource } from '../../entities/teamMemberCatalogSource';
import { AddInviteListState } from '../createTeamForm/addInviteListState';
import { RoleItem } from '../createTeamForm/createTeamFormState';
import { TeamMemberCatalogState } from './teamMemberCatalogState';

const roleSelectorItems = [
  { value: 'Admin', label: 'Admin', description: 'Upload, view, edit, manage team users and videos.' },
  { value: 'Trainer', label: 'Trainer', description: 'Upload, view, edit, and manage team videos.' },
  { value: 'Member', label: 'Member', description: 'Upload and edit team videos.' },
  { value: 'Viewer', label: 'Viewer', description: 'View team videos.' },
  { value: 'Remove Access', label: 'Remove Access', description: '', className: 'red' }
];

export const layout = {
  default: ['Members', 'Invite'] as Tab[],
  invitations: ['Invite'] as Tab[],
  members: ['Members'] as Tab[]
}

export type LayoutType =
  'default' |
  'invitations' |
  'members'


export type TeamMembersWindowMode =
  'view' |
  'edit';

type Tab = 'Members' | 'Invite';
export class TeamMembersWindowState
  extends StoreNode {

  readonly inviteListState: AddInviteListState;
  readonly memberCatalogState: TeamMemberCatalogState;
  readonly saveInvitesButton: InputState;
  readonly cancelButton: InputState;
  readonly inputGroup: InputGroupState;

  readonly memberCatalogSource: TeamMemberCatalogSource;

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);

    this.inviteListState = new AddInviteListState(store, {
      roleItems: () => this.inviteRoleItems,
      checkInviteValidity: this.checkInviteValidity
    });
    this.memberCatalogState = new TeamMemberCatalogState(store, {
      syncStatus: () => this.memberCatalogSource.syncStatus,
      isEndOfList: () => this.memberCatalogSource.isEndOfList,
      fetch: () => this.memberCatalogSource.fetch(),
      fetchMore: () => this.memberCatalogSource.fetchMore()
    });

    this.memberCatalogSource = new TeamMemberCatalogSource(this.store, {
      teamId: () => this.teamId,
      pageSize: 20
    });

    this.saveInvitesButton = input(this, {
      name: 'saveInvites',
      disabled: (input: any) => {
        return (
          (
            this.inviteListState.inviteInputGroup.isSubmitRejected &&
            !this.inviteListState.inviteInputGroup.hasChangedSinceLastSubmitInputs) ||
          ((
            input.loading ||
            input.isSubmitting ||
            (this.inviteListState.inviteInputGroup as any).hasTouchedErrorInputs) ||
            (this.inviteListState as any).isEmpty ||
            this.isLocked
          )
        )
      }
    });

    this.cancelButton = input(this, {
      name: 'cancel',
    });

    this.inputGroup = inputGroup(this, {
      name: 'members',
      inputs: () => {
        return [
          this.inviteListState.inviteInputGroup,
          this.memberCatalogState.memberInputGroup
        ]
      },
      disabled: (input: any) => {
        return (
          input.hasLoadingInputs ||
          input.isSubmitting ||
          this.isLocked
        )
      }
    });
  }

  readonly nodeType = 'TeamMembersWindow';
  readonly window = new WindowState(this.store);

  mode: TeamMembersWindowMode | null = null;

  @observable activeTab: Tab = 'Members';
  @observable layoutType: LayoutType = 'default';
  @observable visibleTabs: Tab[] = ['Members', 'Invite'];
  @observable hasSentInvites = false;

  @observable
  isLoading = false;

  @observable
  isLocked = false;

  @observable
  teamId: string | null = null;

  @computed get team(): Team | null {
    return this.store.teamManager.getTeam(this.teamId);
  }

  @computed get inviteRoleItems(): RoleItem[] {
    const inviteRoleSelectorItems = roleSelectorItems.filter(role => role.value !== 'Remove Access')

    return this.store?.user?.hasPermission('AddTeamTrainer') ?
      inviteRoleSelectorItems :
      inviteRoleSelectorItems.filter(role => role.value !== 'Trainer');
  }

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading || this.memberCatalogSource.isLoading || this.memberCatalogSource.isFetchingMore)
          return;
        this.close();
        break;
    }
  }

  @action
  async bulkInvitesButtonClick() {
    const teamId = this.teamId;
    this.close();
    this.store.bulkInvitesWindow.open(teamId, this.layoutType);
  }

  @action
  async submitInvites() {

    if (!this.team)
      return;

    this.isLoading = true;
    const { inviteListState } = this;
    const { inviteInputGroup, isEmpty, inviteList } = inviteListState;

    inviteInputGroup.handleSubmit();
    if (inviteInputGroup.error) {
      inviteInputGroup.handleSubmitReject();
      this.isLoading = false;
      notifyError(this, 'Fix the validation errors before saving.');
      return;
    }
    if (isEmpty) {
      inviteInputGroup.handleSubmitReject();
      this.isLoading = false;
      notifyError(this, 'Please enter at least one invitation.');
      return;
    }
    this.window.lock();

    const hasErrors = await inviteListState.submitInvites(this.team);
    if (hasErrors) {
      this.isLoading = false;
      notifyError(this, 'Error when sending the invitations.');
      inviteInputGroup.handleSubmitReject();

      inviteList.forEach((invite) => {
        if (!invite.error) {
          this.hasSentInvites = true;
          const index = inviteList.indexOf(invite);
          inviteListState.removeInvite(index);
        }
      });
    } else {
      await Promise.all([
        this.team?.apiFetchInvitations(),
        this.memberCatalogSource?.fetch()
      ]);
      // await this.team?.apiFetchInvitations();
      // await this.memberCatalogSource?.fetch();
      this.isLoading = false;
      notifySuccess(this, `The invitations were sent successfully.`);
      inviteInputGroup.handleSubmitResolve();
      inviteListState.lock();
      this.hasSentInvites = true;

      setTimeout(() => {
        inviteListState.reset();
        inviteListState.unlock();
      }, 3000);
    }
    this.window.unlock();
  }

  checkInviteValidity = (email: string) => {
    if (this.team?.members.some(member => member.user?.email === email))
      return 'Member already exists';

    return null;
  }

  @action
  lock() {
    this.isLocked = true;
    this.inviteListState.lock();
    this.memberCatalogState.lock();
  }

  @action
  unlock() {
    this.isLocked = false;
    this.inviteListState.unlock();
    this.memberCatalogState.unlock();
  }

  @action
  async openTeam(teamId: string, layoutType?: LayoutType) {

    this.isLoading = true;
    this.layoutType = layoutType ?? 'default';
    this.open();

    this.teamId = teamId;
    this.memberCatalogState.teamId = teamId;
    this.mode = 'view';
    this.memberCatalogState.mode = 'view';
    await this.team?.apiFetchInvitations();
    // await this.team?.apiFetchMembers();
    await this.memberCatalogSource.fetch();

    runInAction(() =>
      this.isLoading = false);
  }

  @action
  async openTeamEdit(teamId: string, layoutType?: LayoutType) {
    this.isLoading = true;
    this.layoutType = layoutType ?? 'default';
    this.open();

    this.teamId = teamId;
    this.memberCatalogState.teamId = teamId;
    this.mode = 'edit';
    this.memberCatalogState.mode = 'edit';
    await this.team?.apiFetchInvitations();
    // await this.team?.apiFetchMembers();
    await this.memberCatalogSource.fetch();

    runInAction(() =>
      this.isLoading = false);
  }

  @action
  private open() {
    if (hasKey(layout, this.layoutType)) {
      this.visibleTabs = layout[this.layoutType];
    }

    this.activeTab = this.visibleTabs[0];
    this.dispatch('Overlays', 'openWindow', { name: 'TeamMembersWindow' });
  }

  @action
  close() {
    if (this.hasSentInvites) this.emit('teamMembersWindow:invitesSent');
    this.reset();
    this.emit('teamMembersWindow:close');
    this.dispatch('Overlays', 'closeWindow');
  }

  @action
  private reset() {
    this.mode = null;
    this.teamId = null;
    this.inputGroup.reset();
    this.inviteListState.reset();
    this.memberCatalogState.reset();
    this.memberCatalogSource.reset();
    this.hasSentInvites = false;
    this.isLoading = false;
    this.activeTab = 'Members';
  }
}
