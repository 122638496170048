import './playerIndex.scss';
import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { PlayerIndexState } from './playerIndexState';
import { Speaker } from '../../entities/speaker';
import { PlayerSpeakerAvatar } from '../playerShared/playerSpeakerAvatar';

type SpeakerAvatarListProps = {
  model: PlayerIndexState
}

export const SpeakerAvatarList = observer(({
  model,
}: SpeakerAvatarListProps) => {

  const speakerModelList = model.activeSpeakerList;

  return <ul className="speaker-avatar-list">
    {speakerModelList.map(speaker =>
      <SpeakerAvatarItem key={speaker.id}
        model={model}
        speaker={speaker} />
    )}
  </ul>
})

type SpeakerAvatarItemProps = {
  model: PlayerIndexState;
  speaker: Speaker
}

const SpeakerAvatarItem = observer(({
  model,
  speaker
}: SpeakerAvatarItemProps) => {

  const activeSpeaker = model.activeSpeaker;
  const className = classNames("speaker-avatar-item", {
    active: activeSpeaker?.id === speaker.id
  })

  return (<div className={className}
    data-speaker={true}
    data-speaker-id={speaker.id}
    key={speaker.id}>

    <PlayerSpeakerAvatar
      model={speaker}
      layout={activeSpeaker?.id === speaker.id ? 'speaking' : 'idle'}
      onClick={() => model.handleSpeakerClick(speaker.id)} />
  </div>)
})