import './dateInput.scss';

import { DatePicker } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import { useOutsideMouseDown } from '../../hooks';
import { CalendarSVGIcon } from '../../svg';
import { RefObject } from 'react';
import { DateInputState } from './dateInputState';
import { DateInputFooter } from './dateInputFooter';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import { Feedback } from '..';

const { RangePicker } = DatePicker;
export const locale: PickerLocale = {
  "lang": {
    "locale": "en_US",
    "placeholder": "",
    "rangePlaceholder": ["", ""],
    "today": "Today",
    "now": "Now",
    "backToToday": "",
    "ok": "",
    "clear": "",
    "month": "",
    "year": "",
    "timeSelect": "Select time",
    "dateSelect": "Select date",
    "monthSelect": "Choose a month",
    "yearSelect": "Choose a year",
    "decadeSelect": "Choose a decade",
    "yearFormat": "YYYY",
    "dateFormat": "DD/MM/YYYY",
    "dayFormat": "D",
    "dateTimeFormat": "M/D/YYYY HH:mm:ss",
    "monthFormat": "MMMM",
    "monthBeforeYear": true,
    "previousMonth": "Previous month (PageUp)",
    "nextMonth": "Next month (PageDown)",
    "previousYear": "Last year (Control + left)",
    "nextYear": "Next year (Control + right)",
    "previousDecade": "Last decade",
    "nextDecade": "Next decade",
    "previousCentury": "Last century",
    "nextCentury": "Next century"
  },
  "timePickerLocale": {
    "placeholder": "Select time"
  },
  "dateFormat": "YYYY-MMMM-DD", //change month format to MMMM from MM
  "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
  "weekFormat": "YYYY-wo",
  "monthFormat": "YYYY-MM"
};

export type RangeOptions = {
  name: string;
  getRange: () => [moment.Moment, moment.Moment] | null
}

export type Info = {
  range: string;
}

export type DateInputProps = {
  id?: string;
  name?: string;
  format?: string | string[];
  placeholder?: [string, string] | string;
  disabled?: boolean;
  bordered?: boolean;
  shouldDisableDates?: boolean;
  open?: boolean;
  mode?: [any, any] | any;
  inputReadOnly?: boolean;
  handleOpenChange?: any;
  validationMessage?: string;
  allowEmpty?: [boolean, boolean];
  activeRangeOption?: RangeOptions;
  rangeOptions?: RangeOptions[];
  ref?: RefObject<HTMLElement>;
  max?: moment.Moment;
  min?: moment.Moment;
  value?: [moment.Moment, moment.Moment] | moment.Moment;
  defaultPickerValue?: [moment.Moment, moment.Moment] | moment.Moment;
  rangeDatePicker?: boolean;
  isRequired?: boolean;
  error?: string | null;
  setActiveRange?: (activeRange: RangeOptions) => void;
  onBackdropClick?: () => void;
  onClick?: () => void;
  onApply?: () => void;
  cancel?: () => void;
  apply?: () => void;
  handleChange?: (dates: any, dateStrings: [string, string] | string) => void;
  onCalendarChange?: (dates: any, dateStrings: [string, string], info: Info) => void;
  onPanelChange?: (value: any, mode: any) => void;
  disabledDate?: (currentDate: moment.Moment) => boolean;
}

export const RangePickerWrapper = observer(({
  id,
  name,
  placeholder,
  value,
  open,
  bordered,
  handleOpenChange,
  format,
  inputReadOnly,
  allowEmpty,
  defaultPickerValue,
  validationMessage,
  rangeOptions,
  disabled,
  activeRangeOption,
  mode,
  onPanelChange,
  onBackdropClick,
  setActiveRange,
  onCalendarChange,
  handleChange,
  cancel,
  apply,
  onApply,
  disabledDate }: DateInputProps) => {
  const [wrapperRef, setWrapperRef] = useState<HTMLElement>();
  const [inputRef, setInputRef] = useState<HTMLElement>();
  const rangeRef = useRef(null);

  useOutsideMouseDown([wrapperRef as HTMLElement, inputRef as HTMLElement],
    () => {
      if (onBackdropClick) {
        onBackdropClick()
      }
    });

  useEffect(() => {
    if (open) {
      setInputRef(document.getElementsByClassName('ant-picker-range')[0] as HTMLElement);
      setWrapperRef(document.getElementsByClassName('ant-picker-dropdown')[0] as HTMLElement);
    } else {
      (rangeRef.current as any)?.blur()
      setWrapperRef(undefined);
      setInputRef(undefined);
    }
  }, [open]);

  const applyChanges = (dates: any, dateStrings: [string, string]) => {
    if (handleChange) handleChange(dates, dateStrings);
    if (dates === null && onApply) { onApply() }
  }

  return (
    <RangePicker
      id={id}
      ref={rangeRef}
      name={name}
      format={format}
      bordered={bordered}
      onChange={applyChanges}
      placeholder={placeholder as [string, string]}
      mode={mode}
      value={value as [moment.Moment, moment.Moment]}
      disabled={disabled}
      inputReadOnly={inputReadOnly}
      open={open}
      locale={locale}
      allowEmpty={allowEmpty}
      onOpenChange={handleOpenChange}
      onPanelChange={onPanelChange}
      disabledDate={disabledDate}
      onCalendarChange={onCalendarChange}
      defaultPickerValue={defaultPickerValue as [moment.Moment, moment.Moment]}
      suffixIcon={<CalendarSVGIcon />}
      renderExtraFooter={() => (
        <DateInputFooter
          isRangePicker
          value={value as [moment.Moment, moment.Moment]}
          activeRangeOption={activeRangeOption}
          options={rangeOptions}
          validationMessage={validationMessage}
          setActiveRange={setActiveRange}
          handleChange={handleChange}
          onApply={onApply}
          cancel={cancel}
          apply={apply} />
      )}
    />
  );
})

export const DatePickerWrapper = observer(({
  id,
  name,
  placeholder,
  value,
  open,
  bordered,
  handleOpenChange,
  format,
  inputReadOnly,
  defaultPickerValue,
  validationMessage,
  disabled,
  mode,
  error,
  onPanelChange,
  onBackdropClick,
  handleChange,
  cancel,
  apply,
  onApply,
  disabledDate }: DateInputProps) => {
  const [wrapperRef, setWrapperRef] = useState<HTMLElement>();
  const [inputRef, setInputRef] = useState<HTMLElement>();
  const rangeRef = useRef(null);

  useOutsideMouseDown([wrapperRef as HTMLElement, inputRef as HTMLElement],
    () => {
      if (onBackdropClick) {
        onBackdropClick()
      }
    });

  useEffect(() => {
    if (open) {
      setInputRef(document.getElementsByClassName('ant-picker-panel-container')[0] as HTMLElement);
      setWrapperRef(document.getElementsByClassName('ant-picker-dropdown')[0] as HTMLElement);
    } else {
      (rangeRef.current as any)?.blur()
      setWrapperRef(undefined);
      setInputRef(undefined);
    }
  }, [open]);

  const applyChanges = (date: any, dateStrings: string) => {
    if (handleChange) handleChange(date, dateStrings);
    if (date === null && onApply) { onApply() }
  }

  return (
    <>
      <DatePicker
        className={`${error ? 'dateinput-error' : ''}`}
        id={id}
        ref={rangeRef}
        name={name}
        format={format}
        bordered={bordered}
        onChange={applyChanges}
        placeholder={placeholder as string}
        mode={mode}
        value={value as moment.Moment ?? defaultPickerValue}
        disabled={disabled}
        inputReadOnly={inputReadOnly}
        open={open}
        locale={locale}
        onOpenChange={handleOpenChange}
        onPanelChange={onPanelChange}
        disabledDate={disabledDate}
        suffixIcon={<CalendarSVGIcon />}
        renderExtraFooter={() => (
          <DateInputFooter
            value={value as moment.Moment ?? defaultPickerValue}
            validationMessage={validationMessage}
            isRangePicker={false}
            handleChange={handleChange}
            onApply={onApply}
            cancel={cancel}
            apply={apply} />
        )} />

      {error &&
        <Feedback
          className="date-input-feedback"
          status='error'
          message={error} />}
    </>

  );
})

type ControlledProps = DateInputProps & {
  model: DateInputState;
  onApply?: () => void;
};

export const DateInput = observer(
  ({ model, onApply }: ControlledProps) => {

    if (model.isRangeDatepicker)
      return (
        <RangePickerWrapper
          activeRangeOption={model.activeRangeOption || undefined}
          defaultPickerValue={model.defaultPickerValue || undefined}
          id={model.id}
          value={model.value || undefined}
          open={model.open}
          mode={model.mode || undefined}
          bordered={model.bordered}
          format={model.format}
          disabled={model.disabled}
          inputReadOnly={model.inputReadOnly}
          allowEmpty={model.allowEmpty}
          error={model.error}
          rangeOptions={model.rangeOptions}
          validationMessage={model.validationMessage || undefined}
          onPanelChange={model.onPanelChange}
          onBackdropClick={model.onBackdropClick}
          onCalendarChange={model.onCalendarChange}
          setActiveRange={model.setActiveRange}
          handleChange={model.handleChange}
          handleOpenChange={model.handleOpenChange}
          disabledDate={model.disabledDate}
          cancel={model.cancel}
          apply={model.apply}
          onApply={onApply}
          placeholder={model.placeholder || undefined}
        ></RangePickerWrapper>
      )

    return (
      <DatePickerWrapper
        defaultPickerValue={model.defaultPickerValue as moment.Moment}
        id={model.id}
        value={model.value || undefined}
        open={model.open}
        mode={model.mode || undefined}
        bordered={model.bordered}
        format={model.format}
        disabled={model.disabled}
        inputReadOnly={model.inputReadOnly}
        validationMessage={model.validationMessage || undefined}
        onPanelChange={model.onPanelChange}
        onBackdropClick={model.onBackdropClick}
        onCalendarChange={model.onCalendarChange}
        setActiveRange={model.setActiveRange}
        handleChange={model.handleChange}
        handleOpenChange={model.handleOpenChange}
        disabledDate={model.disabledDate}
        error={model.error}
        cancel={model.cancel}
        apply={model.apply}
        onApply={onApply}
        placeholder={model.placeholder as [string, string] || undefined} />)
  })