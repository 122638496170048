import './input.scss';
import React, { CSSProperties } from 'react';
import { observer } from 'mobx-react-lite';
import clamp from 'lodash/clamp';
import noop from 'lodash/noop';
import classNames from 'classnames';
import { HTMLPropsBase } from '../../core';

type Props = Omit<HTMLPropsBase<HTMLDivElement>, 'onChange'> & {
  className?: string,
  min?: number,
  max?: number,
  step?: number,
  orient?: 'horizontal' | 'vertical',
  value?: number,
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
  tabIndex?: number
}

export const SliderCore = observer(({
  className,
  min = 0,
  max = 1,
  step = 0.01,
  orient = 'horizontal',
  value = (max - min) / 2,
  onChange = noop,
  tabIndex,
  ...props
}: Props) => {

  value = clamp(value, min, max);

  className = classNames('slider-core', className, orient);
  const style = {
    '--value': clamp((value - min) / (max - min), 0, 1)
  } as CSSProperties;

  return (
    <div className={className}
      style={style}
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      {...props}>

      <div className="slider-bar">
        <div className="slider-track" />
        <div className="slider-fill" />
        <div className="slider-thumb" />
      </div>

      <input type="range"
        value={value}
        min={min}
        max={max}
        step={step}
        role="presentation"
        // @ts-ignore
        orient={orient}
        onChange={onChange}
        tabIndex={tabIndex ?? 0} />
    </div>
  );
});