import '../authPages.scss';

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import {
  BasicPage,
  ButtonControlled,
  CliprLogoSVGIcon,
  Feedback,
  Link,
  LoadMask,
  TextInputControlled
} from '../../../components';

import Routes from '../../../routes';
import { LoginPageState } from './loginPageState';
import { SocialLoginBox } from '../social';

type Props = {
  model: LoginPageState
} & Partial<RouteComponentProps>;

export const LoginPage = observer(({
  model
}: Props) => {

  useEffect(() => {
    model.attached();
    return () => {
      model.detached();
    }
  }, [model]);

  const { form } = model;

  return (
    <BasicPage
      // showLoadMask={form.showLoadMask}
      className={`login-page auth-page dock ${model.layout}`}
      showHeader={false}
      showWatermark={false}>

      <div className="auth-splash">
        {model.layout === 'poster' && <div className="login-poster auth-poster">
          <div className="clipr-logo">
            <CliprLogoSVGIcon />
          </div>
          <div className="headline">
            <h1>Welcome to CLIPr!</h1>
            <h2>Review just the key moments of video meetings.</h2>
          </div>
        </div>}
        <div className="auth-container">
          <div className="auth-panel">
            <header className="login-header auth-header">
              {model.layout !== 'poster' && <div className="clipr-logo">
                <CliprLogoSVGIcon />
              </div>}
              <h2>Welcome back!</h2>
              <p className="action-message">Sign in to your CLIPr dashboard</p>
            </header>

            <form id="loginForm"
              className="login-form auth-form"
              onSubmit={form.handleSubmit}>
              <Feedback id="loginForm_error"
                model={form.formGroup}
                data-error-code={form.error?.type} />
              <TextInputControlled
                id="loginForm_email"
                label="Email Address"
                model={form.username}
                autoComplete="username" />

              <div className="row">
                <TextInputControlled
                  id="loginForm_password"
                  type="password"
                  label="Password"
                  model={form.password}
                  autoComplete="current-password">
                  {/* <div className="left-inner-bottom-inner forgot-password">
                    <Link to={Routes.forgotPassword()}>Forgot password</Link>
                  </div> */}
                </TextInputControlled>
              </div>
              <div className="forgot-password">
                <Link to={Routes.forgotPassword()}>Forgot password</Link>
              </div>

              <div className="row vpad1 btn-group fill form-footer">
                <ButtonControlled
                  id="loginForm_submit"
                  model={form.submitButton}
                  className="submit-btn primary light"
                  type="submit"
                  label="Sign in" />
              </div>
            </form>

            <div className="text-separator">or sign in with</div>

            <div className="login-social auth-social">
              <SocialLoginBox
                onProviderClick={form.handleSocialLoginButtonClick} />
            </div>
            <p className="login-signup">
              <span>Don't have an account?</span>
              <Link to={Routes.register()}>Sign up</Link>
            </p>

            <LoadMask
              className="partial"
              visible={form.showLoadMask}
              spinner="dots5" />
          </div>
        </div>
      </div>
    </BasicPage>
  );
});

export default LoginPage;