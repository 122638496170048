import { action, computed, makeObservable, observable } from 'mobx';
import { input, inputGroup, InputGroupState, InputState } from '../input';
import { assert, Nullable } from '../../core';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';

export type BookmarkListWindowMode =
  'create' |
  'edit';

export class BookmarkListWindowState
  extends StoreNode {

  readonly nodeType = 'BookmarkListWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.nameInput = input(this, {
      name: 'name',
      isRequired: true,
      error: (e) => {
        if (e.isEmpty) return 'Required field';

        const bookmarkLists = this.store.bookmarkManager.bookmarkLists.map(bkm => bkm.name);
        if (bookmarkLists.includes(e.value!)) {
          return 'You have already created a list with this name.';
        }
      }
    });
    this.saveButton = input(this, {
      name: 'saveButton',
      disabled: (input: any) => {
        return (
          input.loading ||
          input.isSubmitting ||
          (this.form as any).hasTouchedErrorInputs);
      }
    });

    this.form = inputGroup(this, {
      name: 'moment',
      inputs: [
        this.nameInput,
        this.saveButton
      ],
      isSubmitDisabled: () => {
        return false;
      }
    });

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);

  readonly nameInput: InputState;
  readonly saveButton: InputState;
  readonly form: InputGroupState;

  @observable mode: Nullable<BookmarkListWindowMode> = null;
  @observable targetId: Nullable<string> = null;
  @observable isLoading: boolean = false;

  @computed
  get target() {

    const { store, targetId } = this;
    if (!targetId || !store.hasBookmarkList(targetId)) {
      assert(this.mode !== 'edit',
        `Expected to have a valid BookmarkListWindow.targetId for mode 'edit.'`);
      return null;
    }

    return this.store.getBookmarkList(targetId);
  }

  @action
  cancel() {
    this.emit('cancel');
    this.close();
  }

  @action
  async submit() {
    this.form.handleSubmit();
    this.window.lock();

    if (this.form.error) {
      this.form.handleSubmitReject();
      this.window.unlock();
      return;
    }

    const name = this.nameInput.value!;
    this.isLoading = true;

    switch (this.mode) {
      case 'create': {
        const [bkmList, err] = await this.store.apiCreateBookmarkList({ name });

        if (err) {
          this.emit('createError');
          this.handleSubmitReject(`Could not create bookmark list.`);
          return;
        }

        this.emit('createSuccess', { bookmarkList: bkmList });
        this.handleSubmitSuccess(`Added bookmark list ${name}.`);
      } break;

      case 'edit': {
        const [, err] = await this.store.apiUpdateBookmarkList({
          id: this.targetId!,
          name
        });
        if (err) {
          this.emit('updateError');
          this.handleSubmitReject(`Could not edit bookmark list.`);
          return;
        }

        this.emit('updateSuccess');
        this.handleSubmitSuccess(`Changed name for bookmark list ${name}.`);
      } break;
    }
  }

  private handleSubmitReject(msg: string) {
    this.isLoading = false;
    this.form.handleSubmitReject();
    this.window.unlock();

    notifyError(this, msg);
  }

  private handleSubmitSuccess(msg: string) {
    this.isLoading = false;
    this.form.handleSubmitResolve();
    this.window.unlock();

    this.close();

    notifySuccess(this, msg);
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  private open() {
    this.dispatch('Overlays', 'openWindow', { name: 'BookmarkListWindow' });
  }

  @action
  openCreate() {
    this.mode = 'create';
    this.targetId = null;

    this.open();
  }

  @action
  openEdit(targetId: string) {
    this.mode = 'edit';
    this.targetId = targetId;

    this.nameInput.value = this.target?.name || null;

    this.open();
  }

  @action
  private close() {
    this.mode = null;
    this.targetId = null;

    this.nameInput.reset();
    this.form.reset();

    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}