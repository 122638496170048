import React from 'react';
import { observer } from 'mobx-react-lite';
import { OAuthLogoutPageState } from './oauthLogoutPageState';
import { LoadPage } from "../../components";
import { usePageModel } from '../pageHooks';
import { AuthFlowResponseInterpreter } from '../../components/auth';

export const OAuthLogoutPage = observer(() => {

  const model = usePageModel((kernel) => new OAuthLogoutPageState(kernel.store));

  if (model.isLoading) {
    return (
      <LoadPage />
    );
  }

  return (
    <AuthFlowResponseInterpreter response={model.flowResponse} />
  );
});

export default OAuthLogoutPage;