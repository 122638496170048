import { action, computed, makeObservable, observable } from 'mobx';
import { JobModel } from '../../../entities/job';
import { BindingProps, StoreNode } from '../../../store';
import { Store } from '../../../store/store';
import { PlayerState } from '../playerState';
import { Comment } from '../../../entities/comment';

type Props = BindingProps<{
  player: PlayerState,
  jobId: string
}>

export class MarkerBarState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  @observable hoveredCommentId: string | null = null;

  @computed get player(): PlayerState {
    return this.resolvedProps.player;
  }
  @computed get jobId(): string | null {
    return this.resolvedProps.jobId;
  }
  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @action
  setHovered(commentId: string) {
    this.hoveredCommentId = commentId;
  }

  @action
  removeHover() {
    this.hoveredCommentId = null;
  }

  getPosition(model: Comment): number | null {

    const { playerLiveAdapter, adapter } = this.player;
    const time = model.videoTime;

    if (!time)
      return null;

    if (this.job?.isLiveStreaming)
      return playerLiveAdapter.getTimeRatioFromAbsoluteTime(time);

    if (model.isLive) { // for live source comments/reactions the absolute time is computed based on PTS
      const jobDur = this.job?.videoDuration ?? null;

      if (!Number.isFinite(time) || !jobDur)
        return null;

      if (this.job?.source.liveCliprHLS)
        return (time - adapter.initPtsInSec) / jobDur;
      else
        return time / jobDur;
    }

    return model.videoTimeRatio;
  }
}