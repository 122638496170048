import { ChartPageParams } from '../chartSchema';
import { LineChartDataProps, LineSeriesProps } from '../xySeriesChart';

export enum TeamPerformanceChartMode {
  Uploads = 'Uploads',
  Edits = 'Edits',
  Bookmarks = 'Bookmarks',
  Reactions = 'Reactions'
}

export type TeamPerformanceChartExternalParams = ChartPageParams;

export type TeamPerformanceChartInternalParams = {
  mode?: TeamPerformanceChartMode | null
}

export type TeamPerformanceChartParams =
  TeamPerformanceChartInternalParams &
  TeamPerformanceChartExternalParams;

export type TeamPerformanceChartDatum = LineChartDataProps;
export type TeamPerformanceChartSeriesData = LineSeriesProps;
export type TeamPerformanceChartData = TeamPerformanceChartSeriesData[];
