import React, { useEffect } from 'react';
import {
  Route,
  Redirect,
  useLocation,
  RouteProps,
  match,
} from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from '../store/storeHooks';
import { BasicPage } from '../components/layout';
import { Routes } from './routes';

type Props = RouteProps & {
  keepInHistory?: boolean;
  computedMatch?: match;
};

const ONBOARDING_REDIRECT_PATH_KEY = 'routing.redirectAfterOnboardingPath';

export const AuthorizedRoute = observer((props: Props) => {
  
  const store = useStore();
  const location = useLocation();
  const route = location.pathname + location.search;
  const params = new URLSearchParams(location.search);

  const { auth, storage, widget } = store;

  useEffect(() => {
    const { storage } = store;
    if (params) {
      // Query param used for redirecting to either the signin or
      // signup pages if not authenticated
      const isExistingUser = params.get('existingUser');
      if (isExistingUser === 'false') {
        storage.setLocal('user.isNotRegistered', 'true');
      }
    }
    // store.notifyAuthorizedRouteMounted(props);
    // return () => store.notifyAuthorizedRouteUnmounted(props);
  }, [store, route, params, props]);

  if (widget.isWidgetMode) {
    return <BasicPage
      showLoadMask={true}
      showHeader={false} />;
  }

  if (!auth.isAuthorized) {
    return <BasicPage
      showLoadMask={true}
      showHeader={false} />;
  }

  if (!auth.isOnboarded && Routes.onboard() !== props.path) {
    storage.setLocal(ONBOARDING_REDIRECT_PATH_KEY, route);
    return <Redirect to={Routes.onboard()} />;
  }

  const redirectPath = storage.getLocal(ONBOARDING_REDIRECT_PATH_KEY);

  if (redirectPath && props.path !== Routes.onboard()) {
    if (route === redirectPath) {
      storage.removeLocal(ONBOARDING_REDIRECT_PATH_KEY);
    } else {
      return <Redirect to={redirectPath} />;
    }
  }

  if (props.path && props.location && props.keepInHistory) {
    storage.setLocal(
      'router.lastVisitedLibrary',
      { ...props.computedMatch },
      true
    );
  }

  return <Route {...props} />;
});
