import './dropdownInput-v1-1.scss';
import React, { CSSProperties, ReactEventHandler, ReactNode, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { usePopper } from 'react-popper';

import { CheckmarkSVGIcon, InfoSVGIcon } from '../svg';

import classNames from 'classnames';
import { Maybe, ObjectLiteral, searchMatches } from '../../core';
import { useOutsideClick } from '../hooks';
import { SearchTextInput } from './searchTextInput';
import { ModifierPhases } from '@popperjs/core/lib/enums';
import { ToggleInputControlled, ToggleLayout } from './toggleInput';
import { InputState } from './inputState';
import { Tooltip } from '../overlays';
import { Button } from '.';

import { FixedSizeList as List } from "react-window";

const MENU_MAX_HEIGHT = 420;
const MENU_MIN_HEIGHT = 140;
const PADDING_OFFSET = 5;

export type ItemProps =
  | {
    value: string;
    label: string;
    description?: string;
    className?: string;
    tooltip?: ReactNode;
  }
  | string;

type MenuItemProps = {
  isSelected?: boolean;
  model: ItemProps;
  onClick?: ReactEventHandler;
  className?: string;
  withToggle?: boolean;
  toggleModel?: InputState;
  disabled?: boolean;
  tooltip?: ReactNode;
  toggleOptions?: {
    toggleLayout?: ToggleLayout;
    toggledLabel?: string;
    notToggledLabel?: string;
  };
  style?: CSSProperties;
};

type Props = {
  referenceElement: any;
  className?: string | ObjectLiteral;
  value?: string | null;
  label?: Maybe<string>;
  items: ItemProps[];
  onChange?: any;
  onOutsideClick?: any;
  withSearch?: boolean;
  virtualized?: boolean;
};

export const sameWidth = {
  name: "sameWidth",
  enabled: true,
  phase: "beforeWrite" as ModifierPhases,
  requires: ["computeStyles"],
  fn: ({ state }: any) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }: any) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
  }
};

const calcHeight = {
  name: "calcHeight",
  enabled: true,
  phase: "beforeWrite" as ModifierPhases,
  requires: ["computeStyles"],
  fn: ({ state }: any) => {
    let height;
    let bottom = window.innerHeight - state.elements.reference.getBoundingClientRect().bottom - PADDING_OFFSET;
    let top = state.elements.reference.getBoundingClientRect().top - PADDING_OFFSET;;
    height = bottom > MENU_MAX_HEIGHT ? MENU_MAX_HEIGHT : bottom;

    if (height < MENU_MIN_HEIGHT && top > MENU_MIN_HEIGHT)
      height = top > MENU_MAX_HEIGHT ? MENU_MAX_HEIGHT : top;
    else if (height < MENU_MIN_HEIGHT && top < MENU_MIN_HEIGHT)
      height = window.innerHeight > MENU_MAX_HEIGHT ? MENU_MAX_HEIGHT : window.innerHeight;

    state.styles.popper.maxHeight = `${height}px`;
  },
  effect: ({ state }: any) => {
    let height;
    const bottom = window.innerHeight - state.elements.reference.getBoundingClientRect().bottom - PADDING_OFFSET;;
    const top = state.elements.reference.getBoundingClientRect().top - PADDING_OFFSET;;
    height = bottom > MENU_MAX_HEIGHT ? MENU_MAX_HEIGHT : bottom;

    if (height < MENU_MIN_HEIGHT && top > MENU_MIN_HEIGHT)
      height = top > MENU_MAX_HEIGHT ? MENU_MAX_HEIGHT : top;
    else if (height < MENU_MIN_HEIGHT && top < MENU_MIN_HEIGHT)
      height = window.innerHeight > MENU_MAX_HEIGHT ? MENU_MAX_HEIGHT : window.innerHeight;

    state.elements.popper.style.maxHeight = `${height}px`;
  }
};

export const MenuItems = observer(
  ({ referenceElement, value, className, items = [], withSearch = false, virtualized = false, ...props }: Props) => {
    className = classNames('dropdown-menu menu-popup popup',
      {
        'with-search': withSearch,
        'has-tooltip': !!items.find(i => typeof i !== 'string' && !!i.tooltip)
      },
      className);

    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement: 'bottom-end',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 6],
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top', 'left']
          },
        },
        sameWidth,
        calcHeight
      ],
    });

    const [searchValue, setSearchValue] = useState('');
    const [itemList, setItemList] = useState(items);

    useOutsideClick(popperElement, () => props.onOutsideClick());

    const computeVirtualizedParams = () => {

      let itemCount = itemList.length;
      let itemHeight = !!itemList.find(item => typeof item === 'object' && !!item.description) ? 98 : 48;
      let totalHeight = itemCount * itemHeight;

      let width = parseFloat(styles.popper.width?.toString() ?? '0');
      width = width < 260 ? 260 : width;
      width = width - 9;

      let height = parseFloat(styles.popper.maxHeight?.toString() ?? '0');
      height = height - (withSearch ? 53 : 0);

      height = totalHeight > height ? height : totalHeight;

      return {
        width,
        height,
        itemCount,
        itemHeight
      }
    }

    const Row = ({ index, key, style }: { index: number, key?: number, style?: CSSProperties }) => {
      const el = itemList[index];
      const elValue = typeof el === 'object' ? el.value : el;
      const tooltip = typeof el !== 'string' && el?.tooltip;

      return (
        <MenuItem
          isSelected={value === elValue}
          model={el}
          key={elValue}
          tooltip={tooltip}
          style={style}
          onClick={(evt) => {
            props.onChange(evt, elValue);
          }} />)
    }

    const handleSearchInputChange = (evt: any) => {
      setSearchValue(evt.target.value);
      const filteredItems = items.map(item => {
        const itemValue = typeof item === 'object' ? item.label : item;
        return searchMatches(itemValue, evt.target.value) ? item : null;
      }).filter(item => item) as ItemProps[];
      setItemList(filteredItems);
    }

    const handleSearchInputClear = () => {
      setSearchValue('');
      setItemList(items);
    }

    let vParams = computeVirtualizedParams();
    const { width, height, itemCount, itemHeight } = vParams;

    return (
      <div
        className={className}
        ref={setPopperElement as any}
        style={styles.popper}
        {...attributes.popper}>

        <div className="popup-arrow" style={styles.arrow} />

        {withSearch &&
          <SearchTextInput
            value={searchValue}
            placeholder={"Search items"}
            className={"search-box"}
            showClear={false}
            onClear={handleSearchInputClear}
            onChange={handleSearchInputChange} />}

        {!virtualized && <div className="scrollable">
          <div className="menu-items">

            {itemList.length === 0 &&
              <div className="empty-list">
                {withSearch && !!searchValue ? 'No results found' : 'No items to display'}
              </div>}

            {(itemList || []).map((el: ItemProps) => {
              const elValue = typeof el === 'object' ? el.value : el;
              const tooltip = typeof el !== 'string' && el.tooltip;
              return (
                <MenuItem
                  isSelected={value === elValue}
                  model={el}
                  key={elValue}
                  tooltip={tooltip}
                  onClick={(evt) => {
                    props.onChange(evt, elValue);
                  }}
                />
              );
            })}

          </div>
        </div>}

        {virtualized &&
          <List
            className="menu-items scrollable"
            width={width}
            height={height}
            itemCount={itemCount}
            itemSize={itemHeight}>
            {Row}
          </List>}

      </div>
    );
  }
);

export const MenuItem = observer(
  ({
    isSelected,
    model,
    withToggle,
    toggleModel,
    toggleOptions,
    disabled,
    tooltip,
    onClick,
    style
  }: MenuItemProps) => {
    if (typeof model === 'object')
      var { value, label, description, className } = model;
    else {
      value = model;
      label = model;
    }

    if (!model) return null;

    const cx = classNames('menu-item', className || '');

    return (
      <div
        className={cx}
        key={value}
        onClick={onClick}
        style={style}
        aria-disabled={disabled}>
        <div
          className={
            'label' + (isSelected && !withToggle ? ' current-value' : '')
          }>
          <span className="label-text">
            {label}
            {tooltip && (
              <Tooltip content={tooltip} className="popup-tip">
                <Button className="info-button" icon={<InfoSVGIcon />} />
              </Tooltip>
            )}
          </span>
          {!withToggle && isSelected && (
            <span className="icon">
              <CheckmarkSVGIcon />
            </span>
          )}
          {withToggle && toggleModel && (
            <ToggleInputControlled
              model={toggleModel}
              className="medium"
              name="menu-item-toggle"
              {...toggleOptions}
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
              }}
            />
          )}
        </div>
        {description && <span className="description">{description}</span>}
      </div>
    );
  }
);
