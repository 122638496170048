import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { AuthPermit, createAuthPermit } from './authPermit';
import { AuthenticatedAuthContext } from './authenticatedAuthContext';
import { UserProfile } from '../../entities';
import { AsyncResult, Result } from '../../core';
import { AuthFlowResponse, AuthFlowResponseType, Permissions } from './authFlowSchema';
import { runFetchProfileStep } from './steps/fetchProfileStep';
import { Error } from '../../core/error';
import { runAwaitExternalPermitStep } from './steps/awaitExternalPermitStep';
import { AuthContext, LoginInput, RegisterInput, ResetPasswordInput, SocialLoginProvider } from './authSchema';
import { AuthReply, RegisterReply, ResetPasswordReply } from './authReply';
import { RouteContext } from '../../routes/routeContext';

import { INIT_DEBUGGER, TRACE } from '../../core/debug/debugMacros';
import { AnonymousAuthContext } from './anonymousAuthContext';
import { LibraryPermit } from '../libraries/libraryPermit';
import { Auth0Adapter } from './auth0Adapter';
import { LibraryName } from '../libraries/librarySchema';

export class AuthStepOrchestrator
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    INIT_DEBUGGER(this);
  }

  readonly auth0 = new Auth0Adapter(this.store);

  @observable allowActions: boolean | null = null;
  @observable permit: AuthPermit | null = null;
  @observable profile: UserProfile | null = null;
  @observable context: AuthContext | null = null;
  @observable permissions: Permissions | null = null;

  @observable libraryPermit: LibraryPermit | null = null;

  @observable isClosed = false;

  @observable error: Error | null = null;
  @observable.shallow response: AuthFlowResponse | null = null;

  @computed get isProxyClientConnectionOpened() {
    return this.store.proxyService.isClientConnectionOpened;
  }

  @computed get isProxyServerConnectionOpened() {
    return this.store.proxyService.isServerConnectionOpened;
  }

  private get proxyService() {
    return this.store.proxyService;
  }

  get state() {
    return this.store.authService.localState;
  }

  // #region Proxy Client Methods
  async proxyAuthorize(): AsyncResult<AuthPermit> {
    const [res, err] = await this.proxyService.runAuthorizeServerFlow();
    if (err)
      return [null, err];

    const permit = res!.data.permit;
    if (!permit || !permit.isValid)
      return [null, new Error('AuthError', `Expected a valid permit from the proxy flow.`)];

    TRACE(this, `Received a permit from the proxy server flow`, permit);

    return this.setPermit(permit!);
  }

  async proxyAuthorizeLibrary(libraryName: LibraryName): AsyncResult<LibraryPermit> {
    const [res, err] = await this.proxyService.runAuthorizeLibraryServerFlow(libraryName);
    if (err)
      return [null, err];

    const libraryPermit = res!.data.libraryPermit;
    if (!libraryPermit)
      return [null, new Error('AuthError', `Expected a valid library permit from the proxy flow.`)];

    TRACE(this, `Received a library permit from the proxy server flow`, libraryPermit);

    this.setLibraryPermit(libraryPermit);
    return [libraryPermit];
  }

  async proxyDeauthorize(): AsyncResult<true> {
    const [, err] = await this.proxyService.runDeauthorizeServerFlow();
    if (err)
      return [null, err];

    TRACE(this, `Deauthorized using the proxy server flow`);

    return [true];
  }

  abortServerFlow(): Result<true> {
    this.proxyService.abortServerFlow();
    return [true];
  }
  // #endregion

  // #region Proxy Server Methods
  async sendProxyAuthorizeResponse(): AsyncResult<any> {

    const { permit } = this;
    if (!permit || !permit.isValid)
      return [null, new Error('InternalError', `Can only send a valid AuthPermit to proxy client.`)];

    return await this.proxyService.sendAuthorizeResponse(permit);
  }

  async sendProxyAuthorizeLibraryResult(): AsyncResult<any> {

    const { libraryPermit } = this;
    if (!libraryPermit)
      return [null, new Error('InternalError', `Can only send a valid LibraryPermit to proxy client.`)];

    return await this.proxyService.sendAuthorizeLibraryResponse(libraryPermit);
  }

  async sendProxyDeauthorizeResult(): AsyncResult<any> {

    const { context } = this.state;
    if (context)
      return [null, new Error('InternalError', `Can only send a a deauthorized response after the context has been cleared.`)];

    return await this.proxyService.sendDeauthorizeResponse();
  }
  // #endregion

  // #region Permit Methods
  async awaitExternalPermit(): AsyncResult<AuthPermit> {

    const [permit, err] = await runAwaitExternalPermitStep({
      orchestrator: this
    });

    if (err)
      return this.handlePermitError(err);

    return this.setPermit(permit!);
  }

  async refreshPermit(): AsyncResult<AuthPermit> {

    const [permit, err] = await this.auth0.checkSession();
    if (err)
      return this.handlePermitError(err);

    return this.setPermit(permit!);
  }

  async createPermitFromHash(): AsyncResult<AuthPermit> {

    const [permit, err] = await this.auth0.parseHash();
    if (err)
      return this.handlePermitError(err);

    return this.setPermit(permit!);
  }

  async createPermitFromIdToken(idToken: string): AsyncResult<AuthPermit> {

    const [permit, err] = createAuthPermit({
      idToken
    });
    if (err)
      return this.handlePermitError(err);

    return this.setPermit(permit!);
  }

  @action
  setPermit(permit: AuthPermit): Result<AuthPermit> {
    this.permit = permit;
    return [permit];
  }

  @action
  private handlePermitError(err: Error): Result<AuthPermit> {
    this.permit = null;
    return [null, err];
  }
  // #endregion


  // #region Profile methods
  async fetchProfile(): AsyncResult<UserProfile> {

    const { permit } = this;
    if (!permit)
      return [null, new Error('InternalError', `FetchProfileStep requires a permit.`)];

    const [profile, err] = await runFetchProfileStep({
      orchestrator: this,
      permit
    });

    if (err)
      return this.handleProfileError(err);

    return this.setProfile(profile!);
  }

  @action
  setProfile(profile: UserProfile): Result<UserProfile> {
    this.profile = profile;
    return [profile];
  }

  @action
  private handleProfileError(err: Error): Result<UserProfile> {
    this.profile = null;
    return [null, err];
  }
  // #endregion

  // #region Permissions Methods
  async fetchPermissions(routeContext: RouteContext): AsyncResult<Permissions> {

    const permsPromise = routeContext.injector?.fetchPermissions?.(routeContext);
    if (!permsPromise) {
      // todo: check
      // if the permissions are not defined on the injector, 
      // it is assumed that the resource does not require a permissions check
      return [{
        canView: true,
        isPublic: false
      }];
    }

    const [perms, err] = await permsPromise;
    if (err)
      return this.handlePermissionsError(err);

    return this.setPermissions(perms!);
  }

  @action
  setPermissions(perms: Permissions): Result<Permissions> {
    this.permissions = perms;
    return [perms];
  }

  @action
  private handlePermissionsError(err: Error): Result<Permissions> {
    this.permissions = null;
    return [null, err];
  }
  // #endregion

  // #region Context Methods
  /** 
   * Shortcut for fetching the profile and then creating the context. 
   */
  async initContextFromPermit(): AsyncResult<AuthenticatedAuthContext> {

    const { permit } = this;
    if (!permit)
      return [null, new Error('InternalError', `InitContextFromPermitStep requires a valid permit.`)];

    const [, profileErr] = await this.fetchProfile();
    if (profileErr)
      return [null, profileErr];

    const [context, contextErr] = this.createAuthenticatedContext();
    if (contextErr)
      return [null, contextErr];

    return [context!];
  }

  async initContextFromPermitAndCommit() {
    return this.withCommit(() =>
      this.initContextFromPermit());
  }

  async initContextFromHash(): AsyncResult<AuthenticatedAuthContext> {

    const [, permitErr] = await this.createPermitFromHash();
    if (permitErr)
      return [null, permitErr];

    const [context, contextErr] = await this.initContextFromPermit();
    if (contextErr)
      return [null, contextErr];

    return [context!];
  }

  async initContextFromHashAndCommit() {
    return this.withCommit(() =>
      this.initContextFromHash());
  }

  async initContextFromSession(): AsyncResult<AuthenticatedAuthContext> {

    const [, permitErr] = await this.refreshPermit();
    if (permitErr)
      return [null, permitErr];

    const [, profileErr] = await this.fetchProfile();
    if (profileErr)
      return [null, profileErr];

    const [context, contextErr] = this.createAuthenticatedContext();
    if (contextErr)
      return [null, contextErr];

    return [context!];
  }

  async initContextFromSessionAndCommit(): AsyncResult<AuthenticatedAuthContext> {
    return this.withCommit(() =>
      this.initContextFromSession());
  }

  createAuthenticatedContext(): Result<AuthenticatedAuthContext> {

    const { permit, profile } = this;
    if (!permit || !profile || !permit.isValid)
      return [null, new Error('InternalError', `CreateAuthenticatedContextStep requires a valid permit and profile.`)];

    const context = new AuthenticatedAuthContext({
      permit,
      profile: profile,
      allowActions: this.allowActions
    });

    this.setContext(context);

    return [context!];
  }

  createAnonymousContext(): Result<AnonymousAuthContext> {

    const context = new AnonymousAuthContext({
      allowActions: this.allowActions
    });

    this.setContext(context);

    return [context!];
  }

  initAnonymousContext() {
    return this.createAnonymousContext();
  }

  initAnonymousContextAndCommit() {
    return this.withCommitSync(() =>
      this.createAnonymousContext());
  }
  // #endregion

  // #region Auth0 Flow Methods
  async login(input: LoginInput): AsyncResult<AuthReply<'WaitForRedirect'>> {

    const [, err] = await this.auth0.login(input);
    if (err)
      return [null, err];

    return [new AuthReply('WaitForRedirect')];
  }

  async logout(): AsyncResult<AuthReply<'WaitForRedirect'>> {

    const [, err] = await this.auth0.logout();
    if (err)
      return [null, err];

    return [new AuthReply('WaitForRedirect')];
  }

  async register(input: RegisterInput): AsyncResult<RegisterReply> {

    const [res, err] = await this.auth0.signUp(input);
    if (err)
      return [null, err];
    
    // do not count on this object to be populated since auth0 are a bunch of lazy morons
    const reply = new RegisterReply({
      userId: res?.Id,
      email: res?.email,
      emailVerified: res?.emailVerified
    });

    return [reply]
  }

  async socialLogin(providerName: SocialLoginProvider): AsyncResult<AuthReply<'WaitForRedirect'>> {

    const [, err] = this.auth0.socialLogin(providerName);
    if (err)
      return [null, err];

    return [new AuthReply('WaitForRedirect')];
  }

  async resetPassword(input: ResetPasswordInput): AsyncResult<ResetPasswordReply> {

    const [reply, err] = await this.auth0.changePassword(input);
    if (err)
      return [null, err];

    return [reply!];
  }
  // #endregion

  @action
  commit(): Result<boolean> {
    const { state, context } = this;

    if (!context?.isValid)
      return [null, new Error('InternalError', `Committing requires a valid context.`)];

    const [, err] = state.setContext(context);
    if (err)
      return [null, err];

    // double check
    if (!state.context?.isValid)
      return [null, new Error('InternalError', `The context was not properly set.`)];

    return [true];
  }

  private async withCommit<T extends AuthContext>(callback: () => AsyncResult<T>): AsyncResult<T> {
    const [context, contextErr] = await callback();
    if (contextErr)
      return [null, contextErr];

    const [, commitErr] = this.commit();
    if (commitErr)
      return [null, commitErr];

    return [context!];
  }

  private withCommitSync<T extends AuthContext>(callback: () => Result<T>): Result<T> {
    const [context, contextErr] = callback();
    if (contextErr)
      return [null, contextErr];

    const [, commitErr] = this.commit();
    if (commitErr)
      return [null, commitErr];

    return [context!];
  }

  @action
  invalidate(): Result<boolean> {
    const { state } = this;
    state.clearContext();

    this.permit = null;
    this.profile = null;
    this.context = null;
    this.allowActions = null;
    this.libraryPermit = null;

    return [true];
  }

  @action
  setContext(context: AuthContext): Result<AuthContext> {
    this.permit = context.permit;
    this.profile = context.profile;
    this.context = context;

    return [context];
  }

  // #endregion

  @action
  setAllowActions(allowActions: boolean) {
    this.allowActions = allowActions;
  }

  @action
  setLibraryPermit(libraryPermit: LibraryPermit): Result<LibraryPermit> {
    this.libraryPermit = libraryPermit;
    return [libraryPermit];
  }

  @action
  createLibraryPermitFromRoute(routeContext: RouteContext, libraryName: LibraryName): Result<LibraryPermit> {

    const error = routeContext.searchParams.get('error');
    const errorDesc = routeContext.searchParams.get('error_description');

    if (error) {
      return [null, new Error('AuthError', `Library provider returned an error of type '${error}'.`, {
        innerError: new Error('AuthError', errorDesc ?? error) // to include the description
      })];
    }

    const code = routeContext.searchParams.get('code');
    if (!libraryName)
      return [null, new Error('InternalError', `Invalid library.`)];
    if (!code)
      return [null, new Error('AuthError', `Invalid authorization code.`)];

    const libraryPermit = new LibraryPermit({
      code: code,
      libraryName: libraryName
    });

    this.setLibraryPermit(libraryPermit);
    return [libraryPermit];
  }

  /**
   * Loads the current AuthPermit from the AuthService into the orchestrator.
   * If no permit exists, an Error is returned.
   */
  @action
  loadContext(): Result<AuthContext> {
    const { context } = this.state;
    if (!context)
      return [null, new Error('InternalError', `There is no AuthContext set on the current auth state.`)];

    this.setContext(context);
    return [context];
  }

  private close() {
    if (this.isProxyServerConnectionOpened)
      this.abortServerFlow();
  }

  private setResponse(type: AuthFlowResponseType, props?: Partial<AuthFlowResponse>): Result<AuthFlowResponse> {
    this.close();
    this.response = {
      responseType: type,
      ...props
    }
    return [this.response];
  }

  @action
  setError(err: Error): Result<AuthFlowResponse> {
    this.close();
    this.error = err;
    return [null, err];
  }

  @action
  setRedirectToLoginPage(props?: Partial<Pick<AuthFlowResponse, 'error'>>): Result<AuthFlowResponse> {
    return this.setResponse(AuthFlowResponseType.RedirectToLoginPage, props);
  }

  @action
  setRedirectToOnboardPage(): Result<AuthFlowResponse> {
    return this.setResponse(AuthFlowResponseType.RedirectToOnboardPage);
  }

  @action
  setRedirectToLastPrivateRoute(props?: Partial<Pick<AuthFlowResponse, 'error'>>): Result<AuthFlowResponse> {
    return this.setResponse(AuthFlowResponseType.RedirectToLastPrivateRoute, props);
  }


  @action
  setRedirectToLastWidgetRoute(props?: Partial<AuthFlowResponse>): Result<AuthFlowResponse> {
    return this.setResponse(AuthFlowResponseType.RedirectToLastWidgetRoute, props);
  }

  @action
  setRedirectToSessionWidgetRoute(props?: Partial<AuthFlowResponse>): Result<AuthFlowResponse> {
    return this.setResponse(AuthFlowResponseType.RedirectToSessionWidgetRoute, props);
  }

  @action
  setRedirectToProxyLoginWidget(props?: Partial<Pick<AuthFlowResponse, 'error'>>): Result<AuthFlowResponse> {
    const { context } = this.state;
    if (context)
      return [null, new Error('InternalError', `Cannot redirect to proxy login widget because the context has not been cleared.`)];
    return this.setResponse(AuthFlowResponseType.RedirectToProxyLoginWidget, props);
  }

  @action
  setAuthorized(): Result<AuthFlowResponse> {
    const { context } = this.state;
    if (!context?.isAuthenticated)
      return [null, new Error('InternalError', `Invalid response set, no AuthenticatedAuthcontext has been set.`)];
    return this.setResponse(AuthFlowResponseType.Authorized);
  }

  @action
  setAuthorizedAsAnonymous(): Result<AuthFlowResponse> {
    const context = this.store.authService.context;
    if (!context || !context.isAnonymous)
      return [null, new Error('InternalError', `Invalid response set, no AnonymousAuthContext has been set.`)];
    return this.setResponse(AuthFlowResponseType.AuthorizedAsAnonymous);
  }

  @action
  setLibraryAuthorized(): Result<AuthFlowResponse> {
    return this.setResponse(AuthFlowResponseType.LibraryAuthorized);
  }

  @action
  setDeauthorized(): Result<AuthFlowResponse> {
    const context = this.store.authService.context;
    if (!context || !context.isAnonymous)
      return [null, new Error('InternalError', `Invalid response set, the context has not been invalidated.`)];
    return this.setResponse(AuthFlowResponseType.AuthorizedAsAnonymous);
  }

  @action
  setPassThroughAuthRoute(props?: Partial<AuthFlowResponse>): Result<AuthFlowResponse> {
    return this.setResponse(AuthFlowResponseType.PassThroughAuthRoute, props);
  }

  @action
  setResetPasswordEmailSent(props?: Partial<AuthFlowResponse>): Result<AuthFlowResponse> {
    return this.setResponse(AuthFlowResponseType.ResetPasswordEmailSent, props);
  }

  @action
  setAwaitRedirect(): Result<AuthFlowResponse> {
    return this.setResponse(AuthFlowResponseType.AwaitRedirect);
  }

  @action
  setAwaitProxyClose(): Result<AuthFlowResponse> {
    return this.setResponse(AuthFlowResponseType.AwaitProxyClose);
  }
}