import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { ChevronDownSVGIcon } from '../../../components/player/components/playerSvg';

type ExpandToggleProps = {
  isExpanded: boolean;
  className?: string;
  onExpand?: (evt: any) => void;
}

export const ExpandToggle = observer(({
  isExpanded,
  className,
  onExpand
}: ExpandToggleProps) => {

  className = classNames("expand-toggle", className, {
    expanded: isExpanded
  })

  return <button className={className}
    type={"button"}
    onClick={(evt) => onExpand && onExpand(evt)}>
    <ChevronDownSVGIcon />
  </button>
});