import { RouteContext } from '../routes/routeContext';

export interface IWidgetState {
  attached(routeContext: RouteContext): void | Promise<void>;
  detached(): void;
}

export enum WidgetName {
  Team = 'Team',
  Player = 'Player',
  Analytics = 'Analytics',
  TeamAnalytics = 'TeamAnalytics',
  Upload = 'Upload',
  AdvancedUpload = 'AdvancedUpload',
  LiveStreamQueue = 'LiveStreamQueue',
  TeamLiveStreamQueue = 'TeamLiveStreamQueue',
  TeamSettings = 'TeamSettings',
  SearchResults = 'SearchResults',
  ChatGptSearchResults = 'ChatGptSearchResults'
}