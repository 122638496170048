import '../menu/menu.scss';
import './buttons-v1-1.scss';

import React, { PropsWithChildren, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { usePopper } from 'react-popper';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// @ts-ignore
import CSSVariables from '../menu/menu.scss';

import { ObjectLiteral } from '../../core';
import { ChevronDownSVGIcon } from '../svg/svg';
import { ItemProps } from './menuItems';
import { useOutsideClick } from '../hooks';

export interface IFilterableSource {
  getFilterFieldValue(field: string): any;

  setFilterFieldValue(field: string, val?: any): void;
}

type Props = PropsWithChildren<{
  ref?: any;
  className?: string | ObjectLiteral;
  label?: string;
  selected?: boolean;
  items?: ItemProps[];
  value?: string;
  onChange?: any;
  disabled?: boolean;
}>;

const fadeTimeout = {
  enter: parseInt(CSSVariables.windowEnterTimeout),
  exit: parseInt(CSSVariables.windowExitTimeout),
};

export const FilterFieldButton = observer((props: Props) => {
  const [isOpened, setIsOpened] = useState(false);

  const className = classNames(
    'sort-field-btn btn-core btn sort filter',
    props.className,
    {
      'selected': props.selected || false,
      'open active': isOpened,
    }
  );
  const [popperElement, setPopperElement] = useState(null);
  const [anchorElement, setAnchorElement] = useState(null);
  const [arrowElement] = useState(null);

  const { styles, attributes } = usePopper(anchorElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },

      {
        name: 'offset',
        options: {
          offset: [0, 6],
        },
      },

      {
        name: 'flip',
        options: {
          fallbackPlacements: ['left', 'top', 'bottom'],
        },
      },
    ],
  });

  useOutsideClick(popperElement, () => setIsOpened(false));

  return (
    <div className="flex-center">
      <button
        disabled={props.disabled}
        className={className}
        ref={setAnchorElement as any}
        onClick={(evt) => {
          setIsOpened(!isOpened);
          evt.stopPropagation();
        }}>
        <span className="text">{props.label}</span>

        <span className="icon">
          <ChevronDownSVGIcon />
        </span>
      </button>

      <TransitionGroup>
        {isOpened && (
          <CSSTransition classNames="menu-fade" timeout={fadeTimeout}>
            <div
              className="dropdown-menu menu-popup popup"
              ref={setPopperElement as any}
              style={styles.popper}
              {...attributes.popper}>
              <div className="popup-arrow" style={styles.arrow} />
              <div className="menu-items" onClick={(evt) => setIsOpened(false)}>
                {props.children}
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
});
