import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { SliderCore } from '../../input/sliderCore';
import { Overlay } from '../../overlays/overlay';
import { useOverlayState } from '../../overlays/overlayState';
import { PlayerVolumeSVGIcon } from '../../svg';
import { PlayerState } from '../playerState';

type Props = {
  model: PlayerState
}

export const VolumeButton = observer(({
  model
}: Props) => {

  const btnModel = model.volumeButton;
  const panelState = useOverlayState({
    visibility: 'OnHover'
  });

  const [isHovered, setHovered] = useState(false);

  const className = classNames('user-player-control-btn volume-btn', {
    'dragging': btnModel.isDragging,
    'keying': btnModel.isKeying,
    hover: isHovered
  });

  return (
    <button className={className}
      tabIndex={0}
      ref={panelState.anchorRef.ref}
      {...panelState.anchorProps}
      onPointerEnter={() => {
        setHovered(true);
      }}
      onPointerLeave={() => {
        setHovered(false);
      }}>

      <div className="icon"
        onClick={btnModel.handleButtonClick}>
        <PlayerVolumeSVGIcon
          volume={btnModel.volume} />
      </div>

      <Overlay model={panelState}
        className="control-panel volume-btn-panel">
        <SliderCore
          className="volume-btn-slider"
          orient="vertical"
          onChange={btnModel.handleSliderChange}
          onPointerDown={btnModel.handleSliderPointerDown}
          onPointerUp={btnModel.handleSliderPointerUp}
          onKeyDown={btnModel.handleKeyDown}
          onKeyUp={btnModel.handleKeyUp}
          value={btnModel.volume} />
      </Overlay>
    </button>
  );
});