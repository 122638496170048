import './teams.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Catalog } from '../layout';
import { ICatalogSource } from '../layout/catalog';
import { CatalogFooter } from '../layout/catalogFooter';
import { DropdownInputControlled } from '../input';
import { TeamMember } from '../../entities/teamMember';
import { Nullable } from '../../core';
import {
  TeamMemberCatalogState,
  TeamMemberItemState,
} from './teamMemberCatalogState';
import { TeamMembersWindowMode } from './teamMembersWindowState';
import { UserAvatar } from '../layout/userAvatar';

type Props = {
  model: TeamMemberCatalogState;
  source: ICatalogSource<TeamMember>;
};

export const TeamMemberCatalog = observer(({ model, source }: Props) => {
  const { memberCatalog, team } = model;
  const { entities } = source;

  model.reset();

  return (
    <Catalog className="team-members-catalog catalog" model={memberCatalog} hasExternalLoading>
      {entities.length > 0 && (
        <>
          <ul
            className={
              'team-member-catalog-list' +
              (model.memberInputGroup.loading ? ' loading' : '')
            }>
            {entities.map((member) => {
              if (!member)
                return null;
              const type = member?.userId ? 'member' : 'invitation';
              const memberState = model.addMemberState(member, type);
              return (
                <TeamMemberCatalogItem
                  key={member.id}
                  model={memberState}
                  mode={model.mode}
                />
              );
            })}
            {model.memberCatalog?.isEndOfList &&
              team?.invitations.map((member) => {
                if (!member)
                  return null;
                const type = 'invitation';
                const memberState = model.addMemberState(member, type);
                return (
                  <TeamMemberCatalogItem
                    key={member.id}
                    model={memberState}
                    mode={model.mode}
                  />
                );
              })}
          </ul>

          <CatalogFooter model={memberCatalog} />
        </>
      )}

      {entities.length === 0 && (
        <div className="empty-box" aria-hidden={memberCatalog.isLoading}>
          <div className="empty-message message">No members</div>
        </div>
      )}
    </Catalog>
  );
});

type TeamMemberCatalogItem = {
  model: TeamMemberItemState;
  mode?: Nullable<TeamMembersWindowMode>;
};

// eslint-disable-next-line  no-redeclare
const TeamMemberCatalogItem = observer((props: TeamMemberCatalogItem) => {
  const { model } = props;
  const { target } = model;
  if (!target) return null;

  const user = model.store.userManager.getUser(model.target?.id) || {
    pictureURL: null,
  };

  return (
    <li key={target?.id} className={`team-member-item row ${model.type}`}>
      <div className="member-info left">
        <UserAvatar model={user} className="light-blue" />
        <div className="member-info-content">
          <div className="member-name">{model.targetName}</div>
          {model.isInvitation && (
            <div className="member-status">Pending Invitation Accept</div>
          )}
        </div>
      </div>
      {props.mode === 'edit' ? (
        <DropdownInputControlled
          model={model.roleInput}
          showFeedback={false}
          persistentFeedback={false}
          notDeselectable
          className="form-input role-input"
        />
      ) : (
          <div className="member-role right">{target?.role}</div>
        )}
    </li>
  );
});
