import './momentCloud.scss';

import React, { useEffect, useLayoutEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { MomentCloudState } from './momentCloudState';
import { CloudNode } from './cloudNode';
import { cssPixel } from '../../core';

type Props = {
  model: CloudNode<'Speaker'>,
  parentModel: MomentCloudState
}

export const SpeakerNodeElement = observer(({
  model
}: Props) => {

  const className = classNames('speaker-node cloud-node', {
    'accent': model.isAccented,
    'disable-transitions': model.disableTransitions
  });

  const style = {
    '--idle-width': cssPixel(model.idleSizeInfo.width),
    '--idle-height': cssPixel(model.idleSizeInfo.height),
    '--accented-width': cssPixel(model.accentedSizeInfo.width),
    '--accented-height': cssPixel(model.accentedSizeInfo.height),
    '--expanded-width': cssPixel(model.expandedSizeInfo.width),
    '--expanded-height': cssPixel(model.expandedSizeInfo.height),
    '--size-factor': model.growFactor
  } as any;

  useEffect(() => {
    model.mounted();
    return () => model.unmounted();
  }, [model]);

  useLayoutEffect(() => {
    model.afterRender();
  });

  return (
    <div ref={model.refProxy.ref}
      className={className}
      style={style}
      aria-expanded={model.isExpanded}
      aria-selected={model.isSelected}
      aria-hidden={!model.isVisible}
      onClick={() => model.toggleSelect()}
      data-model-id={model.model.id}>

      <div className="speaker-picture"
        style={{ backgroundImage: `url(${model.model.picture})` }} />

      <div className="speaker-name">
        {model.model.displayName}
      </div>
    </div>
  );
});