import { SpeakerPrediction as SpeakerPredictionApi } from '@clipr/lib';
import { Store } from '../../store/store';
import { Speaker, SpeakerModel } from '../speaker';

/**
 * Immutable object representing information about a speaker prediction
 */
export class SpeakerPrediction {

  readonly speakerId!: string;
  readonly speaker!: Speaker | null
  readonly probability!: number | null

  constructor(props: SpeakerPredictionApi, store: Store) {
    Object.assign(this, props);

    if (props.speaker) {
      this.speaker = new SpeakerModel(props.speaker, store);
    }
    Object.freeze(this);
  }

  get matchPercentage(): number {
    return this.probability ?? 0;
  }

  get matchPercentageDisplay(): string | null {
    return (Math.round(this.matchPercentage * 100)).toString() + '%';
  }

  get hasMatch(): boolean {
    return !!this.speakerId;
  }

  get isConfirmed(): boolean {
    return false; // at the moment we assume every speaker prediction represents an unconfirmed speaker
  }

}