import { isObservableArray } from 'mobx';
import { MaybeObservableArray } from './array';
import { Maybe } from './types';

/**
 * Ensures an Array object given the provided argument.
 * If the argument is already an Array object, it is simply returned.
 * If not, a new Array will be constructed based on the Iterable data.
 * Also acts as a filter for undefined | null values and returns an empty array when that's the case.
 * This method is useful when the argument might already be an array, and will prevent a useless recreation of it.
 */
export function toArray<T>(arg?: Maybe<Iterable<T>>): T[] {

  if (!arg)
    return [];

  if (Array.isArray(arg))
    return arg;
  return [...arg];
}

/**
 * Ensures an ArrayLike object given the provided argument.
 * If the argument is already an ArrayLike object, it is simply returned.
 * If not, a new Array will be constructed based on the Iterable data.
 * Also acts as a filter for undefined | null values and returns an empty array when that's the case.
 */
export function toArrayLike<T>(arg?: Maybe<Iterable<T>>): MaybeObservableArray<T> {

  if (!arg)
    return [];

  if (Array.isArray(arg))
    return arg;
  if (isObservableArray(arg))
    return arg;

  return [...arg];
}


export function isIterable<T = any>(arg?: any): arg is Iterable<T> {
  if (!arg)
    return false;
  return !!arg[Symbol.iterator];
}

/**
 * Returns true if there's at least one element present in both Sets. */
export function iterablesIntersect<T>(a?: Maybe<Iterable<T>>, b?: Maybe<Iterable<T>>) {
  if (!a || !b)
    return false;

  for (const ak of a) {
    for (const bk of b) {
      if (ak === bk)
        return true;
    }
  }

  return false;
}

export type IterableDiffResult<T = any> = {
  added: Set<T>,
  removed: Set<T>,
  changed: Set<T>,
  stable: Set<T>
}

export function iterableDiff<T = any>(
  o?: Maybe<Iterable<T>>,
  n?: Maybe<Iterable<T>>): IterableDiffResult<T> {

  // implementation from https://stackoverflow.com/questions/3476672/algorithm-to-get-changes-between-two-arrays

  let oa = toArray(o);
  let na = toArray(n);

  oa.sort();
  na.sort();

  const all = new Set<T>([...oa, ...na]);

  // don't compare if either list is empty
  if (oa.length === 0 || na.length === 0) return {
    added: new Set(n),
    removed: new Set(o),
    changed: new Set(),
    stable: all
  };

  // declare temporary variables
  let op = 0;
  let np = 0;

  let added = [];
  let removed = [];

  // compare arrays and add to add or remove lists
  while (op < oa.length && np < na.length) {
    if (oa[op] < na[np]) {
      removed.push(oa[op]);
      op++;
    }
    else if (oa[op] > na[np]) {
      added.push(na[np]);
      np++;
    }
    else {
      op++;
      np++;
    }
  }

  // add remaining items
  if (np < na.length)
    added = added.concat(na.slice(np, na.length));

  if (op < oa.length)
    removed = removed.concat(oa.slice(op, oa.length));


  const stable = new Set<T>(all);
  for (let a of added)
    stable.delete(a);
  for (let r of removed)
    stable.delete(r);

  return {
    added: new Set(added),
    removed: new Set(removed),
    // TODO: implement
    changed: new Set(),
    stable
  };
}