import './streamAssetsWindow.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip, Window, WindowHeader } from '../overlays';
import { Button } from '../input';
import { TextInput, ToggleInputControlled } from '../input';
import { StreamAssetsWindowState } from './streamAssetsWindowState'
import { VisibilityInfoTooltip } from '../infoTooltip/visibilityInfoTooltip';
import { InfoSVGIcon } from '..';

type Props = {
  model: StreamAssetsWindowState;
  visible?: boolean;
};

export const StreamAssetsWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      className='stream-assets-winow'
      model={model.window}
      visible={props.visible}
      isLoading={model.loading}>
      <WindowHeader model={model.window} title="Stream Assets" />

      <hr />

      <section className="window-content">
        <p className="label">Embed Code</p>
        <p className="description">
          Add these codes to web pages where you want the CLIPr&apos;d videos to appear
        </p>

        <div className="embed-code-section">
          <TextInput
            label="This Shareable Stream Only"
            className="form-input embed-code"
            value={model.jobIframeEmbedCode}
            showFeedback={false}
            rows={8}
            multiline
            disabled />
          <div className="visibility-container">
            <div className="toggle-visibility">
              <Button
                disabled={!model.job?.isPublic}
                className="secondary btn-outline small"
                label="Copy Code"
                onClick={() => model.copyJobIframeCode()} />

              {!model.job?.isPublic &&
                <Tooltip
                  content={<VisibilityInfoTooltip showPublicInfoSection={false} />}
                  className="popup-tip"
                  trigger="click">
                  <Button className="info-button" icon={<InfoSVGIcon />} />
                </Tooltip>}
            </div>

            <ToggleInputControlled
              className="medium"
              model={model.publicStream} />
          </div>
        </div>

        <div className="embed-code-section">
          <TextInput
            label="All Videos from this library"
            className="form-input embed-code"
            value={model.iframeEmbedCode}
            showFeedback={false}
            rows={5}
            multiline
            disabled />
          <Button
            className="secondary btn-outline small"
            label="Copy Code"
            onClick={() => model.copyPageIframeCode()} />
        </div>

      </section>
    </Window>
  );
});
