import { action, makeObservable, observable, computed, runInAction } from 'mobx';
import { Message, StoreNode } from '../../store';
import { Nullable, Maybe } from '../../core';
import { Store } from '../../store/store';
import { WindowState } from '../../components/overlays';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Team } from '../../entities/team';
import { TeamMember } from '../../entities/teamMember';
import { Routes } from '../../routes';

export class LeaveTeamWindowState
  extends StoreNode {

  readonly nodeType = 'LeaveTeamWindow';

  constructor(store: Store, props?: any) {
    super(store, props);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  @observable isLoading: boolean = false;

  @observable teamId: Nullable<string> = null;
  @observable team: Nullable<Team> = null;
  @observable user: Maybe<TeamMember> = null;

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close();
        break;
    }
  }
  readonly window = new WindowState(this.store);

  @action
  init() {
    this.team = this.store.teamManager.getTeam(this.teamId);
    this.user = this.team?.currentUserMember;
  }

  @action
  async leaveTeam() {
    if (!this.teamId) return;
    this.isLoading = true;
    const [, err] = await this.store.apiService.leaveTeam({ teamId: this.teamId });

    runInAction(() => {
      if (err) {
        notifyError(this, 'Could not leave the library.');
        this.isLoading = false;
        return;
      }
      notifySuccess(this, `Library left`);
      this.store.teamManager.removeTeam(this.teamId!);
      this.isLoading = false;
      this.close();
      this.store.goTo(Routes.emptyRoute());
    })
  }

  @action
  open(params: { teamId: string }) {
    this.dispatch('Overlays', 'openWindow', { name: 'LeaveTeamWindow' });
    this.teamId = params.teamId;
    this.init();
  }

  @action
  close() {
    this.dispatch('Overlays', 'closeWindow');
    this.teamId = null;
    this.user = null;
    this.team = null;
  }

  @computed
  get isOwner() {
    return !!(this.user && this.user.role === 'Owner');
  }
}