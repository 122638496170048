import React from 'react';
import './playerTutorial.scss';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { Button } from '../input';
import { PlayerTutorialWindowState } from './playerTutorialWindowState';
import { ChevronRight2SVGIcon, ChevronLeftSVGIcon } from '../svg';
import { useState } from 'react';
import { usePopper } from 'react-popper';
import { ReactNode } from 'react';
import { ReactionsTutorialComponent } from './reactionsTutorialComponent';
import { Nullable } from '../../core';
import { ToggleBarTutorialComponent } from './toogleBarTutorialComponent';
import { useEffect } from 'react';

type Props = {
  model: PlayerTutorialWindowState;
  visible?: boolean;
};
export const PlayerTutorialWindow = observer((props: Props) => {
  const { model } = props;
  let content: Nullable<ReactNode>;

  const [popperElement, setPopperElement] = useState(null);
  const anchorElement = model.anchorElement;
  const { styles, attributes, forceUpdate } = usePopper(anchorElement, popperElement, {
    placement: model.placement,
    strategy: 'absolute',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: model.offset || [0, 0]
        }
      }
    ]
  });

  useEffect(() => {
    if (forceUpdate) forceUpdate();
  }, [anchorElement, forceUpdate])

  const showTranscripts = model.isWidgetMode
    ? model.store.playerWidget.frameset.isSectionAvailable('Transcripts')
    : model.store.userPlayerPage.frameset.isSectionAvailable('Transcripts');
  const showIndex = model.isWidgetMode
    ? model.store.playerWidget.frameset.isSectionAvailable('Index')
    : model.store.userPlayerPage.frameset.isSectionAvailable('Index');
  const showComments = model.isWidgetMode
    ? model.store.playerWidget.frameset.isSectionAvailable('Comments')
    : model.store.userPlayerPage.frameset.isSectionAvailable('Comments');

  switch (model.content) {
    case 'playerReactionsComponent':
      content = <ReactionsTutorialComponent />
      break;
    case 'toggleBarComponent':
      content = <ToggleBarTutorialComponent
        showTranscripts={showTranscripts}
        showIndex={showIndex}
        showComments={showComments} />
      break;
    default:
      content = null;
  }

  return (
    <Window
      closeOnOutsideClick={false}
      innerRef={setPopperElement as any}
      className={`${model.isWidgetMode ? 'widget-mode' : ''} tutorial-window`}
      model={model.window}
      visible={props.visible}
      style={styles.popper} {...attributes.popper}>

      <WindowHeader
        model={model.window}
        title={model.description} />

      {content &&
        <section className={`${model.isWidgetMode ? 'widget-mode' : ''} window-content`}>
          {content}
        </section>}

      <hr></hr>

      <footer className={`${model.isWidgetMode ? 'widget-mode' : ''} window-footer`}>
        <div className="btn-group right">

          {model.currentPage > 1 &&
            (<Button
              className="btn-icon tutorial-btn medium"
              onClick={() => model.prevPage()}
              leftIcon={<ChevronLeftSVGIcon />}>
            </Button>)}

          <span className="progress-indicator">{model.currentPage} / {model.totalPages}</span>

          {model.currentPage < model.totalPages &&
            (<Button
              className="btn-icon tutorial-btn medium"
              rightIcon={<ChevronRight2SVGIcon />}
              onClick={() => model.nextPage()}
            />)}

          {model.currentPage === model.totalPages &&
            (<Button
              className="primary medium"
              label="Done"
              onClick={() => model.showHelpTooltip()}
            />)}
        </div>
      </footer>
    </Window>
  )
});