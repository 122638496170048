import React from 'react';
import { observer } from 'mobx-react-lite';
import { PlayerState } from '../player/playerState';
import { Maybe } from '../../core/types';
import { MomentModel } from '../../entities/moment';
import { useStore } from '../../store/storeHooks';
import { Button } from '../input/button';

type EditMomentButtonPermissionMode =
  'always' |
  'currentUser';

type Props = {
  player: PlayerState,
  moment: Maybe<MomentModel>,
  permissionMode?: EditMomentButtonPermissionMode,
}

export const PlayerEditMomentButton = observer((props: Props) => {

  const { player, moment } = props;
  const store = useStore();

  if (!moment)
    return null;

  let perm: boolean;
  switch (props.permissionMode) {
    case 'always':
      perm = true;
      break;
    case 'currentUser':
    default:
      perm = moment.createdBy === store.user?.id;
      break;
  }

  if (!perm)
    return null;

  return (
    <Button className="text-btn transparent user-player-edit-moment-btn btn-plain"
      label="Edit"
      onClick={evt => {
        evt.stopPropagation();
        player.momentEditor.openEditMomentWindow(moment);
      }} />
  );
});