import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../../components/overlays';
import { Button } from '../../components';
import { ZoomDateSelectorWindowState } from './zoomDateSelectorWindowState';

type Props = {
  model: ZoomDateSelectorWindowState;
  visible?: boolean;
};

export const ZoomDateSelectorWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      model={model.window}
      visible={props.visible}
      isLoading={model.isLoading}
      className="zoom-date-selector-window">
      <WindowHeader
        model={model.window}
        title="Select Date for Zoom Recordings"
      />

      <hr />

      <div className="window-content">
        <p className="p-bold">
          Select a date range to see your zoom recordings.
        </p>

        <div className="date-selector-wrapper">
          <span className="filter-label">From:</span>
          <div className="trainer-date-filter">
            <input
              type="date"
              value={model.startDate}
              max={model.todayDate}
              onChange={(evt) => model.handleStartDateChanged(evt)}
            />
          </div>

          <span className="filter-label">to</span>

          <div className="trainer-date-filter">
            <input
              type="date"
              value={model.endDate}
              min={model.startDate}
              max={model.maxEndDate}
              onChange={(evt) => model.handleEndDateChanged(evt)}
            />
          </div>
        </div>
      </div>

      <hr />

      <footer className="window-footer">
        <div className="left">
          <span className="danger-text">
            Maximum allowed range is one month.
          </span>
        </div>

        <div className="btn-group right">
          <Button
            className="primary medium"
            label="Confirm"
            onClick={() => model.submit()}
            disabled={model.isButtonDisabled}
          />
        </div>
      </footer>
    </Window>
  );
});
