import { ExternalLibrarySource, Job, LibraryFile, StartJobInput } from '@clipr/lib';
import { action, computed, makeObservable, observable } from 'mobx';
import { DateTime, Duration } from 'luxon';
import { MediaAggregatedStatus, JobModel } from '../../entities';
//TODO: Still might have some issues because you're basically importing from the module which in turn might import back your current file. Let's discuss after the release.
import { Maybe, Nullable } from '../../core';
import { StoreNode } from '../../store';
import { Store } from '../../store/store';
import { notifyError, notifySuccess } from '../../services/notifications';
import { EnrichmentItem, generateJobLevelOutput, getDefaultJobLevelInput } from '../job/jobFeatures';
import { LibraryServiceBase } from '../../services/libraries';
import { getPlayerWidgetIFrameCode } from '../../widgets/playerWidget/playerWidgetUtils';

type Props = Omit<LibraryFile, "job"> | {
  cursor: string,
  index: number,
  job: Maybe<Job>,
  library: LibraryServiceBase
}

/** Stores a pointer to a Job in a catalog list and the pagination info associated with it. */
export class LibraryCatalogItem
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
    Object.assign(this, props);
  }

  readonly job!: Job;
  readonly fileExtension!: string;
  readonly mimeType!: string;
  readonly name!: string;
  readonly createdAt!: string;
  readonly thumbnail!: string | null;
  readonly durationInMs!: string;
  readonly cursor!: string;
  readonly index!: number;
  readonly library!: LibraryServiceBase;
  readonly canDownload!: boolean;

  @observable ingestStarted: boolean = false;
  @observable lastIngestedJobId: Nullable<string> = null;

  @computed
  get libraryType(): ExternalLibrarySource {
    return this.library.librarySource;
  }

  @computed
  get videoDuration(): number {
    return (this.durationInMs as any / 1000) || 0;
  }

  @computed
  get jobModel(): JobModel | null {
    if (!this.job) return null;
    return this.store.jobManager.maybeGetJob(this.job?.id);
  }

  @computed
  get status(): MediaAggregatedStatus | null {
    if (this.ingestStarted) return MediaAggregatedStatus.IngestProcessing;
    if (this.jobModel) return this.jobModel.mediaAggregatedStatus;
    if (this.job) {
      const job = new JobModel(this.job, this.store);
      return job.mediaAggregatedStatus;
    }
    return null;
  }

  @computed
  get enrichmentLevel(): EnrichmentItem | null {
    if (this.jobModel) return this.jobModel.enrichmentLevel;
    if (this.job) {
      const job = new JobModel(this.job, this.store);
      return job.enrichmentLevel;
    }

    return null;
  }

  @computed
  get isNotProcessed(): boolean {
    return this.isAvailableForIngest && this.canDownload && !this.status;
  }

  @computed
  get isIngesting(): boolean {
    return this.status === 'IngestProcessing';
  }

  @computed
  get isFailed(): boolean {
    return this.status === 'Failed';
  }
  @computed
  get isIngested(): boolean {
    return this.status === 'IngestCompleted';
  }

  @computed
  get createdAtRelativeLabel() {
    let date = this.createdAtDate;

    if (date.diffNow('days').days < -2)
      return date.toFormat('DDD');

    return (
      date.toRelativeCalendar() + ' at ' +
      date.toFormat('HH:mm'));
  }

  @computed
  get ellipsedTitle(): string | null {
    if (this.name?.length > 70) {
      return this.name?.substring(0, 69) + '...';
    } else {
      return this.name;
    }
  }

  /** Returns the 'createdAt' property as an UTC luxon DateTime object. */
  @computed
  get createdAtDate() {
    return DateTime.fromISO(this.createdAt);
  }

  @computed
  get isAvailableForIngest() {
    if (this.libraryType === 'Zoom') return true;
    return (this.mimeType?.startsWith('video') || this.mimeType?.startsWith('audio')) ?? false;
  }

  durationToTimeCode(format: string): string | null {
    switch (format) {
      case 'card':
        if (!this.videoDuration)
          return null;

        const dur = Duration.fromObject({ seconds: this.videoDuration });
        const hmsDur = dur.shiftTo('hours', 'minutes', 'seconds');

        const mins = Math.round(hmsDur.minutes).toString().padStart(2, '0');
        const secs = Math.round(hmsDur.seconds).toString().padStart(2, '0');
        const hrs = Math.round(hmsDur.hours).toString().padStart(2, '0');

        return `${hrs !== '00' ? hrs + ':' : ''}${mins}:${secs}`;
    }

    return null;
  }

  @action
  startIngest(teamId?: string) {
    const libraryId = this.library.library?.id || '';
    const args: StartJobInput = {
      teamId,
      source: {
        type: 'ExternalLibrary',
        externalLibrary: {
          externalLibraryId: libraryId,
          fileId: this.id,
          source: this.library.librarySource
        }
      }
    }
    this.store.ingestFromExternalLibraryWindow.open({ args, videoTitle: this.name });
  }

  @action
  async retryIngest() {
    this.ingestStarted = true;

    const libraryId = this.library.library?.id || '';
    const args: StartJobInput = {
      languageCode: this.job.languageCode || 'en-US',
      features: (this.enrichmentLevel &&
        generateJobLevelOutput(this.enrichmentLevel as EnrichmentItem)) || getDefaultJobLevelInput(),
      source: {
        type: 'ExternalLibrary',
        externalLibrary: {
          externalLibraryId: libraryId,
          fileId: this.id,
          source: this.library.librarySource
        }
      },
      videoType: this.job.videoType!
    }

    const [res, err] = await this.store.apiStartJob(args);

    if (err || !res) {
      notifyError(this, 'Video ingest failed.');
      this.ingestStarted = false;
      return [null, err];
    }

    notifySuccess(this, 'Video ingest started.');
    this.emit('job:ingestStarted', { job: res });

    return [res];
  }

  @action
  async copyIFrameEmbedCode() {
    const jobId = this.job.id;

    const code = getPlayerWidgetIFrameCode({ jobId });
    if (!code)
      return;

    await navigator.clipboard?.writeText(code);

    notifySuccess(this, 'Embed code copied to clipboard!');
  }
}