import { makeObservable } from 'mobx';
import nth from 'lodash/nth';
import sum from 'lodash/sum';
import { History, LocationDescriptor } from 'history';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { RouteContext } from '../../routes/routeContext';
import { IRouteStorage, RouteType, RouteVisit } from '../../routes/routeSchema';
import { Result } from '../../core';
import { getRelativeUrl } from './routingUtils';
import { StorageManager } from './storageManager';
import { HistoryManager } from './historyManager';
import { AuthFlowName } from '../auth/authFlowSchema';
import { BackRouteHandler } from './backRouteHandler';

import { INIT_DEBUGGER, TRACE } from '../../core/debug/debugMacros';

export class RoutingService
  extends StoreNode {

  readonly nodeType = 'RoutingService';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    INIT_DEBUGGER(this, { color: 'deepgreen' });
    TRACE(this, `constructor()`);

    const { pageLocation } = this;
    this.pageSearchParams = new URLSearchParams(pageLocation.search);
  }

  readonly storageManager = new StorageManager(this.store);
  readonly historyManager = new HistoryManager(this.store);
  readonly backRouteHandler = new BackRouteHandler(this.store);

  get storage(): StorageManager {
    return this.storageManager;
  }

  get history(): History<IRouteStorage> {
    return this.historyManager.history;
  }

  get contextHistory(): RouteContext[] {
    return this.historyManager.contextHistory;
  }
  get visitHistory(): RouteVisit[] {
    return this.historyManager.visitHistory;
  }

  get context(): RouteContext {
    return this.historyManager.context;
  }

  get initialContext(): RouteContext {
    return this.contextHistory[0] ?? this.pageContext;
  }

  get previousContext(): RouteContext | null {
    return nth(this.contextHistory, -2) ?? null;
  }

  get location() {
    return this.context.location;
  }

  get firstWidgetRoute(): RouteContext | null {
    return this.findVisitContext(ctx => ctx.routeType === RouteType.Widget);
  }

  get lastWidgetRoute(): RouteContext | null {
    return this.findVisitContext(ctx => ctx.routeType === RouteType.Widget, -1);
  }

  get sessionWidgetRoute(): RouteContext | null {
    return this.firstWidgetRoute;
  }

  get firstPrivateRoute(): RouteContext | null {
    return this.findVisitContext(ctx => ctx.routeType === RouteType.Private);
  }

  get lastPrivateRoute(): RouteContext | null {
    return this.findVisitContext(ctx => ctx.routeType === RouteType.Private, -1);
  }

  get pageContext(): RouteContext {
    return this.historyManager.pageContext;
  }

  readonly pageSearchParams: URLSearchParams;

  get pageLocation() { return this.pageContext.location; }
  get pageRelativeUrl() { return getRelativeUrl(this.pageLocation); }
  get pagePathname() { return this.pageLocation.pathname; }
  get pageSearch() { return this.pageLocation.search; }

  get directRouteVisits() {
    return this.countVisits(ctx => ctx.routeType === RouteType.Direct);
  }

  get authRouteVisits() {
    return this.countVisits(ctx => ctx.routeType === RouteType.Auth);
  }

  get privateRouteVisits() {
    return this.countVisits(ctx => ctx.routeType === RouteType.Private);
  }

  get publicRouteVisits() {
    return this.countVisits(ctx => ctx.routeType === RouteType.Public);
  }

  get widgetRouteVisits() {
    return this.countVisits(ctx => ctx.routeType === RouteType.Widget);
  }

  get onboardRouteVisits() {
    return this.countVisits(ctx => ctx.routeType === RouteType.Onboard);
  }

  get totalRouteVisits() {
    return (
      this.authRouteVisits +
      this.privateRouteVisits +
      this.publicRouteVisits +
      this.onboardRouteVisits +
      this.widgetRouteVisits);
  }

  init() {

    TRACE(this, `Initialized RoutingService with state: \n`, {
      pageRelativeUrl: this.pageRelativeUrl,
      pageContext: this.pageContext,
      authFlow: this.storageManager.authFlow,
      lastPrivateROute: this.storageManager.lastPrivatePath
    });
  }

  /** Navigates to a URL in the context of the router without never ever using any kind of hook. */
  goTo(loc: string | LocationDescriptor<IRouteStorage>) {
    this.history.replace(loc);
  }

  invoke(type: string, payload: any) {
    switch (type) {
      case 'goTo':
        this.goTo(payload?.location);
        break;
    }
  }

  routeAttached(context: RouteContext) {

    if (context.location !== this.context.location)
      console.warn('A route was attached with a different context than the one set in the service.');

    const { location } = context;
    switch (context.routeType) {
      case RouteType.Private:
        this.storageManager.setLastPrivatePath(context.relativeUrl);
        break;

      case RouteType.Public:
        break;
    }

    // for analytics
    this.broadcast('routeChanged', { location });
  }

  routeDetached() { }

  getBackRoute(): string | null | (() => void) {
    return this.backRouteHandler.generateBackRoute(this.previousContext, this.context, this.history);
  }

  setStorageAuthFlow(authFlow: AuthFlowName) {
    return this.storageManager.setAuthFlow(authFlow);
  }

  clearStorageAuthFlow() {
    return this.storageManager.clearAuthFlow();
  }

  clearStorage() {
    return this.storageManager.clear();
  }

  prepareRedirectUrl(path: string): Result<string> {
    return this.storageManager.prepareRedirectUrl(path);
  }

  private findVisit(filter: (visit: RouteVisit) => boolean, index = 0): RouteVisit | null {
    const { visitHistory } = this;
    if (index === 0)
      return visitHistory.find(filter) ?? null;

    return nth(visitHistory.filter(filter), index) ?? null;
  }

  private findVisitContext(filter: (ctx: RouteContext) => boolean, index = 0): RouteContext | null {
    return this.findVisit(visit => filter(visit.context), index)?.context ?? null;
  }

  private countVisits(filter: (ctx: RouteContext) => boolean) {
    return sum(this.visitHistory
      .filter(visit => filter(visit.context))
      .map(() => 1));
  }
}