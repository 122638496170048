import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { assertNotNull, Nullable } from '../../core';
import { JobCatalogSource, JobModel } from '../../entities';
import { CatalogState } from '../../components/layout/catalogState';
import { Error, pageError } from '../../core/error';
import { Team } from '../../entities/team';
import { TeamMemberCatalogSource } from '../../entities/teamMemberCatalogSource';
import debounce from 'lodash/debounce';
import { getApiTeamError } from '../../api';
import { TagSectionState } from '../../components/tags/tagSectionState';
import { VideoDetailsWindowState } from '../../components/jobs';

type Props = {

}

export type TeamDashboardPageParams = {
  teamId: string
}

export class TeamDashboardPageState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.jobCatalogSource = new JobCatalogSource(this.store, {
      teamId: () => this.teamId,
      pageSize: 20,
      jobFilter: (job: JobModel) => job.ownerTeamId === this.teamId
    });

    this.teamMemberCatalogSource = new TeamMemberCatalogSource(this.store, {
      teamId: () => this.teamId,
      pageSize: 6
    });

    this.jobCatalog = new CatalogState(this.store, {
      syncStatus: () => this.jobCatalogSource.syncStatus,
      isEndOfList: () => this.jobCatalogSource.isEndOfList
    });
    this.jobCatalog.listen((msg) => {
      switch (msg.type) {
        case 'scrollToBottom':
        case 'loadMore':
          this.jobCatalogSource.fetchMore();
          break;
      }
    });

    this.tagSectionState = new TagSectionState(this.store, {
      teamId: () => this.teamId,
      source: () => this.jobCatalogSource
    });
  }

  @observable isLoading: boolean = true;
  @observable error: Error | null = null;

  @computed get teamManager() {
    return this.store.teamManager;
  }

  @observable teamId: string | null = null;
  @observable team: Team | null = null;

  readonly jobCatalogSource: JobCatalogSource;
  readonly jobCatalog: CatalogState;
  readonly tagSectionState: TagSectionState;

  readonly teamMemberCatalogSource: TeamMemberCatalogSource;

  @action
  handleTeamMatesModal() {
    if (this.team?.hasPermission('EditTeam'))
      return this.openTeamMembersEditWindow();

    if (this.team?.hasPermission('ViewTeam'))
      return this.openTeamMembersWindow();

    return false;

  }

  @action
  async mounted(params: TeamDashboardPageParams) {
    this.isLoading = true;
    this.emit('Mounted', { teamId: params.teamId });
    this.bindExternalListeners();
    this.handlePageMountedDebounce(params);
  }

  unmounted() {
    this.reset();
    this.unbindExternalListeners();
    this.emit('Unmounted');
  }

  @action
  private async manageContentOnPageMounted(params: TeamDashboardPageParams) {
    const { teamId } = params;
    this.teamId = teamId;

    const [, teamErr] = await this.store.teamManager.apiFetchTeam({ id: this.teamId });

    this.setTeam(this.teamManager.getTeam(this.teamId));

    if (!this.team) {
      const [teamRes, teamErr] = await this.store.api.getTeam({ id: this.teamId });

      if (teamErr) {
        const decodedError = getApiTeamError(teamErr);
        return this.setError(decodedError);
      }

      this.setTeam(new Team(this.store, teamRes?.getTeam));
    }

    // make sure that none of the requests failed and also we have a Team entity for the requested ID
    if (teamErr || !this.team) {
      const decodedError = getApiTeamError(teamErr);
      return this.setError(decodedError);
    }

    // we have a team so we can fetch the members, with the same defensive checks
    const [, membersErr] = await this.teamMemberCatalogSource.fetch();

    // const [, invitationsErr] = await team.apiFetchInvitations();
    if (membersErr) // || invitationsErr
      return this.setError();

    const [, jobsErr] = await this.jobCatalogSource.fetch();
    if (jobsErr)
      return this.setError();

    // Fetch the tags for the corresponding team
    await this.tagSectionState.load();

    // everything ok
    this.setLoaded();

    const showOnboarding = this.store.storage.getLocal(
      'user.showOnboarding',
    ) && !this.store.ui.isMobile;

    if (showOnboarding) {
      this.dispatch('openPostRegistrationTutorial');
      this.store.storage.removeLocal(
        'user.showOnboarding',
      );
    }
  }

  private handlePageMountedDebounce = debounce((params: TeamDashboardPageParams) => this.manageContentOnPageMounted(params), 500);

  @action
  setPagesize(pageSize: number) {
    this.jobCatalogSource.setPagesizeValue(pageSize);
  }

  setTeam(team: Nullable<Team>) {
    runInAction(() => {
      this.team = team;
    })
  }

  private bindExternalListeners() {
    const { videoDetailsWindow } = this.store;

    videoDetailsWindow.listen(
      this.videoDetailsWindowListener);
  }

  private videoDetailsWindowListener = action(async (msg: Message<VideoDetailsWindowState>) => {
    switch (msg.type) {
      case 'JobUpdated': {
        this.isLoading = true;
        await this.refreshTeam();
        await this.refreshJobs();
        await this.tagSectionState.load();


        this.isLoading = false;
        break;
      }
    }
  });

  openTeamMembersWindow() {
    assertNotNull(this.teamId);
    const { team } = this;
    if (!team || team.isPublic) {
      // public team
      return;
    }

    this.store.teamMembersWindow.openTeam(this.teamId);
  }

  openTeamMembersEditWindow() {
    assertNotNull(this.teamId);
    const { team } = this;
    if (!team || team.isPublic) {
      // public team
      return;
    }

    this.store.teamMembersWindow.openTeamEdit(this.teamId);
  }

  @action
  async refreshTeam() {
    if (this.teamId)
      await this.store.teamManager.apiFetchTeam({ id: this.teamId });

    this.setTeam(this.teamManager.getTeam(this.teamId));
  }

  @action
  async refreshJobs() {
    return await this.jobCatalogSource.fetch();
  }

  @action
  setError(error?: Error) {
    if (!error)
      error = pageError();

    this.isLoading = false;
    this.error = error;
  }

  @action
  setLoaded() {
    this.isLoading = false;
    this.error = null;
  }

  private unbindExternalListeners() {
    const { store } = this;
    store.videoDetailsWindow.unlisten(
      this.videoDetailsWindowListener);
  }

  @action
  reset() {
    this.setTeam(null);
    this.tagSectionState.reset();
    this.jobCatalogSource.reset();
    this.isLoading = false;
    this.error = null;
    this.emit('Unmounted');
  }
}