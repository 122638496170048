import './teams.scss';

import React, { ReactEventHandler } from 'react';
import { observer } from 'mobx-react-lite';
import { Maybe, RouteValue } from '../../core';
import { Team } from '../../entities/team';
import { TeamsSVGIcon } from '../svg';
import classNames from 'classnames';
import { TeamModel } from '../jobs';

type Props = {
  model?: Maybe<Team | TeamModel>;
  className?: string;
  route?: Maybe<RouteValue>;
  onClick?: ReactEventHandler;
};

/**
 * Displays the avatar for the provided Team.
 */
export const TeamAvatar = observer(
  ({ model, className, route = null, ...props }: Props) => {
    if (!model) return null;

    className = classNames('team-avatar', className);

    return (
      <div className={className} {...props}>
        {model.pictureURL ? (
          <img className="team-picture" src={model.pictureURL} alt="avatar" />
        ) : (
          <TeamsSVGIcon />
        )}
      </div>
    );
  }
);
