import assert from 'assert';
import { DropdownItemObject, input, inputGroup, InputGroupState, InputState } from '../input';
import { MomentModel, JobModel, TrackModel } from '../../entities';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { notifyError, notifyLoading, notifySuccess } from '../../services/notifications';
import { action, computed, observable, makeObservable } from 'mobx';
import { closeWindow, openWindow } from '../../services/overlays';
import { MomentSource, UpdateMomentInput } from '@clipr/lib';
import { ClipTypes, MomentTypes } from './clipWindowState';
import { WindowState } from '../overlays';
import { getDefaultLanguageValue, getLanguageInputItem, LanguageItems } from '../../entities/language';
import { SpeakerWindowState } from '../speakerWindow/speakerWindowState';
import { TrackWindowState } from '../trackWindow/trackWindowState';
import { getSentimentLabel, Sentiments } from '../../entities/job/jobSentiment';
// import { AnalyticsEventTypes } from '../../services/analytics/analyticsSchema';

type MultipleClipEditWindowMode =
  'multipleEdit';

type Props = {
  jobId: string
}

type OpenEditArgs = {
  momentList: MomentModel[],
  jobId: string,
  source?: MomentSource
}

export class MultipleClipEditWindowState
  extends StoreNode {

  readonly nodeType: 'MultipleClipEditWindow' = 'MultipleClipEditWindow';

  constructor(store: Store, props?: Props) {
    super(store);
    makeObservable(this);

    this.jobId = props?.jobId || null;

    // inputs
    this.momentType = input(this, {
      name: 'momentType',
      selectorItems: MomentTypes,
      isRequired: () => this.clipType.value === 'Moment',
      disabled: () => this.clipType.value !== 'Moment',
      // onChange: input => {
      //   //@ts-ignore
      //   const changes = this.evaluateMomentTypeValueChange(input.value);
      //   console.log(`Changes: ${changes}`)
      // },
      onChange: () => {
        if (this.clipType.value === 'Moment') {
          const track = this.job?.maybeGetMomentTrackByMomentType(this.momentType.normValue ?? null);
          //@ts-ignore
          this.track.value = track ? {
            value: track.id,
            label: track.displayName,
          } : null;
        }
      }
    });

    this.clipType = input(this, {
      name: 'clipType',
      selectorItems: () => ClipTypes.filter(item => item !== 'Transcript'),
      disabled: () => (this.clipType.initialValue &&
        ['Transcript', 'Paragraph'].includes(this.clipType.initialValue)) ||
        this.job?.isEnrichmentProcessing,
      error: (input) => {
        if (!input.value)
          return 'Required field';
      },
      onChange: () => {
        this.track.value = null;

        if (this.clipType.value !== 'Moment') {
          this.momentType.value = null;
        }
        if (this.clipType.value === 'Paragraph' || this.clipType.value === 'Transcript') {
          this.name.value = null;
        }
      }
    });

    this.name = input(this, {
      name: 'name',
      error: (input, fallback) => {
        if (!input.value && ['Moment', 'Topic', 'SubTopic'].includes(this.clipType.value!))
          return 'Required field';
        if (!input.value)
          return null;
        if ((this.clipType.value === 'Topic' && input.value.length > 30)
          || (this.clipType.value === 'SubTopic' && input.value.length > 50)
          || (this.clipType.value === 'Moment' && input.value.length > 50))
          return 'Name too long';
        return fallback;
      },
      disabled: true
    });

    this.sentiment = input(this, {
      name: 'sentiment',
      export: (self: InputState) => self.normValue,
      selectorItems: () => Sentiments.map(value => ({
        value,
        label: getSentimentLabel(value)
      })),
      disabled: () => this.clipType.value === 'SubTopic'
    });

    this.speaker = input(this, {
      name: 'speaker',
      selectorItems: () => this.speakerItems,
      error: (input) => {
        if (input.isEmpty && input.isTouched && this.clipType.value === 'Transcript')
          return `Required for 'Transcript'`;
      },
      export: (self: InputState) => self.normValue,
      onChange: () => {
        if (this.clipType.value === 'Transcript') {
          const track = this.job?.maybeGetTranscriptTrackBySpeaker(this.speaker.normValue ?? null);
          //@ts-ignore
          this.track.value = track ? {
            value: track.id,
            label: track.displayName,
          } : null;
        }
      }
    });

    this.keywords = input(this, {
      name: 'keywords',
      disabled: true
    })

    this.importance = input(this, {
      name: 'importance',
      disabled: true
    });

    this.description = input(this, {
      name: 'description',
      multiline: true,
      disabled: true
    });

    this.track = input(this, {
      name: 'track',
      selectorItems: () => this.trackItems,
      export: (self: InputState) => self.normValue,
      onChange: () => {
        if (this.clipType.value === 'Transcript')
          //@ts-ignore
          this.speaker.value = this.trackModel?.speaker ? {
            value: this.trackModel?.speaker.id,
            label: this.trackModel?.speaker.name,
          } : null;

        if (this.clipType.value === 'Moment') {
          //@ts-ignore
          this.momentType.value = this.trackModel?.momentType ?? null;
        }
      },
      isRequired: true
    });

    this.language = input(this, {
      name: 'language',
      placeholder: 'Select language',
      selectorItems: LanguageItems,
      export: (self: InputState) => self.normValue
    });

    this.visibleToConsumer = input(this, {
      name: 'visibleToConsumer',
      onChange: () => {
        if (this.visibleToConsumer.multipleValues)
          this.visibleToConsumer.multipleValues = false;
      }
    });

    this.inputGroup = inputGroup(this, {
      name: 'moment',
      inputs: [
        this.momentType,
        this.clipType,
        this.sentiment,
        this.speaker,
        this.keywords,
        this.importance,
        this.description,
        this.language,
        this.visibleToConsumer,
        this.track
      ]
    });

    this.window.listen(this.windowListener);
  }

  readonly window = new WindowState(this.store);

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
        if (this.inputGroup.isSubmitting)
          return;
        this.cancel();
        break;
      case 'outsideClick':
        if (this.inputGroup.isSubmitting)
          return;
        this.cancel(true);
        break;
    }
  };

  @observable mode: MultipleClipEditWindowMode | null = null;
  @observable jobId: string | null = null;

  @observable startTime: number | null = null;
  @observable endTime: number | null = null;
  // @observable trackId: number | null = null;

  readonly inputGroup: InputGroupState;

  readonly clipType: InputState;
  readonly momentType: InputState;
  readonly name: InputState;
  readonly sentiment: InputState;
  readonly speaker: InputState;
  readonly keywords: InputState;
  readonly importance: InputState;
  readonly description: InputState;
  readonly language: InputState;
  readonly track: InputState;
  readonly visibleToConsumer: InputState;

  @observable isVisible = false;
  @observable source: any = null;
  @observable editModelList: MomentModel[] | null = null;

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this?.jobId);
  }

  @computed get editModel() {
    if (this.editModelList && this.editModelList.length > 0)
      return this.editModelList[0];

    return null;
  }

  @computed get trackModel(): TrackModel | null {
    if (!this.job || !this.track.normValue)
      return null;

    return this.job?.getTrack(this.track.normValue);
  }

  @computed get shouldHaveParentClip(): boolean {
    let type = this.clipType?.value || null;
    return type === 'SubTopic';
  }


  @computed get speakerItems(): DropdownItemObject[] {
    const { job } = this;
    if (!job)
      return [];

    return job.trackSpeakers
      .map(speaker => ({
        value: speaker.id,
        label: speaker.name
      })) || [];
  }

  @computed get trackItems(): DropdownItemObject[] {
    const { job } = this;
    if (!job)
      return [];

    return job.tracks
      .filter(item => item.type === this.clipType.value).map(item => ({
        value: item.id,
        label: item.displayName
      }));
  }

  // @computed get momentTypeListValues() {
  //   let momentTypeValueList = [...MomentTypes];
  //   if (this.mode === 'multipleEdit')
  //     momentTypeValueList.push('(Multiple Values)')
  //   return momentTypeValueList;
  // }

  // @computed get sentimentListValues() {
  //   let sentimentValueList = [...Sentiments];
  //   if (this.mode === 'multipleEdit')
  //     sentimentValueList.push('(Multiple Values)')
  //   return sentimentValueList;
  // }

  // @computed get speakerListValues() {
  //   let speakerItemValues = this.store.speakerManager.publicSpeakers.map(speaker => {
  //     return {
  //       id: speaker.id,
  //       label: speaker.name
  //     }
  //   }) || [];

  //   if (this.mode === 'multipleEdit')
  //     speakerItemValues.push({
  //       id: 'multipleValues',
  //       label: '(Multiple Values)'
  //     });

  //   return speakerItemValues;
  // }

  // evaluateMomentTypeValueChange(value: any) {
  //   return this.editModelList?.filter(el => el.momentType !== value).length || 0;
  // }

  evaluateMomentArray() {
    let distinctSpeakerIds = [...new Set(this.editModelList?.map(it => it.speakerId))];
    let distinctMomentTypes = [...new Set(this.editModelList?.map(it => it.momentType))];
    let distinctSentiments = [...new Set(this.editModelList?.map(it => it.sentiment))];
    let distinctClipTypes = [...new Set(this.editModelList?.map(it => it.clipType))];
    let distinctVisibleToConsumers = [...new Set(this.editModelList?.map(it => it.visibleToConsumer))];
    let distinctLanguages = [...new Set(this.editModelList?.map(it => it.languageCode || this.job?.languageCode || getDefaultLanguageValue()))];
    let distinctNames = [...new Set(this.editModelList?.map(it => it.name))];
    let distinctTrack = [...new Set(this.editModelList?.map(it => it.trackId))];

    return {
      speakerId: (distinctSpeakerIds.length > 1 ? 'multiple' : distinctSpeakerIds[0]),
      momentType: (distinctMomentTypes.length > 1 ? 'multiple' : distinctMomentTypes[0]),
      sentiment: (distinctSentiments.length > 1 ? 'multiple' : distinctSentiments[0]),
      clipType: (distinctClipTypes.length > 1 ? 'multiple' : distinctClipTypes[0]),
      visibleToConsumer: (distinctVisibleToConsumers.length > 1 ? 'multiple' : distinctVisibleToConsumers[0]),
      language: (distinctLanguages.length > 1 ? 'multiple' : distinctLanguages[0]),
      name: (distinctNames.length > 1 ? 'multiple' : distinctNames[0]),
      trackId: (distinctTrack.length > 1 ? 'multiple' : distinctTrack[0])
    }
  }

  initValues() {
    const evalRes = this.evaluateMomentArray();

    //init speaker input - dropdown
    let speakerValue;
    if (evalRes.speakerId === 'multiple')
      speakerValue = {
        value: 'multipleValues',
        label: '(Multiple Values)'
      };
    else {
      const speaker = evalRes.speakerId ? this.store.getSpeaker(evalRes.speakerId) : null;
      speakerValue = (speaker && {
        value: speaker.id,
        label: speaker.name
      }) ?? null;
    }
    this.speaker.loadValue(speakerValue);

    //init track input - dropdown
    let trackValue;
    if (evalRes.trackId === 'multiple')
      trackValue = {
        value: 'multipleValues',
        label: '(Multiple Values)'
      };
    else {
      const track = evalRes.trackId ? this.job?.getTrack(evalRes.trackId) : null;
      trackValue = (track && {
        value: track.id,
        label: track.name
      }) || null;
    }
    this.track.loadValue(trackValue);

    //init language value - dropdown
    const languageValue = evalRes.language === 'multiple' ? {
      value: 'multipleValues',
      label: '(Multiple Values)'
    } :
      getLanguageInputItem(evalRes.language);

    this.language.loadValue(languageValue);

    this.momentType.loadValue(evalRes.momentType === 'multiple' ? '(Multiple Values)' : evalRes.momentType);
    this.sentiment.loadValue(evalRes.sentiment === 'multiple' ? '(Multiple Values)' : evalRes.sentiment);
    this.name.loadValue(evalRes.name === 'multiple' ? '(Multiple Values)' : evalRes.name);
    this.clipType.loadValue(evalRes.clipType === 'multiple' ? '(Multiple Values)' : evalRes.clipType);

    // visibleToConsumer - checkbox
    this.visibleToConsumer.loadValue(evalRes.visibleToConsumer === 'multiple' ? true : evalRes.visibleToConsumer);
    this.visibleToConsumer.multipleValues = evalRes.visibleToConsumer === 'multiple' ? true : false;
  }

  getModelListProps(): UpdateMomentInput[] | null {

    const formData = this.inputGroup.export();

    return this.editModelList?.map(moment => {
      const momentType = formData.momentType === '(Multiple Values)' ? moment.momentType : formData.momentType;
      const clipType = formData.clipType === '(Multiple Values)' ? moment.clipType : formData.clipType;
      const sentiment = formData.sentiment === '(Multiple Values)' ? moment.sentiment : formData.sentiment;
      const speakerId = formData.speaker === 'multipleValues' ? moment.speakerId : formData.speaker;
      const language = formData.language === 'multipleValues' ? (moment.languageCode || this.job?.languageCode || getDefaultLanguageValue()) : formData.language;
      const visibleToConsumer = this.visibleToConsumer.multipleValues ? moment.visibleToConsumer : formData.visibleToConsumer;
      const startTime = moment.startTime;
      const endTime = moment.endTime;
      const trackId = formData.track === 'multipleValues' ? moment.trackId : formData.track;

      return {
        momentId: moment.id!,
        source: this.source || 'User',
        clipType: clipType!,

        jobId: this.jobId!,
        startTime: startTime!,
        endTime: endTime!,

        // keywords: ((formData.keywords as string) || '')
        //   .split(',')
        //   .map(k => k.trim())
        //   .filter(k => k) || undefined,

        momentType: momentType!,
        speakerId: speakerId!,
        trackId: trackId!,
        // description: formData.description!,
        // importance: parseInt(formData.importance) || undefined,
        sentiment: sentiment!,
        languageCode: language!,

        visibleToConsumer: visibleToConsumer!,
      }
    }) || null;
  }

  delete = async () => {

    if (!this.jobId || !this.editModelList)
      return;

    this.store.deleteMomentsPopup.open({
      jobId: this.jobId,
      momentIds: [...this.editModelList.map(clip => clip.id)],
      onSubmitCallback: () => {
        // this.emit('momentsDeleted', { momentId: this.editModel?.id });
        this.clearState();
        this.close('close');
      }
    });
  }

  submit = async () => {
    if (!this.job)
      return;

    this.inputGroup.handleSubmit();
    if (this.inputGroup.error) {
      this.inputGroup.handleSubmitReject();
      notifyError(this, 'Fix the validation errors before saving.');
      return;
    }

    const errorMessage = this.validateClipChange();

    if (errorMessage) {
      this.inputGroup.handleSubmitReject();
      notifyError(this, errorMessage);
      return;
    }

    const propsList: UpdateMomentInput[] | null = this.getModelListProps();

    try {

      if (!propsList)
        return;

      notifyLoading(this, `Updating ${propsList?.length} clips`);

      const [, err] = await this.job?.apiUpdateMoments(propsList);

      if (err) {
        this.inputGroup.handleSubmitReject();
        notifyError(this, 'Could not update all the selected clips because of an error.');
        return;
      }

      this.inputGroup.handleSubmitResolve();
      notifySuccess(this, `${propsList?.length} clips were updated.`);

      // this.emit('momentsUpdated', { momentIdList });
      this.close('momentsUpdated');

    } catch (e) {
      this.inputGroup.handleSubmitReject();
      notifyError(this, 'Could not update the clips because of an error.');
      return;
    }
  }

  validateClipChange = () => {
    let errorMessage = null;
    if (this.editModelList && this.editModelList.length > 0) {
      this.editModelList.forEach(editModel => {

        if (this.job?.status === 'Done') {
          if (editModel.isTopic && this.clipType.value !== 'Topic') {// validate change from Topic
            const children = this.job?.getSubtopicsOverlappingInterval(editModel?.startTimeNorm, editModel?.endTimeNorm);
            if (children && children.length > 0) // one Topic has children that will remain whithout parent
              errorMessage = 'Topic has moments/subtopics';
          }

          if (!editModel.shouldHaveParentClip && this.shouldHaveParentClip && !editModel.parent) // validate change to children (subtopic)
            errorMessage = 'Not all subtopics have a parent';
        }

        if (!editModel.isTopic && this.clipType.value === 'Topic' && editModel.parent)
          errorMessage = 'Overlapping topics';

        if (!editModel.isTopic && this.clipType.value === 'Topic' && editModel.name?.length > 30)
          errorMessage = 'Topic names cannot exceed 30 chars';

        if (!editModel.isSubtopic && this.clipType.value === 'SubTopic' && editModel.name?.length > 50)
          errorMessage = 'SubTopic names cannot exceed 50 chars';

        if (editModel.clipType !== 'Moment' && this.clipType.value === 'Moment' && editModel.name?.length > 50)
          errorMessage = 'Moment names cannot exceed 50 chars';

      })
    }

    return errorMessage;
  }

  /** Hides the window and clears the entire state of the window. */
  @action
  cancel = (withConfirmation: boolean = false) => {
    if (this.inputGroup.isSubmitting || !this.isVisible)
      return;
    if (this.inputGroup.isDirty && withConfirmation)
      this.store.closeWindowConfirmationModal.open({
        onSubmit: () => {
          this.clearState();
          this.close('close');
        },
        modalMessage: 'Are you sure you want to close the clip window? The progress will be lost.'
      })
    else
      this.close('close');
  }

  @action
  open(mode: MultipleClipEditWindowMode) {
    assert(this.jobId,
      `Cannot show a MultipleClipEditWindow without a valid jobId`);

    this.isVisible = true;
    this.mode = mode;

    openWindow(this, 'MultipleClipEditWindow');
    this.emit('open');
  }

  @action
  clearState = () => {
    this.jobId = null;
    this.editModelList = null;
    this.startTime = null;
    this.endTime = null;
    this.inputGroup.clear();
    this.mode = null;
    this.unsubscribeListeners();
  }

  @action
  close(msg?: string) {
    this.isVisible = false;
    closeWindow(this);
    if (msg)
      this.emit(msg);
  }

  @action
  onTransitionEnd = () => {
    if (!this.isVisible)
      this.clearState();
  }

  @action
  openEdit(args: OpenEditArgs) {
    const { momentList, jobId, source } = args;

    assert(!this.isVisible,
      `MultipleClipEditWindow is already opened`);

    assert(!momentList.find(el => !(el?.jobId)) || !jobId,
      `Cannot open MultipleClipEditWindow for editing because the jobId was not provided.`);
    assert(!momentList.find(el => !(el instanceof MomentModel)),
      `Cannot open MultipleClipEditWindow for editing because not all the clips are an instance of MomentModel.`);

    this.editModelList = momentList;

    this.jobId = jobId;
    this.source = source || 'User';

    this.initValues();
    this.subscribeListeners();

    this.open('multipleEdit');
  }

  @action
  openTrackWindow() {
    if (!this.job)
      return;
    const addTrackWindow = this.store.trackWindow;
    addTrackWindow.openCreate(this.job?.id);
  }

  @action
  openSpeakerWindow() {
    if (!this.job)
      return;
    const speakerWindow = this.store.speakerWindow;
    speakerWindow.openCreate(this.job?.id);
  }

  @action
  private auxWindowListener = (msg: Message<SpeakerWindowState | TrackWindowState>) => {
    const { type, payload } = msg;
    switch (type) {
      case 'speakerCreated':
      case 'speakerUpdated':
        const { speakerId } = payload;
        const speakerItem = this.speakerItems.find(({ value }) => value === speakerId) ?? null;
        if (speakerItem) {
          this.speaker.handleChange(null, speakerItem);
        }

        break;
      case 'trackCreated':
      case 'trackUpdated':
        const { trackId } = payload;
        const trackitem = this.trackItems.find(({ value }) => value === trackId) ?? null;
        if (trackitem) {
          this.track.handleChange(null, trackitem);
        }
        break;
    }
  };

  private subscribeListeners() {
    const { speakerWindow, trackWindow } = this.store;
    speakerWindow.listen(this.auxWindowListener);
    trackWindow.listen(this.auxWindowListener);
  }

  private unsubscribeListeners() {
    const { speakerWindow, trackWindow } = this.store;
    speakerWindow.unlisten(this.auxWindowListener);
    trackWindow.unlisten(this.auxWindowListener);
  }
}