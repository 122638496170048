import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { Button } from '../input';
import { JobErrorsWindowState } from './jobErrorsWindowState';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Duration } from 'luxon';

type Props = {
  model: JobErrorsWindowState;
  visible?: boolean;
};

const duration = (timestamp: Maybe<number>) =>
  Duration.fromObject({ seconds: timestamp || 0 }).toFormat('hh:mm:ss');

export const JobErrorsWindow = observer(({ model, visible }: Props) => {
  return (
    <Window
      model={model.window}
      visible={visible}
      onEntered={model.onWindowEntered}
      onExited={model.onWindowExited}
      className="clip-errors-window">
      <WindowHeader title="Status Change Fail" model={model.window} />

      <hr />

      <section className="window-content">
        <p>
          <strong className="red">
            {model.errorCount} {(model.multipleErrors) ? 'errors' : 'error'}{' '}
          </strong>{' '}
          found in <strong>{model.job?.title || 'the selected job'}.</strong>
        </p>

        {model.uniqueActiveTrackTypes.map(item => {
          return <div key={item}>
            <br />
            <p >
              In order to mark this video as 'Done', only one <b>{item}</b> track can be active. Please hide the others.
            </p>
          </div>
        })}
        {model.showClipError && <>
          <br />
          <p>
            In order to mark this video as 'Done', the following subtopics require
            a parent topic.
          </p>
          <br />
          <ClipErrorList model={model} />
        </>}
      </section>

      <hr />

      <footer className="window-footer ">
        <div className="col left"></div>
        <div className="col right">
          <div className="btn-group">
            <Button
              label="Close"
              className="primary medium"
              onClick={() => model.cancel()}
              disabled={model.isLoading}
            />
          </div>
        </div>
      </footer>
    </Window>
  );
});

type ClipErrorListProps = {
  model: JobErrorsWindowState;
};

const ClipErrorList = observer(({ model }: ClipErrorListProps) => {
  if (!model) return null;

  return (
    <ul className="clip-error-list">
      {model.clipList.map((clip) => {
        const startTime = clip.startTime;
        const endTime = clip.endTime;
        return (
          <div key={clip.id} className="clip-error-list-item">
            <div className="clip-interval">
              <span className="start-time">{duration(startTime)}</span>
              <span className="separator">-</span>
              <span className="end-time">{duration(endTime)}</span>
            </div>
            <div className="clip-name">
              {clip.name ||
                clip.description ||
                clip.momentType ||
                clip.clipType}
            </div>
          </div>
        );
      })}
    </ul>
  );
});
