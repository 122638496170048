import { action, makeObservable, observable } from 'mobx';
import { StartJobInput } from '@clipr/lib';
import { Store } from '../../store/store';
import { StoreNode, Message, BindingProps } from '../../store';
import { CatalogState } from '../../components/layout/catalogState';
import { Error, pageError } from '../../core/error';
import { LibraryCatalogSource } from '../../entities';
import { LibraryProvider } from '../../services/libraries';
import { NotConnectedState } from './notConnectedState';
import { IngestFromExternalLibraryWindowState } from '../../components/jobs/ingestFromExternalLibraryWindowState';
import { notifyError } from '../../services/notifications';

type ViewType = 'grid' | 'list';
type Props = BindingProps<{
  pageSize: number,
  libraryService: LibraryProvider,
  defaultSearchValue?: string
  viewType?: ViewType
}>

export class IntegrationTabState
  extends StoreNode {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.catalogSource = new LibraryCatalogSource(this.store, {
      pageSize: props.pageSize as number || 20,
      library: props.libraryService as LibraryProvider,
      defaultSearchValue: props.defaultSearchValue
    });
    this.catalog = new CatalogState(this.store, {
      syncStatus: () => this.catalogSource.syncStatus,
      isEndOfList: () => this.catalogSource.isEndOfList,
      viewType: props.viewType
    });
    this.catalogSource.listen((msg) => {
      switch (msg.type) {
        case 'credentialsExpired': {
          if (!this.credentialsExpired) {
            this.credentialsExpired = true;
            this.handleCredentialsExpired();
          }
          break;
        }
      }
    });
    this.catalog.listen((msg) => {
      switch (msg.type) {
        case 'scrollToBottom':
        case 'loadMore':
          if (!this.catalogSource.isEndOfList) {
            this.catalogSource.fetchMore();
          }
          break;
      }
    });
    this.libraryService = props.libraryService as LibraryProvider;
    this.enabled = (props.libraryService as LibraryProvider).enabled || false;
  }

  @observable isLoading: boolean = false;
  @observable error: Error | null = null;

  @observable credentialsExpired: boolean = false;
  @observable isTabActive: boolean = false;

  readonly libraryService: LibraryProvider;
  readonly catalogSource: LibraryCatalogSource;
  readonly catalog = new CatalogState(this.store);
  readonly notConnectedState = new NotConnectedState(this.store, { library: this.props.libraryService });
  readonly enabled: boolean;

  @action
  async pageMounted(isConnected: boolean) {
    this.reset();
    
    await this.store.libraryService.apiEnsureLibraries();

    this.isTabActive = true;
    if (this.credentialsExpired) {
      return this.handleCredentialsExpired();
    }
    if (isConnected) await this.fetch();
  }

  @action
  async fetch() {
    this.isLoading = true;
    await this.catalogSource.fetch();

    this.setLoaded();
  }

  @action
  setPagesize(pageSize: number) {
    this.catalogSource.setPagesizeValue(pageSize)
  }

  @action
  bulkIngest(teamId?: string) {
    const library = this.catalogSource.libraryService;
    const libraryId = library.library?.id || '';

    const args: StartJobInput = {
      teamId,
      source: {
        type: 'ExternalLibrary',
        externalLibrary: {
          externalLibraryId: libraryId,
          source: library.librarySource,
          fileId: ''
        }
      }
    };
    const listener = (msg: Message<IngestFromExternalLibraryWindowState>) => {
      switch (msg.type) {
        case 'close:successful': {
          this.catalogSource.clearMultipleSelection();
          break;
        }
        case 'close': {
          this.store.ingestFromExternalLibraryWindow.unlisten(listener);
          break;
        }
      }
    };

    this.store.ingestFromExternalLibraryWindow.listen(listener);
    this.store.ingestFromExternalLibraryWindow.open({
      args,
      multipleSelection: true,
      fileSelection: this.catalogSource.fileSelectionIds
    });
  }

  @action
  async handleCredentialsExpired() {
    if (!this.isTabActive || this.store.overlayService.visibleWindow === 'CredentialsExpiredWindow') {
      return;
    }
    if (this.libraryService.enabled) {
      this.dispatch('openCredentialsExpiredWindow', { library: this.libraryService });
      notifyError(this, 'Credentials expired.');
      this.credentialsExpired = false;
    }

    await this.libraryService.disconnect();

  }

  @action
  pageUnmounted() {
    this.reset();
  }

  @action
  reset() {
    this.isTabActive = false;
    this.isLoading = false;
    this.error = null;
    this.catalogSource.reset();
  }

  @action
  private setError(error?: Error) {
    if (!error)
      error = pageError();

    this.isLoading = false;
    this.error = error;
  }

  @action
  private setLoaded() {
    this.isLoading = false;
    this.error = null;
  }
}