import React from 'react';
import { observer } from 'mobx-react-lite';

import { handler } from '../../core';
import { CloseSVGIcon } from '../svg';
import { Window } from '../overlays';
import { Button } from '../input';

import { CommentDeleteWindowState } from './commentDeleteWindowState';
import { CommentCard } from './commentCard';
import { LoadMask } from '../loader/loadMask';

type Props = {
  model: CommentDeleteWindowState;
  visible?: boolean;
};

export const CommentDeleteWindow = observer((props: Props) => {
  const { model, visible } = props;

  return (
    <Window className="comment-delete-window"
      model={model.window}
      // isLoading={model.isLoading}
      visible={visible}>
      <header>
        <h2>{model.title}</h2>
        <Button className="close-btn"
          onClick={handler(model, 'close')}
          icon={<CloseSVGIcon />} />
      </header>

      <section className="vmar4">
        {model.comment && <CommentCard model={model.comment} readonly />}
        {!model.comment?.isReply && (
          <p className="disclamer tmar4">
            All replies on this comment will also be removed.
          </p>
        )}
      </section>

      <footer>
        <Button className="primary btn-outline large"
          onClick={handler(model, 'close')}
          label="Cancel" />
        <Button className="red large"
          onClick={handler(model, 'submit')}
          label="Delete" />
      </footer>

      {/* Cannot use the window load mask yet as it doesn't support the right color */}
      <LoadMask
        visible={model.isLoading}
        className="window-load small"
        spinner="dots5"
        spinnerClassName="medium"
      />
    </Window>
  );
});