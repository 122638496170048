import * as React from "react";
import classNames from 'classnames';

export const FunnyBloomSVGIcon = ({className, ...props}: React.SVGProps<SVGSVGElement>) =>
  <svg
    viewBox="0 0 251 239"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames("funny-bloom-svg-icon", "svg-icon", className)}
    {...props}>
      <style>
        {
          "\n    .main-bloom {\n      transform: scale(0);\n      transform-origin: center center;\n      animation: pulse 2s 1 alternate;\n    }\n\n    .bg-bloom {\n      transform: scale(0);\n      transform-origin: center center;\n      animation: pulse2 1.6s 1 alternate-reverse;\n      opacity: 0;\n    }\n\n    @keyframes pulse {\n      60% {\n        transform: scale(1);\n        opacity: 1;\n      }\n\n      100% {\n        transform: scale(0);\n        opacity: 0;\n      }\n    }\n\n    @keyframes pulse2 {\n      60% {\n        transform: scale(1);\n        opacity: 1;\n      }\n\n      80% {\n        opacity: 0;\n      }\n\n      100% {\n        transform: scale(0);\n        opacity: 0;\n      }\n    }\n  "
        }
      </style>
      <g className="main-bloom">
        <path
          d="M184.455 119.239C184.455 152.206 157.729 178.932 124.762 178.932C91.7938 178.932 65.0681 152.206 65.0681 119.239C65.0681 86.2708 91.7938 59.5452 124.762 59.5452C157.729 59.5452 184.455 86.2708 184.455 119.239Z"
          fill="#FFCB4C"
          fillOpacity={0.1}
          stroke="white"
        />
        <path
          d="M124.762 169.4C152.465 169.4 174.923 146.942 174.923 119.239C174.923 91.5353 152.465 69.0774 124.762 69.0774C97.0583 69.0774 74.6003 91.5353 74.6003 119.239C74.6003 146.942 97.0583 169.4 124.762 169.4Z"
          fill="#FFCB4C"
          fillOpacity={0.15}
        />
        <path
          d="M124.761 161.374C148.032 161.374 166.897 142.509 166.897 119.239C166.897 95.9679 148.032 77.1033 124.761 77.1033C101.491 77.1033 82.626 95.9679 82.626 119.239C82.626 142.509 101.491 161.374 124.761 161.374Z"
          fill="#FFCB4C"
          fillOpacity={0.2}
        />
        <path
          d="M124.761 152.345C143.046 152.345 157.868 137.523 157.868 119.238C157.868 100.954 143.046 86.1321 124.761 86.1321C106.477 86.1321 91.655 100.954 91.655 119.238C91.655 137.523 106.477 152.345 124.761 152.345Z"
          fill="#FFCB4C"
          fillOpacity={0.25}
        />
        <g filter="url(#filter0_d_11596:86091)">
          <path
            d="M144.955 98.5194C156.12 109.684 156.12 127.789 144.955 138.955C133.789 150.12 115.686 150.12 104.519 138.955C93.3542 127.789 93.3542 109.684 104.519 98.5194C115.684 87.3542 133.788 87.3542 144.955 98.5194"
            fill="#FFCB4C"
          />
        </g>
        <path
          d="M126.768 120.77C121.218 126.32 116.89 129.354 111.448 133.022C110.207 133.862 108.386 136.086 111.45 139.151C117.576 145.278 132.276 145.898 142.086 136.087C151.898 126.276 151.28 111.578 145.15 105.45C142.086 102.384 139.86 104.207 139.022 105.448C135.356 110.888 132.32 115.218 126.768 120.77V120.77Z"
          fill="#65471B"
        />
        <path
          d="M114.514 136.087C114.514 136.087 120.642 133.023 129.833 123.831C139.025 114.64 142.087 108.514 142.087 108.514C142.087 108.514 145.15 117.706 134.428 128.428C123.706 139.151 114.514 136.087 114.514 136.087V136.087Z"
          fill="white"
        />
        <path
          d="M134.743 100.716C134.519 100.748 130.973 101.283 126.891 103.302C126.792 103.142 126.681 102.988 126.543 102.849C126.128 102.435 125.598 102.216 125.056 102.171C126.497 99.5614 128.198 97.8236 128.23 97.7934C128.846 97.174 128.846 96.1669 128.225 95.549C127.604 94.9311 126.602 94.9295 125.982 95.549C125.735 95.7968 119.902 101.714 119.902 109.027C119.902 109.67 120.288 110.248 120.882 110.495C121.474 110.741 122.159 110.604 122.613 110.15C127.768 104.995 135.121 103.871 135.195 103.86C135.548 103.809 135.856 103.645 136.09 103.412C136.431 103.07 136.614 102.576 136.541 102.062C136.412 101.193 135.61 100.592 134.743 100.716V100.716ZM114.749 114.178C107.436 114.178 101.519 120.011 101.271 120.259C100.652 120.878 100.652 121.882 101.271 122.502C101.889 123.123 102.894 123.124 103.517 122.506C103.547 122.476 105.285 120.773 107.895 119.334C107.941 119.876 108.158 120.406 108.573 120.821C108.711 120.959 108.865 121.07 109.026 121.169C107.005 125.25 106.47 128.797 106.438 129.021C106.314 129.889 106.918 130.693 107.785 130.817C108.301 130.892 108.792 130.709 109.134 130.368C109.367 130.134 109.531 129.824 109.582 129.47C109.593 129.397 110.69 122.073 115.872 116.891C116.326 116.437 116.461 115.754 116.217 115.16C115.97 114.564 115.392 114.178 114.749 114.178V114.178Z"
          fill="#65471B"
        />
        <path
          d="M143.958 109.294C148.312 109.824 152.272 106.724 152.801 102.368C153.332 98.0142 150.233 94.0543 145.877 93.5237C141.523 92.9948 129.343 97.9126 129.151 99.4899C128.959 101.066 139.605 108.762 143.958 109.294V109.294ZM115.456 137.797C115.986 142.151 112.885 146.111 108.53 146.639C104.176 147.17 100.216 144.071 99.6856 139.715C99.155 135.36 104.074 123.18 105.652 122.988C107.227 122.797 114.923 133.443 115.456 137.797V137.797Z"
          fill="#64AADD"
        />
        <rect
          x={125}
          y={45}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          fill="#64AADD"
        />
        <rect
          x={125}
          y={77.1738}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          fill="#64AADD"
        />
        <rect
          x={125}
          y={70.739}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          fill="#64AADD"
        />
        <rect
          x={125}
          y={64.3044}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          fill="#64AADD"
        />
        <rect
          x={128.217}
          y={193}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(180 128.217 193)"
          fill="#64AADD"
        />
        <rect
          x={128.217}
          y={160.826}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(180 128.217 160.826)"
          fill="#64AADD"
        />
        <rect
          x={128.217}
          y={167.261}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(180 128.217 167.261)"
          fill="#64AADD"
        />
        <rect
          x={128.217}
          y={173.696}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(180 128.217 173.696)"
          fill="#64AADD"
        />
        <rect
          x={199}
          y={119}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(90 199 119)"
          fill="#64AADD"
        />
        <rect
          x={166.826}
          y={119}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(90 166.826 119)"
          fill="#64AADD"
        />
        <rect
          x={173.261}
          y={119}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(90 173.261 119)"
          fill="#64AADD"
        />
        <rect
          x={179.696}
          y={119}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(90 179.696 119)"
          fill="#64AADD"
        />
        <rect
          x={51}
          y={122.217}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(-90 51 122.217)"
          fill="#64AADD"
        />
        <rect
          x={83.1738}
          y={122.217}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-90 83.1738 122.217)"
          fill="#64AADD"
        />
        <rect
          x={76.739}
          y={122.217}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-90 76.739 122.217)"
          fill="#64AADD"
        />
        <rect
          x={70.304}
          y={122.217}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-90 70.304 122.217)"
          fill="#64AADD"
        />
        <rect
          x={177.327}
          y={66.6733}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(45 177.327 66.6733)"
          fill="#64AADD"
        />
        <rect
          x={154.577}
          y={89.4238}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(45 154.577 89.4238)"
          fill="#64AADD"
        />
        <rect
          x={74.9482}
          y={173.601}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(-135 74.9482 173.601)"
          fill="#64AADD"
        />
        <rect
          x={97.6982}
          y={150.851}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-135 97.6982 150.851)"
          fill="#64AADD"
        />
        <rect
          x={177.327}
          y={171.326}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(135 177.327 171.326)"
          fill="#64AADD"
        />
        <rect
          x={154.577}
          y={148.576}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(135 154.577 148.576)"
          fill="#64AADD"
        />
        <rect
          x={70.3987}
          y={68.9497}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(-45 70.3987 68.9497)"
          fill="#64AADD"
        />
        <rect
          x={93.1492}
          y={91.7}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-45 93.1492 91.7)"
          fill="#64AADD"
        />
        <rect
          x={145.175}
          y={68.7202}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(21.8729 145.175 68.7202)"
          fill="white"
        />
        <rect
          x={149.218}
          y={58.6497}
          width={2.36311}
          height={6.43478}
          rx={1.1436}
          transform="rotate(21.8729 149.218 58.6497)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={106.87}
          y={170.478}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-158.127 106.87 170.478)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.3784}
          rx={1.1436}
          transform="matrix(-0.928012 -0.372549 -0.372549 0.928012 105.941 172.798)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={175.366}
          y={139.409}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(111.873 175.366 139.409)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.5957}
          rx={1.1436}
          transform="matrix(-0.37255 0.928012 0.928012 0.37255 178.494 140.665)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={73.6069}
          y={101.103}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-68.1271 73.6069 101.103)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.42869}
          rx={1.1436}
          transform="matrix(0.372549 -0.928012 -0.928012 -0.372549 69.9937 99.6523)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={174.911}
          y={97.812}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(66.8729 174.911 97.812)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.58471}
          rx={1.1436}
          transform="matrix(0.392772 0.919636 0.919636 -0.392772 178.499 96.2795)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={75.8716}
          y={142.681}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-113.127 75.8716 142.681)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={8.48348}
          rx={1.1436}
          transform="matrix(-0.392772 -0.919636 -0.919636 0.392772 72.4053 144.162)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={146.274}
          y={169.143}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(156.873 146.274 169.143)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.43478}
          rx={1.1436}
          transform="matrix(-0.919636 0.392771 0.392771 0.919636 147.32 171.591)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={101.406}
          y={70.1042}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-23.1271 101.406 70.1042)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.43478}
          rx={1.1436}
          transform="matrix(0.919636 -0.392772 -0.392772 -0.919636 99.6348 65.9541)"
          fill="white"
          fillOpacity={0.7}
        />
      </g>
      <g className="bg-bloom">
        <g clipPath="url(#clip0_11596:86091)">
          <path
            d="M155.34 6.02687C159.024 9.48784 159.024 15.1 155.34 18.561C151.655 22.0219 145.682 22.0219 141.997 18.561C138.313 15.1 138.313 9.48784 141.997 6.02687C145.681 2.56591 151.655 2.56591 155.34 6.02687"
            fill="#FFCB4C"
          />
          <path
            d="M149.339 12.9241C147.507 14.6445 146.079 15.585 144.283 16.7219C143.874 16.9824 143.273 17.6717 144.284 18.622C146.305 20.5211 151.156 20.7131 154.393 17.6722C157.631 14.6307 157.427 10.0747 155.404 8.1751C154.393 7.2248 153.659 7.79005 153.382 8.1746C152.172 9.86102 151.171 11.2033 149.339 12.9241V12.9241Z"
            fill="#65471B"
          />
          <path
            d="M145.295 17.6723C145.295 17.6723 147.317 16.7225 150.35 13.8731C153.383 11.0241 154.393 9.125 154.393 9.125C154.393 9.125 155.404 11.9744 151.866 15.298C148.328 18.6221 145.295 17.6723 145.295 17.6723V17.6723Z"
            fill="white"
          />
          <path
            d="M151.97 6.70772C151.896 6.71757 150.726 6.8835 149.379 7.50932C149.346 7.45959 149.31 7.41183 149.264 7.36899C149.127 7.24048 148.952 7.17253 148.773 7.15874C149.249 6.34976 149.81 5.81109 149.821 5.80173C150.024 5.6097 150.024 5.29753 149.819 5.106C149.614 4.91446 149.283 4.91397 149.079 5.106C148.997 5.18281 147.073 7.01694 147.073 9.28387C147.073 9.48328 147.2 9.66251 147.396 9.73883C147.591 9.81515 147.817 9.77281 147.967 9.63198C149.668 8.0342 152.094 7.68559 152.119 7.68215C152.235 7.66639 152.337 7.61567 152.414 7.54329C152.527 7.43743 152.587 7.2843 152.563 7.12477C152.521 6.85543 152.256 6.66931 151.97 6.70772V6.70772ZM145.372 10.8807C142.959 10.8807 141.007 12.6887 140.925 12.7655C140.72 12.9575 140.72 13.2687 140.925 13.4608C141.129 13.6533 141.46 13.6538 141.666 13.4622C141.676 13.4529 142.249 12.925 143.111 12.4789C143.126 12.6468 143.198 12.8113 143.334 12.9398C143.38 12.9827 143.431 13.0171 143.484 13.0476C142.817 14.3126 142.64 15.4121 142.63 15.4815C142.589 15.7508 142.788 16 143.074 16.0384C143.245 16.0615 143.407 16.0049 143.519 15.899C143.596 15.8267 143.65 15.7306 143.667 15.6208C143.671 15.5982 144.033 13.3278 145.743 11.7217C145.893 11.5808 145.937 11.3691 145.857 11.185C145.775 11.0003 145.584 10.8807 145.372 10.8807V10.8807Z"
            fill="#65471B"
          />
          <path
            d="M155.011 9.36648C156.448 9.53093 157.754 8.5698 157.929 7.21968C158.104 5.87006 157.081 4.64255 155.644 4.47809C154.207 4.31413 150.188 5.83855 150.125 6.32749C150.061 6.81593 153.574 9.20153 155.011 9.36648V9.36648ZM145.606 18.2018C145.781 19.5514 144.757 20.7789 143.32 20.9429C141.884 21.1074 140.577 20.1467 140.402 18.7966C140.227 17.4465 141.85 13.6709 142.371 13.6113C142.89 13.5522 145.43 16.8522 145.606 18.2018V18.2018Z"
            fill="#64AADD"
          />
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip1_11596:86091)">
            <path
              d="M142.62 232.003C134.618 234.147 126.392 229.398 124.248 221.396C122.104 213.395 126.853 205.17 134.855 203.025C142.857 200.881 151.082 205.63 153.226 213.632C155.37 221.633 150.622 229.858 142.62 232.003"
              fill="#FFCB4C"
            />
            <path
              d="M137.281 217.904C136.215 213.926 135.972 211.164 135.733 207.729C135.677 206.945 135.145 205.534 132.948 206.122C128.557 207.299 124.42 213.815 126.304 220.845C128.188 227.877 135.028 231.451 139.42 230.274C141.617 229.686 141.372 228.197 141.028 227.49C139.518 224.397 138.347 221.882 137.281 217.904V217.904Z"
              fill="#65471B"
            />
            <path
              d="M133.536 208.318C133.536 208.318 133.321 211.906 135.086 218.493C136.851 225.08 138.831 228.079 138.831 228.079C138.831 228.079 133.851 227.059 131.792 219.375C129.733 211.691 133.536 208.318 133.536 208.318V208.318Z"
              fill="white"
            />
            <path
              d="M144.301 226.787C144.345 226.677 145.032 224.926 145.185 222.541C145.284 222.539 145.383 222.529 145.482 222.502C145.779 222.423 146.018 222.239 146.18 222.005C146.988 223.344 147.331 224.572 147.337 224.595C147.457 225.037 147.914 225.301 148.358 225.181C148.802 225.061 149.065 224.606 148.946 224.162C148.899 223.985 147.74 219.783 144.418 217.864C144.125 217.696 143.761 217.719 143.494 217.925C143.226 218.129 143.109 218.476 143.196 218.802C144.186 222.496 142.768 226.131 142.754 226.168C142.684 226.342 142.678 226.525 142.723 226.692C142.788 226.937 142.965 227.149 143.218 227.251C143.646 227.421 144.129 227.214 144.301 226.787V226.787ZM143.429 214.172C145.347 210.849 144.249 206.631 144.202 206.453C144.083 206.009 143.627 205.746 143.183 205.865C142.738 205.983 142.474 206.439 142.591 206.884C142.597 206.906 142.915 208.142 142.884 209.705C142.626 209.584 142.328 209.544 142.031 209.624C141.932 209.65 141.841 209.691 141.754 209.738C140.43 207.75 138.959 206.576 138.865 206.503C138.503 206.219 137.98 206.282 137.696 206.643C137.527 206.858 137.481 207.129 137.546 207.374C137.591 207.541 137.689 207.697 137.837 207.813C137.867 207.837 140.907 210.257 141.902 213.97C141.989 214.296 142.264 214.537 142.598 214.581C142.933 214.626 143.26 214.464 143.429 214.172V214.172Z"
              fill="#65471B"
            />
            <path
              d="M137.986 228.724C136.603 230.563 136.973 233.176 138.813 234.559C140.652 235.942 143.264 235.573 144.648 233.733C146.03 231.894 146.991 225.07 146.324 224.569C145.659 224.068 139.37 226.886 137.986 228.724V228.724ZM132.513 208.298C130.395 207.397 129.41 204.949 130.312 202.832C131.213 200.714 133.66 199.728 135.778 200.63C137.896 201.531 142.139 206.961 141.813 207.728C141.486 208.494 134.63 209.198 132.513 208.298V208.298Z"
              fill="#64AADD"
            />
          </g>
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip2_11596:86091)">
            <path
              d="M46.7003 125.618C53.6349 123.76 60.764 127.876 62.6221 134.811C64.4802 141.745 60.3646 148.874 53.4296 150.732C46.495 152.591 39.366 148.475 37.5079 141.54C35.6497 134.605 39.7653 127.477 46.7003 125.618"
              fill="#FFCB4C"
            />
            <path
              d="M51.3275 137.837C52.2512 141.285 52.4618 143.679 52.6688 146.655C52.7177 147.335 53.1791 148.558 55.0828 148.048C58.8881 147.029 62.4737 141.381 60.8411 135.288C59.2082 129.194 53.2803 126.096 49.4737 127.117C47.57 127.626 47.7821 128.917 48.0799 129.529C49.3887 132.21 50.4036 134.389 51.3275 137.837V137.837Z"
              fill="#65471B"
            />
            <path
              d="M54.5727 146.145C54.5727 146.145 54.7594 143.035 53.2292 137.327C51.7 131.618 49.9839 129.019 49.9839 129.019C49.9839 129.019 54.2996 129.903 56.084 136.562C57.869 143.222 54.5727 146.145 54.5727 146.145V146.145Z"
              fill="white"
            />
            <path
              d="M45.2436 130.139C45.2052 130.234 44.61 131.752 44.4769 133.818C44.3913 133.821 44.3054 133.829 44.2195 133.852C43.962 133.921 43.7551 134.08 43.6145 134.284C42.9143 133.123 42.6168 132.058 42.6122 132.039C42.5084 131.655 42.1118 131.426 41.7273 131.53C41.3428 131.634 41.1143 132.029 41.2174 132.413C41.2587 132.567 42.2625 136.209 45.1421 137.872C45.3955 138.018 45.7109 137.997 45.9429 137.819C46.1745 137.642 46.2764 137.342 46.2008 137.059C45.3429 133.858 46.5717 130.707 46.5843 130.675C46.6444 130.525 46.6501 130.366 46.6112 130.221C46.5544 130.009 46.4014 129.825 46.1821 129.737C45.8107 129.59 45.392 129.769 45.2436 130.139V130.139ZM45.9991 141.072C44.3365 143.951 45.2881 147.607 45.3293 147.761C45.4324 148.146 45.8277 148.374 46.2125 148.271C46.5975 148.169 46.8267 147.773 46.725 147.388C46.7199 147.369 46.4445 146.298 46.4711 144.943C46.6949 145.048 46.9533 145.083 47.2108 145.014C47.2966 144.991 47.3754 144.955 47.4507 144.915C48.5981 146.638 49.8731 147.655 49.9541 147.718C50.268 147.965 50.7217 147.91 50.9677 147.596C51.1144 147.41 51.1541 147.175 51.0973 146.963C51.0584 146.818 50.9736 146.683 50.8457 146.583C50.8195 146.562 48.185 144.465 47.3227 141.246C47.2471 140.964 47.0088 140.756 46.7193 140.717C46.4288 140.679 46.1453 140.818 45.9991 141.072V141.072Z"
              fill="#65471B"
            />
            <path
              d="M50.7164 128.46C51.9151 126.866 51.5944 124.602 49.9996 123.403C48.4059 122.205 46.1421 122.525 44.943 124.119C43.7449 125.713 42.9123 131.627 43.4897 132.062C44.0665 132.495 49.517 130.053 50.7164 128.46V128.46ZM55.4599 146.163C57.2949 146.944 58.1493 149.065 57.3674 150.9C56.5865 152.735 54.466 153.59 52.6303 152.809C50.7947 152.027 47.117 147.321 47.3999 146.657C47.6831 145.993 53.6245 145.383 55.4599 146.163V146.163Z"
              fill="#64AADD"
            />
          </g>
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip3_11596:86091)">
            <path
              d="M156.067 124.169C157.925 117.234 165.054 113.118 171.988 114.976C178.923 116.834 183.039 123.963 181.181 130.898C179.323 137.832 172.194 141.948 165.259 140.09C158.324 138.232 154.209 131.104 156.067 124.169"
              fill="#FFCB4C"
            />
            <path
              d="M168.963 126.271C172.41 127.195 174.588 128.209 177.27 129.518C177.883 129.816 179.172 130.028 179.683 128.124C180.703 124.319 177.605 118.39 171.512 116.757C165.418 115.125 159.771 118.709 158.751 122.516C158.241 124.42 159.465 124.881 160.144 124.929C163.119 125.136 165.514 125.347 168.963 126.271V126.271Z"
              fill="#65471B"
            />
            <path
              d="M177.78 127.614C177.78 127.614 175.18 125.898 169.471 124.369C163.762 122.838 160.654 123.025 160.654 123.025C160.654 123.025 163.577 119.73 170.237 121.514C176.897 123.298 177.78 127.614 177.78 127.614V127.614Z"
              fill="white"
            />
            <path
              d="M159.253 127.69C159.317 127.771 160.334 129.045 162.057 130.194C162.016 130.269 161.98 130.348 161.957 130.434C161.888 130.691 161.923 130.95 162.028 131.173C160.673 131.199 159.602 130.925 159.583 130.919C159.199 130.817 158.802 131.046 158.7 131.431C158.598 131.816 158.825 132.211 159.21 132.314C159.364 132.355 163.02 133.307 165.9 131.644C166.153 131.498 166.293 131.215 166.255 130.925C166.217 130.636 166.008 130.397 165.725 130.322C162.524 129.464 160.41 126.824 160.388 126.797C160.288 126.67 160.154 126.586 160.009 126.547C159.796 126.49 159.56 126.53 159.374 126.676C159.061 126.924 159.007 127.377 159.253 127.69V127.69ZM169.099 132.502C170.762 135.382 174.404 136.386 174.558 136.427C174.943 136.53 175.338 136.302 175.441 135.917C175.545 135.533 175.317 135.136 174.932 135.032C174.913 135.027 173.848 134.73 172.688 134.029C172.891 133.888 173.05 133.682 173.119 133.424C173.142 133.338 173.151 133.252 173.153 133.167C175.219 133.035 176.738 132.439 176.833 132.401C177.203 132.252 177.383 131.831 177.234 131.462C177.146 131.242 176.963 131.09 176.751 131.033C176.606 130.994 176.447 131 176.295 131.061C176.264 131.073 173.131 132.306 169.912 131.443C169.63 131.368 169.331 131.47 169.152 131.701C168.974 131.934 168.953 132.249 169.099 132.502V132.502Z"
              fill="#65471B"
            />
            <path
              d="M160.536 122.111C159.755 120.276 157.633 119.422 155.798 120.204C153.963 120.985 153.108 123.105 153.89 124.941C154.671 126.776 159.377 130.454 160.041 130.171C160.706 129.888 161.316 123.947 160.536 122.111V122.111ZM178.239 126.855C179.832 125.656 182.097 125.977 183.295 127.572C184.494 129.165 184.174 131.429 182.579 132.628C180.985 133.827 175.07 134.659 174.636 134.082C174.203 133.505 176.645 128.054 178.239 126.855V126.855Z"
              fill="#64AADD"
            />
          </g>
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip4_11596:86091)">
            <path
              d="M74.7566 22.4284C73.0276 29.9641 65.5159 34.6721 57.9802 32.9431C50.4445 31.2141 45.7369 23.7031 47.4655 16.1667C49.1945 8.63104 56.7061 3.92304 64.2418 5.65202C71.7775 7.381 76.4851 14.892 74.7566 22.4284"
              fill="#FFCB4C"
            />
            <path
              d="M60.7959 20.6692C57.05 19.8098 54.6657 18.8029 51.7288 17.4987C51.0576 17.2025 49.6612 17.0249 49.186 19.0934C48.2373 23.2284 51.8027 29.4882 58.4239 31.0074C65.0462 32.5268 70.9835 28.4484 71.9321 24.3116C72.4072 22.2432 71.072 21.7941 70.3392 21.7687C67.1287 21.6623 64.5428 21.5289 60.7959 20.6692V20.6692Z"
              fill="#65471B"
            />
            <path
              d="M51.2539 19.5679C51.2539 19.5679 54.1188 21.3137 60.3225 22.7365C66.526 24.1604 69.8643 23.8379 69.8643 23.8379C69.8643 23.8379 66.8469 27.4988 59.6103 25.8385C52.373 24.1785 51.2539 19.5679 51.2539 19.5679V19.5679Z"
              fill="white"
            />
            <path
              d="M71.1895 18.7627C71.1181 18.6781 69.9741 17.3463 68.0749 16.1776C68.1158 16.095 68.1509 16.0088 68.1723 15.9155C68.2365 15.6357 68.1894 15.3586 68.067 15.1223C69.5245 15.0414 70.6878 15.2954 70.7086 15.3007C71.1259 15.3953 71.5437 15.1334 71.6386 14.7151C71.7335 14.2967 71.4735 13.8804 71.0554 13.7845C70.8881 13.7461 66.9165 12.8648 63.8823 14.7665C63.6154 14.9338 63.4759 15.2443 63.5282 15.5548C63.5801 15.8646 63.8148 16.1132 64.1214 16.1835C67.6004 16.9817 69.979 19.7399 70.003 19.768C70.1158 19.9011 70.2638 19.9864 70.4214 20.0226C70.6519 20.0754 70.9044 20.0228 71.0989 19.8586C71.4259 19.5793 71.4665 19.0904 71.1895 18.7627V18.7627ZM60.4051 13.9681C58.5034 10.9339 54.5448 9.99579 54.3776 9.95741C53.9594 9.86148 53.5429 10.1225 53.447 10.5406C53.35 10.9585 53.6108 11.3761 54.0291 11.4738C54.0495 11.4784 55.2078 11.7566 56.4836 12.4652C56.2708 12.6251 56.1073 12.8534 56.0431 13.1332C56.0217 13.2265 56.0156 13.3193 56.0165 13.4115C53.798 13.6343 52.1872 14.3346 52.086 14.3797C51.6933 14.5542 51.5168 15.0136 51.6909 15.4057C51.7942 15.6393 51.9976 15.7954 52.2281 15.8483C52.3857 15.8845 52.5567 15.8718 52.7169 15.8008C52.7501 15.7864 56.0744 14.3372 59.5715 15.1396C59.8781 15.2099 60.1966 15.0883 60.3795 14.8323C60.5626 14.5753 60.5724 14.235 60.4051 13.9681V13.9681Z"
              fill="#65471B"
            />
            <path
              d="M70.0274 24.817C70.9395 26.7614 73.2557 27.598 75.2003 26.6849C77.1447 25.7728 77.9818 23.4573 77.0693 21.5123C76.1565 19.5683 70.9489 15.7937 70.2445 16.1241C69.5407 16.4541 69.116 22.8722 70.0274 24.817V24.817ZM50.7899 20.4032C49.1214 21.7555 46.6722 21.4988 45.3201 19.8292C43.9678 18.1608 44.2239 15.712 45.893 14.3592C47.5621 13.0065 53.8948 11.8803 54.3847 12.4847C54.8735 13.0889 52.4579 19.0502 50.7899 20.4032V20.4032Z"
              fill="#64AADD"
            />
          </g>
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip5_11596:86091)">
            <path
              d="M129.494 60.7945C131.458 53.6022 138.881 49.3624 146.073 51.3255C153.265 53.2886 157.505 60.7109 155.542 67.9039C153.579 75.0962 146.156 79.3359 138.964 77.3729C131.771 75.4098 127.532 67.9874 129.494 60.7945"
              fill="#FFCB4C"
            />
            <path
              d="M142.876 63.0402C146.451 64.0161 148.709 65.0803 151.487 66.4526C152.122 66.7644 153.46 66.9906 154 65.0165C155.077 61.0699 151.889 54.8981 145.569 53.1732C139.249 51.4481 133.367 55.1433 132.29 59.0916C131.751 61.0658 133.02 61.5512 133.725 61.6045C136.814 61.8336 139.3 62.0642 142.876 63.0402V63.0402Z"
              fill="#65471B"
            />
            <path
              d="M152.026 64.4774C152.026 64.4774 149.335 62.6824 143.413 61.0669C137.493 59.4503 134.264 59.6294 134.264 59.6294C134.264 59.6294 137.315 56.2212 144.222 58.1063C151.13 59.9911 152.026 64.4774 152.026 64.4774V64.4774Z"
              fill="white"
            />
            <path
              d="M132.787 64.467C132.852 64.5514 133.902 65.8796 135.686 67.0806C135.643 67.1585 135.606 67.2402 135.582 67.3292C135.509 67.5963 135.543 67.8651 135.652 68.0976C134.244 68.1181 133.133 67.8275 133.114 67.8216C132.715 67.714 132.302 67.9498 132.194 68.3491C132.087 68.7485 132.321 69.1598 132.72 69.2687C132.879 69.3123 136.671 70.3181 139.669 68.6055C139.933 68.4549 140.08 68.1612 140.042 67.86C140.004 67.5594 139.788 67.3107 139.495 67.2308C136.175 66.3246 133.992 63.5733 133.97 63.5453C133.866 63.4126 133.727 63.3246 133.577 63.2835C133.357 63.2235 133.111 63.2642 132.917 63.4147C132.591 63.6709 132.533 64.1404 132.787 64.467V64.467ZM142.988 69.5119C144.701 72.5103 148.478 73.5703 148.637 73.6139C149.036 73.7228 149.448 73.4877 149.557 73.0886C149.667 72.6898 149.432 72.2772 149.033 72.1666C149.014 72.1613 147.908 71.8476 146.707 71.1146C146.919 70.9689 147.085 70.7554 147.158 70.4884C147.182 70.3993 147.192 70.3101 147.195 70.2213C149.341 70.0941 150.92 69.4829 151.02 69.4435C151.405 69.2908 151.593 68.8551 151.441 68.4705C151.351 68.2414 151.161 68.0829 150.941 68.0229C150.79 67.9818 150.625 67.9873 150.468 68.0494C150.435 68.062 147.175 69.3271 143.838 68.4161C143.545 68.3362 143.233 68.4408 143.047 68.6802C142.86 68.9207 142.838 69.2481 142.988 69.5119V69.5119Z"
              fill="#65471B"
            />
            <path
              d="M134.145 58.6797C133.343 56.7703 131.145 55.8729 129.235 56.6759C127.326 57.478 126.428 59.6759 127.23 61.5859C128.033 63.4949 132.901 67.3371 133.593 67.0465C134.284 66.7563 134.947 60.5894 134.145 58.6797V58.6797ZM152.506 63.6911C154.167 62.454 156.517 62.7979 157.753 64.4598C158.99 66.1206 158.647 68.4699 156.986 69.7074C155.324 70.9449 149.178 71.7803 148.73 71.1786C148.283 70.5772 150.846 64.9288 152.506 63.6911V63.6911Z"
              fill="#64AADD"
            />
          </g>
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip6_11596:86091)">
            <path
              d="M18.7945 50.6574C24.0408 50.6574 28.2945 54.9111 28.2945 60.1575C28.2945 65.4038 24.0412 69.6572 18.7945 69.6575C13.5481 69.6575 9.2944 65.4038 9.2944 60.1575C9.2944 54.9111 13.5477 50.6578 18.7945 50.6574"
              fill="#FFCB4C"
            />
            <path
              d="M19.7495 60.1578C19.7495 62.7656 19.4453 64.4954 19.0285 66.6357C18.9344 67.1246 19.0288 68.0751 20.469 68.0755C23.3478 68.0755 26.9469 64.7675 26.9469 60.1578C26.9469 55.5473 23.3486 52.2393 20.4686 52.2397C19.0285 52.2393 18.934 53.1906 19.0285 53.6791C19.4453 55.8186 19.7495 57.5491 19.7495 60.1578V60.1578Z"
              fill="#65471B"
            />
            <path
              d="M20.469 66.6358C20.469 66.6358 21.1889 64.4761 21.1885 60.1571C21.1889 55.8381 20.469 53.6792 20.469 53.6792C20.469 53.6792 23.3482 55.1193 23.3482 60.1575C23.3486 65.196 20.469 66.6358 20.469 66.6358V66.6358Z"
              fill="white"
            />
            <path
              d="M16.9114 53.5728C16.8662 53.6329 16.159 54.5916 15.6742 56.0251C15.6134 56.0105 15.5511 56.0004 15.4862 56.0004C15.2913 56.0004 15.1152 56.0736 14.9775 56.1904C14.7028 55.2387 14.6942 54.4308 14.6946 54.4162C14.6939 54.1259 14.4573 53.8893 14.1662 53.89C13.8751 53.8908 13.6392 54.1259 13.6392 54.417C13.6392 54.5334 13.659 57.2939 15.3772 59.0121C15.5283 59.1633 15.7549 59.2084 15.9523 59.1267C16.1493 59.0453 16.2781 58.8524 16.2781 58.6389C16.2781 56.2169 17.7414 54.2251 17.7563 54.205C17.8272 54.1102 17.8612 53.9994 17.8612 53.8896C17.8612 53.7292 17.788 53.5702 17.6499 53.4664C17.4156 53.2925 17.086 53.3399 16.9114 53.5728V53.5728ZM15.3768 61.433C13.6586 63.1512 13.6388 65.9117 13.6388 66.0282C13.6388 66.3193 13.8747 66.5551 14.1658 66.5551C14.4569 66.5559 14.6935 66.32 14.6946 66.0286C14.6946 66.0144 14.7028 65.206 14.9779 64.2548C15.1159 64.3712 15.2917 64.4447 15.4865 64.4447C15.5515 64.4447 15.6138 64.4346 15.6746 64.4201C16.1587 65.8535 16.8662 66.8126 16.9114 66.8727C17.0864 67.106 17.4171 67.153 17.6499 66.9783C17.7888 66.8746 17.8612 66.7164 17.8612 66.5559C17.8612 66.4462 17.8268 66.335 17.7556 66.2398C17.741 66.22 16.2781 64.2413 16.2781 61.8066C16.2781 61.5931 16.1493 61.4009 15.9523 61.3188C15.7545 61.2367 15.528 61.2819 15.3768 61.433V61.433Z"
              fill="#65471B"
            />
            <path
              d="M21.0917 53.4227C22.2393 52.5244 22.4412 50.8656 21.5422 49.718C20.6439 48.5704 18.9854 48.3682 17.8375 49.2668C16.6903 50.1655 14.984 54.1825 15.3095 54.5983C15.6345 55.0137 19.9438 54.3206 21.0917 53.4227V53.4227ZM21.0917 66.816C22.2393 67.7142 22.4412 69.3731 21.5422 70.5207C20.6439 71.6682 18.9854 71.8705 17.8375 70.9719C16.6895 70.0732 14.9837 66.0558 15.3091 65.64C15.6345 65.225 19.9438 65.918 21.0917 66.816V66.816Z"
              fill="#64AADD"
            />
          </g>
        </g>
        <g clipPath="url(#clip7_11596:86091)">
          <path
            d="M74.5445 226.166C69.5742 226.166 65.5444 222.136 65.5444 217.166C65.5444 212.196 69.5739 208.166 74.5445 208.166C79.5147 208.166 83.5445 212.196 83.5445 217.166C83.5445 222.136 79.5151 226.165 74.5445 226.166"
            fill="#FFCB4C"
          />
          <path
            d="M73.6397 217.166C73.6397 214.695 73.9279 213.056 74.3228 211.029C74.4119 210.565 74.3225 209.665 72.9581 209.665C70.2308 209.665 66.8211 212.798 66.8211 217.166C66.8211 221.533 70.2301 224.667 72.9584 224.667C74.3228 224.667 74.4123 223.766 74.3228 223.303C73.9279 221.276 73.6397 219.637 73.6397 217.166V217.166Z"
            fill="#65471B"
          />
          <path
            d="M72.9581 211.028C72.9581 211.028 72.2761 213.074 72.2765 217.166C72.2761 221.258 72.9581 223.303 72.9581 223.303C72.9581 223.303 70.2305 221.939 70.2305 217.166C70.2301 212.392 72.9581 211.028 72.9581 211.028V211.028Z"
            fill="white"
          />
          <path
            d="M76.3286 223.404C76.3714 223.347 77.0414 222.439 77.5006 221.081C77.5583 221.094 77.6173 221.104 77.6788 221.104C77.8634 221.104 78.0303 221.035 78.1607 220.924C78.4209 221.826 78.4291 222.591 78.4287 222.605C78.4294 222.88 78.6536 223.104 78.9294 223.103C79.2051 223.103 79.4286 222.88 79.4286 222.604C79.4286 222.494 79.4098 219.879 77.7821 218.251C77.6389 218.108 77.4243 218.065 77.2372 218.142C77.0506 218.219 76.9286 218.402 76.9286 218.604C76.9286 220.899 75.5423 222.786 75.5282 222.805C75.461 222.895 75.4288 223 75.4288 223.104C75.4288 223.256 75.4981 223.406 75.6289 223.505C75.851 223.669 76.1632 223.624 76.3286 223.404V223.404ZM77.7824 215.957C79.4102 214.33 79.4289 211.714 79.4289 211.604C79.4289 211.328 79.2055 211.105 78.9297 211.105C78.6539 211.104 78.4298 211.328 78.4287 211.604C78.4287 211.617 78.4209 212.383 78.1604 213.284C78.0296 213.174 77.863 213.104 77.6785 213.104C77.617 213.104 77.5579 213.114 77.5003 213.127C77.0417 211.769 76.3714 210.861 76.3286 210.804C76.1628 210.583 75.8496 210.538 75.6289 210.704C75.4974 210.802 75.4288 210.952 75.4288 211.104C75.4288 211.208 75.4613 211.313 75.5289 211.404C75.5427 211.422 76.9286 213.297 76.9286 215.603C76.9286 215.806 77.0506 215.988 77.2372 216.066C77.4246 216.143 77.6392 216.101 77.7824 215.957V215.957Z"
            fill="#65471B"
          />
          <path
            d="M72.3683 223.546C71.2811 224.397 71.0898 225.969 71.9415 227.056C72.7925 228.143 74.3637 228.335 75.4512 227.483C76.5381 226.632 78.1545 222.826 77.8462 222.432C77.5383 222.039 73.4558 222.695 72.3683 223.546V223.546ZM72.3683 210.858C71.2811 210.007 71.0898 208.435 71.9415 207.348C72.7925 206.261 74.3637 206.069 75.4512 206.921C76.5388 207.772 78.1549 211.578 77.8466 211.972C77.5383 212.365 73.4558 211.708 72.3683 210.858V210.858Z"
            fill="#64AADD"
          />
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip8_11596:86091)">
            <path
              d="M215.461 59.1252C213.603 66.0598 206.474 70.1758 199.54 68.3176C192.605 66.4595 188.489 59.3311 190.347 52.3959C192.205 45.4612 199.334 41.3453 206.269 43.2034C213.204 45.0615 217.319 52.1899 215.461 59.1252"
              fill="#FFCB4C"
            />
            <path
              d="M202.566 57.0227C199.118 56.0991 196.94 55.0844 194.258 53.7754C193.646 53.4779 192.356 53.2661 191.845 55.1695C190.826 58.9748 193.923 64.9036 200.016 66.5363C206.11 68.1692 211.757 64.5845 212.777 60.7777C213.287 58.8742 212.064 58.4125 211.384 58.3643C208.409 58.1575 206.014 57.9466 202.566 57.0227V57.0227Z"
              fill="#65471B"
            />
            <path
              d="M193.748 55.6794C193.748 55.6794 196.348 57.3959 202.057 58.925C207.766 60.4552 210.874 60.2683 210.874 60.2683C210.874 60.2683 207.951 63.5639 201.292 61.7796C194.632 59.9956 193.748 55.6794 193.748 55.6794V55.6794Z"
              fill="white"
            />
            <path
              d="M212.275 55.6036C212.211 55.5226 211.195 54.2483 209.471 53.0998C209.512 53.0246 209.548 52.9457 209.571 52.8599C209.64 52.6024 209.605 52.3437 209.5 52.1203C210.855 52.0943 211.926 52.3691 211.945 52.3747C212.329 52.4766 212.726 52.2476 212.828 51.8626C212.93 51.4776 212.703 51.0826 212.318 50.9795C212.164 50.9382 208.508 49.9867 205.628 51.6492C205.375 51.7955 205.235 52.0789 205.273 52.3688C205.311 52.6581 205.52 52.8966 205.803 52.9722C209.004 53.83 211.118 56.4696 211.14 56.4965C211.24 56.6238 211.374 56.7079 211.52 56.7468C211.732 56.8036 211.968 56.7632 212.154 56.6175C212.467 56.3693 212.521 55.9169 212.275 55.6036V55.6036ZM202.429 50.7913C200.766 47.9117 197.124 46.9078 196.97 46.8666C196.586 46.7635 196.19 46.9917 196.087 47.3765C195.983 47.761 196.211 48.1572 196.596 48.262C196.615 48.267 197.68 48.5641 198.84 49.2646C198.637 49.4059 198.478 49.6122 198.409 49.8697C198.386 49.9555 198.377 50.0414 198.375 50.127C196.309 50.2591 194.79 50.8547 194.695 50.8931C194.325 51.0418 194.145 51.4622 194.294 51.8319C194.382 52.0522 194.565 52.2039 194.777 52.2607C194.922 52.2996 195.082 52.2936 195.233 52.2331C195.264 52.2208 198.397 50.988 201.616 51.8503C201.898 51.9259 202.197 51.8238 202.376 51.5924C202.554 51.36 202.575 51.0446 202.429 50.7913V50.7913Z"
              fill="#65471B"
            />
            <path
              d="M210.993 61.1823C211.773 63.0173 213.895 63.8717 215.73 63.0898C217.565 62.3089 218.42 60.1884 217.638 58.3528C216.857 56.5181 212.151 52.8401 211.487 53.123C210.822 53.4055 210.212 59.347 210.993 61.1823V61.1823ZM193.289 56.4388C191.696 57.6375 189.432 57.3169 188.233 55.7221C187.034 54.1283 187.354 51.8645 188.949 50.6654C190.543 49.4664 196.458 48.6344 196.892 49.2118C197.325 49.7889 194.883 55.2395 193.289 56.4388V56.4388Z"
              fill="#64AADD"
            />
          </g>
        </g>
        <g clipPath="url(#clip9_11596:86091)">
          <path
            d="M69.9323 69.3806C73.7629 69.9004 76.4474 73.4276 75.9276 77.2583C75.4078 81.0889 71.8809 83.7731 68.0499 83.2536C64.2193 82.7338 61.5349 79.2066 62.0546 75.3759C62.5744 71.5453 66.1013 68.8611 69.9323 69.3806"
            fill="#FFCB4C"
          />
          <path
            d="M69.6883 76.412C69.43 78.3161 69.0365 79.549 68.5201 81.0704C68.403 81.4181 68.3778 82.1214 69.4293 82.2644C71.5313 82.5496 74.4869 80.4908 74.9436 77.125C75.4003 73.7587 73.1007 70.9869 70.9979 70.7018C69.9464 70.5589 69.7832 71.2441 69.8037 71.6101C69.8961 73.2136 69.9468 74.5073 69.6883 76.412V76.412Z"
            fill="#65471B"
          />
          <path
            d="M69.5717 81.2133C69.5717 81.2133 70.3113 79.7077 70.7389 76.5541C71.1671 73.4006 70.8553 71.753 70.8553 71.753C70.8553 71.753 72.8149 73.0898 72.3158 76.7684C71.8169 80.4473 69.5717 81.2133 69.5717 81.2133V81.2133Z"
            fill="white"
          />
          <path
            d="M68.2685 71.3227C68.2295 71.3621 67.6182 71.9921 67.1222 72.9907C67.0792 72.974 67.0347 72.9605 66.9873 72.9541C66.8451 72.9348 66.7092 72.9707 66.5971 73.0424C66.4908 72.3203 66.5646 71.7295 66.5663 71.7189C66.5945 71.5069 66.4452 71.3107 66.2326 71.2824C66.02 71.2541 65.8245 71.4024 65.7956 71.6149C65.7841 71.6999 65.5251 73.7175 66.6094 75.1422C66.7048 75.2676 66.8657 75.323 67.0179 75.2829C67.1699 75.243 67.283 75.1149 67.3041 74.959C67.5441 73.1906 68.8098 71.8813 68.8227 71.868C68.8839 71.8058 68.9197 71.7283 68.9305 71.6482C68.9464 71.531 68.9088 71.4077 68.8182 71.3182C68.6644 71.168 68.419 71.17 68.2685 71.3227V71.3227ZM66.3693 76.9098C64.9445 77.9942 64.6566 80.0078 64.645 80.0928C64.6162 80.3054 64.765 80.5009 64.9776 80.5298C65.1901 80.5592 65.3862 80.4104 65.4159 80.1977C65.4173 80.1873 65.5034 79.5979 65.7984 78.9306C65.8877 79.0293 66.0088 79.1004 66.151 79.1197C66.1984 79.1261 66.2449 79.125 66.2908 79.1204C66.5022 80.2149 66.9238 80.9853 66.9508 81.0337C67.0555 81.2213 67.2923 81.2884 67.4796 81.184C67.5913 81.122 67.6598 81.0136 67.6757 80.8964C67.6866 80.8163 67.6725 80.7317 67.6299 80.6552C67.6212 80.6393 66.7491 79.0496 66.9903 77.2719C67.0115 77.116 66.9365 76.9629 66.8008 76.8835C66.6645 76.8039 66.4946 76.8145 66.3693 76.9098V76.9098Z"
            fill="#65471B"
          />
          <path
            d="M71.3355 71.6275C72.2624 71.0853 72.5742 69.8941 72.0315 68.9671C71.4893 68.0402 70.2984 67.7282 69.3712 68.2707C68.4445 68.8132 66.8007 71.5772 66.9971 71.913C67.1933 72.2485 70.4084 72.1694 71.3355 71.6275V71.6275ZM70.0087 81.4066C70.7576 82.1762 70.7407 83.4074 69.9705 84.1562C69.201 84.9051 67.97 84.8885 67.2208 84.1186C66.4717 83.3488 65.6242 80.2464 65.903 79.9751C66.1817 79.7043 69.2595 80.6373 70.0087 81.4066V81.4066Z"
            fill="#64AADD"
          />
        </g>
        <g clipPath="url(#clip10_11596:86091)">
          <path
            d="M10.3461 166.352C11.2037 163.152 14.494 161.252 17.6946 162.11C20.8952 162.967 22.7947 166.257 21.9373 169.458C21.0797 172.659 17.7894 174.558 14.5888 173.701C11.3882 172.843 9.48866 169.553 10.3461 166.352"
            fill="#FFCB4C"
          />
          <path
            d="M16.2982 167.323C17.8891 167.749 18.8947 168.217 20.1322 168.822C20.4151 168.959 21.0104 169.057 21.2461 168.178C21.7167 166.422 20.2869 163.686 17.4747 162.932C14.6621 162.178 12.0558 163.833 11.5852 165.59C11.3496 166.468 11.9145 166.681 12.228 166.704C13.6013 166.799 14.7068 166.896 16.2982 167.323V167.323Z"
            fill="#65471B"
          />
          <path
            d="M20.3676 167.943C20.3676 167.943 19.1677 167.151 16.5328 166.445C13.898 165.739 12.4633 165.825 12.4633 165.825C12.4633 165.825 13.8125 164.304 16.8861 165.128C19.9599 165.951 20.3676 167.943 20.3676 167.943V167.943Z"
            fill="white"
          />
          <path
            d="M11.8169 167.978C11.8462 168.015 12.3154 168.603 13.1107 169.133C13.0919 169.168 13.0755 169.205 13.0649 169.244C13.0331 169.363 13.0489 169.482 13.0976 169.586C12.4722 169.598 11.9779 169.471 11.9691 169.468C11.7918 169.421 11.6088 169.527 11.5617 169.704C11.5145 169.882 11.6194 170.064 11.797 170.112C11.868 170.131 13.5553 170.57 14.8844 169.803C15.0013 169.735 15.0659 169.605 15.0483 169.471C15.0309 169.337 14.9342 169.227 14.804 169.192C13.3264 168.796 12.3505 167.578 12.3407 167.566C12.2944 167.507 12.2324 167.468 12.1654 167.45C12.0675 167.424 11.9586 167.443 11.8727 167.51C11.7283 167.624 11.7034 167.833 11.8169 167.978V167.978ZM16.3612 170.199C17.1286 171.528 18.8094 171.991 18.8805 172.01C19.058 172.058 19.2405 171.953 19.2881 171.775C19.3361 171.597 19.2309 171.415 19.0533 171.366C19.0446 171.364 18.5528 171.227 18.0174 170.904C18.1111 170.838 18.1846 170.743 18.2165 170.624C18.2271 170.585 18.2311 170.545 18.2322 170.505C19.1858 170.445 19.8866 170.17 19.9306 170.152C20.1015 170.083 20.1843 169.889 20.1158 169.719C20.0752 169.617 19.9905 169.547 19.8926 169.521C19.8257 169.503 19.7522 169.506 19.6825 169.533C19.668 169.539 18.2218 170.108 16.7365 169.71C16.6062 169.675 16.4679 169.722 16.3856 169.829C16.3032 169.936 16.2937 170.082 16.3612 170.199V170.199Z"
            fill="#65471B"
          />
          <path
            d="M12.4087 165.403C12.0483 164.556 11.0693 164.162 10.2223 164.523C9.37533 164.883 8.98083 165.862 9.34141 166.709C9.70211 167.556 11.8739 169.253 12.1807 169.123C12.4872 168.992 12.7688 166.25 12.4087 165.403V165.403ZM20.5794 167.592C21.315 167.039 22.36 167.187 22.9131 167.923C23.4663 168.659 23.3186 169.704 22.5827 170.257C21.8469 170.81 19.1171 171.194 18.9167 170.928C18.7167 170.661 19.8439 168.146 20.5794 167.592V167.592Z"
            fill="#64AADD"
          />
        </g>
        <g clipPath="url(#clip11_11596:86091)">
          <path
            d="M213.206 181.028C218.453 181.028 222.706 185.281 222.706 190.528C222.706 195.774 218.453 200.028 213.206 200.028C207.96 200.028 203.706 195.774 203.706 190.528C203.706 185.281 207.96 181.028 213.206 181.028"
            fill="#FFCB4C"
          />
          <path
            d="M214.161 190.528C214.161 193.136 213.857 194.866 213.44 197.006C213.346 197.495 213.441 198.445 214.881 198.446C217.76 198.446 221.359 195.138 221.359 190.528C221.359 185.918 217.76 182.61 214.88 182.61C213.44 182.61 213.346 183.561 213.44 184.049C213.857 186.189 214.161 187.919 214.161 190.528V190.528Z"
            fill="#65471B"
          />
          <path
            d="M214.881 197.006C214.881 197.006 215.601 194.846 215.6 190.527C215.601 186.208 214.881 184.05 214.881 184.05C214.881 184.05 217.76 185.49 217.76 190.528C217.76 195.566 214.881 197.006 214.881 197.006V197.006Z"
            fill="white"
          />
          <path
            d="M211.323 183.943C211.278 184.003 210.571 184.962 210.086 186.395C210.025 186.381 209.963 186.371 209.898 186.371C209.703 186.371 209.527 186.444 209.389 186.561C209.115 185.609 209.106 184.801 209.106 184.787C209.106 184.496 208.869 184.26 208.578 184.26C208.287 184.261 208.051 184.496 208.051 184.787C208.051 184.904 208.071 187.664 209.789 189.382C209.94 189.534 210.167 189.579 210.364 189.497C210.561 189.416 210.69 189.223 210.69 189.009C210.69 186.587 212.153 184.596 212.168 184.575C212.239 184.481 212.273 184.37 212.273 184.26C212.273 184.1 212.2 183.941 212.062 183.837C211.827 183.663 211.498 183.71 211.323 183.943V183.943ZM209.789 191.803C208.07 193.522 208.051 196.282 208.051 196.399C208.051 196.69 208.287 196.925 208.578 196.925C208.869 196.926 209.105 196.69 209.106 196.399C209.106 196.385 209.115 195.576 209.39 194.625C209.528 194.742 209.704 194.815 209.898 194.815C209.963 194.815 210.026 194.805 210.086 194.79C210.571 196.224 211.278 197.183 211.323 197.243C211.498 197.476 211.829 197.523 212.062 197.349C212.201 197.245 212.273 197.087 212.273 196.926C212.273 196.817 212.239 196.705 212.167 196.61C212.153 196.59 210.69 194.612 210.69 192.177C210.69 191.963 210.561 191.771 210.364 191.689C210.166 191.607 209.94 191.652 209.789 191.803V191.803Z"
            fill="#65471B"
          />
          <path
            d="M215.504 183.793C216.651 182.895 216.853 181.236 215.954 180.088C215.056 178.941 213.397 178.739 212.249 179.637C211.102 180.536 209.396 184.553 209.721 184.969C210.046 185.384 214.356 184.691 215.504 183.793V183.793ZM215.504 197.186C216.651 198.085 216.853 199.743 215.954 200.891C215.056 202.039 213.397 202.241 212.249 201.342C211.101 200.444 209.396 196.426 209.721 196.01C210.046 195.595 214.356 196.288 215.504 197.186V197.186Z"
            fill="#64AADD"
          />
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip12_11596:86091)">
            <path
              d="M107.405 176.632C106.404 172.898 108.621 169.059 112.355 168.059C116.089 167.058 119.927 169.274 120.928 173.008C121.928 176.742 119.712 180.581 115.978 181.582C112.244 182.582 108.406 180.366 107.405 176.632"
              fill="#FFCB4C"
            />
            <path
              d="M113.985 174.14C115.841 173.643 117.13 173.53 118.733 173.418C119.099 173.392 119.757 173.143 119.483 172.118C118.934 170.069 115.893 168.138 112.612 169.018C109.331 169.897 107.662 173.089 108.212 175.138C108.486 176.164 109.181 176.049 109.511 175.889C110.954 175.184 112.128 174.638 113.985 174.14V174.14Z"
              fill="#65471B"
            />
            <path
              d="M118.458 172.393C118.458 172.393 116.783 172.292 113.71 173.116C110.636 173.94 109.236 174.864 109.236 174.864C109.236 174.864 109.712 172.54 113.298 171.579C116.884 170.618 118.458 172.393 118.458 172.393V172.393Z"
              fill="white"
            />
            <path
              d="M109.839 177.416C109.89 177.437 110.708 177.757 111.82 177.829C111.822 177.875 111.826 177.921 111.839 177.968C111.876 178.106 111.961 178.218 112.071 178.293C111.446 178.67 110.872 178.831 110.862 178.833C110.656 178.889 110.532 179.103 110.588 179.31C110.644 179.517 110.857 179.64 111.064 179.584C111.147 179.562 113.108 179.021 114.003 177.471C114.082 177.334 114.071 177.165 113.975 177.04C113.879 176.915 113.717 176.86 113.566 176.901C111.842 177.363 110.145 176.701 110.128 176.694C110.047 176.662 109.961 176.659 109.883 176.68C109.769 176.71 109.67 176.793 109.622 176.911C109.543 177.111 109.64 177.336 109.839 177.416V177.416ZM115.726 177.009C117.277 177.905 119.245 177.392 119.328 177.37C119.535 177.315 119.658 177.102 119.603 176.895C119.548 176.687 119.335 176.564 119.127 176.619C119.117 176.621 118.54 176.77 117.81 176.755C117.867 176.635 117.886 176.496 117.849 176.357C117.836 176.311 117.817 176.268 117.795 176.228C118.723 175.61 119.271 174.923 119.305 174.88C119.438 174.711 119.408 174.466 119.239 174.334C119.139 174.255 119.013 174.234 118.898 174.264C118.82 174.285 118.748 174.331 118.694 174.4C118.682 174.414 117.553 175.832 115.82 176.297C115.668 176.337 115.556 176.466 115.535 176.622C115.514 176.778 115.59 176.931 115.726 177.009V177.009Z"
              fill="#65471B"
            />
            <path
              d="M108.935 174.469C108.077 173.824 106.858 173.997 106.212 174.855C105.567 175.714 105.739 176.932 106.598 177.578C107.456 178.223 110.641 178.672 110.874 178.361C111.108 178.05 109.793 175.115 108.935 174.469V174.469ZM118.467 171.915C118.888 170.927 120.03 170.467 121.018 170.888C122.006 171.309 122.467 172.45 122.046 173.439C121.625 174.427 119.091 176.408 118.733 176.255C118.376 176.103 118.047 172.903 118.467 171.915V171.915Z"
              fill="#64AADD"
            />
          </g>
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip13_11596:86091)">
            <path
              d="M1.75749 102.116C4.10049 99.7728 7.89983 99.7728 10.2428 102.116C12.5858 104.459 12.5858 108.258 10.2428 110.601C7.89983 112.944 4.10049 112.944 1.75749 110.601C-0.585506 108.258 -0.585506 104.459 1.75749 102.116"
              fill="#FFCB4C"
            />
            <path
              d="M6.42671 105.932C7.59138 107.097 8.22804 108.005 8.99771 109.147C9.17404 109.408 9.64071 109.79 10.284 109.147C11.5697 107.861 11.6997 104.777 9.64104 102.718C7.58204 100.659 4.49771 100.789 3.21171 102.075C2.56837 102.718 2.95104 103.185 3.21137 103.361C4.35304 104.13 5.26171 104.767 6.42671 105.932V105.932Z"
              fill="#65471B"
            />
            <path
              d="M9.64107 108.504C9.64107 108.504 8.99807 107.218 7.06907 105.289C5.1404 103.36 3.85474 102.718 3.85474 102.718C3.85474 102.718 5.78374 102.075 8.03374 104.325C10.2841 106.575 9.64107 108.504 9.64107 108.504V108.504Z"
              fill="white"
            />
            <path
              d="M2.21833 104.259C2.225 104.306 2.33733 105.05 2.761 105.907C2.72733 105.927 2.695 105.951 2.666 105.98C2.579 106.067 2.533 106.178 2.52366 106.292C1.976 105.989 1.61133 105.632 1.605 105.626C1.475 105.496 1.26366 105.496 1.134 105.627C1.00433 105.757 1.004 105.967 1.134 106.097C1.186 106.149 2.42766 107.373 3.96233 107.373C4.09733 107.373 4.21866 107.292 4.27033 107.168C4.322 107.043 4.29333 106.9 4.198 106.804C3.11633 105.723 2.88033 104.18 2.878 104.164C2.86733 104.09 2.833 104.025 2.784 103.976C2.71233 103.905 2.60866 103.866 2.50066 103.882C2.31833 103.909 2.19233 104.077 2.21833 104.259V104.259ZM5.04333 108.455C5.04333 109.989 6.26733 111.231 6.31933 111.283C6.44933 111.413 6.66 111.413 6.79 111.283C6.92033 111.153 6.92066 110.942 6.791 110.812C6.78466 110.805 6.42733 110.441 6.12533 109.893C6.239 109.883 6.35033 109.838 6.43733 109.751C6.46633 109.722 6.48966 109.689 6.51033 109.656C7.36666 110.08 8.111 110.192 8.158 110.199C8.34033 110.225 8.509 110.098 8.535 109.916C8.55066 109.808 8.51233 109.705 8.44066 109.633C8.39166 109.584 8.32666 109.55 8.25233 109.539C8.237 109.537 6.7 109.306 5.61266 108.219C5.51733 108.124 5.374 108.095 5.24933 108.147C5.12433 108.198 5.04333 108.32 5.04333 108.455V108.455Z"
              fill="#65471B"
            />
            <path
              d="M4.01833 102.325C4.12966 101.411 3.47899 100.58 2.56499 100.469C1.65133 100.358 0.820326 101.008 0.708992 101.922C0.597992 102.836 1.62999 105.392 1.96099 105.432C2.29166 105.473 3.90666 103.239 4.01833 102.325V102.325ZM9.99966 108.306C10.9133 108.195 11.7443 108.846 11.8553 109.76C11.9667 110.673 11.3163 111.504 10.4023 111.616C9.48833 111.727 6.93233 110.695 6.89199 110.364C6.85199 110.033 9.08599 108.418 9.99966 108.306V108.306Z"
              fill="#64AADD"
            />
          </g>
        </g>
        <g clipPath="url(#clip14_11596:86091)">
          <path
            d="M247.819 115.438C244.695 118.562 239.63 118.562 236.506 115.438C233.382 112.314 233.382 107.248 236.506 104.124C239.63 101 244.695 101 247.819 104.124C250.943 107.248 250.943 112.313 247.819 115.438"
            fill="#FFCB4C"
          />
          <path
            d="M241.594 110.349C240.041 108.796 239.192 107.585 238.166 106.062C237.931 105.715 237.308 105.205 236.451 106.063C234.736 107.777 234.563 111.89 237.308 114.635C240.053 117.38 244.166 117.207 245.88 115.492C246.738 114.635 246.228 114.012 245.881 113.777C244.359 112.751 243.147 111.902 241.594 110.349V110.349Z"
            fill="#65471B"
          />
          <path
            d="M237.308 106.92C237.308 106.92 238.165 108.635 240.737 111.206C243.309 113.778 245.023 114.635 245.023 114.635C245.023 114.635 242.451 115.492 239.451 112.492C236.451 109.492 237.308 106.92 237.308 106.92V106.92Z"
            fill="white"
          />
          <path
            d="M247.205 112.58C247.196 112.517 247.046 111.525 246.481 110.383C246.526 110.356 246.569 110.324 246.608 110.286C246.724 110.17 246.785 110.021 246.798 109.87C247.528 110.273 248.014 110.749 248.023 110.758C248.196 110.93 248.478 110.93 248.651 110.756C248.824 110.583 248.824 110.302 248.651 110.129C248.581 110.06 246.926 108.428 244.88 108.428C244.7 108.428 244.538 108.536 244.469 108.702C244.4 108.868 244.438 109.059 244.565 109.186C246.008 110.628 246.322 112.686 246.325 112.707C246.34 112.805 246.385 112.892 246.451 112.957C246.546 113.052 246.684 113.104 246.828 113.083C247.072 113.047 247.24 112.823 247.205 112.58V112.58ZM243.438 106.986C243.438 104.94 241.806 103.284 241.737 103.215C241.564 103.041 241.283 103.041 241.109 103.215C240.936 103.388 240.935 103.669 241.108 103.843C241.116 103.852 241.593 104.338 241.996 105.068C241.844 105.081 241.696 105.142 241.58 105.258C241.541 105.296 241.51 105.34 241.482 105.384C240.34 104.819 239.348 104.669 239.285 104.66C239.042 104.626 238.817 104.795 238.783 105.037C238.762 105.182 238.813 105.319 238.908 105.415C238.974 105.48 239.06 105.526 239.16 105.54C239.18 105.543 241.229 105.85 242.679 107.3C242.806 107.427 242.997 107.465 243.164 107.396C243.33 107.328 243.438 107.166 243.438 106.986V106.986Z"
            fill="#65471B"
          />
          <path
            d="M244.805 115.159C244.657 116.377 245.524 117.485 246.743 117.633C247.961 117.781 249.069 116.914 249.217 115.696C249.365 114.477 247.989 111.069 247.548 111.016C247.107 110.962 244.954 113.94 244.805 115.159V115.159ZM236.83 107.184C235.612 107.332 234.504 106.464 234.356 105.246C234.207 104.028 235.074 102.92 236.293 102.771C237.512 102.623 240.92 103.999 240.973 104.44C241.027 104.881 238.048 107.035 236.83 107.184V107.184Z"
            fill="#64AADD"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_11596:86091"
          x={92.1455}
          y={90.1455}
          width={65.1829}
          height={65.1829}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11596:86091"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11596:86091"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_11596:86091">
          <rect
            width={18.8694}
            height={17.7258}
            fill="white"
            transform="translate(139.233 3.43091)"
          />
        </clipPath>
        <clipPath id="clip1_11596:86091">
          <rect
            width={30}
            height={30}
            fill="white"
            transform="translate(159.228 212.024) rotate(120)"
          />
        </clipPath>
        <clipPath id="clip2_11596:86091">
          <rect
            width={26}
            height={26}
            fill="white"
            transform="translate(32.3066 142.934) rotate(-60)"
          />
        </clipPath>
        <clipPath id="clip3_11596:86091">
          <rect
            width={26}
            height={26}
            fill="white"
            transform="translate(163.865 145.292) rotate(-120)"
          />
        </clipPath>
        <clipPath id="clip4_11596:86091">
          <rect
            width={28}
            height={28}
            fill="white"
            transform="translate(65.5386) rotate(57.9222)"
          />
        </clipPath>
        <clipPath id="clip5_11596:86091">
          <rect
            width={27}
            height={27}
            fill="white"
            transform="translate(137.491 82.7673) rotate(-119.734)"
          />
        </clipPath>
        <clipPath id="clip6_11596:86091">
          <rect
            width={19}
            height={19}
            fill="white"
            transform="translate(5.35938 60.1575) rotate(-45)"
          />
        </clipPath>
        <clipPath id="clip7_11596:86091">
          <rect
            width={18}
            height={18}
            fill="white"
            transform="translate(87.2725 217.166) rotate(135)"
          />
        </clipPath>
        <clipPath id="clip8_11596:86091">
          <rect
            width={26}
            height={26}
            fill="white"
            transform="translate(207.663 38.0022) rotate(60)"
          />
        </clipPath>
        <clipPath id="clip9_11596:86091">
          <rect
            width={14}
            height={14}
            fill="white"
            transform="translate(59.1814 74.9861) rotate(-37.273)"
          />
        </clipPath>
        <clipPath id="clip10_11596:86091">
          <rect
            width={12}
            height={12}
            fill="white"
            transform="translate(13.9456 176.102) rotate(-120)"
          />
        </clipPath>
        <clipPath id="clip11_11596:86091">
          <rect
            width={19}
            height={19}
            fill="white"
            transform="translate(199.771 190.528) rotate(-45)"
          />
        </clipPath>
        <clipPath id="clip12_11596:86091">
          <rect
            width={14}
            height={14}
            fill="white"
            transform="translate(116.729 184.382) rotate(-150)"
          />
        </clipPath>
        <clipPath id="clip13_11596:86091">
          <rect
            width={12}
            height={12}
            fill="white"
            transform="translate(0 112.359) rotate(-90)"
          />
        </clipPath>
        <clipPath id="clip14_11596:86091">
          <rect
            width={16}
            height={16}
            fill="white"
            transform="translate(250.163 101.781) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
