import { INIT_DEBUGGER, TRACE, TRACE_GROUP, TRACE_GROUP_END } from '../../core/debug/debugMacros';

import { action, computed, makeObservable, observable } from 'mobx';

import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { JobLiveAggregateStatus, JobModel, MomentModel, SyncStatus } from '../../entities';
import { MomentSelector } from '../../entities/moments';
import { PlayerState } from '../../components';
import { AsyncResult, MaybeProps, Result } from '../../core';
import { Routes } from '../../routes';
import { Error, pageError } from '../../core/error';
import { UserPlayerPageParams } from './userPlayerPageSchema';
import { PlayerIndexState } from '../../components/playerIndex/playerIndexState';
import { PlayerTranscriptsState } from '../../components/playerTranscripts/playerTranscriptsState';
import { PlayerFramesetState } from '../../components/playerFrameset/playerFramesetState';
import { ClipWindowState } from '../../components/momentWindow/clipWindowState';
import { PlayerCommentsState } from '../../components/playerComments/playerCommentsState';
import { LiveReactionsController, PlayerReactionsState } from '../../components/playerReactions';
import { PlayerSectionName } from '../../components/playerFrameset/playerFramesetSchema';
import { AuthService } from '../../services/auth';
import { PlayerTutorialController } from '../../components/playerTutorial/playerTutorialController';
import { batchApiResultIsAborted, getApiResultJobError, apiResultIsAborted } from '../../api';
import { JobLiveStatusMonitor } from '../../entities/jobLiveStatusMonitor';
import { PlayerApiClient } from '../../components/player/playerApiClient';
import { notifyError } from '../../services/notifications';
import { PlayerSpeakerIdController } from '../../components/playerSpeakers/playerSpeakerIdController';
import { readParamsFromQueryString } from '../../entities/params/paramsUtils';
import { WidgetParams, WidgetParamsSchema } from '../../services/widget/widgetParams';

type Props = MaybeProps<{
  suppressApiQueries: boolean,
  suppressTutorial: boolean
}>;

export enum SectionStatus {
  Empty = 'Empty',
  Loading = 'Loading',
  Error = 'Error',
  NotEmpty = 'NotEmpty'
}


export class UserPlayerPageState
  extends StoreNode {

  readonly nodeType: 'UserPlayerPage' = 'UserPlayerPage';

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    INIT_DEBUGGER(this, {
      color: 'orange'
    });

    this.auth = store.auth;

    this.defaultSelector = new MomentSelector(store, {
      jobId: () => this.jobId
    });

    this.transcriptsSection = new PlayerTranscriptsState(this.store, {
      jobId: () => this.jobId,
      player: () => this.player,
      frameset: () => this.frameset,
      teamId: () => this.teamId
    });

    this.player = new PlayerState(store, {
      playerTutorialHighlightedComponents: () => this.playerTutorialHighlightedComponents,
      jobId: () => this.jobId,
      momentSelector: () => this.selector,
      frameset: () => this.frameset,
      teamId: () => this.teamId,
      autoplay: () => !this.showPlayerTutorial,
      momentSource: 'User',
      speakerIdMode: () => this.isSpeakerIdMode
    });

    this.playerTutorialController = new PlayerTutorialController(this.store, {
      player: () => this.player,
      frameset: () => this.frameset,
      chrome: () => this.player.chrome,
      job: () => this.job
    });

    this.frameset = new PlayerFramesetState(this.store, {
      widgetMode: false,
      tutorialMode: () => this.player.tutorialMode,
      getSectionAvailability: () => this.getSectionAvailability,
      getSectionStatus: () => this.getSectionStatus
    });

    this.indexSection = new PlayerIndexState(this.store, {
      playerTutorialHighlightedComponents: () => this.playerTutorialHighlightedComponents,
      jobId: () => this.jobId,
      player: () => this.player,
      frameset: () => this.frameset,
      teamId: () => this.teamId,
      speakerIdController: () => this.playerSpeakerIdController
    });

    this.playerComments = new PlayerCommentsState(this.store, {
      jobId: () => this.jobId,
      player: () => this.player,
      frameset: () => this.frameset
    });

    this.reactions = new PlayerReactionsState(this.store, {
      playerTutorialHighlightedComponents: () => this.playerTutorialHighlightedComponents,
      jobId: () => this.jobId,
      player: () => this.player
    });

    this.playerSpeakerIdController = new PlayerSpeakerIdController(this.store, {
      player: () => this.player,
      frameset: () => this.frameset,
      indexSection: () => this.indexSection,
      momentSelector: () => this.selector
    });

    this.player.listen(
      this.playerListener);

    this.jobLiveStatusMonitor.listen(
      this.jobLiveStatusMonitorListener);
  }

  readonly auth: AuthService;
  readonly defaultSelector: MomentSelector;
  readonly player: PlayerState;
  readonly transcriptsSection: PlayerTranscriptsState;
  readonly playerTutorialController: PlayerTutorialController;
  readonly frameset: PlayerFramesetState;
  readonly indexSection: PlayerIndexState;
  readonly playerComments: PlayerCommentsState;
  readonly reactions: PlayerReactionsState;
  readonly playerSpeakerIdController: PlayerSpeakerIdController;

  readonly localApiClient = new PlayerApiClient(this.store);

  @computed
  get selector(): MomentSelector {
    const propSelector = this.getResolvedProp('selector');
    if (!propSelector)
      return this.defaultSelector;

    return propSelector;
  }

  @computed get suppressApiQueries(): boolean {
    return this.getResolvedProp('suppressApiQueries', false);
  }

  @computed get suppressTutorial(): boolean {
    return this.getResolvedProp('suppressTutorial', false);
  }

  // @computed get suppressSpeakerId(): boolean {
  //   return this.getResolvedProp('suppressSpeakerId', false);
  // }

  @computed
  get isAdPlaybackMode(): boolean {
    return this.player.isAdPlaybackMode;
  }

  @computed
  get isAdsAdapterActive(): boolean {
    return this.player.isAdsAdapterActive;
  }

  @computed
  get isSpeakerIdMode(): boolean {
    return this.playerSpeakerIdController.isSpeakerIdMode;
  }

  @computed get showTutorialButton() {
    // no help button on ad playback because it would be very easy
    // to mess everything up if you open the tutorial during ad playback
    if (!this.job || this.isAdsAdapterActive)
      return false;

    const syncStatuses = new Set([SyncStatus.Fetched, SyncStatus.Empty]);

    return (
      syncStatuses.has(this.job?.momentsSyncStatus) &&
      syncStatuses.has(this.job?.commentsSyncStatus));
  }

  @computed get showPlayerTutorial() {
    return (
      !this.suppressTutorial &&
      !!this.auth?.userProfile?.showPlayerTutorial &&
      !!this.job?.isMediaDone &&
      // deactivate tutorial run for any mobile device
      !this.store.uiService.isMobile &&
      // no tutorial on videos with ads because we don't have enough time 
      // to properly correlate between everything
      !this.player.adsAdapter.adTagUrl)
  }

  @observable isMounted = false;
  @observable isLoading = true; // init as true to avoid glitches if a component takes to long to render (ex: comments)
  @observable error: Error | null = null;
  @observable playerTutorialHighlightedComponents: string[] = [];
  @observable jobId: string | null = null;

  @observable.shallow params: UserPlayerPageParams | null = null;
  @observable.shallow queryParams: WidgetParams | null = null;

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed get teamId(): string | null {
    return this.queryParams?.teamId ?? null;
  }

  readonly jobLiveStatusMonitor = new JobLiveStatusMonitor(this.store, {
    jobId: () => this.jobId
  });

  readonly liveReactionsController = new LiveReactionsController(this.store, {
    jobId: () => this.jobId
  })

  private abortController: AbortController | null = null;

  @action
  private async load(reattach: boolean = false) {
    this.abortLoading();
    this.setLoading();

    const {
      store,
      params,
      queryParams,
      jobId
    } = this;

    if (!params || !queryParams || !jobId || !this.isMounted)
      return this.setError(new Error('InternalError'));

    const abortCtrl = new AbortController();
    this.abortController = abortCtrl;

    const useApi = !this.suppressApiQueries;
    const reqOpts = {
      signal: abortCtrl.signal
    };

    if (useApi) {
      // To be transformed in single request
      const requests: AsyncResult[] = [
        store.apiFetchJob(jobId, false, reqOpts),
      ];

      if (this.teamId) {
        requests.push(store.teamManager.apiFetchTeam({
          id: this.teamId
        }));
      }

      const response: Result[] = await Promise.all(requests);
      // if one of the requests is aborted, just stop and reset loading
      if (batchApiResultIsAborted(response))
        return this.setLoadAborted();

      const err = getApiResultJobError(response);
      if (err)
        return this.setError(err);

      // no error checking here since ads are optional
      await this.player.loadAdTagUrl(reqOpts);
    }

    const { job, player, selector } = this;

    if (!job)
      return this.setError(new Error('InternalError', `The job could not be retrieved from the store`));

    if (!job.isPublished)
      return this.setError(new Error('JobUnavailable'));

    // Fetch current moment
    const momentId = queryParams.momentId;
    let selectedMoment;

    if (useApi) {
      if (momentId) {
        const [moment,] = await job.apiFetchMoment(momentId, reqOpts);

        if (moment) {
          selectedMoment = moment.getMoment;
        }
      }
    }

    // console.log('LiveStatusMonitor.Change', {
    //   job: this.job,
    //   jobId: this.jobId,
    //   liveStatus: this.job?.liveStatus,
    //   status: this.job?.testStatus
    // });

    // if the job is a live job start a polling routine
    // for checking when the job's state changes, until it ends
    if (job.isLive && job.liveAggregateStatus !== JobLiveAggregateStatus.ProcessingDone)
      this.jobLiveStatusMonitor.start();

    if (reattach) {
      player.invoke('reattach');
    }

    await this.localApiClient.subscribeToAddReactions({
      jobId: this.jobId
    });

    this.frameset.init();
    this.playerSpeakerIdController.init();
    this.setLoaded();

    const startPromise = player.start();

    if (selectedMoment) {
      const moment = new MomentModel(selectedMoment, store);
      selector.enterVideoMode();
      selector.setHighlightedMoment(moment);
      player.invoke('jumpToMoment', { 
        moment, 
        setAsPrincipal: true 
      });
    }

    let depsPromise = null;
    if (useApi) {
      let requests = [
        this.fetchBookmarks(jobId, reqOpts),
        this.fetchMomentsAndDependecies(job, reqOpts),
      ];

      // Load comments and reactions
      if (!job.isLiveStreaming && useApi) {
        requests = [
          ...requests,
          this.fetchReactionsAndComments(job, reqOpts)]
      }

      depsPromise = Promise.all(requests);
    }

    await startPromise;

    if (depsPromise)
      await depsPromise;

    this.broadcast('videoLoaded', {
      jobId,
      description: this.job?.title,
      path: Routes.userVideo(jobId)
    });

    store.clipWindow.listen(
      this.clipWindowListener);

    this.selector.load();
    this.selector.exitVideoMode();

    if (this.showPlayerTutorial) {
      this.playerTutorialController.openPlayerTutorial('openPlayerTutorialConfirmationWindow');
      this.subscribeToTutorialController();
    }
  }

  @action
  async fetchReactionsAndComments(job: JobModel, reqOpts?: any) {
    await Promise.allSettled([
      job.fetchComments(reqOpts),
      job.fetchReactions(reqOpts)
    ]);
  }

  @action
  async fetchBookmarks(jobId: string, reqOpts: any) {
    const result = await Promise.all([
      this.store.apiFetchJobRelatedBookmarks({ jobId, first: 100 }, reqOpts),
      this.store.apiFetchBookmarkLists(reqOpts)]);

    if (batchApiResultIsAborted(result))
      return;

    const [[, err1], [, err2]] = result;
    if (err1 || err2) {
      notifyError(this, `Could not fetch bookmarks because of an error.`);
    }
  }

  @action
  async fetchMomentsAndDependecies(job: JobModel, reqOpts: any) {
    // Load moments, tracks and speakers
    await job.fetchMoments(reqOpts);
    this.indexSection.scrollToCurrent();

    const result = await job.apiBatchFetchSpeakers(reqOpts);

    if (apiResultIsAborted(result))
      return;

    const [, err2] = result;
    if (err2) {
      console.error(err2);
      notifyError(this, 'Could not fetch speakers because of an error.');
    }
  }

  @action
  private abortLoading() {
    const lastAbortCtrl = this.abortController;
    if (lastAbortCtrl)
      lastAbortCtrl.abort();

    this.jobLiveStatusMonitor.cancel();
  }



  /** Notifies the state that the page has been mounted and triggers the initialization tasks. */
  @action
  async mounted(params: UserPlayerPageParams, queryString: string) {
    TRACE_GROUP(this, `mounted()`);

    const queryParams = readParamsFromQueryString(WidgetParamsSchema, queryString);

    this.setMounted();
    this.emit('Mounted', {
      params,
      queryParams,
      teamId: queryParams.teamId ?? null
    });

    this.store.uiService.setThemeVariation('Inverse');

    this.reset();

    this.params = params;
    this.queryParams = queryParams;

    const { jobId } = params;
    if (!jobId)
      return this.setError(`No 'jobId' param provided.`);

    this.jobId = jobId;

    await this.load();
  }

  /** Notifies the state that the page has been unmounted and triggers the cleanup tasks. */
  @action
  unmounted() {
    TRACE(this, `unmounted()`);

    this.abortLoading();
    this.store.uiService.clearThemeVariation();

    this.dispatch('Overlays', 'closeWindow');
    if (!this.playerSpeakerIdController?.isSpeakerIdMode)
      this.selector.save();
    this.reset();

    this.isLoading = true; //keep loading true when unmounted in order to avoid the glitches caused by the long rendering time of the components

    this.setUnmounted();
    this.emit('Unmounted');

    TRACE_GROUP_END();
  }

  // #region Tutorial
  @action
  openPlayerTutorial(): void {
    this.playerTutorialController.openPlayerTutorial('openPlayerTutorialTopicsWindow');
    this.subscribeToTutorialController();
  }

  @action
  subscribeToTutorialController(): void {
    const playerTutorialControllerListener = (msg: Message<PlayerTutorialController>) => {
      switch (msg.type) {
        case 'show':
          this.showComponentForTutorial(msg.payload);
          break;

        case 'close':
          this.showComponentForTutorial([]);
          this.playerTutorialController.unlisten(playerTutorialControllerListener);
          break;
      }
    }

    this.playerTutorialController.listen(playerTutorialControllerListener);
  }

  // Just temporary until we find a better solution for OverlayState
  @action
  showComponentForTutorial(componentName: string | string[]): void {
    this.playerTutorialHighlightedComponents = [];
    if (!Array.isArray(componentName)) {
      this.playerTutorialHighlightedComponents.push(componentName);
      return;
    }
    this.playerTutorialHighlightedComponents = componentName;
  }
  // #endregion

  private clipWindowListener = (msg: Message<ClipWindowState>) => {
    const { type, payload } = msg;
    switch (type) {
      case 'momentCreated':
      case 'momentUpdated':
        this.selector.handleMomentMutated(payload.momentId);
        break;

      case 'momentDeleted':
        this.selector.handleMomentDeleted(payload.momentId);
        break;

      default: break;
    }
  }

  private playerListener = (msg: Message<PlayerState>) => {
    const { type } = msg;
    switch (type) {
      case 'activeTranscriptChange':
        this.transcriptsSection.scrollToCurrent();
        break;
      case 'activeTopicChange':
        this.indexSection.scrollToCurrent();
        break;
      case 'lastActiveSubtopicChange':
        this.indexSection.scrollToCurrent();
        break;
      case 'activeCommentChange':
        this.playerComments.scrollToCurrent();
        break;
      default: break;
    }
  }

  private jobLiveStatusMonitorListener = async (msg: Message<JobLiveStatusMonitor>) => {
    const { type, payload } = msg;
    const { player } = this;

    switch (type) {
      case 'Change':
        const status = payload?.status;
        const hasUrl = payload?.hasUrl;
        const liveStreamUrlChanged = payload?.liveStreamUrlChanged;
        // console.log('LiveStatusMonitor.Change', {
        //   job: this.job,
        //   jobId: this.jobId,
        //   liveStatus: this.job?.liveStatus,
        //   status: this.job?.testStatus,
        //   jobStatus: status,
        //   isLivePlaybackActive: this.player.isLivePlaybackActive
        // });
        switch (status) {
          case JobLiveAggregateStatus.Streaming:
            if (!hasUrl) { // prepare loading the player only when url is provided
              this.jobLiveStatusMonitor.start();
              return;
            }

            if (player.isPlaying)
              player.invoke('enterPause');

            await this.load(liveStreamUrlChanged); // it triggers a jobLiveStatusMonitor start()

            if (player.wasPlayingBeforeEnterPause)
              player.invoke('exitPause');

            // TODO: for safety I left it like this, but we need to refactor these calls
            // only removed the useless `if` on `DEFAULT_ENABLE_AUTOPLAY` which was always `true`
            // FOR REVIEWERS: please check that the logic that happens within this call 
            // remains unchanged as compared to the version before the ads implementation and autoplay rewiring
            player.invoke('autoplay');
            break;

          default: // waiting or ended (ingest processing, done, failed)
            if (!this.job)
              return;
            const [res, err] = await this.store.apiFetchJob(this.job.id);

            if (!res || err)
              console.warn('Job fetch failed at:', status)

            if (this.job.liveAggregateStatus !== JobLiveAggregateStatus.ProcessingDone)
              this.jobLiveStatusMonitor.start();

            if (this.job.liveAggregateStatus === JobLiveAggregateStatus.ProcessingDone)
              await this.job.fetchReactions();
            break;
        }
        break;
    }
  }

  async handleMomentBookmarkClick(evt: React.MouseEvent, moment: MomentModel) {
    const { store } = this;
    this.player.invoke('pause');
    store.bookmarkWindow.openMoment(moment.id, moment.jobId);
  }

  // #region State helpers
  @action
  reset() {
    TRACE(this, `reset()`);

    this.abortLoading();

    const { store } = this;
    store.clipWindow.unlisten(
      this.clipWindowListener);

    this.player.reset();
    this.selector.reset();

    this.transcriptsSection.reset();
    this.indexSection.reset();
    this.playerComments.reset();
    this.reactions.reset();
    this.jobLiveStatusMonitor.reset();
    this.localApiClient.unsubscribe();
    this.liveReactionsController.reset();
    this.playerSpeakerIdController.reset();

    this.job?.resetSyncStatuses();
    this.jobId = null;
    this.isLoading = false;
    this.error = null;
  }

  @action
  private setError(error?: Error | string) {
    TRACE(this, `setError()`, error);

    if (!error)
      error = pageError();
    if (typeof error === 'string')
      error = pageError('Unknown', error);

    console.error(error);

    this.isLoading = false;
    this.error = error;
  }

  @action
  private setLoading() {
    TRACE(this, `setLoading()`);
    this.isLoading = true;
    this.error = null;
  }

  @action
  private setLoaded() {
    TRACE(this, `setLoaded()`);
    this.isLoading = false;
    this.error = null;
  }

  @action
  private setLoadAborted() {
    TRACE(this, `setLoadAborted()`);
    this.isLoading = false;
    this.error = null;
  }

  @action
  private setMounted() {
    this.isMounted = true;
  }

  @action
  private setUnmounted() {
    this.isMounted = false;
  }

  getSectionStatus = (section: PlayerSectionName): SectionStatus => {

    switch (section) {
      case 'Comments': {
        const { commentsSyncStatus, commentThreads } = this.playerComments;
        if (commentsSyncStatus === SyncStatus.Fetching) {
          return SectionStatus.Loading;
        }

        if (commentsSyncStatus === SyncStatus.Error) {
          return SectionStatus.Error;
        }

        if (!commentThreads.length) {
          return SectionStatus.Empty;
        }

        return SectionStatus.NotEmpty;
      }

      case 'Index': {
        // To be implemented
        return SectionStatus.NotEmpty;
      }

      case 'Transcripts': {
        // To be implemented
        return SectionStatus.NotEmpty;
      }

      default:
        return SectionStatus.NotEmpty;
    }
  }

  getSectionAvailability = (section: PlayerSectionName): boolean => {
    const { job } = this;

    if (this.suppressApiQueries)
      return true;

    if (!job)
      return false;

    if (
      job.isLiveStreamWaiting ||
      job.isLiveStreaming ||
      job.isMediaProcessing ||
      job.isLiveEnded ||
      this.player.isLivePlaybackActive) {
      if (section !== 'Player')
        return false;
    }

    switch (section) {
      case 'Index':
        return job.hasTranscriptLevel && 
        !job.isEnrichmentNotRequested &&
        !job.isTranscriptPending && 
        !job.isTranscriptProcessing;
      
      case 'Transcripts':
        return job.hasTranscriptLevel;

      case 'Comments':
        return !!this.store.isAuthorized;

      default:
        return true;
    }
  }
  // #endregion
}