import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import HLS from 'hls.js';

const ENABLE_LOGGING = false;

type Props = BindingProps<{
  streamURL: string;
  enabled: boolean;
}>

export enum StreamStatus {
  None = 'None',
  Active = 'Active',
  Inactive = 'Inactive'
}

const POLL_INTERVAL = 4000;

export class MediaStreamMonitor
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    // handle enabled 
    reaction(() => this.enabled, () => this.handleEnabled());
  }

  @computed get enabled(): boolean {
    return this.resolvedProps.enabled;
  }

  // #region Props
  @computed get streamURL(): string | null {
    return this.resolvedProps.streamURL ?? null;
  }
  // #endregion

  @computed get isStreamActive(): boolean {
    return this.streamState === StreamStatus.Active;
  }

  @computed get isStreamInactive(): boolean {
    return this.streamState === StreamStatus.Inactive;
  }

  @observable streamState: StreamStatus = StreamStatus.None;
  private hls: Hls | null = null;

  private timerId: ReturnType<typeof setTimeout> | null = null;

  @action
  handleEnabled() {
    ENABLE_LOGGING && console.log('handle enabled', this.enabled);
    if (this.enabled)
      this.start();
    else
      this.reset();
  }

  @action
  private startTimer() {
    this.cancelTimer();

    if (!this.enabled) {
      ENABLE_LOGGING && console.warn('monitor disabled, cannot start timer');
      return;
    }

    ENABLE_LOGGING && console.log('start timer');
    this.timerId = setTimeout(() => {
      this.refresh();
    }, POLL_INTERVAL);
  }

  @action
  private cancelTimer() {
    const timerId = this.timerId;
    if (timerId) {
      clearTimeout(timerId);
      ENABLE_LOGGING && console.log('timer canceled');
      this.timerId = null;
    }
  }

  @action
  private cancelRequest() {
    if (this.hls) {
      this.hls.destroy();
      this.hls = null;
    }
  }

  @action
  start() {
    this.refresh();
  }

  @action
  cancel() {
    this.cancelTimer();
    this.cancelRequest();
  }

  @action
  reset() {
    this.cancel();
    this.streamState = StreamStatus.None;
  }

  @action
  refresh() {
    this.cancel();
    this.run(false);
  }

  @action
  public run(once = true) {
    ENABLE_LOGGING && console.log('monitor: run');

    if (!this.enabled) {
      ENABLE_LOGGING && console.warn('monitor disabled, cannot run');
      // this.startTimer();
      return;
    }

    if (!this.streamURL) {
      ENABLE_LOGGING && console.log('no stream -> inactive');
      if (!this.isStreamInactive)
        this.emit('inactive');
      this.streamState = StreamStatus.Inactive;
      this.startTimer();
      return;
    }
    ENABLE_LOGGING && console.log('check stream');
    this.hls = new HLS();

    this.hls.config.autoStartLoad = false;
    this.hls.loadSource(this.streamURL);

    this.hls.on(HLS.Events.ERROR, (
      event: typeof HLS.Events.ERROR,
      data: Hls.errorData
    ) => {
      ENABLE_LOGGING && console.log('monitor: error', data);
      if (!this.isStreamInactive)
        this.emit('inactive');
      this.streamState = StreamStatus.Inactive;
      this.hls?.destroy();
      this.hls = null;
      !once && this.startTimer();
    });

    this.hls.on(HLS.Events.MANIFEST_LOADED, (
      event: typeof HLS.Events.MANIFEST_LOADED,
      data: Hls.manifestLoadedData
    ) => {
      ENABLE_LOGGING && console.log('monitor: manifest loaded')
      if (!this.isStreamActive)
        this.emit('active');
      this.streamState = StreamStatus.Active;
      this.hls?.destroy();
      this.hls = null;
      !once && this.startTimer();
    });

  }
}