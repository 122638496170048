import './momentCloud.scss';

import React, { ReactElement, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { EdgeLine } from './edgeLine';
import { CloudEdge } from './cloudEdge';

type Snapshot = {
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  accented: boolean,
  expanded: boolean,
  selected: boolean,
  hidden: boolean
}

type Props = {
  model: CloudEdge
}

export const EdgeElement = observer(({
  model
}: Props) => {

  const x1 = model.sourceNode?.renderX || 0;
  const y1 = model.sourceNode?.renderY || 0;
  const x2 = model.targetNode?.renderX || 0;
  const y2 = model.targetNode?.renderY || 0;

  const cursorRef = useRef<number>(0);
  const snapshotRef = useRef<Snapshot>({
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
    accented: false,
    expanded: false,
    selected: false,
    hidden: true
  });

  useEffect(() => {
    cursorRef.current = cursorRef.current + 1;
    snapshotRef.current = {
      x1,
      y1,
      x2,
      y2,
      accented: model.isAccented,
      expanded: model.isExpanded,
      selected: model.isSelected,
      hidden: !model.isVisible
    };
  }, [model, x1, y1, x2, y2]);
  const cursor = cursorRef.current;
  const snapshot = snapshotRef.current;

  const className = classNames('cloud-edge', {
    'accent': model.isAccented,
    'disable-transitions': model.disableTransitions
  });

  const snapshotClassName = classNames('cloud-edge snapshot', {
    'accent': model.isAccented,
  });

  const currElem = (
    <EdgeLine className={className}
      x1={x1}
      y1={y1}
      x2={x2}
      y2={y2}
      aria-expanded={model.isExpanded}
      aria-selected={model.isSelected}
      aria-hidden={!model.isVisible} />
  );
  const prevElem = (
    <EdgeLine className={snapshotClassName}
      x1={snapshot.x1}
      y1={snapshot.y1}
      x2={snapshot.x2}
      y2={snapshot.y2}
      aria-expanded={snapshot.expanded}
      aria-selected={snapshot.selected}
      aria-hidden={snapshot.hidden} />
  );

  let firstElem: ReactElement;
  let secondElem: ReactElement;

  if (cursor % 2 === 0) {
    firstElem = currElem;
    secondElem = React.cloneElement(prevElem, {
      'aria-hidden': true
    });
  } else {
    firstElem = React.cloneElement(prevElem, {
      'aria-hidden': true
    });;
    secondElem = currElem;
  }

  return <>
    {firstElem}
    {secondElem}
  </>
});