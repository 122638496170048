import jwtDecode from 'jwt-decode';
import isEqual from 'lodash/isEqual';
import { isNonEmptyString, Result } from '../../core';
import { UserRole } from './authSchema';
import { isTokenValid } from './authUtils';
import { Error } from '../../core/error';
import { getNowSeconds } from '../../core/time';

export type AuthIdTokenPayload = {
  aud: string,
  exp: number,
  iat: number,
  iss: string,
  nonce: string,
  sub: string,
  roles: UserRole[],
}

/**
 * Represents an object returned by the auth provider when the authorization succeeded
 * and all defensive checks on the tokens have passed.
 */
export type AuthPermitData = {
  accessToken?: string | null;
  accessTokenExpires?: number | null;
  idToken: string;
  idTokenPayload?: AuthIdTokenPayload | null;
};

type AuthPermitPreparedData = {
  accessToken?: string | null;
  accessTokenExpires?: number | null;
  idToken: string;
  idTokenPayload: AuthIdTokenPayload;
}

/**
 * Represents a response from the Auth provider which guarantees that the tokens
 * have been properly obtained, they are valid and the state data has been properly read.
 * This enables a transactional response from the Auth provider in which the authorization
 * either fails or succeeds, without having to deal with partially corrupted data or transient states.
 * ---
 * **NOTE**:  In a real authorization flow, this permit is half of the process, 
 *            with the other half being the fetching of the `UserProfile` object.
 *            See `AuthContext` for the complete object which underlies a fully authorized state.
 * ---
 * This object is immutable using `Object.freeze`.
 */
export class AuthPermit {

  private static IdCursor = 1;

  /**
   * Creates a new instance of AuthPermit.
   * The data object needs to have been validated using `isAuthPermitDataValid`, otherwise an `AssertionError` is thrown.
   * Use `tryCreateAuthPermit` if you're not sure about the data validity.
   * @param data  The data based on which to create the permit.
   * @throws {AssertionError}
   */
  private constructor(data: AuthPermitPreparedData) {

    this.id = (AuthPermit.IdCursor++).toString();

    // we could use object assign but it's better to specify
    // each property explicitly such that TypeScript will yell if some change occurs
    this.accessToken = data.accessToken ?? null;
    this.accessTokenExpires = data.accessTokenExpires ?? null;
    this.idToken = data.idToken;
    this.idTokenExpires = data.idTokenPayload.exp;
    this.idTokenPayload = data.idTokenPayload;

    // make the object immutable
    Object.freeze(this);
    Object.freeze(this.idTokenPayload);
  }

  static create(data: AuthPermitData): Result<AuthPermit> {
    const [preparedData, err] = prepareAuthPermitData(data);
    if (err)
      return [null, err];

    const permit = new AuthPermit(preparedData!);
    return [permit];
  }

  readonly id: string;
  readonly idToken: string;
  readonly idTokenExpires: number;
  readonly idTokenPayload: AuthIdTokenPayload;
  readonly accessToken: string | null;
  readonly accessTokenExpires: number | null;

  get isIdTokenValid() {
    return isTokenValid(this.idTokenExpires);
  }

  get isAccessTokenValid() {
    if (this.accessToken)
      return isTokenValid(this.accessTokenExpires);
    return true;
  }

  get isValid() {
    return (
      this.isIdTokenValid &&
      this.isAccessTokenValid);
  }

  get roles(): UserRole[] {
    return this.idTokenPayload.roles || [];
  }

  hasRole(role: UserRole) {
    return this.roles.includes(role);
  }
}

export function createAuthPermit(data: AuthPermitData): Result<AuthPermit> {
  return AuthPermit.create(data);
}

function prepareAuthPermitData(data: AuthPermitData): Result<AuthPermitPreparedData> {

  let {
    accessToken,
    accessTokenExpires,
    idToken,
    idTokenPayload
  } = data;

  // validate the access token if provided
  if (isNonEmptyString(accessToken)) {
    // validate the access token
    if (!isTokenValid(accessTokenExpires))
      return [null, new Error('AuthError', `The access token is expired.`)];
  }

  // validate that we have an ID token
  if (!isNonEmptyString(idToken))
    return [null, new Error('AuthError', `The ID token is mandatory.`)];

  // if a payload is provided, we will compare it against the decoded token payload
  let decodedIdTokenPayload: AuthIdTokenPayload;
  try {
    decodedIdTokenPayload = jwtDecode(idToken);
  } catch (err) {
    return [null, new Error('AuthError', `Unable to decode ID token.`)];
  }

  if (idTokenPayload) {
    if (!isEqual(idTokenPayload, decodedIdTokenPayload))
      console.warn(`The provided ID token payload is different from the decoded one. The provided ID token payload will be used.`);
  } else {
    idTokenPayload = decodedIdTokenPayload;
  }

  if (
    !isNonEmptyString(idTokenPayload.aud) ||
    !isNonEmptyString(idTokenPayload.iss) ||
    !isNonEmptyString(idTokenPayload.sub) ||
    !(idTokenPayload.exp! > 0) ||
    !(idTokenPayload.iat! > 0))
    return [null, new Error('AuthError', `Some fields are missing from the ID token payload.`)];

  // TODO: at some point this was commented out, however I cannot find the reason for that
  // so investigate more when releasing
  if (idTokenPayload.exp <= getNowSeconds())
    return [null, new Error('AuthError', `Your session has expired.`)];

  const outData = {
    accessToken: accessToken ?? null,
    accessTokenExpires: accessTokenExpires ?? null,
    idToken,
    idTokenPayload
  }

  return [outData];
}