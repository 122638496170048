import { action, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../../components/overlays';
import { LibraryProvider } from '../../services/libraries/libraryServiceBase';

export class CredentialsExpiredWindowState
  extends StoreNode {

  readonly nodeType = 'CredentialsExpiredWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);

  @observable isLoading: boolean = false;
  @observable library: LibraryProvider | null = null;

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open({ library }: { library: LibraryProvider }) {
    this.dispatch('Overlays', 'openWindow', { name: 'CredentialsExpiredWindow' });

    this.library = library;
  }

  @action
  close() {
    this.library = null;

    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}