import './input.scss';
import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { InputState, InputStatus } from './inputState';
import { HTMLDivProps, isNonEmptyString, Maybe } from '../../core';
import { InputGroupState } from './inputGroupState';

type Props = HTMLDivProps<{
  status?: Maybe<InputStatus>,
  message?: Maybe<string | ReactNode>,
  model?: InputState | InputGroupState
}>;

/** Displays a feedback message based on the provided InputState. */
export const Feedback = observer(({
  className,
  model,
  status = model?.status,
  message = model?.statusMessage,
  ...props
}: Props) => {

  className = classNames('feedback', status, className, {
    'hidden': (typeof message === 'string' && !isNonEmptyString(message)) || !message
  });
  // if (!isNonEmptyString(message))
  //   return null;

  return (
    <div className={className} {...props}>
        { message }
    </div>
  );
});
