import { UpdateProfileInput } from '@clipr/lib/dist/generated/graphql';
import { action, computed, makeObservable, observable } from 'mobx';
import { WindowState } from '../../components/overlays/windowState';
import { Message, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { input, InputState } from '../input/inputState';

type OpenParams = {
  onSubmit: () => void;
  onCancel?: (() => void) | null;
  onClose?: (() => void) | null;
  onSuppress?: ((value: boolean) => void) | null;
  targetCount?: number | null;
  jobId?: string | null;
}

export class SpeakerIdConfirmationWindowState
  extends StoreNode {

  readonly nodeType = 'SpeakerIdConfirmationWindow';

  constructor(store: Store, props?: any) {
    super(store, props);
    makeObservable(this);
    this.window.listen(
      this.windowListener);
  }

  @observable onSubmit: (() => void) | null = null;
  @observable onCancel: (() => void) | null = null;
  @observable onClose: (() => void) | null = null;
  @observable onSuppress: ((value: boolean) => void) | null = null;
  @observable targetCount: number = 0;
  @observable jobId: string | null = null;

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close('close');
        break;
    }
  }
  readonly window = new WindowState(this.store);

  readonly showSpeakerIdConfirmation: InputState = input(this, {
    name: 'showSpeakerIdConfirmation',
    onChange: (input) => this.onChangeVisibility(!!input.value)
  });

  @computed
  get showVisibilityCheck(): boolean {
    return !!this.jobId;
  }

  @computed
  get targetCountDisplay(): string {
    return this.targetCount.toString() + ' ' + (this.targetCount === 1 ? 'speaker' : 'speakers');
  }

  @action
  async submit() {

    if (this.onSubmit)
      await this.onSubmit();

    this.close('close');
  }

  @action
  open(params: OpenParams) {
    this.dispatch('Overlays', 'openWindow', { name: 'SpeakerIdConfirmationWindow' });
    this.onSubmit = params.onSubmit;
    this.onCancel = params.onCancel ?? null;
    this.onClose = params.onClose ?? null;
    this.onSuppress = params.onSuppress ?? null;
    this.targetCount = params.targetCount ?? 0;
    this.jobId = params.jobId ?? null;
    this.emit('open');
    this.showSpeakerIdConfirmation.loadValue(false);
  }

  @action
  async close(msg?: string) {
    if (this.onClose)
      await this.onClose();

    this.dispatch('Overlays', 'closeWindow');
    if (msg) this.emit(msg);

    if (!!this.showSpeakerIdConfirmation.value) {
      this.updateUserPermissions();
    }
  }

  @action
  async cancel() {
    if (this.onCancel)
      await this.onCancel();

    this.close('close');
  }

  @action
  onWindowExited() {
    this.clearState();
  }

  @action
  clearState() {
    this.onSubmit = null;
    this.onCancel = null;
    this.onClose = null;
    this.onSuppress = null;
    this.targetCount = 0;
    this.jobId = null;
  }

  @action
  async updateUserPermissions() {

    if (!this.jobId)
      return;

    const params: { args: UpdateProfileInput } = {
      args: {
        hideSpeakerSuggestion: this.jobId,
      }
    };

    // TODO: replace with unified flow
    await this.store.api.updateProfile(params);
    await this.store.authService.runRefreshContextFlow();
  }

  @action
  async onChangeVisibility(value: boolean) {
    this.onSuppress?.(value);
  }

}