import { IObservableArray, observable } from 'mobx';

export type MaybeObservableArray<T> =
  T[] |
  IObservableArray<T>;

/**
 * Utility for instantiating either a native Array or an observable array, depending on preference.
 */
export function createArray<T>(obs = false) {
  return obs ? observable.array<T>() : [];
}