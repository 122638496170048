import { ChartPageParams } from '../chartSchema';
import { LineChartDataProps, LineSeriesProps } from '../xySeriesChart';

export enum AudienceFocusChartMode {
  Topics = 'Topics',
  SubTopics = 'SubTopics',
  MostBookmarkedTopics = 'MostBookmarkedTopics',
  MostBookmarkedSubtopics = 'MostBookmarkedSubtopics',
}


export type AudienceFocusChartExternalParams = ChartPageParams;

export type AudienceFocusChartInternalParams = {
  mode?: AudienceFocusChartMode | null
}

export type AudienceFocusChartParams =
  AudienceFocusChartInternalParams &
  AudienceFocusChartExternalParams;

export type AudienceFocusChartDatum = LineChartDataProps & {
  value: number,
  tooltip: {
    jobTitle: string,
    speakerNames: string[]
  }
};
export type AudienceFocusChartSeriesData = LineSeriesProps;
export type AudienceFocusChartData = AudienceFocusChartSeriesData[];
