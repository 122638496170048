import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import noop from 'lodash/noop';
import { SocialLoginProvider } from '../../../services/auth/authSchema';
import { HTMLDivProps, Maybe } from '../../../core';
import { SocialLoginButton } from './socialLoginButton';

const SUPPORTED_PROVIDERS: SocialLoginProvider[] = [
  'Google',
  'Facebook',
  'LinkedIn',
  'Twitter',
  'Microsoft'
]

type Props = HTMLDivProps<{
  providers?: Maybe<'all' | SocialLoginProvider[]>,
  onProviderClick?:
    (evt: MouseEvent, provider: SocialLoginProvider) => void
}>;

export const SocialLoginBox = observer(({
  providers = 'all',
  onProviderClick = noop
}: Props) => {

  return (
    <div className="social-login-panel btn-group center">
      {SUPPORTED_PROVIDERS.map(provider => {

        return (providers === 'all' || providers?.includes(provider)) &&
          <SocialLoginButton
            key={provider}
            provider={provider}
            onClick={evt => onProviderClick(evt, provider)} />
      })}
    </div>
  );
});