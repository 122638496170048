import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useModel, UseModelCreateCallback } from '../components/componentHooks';
import { useStore } from '../store/storeHooks';
import { RouteContext } from './routeContext';
import { IRouteState, IRouteStorage } from './routeSchema';

export function useRouteContext<TParams = any>(): RouteContext<TParams> {

  // clean enough

  const store = useStore();
  const { routingService } = store;

  // this will cause React to update on location change,
  // since the context is not observable (and it should never be)
  useLocation<IRouteStorage | null>();

  return routingService.context as RouteContext<TParams>;
}

export function useRouteModel<T extends IRouteState>(createCallback: UseModelCreateCallback<T>): T {

  const model = useModel(createCallback);
  const routeContext = useRouteContext();

  useEffect(() => {
    model.attached(routeContext);
    return () =>
      model.detached();
  }, [model, routeContext]);

  return model;
}