import identity from 'lodash/identity';
import last from 'lodash/last';
import uniqBy from 'lodash/uniqBy';
import { computed, makeObservable } from 'mobx';
import { isIterable, mapGetOrSet } from '../../core';
import { getTimeRegionsMergedDuration } from '../../core/time';
import { MomentModel } from '../moment';
import { MomentEdgeFlags } from './momentEdge';
import { isConnectedTo, matches, MomentFilterFunc, MomentRelatedFilterFunc } from './momentFilters';
import { MomentGraph } from './momentGraph';
import { MomentGroup, MomentGroupByKey, MomentGroupKeyData, MomentGroupProps, MomentGroupSignature } from './momentGroup';
import { MomentNodeFlags } from './momentNode';
import { MomentSelectorOutput } from './momentSelector';

type Moment = MomentModel;

type MomentOrderByKey =
  'StartTime' |
  'EndTime' |
  'Name';


export type MomentQueryableState = {
  /** The data set against which queries will be executed. */
  source: Iterable<Moment> | MomentGraph,

  filteredIds?: Set<string>,
  relatedExcludeIds?: Set<string>,
  groupByKeys?: Set<MomentGroupByKey>,
  orderByKeys?: Set<MomentOrderByKey>
}

export class MomentQueryable {

  constructor(state?: MomentQueryableState) {
    makeObservable(this);

    this.source = state?.source || [];

    this.filteredIds = state?.filteredIds ?? null;
    this.relatedExcludeIds = state?.relatedExcludeIds ?? new Set();
    this.groupByKeys = state?.groupByKeys ?? new Set();
    this.orderByKeys = state?.orderByKeys ?? new Set();

  }

  private readonly source: Iterable<Moment> | MomentGraph;

  private readonly filteredIds: Set<string> | null = null;
  private readonly relatedExcludeIds: Set<string>;
  private readonly groupByKeys: Set<MomentGroupByKey>;
  private readonly orderByKeys: Set<MomentOrderByKey>;

  @computed
  private get sourceGraph() {
    const { source } = this;
    if (isIterable(source))
      return new MomentGraph([...source]);

    if (source instanceof MomentGraph)
      return source;

    return new MomentGraph([]);
  }

  @computed
  private get sourceData() {
    const { source } = this;
    if (isIterable(source))
      return [...source];
    if (source instanceof MomentGraph)
      return source.data.slice();

    return [];
  }

  @computed
  private get sourceLookup(): Map<string, MomentModel> {
    const lookup = new Map<string, MomentModel>();
    this.sourceData.forEach(moment =>
      lookup.set(moment.id, moment));

    return lookup;
  }

  @computed
  private get filteredData(): MomentModel[] {
    const { filteredIds } = this;
    if (!filteredIds)
      return this.sourceData.slice();

    return this.sourceData.filter(moment =>
      filteredIds?.has(moment.id))
  }

  @computed
  private get filteredLookup(): Map<string, MomentModel> {
    const lookup = new Map<string, MomentModel>();
    this.sourceData.forEach(moment =>
      lookup.set(moment.id, moment));
    return lookup;
  }


  private pipe(props: Partial<MomentQueryableState>): MomentQueryable {

    const state = Object.assign({
      source: this.source,
      filteredIds: this.filteredIds,
      relatedExcludeIds: this.relatedExcludeIds,
      groupByKeys: this.groupByKeys,
      orderByKeys: this.orderByKeys
    }, props);

    return new MomentQueryable(state);
  }

  private pipeGroupBy(key: MomentGroupByKey, then = false) {
    return this.pipe({
      groupByKeys: new Set(then ?
        [...this.groupByKeys, key] :
        [key])
    });
  }

  private pipeOrderBy(key: MomentOrderByKey, then = false) {
    return this.pipe({
      orderByKeys: new Set(then ?
        [...this.orderByKeys, key] :
        [key])
    });
  }


  private pipeFilter(func: MomentFilterFunc) {
    const data = this.filteredData.filter(func);
    return this.pipe({
      filteredIds: new Set(data.map(moment => moment.id))
    });
  }

  private pipeRelated(func: MomentRelatedFilterFunc, then = false) {

    let excludeIds: Set<string>;
    let source: MomentModel[];
    let dataIds = this.filteredData.map(m => m.id);

    if (then) {

      excludeIds = new Set([
        ...dataIds,
        ...this.relatedExcludeIds]);

      source = this.sourceData
        .filter(other => !excludeIds.has(other.id));

    } else {

      excludeIds = new Set(dataIds);
      source = this.sourceData;
    }

    const nextData =
      source.filter(other =>
        this.filteredData.some(m => func(m, other)));

    return this.pipe({
      filteredIds: new Set(nextData.map(moment => moment.id)),
      relatedExcludeIds: excludeIds
    });
  }

  topics() {
    return this.pipeFilter(m => m.isTopic);
  }
  transcripts() {
    return this.pipeFilter(m => m.isTranscript);
  }
  generics() {
    return this.pipeFilter(m => m.isGeneric);
  }

  filter(func: MomentFilterFunc) {
    return this.pipeFilter(func);
  }

  filterByIds(ids: Iterable<string>) {
    let idSet = new Set([...ids]);
    return this.pipeFilter(m => idSet.has(m.id));
  }

  filterBySelector(data: MomentSelectorOutput) {

    const topics = new Set(data.topicGroupKeys || []);
    const generics = new Set(data.genericGroupKeys || []);
    const speakers = new Set(data.speakerGroupKeys || []);

    if (data.isVideoModeEnabled)
      return this.pipeFilter(m => true);

    switch (data.mode) {

      case 'Topics':
        return this.pipeFilter(m => {
          if (m.isTopic) {
            if (!topics.has(m.name!))
              return false;
          }

          if (m.isGeneric) {
            const keyData: MomentGroupKeyData = {
              name: m.name,
              momentType: m.momentType
            };
            if (!generics.has(JSON.stringify(keyData)))
              return false;
          }

          return true;
        });

      case 'Speakers':
        return this.pipeFilter(m => {

          if (m.isGeneric || m.isTranscript) {
            if (!speakers.has(m.speakerId))
              return false;
          }
          return true;
        });
    }

    return this.pipeFilter(m => false);
  }

  matches(query: string) {
    return this.pipeFilter(matches(query));
  }

  matchesGroup(group: MomentGroup | MomentGroupSignature | MomentGroupProps) {
    const {
      keys,
      firstTopic,
      name,
      speakerId
    } = group;

    const keySet = new Set([...keys]);
    const graph = this.sourceGraph;
    const getParentTopics = (m: MomentModel): MomentModel[] => {

      return graph
        .getNode(m.id)
        ?.filterOutgoingEdgeNodes(MomentEdgeFlags.Connected, MomentNodeFlags.Topic)
        ?.map(node => node.moment) || [];
    }

    return this.pipeFilter(m => {

      if (keySet.has('FirstTopic') && !getParentTopics(m).some(t => t.name === firstTopic))
        return false;

      if (keySet.has('Name') && m.name !== name)
        return false;

      if (keySet.has('Speaker') && m.speakerId !== speakerId)
        return false;

      return true;
    });
  }

  connected() {
    return this.pipeRelated(isConnectedTo());
  }
  thenConnected() {
    return this.pipeRelated(isConnectedTo(), true);
  }


  withoutTopics() {
    return this.pipeFilter(m => !m.isTopic);
  }
  withoutTranscripts() {
    return this.pipeFilter(m => !m.isTranscript);
  }
  withoutGenerics() {
    return this.pipeFilter(m => !m.isGeneric);
  }

  /** Get all moments from the source list which start after the last moment in the group has ended. */
  after() {

  }

  totalDurationSeconds() {
    return getTimeRegionsMergedDuration(this.filteredData);
  }

  groupByFirstTopic() {
    return this.pipeGroupBy('FirstTopic');
  }
  groupByTopics() {
    return this.pipeGroupBy('Topics');
  }
  groupBySpeaker() {
    return this.pipeGroupBy('Speaker');
  }
  groupByName() {
    return this.pipeGroupBy('Name');
  }
  groupByMomentType() {
    return this.pipeGroupBy('MomentType');
  }

  thenGroupByFirstTopic() {
    return this.pipeGroupBy('FirstTopic', true);
  }
  thenGroupByTopics() {
    return this.pipeGroupBy('Topics', true);
  }
  thenGroupBySpeaker() {
    return this.pipeGroupBy('Speaker', true);
  }
  thenGroupByName() {
    return this.pipeGroupBy('Name', true);
  }
  thenGroupByMomentType() {
    return this.pipeGroupBy('MomentType', true);
  }

  moments() {
    return this.filteredData.slice();
  }
  momentNames(): string[] {
    return [...new Set(this.filteredData.map(mom => mom.name!))]
      .filter(identity);
  }
  momentIds() {
    return this.filteredData.map(mom => mom.id);
  }

  groups(): MomentGroup[] {

    const graph = this.sourceGraph;

    // quick key lookup to determine what to read for each moment
    const keys = this.groupByKeys;
    const keyMap: { [key in keyof MomentGroupKeyData]: boolean } = {
      firstTopic: keys.has('FirstTopic'),
      lastTopic: keys.has('LastTopic'),
      topics: keys.has('Topics'),
      name: keys.has('Name'),
      speakerId: keys.has('Speaker'),
      momentType: keys.has('MomentType')
    };

    // flag to indicate that for each moment the topic list needs to be fetched
    const useTopics =
      keyMap.firstTopic ||
      keyMap.lastTopic ||
      keyMap.topics;

    const groupData = new Map<string, MomentModel[]>();

    this.filteredData.forEach(moment => {

      let topics: MomentModel[] = [];
      if (useTopics) {
        topics = graph
          .getNode(moment.id)
          ?.filterOutgoingEdgeNodes(MomentEdgeFlags.Connected, MomentNodeFlags.Topic)
          ?.map(node => node.moment) || [];
      }

      const keyData: MomentGroupKeyData = {};

      if (keyMap.firstTopic)
        keyData.firstTopic = topics[0]?.name || null;

      if (keyMap.lastTopic)
        keyData.lastTopic = last(topics)?.name || null;

      if (keyMap.topics)
        keyData.topics = [...new Set(topics
          .map(topic => topic.name!)
          .filter(desc => desc))];

      if (keyMap.name)
        keyData.name = moment.name;

      if (keyMap.speakerId)
        keyData.speakerId = moment.speakerId;

      if (keyMap.momentType)
        keyData.momentType = moment.momentType;

      // in a perfect world this would be an immutable, pass-by-value struct
      // we need to transform the key data because sets are not properly serialized by JSON.stringify
      const keyStr = JSON.stringify(keyData);
      mapGetOrSet(groupData, keyStr, [])
        .push(moment);
    });

    const groups: MomentGroup[] = [];
    for (const [keyStr, moments] of groupData) {

      const keyData: MomentGroupKeyData = JSON.parse(keyStr);
      const group = new MomentGroup({
        keys,
        ...keyData,
        moments
      });
      groups.push(group);
    }

    return groups;
  }

  groupBases() {
    return this.groups().map(group => group.keyData);
  }
  groupBasisIds() {
    return this.groups().map(group => group.keyDataId);
  }
}



export function fromMoments(source: Moment[] | MomentGraph) {
  return new MomentQueryable({
    source
  });
}

export function fromMomentGroups(source: MomentGroup[]) {
  return new MomentQueryable({
    source: uniqBy(source.map(group => group.moments).flat(), mom => mom.id)
  });
}