import { action, makeObservable } from 'mobx';
import { isNonEmptyString } from '../../core';
import { StoreNode } from '../../store';
import { Store } from '../../store/store';
import { notifyError, notifySuccess } from '../notifications';

/**
 * Cross-browser, observable object for managing the clipboard in the app.
 */
export class ClipboardManager
  extends StoreNode {

  readonly nodeType: 'ClipboardManager' = 'ClipboardManager';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.init();
  }

  get clipboard(): Clipboard | null {
    try {
      return navigator.clipboard;
    } catch (err) {
      return null;
    }
  }

  @action
  private init() {
    
  }

  @action
  dispose() {

  }

  async writeText(val: string, message: string | false = 'Value copied to clipboard!') {
    // TODO: split into reusable logic
    try {
      await this.clipboard?.writeText(val);
      if (isNonEmptyString(message))
        notifySuccess(this, message);
    } catch (err) {
      notifyError(this, 'Could not copy value to clipboard!');
    }
  }
}