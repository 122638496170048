import React from 'react';
import './teams.scss';
import { observer } from 'mobx-react-lite';
import { CheckboxInputControlled, RadioGroupInput, ToggleInput, ToggleLayout } from '../input';
import { GenerativeAiFormState } from './generativeAiFormState';
import { GenerativeAiProvider } from '@clipr/lib';

type PublicTeamFormProps = {
  model: GenerativeAiFormState;
  disabled: boolean;
};

export const GenerativeAiForm = observer(({ model, disabled }: PublicTeamFormProps) => {
  return <div className="scrollable generative-ai-container">
    <RadioGroupInput
      label='AI provider'
      onChange={(evt: any, value: string) => model.changeAiProvider(value)}
      value={model.generativeAiProvider as string}
      name={'generativeAiProvider'}
      items={[
        { value: GenerativeAiProvider.Amazon, label: 'AWS Bedrock' },
        { value: GenerativeAiProvider.OpenAi, label: 'OpenAI', disabled }]} 
      />

    <ToggleInput
      className="medium"
      disabled={!model.generativeAiProvider}
      label='AI video summary'
      toggled={!!model?.team?.chatGptSummaryEnabled}
      toggleLayout={ToggleLayout.Buttons}
      toggledLabel='Enabled'
      notToggledLabel='Disabled'
      name={'chatGptSummary'}
      onChange={() => model.toggleSummarization()}
    />

    <ToggleInput
      className="medium clipr-gpt-toggle"
      disabled={!model.generativeAiProvider}
      label='CLIPr GPT'
      toggled={!!model?.team?.cliprGptEnabled}
      toggleLayout={ToggleLayout.Buttons}
      toggledLabel='Enabled'
      notToggledLabel='Disabled'
      name={'cliprGpt'}
      onChange={() => model.toggleCliprGpt()}
    />
    
    <ToggleInput
      className="medium chat-gpt-action-items-toggle"
      disabled={!model.generativeAiProvider}
      label='AI action items'
      toggled={!!model?.team?.actionItemsExtractionEnabled}
      toggleLayout={ToggleLayout.Buttons}
      toggledLabel='Enabled'
      notToggledLabel='Disabled'
      name={'chatGptActionItems'}
      onChange={() => model.toggleActionItems()}
    />

    <div>
    <CheckboxInputControlled
      name="email-notifications"
      label="Enable email notifications for all members"
      className="email-notifications-checkbox"
      disabled={!model.generativeAiProvider}
      model={model.chatGptEmailNotifications}
    />
    <p className="chatgpt-info">* only for meetings and all hands meeting video types</p>
    </div>
  </div>
});