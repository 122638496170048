import { action, computed, makeObservable, observable } from 'mobx';
import { UploadTask } from '../../services/upload';
import { Message, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { WindowState } from '../../components/overlays';

export class CancelUploadWindowState
  extends StoreNode {

  readonly nodeType = 'CancelUploadWindow';

  constructor(store: Store, props?: any) {
    super(store, props);
    makeObservable(this);
    this.window.listen(
      this.windowListener);
  }

  @observable isLoading = false;
  @observable task: UploadTask | null = null;

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close();
        break;
    }
  }

  readonly window = new WindowState(this.store);

  @computed
  get isUploadFinished() {
    return this.task?.status !== 'uploading';
  }

  @computed
  get uploadSource() {
    const sync = this.task?.synchronizer;
    const teamId = sync?.teamId;

    return teamId ? this.store.teamManager.getTeam(teamId)?.name : 'My Uploads';
  }

  @computed
  get isReplacingVideo() {
    return !!this.task?.synchronizer.replacingJob;
  }

  async submit() {
    if (!this.task) return;
    this.task.cancel();
    this.close();
  }

  @action
  cancel() {
    this.close();
  }

  @action
  open({ task }: { task: UploadTask }) {
    this.dispatch('Overlays', 'openWindow', { name: 'CancelUploadWindow' });
    this.task = task;
  }

  @action
  close() {
    this.dispatch('Overlays', 'closeWindow');
    this.task = null;
  }
}