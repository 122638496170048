import './bookmarks.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { BookmarkList } from '../../entities';
import { BookmarkListCard } from './bookmarkListCard';
import { Grid } from '../layout';

type Props = {
  model: BookmarkList[];
};

export const BookmarkListCardCatalog = observer((props: Props) => {
  const { model } = props;

  return (
    <section className="bookmark-list-card-catalog catalog">
      <div className="catalog-viewport">
        <Grid className="catalog-grid">
          {model.map((bkmList) => {
            return (
              <li
                key={bkmList.id}
                className="catalog-item grid-item"
                data-bookmark-list-id={bkmList.id}>
                <BookmarkListCard model={bkmList} />
              </li>
            );
          })}
        </Grid>
      </div>
    </section>
  );
});
