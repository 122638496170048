import { makeObservable, computed, action, observable } from 'mobx';
import { Store } from '../../store/store';
import { BindingProps, RefProxy, refProxy, StoreNode } from '../../store';
import { PointerEventLike } from '../../core';
import { PlayerState } from './playerState';

type Props = BindingProps<{
  player: PlayerState;
  containerRef?: RefProxy<HTMLElement>;
}>

export class PlayheadState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  readonly componentRef = refProxy(this);

  @computed get player(): PlayerState | null {
    return this.resolvedProps.player ?? null;
  }

  @computed get containerRef(): RefProxy<HTMLElement> | null {
    return this.resolvedProps.containerRef ?? null;
  }

  @computed get position(): number {
    return this.player?.playheadTimeRatio ?? 0;
  }

  @observable isDragging = false;

  @action
  scrollIntoView() {
    // TODO: implement without setTimeout (maybe connect to a React hook that is invoked after the re-render)
    // Set timeout to wait for the new position re-render of the playhead
    setTimeout(() => {
      const playhead = this.componentRef.current;
      // TODO: request the viewportRef as a prop
      const viewport = this.store.trainerVideoPage.timeline.viewportProxy.current;

      if (viewport && playhead) {
        const isInView = viewport.scrollLeft < playhead.offsetLeft &&
          viewport.getBoundingClientRect().width + viewport.scrollLeft > playhead.offsetLeft;

        if (!isInView) {
          viewport.scrollTo({ left: playhead.offsetLeft })
        }
      }
    }, 500)
  }




  private getSeekPayload(evt: PointerEventLike) {
    const { player } = this;
    const time = player?.getTimeFromEvent(evt, this.containerRef) ?? 0;

    return {
      time
    }
  }

  private emitSeek(type: 'seek' | 'seekStart' | 'seekEnd', evt: PointerEventLike) {
    this.emit(type, this.getSeekPayload(evt));
  }

  handlePointerDown = action((evt: React.PointerEvent) => {
    const { player } = this;
    if (player?.isSeekDragging) {
      console.warn('PlayheadAdapter: pointerdown handler invoked, even though VideoState.isSeekDragging is already true.');
      return;
    }

    evt.stopPropagation();
    evt.preventDefault();

    document.addEventListener('pointermove', this.handleRootPointerMove);
    document.addEventListener('pointerup', this.handleRootPointerUp);

    this.isDragging = true;
    this.emitSeek('seekStart', evt);
  });

  private handleRootPointerMove = action((evt: PointerEvent) => {

    evt.stopPropagation();
    evt.preventDefault();

    this.emitSeek('seek', evt);
  })

  private handleRootPointerUp = action((evt: PointerEvent) => {

    document.removeEventListener('pointermove', this.handleRootPointerMove);
    document.removeEventListener('pointerup', this.handleRootPointerUp);

    this.isDragging = false;
    this.emitSeek('seekEnd', evt);
  })
}