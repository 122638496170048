
import React from 'react';
import { observer } from 'mobx-react-lite';
import { UserCard } from '../layout';
import { Button } from '../input';
import { useKernel } from '../../kernel/kernelHooks';
import { AuthContextType } from '../../services/auth';

type Props = {
  showLogoutButton?: boolean;
  showHelp?: boolean;
  showProfile?: boolean;
  onLoginButtonClick?: React.MouseEventHandler;
  onLogoutButtonClick?: React.MouseEventHandler;
  onHelpButtonClick?: React.MouseEventHandler;
}

export const WidgetProfileCard = observer(({
  showLogoutButton = true,
  showHelp = false,
  showProfile = false,
  onLoginButtonClick,
  onLogoutButtonClick,
  onHelpButtonClick
}: Props) => {

  const kernel = useKernel();
  const { store } = kernel;
  const { context } = store.authService;
  const { isWidgetMode } = store.widgetService;

  if (!context || !isWidgetMode)
    return null;

  switch (context.type) {
    case AuthContextType.Authenticated:
      return (
        <div className="user-profile">
          <UserCard
            showLogout={showLogoutButton && context.allowActions}
            showHelp={showHelp}
            showProfile={showProfile}
            onLogoutButtonClick={onLogoutButtonClick}
            onHelpButtonClick={onHelpButtonClick} />
        </div>
      );

    case AuthContextType.Anonymous:
      if (!context.allowActions)
        return null;

      // user is not logged in but we offer this possibility
      return (
        <Button
          label="Sign In"
          className="primary large"
          onClick={onLoginButtonClick} />
      );
  }

  return null;
});