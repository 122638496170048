import './loader-v1-1.scss';

import React from 'react';
import classNames from 'classnames';
import range from 'lodash/range';

type Props = {
  className?: string,
  dots?: 3 | 5
}

export const DotSpinner = ({
  className,
  dots = 5
}: Props) => {
  className = classNames('dot-spinner', className);

  return (
    <div className={className}
      data-dots={dots}>
      {range(dots).map(dot =>
        <div className="dot" key={dot} />)}
    </div>
  )
}