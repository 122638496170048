import { action, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { CatalogState } from '../../components/layout/catalogState';
import { Error, pageError } from '../../core/error';
import { UserDashboardView } from '../../routes';
import { JobCatalogSource, JobModel } from '../../entities';
import { LibraryLabels } from '../../services/libraries/libraryServiceBase';
import { IntegrationTabState } from '../integrations/integrationTabState';
import { ZoomIntegrationTabState } from '../integrations/zoomIntegrationTabState';

type Props = {}
export type UserDashboardPageParams = {}

export type NavigationTabs = LibraryLabels | 'My Uploads';
export class UserDashboardPageState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.jobCatalogSource = new JobCatalogSource(this.store, {
      pageSize: 20,
      jobFilter: (job: JobModel) => !job.ownerTeamId && !job.source.externalLibrary
    });
    this.jobCatalog = new CatalogState(this.store, {
      syncStatus: () => this.jobCatalogSource.syncStatus,
      isEndOfList: () => this.jobCatalogSource.isEndOfList
    });
    this.jobCatalog.listen((msg) => {
      switch (msg.type) {
        case 'scrollToBottom':
        case 'loadMore':
          this.jobCatalogSource.fetchMore();
          break;
      }
    });

    // #region Google Drive tab state
    this.googleDriveTabState = new IntegrationTabState(this.store, {
      pageSize: 20,
      libraryService: this.store.googleDrive
    });
    // #endregion

    // #region One Drive source state
    this.oneDriveTabState = new IntegrationTabState(this.store, {
      pageSize: 20,
      libraryService: this.store.oneDrive,
      defaultSearchValue: this.store.oneDrive.defaultSearchValue
    });
    // #endregion

    // #region Zoom source state
    this.zoomIntegrationTabState = new ZoomIntegrationTabState(this.store, {
      pageSize: 20,
      libraryService: this.store.zoom
    });
    // #endregion
  }

  @observable isLoading: boolean = false;
  @observable error: Error | null = null;
  @observable activeTab: NavigationTabs = 'My Uploads';

  readonly jobCatalogSource: JobCatalogSource;
  readonly jobCatalog = new CatalogState(this.store);

  readonly googleDriveTabState: IntegrationTabState;

  readonly oneDriveTabState: IntegrationTabState;

  readonly zoomIntegrationTabState: ZoomIntegrationTabState;

  @action
  async mounted(params: UserDashboardPageParams) {

    this.reset();
    const search = new URLSearchParams(window.location.search);
    const view: string | null = search.get('view');
    const { user } = this.store;
    if (view && user?.hasPermission('UserUpload')) {
      this.activeTab = (this.getTabFromQuery(view as UserDashboardView));
    }
    this.isLoading = true;

    const [, err] = await this.jobCatalogSource.fetch();
    if (err)
      return this.setError(err);

    // Fetch teams for permissions
    await this.store.teamManager.apiEnsureTeams();
    this.setLoaded();
  }

  @action
  unmounted() {
    this.reset();
  }

  @action
  private getTabFromQuery(view: UserDashboardView): NavigationTabs {
    if (view === 'zoom' && this.zoomIntegrationTabState.enabled) return 'Zoom';
    if (view === 'onedrive' && this.oneDriveTabState.enabled) return 'OneDrive';
    if (view === 'gdrive' && this.googleDriveTabState.enabled) return 'Google Drive';

    if (!!view) this.navigateToTab('My Uploads');
    return 'My Uploads';
  }

  @action
  navigateToTab(tab: NavigationTabs) {
    const { uploadService } = this.store;

    switch (tab) {
      case 'Google Drive':
        return uploadService.navigateToGoogleDriveTab();
      case 'Zoom':
        return uploadService.navigateToZoomTab();
      case 'OneDrive':
        return uploadService.navigateToOneDriveTab();
      default:
        return uploadService.clearLibraryTabNavigation();
    }
  }

  @action
  setPagesize(pageSize: number) {
    this.googleDriveTabState.setPagesize(pageSize)
    this.zoomIntegrationTabState.setPagesize(pageSize);
    this.oneDriveTabState.setPagesize(pageSize);
    this.jobCatalogSource.setPagesizeValue(pageSize);
  }

  // #region State helpers
  @action
  reset() {
    this.isLoading = false;
    this.error = null;
    this.activeTab = 'My Uploads';

    this.jobCatalogSource.reset();
  }

  @action
  private setError(error?: Error) {
    if (!error)
      error = pageError();

    this.isLoading = false;
    this.error = error;
  }

  @action
  private setLoaded() {
    this.isLoading = false;
    this.error = null;
  }
  // #endregion
}