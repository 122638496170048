import './layout.scss';
import React from 'react';

import { HTMLDivProps } from '../../core';
import { PageBackButton } from './pageBackButton';

type Props = HTMLDivProps<{
  showBackButton?: boolean;
}>

/**
 * Header to be used for splash pages (those which contain a single specific functionality, like upload or video topic selector)
 */
export const BasicPageHeader = ({
  showBackButton = true,
  ...props
}: Props) => {

  return (
    <header className="basic-page-header">
      {showBackButton && !props.children &&
        <div className="tpad4 lpad4">
          <PageBackButton />
        </div>}

      {props.children}
    </header>
  );
}