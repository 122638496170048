import { action, computed, makeObservable } from 'mobx';
import { JobSourceType } from '@clipr/lib';

import { Message, StoreNode } from '../../store';
import { JobCatalogSource, JobModel, Team } from '../../entities';
import { CatalogState } from '../../components/layout/catalogState';
import { Store } from '../../store/store';
import { Error } from '../../core/error';
import { ScheduleStreamWindowState } from '../../components/jobs/scheduleStreamWindowState';
import { StreamAssetsWindowState } from '../../components/jobs/streamAssetsWindowState';
import { getApiTeamError } from '../../api';
import { InlineAuthFlowController } from '../../services/auth/controllers/inlineAuthFlowController';
import { RouteContext } from '../../routes/routeContext';
import { AuthFlowResponse } from '../../services/auth/authFlowSchema';
import { AsyncResult } from '../../core';
import { WidgetState } from '../widgetStateMixin';

import { INIT_DEBUGGER, TRACE } from '../../core/debug/debugMacros';

export class LiveStreamQueueWidgetState
  extends WidgetState(StoreNode) {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    INIT_DEBUGGER(this, {
      color: 'deeppink'
    });

    this.jobCatalogSource = new JobCatalogSource(this.store, {
      teamId: () => this.teamId,
      jobFilter: (job: JobModel) => job.isLive && job.ownerTeamId === this.teamId,
      pageSize: 20
    });

    this.jobCatalog = new CatalogState(this.store, {
      syncStatus: () => this.jobCatalogSource.syncStatus,
      isEndOfList: () => this.jobCatalogSource.isEndOfList
    });
    this.jobCatalog.listen((msg) => {
      switch (msg.type) {
        case 'scrollToBottom':
        case 'loadMore':
          this.jobCatalogSource.fetchMore();
          break;
      }
    });
  }

  readonly nodeType = 'LiveStreamQueueWidget';

  readonly inlineAuthFlowController = new InlineAuthFlowController(this.store);

  readonly jobCatalogSource: JobCatalogSource;
  readonly jobCatalog: CatalogState;

  @computed get teamId(): string | null {
    return this.widgetService.teamId;
  }

  @computed get showProfile(): boolean {
    return this.widgetParams.showProfile ?? true;
  }

  @computed get showTopicTags(): boolean {
    return this.widgetParams.showTopicTags ?? true;
  }

  @computed get team(): Team | null {
    return this.store.teamManager.getTeam(this.teamId);
  }

  @action
  async attached(routeContext: RouteContext) {
    TRACE(this, `attached()`, { routeContext }, '\n', this.__traceState);

    this.reset();
    this.baseAttached(routeContext);

    this.emit('Mounted', {
      teamId: this.teamId
    });

    this.bindExternalListeners();

    this.setLoading();

    await this.load();
  }

  @action
  detached() {
    TRACE(this, `detached()`, this.__traceState);

    this.baseDetached();
    this.reset();

    this.emit('Unmounted');
  }

  @action
  async load() {
    TRACE(this, `attached()`, this.__traceState);

    this.setLoading();

    if (!this.team && this.teamId) {
      const [, teamErr] = await this.store.teamManager.apiFetchTeam({ id: this.teamId });
      if (teamErr || !this.team) {
        const decodedError = getApiTeamError(teamErr);
        return this.setError(decodedError);
      }
    }

    this.jobCatalogSource.setFilterFieldValue('sourceType', JobSourceType.Live, false);
    this.jobCatalogSource.setSortFieldValue('scheduledDateTime', 'desc', false);

    const [, jobsErr] = await this.jobCatalogSource.fetch();
    if (jobsErr)
      return this.setError();

    this.setLoaded();
  }

  private bindExternalListeners() {
    const { scheduleStreamWindow, assetsWindow } = this.store;

    scheduleStreamWindow.listen(
      this.scheduleStreamWindowListener)
    assetsWindow.listen(
      this.streamAssetsWindowListener)
  }

  private unbindExternalListeners() {
    const { scheduleStreamWindow, assetsWindow } = this.store;

    assetsWindow.unlisten(
      this.streamAssetsWindowListener)
    scheduleStreamWindow.unlisten(
      this.scheduleStreamWindowListener);
  }

  private scheduleStreamWindowListener = action((msg: Message<ScheduleStreamWindowState>) => {
    switch (msg.type) {
      case 'jobUpdated':
        this.refreshJobs();
        break;
    }
  });

  private streamAssetsWindowListener = action((msg: Message<StreamAssetsWindowState>) => {
    switch (msg.type) {
      case 'jobUpdated':
        this.refreshJobs();
        break;
    }
  });

  @action
  handleLoginButtonClick = async (evt: React.MouseEvent) => {
    this.error = null;
    return this.inlineLogin();
  }

  @action
  handleLogoutButtonClick = async (evt: React.MouseEvent) => {
    this.error = null;
    return this.inlineLogout();
  }

  @action
  private async inlineLogin(): AsyncResult<AuthFlowResponse> {
    const { routeContext } = this;
    if (!this.isAttached || !routeContext)
      return [null, new Error('InvalidComponentState')];

    const [flowRes, flowErr] = await this.inlineAuthFlowController.login(routeContext);
    if (flowErr)
      return [null, flowErr];

    await this.load();
    return [flowRes!];
  }

  @action
  private async inlineLogout(): AsyncResult<AuthFlowResponse> {

    const { authService } = this.store;
    const { routeContext } = this;
    if (!this.isAttached || !routeContext)
      return [null, new Error('InvalidComponentState')];

    const [flowRes, flowErr] = await this.inlineAuthFlowController.logout(routeContext);
    if (flowErr)
      return [null, flowErr];

    if (!authService.context) {
      // we have been logged out and a redirect should be expected
      // TODO: add check to see that a redirect was returned
      return [flowRes!];
    }

    await this.load();

    return [flowRes!];
  }

  async refreshJobs() {
    this.isLoading = true;
    await this.jobCatalogSource.fetch();
    this.isLoading = false;

    return;
  }

  @action
  reset() {
    this.baseReset();
    this.unbindExternalListeners();

    this.inlineAuthFlowController.reset();
    this.jobCatalogSource.reset();
  }

  private get __traceState() {
    return {
      ...this.__baseTraceState
    }
  }
}