import './input.scss';
import './toggleInput.scss';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { ReactEventHandler, ReactNode, useCallback } from 'react';
import { InputState } from '.';
import { assert, Maybe, ObjectLiteral } from '../../core';
import { InfoButton } from '../infoModal';

export enum ToggleLayout {
  Default = 'default',
  Buttons = 'buttons'
}

type Props = {
  toggled?: boolean;
  className?: string | ObjectLiteral;
  label?: Maybe<string>;
  disabled?: boolean;
  isHovered?: boolean;
  readOnly?: boolean;
  name: string;
  description?: string;
  toggledLabel?: string;
  notToggledLabel?: string;
  toggleLayout?: ToggleLayout;
  infoModalTitle?: string;
  infoModalContent?: string | ReactNode;
  infoModalClassName?: string;
  onChange: ReactEventHandler;
};

export const ToggleInput = observer(
  ({
    toggled,
    className,
    isHovered,
    disabled,
    label,
    description,
    toggleLayout = ToggleLayout.Default,
    toggledLabel,
    notToggledLabel,
    infoModalContent,
    infoModalTitle,
    infoModalClassName,
    onChange,
    ...props
  }: Props) => {
    className = classNames(className, {
      hover: isHovered,
    });

    const toggleClassName = classNames('toggle toggle-input input', className, {
      toggled: toggled,
      'button-layout': toggleLayout === ToggleLayout.Buttons,
      'default-layeout': toggleLayout === ToggleLayout.Default
    });

    return (
      <div className="toggle-input-wrapper">
        <div
          className={toggleClassName}
          aria-disabled={disabled}
          aria-checked={toggled}
          onClick={onChange}>
          {
            toggleLayout === ToggleLayout.Default && (
              <>
                <div className="box">
                  <div className="toggle-button">
                    <div className="inner-button"></div>
                  </div>
                </div>
                {label && <span className="text label">
                  {label}
                  {infoModalContent &&
                    <InfoButton
                      title={infoModalTitle}
                      content={infoModalContent}
                      windowClassName={infoModalClassName} />}
                </span>}
              </>
            )
          }
          {
            toggleLayout === ToggleLayout.Buttons && (
              <>
                {label && <span className="text label">
                  {label}
                  {infoModalContent &&
                    <InfoButton
                      title={infoModalTitle}
                      content={infoModalContent}
                      windowClassName={infoModalClassName} />}
                </span>}
                <div className="button-wrapper">
                  <div className={`button ${toggled ? 'active' : ''}`}>{toggledLabel}</div>
                  <div className={`button ${!toggled ? 'active' : ''}`}>{notToggledLabel}</div>
                </div>
              </>
            )
          }
        </div>

        {description && <div className="toggle-description">{description}</div>}
      </div>
    );
  }
);

type ControlledProps = React.InputHTMLAttributes<HTMLInputElement> & {
  value?: string;
  label?: string;
  toggledLabel?: string;
  notToggledLabel?: string;
  model: InputState;
  showEmptyItem?: Maybe<boolean>;
  className?: string | ObjectLiteral;
  toggleLayout?: ToggleLayout;
};

export const ToggleInputControlled = observer(
  ({ model, className, ...props }: ControlledProps) => {
    assert(
      model instanceof InputState,
      `The 'model' passed to ToggleInputControlled must be an instance of InputState. Instead received ${model}.`
    );

    const handleChange = useCallback(
      (evt) => {
        return model.handleChange(evt, !model.value);
      },
      [model]
    );

    className = model.getClassName('checkbox-input-controlled', { className });

    return (
      <ToggleInput
        name={model.name}
        className={className}
        toggled={model.value && model.value !== 'false' ? true : false}
        disabled={model.disabled}
        label={model.label}
        isHovered={model.isHovered}
        onChange={handleChange}
        onFocus={model.handleFocus}
        onBlur={model.handleBlur}
        onPointerEnter={model.handlePointerEnter}
        onPointerLeave={model.handlePointerLeave}
        {...props}
      />
    );
  }
);
