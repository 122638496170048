import './media.scss';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';

import { Maybe } from '../../core';
import { CardOverlay } from './jobCard'
import { JobModel } from '../../entities';
import { Thumb } from '../media/thumb';
import { PlaySVGIcon } from '../svg';

export const JobListCardPreview = observer(
  ({ model }: { model: JobModel }) => {

    let previewElem: Maybe<ReactNode>;
    const mediaAggregatedStatus = model.mediaAggregatedStatus;
    const liveClass = classNames('card-thumb dock', {
      'live-card-placeholder': !model.posterURL && !model.store.uiService.logo,
    });

    switch (mediaAggregatedStatus) {
      case 'IngestProcessing':
      case 'LiveEnded':
        previewElem = (
          <div className="card-status progress dock">
            <Thumb source={model} className="card-thumb dock" />
            <CardOverlay model={model} />
          </div>
        );
        break;

      case 'Failed':
        previewElem = (
          <div className="card-status progress dock">
            <Thumb source={model} className="card-thumb dock" />
            <CardOverlay model={model} />
          </div>
        );
        break;

      case 'LiveStreaming':
        previewElem = (
          <div className="card-status progress dock status-done">
            <Thumb source={model} className={liveClass} />
            <div className="play-icon">
              <PlaySVGIcon />
            </div>
          </div>
        )
        break;

      case 'LiveWaiting':
        previewElem = (
          <div className="card-status progress dock status-done">
            <Thumb source={model} className={liveClass} />
            <div className="play-icon">
              <PlaySVGIcon />
            </div>
          </div>
        )
        break;

      default:
      case 'IngestCompleted':
        previewElem = (
          <div className="card-status progress dock status-done">
            <Thumb source={model} className="card-thumb dock" />
            <div className="play-icon">
              <PlaySVGIcon />
            </div>
          </div>
        );
        break;
    }

    if (!model.isPublished) {
      previewElem = (
        <div className="card-status progress dock">
          <Thumb source={model} className="card-thumb dock" />
          <CardOverlay model={model} />
        </div>
      );
    }

    return <>{previewElem}</>;
  }
);