import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { UpdateProfileInput } from '../../../../../libs/lib/dist';
import { AuthService } from '../../services/auth';
import { PlayerTutorialStep } from './playerTutorialWindowState';

export class PlayerTutorialConfirmationWindowState
  extends StoreNode {
  readonly nodeType = 'PlayerTutoriaConfirmationlWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }
  readonly authService: AuthService = this.store.auth;
  readonly window = new WindowState(this.store);

  @computed get isWidgetMode(): boolean {
    return this.store.widgetService.getIsWidgetMode();
  }

  @observable
  showPlayerTutorial: boolean = true;

  @observable
  steps: PlayerTutorialStep[] = [];

  @action
  initTutorial() {
    this.dispatch('Overlays', 'closeWindow');
    this.dispatch('openPlayerTutorialWindow', {
      steps: this.steps
    });
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open(payload: any) {
    this.steps = payload.steps;
    this.dispatch('Overlays', 'openWindow', { name: 'PlayerTutoriaConfirmationlWindow' });
  }

  @action
  close() {
    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }

  @action
  watchLater() {
    // Close current window
    this.emit('close');
    this.emit('watchLater');
    this.dispatch('Overlays', 'closeWindow');

    // Open tooltip for help button
    if (!this.isWidgetMode) this.dispatch('openPlayerTutorialHelpButtonTooltip');
  }

  @action
  async updateUserPermissions(value: boolean) {
    this.showPlayerTutorial = !value;

    const params: { args: UpdateProfileInput } = {
      args: {
        showPlayerTutorial: this.showPlayerTutorial,
      }
    };

    // TODO: replace with unified flow
    await this.store.api.updateProfile(params);
    await this.authService.runRefreshContextFlow();
  }
}