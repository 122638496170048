import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ExternalLibrary, ExternalLibrarySource } from '@clipr/lib';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import Routes, { UserDashboardView } from '../../routes';
import { ApiVariables } from '../../api/apiSchema';
import { notifyError, notifySuccess } from '../notifications';
import { Error, pageError } from '../../core/error';
import { ApiError } from '../../api/apiError';
import { GoogleDriveService, OneDriveService, ZoomService } from '.';

export type LibraryLabels = 'Google Drive' | 'Zoom' | 'OneDrive';
export type LibraryProvider = GoogleDriveService | OneDriveService | ZoomService;

export type LibraryProps = {
  librarySource: ExternalLibrarySource,
  libraryLabel: LibraryLabels,
  routeView: UserDashboardView,
  enabled: boolean
}
export class LibraryServiceBase
  extends StoreNode {

  constructor(store: Store, { librarySource, libraryLabel, routeView, enabled }: LibraryProps) {
    super(store);
    makeObservable(this);

    this.librarySource = librarySource;
    this.libraryLabel = libraryLabel;
    this.routeView = routeView;
    this.enabled = enabled;
  }

  @observable libraryId: string | null = null;
  @observable isLoading: boolean = false;
  @observable authorizationCode: string | null = null;
  @observable error: Error | ApiError | null = null;

  readonly enabled: boolean;
  readonly librarySource: ExternalLibrarySource;
  readonly libraryLabel: LibraryLabels;
  readonly routeView: UserDashboardView;

  // This is a placeholder for each library auth url implementation
  get authUrl(): string { return ''; }

  @computed
  get isConnected(): boolean {
    return !!this.library;
  }

  @computed
  get library(): ExternalLibrary | null {
    if (!this.libraryId) return this.store.libraryService.getLibraryBySource(this.librarySource);

    return this.store.libraryService.getLibraryById(this.libraryId);
  }


  @action
  async connect(redirectUri: string) {
    if (!this.authorizationCode) return this.handleAuthError();

    const vars: ApiVariables<'connectExternalLibrary'> = {
      input: {
        source: this.librarySource,
        authorizationCode: this.authorizationCode,
        name: this.libraryLabel,
        redirectUri: redirectUri
      }
    }
    const [library, err] = await this.store.libraryService.connectLibrary(vars);

    if (err) return this.handleAuthError(err);

    this.libraryId = library?.id || null;
    this.authorizationCode = null;

    notifySuccess(this, `Connected to ${this.libraryLabel}.`);

    return [library];
  }

  @action
  handleAuthError(err?: ApiError) {
    notifyError(this, 'Could not authorize.');
    this.store.goTo(Routes.userDashboard({ view: this.routeView }));
    return [null, err];
  }

  @action
  setError(error?: Error | ApiError) {
    if (!error)
      error = pageError();

    this.isLoading = false;
    this.error = error;
  }

  @action
  async disconnect() {
    if (!this.library) return [null];

    this.isLoading = true;
    const [res, err] = await this.store.libraryService.deleteLibrary(this.library.id);

    if (err) {
      runInAction(() => {
        if (this.enabled) {
          notifyError(this, `Could not disconnect from ${this.libraryLabel}.`);
        }
        this.isLoading = false;
      })
      return [null, err];
    }
    runInAction(() => {
      this.isLoading = false;
    })

    if (this.enabled) {
      notifySuccess(this, `${this.libraryLabel} successfully disconnected.`);
    }
    if (this.error) {
      this.error = null;
    }
    return [res];
  }

}