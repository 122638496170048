import { Theme } from '../../services/ui/utils';
import { WidgetAuthMode } from '../../services/widget';
import { HTMLMediaEventType } from '../../components/playerAdapter/htmlMediaSchema';

export type PlayerWidgetIFrameParams = {
  jobId: string,
  teamId?: string | null,
  time?: number | 'none',
  momentId?: string,
  auth?: WidgetAuthMode | null,
  showIndex?: boolean | null,
  showComments?: boolean | null,
  showTranscript?: boolean | null,
  allowFullscreen?: boolean | null,
  showProfile?: boolean | null,
  showTopicTags?: boolean | null,
  showEmbedIcon?: boolean | null,
  showHelp?: boolean | null,
  disableReactions?: boolean | null,
  disableComments?: boolean | null,
  disableIndex?: boolean | null,
  disableTranscript?: boolean | null,
  autoplay?: boolean | null,
  allowShare?: boolean | null,
  allowDownload?: boolean | null,
  theme?: Theme | null,
  customRedirect?: string | null,
}
// TODO This should be a Set with a speicific type to avoid having multiple
// instances of the same value or completely wrong values
const allowAttributes = ['autoplay', 'encrypted-media', 'picture-in-picture', 'clipboard-write', 'fullscreen'];

export function getPlayerWidgetIFrameCode(params: PlayerWidgetIFrameParams) {

  /** Example from youtube
   * <iframe 
   *  width="1280" 
   *  height="555" 
   *  src="https://www.youtube.com/embed/4Y6EVouZm-I" 
   *  title="YouTube video player" 
   *  frameborder="0" 
   *  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
   *  allowfullscreen>
   * </iframe>
   */

  // TODO: Define the default value for the params
  const iframe = document.createElement('iframe');
  iframe.src = getPlayerWidgetIFrameSrc(params).toString();
  iframe.width = '800';
  iframe.height = '450';
  iframe.title = 'CLIPr Widget';

  iframe.setAttribute('frameborder', '0');
  iframe.setAttribute('allow', allowAttributes.join('; '));
  iframe.setAttribute('sandbox', 'allow-same-origin allow-scripts allow-popups allow-forms allow-popups-to-escape-sandbox allow-downloads allow-orientation-lock');

  let code = iframe.outerHTML;


  // DIRTY HACK TO REVERSE URL ESCAPING
  code = code.replaceAll(/&amp;/gi, '&');
  return code;
}

export function getPlayerWidgetIFrameSrc(params: PlayerWidgetIFrameParams) {
  const url = buildUrlSearchParams(params, new URL(`${window.location.origin}/widget/${params.jobId}`));
  return url;
}

export function buildUrlSearchParams(params: PlayerWidgetIFrameParams, url: URL, jobId?: string) {
  const time = params.time === 'none' ? '' : (params.time ?? 0).toString();
  const theme = params.theme && params.theme !== Theme.Default ? params.theme.toLocaleLowerCase() : 'dark';

  if (jobId) {
    url.searchParams.append('jobId', jobId);
  }

  if (params.momentId) {
    url.searchParams.append('time', time);
    url.searchParams.append('momentId', params.momentId.toString());
  }

  if (params.teamId) {
    url.searchParams.append('teamId', params.teamId.toString());
  }

  if (params.customRedirect) {
    const redirectUrl = encodeURIComponent(decodeURIComponent(params.customRedirect));
    url.searchParams.append('customRedirect', redirectUrl);
  }

  // TODO: Refactor to use ParamsSchema.
  url.searchParams.append('theme', theme);
  url.searchParams.append('autoplay', params.autoplay?.toString() ?? 'true');
  url.searchParams.append('allowShare', params.allowShare?.toString() ?? 'true');
  url.searchParams.append('allowDownload', params.allowDownload?.toString() ?? 'true');
  url.searchParams.append('allowFullscreen', params.allowFullscreen?.toString() ?? 'true');
  url.searchParams.append('showHelp', params.showHelp?.toString() ?? 'true');
  url.searchParams.append('disableReactions', params.disableReactions?.toString() ?? 'false');
  url.searchParams.append('disableComments', params.disableComments?.toString() ?? 'false');
  url.searchParams.append('disableTranscript', params.disableTranscript?.toString() ?? 'false');
  url.searchParams.append('disableIndex', params.disableIndex?.toString() ?? 'false');
  url.searchParams.append('showIndex', params.showIndex?.toString() ?? 'true');
  url.searchParams.append('showComments', params.showComments?.toString() ?? 'false');
  url.searchParams.append('showTranscript', params.showTranscript?.toString() ?? 'false');
  url.searchParams.append('showProfile', params.showProfile?.toString() ?? 'true');
  url.searchParams.append('showTopicTags', params.showTopicTags?.toString() ?? 'true');
  url.searchParams.append('showEmbedIcon', params.showEmbedIcon?.toString() ?? 'true');

  return url;
}

/**
 * Types and constants to be used by the PlayerWidgetAdapter to comply with the Player.js approach.
 */
export type MediaEventType = HTMLMediaEventType | 'ready';
export const Context = 'player.js';
export const Version = '1.0.0';
export const Events: MediaEventType[] = [
  'ready',
  'play',
  'pause',
  'timeupdate',
  'ended'
];
export type MethodType = 'play' |
  'pause' |
  'getPaused' |
  'mute' |
  'unmute' |
  'getMuted' |
  'setVolume' |
  'getVolume' |
  'getDuration' |
  'setCurrentTime' |
  'getCurrentTime' |
  'setLoop' |
  'getLoop' |
  'addEventListener' |
  'removeEventListener';
export const Methods: MethodType[] = [
  'play',
  'pause',
  'getPaused',
  'mute',
  'unmute',
  'getMuted',
  'setVolume',
  'getVolume',
  'getDuration',
  'setCurrentTime',
  'getCurrentTime',
  // 'setLoop',
  // 'getLoop',
  'addEventListener',
  'removeEventListener'
]