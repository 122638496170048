import { StoreNode } from '../../store';
import { RouteContext } from '../../routes/routeContext';
import { AsyncResult } from '../../core';
import { Permissions } from '../../services/auth/authFlowSchema';
import { IRouteInjector } from '../../routes/routeSchema';
import { AnalyticsWidgetRouteParams } from './analyticsWidgetSchema';

export class AnalyticsWidgetInjector
  extends StoreNode
  implements IRouteInjector {

  async fetchPermissions(routeContext: RouteContext<AnalyticsWidgetRouteParams>): AsyncResult<Permissions, any> {
    const { AnalyticsPermissionsSource } = await import('../../entities/analyticsPermissionsSource');

    const teamId: string | null = routeContext.params?.teamId ?? null;
    const source = new AnalyticsPermissionsSource(this.store, {
      teamId
    });

    return source.fetchPermissions();
  }
}