import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { DeleteBookmarkListWindowState } from './deleteBookmarkListWindowState';
import { Button } from '../input';

type Props = {
  model: DeleteBookmarkListWindowState;
  visible?: boolean;
};

export const DeleteBookmarkListWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      model={model.window}
      visible={props.visible}
      isLoading={model.isLoading}>
      <WindowHeader model={model.window} title="Delete" />

      <hr />

      <div className="window-content">
        <p className="p-bold">Are you sure you want to delete this bookmark list?</p>
        <p>This action cannot be undone.</p>
      </div>

      <hr />

      <footer className="window-footer">
        <div className="btn-group right">
          <Button
            className="primary btn-outline medium"
            label="Cancel"
            onClick={() => model.close()}
          />
          <Button
            className="red medium"
            label="Delete"
            onClick={() => model.submit()}
          />
        </div>
      </footer>
    </Window>
  );
});
