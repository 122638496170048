import { ObjectLiteral } from '../core';

export enum ComponentVisibility {
  Visible = 'Visible',
  Hidden = 'Hidden'
}

export enum ComponentName {
  Player = 'Player',
  JobCard = 'JobCard',
  PlayerIndex = 'PlayerIndex',
  PlayerIndexCardEditButton = 'PlayerIndexCardEditButton',
  PlayerIndexCardBookmarkButton = 'PlayerIndexCardBookmarkButton',
  PlayerComments = 'PlayerComments',
}

export interface IComponentPolicy {
  visibility: ComponentVisibility;
  isReadOnly: boolean;
  isDisabled: boolean;
}

export enum ComponentMountStatus {
  Mounting = 'Mounting',
  Mounted = 'Mounted',
  Unmounting = 'Unmounting',
  Unmounted = 'Unmounted'
}

export interface IComponentState {
  attached(params?: ObjectLiteral): void | Promise<void>;
  detached(params?: ObjectLiteral): void | Promise<void>;
}