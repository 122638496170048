import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { Maybe } from '../../core/types';
import { SettingsSVGIcon } from '../svg';
import { DotSpinner } from '../loader/dotSpinner';

type PlayerSectionStatus =
  'done' |
  'processing' |
  'failed';

type Props = {
  visible: boolean,
  status: PlayerSectionStatus,
  label: string
}

export const PlayerSectionOverlay = observer(({
  status,
  label,
  visible
}: Props) => {

  let content: Maybe<ReactNode>;

  const cx = classNames('player-section-overlay', {
    done: status === 'done',
    processing: status === 'processing',
    failed: status === 'failed',
  });

  switch (status) {
    case 'processing':
      content = <div className="overlay-content">
        <SettingsSVGIcon />
        <p>
          <b>CLIPr is processing the {label || 'data'}</b><br />
          This will take about half the length of the video,<br />
          we will notify you when will become available.
        </p>
        <DotSpinner className="small" />
      </div>
      break;
    default:
      content = null;
      break;
  }

  return <div className={cx} aria-hidden={!visible}>{content}</div>;
});