import { action, makeObservable, observable } from 'mobx';
import { Message, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { WindowState } from '../overlays';

export type RemoveAccessOpenParams = {
  onSubmit: () => void
  modalMessage?: string,
  title?: string
}

export class CloseWindowConfirmationModalState
  extends StoreNode {

  readonly nodeType = 'CloseWindowConfirmationModal';

  constructor(store: Store, props?: any) {
    super(store, props);
    makeObservable(this);
    this.window.listen(
      this.windowListener);
  }
  @observable isLoading: boolean = false;
  @observable onSubmit: (() => void) | null = null;
  @observable modalMessage: string | null = null;
  @observable title: string | null = null;

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
        // case 'outsideClick':
        if (this.isLoading)
          return;
        this.close('close');
        break;
    }
  }
  readonly window = new WindowState(this.store);

  @action
  async submit() {
    this.close();
    if (this.onSubmit)
      this.onSubmit();
  }

  @action
  open(params: RemoveAccessOpenParams) {
    this.dispatch('Overlays', 'openWindow', { name: 'CloseWindowConfirmationModal' });
    this.onSubmit = params.onSubmit;
    this.modalMessage = params.modalMessage || null;
  }

  @action
  close(msg?: string) {
    this.dispatch('Overlays', 'closeWindow');
    if (msg) this.emit(msg);
  }
}