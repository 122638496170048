import { makeObservable } from 'mobx';
import { Store } from '../../../store/store';
import { StoreNode } from '../../../store';
import { AuthStepOrchestrator } from '../authStepOrchestrator';
import { AsyncResult } from '../../../core';
import { AuthFlowName, AuthFlowResponse, IAuthFlow } from '../authFlowSchema';
import { RouteContext } from '../../../routes/routeContext';
import { LibraryName } from '../../libraries/librarySchema';
import { Error } from '../../../core/error';

type Props = {
  routeContext: RouteContext;
  libraryName: LibraryName;
};

export type AuthorizeLibraryFlowParams = Props;

export class AuthorizeLibraryFlow
  extends StoreNode
  implements IAuthFlow {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);
  }

  readonly flowName = AuthFlowName.AuthorizeLibrary;
  readonly isLibraryFlow = true;

  readonly orchestrator = new AuthStepOrchestrator(this.store);

  get routeContext(): RouteContext {
    return this.props.routeContext;
  }

  get libraryName(): LibraryName {
    return this.props.libraryName;
  }

  async run(): AsyncResult<AuthFlowResponse> {
    return [null, new Error('InternalError', `The AuthorizeLibraryFlow can only be restored after redirect.`)];
  }

  async restoreAfterRedirect(): AsyncResult<AuthFlowResponse> {

    const { orchestrator, routeContext, libraryName } = this;
    const { libraryService } = this.store;

    // we need to auto login because connecting the library requires authentication
    // we assume that the user was already logged in before connecting the library
    // in the isolated scenario in which the authentication expires just before the redirect occurrs
    // we just fail the flow, which will prompt the user to log in again and they will be
    // redirected back to the connect library screen (which was the last private route)

    const [, contextErr] = await orchestrator.initContextFromSessionAndCommit();
    if (contextErr)
      return orchestrator.setRedirectToLastPrivateRoute({ error: contextErr });

    const [libraryPermit, libraryPermitErr] = orchestrator.createLibraryPermitFromRoute(routeContext, libraryName);
    if (libraryPermitErr)
      return orchestrator.setRedirectToLastPrivateRoute({ error: libraryPermitErr });

    const { code } = libraryPermit!;
    const libraryProvider = libraryService.getProviderByName(libraryName);
    libraryProvider.authorizationCode = code;

    const [, connectErr] = await libraryProvider.connectLibrary();
    if (connectErr)
      return orchestrator.setRedirectToLastPrivateRoute({ error: connectErr });

    return orchestrator.setRedirectToLastPrivateRoute();
  }
}