import { Enum } from './types';

type DecodeEnumOptions = Partial<{
  /** If true, the decode will return the member value only if the search value has the exact same casing as the match. */
  caseSensitive: boolean,
  /** If true, the matching will also take into account the type of the search value and the member values (e.g. '0' will not match 0) */
  strictTypes: boolean,
  /** If true, a match will be found if the search value matches one of the Enum keys. */
  useKeys: boolean,
  /** If true, a match will be found if the search value matches one of the Enum member value strings. */
  useStrings: boolean,
  /** If true, a match will be found if the search value matches one of the Enum member value numbers. */
  useNumbers: boolean
}>;

const DefaultDecodeEnumOptions: DecodeEnumOptions = {
  caseSensitive: false,
  strictTypes: true,
  useKeys: true,
  useStrings: true,
  useNumbers: true
}

export function decodeEnum(val: string | number, enumObj: Enum, opts: DecodeEnumOptions = DefaultDecodeEnumOptions) {

  // merge options
  opts = {
    ...DefaultDecodeEnumOptions,
    ...opts
  };

  // case and typing transform for all values
  const transform = (arg: string | number): string | number => {
    let val = arg;

    if (!opts.strictTypes)
      val = val.toString();

    if (!opts.caseSensitive && typeof val === 'string')
      val = val.toLocaleLowerCase();

    return val;
  }

  val = transform(val);

  const enumKeys = Object.keys(enumObj);
  
  for (const enumKey of enumKeys) {
    const enumVal = enumObj[enumKey];
    // check for matches over the key and the value
    if (
      transform(enumKey) === val ||
      transform(enumVal) === val) {
      return enumVal;
    }
  }

  return null;
}