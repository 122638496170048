import { makeObservable, computed, observable, action } from 'mobx';
import { RawDraftContentState } from 'draft-js';

import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { JobModel, User } from '../../entities';
import { CommentThreadState } from './commentThreadState';
import { CommentInputState } from './commentInputState';
import { Comment } from '../../entities/comment';
import { CommentDeleteWindowState } from './commentDeleteWindowState';

type Props = {
  comment: Comment,
  thread?: CommentThreadState
};

const CONTENT_LINE_HEIGHT = 18;
const CONTENT_OFFSET = 8;

export class CommentState
  extends StoreNode {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    if (!this.isReply) {
      this.replyInput = new CommentInputState(this.store, {
        jobId: this.jobId,
        comment: this,
        isReply: true
      });
    }
  }

  readonly replyInput: CommentInputState | null = null;

  readonly editInput: CommentInputState = new CommentInputState(this.store, {
    jobId: this.jobId,
    comment: this,
    isEdit: true
  });

  domContent: HTMLElement | null = null;

  @computed get comment(): Comment {
    return this.resolvedProps.comment;
  }

  @computed get commentId(): string {
    return this.comment.id;
  }

  @computed get videoTime(): number | null {
    return this.comment.videoTime;
  }

  @computed get text(): string | null {
    return this.comment.text;
  }

  @computed get jobId(): string | null {
    return this.comment.jobId;
  }
  @computed get job(): JobModel | null {
    return this.comment.job;
  }

  @computed get userId(): string | null {
    return this.comment.userId;
  }
  @computed get user(): User | null {
    return this.comment.actualUser;
  }

  @computed get parentId(): string | null {
    return this.comment.parentId;
  }

  @computed get thread(): CommentThreadState {
    return this.resolvedProps.thread;
  }

  @computed get videoTimeLabel(): string {
    return this.comment.videoTimeLabel;
  }

  @computed get isReply(): boolean {
    return this.comment.isReply;
  }

  @computed get content(): RawDraftContentState | null {
    return this.comment.content;
  }

  @computed get ownerName(): string | null {
    return (
      this.user?.name ||
      this.user?.username ||
      this.user?.email ||
      this.user?.id ||
      null);
  }

  @computed get isCurrent(): boolean {
    return this.videoTime === this.store.userPlayerPage.player.currentTime;
  }

  @computed get isLoading(): boolean {
    return this.replyInput?.isLoading || this.editInput?.isLoading;
  }

  @computed get isActive(): boolean {
    return (
      this.thread?.isActive ||
      this.showReplyInput ||
      this.showEditInput ||
      this.hasMenuOpened
    );
  }

  @observable isHovered: boolean = false;

  @observable hasContentExpanded: boolean = true;

  @observable hasMenuOpened: boolean = false;

  @observable showReplyInput: boolean = false;

  @observable showEditInput: boolean = false;

  @action
  attachDOMContent(content: HTMLElement) {
    this.domContent = content;

    if (this.domContent.clientHeight > (CONTENT_LINE_HEIGHT * 2 + CONTENT_OFFSET)) {
      this.hasContentExpanded = false;
    }
  }

  @action
  detachDOMContent() {
    this.domContent = null;
  }

  @action
  toggleContentExpanded() {
    this.hasContentExpanded = !this.hasContentExpanded
  }

  @action
  handleMenuOpened(isOpened: boolean) {
    this.hasMenuOpened = isOpened;
  }

  @action
  toggleReplyInput() {
    if (!this.replyInput) return;
    this.showReplyInput = !this.showReplyInput;

    if (!this.showReplyInput) this.replyInput.clear();

    this.thread?.setHasReplyInput(this.showReplyInput);

    if (this.showReplyInput) {
      this.thread.playerComments?.player?.invoke('pause');
      this.thread.playerComments?.scrollOnReply(this.comment);
    }
  }

  @action
  toggleEditInput() {
    // TODO: handle user permission
    this.showEditInput = !this.showEditInput;
    this.editInput.setEditorStateContent();

    if (this.showEditInput) {
      this.thread.playerComments?.player?.invoke('pause');
      this.thread.playerComments?.scrollOnEdit(this.comment);
    }
  }

  @action
  openDeleteWindow() {
    // TODO: handle user permission
    const player = this.thread?.playerComments?.player;
    // this.store.commentDeleteWindow.open({ comment: this });

    const window = this.store.commentDeleteWindow;
    const listener = (msg: Message<CommentDeleteWindowState>) => {
      switch (msg.type) {
        case 'open':
          player?.invoke('enterPause');
          break;

        case 'close':
          window.unlisten(listener);
          // player?.invoke('exitPause');
          break;
      }
    }

    window.listen(listener);
    window.open({ comment: this }); // TODO: should not be comment state, as it describes a state object instead of an entity, change to comment
  }

  @action
  async submitEdit() {
    try {
      const [, err] = await this.editInput.submit();
      if (!err)
        this.toggleEditInput();
    } catch (error) {
      // TODO: handle edit error
      // this.toggleEditInput();
    }
  }

  @action
  handlePointerEnter(evt: PointerEvent) {
    if (!this.thread) return;
    this.thread.handlePointerEnter(evt);
  }

  @action
  handlePointerLeave(evt: PointerEvent) {
    if (!this.thread) return;
    this.thread.handlePointerLeave(evt);
  }
};