import React from 'react';
import { observer } from 'mobx-react-lite';
import { SpeakerCardList } from '../../components/speakers/speakerCardList';
import { MomentSplashState } from './momentSplashState';

type Props = {
  model: MomentSplashState
}

export const SpeakerSelectorContent = observer(({
  model
}: Props) => {
  
  const { selector } = model;

  const {
    filteredSpeakers,
  } = model;

  return (
    <div className="speaker-selector-content splash-content tmar12">

      <h2 className="tcenter vpad8 width36">
        Select what speakers you want to watch
      </h2>

      <section className="vpad6 width48">
        <SpeakerCardList
          model={filteredSpeakers}
          onCardClick={(evt, target) => selector?.toggleSpeaker(target)}
          selectedIds={selector?.selectedSpeakerIds} />
      </section>
    </div>
  );
});