import { computed, makeObservable } from 'mobx';

import isNull from 'lodash/isNull';
import max from 'lodash/max';
import min from 'lodash/min';
import omitBy from 'lodash/omitBy';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

import { getTimeRegionsMergedDuration, ITimeRegion } from '../../core/time';
import { MomentModel } from '../moment';
import { shortDurationLabel } from '../../store';

type Moment = MomentModel;

export type MomentGroupByKey =
  'FirstTopic' |
  'LastTopic' |
  'Topics' |
  'Name' |
  'Speaker' |
  'MomentType';

export type MomentGroupKeyData = {
  firstTopic?: string | null,
  lastTopic?: string | null,
  topics?: string[] | null,
  name?: string | null;
  speakerId?: string | null;
  momentType?: string | null;
}


export type MomentGroupSignature = MomentGroupKeyData & {
  keys: Iterable<MomentGroupByKey>;
}

export type MomentGroupProps = MomentGroupKeyData & {
  keys: Iterable<MomentGroupByKey>;
  moments: Moment[];
}

export class MomentGroup
  implements ITimeRegion {

  constructor(props: MomentGroupProps) {
    makeObservable(this);
    Object.assign(this, props);
    this.keys = new Set(props.keys);
  }

  @computed
  get keyData(): MomentGroupKeyData {
    return omitBy({
      firstTopic: this.firstTopic || null,
      lastTopic: this.lastTopic || null,
      topics: this.topics || null,
      name: this.name || null,
      speakerId: this.speakerId || null,
      momentType: this.momentType || null
    }, isNull);
  }
  @computed
  get keyDataId(): string {
    const { keyData } = this;
    return JSON.stringify(Object.assign({}, this.keyData, {
      topics: keyData.topics ? [...keyData.topics] : undefined
    }));
  }

  readonly keys: Set<string>;

  readonly firstTopic: string | null = null;
  readonly lastTopic: string | null = null;
  readonly topics: string[] | null = null;
  readonly name: string | null = null;
  readonly speakerId: string | null = null;
  readonly momentType: string | null = null;

  readonly moments: Moment[] = [];

  @computed
  get momentIds() {
    return this.moments.map(mom => mom.id);
  }

  @computed
  get startTime() {
    return min(this.moments.map(mom => mom.startTime))!;
  }

  @computed
  get endTime() {
    return max(this.moments.map(mom => mom.endTime))!;
  }

  @computed
  get durationSeconds() {
    return getTimeRegionsMergedDuration(this.moments);
  }

  @computed
  get shortDurationLabel(): string {
    return shortDurationLabel(this.durationSeconds);
  }

  @computed
  get names() {
    return uniq(this.moments.map(mom => mom.name));
  }
}

export function flattenMomentGroups(groups: MomentGroup[]) {
  return uniqBy(groups.map(group => group.moments).flat(), mom => mom.id)
}