import React, { ReactNode } from 'react';
import isArray from 'lodash/isArray';
import {
  TeamWidgetParamsDescription,
  TeamWidgetParamDescriptor,
} from '../../widgets/teamWidget/teamWidgetParams';
import {
  PlayerWidgetParamsDescription,
  PlayerWidgetParamDescriptor,
} from '../../widgets/playerWidget/playerWidgetParams';

type ParamDescriptor =
  | TeamWidgetParamDescriptor
  | PlayerWidgetParamDescriptor;

export const EmbedParamsInfoPopup = () => {
  const formatArray = (arr: ReactNode, newLine: boolean = false) => {
    if (!isArray(arr)) return arr;

    if (newLine) {
      return (
        <>
          {arr.map((i, index) => (
            <p key={index}>{i}</p>
          ))}
        </>
      );
    }
    return arr.join(' | ');
  };

  const teamQueryParams = TeamWidgetParamsDescription.filter(
    (p) => p.paramType === 'query' || !p.paramType
  );
  const teamPathParams = TeamWidgetParamsDescription.filter(
    (p) => p.paramType === 'path'
  );

  const playerQueryParams = PlayerWidgetParamsDescription.filter(
    (p) => p.paramType === 'query' || !p.paramType
  );
  const playerPathParams = PlayerWidgetParamsDescription.filter(
    (p) => p.paramType === 'path'
  );

  const renderTable = (params: ParamDescriptor[]) => (
    <div className="table">
      <div className="headline row">
        <div className="name">Parameter</div>
        <div className="type">Type</div>
        <div className="description">Description</div>
        <div className="default-value">Default Value</div>
      </div>
      {params.map((param: ParamDescriptor, index: number) => {
        return (
          <div className="row" key={index}>
            <div className="name">
              {param.name}{' '}
              {param.required && (
                <small>
                  <i>(required)</i>
                </small>
              )}
            </div>
            <div className="type">{formatArray(param.type) || '-'}</div>
            <div className="description">
              {formatArray(param.description, true) || '-'}
            </div>
            <div className="default-value">{param.defaultValue || '-'}</div>
          </div>
        );
      })}
    </div>
  );
  return (
    <div>
      <p className="p-bold">Controlling the CLIPr Library widgets</p>
      <p>
        You can control how CLIPr loads and works by modifying the parameters in
        the embed code URL when you publish it to your site.
      </p>
      <p>
        For example, to make the Comments pane show up when the player loads,
        change the word <i>"false"</i> in <b>showComments=false</b> to{' '}
        <i>"true"</i>.
      </p>
      <br />

      <p className="p-bold">Library Widget Params</p>
      <br />
      <p>Path:</p>
      {renderTable(teamPathParams)}
      <br />
      <p>Query (optional):</p>
      {renderTable(teamQueryParams)}
      <br />

      <p className="p-bold">Player Widget Params</p>
      <br />
      <p>Path:</p>
      {renderTable(playerPathParams)}
      <br />
      <p>Query (optional):</p>
      {renderTable(playerQueryParams)}

      <p className="note">
        <i>Note:</i> To allow user to play in full screen, make sure you have{' '}
        <b>fullscreen</b> option in iframe <b>allow</b> options. Otherwise, even
        it is set as true in params and button will be available, it will not
        work (no action on click). If you have <b>allowFullscreen=false</b> in
        params, it does not matter if you include the fullscreen option in the
        iframe <b>allow</b> options because the fullscreen button will not be
        displayed.
      </p>
    </div>
  );
};
