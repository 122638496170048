import '../../components/overlays/overlays.scss';

import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import { Placement } from '@popperjs/core/lib';
import { Options as OffsetOptions } from '@popperjs/core/lib/modifiers/offset';
// import { useOutsideClick } from '../../components/hooks';
// @ts-ignore
import CSSVariables from '../../components/css/palette.scss';
import { Maybe } from '../../core';
import { OverlayState } from './overlayState';

const FadeTimeout = {
  enter: parseInt(CSSVariables.windowEnterTimeout),
  exit: parseInt(CSSVariables.windowExitTimeout)
};

type Props = PropsWithChildren<{
  model: OverlayState,
  className?: Maybe<string>,
  content?: string,
  placement?: Placement,
  offset?: OffsetOptions["offset"],
  usePadding?: boolean,
  id?: string
}>;

export const Overlay = observer(({
  model,
  placement = 'top',
  offset = [0, 8],
  usePadding = true,
  ...props
}: Props) => {

  const className = classNames('overlay', props.className);

  const { styles, attributes } = usePopper(model.anchorRef.node, model.overlayRef.node, {
    placement: placement,
    strategy: 'fixed',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: model.arrowRef.node
        }
      },

      {
        name: 'offset',
        options: {
          offset: offset,
        }
      },

      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'top-end', 'top-start', 'left', 'right', 'bottom'],
        }
      }
    ],
  });

  // useOutsideClick(model.overlayRef.node, () =>
  //   setIsVisible(false));

  return (
    <TransitionGroup>
      {(model.isVisible || model.isPlayerTutorialOn) && (
        <CSSTransition
          classNames="menu-fade"
          timeout={FadeTimeout}>

          <div className={className}
            id={props.id}
            ref={model.overlayRef.ref}
            style={styles.popper} {...attributes.popper}>

            {usePadding &&
              <div className="overlay-padding" />}

            <div className="overlay-arrow"
              ref={model.arrowRef.ref}
              style={styles.arrow} />

            <div className="overlay-content">
              {props.children}
            </div>
          </div>
        </CSSTransition>)}
    </TransitionGroup>
  );
});