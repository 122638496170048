import { makeObservable } from 'mobx';
import { MomentModel } from './moment';
import { Store } from '../store/store';
import { StoreNode } from '../store';

export type TrackLaneProps = {
  laneIndex: number,
  moments: MomentModel[]
};

/** Local entity for splitting moments on a track into individual lanes. */
export class TrackLane
  extends StoreNode {

  readonly nodeType: 'TrackLane' = 'TrackLane';

  constructor(store: Store, props: TrackLaneProps) {
    super(store);
    makeObservable(this);

    Object.assign(this, props);
  }

  readonly laneIndex: number = 0;
  readonly moments: MomentModel[] = [];
}