import { DateTime, DateTimeOptions } from 'luxon';
import { IObservableValue, observable } from 'mobx';
import { useEffect, useState } from 'react';

/**
 * Takes an arbitrarily typed argument and returns a DateTime only if the 
 * argument is a string and a DateTime object can be constructed from it, otherwise null is returned.
 * This method does not check if the DateTime object is valid, it only returns it as is.
 * If you want to only return valid DateTime objects use `toValidDateTime`.
 * @todo Maybe add support for timestamps?
 */
export function toDateTime(arg: any, opts?: DateTimeOptions): DateTime | null {
  if (typeof arg !== 'string' || arg.length === 0)
    return null;
  return DateTime.fromISO(arg, opts);
}

/**
 * Similar to `toDateTime` but only returns the DateTime object if it's valid.
 */
export function toValidDateTime(arg: any, opts?: DateTimeOptions): DateTime | null {
  const dateTime = toDateTime(arg, opts);
  if (!dateTime || !dateTime.isValid)
    return null;
  return dateTime;
}

/**
 * Returns a boxed observable containing a DateTime object that updates
 * automatically as time passes.
 */
export function observableClock(interval: number = 1000, start?: DateTime, signal?: AbortController): IObservableValue<DateTime> {
  const boxed = observable.box();

  return boxed;
}

export function useClock(interval: number = 1000): DateTime {

  const [value, setValue] = useState<DateTime>(DateTime.now());

  useEffect(() => {
    setValue(DateTime.now());

    const timerId = setInterval(() => {
      setValue(DateTime.now());
    }, interval);

    return () => {
      clearInterval(timerId);
    }
  }, [interval]);

  return value;
}