import './jobs.scss';

import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { UploadThumbnailWindowState } from './uploadThumbnailWindowState';
import { Button } from '../input';
import { CloudUploadSVGIcon, TextInputErrorSVGIcon } from '../svg/svg';
import classNames from 'classnames';
import { handler } from '../../core';

type Props = {
  model: UploadThumbnailWindowState;
  visible?: boolean;
};

export const UploadThumbnailWindow = observer((props: Props) => {
  const { model } = props;
  const inputRef = useRef(null);

  const onClick = () => {
    const input = inputRef.current;
    if (input) {
      // @ts-ignore
      input.click();
    }
  };

  const className = classNames('upload-file-box upload-box aspect-ratio-box', {
    hover: model.isHovered,
    'drag-hover': model.isDragHovered,
    'drag-error': model.isDragHovered && model.dragError,
  });

  const iconElem =
    model.isDragHovered && model.dragError ? (
      <TextInputErrorSVGIcon />
    ) : (
      <CloudUploadSVGIcon />
    );

  return (
    <Window
      model={model.window}
      visible={props.visible}
      className="upload-thumbnail-window"
      isLoading={model.isLoading}>
      <WindowHeader model={model.window} title="Update thumbnail" />

      <hr />

      <div className="window-content">
        <p className="p-bold">Upload a custom cover image for your video.</p>
        <p>1920x1080 recommended resolution, up to 2MB, JPG or PNG.</p>

        <div
          className={className}
          onDrop={handler(model, 'handleDrop')}
          onPointerEnter={handler(model, 'handlePointerEnter')}
          onPointerLeave={handler(model, 'handlePointerLeave')}
          style={{ ['--aspect-ratio' as any]: 9 / 16 }}>
          <div className="upload-file-wrapper">
            {!model.previewUrl && !model.isDragHovered && (
              <div className="drag-drop-placeholder">
                <div className="upload-icon">
                  <CloudUploadSVGIcon />
                </div>
                <span className="text-label">Drop your file here</span>
              </div>
            )}
            {model.previewUrl && (
              <div className="image-preview">
                <img src={model.previewUrl} alt="preview thumbnail" />
              </div>
            )}

            <div className="drag-overlay">
              <div className="upload-icon">{iconElem}</div>
              <span className="text-label">
                {model.dragError
                  ? model.dragErrorMessage
                  : 'Drop your file here'}
              </span>
            </div>

            <input
              type="file"
              className="upload-file-region file-input"
              role="presentation"
              multiple={false}
              onDragEnter={handler(model, 'handleDragEnter')}
              onDragLeave={handler(model, 'handleDragLeave')}
              onChange={handler(model, 'handleFileChange')}
              onClick={handler(model, 'handleFileClick')}
            />
          </div>
        </div>

        <div className="select-files">
          <span>Drag and Drop your file or</span>
          <Button
            label="Select file"
            className="secondary small"
            onClick={onClick}
          />

          <input
            ref={inputRef}
            type="file"
            className="file-input"
            role="presentation"
            multiple={false}
            hidden
            onChange={handler(model, 'handleFileChange')}
          />
        </div>
      </div>

      <hr />

      <footer className="window-footer">
        <div className="btn-group right">
          <Button
            label="Cancel"
            className="primary btn-outline medium"
            onClick={() => model.close()}
          />
          <Button
            label="Save"
            className="primary medium"
            onClick={() => model.submit()}
            disabled={!model.isLoadedImage}
          />
        </div>
      </footer>
    </Window>
  );
});
