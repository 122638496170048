import { makeObservable } from 'mobx';
import { Store } from '../../../store/store';
import { StoreNode } from '../../../store';
import { RouteContext } from '../../../routes/routeContext';

import { AuthFlowName, AuthFlowResponse, IAuthFlow } from '../authFlowSchema';
import { AsyncResult } from '../../../core';
import { AuthStepOrchestrator } from '../authStepOrchestrator';

type Props = {
  routeContext: RouteContext;
}

export type ProxyAuthorizeServerFlowParams = Props;

export class ProxyAuthorizeServerFlow
  extends StoreNode
  implements IAuthFlow {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);
  }

  readonly flowName = AuthFlowName.ProxyAuthorizeServer;

  get routeContext(): RouteContext {
    return this.props.routeContext;
  }

  readonly orchestrator = new AuthStepOrchestrator(this.store);

  async run(): AsyncResult<AuthFlowResponse> {

    const { orchestrator, routeContext } = this;
    const authConnection = routeContext.searchParams.get('authConnection');
    if (authConnection) {

      const [, permitErr] = await orchestrator.socialLogin(authConnection as any);
      if (permitErr)
        // probably not authenticated
        return orchestrator.setRedirectToLoginPage({ error: permitErr });

      return orchestrator.setAwaitRedirect();
    }

    const [, permitErr] = await orchestrator.refreshPermit();
    if (permitErr)
      // probably not authenticated
      return orchestrator.setRedirectToLoginPage();

    const [, contextErr] = await orchestrator.initContextFromPermit();
    if (contextErr)
      // probably not authenticated
      return orchestrator.setRedirectToLoginPage();

    orchestrator.commit();

    const [, sendErr] = await orchestrator.sendProxyAuthorizeResponse();
    if (sendErr)
      return orchestrator.setError(sendErr);

    // TODO: add code from finalize
    return orchestrator.setAwaitProxyClose();
  }

  async restoreAfterRedirect(): AsyncResult<AuthFlowResponse> {

    const { orchestrator } = this;

    const [, contextErr] = await orchestrator.initContextFromHash();
    if (contextErr)
      return orchestrator.setRedirectToLoginPage({ error: contextErr });

    const [, commitErr] = orchestrator.commit();
    if (commitErr)
      return orchestrator.setError(commitErr);

    const [, sendErr] = await orchestrator.sendProxyAuthorizeResponse();
    if (sendErr)
      return orchestrator.setError(sendErr);

    return orchestrator.setAwaitProxyClose();
  }
}