import { ChartPageParams } from '../chartSchema';

export type ReactionsChartExternalParams = ChartPageParams;

export type ReactionsChartInternalParams = {
  mode?: ReactionChartMode | null
};

export type ReactionsChartParams =
  ReactionsChartInternalParams &
  ReactionsChartExternalParams;
  
export type ReactionSeriesData = {
  dataKey: string,
  data: ReactionData[],
}

export type ReactionData = {
  date: string,
  value: number
}

export enum ReactionChartMode {
  ReactionType = 'ReactionType',
  JobSource = 'JobSource',
}