import { MaybeProps } from '../../core'
import { UserProfile } from '../../entities';
import { AuthPermit } from './authPermit';

export interface IAuthContext {
  type: AuthContextType;
  isValid: boolean;
  isAuthenticated: boolean;
  isAnonymous: boolean;
  permit: AuthPermit | null;
  profile: UserProfile | null;
  allowActions: boolean;
}

/**
 * We define this as a type but with an interface name as to keep the AuthContext name free.
 * We need it to be a type such that we can do the switch between authenticated / anonymous types.
 */
export type AuthContext = {
  type: AuthContextType.Anonymous;
  isValid: boolean;
  isAuthenticated: false;
  isAnonymous: true;
  permit: null;
  profile: null;
  allowActions: boolean;
} | {
  type: AuthContextType.Authenticated;
  isValid: boolean;
  isAuthenticated: true;
  isAnonymous: false;
  permit: AuthPermit;
  profile: UserProfile;
  allowActions: boolean;
}

export enum AuthContextType {
  Authenticated = 'Authenticated',
  Anonymous = 'Anonymous'
}

export type SocialLoginProvider =
  'Google' |
  'LinkedIn' |
  'Facebook' |
  'Twitter' |
  'Microsoft' |
  'External:Poly';

export type LoginInput = {
  username: string,
  password: string
}

export type RegisterInput = {
  email: string,
  password: string,
  firstName: string,
  lastName?: string,
  fullName: string,
}

export type OnboardInput = {
  username: string,
  firstName?: string,
  lastName?: string,
  name?: string,
  industry?: string | null,
  companySize?: string | null,
  jobTitle?: string | null
}

export type ResetPasswordInput = {
  email: string
}

export type ResendOnboardLinkInput = {
  username: string
}

export type UserRole =
  'Admin' |
  'Trainer' |
  'Uploader';

export type JwtTokenData = MaybeProps<{
  aud: string,
  auth_time: number,
  at_hash: string,
  email: string,
  email_verified: boolean,
  exp: number,
  iat: number,
  iss: string,
  name: string,
  nonce: string,
  picture: string,
  sub: string,
  roles: UserRole[]
}>;