import React from 'react';
import { observer } from 'mobx-react-lite';
import { Duration } from 'luxon';
import { ParentSize } from '@visx/responsive';
import { ThemeConfig } from '@visx/xychart/lib/theme/buildChartTheme';
import { durationToString } from '../../../core/time';
import { SimpleScaleConfig, XYSeriesChart } from '../xySeriesChart';
import { DropdownInputControlled } from '../../input';
import { HoursCliprdChartState } from './hoursCliprdChartState';
import { DefaultTooltip, TooltipCallbacks } from '../utils/defaultTooltip';
import { ChartWrapper } from '../chartWrapper';

type Props = {
  model: HoursCliprdChartState;
};

export const HoursCliprdChart = observer(({ model }: Props) => {
  const { dataSource } = model;
  const teamId = model.params?.teamId;
  const { isFetching: showLoadMask, error } = dataSource;

  const data = model.outputData;

  const hasData = !!(data?.[0]?.data?.length > 0);
  const showNoDataMask = !hasData && !showLoadMask;

  let overlayMessage;
  if (error) {
    overlayMessage = { code: 'Error', message: 'An error has occured' };
  } else if (showNoDataMask) {
    overlayMessage = { code: 'NoData' };
  }

  const themeConfig: ThemeConfig = {
    backgroundColor: '#ffffff',
    tickLength: 5,
    colors: ['#00A4D8'],
    gridColor: '#E4E4E4',
    gridColorDark: 'rgba(11, 4, 52, 50)',
  }

  const tooltipCallbacks: TooltipCallbacks = {
    value: ({ datum }) => {
      const seconds = Duration.fromObject({ hours: datum.value ?? 0 }).as('seconds')
      return durationToString(seconds);
    },
  };

  const chartTitle = `${teamId ? 'Library' : 'My'} CLIPr Hours`;
  const tooltipContent = teamId ?
    `Discover how much time your library is saving with CLIPr by keeping track of videos processed and viewed over time.` :
    `Discover how much time you are saving with CLIPr by keeping track of videos you processed and viewed over time.`;

  const dropdownInput = <DropdownInputControlled
    className="form-input"
    model={model.chartTypeInput}
    showFeedback={false}
    notDeselectable={true}
    persistentFeedback={false} />;

  const dateScaleConfig: SimpleScaleConfig = { type: "band", paddingOuter: 0.2, paddingInner: 0.5 };

  return (
    <ChartWrapper
      title={chartTitle}
      tooltipContent={tooltipContent}
      showLoadMask={showLoadMask}
      overlayMessage={overlayMessage}
      tools={dropdownInput}>
      <ParentSize>{(parent) =>
        <XYSeriesChart
          renderBarSeries={true}
          renderLineSeries={false}
          showLegend={false}
          height={parent.height - 32}
          width={parent.width}
          dateScaleConfig={dateScaleConfig}
          series={data}
          themeConfig={themeConfig}
          renderTooltip={(renderTooltipParams) => (
            <DefaultTooltip
              {...renderTooltipParams}
              showTooltipTitle={true}
              showItemText={true}
              showItemValue={true}
              callbacks={tooltipCallbacks} />
          )} />
        }</ParentSize>
    </ChartWrapper>
  );
});
