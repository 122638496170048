import { JobSpeciality, UpdateJobSourceMutation, JobType } from '@clipr/lib';
import { DateTime } from 'luxon';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ApiUploadRequest, ApiUploadRequestParams } from '../../api';
import { assertNotNull, FileSizeInfo, Nullable, IAsyncTask, AsyncResult, assert, Result } from '../../core';
import { JobModel } from '../../entities';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { JobLevel } from '../../entities/job/jobFeatures';
import { JobSynchronizer } from '../../entities/job/jobSynchronizer';
import { notifySuccess } from '../notifications';
import { getPlayerWidgetIFrameCode } from '../../widgets/playerWidget/playerWidgetUtils';
import Routes from '../../routes';

export type UploadTaskProps = {
  file: File;
  teamId?: string;
  jobId?: string;
  languageCode?: string;
  isPublic?: boolean;
  jobLevel?: JobLevel;
  jobSpeciality?: JobSpeciality;
  videoType?: JobType;
};

function isActiveStatus(status: UploadTaskStatus) {
  return (
    status === 'fetchingUploadInfo' ||
    status === 'uploading' ||
    status === 'updatingJob' ||
    status === 'creatingJob');
}


export type UploadTaskStatus =
  'idle' |
  'fetchingUploadInfo' |
  'uploading' |
  'creatingJob' |
  'updatingJob' |
  'completed' |
  'error' |
  'canceled';

export class UploadTask
  extends StoreNode
  implements IAsyncTask {

  nodeType = 'UploadTask';

  readonly createdAt: DateTime;

  readonly file: File;
  @computed
  get fileSizeInfo() {
    return new FileSizeInfo(this.file.size);
  }

  readonly teamId?: Nullable<string> = null;
  readonly jobId?: Nullable<string> = null;
  readonly languageCode?: Nullable<string> = null;
  readonly isPublic?: Nullable<boolean> = null;
  readonly jobLevel?: Nullable<JobLevel> = null;
  readonly jobSpeciality?: Nullable<JobSpeciality> = null;
  readonly videoType: Nullable<JobType> = null;
  readonly chunkSize: number = 1024 * 1024 * 5;
  readonly threadsQuantity: number = 5;

  @observable
  uploadRequest: Nullable<ApiUploadRequest> = null;

  @observable
  status: UploadTaskStatus = 'idle';

  @computed get isActive(): boolean {
    return isActiveStatus(this.status);
  }

  @computed get isCompleted(): boolean {
    return this.status === 'completed';
  }

  @computed get isFailed(): boolean {
    return this.status === 'error';
  }
  @computed get isCanceled(): boolean {
    return this.status === 'canceled';
  }

  @observable error: any;
  @observable errorMessage: Nullable<string> = null;

  @computed
  get canRetry(): boolean {
    // in the future we might filter out the errors which support retrying
    return !!this.error && this.status === 'error';
  }

  @computed
  get progress() {
    if (!this.isActive)
      return 0;

    return this.uploadRequest?.uploadProgress || 0;
  }


  @computed
  get jobUrl(): string | null {
    const id = this.outputJob?.id;
    if (!id)
      return null;

    let query = '';
    const { teamId } = this;
    if (teamId)
      query = `teamId=${teamId}`;

    return Routes.userVideo(id, query);
  }

  /** True if the task has failed and the user has discarded the task. */
  @observable isDiscarded: boolean = false;

  constructor(props: UploadTaskProps, store: Store) {
    super(store);
    makeObservable(this);
    this.createdAt = DateTime.utc();

    this.file = props.file;
    this.teamId = props.teamId || null;
    this.jobId = props.jobId || null;
    this.languageCode = props.languageCode || null;
    this.isPublic = props.isPublic || null;
    this.jobLevel = props.jobLevel || null;
    this.jobSpeciality = props.jobSpeciality || null;
    this.videoType = props.videoType || null;
    
    this.synchronizer = new JobSynchronizer({
      teamId: this.teamId ?? undefined,
      jobData: {
        id: this.jobId ?? undefined,
        languageCode: this.languageCode ?? undefined,
        isPublic: this.isPublic ?? undefined,
        enrichmentLevel: this.jobLevel ?? undefined,
        speciality: this.jobSpeciality ?? undefined,
        videoType: this.videoType ?? undefined
      }
    }, this.store)
  
    this.emit('create');
  }

  readonly synchronizer: JobSynchronizer;

  @computed get syncJobId(): string | null {
    return this.synchronizer.syncModelId || null;
  }

  discard() {
    this.outputJob = null;
    this.emit('discard');
  }

  @action
  async retry(): AsyncResult<JobModel | UpdateJobSourceMutation> {

    this.outputJob = null;
    this.emit('retrying');

    assert(this.canRetry,
      `Cannot retry if 'canRetry' is false.`);

    this.error = null;
    this.errorMessage = null;
    this.status = 'idle';

    this.uploadRequest = null; // TODO: maybe store previous request

    // fingers crossed
    return this.start();
  }

  @action
  cancel() {
    this.uploadRequest?.cancel();
  }

  @action
  async start(): AsyncResult<JobModel | UpdateJobSourceMutation> {

    this.outputJob = null;
    this.emit('start');

    const { file, store } = this;
    const { apiService: api } = store;

    // fake
    // await sleep(1000);

    // return this.handleCompleted({} as any, { jobId: null! });

    this.status = 'fetchingUploadInfo';
    const [prepareRes, prepareErr] = await api.queries.uploadUrl({
      fileName: file.name,
      teamId: this.teamId!,
      jobId: this.jobId!
    });

    if (prepareErr)
      return this.handleError(prepareErr, 'Failed to prepare upload.');

    this.synchronizer.bindToken(prepareRes?.uploadToken!);

    assertNotNull(prepareRes);

    // step 2, start the upload
    const uploadParams: ApiUploadRequestParams = {
      file,
      url: prepareRes.uploadUrl,
      data: prepareRes.fields
    }
    
    const uploadRequest = api.uploadRequest(uploadParams);
    runInAction(() => {
      this.status = 'uploading';
      this.uploadRequest = uploadRequest;
    });

    const [, uploadErr] = await uploadRequest.start();

    if (uploadErr) {
      if (uploadErr.message === 'Cancel') return this.handleCanceled(uploadErr);
      return this.handleError(uploadErr, 'Failed to upload file.');
    }

    // step 3, upload completed, start the job OR replace job source
    return await this.syncJob();
  }

  async syncJob(): AsyncResult<JobModel | UpdateJobSourceMutation> {
    if (this.jobId) {
      runInAction(() => {
        this.status = 'updatingJob';
      });

      const [job, jobErr] = await this.synchronizer.submitUpdateJobSource();
      if (jobErr)
        return this.handleError(jobErr, 'Failed to start job.');
      assertNotNull(job);

      this.synchronizer.setSynced();
      return this.handleCompleted(job, { jobId: this.jobId });
    } else {

      runInAction(() => {
        this.status = 'creatingJob';
      });

      const [job, jobErr] = await this.synchronizer.submitStartJob();

      if (jobErr)
        return this.handleError(jobErr, 'Failed to start job.');
      assertNotNull(job);

      this.synchronizer.setSynced();
      return this.handleCompleted(job, { teamId: this.teamId || undefined });
    }
  }

  @action
  async copyIFrameEmbedCode() {
    const jobId = this.outputJob?.id ?? null;

    if (!jobId)
      return;

    const code = getPlayerWidgetIFrameCode({ jobId });
    if (!code)
      return;

    await navigator.clipboard?.writeText(code);

    notifySuccess(this, 'Embed code copied to clipboard!');
  }

  handleCanceled(err: Error): Result<JobModel> {
    this.status = 'canceled';
    this.emit('cancel');
    notifySuccess(this, 'Upload canceled.');

    return [null, err]
  }

  handleError(err: any, errMsg: string): Result<JobModel> {
    this.error = err;
    this.errorMessage = errMsg;
    this.status = 'error';

    // console.error(errMsg, err);

    this.emit('error');
    this.dispatch(
      'NotificationService',
      'notifyError',
      `Could not upload '${this.file.name}'.`);

    return [null, err];
  }

   handleCompleted(
    job: JobModel | UpdateJobSourceMutation,
    { teamId, jobId }: { teamId?: string, jobId?: string }): Result<JobModel | UpdateJobSourceMutation> {
    this.status = 'completed';

    this.emit('completed', { job, teamId, jobId });
    this.dispatch(
      'NotificationService',
      'notifySuccess',
      `File '${this.file.name}' has been uploaded and is processing.`);

    if (job instanceof JobModel)
      this.outputJob = job;

    return [job];
  }

  @observable outputJob: JobModel | null = null;
}