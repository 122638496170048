import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../../components/overlays';
import { Button } from '../../components/input';
import { DeleteMomentsPopupState } from './deleteMomentsPopupState';

type Props = {
  model: DeleteMomentsPopupState;
  visible?: boolean;
};

export const DeleteMomentsPopup = observer(({ model, visible }: Props) => {
  return (
    <Window model={model.window} visible={visible} isLoading={model.isLoading}>
      <WindowHeader title="Delete" model={model.window} />

      <hr />

      <section className="window-content">
        <br />

        <p>
          {`Are you sure you want to delete ${
            model.hasMultipleTargets ? 'these clips' : 'this clip'
          }?`}
        </p>

        <br />
      </section>

      <hr />

      <footer className="window-footer ">
        <div className="col left"></div>
        <div className="col right">
          <div className="btn-group">
            <Button
              label="Cancel"
              className="primary btn-outline medium"
              onClick={() => model.close()}
              disabled={model.isLoading}
            />

            <Button
              label="Delete"
              className="red medium"
              onClick={() => model.submit()}
              disabled={model.isLoading}
            />
          </div>
        </div>
      </footer>
    </Window>
  );
});
