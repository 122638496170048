import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useRouteModel } from './routeHooks';
import { AuthFlowResponseType } from '../services/auth/authFlowSchema';
import { AuthFlowResponseInterpreter } from '../components/auth';
import { LoadPage } from '../components';
import { OnboardRouteState } from './onboardRouteState';

import { TRACE } from '../core/debug/debugMacros';

type Props = RouteProps;

export const OnboardRoute = observer((props: Props) => {

  const model = useRouteModel((kernel) => new OnboardRouteState(kernel.store));

  TRACE(model, `render()`, { props, model });

  const { flowResponse } = model;
  if (model.isLoading) {
    return <LoadPage />
  }

  switch (flowResponse?.responseType) {
    case AuthFlowResponseType.Authorized:
      break;

    default:
      return <AuthFlowResponseInterpreter response={flowResponse} />
  }

  return <Route {...props} />;
});