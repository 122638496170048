import { makeObservable, observable, action, computed } from 'mobx';

import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { Reaction } from '../../entities';

export enum ReactionAnimations {
  BloomAnimation = 'BloomAnimation',
  FlameAnimation = 'FlameAnimation',
}

enum Broadcast {
  'ReactionBuffer:bloomAnimation' = ReactionAnimations.BloomAnimation,
  'ReactionBuffer:reactionAnimation' = ReactionAnimations.FlameAnimation,
}

type AnimationParams = {
  bloomPosition?: number;
  flameTraj?: number;
};

type AnimatedReaction = {
  reaction: Reaction;
  animationParams: AnimationParams;
};

type Reactions = {
  [key in ReactionAnimations]: Map<string, AnimatedReaction>;
};

export class PlayerReactionAnimationsState extends StoreNode {
  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  readonly maxFlameTraj: number = 10;
  readonly maxBloomPosition: number = 5;

  readonly reactions: Reactions = {
    [ReactionAnimations.BloomAnimation]: observable.map<
      string,
      AnimatedReaction
    >(),
    [ReactionAnimations.FlameAnimation]: observable.map<
      string,
      AnimatedReaction
    >(),
  };

  @observable flameTrajCount: number = 0;
  @observable bloomPositionCount: number = 0;

  @computed get bloomReactions() {
    return [...this.reactions[ReactionAnimations.BloomAnimation].values()];
  }

  @computed get flameReactions() {
    return [...this.reactions[ReactionAnimations.FlameAnimation].values()];
  }

  @action
  mounted() {
    this.reset();
    this.receive(this.receiver.bind(this), Object.keys(Broadcast));
  }

  @action
  unmounted() {
    this.reset();
  }

  @action
  handleBlur() {
    this.reset();
  }

  @action
  reset() {
    this.reactions[ReactionAnimations.BloomAnimation].clear();
    this.reactions[ReactionAnimations.FlameAnimation].clear();
  }

  @action
  receiver(msg: Message) {
    const { payload, fullType } = msg;

    const reaction: Reaction = payload.reaction;
    const animationName: ReactionAnimations = Broadcast[
      fullType as any
    ] as ReactionAnimations;

    switch (animationName) {
      case ReactionAnimations.BloomAnimation:
        this.addBloomReaction(reaction);
        break;
      case ReactionAnimations.FlameAnimation:
      default:
        this.addFlameReaction(reaction);
        break;
    }
  }

  @action
  addBloomReaction(reaction: Reaction) {
    this.bloomPositionCount = this.bloomPositionCount % this.maxBloomPosition + 1;

    this.reactions[ReactionAnimations.BloomAnimation].set(reaction.id, {
      reaction,
      animationParams: { bloomPosition: this.bloomPositionCount },
    });
  }

  @action
  addFlameReaction(reaction: Reaction) {
    this.flameTrajCount = this.flameTrajCount % this.maxFlameTraj + 1;

    this.reactions[ReactionAnimations.FlameAnimation].set(reaction.id, {
      reaction,
      animationParams: { flameTraj: this.flameTrajCount },
    });
  }

  @action
  removeReaction(reactionId: string, animationName: ReactionAnimations) {
    this.reactions[animationName].delete(reactionId);
  }
}
