import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { useOverlayState } from '../../overlays/overlayState';
import { Overlay } from '../../overlays/overlay';
import { UserPlayerBackSVGIcon, UserPlayerNextSVGIcon, UserPlayerPauseSVGIcon, UserPlayerPlaySVGIcon } from '../../svg';
import { PlayerState } from '../playerState';
import { ControlButton } from './controlButton';
import { CodeSVGIcon } from '../../svg/svg';

type Props = {
  model: PlayerState
}

export const PlayPauseButton = observer(({
  model
}: Props) => {

  const [isHovered, setHovered] = useState(false);

  const tooltipState = useOverlayState();

  const className = classNames('user-player-control-btn play-btn', {
    'playing': model.isPlaying,
    hover: isHovered
  });

  return (
    <button className={className}
      ref={tooltipState.anchorRef.ref}
      {...tooltipState.anchorProps}
      onClick={evt => {
        model.invoke('togglePlay');
        // tooltipState.hide();
      }}
      onPointerEnter={() => {
        setHovered(true);
      }}
      onPointerLeave={() => {
        setHovered(false);
      }}>

      <div className="icon-switch">
        <span className="play-icon icon">
          <UserPlayerPlaySVGIcon />
        </span>
        <span className="pause-icon icon">
          <UserPlayerPauseSVGIcon />
        </span>
      </div>

      <Overlay model={tooltipState}
        className="user-player-tooltip">
        {model.isPlaying ? 'Pause' : 'Play'}
      </Overlay>
    </button>
  );
});

export const SeekBackButton = observer(({
  model
}: Props) => {

  return (
    <ControlButton
      model={model}
      className="seek-back-btn"
      onClick={evt => model.invoke('seekStepBackward')}
      icon={<UserPlayerBackSVGIcon />}
      tooltip="Back 10 seconds" />
  );
});

export const SeekNextButton = observer(({
  model
}: Props) => {

  return (
    <ControlButton
      model={model}
      disabled={model.isRealTime}
      className="seek-next-btn"
      onClick={evt => model.invoke('seekStepForward')}
      icon={<UserPlayerNextSVGIcon />}
      tooltip="Forward 10 seconds" />
  );
});



export const EmbedButton = observer(({
  model
}: Props) => {

  const { job } = model;

  if (!job?.hasPermission('EmbedPlayerWidget') || !model.showEmbedIcon)
    return null;

  return (
    <ControlButton
      model={model}
      className="embed-btn"
      onClick={evt => model.copyIFrameEmbedCode()}
      icon={<CodeSVGIcon />}
      tooltip="Copy Embed Code" />
  );
});