export enum WidgetAuthMode {
  Wait = 'Wait',
  Status = 'Status',
  None = 'None'
}

export enum WidgetStatus {
  Idle = 'Idle',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error'
}