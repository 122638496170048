import React, { Fragment } from 'react';
import { RawDraftContentState, RawDraftContentBlock, RawDraftEntity } from 'draft-js';
import { MentionData } from '@draft-js-plugins/mention';

import { LINK, MENTION } from '../../../services/draft';
import { Link, LinkData, Mention } from '.';

type Props = {
  content: RawDraftContentState
};

export const RichText = (props: Props) => {
  const { content: { blocks, entityMap } } = props;
  return (
    <div className="rich-text">
      {blocks.map(block => renderBlock(block, entityMap))}
    </div>
  );
};

function renderEntity(entity: RawDraftEntity, entityText: string) {
  const { type, data } = entity;

  switch (type.toUpperCase()) {
    case LINK:
      const { href, text } = data.link as LinkData;
      return <Link href={href}>{text}</Link>;

    case MENTION:
      const { name } = data.mention as MentionData;
      return <Mention>{name}</Mention>;

    default:
      return entityText;
  }
}

function renderBlock(block: RawDraftContentBlock, entityMap: any) {
  const { key, text, entityRanges } = block;
  const hasEntities = entityRanges.length > 0;
  if (!hasEntities) return (
    <p key={key}>{text}</p>
  );

  const contents = [];
  for (let i = 0; i < entityRanges.length; ++i) {
    const { offset, length, key } = entityRanges[i];
    const entityStart = offset;
    const entityEnd = offset + length;

    const hasInitialText = i === 0 && entityStart !== 0;
    if (hasInitialText) contents.push(text.slice(0, entityStart));

    const entity = entityMap[key];
    const entityText = text.slice(entityStart, entityEnd);
    contents.push(renderEntity(entity, entityText));

    const hasNextEntity = i !== entityRanges.length - 1;
    if (hasNextEntity) {
      const nextEntity = entityRanges[i + 1];
      const nextEntityStart = nextEntity.offset;
      contents.push(text.slice(entityEnd, nextEntityStart));
      continue;
    }

    const hasEndText = entityEnd !== text.length;
    if (hasEndText) contents.push(text.slice(entityEnd));
  }

  return (
    <p key={key}>
      {contents.map((content, index) => (
        <Fragment key={index}>{content}</Fragment>
      ))}
    </p>
  );
}