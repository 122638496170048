import { createMessage, StoreNode } from '../../store'

export const openWindow = (node: StoreNode, name: string) => {
  const msg = createMessage(node, 'dispatch', 'openWindow', { name });
  msg.target = 'OverlayService';
  node.store.nodeDispatch(msg);
}

export const closeWindow = (node: StoreNode) => {
  const msg = createMessage(node, 'dispatch', 'closeWindow');
  msg.target = 'OverlayService';
  node.store.nodeDispatch(msg);
}