import { transcriptLanguages } from '@clipr/lib/dist/plugins/languages';
import { DropdownItemObject } from '../components/input/dropdownInput';

export const LanguageItems: DropdownItemObject[] = transcriptLanguages //SelectorObjectItem
  .map(({ name, code }) => ({ value: code, label: name }));

export const getDefaultLanguageValue = () => {
  return 'en-US';
}

export const getLanguageInputItem = (languageCode: string | null): DropdownItemObject | null => {
  return LanguageItems.find(({ value }) => value === languageCode) || null;
}

export const getLanguageInputLabel = (languageCode: string | null): string | null => {
  return getLanguageInputItem(languageCode)?.label || null;
}

export const DefaultLanguageItem: DropdownItemObject | null = LanguageItems.find(({ value }) => value === getDefaultLanguageValue()) || null;