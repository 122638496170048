import { action, computed, makeObservable, observable } from 'mobx';
import { DOMElement, domId } from '../core';
import { Store } from './store';
import { StoreNode } from './storeNode';

type Ref<T extends DOMElement = DOMElement> =
  React.RefCallback<T>;

/**
 * Wrapper around React.RefCallback which also registers the node into an observable field.
 * This also implements React.RefObject.
 * Needed because https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
 */
export class RefProxy<T extends DOMElement = DOMElement>
  extends StoreNode
  implements React.RefObject<T> {

  constructor(store: Store) {
    super(store)
    makeObservable(this);
  }

  @observable name: string | null = null;
  @observable node: T | null = null;

  readonly domId: string = domId();

  @action
  readonly ref: Ref<T> =
    (node: T) => this.setNode(node || null);

  @action
  setNode(node: T | null) {

    if (!this.node && node) {
      this.node = node;
      this.emit('mounted', { node });
    }

    if (this.node && !node) {
      this.emit('unmounted');
      this.node = null;
    }
  }

  get current(): T | null {
    return this.node;
  }

  @computed
  get isFocused(): boolean {
    const node = this.node;
    const focusedElem = this.store.ui.focusedElement;
    if (!focusedElem || !node)
      return false;

    return focusedElem === node;
  }

  @computed
  get isFocusedWithin(): boolean {
    const ui = this.store.ui;
    const node = this.node;

    return (
      this.isFocused ||
      (ui.blurredElement === node && ui.didFocusMoveToDescendant) ||
      (node?.contains(ui.focusedElement) || false));
  }
}

export function refProxy<T extends DOMElement = HTMLElement>(
  node: StoreNode,
  name?: string) {

  const proxy = new RefProxy<T>(node.store);
  if (name)
    proxy.name = name;
  return proxy;
}

export function multiRef<T extends DOMElement = DOMElement>(...refs: RefProxy<T>[]): Ref<T> {

  return (node: T) => {
    refs.forEach(ref =>
      ref.setNode(node || null));
  }
}