import { SpeakerAddInput, SpeakerUpdateInput } from '@clipr/lib/dist/generated/graphql';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ApiUploadRequestParams } from '../../api/apiUploadRequest';
import { assert } from '../../core/assert';
import { AsyncResult } from '../../core/async/async';
import { SCOPE_TEAM_PREFIX, SpeakerModel } from '../../entities/speaker';
import { checkLinkedInUrlValidity } from '../../core/urlUtils';
import { notifyError } from '../../services/notifications';
import { BindingProps } from '../../store/propManager';
import { Store } from '../../store/store';
import { StoreNode } from '../../store/storeNode';
import { DropdownItemObject } from '../input/dropdownInput';
import { fileInput, FileInputState } from '../input/fileInputState';
import { inputGroup, InputGroupState } from '../input/inputGroupState';
import { input, InputState } from '../input/inputState';
import { SpeakerInputGroupState } from './speakerInputGroupState';

type Props = BindingProps<{
  isRequired?: boolean;
  error?: string | null;
  disabled?: boolean;
  mode?: SpeakerFormMode | null;
  onConfirm: () => void;
  shouldFetchSpeaker?: boolean | null;
  teamId?: string | null;
  jobId?: string | null;
  layout?: SpeakerFormMode | null;
}>

export enum SpeakerFormMode {
  Confirm = 'Confirm',
  Display = 'Display',
  Edit = 'Edit',
  Create = 'Create'
}

export enum SpeakerConfirmStatus {
  Pending = 'Pending',
  Confirmed = 'Confirmed',
  Rejected = 'Rejected',
  Edit = 'Edit'
}

export class SpeakerFormBlockState
  extends StoreNode {

  readonly nodeType = 'SpeakerForm';

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  readonly avatar: FileInputState = fileInput(this, {
    name: 'avatar',
    disabled: (input: any) =>
      input.isSubmitting ||
      !this.speakerInputGroup.mode
  });

  readonly speakerInputGroup: SpeakerInputGroupState = new SpeakerInputGroupState(this.store, {
    error: () => this.error,
    disabled: () => this.disabled,
    isRequired: () => this.isRequired,
    selectorItems: () => this.publicSpeakerItems,
    onSelect: (id: string | null) => this.onSelectSpeaker(id),
    onCancel: () => this.onCancelMode(),
    onAddSpeaker: () => this.onAddSpeakerMode(),
    layout: () => (this.isRejected || this.isEditConfirmed) ? SpeakerFormMode.Create : this.mode
  });

  readonly description: InputState = input(this, {
    name: 'speakerDescription',
    multiline: true,
    disabled: (input: any) =>
      input.isSubmitting ||
      !this.speakerInputGroup.mode
  });

  readonly linkedin: InputState = input(this, {
    name: 'speakerLinkedin',
    error: (input) => {
      if (input.value)
        return this.checkLinkValidity(input.value)
      return null;
    },
    disabled: (input: any) =>
      input.isSubmitting ||
      !this.speakerInputGroup.mode
  });

  readonly acceptButton = input(this, {
    name: 'acceptButton',
    disabled: (input: any) =>
      input.isSubmitting ||
      this.disabled
  });

  readonly rejectButton = input(this, {
    name: 'rejectButton',
    disabled: (input: any) =>
      input.isSubmitting ||
      this.disabled
  });

  readonly inputGroup: InputGroupState = inputGroup(this, {
    name: "speaker",
    inputs: () => {
      if (this.isPending) {
        return [
          this.acceptButton,
          this.rejectButton,
          this.description,
          this.linkedin,
          this.avatar
        ]
      }
      return [
        ...this.speakerInputGroup.inputGroup.inputs,
        this.description,
        this.linkedin,
        this.avatar
      ];
    }
  });

  @observable isLoading = false;
  @observable isReadonly = false;
  @observable confirmStatus: SpeakerConfirmStatus | null = null;

  @computed get mode(): SpeakerFormMode {
    return this.resolvedProps.mode ?? SpeakerFormMode.Edit;
  }

  @computed get isRequired() {
    return this.resolvedProps.isRequired;
  }

  @computed get disabled() {
    return this.resolvedProps.disabled;
  }

  @computed get teamId(): string | null {
    return this.resolvedProps.teamId ?? null;
  }

  @computed get jobId(): string | null {
    return this.resolvedProps.jobId ?? null;
  }

  @computed get error(): string | null {
    return this.resolvedProps.error;
  }

  @computed get shouldFetchSpeaker(): boolean {
    return this.resolvedProps.shouldFetchSpeaker ?? false;
  }

  @computed get isConfirmMode() {
    return this.mode === SpeakerFormMode.Confirm;
  }

  @computed get isConfirmed(): boolean {
    return this.isConfirmMode && this.confirmStatus === SpeakerConfirmStatus.Confirmed;
  }

  @computed get isRejected(): boolean {
    return this.isConfirmMode && this.confirmStatus === SpeakerConfirmStatus.Rejected;
  }

  @computed get isEditConfirmed(): boolean {
    return this.isConfirmMode && this.confirmStatus === SpeakerConfirmStatus.Edit;
  }

  @computed get isPending(): boolean {
    return this.isConfirmMode && (this.confirmStatus === SpeakerConfirmStatus.Pending || this.confirmStatus === null);
  }

  @computed
  get speakerModelId(): string | null {
    return this.speakerInputGroup.speakerModelId;
  }

  @computed
  get speakerModel(): SpeakerModel | null {
    return this.speakerInputGroup.speakerModel;
  }

  @computed
  get name(): string | null {
    return this.speakerInputGroup.name;
  }

  @computed
  get publicSpeakerItems(): DropdownItemObject[] {
    let currentPublicSpeakers = this.store.speakerManager.publicSpeakers;

    if (this.teamId)
      currentPublicSpeakers = currentPublicSpeakers.filter(item =>
        (item.scope === SCOPE_TEAM_PREFIX + this.teamId) || item.hasPublicScope);

    return currentPublicSpeakers.map(speaker => ({
      value: speaker.id,
      label: speaker.name
    }));
  }

  @computed
  get isEmpty(): boolean {
    return !this.name;
  }

  @action
  async fetchSpeaker(id: string | null) {
    if (!id)
      return;

    const [, err] = await this.store.apiFetchSpeaker({ id });
    if (err) {
      notifyError(this, `Could not fetch speaker.`);
      return;
    }
  }

  checkLinkValidity = (link: string) => {
    const isValidUrl = checkLinkedInUrlValidity(link);
    if (link && !isValidUrl)
      return 'Invalid LinkedIn link';
    return null;
  }

  @action
  async handleChangeSpeaker(id: string | null) {
    if (id)
      await this.fetchSpeaker(id);

    this.description.value = this.speakerModel?.description || null;
    this.linkedin.value = this.speakerModel?.linkedin || null;
    this.avatar.loadPreviewUrl(this.speakerModel?.pictureURL || null);
  }

  @action
  async load({ speakerId }: { speakerId: string | null }) {
    this.isLoading = true;
    if (this.shouldFetchSpeaker)
      await this.fetchSpeaker(speakerId);
    this.initForm(speakerId);
    this.isLoading = false;
  }

  @action
  onSelectSpeaker = async (id: string | null) => {
    this.isLoading = true;
    if (this.shouldFetchSpeaker)
      await this.fetchSpeaker(id);
    this.syncFields();
    runInAction(() => this.isLoading = false);
  }

  @action
  onCancelMode = () => {
    this.syncFields();
  }

  @action
  onAddSpeakerMode = () => {
    this.description.clear();
    this.linkedin.clear();
    this.avatar.clear();
  }

  @action
  initForm(speakerId: string | null) {
    const speaker = this.store.maybeGetSpeaker(speakerId);
    this.speakerInputGroup.selector.loadValue(speaker ? {
      value: speaker.id,
      label: speaker.name
    } : null);
    this.syncFields();

    if (this.mode === SpeakerFormMode.Confirm)
      this.confirmStatus = SpeakerConfirmStatus.Pending;
  }

  @action
  // this method syncs the detail fields with the speaker selector or clears the fields if no speaker selected
  // should be used when speaker is changed or when an initial value reset makes sense for the detail fields
  syncFields() {
    const speaker = this.speakerInputGroup.speakerModel;
    this.description.loadValue(speaker?.description || null);
    this.linkedin.loadValue(speaker?.linkedin || null);
    this.avatar.loadPreviewUrl(speaker?.pictureURL || null);
  }

  @action
  reset() {
    this.inputGroup.clear();
    this.isLoading = false;
    this.confirmStatus = null;
    this.speakerInputGroup.cancelMode();
    this.confirmStatus = null;
  }

  @action
  clear() {
    this.inputGroup.clear();
    this.isLoading = false;
    this.confirmStatus = null;
    this.speakerInputGroup.clear();
  }

  @action
  async submitAddSpeaker(): AsyncResult<boolean> {

    const { editor } = this.speakerInputGroup;
    const speakerName = editor.value;

    const addArgs: SpeakerAddInput = {
      name: speakerName!,
      description: this.description.value ?? '',
      linkedin: this.linkedin.value ?? '',
      teamId: this.teamId ?? undefined,
      jobId: (!this.teamId && this.jobId) ? this.jobId : undefined
    }

    const [res, err] = await this.store.speakerManager.apiAddSpeaker({
      args: addArgs
    });

    if (err)
      return [null, 'Failed to create the speaker'];

    if (res) {
      this.speakerInputGroup.cancelMode();
      this.initForm(res.id);
    }

    if (this.avatar.isDirty && this.speakerModel) {
      const pictureData = await this.getPictureUploadToken();
      const token = pictureData?.pictureToken;
      // Update the profile picture if the picture upload was successful
      if (token) {
        const updateArgs: SpeakerUpdateInput = {
          id: this.speakerModel.id,
          pictureToken: pictureData?.pictureToken,
          teamId: this.teamId ?? undefined
        }
        const [, updateError] = await this.store.apiService.updateSpeaker({
          args: updateArgs
        });
        if (updateError) {
          this.handleSubmitReject('Failed to update the speaker picture.');
          return [null, 'Failed to update the speaker picture.'];
        }
      } else {
        this.handleSubmitReject('Failed to update the speaker picture.');
        return [null, 'Failed to update the speaker picture.'];
      }
    }
    return [true];
  }

  @action
  async submitEditSpeaker(): AsyncResult<boolean> {

    const { editor, editSpeaker } = this.speakerInputGroup;
    const speakerName = editor.value;

    assert(!!editSpeaker,
      `Cannot submit SpeakerInputModel in edit mode if no SpeakerModel has been loaded.`);
    const oldName = editSpeaker?.name;

    const pictureData = await this.getPictureUploadToken();
    if (!pictureData && this.avatar.file) {
      this.handleSubmitReject(`Failed to update the speaker ${oldName}.`);
      return [null, `Failed to update the speaker ${oldName}.`];
    }

    try {
      if (!editSpeaker)
        throw new Error('Error: No valid edit entity!');

      const updateArgs: SpeakerUpdateInput = {
        id: editSpeaker?.id,
        name: speakerName!,
        description: this.description.value || '',
        linkedin: this.linkedin.value ?? '',
        pictureToken: pictureData?.pictureToken || undefined,
        teamId: this.teamId ?? undefined
      }

      await this.store.speakerManager.apiUpdateSpeaker({
        args: updateArgs
      });
    } catch (e) {
      this.handleSubmitReject(`Failed to update the speaker ${oldName}.`);
      return [null, `Failed to update the speaker ${oldName}.`];
    }

    return [true];
  }

  @action
  async submit(): AsyncResult<boolean> {
    const mode = this.speakerInputGroup.mode;
    const { editor } = this.speakerInputGroup;
    const speakerName = editor.value;

    if (!mode || !this.inputGroup.isDirty) // nothing to do
      return [true];

    this.isLoading = true;
    this.inputGroup.handleSubmit();

    if (this.inputGroup.error) {
      this.inputGroup.handleSubmitReject();
      this.isLoading = false;
      return [null, 'Fix the validation errors before saving.'];
    }

    switch (mode) {

      case 'add':
        const [resAdd, errAdd] = await this.submitAddSpeaker();
        if (!resAdd && errAdd)
          return [null, errAdd]
        break;

      case 'edit':
        const [resEdit, errEdit] = await this.submitEditSpeaker();
        if (!resEdit && errEdit)
          return [null, errEdit]
        break;

      default:
        break;
    }

    if (mode === 'add')
      this.handleSubmitSuccess(`Added speaker ${speakerName}.`);
    else
      this.handleSubmitSuccess(`Speaker ${speakerName} updated successfully.`);

    return [true];
  }

  private handleSubmitReject(msg: string) {
    this.inputGroup.handleSubmitReject();
    this.isLoading = false;
  }

  private handleSubmitSuccess(msg: string) {
    this.inputGroup.handleSubmitResolve();
    this.isLoading = false;
  }

  @action
  handleConfirm = async () => {
    const res = await this.props?.onConfirm();
    if (res)
      this.confirmStatus = SpeakerConfirmStatus.Confirmed;
  }

  @action
  handleReject = () => {
    this.confirmStatus = SpeakerConfirmStatus.Rejected;
  }

  async getPictureUploadToken() {
    const file = this.avatar.file;

    if (!file || !this.speakerModel)
      return null;

    // Get the upload URL
    const [res, err] = await this.store.apiService.getSpeakerPictureUploadUrl({
      id: this.speakerModel?.id,
      fileName: file.name,
      teamId: this.teamId ?? undefined
    });

    if (err)
      return null;

    if (res) {
      const params = res.getSpeakerPictureUploadUrl;
      const uploadParams: ApiUploadRequestParams = {
        file,
        url: params?.uploadUrl,
        data: params?.fields
      }

      // Start the upload request
      const uploadRequest = this.store.apiService.uploadRequest(uploadParams);
      const [, uploadErr] = await uploadRequest.start();

      if (uploadErr) {
        return null;
      }

      return {
        pictureToken: params?.uploadToken
      }
    }
  }

  @action
  setConfirmed() {
    if (!this.isConfirmMode)
      return;
    this.confirmStatus = SpeakerConfirmStatus.Confirmed;
  }

  @action
  setEditConfirmed() {
    if (!this.isConfirmMode)
      return;
    this.confirmStatus = SpeakerConfirmStatus.Edit;
  }
}