import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { TrainerSVGIcon, PlayerBookmarkSVGIcon, ShareSVGIcon, UserSVGIcon, DownloadSVGIcon } from '../svg';
import { MomentModel } from '../../entities/moment';
import { PlayerSpeakerAvatar } from '../playerShared/playerSpeakerAvatar';
import { PlayerMomentTime } from '../playerShared/playerMomentTime';
import { CliprSVGIcon, TagSVGIcon } from '../player/components/playerSvg';
import { PlayerIndexState } from './playerIndexState';
import { ExpandToggle } from '../playerShared/expandToggle';
import { Button } from '..';
import { SyncStatus } from '../../entities';

type IndexCardProps = {
  id?: string,
  model?: PlayerIndexState | null,
  moment: MomentModel,
  isExpanded?: boolean,
  isActive?: boolean,
  showTopicTags?: boolean,
  onExpand?: (id: string) => void,
  onClick?: (id: string) => void
}

export const PlayerIndexCard = observer(({
  id,
  model,
  moment,
  isExpanded = false,
  isActive = false,
  onExpand,
  onClick
}: IndexCardProps) => {

  const isMobile = !!model?.store.ui.isMobile;
  const { widgetService } = model?.store || {};
  const showTopicTags =
    widgetService?.isWidgetMode ?
      (widgetService?.showTopicTags ?? true) : true;

  const [isHovered, setHovered] = useState(false);
  const className = classNames('user-player-index-card',
    moment.momentKind?.toLowerCase(), {
    'expanded': isExpanded,
    'active': isActive,
    'hover': isHovered || (isMobile && isActive)
  });

  return (
    <div
      id={id}
      data-moment={true}
      data-moment-id={moment.id}
      className={className}
      onPointerEnter={() => {
        !isMobile && setHovered(true);
      }}
      onPointerLeave={() => {
        !isMobile && setHovered(false);
      }}>

      <IndexCardSidebar
        isExpanded={isExpanded}
        model={model}
        moment={moment} />

      <div className="card-main"
        onClick={() => {
          onClick?.(moment.id);
        }}>

        <div
          id={id ?? 'subtopic-card'}
          data-tutorial-id={id ?? 'subtopic-card'}
          className="card-content">
          <IndexCardHeader
            model={model}
            moment={moment}
            isExpanded={isExpanded}
            onExpand={onExpand} />

          {isExpanded &&
            <IndexCardBody moment={moment} />}
        </div>

        {moment.keywords?.length > 0 &&
          <IndexCardFooter
            showTopicTags={showTopicTags}
            moment={moment}
            isExpanded={isExpanded} />}

      </div>
    </div>
  );
});

const IndexCardSidebar = observer(({
  isExpanded,
  model,
  moment,
}: IndexCardProps) => {

  const player = model?.player ?? null;

  const canBookmark = model?.store.isAuthorized;
  const hasBookmark = player?.momentHasBookmark(moment) ?? false;
  const showBookmarkBtn = canBookmark;

  const job = player?.job;

  const isEditable: boolean = (
    job?.hasPermission('EditUserMoment') &&
    moment.userHasEditPermission) ?? false;

  const showEditBtn = isEditable;
  const allowShare = model?.player.allowShare ?? true;
  const showShareBtn = job?.isPublic && allowShare;

  const className = classNames('card-sidebar', {
    'expandable': showBookmarkBtn || showEditBtn || showShareBtn
  });

  return (
    <div className={className}>
      <div className="card-sidebar-content">
        {canBookmark && (
          <button className="card-sidebar-btn card-bookmark-btn"
            onClick={evt => player?.openBookmarkWindow(moment)}
            aria-pressed={hasBookmark}>
            <PlayerBookmarkSVGIcon
              pressed={hasBookmark} />
          </button>
        )}

        {job?.isPublic && showShareBtn && (
          <button
            className="card-sidebar-btn card-share-btn"
            onClick={(evt) => player?.shareVideo(moment)}>
            <ShareSVGIcon />
          </button>
        )}
      </div>
    </div>
  );
})

const IndexCardHeader = observer(({
  model,
  moment,
  isExpanded = false,
  onExpand
}: IndexCardProps) => {

  const player = model?.player ?? null;
  const job = player?.job;
  const isEditable: boolean = (
    job?.speakersSyncStatus === SyncStatus.Fetched &&
    job?.hasPermission('EditUserMoment') &&
    moment.userHasEditPermission) ?? false;
  const isExpandable = moment.description || moment.speaker;
  const team = model?.store.teamManager.getTeam(model?.teamId);

  const modelNameClasses = classNames('model-name', {
    'expanded': isExpanded
  })

  return <div className="header">
    <div className="row bpad1">
      <span className="model-type">
        {moment.momentKind}
      </span>

      {job &&
        ((!team && job.hasPermission('UserEditJob')) || 
        (team && team.hasPermission('EditTeam'))) && (
          <button
            className="card-sidebar-btn card-download-btn"
            onClick={(evt) => {
              evt.stopPropagation();
              model?.dispatch('openDownloadJobWindow', {
              jobId: job?.id,
              startTime: moment.startTime,
              endTime: moment.endTime,
              teamId: model?.teamId,
            })}}>
            <DownloadSVGIcon />
          </button>
        )}

      <div className="moment-time-edit">
        <PlayerMomentTime model={moment} />
        {isEditable && (
          <Button
            className="edit-btn btn-plain"
            onClick={(evt) => {
              evt.stopPropagation();
              player?.momentEditor.openEditMomentWindow(moment)
            }}
            label="Edit"
          />
        )}
      </div>
    </div>
    <div className="row">
      <span className={modelNameClasses}>{moment.name}</span>
      {isExpandable && <ExpandToggle onExpand={(evt) => {
        evt.stopPropagation();
        if (onExpand)
          onExpand(moment.id)
      }}
        className={"expand-btn"}
        isExpanded={isExpanded} />}
    </div>
  </div >
})

const IndexCardFooter = observer(({
  moment,
  isExpanded,
  showTopicTags = true
}: IndexCardProps) => {

  return <div className="card-footer" aria-hidden={!isExpanded}>
    {showTopicTags && <ModelTagsCard moment={moment} />}
    <ModelSource moment={moment} />
  </div>
})

const IndexCardBody = observer(({
  moment
}: IndexCardProps) => {

  return <div className="expansion-box bpad4">
    {(moment.description || moment.summary) && <div className="row">
      <span className="model-description">{moment.summary ?? moment.description}</span>
    </div>}
    {moment.speaker && <div className="row tpad4 bpad2">
      <div className="user-player-speaker-card">
        <PlayerSpeakerAvatar
          model={{ pictureURL: moment.speaker?.pictureURL }} />
        <div className="speaker-name">{moment.speaker?.displayName}</div>
      </div>
    </div>}
    {moment.speaker?.description && <div className="row">
      <div className="speaker-description">{moment.speaker?.description}</div>
    </div>}
  </div>
})

type ModelTagsCardProps = {
  moment: MomentModel,
  numberOfElements?: number
}

const ModelTagsCard = observer(({
  moment,
  numberOfElements = 2
}: ModelTagsCardProps) => {

  const keywords = (moment.keywords?.slice(0, numberOfElements) || []).join(', ');
  if (!keywords)
    return null;

  return (
    <div className="model-tags">
      <div className="icon"><TagSVGIcon /></div>
      <div className="text">{keywords}</div>
    </div>
  )
})

const ModelSource = observer(({
  moment
}: ModelTagsCardProps) => {

  const source = moment.source;
  let momentSrcEl = null;

  switch (source) {
    case 'Trainer':
      momentSrcEl = (
        <div className="model-source">
          <span className="icon"><TrainerSVGIcon /></span>
          <span className="text">Trainer</span>
        </div>
      );
      break;

    case 'Clipr':
      momentSrcEl = (
        <div className="model-source">
          <span className="icon"><CliprSVGIcon /></span>
          <span className="text">{`Created this ${moment.momentKind}`}</span>
        </div>
      );
      break;

    case 'User':
      momentSrcEl = (
        <div className="model-source">
          <span className="icon"><UserSVGIcon /></span>
          <span className="text">User</span>
        </div>
      );
      break;

    default:
      break;
  }

  return momentSrcEl;
})