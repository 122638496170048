import React, { MouseEventHandler, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import {
  MinusSVGIcon,
  PlusSVGIcon,
  PencilSVGIcon,
  ThrashSVGIcon,
  CheckSVGIcon,
  KeyboardSVGIcon
} from '../../svg';
import { TrainerTimelineState } from '../trainerTimelineState';
import { DotSpinner } from '../../loader/dotSpinner';
import { ChevronLeftSVGIcon, ChevronRightSVGIcon } from '../../player/components/playerSvg';

type OptionProps = {
  icon?: ReactNode,
  label: ReactNode,
  color: string,
  selected: boolean,
  onClick: MouseEventHandler
}

const ToolbarOptionButton = observer((props: OptionProps) => {

  return (
    <button className={"toolbar-btn option-btn " + props.color}
      aria-selected={props.selected}
      role="option"
      onClick={props.onClick}>

      {props.icon &&
        <span className="icon">{props.icon}</span>}
      <span className="label">{props.label}</span>
    </button>
  );
});

type Props = {
  model: TrainerTimelineState
}

export const TimelineToolbar = observer((props: Props) => {
  const { model } = props;
  const { isTranscriptProcessing, isEnrichmentProcessing } = model;

  return (
    <div className="timeline-toolbar">
      <div className="flex-left">
        {!isTranscriptProcessing &&
          <div className="speaker-actions">
            <div className="add-speaker-action">
              <button className="plus-btn add-speaker-btn"
                onClick={evt => model.invoke('addSpeaker', { jobId: model.job?.id })}>
                <PlusSVGIcon />
              </button>
              <div className="label">Add/Edit speaker</div>
            </div>
          </div>}

        {!isEnrichmentProcessing &&
          <div className="track-actions">
            <div className="add-track-action">
              <button className="plus-btn add-speaker-btn"
                onClick={evt => model.invoke('addTrack', { jobId: model.job?.id })}>
                <PlusSVGIcon />
              </button>
              <div className="label">Add track</div>
            </div>
          </div>}
      </div>

      <div className="flex-center">
        <div className="moment-source-selector">
          <span className="text">FILTER</span>
          <ToolbarOptionButton
            label="CLIPr"
            selected={model.sourcesFilter.has('Clipr')}
            color="blue"
            onClick={() => model.invoke('toggleSourceOption', { sourceName: 'Clipr' })} />
          <ToolbarOptionButton
            label="Trainer"
            selected={model.sourcesFilter.has('Trainer')}
            color="yellow"
            onClick={() => model.invoke('toggleSourceOption', { sourceName: 'Trainer' })} />
          <ToolbarOptionButton
            label="User"
            selected={model.sourcesFilter.has('User')}
            color="gray"
            onClick={() => model.invoke('toggleSourceOption', { sourceName: 'User' })} />
        </div>
      </div>

      {isEnrichmentProcessing &&
        <div className="trainer-info-message">
          <p>Processing AI Moments</p>
          <DotSpinner className="small" />
        </div>
      }

      {!isEnrichmentProcessing &&
        <div className="confidence-treshold-block">
          <div className="confidence-threshold timeline-zoom">
            <label className="label" onClick={evt => model.invoke('setConfidence', { value: 100 })}>Confidence</label>

            <button className="minus-btn zoom-btn"
              onClick={evt => model.invoke('setConfidence', { value: model.confidence - 1 })}>
              <MinusSVGIcon />
            </button>

            <input type="range" className="confidence-threshold-slider zoom-slider"
              value={model.confidence}
              min={model.minConfidence}
              max={model.maxConfidence}
              onChange={evt => model.invoke('setConfidence', { value: parseInt(evt.target.value) })}
              step="any" />

            <button className="plus-btn zoom-btn"
              onClick={evt => model.invoke('setConfidence', { value: model.confidence + 1 })}>

              <PlusSVGIcon />
            </button>

            <label className="label lmar2">{model.confidence}</label>
          </div>

          <div className="skippr-btn-block">
            <button className={"skipr-btn blue"}
              onClick={() => model.throttledNavigateBackwardTranscripts()}>
              <span className="icon"><ChevronLeftSVGIcon /></span>
            </button>
            <button className={"skipr-btn blue"}
              onClick={() => model.throttledNavigateForwardTranscripts()}>
              <span className="label">SKIPr</span>
            </button>
            <button className={"skipr-btn blue"}
              onClick={() => model.throttledNavigateForwardTranscripts()}>
              <span className="icon">{<ChevronRightSVGIcon />}</span>
            </button>
          </div>

        </div>}

      <div className="flex-right hpad2">
        <div className="timeline-zoom">
          <label className="label" onClick={evt => model.invoke('setZoom', { value: 1 })}>Zoom</label>

          <button className="minus-btn zoom-btn"
            onClick={evt => model.invoke('setZoom', { value: model.zoom * 0.75 })}>

            <MinusSVGIcon />
          </button>

          <input type="range" className="zoom-slider"
            value={Math.log2(model.zoom)}
            min={Math.log2(model.minZoom)}
            max={Math.log2(model.maxZoom)}
            onChange={evt => model.invoke('setZoom', { value: Math.pow(2, parseFloat(evt.target.value)) })}
            step="any" />

          <button className="plus-btn zoom-btn"
            onClick={evt => model.invoke('setZoom', { value: model.zoom / 0.75 })}>

            <PlusSVGIcon />
          </button>
        </div>

        <button
          className="keyboard-shortcuts-btn"
          onClick={() => model.dispatch('openTrainerKeyboardShortcutsWindow')}>
          <KeyboardSVGIcon />
        </button>
      </div>
    </div>
  );
});

export const EditMomentsToolbar = observer((props: Props) => {
  const { model } = props;
  return (< div className="timeline-toolbar edit-moments-toolbar" >
    <div className="flex-left">
      <div className="speaker-actions">
        <div className="moments-selection-info">
          <div className="icon"><CheckSVGIcon /></div>
          {model.selectedMomentIds.length} {model.selectedMomentIds.length === 1 ? "segment" : "segments"} selected
        </div>
      </div>
    </div>

    <div className="flex-center">
      <div className="edit-moments-controls">
        <button className="edit-moments-btn"
          onClick={
            evt => model.selectedMomentIds.length > 1 ?
              model.invoke('openMultipleClipEditorWindow') :
              model.invoke('openClipEditorWindow', {
                momentId: model.selectedMomentIds[0]
              })}>
          <PencilSVGIcon />
        </button>
        <button className="edit-moments-btn"
          onClick={evt => model.invoke('openMomentsDeletePopup')}>
          <ThrashSVGIcon />
        </button>
      </div>
    </div>

    <div className="flex-right hpad2">
      <div className="moments-selection-notification">
        ctrl+click for selection, shift+click for mass selection, esc to deselect all
      </div>
    </div>
  </div >)
})