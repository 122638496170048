import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { JobModel, Team } from '../../entities';
import { getPlayerWidgetIFrameCode } from '../../widgets/playerWidget/playerWidgetUtils';
import { notifySuccess } from '../../services/notifications';
import { Routes } from '../../routes';
import { input } from '..';
import { Theme, View } from '../../services/ui/utils';

export class StreamAssetsWindowState
  extends StoreNode {
  readonly nodeType = 'StreamAssetsWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(this.windowListener);
  }
  readonly window = new WindowState(this.store);
  readonly publicStream = input(this, {
    name: 'publicStream',
    label: () => this.publicStream.value ? 'Make Stream Private' : 'Make Stream Shareable',
    onChange: () => this.togglePublishVideo()
  });

  @observable jobId!: string;
  @observable teamId!: string;
  @observable loading: boolean = false;

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed get team(): Team | null {
    return this.store.teamManager.getTeam(this.teamId);
  }

  @computed get ingestUrl(): string | null {
    return this.job?.liveStream?.ingestUrl ?? null;
  }

  @computed
  get jobIframeEmbedCode(): string {
    return getPlayerWidgetIFrameCode({ jobId: this.jobId })
  }

  @computed
  get iframeEmbedCode(): string | null {
    if (!this.team) return null;

    const { displayOptions, id } = this.team;

    const view = displayOptions?.galleryDisplay as View ?? null;
    const theme = displayOptions?.theme as Theme ?? null;
    
    const libraryRoute =
      Routes.teamLibraryWidget(id, { view, theme });
      
    const embedRoute = window.location.origin + libraryRoute;

    const iframe = document.createElement('iframe');

    iframe.src = embedRoute;
    iframe.width = '1280';
    iframe.height = '720';
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allow', 'autoplay; encrypted-media; picture-in-picture; clipboard-write; fullscreen');
    iframe.setAttribute('sandbox', 'allow-same-origin allow-scripts allow-popups allow-forms allow-popups-to-escape-sandbox allow-downloads allow-orientation-lock');

    let code = iframe.outerHTML;

    // // DIRTY HACK TO REVERSE URL ESCAPING
    code = code.replaceAll(/&amp;/gi, '&');
    return code;
  }

  @action
  async togglePublishVideo() {
    this.loading = true;

    await this.job?.handlePublishVideo();
    this.emit('jobUpdated');

    this.loading = false;
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  };

  @action
  open({ jobId, teamId }: { jobId: string, teamId: string }) {
    this.jobId = jobId;
    this.teamId = teamId;

    this.publicStream.loadValue(this.job?.isPublic);

    this.emit('open');
    this.dispatch('Overlays', 'openWindow', { name: 'StreamAssetsWindow' });
  }

  @action
  async copyPageIframeCode() {
    if (this.iframeEmbedCode) {
      await navigator.clipboard?.writeText(this.iframeEmbedCode);
      notifySuccess(this, 'Embed code copied to clipboard!');
    }
  }

  @action
  async copyJobIframeCode() {
    await navigator.clipboard?.writeText(this.jobIframeEmbedCode);
    notifySuccess(this, 'Embed code copied to clipboard!');
  }

  @action
  async copyUrl() {
    await navigator.clipboard?.writeText(this.ingestUrl!);
    notifySuccess(this, 'RTMP URL copied to clipboard!');
  }

  @action
  close() {
    this.window.close();
    this.dispatch('Overlays', 'closeWindow');
  }
}
