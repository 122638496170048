import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { PencilSVGIcon, PlusSVGIcon, CheckSVGIcon, CloseSVGIcon } from '../svg';

import { Button, TextInputControlled, DropdownInputControlled, ButtonControlled } from '../input';
import { SpeakerInputGroupState } from './speakerInputGroupState';
import { SpeakerFormMode } from './speakerFormBlockState';

type Props = {
  model: SpeakerInputGroupState;
};

export const SpeakerInputGroup = observer(
  ({ model }: Props) => {
    const { layout } = model;

    const handleAddSpeakerButtonClick = useCallback(
      (evt) => model.openAddSpeakerMode(),
      [model]
    );

    const handleCancelButtonClick = useCallback((evt) => model.cancelMode(), [
      model,
    ]);

    const handleSubmitButtonClick = useCallback((evt) => model.submit(), [
      model,
    ]);

    let inputs;
    switch (model.mode) {
      case 'edit':
      case 'add':
        inputs = (
          <div className="input-group-inputs gap1">
            <TextInputControlled
              className="speaker-input fill"
              model={model.editor}
              label={'Speaker'}
              placeholder="Enter speaker name" />

            {false && (
              <Button
                leftIcon={<CheckSVGIcon />}
                onClick={handleSubmitButtonClick}
                disabled={model.inputGroup.isSubmitting} />
            )}

            <ButtonControlled
              leftIcon={<CloseSVGIcon />}
              onClick={handleCancelButtonClick}
              model={model.cancelButton}
              disabled={model.inputGroup.isSubmitting} />
          </div>
        );
        break;

      default:
        inputs = (
          <div className="input-group-inputs gap1">
            <DropdownInputControlled
              className="speaker-input fill"
              label={'Speaker'}
              model={model.selector}
              placeholder="Select speaker"
              persistentFeedback
              withSearch={true} 
              virtualized={true}/>

            {layout === SpeakerFormMode.Edit && model.selector.value && (
              <ButtonControlled
                leftIcon={<PencilSVGIcon />}
                model={model.editButton}
                onClick={model.openEditSpeakerMode} />
            )}

            {[SpeakerFormMode.Edit, SpeakerFormMode.Create].includes(layout) && (
              <ButtonControlled
                leftIcon={<PlusSVGIcon />}
                model={model.addButton}
                onClick={handleAddSpeakerButtonClick} />
            )}
          </div>
        );
        break;
    }

    return (
      <div
        className={'input-group speaker-input-group'}>

        {inputs}
      </div>
    );
  }
);
