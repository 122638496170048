import { action, computed, makeObservable } from 'mobx';
import { Store } from '../../store/store';
import Routes from '../../routes';
import { LibraryProps, LibraryServiceBase } from './libraryServiceBase';
import { IntegrationTabState } from '../../pages/integrations/integrationTabState';
import { LibraryCatalogSource } from '../../entities';
import { LibraryName } from './librarySchema';
import { AsyncResult } from '../../core';

const SCOPE = 'https://graph.microsoft.com/.default offline_access';
const CLIENT_ID = process.env.REACT_APP_ONE_DRIVE_CLIENT_ID || '';
const ENABLED = process.env.REACT_APP_ENABLE_ONE_DRIVE?.toLocaleLowerCase() === 'true';

const params: LibraryProps = {
  libraryLabel: 'OneDrive',
  librarySource: 'OneDrive',
  routeView: 'onedrive',
  enabled: ENABLED
}
export class OneDriveService
  extends LibraryServiceBase {

  readonly nodeType: 'OneDriveLibrary' = 'OneDriveLibrary';

  constructor(store: Store) {
    super(store, params);
    makeObservable(this);
  }
  readonly libraryName = LibraryName.OneDrive;

  readonly defaultSearchValue = '.mp4';

  @computed
  get authUrl(): string {
    const url = new URL('https://login.microsoftonline.com/common/oauth2/v2.0/authorize');

    url.searchParams.append('response_type', 'code');
    url.searchParams.append('client_id', CLIENT_ID);
    url.searchParams.append('scope', SCOPE);
    url.searchParams.append('redirect_uri', window.location.origin + Routes.oauthOneDriveCallback());

    return url.toString();
  }

  @action
  async connectLibrary(redirect?: boolean): AsyncResult<true> {
    const redirectUri = window.location.origin + Routes.oauthOneDriveCallback();
    const [, err] = await this.connect(redirectUri);


    if (!err && redirect) {
      const customRoute = localStorage.getItem('externalLibraryUploadAuth');

      if (customRoute) {
        localStorage.removeItem('externalLibraryUploadAuth');
        this.store.goTo(customRoute);
        return [true];
      }
      this.store.goTo(Routes.userDashboard({ view: 'onedrive' }));
    }

    return [true];
  }

  @action
  async deleteLibrary(injectedModel?: IntegrationTabState | LibraryCatalogSource | null) {
    const [, err] = await this.disconnect();

    if (!err) {
      if (injectedModel) {
        injectedModel.reset();
        return;
      }

      this.store.userDashboardPage.oneDriveTabState.reset();
    }
  }
}
