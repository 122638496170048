import './components/css/app.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, Router, Switch } from 'react-router-dom';

import {
  DirectRoute,
  PublicRoute
} from './routes';
import { useStore } from './store/storeHooks';

import { PrivateRoute } from './routes/privateRoute';
import { WidgetRoute } from './routes/widgetRoute';
import { OnboardRoute } from './routes/onboardRoute';
import { AuthRoute } from './routes/authRoute';
import { getRouteLookup } from './routes/routeLookup';
import { RouteDescriptor, RouteType } from './routes/routeSchema';
import { Lazy } from './routes/lazy';

export const renderRouteElement = (path: string, entry: RouteDescriptor) => {
  let elem = entry.element;

  const render = (props: RouteComponentProps) => {
    let loader: React.ReactElement;

    if (typeof elem === 'function')
      loader = elem(props);
    else
      loader = elem;

    return (
      <Lazy>
        {loader}
      </Lazy>
    );
  }

  switch (entry.routeType) {
    case RouteType.Auth:
      return (
        <AuthRoute
          key={path}
          path={path}
          exact={entry.exact ?? true}
          render={render} />
      );

    case RouteType.Direct:
      return (
        <DirectRoute
          key={path}
          path={path}
          exact={entry.exact ?? true}
          render={render} />
      );

    case RouteType.Widget:
      return (
        <WidgetRoute
          key={path}
          path={path}
          exact={entry.exact ?? true}
          render={render} />
      );

    case RouteType.Private:
      return (
        <PrivateRoute
          key={path}
          path={path}
          exact={entry.exact ?? true}
          render={render} />
      );

    case RouteType.Public:
      return (
        <PublicRoute
          key={path}
          path={path}
          exact={entry.exact ?? true}
          render={render} />
      );

    case RouteType.Onboard:
      return (
        <OnboardRoute
          key={path}
          path={path}
          exact={entry.exact ?? true}
          render={render} />
      );
  }

  return null;
}

export const App = observer(() => {
  const store = useStore();
  const routeLookup = getRouteLookup(store);

  return (
    <div id="app"
      {...store.ui.containerJsxProps}>

      <Router history={store.history}>
        <Switch>
          {Object.keys(routeLookup).map(path => {
            const entry = routeLookup[path];
            return renderRouteElement(path, entry);
          })}
        </Switch>
      </Router>
    </div>
  );
});