import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { SquareMinusSVGIcon, SquarePlusSVGIcon } from '../../components/svg';
import {
  Button,
  TextInputControlled,
  CheckboxInputControlled,
} from '../../components/input';
import { UploadLinkState } from './uploadLinkState';
import { handler } from '../../core';
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

type Props = {
  state: UploadLinkState;
};

export const UploadLinkBox = observer((props: Props) => {
  const { state } = props;

  const { pendingItems } = state;

  useEffect(() => {
    state.mounted();
    return () => state.unmounted();
  }, [state]);

  const listSize = pendingItems.length;
  const isEmpty = listSize < 2 && state.form.isEmpty;
  const multipleLines = !state.isReplace;
  const showControls = !isEmpty && multipleLines;

  const className = classNames("upload-link-box upload-box", {
    empty: isEmpty
  });

  const style = {
    '--link-item-count': listSize
  } as any;

  return (
    <div className={className} style={style}>
      <div className="upload-link-list">
        <div className="link-container-label uppercase">Upload by video URL</div>
        <TransitionGroup component="ul">
          {pendingItems.map((item, i) => {
            return (
              <CSSTransition
                classNames="item-show"
                timeout={300}
                key={item.id}>
                <li key={item.id} className="upload-link-item">
                  <div className="item-row">
                    <TextInputControlled
                      model={item.linkInput} />

                    <button
                      aria-hidden={!showControls || (i !== (listSize - 1))}
                      className="line-suffix add-icon-btn btn-core"
                      type={'button'}
                      onClick={handler(state, 'addLinkItem')}>
                      <SquarePlusSVGIcon />
                    </button>
                    <button
                      aria-hidden={!showControls || (i === (listSize - 1))}
                      className="line-suffix remove-icon-btn btn-core"
                      type={'button'}
                      onClick={handler(state, 'removeLinkItem', [item.id])}>
                      <SquareMinusSVGIcon />
                    </button>
                  </div>
                  {!isEmpty && (<CheckboxInputControlled
                    model={item.hasPasswordInput}
                    className="has-password-checkbox" />)}
                </li>
              </CSSTransition>
            )
          })}
        </TransitionGroup>
      </div>

      {!isEmpty && (
        <Button
          label="Continue"
          className="primary medium submit-btn"
          onClick={handler(state, 'stage')}
          disabled={state.form.isSubmitDisabled}
        />
      )}
    </div>
  );
});