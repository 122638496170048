import './playerComments.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { handler } from '../../core';
import { Button } from '../input';
import { CommentThreadState } from './commentThreadState';
import { CommentCard } from './commentCard';
import { Comment } from '../../entities/comment';

type Props = {
  model: CommentThreadState,
  isActive?: boolean,
  onCommentClick?: (comment: Comment) => void
};

export const CommentThread = observer(({
  model,
  isActive,
  onCommentClick
}: Props) => {
  const { replies, showPrevReplies } = model;
  isActive = isActive || model.isActive || model.comment.isActive;
  const className = classNames('comment-thread', {
    'active': isActive
  });

  return (
    <div className={className}
      data-comment={true}
      data-comment-id={model.commentModel.id}>
      <CommentCard model={model.comment}
        isActive={isActive}
        onClick={() => onCommentClick && onCommentClick(model.commentModel)} />

      {replies.length > 1 && (
        <Button className="prev-replies-btn btn-plain"
          onClick={handler(model, 'toggleShowPrevReplies')}
          onPointerEnter={handler(model, 'handlePointerEnter')}
          onPointerLeave={handler(model, 'handlePointerLeave')}
          label={`${showPrevReplies ? 'Hide' : 'Load'} previous replies`} />
      )}


      <div className="replies"
        aria-expanded={showPrevReplies}>
        {replies.length > 0 && replies.map((reply, index) => {
          const isHidden = !showPrevReplies && index !== replies.length - 1;
          return <CommentCard key={reply.id}
            model={reply} hidden={isHidden} isActive={isActive}
            onClick={() => onCommentClick && onCommentClick(reply.comment)} />;
        })}
      </div>
    </div>
  );
});