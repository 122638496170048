import last from 'lodash/last';
import debounce from 'lodash/debounce';
import { DateTime } from 'luxon';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment';

import {
  AdminGetJobsFilterInput, AdminJobOrderByOption, DateRangeFilter, JobSpeciality, JobStatus, JobType, Order, PageInfo
} from '@clipr/lib';

import { ApiResult, ApiVariables } from '../api/apiSchema';
import { input, InputState } from '../components';
import { DateInputState, dateInput } from '../components/input/dateInput/dateInputState';
import { SortFieldValue } from '../components/input/sortFieldBtn';
import { assertNotNull, AsyncResult, Maybe, Nullable } from '../core';
import { notifyError } from '../services/notifications';
import { BindingProps, StoreNode } from '../store';
import { Store } from '../store/store';
import { SyncStatus } from '../store/syncSchema';
import { JobModel } from './job';
import { JobCatalogItem } from './jobCatalogItem';
import { JobLevel } from './job/jobFeatures';
import { JobsPosterSize } from './jobManager';
import { JobFilter } from '.';

type Props = BindingProps<{
  pageSize?: number
}> & {
  /** 
   * Local filter to apply to all Job entities. 
   * Currently it's only being used to detect the transient entities.
   */
  jobFilter?: JobFilter;
};

export class TrainerJobCatalogSource
  extends StoreNode {

  readonly nodeType: 'TrainerJobCatalogSource' = 'TrainerJobCatalogSource';
  readonly dateInput: DateInputState;

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.searchBarModel = input(this, {
      name: 'searchInput',
      onChange: (e) => this.handleSearchInputChange(e),
    });

    this.dateInput = dateInput(this, {
      placeholder: ['dd/mm/yyyy', 'dd/mm/yyyy'],
      format: ['DD/MM/YYYY', 'DDMMYYYY'],
      rangeOptions: [{
        name: 'Today',
        getRange: () => {
          return [moment(), moment()];
        }
      },
      {
        name: 'Yesterday',
        getRange: () => {
          return [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        }
      },
      {
        name: 'Last 30 Days',
        getRange: () => {
          return [moment().subtract(31, 'days'), moment()];
        }
      },
      {
        name: 'This Month',
        getRange: () => {
          return [moment().startOf('month'), moment().endOf('month')]
        }
      }]
    });
  }

  // #region Resolved props
  // -------
  @computed
  get pageSize(): number {
    return this.resolvedProps.pageSize;
  }

  @computed
  get jobFilter(): JobFilter | null {
    return this.getProp('jobFilter');
  }
  // #endregion

  readonly items = observable.array<JobCatalogItem>();
  readonly searchBarModel: InputState;

  @observable syncStatus: SyncStatus = 'idle';

  @observable videoTypeFilter: JobType | null = null;
  @observable levelFilter: JobLevel | null = null;
  @observable statusFilter: JobStatus | null = null;
  @observable specialityFilter: JobSpeciality | null = null;
  @observable createdByIdFilter: string | null = null;
  @observable beforeDateFilter: Date | null = null;
  @observable afterDateFilter: Date | null = null;
  @observable searchFilter: string | null = null;

  @observable sortField: AdminJobOrderByOption | null = 'createdAt';
  @observable sortOrder: Order | null = 'desc';

  @observable pageInfo: PageInfo | null = null;

  @computed get lastItemCursor(): string | null {
    return last(this.items)?.cursor || null;
  }

  @computed get itemEntities(): JobModel[] {
    return this.items.map(item => item.job);
  }

  @computed get transientEntities(): JobModel[] {
    const filter = this.jobFilter;
    if (typeof filter !== 'function')
      return [];

    return this.store.jobManager.transientJobs.filter(job => filter(job));
  }

  @computed get entities(): JobModel[] {
    return [
      ...this.transientEntities,
      ...this.itemEntities
    ];
  }

  @computed get isEndOfList(): boolean {
    return this.pageInfo ? !this.pageInfo.hasNextPage : false;
  }


  getSortFieldValue(field: AdminJobOrderByOption) {
    if (this.sortField !== field)
      return 'none';
    return this.sortOrder!;
  }

  @action
  setSortFieldValue(field: AdminJobOrderByOption, val?: SortFieldValue) {
    if (!val || val === 'none') {
      this.sortField = null;
      this.sortOrder = null;
    } else {
      this.sortField = field;
      this.sortOrder = val;
    }
    this.fetchDebounced();
  }


  getFilterFieldValue<T extends keyof AdminGetJobsFilterInput>(field: T): Nullable<AdminGetJobsFilterInput[T]> {
    switch (field) {
      case 'videoType':
        return this.videoTypeFilter;
      case 'status':
        return this.statusFilter;
      case 'level':
        return this.levelFilter;
      case 'speciality':
        return this.specialityFilter;
    }
    return null;
  }

  @action
  setFilterFieldValue<T extends keyof AdminGetJobsFilterInput>(field: T, val?: Maybe<AdminGetJobsFilterInput[T]>) {

    switch (field) {
      case 'videoType':
        this.videoTypeFilter = (val as JobType) || null;
        break;
      case 'status':
        this.statusFilter = (val as JobStatus) || null;
        break;
      case 'level':
        this.levelFilter = (val as JobLevel) || null;
        break;
      case 'speciality':
        this.specialityFilter = (val as JobSpeciality) || null;
        break;

    }
    this.fetchDebounced();
  }

  fetchDebounced = debounce(() => this.fetch(), 500);

  /** Makes a fresh fetch call, clearing any previous data and pagination state. */
  @action
  async fetch()
    : AsyncResult<JobCatalogItem[]> {

    this.syncStatus = 'fetching';

    const [jobsRes, err] = await this.fetchRaw();

    if (err)
      return [null, err];
    assertNotNull(jobsRes);

    const retItems: JobCatalogItem[] = [];
    runInAction(() => {
      this.items.clear();

      const { adminGetJobs } = jobsRes;
      this.pageInfo = adminGetJobs.pageInfo;

      adminGetJobs.edges.forEach((edge, i) => {

        const item = new JobCatalogItem(this.store, {
          jobId: edge.node.id,
          cursor: edge.cursor,
          index: i
        });

        this.items.push(item);
        retItems.push(item);
      });
    });

    return [retItems];
  }

  @action
  async fetchMore()
    : AsyncResult<JobCatalogItem[]> {

    this.syncStatus = 'fetchingMore';

    const [jobsRes, err] = await this.fetchRaw(true);
    if (err)
      return [null, err];
    assertNotNull(jobsRes);

    const retItems: JobCatalogItem[] = [];
    runInAction(() => {
      const { adminGetJobs } = jobsRes;
      this.pageInfo = adminGetJobs.pageInfo;

      adminGetJobs.edges.forEach((edge, i) => {

        const item = new JobCatalogItem(this.store, {
          jobId: edge.node.id,
          cursor: edge.cursor,
          index: i
        });

        this.items.push(item);
        retItems.push(item);
      });
    });

    return [retItems];
  }

  @action
  private getFetchArgs(more: boolean = false) {
    let args: ApiVariables<'adminGetJobs'> = {
      posterSize: [JobsPosterSize]
    };
    let filter: AdminGetJobsFilterInput = {};

    if (this.sortField && this.sortOrder) {
      args.sort = {
        field: this.sortField,
        order: this.sortOrder
      }
    }

    if (this.createdByIdFilter) {
      filter.createdById = this.createdByIdFilter;
    }

    if (this.statusFilter) {
      filter.status = {
        eq: this.statusFilter
      }
    }

    if (this.specialityFilter) {
      filter.speciality = this.specialityFilter as string;
    }

    if (this.videoTypeFilter) {
      filter.videoType = this.videoTypeFilter;
    }

    if (this.levelFilter) {
      filter.feature = {
        eq: this.levelFilter
      }
    }

    const createdAt: Maybe<DateRangeFilter> = {};
    if (this.beforeDateFilter) {
      // send the before date until almost midnight, otherwise it will be useless
      createdAt.lt = DateTime
        .fromJSDate(this.beforeDateFilter)
        .plus({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })
        .toUTC();
    }
    if (this.afterDateFilter) {
      createdAt.gte = DateTime
        .fromJSDate(this.afterDateFilter)
        .toUTC();
    }

    if (Object.keys(createdAt).length > 0)
      filter.createdAt = createdAt;

    if (this.searchFilter) {
      filter._search = this.searchFilter || undefined;
    }

    if (Object.keys(filter).length > 0)
      args.filter = filter;

    args.first = this.pageSize;

    if (more && this.lastItemCursor)
      args.after = this.lastItemCursor;

    return args;
  }

  @action
  private async fetchRaw(more: boolean = false): ApiResult<'adminGetJobs'> {

    const args: ApiVariables<'adminGetJobs'> = this.getFetchArgs(more);
    const { jobManager } = this.store;

    const [jobsRes, err] = await jobManager.apiFetchTrainerJobs(args, {
      rawResponse: true
    });

    if (err) {
      runInAction(() => {
        this.syncStatus = 'error';
        notifyError(this, `Could not fetch videos because of an error.`);
      });
      return [null, err];
    }

    runInAction(() => {
      this.syncStatus = 'idle';
    });

    assertNotNull(jobsRes);
    return [jobsRes];
  }


  @action
  handleSearchInputChange(evt: any) {
    this.searchFilter = evt.value || null;
    this.fetchDebounced();
  }

  @action
  handleDateFilterChange() {
    const dateInputValue = this.dateInput.value as [moment.Moment, moment.Moment];
    if (dateInputValue) {

      const formattedStartDate = dateInputValue[0].utcOffset(0);
      const formattedEndDate = dateInputValue[1].utcOffset(0);
      formattedStartDate.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      formattedEndDate.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      this.beforeDateFilter = formattedEndDate.toDate();
      this.afterDateFilter = formattedStartDate.toDate();
    } else {
      this.beforeDateFilter = null;
      this.afterDateFilter = null;
    }

    this.fetchDebounced();
  }

  reset() {
    //TODO: Check all the fields that we need to reset
    this.searchFilter = null;

    this.statusFilter = null;
    this.specialityFilter = null;
    this.levelFilter = null;

    this.videoTypeFilter = null;
    this.createdByIdFilter = null;

    this.beforeDateFilter = null;
    this.afterDateFilter = null;

    this.sortField = 'createdAt';
    this.sortOrder = 'desc';
    this.searchBarModel.clear();
  }

  // TEMP just for testing
  @action
  _injectJobs(jobs: JobModel[]) {

    runInAction(() => {
      this.pageInfo = null;

      jobs.forEach((job, i) => {

        this.items.push(new JobCatalogItem(this.store, {
          jobId: job.id,
          cursor: 'TEST_CURSOR_' + job.id,
          index: i
        }));
      });
    });
  }
}