import { action, computed, makeObservable, observable } from 'mobx';
import { History } from 'history';
import { Metadata, Order } from '@clipr/lib';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { JobCatalogSource, Team } from '../../entities';
import { CatalogState } from '../../components/layout/catalogState';
import { Error } from '../../core/error';
import { ApiVariables } from '../../api/apiSchema';
import { getApiTeamError } from '../../api';
import { RouteContext } from '../../routes/routeContext';
import { InlineAuthFlowController } from '../../services/auth/controllers/inlineAuthFlowController';
import { IRouteStorage } from '../../routes/routeSchema';
import { WidgetState } from '../widgetStateMixin';

import { INIT_DEBUGGER, TRACE } from '../../core/debug/debugMacros';

export type TeamWidgetRouteParams = {
  teamId: string
}

export class TeamWidgetState
  extends WidgetState(StoreNode) {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    INIT_DEBUGGER(this, {
      color: 'deeppink'
    });

    this.jobCatalogSource = new JobCatalogSource(this.store, {
      teamId: () => this.teamId,
      pageSize: 20
    });

    this.jobCatalog = new CatalogState(this.store, {
      syncStatus: () => this.jobCatalogSource.syncStatus,
      isEndOfList: () => this.jobCatalogSource.isEndOfList
    });
    this.jobCatalog.listen((msg) => {
      switch (msg.type) {
        case 'scrollToBottom':
        case 'loadMore':
          this.jobCatalogSource.fetchMore();
          break;
      }
    });
  }

  readonly nodeType = 'TeamWidget';

  readonly inlineAuthFlowController = new InlineAuthFlowController(this.store);

  readonly jobCatalogSource: JobCatalogSource;
  readonly jobCatalog = new CatalogState(this.store);

  @observable team: Team | null = null;

  @computed get teamId(): string | null {
    return this.widgetService.teamId;
  }

  @computed get showTeamName(): boolean {
    return this.widgetParams.showTeamName;
  }

  @computed get showSourceBadge(): boolean {
    return this.widgetParams.showSourceBadge;
  }

  @computed get showProfile(): boolean {
    return this.widgetParams.showProfile;
  }

  @computed get showTopicTags(): boolean {
    return this.widgetParams.showTopicTags;
  }

  @computed get metadataFilter(): Partial<Metadata> | null {
    return this.widgetParams.meta;
  }

  @computed get tagsFilter(): string[] | null {
    return this.widgetParams.tags;
  }

  @computed get sort(): Order | null {
    return this.widgetParams.sort;
  }

  @computed get teamName() {
    return this.team?.displayName || this.team?.name;
  }

  @action
  async mounted(routeContext: RouteContext<TeamWidgetRouteParams>) {
    TRACE(this, 'mounted()', routeContext, '\n', this.__traceState);

    this.reset();
    this.baseAttached(routeContext);

    this.emit('Mounted', {
      teamId: this.teamId
    });

    this.setLoading();

    const { tagsFilter, metadataFilter } = this;

    if (tagsFilter)
      this.jobCatalogSource.tagsFilter = tagsFilter;

    if (metadataFilter)
      this.jobCatalogSource.metadataFilter = metadataFilter;

    // Ensure Teams are fetched for job related permissions
    await this.store.teamManager.apiEnsureTeams();

    this.load();
  }

  @action
  unmounted() {
    TRACE(this, 'unmounted()', this.__traceState);

    this.baseDetached();
    this.reset();

    this.emit('Unmounted');
  }

  @action
  async load() {
    TRACE(this, 'load()', this.__traceState);

    if (!this.teamId)
      return;

    this.setLoading();

    this.jobCatalogSource.syncStatus = 'fetching';
    const args: ApiVariables<'getTeam'> = {
      id: this.teamId,
      coverSize: [{ width: 1920 }]
    }

    const [team, teamErr] = await this.store.teamManager.apiFetchTeam(args);

    if (teamErr || !team) {
      const decodedError = getApiTeamError(teamErr);
      return this.setError(decodedError);
    }

    this.setTeam(team);

    const { sort } = this;
    if (sort) {
      this.jobCatalogSource.sortOrder = sort;
      this.jobCatalogSource.sortField = 'sortOrder';
    }

    const [, jobsErr] = await this.jobCatalogSource.fetch();

    if (jobsErr)
      return this.setError();

    if (!this.team) {
      const [teamRes, teamErr] = await this.store.api.getTeam({ id: this.teamId });

      if (teamErr) {
        const decodedError = getApiTeamError(teamErr);
        return this.setError(decodedError);
      }

      this.setTeam(new Team(this.store, teamRes?.getTeam));
    }

    // make sure that none of the requests failed and also we have a Team entity for the requested ID
    if (teamErr || jobsErr || !this.team)
      return this.setError();

    // everything ok
    this.setLoaded();
  }

  @action
  reset() {
    TRACE(this, 'reset()', this.__traceState);

    this.baseReset();

    // very important, otherwise old responses will cause redirects 
    // and you'll bang your head against the wall as to why your widget keeps unmounting 
    this.inlineAuthFlowController.reset();

    this.team = null;
  }

  @action
  setTeam(team: Team | null) {
    this.team = team;
  }

  @action
  async refreshJobs() {
    return await this.jobCatalogSource.fetch();
  }

  @action
  handleLoginButtonClick = async (evt: React.MouseEvent) => {
    return this.inlineLogin();
  }

  @action
  handleLogoutButtonClick = async (evt: React.MouseEvent) => {
    return this.inlineLogout();
  }

  @action
  handleErrorBackButtonRoute = async (router: History<IRouteStorage>) => {
    this.error = null;
    return this.inlineLogout();
  }

  async inlineLogin() {

    const { routeContext } = this;
    if (!this.isAttached || !routeContext)
      return [null, new Error('InvalidComponentState')];

    const [flowRes, flowErr] = await this.inlineAuthFlowController.login(routeContext);
    if (flowErr)
      return [null, flowErr];

    await this.load();
    return [flowRes!];
  }

  async inlineLogout() {

    const { authService } = this.store;
    const { routeContext } = this;
    if (!this.isAttached || !routeContext)
      return [null, new Error('InvalidComponentState')];

    const [flowRes, flowErr] = await this.inlineAuthFlowController.logout(routeContext);
    if (flowErr)
      return [null, flowErr];

    if (!authService.context) {
      // we have been logged out and a redirect should be expected
      // TODO: add check to see that a redirect was returned
      return [flowRes!];
    }

    await this.load();
    return [flowRes!];
  }

  private get __traceState() {
    return {
      ...this.__baseTraceState
    }
  }
}