import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../../components/overlays';
import classNames from 'classnames';
import { CancelAccountWindowState } from './cancelAccountWindowState';

type Props = {
  model: CancelAccountWindowState;
  visible?: boolean;
};

export const CancelAccountWindow = observer(
  ({ model, visible }: Props) => {
    const className = classNames('cancel-account-modal');

    return (
      <Window
        model={model.window}
        visible={visible}
        className={className}>

        <WindowHeader
          title={'Cancel Account'}
          model={model.window}
        />

        <hr />

        <>
          <section className="window-content">
            <p className="p-bold">
              You may cancel your CLIPr account and request your data be deleted at any time, in accordance with our <a
                rel="noopener noreferrer"
                href={"https://www.clipr.ai/terms-of-use"}
                target="_blank">Terms of Use</a> and <a
                  rel="noopener noreferrer"
                  href="https://www.clipr.ai/privacy-policy"
                  target="_blank">Privacy Policy</a>.
            </p>
            <p>
              Fill out this <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.clipr.ai/data-deletion">Data Deletion Request</a>.
              We will reply to you via email as soon as we have completed the request.
            </p>
          </section>

          <hr />
        </>
      </Window>
    );
  }
);
