import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { InputGroupState, InputState, input, inputGroup } from '../input';
import { TeamUpdateInput, VocabularyInput } from '@clipr/lib';
import { notifyError, notifySuccess } from '../../services/notifications';


export class TeamDictionaryWindowState
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly submitButton = input(this, {
    name: 'saveButton',
    disabled: (input: any) => {
      return (
        input.loading ||
        input.isSubmitting ||
        this.isSubmitDisabled);
    }
  });

  readonly nodeType = 'TeamDictionaryWindow';
  readonly window = new WindowState(this.store);

  @observable isLoading = false;
  @observable teamId: string | null = null;
  @observable teamDictionaryList = observable.array<TeamDictionaryItemState>();

  @computed
  get hasError() {
    return !!this.teamDictionaryList.find(i => i.dictionaryInputGroup.hasErrorInputs);
  }

  @computed
  get isPairIncomplete() {
    return !!this.teamDictionaryList.find(i => (!i.correctWordInput.isEmpty && i.misspelledWordsInput.isEmpty));
  }

  @computed
  get isSubmitDisabled() {
    return this.hasError || this.isPairIncomplete;
  }

  @computed get team() {
    return this.store.teamManager.getTeam(this.teamId);
  }

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close();
        break;
    }
  }

  @action
  open(teamId: string | null) {
    this.reset();
    this.teamId = teamId;

    this.init();
    this.dispatch('Overlays', 'openWindow', { name: 'TeamDictionaryWindow' });
  }

  @action
  close() {
    this.emit('TeamDictionaryWindow:close');
    this.dispatch('Overlays', 'closeWindow');
  }

  @action
  addDictionaryItem = () => {
    const teamDictionaryItemState: TeamDictionaryItemState  = new TeamDictionaryItemState(this.store);
    this.teamDictionaryList.push(teamDictionaryItemState);
  }

  @action
  removeDictionaryitem = (i: number) => {
    this.teamDictionaryList.splice(i, 1);
  }

  @action 
  init() {
    if (!this.team?.vocabulary?.length) {
      this.addDictionaryItem();
      return;
    }

    this.team.vocabulary.forEach(value => {
      const teamDictionaryItemState: TeamDictionaryItemState  = new TeamDictionaryItemState(this.store);
      teamDictionaryItemState.misspelledWordsInput.loadValue(value.phrase);
      teamDictionaryItemState.correctWordInput.loadValue(value.displayAs);
      this.teamDictionaryList.push(teamDictionaryItemState);
    });
  }

  @action
  submit = async () => {
    this.isLoading = true;
    const vocabularyInput: VocabularyInput[] = this.teamDictionaryList
    .filter(teamDictionaryItem => !teamDictionaryItem.misspelledWordsInput.isEmpty)
    .map(teamDictionaryItem => ({
      phrase: teamDictionaryItem.misspelledWordsInput.value!,
      displayAs: teamDictionaryItem.correctWordInput.value || ' '
    }));

    const vars: { args: TeamUpdateInput } = {
      args: {
        teamId: this.teamId!,
        vocabulary: vocabularyInput?.length ? vocabularyInput : []
      }
    };

    const [, err] = await this.store.apiService.updateTeam(vars);

    if (err) {
      this.handleSubmitReject(`Could not update the library '${this.team?.name}'. Duplicating common misspellings is not allowed. Please try again.`);
      return;
    }

    runInAction(() => {
      this.handleSubmitSuccess(`Library '${this.team?.name}' updated successfully`);
    });
  }

  private handleSubmitReject(msg: string) {
    this.isLoading = false;
    this.window.unlock();

    notifyError(this, msg);
  }

  private handleSubmitSuccess(msg: string) {
    this.isLoading = false;
    this.window.unlock();

    this.close();
    this.emit('teamDictionaryWindow:dictionaryUpdated');

    notifySuccess(this, msg);
  }

  @action
  reset() {
    this.teamDictionaryList.clear();
  }
}

export class TeamDictionaryItemState
  extends StoreNode {

  readonly dictionaryInputGroup: InputGroupState;
  @observable error: string | null = null;

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.correctWordInput = input(this, {
      name: 'correctWord',
      placeholder: 'Enter word or name, e.g. “CLIPr”',
    });

    this.misspelledWordsInput = input(this, {
      name: 'misspelledWords',
      isRequired: () => {
        return !this.correctWordInput.isEmpty
      },
      placeholder: 'e.g., “clipper”',
      error: (input) => {
        if (input.value) {
          return /^[a-zA-Z.-]+$/.test(input.value) ? 
          null : 'Only alphabetic characters and “-“ or “.“ are allowed'
        }

        if (input.isEmpty && !this.correctWordInput.isEmpty) 
          return 'Required field'
      }
    });

    this.dictionaryInputGroup = inputGroup(this, {
      name: 'dictionaryInputGroup',
      inputs: [
        this.correctWordInput,
        this.misspelledWordsInput
      ],
      disabled: (input: any) => {
        return (
          input.hasLoadingInputs ||
          input.isSubmitting
        )
      }
    });
  }

  @observable readonly correctWordInput: InputState;
  @observable readonly misspelledWordsInput: InputState;

  @action
  setError(value: string | null) {
    this.error = value;
  }
}

