import { StoreNode } from '../../store';
import { RouteContext } from '../../routes/routeContext';
import { AsyncResult } from '../../core';
import { Permissions } from '../../services/auth/authFlowSchema';
import { IRouteInjector } from '../../routes/routeSchema';
import { UploadWidgetRouteParams } from './uploadWidgetSchema';

export class UploadWidgetInjector
  extends StoreNode
  implements IRouteInjector {

  async fetchPermissions(routeContext: RouteContext<UploadWidgetRouteParams>): AsyncResult<Permissions, any> {
    const { UploadPermissionsSource } = await import('../../entities/uploadPermissionsSource');

    const teamId: string | null = routeContext.searchParams.get('teamId') ?? null;
    const source = new UploadPermissionsSource(this.store, {
      teamId
    });

    return source.fetchPermissions();
  }
}