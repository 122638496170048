import { computed, makeObservable } from 'mobx';
import max from 'lodash/max';
import { Track, ClipType } from '@clipr/lib';
import { StoreNode } from '../store';
import { JobModel } from './job';
import { MomentModel } from './moment';
import { SpeakerModel } from './speaker';
import { Store } from '../store/store';
import { TrackLane } from './trackLane';
import { IJobDependency } from './jobSchema';

export type TrackProps = Track;

export class TrackModel
  extends StoreNode
  implements IJobDependency {

  constructor(props: TrackProps, store: Store) {
    super(store);
    makeObservable(this);

    Object.assign(this, props);
  }

  readonly nodeType: 'Track' = 'Track';

  readonly id!: string;
  readonly jobId!: string;
  readonly type!: ClipType;
  readonly momentType!: string;
  readonly name!: string;
  readonly speakerId!: string;
  readonly visibleToConsumer!: boolean;
  readonly languageCode!: string;

  @computed
  get displayName(): string {
    if (this.type === 'Transcript')
      return this.store.maybeGetSpeaker(this.speakerId)?.name ?? this.name;

    return this.name
  }

  @computed
  get trackKind(): string {
    if (this.type === 'Transcript')
      return this.store.maybeGetSpeaker(this.speakerId)?.name ?? this.name;

    if (this.type === 'Moment')
      return this.momentType;

    return this.type;
  }

  @computed
  get job(): JobModel | null {
    return this.store.getJob(this.jobId);
  }

  @computed
  get lanes(): TrackLane[] {
    return computeTrackLanes(this.store, this.moments);
  }

  @computed
  get moments(): MomentModel[] {
    return this.job?.moments.filter(moment => moment.trackId === this.id) || [];
  }

  @computed
  get speaker(): SpeakerModel | null {
    return this.store.speakerManager.maybeGetSpeaker(this.speakerId) || null;
  }

  @computed
  get isEmpty(): boolean {
    return this.moments.length === 0;
  }

  @computed
  get isTopic(): boolean {
    return this.type === 'Topic';
  }

  @computed
  get isSubtopic(): boolean {
    return this.type === 'SubTopic';
  }

  @computed
  get isTranscript(): boolean {
    return this.type === 'Transcript';
  }

  @computed
  get isGeneric(): boolean {
    return this.type === 'Moment';
  }

  @computed
  get isParagraph(): boolean {
    return this.type === 'Paragraph';
  }

  @computed
  get isChapter(): boolean {
    return this.type === 'Chapter';
  }

  @computed
  get isActive(): boolean {
    return !!this.visibleToConsumer;
  }

  @computed
  get hasTopicsWithChildSubtopics(): boolean {
    return this.moments.some(moment => moment.hasChildSubtopics);
  }

  @computed
  get hasUncontainedSubtopics(): boolean {
    return this.moments.some(moment => moment.isUncontainedSubtopic);
  }

  @computed
  get isDuplicable(): boolean {
    return !this.isTranscript;
  }
}

/**
 * @param moments Input list of moments, sorted in ascending order by startTime.
 */
export function computeTrackLanes(store: Store, moments: MomentModel[]): TrackLane[] {

  type LaneStub = {
    moments: MomentModel[];
    laneIndex: number;
    maxEndTime: number;
  }

  if (moments.length === 0)
    return [];

  let lanes: LaneStub[] = [];

  for (let mi = 0; mi < moments.length; mi++) {

    const moment = moments[mi];
    const prevMoment = moments[mi - 1];
    if (prevMoment?.startTime > moment.startTime) {
      console.error(`Error in 'computeTrackLanes': The input Moment list is not sorted in ascending order.`);
      return [];
    }

    let didFit = false;
    for (let li = 0; li < lanes.length; li++) {
      const lane = lanes[li];
      if (moment.startTime >= lane.maxEndTime) { // M1.endTime === M2.startTime is allowed
        lane.moments.push(moment);
        didFit = true;
        break;
      }
    }

    if (!didFit) {
      // we need a new lane
      const lane: LaneStub = {
        moments: [moment],
        laneIndex: lanes.length,
        get maxEndTime() {
          return max(this.moments.map(mom => mom.endTime))!;
        }
      }

      lanes.push(lane);
    }
  }

  return lanes.map(lane => new TrackLane(store, {
    laneIndex: lane.laneIndex,
    moments: lane.moments
  }));
}