import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { DropdownInput } from '../input';
import {
  TeamSettingsPageState,
  RoleProps,
} from '../../pages/teamSettingsPage/teamSettingsPageState';
import { Catalog } from '../layout/catalog';
import { LoadMask } from '../loader';
import { CatalogFooter } from '../layout/catalogFooter';
import { TeamsSVGIcon } from '../svg';

type Props = {
  model: TeamSettingsPageState;
};

export const TeamInvitesTable = observer(({ model }: Props) => {
  const { teamInviteCatalogSource } = model;

  const { sortField, roleFilter, items } = teamInviteCatalogSource;
  return (
    <Catalog
      className="team-invites-catalog catalog catalog-page"
      model={model.teamInviteCatalog}
      hasExternalLoading>
      <>
        <LoadMask visible={teamInviteCatalogSource.isLoading} />
        {(!items ||
          items?.length === 0) &&
          !teamInviteCatalogSource.isLoading && (
            <div className="empty-box" aria-hidden={model.isLoading}>
              <div className="catalog empty-catalog">
                <div className="icon">
                  <TeamsSVGIcon />
                </div>
                <h1 className="headline-1">
                  Invitations will appear here
                </h1>
              </div>
            </div>
          )}
        {items && items.length > 0 && (
          <>
            <table className="team-invites-core">
              <tbody>
                {items?.map((item, index) => {
                  if (!item) return null;

                  return (
                    <tr
                      className={`is-pending`}
                      key={index}>
                      <td
                        className={
                          'email-col ' + (sortField === 'email' && 'active')
                        }>
                        <span>{item?.email || '-'}</span>
                      </td>
                      <td
                        className={
                          'role-col ' + (roleFilter && 'active')
                        }>
                        <DropdownInput
                          className=""
                          showFeedback={false}
                          value={item.role || '-'}
                          items={model.roleItems}
                          persistentFeedback={false}
                          notDeselectable
                          onChange={(e: SyntheticEvent, value: RoleProps) =>
                            model.setInvitedMemberRole(item.teamInvitation, value)
                          }
                          disabled={item.role === 'Owner'}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {!teamInviteCatalogSource.isLoading &&
              <CatalogFooter model={model.teamInviteCatalog} />}
          </>
        )}
      </>
    </Catalog>
  );
});
