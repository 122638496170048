import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import Routes from '../../routes';
import { LibraryServiceBase, LibraryProps } from './libraryServiceBase';
import { IntegrationTabState } from '../../pages/integrations/integrationTabState';
import { LibraryCatalogSource } from '../../entities';
import { LibraryName } from './librarySchema';
import { AsyncResult } from '../../core';

const CLIENT_ID = process.env.REACT_APP_ZOOM_CLIENT_ID || '';
const ENABLED = process.env.REACT_APP_ENABLE_ZOOM?.toLocaleLowerCase() === 'true';

const params: LibraryProps = {
  libraryLabel: 'Zoom',
  librarySource: 'Zoom',
  routeView: 'zoom',
  enabled: ENABLED
}

export class ZoomService
  extends LibraryServiceBase {

  readonly nodeType: 'ZoomLibrary' = 'ZoomLibrary';

  constructor(store: Store) {
    super(store, params);
    makeObservable(this);
  }
  readonly libraryName = LibraryName.Zoom;

  @observable
  injectedModel: any = null;

  @computed
  get authUrl(): string {
    const url = new URL('https://zoom.us/oauth/authorize');

    url.searchParams.append('response_type', 'code');
    url.searchParams.append('client_id', CLIENT_ID);
    url.searchParams.append('redirect_uri', window.location.origin + Routes.oauthZoomCallback());

    return url.toString();
  }


  @action
  async connectLibrary(redirect?: boolean): AsyncResult<true> {
    const redirectUri = window.location.origin + Routes.oauthZoomCallback();
    const [, err] = await this.connect(redirectUri);

    if (!err && redirect) {
      const customRoute = localStorage.getItem('externalLibraryUploadAuth');

      if (customRoute) {
        localStorage.removeItem('externalLibraryUploadAuth');
        this.store.goTo(customRoute);
        return [true];
      }

      this.store.goTo(Routes.userDashboard({ view: 'zoom' }));
    }
    
    return [true];
  }

  @action
  async deleteLibrary(injectedModel?: IntegrationTabState | LibraryCatalogSource | null) {
    const [, err] = await this.disconnect();

    if (!err) {
      if (injectedModel) {
        injectedModel.reset();
        return;
      }

      this.store.userDashboardPage.zoomIntegrationTabState.reset();
    }
  }
}