import './chartWrapper.scss';

import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from '../../overlays';
import { LoadMask } from '../../loader'
import { InfoSVGIcon } from '../../svg'
import { Button } from '../../input'
import { ChartOverlayMessage, ChartOverlayMessageProps } from './chartOverlayMessage';

type Props = PropsWithChildren<{
  title?: string;
  tooltipContent?: string;
  tools?: React.ReactNode;
  showLoadMask?: boolean;
  overlayMessage?: ChartOverlayMessageProps;
}>;

export const ChartWrapper = observer((props: Props) => {
  const { 
    title,
    tooltipContent,
    tools,
    showLoadMask,
    overlayMessage,
    children
  } = props;

  return (
    <div className="chart-wrapper">
      <div className="chart-header">
        {title && 
        <div className="chart-title flex-left">
          {title}
          
          {tooltipContent && 
          <Tooltip
            content={tooltipContent}
            className="popup-tip"
            trigger="click">
            <Button
              className="info-button"
              icon={<InfoSVGIcon />} />
          </Tooltip>}
        </div>}
        <div className="chart-tools flex-right">
          {tools}
        </div>
      </div>
      <div className="chart-body">
        {children}

        <LoadMask visible={!!showLoadMask} spinner="dots3" />

        {overlayMessage && <ChartOverlayMessage {...overlayMessage} />}
      </div>
    </div>
  );
});
