import { action, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { TextEditorState } from '../textEditor/textEditorState';

export class EditorWindowState
  extends StoreNode {

  readonly nodeType = 'EditorWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store, { closeOnOutsideClick: false });
  readonly editor = new TextEditorState(this.store);
  
  @observable isLoading = false;
  @observable data: string | null = null;
  @observable fileName: string | null = null;

  // This is a stupid workaround and has to be removed
  @observable mode: 'notes' | 'report' | null = null;

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close();
        break;
    }
  }

  @action
  open({ data, fileName, mode }: { data?: string, fileName?: string, mode?: 'notes' | 'report' }) {
    this.data = data ?? null;
    this.fileName = fileName ?? null;
    this.mode = mode ?? null;
    this.dispatch('Overlays', 'openWindow', { name: 'EditorWindow' });
    this.emit('open');
    this.window.open();
  }

  @action
  onSave = async (content?: string) => {
    this.emit(this.mode === 'notes' ? 
      'editor:save:notes' :
      'editor:save:report', content);
  }

  @action
  close() {
    this.reset();
    this.dispatch('Overlays', 'closeWindow');
    this.window.close();
    this.emit('close');
  }

  @action
  private reset() {
    this.isLoading = false;
    this.mode = null;
    this.fileName = null;
    this.data = null;
  }
}