import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../store/store';
import { StoreNode } from '../store/storeNode';
import { assert, Maybe } from '../core';
import { User, UserProps } from './user';
import { UserManagerMocker } from './_mock/userManagerMocker';

export interface UserManager {
  _mocker: UserManagerMocker
}

/**
 * Manages User and UserList entities for a Store.
 */
// eslint-disable-next-line  no-redeclare
export class UserManager
  extends StoreNode {

  readonly nodeType: 'UserManager' = 'UserManager';

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  readonly userLookup = observable.map<string, User>();
  @computed
  get users(): User[] {
    return [...this.userLookup.values()];
  }

  // #region User methods
  // -------

  private assertHasUser(id: string) {
    assert(this.userLookup.has(id),
      `Store does not contain a User with id '${id}'.`);
  }

  hasUser(id?: Maybe<string>): boolean {
    if (!id)
      return false;
    return this.userLookup.has(id);
  }

  strictGetUser(id: string): User {
    this.assertHasUser(id);
    return this.userLookup.get(id)!;
  }
  getUser(id?: Maybe<string>): User | null {
    if (!id)
      return null;
    return this.userLookup.get(id) ?? null;
  }

  @action
  insertUser(props: Partial<UserProps>) {
    const user = new User(this.store, props);
    this.userLookup.set(user.id, user);
    return user;
  }

  @action
  removeUser(id: string) {
    const user = this.strictGetUser(id);
    user.dispose();
    this.userLookup.delete(id);
    return user;
  }

  // #endregion
}