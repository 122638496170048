import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { HTMLDivProps } from '../../core';

type Props = HTMLDivProps<{

}>;

export const Grid = observer(({
  className,
  style,
  ...props
}: Props) => {
  className = classNames(className, 'grid');

  return (
    <div className={className} style={style}>
      <ul className="grid-list">
        {props.children}
      </ul>
    </div>
  );
});