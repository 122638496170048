import * as React from "react";
import classNames from 'classnames';

export const HotBloomSVGIcon = ({className, ...props}: React.SVGProps<SVGSVGElement>) =>
  <svg
    viewBox="0 0 251 239"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames("hot-bloom-svg-icon", "svg-icon", className)}
    {...props}>
      <style>
        {
          "\n    .main-bloom {\n      transform: scale(0);\n      transform-origin: center center;\n      animation: pulse 2s 1 alternate;\n    }\n\n    .bg-bloom {\n      transform: scale(0);\n      transform-origin: center center;\n      animation: pulse2 1.6s 1 alternate-reverse;\n      opacity: 0;\n    }\n\n    @keyframes pulse {\n      60% {\n        transform: scale(1);\n        opacity: 1;\n      }\n\n      100% {\n        transform: scale(0);\n        opacity: 0;\n      }\n    }\n\n    @keyframes pulse2 {\n      60% {\n        transform: scale(1);\n        opacity: 1;\n      }\n\n      80% {\n        opacity: 0;\n      }\n\n      100% {\n        transform: scale(0);\n        opacity: 0;\n      }\n    }\n  "
        }
      </style>
      <g className="main-bloom">
        <path
          d="M184.5 119C184.5 151.861 157.861 178.5 125 178.5C92.1391 178.5 65.5 151.861 65.5 119C65.5 86.1391 92.1391 59.5 125 59.5C157.861 59.5 184.5 86.1391 184.5 119Z"
          fill="#FFCB4C"
          fillOpacity={0.1}
          stroke="white"
        />
        <path
          d="M125 169C152.614 169 175 146.614 175 119C175 91.3858 152.614 69 125 69C97.3858 69 75 91.3858 75 119C75 146.614 97.3858 169 125 169Z"
          fill="#FFCB4C"
          fillOpacity={0.15}
        />
        <path
          d="M125 161C148.196 161 167 142.196 167 119C167 95.804 148.196 77 125 77C101.804 77 83 95.804 83 119C83 142.196 101.804 161 125 161Z"
          fill="#FFCB4C"
          fillOpacity={0.2}
        />
        <path
          d="M125 152C143.225 152 158 137.225 158 119C158 100.775 143.225 86 125 86C106.775 86 92 100.775 92 119C92 137.225 106.775 152 125 152Z"
          fill="#FFCB4C"
          fillOpacity={0.25}
        />
        <g filter="url(#filter0_d_11597:86094)">
          <path
            d="M151.416 120.083C151.416 116.818 150.835 113.688 149.77 110.792C149.041 119.325 144.492 123.707 139.739 121.666C135.287 119.754 138.287 112.298 138.509 108.74C138.883 102.709 138.49 95.8057 127.535 90.0662C132.087 98.7745 128.062 104.185 123.841 104.514C119.158 104.88 114.868 100.489 116.452 93.3642C111.325 97.1421 111.176 103.501 112.758 107.614C114.408 111.902 112.691 115.464 108.668 115.86C104.171 116.304 101.673 111.045 103.977 102.666C99.9897 107.361 97.583 113.441 97.583 120.083C97.583 134.949 109.634 147 124.5 147C139.366 147 151.416 134.949 151.416 120.083Z"
            fill="#F4900C"
          />
        </g>
        <path
          d="M140.958 127.998C141.192 132.881 136.903 134.795 134.594 133.871C131.26 132.536 132.155 130.243 131.296 125.493C130.438 120.743 127.14 117.445 122.259 115.993C125.821 126.021 120.284 129.716 117.382 130.376C114.418 131.051 111.44 130.375 111.099 124.032C107.641 127.474 105.501 132.237 105.501 137.5C105.501 138.082 105.537 138.656 105.588 139.226C110.449 144.029 117.126 147 124.501 147C131.876 147 138.553 144.029 143.414 139.226C143.464 138.656 143.501 138.082 143.501 137.5C143.501 134.037 142.576 130.793 140.958 127.998Z"
          fill="#FFCC4D"
        />
        <rect
          x={124.762}
          y={45}
          width={3.20705}
          height={12.8282}
          rx={1.1436}
          fill="#F4600C"
        />
        <rect
          x={124.762}
          y={77.0706}
          width={3.20705}
          height={3.20705}
          rx={1.1436}
          fill="#F4600C"
        />
        <rect
          x={124.762}
          y={70.6562}
          width={3.20705}
          height={3.20705}
          rx={1.1436}
          fill="#F4600C"
        />
        <rect
          x={124.762}
          y={64.2424}
          width={3.20705}
          height={3.20705}
          rx={1.1436}
          fill="#F4600C"
        />
        <rect
          x={127.969}
          y={192.524}
          width={3.20705}
          height={12.8282}
          rx={1.1436}
          transform="rotate(180 127.969 192.524)"
          fill="#F4600C"
        />
        <rect
          x={127.969}
          y={160.454}
          width={3.20705}
          height={3.20705}
          rx={1.1436}
          transform="rotate(180 127.969 160.454)"
          fill="#F4600C"
        />
        <rect
          x={127.969}
          y={166.868}
          width={3.20705}
          height={3.20705}
          rx={1.1436}
          transform="rotate(180 127.969 166.868)"
          fill="#F4600C"
        />
        <rect
          x={127.969}
          y={173.282}
          width={3.20705}
          height={3.20705}
          rx={1.1436}
          transform="rotate(180 127.969 173.282)"
          fill="#F4600C"
        />
        <rect
          x={198.524}
          y={118.762}
          width={3.20705}
          height={12.8282}
          rx={1.1436}
          transform="rotate(90 198.524 118.762)"
          fill="#F4600C"
        />
        <rect
          x={166.454}
          y={118.762}
          width={3.20705}
          height={3.20705}
          rx={1.1436}
          transform="rotate(90 166.454 118.762)"
          fill="#F4600C"
        />
        <rect
          x={172.868}
          y={118.762}
          width={3.20705}
          height={3.20705}
          rx={1.1436}
          transform="rotate(90 172.868 118.762)"
          fill="#F4600C"
        />
        <rect
          x={179.282}
          y={118.762}
          width={3.20705}
          height={3.20705}
          rx={1.1436}
          transform="rotate(90 179.282 118.762)"
          fill="#F4600C"
        />
        <rect
          x={51}
          y={121.969}
          width={3.20705}
          height={12.8282}
          rx={1.1436}
          transform="rotate(-90 51 121.969)"
          fill="#F4600C"
        />
        <rect
          x={83.0706}
          y={121.969}
          width={3.20705}
          height={3.20705}
          rx={1.1436}
          transform="rotate(-90 83.0706 121.969)"
          fill="#F4600C"
        />
        <rect
          x={76.6562}
          y={121.969}
          width={3.20705}
          height={3.20705}
          rx={1.1436}
          transform="rotate(-90 76.6562 121.969)"
          fill="#F4600C"
        />
        <rect
          x={70.2419}
          y={121.969}
          width={3.20705}
          height={3.20705}
          rx={1.1436}
          transform="rotate(-90 70.2419 121.969)"
          fill="#F4600C"
        />
        <rect
          x={176.921}
          y={66.6038}
          width={3.20705}
          height={12.8282}
          rx={1.1436}
          transform="rotate(45 176.921 66.6038)"
          fill="#F4600C"
        />
        <rect
          x={154.244}
          y={89.281}
          width={3.20705}
          height={3.20705}
          rx={1.1436}
          transform="rotate(45 154.244 89.281)"
          fill="#F4600C"
        />
        <rect
          x={74.8713}
          y={173.188}
          width={3.20705}
          height={12.8282}
          rx={1.1436}
          transform="rotate(-135 74.8713 173.188)"
          fill="#F4600C"
        />
        <rect
          x={97.5481}
          y={150.511}
          width={3.20705}
          height={3.20705}
          rx={1.1436}
          transform="rotate(-135 97.5481 150.511)"
          fill="#F4600C"
        />
        <rect
          x={176.921}
          y={170.92}
          width={3.20705}
          height={12.8282}
          rx={1.1436}
          transform="rotate(135 176.921 170.92)"
          fill="#F4600C"
        />
        <rect
          x={154.244}
          y={148.243}
          width={3.20705}
          height={3.20705}
          rx={1.1436}
          transform="rotate(135 154.244 148.243)"
          fill="#F4600C"
        />
        <rect
          x={70.3364}
          y={68.8728}
          width={3.20705}
          height={12.8282}
          rx={1.1436}
          transform="rotate(-45 70.3364 68.8728)"
          fill="#F4600C"
        />
        <rect
          x={93.0137}
          y={91.5498}
          width={3.20705}
          height={3.20705}
          rx={1.1436}
          transform="rotate(-45 93.0137 91.5498)"
          fill="#F4600C"
        />
        <rect
          x={144.872}
          y={68.644}
          width={2.35551}
          height={18.8441}
          rx={1.1436}
          transform="rotate(21.8729 144.872 68.644)"
          fill="white"
        />
        <rect
          x={148.902}
          y={58.6057}
          width={2.35551}
          height={6.41411}
          rx={1.1436}
          transform="rotate(21.8729 148.902 58.6057)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={106.691}
          y={170.075}
          width={2.35551}
          height={18.8441}
          rx={1.1436}
          transform="rotate(-158.127 106.691 170.075)"
          fill="white"
        />
        <rect
          width={2.35551}
          height={6.3579}
          rx={1.1436}
          transform="matrix(-0.928012 -0.372549 -0.372549 0.928012 105.765 172.387)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={174.966}
          y={139.105}
          width={2.35551}
          height={18.8441}
          rx={1.1436}
          transform="rotate(111.873 174.966 139.105)"
          fill="white"
        />
        <rect
          width={2.35551}
          height={6.57451}
          rx={1.1436}
          transform="matrix(-0.37255 0.928012 0.928012 0.37255 178.085 140.357)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={73.5342}
          y={100.923}
          width={2.35551}
          height={18.8441}
          rx={1.1436}
          transform="rotate(-68.1271 73.5342 100.923)"
          fill="white"
        />
        <rect
          width={2.35551}
          height={6.40804}
          rx={1.1436}
          transform="matrix(0.372549 -0.928012 -0.928012 -0.372549 69.9326 99.4768)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={174.512}
          y={97.6423}
          width={2.35551}
          height={18.8441}
          rx={1.1436}
          transform="rotate(66.8729 174.512 97.6423)"
          fill="white"
        />
        <rect
          width={2.35551}
          height={6.56355}
          rx={1.1436}
          transform="matrix(0.392772 0.919636 0.919636 -0.392772 178.09 96.1147)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={75.7917}
          y={142.367}
          width={2.35551}
          height={18.8441}
          rx={1.1436}
          transform="rotate(-113.127 75.7917 142.367)"
          fill="white"
        />
        <rect
          width={2.35551}
          height={8.45622}
          rx={1.1436}
          transform="matrix(-0.392772 -0.919636 -0.919636 0.392772 72.3364 143.843)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={145.968}
          y={168.744}
          width={2.35551}
          height={18.8441}
          rx={1.1436}
          transform="rotate(156.873 145.968 168.744)"
          fill="white"
        />
        <rect
          width={2.35551}
          height={6.41411}
          rx={1.1436}
          transform="matrix(-0.919636 0.392771 0.392771 0.919636 147.01 171.185)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={101.244}
          y={70.0237}
          width={2.35551}
          height={18.8441}
          rx={1.1436}
          transform="rotate(-23.1271 101.244 70.0237)"
          fill="white"
        />
        <rect
          width={2.35551}
          height={6.41411}
          rx={1.1436}
          transform="matrix(0.919636 -0.392772 -0.392772 -0.919636 99.4785 65.8867)"
          fill="white"
          fillOpacity={0.7}
        />
      </g>
      <g className="bg-bloom">
        <g clipPath="url(#clip0_11597:86094)">
          <path
            d="M157.579 12.7863C157.579 11.771 157.386 10.7975 157.034 9.89696C156.792 12.5504 155.287 13.9133 153.713 13.2786C152.239 12.6838 153.232 10.3652 153.306 9.25883C153.429 7.38334 153.3 5.23655 149.673 3.45166C151.18 6.15977 149.847 7.84224 148.45 7.94466C146.9 8.0584 145.48 6.69302 146.004 4.47729C144.307 5.65212 144.257 7.62953 144.781 8.90874C145.327 10.2421 144.759 11.35 143.427 11.4731C141.939 11.6109 141.111 9.97574 141.874 7.37005C140.554 8.82996 139.758 10.7207 139.758 12.7863C139.758 17.4093 143.747 21.1568 148.668 21.1568C153.589 21.1568 157.579 17.4093 157.579 12.7863Z"
            fill="#F4900C"
          />
          <path
            d="M154.116 15.2477C154.194 16.7662 152.774 17.3615 152.01 17.0739C150.906 16.6588 151.202 15.9459 150.918 14.4687C150.634 12.9916 149.542 11.9659 147.926 11.5144C149.105 14.6327 147.272 15.7819 146.312 15.9872C145.33 16.197 144.345 15.9867 144.232 14.0142C143.087 15.0847 142.378 16.5658 142.378 18.2024C142.378 18.3836 142.39 18.5619 142.407 18.7391C144.016 20.233 146.227 21.1567 148.668 21.1567C151.11 21.1567 153.32 20.233 154.929 18.7391C154.946 18.5619 154.958 18.3836 154.958 18.2024C154.958 17.1256 154.652 16.1167 154.116 15.2477Z"
            fill="#FFCC4D"
          />
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip1_11597:86094)">
            <path
              d="M130.933 229.366C132.421 230.225 134.001 230.784 135.601 231.06C131.904 228.483 131.103 225.256 133.284 223.627C135.328 222.101 137.936 225.431 139.5 226.468C142.15 228.225 145.4 229.863 150.899 226.38C145.732 226.163 144.325 222.905 145.286 220.894C146.352 218.663 149.482 217.864 152.313 220.46C151.94 217.129 149.081 215.388 146.789 215.027C144.401 214.65 143.229 212.93 144.107 210.992C145.088 208.826 148.143 209.071 151.356 212.326C150.265 209.273 148.127 206.577 145.099 204.829C138.324 200.917 129.66 203.238 125.747 210.014C121.835 216.79 124.157 225.454 130.933 229.366Z"
              fill="#F4900C"
            />
            <path
              d="M130.077 222.516C127.79 221.338 128.046 218.879 129.075 218.07C130.561 216.902 131.371 217.913 133.761 218.772C136.152 219.63 138.524 218.995 140.47 217.152C134.962 216.137 134.735 212.641 135.197 211.144C135.67 209.616 136.762 208.436 139.743 209.95C139.084 207.468 137.476 205.239 135.077 203.854C134.812 203.701 134.541 203.567 134.268 203.44C130.799 204.391 127.688 206.653 125.747 210.015C123.806 213.376 123.403 217.201 124.313 220.681C124.56 220.854 124.812 221.022 125.077 221.175C126.656 222.086 128.378 222.518 130.077 222.516Z"
              fill="#FFCC4D"
            />
          </g>
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip2_11597:86094)">
            <path
              d="M56.8291 127.904C55.5394 127.159 54.1704 126.675 52.7834 126.435C55.9879 128.669 56.6817 131.465 54.7914 132.878C53.0204 134.2 50.7594 131.314 49.4046 130.415C47.1074 128.892 44.2908 127.473 39.525 130.492C44.0032 130.68 45.2225 133.503 44.3899 135.246C43.4662 137.179 40.7535 137.873 38.3 135.622C38.6231 138.509 41.101 140.018 43.0867 140.331C45.1568 140.657 46.1726 142.148 45.4114 143.827C44.561 145.705 41.914 145.493 39.1294 142.672C40.0747 145.317 41.9276 147.655 44.5514 149.169C50.4238 152.56 57.9327 150.548 61.3231 144.675C64.7136 138.803 62.7016 131.294 56.8291 127.904Z"
              fill="#F4900C"
            />
            <path
              d="M57.5707 133.84C59.5531 134.861 59.331 136.992 58.4392 137.693C57.1515 138.706 56.4498 137.83 54.3777 137.086C52.3056 136.341 50.2506 136.892 48.5637 138.489C53.3373 139.369 53.5343 142.399 53.1332 143.696C52.7236 145.02 51.7773 146.043 49.1941 144.731C49.7652 146.882 51.1583 148.814 53.2374 150.014C53.4675 150.147 53.7023 150.263 53.939 150.373C56.9452 149.549 59.6414 147.588 61.3235 144.675C63.0055 141.762 63.355 138.447 62.5659 135.431C62.3523 135.281 62.1342 135.136 61.904 135.003C60.5361 134.213 59.0437 133.838 57.5707 133.84Z"
              fill="#FFCC4D"
            />
          </g>
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip3_11597:86094)">
            <path
              d="M163.11 116.539C161.821 117.284 160.717 118.228 159.816 119.309C163.352 117.651 166.121 118.448 166.399 120.791C166.659 122.986 163.029 123.501 161.573 124.225C159.106 125.453 156.468 127.183 156.699 132.819C159.101 129.035 162.156 129.391 163.249 130.983C164.462 132.75 163.706 135.446 160.53 136.445C163.192 137.609 165.737 136.217 167.002 134.654C168.319 133.025 170.118 132.89 171.192 134.389C172.392 136.065 170.885 138.251 167.05 139.252C169.814 139.756 172.764 139.32 175.388 137.805C181.261 134.415 183.272 126.906 179.882 121.033C176.492 115.161 168.983 113.149 163.11 116.539Z"
              fill="#F4900C"
            />
            <path
              d="M168.622 118.866C170.498 117.659 172.232 118.917 172.393 120.04C172.627 121.662 171.517 121.831 169.836 123.253C168.156 124.676 167.605 126.731 168.145 128.99C171.293 125.296 174.016 126.64 174.939 127.636C175.881 128.653 176.293 129.984 173.865 131.565C176.014 132.146 178.383 131.905 180.462 130.705C180.693 130.572 180.911 130.427 181.124 130.277C181.913 127.261 181.564 123.946 179.882 121.033C178.2 118.12 175.504 116.159 172.497 115.335C172.261 115.445 172.026 115.561 171.796 115.694C170.428 116.484 169.357 117.589 168.622 118.866Z"
              fill="#FFCC4D"
            />
          </g>
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip4_11597:86094)">
            <path
              d="M67.474 30.9141C68.8329 30.0624 69.9842 29.0039 70.9116 27.8049C67.1701 29.7277 64.1592 28.9776 63.7687 26.4668C63.4033 24.1146 67.2894 23.4184 68.8281 22.5825C71.4358 21.1647 74.2068 19.2004 73.7378 13.1432C71.3007 17.3097 67.9988 17.0459 66.7605 15.3748C65.3864 13.5208 66.095 10.5901 69.4737 9.39035C66.5638 8.24195 63.8783 9.83883 62.5787 11.5703C61.2245 13.3756 59.2939 13.5906 58.0796 12.0192C56.722 10.2632 58.2588 7.85152 62.3474 6.62453C59.3533 6.18979 56.1948 6.77419 53.4301 8.50696C47.2425 12.3851 45.3703 20.5448 49.2485 26.7324C53.1267 32.9201 61.2863 34.7922 67.474 30.9141Z"
              fill="#F4900C"
            />
            <path
              d="M61.4509 28.6258C59.4796 29.9972 57.5639 28.7112 57.3465 27.5091C57.0322 25.773 58.2198 25.5472 59.973 23.9509C61.7263 22.3545 62.2386 20.1214 61.5695 17.7108C58.3252 21.8095 55.3426 20.4689 54.3107 19.4332C53.2567 18.3754 52.7611 16.9594 55.3124 15.163C52.9776 14.6217 50.4367 14.9731 48.2461 16.3461C48.0036 16.4981 47.7745 16.6628 47.5505 16.8326C46.8191 20.109 47.3246 23.6631 49.2486 26.7328C51.1726 29.8026 54.1508 31.8069 57.4184 32.5769C57.6688 32.4493 57.9169 32.3149 58.1594 32.1629C59.6007 31.2596 60.7099 30.0283 61.4509 28.6258Z"
              fill="#FFCC4D"
            />
          </g>
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip5_11597:86094)">
            <path
              d="M136.846 52.906C135.503 53.673 134.352 54.6474 133.411 55.766C137.092 54.061 139.963 54.9025 140.24 57.337C140.5 59.6177 136.728 60.1352 135.213 60.8798C132.644 62.143 129.897 63.9263 130.11 69.7808C132.622 65.8626 135.793 66.247 136.92 67.9059C138.171 69.7464 137.373 72.5421 134.07 73.5647C136.829 74.786 139.479 73.3533 140.799 71.7363C142.175 70.0504 144.044 69.9194 145.152 71.4813C146.391 73.2267 144.815 75.4897 140.827 76.5106C143.695 77.0476 146.761 76.6091 149.493 75.0487C155.608 71.5562 157.733 63.7684 154.241 57.6537C150.748 51.5391 142.96 49.4135 136.846 52.906Z"
              fill="#F4900C"
            />
            <path
              d="M142.559 55.3481C144.512 54.1046 146.307 55.4191 146.469 56.5859C146.704 58.271 145.55 58.4417 143.798 59.9106C142.046 61.3795 141.464 63.5109 142.014 65.8598C145.301 62.0387 148.122 63.448 149.076 64.4866C150.049 65.5473 150.471 66.9312 147.942 68.5613C150.17 69.175 152.632 68.9366 154.797 67.7001C155.037 67.5633 155.264 67.4136 155.486 67.2589C156.32 64.1309 155.973 60.6868 154.241 57.6532C152.508 54.6197 149.718 52.5711 146.6 51.7004C146.353 51.8134 146.109 51.9331 145.87 52.07C144.445 52.8835 143.328 54.026 142.559 55.3481Z"
              fill="#FFCC4D"
            />
          </g>
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip6_11597:86094)">
            <path
              d="M25.5117 54.1864C24.7422 53.4169 23.8674 52.8161 22.9337 52.3847C24.7732 54.5675 24.734 56.6727 23.1326 57.312C21.6324 57.9106 20.5822 55.446 19.7959 54.5552C18.4624 53.0456 16.7428 51.511 12.8078 52.7403C15.9333 53.72 16.2598 55.9438 15.3425 57.0164C14.3248 58.2065 12.279 58.1826 10.9728 56.13C10.6548 58.2289 12.1185 59.7627 13.4609 60.3595C14.8604 60.9812 15.2955 62.2254 14.4405 63.267C13.4851 64.4314 11.6568 63.7809 10.2249 61.263C10.3917 63.3092 11.2575 65.3095 12.8231 66.8751C16.327 70.379 22.0078 70.379 25.5117 66.8751C29.0156 63.3712 29.0156 57.6904 25.5117 54.1864Z"
              fill="#F4900C"
            />
            <path
              d="M24.9122 58.5172C26.1184 59.6129 25.5586 61.075 24.7966 61.4012C23.696 61.8726 23.3665 61.1213 22.0446 60.204C20.7228 59.2867 19.168 59.2867 17.6752 60.095C20.8784 61.6188 20.4443 63.7949 19.9159 64.6346C19.3763 65.4922 18.5149 66.0348 16.9397 64.62C16.9359 66.2464 17.554 67.8735 18.7945 69.114C18.9318 69.2514 19.0755 69.3779 19.2218 69.5003C21.4997 69.4869 23.7736 68.6132 25.512 66.8749C27.2503 65.1365 28.124 62.8626 28.1374 60.5847C28.015 60.4384 27.8885 60.2947 27.7511 60.1573C26.935 59.3412 25.9523 58.7944 24.9122 58.5172Z"
              fill="#FFCC4D"
            />
          </g>
        </g>
        <g clipPath="url(#clip7_11597:86094)">
          <path
            d="M68.1808 222.823C68.9098 223.552 69.7386 224.121 70.6232 224.53C68.8805 222.462 68.9176 220.467 70.4347 219.862C71.856 219.294 72.8509 221.629 73.5958 222.473C74.8591 223.903 76.4883 225.357 80.2161 224.193C77.2551 223.264 76.9458 221.158 77.8148 220.142C78.7789 219.014 80.7171 219.037 81.9545 220.981C82.2558 218.993 80.8691 217.54 79.5974 216.974C78.2716 216.385 77.8593 215.207 78.6693 214.22C79.5744 213.117 81.3065 213.733 82.6631 216.118C82.505 214.18 81.6848 212.285 80.2016 210.802C76.8821 207.482 71.5003 207.482 68.1808 210.802C64.8613 214.121 64.8613 219.503 68.1808 222.823Z"
            fill="#F4900C"
          />
          <path
            d="M68.7486 218.72C67.6059 217.682 68.1362 216.296 68.8582 215.987C69.9008 215.541 70.213 216.253 71.4653 217.122C72.7176 217.991 74.1905 217.991 75.6047 217.225C72.5701 215.781 72.9813 213.72 73.4819 212.924C73.9932 212.112 74.8092 211.598 76.3015 212.938C76.3051 211.397 75.7196 209.856 74.5444 208.681C74.4143 208.55 74.2781 208.431 74.1395 208.315C71.9815 208.327 69.8273 209.155 68.1804 210.802C66.5336 212.449 65.7059 214.603 65.6932 216.761C65.8091 216.9 65.929 217.036 66.0591 217.166C66.8323 217.939 67.7632 218.457 68.7486 218.72Z"
            fill="#FFCC4D"
          />
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip8_11597:86094)">
            <path
              d="M208.418 66.7543C209.708 66.0096 210.812 65.0662 211.712 63.9848C208.176 65.6431 205.407 64.8457 205.129 62.5026C204.869 60.3076 208.499 59.7923 209.955 59.0685C212.422 57.8406 215.06 56.1111 214.829 50.4745C212.427 54.2588 209.372 53.9028 208.279 52.3104C207.066 50.5437 207.822 47.8479 210.998 46.8484C208.336 45.6848 205.791 47.0762 204.526 48.6392C203.209 50.2688 201.41 50.4033 200.336 48.9043C199.136 47.2291 200.643 45.0429 204.478 44.0419C201.715 43.5377 198.764 43.9737 196.14 45.4885C190.268 48.879 188.256 56.3878 191.646 62.2603C195.037 68.1327 202.545 70.1447 208.418 66.7543Z"
              fill="#F4900C"
            />
            <path
              d="M202.906 64.4282C201.03 65.6344 199.296 64.3766 199.135 63.2538C198.901 61.6321 200.011 61.4626 201.692 60.0403C203.372 58.618 203.923 56.5629 203.383 54.3035C200.235 57.9977 197.512 56.6533 196.589 55.6574C195.647 54.6404 195.235 53.3097 197.663 51.7286C195.514 51.1476 193.145 51.3882 191.066 52.5886C190.835 52.7215 190.617 52.8666 190.404 53.0166C189.615 56.0324 189.964 59.3474 191.646 62.2608C193.328 65.1742 196.024 67.1343 199.031 67.9589C199.267 67.8489 199.502 67.7326 199.732 67.5997C201.1 66.8099 202.171 65.7047 202.906 64.4282Z"
              fill="#FFCC4D"
            />
          </g>
        </g>
        <g clipPath="url(#clip9_11597:86094)">
          <path
            d="M74.4874 72.6229C74.0017 71.9848 73.4225 71.4594 72.7835 71.0519C73.9103 72.8279 73.6732 74.3612 72.4406 74.6693C71.2859 74.9577 70.7633 73.0542 70.2774 72.3258C69.4533 71.0915 68.3497 69.8007 65.3548 70.3084C67.5399 71.3333 67.558 72.9894 66.7819 73.6817C65.9209 74.4498 64.4295 74.2297 63.6792 72.6016C63.2391 74.1026 64.1558 75.3675 65.0768 75.9362C66.0371 76.5289 66.2315 77.4804 65.5041 78.1563C64.6911 78.9118 63.4207 78.2557 62.6246 76.2754C62.5437 77.7859 62.9777 79.3323 63.9657 80.6304C66.1769 83.536 70.3247 84.0988 73.2303 81.8875C76.1358 79.6763 76.6986 75.5284 74.4874 72.6229Z"
            fill="#F4900C"
          />
          <path
            d="M73.6206 75.7255C74.3927 76.645 73.8391 77.6572 73.2504 77.8198C72.4001 78.055 72.2339 77.4738 71.3596 76.6731C70.4854 75.8723 69.3502 75.7183 68.1801 76.1606C70.3679 77.5905 69.8354 79.1364 69.3664 79.6972C68.8874 80.2699 68.2048 80.5807 67.1948 79.3917C67.0309 80.5788 67.3209 81.8281 68.1038 82.8567C68.1905 82.9706 68.2829 83.0772 68.3775 83.1811C70.0421 83.397 71.789 82.9843 73.2304 81.8873C74.6719 80.7903 75.5351 79.2166 75.7706 77.5546C75.6957 77.4357 75.6176 77.3182 75.5309 77.2043C75.0158 76.5276 74.3525 76.031 73.6206 75.7255Z"
            fill="#FFCC4D"
          />
        </g>
        <g clipPath="url(#clip10_11597:86094)">
          <path
            d="M13.5971 162.831C13.0018 163.175 12.4923 163.61 12.0764 164.11C13.7088 163.344 14.9867 163.712 15.1149 164.794C15.2348 165.807 13.5596 166.044 12.8876 166.379C11.7488 166.945 10.5315 167.744 10.6382 170.345C11.7467 168.598 13.1568 168.763 13.6612 169.498C14.2209 170.313 13.8719 171.557 12.4062 172.019C13.6346 172.556 14.8096 171.913 15.3931 171.192C16.0011 170.44 16.8313 170.378 17.327 171.07C17.8812 171.843 17.1855 172.852 15.4153 173.314C16.6909 173.547 18.0527 173.345 19.2637 172.646C21.9741 171.081 22.9027 167.616 21.3379 164.905C19.773 162.195 16.3074 161.266 13.5971 162.831Z"
            fill="#F4900C"
          />
          <path
            d="M16.1412 163.905C17.0068 163.348 17.8073 163.929 17.8817 164.447C17.9894 165.196 17.4772 165.274 16.7015 165.93C15.9258 166.587 15.6717 167.535 15.9208 168.578C17.374 166.873 18.6306 167.493 19.0565 167.953C19.4914 168.422 19.6817 169.037 18.5611 169.766C19.5527 170.035 20.6463 169.923 21.6059 169.369C21.7121 169.308 21.8128 169.241 21.9114 169.172C22.2756 167.78 22.1143 166.25 21.3379 164.905C20.5616 163.561 19.3172 162.656 17.9297 162.275C17.8205 162.326 17.7121 162.38 17.6059 162.441C16.9746 162.806 16.4804 163.316 16.1412 163.905Z"
            fill="#FFCC4D"
          />
        </g>
        <g clipPath="url(#clip11_11597:86094)">
          <path
            d="M219.924 184.557C219.154 183.787 218.279 183.186 217.346 182.755C219.185 184.938 219.146 187.043 217.544 187.682C216.044 188.281 214.994 185.816 214.208 184.926C212.874 183.416 211.155 181.881 207.22 183.111C210.345 184.09 210.672 186.314 209.754 187.387C208.737 188.577 206.691 188.553 205.385 186.5C205.067 188.599 206.53 190.133 207.873 190.73C209.272 191.352 209.707 192.596 208.852 193.637C207.897 194.802 206.069 194.151 204.637 191.633C204.804 193.68 205.669 195.68 207.235 197.245C210.739 200.749 216.42 200.749 219.924 197.245C223.428 193.742 223.428 188.061 219.924 184.557Z"
            fill="#F4900C"
          />
          <path
            d="M219.324 188.888C220.53 189.983 219.97 191.445 219.208 191.772C218.108 192.243 217.778 191.492 216.456 190.574C215.135 189.657 213.58 189.657 212.087 190.465C215.29 191.989 214.856 194.165 214.328 195.005C213.788 195.863 212.927 196.405 211.352 194.99C211.348 196.617 211.966 198.244 213.206 199.484C213.344 199.622 213.487 199.748 213.634 199.871C215.912 199.857 218.185 198.984 219.924 197.245C221.662 195.507 222.536 193.233 222.549 190.955C222.427 190.809 222.3 190.665 222.163 190.528C221.347 189.712 220.364 189.165 219.324 188.888Z"
            fill="#FFCC4D"
          />
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip12_11597:86094)">
            <path
              d="M108.635 171.178C108.234 171.872 107.974 172.61 107.845 173.356C109.047 171.631 110.553 171.257 111.314 172.275C112.026 173.229 110.472 174.446 109.988 175.176C109.168 176.413 108.404 177.929 110.029 180.496C110.13 178.084 111.651 177.428 112.589 177.876C113.63 178.373 114.003 179.834 112.791 181.155C114.346 180.981 115.158 179.647 115.327 178.578C115.503 177.463 116.305 176.916 117.21 177.326C118.221 177.784 118.106 179.209 116.587 180.709C118.012 180.2 119.27 179.202 120.086 177.789C121.912 174.627 120.828 170.584 117.666 168.758C114.504 166.932 110.461 168.016 108.635 171.178Z"
              fill="#F4900C"
            />
            <path
              d="M111.832 170.779C112.382 169.711 113.529 169.831 113.907 170.311C114.452 171.004 113.98 171.382 113.58 172.498C113.179 173.614 113.475 174.72 114.335 175.629C114.809 173.058 116.441 172.952 117.139 173.168C117.852 173.389 118.403 173.898 117.696 175.289C118.854 174.982 119.895 174.231 120.541 173.112C120.613 172.988 120.675 172.862 120.734 172.734C120.29 171.115 119.235 169.664 117.666 168.758C116.098 167.852 114.312 167.664 112.689 168.089C112.608 168.204 112.53 168.321 112.458 168.445C112.033 169.182 111.831 169.985 111.832 170.779Z"
              fill="#FFCC4D"
            />
          </g>
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip13_11597:86094)">
            <path
              d="M6.33325 100.692C5.64591 100.692 4.98691 100.814 4.37725 101.039C6.17358 101.192 7.09625 102.15 6.66658 103.15C6.26391 104.088 4.69425 103.456 3.94525 103.409C2.67558 103.331 1.22225 103.413 0.0139157 105.72C1.84725 104.761 2.98625 105.609 3.05558 106.497C3.13258 107.483 2.20825 108.386 0.708249 108.053C1.50358 109.132 2.84225 109.164 3.70825 108.831C4.61092 108.483 5.36092 108.845 5.44425 109.692C5.53758 110.638 4.43058 111.164 2.66658 110.679C3.65492 111.519 4.93492 112.025 6.33325 112.025C9.46291 112.025 11.9999 109.488 11.9999 106.359C11.9999 103.229 9.46291 100.692 6.33325 100.692Z"
              fill="#F4900C"
            />
            <path
              d="M7.99975 102.894C9.02775 102.845 9.43075 103.748 9.23608 104.234C8.95508 104.936 8.47241 104.747 7.47241 104.928C6.47241 105.109 5.77808 105.803 5.47241 106.831C7.58341 106.081 8.36141 107.246 8.50041 107.857C8.64241 108.481 8.50008 109.108 7.16475 109.18C7.88941 109.908 8.89208 110.359 10.0001 110.359C10.1227 110.359 10.2434 110.351 10.3634 110.34C11.3747 109.317 12.0001 107.911 12.0001 106.359C12.0001 104.806 11.3747 103.4 10.3634 102.377C10.2434 102.366 10.1227 102.359 10.0001 102.359C9.27108 102.359 8.58808 102.553 7.99975 102.894Z"
              fill="#FFCC4D"
            />
          </g>
        </g>
        <g clipPath="url(#clip14_11597:86094)">
          <path
            d="M241.718 117.336C242.635 117.336 243.513 117.173 244.326 116.874C241.931 116.669 240.701 115.392 241.274 114.058C241.811 112.808 243.904 113.651 244.902 113.713C246.595 113.818 248.533 113.708 250.144 110.632C247.7 111.91 246.181 110.78 246.088 109.596C245.986 108.281 247.218 107.077 249.218 107.521C248.158 106.082 246.373 106.04 245.218 106.484C244.015 106.948 243.015 106.466 242.904 105.336C242.779 104.074 244.255 103.373 246.607 104.02C245.289 102.9 243.583 102.225 241.718 102.225C237.545 102.225 234.163 105.608 234.163 109.78C234.163 113.953 237.545 117.336 241.718 117.336Z"
            fill="#F4900C"
          />
          <path
            d="M239.496 114.4C238.126 114.466 237.588 113.262 237.848 112.614C238.223 111.678 238.866 111.929 240.2 111.688C241.533 111.447 242.459 110.521 242.866 109.151C240.052 110.151 239.014 108.597 238.829 107.782C238.64 106.95 238.829 106.114 240.61 106.019C239.644 105.048 238.307 104.447 236.829 104.447C236.666 104.447 236.505 104.457 236.345 104.472C234.996 105.836 234.163 107.71 234.163 109.781C234.163 111.851 234.996 113.725 236.345 115.089C236.505 115.104 236.666 115.114 236.829 115.114C237.801 115.114 238.712 114.854 239.496 114.4Z"
            fill="#FFCC4D"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_11597:86094"
          x={93.583}
          y={90.0662}
          width={61.8333}
          height={64.9336}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11597:86094"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11597:86094"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_11597:86094">
          <rect
            width={18.8694}
            height={17.7258}
            fill="white"
            transform="translate(139.233 3.43091)"
          />
        </clipPath>
        <clipPath id="clip1_11597:86094">
          <rect
            width={30}
            height={30}
            fill="white"
            transform="translate(159.228 212.024) rotate(120)"
          />
        </clipPath>
        <clipPath id="clip2_11597:86094">
          <rect
            width={26}
            height={26}
            fill="white"
            transform="translate(32.3066 142.934) rotate(-60)"
          />
        </clipPath>
        <clipPath id="clip3_11597:86094">
          <rect
            width={26}
            height={26}
            fill="white"
            transform="translate(163.865 145.292) rotate(-120)"
          />
        </clipPath>
        <clipPath id="clip4_11597:86094">
          <rect
            width={28}
            height={28}
            fill="white"
            transform="translate(65.5386) rotate(57.9222)"
          />
        </clipPath>
        <clipPath id="clip5_11597:86094">
          <rect
            width={27}
            height={27}
            fill="white"
            transform="translate(137.491 82.7673) rotate(-119.734)"
          />
        </clipPath>
        <clipPath id="clip6_11597:86094">
          <rect
            width={19}
            height={19}
            fill="white"
            transform="translate(5.35938 60.1575) rotate(-45)"
          />
        </clipPath>
        <clipPath id="clip7_11597:86094">
          <rect
            width={18}
            height={18}
            fill="white"
            transform="translate(87.2725 217.166) rotate(135)"
          />
        </clipPath>
        <clipPath id="clip8_11597:86094">
          <rect
            width={26}
            height={26}
            fill="white"
            transform="translate(207.663 38.0022) rotate(60)"
          />
        </clipPath>
        <clipPath id="clip9_11597:86094">
          <rect
            width={14}
            height={14}
            fill="white"
            transform="translate(59.1814 74.9861) rotate(-37.273)"
          />
        </clipPath>
        <clipPath id="clip10_11597:86094">
          <rect
            width={12}
            height={12}
            fill="white"
            transform="translate(13.9456 176.102) rotate(-120)"
          />
        </clipPath>
        <clipPath id="clip11_11597:86094">
          <rect
            width={19}
            height={19}
            fill="white"
            transform="translate(199.771 190.528) rotate(-45)"
          />
        </clipPath>
        <clipPath id="clip12_11597:86094">
          <rect
            width={14}
            height={14}
            fill="white"
            transform="translate(116.729 184.382) rotate(-150)"
          />
        </clipPath>
        <clipPath id="clip13_11597:86094">
          <rect
            width={12}
            height={12}
            fill="white"
            transform="translate(0 112.359) rotate(-90)"
          />
        </clipPath>
        <clipPath id="clip14_11597:86094">
          <rect
            width={16}
            height={16}
            fill="white"
            transform="translate(250.163 101.781) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
