import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { DateTime, Duration } from 'luxon';
import {
  AddMomentInput,
  DeleteMomentInput,
  Job,
  JobSource,
  JobStatus,
  UpdateMomentInput,
  IngestError,
  PageInfo,
  AddCommentInput,
  AddReplyInput,
  EditCommentInput,
  ResizedImage,
  AddReactionInput,
  JobFeatures,
  JobFeatureStatus,
  UpdateJobInput,
  Industry,
  Metadata,
  MedicalSpecialty,
  WatchMode,
  GetMomentQuery,
  JobType,
  JobSourceType,
  LiveStreamActivePlaylist,
  LiveStream,
  LiveStreamPlaylist,
  LiveStreamRecordings,
  LiveStreamChannels,
  Highlight,
  GenerativeAiProvider,
  JobSpeciality,
} from '@clipr/lib';
import { PartialDeep } from 'type-fest';
import identity from 'lodash/identity';
import { Owner } from '../entities/owner';
import { MomentModel, MomentProps, SubTopic, Topic } from './moment';
import { TrackModel, TrackProps } from './track';
import { MediaInfo } from './media';
import { StoreNode } from '../store';
import { Store } from '../store/store';
import { assert, AsyncResult, Maybe, Nullable } from '../core';
import { Bookmark } from './bookmark';
import { PermissionType } from './permission';
import { ApiVariables, ApiMutationOptions, ApiQueryOptions } from '../api/apiSchema';
import { TeamProps } from './team';
import { fromMoments, MomentQueryable } from './moments';
import { SpeakerModel } from './speaker';
import { durationToString, timestampNorm } from '../core/time';
import { apiResultIsAborted } from '../api';
import { Comment, CommentProps, Reaction, ReactionProps } from './comment';
import { EnrichmentItem, EnrichmentItems } from './job/jobFeatures';
import { input, InputState } from '../components';
import { notifyError, notifySuccess } from '../services/notifications';
import { AnalyticsEventTypes } from '../services/analytics/analyticsSchema';
import { JobLiveAggregateStatus, MediaAggregatedStatus } from '.';
import { toValidDateTime } from '../core/dateTime';

const OWNER_TEAM_PREFIX = `TEAM#`;
const OWNER_USER_PREFIX = `USER#`;

export type ValidatorResponse = {
  multipleActiveTrackTypes: string[];
  moments: MomentModel[],
  error: boolean
}

const trackIdentityKey = (type: string): 'type' | 'momentType' | 'speakerId' => {
  if (type === 'Moment')
    return 'momentType';
  else if (type === 'Transcript')
    return 'speakerId';

  return 'type';
}

/**
 * Returns the first Topic moment after the specified target moment.
 * IMPORTANT! This method assumes that the input list of moments is already sorted
 * (as it should be on the Job entity, for example).
 */
export function nextTopicAfter(
  moments: MomentModel[],
  target: MomentModel): Nullable<MomentModel> {
  for (let i = 0; i < moments.length; i++) {
    const mom = moments[i];
    if (mom.startTime > target.startTime && mom.clipType === 'Topic')
      return mom;
  }
  return null;
}

const trackSorter = (a: TrackModel, b: TrackModel) => {
  // order is:
  // 1. topic 
  // 2. generic moments 
  // 3. speaker moments

  // we use (+prop) just to be nice to TypeScript 
  return (
    ((+b.isTopic) - (+a.isTopic)) ||
    ((+b.isChapter) - (+a.isChapter)) ||
    ((+b.isSubtopic) - (+a.isSubtopic)) ||
    ((+b.isGeneric) - (+a.isGeneric)) ||
    ((+b.isParagraph) - (+a.isParagraph)) ||
    ((+b.isTranscript) - (+a.isTranscript)) ||
    a.name.localeCompare(b.name));
}

export function isJob(arg: any): arg is JobModel {
  return (
    arg instanceof JobModel &&
    arg.nodeType === 'Job');
}

export function assertIsJob(arg: any): asserts arg is JobModel {
  assert(isJob(arg),
    `Expected ${arg} to be instance of JobModel.`);
}

export enum SyncStatus {
  Fetching = 'Fetching',
  Fetched = 'Fetched',
  Error = 'Error',
  Empty = 'Empty'
}

export type JobProps = PartialDeep<Job> & {
  isTransient?: boolean
  highlights?: Highlight[]
};

export class JobModel
  extends StoreNode {

  constructor(props: Partial<JobProps>, store: Store) {
    super(store);
    makeObservable(this);

    Object.assign(this, props);

    if (props.media)
      this.media = new MediaInfo(props.media);

    this.publicToggleModel = input(this, {
      name: 'toggle-public-video',
      onChange: (evt) => this.handlePublishVideo(),
      disabled: () => this.isPublishing
    });
    this.publicToggleModel.value = `${!!this.isPublic}`;
    this.foundInTeams = this.visibleToTeams;

    this.isTransient = props.isTransient ?? false;
    this.highlights = props.highlights ?? null;
  }

  readonly nodeType: 'Job' = 'Job';

  readonly id!: string;
  readonly isTransient: boolean;
  readonly highlights: Highlight[] | null;
  readonly createdAt!: string;
  readonly languageCode!: string;
  readonly lastView!: string;
  readonly media!: MediaInfo;
  readonly momentCount!: number;
  readonly recordingLocation!: string;
  readonly recordingUrl!: string;
  readonly source!: JobSource;
  readonly status!: JobStatus;
  readonly subtitleLocation!: string;
  readonly title!: string;
  readonly description!: string;
  readonly user!: {
    id: string,
    username: string,
    name: string,
    showPlayerTutorial: boolean
  }
  readonly userId!: string;
  readonly durationInMs!: number;
  readonly videoType!: JobType;
  readonly viewCount!: number;
  readonly ingestError!: IngestError;
  readonly ingestExecutionArn!: string;
  readonly ownerId!: string;
  readonly owner!: Owner;
  readonly isPublic!: boolean;
  readonly speciality!: JobSpeciality;
  readonly medicalSpecialty?: MedicalSpecialty;
  readonly isPublished!: boolean;

  readonly visibleToTeams!: Partial<TeamProps>[];
  // A mutable copy of visibleToTeams to be used for FE operations like 'Add to Team'
  @observable foundInTeams: Partial<TeamProps>[];

  readonly userPermissions!: JobProps['userPermissions'];
  readonly poster?: ResizedImage[] = [];

  readonly features?: JobFeatures;
  readonly industry?: Industry;
  readonly metadata?: Metadata;
  readonly keywords?: string[];
  readonly tags?: string[];
  readonly sortOrder?: number;
  readonly publicToggleModel: InputState;

  readonly liveStream: LiveStream | null = null;
  readonly adTagUrls!: string[];
  readonly slideDeckUrl: string | null = null;
  readonly availableGptProviders: GenerativeAiProvider[] | null = null;
  readonly publicSafetyNotes: string | null = null;
  readonly publicSafetyReport: string | null = null;

  @computed
  get streamedOnLabel() {
    const startingTime = this.liveStreamStartDate?.toLocaleString(DateTime.TIME_SIMPLE);
    const startingDate = this.liveStreamStartDate?.toLocaleString(DateTime.DATE_SHORT);
    if (this.isLiveStreaming)
      return `Started On ${startingDate} at ${startingTime}`;

    if (
      this.liveAggregateStatus &&
      [
        JobLiveAggregateStatus.Processing,
        JobLiveAggregateStatus.ProcessingDone,
        JobLiveAggregateStatus.ProcessingFailed
      ].includes(this.liveAggregateStatus))
      return `Streamed On ${startingDate} at ${startingTime}`;

    return null;
  }

  @computed
  get recordings(): LiveStreamRecordings | null {
    return this.liveStream?.recordings ?? null;
  }

  @computed
  get isPrimarySource(): boolean {
    return this.source.liveStreamActivePlaylist === LiveStreamActivePlaylist.Primary;
  }

  @computed
  get isExternalSource(): boolean {
    return this.source.liveStreamActivePlaylist === LiveStreamActivePlaylist.External;
  }

  @computed
  get isBackupSource(): boolean {
    return this.source.liveStreamActivePlaylist === LiveStreamActivePlaylist.Secondary;
  }

  @computed
  get playlists(): LiveStreamPlaylist | null {
    return this.liveStream?.playlists ?? null;
  }

  @computed
  get playerSourceLabel(): string | null {
    const { liveCliprHLS, liveStreamUrl } = this.source;

    if (liveCliprHLS) return 'CLIPr';
    if (!liveCliprHLS && liveStreamUrl) return 'My HLS Stream Source';

    return null;
  }

  @computed get scheduledDateTime(): DateTime | null {
    return toValidDateTime(this.liveStream?.scheduledDateTime, { setZone: true });
  }

  // TODO: no need for the "on" in the naming, that's only for the UI
  // TODO: these labels are more specific for the UI tables, we should move them there or add
  //       some sort of separate adapters, because the Job entity is already too crowded with all sorts of UI stuff
  @computed get scheduledOnLabel(): string | null {
    const dateTime = this.scheduledDateTime;
    if (!dateTime)
      return null;

    const date = dateTime.toFormat('DD')
    const time = dateTime.toFormat('t');
    const offset = dateTime.offset / 60;

    return `${date} at ${time} UTC${offset >= 0 ? '+' : ''}${offset < 10 && offset > -10 ? '0' : ''}${offset}:00`;
  }

  @computed get scheduledOrStreamedLabel(): string | null {
    if (this.streamedOnLabel)
      return this.streamedOnLabel;

    if (this.scheduledOnLabel)
      return this.scheduledOnLabel;

    return null;
  }

  @computed
  get liveStreamStartDate(): DateTime | null {
    const dateStr = this.liveStream?.streamStart;
    if (!dateStr)
      return null;
    return DateTime.fromISO(dateStr);
  }

  @computed
  get liveStreamEndDate(): DateTime | null {
    const dateStr = this.liveStream?.streamEnd;
    if (!dateStr)
      return null;
    return DateTime.fromISO(dateStr);
  }

  @computed
  get liveStreamURL(): string | null {
    // TODO: Added optional chaining on this.media just in case, check if it's needed 
    return this.media?.liveStreamUrl ?? null;
  }

  /** 
   * Gets the actual `streamKey` that will be used for the live stream, depending on whether
   * the primary or the backup feed is selected.
   */
  @computed
  get activeLiveStreamKey(): string | null {
    const { liveStream } = this;
    return (this.source.liveStreamActivePlaylist === LiveStreamActivePlaylist.Primary ?
      liveStream?.streamKey :
      liveStream?.backupStreamKey) ?? null;
  }


  /** 
   * Gets the actual `ingestUrl` that will be used for the live stream, depending on whether
   * the primary or the backup feed is selected.
   */
  @computed
  get activeLiveIngestUrl(): string | null {
    const { liveStream } = this;
    return (this.source.liveStreamActivePlaylist === LiveStreamActivePlaylist.Primary ?
      liveStream?.ingestUrl :
      liveStream?.backupIngestUrl) ?? null;
  }

  /** Returns true if the Job is a live job, regardless of it's current status (waiting, streaming or ended). */
  @computed
  get isLive() {
    // TODO: Added optional chaining on source because it was causing some crashes
    // check why it was not added from the start and if the solution is valid
    return this.source?.type === JobSourceType.Live;
  }

  @computed
  get isAudioSource() {
    return !!this.media?.basic;
  }

  @observable updateStatus: 'publishing' | 'idle' = 'idle';
  @observable momentsSyncStatus: SyncStatus = SyncStatus.Empty;
  @observable speakersSyncStatus: SyncStatus = SyncStatus.Empty;
  @observable commentsSyncStatus: SyncStatus = SyncStatus.Empty;
  @observable reactionsSyncStatus: SyncStatus = SyncStatus.Empty

  private momentsFetchPromise: AsyncResult<boolean> | null = null;
  private commentsFetchPromise: AsyncResult<boolean> | null = null;
  private reactionsFetchPromise: AsyncResult<boolean> | null = null;

  @computed
  get isOwnerAuthenticated(): boolean {
    return this.userId === this.store.user?.id;
  }

  @computed
  get isPublishing(): boolean {
    return this.updateStatus === 'publishing';
  }

  @computed
  get isLiveStreaming(): boolean {
    if (!this.isLive) // just for safe measure
      return false;

    return this.status === JobStatus.LiveStreaming;
  }

  @computed
  get isLiveStreamWaiting(): boolean {
    if (!this.isLive) // just for safe measure
      return false;

    return this.status === JobStatus.LiveReady || this.status === JobStatus.Pending;
  }


  @computed
  get isLiveStreamEnded(): boolean {
    if (!this.isLive) // just for safe measure
      return false;

    return this.status === JobStatus.LiveEnded;
  }


  // TODO: Investigate why this is needed and replace it with isLiveProcessing if that's the case
  @computed
  get isLiveStreamProcessing(): boolean {
    if (!this.isLive) // just for safe measure
      return false;

    return this.status === JobStatus.InProgress ||
      this.status === JobStatus.Waiting ||
      this.status === JobStatus.InReview ||
      this.status === JobStatus.Done ||
      this.status === JobStatus.Failed ||
      this.status === JobStatus.Updated;
  }

  @computed
  get isLiveProcessing(): boolean {
    return this.liveAggregateStatus === JobLiveAggregateStatus.Processing;
  }

  @computed
  get isLiveFailed(): boolean {
    return this.liveAggregateStatus === JobLiveAggregateStatus.ProcessingFailed;
  }

  @computed
  get isLiveEnded(): boolean {
    return this.liveAggregateStatus === JobLiveAggregateStatus.LiveEnded;
  }

  @computed
  get isLiveProcessingDone(): boolean {
    return this.liveAggregateStatus === JobLiveAggregateStatus.ProcessingDone;
  }

  @computed
  get hasMetadata(): boolean {
    let hasMetadata = false;
    if (!this.metadata)
      return false;

    Object.keys(this.metadata).forEach(key => {
      let metadataKey = key as keyof Metadata;
      let value = this.metadata && this.metadata[metadataKey];
      if (value)
        hasMetadata = true;
    })
    return hasMetadata;
  }

  get posterURL(): string | null {
    return (this.poster &&
      this.poster.length > 0 &&
      this.poster[0]?.url) || null;
  }

  get ownerTeamId(): string | null {
    const { ownerId } = this;
    return ownerId?.startsWith(OWNER_TEAM_PREFIX) ?
      ownerId.slice(OWNER_TEAM_PREFIX.length) :
      null;
  }

  get ownerUserId(): string | null {
    const { ownerId } = this;
    return ownerId?.startsWith(OWNER_USER_PREFIX) ?
      ownerId.slice(OWNER_USER_PREFIX.length) :
      null;
  }

  @computed
  get displayOwnerName(): string {
    const { owner } = this;
    if (owner?.team) return owner.team.name;
    return owner.user?.name || owner.user?.username || owner.user?.email!;
  }

  @computed
  get videoDuration(): number {
    return (this.durationInMs / 1000) || 0;
  }

  @computed
  get videoDurationNorm(): number {
    return Math.ceil(this.videoDuration) > Math.round(this.videoDuration) ?
      this.videoDuration :
      Math.round(this.videoDuration);
  }

  @computed
  get isInProgress(): boolean {
    return this.status === 'InProgress';
  }
  @computed
  get isProcessing(): boolean {
    return !!(this.status && ['InReview', 'Waiting', 'Updated'].includes(this.status));
  }
  @computed
  get isFailed(): boolean {
    return this.status === 'Failed';
  }
  @computed
  get isDone(): boolean {
    return this.status === 'Done';
  }
  @computed
  get testStatus(): string { // Used for the automated tests
    if (['InReview', 'Waiting', 'Updated'].includes(this.status)) return 'IsProcessing';
    return this.status;
  }

  @computed
  get ellipsedTitle(): string | null {
    if (this.title?.length > 70) {
      return this.title?.substring(0, 69) + '...';
    } else {
      return this.title;
    }
  }

  @computed
  get liveAggregateStatus(): JobLiveAggregateStatus | null {
    const { status } = this;

    // we need to make sure the source is set as 'Live'
    // as most of the status values can overlap the static ones
    // job status alone cannot describe the source of the job (only some specific statuses)
    if (!this.isLive)
      return null;

    // the live status needs to be decoded from the general job status
    switch (status) {
      case JobStatus.Pending:
        return JobLiveAggregateStatus.NotReady;
      case JobStatus.LiveReady: // specific to live
        return JobLiveAggregateStatus.Waiting;

      case JobStatus.LiveStreaming: // specific to live
        return JobLiveAggregateStatus.Streaming;

      case JobStatus.LiveEnded: // specific to live
        return JobLiveAggregateStatus.LiveEnded;

      case JobStatus.Done: // generic
      case JobStatus.InProgress: // generic
      case JobStatus.Updated: // generic
      case JobStatus.InReview: // generic
      case JobStatus.Waiting: // generic
      case JobStatus.Failed: // TODO: check if failed might not refer to a fail in media status, then it might not return a correct status if media status is Pending -> mediaAggregatedStatus === Processing
        {
          switch (this.mediaAggregatedStatus) {
            case 'IngestCompleted':
              return JobLiveAggregateStatus.ProcessingDone;
            case 'Failed':
              return JobLiveAggregateStatus.ProcessingFailed;
            case 'IngestProcessing':
              return JobLiveAggregateStatus.Processing;
          }
        }
    }

    return null;
  }

  // this marks a combination between job status (overall download and ingest status), live job status and video level ingest status
  // job status = 'Failed' && ingest error = 'DownloadFailed' -> video level status = pending
  @computed
  get mediaAggregatedStatus(): MediaAggregatedStatus | null {
    if (this.isFailed) // can be a fail before actual ingestion flow or at ingest level (should include video)
      return MediaAggregatedStatus.Failed;

    if (this.isLiveStreaming)
      return MediaAggregatedStatus.LiveStreaming;

    if (this.isLiveStreamWaiting)
      return MediaAggregatedStatus.LiveWaiting;

    if (this.isLiveStreamEnded)
      return MediaAggregatedStatus.LiveEnded;

    if (this.isMediaLevelPending
      || this.isMediaLevelProcessing
      || this.isMediaLevelNotRequested)
      return MediaAggregatedStatus.IngestProcessing;

    if (this.isMediaLevelDone)
      return MediaAggregatedStatus.IngestCompleted;

    return null;
  }

  @computed
  get isMediaDone(): boolean {
    if (this.mediaAggregatedStatus === 'IngestCompleted')
      return true;

    return false;
  }

  @computed
  get isMediaAccessible(): boolean {
    if (this.mediaAggregatedStatus &&
      ['LiveStreaming', 'LiveWaiting', 'IngestCompleted'].includes(this.mediaAggregatedStatus))
      return true;

    return false;
  }

  @computed
  get isMediaProcessing(): boolean {
    if (this.mediaAggregatedStatus === 'IngestProcessing')
      return true;

    return false;
  }

  @computed
  get isMediaFailed(): boolean {
    if (this.mediaAggregatedStatus === 'Failed')
      return true;

    return false;
  }

  @observable momentQueryable: MomentQueryable | null = null;

  readonly momentLookup = observable.map<string, MomentModel>();
  momentPageInfo: PageInfo | null = null;

  @computed
  get moments(): MomentModel[] {
    return [...this.momentLookup.values()]
      .sort((a, b) => a.startTime - b.startTime);
  }

  @computed
  get visibleMoments(): MomentModel[] {
    return this.moments.filter(mom => mom.isActuallyVisibleToConsumer);
  }

  readonly trackLookup = observable.map<string, TrackModel>();

  @computed
  get tracks(): TrackModel[] {
    return [...this.trackLookup.values()]
      .sort(trackSorter);
  }

  @computed
  get activeTracks(): TrackModel[] {
    return this.tracks.filter(track => track.isActive);
  }

  @computed
  get transcriptTracks(): TrackModel[] {
    return this.tracks.filter(track => track.isTranscript);
  }

  @computed
  get momentTracks(): TrackModel[] {
    return this.tracks.filter(track => track.isGeneric);
  }

  @computed
  get topicTracks(): TrackModel[] {
    return this.tracks.filter(track => track.isTopic);
  }

  @computed
  get subtopicTracks(): TrackModel[] {
    return this.tracks.filter(track => track.isSubtopic);
  }

  @computed
  get activeTopicTracks(): TrackModel[] {
    return this.topicTracks.filter(track => track.isActive);
  }

  @computed
  get activeTopicTrack(): TrackModel | null {
    return this.topicTracks.find(track => track.isActive) ?? null;
  }

  @computed
  get activeSubtopicTracks(): TrackModel[] {
    return this.subtopicTracks.filter(track => track.isActive);
  }

  @computed
  get activeSubtopicTrack(): TrackModel | null {
    return this.subtopicTracks.find(track => track.isActive) ?? null;
  }
  // memoize filtered moment lists which are frequently accessed

  @computed
  get transcriptMoments() {
    return this.moments.filter(m => m.clipType === 'Transcript');
  }

  @computed
  get topicMoments(): Topic[] {
    return this.moments.filter(m => m.clipType === 'Topic') as Topic[];
  }

  @computed
  get subtopicMoments(): SubTopic[] {
    return this.moments.filter(m => m.clipType === 'SubTopic') as SubTopic[];
  }

  /** Moments which are not Transcript or Topic or SubTopic. */

  @computed
  get genericMoments() {
    return this.moments.filter(m => m.clipType === 'Moment');
  }

  /** Returns the 'createdAt' property as an UTC luxon DateTime object. */

  @computed
  get createdAtDate() {
    return DateTime.fromISO(this.createdAt);
  }

  @computed
  get createdAtLabel() {
    let date = this.createdAtDate;
    return (date.toLocaleString(DateTime.DATETIME_FULL));
  }

  @computed
  get createdAtRelativeLabel() {
    let date = this.createdAtDate;

    if (date.diffNow('days').days < -2)
      return date.toFormat('DDD');

    return (
      date.toRelativeCalendar() + ' at ' +
      date.toFormat('HH:mm'));
  }

  @computed
  get relatedBookmarks(): Bookmark[] {
    return this.store.bookmarks
      .filter(bkm => bkm.jobId === this.id);
  }

  @computed
  get relatedMomentBookmarks(): Bookmark[] {
    return this.relatedBookmarks
      .filter(bkm => bkm.targetType === 'Moment');
  }

  @computed
  get relatedTopicMomentBookmarks(): Bookmark[] {
    return this.relatedMomentBookmarks
      .filter(bkm => bkm.moment!.isTopic);
  }

  @computed
  get relatedGenericMomentBookmarks(): Bookmark[] {
    return this.relatedMomentBookmarks
      .filter(bkm => bkm.moment!.isGeneric);
  }

  @computed
  get speakerIds(): Set<string> {
    return new Set([
      ...this.tracks.map(track => track.speakerId),
      ...this.moments.map(moment => moment.speakerId)
    ].filter(identity));
  }

  @computed
  get speakers(): SpeakerModel[] {
    return this.store.speakerManager
      .getJobSpeakers(this.id)
      .filter(speaker => this.speakerIds.has(speaker.id));
  }

  @computed
  get privateSpeakers(): SpeakerModel[] {
    return this.store.speakerManager
      .getJobPrivateSpeakers(this.id)
      .filter(speaker => this.speakerIds.has(speaker.id));
  }

  @computed
  get hasPrivateSpeakers(): boolean {
    return this.privateSpeakers.length > 0;
  }

  @computed
  get trackSpeakerIds(): Set<string> {
    return new Set(this.tracks.map(track => track.speakerId) //this.tracks
      .filter(identity));
  }

  @computed
  get trackSpeakers(): SpeakerModel[] {
    return Array.from(this.trackSpeakerIds)
      .map(trackId => this.store.speakerManager.maybeGetSpeaker(trackId))
      .filter(identity) as SpeakerModel[];
  }

  // Enrichment section
  @computed
  get currentEnrichmentLevel(): EnrichmentItem | null {
    if (!this.features)
      return null;

    EnrichmentItems.forEach((key: EnrichmentItem) => {
      if (this.features && this.features[key] === 'Processing')
        return [key];
    });

    return null;
  }


  @computed
  get enrichmentLevel(): EnrichmentItem | null {
    if (!this.features)
      return null;

    for (let i = EnrichmentItems.length - 1; i >= 0; i--) {
      let key: EnrichmentItem = EnrichmentItems[i];

      if (this.features[key] !== 'NotRequested')
        return key;
    }

    return null;
  }

  @computed
  get mediaLevelStatus(): JobFeatureStatus | null {
    if (this.features)
      return this.features['media'];

    return null;
  }

  @computed
  get isMediaLevelDone(): boolean | null {
    return this.mediaLevelStatus === 'Done';
  }

  @computed
  get isMediaLevelProcessing(): boolean | null {
    return this.mediaLevelStatus === 'Processing';
  }

  @computed
  get isMediaLevelPending(): boolean | null {
    return this.mediaLevelStatus === 'Pending';
  }

  @computed
  get isMediaLevelFailed(): boolean | null {
    return this.mediaLevelStatus === 'Fail';
  }

  @computed
  get isMediaLevelNotRequested(): boolean | null {
    return this.mediaLevelStatus === 'NotRequested';
  }

  @computed
  get automaticEnrichmentLevelStatus(): JobFeatureStatus | null {
    if (this.features)
      return this.features['automaticEnrichment'];

    return null;
  }

  @computed
  get hasAutomaticEnrichmentLevel(): boolean {
    if (this.features)
      return this.features['automaticEnrichment'] !== 'NotRequested';

    return false;
  }

  @computed
  get isAutomaticEnrichmentLevelDone(): boolean {
    if (this.features)
      return this.features['automaticEnrichment'] !== 'Done';

    return false;
  }

  @computed
  get manualEnrichmentLevelStatus(): JobFeatureStatus | null {
    if (this.features)
      return this.features['manualEnrichment'];

    return null;
  }

  @computed
  get hasManualEnrichmentLevel(): boolean {
    if (this.features)
      return this.features['manualEnrichment'] !== 'NotRequested';

    return false;
  }

  @computed
  get hasTranscriptLevel(): boolean {
    if (this.features)
      return this.features['transcript'] !== 'NotRequested';

    return false;
  }

  @computed
  get transcriptLevelStatus(): JobFeatureStatus | null {
    if (this.features)
      return this.features['transcript'];

    return null;
  }

  @computed
  get transcriptStatus(): JobFeatureStatus | null {
    // if (this.hasManualEnrichmentLevel)
    //   return this.manualEnrichmentLevelStatus;

    return this.transcriptLevelStatus;
  }

  @computed
  get isTranscriptProcessing(): boolean {
    return this.transcriptStatus === 'Processing';
  }

  @computed
  get isTranscriptPending(): boolean {
    return this.transcriptStatus === 'Pending';
  }

  @computed
  get isTranscriptDone(): boolean {
    return this.transcriptStatus === 'Done';
  }

  @computed
  get enrichmentStatus(): JobFeatureStatus | null {
    // if (this.hasManualEnrichmentLevel)
    //   return this.manualEnrichmentLevelStatus;

    return this.automaticEnrichmentLevelStatus;
  }

  @computed
  get isEnrichmentProcessing(): boolean {
    return this.enrichmentStatus === 'Processing';
  }

  @computed
  get isEnrichmentPending(): boolean {
    return this.enrichmentStatus === 'Pending';
  }

  @computed
  get isEnrichmentDone(): boolean {
    return this.enrichmentStatus === 'Done';
  }

  @computed
  get isEnrichmentNotRequested(): boolean {
    return this.enrichmentStatus === 'NotRequested';
  }
  // Section end

  readonly commentLookup = observable.map<string, Comment>();
  @computed
  get comments(): Comment[] {
    return [...this.commentLookup.values()]
      .sort((a, b) => (a.videoTime ?? 0) - (b.videoTime ?? 0));
  }

  readonly reactionLookup = observable.map<string, Reaction>();
  @computed
  get reactions(): Reaction[] {
    return [...this.reactionLookup.values()]
      .sort((a, b) => (a.videoTime ?? 0) - (b.videoTime ?? 0));
  }

  @observable addedReaction: Nullable<Reaction> = null;

  @computed
  private get api() {
    return this.store.api;
  }

  async fetchDependencies(opts?: ApiQueryOptions)
    : AsyncResult<boolean> {

    const [
      [, err1],
      [, err2]
    ] = await Promise.all([
      this.apiFetchTracks(opts),
      this.apiFetchMoments(false, opts)
    ]);

    // need to have the tracks and moments loaded in order to determine the list of used speakers
    const [, err3] = await this.apiBatchFetchSpeakers(opts);

    if (err1 || err2 || err3)
      return [null, err1 || err2 || err3];
    return [true];
  }

  async fetchMoments(opts?: ApiQueryOptions): AsyncResult<boolean> {
    const fetch = async (opts?: ApiQueryOptions): AsyncResult<boolean> => {
      this.setMomentsSyncStatus(SyncStatus.Fetching);

      const [
        [, err1],
        [, err2]
      ] = await Promise.all([
        this.apiFetchTracks(opts),
        this.apiFetchMoments(false, opts)
      ]);

      if (err1 || err2) {
        this.setMomentsSyncStatus(SyncStatus.Error);
        return [null, err1 || err2];
      }

      this.setMomentsSyncStatus(SyncStatus.Fetched);
      return [true, null];
    }

    if (this.momentsSyncStatus === SyncStatus.Fetching && this.momentsFetchPromise) {
      return this.momentsFetchPromise;
    }

    this.momentsFetchPromise = fetch(opts);
    return this.momentsFetchPromise;
  }

  @action
  private setMomentsSyncStatus(status: SyncStatus) {
    this.momentsSyncStatus = status;
  }

  resetSyncStatuses() {
    this.momentsSyncStatus = SyncStatus.Empty;
    this.commentsSyncStatus = SyncStatus.Empty;
    this.reactionsSyncStatus = SyncStatus.Empty;
    this.speakersSyncStatus = SyncStatus.Empty;
  }

  async handlePublishVideo() {
    if (this.isPublishing) return;

    this.publicToggleModel.value = `${!this.isPublic}`;
    this.updateStatus = 'publishing';
    const args: UpdateJobInput = {
      id: this.id,
      isPublic: !this.isPublic,
    };
    const [, err] = await this.store.jobManager.apiUpdateJob(args, true);
    if (err) {
      notifyError(this, 'Your video status was not updated.');
    } else {
      notifySuccess(this, `Your video is now ${this.isPublic ? 'private.' : 'shareable.'}`);

      if (!this.isPublic) {
        this.store.analyticsService.registerEvent(
          AnalyticsEventTypes.VideoMakePublicType,
          {
            jobId: this.id,
            job: this
          }
        )
      }
    }
    this.updateStatus = 'idle';
  }

  // #region Speakers
  // -------
  async apiFetchSpeakers(opts?: ApiQueryOptions)
    : AsyncResult<boolean> {

    const [, err] = await this.store.speakerManager.apiFetchSpeakers({ jobId: this.id }, opts);
    if (err)
      return [null, err];

    return [true];
  }

  async apiBatchFetchSpeakers(opts?: ApiQueryOptions)
    : AsyncResult<boolean> {
    this.setSpeakersSyncStatus(SyncStatus.Fetching);

    const ids = Array.from(this.speakerIds);
    if (ids.length < 1)
      return [true];

    const [, err] = await this.store.speakerManager.apiBatchFetchSpeakers({ ids: ids }, opts);
    if (err) {
      this.setSpeakersSyncStatus(SyncStatus.Error);
      return [null, err];
    }

    this.setSpeakersSyncStatus(SyncStatus.Fetched);
    return [true];
  }

  @action
  private setSpeakersSyncStatus(status: SyncStatus) {
    this.speakersSyncStatus = status;
  }

  async addSpeaker(props: ApiVariables<'addSpeaker'>)
    : AsyncResult<boolean> {

    const [, err] = await this.store.apiAddSpeaker(props);
    if (err)
      return [null, err];

    return [true];
  }

  async apiUpdateSpeaker(props: ApiVariables<'updateSpeaker'>)
    : AsyncResult<boolean> {

    const [, err] = await this.store.apiUpdateSpeaker(props);
    if (err)
      return [null, err];

    return [true];
  }
  // #endregion


  // #region Tracks
  // -------
  hasTrack(id: string): boolean {
    return this.trackLookup.has(id);
  }

  getTrack(id: string): TrackModel | null {
    return this.trackLookup.get(id) || null;
  }

  maybeGetTrackByName(name: string): TrackModel | null {
    if (!name)
      return null;
    return this.tracks.find(t => t.name === name) || null;
  }

  maybeGetTranscriptTrackBySpeaker(speakerId: string | null): TrackModel | null {
    if (!speakerId)
      return null;
    return this.transcriptTracks.find(t => t.speakerId === speakerId) || null;
  }

  maybeGetMomentTrackByMomentType(momentType: string | null): TrackModel | null {
    if (!momentType)
      return null;
    return this.momentTracks.find(t => t.momentType === momentType) || null;
  }

  @action
  insertTrack(props: TrackProps) {
    const track = new TrackModel(props, this.store);
    this.trackLookup.set(track.id, track);
    return track;
  }

  async apiFetchTracks(opts?: ApiQueryOptions)
    : AsyncResult<boolean> {

    const [tracksRes, err] = await this.api.getTracks({
      jobId: this.id
    }, opts);
    if (err)
      return [null, err];

    const items = tracksRes?.getTracks || [];

    runInAction(() => {
      this.trackLookup.clear();
      items.forEach(track => {
        this.trackLookup.set(track.id, new TrackModel(track, this.store));
      });
    });
    return [true];
  }

  async apiAddTrack(args: ApiVariables<'addTrack'>)
    : AsyncResult<TrackModel> {

    const [res, err] = await this.api.addTrack(args);
    if (err)
      return [null, err];

    const trackId = res?.addTrack.id;

    if (!trackId)
      return [null, new Error('Added track ID was not returned')];

    const [, refErr] = await this.apiFetchTracks();
    if (refErr)
      return [null, refErr];

    const track = trackId && this.getTrack(trackId);
    if (track)
      return [track];

    return [null, new Error('Added track was not fetched')];
  }

  async apiUpdateTrack(args: ApiVariables<'updateTrack'>, deps: ('tracks' | 'moments')[] = ['tracks'])
    : AsyncResult<TrackModel> {

    const [res, err] = await this.api.updateTrack(args);
    if (err)
      return [null, err];

    const trackId = res?.updateTrack.id;

    if (!trackId)
      return [null, new Error('Updated track ID was not returned')];

    let requests: any[] = [];
    deps.forEach(item => {
      switch (item) {
        case 'tracks':
          requests = [
            ...requests,
            this.apiFetchTracks()
          ]
          break;
        case 'moments':
          requests = [
            ...requests,
            this.apiFetchMoments()
          ]
          break;
      }
    })

    const resAll = await Promise.all(requests);
    const err1 = resAll.find(res => res && !!res[1])?.[1];

    if (err1)
      return [null, err1]

    const track = trackId && this.getTrack(trackId);
    if (track)
      return [track];

    return [null, new Error('Updated track was not fetched')];
  }

  async apiMergeTracks(args: ApiVariables<'mergeTracks'>)
    : AsyncResult<Boolean> {

    const [res, err] = await this.api.mergeTracks(args);
    if (err)
      return [null, err];

    const [
      [, err1],
      [, err2]
    ] = await Promise.all([
      this.apiFetchTracks(),
      this.apiFetchMoments()
    ]);

    if (err1 || err2)
      return [null, err1 || err2];

    if (res?.mergeTracks)
      return [res?.mergeTracks];

    return [null, new Error('No response returned for the merge request')];
  }

  async apiDuplicateTrack(args: ApiVariables<'duplicateTrack'>)
    : AsyncResult<TrackModel> {

    const [res, err] = await this.api.duplicateTrack(args);
    if (err)
      return [null, err];

    const trackId = res?.duplicateTrack.id;

    const [
      [, err1],
      [, err2]
    ] = await Promise.all([
      this.apiFetchTracks(),
      this.apiFetchMoments()
    ]);

    if (err1 || err2)
      return [null, err1 || err2];

    const track = trackId && this.getTrack(trackId);
    if (track)
      return [track];

    return [null, new Error('No response returned for the duplicate request')];
  }
  // #endregion


  // #region Moments
  // -------
  hasMoment(id: string): boolean {
    return this.momentLookup.has(id);
  }

  getMoment(id: string): MomentModel | null {
    return this.momentLookup.get(id) || null;
  }

  // it refers to the active topic track topic moments
  @computed
  get activeTopics(): Topic[] {
    const activeTopicTrack = this.activeTopicTrack;
    if (!activeTopicTrack)
      return [];
    return this.topicMoments.filter(m => m.trackId === activeTopicTrack.id) as Topic[];
  }

  @computed
  get activeSubtopics(): SubTopic[] {
    const activeSubtopicTrack = this.activeSubtopicTrack;
    if (!activeSubtopicTrack)
      return [];
    return this.subtopicMoments.filter(m => m.trackId === activeSubtopicTrack.id) as SubTopic[];
  }

  // everything is normalized to match the player seeker/selector
  getTopicsOverlappingInterval(start: number, end: number): MomentModel[] { // base for determining the parent
    start = timestampNorm(start);
    end = timestampNorm(end);
    return this.topicMoments.filter(moment =>
      (moment.startTimeNorm < end && moment.endTimeNorm > start) || moment.startTimeNorm === start
    );
  }

  // everything is normalized to match the player seeker/selector
  getActiveTopicsOverlappingInterval(start: number, end: number): MomentModel[] { // base for determining the parent
    start = timestampNorm(start);
    end = timestampNorm(end);
    return this.activeTopics.filter(moment =>
      (moment.startTimeNorm < end && moment.endTimeNorm > start) || moment.startTimeNorm === start
    );
  }

  getActiveTopicsContainingInterval(start: number, end: number): MomentModel[] { // base for determining the parent
    start = timestampNorm(start);
    end = timestampNorm(end);
    return this.activeTopics.filter(moment =>
      (moment.startTimeNorm <= start && moment.endTimeNorm >= end)
    );
  }

  getMomentParent(moment: MomentModel): MomentModel | null { // parent is determined by checking the first overlapping active moment(topic)
    const overlappingTopics = this.getActiveTopicsOverlappingInterval(moment.startTimeNorm, moment.endTimeNorm);
    return overlappingTopics.find(el => el.id !== moment.id) || null;
  }

  getIntervalParent(start: number, end: number, excludeIds?: string[]): MomentModel | null { // parent is determined by checking the first overlapping active moment(topic) that is not excluded
    return this.getActiveTopicsOverlappingInterval(start, end)
      .find(el => excludeIds ? !excludeIds.includes(el.id) : el) || null;
  }

  isIntervalContained(start: number, end: number, excludeIds?: string[]): boolean { // check if it's contained in parent boundaries
    start = timestampNorm(start);
    end = timestampNorm(end);
    const parent = this.getIntervalParent(start, end, excludeIds);
    return !!(parent && (start >= parent.startTimeNorm) && (end <= parent.endTimeNorm));
  }

  getSubtopicsOverlappingInterval(start: number, end: number): MomentModel[] { // base for detemining children
    start = timestampNorm(start);
    end = timestampNorm(end);
    return [
      ...this.subtopicMoments?.filter(moment =>
        (moment.startTimeNorm < end && moment.endTimeNorm > start) || moment.startTimeNorm === start
      )
    ];
  }

  getActiveSubtopicsOverlappingInterval(start: number, end: number): MomentModel[] { // base for detemining children
    start = timestampNorm(start);
    end = timestampNorm(end);
    return [
      ...this.activeSubtopics.filter(moment =>
        (moment.startTimeNorm < end && moment.endTimeNorm > start) || moment.startTimeNorm === start
      )
    ];
  }

  getMomentChildren(moment: MomentModel): MomentModel[] {
    return this.getActiveSubtopicsOverlappingInterval(moment.startTimeNorm, moment.endTimeNorm);
  }

  getIntervalChildren(start: number, end: number): MomentModel[] {
    return this.getActiveSubtopicsOverlappingInterval(start, end);
  }

  getIntervalUncontainedChildren(start: number, end: number): MomentModel[] {
    start = timestampNorm(start);
    end = timestampNorm(end);
    return this.getIntervalChildren(start, end)
      .filter(el =>
        el.startTimeNorm < start ||
        el.endTimeNorm > end
      );
  }
  @computed
  get uncontainedSubtopics(): MomentModel[] {
    return [
      ...this.subtopicMoments.filter(moment =>
        !this.topicMoments.some(topic =>
          (topic.startTimeNorm <= moment.startTimeNorm && topic.endTimeNorm >= moment.endTimeNorm) &&
          (topic.endTimeNorm > moment.startTimeNorm) // to exclude adimensional weird cases
        ))
    ]
  }

  @computed
  get uncontainedActiveSubtopics(): MomentModel[] {
    return [
      ...this.activeSubtopics.filter(moment =>
        !this.activeTopics.some(topic =>
          (topic.startTimeNorm <= moment.startTimeNorm && topic.endTimeNorm >= moment.endTimeNorm) &&
          (topic.endTimeNorm > moment.startTimeNorm) // to exclude adimensional weird cases
        ))
    ]
  }

  @computed
  get multipleActiveTrackTypes(): string[] {
    return this.activeTracks.reduce((acc, arg) => {
      const identityProperty: keyof TrackModel = trackIdentityKey(arg.type);
      const identityValue = arg[identityProperty];
      const isMultipleTimes = this.activeTracks.filter(track => track[identityProperty] === arg[identityProperty]).length > 1;

      if (identityValue && isMultipleTimes && !acc.includes(identityValue))
        acc.push(identityValue);

      return acc;
    }, [] as string[]);
  }

  validateSetToDone(): ValidatorResponse {
    const multipleActiveTrackTypes = this.multipleActiveTrackTypes;
    const uncontainedSubtopics = this.uncontainedActiveSubtopics;
    const isError = multipleActiveTrackTypes.length > 0 || uncontainedSubtopics.length > 0; // if no active track, more than one active tracks, or any overlapping moments
    return {
      multipleActiveTrackTypes: multipleActiveTrackTypes,
      moments: uncontainedSubtopics,
      error: isError
    }
  }

  getActiveDuplicateByTrack(track: Pick<TrackModel, 'type' | 'momentType' | 'speakerId'> & { id?: string }) {
    const identityKey = trackIdentityKey(track.type);
    if (track.id) {
      return this.activeTracks.filter(item =>
        track.type === item.type && track[identityKey] === item[identityKey] && track.id !== item.id);
    }

    return this.activeTracks.filter(item =>
      track.type === item.type && track[identityKey] === item[identityKey]);
  }

  checkIfActiveDuplicateForTrack(track: Pick<TrackModel, 'type' | 'momentType' | 'speakerId'> & { id?: string }) {
    return this.getActiveDuplicateByTrack(track).length > 0;
  }

  maybeGetMoment(id?: Maybe<string>): MomentModel | null {
    if (!id)
      return null;
    return this.momentLookup.get(id) || null;
  }

  @action
  insertMoment(props: Partial<MomentProps>) {
    const moment = new MomentModel(props, this.store);
    this.momentLookup.set(moment.id, moment);
    return moment;
  }

  @action
  private getFetchMomentsArgs(more: boolean = false) {
    let args: ApiVariables<'getMoments'> = {
      jobId: this.id
    };

    // args.first = 1000; if not set should return the max size

    if (more && this.momentPageInfo?.endCursor)
      args.after = this.momentPageInfo?.endCursor;

    return args;
  }

  async apiFetchMoments(more: boolean = false, opts?: ApiQueryOptions)
    : AsyncResult<boolean> {

    const args: ApiVariables<'getMoments'> = this.getFetchMomentsArgs(more);
    const [momentsRes, err] = await this.api.getMoments(args, opts);
    if (err) {
      this.setMomentsSyncStatus(SyncStatus.Error);
      return [null, err];
    }

    const items = momentsRes?.getMoments.edges || [];

    // TODO: implement smarter merging strategy
    runInAction(() => {
      if (!more)
        this.momentLookup.clear();
      items.forEach(edge => {
        const moment = edge.node;
        // @ts-ignore
        this.insertMoment(moment)
      });

      this.momentQueryable = fromMoments(this.moments);
      this.momentPageInfo = momentsRes?.getMoments?.pageInfo || null;
      this.setMomentsSyncStatus(SyncStatus.Fetched);
    });

    if (this.momentPageInfo?.hasNextPage)
      await this.apiFetchMoments(true, opts);

    return [true];
  }

  async apiFetchMoment(momentId: string, opts?: ApiQueryOptions)
    : AsyncResult<GetMomentQuery> {

    let args: ApiVariables<'getMoment'> = {
      jobId: this.id,
      momentId: momentId
    };

    const [momentsRes, err] = await this.api.getMoment(args, opts);
    if (err)
      return [null, err];

    return [momentsRes, null];
  }

  async apiAddMoment(input: AddMomentInput, updateJob = true)
    : AsyncResult<string> {

    const [res, err] = await this.api.addMoment({
      args: input
    });
    if (err)
      return [null, err];

    const momentId = res?.addMoment?.id;
    if (!momentId)
      return [null, new Error('Created moment ID was not returned')];

    if (updateJob) {
      const [, refErr] = await this.fetchDependencies();
      if (refErr)
        return [null, refErr];
    }

    return [momentId];
  }

  async apiUpdateMoment(input: UpdateMomentInput)
    : AsyncResult<string> {

    const [res, err] = await this.api.updateMoment({
      args: input
    });
    if (err)
      return [null, err];

    const momentId = res?.updateMoment?.id;
    if (!momentId)
      return [null, new Error('Updated moment ID was not returned')];

    const [, refErr] = await this.fetchDependencies();
    if (refErr)
      return [null, refErr];

    return [momentId];
  }

  async apiUpdateMoments(input: UpdateMomentInput[])
    : AsyncResult<boolean> {

    const res = await Promise.all(input.map(async args => await this.api.updateMoment({ args: args })));

    const resArr = res.map((el, i) => {
      const [res, err] = el;

      if (err)
        return [null, err];

      const momentId = res?.updateMoment?.id;
      if (!momentId)
        return [null, new Error('Updated moment ID was not returned')];

      return [momentId]
    })

    const hasErrors = resArr.find(res => {
      const [, err] = res;
      return !!err;
    })

    if (hasErrors)
      return [null, new Error('Update batch contains errors')]

    const [, refErr] = await this.fetchDependencies();
    if (refErr)
      return [null, refErr];

    return [true]
  }

  async apiDeleteMoment(input: DeleteMomentInput)
    : AsyncResult<string> {

    const [res, err] = await this.api.deleteMoment(input);
    if (err)
      return [null, err];

    const momentId = res?.deleteMoment?.deletedId;
    if (!momentId)
      return [null, new Error('Deleted moment ID was not returned')];

    const [, refErr] = await this.fetchDependencies();
    if (refErr)
      return [null, refErr];
    return [momentId];
  }

  async apiDeleteMoments(input: DeleteMomentInput[])
    : AsyncResult<boolean> {

    const res = await Promise.all(input.map(async args => await this.api.deleteMoment(args)));

    const resArr = res.map((el, i) => {
      const [res, err] = el;

      if (err)
        return [null, err];

      const momentId = res?.deleteMoment?.deletedId;
      if (!momentId)
        return [null, new Error('Deleted moment ID was not returned')];

      return [momentId]
    })

    const hasErrors = resArr.find(res => {
      const [, err] = res;
      return !!err;
    })

    if (hasErrors)
      return [null, new Error('Delete batch contains errors')]

    const [, refErr] = await this.fetchDependencies();
    if (refErr)
      return [null, refErr];

    return [true]
  }
  // #endregion

  // #region Comments and Reactions API methods

  async apiFetchComments(opts?: ApiQueryOptions)
    : AsyncResult<boolean> {

    const [res, err] = await this.api.getComments({
      args: {
        jobId: this.id
      }
    }, opts);

    if (err)
      return [null, err];

    const items = res?.getComments.edges || [];

    // TODO: implement smarter merging strategy
    runInAction(() => {
      items.forEach(edge => {
        const comment = edge.node;
        this.insertComment(comment)
      });
    });

    return [true];
  }

  async fetchComments(opts?: ApiQueryOptions): AsyncResult<boolean> {
    const fetch = async (opts?: ApiQueryOptions): AsyncResult<boolean> => {
      this.setCommentsSyncStaus(SyncStatus.Fetching);

      const [, err] = await this.apiFetchComments(opts);

      if (err) {
        this.setCommentsSyncStaus(SyncStatus.Error);
        return [null, err];
      }

      this.setCommentsSyncStaus(SyncStatus.Fetched);
      return [true, null];
    }

    if (this.commentsSyncStatus === SyncStatus.Fetching && this.commentsFetchPromise) {
      return this.commentsFetchPromise;
    }

    this.commentsFetchPromise = fetch(opts);
    return this.commentsFetchPromise;
  }

  @action
  private setCommentsSyncStaus(status: SyncStatus) {
    this.commentsSyncStatus = status;
  }

  async apiFetchReactions(opts?: ApiQueryOptions)
    : AsyncResult<boolean> {

    const watchMode = [WatchMode.Static, WatchMode.Live];

    const [res, err] = await this.api.getReactions({
      args: {
        jobId: this.id,
        filter: {
          watchMode
        }
      }
    }, opts);

    if (err)
      return [null, err];

    const items = res?.getReactions.edges || [];

    // TODO: implement smarter merging strategy
    runInAction(() => {
      items.forEach(edge => {
        const rct = edge.node;
        this.insertReaction(rct)
      });
    });

    return [true];
  }

  async fetchReactions(opts?: ApiQueryOptions): AsyncResult<boolean> {
    const fetch = async (opts?: ApiQueryOptions): AsyncResult<boolean> => {
      this.setReactionsSyncStatus(SyncStatus.Fetching);

      const result = await this.apiFetchReactions(opts);
      if (apiResultIsAborted(result))
        return [null, null];

      const [, err] = result;

      if (err) {
        this.reactionsSyncStatus = SyncStatus.Error;
        notifyError(this, `Could not fetch reactions because of an error.`);

        return [null, err];
      }

      this.reactionsSyncStatus = SyncStatus.Fetched;
      return [true, null];
    }

    if (this.reactionsSyncStatus === SyncStatus.Fetching && this.reactionsFetchPromise) {
      return this.reactionsFetchPromise;
    }

    this.reactionsFetchPromise = fetch(opts);
    return this.reactionsFetchPromise;
  }

  @action
  private setReactionsSyncStatus(status: SyncStatus) {
    this.reactionsSyncStatus = status;
  }

  async apiAddComment(input: AddCommentInput, opts?: ApiMutationOptions)
    : AsyncResult<Comment> {

    const [res, err] = await this.api.addComment({ args: input }, opts);
    if (err)
      return [null, err];

    const props: CommentProps = res?.addComment!;
    const comm = this.insertComment(props);
    this.addedReaction = null;

    return [comm];
  }

  async apiAddReply(input: AddReplyInput, opts?: ApiMutationOptions)
    : AsyncResult<Comment> {

    const [res, err] = await this.api.addReply({ args: input }, opts);
    if (err)
      return [null, err];

    const props: CommentProps = res?.addReply!;
    const comm = this.insertComment(props);

    return [comm];
  }

  async apiEditComment(input: EditCommentInput, opts?: ApiMutationOptions)
    : AsyncResult<Comment> {

    const [res, err] = await this.api.editComment({ args: input }, opts);
    if (err)
      return [null, err];

    const props: CommentProps = res?.editComment!;
    const comm = this.insertComment(props);

    return [comm];
  }

  async apiDeleteComment(vars: ApiVariables<'deleteComment'>, opts?: ApiMutationOptions)
    : AsyncResult<Comment | null> {

    const [res, err] = await this.api.deleteComment(vars, opts);
    if (err)
      return [null, err];

    const props = res?.deleteComment!;
    const comm = this.deleteComment(props.id);

    return [comm];
  }

  async apiAddReaction(input: AddReactionInput, opts?: ApiMutationOptions)
    : AsyncResult<Reaction> {

    const [res, err] = await this.api.addReaction({ args: input }, opts);
    if (err)
      return [null, err];

    const props: CommentProps = res?.addReaction!;
    const comm = this.insertReaction(props) as Reaction;
    this.setAddedReaction(comm);

    return [comm];
  }

  @action
  setAddedReaction = (rct: Reaction | null) => {
    this.addedReaction = rct;
  }
  // #endregion

  // api misc
  async apiGetJobTranscript(input: Omit<ApiVariables<'getJobTranscript'>, 'jobId'>, opts?: ApiQueryOptions)
    : AsyncResult<string | null> {

    const args = Object.assign(input, {
      jobId: this.id
    });

    const [res, err] = await this.api.getJobTranscript(args, opts);

    if (err)
      return [null, err];

    const url = res?.getJobTranscript?.url ?? null;

    return [url];
  }
  // #endregion

  // #region Comments and Reaction data
  @action
  insertComment(props: Partial<CommentProps>) {
    const com = new Comment(this.store, props);
    this.commentLookup.set(com.id, com);
    return com;
  }
  /**
   * Removes a Comment from the store.
   * This is only a local method. If you want to make an API delete mutation use `apiDeleteComment`.
   */
  @action
  deleteComment(id: string): Comment | null {
    const comm = this.commentLookup.get(id);
    this.commentLookup.delete(id);
    return comm ?? null;
  }

  /** Returns the `Comment` with the requested `id` if it has been loaded into the `Job`, or `null` otherwise. */
  getComment(id?: Maybe<string>): Comment | null {
    if (!id)
      return null;
    return this.commentLookup.get(id) ?? null;
  }

  @action
  insertReaction(props: Partial<ReactionProps>): Reaction {
    const rct = new Comment(this.store, props) as Reaction;

    this.reactionLookup.set(rct.id, rct as Reaction);
    return rct;
  }

  /**
   * Removes a `Reaction` from the store.
   * This is only a local method. If you want to make an API delete mutation use `apiDeleteReaction`.
   */
  @action
  deleteReaction(id: string): Reaction | null {
    const comm = this.reactionLookup.get(id);
    this.reactionLookup.delete(id);
    return comm ?? null;
  }

  /** Returns the `Reaction` with the requested `id` if it has been loaded into the `Job`, or `null` otherwise. */
  getReaction(id?: Maybe<string>): Reaction | null {
    if (!id)
      return null;
    return this.reactionLookup.get(id) ?? null;
  }
  // #endregion

  toString() {
    return '{ Job }';
  }

  async copyStreamKey() {
    if (this.liveStream?.streamKey) {
      await navigator.clipboard?.writeText(this.liveStream?.streamKey);
      notifySuccess(this, 'Server key copied to clipboard!');
    }
  }

  async copyIngestUrl() {
    if (this.liveStream?.ingestUrl) {
      await navigator.clipboard?.writeText(this.liveStream?.ingestUrl);
      notifySuccess(this, 'Server URL copied to clipboard!');
    }
  }

  async copyBackupStreamKey() {
    if (this.liveStream?.backupStreamKey) {
      await navigator.clipboard?.writeText(this.liveStream?.backupStreamKey);
      notifySuccess(this, 'Server key copied to clipboard!');
    }
  }

  async copyBackupIngestUrl() {
    if (this.liveStream?.backupIngestUrl) {
      await navigator.clipboard?.writeText(this.liveStream?.backupIngestUrl);
      notifySuccess(this, 'Server URL copied to clipboard!');
    }
  }

  durationToString(format: 'card' = 'card'): string {
    return durationToString(this.videoDuration, format);
  }

  durationToTimeCode(format: string): string | null {
    switch (format) {
      case 'card':
        if (!this.videoDuration)
          return null;

        const dur = Duration.fromObject({ seconds: this.videoDuration });
        const hmsDur = dur.shiftTo('hours', 'minutes', 'seconds');

        const mins = Math.round(hmsDur.minutes).toString().padStart(2, '0');
        const secs = Math.round(hmsDur.seconds).toString().padStart(2, '0');
        const hrs = Math.round(hmsDur.hours).toString().padStart(2, '0');

        return `${hrs !== '00' ? hrs + ':' : ''}${mins}:${secs}`;
    }

    return null;
  }

  hasPermission(type: PermissionType) {

    switch (type) {
      case 'UserEditJob':
        return this.userPermissions?.includes('Edit');

      case 'DeleteJob':
        return this.userPermissions?.includes('Delete');

      case 'UserCopyJob':
        return this.store.user?.hasPermission('UserUpload');

      case 'UserViewJobMenu':
        return this.userPermissions?.includes('Edit') || this.userPermissions?.includes('Delete');

      case 'EditTrainerMoment':
        return this.userPermissions?.includes('EditTrainerMoment');

      case 'EditUserMoment':
        return this.userPermissions?.includes('EditUserMoment');

      case 'EditIsPublic':
        return this.userPermissions?.includes('EditIsPublic');

      case 'ChangeOwner':
        return this.store.user?.hasPermission('ChangeOwner');

      case 'AdminEditJob':
        return this.store.user?.roles.includes('Admin');

      case 'EmbedPlayerWidget':
        return true; // this.isPublic;

      case 'AddComment':
        return this.userPermissions?.includes('AddComment');
      // const user = this.store.user;
      // if (!user)
      //   return false;
      // return user.hasRole('Admin') || user.hasRole('Trainer') || user.hasRole('Uploader');

      case 'EditAnyComment':
        return false;
      case 'DeleteAnyComment':
        return this.store.user?.isAdmin ?? false;

      case 'TrainerEditJob':
        return this.store.user?.roles.includes('Trainer');

      case 'EditVideoDetails':
        return this.userPermissions?.includes('EditIsPublic');
    }

    console.warn(`PermissionType '${type}' is not valid for a Job entity.`);
    return false;
  }

  getPlaylistSource(playlist: LiveStreamActivePlaylist) {
    switch (playlist) {
      case LiveStreamActivePlaylist.External:
        return this.playlists?.external;

      case LiveStreamActivePlaylist.Primary:
        return this.playlists?.primary;

      case LiveStreamActivePlaylist.Secondary:
        return this.playlists?.secondary;
    }
  }

  isPlaylistActive(playlist: LiveStreamActivePlaylist): boolean {
    switch (playlist) {
      case LiveStreamActivePlaylist.External:
        return (!!this.playlists?.external && this.isLiveStreaming) ?? false;

      case LiveStreamActivePlaylist.Primary:
        return this.playlists?.primaryActive ?? false;

      case LiveStreamActivePlaylist.Secondary:
        return this.playlists?.secondaryActive ?? false;
    }
  }

  isPlaylistSelected(playlist: LiveStreamActivePlaylist): boolean {
    return this.source.liveStreamActivePlaylist === playlist;
  }

  recordingAreAvailable(channel: LiveStreamChannels) {
    switch (channel) {
      case 'Primary': {
        return this.recordings?.primary;
      }

      case 'Secondary': {
        return this.recordings?.secondary;
      }
    }
  }

  isAiProcessing(library: GenerativeAiProvider): boolean {
    return this.isDone && this.hasAutomaticEnrichmentLevel && !this.availableGptProviders?.includes(library);
  }

  isAiDone(library: GenerativeAiProvider): boolean {
    return this.isDone && this.hasAutomaticEnrichmentLevel && !!this.availableGptProviders?.includes(library);
  }
}
