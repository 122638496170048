import { action, computed, makeObservable } from 'mobx';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { IPlayer } from '../player/playerSchema';
import { JobModel, Reaction } from '../../entities';
import { PlayerState } from '../player';
import { AsyncResult, isFiniteNumber } from '../../core';
import { notifyError } from '../../services/notifications';
import { InputPlayerReactionName } from './playerReactionSchema';
import { WatchMode } from '@clipr/lib';

type Props = BindingProps<{
  jobId?: string | null,
  player?: IPlayer | null,
  playerTutorialHighlightedComponents?: string[];
}>

export class PlayerReactionsState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  // #region Props
  @computed get jobId(): string | null {
    return this.resolvedProps.jobId ?? null;
  }

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed get reactions(): Reaction[] {
    return this.job?.reactions ?? [];
  }

  @computed get player(): PlayerState | null {
    return this.resolvedProps.player ?? null;
  }

  // #endregion

  // #region Queries / Mutations
  async addReaction(name: InputPlayerReactionName)
    : AsyncResult<Reaction> {

    const { job, jobId } = this;
    if (!job || !jobId) {
      notifyError(this, 'Could not add your reaction.');
      return [null, new Error('No job active')];
    }

    const time = this.player?.currentTime;
    if (!isFiniteNumber(time)) {
      notifyError(this, 'Could not add your reaction.');
      return [null, new Error(`Player doesn't have a valid time.`)];
    }

    const [reaction, err] = await job.apiAddReaction({
      jobId,
      reaction: name,
      videoTime: time,
      watchMode: this.player?.isLiveStream ? WatchMode.Live : WatchMode.Static,
    });

    if (err || !reaction) {
      notifyError(this, 'Could not add your reaction.');
      return [null, err];
    }

    return [reaction];
  }
  // #endregion

  @action
  reset() {

  }
}