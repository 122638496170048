import './teamDashboardPage.scss';

import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';

import Routes, { BackRoutes } from '../../routes';
import { AnalyticsSVGIcon, SettingsSVGIcon, UploadSVGIcon, LiveQueueSVGIcon } from '../../components';
import { Button, FilterFieldButton, SortFieldButton } from '../../components/input';
import { MenuItem } from '../../components/input/menuItems';
import { MasterPage } from '../../components/layout';
import { JobCardCatalog } from '../../components/media';
import { MenuButton } from '../../components/menu';
import { SearchBar } from '../../components/search';
import { TeamHeading, TeamMemberSummary } from '../../components/teams';
import { JobVideoTypeList } from '../../entities';
import { useStore } from '../../store/storeHooks';
import { ErrorPage } from '../error';
import { TeamDashboardPageParams } from './teamDashboardPageState';
import { useWindowResize } from '../../components/hooks';
import { resolutionBasedPageSize } from '../../components/utils';
import { TagSection } from '../../components/tags/tagSection';

type Props = {};

export const TeamDashboardPage = observer((props: Props) => {
  const [width,] = useWindowResize();
  const params = useParams<TeamDashboardPageParams>();
  const router = useHistory();
  const store = useStore();
  const state = store.teamDashboardPage;
  const source = state.jobCatalogSource;

  const { pathname } = useLocation();
  const link = BackRoutes[Routes.teamUpload()].route({ currentPath: pathname, teamId: state.teamId || undefined });
  const { isLoading } = state;
  const showLoadMask = isLoading;

  useEffect(() => {
    const pageSize: number = resolutionBasedPageSize(width, true);
    state.setPagesize(pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);


  useEffect(() => {
    state.mounted(params);
    return () => state.unmounted();
  }, [state, params]);

  const { team } = state;

  if (state.error) return <ErrorPage message={state.error.message} code={state.error.code} />;

  const pageTitleLocation = state.team?.name ? ('- ' + state.team?.name) : '';

  return (
    <MasterPage
      className="team-dashboard-page catalog-page with-padding"
      showLoadMask={showLoadMask}
      searchBar={
        <SearchBar
          key="team-search"
          teamId={state.teamId}
          size="small"
          defaultValue=""
        />
      }>
      <Helmet>
        <title>{`CLIPr ${pageTitleLocation}`}</title>
      </Helmet>

      <header className="page-header">
        <div className="flex-left">
          <TeamHeading model={state.team} />
          <div className="divider" />
          <span className="filter-label">Members:</span>
          <TeamMemberSummary
            onClick={(evt) => state.handleTeamMatesModal()}
            model={state.team}
          />
          <div className="divider" />
          <div className="page-filters">
            <span className="filter-label">Filter by:</span>
            <FilterFieldButton
              label={source.getVideoTypeFilterLabel() || 'Type'}
              selected={!!source.getVideoTypeFilterValue()}>
              <MenuItem
                model={{ value: 'All', label: 'All' }}
                onClick={() => source.setVideoTypeFilterValue(null)}
                isSelected={!source.getVideoTypeFilterValue()}
              />
              {JobVideoTypeList.map((type, index) => (
                <MenuItem
                  key={index}
                  model={type}
                  onClick={() => source.setVideoTypeFilterValue(type.value)}
                  isSelected={source.getVideoTypeFilterValue() === type.value}
                />
              ))}
            </FilterFieldButton>

            <div className="divider"></div>

            <span className="filter-label">Sort by:</span>

            <SortFieldButton label="Title" name="title" source={source} />

            <SortFieldButton
              label="Upload Date"
              name="createdAt"
              source={source}
            />

            <SortFieldButton
              label="Length"
              name="durationInMs"
              source={source}
            />

            {team?.displaySortOrder &&
              <SortFieldButton
                label="Sort Order"
                name="sortOrder"
                source={source}
              />}
          </div>
        </div>

        <div className="team-job-search">
          <div className="flex-right gap4">
            {team?.hasPermission('EditTeam') &&
              <NavLink
                className="btn btn-core btn-icon medium btn icon-btn large horizontal"
                to={Routes.teamLiveStreamQueue(state.teamId!)}>
                <span className="icon">
                  <LiveQueueSVGIcon />
                </span>
              </NavLink>}

            {state.team?.hasPermission('ViewAnalytics') && (
              <NavLink
                className="btn btn-core btn-icon medium btn icon-btn large horizontal"
                to={Routes.teamAnalytics(state.teamId!)}>
                <span className="icon">
                  <AnalyticsSVGIcon />
                </span>
              </NavLink>
            )}
            <MenuButton
              className="btn icon-btn large"
              svgIcon={<SettingsSVGIcon />}>
              {team?.hasPermission('ViewTeam') && !team?.isPublic && (
                <MenuItem
                  model={{
                    value: 'Manage Library Members',
                    label: 'Manage Library Members',
                  }}
                  onClick={(evt) => state.handleTeamMatesModal()}
                // disabled={team?.isPublic}
                />
              )}
              {team?.hasPermission('EditTeam') && (
                <MenuItem
                  model={{
                    value: 'Library settings',
                    label: 'Library settings',
                  }}
                  onClick={() =>
                    router.push(Routes.teamSettings(state.teamId!))
                  }
                />
              )}

              <MenuItem
                model={{
                  value: 'Leave Library',
                  label: 'Leave Library',
                }}
                onClick={() =>
                  state.dispatch('openLeaveTeamWindow', {
                    teamId: state.teamId!,
                  })
                }
              />
            </MenuButton>

            {team?.hasPermission('CreateJob') && (
              <div className="team-upload">
                <Button
                  route={link}
                  className="upload-btn secondary large"
                  icon={<UploadSVGIcon />}
                  label="Upload Video"
                />
              </div>
            )}
          </div>
        </div>
      </header>

      <TagSection model={state.tagSectionState} />

      <JobCardCatalog
        model={state.jobCatalog}
        source={state.jobCatalogSource}
        teamId={state.teamId || undefined}
      />
    </MasterPage>
  );
});

export default TeamDashboardPage;