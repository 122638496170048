import '../authPages.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { BasicPage, Link } from '../../../components/layout';
import { LinkExpiredPageState } from './linkExpiredPageState';
import { CliprLogoSVGIcon, LoadMask } from '../../../components';
import { ButtonControlled, Feedback } from '../../../components/input';
import Routes from '../../../routes';

type Props = {
  model: LinkExpiredPageState
}

export const LinkExpiredPage = observer(({
  model
}: Props) => {

  const { form } = model;
  const { reply } = form;

  if (reply) {

    return (
      <BasicPage
        className="forgot-password-page auth-page dock"
        showHeader={false}>

        <div className="auth-splash">
          <div className="auth-container">
            <div className="auth-panel">
              <header className="auth-header">
                <div className="clipr-logo">
                  <CliprLogoSVGIcon />
                </div>
                <h1 className="vpad2 bold">New link sent</h1>
                <h2 className="vpad2">Please check your email</h2>
                <p className="text-center">We sent an email to <strong>{reply?.destination || form?.username || 'the provided email'}</strong> which contains a link to reset your CLIPr password.</p>
              </header>

              <p className="text-center vpad4">
                <span>Go back and</span>
                <Link to={Routes.login()} className="bold">Sign in</Link>
                <span>when you're done</span>
              </p>
            </div>
          </div>

          <div className="link-expired-poster auth-poster" />
        </div>
      </BasicPage >
    );
  }

  return (
    <BasicPage
      className="link-expired-page auth-page dock"
      showHeader={false}>

      <div className="auth-splash">
        <div className="auth-container">
          <div className="auth-panel">
            <header className="auth-header">
              <div className="clipr-logo">
                <CliprLogoSVGIcon />
              </div>
              <h1 className="vpad2 bold">Link expired</h1>
              <h2 className="vpad10">Your link to create an account has expired. Would you like to send a new link to {form.username || ' your email'}?</h2>
            </header>

            <form id="linkExpiredForm"
              className="auth-form"
              onSubmit={form.handleSubmit}>

              <div className="row">
                <Feedback id="linkExpiredForm_error"
                  model={form.formGroup}
                  className="text-center"
                  data-error-code={form.error?.type} />
              </div>

              <div className="row btn-group fill vpad4">
                <ButtonControlled
                  id="linkExpiredForm_submit"
                  model={form.submitButton}
                  className="submit-btn primary large"
                  type="submit"
                  label="Send new link" />
              </div>

              <div className="text-center vpad4">
                <p>Still need help?</p>
                <a href="mailto:support@clipr.ai" className="bold">Contact CLIPr support</a>
              </div>
            </form>
            <LoadMask
              className="partial"
              visible={form.formGroup.isSubmitting}
              spinner="dots5" />
          </div>
        </div>

        <div className="link-expired-poster auth-poster" />
      </div>
    </BasicPage>
  );
});