import { action, computed, makeObservable, observable } from 'mobx';
import { StoreNode } from '../../store';
import { Store } from '../../store/store';

declare global {
  interface Document {
    webkitFullscreenElement?: Element;
    webkitExitFullscreen?: () => Promise<void>;
  }

  interface Element {
    webkitRequestFullscreen?: () => Promise<void>;
  }
}

/**
 * Cross-browser, observable object for managing the fullscreen in the app.
 */
export class FullscreenManager
  extends StoreNode {

  readonly nodeType: 'FullscreenManager' = 'FullscreenManager';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.init();
  }

  @observable
  fullscreenElement: Element | null = null;

  @computed
  get isFullscreen() {
    return !!this.fullscreenElement;
  }

  get isEnabled(): boolean {
    return document.fullscreenEnabled ?? false;
  }

  /**
   * Cross-browser, non-observable getter for accessing the fullscreenElement from the document.
   * If you want an observable property, use `fullscreenElement`.
   */
  getFullscreenElement(): Element | null {
    return (
      document.fullscreenElement ??
      document.webkitFullscreenElement ??
      null);
  }

  @action
  private init() {
    document.addEventListener('fullscreenchange',
      this.handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange',
      this.handleFullscreenChange);
  }

  @action
  dispose() {
    document.removeEventListener('fullscreenchange',
      this.handleFullscreenChange);
    document.removeEventListener('webkitfullscreenchange',
      this.handleFullscreenChange);

    this.fullscreenElement = null;
    this.isDisposed = true;
  }

  async exitFullscreen() {

    if (document.exitFullscreen)
      await document.exitFullscreen();

    else if (document.webkitExitFullscreen)
      await document.webkitExitFullscreen(); // still not stable in safari after 10 years
  }

  async requestFullscreen(elem: Element) {

    if (elem.requestFullscreen)
      await elem.requestFullscreen();

    else if (elem.webkitRequestFullscreen)
      await elem.webkitRequestFullscreen(); // safari is the new IE
  }

  /**
   * If the page is not in fullscreen, it will enter fullscreen using the provided Element.
   * If the page is already in fullscreen, the result depends on the `exitIfAny` argument:
   * - if `exitIfAny` set to true then it will exit fullscreen
   * - otherwise the method will exit fullscreen if the active element is the same as the provided one,
   *  and switch to the provided element if it's different from the current fullscreen element.
   * If the provided element is `null`, the method will just exit fullscreen.
   */
  async toggleFullscreen(elem: Element | null, exitIfAny = false) {
    if (
      !this.isEnabled &&
      this.store.widgetService.isWidgetMode) {
      console.warn('Please add the allow="fullscreen" attribute to the iframe element used by the embedded content.')
      return;
    }

    if (!elem)
      return await this.exitFullscreen();

    if (this.isFullscreen) {
      if (exitIfAny) {
        await this.exitFullscreen();
      } else {
        if (this.getFullscreenElement() === elem)
          await this.exitFullscreen();
        else
          await this.requestFullscreen(elem);
      }
    } else {
      await this.requestFullscreen(elem);
    }
  }

  async ensureFullscreen(elem: Element | null) {
    if (!elem)
      return;

    if (this.getFullscreenElement() !== elem)
      await this.requestFullscreen(elem);
  }

  async ensureNotFullscreen() {
    if (this.getFullscreenElement())
      await this.exitFullscreen();
  }

  @action
  private handleFullscreenChange = (evt: Event) => {
    this.fullscreenElement = this.getFullscreenElement();
  }
}