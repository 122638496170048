import clamp from 'lodash/clamp';
import isURL from 'validator/lib/isURL';
import { isIterable } from './iterable';
import { isFiniteNumber } from './number';
import { isNonEmptyString } from './string';
import { Maybe } from './types';

const BOOLEAN_TRUTHY_VALUES = [
  '1',
  't',
  'true',
  'y',
  'yes'
];

const BOOLEAN_FALSY_VALUES = [
  '0',
  'f',
  'false',
  'n',
  'no'
];

export function readStringFromUrlValue(val?: Maybe<string>): string | null {
  if (!isNonEmptyString(val))
    return null;
  return val;
}

export function readIntFromUrlValue(val?: Maybe<string>): number | null {
  if (!isNonEmptyString(val))
    return null;

  let num = parseInt(val ?? '');
  if (Number.isSafeInteger(num))
    return num;
  return null;
}

export function readUIntFromUrlValue(val?: Maybe<string>): number | null {

  let time = readIntFromUrlValue(val);
  if (isFiniteNumber(time))
    return clamp(time, 0, time);
  return null;
}

export function readFloatFromUrlValue(val?: Maybe<string>): number | null {
  if (!isNonEmptyString(val))
    return null;

  let num = parseFloat(val ?? '');
  if (Number.isFinite(num))
    return num;
  return null;
}

export function readBooleanFromUrlValue(val?: Maybe<string>): boolean | null {
  if (!isNonEmptyString(val))
    return null;

  if (isNonEmptyString(val)) {
    val = val.toLowerCase();
    if (BOOLEAN_TRUTHY_VALUES.includes(val))
      return true;
    if (BOOLEAN_FALSY_VALUES.includes(val))
      return false;
  }

  // either the param is not a string or it is not a valid boolean-ish string
  return null;
}


export type URLValueReadFunc<T = string> = (val?: Maybe<string>) => T | null;

export type URLQueryParamKey<TKey extends string = string> = TKey | Iterable<TKey>;
export type URLQueryParamsSource = ConstructorParameters<typeof URLSearchParams>[0];

export const DEFAULT_ARRAY_SEPARATOR = /[,;]/;

export function queryParamsToString(queryParams: Partial<URLQueryParamsSource>) {
  // @ts-ignore
  return (new URLSearchParams(queryParams))
    .toString();
}

/** 
 * Works almost similar to the `URLSearchParams` constructor,
 * with the two differences being that: 
 * 1. if the query is already a `URLSearchParams`, it will be returned as is,
 *    rather than cloned into a new object
 * 2. `null` is also supported as a argument
 */
export function getQueryParams(query?: Maybe<URLQueryParamsSource>): URLSearchParams {
  if (query instanceof URLSearchParams)
    return query;

  if (!query)
    return new URLSearchParams();

  return new URLSearchParams(query);
}

export function readStringQueryParam(query: URLQueryParamsSource, key: URLQueryParamKey): string | null {
  return readValueFromQueryParams(query, key);
}

export function readIntQueryParam(query: URLQueryParamsSource, key: URLQueryParamKey): number | null {
  const valStr = readValueFromQueryParams(query, key);
  if (valStr)
    return readIntFromUrlValue(valStr);
  return null;
}

export function readUIntQueryParam(query: URLQueryParamsSource, key: URLQueryParamKey): number | null {
  const valStr = readValueFromQueryParams(query, key);
  if (valStr)
    return readUIntFromUrlValue(valStr);
  return null;
}

export function readFloatQueryParam(query: URLQueryParamsSource, key: URLQueryParamKey): number | null {
  const valStr = readValueFromQueryParams(query, key);
  if (valStr)
    return readFloatFromUrlValue(valStr);
  return null;
}

export function readBooleanQueryParam(query: URLQueryParamsSource, key: URLQueryParamKey): boolean | null {
  const valStr = readValueFromQueryParams(query, key);
  if (valStr)
    return readBooleanFromUrlValue(valStr);
  return null;
}

export function readValueFromQueryParams(query: URLQueryParamsSource, key: URLQueryParamKey): string | null {

  query = getQueryParams(query);
  let paramNames: string[] = [];
  if (isNonEmptyString(key))
    paramNames = [key];
  else if (isIterable(key))
    paramNames = [...key];

  for (const name of paramNames) {
    const val = query.get(name);
    if (isNonEmptyString(val))
      return val;
  }

  return null;
}

export function isValidUrl(url: string | null): boolean {

  if (!url)
    return false;
  try {
    new URL(url);
  } catch (_) {
    return false;
  }
  return true;
}

export function checkLinkedInUrlValidity(link: string): boolean {
  const isValidUrl = isURL(link || '', { protocols: ['http', 'https', 'ftp', 's3'] });
  const isLinkedInUrl = !!link.match(/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:linkedin\.com))/gi);
  return isValidUrl && isLinkedInUrl;
}
