import './teamWidget.scss';

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { TeamWidgetState } from './teamWidgetState';
import { JobCardCatalog } from '../../components/media';
import { SearchBar } from '../../components/search';
import { WidgetErrorPage } from '../../pages/error';
import { BasicPage, LiveQueueSVGIcon, SettingsSVGIcon } from '../../components';
import { JobListCatalog } from '../../components/media/jobListCatalog';
import { View } from '../../services/ui/utils';
import { Routes } from '../../routes';
import { PageChunks } from '../../components/layout/pageChunks';
import { JobCarouselCatalog } from '../../components/media/jobCarouselCatalog';
import { useRouteContext } from '../../routes/routeHooks';
import { AuthFlowResponseType } from '../../services/auth/authFlowSchema';
import { AuthFlowResponseInterpreter } from '../../components/auth';
import { WidgetProfileCard } from '../../components/widgets/widgetProfileCard';
import { useAuthContext } from '../../services/auth/authHooks';
import { WidgetInlineAuthFlowMask } from '../../components/widgets/widgetInlineAuthFlowMask';

import { TRACE } from '../../core/debug/debugMacros';

type Props = {
  model: TeamWidgetState;
};

export const TeamWidget = observer(({
  model
}: Props) => {

  TRACE(model, `render()`);

  const routeContext = useRouteContext();
  useEffect(() => {
    model.mounted(routeContext);
    return () =>
      model.unmounted();
  }, [model, routeContext]);

  const authContext = useAuthContext();
  if (model.error) {
    return (
      <WidgetErrorPage
        error={model.error}
        showBackButton={authContext?.allowActions}
        backButtonRoute={model.handleErrorBackButtonRoute} />
    );
  }

  const { view, logo } = model.store.uiService;
  if (model.isLoading) {
    return (
      <BasicPage
        showWatermark={!logo}
        showLoadMask />);
  }

  const { inlineAuthFlowController } = model;
  const { response: flowResponse } = inlineAuthFlowController;
  if (flowResponse) {
    switch (flowResponse.responseType) {
      case AuthFlowResponseType.Authorized:
      case AuthFlowResponseType.AuthorizedAsAnonymous:
      case AuthFlowResponseType.Deauthorized:
        break;

      default:
        TRACE(model, `No inline response available, using the flow interpreter`, { flowResponse });
        return <AuthFlowResponseInterpreter response={flowResponse} />
    }
  }

  const coverImage = model.team?.coverURL || undefined;
  const showCover = (model.team?.showCover && !!coverImage && view !== View.Carousel) ?? false;

  const logoImage = model.team?.logoURL || undefined;
  const showLogo = (model.team?.showLogo && !!logoImage) ?? false;

  const heroClassName = classNames('catalog-hero', {
    'with-cover': showCover,
    'with-logo': !showCover && showLogo,
    hidden: !showCover && !showLogo,
  });

  const dashboardClassName = classNames('team-dashboard-widget widget catalog-page', {
    'scrollable': showCover,
    'carousel': view === View.Carousel,
    'with-padding': view !== View.Carousel
  });

  return (
    <div
      className={dashboardClassName}>

      {view !== View.Carousel && (
        <>
          <div className={heroClassName}>
            {showCover && (
              <img src={coverImage} alt="team-hero-cover" className="hero-image" />
            )}
            {showLogo && (
              <div className="team-logo">
                <img src={logoImage} alt="logo" />
              </div>
            )}
          </div>

          <div className="catalog-header">
            <div className="team-name">{model.showTeamName && model.teamName}</div>
            <div className="team-controls">
              {model.team?.hasPermission('EditTeam') && (
                <div className="nav-controls">
                  <NavLink
                    className="btn btn-core btn-icon medium btn icon-btn large horizontal"
                    to={Routes.teamLiveStreamQueueWidget(model.teamId!)}>
                    <span className="icon">
                      <LiveQueueSVGIcon />
                    </span>
                  </NavLink>

                  <NavLink
                    className="btn btn-core btn-icon medium btn icon-btn large horizontal"
                    to={Routes.teamSettingsWidget(model.teamId!)}>
                    <span className="icon">
                      <SettingsSVGIcon />
                    </span>
                  </NavLink>
                </div>
              )}
              <div className="header-controls">
                <SearchBar
                  key="team-search"
                  teamId={model.teamId}
                  size="small"
                  defaultValue=""
                  metadata={model.metadataFilter}
                  tags={model.tagsFilter} />

                {model.showProfile &&
                  <WidgetProfileCard
                    showHelp={false}
                    onLoginButtonClick={model.handleLoginButtonClick}
                    onLogoutButtonClick={model.handleLogoutButtonClick} />
                }
              </div>
            </div>
          </div>
        </>
      )}

      {
        view === View.Grid && (
          <JobCardCatalog
            model={model.jobCatalog}
            source={model.jobCatalogSource}
            teamId={model.teamId || undefined} />
        )
      }

      {
        view === View.List && (
          <JobListCatalog
            model={model.jobCatalog}
            source={model.jobCatalogSource}
            teamId={model.teamId || undefined} />
        )
      }

      {
        view === View.Carousel && (
          <JobCarouselCatalog
            model={model.jobCatalog}
            source={model.jobCatalogSource}
            teamId={model.teamId || undefined} />
        )
      }

      <PageChunks />

      <WidgetInlineAuthFlowMask
        controller={inlineAuthFlowController} />
    </div >
  );
});

export default TeamWidget;