import './textEditor.scss'

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import FroalaEditor from 'react-froala-wysiwyg';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/plugins.pkgd.min.css';
import 'froala-editor/css/themes/gray.min.css';

// Import individual plugins
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/inline_style.min.js';
import 'froala-editor/js/plugins/line_breaker.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/print.min.js'
import 'froala-editor/js/plugins/save.min.js'
import { TextEditorState } from './textEditorState';
import { Config } from '../../config';

type Props = {
  model: TextEditorState;
  data?: string | null;
  fileName?: string | null;
  height?: number | null;
  onSave?: (content: string) => void;
  onChange?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

export const TextEditor = observer((props: Props) => {
  const { model, data, onSave } = props;

  useEffect(() => {
    model.mounted(data ?? null);
    return () => {
      model.unmounted();
    };
  }, [model, data]);

  const handleGetPDF = () => {
    model.exportToPDF(props.fileName);
  }

  const handleSave = () => {
    onSave?.(model.editorModel as string ?? '');
  }

  const config = {
    key: Config.vendor.textEditor.apiKey,
    pluginsEnabled: ['save', 'align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'emoticons', 'entities', 'fontFamily', 'fontSize', 'fullscreen', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'table', 'url', 'wordPaste', 'getPDF', 'print'],
    toolbarButtons: ['save', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|', 'fontFamily', 'fontSize', 'color', 'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', 'insertHR', '-', 'insertLink', 'insertTable', 'emoticons', 'specialCharacters', 'insert', '|', 'undo', 'redo', 'clearFormatting', 'selectAll', 'html', 'getPDF', 'print'],
    toolbarButtonsXS: ['save', 'bold', 'italic', 'underline', 'strikeThrough', '|', 'fontFamily', 'fontSize', 'color', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'insertLink', 'insertTable', 'undo', 'redo'],
    getPDF: {
      filename: props.fileName ?? 'document.pdf',
      orientation: 'portrait',
      size: 'A4',
      margin: [10, 10, 10, 10],
      quality: 1,
      width: '800',
      height: '1000'
    },
    events: {
      initialized: function () {
        const getPdfButton = document.querySelector(`[data-cmd=getPDF]`);
        const getSaveButton = document.querySelector(`[data-cmd=save]`);
        getPdfButton?.addEventListener('click', handleGetPDF);
        getSaveButton?.addEventListener('click', handleSave);
        const span = document.createElement('span').innerHTML = 'Save';
        getSaveButton?.prepend(span);
        
        return () => {
          getPdfButton?.removeEventListener('click', handleGetPDF);
          getSaveButton?.removeEventListener('click', handleSave);
        };
      }
    }
  };

  // TODO: Fix workaround
  if (!model.isMounted && !!data) {
    return <></>
  }

  return (
      <FroalaEditor 
        model={model.editorModel}
        onModelChange={(editorModel: string | object | null) => 
          model.setModel(editorModel)}
        tag='textarea'
        config={config} />
      );
})
