import React from 'react';
import { Duration } from 'luxon';
import { TrainerTimelineState } from '../trainerTimelineState.js';
import { observer } from 'mobx-react-lite';

type LabelName = 
  'minute' |
  '30seconds' |
  '10seconds' |
  '5seconds' |
  'second';

  type LabelGroup = {
    spacing: number,
    value: (i: number) => number
  }

const LabelGroups: { [key in LabelName]: LabelGroup } = {
  'minute': {
    spacing: 60,
    value: i => i * 60
  },
  '30seconds': {
    spacing: 30,
    value: i => i * 30
  },
  '10seconds': {
    spacing: 10,
    value: i => i * 10
  },
  '5seconds': {
    spacing: 5,
    value: i =>i * 5
  },
  'second': {
    spacing: 1,
    value: i => i * 1
  }
}

type Props = {
  unitWidth: number,
  model: TrainerTimelineState
}

export const TimelineRuler = observer(({
  model,
  unitWidth,
}: Props) => {

  const factor = 10 / unitWidth;
  const labelGroups = [];

  for (const groupKey in LabelGroups) {
    const group = LabelGroups[groupKey as LabelName];
    const { spacing, value } = group;
    const count = Math.floor(model.duration / spacing);

    if (unitWidth / 120 > 1 / spacing) {
      const elements = Array(count).fill(null).map((x, i) => {

        return (
          <g key={i} className="minute-label time-label" transform={`translate(${i * unitWidth * spacing + 10}, 35)`}>
            <text>
              {/* TODO: replace with luxon */}
              {Duration.fromMillis(value(i) * 1000).toFormat('hh:mm:ss')}
            </text>
          </g>
        );
      });

      const elGroup = (
        <g className="time-label-group" key={groupKey}>
          {elements}
        </g>
      );

      labelGroups.push(elGroup);
    }
  }

  return (
    <div className="surface-ruler" 
      ref={model.rulerProxy.ref} 
      onClick={model.handleRulerClick}>
      
      <svg className="timeline-ruler-svg" style={{ width: `100%`, height: '100%' }}>
        <defs>
          <pattern id="smallDash" width={10} height="50" patternUnits="userSpaceOnUse">
            <line x1={0} y1={0} x2={0} y2={8} strokeWidth={factor} stroke="white" />
          </pattern>

          <pattern id="mediumDash" width={10 * 2} height="50" patternUnits="userSpaceOnUse">
            <rect x={5} width={10 * 2} height="50" fill="url(#smallDash)" />
            <line x1={0} y1={0} x2={0} y2={10} strokeWidth={factor} stroke="white" />
          </pattern>

          <pattern id="largeDash" width={10 * 30} height="50" patternUnits="userSpaceOnUse">
            <rect x={5} width={10 * 29} height="50" fill="url(#mediumDash)" />
            <line x1={0} y1={0} x2={0} y2={24} strokeWidth={factor} stroke="white" />
          </pattern>

          <pattern id="dashedSection" width={10 * 60} height="50" patternUnits="userSpaceOnUse">
            <rect x={5} width={10 * 59} height="50" fill="url(#largeDash)" />
            <line x1={0} y1={0} x2={0} y2={50} strokeWidth={factor} stroke="white" />
          </pattern>
        </defs>

        <g className="dashes" transform={`scale(${unitWidth / 10}, 1)`}>
          <rect width={model.duration * 10} height="50" x="0" fill="url(#dashedSection)" />
        </g>

        <g className="labels">
          {labelGroups}
        </g>
      </svg>
    </div>
  );
});