import './media.scss';

// @ts-ignore
import CSSVariables from './media.scss';

import React, { CSSProperties, ReactNode, useRef, useState } from 'react';
import classNames from 'classnames';
import { LocationDescriptor } from 'history';
import { observer } from 'mobx-react-lite';

import Routes, { BackRoutes } from '../../routes';
import { Maybe } from '../../core';
import { JobModel } from '../../entities';
import { MenuItem, sameWidth } from '../input/menuItems';
import { Link } from '../layout';
import { DotSpinner } from '../loader';
import { MenuButton } from '../menu';
import { IngestingSVGIcon, TextInputErrorSVGIcon, TextInputWarningSVGIcon } from '../svg';
import { getMetadataInputLabel, JobMetadataField } from '../../entities/job/jobMetadata';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { usePopper } from 'react-popper';
import { JobTitle } from './jobTitle';
import { JobCardPreview } from './jobCardPreview';
import { useHistory, useLocation } from 'react-router';
import { ellipseText } from '../utils';
import { ToggleLayout } from '../input/toggleInput';
import { sanitizeHtml } from '../../pages/searchResultsPage/util';
import { View } from '../../services/ui/utils';
import { TranscriptFormat } from '@clipr/lib';
import { notifyError } from '../../services/notifications';
import { TranscriptFormatItems } from '../playerTranscripts';

/** The time window in which a Job is considered New and will have a new label. */
const NEW_MARGIN_HOURS = 6; // probably one of the dumbest constant names ever, I'm open to suggestions

type Props = {
  model: JobModel;
  route?: LocationDescriptor;
  aspectRatio?: Maybe<number>;
  teamId?: string;
  isExpandable?: boolean;
  view?: View;
  target?: string;
};

type JobMenuDropdownOptionsProps = {
  model: JobModel;
  teamId?: string;
};

const fadeTimeout = {
  enter: parseInt(CSSVariables.windowEnterTimeout),
  exit: parseInt(CSSVariables.windowExitTimeout),
};

const CardMetadataSectionFields = [
  'startTime',
  'endTime',
  'track',
  'level',
  'featured',
  'publishDate',
  'location'
] as JobMetadataField[];

const isInViewport = (el: HTMLElement) => {
  const rect = el?.getBoundingClientRect();

  if (!rect) return false;

  return rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
};

export const JobCard = observer(({
  model,
  teamId,
  isExpandable = true,
  view,
  target = '_self',
  route = BackRoutes[Routes.userVideo()].route({ id: model.id, teamId }),
  aspectRatio = 9 / 16
}: Props) => {

  const isNew = model.createdAtDate.diffNow('hours').as('hours') > -NEW_MARGIN_HOURS; // diff will be negative
  const previewStyle: CSSProperties = {
    ['--aspect-ratio' as any]: aspectRatio,
  };

  const { widgetService } = model.store;
  const isWidgetMode = widgetService.isWidgetMode;
  const { metadata } = model;
  const team = (teamId && model.store.teamManager.getTeam(teamId)) || null;
  const allowHover = !model?.store.ui.isTouchBased;

  const menuRef = useRef<HTMLDivElement>(null);

  let linkRoute: LocationDescriptor | null = null;
  if (model.isMediaAccessible && model.isPublished) {
    linkRoute = isWidgetMode
      ? Routes.playerWidget(model.id, {
        teamId
      })
      : route;
  }

  const [isHovered, setHovered] = useState(false);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const isInView = anchorElement && isInViewport(anchorElement);

  const canExpandCard = isExpandable && (model.hasMetadata || model.description) && isInView;;
  const showSession =
    (team && team.visibleMetadataFields?.includes('session')) ||
    (!team && metadata?.session);

  if (showSession && !CardMetadataSectionFields.includes('session'))
    CardMetadataSectionFields.push('session');

  const className = classNames('job-card-new', {
    'carousel-card': view === View.Carousel,
    'transient': model.isTransient,
    'status-not-ingested': !model.status,
    'status-done': model.isDone && model.isPublished && model.isMediaDone,
    'status-is-ingesting': model.isInProgress || model.isMediaProcessing,
    'status-is-analyzing': model.isProcessing,
    'status-is-failed': model.isFailed || model.isMediaFailed,
    'status-is-unpublish': !model.isPublished,
    hovered: isHovered
  });

  const handleMouseEnter = () => {
    // this was left as a safe measure, 
    // as modern plarforms should diferentiate between a mouse action and a pointer
    // but I have no trust left whatsoever
    if (allowHover)
      setHovered(true);
  }

  const handleMouseLeave = () => {
    setHovered(false);
  }

  return (
    <div
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={setAnchorElement as any}
      data-job-id={model.id}
      data-job-status={model.testStatus}>

      <Link
        target={target}
        to={linkRoute as any}
        className="card-preview aspect-ratio-box"
        style={previewStyle}>
        <JobCardPreview 
          teamId={teamId}
          model={model} 
          isNew={isNew} />
      </Link>

      <Link
        target={target}
        to={linkRoute as any}
        className="card-title">
        {model.highlights &&
          <div className="snippet">
            {sanitizeHtml(model.highlights[0].snippets[0])}
          </div>
        }
        {!model.highlights &&
          <JobTitle
            isAudioSource={model.isAudioSource}
            title={model.title} />
        }
      </Link>

      {view !== View.Carousel &&
        <Link
          target={target}
          to={(model.description ? linkRoute : null) as any} className="card-description">
          {ellipseText(model.description, 80)}
        </Link>}

      {view !== View.Carousel &&
        <Link to={null as any} className="card-summary">
          {model.createdAtRelativeLabel}
        </Link>}

      <div className="card-menu" ref={menuRef}>
        {!model.isMediaProcessing &&
          model.hasPermission('UserViewJobMenu') && (
            <MenuButton layout="horizontal">
              <JobMenuDropdownOptions
                model={model}
                teamId={teamId} />
            </MenuButton>
          )}
      </div>

      <TransitionGroup >
        {isHovered && canExpandCard &&
          <CSSTransition
            classNames="card-expand"
            timeout={fadeTimeout}>
            <JobCardExpandedSection
              model={model}
              teamId={teamId}
              anchorElement={anchorElement}
              linkRoute={linkRoute} />
          </CSSTransition>}
      </TransitionGroup>
    </div>
  );
});

export const JobMenuDropdownOptions = observer(({ model, teamId }: JobMenuDropdownOptionsProps) => {
  const isWidgetMode = model.store.widgetService.isWidgetMode;
  const history = useHistory();

  const { pathname, search } = useLocation();
  const trainerPlayerRoute = BackRoutes[Routes.trainerVideo()].route({ id: model.id, teamId, currentPath: search ? pathname + search : pathname });

  const onDownloadTranscript = async (format: string) => {
    const args = {
      format: format as TranscriptFormat
    };

    const [res, err] = await model.apiGetJobTranscript(args);

    if (!res || err) {
      return notifyError(model, 'Transcript download failed.');
    }

    return res;
  }

  if ((
    model.isMediaAccessible ||
    model.isLiveStreamEnded) &&
    model.isPublished) {
    return (
      <>
        {!isWidgetMode &&
          teamId &&
          model?.hasPermission('EditTrainerMoment') &&
          model.isMediaDone && (
            <MenuItem
              isSelected={false}
              model={{ value: 'Open in Trainer Player', label: 'Open in Trainer Player' }}
              onClick={() => {
                history.push(trainerPlayerRoute);
              }}
            />
          )}

        {model.hasPermission('UserEditJob') && (
          <>
            {model.hasPermission('EditVideoDetails') && (
              <MenuItem
                isSelected={false}
                model={{ value: 'Video Details', label: 'Video Details' }}
                onClick={(evt) => {
                  model.dispatch('openVideoDetailsWindow', {
                    jobId: model.id,
                    teamId: teamId,
                    layoutType: 'full',
                  });
                }} />
            )}

            <MenuItem
              isSelected={false}
              model={{ value: 'Rename', label: 'Rename' }}
              onClick={(evt) => {
                model.dispatch('openRenameJobWindow', {
                  page: teamId ? 'Libraries' : 'User',
                  jobId: model.id,
                  title: model.title,
                });
              }} />

            <MenuItem
              isSelected={false}
              model={{ value: 'Update thumbnail', label: 'Update thumbnail' }}
              onClick={(evt) => {
                model.dispatch('openUploadThumbnailWindow', {
                  jobId: model.id,
                });
              }} />

            {model.hasPermission('EditIsPublic') && (
              <MenuItem
                model={{ value: 'Visibility', label: 'Visibility' }}
                isSelected={model.isPublic}
                withToggle
                toggleOptions={{ toggleLayout: ToggleLayout.Buttons, toggledLabel: 'Shareable', notToggledLabel: 'Private' }}
                toggleModel={model.publicToggleModel}
                disabled={model.isPublishing}
                onClick={(evt) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  model.handlePublishVideo();
                }} />
            )}

            {((teamId &&
              model.store.teamManager
                .getTeam(teamId)
                ?.hasPermission('CreateJob')) ||
              model.hasPermission('UserCopyJob')) &&
              model.isDone && (
                <MenuItem
                  isSelected={false}
                  model={{ value: 'Make a copy', label: 'Make a copy' }}
                  onClick={(evt) => {
                    model.dispatch('openCopyJobWindow', {
                      jobId: model.id,
                      teamId: teamId,
                    });
                  }} />
              )}
            {model.store.user?.hasPermission('UserCanAddToTeams') && (
              <MenuItem
                isSelected={false}
                model={{ value: 'Add to library', label: 'Add to library' }}
                onClick={(evt) => {
                  model.dispatch('openAddJobToTeamWindow', {
                    jobId: model.id,
                  });
                }} />
            )}
          </>
        )}
        {(
          (teamId && model.store.teamManager.getTeam(teamId)?.hasPermission('EditTeam')) ||
          (!teamId && model.hasPermission('UserEditJob'))) &&
          model.isMediaDone && (
            <MenuItem
              isSelected={false}
              model={{ value: 'Download original video', label: 'Download original video' }}
              onClick={() => {
                model.dispatch('openDownloadJobWindow', {
                  jobId: model.id,
                  teamId: teamId
                });
              }}
            />
          )}

        <MenuItem
          isSelected={false}
          model={{ value: 'Download transcript', label: 'Download transcript' }}
          onClick={(evt) => {
            model.dispatch('openDownloadTranscriptWindow', {
              message: 'Transcript format',
              onSubmit: (format: string) => onDownloadTranscript(format),
              formatItems: TranscriptFormatItems,
              title: 'Download Transcript'
            });
          }} />

        {model.slideDeckUrl && 
          <MenuItem
            isSelected={false}
            model={{ value: 'Download slides', label: 'Download slides' }}
            onClick={(evt) => {
              model.dispatch('openDownloadJobSlidesWindow', {
              jobId: model.id
              });
            }} />
        }

        {teamId &&
          model.store.teamManager
            .getTeam(teamId)
            ?.hasPermission('RemoveJob') &&
          (!model.ownerTeamId || model.ownerTeamId !== teamId) && (
            <MenuItem
              isSelected={false}
              model={{ value: 'Remove from library', label: 'Remove from library' }}
              onClick={(evt) => {
                model.dispatch('openRemoveJobFromTeamWindow', {
                  jobId: model.id,
                  teamId: teamId,
                });
              }} />
          )}

        {model.hasPermission('DeleteJob') &&
          (model.source.type === 'ExternalLibrary' ? (
            <MenuItem
              isSelected={false}
              model={{
                value: 'Remove from CLIPr',
                label: 'Remove from CLIPr',
              }}
              onClick={(evt) => {
                model.dispatch('openRemoveJobFromCliprWindow', {
                  teamId: teamId,
                  jobId: model.id,
                });
              }} />
          ) : (
            <MenuItem
              isSelected={false}
              model={{ value: 'Delete', label: 'Delete' }}
              onClick={(evt) => {
                model.dispatch('openDeleteJobWindow', {
                  jobId: model.id,
                  teamId: teamId,
                });
              }} />
          ))}
      </>
    );
  } else if (model.isMediaFailed) {

    return model.hasPermission('DeleteJob') ? (
      model.source.type === 'ExternalLibrary' ? (
        <MenuItem
          isSelected={false}
          model={{
            value: 'Remove from CLIPr',
            label: 'Remove from CLIPr',
          }}
          onClick={(evt) => {
            model.dispatch('openRemoveJobFromCliprWindow', {
              teamId: teamId,
              jobId: model.id,
            });
          }} />
      ) : (
        <MenuItem
          isSelected={false}
          model={{ value: 'Delete', label: 'Delete' }}
          onClick={(evt) => {
            model.dispatch('openDeleteJobWindow', {
              jobId: model.id,
              teamId: teamId,
            });
          }} />
      )
    ) : null;
  } else if (!model.isPublished) {

    return (
      <>
        {teamId &&
          model.store.teamManager
            .getTeam(teamId)
            ?.hasPermission('RemoveJob') &&
          (!model.ownerTeamId || model.ownerTeamId !== teamId) && (
            <MenuItem
              isSelected={false}
              model={{ value: 'Remove from library', label: 'Remove from library' }}
              onClick={(evt) => {
                model.dispatch('openRemoveJobFromTeamWindow', {
                  jobId: model.id,
                  teamId: teamId,
                });
              }} />
          )}

        {model.hasPermission('DeleteJob') &&
          (model.source.type === 'ExternalLibrary' ? (
            <MenuItem
              isSelected={false}
              model={{
                value: 'Remove from CLIPr',
                label: 'Remove from CLIPr',
              }}
              onClick={(evt) => {
                model.dispatch('openRemoveJobFromCliprWindow', {
                  teamId: teamId,
                  jobId: model.id,
                });
              }} />
          ) : (
            <MenuItem
              isSelected={false}
              model={{ value: 'Delete', label: 'Delete' }}
              onClick={(evt) => {
                model.dispatch('openDeleteJobWindow', {
                  jobId: model.id,
                  teamId: teamId,
                });
              }} />
          ))}
      </>
    );
  }
  return null;
});

export const CardOverlay = observer(({ model }: { model: JobModel }) => {
  let content: Maybe<ReactNode>;

  const className = classNames('card-overlay', {
    'done': model.isDone && model.isPublished && model.isMediaDone,
    'processing': model.isInProgress || model.isMediaProcessing,
    'analyzing': model.isProcessing,
    'failed': model.isFailed || model.isMediaFailed,
    'unpublish': !model.isPublished,
    'transient': model.isTransient
  });

  // TODO: we need some else ifs here
  // but we need to validate the logic beforehand

  if (model.isMediaProcessing) {
    content = (
      <>
        <IngestingSVGIcon />
        <p>
          <b>CLIPr</b> is <b>ingesting</b> your video.
          <br />
          This will take about half the length <br /> of the video.
        </p>
        <DotSpinner className="small" />
      </>
    );
  }

  if (model.isMediaFailed) {
    content = (
      <>
        <TextInputErrorSVGIcon />
        <p>Failed to ingest video</p>
      </>
    );
  }

  if (!model.isPublished) {
    content = (
      <>
        <TextInputWarningSVGIcon />
        <p>Video is no longer available</p>
        <p className="small">If you think this is an error, please <a href={'mailto:' + process.env.REACT_APP_EMAIL_QA}>Contact Us &#8594;</a></p>
      </>
    );
  }

  if (model.isLiveEnded) {
    content = (
      <>
        <TextInputWarningSVGIcon />
        <p>Live ended. Waiting for manual ingest</p>
      </>
    );
  }

  if (model.isTransient) {
    content = (
      <>
        <IngestingSVGIcon />
        <p>
          <b>CLIPr</b> is <b>preparing</b> your video.
          <br />
          This will take about half the length <br /> of the video.
        </p>
        <DotSpinner className="small" />
      </>
    );
  }

  return <div className={className}>{content}</div>;
});

type ExpandedSectionProps = {
  model: JobModel;
  teamId?: string;
  anchorElement: any;
  linkRoute: any;
}

export const JobCardExpandedSection = observer(({
  model,
  teamId,
  anchorElement,
  linkRoute
}: ExpandedSectionProps) => {

  const { metadata } = model;
  const team = (teamId && model.store.teamManager.getTeam(teamId)) || null;

  const showSession =
    (team && team.visibleMetadataFields?.includes('session')) ||
    (!team && metadata?.session);

  if (showSession && !CardMetadataSectionFields.includes('session'))
    CardMetadataSectionFields.push('session');

  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(anchorElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'absolute',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -84],
        }
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: [],
          flipVariations: false,
          allowedAutoPlacements: []
        },
      },
      sameWidth
    ],
  });

  return (
    <div className="job-card-expanded-section"
      ref={setPopperElement as any}
      style={styles.popper} {...attributes.popper}>

      <Link to={(model.description ? linkRoute : null) as any} className="card-description">
        {model.description}
      </Link>

      {metadata && model.hasMetadata && <Link to={null as any} className="card-metadata">
        {CardMetadataSectionFields.map((item, i) => {
          if ((team && !team.visibleMetadataFields?.includes(item)) || !metadata[item])
            return null;

          return (
            <div className="metadata-block" key={i}>
              <h6>{team?.getMetadataFieldAlias(item) ?? getMetadataInputLabel(item)}</h6>
              <p>{metadata[item]}</p>
            </div>
          );
        })}
      </Link>}

      <Link to={null as any} className="card-summary">
        {model.createdAtRelativeLabel}
      </Link>

      <div className="card-menu">
        {!model.isMediaProcessing &&
          model.hasPermission('UserViewJobMenu') && (
            <MenuButton layout="horizontal">
              <JobMenuDropdownOptions model={model} teamId={teamId} />
            </MenuButton>
          )}
      </div>
    </div>
  )
});