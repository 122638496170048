import { action, makeObservable, observable, runInAction } from 'mobx';
import { Maybe } from '../../core';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { input, InputState } from '../input';

export type ChangeJobOwnerWindowParams = {
  jobId: string
}

export class ChangeJobOwnerWindowState
  extends StoreNode {

  readonly nodeType = 'ChangeJobOwnerWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);

    this.emailInput = input(this, {
      name: 'emailInput',
      placeholder: 'Email address',
      isRequired: true
    });
  }

  readonly window = new WindowState(this.store);
  readonly emailInput: InputState;

  @observable isLoading: boolean = false;

  jobId: Maybe<string>;

  @action
  async init() {
    this.emailInput.clear();
  }

  @action
  async submit() {
    this.isLoading = true;

    this.emailInput.handleSubmit();

    if (this.emailInput.error) {
      this.emailInput.handleSubmitReject();
      this.isLoading = false;
      return;
    }

    const email = this.emailInput.value!;
    const [res] = await this.store.apiService.adminGetProfile({ email });
    const profile = res?.adminGetProfile;

    if (!profile) {
      notifyError(this, `User with email ${email} not found.`);
      this.emailInput.handleSubmitReject();
      this.emailInput.value = null;
      this.isLoading = false;
      return;
    }

    const { id, name, username } = profile!;
    const [, err] = await this.store.apiService.changeJobOwner({
      args: {
        jobId: this.jobId || '',
        userId: id
      }
    });

    runInAction(async () => {
      if (err) {
        notifyError(this, 'Could not change ownership.');
        this.emailInput.handleSubmitReject();
      } else {
        notifySuccess(this, `Ownership changed to ${name || username}.`);
        this.emailInput.handleSubmitResolve();
        await this.store.trainerDashboardPage.refreshJobs();
        await this.store.teamDashboardPage.refreshJobs();
      }

      this.handleSubmitFinally();
    });
  }

  handleSubmitFinally() {
    runInAction(() => {
      this.isLoading = false;
      this.close();
    })
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open(params: ChangeJobOwnerWindowParams) {
    this.dispatch('Overlays', 'openWindow', { name: 'ChangeJobOwnerWindow' });

    this.jobId = params.jobId;

    this.init();
  }

  @action
  close() {
    this.jobId = null;

    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}