import './layout.scss';
import '../menu/menu.scss';
import '../input/dropdownInput-v1-1.scss';

import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { HTMLDivProps, Maybe, RouteValue } from '../../core';
import { useStore } from '../../store/storeHooks';
import { UserAvatarSVGIcon } from '../svg';

interface IUserAvatar {
  displayName?: Maybe<string>,
  pictureURL?: Maybe<string>
}

type Props = HTMLDivProps<{
  model?: Maybe<IUserAvatar>;
  route?: Maybe<RouteValue>;
  withDropdown?: Boolean;
}>;

/**
 * Displays the avatar for the provided User.
 * If no model is provided, the current authenticated user will be used.
 */
export const UserAvatar = observer(({
  model,
  route,
  className,
  withDropdown,
  ...props
}: Props) => {

  const store = useStore();
  const { auth } = store;
  const userProfile = auth.userProfile;

  if (!model && userProfile) model = userProfile;
  if (!model) return null;

  const picture = model.pictureURL;

  className = classNames(
    'user-avatar avatar-core',
    {
      blue: !picture,
    },
    className
  );

  return (
    <div className={className} onClick={props.onClick}>
      {picture ? (
        <img className="profile-picture" src={picture} alt="avatar" />
      ) : (
        <UserAvatarSVGIcon />
      )}
    </div>
  );
});
