import { ConfigAutoplayPolicy, GlobalConfig } from './configSchema';

/** Global config for CLIPr App and widgets. */
export const Config: GlobalConfig = {
  auth: {
    minPasswordLength: 8,

    storageIdTokenExpiresKey: 'auth.idToken.expires',
    storageIsAuthenticatedKey: 'auth.isAuthenticated',
    storageForceConnectionKey: 'auth.forceConnection',

    connections: {

      'Default': {
        enabled: true,
        auth0: {
          connection: 'Password-Authentication'
        }
      },

      'Google': {
        enabled: true,
        showSocialLoginButton: true,
        auth0: {
          connection: 'google-oauth2'
        }
      },

      'Facebook': {
        enabled: true,
        showSocialLoginButton: true,
        auth0: {
          connection: 'facebook'
        }
      },

      'LinkedIn': {
        enabled: true,
        showSocialLoginButton: true,
        auth0: {
          connection: 'linkedin'
        }
      },

      'Twitter': {
        enabled: true,
        showSocialLoginButton: true,
        auth0: {
          connection: 'twitter'
        }
      },

      'Microsoft': {
        enabled: true,
        showSocialLoginButton: true,
        auth0: {
          connection: 'windowslive'
        }
      },

      'External:Poly': {
        enabled: true,
        allowForcing: true,
        auth0: {
          connection: process.env.REACT_APP_AUTH0_EXTERNAL_POLY_CONNECTION!
        }
      }
    },

    auth0: {
      domain: process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN_NAME!,
      clientId: process.env.REACT_APP_AUTH0_WEB_APP_CLIENT_ID!,
      responseType: 'token id_token',
      defaultConnection: 'Default',
      redirectUrlDataMaxOutputLength: 512,
      redirectUrlDataKey: 'data'
    }
  },

  player: {
    liveStreamEndedMessageDelta: 60,
    autoplayPolicy: ConfigAutoplayPolicy.Standard
  },

  routing: {
    maxUrlLength: 2000,
    storageKeyPrefix: 'routing.',
    redirect: {
      lastPrivatePathKey: 'rdlpp',
      authFlowKey: 'rdaf'
    }
  },

  liveFeedPage: {
    activeStreamTooltipMessage: 'Select "Backup feed" if your Primary feed is running less efficiently than expected.',
  },

  speakerId: {
    autoTriggerSpeakerIdFlow: (!process.env.REACT_APP_SPEAKER_TEAM_SCOPE_ENABLE || process.env.REACT_APP_SPEAKER_TEAM_SCOPE_ENABLE === 'undefined'),
    speakerIdConfirmationMessage:
    (!process.env.REACT_APP_SPEAKER_TEAM_SCOPE_ENABLE || process.env.REACT_APP_SPEAKER_TEAM_SCOPE_ENABLE === 'undefined') ?
    `You are about to identify this person in this video, adding their name to CLIPr's global database of speakers. This will help CLIPr identify speakers automatically in future videos.` :
    'You are about to identify this person in this video. This will help CLIPr identify speakers automatically in future videos.'
  },

  storage: {
    testKey: 'STORAGE_TEST_KEY',
    testValue: 'STORAGE_TEST_VALUE'
  },

  vendor: {
    react: {
      useStrictMode: false
    },

    googleImaSdk: {
      url: 'https://imasdk.googleapis.com/js/sdkloader/ima3.js'
    },

    textEditor: {
      apiKey: 'zEG4iE3C7B5B4F6F5F4aIVLEABVAYFKc2Cb1MYGH1g1NYVMiG5G4E3G3A1C8B6D4F4B3=='
    }
  },

  debug: {

    // IMPORTANT! If you're reviewing this and it's set to `true`, 
    // notify the person who left it like this to fix it (probably Tibi) 
    // because it should always be set to `false` (or `process.env.NODE_ENV !== 'production'`) before merging 
    // we will move this to env variables at some point

    enable: false,
    trace: false,
    traceFilter: []
  }
}