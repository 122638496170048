import { action, makeObservable, observable, runInAction } from 'mobx';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';

type WindowProps = { 
  jobId: string, 
  teamId: string|null, 
  isPublished: boolean 
}
export class JobViewStatusWindowState
  extends StoreNode {

  readonly nodeType = 'JobViewStatusWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);

  @observable isLoading: boolean = false;

  @observable jobId: string | null = null;
  @observable teamId: string | null = null;
  @observable isPublished: boolean | null = null;

  @action
  async submit() {
    this.isLoading = true;

    const [, err] = await this.store.apiService.updateJob({
      args: {
        id: this.jobId || '',
        isPublished: !this.isPublished
      }
    });

    runInAction(async () => {
      if (err) {
        notifyError(this, 'The video could not be updated.');
      } else {
        notifySuccess(this, `This video was ${!this.isPublished ? 'published' : 'unpublished'}.`);
        await this.store.trainerDashboardPage.refreshJobs();
      }

      this.handleSubmitFinally();
    })
  }

  handleSubmitFinally() {
    runInAction(() => {
      this.isLoading = false;
      this.close();
    })
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open({ jobId, teamId, isPublished }: WindowProps) {
    this.dispatch('Overlays', 'openWindow', { name: 'JobViewStatusWindow' });

    this.jobId = jobId;
    this.teamId = teamId;
    this.isPublished = isPublished;
  }

  @action
  close() {
    this.jobId = null;
    this.teamId = null;
    this.isPublished = null;

    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}