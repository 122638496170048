/**
 * Represents the casing style of a string.
 * This enum is a mix of context dependent members (Unchanged, Default, Auto),
 * native options (LowerCase, LocaleLowerCase, UpperCase, LocaleUpperCase), and
 * casings that are implemented by `change-case`.
 * @see https://github.com/blakeembrey/change-case#change-case
 */
export enum StringCase {

  /** For transformations, the casing will be left unchanged. */
  Unchanged = 'Unchanged',
  /** For transformations, the default casing will be selected depending on the context. */
  Default = 'Default',
  /** For transformations, the most appropriate casing will be selected depending on the context. */
  Auto = 'Auto',

  LowerCase = 'LowerCase',
  LocaleLowerCase = 'LocaleLowerCase',
  UpperCase = 'UpperCase',
  LocaleUpperCase = 'LocaleUpperCase',

  /** Transform into a string with the separator denoted by the next word capitalized. */
  CamelCase = 'CamelCase',

  /** Transform into a space separated string with each word capitalized. */
  CapitalCase = 'CapitalCase',
  
  /** Transform into upper case string with an underscore between words. */
  ConstantCase = 'ConstantCase',
  
  /** Transform into a lower case string with a period between words. */
  DotCase = 'DotCase',
  
  /** Transform into a dash separated string of capitalized words. */
  HeaderCase = 'HeaderCase',
  
  /** Transform into a lower cased string with spaces between words. */
  NoCase = 'NoCase',
  
  /** Transform into a lower cased string with dashes between words. */
  ParamCase = 'ParamCase',
  
  /** Transform into a string of capitalized words without separators. */
  PascalCase = 'PascalCase',
  
  /** Transform into a lower case string with slashes between words. */
  PathCase = 'PathCase',
  
  /** Transform into a lower case with spaces between words, then capitalize the string. */
  SentenceCase = 'SentenceCase',

  /** Transform into a lower case string with underscores between words. */
  SnakeCase = 'SnakeCase',
}