import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { BindingProps, refProxy, RefProxy, StoreNode } from '../../store';
import { PointerEventLike } from '../../core';
import { getRatio } from '../../core/math';
import { PlayerState } from './playerState';

type Props = BindingProps<{
  player?: PlayerState | null;
  containerRef?: RefProxy<HTMLElement> | null;
}>

/**
 * Manages events and data for a player's progress bar.
 */
export class PlayerProgressBarState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  readonly playheadRef = refProxy(this);

  @computed get player(): PlayerState | null {
    return this.resolvedProps.player ?? null;
  }

  @computed get containerRef(): RefProxy<HTMLElement> | null {
    return this.resolvedProps.containerRef ?? null;
  }

  @computed get position(): number {
    return this.player?.playheadTimeRatio ?? 0;
  }

  @observable isDragging = false;
  @observable isHovering = false;
  @observable hoverTime: number | null = null;
  @observable seekTime: number | null = null;

  @computed get showHoverProgress() {
    return this.isHovering && !this.isDragging;
  }


  @computed get currentTimeRatio(): number {
    return (this.isDragging ? this.seekTimeRatio : this.player?.playheadTimeRatio) ?? 0;
  }

  @computed get seekTimeRatio(): number {

    if (this.player?.isLiveStream)
      return this.player?.playerLiveAdapter.getTimeRatio(this.seekTime || 0);

    return getRatio(this.seekTime, this.player?.duration);
  }

  @computed get hoverTimeRatio(): number {

    if (this.player?.isLiveStream)
      return this.player?.playerLiveAdapter.getTimeRatio(this.hoverTime || 0);

    return getRatio(this.hoverTime, this.player?.duration);
  }

  private getTimeFromEvent(evt: PointerEventLike) {
    return this.player?.getTimeFromEvent(evt, this.containerRef) ?? 0;
  }

  private emitSeek(type: 'seek' | 'seekStart' | 'seekEnd', evt: PointerEventLike) {
    if (!this.player?.isSeekable) 
      return;

    const time = this.getTimeFromEvent(evt);
    this.seekTime = time;
    this.emit(type, { time });
  }

  handleMouseEnter = action((evt: React.MouseEvent) => {
    if (!this.player?.isSeekable) 
      return;

    this.isHovering = true;
    this.hoverTime = this.getTimeFromEvent(evt);
  })

  handleMouseLeave = action((evt: React.MouseEvent) => {
    if (!this.player?.isSeekable) 
      return;

    this.isHovering = false;
    this.hoverTime = this.getTimeFromEvent(evt);
  })

  handleMouseMove = action((evt: React.MouseEvent) => {
    if (!this.player?.isSeekable) 
      return;

    this.hoverTime = this.getTimeFromEvent(evt);
  })




  handlePointerDown = action((evt: React.PointerEvent) => {
    if (!this.player?.isSeekable) return;
    const { player: controller } = this;
    if (controller?.isSeekDragging) {
      console.warn('PlayheadAdapter: pointerdown handler invoked, even though VideoState.isSeekDragging is already true.');
      return;
    }

    evt.stopPropagation();
    evt.preventDefault();

    document.addEventListener('pointermove', this.handleRootPointerMove);
    document.addEventListener('pointerup', this.handleRootPointerUp);

    this.isDragging = true;
    this.emitSeek('seekStart', evt);
  })

  private handleRootPointerMove = action((evt: PointerEvent) => {
    if (!this.player?.isSeekable) return;
    evt.stopPropagation();
    evt.preventDefault();

    this.emitSeek('seek', evt);
  })

  private handleRootPointerUp = action((evt: PointerEvent) => {
    if (!this.player?.isSeekable) return;
    document.removeEventListener('pointermove', this.handleRootPointerMove);
    document.removeEventListener('pointerup', this.handleRootPointerUp);

    this.isDragging = false;
    this.emitSeek('seekEnd', evt);

    this.seekTime = null;
  })
}