import { action, makeObservable, observable } from 'mobx';

import { CatalogState } from '../../components/layout/catalogState';
import { Maybe } from '../../core';
import { Error, pageError } from '../../core/error';
import { JobModel } from '../../entities';
import { TrainerJobCatalogSource } from '../../entities/trainerJobCatalogSource';
import { StoreNode } from '../../store';
import { Store } from '../../store/store';

type Props = {
  jobId?: string;
}

export type TrainerDashboardPageParams = {
  jobId?: string
}

/** Controller for TrainerDashboardPage. */
export class TrainerDashboardPageState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.failedJobId = props?.jobId || null;
    this.jobCatalogSource = new TrainerJobCatalogSource(this.store, {
      pageSize: 20,
      jobFilter: (job: JobModel) => {
        const profileId = this.store.auth.userProfile?.id;
        return !!profileId && job.userId === profileId;
      }
    });
    this.jobCatalog = new CatalogState(this.store, {
      syncStatus: () => this.jobCatalogSource.syncStatus,
      isEndOfList: () => this.jobCatalogSource.isEndOfList
    });
    this.jobCatalog.listen((msg) => {
      switch (msg.type) {
        case 'scrollToBottom':
        case 'loadMore':
          this.jobCatalogSource.fetchMore();
          break;
      }
    });
  }

  @observable failedJobId: Maybe<string>;
  @observable isLoading: boolean = false;
  @observable error: Error | null = null;

  readonly jobCatalogSource: TrainerJobCatalogSource;
  readonly jobCatalog = new CatalogState(this.store);

  @action
  async pageMounted(params: TrainerDashboardPageParams) {
    this.reset();
    this.isLoading = true;

    const [, err] = await this.jobCatalogSource.fetch();
    if (err)
      return this.setError(err);

    if (params.jobId) {
      this.failedJobId = params.jobId;
      this.dispatch('openVideoInformationWindow', {
        jobId: this.failedJobId
      });
    }

    // Fetch teams for permissions
    await this.store.teamManager.apiEnsureTeams()

    this.setLoaded();
  }

  @action
  pageUnmounted() {
    this.reset();
  }

  // #region State helpers
  @action
  async refreshJobs() {
    return await this.jobCatalogSource.fetch();
  }

  @action
  reset() {
    this.isLoading = false;
    this.error = null;

    this.jobCatalogSource.reset();
  }

  @action
  private setError(error?: Error) {
    if (!error)
      error = pageError();

    this.isLoading = false;
    this.error = error;
  }

  @action
  private setLoaded() {
    this.isLoading = false;
    this.error = null;
  }
  // #endregion
}