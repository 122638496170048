import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { UploadPageState } from '../uploadPage';
import { LibraryProvider } from '../../services/libraries';

type Props = {
  library: LibraryProvider
}

export class NotConnectedState
  extends StoreNode {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.library = props.library;
  }

  readonly library: LibraryProvider;

  @observable allowAuth: boolean = false;

  @computed
  get uploadPageState(): UploadPageState {
    return this.store.uploadPage;
  }

  @computed
  get isWidgetMode(): boolean {
    return this.store.widget.isWidgetMode;
  }

  @computed
  get showOpenConfirmation(): boolean {
    return this.uploadPageState.showOpenConfirmation;
  }

  @computed
  get showStaging(): boolean {
    return this.uploadPageState.showStaging;
  }

  @action
  setAllowAuth(value: boolean) {
    this.allowAuth = value;
  }

  @action
  triggerConfirmationModal = (authUrl: string) => {
    if (this.isWidgetMode) 
      return this.store.uploadWidget.authorizeLibrary(this.library);

    if ((this.showOpenConfirmation || this.showStaging) && !this.allowAuth) {
      this.dispatch('openConfirmationModal', {
        onSubmit: () => { this.setAllowAuth(true) },
        onCancel: () => {
          this.dispatch('Overlays', 'closeWindow');
          this.setAllowAuth(false);
        },
        title: 'You have unsubmitted videos',
        message: 'Are you sure you want to leave the current page?',
        secondaryMessage: 'You will lose edits you have made.',
        confirmLabel: 'Leave',
        closeLabel: 'Stay',
        layout: 'danger'
      });
    } else {
      if (this.setAllowAuth) this.setAllowAuth(false);
      window.onbeforeunload = null;
      window.open(authUrl, "_self");
    }
  }
}