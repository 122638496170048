import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { PlayerIndexState } from '../playerIndex/playerIndexState';
import { PlayerTranscriptsState } from '../playerTranscripts/playerTranscriptsState';
import { PlayerCommentsState } from '../playerComments/playerCommentsState';
import { CloseSVGIcon } from '../svg';
import { PlayerSpeakersState } from '../playerSpeakers/playerSpeakersState';

type PlayerSectionCloseButtonProps = {
  model: PlayerTranscriptsState | PlayerIndexState | PlayerCommentsState | PlayerSpeakersState,
  className?: string,
  onClose?: () => void
}

export const PlayerSectionCloseButton = observer(({
  model,
  className,
  onClose
}: PlayerSectionCloseButtonProps) => {

  className = classNames('user-player-section-close-btn section-close-btn', className)

  return <button className={className}
    onClick={evt => {
      onClose?.call(model);
    }}>
    <span className="icon"><CloseSVGIcon /></span>
  </button>
})