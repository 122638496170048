import React from 'react';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import { Window, WindowHeader } from '../overlays';
import { VideoInformationWindowState } from './videoInformationWindowState';
import { Button, TextInputControlled, DropdownInputControlled } from '../input';
import { duration } from '../utils';
import { getJobSpecialityInput } from '../../entities/job/jobSpeciality';
import { getLanguageInputLabel } from '../../entities/language';
import { getEnrichmentLevelLabel } from '../../entities/job/jobFeatures';
import { getJobExternalSource } from '../../entities/job/jobSource';
import { useEffect } from 'react';

type Props = {
  model: VideoInformationWindowState;
  visible?: boolean;
};

export const VideoInformationWindow = observer((props: Props) => {

  const { model } = props;
  const { job } = model;

  useEffect(() => {
    return () => model.close();
  }, [model]);

  let source;
  const externalLibrarySource = job?.source.externalLibrary?.source;

  if (externalLibrarySource) {
    source = getJobExternalSource(externalLibrarySource);
  }

  if (job?.source.url) source = 'URL';
  if (job?.source.type === 'Upload') source = 'File Upload';
  if (model.streamedOnText) source = model.streamedOnText;

  return (
    <Window
      className="video-information-window"
      model={model.window}
      onExited={model.onTransitionEnd}
      visible={props.visible}
      isLoading={model.isLoading}>
      <WindowHeader model={model.window} title="Content Information" />

      <hr />

      <section className="window-content">
        <table className="job-info-fields">
          <tbody>
            <tr>
              <td className="field-name">Upload</td>
              <td className="field-value">
                {job?.createdAtDate.toLocaleString(DateTime.DATETIME_SHORT)}
              </td>
            </tr>
            <tr>
              <td className="field-name">Owner</td>
              <td className="field-value">{job?.displayOwnerName}</td>
            </tr>
            <tr>
              <td className="field-name">Uploader</td>
              <td className="field-value">
                {job?.user?.name || job?.user?.username}
              </td>
            </tr>
            <tr>
              <td className="field-name">Length</td>
              <td className="field-value">
                {duration(job?.videoDuration).toFormat('h:mm:ss')}
              </td>
            </tr>
            <tr>
              <td className="field-name">Status</td>
              <td
                className={`field-value ${job?.status === 'Failed' ? 'error' : ''
                  }`}>
                {job?.status}{' '}
              </td>
            </tr>
            {job?.source.url && (
              <tr>
                <td className="field-name">Original Link</td>
                <td
                  className={`field-value ${job?.status === 'Failed' ? 'error' : ''
                    }`}>
                  {job?.source.url}{' '}
                </td>
              </tr>
            )}
            {job?.source.password && (
              <tr>
                <td className="field-name">Video Password</td>
                <td className="field-value">{job?.source.password}</td>
              </tr>
            )}
            {(job?.enrichmentLevel) && (
              <tr>
                <td className="field-name">Enrichment level</td>
                <td className="field-value">{getEnrichmentLevelLabel(job.enrichmentLevel)}</td>
              </tr>
            )}
            {(job?.languageCode) && (
              <tr>
                <td className="field-name">Language</td>
                <td className="field-value">{getLanguageInputLabel(job.languageCode)}</td>
              </tr>
            )}
            <tr>
              <td className="field-name">Speciality</td>
              <td className="field-value">{getJobSpecialityInput(job ?? null)}</td>
            </tr>
            {source && (
              <tr>
                <td className="field-name">Source</td>
                <td className="field-value">{source}</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="flex tmar4">
          <TextInputControlled
            className="rpad2"
            model={model.titleInput}
            feedbackPosition="bottomEnd"
            placeholder="Enter video title"
            label="Title"
          />

          <DropdownInputControlled
            className="lpad2"
            model={model.videoTypeInput}
            placeholder="Video Type"
            showEmptyItem={!model.videoTypeInput.value}
            label="Video Type"
          />
        </div>
      </section>

      <hr />

      <footer className="window-footer">
        <div className="btn-group right">
          <Button
            className="primary btn-outline medium"
            label="Cancel"
            onClick={() => model.close()}
          />

          <Button
            className="primary medium"
            label="Save"
            disabled={model.isLoading || !!model.inputGroup.error}
            onClick={() => model.submit()}
          />
        </div>
      </footer>
    </Window>
  );
});
