import { MouseEvent } from 'react';
import { matchPath } from 'react-router-dom';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import debounce from 'lodash/debounce';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { Nullable } from '../../core';
import { notifyError } from '../../services/notifications';
import { input, InputState } from '../input';
import { Routes } from '../../routes';
import { Team } from '../../entities';
import { TeamManager } from '../../entities/teamManager';
import { DeleteTeamWindowState } from '../teams/deleteTeamWindowState';

type Props = {

}

/**
 * Manages state for the page sidebar.
 * The sidebar component should be able to work without a state as well,
 * but having a persistent state object will be much nicer for the user,
 * for example it will store the expand / collapse status between pages.
 */
export class MasterSidebarState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.toggleModel = input(store, {
      name: 'sidebarToggle',
      value: false,
    });

    this.teamSearchInput = input(store, {
      name: 'teamSearchInput',
      onChange: (e) => this.handleSearchInputChange(e),
    })

    this.receive(this.receiver, [
      'RoutingService:authorizedRouteMounted',
      'AuthService:authorized',
      'CreateTeamForm:teamCreated'
    ]);
  }

  readonly teamManager: TeamManager = new TeamManager(this.store);
  readonly toggleModel: InputState;
  readonly teamSearchInput: InputState;

  @observable isExpanded = true;
  @observable isSearchToggled = false;
  @observable teams: Team[] = [];
  @observable teamSearchFilter: string | null = null;

  @computed
  get isTeamAnalytics() {
    return !!matchPath(window.location.pathname, Routes.teamAnalytics());
  }

  @observable isTeamListLoading: boolean = false;
  @observable isTeamListFetched: boolean = false;
  @observable currentTeamId: Nullable<string> = null;

  @action
  mounted() {
    const { deleteTeamWindow } = this.store;

    deleteTeamWindow.listen(
      this.deleteTeamWindowListener)

    if (!this.isTeamListFetched) {
      this.fetchTeams();
    }
  }

  @action
  unmounted() {
    this.handleSearchInputClear();
    this.teamSearchInput.clear();

    this.store.deleteTeamWindow.unlisten(
      this.deleteTeamWindowListener)
  }

  // Collapse the sidebar
  @action
  handleCollapseButtonClick = (evt: MouseEvent) => {
    this.isExpanded = !this.isExpanded;
  }

  // #region Team List
  @action
  setCurrentTeamId = (teamId: string) => {
    this.currentTeamId = teamId || this.store.teamDashboardPage.teamId;
  }

  @action
  handleToggleSearch = () => {
    this.isSearchToggled = !this.isSearchToggled;
  }

  @action
  handleSearchInputChange(evt: any) {
    this.teamSearchFilter = evt.value || null;
    this.fetchTeamsDebounced();
  }
  @action
  handleSearchInputClear() {
    this.isSearchToggled = false;
    if (this.teamSearchFilter) this.handleSearchInputChange({});
  }

  @action
  async fetchTeams() {

    this.isTeamListLoading = true;
    const [teams, err] = await this.teamManager.apiFetchTeams({
      sort: {
        field: 'name',
        order: 'asc'
      },
      filter: {
        _search: this.teamSearchFilter || undefined
      }
    });
    runInAction(() => {
      if (teams) this.teams = teams;
      this.isTeamListLoading = false;
    });

    if (err) {
      notifyError(this, 'Could not get teams.');
      return false;
    }

    runInAction(() => {
      this.isTeamListFetched = true;
    });
    return true;
  }

  private deleteTeamWindowListener = action((msg: Message<DeleteTeamWindowState>) => {
    switch (msg.type) {
      case 'team:deleted':
        this.fetchTeams();
        break;
    }
  });


  fetchTeamsDebounced = debounce(() => this.fetchTeams(), 500);
  // #endregion

  /**
   * Broadcast receiver for this Node.
   */
  private receiver = (msg: Message) => {
    switch (msg.fullType) {
      case 'CreateTeamForm:teamCreated':
        this.fetchTeams();
        break;
      case 'RoutingService:authorizedRouteMounted':
        const { routingService } = this.store;
        this.setCurrentTeamId(routingService.context.params?.teamId);
        break;
    }
  }

}