import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useRouteModel } from './routeHooks';
import { PublicRouteState } from './publicRouteState';

import { TRACE } from '../core/debug/debugMacros';

type Props = RouteProps;

export const PublicRoute = observer((props: Props) => {

  const model = useRouteModel((kernel) => new PublicRouteState(kernel.store));

  TRACE(model, `render()`, { props, model });

  return (
    <Route {...props} />
  )
});