import './speakers.scss';

import React, { MouseEventHandler } from 'react';
import { observer } from 'mobx-react-lite';
import { SpeakerModel } from '../../entities';
import { SpeakerAvatar } from './speakerAvatar';

type Props = {
  model: SpeakerModel,
  selected?: boolean,
  disabled?: boolean,
  onClick?: MouseEventHandler
}

export const SpeakerCard = observer(({
  model,
  selected = false,
  disabled = false,
  onClick
}: Props) => {
  
  return (
    <div className="speaker-card"
      aria-selected={selected || undefined}
      aria-disabled={disabled || undefined}
      onClick={onClick}>

      <SpeakerAvatar model={model} />
      <div className="speaker-name">{model.name}</div>
    </div>
  );
});