
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SearchTextInputControlled } from '..';
import { TeamSettingsPageState } from '../../pages/teamSettingsPage';
export const TeamSearchComponent = observer(
  ({ model }: { model: TeamSettingsPageState }) => {
    const { teamMemberCatalogSource, teamInviteCatalogSource } = model;

    switch (model.activeTab) {

      case 'Active':
        return (
          <div className="search-bar">
            <SearchTextInputControlled
              model={teamMemberCatalogSource.searchInput}
              persistentFeedback={false}
              id="search-members"
              name="search-members"
              type="text"
              autoComplete="off"
              placeholder="Search"
              showFeedback={false}
              onClear={() =>
                teamMemberCatalogSource.handleSearchInputChange({})
              }
            />
          </div>
        );
      case 'Pending':
        return (
          <div className="search-bar">
            <SearchTextInputControlled
              model={teamInviteCatalogSource.searchInput}
              persistentFeedback={false}
              id="search-invitation"
              name="search-invitation"
              type="text"
              autoComplete="off"
              placeholder="Search"
              showFeedback={false}
              onClear={() => teamInviteCatalogSource.handleSearchInputChange({})}
            />
          </div>
        );

      default:
        return null;
    }
  }
);