import '../../components/layout/layout.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { BookmarkListWindow, BookmarkWindow } from '../../components/bookmarks';
import { useStore } from '../../store/storeHooks';
import { MultipleClipEditWindow, ClipWindow } from '../../components/momentWindow';
import { TeamMembersWindow } from '../../components/teams';
import { AddJobToTeamWindow, CopyJobWindow, DeleteJobWindow, RemoveJobFromTeamWindow, RenameJobWindow, ChangeJobOwnerWindow, RemoveJobFromCliprWindow, IngestFromExternalLibraryWindow, JobViewStatusWindow, UploadThumbnailWindow, VideoDetailsWindow, ShareVideoWindow } from '../../components/jobs';

import { SpeakerWindow } from '../../components/speakerWindow/speakerWindow';
import { DeleteTrackPopup, TrainerKeyboardShortcutsWindow } from '../../pages/trainerVideoPage';
import { TrackWindow } from '../../components/trackWindow/trackWindow';
import { RemoveAccessPopup } from '../../components/teams/removeAccessPopup';
import { LeaveTeamWindow } from '../../components/teams/leaveTeamWindow';
import { DeleteMomentsPopup } from '../../components/momentWindow/deleteMomentsPopup';
import { VideoInformationWindow } from '../../components/jobs/videoInformationWindow';
import { BulkInvitesWindow } from '../../components/teams/bulkInvitesWindow';
import { CloseWindowConfirmationModal } from '../../components/momentWindow/closeWindowConfirmationModal';
import { MergeTracksWindow } from '../../components/trackWindow/mergeTracksWindow';
import { JobErrorsWindow } from '../../components/momentWindow/jobErrorsWindow';
import { DeleteBookmarkListWindow } from '../../components/bookmarks/deleteBookmarkListWindow';
import { CommentDeleteWindow } from '../../components/playerComments/commentDeleteWindow';
import { ConfirmationModal } from '../../pages/trainerVideoPage/confirmationModal';
import { CredentialsExpiredWindow } from '../../pages/userDashboardPage/credentialsExpiredWindow';
import { ZoomDateSelectorWindow } from '../../pages/userDashboardPage/zoomDateSelectorWindow';
import { ExternalLibraryUploadWindow } from '../../pages/uploadPage/externalLibraryUploadWindow';
import { PlayerTutorialWindow } from '../../components/playerTutorial/playerTutorialWindow';
import { PlayerTutorialConfirmationWindow } from '../../components/playerTutorial/playerTutorialConfirmationWindow';
import { PlayerTutorialTopicsWindow } from '../../components/playerTutorial/playerTutorialTopicsWindow';
import { PlayerTutorialHelpButtonTooltip } from '../../components/playerTutorial/playerTutorialHelpButtonTooltip';
import { MakeTeamPublicWindow } from '../../components/teamSettings';
import { CancelUploadWindow } from '../../pages/uploadPage/cancelUploadWindow';
import { InfoModal } from '../../components/infoModal';
import { DeleteStreamWindow } from '../../components/jobs/deleteStreamWindow';
import { StreamAssetsWindow } from '../../components/jobs/streamAssetsWindow';
import { StreamQueueSettingsWindow } from '../../components/jobs/streamQueueSettingsWindow';
import { DownloadWindow } from '../../components/download/downloadWindow';
import { TeamProductWindow } from '../../components/teams/teamProductWindow';
import { ToggleChatGptWindow } from '../../components/teamSettings/toggleChatGptWindow';
import { ScheduleStreamWindow } from '../../components/jobs/scheduleStreamWindow';
import { UploadJobDetailsWindow } from '../../pages/uploadPage/uploadJobDetailsWindow';
import { DeleteTeamWindow } from '../../components/teams/deleteTeamWindow';
import { LiveFeedWindow } from '../../components/jobs/liveFeedWindow';
import { ManualIngestWindow } from '../../components/jobs/manualIngestWindow';
import { LiveNotStreamingWindow } from '../../components/jobs/liveNotStreamingWindow';
import { SpeakerIdConfirmationWindow } from '../../components/speakerId/speakerIdConfirmationWindow';
import { PostRegistrationTutorialWindow } from '../../pages/auth/register/postRegistrationTutorial';
import { CancelAccountWindow } from '../../pages/account/cancelAccountWindow';
import { DownloadJobWindow } from '../../components/jobs/downloadJobWindow';
import { ExportToEdlWindow } from '../../components/jobs/exportToEdlWindow';
import { TeamDictionaryWindow } from '../../components/teams/teamDictionaryWindow';
import { DownloadJobSlidesWindow } from '../../components/jobs/downloadJobSlidesWindow';
import { EditorWindow } from '../../components/editorWindow/editorWindow';
import { TogglePublicSafetyWindow } from '../../components/teamSettings/togglePublicSafetyWindow';

type Props = {

}

export const OverlaysChunk = observer((props: Props) => {
  const store = useStore();
  const state = store.overlayService;

  return (
    <div className="overlays-chunk page-chunk">

      <BookmarkWindow
        model={store.bookmarkWindow}
        visible={state.visibleWindow === 'BookmarkWindow'} />

      <BookmarkListWindow
        model={store.bookmarkListWindow}
        visible={state.visibleWindow === 'BookmarkListWindow'} />

      <DeleteBookmarkListWindow
        model={store.deleteBookmarkListWindow}
        visible={state.visibleWindow === 'DeleteBookmarkListWindow'} />

      <ShareVideoWindow
        model={store.shareVideoWindow}
        visible={state.visibleWindow === 'ShareVideoWindow'} />

      <MultipleClipEditWindow
        model={store.multipleClipEditWindow}
        visible={state.visibleWindow === 'MultipleClipEditWindow'} />

      <TeamMembersWindow
        model={store.teamMembersWindow}
        visible={state.visibleWindow === 'TeamMembersWindow'} />

      <TeamDictionaryWindow
        model={store.teamDictionaryWindow}
        visible={state.visibleWindow === 'TeamDictionaryWindow'} />

      <DeleteTeamWindow
        model={store.deleteTeamWindow}
        visible={state.visibleWindow === 'DeleteTeamWindow'} />

      <CopyJobWindow
        model={store.copyJobWindow}
        visible={state.visibleWindow === 'CopyJobWindow'} />

      <AddJobToTeamWindow
        model={store.addJobToTeamWindow}
        visible={state.visibleWindow === 'AddJobToTeamWindow'} />

      <RemoveJobFromTeamWindow
        model={store.removeJobFromTeamWindow}
        visible={state.visibleWindow === 'RemoveJobFromTeamWindow'} />

      <DeleteJobWindow
        model={store.deleteJobWindow}
        visible={state.visibleWindow === 'DeleteJobWindow'} />

      <DownloadJobWindow
        model={store.downloadJobWindow}
        visible={state.visibleWindow === 'DownloadJobWindow'}/>

      <ExportToEdlWindow
        model={store.exportToEdlWindow}
        visible={state.visibleWindow === 'ExportToEdlWindow'}
      />

      <DeleteStreamWindow
        model={store.deleteStreamWindow}
        visible={state.visibleWindow === 'DeleteStreamWindow'} />

      <RemoveJobFromCliprWindow
        model={store.removeJobFromCliprWindow}
        visible={state.visibleWindow === 'RemoveJobFromCliprWindow'} />

      <IngestFromExternalLibraryWindow
        model={store.ingestFromExternalLibraryWindow}
        visible={state.visibleWindow === 'IngestFromExternalLibraryWindow'} />

      <RenameJobWindow
        model={store.renameJobWindow}
        visible={state.visibleWindow === 'RenameJobWindow'} />

      <ChangeJobOwnerWindow
        model={store.changeJobOwnerWindow}
        visible={state.visibleWindow === 'ChangeJobOwnerWindow'} />

      {/* The survey modal is no longer used but it can be repurposed if needed */}
      {/* <UpgradeModal
        model={store.upgradeModal}
        visible={state.visibleWindow === 'UpgradeModal'} /> */}

      <SpeakerWindow
        model={store.speakerWindow}
        visible={state.visibleWindow === 'SpeakerWindow'} />

      <DeleteTrackPopup
        model={store.deleteTrack}
        visible={state.visibleWindow === 'DeleteTrackPopup'} />

      <TrackWindow
        model={store.trackWindow}
        visible={state.visibleWindow === 'TrackWindow'} />

      <RemoveAccessPopup
        model={store.removeAccessPopup}
        visible={state.visibleWindow === 'RemoveAccessPopup'} />

      <LeaveTeamWindow
        model={store.leaveTeamWindow}
        visible={state.visibleWindow === 'LeaveTeamWindow'} />

      <VideoInformationWindow
        model={store.videoInformationWindow}
        visible={state.visibleWindow === 'VideoInformationWindow'} />

      <DeleteMomentsPopup
        model={store.deleteMomentsPopup}
        visible={state.visibleWindow === 'DeleteMomentsPopup'} />

      <ClipWindow
        model={store.clipWindow}
        visible={state.visibleWindow === 'ClipWindow'} />

      <BulkInvitesWindow
        model={store.bulkInvitesWindow}
        visible={state.visibleWindow === 'BulkInvitesWindow'} />

      <CloseWindowConfirmationModal
        model={store.closeWindowConfirmationModal}
        visible={state.visibleWindow === 'CloseWindowConfirmationModal'} />

      <MergeTracksWindow
        model={store.mergeTracksWindow}
        visible={state.visibleWindow === 'MergeTracksWindow'} />

      <JobErrorsWindow
        model={store.jobErrorsWindow}
        visible={state.visibleWindow === 'JobErrorsWindow'} />

      <CommentDeleteWindow
        model={store.commentDeleteWindow}
        visible={state.visibleWindow === 'CommentDeleteWindow'} />

      <ConfirmationModal
        model={store.confirmationModal}
        visible={state.visibleWindow === 'ConfirmationModal'} />

      <JobViewStatusWindow
        model={store.jobViewStatusWindow}
        visible={state.visibleWindow === 'JobViewStatusWindow'} />
      <CredentialsExpiredWindow
        model={store.credentialsExpiredWindow}
        visible={state.visibleWindow === 'CredentialsExpiredWindow'} />

      <ZoomDateSelectorWindow
        model={store.zoomDateSelectorWindow}
        visible={state.visibleWindow === 'ZoomDateSelectorWindow'} />

      <UploadThumbnailWindow
        model={store.uploadThumbnailWindow}
        visible={state.visibleWindow === 'UploadThumbnailWindow'} />

      <TrainerKeyboardShortcutsWindow
        model={store.trainerKeyboardShortcutsWindow}
        visible={state.visibleWindow === 'TrainerKeyboardShortcutsWindow'} />

      <PlayerTutorialWindow
        model={store.playerTutorialWindow}
        visible={state.visibleWindow === 'PlayerTutorialWindow'} />

      <PostRegistrationTutorialWindow
        model={store.postRegistrationWindow}
        visible={state.visibleWindow === 'PostRegistrationTutorialWindow'}
      />

      <PlayerTutorialConfirmationWindow
        model={store.playerTutorialConfirmationWindowState}
        visible={state.visibleWindow === 'PlayerTutoriaConfirmationlWindow'} />

      <PlayerTutorialTopicsWindow
        model={store.playerTutorialTopicsWindow}
        visible={state.visibleWindow === 'PlayerTutorialTopicsWindow'}
      />

      <PlayerTutorialHelpButtonTooltip
        model={store.playerTutorialHelpButtonTooltip}
        visible={state.visibleWindow === 'PlayerTutorialHelpButtonTooltip'}
      />

      <ScheduleStreamWindow
        model={store.scheduleStreamWindow}
        visible={state.visibleWindow === 'ScheduleStreamWindow'} />

      <StreamAssetsWindow
        model={store.assetsWindow}
        visible={state.visibleWindow === 'StreamAssetsWindow'} />

      <LiveFeedWindow
        model={store.liveFeedWindow}
        visible={state.visibleWindow === 'LiveFeedWindow'} />

      <LiveNotStreamingWindow
        model={store.liveNotStreamingWindow}
        visible={state.visibleWindow === 'LiveNotStreamingWindow'} />

      <ManualIngestWindow
        model={store.manualIngestWindow}
        visible={state.visibleWindow === 'ManualIngestWindow'} />

      <StreamQueueSettingsWindow
        model={store.streamQueueSettingsWindow}
        visible={state.visibleWindow === 'StreamQueueSettingsWindow'} />

      <VideoDetailsWindow
        model={store.videoDetailsWindow}
        visible={state.visibleWindow === 'VideoDetailsWindow'} />

      <ExternalLibraryUploadWindow
        model={store.externalLibraryUploadWindow}
        visible={state.visibleWindow === 'ExternalLibraryUploadWindow'}
      />

      <MakeTeamPublicWindow
        model={store.makeTeamPublicWindow}
        visible={state.visibleWindow === 'MakeTeamPublicWindow'}
      />

      <ToggleChatGptWindow
        model={store.toggleChatGptWindow}
        visible={state.visibleWindow === 'ToggleChatGptWindow'}
      />

      <TogglePublicSafetyWindow
        model={store.togglePublicSafetyWindow}
        visible={state.visibleWindow === 'TogglePublicSafetyWindow'}
      />      

      <CancelAccountWindow
        model={store.cancelAccountWindow}
        visible={state.visibleWindow === 'CancelAccountWindow'} />

      <CancelUploadWindow
        model={store.cancelUploadWindow}
        visible={state.visibleWindow === 'CancelUploadWindow'}
      />

      <InfoModal
        model={store.infoModal}
        visible={state.visibleWindow === 'InfoModal'}
      />

      <DownloadWindow
        model={store.downloadWindow}
        visible={state.visibleWindow === 'DownloadWindow'}
      />

      <DownloadJobSlidesWindow
        model={store.downloadJobSlidesWindow}
        visible={state.visibleWindow === 'DownloadJobSlidesWindow'}
      />

      <TeamProductWindow
        model={store.teamProductWindow}
        visible={state.visibleWindow === 'TeamProductWindow'}
      />

      <UploadJobDetailsWindow
        model={store.uploadJobDetailsWindow}
        visible={state.visibleWindow === 'UploadJobDetailsWindow'} />

      <SpeakerIdConfirmationWindow
        model={store.speakerIdWindow}
        visible={state.visibleWindow === 'SpeakerIdConfirmationWindow'} />

      <EditorWindow 
        model={store.editorWindow}
        visible={state.visibleWindow === 'EditorWindow'} />

    </div >
  );
});