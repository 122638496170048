import '../player.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { PlayerState } from '../playerState';
import { PlayerMoment } from './playerMoment';
import { PlayerMomentStub } from './playerMomentStub';
import { Playhead } from '..';
import { cssPercentage } from '../../../core';
import { paramCase } from 'param-case';
import classNames from 'classnames';
import { IComponentPolicy } from '../../componentSchema';
import { PlayerComponentName } from '../playerSchema';

type Props = {
  model: PlayerState,
  policy?: IComponentPolicy
}



/** The background of the track. */
const PlaybarTrail = observer(({
  model
}: Props) => {
  return (
    <div className="player-trail" />
  );
});

const PlaybarProgress = observer(({
  model
}: Props) => {
  const { progressBar } = model;
  return (
    <div className="player-progress"
      style={{ width: cssPercentage(progressBar.currentTimeRatio) }} />
  );
});

const PlaybarBuffered = observer(({
  model
}: Props) => {
  return (
    <div className="player-buffered"
      style={{ width: cssPercentage(model.bufferedTimeRatio) }} />
  );
});

const HoverProgress = observer(({
  model
}: Props) => {
  const { progressBar } = model;
  return (
    <div className="hover-progress"
      style={{ width: cssPercentage(progressBar.hoverTimeRatio) }} />
  );
});


const PlaybarMoments = observer(({
  model
}: Props) => {

  const {
    itemSource
  } = model;

  return (
    <div className="player-moments">
      {itemSource.playbarItems
        .map(item => {

          if (!item.moment)
            return null;

          const className = paramCase(item.playbackRole);

          return <PlayerMoment
            className={className}
            key={item.key}
            moment={item.moment}
            model={model} />
        })}
    </div>
  );
});

const PlaybarTrack = observer(({
  model
}: Props) => {

  const showMoments = model.isComponentVisible(PlayerComponentName.ProgressBarMoments);

  return (
    <div className="playbar-track">
      <PlaybarTrail
        model={model} />

      {showMoments &&
        <PlaybarMoments
          model={model} />}

      <PlaybarBuffered
        model={model} />
      <HoverProgress
        model={model} />
      <PlaybarProgress
        model={model} />

      {showMoments &&
        <PlayerMomentStub
          model={model} />}
    </div>
  );
});

export const PlayerPlaybar = observer(({
  model,
  policy
}: Props) => {

  const readOnly = policy?.isReadOnly;

  const { progressBar } = model;
  const className = classNames({
    'hovering': progressBar.showHoverProgress,
    'dragging': progressBar.isDragging
  });

  const showPlayhead = !readOnly;
  let elemProps = {};

  if (!readOnly) {
    elemProps = {
      onClick: model.handleTimelineClick,
      onPointerDown: progressBar.handlePointerDown,
      onMouseEnter: progressBar.handleMouseEnter,
      onMouseLeave: progressBar.handleMouseLeave,
      onMouseMove: progressBar.handleMouseMove
    }
  }

  return (
    <div id="user-player-playbar"
      ref={model.progressBarRef.ref}
      className={className}
      aria-readonly={readOnly ? true : undefined}
      {...elemProps}>

      <PlaybarTrack
        model={model} />

      {showPlayhead &&
        <Playhead
          className={!model.isSeekable ? 'hidden' : ''}
          proxyRef={progressBar.playheadRef}
          position={progressBar.position}
          onPointerDown={progressBar.handlePointerDown} />}
    </div>
  );
});