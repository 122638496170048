import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ApiResponse, ApiResult, ApiVariables, ApiRequestOptions } from '../../../api/apiSchema';
import { AsyncResult, Result } from '../../../core';
import { BindingProps, StoreNode } from '../../../store';
import { IChartDataSource } from '../chartSchema';
import { Store } from '../../../store/store';
import { error, Error } from '../../../core/error';
import { parseKeenJsonResult } from '../utils/parseKeenJsonResult';
import { HoursCliprdChartDatum, HoursCliprdChartParams, HoursCliprdChartMode, HoursCliprdData } from './hoursCliprdChartSchema';
import { ApiError } from '../../../api/apiError';
import { DateTime, Duration } from 'luxon';

type Props = BindingProps<{
  params: HoursCliprdChartParams,
  suppressFetchOnParamsChange?: boolean
}>

export class HoursCliprdDataSource
  extends StoreNode
  implements IChartDataSource<HoursCliprdChartDatum> {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.onPropChange('params',
      this.handleParamsChange);
  }

  // #region Props
  @computed get params(): HoursCliprdChartParams | null {
    return this.getResolvedProp('params') ?? null;
  }
  set params(val: HoursCliprdChartParams | null) {
    this.setProp('params', val);
  }

  @computed get suppressFetchOnParamsChange(): boolean | null {
    return this.getResolvedProp('suppressFetchOnParamsChange') ?? null;
  }
  set suppressFetchOnParamsChange(val: boolean | null) {
    this.setProp('suppressFetchOnParamsChange', val);
  }
  // #endregion

  // #region Handlers
  private handleParamsChange = () => {
    // this.fetch();
  }
  // #endregion

  @computed get mode(): HoursCliprdChartMode {
    return this.params?.mode ?? HoursCliprdChartMode.Processed;
  }

  readonly data = observable.array<HoursCliprdChartDatum>([], { deep: false });

  private abortController: AbortController | null = null;

  @observable isFetching = false;
  @observable error: Error | null = null;

  @action
  reset() {
    this.abort();

    this.isFetching = false;
    this.error = null;
    this.abortController = null;

    this.data.clear();
  }

  @action
  async fetch(): AsyncResult {

    this.reset();

    this.isFetching = true;

    const abortCtrl = new AbortController();
    this.abortController = abortCtrl;

    const { params } = this;
    if (!params)
      return [null];

    const startDateArg = params.startDate?.toISO();
    const endDateArg = params.endDate?.toISO();
    const timezoneArg = params.timezone ?? undefined;
    const teamIdArg = params.teamId ?? undefined;

    if (!startDateArg || !endDateArg)
      return [null, error('Unknown', 'Invalid parameters')];

    const reqArgs: ApiVariables<'keenBatch'> = {
      args: {
        start: startDateArg,
        end: endDateArg,
        timezone: timezoneArg,
        teamId: teamIdArg
      }
    };

    const api = this.store.api;
    const reqOpts: ApiRequestOptions = {
      signal: abortCtrl.signal
    };

    let reqPromise: ApiResult<'keenBatch'> | null = null;

    switch (params.mode) {
      default:
      case HoursCliprdChartMode.Processed:
        reqPromise = api.runQuery('keen_getHoursCliprdProcessed', reqArgs, reqOpts);
        break;

      case HoursCliprdChartMode.Viewed:
        reqPromise = api.runQuery('keen_getHoursCliprdViewed', reqArgs, reqOpts);
        break;

      case HoursCliprdChartMode.Saved:
        reqPromise = api.runQuery('keen_getHoursCliprdSaved', reqArgs, reqOpts);
        break;
    }

    if (!reqPromise) {
      console.error('Mode not implemented');
      return this.handleError(error('Unknown', `Invalid mode ${params.mode} was provided to HoursCliprdDataSource`));
    }

    const [res, err] = await reqPromise;
    if (err)
      // @ts-ignore TODO: make ApiError extend our own Error class
      return this.handleError(err);

    const [data, insertErr] = this.insert(res!);
    if (insertErr)
      return this.handleError(insertErr);

    runInAction(() => {
      this.isFetching = false;
    });

    return [data!];
  }

  cancel(): void {
    this.abort();
  }

  @observable isBatchRequesting = false;

  notifyBatchRequestStarted() {
    this.isBatchRequesting = true;
  }
  notifyBatchRequestCompleted() {
    this.isBatchRequesting = false;
  }

  @action
  insert(res: ApiResponse<'keenBatch' | 'keen_getHoursCliprdProcessed'>): Result<HoursCliprdChartDatum[]> {
    let dataRes: Result<HoursCliprdChartDatum[]>;

    switch (this.mode) {
      default:
      case HoursCliprdChartMode.Processed:
        dataRes = this.getDataFromHoursCliprdProcessed(res);
        break;
      case HoursCliprdChartMode.Viewed:
        dataRes = this.getDataFromHoursCliprdViewed(res);
        break;
      case HoursCliprdChartMode.Saved:
        dataRes = this.getDataFromHoursCliprdSaved(res);
        break;
    }

    const [data, err] = dataRes;
    if (err) {
      return this.handleError(err);
    }

    this.data.replace(data ?? []);
    return [data ?? []];
  }

  private abort() {

    const lastAbortCtrl = this.abortController;
    if (lastAbortCtrl)
      lastAbortCtrl.abort();
    this.abortController = null;
  }

  @action
  private handleError(err?: Error | string): Result {
    if (!err)
      err = new Error();
    if (typeof err === 'string')
      err = new Error('Unknown', err);

    if (err instanceof ApiError && err.type === 'RequestAborted') {
      // for aborted requests we only return the error but we don't set it on the instance and we do not repor it as error
      // console.warn(`Request aborted in HoursCliprdDataSource`);
      return [null, err];

    } else {
      console.error(`An error occured in HoursCliprdDataSource: `, err);

      this.isFetching = false;
      this.abort();
      this.error = err;

      return [null, err];
    }
  }

  @action
  private handleApiResponse(data: any): HoursCliprdData {
    const hours = Duration.fromObject({ seconds: data?.value ?? 0 }).as('hours')

    return {
      date: DateTime
        .fromISO(data?.timeframe?.start)
        .setLocale('en-US')
        .toLocaleString(DateTime.DATE_SHORT),
      value: hours ?? 0,
    };
  }

  @action
  private getDataFromHoursCliprdProcessed(data: ApiResponse<'keenBatch' | 'keen_getHoursCliprdProcessed'>): Result<HoursCliprdChartDatum[]> {
    const apiResultStr = data.keenBatch.hoursCliprdProcessed?.result;
    const [apiResult, parseErr] = parseKeenJsonResult<any>(apiResultStr);

    if (parseErr)
      return [null, parseErr];

    const outResult: HoursCliprdChartDatum[] = [{
      dataKey: 'Processed',
      data: apiResult?.map((data: any) => {
        const valueInSeconds = Duration.fromObject({ milliseconds: data?.value ?? 0 }).as('seconds');
        
        return this.handleApiResponse({ ...data, value: valueInSeconds });
      }) ?? [],
    }]

    return [outResult];
  }

  @action
  private getDataFromHoursCliprdViewed(data: ApiResponse<any>): Result<HoursCliprdChartDatum[]> {
    const apiResultStr = data.keenBatch.hoursCliprdViewed?.result;
    const [apiResult, parseErr] = parseKeenJsonResult<any>(apiResultStr);

    if (parseErr)
      return [null, parseErr];

    const outResult: HoursCliprdChartDatum[] = [{
      dataKey: 'Viewed',
      data: apiResult?.map((data: any) => this.handleApiResponse(data)) ?? [],
    }]

    return [outResult];
  }

  @action
  private getDataFromHoursCliprdSaved(data: ApiResponse<any>): Result<HoursCliprdChartDatum[]> {
    const apiResultStr = data.keenBatch.hoursCliprdSaved?.result;
    const [apiResult, parseErr] = parseKeenJsonResult<any>(apiResultStr);

    if (parseErr)
      return [null, parseErr];

    const outResult: HoursCliprdChartDatum[] = [{
      dataKey: 'Saved',
      data: apiResult?.map((data: any) => this.handleApiResponse(data)) ?? [],
    }]

    return [outResult];
  }
}
