import { Tooltip } from '../overlays/tooltip';
import React from 'react';
import { InputPlayerReactionNames } from '../playerReactions/playerReactionSchema';
import { ReactionSVGIconLookup } from '../playerReactions/reactionSvgIconLookup';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

export const ReactionsTutorialComponent = () => {
  const itemsRef = useRef<Array<HTMLElement | null>>([])
  const [index, setIndex] = useState(0);

  const mouseOverEvent = new MouseEvent('mouseover', {
    view: window,
    bubbles: true,
    cancelable: true
  });
  const mouseOutEvent = new MouseEvent('mouseout', {
    view: window,
    bubbles: true,
    cancelable: true
  })

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, InputPlayerReactionNames.length);
  }, []);

  // Tooltip animation
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const length = itemsRef.current.length - 1;

    if (index <= length) {
      timeout = setTimeout(() => {
        if (index > 0) itemsRef.current[index - 1]?.dispatchEvent(mouseOutEvent);
        if (index === 0) itemsRef.current[length]?.dispatchEvent(mouseOutEvent);
        itemsRef.current[index]?.dispatchEvent(mouseOverEvent);
        setIndex(index => index + 1);

        if (index === length) {
          setIndex(0);
        }
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [index, mouseOutEvent, mouseOverEvent]);

  return (
    <ul
      id="tutorial-player-reactions-menu"
      role="menu">
      {InputPlayerReactionNames.map((name, index) => {
        const iconElem = ReactionSVGIconLookup[name];
        return (
          <Tooltip
            key={name}
            className="tutorial-player-tooltip"
            arrowClassName="tutorial-player-tooltip-arrow"
            content={iconElem.tooltip}>
            <li
              className="menu-list-item"
              ref={el => itemsRef.current[index] = el}>
              <button
                className="player-reactions-menu-btn"
                role="menuitem">
                <span className="icon">
                  {iconElem.component}
                </span>
              </button>
            </li>
          </Tooltip>
        );
      })}
    </ul>
  )
}