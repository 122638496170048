import './playerFrameset.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { PlayerFramesetState } from './playerFramesetState';
import { FrameWrapper, SectionElement } from './frameWrapper';
import { useSizeReporter } from '../../core/dom/sizeReporter';
import { SectionStatus } from '../../pages/userPlayerPage/userPlayerPageState';

type Props = {
  model: PlayerFramesetState,

  player?: SectionElement,
  toolbar?: SectionElement,
  transcripts?: SectionElement,
  index?: SectionElement,
  comments?: SectionElement
}

export const PlayerFrameset = observer(({
  model,
  player,
  toolbar,
  transcripts,
  index,
  comments
}: Props) => {

  const showPlayer = model.isSectionVisible('Player');
  const showToolbar = model.isSectionVisible('Toolbar');
  const showTranscripts = model.isSectionVisible('Transcripts');
  const showIndex = model.isSectionVisible('Index');
  const showComments = model.isSectionVisible('Comments');

  const widgetMode = model.isWidgetMode;
  const emptyComments = model.getSectionStatus('Comments') === SectionStatus.Empty;
  const loadingComments = model.getSectionStatus('Comments') === SectionStatus.Loading;

  const applyCollapsedCommentsClass =
    (emptyComments || loadingComments) &&
    !(model?.isWidgetMode && model.tutorialMode);

  const className = classNames('user-player-frameset', {
    'show-player': showPlayer,
    'show-toolbar': showToolbar,
    'show-transcripts': showTranscripts,
    'show-index': showIndex,
    'show-comments': showComments,
    'collapsed-comments': applyCollapsedCommentsClass,
    'widget-mode': model.isWidgetMode,
    'page-mode': !model.isWidgetMode
  });

  const sizeRef = useSizeReporter('theater');

  const showBottomTranscripts = widgetMode ?
    (showTranscripts || showIndex) :
    (showTranscripts);

  const showRightTranscripts = widgetMode ?
    false :
    (!showIndex && !showComments && showTranscripts);

  return (
    <div id="user-player-frameset"
      className={className}>

      <div className="right-frameset">
        <div className="transcripts-frame right-transcripts-frame frame">
          <FrameWrapper
            model={model}
            frame="RightTranscripts"
            section="Transcripts"
            content={transcripts}
            frameVisible={showRightTranscripts} />
        </div>

        <div className="index-frame frame">
          <FrameWrapper
            model={model}
            frame="Index"
            section="Index"
            content={index}
            frameVisible={showIndex} />
        </div>

        <div className="comments-frame frame">
          <FrameWrapper
            model={model}
            frame="Comments"
            section="Comments"
            content={comments}
            frameVisible={showComments} />
        </div>
      </div>

      <div className="left-frameset">

        <div className="transcripts-frame bottom-transcripts-frame frame">
          <FrameWrapper
            model={model}
            frame="BottomTranscripts"
            section="Transcripts"
            content={transcripts}
            frameVisible={showBottomTranscripts} />
        </div>

        <div className="theater-frameset"
          ref={sizeRef.ref}>

          <div className="theater-stage">
            <div className="player-frame frame">
              <FrameWrapper
                model={model}
                frame="Player"
                section="Player"
                content={player} />
            </div>
            <div className="toolbar-frame frame">
              <FrameWrapper
                model={model}
                frame="Toolbar"
                section="Toolbar"
                content={toolbar} />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
});