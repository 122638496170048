/**
 * This is an adapter for the Player Widget to comply with Player.js
 * https://github.com/embedly/player.js/blob/master/SPEC.rst
 */

import { action, computed, makeObservable } from "mobx";
import { PlayerState } from "../../components";
import { PlayerAdapterState } from '../../components/playerAdapter'
import { Context, Methods, Version, MediaEventType, Events, MethodType } from '../../widgets/playerWidget/playerWidgetUtils';
import { BindingProps, Message, StoreNode } from "../../store";
import { Store } from "../../store/store";

type Props = BindingProps<{
  player: PlayerState
}>

export class PlayerWidgetAdapter
  extends StoreNode {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.autoListen('adapter', this.adapterListener);

    this.parent = window.parent;
    window.addEventListener('message', this.connectionListener);
  }

  readonly parent: Window;
  readonly listeners: Map<MediaEventType | MethodType, string | null> = new Map();

  @computed
  get player(): PlayerState {
    return this.resolvedProps.player;
  }

  @computed
  get adapter(): PlayerAdapterState {
    return this.player.adapter;
  }

  private adapterListener = (msg: Message<PlayerAdapterState>) => {
    const { type, payload } = msg;

    switch (type) {
      case 'ready': {
        const value = {
          src: window.location.href,
          events: Events,
          methods: Methods
        }
        this.sendEvent(type, { value });
        break;
      }
      case 'timeupdate': {
        if (this.listeners.has('timeupdate')) {
          this.sendEvent('timeupdate', {
            value: {
              seconds: this.player.currentTime,
              duration: this.player.duration
            }
          });
        }
        break;
      }
      default: {
        const event = type as MediaEventType | MethodType;

        if (this.listeners.has(event)) {
          this.sendEvent(event, {
            value: payload || {}
          });
        }
        break;
      }
    }
  }

  private connectionListener = (msg: MessageEvent) => {
    let data: any;
    try {
      data = JSON.parse(msg.data);
    } catch (err) { }

    if (!data || data.context !== Context) {
      return;
    }

    const { method, value, listener } = data;
    if (!Methods.includes(method)) {
      return;
    }

    switch (method) {
      case 'addEventListener': {
        this.handleAddEventListener(value, listener);
        break;
      }
      case 'removeEventListener': {
        this.handleRemoveEventListener(value);
        break;
      }
      case 'getMuted': {
        this.sendEvent('getMuted', { value: this.player.isMuted }, listener);
        break;
      }
      case 'getVolume': {
        this.sendEvent('getVolume', { value: this.player.volume * 100 }, listener);
        break;
      }
      case 'getPaused': {
        this.sendEvent('getPaused', { value: this.player.isPaused }, listener);
        break;
      }
      case 'getDuration': {
        this.sendEvent('getDuration', { value: this.player.duration }, listener);
        break;
      }
      case 'getCurrentTime': {
        this.sendEvent('getCurrentTime', { value: this.player.currentTime }, listener);
        break;
      }
      case 'setCurrentTime': {
        this.player.invoke('seek', { time: value });
        break;
      }
      case 'setVolume': {
        this.adapter.invoke('setVolume', { volume: value / 100 || 1 });
        break;
      }
      default: {
        this.player.invoke(method, value);
        break;
      }
    }
  }

  @action
  reset() {
    window.removeEventListener('message', this.connectionListener);
  }

  @action
  private handleAddEventListener(event: MediaEventType | MethodType, listener: string | null = null) {
    this.listeners.set(event, listener);
  }

  @action
  private handleRemoveEventListener(event: MediaEventType | MethodType) {
    this.listeners.delete(event);
  }

  @action
  private sendEvent(event: MediaEventType | MethodType, value?: any, listener?: string) {
    this.ensureListener(event, listener);

    const message = this.wrapEvent(event, value);
    this.parent.postMessage(message, '*');
  }

  @action
  private ensureListener(event: MediaEventType | MethodType, listener?: string | null) {
    if (!listener) return;

    if (!this.listeners.has(event)) {
      this.handleAddEventListener(event, listener);
    } else if (this.listeners.get(event) !== listener) {
      this.listeners.set(event, listener);
    }
  }

  @action
  private wrapEvent(event: MediaEventType | MethodType, payload?: any): string {
    const listener = this.listeners.get(event);

    return JSON.stringify({
      context: Context,
      version: Version,
      event,
      listener,
      ...payload
    });
  }
}