import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, CheckboxInput, SortFieldButton } from '../input';
import { CatalogState } from '../layout/catalogState';
import { LibraryCatalogSource } from '../../entities';
import { IntegrationTabState } from '../../pages/integrations/integrationTabState';
import { useParams } from 'react-router';
import { UploadPageParams } from '../../pages';

type Props = {
  model: CatalogState;
  source: LibraryCatalogSource;
  integration: IntegrationTabState;
  teamId?: string;
};

export const GoogleDriveTableCatalogHeader = observer(
  ({ model, source, integration, teamId }: Props) => {
    const params = useParams<UploadPageParams>();
    const { search } = window.location;
    const query = new URLSearchParams(search);
    const computedTeamId = params?.teamId || query.get('teamId') || teamId;

    return (
      <div className="job-table-catalog-header google-drive-table-catalog catalog-filters">
        <table className="job-table-header job-table-core">
          <thead>
            <tr>
              <th className="select-col">
                <CheckboxInput
                  name="select-all"
                  checked={source.hasMultipleSelection}
                  deselectCheck={!source.hasAllAvailableForIngestFilesSelected}
                  onChange={() => source.selectAllFilesAction()}
                  disabled={
                    !source.entities.length ||
                    !source.availableforIngestEntities.length
                  }
                />
              </th>
              <th className="thumb-col">
                {source.hasMultipleSelection && (
                  <Button
                    className="primary small"
                    label="Ingest Selected"
                    onClick={() => integration.bulkIngest(computedTeamId)}
                  />
                )}
              </th>
              <th className="title-col">
                <SortFieldButton label="Title" name="name" source={source} />
              </th>

              <th className="duration-col">
                <SortFieldButton
                  label="Duration"
                  name="durationInMs"
                  source={source}
                  disabled
                />
              </th>

              <th className="upload-col">
                <SortFieldButton
                  label="Date"
                  name="modifiedTime"
                  source={source}
                />
              </th>

              <th className="user-upload">
                <Button
                  className="upload-btn secondary btn-outline medium"
                  label="Disconnect Account"
                  onClick={() => model.store.googleDrive.deleteLibrary(source)}
                />
              </th>
            </tr>
          </thead>
        </table>
      </div>
    );
  }
);
