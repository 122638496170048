import './masterSidebar-v1-1.scss';

import React, { RefObject, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink, useLocation } from 'react-router-dom';
import {
  BurgerSVGIcon,
  CliprLogoSVGIcon,
  MyUploadsSVGIcon,
  SidebarBookmarkSVGIcon,
  SideBarPlusSVGIcon,
  SearchSVGIcon,
  SunSVGIcon,
  MoonSVGIcon,
  AnalyticsSVGIcon,
} from '../svg';
import { TrainerLinkSVGIcon, UsersLinkSVGIcon, AdminTeamsLinkSVGIcon } from '../svg/svg';
import Routes, { BackRoutes } from '../../routes';
import { useStore } from '../../store/storeHooks';
import { MasterSidebarState } from './masterSidebarState';
import { UserProfile } from '../../entities';
import { DotSpinner } from '../loader';
import { TeamAvatar } from '../teams';
import { ToggleInputControlled } from '../input/toggleInput';
import { Button, SearchTextInputControlled } from '../input';

type Props = {
  /** Alternative state to be used instead of the default one from Store.pageSidebar. */
  model?: MasterSidebarState;
  user?: UserProfile;
};

const useScrollToTeam = (
  model: MasterSidebarState,
  teamList: RefObject<HTMLUListElement>
) => {
  useEffect(() => {
    const hasCurrentTeamLoaded =
      model.currentTeamId && !model.isTeamListLoading && model.teams.length > 0;

    if (hasCurrentTeamLoaded && teamList.current) {
      for (const item of teamList.current.childNodes) {
        const li = item as HTMLElement;

        if (li.dataset.teamId === model.currentTeamId) {
          const container = teamList.current.parentElement || teamList.current;
          // container.scrollTop = li.offsetTop;

          const scrollElementTop = container.getBoundingClientRect().top;   //top value of the scroll element relative to the viewport
          const scrollElementScrollTop = container.scrollTop;               //how much is the element scrolled (always positive or 0)
          const domMomentTop = li.getBoundingClientRect().top;

          const topValue =
            (domMomentTop - scrollElementTop) // determine the position of the scroll item relative to the top of the scroll element
            + scrollElementScrollTop          // add it to the already scrolled value

          container.scrollTo({
            top: topValue,
            // removed the smooth scrolling because the sidebar rerenders each route change
            // causing the container to "lazy" scroll to the previously selected team
            // behavior: 'auto'
          })

          break;
        }
      }
    }
  }, [
    model.currentTeamId,
    model.isTeamListLoading,
    model.teams.length,
    teamList,
  ]);
};

export const MasterSidebar = observer((props: Props) => {
  const store = useStore();

  let model = props.model || store?.pageSidebar || null;

  const showUsersBtn = store.user?.hasPermission('ViewAdminUsersPage');
  const showTrainerBtn = store.user?.hasPermission('ViewTrainerDashboard');
  const showAdminTeamsBtn = store.user?.hasPermission('AdminGetTeams');
  const showSettingsBtn = store.user?.hasPermission('ViewSettingsPage');
  const showMyAnalyticsBtn = store.user?.hasPermission('ViewAnalytics');

  model.toggleModel.handleChange = (evt, value) => {
    if (value) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', '');
    }
    model.toggleModel.value = value;
  };

  useEffect(() => {
    model.mounted();
    return () => model.unmounted();
  }, [model]);

  return (
    <aside
      className="master-sidebar"
      aria-expanded={model ? model.isExpanded : true}>
      <header className="sidebar-header">
        <div className="sidebar-logo">
          <div className="clipr-logo">
            {model?.isExpanded && (
              <div>
                <CliprLogoSVGIcon />
              </div>
            )}
          </div>
        </div>
        <button
          className="expand-btn"
          onClick={model?.handleCollapseButtonClick}>
          <BurgerSVGIcon />
        </button>
      </header>
      <nav className="sidebar-nav">
        <ul
          className="sidebar-nav-list"
          aria-expanded={model ? model.isExpanded : true}>
          {showUsersBtn && (
            <li className="sidebar-nav-item">
              <NavLink
                to={Routes.userDashboard()}
                className="btn btn-core sidepanel-sidebar"
                aria-current="page"
                activeClassName="selected">
                <span className="icon">
                  <UsersLinkSVGIcon />
                </span>
                <span className="text">Users</span>
              </NavLink>
            </li>
          )}

          {showTrainerBtn && (
            <li className="sidebar-nav-item">
              <NavLink
                to={Routes.trainerDashboard()}
                className="btn btn-core sidepanel-sidebar"
                aria-current="page"
                activeClassName="selected">
                <span className="icon">
                  <TrainerLinkSVGIcon />
                </span>
                <span className="text">Trainer</span>
              </NavLink>
            </li>
          )}

          {showAdminTeamsBtn && (
            <li className="sidebar-nav-item">
              <NavLink
                to={Routes.adminTeamsDashboard()}
                className="btn btn-core sidepanel-sidebar"
                aria-current="page"
                activeClassName="selected">
                <span className="icon">
                  <AdminTeamsLinkSVGIcon />
                </span>
                <span className="text">Libraries Admin</span>
              </NavLink>
            </li>
          )}

          <li data-navItem={'myUploads'} className="sidebar-nav-item">
            <NavLink
              className="btn btn-core sidepanel-sidebar"
              aria-current="page"
              activeClassName="selected"
              aria-expanded={model ? model.isExpanded : true}
              to={Routes.userDashboard()}>
              <span className="myclipr icon">
                <MyUploadsSVGIcon />
              </span>
              <span className="text">My Uploads</span>
            </NavLink>
          </li>

          <li className="sidebar-nav-item">
            <NavLink
              className="btn btn-core sidepanel-sidebar"
              aria-current="page"
              aria-expanded={model ? model.isExpanded : true}
              activeClassName="selected"
              to={Routes.bookmarks()}>
              <span className="icon">
                <SidebarBookmarkSVGIcon />
              </span>
              <span className="text">My bookmarks</span>
            </NavLink>
          </li>

          {showMyAnalyticsBtn && (<li className="sidebar-nav-item">
            <NavLink
              className="btn btn-core sidepanel-sidebar"
              aria-current="page"
              aria-expanded={model ? model.isExpanded : true}
              activeClassName="selected"
              to={Routes.analytics()}>
              <span className="icon">
                <AnalyticsSVGIcon />
              </span>
              <span className="text">My Analytics</span>
            </NavLink>
          </li>)}

          {showSettingsBtn && (
            <li className="sidebar-nav-item">
              <Button
                className="btn btn-core sidepanel-sidebar"
                label="Settings"
              />
            </li>
          )}

          <li className="divider" />

          <MasterSidebarTeamList model={model} />
        </ul>
      </nav>

      {false && (
        <div className="sidebar-footer">
          <div className="theme-toggle">
            <div
              className={`theme theme-light ${!model.toggleModel.value ? 'active' : ''
                }`}>
              <SunSVGIcon />
            </div>
            <div>
              <ToggleInputControlled
                model={model.toggleModel}
                label=""
                placeholder=""
                className="large"
              />
            </div>
            <div
              className={`theme theme-dark ${model.toggleModel.value ? 'active' : ''
                }`}>
              <MoonSVGIcon />
            </div>
          </div>
        </div>
      )}
    </aside>
  );
});

const MasterSidebarTeamList = observer(
  ({ model }: { model: MasterSidebarState }) => {
    const showCreateTeamBtn = model.store.user?.hasPermission('CreateTeam');
    const { pathname } = useLocation();
    const createTeamLink = BackRoutes[Routes.createTeam()].route({
      currentPath: pathname,
    });

    const teamList = useRef<HTMLUListElement>(null);

    useScrollToTeam(model, teamList);

    return (
      <>
        <li className="my-teams">
          {((!model.isSearchToggled && model.isExpanded) ||
            !model.isExpanded) && <span>My Libraries</span>}
          {model.isSearchToggled && model.isExpanded && (
            <div className="team-search">
              <SearchTextInputControlled
                model={model.teamSearchInput}
                persistentFeedback={false}
                id="search"
                name="search"
                type="text"
                autoComplete="off"
                placeholder="Search"
                showFeedback={false}
                alwaysShowClear={true}
                autoFocus
                onClear={() => model.handleSearchInputClear()}
              />
            </div>
          )}
          <div className="my-teams-buttons">
            {!model?.isSearchToggled && (
              <button
                className="btn btn-icon text-opacity-1 my-teams-button search-button"
                onClick={model?.handleToggleSearch}
              >
                <span className="icon">
                  <SearchSVGIcon />
                </span>
              </button>
            )}
            {showCreateTeamBtn && (
              <NavLink className="create-team-link" to={createTeamLink}>
                <button className="btn btn-icon text-opacity-1 my-teams-button">
                  <span className="icon">
                    <SideBarPlusSVGIcon />
                  </span>
                </button>
              </NavLink>
            )}
          </div>
        </li>
        {!model.isExpanded && (
          <NavLink
            className="create-team-link-collapsed"
            to={Routes.createTeam()}
          >
            <div className="my-teams-button">
              <button className="btn btn-icon medium">
                <span className="icon">
                  <SideBarPlusSVGIcon />
                </span>
              </button>
            </div>
          </NavLink>
        )}
        <li className="sidebar-nav-item sidebar-teams">
          <div className="sidebar-nav-scrollable">
            <ul className="sidebar-nav-sub-list bpad1" ref={teamList}>
              {model.isTeamListLoading && (
                <li
                  key="_loading_"
                  className="sidebar-nav-sub-item loading-item"
                >
                  <span className="icon">
                    <DotSpinner dots={3} className="small white" />
                  </span>
                </li>
              )}

              {!model.isTeamListLoading &&
                model.teams.map((team) => (
                  <li
                    key={team.id}
                    className="sidebar-nav-sub-item"
                    data-team-id={team.id}
                  >
                    <NavLink
                      className="btn btn-core sidepanel-sidebar"
                      aria-current="page"
                      aria-expanded={model ? model.isExpanded : true}
                      activeClassName="selected"
                      isActive={(match) =>
                        !!match ||
                        (model.currentTeamId === team.id &&
                          model.isTeamAnalytics)
                      }
                      to={Routes.teamDashboard(team.id)}
                    >
                      <TeamAvatar className="icon" model={team} />
                      <span className="text">{team.name}</span>
                    </NavLink>
                  </li>
                ))}
            </ul>
          </div>
        </li>
      </>
    );
  }
);