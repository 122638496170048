import React from 'react';
import { observer } from 'mobx-react-lite';
import { PlayerState } from '../playerState';
import { CliprSpinner } from '../../loader/cliprSpinner';
import { PageBackButton } from '../../layout/pageBackButton';
import { LogoSpinner } from '../../loader/logoSpinner';
import { useStore } from '../../../store/storeHooks';
import { JobModel } from '../../../entities';
import { useClock } from '../../../core/dateTime';
import { Config } from '../../../config';
import { useRoutingService } from '../../../services/routing/routingHooks';


type Props = {
  model?: PlayerState,
  job?: JobModel | null,
}

export const PlayerLiveEndedScreen = observer((props: Props) => {
  const { model, job } = props;

  const store = useStore();
  const routingService = useRoutingService();
  const { logo } = store.uiService;

  const poster = model?.job?.posterURL ?? '/img/LiveThumbnailPlaceholder.png';
  let coverStyle = {};

  if (!logo && poster) {
    coverStyle = {
      backgroundImage: `url('${poster}')`,
    };
  }

  const showBackButton = routingService.getBackRoute();
  const clock = useClock();

  const isEnded = job?.isLiveStreamEnded ?? null;

  const endedDate = job?.liveStreamEndDate ?? null;
  const endedMessageDelta = Config.player.liveStreamEndedMessageDelta; // in seconds
  const endedMessageDeltaPassed = endedDate ? (clock.diff(endedDate).as('seconds') > endedMessageDelta) : false;

  const showProcessingScreen = !isEnded; // TODO: needs to be less confusing
  const showEndedScreen = isEnded && endedMessageDeltaPassed;
  const showPausedScreen = isEnded && !endedMessageDeltaPassed;

  const spinnerElem = logo ?
    <LogoSpinner logo={logo} /> :
    <CliprSpinner />;

  return (
    <div className="live-cover" style={coverStyle}>
      <div id="player-live-ended-screen"
        className="player-live-status-screen">

        {showBackButton &&
          <div className="live-back-button">
            <PageBackButton />
          </div>}

        {showProcessingScreen &&
          <>
            {spinnerElem}
            <span className="info-live">This live stream has ended and we're currently processing your video</span>
          </>}

        {showEndedScreen &&
          <span className="info-live">This live stream has ended</span>}

        {showPausedScreen &&
          spinnerElem}
      </div>
    </div>
  );
});