import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { PostRegistrationTutorialState } from './postRegistrationTutorialState';
import { Window, WindowHeader } from '../../../components/overlays';
import { Button } from '../../../components';
import { usePopper } from 'react-popper';

import '../authPages.scss';

type Props = {
  model: PostRegistrationTutorialState;
  visible?: boolean;
};
export const PostRegistrationTutorialWindow = observer((props: Props) => {
  const { model } = props;

  const isMobile = !!model?.store.ui.isMobile;

  const [popperElement, setPopperElement] = useState(null);
  const anchorElement = document.querySelector('[data-navItem=myUploads]');
  const { styles } = usePopper(anchorElement, popperElement, {
    placement: 'right',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: isMobile ? [-520, -80] : [-180, 0]
        }
      }
    ]
  });

  return (
    <Window
      innerRef={setPopperElement as any}
      className='register-tutorial-window'
      model={model.window}
      style={styles.popper}
      visible={props.visible}>

      <WindowHeader
        model={model.window}
        title='Get started with Clipr' />

      <section className="tutorial-content">
        <div className="tutorial-boxes">
          <img
            className="tutorial-img"
            src={`/img/signup-tutorial${isMobile ? '-mobile' : ''}.png`} alt="Tutorial" />
        </div>

        <div className="tutorial-player">
          {!isMobile &&
            <p className="text">
              OR
            </p>}
          <strong>Play with this sample to see how CLIPr works</strong>
          <img
            className="tutorial-player-img"
            src='/img/signup-player.png' alt='CLIPr Player' />

          {!isMobile &&
            <Button
              route='/user/video/08faa642-9b03-4084-8c2e-cddef4626fc0?teamId=fa5e6327-3953-4e91-a3b5-bcbe3df9e5f3'
              target='_blank'
              className="secondary medium"
              label="Get Started"
            />}
        </div>
      </section>

    </Window>
  )
});