import { getFlagMask, hasFlagMask } from '../../core';
import { MomentModel } from '../moment';
import { MomentEdge, MomentEdgeFlags } from './momentEdge';

type Moment = MomentModel;

let flagCursor = 0;
let flag = (label?: string) => 1 << flagCursor++

export enum MomentNodeFlags {
  None = 0,

  Topic = flag('Topic'),
  Transcript = flag('Transcript'),
  Generic = flag('Generic'),
  HasConnected = flag('HasConnected')
}


export interface IMomentNode {
  readonly key: string;
  readonly index: number;

  readonly moment: Moment;

  readonly edges: MomentEdge[];
}

export class MomentNode {

  constructor(index: number, moment: Moment) {
    this.index = index;
    this.key = moment.id;

    this.moment = moment;
  }

  readonly key: string;
  readonly index: number;

  readonly moment: Moment;

  readonly flags = new Set<MomentNodeFlags>();
  flagMask: MomentNodeFlags = MomentNodeFlags.None;

  readonly edges: MomentEdge[] = [];
  readonly incomingEdges: MomentEdge[] = [];
  readonly outgoingEdges: MomentEdge[] = [];

  readonly searchTokens = new Set<string>();

  commit(): MomentNode {
    this.flagMask = getFlagMask(this.flags);

    const { moment } = this;
    const tokens = this.searchTokens;

    moment.name
      ?.toLowerCase()
      ?.match(/\w+/g)
      ?.forEach(token => tokens.add(token));

    moment.description
      ?.toLowerCase()
      ?.match(/\w+/g)
      ?.forEach(token => tokens.add(token));

    moment.keywords
      ?.join(' ')
      ?.toLowerCase()
      ?.match(/\w+/g)
      ?.forEach(token => tokens.add(token));

    Object.freeze(this);
    return this as MomentNode;
  }

  hasFlag(flag: MomentNodeFlags) {
    return this.flags.has(flag);
  }
  hasFlagMask(flag: MomentNodeFlags) {
    return hasFlagMask(this.flagMask, flag);
  }

  filterOutgoingEdges(
    flags: MomentEdgeFlags | MomentEdgeFlags[]): MomentEdge[] {

    return this.outgoingEdges
      .filter(edge => edge.hasFlagMask(getFlagMask(flags)));
  }

  filterOutgoingEdgeNodes(
    edgeFlags: MomentEdgeFlags | MomentEdgeFlags[],
    nodeFlags: MomentNodeFlags | MomentNodeFlags[]): MomentNode[] {

    return this.filterOutgoingEdges(edgeFlags)
      .map(edge => edge.target)
      .filter(node => node.hasFlagMask(getFlagMask(nodeFlags)));
  }
}