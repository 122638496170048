import { UserProfile } from '../../entities';
import { AuthPermit } from './authPermit';
import { AuthContextType, IAuthContext } from './authSchema';

type Props = {
  permit: AuthPermit;
  profile: UserProfile;
  allowActions?: boolean | null;
}

export class AuthenticatedAuthContext
  implements IAuthContext {

  constructor(props: Props) {

    this.permit = props.permit;
    this.profile = props.profile;
    this.allowActions = props.allowActions ?? true;

    Object.freeze(this);
  }

  readonly type: AuthContextType.Authenticated = AuthContextType.Authenticated;
  readonly isAuthenticated: true = true;
  readonly isAnonymous: false = false;

  readonly permit: AuthPermit;
  readonly profile: UserProfile;

  readonly allowActions: boolean = true;

  get isValid() {
    return this.permit.isValid ?? false;
  }
}