import { observer } from 'mobx-react-lite';
import React from 'react';
import { MomentModel } from '../../entities/moment';
import { ArrowRightSVGIcon } from '../svg';

export const PlayerMomentTime = observer(({ model }: { model: MomentModel }) => {

  return (
    <div className="user-player-moment-time">
      <span className="time text">
        <span className="text">{model?.startTimeLabel || '0:00'}</span>
        <span className="icon"><ArrowRightSVGIcon /></span>
        <span className="text">{model?.endTimeLabel || '0:00'}</span>
      </span>
    </div>
  );
});