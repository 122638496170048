import { makeObservable } from 'mobx';
import { Store } from '../../../store/store';
import { StoreNode } from '../../../store';
import { Error } from '../../../core/error';
import { RouteContext } from '../../../routes/routeContext';
import { AuthFlowName, AuthFlowResponse, IAuthFlow } from '../authFlowSchema';
import { AsyncResult } from '../../../core';
import { AuthStepOrchestrator } from '../authStepOrchestrator';
import { LibraryName } from '../../libraries/librarySchema';

type Props = {
  routeContext: RouteContext;
  libraryName: LibraryName;
}

export type ProxyAuthorizeLibraryServerFlowParams = Props;

export class ProxyAuthorizeLibraryServerFlow
  extends StoreNode
  implements IAuthFlow {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);
  }

  readonly flowName = AuthFlowName.ProxyAuthorizeLibraryServer;
  readonly isLibraryFlow = true;

  get routeContext(): RouteContext {
    return this.props.routeContext;
  }
  get libraryName(): LibraryName {
    return this.props.libraryName;
  }

  readonly orchestrator = new AuthStepOrchestrator(this.store);

  async run(): AsyncResult<AuthFlowResponse> {
    return [null, new Error('InternalError', `The ProxyAuthorizeLibraryServerFlow can only be restored after redirect.`)];
  }

  async restoreAfterRedirect(): AsyncResult<AuthFlowResponse> {

    const { orchestrator, routeContext, libraryName } = this;
    
    const [, libraryPermitErr] = orchestrator.createLibraryPermitFromRoute(routeContext, libraryName);
    if (libraryPermitErr)
      return orchestrator.setError(libraryPermitErr);

    const [, sendErr] = await orchestrator.sendProxyAuthorizeLibraryResult();
    if (sendErr)
      return orchestrator.setError(sendErr);

    return orchestrator.setAwaitProxyClose();
  }
}