import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ApiResponse, ApiVariables } from '../../../api/apiSchema';
import { AsyncResult, Result } from '../../../core';
import { BindingProps, StoreNode } from '../../../store';
import { IChartDataSource } from '../chartSchema';
import { ApiRequestOptions, ApiResult } from '../../../api/apiSchema';
import { Store } from '../../../store/store';
import { Error, error } from '../../../core/error';
import { parseKeenJsonResult } from '../utils/parseKeenJsonResult';
import { AudienceFocusChartParams, AudienceFocusChartMode, AudienceFocusChartDatum, AudienceFocusChartData, AudienceFocusChartSeriesData } from './audienceFocusChartSchema';
import { ApiError } from '../../../api/apiError';

type Props = BindingProps<{
  params: AudienceFocusChartParams,
  suppressFetchOnParamsChange?: boolean
}>

export class AudienceFocusDataSource
  extends StoreNode
  implements IChartDataSource<AudienceFocusChartSeriesData> {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.onPropChange('params',
      this.handleParamsChange);
  }

  // #region Props
  @computed get params(): AudienceFocusChartParams | null {
    return this.getResolvedProp('params') ?? null;
  }
  set params(val: AudienceFocusChartParams | null) {
    this.setProp('params', val);
  }

  @computed get suppressFetchOnParamsChange(): boolean | null {
    return this.getResolvedProp('suppressFetchOnParamsChange') ?? null;
  }
  set suppressFetchOnParamsChange(val: boolean | null) {
    this.setProp('suppressFetchOnParamsChange', val);
  }
  // #endregion

  // #region Handlers
  private handleParamsChange = () => {
    // this.fetch();
  }
  // #endregion

  @computed get mode(): AudienceFocusChartMode {
    return this.params?.mode ?? AudienceFocusChartMode.Topics;
  }

  readonly data = observable.array<AudienceFocusChartSeriesData>([], { deep: false });

  private abortController: AbortController | null = null;

  @observable isFetching = false;
  @observable error: Error | null = null;

  @action
  reset() {
    this.abort();

    this.isFetching = false;
    this.error = null;
    this.abortController = null;

    this.data.clear();
  }

  @action
  async fetch(): AsyncResult {

    this.reset();

    this.isFetching = true;

    const abortCtrl = new AbortController();
    this.abortController = abortCtrl;

    const { params } = this;
    if (!params)
      return [null];

    const reqArgs: ApiVariables<'keenBatch'> = {
      args: {
        start: params.startDate?.toISO()!,
        end: params.endDate?.toISO()!,
        timezone: params.timezone ?? undefined,
        teamId: params.teamId ?? undefined,
        limit: 10
      }
    };

    const api = this.store.api;
    const reqOpts: ApiRequestOptions = {
      signal: abortCtrl.signal
    };

    let reqPromise: ApiResult<'keenBatch'> | null = null;

    switch (params.mode) {
      default:
      case AudienceFocusChartMode.Topics:
        reqPromise = api.runQuery('keen_getTopicViews', reqArgs, reqOpts);
        break;

      case AudienceFocusChartMode.SubTopics:
        reqPromise = api.runQuery('keen_getSubTopicViews', reqArgs, reqOpts);
        break;

      case AudienceFocusChartMode.MostBookmarkedTopics:
        reqPromise = api.runQuery('keen_getMostBookmarkedTopics', reqArgs, reqOpts);
        break;

      case AudienceFocusChartMode.MostBookmarkedSubtopics:
        reqPromise = api.runQuery('keen_getMostBookmarkedSubtopics', reqArgs, reqOpts);
        break;
    }

    if (!reqPromise) {
      console.error('Mode not implemented');
      return this.handleError(error('Unknown', `Invalid mode ${params.mode} was provided to TopicViewsDataSource`));
    }

    const [res, err] = await reqPromise;
    if (err)
      // @ts-ignore TODO: make ApiError extend our own Error class
      return this.handleError(err);

    const [data, insertErr] = this.insert(res!);
    if (insertErr)
      return this.handleError(insertErr);

    runInAction(() => {
      this.isFetching = false;
    });

    return [data!];
  }

  cancel(): void {
    this.abort();
  }

  @observable isBatchRequesting = false;

  notifyBatchRequestStarted() {
    this.isBatchRequesting = true;
  }
  notifyBatchRequestCompleted() {
    this.isBatchRequesting = false;
  }

  @action
  insert(res: ApiResponse<'keenBatch'>): Result<AudienceFocusChartData> {

    let dataRes: Result<AudienceFocusChartData>;

    switch (this.mode) {
      default:
      case AudienceFocusChartMode.Topics:
        dataRes = getDataFromTopicViews(res);
        break;

      case AudienceFocusChartMode.SubTopics:
        dataRes = getDataFromSubTopicViews(res);
        break;

      case AudienceFocusChartMode.MostBookmarkedTopics:
        dataRes = getDataFromMostBookmarkedTopics(res);
        break;

      case AudienceFocusChartMode.MostBookmarkedSubtopics:
        dataRes = getDataFromMostBookmarkedSubtopics(res);
        break;
    }

    const [data, err] = dataRes;
    if (err) {
      return this.handleError(err);
    }

    this.data.replace(data ?? []);
    return [data ?? []];
  }

  private abort() {

    const lastAbortCtrl = this.abortController;
    if (lastAbortCtrl)
      lastAbortCtrl.abort();
    this.abortController = null;
  }

  @action
  private handleError(err?: Error | string): Result {
    if (!err)
      err = new Error();
    if (typeof err === 'string')
      err = new Error('Unknown', err);

    if (err instanceof ApiError && err.type === 'RequestAborted') {
      // for aborted requests we only return the error but we don't set it on the instance and we do not repor it as error
      // console.warn(`Request aborted in TopicViewsDataSource`);
      return [null, err];

    } else {
      console.error(`An error occured in TopicViewsDataSource: `, err);

      this.isFetching = false;
      this.abort();
      this.error = err;

      return [null, err];
    }
  }
}

function getDataFromTopicViews(data: ApiResponse<'keenBatch' | 'keen_getTopicViews'>): Result<AudienceFocusChartData> {

  const apiResultStr = data.keenBatch.topicViews?.result;
  const [apiResult, err] = parseKeenJsonResult<any>(apiResultStr);
  if (err)
    return [null, err];

  const resMap = new Map<string, AudienceFocusChartDatum>();
  apiResult!
    .forEach((data: any) => {
      const topicName = data?.['player.activeTopicName'];

      if (!topicName) return null;

      if (resMap.has(topicName)) {
        const topic = resMap.get(topicName);
        topic!.value += data.result;
        topic?.tooltip.speakerNames.push(data['player.speakerName'])
      } else {
        resMap.set(topicName, {
          date: topicName,
          value: data?.result ?? 0,
          tooltip: {
            jobTitle: data['job.title'],
            speakerNames: [data['player.speakerName']]
          }
        });
      }
    });

  const outData: AudienceFocusChartData = [{
    dataKey: 'Most Popular Topics',
    data: [...resMap.values()].sort((a, b) => a.value - b.value)
  }];

  return [outData];
}


function getDataFromSubTopicViews(data: ApiResponse<'keenBatch' | 'keen_getSubTopicViews'>): Result<AudienceFocusChartData> {

  const apiResultStr = data.keenBatch.subTopicViews?.result;
  const [apiResult, err] = parseKeenJsonResult<any>(apiResultStr);
  if (err)
    return [null, err];

  const resMap = new Map<string, AudienceFocusChartDatum>();

  apiResult!.forEach((data: any) => {
    const subTopicNames = data?.['player.activeSubTopicNames'];

    if (!Array.isArray(subTopicNames))
      return null!;

    subTopicNames.forEach(subTopicName => {
      if (resMap.has(subTopicName)) {
        const subTopic = resMap.get(subTopicName);
        subTopic!.value += data.result;
        subTopic!.tooltip.speakerNames.push(data['player.speakerName'])
      } else {
        resMap.set(subTopicName, {
          date: subTopicName,
          value: data?.result ?? 0,
          tooltip: {
            jobTitle: data['job.title'],
            speakerNames: [data['player.speakerName']]
          }
        });
      }
    }
    );
  });

  const outData: AudienceFocusChartData = [{
    dataKey: 'Most Popular SubTopics',
    data: [...resMap.values()].sort((a, b) => a.value - b.value)
  }];

  return [outData];
}

function getDataFromMostBookmarkedTopics(data: ApiResponse<'keenBatch' | 'keen_getMostBookmarkedTopics'>): Result<AudienceFocusChartData> {
  const apiResultStr = data.keenBatch.mostBookmarkedTopics?.result;
  const [apiResult, err] = parseKeenJsonResult<any>(apiResultStr);
  if (err)
    return [null, err];

  const resMap = new Map<string, AudienceFocusChartDatum>();
  apiResult!
    .forEach((data: any) => {
      const topicName = data?.['bookmark.momentName'];

      if (!topicName) return null;

      if (resMap.has(topicName)) {
        const topic = resMap.get(topicName);
        topic!.value += data.result;
        topic?.tooltip.speakerNames.push(data['bookmark.speakerName'])
      } else {
        resMap.set(topicName, {
          date: topicName,
          value: data?.result ?? 0,
          tooltip: {
            jobTitle: data['job.title'],
            speakerNames: [data['bookmark.speakerName']]
          }
        });
      }
    });

  const outData: AudienceFocusChartData = [{
    dataKey: 'Most Bookmarked Topics',
    data: [...resMap.values()].sort((a, b) => a.value - b.value)
  }];

  return [outData];
}

function getDataFromMostBookmarkedSubtopics(data: ApiResponse<'keenBatch' | 'keen_getMostBookmarkedSubtopics'>): Result<AudienceFocusChartData> {
  const apiResultStr = data.keenBatch.mostBookmarkedSubtopics?.result;
  const [apiResult, err] = parseKeenJsonResult<any>(apiResultStr);
  if (err)
    return [null, err];

  const resMap = new Map<string, AudienceFocusChartDatum>();
  apiResult!
    .forEach((data: any) => {
      const topicName = data?.['bookmark.momentName'];

      if (!topicName) return null;

      if (resMap.has(topicName)) {
        const topic = resMap.get(topicName);
        topic!.value += data.result;
        topic?.tooltip.speakerNames.push(data['bookmark.speakerName'])
      } else {
        resMap.set(topicName, {
          date: topicName,
          value: data?.result ?? 0,
          tooltip: {
            jobTitle: data['job.title'],
            speakerNames: [data['bookmark.speakerName']]
          }
        });
      }
    });

  const outData: AudienceFocusChartData = [{
    dataKey: 'Most Bookmarked Subtopics',
    data: [...resMap.values()].sort((a, b) => a.value - b.value)
  }];

  return [outData];
}
