import './buttons-v1-1.scss';

import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { assert, HTMLButtonProps, Maybe, ObjectLiteral } from '../../core';
import { InputState, InputStatus } from './inputState';
import { History, LocationDescriptor } from 'history';

export type ButtonRoute = string | ((router: History) => void) | LocationDescriptor;

type Props = HTMLButtonProps<{
  className?: string | ObjectLiteral;
  icon?: ReactNode;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  label?: ReactNode;
  isFocused?: boolean;
  isHovered?: boolean;
  status?: Maybe<InputStatus>;
  statusMessage?: Maybe<string>;
  route?: ButtonRoute;
  target?: string;
  active?: boolean;
  id?: string;
}>;
export type ButtonProps = Props;

export const Button = observer(
  ({
    className,
    icon,
    leftIcon,
    rightIcon,
    label,
    isFocused,
    isHovered,
    status,
    statusMessage,
    route,
    target,
    onClick,
    type = 'button',
    active,
    id,
    ...props
  }: Props) => {
    const router = useHistory();

    if (icon) leftIcon = icon;

    className = classNames('btn btn-core', className, {
      'btn-icon': leftIcon && !label,
      'text-btn': !leftIcon && label,
      active: active,
    });

    let clickHandler: React.MouseEventHandler | undefined;
    if (typeof onClick === 'function') {
      clickHandler = onClick;
    } else if (typeof route === 'function') {
      // custom route, no need to wrap the button in a Link
      clickHandler = (evt: React.MouseEvent) => route(router);
    } else {
    }

    let btnElem = (
      <button
        id={id}
        className={className}
        type={type}
        onClick={clickHandler}
        {...props}>
        {leftIcon && <span className="icon">{leftIcon}</span>}
        {label && <span className="text">{label}</span>}
        {rightIcon && <span className="icon">{rightIcon}</span>}
      </button>
    );

    if (route && typeof route !== 'function' && !clickHandler) {
      return (
        <Link
          id={id}
          to={route}
          aria-disabled={props.disabled}
          className={className}
          target={target}>
          {leftIcon && <span className="icon">{leftIcon}</span>}
          {label && <span className="text">{label}</span>}
          {rightIcon && <span className="icon">{rightIcon}</span>}
        </Link>
      );
    }

    return btnElem;
  }
);

type ControlledProps = React.InputHTMLAttributes<HTMLButtonElement> & {
  type?: 'submit' | 'reset' | 'button';
  label?: string;
  model: InputState;
  className?: string | ObjectLiteral;
  leftIcon?: ReactNode;
};

export const ButtonControlled = observer(
  ({ model, className, ...props }: ControlledProps) => {
    assert(
      model instanceof InputState,
      `The 'model' passed to ButtonControlled must be an instance of InputState. Instead received ${model}.`
    );

    className = model.getClassName('button-input', { className });

    return (
      <Button
        className={className}
        disabled={props.disabled || model.disabled}
        label={props.label || model.label!}
        onFocus={model.handleFocus}
        onBlur={model.handleBlur}
        onPointerEnter={model.handlePointerEnter}
        onPointerLeave={model.handlePointerLeave}
        status={model.status!}
        statusMessage={model.statusMessage}
        {...props}
      />
    );
  }
);
