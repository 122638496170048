import { useState } from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { useStore } from '../../store/storeHooks';
import { OverlayAnchorJSXProps, OverlayState } from './overlayState';

type Props = {
  overlays?: Iterable<OverlayState>
}

// type OverlayDOMEventType =
//   React.MouseEvent |
//   React.PointerEvent |
//   React.FocusEvent;

// type OverlayDOMEventName =
//   'mouseenter' |
//   'mouseleave' |
//   'click' |
//   'focus' |
//   'blur';

// type OverlayTargetType =
//   'Anchor' |
//   'Overlay';

export class OverlayGroupState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.overlays.replace([...(props?.overlays || [])]);
  }

  readonly overlays = observable.array<OverlayState>();

  @computed get exclusivelyVisibleOverlay(): OverlayState | null {
    return this.overlays.find(ovr => ovr.isExclusivelyVisible) ?? null;
  }

  handleAnchorMouseEnter = action((evt: React.MouseEvent) => {
    this.handleEvent('handleAnchorMouseEnter', evt);
  })
  handleAnchorMouseLeave = action((evt: React.MouseEvent) => {
    this.handleEvent('handleAnchorMouseLeave', evt);
  })
  handleAnchorClick = action((evt: React.MouseEvent) => {
    this.handleEvent('handleAnchorClick', evt);
  })
  handleAnchorFocus = action((evt: React.FocusEvent) => {
    this.handleEvent('handleAnchorFocus', evt);
  })
  handleAnchorBlur = action((evt: React.FocusEvent) => {
    this.handleEvent('handleAnchorBlur', evt);
  })

  @action
  private handleEvent<T extends React.SyntheticEvent>(cbName: string, evt: T) {

    // const exOvr = this.exclusivelyVisibleOverlay;
    // if (exOvr) {
    //   exOvr[cbName]?.call(exOvr, evt);
    // } else {
      this.overlays.forEach(ovr => {
        ovr[cbName]?.call(ovr, evt);
      });
    // }

    // const newExOvr = this.exclusivelyVisibleOverlay;
    // if (newExOvr)
    //   this.hideOtherOverlays(newExOvr);
  }

  @action
  hide() {
    this.overlays.forEach(ovr =>
      ovr.hide());
  }

  @action
  hideOtherOverlays(ovr: OverlayState) {
    this.overlays.forEach(otherOvr => {
      if (otherOvr === ovr)
        return;
      otherOvr.hide();
    });
  }

  @computed get anchorProps(): OverlayAnchorJSXProps {
    const props: OverlayAnchorJSXProps = {};

    props.onMouseEnter = evt => this.handleAnchorMouseEnter(evt);
    props.onMouseLeave = evt => this.handleAnchorMouseLeave(evt);
    props.onClick = evt => this.handleAnchorClick(evt);
    props.onFocus = evt => this.handleAnchorFocus(evt);
    props.onBlur = evt => this.handleAnchorBlur(evt);

    return props;
  }
}

export function useOverlayGroupState(props?: Props): OverlayGroupState {

  const store = useStore();
  const overlays = props?.overlays || [];

  const init = new OverlayGroupState(store, {
    overlays
  });

  const [state] = useState(init);

  return state;
}