import './layout.scss';
import '../menu/menu.scss';
import '../input/dropdownInput-v1-1.scss';

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../store/storeHooks';
import { UserAvatar } from './userAvatar';
import { UserAccountDropdown } from './userAccountDropdown';

const getRole = (roles: string[] | undefined) => {
  if (roles?.includes('Admin')) {
    return 'Admin';
  } else if (roles?.includes('Trainer')) {
    return 'Trainer';
  } else {
    return 'Member';
  }
};

type Props = {
  showProfile: boolean;
  showHelp: boolean;
  showLogout: boolean;
  onLogoutButtonClick?: React.MouseEventHandler;
  onHelpButtonClick?: React.MouseEventHandler;
}

export const UserCard = observer(({ 
  showHelp, 
  showProfile, 
  showLogout, 
  onLogoutButtonClick,
  onHelpButtonClick
}: Props) => {

  const store = useStore();
  const { localUser } = store.profilePage;
  const [isOpened, setIsOpened] = useState(false);
  const userRole = getRole(store?.user?.roles);
  const showDropdown = showLogout || showHelp || showProfile;

  return (
    <div className="user-card">
      <UserAvatar
        className="light-blue"
        onClick={() => setIsOpened(!isOpened)}
        model={localUser ?? store.authService.userProfile} />

      <div className="user-info" onClick={() => setIsOpened(!isOpened)}>
        <p className="user-name">{localUser?.name || store.user?.name || store.user?.username}</p>
        <p className="user-role">{userRole}</p>
      </div>

      {showDropdown &&
        <UserAccountDropdown
          showLogout={showLogout}
          showHelp={showHelp}
          showProfile={showProfile}
          isOpened={isOpened}
          setIsOpened={setIsOpened}
          onLogoutButtonClick={onLogoutButtonClick}
          onHelpButtonClick={onHelpButtonClick} />}
    </div>
  );
});