import { DOMAttributes } from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import identity from 'lodash/identity';
import pick from 'lodash/pick';
import Bowser from 'bowser';
import { capitalCase } from 'capital-case';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { areSiblings } from '../../core';
import { Theme, View } from './utils';
import { FullscreenManager } from './fullscreenManager';
import { VisibilityManager } from './visibilityManager';
import { OrientationManager } from './orientationManager';
import { ClipboardManager } from './clipboardManager';
import { AutoplayManager } from './autoplay';

const ROOT_ELEMENT_TARGET: 'Html' | 'Body' = 'Html';

type ContainerJsxProps = Pick<DOMAttributes<HTMLElement>, 'onFocus' | 'onBlur' | 'onKeyDown' | 'onKeyUp' | 'onClick' | 'onClickCapture'>

export class UIService
  extends StoreNode {

  readonly nodeType: 'UIService' = 'UIService';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.onFieldChange('rootClassName', () => {
      this.writeRootClassName(this.rootClassName);
    });

    this.platform = null;
    this.os = null;

    try {
      const parser = Bowser.getParser(window.navigator.userAgent);

      this.browser = parser;
      this.browserName = parser.getBrowserName(true);
      this.platform = parser?.getPlatformType();
      this.os = parser?.getOSName();
    } catch {
      // error handling 
    }
  }

  readonly clipboard = new ClipboardManager(this.store);
  readonly fullscreen = new FullscreenManager(this.store);
  readonly visibility = new VisibilityManager(this.store);
  readonly orientation = new OrientationManager(this.store);
  readonly autoplay = new AutoplayManager(this.store);

  readonly platform: string | null;
  readonly os: string | null;
  readonly browser: Bowser.Parser.Parser | null = null;
  readonly browserName: string | null = null; // lowercase browser name

  @computed get isIOS(): boolean {
    return this.os?.toLowerCase() === 'ios';
  }

  @computed get isMobile(): boolean {
    return this.platform?.toLowerCase() === 'mobile';
  }

  @computed get isTablet(): boolean {
    return this.platform?.toLowerCase() === 'tablet';
  }

  @computed get isTouchBased(): boolean {
    return this.isMobile || this.isTablet;
  }

  @computed get isSafari(): boolean {
    return !!this.browser?.isBrowser('safari');
  }

  readonly rootClassList = observable.set<string>();

  @observable isFeedbackButtonSuppressed = false;
  @observable isHelpButtonSuppressed = false;

  /**
   * Widget configurator parameters
   */

  @observable view: View = View.Grid;
  @observable theme: Theme = Theme.Default;
  @observable brandName: string | null = null;

  /**
   * The logo parameter has 3 possible values
   * null - which means no logo parameter was specified so we show the default CLIPr logo
   * string - which should be a valid logo url
   * None - which means no logo is desired so we show just the dots as a loading spinner
   */
  @observable logo: string | null | 'None' = null;
  @observable themeVariation: 'Default' | 'Inverse' | null = null;

  /**
   * Gets the output string that will be used as the root `className`.
   * If you want to add / remove your own classes, see `rootClassList`. 
   */
  @computed get rootClassName(): string {
    const { store } = this;
    const clsList = [
      ...this.rootClassList,
      this.isFeedbackButtonSuppressed ? 'suppress-feedback-button' : '',
      this.isHelpButtonSuppressed ? 'suppress-help-button' : '',
      store.widgetService?.isWidgetMode ? 'widget-mode' : 'app-mode',
      ...(this.hasFocusFromKeyboard ? [
        'has-keyboard-focus',
        'has-visible-focus'
      ] : [])
    ];

    return [...new Set(clsList.filter(identity))].join(' ');
  }

  @observable
  private rootClassNameWriteCount = 0;

  /**
   * Gets the element on which root classNames will be added.
   * Will return either the <html> or the <body> element.
   * Can also return null if we're in a context that doesn't have access to `document` or the aforementioned elements.
   */
  get rootClassNameElement(): HTMLElement | null {
    try {
      switch (ROOT_ELEMENT_TARGET) {
        case 'Body':
          return document.body;
        case 'Html':
          return document.documentElement;
      }
    } catch (err) { }
    return null;
  }

  /**
   * Sets the provided value as the `className` of the element marked as `rootClassNameElement`.
   * The first time the method is invoked it will check to see if there's already a `className` set on the element and
   * it will warn if that's the case.
   */
  @action
  private writeRootClassName(val: string) {
    const rootElem = this.rootClassNameElement;
    if (!rootElem)
      return;

    if (
      this.rootClassNameWriteCount === 0 &&
      rootElem.className.length > 0) {
      // there's already a class name written on the root element which might come from a plugin or some other code
      // warn that this will be overwritten

      // TODO: add flag to enable, which must be disabled in storybook
      // console.warn(`Root element ${rootElem} already has a 'className' set to ${rootElem.className}. This will be overwritten by the UIService.`);
    }

    rootElem.className = val;
    this.rootClassNameWriteCount++;
  }

  @action
  suppressFeedbackButton() {
    this.isFeedbackButtonSuppressed = true;
  }
  @action
  restoreFeedbackButton() {
    this.isFeedbackButtonSuppressed = false;
  }

  @action
  suppressHelpButton() {
    this.isHelpButtonSuppressed = true;
  }
  @action
  restoreHelpButton() {
    this.isHelpButtonSuppressed = false;
  }

  @computed get containerJsxProps(): ContainerJsxProps {
    return {
      onFocus: evt => this.handleFocus(evt),
      onBlur: evt => this.handleBlur(evt),
      onKeyDown: evt => this.handleKeyDown(evt),
      onKeyUp: evt => this.handleKeyUp(evt),
      onClick: evt => this.handleClick(evt),
      onClickCapture: evt => this.handleClickCapture(evt)
    } as ContainerJsxProps
  }

  @observable focusedElement: Element | null = null;
  @observable blurredElement: Element | null = null;
  @observable didFocusMoveToDescendant: boolean = false;
  @observable didFocusMoveToParent: boolean = false;
  @observable didFocusMoveToSibling: boolean = false;
  @observable hasFocusFromKeyboard: boolean = false;

  @observable isTabPressed = false;
  @observable lastClickEventCaptured: React.MouseEvent | null = null;
  @observable lastClickEvent: React.MouseEvent | null = null;

  @action
  handleFocus(evt: React.FocusEvent) {
    this.focusedElement = document.activeElement ?? null;
    this.hasFocusFromKeyboard = !!this.focusedElement && this.isTabPressed;
  }

  @action
  handleBlur(evt: React.FocusEvent) {

    const related = evt.relatedTarget;
    const relatedElem = related instanceof Element ? related : null;
    const blurredElem = this.focusedElement;

    // store a reference to the last focused element
    // also if we have access to the element that is going to be focused next (through relatedTarget)
    // we already set it so that we don't have small gaps in which there is no focused element,
    // because in practice the users should never perceive the transition between the focused elements
    this.blurredElement = blurredElem;
    this.focusedElement = relatedElem;

    // try to expose some helpful props for fixing focus issues when focus
    // moves between parent and child elements (as in the case of a button that opens a panel)
    if (blurredElem && relatedElem) {

      this.didFocusMoveToDescendant =
        blurredElem.contains(relatedElem);

      this.didFocusMoveToParent =
        relatedElem.contains(blurredElem);

      this.didFocusMoveToSibling =
        areSiblings(relatedElem, blurredElem);
    }

    this.hasFocusFromKeyboard = !!relatedElem && this.isTabPressed;
  }

  @action
  handleKeyDown(evt: React.KeyboardEvent) {
    if (evt.key === 'Tab')
      this.isTabPressed = true;
  }
  @action
  handleKeyUp(evt: React.KeyboardEvent) {
    if (evt.key === 'Tab')
      this.isTabPressed = false;
  }

  @action
  handleClick(evt: React.MouseEvent) {
    this.lastClickEvent = evt;
    evt.persist(); // TODO: remove when upgrading to react 17
  }
  @action
  handleClickCapture(evt: React.MouseEvent) {
    this.lastClickEventCaptured = evt;
    evt.persist(); // TODO: remove when upgrading to react 17
  }

  /**
   * Sets custom brand name if the user added one in the query params
   * Otherwise use CLIPr brand name
   * This will be a temporary solution until the actual configurator will be implemented
   * @param brandName 
   */

  @action
  setBrandName(brandName: string) {
    this.brandName = brandName;
  }

  /**
  * Sets custom brand logo if the user added one in the query params
  * Otherwise use CLIPr brand logo
  * This will be a temporary solution until the actual configurator will be implemented
  * @param logo
  */
  @action
  setLogo(logo: string | null) {
    this.logo = logo === 'none' ? capitalCase(logo) : logo;
  }

  @action
  setView(view: View) {
    this.view = view;
  }

  @action
  setTheme(theme: Theme) {
    this.theme = theme;
    this.applyTheme();
  }

  @action
  setThemeVariation(variation: 'Default' | 'Inverse') {
    this.themeVariation = variation;
    this.applyTheme();
  }

  @action
  clearThemeVariation() {
    this.themeVariation = null;
    this.applyTheme();
  }

  private applyTheme() {
    let outTheme: string = 'default';

    switch (this.theme) {
      case Theme.Dark:
        outTheme = 'dark';
        break;

      case Theme.Light:
        outTheme = 'light';
        break;

      default:
      case Theme.Default:
        if (this.themeVariation === 'Inverse') {
          outTheme = 'dark'
        } else {
          outTheme = 'default';
        }
        break;
    }

    this.rootClassNameElement?.setAttribute('data-theme', outTheme);
  }

  isElementLastClicked(arg?: EventTarget | null) {
    if (!arg)
      return false;
    return this.lastClickEvent?.nativeEvent?.composedPath().includes(arg) ?? false;
  }
  isElementLastClickCaptured(arg?: EventTarget | null) {
    if (!arg)
      return false;
    return this.lastClickEventCaptured?.nativeEvent?.composedPath().includes(arg) ?? false;
  }

  private _dumpFocusState() {
    console.log(pick(this, [
      'focusedElement',
      'blurredElement',
      'didFocusMoveToDescendant',
      'didFocusMoveToParent',
      'didFocusMoveToSibling',
      'isTabPressed'
    ]));
  }
}