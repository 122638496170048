import './input.scss';
import './inputs-v1-1.scss';
import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { FileInputState } from './fileInputState';
import { PreviewUploadBox } from './fileInput/previewUploadBox';
import { UploadButton } from './fileInput/uploadButton';
import { FileInputProps } from './fileInput/fileInputSchema';
import { AvatarUploadBox } from './fileInput/avatarUploadBox';


// WIP - only supports 1 image (jpeg, png) uploads / small layout
export const FileInput = observer(
  (props: FileInputProps) => {

    const { layout } = props;

    switch (layout) {
      case 'button':
        return <UploadButton {...props} />
      case 'avatar':
        return <AvatarUploadBox {...props} />
      case 'preview-box':
      default:
        return <PreviewUploadBox {...props} />
    }
  }
);

type ControlledProps = FileInputProps & {
  model: FileInputState;
};

/**
 * Wrapper for TextInput in which the entire state and all the handlers are provided through an InputState object.
 */
export const FileInputControlled = observer(
  ({ model, className, children, ...props }: ControlledProps) => {
    // assert(
    //   model instanceof FileInputState,
    //   `The 'model' passed to FileInputControlled must be an instance of FileInputState. Instead received ${model}.`
    // );

    const handleChange = useCallback(
      (evt) => {
        model.handleFileChange(evt);
      },
      [model]
    );

    className = model.getClassName('file-input', { className });

    return (
      <FileInput
        className={className}
        value={model.value || ''}
        placeholder={model.placeholder || undefined}
        disabled={model.disabled}
        label={model.label!}
        multiple={model.multiple}
        isHovered={model.isHovered}
        isFocused={model.isFocused}
        isRequired={model.isRequired}
        onChange={handleChange}
        onFocus={model.handleFocus}
        onBlur={model.handleBlur}
        onPointerEnter={model.handlePointerEnter}
        onPointerLeave={model.handlePointerLeave}
        status={model.status}
        statusMessage={model.statusMessage}
        showStatus={model.showStatus}
        showFeedback={model.showStatusMessage}
        labelPosition={model.labelPosition || undefined}
        feedbackPosition={model.feedbackPosition || undefined}
        isDragHovered={model.isDragHovered}
        dragError={model.dragError}
        dragErrorMessage={model.dragErrorMessage}
        previewUrl={model.previewUrl}
        handleDrop={model.handleDrop}
        handlePointerEnter={model.handlePointerEnter}
        handlePointerLeave={model.handlePointerLeave}
        handleDragEnter={model.handleDragEnter}
        handleDragLeave={model.handleDragLeave}
        handleFileClick={model.handleFileClick}
        onLoad={model.setValueLoaded}
        isLoading={model.isLoadingValue}
        {...props}>
        {children}
      </FileInput>
    );
  }
);
