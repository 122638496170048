import './layout.scss';

import React, { ReactChild } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { HTMLDivProps } from '../../core';
import { LoadMask } from '../loader';
import { MasterSidebar } from './masterSidebar';
import { MasterHeader } from './masterHeader';
import { ChunksPropValue, PageChunks } from './pageChunks';

type Props = HTMLDivProps & {
  chunks?: ChunksPropValue,
  showLoadMask?: boolean,
  showHeader?: boolean,
  showSidebar?: boolean,
  searchBar?: ReactChild,
};

/**
 * Full page with sidebar and account / settings header.
 */
export const MasterPage = observer(({
  className,
  chunks,
  children,
  showLoadMask = false,
  showHeader = true,
  showSidebar = true,
  searchBar,
  ...props
}: Props) => {

  className = classNames(className, 'master-page');

  return (
    <div className={className}
      {...props}>

      {showSidebar &&
        <MasterSidebar />}

      {showHeader &&
        <MasterHeader searchBar={searchBar} />}

      <div className="master-page-body page-body">
        {!showLoadMask && children}

        <LoadMask
          visible={showLoadMask} />
      </div>

      <PageChunks
        chunks={chunks} />
    </div>
  );
});