import Cookies from 'js-cookie';
import axios from 'axios';
import { computed, makeObservable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode, Message } from '../../store';
import { RegisterInput, OnboardInput } from '../auth/authSchema';
import { UserProfile } from '../../entities';
import { AsyncResult } from '../../core';

const HUBSPOT_ID = process.env.REACT_APP_HUBSPOT_ID;
const HUBSPOT_ONBOARD_FORM_ID = process.env.REACT_APP_HUBSPOT_ONBOARD_FORM_ID;

export const HUBSPOT_UPDATE_CONTACT_KEY = 'shouldUpdateHubspotContact';

export const addHubspotTracking = () => {
  if (!HUBSPOT_ID) return;

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'hs-script-loader';
  script.async = true;
  script.defer = true;
  script.src = `//js.hs-scripts.com/${HUBSPOT_ID}.js`;

  document.body.appendChild(script);
}

type ContactUpdateParams = OnboardInput & { name?: string, email?: string };
type ContactField = {
  objectTypeId: 'CONTACT' | 'COMPANY',
  name: string,
  value: any
}
type ContactUpdateInput = {
  fields: ContactField[],
  context?: {
    hutk?: string
  }
}

enum MessageTypes {
  ProfilePageUpdate = 'ProfilePage:userUpdated',
  AuthServiceCallbackSuccess = 'AuthService:oauthCallbackSuccess',
}

export class HubspotProvider
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.receive(this.receiver, [
      MessageTypes.ProfilePageUpdate,
      MessageTypes.AuthServiceCallbackSuccess
    ]);
  }

  private receiver = async (msg: Message) => {
    const { fullType, payload } = msg;

    switch (fullType) {
      case MessageTypes.ProfilePageUpdate: {
        this.updateUserContact(payload);
        break;
      }
      case MessageTypes.AuthServiceCallbackSuccess: {
        const shouldUpdate = this.store.storage.getLocal(HUBSPOT_UPDATE_CONTACT_KEY);

        if (!this.user || !shouldUpdate) return;
        const { email, username, industry, companySize, jobTitle } = this.user;

        if (!username) return;

        const [, err] = await this.updateUserContact({
          email,
          username,
          industry,
          companySize,
          jobTitle,
        });

        if (err) {
          console.error('Could not update Hubspot Contact', err);
          return;
        }
        this.store.storage.removeLocal(HUBSPOT_UPDATE_CONTACT_KEY);
        break;
      }

      default:
        break;
    }
  }

  @computed get isEnabled(): boolean { return !!HUBSPOT_ID; }
  @computed get user(): UserProfile | null { return this.store.user }

  @computed
  get onboardFormUrl(): string | null {
    if (!this.isEnabled || !HUBSPOT_ONBOARD_FORM_ID) return null;

    return `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_ID}/${HUBSPOT_ONBOARD_FORM_ID}`;
  }

  /**
   * Creates a new contact in Hubspot
   * @param payload 
   * @returns 
   */
  async sendRegisterForm(payload: RegisterInput): AsyncResult<boolean | Error> {
    if (!this.isEnabled) return [false];

    if (!this.onboardFormUrl) return [null, new Error('Hubspot Form URL is missing.')];

    const { email, firstName, lastName, fullName } = payload;
    const body: ContactUpdateInput = {
      fields: [
        {
          objectTypeId: 'CONTACT',
          name: 'email',
          value: email
        },
        {
          objectTypeId: 'CONTACT',
          name: 'firstname',
          value: firstName
        },
        {
          objectTypeId: 'CONTACT',
          name: 'full_name',
          value: fullName
        },
      ],
    };
    if (lastName) {
      body.fields.push({
        objectTypeId: 'CONTACT',
        name: 'lastname',
        value: lastName
      })
    }

    return this.handleApiCall(this.onboardFormUrl, body);
  }

  /**
   * Updates an existing contact in Hubspot
   * @param payload 
   * @returns 
   */
  async updateUserContact(payload: ContactUpdateParams): AsyncResult<boolean | Error> {
    if (!this.isEnabled) return [false];

    if (!this.onboardFormUrl) return [null, new Error('Hubspot Form URL is missing.')];

    const email = this.user?.email;
    const { username, industry, companySize, jobTitle, name, firstName, lastName } = payload;
    const numEmployees = companySize?.split('-').map(i => i.trim()).join('-');

    const body: ContactUpdateInput = {
      fields: [{
        objectTypeId: 'CONTACT',
        name: 'email',
        value: email
      }],
    };

    if (firstName) {
      body.fields.push({
        objectTypeId: 'CONTACT',
        name: 'firstname',
        value: firstName
      }
      )
    }
    if (lastName) {
      body.fields.push({
        objectTypeId: 'CONTACT',
        name: 'lastname',
        value: lastName
      })
    }
    if (username) {
      body.fields.push({
        objectTypeId: 'CONTACT',
        name: 'username',
        value: username
      });
    }
    if (numEmployees) {
      body.fields.push({
        objectTypeId: 'CONTACT',
        name: 'numemployees',
        value: numEmployees
      });
    }
    if (jobTitle) {
      body.fields.push({
        objectTypeId: 'CONTACT',
        name: 'jobtitle',
        value: jobTitle
      });
    }
    if (industry) {
      body.fields.push({
        objectTypeId: 'CONTACT',
        name: 'industry',
        value: industry
      });
    }
    if (name) {
      body.fields.push({
        objectTypeId: 'CONTACT',
        name: 'full_name',
        value: name
      })
    }

    const hutk = Cookies.get('hubspotutk');
    if (hutk) body.context = { hutk };

    return this.handleApiCall(this.onboardFormUrl, body);
  }

  private async handleApiCall(url: string, body: ContactUpdateInput): AsyncResult<boolean> {
    try {
      const res = await axios.post(url, body);
      if (res) {
        return [true]
      }
      return [false];
    } catch (err) {
      return [null, err as any];
    }
  }
}