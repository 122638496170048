import './trackWindow.scss';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { ButtonControlled, DropdownInputControlled } from '../input';
import { MergeTracksWindowState } from './mergeTracksWindowState';

type Props = {
  model: MergeTracksWindowState;
  visible?: boolean;
};

export const MergeTracksWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      model={model.window}
      visible={props.visible}
      isLoading={model.isLoading}
      onExited={model.onTransitionEnd}
      className="merge-tracks-window">
      <WindowHeader model={model.window} title={'Merge Swimlanes'} />

      <hr />

      <section className="window-content">
        <DropdownInputControlled
          className="track-selector-input fill"
          model={model.targetTrack}
          label="Merge selected swimlane with:"
          placeholder="Select target swimlane"
          showFeedback={false}
        />
      </section>

      <hr />
      <footer className="window-footer">
        <div className="btn-group right">
          <ButtonControlled
            className="primary btn-outline medium"
            label="Cancel"
            model={model.cancelButton}
            onClick={() => model.cancel()}
          />

          <ButtonControlled
            className="primary medium"
            label="Save"
            model={model.saveButton}
            onClick={() => model.submit()}
          />
        </div>
      </footer>
    </Window>
  );
});
