import { action, makeObservable } from 'mobx';
import { Store } from '../../../store/store';
import { StoreNode } from '../../../store';
import { OnboardFormState } from './onboardFormState';
import { RouteContext } from '../../../routes/routeContext';
import { PageState } from '../../../components/mixins/pageState';

export class OnboardPageState
  extends PageState(StoreNode) {

  readonly nodeType = 'OnboardPage';

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  readonly form = new OnboardFormState(this.store);

  @action
  attached(routeContext: RouteContext) {
    this.baseAttached(routeContext);

    const { user: profile } = this.store;
    if (!profile)
      return;

    this.form.username
      .loadValue(profile.username || '');
    this.form.firstName
      .loadValue(profile.firstName || '');
    this.form.lastName
      .loadValue(profile.lastName || '');
    this.form.industry
      .loadValue(profile.industry || '');
    this.form.companySize
      .loadValue(profile.companySize || '');
    this.form.jobTitle
      .loadValue(profile.jobTitle || '');

    this.broadcast('mounted');
  }

  @action
  detached() {
    this.baseDetached();
    this.reset();
  }

  @action
  private reset() {
    this.baseReset();
    this.form.reset();
  }
}