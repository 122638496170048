import './input.scss';
import './dropdownInput-v1-1.scss';
import '../menu/menu.scss';
// @ts-ignore
import CSSVariables from '../menu/menu.scss';

import React, { useCallback, useState, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import find from 'lodash/find';
import classNames from 'classnames';

import { InputState } from './inputState';
import { ChevronDownSVGIcon, ChevronUpSVGIcon, InfoSVGIcon } from '../svg';
import { assert, Maybe, ObjectLiteral, VerticalPosition } from '../../core';
import { paramCase } from 'change-case';
import { MenuItems } from './menuItems';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Button } from '../input';
import { Tooltip } from '../overlays';

export type DropdownItemObject = {
  value: string;
  label: string;
  description?: string;
  tooltip?: ReactNode;
};

type DropdownLayout = 'default' | 'plain' | 'filter';

export type DropdownItem = DropdownItemObject | string;

type Props = {
  className?: string | ObjectLiteral;
  dropdownClassName?: string | ObjectLiteral;
  value?: DropdownItem | null;
  label?: Maybe<string>;
  disabled?: boolean;
  isFocused?: boolean;
  isHovered?: boolean;
  checked?: boolean;
  readOnly?: boolean;
  message?: string;
  items: DropdownItem[];
  status?: Maybe<string>;
  statusMessage?: Maybe<string>;
  placeholder?: Maybe<string>;
  onChange?: any;
  onFocus?: any;
  onBlur?: any;
  onPointerEnter?: any;
  onPointerLeave?: any;
  showStatus?: boolean;
  labelPosition?: string;
  showFeedback?: Maybe<boolean>;
  feedbackPosition?: VerticalPosition;
  persistentFeedback?: boolean;
  layout?: DropdownLayout;
  notDeselectable?: boolean;
  withSearch?: boolean;
  tooltipContent?: string | ReactNode;
  virtualized?: boolean;
};

export const DropdownInput = observer(
  ({
    readOnly,
    value,
    className,
    dropdownClassName,
    label,
    disabled,
    isFocused,
    isHovered,
    checked,
    message,
    items,
    status,
    statusMessage: feedback,
    showStatus,
    labelPosition = 'topStart',
    showFeedback = true,
    feedbackPosition = 'bottom',
    persistentFeedback = true,
    layout = 'default',
    placeholder,
    withSearch,
    tooltipContent,
    virtualized,
    ...props
  }: Props) => {

    const fadeTimeout = {
      enter: parseInt(CSSVariables.windowEnterTimeout),
      exit: parseInt(CSSVariables.windowExitTimeout),
    };

    const [isExpanded, setIsExpanded] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);

    className = classNames('dropdown-input input', className, {
      focus: isFocused,
      hover: isHovered,
      active: isExpanded
    });

    const inputValue = typeof value === 'object' ? value?.value : value;
    const displayValue =
      (find(items, ['value', inputValue]) as DropdownItemObject)?.label || // if value is string for object items
      (typeof value === 'object' ? value?.label : value)                   // if value is object it already has the label

    const feedbackElem = (
      <CSSTransition
        in={!!(showFeedback && feedback)}
        classNames="feedback-fade"
        unmountOnExit={!persistentFeedback}
        timeout={fadeTimeout}>
        <div
          className={[
            'feedback input-feedback',
            paramCase(feedbackPosition!),
          ].join(' ')}
          data-status={status}
          aria-live="polite">
          {showFeedback && feedback}
        </div>
      </CSSTransition>
    );

    const labelElem = label && (
      <label className={['input-label', paramCase(labelPosition)].join(' ')}>
        {label}

        {tooltipContent &&
          <Tooltip
            content={tooltipContent}
            className="popup-tip"
            trigger="click">
            <Button className="info-button" icon={<InfoSVGIcon />} />
          </Tooltip>}
      </label>
    );

    let inputElem;

    const handleInputElemClick = () => {
      setIsExpanded(!isExpanded);
      // Remove focus from the input element in order to avoid click event interruptions in some scenarios
      (referenceElement as any)?.blur();
    }

    switch (layout) {
      case 'filter':
        inputElem = (
          <button
            className="sort-field-btn sort btn-core btn filter"
            type="button"
            ref={setReferenceElement as any}
            disabled={disabled}
            onClick={handleInputElemClick}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            onPointerEnter={props.onPointerEnter}
            onPointerLeave={props.onPointerLeave}>
            {value ? (
              <span className="value">{displayValue}</span>
            ) : (
              !disabled && <span className="placeholder">{placeholder}</span>
            )}
            <span className="icon">
              {isExpanded ? <ChevronUpSVGIcon /> : <ChevronDownSVGIcon />}
            </span>
          </button>
        );
        break;
      case 'plain':
        inputElem = (
          <button
            className={'dropdown-button' + (isExpanded ? ' is-open' : '')}
            type="button"
            ref={setReferenceElement as any}
            disabled={disabled}
            onClick={handleInputElemClick}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            onPointerEnter={props.onPointerEnter}
            onPointerLeave={props.onPointerLeave}>
            {value ? (
              <span className="value">{displayValue}</span>
            ) : (
              <span className="placeholder">{!disabled && placeholder}</span>
            )}
            <span className="icon">
              <ChevronDownSVGIcon />
            </span>
          </button>
        );
        break;
      case 'default':
      default:
        inputElem = (
          <div className={className} aria-disabled={disabled}>
            <div className="dropdown-input-box input-box" />
            <button
              className={'dropdown-button' + (isExpanded ? ' is-open' : '')}
              type="button"
              ref={setReferenceElement as any}
              disabled={disabled}
              onClick={handleInputElemClick}
              onFocus={props.onFocus}
              onBlur={props.onBlur}
              onPointerEnter={props.onPointerEnter}
              onPointerLeave={props.onPointerLeave}>
              {value ? (
                <span className="value">{displayValue}</span>
              ) : (
                <span className="placeholder">{!disabled && placeholder}</span>
              )}
              <span className="icon">
                <ChevronDownSVGIcon />
              </span>
            </button>
            {labelElem}
            {feedbackElem}
          </div>
        );
        break;
    }

    return (
      <>
        {inputElem}

        <TransitionGroup className="transition-group">
          {isExpanded && (
            <CSSTransition classNames="menu-fade" timeout={fadeTimeout}>
              <MenuItems
                items={items}
                referenceElement={referenceElement}
                value={inputValue}
                onChange={(evt: any, val: any) => {
                  if (props.notDeselectable && val === value) return;
                  props.onChange(evt, val);
                  setIsExpanded(false);
                }}
                onOutsideClick={() => {
                  setIsExpanded(false);
                }}
                withSearch={withSearch}
                virtualized={virtualized}
              />
            </CSSTransition>
          )}
        </TransitionGroup>
      </>
    );
  }
);

type ControlledProps = React.InputHTMLAttributes<HTMLInputElement> & {
  value?: string;
  label?: string;
  validator?: 'top' | 'bottom' | 'none';
  model: InputState;
  showEmptyItem?: Maybe<boolean>;
  showFeedback?: Maybe<boolean>;
  className?: string | ObjectLiteral;
  dropdownClassName?: string | ObjectLiteral;
  layout?: DropdownLayout;
  feedbackPosition?: VerticalPosition;
  persistentFeedback?: boolean;
  notDeselectable?: boolean;
  withSearch?: boolean;
  tooltipContent?: string | ReactNode;
  virtualized?: boolean;
};

/**
 * Wrapper for SelectInput in which the entire state and all the handlers are provided through an InputState object.
 */
export const DropdownInputControlled = observer(
  ({
    model,
    className,
    dropdownClassName,
    showFeedback = true,
    ...props
  }: ControlledProps) => {
    assert(
      model instanceof InputState,
      `The 'model' passed to CustomDropdownInputControlled must be an instance of InputState. Instead received ${model}.`
    );

    const handleChange = useCallback(
      (evt, value) => {
        if (model.normValue === value) {
          if (props.notDeselectable) return;

          model.handleChange(evt, null);
        } else {
          model.handleChange(evt, value || null);
        }
      },
      [model, props.notDeselectable]
    );

    className = model.getClassName('dropdown-input-controlled', { className });

    return (
      <DropdownInput
        className={className}
        dropdownClassName={dropdownClassName}
        value={model.value || null}
        placeholder={model.placeholder}
        disabled={model.disabled}
        label={model.label}
        items={model.selectorItems}
        showEmptyItem={true}
        isHovered={model.isHovered}
        isFocused={model.isFocused}
        tooltipContent={props.tooltipContent}
        onChange={handleChange}
        onFocus={model.handleFocus}
        onBlur={model.handleBlur}
        onPointerEnter={model.handlePointerEnter}
        onPointerLeave={model.handlePointerLeave}
        status={model.status}
        statusMessage={model.statusMessage}
        showStatus={model.showStatus}
        showFeedback={model.showStatusMessage}
        {...props}
      />
    );
  }
);
