import { action, makeObservable, observable } from 'mobx';
import { StoreNode } from '../../store';
import { Store } from '../../store/store';

/**
 * Cross-browser, observable object for managing the visibility of the app.
 */
export class VisibilityManager
  extends StoreNode {

  readonly nodeType: 'VisibilityManager' = 'VisibilityManager';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.init();
  }

  get rootVisibilityState(): VisibilityState {
    return document.visibilityState;
  }

  get rootIsVisible() {
    return this.rootVisibilityState === 'visible';
  }

  @observable isVisible: boolean = false;

  /**
   * Cross-browser, non-observable getter for accessing the fullscreenElement from the document.
   * If you want an observable property, use `fullscreenElement`.
   */
  getFullscreenElement(): Element | null {
    return (
      document.fullscreenElement ??
      document.webkitFullscreenElement ??
      null);
  }

  @action
  private init() {
    document.addEventListener('visibilitychange',
      this.handleVisibilityChange);
  }

  @action
  dispose() {
    document.removeEventListener('visibilitychange',
      this.handleVisibilityChange);
    this.isDisposed = true;
  }

  @action
  private handleVisibilityChange = (evt: Event) => {
    this.isVisible = this.rootIsVisible;
    this.emit('Change', {
      isVisible: this.isVisible 
    });
  }
}