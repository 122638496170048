import endsWith from 'lodash/endsWith';
import padEnd from 'lodash/padEnd';
import { DateTime } from 'luxon';
import { Config } from '../../config';
import { isDefinedObject } from '../object';
import { Maybe } from '../types';
import { DEFAULT, RUNTIME } from './debugContexts';
import { DebugContext, IDebugNode } from './debugSchema';
import { _debugGetCalleeName } from './debugUtils';

const NODE_LABEL_MAX_LENGTH = 16;

const enableDebug = Config.debug.enable;
const enableTrace = enableDebug && Config.debug.trace;

type TraceMessageOrContext = string | IDebugNode | DebugContext;
type TraceArgs = [
  TraceMessageOrContext,
  ...any[]
]

function getValueFromToken<T extends string | number>(tok?: Maybe<T | (() => Maybe<T>)>): T | null {
  if (typeof tok === 'function')
    return tok() ?? null;
  return tok ?? null;
}

function getTraceConsoleArgs(msgOrCtx?: TraceMessageOrContext, msgOrA?: string | any, ...other: any[]): any[] | null {

  if (!enableTrace)
    return null;

  let msg: string | null = null;
  let msgStyle: string | null = null;
  let ctx: DebugContext | null = null;

  if (typeof msgOrCtx === 'string') {
    msg = msgOrCtx ?? null;
    ctx = null;
  } else if (isDefinedObject(msgOrCtx) && ('_debugger' in msgOrCtx)) {
    msg = msgOrA ?? null;
    ctx = msgOrCtx._debugger ?? null;
  } else if (isDefinedObject(msgOrCtx)) {
    msg = msgOrA ?? null;
    ctx = msgOrCtx as DebugContext;
  }

  if (ctx?.enableTrace === false)
    return null;

  let args = [];
  let tokens = [];

  let color = getValueFromToken(ctx?.color);

  // let id = getValue(ctx?.id);

  let timestamp: string | undefined;
  if (ctx?.showTimestamp) {
    timestamp = DateTime.now().toFormat('HH:mm:ss.SSS');
    tokens.push(`%c[${timestamp}]`);
    args.push(`color: ${color};`);
  }

  let label = getValueFromToken(ctx?.label);
  if (label) {
    let labelToken = `[${label}]`;

    if (ctx?.alignMessage) {
      if (label.length > NODE_LABEL_MAX_LENGTH)
        label = label.slice(0, NODE_LABEL_MAX_LENGTH - 1) + '.';
      labelToken = padEnd(`[${label}]`, NODE_LABEL_MAX_LENGTH + 2, ' ');
    }

    tokens.push(`%c${labelToken}`);
    args.push(`color: ${color}; font-weight: bold;`);
  }

  if (!msg) {

    let callee = _debugGetCalleeName(2);
    // do not show duplicate node name when invoking methods on class instances
    if (label)
      callee = callee?.split('.')[1] ?? callee;

    msg = callee + '()';
  }

  // lousy hack to view trace calls better
  if (endsWith(msg, '()'))
    msgStyle = 'background-color: rgba(0,0,0,.05); font-style: italic;';

  tokens.push(` %c${msg}`);
  args.push(`color: ${color}; ${msgStyle ?? undefined}`);

  return [tokens.join(''), ...args, ...other];
}

export function INIT_DEBUGGER(node: IDebugNode, ctx?: DebugContext) {
  if (enableDebug && isDefinedObject(node)) {
    node._debugger = {
      ...DEFAULT,
      label: node.nodeType,
      ...ctx
    }
  }
}

export function TRACE_SEPARATOR_DECENT(...args: TraceArgs) {
  const consoleArgs = getTraceConsoleArgs(...args);
  if (consoleArgs)
  console.info('\n\n\n');
}

export function TRACE_SEPARATOR_FAR_FROM_DECENT(...args: TraceArgs) {
  const consoleArgs = getTraceConsoleArgs(...args);
  if (consoleArgs)
    console.info('\n\n\n\n\n\n');
}

export function TRACE(...args: TraceArgs) {
  const consoleArgs = getTraceConsoleArgs(...args);
  if (consoleArgs)
    console.info(...consoleArgs);
}

export function TRACE_CALL(...args: TraceArgs) {
  const consoleArgs = getTraceConsoleArgs(...args);
  if (consoleArgs)
    console.info(...consoleArgs);
}

export function TRACE_GROUP(...args: TraceArgs) {
  const consoleArgs = getTraceConsoleArgs(...args);
  if (consoleArgs)
    console.group(...consoleArgs);
}

export function TRACE_GROUP_END() {
  console.groupEnd();
}

export function TRACE_ERROR(...args: TraceArgs) {
  const consoleArgs = getTraceConsoleArgs(...args);
  if (consoleArgs)
    console.error(...consoleArgs);
}

export function TRACE_WARN(...args: TraceArgs) {
  const consoleArgs = getTraceConsoleArgs(...args);
  if (consoleArgs)
    console.warn(...consoleArgs);
}

export function TRACE_RUNTIME(...args: any[]) { // node or ctx ommitted because it's automatically set to Runtime
  const consoleArgs = getTraceConsoleArgs(RUNTIME, ...args);
  if (consoleArgs)
    console.info(...consoleArgs);
}