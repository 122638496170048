import assert from 'assert';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { TRACE } from '../../core/debug/debugMacros';
import { PlayerAdapterState } from './playerAdapterState';

type Props = {
  model: PlayerAdapterState,
  onClick?: React.MouseEventHandler
}

export const PlayerAdapter = observer(({
  model,
  onClick
}: Props) => {

  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {

    // should not fail, but check it anyways in case someone modifies the render logic
    assert(videoRef.current,
      `HTMLVideoElement cannot be accessed from ref in useEffect. Make sure that the element is rendered from the start and doesn't update between renders`);

    model.mounted(videoRef.current);
    return () => {
      model.unmounted();
    }
  }, [model]);

  TRACE(model, 'Rendering');

  return (
    <div className="player-adapter"
      id="player-adapter"
      onClick={onClick}>

      <video
        crossOrigin="anonymous"
        ref={videoRef}
        playsInline={true}
        controls={model.initialControls ?? undefined}
        autoPlay={model.initialAutoplay ?? undefined} />
    </div>
  );
});