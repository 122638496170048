import { Maybe } from '../../../core';
import { AuthError, AuthErrorType } from '../../../services/auth';

export const LoginErrorCodes: AuthErrorType[] = [
  'AccessDenied',
  'PasswordLeaked',
  'TooManyAttempts',
  'WrongCredentials',
  'NotAuthorized',
  'NetworkError',
  'ProviderError'
];

export const LoginUsernameSubmitErrorCodes: AuthErrorType[] = [
  'WrongCredentials',
  'AccessDenied'
];

export const LoginPasswordSubmitErrorCodes: AuthErrorType[] = [
  'WrongCredentials',
  'AccessDenied',
  'PasswordLeaked'
];


export const LoginErrorMessageLookup: { [key in AuthErrorType]?: string } = {

  // Auth0 `access_denied`: When using web-based authentication, the resource server denies access per OAuth2 specifications
  // however this might be returned by Auth0 when the username / password combination is wrong
  'AccessDenied':
    `You are not authorized to sign in with the provided credentials.`,

  // Auth0 `password_leaked`: If the password has been leaked and a different one needs to be used
  'PasswordLeaked':
    `The password you are attempting to sign in with has been leaked and you must change it.`,

  // Auth0 `too_many_attempts`: The account is blocked due to too many attempts to sign in
  'TooManyAttempts':
    `This account has been blocked due to too many attempts to sign in.`,

  // Auth0 `invalid_user_password`: The username and/or password used for authentication are invalid
  'WrongCredentials':
    `The provided username or password is wrong.`,

  // Auth0 `unauthorized`: The user you are attempting to sign in with is blocked
  'NotAuthorized':
    `The user you are attempting to sign in with has been blocked.`,

  // general errors
  'NetworkError':
    `Signing in failed because of a network error.`,

  'ProviderError':
    `Signing in failed because of a provider error.`,

  // default error
  'UnknownError':
    `Signing in failed because of an unknown error.`
}

export function getLoginDefaultErrorMessage(errType?: Maybe<AuthErrorType>): string {
  return LoginErrorMessageLookup[errType || 'UnknownError']!;
}

export function getLoginErrorMessage(err?: Maybe<AuthError>): string | null {

  if (err && !(err instanceof AuthError))
    return getLoginDefaultErrorMessage();

  const errType = err?.type;
  const errMsg = err?.originalMessage;

  const defMsg = getLoginDefaultErrorMessage(errType);

  if (!err || !errType)
    return null;

  if (errMsg === 'Login required')
    return null;
    
  return errMsg || defMsg || null;
}
