import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { assertNotNull, AsyncResult, Maybe } from '../core';
import { Store } from '../store/store';
import { BindingProps, StoreNode } from '../store';
import { ApiResult, ApiVariables } from '../api/apiSchema';
import { GetTeamMembersFilterInput, InvitationByOption, Order, PageInfo, TeamMemberRole, TeamMemberRoleInput, TeamMembersByOption } from '@clipr/lib';
import { SyncStatus } from '../store/syncSchema';
import last from 'lodash/last';
import debounce from 'lodash/debounce';
import { TeamMember } from './teamMember';
import { TeamMemberCatalogItem } from './teamMemberCatalogItem';
import { membersSorter, teamMemberAvatarSize } from './team';
import { input, InputState, SortFieldValue } from '../components';

type Props = BindingProps<{
  teamId: string,
  pageSize?: number
}>

export class TeamMemberCatalogSource
  extends StoreNode {

  readonly nodeType: 'TeamMemberCatalogSource' = 'TeamMemberCatalogSource';

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.searchInput = input(this, {
      name: 'searchInput',
      onChange: (e) => this.handleSearchInputChange(e),
    });
  }

  readonly searchInput: InputState;

  // #region Resolved props
  // -------

  @computed get teamId(): string {
    return this.resolvedProps.teamId;
  }

  @computed get team() {
    return this.store.teamManager.strictGetTeam(this.teamId);
  }

  @computed get pageSize(): number {
    return this.resolvedProps.pageSize || 20;
  }

  @computed get isLoading(): boolean {
    return this.syncStatus === 'fetching';
  }

  @computed get isFetchingMore(): boolean {
    return this.syncStatus === 'fetchingMore';
  }

  @computed get isFirstRequest(): boolean {
    return !this.lastItemCursor;
  }

  @computed get isLastRequest(): boolean {
    const totalCount = (this.team && this.team.membersCount) || 0;
    return !this.isEndOfList && (totalCount - this.items.length <= this.pageSize);
  }
  // #endregion

  readonly items = observable.array<TeamMemberCatalogItem>();

  @observable syncStatus: SyncStatus = 'idle';
  @observable pageInfo: PageInfo | null = null;
  @observable sortField: TeamMembersByOption | null = null;
  @observable sortOrder: Order | null = null;
  @observable roleFilter: TeamMemberRole | null = null;
  @observable statusFilter: string | null = null;
  @observable searchFilter: string | null = null;

  @computed get lastItemCursor(): string | null {
    return last(this.items)?.cursor || null;
  }

  @computed get entities(): TeamMember[] {
    return this.items.map(item => item.teamMember)
      .filter(item => item)
      .sort(membersSorter);
  }

  @computed get isEndOfList(): boolean {
    return this.pageInfo ? !this.pageInfo.hasNextPage : false;
  }

  /** Makes a fresh fetch call, clearing any previous data and pagination state. */
  @action
  async fetch()
    : AsyncResult<TeamMemberCatalogItem[]> {

    this.syncStatus = 'fetching';

    const [membersRes, err] = await this.fetchRaw();

    if (err)
      return [null, err];

    assertNotNull(membersRes);

    const retItems: TeamMemberCatalogItem[] = [];
    runInAction(() => {
      this.items.clear();

      const { getTeamMembers } = membersRes;
      this.pageInfo = getTeamMembers.pageInfo;

      getTeamMembers.edges.forEach((edge, i) => {

        this.team.insertMember(edge.node);

        const item = new TeamMemberCatalogItem(this.store, {
          teamId: this.teamId,
          teamMemberId: edge.node.userId,
          cursor: edge.cursor,
          index: i
        });

        this.items.push(item);
        retItems.push(item);
      });
    });

    return [retItems];
  }

  @action
  async fetchMore()
    : AsyncResult<TeamMemberCatalogItem[]> {

    this.syncStatus = 'fetchingMore';

    const [membersRes, err] = await this.fetchRaw(true);
    if (err)
      return [null, err];

    assertNotNull(membersRes);

    const retItems: TeamMemberCatalogItem[] = [];
    runInAction(() => {
      const { getTeamMembers } = membersRes;
      this.pageInfo = getTeamMembers.pageInfo;

      getTeamMembers.edges.forEach((edge, i) => {

        this.team.insertMember(edge.node);

        const item = new TeamMemberCatalogItem(this.store, {
          teamId: this.teamId,
          teamMemberId: edge.node.userId,
          cursor: edge.cursor,
          index: i
        });

        this.items.push(item);
        retItems.push(item);
      });
    });
    return [retItems];
  }

  getSortFieldValue(field: TeamMembersByOption) {
    if (this.sortField !== field)
      return 'none';
    return this.sortOrder!;
  }

  @action
  setSortFieldValue<T extends TeamMembersByOption | InvitationByOption>(field: T, val?: SortFieldValue, fetch: boolean = true) {
    if (!val || val === 'none') {
      this.sortField = null;
      this.sortOrder = null;
    } else {
      this.sortField = field as TeamMembersByOption;
      this.sortOrder = val;
    }
    if (fetch) this.fetchDebounced();
  }

  @action
  setFilterFieldValue<T extends keyof GetTeamMembersFilterInput>(field: T, val?: Maybe<GetTeamMembersFilterInput[T]>) {
    switch (field) {
      case 'role':
        if (val === this.roleFilter) {
          this.roleFilter = null;
        } else {
          this.roleFilter = val as TeamMemberRoleInput || null;
        }
        break;
    }
    this.fetchDebounced();
  }

  getFilterFieldValue<T extends keyof GetTeamMembersFilterInput>(field: T): GetTeamMembersFilterInput[T] | null {
    switch (field) {
      case 'role':
        return this.roleFilter;
    }
    return null;
  }

  @action
  handleSearchInputChange(evt: any) {
    this.searchFilter = evt.value || null;
    this.fetchDebounced();
  }

  @action
  private getFetchArgs(more: boolean = false) {
    let args: ApiVariables<'getTeamMembers'> = {
      id: this.teamId,
      avatarSize: [teamMemberAvatarSize]
    };
    args.first = this.pageSize;

    if (more && this.lastItemCursor)
      args.after = this.lastItemCursor;

    if (this.sortField && this.sortOrder) {
      args.sort = {
        field: this.sortField,
        order: this.sortOrder
      }
    }

    let filter: GetTeamMembersFilterInput = {};

    if (this.roleFilter) {
      filter.role = this.roleFilter;
    }

    if (this.searchFilter) {
      filter._search = this.searchFilter || undefined;
    }

    args.filter = filter;

    return args;
  }

  fetchDebounced = debounce(() => this.fetch(), 500);

  @action
  private async fetchRaw(more: boolean = false): ApiResult<'getTeamMembers'> {

    const args: ApiVariables<'getTeamMembers'> = this.getFetchArgs(more);

    const [membersRes, err] = await this.store.api.getTeamMembers(args);
    if (err) {
      runInAction(() => {
        this.syncStatus = 'error';
      });
      return [null, err];
    }

    runInAction(() => {
      this.syncStatus = 'idle';
    });

    assertNotNull(membersRes);
    return [membersRes];
  }

  @action
  resetSearchField() {
    this.searchFilter = null;
    this.searchInput.loadValue(null);
  }

  @action
  reset() {
    this.sortField = null;
    this.sortOrder = null;
    this.roleFilter = null;
    this.statusFilter = null;
    this.searchFilter = null;
    this.searchInput.loadValue(null);
    this.items.clear();
  }
}
