import { action, makeObservable, observable, runInAction } from 'mobx';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { input, InputState } from '..';
import isEmail from 'validator/lib/isEmail';
import { GetDownloadLinkInput } from '@clipr/lib';

export class DownloadJobWindowState
  extends StoreNode {

  readonly nodeType = 'DownloadJobWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);

    this.emailInput = input(this, {
      name: 'email',
      isRequired: false,
      placeholder: 'Email Address',
      error: input => {
        if (input.isRequired && input.isEmpty)
          return 'Required field';

        if (!input.isEmpty && !isEmail(input.value!.trim()))
          return 'Invalid email address format';

        return null;
      }
    });
  }

  readonly window = new WindowState(this.store);
  readonly emailInput: InputState;

  @observable isLoading: boolean = false;
  jobId: string | null = null;
  teamId: string | null = null;
  startTime: number | null = null
  endTime: number | null = null;

  @action
  async submit() {
    if (!this.jobId) {
      notifyError(this, 'Could not download the video.');
      return this.handleSubmitFinally();
    }

    this.isLoading = true;

    const input: GetDownloadLinkInput = {
      jobId: this.jobId,
      teamId: this.teamId ?? undefined,
      startTime: this.startTime ?? undefined,
      endTime: this.endTime ?? undefined,
      email: this.emailInput.value ?? undefined
    }

    const [, err] = await this.store.apiService.getDownloadLink({ args: input });

    if (err) {
      notifyError(this, 'Could not download the Video/Clip.');
      return this.handleSubmitFinally();
    }

    runInAction(() => {
      notifySuccess(this, 'Email sent successfully.');
      this.handleSubmitFinally();
    });
  }

  handleSubmitFinally() {
    runInAction(() => {
      this.isLoading = false;
      this.close();
    });
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open({ jobId, teamId, startTime, endTime }: { jobId: string, teamId?: string, startTime?: number, endTime?: number }) {
    this.dispatch('Overlays', 'openWindow', { name: 'DownloadJobWindow' });

    this.jobId = jobId;
    this.teamId = teamId ?? null;
    this.startTime = startTime ?? null;
    this.endTime = endTime ?? null;
  }

  @action
  close() {
    this.jobId = null;
    this.teamId = null;
    this.startTime = null;
    this.endTime = null;
    this.emailInput.clear();

    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}