import React from 'react';
import { observer } from 'mobx-react-lite';
import { WidgetSuccessSVGIcon } from '../../widgets/uploadWidget/components/uploadWidgetSVG';

export const UploadLinkSuccessBox = observer(() => {
  return <div className="upload-link-success-box upload-stage-box notification-box success">
    <div className="box-row tmar2 bmar4">
      <div className="notification-status-icon"><WidgetSuccessSVGIcon /></div>
      <div className="notification-text">Your URL’s have been successfully submitted!</div>
    </div>
    <div className="box-row bmar2">
      <div className="notification-content">
        We will notify you once our system will start processing the videos. We also sent a confirmation of your recent submission to the registered email address.
      </div>
    </div>
  </div>;
})
