import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Button,
  GoogleDriveSVGIcon,
  LoadMask,
  SortFieldButton,
} from '../../components';
import {
  GoogleDriveCatalog,
  GoogleDriveTableCatalog,
  GoogleDriveTableCatalogHeader,
} from '../../components/media';
import { ErrorSection } from './errorSection';
import Routes from '../../routes';
import { wrapRouteWithQuery } from '../../core';
import { UploadPageParams } from '../uploadPage';
import { IntegrationTabState } from './integrationTabState';
import { NotConnectedState } from './notConnectedState';
import { DisconnectSection } from '../integrations/disconnectSection';

type Props = {
  model: IntegrationTabState;
};
export const GoogleDriveTab = observer(({ model }: Props) => {
  const { store } = model;
  const state = model;
  const { catalog, catalogSource } = state;
  const { isConnected, isLoading, error } = store.googleDrive;

  useEffect(() => {
    state.pageMounted(isConnected);
    return () => state.pageUnmounted();
  }, [state, isConnected]);

  const location = useLocation();
  const { pathname } = location;

  const params = useParams<UploadPageParams>();
  const { teamId } = params;

  let route;
  if (teamId) {
    route = `${Routes.teamUpload(teamId)}`;
  } else {
    route = Routes.upload();
  }

  if (pathname === route) {
    const returnUrl = wrapRouteWithQuery(route, { view: 'gdrive' });
    localStorage.setItem('externalLibraryUploadAuth', returnUrl);
  } else {
    localStorage.removeItem('externalLibraryUploadAuth');
  }

  if (isLoading)
    return (
      <div className="catalog">
        <LoadMask
          className="partial"
          visible={isLoading} />
      </div>
    );

  const disconnectSection = <DisconnectSection model={model} library={model.libraryService} />;

  if (error) return <ErrorSection header={disconnectSection} />;

  if (!isConnected) return <NotConnected model={state.notConnectedState} />;

  return (
    <>
      <section
        className={`${state.catalog.viewType === 'list' ? 'list-view' : ''
          } page-subheader`}>
        {state.catalog.viewType === 'grid' ? (
          <>
            <div className="flex-left">
              <div className="page-filters">
                <div className="select-controls">
                  {catalogSource.hasMultipleSelection ? (
                    <>
                      <Button
                        label="Cancel"
                        className="primary btn-outline medium"
                        onClick={() => catalogSource.clearMultipleSelection()}
                      />
                      <Button
                        className="primary medium"
                        label="Ingest Selected"
                        onClick={() => state.bulkIngest(teamId)}
                      />
                    </>
                  ) : (
                    <Button
                      className="primary medium"
                      label="Select all"
                      onClick={() => catalogSource.selectAllFiles()}
                      disabled={
                        !catalogSource.entities.length ||
                        !catalogSource.availableforIngestEntities.length
                      }
                    />
                  )}
                </div>

                <div className="divider" />

                <span className="filter-label">Sort by:</span>

                <SortFieldButton
                  label="Title"
                  name="name"
                  source={catalogSource}
                />

                <SortFieldButton
                  label="Date"
                  name="modifiedTime"
                  source={catalogSource}
                />
              </div>
            </div>

            {disconnectSection}
          </>
        ) : (
          <GoogleDriveTableCatalogHeader
            teamId={teamId}
            model={catalog}
            source={catalogSource}
            integration={model}
          />
        )}
      </section>

      {isConnected &&
        (state.catalog.viewType === 'grid' ? (
          <GoogleDriveCatalog model={catalog} source={catalogSource} />
        ) : (
          <GoogleDriveTableCatalog model={catalog} source={catalogSource} />
        ))}
    </>
  );
});

type NotConnectedProps = {
  model: NotConnectedState;
};

const NotConnected = observer(({ model }: NotConnectedProps) => {
  const { store } = model;
  const { googleDrive } = store;

  return (
    <div className="catalog empty-catalog">
      <div className="icon">
        <GoogleDriveSVGIcon />
      </div>
      <h1 className="headline-1">Authorization is Required</h1>
      <p>To access your Drive Videos please provide CLIPr with permissions.</p>

      <Button
        onClick={() => model.triggerConfirmationModal(googleDrive.authUrl)}
        className="upload-btn secondary large"
        label="Connect with Google"
      />
    </div>
  );
});
