import { RUNTIME_ERROR } from './debug/debugContexts';
import { TRACE_ERROR } from './debug/debugMacros';

export type ErrorCode =
  'AuthError' |
  'ApiError' |
  'Forbidden' |
  'NotImplemented' |
  'Unauthorized' |
  'JobUnauthorized' |
  'JobUnavailable' |
  'RequestAborted' |
  'MessageTimeout' |
  'JobDeleted' |
  'TeamDeleted' |
  'DataError' |
  'InternalError' |
  'NetworkError' |
  'JsonSerializationError' |
  'JsonDeserializationError' |
  'LoadScriptError' |
  'InvalidComponentState' |
  'InvalidComponentProps' |
  'VendorError' |
  'Aborted' |
  'Timeout' |
  'MediaPlayError' |
  'CommandAlreadyRequested' |
  'RoutingError' |
  'StorageError' |
  'SecurityError' |
  'WidgetError' |
  'Unknown';

export type ErrorProps = {
  // `code` and `message` are optional, in case we'll make an overloaded constructor
  code?: ErrorCode;
  message?: string;
  data?: Record<string, any> | null;
  innerError?: any | null;
  source?: any
}

export class Error {

  readonly code: ErrorCode;
  readonly message: string;
  readonly data: Record<string, any> | null;
  readonly innerError: any | null = null;
  readonly source?: any | null = null;

  constructor(code: ErrorCode = 'Unknown', message?: string | null, props?: ErrorProps) {

    this.data = props?.data ?? null;
    this.innerError = props?.innerError ?? null;
    this.source = props?.source ?? null;

    if (!message) {
      switch (code) {
        case 'Unauthorized':
          message = `You don't have permissions to view this page.`;
          break;

        case 'JobUnauthorized':
          message = `You don't have permissions to view this video.`;
          break;

        case 'JobUnavailable':
          message = `This video is no longer available.`;
          break;


        case 'MessageTimeout':
          message = `Timeout expired while waiting to send a message.`;
          break;

        case 'JobDeleted':
          message = `Video no longer available - deleted.`;
          break;

        case 'TeamDeleted':
          message = `Library no longer available - deleted.`;
          break;

        case 'InternalError':
          message = `An unknown error occured.`;
          break;

        case 'LoadScriptError':
          message = `An error occurred while trying to load a script.`;
          break;

        case 'MediaPlayError':
          message = `Cannot play media. Either the media is not configured properly or the user hasn't interacted with the page first.`;
          break;

        case 'CommandAlreadyRequested':
          message = `The command has already been requested and has not completed its execution yet. You can abort the previous request and then request the command again.`;
          break;

        default:
        case 'Unknown':
          message = `An unknown error occurred.`;
          break;
      }
    }

    this.code = code;
    this.message = message ?? `An error of type ${code} has occurred.`;

    if (process.env.NODE_ENV !== 'production') {
      TRACE_ERROR(this.source ?? RUNTIME_ERROR, this.message, this);
    }
  }
}

export function unauthorizedError(message?: string) {
  return new Error('Unauthorized', message);
}

export function unauthorizedPageError(message?: string) {
  return new Error('Unauthorized',
    message || `You are not authorized to access this page.`);
}

export function error(code: ErrorCode = 'Unknown', message?: string) {
  return new Error(code, message);
}

export function pageError(code: ErrorCode = 'Unknown', message?: string) {
  return new Error(code,
    // TODO: replace with generic message and add specific messages from the source pages
    message || `You don't have permissions to view this page.`);
}

export function widgetError(code: ErrorCode = 'Unknown', message?: string) {
  return new Error(code,
    message || `An unknown error occured.`);
}