import { INIT_DEBUGGER, TRACE } from '../../core/debug/debugMacros';

import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import HLS, { Fragment, HlsAudioTrack } from 'hls.js';

import { assertNotNull, AsyncResult, isFiniteNumber, Maybe } from '../../core';
import { MediaInfo, JobModel } from '../../entities';
import { BindingProps, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { IPlayerAdapter } from '../player';
import { setCookies } from '../../core';
import { HTMLMediaEventType, HTMLMediaNetworkState, HTMLMediaReadyState } from './htmlMediaSchema';
import { getBufferedTimeFromRanges, getSeekableTimeFromRanges, getSeekableWindow, timeRangesHas } from '../../core/media/mediaUtils';
import { ITimeRegion } from '../../core/time/timeSchema';
import { createArray } from '../../core/array';
import { PlayerAdapterMessageType, SeekParams, SetAutoplayParams, SetControlsParams, SetPlaybackRateParams, SetVolumeParams } from './playerAdapterSchema';
import { VIDEO_HLS_EVENTS } from './hls/hlsEvents';
import { PlayerAudioTracksState } from './playerAudioTracksState';

export const VIDEO_DOM_EVENTS: HTMLMediaEventType[] = [
  'abort',          // Fired when the resource was not fully loaded, but not as the result of an error.
  'canplay',        // The browser can play the media, but estimates that not enough data has been loaded to play the media up to its end without having to stop for further buffering of content.
  'canplaythrough', // The browser estimates it can play the media up to its end without stopping for content buffering.
  'complete',       // The rendering of an OfflineAudioContext is terminated.
  'durationchange', // The duration attribute has been updated.
  'emptied',        // The media has become empty; for example, this event is sent if the media has already been loaded (or partially loaded), and the load() method is called to reload it.
  'ended',          // Playback has stopped because the end of the media was reached.
  'error',          // Fired when the resource could not be loaded due to an error.
  'loadeddata',     // The first frame of the media has finished loading.
  'loadedmetadata', // The metadata has been loaded.
  'loadstart',      // Fired when the browser has started to load a resource.
  'pause',          // Playback has been paused.
  'play',           // Playback has begun.
  'playing',        // Playback is ready to start after having been paused or delayed due to lack of data.
  'progress',       // Fired periodically as the browser loads a resource.
  'ratechange',     // The playback rate has changed.
  'seeked',         // A seek operation completed.
  'seeking',        // A seek operation began.
  'stalled',        // The user agent is trying to fetch media data, but data is unexpectedly not forthcoming.
  'suspend',        // Media data loading has been suspended.
  'timeupdate',     // The time indicated by the currentTime attribute has been updated.
  'volumechange',   // The volume has changed.
  'waiting',        // Playback has stopped because of a temporary lack of data
];

const HLS_LOGS = false;
const HLS_VERBOSE_LOGS = false;

/** The events for which the adapter will listen. */
const VIDEO_DOM_LISTENER_EVENTS = VIDEO_DOM_EVENTS;

/** The hls events for which the adapter will listen. */
const VIDEO_HLS_LISTENER_EVENTS = VIDEO_HLS_EVENTS;

export enum HlsManifestStatus {
  None = 'None',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Parsed = 'Parsed',
  Error = 'Error'
}

type Props = {
  media: MediaInfo,
  jobId: string | null,
};

export class PlayerAdapterState
  extends StoreNode
  implements IPlayerAdapter {

  readonly nodeType: 'PlayerAdapter' = 'PlayerAdapter';

  constructor(store: Store, props?: BindingProps<Props>) {
    super(store, props);
    makeObservable(this);

    INIT_DEBUGGER(this, {
      color: 'purple',
      enableTrace: false
    });

    this.onPropChange('media',
      this.handleMediaChange);

    this.playerAudioTracks = new PlayerAudioTracksState(this.store, {
      adapter: this
    });
  }

  readonly playerAudioTracks: PlayerAudioTracksState;

  @computed get media(): MediaInfo | null {
    return this.resolvedProps.media;
  }

  @computed get jobId(): string {
    return this.resolvedProps.jobId;
  }

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @observable isInitialized = false;
  readonly fragments: IObservableArray<Fragment> = createArray<Fragment>(true) as IObservableArray;
  @observable currentFragment: Fragment | null = null;
  @observable initFragment: Fragment | null = null;
  @observable fragmentDuration: number | null = null;
  @observable initPTS: number | null = null;
  @observable isLiveStream: boolean = false;

  @observable videoElement: HTMLVideoElement | null = null;

  @observable duration: number = 0;
  @computed get hasDuration() {
    return (Number.isFinite(this.duration) && this.duration > 0);
  }

  @observable error: MediaError | null = null;
  @observable readyState: HTMLMediaReadyState = HTMLMediaReadyState.HAVE_NOTHING;
  @observable networkState: HTMLMediaNetworkState = HTMLMediaNetworkState.NETWORK_EMPTY;

  @observable controls: boolean = false;
  @observable autoplay: boolean = false;
  @observable autoplayAfterRetry: boolean = false;
  @observable currentTime: number = 0;
  @observable volume: number = 0;
  @observable isMuted: boolean = false;
  @observable playbackRate: number = 1.0;

  @observable playedTimeRanges: TimeRanges | null = null;
  @observable bufferedTimeRanges: TimeRanges | null = null;
  @observable seekableTimeRanges: TimeRanges | null = null;
  @observable isPaused = false;
  @observable isSeeking: boolean = false;
  @observable isReady: boolean = false;
  @observable timer: NodeJS.Timeout | null = null;

  @computed get bufferedTime(): number {
    return getBufferedTimeFromRanges(this.bufferedTimeRanges, this.currentTime) ?? 0;
  }

  @computed get seekableTime(): number {
    return getSeekableTimeFromRanges(this.seekableTimeRanges, this.currentTime) ?? 0;
  }

  // live
  @computed get seekableWindow(): ITimeRegion | null {
    return getSeekableWindow(this.seekableTimeRanges);
  }

  @computed get seekableStart(): number {
    return this.seekableWindow?.startTime ?? 0;;
  }

  @computed get seekableEnd(): number {
    return this.seekableWindow?.endTime ?? 0;
  }

  @computed get initPtsInSec(): number {
    if (this.initPTS)
      return this.initPTS / 90000;

    return 0;
  }

  /**
   * Indicates that the playback has stopped because the end of the media resource was reached.
   */
  @observable isEnded: boolean = false;

  @observable hlsManifestStatus: HlsManifestStatus = HlsManifestStatus.None;

  @computed get isHlsManifestParsed(): boolean {
    return this.hlsManifestStatus === HlsManifestStatus.Parsed;
  }

  @computed get isHlsManifestLoaded(): boolean {
    return [HlsManifestStatus.Loaded, HlsManifestStatus.Parsed].includes(this.hlsManifestStatus);
  }

  @computed get isHlsManifestError(): boolean {
    return this.hlsManifestStatus === HlsManifestStatus.Error;
  }

  @computed get isHlsManifestIdle(): boolean {
    return this.hlsManifestStatus === HlsManifestStatus.None;
  }

  @observable clientWidth: number | null = null;
  @observable clientHeight: number | null = null;
  @observable videoWidth: number | null = null;
  @observable videoHeight: number | null = null;

  @observable hls: HLS | null = null;

  /** Stores the `currentTime` requested before the player has been initialized. */
  @observable initialCurrentTime: number | null = null;
  /** Stores the `volume` requested before the player has been initialized. */
  @observable initialVolume: number | null = null;
  /** Stores the `playbackRate` requested before the player has been initialized. */
  @observable initialPlaybackRate: number | null = null;
  /** Stores the `isPlaying` requested before the player has been initialized. */
  @observable initialIsPlaying: boolean | null = null;
  /** Stores the `controls` option requested before the player has been initialized. */
  @observable initialControls: boolean | null = null;
  /** Stores the `autoplay` option requested before the player has been initialized. */
  @observable initialAutoplay: boolean | null = null;

  @computed get isWaiting(): boolean {
    /**
     * https://html.spec.whatwg.org/multipage/media.html#event-media-waiting
     * readyState is equal to or less than HAVE_CURRENT_DATA, and paused is false.
     * Either seeking is true, or the current playback position is not contained in any of the ranges in buffered.
     * It is possible for playback to stop for other reasons without paused being false, but those reasons do not fire this event
     * (and when those situations resolve, a separate playing event is not fired either):
     * e.g., playback has ended, or playback stopped due to errors, or the element has paused for user interaction or paused for in-band content.
     */

    if (this.readyState === null)
      return false;

    return (
      this.readyState <= HTMLMediaReadyState.HAVE_CURRENT_DATA &&
      !this.isPaused && (
        this.isSeeking ||
        !timeRangesHas(this.bufferedTimeRanges, this.currentTime)));
  }

  @computed get isMetadataLoaded(): boolean {
    return this.readyState >= HTMLMediaReadyState.HAVE_METADATA;
  }

  @computed get canInvokePlay(): boolean {
    return this.isMetadataLoaded && !!this.videoElement;
  }

  /**
   * Returns `true` if `play` has been invoked by the user or through autoPlay.
   * As long as the element is mounted, this is the inverse of `isPaused`.
   *
   * ---
   * Returns `true` immediately after the `play` MediaEvent has been emitted and can return `false` again depending on user interactions.
   * The `play` MediaEvent is emitted when playback is ready to start after having been paused or delayed due to lack of media data.
   * @see `play` https://html.spec.whatwg.org/multipage/media.html#event-media-play
   */
  @computed get isPlaying(): boolean {
    return (
      !!this.videoElement &&
      !this.isPaused);
  }

  /**
   * Indicates that the player is in a playing state, meaning that it has enough data to play and it is not paused by the user.
   * This is not the exact inverse of `isPaused`, because in certain situations they might both be `false` (for example when the video is buffering).
   * However, most of the times they will be the inverse of each other.
   *
   * Returns `true` immediately after `playing` MediaEvent has been emitted and can return `false` again depending on user interactions or network conditions.
   * The `playing` MediaEvent is emitted when playback is ready to start after having been paused or delayed due to lack of media data.
   * @see `playing` https://html.spec.whatwg.org/multipage/media.html#event-media-playing
   */
  @computed get isActuallyPlaying(): boolean {
    // spec preconditions:
    // readyState is newly equal to or greater than HAVE_FUTURE_DATA and paused is false,
    // or paused is newly false and readyState is equal to or greater than HAVE_FUTURE_DATA.
    // Even if this event fires, the element might still not be potentially playing,
    // e.g. if the element is paused for user interaction or paused for in-band content.
    return (
      !!this.videoElement &&
      this.readyState >= HTMLMediaReadyState.HAVE_FUTURE_DATA &&
      !this.isPaused);
  }

  /**
   * The user agent can resume playback of the media data, but estimates that if playback were to be started now,
   * the media resource could not be rendered at the current playback rate up to its end without having to stop for further buffering of content.
   * Returns `true` after `canplay` MediaEvent has been emitted, but can be set back to `false` depending on the user interactions.
   * @see `canplay` https://html.spec.whatwg.org/multipage/media.html#event-media-canplay
   */
  get canPlay() {
    // spec preconditions:
    // `readyState` newly increased to `HAVE_FUTURE_DATA` or greater.

    if (this.readyState === null)
      return false;
    return this.readyState >= HTMLMediaReadyState.HAVE_FUTURE_DATA;
  }

  /**
   * The user agent estimates that if playback were to be started now,
   * the media resource could be rendered at the current playback rate all the way to its end without having to stop for further buffering.
   * It is `true` after `canplaythrough` MediaEvent has been emitted, but can be set back to `false` depending on the user interactions.
   * @see `canplaythrough` https://html.spec.whatwg.org/multipage/media.html#event-media-canplaythrough
   */
  get canPlayThrough() {

    // spec preconditions:
    // `readyState` is newly equal to `HAVE_ENOUGH_DATA`.

    if (this.readyState === null)
      return false;
    return this.readyState >= HTMLMediaReadyState.HAVE_ENOUGH_DATA;
  }

  @action
  private syncState() {

    const { videoElement: domVideo } = this;
    if (!domVideo)
      return this.resetState();

    // store some shared vars
    const time = domVideo.currentTime;
    const buffered = domVideo.buffered;

    // low-level state
    this.error = domVideo.error ?? null;
    this.readyState = domVideo.readyState;
    this.networkState = domVideo.networkState;

    // time ranges
    this.playedTimeRanges = domVideo.played ?? null;
    this.bufferedTimeRanges = buffered ?? null;
    this.seekableTimeRanges = domVideo.seekable ?? null;

    // metadata
    this.duration = domVideo.duration || 0;
    this.clientWidth = domVideo.clientWidth || 0;
    this.clientHeight = domVideo.clientHeight || 0;
    this.videoWidth = domVideo.videoWidth || 0;
    this.videoHeight = domVideo.videoHeight || 0;

    // flags
    this.autoplay = domVideo.autoplay ?? false;
    this.isSeeking = domVideo.seeking ?? false;
    this.isPaused = domVideo.paused ?? false;
    this.isEnded = domVideo.ended ?? false;

    // time
    this.currentTime = time;

    // settings
    this.isMuted = domVideo.muted;
    this.volume = domVideo.volume;
    this.playbackRate = domVideo.playbackRate;
  }

  @action
  private resetState() {

    // low-level state
    this.error = null;
    this.readyState = HTMLMediaReadyState.HAVE_NOTHING;
    this.networkState = HTMLMediaNetworkState.NETWORK_EMPTY;

    // time ranges
    this.playedTimeRanges = null;
    this.bufferedTimeRanges = null;
    this.seekableTimeRanges = null;

    // metadata
    this.duration = 0;
    this.videoWidth = 0;
    this.videoHeight = 0;

    // flags
    this.isSeeking = false;
    this.isPaused = false;
    this.isEnded = false;

    // time
    this.currentTime = 0;

    // volume
    this.isMuted = false;
    this.volume = 0;

    // hls
    this.isInitialized = false;
    this.hlsManifestStatus = HlsManifestStatus.None;

    // hls fragments
    this.fragments.clear();
    this.initFragment = null;
    this.currentFragment = null;
    this.isLiveStream = false;
    this.initPTS = null;

    // audio tracks
    this.playerAudioTracks.reset();
  }

  @action
  private handleMediaChange = () => {
    if (this.isInitialized && !this.isHlsManifestLoaded) {
      this.reattachDOMVideo();
    }
    else
      this.tryInitPlayer();
  }

  @action
  mounted(video: HTMLVideoElement | null) {
    TRACE(this, `mounted()`, video);

    assertNotNull(video);
    this.videoElement = video;

    // attach event handlers
    VIDEO_DOM_LISTENER_EVENTS.forEach(evtName =>
      video.addEventListener(evtName, this.handleDOMEvent));

    this.tryInitPlayer();
    this.emit(PlayerAdapterMessageType.Mounted);
  }

  @action
  unmounted() {
    TRACE(this, `unmounted()`);

    const video = this.videoElement;
    assertNotNull(video);

    TRACE(this, `Calling 'pause' on HTMLVideoElement and removing it's source`);
    video.pause();
    video.removeAttribute('src');
    video.childNodes.forEach(item => video.removeChild(item));
    Object.values(video.textTracks).forEach(track => track.mode = 'disabled');

    VIDEO_DOM_LISTENER_EVENTS.forEach(evtName =>
      video.removeEventListener(evtName, this.handleDOMEvent));

    if (this.videoElement)
      this.videoElement.textTracks.onaddtrack = null;
    this.videoElement = null;

    this.resetState();
    this.resetInitialState();

    if (this.hls) {
      this.hls.stopLoad();
      this.hls.detachMedia();
      this.hls.destroy();
      this.hls = null;
    }

    this.isInitialized = false;
    this.isLiveStream = false;
    this.emit('videoDetached');

    this.emit(PlayerAdapterMessageType.Unmounted);
  }

  @action
  reattachDOMVideo() {
    TRACE(this, `Rettaching HTMLVideoElement`);
    if (!this.videoElement) {
      TRACE(this, `Rettaching HTMLVideoElement suppressed because the HTMLVideoElement is not available`);
      return;
    }
    const video = this.videoElement;
    this.unmounted();
    this.mounted(video);
  }

  @action
  private tryInitPlayer() {
    TRACE(this, `tryInitPlayer()`);

    const { media, videoElement: video } = this;
    if (!media || !video || this.isInitialized) {
      TRACE(this, `'tryInitPlayer' stopped because either there's no media, no video, or the player is already initialized`);
      return;
    }

    const audioSrc = media.basic;
    const videoSrc = media.hls?.signedUrl ?? media?.liveStreamUrl;
    const isLiveStream = !!media?.liveStreamUrl && !media.hls?.signedUrl;
    const cookies = media.hls?.cookieCredentials;

    if (!videoSrc && !audioSrc) {
      TRACE(this, `'tryInitPlayer' stopped because there is no video or audio source available`);
      return;
    }

    // ANOTHER HACK, EVERYTHING HERE IS A HACK
    if (!media.isPublic && !audioSrc && !isLiveStream) {
      if (!cookies) {
        TRACE(this, `'tryInitPlayer' stopped because the media is not public, there is no audio source, the stream is not live and there are no cookies specified`);
        return;
      }
      setCookies(cookies);
    }

    // TODO: Check why
    video.autoplay = false;

    const isHlsSupported = HLS.isSupported();
    if (isHlsSupported && !audioSrc) {
      // no native HLS support, but hls.js is supported

      if (this.hls) {
        TRACE(this, `'tryInitPlayer' stopped because HLS js is already initialized`);
        return;
      }

      // console.log('using HLS.js');

      const hls = new HLS({
        // render natively will create a caption/subtitle kind track,
        // we will create it ourselves as metadata to avoid ui issues and update it based on the hls.js events
        //@ts-ignore
        renderTextTracksNatively: false

      });

      hls.config.xhrSetup = (xhr, url) => {
        // ANOTHER HACK, EVERYTHING HERE IS A HACK
        xhr.withCredentials = !media.isPublic && !isLiveStream; // do send cookies
      }
      hls.config.startLevel = -1; //set in order to activate the automatic start level selection

      if (isLiveStream) {
        //TODO: refactor this mess
        hls.config.liveSyncDuration = 3;
        hls.config.liveBackBufferLength = 1200;
        hls.config.maxBufferSize = 220 * 1000 * 1000;
        // hls.config.maxMaxBufferLength = 11 * 60;
        // hls.config.initialLiveManifestSize = 5;
      }

      TRACE(this, `Attach media to HLS.js and load the source`);
      hls.attachMedia(video);
      hls.loadSource(videoSrc);
      this.hlsManifestStatus = HlsManifestStatus.Loading;

      // attach event handlers
      VIDEO_HLS_LISTENER_EVENTS.forEach((evtName: any) =>
        hls.on(evtName, this.handleHLSEvent));

      this.hls = hls;
    }
    // check for native browser HLS support
    else if (video.canPlayType('application/vnd.apple.mpegurl') || audioSrc) {
      TRACE(this, `Browser natively supports HLS so the native mode will be used`);

      // console.log('using native HLS');
      video.src = audioSrc ?? videoSrc;
    }

    this.isInitialized = true;
    this.emit('ready');
    this.isLiveStream = isLiveStream;
    TRACE(this, `'tryInitPlayer' completed`);
  }

  @action
  private resetInitialState() {
    TRACE(this, `Resetting initial state`);

    this.initialCurrentTime = null;
    this.initialVolume = null;
    this.initialPlaybackRate = null;
    this.initialControls = null;
    this.initialAutoplay = null;
    this.initialIsPlaying = null;
  }

  @action
  private applyInitialState() {
    TRACE(this, `Applying initial state`);

    const video = this.videoElement;
    if (!video) {
      TRACE(this, `Applying initial state suppressed because the HTMLVideoElement is not available`);
      return;
    }

    if (this.initialCurrentTime !== null) {
      TRACE(this, `Setting 'currentTime' on HTMLVideoElement because 'initialCurrentTime' is ${this.initialCurrentTime}`);
      video.currentTime = this.initialCurrentTime;
    }

    if (this.initialVolume !== null)
      video.volume = this.initialVolume;

    if (this.initialPlaybackRate !== null)
      video.playbackRate = this.initialPlaybackRate;

    if (this.initialAutoplay !== null) {
      // note, this should also be set on mounting, but we set it here as well for safety
      TRACE(this, `Setting 'autoplay' on HTMLVideoElement because 'initialAutoplay' is ${this.initialAutoplay}`);
      video.autoplay = this.initialAutoplay;
    }

    switch (this.initialIsPlaying) {
      case true:
        TRACE(this, `Calling 'play' on HTMLVideoElement because 'initialIsPlaying' is true`);
        video.play();
        break;
      case false:
        TRACE(this, `Calling 'pause' on HTMLVideoElement because 'initialIsPlaying' is false`);
        video.pause();
        break;
    }

    if (this.media?.subtitle) {
      this.handleExternalCaptions(video);
    }

    this.syncState();
  }

  @action
  handleExternalCaptions = (video: HTMLVideoElement) => {
    const track: HTMLTrackElement & { isCaption?: boolean } = document.createElement('track');
    track.id = 'transcript';
    track.isCaption = true;
    track.kind = 'metadata';
    track.label = 'English';
    track.srclang = 'en';
    track.default = true;

    if (this.media?.subtitle)
      track.src = this.media.subtitle;

    video.appendChild(track);
  }

  setHlsAudioTrack = (track: HlsAudioTrack) => {
    if (!this.hls)
      return;

    this.hls.audioTrack = track.id;
  }

  @action
  private handleDOMEvent = (evt: Event) => {

    if (evt.type !== 'progress' && evt.type !== 'timeupdate')
      TRACE(this, 'HTMLVideoElement event', evt.type, evt);

    switch (evt.type) {
      case 'loadedmetadata':
        this.applyInitialState();
        this.resetInitialState();
    }

    this.syncState();
    this.emit(evt.type);
  }

  @action
  invoke = async (type: string, payload: any = null) => {

    TRACE(this, 'Invoke', type, payload);

    this.syncState();

    switch (type) {
      case 'play':
        await this.play();
        break;

      case 'pause':
        this.pause();
        break;

      case 'seek':
        this.seek(payload);
        break;

      case 'setVolume':
        this.setVolume(payload);
        break;

      case 'setPlaybackRate':
        this.setPlaybackRate(payload);
        break;

      case 'setControls':
        this.setControls(payload);
        break;

      case 'setAutoplay':
        this.setAutoplay(payload);
        break;
    }

    this.syncState();
  }

  isValidSeekTime(time?: Maybe<number>): boolean {

    const { videoElement: domVideo } = this;
    if (isFiniteNumber(time) && time >= 0) {
      const duration = domVideo?.duration;
      if (!isFiniteNumber(duration)) {
        // no info about the duration so we cannot disprove the validity of the provided time
        return true;
      }

      // if we have a duration, seek time must be less than or equal to that
      return time <= duration;
    }

    // either not a finite number or less than 0
    return false;
  }

  private handleHlsManifestParsed = action((
    evtType: typeof HLS.Events.MANIFEST_PARSED,
    data: HLS.manifestParsedData) => {
    this.syncState();
    this.hlsManifestStatus = HlsManifestStatus.Parsed;
    this.timer && clearTimeout(this.timer);
    HLS_LOGS && console.log('manifest parsed', data);
  });

  private handleHlsManifestLoaded = action((
    evtType: typeof HLS.Events.MANIFEST_LOADED,
    data: HLS.manifestLoadedData) => {
    this.hlsManifestStatus = HlsManifestStatus.Loaded;
    this.timer && clearTimeout(this.timer);
    HLS_LOGS && console.log('manifest loaded', data);
  });

  private handleHlsFragmentLoaded = action((
    evtType: typeof HLS.Events.FRAG_LOADED,
    data: HLS.fragLoadedData) => {
    this.fragments.push(data.frag);
  });

  private handleHlsFragChanged = action((
    evtType: typeof HLS.Events.FRAG_CHANGED,
    data: HLS.fragChangedData) => {
    this.currentFragment = data.frag;
    if (!this.initFragment) {
      this.initFragment = data.frag;
      HLS_LOGS && console.log('init frag', data);
    }
  });

  private handleHlsBufferAppended = action((
    evtType: typeof HLS.Events.BUFFER_APPENDED,
    data: HLS.bufferAppendedData) => {
  });

  private handleHlsBufferCreated = action((
    evtType: typeof HLS.Events.BUFFER_CREATED,
    data: HLS.bufferCreatedData) => {
    HLS_LOGS && console.log('buffer created', data);

    if (!data || !this.isLiveStream)
      return;

    const tracks = (data as any).tracks;

    if (!tracks.video) {
      if (this.autoplay)
        this.autoplayAfterRetry = true;

      console.warn('NO VIDEO TRACK AVAILABLE');

      // reattach in order to clear and reload the buffered fragments and not wait for the problematic fragment to be flushed
      this.reattachDOMVideo();
    } else if (this.autoplayAfterRetry) {
      this.invoke('setAutoplay', { autoplay: true });
      this.autoplayAfterRetry = false;
    }

  });

  private handleHlsInitSegmentParsed = action((
    evtType: typeof HLS.Events.FRAG_PARSING_INIT_SEGMENT,
    data: HLS.fragParsingInitSegmentData) => {

    if (!data)
      return;

    const { frag } = data;
    this.fragmentDuration = frag?.duration || null;
    HLS_LOGS && console.log('first segment parsed', data);
  });

  private handleHlsBufferEOS = action((
    evtType: typeof HLS.Events.BUFFER_EOS) => {
    this.emit('endOfStream');
    HLS_LOGS && console.log('EOS');
  });

  private handleHlsAudioTracksUpdated = action((
    evtType: typeof HLS.Events.AUDIO_TRACKS_UPDATED,
    data: HLS.audioTracksUpdatedData) => {
    HLS_LOGS && console.log('AUDIO TRACKS UPDATED', data, this.hls);
    this.playerAudioTracks.refreshAudioTracks();
  });

  private handleHlsAudioTrackSwitching = action((
    evtType: typeof HLS.Events.AUDIO_TRACK_SWITCHING,
    data: HLS.audioTrackSwitchingData) => {
    HLS_LOGS && console.log('AUDIO TRACK SWITCHING', data);
    const trackId = data.id?.toString() ?? null;
    this.playerAudioTracks.setSelectedTrack(trackId);
  });

  private handleHlsAudioTrackSwitched = action((
    evtType: typeof HLS.Events.AUDIO_TRACK_SWITCHED,
    data: HLS.audioTrackSwitchedData) => {
    HLS_LOGS && console.log('AUDIO TRACK SWITCHED', data);
    this.playerAudioTracks.refreshActiveAudioTrack();
    HLS_LOGS && console.log('ACTIVE AUDIO TRACK', this.playerAudioTracks.activeAudioTrack);
  });

  private handleHlsAudioTrackError = action(
    (data: HLS.errorData) => {
      HLS_LOGS && console.log('AUDIO TRACK ERROR', data);
      this.playerAudioTracks.setError();
      this.playerAudioTracks.refreshActiveAudioTrack();
    });

  private handleHlsInitPTSFound = action((
    evtType: typeof HLS.Events.INIT_PTS_FOUND,
    data: HLS.initPtsFoundData) => {
    this.initPTS = data.initPTS;
    HLS_LOGS && console.log('init pts', data);
  });

  private handleHlsNonNativeTextTracksFound = action((
    evtType: 'hlsNonNativeTextTracksFound',
    data: any) => {
    const tracks = data.tracks;

    HLS_LOGS && console.log('hlsNonNativeTextTracksFound', data);

    tracks.forEach((track: any) => {
      const textTrack: HTMLTrackElement & { isCaption?: boolean } = document.createElement('track');
      textTrack.kind = 'metadata';
      textTrack.isCaption = true;
      textTrack.label = track.label;
      textTrack.default = track.default;


      if (track.kind === 'captions') {
        // Based on hls.js, for captions the id is the trackName (for CEA608/708 4 channels, named textTrack1, textTrack2, textTrack3, textTrack4)
        textTrack.id = track._id;
        textTrack.srclang = track.lang ?? track.label;
      } else if (track.kind === 'subtitles') {
        // Based on hls.js m3u8 parser (parseMasterPlaylistMedia), the id is incremented in the order of the subtitle tracks in the manifest file
        const subtitleTrack = track.subtitleTrack;
        textTrack.id = subtitleTrack?.id;
        textTrack.srclang = subtitleTrack?.lang;
      }

      this.videoElement?.appendChild(textTrack);
      textTrack.track.mode = 'hidden';
    })

  });

  private handleHlsCuesParsed = action((
    evtType: 'hlsCuesParsed',
    data: any) => {

    HLS_LOGS && console.log('hlsCuesParsed', data, this.hls);

    const type = data.type;
    const cues = data.cues;
    const track = data.track;
    const video = this.videoElement;
    const textTracks = video?.textTracks ?? [];

    let trackId: string | null = null;

    // the track parameter (which we can use to identify the correspondent textTrack id):
    // for `captions` the track id is equal to the track values //this.hls.trigger(Event.CUES_PARSED, { type: 'captions', cues, track: trackName });//
    // for `subtitles` hls.js in its great wisdom populates the field as follows (the level is the index in the manifest playlist):
    // let trackId = this.tracks[frag.level].default ? 'default' : 'subtitles' + frag.level;
    // hls.trigger(Event.CUES_PARSED, { type: 'subtitles', cues: cues, track: trackId });
    if (type === 'subtitles') {
      trackId = (track === 'default') ? 'default' : track.substring(type.length);
    } else if (type === 'captions') {
      trackId = track;
    }

    if (trackId === 'default') {
      const subtitleTracks = this.hls!.subtitleTracks || [];
      trackId = subtitleTracks.find(subtitle => !!subtitle.default)?.id.toString() ?? this.hls!.subtitleTrack.toString();
    }

    const textTrack = [...Object.values(textTracks)].find(item => item.id === trackId && item.mode !== 'disabled') ?? null;

    if (textTrack) {
      // Add cues
      cues.forEach((cue: any) => {

        if (!cue.id)
          textTrack!.addCue(cue);
        // Sometimes there are cue overlaps on segmented vtts so the same
        // cue can appear more than once in different vtt files.
        // This avoid showing duplicated cues with same timecode and text.
        else if (!textTrack!.cues?.getCueById(cue.id)) {
          try {
            textTrack!.addCue(cue);
            if (!textTrack!.cues?.getCueById(cue.id)) {
              throw new Error(`addCue is failed for: ${cue}`);
            }
          } catch (err) {
            const textTrackCue = new VTTCue(cue.startTime, cue.endTime, cue.text);
            textTrackCue.id = cue.id;
            textTrack!.addCue(textTrackCue);
          }
        }
      });
    }

  });

  private handleHlsError = action((
    evtType: typeof HLS.Events.ERROR,
    data: HLS.errorData) => {
    HLS_LOGS && console.log('HLS Error', data);

    switch (data.details) {
      case HLS.ErrorDetails.MANIFEST_LOAD_ERROR:
      case HLS.ErrorDetails.MANIFEST_LOAD_TIMEOUT:
      case HLS.ErrorDetails.MANIFEST_PARSING_ERROR:
        this.handleHlsManifestError(data);
        break;
      case HLS.ErrorDetails.FRAG_LOAD_ERROR:
        this.handleHlsFragmentLoadError(data);
        break;
      case HLS.ErrorDetails.AUDIO_TRACK_LOAD_ERROR:
      case HLS.ErrorDetails.AUDIO_TRACK_LOAD_TIMEOUT:
        this.handleHlsAudioTrackError(data);
        break;
      default:
        break;
    }
  })

  private handleHlsFragmentLoadError = action((data: HLS.errorData) => {
    this.emit('sourceError');
    this.reauthorize();
  });

  private handleHlsManifestError = action((data: HLS.errorData) => {
    this.hlsManifestStatus = HlsManifestStatus.Error;
    if (data.response?.code === 404) {
      this.timer = setTimeout(() => this.reattachDOMVideo(), 3000)
    }
  });

  private async reauthorize() {
    const { hls, store } = this;

    // try to signal to HLS.js that it should not fetch any more segments
    // while the credentials are refreshed
    if (hls)
      hls.stopLoad();

    const [jobRes, err] = await store.api.getJob({ id: this.jobId });
    if (err)
      return;
    assertNotNull(jobRes);

    const { media } = new JobModel(jobRes.getJob!, store);
    const cookies = media.hls?.cookieCredentials;
    if (!media || !cookies)
      return;

    setCookies(media.hls.cookieCredentials);

    // resume segment fetching
    if (hls)
      hls.startLoad();
  }

  // #region Media API

  @action
  async play(): AsyncResult<boolean> {
    const { videoElement: domVideo } = this;
    if (!domVideo) {
      TRACE(this, `Call to 'play' delayed because HTMLVideoElement is not available.`);
      this.initialIsPlaying = true;
      return [false];
    }

    try {
      TRACE(this, `Calling 'play' on HTMLVideoElement`);
      await domVideo.play();
      TRACE(this, `Call to 'play' on HTMLVideoElement succeeded`);
      return [true];
    } catch (err) {
      TRACE(this, `Call to 'play' on HTMLVideoElement failed with error`, err);
      return [null, new Error('MediaPlayError')];
    }
  }

  @action
  pause() {
    const { videoElement: domVideo } = this;
    if (!domVideo) {
      TRACE(this, `Call to 'pause' delayed because HTMLVideoElement is not available.`);
      this.initialIsPlaying = false;
      return;
    }
    TRACE(this, `Calling 'pause' on HTMLVideoElement`);
    domVideo.pause();
  }

  @action
  seek(params: SeekParams) {
    const { videoElement: domVideo } = this;
    const { time } = params;
    if (!this.isValidSeekTime(time)) {
      console.warn(`'seek' call was rejected because 'time' param is not valid:`, time);
      return;
    }

    if (!domVideo) {
      TRACE(this, `Call to 'seek' delayed because HTMLVideoElement is not available`);
      this.initialCurrentTime = time;
      return;
    }

    try {
      // it should never throw an error because the time has been validated
      // however setting a value on this prop can throw errors in some circumstances
      // so better safe than sorry
      TRACE(this, `Setting 'currentTime' on HTMLVideoElement to ${time}`);
      domVideo.currentTime = time;
    } catch (err) {
      console.warn(`Error setting 'currentTime' on HTMLMediaElement: ${(err as any).message}`);
    }
  }

  @action
  setVolume(params: SetVolumeParams) {
    const { videoElement: domVideo } = this;
    const { volume } = params;
    if (!domVideo) {
      this.initialVolume = volume;
      return;
    }
    domVideo.volume = volume;
  }

  @action
  setPlaybackRate(params: SetPlaybackRateParams) {
    const { videoElement: domVideo } = this;
    const { rate } = params;
    if (!domVideo) {
      this.initialPlaybackRate = rate;
      return;
    }
    domVideo.playbackRate = rate;
  }

  @action
  setControls(params: SetControlsParams) {
    const { videoElement: domVideo } = this;
    const { controls } = params;
    if (!domVideo) {
      TRACE(this, `Call to 'setControls' delayed because HTMLVideoElement is not available`);
      this.initialControls = controls;
      return;
    }
    TRACE(this, `Setting 'controls' on HTMLVideoElement to ${controls}`);
    domVideo.controls = controls;
  }

  @action
  setAutoplay(params: SetAutoplayParams) {
    TRACE(this, `setAutoplay()`, params);

    const { videoElement: domVideo } = this;
    const { autoplay } = params;
    if (!domVideo) {
      TRACE(this, `Call to 'setAutoplay' delayed because HTMLVideoElement is not available`);
      this.initialAutoplay = autoplay;
      return;
    }
    TRACE(this, `Setting 'autoplay' on HTMLVideoElement to ${autoplay}`);
    domVideo.autoplay = autoplay;
  }
  // #endregion

  @action
  private handleHLSEvent = (evt: any, data: any) => {

    switch (evt) {
      // manifest
      case HLS.Events.MANIFEST_PARSED:
        this.handleHlsManifestParsed(evt, data);
        break;
      case HLS.Events.MANIFEST_LOADED:
        this.handleHlsManifestLoaded(evt, data);
        break;
      // error
      case HLS.Events.ERROR:
        this.handleHlsError(evt, data);
        break;
      // pts
      case HLS.Events.INIT_PTS_FOUND:
        this.handleHlsInitPTSFound(evt, data);
        break;
      // fragments
      case HLS.Events.FRAG_PARSING_INIT_SEGMENT:
        this.handleHlsInitSegmentParsed(evt, data)
        break;
      // case HLS.Events.FRAG_LOADED:
      // this.handleHlsFragmentLoaded(evt, data);
      // break;
      case HLS.Events.FRAG_CHANGED:
        this.handleHlsFragChanged(evt, data);
        break;
      // buffer
      case HLS.Events.BUFFER_EOS:
        this.handleHlsBufferEOS(evt);
        break;
      case HLS.Events.BUFFER_CREATED:
        this.handleHlsBufferCreated(evt, data);
        break;
      case HLS.Events.BUFFER_APPENDED:
        this.handleHlsBufferAppended(evt, data);
        break;
      // audio tracks
      case HLS.Events.AUDIO_TRACKS_UPDATED:
        this.handleHlsAudioTracksUpdated(evt, data)
        break;
      case HLS.Events.AUDIO_TRACK_SWITCHING:
        this.handleHlsAudioTrackSwitching(evt, data)
        break;
      case HLS.Events.AUDIO_TRACK_SWITCHED:
        this.handleHlsAudioTrackSwitched(evt, data)
        break;
      case 'hlsNonNativeTextTracksFound':
        this.handleHlsNonNativeTextTracksFound(evt, data)
        break;
      case 'hlsCuesParsed':
        this.handleHlsCuesParsed(evt, data)
        break;
      default:
        this.emit('HLS_' + evt.type);
        break;
      // hls.on("hlsLevelLoaded",
      //   (evt, data) => console.log(data));
      // hls.on(HLS.Events.FRAG_LOADED, (evt, data) => this.handleHlsFragmentLoaded(evt, data));
      // hls.once("hlsFragParsingUserData", (evt, data) => console.log(data));
      // hls.once("hlsFragParsingMetadata", (evt, data) => console.log(data));
      // hls.once("hlsFragParsingData", (evt, data) => console.log(data));
    }

    HLS_VERBOSE_LOGS
      && console.log(evt, data, this.hls);
  }
}
