
import { action, makeObservable, observable } from "mobx";
import { WindowState } from "../../components/overlays";
import { LibraryLabels } from "../../services/libraries/libraryServiceBase";
import { Message, StoreNode } from "../../store";
import { Store } from "../../store/store";
import { IntegrationTabState } from "../integrations/integrationTabState";
import { ZoomIntegrationTabState } from "../integrations/zoomIntegrationTabState";

export class ExternalLibraryUploadWindowState extends StoreNode {
  readonly nodeType = 'ExternalLibraryUploadWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    // #region Google Drive tab state
    this.googleDriveTabState = new IntegrationTabState(this.store, {
      pageSize: 20,
      libraryService: this.store.googleDrive,
      viewType: 'list'
    });
    // #endregion

    // #region One Drive source state
    this.oneDriveTabState = new IntegrationTabState(this.store, {
      pageSize: 20,
      libraryService: this.store.oneDrive,
      defaultSearchValue: this.store.oneDrive.defaultSearchValue,
      viewType: 'list'
    });
    // #endregion

    // #region Zoom source state
    this.zoomIntegrationTabState = new ZoomIntegrationTabState(this.store, {
      pageSize: 20,
      libraryService: this.store.zoom,
      viewType: 'list'
    });
    // #endregion
    this.window.listen(this.windowListener);
  }
  @observable isLoading: boolean = false;
  @observable error: Error | null = null;
  @observable activeTab: LibraryLabels = 'Google Drive';
  readonly window = new WindowState(this.store, { ignoredTargetOnOutsideClickSelector: 'ant-picker-date-range-wrapper' });

  readonly googleDriveTabState: IntegrationTabState;
  readonly oneDriveTabState: IntegrationTabState;
  readonly zoomIntegrationTabState: ZoomIntegrationTabState;

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  private close() {
    
    const { uploadService } = this.store;
    uploadService.clearLibraryTabNavigation();

    this.dispatch('Overlays', 'closeWindow');
    this.reset();
  }

  @action
  async open(payload: { activeTab: LibraryLabels | null }) {
    this.reset();
    // const { user } = this.store;
    const { activeTab } = payload;

    this.isLoading = true;
    this.dispatch('Overlays', 'openWindow',
      { name: 'ExternalLibraryUploadWindow' });
    if (activeTab) {
      this.activeTab = activeTab;
    }

    this.isLoading = false;
  }

  @action
  navigateToTab(tab: LibraryLabels | null) {

    const { uploadService } = this.store;

    switch (tab) {
      case 'Google Drive':
        return uploadService.navigateToGoogleDriveTab();
      case 'Zoom':
        return uploadService.navigateToZoomTab();
      case 'OneDrive':
        return uploadService.navigateToOneDriveTab();
      default:
        return uploadService.clearLibraryTabNavigation();
    }
  }

  @action
  reset() {
    this.isLoading = false;
    this.error = null;
    this.activeTab = 'Google Drive';
  }
}