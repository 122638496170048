import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { Overlay } from '../../overlays/overlay';
import { Tooltip } from '../../overlays/tooltip';
import { useOverlayState } from '../../overlays/overlayState';
import { PlayerReactionSVGIcon } from '../../svg';
import { PlayerState } from '../playerState';
import { InputPlayerReactionNames } from '../../playerReactions/playerReactionSchema';
import { ReactionSVGIconLookup } from '../../playerReactions/reactionSvgIconLookup';
import { Placement } from '../../playerTutorial/playerTutorial.consts';
import { ComponentVisibility, IComponentPolicy } from '../../componentSchema';
import { PlayerComponentName } from '../playerSchema';

type Props = {
  model: PlayerState,
  policy?: IComponentPolicy
}

export const ReactionButton = observer(({
  model,
  policy = model.getComponentPolicy(PlayerComponentName.ReactionButton)
}: Props) => {

  const btnModel = model.reactionButton;
  const panelState = useOverlayState({
    visibility: 'OnClick',
    visibilityTrigger: () => (model.chrome.visibility !== 'Visible' ? false : undefined),
    onShow: () => {
      if (!model.isLiveStream)
        model.invoke('enterPause');
      
      model.chrome.startControlAction();
    },
    onHide: () => {
      model.chrome.endControlAction();
    },
  });

  const [isHovered, setHovered] = useState(false);

  useEffect(() => {
    model.playerTutorialIsOnForReactions ?
      panelState.setPlayerTutorialMode(true) : panelState.setPlayerTutorialMode(false);
  }, [model.playerTutorialIsOnForReactions, panelState]);

  if (policy.visibility !== ComponentVisibility.Visible)
    return null;

  const className = classNames('user-player-control-btn reaction-btn', {
    hover: isHovered
  });

  return (
    <button
      id="reactions-button"
      data-tutorial-id="reactions-button"
      className={className}
      tabIndex={0}
      ref={panelState.anchorRef.ref}
      {...panelState.anchorProps}
      onPointerEnter={() => {
        setHovered(true);
      }}
      onPointerLeave={() => {
        setHovered(false);
      }}>

      <div className="icon"
        onClick={btnModel.handleButtonClick}>
        <PlayerReactionSVGIcon />
      </div>

      <Overlay
        model={panelState}
        className={`${model.playerTutorialIsOnForReactions ? 'player-tutorial-mode' : ''} control-panel`}
        id="player-reactions-panel"
        data-tutorial-id="player-reactions-panel">

        <ul
          id="player-reactions-menu"
          data-tutorial-id="player-reactions-menu"
          role="menu">
          {InputPlayerReactionNames.map(name => {
            const iconElem = ReactionSVGIconLookup[name];

            return (
              <Tooltip
                key={name}
                className="player-reactions-tooltip"
                arrowClassName="player-reactions-tooltip-arrow"
                allowedAutoPlacements={[Placement.Top]}
                fallbackPlacements={[Placement.Top]}
                content={iconElem.tooltip}>
                <li className="menu-list-item"
                  key={name}>

                  <button className="player-reactions-menu-btn"
                    role="menuitem"
                    onClick={evt => {
                      model.addReaction(name)
                      panelState?.hide();
                    }}>

                    <span className="icon">
                      {iconElem.component}
                    </span>
                  </button>
                </li>
              </Tooltip>
            );
          })}
        </ul>
      </Overlay>
    </button>
  );
});