import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { TeamMembersWindowState } from './teamMembersWindowState';
import { ButtonControlled, Button } from '../input';
import { TeamMemberCatalog } from './teamMemberCatalog';
import { useStore } from '../../store/storeHooks';
import { AddInviteListGroup } from '../createTeamForm/addInviteList';

type Props = {
  model: TeamMembersWindowState;
  visible?: boolean;
};

export const TeamMembersWindow = observer((props: Props) => {
  const store = useStore();
  const { user } = store;
  const { model } = props;

  return (
    <Window
      model={model.window}
      visible={props.visible}
      isLoading={!!(model.isLoading || model.memberCatalogState.isLoading)}
      className="team-members-window">
      <WindowHeader model={model.window} title="Library Members" />
      <hr />
      <div className="window-content">
        <div className="tab-navigation">
          {model.visibleTabs.includes('Members') &&
            <Button
              className={`tab ${model.activeTab === 'Members' ? ' active' : ''}`}
              label="Members"
              onClick={() => (model.activeTab = 'Members')}
            />}
          {model.team?.userPermissions?.includes('EditTeam') &&
            model.visibleTabs.includes('Invite') && (
              <Button
                className={`tab ${model.activeTab === 'Invite' ? ' active' : ''}`}
                label="Invite"
                onClick={() => (model.activeTab = 'Invite')}
              />
            )}
          {model.activeTab === 'Invite' &&
            user?.hasPermission('BulkInviteTeammates') && (
              <Button
                className="nav-link medium bulk-invites-button"
                onClick={() => {
                  model.bulkInvitesButtonClick();
                }}
                label="Upload CSV"
              />
            )}
        </div>

        {model.activeTab === 'Members' && (
          <TeamMemberCatalog
            model={model.memberCatalogState}
            source={model.memberCatalogSource}
          />
        )}
        {model.activeTab === 'Invite' && (
          <AddInviteListGroup
            className="tmar4"
            model={model.inviteListState}
            addButtonRight={false}
          />
        )}

        {model.inviteListState.isLocked && (
          <div className="notification-box">
            {model.inviteListState.hasError ? (
              <div className="error-notification">
                The user could not be added to your library. Please contact an
                administrator.
              </div>
            ) : (
              <div className="success-notification">
                Library Member Successfully added!
              </div>
            )}
          </div>
        )}
      </div>
      <hr />
      <footer className="window-footer">
        <div className="btn-group right">
          {model.activeTab === 'Members' && (
            <ButtonControlled
              className="primary medium"
              label="Done"
              onClick={(evt) => model.close()}
              model={model.cancelButton}
            />
          )}

          {model.activeTab === 'Invite' && (
            <>
              <ButtonControlled
                className="primary medium btn-outline"
                label="Cancel"
                onClick={(evt) => model.close()}
                model={model.cancelButton}
              />
              <ButtonControlled
                className="primary medium"
                label="Send"
                onClick={(evt) => model.submitInvites()}
                model={model.saveInvitesButton}
              />
            </>
          )}
        </div>
      </footer>
    </Window>
  );
});
