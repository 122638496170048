import React from 'react';
import { observer } from 'mobx-react-lite';
import { handler } from '../../core';
import { MomentSplashState } from './momentSplashState';

type Props = {
  model: MomentSplashState
}

export const TopicSelectorSection = observer(({
  model 
}: Props) => {
  const { selector } = model;

  const {
    topics,
    filteredTopics,
    visibleTopics,
    isTopicListExpandable: expandable,
    isTopicListExpanded: expanded
  } = model;

  let expandBtn;
  if (expandable) {
    expandBtn = (
      <button className="toggle-expansion-btn btn-core tmar4"
        onClick={handler(model, 'toggleTopicListExpansion')}>

        <span className="text">{expanded ? 'Hide' : 'Show all'}</span>
        <span className="icon"></span>
      </button>
    );
  }

  return (
    <section className="topic-filters content-section vpad6 hpad4"
      aria-expanded={expandable ? expanded : undefined}>

      {topics.length === 0 &&
        <div className="filters-heading">This video doesn't have any topics yet.</div>}

      {topics.length > 0 && visibleTopics.length === 0 &&
        <div className="filters-heading">No topics match your query.</div>}

      {filteredTopics.length > 0 &&
        <>
          <div className="filters-selector">

            <ul className="filter-list">
              {visibleTopics.map(top => {

                return (
                  <li className="filter-item" key={top.id}>
                    <button className="filter-btn toggle-btn"
                      aria-pressed={selector?.isTopicSelected(top)}
                      onClick={evt => model.handleTopicClick(evt, top)}>
                      <span className="name">{top.name}</span>
                      <span className="duration">{top.shortDurationLabel}</span>
                    </button>
                  </li>
                );
              })}
            </ul>

            {expandBtn}
          </div>
        </>}
    </section>
  );
});