import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { ReactEventHandler, useCallback, ReactNode } from "react";
import { CheckSVGIcon } from "../svg";
import { Button } from '../../components/input';
import { assert, ObjectLiteral } from "../../core";
import { InputState } from ".";
import { Maybe } from "graphql/jsutils/Maybe";
import './radioInput.scss';
import './input.scss';

type Props = {
  value?: string,
  className?: string | ObjectLiteral,
  label?: string,
  disabled?: boolean,
  isFocused?: boolean,
  isHovered?: boolean,
  checked?: boolean,
  readOnly?: boolean,
  message?: string,
  name?: string,
  icon?: ReactNode,
  onChange?: ReactEventHandler
}

export const RadioInput = observer(({
  readOnly,
  value,
  className,
  label,
  disabled,
  isFocused,
  isHovered,
  checked,
  message,
  name,
  icon,
  ...props
}: Props) => {

  className = classNames('radio-input input', className, {
    'focus': isFocused,
    'hover': isHovered
  });

  return (<div className={className}
    role="radio"
    aria-disabled={disabled}
    aria-checked={checked}>
    <div className="border">
      <div className="checked-icon"><CheckSVGIcon /></div>
    </div>

    {icon && <Button className="radio-btn primary" icon={icon} />}
    {label && <span className="text label">{label}</span>}

    <input type="radio"
      className={"native-radio-input"}
      name={name}
      checked={checked}
      disabled={disabled}
      readOnly={readOnly}
      role="presentation"
      {...props} />
  </div>)
})

type ControlledProps =
  React.InputHTMLAttributes<HTMLInputElement> & {
    value?: string,
    label?: string,
    model: InputState,
    showEmptyItem?: Maybe<boolean>,
    icon?: ReactNode,
    className?: string | ObjectLiteral
  };

export const RadioInputControlled = observer(({
  model,
  className,
  icon,
  ...props
}: ControlledProps) => {
  assert(model instanceof InputState,
    `The 'model' passed to CheckboxInputControlled must be an instance of InputState. Instead received ${model}.`);


  const handleChange = useCallback(evt =>
    model.handleChange(evt, evt.target.checked),
    [model]);

  return <RadioInput
    name={model.name}
    icon={icon}
    className={className}
    checked={model.value && model.value !== 'false' ? true : false}
    placeholder={model.placeholder}
    disabled={model.disabled}
    label={model.label ?? ''}
    isHovered={model.isHovered}
    isFocused={model.isFocused}
    onChange={handleChange}
    onFocus={model.handleFocus}
    onBlur={model.handleBlur}
    onPointerEnter={model.handlePointerEnter}
    onPointerLeave={model.handlePointerLeave}
    {...props} />
});