import { action, makeObservable } from 'mobx';
import { WindowState } from '../../components/overlays/windowState';
import { Message, StoreNode } from '../../store';
import { Store } from '../../store/store';

export class CancelAccountWindowState
  extends StoreNode {
  readonly nodeType = 'CancelAccountWindow';
  constructor(store: Store, props?: any) {
    super(store, props);
    makeObservable(this);
    this.window.listen(
      this.windowListener);
  }

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close('close');
        break;
    }
  }
  readonly window = new WindowState(this.store);

  @action
  open() {
    this.dispatch('Overlays', 'openWindow', { name: 'CancelAccountWindow' });
    this.emit('open');
  }

  @action
  async close(msg?: string) {
    this.dispatch('Overlays', 'closeWindow');
    if (msg) this.emit(msg);
  }
}