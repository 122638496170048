import React from 'react';
import { observer } from 'mobx-react-lite';
import { PlayerState } from '../playerState';
import { PageBackButton } from '../../layout/pageBackButton';
import { EmbedButton } from './controlButtons';
import { useStore } from '../../../store/storeHooks';
import { useRoutingService } from '../../../services/routing/routingHooks';


type Props = {
  model?: PlayerState,
}

export const PlayerLiveWaitingScreen = observer((props: Props) => {
  const { model } = props;
  const poster = model?.job?.posterURL ?? '/img/LiveThumbnailPlaceholder.png';

  const store = useStore();
  const { logo } = store.uiService;

  let coverStyle = {};

  if (!logo && poster) {
    coverStyle = {
      backgroundImage: `url('${poster}')`,
    };
  }

  const embedBtn = model && (
    <EmbedButton
      model={model} />
  );

  const routingService = useRoutingService();
  const showBackButton = routingService.getBackRoute();

  return (
    <div className="live-cover" style={coverStyle}>
      <div id="player-live-waiting-screen"
        className="player-live-status-screen">
        {showBackButton &&
          <div className="live-back-button">
            <PageBackButton />
          </div>}
        <div className="player-live-tag">Live</div>
        <span className="info-live">Live video will start soon</span>
        <div className="player-live-waiting-controls">
          {embedBtn}
        </div>
      </div>
    </div>
  );
});