import { getFlagMask, hasFlagMask } from '../../core';
import { MomentModel } from '../moment';
import { MomentNode } from './momentNode';

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
type Moment = MomentModel;

let flagCursor = 0;
let flag = (label?: string) => 1 << flagCursor++

export enum MomentEdgeFlags {
  None = 0,

  Connected = flag('Connected'),
  Contains = flag('Contains'),
  Contained = flag('Contained'),

  SameStart = flag('SameStart'),
  StartsBefore = flag('StartsBefore'),
  StartsAfter = flag('StartsAfter'),

  SameEnd = flag('SameEnd'),
  EndsAfter = flag('EndsAfter'),
  EndsBefore = flag('EndsBefore'),

  SameRegion = flag('SameRegion'),

  NextAfter = flag('NextAfter'),
  LastBefore = flag('LastBefore'),


  SameDescription = flag('SameDescription')
}

export const MomentEdgeFlagsInverseLookup: { [key in MomentEdgeFlags]: MomentEdgeFlags } = {

  [MomentEdgeFlags.Connected]: MomentEdgeFlags.Connected,
  [MomentEdgeFlags.Contains]: MomentEdgeFlags.Contained,
  [MomentEdgeFlags.Contained]: MomentEdgeFlags.Contains,
  [MomentEdgeFlags.SameRegion]: MomentEdgeFlags.SameRegion,

  [MomentEdgeFlags.SameStart]: MomentEdgeFlags.SameStart,
  [MomentEdgeFlags.StartsBefore]: MomentEdgeFlags.StartsAfter,
  [MomentEdgeFlags.StartsAfter]: MomentEdgeFlags.StartsBefore,

  [MomentEdgeFlags.SameEnd]: MomentEdgeFlags.SameEnd,
  [MomentEdgeFlags.EndsBefore]: MomentEdgeFlags.EndsAfter,
  [MomentEdgeFlags.EndsAfter]: MomentEdgeFlags.EndsAfter,

  [MomentEdgeFlags.NextAfter]: MomentEdgeFlags.LastBefore,
  [MomentEdgeFlags.LastBefore]: MomentEdgeFlags.NextAfter,

  [MomentEdgeFlags.SameDescription]: MomentEdgeFlags.SameDescription,
}




function edgeKey(src: MomentNode, tgt: MomentNode) {
  return '(' + src.key + ',' + tgt.key + ')';
}

export interface IMomentEdge {
  readonly key: string;
  readonly index: number;

  readonly source: MomentNode;
  readonly target: MomentNode;
  readonly flags: Set<MomentEdgeFlags>;
}

export class MomentEdge {

  constructor(index: number, src: MomentNode, tgt: MomentNode) {
    this.index = index;
    this.key = edgeKey(src, tgt);

    this.source = src;
    this.target = tgt;
  }

  readonly key: string;
  readonly index: number;

  readonly source: MomentNode;
  readonly target: MomentNode;

  readonly flags = new Set<MomentEdgeFlags>();
  flagMask: MomentEdgeFlags = MomentEdgeFlags.None;

  commit(): IMomentEdge {
    this.flagMask = getFlagMask(this.flags);

    Object.freeze(this);
    return this as IMomentEdge;
  }

  hasFlag(flag: MomentEdgeFlags) {
    return this.flags.has(flag);
  }
  hasFlagMask(flag: MomentEdgeFlags) {
    return hasFlagMask(this.flagMask, flag);
  }
}
