import * as React from 'react';

export const PlaySVGIcon = () =>
  <svg className="play-svg-icon svg-icon" viewBox="0 0 15 18" xmlns="http://www.w3.org/2000/svg">
    <path className="fill" d="M13.1484 10.7487C14.4412 9.97155 14.4412 8.09751 13.1484 7.32039L3.03039 1.23833C1.69737 0.437033 0 1.39715 0 2.95247V15.1166C0 16.6719 1.69737 17.632 3.03039 16.8307L13.1484 10.7487Z" />
  </svg>

export const InfoSVGIcon = () =>
  <svg className="info-svg-icon svg-icon" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <path className="fill" d="M7 0C3.13413 0 0 3.13413 0 7C0 10.8659 3.13413 14 7 14C10.8659 14 14 10.8659 14 7C14 3.13413 10.8659 0 7 0ZM6.19111 10.0215V6.81707C6.19111 6.60254 6.27633 6.39679 6.42803 6.2451C6.57973 6.0934 6.78547 6.00818 7 6.00818C7.21453 6.00818 7.42027 6.0934 7.57197 6.2451C7.72367 6.39679 7.80889 6.60254 7.80889 6.81707V10.0215C7.80889 10.236 7.72367 10.4418 7.57197 10.5935C7.42027 10.7452 7.21453 10.8304 7 10.8304C6.78547 10.8304 6.57973 10.7452 6.42803 10.5935C6.27633 10.4418 6.19111 10.236 6.19111 10.0215ZM7 5.12556C6.80771 5.12556 6.61974 5.06854 6.45986 4.96171C6.29998 4.85488 6.17537 4.70304 6.10178 4.52539C6.0282 4.34774 6.00895 4.15226 6.04646 3.96366C6.08397 3.77507 6.17657 3.60184 6.31254 3.46587C6.4485 3.3299 6.62174 3.23731 6.81033 3.19979C6.99892 3.16228 7.1944 3.18153 7.37205 3.25512C7.5497 3.3287 7.70154 3.45331 7.80837 3.6132C7.9152 3.77308 7.97222 3.96105 7.97222 4.15333C7.97222 4.41118 7.86979 4.65847 7.68747 4.8408C7.50514 5.02313 7.25785 5.12556 7 5.12556Z" />
  </svg>

export const ChevronLeftSVGIcon = () =>
  <svg className="chevron-left-svg-icon svg-icon" viewBox="0 0 14 22" xmlns="http://www.w3.org/2000/svg">
    <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M13.1149 0.996566C13.6691 1.61233 13.6192 2.56077 13.0034 3.11495L4.24227 11L13.0034 18.8851C13.6192 19.4393 13.6691 20.3877 13.1149 21.0035C12.5608 21.6192 11.6123 21.6691 10.9966 21.115L0.996553 12.115C0.680482 11.8305 0.5 11.4252 0.5 11C0.5 10.5748 0.680482 10.1695 0.996553 9.88507L10.9966 0.885072C11.6123 0.330883 12.5608 0.380801 13.1149 0.996566Z" />
  </svg>

export const SearchSVGIcon = () => <svg className="search-svg-icon  svg-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path className="fill" d="M21.3212 18.1221L17.7432 14.5441C18.4378 13.3233 18.8377 11.9132 18.8377 10.4188C18.8377 5.76743 15.0702 2 10.4188 2C5.76743 2 2 5.76743 2 10.4188C2 15.0702 5.76743 18.8377 10.4188 18.8377C11.9132 18.8377 13.3233 18.4378 14.5441 17.7432L18.1221 21.3212C18.5641 21.7632 19.1534 21.9947 19.7217 21.9947C20.2899 21.9947 20.8792 21.7843 21.3212 21.3212C22.2263 20.4373 22.2263 19.0061 21.3212 18.1221ZM3.34701 10.4188C3.34701 6.52512 6.52512 3.34701 10.4188 3.34701C14.3125 3.34701 17.4907 6.52512 17.4907 10.4188C17.4907 14.3125 14.3125 17.4907 10.4188 17.4907C6.52512 17.4907 3.34701 14.3125 3.34701 10.4188ZM20.3741 20.3741C20.0163 20.7319 19.427 20.7319 19.0692 20.3741L15.6806 16.9855C16.1647 16.6067 16.6067 16.1647 16.9855 15.6806L20.3741 19.0692C20.7319 19.427 20.7319 20.0163 20.3741 20.3741Z"/>
  <path className="fill" d="M10.4191 5.13599C7.51455 5.13599 5.13623 7.49326 5.13623 10.4188C5.13623 10.7977 5.43089 11.0923 5.80974 11.0923C6.18859 11.0923 6.48324 10.7977 6.48324 10.4188C6.48324 8.25096 8.2512 6.483 10.4191 6.483C10.7979 6.483 11.0926 6.18834 11.0926 5.80949C11.0926 5.43065 10.7979 5.13599 10.4191 5.13599Z"/>
</svg>
