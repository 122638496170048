import { Auth0OriginalError } from '.';
import { isFiniteNumber, isNonEmptyString, Maybe, Result } from '../../core';
import { Error } from '../../core/error';
import { getNowSeconds } from '../../core/time';
import { Auth0DecodedHash } from '../../vendor/auth0';
import { AuthPermit, AuthPermitData, createAuthPermit } from './authPermit';

export function createAuthPermitFromHash(data?: Maybe<Auth0DecodedHash>): Result<AuthPermit> {

  if (!data)
    return [null, new Error('AuthError', `No decoded hash data provided.`)];

  const accessToken = data.accessToken ?? null;
  const accessTokenExpiresIn = data.expiresIn ?? null;
  const {
    idToken,
    idTokenPayload
  } = data;

  if (!isNonEmptyString(idToken))
    return [null, new Error('AuthError', `Cannot create AuthPermit from the decoded hash because no ID token exists.`)];

  // normalize values for the access token
  let accessTokenExpires: number | null = null;
  if (isNonEmptyString(accessToken) && isFiniteNumber(accessTokenExpiresIn)) {
    accessTokenExpires = accessTokenExpiresIn + getNowSeconds();
  }

  // create the data object which will be fed into the AuthPermit constructor
  const permitData: AuthPermitData = {
    accessToken,
    accessTokenExpires,
    idToken,
    idTokenPayload
  }

  return createAuthPermit(permitData);
}


export type InvalidPasswordMessage = {
  title: string,
  rules: InvalidPasswordRule[]
} | null;

export type InvalidPasswordRule = {
  title: string,
  items: string[]
}

export function getRegisterInvalidPasswordErrorMessage(err: Auth0OriginalError | null): InvalidPasswordMessage {

  if (!err)
    return null;

  const error: InvalidPasswordMessage = {
    title: 'Your Password should:',
    rules: []
  };
  const rules = (err.description?.rules ?? []).filter(rule => !rule.verified);

  rules.forEach(rule => {
    const { items, format, message } = rule;

    const tmp: string[] = message.split('%d');
    let title: string = tmp.map((str, index) => (`${str}${format?.[index] ?? ''}`)).join('');

    if (rule.code === 'lengthAtLeast') {
      const titleArr = title.split(' ');
      titleArr[0] = titleArr[0].toLowerCase();
      title = `Contain ${titleArr.join(' ')}`;
    }

    const newRule: InvalidPasswordRule = {
      title,
      items: items?.map(i => i.message) ?? []
    }
    error.rules.push(newRule);
  })

  return error;
}