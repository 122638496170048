import { Industry } from '@clipr/lib';
import { DropdownItemObject } from '../../components/input/dropdownInput';
import { camelPad } from '../../core/string';

export type JobIndustry = Industry;

// eslint-disable-next-line  no-redeclare
export const JobIndustry: JobIndustry[] = [
  'AccomodationFoodServices',
  'AdministrationBusinessWasteServices',
  'AgricultureForestryFishingHunting',
  'ArtsEntertainmentRecreation',
  'Construction',
  'EducationalServices',
  'FinanceInsurance',
  'HealthcareSocialAssistance',
  'Information',
  'Manufacturing',
  'Mining',
  'OtherServices',
  'ProfessionalScientificTechnicalServices',
  'RealEstateRentalLeasing',
  'RetailTrade',
  'TransportationWarehousing',
  'Utilities',
  'WholesaleTrade'
];

export const getJobIndustryLabel = (industry: Industry): string => {
  switch (industry) {
    case 'AccomodationFoodServices':
    case 'AdministrationBusinessWasteServices':
    case 'AgricultureForestryFishingHunting':
    case 'ArtsEntertainmentRecreation':
    case 'Construction':
    case 'EducationalServices':
    case 'FinanceInsurance':
    case 'HealthcareSocialAssistance':
    case 'Information':
    case 'Manufacturing':
    case 'Mining':
    case 'OtherServices':
    case 'ProfessionalScientificTechnicalServices':
    case 'RealEstateRentalLeasing':
    case 'RetailTrade':
    case 'TransportationWarehousing':
    case 'Utilities':
    case 'WholesaleTrade':
    default:
      return camelPad(industry);
  }
}

export const getDefaultJobIndustry = (): Industry => {
  return 'OtherServices';
}

export const getJobIndustryInputLabel = (): string => {
  return 'Industry';
}

export const SpecialityItems: DropdownItemObject[] = JobIndustry
.map(item => ({ value: item, label: getJobIndustryLabel(item) }));

export const DefaultJobIndustryItem: DropdownItemObject | null = SpecialityItems.find(({ value }) => value === getDefaultJobIndustry()) || null;