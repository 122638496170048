import './media.scss';

import React, { CSSProperties, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { Thumb } from './thumb';
import {
  IngestingSVGIcon,
  PlaySVGIcon,
  TextInputErrorSVGIcon,
  TextInputWarningSVGIcon,
  ZoomPlaceholderSVGIcon,
  // CodeSVGIcon,
} from '../svg';
import { Link } from '../layout';
import Routes, {
  BackRoutes,
  RouteParams,
  UserDashboardView,
} from '../../routes';
import { Maybe } from '../../core';
import { DotSpinner } from '../loader';
import classNames from 'classnames';
import { Button, CheckboxInput } from '../input';
import { LibraryCatalogItem, LibraryCatalogSource } from '../../entities';
import { LocationDescriptor } from 'history';
import { Tooltip } from '../overlays';
import { useParams } from 'react-router-dom';
import { UploadPageParams } from '../../pages';

type Props = {
  source: LibraryCatalogSource;
  model: LibraryCatalogItem;
  view: string;
  aspectRatio?: Maybe<number>;
};

export const LibraryCard = observer(
  ({ model, source, view, aspectRatio = 9 / 16 }: Props) => {
    const previewStyle: CSSProperties = {
      ['--aspect-ratio' as any]: aspectRatio,
    };

    const { isWidgetMode } = model.store.widget;
    // Define next / previous routes
    const params = useParams<UploadPageParams>();
    const { search } = window.location;
    const query = new URLSearchParams(search);
    const teamId = params?.teamId || query.get('teamId') || undefined;
    const routeParams: RouteParams = {
      id: model.job?.id,
      view: view as UserDashboardView,
      teamId,
    };
    let linkRoute: Maybe<LocationDescriptor> = null;
    
    if (!isWidgetMode && model.isIngested && model.job?.isPublished) {
      linkRoute = BackRoutes[Routes.userVideoLanding()].route(routeParams);
    }
    const linkTarget = isWidgetMode ? '_blank' : undefined;

    const { hasMultipleSelection, fileSelectionLookup } = source;
    const isSelected = fileSelectionLookup.has(model.id);

    let actionButton: Maybe<ReactNode> = null;

    if (!model.status) {
      actionButton = (
        <Button
          label="Ingest"
          className="primary btn-outline small"
          onClick={() => model.startIngest(teamId)}
          disabled={hasMultipleSelection}
        />
      );
    }
    if (model.isIngesting) {
      actionButton = (
        <Button
          label="Ingesting"
          className="primary btn-outline small"
          disabled
        />
      );
    }
    if (model.isFailed) {
      actionButton = (
        <Button
          label="Retry"
          className="green small"
          onClick={() => model.retryIngest()}
        />
      );
    }
    if (model.isIngested && model.job?.isPublished) {
      actionButton = (
        <Button
          label="Added to CLIPr"
          className="green btn-outline small"
          onClick={() => (isWidgetMode ? null : model.store.goTo(linkRoute!))}
        />
      );
      // actionButton = (
      //   <>
      //     <Tooltip content="Preview">
      //       <Button
      //         icon={<PlaySVGIcon />}
      //         className="preview-button"
      //         route={linkRoute || '#'}
      //         target="_blank"
      //       />
      //     </Tooltip>
      //     <Tooltip content="Copy Embed Code">
      //       <Button
      //         className="embed-button"
      //         icon={<CodeSVGIcon />}
      //         onClick={() => model.copyIFrameEmbedCode()}
      //       />
      //     </Tooltip>
      //   </>
      // );
    }
    if (!model.isAvailableForIngest) {
      actionButton = (
        <Tooltip
          className="not-supported-tooltip"
          arrowClassName="not-supported-tooltip-arrow"
          content={'Format currently not supported'}
        >
          <Button
            label="Not Supported"
            className="red btn-outline small"
            onClick={() => null}
          />
        </Tooltip>
      );
    }
    if (!model.status && !model.canDownload) {
      actionButton = (
        <Tooltip
          className="popup-tip"
          content={
            "We're sorry, but this video cannot be ingested because the owner has not permitted global sharing on Google Drive. Please ask them to change permissions on the video, or download a copy to your computer and upload it directly."
          }>
          <Button
            label="Ingest"
            className="primary btn-outline small"
            onClick={() => null}
            disabled={true}
          />
        </Tooltip>
      );
    }

    const cx = classNames('external-file-card', {
      'status-not-ingested': !model.status,
      'status-done': model.job?.isPublished && model.isIngested,
      'status-is-ingesting': model.isIngesting,
      'status-is-failed': model.isFailed,
      'status-is-unpublish': model.isIngested && !model.job?.isPublished,
      selected: isSelected,
    });

    const thumbnail = model.thumbnail || model.jobModel?.posterURL;

    let previewElem: Maybe<ReactNode>;

    if (model.isIngested && !model.job?.isPublished) {
      previewElem = (
        <div className="card-status progress dock">
          {thumbnail || model.libraryType !== 'Zoom' ? (
            <Thumb source={thumbnail} className="card-thumb dock" />
          ) : (
            <div className="thumb aspect-ratio-box" style={previewStyle}>
              <div className="thumb-img">
                <ZoomPlaceholderSVGIcon />
              </div>
            </div>
          )}
          <CardOverlay model={model} />
        </div>
      );
    } else {
      previewElem = (
        <div
          className={`card-status progress dock ${
            model.isIngested && model.job?.isPublished && 'status-done'
          }`}
        >
          {thumbnail || model.libraryType !== 'Zoom' ? (
            <Thumb source={thumbnail} className="card-thumb dock" />
          ) : (
            <div className="thumb aspect-ratio-box" style={previewStyle}>
              <div className="thumb-img">
                <ZoomPlaceholderSVGIcon />
              </div>
            </div>
          )}
          {model.isIngested && (
            <div className="play-icon">
              <PlaySVGIcon />
            </div>
          )}
          {(!model.status || model.isIngested) &&
            model.isAvailableForIngest && (
              <div className="card-duration">
                {model.durationToTimeCode('card')}
              </div>
            )}
          {model.isNotProcessed && (
            <CheckboxInput
              name="select"
              className="select-box"
              checked={isSelected}
              onChange={() => source.updateFileSelection(model)}
            />
          )}
          {(model.isIngesting ||
            model.isFailed ||
            (model.isIngested && !model.job?.isPublished)) && (
            <CardOverlay model={model} />
          )}
        </div>
      );
    }

    return (
      <>
        <div className={cx}>
          <Link
            to={linkRoute as any}
            target={linkTarget}
            className={`card-preview aspect-ratio-box ${model.libraryType.toLocaleLowerCase()}-card-preview`}
            style={previewStyle}
          >
            {previewElem}
          </Link>

          <Link
            to={linkRoute as any}
            target={linkTarget}
            className="card-title"
            title={model.name}
          >
            {model.name}
          </Link>

          <Link
            to={linkRoute as any}
            target={linkTarget}
            className="card-summary"
          >
            {model.createdAtRelativeLabel}
          </Link>

          <div className="card-menu">{actionButton}</div>
        </div>
      </>
    );
  }
);

const CardOverlay = observer(
  ({ model }: { model: LibraryCatalogItem }) => {
    let content: Maybe<ReactNode>;

    const cx = classNames('card-overlay', {
      'not-ingested': !model.status,
      done: model.job?.isPublished && model.isIngested,
      ingesting: model.isIngesting,
      failed: model.isFailed,
      unpublish: model.isIngested && !model.job?.isPublished,
    });

    if (model.isIngesting) {
      content = (
        <>
          <IngestingSVGIcon />
          <p>
            <b>CLIPr</b> is <b>ingesting</b> your video.
            <br />
            This will take about half the length <br /> of the video.
          </p>
          <DotSpinner className="small" />
        </>
      );
    }
    if (model.isFailed) {
      content = (
        <>
          <TextInputErrorSVGIcon />
          <p>Failed to ingest video</p>
        </>
      );
    }
    if (!model.job?.isPublished) {
      content = (
        <>
          <TextInputWarningSVGIcon />
          <p>Video is no longer available</p>
          <p className="small">
            If you think this is an error, please{' '}
            <a href={'mailto:' + process.env.REACT_APP_EMAIL_QA}>
              Contact Us &#8594;
            </a>
          </p>
        </>
      );
    }

    return <div className={cx}>{content}</div>;
  }
);

