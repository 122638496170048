
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BindingProps, Message, refProxy, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { JobModel, MomentModel, MomentSelector, SpeakerModel, SubTopic, SyncStatus, Team, Topic } from '../../entities';
import { PlayerState } from '../player/playerState';
import { PlayerFramesetState } from '../playerFrameset/playerFramesetState';
import { TeamMemberRole } from '../../../../../libs/lib/dist';
import { PlayerSpeakersState } from '../playerSpeakers/playerSpeakersState';
import { PlayerSpeakerIdController } from '../playerSpeakers/playerSpeakerIdController';
import { SpeakerIdFormState } from '../speakerId/speakerIdFormState';
import clamp from 'lodash/clamp';

type Props = BindingProps<{
  jobId: string,
  player: PlayerState,
  frameset: PlayerFramesetState,
  playerTutorialHighlightedComponents?: string[];
  teamId?: string | null;
  speakerIdController?: PlayerSpeakerIdController;
}>

export class PlayerIndexState
  extends StoreNode {

  @observable expandedClipId: string | null = null;
  @observable isSpeakerListExpanded: boolean = false;
  @observable isActiveTopicIntoView: boolean = false;

  @observable isSpeakerIdCompleted: boolean = false;
  @observable showSpeakerIdNotification: boolean = false;

  @observable maxSpeakerListHeight: number = 0;
  @observable transitionDuration: number = 0;

  readonly scrollElementProxy = refProxy(this);
  readonly sectionProxy = refProxy(this);

  readonly playerSpeakers = new PlayerSpeakersState(this.store, {
    jobId: () => this.jobId,
    player: () => this.player,
    teamId: () => this.teamId,
    speakerIdController: () => this.speakerIdController
  });

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.autoListen('player', this.playerListener);

    this.playerSpeakers.listen(this.playerSpeakerListener);
  }

  readonly speakerIdForm: SpeakerIdFormState = new SpeakerIdFormState(this.store, {
    onEdit: () => this.speakerIdController.enterSpeakerIdMode('edit'),
    shouldFetchSpeakers: () => false
  });

  private playerSpeakerListener = async (msg: Message<PlayerSpeakersState>) => {
    const { type } = msg;

    switch (type) {
      case 'completed':
        this.handleSpeakerIdCompleted();
        this.isActiveTopicIntoView = this.isMomentIntoView(this.highlightedTopic) || false;
        this.scrollToCurrent();
        this.displayNotification();
        this.updateSpeakerIdForm();
        break;
    }
  }

  @computed get momentsSyncStatus(): SyncStatus | null {
    return this.job?.momentsSyncStatus || null;
  }

  @computed get isJumpToCurrentDisabled(): boolean {
    return (
      !this.highlightedTopic ||
      this.isActiveTopicIntoView ||
      this.job?.topicMoments.length === 0)
  }

  @action
  private scrollToMoment = (moment: Topic | SubTopic | null) => {
    if (!moment)
      return;

    const topOffsetElement = document.querySelector('#user-player-index-section-header');
    const scrollElement = this.scrollElementProxy.current;//document.querySelector(`#user-player-clip-scroll`);
    const domMoment = this.domGetTopicMoment(moment.id);

    if (!domMoment || !scrollElement)
      return;

    const topOffsetElementHeight = 128 + (topOffsetElement?.clientHeight || 0);   // header overlapping the scroll box
    const scrollElementTop = scrollElement.getBoundingClientRect().top;   // top value of the scroll element relative to the viewport
    const scrollElementScrollTop = scrollElement.scrollTop;               // how much is the element scrolled (always positive or 0)
    const domMomentTop = domMoment.getBoundingClientRect().top;           // top value of the scroll item element relative to the viewport

    // to determine the top value:
    const topValue =
      (domMomentTop - scrollElementTop)             // determine the position of the scroll item relative to the top of the scroll element
      + scrollElementScrollTop                      // add it to the already scrolled value
      - topOffsetElementHeight;                     // subtract the header offset

    scrollElement.scrollTo({
      top: topValue,
      behavior: 'smooth'
    })
  }

  isMomentIntoView(moment: Topic | SubTopic | null) {
    if (!moment)
      return;

    const topOffsetElement = document.querySelector('#user-player-index-section-header');
    const scrollElement = this.scrollElementProxy.current;
    const domMoment = this.domGetTopicMoment(moment.id);

    if (!domMoment || !scrollElement)
      return;

    const topOffsetElementHeight = topOffsetElement?.clientHeight || 0;         // header overlapping the scroll box
    const scrollElementTop = scrollElement.getBoundingClientRect().top;         // top value of the scroll element relative to the viewport
    const scrollElementBottom = scrollElement.getBoundingClientRect().bottom;   // bottom value of the scroll element relative to the viewport
    const domMomentTop = domMoment.getBoundingClientRect().top;                 // top value of the scroll item relative to the viewport
    const domMomentBottom = domMoment.getBoundingClientRect().bottom;           // bottom value of the scroll item relative to the viewport

    return (
      domMomentTop >= (scrollElementTop + topOffsetElementHeight) &&            // top of the item should be lower than the top of the scroll + offset
      (domMomentBottom < scrollElementBottom)                                   // bottom of the item should be higher than top of the scroll
    );
  }

  private domGetTopicMoment(id: string) {
    return document.querySelector(`[data-moment="true"][data-moment-id="${id}"]`);
  }

  private playerListener = (msg: Message<PlayerState>) => {
    const { type, payload } = msg;
    switch (type) {
      case 'activeTopicChange':
        this.scrollToMoment(payload.moment);
        break;
      case 'lastActiveSubtopicChange':
        this.scrollToMoment(payload.moment);
        break;
      case 'activeSpeakerChange':
        this.handleActiveSpeakerChange();
        break;
      default: break;
    }
  }

  @action
  scrollToCurrentMoment() {
    if (!this.highlightedTopic)
      return;

    this.scrollToMoment(this.highlightedTopic);
  }

  @action
  scrollToCurrent() {
    this.scrollToCurrentMoment();
  }

  @computed get jobId(): string | null {
    return this.resolvedProps.jobId ?? null;
  }

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed get teamId(): string | null {
    return this.resolvedProps.teamId ?? null;
  }

  @computed get team(): Team | null {
    return this.store.teamManager.getTeam(this.teamId);
  }

  @computed get userTeamRole(): TeamMemberRole | null {
    const { user } = this.store;
    if (user) {
      return this.team?.getMember(user.id)?.role ?? null;
    }

    return null;
  }

  @computed
  get player(): PlayerState {
    return this.resolvedProps.player;
  }

  @computed
  get speakerIdController(): PlayerSpeakerIdController {
    return this.resolvedProps.speakerIdController;
  }

  @computed
  get frameset(): PlayerFramesetState | null {
    return this.resolvedProps.frameset ?? null;
  }

  @computed
  get items(): Array<MomentModel> {
    return this.player?.momentView?.topicMoments || [];
  }

  @computed
  get momentSelector(): MomentSelector | null {
    return this.player?.selector;
  }

  @computed get activeTopic(): Topic | null {
    return this.player.momentView.activeTopic;
  }

  @computed get lastActiveSubtopic(): SubTopic | null {
    return this.player.momentView.lastActiveSubtopic;
  }

  @computed get activeSubtopics(): SubTopic[] | null {
    return this.player.momentView.activeSubTopics;
  }

  @computed get highlightedTopic(): SubTopic | Topic | null {
    // Always check for an active subtopic, if it's not available, then scroll into view the active topic
    return this.lastActiveSubtopic || this.activeTopic;
  }

  @computed get activeSpeaker(): SpeakerModel | null {
    return this.player.momentView?.activeTranscriptSpeaker ?? null;
  }

  @computed get activeSpeakerList(): SpeakerModel[] {
    return this.player.momentView?.displayActiveTranscriptSpeakerPool || [];
  }

  @computed get showOverlay(): boolean {
    const { job } = this;
    return (job?.isEnrichmentProcessing || job?.isEnrichmentPending) ?? false;
  }

  @computed get showSpeakerIdForm(): boolean {
    return !!this.activeSpeaker?.isPublic &&
      !!this.speakerIdController?.showEditSpeakerId;
  }

  @computed
  get isSpeakerIdMode(): boolean {
    return this.speakerIdController?.isSpeakerIdMode ?? false;
  }

  @action
  async mounted() {
    this.isActiveTopicIntoView = this.isMomentIntoView(this.highlightedTopic) || false;
    this.scrollToCurrent();
    this.speakerIdForm.openEditMode();
    this.isSpeakerListExpanded = false;
  }

  @action
  unmounted() {

  }

  @action
  expandIndexCard = (id: string) => {
    if (this.expandedClipId === id)
      this.expandedClipId = null;
    else {
      this.expandedClipId = id;
      this.isSpeakerListExpanded = false;
    }
  }

  @action
  toogleSpeakerList = () => {
    if (!this.isSpeakerListExpanded)
      this.setLayoutVariables();
    this.isSpeakerListExpanded = !this.isSpeakerListExpanded;
  }

  @action
  handleScroll = () => {
    this.isActiveTopicIntoView = this.isMomentIntoView(this.highlightedTopic) || false;
  }

  @action
  reset() {
    this.expandedClipId = null;
    this.isSpeakerListExpanded = false;
    this.isActiveTopicIntoView = false;
    this.showSpeakerIdNotification = false;
    this.isSpeakerIdCompleted = false;
    this.exitSpeakerIdMode();
  }

  @action
  enterSpeakerIdMode() {
    this.playerSpeakers.init();
  }

  @action
  enterSpeakerIdEditMode() {
    const editSpeaker = this.speakerIdForm.currentSpeaker;
    if (!editSpeaker)
      return;

    this.playerSpeakers.initEdit([editSpeaker]);
  }

  @action
  exitSpeakerIdMode() {
    this.playerSpeakers.reset();
  }

  @action
  handleSpeakerIdCompleted() {
    if (!this.playerSpeakers.speakerIdForm.isEditMode)
      this.isSpeakerIdCompleted = true;
    this.speakerIdController.exitSpeakerIdMode();
  }

  @action
  handleActiveSpeakerChange() {
    if (this.isSpeakerIdMode)
      return;

    this.updateSpeakerIdForm();
  }

  @action
  updateSpeakerIdForm() {
    this.speakerIdForm.openEditMode();
    if (this.activeSpeaker)
      this.speakerIdForm.init([this.activeSpeaker]);
  }

  @action
  displayNotification() {
    this.showSpeakerIdNotification = true;
    setTimeout(() => runInAction(() => this.showSpeakerIdNotification = false), 3000);
  }

  @action
  handleSpeakerClick = (id: string) => {
    const { player, job } = this;
    const transcript = player.momentView.getNextTranscriptBySpeakerId(id);

    // needs refactoring
    const openSpeakerIdWindow = !this.speakerIdController.isPublic &&
      !!job?.isOwnerAuthenticated &&
      !!transcript?.isSpeakerPrivate &&
      !this.isSpeakerIdCompleted &&
      !!this.teamId;

    if (openSpeakerIdWindow) {
      this.speakerIdController?.openSpeakerIdConfirmationWindow();
    } else if (transcript)
      player.invoke('jumpToMoment', { moment: transcript });
  }

  computeMaxSpeakerListHeight = () => {
    const scrollElement = this.scrollElementProxy.current;
    return (scrollElement?.clientHeight ?? 0) - 32;
  }

  @action
  setLayoutVariables() {
    this.maxSpeakerListHeight = this.computeMaxSpeakerListHeight();
    this.transitionDuration = clamp((this.maxSpeakerListHeight / 1000), 0.3, 0.6);
  }
}