import './bookmarks.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { BookmarkBrowserHeader } from './bookmarkBrowserHeader';
import { BookmarkListCardCatalog } from './bookmarkListCardCatalog';
import { BookmarkBrowserState } from './bookmarkBrowserState';
import { SidebarBookmarkSVGIcon, SquarePlusSVGIcon } from '../svg';
import { Button } from '../input';

type Props = {
  model: BookmarkBrowserState;
};

export const BookmarkBrowser = observer((props: Props) => {
  const model = props.model;

  const data = model.store.bookmarkLists;

  return data.length > 0 ? (
    <>
      <BookmarkBrowserHeader className="page-header" model={model} />
      <BookmarkListCardCatalog model={data} />
    </>
  ) : (
    <div className="catalog empty-catalog">
      <div className="icon">
        <SidebarBookmarkSVGIcon />
      </div>
      <h1 className="headline-1">
        Looks like you haven't added any video bookmarks.
      </h1>
      <p>Start adding bookmarks by creating your 1st list.</p>

      <Button
        className="secondary large"
        icon={<SquarePlusSVGIcon />}
        label="New List"
        onClick={(evt: any) => model.openCreateListWindow()}
      />
    </div>
  );
});
