import './teams.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';

import { Team } from '../../entities/team';
import { TeamAvatar } from '.';
import { Maybe } from '../../core';

type Props = {
  model?: Maybe<Team>
}

export const TeamHeading = observer(({
  model
}: Props) => {
  
  if (!model)
    return null;

  return (
    <div className="team-heading">
      <TeamAvatar
        model={model} />
        
      <h1 className="team-name">{model.name}</h1>
    </div>
  );
});