import './editorWindow.scss';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { EditorWindowState } from './editorWindowState';
import { TextEditor } from '../textEditor/textEditor';

type Props = {
  model: EditorWindowState;
  visible?: boolean;
};

export const EditorWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      name="editorWindow"
      model={model.window}
      visible={props.visible}
      isLoading={model.isLoading}
      className="editor-window">
      
      <WindowHeader model={model.window} />

      <div className="window-content">
        <TextEditor 
          model={model.editor}
          onSave={model.onSave}
          fileName={model.fileName}
          data={model.data} />
      </div>
    </Window>
  );
});
