import { action, makeObservable, observable, computed, runInAction } from 'mobx';
import { Message, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { WindowState } from '../overlays';
import { notifyLoading, notifyError, notifySuccess } from '../../services/notifications';
import { JobModel } from '../../entities/job';
import { DeleteMomentInput } from '@clipr/lib';

export type RemoveAccessOpenParams = {
  jobId: string
  momentIds: string[],
  onSubmitCallback?: () => void
}

export class DeleteMomentsPopupState
  extends StoreNode {

  readonly nodeType = 'DeleteMomentsPopup';

  constructor(store: Store, props?: any) {
    super(store, props);
    makeObservable(this);
    this.window.listen(
      this.windowListener);
  }
  @observable isLoading: boolean = false;

  @observable jobId: string | null = null;
  @observable targetIds: string[] | null = null;
  @observable onSubmitCallback: (() => void) | null = null;

  @computed
  get job(): JobModel | null {
    return this.store.jobManager.maybeGetJob(this.jobId);
  }

  @computed
  get hasMultipleTargets() {
    return this.targetIds && this.targetIds.length > 1;
  }

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close('close');
        break;
    }
  }
  readonly window = new WindowState(this.store);

  @action
  async submit() {
    if (!this.jobId) return;

    await this.handleSubmitDeleteMoments();

    runInAction(() => {
      this.close('momentsDeleted');
      if (this.onSubmitCallback)
        this.onSubmitCallback();
    });

    runInAction(() => this.isLoading = false)
  }

  @action
  async handleSubmitDeleteMoments() {
    const { job } = this;
    if (!job || !this.targetIds)
      return;

    this.isLoading = true;
    notifyLoading(this, "Deleting clips");

    let hasConflicts = false;
    const input: DeleteMomentInput[] = this.targetIds.map(momentId => {
      let moment = job.getMoment(momentId);
      if (moment?.hasChildSubtopics)
        hasConflicts = true;

      return {
        jobId: job.id,
        momentId: momentId
      }
    }).filter(el => el) as DeleteMomentInput[];

    if (job.isDone && hasConflicts) {
      this.handleSubmitError('Could not delete the topics because of underlying subtopics.')
      return;
    }

    const [, err] = await job.apiDeleteMoments(input);
    if (err) {
      this.handleSubmitError('Could not delete the clips because of an error.')
      return;
    }

    notifySuccess(this, 'Clips were deleted.');
  }


  @action
  private handleSubmitError(msg: string) {
    notifyError(this, msg);
    this.isLoading = false;
  }

  @action
  open(params: RemoveAccessOpenParams) {
    this.dispatch('Overlays', 'openWindow', { name: 'DeleteMomentsPopup' });

    this.jobId = params.jobId;
    this.targetIds = params.momentIds || null;
    this.onSubmitCallback = params.onSubmitCallback || null;
  }

  @action
  close(msg?: string) {
    this.dispatch('Overlays', 'closeWindow');

    this.jobId = null;
    this.targetIds = null;

    if (msg) this.emit(msg);
  }
}