
export function getFlagMask<T extends number>(flags: Iterable<T> | T): number {
  if (typeof flags === 'number')
    return flags;

  return [...flags].reduce((acc, flag) => acc | flag, 0);
}

export function hasFlagMask<T extends number>(value: T, test: T,): boolean {
  return (value & test) === test;
}

export function extractFlags<T extends number>(value: T): number[] {
  let i = 1;
  let shift = 0;
  let flags: number[] = [];

  while (i <= value) {

    if (hasFlagMask(value, i))
      flags.push(i);

    shift++
    i = i << shift;
  }

  return flags;
} 