import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { input, inputGroup, submitButtonDisabled } from '../../../components/input';
import { assertNotNull, Maybe } from '../../../core';
import { AuthError, AuthErrorType } from '../../../services/auth';
import { AuthFlowResponse } from '../../../services/auth/authFlowSchema';
import { StoreNode } from '../../../store';
import { Store } from '../../../store/store';

const UsernameSubmitErrors: AuthErrorType[] = [
  'InvalidUsername',
  'UsernameExists'
]

export class OnboardFormState
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  @observable error: AuthError | null = null;
  @observable response: AuthFlowResponse | null = null;

  @computed get isSubmitting(): boolean {
    return this.formGroup.isSubmitting;
  }

  @computed get showLoadMask(): boolean {
    return this.isSubmitting || !!this.response;
  }

  readonly formGroup = inputGroup(this, {
    name: 'onboardForm',
    inputs: () => [
      this.username,
      this.firstName,
      this.lastName,
      this.industry,
      this.companySize,
      this.jobTitle,
      this.submitButton
    ],
    error: (form) => {
      if (form.hasErrorInputs)
        return true;

      switch (this.error?.type) {
        case 'UsernameExists':
          return null; // pass the error to the inputs

        case 'NetworkError':
          return 'Signing up failed because of a network error';
        case 'ProviderError':
          return this.error?.message || 'Signing up failed because of a provider error';

        default:
          if (this.error)
            return 'Signing up failed because of an unknown error';
      }
    }
  });

  readonly username = input(this, {
    name: 'username',
    isRequired: true,
    error: (input, fallback) => {
      if (!input.value)
        return 'Required field';
      if (this.usernameSubmitError)
        return this.usernameSubmitError;
      return fallback;
    },
    showStatus: (input) => input.isSubmitRejected || !!this.usernameSubmitError,
    showStatusMessage: (input) => input.showStatus,
    onChange: () => this.clearError()
  });

  readonly firstName = input(this, {
    name: 'firstName',
    isRequired: true,
    error: (input, fallback) => {
      if (!input.value)
        return 'Required field';
      return fallback;
    },
    showStatus: (input) => input.isSubmitRejected,
    showStatusMessage: (input) => input.showStatus,
    onChange: () => this.clearError()
  });

  readonly lastName = input(this, {
    name: 'lastName',
    showStatus: (input) => input.isSubmitRejected,
    showStatusMessage: (input) => input.showStatus,
  });

  @computed get usernameSubmitError(): Maybe<string> {
    if (!this.username.isChangedSinceLastSubmit &&
      UsernameSubmitErrors.includes(this.error?.type!)) {
      return this.error?.message || null;
    }
    return null;
  }

  readonly industry = input(this, {
    name: 'industry',
    selectorItems: [
      'Corporate',
      'Education',
      'Events',
      'Other'
    ]
  });

  readonly companySize = input(this, {
    name: 'companySize',
    selectorItems: [
      '1 - 50',
      '50 - 100',
      '100 - 200',
      '200 - 1000',
      '1000+'
    ]
  });

  readonly jobTitle = input(this, {
    name: 'jobTitle'
  });

  readonly submitButton = input(this, {
    name: 'submit',
    disabled: submitButtonDisabled(this.formGroup)
  });

  @action
  handleSubmit = async (evt: React.FormEvent) => {
    evt.preventDefault();

    this.error = null;
    this.formGroup.handleSubmit();

    if (this.formGroup.error) {
      this.formGroup.handleSubmitReject();
      return false;
    }

    const username = this.username.value;
    const firstName = this.firstName.value?.trim();
    const lastName = this.lastName.value?.trim();
    const name = [firstName, lastName].join(' ');

    assertNotNull(username);

    const { auth } = this.store;
    const [flowRes, flowErr] = await auth.runOnboardFlow({
      username,
      firstName,
      lastName,
      name,
      industry: this.industry.value,
      companySize: this.companySize.value,
      jobTitle: this.jobTitle.value
    });

    if (flowErr) {
      runInAction(() =>
        this.error = flowErr);

      this.formGroup.handleSubmitReject();
      return false;
    }

    this.formGroup.handleSubmitResolve();
    runInAction(() =>
      this.response = flowRes!);
    return true;
  }

  // #region Reset
  @action
  clearError() {
    this.error = null;
  }

  @action
  setError(err: AuthError) {
    this.error = err;
  }

  @action
  reset() {
    this.formGroup.reset();
    this.error = null;
    this.response = null;
  }
  // #endregion
}