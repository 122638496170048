import { makeObservable, computed, observable, action } from 'mobx';

import { Store } from '../../store/store';
import { StoreNode } from '../../store';

import { CommentState } from './commentState';
import { Comment } from '../../entities/comment';
import { PlayerCommentsState } from './playerCommentsState';

export type CommentThreadProps = {
  playerComments?: PlayerCommentsState,
  threadComments: Comment[]
};

export class CommentThreadState
  extends StoreNode {

  constructor(store: Store, props: CommentThreadProps) {
    super(store, props);
    makeObservable(this);
  }

  @computed get playerComments(): PlayerCommentsState {
    return this.resolvedProps.playerComments ?? null;
  }

  @computed get threadComments(): Comment[] {
    return this.resolvedProps.threadComments ?? [];
  }

  @computed get comments(): CommentState[] {
    return this.threadComments.map(comment => (
      new CommentState(this.store, { comment, thread: this })
    ));
  }

  @computed get comment(): CommentState {
    return this.comments.filter(comment => !comment.isReply)[0];
  }

  @computed get commentModel(): Comment {
    return this.comment?.comment;
  }

  @computed get replies(): CommentState[] {
    return this.comments.filter(comment => comment.isReply);
  }

  @observable isActive: boolean = false;

  @observable hasReplyInput: boolean = false;

  @observable showPrevReplies: boolean = false;

  @action
  toggleShowPrevReplies() {
    this.showPrevReplies = !this.showPrevReplies;
  }

  @action
  setIsActive(isActive: boolean) {
    if (this.hasReplyInput) return;
    this.isActive = isActive;
  }

  @action
  setHasReplyInput(hasReplyInput: boolean) {
    this.hasReplyInput = hasReplyInput;
  }

  @action
  handlePointerEnter(evt: PointerEvent) {
    this.setIsActive(true);
  }

  @action
  handlePointerLeave(evt: PointerEvent) {
    this.setIsActive(false);
  }
};