import { DateTime } from 'luxon';
import { Cookie } from '../core';

export class MediaSignaturePolicy {
  readonly resource!: string;
  readonly expiresAt!: DateTime;
  readonly expiresIn!: number;

  constructor(props: any) {
    Object.assign(this, props);
    this.expiresAt = DateTime.fromISO(props?.expiresAt);
    Object.freeze(this);
  }
}

export class HLSMediaSource {
  readonly cookieCredentials!: Cookie[];
  readonly url!: string;
  readonly signedUrl!: string;
  readonly policy!: MediaSignaturePolicy;

  constructor(props: any) {
    Object.assign(this, props);
    this.policy = new MediaSignaturePolicy(props?.policy);
    Object.freeze(this);
  }
}

/**
 * Immutable object representing information about a media object.
 */
export class MediaInfo {
  readonly basic: string;
  readonly hls: HLSMediaSource;
  readonly poster!: string;
  readonly subtitle!: string;
  readonly isPublic = false;
  
  readonly liveStreamUrl: string | null = null;

  constructor(props: any) {
    Object.assign(this, props);
    this.basic = props?.basic;
    this.hls = new HLSMediaSource(props?.hls);
    Object.freeze(this);
  }
}