type ScaleBandRangeByMaxBandWidthProps = {
  leftPad?: number;
  rightPad?: number;
  paddingInner?: number;
  chartWidth?: number;
  domain: string[];
  maxBandWidth: number;
};

export const getScaleBandRangeByMaxBandWidth = ({
  leftPad = 0,
  rightPad = 0,
  paddingInner = 0,
  chartWidth = 0,
  domain = [],
  maxBandWidth,
}: ScaleBandRangeByMaxBandWidthProps) => {
  const totalSteps = domain.length - 1 + (1 - paddingInner);

  const innerChart = chartWidth - rightPad;
  const stepWidth = innerChart / totalSteps;

  let bandWidth = stepWidth - paddingInner * stepWidth;

  if (maxBandWidth && bandWidth > maxBandWidth) {
    bandWidth = maxBandWidth;

    const newStepWidth = bandWidth / (1 - paddingInner);
    const newInnerChart = totalSteps * newStepWidth;

    return {
      range: [
        ((domain.length === 1 && leftPad) || 0) + newInnerChart,
        rightPad,
      ],
    };
  }

  return {};
};