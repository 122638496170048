import { useRef } from 'react';
import { Kernel } from '../kernel/kernel';
import { useKernel } from '../kernel/kernelHooks';

export type UseModelCreateCallback<T> = (kernel: Kernel) => T;

export function useModel<T>(createCallback: UseModelCreateCallback<T>): T {

  const kernel = useKernel();

  const modelRef = useRef<T | null>(null);
  if (modelRef.current === null) {
    modelRef.current = createCallback(kernel);
  }

  return modelRef.current;
}