import { action, makeObservable, observable, runInAction } from 'mobx';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { TeamModel } from '../jobs';

export class DeleteTeamWindowState
  extends StoreNode {
  readonly nodeType = 'DeleteTeamWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);
  @observable isLoading: boolean = false;
  @observable teams: TeamModel[] | null = [];
  @observable teamId: string | null = null;

  @action
  async submit() {
    this.isLoading = true;

    const [, err] = await this.store.apiService.deleteTeam({ id: this.teamId || '' });

    if (err) {
      notifyError(this, 'Could not delete the library.');
      return this.handleSubmitFinally();
    }

    this.emit('team:deleted');
    notifySuccess(this, 'Library deleted.');

    this.handleSubmitFinally();
  }

  handleSubmitFinally() {
    runInAction(() => {
      this.isLoading = false;
      this.close();
    })
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open({ teamId }: { teamId: string }) {
    this.dispatch('Overlays', 'openWindow', { name: 'DeleteTeamWindow' });

    this.teamId = teamId;
  }

  @action
  close() {
    this.teamId = null;

    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}