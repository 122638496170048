import './xySeriesChart.scss';

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import {
  Axis,
  Grid,
  XYChart,
  Tooltip,
  DataProvider,
  BarSeries,
  BarGroup,
  BarStack,
  AreaSeries,
  AreaStack,
  LineSeries,
  AxisScale,
} from '@visx/xychart';
import { RenderTooltipParams } from '@visx/xychart/lib/components/Tooltip';
import {
  curveLinear,
  curveStep,
  curveCardinal,
  curveMonotoneX,
} from '@visx/curve';
import { buildChartTheme } from '@visx/xychart';
import { ThemeConfig } from '@visx/xychart/lib/theme/buildChartTheme';
import { lightTheme, XYChartTheme } from '@visx/xychart';
import { AxisProps } from '@visx/xychart/lib/components/axis/Axis';
import { ObjectLiteral } from '../../../core';
import { ChartLegend, ChartLegendProps } from '../utils/chartLegend';
import { getScaleBandRangeByMaxBandWidth } from '../utils/scaleBandRangeByMaxBandWidth';
import { XYSeriesChartState } from './xySeriesChartState';
import { DefaultTooltip } from '../utils/defaultTooltip';
import {
  Accessors,
  LineSeriesProps,
  SimpleScaleConfig,
} from './xySeriesChartSchema';

export type LineChartProps = {
  id?: string;
  className?: string | ObjectLiteral;
  themeConfig?: ThemeConfig;
  valueScaleConfig?: SimpleScaleConfig;
  dateScaleConfig?: SimpleScaleConfig;
  valueAxisLabel?: string;
  dateAxisLabel?: string;
  series?: LineSeriesProps[];
  height?: number;
  width?: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  accessors?: Accessors;
  colorAccessor?: any;
  showGridRows?: Boolean;
  showGridColumns?: Boolean;
  numTicks?: number;
  showLegend?: boolean;
  showTooltip?: boolean;
  sharedTooltip?: boolean;
  showHorizontalCrosshair?: boolean;
  showVerticalCrosshair?: boolean;
  snapTooltipToDatumX?: boolean;
  snapTooltipToDatumY?: boolean;
  renderTooltip?: (params: RenderTooltipParams<object>) => React.ReactNode;
  renderBarGroup?: boolean;
  renderBarSeries?: boolean;
  renderBarStack?: boolean;
  renderAreaSeries?: boolean;
  renderAreaStack?: boolean;
  renderLineSeries?: boolean;
  renderHorizontally?: boolean;
  xAxisOrientation?: 'top' | 'bottom';
  yAxisOrientation?: 'left' | 'right';
  stackOffset?: 'wiggle' | 'expand' | 'diverging' | 'silhouette' | undefined;
  curve?: 'cardinal' | 'step' | 'linear' | 'basis';
  legend?: ChartLegendProps;
  dateAxisProps?: Partial<AxisProps<AxisScale>>;
  valueAxisProps?: Partial<AxisProps<AxisScale>>;
  scaleBandRangeByMaxBandWidth?: {
    domain: Array<string>;
    maxBandWidth: number;
  };
  handleClickLegendItem?: (dataKey: string) => void;
};

export const XYSeriesChart = observer(
  ({
    id,
    className,
    series = [],
    width,
    height,
    margin,
    themeConfig,
    renderHorizontally = false,
    accessors = {
      xAccessor: (d: any) => (renderHorizontally ? d.value : d.date),
      yAccessor: (d: any) => (renderHorizontally ? d.date : d.value),
    },
    colorAccessor,
    valueScaleConfig = { type: 'linear' },
    dateScaleConfig = { type: 'band', paddingInner: 0.3 },
    valueAxisLabel = undefined,
    dateAxisLabel = undefined,
    showGridRows = true,
    showGridColumns = false,
    numTicks = 4,
    showTooltip = true,
    sharedTooltip = true,
    showLegend = true,
    showHorizontalCrosshair = false,
    showVerticalCrosshair = false,
    snapTooltipToDatumX = true,
    snapTooltipToDatumY = true,
    renderBarSeries = false,
    renderBarGroup = false,
    renderBarStack = false,
    renderAreaSeries = false,
    renderAreaStack = false,
    renderLineSeries = true,
    xAxisOrientation = 'bottom',
    yAxisOrientation = 'left',
    stackOffset = undefined,
    curve = 'linear',
    legend = {
      showLineColor: true,
      showLabel: true,
    },
    dateAxisProps,
    valueAxisProps,
    scaleBandRangeByMaxBandWidth,
    renderTooltip = (renderTooltipParams) => (
      <DefaultTooltip
        {...renderTooltipParams}
        showTooltipTitle={true}
        showItemText={true}
        showItemValue={true} />
    ),
    handleClickLegendItem,
  }: LineChartProps) => {
    className = classNames('chart xySeriesChart', className);

    const curveType =
      (curve === 'cardinal' && curveCardinal) ||
      (curve === 'step' && curveStep) ||
      (curve === 'linear' && curveLinear) ||
      curveMonotoneX;

    if (scaleBandRangeByMaxBandWidth && renderBarSeries) {
      dateScaleConfig = {
        ...dateScaleConfig,
        ...getScaleBandRangeByMaxBandWidth({
          leftPad: renderHorizontally ? margin?.bottom : margin?.left,
          rightPad: renderHorizontally ? margin?.top : margin?.right,
          paddingInner: dateScaleConfig?.paddingInner,
          chartWidth: renderHorizontally ? height : width,
          domain: scaleBandRangeByMaxBandWidth.domain,
          maxBandWidth: scaleBandRangeByMaxBandWidth.maxBandWidth,
        }),
      };
    }

    const xScale = renderHorizontally ? valueScaleConfig : dateScaleConfig;
    const yScale = renderHorizontally ? dateScaleConfig : valueScaleConfig;

    const theme: XYChartTheme =
      (themeConfig && buildChartTheme(themeConfig)) || lightTheme;

    const normalizeSeries = (series: LineSeriesProps[]) => {
      return series.map(({ dataKey, data }) => {
        return { dataKey, data };
      });
    };

    const timeAxisOrientation = renderHorizontally ? yAxisOrientation : xAxisOrientation;
    const valueAxisOrientation = renderHorizontally ? xAxisOrientation : yAxisOrientation;

    const tickFormat = stackOffset === 'wiggle' ? () => '' : undefined;

    const showDatumGlyph = (snapTooltipToDatumX || snapTooltipToDatumY) && !renderBarGroup;
    const showSeriesGlyphs = sharedTooltip && !renderBarGroup;

    return (
      <div id={id} className={className}>
        <DataProvider xScale={xScale} yScale={yScale} theme={theme}>
          <XYChart width={width} height={height} margin={margin}>
            <Grid
              rows={!!showGridRows}
              columns={!!showGridColumns}
              numTicks={numTicks} />

            <Axis
              key={`time-axis-${renderHorizontally}`}
              label={dateAxisLabel}
              orientation={timeAxisOrientation}
              numTicks={numTicks}
              {...dateAxisProps} />
            <Axis
              key={`value-axis-${renderHorizontally}`}
              label={valueAxisLabel}
              orientation={valueAxisOrientation}
              numTicks={numTicks}
              tickFormat={tickFormat}
              {...valueAxisProps} />

            {renderBarStack &&
              <BarStack offset={stackOffset}>
                {normalizeSeries(series).map((series) => {
                  return (
                    <BarSeries
                      key={series.dataKey}
                      {...series}
                      {...accessors} />
                  );
                })}
              </BarStack>}

            {renderBarGroup &&
              <BarGroup>
                {normalizeSeries(series).map((series) => {
                  return (
                    <BarSeries
                      key={series.dataKey}
                      {...series}
                      {...accessors} />
                  );
                })}
              </BarGroup>}

            {renderBarSeries &&
              normalizeSeries(series).map((series) => {
                return (
                  <BarSeries
                    key={series.dataKey}
                    {...series}
                    {...accessors}
                    colorAccessor={colorAccessor} />
                );
              })}

            {renderAreaSeries &&
              normalizeSeries(series).map((series) => {
                return (
                  <AreaSeries
                    key={series.dataKey}
                    fillOpacity={0.4}
                    curve={curveType}
                    {...series}
                    {...accessors} />
                );
              })}

            {renderAreaStack &&
              <AreaStack
                curve={curveType}
                offset={stackOffset}
                renderLine={stackOffset !== 'wiggle'}>
                {normalizeSeries(series).map((series) => {
                  return (
                    <AreaSeries
                      key={series.dataKey}
                      fillOpacity={0.4}
                      {...series}
                      {...accessors} />
                  );
                })}
              </AreaStack>}

            {renderLineSeries && normalizeSeries(series).map((series) => {
              return (
                <LineSeries
                  key={series.dataKey}
                  curve={curveType}
                  fillOpacity={0.4}
                  strokeWidth={series.data?.length > 1 ? 2 : 8}
                  {...series}
                  {...accessors} />
              );
            })}

            {showTooltip &&
              <Tooltip
                showHorizontalCrosshair={showHorizontalCrosshair}
                showVerticalCrosshair={showVerticalCrosshair}
                snapTooltipToDatumX={snapTooltipToDatumX}
                snapTooltipToDatumY={snapTooltipToDatumY}
                showDatumGlyph={showDatumGlyph}
                showSeriesGlyphs={showSeriesGlyphs}
                className="xy-series-chart-tooltip"
                renderTooltip={renderTooltip} />}
          </XYChart>

          {showLegend &&
            <ChartLegend
              {...legend}
              onClickLegendItem={handleClickLegendItem} />}
        </DataProvider>
      </div>
    );
  }
);

type ControlledProps = LineChartProps & {
  model: XYSeriesChartState;
};

export const XYSeriesChartControlled = observer(
  ({ model, ...props }: ControlledProps) => {
    const { series, themeConfig } = props;
    const colors = themeConfig?.colors;

    useEffect(() => {
      model.mounted({ series, colors });
      return () => model.unmounted();
    }, [model, series, colors]);

    const legend: ChartLegendProps = props?.legend?.legendItems ? 
      props.legend : 
      {
        ...props?.legend,
        legendItems: model.initialSeriesArr.map(({ dataKey, glyph }) => {
          return {
            dataKey,
            glyph,
            color: model.initialColors.get(dataKey),
          };
        }),
      };

    const newThemeConfig: ThemeConfig | undefined = props?.themeConfig && {
      ...props.themeConfig,
      colors: model.availableColorsArr,
    };

    return (
      <XYSeriesChart
        handleClickLegendItem={model.handleClickLegendItem}
        {...props}
        series={model.availableSeriesArr}
        legend={legend}
        themeConfig={newThemeConfig} />
    );
  }
);
