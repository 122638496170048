import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { UploadFileState } from './uploadFileState';
import { UploadTask } from '../../services/upload';
import {
  FileUploadCompleteSVGIcon,
  FileUploadingSVGIcon,
  FileUploadSVGIcon,
  PlaySVGIcon,
  CodeSVGIcon
} from '../../components/svg';
import { percentage } from '../../components/utils';
import { handler } from '../../core';
import { Button } from '../../components';
import Routes from '../../routes';
import { ExpandToggle } from './sharedComponents/expandToggle';
import { EditButton } from './sharedComponents/editButton';
import { UploadProgressBar } from './sharedComponents/uploadProgressBar';
import { UploadTaskGroup } from '../../services/upload/uploadTaskGroup';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Tooltip } from '../../components/overlays';

type ItemProps = {
  task: UploadTask;
};

export const UploadFileProgress = observer((props: ItemProps) => {
  const { task } = props;
  
  return (
    <div className="upload-file-progress">
      <div className="file-info">
        <span className="file-name" title={task.file.name}>
          {task.file.name}
        </span>
        <span className="file-progress">
          <span className="progress-value">
            {task.isActive && (
              <div>
                {percentage(task.progress)} OF {task.fileSizeInfo.toString()}
              </div>
            )}

            {task.status === 'completed' && (
              <div>{task.fileSizeInfo.toString()}</div>
            )}
          </span>

          {task.error &&
            (task.canRetry ? (
              <Button
                label="Retry"
                className="green small"
                onClick={handler(task, 'retry')}
              />
            ) : (
              !task.isCanceled && (
                <Button
                  label="Remove"
                  className="red small"
                  onClick={handler(task, 'discard')}
                />
              )
            ))}

          {task.error && <FileUploadSVGIcon />}

          {task.status === 'completed' && <FileUploadCompleteSVGIcon />}

          {task.status === 'uploading' && (
            <>
              <FileUploadingSVGIcon />
              <Button
                label="Cancel"
                className="btn-outline yellow small cancel-btn"
                onClick={() => 
                  task.dispatch('openCancelUploadWindow', { task })
                }
              />
            </>
          )}

          {task.isCanceled && <span className="status">Canceled</span>}
        </span>
      </div>
    </div>
  );
});

type Props = {
  state: UploadFileState;
  model: UploadTaskGroup;
};

export const UploadFileProgressBox = observer((props: Props) => {
  const { state, model } = props;
  const pendingTasks = model.pendingTasks;
  const completedTasks = model.completedTasks;
  const teamId = model.groupId;
  const isExpanded = state.getIsExpanded(teamId);

  const showPreview = !model.store.widget.isWidgetMode;

  const className = classNames('upload-file-progress-box upload-stage-box', {
    expanded: isExpanded
  });

  return (
    <div className={className}>
      <UploadFileProgressHeading
        state={state}
        model={model} />

      <div className="upload-file-progress-content"
        aria-hidden={!isExpanded}>
        {(pendingTasks.length > 0 || true) && <>
          <div className="file-container-label uppercase"
            aria-hidden={pendingTasks.length === 0}>Upload Progress</div>
          <div className="scroll-container">
            <TransitionGroup component="ul"
              className="upload-file-list">
              {pendingTasks.map((task) => {
                const cardClassName = classNames('upload-file-progress-card', {
                  completed: task.status === 'completed',
                  error: task.status === 'error',
                  canceled: task.isCanceled,
                  active: task.isActive,
                });

                return (
                  <CSSTransition
                    classNames="item-show"
                    in={true}
                    appear={true}
                    mountOnEnter={true}
                    timeout={300}
                    key={task.id}>
                    <li key={task.id} className={"upload-file-progress-item"}>
                      <div className={cardClassName}>
                        {task.isActive &&
                          <UploadProgressBar progress={task.progress} />}
                        <UploadFileProgress task={task} />
                      </div>
                      {!task.isCanceled && (
                        <Tooltip content="Video Details">
                          <EditButton
                            onClick={() =>
                              state.dispatch('openVideoDetailsWindow', {
                                jobId: task.syncJobId,
                                teamId: task.teamId,
                                layout: 'partial',
                              })
                            }
                            disabled={task.synchronizer.isPending}
                          />
                          </Tooltip>
                        )}
                    </li>
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
          </div>
        </>}

        {(completedTasks.length > 0 || true) && <>
          <div className="file-container-label uppercase"
            aria-hidden={completedTasks.length === 0}>Uploaded Files</div>
          <div className="scroll-container">
            <TransitionGroup component="ul"
              className="upload-file-list">
              {completedTasks.map((task) => {
                const cardClassName = classNames('upload-file-progress-card', {
                  completed: task.status === 'completed',
                  error: task.status === 'error',
                  active: task.isActive,
                });

                return (
                  <CSSTransition
                    classNames="item-show"
                    timeout={300}
                    appear={true}
                    in={true}
                    key={task.id}>
                    <li key={task.id} className={'upload-file-progress-item'}>
                      <div className={cardClassName}>
                        <UploadFileProgress task={task} />
                        {task.isActive && (
                          <UploadProgressBar progress={task.progress} />
                        )}
                      </div>
                      <Tooltip content="Video Details">
                        <EditButton
                          onClick={() =>
                            state.dispatch('openVideoDetailsWindow', {
                              jobId: task.syncJobId,
                              teamId: task.teamId,
                              layout: 'partial',
                            })
                          }
                        />
                      </Tooltip>
                      {showPreview && (
                        <Tooltip content="Preview">
                          <Button
                            className="preview-button"
                            icon={<PlaySVGIcon />}
                            route={task.jobUrl || '#'}
                            target="_blank"
                          />
                        </Tooltip>
                      )}
                      {false && task.outputJob?.isPublic && (
                        <Tooltip content="Copy Embed Code">
                          <Button
                            className="embed-button"
                            icon={<CodeSVGIcon />}
                            onClick={() => task.copyIFrameEmbedCode()}
                          />
                        </Tooltip>
                      )}
                    </li>
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
          </div>
        </>}
      </div>

    </div>
  );
});

type UploadFileProgressHeadingProps = {
  state: UploadFileState;
  model: UploadTaskGroup;
};

const UploadFileProgressHeading = observer(
  ({ state, model }: UploadFileProgressHeadingProps) => {
    const teamId = model.groupId;

    const messageParagraphContent = !model.isQueueCompleted ?
      <p> Your videos are uploading. Depending on the file size and your
      internet connection this may take a while.<br />
      Meanwhile you can provide some details for each video.</p> :
      <p>
        Your videos are uploaded. CLIPr has started processing them.{' '}
        {teamId !== 'null' && (
          <>
            You can check the progress in:<br />
            <Button
              className="link link-secondary team-dashboard-cta"
              label={state.teamName}
              route={Routes.teamDashboard(teamId)}
              target={state.store.widget.isWidgetMode ? '_blank' : undefined}
            />
          </>
        )}
      </p>

    return (
      <div className="upload-file-progress-heading" onClick={() => state.toggleProgressBox(teamId)}>
        <div className="headline">
          <div className="box-status">
            <span className="percentage">{percentage(model.averageQueueProgress)}{' '}</span>
            {model.activeTasks.length ? <>Uploading{' '}</> : <strong>Uploaded{' '}</strong>}
            {model.teamName && (
              <>
                <span className="team-name">for <strong>{model.teamName}</strong></span>
              </>
            )}
          </div>

          <ExpandToggle
            isExpanded={state.getIsExpanded(teamId)} />
        </div>
        {messageParagraphContent}
      </div>
    );
  }
);