import React from 'react';
import { observer } from 'mobx-react-lite';
import { Maybe, ObjectLiteral } from '../../../core';
import { useAnchor } from '../../../services/overlays';
import { PlayerState } from '../playerState';

type Props = {
  model: PlayerState,
  className?: Maybe<string | ObjectLiteral>
}

export const PlayerMomentStub = observer((props: Props) => {
  const { model } = props;

  const [anchorRef] = useAnchor({
    target: model,
    id: 'userPlayerMomentStub'
  });

  const stub = model.momentEditor.currentStub;
  if (!stub)
    return null;

  const style = {
    left: model.getTimeRatio(stub.startTime) * 100 + '%',
    width: model.getTimeRatio(stub.duration) * 100 + '%'
  }

  return (
    <div className="player-stub"
      style={style}
      ref={anchorRef as any}>

    </div>
  )
});