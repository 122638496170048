import range from 'lodash/range';
import { ITimeRegion, mergeTimeRegions } from '../../core/time';
import { isFiniteNumber } from '../number';

/**
 * Returns an array of time regions from a TimeRanges object.
 */
export function timeRangesToArray(ranges: TimeRanges): ITimeRegion[] {
  return range(0, ranges.length)
    .map(i => ({
      startTime: ranges.start(i),
      endTime: ranges.end(i)
    }));
}

export function timeRangesHas(ranges: TimeRanges | null, time: number) {
  if (!ranges || ranges.length === 0)
    return false;
  for (let i = 0; i < ranges.length; i++)
    if (ranges.start(i) >= time && ranges.end(i) <= time)
      return true;
  return false;
}

/**
 * Takes a TimeRanges object and returns the first `end` timestamp after the provided `after` threshold.
 * If no such range exists, null is returned.
 */
export function getNextEndTimeFromRanges(ranges: TimeRanges, after: number = 0): number | null {
  return timeRangesToArray(ranges).find(r => r.endTime > after)?.endTime ?? null;
}

/**
 * Takes a TimeRanges object and returns the first `start` timestamp after the provided `after` threshold.
 * If no such range exists, null is returned.
 */
export function getNextStartTimeFromRanges(ranges: TimeRanges, after: number = 0): number | null {
  return timeRangesToArray(ranges).find(r => r.startTime > after)?.startTime ?? null;
}

/**
 * Returns a timestamp representing the `end` position of the last region 
 * that is connected to the provided `after` threshold.
 * If no such range exists, null is returned.
 */
export function getBufferedTimeFromRanges(ranges: TimeRanges | null, after: number | null): number | null {
  if (!ranges || !isFiniteNumber(after))
    return null;

  const arr = timeRangesToArray(ranges);
  const contArr = mergeTimeRegions(arr);

  // with merged regions all we have to do is return the first region that 
  // starts before the `after` threshold and ends after it
  return contArr.find(reg => reg.startTime <= after && reg.endTime > after)?.endTime ?? null;
}

/**
 * Returns a timestamp representing the `end` position of the last region 
 * that is connected to the provided `after` threshold.
 * If no such range exists, null is returned.
 */
export function getSeekableTimeFromRanges(ranges: TimeRanges | null, after: number | null): number | null {
  if (!ranges || !isFiniteNumber(after))
    return null;

  const arr = timeRangesToArray(ranges);
  const contArr = mergeTimeRegions(arr);

  // with merged regions all we have to do is return the first region that 
  // starts before the `after` threshold and ends after it
  return contArr.find(reg => reg.startTime <= after && reg.endTime > after)?.endTime ?? null;
}

/**
 * Returns an object containing a startTime and an endTime
 * that will define an interval based on the merged time regions provided.
 * If no such range exists, null is returned.
 */
export function getSeekableWindow(ranges: TimeRanges | null): ITimeRegion | null {
  if (!ranges)
    return null;
    
  const arr = timeRangesToArray(ranges);
  const contArr = mergeTimeRegions(arr);

  // return the start time of the first region and the endTime of the last region 
  // in order to have the total seekable area (might not be contiguous)
  if (contArr && contArr.length > 0)
    return {
      startTime: contArr[0].startTime,
      endTime: contArr[contArr.length - 1].endTime
    };

  return null;
}