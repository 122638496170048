import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { JobSpeciality, JobType, StartJobInput } from '@clipr/lib';
import isEmpty from 'lodash/isEmpty';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { EnrichmentItem, generateJobLevelOutput, getDefaultEnrichmentLevel, getEnrichmentLevelItemTooltip, getEnrichmentLevelLabel, JobLevel } from '../../entities/job/jobFeatures';
import { DefaultSpecialityItem, FilteredSpecialityItems, getJobSpecialityOutput, SpecialityItems } from '../../entities/job/jobSpeciality';
import { DefaultLanguageItem, LanguageItems } from '../../entities/language';
import { input, inputGroup, InputGroupState, InputState } from '../input';
import { JobVideoTypeList } from '../../entities';

type Params = {
  args: StartJobInput,
  videoTitle?: string,
  fileSelection?: string[],
  multipleSelection?: boolean
}

export class IngestFromExternalLibraryWindowState
  extends StoreNode {

  readonly nodeType = 'IngestFromExternalLibraryWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);


    this.languageInput = input(this, {
      name: 'language',
      placeholder: 'Choose an option',
      selectorItems: LanguageItems,
      showStatusMessage: true,
      isRequired: true,
      statusMessage: (input: InputState) =>
        input.status === 'error' && input.showStatusIcons ? input.error?.toString() : '',
      error: (input: InputState) => {
        if (isEmpty(input.value)) return 'Required field';
        if (this.specialityInput.normValue === 'Medical' && input.normValue !== 'en-US')
          return 'Only US English supported';
      }
    });

    this.levelInput = input(this, {
      name: 'level',
      placeholder: 'Choose an option',
      selectorItems: () => JobLevel.map(item => ({
        value: item,
        label: getEnrichmentLevelLabel(item),
        tooltip: getEnrichmentLevelItemTooltip(item)
      })),
      showStatusMessage: true,
      isRequired: true,
      statusMessage: (input: InputState) =>
        input.status === 'error' && input.showStatusIcons ? input.error?.toString() : '',
      error(input: InputState) {
        if (isEmpty(input.value)) return 'Required field';
      }
    });

    this.specialityInput = input(this, {
      name: 'speciality',
      placeholder: 'Choose an option',
      selectorItems: () => this.teamManager.getTeam(this.args?.teamId)?.publicSafety ? 
        SpecialityItems : 
        FilteredSpecialityItems,
      showStatusMessage: true,
      isRequired: !this.isSpecialityInputDisabled,
      statusMessage: (input: InputState) =>
        input.status === 'error' && input.showStatusIcons ? input.error?.toString() : '',
      error: (input: InputState) => {
        if (isEmpty(input.value) && !this.isSpecialityInputDisabled) return 'Required field';
      }
    });

    this.videoTypeInput = input(this, {
      name: 'videoType',
      placeholder: 'Choose an option',
      selectorItems: JobVideoTypeList,
      showStatusMessage: true,
      export: (self: InputState) => self.normValue
    });

    this.form = inputGroup(this, {
      name: 'languages',
      inputs: [
        this.languageInput,
        this.levelInput,
        this.specialityInput,
        this.videoTypeInput
      ],
      isSubmitDisabled: () => {
        return (
          this.form.isSubmitting ||
          this.form.hasErrorInputs
        );
      }
    });
  }

  readonly window = new WindowState(this.store);

  readonly languageInput: InputState;
  readonly levelInput: InputState;
  readonly specialityInput: InputState;
  readonly videoTypeInput: InputState;

  readonly form: InputGroupState;

  @observable isLoading: boolean = false;
  @observable args: StartJobInput | null = null;
  @observable videoTitle: string | null = null;
  @observable multipleSelection: boolean = false;
  @observable fileSelection: string[] = [];

  @computed get teamManager() {
    return this.store.teamManager;
  }

  @computed
  get isSpecialityInputDisabled() {
    return this.levelInput?.value === 'media';
  }
  

  @action
  async init() {
    this.languageInput.loadValue(DefaultLanguageItem);
    const team = this.teamManager.getTeam(this.args?.teamId);
    this.levelInput.loadValue(team?.enrichmentLevel ?? getDefaultEnrichmentLevel());
    const defaultSpecialityInput = this.teamManager.getTeam(this.args?.teamId)?.publicSafety ? 
      'PublicSafety' : 
      DefaultSpecialityItem?.value;

    this.specialityInput.loadValue(!this.isSpecialityInputDisabled ? defaultSpecialityInput : null);
    this.videoTypeInput.loadValue(JobType.Presentation);
  }

  @action
  async submit() {
    this.isLoading = true;


    if (!this.args || !this.languageInput.value || !this.levelInput.value) return;

    this.form.handleSubmit();

    if (this.form.error) {
      this.form.handleSubmitReject();
      this.isLoading = false;
      return;
    }

    this.updateArgs();

    if (this.multipleSelection) return this.bulkIngest();

    const [res, err] = await this.store.apiStartJob(this.args);

    runInAction(() => {
      if (err) {
        notifyError(this, 'Video ingest failed.');
        this.form.handleSubmitReject();
      } else {
        notifySuccess(this, 'Video ingest started.');

        //{ source: this.args?.source?.externalLibrary?.source }
        this.emit('job:ingestStarted', { job: res });

        this.form.handleSubmitResolve();
        this.refetchJobs();
      }

      this.handleSubmitFinally();
    });
  }

  @action
  private async bulkIngest() {
    if (!this.args) return;

    let hasErrors = false;
    let hasSuccessful = false;

    for await (let id of this.fileSelection) {
      const args = this.args;

      args.source.externalLibrary!.fileId = id;

      const [res, err] = await this.store.apiStartJob(args);

      if (err) {
        hasErrors = true;
      } else {
        hasSuccessful = true;
        this.emit('job:ingestStarted', { job: res });
      }
    }

    if (hasErrors && !hasSuccessful) {
      notifyError(this, 'Video ingest failed.');
      this.form.handleSubmitReject();
    } else {
      notifySuccess(this, 'Video ingest started.');

      this.form.handleSubmitResolve();
      this.refetchJobs();
    }
    if (hasSuccessful) {
      this.emit('close:successful');
    } else {
      this.emit('close:unsuccessful');
    }
    this.handleSubmitFinally();
  }

  @action
  private updateArgs() {
    if (!this.args) return;

    this.args.languageCode = this.languageInput.normValue || undefined;
    this.args.medicalSpecialty = getJobSpecialityOutput(this.specialityInput.value as JobSpeciality || null);
    this.args.speciality = this.specialityInput.value as JobSpeciality;
    this.args.features = (this.levelInput.normValue &&
      generateJobLevelOutput(this.levelInput.normValue as EnrichmentItem)) || undefined;
    this.args.videoType = this.videoTypeInput.value as JobType;
  }

  refetchJobs() {
    switch (this.args?.source.externalLibrary?.source) {
      case 'GoogleDrive': {
        this.store.externalLibraryUploadWindow.googleDriveTabState.fetch();
        this.store.userDashboardPage.googleDriveTabState.fetch();
        break;
      }
      case 'OneDrive': {
        this.store.userDashboardPage.oneDriveTabState.fetch();
        this.store.externalLibraryUploadWindow.oneDriveTabState.fetch();
        break;
      }
      case 'Zoom': {
        this.store.externalLibraryUploadWindow.zoomIntegrationTabState.fetch();
        this.store.userDashboardPage.zoomIntegrationTabState.fetch();
        break;
      }
      default: {
        this.store.userDashboardPage.jobCatalogSource.fetch();
      }
    }
  }

  handleSubmitFinally() {
    runInAction(() => {
      this.isLoading = false;
      this.close();
    })
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open({ args, videoTitle, fileSelection, multipleSelection = false }: Params) {
    this.args = args;
    this.videoTitle = videoTitle || null;
    this.multipleSelection = multipleSelection;
    this.fileSelection = fileSelection || [];

    this.dispatch('Overlays', 'openWindow', { name: 'IngestFromExternalLibraryWindow' });
    this.init();
  }

  @action
  close() {
    this.args = null;
    this.videoTitle = null;
    this.multipleSelection = false;
    this.fileSelection = [];

    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}