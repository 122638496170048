import { computed, makeObservable } from 'mobx';
import { Moment, MomentModel, MomentSelector, SpeakerModel, SubTopic, Topic, Transcript } from '../../entities';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { IPlayer } from './playerSchema';
import { allActiveMoments, lastActiveMoment } from '../../entities/moments/momentFilters';
import { PlayerItemSource } from '../../entities/player/playerItemSource';
import { PlayerItem } from '../../entities/player';
import identity from 'lodash/identity';
import { Comment, Reaction } from '../../entities/comment';
import { allActiveComments, firstActiveComment } from '../../entities/comments/commentsFilter';

type Props = BindingProps<{
  player?: IPlayer,
  source?: PlayerItemSource
}>

/** 
 * Sometimes the player's time is at the start of an active moment, which might be slightly higher than the 
 * player's time (due to rounding differences) which might fool the view into returning the current active moment
 * as the next moment. This constant ensures that the next moment is actually the one after player's time + the constant
 */
const NEXT_MOMENT_EPSILON = 0.01;

/** 
 * Collection of properties and events which can be attached to a Player instance
 * and query information about Moments being played.
 */
export class PlayerMomentView
  extends StoreNode {

  constructor(store: Store, props?: BindingProps<Props>) {
    super(store, props);
    makeObservable(this);
  }

  // #region Resolved props
  // -------

  @computed
  private get player(): IPlayer | null {
    return this.getResolvedProp('player') ?? null;
  }

  @computed
  private get source(): PlayerItemSource | null {
    return this.getResolvedProp('source') ?? null;
  }

  @computed
  private get selector(): MomentSelector | null {
    return this.source?.selector ?? null;
  }

  @computed
  private get items(): PlayerItem[] {
    return this.source?.items ?? [];
  }

  @computed
  private get currentTime(): number {
    // NOTE: was previously using `player.currentTime` directly,
    // however since the ads were implemented there's an open problem
    // as to handle both the ads adapter and the content adapter
    // so as of now the usage of the adapter currentTime is mostly a workaround
    // until we standardize the behaviour across the player
    return this.player?.adapter.currentTime ?? 0;
  }
  // #endregion


  // #region Topics
  // -------

  @computed
  get showActiveTopic(): boolean {
    return this.selector?.mode === 'Topics' &&
      !!(this.source?.indexSectionTopics && this.source?.indexSectionTopics.length > 0);
  }

  @computed
  get activeTopicPool(): Topic[] {
    return this.items
      .filter(item => item.showAsActiveTopic)
      .map(item => item.moment! as Topic);
  }

  @computed
  get activeSubTopicPool(): SubTopic[] {
    return this.items
      .filter(item => item.showAsActiveSubTopic)
      .map(item => item.moment! as SubTopic);
  }

  /** Gets the last Topic moment which overlaps the current player time. */
  @computed
  get topicMoments(): Topic[] {
    return this.items
      .filter(item => item.showInIndexSection)
      .map(item => item.moment as Topic);
  }

  @computed
  get activeTopic(): Topic | null {
    return lastActiveMoment(
      this.activeTopicPool, this.currentTime + NEXT_MOMENT_EPSILON);
  }

  @computed
  get activeSubTopics(): SubTopic[] {
    return allActiveMoments(
      this.activeSubTopicPool, this.currentTime + NEXT_MOMENT_EPSILON);
  }

  @computed
  get lastActiveSubtopic(): SubTopic | null {
    return lastActiveMoment(
      this.activeSubTopicPool, this.currentTime + NEXT_MOMENT_EPSILON);
  }

  @computed
  get showNextTopic(): boolean {
    return !!this.nextTopic && (
      this.selector?.mode === 'Topics');
  }

  @computed
  get nextTopicPool(): Topic[] {
    return this.items
      .filter(item => item.showNextTopicButton)
      .map(item => item.moment! as Topic);
  }

  @computed
  get nextTopic(): Topic | null {
    const time = this.currentTime;
    return this.nextTopicPool
      .find(mom => mom.startTime > time + NEXT_MOMENT_EPSILON) || null;
  }
  // #endregion


  // #region Generics
  // -------

  @computed
  get showNextSubTopic(): boolean {
    return !!this.nextSubTopic && (
      this.selector?.mode === 'Topics');
  }

  @computed
  get nextSubTopic(): SubTopic | null {
    const time = this.currentTime;
    return this.items
      .filter(item => item.showNextSubTopicButton && !!item.moment)
      .map(item => item.moment as SubTopic)
      .find(mom => mom.startTime > time + NEXT_MOMENT_EPSILON) || null;
  }

  @computed
  get detailsPanelMoments(): Moment[] {
    return this.items
      .filter(item => item.showInDetailsPanel)
      .map(item => item.moment!);
  }

  @computed
  get activeDetailsPanelMoment() {
    return lastActiveMoment(
      this.detailsPanelMoments, this.currentTime + NEXT_MOMENT_EPSILON);
  }
  // #endregion


  // #region Transcripts
  // -------
  @computed
  get transcriptMoments(): Transcript[] {
    return this.items
      .filter(item => item.showInTranscriptsBox)
      .map(item => item.moment as Transcript);
  }

  @computed
  get activeTranscript(): Transcript | null {
    return lastActiveMoment(
      this.transcriptMoments, this.currentTime + NEXT_MOMENT_EPSILON);
  }

  @computed
  get nextTranscriptPool(): Transcript[] {
    const time = this.currentTime;
    return this.items
      .filter(item => item.showInTranscriptsBox)
      .map(item => item.moment! as Transcript)
      .filter(mom => mom.startTime > time + NEXT_MOMENT_EPSILON);
  }

  @computed
  get nextTranscript(): Transcript | null {
    const time = this.currentTime;
    return this.nextTranscriptPool
      .find(mom => mom.startTime > time + NEXT_MOMENT_EPSILON) || null;
  }

  @computed
  get activeSortedTranscriptPool(): Transcript[] {
    return this.items
      .filter(item => item.showAsActiveSpeaker && item.isTranscriptNode)
      .map(item => item.moment! as Transcript)
      .sort((a, b) => {
        const diffA = a.startTime - this.currentTime;
        const diffB = b.startTime - this.currentTime;
        if (this.activeTranscript?.id === a.id) return -1;
        if (this.activeTranscript?.id === b.id) return 1;
        if (diffA < 0 && diffB > 0) return 1;
        if (diffA > 0 && diffB < 0) return -1;
        return diffA - diffB;
      });
  }

  @computed
  get activeTranscriptSpeakerPool(): SpeakerModel[] {
    const uniqueSpeakers = new Set(
      this.transcriptMoments
        .map(mom => mom.actualSpeaker)
        .filter(identity)
    );
    return [...uniqueSpeakers] as SpeakerModel[];
  }

  @computed
  get activeTranscriptSpeakerId(): string | null {
    return this.activeTranscript?.actualSpeakerId ?? null;
  }

  @computed
  get activeTranscriptSpeaker(): SpeakerModel | null {
    return this.activeTranscript?.actualSpeaker || null;
  }

  @computed
  get displayActiveTranscriptSpeakerPool(): SpeakerModel[] {
    const uniqueSpeakers = new Set(
      this.activeSortedTranscriptPool
        .map(mom => mom.actualSpeaker)
        .filter(identity)
    );
    return [...uniqueSpeakers] as SpeakerModel[];
  }

  getNextTranscriptBySpeakerId(speakerId: string): Transcript | null {
    const time = this.currentTime;
    const nextMoment = this.nextTranscriptPool
      .find(m =>
        m.startTime > time + NEXT_MOMENT_EPSILON &&
        m.hasSpeaker &&
        m.actualSpeakerId === speakerId) ?? null;

    if (!nextMoment) {
      return this.transcriptMoments
        .find(m =>
          m.hasSpeaker &&
          m.actualSpeakerId === speakerId) ?? null
    }

    return nextMoment;
  }
  // #endregion


  // #region Speakers

  @computed
  get showActiveSpeaker(): boolean {
    return this.selector?.mode === 'Speakers'
      && this.activeSpeakerPool.length > 0;
  }

  @computed
  get activeSpeakerMomentPool(): Moment[] {
    return this.items
      .filter(item => item.showAsActiveSpeaker)
      .map(item => item.moment as Moment);
  }

  @computed
  get activeSpeakerPool(): SpeakerModel[] {
    const uniqueSpeakers = new Set(
      this.activeSpeakerMomentPool
        .map(mom => mom.actualSpeaker) //should already contain only non-null values
    );
    return [...uniqueSpeakers] as SpeakerModel[];
  }

  @computed
  get activeSpeakerTopicPool(): Topic[] {
    return this.items
      .filter(item => item.showAsActiveSpeaker && item.isTopicNode)
      .map(item => item.moment! as Topic);
  }

  @computed
  get activeSortedSpeakerTopicPool(): Topic[] {
    return this.items
      .filter(item => item.showAsActiveSpeaker && item.isTopicNode)
      .map(item => item.moment! as Topic)
      .sort((a, b) => {
        const diffA = a.startTime - this.currentTime;
        const diffB = b.startTime - this.currentTime;
        if (this.activeTopic?.id === a.id) return -1;
        if (this.activeTopic?.id === b.id) return 1;
        if (diffA < 0 && diffB > 0) return 1;
        if (diffA > 0 && diffB < 0) return -1;
        return diffA - diffB;
      });
  }

  @computed
  get activeSpeakerTopic(): MomentModel | null {
    return lastActiveMoment(
      this.activeSpeakerTopicPool, this.currentTime + NEXT_MOMENT_EPSILON);
  }

  @computed
  get activeTopicSpeakerPool(): SpeakerModel[] {
    const uniqueSpeakers = new Set(
      this.activeSpeakerTopicPool
        .map(mom => mom.actualSpeaker)
        .filter(identity)
    );
    return [...uniqueSpeakers] as SpeakerModel[];
  }

  @computed
  get activeTopicSpeakerId(): string | null {
    return this.activeTopic?.actualSpeakerId ?? null;
  }

  @computed
  get activeTopicSpeaker(): SpeakerModel | null {
    return this.activeSpeakerTopic?.actualSpeaker || null;
  }

  @computed
  get displayActiveTopicSpeakerPool(): SpeakerModel[] {
    const uniqueSpeakers = new Set(
      this.activeSortedSpeakerTopicPool
        .map(mom => mom.actualSpeaker)
        .filter(identity)
    );
    return [...uniqueSpeakers] as SpeakerModel[];
  }

  @computed
  get activeSpeakerMoment(): MomentModel | null {
    return lastActiveMoment(
      this.activeSpeakerMomentPool, this.currentTime + NEXT_MOMENT_EPSILON);
  }

  @computed
  get activeSpeakerId(): string | null {
    return this.activeSpeakerMoment?.actualSpeakerId ?? null;
  }

  @computed
  get activeSpeaker(): SpeakerModel | null {
    return this.activeSpeakerMoment?.actualSpeaker || null;
  }

  @computed
  get nextSpeakerMomentPool(): Moment[] {
    return this.items
      .filter(item => item.showNextSpeakerButton)
      .map(item => item.moment! as Moment);
  }

  @computed
  get nextSpeakerMoment(): MomentModel | null {
    const time = this.currentTime;
    return this.nextSpeakerMomentPool
      .find(m =>
        m.startTime > time + NEXT_MOMENT_EPSILON &&
        m.hasSpeaker &&
        m.actualSpeakerId !== this.activeSpeakerId) || null;
  }

  @computed
  get nextSpeaker(): SpeakerModel | null {
    return this.nextSpeakerMoment?.actualSpeaker || null;
  }

  @computed
  get nextTopicBySpeakerPool(): Topic[] {
    return this.items
      .filter(item => item.showAsActiveSpeaker && item.isTopicNode)
      .map(item => item.moment! as Topic);
  }

  getNextTopicBySpeakerId(speakerId: string): Topic | null {
    const time = this.currentTime;
    const nextMoment = this.nextTopicBySpeakerPool
      .find(m =>
        m.startTime > time + NEXT_MOMENT_EPSILON &&
        m.hasSpeaker &&
        m.actualSpeakerId === speakerId) || null;

    if (!nextMoment) {
      return this.nextTopicBySpeakerPool
        .find(m =>
          m.hasSpeaker &&
          m.actualSpeakerId === speakerId) ?? null
    }

    return nextMoment;
  }

  get showNextSpeaker(): boolean {
    return !!this.nextSpeaker && (
      this.selector?.mode === 'Speakers');
  }

  @computed
  get activeSpeakerIdSpeakerPool(): SpeakerModel[] {
    const uniqueSpeakers = new Set(this.items
      .filter(item => item.showInSpeakerIdForm)
      .map(item => item.moment?.actualSpeaker as SpeakerModel));

    return [...uniqueSpeakers] as SpeakerModel[];
  }
  // #endregion

  // #region Comments
  @computed
  get activeCommentPool(): Comment[] {
    return this.items
      .filter(item => item.showInCommentsSection && item.isComment)
      .map(item => item.comment!);
  }

  @computed
  get allActiveComments(): Comment[] {
    return allActiveComments(this.activeCommentPool, Math.round(this.currentTime));
  }

  @computed
  get activeComment(): Comment | null {
    return firstActiveComment(
      this.activeCommentPool, Math.round(this.currentTime));
  }

  @computed
  get activeReactionPool(): Reaction[] {
    return this.items
      .filter(item => item.isReaction)
      .map(item => item.reaction!);
  }

  @computed
  get activeReaction(): Comment | null {
    return firstActiveComment(
      this.activeReactionPool, Math.round(this.currentTime));
  }

  @computed
  get activeCommentOrReactionPool(): (Comment | Reaction)[] {
    return [
      ...this.activeCommentPool,
      ...this.activeReactionPool
    ];
  }

  @computed
  get activeCommentOrReaction(): Comment | Reaction | null {
    return firstActiveComment(
      this.activeCommentOrReactionPool, Math.round(this.currentTime));
  }
  // #endregion
}