import { Maybe, Result } from '../../../core';
import { error } from '../../../core/error';

export function parseKeenJsonResult<TDatum>(res: Maybe<string>): Result<TDatum> {
  try {
    if (!res)
      return [null, error('DataError', `Error parsing Keen JSON data: The input is not a string.`)];

    return [JSON.parse(res)];
  } catch (e) {
    const err = e as Error;
    return [null, error('DataError', `Error parsing Keen JSON data: ${err.message}.`)];
  }
}
