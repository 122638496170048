import { action, makeObservable, observable, runInAction } from 'mobx';
import { Maybe } from '../../core';
import { notifyError } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';

export class DownloadJobSlidesWindowState
  extends StoreNode {

  readonly nodeType = 'DownloadJobSlidesWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);

  @observable isLoading: boolean = false;
  @observable downloadUrl: string | null = null;

  jobId: Maybe<string>;

  @action
  async submit() {
    if (!this.jobId) {
      notifyError(this, 'Could not download the slides for this video');
    }
    
    this.isLoading = true;

    const [res, err] = await this.store.apiService.getJob({ id: this.jobId! });

    if (err) {
      notifyError(this, 'Could not download the slides for this video');
      return this.handleSubmitFinally();
    }

    runInAction(() => {
      if (res?.getJob?.slideDeckUrl) {
        this.downloadUrl = res.getJob?.slideDeckUrl;
      } else {  
        notifyError(this, 'Could not download the slides for this video');
        this.handleSubmitFinally();
      }  
    });
  }

  handleSubmitFinally() {
    runInAction(() => {
      this.isLoading = false;
      this.downloadUrl = null;
      this.close();
    })
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open({ jobId }: { jobId: string }) {
    this.dispatch('Overlays', 'openWindow', { name: 'DownloadJobSlidesWindow' });

    this.jobId = jobId;
  }

  @action
  close() {
    this.jobId = null;

    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}