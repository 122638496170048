import _assert from 'assert';

export function assert(condition: boolean, msg?: string | Error | undefined): asserts condition is true {
  return _assert(condition, msg);
}
export function assertNotNull<T>(arg: T, msg?: string | Error | undefined): asserts arg is NonNullable<T> {
  return assert(arg !== null && arg !== undefined, msg || `Expected value to not be null or undefined.`);
}

export function assertObject(arg: any, msg?: string | Error | undefined): asserts arg is NonNullable<object> {
  return assert(
    arg !== null &&
    arg !== undefined &&
    typeof arg === 'object',

    msg || `Expected value to be a non-null object.`);
}