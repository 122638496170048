import React from 'react';
import { observer } from 'mobx-react-lite';
import { ChevronDownSVGIcon } from '../player/components/playerSvg';
import classNames from 'classnames';

type Props = {
  isExpanded: boolean;
  className?: string;
  onExpand?: (evt: any) => void;
}

export const ExpandToggle = observer(({
  isExpanded,
  className,
  onExpand
}: Props) => {

  className = classNames("expand-toggle", className, {
    expanded: isExpanded
  })

  return <button className={className}
    type="button"
    onClick={(evt) => onExpand && onExpand(evt)}>
    <ChevronDownSVGIcon />
  </button>
});