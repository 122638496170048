import '../media/media.scss';
import './liveFeedWindow.scss';

import React, { CSSProperties } from 'react';
import { observer } from 'mobx-react-lite';
import { LiveStreamActivePlaylist } from '@clipr/lib';
import { Tooltip, Window, WindowHeader } from '../overlays';
import { Button } from '../input';
import { LiveFeedWindowState } from './liveFeedWindowState';
import { InfoSVGIcon } from '../svg';
import { Player } from '../player/player';
import { getJobLiveStreamPlaylistLabel } from '../../entities/job/jobLiveStreamPlaylist';
import { Config } from '../../config';
import { PlayerState } from '../player/playerState';

type Props = {
  model: LiveFeedWindowState;
  visible?: boolean;
};

export const LiveFeedWindow = observer((props: Props) => {
  const { model, visible } = props;
  const { job, window, isLoading, showFeedPlayer, playerGroup } = model;

  const activeStreamMessage = Config.liveFeedPage.activeStreamTooltipMessage;

  const previewStyle: CSSProperties = {
    ['--aspect-ratio' as any]: 9 / 16,
  };

  return (
    <Window
      className="live-feed-window"
      model={window}
      visible={visible}
      isLoading={isLoading}>
      <WindowHeader
        model={window}
        title="Manage Live Feed"
        showBackButton={showFeedPlayer} />
      <hr />

      <section className="window-content">
        <table className="job-info-fields">
          <tbody>
            <tr>
              <td className="with-tooltip">
                <p className="field-name">Active Stream</p>
                <Tooltip
                  content={activeStreamMessage}
                  className="popup-tip"
                  trigger="click">
                  <Button
                    className="info-button"
                    icon={<InfoSVGIcon />} />
                </Tooltip>
              </td>

              <td className="field-value">
                {job?.title}
              </td>
            </tr>

            <tr>
              <td className="field-name">Player Source</td>
              <td className="field-value">{job?.liveStream?.ingestUrl}</td>
            </tr>
            <tr>
              <td className="field-name">CLIPr RTMP</td>
              <td className="field-value">
                {job?.liveStream?.ingestUrl}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="feed-section">
          {playerGroup?.map((userPlayerState: PlayerState) => {

            const { playlistType } = userPlayerState;

            if (!job || !playlistType)
              return null;

            if (playlistType === LiveStreamActivePlaylist.External && !job.getPlaylistSource(playlistType))
              return null;

            return (
              <div
                key={getJobLiveStreamPlaylistLabel(playlistType)}
                className="live-feed-container">

                <div className="live-feed-card">
                  <div
                    className="card-preview aspect-ratio-box"
                    style={previewStyle}>

                    <div className="card-status progress dock status-done">
                      <Player model={userPlayerState} />
                    </div>
                  </div>
                </div>

                <div className="select-feed tmar4">
                  <p className="field-name">{`${getJobLiveStreamPlaylistLabel(playlistType)} Feed`}</p>
                  <Button
                    className="primary small"
                    label="Select feed"
                    disabled={job.source.liveStreamActivePlaylist === playlistType}
                    onClick={() => model.changeLiveSource(playlistType)} />
                </div>

                <div className="tmar4">
                  <p className="field-name small">HLS Stream Feed:</p>
                  <p className="field-value">{job.getPlaylistSource(playlistType)}</p>
                </div>
              </div >
            )
          })}
        </div >
      </section >
    </Window >
  );
});
