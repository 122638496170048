import React from 'react';
import { observer } from 'mobx-react-lite';
import { MomentModel } from '../../../entities';
import { useAnchor } from '../../../services/overlays';
import { TrainerTimelineState } from '../trainerTimelineState';
import classNames from 'classnames';
import { getMomentConfidenceThresholdColor } from '../../../entities/momentUtils';

type Props = {
  moment: MomentModel,
  model: TrainerTimelineState
}

export const TimelineMoment = observer(({
  model,
  moment
}: Props) => {

  const duration = moment.endTime - moment.startTime;

  const hiddenTranscript = !!(moment.isTranscript && moment.minConfidencePercentage) && (moment.minConfidencePercentage > model.confidence);

  const color = moment.minConfidencePercentage && getMomentConfidenceThresholdColor(moment.minConfidencePercentage);

  const className = classNames("track-item", (moment.source || '').toLowerCase(), (moment.clipType || '').toLowerCase(), {
    'user-visible': moment.visibleToConsumer,
    'user-hidden': !moment.visibleToConsumer,
    'confidence-hidden': hiddenTranscript,
    'confidence-active': !hiddenTranscript && !!color
  });

  const style = {
    '--border-color': color,
    left: (moment.startTime / model.durationLayout) * 100 + '%',
    width: (duration / model.durationLayout) * 100 + '%'
  };

  const isDisabled = !model.sourcesFilter.has(moment.source) || hiddenTranscript;

  // TODO: create anchor only when opening window
  const [anchorRef] = useAnchor({
    target: model,
    id: 'timeline.moment#' + moment.id
  });

  return (
    <div
      ref={anchorRef as any}
      className={className}
      style={style}
      aria-disabled={isDisabled}
      aria-selected={model.selectedMomentIds.includes(moment.id)}
      onClick={evt => model.handleTimelineMomentClick(evt, moment)}>
    </div>
  );
});