import React, { ReactNode } from 'react';
import classNames from 'classnames';

type Props = {
  className?: string,
  children: ReactNode
};

export const Mention = (props: Props) => {
  const { className, children } = props;
  const mentionClass = classNames('mention', className);

  return (
    <span className={mentionClass}>
      {children}
    </span>
  );
};