import { action, computed, makeObservable, observable } from 'mobx';
import { StoreNode } from '../../store';
import { Store } from '../../store/store';
import { InputState, input } from '../input';
import { SyntheticEvent } from 'react';
import Routes from '../../routes';
import { SearchResultsState } from '../../pages/searchResultsPage';
import { Team } from '../../entities';
import { ChatGptSearchResultPageState } from '../../pages/chatGptSearchResultPage/chatGptSearchResultPageState';
import { isNonEmptyString } from '../../core';

export enum SearchMode {
  Initialized = 'Initialized',
  Ask = 'Ask',
  Suggestions = 'Suggestions'
}

export class SearchBarState
  extends StoreNode { 
    constructor(store: Store) {
      super(store);
      makeObservable(this);

      this.model = input(this, {
        name: 'searchOptionsInput',
        onFocus: this.handleFocus,
      });
    }

   readonly model: InputState;
    @observable searchMode: SearchMode = SearchMode.Initialized;
    @observable isFocused: boolean = false;
    @observable teamId: string | null = null;

    @computed get team(): Team | null {
      return this.store.teamManager.getTeam(this.teamId);
    }

    @computed
    get cliprGptEnabled() {
      const { user } = this.store;
      const { localUser } = this.store.profilePage;
      return this.team?.cliprGptEnabled ?? localUser?.cliprGptEnabled ?? user?.cliprGptEnabled;
    }

    @computed 
    get showOptions() {
      return !!this.model.value && this.isFocused;
    }

    @computed
    get state() {
      return this.searchMode === SearchMode.Ask ? 
      this.store.chatGptSearchResultPageState : this.store.searchResultsPage;
    } 

    @action handleFocus = (e: any) => {
      this.isFocused = true;
    }
  
    @action
    setSearchMode = (searchMode: SearchMode) => {
      this.searchMode = searchMode;
    }

    @action 
    setTeamId = (teamId: string | null) => {
      this.teamId = teamId;
    }

    @action
    onSubmit = (e: SyntheticEvent, replaceOnSubmit: boolean = false) => {
      e.preventDefault();
      this.state.submit(replaceOnSubmit, this.teamId);
    };
  
    @action
    onClear = (e: SyntheticEvent, replaceOnSubmit: boolean = false) => {
      e.preventDefault();
  
      // TODO: fix, risky dependency to location 
      const { pathname } = window.location;
      if (
        pathname === Routes.search ||
        pathname === Routes.librarySearchWidget()) {
        this.onSubmit(e, replaceOnSubmit);
        return;
      }
  
      if (this.searchMode === SearchMode.Suggestions) {
        this.reset();
        return;
      }
    };

    @action
    reset = () => {
      this.model.clear();
      this.searchMode = SearchMode.Initialized;
    }
  
    @action
    handleOnSearchClick = (evt: React.MouseEvent) => {
      evt.stopPropagation();
      this.searchMode = SearchMode.Suggestions;
      (this.state as SearchResultsState).model.loadValue(this.model.value);
      (this.state as SearchResultsState).model.handleFocus();

      setTimeout(() => {
        const component: HTMLElement | null = document.querySelector('[data-focus-id=search]');
        component?.focus();
      }, 0);
    }
  
    @action
    handleOnAskClick = (evt: React.MouseEvent, value?: string) => {
      evt.stopPropagation();
      const submittedValue = value ?? this.model.value;

      if (!submittedValue || !isNonEmptyString(submittedValue)) {
        return;
      }

      this.searchMode = SearchMode.Ask;
      (this.state as ChatGptSearchResultPageState).submit(false, this.teamId, submittedValue);
    }
  }