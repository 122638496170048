import {
  CreateBookmarkDocument,
  CreateBookmarkListDocument,
  DeleteBookmarkDocument,
  DeleteBookmarkListDocument,
  StartJobDocument,
  UpdateBookmarkListDocument,
  UpdateJobDocument,
  ChangeJobOwnerDocument,
  JoinTeamByInvitationDocument,
  CopyJobDocument,
  CreateTeamDocument,
  InviteTeamMemberDocument,
  DeleteTeamMemberDocument,
  UpdateTeamInvitationDocument,
  DeleteTeamInvitationDocument,
  UpdateProfileDocument,
  GetProfilePictureUploadUrlDocument,
  AddSpeakerDocument,
  UpdateMomentDocument,
  DeleteMomentDocument,
  AddMomentDocument,
  UpdateSpeakerDocument,
  AddJobToTeamDocument,
  RemoveJobFromTeamDocument,
  ArchiveJobDocument,
  UpdateTrackDocument,
  GetSpeakerPictureUploadUrlDocument,
  DeleteTrackDocument,
  GetAvatarUploadUrlDocument,
  GetCoverUploadUrlDocument,
  GetLogoUploadUrlDocument,
  UpdateTeamDocument,
  DeleteTeamDocument,
  UpdateTeamMemberRoleDocument,
  LeaveTeamDocument,
  UpgradeDocument,
  UpdateJobSourceDocument,
  MergeTracksDocument,
  AddTrackDocument,
  AddCommentDocument,
  AddReactionDocument,
  AddReplyDocument,
  EditCommentDocument,
  DeleteCommentDocument,
  ConnectExternalLibraryDocument,
  DeleteExternalLibraryDocument,
  GetJobThumbnailUploadUrlDocument,
  UpdateNotificationsSubscriptionDocument,
  DeleteNotificationsSubscriptionSubscribedEventDocument,
  StartLiveJobDocument,
  UpdateLiveJobSourceDocument,
  DuplicateTrackDocument,
  CreateNotificationsSubscriptionDocument,
  StopLiveJobDocument,
  ConfirmSpeakerPredictionDocument,
  UpdateTeamMemberDocument,
  ExportClipsDocument,
  CreateMultipartUploadDocument,
  GetGptAnswerDocument,
  CompleteMultipartUploadDocument
} from '@clipr/lib';

import { DocumentNode, print } from 'graphql';
import { ApiMutationName } from './apiSchema';

export const ApiMutations: { [key in ApiMutationName]?: string } = {};

const mutation = (name: ApiMutationName, doc: DocumentNode) => {
  return ApiMutations[name] = print(doc);
}

mutation('createBookmarkList', CreateBookmarkListDocument);
mutation('updateBookmarkList', UpdateBookmarkListDocument);
mutation('deleteBookmarkList', DeleteBookmarkListDocument);
mutation('createBookmark', CreateBookmarkDocument);
mutation('deleteBookmark', DeleteBookmarkDocument);
mutation('exportClips', ExportClipsDocument);

mutation('startJob', StartJobDocument);
mutation('updateJob', UpdateJobDocument);
mutation('changeJobOwner', ChangeJobOwnerDocument);
mutation('copyJob', CopyJobDocument);
mutation('getJobThumbnailUploadURL', GetJobThumbnailUploadUrlDocument);
mutation('addMoment', AddMomentDocument);
mutation('updateMoment', UpdateMomentDocument);
mutation('deleteMoment', DeleteMomentDocument);
mutation('addTrack', AddTrackDocument);
mutation('updateTrack', UpdateTrackDocument);
mutation('mergeTracks', MergeTracksDocument);
mutation('duplicateTrack', DuplicateTrackDocument);

mutation('startLiveJob', StartLiveJobDocument);
mutation('stopLiveJob', StopLiveJobDocument);
mutation('updateLiveJobSource', UpdateLiveJobSourceDocument);

mutation('addSpeaker', AddSpeakerDocument);
mutation('updateSpeaker', UpdateSpeakerDocument);
mutation('getSpeakerPictureUploadUrl', GetSpeakerPictureUploadUrlDocument);
mutation('confirmSpeakerPrediction', ConfirmSpeakerPredictionDocument);
mutation('deleteTrack', DeleteTrackDocument);
mutation('addJobToTeam', AddJobToTeamDocument);
mutation('removeJobFromTeam', RemoveJobFromTeamDocument);
mutation('archiveJob', ArchiveJobDocument);
mutation('updateJobSource', UpdateJobSourceDocument);

mutation('createTeam', CreateTeamDocument);
mutation('deleteTeam', DeleteTeamDocument);
mutation('updateTeam', UpdateTeamDocument);
mutation('leaveTeam', LeaveTeamDocument);
mutation('updateTeamMemberRole', UpdateTeamMemberRoleDocument);
mutation('updateTeamMember', UpdateTeamMemberDocument);
mutation('deleteTeamMember', DeleteTeamMemberDocument);
mutation('inviteTeamMember', InviteTeamMemberDocument);
mutation('getAvatarUploadUrl', GetAvatarUploadUrlDocument);
mutation('getLogoUploadUrl', GetLogoUploadUrlDocument);
mutation('getCoverUploadUrl', GetCoverUploadUrlDocument);
mutation('joinTeamByInvitation', JoinTeamByInvitationDocument);
mutation('updateTeamInvitation', UpdateTeamInvitationDocument);
mutation('deleteTeamInvitation', DeleteTeamInvitationDocument);

mutation('upgrade', UpgradeDocument);
mutation('updateProfile', UpdateProfileDocument);
mutation('getProfilePictureUploadUrl', GetProfilePictureUploadUrlDocument);

mutation('createNotificationsSubscription', CreateNotificationsSubscriptionDocument);
mutation('deleteNotificationsSubscriptionSubscribedEvent', DeleteNotificationsSubscriptionSubscribedEventDocument);
mutation('updateNotificationsSubscription', UpdateNotificationsSubscriptionDocument);

mutation('addComment', AddCommentDocument);
mutation('addReaction', AddReactionDocument);
mutation('addReply', AddReplyDocument);
mutation('editComment', EditCommentDocument);
mutation('deleteComment', DeleteCommentDocument);

mutation('connectExternalLibrary', ConnectExternalLibraryDocument);
mutation('deleteExternalLibrary', DeleteExternalLibraryDocument);

mutation('getGPTAnswer', GetGptAnswerDocument);

mutation('createMultipartUpload', CreateMultipartUploadDocument);
mutation('completeMultipartUpload', CompleteMultipartUploadDocument);
