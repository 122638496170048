
// import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';
import { Nullable } from '../../core';
import { DateTime } from 'luxon';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { action, makeObservable, observable } from 'mobx';

const TIMEOUTS: { [key in NotificationType]: number } = {
  'default': 5000,
  'info': 5000,
  'loading': 0,
  'success': 3000,
  'warning': 3000,
  'error': 7000
}

export type NotificationType =
  'default' |
  'loading' |
  'success' |
  'warning' |
  'info' |
  'error';

export interface INotification {
  id: string,
  createdTime: DateTime,
  duration: number,
  content: string,
  description?: string,
  type: NotificationType
}

export class NotificationService
  extends StoreNode {

  @observable notification: Nullable<INotification> = null;
  @observable isVisible: boolean = false;

  private hideTimeoutId: Nullable<ReturnType<typeof setTimeout>> = null;

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  invoke(type: string, payload: string | Partial<INotification>) {
    const content: string = (typeof payload === 'object' ? payload.content : payload) || '(Empty notification)';

    switch (type) {
      case 'notify':
        this.notify(content);
        break;

      case 'notifyLoading':
        this.notify(content, 'loading');
        break;

      case 'notifyError':
        this.notify(content, 'error');
        break;

      case 'notifySuccess':
        this.notify(content, 'success');
        break;

      case 'notifyWarning':
        this.notify(content, 'warning');
        break;

      default:
        break;
    }
  }

  notify(content: string, type: NotificationType = 'info') {

    // clear the current timeout
    const prevId = this.hideTimeoutId;
    if (typeof prevId === 'number')
      clearTimeout(prevId);

    let timeout = TIMEOUTS[type || 'default'];

    let notification = {

      id: uuid(),
      createdTime: DateTime.utc(),
      duration: timeout / 1000,
      content: content,
      type
    }

    // initialize a new timeout
    this.notification = notification;
    this.isVisible = true;

    if (timeout)
      this.hideTimeoutId = setTimeout(this.hide, timeout);
  }

  @action
  hide = () => {
    this.isVisible = false;

    // for now we don't use React transitions, and we only hide the notification using CSS
    // this means that we need to leave the notification object set on the service, so that
    // the message doesn't disappear during the transition
  }
}