import React from 'react';
import { observer } from 'mobx-react-lite';
import { InvalidPasswordMessage } from '../../../services/auth/auth0Utils';

export const InvalidPasswordFeedback = observer(
  ({ message }: { message: InvalidPasswordMessage | string }) => {
    if (!message) return null;

    if (typeof message === 'string') return <>{message}</>;

    return (
      <>
        <p className="feedback-title">{message.title}</p>
        <ul className="feedback-rule-list">
          {message.rules?.map((rule) => (
            <li key={rule.title} className="feedback-rule">
              {rule.title}
              <ul className="feedback-rule-items">
                {rule.items.map((item) => (
                  <li key={item} className="feedback-rule-item">
                    {item}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </>
    );
  }
);
