import './input.scss';
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { ReactEventHandler, useCallback } from "react";
import { InputState } from ".";
import { CheckSVGIcon, MinusSVGIcon } from "../svg";
import { assert, Maybe, ObjectLiteral } from "../../core";

type Props = {
  checked?: boolean,
  className?: string | ObjectLiteral,
  label?: Maybe<string>,
  disabled?: boolean,
  isFocused?: boolean,
  isHovered?: boolean,
  readOnly?: boolean,
  name: string,
  status?: Maybe<string>,
  statusMessage?: Maybe<string>,
  multiple?: Maybe<boolean>,
  deselectCheck?: boolean,
  onChange: ReactEventHandler
}

export const CheckboxInput = observer(({
  checked,
  className,
  label,
  isFocused,
  isHovered,
  name,
  status,
  statusMessage,
  disabled,
  multiple,
  deselectCheck,
  onChange,
  ...props
}: Props) => {

  className = classNames('checkbox-input input', className, {
    'focus': isFocused,
    'hover': isHovered,
    'multiple': multiple,
    'deselect-all': deselectCheck
  });

  return (<div className={className}
    role="checkbox"
    aria-disabled={disabled}
    aria-checked={checked}>
    <div className="box">
      {!deselectCheck ? (
        <div className="checked-icon">
          <CheckSVGIcon />
        </div>
      ) : (
        <div className="checked-icon">
          <MinusSVGIcon />
        </div>
      )}
    </div>
    {label &&
      <span className="text label">{label}</span>}

    <input
      name={name}
      type="checkbox"
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      role="presentation"
      {...props} />

    {/* {label &&
      <span className="text label">{label}</span>} */}
  </div>)

})

type ControlledProps =
  React.InputHTMLAttributes<HTMLInputElement> & {
    value?: string,
    label?: string,
    model: InputState,
    showEmptyItem?: Maybe<boolean>,
    className?: string | ObjectLiteral
  };

/**
* Wrapper for CheckboxInput in which the entire state and all the handlers are provided through an InputState object.
*/
export const CheckboxInputControlled = observer(({
  model,
  className,
  ...props
}: ControlledProps) => {

  assert(model instanceof InputState,
    `The 'model' passed to CheckboxInputControlled must be an instance of InputState. Instead received ${model}.`);

  const handleChange = useCallback(evt =>
    model.handleChange(evt, evt.target.checked),
    [model]);

  className = model.getClassName('checkbox-input-controlled', { className });

  return <CheckboxInput
    name={model.name}
    className={className}
    checked={model.value && model.value !== 'false' ? true : false}
    placeholder={model.placeholder}
    disabled={model.disabled}
    label={model.label}
    isHovered={model.isHovered}
    isFocused={model.isFocused}
    onChange={handleChange}
    onFocus={model.handleFocus}
    onBlur={model.handleBlur}
    onPointerEnter={model.handlePointerEnter}
    onPointerLeave={model.handlePointerLeave}
    status={model.status}
    statusMessage={model.statusMessage}
    multiple={model.multipleValues}
    {...props} />
});