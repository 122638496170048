import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { AuthFlowResponse, AuthFlowResponseType } from '../../services/auth/authFlowSchema';
import { LoadPage } from '../layout';
import { Routes } from '../../routes';
import { Redirect } from 'react-router-dom';
import { ErrorPage, WidgetErrorPage } from '../../pages/error';
import { LocationDescriptor } from 'history';
import { useRoutingService } from '../../services/routing/routingHooks';
import { Error } from '../../core/error';
import { useStore } from '../../store/storeHooks';
import { notifyError } from '../../services/notifications';
import { useKernel } from '../../kernel/kernelHooks';
import { ClientMode } from '../../kernel/kernelSchema';

type Props = {
  response?: AuthFlowResponse | null;
  error?: Error | null;
}

export const AuthFlowResponseInterpreter = observer(({
  response,
  error
}: Props) => {

  const store = useStore();
  const kernel = useKernel();
  const { notificationService } = store;

  const responseType: AuthFlowResponseType | null = response?.responseType ?? null;
  const responseError: Error | null = response?.error ?? null;

  const redirectPath = response?.redirectPath;
  let descriptor: LocationDescriptor = {};
  if (redirectPath) {
    descriptor.state = {
      redirectPath
    }
  }

  const routingService = useRoutingService();

  if (error) {
    switch (kernel.clientMode) {
      case ClientMode.Widget:
        return (
          <WidgetErrorPage error={error} />
        );
      default:
        return (
          <ErrorPage code={error.code} message={error.message} />
        );
    }
  }

  useEffect(() => {
    if (responseError)
      notifyError(notificationService, responseError.message);
  }, [responseError, notificationService]);

  switch (responseType) {
    case null:
    case AuthFlowResponseType.AwaitRedirect:
      return (
        <LoadPage />
      );

    case AuthFlowResponseType.RedirectToLoginPage:
      return (
        <Redirect to={Routes.login()} />
      );

    case AuthFlowResponseType.RedirectToOnboardPage:
      return (
        <Redirect to={Routes.onboard()} />
      );

    case AuthFlowResponseType.RedirectToExternalLoginWidget:
      return (
        <Redirect to={Routes.externalLoginWidget()} />
      );

    case AuthFlowResponseType.RedirectToProxyLoginWidget:
      return (
        <Redirect to={Routes.proxyLoginWidget()} />
      );

    case AuthFlowResponseType.RedirectToLastPrivateRoute: {
      const redirPath = routingService.storage.lastPrivatePath;
      return (
        <Redirect to={redirPath ?? Routes.defaultRoute()} />
      );
    }

    case AuthFlowResponseType.RedirectToLastWidgetRoute: {
      const redirUrl = routingService.lastWidgetRoute?.relativeUrl;
      if (!redirUrl) {
        const err = new Error('InternalError', `Invalid redirect state.`);
        return (
          <WidgetErrorPage error={err} />
        );
      }

      return (
        <Redirect to={redirUrl} />
      );
    }
    
    case AuthFlowResponseType.RedirectToSessionWidgetRoute: {
      const redirUrl = routingService.sessionWidgetRoute?.relativeUrl;
      if (!redirUrl) {
        const err = new Error('InternalError', `Invalid redirect state.`);
        return (
          <WidgetErrorPage error={err} />
        );
      }

      return (
        <Redirect to={redirUrl} />
      );
    }
  }

  // TODO: maybe add error screen
  return null;
});