import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../../components/overlays';
import { Button } from '../../components/input';
import { LeaveTeamWindowState } from './leaveTeamWindowState';

type Props = {
  model: LeaveTeamWindowState;
  visible?: boolean;
};

export const LeaveTeamWindow = observer(({ model, visible }: Props) => {
  return (
    <Window model={model.window} visible={visible} isLoading={model.isLoading}>
      <WindowHeader title="Leave library" model={model.window} />

      <hr />

      <div className="window-content">
        {model.isOwner ? (
          <>
            <p className="p-bold">You can't leave {model.team?.name}.</p>
            <p>You are the owner of the library.</p>
          </>
        ) : (
          <>
            <p className="p-bold">
              Are you sure you want to leave {model.team?.name}?
            </p>
            <p>
              You will lose access to all library content and a new invitation will
              be required to rejoin.
            </p>
          </>
        )}
      </div>

      <hr />

      <footer className="window-footer">
        <div className="btn-group right">
          {model.isOwner ? (
            <Button
              label="Cancel"
              className="primary btn-outline medium"
              onClick={() => model.close()}
              disabled={model.isLoading}
            />
          ) : (
            <>
              <Button
                label="Cancel"
                className="primary btn-outline medium"
                onClick={() => model.close()}
                disabled={model.isLoading}
              />

              <Button
                label="Leave"
                className="primary medium"
                onClick={() => model.leaveTeam()}
                disabled={model.isLoading || model.isOwner}
              />
            </>
          )}
        </div>
      </footer>
    </Window>
  );
});
