import assert from 'assert';
import { ReactEventHandler, ReactNode } from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import find from 'lodash/find';
import classNames from 'classnames/dedupe';
import { Nullable, ObjectLiteral, Position, VerticalPosition } from '../../core';
import { StoreNode, BindingProps } from '../../store';
import { Store } from '../../store/store';
import { SelectorItem } from './selectInput';

const isEmptyValue = (val: string | null | undefined) =>
  (typeof val === 'string' && val.trim() === '') ||
  val === null ||
  val === undefined ||
  (typeof val === 'boolean' && val === false);


const defaultEqualityComparer = (a: any, b: any) => {

  if ((a && typeof a === 'object') && (b && typeof b === 'object'))
    return a.id === b.id; // defaultEqualityComparer(a.id, b.id)

  if (!a && !b) // '', 0, false should be treated as null
    return true;

  return a === b;
}

export type InputStatus =
  'none' |
  'error' |
  'warning' |
  'success' |
  'loading';

export type InputChangeEventHandler = (input: InputState, evt: React.ChangeEvent) => void;

export type InputStateProps = {
  name: string
} &

  Partial<{
    onChange: InputChangeEventHandler;
    onBlur: ReactEventHandler
    onFocus: ReactEventHandler
    onSubmit: ReactEventHandler
    onSubmitResolve: ReactEventHandler,
    onSubmitReject: ReactEventHandler,
    onReset: ReactEventHandler
    onPointerEnter: ReactEventHandler
    onPointerLeave: ReactEventHandler
    onKeyDown: ReactEventHandler,
  }> &

  BindingProps<{
    value: string | boolean | SelectorItem | number,
    initialValue: string | boolean | SelectorItem | number,
    label: string,
    isRequired: boolean,
    placeholder: string,
    selectorItems: any[],
    multiline: boolean,
    charCountMax: number,

    showContentVisibilityButton: boolean,
    showStatus: boolean,
    showStatusMessage: boolean,
    showErrorIcon: boolean,
    showWarningIcon: boolean,
    showSuccessIcon: boolean,
    status: InputStatus,
    statusMessage: string,
    labelPosition: Position,
    feedbackPosition: VerticalPosition,

    info: string,
    error: string,
    warning: string,
    success: string,
    loading: string,

    disabled: boolean,
    readOnly: boolean,

    equalityComparer: any,
    export: any
  }, InputState>;

/**
 * Centralized, observable model for keeping the entire state and interaction history of an input.
 */
export class InputState
  extends StoreNode {

  readonly nodeType = 'Input';

  constructor(store: Store, props: InputStateProps) {
    super(store, props);
    makeObservable(this);

    const { name } = props;
    assert(typeof name === 'string' && name.length > 0,
      `Input name must be a non-empty string.`);

    this.name = props.name;
  }

  // #region Resolved props
  // -------

  @computed get placeholder(): string | null {
    return this.resolvedProps.placeholder;
  }

  @computed get label(): string | null {
    return this.resolvedProps.label;
  }

  @computed get multiline(): boolean {
    return this.resolvedProps.multiline || false;
  }

  @computed get initialValue(): any {
    return this.resolvedProps.initialValue;
  }

  @computed get selectorItems(): any {
    return this.resolvedProps.selectorItems;
  }

  @computed get isRequired(): boolean {
    return this.resolvedProps.isRequired;
  }

  @computed get charCountMax(): number | undefined {
    return this.resolvedProps.charCountMax;
  }
  // #endregion

  // @ts-ignore
  readonly props: InputStateProps;

  readonly name: string;
  @observable value: Nullable<string> = null;

  get normValue(): string | null {
    return (typeof this.value === 'object'
      //@ts-ignore
      ? this.value?.value :
      this.value) || null;
  }

  get normInitialValue(): string | null {
    return (typeof this.initialValue === 'object'
      //@ts-ignore
      ? this.initialValue?.value :
      this.initialValue) || null;
  }

  get displayValue(): string | null {
    return (find(this.selectorItems, ['value', this.normValue]))?.label ||        // if value is string for object items
      (typeof this.value === 'object' ? (this.value as any)?.label : this.value)  // if value is object it already has the label
  }

  // @observable values: Nullable<any[]> = null;
  @observable isFocused = false;
  @observable isNativeFocused = false;
  @observable focusCount = 0;

  @observable multipleValues = false;
  @observable isLoadingValue = false;

  @computed get blurCount() { return this.focusCount - (+this.isFocused); }
  @computed get isFocusedOnce() { return this.focusCount > 0; }
  @computed get isBlurredOnce() { return this.blurCount > 0; }

  @observable isHovered = false;
  @observable mouseEnterCount = 0;
  @observable mouseLeaveCount = 0;
  @computed get isHoveredOnce() { return this.mouseEnterCount > 0; }

  @observable isChanged = false;
  @observable isChangedSinceLastSubmit = false;
  @observable changeCount = 0;
  @computed get isChangedOnce() { return this.changeCount > 0; }

  @observable isSubmitting = false;
  @observable isSubmitRejected = false;
  @observable isSubmitResolved = false;
  @computed get isSubmitCompleted() { return this.isSubmitResolved || this.isSubmitRejected }
  @observable submitCount = 0;
  @observable submitResolveCount = 0;
  @observable submitRejectCount = 0;
  @computed get isSubmittedOnce() { return this.submitCount > 0; }
  @computed get isSubmitResolvedOnce() { return this.submitResolveCount > 0; }
  @computed get isSubmitRejectedOnce() { return this.submitRejectCount > 0; }

  @computed get equalityComparer() {
    return this.getResolvedProp('equalityComparer', defaultEqualityComparer);
  }

  @computed get isDirty() { return !this.equalityComparer(this.normValue, this.normInitialValue); }
  @computed get isEmpty() { return isEmptyValue(this.value); }

  @computed
  get isTouched(): boolean {
    return (
      this.isBlurredOnce ||
      this.isChangedOnce ||
      this.isSubmittedOnce);
  }

  @computed get disabled(): boolean {
    return this.getResolvedProp('disabled', this.loading || this.isSubmitting);
  }
  @computed get readOnly(): boolean {
    return this.getResolvedProp('readOnly', false);
  }

  @computed get error(): ReactNode | null {
    let fallback = null;
    if (this.isRequired && this.isEmpty) {
      fallback = 'Required field'
    } else if (this.value && this.charCountMax && this.charCountMax < this.value.length) {
      fallback = `Max ${this.charCountMax} characters.`
    }
    return this.getResolvedProp('error', fallback);
  }
  @computed get warning(): ReactNode | null {
    return this.getResolvedProp('warning');
  }
  @computed get success(): ReactNode | null {
    return this.getResolvedProp('success');
  }
  @computed get loading(): ReactNode | null {
    return this.getResolvedProp('loading');
  }
  @computed get info(): ReactNode | null {
    return this.getResolvedProp('info');
  }

  @computed
  get showStatus(): boolean {
    return this.getResolvedProp('showStatus',
      (this.isTouched && !this.isFocused) || this.loading) // fallback;
  }

  @computed
  get hasVisibleError(): boolean {
    return this.getResolvedProp('hasVisibleError', this.status === 'error' && this.showStatus) //(this.isTouched && !this.isFocused)
  }

  @computed
  get showStatusIcons(): boolean {
    return this.getResolvedProp('showStatusIcons', this.showStatus) // fallback;
  }

  @computed
  get showStatusMessage(): boolean {
    return this.getResolvedProp('showStatusMessage',
      (this.isTouched && !this.isFocused)) // fallback;
  }

  @computed
  get showContentVisibilityButton(): boolean {
    return this.resolvedProps.showContentVisibilityButton;
  }

  @computed
  get showErrorIcon(): boolean {
    return this.resolvedProps.showErrorIcon;
  }

  @computed
  get showWarningIcon(): boolean {
    return this.resolvedProps.showWarningIcon;
  }

  @computed
  get showSuccessIcon(): boolean {
    return this.resolvedProps.showSuccessIcon;
  }

  // @computed
  // get valueDelta(): number {
  //   if (this.values && this.values.length > 0)
  //     return this.values?.filter(el => el !== this.value)?.length || 0;

  //   return 0;
  // }

  // @computed
  // get valueSetOutput(): any {
  //   const { value, values } = this;

  //   if (!values || (values && values.length === 0))
  //     return null;

  //   let distinctValues = [...new Set(values?.map(val => val))];

  //   return distinctValues.length > 1 ? 'Multiple Values' : distinctValues[0];
  // }


  /** Gets the status to be displayed, in the order of importance. */
  @computed
  get status(): InputStatus | null {
    const fallback = (() => {
      if (this.loading) return 'loading';
      if (this.error) return 'error';
      if (this.warning) return 'warning';
      if (this.success) return 'success';
      if (this.info) return 'info';
      return null;
    })();

    return this.getResolvedProp('status', fallback);
  }

  @computed
  get statusMessage(): string | null {
    const fallback = (() => {
      switch (this.status) {
        case 'loading': return this.loading;
        case 'error': return this.error;
        case 'warning': return this.warning;
        case 'success': return this.success;
        default: return this.info;
      }
    })();
    return this.getResolvedProp('statusMessage', fallback);
  }

  @computed get labelPosition(): VerticalPosition {
    return this.getResolvedProp('labelPosition', 'topStart');
  }
  @computed get feedbackPosition(): VerticalPosition {
    return this.getResolvedProp('feedbackPosition', 'bottom');
  }

  getClassName(typeClassName: string, props: { className?: string | ObjectLiteral }) {

    let status = (this.showStatus && this.status) || '';
    let statusMessage = (this.showStatusMessage && this.statusMessage) || '';

    return classNames(
      typeClassName,
      typeClassName !== 'button-input' ? 'input' : null,
      props.className,
      status,
      {
        'status': !!status,
        'status-message': !!statusMessage,
        'hover': this.isHovered,
        //'focus': this.isFocused,
        'dirty': this.isDirty,
        'empty': this.isEmpty,
        'touched': this.isTouched,
        'changed': this.isChanged
      });
  }

  @action
  reset() {
    Object.assign(this, {
      focusCount: this.isFocused ? 1 : 0,

      mouseEnterCount: 0,
      mouseLeaveCount: 0,

      isSubmitting: false,
      submitCount: 0,
      submitResolveCount: 0,
      submitRejectCount: 0,

      isChanged: false,
      changeCount: 0
    });

    this.value = this.initialValue || '';
  }

  @action
  clear() {
    this.reset();

    Object.assign(this, {
      isFocused: false,
      isHovered: false
    });

    this.setProp('initialValue', null);
    this.value = null;
  }

  @action
  handleChange(evt: React.ChangeEvent | null, value: any) {
    this.value = value;
    this.changeCount++;
    this.isChanged = true;
    this.isChangedSinceLastSubmit = true;
    this.isSubmitRejected = false;
    this.isSubmitResolved = false;
    this.invokeHandler('onChange', evt);
  }

  @action
  handleFocus = (evt?: React.FocusEvent) => {
    this.isNativeFocused = true;
    this.isFocused = true;
    this.focusCount++;
    this.invokeHandler('onFocus', evt);
  }

  @action
  handleBlur = (evt?: React.FocusEvent) => {
    this.isNativeFocused = false;
    this.isFocused = false;
    this.invokeHandler('onBlur', evt);
  }

  @action
  handlePointerEnter = (evt?: React.PointerEvent) => {
    this.isHovered = true;
    this.mouseEnterCount++;
    this.invokeHandler('onPointerEnter', evt);
  }

  @action
  handlePointerLeave = (evt?: React.PointerEvent) => {
    this.isHovered = false;
    this.mouseLeaveCount++;
    this.invokeHandler('onPointerLeave', evt);
  }

  @action
  handleSubmit = () => {
    this.isSubmitting = true;
    this.isSubmitRejected = false;
    this.isSubmitResolved = false;
    this.isChangedSinceLastSubmit = false;
    this.submitCount++;
    this.invokeHandler('onSubmit');
  }

  @action
  handleSubmitResolve = () => {
    this.isSubmitting = false;
    this.isSubmitResolved = true;
    this.isChangedSinceLastSubmit = false;
    this.submitResolveCount++;
    this.invokeHandler('onSubmitResolve');
  }

  @action
  handleSubmitReject = () => {
    this.isSubmitting = false;
    this.isSubmitRejected = true;
    this.isChangedSinceLastSubmit = false;
    this.submitRejectCount++;
    this.invokeHandler('onSubmitReject');
  }

  @action
  // @ts-ignore
  invokeHandler(name: keyof InputStateProps, evt?: any) {
    const handler = this.getProp(name);
    if (typeof handler === 'function')
      handler(this, evt);
  }

  /** Sets both value and initialValue to the provided argument. */
  @action
  loadValue(value: any, invokeOnChange: boolean = false) {
    this.value = value;
    this.setProp('initialValue', value);
    if (invokeOnChange) this.invokeHandler('onChange');
  }

  @action
  setValueLoaded = () => {
    this.isLoadingValue = false;
  }

  // @action
  // loadValueSet(value: any[]) {
  //   this.values = value;
  //   this.setProp('initialValueSet', value);
  //   this.loadValue(this.valueSetOutput);
  // }

  export(): any {
    return this.getResolvedProp('export', this.value);
  }
}

/** Helper method for creating a new InputState object for the provided StoreNode. */
export const input = (node: StoreNode | Store, props: InputStateProps) => {
  let store: Store;
  if (node instanceof StoreNode)
    store = node.store;
  else
    store = node;

  return new InputState(store, props);
}