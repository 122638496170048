import './bookmarks.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { BookmarkList } from '../../entities';
import { BookmarkCard } from './bookmarkCard';
import { Grid } from '../layout';
import { SidebarBookmarkSVGIcon } from '../svg';

type Props = {
  model: BookmarkList;
};

export const BookmarkCardCatalog = observer((props: Props) => {
  const { model } = props;

  if (model.bookmarks.length > 0) {
    return (
      <section className="bookmark-card-catalog catalog">
        <div className="catalog-content catalog-viewport">
          {model.bookmarks.length > 0 && (
            <Grid className="catalog-grid">
              {model.bookmarks.map((bkm) => {
                return (
                  <li key={bkm.id} className="grid-item catalog-item">
                    <BookmarkCard model={bkm} />
                  </li>
                );
              })}
            </Grid>
          )}
        </div>
      </section>
    );
  }
  return (
    <section className="catalog empty-catalog">
      <div className="icon">
        <SidebarBookmarkSVGIcon />
      </div>
      <h1 className="headline-1">No bookmarks here</h1>
    </section>
  );
});
