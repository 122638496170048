import debounce from 'lodash/debounce';
import last from 'lodash/last';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import {
  AdminGetJobsFilterInput, AdminJobOrderByOption, Order, PageInfo, GetTeamsFilterInput, TeamOrderByOption, TeamProduct
} from '@clipr/lib';

import { ApiResult, ApiVariables } from '../api/apiSchema';
import { input, InputState } from '../components';
import { SortFieldValue } from '../components/input/sortFieldBtn';
import { assertNotNull, AsyncResult, Maybe, Nullable } from '../core';
import { notifyError } from '../services/notifications';
import { BindingProps, StoreNode } from '../store';
import { Store } from '../store/store';
import { SyncStatus } from '../store/syncSchema';
import { Team } from './team';
import { TeamCatalogItem } from './teamCatalogItem';
import { TeamProps } from '.';


export const TeamAvatarSize = {
  width: 100,
  height: 100
}

export const TeamCoverSize = {
  width: 200
}

export const TeamLogoSize = {
  width: 200
}

type Props = BindingProps<{
  pageSize?: number
}>

export class AdminTeamsCatalogSource
  extends StoreNode {

  readonly nodeType: 'AdminTeamsCatalogSource' = 'AdminTeamsCatalogSource';

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.searchBarModel = input(this, {
      name: 'searchInput',
      onChange: (e) => this.handleSearchInputChange(e),
    });
  }

  // #region Resolved props
  // -------
  @computed get pageSize(): number {
    return this.resolvedProps.pageSize;
  }
  // #endregion

  readonly items = observable.array<TeamCatalogItem>();
  readonly teamNamespaces = observable.array<string>();
  readonly searchBarModel: InputState;

  @observable syncStatus: SyncStatus = 'idle';

  @observable searchFilter: string | null = null;
  @observable teamProductFilter: TeamProduct | null = null;
  @observable teamNamespaceFilter: string | null = null;

  @observable sortField: TeamOrderByOption | null = 'name';
  @observable sortOrder: Order | null = 'asc';

  @observable pageInfo: PageInfo | null = null;

  @computed get lastItemCursor(): string | null {
    return last(this.items)?.cursor || null;
  }

  @computed get entities(): Team[] {
    return this.items.map(item => item.team);
  }

  @computed get isEndOfList(): boolean {
    return this.pageInfo ? !this.pageInfo.hasNextPage : false;
  }


  getSortFieldValue(field: AdminJobOrderByOption) {
    if (this.sortField !== field)
      return 'none';
    return this.sortOrder!;
  }

  @action
  setSortFieldValue(field: TeamOrderByOption, val?: SortFieldValue) {
    if (!val || val === 'none') {
      this.sortField = null;
      this.sortOrder = null;
    } else {
      this.sortField = field;
      this.sortOrder = val;
    }
    this.fetchDebounced();
  }


  getFilterFieldValue<T extends keyof AdminGetJobsFilterInput>(field: T): Nullable<AdminGetJobsFilterInput[T]> {
    switch (field) {
      case 'teamProduct':
        return this.teamProductFilter;

      case 'teamNamespace':
        return this.teamNamespaceFilter;
    }
    return null;
  }

  @action
  setFilterFieldValue<T extends keyof AdminGetJobsFilterInput>(field: T, val?: Maybe<AdminGetJobsFilterInput[T]>) {
    switch (field) {
      case 'teamProduct':
        this.teamProductFilter = (val as TeamProduct) || null;
        break;

      case 'teamNamespace':
        this.teamNamespaceFilter = (val as string);
        break;
    }
    this.fetchDebounced();
  }

  /** Makes a fresh fetch call, clearing any previous data and pagination state. */
  @action
  async fetchTeamNamespaces(): AsyncResult<string[]> {

    this.syncStatus = 'fetching';

    const [resp, err] = await this.store.api.getTeamNamespaces();

    if (err)
      return [null, err];
    assertNotNull(resp);

    const retItems: string[] = [];
    runInAction(() => {
      this.teamNamespaces.clear();

      const { getTeamNamespaces } = resp;
      getTeamNamespaces?.forEach(teamNamespace => {
        const { namespace } = teamNamespace!;
        if (namespace)
          this.teamNamespaces.push(namespace);
        retItems.push(namespace);
      });
    });

    return [retItems];
  }

  fetchDebounced = debounce(() => this.fetch(), 500);

  /** Makes a fresh fetch call, clearing any previous data and pagination state. */
  @action
  async fetch()
    : AsyncResult<TeamCatalogItem[]> {

    this.syncStatus = 'fetching';

    const [teamsRes, err] = await this.fetchRaw();

    if (err)
      return [null, err];
    assertNotNull(teamsRes);

    const retItems: TeamCatalogItem[] = [];
    runInAction(() => {
      this.items.clear();

      const { getTeams } = teamsRes;
      this.pageInfo = getTeams.pageInfo;

      getTeams.edges.forEach((edge, i) => {

        this.store.teamManager.insertAdminTeam(edge.node as TeamProps);

        const item = new TeamCatalogItem(this.store, {
          teamId: edge.node.id,
          cursor: edge.cursor,
          index: i
        });

        this.items.push(item);
        retItems.push(item);
      });
    });

    return [retItems];
  }

  @action
  async fetchMore()
    : AsyncResult<TeamCatalogItem[]> {

    this.syncStatus = 'fetchingMore';

    const [jobsRes, err] = await this.fetchRaw(true);
    if (err)
      return [null, err];
    assertNotNull(jobsRes);

    const retItems: TeamCatalogItem[] = [];
    runInAction(() => {
      const { getTeams } = jobsRes;
      this.pageInfo = getTeams.pageInfo;

      getTeams.edges.forEach((edge, i) => {

        this.store.teamManager.insertAdminTeam(edge.node as TeamProps);

        const item = new TeamCatalogItem(this.store, {
          teamId: edge.node.id,
          cursor: edge.cursor,
          index: i
        });

        this.items.push(item);
        retItems.push(item);
      });
    });

    return [retItems];
  }

  @action
  private getFetchArgs(more: boolean = false) {
    let args: ApiVariables<'getTeams'> = {
      coverSize: [TeamCoverSize],
      logoSize: [TeamLogoSize],
      avatarSize: [TeamAvatarSize],
    };
    let filter: GetTeamsFilterInput = {
      scope: 'Admin'
    };

    if (this.sortField && this.sortOrder) {
      args.sort = {
        field: this.sortField,
        order: this.sortOrder
      }
    }

    if (this.teamProductFilter) {
      filter.teamProduct = this.teamProductFilter;
    }

    if (this.teamNamespaceFilter) {
      filter.teamNamespace = this.teamNamespaceFilter === 'None' ? '' : this.teamNamespaceFilter!;
    }

    if (this.searchFilter) {
      filter._search = this.searchFilter || undefined;
    }

    if (Object.keys(filter).length > 0)
      args.filter = filter;

    args.first = this.pageSize;

    if (more && this.lastItemCursor)
      args.after = this.lastItemCursor;

    return args;
  }

  @action
  private async fetchRaw(more: boolean = false): ApiResult<'getTeams'> {
    const args: ApiVariables<'getTeams'> = this.getFetchArgs(more);

    const [teamsRes, err] = await this.store.api.getTeams(args);
    if (err) {
      runInAction(() => {
        this.syncStatus = 'error';
        notifyError(this, `Could not fetch teams because of an error.`);
      });
      return [null, err];
    }

    runInAction(() => {
      this.syncStatus = 'idle';
    });

    assertNotNull(teamsRes);
    return [teamsRes];
  }


  @action
  handleSearchInputChange(evt: any) {
    this.searchFilter = evt.value || null;
    this.fetchDebounced();
  }
}