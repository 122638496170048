import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { PlayerPencilSVGIcon } from '../../../components/svg';

type EditButtonProps = {
  className?: string;
  onClick?: (evt: any) => void;
  disabled?: boolean;
}

export const EditButton = observer(({
  className,
  onClick,
  disabled
}: EditButtonProps) => {

  className = classNames("edit-button btn btn-core btn-icon");

  return <button className={className}
    onClick={(evt) => onClick && onClick(evt)}
    disabled={disabled}>
    <PlayerPencilSVGIcon />
  </button>
});