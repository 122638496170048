import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { InputPlayerReactionName } from './playerReactionSchema';
import { OutputReactionsSVGIconLookup } from './reactionSvgIconLookup';
import { WatchMode } from '@clipr/lib';
import { LiveSVGIcon } from '..';

type Props = {
  className?: string,
  reactionName: InputPlayerReactionName,
  watchMode: WatchMode | null
}

export const PlayerReactionIcon = observer(({
  className,
  reactionName,
  watchMode,
}: Props) => {

  className = classNames('player-reaction-icon', className);

  const iconElem = OutputReactionsSVGIconLookup[reactionName];
  return (
    <span className={className}>
      {
        watchMode === WatchMode.Live &&
        <div className='live-svg-icon'>
          <LiveSVGIcon />
        </div>
      }
      {iconElem.component}
    </span>
  );
});