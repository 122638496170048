// TODO update reaction names
export type InputPlayerReactionName =
  'Love' |
  'Genius' |
  'Fire' |
  'Funny' |
  'Engaging' |
  'Confusing';

export type OutputPlayerReactionName =
  'Love' |
  'Genius' |
  'Fire' |
  'Funny' |
  'Engaging' |
  'Confusing' |

  'Hot' |
  'Rocket' |
  'Insightful' |
  'Smart' |
  'Celebrate';

export const InputPlayerReactionNames: InputPlayerReactionName[] = [
  'Love',
  'Genius',
  'Fire',
  'Funny',
  'Engaging',
  'Confusing',
];

export const OutputPlayerReactionNames: OutputPlayerReactionName[] = [
  'Love',
  'Genius',
  'Fire',
  'Funny',
  'Engaging',
  'Confusing',

  'Hot',
  'Rocket',
  'Insightful',
  'Smart',
  'Celebrate',
];