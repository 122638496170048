import { makeObservable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { Error } from '../../core/error';
import { AuthPermit } from '../auth/authPermit';
import { INIT_DEBUGGER, TRACE } from '../../core/debug/debugMacros';
import { ProxyFlowMessageType, ProxyFlowName, ProxyFlowResponseMessage, ProxyFlowResponseMessageData } from './proxySchema';
import { AsyncResult } from '../../core';
import { LibraryPermit } from '../libraries/libraryPermit';

export class ProxyClientConnection
  extends StoreNode {

  readonly nodeType = 'ProxyClientConnection';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    INIT_DEBUGGER(this);
  }

  get clientWindow(): Window | null {
    try {
      return window.opener;
    }
    catch (err) {
      return null;
    }
  }

  private async sendResponse<T extends ProxyFlowName>(flowName: T, data: ProxyFlowResponseMessageData<T>)
    : AsyncResult<ProxyFlowResponseMessage<T>> {

    const targetOrigin = window.location.origin;
    const targetWin = this.clientWindow;
    if (!targetWin)
      return [null, new Error('InternalError', `Connection has not been opened.`)];

    if (targetWin.origin !== targetOrigin)
      return [null, new Error('InternalError', `User has navigated away from the page.`)];

    TRACE(this, 'sendAuthorizeResult()', flowName, data);

    const msg: ProxyFlowResponseMessage<T> = {
      type: ProxyFlowMessageType.ProxyFlowResponse,
      payload: {
        flowName,
        data
      }
    }

    targetWin.postMessage(msg, targetOrigin);
    return [msg];
  }

  async sendAuthorizeResponse(permit: AuthPermit)
    : AsyncResult<ProxyFlowResponseMessage<ProxyFlowName.Authorize>> {

    return this.sendResponse(ProxyFlowName.Authorize, {
      permit: {
        idToken: permit.idToken,
        idTokenPayload: permit.idTokenPayload
      }
    });
  }

  async sendAuthorizeLibraryResponse(libraryPermit: LibraryPermit)
    : AsyncResult<ProxyFlowResponseMessage<ProxyFlowName.AuthorizeLibrary>> {

    const { libraryName, code } = libraryPermit;
    return this.sendResponse(ProxyFlowName.AuthorizeLibrary, {
      libraryPermit: {
        libraryName,
        code
      }
    });
  }

  async sendDeauthorizeResponse()
    : AsyncResult<ProxyFlowResponseMessage<ProxyFlowName.Deauthorize>> {

    return this.sendResponse(ProxyFlowName.Deauthorize, {});
  }
}