import { observable } from 'mobx';
import { AsyncResult, isDefinedObject, PromiseRelay, Result } from '../../core';
import { Error } from '../../core/error';
import { onWindowClosed } from '../../core/window';
import { Message } from '../../store';
import { ProxyFlowMessageType, ProxyFlowName, ProxyFlowNames } from './proxySchema';

type Params = {
  url: string;
  flowName: ProxyFlowName;
}

/**
 * Low level abstraction for interacting with the window opened by a ProxyServerConnection.
 * It exposes a promise which resolves only if a message having the expected type will be received.
 * Otherwise it will be rejected for any other reason, including if the window is closed before
 * receiving the expected message.
 */
export class ProxyServerHandle {

  @observable window: Window | null = null;

  private readonly abortController = new AbortController();
  private get abortSignal() {
    return this.abortController.signal;
  }

  private readonly promiseRelay = new PromiseRelay<Result<Message>>();

  get promise(): Promise<Result<Message>> {
    return this.promiseRelay.promise;
  }

  @observable url: string | null = null;
  @observable flowName: ProxyFlowName | null = null;

  async run(params: Params): AsyncResult<Message> {

    this.url = params.url;
    this.flowName = params.flowName;

    const { url } = this;

    const serverWin = window.open(url, `Proxy${this.flowName}Server`);
    if (!serverWin)
      return [null, new Error('InternalError', `Unable to open proxy server window.`)];

    this.window = serverWin;

    window.addEventListener('message',
      this.handleMessage);

    serverWin.addEventListener('load',
      this.handleLoad);

    onWindowClosed(serverWin, this.handleClosed, this.abortSignal);

    return this.promise;
  }

  abort() {
    this.setError(new Error('Aborted', `The flow has been aborted.`));
  }

  focus(): Result<boolean> {
    try {
      const { window } = this;
      if (!window)
        return [null, new Error('InternalError', 'Window does not exist.')];
      window.focus();
      return [true];
    } catch (err) {
      return [null, new Error('InternalError', 'Cannot focus window')];
    }
  }

  private handleClosed = (evt: Event) => {
    this.setError(new Error('Aborted', `The window has been closed.`));
  }

  private handleMessage = (evt: MessageEvent) => {

    const { flowName } = this;

    const msgData = evt.data;
    const msgType = msgData?.type;
    const msgPayload = msgData?.payload;

    // validate the message
    // if it's from a different origin, ignore
    if (evt.origin !== window.location.origin)
      return;

    // if the type is not recognized, ignore
    if (msgType !== ProxyFlowMessageType.ProxyFlowResponse)
      return;

    // the message seems of interest for the proxy context, so check if the incoming message is correct
    if (!isDefinedObject(msgPayload))
      return this.setError(new Error('InternalError', `A payload was expected for the message.`));

    const resFlowName = msgPayload.flowName;
    const resData = msgPayload.data;

    if (!ProxyFlowNames.has(resFlowName) ||
      !isDefinedObject(resData) ||
      resFlowName !== flowName)
      return this.setError(new Error('InternalError', `Malformed message.`));

    this.setResolved(msgData);
  }

  private handleLoad = (evt: Event) => {
    // TODO: add logic for detecting that the window still contains CLIPr
    // it seems to work in chrome, but needs to be tested in all browsers
  }

  private setResolved(msg: Message) {
    this.promiseRelay.resolve([msg]);
    this.close();
    return [msg];
  }

  private setError(err: Error): Result {
    this.promiseRelay.resolve([null, err]);
    this.close();
    return [null, err];
  }

  private close() {

    window.removeEventListener('message',
      this.handleMessage);

    this.abortController.abort();

    const serverWindow = this.window;
    if (serverWindow) {
      try {
        serverWindow.removeEventListener('load',
          this.handleLoad);
        serverWindow.close();
      } catch (err) {
        // sometimes the window is already closed and it's transformed into the global scope
        // TODO: investigate
      }
    }

    this.window = null;
  }
}