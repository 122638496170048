import { makeObservable } from 'mobx';
import { Store } from '../../../store/store';
import { StoreNode } from '../../../store';
import { AuthFlowName, AuthFlowResponse, IAuthFlow } from '../authFlowSchema';
import { assertNotNull, AsyncResult } from '../../../core';
import { AuthStepOrchestrator } from '../authStepOrchestrator';
import { RouteContext } from '../../../routes/routeContext';
import { Error } from '../../../core/error';

import { INIT_DEBUGGER } from '../../../core/debug/debugMacros';

type Props = {
  autoLogin?: boolean | null;
  routeContext: RouteContext;
}

export type OptionalLoginWidgetFlowParams = Props;

export class OptionalLoginWidgetFlow
  extends StoreNode
  implements IAuthFlow {

  readonly nodeType = 'OptionalLoginWidgetFlow';

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    INIT_DEBUGGER(this);
  }

  readonly flowName = AuthFlowName.OptionalLoginWidget;

  get autoLogin(): boolean | null {
    return this.props.autoLogin ?? null;
  }

  get routeContext(): RouteContext {
    return this.props.routeContext;
  }


  readonly orchestrator = new AuthStepOrchestrator(this.store);

  async run(): AsyncResult<AuthFlowResponse> {

    const { orchestrator, routeContext } = this;
    const { state } = orchestrator;

    const tryAutoLogin = !state.hasContext && this.autoLogin;
    const tryRefreshExpiredContext = state.hasInvalidAuthenticatedContext;

    const tryRefreshContext =
      tryRefreshExpiredContext ||
      tryAutoLogin;

    if (tryRefreshContext)
      // we don't do any error check here since we will do an overall check after this call
      await orchestrator.initContextFromSessionAndCommit();

    // double check the current state
    // if we don't have a valid authenticated context, then we'll invalidate it
    // anonymous contexts will be created after the permissions check
    if (state.hasValidAuthenticatedContext)
      orchestrator.loadContext();
    else
      orchestrator.invalidate();

    // permit refreshed or not, check permissions
    const [perms, permsErr] = await orchestrator.fetchPermissions(routeContext);
    if (permsErr)
      // could not determine the permissions of the resource so we must fail the flow
      // this should not be confused with a negative result (not having permissions) from the method,
      // because that will come as a valid object containing permissions (see below)
      // an error here means something wrong went during the API call
      return orchestrator.setError(permsErr);

    assertNotNull(perms);

    if (!perms.canView) {
      if (state.hasValidAuthenticatedContext)
        // user cannot view the resource because it doesn't have permissions for it, even if they're authenticated
        return orchestrator.setError(perms.error ?? new Error('Unauthorized', `You are not authorized to view this resource.`));
      else {
        // user cannot view the resource but they're not authenticated, which means they can authenticate
        orchestrator.invalidate();
        return orchestrator.setRedirectToProxyLoginWidget();
      }
    } else {
      // user can view the resource but let's return the correct result depending on the state

      if (state.hasValidAuthenticatedContext)
        return orchestrator.setAuthorized();
      else {
        // create an anonymous context

        const [, contextErr] = orchestrator.initAnonymousContextAndCommit();
        if (contextErr)
          // this should never happen
          return orchestrator.setError(contextErr);

        return orchestrator.setAuthorizedAsAnonymous();
      }
    }
  }
}