import HLS from 'hls.js';
import { HLSEvents } from './hlsSchema';

export const VIDEO_HLS_EVENTS: (HLSEvents | 'hlsNonNativeTextTracksFound' | 'hlsCuesParsed')[] = [
  /// all hls events
  HLS.Events.MEDIA_ATTACHING,
  HLS.Events.MEDIA_ATTACHED,
  HLS.Events.MEDIA_DETACHING,
  HLS.Events.MEDIA_DETACHED,
  HLS.Events.BUFFER_RESET,
  HLS.Events.BUFFER_CODECS,
  HLS.Events.BUFFER_CREATED,
  HLS.Events.BUFFER_APPENDING,
  HLS.Events.BUFFER_APPENDED,
  HLS.Events.BUFFER_EOS,
  HLS.Events.BUFFER_FLUSHING,
  HLS.Events.BUFFER_FLUSHED,
  HLS.Events.MANIFEST_LOADING,
  HLS.Events.MANIFEST_LOADED,
  HLS.Events.MANIFEST_PARSED,
  HLS.Events.LEVEL_LOADING,
  HLS.Events.LEVEL_LOADED,
  HLS.Events.LEVEL_UPDATED,
  HLS.Events.LEVEL_PTS_UPDATED,
  HLS.Events.LEVEL_SWITCHING,
  HLS.Events.LEVEL_SWITCHED,
  HLS.Events.AUDIO_TRACKS_UPDATED,
  HLS.Events.AUDIO_TRACK_SWITCH,
  HLS.Events.AUDIO_TRACK_SWITCHING,
  HLS.Events.AUDIO_TRACK_SWITCHED,
  HLS.Events.AUDIO_TRACK_LOADING,
  HLS.Events.AUDIO_TRACK_LOADED,
  HLS.Events.SUBTITLE_TRACKS_UPDATED,
  HLS.Events.SUBTITLE_TRACK_SWITCH,
  HLS.Events.SUBTITLE_TRACK_LOADING,
  HLS.Events.SUBTITLE_TRACK_LOADED,
  HLS.Events.SUBTITLE_FRAG_PROCESSED,
  HLS.Events.KEY_LOADING,
  HLS.Events.KEY_LOADED,
  HLS.Events.INIT_PTS_FOUND,
  HLS.Events.FRAG_LOADING,
  HLS.Events.FRAG_LOAD_PROGRESS,
  HLS.Events.FRAG_LOAD_EMERGENCY_ABORTED,
  HLS.Events.FRAG_LOADED,
  HLS.Events.FRAG_DECRYPTED,
  HLS.Events.FRAG_PARSING_INIT_SEGMENT,
  HLS.Events.FRAG_PARSING_USERDATA,
  HLS.Events.FRAG_PARSING_METADATA,
  HLS.Events.FRAG_PARSING_DATA,
  HLS.Events.FRAG_PARSED,
  HLS.Events.FRAG_BUFFERED,
  HLS.Events.FRAG_CHANGED,
  HLS.Events.FPS_DROP,
  HLS.Events.FPS_DROP_LEVEL_CAPPING,
  HLS.Events.ERROR,
  HLS.Events.DESTROYING,
  HLS.Events.STREAM_STATE_TRANSITION,
  HLS.Events.STREAM_STATE_TRANSITION,
  'hlsNonNativeTextTracksFound',
  'hlsCuesParsed'
];

export const USED_VIDEO_HLS_EVENTS: HLSEvents[] = [
  HLS.Events.MANIFEST_LOADED,
  HLS.Events.MANIFEST_PARSED,
  // HLS.Events.LEVEL_LOADED,
  HLS.Events.BUFFER_APPENDED,
  HLS.Events.BUFFER_CREATED,
  HLS.Events.ERROR,
  HLS.Events.FRAG_CHANGED,
  HLS.Events.INIT_PTS_FOUND,
  HLS.Events.FRAG_PARSING_INIT_SEGMENT,
  // HLS.Events.FRAG_LOADED
  HLS.Events.BUFFER_EOS,
  // audio tracks
  HLS.Events.AUDIO_TRACKS_UPDATED,
  HLS.Events.AUDIO_TRACK_SWITCHING
  // HLS.Events.FRAG_PARSING_USERDATA,
  // HLS.Events.FRAG_PARSING_METADATA
  // HLS.Events.FRAG_PARSING_DATA
];