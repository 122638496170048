import { makeObservable, observable } from 'mobx';
import { Store } from '../../../store/store';
import { StoreNode } from '../../../store';
import { AuthError } from '../authError';
import { AuthPermit, AuthPermitData, createAuthPermit } from '../authPermit';
import { AuthFlowRenderMode } from '../authFlowSchema';
import { AsyncResult, PromiseRelay, Result } from '../../../core';
import { AuthStepOrchestrator } from '../authStepOrchestrator';

type Props = {
  orchestrator: AuthStepOrchestrator;
  timeout?: number;
}

type Params = Props;

type WidgetMessage = {
  type: string;
  payload: {
    token: string;
  }
}

export class AwaitExternalPermitStep
  extends StoreNode {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);
  }

  get timeout(): number | null {
    return this.props.timeout ?? null;
  }


  @observable isActive = false;
  @observable error: AuthError | null = null;

  @observable permit: AuthPermit | null = null;

  @observable renderMode: AuthFlowRenderMode = AuthFlowRenderMode.None;

  readonly permitPromiseRelay = new PromiseRelay<Result<AuthPermit>>({
    autoStart: false,
    timeout: this.timeout
  });

  async run(): AsyncResult<AuthPermit> {

    this.isActive = true;
    this.renderMode = AuthFlowRenderMode.Loading;
    this.openConnection();

    const relay = this.permitPromiseRelay;
    relay.start();

    const [permit, err] = await relay.promise;
    if (err) {
      this.error = err;
      return [null, err];
    }

    this.permit = permit!;

    this.closeConnection();
    this.renderMode = AuthFlowRenderMode.None;
    this.isActive = false;

    return [permit!];
  }

  private openConnection() {
    window.addEventListener('message',
      this.handleNativeMessage, false);

    const parent: Window | null = window.parent;
    if (parent) {
      parent.postMessage({
        type: 'widgetReady'
      }, '*');
    }
  }

  private closeConnection() {
    window.removeEventListener('message',
      this.handleNativeMessage);
  }

  private handleNativeMessage = (event: MessageEvent) => {

    const packet = event.data as WidgetMessage;
    if (!packet.type)
    return;
    
    const { type, payload } = packet;
    switch (type) {
      case 'registerToken':
        const token = payload?.token;
        this.handleRegisterToken(token);
        break;
    }
  }

  private handleRegisterToken = async (token: string): Promise<void> => {

    const permitData: AuthPermitData = {
      idToken: token
    };

    const [permit, permitErr] = createAuthPermit(permitData);
    if (permitErr) {
      this.permitPromiseRelay.resolve([null, permitErr]);
      return;
    }

    this.permit = permit;

    this.permitPromiseRelay.resolve([permit!]);
  }
}

export async function runAwaitExternalPermitStep(params: Params): AsyncResult<AuthPermit> {
  
  const { orchestrator } = params;
  const { store } = orchestrator;

  const step = new AwaitExternalPermitStep(store, {
    orchestrator
  });

  const [permit, err] = await step.run();
  if (err)
    return [null, err];

  return [permit!];
}