import './adminTeamsPage.scss';
import '../../components/search/searchBar.scss';

import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react-lite';

import { SquarePlusSVGIcon } from '../../components';

import { Button, SearchTextInputControlled } from '../../components/input';
import Routes, { BackRoutes } from '../../routes';
import { MasterPage } from '../../components/layout';
import { useStore } from '../../store/storeHooks';
import { ErrorPage, NotAuthorizedErrorPage } from '../error';
import {
  AdminTeamsPageParams,
  AdminTeamsPageState,
} from './adminTeamsPageState';
import { AdminTeamsCatalogHeader } from '../../components/adminTeams';
import { AdminTeamsTableCatalog } from '../../components/adminTeams/adminTeamsTableCatalog';

type Props = {
  model: AdminTeamsPageState;
};

/** Page containing the catalog of Teams. */
export const AdminTeamsPage = observer(({ model }: Props) => {
  // page init section
  const params = useParams<AdminTeamsPageParams>();
  useEffect(() => {
    model.pageMounted(params);
    return () => model.pageUnmounted();
  }, [model, params]);

  if (model.error) return <ErrorPage message={model.error.message} />;

  const { pathname } = useLocation();
  const link = BackRoutes[Routes.createTeam()].route({ currentPath: pathname });
  // data fields
  const store = useStore();

  const showLoadMask = model.isLoading;
  const { user } = store;
  const source = model.teamCatalogSource;

  if (!user?.hasPermission('AdminGetTeams')) return <NotAuthorizedErrorPage />;

  return (
    <MasterPage
      className="admin-teams-dashboard-page catalog-page with-padding"
      showLoadMask={showLoadMask}
      searchBar={
        <div className="search-bar">
          <SearchTextInputControlled
            model={source.searchBarModel}
            persistentFeedback={false}
            id="search"
            name="search"
            type="text"
            autoComplete="off"
            placeholder="Search"
            showFeedback={false}
            onClear={() => source.handleSearchInputChange({})}
          />
        </div>
      }>
      <Helmet>
        <title>Libraries</title>
      </Helmet>

      <header className="page-header hgrid-3">
        <div className="flex-left">
          <h1>Libraries</h1>
        </div>

        <div className="user-upload flex-right">
          {user?.hasPermission('CreateTeam') && (
            <Button
              route={link}
              className="upload-btn secondary large"
              icon={<SquarePlusSVGIcon />}
              label="Add New"
            />
          )}
        </div>
      </header>

      <AdminTeamsCatalogHeader
        model={model.teamCatalog}
        source={model.teamCatalogSource}
      />

      <AdminTeamsTableCatalog
        model={model.teamCatalog}
        source={model.teamCatalogSource}
      />
    </MasterPage>
  );
});

export default AdminTeamsPage;