import { ObjectLiteral } from '../../../core';

export type WordData = {
  value: number;
  text: string;
  originalText?: string;
  link?: string;
}

export type SpiralType = 'archimedean' | 'rectangular';

export type Word = {
  originalText?: string | undefined;
  text?: string | undefined;
  value?: number | undefined;
  link?: string | undefined;
  font?: string | undefined;
  style?: string | undefined;
  weight?: string | number | undefined;
  rotate?: number | undefined;
  size?: number | undefined;
  padding?: number | undefined;
  x?: number | undefined;
  y?: number | undefined;
}

export type WordCloudChartPropsBase = {
  id?: string,
  className?: string | ObjectLiteral,
  words?: WordData[],
  height?: number,
  width?: number,
  spiralType?: SpiralType,
  withRotation?: boolean,
  font?: string,
  wordPadding?: number,
  showTooltip?: boolean,
  renderTooltip?: (params: any) => React.ReactNode
}

export type WordMouseEventHandler = (evt: React.MouseEvent<SVGElement>, datum: Word) => void;
export type WordTouchEventHandler = (evt: React.TouchEvent<SVGElement>, datum: Word) => void;
export type WordPointerEventHandler = (evt: React.PointerEvent<SVGElement>, datum: Word) => void;

export const WordCloudExcludeWords = [
  // taken from https://github.com/Lissy93/remove-words
  'a',
  'aboard',
  'about',
  'above',
  'across',
  'after',
  'against',
  'all',
  'along',
  'although',
  'amid',
  'among',
  'an',
  'and',
  'another',
  'anti',
  'any',
  'anybody',
  'anyone',
  'anything',
  'are',
  'around',
  'as',
  'asked',
  'at',
  'be',
  'because',
  'been',
  'before',
  'behind',
  'below',
  'beneath',
  'beside',
  'besides',
  'between',
  'beyond',
  'both',
  'but',
  'by',
  'can',
  'cant',
  'concerning',
  'considering',
  'despite',
  'do',
  'dont',
  'down',
  'during',
  'each',
  'either',
  'even',
  'every',
  'everybody',
  'everyone',
  'everything',
  'except',
  'excepting',
  'excluding',
  'few',
  'following',
  'for',
  'from',
  'get',
  'give',
  'go',
  'going',
  'got',
  'had',
  'having',
  'he',
  'her',
  'hers',
  'herself',
  'him',
  'himself',
  'his',
  'how',
  'i',
  'if',
  'im',
  'in',
  'inside',
  'into',
  'is',
  'it',
  'its',
  'itself',
  'just',
  'know',
  'left',
  'let',
  'like',
  'little',
  'many',
  'me',
  'might',
  'mine',
  'minus',
  'more',
  'most',
  'much',
  'must',
  'my',
  'myself',
  'near',
  'needs',
  'neither',
  'never',
  'new',
  'no',
  'nobody',
  'none',
  'not',
  'nothing',
  'now',
  'of',
  'off',
  'on',
  'once',
  'one',
  'only',
  'onto',
  'opposite',
  'or',
  'other',
  'others',
  'our',
  'ours',
  'ourselves',
  'out',
  'outside',
  'over',
  'own',
  'past',
  'per',
  'plus',
  'regarding',
  'round',
  'RT',
  'save',
  'say',
  'see',
  'several',
  'she',
  'side',
  'since',
  'so',
  'some',
  'somebody',
  'someone',
  'something',
  'take',
  'than',
  'that',
  'that',
  'the',
  'their',
  'theirs',
  'them',
  'themselves',
  'there',
  'these',
  'they',
  'this',
  'those',
  'though',
  'through',
  'to',
  'too',
  'toward',
  'towards',
  'try',
  'under',
  'underneath',
  'unless',
  'unlike',
  'until',
  'up',
  'upon',
  'us',
  'versus',
  'via',
  'want',
  'was',
  'we',
  'what',
  'whatever',
  'when',
  'whenever',
  'where',
  'whereas',
  'whether',
  'which',
  'whichever',
  'while',
  'who',
  'whoever',
  'whom',
  'whomever',
  'whose',
  'why',
  'will',
  'with',
  'within',
  'without',
  'would',
  'you',
  'your',
  'yours',
  'yourself',
  'yourselves',
];