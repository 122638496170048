import { computed, makeObservable } from 'mobx';
import { TeamInvitation as ApiTeamInvitation, TeamMemberRole } from '@clipr/lib';
import { Store } from '../store/store';
import { StoreNode } from '../store';
import { Nullable } from '../core';
import { Team } from './team';

export type TeamInvitationProps = ApiTeamInvitation;

export class TeamInvitation
  extends StoreNode {

  constructor(store: Store, props: TeamInvitationProps) {
    super(store, props);
    makeObservable(this);

    Object.assign(this, props, { id: props.email });
  }

  readonly email!: string;
  readonly teamId!: string;
  readonly role!: TeamMemberRole;
  readonly cursor!: string;
  readonly index!: number;

  @computed
  get team(): Nullable<Team> {
    return this.store.teamManager.getTeam(this.teamId);
  }
  @computed
  get strictTeam(): Nullable<Team> {
    return this.store.teamManager.strictGetTeam(this.teamId);
  }
  @computed
  get name(): Nullable<string> {
    return this.email;
  }
}