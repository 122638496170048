import { makeObservable } from 'mobx';
import { getMetadataInputLabel, JobMetadataField } from '../../entities/job/jobMetadata';
import { Store } from '../../store/store';
import { StoreNode } from '../../store/storeNode';
import { input, inputGroup, InputGroupState, InputState } from '../input';

type TeamMetadataItemStateProps = {
  fieldName: JobMetadataField,
  isActive?: boolean,
  fieldAlias?: string | null
}

export class TeamMetadataItemState
  extends StoreNode {

  constructor(store: Store, props: TeamMetadataItemStateProps) {
    super(store, props);
    makeObservable(this);

    this.isActive = input(this, {
      name: 'isActive',
      label: getMetadataInputLabel(props.fieldName)
    });

    this.fieldAlias = input(this, {
      name: 'fieldAlias',
      isRequired: () => !!this.isActive.value,
      placeholder: getMetadataInputLabel(props.fieldName),
      showStatusMessage: true,
      error: (input, fallback) =>
        (input.value && input.value.length > 25) ? 'Max char count exceeded' : fallback,
      disabled: () => {
        //@ts-ignore
        return !this.isActive.value
      }
    });

    this.form = inputGroup(this, {
      name: props.fieldName,
      inputs: () => {
        return [
          this.isActive,
          this.fieldAlias
        ]
      }
    });

    this.isActive.loadValue(props?.isActive || false);
    this.fieldAlias.loadValue(props?.fieldAlias || null);
    this.fieldName = props.fieldName;
  }

  readonly isActive: InputState;
  readonly fieldAlias: InputState;
  readonly form: InputGroupState;
  readonly fieldName: JobMetadataField;
}