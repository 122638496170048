import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import clamp from 'lodash/clamp';
import identity from 'lodash/identity';
import { BindingProps, RefProxy, refProxy, StoreNode } from '../../store';
import { MomentModel, SpeakerModel } from '../../entities';
import { CloudGraph } from './cloudGraph';
import { Store } from '../../store/store';
import { Maybe } from '../../core';
import { SimulationNodeDatum } from 'd3';

type Props = {
  model: MomentModel | SpeakerModel,
  graph: CloudGraph
} & BindingProps<{
  isSelected?: Maybe<boolean>,
  isFiltered?: Maybe<boolean>
}>

class CloudNodeSizeInfo {
  constructor(target: RefProxy<HTMLElement>) {
    makeObservable(this);

    this.target = target;
    this.promise = new Promise((res, rej) => {
      this.resolvePromise = res;
      this.rejectPromise = rej;
    });
  }

  readonly target: RefProxy<HTMLElement>;

  @observable width: number | null = null;
  @observable height: number | null = null;

  @computed get hasInfo() {
    return (
      Number.isFinite(this.width) &&
      Number.isFinite(this.height));
  }

  readonly promise: Promise<void>;

  private resolvePromise!: (value: void | PromiseLike<void>) => void;
  private rejectPromise!: (value: void | PromiseLike<void>) => void;

  @action
  sync() {
    const domElem = this.target.current;
    if (!domElem)
      return;

    const hadInfo = this.hasInfo;

    this.width = domElem.offsetWidth;
    this.height = domElem.offsetHeight;

    if (!hadInfo && this.hasInfo)
      this.resolvePromise();
  }
}

export type CloudNodeType =
  'Topic' |
  'SubTopic' |
  'Speaker' |
  'Unknown';

export class CloudNode<TType extends CloudNodeType = any>
  extends StoreNode
  implements SimulationNodeDatum {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    // @ts-ignore
    this.model = props.model;
    this.graph = props.graph;
  }

  readonly refProxy = refProxy(this);

  get modelType(): TType | 'Invalid' {
    const { model } = this;
    switch (model.nodeType) {
      case 'Moment':
        // @ts-ignore
        switch (model.clipType) {
          case 'Topic': return 'Topic' as TType;
          case 'SubTopic': return 'SubTopic' as TType;
        }
        break;
      case 'Speaker':
        return 'Speaker' as TType;
    }
    return 'Invalid';
  }

  @observable explicitIsVisible: boolean | null = null;
  @observable explicitIsSelected: boolean | null = null;
  @observable explicitIsAccented: boolean | null = null;

  @computed
  get isSelected() {
    if (typeof this.explicitIsSelected === 'boolean')
      return this.explicitIsSelected;

    return this.getResolvedProp('isSelected', false);
  }

  @computed
  get isFiltered() {
    return this.getResolvedProp('isFiltered', true);
  }

  @computed
  get isAccented(): boolean {
    if (typeof this.explicitIsAccented === 'boolean')
      return this.explicitIsAccented;

    const { graph } = this;
    switch (graph.mode) {

      case 'Topics':
        switch (this.modelType) {
          case 'Topic':
            return (
              this.graph.isConnectedToSelectedNode(this.key) ||
              this.graph.isConnectedToAccentedNode(this.key)) && !this.isSelected;
        }
        break;
    }

    return this.graph.isConnectedToSelectedNode(this.key) && !this.isSelected;
  }

  @computed
  get isExpanded() {
    return this.isSelected;
  }

  @computed
  get isVisible() {
    if (typeof this.explicitIsVisible === 'boolean')
      return this.explicitIsVisible;
    if (!this.isFiltered)
      return false;

    const { graph } = this;

    switch (graph.mode) {

      case 'Topics':
        switch (this.modelType) {
          case 'Speaker':
            return this.graph.isConnectedToSelectedNode(this.key) || this.isSelected;
          case 'Topic':
            return true;
          case 'SubTopic':
            return true;
        }

        return true;

      case 'Speakers':

        switch (this.modelType) {
          case 'Speaker':
            return true;
          case 'Topic':
            return this.graph.isConnectedToSelectedNode(this.key) || this.isSelected;
          case 'SubTopic':
            return true;
        }

        return true;
    }

    return false;
  }

  @computed
  get duration(): number {

    const moments = this.graph.job?.moments;
    if (!moments || moments.length === 0)
      return 0;

    switch (this.modelType) {
      case 'Speaker':
        return moments
          .filter(mom => mom.actualSpeakerId === this.model.id)
          .reduce((acc, mom) => acc + mom.duration, 0);

      case 'Topic':
        return this.asTopic?.duration || 0;

      case 'SubTopic':
        return this.asSubTopic?.duration || 0;
    }

    return 0;
  }

  @computed
  get growFactor(): number {

    const { graph } = this;
    let min: number = 0;
    let max: number = 1;

    switch (this.modelType) {
      case 'Speaker':
        min = graph.minSpeakerDuration;
        max = graph.maxSpeakerDuration;
        break;
      case 'Topic':
        min = graph.minTopicDuration;
        max = graph.maxTopicDuration;
        break;
      case 'SubTopic':
        return 0;
    }

    const sf = clamp((this.duration - min) / (max - min), 0, 1);

    if (this.renderStatus !== 'Ready')
      return sf;

    switch (graph.mode) {

      case 'Topics':
        switch (this.modelType) {
          case 'Speaker': return 0;
          case 'Topic': return sf;
        }
        break;

      case 'Speakers':
        return sf;
    }

    return 0;
  }

  @observable lastSelectedAt: number | null = null;

  readonly model: TType extends 'Speaker' ? SpeakerModel : MomentModel;
  readonly graph: CloudGraph;

  get isSpeakerNode(): boolean {
    const { model } = this;
    return (model instanceof SpeakerModel)
  }
  get asSpeaker(): SpeakerModel | null {
    if (this.isSpeakerNode)
      return this.model as SpeakerModel;
    return null;
  }

  get isTopicNode(): boolean {
    const { model } = this;
    return (model instanceof MomentModel && model.isTopic)
  }
  get asTopic(): MomentModel | null {
    if (this.isTopicNode)
      return this.model as MomentModel;
    return null;
  }


  get asMoment(): MomentModel | null {
    if (this.isTopicNode || this.isSubTopicNode)
      return this.model as MomentModel;
    return null;
  }

  get isSubTopicNode(): boolean {
    const { model } = this;
    return (model instanceof MomentModel && model.isSubtopic)
  }
  get asSubTopic(): MomentModel | null {
    if (this.isSubTopicNode)
      return this.model as MomentModel;
    return null;
  }

  get key() {
    return this.model.id;
  }

  @computed get width() {
    if (this.isExpanded)
      return this.expandedSizeInfo.width || 100;
    if (this.isAccented)
      return this.accentedSizeInfo.width || 100;
    return this.idleSizeInfo.width || 100;
  }

  @computed get height() {
    if (this.isExpanded)
      return this.expandedSizeInfo.height || 100;
    if (this.isAccented)
      return this.accentedSizeInfo.height || 100;
    return this.idleSizeInfo.height || 100;
  }

  @computed get hasSizeInfo() {
    return (
      this.idleSizeInfo.hasInfo &&
      this.accentedSizeInfo.hasInfo &&
      this.expandedSizeInfo.hasInfo);
  }

  readonly idleSizeInfo = new CloudNodeSizeInfo(this.refProxy);
  readonly accentedSizeInfo = new CloudNodeSizeInfo(this.refProxy);
  readonly expandedSizeInfo = new CloudNodeSizeInfo(this.refProxy);

  readonly sizeInfoPromise = Promise.all([
    this.idleSizeInfo.promise,
    this.accentedSizeInfo.promise,
    this.expandedSizeInfo.promise
  ]);

  @observable disableTransitions = false;
  @observable renderStatus:
    'Idle' |
    'Compute' |
    'Ready' = 'Idle';

  @action
  async mounted() {
    await this.graph.nodeMounted(this);
  }

  unmounted() {
    this.graph.nodeUnmounted(this);
  }

  @action
  async computeSizeInfo() {

    this.renderStatus = 'Compute';
    this.disableTransitions = true;
    this.explicitIsVisible = true;

    // compute expanded size
    runInAction(() => {
      this.explicitIsSelected = true;
      this.explicitIsAccented = false;
    });
    await this.expandedSizeInfo.promise;

    // compute accented size
    runInAction(() => {
      this.explicitIsAccented = true;
      this.explicitIsSelected = false;
    });
    await this.accentedSizeInfo.promise;

    // compute idle size
    runInAction(() => {
      this.explicitIsAccented = false;
      this.explicitIsSelected = false;
    });
    await this.idleSizeInfo.promise;

    runInAction(() => {
      this.renderStatus = 'Ready';
      this.disableTransitions = false;
      this.explicitIsVisible = null;
      this.explicitIsAccented = null;
      this.explicitIsSelected = null;
    });
  }

  @action
  afterRender() {

    const elem = this.refProxy.current;
    if (!elem || this.hasSizeInfo)
      return;

    if (this.isExpanded) {
      this.expandedSizeInfo.sync();
    }

    else if (this.isAccented) {
      this.accentedSizeInfo.sync();
    }

    else {
      this.idleSizeInfo.sync();
    }
  }

  isConnectedToSpeaker(id: string) {
    return this.connectedSpeakerIds.has(id);
  }

  @computed
  get connectedSubTopicNodes(): CloudNode<'SubTopic'>[] {
    return this.graph.getSubTopicsForTopic(this.key);
  }

  @computed
  get areAllSubTopicNodesSelected() {
    return this.connectedSubTopicNodes.every(node => node.isSelected);
  }

  @computed
  get areNoSubTopicNodesSelected() {
    return !this.connectedSubTopicNodes.some(node => node.isSelected);
  }

  @computed
  get connectedSpeakerIds(): Set<string> {
    const { model } = this;

    return new Set([
      ...this.connectedSubTopicNodes.map(node => node.model.actualSpeakerId!),
      ((model instanceof MomentModel) ? model.actualSpeakerId : null)!
    ].filter(identity));
  }

  @computed
  get connectedSpeakerNodes(): CloudNode<'Speaker'>[] {

    return [...this.connectedSpeakerIds]
      .map(id => this.graph.getSpeakerNode(id)!)
      .filter(identity);
  }

  @computed
  get speakerConnectedTopicIds(): Set<string> {
    const { model } = this;
    if (model.nodeType !== 'Speaker')
      return new Set();

    return new Set([
      ...this.graph.topicNodes
        .filter(topNode => topNode.connectedSpeakerIds.has(model.id))
        .map(topNode => topNode.id)
    ].filter(identity));
  }

  @computed
  get speakerConnectedTopicNodes(): CloudNode<'Speaker'>[] {

    return [...this.connectedSpeakerIds]
      .map(id => this.graph.getSpeakerNode(id)!)
      .filter(identity);
  }

  @action
  select() {
    this.graph.selectNode(this);
    this.lastSelectedAt = +new Date();
  }

  @action
  unselect() {
    this.graph.unselectNode(this);
    this.lastSelectedAt = null;
  }

  @action
  toggleSelect() {
    if (this.isSelected)
      this.unselect();
    else
      this.select();
  }


  // #region SimulationNodeDatum members
  /**
   * Node’s zero-based index into nodes array. This property is set during the initialization process of a simulation.
   */
  index?: number;
  /**
   * Node’s current x-position
   */
  x?: number;
  /**
   * Node’s current y-position
   */
  y?: number;
  /**
   * Node’s current x-velocity
   */
  vx?: number;
  /**
   * Node’s current y-velocity
   */
  vy?: number;
  /**
   * Node’s fixed x-position (if position was fixed)
   */
  fx?: number | null;
  /**
   * Node’s fixed y-position (if position was fixed)
   */
  fy?: number | null;
  // @endregion

  get x1() { return (this.x || 0) - this.width / 2; }
  get y1() { return (this.y || 0) - this.height / 2; }
  get x2() { return (this.x || 0) + this.width / 2; }
  get y2() { return (this.y || 0) + this.height / 2; }

  @observable renderX: number = 0;
  @observable renderY: number = 0;

  @action
  sync() {
    this.renderX = this.x || 0;
    this.renderY = this.y || 0;
  }
}