

import { makeObservable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { PageName } from '../../pages/pageSchema';
import { IRouteStorage, RouteComponentType } from '../../routes/routeSchema';
import { History } from 'history';
import { RouteContext } from '../../routes/routeContext';

import { INIT_DEBUGGER } from '../../core/debug/debugMacros';
import Routes, { UserDashboardQueryParams } from '../../routes';
import { queryParamsToString } from '../../core/urlUtils';

export class BackRouteHandler
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    INIT_DEBUGGER(this);
  }
  readonly nodeType = 'BackRouteHandler';

  generateBackRoute = (previousContext: RouteContext | null, currentContext: RouteContext, history: History<IRouteStorage>): string | null | (() => void) => {
    if (currentContext.componentType === RouteComponentType.Widget) {
      if (previousContext) {
        return history.goBack;
      }

      return null;
    }

    switch (currentContext.pageName) {
      case PageName.Analytics:
      case PageName.BookmarkList:
      case PageName.CreateTeam:
      case PageName.JoinInvitation:
      case PageName.LiveStreamQueue:
      case PageName.Profile:
      case PageName.SearchResults:
      case PageName.TeamAnalytics:
      case PageName.TeamSettings:
      case PageName.TrainerPlayer:
      case PageName.Upload:
      case PageName.UserSplash:
      case PageName.ChatGptSearchResults: {
        if (previousContext) {
          return history.goBack;
        }

        const { teamId, listId, view } = currentContext.params || currentContext.searchParams;

        if (listId) return Routes.bookmarkList(listId);
        if (teamId) {
          return Routes.teamDashboard(teamId);
        }

        if (teamId && view) {
          let params = { view };
          const search = queryParamsToString(params);

          return `${Routes.teamUpload(teamId)}?${search}`;
        }

        if (view) {
          const viewQueryParam: UserDashboardQueryParams = { view };
          return Routes.userDashboard(viewQueryParam);
        }

        return Routes.userDashboard();
      }

      case PageName.UserPlayer: {
        const { teamId, listId } = currentContext.params || currentContext.searchParams;
        if (previousContext && previousContext.pageName === PageName.UserSplash) {
          return Routes.userDashboard();
        }

        if (previousContext) {
          return history.goBack;
        }

        if (listId) return Routes.bookmarkList(listId);
        if (teamId) {
          return Routes.teamDashboard(teamId);
        }
        return Routes.userDashboard();
      }

      default:
        return null;
    }
  }
}

