import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import { inputGroup, InputGroupState } from '../../components/input';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { Nullable } from '../../core';
import { createArray } from '../../core/array';
import { LinkStatus, UploadLinkItemState } from './uploadLinkItemState';
import Routes from '../../routes';

type Props = BindingProps<{
  teamId?: string,
  jobId?: string,
}>;

/**
 * Controller for the UploadLink component
 */
export class UploadLinkState
  extends StoreNode {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);
    // link list form
    this.form = inputGroup(this, {
      name: 'linkForm',
      inputs: () =>
        this.pendingItems.map((el: UploadLinkItemState) => el.linkInput),
      isSubmitDisabled: () => {
        return (
          this.form.inputs.some(input => input.status === 'error' && input.isTouched && !input.isFocused) ||
          this.form.isSubmitting ||
          this.isLoading ||
          (this.isReplace && (this.stagedItems.length > 0 || this.pendingItems.length > 1))
          // this.form.hasTouchedErrorInputs ||
          // this.linkInput.isEmpty ||
          // !!(this.hasPasswordInput.value && this.passwordInput.isEmpty)
        );
      }
    });

    this.stagingForm = inputGroup(this, {
      name: 'stagingForm',
      inputs: () =>
        this.stagedItems.map((el: UploadLinkItemState) => el.form),
      isSubmitDisabled: () => {
        return (
          this.stagingForm.inputs.some(input =>
            //@ts-ignore
            input.inputs.some(input => input.status === 'error' && input.isTouched && !input.isFocused)
          ) ||
          this.stagingForm.isSubmitting ||
          // this.stagingForm.hasTouchedErrorInputs ||
          this.stagingForm.isEmpty ||  // including links
          this.isLoading ||
          (this.isReplace && (this.stagedItems.length > 1))
          // || !!(this.hasPasswordInput.value && this.passwordInput.isEmpty)
        );
      }
    });

  }

  @observable isLoading: boolean = false;
  @observable isLocked: boolean = false;

  // #region Resolved props
  @computed get teamId(): Nullable<string> {
    return this.resolvedProps.teamId;
  }
  @computed get jobId(): Nullable<string> {
    return this.resolvedProps.jobId;
  }
  // #endregion

  @computed get job() {
    return this.store.jobManager.maybeGetJob(this.jobId);
  }

  @computed get isReplace(): boolean {
    return !!this.jobId;
  }

  @computed get hasCompletedItems(): boolean {
    return this.completedItems.length > 0;
  }

  @computed get hasSyncingItems(): boolean {
    return this.syncingItems.length > 0;
  }

  @computed get replaceLocked(): boolean {
    return this.hasCompletedItems || this.hasSyncingItems;
  }

  //link list
  readonly form: InputGroupState;
  readonly stagingForm: InputGroupState;
  readonly linkInputList: IObservableArray<UploadLinkItemState>
    = createArray<UploadLinkItemState>(true) as IObservableArray;

  @computed get pendingItems(): UploadLinkItemState[] {
    return this.linkInputList.filter(item => item.isPending);
  }

  @computed get stagedItems(): UploadLinkItemState[] {
    return this.linkInputList.filter(item => item.showInStaging);
  }

  @computed get failedItems(): UploadLinkItemState[] {
    return this.linkInputList.filter(item => item.isFailed);
  }

  @computed get duplicateErrorItems(): UploadLinkItemState[] {
    return this.failedItems.filter(item => item.hasDuplicateServerError);
  }

  @computed get syncingItems(): UploadLinkItemState[] {
    return this.linkInputList.filter(item => item.isSyncing);
  }

  @computed get completedItems(): UploadLinkItemState[] {
    return this.linkInputList.filter(item => item.isCompleted);
  }

  @computed get isInStaging(): boolean {
    return this.stagedItems.length > 0;
  }

  @computed get showStagingForm(): boolean {
    return this.stagedItems.length > 0 || this.syncingItems.length > 0;
  }

  @computed get showProgressBox(): boolean {
    return this.isLocked;
  }

  @computed get stagingBoxesCount(): number {
    let counter = 0;

    if (this.showStagingForm)
      counter += 1;

    if (this.showProgressBox)
      counter += 1;

    return counter;
  }

  @action
  clearStagedItems(status?: LinkStatus) {

    if (status === 'pending')
      return;

    if (!status)
      this.linkInputList.filter(item => !item.isPending).forEach(item =>
        this.linkInputList.remove(item))
    else
      this.linkInputList.filter(item => item.status === status)
        .forEach(item =>
          this.linkInputList.remove(item))
  }

  @action
  mounted() {
    this.reset();
    this.isLoading = false;
  }

  @action
  unmounted() {
    this.reset();
  }

  @action
  async submit() {
    this.isLoading = true;
    this.stagingForm.handleSubmit();

    if (this.stagingForm.error) {
      this.isLoading = false;
      this.stagingForm.handleSubmitReject();
      return;
    }

    const resArray = await Promise.all([...this.stagedItems.map(async item => item.submit())])
    // const hasError = resArray.some(([, err]) => !!err);

    if (this.duplicateErrorItems.length > 0) {
      const secMessage = this.duplicateErrorItems.length > 1 ?
        'It looks like you are trying to upload multiple videos that CLIPr has already processed. Do you want to upload duplicates?' :
        'It looks like you are trying to upload a version of a video that CLIPr has already processed. Do you want to upload a duplicate?';
      const confirmLabel = this.duplicateErrorItems.length > 1 ?
        'Upload All Duplicates' :
        'Upload Duplicate';
      const title = this.duplicateErrorItems.length > 1 ?
        this.duplicateErrorItems.length + ' duplicate videos' :
        'Duplicate video';
      this.dispatch('openConfirmationModal', {
        onSubmit: async () => await this.submitDuplicates(),
        onClose: () => this.abortSubmitDuplicates(), // it will be executed after onSubmit also, but it should only be redundant
        title: title,
        message: 'Are you sure?',
        secondaryMessage: secMessage,
        // secondaryMessage: 'You will lose edits you have made.',
        confirmLabel: confirmLabel,
        closeLabel: 'Cancel'
      })
    }

    const nonDuplicatesError = this.failedItems.filter(item => !item.hasDuplicateServerError);
    if (nonDuplicatesError.length > 0) {
      this.clearStagedItems('error');
      this.handleUploadError();
      return;
    }

    if (resArray.some(([, err]) => !err)) {
      this.handleUploadSuccess();
      this.clearStagedItems('submitted');
    }

  }

  @action
  async submitDuplicates() {
    this.isLoading = true;
    const resArray = await Promise.all([...this.duplicateErrorItems.map(async item => item.submit(true))]);
    const hasError = resArray.some(([, err]) => !!err);

    this.clearStagedItems();
    if (hasError) {
      return this.handleUploadError();
    }

    this.handleUploadSuccess();
  }

  @action
  abortSubmitDuplicates() {
    this.stagingForm.handleSubmitResolve();
    this.isLoading = false;
    this.clearStagedItems();
  }

  @action
  stage() {

    if (this.form.hasErrorInputs ||
      (this.isReplace && (this.stagedItems.length > 0 || this.pendingItems.length > 1)))
      return;
    //clear all empty lines
    this.linkInputList.replace(this.linkInputList.filter(item => !item.linkInput.isEmpty));
    //stage all pending
    this.pendingItems.forEach(item => item.stage());
    this.addLinkItem();
  }

  @action
  handleUploadError() {
    this.dispatch(
      'NotificationService',  // target
      'notifyError',          // type
      `Failed to process some of the provided URLs.`);

    this.stagingForm.handleSubmitReject();
    this.isLoading = false;
  }

  @action
  handleUploadSuccess() {
    this.dispatch(
      'NotificationService',
      'notifySuccess',
      `Your video has been received and is now processing.`);

    this.emit('upload:taskCompleted', { job: this.job });

    this.stagingForm.handleSubmitResolve();
    this.isLoading = false;
    this.lock();
    setTimeout(() => {
      this.unlock();
      if (this.isReplace)
        this.store.goTo(Routes.trainerDashboard());
    }, 3000);
  }

  @action
  lock() {
    this.isLocked = true;
  }

  @action
  unlock() {
    this.isLocked = false;
  }

  @action
  reset() {
    this.form.clear();

    this.linkInputList.clear();
    this.addLinkItem();

    this.isLocked = false;
  }

  @action
  addLinkItem() {
    if (this.isReplace && (this.pendingItems.length > 1))
      return;

    const linkInputItem: UploadLinkItemState = new UploadLinkItemState(this.store, {
      teamId: () => this.teamId,
      jobId: () => this.jobId
    });
    this.linkInputList.push(linkInputItem);
  }

  @action
  removeLinkItem(id: string) {
    const itemIndex = this.linkInputList.findIndex(item => item.id === id);

    if (itemIndex > -1)
      this.linkInputList.splice(itemIndex, 1);
  }
}
