import React from 'react';
import { observer } from 'mobx-react-lite';
import { RouteProps } from 'react-router-dom';
import './widgetErrorPages.scss';
import { TextInputWarningSVGIcon } from '../../components';
import { Maybe } from '../../core';

type Props = RouteProps & {
  code?: Maybe<string>;
  message?: Maybe<string>;
};

export const UnauthorizedVideoErrorPage = observer(({ message }: Props) => {
  return (<div className="dock flex-center widget-error-page">
    <TextInputWarningSVGIcon />
    <p>{message}</p>
    <p className="small">If you think this is an error, please <a href={'mailto:' + process.env.REACT_APP_EMAIL_QA}>Contact Us &#8594;</a></p>
  </div>)
});