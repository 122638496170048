import './teamSettingsPage.scss';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { RadioGroupInputControlled } from '../../components/input';
import { BasicPage, SplashPageHeader } from '../../components/layout';
import { TeamSettingsPageState } from './teamSettingsPageState';
import { TeamEditForm, TeamMembers } from '../../components/teamSettings';
import { ErrorPage } from '../error';

type Props = {
  model: TeamSettingsPageState;
};

export const TeamSettingsPage = observer(({ model }: Props) => {
  const { teamId } = useParams<{ teamId: string }>();

  useEffect(() => {
    model.pageMounted({ teamId: teamId });
    return () => model.pageUnmounted();
  }, [model, teamId]);

  if (model.error)
    return <ErrorPage message={model.error.message} code={model.error.code} />;

  const pageTitleLocation = model.team?.name ? ('- ' + model.team?.name) : '';

  return (
    <BasicPage
      className="team-settings-page"
      showHeader={false}
      showLoadMask={model.isLoading}>
      <SplashPageHeader
        text="Library Settings"
        leftArrow={true}
      />
      <Helmet>
        <title>{`CLIPr ${pageTitleLocation}`}</title>
      </Helmet>

      <div className="splash-page-content page-content">
        <div className="teams-settings-grid">
          <div className="team-info modal-card">
            <TeamEditForm model={model} />
            {false && <TeamPrivacy model={model} />}
          </div>

          <div className="team-members-container">
            <TeamMembers model={model} />
          </div>
        </div>
      </div>
    </BasicPage>
  );
});

const TeamPrivacy = observer(({ model }: Props) => (
  <div className="block input-group">
    <label className="input-group-label" htmlFor={model.teamPrivacy.name}>
      Library Visibility
    </label>
    <RadioGroupInputControlled
      className="form-input team-privacy"
      model={model.teamPrivacy}
    />
  </div>
));

export default TeamSettingsPage;