import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { percentage } from '../../../components/utils';

export const UploadProgressBar = observer(({ progress, className }: { progress: number | string, className?: string }) => {

  className = classNames("file-progress-bar", className);
  //@ts-ignore
  progress = percentage(typeof progress === 'number' ? Math.min(progress, 1) : progress);

  return (
    <div className={className}>
      <div className="progress-bar" style={{ width: `${progress}` }} />
    </div>
  );
});