import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { BindingProps, Message, refProxy, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { JobModel, MomentSelector, SpeakerModel, SubTopic, SyncStatus, Team, } from '../../entities';
import { PlayerState } from '../player/playerState';
import { PlayerFramesetState } from '../playerFrameset/playerFramesetState';
import { SpeakerIdFormState } from '../speakerId/speakerIdFormState';
import { PlayerSpeakerIdController } from './playerSpeakerIdController';

type Props = BindingProps<{
  jobId: string,
  player: PlayerState,
  frameset: PlayerFramesetState,
  playerTutorialHighlightedComponents?: string[];
  teamId?: string | null;
  speakerIdController?: PlayerSpeakerIdController;
}>

export class PlayerSpeakersState
  extends StoreNode {

  readonly scrollElementProxy = refProxy(this);
  readonly sectionProxy = refProxy(this);

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    reaction(() => this.speakerIdForm.isFinished, () => {
      if (this.speakerIdForm.isFinished)
        this.emit('completed')
    });
    reaction(() => this.speakerIdForm.currentSpeaker, () => {
      const currentSpeaker = this.speakerIdForm.currentSpeaker;
      if (currentSpeaker) {
        this.speakerIdController.setSelectorSpeaker(currentSpeaker);
        this.player.invoke('pause');
      }

    });
    this.speakerIdForm.listen(this.speakerIdFormListener);
  }

  private speakerIdFormListener = (msg: Message<SpeakerIdFormState>) => {
    const { type, payload } = msg;
    const { player } = this;
    const speaker = payload.speaker;

    switch (type) {
      case 'change':
        const currentActiveTranscript = player.momentView.activeTranscript;
        if (!speaker || currentActiveTranscript?.speakerId === speaker.id)
          return;

        const nextSpeaker = player.momentView.getNextTranscriptBySpeakerId(speaker?.id);
        if (nextSpeaker) {

          if (nextSpeaker.duration > 1) {
            player.invoke('seek', { time: nextSpeaker.startTime + 0.3 }); // just to be sure it's in the frame, and not an overspill of the transcript
          } else {
            player.invoke('jumpToMoment', { moment: nextSpeaker });
          }
        }
        break;
    }

  }

  readonly speakerIdForm: SpeakerIdFormState = new SpeakerIdFormState(this.store, {
    disabledSpeakerSuggestion: () => this.team ? !this.team.isSpeakerSuggestEnabled : false
  })

  @observable isLoading: boolean = false;

  @computed get momentsSyncStatus(): SyncStatus | null {
    return this.job?.momentsSyncStatus || null;
  };

  @computed get jobId(): string | null {
    return this.resolvedProps.jobId ?? null;
  }

  @computed get suppressApiQueries(): boolean {
    return this.resolvedProps.suppressApiQueries;
  }

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed get teamId(): string | null {
    return this.resolvedProps.teamId ?? null;
  }

  @computed get team(): Team | null {
    return this.store.teamManager.getTeam(this.teamId);
  }

  @computed
  get player(): PlayerState {
    return this.resolvedProps.player;
  }
  @computed
  get frameset(): PlayerFramesetState | null {
    return this.resolvedProps.frameset ?? null;
  }
  @computed
  get speakerIdController(): PlayerSpeakerIdController {
    return this.resolvedProps.speakerIdController;
  }


  @computed
  get momentSelector(): MomentSelector | null {
    return this.player?.selector;
  }

  @computed get activeSubtopics(): SubTopic[] {
    return this.player.momentView.activeSubTopics;
  }

  @computed get activeSpeaker(): SpeakerModel | null {
    return this.player.momentView.activeTopicSpeaker;
  }

  @computed get activeSpeakerList(): SpeakerModel[] {
    return this.player.momentView.displayActiveTopicSpeakerPool;
  }

  @action
  async mounted() {

  }

  @action
  unmounted() {

  }

  @action
  async init() {
    this.setLoading(true);
    if (!this.suppressApiQueries) {
      await this.store.apiFetchSpeakers({
        jobId: undefined,
        teamId: this.teamId ?? undefined
      });

      await this.job?.apiBatchFetchSpeakers();
    }

    const { job, team } = this;
    const jobId = job?.id ?? null;
    const teamId = team?.id ?? null;
    if (job) {
      const speakerList = this.player.momentView.activeSpeakerIdSpeakerPool;
      await this.speakerIdForm.init(speakerList, jobId, teamId);
    }
    this.setLoading(false);
  }

  @action
  async initEdit(speakerList: SpeakerModel[]) {
    this.setLoading(true);
    if (!this.suppressApiQueries) {
      await this.store.apiFetchSpeakers({
        jobId: undefined,
        teamId: this.teamId ?? undefined
      });

      await this.job?.apiBatchFetchSpeakers();
    }

    const { job, team } = this;
    const jobId = job?.id ?? null;
    const teamId = team?.id ?? null;
    this.speakerIdForm.openEditMode();
    await this.speakerIdForm.init(speakerList, jobId, teamId);
    this.speakerIdForm.speakerBlock.setEditConfirmed();
    this.setLoading(false);
  }

  @action
  setLoading(value: boolean) {
    this.isLoading = value;
  }

  @action
  reset() {
    this.speakerIdForm.reset();
  }
}