
import React from 'react';
type JobTitle = {
  isAudioSource?: boolean;
  title: string;
}

// eslint-disable-next-line 
export const JobTitle = ({ isAudioSource, title }: JobTitle) => {
  if (isAudioSource)
  // eslint-disable-next-line jsx-a11y/accessible-emoji
    return (<span title={title}>&#x1F3B6; {title}</span>)
  return <span title={title}> {title} </span>;
}