import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../store/store';
import { Message, refProxy, RefProxy } from '../store';
import { StoreNode } from '../store/storeNode';
import { Routes } from '../routes';
import { Error } from '../core/error';
import { UploadFileState } from '../pages/uploadPage/uploadFileState';
import { UploadLinkState } from '../pages/uploadPage/uploadLinkState';

const stagingItemHeight = 133;
const offset = 125;

export class UploadManager
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
    this.receive(this.receiver, [
      'UploadService:taskDiscarded',
      'UploadService:taskCompleted'
    ]);

    this.fileComponent = new UploadFileState(this.store, {
      teamId: () => this.teamId,
      jobId: () => this.jobId
    });
    this.linkComponent = new UploadLinkState(this.store, {
      teamId: () => this.teamId,
      jobId: () => this.jobId
    });
  }

  readonly fileComponent: UploadFileState;
  readonly linkComponent: UploadLinkState;

  readonly stageRef: RefProxy<HTMLElement> = refProxy(this);

  @observable previousRoute: string | null = null;
  @observable teamId: string | null = null;
  @observable isLoading: boolean = false;

  @computed get team() {
    return this.store.teamManager.getTeam(this.teamId);
  }
  @observable jobId: string | null = null;
  @computed get job() {
    return this.store.jobManager.maybeGetJob(this.jobId);
  }

  @computed get isReplace(): boolean {
    return !!this.jobId;
  }

  @computed get showStaging() {
    return (this.linkComponent.showStagingForm
      || this.linkComponent.showProgressBox
      || this.fileComponent.showStagingForm
      || this.fileComponent.showProgressBox)
  }

  @computed get stagingBoxesNumber() {
    return (this.fileComponent.stagingBoxesCount +
      this.linkComponent.stagingBoxesCount)
  }

  @computed get showOpenConfirmation() {
    return (this.linkComponent.showStagingForm
      || this.fileComponent.showStagingForm)
  }

  @computed get maxStagingItemsNumber() {
    if (this.stagingBoxesNumber > 1)
      return 2;

    const stageHeight: number = this.stageRef.current?.offsetHeight || 0;
    const maxItems = Math.floor((stageHeight - offset) / stagingItemHeight);
    return maxItems > 2 ? maxItems : 3;
  }

  @observable error: Error | null = null;

  @action
  reset() {
    this.error = null;
    this.fileComponent.reset();
    this.linkComponent.reset();
    this.store.externalLibraryUploadWindow.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }

  @action
  init() {
    this.fileComponent.init();
  }

  @action
  setTeamId(teamId: string) {
    this.teamId = teamId;
  }

  private receiver = (msg: Message) => {
    switch (msg.type) {
      case 'taskDiscarded':
        this.fileComponent.pageSessionTasks.remove(msg.payload.task);
        if (this.fileComponent.pageSessionTasks.length === 0)
          this.fileComponent.reset();
        break;
      case 'taskCompleted':
        if (this.isReplace)
          this.store.goTo(Routes.trainerDashboard());
        break;
    }
  }
}