import { searchMatches } from '../../core';
import { timeRegionsOverlapStrict } from '../../core/time';
import { MomentModel } from '../moment';

type Moment = MomentModel;

export type MomentFilterFunc = (mom: Moment) => boolean;
export type MomentRelatedFilterFunc = (mom: Moment, other: Moment) => boolean;
export type MomentRelatedThunk = (other: Moment) => (mom: Moment) => boolean;
export type MomentTransformFunc = (mom: Moment[]) => Moment[];

export const isTopic = () =>
  (mom: Moment) => mom.isTopic;
export const isTranscript = () =>
  (mom: Moment) => mom.isTranscript;
export const isGeneric = () =>
  (mom: Moment) => mom.isGeneric;

export const startsAfter = (after: number) =>
  (mom: Moment) => mom.startTime >= after;
export const startsBefore = (before: number) =>
  (mom: Moment) => mom.startTime <= before;
export const endsAfter = (after: number) =>
  (mom: Moment) => mom.endTime >= after;
export const endsBefore = (before: number) =>
  (mom: Moment) => mom.endTime <= before;

export const matches = (query: string) =>
  (mom: Moment) => searchMatches([
    mom.name || '', 
    mom.description || '', 
    mom.keywords?.join(' ')].join(' '), query);

export const isConnectedTo = (): MomentRelatedFilterFunc =>
  (mom: Moment, other: Moment) => areConnected(mom, other);

export const hasSpeakerId = (spkId: string) =>
  (mom: Moment) => mom.speakerId === spkId;
export const hasSpeakerName = (spkName: string) =>
  (mom: Moment) => mom.speaker?.name === spkName;





export const areConnected = (a: Moment, b: Moment) =>
  a.id !== b.id &&
  timeRegionsOverlapStrict(a, b);

/**
 * @param moments The input moment array, sorted in ascending order.
 */
export const firstActiveMoment = (moments: Moment[], time: number) =>
  moments.find(m => time >= m.startTime && time <= m.endTime) || null;

/**
 * @param moments The input moment array, sorted in ascending order.
 */
export const lastActiveMoment = <T extends Moment>(moments: T[], time: number): T | null => {
  // iterate backwards to short circuit early
  for (let i = moments.length - 1; i >= 0; i--) {
    const moment = moments[i];
    if (time >= moment.startTime && time <= moment.endTime)
      return moment;
  }
  return null;
}

/**
 * @param moments The input moment array, sorted in ascending order.
 */
export const allActiveMoments = <T extends Moment>(moments: T[], time: number): T[] => {
  return moments.filter((moment: Moment) => time >= moment.startTime && time <= moment.endTime);
}