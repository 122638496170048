import { Store } from '../store/store';
import { ServiceLoader } from './serviceLoader';
import { ServiceName } from './serviceSchema';
import { ResourceService } from '../services/resources';
import { VendorService } from '../services/vendor';
import { ClientMode } from './kernelSchema';
import { action, makeObservable, observable } from 'mobx';

/**
 * Although the app is currently constructed around the `Store` and it's a giant monolith which loads all at once, 
 * you will start seeing some experiments and snippets which have the role of helping us decouple and modularize the app better in the future.
 * The purpose of the `Kernel` will be to act as the barebone entry point of the app, 
 * and the rest of the modules in the folder act as helpers to load the rest of the app dynamically.
 */
export class Kernel {
  constructor(store: Store) {
    this.store = store;
    makeObservable(this);
  }

  readonly store: Store;
  readonly serviceLoader = new ServiceLoader(this);

  get resourceService(): Promise<ResourceService> {
    return this.serviceLoader.load(ServiceName.Resources);
  }

  get vendorService(): Promise<VendorService> {
    return this.serviceLoader.load(ServiceName.Vendor);
  }

  @observable clientMode: ClientMode = ClientMode.Application;

  @action
  setWidgetMode() {
    this.clientMode = ClientMode.Widget;
  }

  @action
  setProxyServerMode() {
    this.clientMode = ClientMode.ProxyServer;
  }
  
  get openerWindow(): Window | null {
    try {
      return window.opener;
    }
    catch (err) {
      return null;
    }
  }
  
  get parentWindow(): Window | null {
    try {
      return window.parent;
    }
    catch (err) {
      return null;
    }
  }
}