import './momentCloud.scss';

import React, { useEffect, useLayoutEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { MomentCloudState } from './momentCloudState';
import { cssPixel } from '../../core';
import { CloudNode } from './cloudNode';

// @ts-ignore
import CSSVariables from './momentCloud.scss';

const MinFontSize: number = parseFloat(CSSVariables.topicMinFontSize) || 16;
const MaxFontSize: number = parseFloat(CSSVariables.topicMaxFontSize) || 36;
const FontSizeRange: number = MaxFontSize - MinFontSize;

type Props = {
  model: CloudNode<'Topic'>,
  parentModel: MomentCloudState
}

export const TopicNodeElement = observer(({
  model
}: Props) => {

  const className = classNames('topic-node cloud-node', {
    'accent': model.isAccented,
    'disable-transitions': model.disableTransitions
  });

  const style = {
    '--idle-width': cssPixel(model.idleSizeInfo.width),
    '--idle-height': cssPixel(model.idleSizeInfo.height),
    '--accented-width': cssPixel(model.accentedSizeInfo.width),
    '--accented-height': cssPixel(model.accentedSizeInfo.height),
    '--expanded-width': cssPixel(model.expandedSizeInfo.width),
    '--expanded-height': cssPixel(model.expandedSizeInfo.height),
    '--font-size': cssPixel(MinFontSize + model.growFactor * FontSizeRange)
  } as any;

  useEffect(() => {
    model.mounted();
    return () => 
      model.unmounted();
  }, [model]);

  useLayoutEffect(() => {
    model.afterRender();
  });

  return (
    <div ref={model.refProxy.ref}
      className={className}
      style={style}
      aria-selected={model.isSelected}
      aria-expanded={model.isExpanded}
      aria-hidden={!model.isVisible}
      data-debug-key={model.key}>

      <div className="node-main"
        onClick={() => model.toggleSelect()}>
        {model.model.name}
      </div>

      {model.connectedSubTopicNodes.length > 0 &&
        <div className="node-subtopics">
          <div className="node-subtopics-panel">
            <h4>Subtopics</h4>

            <ul className="subtopic-list">

              {model.connectedSubTopicNodes.map(subNode => {

                return (
                  <li className="subtopic-item" 
                    key={subNode.id}>
                    
                    <label className="subtopic-label">
                      <input
                        type="checkbox"
                        checked={subNode.isSelected}
                        onChange={() => subNode.toggleSelect()} />
                      <span className="name">{subNode.model.name}</span>
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      }
    </div >
  );
});