import { makeObservable, computed, reaction } from 'mobx';
import { MomentModel } from '../../entities';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { PlayerState } from '../player/playerState';

type Props = BindingProps<{
  jobId: string,
  player: PlayerState
}>;

export class TrainerTranscriptsState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    reaction(() => this.activeTranscriptMoment, () => {
      const moment = this.activeTranscriptMoment;
      if (moment)
        this.domScrollToMoment(moment);
    });
  }

  // #region Resolved props
  @computed get player(): PlayerState {
    return this.resolvedProps.player;
  }
  @computed get jobId(): string {
    return this.resolvedProps.jobId;
  }
  @computed get job() {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed get showOverlay(): boolean {
    return this.player.isTranscriptProcessing;
  }
  // #endregion

  @computed get transcriptMoments(): MomentModel[] {
    return this.job?.transcriptMoments || [];
  }

  @computed get activeTranscriptMoment(): MomentModel | null {
    const time = this.player.currentTime;
    return this.transcriptMoments
      .find(m => time >= m.startTime && time <= m.endTime) || null;
  }

  isTranscriptMomentActive(moment: MomentModel): boolean {
    return this.activeTranscriptMoment?.id === moment.id;
  }

  handleTranscriptClick(evt: React.MouseEvent, moment: MomentModel) {
    return this.player.invoke('jumpToMoment', { moment });
  }


  domScrollToMoment = (moment: MomentModel) => {
    if (!moment)
      return;

    const domMoment = this.domGetTranscriptMoment(moment.id);
    if (!domMoment)
      return;

    domMoment.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }

  domGetTranscriptMoment(id: string) {
    // TODO: wrap in container selector
    return document.querySelector(`[data-moment="true"][data-moment-id="${id}"]`);
  }
}