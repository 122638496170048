import { action, computed, observable, makeObservable } from 'mobx';
import assert from 'assert';
import { capitalCase } from 'change-case';
import { DropdownItemObject, input, inputGroup, InputGroupState, InputState } from '../input';
import { assertNotNull, hasKey } from '../../core';
import { MomentModel, MomentStub, JobModel, TrackModel } from '../../entities';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { notifyError, notifyLoading, notifySuccess } from '../../services/notifications';
import { closeWindow, openWindow } from '../../services/overlays';
import { AddMomentInput, AddTrackMutationVariables, ClipType, MomentSource, UpdateMomentInput } from '@clipr/lib';
import toInteger from 'lodash/toInteger';
import { AnalyticsEventTypes } from '../../services/analytics/analyticsSchema';
import { timestampNorm } from '../../core/time/timeUtils';
import { shouldHaveParentClip } from '../../entities/moments/momentValidations';
import { InfoModalState } from '../infoModal';
import { WindowState } from '../overlays/windowState';
import { getDefaultLanguageValue, getLanguageInputItem, LanguageItems } from '../../entities/language';

import {
  ClipWindowTrainerInputs as TrainerInputs,
  ClipWindowUserInputs as UserInputs
} from './clipWindowInputPresets';
import { TrackWindowState } from '../trackWindow/trackWindowState';
import { SpeakerWindowState } from '../speakerWindow/speakerWindowState';
import { getSentimentLabel, Sentiments } from '../../entities/job/jobSentiment';

export const ClipTypes: ClipType[] = [
  'Topic',
  // 'Chapter',
  'SubTopic',
  'Moment',
  'Paragraph',
  'Transcript'
];

export const MomentTypes = [
  'Resources',
  'Action Item/Task',
  'Question',
  'Answer',
  'Presentation',
  'Socializing'
];

export enum ClipWindowMode {
  Add = 'Add',
  Edit = 'Edit'
}

export type OpenClipWindowOptions = {
  momentKindLabel?: MomentKindLabel,
  layout?: Layout,
}

type OpenCreateArgs = {
  stub: MomentStub,
  jobId: string,
  source?: MomentSource,
  options?: OpenClipWindowOptions
}

type OpenEditArgs = {
  momentId: string,
  jobId: string,
  source?: MomentSource,
  options?: OpenClipWindowOptions
}

type Props = {
  jobId: string
}

type MomentKindLabel = 'clip' | 'moment';
type Layout = 'Trainer' | 'User';

export class ClipWindowState
  extends StoreNode {

  readonly nodeType: 'ClipWindow' = 'ClipWindow';

  constructor(store: Store, props?: Props) {
    super(store);
    makeObservable(this);

    this.jobId = props?.jobId || null;

    // clip basic inputs
    this.clipType = input(this, {
      name: 'clipType',
      selectorItems: this.clipTypeItems,
      isRequired: true,
      onChange: () => {
        this.track.value = null;

        if (this.clipType.value !== 'Moment') {
          this.momentType.value = null;
        }
        if (this.clipType.value === 'Paragraph' || this.clipType.value === 'Transcript') {
          this.name.value = null;
        }
      },
    });

    this.startTime = input(this, {
      name: 'startTime',
      showStatus: () => !this.inputGroup.isSubmitting,
      showStatusMessage: false,
      error: () => this.validateStartTime()
    });

    this.endTime = input(this, {
      name: 'endTime',
      showStatus: () => !this.inputGroup.isSubmitting,
      showStatusMessage: false,
      error: () => this.validateEndTime()
    });

    // clip expanded inputs
    this.momentType = input(this, {
      name: 'momentType',
      selectorItems: MomentTypes,
      isRequired: () => this.clipType.value === 'Moment',
      onChange: () => {
        if (this.clipType.value === 'Moment') {
          const track = this.job?.maybeGetMomentTrackByMomentType(this.momentType.normValue ?? null);
          //@ts-ignore
          this.track.value = track ? {
            value: track.id,
            label: track.displayName,
          } : null;
        }
      }
    });

    this.name = input(this, {
      name: 'name',
      isRequired: () =>
        ['Moment', 'Topic', 'SubTopic', 'Chapter'].includes(this.clipType.value!),
      charCountMax: () => {
        if (['Topic', 'Chapter'].includes(this.clipType.value!)) return 60;
        if (['SubTopic', 'Moment'].includes(this.clipType.value!)) return 100;
      }
    });

    this.sentiment = input(this, {
      name: 'sentiment',
      export: (self: InputState) => self.normValue,
      selectorItems: () => Sentiments.map(value => ({
        value,
        label: getSentimentLabel(value)
      }))
    });

    this.speaker = input(this, {
      name: 'speaker',
      selectorItems: () => this.speakerItems,
      error: (input) => this.clipType.value === 'Transcript' && input.isEmpty ? `Required for 'Transcript'` : null,
      export: (self: InputState) => self.normValue,
      onChange: () => {
        if (this.clipType.value === 'Transcript') {
          const track = this.job?.maybeGetTranscriptTrackBySpeaker(this.speaker.normValue ?? null);
          //@ts-ignore
          this.track.value = track ? {
            value: track.id,
            label: track.displayName,
          } : null;
        }
      }
    });

    this.keywords = input(this, {
      name: 'keywords',
    })

    this.importance = input(this, {
      name: 'importance',
    });

    this.description = input(this, {
      name: 'description',
      multiline: true,
      error: () => this.validateDescription()
    });

    this.summary = input(this, {
      name: 'summary',
      multiline: true
    });

    this.track = input(this, {
      name: 'track',
      selectorItems: () => this.trackItems,
      export: (self: InputState) => self.normValue,
      onChange: () => {
        if (this.clipType.value === 'Transcript') {
          //@ts-ignore
          this.speaker.value = this.trackModel?.speaker ? {
            value: this.trackModel?.speaker.id,
            label: this.trackModel?.speaker.name,
          } : null;
        }

        if (this.clipType.value === 'Moment') {
          //@ts-ignore
          this.momentType.value = this.trackModel?.momentType ?? null;
        }
      },
      isRequired: () => this.isTrainerLayout
    });

    this.language = input(this, {
      name: 'language',
      selectorItems: () => LanguageItems,
      export: (self: InputState) => self.normValue
    });

    this.visibleToConsumer = input(this, {
      name: 'visibleToConsumer',
      multiline: true,
    });

    this.clipBasicInputGroup = inputGroup(this, {
      name: 'clipBasic',
      inputs: [
        this.clipType,
        this.startTime,
        this.endTime
      ],
      error: () => this.validateBasicForm()
    });

    this.clipExtensionInputGroup = inputGroup(this, {
      name: 'clipExtension',
      inputs: () =>
        [
          this.momentType,
          this.name,
          this.sentiment,
          this.speaker,
          this.keywords,
          this.importance,
          this.description,
          this.summary,
          this.language,
          this.visibleToConsumer,
          this.track
        ]
    })

    this.inputGroup = inputGroup(this, {
      name: 'window',
      inputs: [
        this.clipBasicInputGroup,
        this.clipExtensionInputGroup
      ],
      isSubmitDisabled: () =>
        this.inputGroup.inputs.some(input =>
          //@ts-ignore
          input.inputs.some(input => input.status === 'error' && input.isTouched && !input.isFocused)) ||
        this.showErrorNotification
    });

    this.window.listen(this.windowListener);
  }

  @observable mode: ClipWindowMode | null = null;
  @observable layout: Layout = 'Trainer';

  @observable jobId: string | null = null;

  @observable trackId: number | null = null;

  @observable isLoading: boolean = false;

  readonly inputGroup: InputGroupState;
  readonly clipBasicInputGroup: InputGroupState;
  readonly clipExtensionInputGroup: InputGroupState;

  readonly clipType: InputState;
  readonly track: InputState;
  readonly startTime: InputState;
  readonly endTime: InputState;
  readonly momentType: InputState;
  readonly name: InputState;
  readonly sentiment: InputState;
  readonly speaker: InputState;
  readonly keywords: InputState;
  readonly importance: InputState;
  readonly description: InputState;
  readonly summary: InputState;
  readonly language: InputState;
  readonly visibleToConsumer: InputState;

  readonly window = new WindowState(this.store);

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
        if (this.inputGroup.isSubmitting)
          return;
        this.cancel();
        break;
      case 'outsideClick':
        if (this.inputGroup.isSubmitting)
          return;
        this.cancel(true);
        break;
    }
  };

  @action
  private auxWindowListener = (msg: Message<SpeakerWindowState | TrackWindowState>) => {
    const { type, payload } = msg;
    switch (type) {
      case 'speakerCreated':
      case 'speakerUpdated':
        const { speakerId } = payload;
        const speakerItem = this.speakerItems.find(({ value }) => value === speakerId) ?? null;
        if (speakerItem) {
          this.speaker.handleChange(null, speakerItem);
        }

        break;
      case 'trackCreated':
      case 'trackUpdated':
        const { trackId } = payload;
        const trackitem = this.trackItems.find(({ value }) => value === trackId) ?? null;
        if (trackitem) {
          this.track.handleChange(null, trackitem);
        }
        break;
    }
  };

  @observable isVisible = false;
  @observable source: any = null;
  @observable editModelId: string | null = null;

  @observable momentKindLabel: MomentKindLabel = 'moment';
  @observable closedInfoModal: boolean = false;

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed get trackModel(): TrackModel | null {
    if (!this.job || !this.track.normValue)
      return null;

    return this.job.getTrack(this.track.normValue);
  }

  @computed get speakerItems(): DropdownItemObject[] {
    const { job } = this;
    if (!job)
      return [];

    return job.trackSpeakers
      .map(speaker => ({
        value: speaker.id,
        label: speaker.name
      })) || [];
  }

  @computed get trackItems(): DropdownItemObject[] {
    const { job } = this;
    if (!job)
      return [];

    return job.tracks
      .filter(item => item.type === this.clipType.value).map(item => ({
        value: item.id,
        label: item.displayName
      }));
  }

  @computed get effectiveStartTime(): number {
    const { editModel, startTime, mode } = this;
    if (startTime.isDirty ||
      mode !== 'Edit' ||
      !Number.isFinite(editModel?.startTime))
      // use the input value only if it was changed ||
      // window not in edit mode ||
      // model value is null or undefined (for avoiding errors while fetching)
      return toInteger(startTime.value);
    else {
      assert(timestampNorm(editModel?.startTime!) === toInteger(startTime.value),
        'Start timestamp determination issue');
      return editModel?.startTime!;
    }
  }

  @computed get effectiveEndTime(): number {
    const { editModel, endTime, mode } = this;
    if (endTime.isDirty ||
      mode !== 'Edit' ||
      !Number.isFinite(editModel?.endTime))
      // use the input value only if it was changed ||
      // window not in edit mode ||
      // model value is null or undefined (for avoiding errors while fetching)
      return toInteger(endTime.value);
    else {
      assert(timestampNorm(editModel?.endTime!) === toInteger(endTime.value),
        'End timestamp determination issue');
      return editModel?.endTime!;
    }
  }

  @computed get editModel(): MomentModel | null {
    const { job, editModelId } = this;
    if (!editModelId || !job)
      return null;

    return job.getMoment(editModelId) || null;
  }

  @computed get inputs(): string[] | null {
    const clipType = this.clipType.value?.toLowerCase() ?? null;

    if (!clipType)
      return null;

    if (this.isTrainerLayout && hasKey(TrainerInputs, clipType))
      return TrainerInputs[clipType];

    if (this.isUserLayout && hasKey(UserInputs, clipType))
      return UserInputs[clipType];

    return null;
  }

  @computed get clipTypeItems(): string[] {
    if (this.isUserLayout)
      return [];

    if (this.isTrainerLayout)
      return ClipTypes;

    return [];
  }

  @computed get isCreateMode(): boolean {
    return this.mode === ClipWindowMode.Add;
  }

  @computed get isEditMode(): boolean {
    return this.mode === ClipWindowMode.Edit;
  }

  @computed get isIdleMode(): boolean {
    return !this.mode;
  }

  @computed get isUserLayout(): boolean {
    return this.layout === 'User';
  }

  @computed get isTrainerLayout(): boolean {
    return this.layout === 'Trainer';
  }

  @computed get shouldHaveParentClip(): boolean {
    return shouldHaveParentClip(this.clipType?.value as ClipType);
  }

  @computed get shouldHaveExtractor(): boolean {
    let type = this.clipType?.value || null;
    return type === 'Chapter';
  }

  @computed get parent(): MomentModel | null {
    const { job } = this;
    if (!job)
      return null;
    // exclude edit model id for situations when a topic is changed to subtopic 
    // and will see itself as parent instead of returning null
    const excludedIds = this.editModel ? [this.editModel?.id] : undefined;
    return job.getIntervalParent(this.effectiveStartTime, this.effectiveEndTime, excludedIds) || null;
  }

  @computed get hasOverlappingTrackClip(): boolean {
    const { job } = this;
    if (!job)
      return false;
    // exclude edit model id for situations when a topic is changed to subtopic 
    // and will see itself as parent instead of returning null
    const excludedIds = this.editModel ? [this.editModel?.id] : undefined;
    const topics = job.getTopicsOverlappingInterval(this.effectiveStartTime, this.effectiveEndTime)
      .filter(el => excludedIds ? !excludedIds.includes(el.id) : el)
      .filter(el => el.trackId === this.track.normValue);
    return topics.length > 0;
  }

  @computed get isContained(): boolean {
    const { effectiveStartTime, effectiveEndTime, editModel, job } = this;
    if (!job)
      return false;
    // exclude itself from parenthood
    const excludedIds = editModel ? [editModel.id] : undefined;
    return job.isIntervalContained(effectiveStartTime, effectiveEndTime, excludedIds);
  }

  @computed get children(): MomentModel[] {
    const { job, effectiveStartTime, effectiveEndTime, editModel, clipType } = this;
    if (!job)
      return [];
    // if initial value of clip type is 'Topic', the children are determined using the initial boundaries, else current boundaries determine the children
    if (clipType.initialValue === 'Topic' && editModel)
      return job.getMomentChildren(editModel);
    else
      return job.getIntervalChildren(effectiveStartTime, effectiveEndTime);
  }

  @computed get hasChildren(): boolean {
    return (this.children && this.children.length > 0) || false;
  }

  @computed get areChildrenContained(): boolean {
    const { job } = this;
    if (!job) return false;
    return job.getIntervalUncontainedChildren(
      this.effectiveStartTime,
      this.effectiveEndTime
    ).length === 0;

    // const startTime = timestampNorm(this.effectiveStartTime);
    // const endTime = timestampNorm(this.effectiveEndTime);

    // if (startTime && endTime)
    //   return !this.children?.find(el =>
    //     el.startTime < startTime ||
    //     el.endTime > endTime
    //   );

    // return true;
  }

  @computed get isExpanded(): boolean {
    return !!this.clipType.value;
  }

  @computed get shouldCreateTrack() {
    return this.isUserLayout && this.track.isEmpty && this.isCreateMode && this.clipType.normValue === 'SubTopic'; // for safe measure
  }

  @computed get isActive(): boolean {
    return !!this.trackModel?.isActive;
  }

  @computed get showErrorNotification(): boolean {
    return !!this.clipBasicInputGroup.error &&
      this.clipBasicInputGroup.error !== 'Required field' &&
      !this.inputGroup.isSubmitting;
  }

  getModelProps(): AddMomentInput & UpdateMomentInput {
    const momentId = (this.isEditMode ? this.editModel?.id : undefined);
    const dataGroup = this.inputGroup.export();
    const formData = Object.assign({}, dataGroup.clipBasic, dataGroup.clipExtension);
    const startTime = this.effectiveStartTime;
    const endTime = this.effectiveEndTime;

    return {
      momentId: momentId!,
      trackId: formData.track,
      source: this.source ?? 'User',
      clipType: formData.clipType,
      name: formData.name ?? null,

      jobId: this.jobId!,
      startTime: startTime!,
      endTime: endTime!,

      keywords: ((formData.keywords as string) ?? '')
        .split(',')
        .map(k => k.trim())
        .filter(k => k),

      momentType: formData.momentType ?? null,
      speakerId: formData.speaker ?? null,
      description: this.description.isDirty ? (formData.description ?? null) : undefined,
      summary: this.summary.isDirty ? (formData.summary ?? null) : undefined,
      importance: parseInt(formData.importance) ?? 0,
      sentiment: formData.sentiment ?? null,
      languageCode: formData.language ?? null,
      visibleToConsumer: formData.visibleToConsumer ?? false
    }
  }

  validateClipEdit = (): string | null => {
    const { job, editModel } = this;
    const jobStatus = job?.status ?? null;
    const clipType = this.clipType.value;

    if (!editModel)
      return 'No edit model';

    if (!this.track.normValue)
      return 'No track available.';

    if (clipType === 'Topic' && this.hasOverlappingTrackClip) // validate topic doesn't overlap another topic after edit
      return 'Overlapping topics.';

    if (jobStatus !== 'Done' || !this.isActive) // parent <-> child dependency applies only when job status is 'Done' 
      return null;

    if (editModel.isTopic && clipType !== 'Topic' && this.hasChildren) // validate change from topic
      return 'Topic has underlying subtopics.';

    if (this.shouldHaveParentClip && !this.parent) // validate children has parent (subtopic or moment) after edit
      return 'Subtopic should have a parent.';

    if (this.shouldHaveParentClip && !this.isContained) // validate child in parent boundaries
      return 'Outside of parent boundaries.';

    if (clipType === 'Topic' && !this.areChildrenContained) // validate the children are contained in parent topic after edit
      return 'Underlying subtopics exceed the topic boundaries!';

    return null;
  }

  validateClipCreate = (): string | null => {
    const { job } = this;
    const clipType = this.clipType.value;
    const jobStatus = job?.status ?? null;

    if (!this.track.normValue)
      return 'No track available.';

    if (clipType === 'Topic' && this.hasOverlappingTrackClip) // validate topic doesn't overlap another topic
      return 'Overlapping topics.';

    if (jobStatus !== 'Done' || !this.isActive) // parent <-> child dependency applies only when job status is 'Done' and on active track
      return null;

    if (this.shouldHaveParentClip && !this.parent) // validate child has parent (subtopic or moment)
      return 'Subtopic should have a parent.';

    if (this.shouldHaveParentClip && !this.isContained) // validate child in parent boundaries
      return 'Outside of parent boundaries.';

    return null;
  }

  @computed get isStartOutsideParentClip(): boolean {
    const { job, parent } = this;
    const start = timestampNorm(this.effectiveStartTime);

    if (!job || !parent)
      return false;

    return job.isDone &&
      this.shouldHaveParentClip &&
      (start < parent.startTimeNorm)
  }

  @computed get isEndOutsideParentClip(): boolean {
    const { job, parent } = this;
    const end = timestampNorm(this.effectiveEndTime);
    if (!job || !parent)
      return false;

    return job.isDone &&
      this.shouldHaveParentClip &&
      (end > parent.endTimeNorm)
  }

  validateStartTime = (): string | null => {
    const { job, isStartOutsideParentClip } = this;
    const clipType = this.clipType.value;
    const videoDuration = job?.videoDurationNorm ?? 0
    const start = timestampNorm(this.effectiveStartTime);
    const end = timestampNorm(this.effectiveEndTime);

    if (!job)
      return 'Job Required';
    if (clipType !== 'Transcript' && start >= end)
      return 'Start time cannot be greater than or equal to the end time.';
    if (clipType === 'Transcript' && start > end)
      return 'Start time cannot be greater than the end time.';
    if (start > videoDuration)
      return 'Time point is exceeding the length of the video';
    if (isStartOutsideParentClip)
      return 'Start point is outside of the clips parent. Adjust Start point to continue.';

    return null;
  }

  validateEndTime = (): string | null => {
    const { job, isEndOutsideParentClip } = this;
    const clipType = this.clipType.value;
    const videoDuration = job?.videoDurationNorm ?? 0
    const start = timestampNorm(this.effectiveStartTime);
    const end = timestampNorm(this.effectiveEndTime);

    if (!job)
      return 'Job Required';
    if (clipType !== 'Transcript' && end <= start)
      return 'End time cannot be before or equal to the start time.';
    if (clipType === 'Transcript' && end < start)
      return 'End time cannot be before the start time.';
    if (end > videoDuration)
      return 'Time point is exceeding the length of the video';
    if (isEndOutsideParentClip)
      return 'End point is outside of the clips parent. Adjust end point to continue.';

    return null;
  }

  validateDescription = (): string | null => {
    if (this.clipType.value === 'Transcript')
      return (this.description.isEmpty && this.description.isTouched) ? `Required for 'Transcript'` : null;

    return null;
  }

  validateBasicForm = (): string | null => {
    const { job } = this;
    const videoDuration = job?.videoDurationNorm ?? 0;
    const clipType = this.clipType.value;
    const start = timestampNorm(this.effectiveStartTime);
    const end = timestampNorm(this.effectiveEndTime);
    const inputError = this.clipBasicInputGroup.inputs.find(input => input.error)?.error as string ?? null;

    if (!job)
      return 'Job Required';
    if (clipType !== 'Transcript' && start >= end)
      return 'Start time cannot be greater than or equal to the end time.';
    if (clipType === 'Transcript' && start > end)
      return 'Start time cannot be greater than the end time.';
    if (start > videoDuration || end > (videoDuration))
      return 'Time point is exceeding the length of the video.';
    if (inputError)
      return inputError ?? 'Unknown Error';
    if (job.isDone && this.shouldHaveParentClip && !this.parent)
      return 'Parent topic required.';
    if (clipType === 'Topic' && this.hasOverlappingTrackClip)
      return 'Overlap with another topic found. Change the Clip type or the start and/or end points to continue.';
    if (job.isDone &&
      clipType === 'Topic' &&
      !this.areChildrenContained)
      return 'Underlying children exceed the topic boundaries!';

    return null;
  }

  submit = async () => {
    assertNotNull(this.job);

    this.inputGroup.handleSubmit();
    if (this.inputGroup.error) {
      this.handleSubmitReject('Fix the validation errors before saving.');
      return;
    }

    if (this.shouldCreateTrack) {
      const [, err] = await this.submitCreateTrack();
      if (err) {
        this.handleSubmitReject(`Could not add the ${this.momentKindLabel} because of an error.`);
        return;
      }
      this.setActiveSubtopicTrack();
    }

    const props: AddMomentInput & UpdateMomentInput = this.getModelProps();

    switch (this.mode) {
      case 'Add':
        try {

          const errorMessage = this.validateClipCreate();

          if (errorMessage) {
            this.handleSubmitReject(errorMessage);
            return;
          }

          notifyLoading(this, `Creating ${this.momentKindLabel}`);
          this.isLoading = true;
          const [momentId, err] = await this.job.apiAddMoment(props, false);
          if (err) {
            this.handleSubmitReject(`Could not add the ${this.momentKindLabel} because of an error.`);
            break;
          }

          if (momentId)
            this.editModelId = momentId; // in order to avoid errors

          await this.job.fetchDependencies();

          this.inputGroup.handleSubmitResolve();
          notifySuccess(this, `${capitalCase(this.momentKindLabel)} was added.`);

          if (this.source === 'User') {
            this.store.analyticsService.registerEvent(
              AnalyticsEventTypes.ClipCreatedType,
              {
                momentId: momentId,
                job: this.job,
              }
            )
          }

          this.emit('momentCreated', { momentId });
          this.close();

        } catch (e) {
          this.handleSubmitReject(`Could not add the ${this.momentKindLabel} because of an error.`);
        }
        break;

      case 'Edit':
        try {

          const errorMessage = this.validateClipEdit();

          if (errorMessage) {
            this.inputGroup.handleSubmitReject();
            notifyError(this, errorMessage);
            return;
          }

          notifyLoading(this, `Updating ${this.momentKindLabel}`);
          this.isLoading = true;
          const [momentId, err] = await this.job.apiUpdateMoment(props);
          if (err) {
            this.handleSubmitReject(`Could not update the ${this.momentKindLabel} because of an error.`);
            break;
          }

          this.inputGroup.handleSubmitResolve();
          notifySuccess(this, `${capitalCase(this.momentKindLabel)} was updated.`);

          if (this.source === 'User') {
            this.store.analyticsService.registerEvent(
              AnalyticsEventTypes.ClipEditedType,
              {
                momentId: momentId,
                job: this.job
              }
            )
          }

          this.emit('momentUpdated', { momentId });
          this.close();

        } catch (e) {

          // console.error('Error while submitting ClipWindow: ', e);
          this.handleSubmitReject(`Could not update the ${this.momentKindLabel} because of an error.`)
        }
        break;

      default:
        break;
    }
  }

  @action
  private handleSubmitReject(msg: string) {
    this.inputGroup.handleSubmitReject();
    this.window.unlock();
    this.isLoading = false;
    notifyError(this, msg);
  }

  /** Opens delete moments openWindow. */
  @action
  delete = () => {
    if (!this.jobId || !this.editModel)
      return;

    this.store.deleteMomentsPopup.open({
      jobId: this.jobId,
      momentIds: [this.editModel?.id],
      onSubmitCallback: () => {
        // this.emit('momentDeleted', { momentId: this.editModel?.id });
        this.clearState();
        this.close('close');
      }
    });
  }

  /** Hides the window and clears the entire state of the window. */
  @action
  cancel = (withConfirmation: boolean = false) => {
    if (this.closedInfoModal) {
      this.closedInfoModal = false;
      return;
    }
    if (this.inputGroup.isSubmitting || !this.isVisible)
      return;
    if (this.inputGroup.isDirty && withConfirmation)
      this.store.closeWindowConfirmationModal.open({
        onSubmit: () => {
          this.clearState();
          this.close('close');
        },
        modalMessage: `Are you sure you want to close the ${this.momentKindLabel} window? The progress will be lost.`
      })
    else
      this.close('close');
  }

  @action
  open(mode: ClipWindowMode) {
    assert(this.jobId,
      `Cannot show a ClipWindow without a valid jobId.`);

    this.subscribeListeners();
    this.isVisible = true;
    this.mode = mode;

    openWindow(this, 'ClipWindow');
    this.emit('open');
  }

  infoModalListener = (msg: Message<InfoModalState>) => {
    switch (msg.type) {
      case 'close': {
        this.closedInfoModal = true
        break;
      }
    }
  }

  @action
  clearState = () => {
    this.jobId = null;
    this.editModelId = null;
    this.inputGroup.clear();
    this.mode = null;
    this.layout = 'Trainer';
    this.momentKindLabel = 'moment';
    this.isLoading = false;
    this.unsubscribeListeners();
  }

  @action
  close(msg?: string) {
    this.isVisible = false;
    closeWindow(this);
    if (msg)
      this.emit(msg);
  }

  private subscribeListeners() {
    const { infoModal, speakerWindow, trackWindow } = this.store;
    infoModal.listen(this.infoModalListener);
    speakerWindow.listen(this.auxWindowListener);
    trackWindow.listen(this.auxWindowListener);
  }

  private unsubscribeListeners() {
    const { infoModal, speakerWindow, trackWindow } = this.store;
    infoModal.unlisten(this.infoModalListener);
    speakerWindow.unlisten(this.auxWindowListener);
    trackWindow.unlisten(this.auxWindowListener);
  }

  async ensureEditModel() {

    if (!this.jobId || !this.editModelId)
      return;

    if (!this.job) {
      const [, err] = await this.store.jobManager.apiFetchJob(this.jobId);

      if (err || !this.job) {
        //notify error
        return;
      }
    }

    if (!this.editModel) {
      const [, refErr] = await this.job.apiFetchMoments();
      if (refErr || !this.editModel) {
        //notify error
        return;
      }
    }
  }

  @action
  init() {
    const moment = this.editModel;
    if (!moment) {
      this.inputGroup.clear();
      return;
    }

    this.momentType.loadValue(moment.momentType);
    this.clipType.loadValue(moment.clipType);
    this.name.loadValue(moment.name);
    this.description.loadValue(moment.description);
    this.summary.loadValue(moment.summary);
    this.importance.loadValue(moment.importance);
    this.sentiment.loadValue(moment.sentiment);
    this.keywords.loadValue((moment.keywords || []).join(','));
    this.startTime.loadValue(moment.startTimeNorm);
    this.endTime.loadValue(moment.endTimeNorm);
    this.visibleToConsumer.loadValue(moment.visibleToConsumer);
    this.speaker.loadValue(
      moment.speaker ? {
        value: moment.speaker.id,
        label: moment.speaker.name
      } : null
    );

    this.track.loadValue(
      moment.track ? {
        value: moment.track.id,
        label: moment.track.displayName
      } : null
    );

    this.language.loadValue(
      getLanguageInputItem(moment.languageCode) || // clip langage code
      getLanguageInputItem(this.job?.languageCode || null) || // job language code
      getDefaultLanguageValue() || // the default language code - 'en-US' if no clip or job language code is set
      null
    );
  }

  @action
  openMomentEdit(args: OpenEditArgs) {
    const { jobId, momentId, source, options } = args;

    assert(!this.isVisible,
      `ClipWindow is already opened`);

    assert(jobId,
      `Cannot open ClipWindow for editing because he jobId was not provided.`);

    this.editModelId = momentId;
    this.jobId = jobId;

    assert(this.editModel instanceof MomentModel,
      `Cannot open ClipWindow for editing because the ${this.momentKindLabel} is not an instance of MomentModel.`);

    this.init();
    this.source = source ?? 'User';
    this.momentKindLabel = options?.momentKindLabel ?? 'moment';
    this.layout = options?.layout ?? 'Trainer';
    this.open(ClipWindowMode.Edit);
  }

  @action
  openMomentStub(args: OpenCreateArgs) {
    const { stub, jobId, source, options } = args;

    assert(!this.isVisible,
      `ClipWindow is already opened.`);

    // validate and set jobId
    assert(jobId,
      `Cannot open ClipWindow from stub object. The jobId was not provided.`);
    this.jobId = jobId;

    // validate and set start and end time
    const { startTimeNorm, endTimeNorm } = stub;
    assert(startTimeNorm >= 0 && endTimeNorm >= 0 && startTimeNorm <= endTimeNorm,
      `Cannot open ClipWindow from stub object. Both 'startTime' and 'endTime' must be positive or 0, and 'startTime' must be less than or equal to 'endTime'.`);

    const { job } = this;
    // validate and set jobId
    assert(job,
      `Cannot open ClipWindow from stub object. The job does not exist.`);

    this.startTime.loadValue(startTimeNorm);
    this.endTime.loadValue(endTimeNorm);
    this.visibleToConsumer.loadValue(true);

    this.language.loadValue(
      getLanguageInputItem(job.languageCode || null) || // job language code preset at clip creation
      getDefaultLanguageValue() || // the default language code - 'en-US' if no job language code
      null
    );

    this.source = source ?? 'User';
    this.momentKindLabel = options?.momentKindLabel ?? 'moment';
    this.layout = options?.layout ?? 'Trainer';

    if (this.isUserLayout) {
      this.clipType.loadValue('SubTopic');
      this.setActiveSubtopicTrack();
    }

    this.open(ClipWindowMode.Add);
  }

  @action
  setActiveSubtopicTrack() {
    if (this.clipType.normValue !== 'SubTopic')
      return;
    const track = this.job?.activeSubtopicTrack;
    this.track.loadValue(
      track ? {
        value: track.id,
        label: track.displayName
      } : null
    );
  }

  @action
  onTransitionEnd = () => {
    if (!this.isVisible)
      this.clearState();
  }

  @action
  openTrackWindow() {
    if (!this.job || this.isUserLayout)
      return;
    const trackWindow = this.store.trackWindow;
    trackWindow.openCreate(this.job?.id);
  }

  @action
  openSpeakerWindow() {
    if (!this.job || this.isUserLayout)
      return;
    const speakerWindow = this.store.speakerWindow;
    speakerWindow.openCreate(this.job?.id);
  }

  @action
  async submitCreateTrack() {
    if (!this.job || !this.clipType.normValue || this.isTrainerLayout) {
      return [null, 'Prerequisited failed']
    }

    if (this.job.activeSubtopicTrack)
      return [true];

    const type = this.clipType.normValue;
    const name = this.clipType.normValue + '.user';

    const addArgs: AddTrackMutationVariables = {
      args: {
        jobId: this.job.id,
        name: name,
        type: type as ClipType,
        visibleToConsumer: true
      }
    }
    const [, err] = await this.job.apiAddTrack(addArgs);

    if (err)
      return [null, err];

    return [true]
  }
}

