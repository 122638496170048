import { makeObservable } from 'mobx';

import { Store } from '../../store/store';
import { StoreNode, Message } from '../../store';
import { createScript } from './gtm';
import { createAsyncScript, createInitScript, createNoScriptPixel } from './linkedIn';
import { UserProfile } from '../../entities';
import { AnalyticsStreamParams, AnalyticsStreamData, AnalyticsStreamsList, AnalyticsProviders, AnalyticsEventTriggerType } from './analyticsSchema';
import { KeenAnalyticsProvider } from './provider';
import { AnalyticsStream, AnalyticsPlayerReactionStream, AnalyticsPlayerStream, AnalyticsCommentInputStream, AnalyticsClipBookmarkStream, AnalyticsVideoUploadStream, AnalyticsVideoShareStream } from './stream';
import { PlayerState } from '../../components';
import { BookmarkWindowState } from '../../components/bookmarks/bookmarkWindowState';
import { PlayerAnalyzer } from './analyzers/playerAnalyzer';
import { AnalyticsBeaconCoordinator } from './beacon';
import { AnalyticsPageViewStream } from './stream/analyticsPageViewStream';
import { AnalyticsPlayerMetricsStream } from './stream/analyticsPlayerMetricsStream';
import { PlayerWidgetState } from '../../widgets';
import { UserPlayerPageState } from '../../pages/userPlayerPage/userPlayerPageState';
import { HubspotProvider } from './hubspot';
import { InstallContext } from './installContext';
import { ViewContext } from './viewContext';

const KEEN_PROJECT_ID: string = process.env.REACT_APP_KEEN_PROJECT_ID || '';
const KEEN_WRITE_KEY: string = process.env.REACT_APP_KEEN_WRITE_KEY || '';
const GTM_ID = process.env.REACT_APP_GTM_ID;
const LINKEDIN_ID = process.env.REACT_APP_LINKEDIN_ID;

export const sendUserDetails = (user: UserProfile | null, action: 'login' | 'logOut') => {
  if (!user)
    return;

  // @ts-ignore
  window.hj = window.hj || function () { (hj.q = hj.q || []).push(arguments) };
  // @ts-ignore
  const userId = hj.globals?.get('userId');

  const payload = {
    email: user.email,
    action,
    id: user.id
  };

  // @ts-ignore
  if (typeof window.hj === 'function') {
    // @ts-ignore
    hj('identify', userId, payload);
  }
}

const createNoScript = (id: string) => {
  const noscript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${id}`;
  iframe.height = '0';
  iframe.width = '0';
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  noscript.appendChild(iframe);

  return noscript;
};

export const addGoogleTagManager = () => {
  if (GTM_ID) {
    const script = createScript(GTM_ID);
    const noscript = createNoScript(GTM_ID);

    document.head.appendChild(script);
    document.body.appendChild(noscript);
  }
};

export const addLinkedInTracking = () => {
  if (!LINKEDIN_ID) return;
  const initScript = createInitScript(LINKEDIN_ID);
  document.head.appendChild(initScript);

  const asyncScript = createAsyncScript(initScript);
  document.head.appendChild(asyncScript);

  const noscript = createNoScriptPixel(LINKEDIN_ID);
  document.body.appendChild(noscript);
}

export class GoogleTagManagerProvider extends StoreNode {
  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.receive(this.receiver, [
      'RoutingService:routeChanged',
      'UserVideoPage:videoLoaded',
      'UserVideoPage:videoSplashLoaded',
      'OnboardPage:mounted'
    ]);
  }

  private receiver = (msg: Message) => {
    switch (msg.fullType) {
      case 'RoutingService:routeChanged':
        this.routeChanged(msg.payload.location.pathname);
        break;

      case 'UserVideoPage:videoLoaded':
      case 'UserVideoPage:videoSplashLoaded':
        this.videoLoaded(msg.payload);
        break;

      case 'OnboardPage:mounted':
        this.onboardMounted();
        break;

      default:
        break;
    }
  }

  private routeChanged = (newRoute: string) => {
    // @ts-ignore
    if (window.dataLayer) {
      if (newRoute.includes('/user/video'))
        // do nothing for video routes because they will be handled by `videoLoaded`
        return;

      // @ts-ignore
      window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': newRoute,
        'pageTitle': newRoute
      });
    }
  }

  private videoLoaded = (payload?: Partial<{ jobId: string, title: string, path: string }>) => {
    // @ts-ignore
    if (window.dataLayer) {
      // @ts-ignore
      window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': payload?.path,
        'pageTitle': payload?.title
      });
    }
  }

  private onboardMounted = () => {
    // @ts-ignore
    if (window.dataLayer) {
      // @ts-ignore
      window.dataLayer.push({
        'event': 'Conversion',
      });
    }
  }
}
export class HotJarProvider
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.receive(this.receiver, [
      'AuthService:oauthCallbackSuccess',
      'AuthService:oauthLogoutSuccess'
    ]);
  }

  private receiver = (msg: Message) => {
    const user = this.store.user;

    switch (msg.fullType) {
      case 'AuthService:oauthCallbackSuccess':
        sendUserDetails(user, 'login');
        break;

      case 'AuthService:logout':
        sendUserDetails(user, 'logOut');
        break;

      default:
        break;
    }
  }
}
export class AnalyticsService
  extends StoreNode {

  readonly googleTagManagerProvider =
    new GoogleTagManagerProvider(this.store);
  readonly hotJarProvider =
    new HotJarProvider(this.store);
  readonly hubspotProvider =
    new HubspotProvider(this.store);

  //Providers
  readonly keenAnalyticsProvider = new KeenAnalyticsProvider(this.store, {
    projectId: KEEN_PROJECT_ID,
    writeKey: KEEN_WRITE_KEY
  });

  readonly installContext = new InstallContext(this.store);
  readonly viewContext = new ViewContext(this.store);

  //Streams
  readonly analyticsDefaultStream = new AnalyticsStream(this.store);
  readonly analyticsPlayerStream = new AnalyticsPlayerStream(this.store);
  readonly analyticsPlayerReactionStream = new AnalyticsPlayerReactionStream(this.store);
  readonly analyticsCommentInputStream = new AnalyticsCommentInputStream(this.store);
  readonly analyticsClipBookmarkStream = new AnalyticsClipBookmarkStream(this.store);
  readonly analyticsVideoUploadStream = new AnalyticsVideoUploadStream(this.store);
  readonly analyticsVideoShareStream = new AnalyticsVideoShareStream(this.store);
  readonly analyticsPageViewStream = new AnalyticsPageViewStream(this.store);
  readonly analyticsPlayerMetricsStream = new AnalyticsPlayerMetricsStream(this.store);

  readonly beaconCoordinator = new AnalyticsBeaconCoordinator(this.store);

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  get providers(): AnalyticsProviders {
    return {
      [KeenAnalyticsProvider.providerName]: this.keenAnalyticsProvider
    };
  }

  get streams(): AnalyticsStreamsList {
    return {
      [AnalyticsPlayerStream.streamName]: this.analyticsPlayerStream,
      [AnalyticsPlayerReactionStream.streamName]: this.analyticsPlayerReactionStream,
      [AnalyticsCommentInputStream.streamName]: this.analyticsCommentInputStream,
      [AnalyticsClipBookmarkStream.streamName]: this.analyticsClipBookmarkStream,
      [AnalyticsVideoUploadStream.streamName]: this.analyticsVideoUploadStream,
      [AnalyticsVideoShareStream.streamName]: this.analyticsVideoShareStream,
      [AnalyticsPageViewStream.streamName]: this.analyticsPageViewStream,
      [AnalyticsPlayerMetricsStream.streamName]: this.analyticsPlayerMetricsStream
    }
  }

  init() {
    this.installContext.init();
    this.viewContext.init();
    this.initListeners();
    this.initPlayerListeners();
  }

  initListeners() {
    return [
      // TODO: check that the behavior is still the same after the merging of the controller with the player state

      // this.store.userPlayerPage.player.controller.listen(
      //   (msg: Message<PlayerState>) => this.registerEvent(AnalyticsPlayerStream.streamName, msg)),
      this.store.userPlayerPage.player.listen(
        (msg: Message<PlayerState>) => this.registerEvent(AnalyticsPlayerStream.streamName, msg)),

      // this.store.playerWidget.player.controller.listen(
      //   (msg: Message<PlayerState>) => this.registerEvent(AnalyticsPlayerStream.streamName, msg)),
      this.store.playerWidget.player.listen(
        (msg: Message<PlayerState>) => this.registerEvent(AnalyticsPlayerStream.streamName, msg)),

      this.store.bookmarkWindow.listen(
        (msg: Message<BookmarkWindowState>) =>
          msg.type === 'bookmark:created' && this.registerEvent(AnalyticsClipBookmarkStream.streamName, msg.payload)
      ),
      this.store.uploadService.listen(
        (msg: Message<any>) =>
          msg.type === 'upload:taskCompleted' && this.registerEvent(AnalyticsVideoUploadStream.streamName, msg.payload)
      ),
      this.store.ingestFromExternalLibraryWindow.listen(
        (msg: Message<any>) =>
          msg.type === 'job:ingestStarted' && this.registerEvent(AnalyticsVideoUploadStream.streamName, msg.payload)
      ),
      this.store.shareVideoWindow.listen(
        (msg: Message<any>) =>
          ['bookmark:share', 'video:share'].includes(msg.type) && this.registerEvent(AnalyticsVideoShareStream.streamName, msg.payload)
      ),
      this.receive(
        (msg: Message) => {
          switch (msg.fullType) {
            case 'UploadLinkItemState:linkSubmitted':
              this.registerEvent(AnalyticsVideoUploadStream.streamName, msg.payload);
              break;
            case 'PlayerState:reactionAdded':
              this.registerEvent(AnalyticsPlayerReactionStream.streamName, msg.payload);
              break;
            case 'CommentInputState:commentAdded':
              this.registerEvent(AnalyticsCommentInputStream.streamName, msg.payload);
              break;
            case 'RoutingService:routeChanged':
              this.registerEvent(AnalyticsPageViewStream.streamName, msg.payload);
              break;
          }
        },
        ['UploadLinkItemState:linkSubmitted', 'PlayerState:reactionAdded', 'CommentInputState:commentAdded', 'RoutingService:routeChanged']
      )
    ]
  }

  registerEvent<EventKey extends keyof AnalyticsStreamParams>(type: EventKey, payload: AnalyticsStreamParams[EventKey]) {
    let filteredPayload: AnalyticsStreamData[EventKey] | null = null;

    if (this.streams && this.streams[type]) {
      filteredPayload = this.streams[type].exchangeData(payload) as AnalyticsStreamData[EventKey]
    } else {
      filteredPayload = this.analyticsDefaultStream.exchangeData(payload as AnalyticsStreamParams[EventKey]) as AnalyticsStreamData[EventKey]
    }

    if (!filteredPayload) {
      return;
    }

    const { team, teamAnalyticsExclude } = this.viewContext.export().context;

    if (
      team && 
      teamAnalyticsExclude?.includes(team.id!)) {
      filteredPayload = {
        ...filteredPayload,
        user: {
          ...filteredPayload.user,
          isExcluded: true
        }
      }
    }
    
    for (const providerName of Object.keys(this.providers)) {
      const providerClass = this.providers[providerName];

      providerClass.registerEvent(type, filteredPayload);
    }
  }


  // #region Player Analyzer
  playerAnalyzer: PlayerAnalyzer | null = null;

  private initPlayerListeners() {

    const { userPlayerPage, playerWidget } = this.store;

    // both the page and the widget can use the same listener
    // but inside the listener we must make sure that there's a safety check to not
    // overwrite the analyzer in case something's messed up in the mounting / unmounting flow
    userPlayerPage.listen(
      this.playerContainerListener);
    playerWidget.listen(
      this.playerContainerListener);

    window.addEventListener('beforeunload',
      this.handleNativeBeforeUnload);
  }

private playerContainerListener = (msg: Message<UserPlayerPageState | PlayerWidgetState>) => {

    const container = msg.sender;
    if (!container)
      return;

    const { player } = container;
    if (!player)
      return;

    switch (msg.type) {
      case 'Mounted': {
        if (this.playerAnalyzer) {
          console.error(
            `There is already a PlayerAnalyzer registered on AnalyticsService.` +
            `Please check the mounting / unmounting logic inside the Player listeners is correct.`);
          return;
        }

        const jobId = msg.payload?.params?.jobId;
        const analyzer = new PlayerAnalyzer(this.store, {
          // because we create a new analyzer for each instance we can set the props statically
          // this is because when Unmounted gets invoked, jobId on the player will be null
          jobId: jobId,
          player: player
        });

        this.playerAnalyzer = analyzer;
        this.viewContext.attachPlayerAnalyzerListener();
      } break;

      case 'Unmounted': {

        const analyzer = this.playerAnalyzer;

        if (!analyzer) {
          console.error(
            `No PlayerAnalyzer was registered on the AnalyticsService.` +
            `Please check the mounting / unmounting logic inside the Player listeners is correct.`);
          return;
        }

        const { job } = analyzer;

        if (!job) {
          console.warn(`Cannot send the PlayerMetrics event because no Job exists on PlayerAnalyzer.`);
          return;
        }

        const { metrics } = analyzer;
        if (!metrics) {
          console.warn(`Cannot send the PlayerMetrics event because the PlayerAnalyzer.metrics object is null.`);
          return;
        }

        // send the event
        this.registerEvent(AnalyticsPlayerMetricsStream.streamName, {
          job: job,
          metrics: metrics,
          triggerType: AnalyticsEventTriggerType.ExitPage
        });

        analyzer.reset();
        analyzer.dispose();
        this.viewContext.detachPlayerAnalyzerListener();
        this.playerAnalyzer = null;
      } break;
    }
  }

  private handleNativeBeforeUnload = (evt: BeforeUnloadEvent) => {
    const analyzer = this.playerAnalyzer;
    if (!analyzer)
      return;

    const { metrics, job } = analyzer;
    if (!metrics || !job)
      return;

    const payload = this.analyticsPlayerMetricsStream.exchangeData({
      job,
      metrics,
      triggerType: AnalyticsEventTriggerType.CloseTab
    });

    if (!payload)
      return;

    this.keenAnalyticsProvider.sendBeacon(AnalyticsPlayerMetricsStream.streamName, payload);
  }
  // #endregion
}
