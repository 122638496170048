import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  Button,
  DropdownInputControlled,
  TextInputControlled,
  ToggleInput,
  TextInput,
  ToggleLayout
} from '../input';
import { TeamSettingsPageState } from '../../pages/teamSettingsPage/teamSettingsPageState';
import { UploadAvatarInput } from '../uploadTeamAvatar';
import { TeamDetailsDrawer } from '../teams/teamDetailsDrawer';
import { TeamMetadataList } from '../teams/teamMetadataList';
import { TeamEnrichmentLevelList } from '../teams/teamEnrichmentLevelList';
import { PublicTeamForm } from '../teams/publicTeamForm';
import { AdsSettingsSection } from '../teams/adsSettingsSection';
import { EmbedParamsInfoPopup } from '../../pages/teamSettingsPage/embedParamsInfoPopup';
import { TeamDictionary } from '../teams/teamDictionary';
import { GenerativeAiForm } from '../teams/generativeAiForm';

type Props = {
  model: TeamSettingsPageState;
};

export const TeamEditForm = observer(({ model }: Props) => {
  return (
    <section className="teams-settings-form form">
      <div className="flex-left form-header">
        <UploadAvatarInput model={model} />

        <TextInputControlled
          model={model.teamName}
          label="Library Name"
          className="form-input team-name"
        />
      </div>

      <TextInputControlled
        model={model.teamDescription}
        label="Library Description"
        className="form-input team-description"
        showFeedback={false}
        rows={5}
      />

      <DropdownInputControlled
        model={model.teamIndustry}
        label="Field Of Activity"
        className="form-input team-industry"
        notDeselectable={true}
        showFeedback={false}
      />

      <ToggleInput
        className="medium"
        label={'Library Visibility'}
        toggled={!!model?.team?.isPublic}
        toggleLayout={ToggleLayout.Buttons}
        toggledLabel='Shareable'
        notToggledLabel='Private'
        name={'isPublic'}
        onChange={() => model.makeTeamPublic()}
        disabled={!!model?.team?.isPublic}
      />

      <ToggleInput
        className="medium"
        label={'Public Safety'}
        toggled={!!model?.team?.publicSafetyActivated}
        toggleLayout={ToggleLayout.Buttons}
        toggledLabel='On'
        notToggledLabel='Off'
        name={'publicSafety'}
        onChange={() => model.togglePublicSafety()}
        disabled={!model.isUserAdminOrTrainer}
      />

      <TeamDetailsDrawer
        className="generative-ai-drawer"
        header={'Generative AI settings'}
        description={'Select the AI provider, then enable the desired option.'}
        isExpanded={model.isChatGptSectionExpanded}
        onExpand={() => model.toggleChatGptSection()}>
       <GenerativeAiForm 
        disabled={!!model?.team?.publicSafetyActivated}
        model={model.generativeAiForm} />
      </TeamDetailsDrawer>

      <TeamDetailsDrawer
        className={'metadata-drawer'}
        header={'Advanced video details'}
        description={
          'Check options to customise the information you can add for library video content.'
        }
        isExpanded={model.isAdvancedSectionExpanded}
        onExpand={() => model.toggleAdvancedVideoDetails()}>
        <TeamMetadataList model={model.teamMetadata} />
      </TeamDetailsDrawer>

      <TeamDetailsDrawer
        className={'metadata-drawer'}
        header={'Dictionary'}
        isExpanded={model.isDictionaryExpanded}
        onExpand={() => model.toggleDictionary()}>
        <TeamDictionary model={model.teamDictionary} />
      </TeamDetailsDrawer>

      <TeamDetailsDrawer
        header={'Video enrichment level'}
        description={'Select your library default Video Enrichment Level.'}
        isExpanded={model.isVideoEnrichmentLevelExpanded}
        onExpand={() => model.toggleVideoEnrichmentLevel()}>
        <TeamEnrichmentLevelList model={model.teamEnrichmentLevel} />
      </TeamDetailsDrawer>

      <TeamDetailsDrawer
        header={'Embed settings'}
        description={
          'You can customise the appearance of your embedded library dashboard.'
        }
        note={
          'Note: Changing the Video Gallery Display, Theme Selection or adding a Open in Website URL will update the embed code below.'
        }
        className={'embed-settings-drawer'}
        isExpandable={true}
        isExpanded={model.isPublicTeamFormExpanded}
        onExpand={() => model.togglePublicTeamForm()}>
        <PublicTeamForm model={model.publicTeamForm} />
      </TeamDetailsDrawer>

      <TeamDetailsDrawer
        header={'Ads Settings'}
        description={
          'Add one or more VAST links for ads'
        }
        className={'ads-settings-drawer bmar4'}
        isExpandable={true}
        isExpanded={model.isAdsSettingsExpanded}
        onExpand={() => model.toggleAdsSettings()}>
        <AdsSettingsSection model={model.adsSettingsSectionState} />
      </TeamDetailsDrawer>

      <TextInput
        label="Embed Code"
        className="form-input embed-code"
        value={model.iframeEmbedCode}
        showFeedback={false}
        rows={5}
        infoModalContent={<EmbedParamsInfoPopup />}
        infoModalTitle={'Widget Configuration'}
        infoModalClassName="widget-params-window"
        multiline
        disabled
      />

      <Button
        className="secondary btn-outline small"
        label="Copy Code"
        onClick={() => model.copyIframeCode()}
      />

      <hr />

      <Button
        className="primary medium submit-btn"
        label="Save"
        onClick={() => model.submit()}
        disabled={
          !model.isChanged ||
          (model.form.hasErrorInputs && !model.form.isChangedSinceLastSubmit)
        }
      />
    </section>
  );
});
