import { makeObservable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { Action, createBrowserHistory, History, Location, LocationListener } from 'history';
import { RouteContext } from '../../routes/routeContext';
import { RouteDescriptor, RouteLookup, RouteState, RouteVisit } from '../../routes/routeSchema';
import { getRouteLookup } from '../../routes/routeLookup';
import { matchPath, RouteProps } from 'react-router-dom';
import { Error } from '../../core/error';
import { INIT_DEBUGGER, TRACE, TRACE_SEPARATOR_DECENT } from '../../core/debug/debugMacros';
import { last } from 'lodash';

export class HistoryManager
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    INIT_DEBUGGER(this, { color: 'black' });

    // initialize objects
    const history = createBrowserHistory<RouteState>();
    const { location } = history;

    this.history = history;
    this.lookup = getRouteLookup(this.store);

    // initialize page context
    const context = this.createContext(location);

    this.pageContext = context;
    this.contextHistory.push(context);

    this.registerVisit();

    // register listeners
    history.listen(
      this.historyListener);
  }

  readonly nodeType = 'HistoryManager';

  readonly lookup: RouteLookup;
  readonly history: History<RouteState>;
  readonly contextHistory: RouteContext[] = [];
  readonly visitHistory: RouteVisit[] = [];

  readonly pageContext: RouteContext;

  get context(): RouteContext {
    return last(this.contextHistory) ?? this.pageContext;
  }

  private createContext(location: Location) {

    const context = new RouteContext({
      location: location,
      descriptor: this.getDescriptor(location)
    });

    return context;
  }

  private historyListener: LocationListener<RouteState> = (location: Location<RouteState>, action: Action) => {

    const { contextHistory } = this;

    switch (action) {
      case 'PUSH': {
        const ctx = new RouteContext({
          location: location,
          descriptor: this.getDescriptor(location)
        });
        contextHistory.push(ctx);
      } break;

      case 'POP':
        contextHistory.pop();
        break;

      case 'REPLACE': {
        const ctx = new RouteContext({
          location: location,
          descriptor: this.getDescriptor(location)
        });
        contextHistory.pop();
        contextHistory.push(ctx);
      } break;
    }

    const { context } = this;
    this.registerVisit();

    TRACE_SEPARATOR_DECENT(this);
    TRACE(this, 'History event', action, location, context);
  }

  private registerVisit() {
    const { history } = this;
    const { context } = this;

    const pageVisit: RouteVisit = {
      action: history.action,
      context,
      index: this.visitHistory.length,
      historyIndex: history.length
    }

    this.visitHistory.push(pageVisit);
  }

  private getDescriptor(location: Location): RouteDescriptor {

    const { lookup } = this;
    const lookupPaths = Object.keys(lookup);

    for (const lookupPath of lookupPaths) {

      const desc = lookup[lookupPath];
      const query: RouteProps = {
        path: lookupPath,
        exact: desc.exact
      }

      if (matchPath(location.pathname, query))
        return desc;
    }

    throw new Error('InternalError', `There should always be a descriptor for the wildcard (*) path.`);
  }

}