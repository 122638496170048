import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/emoji/lib/plugin.css';
import './playerComments.scss';

import React, { useRef, useLayoutEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Editor from '@draft-js-plugins/editor';
import classNames from 'classnames';

import { handler } from '../../core';
import { UserAvatar } from '../layout/userAvatar';
import { CommentInputState } from './commentInputState';

type Props = {
  model: CommentInputState
};

export const CommentInput = observer((props: Props) => {
  const { model } = props;
  const { MentionSuggestions } = model.mentionPlugin;
  const { EmojiSuggestions, EmojiSelect } = model.emojiPlugin;
  const editorRef = useRef(null);

  const isError = (!model.isEdit && !model.isReply) ? model.cannotSubmit : !model.canSubmit;

  const className = classNames('comment-input', {
    error: isError
  });

  const charCountClass = classNames('count', {
    'green-count': model.canSubmit,
    'red-count': isError
  });

  useLayoutEffect(() => {
    const editor = editorRef.current;
    if (editor) model.attachDOMEditor(editor);
    if (model.isReply) model.focus();
    return () => model.detachDOMEditor();
  }, [model]);

  return (
    <div className={className}
      onClick={evt => {
        evt.stopPropagation();
        model.focus();
      }}//handler(model, 'focus')
    >
      <div className="comment-input-container">
        {!model.isEdit && (
          <UserAvatar className="input-user-avatar"
            model={model.user} />
        )}

        <div className="input-editor-wrapper">
          <div className="input-editor">
            <Editor
              ref={editorRef}
              placeholder={model.placeholder}
              editorState={model.editorState}
              onChange={handler(model, 'handleChange')}
              handleKeyCommand={handler(model, 'handleKeyCommand')}
              keyBindingFn={handler(model, 'keyBindingFn')}
              plugins={model.plugins}
              stripPastedStyles={true}
              onFocus={evt => model.handleFocus(evt)}
              onBlur={evt => model.handleBlur(evt)} />
          </div>

          <div className="input-controls">
            {model.showVideoTimeLabel && (
              <span className="text">@{model.videoTimeLabel}</span>
            )}
            <EmojiSelect />
          </div>
        </div>
      </div>

      <div className="comment-input-footer">
        <div className="character-count">
          <span className={charCountClass}>
            {model.charCount}
          </span>
          /{model.charCountMax}ch.
        </div>
        {(!model.isEdit && !model.error) && (
          <div className="send-label">
            Hit <span className="enter">Enter</span> to {model.isReply ? 'Reply' : 'Comment'}
          </div>
        )}
        {isError && (
          <div className="error-message">
            {model.error}
          </div>
        )}
      </div>

      <div className="editor-suggestions-wrapper">
        <MentionSuggestions
          open={model.hasMentionSuggestionsOpen}
          suggestions={model.mentionSuggestions}
          onOpenChange={handler(model, 'handleOpenMentionSuggestions')}
          onSearchChange={handler(model, 'handleSearchMentions')} />
        <EmojiSuggestions />
      </div>
    </div>
  );
});