import { StoreNode } from '../../../store';
import { AuthStepOrchestrator } from '../authStepOrchestrator';
import { AsyncResult } from '../../../core';
import { AuthFlowName, AuthFlowResponse, IAuthFlow } from '../authFlowSchema';
import { AuthError } from '../authError';
import { ClientMode } from '../../../kernel/kernelSchema';

export class RefreshPermitFlow
  extends StoreNode
  implements IAuthFlow {

  readonly flowName = AuthFlowName.RefreshPermit;
  readonly orchestrator = new AuthStepOrchestrator(this.store);

  async run(): AsyncResult<AuthFlowResponse> {

    const { orchestrator } = this;
    
    const [, contextErr] = await orchestrator.initContextFromSessionAndCommit();
    if (contextErr)
      return this.handleError(contextErr);

    return orchestrator.setAuthorized();
  }

  private handleError(err: AuthError) {
    const { orchestrator } = this;
    const { kernel } = this.store;

    orchestrator.invalidate();

    if (kernel.clientMode === ClientMode.Widget) {
      // basically we should just re-run the widget flow which will decide what to do from there
      // also there will be a notification because of the error
      // TODO: invalidation doesn't happen properly, app still thinks we're authenticated until the next call
      return orchestrator.setRedirectToLastWidgetRoute({ error: err });
    }

    return orchestrator.setRedirectToLoginPage({ error: err });
  }
}