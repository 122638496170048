import { Maybe, Result } from '../../core';
import { Error } from '../../core/error';
import { fromStorageValue, isStorageSupported, toStorageValue } from './storageHelpers';
import { StorageValue } from './storageSchema';

export function isSessionStorageSupported(target?: Maybe<Window>) {
  try {
    const storage = target ? target.sessionStorage : sessionStorage;
    return isStorageSupported(storage);
  } catch (e) {
    return false;
  }
}

export function getSession(key: string, decode?: false): string | null;
// eslint-disable-next-line  no-redeclare
export function getSession<T extends StorageValue = StorageValue>(key: string, decode?: true): T | null;
// eslint-disable-next-line  no-redeclare
export function getSession<T extends StorageValue = StorageValue>(key: string, decode = false): T | null {
  if (!isSessionStorageSupported())
    return null;

  return fromStorageValue(
    sessionStorage.getItem(key), decode) as T;
}

export function setSession(key: string, val: StorageValue | null, encode = false): Result<boolean> {
  if (!isSessionStorageSupported())
    return [null, new Error('StorageError', `Session storage is not available.`)];

  const value = toStorageValue(val, encode);
  try {
    sessionStorage.setItem(key, value);
  } catch (err) {
    return [null, new Error('StorageError', `An error occurred while trying to set the value in local storage`, { innerError: err })];
  }

  // check that the value has been actually set
  if (sessionStorage.getItem(key) !== value)
    return [null, new Error('StorageError', `The value could not be properly set in session storage.`)];

  return [true];
}

/**
 * Writes to the session storage of the provided Window.
 * This behavior is currently at the stub level in this Service, because implementation model might change.
 */
export function setSessionTo(target: Window, key: string, val: StorageValue | null, encode = false) {
  if (!isSessionStorageSupported(target))
    return false;
  target.sessionStorage.setItem(key,
    toStorageValue(val, encode));
}

export function removeSession(key: string) {
  if (!isSessionStorageSupported())
    return null;
  return sessionStorage.removeItem(key);
}

export function consumeSession(key: string, decode?: false): string | null;
// eslint-disable-next-line  no-redeclare
export function consumeSession<T extends StorageValue = StorageValue>(key: string, decode?: true): T | null;
// eslint-disable-next-line  no-redeclare
export function consumeSession<T extends StorageValue = StorageValue>(key: string, decode = false): T | null {
  const val = getSession<T>(key, decode as any); // TODO: check if there's a nice solution to fix the typings
  removeSession(key);
  return val;
}