import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { BulkInvitesWindowState } from './bulkInvitesWindowState';
import CSVReader from 'react-csv-reader';
import { Button, TextInputControlled, ToggleInputControlled } from '../input';
import {
  CloudUploadSVGIcon,
  FileUploadSVGIcon,
  FileUploadCompleteSVGIcon,
} from '../svg/svg';
import classNames from 'classnames';

type Props = {
  model: BulkInvitesWindowState;
  visible?: boolean;
};

export const BulkInvitesWindow = observer((props: Props) => {
  const { model } = props;

  const cssDropZoneClassName = classNames('upload-file-box upload-box', {
    visible: (!model.isFileSelected || !model.isFileValid) && !model.hasSentInvites,
  });

  const cssDropClassName = classNames('csv-drop', {
    visible: (!model.isFileSelected || !model.isFileValid) && !model.hasSentInvites,
  });

  const fileSelectedClassName = classNames('file-selected', {
    visible: model.isFileSelected && !model.hasSentInvites,
    success: model.isFileValid,
    error: !model.isFileValid,
  });

  const formatFileFize = () => {
    return (model.fileSize / 1000).toFixed(2) + ' kb';
  };

  const fileUploadStatus = model.isFileValid
    ? 'YOUR FILE HAS BEEN SUCCESSFULLY UPLOADED'
    : 'CHECK THE FILE YOU UPLOADED TO MAKE SURE IT MEETS THE UPLOAD REQUIREMENTS.';

  return (
    <Window
      model={model.window}
      visible={props.visible}
      className="bulk-invites-window"
      isLoading={model.isLoading}>
      <WindowHeader model={model.window} title="Bulk Invites" />

      <hr />
      <div className="window-content">
        {!model.hasSentInvites && (
          <p>
            Before you upload your file, make sure it does not have headers and
            it contains two columns, the first with user emails and the second
            with user role (Admin, Trainer, Member, Viewer).
          </p>
        )}
        {!model.isFileSelected && !model.hasSentInvites && (
          <a
            className="btn btn-core link link-secondary invite-template-btn"
            href="/bulk_invites_template.csv">
            DOWNLOAD CSV TEMPLATE
          </a>
        )}
        <div className={cssDropZoneClassName}>
          <div className="upload-file-wrapper">
            <div className="upload-icon">
              <CloudUploadSVGIcon />
            </div>

            <div className="text-row">
              <span>Drag and Drop a CSV file</span>

              <small>or</small>

              <Button
                label="Select files"
                className="secondary medium"
                onClick={() => {
                  (document.getElementById(
                    'csvReader'
                  ) as HTMLButtonElement).click();
                }}
              />
            </div>

            <CSVReader
              inputId="csvReader"
              onFileLoaded={(data: any, fileInfo: any) =>
                model.handleFileLoaded(data, fileInfo)
              }
              cssClass={cssDropClassName}
              inputStyle={{ opacity: 0 }}
            />
          </div>
        </div>

        <div className={fileSelectedClassName}>
          <div className="upload-file-progress">
            <div
              className={
                'upload-file-item ' +
                (model.isFileValid ? 'completed' : 'error')
              }>
              <div className="file-info">
                <span className="file-name" title={model.fileName}>
                  {model.fileName}
                </span>
                <span className="file-progress">
                  <span className="progress-value">{formatFileFize()}</span>

                  {model.isFileValid && <FileUploadCompleteSVGIcon />}
                  {!model.isFileValid && <FileUploadSVGIcon />}
                </span>
              </div>
            </div>
          </div>

          <div className="file-upload-status">{fileUploadStatus}</div>

          {model.isFileValid && !model.hasSentInvites && (
            <div className="include-message-section">
              <ToggleInputControlled
                name="includeInviteMessage"
                label="Include message"
                model={model.includeInviteMessage}
                className="medium"
              />
              {model.includeInviteMessage.value && (
                <TextInputControlled
                  className="form-input include-message"
                  model={model.inviteMessage}
                  rows={4}
                  placeholder="Add your Message"
                />
              )}
            </div>
          )}
        </div>
        {model.hasSentInvites && model.inviteErrors.length > 0 && (
          <div className="invite-errors-download">
            <p className="p-bold">
              {model.inviteSuccess.length} Invites Successfully sent!
            </p>
            <div className="flex-space-between tmar4">
              <p>Some of the invites were not sent!</p>

              <Button
                id="bulk-download-details-button"
                label="DOWNLOAD DETAILS"
                className="link link-secondary uppercase"
                onClick={() => {
                  model.bulkDownloadDetailsReport();
                }}
              />
            </div>
          </div>
        )}
      </div>

      <hr />

      <footer className="window-footer">
        <div className="btn-group left">
          <Button
            label="Back to manual invites"
            className="primary btn-outline medium"
            onClick={() => {
              model.back();
            }}
          />
        </div>
        <div className="btn-group right">
          {model.isFileValid && !model.hasSentInvites && (
            <Button
              label="Send Invites"
              className="primary medium"
              onClick={() => {
                model.submitInvites();
              }}
              disabled={
                !!(
                  model.includeInviteMessage.value && !model.inviteMessage.value
                ) ||
                !model.isFileSelected ||
                !model.isFileValid
              }
            />
          )}
          {(!model.isFileValid ||
            (model.hasSentInvites && model.inviteErrors.length > 0)) && (
              <Button
                label="Done"
                className="primary medium"
                onClick={() => {
                  model.close();
                }}
              />
            )}
        </div>
      </footer>
    </Window>
  );
});
