import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ExternalLibrary, ExternalLibrarySource, ViewerQuery } from '@clipr/lib';
import { assertNotNull, AsyncResult, Maybe } from '../../core';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { ApiResponse, ApiVariables } from '../../api/apiSchema';
import { ZoomService } from './zoom';
import { OneDriveService } from './oneDrive';
import { GoogleDriveService } from './googleDrive';
import { LibraryServiceBase, LibraryLabels, LibraryProvider } from './libraryServiceBase';
import { ApiError } from '../../api/apiError';
import { LibraryName } from './librarySchema';

export class LibraryService
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  readonly libraries = observable.map<string, ExternalLibrary>();
  readonly googleDriveService = new GoogleDriveService(this.store);
  readonly oneDriveService = new OneDriveService(this.store);
  readonly zoomService = new ZoomService(this.store);

  @observable hasFetchedOnce: boolean = false;

  @computed
  get connectedLibraries(): ExternalLibrary[] {
    return [...this.libraries.values()];
  }

  @computed
  get enabledLibraries(): LibraryLabels[] {
    return [
      this.googleDriveService,
      this.oneDriveService,
      this.zoomService
    ].filter((library: LibraryServiceBase) => !!library.enabled)
      .map((library: LibraryServiceBase) => library.libraryLabel)
  }

  getLibraryById(id: string): ExternalLibrary | null {
    return this.libraries.get(id) || null;
  }

  getLibraryBySource(source: ExternalLibrarySource): ExternalLibrary | null {
    return this.connectedLibraries.find(library => library.source === source) || null;
  }

  getLibraryServiceById(id: string): LibraryProvider | null {
    return [
      this.googleDriveService,
      this.oneDriveService,
      this.zoomService
    ].find((library: LibraryProvider) => library.library?.id === id) || null;
  }

  @action
  async apiEnsureLibraries() {
    if (!this.hasFetchedOnce) {
      return await this.fetchConnectedLibraries();
    }

    return [this.connectedLibraries];
  }

  @action
  async fetchConnectedLibraries(): AsyncResult<Maybe<ViewerQuery>> {
    this.setLoadingLibraries(true);
    const [res, err] = await this.store.api.viewer({});

    if (err) return [null, err];

    if (res?.viewer?.externalLibraries) {
      runInAction(() => {
        this.libraries.clear();
        res.viewer?.externalLibraries.forEach((library: ExternalLibrary) => this.libraries.set(library.id, library));
      })
    }

    this.hasFetchedOnce = true;
    this.setLoadingLibraries(false);
    return [res];
  }

  @action
  async connectLibrary(args: ApiVariables<'connectExternalLibrary'>): AsyncResult<ExternalLibrary> {
    assertNotNull(args);

    const [res, err] = await this.store.api.connectExternalLibraries(args);

    if (err) return [null, err];

    const library = res?.connectExternalLibrary.externalLibrary;
    if (library) {
      this.libraries.set(library.id, library);
    }

    return [library!];
  }

  @action
  async deleteLibrary(externalLibraryId: string) {
    if (!this.libraries.has(externalLibraryId)) return [false, null];

    const args: ApiVariables<'deleteExternalLibrary'> = {
      input: {
        externalLibraryId
      }
    }
    const [, err] = await this.store.api.deleteExternalLibrary(args);

    if (err) {
      return this.handleApiError(err, externalLibraryId);
    }

    runInAction(() => {
      this.libraries.delete(externalLibraryId);
    })
    return [true, null];
  }

  @action
  handleApiError(err: ApiError, libraryId?: string): ApiResponse<any> {
    if (!err) return;

    // If delete library fails in BE it means it's already been deleted by another action
    // (i.e the user disconnected from a different tab) and it also needs to be deleted in FE
    if (err.data.graphQLError?.errors?.find(err => err.errorType === 'NotFoundError')) {
      this.forceDeleteLibrary(libraryId);
      return [true, null];
    }

    return [false, err];
  }

  getProviderByName(libraryName: LibraryName): LibraryProvider {
    switch (libraryName) {
      case LibraryName.OneDrive:
        return this.oneDriveService;
      case LibraryName.GoogleDrive:
        return this.googleDriveService;
      case LibraryName.Zoom:
        return this.zoomService;
    }
  }

  @action
  private forceDeleteLibrary(id?: string) {
    if (!id) return;

    const library = this.getLibraryServiceById(id);
    if (library) {
      library.error = null;
    }

    this.libraries.delete(id);
  }

  @action
  private setLoadingLibraries(bool: boolean): void {
    this.zoomService.isLoading = bool;
    this.oneDriveService.isLoading = bool;
    this.googleDriveService.isLoading = bool;
  }
}