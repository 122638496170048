import { DateTime } from 'luxon';
import { action, computed, makeObservable, observable } from 'mobx';
import { ChangeEvent } from 'react';

import { WindowState } from '../../components/overlays';
import { Message, StoreNode } from '../../store';
import { Store } from '../../store/store';

export class ZoomDateSelectorWindowState
  extends StoreNode {

  readonly nodeType = 'ZoomDateSelectorWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);

  @observable isLoading: boolean = false;

  readonly todayDate: string = DateTime.local().toISODate();
  @observable prevStartDate: string | undefined;
  @observable prevEndDate: string | undefined;
  @observable startDate: string | undefined;
  @observable endDate: string | undefined;

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @computed get maxEndDate(): string {
    if (!this.startDate) return '';

    return DateTime.fromISO(this.startDate).plus({ month: 1 }).toISODate();
  }

  @computed
  get isButtonDisabled(): boolean {
    return !this.startDate
      || !this.endDate
      || (this.startDate === this.prevStartDate && this.endDate === this.prevEndDate);
  }
  @action
  handleEndDateChanged(evt: ChangeEvent<HTMLInputElement>) {
    this.endDate = evt.target.value;
  }
  @action
  handleStartDateChanged(evt: ChangeEvent<HTMLInputElement>) {
    this.startDate = evt.target.value;
    this.endDate = '';
  }

  @action
  submit() {
    if (!this.startDate || !this.endDate) return;

    this.store.userDashboardPage.zoomIntegrationTabState.catalogSource.startDate = this.startDate;
    this.store.userDashboardPage.zoomIntegrationTabState.catalogSource.endDate = this.endDate;
    this.store.userDashboardPage.zoomIntegrationTabState.catalogSource.fetch();
    this.close();
  }

  @action
  close() {
    this.prevStartDate = undefined;
    this.prevEndDate = undefined;
    this.startDate = undefined;
    this.endDate = undefined;

    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}