import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import ReactHotkeys, { IReactHotkeysProps } from 'react-hot-keys';
import { HotkeysControllerState } from './hotkeysControllerState';

type Props = PropsWithChildren<
  IReactHotkeysProps & {
    model: HotkeysControllerState;
  }
>;

export const HotkeysController = observer(
  ({ model, children, ...props }: Props) => {
    return (
      <div>
        <ReactHotkeys
          keyName={model.keynames}
          filter={model.filter}
          disabled={model.disabled}
          splitKey={model.splitKey}
          allowRepeat={model.allowRepeat}
          onKeyDown={(key, evt) => model.onKeyDown(key, evt)}
          onKeyUp={(key, evt) => model.onKeyUp(key, evt)}
          {...props}>
          {children}
        </ReactHotkeys>
      </div>
    );
  }
);
