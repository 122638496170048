import { makeObservable, observable, computed, action } from 'mobx';
import { input, InputState } from '../..';
import { BindingProps, StoreNode } from '../../../store';
import { Store } from '../../../store/store';
import { XYSeriesChartState } from '../xySeriesChart/xySeriesChartState';
import { ReactionChartMode, ReactionsChartInternalParams, ReactionsChartParams, ReactionSeriesData } from './reactionsChartSchema';
import { ReactionsDataSource } from './reactionsDataSource';

type Props = BindingProps<{
  params?: ReactionsChartParams
}>

export class ReactionsChartState
  extends StoreNode {

  readonly nodeType = 'ReactionsChartState';
  readonly chartTypeInput: InputState;

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.chartTypeInput = input(this, {
      name: 'audienceFocusTypes',
      onChange: this.handleChartTypeChange,
      selectorItems: [
        { value: ReactionChartMode.ReactionType, label: 'Reaction Type' },
        { value: ReactionChartMode.JobSource, label: 'Video Source' },
      ],
    });

    this.chartTypeInput.loadValue(ReactionChartMode.ReactionType);
  }

  // #region Props
  @computed get params(): ReactionsChartParams | null {
    return this.getResolvedProp('params') ?? null;
  }
  set params(val: ReactionsChartParams | null) {
    this.setProp('params', val);
  }
  // #endregion

  readonly internalParams: ReactionsChartInternalParams = observable({
    
  })

  @computed get outputParams(): ReactionsChartParams {
    const outParams = {
      ...this.internalParams,
      ...this.params
    };

    Object.freeze(outParams);
    return outParams;
  }

  @computed get outputData(): ReactionSeriesData[] {
    return [...this.dataSource.data];
  }

  readonly dataSource = new ReactionsDataSource(this.store, {
    params: () => this.outputParams
  });

  readonly xySeriesChartState = new XYSeriesChartState(this.store);

  @action
  private handleChartTypeChange = async (input: InputState, evt: React.ChangeEvent) => {
    const mode = input.value as ReactionChartMode;
    if (!mode)
      return;
    this.internalParams.mode = mode;
    this.dataSource.fetch();
  }

  async fetch() {
    return this.dataSource.fetch();
  }
  abort() {
    this.dataSource.cancel();
  }
}