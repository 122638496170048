import './trainerDashboard.scss';
import '../../components/search/searchBar.scss';

import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { UploadSVGIcon } from '../../components';
import { DateInput } from '../../components/input/dateInput/dateInput';

import { useLocation, useParams } from 'react-router-dom';
import { Button, SearchTextInputControlled } from '../../components/input';
import Routes, { BackRoutes } from '../../routes';
import { TrainerJobTableCatalog } from '../../components/jobs';
import { TrainerJobTableCatalogHeader } from '../../components/jobs/trainerJobTableCatalogHeader';
import { MasterPage } from '../../components/layout';
import { useStore } from '../../store/storeHooks';
import { ErrorPage, NotAuthorizedErrorPage } from '../error';
import { TrainerDashboardPageParams, TrainerDashboardPageState } from './trainerDashboardPageState';

type Props = {
  model: TrainerDashboardPageState;
};

/** Page containing the catalog of trainer videos. */
export const TrainerDashboardPage = observer(({ model }: Props) => {
  // page init section
  const params = useParams<TrainerDashboardPageParams>();
  useEffect(() => {
    model.pageMounted(params);
    return () => model.pageUnmounted();
  }, [model, params]);

  if (model.error) return <ErrorPage message={model.error.message} />;

  const { pathname } = useLocation();
  const link = BackRoutes[Routes.upload()].route({ currentPath: pathname });
  // data fields
  const store = useStore();

  const showLoadMask = model.isLoading;
  const { user } = store;
  const source = model.jobCatalogSource;

  if (!user?.hasPermission('ViewTrainerDashboard'))
    return <NotAuthorizedErrorPage />;

  return (
    <MasterPage
      className="trainer-dashboard-page catalog-page with-padding"
      showLoadMask={showLoadMask}
      searchBar={
        <div className="search-bar">
          <SearchTextInputControlled
            model={source.searchBarModel}
            persistentFeedback={false}
            id="search"
            name="search"
            type="text"
            autoComplete="off"
            placeholder="Search"
            showFeedback={false}
            onClear={() => source.handleSearchInputChange({})}
          />
        </div>
      }>
      <Helmet>
        <title>CLIPr - Trainer</title>
      </Helmet>

      <header className="page-header hgrid-3">
        <div className="flex-left">
          <h1>Trainer</h1>
          <div className="divider"></div>
          <div className="trainer-date-filter">
            <span className="filter-label">Date range: </span>
            <DateInput
              model={source.dateInput}
              onApply={() => { source.handleDateFilterChange() }}
            ></DateInput>
          </div>
        </div>

        <div className="user-upload flex-right">
          {user?.hasPermission('UserUpload') && (
            <Button
              route={link}
              className="upload-btn secondary large"
              icon={<UploadSVGIcon />}
              label="Upload Video"
            />
          )}
        </div>
      </header>

      <div className="trainer-job-table-header">
        <TrainerJobTableCatalogHeader
          model={model.jobCatalog}
          source={model.jobCatalogSource}
        />
      </div>

      <TrainerJobTableCatalog
        model={model.jobCatalog}
        source={model.jobCatalogSource}
      />
    </MasterPage>
  );
});

export default TrainerDashboardPage;