import { action, makeObservable, observable } from 'mobx';
import { Store } from '../store/store';
import { StoreNode } from '../store/storeNode';
import { ApiUploadRequest, ApiUploadRequestParams } from './apiUploadRequest';
import {
  ApiMutationName,
  ApiResult,
  ApiVariables,
  ApiQueryName,
  ApiQueryOptions,
  ApiMutationOptions,
  ApiRequestOptions,
  ApiRequestParams,
  DefaultApiRequestOptions,
  ApiRequestMode,
} from './apiSchema';

import { ApiMutations } from './apiMutations';
import { ApiQueries } from './apiQueries';
import { ApiError } from './apiError';
import { ApiServiceQueryHelpers } from './apiServiceQueryHelpers';
import { INIT_DEBUGGER, TRACE } from '../core/debug/debugMacros';
import { runGraphQlApiRequest } from './graphQlApiRequest';
import { AsyncResult } from '../core/async';
import { isNonEmptyString, Result } from '../core';
import { AuthContext } from '../services/auth';
import { Error } from '../core/error';
import { AuthFlowResponseType } from '../services/auth/authFlowSchema';
import { ApiMultipartUploadRequest, ApiMultipartUploadRequestParams } from './apiMultipartUploadRequest';

export const GRAPHQL_CLIENT_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT;

export class ApiService
  extends StoreNode {

  readonly nodeType = 'ApiService';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    INIT_DEBUGGER(this);
  }

  readonly queries = new ApiServiceQueryHelpers(this);

  @observable.shallow defaultOptions: ApiRequestOptions = {};

  uploadRequest(params: ApiUploadRequestParams): ApiUploadRequest {
    const req = new ApiUploadRequest(params, this.store);
    return req;
  }

  multipartUploadRequest(params: ApiMultipartUploadRequestParams): ApiMultipartUploadRequest {
    const req = new ApiMultipartUploadRequest(params, this.store);
    return req;
  }

  @action
  setDefaultOptions(opts?: ApiRequestOptions) {
    this.defaultOptions = opts ?? {};
  }

  // #region Queries
  // -------

  // #region Profile queries
  async getProfile(vars?: ApiVariables<'getProfile'>, opts?: ApiQueryOptions) {
    return this.runQuery('getProfile', vars, opts);
  }
  async adminGetProfile(vars?: ApiVariables<'adminGetProfile'>, opts?: ApiQueryOptions) {
    return this.runQuery('adminGetProfile', vars, opts);
  }
  // #endregion

  // #region Notification queries
  async getNotificationSubscriptionEmailEventTypes(vars?: ApiVariables<'getNotificationSubscriptionEmailEventTypes'>, opts?: ApiQueryOptions) {
    return this.runQuery('getNotificationSubscriptionEmailEventTypes', vars, opts);
  }

  //#endregion

  // #region Bookmark queries
  async getBookmarks(vars: ApiVariables<'getBookmarks'>, opts?: ApiQueryOptions) {
    return this.runQuery('getBookmarks', vars, opts);
  }
  async getBookmarkLists(vars?: ApiVariables<'getBookmarkLists'>, opts?: ApiQueryOptions) {
    return this.runQuery('getBookmarkLists', vars, opts);
  }
  async getBookmarkList(vars: ApiVariables<'getBookmarkList'>, opts?: ApiQueryOptions) {
    return this.runQuery('getBookmarkList', vars, opts);
  }
  async getMomentBookmarks(vars: ApiVariables<'getMomentBookmarks'>, opts?: ApiQueryOptions) {
    return this.runQuery('getMomentBookmarks', vars, opts);
  }
  async getJobRelatedBookmarks(vars: ApiVariables<'getJobRelatedBookmarks'>, opts?: ApiRequestOptions) {
    return this.runQuery('getJobRelatedBookmarks', vars, opts);
  }
  // #endregion

  // #region Job queries
  async getJob(vars: ApiVariables<'getJob'>, opts?: ApiQueryOptions) {
    return this.runQuery('getJob', vars, opts);
  }
  async getJobs(vars: ApiVariables<'getJobs'>, opts?: ApiQueryOptions) {
    return this.runQuery('getJobs', vars, opts);
  }
  async getSpeakers(vars: ApiVariables<'getSpeakers'>, opts?: ApiQueryOptions) {
    return this.runQuery('getSpeakers', vars, opts);
  }
  async getSpeaker(vars: ApiVariables<'getSpeaker'>, opts?: ApiQueryOptions) {
    return this.runQuery('getSpeaker', vars, opts);
  }
  async batchGetSpeakers(vars: ApiVariables<'batchGetSpeakers'>, opts?: ApiQueryOptions) {
    return this.runQuery('batchGetSpeakers', vars, opts);
  }
  async getMoments(vars: ApiVariables<'getMoments'>, opts?: ApiQueryOptions) {
    return this.runQuery('getMoments', vars, opts);
  }
  async getMoment(vars: ApiVariables<'getMoment'>, opts?: ApiQueryOptions) {
    return this.runQuery('getMoment', vars, opts)
  }
  async getTracks(vars: ApiVariables<'getTracks'>, opts?: ApiQueryOptions) {
    return this.runQuery('getTracks', vars, opts);
  }
  async adminGetJobs(vars: ApiVariables<'adminGetJobs'>, opts?: ApiQueryOptions) {
    return this.runQuery('adminGetJobs', vars, opts);
  }
  async searchMoments(vars: ApiVariables<'searchMoments'>, opts?: ApiQueryOptions) {
    return this.runQuery('searchMoments', vars, opts);
  }
  async getJobPermissions(vars: ApiVariables<'getJobPermissions'>, opts?: ApiQueryOptions) {
    return this.runQuery('getJobPermissions', vars, opts);
  }
  async getUploadUrl(vars: ApiVariables<'getUploadUrl'>, opts?: ApiQueryOptions) {
    return this.runQuery('getUploadUrl', vars, opts);
  }
  async getJobTranscript(vars: ApiVariables<'getJobTranscript'>, opts?: ApiQueryOptions) {
    return this.runQuery('getJobTranscript', vars, opts);
  }

  async getDownloadLink(vars: ApiVariables<'getDownloadLink'>, opts?: ApiQueryOptions) {
    return this.runQuery('getDownloadLink', vars, opts);
  }
  // #endregion

  // #region Team queries
  async getTeam(vars: ApiVariables<'getTeam'>, opts?: ApiQueryOptions) {
    return this.runQuery('getTeam', vars, opts);
  }
  async getTeamMembers(vars: ApiVariables<'getTeamMembers'>, opts?: ApiQueryOptions) {
    return this.runQuery('getTeamMembers', vars, opts);
  }
  async getTeamInvitations(vars: ApiVariables<'getTeamInvitations'>, opts?: ApiQueryOptions) {
    return this.runQuery('getTeamInvitations', vars, opts);
  }
  async getTeamNamespaces(vars?: ApiVariables<'getTeamNamespaces'>, opts?: ApiQueryOptions) {
    return this.runQuery('getTeamNamespaces', vars, opts);
  }
  async getTeamTags(vars: ApiVariables<'getTeamTags'>, opts?: ApiQueryOptions) {
    return this.runQuery('getTeamTags', vars, opts);
  }

  async getTeams(vars: ApiVariables<'getTeams'>, opts?: ApiQueryOptions) {
    return this.runQuery('getTeams', vars, opts);
  }
  // #endregion

  // #region Comment queries
  async getComments(vars: ApiVariables<'getComments'>, opts?: ApiQueryOptions) {
    return this.runQuery('getComments', vars, opts);
  }
  async getReactions(vars: ApiVariables<'getReactions'>, opts?: ApiQueryOptions) {
    return this.runQuery('getReactions', vars, opts);
  }
  // #endregion

  // #region External libraries queries
  async viewer(vars: ApiVariables<'viewer'>, opts?: ApiQueryOptions) {
    return this.runQuery('viewer', vars, opts);
  }
  async getExternalLibrary(vars: ApiVariables<'getExternalLibrary'>, opts?: ApiQueryOptions) {
    return this.runQuery('getExternalLibrary', vars, opts);
  }
  // #endregion

  // #region Analytics queries
  async keenBatch(vars: ApiVariables<'keenBatch'>, opts?: ApiQueryOptions) {
    return this.runQuery('keenBatch', vars, opts);
  }
  // #endregion

  // #endregion



  // #region Mutations

  // #region Bookmark mutations
  async createBookmark(vars: ApiVariables<'createBookmark'>, opts?: ApiMutationOptions) {
    return this.runMutation('createBookmark', vars, opts);
  }
  async deleteBookmark(vars: ApiVariables<'deleteBookmark'>, opts?: ApiMutationOptions) {
    return this.runMutation('deleteBookmark', vars, opts);
  }
  async createBookmarkList(vars: ApiVariables<'createBookmarkList'>, opts?: ApiMutationOptions) {
    return this.runMutation('createBookmarkList', vars, opts);
  }
  async updateBookmarkList(vars: ApiVariables<'updateBookmarkList'>, opts?: ApiMutationOptions) {
    return this.runMutation('updateBookmarkList', vars, opts);
  }
  async deleteBookmarkList(vars: ApiVariables<'deleteBookmarkList'>, opts?: ApiMutationOptions) {
    return this.runMutation('deleteBookmarkList', vars, opts);
  }
  async exportBookmarkListToEdl(vars: ApiVariables<'exportClips'>, opts?: ApiMutationOptions) {
    return this.runMutation('exportClips', vars, opts);
  }
  // #endregion

  // #region Job mutations
  async updateJob(vars: ApiVariables<'updateJob'>, opts?: ApiMutationOptions) {
    return this.runMutation('updateJob', vars, opts);
  }
  async changeJobOwner(vars: ApiVariables<'changeJobOwner'>, opts?: ApiMutationOptions) {
    return this.runMutation('changeJobOwner', vars, opts);
  }
  async addJobToTeam(vars: ApiVariables<'addJobToTeam'>, opts?: ApiMutationOptions) {
    return this.runMutation('addJobToTeam', vars, opts);
  }
  async removeJobFromTeam(vars: ApiVariables<'removeJobFromTeam'>, opts?: ApiMutationOptions) {
    return this.runMutation('removeJobFromTeam', vars, opts);
  }
  async startJob(vars: ApiVariables<'startJob'>, opts?: ApiMutationOptions) {
    return this.runMutation('startJob', vars, opts);
  }
  async copyJob(vars: ApiVariables<'copyJob'>, opts?: ApiMutationOptions) {
    return this.runMutation('copyJob', vars, opts);
  }
  async archiveJob(vars: ApiVariables<'archiveJob'>, opts?: ApiMutationOptions) {
    return this.runMutation('archiveJob', vars, opts);
  }
  async updateJobSource(vars: ApiVariables<'updateJobSource'>, opts?: ApiMutationOptions) {
    return this.runMutation('updateJobSource', vars, opts);
  }
  async getJobThumbnailUploadURL(vars: ApiVariables<'getJobThumbnailUploadURL'>, opts?: ApiMutationOptions) {
    return this.runMutation('getJobThumbnailUploadURL', vars, opts);
  }
  // #endregion

  // #region Live Job mutations
  async startLiveJob(vars: ApiVariables<'startLiveJob'>, opts?: ApiMutationOptions) {
    return this.runMutation('startLiveJob', vars, opts);
  }
  async stopLiveJob(vars: ApiVariables<'stopLiveJob'>, opts?: ApiMutationOptions) {
    return this.runMutation('stopLiveJob', vars, opts);
  }
  async updateLiveSource(vars: ApiVariables<'updateLiveJobSource'>, opts?: ApiMutationOptions) {
    return this.runMutation('updateLiveJobSource', vars, opts);
  }
  // #endregion

  // #region Team mutations
  async createTeam(vars: ApiVariables<'createTeam'>, opts?: ApiMutationOptions) {
    return this.runMutation('createTeam', vars, opts);
  }
  async deleteTeam(vars: ApiVariables<'deleteTeam'>, opts?: ApiMutationOptions) {
    return this.runMutation('deleteTeam', vars, opts);
  }
  async updateTeam(vars: ApiVariables<'updateTeam'>, opts?: ApiMutationOptions) {
    return this.runMutation('updateTeam', vars, opts);
  }
  async leaveTeam(vars: ApiVariables<'leaveTeam'>, opts?: ApiMutationOptions) {
    return this.runMutation('leaveTeam', vars, opts);
  }
  async updateTeamMemberRole(vars: ApiVariables<'updateTeamMemberRole'>, opts?: ApiMutationOptions) {
    return this.runMutation('updateTeamMemberRole', vars, opts);
  }
  async updateTeamMember(vars: ApiVariables<'updateTeamMember'>, opts?: ApiMutationOptions) {
    return this.runMutation('updateTeamMember', vars, opts);
  }
  async deleteTeamMember(vars: ApiVariables<'deleteTeamMember'>, opts?: ApiMutationOptions) {
    return this.runMutation('deleteTeamMember', vars, opts);
  }
  async inviteTeamMember(vars: ApiVariables<'inviteTeamMember'>, opts?: ApiMutationOptions) {
    return this.runMutation('inviteTeamMember', vars, opts);
  }
  async updateTeamInvitation(vars: ApiVariables<'updateTeamInvitation'>, opts?: ApiMutationOptions) {
    return this.runMutation('updateTeamInvitation', vars, opts);
  }
  async deleteTeamInvitation(vars: ApiVariables<'deleteTeamInvitation'>, opts?: ApiMutationOptions) {
    return this.runMutation('deleteTeamInvitation', vars, opts);
  }
  async getAvatarUploadUrl(vars: ApiVariables<'getAvatarUploadUrl'>, opts?: ApiMutationOptions) {
    return this.runMutation('getAvatarUploadUrl', vars, opts);
  }
  async getLogoUploadUrl(vars: ApiVariables<'getLogoUploadUrl'>, opts?: ApiMutationOptions) {
    return this.runMutation('getLogoUploadUrl', vars, opts);
  }
  async getCoverUploadUrl(vars: ApiVariables<'getCoverUploadUrl'>, opts?: ApiMutationOptions) {
    return this.runMutation('getCoverUploadUrl', vars, opts);
  }
  async joinTeamByInvitation(vars: ApiVariables<'joinTeamByInvitation'>, opts?: ApiMutationOptions) {
    return this.runMutation('joinTeamByInvitation', vars, opts);
  }
  // #endregion

  // #region Profile mutations
  async updateProfile(vars: ApiVariables<'updateProfile'>, opts?: ApiMutationOptions) {
    return this.runMutation('updateProfile', vars, opts);
  }
  async getProfilePictureUploadUrl(vars: ApiVariables<'getProfilePictureUploadUrl'>, opts?: ApiMutationOptions) {
    return this.runMutation('getProfilePictureUploadUrl', vars, opts);
  }
  async upgrade(vars: ApiVariables<'upgrade'>, opts?: ApiMutationOptions) {
    return this.runMutation('upgrade', vars, opts);
  }
  // #endregion

  // #region Notification mutations 
  async createNotificationSubscription(vars: ApiVariables<'createNotificationsSubscription'>, opts?: ApiMutationOptions) {
    return this.runMutation('createNotificationsSubscription', vars, opts);
  }

  async updateNotificationsSubscription(vars: ApiVariables<'updateNotificationsSubscription'>, opts?: ApiMutationOptions) {
    return this.runMutation('updateNotificationsSubscription', vars, opts);
  }

  async deleteNotificationsSubscriptionSubscribedEvent(vars: ApiVariables<'deleteNotificationsSubscriptionSubscribedEvent'>, opts?: ApiMutationOptions) {
    return this.runMutation('deleteNotificationsSubscriptionSubscribedEvent', vars, opts);
  }
  //

  // #region Speaker mutations
  async getSpeakerPictureUploadUrl(vars: ApiVariables<'getSpeakerPictureUploadUrl'>, opts?: ApiMutationOptions) {
    return this.runMutation('getSpeakerPictureUploadUrl', vars, opts);
  }
  async addSpeaker(vars: ApiVariables<'addSpeaker'>, opts?: ApiMutationOptions) {
    return this.runMutation('addSpeaker', vars, opts);
  }
  async updateSpeaker(vars: ApiVariables<'updateSpeaker'>, opts?: ApiMutationOptions) {
    return this.runMutation('updateSpeaker', vars, opts);
  }
  async confirmSpeakerPrediction(vars: ApiVariables<'confirmSpeakerPrediction'>, opts?: ApiMutationOptions) {
    return this.runMutation('confirmSpeakerPrediction', vars, opts);
  }
  // #endregion

  // #region Moment mutations
  async addMoment(vars: ApiVariables<'addMoment'>, opts?: ApiMutationOptions) {
    return this.runMutation('addMoment', vars, opts);
  }
  async updateMoment(vars: ApiVariables<'updateMoment'>, opts?: ApiMutationOptions) {
    return this.runMutation('updateMoment', vars, opts);
  }
  async deleteMoment(vars: ApiVariables<'deleteMoment'>, opts?: ApiMutationOptions) {
    return this.runMutation('deleteMoment', vars, opts);
  }
  // #endregion

  // #region Track mutations
  async addTrack(vars: ApiVariables<'addTrack'>, opts?: ApiMutationOptions) {
    return this.runMutation('addTrack', vars, opts);
  }
  async updateTrack(vars: ApiVariables<'updateTrack'>, opts?: ApiMutationOptions) {
    return this.runMutation('updateTrack', vars, opts);
  }
  async mergeTracks(vars: ApiVariables<'mergeTracks'>, opts?: ApiMutationOptions) {
    return this.runMutation('mergeTracks', vars, opts);
  }
  async deleteTrack(vars: ApiVariables<'deleteTrack'>, opts?: ApiMutationOptions) {
    return this.runMutation('deleteTrack', vars, opts)
  }
  async duplicateTrack(vars: ApiVariables<'duplicateTrack'>, opts?: ApiMutationOptions) {
    return this.runMutation('duplicateTrack', vars, opts)
  }
  // #endregion

  // #region Comment mutations
  async addComment(vars: ApiVariables<'addComment'>, opts?: ApiMutationOptions) {
    return this.runMutation('addComment', vars, opts);
  }
  async addReaction(vars: ApiVariables<'addReaction'>, opts?: ApiMutationOptions) {
    return this.runMutation('addReaction', vars, opts);
  }
  async addReply(vars: ApiVariables<'addReply'>, opts?: ApiMutationOptions) {
    return this.runMutation('addReply', vars, opts);
  }
  async editComment(vars: ApiVariables<'editComment'>, opts?: ApiMutationOptions) {
    return this.runMutation('editComment', vars, opts);
  }
  async deleteComment(vars: ApiVariables<'deleteComment'>, opts?: ApiMutationOptions) {
    return this.runMutation('deleteComment', vars, opts);
  }
  // #endregion

  // #region External Libraries mutations
  async connectExternalLibraries(vars: ApiVariables<'connectExternalLibrary'>, opts?: ApiMutationOptions) {
    return this.runMutation('connectExternalLibrary', vars, opts);
  }
  async deleteExternalLibrary(vars: ApiVariables<'deleteExternalLibrary'>, opts?: ApiMutationOptions) {
    return this.runMutation('deleteExternalLibrary', vars, opts);
  }
  // #endregion

  // Region multipart upload mutations
  createMultipartUpload(vars: ApiVariables<'createMultipartUpload'>) {
    return this.runMutation('createMultipartUpload', vars);
  }

  completeMultipartUpload(vars: ApiVariables<'completeMultipartUpload'>) {
    return this.runMutation('completeMultipartUpload', vars);
  }

  // #endregion

// Region chat gpt mutations
  getGPTAnswer(vars: ApiVariables<'getGPTAnswer'>) {
    return this.runMutation('getGPTAnswer', vars);
  }
// #endregion



  async runQuery<TQuery extends ApiQueryName>(
    name: TQuery,
    vars?: ApiVariables<TQuery>,
    opts?: ApiQueryOptions): ApiResult<TQuery> {

    const query: string = ApiQueries[name]!;
    return this.request(query, vars, opts);
  }

  async runMutation<TMutation extends ApiMutationName>(
    name: TMutation,
    vars?: ApiVariables<TMutation>,
    opts?: ApiMutationOptions): ApiResult<TMutation> {

    const mutation: string = ApiMutations[name]!;
    return this.request(mutation, vars, opts);
  }

  async runRequest<T extends ApiQueryName | ApiMutationName = any>(
    arg: T | string,
    vars?: ApiVariables<T>,
    opts?: ApiRequestOptions): ApiResult<T> {

    const doc: string =
      ApiQueries[arg as ApiQueryName] ||
      ApiMutations[arg as ApiMutationName] ||
      arg;

    return this.request(doc, vars, opts);
  }

  /**
   * Attempts to execute a function that contains GraphQL requests.
   * If the request fails because of an issue that can be solved, this function will attempt to solve the issue and retry the request.
   * Currently the only failing scenario that is handled is when the server returns 401 - Unauthorized.
   * ---
   * The flow of the method is as following.
   * 
   * 1. **Check if the network is online:**
   *    1. If the network is online, go to **2**.
   *    2. Otherwise, return error code `NetworkOffline`.
   * 
   * 2. **Initial authorization attempt:**
   *    1. If `options.public` is `true`, skip authorization and go to **3**.
   *    2. Otherwise, call `AuthService.ensureIdToken`:
   *        1. If it returns a valid token, go to **3**.
   *        2. Otherwise return error `NotAuthorized`, with the `innerError` set to the one returned by the call.
   *    
   * 3. **Initial call to `provider.request`:**
   *    1. If the call succeeds, return the response.
   *    2. If the call fails with code `ProviderNotAuthorized` go to **4**.
   *    3. If the call fails with any other code, return the provider error directly.
   * 
   * 4. **Retry authorization attempt:**
   *    1. If `options.public` is `true`, return error `NotAuthorized`, with the `innerError` set to the one previously returned by the provider.
   *    2. Otherwise, call `AuthService.ensureIdToken`:
   *        1. If it returns a valid token, go to **3**.
   *        2. Otherwise return error `NotAuthorized`, with the `innerError` set to the one returned by the call.
   */
  private async request<T extends ApiQueryName | ApiMutationName = any>(
    doc: string,
    vars?: ApiVariables<T>,
    opts?: ApiRequestOptions): ApiResult<T> {

    const queryName = doc.match(/(?:query|mutation)\s+(\w+)/)?.[1] ?? null;

    TRACE(this, `request()`, queryName, vars, opts);

    // merge the provided options with the default ones
    opts = {
      ...DefaultApiRequestOptions,
      ...this.defaultOptions,
      ...opts
    };

    const mode = opts.mode ?? ApiRequestMode.RequiredAuthorization;
    const reqParams: ApiRequestParams = {
      endpoint: GRAPHQL_CLIENT_ENDPOINT!,
      document: doc,
      variables: vars,
      signal: opts.signal
    }

    if (mode !== ApiRequestMode.Direct && isNonEmptyString(opts.token))
      return [null, new ApiError('ApiError', `You cannot provide a token when mode is not set to Direct.`)];

    const [, networkErr] = this.ensureNetwork(opts);
    if (networkErr)
      return [null, networkErr];

    let retry = false;
    switch (mode) {
      case ApiRequestMode.Direct:
        reqParams.token = opts.token ?? null;
        break;

      case ApiRequestMode.OptionalAuthorization: {
        // as per the spec, if we have a token, regardless of if it's valid or not, we send it
        // if not, we don't send it (duh)
        const authContext = this.store.authService.context;
        reqParams.token = authContext?.permit?.idToken ?? null;
      } break;

      case ApiRequestMode.RequiredAuthorization:
        const [authContext, authContextErr] = await this.ensureAuthContext(mode);
        if (authContextErr)
          return [null, new ApiError('NotAuthorized', `Failed to get a valid context before sending the request`, authContextErr as any)];

        if (!authContext || !authContext.isValid)
          return [null, new ApiError('ApiError', `Expected a valid AuthContext from 'ensureContext'.`)];

        reqParams.token = authContext.permit?.idToken ?? null;
        retry = authContext.isAuthenticated;
        break;
    }

    const [initResData, initResErr] = await runGraphQlApiRequest(reqParams);

    if (retry && initResErr?.type === 'ProviderNotAuthorized') {
      // we should retry
      TRACE(this, 'Initial request attempt failed with ProviderNotAuthorized. Token probably expired. Auth strategy is set to EnsureToken so refresh the user session and retry.');

      // the initial request failed but with this strategy we attempt to fetch another valid token
      const [retryAuthCtx, retryAuthCtxErr] = await this.ensureAuthContext(mode);
      if (retryAuthCtxErr || !retryAuthCtx) {
        TRACE(this, `Failed to re-authorize after the initial attempt was rejected.`);

        return [null, new ApiError('NotAuthorized', null, retryAuthCtxErr as any)];
      }

      if (!retryAuthCtx.isAuthenticated)
        return [null, new ApiError('ApiError', `Expected the AuthContext to be of the Authenticated type after retrying.`)];

      // we have a new token, set it on the params and then try again with the request
      reqParams.token = retryAuthCtx.permit.idToken;

      TRACE(this, `Retrying the request with params`, reqParams);

      const [retryResData, retryResErr] = await runGraphQlApiRequest(reqParams);
      if (retryResErr) {
        TRACE(this, `Final attempt to run request failed with error`, retryResErr);

        this.store.auth.notifyTokenRejected();
        return [null, new ApiError('RetryRequestFailed')];
      }

      return [retryResData];
    }

    if (initResErr)
      return [null, initResErr];

    return [initResData];
  }

  private ensureNetwork(opts?: ApiRequestOptions): Result<boolean, ApiError> {

    const { networkService } = this.store;
    if (!networkService.isOnline) {
      TRACE(this, 'Network is offline.');

      if (!opts?.suppressRedirectWhenOffline) {
        TRACE(this, 'Redirect to network offline error page.');
        this.store.goTo('/error/network');
      }

      return [null, new ApiError('NetworkOffline')];
    }

    return [true];
  }


  /**
   * Should be called by services like the API which expect a valid access token to operate.
   * If there is already a reauthorization process taking place, this method will only return
   * the existing promise, instead of starting a new reauthorization process if one is needed.
   */
  private async ensureAuthContext(mode: ApiRequestMode): AsyncResult<AuthContext, Error> {

    TRACE(this, `ensureAuthContext()`);

    const { authService } = this.store;

    if (mode !== ApiRequestMode.RequiredAuthorization)
      return [null, new Error('InternalError', `ensureAuthContext() can only be called when 'mode' is set to 'RequiredAuthorization'.`)];

    let context: AuthContext | null = null;
    if (authService.shouldWaitForNextContext) {
      TRACE(this, `There is a flow already in progress or the initial flow hasn't run yet. Wait for the next context.`);

      context = await authService.localState.waitForNextContextOrInvalidation();
      if (!context) {
        TRACE(this, `The next context got invalidated, so return an error`);
        return [null, new Error('AuthError', `AuthContext got invalidated.`)];
      }
    } else {

      context = authService.localState.getContext();
      TRACE(this, `Using the current AuthContext`, context);
    }

    if (context) {
      if (context.isValid) {
        TRACE(this, `Context exists and it is valid`);

        return [context];
      }

      TRACE(this, `Context either does not exist or it is not valid, so attempt to refresh`);

      // context is not valid so we need to refresh
      return this.refreshAuthenticatedContext();
    }

    return [null, new Error('AuthError', `There is no current context, which means the session was probably terminated.`)];
  }

  private async refreshAuthenticatedContext(): AsyncResult<AuthContext, Error> {

    TRACE(this, `refreshAuthenticatedContext()`);

    const { authService } = this.store;
    if (!authService.canRunFlow)
      return [null, new Error('InternalError', `Cannot run the RefreshPermitFlow because there is another flow in progress.`)];

    const [res, err] = await authService.runRefreshPermitFlow();
    if (err)
      return [null, err];

    const responseType = res?.responseType
    const { context } = authService;

    TRACE(this, `'responseType' of RefreshPermitFlow is:`, responseType);

    switch (responseType) {
      case AuthFlowResponseType.Authorized:

        if (
          !context ||
          !context.isValid ||
          !context.isAuthenticated)
          return [null, new Error('InternalError', `Expected the AuthContext returned by RefreshPermitFlow to be valid and of the Authenticated type.`)];

        // nothing to do, context will be returned
        TRACE(this, `Context has been refreshed successfully`, context);

        return [context];

      case AuthFlowResponseType.RedirectToLoginPage:
      case AuthFlowResponseType.RedirectToLastWidgetRoute:
        TRACE(this, `Context failed to refresh and user will be redirected`);

        authService.executeFlowResponse(res!);
        break;
    }

    // context is valid and authenticated
    return [null, new Error('InternalError', `Your session has expired. You should be redirected to the login page.`)];
  }
}