import { computed, makeObservable } from 'mobx';
import { StoreNode } from '../store';
import { Store } from '../store/store';

type Props = {
  jobId: string,
  cursor: string,
  index: number
}

/** Stores a pointer to a Job in a catalog list and the pagination info associated with it. */
export class JobCatalogItem
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
    Object.assign(this, props);
  }

  readonly jobId!: string;
  readonly cursor!: string;
  readonly index!: number;

  @computed get job() {
    return this.store.getJob(this.jobId);
  }
}