import assert from 'assert';
import { MomentModel, JobModel } from '../../entities';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { action, computed, observable, makeObservable } from 'mobx';
import { closeWindow, openWindow } from '../../services/overlays';
import { WindowState } from '../overlays/windowState';

type Props = {
  // jobId: string
}

export type OpenArgs = {
  jobId: string;
  clipList: MomentModel[];
  multipleActiveTrackTypes: string[];
}

export class JobErrorsWindowState
  extends StoreNode {

  readonly nodeType: 'JobErrorsWindow' = 'JobErrorsWindow';

  constructor(store: Store, props?: Props) {
    super(store);
    makeObservable(this);
    this.window.listen(
      this.windowListener);
  }
  @observable jobId: string | null = null;
  @observable isVisible = false;
  @observable clipList: MomentModel[] = [];
  @observable multipleActiveTrackTypes: string[] = [];
  @observable isLoading: boolean = false;

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close('close');
        break;
    }
  }
  readonly window = new WindowState(this.store);

  @computed
  get uniqueActiveTrackTypes(): string[] {
    return [...new Set(this.multipleActiveTrackTypes)];
  }

  @computed
  get clipErrorCount() {
    return this.clipList.length ?? 0;
  }

  @computed
  get trackError() {
    return this.multipleActiveTrackTypes.length > 0;
  }

  @computed
  get trackErrorCount() {
    return this.multipleActiveTrackTypes.length;
  }

  @computed
  get clipError() {
    return this.clipErrorCount > 0;
  }

  @computed
  get errorCount() {
    return this.showClipError ? this.clipErrorCount : this.trackErrorCount;
  }

  @computed
  get multipleErrors() {
    return this.errorCount > 1;
  }

  @computed
  get showClipError() {
    return this.clipError && !this.trackError;
  }

  /** Hides the window and clears the entire state of the window. */
  @action
  cancel = () => {
    if (this.isLoading)
      return;

    this.close('close');
  }

  @action
  open(args: OpenArgs) {
    const { jobId, clipList, multipleActiveTrackTypes } = args;
    this.jobId = jobId;
    assert(this.jobId,
      `Cannot show a JobErrorsWindow without a valid jobId`);

    this.isLoading = true;

    this.isVisible = true;
    this.clipList = clipList;
    this.multipleActiveTrackTypes = multipleActiveTrackTypes;

    openWindow(this, 'JobErrorsWindow');
    this.emit('open');
  }

  @action
  clearState = () => {
    this.jobId = null;
    this.clipList = [];
    this.multipleActiveTrackTypes = [];
  }

  @action
  close(msg?: string) {
    this.isVisible = false;
    closeWindow(this);
    if (msg)
      this.emit(msg);
  }

  @action
  onWindowEntered = () => {
    this.isLoading = false;
  }

  @action
  onWindowExited = () => {
    if (!this.isVisible)
      this.clearState();
  }
}