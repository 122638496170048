import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { Button, TextInputControlled } from '../input';
import { ExportToEdlWindowState } from './exportToEdlWindowState';

type Props = {
  model: ExportToEdlWindowState;
  visible?: boolean;
};

export const ExportToEdlWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      model={model.window}
      visible={props.visible}
      isLoading={model.isLoading}>
      <WindowHeader model={model.window} title="Export to EDL bookmark's list" />

      <hr />

      <div className="window-content">
        <p className="p-bold">Are you sure you want to export to EDL this bookmark's list?</p>
        <p>Check your email soon, you will receive a download link from us, but keep in mind that it will expire in 24h!</p>
        <p>Here you can add an optional email address, otherwise it will be sent to your registration email:</p>
        <br />

        <TextInputControlled
          model={model.emailInput}
          className="form-input email-input" />
      </div>

      <hr />

      <footer className="window-footer">
        <div className="btn-group right">
          <Button
            className="primary btn-outline medium"
            label="Cancel"
            onClick={() => model.close()}
          />
          <Button
            className="red medium"
            label="Download"
            onClick={() => model.submit()}
          />
        </div>
      </footer>
    </Window>
  );
});
