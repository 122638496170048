import React, { ReactEventHandler } from 'react';
import { observer } from 'mobx-react-lite';
import { BookmarkSVGIcon } from '../../components/svg';

type Props = {
  disabled?: boolean,
  enabled?: boolean,
  onClick?: ReactEventHandler
}

export const BookmarkButton = observer((props: Props) => {

  return (
    <button className="bookmark-btn toggle-btn btn-core"
      onClick={props.onClick}
      aria-pressed={props.enabled}
      disabled={props.disabled}>

      <span className="icon">
        <BookmarkSVGIcon
          enabled={props.enabled || false} />
      </span>
    </button>
  );
});