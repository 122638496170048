import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { DateTime } from 'luxon';
import identity from 'lodash/identity';
import { ApiResponse, ApiVariables, ApiRequestOptions } from '../../../api/apiSchema';
import { AsyncResult, Result } from '../../../core';
import { BindingProps, StoreNode } from '../../../store';
import { IChartDataSource } from '../chartSchema';
import { Store } from '../../../store/store';
import { error, Error } from '../../../core/error';
import { parseKeenJsonResult } from '../utils/parseKeenJsonResult';
import { BookmarksChartDatum, BookmarksChartParams } from './bookmarksChartSchema';
import { ApiError } from '../../../api/apiError';
import { LineChartDataProps } from '../xySeriesChart';

type Props = BindingProps<{
  params: BookmarksChartParams,
  suppressFetchOnParamsChange?: boolean
}>

export class BookmarksDataSource
  extends StoreNode
  implements IChartDataSource<BookmarksChartDatum> {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.onPropChange('params',
      this.handleParamsChange);
  }

  // #region Props
  @computed get params(): BookmarksChartParams | null {
    return this.getResolvedProp('params') ?? null;
  }
  set params(val: BookmarksChartParams | null) {
    this.setProp('params', val);
  }

  @computed get suppressFetchOnParamsChange(): boolean | null {
    return this.getResolvedProp('suppressFetchOnParamsChange') ?? null;
  }
  set suppressFetchOnParamsChange(val: boolean | null) {
    this.setProp('suppressFetchOnParamsChange', val);
  }
  // #endregion

  // #region Handlers
  private handleParamsChange = () => {
    // this.fetch();
  }
  // #endregion

  readonly data = observable.array<BookmarksChartDatum>([], { deep: false });

  private abortController: AbortController | null = null;

  @observable isFetching = false;
  @observable error: Error | null = null;

  @action
  reset() {
    this.abort();

    this.isFetching = false;
    this.error = null;
    this.abortController = null;

    this.data.clear();
  }

  @action
  async fetch(): AsyncResult {

    this.reset();

    this.isFetching = true;

    const abortCtrl = new AbortController();
    this.abortController = abortCtrl;

    const { params } = this;
    if (!params)
      return [null];

    const startDateArg = params.startDate?.toISO();
    const endDateArg = params.endDate?.toISO();
    const timezoneArg = params.timezone ?? undefined;
    const teamIdArg = params.teamId ?? undefined;

    if (!startDateArg || !endDateArg)
      return [null, error('Unknown', 'Invalid parameters')];

    const reqArgs: ApiVariables<'keenBatch'> = {
      args: {
        start: startDateArg,
        end: endDateArg,
        timezone: timezoneArg,
        teamId: teamIdArg
      }
    };

    const api = this.store.api;
    const reqOpts: ApiRequestOptions = {
      signal: abortCtrl.signal
    };

    let reqPromise = api.runQuery('keen_getBookmarks', reqArgs, reqOpts);

    const [res, err] = await reqPromise;
    if (err)
      // @ts-ignore TODO: make ApiError extend our own Error class
      return this.handleError(err);

    const [data, insertErr] = this.insert(res!);
    if (insertErr)
      return this.handleError(insertErr);

    runInAction(() => {
      this.isFetching = false;
    });

    return [data!];
  }

  cancel(): void {
    this.abort();
  }

  @observable isBatchRequesting = false;

  notifyBatchRequestStarted() {
    this.isBatchRequesting = true;
  }
  notifyBatchRequestCompleted() {
    this.isBatchRequesting = false;
  }

  @action
  insert(res: ApiResponse<'keenBatch' | 'keen_getBookmarks'>): Result<BookmarksChartDatum[]> {

    const apiResultStr = res.keenBatch.bookmarks?.result;
    const [apiResult, parseErr] = parseKeenJsonResult<any>(apiResultStr);
    if (parseErr)
      return this.handleError(parseErr);

    const seriesData: LineChartDataProps[] = apiResult!
      .map((item: any) => {
        return {
          date: DateTime
            .fromISO(item?.timeframe?.start)
            .setLocale('en-US')
            .toLocaleString(DateTime.DATE_SHORT),
          value: item?.value ?? 0
        }
      })
      .filter(identity);

    const seriesItem: BookmarksChartDatum = {
      data: seriesData,
      dataKey: 'Bookmarks'
    }

    const data: BookmarksChartDatum[] = [seriesItem];

    this.data.replace(data ?? []);
    return [data ?? []];
  }

  private abort() {

    const lastAbortCtrl = this.abortController;
    if (lastAbortCtrl)
      lastAbortCtrl.abort();
    this.abortController = null;
  }

  @action
  private handleError(err?: Error | string): Result {
    if (!err)
      err = new Error();
    if (typeof err === 'string')
      err = new Error('Unknown', err);

    if (err instanceof ApiError && err.type === 'RequestAborted') {
      // for aborted requests we only return the error but we don't set it on the instance and we do not repor it as error
      // console.warn(`Request aborted in BookmarksDataSource`);
      return [null, err];

    } else {
      console.error(`An error occured in BookmarksDataSource: `, err);

      this.isFetching = false;
      this.abort();
      this.error = err;

      return [null, err];
    }
  }
}
