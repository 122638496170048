import { action, makeObservable, observable } from 'mobx';
import { ReactNode } from 'react';
import { WindowState } from '../../components/overlays/windowState';
import { Message, StoreNode } from '../../store';
import { Store } from '../../store/store';

type ConfirmationModalLayout = 'default' | 'danger' | 'info';

export type ConfirmationModalOpenParams = {
  onSubmit: () => void,
  onCancel?: () => void, // action by user
  onClose?: () => void, // window close (submit, close button, x button, outside click)
  modalMessage?: ReactNode,
  modalSecondaryMessage?: string,
  title?: string,
  confirmLabel?: string,
  closeLabel?: string,
  isLoading?: boolean,
  layout?: ConfirmationModalLayout
}

export class ConfirmationModalState
  extends StoreNode {

  readonly nodeType = 'ConfirmationModal';

  constructor(store: Store, props?: any) {
    super(store, props);
    makeObservable(this);
    this.window.listen(
      this.windowListener);
  }
  @observable isLoading: boolean = false;
  @observable onSubmit: (() => void) | null = null;
  @observable onCancel: (() => void) | null = null;
  @observable onClose: (() => void) | null = null;
  @observable modalMessage: ReactNode | HTMLElement | null = null;
  @observable modalSecondaryMessage: string | HTMLElement | null = null;
  @observable title: string | null = null;
  @observable confirmLabel: string | null = null;
  @observable closeLabel: string | null = null;
  @observable layout: ConfirmationModalLayout = 'default';

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close('close');
        break;
    }
  }
  readonly window = new WindowState(this.store);

  @action
  async submit() {
    this.isLoading = true;

    if (this.onSubmit)
      await this.onSubmit();

    this.close('close');
  }

  @action
  open(params: ConfirmationModalOpenParams) {
    this.dispatch('Overlays', 'openWindow', { name: 'ConfirmationModal' });
    const {
      onSubmit, onCancel, onClose,
      modalMessage, modalSecondaryMessage, title,
      confirmLabel, closeLabel, layout, isLoading
    } = params;

    this.onSubmit = onSubmit;
    this.onCancel = onCancel ?? null;
    this.onClose = onClose ?? null;
    this.modalMessage = modalMessage ?? null;
    this.modalSecondaryMessage = modalSecondaryMessage ?? null;
    this.title = title ?? null;
    this.confirmLabel = confirmLabel ?? null;
    this.closeLabel = closeLabel ?? null;
    this.layout = layout ?? 'default';
    this.isLoading = isLoading === false ? false : true;
    this.emit('open');
  }

  @action
  async close(msg?: string) {
    if (this.onClose)
      await this.onClose();

    this.dispatch('Overlays', 'closeWindow');
    if (msg) this.emit(msg);
  }

  @action
  async cancel() {
    if (this.onCancel)
      await this.onCancel();

    this.close('close');
  }

  @action
  onWindowEntered() {
    this.isLoading = false;
  }

  @action
  onWindowExited() {
    this.clearState();
  }

  @action
  clearState() {
    this.onSubmit = null;
    this.onCancel = null;
    this.onClose = null;
    this.modalMessage = null;
    this.modalSecondaryMessage = null;
    this.title = null;
    this.isLoading = false;
    this.confirmLabel = null;
    this.closeLabel = null;
    this.layout = 'default';
  }

}