import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { action, computed, makeObservable, observable } from 'mobx';
import { AsyncResult, IAsyncTask } from '../core';
import { Store } from '../store/store';
import { StoreNode } from '../store/storeNode';

export type ApiMultipartUploadRequestParams = {
  url: string,
  blob: Blob;
  part: number;
  fileSize: number;
  contentType: string;
}

export class ApiMultipartUploadRequest
  extends StoreNode
  implements IAsyncTask {

  constructor(params: ApiMultipartUploadRequestParams, store: Store) {
    super(store);
    makeObservable(this);

    this.url = params.url;
    this.blob = params.blob;
    this.part = params.part;
    this.fileSize = params.fileSize;
    this.contentType = params.contentType;
  }

  readonly url: string;
  readonly blob: Blob;
  readonly part: number;
  readonly fileSize: number;
  readonly contentType: string;

  @observable promise: AsyncResult | null = null;
  @observable error: any = null;
  @observable isActive: boolean = false;
  @observable uploadTotal: number = 0;
  @observable uploadLoaded: number = 0;
  cancelToken?: CancelTokenSource;

  @computed
  get uploadProgress(): number {
    return this.uploadLoaded / this.uploadTotal;
  }

  cancel() {
    this.cancelToken?.cancel();
  }

  private async startRequest(): AsyncResult {
    this.isActive = true;

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    this.cancelToken = source;

    const { blob, url } = this;

    const config: AxiosRequestConfig = {
      cancelToken: source.token,
      onUploadProgress: this.handleUploadProgress,
    };

    try {
      let resData = await axios.put(url, blob, config);
      this.isActive = false;

      return [resData];
    } catch (err) {
      this.error = err;
      this.isActive = false;
      if (axios.isCancel(err)) {
        return [null, new Error('Cancel')];
      }
      return [null, err];
    }
  }

  async start(): AsyncResult {
    // wrapper around startRequest which intercepts the promise
    const promise = this.startRequest();
    this.promise = promise;
    return promise;
  }

  @action
  private handleUploadProgress = (evt: ProgressEvent<EventTarget>) => {
    if (evt.lengthComputable) {
      this.uploadLoaded = evt.loaded;
      this.uploadTotal = evt.total;
    }
  }
}