import './playerTranscripts.scss';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { ChatGptLogoSVGIcon, CommentSVGIcon, DownloadSVGIcon } from '../svg';
import { PlayerTranscriptBlock } from './playerTranscriptBlock';
import { PlayerTranscriptsLayout, PlayerTranscriptsState } from './playerTranscriptsState';
import { PlayerSearchBarControlled } from '../playerShared/playerSearchBar';
import { PlayerSectionHeaderButton } from '../playerShared/playerSectionHeaderButton';
import { PlayerSectionCloseButton } from '../playerShared/playerSectionCloseButton';
import { Transcript } from '../../entities/moment';
import { PlayerSectionOverlay } from '../playerShared/playerSectionOverlay';
import { SyncStatus } from '../../entities';
import { LoadMask } from '../loader';
import { ErrorMessageBox } from '../player/components/errorMessageBox';
import { PlayerActionButton } from '../playerShared/playerActionButton';
import { PlayerComponentName } from '../player/playerSchema';

type PlayerTranscriptsProps = {
  model: PlayerTranscriptsState,
  layout?: PlayerTranscriptsLayout,
  className?: string,
  showHeader?: boolean,
  onClose?: () => void
}

export const PlayerTranscripts = observer(({
  model,
  layout = 'vertical',
  showHeader,
  className,
  onClose
}: PlayerTranscriptsProps) => {

  useEffect(() => {
    model.mounted();
  });

  showHeader = true;

  className = classNames('user-player-transcripts user-player-section page-section', className, layout, {
    'with-header': showHeader,
  });

  const { momentsSyncStatus } = model;

  const proxy = layout === 'vertical' ? model.verticalComponent : model.horizontalComponent;
  const isLoading = momentsSyncStatus === SyncStatus.Fetching;
  const isDisabled = model.player.isComponentDisabled(PlayerComponentName.TranscriptsSection);

  const contentElem = (
    <section
      id="player-transcripts"
      data-tutorial-id="player-transcripts"
      data-loading={isLoading}
      aria-disabled={isDisabled}
      className={className}
      ref={proxy.ref}>
      {momentsSyncStatus === SyncStatus.Fetched && 
       (model.isTranscriptSearchMode || model.isInitializedSearchMode) &&
        <PlayerTranscriptSectionBody
          model={model} />}

      {model.isCliprGptSearchMode &&
        <PlayerTranscriptSearchModeSectionBody
          model={model} />}

      <div className="user-player-section-mask" />

      {showHeader && (
        layout === 'vertical' ?
          <PlayerTranscriptHeaderSection
            model={model} /> :
          <PlayerTranscriptHorizontalHeader
            model={model} />
      )}

      <PlayerSectionCloseButton
        model={model}
        onClose={onClose} />

      <PlayerSectionOverlay
        visible={model.showOverlay}
        label={"Transcript"}
        status={"processing"}
      />

      {momentsSyncStatus === SyncStatus.Error &&
        <ErrorMessageBox
          content={'Could not fetch transcript because of an error.'}
        />
      }

      <LoadMask
        className="page-load-mask player-page-background user-player-section-load-mask small"
        spinner="dots3"
        spinnerClassName="medium"
        visible={momentsSyncStatus === SyncStatus.Fetching} />
    </section>
  );

  return contentElem;

});

const PlayerTranscriptHeaderSection = observer(({
  model
}: PlayerTranscriptsProps) => {
  const showDownloadButton = model.player.allowDownload ?? true;

  return (<header className="user-player-section-header"
    id={'user-player-transcript-header'}>
    <div className="section-row flex-space-between">
      <h3 className="section-heading">
        <span className="icon"><CommentSVGIcon /></span>
        <span className="text">Transcript</span>
      </h3>

      <div className="section-action-block">
        <PlayerSectionHeaderButton
          model={model}
          disabled={!model.activeTranscript || model.isActiveTranscriptIntoView} />
        <span className="hline-separator" />
        {showDownloadButton && <PlayerActionButton
          className="player-download-transcript-btn"
          model={model}
          icon={<DownloadSVGIcon />}
          onClick={() => model.openDownloadTranscript()}
          tooltip={'Download Transcript'} />}
      </div>

    </div>

    <div className="section-row flex-right">
      <PlayerSearchBarControlled
        model={model.searchQuery}
        showFeedback={false}
        persistentFeedback={false}
        placeholder={model.searchQuery.isFocused ? 'Type your keywords' : model.placeholder}
        resultIndex={model.searchNavIndex}
        numberOfResults={model.searchMatchesNumber}
        playerSearchMode={model.playerSearchMode}
        isCliprGptEnabled={model.cliprGptEnabled}
        onTranscriptMode={model.onTranscriptMode}
        onChatGptMode={(chatGptSearchKey: string) => model.onChatGptMode(chatGptSearchKey)}
        navigateBack={() => model.decreaseSearchNavIndex()}
        navigateFwd={() => model.increaseSearchNavIndex()}
        onClear={() => model.clearSearchQuery()} />
    </div>
  </header >)
});

const PlayerTranscriptHorizontalHeader = observer(({
  model
}: PlayerTranscriptsProps) => {
  const showDownloadButton = model.player.allowDownload ?? true;

  return (<header className="user-player-section-header"
    id={'user-player-transcript-header'}>
    <div className="section-row flex-space-between">
      <PlayerSearchBarControlled
        model={model.searchQuery}
        showFeedback={false}
        persistentFeedback={false}
        isAiProcessing={model.isAiProcessing}
        placeholder={model.searchQuery.isFocused ? 'Type your keywords' : model.placeholder}
        resultIndex={model.searchNavIndex}
        numberOfResults={model.searchMatchesNumber}
        isCliprGptEnabled={model.cliprGptEnabled}
        playerSearchMode={model.playerSearchMode}
        onTranscriptMode={model.onTranscriptMode}
        onChatGptMode={(chatGptSearchKey: string) => model.onChatGptMode(chatGptSearchKey)}
        navigateBack={() => model.decreaseSearchNavIndex()}
        navigateFwd={() => model.increaseSearchNavIndex()}
        onClear={() => model.clearSearchQuery()} />
      <div className="section-action-block">
        <PlayerSectionHeaderButton
          model={model}
          disabled={!model.activeTranscript || model.isActiveTranscriptIntoView} />
        <span className="hline-separator" />
        {showDownloadButton && <PlayerActionButton
          className="player-download-transcript-btn"
          model={model}
          icon={<DownloadSVGIcon />}
          onClick={() => model.openDownloadTranscript()}
          tooltip={'Download Transcript'} />}
      </div>
    </div>
  </header>)
});

const PlayerTranscriptSearchModeSectionBody = observer(({
  model
}: PlayerTranscriptsProps) => {

  return (
    <div className="user-player-section-body search-mode"
      data-tutorial-id='user-player-search-result-scroll'
      id={'user-player-search-result-scroll'}
      onScroll={model.handleScroll}>

      <div 
        onClick={model.clearSearchQuery}
        className="back-to-transcript">Back to transcript</div>
      <div className="transcript-search-result-container">
        <div className="transcript-search-result-item">{model.chatGptSearchKey}</div>
        <div className="transcript-search-result-item answer">  
          <ChatGptLogoSVGIcon />
          {!model.chatGptLoading ? model.chatGptSearchResult ?? 'No answer.' : 'Loading answer ...'}
        </div>
      </div>
    </div>
  )
});

const PlayerTranscriptSectionBody = observer(({
  model
}: PlayerTranscriptsProps) => {

  return (
    <div className="user-player-section-body"
      data-tutorial-id='user-player-transcript-scroll'
      id={'user-player-transcript-scroll'}
      onScroll={model.handleScroll}> {/*transcripts-body*/}

      <div className="transcripts-empty flex-center"
        aria-hidden={model.items.length > 0}>
        <div className="message">No transcripts here.</div>
      </div>

      <div className="transcripts-container">
        <PlayerTranscriptList
          model={model} />
      </div>
    </div>
  )
});

const PlayerTranscriptList = observer(({
  model
}: PlayerTranscriptsProps) => {

  return (<ul className="transcript-list">
    {(model.items || []).map(moment => (
      <PlayerTranscripItem key={moment.id} model={model} transcript={moment} />
    ))}
  </ul>)
})

type PlayerTranscripItemProps = {
  model: PlayerTranscriptsState;
  transcript: Transcript;
}

const PlayerTranscripItem = observer(({
  model,
  transcript
}: PlayerTranscripItemProps) => {

  return <li className="transcript-item"
    data-moment={true}
    data-moment-id={transcript.id}>
    <PlayerTranscriptBlock
      pageModel={model}
      moment={transcript} />
  </li>
})