export function createResizeObserver(callback: ResizeObserverCallback): ResizeObserver | null {
  if (typeof ResizeObserver === 'function')
    return new ResizeObserver(callback);
  return null;
}

export function createBroadcastChannel(name: string): BroadcastChannel | null {
  if (typeof BroadcastChannel === 'function')
    return new BroadcastChannel(name);
  return null;
}