import './media.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { JobModel } from '../../entities';
import { JobCard } from './jobCard';
import { Catalog, Grid } from '../layout';
import { ICatalogSource } from '../layout/catalog';
import { CatalogState } from '../layout/catalogState';
import { CatalogFooter } from '../layout/catalogFooter';
import { MyUploadsSVGIcon } from '../svg';

type Props = {
  model: CatalogState;
  source: ICatalogSource<JobModel>;
  teamId?: string;
};

export const JobCardCatalog = observer(({ model, source, teamId }: Props) => {
  const { entities } = source;

  return (
    <Catalog className="job-card-catalog catalog" model={model}>
      {entities.length > 0 && (
        <>
          <Grid className="catalog-grid">
            {entities.map((job) => {
              return (
                <li key={job.id} className="grid-item catalog-item">
                  <JobCard model={job} teamId={teamId} />
                </li>
              );
            })}
          </Grid>
          <CatalogFooter model={model} />
        </>
      )}

      {entities.length === 0 && (
        <div className="empty-box" aria-hidden={model.isLoading}>
          <div className="catalog empty-catalog">
            <div className="icon">
              <MyUploadsSVGIcon />
            </div>
            <h1 className="headline-1">
              {teamId
                ? 'Uploaded content will appear here.'
                : ' Looks like you haven’t uploaded any videos.'}
            </h1>
          </div>
        </div>
      )}
    </Catalog>
  );
});
