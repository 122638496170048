import { useKernel } from '../../kernel/kernelHooks';
import { AuthContext } from './authSchema';
import { AuthService } from './authService';

export function useAuthService(): AuthService {
  const kernel = useKernel();
  return kernel.store.authService;
}

export function useAuthContext(): AuthContext | null {
  const authService = useAuthService();
  return authService.context;
}