import { action, computed, IObservableArray, makeObservable, observable, reaction, runInAction } from 'mobx';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { PlayerAdapterState } from '../playerAdapter';
import { JobModel } from '../../entities';
import clamp from 'lodash/clamp';
import { getRatio } from '../../core/math';
import { TimeTracker } from '../../core/time/timeTracker';
import { createArray } from '../../core/array';
import Bowser from 'bowser';
import HLS, { Fragment } from 'hls.js';
import { DateTime } from 'luxon';

// switch for player seekable mode - is overwritten by instance restrictions
const IS_SEEKABLE = true;

// player should jump cu live edge when falling behind
const AUTOSYNC_TO_LIVE = false;
// const AUTOSYNC_TOLERANCE = 1;

// tolerance for live delay until it's considered behind live - a autosync is triggered
const LIVE_DELAY_TOLERANCE = 1;

// delay delta in secs for preventing play stalls when to close to the seekable end - non seekable - similar to hls liveSyncDuration
const LIVE_DELTA = 5;
// delay delta in secs for preventing play stalls or losing live edge in certain scenarios - seekable player
const LIVE_SEEKABLE_DELTA = 10;

// interval in seconds before resyncing the player time after leaving the seekable window
const OUTSIDE_WINDOW_OFFSET = 3;

// average desync in secs for tracker which will trigger a resync
const TRACKER_DESYNC_TOLERANCE = 3;

// max seekable window in sec on the playbar
const MAX_SEEKABLE_INT = 540;//600;

// enable console logs switch
const ENABLE_LOGGING = false;

// enable verbose console logs switch
const ENABLE_VERBOSE_LOGGING = false;

// absolute time delta in seconds
const ABS_TIME_DELTA = -1;

// absolute time delta in seconds
const PLAYBACK_END_DELTA = 1;

type Props = {
} & BindingProps<{
  isActive: boolean,
  adapter: PlayerAdapterState;
  jobId: string;
}>

export class PlayerLiveAdapter
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    // handle active stream change
    reaction(() => this.isActive, () => this.handleActiveChange());

    // handle seekable change
    // reaction(() => this.isSeekable, () => this.handleSeekableChange());

    // handle real time mode toggle
    reaction(() => this.isRealTime, () => this.handleRealTimeChange());

    // handle seekable end changes
    reaction(() => this.seekableEnd, () => this.handleSeekableEndChange());

    // handle live edge changes
    reaction(() => this.atLiveEdge, () => this.handleLiveEdgeChange());

    // handle outside borders
    reaction(() => this.isOutsideSeekableWindow, () => this.handleOutsideSeekableWindow());

    // handle hls stream completed
    reaction(() => this.isHlsStreamCompleted, () => this.handleStreamEnded());

    // handle stream end reached
    reaction(() => this.isStreamEndReached, () => this.handleStreamEndReached());

  }

  @observable isSeekRestricted = false;

  @observable isRealTime = true;

  @observable trackerDiffHistory: IObservableArray = createArray<number>(true) as IObservableArray;

  @observable lastSeekByUser = false;

  @observable isFirstSync = true;

  @observable timeTracker: TimeTracker = new TimeTracker({ //synced with liveCurrentTime
    intervalInMs: 100,
    onIncrement: () => this.syncTrackedCurrentTime()
  });

  @observable isPlaybackCompleted = false;

  @observable isStreamEnded = false;

  @observable trackedCurrentTime = 0;

  @computed get isSeekable(): boolean {
    if (!IS_SEEKABLE)
      return false;

    if (this.isSeekRestricted)
      return false;

    return true;
  }

  @computed get isActive(): PlayerAdapterState {
    return this.resolvedProps.isActive ?? false;
  }

  @computed get adapter(): PlayerAdapterState {
    return this.resolvedProps.adapter ?? null;
  }

  @computed get jobId(): string | null {
    return this.resolvedProps.jobId ?? null;
  }

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  // native adapter input fields - start 
  @computed
  get initFragment(): Fragment | null {
    return this.adapter.initFragment || null;
  }

  @computed
  get initPts(): number | null {
    return this.adapter.initPTS ?? null;
  }

  @computed
  get initFragmentStart(): number {
    return this.initFragment?.start ?? 0;
  }

  @computed
  get absoluteStartTime(): number {
    return this.absoluteStartTimeByPts;
  }

  @computed
  get absoluteStartTimeBySN(): number {
    if (this.initFragment)
      return (this.initFragment.sn * Math.round(this.initFragment.duration)) - this.initFragment.start + ABS_TIME_DELTA;

    return 0;
  }

  @computed
  get absoluteStartTimeByPts(): number {
    if (this.initPts)
      return this.initPts / 90000;

    return 0;
  }

  @computed
  get currentTime(): number {
    if (this.timeTracker.status === 'running')
      return this.trackedCurrentTime;
    else
      return this.adapter.currentTime;
  }

  @computed
  get realCurrentTime(): number {
    return this.adapter.videoElement?.currentTime || 0;
  }

  @computed
  get absoluteCurrentTime(): number {
    return this.absoluteStartTime + this.currentTime;
  }

  @computed
  get liveReactionTime(): number {
    const time = this.isActuallyPlaying ? this.currentTime : this.appliedLiveCurrentTime
    return this.absoluteStartTimeByPts + time;
  }

  @computed
  get hls(): HLS | null {
    return this.adapter.hls;
  }

  @computed
  get isHlsStreamCompleted(): boolean {
    return this.isStreamEnded;
  }

  @computed
  get isStreamEndReached(): boolean {
    return this.isHlsStreamCompleted && ((this.seekableEnd - this.currentTime) < PLAYBACK_END_DELTA);
  }

  // it refers to the general state of the live stream, it doesn't take into consideration the media status
  @computed get isPlaybackActive(): boolean {
    return this.isActive && // native adapterListener has live stream attached
      this.isHlsManifestLoaded && // manifest is loaded successfully
      !this.isPlaybackCompleted; // playback is still active
  }

  @computed get isHlsManifestLoaded(): boolean {
    return this.adapter.isHlsManifestLoaded;
  }

  @computed
  get playbackRate(): number {
    return this.adapter.playbackRate;
  }

  @computed
  get isNormalPlaybackRate(): boolean {
    return this.adapter.playbackRate === 1;
  }

  @computed
  get isActuallyPlaying(): boolean {
    return this.adapter.isActuallyPlaying;
  }

  @computed
  get isPaused(): boolean {
    return this.adapter.isPaused;
  }

  @computed
  get seekableStart(): number {
    return this.initFragmentStart + 0.05;
    // return this.isSeekable ? //this.adapter.seekableStart
    //   this.initFragmentStart + 0.05 : // to avoid some issues when the first fragment start is > playerItemSource seekableStart (+ delta because of buffer delay to the start of the fragment)
    //   0 //this.adapter.seekableStart;
  }

  @computed
  get seekableEnd(): number {
    return this.adapter.seekableEnd;
  }

  // HLS FRAGMENT LOGIC
  @computed
  get currentHlsFragment(): Fragment | null {
    return this.adapter.currentFragment;
  }

  @computed
  get currentHlsFragmentAbsoluteDateTime(): DateTime | null { //UTC
    if (this.currentHlsFragment?.programDateTime)
      return DateTime.fromMillis(this.currentHlsFragment?.programDateTime);

    return null;
  }

  @computed
  get currentHlsFragmentStartTime(): number | null { //seconds
    if (this.currentHlsFragment?.start)
      return this.currentHlsFragment.start;

    return null;
  }

  @computed
  get absoluteCurrentDateTime(): DateTime | null { //UTC
    if (this.currentHlsFragmentAbsoluteDateTime && this.currentHlsFragmentStartTime) {
      return DateTime.fromSeconds(
        this.currentHlsFragmentAbsoluteDateTime?.toSeconds() +
        (this.currentTime - this.currentHlsFragmentStartTime)
      );
    }

    return null;
  }

  /*
    Live Playlists:
    The server MAY limit the availability of Media Segments by removing
    Media Segments from the Playlist file. If Media
    Segments are to be removed, the Playlist file MUST contain an EXT-X-
    MEDIA-SEQUENCE tag.  Its value MUST be incremented by 1 for every
    Media Segment that is removed from the Playlist file; it MUST NOT
    decrease or wrap.  Clients can malfunction if each Media Segment does
    not have a consistent, unique Media Sequence Number.
  */
  @computed
  get currentHlsFragmentSN(): number | null { // Fragment Sequence Number
    if (this.currentHlsFragment)
      return this.currentHlsFragment.sn;

    return null;
  }

  @computed
  get currentHlsFragmentSNStartTime(): number | null { // absolute time duration for current fragment start calculated based on SN and duration
    if (this.currentHlsFragmentSN && this.adapter.fragmentDuration)
      return this.currentHlsFragmentSN * this.adapter.fragmentDuration;

    return null;
  }

  @computed
  get currentAbsoluteTimeBySN(): number | null { // current absolute time duration based on current fragment
    if (this.currentHlsFragmentSNStartTime && this.currentHlsFragmentStartTime)
      return this.currentHlsFragmentSNStartTime + (this.currentTime - this.currentHlsFragmentStartTime)

    return null;
  }

  @computed
  get currentAbsoluteTimeByDate(): number | null { // current absolute time duration based on absolute dates
    return (this.job?.liveStreamStartDate &&
      this.absoluteCurrentDateTime &&
      (this.absoluteCurrentDateTime.diff(this.job?.liveStreamStartDate).toMillis() / 1000)
    ) ||
      null;
  }

  @computed
  get currentAbsoluteTimeByInitPts(): number | null { // current absolute time duration based on absolute dates
    return this.absoluteStartTimeByPts + this.currentTime;
  }
  // native adapter input fields - end

  // tracker fields - start
  @computed
  get trackedLiveCurrentTime(): number { // synced with liveCurrentTime
    return this.timeTracker.timeInSec;
  }

  @computed
  get isTrackerDesynced(): boolean {
    // return Math.abs(this.trackedLiveCurrentTime - this.liveCurrentTime) > TRACKER_DESYNC_TOLERANCE;
    return Math.abs(this.avgTrackerDiff) > TRACKER_DESYNC_TOLERANCE;
  }
  // tracker fields - end

  // duration fields - start
  @computed
  get duration(): number {
    return (
      this.isRealTime ||
      (this.currentTime > this.appliedLiveCurrentTime)
    ) ?
      this.currentTime :
      this.appliedLiveCurrentTime;
  }

  @computed
  get seekableDuration(): number {
    return this.duration - this.liveSeekableStart;
  }

  @computed
  get playTimeDuration(): number {
    return this.currentTime - this.liveSeekableStart;
  }

  @computed
  get hasDuration() {
    return (Number.isFinite(this.duration) && this.duration > 0);
  }

  @computed
  get hasSeekableDuration() {
    return (Number.isFinite(this.seekableDuration) && this.seekableDuration > 0);
  }
  // duration fields - end

  // controller fields - start
  @computed
  get currentTimeRatio(): number {
    return this.getTimeRatio(this.currentTime);
  }

  @computed
  get bufferedTime(): number {
    return clamp(this.adapter.bufferedTime, this.liveSeekableStart, this.duration);
  }

  @computed
  get bufferedTimeRatio(): number {
    return this.getTimeRatio(this.bufferedTime);
  }
  // controller fields - end 

  // live applied fields - start 
  @computed
  get liveDiff(): number {
    return this.appliedLiveCurrentTime - this.currentTime;
  }

  @computed
  get trackerDiff(): number {
    return this.appliedLiveCurrentTime - this.liveCurrentTime;
  }

  @computed
  get avgTrackerDiff(): number {
    const n = 10;
    const lastNValues = this.trackerDiffHistory.slice(-n);
    const avgValueCount = lastNValues.length;
    return avgValueCount > 0 ? lastNValues.reduce((acc, a) => acc + a, 0) / avgValueCount : 0;
  }

  @computed
  get isBehindLive(): boolean {
    return this.lastSeekByUser === true ?
      // if it's seeked by user interface by more than 2 sec it will be considered desynced 
      this.liveDiff > 2 :
      // automatic syncing will allow for a predefined delta desync
      this.liveDiff > LIVE_DELAY_TOLERANCE;
  }

  @computed
  get atLiveEdge(): boolean {
    return !this.isBehindLive;
  }

  @computed
  get liveSeekableStart(): number {
    // if (MAX_SEEKABLE_INT === Infinity || !this.isSeekable)
    //   return this.seekableStart; //0

    const seekableRangeSize = this.appliedLiveCurrentTime - this.seekableStart;

    return seekableRangeSize < MAX_SEEKABLE_INT ?
      this.seekableStart :
      this.appliedLiveCurrentTime - MAX_SEEKABLE_INT;
  }

  @computed
  get liveCurrentTime(): number {
    const delta = this.isSeekable ? LIVE_SEEKABLE_DELTA : LIVE_DELTA;
    return this.seekableEnd >= delta ? this.seekableEnd - delta : 0;
  }

  @computed
  get isRealTimeDesynced(): boolean {
    return (this.isRealTime &&
      this.isActuallyPlaying &&
      this.isNormalPlaybackRate) ? this.isBehindLive : false;
  }

  @computed
  get isOutsideSeekableWindow(): boolean {
    return this.currentTime < this.liveSeekableStart - OUTSIDE_WINDOW_OFFSET;

  }

  @computed
  get appliedLiveCurrentTime(): number {
    const time = this.isSeekable ? this.trackedLiveCurrentTime : this.liveCurrentTime;
    return time < this.seekableStart ? this.seekableStart : time; // if not enough loaded fragments for an acctual determination of the live edge, use seekable start (start of first fragment or 0)
  }
  // live applied fields - end

  checkSeekRestricted(): boolean {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isRestricted = browser.isBrowser('safari');
    return isRestricted;
  }

  @action
  init() {
    if (!this.isActive) //TODO: find a more appropriate solution
      return;
    // this.initHLS();
    this.isSeekRestricted = this.checkSeekRestricted();
    this.seekToLiveEdge();
    if (this.isSeekable) // the time tracker should be init at level loaded
      this.timeTracker.start();
  }

  // @action
  // initHLS() {
  //   if (this.hls) {
  //     this.hls.config.liveSyncDuration = LIVE_DELTA;
  //     // this.hls.config.maxMaxBufferLength = 5000;
  //     this.hls.config.liveBackBufferLength = 1200;
  //     this.hls.config.maxBufferSize = 180000000;
  //   }
  //   // console.log(
  //   //   this.hls?.config.liveSyncDuration,
  //   //   this.hls?.config.liveBackBufferLength,
  //   //   this.hls?.config.maxBufferSize
  //   // )
  // }

  @action
  reset() {
    ENABLE_LOGGING && console.log('live reset');
    this.isSeekRestricted = false;
    this.isRealTime = true;
    this.lastSeekByUser = false;
    this.timeTracker.reset();
    this.trackerDiffHistory.clear();
    this.isFirstSync = true;
    this.isPlaybackCompleted = false;
    this.isStreamEnded = false;
  }

  @action
  syncTracker() {
    this.timeTracker.setTime(this.liveCurrentTime * 1000);
    // this.isFirstSync = false;
    this.trackerDiffHistory.clear();
    ENABLE_LOGGING && console.log('tracker synced');
  }

  @action
  syncTrackedCurrentTime() {
    if (this.timeTracker.status !== 'running')
      return;
    const time = Math.round(this.realCurrentTime * 100) / 100;
    runInAction(() => this.trackedCurrentTime = time);
  }

  @action
  seekToLiveEdge() {
    if (this.atLiveEdge)
      return;

    this.invoke('seekToLive', { time: this.appliedLiveCurrentTime });
    this.lastSeekByUser = false;
    ENABLE_LOGGING && console.log('seeked to live');
  }

  @action
  enterRealTime() {
    if (this.isHlsStreamCompleted)
      return;
    this.isRealTime = true;
    this.adapter.invoke('setPlaybackRate', { rate: 1 }); // if might double the reaction for save measure
    ENABLE_LOGGING && console.log('enter real time');
  }

  @action
  exitRealTime() {
    if (!this.isSeekable)
      return;

    ENABLE_LOGGING && console.log('exit real time');
    this.isRealTime = false;
  }

  validateSeekTime = (time: number) => {
    if (time >= this.liveSeekableStart && time <= this.seekableEnd)
      return true;

    return false;
  }

  getValidSeekableTime = (time: number) => {
    if (this.validateSeekTime(time))
      return time;

    if (time < this.liveSeekableStart)
      return this.liveSeekableStart;
    else
      return this.liveCurrentTime;
  }

  @action
  setPlaybackCompleted() {
    this.isPlaybackCompleted = true;
    ENABLE_LOGGING && console.log('live playback ended');
  }

  @action
  setStreamEnded() {
    this.isStreamEnded = true;
    ENABLE_LOGGING && console.log('live stream ended');
  }

  @action
  invoke = (type: string, payload: any = null) => {
    let time;

    switch (type) {

      case 'exitRealTime':
        this.exitRealTime();
        break;
      case 'enterRealTime':
        this.enterRealTime();
        break;

      case 'updateRealTime':
        if (this.atLiveEdge)
          this.invoke('enterRealTime');
        else
          this.invoke('exitRealTime');
        break;

      case 'seek':
        if (!this.isSeekable) return;
        // console.log('seek', time);
        time = this.getValidSeekableTime(payload?.time);
        this.trackedCurrentTime = time;
        this.adapter.invoke('seek', { time });
        this.invoke('updateRealTime');
        break;

      case 'seekStepForward':
        break;

      case 'seekToLive':
        time = this.getValidSeekableTime(payload?.time);
        this.adapter.invoke('seek', { time });
        this.invoke('enterRealTime');
        break;

      case 'play':
        if (!this.isSeekable || this.isRealTime || payload?.jumpToLive)
          this.seekToLiveEdge();

        this.syncTrackedCurrentTime();
        this.adapter.invoke('play');
        break;

      case 'autoplay':
        // NOTE: Was previously invoking 'autoplay' on adapter
        this.adapter.invoke('setAutoplay', { autoplay: true });
        this.adapter.invoke('play');
        if (!this.isSeekable || this.isRealTime)
          this.seekToLiveEdge();
        break;

      case 'pause':
        this.adapter.invoke('pause');
        this.invoke('exitRealTime');
        break;

      case 'setPlaybackRate':
        const { rate } = payload;
        if (this.isRealTime && rate > 1)
          return;
        this.adapter.invoke('setPlaybackRate', payload);
        break;

      case 'playbackCompleted':
        if (!this.isPlaybackActive)
          return;
        this.setPlaybackCompleted();
        this.emit('playbackCompleted');
        ENABLE_LOGGING && console.log('playback ended');
        break;
    }
  }

  // @action
  getTimeRatio(timePoint: number) {
    if (timePoint < this.liveSeekableStart)
      return 0;

    return getRatio((timePoint - this.liveSeekableStart), this.seekableDuration);
  }

  // @action
  getTimeFromRatio(ratio: number): number {
    return this.liveSeekableStart + (ratio * this.seekableDuration);
  }

  // @action
  convertToAbsoluteTime(time: number): number {
    return this.absoluteStartTime + time;
  }

  // @action
  convertToRelativeTime(time: number): number {
    return time - this.absoluteStartTime;
  }

  // @action
  getTimeRatioFromAbsoluteTime(time: number) {
    return this.getTimeRatio(this.convertToRelativeTime(time));
  }

  // @action
  getAbsoluteTimeFromRatio(ratio: number) {
    return this.convertToAbsoluteTime(this.getTimeFromRatio(ratio));
  }

  @action
  playheadTimeRatio(timePoint: number) {
    if (this.isSeekable)
      return this.getTimeRatio(timePoint);
    else
      return 1;
  }

  @action
  handleStreamEnded() {
    this.timeTracker.pause();
    this.trackerDiffHistory.clear();
    this.timeTracker.setTime((this.seekableEnd - PLAYBACK_END_DELTA) * 1000);
    this.invoke('exitRealTime');
  }

  @action
  handleStreamEndReached() {
    ENABLE_LOGGING && console.log('handleStreamEndReached');
    this.invoke('playbackCompleted');
  }

  // Reaction Handlers - Section start 
  @action
  handleLiveEdgeChange() {
    if (!this.isActive) //TODO: find a more appropriate solution
      return;

    // if (this.isPaused && !this.atLiveEdge) // when video is paused
    //   this.invoke('exitRealTime');

    if ( // when crosses the real time edge (no live when hls is completed)
      this.isSeekable &&
      this.atLiveEdge && !this.isTrackerDesynced && !this.isHlsStreamCompleted &&
      this.playbackRate >= 1 // just for safe measure in order to avoid glitches when playbackRate < 1 at live edge
    ) {
      this.invoke('enterRealTime');
      this.adapter.invoke('setPlaybackRate', { rate: 1 });
    }

    if (AUTOSYNC_TO_LIVE && this.isRealTimeDesynced) // when playing and falls behind
      this.seekToLiveEdge();

    if (this.playbackRate < 1 && !this.atLiveEdge) // when rate < 1 and falls behind the live edge
      this.invoke('exitRealTime');
  }

  @action
  handleRealTimeChange() {
    if (!this.isActive) //TODO: find a more appropriate solution
      return;

    if (this.isRealTime)
      this.seekToLiveEdge();
  }

  @action
  handleSeekableChange() {
    if (!this.isActive) //TODO: find a more appropriate solution
      return;

    if (this.isSeekable)
      this.timeTracker.start();
    else
      this.timeTracker.stop();
  }

  @action
  handleActiveChange() {
    ENABLE_LOGGING && console.log('live active change', this.isActive);
    this.reset();

    if (this.isActive)
      this.init();
  }

  @action
  handleOutsideSeekableWindow() {
    this.seekToLiveEdge();
  }

  @action
  handleSeekableEndChange() {
    if (!this.isActive) //TODO: find a more appropriate solution
      return;

    ENABLE_VERBOSE_LOGGING && console.log({
      currentTime: this.currentTime,
      seekableEnd: this.seekableEnd,
      liveDiff: this.liveDiff,
      duration: this.duration,
      trackedLiveCurrentTime: this.trackedLiveCurrentTime,
      liveCurrentTime: this.liveCurrentTime,
      trackerDelay: this.trackerDiff,
      liveSyncPosition: this.hls?.liveSyncPosition || 'not available',
      fragmentCurrentTime: this.currentHlsFragmentAbsoluteDateTime?.toISO(),
      absoluteDateTime: this.absoluteCurrentDateTime?.toISO(),
    });

    if (this.isFirstSync) {
      if (this.isSeekable)
        this.syncTracker();
      this.seekToLiveEdge();
    }

    if (this.isSeekable) {
      this.trackerDiffHistory.push(this.trackerDiff);
      // console.log(this.avgTrackerDiff);

      if (this.isTrackerDesynced)
        this.syncTracker();
    }

    if (this.isFirstSync)
      runInAction(() => this.isFirstSync = false);

    if (this.isHlsStreamCompleted)
      this.timeTracker.setTime((this.seekableEnd - PLAYBACK_END_DELTA) * 1000);
  }
  // Reaction Handlers - Section end

}