import { action, computed, makeObservable, observable } from 'mobx';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { JobModel } from '../../entities';
import { LiveStreamChannels } from '@clipr/lib';

export class ManualIngestWindowState extends StoreNode {
  readonly nodeType = 'manualIngestWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(this.windowListener);
  }

  readonly window = new WindowState(this.store);

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading || this.isLoading)
          return;
        this.close();
        break;
    }
  };

  @observable isLoading: boolean = false;
  @observable jobId: string | null = null;
  @observable teamId: string | null = null;
  private abortController: AbortController | null = null;

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @action
  open({ jobId, teamId }: { jobId: string, teamId: string }) {
    this.jobId = jobId;
    this.teamId = teamId;

    this.emit('open');
    this.dispatch('Overlays', 'openWindow', { name: 'ManualIngestWindow' });

    this.init();
  }

  @action
  private async load() {
    this.abortLoading();
    this.isLoading = true;

    const {
      store,
      jobId
    } = this;

    if (!jobId) {
      this.isLoading = false;
      console.error('No job id provided');
      notifyError(this, 'Could not load job');
      return;
    }

    const abortCtrl = new AbortController();
    this.abortController = abortCtrl;

    const reqOpts = {
      signal: abortCtrl.signal
    };

    const [, error] = await store.apiFetchJob(jobId, false, reqOpts);

    // if one of the requests is aborted, just stop and reset loading
    if (error) {
      console.error(this, error);
      notifyError(this, 'Could not load job');
      this.isLoading = false;
    }

    const { job } = this;

    if (!job) {
      notifyError(this, 'Could not load job');
      console.error('The job could not be retrieved from the store');
      this.isLoading = false;
      return;
    }

    this.isLoading = false;
  }

  @action
  init() {
    const { job } = this;

    if (!job)
      return;

    this.load();
  }

  @action
  async startIngest(liveStreamChannel: LiveStreamChannels) {

    if (!this.jobId) {
      notifyError(this, 'Could not ingest job');
      console.error(this, 'No job id provided');
      return;
    }

    this.isLoading = true;

    const [, err] = await this.store.jobManager.apiStopLiveJob(this.jobId, liveStreamChannel);

    if (err) {
      notifyError(this, 'There was an error ingesting the job');
      this.isLoading = false;
      return;
    }

    await this.load();
    this.isLoading = false;
    this.close();
    notifySuccess(this, `Ingest started.`);
  }

  @action
  private abortLoading() {
    const lastAbortCtrl = this.abortController;
    if (lastAbortCtrl)
      lastAbortCtrl.abort();
  }

  @action
  close() {
    this.emit('close');
    this.abortLoading();
    this.dispatch('Overlays', 'closeWindow');
  }
}
