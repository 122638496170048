import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { Team } from '../../entities';
import { notifySuccess, notifyError } from '../../services/notifications';
import Routes from '../../routes';
import { input } from '..';
import { Theme } from '../../services/ui/utils';

export class StreamQueueSettingsWindowState
  extends StoreNode {
  readonly nodeType = 'StreamQueueSettingsWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(this.windowListener);
  }

  readonly window = new WindowState(this.store);

  readonly theme = input(this, {
    name: 'theme',
    selectorItems: [
      { value: 'darkTheme', label: 'Dark Theme' },
      { value: 'lightTheme', label: 'Light Theme' }
    ]
  });

  @observable teamId: string | null = null;

  @computed
  get team(): Team | null {
    return this.store.teamManager.getTeam(this.teamId);
  }

  @computed
  get iframeEmbedCode(): string | null {
    if (!this.teamId) 
      return null;

    const theme = this.theme.value === 'darkTheme' ? Theme.Dark : Theme.Light;
    const libraryRoute = Routes.teamLiveStreamQueueWidget(this.teamId, { theme });

    const embedRoute = window.location.origin + libraryRoute;
    const iframe = document.createElement('iframe');

    iframe.src = embedRoute;
    iframe.width = '1280';
    iframe.height = '720';
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allowfullscreen', 'allowfullscreen');
    iframe.setAttribute('allow', 'autoplay; encrypted-media; picture-in-picture; clipboard-write');
    iframe.setAttribute('sandbox', 'allow-same-origin allow-scripts allow-popups allow-forms allow-popups-to-escape-sandbox allow-downloads allow-orientation-lock');

    let code = iframe.outerHTML;

    // // DIRTY HACK TO REVERSE URL ESCAPING
    code = code.replaceAll(/&amp;/gi, '&');
    return code;
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  };

  @action
  open({ teamId }: { teamId: string }) {
    this.teamId = teamId;
    this.theme.loadValue('lightTheme');

    this.emit('open');
    this.dispatch('Overlays', 'openWindow', { name: 'StreamQueueSettingsWindow' });
  }

  @action
  async copyPageIframeCode() {
    if (!this.iframeEmbedCode) {
      notifyError(this, 'Error copying the embed code');
      return;
    }

    await navigator.clipboard?.writeText(this.iframeEmbedCode);
    notifySuccess(this, 'Embed code copied to clipboard!');
  }


  @action
  close() {
    this.window.close();
    this.dispatch('Overlays', 'closeWindow');
  }
}
