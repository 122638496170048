import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { useState } from 'react';
import { useStore } from '../../store/storeHooks';
import { refProxy } from '../../store/refProxy';

export type OverlayVisibility = boolean | 'OnHover' | 'OnClick';

type Props = BindingProps<{
  visibility?: OverlayVisibility,
  visibilityTrigger?: boolean,
  makeExclusiveWhenVisible?: boolean,
  suppressFrom?: OverlayState[],
  // dirty hack
  onHide?: () => void,
  onShow?: () => void
}>
export type OverlayStateProps = Props;

export type OverlayAnchorJSXProps = Partial<{
  onMouseEnter: React.MouseEventHandler,
  onMouseLeave: React.MouseEventHandler,
  onClick: React.MouseEventHandler,
  onFocus: React.FocusEventHandler,
  onFocusCapture: React.FocusEventHandler,
  onBlur: React.FocusEventHandler
}>

export type OverlayEventHandlerName =
  'handleAnchorMouseEnter' |
  'handleAnchorMouseLeave' |
  'handleAnchorClick' |
  'handleAnchorFocus' |
  'handleAnchorBlur';

export class OverlayState
  extends StoreNode {

  [key: string]: any;

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.onPropChange('visibilityTrigger',
      this.handleVisibilityTriggerChange.bind(this));

    this.onFieldChange('isVisible',
      this.handleIsVisibleChange.bind(this));
  }

  readonly anchorRef = refProxy(this, 'anchor');
  readonly overlayRef = refProxy(this, 'overlay');
  readonly arrowRef = refProxy(this, 'arrow');

  @computed get suppressFrom(): OverlayState[] {
    return this.getResolvedProp('suppressFrom', []);
  }

  @computed get visibilityTrigger(): boolean {
    return this.getResolvedProp('visibilityTrigger', []);
  }

  @observable anchorIsHovered = false;
  @observable anchorIsClicked = false;
  @computed get anchorIsFocused() {
    return this.anchorRef.isFocused;
  }
  @computed get anchorIsFocusedWithin() {
    return this.anchorRef.isFocusedWithin;
  }

  @computed get isClickedLast() {
    const { ui } = this.store;
    return (
      ui.isElementLastClicked(this.overlayRef?.node) ||
      ui.isElementLastClicked(this.anchorRef?.node))
  }
  @computed get isClickCapturedLast() {
    const { ui } = this.store;
    return (
      ui.isElementLastClickCaptured(this.overlayRef?.node) ||
      ui.isElementLastClickCaptured(this.anchorRef?.node))
  }

  @computed get isOverlayClickCapturedLast() {
    const { ui } = this.store;
    return (
      ui.isElementLastClickCaptured(this.overlayRef?.node))
  }

  @observable isVisibleFromClickHandler = false;
  @computed get isVisibleFromClick(): boolean {
    if (!this.isClickedLast)
      return false;
    return this.isVisibleFromClickHandler;
  }

  @computed get isVisibleFromHover(): boolean {
    return this.anchorIsHovered;
  }

  @observable overlayIsHovered = false;
  @observable isPlayerTutorialOn = false;
  @computed get overlayIsFocused() {
    return this.overlayRef.isFocused;
  }
  @computed get overlayIsFocusedWithin() {
    return this.overlayRef.isFocusedWithin;
  }

  private readonly defaultVisibilityTransform: OverlayVisibility = 'OnHover';

  @computed get visibility(): OverlayVisibility {
    return this.getResolvedProp('visibility', this.defaultVisibilityTransform);
  }

  @computed get makeExclusiveWhenVisible(): boolean {
    return this.getResolvedProp('makeExclusiveWhenVisible', false);
  }

  @computed get isVisible(): boolean {
    if (this.suppressFrom.find(ovr => ovr.isVisible))
      return false;

    switch (this.visibility) {
      case true:
      case false: return this.visibility;

      case 'OnHover':
        return this.isVisibleFromHover;
      case 'OnClick':
        return this.isVisibleFromClick;
    }
    return false;
  }

  @computed get isExclusivelyVisible(): boolean {
    return this.makeExclusiveWhenVisible ? this.isVisible : false;
  }

  @action
  setPlayerTutorialMode(mode: boolean) {
    this.isPlayerTutorialOn = mode;
  }

  @action
  handleVisibilityTriggerChange(newVal: boolean, oldVal: boolean) {
    if (newVal === false)
      this.hide();
  }

  @action
  handleIsVisibleChange(newVal: boolean, oldVal: boolean) {
    if (newVal) {
      this.notifyShow();
    } else {
      this.notifyHide();
    }
  }

  @action
  handleAnchorMouseEnter(evt?: React.MouseEvent) {
    this.anchorIsHovered = true;
  }

  @action
  handleAnchorMouseLeave(evt?: React.MouseEvent) {
    this.anchorIsHovered = false;
  }

  @action
  handleAnchorClick(evt?: React.MouseEvent) {
    if (this.isOverlayClickCapturedLast)
      return;

    if (this.isVisibleFromClick)
      this.isVisibleFromClickHandler = false;
    else
      this.isVisibleFromClickHandler = true;
  }

  @action
  handleOverlayClick(evt: React.MouseEvent) {

  }

  @action
  handleAnchorFocus(evt: React.FocusEvent) {

  }

  @action
  handleAnchorBlur(evt: React.FocusEvent) {

  }

  @action
  hide() {
    this.isVisibleFromClickHandler = false;
    this.anchorIsHovered = false;
  }

  notifyHide() {
    const onHide = this.props?.onHide;
    if (typeof onHide === 'function')
      onHide();
    this.emit('hide');
  }

  notifyShow() {
    const onShow = this.props?.onShow;
    if (typeof onShow === 'function')
      onShow();
    this.emit('show');
  }

  @computed get anchorProps(): OverlayAnchorJSXProps {
    const props: OverlayAnchorJSXProps = {};

    props.onMouseEnter = evt => this.handleAnchorMouseEnter(evt);
    props.onMouseLeave = evt => this.handleAnchorMouseLeave(evt);

    props.onClick = evt => this.handleAnchorClick(evt);
    props.onFocus = evt => this.handleAnchorFocus(evt);
    props.onBlur = evt => this.handleAnchorBlur(evt);

    return props;
  }
}

export function useOverlayState(props?: Props): OverlayState {

  const store = useStore();
  const init = new OverlayState(store, props);

  const [state] = useState(init);

  return state;
}