import { AuthContextType, IAuthContext } from './authSchema';

type Props = {
  allowActions?: boolean | null;
}

export class AnonymousAuthContext
  implements IAuthContext {

  constructor(props?: Props) {
    this.allowActions = props?.allowActions ?? true;
    Object.freeze(this);
  }

  readonly type = AuthContextType.Anonymous;
  readonly isAuthenticated: false = false;
  readonly isAnonymous: true = true;
  readonly isValid = true;

  readonly permit: null = null;
  readonly profile: null = null;
  
  readonly allowActions: boolean = true;
}