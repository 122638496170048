import './player.scss';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Playhead } from './playhead';
import { PlayheadState } from './playheadState';
import { RefProxy } from '../../store/refProxy';

type Props = {
  model: PlayheadState,
  containerRef: any,
  proxyRef?: RefProxy<HTMLElement>
}

export const PlayheadAdapter = observer((props: Props) => {

  const { model, proxyRef } = props;
  const { player: controller } = model;

  const position = controller?.playheadTimeRatio ?? 0;

  return (
    <Playhead
      proxyRef={proxyRef}
      position={position}
      onPointerDown={model.handlePointerDown} />
  );
});