import * as React from "react";
import classNames from 'classnames';

export const InsightfulBloomSVGIcon = ({className, ...props}: React.SVGProps<SVGSVGElement>) =>
  <svg
    viewBox="0 0 251 239"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames("insightful-bloom-svg-icon", "svg-icon", className)}
    {...props}>
      <style>
        {
          "\n    .main-bloom {\n      transform: scale(0);\n      transform-origin: center center;\n      animation: pulse 2s 1 alternate;\n    }\n\n    .bg-bloom {\n      transform: scale(0);\n      transform-origin: center center;\n      animation: pulse2 1.6s 1 alternate-reverse;\n      opacity: 0;\n    }\n\n    @keyframes pulse {\n      60% {\n        transform: scale(1);\n        opacity: 1;\n      }\n\n      100% {\n        transform: scale(0);\n        opacity: 0;\n      }\n    }\n\n    @keyframes pulse2 {\n      60% {\n        transform: scale(1);\n        opacity: 1;\n      }\n\n      80% {\n        opacity: 0;\n      }\n\n      100% {\n        transform: scale(0);\n        opacity: 0;\n      }\n    }\n  "
        }
      </style>
      <g className="main-bloom">
        <path
          d="M184.932 119.239C184.932 152.206 158.206 178.932 125.239 178.932C92.2708 178.932 65.5452 152.206 65.5452 119.239C65.5452 86.2708 92.2708 59.5452 125.239 59.5452C158.206 59.5452 184.932 86.2708 184.932 119.239Z"
          fill="#A0A8AD"
          fillOpacity={0.1}
          stroke="white"
        />
        <path
          d="M125.239 169.4C152.942 169.4 175.4 146.942 175.4 119.239C175.4 91.5353 152.942 69.0774 125.239 69.0774C97.5353 69.0774 75.0774 91.5353 75.0774 119.239C75.0774 146.942 97.5353 169.4 125.239 169.4Z"
          fill="#A0A8AD"
          fillOpacity={0.15}
        />
        <path
          d="M125.239 161.374C148.509 161.374 167.374 142.509 167.374 119.239C167.374 95.9679 148.509 77.1033 125.239 77.1033C101.968 77.1033 83.1033 95.9679 83.1033 119.239C83.1033 142.509 101.968 161.374 125.239 161.374Z"
          fill="#A0A8AD"
          fillOpacity={0.2}
        />
        <path
          d="M125.238 152.345C143.523 152.345 158.345 137.523 158.345 119.238C158.345 100.954 143.523 86.1321 125.238 86.1321C106.954 86.1321 92.1321 100.954 92.1321 119.238C92.1321 137.523 106.954 152.345 125.238 152.345Z"
          fill="#A0A8AD"
          fillOpacity={0.25}
        />
        <g filter="url(#filter0_d_11596:86089)">
          <path
            d="M142.736 107.713C142.736 117.941 134.794 119.53 134.794 129.062C134.794 133.983 129.834 134.398 126.058 134.398C122.797 134.398 115.596 133.16 115.596 129.059C115.596 119.531 107.791 117.943 107.791 107.713C107.791 98.1335 116.186 90.3677 125.395 90.3677C134.608 90.3677 142.736 98.1335 142.736 107.713Z"
            fill="#FFD983"
          />
        </g>
        <g filter="url(#filter1_d_11596:86089)">
          <path
            d="M131.883 141.769C131.883 143.084 128.334 145.74 125.264 145.74C122.194 145.74 118.645 143.084 118.645 141.769C118.645 140.453 122.192 140.974 125.264 140.974C128.334 140.974 131.883 140.453 131.883 141.769Z"
            fill="#CCD6DD"
          />
        </g>
        <path
          d="M132.739 106.492C132.118 105.871 131.114 105.871 130.493 106.492L125.262 111.723L120.031 106.492C119.41 105.871 118.406 105.871 117.785 106.492C117.164 107.113 117.164 108.117 117.785 108.738L123.674 114.627V131.442C123.674 132.32 124.385 133.03 125.262 133.03C126.139 133.03 126.851 132.32 126.851 131.442V114.627L132.739 108.738C133.36 108.117 133.36 107.113 132.739 106.492V106.492Z"
          fill="#FFCC4D"
        />
        <g filter="url(#filter2_d_11596:86089)">
          <path
            d="M134.793 139.387C134.793 141.141 133.369 142.564 131.616 142.564H118.908C117.155 142.564 115.731 141.141 115.731 139.387V129.856H134.793V139.387Z"
            fill="#99AAB5"
          />
        </g>
        <path
          d="M115.73 140.975C114.968 140.975 114.294 140.424 114.166 139.647C114.021 138.783 114.606 137.964 115.471 137.821L134.532 134.644C135.397 134.488 136.216 135.084 136.359 135.949C136.504 136.814 135.919 137.633 135.053 137.776L115.992 140.953C115.905 140.969 115.816 140.975 115.73 140.975V140.975ZM115.73 134.621C114.968 134.621 114.294 134.07 114.166 133.294C114.021 132.429 114.606 131.61 115.471 131.467L134.532 128.29C135.397 128.136 136.216 128.73 136.359 129.596C136.504 130.46 135.919 131.279 135.053 131.422L115.992 134.599C115.905 134.615 115.816 134.621 115.73 134.621V134.621Z"
          fill="#CCD6DD"
        />
        <rect
          x={125}
          y={45}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          fill="#99AAB5"
        />
        <rect
          x={125}
          y={77.1738}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          fill="#99AAB5"
        />
        <rect
          x={125}
          y={70.739}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          fill="#99AAB5"
        />
        <rect
          x={125}
          y={64.3044}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          fill="#99AAB5"
        />
        <rect
          x={128.217}
          y={193}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(180 128.217 193)"
          fill="#99AAB5"
        />
        <rect
          x={128.217}
          y={160.826}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(180 128.217 160.826)"
          fill="#99AAB5"
        />
        <rect
          x={128.217}
          y={167.261}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(180 128.217 167.261)"
          fill="#99AAB5"
        />
        <rect
          x={128.217}
          y={173.696}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(180 128.217 173.696)"
          fill="#99AAB5"
        />
        <rect
          x={199}
          y={119}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(90 199 119)"
          fill="#99AAB5"
        />
        <rect
          x={166.826}
          y={119}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(90 166.826 119)"
          fill="#99AAB5"
        />
        <rect
          x={173.261}
          y={119}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(90 173.261 119)"
          fill="#99AAB5"
        />
        <rect
          x={179.696}
          y={119}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(90 179.696 119)"
          fill="#99AAB5"
        />
        <rect
          x={51}
          y={122.217}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(-90 51 122.217)"
          fill="#99AAB5"
        />
        <rect
          x={83.1738}
          y={122.217}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-90 83.1738 122.217)"
          fill="#99AAB5"
        />
        <rect
          x={76.739}
          y={122.217}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-90 76.739 122.217)"
          fill="#99AAB5"
        />
        <rect
          x={70.304}
          y={122.217}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-90 70.304 122.217)"
          fill="#99AAB5"
        />
        <rect
          x={177.327}
          y={66.6733}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(45 177.327 66.6733)"
          fill="#99AAB5"
        />
        <rect
          x={154.577}
          y={89.4238}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(45 154.577 89.4238)"
          fill="#99AAB5"
        />
        <rect
          x={74.9482}
          y={173.601}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(-135 74.9482 173.601)"
          fill="#99AAB5"
        />
        <rect
          x={97.6982}
          y={150.851}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-135 97.6982 150.851)"
          fill="#99AAB5"
        />
        <rect
          x={177.327}
          y={171.326}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(135 177.327 171.326)"
          fill="#99AAB5"
        />
        <rect
          x={154.577}
          y={148.576}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(135 154.577 148.576)"
          fill="#99AAB5"
        />
        <rect
          x={70.3987}
          y={68.9497}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(-45 70.3987 68.9497)"
          fill="#99AAB5"
        />
        <rect
          x={93.1492}
          y={91.7}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-45 93.1492 91.7)"
          fill="#99AAB5"
        />
        <rect
          x={145.175}
          y={68.7202}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(21.8729 145.175 68.7202)"
          fill="white"
        />
        <rect
          x={149.218}
          y={58.6497}
          width={2.36311}
          height={6.43478}
          rx={1.1436}
          transform="rotate(21.8729 149.218 58.6497)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={106.87}
          y={170.478}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-158.127 106.87 170.478)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.3784}
          rx={1.1436}
          transform="matrix(-0.928012 -0.372549 -0.372549 0.928012 105.941 172.798)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={175.366}
          y={139.409}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(111.873 175.366 139.409)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.5957}
          rx={1.1436}
          transform="matrix(-0.37255 0.928012 0.928012 0.37255 178.494 140.665)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={73.6069}
          y={101.103}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-68.1271 73.6069 101.103)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.42869}
          rx={1.1436}
          transform="matrix(0.372549 -0.928012 -0.928012 -0.372549 69.9937 99.6523)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={174.911}
          y={97.812}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(66.8729 174.911 97.812)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.58471}
          rx={1.1436}
          transform="matrix(0.392772 0.919636 0.919636 -0.392772 178.499 96.2795)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={75.8716}
          y={142.681}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-113.127 75.8716 142.681)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={8.48348}
          rx={1.1436}
          transform="matrix(-0.392772 -0.919636 -0.919636 0.392772 72.4053 144.162)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={146.274}
          y={169.143}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(156.873 146.274 169.143)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.43478}
          rx={1.1436}
          transform="matrix(-0.919636 0.392771 0.392771 0.919636 147.32 171.591)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={101.406}
          y={70.1042}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-23.1271 101.406 70.1042)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.43478}
          rx={1.1436}
          transform="matrix(0.919636 -0.392772 -0.392772 -0.919636 99.6348 65.9541)"
          fill="white"
          fillOpacity={0.7}
        />
      </g>
      <g className="bg-bloom">
        <g clipPath="url(#clip0_11596:86089)">
          <path
            d="M154.434 8.87658C154.434 12.047 151.813 12.5394 151.813 15.4942C151.813 17.0196 150.176 17.1481 148.93 17.1481C147.854 17.1481 145.478 16.7646 145.478 15.4932C145.478 12.5399 142.903 12.0475 142.903 8.87658C142.903 5.90702 145.673 3.49976 148.712 3.49976C151.752 3.49976 154.434 5.90702 154.434 8.87658Z"
            fill="#FFD983"
          />
          <path
            d="M150.852 19.4333C150.852 19.841 149.681 20.6642 148.668 20.6642C147.655 20.6642 146.484 19.841 146.484 19.4333C146.484 19.0256 147.655 19.1871 148.668 19.1871C149.681 19.1871 150.852 19.0256 150.852 19.4333Z"
            fill="#CCD6DD"
          />
          <path
            d="M151.135 8.4984C150.93 8.30587 150.599 8.30587 150.394 8.4984L148.668 10.1198L146.942 8.4984C146.737 8.30587 146.406 8.30587 146.201 8.4984C145.996 8.69092 145.996 9.0021 146.201 9.19463L148.144 11.0199V16.2323C148.144 16.5046 148.378 16.7246 148.668 16.7246C148.957 16.7246 149.192 16.5046 149.192 16.2323V11.0199L151.135 9.19463C151.34 9.0021 151.34 8.69092 151.135 8.4984V8.4984Z"
            fill="#FFCC4D"
          />
          <path
            d="M151.813 18.695C151.813 19.2386 151.343 19.6798 150.764 19.6798H146.571C145.993 19.6798 145.523 19.2386 145.523 18.695V15.7407H151.813V18.695Z"
            fill="#99AAB5"
          />
          <path
            d="M145.523 19.1873C145.271 19.1873 145.049 19.0164 145.006 18.7756C144.959 18.5078 145.151 18.2537 145.437 18.2094L151.727 17.2246C152.012 17.1764 152.283 17.361 152.33 17.6294C152.377 17.8972 152.185 18.1513 151.899 18.1956L145.609 19.1804C145.58 19.1853 145.551 19.1873 145.523 19.1873V19.1873ZM145.523 17.2177C145.271 17.2177 145.049 17.0469 145.006 16.8061C144.959 16.5383 145.151 16.2842 145.437 16.2399L151.727 15.2551C152.012 15.2073 152.283 15.3915 152.33 15.6598C152.377 15.9277 152.185 16.1818 151.899 16.2261L145.609 17.2109C145.58 17.2158 145.551 17.2177 145.523 17.2177V17.2177Z"
            fill="#CCD6DD"
          />
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip1_11596:86089)">
            <path
              d="M139.162 228.344C134.516 225.662 135.877 221.636 131.546 219.136C129.311 217.845 130.423 215.483 131.414 213.767C132.269 212.286 134.72 209.339 136.584 210.415C140.912 212.914 143.681 209.784 148.329 212.467C152.682 214.98 154.008 220.831 151.592 225.016C149.175 229.202 143.515 230.857 139.162 228.344Z"
              fill="#FFCB4C"
            />
            <path
              d="M126.537 214.48C125.939 214.135 125.664 211.826 126.469 210.431C127.274 209.036 129.412 208.121 130.009 208.466C130.607 208.811 129.44 210.286 128.634 211.681C127.829 213.076 127.134 214.825 126.537 214.48Z"
              fill="#CCD6DD"
            />
            <path
              d="M142.34 224.122C142.785 224.003 143.048 223.547 142.929 223.102L141.925 219.353L145.673 218.349C146.118 218.23 146.382 217.774 146.262 217.328C146.143 216.883 145.687 216.62 145.242 216.739L141.022 217.87L133.382 213.459C132.983 213.229 132.474 213.366 132.244 213.764C132.014 214.163 132.15 214.672 132.549 214.903L140.189 219.313L141.319 223.533C141.439 223.978 141.895 224.242 142.34 224.122V224.122Z"
              fill="#FFCC4D"
            />
            <path
              d="M126.856 216.427C126.059 215.967 125.786 214.947 126.246 214.15L129.579 208.377C130.039 207.58 131.059 207.307 131.856 207.767L136.186 210.267L131.186 218.927L126.856 216.427Z"
              fill="#99AAB5"
            />
            <path
              d="M131.135 207.35C131.335 207.003 131.762 206.842 132.148 206.987C132.579 207.148 132.798 207.629 132.636 208.059L129.079 217.553C128.923 217.986 128.438 218.203 128.007 218.04C127.576 217.879 127.357 217.399 127.519 216.968L131.076 207.475C131.092 207.431 131.112 207.389 131.135 207.35V207.35ZM134.021 209.016C134.221 208.67 134.648 208.508 135.035 208.654C135.466 208.815 135.685 209.295 135.522 209.726L131.966 219.22C131.809 219.653 131.324 219.869 130.893 219.707C130.463 219.546 130.244 219.066 130.406 218.635L133.963 209.141C133.978 209.097 133.999 209.055 134.021 209.016V209.016Z"
              fill="#CCD6DD"
            />
          </g>
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip2_11596:86089)">
            <path
              d="M49.6966 128.789C53.724 131.114 52.5439 134.602 56.2973 136.77C58.235 137.888 57.2705 139.936 56.4121 141.423C55.6707 142.707 53.5466 145.261 51.9316 144.328C48.1801 142.162 45.7802 144.875 41.7522 142.549C37.98 140.371 36.8306 135.3 38.9243 131.674C41.0188 128.046 45.9245 126.611 49.6966 128.789Z"
              fill="#FFCB4C"
            />
            <path
              d="M60.6389 140.805C61.1568 141.104 61.3959 143.105 60.6978 144.314C59.9998 145.523 58.1473 146.316 57.6294 146.017C57.1115 145.718 58.1231 144.44 58.8214 143.23C59.5195 142.021 60.121 140.506 60.6389 140.805Z"
              fill="#CCD6DD"
            />
            <path
              d="M46.9432 132.448C46.5575 132.551 46.3292 132.947 46.4326 133.332L47.3031 136.581L44.0543 137.452C43.6686 137.555 43.4403 137.95 43.5437 138.336C43.6471 138.722 44.0424 138.95 44.4281 138.847L48.0853 137.867L54.7065 141.689C55.0524 141.889 55.4937 141.77 55.6931 141.425C55.8924 141.08 55.7746 140.638 55.4287 140.439L48.8076 136.616L47.8276 132.959C47.7243 132.573 47.329 132.345 46.9432 132.448V132.448Z"
              fill="#FFCC4D"
            />
            <path
              d="M60.3627 139.117C61.0532 139.516 61.2901 140.4 60.8914 141.091L58.0025 146.094C57.6039 146.785 56.7199 147.022 56.0294 146.623L52.2766 144.456L56.6099 136.951L60.3627 139.117Z"
              fill="#99AAB5"
            />
            <path
              d="M56.6545 146.985C56.4812 147.285 56.111 147.425 55.7759 147.299C55.4028 147.159 55.213 146.743 55.3535 146.369L58.4359 138.142C58.5711 137.766 58.9919 137.579 59.3653 137.719C59.7384 137.859 59.9283 138.275 59.7878 138.649L56.7054 146.876C56.6917 146.914 56.674 146.951 56.6545 146.985V146.985ZM54.1527 145.54C53.9793 145.84 53.6092 145.98 53.2741 145.854C52.901 145.715 52.7111 145.298 52.8516 144.925L55.9341 136.697C56.0698 136.322 56.4901 136.134 56.8635 136.275C57.2366 136.414 57.4264 136.831 57.2859 137.204L54.2035 145.432C54.1899 145.47 54.1722 145.506 54.1527 145.54V145.54Z"
              fill="#CCD6DD"
            />
          </g>
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip3_11596:86089)">
            <path
              d="M160.311 123.159C164.338 120.834 166.769 123.6 170.523 121.433C172.46 120.314 173.751 122.173 174.61 123.66C175.351 124.944 176.501 128.061 174.886 128.993C171.134 131.159 172.283 134.594 168.255 136.919C164.483 139.097 159.517 137.557 157.423 133.931C155.329 130.303 156.539 125.337 160.311 123.159Z"
              fill="#FFCB4C"
            />
            <path
              d="M176.188 119.69C176.706 119.391 178.558 120.185 179.256 121.394C179.954 122.603 179.715 124.604 179.197 124.903C178.679 125.202 178.078 123.687 177.38 122.477C176.682 121.268 175.67 119.989 176.188 119.69Z"
              fill="#CCD6DD"
            />
            <path
              d="M162.103 127.373C161.999 127.759 162.228 128.154 162.613 128.258L165.862 129.128L164.992 132.377C164.888 132.763 165.117 133.158 165.502 133.261C165.888 133.365 166.283 133.136 166.387 132.751L167.367 129.093L173.988 125.271C174.334 125.071 174.452 124.629 174.252 124.284C174.053 123.939 173.611 123.82 173.266 124.02L166.644 127.842L162.987 126.863C162.601 126.759 162.206 126.987 162.103 127.373V127.373Z"
              fill="#FFCC4D"
            />
            <path
              d="M174.589 119.086C175.279 118.687 176.163 118.924 176.562 119.615L179.451 124.618C179.849 125.309 179.612 126.193 178.922 126.592L175.169 128.758L170.836 121.253L174.589 119.086Z"
              fill="#99AAB5"
            />
            <path
              d="M179.548 126.231C179.721 126.531 179.657 126.922 179.38 127.149C179.073 127.403 178.617 127.359 178.364 127.05L172.78 120.267C172.522 119.962 172.571 119.504 172.879 119.251C173.186 118.998 173.642 119.041 173.895 119.35L179.479 126.133C179.505 126.164 179.528 126.197 179.548 126.231V126.231ZM177.046 127.676C177.219 127.976 177.155 128.366 176.879 128.594C176.571 128.847 176.116 128.803 175.862 128.495L170.278 121.711C170.021 121.406 170.069 120.948 170.377 120.695C170.684 120.442 171.14 120.486 171.393 120.794L176.977 127.578C177.004 127.608 177.026 127.642 177.046 127.676V127.676Z"
              fill="#CCD6DD"
            />
          </g>
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip4_11596:86089)">
            <path
              d="M70.2282 23.6807C65.9847 26.3403 63.2604 23.4582 59.3055 25.937C57.2639 27.2166 55.8019 25.2662 54.82 23.6997C53.972 22.3467 52.6131 19.0376 54.3147 17.9711C58.2676 15.4936 56.8968 11.842 61.141 9.18194C65.1156 6.69081 70.5206 8.15438 72.9155 11.9754C75.3112 15.7978 74.2028 21.1895 70.2282 23.6807Z"
              fill="#FFCB4C"
            />
            <path
              d="M53.2767 28.0332C52.731 28.3753 50.7063 27.5936 49.9079 26.3197C49.1095 25.0458 49.2886 22.8829 49.8343 22.5409C50.38 22.1989 51.0862 23.806 51.885 25.0805C52.6834 26.3544 53.8224 27.6912 53.2767 28.0332Z"
              fill="#CCD6DD"
            />
            <path
              d="M68.1352 19.2153C68.2314 18.7961 67.9703 18.3796 67.5512 18.2834L64.0208 17.4734L64.8308 13.943C64.927 13.5239 64.6659 13.1073 64.2467 13.0112C63.8275 12.915 63.411 13.176 63.3148 13.5952L62.403 17.5695L55.4265 21.9421C55.062 22.1705 54.9525 22.6504 55.1805 23.0141C55.4085 23.3779 55.8881 23.4885 56.2526 23.2601L63.2291 18.8875L67.2033 19.7994C67.6225 19.8955 68.039 19.6345 68.1352 19.2153V19.2153Z"
              fill="#FFCC4D"
            />
            <path
              d="M55.0215 28.6213C54.2939 29.0774 53.3333 28.857 52.8773 28.1294L49.5729 22.8571C49.1169 22.1296 49.3373 21.169 50.0648 20.7129L54.019 18.2346L58.9757 26.143L55.0215 28.6213Z"
              fill="#99AAB5"
            />
            <path
              d="M49.4055 21.1253C49.2073 20.809 49.2608 20.3863 49.5496 20.1309C49.8706 19.8462 50.3626 19.8756 50.6471 20.1976L56.9218 27.2799C57.2111 27.5979 57.1771 28.0929 56.8551 28.3773C56.5341 28.662 56.0421 28.6326 55.7576 28.3106L49.4829 21.2283C49.4536 21.1962 49.4278 21.1609 49.4055 21.1253V21.1253ZM52.0417 19.4731C51.8434 19.1568 51.897 18.734 52.1858 18.4787C52.5067 18.194 52.9988 18.2234 53.2832 18.5454L59.5579 25.6277C59.8465 25.9461 59.8132 26.4407 59.4912 26.7251C59.1703 27.0098 58.6782 26.9804 58.3938 26.6584L52.1191 19.5761C52.0897 19.544 52.064 19.5087 52.0417 19.4731V19.4731Z"
              fill="#CCD6DD"
            />
          </g>
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip5_11596:86089)">
            <path
              d="M133.907 59.7666C138.1 57.3715 140.611 60.2558 144.52 58.0236C146.537 56.8712 147.869 58.808 148.753 60.356C149.517 61.693 150.695 64.935 149.014 65.8954C145.108 68.1265 146.284 71.6988 142.09 74.0943C138.163 76.3377 133.013 74.7144 130.856 70.9384C128.698 67.1611 129.979 62.01 133.907 59.7666Z"
              fill="#FFCB4C"
            />
            <path
              d="M150.411 56.2415C150.95 55.9335 152.87 56.7665 153.589 58.0254C154.308 59.2842 154.05 61.3611 153.511 61.6691C152.972 61.9771 152.355 60.4008 151.636 59.1413C150.916 57.8824 149.872 56.5495 150.411 56.2415Z"
              fill="#CCD6DD"
            />
            <path
              d="M135.747 64.1515C135.638 64.5516 135.873 64.9632 136.273 65.0724L139.643 65.9921L138.723 69.3616C138.614 69.7617 138.849 70.1733 139.249 70.2825C139.649 70.3917 140.061 70.1566 140.17 69.7565L141.205 65.9634L148.1 62.0256C148.46 61.8199 148.584 61.3619 148.379 61.0024C148.173 60.6429 147.716 60.5174 147.356 60.7231L140.461 64.6609L136.668 63.6256C136.268 63.5164 135.857 63.7514 135.747 64.1515V64.1515Z"
              fill="#FFCC4D"
            />
            <path
              d="M148.753 55.6062C149.472 55.1955 150.389 55.4457 150.8 56.1647L153.775 61.3748C154.186 62.0937 153.936 63.0106 153.217 63.4212L149.309 65.6531L144.846 57.838L148.753 55.6062Z"
              fill="#99AAB5"
            />
            <path
              d="M153.868 63.05C154.047 63.3626 153.979 63.7678 153.69 64.0024C153.37 64.2641 152.897 64.2163 152.636 63.8949L146.869 56.8237C146.603 56.5059 146.656 56.0304 146.977 55.769C147.297 55.5074 147.77 55.5551 148.032 55.8766L153.798 62.9477C153.825 62.9798 153.848 63.0148 153.868 63.05V63.05ZM151.263 64.5379C151.442 64.8505 151.374 65.2557 151.085 65.4903C150.765 65.752 150.292 65.7042 150.031 65.3828L144.264 58.3117C143.999 57.9934 144.051 57.5183 144.372 57.2569C144.692 56.9953 145.165 57.043 145.427 57.3645L151.193 64.4356C151.22 64.4677 151.243 64.5027 151.263 64.5379V64.5379Z"
              fill="#CCD6DD"
            />
          </g>
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip6_11596:86089)">
            <path
              d="M20.3098 53.4622C22.7128 55.8652 21.22 58.1043 23.4595 60.3439C24.6157 61.5 23.5476 62.7629 22.6605 63.65C21.8943 64.4162 19.9119 65.8172 18.9483 64.8536C16.7099 62.6152 14.5028 64.0758 12.0995 61.6725C9.84872 59.4217 9.99651 55.6248 12.1603 53.461C14.3248 51.2965 18.059 51.2114 20.3098 53.4622Z"
              fill="#FFCB4C"
            />
            <path
              d="M25.7609 64.0134C26.0699 64.3224 25.8602 65.7801 25.1388 66.5015C24.4174 67.2229 22.9597 67.4326 22.6507 67.1236C22.3417 66.8146 23.2974 66.1037 24.0192 65.3819C24.7406 64.6605 25.4519 63.7044 25.7609 64.0134Z"
              fill="#CCD6DD"
            />
            <path
              d="M17.674 55.5243C17.3822 55.5243 17.1463 55.7602 17.1463 56.052L17.1463 58.5099L14.6885 58.5099C14.3966 58.5099 14.1608 58.7458 14.1608 59.0376C14.1608 59.3294 14.3966 59.5653 14.6885 59.5653L17.4553 59.5653L21.406 63.5159C21.6124 63.7223 21.9464 63.7219 22.1524 63.5159C22.3584 63.3099 22.3588 62.9759 22.1524 62.7696L18.2017 58.8189L18.2017 56.052C18.2017 55.7602 17.9659 55.5243 17.674 55.5243V55.5243Z"
              fill="#FFCC4D"
            />
            <path
              d="M25.885 62.7701C26.297 63.1821 26.297 63.8509 25.885 64.2629L22.8994 67.2484C22.4874 67.6605 21.8187 67.6605 21.4067 67.2484L19.1675 65.0093L23.6458 60.5309L25.885 62.7701Z"
              fill="#99AAB5"
            />
            <path
              d="M21.7797 67.6221C21.6006 67.8012 21.3128 67.83 21.1001 67.6777C20.8631 67.5086 20.8079 67.1787 20.9777 66.9418L24.7097 61.717C24.8761 61.4774 25.2086 61.4248 25.4456 61.5946C25.6826 61.7637 25.7378 62.0936 25.568 62.3306L21.8361 67.5553C21.8193 67.5795 21.7999 67.6019 21.7797 67.6221V67.6221ZM20.2869 66.1293C20.1078 66.3084 19.8201 66.3372 19.6073 66.1849C19.3704 66.0159 19.3151 65.686 19.4849 65.449L23.2169 60.2242C23.3837 59.985 23.7158 59.932 23.9528 60.1018C24.1898 60.2709 24.245 60.6008 24.0752 60.8378L20.3433 66.0625C20.3265 66.0868 20.3071 66.1092 20.2869 66.1293V66.1293Z"
              fill="#CCD6DD"
            />
          </g>
        </g>
        <g clipPath="url(#clip7_11596:86089)">
          <path
            d="M73.1091 223.509C70.8325 221.232 72.2468 219.111 70.1251 216.989C69.0298 215.894 70.0416 214.697 70.882 213.857C71.6079 213.131 73.486 211.804 74.3988 212.717C76.5194 214.837 78.6104 213.454 80.8872 215.731C83.0195 217.863 82.8795 221.46 80.8296 223.51C78.779 225.56 75.2413 225.641 73.1091 223.509Z"
            fill="#FFCB4C"
          />
          <path
            d="M67.9446 213.513C67.6519 213.22 67.8506 211.839 68.534 211.156C69.2174 210.472 70.5984 210.273 70.8911 210.566C71.1839 210.859 70.2784 211.532 69.5947 212.216C68.9112 212.9 68.2374 213.805 67.9446 213.513Z"
            fill="#CCD6DD"
          />
          <path
            d="M75.6059 221.555C75.8824 221.555 76.1058 221.332 76.1058 221.055L76.1058 218.727L78.4343 218.727C78.7108 218.727 78.9342 218.503 78.9342 218.227C78.9342 217.95 78.7108 217.727 78.4343 217.727L75.8131 217.727L72.0704 213.984C71.8748 213.789 71.5584 213.789 71.3632 213.984C71.1681 214.179 71.1677 214.496 71.3632 214.691L75.106 218.434L75.106 221.055C75.106 221.332 75.3294 221.555 75.6059 221.555V221.555Z"
            fill="#FFCC4D"
          />
          <path
            d="M67.827 214.691C67.4366 214.3 67.4366 213.667 67.827 213.276L70.6554 210.448C71.0457 210.058 71.6793 210.058 72.0696 210.448L74.1909 212.569L69.9483 216.812L67.827 214.691Z"
            fill="#99AAB5"
          />
          <path
            d="M71.7164 210.094C71.8861 209.924 72.1587 209.897 72.3602 210.041C72.5847 210.202 72.6371 210.514 72.4762 210.739L68.9407 215.688C68.783 215.915 68.468 215.965 68.2434 215.804C68.0189 215.644 67.9666 215.332 68.1275 215.107L71.663 210.157C71.6789 210.134 71.6973 210.113 71.7164 210.094V210.094ZM73.1306 211.508C73.3003 211.339 73.5729 211.311 73.7744 211.456C73.9989 211.616 74.0513 211.928 73.8904 212.153L70.3549 217.103C70.1968 217.329 69.8822 217.379 69.6577 217.219C69.4332 217.058 69.3808 216.746 69.5417 216.521L73.0772 211.572C73.0931 211.549 73.1115 211.527 73.1306 211.508V211.508Z"
            fill="#CCD6DD"
          />
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip8_11596:86089)">
            <path
              d="M211.217 60.1347C207.19 62.4599 204.759 59.6937 201.005 61.8607C199.068 62.9794 197.777 61.1203 196.918 59.6336C196.177 58.3495 195.027 55.233 196.642 54.3006C200.394 52.1347 199.245 48.7 203.273 46.3745C207.045 44.1966 212.011 45.7367 214.105 49.3632C216.199 52.9908 214.989 57.9568 211.217 60.1347Z"
              fill="#FFCB4C"
            />
            <path
              d="M195.34 63.6033C194.822 63.9023 192.97 63.1088 192.272 61.8998C191.574 60.6908 191.813 58.6897 192.331 58.3907C192.849 58.0917 193.45 59.6068 194.148 60.8165C194.846 62.0255 195.858 63.3043 195.34 63.6033Z"
              fill="#CCD6DD"
            />
            <path
              d="M209.425 55.9206C209.529 55.5348 209.3 55.1395 208.915 55.0362L205.666 54.1657L206.536 50.9169C206.64 50.5311 206.412 50.1358 206.026 50.0325C205.64 49.9291 205.245 50.1573 205.141 50.5431L204.161 54.2003L197.54 58.023C197.194 58.2227 197.077 58.6644 197.276 59.0096C197.475 59.3549 197.917 59.4737 198.262 59.274L204.884 55.4512L208.541 56.4312C208.927 56.5346 209.322 56.3063 209.425 55.9206V55.9206Z"
              fill="#FFCC4D"
            />
            <path
              d="M196.939 64.2076C196.249 64.6063 195.365 64.3694 194.966 63.6789L192.077 58.6752C191.679 57.9847 191.916 57.1007 192.606 56.7021L196.359 54.5354L200.692 62.041L196.939 64.2076Z"
              fill="#99AAB5"
            />
            <path
              d="M191.98 57.0624C191.807 56.7622 191.871 56.3717 192.148 56.1445C192.455 55.8911 192.911 55.9349 193.164 56.2433L198.748 63.0266C199.006 63.3315 198.958 63.7897 198.649 64.0428C198.342 64.2961 197.886 64.2523 197.633 63.9439L192.049 57.1606C192.023 57.1298 192 57.0962 191.98 57.0624V57.0624ZM194.482 55.618C194.309 55.3178 194.373 54.9273 194.649 54.7C194.957 54.4467 195.412 54.4905 195.666 54.7989L201.25 61.5822C201.507 61.8874 201.459 62.3452 201.151 62.5983C200.844 62.8517 200.388 62.8078 200.135 62.4995L194.551 55.7161C194.524 55.6853 194.502 55.6518 194.482 55.618V55.618Z"
              fill="#CCD6DD"
            />
          </g>
        </g>
        <g clipPath="url(#clip9_11596:86089)">
          <path
            d="M70.7606 71.5786C72.2771 73.5712 70.9653 75.0582 72.3786 76.9153C73.1083 77.874 72.2033 78.6903 71.4677 79.2501C70.8324 79.7337 69.2461 80.5602 68.638 79.7612C67.2254 77.905 65.4692 78.7529 63.9525 76.7599C62.5321 74.8936 63.0161 72.1359 64.8104 70.7704C66.6053 69.4044 69.3402 69.7122 70.7606 71.5786Z"
            fill="#FFD983"
          />
          <path
            d="M73.6955 79.8229C73.8906 80.0791 73.593 81.1227 72.9948 81.5779C72.3966 82.0332 71.3115 82.0419 71.1165 81.7856C70.9215 81.5294 71.6898 81.105 72.2883 80.6495C72.8865 80.1943 73.5005 79.5666 73.6955 79.8229Z"
            fill="#CCD6DD"
          />
          <path
            d="M68.6322 72.8233C68.4191 72.7944 68.2235 72.9433 68.1946 73.1564L67.9511 74.951L66.1565 74.7075C65.9434 74.6786 65.7478 74.8274 65.7189 75.0405C65.69 75.2536 65.8388 75.4491 66.0519 75.4781L68.0722 75.7522L70.5653 79.0281C70.6956 79.1993 70.9395 79.2321 71.1103 79.1021C71.2811 78.9721 71.3145 78.7282 71.1843 78.5571L68.6911 75.2811L68.9652 73.2609C68.9941 73.0478 68.8453 72.8522 68.6322 72.8233V72.8233Z"
            fill="#FFCC4D"
          />
          <path
            d="M73.9094 78.9271C74.1694 79.2687 74.1031 79.757 73.7615 80.017L71.2858 81.9011C70.9441 82.1612 70.4558 82.0949 70.1958 81.7533L68.7827 79.8965L72.4963 77.0703L73.9094 78.9271Z"
            fill="#99AAB5"
          />
          <path
            d="M70.4311 82.0631C70.2825 82.1762 70.0696 82.1687 69.9294 82.0364C69.7731 81.8895 69.7655 81.6431 69.9129 81.4869L73.1554 78.0418C73.3007 77.8834 73.5487 77.8779 73.7049 78.0253C73.8612 78.1723 73.8688 78.4186 73.7214 78.5748L70.4789 82.0199C70.4642 82.036 70.4478 82.0504 70.4311 82.0631V82.0631ZM69.489 80.8253C69.3405 80.9383 69.1275 80.9308 68.9873 80.7986C68.831 80.6516 68.8234 80.4053 68.9708 80.2491L72.2134 76.804C72.3589 76.6458 72.6066 76.64 72.7628 76.7875C72.9191 76.9344 72.9268 77.1808 72.7793 77.337L69.5368 80.7821C69.5221 80.7981 69.5057 80.8126 69.489 80.8253V80.8253Z"
            fill="#CCD6DD"
          />
        </g>
        <g clipPath="url(#clip10_11596:86089)">
          <path
            d="M12.3048 165.887C14.1635 164.814 15.2855 166.09 17.0179 165.09C17.9122 164.574 18.508 165.432 18.9042 166.118C19.2464 166.711 19.777 168.149 19.0316 168.579C17.3002 169.579 17.8305 171.164 15.9714 172.238C14.2304 173.243 11.9383 172.532 10.9719 170.858C10.0053 169.184 10.5638 166.892 12.3048 165.887Z"
            fill="#FFD983"
          />
          <path
            d="M19.633 164.286C19.872 164.148 20.727 164.514 21.0492 165.072C21.3713 165.63 21.261 166.553 21.022 166.691C20.7829 166.829 20.5055 166.13 20.1831 165.572C19.861 165.014 19.3939 164.424 19.633 164.286Z"
            fill="#CCD6DD"
          />
          <path
            d="M13.1323 167.831C13.0846 168.009 13.1899 168.192 13.368 168.239L14.8674 168.641L14.4656 170.141C14.4179 170.319 14.5233 170.501 14.7013 170.549C14.8793 170.597 15.0618 170.491 15.1095 170.313L15.5618 168.625L18.6177 166.861C18.7773 166.769 18.8317 166.565 18.7397 166.406C18.6477 166.246 18.444 166.191 18.2844 166.284L15.2284 168.048L13.5405 167.596C13.3625 167.548 13.18 167.653 13.1323 167.831V167.831Z"
            fill="#FFCC4D"
          />
          <path
            d="M18.8946 164.007C19.2133 163.823 19.6213 163.932 19.8053 164.251L21.1387 166.56C21.3227 166.879 21.2133 167.287 20.8946 167.471L19.1626 168.471L17.1626 165.007L18.8946 164.007Z"
            fill="#99AAB5"
          />
          <path
            d="M21.1834 167.304C21.2634 167.443 21.2338 167.623 21.1062 167.728C20.9643 167.845 20.754 167.825 20.6372 167.682L18.0599 164.551C17.9409 164.411 17.9632 164.199 18.1055 164.082C18.2474 163.966 18.4576 163.986 18.5745 164.128L21.1518 167.259C21.1639 167.273 21.1744 167.289 21.1834 167.304V167.304ZM20.0287 167.971C20.1087 168.109 20.0791 168.29 19.9515 168.394C19.8096 168.511 19.5993 168.491 19.4825 168.349L16.9052 165.218C16.7865 165.077 16.8085 164.866 16.9508 164.749C17.0927 164.632 17.3029 164.652 17.4198 164.795L19.9971 167.926C20.0092 167.94 20.0197 167.955 20.0287 167.971V167.971Z"
            fill="#CCD6DD"
          />
        </g>
        <g clipPath="url(#clip11_11596:86089)">
          <path
            d="M214.722 183.833C217.125 186.236 215.632 188.475 217.871 190.714C219.028 191.87 217.959 193.133 217.072 194.02C216.306 194.787 214.324 196.188 213.36 195.224C211.122 192.986 208.915 194.446 206.511 192.043C204.261 189.792 204.408 185.995 206.572 183.831C208.737 181.667 212.471 181.582 214.722 183.833Z"
            fill="#FFD983"
          />
          <path
            d="M220.173 194.384C220.482 194.693 220.272 196.15 219.551 196.872C218.829 197.593 217.372 197.803 217.063 197.494C216.754 197.185 217.709 196.474 218.431 195.752C219.152 195.031 219.864 194.075 220.173 194.384Z"
            fill="#CCD6DD"
          />
          <path
            d="M212.086 185.895C211.794 185.895 211.558 186.131 211.558 186.422L211.558 188.88L209.1 188.88C208.808 188.88 208.573 189.116 208.573 189.408C208.573 189.7 208.808 189.936 209.1 189.936L211.867 189.936L215.818 193.886C216.024 194.093 216.358 194.092 216.564 193.886C216.77 193.68 216.771 193.346 216.564 193.14L212.614 189.189L212.614 186.422C212.614 186.131 212.378 185.895 212.086 185.895V185.895Z"
            fill="#FFCC4D"
          />
          <path
            d="M220.297 193.14C220.709 193.552 220.709 194.221 220.297 194.633L217.311 197.619C216.899 198.031 216.231 198.031 215.819 197.619L213.579 195.38L218.058 190.901L220.297 193.14Z"
            fill="#99AAB5"
          />
          <path
            d="M216.192 197.992C216.012 198.172 215.725 198.2 215.512 198.048C215.275 197.879 215.22 197.549 215.39 197.312L219.122 192.087C219.288 191.848 219.62 191.795 219.857 191.965C220.094 192.134 220.15 192.464 219.98 192.701L216.248 197.926C216.231 197.95 216.212 197.972 216.192 197.992V197.992ZM214.699 196.5C214.52 196.679 214.232 196.708 214.019 196.555C213.782 196.386 213.727 196.056 213.897 195.819L217.629 190.595C217.796 190.355 218.128 190.302 218.365 190.472C218.602 190.641 218.657 190.971 218.487 191.208L214.755 196.433C214.738 196.457 214.719 196.48 214.699 196.5V196.5Z"
            fill="#CCD6DD"
          />
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip12_11596:86089)">
            <path
              d="M109.112 175.019C110.364 172.85 112.243 173.485 113.409 171.464C114.012 170.421 115.114 170.94 115.915 171.403C116.606 171.802 117.982 172.946 117.479 173.815C116.313 175.835 117.774 177.127 116.522 179.296C115.349 181.328 112.618 181.946 110.666 180.819C108.712 179.691 107.94 177.05 109.112 175.019Z"
              fill="#FFCB4C"
            />
            <path
              d="M115.582 169.127C115.743 168.848 116.821 168.719 117.472 169.095C118.123 169.471 118.55 170.468 118.389 170.747C118.228 171.026 117.54 170.481 116.888 170.105C116.237 169.729 115.421 169.405 115.582 169.127Z"
              fill="#CCD6DD"
            />
            <path
              d="M111.082 176.501C111.138 176.708 111.351 176.831 111.559 176.776L113.308 176.307L113.777 178.056C113.832 178.264 114.045 178.387 114.253 178.331C114.461 178.276 114.584 178.063 114.528 177.855L114 175.886L116.059 172.321C116.166 172.134 116.102 171.897 115.916 171.789C115.73 171.682 115.493 171.745 115.385 171.932L113.327 175.497L111.357 176.025C111.15 176.08 111.027 176.293 111.082 176.501V176.501Z"
              fill="#FFCC4D"
            />
            <path
              d="M114.674 169.275C114.888 168.903 115.364 168.776 115.736 168.99L118.43 170.546C118.802 170.761 118.93 171.237 118.715 171.608L117.548 173.629L113.507 171.296L114.674 169.275Z"
              fill="#99AAB5"
            />
            <path
              d="M118.91 171.272C119.072 171.365 119.147 171.565 119.079 171.745C119.004 171.946 118.78 172.048 118.579 171.972L114.148 170.313C113.946 170.24 113.845 170.013 113.921 169.812C113.996 169.611 114.22 169.509 114.421 169.585L118.852 171.245C118.872 171.252 118.892 171.261 118.91 171.272V171.272ZM118.132 172.619C118.294 172.712 118.369 172.912 118.301 173.092C118.226 173.293 118.002 173.395 117.801 173.32L113.37 171.66C113.168 171.587 113.067 171.36 113.143 171.159C113.218 170.959 113.442 170.856 113.643 170.932L118.074 172.592C118.094 172.599 118.114 172.609 118.132 172.619V172.619Z"
              fill="#CCD6DD"
            />
          </g>
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip13_11596:86089)">
            <path
              d="M3.68639 102.692C5.83272 102.692 6.16605 104.358 8.16639 104.358C9.19905 104.358 9.28605 105.399 9.28605 106.192C9.28605 106.876 9.02639 108.387 8.16572 108.387C6.16639 108.387 5.83305 110.025 3.68639 110.025C1.67605 110.025 0.0463866 108.263 0.0463866 106.331C0.0463865 104.397 1.67605 102.692 3.68639 102.692Z"
              fill="#FFCB4C"
            />
            <path
              d="M10.8333 104.97C11.1093 104.97 11.6666 105.714 11.6666 106.359C11.6666 107.003 11.1093 107.748 10.8333 107.748C10.5573 107.748 10.6666 107.003 10.6666 106.359C10.6666 105.714 10.5573 104.97 10.8333 104.97Z"
              fill="#CCD6DD"
            />
            <path
              d="M3.43076 104.79C3.30042 104.92 3.30042 105.131 3.43076 105.261L4.52842 106.359L3.43076 107.456C3.30042 107.587 3.30042 107.797 3.43076 107.928C3.56109 108.058 3.77176 108.058 3.90209 107.928L5.13776 106.692L8.66643 106.692C8.85076 106.692 8.99976 106.543 8.99976 106.359C8.99976 106.175 8.85076 106.025 8.66643 106.025L5.13776 106.025L3.90209 104.79C3.77176 104.659 3.56109 104.659 3.43076 104.79V104.79Z"
              fill="#FFCC4D"
            />
            <path
              d="M10.3335 104.359C10.7015 104.359 11.0002 104.657 11.0002 105.025L11.0002 107.692C11.0002 108.06 10.7015 108.359 10.3335 108.359L8.3335 108.359L8.3335 104.359L10.3335 104.359Z"
              fill="#99AAB5"
            />
            <path
              d="M10.6669 108.359C10.6669 108.519 10.5512 108.66 10.3882 108.687C10.2069 108.718 10.0349 108.595 10.0049 108.413L9.3382 104.413C9.30553 104.232 9.43053 104.06 9.6122 104.03C9.79353 104 9.96553 104.122 9.99553 104.304L10.6622 108.304C10.6655 108.322 10.6669 108.341 10.6669 108.359V108.359ZM9.33353 108.359C9.33353 108.519 9.21786 108.66 9.05486 108.687C8.87353 108.718 8.70153 108.595 8.67153 108.413L8.00486 104.413C7.97253 104.232 8.0972 104.06 8.27886 104.03C8.4602 104 8.6322 104.122 8.6622 104.304L9.32886 108.304C9.3322 108.322 9.33353 108.341 9.33353 108.359V108.359Z"
              fill="#CCD6DD"
            />
          </g>
        </g>
        <g clipPath="url(#clip14_11596:86089)">
          <path
            d="M245.247 114.67C242.385 114.67 241.941 112.447 239.274 112.447C237.897 112.447 237.781 111.059 237.781 110.003C237.781 109.091 238.127 107.076 239.275 107.076C241.941 107.076 242.385 104.892 245.247 104.892C247.928 104.892 250.101 107.241 250.101 109.818C250.101 112.395 247.928 114.67 245.247 114.67Z"
            fill="#FFD983"
          />
          <path
            d="M235.718 111.633C235.35 111.633 234.607 110.64 234.607 109.781C234.607 108.922 235.35 107.929 235.718 107.929C236.086 107.929 235.941 108.921 235.941 109.781C235.941 110.64 236.086 111.633 235.718 111.633Z"
            fill="#CCD6DD"
          />
          <path
            d="M245.588 111.872C245.762 111.699 245.762 111.418 245.588 111.244L244.125 109.78L245.588 108.317C245.762 108.143 245.762 107.862 245.588 107.688C245.415 107.515 245.134 107.515 244.96 107.688L243.312 109.336L238.608 109.336C238.362 109.336 238.163 109.535 238.163 109.78C238.163 110.026 238.362 110.225 238.608 110.225L243.312 110.225L244.96 111.872C245.134 112.046 245.415 112.046 245.588 111.872V111.872Z"
            fill="#FFCC4D"
          />
          <path
            d="M236.385 112.447C235.894 112.447 235.496 112.049 235.496 111.558L235.496 108.002C235.496 107.512 235.894 107.114 236.385 107.114L239.052 107.114L239.052 112.447L236.385 112.447Z"
            fill="#99AAB5"
          />
          <path
            d="M235.94 107.113C235.94 106.9 236.094 106.712 236.312 106.676C236.554 106.635 236.783 106.799 236.823 107.041L237.712 112.374C237.755 112.616 237.589 112.845 237.346 112.885C237.105 112.926 236.875 112.762 236.835 112.52L235.946 107.187C235.942 107.162 235.94 107.137 235.94 107.113V107.113ZM237.718 107.113C237.718 106.9 237.872 106.712 238.09 106.676C238.331 106.635 238.561 106.799 238.601 107.041L239.49 112.374C239.533 112.616 239.366 112.845 239.124 112.885C238.882 112.926 238.653 112.762 238.613 112.52L237.724 107.187C237.72 107.162 237.718 107.137 237.718 107.113V107.113Z"
            fill="#CCD6DD"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_11596:86089"
          x={103.791}
          y={90.3677}
          width={42.9456}
          height={52.0298}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11596:86089"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11596:86089"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_11596:86089"
          x={114.645}
          y={140.873}
          width={21.238}
          height={12.8672}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11596:86089"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11596:86089"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_11596:86089"
          x={111.731}
          y={129.856}
          width={27.0613}
          height={20.7075}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11596:86089"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11596:86089"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_11596:86089">
          <rect
            width={18.8694}
            height={17.7258}
            fill="white"
            transform="translate(139.233 3.43091)"
          />
        </clipPath>
        <clipPath id="clip1_11596:86089">
          <rect
            width={30}
            height={30}
            fill="white"
            transform="translate(159.228 212.024) rotate(120)"
          />
        </clipPath>
        <clipPath id="clip2_11596:86089">
          <rect
            width={26}
            height={26}
            fill="white"
            transform="translate(32.3066 142.934) rotate(-60)"
          />
        </clipPath>
        <clipPath id="clip3_11596:86089">
          <rect
            width={26}
            height={26}
            fill="white"
            transform="translate(163.865 145.292) rotate(-120)"
          />
        </clipPath>
        <clipPath id="clip4_11596:86089">
          <rect
            width={28}
            height={28}
            fill="white"
            transform="translate(65.5386) rotate(57.9222)"
          />
        </clipPath>
        <clipPath id="clip5_11596:86089">
          <rect
            width={27}
            height={27}
            fill="white"
            transform="translate(137.491 82.7673) rotate(-119.734)"
          />
        </clipPath>
        <clipPath id="clip6_11596:86089">
          <rect
            width={19}
            height={19}
            fill="white"
            transform="translate(5.35938 60.1575) rotate(-45)"
          />
        </clipPath>
        <clipPath id="clip7_11596:86089">
          <rect
            width={18}
            height={18}
            fill="white"
            transform="translate(87.2725 217.166) rotate(135)"
          />
        </clipPath>
        <clipPath id="clip8_11596:86089">
          <rect
            width={26}
            height={26}
            fill="white"
            transform="translate(207.663 38.0022) rotate(60)"
          />
        </clipPath>
        <clipPath id="clip9_11596:86089">
          <rect
            width={14}
            height={14}
            fill="white"
            transform="translate(59.1814 74.9861) rotate(-37.273)"
          />
        </clipPath>
        <clipPath id="clip10_11596:86089">
          <rect
            width={12}
            height={12}
            fill="white"
            transform="translate(13.9456 176.102) rotate(-120)"
          />
        </clipPath>
        <clipPath id="clip11_11596:86089">
          <rect
            width={19}
            height={19}
            fill="white"
            transform="translate(199.771 190.528) rotate(-45)"
          />
        </clipPath>
        <clipPath id="clip12_11596:86089">
          <rect
            width={14}
            height={14}
            fill="white"
            transform="translate(116.729 184.382) rotate(-150)"
          />
        </clipPath>
        <clipPath id="clip13_11596:86089">
          <rect
            width={12}
            height={12}
            fill="white"
            transform="translate(0 112.359) rotate(-90)"
          />
        </clipPath>
        <clipPath id="clip14_11596:86089">
          <rect
            width={16}
            height={16}
            fill="white"
            transform="translate(250.163 101.781) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
