import './bookmarks.scss';

import React from 'react';
import { observer } from 'mobx-react-lite'
import { Button } from '../input';
import classNames from 'classnames';
import { ObjectLiteral } from '../../core';
import { BookmarkBrowserState } from './bookmarkBrowserState';
import { SquarePlusSVGIcon } from '../svg';

type Props = {
  className?: string | ObjectLiteral,
  model: BookmarkBrowserState
}


export const BookmarkBrowserHeader = observer((props: Props) => {
  const { model } = props;

  const className = classNames('bookmark-browser-header', props.className, {});

  return (
    <header className={className}>
      <div className="flex-left">
        <h1>Bookmarks</h1>
      </div>

      <div className="flex-right">
        <Button
          className="secondary large"
          icon={<SquarePlusSVGIcon />}
          label="New List"
          onClick={(evt: any) => model.openCreateListWindow()}
        />
      </div>
    </header>
  );
});