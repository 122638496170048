import { 
  camelCase, 
  capitalCase, 
  constantCase, 
  dotCase, 
  headerCase, 
  noCase, 
  paramCase, 
  pascalCase, 
  pathCase, 
  sentenceCase 
} from 'change-case';

import snakeCase from 'lodash/snakeCase';
import { StringCase } from './stringSchema';
import { Maybe } from './types';

export function isNonEmptyString(val: any): val is string {
  return typeof val === 'string' && val.length > 0 && val.trim() !== "";
}

/**
 * Gets a counting string that is gramatically correct (so that you don't have things like "1 items").
 * You need to provide the count, the singular and the plural form of the word you want to pluralize.
 * Optionally, you can provide a string for when the count is 0. That string won't be concatenated with the count.
 * By default, when count is 0, the function returns a string in the form of "no [plural]". You can also pass false
 * as the last parameter to return "0 [plural]"
 */
export function pluralize(
  count: number,
  singular: string,
  plural: string,
  empty: string | boolean = true): string {

  switch (count) {
    case 1:
      return count + ' ' + singular;

    case 0: {
      if (empty === false)
        return '0 ' + plural;
      else if (typeof empty === 'string')
        return empty;

      return 'no ' + plural;
    }

    default:
      return count + ' ' + plural;
  }
}
/**
 * Takes ratio values where [0,1] maps to [0%,100%] and returns a string percentage value.
 */
export function percentage(val: any, digits: number = 0) {
  if (Number.isFinite(val))
    return (val * 100).toFixed(digits) + '%';
  else if (typeof val === 'string' && val)
    return val;
  return (0).toFixed(digits) + '%';
}

// split and trim camel case strings into space separated words
export function camelPad(str: string) {
  return str
    // Look for long acronyms and filter out the last letter
    .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
    // Look for lower-case letters followed by upper-case letters
    .replace(/([a-z\d])([A-Z])/g, '$1 $2')
    // Look for lower-case letters followed by numbers
    .replace(/([a-zA-Z])(\d)/g, '$1 $2')
    .replace(/^./, function (str) { return str.toUpperCase(); })
    // Remove any white space left around the word
    .trim();
}

export type StringChangeCaseOptions = {

}

export function changeCase(val: null, stringCase?: StringCase): null;
// eslint-disable-next-line  no-redeclare
export function changeCase(val: undefined, stringCase?: StringCase): undefined;
// eslint-disable-next-line  no-redeclare
export function changeCase(val: string, stringCase?: StringCase): string;
// eslint-disable-next-line  no-redeclare
export function changeCase(val: Maybe<string>, stringCase: StringCase = StringCase.Unchanged): Maybe<string> {
  const opts = {};
  if (!val || typeof val !== 'string')
    return val;

  switch (stringCase) {
    case StringCase.Unchanged:
    case StringCase.Auto:
    case StringCase.Default:
      return val;

    case StringCase.LowerCase:
      return val.toLowerCase();
    case StringCase.LocaleLowerCase:
      return val.toLocaleLowerCase();

    case StringCase.UpperCase:
      return val.toUpperCase();
    case StringCase.LocaleUpperCase:
      return val.toLocaleUpperCase();

    case StringCase.CamelCase:
      return camelCase(val, opts);

    case StringCase.CapitalCase:
      return capitalCase(val, opts);

    case StringCase.ConstantCase:
      return constantCase(val, opts);

    case StringCase.DotCase:
      return dotCase(val, opts);

    case StringCase.HeaderCase:
      return headerCase(val, opts);

    case StringCase.NoCase:
      return noCase(val, opts);

    case StringCase.ParamCase:
      return paramCase(val, opts);

    case StringCase.PascalCase:
      return pascalCase(val, opts);

    case StringCase.PathCase:
      return pathCase(val, opts);

    case StringCase.SentenceCase:
      return sentenceCase(val, opts);

    case StringCase.SnakeCase:
      return snakeCase(val);
  }

  return val;
}