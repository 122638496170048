import { computed, observable } from "mobx";
import * as uuid from 'uuid';
import { StoreNode } from '../../store';

const KEY = 'analytics.installContextUid';

enum IdTypes {
  Persistent = 'persistent',
  Transient = 'transient'
}

export type InstallContextType = {
  id: string,
  idType: IdTypes,
}

/**
 * Class that generates an UUID for session tracking purposes.
 * It tries to save the UUID in local or session storage if it has permissions.
 */
export class InstallContext extends StoreNode {

  @observable uid: string | null = null;

  @computed
  get storage() {
    return this.store.storage;
  }

  @computed
  get hasLocalStorage() {
    return this.storage.hasLocalStorage;
  }

  @computed
  get idType(): IdTypes {
    return this.hasLocalStorage ? IdTypes.Persistent : IdTypes.Transient;
  }

  init() {
    const existing = this.storage.getLocalOrSession(KEY);

    if (existing) {
      this.uid = existing;
    } else {
      const uid = uuid.v4();
      this.uid = uid;

      this.storage.setLocalOrSession(KEY, uid);
    }
  }


  export(): InstallContextType {
    return {
      id: this.uid || '',
      idType: this.idType
    }
  }
}