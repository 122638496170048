import { action, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import isEmail from 'validator/lib/isEmail';
import { notifySuccess } from "../../services/notifications";
import { assertNotNull, Nullable } from "../../core";
import { ApiVariables } from "../../api/apiSchema";
import { input, InputState } from "../input";
import { LayoutType } from '.';

function checkDisabled(input: any, disable: boolean) {
  return (input.loading ||
    input.isSubmitting ||
    disable)
}

export class BulkInvitesWindowState
  extends StoreNode {

  @observable isLoading = false;
  @observable isFileSelected = false;
  @observable isFileValid = false;
  @observable hasSentInvites = false;
  @observable fileName = '';
  @observable fileSize = 0;
  @observable teamId: Nullable<string> = null;
  @observable inviteErrors: Array<any> = [];
  @observable inviteSuccess: Array<any> = [];
  @observable layoutType: LayoutType = 'default';

  readonly includeInviteMessage: InputState;
  readonly inviteMessage: InputState;

  inviteList: Array<any> = [];

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.includeInviteMessage = input(this, {
      name: 'includeInviteMessage',
      disabled: input => checkDisabled(input, false)
    });

    this.inviteMessage = input(this, {
      name: 'inviteMessage',
      multiline: true,
      isRequired: () => {
        return !this.includeInviteMessage.isEmpty
      },
      disabled: input => checkDisabled(input, false)
    });


    this.window.listen(
      this.windowListener);
  }

  readonly nodeType = 'BulkInvitesWindow';
  readonly window = new WindowState(this.store);

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close();
        break;
    }
  }

  @action bulkDownloadDetailsReport() {
    const bulkDownloadDetailsReportId = 'bulk-download-details-report';
    const bulkDownloadDetailsReportElem = document.getElementById(bulkDownloadDetailsReportId);
    if (!bulkDownloadDetailsReportElem) {
      const element = document.createElement("a");
      element.id = bulkDownloadDetailsReportId;
      const file = new Blob([this.inviteErrors.join('\r\n')],
        { type: 'text/plain;charset=utf-8' });
      element.href = URL.createObjectURL(file);
      element.download = "bulk_invites_errors.txt";
      document.getElementById('bulk-download-details-button')?.appendChild(element);
      element.click();
    } else {
      bulkDownloadDetailsReportElem.click();
    }
  }

  @action
  async handleFileLoaded(data: any, fileInfo: any) {
    let isValid = true;
    this.isFileSelected = true;
    this.fileName = fileInfo.name;
    this.fileSize = fileInfo.size;

    this.inviteList = [];
    this.inviteErrors = [];
    this.inviteSuccess = [];

    const allowedRoles = ['Admin', 'Trainer', 'Member', 'Viewer'];

    data.forEach((d: any, index: any) => {
      const email = d[0]?.trim();
      const role = d[1]?.trim();

      if (d.length !== 2) {
        if (index === data.length - 1 && d.length === 1 && d[0] === '') {
          //this is a last line that is blank, still a valid file
          return
        } else {
          isValid = false;
          return;
        }
      }

      if (!isEmail(email)) {
        this.inviteErrors.push(email + ' is not a valid email');
        return;
      }

      if (!allowedRoles.includes(role)) {
        this.inviteErrors.push(email + ' has an invalid role of ' + role);
        return;
      }

      let duplicateEmail = false;
      this.inviteList.forEach(invite => {
        if (invite[0]?.trim() === email) {
          duplicateEmail = true;
          return;
        }
      })

      if (duplicateEmail) {
        this.inviteErrors.push(email + ' DUPLICATE EMAIL ADDRESS');
      } else {
        const formattedItem = d.map((item: string) => item.trim());
        this.inviteList.push(formattedItem);
      }
    });

    this.isFileValid = isValid;
  }

  private reset() {
    this.fileSize = 0;
    this.fileName = '';
    this.isFileSelected = false;
    this.isFileValid = false;
    this.inviteList = [];
    this.inviteErrors = [];
    this.inviteSuccess = [];
    this.hasSentInvites = false;
    this.includeInviteMessage.value = '';
    this.inviteMessage.value = '';
  }

  @action
  open(teamId: string | null, layoutType?: LayoutType) {
    this.reset();
    this.teamId = teamId;

    // We need to keep track of the layout type in order to mantain the consistency between windows
    this.layoutType = layoutType ?? 'default';
    this.dispatch('Overlays', 'openWindow', { name: 'BulkInvitesWindow' });
  }

  @action
  close() {
    if (this.hasSentInvites) this.emit('bulkInvitesWindow:invitesSent');

    this.emit('bulkInvitesWindow:close');
    this.dispatch('Overlays', 'closeWindow');
  }

  @action
  back() {
    const teamId = this.teamId || '';
    this.close();

    // We need to keep track of the layout type in order to mantain the consistency between windows
    this.store.teamMembersWindow.openTeamEdit(teamId, this.layoutType);
  }

  @action
  async submitInvites() {
    const team = this.store.teamManager.getTeam(this.teamId);

    if (!team)
      return;

    this.window.lock();
    this.isLoading = true;

    const message = this.inviteMessage.value!;

    const res = await Promise.all([...this.inviteList.map(async invite => {
      const email = invite[0]?.trim();
      const role = invite[1];
      const teamId = this.teamId || '';

      assertNotNull(email);
      assertNotNull(role);

      const vars: ApiVariables<'inviteTeamMember'> = {
        teamId,
        email,
        role
      };

      if (this.includeInviteMessage.value && message)
        vars.message = message;

      return team.apiInviteTeamMember(vars);
    })])

    res.forEach((el, i) => {
      const [res, err] = el;

      if (err) {
        let errorMessage = this.inviteList[i][0] + ' ' + err.message;

        if (errorMessage.indexOf(team.id) > -1) {
          errorMessage = errorMessage.replace(team.id, `${team.name} (id:${team.id})`);
        }

        this.inviteErrors.push(errorMessage);
      } else if (res) {
        this.inviteSuccess.push(this.inviteList[i][0] + ' success');
      }
    })

    this.window.unlock();
    this.isLoading = false;

    if (this.inviteErrors.length === 0) {
      notifySuccess(this, `${this.inviteList.length} invites successfully sent.`);
      this.emit('bulkInvitesWindow:invitesSent');
      this.close();
    } else {
      this.hasSentInvites = true;
    }
  }
}

