import {
  action,
  makeObservable,
  computed
} from 'mobx';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { TrainerVideoPageState } from './trainerVideoPageState';
import { HotkeysControllerState } from '../../components/hotkeys';
import { hotkeys, TrainerHotkeysActions } from './trainerHotkeys';

export class TrainerHotkeysController
  extends StoreNode {

  readonly nodeType = 'HotkeysController';

  constructor(store: Store, state: TrainerVideoPageState) {
    super(store);
    makeObservable(this);

    this.state = state;
    this.hotkeysController = new HotkeysControllerState(this.store, { config: hotkeys });

    this.hotkeysController.listen(this.listener);
  }

  readonly state: TrainerVideoPageState;
  readonly hotkeysController: HotkeysControllerState;

  @computed
  get controller(): HotkeysControllerState {
    return this.hotkeysController;
  }

  @action
  private listener = (msg: Message & { payload: TrainerHotkeysActions | null }) => {
    const { type, payload } = msg;

    if (!payload || this.store.overlayService.visibleWindow) return;

    const [action, keyItem] = payload;

    if (this.state.player.isFullscreen && !keyItem.isAvailableInFullscreen) return;

    if (type === 'onKeyUp') {

    } else if (type === 'onKeyDown') {
      this.handleKeyDown(action);
    }
  }

  @action
  handleKeyDown(action: TrainerHotkeysActions) {
    const player = this.state.player;
    const timeline = this.state.timeline;

    switch (action) {
      case 'SKIP_BACK': {
        return player.invoke('seekStepBackward');
      }
      case 'SKIP_FORWARD': {
        return player.invoke('seekStepForward');
      }
      case 'PAUSE': {
        if (player.isStopped) return player.invoke('play');

        return player.invoke('togglePlay');
      }
      // case 'FULL_SCREEN': {
      //   return player.invoke('toggleFullscreen');
      // }
      case 'PLAYBACK_SPEED_INC':
      case 'PLAYBACK_SPEED_DEC': {
        const options = player.playbackRateOptions;
        const rate = player.playbackRate;
        const index = options.findIndex((opt) => opt.rate === rate);
        const nextIndex = index < options.length - 1 ? index + 1 : index;
        const prevIndex = index > 0 ? index - 1 : index;

        if (action === 'PLAYBACK_SPEED_INC') {
          return player.invoke('setPlaybackRate', options[nextIndex]);
        }
        if (action === 'PLAYBACK_SPEED_DEC') {
          return player.invoke('setPlaybackRate', options[prevIndex]);
        }
        return;
      }
      case 'START_MOMENT_STUB': {
        return player.invoke('startMomentStub');
      }
      case 'STOP_MOMENT_STUB': {
        return player.invoke('confirmMomentStub');
      }
      case 'ESCAPE': {
        if (player.momentStub) {
          return player.invoke('clearMomentStub');
        }
        return;
      }
      case 'TOGGLE_CLIPR_CLIPS': {
        return timeline.invoke('toggleSourceOption', { sourceName: 'Clipr' })
      }
      case 'TOGGLE_TRAINER_CLIPS': {
        return timeline.invoke('toggleSourceOption', { sourceName: 'Trainer' })
      }
      case 'TOGGLE_USER_CLIPS': {
        return timeline.invoke('toggleSourceOption', { sourceName: 'User' })
      }
      case 'ZOOM_IN_TIMELINE': {
        return timeline.invoke('setZoom', { value: timeline.zoom / 0.75 });
      }
      case 'ZOOM_OUT_TIMELINE': {
        return timeline.invoke('setZoom', { value: timeline.zoom * 0.75 });
      }
      case 'NEXT_TIMELINE_TRACK': {
        return timeline.invoke('nextTrack');
      }
      case 'PREV_TIMELINE_TRACK': {
        return timeline.invoke('previousTrack');
      }
      case 'NEXT_TIMELINE_CLIP': {
        return timeline.invoke('jumpToNextMomentInTrack');
      }
      case 'PREV_TIMELINE_CLIP': {
        return timeline.invoke('jumpToPreviousMomentInTrack');
      }
      default:
        return;
    }
  }

}