import set from 'lodash/set';
import { DateTime, Duration } from 'luxon';
import { isValue } from '../../core';
import { decodeEnum } from '../../core/enum';
import { isNonEmptyString } from '../../core/string';
import { readBooleanFromUrlValue, readFloatFromUrlValue, readIntFromUrlValue, readStringFromUrlValue, readUIntFromUrlValue } from '../../core/urlUtils';
import { ParamInfo, ParamsData, ParamsSchema, ParamType } from './paramsSchema';

type URLValueParamReadFunc<T extends string | number | boolean = any> = (schema: ParamInfo, urlVal: string) => T | null;

function readStringParamFromUrlValue(schema: ParamInfo, urlVal: string) {
  return readStringFromUrlValue(urlVal);
}

function readBooleanParamFromUrlValue(schema: ParamInfo, urlVal: string) {
  return readBooleanFromUrlValue(urlVal);
}

function readIntParamFromUrlValue(schema: ParamInfo, urlVal: string) {
  return readIntFromUrlValue(urlVal);
}

function readUIntParamFromUrlValue(schema: ParamInfo, urlVal: string) {
  return readUIntFromUrlValue(urlVal);
}

function readFloatParamFromUrlValue(schema: ParamInfo, urlVal: string) {
  return readFloatFromUrlValue(urlVal);
}

function readEnumParamFromUrlValue(schema: ParamInfo, urlVal: string): string | number | null {
  const valStr = readStringFromUrlValue(urlVal);
  if (!valStr || !schema.enum)
    return null;
  return decodeEnum(valStr, schema.enum);
}

function readDateTimeParamFromUrlValue(schema: ParamInfo, urlVal: string): DateTime | null {
  const valStr = readStringFromUrlValue(urlVal);
  if (!valStr)
    return null;
  const date = DateTime.fromISO(valStr);
  if (!date.isValid)
    return null;
  return date;
}

function readDurationParamFromUrlValue(schema: ParamInfo, urlVal: string): Duration | null {
  const valStr = readStringFromUrlValue(urlVal);
  if (!valStr)
    return null;
  const dur = Duration.fromISO(valStr);
  if (!dur.isValid)
    return null;
  return dur;
}

const URLValueParamReadFuncLookup: Record<ParamType, URLValueParamReadFunc<any>> = {
  [ParamType.String]: readStringParamFromUrlValue,
  [ParamType.Boolean]: readBooleanParamFromUrlValue,
  [ParamType.Int]: readIntParamFromUrlValue,
  [ParamType.UInt]: readUIntParamFromUrlValue,
  [ParamType.Float]: readFloatParamFromUrlValue,
  [ParamType.Enum]: readEnumParamFromUrlValue,
  [ParamType.DateTime]: readDateTimeParamFromUrlValue,
  [ParamType.Duration]: readDurationParamFromUrlValue
};

type Props<TParams extends ParamsData = ParamsData> = {
  schema: ParamsSchema<TParams>;
  caseSensitive?: boolean;
}

/** 
 * Typed, general purpose object for reading params from various sources like URL params, etc. 
 */
export class ParamsReader<TParams extends ParamsData = ParamsData> {

  constructor(props: Props<TParams>) {
    this.schema = props.schema;
    this.caseSensitive = props.caseSensitive ?? false;
  }

  readonly schema: ParamsSchema<TParams>;
  readonly caseSensitive: boolean;

  readFromQueryString(queryString: string, useDefaults = true): TParams {

    const { schema } = this;
    const queryLookup = this.getQueryLookup(queryString);

    const params = schema.params;
    const keys = Object.keys(params);
    const output: any = {};

    for (const key of keys) {
      const val = this.readParamValueFromQueryLookup(queryLookup, key, useDefaults);
      set(output, key, val);
    }

    return output;
  }

  readParamFromQueryString<T extends keyof TParams>(queryString: string, key: keyof TParams & string, useDefault = true): TParams[T] | null {
    const queryLookup = this.getQueryLookup(queryString);
    return this.readParamValueFromQueryLookup(queryLookup, key, useDefault);
  }

  private getQueryLookup(queryString: string): Map<string, string> {
    const queryParams = new URLSearchParams(queryString);
    const queryKeys = [...queryParams.keys()];
    const lookup = new Map<string, string>();

    for (const key of queryKeys) {
      const casedKey = this.caseSensitive ? key : key.toLocaleLowerCase();
      const value = queryParams.get(key);
      if (!value)
        continue;

      lookup.set(casedKey, value);
    }

    return lookup;
  }

  /** 
   * Returns the value of a single param from a pre-computed lookup of the query params,
   * generated using `getQueryLookup`, such that all keys are cased properly. 
   */
  private readParamValueFromQueryLookup<T extends keyof TParams>(queryLookup: Map<string, string>, key: keyof TParams & string, useDefault = true): TParams[T] | null {
    const schema = this.schema.params[key];
    const defaultValue = useDefault ? schema.defaultValue as TParams[T] : null;

    const name = schema.name;
    const aliasKeys = schema.aliasKeys ?? [];
    const keys: string[] = [...aliasKeys, name]
      .filter(x => isNonEmptyString(x))
      .map(x => x.toLowerCase());

    const valStr: string | null = (() => {
      for (const name of keys) {
        const val = queryLookup.get(name);
        if (isNonEmptyString(val))
          return val;
      }

      return null;
    })();

    if (!valStr)
      return defaultValue ?? null;

    const isArray = schema.isArray;
    const separator = /[,;]/;

    // we treat everything as an array for easier processing
    let items: string[];
    if (isArray)
      items = valStr?.split(separator) ?? [];
    else
      items = [valStr];

    // cleanup the items
    items = items.map(item => item.trim());

    const read = URLValueParamReadFuncLookup[schema.type];

    // remove empty string / nullish values then read
    const values = items
      .filter(x => isNonEmptyString(x))
      .map(x => read(schema, x))
      .filter(x => isValue(x));

    if (isArray)
      return values as TParams[T];

    return (
      values[0] ??
      defaultValue ??
      null);
  }
}