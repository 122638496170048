import { observer } from 'mobx-react-lite';
import React from 'react';
import { handler } from '../../core';
import { MomentSplashState } from './momentSplashState';


type Props = {
  model: MomentSplashState
}

export const GenericSelectorSection = observer(({
  model
}: Props) => {
  const { selector } = model;

  const {
    selectedTopics,
    visibleSubTopics,
    isSubTopicListExpandable: expandable,
    isSubTopicListExpanded: expanded
  } = model;

  let expandBtn;
  if (expandable) {
    expandBtn = (
      <button className="toggle-expansion-btn btn-core tmar4"
        onClick={handler(model, 'toggleSubTopicListExpansion')}>

        <span className="text">{expanded ? 'Hide' : 'Show all'}</span>
        <span className="icon"></span>
      </button>
    );
  }

  return (
    <section className="moment-filters content-section vpad6 hpad4"
      aria-expanded={expandable ? expanded : undefined}>

      <h3 className="section-header vpad2">Subtopics</h3>

      {selectedTopics.length === 0 &&
        <div className="filters-heading">Select one or more topics to see related subtopics.</div>}

      {selectedTopics.length > 0 && visibleSubTopics.length === 0 &&
        <div className="filters-heading">No subtopics match your selection.</div>}

      {visibleSubTopics.length > 0 &&
        <>
          <div className="filters-selector">

            <ul className="filter-list">
              {visibleSubTopics.map(sub => {

                return (
                  <li className="filter-item" key={sub.id}>
                    <button className="filter-btn toggle-btn"
                      aria-pressed={selector?.isSubTopicSelected(sub)}
                      onClick={evt => model.handleSubTopicClick(evt, sub)}>

                      <span className="name">{sub.name}</span>
                      <span className="duration">{sub.shortDurationLabel}</span>
                    </button>
                  </li>
                );
              })}
            </ul>

            {expandBtn}
          </div>
        </>
      }
    </section>
  );
});