import { Duration } from 'luxon';
import { assert } from '../assert';
import { toArrayLike } from '../iterable';
import { pluralize } from '../string';
import { Maybe } from '../types';
import { ITimeRegion } from './timeSchema';

export function getNowSeconds() {
  return new Date().getTime() / 1000;
}

export function getTimeRegionDuration(region: ITimeRegion) {
  return region.startTime - region.endTime;
}

export function toTimeRegionString(region: ITimeRegion) {
  return `ITimeRegion(${region.startTime}, ${region.endTime})`;
}

export function assertValidDuration(region: ITimeRegion) {
  assert(region.endTime >= region.startTime,
    `${toTimeRegionString(region)} does not have a valid duration`);
}

/**
 * Takes an array of time regions (Moments most of the time) and computes the actual time 
 * which would elapse by playing through the regions.
 * This is used in the case of overlapping regions, for which summing them would yield larger and incorrect values.
 * NOTE: This function expects the region array to be sorted by startTime in ascending order.
 * 
 * @param regions The array of time regions to compute the duration for. 
 *                This must be sorted by startTime in ascending order.
 * @returns       The merged duration.
 * @throws  {AssertionError} startTime must be less than or equal to endTime for each provided time region.
 * 
 * @example
 * // Region A:     |   --------| length: 8
 * // Region B:     |------     | length: 6
 * // Elapsed time: |-----------| length: 11 (summation would yield 14 which is incorrect)
 * 
 */
export function getTimeRegionsMergedDuration(regions: ITimeRegion[]): number {

  let prevStart = 0, prevEnd = 0, prevDur = 0;

  let sum = 0;
  for (let i = 0; i < regions.length; i++) {
    const prev = regions[i - 1];
    const curr = regions[i];

    assertValidDuration(curr);

    if (i === 0) {
      // first element, the sum contribution is simply the duration itself
      prevStart = curr.startTime;
      prevEnd = curr.endTime;
      prevDur = prevEnd - prevStart;
    } else {
      assert(prev.startTime <= curr.startTime,
        `${toTimeRegionString(prev)} at index ${i - 1} has a higher 'startTime' value than ${toTimeRegionString(prev)} at index ${i}.` +
        `Please make sure that the 'regions' array is sorted by 'startTime' in ascending order`);

      let overlaps = curr.startTime <= prevEnd;
      if (overlaps) {
        // we just extend the current overlapping region
        if (curr.endTime > prevEnd) {
          prevEnd = curr.endTime;
          prevDur = prevEnd - prevStart;
        }
      } else {
        // non overlapping, just start a new region and add the result from the previous region
        sum += prevDur;
        prevStart = curr.startTime;
        prevEnd = curr.endTime;
        prevDur = prevEnd - prevStart;
      }

      assertValidDuration({ startTime: prevStart, endTime: prevEnd });
    }
  }

  // after the loop ends we'll have a last duration accumulator which we need to add to the final result
  sum += prevDur;

  return sum;
}


export function timeRegionsOverlap(a: ITimeRegion, b: ITimeRegion) {
  return (
    a.startTime <= b.endTime &&
    a.endTime >= b.startTime);
}
export function timeRegionsOverlapStrict(a: ITimeRegion, b: ITimeRegion) {
  return (
    a.startTime < b.endTime &&
    a.endTime > b.startTime);
}

export function timeRegionContains(a: ITimeRegion, b: ITimeRegion) {
  return (
    a.startTime <= b.startTime &&
    a.endTime >= b.endTime);
}

export function timeRegionEquals(a: ITimeRegion, b: ITimeRegion) {
  return (
    a.startTime === b.startTime &&
    a.endTime === b.endTime);
}




export function mergeTimeRegions(regions: ITimeRegion[], passageEpsilon = 0): ITimeRegion[] {

  const outRegions: ITimeRegion[] = [];

  let prevStart = 0;
  let prevEnd = 0;

  for (let i = 0; i < regions.length; i++) {
    const prev = regions[i - 1];
    const curr = regions[i];

    if (i === 0) {
      // first element, the sum contribution is simply the duration itself
      prevStart = curr.startTime;
      prevEnd = curr.endTime;
    } else {
      assert(prev.startTime <= curr.startTime,
        `${toTimeRegionString(prev)} at index ${i - 1} has a higher 'startTime' value than ${toTimeRegionString(prev)} at index ${i}.` +
        `Please make sure that the 'regions' array is sorted by 'startTime' in ascending order`);

      let overlaps = curr.startTime <= prevEnd + passageEpsilon;
      if (overlaps) {
        // we just extend the current overlapping region
        if (curr.endTime > prevEnd) {
          prevEnd = curr.endTime;
        }
      } else {
        // non overlapping, just start a new region and add the result from the previous region
        prevStart = curr.startTime;
        prevEnd = curr.endTime;
      }

      outRegions.push({
        startTime: prevStart,
        endTime: prevEnd
      });
    }
  }

  // after the loop ends we'll have a last duration accumulator which we need to add to the final result
  outRegions.push({
    startTime: prevStart,
    endTime: prevEnd
  });

  return outRegions;
}

export function durationToString(val?: Maybe<number>, format: 'card' | 'time' = 'card') {

  if (!val)
    val = 0;

  val = Math.round(val); // dur.toFormat will not round the value 

  const dur = Duration.fromObject({ seconds: val });
  const hmsDur = dur.shiftTo('hours', 'minutes', 'seconds');

  const mins = Math.round(hmsDur.minutes);
  const secs = Math.round(hmsDur.seconds);
  const hrs = Math.round(hmsDur.hours);

  switch (format) {
    case 'card': {
      if (!val)
        return '0 mins';

      if (mins < 1)
        return pluralize(secs, 'sec', 'secs');
      if (hrs < 1)
        return pluralize(mins, 'min', 'mins');

      return (
        pluralize(hrs, 'hr', 'hrs') + ' ' +
        pluralize(mins, 'min', 'mins'));
    }

    case 'time': {
      if (hrs < 1)
        return dur.toFormat('mm:ss');

      return dur.toFormat('h:mm:ss');
    }
  }

  return '0 mins';
}


/**
 * @param moments The input `ITimeRegion` array, sorted in ascending order.
 */
export function firstOverlappingTimeRegion<T extends ITimeRegion = ITimeRegion>(regions: Iterable<T>, time: number): T | null {
  for (let region of regions) {
    if (time >= region.startTime && time <= region.endTime)
      return region;
  }
  return null;
}

/**
* @param moments The input `ITimeRegion` array, sorted in ascending order.
*/
export function lastOverlappingTimeRegion<T extends ITimeRegion = ITimeRegion>(regions: Iterable<T>, time: number): T | null {
  // iterate backwards to short circuit early
  let regArr = toArrayLike(regions);

  for (let i = regArr.length - 1; i >= 0; i--) {
    const region = regArr[i];
    if (time >= region.startTime && time <= region.endTime)
      return region;
  }

  return null;
}

// used to centralize the normalization of the timestamps from be to player 
export const timestampNorm = (timestamp: number) => {
  return Math.round(timestamp);
}