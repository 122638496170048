import './trainerVideoPage.scss';

import React, { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react-lite';

import { OverlaysChunk } from '../../services/overlays';
import { NotificationsChunk } from '../../services/notifications';
import { LoadMask } from '../../components/loader';
import { Routes } from '../../routes';
import { PageBackButton, Player } from '../../components';
import { ChevronLeft1SVGIcon, InfoSVGIcon } from '../../components/svg';
import { TrainerTimeline } from '../../components/trainerTimeline';
import { TrainerTranscripts } from '../../components/trainerTranscripts';
import { DropdownInputControlled } from '../../components/input';
import { TrainerVideoPageParams, TrainerVideoPageState } from './trainerVideoPageState';
import { useStore } from '../../store/storeHooks';
import { ErrorPage, NotAuthorizedErrorPage } from '../error';
import { HotkeysController } from '../../components/hotkeys';
import { JobTitle } from '../../components/media/jobTitle';

type Props = {
  model: TrainerVideoPageState
}

export const TrainerVideoPage = observer(({ model }: Props) => {

  const params = useParams<TrainerVideoPageParams>();
  const { search } = useLocation();

  const store = useStore();
  const { user } = store;

  const {
    job,
    player,
    transcripts,
    timeline,
    hotkeys,
    isLoading,
    error,
    isJobFailed,
    isJobInProgress
  } = model;

  const { title, isPublished, isAudioSource } = job || {};

  useEffect(() => {
    model.load(params, search);
    return () =>
      model.unload();
  }, [model, params, search]);

  if (!user?.hasPermission('ViewTrainerDashboard'))
    return <NotAuthorizedErrorPage />;

  if (error)
    return <ErrorPage message={error.message} />;

  const { jobId } = params;

  const pageTitleLocation = title ? ('- ' + title) : '';

  return (
    <div className="app-page">
      <Helmet>
        <title>{`CLIPr ${pageTitleLocation}`}</title>
      </Helmet>
      <div className="trainer-video-page">

        <header className="trainer-video-header trainer-page-section hgrid-3 hpad4">
          <div className="trainer-video-header-left flex-left gap6">
            <PageBackButton
              className="close-back-btn"
              leftIcon={<ChevronLeft1SVGIcon />} />

            <div id="page-headline">
              <h1 id="page-subheading">
                TRAINING:
              </h1>
              <h1 id="page-title" className="trainer-video-title">
                <JobTitle
                  title={title || 'Title not set'}
                  isAudioSource={isAudioSource}></JobTitle>
              </h1>
            </div>

            <button className="user-video-info-btn info-btn"
              onClick={evt => {
                model.invoke('openVideoInformationWindow');
                evt.stopPropagation();
              }}>

              <InfoSVGIcon />
            </button>
          </div>

          <div className="trainer-video-header-right flex-right">
            {
              isPublished &&
              model.job?.hasPermission('EditIsPublic') &&
              <DropdownInputControlled
                className="video-privacy-input video-update-input"
                model={model.videoPrivacyInput}
                persistentFeedback={false}
                showEmptyItem={false} />}

            <DropdownInputControlled
              className="video-status-input video-update-input"
              disabled={isJobInProgress || isJobFailed}
              model={model.videoStatusInput}
              persistentFeedback={false}
              showEmptyItem={false} />

            <DropdownInputControlled
              className="video-type-input video-update-input"
              model={model.videoTypeInput}
              persistentFeedback={false}
              placeholder="Video Type"
              showEmptyItem={!model.videoTypeInput.value} />

            <Link to={Routes.userVideoLanding(jobId)}
              target="_blank"
              className="btn primary">
              <span className="text">Open in Consumer Player</span>
            </Link>
          </div>
        </header>

        <Player model={player} />

        <TrainerTranscripts
          model={transcripts} />

        <TrainerTimeline
          model={timeline} />

        <HotkeysController model={hotkeys.controller} />
      </div>

      <OverlaysChunk />
      <NotificationsChunk />

      <LoadMask
        visible={isLoading} />
    </div>
  );
});

export default TrainerVideoPage;