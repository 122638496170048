import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { Overlay } from '../../overlays/overlay';
import { useOverlayState } from '../../overlays/overlayState';
import { AudioLanguageSVGIcon, CheckmarkSVGIcon } from '../../svg';
import { PlayerState } from '../playerState';
import { action } from 'mobx';
import { useOverlayGroupState } from '../../overlays/overlayGroupState';
import { multiRef } from '../../../store/refProxy';

type Props = {
  model: PlayerState
}

export const AudioTrackButton = observer(({
  model
}: Props) => {
  const { playerAudioTracks: controller } = model.adapter;
  const audioTrackItems = controller.audioTracks;
  const selectedAudioTrack = controller.selectedAudioTrack;

  const panelState = useOverlayState({
    visibility: 'OnClick',
    visibilityTrigger: () => (model.chrome.visibility !== 'Visible' ? false : undefined),
    onShow: () => model.chrome.startControlAction(),
    onHide: () => model.chrome.endControlAction()
  });
  const tooltipState = useOverlayState({
    suppressFrom: [panelState]
  });

  const className = classNames('user-player-control-btn audio-track-btn', {
    // hover: panelState.anchorIsHovered 
  });

  const overlayGroupState = useOverlayGroupState({
    overlays: [tooltipState, panelState]
  });

  return (
    <button className={className}
      tabIndex={0}
      // aria-disabled={!controller.isTrackActive}
      ref={multiRef(tooltipState.anchorRef, panelState.anchorRef)}
      {...overlayGroupState.anchorProps}>

      <div className="icon"
        aria-selected={!!selectedAudioTrack && false}>
        <AudioLanguageSVGIcon />
      </div>

      <Overlay model={tooltipState}
        className="user-player-tooltip">
        Audio Track
      </Overlay>

      <Overlay
        model={panelState}
        className={`control-panel audio-track-btn-panel`}>

        <ul
          className="audio-track-btn-panel-list"
          data-tutorial-id="audio-track-btn-panel-list"
          role="menu">

          {audioTrackItems.map(track => {
            return (
              <li key={track.id}
                className="audio-track-btn-panel-item"
                role="option"
                aria-selected={selectedAudioTrack?.id === track.id}
                tabIndex={0}
                onClick={action(evt => {
                  panelState.hide();
                  tooltipState.hide();
                  controller.switchTrack(track);
                })}>
                <span className="value">
                  {track.name}
                </span>
                <span className="check">
                  <CheckmarkSVGIcon />
                </span>
              </li>
            );
          })}

        </ul>
      </Overlay >
    </button >
  );
});