import './infoTooltip.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';

type Props = {
  showPublicInfoSection: boolean;
}

export const VisibilityInfoTooltip = observer(({ showPublicInfoSection }: Props) => {
  return (
    <div
      className="info-modal-tooltip job-details">

      <div className="tooltip-content">
        <p className="title">Private Stream</p>
        <p className="description">“Private” means that only you can see this session. Set it to Shareable so that it will appear automatically wherever it is embedded when the session starts</p>

        {showPublicInfoSection &&
          <>
            <p className="title">Shareable Stream</p>
            <p className="description">Keep it Private in the event of title and schedule changes until closer to the scheduled event time, then switch it to Shareable before the event</p>
          </>
        }
      </div>
    </div>
  );
});
