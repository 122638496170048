import { Duration } from 'luxon';

export function shortDurationLabel(val: number) {

  if (val < 45) {
    return Math.round(Duration
      .fromObject({ seconds: val })
      .as('seconds')) + 's';
  }

  if (val < 45 * 60) {
    return Math.round(Duration
      .fromObject({ seconds: val })
      .as('minutes')) + 'm';
  }

  return Math.round(Duration
    .fromObject({ seconds: val })
    .as('hours')) + 'h';
}


export class DateFormatter {

}