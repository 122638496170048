import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { PlayerState } from '../playerState';
import { ControlButton } from './controlButton';
import { UserPlayerFullscreenSVGIcon, SquarePlusSVGIcon, CheckmarkSVGIcon, CloseSVGIcon, BodyCamSvgIcon, DocBodyCamSvgIcon } from '../../svg/svg';
import { EmbedButton, PlayPauseButton, SeekBackButton, SeekNextButton } from './controlButtons';
import { VolumeButton } from './volumeButton';
import { PinButton } from './pinButton';
import { SpeedButton } from './speedButton';
import { durationToString } from '../../../core/time';
import { ReactionButton } from './reactionButton';
import { CaptionsButton } from './captionsButton';
import { PlayerComponentName } from '../playerSchema';
import { AudioTrackButton } from './audioTrackButton';

type Props = {
  model: PlayerState
}

export const PlayerControlsBar = observer(({
  model
}: Props) => {

  const indexIsVisible = model.frameset?.isSectionVisible('Index');
  const commentsIsVisible = model.frameset?.isSectionVisible('Comments');
  const editor = model.momentEditor;

  const stub = editor.currentStub;
  const showAddMomentBtn = model.isComponentVisible(PlayerComponentName.AddMomentButton);

  const showReactionsButton = model.showReactionsButton;
  const allowFullscreen = model.allowFullscreen ?? true;

  const qs = new URLSearchParams(window.location.search);
  const teamId = qs.get('teamId') || undefined;
  const selectionRangeClassName = classNames('stub-time-range', {
    'small': model.isWidgetMode && (indexIsVisible || commentsIsVisible) && !teamId
  });

  const showPlaybackRateButton = model.isComponentVisible(PlayerComponentName.PlaybackRateButton);
  const showAudioTrackButton = model.isComponentVisible(PlayerComponentName.AudioTrackButton);
  const showClosedCaptionsButton = model.isComponentVisible(PlayerComponentName.ClosedCaptionsButton);
  const showVolumeButton = model.isComponentVisible(PlayerComponentName.VolumeButton);
  const showPublicSafetyNotes = !!model.job?.publicSafetyNotes;
  const showPublicSafetyReport = !!model.job?.publicSafetyReport;

  return (
    <div id="user-player-controls-bar">

      <div className="left-controls">
        <div className="control-group">
          {!model.isLiveStream
            && <PlayerStaticControls model={model} />}

          {model.isLiveStream
            && <PlayerLiveControls model={model} />}
        </div>

        {showReactionsButton && (
          <div className="control-group">
            <ReactionButton
              model={model} />
          </div>
        )}

        {(showPublicSafetyNotes || showPublicSafetyReport) && (
          <div className="control-group"> 
            {showPublicSafetyNotes && <button 
              className="player-body-cam-button"
              onClick={() => model.openEditorWindow(`${model.job?.title}-notes.pdf`, 'notes' )}>
              <span className="icon">
                <BodyCamSvgIcon />
              </span>
            </button>}

            {showPublicSafetyReport && <button 
              className="player-body-cam-button"
              onClick={() => model.openEditorWindow(`${model.job?.title}-reports.pdf`, 'report' )}>
              <span className="icon">
                <DocBodyCamSvgIcon />
              </span>
            </button>}
          </div>
        )}

        {showAddMomentBtn && (
          <div className="control-group">
            {model.isEditMode && (
              <>
                <ControlButton
                  className="exit-edit-mode-btn"
                  icon={<CloseSVGIcon />}
                  tooltip="Cancel"
                  model={model}
                  onClick={evt => editor.exitEditMode()} />

                <ControlButton
                  className="edit-confirm-moment-btn"
                  icon={<CheckmarkSVGIcon />}
                  tooltip="Save selection"
                  model={model}
                  onClick={evt => editor.editConfirmMoment(model.openMomentWindowOptions)} />
              </>
            )}

            {!model.isEditMode && (
              <ControlButton
                id="add-clip-btn-widget"
                data-tutorial-id="add-clip-btn-widget"
                icon={<SquarePlusSVGIcon />}
                tooltip="Add a moment"
                model={model}
                onClick={evt => editor.enterEditMode()} />
            )}
          </div>)}

        {model.isEditMode &&
          <div className="control-group">

            <div className="control-text flex-center gap2">
              <span>Your Selection:</span>
              <strong className={selectionRangeClassName}>
                <span className="stub-start-time">
                  {durationToString(stub?.startTime, 'time')}
                </span>
                <span> - </span>
                <span className="stub-end-time">
                  {durationToString(stub?.endTime, 'time')}
                </span>
              </strong>
            </div>
          </div>}
      </div>

      <div className="right-controls">

        <div className="control-group">
          {showAudioTrackButton &&
            <AudioTrackButton
              model={model} />}

          {showPlaybackRateButton &&
            <SpeedButton
              model={model} />}

          <EmbedButton
            model={model} />

          <PinButton
            model={model} />

          {showVolumeButton &&
            <VolumeButton
              model={model} />}

          {showClosedCaptionsButton &&
            <CaptionsButton
              model={model} />}

          {allowFullscreen &&
            <ControlButton model={model}
              className={`fullscreen-btn`}
              tooltip={model.isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
              icon={<UserPlayerFullscreenSVGIcon />}
              onClick={evt => model.invoke('toggleFullscreen')} />}
        </div>
      </div>
    </div>
  );
});

export const PlayerLiveControls = observer(({
  model
}: Props) => {
  const timeLabelClassName = model.duration > 3600 ? 'time-label long' : 'time-label short';

  // TODO: unify this with PlayerStaticControls and use getComponentPolicy
  // currently I'm not updating this because ads won't be run on live streams

  return <>
    {model.isSeekable &&
      <SeekBackButton
        model={model} />}

    <PlayPauseButton
      model={model} />

    {model.isSeekable &&
      <SeekNextButton
        model={model} />}

    {model.isLiveStreamRunning &&
      <div className={`control-live 
    ${model.isRealTime ? 'active' : ''}`}
        onClick={() => model.invoke('toggleRealTimeMode')}>
        LIVE
      </div>}

    {(model.isLiveStream && model.isSeekable) &&
      <div className="control-time-live">
        <span className={"current " + timeLabelClassName}>
          {model.liveOffsetLabel}
        </span>
      </div>}
  </>
})

export const PlayerStaticControls = observer(({
  model
}: Props) => {
  const timeLabelClassName = model.duration > 3600 ? 'time-label long' : 'time-label short';

  const showPlayPauseBtn = model.isComponentVisible(PlayerComponentName.PlayPauseButton)
  const showSeekBackBtn = model.isComponentVisible(PlayerComponentName.SeekBackButton);
  const showSeekNextBtn = model.isComponentVisible(PlayerComponentName.SeekNextButton);

  return <>
    {showSeekBackBtn &&
      <SeekBackButton
        model={model} />}

    {showPlayPauseBtn &&
      <PlayPauseButton
        model={model} />}

    {showSeekNextBtn &&
      <SeekNextButton
        model={model} />}

    <div className="control-time">
      <span className={"current " + timeLabelClassName}>
        {model.currentTimeLabel}
      </span>
      <span className="separator">
        /
      </span>
      <span className={"duration " + timeLabelClassName}>
        {model.durationLabel}
      </span>
    </div>
  </>
});