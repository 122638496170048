import { action, computed, makeObservable, observable } from 'mobx';
import { Error } from '../../core/error';
import { JobCatalogSource } from '../../entities';
import { notifyError } from '../../services/notifications';
import { BindingProps, StoreNode } from '../../store';
import { Store } from '../../store/store';

export const DEFAULT_MAX_TAGS_NUMBER = 10;

type Props = BindingProps<{
  teamId: string;
  source: JobCatalogSource;
}>

export class TagSectionState
  extends StoreNode {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);
  }

  @observable tags: string[] = [];
  @observable activeTags: string[] = [];
  @observable isExpanded: boolean = false;

  @computed
  get teamId(): string | null {
    return this.resolvedProps.teamId ?? null;
  }

  @computed
  get source(): JobCatalogSource | null {
    return this.resolvedProps.source ?? null;
  }

  @computed
  get isExpandable(): boolean {
    return this.tags.length >= DEFAULT_MAX_TAGS_NUMBER;
  }

  @action
  async load(sync: boolean = false) {
    this.tags = [];

    if (!this.teamId) {
      // notifyError(this, 'There was an error loading the tags');
      return new Error('Unknown', 'No teamId provided');
    }

    const [result, tagsError] = await this.store.apiService.getTeamTags({ teamId: this.teamId });

    if (tagsError || !result || !result.getTeamTags) {
      // notifyError(this, 'There was an error loading the tags');
      return;
    }

    const { tags } = this;

    result.getTeamTags?.forEach((item) => {
      tags.push(item?.tag!);
    });

    // Syncronize the new retrieved tags with the local active tags
    // This should happen just in case a tag is removed/added from/to a job
    if (sync)
      this.sync();
  }

  @action
  sync() {
    const { activeTags, tags } = this;

    if (!activeTags.length)
      return;

    if (!this.source) {
      notifyError(this, 'Could not filter by tag');
      return new Error('Unknown', 'No source found');
    }

    // When deleting a tag from a job
    // The tag could be still present in the activeTags array
    activeTags.forEach((tag: string, index: number) => {
      if (!tags.includes(tag)) {
        activeTags.splice(index, 1);
      }
    });

    this.source.setFilterFieldValue('tags', activeTags);
  }

  @action
  toggleExpandedSection() {
    this.isExpanded = !this.isExpanded;
  }

  @action
  manageActiveTag(tag: string) {

    if (!this.source) {
      console.error('No source found');
      notifyError(this, 'Could not filter by tag');
      return;
    }

    const indexOfTag = this.activeTags.indexOf(tag);

    indexOfTag > -1 ?
      this.activeTags.splice(indexOfTag, 1) :
      this.activeTags.push(tag);

    this.source.setFilterFieldValue('tags', this.activeTags);
  }

  @action
  reset() {
    this.tags = [];
    this.activeTags = [];
    this.isExpanded = false;
  }
}