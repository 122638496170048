import './input.scss';
import assert from 'assert';
import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames/dedupe';
import { InputState } from './inputState';
import { HTMLInputProps } from '../../core';
import { InputProps } from './inputSchema';
import { paramCase } from 'change-case';

type Props =
  Omit<HTMLInputProps, 'value' | 'placeholder'> &
  InputProps<string> & {
    orientation?: 'vertical' | 'horizontal'
  };

export const SliderInput = observer(({
  value,
  className,
  label,
  isFocused,
  isHovered,
  isRequired,
  showStatus,
  status,
  statusMessage,
  labelPosition = 'topStart',
  showFeedback,
  feedbackPosition: messagePosition = 'bottom',
  placeholder,
  type = 'text',
  readOnly = false,
  orientation = 'horizontal',
  infoButton,
  ...props
}: Props) => {

  className = classNames('slider-input input', className);

  const inputElem = (
    <input
      type="range"
      role="presentation"
      className="native-input native-slider-input"
      value={value || ''}
      {...props} />
  );

  const labelElem = label &&
    <label className={['input-label', paramCase(labelPosition!)].join(' ')}
      id={props.id && props.id + '_label'}
      htmlFor={props.id}>

      {label}
      {infoButton}
    </label>

  const feedbackElem = statusMessage && showFeedback &&
    <div className={['message input-message', paramCase(messagePosition!)].join(' ')}
      id={props.id && props.id + '_feedback'}
      data-status={status}
      aria-live="polite">
      {statusMessage}
    </div>

  return (
    <div className="slider-input input">

      {inputElem}
      {labelElem}
      {feedbackElem}
    </div>
  );
});

type ControlledProps =
  Props & {
    model: InputState,
  };


/**
 * Wrapper for SliderInput in which the entire state and all the handlers are provided through an InputState object.
 */
export const SliderInputControlled = observer(({ model, className, ...props }: ControlledProps) => {

  assert(model instanceof InputState,
    `The 'model' passed to SliderInputControlled must be an instance of InputState. Instead received ${model}.`);

  const handleChange = useCallback(evt =>
    model.handleChange(evt, evt.target.value),
    [model]);

  className = model.getClassName('slider-input', { className });

  return <SliderInput
    className={className}
    value={model.value}
    placeholder={model.placeholder}
    disabled={model.disabled}
    label={model.label}
    isHovered={model.isHovered}
    isFocused={model.isFocused}
    onChange={handleChange}
    onFocus={model.handleFocus}
    onBlur={model.handleBlur}
    onPointerEnter={model.handlePointerEnter}
    onPointerLeave={model.handlePointerLeave}
    status={model.status}
    statusMessage={model.statusMessage}
    {...props} />
});