import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { RemoveJobFromTeamWindowState } from './removeJobFromTeamWindowState';
import { Button } from '../input';

type Props = {
  model: RemoveJobFromTeamWindowState;
  visible?: boolean;
};

const Message = observer(
  ( { model }: { model: RemoveJobFromTeamWindowState } ) => {
    const { owner } = model;

    if (!owner) {
      return null;
    }

    if (owner.teamId) {
      if (owner.teamId === model.teamId) {
        model.teamIsOwner = true;
        return null;
      }
      const teamName = model.getTeamDetails(owner.teamId)?.name || null;
      return (
        <p>It will remain in <b>{teamName}</b> library unless it is deleted.</p>
      );
    } else if (owner.userId) {
      return (<p>It will remain in <b>My Uploads</b> library unless it is deleted.</p>)
    } else {
      return (<p>It will remain in the owner’s library unless it is deleted.</p>)
    }
  }
);

export const RemoveJobFromTeamWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      model={model.window}
      visible={props.visible}
      isLoading={model.isLoading}>
      <WindowHeader model={model.window} title="Remove from library" />

      <hr />

      <div className="window-content">
        <p className="p-bold">
          {model.teamIsOwner
            ? `The video can't be removed. ${model.teamName} is the owner.`
            : `Are you sure you want to remove this video from ${model.teamName}?`}
        </p>
        <Message model={model} />
      </div>

      <hr />


      <footer className="window-footer">
        <div className="btn-group right">
          {!model.isLoading ? (
            model.teamIsOwner ? (
              <Button
              className="primary btn-outline medium"
              label="Cancel"
              onClick={() => model.close()}
              />
              ) : (
                <>
                <Button
                  className="primary btn-outline medium"
                  label="Cancel"
                  onClick={() => model.close()}
                  />
                <Button
                  className="red medium"
                  label="Remove"
                  onClick={() => model.submit()}
                  />
              </>
            )
            ) : null}
        </div>
      </footer>
    </Window>
  );
});
