import './metricCounter.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { LoadMask } from '../../loader';
import { ChartOverlayMessage } from '../chartWrapper';
import { MetricCounterState } from './metricCounterState';

type Props = {
  model: MetricCounterState;
};

export const MetricCounter = observer(({ model }: Props) => {
  const { label, outputData, dataSource } = model;
  const { isFetching: showLoadMask, error } = dataSource;

  let overlayMessage;
  if (error) {
    overlayMessage = { code: 'Error', showMessage: false };
  }

  return (
    <div className="metric-counter">
      <div className="counter-title">{label}</div>
      <div className="counter-body">
        <span>{outputData}</span>

        <LoadMask visible={!!showLoadMask} spinner="dots3" />
        
        {overlayMessage && <ChartOverlayMessage {...overlayMessage} />}
      </div>
    </div>
  );
});
