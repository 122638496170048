export type ParamsData = Record<string, any>;
export type ParamKey = string | string[];
export enum ParamType {
  String,
  Int,
  UInt,
  Float,
  Boolean,
  Enum,
  DateTime,
  Duration
}

export type ParamInfo<T = string> = {
  name: string;
  type: ParamType;
  aliasKeys?: string[];
  defaultValue?: T;
  isArray?: boolean;
  enum?: Record<string, string | number>;
}

export type ParamsSchemaParams<TParams extends ParamsData = ParamsData> = Record<keyof TParams, ParamInfo>;
export type ParamsSchema<TParams extends ParamsData = ParamsData> = {
  params: ParamsSchemaParams<TParams>;
}

export function param(name: string, type: ParamType, opts: Omit<ParamInfo, 'name' | 'type'> = {}): ParamInfo {
  return {
    name, 
    type,
    ...opts ?? {}
  }
}