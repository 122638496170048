import { action, makeObservable, observable } from 'mobx';
import { Message, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { WindowState } from '../../components/overlays';

export class TrainerKeyboardShortcutsWindowState
  extends StoreNode {

  readonly nodeType = 'TrainerKeyboardShortcutsWindow';

  constructor(store: Store, props?: any) {
    super(store, props);
    makeObservable(this);
    this.window.listen(
      this.windowListener);
  }

  @observable isLoading = false;

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close();
        break;
    }
  }
  readonly window = new WindowState(this.store);

  @action
  open() {
    this.dispatch('Overlays', 'openWindow', { name: 'TrainerKeyboardShortcutsWindow' });
  }

  @action
  close() {
    this.dispatch('Overlays', 'closeWindow');
  }
}