import { useState } from 'react';
import { RefProxy } from '../../store';
import { useStore } from '../../store/storeHooks';

export function useSizeReporter(prefix: string) {

  const store = useStore();
  const init = new RefProxy(store);

  const [ref] = useState(init);

  const callback = (entries: ResizeObserverEntry[]) => {
    ref.node?.style.setProperty(`--${prefix}-width`, entries[0].contentRect.width + 'px');
    ref.node?.style.setProperty(`--${prefix}-height`, entries[0].contentRect.height + 'px');
  }

  const observer = new ResizeObserver(callback);

  init.listen(msg => {
    switch (msg.type) {
      case 'mounted':
        if (ref.node)
          observer.observe(ref.node);
        break;

      case 'unmounted':
        observer.disconnect();
        break;
    }
  });

  return ref;
}