import React from 'react';
import { observer } from 'mobx-react-lite';
import { ButtonControlled, TextInputControlled } from '../input';
import { SpeakerFormBlockState } from './speakerFormBlockState';
import { FileInputControlled } from '../input/fileInput';
import { SpeakerInputGroup } from './speakerInputGroup';
import { ConfirmationCheckSVGIcon, ConfirmationRejectSVGIcon } from '..';
import classNames from 'classnames';

type Props = {
  model: SpeakerFormBlockState;
  showDescription?: boolean | null;
  showLinkedin?: boolean | null;
  descriptionLabel?: string | null;
  linkedinLabel?: string | null;
  confirmLabel?: string | null;
  className?: string | null;
}

export const SpeakerFormBlock = observer(({
  model,
  showDescription = true,
  showLinkedin = true,
  descriptionLabel = 'Speaker description',
  linkedinLabel = 'Speaker LinkedIn',
  className,
  confirmLabel
}: Props) => {

  const confirmLayoutActive = model.isPending || model.isConfirmed;

  const showExtended = showDescription || showLinkedin;

  className = classNames('speaker-form-block', {
    'is-confirmation': confirmLayoutActive
  });

  return (
    <div className={className}>
      <div className="speaker-details">
        <FileInputControlled
          model={model.avatar}
          layout={'avatar'}
          layoutSize={'small'} />

        {!confirmLayoutActive &&
          <SpeakerInputGroup
            model={model.speakerInputGroup} />}

        {confirmLayoutActive &&
          <SpeakerConfirmationGroup
            model={model}
            confirmLabel={confirmLabel} />}
      </div>

      {showDescription && <div className="speaker-description">
        <TextInputControlled
          label={descriptionLabel}
          rows={4}
          model={model.description} />

      </div>}

      {showLinkedin && <div className="speaker-linkedin">
        <TextInputControlled
          label={linkedinLabel}
          model={model.linkedin} />
      </div>}

      {!showExtended && <div className="vpad1" />}
    </div>
  );
});

type ConfirmationProps = {
  model: SpeakerFormBlockState;
  confirmLabel?: string | null;
}

export const SpeakerConfirmationGroup = observer(({
  model,
  confirmLabel
}: ConfirmationProps) => {

  const { speakerModel } = model;

  return <div className="speaker-confirmation-group">
    <div className="speaker-confirmation-info">
      <div className="match-info">{confirmLabel}</div>
      <div className="speaker-name">{speakerModel?.displayName}</div>
    </div>

    <div className="speaker-confirmation-btn-group">
      {!model.isConfirmed && <ButtonControlled
        className="confirmation-btn red"
        leftIcon={<ConfirmationRejectSVGIcon />}
        model={model.rejectButton}
        onClick={model.handleReject} />}
      <ButtonControlled
        className="confirmation-btn green"
        leftIcon={<ConfirmationCheckSVGIcon />}
        model={model.acceptButton}
        disabled={model.isConfirmed}
        onClick={model.handleConfirm} />

      {model.isConfirmed && <div className="confirmation-text">
        Confirmed!
      </div>}
    </div>
  </div>
});