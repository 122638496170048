import { HotkeysConfigItem } from "../../components/hotkeys";
import { ObjectLiteral } from "../../core";

export const hotkeys: ObjectLiteral<HotkeysConfigItem> = {
  'SKIP_BACK': {
    keynames: ['j'],
    description: 'Rewind 10 seconds',
    isAvailableInFullscreen: true,
  },
  'SKIP_FORWARD': {
    keynames: ['l'],
    description: 'Fast forward 10 seconds',
    isAvailableInFullscreen: true,
  },
  'PAUSE': {
    keynames: ['k', 'space'],
    description: 'Toggle play/pause',
    isAvailableInFullscreen: true,
  },
  // 'FULL_SCREEN': {
  //   keynames: ['f'],
  //   description: 'Toggle full screen',
  //   isAvailableInFullscreen: true,
  // },
  'PLAYBACK_SPEED_INC': {
    keynames: ['shift+.'],
    description: 'Increase playback rate',
    isAvailableInFullscreen: true,
  },
  'PLAYBACK_SPEED_DEC': {
    keynames: ['shift+,'],
    description: 'Decrease playback rate',
    isAvailableInFullscreen: true,
  },
  'START_MOMENT_STUB': {
    keynames: ['i'],
    description: 'Start moment creation',
  },
  'STOP_MOMENT_STUB': {
    keynames: ['o'],
    description: 'Confirm moment creation',
  },
  'ESCAPE': {
    keynames: ['esc'],
    isAvailableInFullscreen: true,
  },
  'TOGGLE_CLIPR_CLIPS': {
    keynames: ['c'],
    description: 'Toggle CLIPr created moments'
  },
  'TOGGLE_TRAINER_CLIPS': {
    keynames: ['t'],
    description: 'Toggle Trainer created moments'
  },
  'TOGGLE_USER_CLIPS': {
    keynames: ['u'],
    description: 'Toggle User created moments'
  },
  'ZOOM_IN_TIMELINE': {
    keynames: ['+', '='],
    description: 'Zoom in on timeline'
  },
  'ZOOM_OUT_TIMELINE': {
    keynames: ['-'],
    description: 'Zoom out on timeline'
  },
  'NEXT_TIMELINE_TRACK': {
    keynames: ['down'],
    description: 'Highlight the next swimlane in the timeline'
  },
  'PREV_TIMELINE_TRACK': {
    keynames: ['up'],
    description: 'Highlight the previous swimlane in the timeline'
  },
  'NEXT_TIMELINE_CLIP': {
    keynames: ['right'],
    description: 'Jump to the next moment in the highlighted swimlane'
  },
  'PREV_TIMELINE_CLIP': {
    keynames: ['left'],
    description: 'Jump to the previous moment in the highlighted swimlane'
  },
}

export type TrainerHotkeysActions = keyof typeof hotkeys;