import './infoTooltip.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { getEnrichmentLevelLabel } from '../../entities/job/jobFeatures';

export const EnrichmentLevelInfoTooltip = observer(() => {
  return (
    <div
      className="info-modal-tooltip job-details">

      <div className="tooltip-content">
        <p className="title">{getEnrichmentLevelLabel('transcript')}</p>
        <p className="description">Delivers a text-only transcript of the video or audio file.</p>

        <p className="title">{getEnrichmentLevelLabel('automaticEnrichment')}</p>
        <p className="description">CLIPr's AI and machine-learning analysis delivers an index of Topics and Subtopics, with speakers tentatively identified.</p>

        <p className="title">{getEnrichmentLevelLabel('manualEnrichment')}</p>
        <p className="description">CLIPr's staff uses the AI-enriched output to conduct quality-control review - clarifying Topics and Subtopics, identifying speakers, and correcting any text CLIPr spelled differently than expected.</p>
      </div>
    </div>
  );
});
