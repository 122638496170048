import { JobModel } from "../../../entities";
import { IAnalyticsStream, AnalyticsStreamParams, AnalyticsEventTypes } from "../analyticsSchema";
import { AnalyticsStream, AnalyticsCurrentJobData, AnalyticsMainData, AnalyticsPlayerStateData } from "./analyticsStream";

export type AnalyticsCommentInputStreamParams = {
  commentId: string | null,
  job: JobModel
}

export type AnalyticsCommentInputStreamData = {
  commentId: string | null
} 
& AnalyticsPlayerStateData
& AnalyticsCurrentJobData
& AnalyticsMainData

export class AnalyticsCommentInputStream extends AnalyticsStream implements IAnalyticsStream<keyof AnalyticsStreamParams> {
  static streamName = AnalyticsEventTypes.CommentInputType

  exchangeData(data: AnalyticsCommentInputStreamParams): AnalyticsCommentInputStreamData|null {
    return { 
      ...{ 
        commentId: data?.commentId ?? null 
      }, 
      ...super.playerData,
      ...super.currentJobData(data.job), 
      ...super.mainData,
      ...super.viewContextData
    };
  }
}
