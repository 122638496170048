import React from 'react';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';
import classNames from 'classnames';
import { PlayerState } from '../playerState';
import { useOverlayState } from '../../overlays/overlayState';
import { useOverlayGroupState } from '../../overlays/overlayGroupState';
import { multiRef } from '../../../store';
import { Overlay } from '../../overlays/overlay';
import { CheckmarkSVGIcon } from '../../svg';

type Props = {
  model: PlayerState
}

export const SpeedButton = observer(({
  model
}: Props) => {

  const panelState = useOverlayState({
    visibility: 'OnClick',
    visibilityTrigger: () => (model.chrome.visibility !== 'Visible' ? false : undefined),
    makeExclusiveWhenVisible: true,
    onShow: () => model.chrome.startControlAction(),
    onHide: () => model.chrome.endControlAction()
  });
  const tooltipState = useOverlayState({
    suppressFrom: [panelState]
  });

  const overlayGroupState = useOverlayGroupState({
    overlays: [tooltipState, panelState]
  });

  const className = classNames('user-player-control-btn speed-btn', {
    // hover: panelState.anchorIsHovered // deactivate hover for the moment to avoid issues
  });

  return (
    <button className={className}
      tabIndex={0}
      ref={multiRef(tooltipState.anchorRef, panelState.anchorRef)}
      {...overlayGroupState.anchorProps}>

      <div className="icon">
        {model.playbackRateItem?.iconLabel}
      </div>

      <Overlay model={tooltipState}
        className="user-player-tooltip">
        Playback Speed
      </Overlay>

      <Overlay model={panelState}
        className="control-panel speed-btn-panel">

        <ul className="speed-btn-panel-list"
          role="listbox">

          {model.playbackRateOptions.map(opt =>
            <li key={opt.rate}
              className="speed-btn-panel-item"
              role="option"
              aria-selected={model.playbackRate === opt.rate}
              tabIndex={0}
              onClick={action(evt => {
                panelState.hide();
                tooltipState.hide();
                model.invoke('setPlaybackRate', { rate: opt.rate });
              })}>
              <span className="value">
                {opt.label}
              </span>
              <span className="check">
                <CheckmarkSVGIcon />
              </span>
            </li>
          )}
        </ul>
      </Overlay>
    </button>
  );
});