import { action, makeObservable, observable } from 'mobx';
import { Result } from '../../core';
import { Error } from '../../core/error';
import { StoreNode } from '../../store';
import { Store } from '../../store/store';

/**
 * Manages low-level network related tasks.
 */
export class NetworkService
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.init();
  }

  @observable isOnline = true;

  async init() {

    window.addEventListener('offline',
      this.handleOffline);
    window.addEventListener('online',
      this.handleOnline);
  }

  @action
  dispose() {

    window.removeEventListener('offline',
      this.handleOffline);
    window.removeEventListener('online',
      this.handleOnline);

    this.isDisposed = true;
  }

  sendBeacon(url: string, data?: Record<string, any>): Result<boolean, Error> {

    try {

      const res = navigator.sendBeacon(url, JSON.stringify(data));
      if (res)
        return [true];
      return [null, new Error('Unknown', `Cannot send beacon because of unknown error.`)];

    } catch (e) {
      const err = new Error('NetworkError', `Cannot send beacon. The error is: ${(e as any).message}`);
      return [null, err];
    }
  }

  private handleOnline = action(() => {
    this.isOnline = true;
  })

  private handleOffline = action(() => {
    this.isOnline = false;
  })
}