import { observer } from 'mobx-react-lite';
import React from 'react';
import { CheckboxInputControlled, TextInputControlled } from '../input';
import { TeamMetadataItemState } from './teamMetadataItemState';

type TeamMetadataItem = {
  model: TeamMetadataItemState
}

// eslint-disable-next-line  no-redeclare
export const TeamMetadataItem = observer(({ model }: TeamMetadataItem) => (
  <div className="team-metadata-item">
    <CheckboxInputControlled model={model.isActive} />
    <TextInputControlled
      statusMessage={
        model.fieldAlias.status === 'error' &&
          model.fieldAlias.showStatusIcons
          ? model.fieldAlias.error?.toString()
          : 'Max 25ch.'
      }
      model={model.fieldAlias} />
  </div>
));