import { INIT_DEBUGGER, TRACE } from '../../core/debug/debugMacros';

import { action, computed, makeObservable, observable, when } from 'mobx';
import findKey from 'lodash/findKey';

import { Store } from '../../store/store';
import { BindingProps, RefProxy, StoreNode } from '../../store';
import { IPlayerAdapter } from '../player/playerSchema';
import { Error } from '../../core/error';
import { isGoogleImaAdErrorEvent } from '../../vendor/google-ima-sdk';
import { AsyncResult, createResizeObserver, isDefinedObject, isFiniteNumber, isNonEmptyString, Result } from '../../core';
import { PlayerAdsAdapterInvokeType, PlayerAdsAdapterMessageType } from './playerAdsAdapterSchema';
import { safe } from '../../core/safe';

// shortcuts to avoid having to write the namespace all the time

type AdsRenderingSettings = google.ima.AdsRenderingSettings;
type AdsManager = google.ima.AdsManager;
type AdsLoader = google.ima.AdsLoader;
type AdDisplayContainer = google.ima.AdDisplayContainer;
type Ad = google.ima.Ad;
type AdPodInfo = google.ima.AdPodInfo;
type AdError = google.ima.AdError;
type AdErrorEvent = google.ima.AdErrorEvent;
type AdErrorEventListener = google.ima.AdErrorEvent.Listener;
type AdEvent = google.ima.AdEvent;
type AdEventListener = google.ima.AdEvent.Listener;
type AdsRequest = google.ima.AdsRequest;

export enum AdPodCueType {
  PreRoll = 'PreRoll',
  MidRoll = 'MidRoll',
  PostRoll = 'PostRoll'
}

type Props = BindingProps<{
  contentVideoElement?: HTMLElement | null;
}>

/**
 * Backing state for `PlayerAdsAdapter`.
 */
export class PlayerAdsAdapterState
  extends StoreNode
  implements IPlayerAdapter {

  readonly nodeType: 'PlayerAdsAdapter' = 'PlayerAdsAdapter';

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    INIT_DEBUGGER(this, {
      color: 'salmon',
      enableTrace: false
    });
  }

  /** RefProxy for the root HTML element of the component. */
  readonly elementRef = new RefProxy<HTMLDivElement>(this.store);

  /** Root HTML element of the component. */
  @computed
  get element(): HTMLDivElement | null {
    return this.elementRef.node;
  }

  private elementResizeObserver: ResizeObserver | null = null;

  @computed
  get videoElement(): HTMLVideoElement | null {
    return null;
  }

  @computed
  get contentVideoElement(): HTMLVideoElement | null {
    return this.resolvedProps.contentVideoElement ?? null;
  }

  @observable duration: number = 0;
  @computed get hasDuration() {
    return (Number.isFinite(this.duration) && this.duration > 0);
  }

  @observable error: MediaError | null = null;

  @observable autoplay: boolean = false;
  @observable autoplayAfterRetry: boolean = false;
  @observable currentTime: number = 0;
  @observable bufferedTime: number = 0;
  @observable volume: number = 0;
  @observable isMuted: boolean = false;
  @observable playbackRate: number = 1.0;

  @observable isPaused = false;
  @observable isSeeking: boolean = false;
  @observable isReady: boolean = false;
  @observable isWaiting: boolean = false;
  @observable isMetadataLoaded: boolean = false;
  @observable isPlaying: boolean = false;
  @observable canPlay: boolean = false;
  @observable canPlayThrough: boolean = false;

  @observable initialCurrentTime: number | null = null;
  @observable initialVolume: number | null = null;
  @observable initialAutoplay: boolean | null = null;
  @observable initialPlaybackRate: number | null = null;
  @observable initialIsPlaying: boolean | null = null;

  // TEMP to implement the IPlayerAdapter interface
  reattachDOMVideo() { };
  readonly initPtsInSec = 0;
  readonly isLiveStream = false;

  @observable isMounted = false;

  @observable isLoading = false;
  @observable isLoaded = false;
  @observable isBreakRequested = false;

  @observable isStarting = false;
  @observable isStarted = false;

  @observable adTagUrl: string | null = null;

  @observable isActive = false;

  get isLoadAborted(): boolean {
    // not @computed because we're accessing a non-observable native field
    // of an observable member of our class, which is the perfect recipe for bugs
    return !!this.loadAbortController?.signal.aborted;
  }

  @observable loadAbortController: AbortController | null = null;

  @computed get hasAd(): boolean {
    return !!this.ad;
  }

  private adDisplayContainer: AdDisplayContainer | null = null;
  private adsLoader: AdsLoader | null = null;
  private adsManager: AdsManager | null = null;
  private adsRequest: AdsRequest | null = null;
  private adsRenderingSettings: AdsRenderingSettings | null = null;

  @computed get isSkippable() {
    return isFiniteNumber(this.canSkipAfter);
  }

  @observable canSkip = false;
  @observable canSkipAfter: number | null = null;

  @observable ad: Ad | null = null;
  @computed get adIndex(): number | null {
    if (!this.ad || !this.adPod)
      return null;

    const position = safe(() => this.adPod?.getAdPosition());
    if (isFiniteNumber(position))
      return position - 1; // convert to 0 based index
    return null;
  }

  @computed get adPod(): AdPodInfo | null {
    return safe(() => this.ad?.getAdPodInfo()) ?? null;
  }
  @computed get adPodSize(): number | null {
    return safe(() => this.adPod?.getTotalAds()) ?? null;
  }
  @computed get adPodIndex(): number | null {
    return safe(() => this.adPod?.getPodIndex()) ?? null;
  }

  @computed
  get adPodCueType(): AdPodCueType | null {
    const index = this.adPodIndex;
    switch (index) {
      case 0: return AdPodCueType.PreRoll;
      case -1: return AdPodCueType.PostRoll; // google's majestic SDK coding standards
      default:
        if (isFiniteNumber(index) && index > 0)
          return AdPodCueType.MidRoll;
    }
    return null;
  }

  @observable remainingTime: number | null = null;

  @computed
  private get canLoad() {
    return (
      this.isMounted &&
      isDefinedObject(this.element));
  }

  @observable adWillAutoplay: boolean = false;
  @observable adWillAutoplayMuted: boolean = false;

  @action
  async mounted() {
    TRACE(this, 'Mounted');
    this.isMounted = true;
  }

  @action
  unmounted() {
    TRACE(this, 'Unmounted');
    this.isMounted = false;
    this.reset();
  }

  @action
  private syncState(evt: AdEvent) {

    const { adsManager } = this;

    const ad = safe(() => evt.getAd());
    const adData = safe(() => evt.getAdData()) ?? {};

    if (ad) {
      this.ad = ad;

      const canSkipAfter = safe(() => ad.getSkipTimeOffset());

      if (isFiniteNumber(canSkipAfter)) {
        if (canSkipAfter > 0)
          this.canSkipAfter = canSkipAfter;
        else
          this.canSkipAfter = null;
      }
    }

    const canSkip = safe(() => adsManager?.getAdSkippableState());
    if (typeof canSkip === 'boolean')
      this.canSkip = canSkip;

    const volume = safe(() => adsManager?.getVolume());
    if (isFiniteNumber(volume))
      this.volume = volume;

    const remainingTime = safe(() => adsManager?.getRemainingTime());
    if (isFiniteNumber(remainingTime))
      this.remainingTime = remainingTime;

    if (isDefinedObject(adData)) {

      const { duration } = adData;
      if (isFiniteNumber(duration))
        this.duration = duration;

      const { currentTime } = adData;
      if (isFiniteNumber(currentTime))
        this.currentTime = currentTime;
    }
  }

  private handleAdLogEvent: AdEventListener = async (evt: AdEvent) => {

    const ErrorType = google.ima.AdError.Type;
    const ErrorCode = google.ima.AdError.ErrorCode;

    const adData = safe(() => evt.getAdData()) ?? {};

    // see https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/reference/js/google.ima.AdEvent#getAdData
    const adErr: AdError | null = adData.adError ?? null;
    if (adErr) {

      const adErrType = adErr.getType();
      const adErrCode = adErr.getErrorCode();

      // TODO: continue
      switch (adErrType) {
        case ErrorType.AD_PLAY:
          switch (adErrCode) {
            case ErrorCode.VIDEO_PLAY_ERROR:
              break;

          }

          break;

        case ErrorType.AD_LOAD:
          break;
      }

      const { adTagUrl } = this;
      this.reset();
      if (adTagUrl)
        this.setAdTagUrl(adTagUrl);
      await this.load();
    }
  }

  @action
  private handleAdEvent: AdEventListener = (evt: AdEvent) => {

    const { Type } = google.ima.AdEvent;

    const ad = safe(() => evt.getAd());
    const adData = safe(() => evt.getAdData()) ?? {};

    TRACE(this, 'AdEvent', findKey(google.ima.AdEvent.Type, (val) => evt.type === val), evt.type, adData, ad);

    switch (evt.type) {
      case Type.LOG:
        // we exit early because the syncState will mess things up
        this.handleAdLogEvent(evt);
        return;

      case Type.CONTENT_PAUSE_REQUESTED:
        this.isBreakRequested = true;
        this.isActive = true;
        this.emit(PlayerAdsAdapterMessageType.RequestBreak);
        break;

      case Type.CONTENT_RESUME_REQUESTED:
        this.isBreakRequested = false;
        this.isActive = false;
        this.ad = null;
        this.emit(PlayerAdsAdapterMessageType.RequestResume);
        break;

      case Type.DURATION_CHANGE:
        break;

      case Type.PAUSED:
        this.isPlaying = false;
        break;

      case Type.RESUMED:
        this.isPlaying = true;
        this.isWaiting = false;
        break;

      case Type.SKIPPED:
        break;

      case Type.AD_PROGRESS:
        this.isWaiting = false;
        break;

      case Type.AD_BUFFERING:
        this.isWaiting = true;
        break;

      case Type.STARTED:
        this.isStarted = true;
        this.isPlaying = true;
        this.isWaiting = false;
        break;

      case Type.COMPLETE:
        this.isPlaying = false;
        this.isWaiting = false;
        break;

      case Type.ALL_ADS_COMPLETED:
        this.isPlaying = false;
        this.isWaiting = false;
        break;

      case Type.VOLUME_CHANGED:
        return;

      case Type.VOLUME_MUTED:
        return;
    }

    this.syncState(evt);
  }

  private handleAdErrorEvent: AdErrorEventListener = (evt: AdErrorEvent) => {

    const adError = evt.getError();
    const userRequestContext = evt.getUserRequestContext();

    const err = new Error('VendorError', `An error occurred during ad playback: ${adError.message}`, {
      innerError: adError,
      data: {
        adError: adError,
        adErrorEvent: evt,
        userRequestContext
      }
    });

    return this.handleLoadError(err);
  }

  @action
  start(): Result<boolean, Error> {

    TRACE(this, `start()`);

    const { adDisplayContainer, adsManager } = this;
    if (!adDisplayContainer || !adsManager || !this.isLoaded)
      return [null, new Error('InvalidComponentState', `Cannot start because the object has not been loaded properly.`)];

    if (this.isStarting || this.isStarted)
      return [null, new Error('CommandAlreadyRequested', `start() has already been called`)];

    this.isStarting = true;
    this.isWaiting = true;
    this.isActive = true;

    try {

      TRACE(this, `Initializing AdDisplayContainer`);
      // Initialize the container. Must be done via a user action on mobile devices.
      // videoContent.load();
      adDisplayContainer.initialize();

      TRACE(this, `Initializing AdsManager`);
      // the fixed size of the video has to be by far the most idiotic thing in this SDK
      // see https://groups.google.com/g/ima-sdk/c/ewQ_aTAAUSE
      // you can see the wickedness of one of the SDK devs responding that the size can be adjusted via a call to `resize`
      // HAVE YOU EVER HEARD OF CSS???
      const width = this.element?.offsetWidth!;
      const height = this.element?.offsetHeight!;

      adsManager.init(width, height, google.ima.ViewMode.NORMAL);

      // Call play to start showing the ad. Single video and overlay ads will
      // start at this time; the call will be ignored for ad rules.

      TRACE(this, `Playing ad`);
      adsManager.start();

    } catch (adError) {

      const err = new Error('VendorError', `An error occured while trying to start ad playback`, {
        innerError: adError
      });

      return this.handleStartError(err);
    }

    return [true];
  }

  @action
  private handleStartError(err: Error): Result<boolean, Error> {
    this.reset();
    return [null, err];
  }

  @action
  async load(): AsyncResult<boolean, Error> {

    TRACE(this, `load()`);

    if (this.isLoading) {
      TRACE(this, `The load request was rejected because it was already requested before and not completed`);
      // we don't use `handleLoadError` here because we don't want to affect the original (and valid) `load` call
      return [null, new Error('CommandAlreadyRequested')];
    }

    if (!this.adTagUrl)
      return this.handleLoadError(new Error('InvalidComponentProps', `Cannot load because no 'adTagUrl' has been provided.`));

    const abortCtrl = new AbortController();

    // set state
    this.isLoading = true;
    this.isLoaded = false;
    this.isWaiting = true;
    this.isActive = true;

    this.loadAbortController = abortCtrl;

    if (!this.canLoad) {

      TRACE(this, `Not ready for loading yet. Waiting...`);

      const canLoadPromise = when(() => this.canLoad);
      abortCtrl.signal.addEventListener('abort', () => {
        canLoadPromise.cancel();
      });

      // TODO: probably when aborted it will reject, causing an error
      await canLoadPromise;

      TRACE(this, `Ready to load`);
    }

    TRACE(this, `Loading`);

    type Task = () => AsyncResult;

    const tasks: Task[] = [
      () => this.initGoogleImaSdk(),
      () => this.initAdDisplayContainer(),
      () => this.initAdsLoader(),
      () => this.initAdsRenderingSettings(),
      () => this.initAdsRequest(),
      () => this.initAdsManager()
    ];

    // we need to run an aborted check before and after each async operation
    // we can run one check before the iteration starts and one check after each iteration
    if (this.isLoadAborted)
      return this.handleLoadAborted();

    for (const helper of tasks) {
      // errors returned by each helper should already be handled using `handleInitError`,
      // so no need to call that again
      const [, err] = await helper();
      if (err)
        return [null, err];

      if (this.isLoadAborted)
        return this.handleLoadAborted();
    }

    TRACE(this, `All SDK objects created successfully, set them on the current state.`);

    return this.handleLoadSuccess();
  }

  @action
  private handleLoadSuccess(): Result<boolean> {
    const res: Result = [true];

    this.loadAbortController = null;

    this.isLoading = false;
    this.isLoaded = true;
    this.isWaiting = false;
    this.isActive = false;

    return res;
  }

  @action
  private handleLoadError(err: Error): Result<any, Error> {
    this.reset();
    return [null, err];
  }

  @action
  private handleLoadAborted(): Result<any, Error> {
    const err = new Error('Aborted', `The operation was aborted.`);
    this.loadAbortController = null;
    return this.handleLoadError(err);
  }

  @action
  private handleLoadInvalidState(): Result<any, Error> {
    const err = new Error('InvalidComponentState', `The required objects have not been initialized properly.`);
    return this.handleLoadError(err);
  }

  // #region SDK helpers
  private async initGoogleImaSdk(): AsyncResult {
    TRACE(this, `Initializing Google IMA SDK`);

    TRACE(this, `Loading VendorService`);

    const vendorService = await this.store.vendorService;

    if (this.isLoadAborted)
      return this.handleLoadAborted();

    TRACE(this, `Loading Google IMA SDK using VendorService`);

    const [googleIma, googleImaErr] = await vendorService.googleImaSdk.load();
    if (googleImaErr)
      return this.handleLoadError(googleImaErr);

    return [googleIma];
  }
  // #endregion

  // #region AdDisplayContainer
  private async initAdDisplayContainer(): AsyncResult<AdDisplayContainer, Error> {
    TRACE(this, `Initializing AdDisplayContainer`);

    const { element } = this;
    if (!element)
      return this.handleLoadError(new Error('InvalidComponentProps', `A valid container element has not been provided`));

    TRACE(this, `Creating ResizeObserver to make up for the unacceptable lack of CSS resize support in Google IMA SDK`);

    const resizeObserver = createResizeObserver(() => {
      const { element } = this;
      if (!element)
        return;

      const width = element.offsetWidth;
      const height = element.offsetHeight;

      if (!Number.isFinite(width) || !Number.isFinite(height))
        return;

      this.adsManager?.resize(width, height, google.ima.ViewMode.NORMAL);
    });

    this.elementResizeObserver = resizeObserver;

    resizeObserver?.observe(element);

    // create the ad display container
    TRACE(this, `Creating AdDisplayContainer using container element: `, element);

    const adDisplayContainer = new google.ima.AdDisplayContainer(element);
    this.adDisplayContainer = adDisplayContainer;

    return [adDisplayContainer];
  }

  private destroyAdDisplayContainer() {
    this.adDisplayContainer?.destroy();
    this.adDisplayContainer = null;

    this.elementResizeObserver?.disconnect();
    this.elementResizeObserver = null;
  }

  // #endregion

  // #region AdsLoader
  private async initAdsLoader(): AsyncResult<AdsLoader> {
    TRACE(this, `Initializing AdsLoader`);

    const { adDisplayContainer } = this;
    if (!adDisplayContainer)
      return this.handleLoadInvalidState();

    const adsLoader = new google.ima.AdsLoader(adDisplayContainer);
    this.adsLoader = adsLoader;

    return [adsLoader];
  }

  private destroyAdsLoader(): void {
    this.adsLoader?.destroy();
    this.adsLoader = null;
  }
  // #endregion

  // #region AdsRenderingSettings
  private async initAdsRenderingSettings(): AsyncResult<AdsRenderingSettings> {
    TRACE(this, `Initializing AdsRenderingSettings`);

    const adsRenderingSettings = new google.ima.AdsRenderingSettings();
    adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;
    adsRenderingSettings.useStyledLinearAds = false;
    adsRenderingSettings.useStyledNonLinearAds = false;
    adsRenderingSettings.uiElements = [];

    this.adsRenderingSettings = adsRenderingSettings;

    return [adsRenderingSettings];
  }

  private destroyAdsRenderingSettings() {
    this.adsRenderingSettings = null;
  }
  // #endregion

  // #region AdsManager
  private async initAdsManager(): AsyncResult<google.ima.AdsManager> {
    TRACE(this, `Initializing AdsManager`);

    const { adsRenderingSettings, adsLoader } = this;
    if (!adsRenderingSettings || !adsLoader)
      return this.handleLoadError(new Error('InvalidComponentState', `The required objects have not been initialized properly.`));

    // cheap trick to wait for the event handler to be invoked inline
    // because some google SDK devs haven't heard of Promises yet
    const adsManagerPromise = this.createAdsManagerPromise(adsLoader);

    let adsManagerLoadedEvt: google.ima.AdsManagerLoadedEvent;
    try {
      adsManagerLoadedEvt = await adsManagerPromise;
    } catch (err) {
      return this.handleInitAdsManagerError(err);
    }

    // aborted check after awaiting AdsManager async creation
    if (this.isLoadAborted)
      return this.handleLoadAborted();

    if (!adsManagerLoadedEvt)
      return this.handleLoadError(new Error('InternalError', `Expected a valid instance of google.ima.AdsManagerLoadedEvent`));

    const adsManager =
      adsManagerLoadedEvt.getAdsManager(this.contentVideoElement!, adsRenderingSettings);
    this.adsManager = adsManager;

    this.bindAdsManagerEventHandlers();

    return [adsManager];
  }

  private destroyAdsManager(): void {
    this.unbindAdsManagerEventHandlers();

    this.adsManager?.destroy();
    this.adsManager = null;
  }

  /** Returns a Promise which resolves when an AdsManager is loaded (or not) from Google IMA SDK. */
  private createAdsManagerPromise(adsLoader: google.ima.AdsLoader) {

    return new Promise<google.ima.AdsManagerLoadedEvent>((res, rej) => {

      adsLoader.addEventListener(
        google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
        (evt) => res(evt), false);

      adsLoader.addEventListener(
        google.ima.AdErrorEvent.Type.AD_ERROR,
        evt => rej(evt), false);
    });
  }

  private handleInitAdsManagerError(errEvt: any): Result {

    if (isGoogleImaAdErrorEvent(errEvt)) {

      const adErrorEvt = errEvt as AdErrorEvent;
      const adError = adErrorEvt.getError();
      const userRequestContext = adErrorEvt.getUserRequestContext();

      const err = new Error('VendorError', `Google IMA SDK returned an error while creating google.ima.AdsManager instance: ${adError?.getMessage()}`, {
        source: this,
        innerError: adError,
        data: {
          adError: adError,
          adErrorEvent: adErrorEvt,
          userRequestContext
        }
      });

      return this.handleLoadError(err);

    } else {
      const intErr = errEvt as any;
      const err = new Error('InternalError', `An unexpected error occured while creating google.ima.AdsManager instance: ${intErr?.message ?? intErr}`, {
        source: this,
        innerError: intErr
      });

      return this.handleLoadError(err);
    }
  }

  private bindAdsManagerEventHandlers(): void {
    const { adsManager } = this;
    if (!adsManager)
      return;

    // bind handlers for all the events possible, because we can
    Object.values(google.ima.AdEvent.Type).forEach(evtType =>
      adsManager.addEventListener(evtType, this.handleAdEvent));

    // also bind handler for the error events
    adsManager.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, (evt) => this.handleAdErrorEvent);
  }

  private unbindAdsManagerEventHandlers(): void {
    const { adsManager } = this;
    if (!adsManager)
      return;

    // unbind handlers for all the events possible, because we bound them initially because we could
    Object.values(google.ima.AdEvent.Type).forEach(evtType =>
      adsManager.addEventListener(evtType, this.handleAdEvent));

    // also unbind handler for the error events
    adsManager.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, (evt) => this.handleAdErrorEvent);
  }
  // #endregion

  // #region AdsRequest
  private async initAdsRequest(): AsyncResult {
    TRACE(this, `Initializing AdsRequest`);

    const { adTagUrl } = this;
    if (!isNonEmptyString(adTagUrl))
      return this.handleLoadError(new Error('InvalidComponentState', `No request URL has been set.`));

    const { adsLoader } = this;
    if (!adsLoader)
      return this.handleLoadError(new Error('InvalidComponentState', `AdsLoader has not been initialized.`));

    const adsRequest = new google.ima.AdsRequest();
    this.adsRequest = adsRequest;

    adsRequest.setAdWillAutoPlay(this.adWillAutoplay);
    adsRequest.setAdWillPlayMuted(this.adWillAutoplayMuted);
    adsRequest.adTagUrl = adTagUrl;

    // Specify the linear and nonlinear slot sizes. This helps the SDK to
    // select the correct creative if multiple are returned.

    adsRequest.linearAdSlotWidth = 640;
    adsRequest.linearAdSlotHeight = 400;

    adsRequest.nonLinearAdSlotWidth = 640;
    adsRequest.nonLinearAdSlotHeight = 150;

    adsLoader.requestAds(adsRequest);

    return [adsRequest];
  }

  private destroyAdsRequest() {
    this.adsRequest = null;
  }
  // #endregion

  destroy() {
    TRACE(this, 'Destroying');

    this.destroyAdDisplayContainer();
    this.destroyAdsLoader();
    this.destroyAdsRenderingSettings();
    this.destroyAdsManager();
    this.destroyAdsRequest();

    TRACE(this, 'Destroyed');
  }

  abort() {
    TRACE(this, 'Aborting');

    if (this.isLoading)
      this.loadAbortController?.abort();
    this.destroy();
  }


  signalVideoEnded(): boolean {
    const { adsLoader } = this;
    if (!adsLoader)
      return false;

    adsLoader.contentComplete();
    return true;
  }

  @action
  invoke = async (type: PlayerAdsAdapterInvokeType, payload: any = {}) => {

    const {
      adsManager
    } = this;

    TRACE(this, `Invoke`, type, payload);

    switch (type) {
      case PlayerAdsAdapterInvokeType.Skip:
        this.adsManager?.skip();
        break;

      case PlayerAdsAdapterInvokeType.Play:
        if (!adsManager) {
          this.initialIsPlaying = true;
          return;
        }
        adsManager.resume();
        break;

      case PlayerAdsAdapterInvokeType.Pause:
        if (!adsManager) {
          this.initialIsPlaying = false;
          return;
        }
        adsManager.pause();
        break;

      case PlayerAdsAdapterInvokeType.SetVolume:
        const { volume } = payload;
        if (!adsManager) {
          this.initialVolume = volume;
          return;
        }
        // THIS IS REALLY SLOW AND IT WILL CRASH EVERYTHING, DON'T USE IT!!!!!!!!!!!!!!!!!!!!!
        // ASK THE ALMOST 2 TRILLION DOLLARS COMPANY ALPHABET INC. WHY THIS IS HAPPENING
        adsManager.setVolume(volume);
        break;
    }

    // this.syncState();
  }

  @action
  reset() {

    TRACE(this, 'Resetting');

    this.abort();

    this.isLoading = false;
    this.isLoaded = false;
    this.isActive = false;

    this.isStarting = false;
    this.isStarted = false;

    this.remainingTime = null;
    this.ad = null;
    this.canSkipAfter = null;
    this.canSkip = false;
    this.adTagUrl = null;

    this.currentTime = 0;
    this.playbackRate = 1;
    this.volume = 1;

    this.initialCurrentTime = 0;
  }

  @action
  setAdTagUrl(url: string) {
    this.adTagUrl = url;
  }

  @action
  setAdWillAutoplay(val: boolean) {
    this.adWillAutoplay = val;
  }

  @action
  setAdWillAutoplayMuted(val: boolean) {
    this.adWillAutoplayMuted = val;
  }
}