import './momentSplash.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { MomentSplashState } from './momentSplashState';
import { TopicSelectorContent } from './topicSelectorContent';
import { SpeakerSelectorContent } from './speakerSelectorContent';

type Props = {
  model: MomentSplashState
}

export const MomentSplash = observer(({
  model
}: Props) => {

  return (
    <div className="moment-splash">
      {(model.selector?.mode === 'Topics' && model.selector.hasTopics) &&
        <TopicSelectorContent model={model} />}

      {(model.selector?.mode === 'Speakers' && model.selector.hasSpeakers) &&
        <SpeakerSelectorContent model={model} />}

      <div className="comp-overlays">

        <div className="comp-selector">
          <button className="select-all-btn select-btn"
            onClick={() => model.selectAll()}>
            Select all
          </button>

          <button className="clear-selection-btn select-btn"
            onClick={() => model.unselectAll()}>
            Clear selection
          </button>
        </div>
      </div>
    </div>
  );
});