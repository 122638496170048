import { action, computed, makeObservable, observable, ObservableMap } from 'mobx';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { IPlayerAdapter } from '../player/playerSchema';
import { HlsAudioTrack } from 'hls.js';
import { PlayerAdapterState } from './playerAdapterState';

type Props = BindingProps<{
  adapter: IPlayerAdapter
}>

enum AudioTracksStatus {
  Active = 'active',
  Switching = 'switching',
  // Loading = 'loading',
  // Loaded = 'loaded',
  Error = 'error'
}

export class PlayerAudioTracksState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  @computed get adapter(): PlayerAdapterState {
    return this.getResolvedProp('adapter');
  }

  readonly audioTracksMap: ObservableMap<string, HlsAudioTrack> =
    observable.map<string, HlsAudioTrack>();

  @computed get audioTracks(): HlsAudioTrack[] {
    return [...this.audioTracksMap.values()];
  }

  @computed get hasMultipleAudioTracks(): boolean {
    return this.audioTracks.length > 1;
  }

  @observable activeAudioTrackId: string | null = null; // hls active

  @observable selectedAudioTrackId: string | null = null; // ui selected

  @observable trackStatus: AudioTracksStatus | null = null;

  @computed get activeAudioTrack(): HlsAudioTrack | null {
    if (this.audioTracks.length === 0 || !this.activeAudioTrackId)
      return null;

    return this.getTrack(this.activeAudioTrackId) ?? null;
  }

  @computed get selectedAudioTrack(): HlsAudioTrack | null {
    if (this.audioTracks.length === 0 || !this.selectedAudioTrackId)
      return null;

    return this.getTrack(this.selectedAudioTrackId) ?? null;
  }

  @computed get isTrackActive(): boolean {
    return this.trackStatus === AudioTracksStatus.Active;
  }

  // non-observable properties section
  get activeHlsAudioTrackId(): number | null {
    return this.adapter.hls?.audioTrack ?? null;
  }
  get activeHlsAudioTracks(): HlsAudioTrack[] {
    return this.adapter.hls?.audioTracks ?? [];
  }
  // end section

  @action
  setTrackStatus(status: AudioTracksStatus | null) {
    this.trackStatus = status;
  }

  @action
  setSwitching() {
    this.setTrackStatus(AudioTracksStatus.Switching);
  }

  @action
  setActive() {
    this.setTrackStatus(AudioTracksStatus.Active);
  }

  @action
  setError() {
    this.setTrackStatus(AudioTracksStatus.Error);
  }

  @action
  refreshStatus() {
    this.setTrackStatus(null);
  }

  @action
  switchTrack = (track: HlsAudioTrack) => {
    const trackId = track.id?.toString() ?? null;
    if (!this.isTrackActive || this.selectedAudioTrackId === trackId) // if the audio track is still loading the action will be canceled to avoid potential issues 
      return;

    this.adapter.setHlsAudioTrack(track);
    this.setSelectedTrack(trackId);
  }

  getTrack(id: string) {
    return this.audioTracks.find(track => track.id.toString() === id);
  }

  @action
  private setActiveTrack(id: string | null) {
    this.activeAudioTrackId = id;
    this.selectedAudioTrackId = id;
    if (!id) {
      this.refreshStatus();
      return;
    }
    this.setActive();
  }

  @action
  setSelectedTrack(id: string | null) {
    this.selectedAudioTrackId = id;
    if (!id) {
      this.refreshStatus();
      return;
    }
    this.setSwitching();
  }

  @action
  private replaceTracks(tracks: HlsAudioTrack[]) {
    this.audioTracksMap.replace(tracks.map(track => [track.id, track]));
  }

  @action
  refreshAudioTracks() {
    this.replaceTracks(this.activeHlsAudioTracks);
  }

  @action
  refreshActiveAudioTrack() {
    this.setActiveTrack(this.activeHlsAudioTrackId?.toString() ?? null);
  }

  @action
  reset() {
    // audio tracks
    this.audioTracksMap.clear();
    this.activeAudioTrackId = null;
    this.selectedAudioTrackId = null;
    this.trackStatus = null;
  }

}