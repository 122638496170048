import { computed, makeObservable } from 'mobx';
import { BookmarkList as ApiBookmarkList, ResizedImage } from '@clipr/lib';
import { assertNotNull, Maybe, pluralize } from '../core';
import { Bookmark, BookmarkTarget } from './bookmark';
import { Store } from '../store/store';
import { StoreNode } from '../store';
import { MomentModel } from './moment';
import { JobModel } from './job';
import { DateTime } from 'luxon';

export type BookmarkListProps = ApiBookmarkList;

export class BookmarkList
  extends StoreNode {

  constructor(props: BookmarkListProps, store: Store) {
    super(store);
    makeObservable(this);

    Object.assign(this, props);
    this.thumbnails = props.thumbnails || [];
  }

  readonly nodeType: 'BookmarkList' = 'BookmarkList';

  readonly id!: string;
  readonly name!: string;
  readonly createdAt!: string;
  readonly bookmarkCount: number = 0;

  readonly thumbnails: Maybe<ResizedImage[]>[] = [];

  @computed
  get thumbnailUrlList() {
    return this.thumbnails
      .map(item => (item && item[0].url) || '')
      .filter(item => item!) as string[];
  }

  @computed
  get createdAtDate(): DateTime {
    return DateTime.fromISO(this.createdAt);
  }

  @computed
  get createdAtTimestamp(): number {
    return +this.createdAtDate;
  }

  @computed
  get bookmarks(): Bookmark[] {
    return this.store.bookmarks
      .filter(bm => bm.listId === this.id);
  }

  @computed
  get bookmarkCountLabel() {
    return pluralize(this.bookmarkCount, 'Bookmark', 'Bookmarks', 'No Bookmarks added yet.');
  }

  hasMomentId(momId: Maybe<string>) {
    return this.bookmarks.find(bkm => bkm.momentId === momId);
  }

  hasMoment(mom: MomentModel) {
    return this.bookmarks.find(bkm => bkm.momentId === mom.id);
  }


  hasJobId(jobId: Maybe<string>) {
    return this.bookmarks.find(bkm => bkm.jobId === jobId);
  }
  hasJob(mom: JobModel) {
    return this.bookmarks.find(bkm => bkm.jobId === mom.id);
  }

  hasTargetId(targetId: Maybe<string>) {
    return this.bookmarks.some(bkm => bkm.targetId === targetId);
  }
  hasTarget(target: Maybe<BookmarkTarget>) {
    return this.bookmarks.some(bkm => bkm.target === target);
  }

  /** 
   * Gets the Bookmark associated with the provided target. 
   * This will throw if no Bookmark is associated with the target.
   * Use 'hasTarget' before calling this method to check.
   */
  getBookmarkFor(targetId: string): Bookmark {
    let bkm = this.bookmarks.find(bkm => bkm.targetId === targetId);
    assertNotNull(bkm);
    return bkm;
  }
}