/**
 * Wrapper around `Number.isFinite` plus the useful type guard.
 */
export function isFiniteNumber(arg: any): arg is number {
  return Number.isFinite(arg);
}

/**
 * Wrapper around `Number.isInteger` plus the useful type guard.
 */
export function isInteger(arg: any): arg is number {
  return Number.isInteger(arg);
}

/**
 * Shortcut for `isFiniteNumber` plus checking for non-zero argument value.
 * Useful when the argument must be used as a denominator.
 */
export function isNonZeroFiniteNumber(arg: any): arg is number {
  return isFiniteNumber(arg) && arg !== 0;
}

export function certifyNumber(arg: any): asserts arg is number { }