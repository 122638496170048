import * as React from "react";
import classNames from 'classnames';

export const LoveBloomSVGIcon = ({className, ...props}: React.SVGProps<SVGSVGElement>) =>
  <svg
    viewBox="0 0 251 239"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames("love-bloom-svg-icon", "svg-icon", className)}
    {...props}> 
      <style>
        {
          "\n    .main-bloom {\n      transform: scale(0);\n      transform-origin: center center;\n      animation: pulse 2s 1 alternate;\n    }\n\n    .bg-bloom {\n      transform: scale(0);\n      transform-origin: center center;\n      animation: pulse2 1.6s 1 alternate-reverse;\n      opacity: 0;\n    }\n\n    @keyframes pulse {\n      60% {\n        transform: scale(1);\n        opacity: 1;\n      }\n\n      100% {\n        transform: scale(0);\n        opacity: 0;\n      }\n    }\n\n    @keyframes pulse2 {\n      60% {\n        transform: scale(1);\n        opacity: 1;\n      }\n\n      80% {\n        opacity: 0;\n      }\n\n      100% {\n        transform: scale(0);\n        opacity: 0;\n      }\n    }\n  "
        }
      </style>
      <g className="main-bloom">
        <path
          d="M184.932 119.239C184.932 152.206 158.206 178.932 125.239 178.932C92.2708 178.932 65.5452 152.206 65.5452 119.239C65.5452 86.2708 92.2708 59.5452 125.239 59.5452C158.206 59.5452 184.932 86.2708 184.932 119.239Z"
          fill="#DD2E44"
          fillOpacity={0.1}
          stroke="white"
        />
        <path
          d="M125.239 169.4C152.942 169.4 175.4 146.942 175.4 119.239C175.4 91.5353 152.942 69.0774 125.239 69.0774C97.5353 69.0774 75.0774 91.5353 75.0774 119.239C75.0774 146.942 97.5353 169.4 125.239 169.4Z"
          fill="#DD2E44"
          fillOpacity={0.15}
        />
        <path
          d="M125.716 161.374C148.986 161.374 167.851 142.509 167.851 119.239C167.851 95.9679 148.986 77.1033 125.716 77.1033C102.445 77.1033 83.5803 95.9679 83.5803 119.239C83.5803 142.509 102.445 161.374 125.716 161.374Z"
          fill="#DD2E44"
          fillOpacity={0.2}
        />
        <g filter="url(#filter0_d_11596:86090)">
          <path
            d="M153.329 108.796C153.329 100.157 146.266 93.1548 137.555 93.1548C132.267 93.1548 127.6 95.7431 124.737 99.6993C121.874 95.7431 117.207 93.1548 111.921 93.1548C103.208 93.1548 96.145 100.156 96.145 108.796C96.145 110.019 96.3017 111.205 96.5703 112.348C98.7604 125.841 113.892 140.076 124.737 143.985C135.581 140.076 150.713 125.841 152.9 112.349C153.172 111.207 153.329 110.021 153.329 108.796Z"
            fill="#DD2E44"
          />
        </g>
        <rect
          x={125}
          y={45}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          fill="#DD2E44"
        />
        <rect
          x={125}
          y={77.1738}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          fill="#DD2E44"
        />
        <rect
          x={125}
          y={70.739}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          fill="#DD2E44"
        />
        <rect
          x={125}
          y={64.3044}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          fill="#DD2E44"
        />
        <rect
          x={128.217}
          y={193}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(180 128.217 193)"
          fill="#DD2E44"
        />
        <rect
          x={128.217}
          y={160.826}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(180 128.217 160.826)"
          fill="#DD2E44"
        />
        <rect
          x={128.217}
          y={167.261}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(180 128.217 167.261)"
          fill="#DD2E44"
        />
        <rect
          x={128.217}
          y={173.696}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(180 128.217 173.696)"
          fill="#DD2E44"
        />
        <rect
          x={199}
          y={119}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(90 199 119)"
          fill="#DD2E44"
        />
        <rect
          x={166.826}
          y={119}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(90 166.826 119)"
          fill="#DD2E44"
        />
        <rect
          x={173.261}
          y={119}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(90 173.261 119)"
          fill="#DD2E44"
        />
        <rect
          x={179.696}
          y={119}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(90 179.696 119)"
          fill="#DD2E44"
        />
        <rect
          x={51}
          y={122.217}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(-90 51 122.217)"
          fill="#DD2E44"
        />
        <rect
          x={83.174}
          y={122.217}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-90 83.174 122.217)"
          fill="#DD2E44"
        />
        <rect
          x={76.739}
          y={122.217}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-90 76.739 122.217)"
          fill="#DD2E44"
        />
        <rect
          x={70.3038}
          y={122.217}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-90 70.3038 122.217)"
          fill="#DD2E44"
        />
        <rect
          x={177.327}
          y={66.6733}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(45 177.327 66.6733)"
          fill="#DD2E44"
        />
        <rect
          x={154.577}
          y={89.4238}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(45 154.577 89.4238)"
          fill="#DD2E44"
        />
        <rect
          x={74.9481}
          y={173.601}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(-135 74.9481 173.601)"
          fill="#DD2E44"
        />
        <rect
          x={97.6981}
          y={150.851}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-135 97.6981 150.851)"
          fill="#DD2E44"
        />
        <rect
          x={177.327}
          y={171.326}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(135 177.327 171.326)"
          fill="#DD2E44"
        />
        <rect
          x={154.577}
          y={148.576}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(135 154.577 148.576)"
          fill="#DD2E44"
        />
        <rect
          x={70.3989}
          y={68.9497}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(-45 70.3989 68.9497)"
          fill="#DD2E44"
        />
        <rect
          x={93.1493}
          y={91.7}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-45 93.1493 91.7)"
          fill="#DD2E44"
        />
        <rect
          x={145.174}
          y={68.7202}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(21.8729 145.174 68.7202)"
          fill="white"
        />
        <rect
          x={149.218}
          y={58.6497}
          width={2.36311}
          height={6.43478}
          rx={1.1436}
          transform="rotate(21.8729 149.218 58.6497)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={106.87}
          y={170.478}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-158.127 106.87 170.478)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.3784}
          rx={1.1436}
          transform="matrix(-0.928012 -0.372549 -0.372549 0.928012 105.941 172.798)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={175.366}
          y={139.409}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(111.873 175.366 139.409)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.5957}
          rx={1.1436}
          transform="matrix(-0.37255 0.928012 0.928012 0.37255 178.495 140.665)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={73.6068}
          y={101.103}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-68.1271 73.6068 101.103)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.42869}
          rx={1.1436}
          transform="matrix(0.372549 -0.928012 -0.928012 -0.372549 69.9935 99.6523)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={174.911}
          y={97.812}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(66.8729 174.911 97.812)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.58471}
          rx={1.1436}
          transform="matrix(0.392772 0.919636 0.919636 -0.392772 178.499 96.2795)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={75.8717}
          y={142.681}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-113.127 75.8717 142.681)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={8.48348}
          rx={1.1436}
          transform="matrix(-0.392772 -0.919636 -0.919636 0.392772 72.4052 144.162)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={146.274}
          y={169.143}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(156.873 146.274 169.143)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.43478}
          rx={1.1436}
          transform="matrix(-0.919636 0.392771 0.392771 0.919636 147.32 171.591)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={101.406}
          y={70.1042}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-23.1271 101.406 70.1042)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.43478}
          rx={1.1436}
          transform="matrix(0.919636 -0.392772 -0.392772 -0.919636 99.6348 65.9541)"
          fill="white"
          fillOpacity={0.7}
        />
      </g>
      <g className="bg-bloom">
        <g clipPath="url(#clip0_11596:86090)">
          <path
            d="M158.042 9.25728C158.042 6.57379 155.727 4.39844 152.871 4.39844C151.137 4.39844 149.607 5.2025 148.668 6.43149C147.729 5.2025 146.199 4.39844 144.466 4.39844C141.609 4.39844 139.294 6.57329 139.294 9.25728C139.294 9.6374 139.345 10.0057 139.433 10.3607C140.151 14.5524 145.112 18.9745 148.668 20.1887C152.223 18.9745 157.185 14.5524 157.902 10.3612C157.991 10.0062 158.042 9.63789 158.042 9.25728V9.25728Z"
            fill="#DD2E44"
          />
        </g>
        <g clipPath="url(#clip1_11596:86090)">
          <path
            d="M136.148 233.58C139.821 235.701 144.659 234.199 146.952 230.227C148.345 227.815 148.473 225.052 147.544 222.775C149.98 222.44 152.31 220.948 153.701 218.537C155.995 214.564 154.878 209.624 151.204 207.503C150.683 207.202 150.138 206.983 149.581 206.825C143.267 204.511 133.23 207.916 128.712 211.902C127.519 217.807 129.588 228.203 134.75 232.512C135.164 232.917 135.627 233.279 136.148 233.58V233.58Z"
            fill="#DD2E44"
            fillOpacity={0.5}
          />
        </g>
        <g clipPath="url(#clip2_11596:86090)">
          <path
            d="M51.9675 124.668C48.8189 122.85 44.6876 124.111 42.7402 127.484C41.558 129.532 41.4583 131.883 42.2602 133.824C40.1782 134.1 38.1916 135.363 37.0097 137.41C35.062 140.783 36.035 144.991 39.1842 146.809C39.6302 147.067 40.0973 147.256 40.5739 147.392C45.9817 149.384 54.5528 146.52 58.4019 143.144C59.4013 138.123 57.5957 129.268 53.1669 125.582C52.8112 125.236 52.414 124.926 51.9675 124.668V124.668Z"
            fill="#DD2E44"
            fillOpacity={0.5}
          />
        </g>
        <g clipPath="url(#clip3_11596:86090)">
          <path
            d="M157.877 119.132C154.729 120.95 153.755 125.158 155.703 128.531C156.885 130.579 158.871 131.841 160.953 132.117C160.151 134.058 160.251 136.41 161.433 138.457C163.381 141.83 167.511 143.092 170.661 141.273C171.107 141.016 171.504 140.706 171.86 140.361C176.289 136.674 178.095 127.819 177.095 122.798C173.246 119.422 164.675 116.558 159.268 118.55C158.791 118.685 158.324 118.874 157.877 119.132V119.132Z"
            fill="#DD2E44"
            fillOpacity={0.7}
          />
        </g>
        <g clipPath="url(#clip4_11596:86090)">
          <path
            d="M72.8701 27.6183C76.1698 25.5502 77.06 21.0263 74.8589 17.5144C73.5226 15.3824 71.3548 14.1207 69.1201 13.9136C69.9078 11.8121 69.7174 9.31116 68.3815 7.17979C66.18 3.66724 61.7211 2.49593 58.4209 4.5644C57.9535 4.85735 57.5402 5.20435 57.1715 5.58622C52.5708 9.69959 50.9567 19.2078 52.2041 24.5159C56.4371 27.9518 65.6983 30.6447 71.4044 28.2963C71.9096 28.1323 72.4021 27.9116 72.8701 27.6183V27.6183Z"
            fill="#DD2E44"
            fillOpacity={0.5}
          />
        </g>
        <g clipPath="url(#clip5_11596:86090)">
          <path
            d="M131.504 55.8096C128.236 57.6761 127.197 62.0092 129.184 65.4872C130.39 67.5987 132.433 68.9026 134.583 69.191C133.739 71.189 133.824 73.6115 135.03 75.7223C137.017 79.201 141.276 80.5083 144.544 78.6414C145.007 78.377 145.42 78.0583 145.791 77.7042C150.396 73.9142 152.331 64.7969 151.336 59.6222C147.384 56.1372 138.548 53.1709 132.946 55.2128C132.451 55.3513 131.967 55.5449 131.504 55.8096V55.8096Z"
            fill="#DD2E44"
            fillOpacity={0.5}
          />
        </g>
        <g clipPath="url(#clip6_11596:86090)">
          <path
            d="M22.7792 50.9771C20.8817 49.0796 17.7061 49.1788 15.6865 51.1984C14.4604 52.4244 13.9471 54.0749 14.1524 55.6077C12.6195 55.4025 10.9691 55.9158 9.74344 57.1415C7.7235 59.1614 7.62392 62.3367 9.52178 64.2345C9.79056 64.5033 10.0873 64.7274 10.4006 64.9162C13.8723 67.3724 20.5072 66.9913 23.8802 65.3355C25.5356 61.9629 25.9171 55.3277 23.4605 51.857C23.2725 51.543 23.0483 51.2462 22.7792 50.9771V50.9771Z"
            fill="#DD2E44"
            fillOpacity={0.7}
          />
        </g>
        <g clipPath="url(#clip7_11596:86090)">
          <path
            d="M69.8525 226.346C71.75 228.244 74.9257 228.144 76.9452 226.125C78.1713 224.899 78.6846 223.248 78.4794 221.716C80.0122 221.921 81.6626 221.407 82.8883 220.182C84.9082 218.162 85.0078 214.987 83.1099 213.089C82.8412 212.82 82.5444 212.596 82.2311 212.407C78.7594 209.951 72.1245 210.332 68.7515 211.988C67.0961 215.36 66.7147 221.996 69.1712 225.466C69.3593 225.78 69.5834 226.077 69.8525 226.346V226.346Z"
            fill="#DD2E44"
          />
        </g>
        <g clipPath="url(#clip8_11596:86090)">
          <path
            d="M213.768 64.9006C217.024 63.0211 218.041 58.6883 216.04 55.2235C214.826 53.1201 212.779 51.8272 210.631 51.5491C211.464 49.5496 211.368 47.1303 210.154 45.0276C208.153 41.5621 203.893 40.2762 200.637 42.156C200.176 42.4223 199.765 42.7425 199.396 43.098C194.814 46.9049 192.924 56.0205 193.941 61.1846C197.905 64.6472 206.744 67.569 212.331 65.5034C212.824 65.3628 213.307 65.1672 213.768 64.9006V64.9006Z"
            fill="#DD2E44"
            fillOpacity={0.7}
          />
        </g>
        <g clipPath="url(#clip9_11596:86090)">
          <path
            d="M73.1384 69.7965C71.9326 68.2122 69.5592 67.9903 67.8374 69.3007C66.7921 70.0962 66.231 71.2728 66.2173 72.4291C65.0992 72.1342 63.8155 72.3615 62.7706 73.1567C61.0485 74.4673 60.6297 76.8138 61.8357 78.3985C62.0065 78.6229 62.2029 78.8168 62.4155 78.986C64.7318 81.1313 69.7096 81.4659 72.3988 80.5514C73.9965 78.2034 75.0006 73.3162 73.5497 70.5127C73.4439 70.2622 73.3094 70.0212 73.1384 69.7965V69.7965Z"
            fill="#DD2E44"
          />
        </g>
        <g clipPath="url(#clip10_11596:86090)">
          <path
            d="M11.2978 165.126C10.0234 165.861 9.65694 167.612 10.4791 169.037C10.9783 169.901 11.8006 170.444 12.6545 170.575C12.3411 171.38 12.3997 172.363 12.8987 173.227C13.7211 174.652 15.4206 175.21 16.6952 174.474C16.8758 174.37 17.0359 174.243 17.1791 174.102C18.9631 172.595 19.6351 168.908 19.1881 166.803C17.5879 165.363 14.0594 164.101 11.8626 164.893C11.6683 164.946 11.4786 165.021 11.2978 165.126V165.126Z"
            fill="#DD2E44"
          />
        </g>
        <g clipPath="url(#clip11_11596:86090)">
          <path
            d="M217.191 181.347C215.294 179.45 212.118 179.549 210.098 181.569C208.872 182.795 208.359 184.445 208.564 185.978C207.032 185.773 205.381 186.286 204.155 187.512C202.135 189.532 202.036 192.707 203.934 194.605C204.203 194.874 204.499 195.098 204.813 195.287C208.284 197.743 214.919 197.362 218.292 195.706C219.948 192.333 220.329 185.698 217.872 182.227C217.684 181.913 217.46 181.617 217.191 181.347V181.347Z"
            fill="#DD2E44"
          />
        </g>
        <g clipPath="url(#clip12_11596:86090)">
          <path
            d="M107.043 173.799C106.048 175.523 106.699 177.763 108.498 178.802C109.59 179.432 110.852 179.472 111.899 179.024C112.035 180.155 112.7 181.228 113.792 181.858C115.591 182.897 117.856 182.341 118.852 180.617C118.993 180.373 119.097 180.117 119.174 179.857C120.276 176.903 118.792 172.257 117.003 170.184C114.313 169.671 109.548 170.709 107.541 173.141C107.353 173.337 107.185 173.554 107.043 173.799V173.799Z"
            fill="#DD2E44"
            fillOpacity={0.7}
          />
        </g>
        <g clipPath="url(#clip13_11596:86090)">
          <path
            d="M3.759 100.389C2.02772 100.389 0.624267 101.863 0.624267 103.68C0.624267 104.784 1.14302 105.757 1.93591 106.355C1.14302 106.952 0.624267 107.926 0.624267 109.029C0.624268 110.847 2.0274 112.32 3.759 112.32C4.00424 112.32 4.24186 112.288 4.47089 112.232C7.17519 111.775 10.0282 108.618 10.8115 106.355C10.0282 104.092 7.17519 100.935 4.47121 100.479C4.24217 100.422 4.00456 100.389 3.759 100.389V100.389Z"
            fill="#DD2E44"
            fillOpacity={0.7}
          />
        </g>
        <g clipPath="url(#clip14_11596:86090)">
          <path
            d="M245.088 118.31C247.425 118.31 249.32 116.275 249.32 113.765C249.32 112.241 248.62 110.897 247.549 110.072C248.62 109.247 249.32 107.902 249.32 106.379C249.32 103.869 247.426 101.833 245.088 101.833C244.757 101.833 244.436 101.879 244.127 101.956C240.476 102.587 236.625 106.947 235.567 110.072C236.625 113.196 240.476 117.556 244.127 118.186C244.436 118.265 244.757 118.31 245.088 118.31V118.31Z"
            fill="#DD2E44"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_11596:86090"
          x={93.8578}
          y={93.1548}
          width={61.7581}
          height={55.4045}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2.2872} />
          <feGaussianBlur stdDeviation={1.1436} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11596:86090"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11596:86090"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_11596:86090">
          <rect
            width={18.8694}
            height={17.7258}
            fill="white"
            transform="translate(139.233 3.43091)"
          />
        </clipPath>
        <clipPath id="clip1_11596:86090">
          <rect
            width={30.3054}
            height={28.0182}
            fill="white"
            transform="translate(159.228 212.024) rotate(120)"
          />
        </clipPath>
        <clipPath id="clip2_11596:86090">
          <rect
            width={25.731}
            height={24.0156}
            fill="white"
            transform="translate(32.3068 142.934) rotate(-60)"
          />
        </clipPath>
        <clipPath id="clip3_11596:86090">
          <rect
            width={25.731}
            height={24.0156}
            fill="white"
            transform="translate(163.865 145.292) rotate(-120)"
          />
        </clipPath>
        <clipPath id="clip4_11596:86090">
          <rect
            width={27.3827}
            height={25.7231}
            fill="white"
            transform="translate(65.5387) rotate(57.9222)"
          />
        </clipPath>
        <clipPath id="clip5_11596:86090">
          <rect
            width={26.4627}
            height={24.8589}
            fill="white"
            transform="translate(137.491 82.7673) rotate(-119.734)"
          />
        </clipPath>
        <clipPath id="clip6_11596:86090">
          <rect
            width={18.8694}
            height={17.7258}
            fill="white"
            transform="translate(5.35925 60.1575) rotate(-45)"
          />
        </clipPath>
        <clipPath id="clip7_11596:86090">
          <rect
            width={18.8694}
            height={17.7258}
            fill="white"
            transform="translate(87.2725 217.166) rotate(135)"
          />
        </clipPath>
        <clipPath id="clip8_11596:86090">
          <rect
            width={26.432}
            height={24.8301}
            fill="white"
            transform="translate(207.663 38.0022) rotate(60)"
          />
        </clipPath>
        <clipPath id="clip9_11596:86090">
          <rect
            width={14.295}
            height={13.1514}
            fill="white"
            transform="translate(59.1814 74.9861) rotate(-37.273)"
          />
        </clipPath>
        <clipPath id="clip10_11596:86090">
          <rect
            width={10.8642}
            height={9.7206}
            fill="white"
            transform="translate(13.9456 176.102) rotate(-120)"
          />
        </clipPath>
        <clipPath id="clip11_11596:86090">
          <rect
            width={18.8694}
            height={17.7258}
            fill="white"
            transform="translate(199.771 190.528) rotate(-45)"
          />
        </clipPath>
        <clipPath id="clip12_11596:86090">
          <rect
            width={13.7232}
            height={13.1514}
            fill="white"
            transform="translate(116.729 184.382) rotate(-150)"
          />
        </clipPath>
        <clipPath id="clip13_11596:86090">
          <rect
            width={12.0078}
            height={11.436}
            fill="white"
            transform="translate(0 112.359) rotate(-90)"
          />
        </clipPath>
        <clipPath id="clip14_11596:86090">
          <rect
            width={16.5822}
            height={15.4386}
            fill="white"
            transform="translate(250.163 101.781) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
