import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { Button, ButtonProps } from '../../../components';
import { SocialLoginProvider } from '../../../services/auth';
import { SocialLoginIcon } from './socialLoginIcon';

type Props = {
  provider: SocialLoginProvider
} & ButtonProps;

export const SocialLoginButton = observer(({
  provider,
  className,
  ...props
}: Props) => {

  className = classNames('social-login-btn', className);
  let iconElem =
    <SocialLoginIcon provider={provider} />

  return (
    <Button
      className={className}
      leftIcon={iconElem}
      {...props} />
  );
});