import { observer } from 'mobx-react-lite';
import React from 'react';
import { TeamMetadataItem } from './teamMetadataItem';
import { TeamMetadataState } from './teamMetadataState';

type TeamMetadataListProps = {
  model: TeamMetadataState;
};

export const TeamMetadataList = observer(({ model }: TeamMetadataListProps) => {
  const { metadataFields } = model;
  return <div className="team-metadata-list scrollable">
    {metadataFields.map(item => <TeamMetadataItem model={item} key={item.id} />)}
  </div>
});