import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import Bowser from 'bowser';
import * as serviceWorker from './serviceWorker';
import { App } from './app';
import { Store } from './store/store';
import { StoreContext } from './store/storeHooks';
import {
  addGoogleTagManager,
  addHubspotTracking,
  addLinkedInTracking,
} from './services/analytics';
import { Config } from './config';
import { TRACE, TRACE_SEPARATOR_FAR_FROM_DECENT } from './core/debug/debugMacros';
import { RUNTIME } from './core/debug';

TRACE_SEPARATOR_FAR_FROM_DECENT(RUNTIME);
TRACE(RUNTIME, `Started CLIPr at ${new Date().toISOString()} for:`, window.location.href);

function init() {

  const store = new Store();

  addGoogleTagManager();
  addLinkedInTracking();
  addHubspotTracking();

  if (process.env.NODE_ENV === 'development')
    (window as any).clipr = store;

  store.init();

  let appElem = (
    <StoreContext.Provider value={store}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </StoreContext.Provider>
  );

  if (Config.vendor.react.useStrictMode) {
    appElem = (
      <React.StrictMode>
        {appElem}
      </React.StrictMode>
    );
  }

  ReactDOM.render(
    appElem,
    document.getElementById('root')
  );
}

function checkBrowser() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isValidBrowser = browser.satisfies({
    chrome: '>=75',
    safari: '>=11',
  });

  const skipBrowserCheck = true; //localStorage.getItem('skipBrowserCheck');

  if (!skipBrowserCheck && !isValidBrowser) {
    document.location.href = '/unsupported/unsupportedDesktopBrowser.html';
  }
}

checkBrowser();
init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
