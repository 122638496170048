import './speakerId.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { SpeakerIdFormState } from './speakerIdFormState';
import { SpeakerFormBlock } from '../speakers/speakerFormBlock';
import { ButtonControlled } from '../input/button';
import { LoadMask } from '../loader/loadMask';
import { SpeakerIdNotification } from './speakerIdNotification';

const SpeakerIdModeTitle = {
  confirm: 'Confirm this speaker',
  select: 'Tell us who this speaker is',
  edit: null
}

type Props = {
  model: SpeakerIdFormState;
};

export const SpeakerIdForm = observer((props: Props) => {

  const { model } = props;
  const { mode, isEditMode, showEditButton, showSkipButton, showSubmitButton, showStepIndex } = model;

  if (model.isFinished)
    return <div className="speaker-id-block"><SpeakerIdNotification /></div>

  if (model.stepCount === 0)
    return <div className="speaker-id-block">There are no unindentified speakers.</div>;

  return (
    <div className="speaker-id-block">
      {!isEditMode &&
        <div className="block-title">{SpeakerIdModeTitle[mode]}</div>}

      <div className="block-content">
        <SpeakerFormBlock
          model={model.speakerBlock}
          showDescription={!model.speakerBlock.isEmpty ||
            model.speakerBlock.speakerInputGroup.mode === 'add'}
          showLinkedin={model.speakerBlock.speakerInputGroup.mode === 'add'}
          descriptionLabel={'Bio'}
          linkedinLabel={'Linkedin'}
          confirmLabel={model.confirmLabel ?? undefined} />
      </div>

      <div className="block-footer">
        <div className="left">
          {showStepIndex && <div className="step-index">
            <span className="bold">{model.stepIndexDisplay}</span> of {model.stepCount}
          </div>}
        </div>
        <div className="right">
          {!model.isLocked && <div className="btn-group ">
            {showSkipButton && <ButtonControlled
              className="primary btn-outline medium"
              label="I don't know"
              model={model.cancelButton}
              onClick={() => model.cancel()} />}
            <div className="hpad2" />
            {showSubmitButton && <ButtonControlled
              className="primary medium"
              label="Save"
              model={model.submitButton}
              onClick={() => model.submit()} />}
            {showEditButton && <ButtonControlled
              className="primary btn-outline medium"
              label="Edit this Speaker"
              model={model.editButton}
              onClick={() => model.edit()} />}
          </div>}
        </div>
      </div>
      <LoadMask
        className="small"
        spinner="dots3"
        spinnerClassName="medium"
        visible={model.isLoading} />
    </div>
  );
});
