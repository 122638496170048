import React from 'react';
import { observer } from 'mobx-react-lite';
import { ParentSize } from '@visx/responsive';
import { TopicViewsChartState } from './topicViewsChartState';
import { WordCloudChart } from '../wordCloud/wordCloudChart';
import { DropdownInputControlled } from '../../input';
import { WordCloudChartPropsBase } from '../wordCloud/wordCloudSchema';
import { ChartWrapper } from '../chartWrapper';

export type Props = {
  model: TopicViewsChartState;
} & Partial<WordCloudChartPropsBase>

export const TopicViewsChart = observer(({ model }: Props) => {
  const { dataSource } = model;
  const teamId = model.params?.teamId;
  const { isFetching: showLoadMask, error } = dataSource;

  // NOTE: do not access model.outputData directly in WordCloudChart
  // this is because WordCloudChart is rendered within a callback to ParentSize, which I suspect is run async
  const data = model.outputData;

  const hasData = data.length > 0;
  const showNoDataMask = !hasData && !showLoadMask;

  let overlayMessage;
  if (error) {
    overlayMessage = { code: 'Error', message: 'An error has occured' };
  } else if (showNoDataMask) {
    overlayMessage = { code: 'NoData' };
  }

  const chartTitle = `${teamId ? 'Library' : 'My'} Audience Focus`;
  const tooltipContent = teamId ?
    `Find out what subjects viewers care most about in the most viewed and bookmarked topics from your library’s videos.` :
    `Find out what subjects your viewers care most about in the most viewed and bookmarked topics from your videos.`;

  const dropdownInput = <DropdownInputControlled
    className="form-input"
    model={model.chartTypeInput}
    notDeselectable={true}
    persistentFeedback={false} />;

  return (
    <ChartWrapper
      title={chartTitle}
      tooltipContent={tooltipContent}
      showLoadMask={showLoadMask}
      overlayMessage={overlayMessage}
      tools={dropdownInput}>
      {!overlayMessage && 
        <ParentSize enableDebounceLeadingCall={false}>{parent => 
          <WordCloudChart
            height={parent.height}
            width={parent.width}
            words={data} />
        }</ParentSize>}
    </ChartWrapper>
  );
});
