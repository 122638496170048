export class DemoService {
  readonly nodeType = 'DemoService';
  playerTutorialComponents: string[];

  constructor(playerTutorialComponents: string[]) {
    this.playerTutorialComponents = playerTutorialComponents;
  }

  highlightComponents(tutorialIds: string[]): void {
    this.playerTutorialComponents.forEach((tutorialId: string) => {
      const component: HTMLElement | null | Element = document.querySelector(`[data-tutorial-id=${tutorialId}]`) ?? document.getElementById(tutorialId);

      if (tutorialIds.indexOf(tutorialId) >= 0) {
        component?.classList.add('player-tutorial-mode');
      } else {
        component?.classList.remove('player-tutorial-mode');
      }
    });
  }

  toggleCustomClass(tutorialId: string, customClass: string, add: boolean): void {
    const component: HTMLElement | null | Element = document.querySelector(`[data-tutorial-id=${tutorialId}]`) ?? document.getElementById(tutorialId);

    if (!add && component) {
      component.classList.remove(customClass);
      return;
    } else component?.classList.add(customClass);
  }
}