import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Button, LoadMask, OneDriveSVGIcon } from '../../components';
import {
  OneDriveCatalog,
  OneDriveTableCatalog,
  OneDriveTableCatalogHeader,
} from '../../components/media';
import { ErrorSection } from './errorSection';
import Routes from '../../routes';
import { UploadPageParams } from '../uploadPage';
import { wrapRouteWithQuery } from '../../core';
import { IntegrationTabState } from '../integrations/integrationTabState';
import { NotConnectedState } from '../integrations/notConnectedState';
import { DisconnectSection } from '../integrations/disconnectSection';

type Props = {
  model: IntegrationTabState;
};

export const OneDriveTab = observer(({ model }: Props) => {
  const { store } = model;
  const state = model;
  const { catalog, catalogSource } = state;
  const { isConnected, isLoading, error } = store.oneDrive;

  useEffect(() => {
    state.pageMounted(isConnected);
    return () => state.pageUnmounted();
  }, [state, isConnected]);

  const location = useLocation();
  const { pathname } = location;

  const params = useParams<UploadPageParams>();
  const { teamId } = params;

  let route;
  if (teamId) {
    route = `${Routes.teamUpload(teamId)}`;
  } else {
    route = Routes.upload();
  }

  if (pathname === route) {
    const returnUrl = wrapRouteWithQuery(route, { view: 'onedrive' });
    localStorage.setItem('externalLibraryUploadAuth', returnUrl);
  } else {
    localStorage.removeItem('externalLibraryUploadAuth');
  }

  if (isLoading)
    return (
      <div className="catalog">
        <LoadMask
          className="partial"
          visible={isLoading} />
      </div>
    );

  const disconnectSection = <DisconnectSection model={model} library={model.libraryService} />;

  if (error) return <ErrorSection header={disconnectSection} />;

  if (!isConnected) return <NotConnected model={state.notConnectedState} />;

  return (
    <>
      <section
        className={`${state.catalog.viewType === 'list' ? 'list-view' : ''
          } page-subheader`}>
        {catalog.viewType === 'grid' ? (
          <>
            <div className="flex-left">
              <div className="page-filters">
                <div className="select-controls">
                  {catalogSource.hasMultipleSelection ? (
                    <>
                      <Button
                        label="Cancel"
                        className="primary btn-outline medium"
                        onClick={() => catalogSource.clearMultipleSelection()}
                      />
                      <Button
                        className="primary medium"
                        label="Ingest Selected"
                        onClick={() => state.bulkIngest(teamId)}
                      />
                    </>
                  ) : (
                    <Button
                      className="primary medium"
                      label="Select all"
                      onClick={() => catalogSource.selectAllFiles()}
                      disabled={
                        !catalogSource.entities.length ||
                        !catalogSource.availableforIngestEntities.length
                      }
                    />
                  )}
                </div>
              </div>
            </div>

            {disconnectSection}
          </>
        ) : (
          <OneDriveTableCatalogHeader
            teamId={teamId}
            model={catalog}
            source={catalogSource}
            integration={model}
          />
        )}
      </section>

      {isConnected &&
        (state.catalog.viewType === 'grid' ? (
          <OneDriveCatalog model={catalog} source={catalogSource} />
        ) : (
          <OneDriveTableCatalog model={catalog} source={catalogSource} />
        ))}
    </>
  );
});

type NotConnectedProps = {
  model: NotConnectedState;
};

const NotConnected = observer(({ model }: NotConnectedProps) => {
  const { store } = model;
  const { oneDrive } = store;

  return (
    <div className="catalog empty-catalog">
      <div className="icon">
        <OneDriveSVGIcon />
      </div>
      <h1 className="headline-1">Authorization is Required</h1>
      <p>To access your Drive Videos please provide CLIPr with permissions.</p>

      <Button
        onClick={() => model.triggerConfirmationModal(oneDrive.authUrl)}
        className="upload-btn secondary large"
        label="Connect with OneDrive"
      />
    </div>
  );
});
