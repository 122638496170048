import { assert } from './assert';

export function handler<TTarget>(
  target: TTarget,
  methodName: keyof TTarget,
  args: any[] = []) {

  const method = target[methodName];
  assert(typeof method === 'function',
    `'${methodName}' is not a function.`);

  return (target[methodName] as any).bind(target, ...args);
}