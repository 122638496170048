import { ResizedImage, Speaker as SpeakerProps } from '@clipr/lib';
import { makeObservable, computed } from 'mobx';
import { Store } from '../store/store';
import { StoreNode } from '../store';
import { IJobDependency } from './jobSchema';
import { SpeakerPrediction } from './speakers/speakerPrediction';

export const SCOPE_TEAM_PREFIX = `TEAM#`;
export const SCOPE_JOB_PREFIX = `JOB#`;

export type Speaker = SpeakerModel;
export type { Speaker as SpeakerProps } from '@clipr/lib';

export class SpeakerModel
  extends StoreNode
  implements IJobDependency {

  constructor(props: SpeakerProps, store: Store) {
    super(store);
    makeObservable(this);

    Object.assign(this, props);

    if (props.prediction) {
      this.prediction = new SpeakerPrediction(props.prediction, this.store)
    }
  }

  readonly nodeType: 'Speaker' = 'Speaker';

  readonly id!: string;
  readonly jobId!: string;
  readonly name!: string;
  
  readonly description!: string;
  readonly linkedin!: string;
  readonly picture!: ResizedImage[];
  readonly scope!: string | null;
  readonly prediction: SpeakerPrediction | null = null;

  @computed
  get displayName() {
    return this.name;
  }

  @computed
  get pictureURL(): string | null {
    return (this.picture &&
      this.picture.length > 0 &&
      this.picture[0]?.url) || null;
  }

  @computed
  get isPublic(): boolean {
    return this.hasPublicScope || this.hasTeamScope;
  }

  @computed
  get isPrivate(): boolean {
    return this.hasJobScope;
  }

  @computed
  get hasPublicScope(): boolean {
    return this.scope === 'public';
  }

  @computed
  get hasTeamScope(): boolean {
    return !!this.scope?.startsWith(SCOPE_TEAM_PREFIX);
  }

  @computed
  get hasJobScope(): boolean {
    return !!this.scope?.startsWith(SCOPE_JOB_PREFIX);
  }

  @computed
  get matchPercentage(): number {
    return this.prediction?.matchPercentage ?? 0;
  }

  @computed
  get matchPercentageDisplay(): string | null {
    return this.prediction?.matchPercentageDisplay ?? null;
  }

  @computed
  get hasMatch(): boolean {
    return this.prediction?.hasMatch ?? false;
  }

  @computed
  get isConfirmed(): boolean {
    // at this a lack of prediction means the speaker is confirmed
    return this.prediction?.isConfirmed ?? true;
  }

  @computed
  get matchId(): string | null {
    return this.prediction?.speakerId ?? null;
  }
}