import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { WidgetRouteState } from './widgetRouteState';
import { LoadPage } from '../components';
import { useRouteModel } from './routeHooks';
import { AuthFlowResponseType } from '../services/auth/authFlowSchema';
import { AuthFlowResponseInterpreter } from '../components/auth';
import { WidgetErrorPage } from '../pages/error';

import { TRACE } from '../core/debug/debugMacros';

type Props = RouteProps;

export const WidgetRoute = observer((props: Props) => {

  const model = useRouteModel((kernel) => new WidgetRouteState(kernel.store));

  TRACE(model, `render()`, { props, model });

  if (model.isLoading) {
    return (
      <LoadPage />
    );
  }

  if (model.error) {
    return <WidgetErrorPage
      error={model.error}
      onLogoutButtonClick={model.handleLogoutButtonClick} />;
  }

  const { flowResponse } = model;
  switch (flowResponse?.responseType) {
    case AuthFlowResponseType.Authorized:
    case AuthFlowResponseType.AuthorizedAsAnonymous:
      TRACE(model, `Authorized and passing through to render the route`, { flowResponse });
      return <Route {...props} />;
  }

  TRACE(model, `Not authorized, using the flow interpreter`, { flowResponse });
  return <AuthFlowResponseInterpreter response={model.flowResponse} />
});
