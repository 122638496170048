import { action, makeObservable, observable } from 'mobx';
import { ApiVariables } from '../../api/apiSchema';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Message, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { WindowState } from '../overlays';

export class TogglePublicSafetyWindowState
  extends StoreNode {

  readonly nodeType = 'TogglePublicSafetyWindow';

  constructor(store: Store, props?: any) {
    super(store, props);
    makeObservable(this);
    this.window.listen(
      this.windowListener);
  }

  @observable
  publicSafety: boolean = false;

  @observable
  isLoading = false;

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close();
        break;
    }
  }

  @observable teamId: string | null = null;
  readonly window = new WindowState(this.store);

  async submit() {
    this.isLoading = true;

    const vars: ApiVariables<'updateTeam'> = {
      args: {
        teamId: this.teamId || '',
        publicSafety: this.publicSafety
      }
    };

    const [, err] = await this.store.teamManager.updateTeam(vars);
    if (err) {
      this.emit('error');
      this.handleSubmitReject(`Could not update library. Please try again.`);

      return;
    }

    this.emit('success');
    this.handleSubmitSuccess(`Library updated.`);
  }

  @action
  private handleSubmitReject(msg: string) {
    this.isLoading = false;

    notifyError(this, msg);
  }

  @action
  private handleSubmitSuccess(msg: string) {
    this.isLoading = false;
    this.close();

    notifySuccess(this, msg);
  }

  @action
  open({ teamId, publicSafety }: { teamId: string, publicSafety: boolean }) {
    this.dispatch('Overlays', 'openWindow', { name: 'TogglePublicSafetyWindow' });

    this.teamId = teamId ?? null;
    this.publicSafety = publicSafety;
  }

  @action
  close() {
    this.dispatch('Overlays', 'closeWindow');
    this.emit('close');

    this.teamId = null;
  }
}