import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { usePopper } from 'react-popper';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';

import { PlayerState } from './playerState';

import { useOutsideClick } from '../hooks';
import { CheckSVGIcon } from '../svg';

// @ts-ignore
import CSSVariables from '../menu/menu.scss';
import '../menu/menu.scss';
import './player.scss';

type Props = {
  model: PlayerState;
  className?: string;
  offsetY?: number;
  anchorElement?: any;
};

const fadeTimeout = {
  enter: parseInt(CSSVariables.windowEnterTimeout),
  exit: parseInt(CSSVariables.windowExitTimeout)
};

export const PlaybackRate = observer((props: Props) => {
  const { model } = props;
  const buttonLabel = `${model.playbackRate}x`;
  const offsetY = props?.offsetY || 0;
  const className = classNames(
    'playback-rate-btn',
    props?.className
  );

  const [anchorElement, setAnchorElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isOpened, setIsOpened] = useState(false);

  const popperAnchorElement = props?.anchorElement || anchorElement;

  const { styles, attributes } = usePopper(popperAnchorElement, popperElement, {
    placement: 'top-end',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, offsetY],
        }
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['left-start', 'bottom', 'bottom-end'],
        },
      }
    ],
  });

  useOutsideClick(popperElement, () => setIsOpened(false));

  return (
    <>
      <button className={className}
        ref={setAnchorElement as any}
        onClick={evt => {
          setIsOpened(!isOpened);
          evt.stopPropagation();
        }}>

        <span className="text">
          {buttonLabel}
        </span>
      </button>

      <TransitionGroup>
        {isOpened && (
          <CSSTransition
            classNames="menu-fade"
            timeout={fadeTimeout}>
            <div className="playback-rate-popup menu-popup popup"
              ref={setPopperElement as any}
              style={styles.popper}
              {...attributes.popper}>
              <div className="menu-items"
                onClick={evt => setIsOpened(false)}>
                {model.playbackRateOptions.map(({ label, rate}) => (
                  <button key={label}
                    className="menu-item-btn btn-core"
                    onClick={model.invokeHandler('setPlaybackRate', { rate })}>
                    <span className="icon">
                      {model.playbackRate === rate && <CheckSVGIcon />}
                    </span>
                    {label}
                  </button>
                ))}
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
});
