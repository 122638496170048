import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import last from 'lodash/last';
import debounce from 'lodash/debounce';
import { assertNotNull, AsyncResult, Maybe } from '../core';
import { Store } from '../store/store';
import { BindingProps, StoreNode } from '../store';
import { ApiResult, ApiVariables } from '../api/apiSchema';
import { GetJobsFilterInput, JobOrderByOption, JobSourceType, JobStatus, JobType, Metadata, Order, PageInfo } from '@clipr/lib';
import { SyncStatus } from '../store/syncSchema';
import { JobModel } from './job';
import { SortFieldValue } from '../components/input/sortFieldBtn';
import { JobCatalogItem } from './jobCatalogItem';
import { JobsPosterSize } from './jobManager';
import { getVideoTypeLabel, JobLiveAggregateStatus } from '.';
import { input, InputState } from '../components';
import { JobFilter } from './jobSchema';
import { getCatalogMetadataFilterInput, getCatalogTagsFilterInput } from './catalogUtils';

type Props = BindingProps<{
  teamId?: string;
  pageSize?: number;
}> & {
  /** 
   * Local filter to apply to all Job entities. 
   * Currently it's only being used to detect the transient entities.
   */
  jobFilter?: JobFilter;
};

export class JobCatalogSource
  extends StoreNode {

  readonly nodeType: 'JobCatalogSource' = 'JobCatalogSource';

  constructor(store: Store, props?: Props) {
    super(store, props);

    this.pageSize = this.props.pageSize ?? 20;
    makeObservable(this);

    this.searchBarModel = input(this, {
      name: 'searchInput',
      onChange: (e) => this.handleSearchInputChange(e),
    });
  }

  // #region Resolved props
  // -------

  @computed get teamId(): string | null {
    return this.resolvedProps.teamId ?? null;
  }

  @computed
  get jobFilter(): JobFilter | null {
    return this.getProp('jobFilter');
  }

  // #endregion

  readonly searchBarModel: InputState;
  readonly items = observable.array<JobCatalogItem>();

  @observable pageSize: number;
  @observable syncStatus: SyncStatus = 'idle';

  @observable statusFilter: JobStatus | null = null;
  @observable liveStatusFilter: JobLiveAggregateStatus | null = null;
  @observable videoTypeFilter: string | null = null;
  @observable createdByIdFilter: string | null = null;
  @observable sourceTypeFilter: JobSourceType | null = null;

  @observable metadataFilter: Partial<Metadata> | null = null;
  @observable tagsFilter: string[] | null = null;

  @observable searchFilter: string | null = null;
  @observable sortField: JobOrderByOption | null = 'createdAt';
  @observable sortOrder: Order | null = 'desc';

  @observable pageInfo: PageInfo | null = null;

  @computed get lastItemCursor(): string | null {
    return last(this.items)?.cursor || null;
  }

  @computed
  get itemEntityIds(): Set<string> {
    return new Set(this.itemEntities.map(job => job.id));
  }

  @computed
  get itemEntities(): JobModel[] {
    return this.items.map(item => item.job);
  }

  @computed
  get transientEntityIds(): Set<string> {
    return new Set(this.transientEntities.map(job => job.id));
  }

  @computed
  get transientEntities(): JobModel[] {
    const filter = this.jobFilter;
    if (typeof filter !== 'function')
      return [];

    return this.store.jobManager.transientJobs.filter(job => filter(job));
  }

  @computed
  get entities(): JobModel[] {

    this._verifyEntityDuplicates();

    return [
      ...this.transientEntities,
      ...this.itemEntities
    ];
  }

  @computed get isEndOfList(): boolean {
    return this.pageInfo ? !this.pageInfo.hasNextPage : false;
  }

  getSortFieldValue(field: JobOrderByOption) {
    if (this.sortField !== field)
      return 'none';
    return this.sortOrder!;
  }

  @action
  setSortFieldValue(field: JobOrderByOption, val?: SortFieldValue, fetch: boolean = true) {
    if (!val || val === 'none') {
      this.sortField = null;
      this.sortOrder = null;
    } else {
      this.sortField = field;
      this.sortOrder = val;
    }

    if (fetch)
      this.debouncedFetch();
  }

  getFilterFieldValue<T extends keyof GetJobsFilterInput>(field: T): string | null {
    switch (field) {
      case 'videoType':
        return this.videoTypeFilter;
      case 'status':
        return this.statusFilter;
    }
    return null;
  }

  getLiveStatusFilterValue(): JobLiveAggregateStatus | null {
    return this.liveStatusFilter;
  }

  @action
  setFilterFieldValue<T extends keyof GetJobsFilterInput>(field: T, val?: Maybe<GetJobsFilterInput[T]>, fetch: boolean = true) {
    switch (field) {
      case 'sourceType':
        this.sourceTypeFilter = (val as JobSourceType) || null;
        break;
      case 'videoType':
        this.videoTypeFilter = (val as string) || null;
        break;
      case 'status':
        this.statusFilter = (val as JobStatus) || null;
        break;
      case 'tags':
        this.tagsFilter = (val as string[]) || null;
    }

    if (fetch)
      this.debouncedFetch();
  }

  @action
  setLiveStatusFilterValue(liveStatusFilter: JobLiveAggregateStatus | null) {
    this.liveStatusFilter = liveStatusFilter;
    this.debouncedFetch();
  }

  @action
  handleSearchInputChange(evt: any) {
    this.searchFilter = evt.value || null;
    this.debouncedFetch();
  }

  debouncedFetch = debounce(this.fetch, 500);

  @action
  setPagesizeValue(pageSize: number) {
    if (this.pageSize !== pageSize) {
      this.pageSize = pageSize;
      this.debouncedFetch();
    }
  }

  getVideoTypeFilterValue(): JobType | null {
    return this.videoTypeFilter as JobType;
  }
  getVideoTypeFilterLabel() {
    return getVideoTypeLabel(this.getVideoTypeFilterValue());
  }

  @action
  setVideoTypeFilterValue(val?: Maybe<string>) {
    this.videoTypeFilter = val || null;
    this.fetch();
  }


  /** Makes a fresh fetch call, clearing any previous data and pagination state. */
  @action
  async fetch()
    : AsyncResult<JobCatalogItem[]> {
    this.syncStatus = 'fetching';

    const [jobsRes, err] = await this.fetchRaw();

    if (err)
      return [null, err];

    assertNotNull(jobsRes);

    const retItems: JobCatalogItem[] = [];
    runInAction(() => {
      this.items.clear();

      const { getJobs } = jobsRes;
      this.pageInfo = getJobs.pageInfo;

      getJobs.edges.forEach((edge, i) => {

        const item = new JobCatalogItem(this.store, {
          jobId: edge.node.id,
          cursor: edge.cursor,
          index: i
        });

        this.items.push(item);
        retItems.push(item);

        if (this.transientEntityIds.has(item.jobId))
          console.warn(`JobCatalogSource: Job ${item.jobId} is already registered as a transient entity which means it was not properly removed within the JobManager after the fetch.`);
      });
    });

    return [retItems];
  }

  @action
  async fetchMore()
    : AsyncResult<JobCatalogItem[]> {

    this.syncStatus = 'fetchingMore';

    const [jobsRes, err] = await this.fetchRaw(true);
    if (err)
      return [null, err];

    assertNotNull(jobsRes);

    const retItems: JobCatalogItem[] = [];
    runInAction(() => {
      const { getJobs } = jobsRes;
      this.pageInfo = getJobs.pageInfo;

      getJobs.edges.forEach((edge, i) => {

        const item = new JobCatalogItem(this.store, {
          jobId: edge.node.id,
          cursor: edge.cursor,
          index: i
        });

        this.items.push(item);
        retItems.push(item);

        if (this.transientEntityIds.has(item.jobId))
          console.warn(`JobCatalogSource: Job ${item.jobId} is already registered as a transient entity which means it was not properly removed within the JobManager after the fetch.`);
      });
    });
    return [retItems];
  }

  private getFetchArgs(more: boolean = false) {
    let args: ApiVariables<'getJobs'> = {
      posterSize: [JobsPosterSize]
    };
    let filter: GetJobsFilterInput = {};

    if (this.sortField && this.sortOrder) {
      args.sort = {
        field: this.sortField,
        order: this.sortOrder
      }
    }

    if (this.createdByIdFilter)
      filter.createdById = this.createdByIdFilter;
    if (this.videoTypeFilter)
      filter.videoType = this.videoTypeFilter;
    if (this.sourceTypeFilter)
      filter.sourceType = this.sourceTypeFilter;

    if (this.liveStatusFilter) {
      switch (this.liveStatusFilter) {
        case JobLiveAggregateStatus.NotReady: {
          filter.status = {
            eq: JobStatus.Pending
          }
          break;
        }
        case JobLiveAggregateStatus.Waiting: {
          filter.status = {
            eq: JobStatus.LiveReady
          }
          break;
        }
        case JobLiveAggregateStatus.Streaming: {
          filter.status = {
            eq: JobStatus.LiveStreaming
          }
          break;
        }
        case JobLiveAggregateStatus.Processing: {
          filter.status = {
            eq: JobStatus.InProgress
          }
          break;
        }
        case JobLiveAggregateStatus.LiveEnded: {
          filter.status = {
            eq: JobStatus.LiveEnded
          }
          break;
        }
        case JobLiveAggregateStatus.ProcessingDone: {
          filter.status = {
            in: [
              JobStatus.Done,
              JobStatus.Updated,
              JobStatus.InReview,
              JobStatus.Waiting]
          }
          break;
        }
        case JobLiveAggregateStatus.ProcessingFailed: {
          filter.status = {
            eq: JobStatus.Failed
          }
          break;
        }
      }
    }

    if (this.teamId)
      filter.teamId = this.teamId;

    if (this.searchFilter) {
      filter._search = this.searchFilter || undefined;
    }

    const metadata = getCatalogMetadataFilterInput(this.metadataFilter);
    if (metadata && metadata.length > 0)
      filter.metadata = metadata;

    const tags = getCatalogTagsFilterInput(this.tagsFilter);
    if (tags && tags.length > 0)
      filter.tags = tags;

    args.filter = filter;
    args.first = this.pageSize;

    if (more && this.lastItemCursor)
      args.after = this.lastItemCursor;

    return args;
  }

  @action
  private async fetchRaw(more: boolean = false): ApiResult<'getJobs'> {

    const args: ApiVariables<'getJobs'> = this.getFetchArgs(more);
    const { jobManager } = this.store;

    const [jobsRes, err] = await jobManager.apiFetchJobs(args, {
      rawResponse: true
    });

    if (err) {
      runInAction(() => {
        this.syncStatus = 'error';
        // notifyError(this, `Could not fetch videos because of an error.`);
      });
      return [null, err];
    }

    runInAction(() => {
      this.syncStatus = 'idle';
    });

    assertNotNull(jobsRes);
    return [jobsRes];
  }

  @action
  reset() {
    //TODO: Check all the fields that we need to reset
    this.searchFilter = null;

    this.statusFilter = null;
    this.liveStatusFilter = null;

    this.videoTypeFilter = null;
    this.createdByIdFilter = null;
    this.sourceTypeFilter = null;

    this.metadataFilter = null;
    this.tagsFilter = null;

    this.sortField = 'createdAt';
    this.sortOrder = 'desc';
    this.searchBarModel.clear();
  }

  private _verifyEntityDuplicates() {

    const transEntIds = this.transientEntityIds;
    const itemEntIds = this.itemEntityIds;

    const duplicates = new Set<string>();
    for (let transEntId of transEntIds) {
      if (itemEntIds.has(transEntId)) {
        duplicates.add(transEntId);
      }
    }

    if (duplicates.size > 0) {
      console.warn(
        `JobCatalogSource: Encountered ${duplicates.size} duplicates between fetched jobs and transient jobs:\n` +
        [...duplicates].map(id => `\t${id}\n`));
    }
  }
}

