import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { cssPercentage } from '../../../core';
import { UserAvatar } from '../../layout/userAvatar';
import { PlayerItem } from '../../../entities/player/playerItem';
import { MarkerBarState } from './markerBarState';
import { PlayerReactionIcon } from '../../playerReactions/reactionIcon';
import { useEffect } from 'react';
import { ComponentVisibility, IComponentPolicy } from '../../componentSchema';
import { PlayerComponentName } from '../playerSchema';

type Props = {
  model: MarkerBarState,
  policy?: IComponentPolicy
}

export const PlayerMarkerBar = observer(({
  model,
  policy = model.player.getComponentPolicy(PlayerComponentName.MarkerBar)
}: Props) => {

  const { player } = model;

  const [indexOfTheLastAddedReaction, setIndexOfTheLastAddedReaction] = useState(-1);
  const items = player.itemSource.markerBarItems;
  const lastAddedReaction = player.itemSource.lastAddedReaction;
  const activeTarget = player.momentView.activeCommentOrReaction;

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (lastAddedReaction) {
      const latestAddedReactionItem = items.find((item) => item.reactionId === lastAddedReaction.reactionId);

      if (latestAddedReactionItem) {
        const indexOfLatestAddedReaction = items.indexOf(latestAddedReactionItem);
        setIndexOfTheLastAddedReaction(indexOfLatestAddedReaction);

        timeout = setTimeout(() => setIndexOfTheLastAddedReaction(-1), 6000);
      }
    } else {
      setIndexOfTheLastAddedReaction(-1);
    }

    return () => clearTimeout(timeout);
  }, [items, lastAddedReaction]);

  if (policy.visibility !== ComponentVisibility.Visible)
    return null;

  const className = classNames({
    'show-comment-items': player.frameset?.isSectionVisible('Comments'),
    'show-reaction-items': true
  });

  return (
    <div id="user-player-marker-bar"
      className={className}>
      <ul className="marker-list">
        {items.map((item, index) => {
          return <MarkerItem
            isLastAddedReaction={index === indexOfTheLastAddedReaction}
            key={item.id}
            isActive={activeTarget?.id === item?.targetId}
            model={model}
            item={item} />
        })}
      </ul>
    </div>
  );
});

type MarkerItemProps = {
  model: MarkerBarState,
  item: PlayerItem,
  isActive: boolean,
  isLastAddedReaction: boolean
}

const MarkerItem = observer(({
  model,
  item,
  isActive,
  isLastAddedReaction
}: MarkerItemProps) => {

  const { player } = model;
  const target = item?.comment || item?.reaction;
  if (!target)
    return null;

  const { reaction, watchMode } = target;
  const markerPosition = model.getPosition(target);

  if (!markerPosition) return null; //including 0 for the moment

  const isHovered = model.hoveredCommentId === target.id;

  const className = classNames('marker-item', {
    'hovered': isHovered,
    'active': isActive && !isLastAddedReaction,
    'reaction-item': target.isReaction,
    'comment-item': target.isComment && !target.isReply
  });

  let revealElem: React.ReactNode;
  let markerElem: React.ReactNode;

  if (reaction) {
    revealElem = (
      <PlayerReactionIcon
        className={`${isLastAddedReaction ? 'mask-icon' : ''} marker-reveal`}
        reactionName={reaction}
        watchMode={watchMode} />
    );
    markerElem = (
      <div className="marker reaction-marker" />
    );

  } else {

    revealElem = (
      <UserAvatar className="marker-reveal"
        model={target.actualUser} />
    );
    markerElem = (
      <div className="marker comment-marker" />
    );
  }

  return (
    <li key={target.id}
      className={className}
      onClick={() => player?.invoke('jumpToCommentOrReaction', { target })}
      onMouseEnter={() => model.setHovered(target.id)}
      onMouseLeave={() => model.removeHover()}
      style={{ left: cssPercentage(markerPosition) }}>

      {markerElem}
      {revealElem}
    </li>
  );
});