import React from 'react';
import { observer } from 'mobx-react-lite';
import { TopicSelectorSection } from './topicSelectorSection';
import { GenericSelectorSection } from './genericSelectorSection';
import { MomentSplashState } from './momentSplashState';

type Props = {
  model: MomentSplashState
}

export const TopicSelectorContent = observer(({
  model
}: Props) => {

  return (
    <div className="topic-selector-content splash-content tmar12">
      <h2 className="tcenter vpad8 width36">
        Select topics and subtopics important to you.
      </h2>

      <div className="content-box width48">
        <TopicSelectorSection
          model={model} />

        <hr />

        {model.selector?.hasSubTopics && <GenericSelectorSection
          model={model} />}
      </div>
    </div>
  );
});