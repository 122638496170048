import { makeObservable, computed, observable, action } from 'mobx';

import { Store } from '../../store/store';
import { BindingProps, refProxy, StoreNode } from '../../store';

import { CommentThreadState, CommentThreadProps } from './commentThreadState';
import { CommentInputState } from './commentInputState';
import { JobModel, Comment, SyncStatus } from '../../entities';
import { IPlayer } from '../player';
import { PlayerState } from '../player/playerState';
import { PlayerFramesetState } from '../playerFrameset/playerFramesetState';

export type PlayerCommentsProps = BindingProps<{
  jobId?: string | null,
  player?: IPlayer,
  frameset: PlayerFramesetState,
  playerTutorialHighlightedComponents?: string[];
}>;

export class PlayerCommentsState
  extends StoreNode {

  constructor(store: Store, props: PlayerCommentsProps) {
    super(store, props);
    makeObservable(this);
  }

  readonly scrollElementProxy = refProxy(this);
  readonly sectionProxy = refProxy(this);

  readonly commentInput: CommentInputState = new CommentInputState(this.store, {
    jobId: () => this.jobId,
    player: () => this.player
  });

  @computed get jobId(): string | null {
    return this.resolvedProps.jobId ?? null;
  }

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed get comments(): Comment[] {
    return this.job?.comments ?? [];
  }

  @computed get commentsSyncStatus(): SyncStatus | null {
    return this.job?.commentsSyncStatus || null;
  }

  @computed
  get player(): PlayerState {
    return this.resolvedProps.player;
  }
  @computed
  get frameset(): PlayerFramesetState | null {
    return this.resolvedProps.frameset ?? null;
  }

  @computed get activeComment(): Comment | null {
    return this.player.momentView.activeComment;
  }
  @computed get allActiveComments(): Comment[] {
    return this.player.momentView.allActiveComments;
  }

  @computed get shouldHideSection(): boolean {
    return !this.commentThreads?.length && !(this.player?.isWidgetMode && this.player.tutorialMode);
  }

  @computed get commentThreads(): CommentThreadState[] {
    return this.comments
      .filter(comment => !comment.parentId)
      .map(comment => {
        const replies = this.comments.filter(c => comment.id === c.parentId);
        const props: CommentThreadProps = { playerComments: this, threadComments: [comment, ...replies] };
        return new CommentThreadState(this.store, props);
      });
  }

  @observable showSection: boolean = true;

  @observable scrollbarWidth: string | null = null;

  @observable isActiveCommentIntoView: boolean = false;

  isCommentThreadActive(commentThread: CommentThreadState): boolean {
    return (
      commentThread.isActive ||
      this.allActiveComments.some((comment: Comment) => comment.id === commentThread.commentModel.id));
  }

  @action
  toggleSection() {
    this.showSection = !this.showSection;
  }

  @action
  setScrollbarWidth(el: HTMLElement) {
    // Find a better approach
    if (this.player.frameset?.isWidgetMode) {
      this.scrollbarWidth = `0.4rem`;
      return;
    }

    this.scrollbarWidth = `${el.offsetWidth - el.clientWidth}px`;
  }

  private alignTopScrollValue(elTop: number, scrollTop: number, scrolledTop: number, offset: number = 0) {
    return (
      (elTop - scrollTop)             // determine the position of the scroll item relative to the top of the scroll element
      + scrolledTop                   // add it to the already scrolled value
      - offset                        // subtract the header offset
    )
  }

  @action
  private scrollToComment = (comment: Comment | null, isReply = false) => {

    if (!comment) return;

    const sectionElement = this.sectionProxy.current;
    if (!sectionElement) return;

    const scrollElement = sectionElement.querySelector(`#player-comments-scroll`);
    if (!scrollElement) return;

    const topOffsetElement = sectionElement.querySelector('#player-comments-header');
    const bottomOffsetElement = sectionElement.querySelector(`#player-comments-footer`);
    const topOffsetElementHeight = topOffsetElement?.scrollHeight || 0;         // header overlapping the scroll box (scrollHeight to include the pseudo element)
    const bottomOffsetElementHeight = bottomOffsetElement?.clientHeight || 0;   // footer overlapping the scroll box
    const elSelector = isReply && (scrollElement.clientHeight - topOffsetElementHeight - bottomOffsetElementHeight) < 200 ? // if the scroll element height is higher than 200px it should still scroll to the top of the comment thread
      `#comment-reply-input-${comment.id}` :
      `[data-comment="true"][data-comment-id="${comment.id}"]`;

    const domComment = sectionElement.querySelector(elSelector);

    if (!domComment || !scrollElement)
      return;

    const scrollElementTop = scrollElement.getBoundingClientRect().top;   // top value of the scroll element relative to the viewport
    const scrollElementScrollTop = scrollElement.scrollTop;               // how much is the element scrolled (always positive or 0)
    const domMomentTop = domComment.getBoundingClientRect().top;          // top value of the scroll item element relative to the viewport

    const topValue = this.alignTopScrollValue(domMomentTop, scrollElementTop, scrollElementScrollTop, topOffsetElementHeight);
    scrollElement.scrollTo({
      top: topValue,
      behavior: 'smooth'
    })
  }

  private isCommentIntoView(comment: Comment | null) {
    if (!comment) return;

    const sectionElement = this.sectionProxy.current;

    if (!sectionElement) return;

    const topOffsetElement = sectionElement.querySelector('#player-comments-header');
    const scrollElement = sectionElement.querySelector(`#player-comments-scroll`);
    const bottomOffsetElement = sectionElement.querySelector(`#player-comments-footer`);
    const domComment = this.domGetComment(comment.id);

    if (!domComment || !scrollElement)
      return;

    const topOffsetElementHeight = topOffsetElement?.clientHeight || 0;         // header overlapping the scroll box
    const bottomOffsetElementHeight = bottomOffsetElement?.clientHeight || 0;   // footer overlapping the scroll box
    const scrollElementTop = scrollElement.getBoundingClientRect().top;         // top value of the scroll element relative to the viewport
    const scrollElementBottom = scrollElement.getBoundingClientRect().bottom;   // bottom value of the scroll element relative to the viewport
    const domMomentTop = domComment.getBoundingClientRect().top;                // top value of the scroll item relative to the viewport
    const domMomentBottom = domComment.getBoundingClientRect().bottom;          // bottom value of the scroll item relative to the viewport

    return (
      domMomentTop >= (scrollElementTop + topOffsetElementHeight) &&            // top of the item should be lower than the top of the scroll + offset
      (domMomentBottom < (scrollElementBottom - bottomOffsetElementHeight))     // bottom of the item should be higher than top of the scroll
    );
  }

  private domGetComment(id: string) {
    return document.querySelector(`[data-comment="true"][data-comment-id="${id}"]`);
  }

  @action
  async mounted() {
    this.updateIsCommentIntoView();
    this.scrollToCurrent();
  }

  @action
  scrollToCurrentComment() {
    this.scrollToComment(this.activeComment);
  }

  @action
  scrollToCurrent() {
    this.scrollToCurrentComment();
  }

  @action
  scrollOnReply(comment: Comment) {
    setTimeout(() => this.scrollToComment(comment, true), 200)
  }

  @action
  scrollOnEdit(comment: Comment) {
    setTimeout(() => this.scrollToComment(comment), 200)
  }

  @action
  handleScroll = () => {
    this.updateIsCommentIntoView();
  }

  @action
  updateIsCommentIntoView() {
    this.isActiveCommentIntoView = this.isCommentIntoView(this.activeComment) || false;
  }

  @action
  reset() {

  }

};