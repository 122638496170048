import './speakers.scss';
import '../menu/menu.scss';
import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { HTMLDivProps, Maybe } from '../../core';
import { UserAvatarSVGIcon } from '../svg';
import { LoadMask } from '../loader';

interface ISpeakerAvatar {
  displayName?: Maybe<string>,
  pictureURL?: Maybe<string>
}

type Props = HTMLDivProps<{
  model?: Maybe<ISpeakerAvatar>;
  isLoading?: boolean;
  onLoad?: () => void;
}>;

/**
 * Displays the avatar for the provided Speaker.
 */
export const SpeakerAvatar = observer(
  ({ model, className, isLoading, onLoad }: Props) => {
    const picture = model?.pictureURL;

    className = classNames(
      'speaker-avatar avatar-core',
      {
        empty: !picture,
      },
      className
    );

    return (
      <div className={className}>
        {picture ? (
          <img
            className="profile-picture"
            src={picture}
            alt={model?.displayName || 'Speaker avatar'}
            onLoad={() => onLoad && onLoad()}
          />
        ) : (
          <UserAvatarSVGIcon />
        )}
        <LoadMask
          className={'avatar-load-mask spinner small'}
          spinner={'dots3'}
          visible={isLoading}
        />
      </div>
    );
  }
);
