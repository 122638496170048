import { action, makeObservable, observable } from 'mobx';
import { Nullable } from '../core';
import { Store } from '../store/store';
import { StoreNode } from '../store';
import debounce from 'lodash/debounce';


type Props = {
  pageSize?: number;
}

export class BookmarkCatalogSource
  extends StoreNode {

  readonly nodeType: 'BookmarkCatalogSource' = 'BookmarkCatalogSource';

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.pageSize = this.props.pageSize || 20;
    this.listId = '';
    this.afterToken = null;
  }

  readonly listId: string;

  @observable cursor: number = 0;
  @observable pageSize: number;
  @observable afterToken: Nullable<string>;

  debouncedFetch = debounce(this.fetch, 500);
  async fetch() {

    const [,] = await this.store.apiFetchBookmarks({
      listId: this.listId,
      first: this.pageSize,
      after: this.afterToken || undefined
    });
  }

  async fetchMore() {

  }

  @action
  setPageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.debouncedFetch();
  }
}

