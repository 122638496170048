import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { Button } from '../input';
import { MakeTeamPublicWindowState } from '.';

type Props = {
  model: MakeTeamPublicWindowState;
  visible?: boolean;
};

export const MakeTeamPublicWindow = observer(({ model, visible }: Props) => {
  return (
    <Window
      model={model.window}
      visible={visible}
      isLoading={model.isLoading}
      className="make-team-public-window">
      <WindowHeader model={model.window} title="Make library shareable" />

      <hr />

      <div className="window-content">
        <p className="p-bold">IMPORTANT:</p>
        <p className="p-bold">
          By making this library Shareable, you will remove all the security
          protections of a Private library.
        </p>
        <p className="p-bold">
          This means that everyone will be able to view the videos in CLIPr by
          accessing the library's URL. This means that everyone will be able to
          view this library and the videos that you set to Shareable wherever the
          library's widget is embedded.
        </p>
      </div>

      <hr />

      <footer className="window-footer">
        <div className="btn-group right">
          <Button
            className="primary btn-outline medium"
            label="Cancel"
            onClick={(evt) => model.close()}
          />
          <Button
            className="red medium"
            label="Make it shareable"
            onClick={(evt) => model.submit()}
          />
        </div>
      </footer>
    </Window>
  );
});
