import { ClientError, GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { AsyncResult } from '../core';
import { isAbortError } from '../core/errorUtils';
import { apiErrorResult } from './apiError';
import { ApiRequestParams } from './apiSchema';

export async function runGraphQlApiRequest<TData = any, TVars = any>(params: ApiRequestParams<TVars>): AsyncResult<TData> {

  const {
    document: doc,
    variables: vars,
    endpoint,
    token
  } = params;

  const reqInitOpts: RequestInit = {
    mode: 'cors',
  };

  if (params.signal)
    reqInitOpts.signal = params.signal as RequestInit['signal'];

  const client = new GraphQLClient(endpoint, reqInitOpts);
  if (token)
    client.setHeader('Authorization', token);

  try {
    return [await client.request<TData, TVars>(doc, vars)];
  } catch (e) {

    const err: any = e;

    if (isAbortError(err))
      return apiErrorResult('RequestAborted', null, err);

    if (err instanceof ClientError) {

      const message = err.message.split(':')[0];
      console.error('GraphQL Error', message);

      if (err.response.status === 401)
        return apiErrorResult('ProviderNotAuthorized', message, err);

      else if (message === 'Unauthorized')
        return apiErrorResult('NotAuthorized', message, err);

      return apiErrorResult('ProviderError', message, err);
    }

    return apiErrorResult('ProviderError', null, err as any);
  }
}