import '../../components/layout/layout.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../store/storeHooks';
import { NotificationMessage } from './notificationMessage';

type Props = {};

export const NotificationsChunk = observer((props: Props) => {
  const store = useStore();
  const { notifications } = store;

  return (
    <div className="notifications-chunk page-chunk">
      <NotificationMessage
        notification={notifications.notification}
        visible={notifications.isVisible}
        onClose={() => notifications.hide()}
      />
    </div>
  );
});
