import React from 'react';
import { observer } from 'mobx-react-lite';
import { Catalog } from '../layout';
import { CatalogState } from '../layout/catalogState';
import { CatalogFooter } from '../layout/catalogFooter';
import { SearchSVGIcon, AdminTeamsLinkSVGIcon } from '../svg';
import { AdminTeamsCatalogSource } from '../../entities/adminTeamsCatalogSource';
import { AdminTeamsTableRow } from '.';

type Props = {
  model: CatalogState;
  source: AdminTeamsCatalogSource;
};

export const AdminTeamsTableCatalog = observer(({ model, source }: Props) => {
  const { entities } = source;

  return (
    <Catalog className="admin-teams-table-catalog catalog" model={model}>
      {entities.length > 0 && (
        <>
          <table className="admin-teams-table admin-teams-table-core">
            <tbody>
              {entities.map((team) => {
                return (
                  <AdminTeamsTableRow
                    key={team.id}
                    model={team}
                    source={source}
                  />
                );
              })}
            </tbody>
          </table>

          <CatalogFooter model={model} />
        </>
      )}

      {entities.length === 0 &&
        (!!source.searchFilter ? (
          <div className="empty-box" aria-hidden={model.isLoading}>
            <div className="catalog empty-catalog">
              <div className="icon">
                <SearchSVGIcon />
              </div>

              <h1 className="headline-1">
                No results found for "{source.searchFilter}".
              </h1>
              <p>
                Make sure all words are spelled correctly or try different
                keywords.
              </p>
            </div>
          </div>
        ) : (
          <div className="empty-box" aria-hidden={model.isLoading}>
            <div className="catalog empty-catalog">
              <div className="icon">
                <AdminTeamsLinkSVGIcon />
              </div>
              <h1 className="headline-1">
                Content to be reviewed will appear here
              </h1>
            </div>
          </div>
        ))}
    </Catalog>
  );
});
