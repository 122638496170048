import { action, computed, makeObservable, observable } from 'mobx';
import { Nullable } from '../../core';
import { notifyError, notifyLoading, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { input, inputGroup, InputGroupState, InputState } from '../input';

export class MergeTracksWindowState
  extends StoreNode {

  readonly nodeType = 'MergeTracksWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.targetTrack = input(this, {
      name: 'speaker',
      selectorItems: () => this.job?.transcriptTracks.filter(track => track?.id !== this.sourceTrackId).map(track => {
        return {
          value: track.id,
          label: track.speaker?.name || track.name
        }
      }),
      isRequired: () => true
    });
    this.saveButton = input(this, {
      name: 'saveButton',
      disabled: (input: any) => {
        return (
          input.loading ||
          input.isSubmitting ||
          (this.inputGroup as any).hasTouchedErrorInputs);
      }
    });
    this.cancelButton = input(this, {
      name: 'cancelButton',
      disabled: (input: any) => {
        return (
          input.loading ||
          input.isSubmitting);
      }
    });
    this.inputGroup = inputGroup(this, {
      name: "speaker",
      inputs: [
        this.targetTrack,
        this.saveButton,
        this.cancelButton
      ]
    });

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);
  readonly saveButton: InputState;
  readonly cancelButton: InputState;
  readonly targetTrack: InputState;
  readonly inputGroup: InputGroupState;
  @observable isLoading = false;

  @observable sourceTrackId: Nullable<string> = null; //as track
  @observable jobId: string | null = null;

  @computed
  get sourceTrack() {
    const { sourceTrackId } = this;

    if (sourceTrackId)
      return this.job?.getTrack(sourceTrackId);

    return null;
  }

  @computed
  get job() {
    const { jobId } = this;
    if (jobId)
      return this.store.getJob(jobId);
    return null;
  }

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading || this.inputGroup.isSubmitting)
          return;
        this.cancel();
        break;
    }
  }

  @action
  async submit() {
    this.inputGroup.handleSubmit();
    this.window.lock();
    notifyLoading(this, 'Updating Swimlane');
    if (this.inputGroup.error) {
      this.handleSubmitReject('Fix the validation errors before saving.');
      return;
    }
    const targetTypeId = this.targetTrack?.normValue;
    const sourceTargetName = this.sourceTrack?.speaker?.name;

    if (!this.jobId || !this.sourceTrackId || !targetTypeId) {
      this.handleSubmitReject('Prerequisites failed.');
      return;
    }

    this.isLoading = true;

    try {
      const mergeTracksArgs = {
        jobId: this.jobId!,
        targetTrackId: targetTypeId!,
        sourceTrackId: this.sourceTrackId!
      }
      const [, err] = await this.job?.apiMergeTracks(mergeTracksArgs) || [null, 'Invalid job'];

      if (err)
        throw new Error(err);
    } catch (e) {
      let message = "Failed to merge the swimlanes.";
      this.handleSubmitReject(message);
      return;
    }

    const targetTrack = this.job?.getTrack(targetTypeId);
    this.handleSubmitSuccess(`Swimlanes ${sourceTargetName} and  ${targetTrack?.speaker?.name} merged successfully.`);
  }

  private handleSubmitReject(msg: string) {
    this.inputGroup.handleSubmitReject();
    this.window.unlock();
    this.isLoading = false;
    notifyError(this, msg);
  }

  private handleSubmitSuccess(msg: string) {
    this.inputGroup.handleSubmitResolve();
    this.window.unlock();
    this.cancel();
    notifySuccess(this, msg);
  }

  @action
  cancel() {
    this.emit('cancel');
    this.close();
  }

  @action
  private open() {
    this.dispatch('Overlays', 'openWindow', { name: 'MergeTracksWindow' });
    this.window.open();
  }

  @action
  async openMerge(trackId: string, jobId: string) {

    this.sourceTrackId = trackId;
    this.jobId = jobId;

    this.open();
  }

  @action
  private close() {
    this.dispatch('Overlays', 'closeWindow');
    this.window.close();
    // this.reset();
  }

  @action
  onTransitionEnd = () => {
    if (!this.window.isVisible)
      this.reset();
  }

  @action
  private reset() {
    this.sourceTrackId = null;
    this.jobId = null;
    this.isLoading = false;
    this.inputGroup.clear();
  }
}