import { AsyncResult, Result } from '../../core';
import { Error } from '../../core/error';
import { RouteContext } from '../../routes/routeContext';
import { AuthError } from './authError';
import { AuthContext } from './authSchema';

export interface IAuthFlow {
  run(...args: any[]): AsyncResult<AuthFlowResponse>;
  tryRunSync?(...args: any[]): Result<AuthFlowResponse>;

  flowName: AuthFlowName;
  routeContext?: RouteContext;
  isLibraryFlow?: boolean;
}

export enum AuthFlowName {
  ProxyLogin = 'ProxyLogin',
  InlineProxyLogin = 'InlineProxyLogin',
  InlineProxyLogout = 'InlineProxyLogout',

  AuthorizeLibrary = 'AuthorizeLibrary',
  ProxyAuthorizeLibrary = 'ProxyAuthorizeLibrary',
  DeauthorizeLibrary = 'DeauthorizeLibrary',
  ProxyDeauthorizeLibrary = 'ProxyDeauthorizeLibrary',

  Login = 'Login',
  Logout = 'Logout',
  Register = 'Register',
  SocialLogin = 'SocialLogin',
  Onboard = 'Onboard',
  ResetPassword = 'ResetPassword',

  PublicWidget = 'PublicWidget',
  ExternalTokenWidget = 'ExternalTokenWidget',
  ChannelTokenWidget = 'ExternalTokenWidget',
  OptionalLoginWidget = 'OptionalLoginWidget',
  RequiredLoginWidget = 'RequiredLoginWidget',

  ProxyAuthorizeServer = 'ProxyAuthorizeServer',
  ProxyAuthorizeLibraryServer = 'ProxyAuthorizeLibraryServer',
  ProxyDeauthorizeServer = 'ProxyDeauthorizeServer',

  AuthRoute = 'AuthRoute',
  PublicRoute = 'PublicRoute',
  PrivateRoute = 'PrivateRoute',
  OnboardRoute = 'OnboardRoute',
  
  RefreshPermit = 'RefreshPermit'
}

export enum AuthFlowResponseType {
  PassThroughAuthRoute = 'PassThroughAuthRoute',
  RedirectToLoginPage = 'RedirectToLoginPage',
  RedirectToOnboardPage = 'RedirectToOnboardingPage',
  RedirectToProxyLoginWidget = 'RedirectToProxyLoginWidget',
  RedirectToExternalLoginWidget = 'RedirectToExternalLoginWidget',
  RedirectToLastPrivateRoute = 'RedirectToAuthorizedLibraryRoute',
  RedirectToLastWidgetRoute = 'RedirectToLastWidgetRoute',
  RedirectToSessionWidgetRoute = 'RedirectToSessionWidgetRoute',
  Authorized = 'Authorized',
  LibraryAuthorized = 'LibraryAuthorized',
  AuthorizedAsAnonymous = 'AuthorizedAsAnonymous',
  Deauthorized = 'Deauthorized',
  NotifyAuthenticated = 'NotifyAuthenticated',
  NotifyDeauthorized = 'NotifyDeauthorized',
  AwaitRedirect = 'AwaitRedirect',
  AwaitProxyClose = 'AwaitProxyClose',
  ResetPasswordEmailSent = 'ResetPasswordEmailSent'
}

export enum AuthFlowRenderMode {
  None = 'None',
  Loading = 'Loading',
  Error = 'Error',
  DisplayLoginButton = 'DisplayLoginButton',
}

export type AuthFlowResponse = {
  responseType: AuthFlowResponseType;
  context?: AuthContext | null;
  error?: AuthError | null;
  redirectPath?: string | null;
  resetPasswordData?: {
    message: string | null;
    destination: string | null;
  }
}

export type Permissions = {
  isPublic: boolean;
  canView: boolean;
  
  /** 
   * The error to display in the case the user does not have permissions. 
   * It is optional, so only provide it if you want to display a custom message,
   * otherwise a default message will be created by each flow.
   */
  error?: Error | null;
}

export interface IPermissionsSource {
  fetchPermissions(): AsyncResult<Permissions>;
}