import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { action, makeObservable, observable } from 'mobx';
import { Maybe, Nullable } from '../../core';
import last from 'lodash/last';

export class OverlayService
  extends StoreNode {

  readonly anchors = observable.map<string, HTMLElement>();

  readonly activeWindows = observable.array<string>();
  get visibleWindow(): Nullable<String> {
    return last(this.activeWindows) || null;
  }

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  @action
  invoke(type: string, payload?: any) {

    switch (type) {
      case 'chunkMounted':
        break;

      case 'chunkUnmounted':
        break;

      case 'anchorMounted':
        // assert(!this.anchors.has(payload.id),
        //   `There's an anchor with id ${payload.id} already mounted.`);

        this.anchors.set(payload.id, payload.node);
        break;

      case 'anchorUnmounted':
        this.anchors.delete(payload.id);
        break;

      case 'openWindow':
        this.openWindow(payload.name);
        break;

      case 'closeWindow':
        this.closeWindow();
        break;
      default: break;
    }
  }

  @action
  openWindow(name: string) {
    this.activeWindows.push(name);
  }

  @action
  closeWindow() {
    this.activeWindows.pop();
  }

  getAnchor(id: Maybe<string>): Nullable<HTMLElement> {
    if (!id)
      return null;

    return (
      this.anchors.get(id) ||
      document.getElementById(id) ||
      null);
  }
}