
import './input.scss';
import assert from 'assert';
import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames/dedupe';
import { InputState } from './inputState';
import { Maybe } from '../../core';
import { paramCase } from 'change-case';
import { InputProps } from './inputSchema';
import { HTMLSelectProps } from '../../core/reactSchema';
import find from 'lodash/find';

export type SelectorObjectItem = {
  id: string,
  label: string
}

export type SelectorItem = SelectorObjectItem | string;

type Props =
  Omit<HTMLSelectProps, 'value' | 'placeholder'> &
  InputProps<SelectorItem> & {
    multiple?: boolean,
    items?: SelectorItem[],
    showEmptyItem?: Maybe<boolean>
  };

const itemId = (item: SelectorItem) =>
  typeof item === 'object' ? item.id : item;

const itemLabel = (item: SelectorItem) =>
  typeof item === 'object' ? item.label : item;

export const SelectInput = observer(({
  value,
  placeholder,
  className,
  label,
  isFocused,
  isHovered,
  status,
  statusMessage,
  showStatus,
  showEmptyItem = true,
  labelPosition = 'topStart',
  showFeedback,
  feedbackPosition: messagePosition = 'bottom',
  ...props
}: Props) => {

  className = classNames('select-input input', className, {
    'focus': isFocused
  });

  const inputValue = typeof value === 'object' ? value?.id : value;

  const inputElem =
    <select
      className="native-input native-select-input"
      id={props.id}
      name={props.name}
      value={inputValue || ''}
      multiple={props.multiple}
      {...props}>

      <option
        className="select-option empty"
        value={''}
        key="__EMPTY__"
        hidden={!showEmptyItem}>
        {placeholder || 'Select option'}
      </option>

      {(props.items || []).map((item, i) => {

        return (
          <option
            className="select-option"
            value={itemId(item)}
            key={itemId(item)}>

            {itemLabel(item)}
          </option>
        );
      })}
      {(typeof value === 'object' && value && !props.items?.includes(value)) && <option value={value?.id} hidden>{value?.label}</option>}
      {(typeof value === 'string' && !props.items?.includes(value)) && <option value={value} hidden>{value}</option>}
    </select>

  const labelElem = label &&
    <label className={['input-label', paramCase(labelPosition)].join(' ')}>
      {label}
    </label>

  const feedbackElem = statusMessage && showFeedback &&
    <div className={['feedback input-feedback', paramCase(messagePosition)].join(' ')}
      aria-live="polite">
      {statusMessage}
    </div>

  return (
    <div className={className}
      role="listbox"
      aria-disabled={props.disabled}>

      <div className="select-input-box input-box" />

      {inputElem}
      {labelElem}
      {feedbackElem}
    </div>
  );
});

type ControlledProps =
  Props & {
    model: InputState,
  };


/**
 * Wrapper for SelectInput in which the entire state and all the handlers are provided through an InputState object.
 */
export const SelectInputControlled = observer(({
  model,
  className,
  ...props
}: ControlledProps) => {

  assert(model instanceof InputState,
    `The 'model' passed to SelectInputControlled must be an instance of InputState. Instead received ${model}.`);

  const handleChange = useCallback(evt => {
    let val = find(model.selectorItems, ['id', evt.target.value]) || evt.target.value;
    model.handleChange(evt, val || null)
  },
    [model]);

  className = model.getClassName('select-input', { className });

  return <SelectInput
    className={className}
    value={model.value || ''}
    placeholder={model.placeholder}
    disabled={model.disabled}
    label={model.label}
    items={model.selectorItems}
    isHovered={model.isHovered}
    isFocused={model.isFocused}
    onChange={handleChange}
    onFocus={model.handleFocus}
    onBlur={model.handleBlur}
    onPointerEnter={model.handlePointerEnter}
    onPointerLeave={model.handlePointerLeave}
    status={model.status}
    statusMessage={model.statusMessage}
    showStatus={model.showStatus}
    showFeedback={model.showStatusMessage}
    labelPosition={model.labelPosition || undefined}
    feedbackPosition={model.feedbackPosition || undefined}
    {...props} />
});