import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../../components/overlays';
import { Button, DropdownInputControlled } from '../../components/input';
import { UploadJobDetailsWindowState } from './uploadJobDetailsWindowState';
import { getJobLevelInputLabel } from '../../entities/job/jobFeatures';

type Props = {
  model: UploadJobDetailsWindowState;
  visible?: boolean;
};

export const UploadJobDetailsWindow = observer(({ model, visible }: Props) => {
  return (
    <Window
      model={model.window}
      visible={visible}
      isLoading={model.isLoading}
      className="upload-job-details-window"
    >
      <WindowHeader model={model.window} title="Modify Output" />

      <hr />

      <div className="window-content">
        <p>Choose how CLIPr creates your index of Topics</p>

        {model.isLoaded && (
          <div className="controls">
            <DropdownInputControlled
              className="level-input"
              label={getJobLevelInputLabel()}
              model={model.levelInput!}
              tooltipContent={<JobLevelInputTooltip />}
              notDeselectable={true}
            />

            <DropdownInputControlled
              className="speciality-input"
              label="Video Speciality"
              required={!model.isSpecialityInputDisabled}
              disabled={model.isSpecialityInputDisabled}
              model={model.specialityInput!}
              tooltipContent={<VideoSpecialtyInputTooltip />}
              notDeselectable={true}
            />
          </div>
        )}
      </div>

      <hr />
      <footer className="window-footer">
        <div className="btn-group right">
          <Button
            className="primary btn-outline medium"
            label="Cancel"
            onClick={(evt) => model.close()}
          />
          <Button
            className="primary medium"
            label="Save"
            disabled={model.task?.form.hasErrorInputs}
            onClick={(evt) => model.submit()}
          />
        </div>
      </footer>
    </Window>
  );
});

export const JobLevelInputTooltip = () => (
  <>
   <strong>Video Processing:</strong> CLIPr just process your video.
    <br />
    <br />
    <strong>Transcript:</strong> CLIPr simply creates a downloadable transcript
    of your video.
    <br />
    <br />
    <strong>AI Enrichment:</strong> CLIPr’s native AI and machine learning will
    create a searchable, actionable index of Topics that your video contains.
    Each moment can be shared via social media, email, or shareable HTML.
    <br />
    <br />
    <strong>Custom Enrichment:</strong> A human editor reviews AI enrichment
    output for accuracy.
  </>
);

export const VideoSpecialtyInputTooltip = () => (
  <>
    <strong>Video Specialty:</strong> When selected, CLIPr applies a specialized
    glossary when indexing your video. In upcoming releases, we plan to add
    glossaries for legal and fiscal terminology.
  </>
);
