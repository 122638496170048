import '../jobs/jobs.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { JobCatalogSource } from '../../entities';
import { Catalog } from '../layout';
import { CatalogState } from '../layout/catalogState';
import { CatalogFooter } from '../layout/catalogFooter';
import { MyUploadsSVGIcon } from '../svg';
import { JobTableRow } from '.';

type Props = {
  model: CatalogState;
  source: JobCatalogSource;
  teamId?: string;
};

export const JobTableCatalog = observer(({ model, source, teamId }: Props) => {
  const { entities } = source;

  return (
    <Catalog className="job-table-catalog catalog" model={model}>
      {entities.length > 0 && (
        <>
          <table className="job-table job-table-core">
            <tbody>
              {entities.map((job) => {
                return (
                  <JobTableRow
                    key={job.id}
                    model={job}
                    teamId={teamId}
                    source={source}
                  />
                );
              })}
            </tbody>
          </table>

          <CatalogFooter model={model} />
        </>
      )}

      {entities.length === 0 && (
        <div className="empty-box" aria-hidden={model.isLoading}>
          <div className="catalog empty-catalog">
            <div className="icon">
              <MyUploadsSVGIcon />
            </div>
            <h1 className="headline-1">
              {teamId
                ? 'Uploaded content will appear here.'
                : ' Looks like you haven’t uploaded any videos.'}
            </h1>
          </div>
        </div>
      )}
    </Catalog>
  );
});
