import { makeObservable } from 'mobx';
import { Store } from '../../../store/store';
import { StoreNode } from '../../../store';
import { LinkExpiredFormState } from './linkExpiredFormState';

export class LinkExpiredPageState
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  readonly form = new LinkExpiredFormState(this.store);
}