import { FormEvent } from 'react';
import isEmail from 'validator/lib/isEmail';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { StoreNode, Message } from '../../store';
import { Store } from '../../store/store';
import { input, InputState, inputGroup, InputGroupState } from '../../components/input';
import { isNonEmptyString } from '../../core';
import { notifyError, notifySuccess } from '../../services/notifications';
import { ApiUploadRequestParams } from '../../api';
import { GenerativeAiProvider, NotificationsSubscription, NotificationsSubscriptionEdge, NotificationSubscriptionEmailEventType, NotificationSubscriptionSubscribedEvent, UpdateNotificationSubscriptionInput, UpdateProfileInput } from '@clipr/lib';
import { User } from '../../entities/user';
import { ApiVariables } from '../../api/apiSchema';
import { UserProfileAvatarSize } from '../../entities/userProfile';
import { SubscribedEventsState } from './subscribedEventsState';
import { ToggleChatGptWindowState } from '../../components/teamSettings/toggleChatGptWindowState';

const FILE_SIZE_IN_MB = 2;
const PHOTO_MAX_SIZE = FILE_SIZE_IN_MB * 1024 * 1024; // 10Mb 
const FILE_TYPES = ['image/jpeg', 'image/png'];
export class ProfilePageState extends StoreNode {
  readonly nodeType = 'ProfilePage';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    // Models
    this.firstNameModel = input(this, {
      name: 'firstName',
      value: store.user?.name,
      isRequired: true,
      error: (input: InputState) => {
        if (input.isEmpty || !input.value?.trim()) {
          return 'Required field';
        }
      }
    });
    this.lastNameModel = input(this, {
      name: 'lastName',
      value: store.user?.name,
    });
    this.emailModel = input(this, {
      name: 'email',
      value: store.user?.email,
      isRequired: true,
      error: (input: InputState) => {
        if (input.isEmpty) {
          return 'Required field';
        } else if (input.value && !isEmail(input.value?.trim())) {
          return 'Email address not valid'
        }
      }
    });

    // Notifications manager
    this.subscribedEvents = new SubscribedEventsState(this.store, {
      userNotificationSubscriptions: () => this.localUser?.notificationsSubscriptions,
      notificationSubscriptionsTypeEmail: () => this.notificationSubscriptionsTypeEmail
    });

    // Form
    this.accountInfoForm = inputGroup(this, {
      name: 'accountInfoForm',
      inputs: [
        this.firstNameModel,
        this.lastNameModel,
        this.emailModel,
      ]
    });
  };

  // readonly nameModel: InputState;
  readonly firstNameModel: InputState;
  readonly lastNameModel: InputState;
  readonly subscribedEvents: SubscribedEventsState;
  readonly emailModel: InputState;
  readonly accountInfoForm: InputGroupState;

  // Temporary
  @observable localUser: User | null = null;
  @observable isLoading: boolean = false;
  @observable editAccountInfo: boolean = false;
  @observable notificationSubscriptionsTypeEmail: NotificationSubscriptionEmailEventType[] | null = null;

  @action
  async initialize() {
    this.isLoading = true;
    this.subscribedEvents.listen(this.subscribedEventsListener)
    await this.updateLocalUser();
    runInAction(() => {
      this.setFormData();
      this.isLoading = false;
    })
  }

  @action
  async updateLocalUser() {
    this.subscribedEvents.reset();

    const args: ApiVariables<'getProfile'> = {
      avatarSize: [UserProfileAvatarSize]
    };
    const [[res1, err1], [res2, err2]] = await Promise.all([
      this.store.api.getProfile(args),
      this.store.api.getNotificationSubscriptionEmailEventTypes()
    ]);

    if (err1 || err2)
      return;

    runInAction(() => {
      if (res1) {
        const userNotificationSubscriptions: NotificationsSubscription[] | null =
          res1
            .getProfile
            .userNotificationSubscriptions?.edges
            .map((value: NotificationsSubscriptionEdge) => value.node) ?? null;

        const user = {
          ...res1.getProfile,
          notificationsSubscriptions: userNotificationSubscriptions
        }

        this.localUser = new User(this.store, user);
      }

      if (res2) {
        this.notificationSubscriptionsTypeEmail = res2.getNotificationSubscriptionEmailEventTypes as any;
      }
    })
  }

  @action
  setFormData() {
    this.firstNameModel.value = this.localUser?.firstName || '';
    this.lastNameModel.value = this.localUser?.lastName || '';
    this.emailModel.value = this.localUser?.email || '';
    this.subscribedEvents.init();
  }

  @action
  reset() {
    this.editAccountInfo = false;
    this.subscribedEvents.reset();
    this.subscribedEvents.unlisten(this.subscribedEventsListener);
  }

  @action
  toggleEdit() {
    this.editAccountInfo = !this.editAccountInfo;
  }

  @action
  cancel() {
    this.setFormData();
    this.toggleEdit();
  }

  private subscribedEventsListener = action(async (msg: Message<SubscribedEventsState>) => {
    switch (msg.type) {
      case 'notificationPreferencesChanged': {
        this.isLoading = true;
        const payload: UpdateNotificationSubscriptionInput = this.subscribedEvents.export();
        const { id, subscribedEvents } = payload;

        const [, err] =
          isNonEmptyString(id) ?
            await this.store.api.updateNotificationsSubscription({ args: payload }) :
            await this.store.api.createNotificationSubscription({
              args:
              {
                destination: this.store.user?.email || this.localUser?.email || '',
                subscribedEvents: subscribedEvents as NotificationSubscriptionSubscribedEvent[]
              }
            });

        runInAction(async () => {
          if (err) {
            notifyError(this, `Something went wrong.`);
          } else {
            await this.updateLocalUser();
            notifySuccess(this, `Your preferences have been saved.`);
          }
          this.setFormData();
          this.isLoading = false;
        })
        break;
      }
    }
  })

  @action
  async submitAccountInfo() {
    this.accountInfoForm.handleSubmit();

    if (this.accountInfoForm.error) {
      this.accountInfoForm.handleSubmitReject();
      return;
    }
    this.isLoading = true;

    const firstName = this.firstNameModel.value || '';
    const lastName = this.lastNameModel.value || '';
    const name = [firstName, lastName].join(' ');

    const params: { args: UpdateProfileInput } = {
      args: {
        firstName,
        lastName,
        name
      }
    };
    if (this.emailModel.value !== this.localUser?.email) {
      params.args.email = this.emailModel.value?.trim() || undefined;
    }

    const [, err] = await this.store.api.updateProfile(params);

    this.broadcast('userUpdated', params.args);

    await this.updateLocalUser();

    runInAction(() => {
      if (err) {
        this.accountInfoForm.handleSubmitReject();
        notifyError(this, `Something went wrong.`);
      } else {
        notifySuccess(this, `Your account information has been updated.`);
      }
      this.setFormData();
      this.isLoading = false;
      this.accountInfoForm.handleSubmitResolve();
      this.toggleEdit();
    })
  }

  @action
  toggleSummarization = async () => {
    if (!this.localUser) return;

    if (this.localUser?.chatGptSummaryEnabled) {
      await this.disableChatGpt();
      return;
    }

    const window = this.store.toggleChatGptWindow;
    const listener = (msg: Message<ToggleChatGptWindowState>) => {
      switch (msg.type) {
        case 'success':
          this.refreshForm()
          break;
        case 'close':
          window.unlisten(listener);
          break;
      }
    }
    window.listen(listener);
    window.open({
      provider: this.localUser.generativeAiProvider ?? GenerativeAiProvider.Amazon,
      summarizationEnabled: !this.localUser.chatGptSummaryEnabled,
      actionItemsExtractionEnabled: this.localUser.actionItemsExtractionEnabled,
      chatGptEnabled: this.localUser.cliprGptEnabled,
      header: 'Enable video summary',
      description: 'By enabling AI video summary, we will send parts of the video transcript to an AI provider to generate a summary, for all the existing and the new videos from this library.'
    });
  }

  @action
  toggleCliprGpt = async () => {
    if (!this.localUser) return;

    if (this.localUser?.cliprGptEnabled) {
      await this.disableCliprGpt();
      return;
    }

    const window = this.store.toggleChatGptWindow;
    const listener = (msg: Message<ToggleChatGptWindowState>) => {
      switch (msg.type) {
        case 'success':
          this.refreshForm()
          break;
        case 'close':
          window.unlisten(listener);
          break;
      }
    }
    window.listen(listener);
    window.open({
      provider: this.localUser.generativeAiProvider ?? GenerativeAiProvider.Amazon,
      summarizationEnabled: this.localUser.chatGptSummaryEnabled,
      actionItemsExtractionEnabled: this.localUser.actionItemsExtractionEnabled,
      chatGptEnabled: !this.localUser.cliprGptEnabled,
      header: 'Enable CLIPr GPT',
      description: 'By enabling CLIPr GPT, we will send parts of the video transcript to the AI provider to generate answers, for all the existing and the new videos from this library.'
    });
  }

  @action
  toggleActionItems = async () => {
    if (!this.localUser) return;

    if (this.localUser?.actionItemsExtractionEnabled) {
      await this.disableChatGptExtraActions();
      return;
    }

    const window = this.store.toggleChatGptWindow;
    const listener = (msg: Message<ToggleChatGptWindowState>) => {
      switch (msg.type) {
        case 'success':
          this.refreshForm();
          break;
        case 'close':
          window.unlisten(listener);
          break;
      }
    }
    window.listen(listener);
    window.open({
      provider: this.localUser.generativeAiProvider,
      summarizationEnabled: this.localUser.chatGptSummaryEnabled,
      actionItemsExtractionEnabled: !this.localUser.actionItemsExtractionEnabled,
      chatGptEnabled: this.localUser.cliprGptEnabled,
      description: 'By enabling ChatGPT action items, we will send parts of the video transcript to an AI provider to generate action items and next steps, for all the new videos from this library.',
      header: 'Enable action items',
    });
  }

  @action
  async changeAiProvider(value: string) {
    if (!this.localUser) return;

    const window = this.store.toggleChatGptWindow;
    const listener = (msg: Message<ToggleChatGptWindowState>) => {
      switch (msg.type) {
        case 'success':
          this.refreshForm();
          break;
        case 'close':
          window.unlisten(listener);
          break;
      }
    }
    window.listen(listener);
    window.open({
      provider: value as GenerativeAiProvider,
      summarizationEnabled: this.localUser.chatGptSummaryEnabled,
      actionItemsExtractionEnabled: this.localUser.actionItemsExtractionEnabled,
      chatGptEnabled: this.localUser.cliprGptEnabled,
      header: 'Change AI Provider',
      button: 'Confirm',
      description: 'Are you sure you want to change the AI provider? This means that all the videos from this library will be reprocessed.'
    });
  }

  @action
  async disableCliprGpt() {
    const vars: ApiVariables<'updateProfile'> = {
      args: {
        generativeAiSettings: {
          summarizationEnabled: this.localUser?.chatGptSummaryEnabled ?? false,
          actionItemsExtractionEnabled: this.localUser?.actionItemsExtractionEnabled ?? false,
          cliprGptEnabled: false,
          provider: this.localUser?.generativeAiProvider ?? GenerativeAiProvider.Amazon
        }
      }
    };

    const [, err] = await this.store.api.updateProfile(vars);
    if (err) {
      notifyError(this, `Could not update profile. Please try again.`);
      return;
    }

   this.refreshForm();
  }

  @action
  async disableChatGpt() {
    const vars: ApiVariables<'updateProfile'> = {
      args: {
        generativeAiSettings: {
          summarizationEnabled: false,
          actionItemsExtractionEnabled: this.localUser?.actionItemsExtractionEnabled ?? false,
          cliprGptEnabled: this.localUser?.cliprGptEnabled ?? false,
          provider: this.localUser?.generativeAiProvider ?? GenerativeAiProvider.Amazon
        }
      }
    };

    const [, err] = await this.store.api.updateProfile(vars);
    if (err) {
      notifyError(this, `Could not update profile. Please try again.`);
      return;
    }

    this.refreshForm();
  }

  @action
  async disableChatGptExtraActions() {
    const vars: ApiVariables<'updateProfile'> = {
      args: {
        generativeAiSettings: {
          summarizationEnabled: this.localUser?.chatGptSummaryEnabled ?? false,
          actionItemsExtractionEnabled: false,
          cliprGptEnabled: this.localUser?.cliprGptEnabled ?? false,
          provider: this.localUser?.generativeAiProvider ?? GenerativeAiProvider.Amazon
        }
      }
    };

    const [, err] = await this.store.api.updateProfile(vars);
    if (err) {
      notifyError(this, `Could not update profile. Please try again.`);
      return;
    }

    this.refreshForm();
  }

  @action
  async refreshForm() {
    this.isLoading = true;
    await this.updateLocalUser();
    this.setFormData();
    this.isLoading = false;
  }

  @action
  async submitPasswordForm() {
    this.isLoading = true;
    const [, err] = await this.store.authService.runResetPasswordFlow({
      email: this.store.user?.email || this.localUser?.email || ''
    });
    if (err) {
      notifyError(this, `Something went wrong.`);
      return;
    }
    this.isLoading = false;
    notifySuccess(this, 'Your reset password email has been sent.');
  }

  @action
  async handleFileInput(e: FormEvent<HTMLInputElement>) {
    const target = e.currentTarget;
    const fileList: FileList | null = target.files;
    if (fileList && fileList.length > 0) {
      const [file] = fileList;

      if (file.size >= PHOTO_MAX_SIZE) {
        this.handleUploadError(target, `Photo size should be smaller than ${FILE_SIZE_IN_MB}Mb.`);
        return;
      }
      if (!FILE_TYPES.includes(file.type)) {
        this.handleUploadError(target, 'The accepted formats are jpeg and png.');
        return;
      }

      this.isLoading = true;
      // Get the upload URL
      const [res, err] = await this.store.apiService.getProfilePictureUploadUrl({ fileName: file.name });

      if (err) {
        runInAction(() => this.handleUploadError(target, 'Could not upload.'));
        return;
      }

      if (res) {
        const params = res.getProfilePictureUploadUrl;
        const uploadParams: ApiUploadRequestParams = {
          file,
          url: params.uploadUrl,
          data: params.fields
        }

        // Start the upload request
        const uploadRequest = this.store.apiService.uploadRequest(uploadParams);
        const [, uploadErr] = await uploadRequest.start();

        if (uploadErr) {
          runInAction(() => this.handleUploadError(target, 'Could not upload.'));
          return;
        }

        // Update the profile picture if the upload was successful
        const [, updateError] = await this.store.apiService.updateProfile({ args: { avatarToken: params.uploadToken } });
        if (updateError) {
          runInAction(() => this.handleUploadError(target, 'Could not update the profile picture.'));
          return;
        }

        await this.updateLocalUser();
        runInAction(() => {
          this.subscribedEvents.init();
          notifySuccess(this, 'Your photo has been uploaded.');
          this.isLoading = false;
        })
      }
    }
  }

  @action
  handleUploadError(target: HTMLInputElement, message: string) {
    notifyError(this, message);
    target.value = '';
    this.isLoading = false;
  }

  @computed
  get isChanged(): boolean {
    if (!this.localUser) return false;

    return this.accountInfoForm.isChangedSinceLastSubmit;
  }
}