import { Metadata } from '@clipr/lib';
import { action, computed, IObservableArray, makeObservable } from 'mobx';
import { createArray } from '../../core/array';
import { getMetadataInputLabel, JobMetadataFields } from '../../entities/job/jobMetadata';
import { BindingProps, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { inputGroup } from '../input';
import { TeamMetadataItemState } from './teamMetadataItemState';

type TeamMetadataStateProps = BindingProps<{
  teamId?: string;
}>;

export class TeamMetadataState
  extends StoreNode {

  constructor(store: Store, props?: TeamMetadataStateProps) {
    super(store, props);
    makeObservable(this);
  }

  @computed get teamId(): string {
    return this.resolvedProps.teamId;
  }

  @computed get team() {
    return this.store.teamManager.getTeam(this.teamId);
  }

  readonly metadataFields: IObservableArray<TeamMetadataItemState>
    = createArray<TeamMetadataItemState>(true) as IObservableArray;

  readonly form = inputGroup(this, {
    name: 'metadataForm',
    inputs: () =>
      this.metadataFields.map(item => item.form)
  });

  init() {
    JobMetadataFields.forEach(item => {
      const fieldAlias = (this.team?.metadataFields && this.team?.metadataFields[item]) || null;
      this.metadataFields.push(new TeamMetadataItemState(this.store, {
        fieldName: item,
        fieldAlias: fieldAlias || getMetadataInputLabel(item),
        isActive: !!fieldAlias
      }));
    });
  }

  reset() {
    this.metadataFields.clear();
  }

  export(): Metadata {
    return this.exportMetadataFields();
  }

  @action
  private exportMetadataFields(): Metadata {
    const outputData = {};
    const metadataData = this.form.export();
    Object.keys(metadataData).forEach(key => {
      const field = metadataData[key];
      if (field.isActive === true)
        Object.assign(outputData, {
          [key]: field.fieldAlias
        })
    });
    return outputData;
  }
}