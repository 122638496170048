import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { UploadTask, UploadFile } from '../../services/upload';
import { Routes } from '../../routes';
import { getPlayerWidgetIFrameCode } from '../playerWidget/playerWidgetUtils';
import { getDefaultEnrichmentLevel } from '../../entities/job/jobFeatures';
import { RouteContext } from '../../routes/routeContext';
import { WidgetState } from '../widgetStateMixin';

import { INIT_DEBUGGER, TRACE } from '../../core/debug/debugMacros';

export type UploadCompactWidgetStatus =
  'Error' |
  'AwaitingUpload' |
  'Uploading' |
  'UploadSuccess' |
  'UploadError' |
  'NotAuthenticated';

export class UploadCompactWidgetState
  extends WidgetState(StoreNode) {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
    
    INIT_DEBUGGER(this, {
      color: 'deeppink'
    });
  }

  readonly nodeType = 'UploadCompactWidget';

  @observable uploadTask: UploadTask | null = null;
  @observable linkCopied: boolean = false;

  @computed get jobUrl(): string | null {
    if (this.status !== 'UploadSuccess')
      return null;

    const id = this.uploadTask?.outputJob?.id;
    if (!id)
      return null;

    let query = '';
    const { teamId } = this;
    if (teamId)
      query = `teamId=${teamId}`;

    return window.location.origin + Routes.userVideo(id, query);
  }

  @computed get teamId(): string | null {
    return this.store.widgetService.teamId;
  }

  @computed get status(): UploadCompactWidgetStatus {

    if (this.error)
      return 'Error';

    const task = this.uploadTask;
    if (!task)
      return 'AwaitingUpload';

    switch (task.status) {
      case 'completed':
        return 'UploadSuccess';
      case 'error':
        return 'UploadError';
      default:
        return 'Uploading';
    }
  }

  @action
  async mounted(routeContext: RouteContext) {
    TRACE(this, `attached()`, { routeContext }, '\n', this.__traceState);

    this.reset();
    this.baseAttached(routeContext);

    this.emit('Mounted', {
      teamId: this.teamId
    });
  }

  @action
  unmounted() {
    TRACE(this, `detached()`, this.__traceState);

    this.baseDetached();
    this.reset();

    this.emit('Unmounted');
  }

  @action
  reset() {
    this.baseReset();
  }

  handleFileInputChange = action(async (evt: React.ChangeEvent<HTMLInputElement>) => {

    const file = [...evt.target.files || []].map(file => ({
      file,
      languageCode: 'en-US',
      isPublic: true,
      jobLevel: getDefaultEnrichmentLevel()
    } as UploadFile))[0] || null;

    const task = this.store.uploadService.createTask(file, {
      teamId: this.teamId ?? undefined
    });
    this.uploadTask = task;
  })

  @action
  async copyIFrameEmbedCode() {
    const jobId = this.uploadTask?.outputJob?.id ?? null;

    if (!jobId)
      return;

    const code = getPlayerWidgetIFrameCode({ jobId });
    if (!code)
      return;

    await navigator.clipboard?.writeText(code);

    this.displayNotification();
    setTimeout(() => {
      this.hideNotfication();
    }, 3000);
  }

  @action
  displayNotification = () => {
    this.linkCopied = true;
  }

  @action
  hideNotfication = () => {
    this.linkCopied = false;
  }
  
  private get __traceState() {
    return {
      ...this.__baseTraceState
    }
  }
}