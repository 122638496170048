import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../../components/overlays';
import { Button } from '../../components/input';
import { CloseWindowConfirmationModalState } from './closeWindowConfirmationModalState';

type Props = {
  model: CloseWindowConfirmationModalState;
  visible?: boolean;
};

export const CloseWindowConfirmationModal = observer(({ model, visible }: Props) => {

  return (
    <Window model={model.window} visible={visible}>
      <WindowHeader title={model.title || "Close window"} model={model.window} />

      <hr />

      <section className="window-section pad4">
        <br />
        {model.modalMessage || 'Are you sure you want to close the window? All progress will be lost.'}<br />
        <br />
      </section>

      <footer className="window-footer pad4">
        <div className="col left"></div>
        <div className="col right">
          <div className="btn-group right gap2">
            <Button
              label="Cancel"
              className="secondary light"
              onClick={() => model.close()}
              disabled={model.isLoading}
            />

            <Button
              label="Close"
              className="primary light"
              onClick={() => model.submit()}
              disabled={model.isLoading}
            />
          </div>
        </div>
      </footer>
    </Window>
  );
});