import { InputState, input } from '../input';
import { StoreNode } from '../../store';
import { action, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';

type Props = {
  initialValue?: string,
}

export class AdsItemState
  extends StoreNode {

  @observable isSyncing = false;
  @observable isSynced = false;
  @observable isLocked = false;

  @observable error: string | null = null;

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.urlInput = input(this, {
      name: 'vastUrl',
      isRequired: false,
      placeholder: "VAST URL",
      disabled: (input: any) => {
        return this.isLocked
      },
      error: input => {
        if (this.isSynced)
          return null;

        // const error = this.props?.checkLinkValidity(input.value);

        if (input.isRequired && input.isEmpty)
          return 'Required field';

        // if (!input.isEmpty && error)
        //   return error;

        return null;
      }
    });

    this.urlInput.loadValue(this.props?.initialValue);
  }

  readonly urlInput: InputState;

  @action
  setSyncing(value: boolean) {
    this.isSyncing = value;
  }

  @action
  setSynced(value: boolean) {
    this.isSynced = value;
  }

  @action
  setError(value: string | null) {
    this.error = value;
  }

  @action
  lock() {
    this.isLocked = true;
  }

  @action
  unlock() {
    this.isLocked = false;
  }
}