import groupBy from 'lodash/groupBy';
import remove from 'lodash/remove';
import { ChangeEvent } from 'react';
import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import { isMediaFile, isMediaItem, Nullable, pluralize } from '../../core';
import { UploadTask } from '../../services/upload';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { input, inputGroup, InputGroupState, InputState } from '../../components/input';
import { createArray } from '../../core/array';
import { UploadFileItemState } from './uploadFileItemState';
import { GroupCriteria, UploadTaskGroup } from '../../services/upload/uploadTaskGroup';

type Props = BindingProps<{
  teamId?: string,
  jobId?: string,
}>;

/**
 * Controller for the UploadFile component
 */
export class UploadFileState
  extends StoreNode {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.form = inputGroup(this, {
      name: 'form',
      inputs: () =>
        this.stagedItems.map((el: UploadFileItemState) => el.form),
      isSubmitDisabled: () => {
        return (
          this.form.inputs.some(input =>
            //@ts-ignore
            input.inputs.some(input => input.status === 'error' && input.isTouched && !input.isFocused)
          ) ||
          this.form.isSubmitting ||
          // this.form.hasTouchedErrorInputs ||
          this.form.isEmpty ||
          (this.isReplace && this.hasReplaceCurrentTask)
        );
      }
    });
  }

  readonly form: InputGroupState;

  @computed get stagedItems(): UploadFileItemState[] {
    return this.fileInputList.filter(item => item.showInStaging);
  }

  @computed get activeItems(): UploadFileItemState[] {
    return this.fileInputList.filter(item => item.isActive);
  }

  @computed get completedItems(): UploadFileItemState[] {
    return this.fileInputList.filter(item => item.isCompleted);
  }

  @computed get isReplace(): boolean {
    return !!this.jobId;
  }

  // #region Resolved props
  @computed get teamId(): Nullable<string> {
    return this.resolvedProps.teamId;
  }
  @computed get jobId(): Nullable<string> {
    return this.resolvedProps.jobId;
  }
  // #endregion

  @computed get teamName(): Nullable<string> {
    if (!this.teamId) return null;

    return this.store.teamManager.strictGetTeam(this.teamId).name;
  }
  @computed get job() {
    return this.store.jobManager.maybeGetJob(this.jobId);
  }

  @observable isHovered: boolean = false;
  @observable isDragHovered: boolean = false;
  readonly dragItems = observable.array<DataTransferItem>();

  // @observable isExpanded: boolean = true;
  @observable expandedBoxes: string[] = [];

  getIsExpanded(id: string): boolean {
    return this.expandedBoxes.findIndex(el => el === id) > -1;
  }

  @action
  expandProgressBox = (id: string) => {
    this.expandedBoxes.push(id);
  }

  @action
  collapseProgressBox = (id: string) => {
    remove(this.expandedBoxes, el => el === id);
  }

  @action
  toggleProgressBox = (id: string) => {
    if (this.expandedBoxes.findIndex(el => el === id) > -1)
      this.collapseProgressBox(id);
    else
      this.expandProgressBox(id);
  }

  @computed
  get dragError(): boolean {
    return !!this.dragErrorMessage;
  }

  @computed
  get dragErrorMessage(): Nullable<string> {
    // check if all files are video files
    const items = [...this.dragItems];

    let nonMediaItems = items.filter(item => !isMediaItem(item));
    if (nonMediaItems.length > 0) {
      return pluralize(nonMediaItems.length, 'item is not a media file', 'items are not media files');
    }

    if (this.isReplace && items.length > 0) {
      return 'Only one item supported for replacing the video';
    }

    return null;
  }

  @computed
  get dragDropMessage(): Nullable<string> {
    const items = [...this.dragItems];
    if (items.length === 0)
      return `Drop files here`; // to avoid glitching on drag leave

    return `Drop ${pluralize(items.length, 'file', 'files')} here`;
  }

  /** The list of tasks, regardless of status, which have been initiated from the current session. */
  readonly pageSessionTasks = observable.array<UploadTask>();

  @computed
  get teamGroupedTasks(): UploadTaskGroup[] {
    const criteria: GroupCriteria = 'teamId';
    const groups = groupBy(this.pageSessionTasks, criteria);
    return Object.keys(groups).map(key => {
      const value = groups[key];
      const taskGroup = new UploadTaskGroup(this.store, {
        groupBy: criteria,
        groupId: key,
        tasks: value
      });
      return taskGroup;
    });
  }

  @computed
  get tasksByTeam() {
    return groupBy(this.pageSessionTasks, 'teamId')
  }

  @computed
  get taskTeamIds() {
    return Object.keys(this.tasksByTeam);
  }

  // @computed
  // get visibleTasks(): UploadTask[] {
  //   const pendingTasks: UploadTask[] = this.store?.uploadService?.pendingTasks || [];
  //   return uniq(pendingTasks.concat([...this.pageSessionTasks]))
  //     .sort((a, b) => (+a.createdAt) - (+b.createdAt));
  // }

  @computed get hasReplaceCurrentTask(): boolean {
    return this.pageSessionTasks.some(task => task.jobId === this.jobId && task.status !== 'canceled');
  }

  @computed get replaceLocked(): boolean {
    return this.hasReplaceCurrentTask;
  }

  readonly fileInputList: IObservableArray<UploadFileItemState> = createArray<UploadFileItemState>(true) as IObservableArray;

  readonly uploadButton: InputState = input(this, {
    name: 'uploadButton',
    disabled: () => {
      return (
        this.form?.isSubmitting ||
        this.form?.hasTouchedErrorInputs
      );
    }
  });

  @computed
  get showStagingForm(): boolean {
    return this.stagedItems.length > 0;
  }

  @computed
  get showProgressBox(): boolean {
    return this.teamGroupedTasks && this.teamGroupedTasks.length > 0;
  }

  @computed get stagingBoxesCount(): number {
    let counter = 0;

    if (this.showStagingForm)
      counter += 1;

    counter += this.teamGroupedTasks.length;

    return counter;
  }

  @computed
  get isDisabled(): boolean {
    return this.jobId
      ? this.store?.uploadService?.pendingTasks.some(task => task.jobId === this.jobId)
      : false;
  }

  @action
  handlePointerEnter(evt: PointerEvent) {
    this.isHovered = true;
  }
  @action
  handlePointerLeave(evt: PointerEvent) {
    this.isHovered = false;
  }
  @action
  handleFileChange(evt: ChangeEvent<HTMLInputElement>) {
    this.setUploadInput(evt.target.files);
    //@ts-ignore - hack to have the file change triggered again for the same file
    evt.target.value = null;
  }
  @action
  handleFileClick(evt: PointerEvent) {

  }

  @action
  handleDragEnter(evt: DragEvent) {
    if (this.isDisabled)
      return;

    this.isDragHovered = true;
    const items = evt.dataTransfer?.items;

    if (items && items.length > 0) {
      // we have fileList
      this.dragItems.replace([...items]);
    }
  }

  @action
  handleDragLeave(evt: DragEvent) {
    this.isDragHovered = false;
    // this.dragItems.clear();
  }
  @action
  handleDrop(evt: DragEvent) {
    this.isDragHovered = false;
    this.dragItems.clear();

    // Drop on the file input also triggers a file change event
    // TODO: test if this is cross browser
    // const fileList = evt.dataTransfer?.files;
    // this.beginUpload(fileList);
  }

  @action
  setUploadInput(fileList?: FileList | null) {
    if (!this.store) {
      console.warn('Cannot begin upload without a store.');
      return;
    }

    if (!fileList || fileList.length === 0)
      return;

    const inputFiles = [...fileList];

    let nonMediaFiles = inputFiles.filter(file => !isMediaFile(file));
    if (nonMediaFiles.length > 0) {
      this.dispatch(
        'NotificationService',  // target
        'notifyError',          // type
        pluralize(nonMediaFiles.length, 'file is not a media file', 'files are not media files'));
      return;
    }

    // let largeFiles = inputFiles.filter(file => isLargeFile(file));
    // if (largeFiles.length > 0) {
    //   this.dispatch(
    //     'NotificationService',  // target
    //     'notifyError',          // type
    //     pluralize(largeFiles.length, `file exceeds the maximum file size.`, 'files exceed the maximum file size.'));
    //   return;
    // }

    if (this.jobId)
      this.resetForm();

    inputFiles.forEach(file => this.addFileItem(file));
  }

  @action
  addFileItem(file: File) {
    const fileInputItem: UploadFileItemState = new UploadFileItemState(this.store, {
      teamId: () => this.teamId,
      jobId: () => this.jobId,
      file
    });

    this.fileInputList.push(fileInputItem);
  }

  @action
  removeFileItem(id: string) {
    const itemIndex = this.fileInputList.findIndex(item => item.id === id);

    if (itemIndex > -1)
      this.fileInputList.splice(itemIndex, 1);
  }

  @action
  async submit() {
    if (!this.form)
      return;

    this.form.handleSubmit();

    if (this.form.error) {
      this.form.handleSubmitReject();
      return;
    }

    const tasks = this.stagedItems.map(item => item.submit());
    tasks.forEach(task => {
      this.expandProgressBox(task.teamId || 'null');
      this.pageSessionTasks.push(task)
    });

    this.form.handleSubmitResolve();
  }

  @action
  init() {
    // for the moment the tasks are merged when the page is initialized, the current session tasks and the global tasks should be separated
    const contextTasks = this.store?.uploadService?.tasks.filter(task => task.isActive);
    this.pageSessionTasks.push(...contextTasks);
    this.taskTeamIds.forEach(id => this.expandProgressBox(id));
  }

  @action
  reset() {
    this.pageSessionTasks.clear();
    this.form?.clear();
    this.fileInputList.clear();
  }

  @action
  resetForm() {
    this.form?.clear();
    this.fileInputList.clear();
  }
}