import { Metadata } from '@clipr/lib';
import camelCase from 'lodash/camelCase';
import { camelPad } from '../../core/string';

export type JobMetadataField = keyof Metadata;

export const JobMetadataFields = [
  'startTime',
  'endTime',
  'session',
  'track',
  'level',
  'featured',
  'location',
  'publishDate'
] as JobMetadataField[];

export const getMetadataInputFieldList = () => JobMetadataFields.map(item => camelCase(item));

export const getMetadataInputLabel = (fieldName: JobMetadataField) => camelPad(fieldName);

