import { JobModel } from "../../../entities";
import { IAnalyticsStream, AnalyticsStreamParams, AnalyticsEventTypes } from "../analyticsSchema";
import { AnalyticsCurrentJobData, AnalyticsMainData, AnalyticsPlayerStateData, AnalyticsStream } from "./analyticsStream";

export type AnalyticsPlayerReactionStreamParams = {
  reactionName: string,
  job: JobModel
}

export type AnalyticsPlayerReactionStreamData = {
  reactionName: string | null
} 
& AnalyticsPlayerStateData
& AnalyticsCurrentJobData
& AnalyticsMainData

export class AnalyticsPlayerReactionStream extends AnalyticsStream implements IAnalyticsStream<keyof AnalyticsStreamParams>{
  static streamName = AnalyticsEventTypes.PlayerReactionType

  exchangeData(data: AnalyticsPlayerReactionStreamParams): AnalyticsPlayerReactionStreamData|null {
   
    return { 
      ...{ 
        reactionName: data?.reactionName ?? null 
      }, 
      ...super.playerData,
      ...super.currentJobData(data.job), 
      ...super.mainData,
      ...super.viewContextData
    };
  }
}
