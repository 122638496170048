import { isIterable } from './iterable';
import { IMap } from './map';

/**
 * Implementation of Map in which keys are serialized using JSON.stringify and deserialized using JSON.parse.
 * Probably not very efficient, but it's the best that we've got until the good ol' boys at TC39 decide to prioritize Records / Tuples.
 */
export class JsonMap<TKey, TVal>
  implements IMap<TKey, TVal> {

  constructor();
  constructor(entries?: readonly (readonly [TKey, TVal])[] | null);
  constructor(iterable?: Iterable<readonly [TKey, TVal]> | null);

  constructor(entries?: Iterable<readonly [TKey, TVal]> | readonly (readonly [TKey, TVal])[] | null) {
    if (isIterable(entries)) {
      for (const entry of entries)
        this.set(entry[0], entry[1]);
    }
  }

  private map = new Map<string, TVal>();

  private fromKey(key: TKey): string {
    return JSON.stringify(key);
  }
  private toKey(key: string): TKey {
    return JSON.parse(key);
  }
  private *keysIterator(): IterableIterator<TKey> {
    const keys = this.map.keys();
    for (const key of keys) {
      yield this.toKey(key);
    }
  }
  private *entriesIterator(): IterableIterator<[TKey, TVal]> {
    const entries = this.map.entries();
    for (const entry of entries) {
      yield [this.toKey(entry[0]), entry[1]];
    }
  }

  get size() {
    return this.map.size;
  }

  [Symbol.iterator]() {
    return this.entriesIterator();
  }

  get(key: TKey) {
    return this.map.get(this.fromKey(key));
  }
  set(key: TKey, val: TVal) {
    this.map.set(this.fromKey(key), val);
    return this;
  }
  has(key: TKey) {
    return this.map.has(this.fromKey(key));
  }
  delete(key: TKey) {
    return this.map.delete(this.fromKey(key));
  }

  values(): IterableIterator<TVal> {
    return this.map.values();
  }
  keys(): IterableIterator<TKey> {
    return this.keysIterator();
  }
  entries(): IterableIterator<[TKey, TVal]> {
    return this.entriesIterator();
  }

  clear() {
    this.map.clear();
  }
  forEach(func: (val: TVal, key: TKey, map: JsonMap<TKey, TVal>) => void, thisArg?: any) {
    this.map.forEach((val, keyStr) => {
      func(val, this.toKey(keyStr), this);
    }, thisArg);
  }
}