import { ApiQueryName, ApiUnwrappedResult, ApiVariables } from './apiSchema';
import { ApiService } from './apiService';

export class ApiServiceQueryHelpers {
  private readonly api: ApiService;
  constructor(api: ApiService) {
    this.api = api;
  }

  uploadUrl(vars: ApiVariables<'getUploadUrl'>): ApiUnwrappedResult<'getUploadUrl'> {
    return this.queryAndUnwrap('getUploadUrl', vars);
  }

  async queryAndUnwrap<T extends ApiQueryName>(name: T, vars: ApiVariables<T>): ApiUnwrappedResult<T> {

    let [res, err] = await this.api.runQuery(name, vars);
    if (err)
      return [null, err];

    return [(res as any)[name]];
  }
}