import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { Button, ButtonControlled, TextInputControlled } from '../input';
import { CopyJobWindowState } from './copyJobWindowState';

type Props = {
  model: CopyJobWindowState;
  visible?: boolean;
};

export const CopyJobWindow = observer(({ model, visible }: Props) => {
  return (
    <Window
      model={model.window}
      visible={visible}
      isLoading={model.isLoading}
      className="copy-job-window">
      <WindowHeader model={model.window} title="Copy video" />

      <hr />

      <div className="window-content">
        <TextInputControlled
          model={model.titleInput}
          feedbackPosition="topEnd"
          placeholder="Enter video title"
          statusMessage={
            model.titleInput.status === 'error' &&
              model.titleInput.showStatusIcons
              ? model.titleInput.error?.toString()
              : 'Minimum title length 5ch.'
          }
          label="Title"
        />

        <div className="tpad2">
          <p>
            This video is shared with:
            <strong className="value">
              {' '}
              {model.teamId ? model.team?.name || 'Your library' : 'My Uploads'}
            </strong>
          </p>
        </div>
      </div>

      <hr />

      <footer className="window-footer">
        <div className="btn-group right">
          <Button
            className="primary btn-outline medium"
            label="Cancel"
            onClick={(evt) => model.cancel()}
          />
          <ButtonControlled
            className="primary medium"
            model={model.submitButton}
            label="Done"
            onClick={(evt) => model.submit()}
          />
        </div>
      </footer>
    </Window>
  );
});
