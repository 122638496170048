import React from 'react';
import * as CSS from 'csstype';
import identity from 'lodash/identity';
import { observer } from 'mobx-react-lite';
import { RenderTooltipParams } from '@visx/xychart/lib/components/Tooltip';

export const AudienceFocusTooltip = observer(
  ({ tooltipData }: RenderTooltipParams<any>) => {
    const dataKey = tooltipData?.nearestDatum?.key;
    if (!dataKey) return null;
    const data = tooltipData?.datumByKey ?? {};
    const { jobTitle, speakerNames } = data[dataKey].datum.tooltip;
    const { value, date: topicTitle } = data[dataKey].datum;
    const speakerNamesFiltered = speakerNames.filter(identity);
    const speakers = speakerNamesFiltered.join(', ');

    const divStyle: CSS.Properties = {
      display: 'flex',
      alignItems: 'flex-start',
      padding: '0.875rem 0.5rem',
      flexDirection: 'column',
      maxWidth: '350px',
    };
    const pseudo: { [P in CSS.SimplePseudos]?: CSS.Properties } = {
      ':last-child': {
        marginBottom: 0,
      },
    };
    const spanStyle: CSS.Properties = {
      marginBottom: '0.25rem',
      fontSize: '0.875rem',
      lineHeight: '1rem',
      ...pseudo,
    };
    const strongStyle: CSS.Properties = {
      fontWeight: 600,
    };
    const italicStyle: CSS.Properties = {
      fontWeight: 400,
      fontStyle: 'italic',
    };

    return (
      <div style={divStyle}>
        <span style={spanStyle}>
          <strong style={strongStyle}>Title:</strong>{' '}
          <i style={italicStyle}>{topicTitle}</i>
        </span>

        <span style={spanStyle}>
          <strong style={strongStyle}>Video:</strong>{' '}
          <i style={italicStyle}>{jobTitle}</i>
        </span>

        <span style={spanStyle}>
          <strong style={strongStyle}>
            Speaker{speakerNamesFiltered.length > 1 ? 's' : ''}:
          </strong>{' '}
          <i style={italicStyle}>{speakers}</i>
        </span>

        <span style={spanStyle}>
          <strong style={strongStyle}>Count:</strong>{' '}
          <i style={italicStyle}>{value}</i>
        </span>
      </div>
    );
  }
);
