import React, { ReactEventHandler, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { TextInput, TextInputControlled } from './textInput';
import { HTMLInputProps } from '../../core';
import { InputProps } from './inputSchema';
import { CloseSVGIcon, SearchSVGIcon } from '../svg';
import { InputState } from './inputState';
import { Button } from './button';

type Props = Omit<HTMLInputProps, 'value' | 'placeholder'> &
  InputProps<string> & {
    onClear?: ReactEventHandler;
    showClear?: boolean;
    alwaysShowClear?: boolean;
  };

export const SearchTextInput = observer(
  ({
    onClear = () => {},
    showClear = true,
    alwaysShowClear,
    ...props
  }: Props) => {
    return (
      <TextInput showFeedback={false} persistentFeedback={false} {...props}>
        <div className="icon left-gutter flex-center">
          { alwaysShowClear || (props.value && showClear) ? (
            <Button
              className="btn-icon medium"
              icon={<CloseSVGIcon />}
              onClick={(e) => onClear(e)}
            />
          ) : (
            <SearchSVGIcon />
          )}
        </div>
      </TextInput>
    );
  }
);

type ControlledProps = Props & {
  model: InputState;
  icon?: ReactNode;
};

export const SearchTextInputControlled = observer(
  ({ alwaysShowClear, onClear, icon, ...props }: ControlledProps) => {
    const handleOnClear = (e: any) => {
      props.model.clear();
      if (onClear) onClear(e);
    };

    const handleClick = (evt: any) => {
      evt.stopPropagation();
      props.model.handleFocus();
      if (props.value || props.model?.value) handleOnClear(evt);
    };

    return (
      <TextInputControlled {...props}>
        <div
          className="icon left-gutter flex-center"
          onClick={() => props.model.handleFocus()}
        >
          {alwaysShowClear ? (
            <Button
              className="btn-icon medium"
              icon={<CloseSVGIcon />}
              onClick={(evt) => handleOnClear(evt)}
            />
          ) : props.value || props.model?.value ? (
            <Button
              className="btn-icon medium"
              icon={<CloseSVGIcon />}
              onClick={(evt) => handleClick(evt)}
            />
          ) : (
            icon ? icon : <SearchSVGIcon />
          )}
        </div>
      </TextInputControlled>
    );
  }
);
