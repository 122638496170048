import React, { ReactNode, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { useOverlayState } from '../../overlays/overlayState';
import { Overlay } from '../../overlays/overlay';
import { PlayerState } from '../playerState';
import { Placement } from '../../playerTutorial/playerTutorial.consts';
import { HTMLButtonProps } from '../../../core';

type Props = HTMLButtonProps<{
  model?: PlayerState
  className?: string,
  tooltip?: ReactNode,
  placement?: Placement,
  icon?: ReactNode,
  pressed?: boolean
}>

export const ToggleButton = observer(({
  model,
  className,
  tooltip,
  placement,
  icon,
  pressed,
  ...props
}: Props) => {

  const tooltipState = useOverlayState();
  const [isHovered, setHovered] = useState(false);

  className = classNames('user-player-toggle-btn', className, {
    hover: isHovered
  });

  return (
    <button className={className}
      ref={tooltipState.anchorRef.ref}
      aria-pressed={pressed}
      {...tooltipState.anchorProps}
      {...props}
      onPointerEnter={() => {
        setHovered(true);
      }}
      onPointerLeave={() => {
        setHovered(false);
      }}>

      <span className="icon">
        {icon}
      </span>

      {tooltip &&
        <Overlay model={tooltipState}
          placement={placement}
          className="user-player-tooltip">
          {tooltip}
        </Overlay>}
    </button>
  );
});