import { ClientError } from 'graphql-request';
import { GraphQLError, GraphQLResponse } from 'graphql-request/dist/types';
import { Maybe } from '../core';
import { Error, ErrorCode } from '../core/error';
import { NativeError } from '../core/errorSchema';
import { ApiErrorResult } from './apiSchema';

export interface IApiOriginalError extends GraphQLError {
  data: unknown,
  errorInfo: string | null,
  errorType: string | null,
};

export interface IApiErrorResponse extends GraphQLResponse {
  errors?: IApiOriginalError[]
}

type ApiData = {
  graphQLError?: IApiErrorResponse;
}

export type ApiErrorType =
  'ApiError' |
  'RequestAborted' |
  'RetryRequestFailed' |
  'NotAuthorized' |
  'NetworkOffline' |
  'GraphQLError' |
  'ProviderError' |
  'ProviderConfigurationError' |
  'ProviderRequestError' |
  'ProviderNotAuthorized';

function getMessage(type: Maybe<ApiErrorType>) {

  switch (type) {
    case 'RequestAborted':
      return `The request has been aborted.`;
    case 'RetryRequestFailed':
      return `Request still failed after re-authentication of the request.`;
    case 'NotAuthorized':
      return `Could not authorize the request.`;
    case 'ApiError':
      return `An unknown error occured in the ApiService.`;
  }

  return `An unknown error occurred.`;
}

/** Base class for all errors returned during the authentication flows. */
export class ApiError
  extends Error {

  readonly type: ApiErrorType;
  readonly data: ApiData;
  readonly originalMessage: string | null;
  readonly innerError: NativeError | null;
  readonly originalErrorResponse: IApiErrorResponse | null;
  readonly timestamp: Date;

  constructor(
    type?: ApiErrorType | null,
    message?: Maybe<string>,
    innerError?: NativeError) {
    super(type as ErrorCode, message ?? getMessage(message));

    this.data = {};
    this.type = type ?? 'ApiError';
    this.originalMessage = message ?? null;
    this.innerError = innerError ?? null;
    this.originalErrorResponse = innerError instanceof ClientError ? innerError?.response as IApiErrorResponse : null;
    this.timestamp = new Date();

    if (innerError instanceof ClientError) {
      this.data.graphQLError = innerError?.response as IApiErrorResponse;
    }
  }
}

export function apiError(
  type?: ApiErrorType | null,
  message?: Maybe<string>,
  innerError?: NativeError) {
  return new ApiError(type, message, innerError);
}

export function apiErrorResult(
  type?: ApiErrorType | null,
  message?: Maybe<string>,
  innerError?: NativeError): ApiErrorResult {

  return [null, apiError(type, message, innerError)];
}