import identity from 'lodash/identity';
import words from 'lodash/words';
import { ApiResponse, ApiVariables } from '../../../api/apiSchema';
import { AsyncResult, isNonEmptyString, mapIncrement, Result } from '../../../core';
import { BindingProps, StoreNode } from '../../../store';
import { IChartDataSource } from '../chartSchema';
import { Word, WordCloudExcludeWords, WordData } from '../wordCloud/wordCloudSchema';
import { ApiResult, ApiRequestOptions } from '../../../api/apiSchema';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Store } from '../../../store/store';
import { Error, error } from '../../../core/error';
import { parseKeenJsonResult } from '../utils/parseKeenJsonResult';
import { TopicViewsChartParams, TopicViewsChartMode } from './topicViewsChartSchema';
import { ApiError } from '../../../api/apiError';

type Props = BindingProps<{
  params: TopicViewsChartParams,
  suppressFetchOnParamsChange?: boolean
}>

export class TopicViewsDataSource
  extends StoreNode
  implements IChartDataSource<Word> {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.onPropChange('params',
      this.handleParamsChange);
  }

  // #region Props
  @computed get params(): TopicViewsChartParams | null {
    return this.getResolvedProp('params') ?? null;
  }
  set params(val: TopicViewsChartParams | null) {
    this.setProp('params', val);
  }

  @computed get suppressFetchOnParamsChange(): boolean | null {
    return this.getResolvedProp('suppressFetchOnParamsChange') ?? null;
  }
  set suppressFetchOnParamsChange(val: boolean | null) {
    this.setProp('suppressFetchOnParamsChange', val);
  }
  // #endregion

  // #region Handlers
  private handleParamsChange = () => {
    // this.fetch();
  }
  // #endregion

  @computed get mode(): TopicViewsChartMode {
    return this.params?.mode ?? TopicViewsChartMode.Topics;
  }

  readonly data = observable.array<WordData>([], { deep: false });

  private abortController: AbortController | null = null;

  @observable isFetching = false;
  @observable error: Error | null = null;

  @action
  reset() {
    this.abort();

    this.isFetching = false;
    this.error = null;
    this.abortController = null;

    this.data.clear();
  }

  @action
  async fetch(): AsyncResult {

    this.reset();

    this.isFetching = true;

    const abortCtrl = new AbortController();
    this.abortController = abortCtrl;

    const { params } = this;
    if (!params)
      return [null];

    const reqArgs: ApiVariables<'keenBatch'> = {
      args: {
        start: params.startDate?.toISO()!, // TODO: fix
        end: params.endDate?.toISO()!,
        timezone: params.timezone ?? undefined,
        teamId: params.teamId ?? undefined
      }
    };

    const api = this.store.api;
    const reqOpts: ApiRequestOptions = {
      signal: abortCtrl.signal
    };

    let reqPromise: ApiResult<'keenBatch'> | null = null;

    switch (params.mode) {
      default:
      case TopicViewsChartMode.Topics:
        reqPromise = api.runQuery('keen_getTopicViews', reqArgs, reqOpts);
        break;

      case TopicViewsChartMode.SubTopics:
        reqPromise = api.runQuery('keen_getSubTopicViews', reqArgs, reqOpts);
        break;

      case TopicViewsChartMode.MostBookmarkedTopics:
        reqPromise = api.runQuery('keen_getMostBookmarkedTopics', reqArgs, reqOpts);
        break;

      case TopicViewsChartMode.MostBookmarkedSubtopics:
        reqPromise = api.runQuery('keen_getMostBookmarkedSubtopics', reqArgs, reqOpts);
        break;
    }

    if (!reqPromise) {
      console.error('Mode not implemented');
      return this.handleError(error('Unknown', `Invalid mode ${params.mode} was provided to TopicViewsDataSource`));
    }

    const [res, err] = await reqPromise;
    if (err)
      // @ts-ignore TODO: make ApiError extend our own Error class
      return this.handleError(err);

    const [data, insertErr] = this.insert(res!);
    if (insertErr)
      return this.handleError(insertErr);

    runInAction(() => {
      this.isFetching = false;
    });

    return [data!];
  }

  cancel(): void {
    this.abort();
  }

  @observable isBatchRequesting = false;

  notifyBatchRequestStarted() {
    this.isBatchRequesting = true;
  }
  notifyBatchRequestCompleted() {
    this.isBatchRequesting = false;
  }

  @action
  insert(res: ApiResponse<'keenBatch'>): Result<WordData[]> {

    let dataRes: Result<WordData[]>;

    switch (this.mode) {
      default:
      case TopicViewsChartMode.Topics:
        dataRes = getDataFromTopicViews(res);
        break;

      case TopicViewsChartMode.SubTopics:
        dataRes = getDataFromSubTopicViews(res);
        break;

      case TopicViewsChartMode.MostBookmarkedTopics:
        dataRes = getDataFromMostBookmarkedTopics(res);
        break;

      case TopicViewsChartMode.MostBookmarkedSubtopics:
        dataRes = getDataFromMostBookmarkedSubtopics(res);
        break;
    }

    const [data, err] = dataRes;
    if (err) {
      return this.handleError(err);
    }

    this.data.replace(data ?? []);
    return [data ?? []];
  }

  private abort() {

    const lastAbortCtrl = this.abortController;
    if (lastAbortCtrl)
      lastAbortCtrl.abort();
    this.abortController = null;
  }

  @action
  private handleError(err?: Error | string): Result {
    if (!err)
      err = new Error();
    if (typeof err === 'string')
      err = new Error('Unknown', err);

    if (err instanceof ApiError && err.type === 'RequestAborted') {
      // for aborted requests we only return the error but we don't set it on the instance and we do not report it as error
      // console.warn(`Request aborted in TopicViewsDataSource`);
      return [null, err];

    } else {
      console.error(`An error occured in TopicViewsDataSource: `, err);

      this.isFetching = false;
      this.abort();
      this.error = err;

      return [null, err];
    }
  }
}

function getDataFromTopicViews(data: ApiResponse<'keenBatch' | 'keen_getTopicViews'>): Result<WordData[]> {

  const apiResultStr = data.keenBatch.topicViews?.result;
  const [apiResult, err] = parseKeenJsonResult<any>(apiResultStr);
  if (err)
    return [null, err];

  const dict = new Map<string, number>();

  apiResult!.forEach((data: any) => {
    const topicName: string = data?.['player.activeTopicName'];
    if (!topicName)
      return null!;

    if (isNonEmptyString(topicName)) {
      words(topicName)
        .map(word => word.toLowerCase())
        .filter(identity)
        .filter(word => !WordCloudExcludeWords.includes(word))
        .forEach(word => mapIncrement(dict, word, data?.result ?? 1));
    }
  });

  const outResult: WordData[] = [...dict.entries()]
    .map(([key, val]) => {

      return {
        originalText: key,
        text: key,
        value: val
      }
    });

  return [outResult];
}


function getDataFromSubTopicViews(data: ApiResponse<'keenBatch' | 'keen_getSubTopicViews'>): Result<WordData[]> {

  const apiResultStr = data.keenBatch.subTopicViews?.result;
  const [apiResult, err] = parseKeenJsonResult<any>(apiResultStr);
  if (err)
    return [null, err];

  const dict = new Map<string, number>();

  apiResult!.forEach((data: any) => {
    const subTopicNames: string = data?.['player.activeSubTopicNames'];
    if (!subTopicNames)
      return null!;

    if (Array.isArray(subTopicNames)) {
      subTopicNames.forEach(subTopicName => {
        if (!isNonEmptyString(subTopicName))
          return;

        words(subTopicName)
          .map(word => word.toLowerCase())
          .filter(identity)
          .filter(word => !WordCloudExcludeWords.includes(word))
          .forEach(word => mapIncrement(dict, word, data?.result ?? 1));
      });
    }
  });

  const outResult: WordData[] = [...dict.entries()]
    .map(([key, val]) => {

      return {
        originalText: key,
        text: key,
        value: val
      }
    });

  return [outResult];
}

function getDataFromMostBookmarkedTopics(data: ApiResponse<'keenBatch' | 'keen_getMostBookmarkedTopics'>): Result<WordData[]> {
  const apiResultStr = data.keenBatch.mostBookmarkedTopics?.result;
  const [apiResult, err] = parseKeenJsonResult<any>(apiResultStr);
  if (err)
    return [null, err];

  const dict = new Map<string, number>();

  apiResult!.forEach((data: any) => {
    const topicName: string = data?.['bookmark.momentName'];
    if (!topicName)
      return null!;

    if (isNonEmptyString(topicName)) {
      words(topicName)
        .map(word => word.toLowerCase())
        .filter(identity)
        .filter(word => !WordCloudExcludeWords.includes(word))
        .forEach(word => mapIncrement(dict, word, data?.result ?? 1));
    }
  });

  const outResult: WordData[] = [...dict.entries()]
    .map(([key, val]) => {

      return {
        originalText: key,
        text: key,
        value: val
      }
    });

  return [outResult];
}

function getDataFromMostBookmarkedSubtopics(data: ApiResponse<'keenBatch' | 'keen_getMostBookmarkedSubtopics'>): Result<WordData[]> {
  const apiResultStr = data.keenBatch.mostBookmarkedSubtopics?.result;
  const [apiResult, err] = parseKeenJsonResult<any>(apiResultStr);
  if (err)
    return [null, err];

  const dict = new Map<string, number>();

  apiResult!.forEach((data: any) => {
    const subtopicName: string = data?.['bookmark.momentName'];
    if (!subtopicName)
      return null!;

    if (isNonEmptyString(subtopicName)) {
      words(subtopicName)
        .map(word => word.toLowerCase())
        .filter(identity)
        .filter(word => !WordCloudExcludeWords.includes(word))
        .forEach(word => mapIncrement(dict, word, data?.result ?? 1));
    }
  });

  const outResult: WordData[] = [...dict.entries()]
    .map(([key, val]) => {

      return {
        originalText: key,
        text: key,
        value: val
      }
    });

  return [outResult];
}
