import React from 'react';
import { observer } from 'mobx-react-lite';
import { PlayerState } from '../playerState';
import { LiveReactionsBar } from './liveReactionsBar';

type Props = {
  model: PlayerState;
};

export const PlayerChromeLeft = observer(({ model }: Props) => {
  const { chrome } = model;

  return (
    <div
      id="user-player-chrome-left"
      onPointerEnter={chrome.handleRegionPointerEnter}
      onPointerLeave={chrome.handleRegionPointerLeave}>
      {
        model.isRealTime &&
        !model.disableReactions &&
        <LiveReactionsBar model={model} />}
    </div>
  );
});
