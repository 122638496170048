import './createTeamForm.scss';

import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';

import { DropdownInputControlled, RadioGroupInputControlled, TextInputControlled, ToggleInputControlled, ToggleLayout } from '../input';

import { CreateTeamFormState } from './createTeamFormState';
import { UploadAvatarInput } from '../uploadTeamAvatar';
import { AddInviteListGroup } from './addInviteList';
import { TeamDetailsDrawer } from '../teams/teamDetailsDrawer';
import { TeamMetadataList } from '../teams/teamMetadataList';
import { TeamEnrichmentLevelList } from '../teams/teamEnrichmentLevelList';

type Props = {
  model: CreateTeamFormState;
};

export const CreateTeamForm = observer((props: Props) => {
  const { model } = props;
  const { addInviteListState } = model;

  return (
    <form className="create-team-grid form">
      <div className="team-info modal-card">
        <div className="create-teams-form">
          <div className="flex-left form-header">
            <UploadAvatarInput model={model} disabled={model.form.disabled} />
            <TextInputControlled
              label="Library Name"
              model={model.teamName}
              className="form-input team-name"
            />
          </div>
          <TextInputControlled
            model={model.teamDescription}
            label="Library Description"
            className="form-input team-description"
            rows={5}
          />
          <DropdownInputControlled
            model={model.teamIndustry}
            label="Field Of Activity"
            className="form-input team-industry"
            // notDeselectable={true}
            showFeedback={false}
          />

          <ToggleInputControlled
            className="medium"
            label="Public Safety"
            model={model.publicSafety}
            toggleLayout={ToggleLayout.Buttons}
            toggledLabel="On"
            notToggledLabel="Off"
          />

          <TeamDetailsDrawer
            className={"metadata-drawer"}
            header={"Advanced video details"}
            description={"Check options to customise the information you can add for library video content."}
            isExpanded={model.isAdvancedSectionExpanded}
            onExpand={() => model.toggleAdvancedVideoDetails()} >
            <TeamMetadataList model={model.teamMetadata} />
          </TeamDetailsDrawer>

          <TeamDetailsDrawer
            header={"Video Enrichment Level"}
            description={"Select your library default Video Enrichment Level."}
            isExpanded={model.isVideoEnrichmentLevelExpanded}
            onExpand={() => model.toggleVideoEnrichmentLevel()} >
            <TeamEnrichmentLevelList model={model.teamEnrichmentLevel} />
          </TeamDetailsDrawer>
        </div>
      </div>
      <div className="team-invite modal-card">
        <AddInviteListGroup
          addButtonRight={true}
          className="bmar4"
          model={addInviteListState}
        />
      </div>

      {false && (
        <FormInputGroup label={'Library Visibility *'}>
          <RadioGroupInputControlled
            className="form-input team-privacy"
            model={model.teamPrivacy}
          />
        </FormInputGroup>
      )}
    </form>
  );
});

type FormInputGroupProps = PropsWithChildren<{
  label: string;
}>;

const FormInputGroup = observer((props: FormInputGroupProps) => {
  return (
    <div className="block input-group modal-card">
      <label className="input-group-label">{props.label}</label>
      {props.children}
    </div>
  );
});
