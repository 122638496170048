import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { PlayerState } from '../playerState';
import { ClickPadding } from './clickPadding';
import { ActiveTopicCard } from './activeTopicCard';
import { ActiveSpeakerCard } from './activeSpeakerCard';
import { ComponentVisibility, IComponentPolicy } from '../../componentSchema';
import { PlayerComponentName } from '../playerSchema';

type Props = {
  model: PlayerState,
  policy?: IComponentPolicy
}

export const PlayerExplorerBar = observer(({
  model,
  policy = model.getComponentPolicy(PlayerComponentName.ExplorerBar)
}: Props) => {

  if (policy.visibility !== ComponentVisibility.Visible)
    return null;

  const { momentView } = model;
  const {
    nextTopic,
    nextSubTopic,
    nextSpeaker,
    nextSpeakerMoment,
    showNextTopic,
    showNextSubTopic,
    showNextSpeaker
  } = momentView;

  const className = classNames('user-player-explorer-bar', {
    'with-topic': !!nextTopic,
    'with-subtopic': !!nextSubTopic
  });

  const showNextClips =
    (showNextSpeaker && nextSpeaker && nextSpeakerMoment) ||
    (showNextSubTopic && nextSubTopic) ||
    (showNextTopic && nextTopic);

  return (
    <div id="user-player-explorer-bar">

      <div id="user-player-status-cards">
        <ClickPadding
          model={model} />

        <ActiveTopicCard
          model={model} />

        <ActiveSpeakerCard
          model={model} />
      </div>

      <ClickPadding
        showSubtitles={!model.frameset?.isWidgetMode && !model.isFullscreen}
        model={model} />


      {showNextClips &&
        <div id="user-player-next-clips" className={className}>
          {showNextTopic && nextTopic &&
            <button className="next-topic-btn next-clip-btn"
              onClick={evt => model.invoke('jumpToMoment', { moment: nextTopic })}>
              <div className="heading">Next topic</div>
              <div className="clip-title">{nextTopic.name}</div>
            </button>}

          {showNextSubTopic && nextSubTopic &&
            <button className="next-subtopic-btn next-clip-btn"
              onClick={evt => model.invoke('jumpToMoment', { moment: nextSubTopic })}>
              <div className="heading">Next subtopic</div>
              <div className="clip-title">{nextSubTopic.name}</div>
            </button>}

          {showNextSpeaker && nextSpeaker && nextSpeakerMoment &&
            <button className="next-speaker-btn next-clip-btn"
              onClick={evt => model.invoke('jumpToMoment', { moment: nextSpeakerMoment })}>
              <div className="heading">Next speaker</div>
              <div className="clip-title">{nextSpeaker.name}</div>
            </button>}
        </div>}
    </div>
  );
});