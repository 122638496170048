import { computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { UploadTask } from './uploadTask';
import uniq from 'lodash/uniq';

export type GroupCriteria = 'teamId';

export type UploadTaskGroupProps = {
  groupBy: GroupCriteria;
  groupId: string;
  tasks: (UploadTask)[];
};

export class UploadTaskGroup
  extends StoreNode {

  readonly nodeType = 'UploadTaskGroup';

  readonly groupBy: string;
  readonly groupId: string;

  readonly tasks = observable.array<UploadTask>([], { deep: false });

  constructor(store: Store, props: UploadTaskGroupProps) {
    super(store);
    makeObservable(this);

    this.groupBy = props.groupBy;
    this.groupId = props.groupId;
    this.tasks.push(...props.tasks || [])
  }

  @computed
  get pendingTasks() {
    const teamPendingTasks = this.tasks.filter(task => task.isActive || task.isFailed || task.isCanceled);
    return uniq(teamPendingTasks)
      .sort((a, b) => (+a.createdAt) - (+b.createdAt));
  }

  @computed
  get completedTasks() {
    const teamCompletedTasks = this.tasks.filter(task => task.isCompleted);
    return uniq(teamCompletedTasks)
      .sort((a, b) => (+a.createdAt) - (+b.createdAt));
  }

  @computed
  get activeTasks() {
    const currentActiveTasks: UploadTask[] = this.tasks.filter(task => task.isActive) || [];
    return uniq(currentActiveTasks)
      .sort((a, b) => (+a.createdAt) - (+b.createdAt));
  }

  @computed
  get averageQueueProgress() {
    if (this.activeTasks.length === 0) {
      return this.completedTasks.length ? 1 : 0;
    }

    return this.activeTasks.reduce((a, c) => a + c.progress, 0) / this.activeTasks.length;
  }

  @computed
  get isQueueCompleted() {
    return this.pendingTasks.every(task => task.status === 'completed');
  }

  @computed
  get teamName() {
    const { groupId: teamId } = this;
    if (this.groupBy !== 'teamId' || !teamId || teamId === 'null') return null;

    return this.store.teamManager.strictGetTeam(teamId).name;
  }

}