import React from 'react';
import { observer } from 'mobx-react-lite';
import { PlayerState } from '../playerState';
import classNames from 'classnames';

type Props = {
  model: PlayerState,
  chromeTop?: boolean
}

export const PlayerCaptionsComponent = observer(({
  model,
  chromeTop
}: Props) => {

  const indexVisible = model.frameset?.isSectionVisible('Index');

  const className = classNames('player-captions-wrapper',
    {
      'chrome-top': chromeTop,
      'captions-widget-mode-width': model.frameset?.isWidgetMode && !indexVisible && !model.isFullscreen,
      // 'captions-widget-full-toggled': model.frameset?.isWidgetMode && !model.isFullscreen && !indexVisible,
      'captions-widget-size': model.frameset?.isWidgetMode && !model.isFullscreen
    });

  let elem = null;

  if (typeof model.captionText === 'string')
    elem = <span className="player-caption-text">{model.captionText}</span>
  else if (Array.isArray(model.captionText) && model.captionText.length > 0) {
    elem = <>
      {model.captionText.map(cue =>
        <div className="player-caption-text">{(cue as any).text}</div>)}
    </>
  }

  return (
    <div
      id="tracks"
      className={className}>
      <div className="player-captions">
        {elem}
      </div>
    </div>
  );
});