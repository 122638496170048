import { makeObservable } from 'mobx';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { UserProfile } from '../../entities';

const SENTRY_DSN: string = process.env?.REACT_APP_SENTRY_DSN || '';
export class DiagnosticsService
  extends StoreNode {
  userProfile: UserProfile | undefined;

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    // @ts-ignore
    this.userProfile = store?.authService?.context?.profile
  }

  init() {
    const userProfile = this.userProfile
    const host = window.location?.hostname
    const nodeEnv = process.env?.NODE_ENV

    try {
      Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [
          new Integrations.BrowserTracing(),
          new CaptureConsoleIntegration(
            {
              levels: ['warn', 'error', 'assert']
            }
          )
        ],
        environment: host,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        beforeSend(event: any) {
          if (!!userProfile) {
            event.user = { 
              email: userProfile?.email
            }
          }

          return event;
        },
      });

      Sentry.setTag("node_env", nodeEnv);
    } catch(e) {
      console.error(e);
    }
  }

  captureException(err: any) {
    try {
      return Sentry.captureException(err);
    } catch(e) {
      console.error(e);
    }
  }
}
