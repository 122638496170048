import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Store } from '../../store/store';
import { Message, refProxy, RefProxy } from '../../store';
import { StoreNode } from '../../store/storeNode';
import { UploadFileState } from './uploadFileState';
import { UploadLinkState } from './uploadLinkState';
import { Routes } from '../../routes';
import { Error, pageError } from '../../core/error';
import { match } from 'react-router-dom';

const stagingItemHeight = 133;
const offset = 125;

export type UploadPageParams = {
  teamId?: string,
  jobId?: string
}

export class UploadPageState
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
    this.receive(this.receiver, [
      'UploadService:taskDiscarded',
      'UploadService:taskCompleted'
    ]);

    this.fileComponent = new UploadFileState(this.store, {
      teamId: () => this.teamId,
      jobId: () => this.jobId
    });
    this.linkComponent = new UploadLinkState(this.store, {
      teamId: () => this.teamId,
      jobId: () => this.jobId
    });
  }

  readonly fileComponent: UploadFileState;
  readonly linkComponent: UploadLinkState;

  readonly stageRef: RefProxy<HTMLElement> = refProxy(this);

  @observable previousRoute: string | null = null;
  @observable teamId: string | null = null;
  @observable isLoading: boolean = false;

  @computed get team() {
    return this.store.teamManager.getTeam(this.teamId);
  }
  @observable jobId: string | null = null;
  @computed get job() {
    return this.store.jobManager.maybeGetJob(this.jobId);
  }

  @computed get isReplace(): boolean {
    return !!this.jobId;
  }

  @computed get showStaging() {
    return (this.linkComponent.showStagingForm
      || this.linkComponent.showProgressBox
      || this.fileComponent.showStagingForm
      || this.fileComponent.showProgressBox)
  }

  @computed get stagingBoxesNumber() {
    return (this.fileComponent.stagingBoxesCount +
      this.linkComponent.stagingBoxesCount)
  }

  @computed get showOpenConfirmation() {
    return (this.linkComponent.showStagingForm
      || this.fileComponent.showStagingForm)
  }

  @computed get maxStagingItemsNumber() {
    if (this.stagingBoxesNumber > 1)
      return 2;

    const stageHeight: number = this.stageRef.current?.offsetHeight || 0;
    const maxItems = Math.floor((stageHeight - offset) / stagingItemHeight);
    return maxItems > 2 ? maxItems : 3;
  }

  @observable error: Error | null = null;

  @action
  async mounted(params: UploadPageParams) {

    const { uploadService } = this.store;

    this.teamId = params.teamId || null;
    this.jobId = params.jobId || null;
    this.previousRoute = params.teamId
      ? Routes.teamDashboard(params.teamId)
      : params.jobId
        ? Routes.trainerDashboard()
        : Routes.userDashboard();
    this.emit('Mounted', { teamId: this.teamId });

    this.isLoading = true;

    if (this.teamId && !this.team)
      await this.store.teamManager.apiFetchTeam({ id: this.teamId });

    if (this.jobId && !this.job)
      await this.store.jobManager.apiFetchJob(this.jobId);

    if (
      (!this.teamId && !this.store.user?.hasPermission('ViewUploadPage')) ||
      (this.teamId && !this.team?.hasPermission('CreateJob'))
    )
      return this.setError(new Error(`Unauthorized`));

    this.fileComponent.init();
    
    uploadService.openLibraryUploadWindowOnMount();

    runInAction(() => 
      this.isLoading = false);
  }

  @action
  unmounted() {
    this.reset();
  }

  handleBackButton = (prevRoute: string) => {

    if (this.showOpenConfirmation)
      this.dispatch('openConfirmationModal', {
        onSubmit: () => this.navigateBack(prevRoute),
        title: 'You have unsubmitted videos',
        message: 'Are you sure you want to leave the current page?',
        secondaryMessage: 'You will lose edits you have made.',
        confirmLabel: 'Leave',
        closeLabel: 'Stay',
        layout: 'danger'
      })
    else {
      this.navigateBack(prevRoute)
    }
  }

  /**
   * Checks if the previous accessed library is the Trainer dashboard and routes back to it
   * This should be checked because connecting to external libraries from the Upload page results
   * in a redirect and the app loses the correct previous route state
  */
  navigateBack(prevRoute: string) {
    const lastVisitedLibrary = this.store.storage.getLocal<match>(
      'router.lastVisitedLibrary',
      true
    );
    if (typeof lastVisitedLibrary === 'object' && lastVisitedLibrary?.path === Routes.trainerDashboard()) {
      this.store.goTo(Routes.trainerDashboard());
    } else {
      this.previousRoute && this.store.goTo(prevRoute || this.previousRoute);
    }
  }

  @action
  reset() {
    this.error = null;
    this.fileComponent.reset();
    this.linkComponent.reset();
    this.emit('Unmounted');
    this.store.externalLibraryUploadWindow.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }

  @action
  init() {
    this.fileComponent.init();
    // this.linkComponent.init();
  }

  @action
  private setError(error?: Error) {
    if (!error)
      error = pageError();

    this.error = error;
  }

  private receiver = (msg: Message) => {

    switch (msg.type) {
      case 'taskDiscarded':
        this.fileComponent.pageSessionTasks.remove(msg.payload.task);
        if (this.fileComponent.pageSessionTasks.length === 0)
          this.fileComponent.reset();
        break;
      case 'taskCompleted':
        if (this.isReplace)
          this.store.goTo(Routes.trainerDashboard());
        // const { teamId, jobId } = msg.payload;
        // if (this.fileComponent.isQueueCompleted) {
        //   if (teamId)
        //     this.store.goTo(Routes.teamDashboard(teamId));
        //   else if (jobId) {
        //     this.store.goTo(Routes.trainerDashboard());
        //   }
        //   else
        //     this.store.goTo(Routes.userDashboard());
        // }
        break;
    }
  }
}