import * as React from 'react';

export const FullscreenSVGIcon = () =>
  <svg className="fullscreen-svg-icon svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="fill" d="M16.1635 3C16.0256 3 15.9138 3.11193 15.9138 3.25V4.56255C15.9138 4.70062 16.0256 4.81255 16.1635 4.81255H18.5692L12.9039 10.4826C12.8064 10.5802 12.8064 10.7385 12.9039 10.8361L13.8281 11.7614C13.875 11.8083 13.9385 11.8347 14.0047 11.8347C14.0709 11.8347 14.1345 11.8083 14.1813 11.7614L19.8465 6.0915V8.50019C19.8465 8.63826 19.9583 8.75019 20.0962 8.75019H21.4071C21.5451 8.75019 21.6569 8.63826 21.6569 8.50019V3.90627C21.6569 3.40576 21.2516 3 20.7517 3H16.1635Z" />
    <path className="fill" d="M10.8294 12.9042C10.7826 12.857 10.7189 12.8304 10.6525 12.8303C10.5861 12.8302 10.5224 12.8566 10.4755 12.9037L4.81033 18.5796V16.1714C4.81033 16.0333 4.69853 15.9214 4.56062 15.9214H3.24972C3.1118 15.9214 3 16.0333 3 16.1714V20.7653C3 21.2658 3.40521 21.6716 3.90517 21.6716H8.49332C8.63123 21.6716 8.74303 21.5596 8.74303 21.4216V20.109C8.74303 19.971 8.63123 19.859 8.49332 19.859H6.08762L11.7529 14.189C11.8502 14.0916 11.8505 13.9338 11.7535 13.8361L10.8294 12.9042Z" />
    <path className="fill" d="M21.6641 16.1714C21.6641 16.0334 21.5521 15.9216 21.4141 15.9216L20.1015 15.9216C19.9634 15.9216 19.8515 16.0334 19.8515 16.1714L19.8515 18.577L14.1815 12.9117C14.0839 12.8142 13.9256 12.8142 13.828 12.9117L12.9026 13.8359C12.8557 13.8828 12.8294 13.9463 12.8294 14.0125C12.8294 14.0788 12.8557 14.1423 12.9026 14.1891L18.5726 19.8543L16.1639 19.8543C16.0258 19.8543 15.9139 19.9661 15.9139 20.104L15.9139 21.415C15.9139 21.5529 16.0258 21.6647 16.1639 21.6647L20.7578 21.6647C21.2583 21.6647 21.6641 21.2595 21.6641 20.7595L21.6641 16.1714Z" />
    <path className="fill" d="M11.7599 10.8372C11.8071 10.7904 11.8336 10.7267 11.8337 10.6603C11.8338 10.5939 11.8074 10.5302 11.7604 10.4833L6.08445 4.81815L8.49269 4.81815C8.63076 4.81815 8.74269 4.70634 8.74269 4.56843L8.74269 3.25753C8.74269 3.11961 8.63076 3.00781 8.49269 3.00781L3.89876 3.00781C3.39825 3.00781 2.99249 3.41302 2.99249 3.91298L2.99249 8.50113C2.99249 8.63904 3.10442 8.75084 3.24249 8.75084L4.55504 8.75084C4.69311 8.75084 4.80504 8.63904 4.80504 8.50113L4.80504 6.09543L10.4751 11.7607C10.5724 11.858 10.7302 11.8583 10.828 11.7614L11.7599 10.8372Z" />
  </svg>

export const FitSVGIcon = () =>
  <svg className="fit-svg-icon svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="fill" d="M17.308 8.30803C17.2105 8.21051 17.0523 8.2106 16.9547 8.30823L16.0266 9.23634C15.9289 9.33397 15.9288 9.49217 16.0264 9.58969L17.7274 11.2908L12.7122 11.2941C12.5742 11.2942 12.4623 11.4061 12.4622 11.5441L12.4614 12.8519C12.4613 12.9182 12.4876 12.9817 12.5344 13.0286C12.5813 13.0754 12.6448 13.1017 12.7111 13.1016L17.7263 13.0983L16.0231 14.8015C15.9254 14.8991 15.9253 15.0573 16.0229 15.1549L16.9498 16.0818C17.0473 16.1793 17.2055 16.1792 17.3032 16.0816L20.5516 12.8332C20.9055 12.4793 20.9059 11.9059 20.5523 11.5523L17.308 8.30803Z" />
    <path className="fill" d="M11.5329 11.5395C11.5331 11.4731 11.5069 11.4093 11.4601 11.3622C11.4132 11.3152 11.3495 11.2888 11.283 11.2889L6.26365 11.2965L7.96653 9.59367C8.06416 9.49604 8.06425 9.33784 7.96673 9.24032L7.03978 8.31337C6.94226 8.21585 6.78406 8.21594 6.68643 8.31357L3.43804 11.562C3.08412 11.9159 3.08373 12.4893 3.43725 12.8428L6.68156 16.0872C6.77908 16.1847 6.93729 16.1846 7.03492 16.087L7.96303 15.1588C8.06066 15.0612 8.06075 14.903 7.96323 14.8055L6.26215 13.1044L11.2774 13.1011C11.4151 13.101 11.5268 12.9896 11.5274 12.852L11.5329 11.5395Z" />
    <rect className="fill" x="21" y="5" width="2.5" height="15" rx="1" />
    <rect className="fill" x="0.5" y="5" width="2.5" height="15" rx="1" />
  </svg>

export const MinusSVGIcon = () =>
  <svg className="minus-svg-icon svg-icon" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <rect className="fill" x="12.668" y="7.5" width="1.66667" height="10" rx="0.833333" transform="rotate(90 12.668 7.5)" />
  </svg>

export const PlusSVGIcon = () =>
  <svg className="plus-svg-icon svg-icon" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
    <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M5.49944 0.335938C5.03921 0.335938 4.66611 0.709033 4.66611 1.16927V4.50164H1.49935C1.03911 4.50164 0.666016 4.87474 0.666016 5.33498C0.666016 5.79522 1.03911 6.16831 1.49935 6.16831H4.66611V9.5026C4.66611 9.96284 5.03921 10.3359 5.49944 10.3359C5.95968 10.3359 6.33278 9.96284 6.33278 9.5026V6.16831H9.83268C10.2929 6.16831 10.666 5.79522 10.666 5.33498C10.666 4.87474 10.2929 4.50164 9.83268 4.50164H6.33278V1.16927C6.33278 0.709034 5.95968 0.335938 5.49944 0.335938Z" />
  </svg>