import { action, computed, makeObservable, observable } from 'mobx';
import { BindingProps, Message, refProxy, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { JobModel, MomentModel, SyncStatus, Team, Transcript } from '../../entities';
import { PlayerState } from '../player/playerState';
import { input, InputState, RadioItemProps } from '../input';
import { findAllMatches, isNonEmptyString } from '../../core'; //findAllAppearances
import { PlayerFramesetState } from '../playerFrameset/playerFramesetState';
import debounce from 'lodash/debounce';
import { TeamMemberRole, TranscriptFormat } from '../../../../../libs/lib/dist';
import { notifyError } from '../../services/notifications';
import { DownloadWindowState } from '../download/downloadWindowState';

export enum PlayerSearchMode {
  Initialized = 'Initialized',
  Ask = 'Ask',
  Transcript = 'Transcript'
}

export type PlayerTranscriptsLayout = 'vertical' | 'horizontal';

type Props = BindingProps<{
  jobId: string,
  player: PlayerState,
  frameset: PlayerFramesetState,
  teamId?: string | null,
  playerTutorialHighlightedComponents?: string[];
}>

export const TranscriptFormatItems: RadioItemProps[] = [
  { value: 'RTF', label: '.rtf' },
  { value: 'TXT', label: '.txt' }
];

export class PlayerTranscriptsState
  extends StoreNode {

  @observable isActiveTranscriptIntoView: boolean = false;
  @observable chatGptSearchKey: string | null = null;
  @observable chatGptSearchResult: string | null = null;
  @observable chatGptLoading: boolean = false;
  @observable playerSearchMode: PlayerSearchMode = PlayerSearchMode.Initialized;
  @observable refreshScrollTimeout: ReturnType<typeof setTimeout> | null = null;

  readonly horizontalComponent = refProxy(this);
  readonly verticalComponent = refProxy(this);

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.searchQuery = input(this, {
      name: 'searchQuery',
      onChange: debounce(() => this.refreshSearchMatches(), 200)
    });

    this.autoListen('player', this.playerListener);
  }

  readonly searchQuery: InputState;

  private scrollToMoment = (moment: MomentModel) => {

    if (!moment) 
      return;

    const sectionElement = this.activeComponent;

    if (!sectionElement) 
      return;

    const topOffsetElement = sectionElement.querySelector('#user-player-transcript-header');
    const scrollElement = sectionElement.querySelector(`#user-player-transcript-scroll`);
    // const domMoment = this.domGetTranscriptMoment(moment.id);
    const domMoment = sectionElement.querySelector(`[data-moment="true"][data-moment-id="${moment.id}"]`);

    if (!domMoment || !scrollElement) 
      return;

    const topOffsetElementHeight = topOffsetElement?.clientHeight || 0;   //header overlapping the scroll box
    const scrollElementTop = scrollElement.getBoundingClientRect().top;   //top value of the scroll element relative to the viewport
    const scrollElementScrollTop = scrollElement.scrollTop;               //how much is the element scrolled (always positive or 0)
    const domMomentTop = domMoment.getBoundingClientRect().top;           //top value of the scroll item element relative to the viewport
    let centerMomentOffset = (scrollElement.clientHeight - domMoment.clientHeight - topOffsetElementHeight) / 2;

    if (domMoment.clientHeight > (scrollElement.clientHeight - topOffsetElementHeight)) // when element is bigger than the visibile container it should be aligned on top
      centerMomentOffset = 0;

    // to determine the top value:
    const topValue =
      (domMomentTop - scrollElementTop) // determine the position of the scroll item relative to the top of the scroll element
      + scrollElementScrollTop          // add it to the already scrolled value
      - topOffsetElementHeight          // subtract the header offset
      - centerMomentOffset;             // subtract the position offset

    scrollElement.scrollTo({
      top: topValue,
      behavior: 'smooth'
    })
  }

  private scrollToSearchMatch = () => {

    const sectionElement = this.activeComponent;

    if (!sectionElement) 
      return;

    const topOffsetElement = sectionElement.querySelector('#user-player-transcript-header');
    const scrollElement = sectionElement.querySelector(`#user-player-transcript-scroll`);
    // const domMoment = this.domGetTranscriptMoment(moment.id);
    const domSearchMatch = sectionElement.querySelector(`[data-match="true"][data-match-id="${this.searchNavIndex}"]`);

    if (!domSearchMatch || !scrollElement) 
      return;

    const topOffsetElementHeight = topOffsetElement?.clientHeight || 0;   //header overlapping the scroll box
    const scrollElementTop = scrollElement.getBoundingClientRect().top;   //top value of the scroll element relative to the viewport
    const scrollElementScrollTop = scrollElement.scrollTop;               //how much is the element scrolled (always positive or 0)
    const domMomentTop = domSearchMatch.getBoundingClientRect().top;           //top value of the scroll item element relative to the viewport
    let centerMomentOffset = (scrollElement.clientHeight - domSearchMatch.clientHeight - topOffsetElementHeight) / 2;

    // to determine the top value:
    const topValue =
      (domMomentTop - scrollElementTop) // determine the position of the scroll item relative to the top of the scroll element
      + scrollElementScrollTop          // add it to the already scrolled value
      - topOffsetElementHeight          // subtract the header offset
      - centerMomentOffset;             // subtract the position offset

    scrollElement.scrollTo({
      top: topValue,
      behavior: 'smooth'
    })
  }

  private isMomentIntoView(moment: MomentModel | null) {
    if (!moment)
      return;

    const scrollElement = document.querySelector(`#user-player-transcript-scroll`);
    const domMoment = this.domGetTranscriptMoment(moment.id);

    if (!domMoment || !scrollElement)
      return;

    const topOffsetElement = document.querySelector('#user-player-transcript-header')?.clientHeight || 0;
    const scrollElementTop = scrollElement.getBoundingClientRect().top;
    const scrollElementBottom = scrollElement.getBoundingClientRect().bottom;
    const domMomentTop = domMoment.getBoundingClientRect().top;
    const domMomentBottom = domMoment.getBoundingClientRect().bottom;

    return (domMomentTop >= (scrollElementTop + topOffsetElement)) && (domMomentBottom <= scrollElementBottom);
  }

  private domGetTranscriptMoment(id: string) {
    return document.querySelector(`[data-moment="true"][data-moment-id="${id}"]`);
  }

  private playerListener = (msg: Message<PlayerState>) => {
    const { type, payload } = msg;
    switch (type) {
      case 'activeTranscriptChange':
        this.scrollToMoment(payload.moment);
        break;

      default: break;
    }
  }

  @action
  scrollToCurrent() {

    const { momentView } = this.player;
    if (!momentView.activeTranscript)
      return;

    this.scrollToMoment(momentView.activeTranscript);
  }

  @action
  clearSearchQuery = () => {
    this.searchQuery.value = null;
    this.refreshSearchMatches();
    this.searchNavIndex = 1;

    if (!this.isInitializedSearchMode) {
      this.playerSearchMode = PlayerSearchMode.Initialized;
    }
  }
  @observable searchNavIndex: number = 1;
  @observable searchMatches = observable.map<string, MomentSearchAtom>();

  @computed get placeholder() {
   return this.cliprGptEnabled ? 'Ask anything or search in transcript' : 'Search in transcript';
  }

  @computed get isAiProcessing() {
    const getUserProvider = () => (this.store.profilePage.localUser ?? this.store.user)?.generativeAiSettings?.provider;
    const getTeamProvider = (teamId: string) => this.store.teamManager.getTeam(teamId)?.generativeAiProvider;
    const getOwnerProvider = () => this.job?.owner?.team?.generativeAiProvider || this.job?.owner?.user?.generativeAiProvider;
  
    let gptProvider = getUserProvider() || getOwnerProvider();

    if (this.teamId) {
      gptProvider = getTeamProvider(this.teamId) || gptProvider;
    }

    if (gptProvider)
      return this.job?.isAiProcessing(gptProvider);

    return false;  
  }

  @computed get cliprGptEnabled() {
    const { user } = this.store;
    const { localUser } = this.store.profilePage;
    return this.job?.hasAutomaticEnrichmentLevel && this.job.isDone &&
    (this.team?.cliprGptEnabled ?? localUser?.cliprGptEnabled ?? user?.cliprGptEnabled);
  }

  @computed get isTranscriptSearchMode() {
    return this.cliprGptEnabled ? this.playerSearchMode === PlayerSearchMode.Transcript : true;
  }

  @computed get isCliprGptSearchMode() {
    return this.playerSearchMode === PlayerSearchMode.Ask;
  }

  @computed get isInitializedSearchMode() {
    return this.playerSearchMode === PlayerSearchMode.Initialized;
  }

  @computed get momentsSyncStatus(): SyncStatus | null {
    return this.job?.momentsSyncStatus || null;
  }

  @computed
  get searchValue(): string | null {
    return this.searchQuery.value;
  }

  @computed get searchMatchItems(): MomentSearchAtom[] {
    return [...this.searchMatches.values()];
  }

  @computed get searchMatchesNumber(): number {
    return this.searchMatchItems.length;
  }

  @computed get selectedSearchMatch(): MomentSearchAtom | null {
    return this.getSearchMatchByGlobalId(this.searchNavIndex);
  }

  @computed get selectedMomentMatch(): MomentModel | null {
    const momentId = this.selectedSearchMatch?.momentId;
    return this.items?.find(moment => moment.id === momentId) || null;
  }

  @computed get showOverlay(): boolean {
    const { job } = this;

    return (job?.isTranscriptProcessing || job?.isTranscriptPending)
      ?? false;
  }

  getMomentMatches(momentId: string): MomentSearchAtom[] {
    return this.searchMatchItems.filter(momentMatch => momentMatch.momentId === momentId);
  }

  getSearchMatchByGlobalId(globalId: number): MomentSearchAtom | null {
    return this.searchMatches.get(globalId.toString()) || null;
  }

  @action
  insertSearchMatch(momentMatch: MomentSearchAtom) {
    this.searchMatches.set(momentMatch.globalIndex.toString(), momentMatch);
  }

  // search section 
  @action
  increaseSearchNavIndex() {
    if (this.searchNavIndex < this.searchMatchesNumber)
      this.searchNavIndex++;
    else
      this.searchNavIndex = 1;
    if (this.selectedMomentMatch)
      this.scrollToSearchMatch()
    // this.scrollToMoment(this.selectedMomentMatch)
  }

  @action
  decreaseSearchNavIndex() {
    if (this.searchNavIndex > 1)
      this.searchNavIndex--;
    else
      this.searchNavIndex = this.searchMatchesNumber;

    if (this.selectedMomentMatch)
      this.scrollToSearchMatch()
    // this.scrollToMoment(this.selectedMomentMatch)
  }

  @action
  onChatGptMode = async (chatGptSearchKey: string) => {
    if (this.store.ui.isMobile) {
      this.frameset?.hideSection('Index');
      this.frameset?.hideSection('Comments');
      this.frameset?.hideSection('Toolbar');
    }
    
    if (!chatGptSearchKey || !isNonEmptyString(chatGptSearchKey) || this.chatGptLoading) 
      return;

    this.playerSearchMode = PlayerSearchMode.Ask;
    this.chatGptLoading = true;
    this.chatGptSearchKey = chatGptSearchKey;
    this.searchQuery.value = null;
    
    const { user: profile } = this.store;
    const userId = !this.teamId ? profile?.id : undefined;
    const [res, err] = await this.store.api.getGPTAnswer({
      input: {
      question: chatGptSearchKey,
      jobId: this.jobId,
      teamId: this.teamId ?? undefined,
      userId: userId ?? undefined
      }
    });

    this.chatGptLoading = false;
    if (!res || err) {
      return notifyError(this, 'Cannot extract answer. Try again.');
    }

    this.chatGptSearchResult = res.getGPTAnswer.result;
  }

  @action
  onTranscriptMode = () => {
    this.chatGptSearchKey = null;
    this.chatGptSearchResult = null;
    this.playerSearchMode = PlayerSearchMode.Transcript;
    this.refreshSearchMatches();
  }

  @action
  onBackToTranscript = () => {
    this.chatGptSearchKey = null;
    this.chatGptSearchResult = null;
    this.playerSearchMode = PlayerSearchMode.Initialized;
  }

  @action
  refreshSearchMatches() {
    if (!this.isTranscriptSearchMode) 
      return;
    
    this.player.invoke('enterPause');
    this.searchMatches.clear();
    this.searchNavIndex = 1;

    if (!this.searchValue)
      return;

    let globalIndex = 0;

    this.items.forEach((moment, index) => {

      if (!moment.description || !this.searchValue)
        return;

      // let indexPairList = findAllAppearances(moment.description, this.searchValue);
      let indexPairList = findAllMatches(moment.description, this.searchValue);
      indexPairList.forEach((indexPair, i) => {
        globalIndex++;
        let momentMatch = new MomentSearchAtom({
          globalIndex: globalIndex,
          momentId: moment.id,
          indexPair: indexPair
        })
        this.insertSearchMatch(momentMatch);
      })
    });

    this.refreshScrollTimeout = setTimeout(() => this.scrollToSearchMatch(), 100);
  }
  //end search section
  @computed get teamId(): string | null {
    return this.resolvedProps.teamId ?? null;
  }

  @computed get team(): Team | null {
    return this.store.teamManager.getTeam(this.teamId);
  }

  @computed get userTeamRole(): TeamMemberRole | null {
    const { user } = this.store;
    if (user) {
      return this.team?.getMember(user.id)?.role ?? null;
    }

    return null;
  }

  @computed get jobId() {
    return this.resolvedProps.jobId;
  }

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }
  @computed get frameset(): PlayerFramesetState | null {
    return this.resolvedProps.frameset ?? null;
  }

  @computed get activeLayout(): PlayerTranscriptsLayout | null {
    const isTranscriptsVisible = this.frameset?.isSectionVisible('Transcripts');
    if (!isTranscriptsVisible)
      return null;

    if (this.frameset?.isWidgetMode)
      return 'horizontal';
    else
      return this.frameset?.isSectionVisible('Transcripts')
        && (this.frameset?.isSectionVisible('Index')
          || this.frameset?.isSectionVisible('Comments')) ?
        'horizontal' :
        'vertical';
  }

  @computed get activeComponent() {
    if (this.activeLayout) // active layout stated by the frameset
      return this.activeLayout === 'vertical' ?
        this.verticalComponent?.current :
        this.horizontalComponent?.current
    else { // if there is no active layout stated by the frameset, it should still try to find the active component
      if (this.horizontalComponent?.current)
        return this.horizontalComponent?.current
      if (this.verticalComponent?.current)
        return this.verticalComponent?.current
    }
    return null;
  }

  @computed
  get player(): PlayerState {
    return this.resolvedProps.player;
  }

  @computed
  get items(): Array<Transcript> {
    return this.player?.momentView?.transcriptMoments || [];
  }

  @computed
  get isSearchActive(): boolean {
    return !!this.searchQuery.value;
  }

  @computed
  get activeTranscript(): Transcript | null {
    return this.player.momentView.activeTranscript;
  }

  @action
  updateIsTranscriptIntoView() {
    this.isActiveTranscriptIntoView = this.isMomentIntoView(this.player.momentView.activeTranscript) || false;
  }

  @action
  mounted() {
    this.updateIsTranscriptIntoView();
    this.scrollToCurrent();
  }

  @action
  handleScroll = () => {
    this.updateIsTranscriptIntoView();
  }

  @action
  openDownloadTranscript = async () => {

    const window = this.store.downloadWindow;

    const listener = (msg: Message<DownloadWindowState>) => {
      switch (msg.type) {
        case 'open':
          this.player.invoke('enterPause');
          break;
        case 'close':
          window.unlisten(listener);
          this.player.invoke('exitPause');
          break;
      }
    }

    window.listen(listener);

    window.open({
      message: 'Transcript format',
      onSubmit: async (format: string) => {
        if (!this.job)
          return null;

        const args = {
          format: format as TranscriptFormat
        };

        const [res, err] = await this.job.apiGetJobTranscript(args);

        if (!res || err) {
          return notifyError(this, 'Transcript download failed.');
        }

        return res;
      },
      formatItems: TranscriptFormatItems,
      title: 'Download Transcript'
    });

    return true;
  }

  @action
  reset() {
    this.playerSearchMode = PlayerSearchMode.Initialized;
    this.clearSearchQuery();

    if (this.refreshScrollTimeout)
      clearTimeout(this.refreshScrollTimeout);
  }
}

type MomentSearchItemProps = {
  globalIndex: number;
  momentId: string;
  indexPair: [number, number]
}

export class MomentSearchAtom {
  globalIndex: number;
  momentId: string;
  indexPair: [number, number];

  constructor(props: MomentSearchItemProps) {
    this.globalIndex = props.globalIndex;
    this.momentId = props.momentId;
    this.indexPair = props.indexPair;
  }

}


