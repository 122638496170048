import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store/storeHooks';
import { SearchBar } from '../../components/search';
import { LoadMask } from '../../components/loader';
import { BasicPage, Grid, SplashPageHeader } from '../../components/layout';
import { NoResults } from './noResults';
import {
  SearchResultMomentCard,
  SeeMoreCard,
} from './searchResultCard';
import './searchResultsPage.scss';
// import routes from '../../routes';
import { MomentResultItem } from './momentResultsCatalog';
import { useWindowResize } from '../../components/hooks';
import { resolutionBasedPageSize } from '../../components/utils';
import { JobModel } from '../../entities';
import { JobCard } from '../../components/media';
import { useRouteContext } from '../../routes/routeHooks';

export const SearchResultsPage = observer(() => {
  /* eslint-disable no-unused-vars */
  const [width,] = useWindowResize();
  const store = useStore();

  const state = store.searchResultsPage;

  const { isLoading, hasResults } = state;

  const qs = new URLSearchParams(window.location.search);
  const teamId = qs.get('teamId') || undefined;

  useEffect(() => {
    const pageSize: number = resolutionBasedPageSize(width, false);
    state.setPageSize(pageSize - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width])

  const routeContext = useRouteContext();
  useEffect(() => {
    state.mounted();
    return () => state.unmounted();
  }, [state, routeContext]);

  return (
    <BasicPage
      className="search-results-page"
      showHeader={false}>
      <SplashPageHeader
        leftArrow={true}
        stretchContent={true}>
        <SearchBar 
          size="large" 
          teamId={teamId}
          replaceRouteOnSubmit={true} />
      </SplashPageHeader>
      <div className="main-container">
        {!isLoading &&
          (hasResults === false ? <NoResults queryString={state.queryString ?? ''} /> : <SearchResultsList teamId={teamId} />)}
        {isLoading &&
          <LoadMask
            visible={true} />}
      </div>
    </BasicPage>
  );
});

type SearchResultsListType = {
  teamId?: string;
}

const SearchResultsList = observer(({ teamId }: SearchResultsListType) => {
  const store = useStore();
  const state = store.searchResultsPage;

  const { jobCatalog, momentCatalog, topicCatalog } = state;
  const { jobs } = jobCatalog;
  const { moments } = momentCatalog;
  const { moments: topics } = topicCatalog;

  return (
    <>
      {!jobCatalog.isEmpty && (
        <div className="results-cathegory catalog">
          <h3 className="cathegory-title">
            Full videos ({jobCatalog.pageInfo?.totalCount})
          </h3>
          <div className="catalog-content catalog-viewport">
            <Grid className="catalog-grid">
              {jobs.map((video: JobModel) => (
                <li key={video.id}>
                  <JobCard
                    isExpandable={false}
                    teamId={teamId}
                    model={video} />
                </li>
              ))}
              {jobCatalog.hasNextPage && (
                <li>
                  <SeeMoreCard
                    className="video"
                    isLoading={jobCatalog.isFetchingMore}
                    onClick={() => jobCatalog.fetchMore(state.pageSize)}
                  />
                </li>
              )}
            </Grid>
          </div>
        </div>
      )}
      {!topicCatalog.isEmpty && (
        <div className="results-cathegory catalog">
          <h3 className="cathegory-title">
            Topics ({topicCatalog.pageInfo?.totalCount})
          </h3>
          <div className="catalog-content catalog-viewport">
            <Grid className="catalog-grid">
              {topics.map((topic: any) => {
                return (
                  <li key={topic.id} className="grid-item catalog-item">
                    <SearchResultMomentCard model={topic} />
                  </li>
                );
              })}
              {topicCatalog.hasNextPage && (
                <li>
                  <SeeMoreCard
                    className="topic"
                    isLoading={topicCatalog.isFetchingMore}
                    onClick={() => topicCatalog.fetchMore(state.pageSize)}
                  />
                </li>
              )}
            </Grid>
          </div>
        </div>
      )}
      {!momentCatalog.isEmpty && (
        <div className="results-cathegory catalog">
          <h3 className="cathegory-title">
            Moments ({momentCatalog.pageInfo?.totalCount})
          </h3>
          <div className="catalog-content catalog-viewport">
            <Grid className="catalog-grid">
              {moments.map((moment: MomentResultItem) => {
                return (
                  <li key={moment.id} className="grid-item catalog-item">
                    <SearchResultMomentCard model={moment} />
                  </li>
                );
              })}
              {momentCatalog.hasNextPage && (
                <li>
                  <SeeMoreCard
                    className="topic"
                    isLoading={momentCatalog.isFetchingMore}
                    onClick={() => momentCatalog.fetchMore(state.pageSize)}
                  />
                </li>
              )}
            </Grid>
          </div>
        </div>
      )}
    </>
  );
});

export default SearchResultsPage;