import { action, makeObservable, observable, runInAction } from 'mobx';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';

export class DeleteStreamWindowState
  extends StoreNode {

  readonly nodeType = 'DeleteStreamWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);

  @observable isLoading: boolean = false;

  streamId?: string | null;
  streamName?: string | null;

  @action
  async submit() {
    this.isLoading = true;

    const [, err] = await this.store.apiService.archiveJob({ id: this.streamId || '' });

    if (err) {
      notifyError(this, 'Could not delete the video.');
      return this.handleSubmitFinally();
    }

    runInAction(() => {
      notifySuccess(this, 'Stream deleted.');
      this.emit('streamDeleted')

      // TODO: We need to decouple this from the widget / page ASAP!
      this.store.widgetService.isWidgetMode ?
        this.store.liveStreamQueueWidget.refreshJobs() :
        this.store.liveStreamQueuePage.refreshJobs();

      this.handleSubmitFinally();
    });
  }


  @action
  handleSubmitFinally() {
    this.isLoading = false;
    this.close();
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open({ streamId, streamName }: { streamId: string, streamName?: string }) {
    this.dispatch('Overlays', 'openWindow', { name: 'DeleteStreamWindow' });

    this.streamId = streamId;
    this.streamName = streamName;
  }

  @action
  close() {
    this.streamName = null;
    this.streamId = null;

    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}