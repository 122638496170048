import { computed, makeObservable } from 'mobx';
import { MomentModel } from '../../entities';
import { BindingProps, Message, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { IPlayer } from './playerSchema';

type Props = BindingProps<{
  player?: IPlayer,
  moments?: MomentModel[],
  enabled?: boolean,
  autoJump?: boolean,
  passageLength?: number
}>

const PASSAGE_LENGTH = 10;

/**
 * Manages jumps between the provided moments for a player.
 * This component does not make any distinctions between moment types or other criteria.
 * Also, this component does not invoke the jumps itselfs, but notifies through `emit` messages
 * what actions should be called by the interested parties.
 */
export class PlayerDirector
  extends StoreNode {

  constructor(store: Store, props?: BindingProps<Props>) {
    super(store, props);
    makeObservable(this);

    this.autoListen('player', this.playerListener);
  }

  @computed({ keepAlive: true })
  get moments(): MomentModel[] | null {
    return this.resolvedProps.moments ?? null;
  }

  @computed({ keepAlive: true })
  get player(): IPlayer | null {
    return this.resolvedProps.player ?? null;
  }

  @computed({ keepAlive: true })
  get enabled(): boolean {
    return this.resolvedProps.enabled ?? true;
  }

  @computed({ keepAlive: true })
  get passageLength(): number {
    return this.resolvedProps.passageLength ?? PASSAGE_LENGTH;
  }

  private ignoreNextSeek: boolean = false;
  private ignoreNextJump: boolean = false;
  private currentSeekStartTime: number = 0;

  private playerListener = (msg: Message) => {
    const { player } = this;
    if (!player)
      return;

    switch (msg.type) {
      case 'timeupdate':
      case 'play':
        this.handlePlayerTimeUpdate(player.currentTime);
        break;

      case 'seeking':
        if (this.ignoreNextSeek) {
          this.ignoreNextSeek = false;
          break;
        }

        this.ignoreNextJump = true;
        break;
    }
  }

  private hasActiveMoments(time: number): boolean {
    return this.moments
      ?.some(m => time >= m.startTime && time < m.endTime) || false;
  }

  /** Returns the first moment that starts after the specified time. */
  private getNextActiveMoment(time: number): MomentModel | null {
    // traditional loop to ensure short circuit
    const { moments } = this;
    if (!moments)
      return null;

    for (let moment of moments)
      if (moment.startTime > time)
        return moment;

    return null;
  }

  private handlePlayerTimeUpdate = (time: number): void => {

    // console.log('handlePlayerTimeUpdate.stop inside', time, this.player?.currentTime);

    // if the director is not enabled or the player is currently seeking, don't do anything
    if (
      !this.enabled ||
      !this.player ||
      this.player.isSeeking)
      return;

    const hasActive = this.hasActiveMoments(time);
    if (!hasActive) {

      // we're "in the wild", no moments here
      // check to see when is the next moment
      const nextActive = this.getNextActiveMoment(time);

      // check to see if we're in a no-moment zone because of a seek
      if (this.ignoreNextJump)
        return;

      if (nextActive) {
        // timeupdate frequency is dependant on system load, and will be thrown between 4Hz and 66Hz
        // we may receive a timeupdate while already requesting a seek to the next moment
        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/timeupdate_event
        if (nextActive.startTime === this.currentSeekStartTime && time > 0)
          return;

        // we have a next moment
        // if it starts sooner than the configured PASSAGE_LENGTH we don't do anything
        // otherwise we jump to it
        if (nextActive.startTime - time > this.passageLength) {
          this.ignoreNextSeek = true;
          this.currentSeekStartTime = nextActive.startTime;
          this.emit('jumpToMoment', { 
            moment: nextActive,
            source: 'PlayerDirector'
          });
          this.player.invoke('seek', { time: nextActive.startTime });
        }
      } else {
        // no more moments until the end of the video
        this.ignoreNextSeek = true;

        // we only jump to the end if we actually have moments in the director
        // otherwise you might be unable to play a video without moments if the director is enabled
        if (this.moments && this.moments.length > 0) {
          this.emit('jumpToEnd', {
            source: 'PlayerDirector' 
          });
          this.player.invoke('end');

          // console.log('handlePlayerTimeUpdate.stop inside', time, this.player.currentTime);
        }
      }
    } else {
      // we're in the middle of a moment
      // if we were previously seeking and we just got to the first moment after that, reset the state
      if (this.ignoreNextJump)
        this.ignoreNextJump = false;
    }
  }

  reset() {
    this.ignoreNextJump = false;
    this.ignoreNextSeek = false;
    this.currentSeekStartTime = 0;
  }
}