import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { assert } from '../../core';
import { notifyError, notifyLoading, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { DropdownItemObject, input, inputGroup, InputGroupState, InputState } from '../input';
import { SpeakerModel } from '../../entities/speaker';
import { closeWindow } from '../../services/overlays';
import { getLanguageInputItem, LanguageItems } from '../../entities/language';
import { ClipTypes, MomentTypes } from '../momentWindow/clipWindowState';
import { AddTrackMutationVariables, ClipType, UpdateTrackInput } from '@clipr/lib';
import { TrackModel } from '../../entities';
import { SpeakerFormBlockState } from '../speakers/speakerFormBlockState';

const TRACK_NAME_SUFFIX = '.manual';

export enum TrackWindowMode {
  Add = 'Add',
  Edit = 'Edit'
}

export class TrackWindowState
  extends StoreNode {

  readonly nodeType = 'TrackWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);

  readonly saveButton: InputState = input(this, {
    name: 'saveButton',
    disabled: (input: any) => this.isInputDisabled(input)
  });

  readonly speakerBlock = new SpeakerFormBlockState(this.store, {
    shouldFetchSpeaker: () => true,
    error: () => {
      if (this.isTranscriptWithSameSpeaker)
        return 'Speaker already exists';
      if (this.clipType.value === 'Transcript' && this.speakerBlock.isEmpty)
        return 'Required field';
    },
    disabled: () => this.clipType.value !== 'Transcript',
    jobId: () => this.jobId
  });

  readonly cancelButton: InputState = input(this, {
    name: 'cancelButton',
    disabled: (input: any) => this.isInputDisabled(input)
  });

  readonly language = input(this, {
    name: 'language',
    selectorItems: LanguageItems,
    export: (self: InputState) => self.normValue,
    disabled: (input: any) => this.isInputDisabled(input)
  });

  readonly name = input(this, {
    name: 'name',
    error: () => {
      if (this.clipType.normValue === 'Transcript')
        return null;
      if (this.name.isEmpty)
        return 'Required Field';
      if (this.isTrackNameDuplicated)
        return 'Track name already exists';
    },
    disabled: (input: any) => this.isInputDisabled(input)
  });

  readonly visibleToConsumer = input(this, {
    name: 'visibleToConsumer',
    disabled: (input: any) => this.isInputDisabled(input)
  });

  readonly clipType = input(this, {
    name: 'clipType',
    selectorItems: ClipTypes,
    isRequired: true,
    onChange: () => this.onClipTypeChange(),
    disabled: (input: any) => this.isInputDisabled(input)
  });

  readonly momentType = input(this, {
    name: 'momentType',
    selectorItems: MomentTypes,
    isRequired: () => this.clipType.normValue === 'Moment',
    onChange: () => {
      if (this.job?.isDone && this.activeTrackTypeExists && !!this.visibleToConsumer.value) {
        //@ts-ignore
        this.visibleToConsumer.value = false;
      }
      if (this.clipType.normValue === 'Moment')
        this.generateTrackName();
    },
    disabled: (input: any) => this.isInputDisabled(input)
  });

  readonly inputGroup: InputGroupState = inputGroup(this, {
    name: "track",
    inputs: [
      this.saveButton,
      this.cancelButton,
      this.language,
      this.clipType,
      this.momentType,
      this.visibleToConsumer,
      this.name,
      ...this.speakerBlock.inputGroup.inputs
    ],
    isSubmitDisabled: () =>
      this.inputGroup.isSubmitting ||
      this.inputGroup.hasVisibleError ||
      this.isLoading ||
      !this.inputGroup.isDirty
  });

  @observable isLoading = false;
  @observable isVisible = false;

  @observable trackId: string | null = null; //as track
  @observable jobId: string | null = null;

  @observable mode: TrackWindowMode | null = null;

  @computed
  get track(): TrackModel | null {
    const { trackId } = this;
    if (!trackId || !this.job?.hasTrack(trackId))
      return null;

    if (trackId)
      return this.job?.getTrack(trackId);

    return null;
  }

  @computed
  get job() {
    const { store, jobId } = this;
    assert(!!(jobId && store.hasJob(jobId)), "Expected valid job");
    if (jobId)
      return this.store.getJob(jobId);
    return null;
  }

  @computed
  get speakerModel(): SpeakerModel | null {
    return this.speakerBlock.speakerModel;
  }

  @computed
  get publicSpeakerItems(): DropdownItemObject[] {
    return this.store.speakerManager.publicSpeakers.map(speaker => ({
      value: speaker.id,
      label: speaker.name
    }));
  }

  @computed
  get isMomentTypeDisabled(): boolean {
    return !!(this.clipType.normValue !== 'Moment' ||
      (this.track && !this.track.isEmpty) ||
      this.momentType.loading ||
      this.momentType.isSubmitting)
  }

  @computed
  get isVisibleToConsumerDisabled(): boolean {
    const { track, job } = this;
    if (this.clipType.value === 'Transcript' || !job)
      return false;

    return (job.isDone && this.activeTrackTypeExists && !this.visibleToConsumer.value) ||
      (job.isDone && track?.hasTopicsWithChildSubtopics && !!this.visibleToConsumer.value) ||
      (job.isDone && track?.hasUncontainedSubtopics && !this.visibleToConsumer.value) ||
      !!this.visibleToConsumer.loading ||
      this.visibleToConsumer.isSubmitting;
  }

  @computed
  get isEditTrackActive(): boolean {
    return this.track?.isActive ?? false;
  }

  @computed
  get trackKind(): string | null {
    if (this.clipType.normValue === 'Transcript')
      return this.speakerModel?.name ?? null;
    if (this.clipType.normValue === 'Moment')
      return this.momentType.normValue ?? null;

    return this.clipType.normValue ?? null;
  }

  @computed
  get activeTrackTypeExists(): boolean {
    const { track, job } = this;
    const trackStub = {
      id: track?.id ?? undefined,
      type: this.clipType.value! as ClipType,
      momentType: this.momentType.value!,
      speakerId: this.speakerBlock.speakerModelId!
    }

    return job?.checkIfActiveDuplicateForTrack(trackStub) ?? false;
  }

  @computed
  get isEditMode(): boolean {
    return this.mode === TrackWindowMode.Edit;
  }

  @computed
  get isCreateMode(): boolean {
    return this.mode === TrackWindowMode.Add;
  }

  @computed
  get isTrackNameDuplicated(): boolean {
    const { job } = this;

    if (!job)
      return false;

    if (this.track)
      return job.tracks.some(track => track.name === this.name.value && track.id !== this.track?.id)

    return job.tracks.some(track => track.name === this.name.value);
  }

  @computed
  get isTranscriptWithSameSpeaker(): boolean {
    const { job } = this;

    if (!job || this.clipType.value !== 'Transcript')
      return false;

    if (this.track)
      return job.transcriptTracks.some(track =>
        track.speakerId === this.speakerModel?.id && track.id !== this.track?.id)

    return job.transcriptTracks.some(track => track.speakerId === this.speakerModel?.id);
  }

  @computed
  get shouldUpdateMoments(): boolean {
    const { isEditMode, clipType, speakerModel, track } = this;
    if (!speakerModel?.id || !track?.speakerId)
      return false;

    return isEditMode &&
      clipType.normValue === 'Transcript' &&
      speakerModel.id !== track.speakerId;
  }

  @computed
  get shouldHaveName(): boolean {
    return this.clipType.normValue !== 'Transcript';
  }

  @computed
  get defaultTrackName(): string | null {
    switch (this.clipType.normValue) {
      case 'Moment':
        if (this.momentType.isEmpty)
          return null;
        return this.momentType.normValue + TRACK_NAME_SUFFIX;
      case 'Transcript':
        return null;
      case 'Topic':
      case 'Chapter':
      case 'SubTopic':
      case 'Paragraph':
        return this.clipType.normValue + TRACK_NAME_SUFFIX;
      default:
        return null;
    }
  }

  @computed
  get isNameGenerated(): boolean {
    return ClipTypes.some(item => this.name.value === item + TRACK_NAME_SUFFIX) ||
      MomentTypes.some(item => this.name.value === item + TRACK_NAME_SUFFIX)
  }

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
        if (this.isLoading || this.inputGroup.isSubmitting)
          return;
        this.cancel();
        break;
      case 'outsideClick':
        if (this.isLoading || this.inputGroup.isSubmitting)
          return;
        this.cancel(true);
        break;
    }
  }

  isInputDisabled(input: any): boolean {
    const { name } = input;

    switch (name) {
      case 'speaker':
        return this.clipType.value !== 'Transcript';
      case 'saveButton':
        return this.inputGroup.isSubmitDisabled;
      case 'cancelButton':
        return (input.loading ||
          input.isSubmitting)
      case 'name':
        return this.clipType.normValue === 'Transcript';
      case 'visibleToConsumer':
        return this.isVisibleToConsumerDisabled;
      case 'clipType':
        return (this.track && !this.track.isEmpty) ||
          input.loading ||
          input.isSubmitting;
      case 'momentType':
        return this.isMomentTypeDisabled;
      default:
        return false;
    }
  }

  onClipTypeChange = () => {
    if (this.job?.isDone && this.activeTrackTypeExists && !!this.visibleToConsumer.value) {
      //@ts-ignore
      this.visibleToConsumer.value = false;
    }

    if (this.job?.isDone && this.clipType.normValue === 'SubTopic' && !this.job.activeTopicTrack) {
      this.momentType.value = null;
    }

    if (this.clipType.normValue !== 'Moment') {
      this.momentType.value = null;
    }
    if (this.clipType.normValue !== 'Transcript') {
      this.speakerBlock.clear();
    }
    if (this.clipType.normValue === 'Transcript') {
      this.name.value = null;
    }
    this.generateTrackName();
  }

  validateForm = (): string | null => {
    const { job, track } = this;
    const clipType = this.clipType.normValue;

    if (!job)
      return 'Job Required';

    if (this.isTranscriptWithSameSpeaker)
      return 'Another transcript track with the selected speaker already exists. Please select another speaker.';

    if (this.shouldHaveName && this.name.isEmpty)
      return 'Track name cannot be empty. Please insert a name.';

    if (this.isTrackNameDuplicated)
      return 'Track name already exists. Please choose another name.';

    if (track && track.type !== clipType && !track.isEmpty)
      return 'Track type can only be changed if the track is empty.';

    if (this.job?.isDone && this.activeTrackTypeExists && !!this.visibleToConsumer.value)
      return `An active ${this.trackKind ?? ''} track already exists.`;

    return null;
  }

  @action
  async submit() {
    this.inputGroup.handleSubmit();
    this.window.lock();
    if (this.inputGroup.error) {
      this.handleSubmitReject('Fix the validation errors before saving.');
      return;
    }

    const errorMessage = this.validateForm();
    if (errorMessage) {
      this.handleSubmitReject(errorMessage);
      return;
    }

    this.isLoading = true;

    if (this.clipType.value === 'Transcript') {
      const [, err] = await this.speakerBlock.submit();
      if (err) {
        this.handleSubmitReject(err);
        return;
      }
    }

    if (this.isCreateMode)
      //submit CREATE
      await this.submitCreate();
    else
      //submit EDIT
      await this.submitUpdate();
  }

  async submitUpdate() {
    if (!this.job || !this.trackId) {
      this.handleSubmitReject('Prerequisites failed');
      return;
    }

    notifyLoading(this, 'Updating Swimlane');

    try {
      const trackType = this.track?.isEmpty ? this.clipType.normValue as ClipType : undefined;

      const updArgs: UpdateTrackInput = {
        jobId: this.job.id,
        trackId: this.trackId,
        speakerId: this.speakerModel?.id ?? '',
        name: this.name.value ?? '',
        languageCode: this.language.normValue ?? '',
        type: trackType ?? undefined,
        momentType: this.clipType.normValue === 'Moment' ? (this.momentType.normValue ?? undefined) : '',
        visibleToConsumer: !!this.visibleToConsumer.value ?? undefined
      }

      const deps: ("tracks" | "moments")[] = this.shouldUpdateMoments ? ['tracks', 'moments'] : ['tracks'];
      const [res, err] = await this.job?.apiUpdateTrack({ args: updArgs }, deps);

      if (err)
        throw new Error(err);
      else
        this.emit('trackUpdated', { trackId: res?.id });
    } catch (e) {
      let message: string;
      // TODO: add typings
      if ((e as any).message === "Error: Track name already exists.")
        message = "Not possible to have 2 swimlanes with the same speaker.";
      else
        message = "Failed to update the swimlane."
      this.handleSubmitReject(message);
      return;
    }

    this.handleSubmitSuccess(`Track ${this.track?.displayName} updated successfully.`);
  }

  async submitCreate() {
    if (!this.job || !this.clipType.normValue) {
      this.handleSubmitReject('Prerequisites failed');
      return;
    }

    notifyLoading(this, 'Creating Swimlane');

    try {
      const addArgs: AddTrackMutationVariables = {
        args: {
          jobId: this.job.id,
          speakerId: this.speakerModel?.id ?? undefined,
          name: this.name.value ?? this.speakerModel?.id!,
          languageCode: this.language.normValue ?? '',
          type: this.clipType.normValue as ClipType ?? undefined,
          momentType: this.clipType.normValue === 'Moment' ? (this.momentType.normValue ?? undefined) : '',
          visibleToConsumer: !!this.visibleToConsumer.value ?? undefined
        }
      }
      const [res, err] = await this.job.apiAddTrack(addArgs);
      runInAction(() => this.trackId = res?.id ?? null);

      if (err)
        throw new Error(err);
      else
        this.emit('trackCreated', { trackId: res?.id });
    } catch (e) {
      let message: string;
      // TODO: add typings
      if ((e as any).message === "Error: Track name already exists.")
        message = "Not possible to have 2 swimlanes with the same name.";
      else
        message = "Failed to create the track."
      this.handleSubmitReject(message);
      return;
    }
    this.handleSubmitSuccess(`Track ${this.track?.displayName} created successfully.`);
  }

  @action
  private handleSubmitReject(msg: string) {
    this.inputGroup.handleSubmitReject();
    this.window.unlock();
    this.isLoading = false;
    notifyError(this, msg);
  }

  @action
  private handleSubmitSuccess(msg: string) {
    this.inputGroup.handleSubmitResolve();
    this.window.unlock();
    this.cancel();

    notifySuccess(this, msg);
  }

  private generateTrackName() {
    if (this.name.isEmpty || this.isNameGenerated)
      this.name.value = this.defaultTrackName;
  }

  @action
  private open(mode: TrackWindowMode) {
    this.dispatch('Overlays', 'openWindow', { name: 'TrackWindow' });
    this.window.open();
    this.mode = mode;
  }

  @action
  async openEdit(trackId: string, jobId: string) {
    this.isLoading = true;
    this.trackId = trackId;
    this.jobId = jobId;

    this.open(TrackWindowMode.Edit);
    if (this.job)
      await this.job.apiFetchTracks();

    const { track, store } = this;

    if (!track) {
      notifyError(this, 'Track could not be retrieved.');
      this.close()
      this.isLoading = false;
      return;
    }

    const speaker = store.maybeGetSpeaker(track.speakerId);

    if (speaker) {
      await this.speakerBlock.load({
        speakerId: speaker.id
      });
    }

    this.clipType.loadValue(track.type ?? null);
    this.momentType.loadValue(track.momentType ?? null);
    this.visibleToConsumer.loadValue(track.visibleToConsumer ?? false);
    const trackName = track.type !== 'Transcript' ? track.name : null;
    this.name.loadValue(trackName ?? null);
    this.language.loadValue(getLanguageInputItem(track.languageCode ?? null));
    runInAction(() => this.isLoading = false);
  }

  @action
  async openCreate(jobId: string) {
    this.jobId = jobId;
    this.open(TrackWindowMode.Add);
  }

  @action
  cancel(withConfirmation?: boolean) {
    if (this.inputGroup.isSubmitting || !this.window.isVisible)
      return;

    if (this.inputGroup.isDirty && withConfirmation)
      this.store.closeWindowConfirmationModal.open({
        onSubmit: () => {
          this.reset();
          this.close('close');
        }
      })
    else
      this.close('close');
  }

  @action
  close(msg?: string) {
    closeWindow(this);
    this.window.close();
    if (msg)
      this.emit(msg);
  }

  @action
  private reset() {
    this.trackId = null;
    this.jobId = null;
    this.isLoading = false;
    this.inputGroup.clear();
    this.speakerBlock.clear();
  }

  @action
  onTransitionEnd = () => {
    if (!this.window.isVisible)
      this.reset();
  }

  @action
  openSpeakerWindow() {
    if (!this.job)
      return;
    const speakerWindow = this.store.speakerWindow;
    speakerWindow.openCreate(this.job?.id, false);
  }
}