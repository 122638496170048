import { ThemeConfig } from '@visx/xychart/lib/theme/buildChartTheme';

export const DefaultThemeConfig: ThemeConfig = {
  backgroundColor: '#ffffff',
  colors: [
    'rgba(45, 182, 245, 1)',
    'rgba(57, 200, 106, 1)',
    'rgba(245, 195, 36, 1)',
    'rgba(255, 138, 101, 1)',
    'rgba(226, 137, 242, 1)',
    'rgba(255, 87, 34, 1)',
    'rgba(95, 39, 205, 1)',
    'rgba(29, 209, 161, 1)',
    'rgba(203, 91, 102, 1)',
  ],
  tickLength: 4,
  gridColor: '#adb5bd',
  gridColorDark: '#000',
};
