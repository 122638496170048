import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TumblrShareButton,
  RedditShareButton,
} from 'react-share';
import { Tooltip, Window, WindowHeader } from '../overlays';
import {
  ShareVideoWindowState,
  WINDOW_HEIGHT,
  WINDOW_WIDTH,
} from './shareVideoWindowState';
import { Button, TextInputControlled } from '../input';
import {
  FacebookSquareSVGIcon,
  LinkedInSquareSVGIcon,
  RedditSquareSVGIcon,
  TumblrSquareSVGIcon,
  TwitterSquareSVGIcon,
  WhatsappSquareSVGIcon,
  CopyLinkSVGIcon,
  CodeSVGIcon,
} from '..';
import { SocialSharePlatforms } from '../../services/analytics/stream';

type Props = {
  model: ShareVideoWindowState;
  visible?: boolean;
};

export const ShareVideoWindow = observer((props: Props) => {
  const { model } = props;
  const { modalTitle } = model;

  return (
    <Window
      model={model.window}
      visible={props.visible}
      className="share-video-window"
    >
      <WindowHeader model={model.window} title={modalTitle} />

      <hr />
      <div className="window-content">
        <p className="p-bold">Share on Social</p>
        <SocialShare model={model} />
      </div>
    </Window>
  );
});

const SocialShare = observer(({ model }: { model: ShareVideoWindowState }) => {
  return (
    <>
      <div className="share-message">
        <TextInputControlled
          model={model.messageInput}
          feedbackPosition="topEnd"
          placeholder="Type in message"
          label="Message"
          multiline
        />
      </div>
      <div className="share-label">Share on</div>
      <div className="share-buttons">
        <FacebookShareButton
          className="btn btn-core medium"
          resetButtonStyle={false}
          url={model.sharedLink!}
          quote={model.message}
          children={<FacebookSquareSVGIcon />}
          onShareWindowClose={() =>
            model.handleShareDialogClose(SocialSharePlatforms.Facebook)
          }
          windowHeight={WINDOW_HEIGHT}
          windowWidth={WINDOW_WIDTH}
        />
        <TwitterShareButton
          className="btn btn-core medium"
          resetButtonStyle={false}
          url={model.sharedLink!}
          title={model.titleWithMessage}
          children={<TwitterSquareSVGIcon />}
          onShareWindowClose={() =>
            model.handleShareDialogClose(SocialSharePlatforms.Twitter)
          }
          windowHeight={WINDOW_HEIGHT}
          windowWidth={WINDOW_WIDTH}
        />
        <LinkedinShareButton
          className="btn btn-core medium"
          resetButtonStyle={false}
          url={model.sharedLink!}
          title={model.titleWithMessage}
          summary={model.message}
          children={<LinkedInSquareSVGIcon />}
          onShareWindowClose={() =>
            model.handleShareDialogClose(SocialSharePlatforms.Linkedin)
          }
          windowHeight={WINDOW_HEIGHT}
          windowWidth={WINDOW_WIDTH}
        />
        <WhatsappShareButton
          url={model.sharedLink!}
          className="btn btn-core medium"
          resetButtonStyle={false}
          title={model.titleWithMessage}
          children={<WhatsappSquareSVGIcon />}
          onShareWindowClose={() =>
            model.handleShareDialogClose(SocialSharePlatforms.Whatsapp)
          }
          windowHeight={WINDOW_HEIGHT}
          windowWidth={WINDOW_WIDTH}
        />
        <TumblrShareButton
          className="btn btn-core medium"
          resetButtonStyle={false}
          url={model.sharedLink!}
          title={model.title}
          caption={model.message}
          children={<TumblrSquareSVGIcon />}
          onShareWindowClose={() =>
            model.handleShareDialogClose(SocialSharePlatforms.Tumblr)
          }
          windowHeight={WINDOW_HEIGHT}
          windowWidth={WINDOW_WIDTH}
        />
        <RedditShareButton
          className="btn btn-core medium"
          resetButtonStyle={false}
          url={model.sharedLink!}
          title={model.titleWithMessage}
          children={<RedditSquareSVGIcon />}
          onShareWindowClose={() =>
            model.handleShareDialogClose(SocialSharePlatforms.Reddit)
          }
          windowHeight={WINDOW_HEIGHT}
          windowWidth={WINDOW_WIDTH}
        />

        <div className="divider"></div>

        <Tooltip content="Copy Link">
          <Button
            className="copy-link-btn"
            icon={<CopyLinkSVGIcon />}
            onClick={() => model.copyLink()}
          />
        </Tooltip>

        <Tooltip content="Copy Embed Code">
          <Button
            className="copy-link-btn"
            icon={<CodeSVGIcon />}
            onClick={() => model.copyIFrameEmbedCode()}
          />
        </Tooltip>
      </div>
    </>
  );
});
