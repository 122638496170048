import './loader-v1-1.scss';

import React from 'react';
import classNames from 'classnames';

type Props = {
  className?: string;
};

export const CliprSpinner = (props: Props) => {
  const className = classNames('clipr-spinner', props.className);

  return (
    <div className={className}>
      <svg
        className="spinner-logo"
        viewBox="0 0 85 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
          <path
            className="glyph"
            d="M1.83898 5.4C2.90678 3.72 4.38983 2.4 6.16949 1.44C7.94915 0.48 9.9661 0 12.1017 0C13.6441 0 15.1271 0.24 16.5508 0.78C17.9746 1.32 19.161 2.1 20.2881 3.12L17.678 6.84C16.8474 6.18 15.9576 5.64 15.0085 5.28C14.0593 4.92 13.0508 4.68 12.1017 4.68C10.9152 4.68 9.78813 4.98 8.77966 5.52C7.77118 6.06 7 6.84 6.34745 7.8C5.75423 8.76 5.33898 9.84 5.22034 11.1H0C0.177966 8.94 0.771186 7.08 1.83898 5.4ZM6.16949 22.62C4.38983 21.66 2.9661 20.4 1.83898 18.72C0.771186 17.04 0.118644 15.12 0 13.02H5.27966C5.3983 14.28 5.81356 15.36 6.40678 16.32C7.05932 17.28 7.8305 18 8.83898 18.54C9.78813 19.08 10.9152 19.32 12.1017 19.32C13.2288 19.32 14.3559 19.08 15.3644 18.6C16.4322 18.12 17.3813 17.52 18.2119 16.74L20.7034 20.52C19.5763 21.66 18.2712 22.5 16.7881 23.1C15.3051 23.7 13.7627 24 12.1017 24C9.90677 24 7.94915 23.52 6.16949 22.62Z"
          />
          <path
            className="glyph"
            d="M23.3728 23.46V0.599976H28.5338V23.46H23.3728ZM30.3728 23.46V19.02H39.3897V23.46H30.3728ZM27.9999 23.46V19.02H39.4491V23.46H27.9999Z"
          />
          <path
            className="glyph"
            d="M42.7712 23.46V0.599976H47.9323V23.46H42.7712Z"
          />
          <path
            className="glyph"
            d="M57.3053 12.3H59.5595C61.1019 12.3 62.229 11.94 63.0002 11.16C63.7714 10.44 64.1273 9.53998 64.1273 8.45998C64.1273 7.43998 63.7714 6.53998 63.0002 5.75998C62.229 5.03998 61.1019 4.61998 59.5595 4.61998H52.2629V0.599976H60.0934C61.9918 0.599976 63.5934 0.959976 65.0172 1.61998C66.4409 2.27998 67.5087 3.23998 68.2799 4.43998C69.0511 5.63998 69.407 6.95998 69.407 8.45998C69.407 9.95998 69.0511 11.34 68.2799 12.48C67.5087 13.68 66.4409 14.64 65.0172 15.3C63.5934 15.96 61.9324 16.32 60.0341 16.32H57.3053V12.3ZM52.2629 23.46V6.53998H57.424V23.46H52.2629Z"
          />
          <path
            className="glyph"
            d="M77.7118 14.4601H72.5508V23.4601H77.7118V14.4601Z"
          />
          <path
            className="glyph small"
            d="M77.7118 8.52003V7.02003H72.5508V12.6H77.7118C78.5423 11.52 80.8559 10.74 82.5762 10.74C83.1694 10.74 83.644 10.8 83.9999 10.86V6.66003C81.5084 6.66003 79.0762 7.02003 77.7118 8.52003Z"
          />
      </svg>

      <div className="spinner-bullets">
        <div className="bullet" />
        <div className="bullet" />
        <div className="bullet" />
        <div className="bullet" />
        <div className="bullet" />
      </div>
    </div>
  );
};
