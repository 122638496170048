const MAX_FILE_SIZE = 5000 * 1000 * 1000;

export function isVideoMimeType(type: string) {
  return type.match('video.*');
}
export function isVideoFile(file: File) {
  return isVideoMimeType(file.type);
}
export function isVideoItem(item: DataTransferItem) {
  return item.kind === 'file' && isVideoMimeType(item.type);
}


export function isAudioMimeType(type: string) {
  return type.match('audio.*');
}
export function isAudioFile(file: File) {
  return isAudioMimeType(file.type);
}
export function isAudioItem(item: DataTransferItem) {
  return item.kind === 'file' && isAudioMimeType(item.type);
}


export function isMediaFile(file: File) {
  return (
    isVideoFile(file) ||
    isAudioFile(file));
}
export function isMediaItem(item: DataTransferItem) {
  return (
    isVideoItem(item) ||
    isAudioItem(item));
}

export function isJobPosterCompatible(file: DataTransferItem) {
  return file.kind === 'file' && ['image/jpeg', 'image/png'].includes(file.type);
}

export function isLargeFile(file: File) {
  return file.size > MAX_FILE_SIZE;
}