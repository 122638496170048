import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { Overlay } from '../../overlays/overlay';
import { useOverlayState } from '../../overlays/overlayState';
import { PinOutlineSVGIcon, PinSVGIcon } from '../../svg';
import { PlayerState } from '../playerState';

type Props = {
  model: PlayerState
}

export const PinButton = observer(({
  model
}: Props) => {

  const [isHovered, setHovered] = useState(false);

  const { chrome } = model;
  const tooltipState = useOverlayState();

  const className = classNames('user-player-control-btn pin-btn', {
    hover: isHovered
  });

  return (
    <button className={className}
      ref={tooltipState.anchorRef.ref}
      aria-pressed={chrome.isPinned}
      {...tooltipState.anchorProps}
      onClick={evt => {
        chrome.togglePin();
        tooltipState.hide();
      }}
      onPointerEnter={() => {
        setHovered(true);
      }}
      onPointerLeave={() => {
        setHovered(false);
      }}>

      <div className="icon-switch">
        <span className="pin-icon icon">
          <PinOutlineSVGIcon />
        </span>
        <span className="unpin-icon icon">
          <PinSVGIcon />
        </span>
      </div>

      <Overlay model={tooltipState}
        className="user-player-tooltip">
        {chrome.isPinned ? 'Unpin Video Controls' : 'Pin Video Controls'}
      </Overlay>
    </button>
  );
});