import './media.scss';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';

import { Maybe } from '../../core';
import { CardOverlay } from './jobCard'
import { JobModel } from '../../entities';
import { Thumb } from '../media/thumb';
import { PlaySVGIcon, LiveSVGIcon } from '../svg';
import { LibraryTag } from './libraryTag';
import { AiTag } from './aiTag';

type Props = {
  model: JobModel;
  isNew: boolean;
  teamId?: string;
}

export const JobCardPreview = observer(({
  model,
  isNew,
  teamId
}: Props) => {

  let previewElem: Maybe<ReactNode>;
  const { widgetService } = model.store;
  const showSourceBadge = widgetService.isWidgetMode ?
    (widgetService.showSourceBadge ?? true) : true;
  const mediaAggregatedStatus = model.mediaAggregatedStatus;
  const liveClass = classNames('card-thumb dock', {
    'live-card-placeholder': !model.posterURL && !model.store.uiService.logo,
  });

  switch (mediaAggregatedStatus) {
    case 'IngestProcessing':
      previewElem = (
        <div className="card-status progress dock">
          <Thumb source={model} className="card-thumb dock" />
          <CardOverlay model={model} />
          {isNew && <div className="job-card-new-tag">New</div>}
          {showSourceBadge && <LibraryTag model={model} />}
        </div>
      );
      break;

    case 'Failed':
      previewElem = (
        <div className="card-status progress dock">
          <Thumb source={model} className="card-thumb dock" />
          <CardOverlay model={model} />
          {isNew && <div className="job-card-new-tag">New</div>}
          {showSourceBadge && <LibraryTag model={model} />}
        </div>
      );
      break;

    case 'LiveStreaming':
      previewElem = (
        <div className="card-status progress dock status-done">
          <Thumb source={model} className={liveClass} />
          <div className="play-icon">
            <PlaySVGIcon />
          </div>
          {showSourceBadge && <LibraryTag model={model} />}
          <div className="job-card-live-tag">
            <LiveSVGIcon />
            <span>LIVE NOW</span>
          </div>
        </div>
      )
      break;

    case 'LiveEnded':
      previewElem = (
        <div className="card-status progress dock">
          <Thumb source={model} className="card-thumb dock" />
          <CardOverlay model={model} />
          {showSourceBadge && <LibraryTag model={model} />}
        </div>
      )
      break;

    case 'LiveWaiting':
      previewElem = (
        <div className="card-status progress dock status-done">
          <Thumb source={model} className={liveClass} />
          <div className="play-icon">
            <PlaySVGIcon />
          </div>
          {showSourceBadge && <LibraryTag model={model} />}
          <div className="job-card-live-tag">
            <LiveSVGIcon />
            <span>LIVE </span>
          </div>
        </div>
      );
      break;

    default:
    case 'IngestCompleted':
      previewElem = (
        <div className="card-status progress dock status-done">
          <Thumb source={model} className="card-thumb dock" />
          <div className="play-icon">
            <PlaySVGIcon />
          </div>
          {isNew && <div className="job-card-new-tag">New</div>}
          {showSourceBadge && <LibraryTag model={model} />}
          <AiTag 
            teamId={teamId}
            model={model} />
          <div className="card-duration">
            {model.durationToTimeCode('card')}
          </div>
        </div>
      );
      break;
  }

  if (!model.isPublished || model.isTransient) {
    previewElem = (
      <div className="card-status progress dock">
        <Thumb source={model} className="card-thumb dock" />
        <CardOverlay model={model} />
        {isNew && <div className="job-card-new-tag">New</div>}
        {showSourceBadge && <LibraryTag model={model} />}
      </div>
    );
  }

  return <>{previewElem}</>;
});