import { Placement } from '@popperjs/core';

export type HorizontalPosition =
  'left' |
  'leftStart' |
  'leftEnd' |
  'right' |
  'rightStart' |
  'rightEnd';

export type VerticalPosition =
  'top' |
  'topStart' |
  'topEnd' |
  'bottom' |
  'bottomStart' |
  'bottomEnd';

export type Position =
  HorizontalPosition |
  VerticalPosition;

export const HorizontalPositions: HorizontalPosition[] = [
  'left',
  'leftStart',
  'leftEnd',
  'right',
  'rightStart',
  'rightEnd'
];

export const VerticalPositions: VerticalPosition[] = [
  'top',
  'topStart',
  'topEnd',
  'bottom',
  'bottomStart',
  'bottomEnd'
];

export const Positions = ([] as Position[])
  .concat(HorizontalPositions)
  .concat(VerticalPositions);


export const PopperPlacements: Placement[] = [
  'top',
  'bottom',
  'right',
  'left',
  'auto',
  'top-start',
  'top-end',
  'bottom-start',
  'bottom-end',
  'right-start',
  'right-end',
  'left-start',
  'left-end',
  'auto-start',
  'auto-end'
];
