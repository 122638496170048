export type SetPlaybackRateParams = {
  rate: number;
}

export type SetVolumeParams = {
  volume: number;
}

export type SetControlsParams = {
  controls: boolean;
}

export type SetAutoplayParams = {
  autoplay: boolean;
}

export type SeekParams = {
  time: number;
}

export enum PlayerAdapterMessageType {
  Mounted = 'Mounted',
  Unmounted = 'Unmounted'
}