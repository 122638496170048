import { action, computed, makeObservable, observable } from 'mobx';
import { assert, Maybe } from '../../core';
import { notifyError, notifyLoading, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';

import { input, inputGroup, InputGroupState, InputState } from '../input';
import { SpeakerInputGroupState } from '../speakers/speakerInputGroupState';
import { ApiUploadRequestParams } from '../../api';
import { fileInput, FileInputState } from '../input/fileInputState';
import { closeWindow } from '../../services/overlays';
import { SpeakerAddInput, SpeakerUpdateInput } from '@clipr/lib/dist/generated/graphql';
import { checkLinkedInUrlValidity } from '../../core/urlUtils';

export enum SpeakerWindowMode {
  Add = 'Add',
  Edit = 'Edit'
}
export class SpeakerWindowState
  extends StoreNode {

  readonly nodeType = 'SpeakerWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    // disable the other inputs when speaker is in edit mode
    const disabled = (input: InputState, fallback?: boolean): Maybe<boolean> => !this.speaker.mode ? true : fallback;

    this.speaker = new SpeakerInputGroupState(this.store, {
      selectorItems: () => {
        return this.store.speakerManager.publicSpeakers.map(speaker => {
          return {
            value: speaker.id,
            label: speaker.name
          }
        });
      },
      onSelect: this.onSelectSpeaker,
      onCancel: this.onCancelMode,
      onAddSpeaker: this.onAddSpeakerMode
    });

    this.description = input(this, {
      name: 'description',
      placeholder: 'Enter description',
      multiline: true,
      disabled
    });

    this.linkedin = input(this, {
      name: 'linkedin',
      error: (input) => {
        if (input.value)
          return this.checkLinkValidity(input.value)
        return null;
      },
      placeholder: 'Linkedin',
      disabled
    });

    this.addTrackButton = input(this, {
      name: 'addTrackButton',
      disabled: (input: any) => {
        return (
          input.loading ||
          input.isSubmitting ||
          (this.inputGroup as any).hasTouchedErrorInputs) ||
          this.speaker.mode ||
          this.isLoading ||
          !this.target ||
          Array.from(this.job?.trackSpeakerIds || []).includes(this.target.id)
      }
    });

    this.speakerAvatar = fileInput(this, {
      name: 'avatar',
      disabled: () => !this.speaker?.mode || this.inputGroup.isSubmitting,
    });

    this.saveButton = input(this, {
      name: 'saveButton',
      disabled: (input: any) => this.inputGroup.isSubmitDisabled
    });

    this.cancelButton = input(this, {
      name: 'cancelButton',
      disabled: (input: any) => {
        return (
          input.loading ||
          input.isSubmitting)
      }
    });

    this.inputGroup = inputGroup(this, {
      name: "speaker",
      inputs: () => [
        this.description,
        this.linkedin,
        this.speaker.inputGroup,
        this.saveButton,
        this.speakerAvatar,
        this.cancelButton
      ],
      isSubmitDisabled: () =>
        this.inputGroup.hasVisibleError ||
        this.inputGroup.isSubmitting ||
        this.isLoading ||
        !this.speaker.mode ||
        !this.inputGroup.isDirty
    })

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);
  readonly description: InputState;
  readonly linkedin: InputState;

  readonly addTrackButton: InputState;
  readonly saveButton: InputState;
  readonly cancelButton: InputState;
  readonly speaker: SpeakerInputGroupState;
  readonly inputGroup: InputGroupState;

  readonly speakerAvatar: FileInputState;

  @observable mode: SpeakerWindowMode | null = null;
  @observable isLoading = false;

  @observable jobId: string | null = null;
  @observable trackId: string | null = null;

  @observable showAddTrack: boolean = true;

  @computed get targetId() {
    return this.speaker.speakerModelId;
  };

  @computed
  get target() {
    const { store, targetId } = this;
    if (!targetId || !store.hasSpeaker(targetId)) {
      assert(this.mode !== SpeakerWindowMode.Edit,
        `Expected to have a valid SpeakerWindow.targetId for mode 'edit'`);
      return null;
    }

    return this.store.getSpeaker(targetId);
  }

  @computed
  get job() {
    const { store, jobId } = this;
    if (!jobId || !store.hasJob(jobId)) {
      assert(this.mode !== SpeakerWindowMode.Edit,
        `Expected to have a valid SpeakerWindow.JobId`);
      return null;
    }

    return this.store.getJob(jobId);
  }

  @computed
  get track() {
    const { trackId } = this;
    if (!trackId || !this.job?.hasTrack(trackId)) {
      assert(this.mode !== SpeakerWindowMode.Edit,
        `Expected to have a valid SpeakerWindow.TrackId`);
      return null;
    }

    return this.job.getTrack(trackId);
  }

  checkLinkValidity = (link: string) => {
    const isValidUrl = checkLinkedInUrlValidity(link);

    if (link && !isValidUrl)
      return 'Invalid LinkedIn link';
    return null;
  }

  @computed
  get isCreateMode() {
    return this.mode === SpeakerWindowMode.Add;
  }

  @computed
  get isEditMode() {
    return this.mode === SpeakerWindowMode.Edit;
  }

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
        if (this.isLoading || this.inputGroup.isSubmitting)
          return;
        this.cancel();
        break;
      case 'outsideClick':
        if (this.isLoading || this.inputGroup.isSubmitting)
          return;
        this.cancel(true);
        break;
    }
  }

  @action
  onSelectSpeaker = async (id: string | null) => {
    this.isLoading = true;
    await this.fetchSpeaker(id);
    this.description.loadValue(this.target?.description || null);
    this.linkedin.loadValue(this.target?.linkedin || null);
    this.speakerAvatar.loadPreviewUrl(this.target?.pictureURL || null);
  }

  @action
  async fetchSpeaker(id: string | null) {

    if (id) {
      const [, err] = await this.store.apiFetchSpeaker({ id });
      if (err) {
        notifyError(this, `Could not fetch speaker.`);
      }
    }
    this.isLoading = false;
  }

  @action
  onCancelMode = () => {
    this.description.value = this.target?.description || null;
    this.linkedin.value = this.target?.linkedin || null;
    this.speakerAvatar.loadPreviewUrl(this.target?.pictureURL || null);
  }

  @action
  onAddSpeakerMode = () => {
    this.description.clear();
    this.linkedin.clear();
    this.speakerAvatar.clear();
  }

  @action
  async submitAddSpeaker(): Promise<boolean> {
    const { editor } = this.speaker;
    const speakerName = editor.value;
    notifyLoading(this, 'Adding new speaker');

    const addArgs: SpeakerAddInput = {
      name: speakerName!,
      description: this.description.value ?? '',
      linkedin: this.linkedin.value ?? '',
      jobId: this.jobId ?? undefined
    }

    const [res, err] = await this.store.speakerManager.apiAddSpeaker({
      args: addArgs
    });

    if (err) {
      this.handleSubmitReject(`Failed to add speaker ${speakerName}.`);
      return false;
    }

    this.emit('speakerCreated', { speakerId: res?.id });
    this.speaker.cancelMode();
    if (res)
      this.syncFields(res.id);

    if (this.speakerAvatar.isDirty && this.target) {
      const pictureData = await this.getPictureUploadToken();
      const token = pictureData?.pictureToken;
      // Update the profile picture if the picture upload was successful
      if (token) {
        const updateArgs: SpeakerUpdateInput = {
          id: this.target.id,
          pictureToken: pictureData?.pictureToken
        }
        const [, updateError] = await this.store.speakerManager.apiUpdateSpeaker({
          args: updateArgs
        });
        if (updateError) {
          this.handleSubmitReject('Failed to update the speaker picture.');
          return false;
        }
      } else {
        this.handleSubmitReject('Failed to update the speaker picture.');
        return false;
      }
    }

    return true;
  }

  @action
  async submitEditSpeaker(): Promise<boolean> {
    const { editor, editSpeaker } = this.speaker;
    const speakerName = editor.value;
    assert(!!editSpeaker,
      `Cannot submit SpeakerInputModel in edit mode if no SpeakerModel has been loaded.`);
    const oldName = editSpeaker?.name;

    notifyLoading(this, 'Updating speaker');
    const pictureData = await this.getPictureUploadToken();
    if (!pictureData && this.speakerAvatar.file) {
      this.handleSubmitReject(`Failed to update the speaker ${oldName}.`);
      return false;
    }

    try {
      if (!editSpeaker)
        throw new Error('Error: No valid edit entity!');

      const updateArgs: SpeakerUpdateInput = {
        id: editSpeaker?.id,
        name: speakerName!,
        description: this.description.value ?? '',
        linkedin: this.linkedin.value ?? '',
        pictureToken: pictureData?.pictureToken ?? undefined
      }

      const [res, err] = await this.store.speakerManager.apiUpdateSpeaker({
        args: updateArgs
      });

      if (!err)
        this.emit('speakerUpdated', { speakerId: res?.id });

    } catch (e) {
      this.handleSubmitReject(`Failed to update the speaker ${oldName}.`);
      return false;
    }

    return true;
  }

  @action
  async submit() {
    const mode = this.speaker.mode;
    const { editor } = this.speaker;
    const speakerName = editor.value;

    this.isLoading = true;
    this.inputGroup.handleSubmit();
    this.window.lock();

    if (this.inputGroup.error) {
      this.inputGroup.handleSubmitReject();
      notifyError(this, 'Fix the validation errors before saving.');
      this.window.unlock();
      this.isLoading = false;
      return;
    }

    switch (mode) {

      case 'add':
        const addSuccess = await this.submitAddSpeaker();
        if (!addSuccess)
          return;
        break;

      case 'edit':
        const editSuccess = await this.submitEditSpeaker();
        if (!editSuccess)
          return;
        break;

      default:
        break;
    }

    if (mode === 'add')
      this.handleSubmitSuccess(`Added speaker ${speakerName}.`);
    else
      this.handleSubmitSuccess(`Speaker ${speakerName} updated successfully.`);
  }

  async getPictureUploadToken() {
    const file = this.speakerAvatar.file;

    if (!file || !this.target)
      return null;

    // Get the upload URL
    const [res, err] = await this.store.apiService.getSpeakerPictureUploadUrl({
      id: this.target?.id,
      fileName: file.name
    });

    if (err)
      return null;

    if (res) {
      const params = res.getSpeakerPictureUploadUrl;
      const uploadParams: ApiUploadRequestParams = {
        file,
        url: params?.uploadUrl,
        data: params?.fields
      }

      // Start the upload request
      const uploadRequest = this.store.apiService.uploadRequest(uploadParams);
      const [, uploadErr] = await uploadRequest.start();

      if (uploadErr) {
        return null;
      }

      return {
        pictureToken: params?.uploadToken
      }
    }
  }

  @action
  async submitAddTrack() {
    if (!this.job || !this.target)
      return;

    this.isLoading = true;
    this.inputGroup.handleSubmit();
    this.window.lock();

    if (this.inputGroup.error) {
      this.inputGroup.handleSubmitReject();
      notifyError(this, 'Fix the validation errors before submitting.');
      this.window.unlock();
      this.isLoading = false;
      return;
    }

    notifyLoading(this, 'Creating new swimlane.');

    const [res, err] = await this.job?.apiAddTrack({
      args: {
        jobId: this.job?.id,
        name: this.target?.name,
        type: 'Transcript',
        speakerId: this.target?.id
      }
    });

    if (err) {
      this.handleSubmitReject('Failed to create a new swimlane.');
      return;
    }

    this.handleSubmitSuccess(`New swimlane created.`);
    this.emit('trackCreated', { trackId: res?.id });
  }

  private handleSubmitReject(msg: string) {
    this.inputGroup.handleSubmitReject();
    this.window.unlock();
    notifyError(this, msg);
    this.isLoading = false;
  }

  private handleSubmitSuccess(msg: string) {
    this.inputGroup.handleSubmitResolve();
    this.window.unlock();
    this.speaker.cancelMode();
    this.cancel();
    notifySuccess(this, msg);
    this.isLoading = false;
  }

  @action
  cancel(withConfirmation?: boolean) {
    if (this.inputGroup.isSubmitting || !this.window.isVisible)
      return;

    if (this.inputGroup.isDirty && withConfirmation)
      this.store.closeWindowConfirmationModal.open({
        onSubmit: () => {
          this.reset();
          this.close('close');
        }
      })
    else
      this.close('close');
  }

  @action
  private open() {
    this.dispatch('Overlays', 'openWindow', { name: 'SpeakerWindow' });
    this.window.open();
    this.emit('open');
  }

  @action
  async openCreate(jobId: string, showAddTrack: boolean = true) {
    this.mode = SpeakerWindowMode.Add;
    this.jobId = jobId;
    this.showAddTrack = showAddTrack;

    this.open();
  }

  @action
  syncFields(speakerId: string) {
    const speaker = this.store.maybeGetSpeaker(speakerId);
    this.speaker.selector.loadValue(speaker ? {
      value: speaker.id,
      label: speaker.name
    } : null)
    this.description.loadValue(speaker?.description ?? null);
    this.linkedin.loadValue(speaker?.linkedin ?? null);
    this.speakerAvatar.loadPreviewUrl(speaker?.pictureURL ?? null);
  }

  @action
  async openEdit(targetId: string, jobId: string, trackId: string, showAddTrack: boolean = true) {

    this.mode = SpeakerWindowMode.Edit;
    this.trackId = trackId;
    this.jobId = jobId;
    this.showAddTrack = showAddTrack;

    await this.fetchSpeaker(targetId);
    this.syncFields(targetId);

    this.isLoading = true;
    this.open();
  }

  @action
  close(msg?: string) {
    closeWindow(this);
    this.window.close();
    if (msg)
      this.emit(msg);
  }

  @action
  private reset() {
    this.mode = null;
    this.jobId = null;
    this.speaker.cancelMode();
    this.inputGroup.clear();
    this.isLoading = false;
    this.showAddTrack = true;
  }

  @action
  onTransitionEnd = () => {
    if (!this.window.isVisible)
      this.reset();
  }
}