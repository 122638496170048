import './errorPages.scss';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { RouteProps } from 'react-router-dom';
import { ErrorPage } from './errorPage';

type Props = RouteProps;

export const NotAuthorizedErrorPage = observer((props: Props) => {
  return <ErrorPage
    message="You don't have permissions to view this page." />
});