import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { TrackModel } from '../../../entities';
import { TrainerTimelineState } from '../trainerTimelineState';
import { TimelineTrackLane } from './timelineTrackLane';
import { Maybe, ObjectLiteral } from '../../../core';

type TrackProps = {
  track: TrackModel,
  model: TrainerTimelineState,
  className?: Maybe<string | ObjectLiteral>
}

export const TimelineTrack = observer(({
  track,
  model,
  className
}: TrackProps) => {

  const { lanes } = track;
  className = classNames('timeline-track track-row', className, {
    'empty': lanes.length === 0,
    'single-lane': lanes.length === 1,
    'multi-lane': lanes.length > 1,
    'user-visible': track.visibleToConsumer,
    'user-hidden': !track.visibleToConsumer
  });

  return (
    <div className={className}
      aria-selected={track?.id === model.selectedTrackId}
      data-lanes={lanes.length}
      style={{
        // @ts-ignore
        '--track-lanes': lanes.length
      }}>

      {track.lanes.map(lane =>
        <TimelineTrackLane
          key={lane.laneIndex}
          model={model}
          lane={lane} />)}
    </div>
  );
});