import React from 'react';
import { observer } from 'mobx-react-lite';
import { LinkVarSVGIcon, SquareCloseSVGIcon } from '../../components/svg';
import {
  Button,
  TextInputControlled,
  DropdownInputControlled,
} from '../../components/input';
import { handler } from '../../core';
import { LoadMask } from '../../components/loader/loadMask';
import { UploadLinkState } from './uploadLinkState';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

type Props = {
  state: UploadLinkState;
  submitDisabled?: boolean;
};

export const UploadLinkStageBox = observer((props: Props) => {
  const { state } = props;
  const { stagedItems } = state;
  const showLoadMask = state.isLoading;

  return <div className="upload-link-stage-box upload-stage-box"
    aria-hidden={stagedItems.length === 0}>
    <header className="upload-stage-box-header">
      <div className="box-title">
        <LinkVarSVGIcon />
      PROVIDED URL’S
      </div>
    </header>
    <TransitionGroup component="ul"
      className="upload-link-staging-list upload-staging-list scrollable">
      {stagedItems.map((item) => {
        return (
          <CSSTransition
            classNames="item-show"
            timeout={300}
            key={item.id}>
            <li key={item.id} className="upload-link-staging-item">
              <div className="header-row">
                <div className="title">{item.linkInput.value}</div>

                <button
                  className="line-suffix close-icon-btn btn-core"
                  type={'button'}
                  onClick={handler(state, 'removeLinkItem', [item.id])}>
                  <SquareCloseSVGIcon />
                </button>

              </div>
              <div className="box-row">
                <DropdownInputControlled
                  className={"language-input rmar4"}
                  label="Video Language"
                  model={item.languageInput}
                  notDeselectable={true} />

                <DropdownInputControlled
                  className={"video-type-input rmar4"}
                  label="Video Type"
                  model={item.videoTypeInput}
                  notDeselectable={true} />

                {item.hasPasswordInput.value && (
                  <TextInputControlled
                    className={"password-input rmar4"}
                    model={item.passwordInput}
                  />
                )}

                <Button
                  className="primary btn-outline medium"
                  label="Modify Output"
                  onClick={() =>
                    state.dispatch('openUploadJobDetailsWindow', { task: item })
                  } />
              </div>
            </li>
          </CSSTransition>
        )
      })}
    </TransitionGroup>

    <div className="upload-stage-box-footer flex right">
      <Button
        label="Submit URLs"
        className="primary medium submit-btn"
        onClick={handler(state, 'submit')}
        disabled={state.stagingForm.isSubmitDisabled || props.submitDisabled}
      />
    </div>
    <LoadMask
      visible={showLoadMask}
      className="small inherit"
      spinner="dots5"
      spinnerClassName="medium" />
  </div>
})