import React from 'react';
import { observer } from 'mobx-react-lite';
import { PlayerState } from '../playerState';
import { PlayerPoster } from '..';

type Props = {
  model: PlayerState
}

// TODO: rename
export const UserPlayerPoster = observer(({
  model
}: Props) => {
  return (
    <div id="user-player-poster">
      <PlayerPoster
        model={model} />
    </div>
  );
});