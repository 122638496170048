import { Action } from 'history';
import React, { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AsyncResult } from '../core';
import { PageName } from '../pages/pageSchema';
import { AuthFlowName, Permissions } from '../services/auth/authFlowSchema';
import { WidgetName } from '../widgets/widgetSchema';
import { RouteContext } from './routeContext';

export type RouteVisit = {
  action: Action;
  context: RouteContext;
  index: number;
  historyIndex: number;
}

export type RouteComponent = ReturnType<typeof React.lazy>;

export interface IRouteInjector {
  disabledAuthFlows?: Set<AuthFlowName>;
  fetchPermissions?(routeContext: RouteContext): AsyncResult<Permissions>
}

export type RouteInjectorCallback = () => IRouteInjector;

export interface IRouteState {
  routeType: RouteType;
  attached(context: RouteContext): void | Promise<void>;
  detached(): void;
}

export enum RouteType {
  Direct = 'Direct',
  Auth = 'Auth',
  Private = 'Private',
  Public = 'Public',
  Widget = 'Widget',
  Onboard = 'Onboard'
}

export enum RouteComponentType {
  Page = 'Page',
  Widget = 'Widget'
}

export type RouteState = any;

export interface IRouteStorage {
  authFlow: AuthFlowName | null;
  lastPrivatePath: string | null;
}

export type RouteDescriptor = {
  path: string;
  exact?: boolean;
  element: ReactElement | ((props: RouteComponentProps) => ReactElement);
  injector?: IRouteInjector | (() => IRouteInjector);
  routeType: RouteType;
  pageName?: PageName;
  widgetName?: WidgetName;
}

export type RouteLookup = Record<string, RouteDescriptor>;