export const createInitScript = (id: string): Node => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.text = `
    _linkedin_partner_id = ${id};
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
  `
  return script;
}

export const createAsyncScript = (s: Node): Node => {
  const w = window as any;
  const script = document.createElement('script');
  script.type = 'text/javascript';

  script.onload = (function (l) {
    if (!l) {
      w.lintrk = function (a: any, b: any) { w.lintrk.q.push([a, b]) };
      w.lintrk.q = []
    }
    const b = document.createElement("script");
    b.type = "text/javascript";
    b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode?.insertBefore(b, s);

    return null;
  })(w.lintrk);

  return script;
}

export const createNoScriptPixel = (id: string): Node => {
  const noscript = document.createElement('noscript');
  const img = document.createElement('img');
  img.height = 1;
  img.width = 1;
  img.style.display = 'none';
  img.alt = '';
  img.src = `https://px.ads.linkedin.com/collect/?pid=${id}&fmt=gif`;
  noscript.appendChild(img);

  return noscript;
}