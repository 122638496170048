import React from 'react';
import './playerTutorial.scss';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { PlayerTutorialHelpButtonTooltipState } from './playerTutorialHelpButtonState';
import { useState } from 'react';
import { usePopper } from 'react-popper';
import { useEffect } from 'react';
import { Placement } from './playerTutorial.consts';

type Props = {
  model: PlayerTutorialHelpButtonTooltipState,
  visible?: boolean;
};
export const PlayerTutorialHelpButtonTooltip = observer((props: Props) => {
  const { model } = props;

  const [popperElement, setPopperElement] = useState(null);
  const anchorElement = model.anchorElement;

  const { styles, attributes, forceUpdate } = usePopper(anchorElement, popperElement, {
    placement: Placement.Left,
    strategy: 'absolute',
  });

  useEffect(() => {
    if (forceUpdate) forceUpdate();
  }, [anchorElement, forceUpdate])

  return (
    <Window
      innerRef={setPopperElement as any}
      style={styles.popper} {...attributes.popper}
      className="help-button-tooltip"
      model={model.window}
      visible={props.visible}>
      <WindowHeader
        model={model.window}
        title="OK! Help is always right here." />
    </Window>
  )
});