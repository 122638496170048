import { isIterable } from './iterable';

export function getSearchTokens(input: string | number | Iterable<string> | Iterable<number>): Set<string> {

  let inputStr = (typeof input !== 'string' && isIterable(input)) ?
    [...input].join(' ') :
    input.toString();

  return new Set(inputStr
    ?.toLowerCase()
    ?.match(/\w+/g));
}

export function searchMatches(
  target: Set<string> | string,
  query: Set<string> | string) {

  if (typeof target === 'string')
    target = getSearchTokens(target);

  if (typeof query === 'string')
    query = getSearchTokens(query);

  for (const queryToken of query) {
    let found = false;
    for (const targetToken of target) {
      if (targetToken.includes(queryToken)) {
        found = true;
        break;
      }
    }
    if (!found)
      return false;
  }

  return true;
}

export function getSearchTokensExtended(input: string | number | Iterable<string> | Iterable<number>): Set<string> {

  let inputStr = (typeof input !== 'string' && isIterable(input)) ?
    [...input].join(' ') :
    input.toString();

  return new Set(inputStr
    ?.toLowerCase()
    ?.match(/[^\s]+/g));
}

export function findAllMatches(
  target: Set<string> | string,
  query: Set<string> | string): Array<[number, number]> {

  const origTarget: string | null = ((typeof target === 'string') && target.toLowerCase()) || null;

  if (typeof target === 'string')
    target = getSearchTokensExtended(target);

  if (typeof query === 'string')
    query = getSearchTokensExtended(query);

  let indexArray: Array<[number, number]> = [];

  for (const queryToken of query) {
    for (const targetToken of target) {
      if (targetToken.includes(queryToken)) {
        if (origTarget) {
          let startIndex: number = origTarget.indexOf(targetToken);
          indexArray.push([startIndex, startIndex + targetToken.length]);
        }
      }
    }
  }

  //post-processing
  //ISSUE: multiple matches in a single word
  //ISSUE: only the first encounter is returned
  indexArray = Array.from(
    indexArray.reduce(
      (accMap, currPair) =>
        accMap.set(
          currPair[0], // first index in interval is the map key
          currPair[1]
          // Math.max( // the biggest value from already existing value in map or the current value
          //   (accMap.get(currPair[0]) || currPair[1]),
          //   currPair[1]
          // )
        ),
      new Map()
    )).sort((a, b) => a[0] - b[0]);

  //PROV: sanitize to avoid duplicate matches, must be refactored to avoid losing data
  indexArray = indexArray.filter((curr, i, arr) => {
    const prev = arr[i - 1];
    if (!prev)
      return true;
    return !(curr[0] <= prev[1] && curr[1] >= prev[0]);
  })

  return indexArray;
}

export function findAllAppearances(
  target: string,
  query: string
): Array<[number, number]> {

  target = target.toLowerCase();
  query = query.toLowerCase();
  const indexArray: Array<[number, number]> = [];
  let currentSearchIndex = 0, found;

  do {
    const startIndex: number = target.slice(currentSearchIndex).indexOf(query);

    if (startIndex === -1)
      found = false;
    else {
      const endIndex = startIndex + query.length;
      indexArray.push([startIndex, endIndex]);
      currentSearchIndex = endIndex;
      found = true;
    }

  } while (found);

  return indexArray;
} 
