import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../../components/overlays';
import { Button } from '../../components/input';
import { ConfirmationModalState } from './confirmationModalState';
import classNames from 'classnames';

type Props = {
  model: ConfirmationModalState;
  visible?: boolean;
};

export const ConfirmationModal = observer(
  ({ model, visible }: Props) => {
    const className = classNames('confirmation-modal', model.layout);

    const closeBtnElem = <Button
      label={model.closeLabel || 'Close'}
      className={classNames("medium", {
        green: model.layout === 'danger',
        'btn-outline': model.layout === 'default',
        primary: model.layout === 'default' || model.layout === 'info'
      })}
      onClick={() => model.cancel()}
      disabled={model.isLoading}
    />

    const submitBtnElem = <Button
      label={model.confirmLabel || 'Submit'}
      className={classNames("medium", {
        red: model.layout === 'danger',
        green: model.layout === 'default'
      })}
      onClick={() => model.submit()}
      disabled={model.isLoading}
    />

    return (
      <Window
        onEntered={() => model.onWindowEntered()}
        onExited={() => model.onWindowExited()}
        model={model.window}
        isLoading={model.isLoading}
        visible={visible}
        className={className}>

        <WindowHeader
          title={model.title || 'Action Confirmation'}
          model={model.window}
        />

        <hr />

        {(model.modalMessage || model.modalSecondaryMessage) &&
          (<>
            <section className="window-content">
              {/* <br /> */}
              {model.modalMessage && <p className="p-bold">
                {model.modalMessage}
              </p>}
              {model.modalSecondaryMessage && <p>
                {model.modalSecondaryMessage}
              </p>}
              {/* <br /> */}
            </section>

            <hr />
          </>)}

        <footer className="window-footer">
          <div className="col left"></div>
          <div className="col right">
            <div className="btn-group">
              {model.layout === 'danger' &&
                <>
                  {submitBtnElem}
                  {closeBtnElem}
                </>}
              {model.layout === 'default' &&
                <>
                  {closeBtnElem}
                  {submitBtnElem}
                </>
              }
              {model.layout === 'info' &&
                <>
                  {closeBtnElem}
                </>
              }
            </div>
          </div>
        </footer>
      </Window>
    );
  }
);
