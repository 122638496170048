import { action, computed, observable } from 'mobx';
import { Constructor } from '../core';
import { Error } from '../core/error';
import { RouteContext } from '../routes/routeContext';
import { WidgetService } from '../services/widget';
import { RequestedWidgetParams, WidgetParams, WidgetRouteParams } from '../services/widget/widgetParams';
import { StoreNode } from '../store';

export function WidgetState<TBase extends Constructor<StoreNode>>(Base: TBase) {

  class WidgetState extends Base {

    @observable isAttached = false;
    @observable isLoading = false;
    @observable error: Error | null = null;

    @observable routeContext: RouteContext | null = null;

    @computed get widgetService(): WidgetService {
      return this.store.widgetService;
    }

    @computed get widgetParams(): WidgetParams {
      return this.widgetService.widgetParams;
    }

    @computed get requestedWidgetParams(): RequestedWidgetParams {
      return this.widgetService.requestedWidgetParams;
    }

    @computed get widgetRouteParams(): WidgetRouteParams | null {
      return this.widgetService.widgetRouteParams;
    }

    @computed get isLoaded() {
      return this.isAttached && !this.isLoading;
    }

    @action
    protected baseAttached(routeContext: RouteContext): void {
      this.baseReset();
      this.isAttached = true;
      this.routeContext = routeContext;
    }

    @action
    protected baseDetached(): void {
      this.baseReset();
    }

    protected baseReset(): void {
      this.isAttached = false;
      this.isLoading = false;
      this.error = null;
      this.routeContext = null;
    }

    @action
    protected setLoading() {
      this.error = null;
      this.isLoading = true;
    }

    @action
    protected setLoaded() {
      this.error = null;
      this.isLoading = false;
    }

    @action
    protected setLoadAborted() {
      // trying to keep as few sources of truth as possible
      // a.k.a. not adding a separate 'LoadAborted' state, since for the UI this would be a little bit of a dilemma
      // we consider a load abort to come either as a result of unmounting the page or triggering another load
      // if unmounting, setting the 'isLoading' flag to true will just show a spinner which would probably be continued
      // by the next widget's spinner
      // if triggering another load, then setting the 'isLoading' flag to true will just do what the follow up load call did
      // and that should be reverted when the follow up load is completed
      this.isLoading = true;
      this.error = null;
    }

    @action
    protected setError(error?: Error | string) {
      if (typeof error === 'string')
        error = new Error('WidgetError', error);

      if (!error)
        error = new Error('Unknown', `An unknown error has occurred.`);

      this.error = error;
      this.isLoading = false;

      return [null, error];
    }

    protected get __baseTraceState() {
      return {
        isAttached: this.isAttached,
        isLoading: this.isLoading,
        error: this.error,
        routeContext: this.routeContext,
        
        widgetRouteParams: this.widgetRouteParams,
        widgetParams: this.widgetParams,
        requestedWidgetParams: this.requestedWidgetParams,
        
        teamId: (this as any)['teamId'],
        contextTeamId: this.routeContext?.params?.teamId,
        widgetTeamId: this.widgetRouteParams?.teamId,

        jobId: (this as any)['jobId'],
        contextJobId: this.routeContext?.params?.jobId,
        widgetJobId: this.widgetRouteParams?.jobId
      }
    }
  }

  return WidgetState;
}