import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import React, { Fragment, MouseEvent, ReactNode } from 'react';
import isEmail from 'validator/lib/isEmail';
import { input, inputGroup, submitButtonDisabled } from '../../../components/input';
import Routes from '../../../routes';
import { AuthError, AuthWaitForRedirectReply, RegisterInput, SocialLoginProvider } from '../../../services/auth';
import { AuthFlowResponse } from '../../../services/auth/authFlowSchema';
// import { AUTH_MIN_PASSWORD_LENGTH } from '../../../services/auth/authConfig';
import { assertValidRegisterInput, /* isValidPassword */ } from '../../../services/auth/authUtils';
import { Message, StoreNode } from '../../../store';
import { Store } from '../../../store/store';
import { ConfirmationModalOpenParams, ConfirmationModalState } from '../../trainerVideoPage/confirmationModalState';
import { getRegisterErrorMessage, RegisterEmailSubmitErrorCodes, RegisterPasswordSubmitErrorCodes } from './registerErrors';

export class RegisterFormState
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }


  @observable error: AuthError | null = null;
  @observable reply: AuthWaitForRedirectReply | null = null;
  @observable response: AuthFlowResponse | null = null;

  @observable agreedTos: boolean = false;

  @computed get isWaitingForRedirect() {
    return this.reply?.type === 'WaitForRedirect';
  }
  @computed get isSubmitting() {
    return this.formGroup.isSubmitting;
  }
  @computed get showLoadMask() {
    return this.isSubmitting || this.isWaitingForRedirect;
  }


  readonly formGroup = inputGroup(this, {
    name: 'onboardForm',
    inputs: () => [
      this.firstName,
      this.lastName,
      this.email,
      this.password,
      this.submitButton
    ],
    error: (form) => {
      const err = this.error;
      const errType = err?.type;
      const errData = err?.data;
      if (errData)
        return errData;

      const errMsg = getRegisterErrorMessage(err);
      if (errMsg)
        return errMsg;

      if (
        RegisterEmailSubmitErrorCodes.includes(errType!) ||
        RegisterPasswordSubmitErrorCodes.includes(errType!)) {
        return null; // pass-through
      }

      if (form.hasErrorInputs)
        return true;
    },

    showStatus: (form) => !!form.error,
    showStatusMessage: (input) => input.showStatus
  });

  // #region First Name and Last Name fields
  readonly firstName = input(this, {
    name: 'firstName',
    isRequired: true,
    error: (input, fallback) => {
      if (!input.value?.trim())
        return 'First Name is required';
      return fallback;
    },
    showStatus: (input) => input.isSubmitRejected,
    showStatusMessage: (input) =>
      input.showStatus && !this.passwordSubmitError,
    onChange: () => this.clearError()
  });

  readonly lastName = input(this, {
    name: 'lastName',
    showStatus: (input) => input.isSubmitRejected,
    showStatusMessage: (input) =>
      input.showStatus && !this.passwordSubmitError,
  });
  // #endregion

  // #region Email field
  readonly email = input(this, {
    name: 'email',
    isRequired: true,
    error: (input, fallback) => {
      if (!input.value)
        return 'Email is required';
      if (!isEmail(input.value?.trim() || ''))
        return 'Valid email required';
      if (this.emailSubmitError)
        return this.emailSubmitError;
      return fallback;
    },
    showStatus: (input) =>
      input.isSubmitRejected || !!this.emailSubmitError,
    showStatusMessage: (input) =>
      input.showStatus && !this.emailSubmitError,
    onChange: () => this.clearError()
  });

  /** Gets the backend error targeted at the `email` field, if any. */
  @computed get emailSubmitError(): string | null {
    if (!this.email.isChangedSinceLastSubmit &&
      RegisterEmailSubmitErrorCodes.includes(this.error?.type!)) {
      return this.error?.message || null;
    }
    return null;
  }
  // #endregion

  // #region Password field
  readonly password = input(this, {
    name: 'password',
    isRequired: true,
    showContentVisibilityButton: true,
    error: (input, fallback) => {
      if (!input.value?.trim())
        return 'Password is required';
      // if (!isValidPassword(input.value))
      //   return `Password must be at least ${AUTH_MIN_PASSWORD_LENGTH} characters`;
      if (this.passwordSubmitError)
        return this.passwordSubmitError;
      return fallback;
    },
    showStatus: (input) =>
      input.isSubmitRejected || !!this.passwordSubmitError,
    showStatusMessage: (input) =>
      input.showStatus && !this.passwordSubmitError,
    onChange: () => this.clearError()
  });

  /** Gets the backend error targeted at the `password` field, if any. */
  @computed get passwordSubmitError(): string | null {
    if (!this.email.isChangedSinceLastSubmit &&
      RegisterPasswordSubmitErrorCodes.includes(this.error?.type!)) {
      return this.error?.message || null;
    }
    return null;
  }
  // #endregion

  readonly submitButton = input(this, {
    name: 'submit',
    disabled: submitButtonDisabled(this.formGroup)
  });

  @action
  handleSubmit = async (evt?: React.FormEvent) => {
    evt?.preventDefault();

    this.error = null;
    this.formGroup.handleSubmit();
    if (this.formGroup.error) {
      this.formGroup.handleSubmitReject();
      return false;
    }

    const firstName = this.firstName.value?.trim()!;
    const lastName = this.lastName.value?.trim() || undefined;
    const fullName = [firstName, lastName].join(' ').trim();

    const input: RegisterInput = {
      firstName,
      lastName,
      fullName,
      email: this.email.value?.trim()!,
      password: this.password.value!,
    }

    assertValidRegisterInput(input);

    if (!this.agreedTos) {
      this.formGroup.handleSubmitReject();
      return this.handleTosModal();
    }

    const { auth } = this.store;
    const [flowRes, flowErr] = await auth.runRegisterFlow(input);

    if (flowErr) {
      runInAction(() =>
        this.error = flowErr);

      this.formGroup.handleSubmitReject();
      return false;
    }

    this.response = flowRes;
    this.formGroup.handleSubmitResolve();
    return true;
  }

  @action
  private handleTosModal() {
    const confirmationModal = this.store.confirmationModal;
    const confirmationModalListener = (msg: Message<ConfirmationModalState>) => {
      switch (msg.type) {
        case 'close': {
          if (this.agreedTos) {
            this.handleSubmit();
          }
          confirmationModal.unlisten(confirmationModalListener);
          break;
        }
      }
    }
    confirmationModal.listen(confirmationModalListener);

    this.dispatch('openConfirmationModal', {
      title: 'Terms of Use',
      message: this.createTosConfirmMessage(),
      confirmLabel: 'I agree',
      isLoading: false,
      onSubmit: () => { this.agreedTos = true; }
    } as ConfirmationModalOpenParams);
  }

  private createTosConfirmMessage(): ReactNode {
    const link = React.createElement('a', { href: Routes.legalTerms(), target: '_blank', rel: "noopener noreferrer" }, `Terms of Use`);
    return React.createElement(Fragment, {}, 'By using CLIPr, you agree to our ', link, '.');
  }

  @action
  handleSocialLoginButtonClick = (evt: MouseEvent, provider: SocialLoginProvider) => {
    this.store.auth.runSocialLoginFlow(provider);
  }

  // #region Reset
  @action
  clearError() {
    this.error = null;
  }

  @action
  reset() {
    this.formGroup.reset();
    this.error = null;
    this.reply = null;
  }
  // #endregion
}