import { action, makeObservable } from 'mobx';
import { Store } from '../store/store';
import { StoreNode } from '../store';
import { RouteContext } from './routeContext';
import { RouteType } from './routeSchema';
import { RouteState } from './routeStateMixin';

import { INIT_DEBUGGER, TRACE } from '../core/debug/debugMacros';

export class PublicRouteState
  extends RouteState(StoreNode) {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    INIT_DEBUGGER(this, { color: 'teal' });
    TRACE(this, `constructor()`);
  }

  readonly nodeType = 'PublicRoute';
  readonly routeType = RouteType.Public;

  @action
  attached(routeContext: RouteContext) {
    TRACE(this, `attached()`, { routeContext }, this.__traceState);

    this.reset();
    this.baseAttached(routeContext);

    const { routingService } = this.store;
    routingService.routeAttached(routeContext);
  }

  @action
  detached() {
    TRACE(this, `detached()`, this.__traceState);

    this.baseDetached();
    this.reset();

    const { routingService } = this.store;
    routingService.routeDetached();
  }

  @action
  reset() {
    this.baseReset();
  }
  
  private get __traceState() {
    return {
      ...this.__baseTraceState
    }
  }
}