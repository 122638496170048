import React from 'react';
import { observer } from 'mobx-react-lite';
import { AsyncResult, HTMLDivProps, ObjectLiteral } from '../../core';
import { StoreNode } from '../../store';
import { CatalogState } from './catalogState';
import { LoadMask } from '../loader';
import classNames from 'classnames';

export interface ICatalogSource<T extends StoreNode = StoreNode> {
  fetch(): AsyncResult;
  fetchMore(): AsyncResult;
  entities: T[];
}

type Props = HTMLDivProps<{
  model: CatalogState;
  className?: string | ObjectLiteral;
  hasExternalLoading?: boolean;
}>;

export const Catalog = observer(
  ({ model, className, children, hasExternalLoading, ...props }: Props) => {
    className = classNames('catalog', className);

    return (
      <div className={className} {...props}>
        <div
          className="catalog-viewport"
          onScroll={model.handleScroll}
          ref={model.domContainer.ref}>
          {children}
        </div>

        {!hasExternalLoading && (
          <LoadMask
            className="partial"
            visible={model.isLoading} />
        )}
      </div>
    );
  }
);
