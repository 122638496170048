import React from 'react';
import { EmailSVGIcon } from '../../components/svg';

export const UploadEmailBox = () => {
  return (
    <a href="mailto:recap@clipr.ai">
      <div className="upload-email-box upload-box">
        <div className="upload-icon">
          <EmailSVGIcon />
        </div>

        <div className="text-row">
          Email a video URL to<br />
          <span className="link">recap@clipr.ai</span>
        </div>
      </div>
    </a>
  );
};

export const UploadEmailText = () => {
  return (
    <div className="upload-email-text">
      You can also send us an email at
      <a className="link" href="mailto:recap@clipr.ai"> recap@clipr.ai </a>
      with the direct link to your video.
    </div>
  );
};
