import React from 'react';
import { ChevronLeft1SVGIcon } from '../../components/svg';
import classNames from 'classnames';
import { Button, ButtonProps } from '../input';
import { useRoutingService } from '../../services/routing/routingHooks';

type Props = ButtonProps;

/**
 * Header to be used for splash pages (those which contain a single specific functionality, like upload or video topic selector)
 */
export const PageBackButton = ({
  className,
  ...props
}: Props) => {
  className = classNames('page-back-btn', className);

  const routingService = useRoutingService();
  const backButtonRoute = props.route ?? routingService.getBackRoute();

  if (backButtonRoute)
    return (
      <Button
        route={backButtonRoute}
        className={className}
        leftIcon={props.leftIcon ? props.leftIcon : <ChevronLeft1SVGIcon />}
        {...props} />
    );

  return null;
}