import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { useStore } from '../../store/storeHooks';
import { BasicPage, SplashPageHeader } from '../../components/layout';
import './createTeamPage.scss';

import { CreateTeamForm } from '../../components/createTeamForm';
import { ButtonControlled } from '../../components';

type Props = {};

export const CreateTeamPage = observer((props: Props) => {
  const store = useStore();
  const state = store.createTeamPage;

  const showLoadMask = !state.isLoaded || state.createTeamForm.isLoading;

  useEffect(() => {
    state.pageMounted();
    return () => state.pageUnmounted();
  }, [state]);

  return (
    <BasicPage
      className="create-team-page"
      showHeader={false}
      showLoadMask={showLoadMask}>
      <SplashPageHeader
        text="Create a Library"
        leftArrow={true}>
        <Helmet>
          <title>CLIPr - Create Library</title>
        </Helmet>

        <div className="header-buttons">
          {!state.createTeamForm.isLocked ? (
            <ButtonControlled
              className="primary large"
              label="Save"
              onClick={() => state.createTeamForm.submit()}
              model={state.createTeamForm.saveButton}
            />
          ) : (
            <ButtonControlled
              className="primary large"
              label="Go to Library"
              onClick={() => state.createTeamForm.navToTeam()}
              model={state.createTeamForm.navButton}
            />
          )}
        </div>
      </SplashPageHeader>

      <div className="splash-page-content page-content">
        <CreateTeamForm model={state.createTeamForm} />
      </div>
    </BasicPage>
  );
});

export default CreateTeamPage;