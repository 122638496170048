import { action, makeObservable, observable, runInAction, computed } from 'mobx';
import { Maybe } from '../../core';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { input, InputState } from '../input';

export class RenameJobWindowState
  extends StoreNode {

  readonly nodeType = 'RenameJobWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);

    this.titleInput = input(this, {
      name: 'titleInput',
      isRequired: true,
      showStatusMessage: true,
      error: (input, fallback) =>
        (input.value && input.value.length < 5) ? 'Add at least 5 characters' : fallback
    })
  }

  readonly titleInput: InputState;
  readonly window = new WindowState(this.store);

  @observable isLoading: boolean = false;

  jobId: Maybe<string>;
  title: string | null = null;
  page: string | null = null;

  @computed
  get isWidgetMode() {
    return this.store.widgetService.isWidgetMode;
  }

  @action
  async init() {
    this.titleInput.value = this.title;
  }

  @action
  async submit() {
    this.isLoading = true;

    this.titleInput.handleSubmit();

    if (this.titleInput.error) {
      this.titleInput.handleSubmitReject();
      this.isLoading = false;
      return;
    }

    const [, err] = await this.store.apiService.updateJob({
      args: {
        id: this.jobId || '',
        title: this.titleInput.value || ''
      }
    });

    runInAction(() => {
      if (err) {
        notifyError(this, 'Could not rename video.');
        this.titleInput.handleSubmitReject();
      } else {
        notifySuccess(this, 'Video renamed.');
        this.titleInput.handleSubmitResolve();

        // TODO: remove hard dependencies to other pages
        switch (this.page) {
          case 'User': {
            this.store.userDashboardPage.mounted({});
            break;
          }
          case 'Trainer': {
            this.store.trainerDashboardPage.refreshJobs();
            break;
          }
          case 'Teams': {
            if (this.isWidgetMode) {
              this.store.teamLibraryWidget.refreshJobs();
            } else {
              this.store.teamDashboardPage.refreshJobs();
            }
            break;
          }
          default: {
            this.store.userDashboardPage.mounted({});
            this.store.teamDashboardPage.refreshJobs();
            this.store.trainerDashboardPage.refreshJobs();
          }
        }
      }

      this.handleSubmitFinally();
      this.emit('JobRenamed');
    });
  }

  handleSubmitFinally() {
    runInAction(() => {
      this.isLoading = false;
      this.close();
    })
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open({ jobId, title, page }: { jobId: string, title?: string, page?: string }) {
    this.dispatch('Overlays', 'openWindow', { name: 'RenameJobWindow' });

    this.jobId = jobId;
    this.page = page || null;
    this.title = title || null;
    this.init();
  }

  @action
  close() {
    this.jobId = null;
    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}