import '../authPages.scss';

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  BasicPage,
  ButtonControlled,
  CliprLogoSVGIcon,
  Feedback,
  LoadMask,
  TextInputControlled
} from '../../../components';
import { ForgotPasswordPageState } from './forgotPasswordPageState';
import { Link } from 'react-router-dom';
import Routes from '../../../routes';

type Props = {
  model: ForgotPasswordPageState
}

export const ForgotPasswordPage = observer(({
  model
}: Props) => {

  useEffect(() => {
    model.attached();
    return () =>
      model.detached();
  }, [model]);

  const { form } = model;
  const { flowResponse: response } = form;

  if (response) {
    const data = response.resetPasswordData;

    return (
      <BasicPage
        className="auth-page dock"
        showHeader={false}
        showWatermark={false}>
        <div className="auth-basic">
          <div className="auth-container">
            <div className="auth-panel">
              <header className="auth-header">
                <div className="clipr-logo">
                  <CliprLogoSVGIcon />
                </div>
                <h1 className="vpad2 bold">Reset link sent</h1>
                <h2 className="vpad2">Please check your email</h2>
                <p className="text-center">We sent an email
                  to <strong>{data?.destination || form?.username.value || 'the provided email'}</strong> which
                  contains a link to reset your CLIPr password.</p>
              </header>

              <p className="text-center vpad4">
                <span>Go back and</span>
                <Link to={Routes.login()} className="bold">Sign in</Link><span>when you're done</span>
              </p>
            </div>
          </div>
        </div>
      </BasicPage>
    );
  }

  return (
    <BasicPage
      className="forgot-password-page auth-page dock"
      showBackButton={true}
      showWatermark={false}>

      <div className="auth-basic">
        <div className="auth-container">
          <div className="auth-panel">
            <header className="auth-header">
              <div className="clipr-logo">
                <CliprLogoSVGIcon />
              </div>
              <h2 className="vpad2">Forgot password</h2>
              <p className="text-center">Enter your email address and we'll send you a password reset link.</p>
            </header>

            <form id="forgotPasswordForm"
              className="auth-form"
              onSubmit={form.handleSubmit}>

              <div className="row">
                <Feedback
                  id="forgotPasswordForm_feedback"
                  data-error-code={form.error?.type}
                  model={form.formGroup}
                  className="text-center" />
              </div>

              <div className="row">
                <TextInputControlled
                  id="forgotPasswordForm_email"

                  label="Email Address"
                  placeholder="Enter email address"
                  model={form.username} />
              </div>

              <div className="row btn-group fill form-footer">
                <ButtonControlled
                  id="forgotPasswordForm_submit"
                  model={form.submitButton}
                  className="submit-btn primary light"
                  type="submit"
                  label="Send" />
              </div>
              <p className="login-signup">
                <span>Return to</span>
                <Link to={Routes.login()}>Sign in</Link>
              </p>
              <p className="login-signup">
                <span>Don't have an account?</span>
                <Link to={Routes.register()}>Sign up</Link>
              </p>
            </form>
            <LoadMask
              className="partial"
              visible={form.showLoadMask}
              spinner="dots5" />
          </div>

        </div>
      </div>
    </BasicPage>
  );
});