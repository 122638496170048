import { makeObservable } from 'mobx';
import { Store } from '../../../store/store';
import { StoreNode } from '../../../store';
import { AnalyticsStreamData, IAnalyticsProvider } from '../analyticsSchema';
import { Error } from '../../../core/error';
import { Result } from '../../../core';

type Props = {
  streamName: keyof AnalyticsStreamData
  provider: IAnalyticsProvider
}

export class AnalyticsBeaconCoordinator<TName extends keyof AnalyticsStreamData>
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  get provider(): IAnalyticsProvider | null {
    return this.getProp('provider') ?? null;
  }

  get streamName(): TName | null {
    return this.getProp('streamName') ?? null;
  }

  isEnabled = false;
  payload: AnalyticsStreamData[TName] | null = null;

  setPayload(payload: AnalyticsStreamData[TName] | null) {
    this.payload = payload;
  }

  enable() {
    this.isEnabled = true;
  }
  disable() {
    this.isEnabled = false;
  }

  private init() {
    window.addEventListener('beforeunload',
      this.handleBeforeUnload);
    window.addEventListener('unload',
      this.handleUnload);
    window.addEventListener('visibilitychange',
      this.handleVisibilityChange);
    window.addEventListener('pagehide',
      this.handlePageHide);
  }

  dispose() {
    window.removeEventListener('beforeunload',
      this.handleBeforeUnload);
    window.removeEventListener('unload',
      this.handleUnload);
    window.removeEventListener('visibilitychange',
      this.handleVisibilityChange);
    window.removeEventListener('pagehide',
      this.handlePageHide);
  }

  /** Handles the native 'beforeunload' event from the global context. */
  private handleBeforeUnload = () => {
    this.commit();
  }

  /** Handles the native 'unload' event from the global context. */
  private handleUnload = () => {

  }

  /** Handles the native 'visibilitychange' event from the global context. */
  private handleVisibilityChange = () => {

  }

  /** Handles the native 'pagehide' event from the global context. */
  private handlePageHide = () => {

  }

  /** Called whenever the coordinator decides it's time to send the beacon. */
  private commit(): Result<boolean, Error> {
    if (this.isEnabled)
      return [null, new Error('InternalError', 'Not enabled.')];

    return this.send();
  }

  /** The actual send operation without any other side effects. */
  private send(): Result<boolean, Error> {

    const { provider } = this;
    if (!provider) {
      return [null, new Error('InternalError', 'No provider specified.')];
    }

    const { streamName, payload } = this;
    if (!streamName || !payload)
      return [null, new Error('InternalError', 'No streamName or payload specified.')];

    return provider.sendBeacon(streamName, payload);
  }
}