import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ApiVariables } from '../../api/apiSchema';
import { assertNotNull, Nullable } from '../../core';
import { notifyError, notifySuccess } from '../../services/notifications';
import { BookmarkList, BookmarkTarget, BookmarkTargetType, JobModel, MomentModel } from '../../entities';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { BookmarkListWindowState } from './bookmarkListWindowState';

export class BookmarkWindowState
  extends StoreNode {

  readonly nodeType = 'BookmarkWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);

  @observable
  isLoading = false;

  @observable
  targetType: Nullable<BookmarkTargetType> = null;

  /** 
   * The ID of the target Moment when the window target is set to 'Moment'
   * or null otherwise.
   */
  @observable momentId: Nullable<string> = null;

  /** 
   * The ID of the target Job when the target is set to 'Job' 
   * or the parent jobId of the target Moment when the target is set to 'Moment'. 
   */
  @observable jobId: Nullable<string> = null;


  @computed
  get bookmarkLists(): BookmarkList[] {
    const bkmListArr = this.store.bookmarkLists.slice();

    bkmListArr.sort((a, b) => b.createdAtTimestamp - a.createdAtTimestamp);
    return bkmListArr;
  }

  @computed
  get moment(): Nullable<MomentModel> {
    if (this.momentId === null)
      return null;
    return this.store.jobManager.maybeGetMoment(this.momentId);
  }

  @computed
  get job(): Nullable<JobModel> {
    if (this.jobId === null)
      return null;
    return this.store.jobManager.maybeGetJob(this.jobId);
  }

  @computed
  get target(): Nullable<BookmarkTarget> {
    if (!this.targetType)
      return null;
    return this.targetType === 'Moment' ?
      this.moment :
      this.job;
  }

  @computed get targetId(): Nullable<string> {
    if (!this.targetType)
      return null;
    return this.targetType === 'Moment' ?
      this.momentId :
      this.jobId;
  }


  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close();
        break;
    }
  }

  @action
  invoke(type: string, payload?: any) {

    switch (type) {
      case 'createList':
        const listWindow = this.store.bookmarkListWindow;
        listWindow.openCreate();

        const listener = async (msg: Message<BookmarkListWindowState>) => {
          const { payload } = msg;
          switch (msg.type) {
            case 'createSuccess': {

              const [res, err] = await this.store.apiCreateBookmark(
                this.getCreateBookmarkVars(payload.bookmarkList.id));

              if (err)
                notifyError(this, 'List created, but could not add bookmark in it.');

              this.emit('bookmark:created', res);
            } break;

            case 'close':
              listWindow.unlisten(listener as any);
              break;
          }
        }

        listWindow.listen(listener as any);
        break;
    }
  }

  private getCreateBookmarkVars(listId: string): ApiVariables<'createBookmark'> {

    assertNotNull(this.targetType);

    switch (this.targetType) {
      case 'Job':
        assertNotNull(this.jobId);

        return {
          listId,
          jobId: this.jobId
        };

      case 'Moment':
        assertNotNull(this.momentId);
        assertNotNull(this.jobId);

        return {
          listId,
          jobId: this.jobId,
          momentId: this.momentId
        }
    }
  }

  async toggleBookmark(listId: string) {

    if (!this.targetId)
      return notifyError(this, 'Could not add bookmark.');

    const list = this.store.getBookmarkList(listId);
    if (!list.hasTargetId(this.targetId)) {

      const [res, err] = await this.store.apiCreateBookmark(
        this.getCreateBookmarkVars(listId));

      if (err)
        return notifyError(this, 'Could not add bookmark.');

      this.emit('bookmark:created', res);
      return notifySuccess(this, 'Bookmark added.');

    } else {

      const bkm = list.getBookmarkFor(this.targetId);
      const [, err] = await this.store.apiDeleteBokmark({
        bookmarkId: bkm.id,
        listId
      });

      if (err)
        return notifyError(this, 'Could not remove bookmark.');
      return notifySuccess(this, 'Bookmark removed.');
    }
  }

  @action
  async openBookmark(bkmId: string) {
    const { store } = this;
    this.isLoading = true;
    this.open();

    const bkm = this.store.getBookmark(bkmId);

    await bkm.apiFetchRelations();
    await store.apiFetchBookmarkLists();

    switch (bkm.targetType) {
      case 'Job':
        break;

      case 'Moment':
        this.openMoment(bkm.momentId, bkm.jobId, false);
        break;
    }

    runInAction(() =>
      this.isLoading = false);
  }

  @action
  async openMoment(momentId: string, jobId: string, open = true) {
    this.isLoading = true;
    if (open)
      this.open();

    const [, err] = await this.store.apiFetchMomentBookmarks({
      momentId: momentId,
      jobId: jobId
    });
    if (err)
      return notifyError(this, 'Could not open bookmark window.');

    runInAction(() => {
      this.momentId = momentId;
      this.jobId = jobId;
      this.targetType = 'Moment';
      this.isLoading = false;
    });
  }

  @action
  async openJob(jobId: string) {
    this.open();
  }

  @action
  private open() {
    this.dispatch('Overlays', 'openWindow', { name: 'BookmarkWindow' });
    this.emit('open');
  }

  @action
  private close() {
    this.reset();
    this.dispatch('Overlays', 'closeWindow');
    this.emit('close');
  }

  @action
  private reset() {
    this.momentId = null;
    this.jobId = null;
    this.targetType = null;
    this.isLoading = false;
  }
}