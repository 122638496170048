import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { TextInputWarningSVGIcon } from '../../components';

type Props = {
  header?: ReactNode
}

export const ErrorSection = observer(({header}: Props) => {
  return (
    <>
      {header && <section className="page-subheader">{header}</section>}

      <div className="catalog empty-catalog error-catalog">
        <div className="icon">
          <TextInputWarningSVGIcon />
        </div>
        <h1 className="headline-1">
          An error occurred, please try again later.
        </h1>
      </div>
    </>
  );
});
