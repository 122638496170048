import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import camelCase from 'lodash/camelCase';
import { camelPad, hasKey, isNonEmptyString } from '../../core';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { JobModel, JobVideoTypeList, Team } from '../../entities';
import { input, inputGroup, InputGroupState, InputState } from '../input';
import { Metadata, UpdateJobInput } from '@clipr/lib';
import { getEnrichmentLevelLabel, JobLevel } from '../../entities/job/jobFeatures';
import { getMetadataInputFieldList, JobMetadataField, JobMetadataFields } from '../../entities/job/jobMetadata';
import { fileInput } from '../input/fileInputState';
import { ApiVariables } from '../../api/apiSchema';
import { ApiUploadRequestParams } from '../../api/apiUploadRequest';
import { getLanguageInputItem, LanguageItems } from '../../entities/language';
import { FilteredSpecialityItems, getJobSpecialityInput, SpecialityItems } from '../../entities/job/jobSpeciality';
import { UploadTask } from '../../services/upload/uploadTask';
import { isEmptyValue } from '../../util';

type VideoDetailsMainInput = 'title' | 'enrichmentLevel' | 'description' | 'keywords' | 'speciality' | 'thumbnail' | 'language' | 'isPublic' | 'videoType';

//TODO: I think these inputs are duplicated in multiple files. Also this array approach is problematic.
export const VideoDetailsInputs = {
  full: {
    main: ['title', 'description', 'speciality', 'language', 'videoType', 'enrichmentLevel', 'sortOrder', 'keywords', 'tags', 'thumbnail', 'isPublic'] as VideoDetailsMainInput[],
    metadata: getMetadataInputFieldList()
  },
  partial: {
    main: ['title', 'description', 'keywords', 'thumbnail', 'isPublic'] as VideoDetailsMainInput[],
    metadata: getMetadataInputFieldList()
  }
}

type VideoDetailsLayout = 'full' | 'partial' | 'live';

export class VideoDetailsWindowState
  extends StoreNode {
  readonly nodeType = 'VideoDetailsWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(this.windowListener);
    this.assignMetadataFields();

    this.metadataGroup = inputGroup(this, {
      name: 'window',
      inputs: [
        ...this.metadataInputs
      ]
    });

    this.inputGroup = inputGroup(this, {
      name: 'window',
      inputs: [
        this.mainGroup,
        this.metadataGroup
      ],
      isSubmitDisabled: () =>
        this.inputGroup.inputs.some(input =>
          //@ts-ignore
          input.inputs.some(input => input.status === 'error' && input.isTouched && !input.isFocused)
        )
    });
  }

  readonly metadataInputs: InputState[] = [];
  readonly window = new WindowState(this.store);

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
        if (this.isLoading || this.inputGroup.isSubmitting)
          return;
        this.cancel();
        break;
      case 'outsideClick':
        if (this.isLoading || this.inputGroup.isSubmitting)
          return;
        this.cancel(true);
        break;
    }
  };

  @action
  cancel = (withConfirmation: boolean = false) => {
    if (this.inputGroup.isSubmitting || !this.window.isVisible) //|| !this.isVisible
      return;
    if (this.inputGroup.isDirty && withConfirmation)
      this.store.closeWindowConfirmationModal.open({
        onSubmit: () => {
          this.clearState();
          this.close('close');
        },
        modalMessage: 'Are you sure you want to close the video details window? The progress will be lost.'
      })
    else
      this.close('close');
  }

  @action
  private assignMetadataFields = () => {
    JobMetadataFields.forEach(item => {
      const itemName = camelCase(item);
      const inputState = generateMetadataInputState(item, this);
      Object.assign(this, {
        [itemName]: inputState
      });
      this.metadataInputs.push(inputState);
    })
  };

  //main fields
  readonly title = input(this, {
    name: 'title',
    isRequired: true,
    showStatusMessage: true,
    error: (input, fallback) =>
      (input.value && input.value.length < 5) ? 'Add at least 5 characters' : fallback
  });

  readonly description = input(this, {
    name: 'description',
    multiline: true,
    showStatusMessage: true,
  });

  readonly keywords = input(this, {
    name: 'keywords',
    showStatusMessage: true,
  });

  readonly tags = input(this, {
    name: 'tags',
    showStatusMessage: true,
  });

  readonly sortOrder = input(this, {
    name: 'sortOrder',
    isRequired: false,
    showStatusMessage: true,
    error: (input) => {
      if (!this.teamId || !input.value)
        return;

      const sortOrder = Number(input.value);
      if (!Number.isInteger(sortOrder))
        return 'Only digits allowed';

      if (!!sortOrder && sortOrder <= 0)
        return 'Sort order must be bigger than 0';
    }
  });

  readonly isPublic = input(this, {
    name: 'isPublic',
    label: () => this.isPublic.value ? 'Make video private' : 'Make video shareable'
  });

  readonly thumbnail = fileInput(this, {
    name: 'thumbnail',
    // disabled: () => !this.job
  });

  readonly enrichmentLevel = input(this, {
    name: 'enrichmentLevel',
    selectorItems: () => JobLevel.map(item => ({
      value: item,
      label: getEnrichmentLevelLabel(item)
    })),
    disabled: true
  });

  readonly speciality = input(this, {
    name: 'speciality',
    selectorItems: this.team?.publicSafety ? 
      SpecialityItems : 
      FilteredSpecialityItems,
    disabled: true
  });

  readonly language = input(this, {
    name: 'language',
    selectorItems: LanguageItems,
    disabled: true
  });

  readonly videoType = input(this, {
    name: 'videoType',
    selectorItems: JobVideoTypeList,
    disabled: true,
  });

  readonly mainGroup = inputGroup(this, {
    name: 'window',
    inputs: [
      this.title,
      this.description,
      this.sortOrder,
      this.keywords,
      this.tags,
      this.isPublic,
      this.speciality,
      this.thumbnail,
      this.videoType,
    ]
  });

  readonly metadataGroup: InputGroupState;
  readonly inputGroup: InputGroupState;

  @observable isLoading: boolean = false;

  @observable jobId: string | null = null;
  @observable teamId: string | null = null;
  @observable task: UploadTask | null = null;
  @observable layoutType: VideoDetailsLayout | null = null;
  @observable isAdvancedSectionExpanded: boolean = false;

  @computed get streamedOnText() {
    if (this.job?.streamedOnLabel)
      return this.job?.streamedOnLabel;

    if (this.job?.liveStream?.scheduledDateTime)
      return `Scheduled on ${new Date(this.job?.liveStream?.scheduledDateTime).toUTCString()}`;

    return null;
  }

  @computed get isAdvancedSectionExpandable() {
    return this.advancedInputs.length > 4;
  }

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed get team(): Team | null {
    return this.store.teamManager.getTeam(this.teamId);
  }

  @computed get chatGptSummaryEnabled(): boolean {
    if (this.team) {
      return this.team.chatGptSummaryEnabled;
    }

    return this.store.profilePage?.localUser?.chatGptSummaryEnabled ?? false;
  }

  @computed get isJobCompleted(): boolean {
    if (this.job) return true;
    if (this.task?.isCompleted) return true;
    return false;
  }

  @computed get isJobInProgress(): boolean {
    return this.job?.isInProgress ?? false;
  }

  @computed
  get mainInputs(): string[] | null {
    let type = this.layoutType;

    if (type && hasKey(VideoDetailsInputs, type))
      return VideoDetailsInputs[type].main
    else
      return null;
  }

  @computed
  get advancedInputs(): string[] {
    let type = this.layoutType;

    if (this.team)
      return this.team.visibleMetadataFields ?? [];

    if (type && hasKey(VideoDetailsInputs, type))
      return VideoDetailsInputs[type].metadata ?? []
    else
      return [];
  }

  @computed
  get hasAdvancedInputs(): boolean {
    return this.advancedInputs.length > 0;
  }

  @action
  expandAdvancedSection = () => {
    this.isAdvancedSectionExpanded = true;
  }

  @action
  collapseAdvancedSection = () => {
    this.isAdvancedSectionExpanded = false;
  }

  @action
  toggleAdvancedSection = () => {
    if (this.isAdvancedSectionExpanded)
      this.collapseAdvancedSection();
    else
      this.expandAdvancedSection();
  }

  @action
  async init() {
    this.isLoading = true;

    // try to fetch the job
    await this.store.jobManager.apiFetchJob(this.jobId!, false, undefined, this.teamId ?? undefined);

    // try to find an active task
    if (!this.job) {
      const activeTask = this.store.uploadService.tasks.find(task => task.syncJobId === this.jobId);
      this.task = activeTask || null;
    }

    if (!this.job && !this.task)
      this.handleFailed();
  }

  getInputLabel(inputName: string) {
    let label = null;
    if (this.team?.metadataFields && hasKey(this.team.metadataFields, inputName))
      label = this.team.getMetadataFieldAlias(inputName);

    if (!label)
      label = camelPad(inputName);

    return label;
  }

  @action
  handleFailed() {
    this.close();
    notifyError(this, 'Job does not exist.');
    this.isLoading = false;
  }

  @action
  handleError(msg: string) {
    this.isLoading = false;
    notifyError(this, msg);
  }

  @action
  handleSuccess() {
    this.isLoading = false;
  }

  @action
  async submit() {
    if (!this.jobId) return;

    this.inputGroup.handleSubmit();
    if (this.inputGroup.error) {
      console.log(this.inputGroup.error, this.inputGroup, 'lala')
      this.inputGroup.handleSubmitReject();
      notifyError(this, 'Fix the validation errors before saving.');
      return;
    }

    const metadataData = this.metadataGroup.export();
    const mainData = this.mainGroup.export();

    if (this.job) {
      const [fileParams, errFile] = await this.submitThumbnail();

      if (errFile)
        return;

      const metadataInput: Metadata = {};
      Object.keys(metadataData).forEach(item => Object.assign(metadataInput, {
        [item]: metadataData[item] ?? undefined
      }));

      const input: UpdateJobInput = {
        id: this.jobId,
        title: mainData.title || undefined,
        description: mainData.description || '',
        sortOrder: this.teamId && !isEmptyValue(mainData.sortOrder) ? Number(mainData.sortOrder) : undefined,
        keywords: ((mainData.keywords as string) || '')
          .split(',')
          .map(k => k.trim())
          .filter(x => isNonEmptyString(x)),

        tags: ((mainData.tags as string) || '')
          .split(',')
          .map(k => k.trim())
          .filter(x => isNonEmptyString(x)),

        isPublic: mainData.isPublic ?? undefined,
        // medicalSpecialty: getJobSpecialityOutput(mainData.speciality)
        teamId: this.teamId ?? undefined
      };

      Object.assign(input, { metadata: metadataInput });
      Object.assign(input, fileParams);
      this.isLoading = true;
      const [, err] = await this.store.jobManager.apiUpdateJob(input);
      if (err) {
        runInAction(() => this.isLoading = false);
        this.inputGroup.handleSubmitReject();
        return notifyError(this, `Failed to update video.`);
      }
    } else if (this.task) {
      const input = Object.assign(mainData, { metadata: metadataData });
      const { synchronizer } = this.task;
      synchronizer.updateSyncModel(input);
    }

    runInAction(() => this.isLoading = false);
    this.inputGroup.handleSubmitResolve();
    notifySuccess(this, `Video was updated.`);

    // TODO: change this into a subscribed event
    if (this.store.widgetService.isWidgetMode && this.teamId) {
      this.store.teamLibraryWidget.refreshJobs();
    }
    this.close('JobUpdated');
  }

  @action
  async submitThumbnail() {
    if (!this.jobId)
      return [null, true];

    const thumbnailFile = this.thumbnail.export();
    if (!thumbnailFile)
      return [null, null];

    this.isLoading = true;

    const input: ApiVariables<'getJobThumbnailUploadURL'> = {
      input: {
        id: this.jobId,
        filename: thumbnailFile.name
      }
    }
    // Get the upload URL
    const [res, err] = await this.store.apiService.getJobThumbnailUploadURL(input);

    if (err || !res) {
      runInAction(() => this.handleError('Could not retrieve thumbnail upload url.'));
      return [null, true];
    }

    const params = res.getJobThumbnailUploadURL;
    const uploadParams: ApiUploadRequestParams = {
      file: thumbnailFile,
      url: params.uploadUrl,
      data: params.fields
    }

    // Start the upload request
    const uploadRequest = this.store.apiService.uploadRequest(uploadParams);
    const [, uploadErr] = await uploadRequest.start();

    if (uploadErr) {
      runInAction(() => this.handleError('Could not upload thumbnail.'));
      return [null, true];
    }

    const updateParams = {
      posterToken: params.uploadToken,
    };

    return [updateParams, false];
  }

  @action
  async open({ jobId, layoutType, teamId }: { jobId: string, layoutType: VideoDetailsLayout, teamId?: string }) {
    this.dispatch('Overlays', 'openWindow', { name: 'VideoDetailsWindow' });
    this.emit('open');
    this.window.open();
    this.jobId = jobId;
    this.teamId = teamId || null;
    this.layoutType = layoutType;
    await this.init();
    this.initMainForm();
    this.initMetadataForm();
    this.handleSuccess();
  }

  @action
  initMainForm() {
    if (this.job) {
      this.loadFromJob();
    } else {
      this.loadFromJobStub();
    }
  }

  @action
  loadFromJob() {
    const { job } = this;
    if (!job)
      return;

    this.title.loadValue(job.title);
    this.description.loadValue(job.description);
    this.isPublic.loadValue(job.isPublic);
    this.language.loadValue(getLanguageInputItem(job.languageCode));
    this.enrichmentLevel.loadValue(JobLevel.find(item => item === job.enrichmentLevel));
    this.thumbnail.loadPreviewUrl(job.posterURL);
    this.keywords.loadValue((job.keywords ?? []).join(', '));
    this.tags.loadValue((job.tags ?? []).join(', '));
    this.sortOrder.loadValue((job.sortOrder));
    this.speciality.loadValue(SpecialityItems.find(item => item.value === getJobSpecialityInput(job ?? null)));
    this.videoType.loadValue(JobVideoTypeList.find(item => item.value === job.videoType) || null);
  }

  @action
  loadFromJobStub() {
    const source = this.task?.synchronizer.syncModel;
    if (!source)
      return;

    this.title.loadValue(source.title);
    this.description.loadValue(source.description);
    this.isPublic.loadValue(source.isPublic);
    this.thumbnail.loadFile(source.thumbnail || null);
    this.keywords.loadValue(source.keywords);
    this.tags.loadValue(source.tags);
    this.sortOrder.loadValue(source.sortOrder);

    // this.language.loadValue(LanguageItems.find(item => item.value === source.languageCode));
    // this.enrichmentLevel.loadValue(JobLevel.find(item => item === source.enrichmentLevel));
    this.speciality.loadValue(source.speciality);
  }

  @action
  initMetadataForm() {
    const source = this.job || this.task?.synchronizer.syncModel;
    const metadata = source?.metadata || null;

    this.metadataInputs.forEach(input => {
      const value = (metadata && metadata[input.name! as keyof Metadata]) || null;
      input.loadValue(value);
    });
  }

  @action
  clearState() {
    this.jobId = null;
    this.layoutType = null;
    this.task = null;
    this.inputGroup.clear();
    this.isLoading = false;
    this.collapseAdvancedSection();
  }

  @action
  close(msg: string = 'close') {
    this.emit(msg);
    this.window.close();
    this.dispatch('Overlays', 'closeWindow');
  }

  @action
  onTransitionEnd = () => {
    if (!this.window.isVisible)
      this.clearState();
  }
}

const generateMetadataInputState = (metadata: JobMetadataField, obj: VideoDetailsWindowState): InputState => {
  let inputName = camelCase(metadata);
  switch (metadata) {
    //TODO: handle each input by name
    default:
      return input(obj, {
        name: inputName,
        showStatusMessage: true,
        error: input =>
          input.value && input.value.length > 50 ? 'Max char count exceeded' : null
      })
  }
}
