import { ClipType } from '@clipr/lib';

export const shouldHaveParentClip = (clipType: ClipType | null): boolean => {
  return clipType === 'SubTopic';
}

export const getParentType = (clipType: ClipType | null): ClipType => {
  switch (clipType) {
    case 'SubTopic':
    default:
      return 'Topic';
  }
}

