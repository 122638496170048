import React from 'react';
import { observer } from 'mobx-react-lite';
import { MomentModel } from '../../entities';
import { TrainerTranscriptsState } from './trainerTranscriptsState';
import { ArrowRightSVGIcon } from '../svg';
import { generateTranscriptText } from './trainerTranscriptTextBlock';

type Props = {
  ownerModel: TrainerTranscriptsState,
  moment: MomentModel
}

export const TrainerTranscriptBlock = observer((props: Props) => {

  const { moment, ownerModel } = props;
  const { description } = moment;
  const active = ownerModel.isTranscriptMomentActive(moment);

  const text = (description && moment.wordConfidenceNorm && moment.avgConfidence !== 1) ?
    generateTranscriptText(description, moment.wordConfidenceNorm) : description;

  return (
    <div className="trainer-transcript-block"
      aria-expanded={active}
      data-moment={true}
      data-moment-id={moment.id}>

      <div className="block-summary"
        onClick={evt => ownerModel.handleTranscriptClick(evt, moment)}>

        <span className="block-time">
          <span className="text">{moment?.startTimeLabel || '0:00'}</span>
          <span className="icon"><ArrowRightSVGIcon /></span>
          <span className="text">{moment?.endTimeLabel || '0:00'}</span>
        </span>

        <span className="block-speaker">{moment.speaker?.name}</span>
      </div>

      {description && active &&
        <div className="block-text">
          {text}
        </div>}
    </div>
  );
});