import { action, makeObservable, computed, observable } from 'mobx';
import { Store } from '../../store/store';
import { BindingProps, Message, StoreNode } from '../../store';
import {
  InputState,
  input,
  inputGroup,
} from '../../components/input';
import { ApiVariables } from '../../api';
import { notifyError, notifySuccess } from '../../services/notifications';
import { GenerativeAiProvider } from '@clipr/lib';
import { ToggleChatGptWindowState } from '../teamSettings/toggleChatGptWindowState';

type GenerativeAiFormStateProps = BindingProps<{
  teamId?: string;
  refreshTeam?: (() => void) | null;
}>;

export class GenerativeAiFormState
  extends StoreNode {

  constructor(store: Store, props?: GenerativeAiFormStateProps) {
    super(store, props);
    makeObservable(this);

    this.chatGptEmailNotifications = input(this, {
      name: 'chatGptEmailNotifications'
    });
  }

  @observable generativeAiProvider: GenerativeAiProvider | null = null;

  @computed get teamId(): string {
    return this.resolvedProps.teamId;
  }

  @computed get team() {
    return this.store.teamManager.getTeam(this.teamId);
  }

  readonly chatGptEmailNotifications: InputState;

  readonly form = inputGroup(this, {
    name: 'generativeAiForm',
    inputs: () => {
      return [
        this.chatGptEmailNotifications
      ]
    }
  });

  @action
  init() {
    if (!this.team) return;
    this.chatGptEmailNotifications.loadValue(!!this.team.isSendVideoProcessedNotificationToAllMembers);
    this.generativeAiProvider = this.team.generativeAiProvider;
  }

  @action
  async changeAiProvider(value: string) {
    if (!this.team) return;

    const window = this.store.toggleChatGptWindow;
    const listener = (msg: Message<ToggleChatGptWindowState>) => {
      switch (msg.type) {
        case 'success':
          this.props.refreshTeam?.();
          break;
        case 'close':
          window.unlisten(listener);
          break;
      }
    }
    window.listen(listener);
    window.open({
      teamId: this.team?.id,
      provider: value as GenerativeAiProvider,
      summarizationEnabled: this.team.chatGptSummaryEnabled,
      actionItemsExtractionEnabled: this.team.actionItemsExtractionEnabled,
      chatGptEnabled: this.team.cliprGptEnabled,
      header: 'Change AI Provider',
      button: 'Confirm',
      description: 'Are you sure you want to change the AI provider? This means that all the videos from this library will be reprocessed.'
    });
  }

  @action
  async toggleSummarization() {
    if (!this.team) return;

    if (this.team?.chatGptSummaryEnabled) {
      await this.disableChatGpt();
      return;
    }


    const window = this.store.toggleChatGptWindow;
    const listener = (msg: Message<ToggleChatGptWindowState>) => {
      switch (msg.type) {
        case 'success':
          this.props.refreshTeam?.();
          break;
        case 'close':
          window.unlisten(listener);
          break;
      }
    }
    window.listen(listener);
    window.open({
      teamId: this.team?.id,
      provider: this.generativeAiProvider as GenerativeAiProvider ?? this.team.generativeAiProvider,
      summarizationEnabled: !this.team.chatGptSummaryEnabled,
      actionItemsExtractionEnabled: this.team.actionItemsExtractionEnabled,
      chatGptEnabled: this.team.cliprGptEnabled,
      header: 'Enable video summary',
      description: 'By enabling AI video summary, we will send parts of the video transcript to an AI provider to generate a summary, for all the existing and the new videos from this library.'
    });
  }

  @action
  async toggleCliprGpt() {
    if (!this.team) return;

    if (this.team?.cliprGptEnabled) {
      await this.disableCliprGpt();
      return;
    }

    const window = this.store.toggleChatGptWindow;
    const listener = (msg: Message<ToggleChatGptWindowState>) => {
      switch (msg.type) {
        case 'success':
          this.props.refreshTeam?.();
          break;
        case 'close':
          window.unlisten(listener);
          break;
      }
    }
    window.listen(listener);
    window.open({
      teamId: this.team?.id,
      provider: this.generativeAiProvider as GenerativeAiProvider ?? this.team.generativeAiProvider,
      summarizationEnabled: this.team.chatGptSummaryEnabled,
      actionItemsExtractionEnabled: this.team.actionItemsExtractionEnabled,
      chatGptEnabled: !this.team.cliprGptEnabled,
      header: 'Enable CLIPr GPT',
      description: 'By enabling CLIPr GPT, we will send parts of the video transcript to the AI provider to generate answers, for all the existing and the new videos from this library.'
    });
  }

  @action
  async toggleActionItems() {
    if (!this.team) return;

    if (this.team?.actionItemsExtractionEnabled) {
      await this.disableChatGptExtraActions();
      return;
    }

    const window = this.store.toggleChatGptWindow;
    const listener = (msg: Message<ToggleChatGptWindowState>) => {
      switch (msg.type) {
        case 'success':
          this.props.refreshTeam?.();
          break;
        case 'close':
          window.unlisten(listener);
          break;
      }
    }
    window.listen(listener);
    window.open({
      teamId: this.team?.id,
      provider: this.generativeAiProvider as GenerativeAiProvider ?? this.team.generativeAiProvider,
      summarizationEnabled: this.team.chatGptSummaryEnabled,
      actionItemsExtractionEnabled: !this.team.actionItemsExtractionEnabled,
      chatGptEnabled: this.team.cliprGptEnabled,
      header: 'Enable action items',
      description: 'By enabling AI action items, we will send parts of the video transcript to an AI provider to generate action items and next steps, for all the new videos from this library.'
    });
  }

  @action
  async disableCliprGpt() {
    const vars: ApiVariables<'updateTeam'> = {
      args: {
        teamId: this.team?.id || '',
        generativeAiSettings: {
          summarizationEnabled: this.team?.chatGptSummaryEnabled ?? false,
          actionItemsExtractionEnabled: this.team?.actionItemsExtractionEnabled ?? false,
          cliprGptEnabled: false,
          provider: this.team?.generativeAiProvider ?? GenerativeAiProvider.Amazon
        }
      }
    };

    const [, err] = await this.store.teamManager.updateTeam(vars);
    if (err) {
      this.handleSubmitReject(`Could not update team. Please try again.`);
      return;
    }

    this.handleSubmitSuccess(`Team updated.`);
    this.props.refreshTeam?.();
  }

  @action
  async disableChatGpt() {
    const vars: ApiVariables<'updateTeam'> = {
      args: {
        teamId: this.team?.id || '',
        generativeAiSettings: {
          summarizationEnabled: false,
          actionItemsExtractionEnabled: this.team?.actionItemsExtractionEnabled ?? false,
          cliprGptEnabled: this.team?.cliprGptEnabled ?? false,
          provider: this.team?.generativeAiProvider ?? GenerativeAiProvider.Amazon
        }
      }
    };

    const [, err] = await this.store.teamManager.updateTeam(vars);
    if (err) {
      this.handleSubmitReject(`Could not update team. Please try again.`);
      return;
    }

    this.handleSubmitSuccess(`Team updated.`);
    this.props.refreshTeam?.();
  }

  @action
  async disableChatGptExtraActions() {
    const vars: ApiVariables<'updateTeam'> = {
      args: {
        teamId: this.team?.id || '',
        generativeAiSettings: {
          summarizationEnabled: this.team?.chatGptSummaryEnabled ?? false,
          actionItemsExtractionEnabled: false,
          cliprGptEnabled: this.team?.cliprGptEnabled ?? false,
          provider: this.team?.generativeAiProvider ?? GenerativeAiProvider.Amazon
        }
      }
    };

    const [, err] = await this.store.teamManager.updateTeam(vars);
    if (err) {
      this.handleSubmitReject(`Could not update library. Please try again.`);
      return;
    }

    this.handleSubmitSuccess(`Library updated.`);
    this.props.refreshTeam?.();
  }

  @action
  async toggleSendVideoProcessedNotificationToAllMembers() {
    const vars: ApiVariables<'updateTeam'> = {
      args: {
        teamId: this.team?.id || '',
        sendVideoProcessedNotificationToAllMembers: !this.team?.sendVideoProcessedNotificationToAllMembers
      }
    };

    const [, err] = await this.store.teamManager.updateTeam(vars);
    if (err) {
      this.handleSubmitReject(`Could not update library. Please try again.`);
      return;
    }

    this.handleSubmitSuccess(`Library updated.`);
    this.props.refreshTeam?.();
  }

  reset() {
    this.form.clear();
  }

  export(): any {
    return this.form.export();
  }

  @action
  private handleSubmitReject(msg: string) {
    this.form.handleSubmitReject();
    notifyError(this, msg);
  }

  @action
  private handleSubmitSuccess(msg: string) {
    this.props.refreshTeam?.();
    this.init();
    this.form.handleSubmitResolve();
    notifySuccess(this, msg);
  }
}
