import fromPairs from 'lodash/fromPairs';
import { ParamInfo, ParamsSchema, ParamsSchemaParams, ParamType } from '.';

export class ParamsSchemaBuilder {

  private readonly lookup = new Map<string, ParamInfo<any>>();

  get schema(): ParamsSchema {
    return this.build();
  }

  /** Copies another schema into the current one. Any duplicate keys will be overwritten. */
  copy(other: ParamsSchema) {
    const otherParams = other.params;
    const otherParamsKeys = Object.keys(other.params);

    for (const key of otherParamsKeys)
      this.lookup.set(key, otherParams[key]);
      
    return this;
  }

  param<T = string>(name: string, type: ParamType, opts?: Partial<ParamInfo<T>>) {
    const schema = {
      name,
      type,
      ...opts ?? {}
    };

    this.lookup.set(name, schema);
    return this;
  }

  string(name: string, opts?: Partial<ParamInfo>) {
    return this.param(name, ParamType.String, opts);
  }

  boolean(name: string, opts?: Partial<ParamInfo<boolean>>) {
    return this.param(name, ParamType.Boolean, opts);
  }

  int(name: string, opts?: Partial<ParamInfo>) {
    return this.param(name, ParamType.Int, opts);
  }

  uint(name: string, opts?: Partial<ParamInfo>) {
    return this.param(name, ParamType.UInt, opts);
  }

  float(name: string, opts?: Partial<ParamInfo>) {
    return this.param(name, ParamType.Float, opts);
  }

  enum(name: string, opts?: Partial<ParamInfo>) {
    return this.param(name, ParamType.Enum, opts);
  }

  dateTime(name: string, opts?: Partial<ParamInfo>) {
    return this.param(name, ParamType.DateTime, opts);
  }

  duration(name: string, opts?: Partial<ParamInfo>) {
    return this.param(name, ParamType.Duration, opts);
  }

  build(): ParamsSchema {
    const params: ParamsSchemaParams = fromPairs([...this.lookup.entries()]);
    const schema: ParamsSchema = {
      params
    }

    return schema;
  }
}

export function paramsSchema(): ParamsSchemaBuilder {
  return new ParamsSchemaBuilder();
}