import { action, computed, makeObservable } from 'mobx';
import { Store } from '../../store/store';
import Routes from '../../routes';
import { LibraryProps, LibraryServiceBase } from './libraryServiceBase';
import { IntegrationTabState } from '../../pages/integrations/integrationTabState';
import { LibraryCatalogSource } from '../../entities';
import { LibraryName } from './librarySchema';
import { AsyncResult } from '../../core';

const SCOPE = 'https://www.googleapis.com/auth/drive.readonly';
const CLIENT_ID = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID || '';
const ENABLED = process.env.REACT_APP_ENABLE_GOOGLE_DRIVE?.toLocaleLowerCase() === 'true';

const params: LibraryProps = {
  libraryLabel: 'Google Drive',
  librarySource: 'GoogleDrive',
  routeView: 'gdrive',
  enabled: ENABLED
}
export class GoogleDriveService
  extends LibraryServiceBase {

  readonly nodeType: 'GoogleDriveLibrary' = 'GoogleDriveLibrary';

  constructor(store: Store) {
    super(store, params);
    makeObservable(this);
  }

  readonly libraryName = LibraryName.GoogleDrive;

  @computed
  get authUrl(): string {
    const url = new URL('https://accounts.google.com/o/oauth2/v2/auth');

    url.searchParams.append('access_type', 'offline');
    url.searchParams.append('scope', SCOPE);
    url.searchParams.append('prompt', 'consent');
    url.searchParams.append('response_type', 'code');
    url.searchParams.append('client_id', CLIENT_ID);
    url.searchParams.append('redirect_uri', window.location.origin + Routes.oauthGoogleDriveCallback());

    return url.toString();
  }

  @action
  async connectLibrary(redirect?: boolean): AsyncResult<true> {
    
    const redirectUri = window.location.origin + Routes.oauthGoogleDriveCallback();
    const [, err] = await this.connect(redirectUri);

    if (!err && redirect) {
      const customRoute = localStorage.getItem('externalLibraryUploadAuth');

      if (customRoute) {
        localStorage.removeItem('externalLibraryUploadAuth');
        this.store.goTo(customRoute);
        return [true];
      }

      this.store.goTo(Routes.userDashboard({ view: 'gdrive' }));
    }

    return [true];
  }

  @action
  async deleteLibrary(injectedModel?: IntegrationTabState | LibraryCatalogSource | null) {
    const [, err] = await this.disconnect();

    if (!err) {
      if (injectedModel) {
        injectedModel.reset();
        return;
      }

      this.store.userDashboardPage.googleDriveTabState.reset();
    }
  }
}