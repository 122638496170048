import { computed, makeObservable } from 'mobx';
import { BindingProps } from '../../store/propManager';
import { Store } from '../../store/store';
import { StoreNode } from '../../store/storeNode';

type TeamDictionaryStateProps = BindingProps<{
  teamId?: string;
}>;

export class TeamDictionaryState
  extends StoreNode {

  constructor(store: Store, props?: TeamDictionaryStateProps) {
    super(store, props);
    makeObservable(this);
  }

  @computed get teamId(): string {
    return this.resolvedProps.teamId;
  }

  @computed get team() {
    return this.store.teamManager.getTeam(this.teamId);
  }


  init() {
   
  }

  reset() {
   
  }
}