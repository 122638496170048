import isEmpty from 'lodash/isEmpty';
import { JobSpeciality, JobType } from '@clipr/lib';
import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { input, inputGroup, InputGroupState, InputState } from '../../components/input';
import { getDefaultEnrichmentLevel, getEnrichmentLevelItemTooltip, getEnrichmentLevelLabel, JobLevel } from '../../entities/job/jobFeatures';
import { DefaultSpecialityItem, FilteredSpecialityItems, getJobSpecialityInput, SpecialityItems } from '../../entities/job/jobSpeciality';
import { DefaultLanguageItem, getDefaultLanguageValue, getLanguageInputItem, LanguageItems } from '../../entities/language';
import { UploadTask } from '../../services/upload/uploadTask';
import { UploadFile } from '../../services/upload/uploadService';
import { JobVideoTypeList, Team } from '../../entities';

type UploadFileItemProps = BindingProps<{
  teamId?: string;
  jobId?: string;
  file: File;
}>;

export class UploadFileItemState
  extends StoreNode {

  constructor(store: Store, props: UploadFileItemProps) {
    super(store, props);
    makeObservable(this);

    this.levelInput = input(this, {
      name: 'level',
      placeholder: 'Choose an option',
      selectorItems: () => JobLevel.map(item => ({
        value: item,
        label: getEnrichmentLevelLabel(item),
        tooltip: getEnrichmentLevelItemTooltip(item)
      })),
      showStatusMessage: true,
      statusMessage: (input: InputState) =>
        input.status === 'error' && input.showStatusIcons ? input.error?.toString() : '',
      export: (self: InputState) => self.normValue,
      disabled: () => !!this.jobId
    });

    this.specialityInput = input(this, {
      name: 'speciality',
      placeholder: 'Choose an option',
      selectorItems: () => this.team?.publicSafety ? 
        SpecialityItems : 
        FilteredSpecialityItems,
      showStatusMessage: true,
      isRequired: () => !this.isSpecialityInputDisabled,
      // statusMessage: (input: InputState) =>
      //  input.status === 'error' && input.showStatusIcons ? input.error?.toString() : '',
      error: (input: InputState) => {
        if (isEmpty(input.value) && !this.isSpecialityInputDisabled) return 'Required field';
      },
      export: (self: InputState) => self.normValue,
      disabled: () => !!this.jobId,
      onChange: (input: InputState) => {
        if (input.normValue === 'Medical') {
          const lang = getLanguageInputItem(getDefaultLanguageValue());
          this.languageInput.loadValue(lang);
        }
      }
    });

    this.languageInput = input(this, {
      name: 'language',
      placeholder: 'Choose an option',
      selectorItems: LanguageItems,
      showStatusMessage: true,
      isRequired: true,
      statusMessage: (input: InputState) =>
        input.status === 'error' && input.showStatusIcons ? input.error?.toString() : '',
      error: (input: InputState) => {
        if (isEmpty(input.value)) return 'Required field';
        if (this.specialityInput.normValue === 'Medical' && input.normValue !== 'en-US')
          return 'Only US English supported';
      },
      export: (self: InputState) => self.normValue,
      disabled: () => !!this.jobId || this.specialityInput.normValue === 'Medical',
    });

    this.videoTypeInput = input(this, {
      name: 'videoType',
      placeholder: 'Choose an option',
      selectorItems: JobVideoTypeList,
      showStatusMessage: true,
      export: (self: InputState) => self.normValue,
      disabled: () => !!this.jobId
    });

    this.form = inputGroup(this, {
      name: 'languages',
      inputs: [
        this.specialityInput,
        this.languageInput,
        this.levelInput,
        this.videoTypeInput
      ],
      isSubmitDisabled: () => {
        return (
          this.form.isSubmitting ||
          this.form.hasTouchedErrorInputs
        );
      }
    });

    this.file = this.props.file;
    this.init();
  }

  @observable task: UploadTask | null = null;

  readonly form: InputGroupState;
  readonly file: File;

  readonly languageInput: InputState;
  readonly levelInput: InputState;
  readonly specialityInput: InputState;
  readonly videoTypeInput: InputState;


  @computed get isStaged(): boolean {
    return !this.task;
  }

  @computed get isActive(): boolean {
    return !!this.task?.isActive;
  }

  @computed get isCompleted(): boolean {
    return !!this.task?.isCompleted;
  }

  @computed get isFailed(): boolean {
    return !!this.task?.isFailed;
  }

  @computed get showInStaging(): boolean {
    return this.isStaged;
  }

  @computed get inProgress(): boolean {
    return this.isActive || this.isFailed;
  }

  // #region Resolved props
  @computed get teamId(): string | null {
    return this.resolvedProps.teamId;
  }

  @computed get team(): Team | null {
    return this.store.teamManager.getTeam(this.teamId);
  }

  @computed get jobId(): string | null {
    return this.resolvedProps.jobId;
  }
  // #endregion

  @computed get job() {
    return this.store.jobManager.maybeGetJob(this.jobId);
  }

  @computed
  get isSpecialityInputDisabled() {
    return this.levelInput?.value === 'media';
  }

  @action
  submit(): UploadTask {
    const formData = this.form.export();

    const uploadFile: UploadFile = {
      file: this.file,
      languageCode: formData.language || undefined,
      jobLevel: formData.level || undefined,
      jobSpeciality: formData.speciality as JobSpeciality || undefined,
      videoType: formData.videoType as JobType,
    };

    this.task = this.store.uploadService.createTask(uploadFile, {
      teamId: this.teamId || undefined,
      jobId: this.jobId || undefined
    });

    return this.task;
  }

  @action
  init = () => {
    const { job, team } = this;

    //init specialityInput
    if (job && job.medicalSpecialty !== undefined) {
      this.specialityInput.loadValue(SpecialityItems.find(item =>
        item.value === getJobSpecialityInput(this.job || null)
      ));
    } else {
      this.specialityInput.loadValue(this.team?.publicSafety ? 
        'PublicSafety' : 
        DefaultSpecialityItem?.value);
    }

    //init languageInput
    if (job?.languageCode) {
      this.languageInput.loadValue(getLanguageInputItem(job.languageCode));
    } else {
      this.languageInput.loadValue(DefaultLanguageItem);
    }

    //init levelInput
    if (job?.enrichmentLevel) {
      this.levelInput.loadValue(JobLevel.find((value) => value === job.enrichmentLevel));
    } else if (team?.enrichmentLevel) {
      this.levelInput.loadValue(JobLevel.find((value) => value === team.enrichmentLevel));
    } else {
      this.levelInput.loadValue(getDefaultEnrichmentLevel());
    }

    //init videoTypeInput
    if (job?.videoType) {
      this.videoTypeInput.loadValue(JobVideoTypeList.find(item => item.value === job.videoType) ?? JobType.Presentation);
    } else {
      this.videoTypeInput.loadValue(JobType.Presentation);
    }
  }
}

