import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../../components/overlays';
import { Button, DropdownInputControlled } from '../../components/input';
import { TeamProductWindowState } from './teamProductWindowState';

type Props = {
  model: TeamProductWindowState;
  visible?: boolean;
};

export const TeamProductWindow = observer(({ model, visible }: Props) => {
  return (
    <Window model={model.window} visible={visible} isLoading={model.isLoading}>
      <WindowHeader title="Change Library Product" model={model.window} />

      <hr />

      <div className="window-content">
        <DropdownInputControlled
          label="Library Product"
          model={model.teamProductModel}
        />
      </div>

      <hr />

      <footer className="window-footer">
        <div className="btn-group right">
          <Button
            label="Cancel"
            className="primary btn-outline medium"
            onClick={() => model.close()}
            disabled={model.isLoading}
          />

          <Button
            label="Save"
            className="primary medium"
            onClick={() => model.updateTeamProduct()}
            disabled={model.isLoading || !model.teamProductModel.isChanged}
          />
        </div>
      </footer>
    </Window>
  );
});
