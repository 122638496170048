import './playerAdsAdapter.scss';

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { PlayerAdsAdapterState } from './playerAdsAdapterState';

type Props = {
  model: PlayerAdsAdapterState;
}

/**
 * Wrapper around Google IMA SDK which renders the ads in a separate iframe 
 * controlled by the SDK and rendered in a provided container element.
 */
export const PlayerAdsAdapter = observer(({
  model
}: Props) => {

  useEffect(() => {
    model.mounted();
    return () =>
      model.unmounted();
  }, [model]);

  return (
    <div id="player-ads-adapter" ref={model.elementRef.ref}>

    </div>
  );
});