import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { Overlay } from '../../overlays/overlay';
import { useOverlayState } from '../../overlays/overlayState';
import { CheckmarkSVGIcon, PlayerCaptionsSVGIcon, PlayerCaptionsSVGIconOutline } from '../../svg';
import { PlayerState } from '../playerState';
import { action } from 'mobx';
import { useOverlayGroupState } from '../../overlays/overlayGroupState';
import { multiRef } from '../../../store/refProxy';

type Props = {
  model: PlayerState
}

export const CaptionsButton = observer(({
  model
}: Props) => {
  const { playerCaption: controller } = model;

  const transcriptsVisible = model.frameset?.isSectionVisible('Transcripts');
  const captionItems = controller.captionTracks;
  const panelState = useOverlayState({
    visibility: 'OnClick',
    visibilityTrigger: () => (model.chrome.visibility !== 'Visible' ? false : undefined),
    onShow: () => model.chrome.startControlAction(),
    onHide: () => model.chrome.endControlAction()
  });
  const tooltipState = useOverlayState({
    suppressFrom: [panelState]
  });

  const shouldShowCaptions = model.captionsAreEnabled;
  const className = classNames('user-player-control-btn captions-btn', {
    // hover: panelState.anchorIsHovered 
  });

  const overlayGroupState = useOverlayGroupState({
    overlays: [tooltipState, panelState]
  });

  const toggleCaptions = () => {
    if (
      !shouldShowCaptions &&
      transcriptsVisible &&
      model.frameset?.isWidgetMode) {
      model.frameset?.toggleSection('Transcripts');
    }

    controller.toggleCaptionTrack();
    model.invoke('toggleCaptions');
    tooltipState.hide();
  }

  const props = overlayGroupState.anchorProps;

  if (!model.hasMultipleCaptionTracks) {
    Object.assign(props, {
      onClick: toggleCaptions
    })
  }

  const handleItemClick = action((track: TextTrack) => {
    if (
      !shouldShowCaptions &&
      transcriptsVisible &&
      model.frameset?.isWidgetMode) {
      model.frameset?.toggleSection('Transcripts');
    }

    panelState.hide();
    tooltipState.hide();
    controller.activateCaptionTrack(track);
    model.invoke('enableCaptions');
  });

  const handleOffItemClick = action(() => {
    controller.deactivateCaptionTrack();
    model.invoke('disableCaptions');
    panelState?.hide();
  });

  return (
    <button className={className}
      tabIndex={0}
      ref={multiRef(tooltipState.anchorRef, panelState.anchorRef)}
      {...props}>

      <div className="icon">
        {shouldShowCaptions ?
          <PlayerCaptionsSVGIcon /> :
          <PlayerCaptionsSVGIconOutline />}
      </div>

      <Overlay model={tooltipState}
        className="user-player-tooltip">
        Closed Captions
      </Overlay>

      <Overlay
        model={panelState}
        className={`control-panel captions-btn-panel`}>

        <ul
          className="captions-btn-panel-list"
          data-tutorial-id="captions-btn-panel-list"
          role="menu">

          {captionItems.map(track => {
            return (
              <li key={track.language}
                className="captions-btn-panel-item"
                role="option"
                aria-selected={model.activeCaptionItem === track}
                tabIndex={0}
                onClick={() => handleItemClick(track)}>
                <span className="value">
                  {track.label}
                </span>
                <span className="check">
                  <CheckmarkSVGIcon />
                </span>
              </li>
            );
          })}
          <li className="captions-btn-panel-item"
            role="option"
            aria-selected={!model.activeCaptionItem}
            onClick={evt => handleOffItemClick()}>
            <span className="value">
              {"Off"}
            </span>
            <span className="check">
              <CheckmarkSVGIcon />
            </span>
          </li>
        </ul>
      </Overlay >
    </button >
  );
});