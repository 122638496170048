import get from 'lodash/get';
import { DateTime, Duration } from 'luxon';
import { changeCase, isDefined, isDefinedObject, isValue, ObjectLiteral } from '../../core';
import { StringCase } from '../../core/stringSchema';
import { URLQueryParamsSource } from '../../core/urlUtils';
import { ParamInfo, ParamsData, ParamsSchema, ParamType } from './paramsSchema';

type URLValueParamWriteFunc<T extends string | number | boolean = any> = (schema: ParamInfo, val: T) => string | null;

function writeStringParamToUrlValue(schema: ParamInfo, val: string): string {
  return val;
}

function writeBooleanParamToUrlValue(schema: ParamInfo, val: boolean): string {
  return val ? 'true' : 'false';
}

function writeIntParamToUrlValue(schema: ParamInfo, val: number): string {
  // TODO: maybe validate the value
  return val.toString();
}

function writeUIntParamToUrlValue(schema: ParamInfo, val: number): string {
  // TODO: maybe validate the value
  return val.toString();
}

function writeFloatParamToUrlValue(schema: ParamInfo, val: number): string {
  return val.toString();
}

function writeEnumParamToUrlValue(schema: ParamInfo, val: string | number): string {
  return val.toString();
}

function writeDateTimeParamToUrlValue(schema: ParamInfo, val: DateTime): string | null {
  if (!val.isValid)
    return null;
  return val.toISO();
}

function writeDurationParamToUrlValue(schema: ParamInfo, val: Duration): string | null {
  if (!val.isValid)
    return null;
  return val.toISO();
}

const URLValueParamWriteFuncLookup: Record<ParamType, URLValueParamWriteFunc<any>> = {
  [ParamType.String]: writeStringParamToUrlValue,
  [ParamType.Boolean]: writeBooleanParamToUrlValue,
  [ParamType.Int]: writeIntParamToUrlValue,
  [ParamType.UInt]: writeUIntParamToUrlValue,
  [ParamType.Float]: writeFloatParamToUrlValue,
  [ParamType.Enum]: writeEnumParamToUrlValue,
  [ParamType.DateTime]: writeDateTimeParamToUrlValue,
  [ParamType.Duration]: writeDurationParamToUrlValue
};

type Props<TParams extends ParamsData = ParamsData> = {
  schema: ParamsSchema<TParams>;
  caseSensitive?: boolean;
}

/** Typed, general purpose object for writer params to various destinations like URL params, etc. */
export class ParamsWriter<TParams extends ParamsData = ParamsData> {

  constructor(props: Props<TParams>) {
    this.schema = props.schema;
    this.caseSensitive = props.caseSensitive ?? false;
  }

  readonly schema: ParamsSchema<TParams>;
  readonly caseSensitive: boolean;

  private readonly defaultTransformKeyCase = StringCase.LowerCase;

  private transformKey = (key: string): string => {
    if (this.caseSensitive)
      return key;
    return changeCase(key, this.defaultTransformKeyCase);
  }

  private getValueLookup = (data: ObjectLiteral) => {
    // recursive transform all keys
    const tfData: ObjectLiteral = {};
    const keys = Object.getOwnPropertyNames(data);
    for (const key of keys) {
      let val = data[key];
      if (isDefinedObject(val))
        val = this.getValueLookup(val);

      tfData[this.transformKey(key)] = val;
    }

    return tfData;
  }

  writeToQueryString(paramsData: ParamsData): string {
    const queryParams = new URLSearchParams();
    this.writeParamsToQueryParams(queryParams, paramsData);
    return queryParams.toString();
  }

  /**
   * Takes an existing query string and appends the provided params as additional query params.
   * Any existing params on the query string that were also provided on the params data object
   * will be overwritten.
   */
  appendToQueryParams(querySource: URLQueryParamsSource, paramsData: ParamsData): URLSearchParams {
    const queryParams = new URLSearchParams(querySource);
    this.writeParamsToQueryParams(queryParams, paramsData);
    return queryParams;
  }

  private writeParamsToQueryParams(queryParams: URLSearchParams, paramsData: ParamsData): void {

    const { schema } = this;

    const schemaKeys = Object.keys(schema.params);
    const valueLookup = this.getValueLookup(paramsData);

    for (const key of schemaKeys)
      this.writeParamValueToQueryParams(queryParams, valueLookup, key);
  }

  /** 
   * Returns the value of a single param from a pre-computed lookup of the query params,
   * generated using `getQueryLookup`, such that all keys are cased properly. 
   */
  private writeParamValueToQueryParams(queryParams: URLSearchParams, valueLookup: ParamsData, key: keyof TParams & string): void {
    const { schema } = this;
    const paramsSchema = schema.params;
    const paramSchema = paramsSchema[key];
    const { isArray } = paramSchema;

    const write = URLValueParamWriteFuncLookup[paramSchema.type];

    const tfKey = this.transformKey(key);

    let val = get(valueLookup, tfKey);
    if (!isDefined(val)) {

      // also check for alias keys
      const aliasKeys = paramSchema.aliasKeys ?? [];
      for (const aliasKey of aliasKeys) {
        const tfAliasKey = this.transformKey(aliasKey);
        const aliasVal = get(valueLookup, tfAliasKey);

        if (isDefined(aliasVal)) {
          val = aliasVal;
          // also update the output key since this is the intent
          key = aliasKey;
          break;
        }
      }
    }

    if (isArray && Array.isArray(val))
      val = val.join(',');

    if (val === null || val === undefined || val === '')
      return;

    const outVal = write?.(paramSchema, val);

    if (isValue(outVal))
      queryParams.set(key, outVal);
  }
}