import { FormEvent } from 'react';
import { action, makeObservable, observable, runInAction, computed } from 'mobx';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { notifyError, notifySuccess } from '../../services/notifications';
import { ApiVariables } from '../../api/apiSchema';
import { ApiUploadRequestParams } from '../../api';
import { WindowState } from '../overlays';
import { JobModel } from '../../entities';
import { isJobPosterCompatible } from '../../core';
import { UpdateJobInput } from '../../../../../libs/lib/dist';

const FILE_SIZE_IN_MB = 2;
const PHOTO_MAX_SIZE = FILE_SIZE_IN_MB * 1024 * 1024;
const FILE_TYPES = ['image/jpeg', 'image/png'];

export class UploadThumbnailWindowState
  extends StoreNode {

  readonly nodeType = 'UploadThumbnailWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close();
        break;
    }
  }

  @observable isLoading: boolean = true;
  @observable job: JobModel | null = null;

  @observable isHovered: boolean = false;
  @observable isDragHovered: boolean = false;
  @observable dragItem: DataTransferItem | null = null;
  @observable previewUrl: string | null = null;
  @observable thumbnailFile: File | null = null;

  @action
  handlePointerEnter(evt: PointerEvent) {
    this.isHovered = true;
  }
  @action
  handlePointerLeave(evt: PointerEvent) {
    this.isHovered = false;
  }

  @computed
  get isLoadedImage(): boolean {
    return this.previewUrl !== this.job?.posterURL;
  }

  @computed
  get dragError(): boolean {
    return !!this.dragErrorMessage;
  }

  @computed
  get dragErrorMessage(): string | null {
    if (this.dragItem && !isJobPosterCompatible(this.dragItem)) return 'The accepted formats are JPEG and PNG';

    return null;
  }


  @action
  handleDragEnter(evt: DragEvent) {
    this.isDragHovered = true;
    const item = evt.dataTransfer?.items[0];

    if (item) {
      this.dragItem = item;
    }
  }

  @action
  handleDragLeave(evt: DragEvent) {
    this.isDragHovered = false;
  }
  @action
  handleDrop(evt: DragEvent) {
    this.isDragHovered = false;
    this.dragItem = null;
  }

  @action
  handleFileClick(evt: PointerEvent) {
    evt.stopPropagation();
    evt.preventDefault();
  }

  @action
  private handleError(msg: string) {
    this.isLoading = false;
    notifyError(this, msg);
  }

  @action
  async handleFileChange(e: FormEvent<HTMLInputElement>) {
    const target = e.currentTarget;
    const fileList: FileList | null = target.files;
    if (fileList && fileList.length > 0) {
      const [file] = fileList;

      if (file.size >= PHOTO_MAX_SIZE) {
        this.handleError(`Photo size should be smaller than ${FILE_SIZE_IN_MB}Mb.`);
        return;
      }
      if (!FILE_TYPES.includes(file.type)) {
        this.handleError('The accepted formats are JPEG and PNG.');
        return;
      }

      const reader = new FileReader();
      // eslint-disable-next-line
      const url = reader.readAsDataURL(file);

      reader.onloadend = () => this.previewUrl = reader.result as string;

      this.thumbnailFile = file;
    }
  }

  @action
  async submit() {
    if (!this.thumbnailFile || !this.job) return;

    this.isLoading = true;

    const input: ApiVariables<'getJobThumbnailUploadURL'> = {
      input: {
        id: this.job.id,
        filename: this.thumbnailFile.name
      }
    }
    // Get the upload URL
    const [res, err] = await this.store.apiService.getJobThumbnailUploadURL(input);

    if (err || !res) {
      runInAction(() => this.handleError('Could retrieve upload url.'));
      return;
    }

    const params = res.getJobThumbnailUploadURL;
    const uploadParams: ApiUploadRequestParams = {
      file: this.thumbnailFile,
      url: params.uploadUrl,
      data: params.fields
    }

    // Start the upload request
    const uploadRequest = this.store.apiService.uploadRequest(uploadParams);
    const [, uploadErr] = await uploadRequest.start();

    if (uploadErr) {
      runInAction(() => this.handleError('Could not upload.'));
      return;
    }

    const updateParams: UpdateJobInput = {
      id: this.job.id,
      posterToken: params.uploadToken,
    };

    // Update the job postrUrl if the upload was successful and fetch the job
    const [, updateError] = await this.store.jobManager.apiUpdateJob(updateParams, true);
    if (updateError) {
      runInAction(() => this.handleError('Could not update the thumbnail.'));
      return;
    }

    runInAction(() => {
      notifySuccess(this, 'Your thumbnail has been updated.');
      this.emit('ThumbnailUpdated')
      this.close();
      this.isLoading = false;
    })
  }

  @action
  open({ jobId }: { jobId: string }) {
    this.dispatch('Overlays', 'openWindow', { name: 'UploadThumbnailWindow' });

    this.job = this.store.jobManager.getJob(jobId);
    this.previewUrl = this.job.posterURL ?? null;
    this.isLoading = false;
  }

  @action
  close() {
    this.job = null;
    this.previewUrl = null;
    this.thumbnailFile = null;

    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }

}