import './jobs.scss';

import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import { paramCase } from 'change-case';
import { useHistory } from "react-router-dom";
import { LocationDescriptor } from 'history';
import { Link } from '../layout';
import { JobModel } from '../../entities';
import { BackRoutes, BackRouteState, Routes } from '../../routes';
import { ObjectLiteral } from '../../core';
import { MenuButton } from '../menu';
import { ICatalogSource } from '../layout/catalog';
import { IFilterableSource, ToggleLayout } from '../input';
import { MenuItem } from '../input/menuItems';
import { ISortableSource } from '../input/sortFieldBtn';
import { JobTitle } from '../media/jobTitle';
import { getEnrichmentLevelLabel } from '../../entities/job/jobFeatures';
import { useLocation } from 'react-router';
import { TrainerJobCardPreview } from '../media/trainerJobCardPreview';
import { TranscriptFormat } from '@clipr/lib';
import { notifyError } from '../../services/notifications';
import { TranscriptFormatItems } from '../playerTranscripts';
import { getJobSpecialityLabel } from '../../entities/job/jobSpeciality';

type Props = {
  model: JobModel;
  route?: string;
  teamId?: string;
  className?: string | ObjectLiteral;
  source: ICatalogSource<JobModel> & ISortableSource & IFilterableSource;
};

type JobMenuDropdownOptionsProps = {
  model: JobModel;
  teamId?: string;
};

export const TrainerJobTableRow = observer(
  ({
    model,
    teamId,
    source,
    className,
  }: Props) => {
    const isNew = model.createdAtDate.diffNow('hours').as('hours') > -6; // diff will be negative

    const { pathname } = useLocation();
    const trainerPlayerRoute = BackRoutes[Routes.trainerVideo()].route({ id: model.id, teamId, currentPath: pathname });

    let linkRoute: LocationDescriptor<BackRouteState> | null = model.isMediaDone ? trainerPlayerRoute : null;

    if (
      !model.isPublished && !(model.hasPermission('TrainerEditJob') || model.hasPermission('AdminEditJob'))
    ) {
      linkRoute = null;
    }

    className = classNames(
      'job-table-row',
      paramCase(model.status || ''),
      paramCase(model.videoType || ''),
      {
        new: isNew,
        'status-is-unpublish': !model.isPublished
      }
    );

    return (
      <tr className={className}>
        <td
          className={
            'thumb-col ' +
            (source.getSortFieldValue('title') !== 'none' && 'active')
          }>
          <Link to={linkRoute!} className="link fill" title={model.title}>
            <TrainerJobCardPreview model={model} />
          </Link>
        </td>

        <td
          className={
            'title-col ' +
            (source.getSortFieldValue('title') !== 'none' && 'active')
          }>
          <div>
            <Link to={linkRoute!} className="link title" title={model.title}>
              <JobTitle
                title={model.title || 'Title not set'}
                isAudioSource={model.isAudioSource}></JobTitle>
            </Link>
            <div className="flex-left">
              <Link to={linkRoute!} className="link details">
                <span>
                  {model.momentCount}{' '}
                  {model.momentCount === 1 ? `Moment` : `Moments`}
                </span>
              </Link>
              <div className="pipe" />
              <Link to={linkRoute!} className="link details">
                <span>{model.durationToTimeCode('card')}</span>
              </Link>
            </div>
          </div>
          {model.ingestExecutionArn && (
            <a
              href={`https://console.aws.amazon.com/states/home#/executions/details/${model.ingestExecutionArn}`}
              className="btn btn-core link link-secondary check-progress uppercase bold"
              target="_blank"
              rel="noopener noreferrer">
              Check progress &rarr;
            </a>
          )}
        </td>

        <td
          className={
            'level-col' + (!!source.getFilterFieldValue('status') && 'active')
          }>
          <Link to={linkRoute!} className="link fill">
            <span className="uppercase">
              {model.enrichmentLevel &&
                getEnrichmentLevelLabel(model.enrichmentLevel)}
            </span>
          </Link>
        </td>

        <td
          className={
            'status-col ' + (!!source.getFilterFieldValue('status') && 'active')
          }>
          <Link to={linkRoute!} className="link fill">
            <span className="uppercase">{model.status}</span>
          </Link>
        </td>

        <td
          className={
            'type-col ' +
            (!!source.getFilterFieldValue('speciality') && 'active')
          }>
          <Link to={linkRoute!} className="link fill">
            <span>{(model.speciality && getJobSpecialityLabel(model.speciality)) || 'Not set'}</span>
          </Link>
        </td>

        <td
          className={
            'uploaded-by ' +
            (source.getSortFieldValue('uploaderName') !== 'none' && 'active')
          }>
          <Link
            to={linkRoute!}
            className="link fill"
            title={model.user?.username}>
            <span>{model.user?.username}</span>
          </Link>
        </td>

        <td
          className={
            'upload-col ' +
            (source.getSortFieldValue('createdAt') !== 'none' && 'active')
          }>
          <Link
            to={linkRoute!}
            className="link fill"
            title={model.createdAtLabel}>
            <span>
              {model.createdAtDate.toLocaleString(DateTime.DATE_MED)}{' '}
              {model.createdAtDate.toLocaleString(DateTime.TIME_SIMPLE)}
            </span>
          </Link>
        </td>

        <td
          className={
            'owner-col ' +
            (source.getSortFieldValue('ownerName') !== 'none' && 'active')
          }>
          <Link
            to={linkRoute!}
            className="link fill"
            title={model.displayOwnerName}>
            <span>{model.displayOwnerName}</span>
          </Link>
        </td>

        <td className="team-col">
          <div className="teams">
            {model.foundInTeams?.map((teamStub) => (
              <Link
                key={teamStub.id}
                to={Routes.teamDashboard(teamStub.id)}
                className="team-name btn btn-core link link-primary">
                {teamStub.name || teamStub.id}
              </Link>
            ))}
          </div>
        </td>

        <td className="options-col">
          {model.hasPermission('UserViewJobMenu') && (
            <MenuButton>
              <JobMenuDropdownOptions model={model} teamId={teamId} />
            </MenuButton>
          )}
        </td>
      </tr>
    );
  }
);

const JobMenuDropdownOptions = observer(
  (props: JobMenuDropdownOptionsProps) => {
    const { model, teamId } = props;

    if (model.isMediaFailed) {
      return (
        <>
          {model.ingestError && model.ingestError === 'DownloadFailed' && (
            <>
              <MenuItem
                isSelected={false}
                model={{ value: 'Replace video', label: 'Replace video' }}
                onClick={(evt) => model.store.goTo(Routes.jobReplace(model.id))}
              />

              <MenuItem
                isSelected={false}
                model={{
                  value: 'View video information',
                  label: 'View video information',
                }}
                onClick={(evt) =>
                  model.dispatch('openVideoInformationWindow', {
                    jobId: model.id,
                  })
                }
              />
            </>
          )}

          <MenuItem
            isSelected={false}
            model={{
              value: 'Delete',
              label: 'Delete',
            }}
            onClick={(evt) =>
              model.dispatch('openDeleteJobWindow', {
                jobId: model.id,
                teamId: teamId,
              })
            }
          />
        </>
      );
    }

    if (!model.isPublished) {
      return (
        <>
          <MenuItem
            isSelected={false}
            model={{
              value: 'Delete',
              label: 'Delete',
            }}
            onClick={(evt) =>
              model.dispatch('openDeleteJobWindow', {
                jobId: model.id,
                teamId: teamId,
              })
            }
          />
          {(model.hasPermission('TrainerEditJob') || model.hasPermission('AdminEditJob')) && (
            <MenuItem
              isSelected={false}
              model={{
                value: model.isPublished ? 'Unpublish' : 'Publish',
                label: model.isPublished ? 'Unpublish' : 'Publish',
              }}
              onClick={() =>
                model.dispatch('openJobViewStatusWindow', {
                  jobId: model.id,
                  teamId: teamId,
                  isPublished: model.isPublished,
                })
              }
            />
          )}
        </>
      );
    }

    const history = useHistory();

    const showOpenInConsumer = model.isMediaAccessible;

    const { pathname } = useLocation();
    const consumerPlayerRoute = BackRoutes[Routes.userVideoLanding()].route({ id: model.id, currentPath: pathname });

    const onDownloadTranscript = async (format: string) => {
      const args = {
        format: format as TranscriptFormat
      };

      const [res, err] = await model.apiGetJobTranscript(args);

      if (!res || err) {
        return notifyError(model, 'Transcript download failed.');
      }

      return res;
    }

    return (
      <>
        {
          showOpenInConsumer &&
          <MenuItem
            isSelected={false}
            model={{ value: 'Open in Consumer Player', label: 'Open in Consumer Player' }}
            onClick={() => {
              history.push(consumerPlayerRoute);
            }}
          />
        }
        {
          model.hasPermission('EditVideoDetails') && (
            <MenuItem
              isSelected={false}
              model={{ value: 'Video Details', label: 'Video Details' }}
              onClick={(evt) => {
                model.dispatch('openVideoDetailsWindow', {
                  jobId: model.id,
                  // teamId: teamId,
                  layoutType: 'full',
                });
              }}
            />
          )
        }

        <MenuItem
          isSelected={false}
          model={{
            value: 'Rename',
            label: 'Rename',
          }}
          onClick={(evt) =>
            model.dispatch('openRenameJobWindow', {
              page: 'Trainer',
              jobId: model.id,
              title: model.title,
            })
          }
        />

        <MenuItem
          isSelected={false}
          model={{ value: 'Update thumbnail', label: 'Update thumbnail' }}
          onClick={(evt) => {
            model.dispatch('openUploadThumbnailWindow', {
              jobId: model.id,
            });
          }}
        />

        <MenuItem
          isSelected={false}
          model={{ value: 'Download transcript', label: 'Download transcript' }}
          onClick={(evt) => {
            model.dispatch('openDownloadTranscriptWindow', {
              message: 'Transcript format',
              onSubmit: (format: string) => onDownloadTranscript(format),
              formatItems: TranscriptFormatItems,
              title: 'Download Transcript'
            });
          }} />

        {model.slideDeckUrl && 
          <MenuItem
            isSelected={false}
            model={{ value: 'Download slides', label: 'Download slides' }}
            onClick={(evt) => {
              model.dispatch('openDownloadJobSlidesWindow', {
              jobId: model.id
              });
            }} />
        }

        {
          model.hasPermission('EditIsPublic') && (
            <MenuItem
              model={{ value: 'Visibility', label: 'Visibility' }}
              isSelected={model.isPublic}
              withToggle
              toggleOptions={{ toggleLayout: ToggleLayout.Buttons, toggledLabel: 'Shareable', notToggledLabel: 'Private' }}
              toggleModel={model.publicToggleModel}
              disabled={model.isPublishing}
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                model.handlePublishVideo();
              }}
            />
          )
        }

        {
          model.isDone && (
            <MenuItem
              isSelected={false}
              model={{
                value: 'Make a copy',
                label: 'Make a copy',
              }}
              onClick={(evt) =>
                model.dispatch('openCopyJobWindow', {
                  jobId: model.id,
                  teamId: teamId,
                })
              }
            />
          )
        }

        {model.store.user?.hasPermission('UserCanAddToTeams') && (<MenuItem
          isSelected={false}
          model={{
            value: 'Add to library',
            label: 'Add to library',
          }}
          onClick={(evt) =>
            model.dispatch('openAddJobToTeamWindow', {
              jobId: model.id,
            })
          }
        />)}

        {
          teamId && (
            <MenuItem
              isSelected={false}
              model={{
                value: 'Remove from library',
                label: 'Remove from library',
              }}
              onClick={(evt) =>
                model.dispatch('openRemoveJobFromTeamWindow', {
                  jobId: model.id,
                  teamId: teamId,
                })
              }
            />
          )
        }

        {
          model.hasPermission('ChangeOwner') && (
            <MenuItem
              isSelected={false}
              model={{
                value: 'Change owner',
                label: 'Change owner',
              }}
              onClick={(evt) =>
                model.dispatch('openChangeJobOwnerWindow', {
                  jobId: model.id,
                })
              }
            />
          )
        }

        {
          model.hasPermission('DeleteJob') && (
            <MenuItem
              isSelected={false}
              model={{
                value: 'Delete',
                label: 'Delete',
              }}
              onClick={(evt) =>
                model.dispatch('openDeleteJobWindow', {
                  jobId: model.id,
                  teamId: teamId,
                })
              }
            />
          )
        }

        {
          (model.hasPermission('TrainerEditJob') || model.hasPermission('AdminEditJob')) && (
            <MenuItem
              isSelected={false}
              model={{
                value: model.isPublished ? 'Unpublish' : 'Publish',
                label: model.isPublished ? 'Unpublish' : 'Publish',
              }}
              onClick={() =>
                model.dispatch('openJobViewStatusWindow', {
                  jobId: model.id,
                  teamId: teamId,
                  isPublished: model.isPublished,
                })
              }
            />
          )
        }
      </>
    );
  }
);
