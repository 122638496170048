import { createMessage, StoreNode } from '../../store'

export const notify = (node: StoreNode, message: string) => {
  const msg = createMessage(node, 'dispatch', 'notify', message);
  msg.target = 'NotificationService';
  node.store.nodeDispatch(msg);
}

export const notifySuccess = (node: StoreNode, message: string) => {
  const msg = createMessage(node, 'dispatch', 'notifySuccess', message);
  msg.target = 'NotificationService';
  node.store.nodeDispatch(msg);
}

export const notifyError = (node: StoreNode, message: string) => {
  const msg = createMessage(node, 'dispatch', 'notifyError', message);
  msg.target = 'NotificationService';
  node.store.nodeDispatch(msg);
}

export const notifyLoading = (node: StoreNode, message: string) => {
  const msg = createMessage(node, 'dispatch', 'notifyLoading', message);
  msg.target = 'NotificationService';
  node.store.nodeDispatch(msg);
}

export const notifyWarning = (node: StoreNode, message: string) => {
  const msg = createMessage(node, 'dispatch', 'notifyWarning', message);
  msg.target = 'NotificationService';
  node.store.nodeDispatch(msg);
}