import './playerWidget.scss';

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { LoadMask, Player } from '../../components';
import { OverlaysChunk } from '../../services/overlays';
import { NotificationsChunk } from '../../services/notifications';
import { PlayerWidgetState } from './playerWidgetState';
import { WidgetErrorPage } from '../../pages/error';
import { PlayerFrameset } from '../../components/playerFrameset/playerFrameset';
import { PlayerIndex } from '../../components/playerIndex/playerIndex';
import { PlayerTranscripts } from '../../components/playerTranscripts/playerTranscripts';
import { SectionElement } from '../../components/playerFrameset/frameWrapper';
import { PlayerComments } from '../../components/playerComments';
import { useRouteContext } from '../../routes/routeHooks';
import { AuthFlowResponseType } from '../../services/auth/authFlowSchema';
import { AuthFlowResponseInterpreter } from '../../components/auth';
import { WidgetInlineAuthFlowMask } from '../../components/widgets/widgetInlineAuthFlowMask';
import { useAuthContext } from '../../services/auth/authHooks';

import { TRACE } from '../../core/debug/debugMacros';

type Props = {
  model: PlayerWidgetState
};

export const PlayerWidget = observer(({
  model
}: Props) => {

  TRACE(model, `render()`);

  const routeContext = useRouteContext();
  useEffect(() => {
    model.attached(routeContext);
    return () =>
      model.detached();
  }, [model, routeContext]);

  const authContext = useAuthContext();
  if (model.error) {
    return <WidgetErrorPage
      error={model.error}
      showBackButton={authContext?.allowActions} />;
  }

  const { inlineAuthFlowController } = model;
  const { response: flowResponse } = model.inlineAuthFlowController;
  if (flowResponse) {
    switch (flowResponse.responseType) {
      case AuthFlowResponseType.Authorized:
      case AuthFlowResponseType.AuthorizedAsAnonymous:
      case AuthFlowResponseType.Deauthorized:
        break;

      default:
        TRACE(model, `No inline response available, using the flow interpreter`, { flowResponse });
        return <AuthFlowResponseInterpreter response={flowResponse} />
    }
  }

  const { frameset } = model;

  const player =
    <Player
      model={model.player} />;

  const transcripts: SectionElement = (args) => {
    return (
      <PlayerTranscripts
        model={model.transcriptsSection}
        layout={args.frame === 'RightTranscripts' ? 'vertical' : 'horizontal'}
        showHeader={args.frame === 'RightTranscripts'}
        onClose={() => frameset.hideSection('Transcripts')} />
    );
  }

  const index = (
    <PlayerIndex
      model={model.indexSection}
      onClose={() => frameset.hideSection('Index')} />
  );

  const comments = (
    <PlayerComments
      player={model.player}
      model={model.commentsSection}
      onClose={() => frameset.hideSection('Comments')} />
  );

  const className = classNames('player-widget', {
    'speaker-id-mode': model.indexSection.isSpeakerIdMode
  });

  return (
    <div className={className} id="player-widget">

      <PlayerFrameset
        model={model.frameset}
        player={player}
        transcripts={transcripts}
        index={index}
        comments={comments} />

      <OverlaysChunk />
      <NotificationsChunk />

      <WidgetInlineAuthFlowMask
        controller={inlineAuthFlowController} />

      <LoadMask
        className={`dark widget-load-mask`}
        visible={model.isLoading} />
    </div>
  )
});

export default PlayerWidget;