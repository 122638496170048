import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { ExpandToggle } from '../../pages/uploadPage/sharedComponents/expandToggle';

type TeamDetailsDrawerProps = PropsWithChildren<{
  isExpanded: boolean;
  onExpand?: () => void;
  isExpandable?: boolean;
  header: any; //string | Element
  description?: string;
  note?: string;
  className?: string;
  persistentDescription?: boolean;
}>;

export const TeamDetailsDrawer = observer(({
  isExpanded,
  onExpand,
  isExpandable = true,
  header,
  description,
  className,
  persistentDescription,
  note,
  ...props
}: TeamDetailsDrawerProps) => {
  className = classNames("team-details-drawer", className, {
    expanded: isExpanded,
    "persistent-description": persistentDescription
  });

  if (!isExpandable)
    onExpand = undefined;

  return <div className={className}>
    <header className="drawer-header" onClick={onExpand}>
      <div className="header">{header}</div>
      {isExpandable && <ExpandToggle
        className="drawer-expand-toggle"
        isExpanded={isExpanded} />}
    </header>
    {description && persistentDescription &&
      <div className="drawer-description" onClick={onExpand}>
        {description}
        {note && <div className='drawer-note'>{note}</div>}
      </div>}
    <div className="drawer-content expandable"
      aria-hidden={!isExpanded}>
      {description && !persistentDescription &&
        <div className="drawer-description">
          {description}
          {note && <div className='drawer-note'>{note}</div>}
        </div>}
      {props.children}
    </div>
  </div>
});