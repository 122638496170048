import { action, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { ProxyFlowName, ProxyFlowResponse, ProxyFlowResponseData } from './proxySchema';
import { getAbsoluteUrl } from '../routing/routingUtils';
import { ProxyQueryParams, Routes } from '../../routes';
import { Error } from '../../core/error';
import { AsyncResult, isNonEmptyString, PromiseRelay, Result, sanitize } from '../../core';
import { createAuthPermit } from '../auth/authPermit';
import { LibraryName } from '../libraries/librarySchema';
import { ProxyServerHandle } from './proxyServerHandle';
import { LibraryPermit } from '../libraries/libraryPermit';

import { INIT_DEBUGGER, TRACE } from '../../core/debug/debugMacros';

let IdCursor = 0;

export class ProxyServerConnection
  extends StoreNode {

  readonly nodeType = 'ProxyServerConnection';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    INIT_DEBUGGER(this, {
      id: (IdCursor++).toString()
    });
    TRACE(this, `constructor()`);
  }

  @observable flowName: ProxyFlowName | null = null;

  private readonly promiseRelay = new PromiseRelay<Result<ProxyFlowResponse>>();
  get promise(): Promise<Result<ProxyFlowResponse>> {
    return this.promiseRelay.promise;
  }

  readonly handle = new ProxyServerHandle();

  @action
  async runAuthorizeFlow()
    : AsyncResult<ProxyFlowResponse<ProxyFlowName.Authorize>> {

    TRACE(this, `runAuthorizeFlow()`);

    const flowName = ProxyFlowName.Authorize;
    this.flowName = flowName;

    const authConnection = this.store.widgetService.authConnection ?? undefined;
    const params: ProxyQueryParams = sanitize({
      authConnection
    });

    const url = getAbsoluteUrl(Routes.proxyAuthorize(params));
    const [msg, err] = await this.handle.run({
      url,
      flowName
    });

    if (err)
      return this.setError(err);

    const msgPayload = msg?.payload ?? {};
    const resData = msgPayload?.data ?? {};
    const resPermit = resData?.permit ?? {};

    const {
      idToken,
      idTokenPayload
    } = resPermit;

    const [permit, permitErr] = createAuthPermit({
      idToken,
      idTokenPayload
    });

    if (permitErr)
      return this.setError(permitErr);

    return this.setResolved(flowName, {
      permit: permit!
    });
  }

  @action
  async runAuthorizeLibraryFlow(libraryName: LibraryName)
    : AsyncResult<ProxyFlowResponse<ProxyFlowName.AuthorizeLibrary>> {

    TRACE(this, `runAuthorizeLibraryFlow()`, libraryName);

    const flowName = ProxyFlowName.AuthorizeLibrary;
    this.flowName = flowName;

    const libraryProvider = this.store.libraryService.getProviderByName(libraryName);
    const url = getAbsoluteUrl(libraryProvider.authUrl);

    const [msg, err] = await this.handle.run({
      url,
      flowName
    });

    if (err)
      return this.setError(err);

    const msgPayload = msg?.payload ?? {};
    const resFlowName = msgPayload.flowName;
    const resData = msgPayload.data ?? {};
    const resLibraryPermit = resData.libraryPermit;
    const resLibraryName = resLibraryPermit.libraryName;
    const resCode = resLibraryPermit?.code;

    if (
      flowName !== resFlowName ||
      libraryProvider.libraryName !== resLibraryName ||
      !isNonEmptyString(resCode))
      return [null, new Error('InternalError', `Received an invalid message from the proxy flow server.`)];

    const libraryPermit = new LibraryPermit({
      code: resCode,
      libraryName: resLibraryName
    });

    return this.setResolved(flowName, {
      libraryPermit: libraryPermit
    });
  }

  async runDeauthorizeFlow()
    : AsyncResult<ProxyFlowResponse<ProxyFlowName.Deauthorize>> {

    TRACE(this, `runDeauthorizeFlow()`);

    const flowName = ProxyFlowName.Deauthorize;
    this.flowName = flowName;

    const url = getAbsoluteUrl(Routes.proxyDeauthorize());
    const [, err] = await this.handle.run({
      url,
      flowName
    });

    if (err)
      return this.setError(err);

    return this.setResolved(flowName, {});
  }

  abort(): void {
    this.handle.abort();
  }

  focus(): Result<boolean> {
    return this.handle.focus();
  }

  @action
  private close() {

  }

  private setResolved<T extends ProxyFlowName>(flowName: T, data: ProxyFlowResponseData<T>)
    : Result<ProxyFlowResponse<T>> {

    const flowRes: ProxyFlowResponse<T> = {
      flowName,
      data
    }

    this.promiseRelay.resolve([flowRes]);
    this.close();
    return [flowRes];
  }

  private setError(err: Error): Result {
    this.promiseRelay.resolve([null, err]);
    this.close();
    return [null, err];
  }
}