import './speakerId.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { Button, CheckboxInputControlled } from '../input';
import { SpeakerIdConfirmationWindowState } from './speakerIdConfirmationWindowState';
import classNames from 'classnames';

type Props = {
  visible?: boolean | null;
  model: SpeakerIdConfirmationWindowState;
};

export const SpeakerIdConfirmationWindow = observer((props: Props) => {

  const { model } = props;
  const speakerCount = model.targetCountDisplay;

  return (
    <Window
      model={model.window}
      visible={props.visible ?? false}
      className="speaker-id-confirmation-window">
      <WindowHeader
        model={model.window} />

      <section className="window-content">
        <div className="content-info">
          <div className="heading-1">Speaker ID</div>
          <div className="heading-2">Help up make the clip better!</div>
          <div className="info-text">
            {"We've found "}
            <span className="bold">{speakerCount}</span>
            {" in this video and would appreciate your help to identify them."}
          </div>
        </div>

        <div className="content-actions">
          <div className="btn-group ">
            <Button
              label={'Maybe Later'}
              className={classNames("medium", {
                red: true,
                'btn-outline': true
              })}
              onClick={() => model.cancel()} />
            <div className="hpad4" />
            <Button
              label={'Yes, I want to help!'}
              className={classNames("medium", {
                green: true,
                'btn-outline': true,
                primary: true
              })}
              onClick={() => model.submit()} />
          </div>
        </div>
      </section>

      {model.showVisibilityCheck && <>
        <hr />

        <footer className="window-footer">
          <CheckboxInputControlled
            model={model.showSpeakerIdConfirmation}
            name="playerTutorial"
            label={'Don’t show this next time'} />
        </footer>
      </>}
    </Window>
  );
});
