import React from 'react';
import { observer } from 'mobx-react-lite';
import { TeamSettingsPageState } from '../../pages/teamSettingsPage/teamSettingsPageState';
import classNames from 'classnames';
import { ArrowDownSVGIcon } from '../svg';
import { Button } from '../input/button';
import { InvitationByOption, Order, TeamMembersByOption } from '@clipr/lib';

type SortButtonProps = {
  model: TeamSettingsPageState;
  sortField: TeamMembersByOption | InvitationByOption;
  label: string;
};

export const SortButton = observer(
  ({ model, label, sortField }: SortButtonProps) => {
    const source = model.activeTab === 'Active' ?
      model.teamMemberCatalogSource : model.teamInviteCatalogSource;

    const isSortedBy = source.sortField === sortField;

    const value = isSortedBy ? source.sortOrder : 'none';
    let newValue: Order | 'none' = 'asc';
    const className = classNames('sort-field-btn sort', value);

    if (isSortedBy) {
      switch (value) {
        case 'asc':
          newValue = 'desc';
          break;
        case 'desc':
          newValue = 'none';
          break;
        default:
          newValue = 'asc';
      }
    }
    return (
      <Button
        className={className}
        aria-current={isSortedBy && value !== 'none'}
        onClick={(evt) =>
          source.setSortFieldValue(sortField, newValue)
        }
        label={label}
        rightIcon={< ArrowDownSVGIcon />}></Button >
    );
  }
);