import { Cookie, StorageValue } from './storageSchema';
import Cookies from 'js-cookie';
import { Maybe } from '../../core';
import { Config } from '../../config';

const TEST_KEY = Config.storage.testKey;
const TEST_VALUE = Config.storage.testValue;

export function isStorageSupported(storage: Maybe<Storage>): boolean {
  if (!storage)
    return false;

  try {
    storage.setItem(TEST_KEY, TEST_VALUE);
    const val = storage.getItem(TEST_KEY);
    storage.removeItem(TEST_KEY);

    return val === TEST_VALUE;
  } catch (err) {
    return false;
  }
}

export function isLocalStorageSupported(target?: Maybe<Window>) {

  try {
    const storage = target ? target.localStorage : localStorage;
    return isStorageSupported(storage);
  } catch (err) {
    return false;
  }
}


export function isCookieSupported() {
  try {
    const success = setCookie({
      name: TEST_KEY,
      value: TEST_VALUE
    }, true);

    removeCookie(TEST_KEY);
    return success;
  } catch (e) {
    return false;
  }
}

export function toStorageValue(val: StorageValue | null, encode = true): string {
  if (typeof val === 'string' || !encode)
    return val ? val.toString() : '';

  return JSON.stringify(val);
}

export function fromStorageValue(val: string | null, decode = true): StorageValue | null {
  if (!decode || !val)
    return val;

  try {
    return JSON.parse(val);
  } catch (err) {
    return val;
  }
}

export function setCookie(cookie: Cookie, verify = false): boolean {

  const { name, value } = cookie;
  Cookies.set(name, value, {
    path: cookie.path ?? undefined,
    domain: cookie.domain ?? undefined,
    secure: cookie.secure ?? true,
    sameSite: cookie.sameSite ?? 'None'
  });

  if (!verify)
    return true;

  // verify the cookie has been set
  const currCookie = Cookies.get(name);
  return currCookie === value;
}

/** 
 * Helper for setting cookies based on object literals.
 * Also supports checking to see if all the cookies have been actually set.
 */
export const setCookies = (cookies: Cookie[], verify = false): boolean => {

  let success = true;
  for (let cookie of cookies) {
    let res = setCookie(cookie, verify);
    if (!res)
      success = false;
  }

  return success;
};

export function removeCookie(name: string) {
  return Cookies.remove(name);
}

export function removeCookies(names: string[]) {
  for (let name of names)
    removeCookie(name);
}




