import { computed, makeObservable, observable } from 'mobx';
import { StoreNode } from '../../store';
import { Store } from '../../store/store';
import { CloudNode, CloudNodeType } from './cloudNode';
import { CloudGraph } from './cloudGraph';
import { SimulationLinkDatum } from 'd3';

export interface Props {
  graph: CloudGraph,
  sourceId: string,
  targetId: string
}

export type CloudEdgeType =
  'TopicSpeaker' |
  'TopicTopic' |
  'SpeakerTopic' |
  'SpeakerSpeaker';

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
export class CloudEdge<TSourceType extends CloudNodeType = 'Unknown', TTargetType extends CloudNodeType = 'Unknown'>
  extends StoreNode
  implements SimulationLinkDatum<CloudNode> {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.graph = props.graph;
    this.sourceId = props.sourceId;
    this.targetId = props.targetId;
  }

  get key() {
    return `(${this.sourceId},${this.targetId})`;
  }

  readonly sourceId: string;
  readonly targetId: string;
  readonly graph: CloudGraph;

  @computed
  get sourceNode(): CloudNode | null {
    return this.graph.getNode(this.sourceId);
  }
  @computed
  get source(): CloudNode {
    return this.sourceNode!;
  }

  @computed
  get targetNode(): CloudNode | null {
    return this.graph.getNode(this.targetId);
  }
  @computed
  get target(): CloudNode {
    return this.targetNode!;
  }

  @computed
  get isVisible() {
    const src = this.sourceNode;
    const tgt = this.targetNode;
    if (!src || !tgt)
      return false;

    return !!(src.isVisible && tgt.isVisible);
  }

  @computed
  get isAccented() {
    const src = this.sourceNode;
    const tgt = this.targetNode;
    if (!src || !tgt)
      return false;

    return !!(
      this.isVisible && (
        (src.isSelected && tgt.isAccented) ||
        (src.isAccented && tgt.isSelected)));
  }

  @computed
  get isSelected() {
    return !!(this.sourceNode?.isSelected && this.targetNode?.isSelected);
  }

  @computed
  get isExpanded() {
    return !!(this.sourceNode?.isExpanded && this.targetNode?.isExpanded);
  }

  strength = 1;
  distance = 1;

  @observable disableTransitions = false;
}