import './media.scss';

import classNames from 'classnames';
import { LocationDescriptor } from 'history';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, useRef } from 'react';

import Routes, { BackRoutes } from '../../routes';
import { Maybe } from '../../core';
import { JobModel } from '../../entities';
import { Link } from '../layout';
import { getMetadataInputLabel, JobMetadataField } from '../../entities/job/jobMetadata';
import { JobTitle } from './jobTitle';
import { JobListCardPreview } from './jobListCardPreview';
import { LibraryTag } from './libraryTag';
import { LiveSVGIcon } from '..';
import { ellipseText } from '../utils';
import { MenuButton } from '../menu';
import { JobMenuDropdownOptions } from '.';
import { AiTag } from './aiTag';

type Props = {
  model: JobModel;
  route?: LocationDescriptor;
  aspectRatio?: Maybe<number>;
  teamId?: string;
};

const CardMetadataSection = [
  'startTime',
  'endTime',
  'track',
  'level',
  'session',
  'featured',
  'publishDate',
  'location'
] as JobMetadataField[];

export const JobListCard = observer(
  ({
    model,
    teamId,
    route = BackRoutes[Routes.userVideo()].route({ id: model.id, teamId }),
    aspectRatio = 9 / 16,
  }: Props) => {
    const previewStyle: CSSProperties = {
      ['--aspect-ratio' as any]: aspectRatio,
    };
    const { widgetService } = model.store;
    const { isWidgetMode } = widgetService;

    let linkRoute: LocationDescriptor | null = null;
    if (model.isMediaAccessible && model.isPublished) {
      linkRoute = isWidgetMode
        ? Routes.playerWidget(model.id, {
          teamId
        })
        : route;
    }

    const cx = classNames('job-card-new', 'list-card-container', {
      'status-not-ingested': !model.status,
      'status-done': model.isDone && model.isPublished && model.isMediaDone,
      'status-is-ingesting': model.isInProgress || model.isMediaProcessing,
      'status-is-analyzing': model.isProcessing,
      'status-is-failed': model.isFailed || model.isMediaFailed,
      'status-is-unpublish': !model.isPublished,
    });

    return (
      <>
        <div className={cx}
          data-job-id={model.id}
          data-job-status={model.testStatus}>
          <Link
            to={linkRoute as any}
            className="card-preview player-container"
            style={previewStyle}>
            <JobListCardPreview 
              model={model} />
          </Link>
          <div className="details-container">
            <JobDetails
              model={model}
              linkRoute={linkRoute}
              teamId={teamId} />
          </div>
        </div>

        <hr className="job-card-divider" />
      </>
    );
  }
);

type JobDetailsProps = {
  model: JobModel;
  linkRoute: LocationDescriptor | null;
  teamId?: string;
}

export const JobDetails = observer(({ model, linkRoute, teamId }: JobDetailsProps) => {
  const { metadata } = model;
  const team = (teamId && model.store.teamManager.getTeam(teamId)) || null;

  const menuRef = useRef<HTMLDivElement>(null);
  const source = model.source.externalLibrary?.source;
  const isNew = model.createdAtDate.diffNow('hours').as('hours') > -6; // diff will be negative
  const isLive = model.isLiveStreaming;
  const isLiveWaiting = model.isLiveStreamWaiting;
  const showTags = isNew || isLive || isLiveWaiting || source;

  return (
    <>
      <div className="job-tags">

        {isLiveWaiting &&
          <div className="job-card-live-tag">
            <LiveSVGIcon />
            <span>LIVE</span>
          </div>}

        {isLive &&
          <div className="job-card-live-tag">
            <LiveSVGIcon />
            <span>LIVE NOW</span>
          </div>}

        {isNew && <div className="job-card-new-tag">New</div>}

        <LibraryTag model={model} />
        <AiTag 
          model={model}
          teamId={teamId} />

        <span className={`${'card-summary'} ${!showTags ? 'left' : ''}`}>
          {model.createdAtRelativeLabel}
        </span>
      </div>

      <Link to={linkRoute as any} className="card-title bmar4">
        <JobTitle
          isAudioSource={model.isAudioSource}
          title={model.title}></JobTitle>
      </Link>

      <Link to={(model.description ? linkRoute : null) as any} className="card-description">
        {ellipseText(model.description, 280)}
      </Link>

      {metadata && model.hasMetadata &&
        <Link to={null as any} className="card-metadata">
          {CardMetadataSection.map((item, i) => {
            if ((team && !team.visibleMetadataFields?.includes(item)) || !metadata[item])
              return null;

            return <div className="metadata-block" key={i}>
              <h6>{team?.getMetadataFieldAlias(item) ?? getMetadataInputLabel(item)}</h6>
              <p>{metadata[item]}</p>
            </div>
          })}
        </Link>}

      <div className="card-menu" ref={menuRef}>
        {!model.isMediaProcessing && model.hasPermission('UserViewJobMenu') && (
          <MenuButton layout="horizontal">
            <JobMenuDropdownOptions model={model} teamId={teamId} />
          </MenuButton>
        )}
      </div>
    </>)
});




