import { makeObservable, observable, action, computed } from 'mobx';
import { TeamPerformanceChartData, TeamPerformanceChartInternalParams, TeamPerformanceChartMode, TeamPerformanceChartParams, TeamPerformanceDataSource } from '.';
import { BindingProps, StoreNode } from '../../../store';
import { Store } from '../../../store/store';
import { input, InputState } from '../../input';

type Props = BindingProps<{
  params?: TeamPerformanceChartParams
}>

export class TeamPerformanceChartState
  extends StoreNode {

  readonly nodeType = 'TeamPerformanceChartState';
  readonly chartTypeInput: InputState;

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.chartTypeInput = input(this, {
      name: 'audienceFocusTypes',
      onChange: this.handleChartTypeChange,
      selectorItems: [
        { value: TeamPerformanceChartMode.Uploads, label: 'Uploads' },
        { value: TeamPerformanceChartMode.Edits, label: 'Edits' },
        { value: TeamPerformanceChartMode.Bookmarks, label: 'Bookmarks' },
        { value: TeamPerformanceChartMode.Reactions, label: 'Reactions' },
      ],
    });

    this.chartTypeInput.loadValue(TeamPerformanceChartMode.Uploads);
  }

  // #region Props
  @computed get params(): TeamPerformanceChartParams | null {
    return this.getResolvedProp('params') ?? null;
  }
  set params(val: TeamPerformanceChartParams | null) {
    this.setProp('params', val);
  }
  // #endregion

  readonly internalParams: TeamPerformanceChartInternalParams = observable({
    mode: TeamPerformanceChartMode.Uploads
  })

  @computed get outputParams(): TeamPerformanceChartParams {
    const outParams = {
      ...this.internalParams,
      ...this.params
    };

    Object.freeze(outParams);
    return outParams;
  }

  @computed get outputData(): TeamPerformanceChartData {
    return [...this.dataSource.data];
  }

  readonly dataSource = new TeamPerformanceDataSource(this.store, {
    params: () => this.outputParams
  });

  @observable isMounted = false;

  async mounted() {
    this.isMounted = true;
  }

  unmounted() {
    this.isMounted = false;
  }

  @action
  private handleChartTypeChange = async (input: InputState, evt: React.ChangeEvent) => {

    const mode = input.value as TeamPerformanceChartMode;
    if (!mode)
      return;
    this.internalParams.mode = mode;
    this.dataSource.fetch();
  }

  async fetch() {
    return this.dataSource.fetch();
  }
  abort() {
    this.dataSource.cancel(); 
  }
}