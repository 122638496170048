import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import identity from 'lodash/identity';
import sortBy from 'lodash/sortBy';

import { ApiResponse, ApiVariables } from '../../../api/apiSchema';
import { AsyncResult, Result } from '../../../core';
import { BindingProps, StoreNode } from '../../../store';
import { IChartDataSource } from '../chartSchema';
import { ApiResult, ApiRequestOptions } from '../../../api/apiSchema';
import { Store } from '../../../store/store';
import { Error, error } from '../../../core/error';
import { parseKeenJsonResult } from '../utils/parseKeenJsonResult';
import { ApiError } from '../../../api/apiError';
import { 
  TeamPerformanceChartData, 
  TeamPerformanceChartDatum, 
  TeamPerformanceChartMode, 
  TeamPerformanceChartParams, 
  TeamPerformanceChartSeriesData 
} from '.';

type Props = BindingProps<{
  params: TeamPerformanceChartParams,
  suppressFetchOnParamsChange?: boolean
}>

export class TeamPerformanceDataSource
  extends StoreNode
  implements IChartDataSource<TeamPerformanceChartSeriesData> {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.onPropChange('params',
      this.handleParamsChange);
  }

  // #region Props
  @computed get params(): TeamPerformanceChartParams | null {
    return this.getResolvedProp('params') ?? null;
  }
  set params(val: TeamPerformanceChartParams | null) {
    this.setProp('params', val);
  }

  @computed get suppressFetchOnParamsChange(): boolean | null {
    return this.getResolvedProp('suppressFetchOnParamsChange') ?? null;
  }
  set suppressFetchOnParamsChange(val: boolean | null) {
    this.setProp('suppressFetchOnParamsChange', val);
  }
  // #endregion

  // #region Handlers
  private handleParamsChange = () => {
    // this.fetch();
  }
  // #endregion

  @computed get mode(): TeamPerformanceChartMode {
    return this.params?.mode ?? TeamPerformanceChartMode.Uploads;
  }

  readonly data = observable.array<TeamPerformanceChartSeriesData>([], { deep: false });

  private abortController: AbortController | null = null;

  @observable isFetching = false;
  @observable error: Error | null = null;

  @action
  reset() {
    this.abort();

    this.isFetching = false;
    this.error = null;
    this.abortController = null;

    this.data.clear();
  }

  @action
  async fetch(): AsyncResult {

    this.reset();

    this.isFetching = true;

    const abortCtrl = new AbortController();
    this.abortController = abortCtrl;

    const { params } = this;
    if (!params)
      return [null];

    const reqArgs: ApiVariables<'keenBatch'> = {
      args: {
        start: params.startDate?.toISO()!, // TODO: fix
        end: params.endDate?.toISO()!,
        timezone: params.timezone ?? undefined,
        teamId: params.teamId ?? undefined,
        limit: 10
      }
    };

    const api = this.store.api;
    const reqOpts: ApiRequestOptions = {
      signal: abortCtrl.signal
    };

    let reqPromise: ApiResult<'keenBatch'> | null = null;

    switch (params.mode) {
      default:
      case TeamPerformanceChartMode.Uploads:
        const [[res1, err1], [res2, err2]] = await Promise.all([
          api.runQuery('keen_getTeamUserUploads', reqArgs, reqOpts),
          api.runQuery('keen_getVideoAddToTeam', reqArgs, reqOpts)]
        );

        if (err1 || err2 || !res1 || !res2)
          // @ts-ignore
          return this.handleError(err1 || err2);

        const [data, insertErr] = this.insertUploads(res1, res2);
        if (insertErr)
          return this.handleError(insertErr);

        runInAction(() => {
          this.isFetching = false;
        });

        return [data!];

      case TeamPerformanceChartMode.Edits:
        reqPromise = api.runQuery('keen_getTeamUserEdits', reqArgs, reqOpts);
        break;

      case TeamPerformanceChartMode.Bookmarks:
        reqPromise = api.runQuery('keen_getTeamUserBookmarks', reqArgs, reqOpts);
        break;

      case TeamPerformanceChartMode.Reactions:
        reqPromise = api.runQuery('keen_getTeamUserReactions', reqArgs, reqOpts);
        break;
    }

    if (!reqPromise) {
      console.error('Mode not implemented');
      return this.handleError(error('Unknown', `Invalid mode ${params.mode} was provided to TeamPerformanceDataSource`));
    }

    const [res, err] = await reqPromise;
    if (err)
      // @ts-ignore TODO: make ApiError extend our own Error class
      return this.handleError(err);

    const [data, insertErr] = this.insert(res!);
    if (insertErr)
      return this.handleError(insertErr);

    runInAction(() => {
      this.isFetching = false;
    });

    return [data!];
  }

  cancel(): void {
    this.abort();
  }

  @observable isBatchRequesting = false;

  notifyBatchRequestStarted() {
    this.isBatchRequesting = true;
  }
  notifyBatchRequestCompleted() {
    this.isBatchRequesting = false;
  }

  @action
  insertUploads(res1: ApiResponse<'keenBatch'>, res2: ApiResponse<'keenBatch'>): Result<TeamPerformanceChartData> {
    let apiRes1: string;
    let apiRes2: string;
    // @ts-ignore
    apiRes1 = res1?.keenBatch.teamUserUploads?.result;
    // @ts-ignore
    apiRes2 = res2?.keenBatch.videoAddToTeam?.result;

    const [data, err]: Result<TeamPerformanceChartData> = getUploadsDataFromQuery(apiRes1, apiRes2);

    if (err) {
      return this.handleError(err);
    }

    this.data.replace(data ?? []);
    return [data ?? []];
  }

  @action
  insert(res: ApiResponse<'keenBatch'>): Result<TeamPerformanceChartData> {
    let apiRes: string;
    switch (this.mode) {
      default:
      case TeamPerformanceChartMode.Edits:
        // @ts-ignore
        apiRes = res.keenBatch.teamUserEdits?.result;
        break;

      case TeamPerformanceChartMode.Bookmarks:
        // @ts-ignore
        apiRes = res.keenBatch.teamUserBookmarks?.result;
        break;

      case TeamPerformanceChartMode.Reactions:
        // @ts-ignore
        apiRes = res.keenBatch.teamUserReactions?.result;
        break;
    }

    const [data, err]: Result<TeamPerformanceChartData> = getDataFromQuery(apiRes);

    if (err) {
      return this.handleError(err);
    }

    this.data.replace(data ?? []);
    return [data ?? []];
  }

  private abort() {

    const lastAbortCtrl = this.abortController;
    if (lastAbortCtrl)
      lastAbortCtrl.abort();
    this.abortController = null;
  }

  @action
  private handleError(err?: Error | string): Result {
    if (!err)
      err = new Error();
    if (typeof err === 'string')
      err = new Error('Unknown', err);

    if (err instanceof ApiError && err.type === 'RequestAborted') {
      // for aborted requests we only return the error but we don't set it on the instance and we do not repor it as error
      return [null, err];

    } else {
      console.error(`An error occured in TeamPerformanceDataSource: `, err);

      this.isFetching = false;
      this.abort();
      this.error = err;

      return [null, err];
    }
  }
}

function getDataFromQuery(data: string): Result<TeamPerformanceChartData> {
  const [apiResult, err] = parseKeenJsonResult<any>(data);
  if (err)
    return [null, err];

  const result: TeamPerformanceChartDatum[] = apiResult!
    .map((data: any) => {
      if (!data?.['user.username'])
        return null!;

      return {
        date: data?.['user.username'],
        value: data?.result ?? 0,
      }
    })
    .filter(identity);

  const outData: TeamPerformanceChartData = [{
    dataKey: ' ',
    data: sortBy(result, 'value')
  }];

  return [outData];
}

function getUploadsDataFromQuery(data1: string, data2: string): Result<TeamPerformanceChartData> {
  const [apiResult1, err1] = parseKeenJsonResult<any>(data1);
  const [apiResult2, err2] = parseKeenJsonResult<any>(data2);

  if (err1 || err2)
    return [null, err1 || err2];


  const result: TeamPerformanceChartDatum[] = apiResult1!
    .map((data: any) => {
      if (!data?.['user.username'])
        return null!;

      return {
        date: data?.['user.username'],
        value: data?.result ?? 0,
      }
    })
    .filter(identity);

  apiResult2?.forEach((data: any) => {
    const username = data['user.username'];

    if (username) {
      const r = result.find(i => i.date === username);
      if (r) {
        r.value += data.result;
      } else {
        result.push({
          date: username,
          value: data.result || 0
        })
      }
    }
  })

  const outData: TeamPerformanceChartData = [{
    dataKey: ' ',
    data: sortBy(result, 'value')
  }];

  return [outData];
}
