import { action, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { CreateTeamFormState } from '../../components/createTeamForm/createTeamFormState';

export class CreateTeamPageState
  extends StoreNode {

  readonly createTeamForm: CreateTeamFormState;

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.createTeamForm = new CreateTeamFormState(this.store)
  }

  @observable isLoaded = false;
  @observable isMounted = false;

  @action
  async pageMounted() {

    await this.createTeamForm.teamStore.apiEnsureTeams();
    this.createTeamForm.init();
    this.isMounted = true;
    this.isLoaded = true;
  }

  @action
  pageUnmounted() {
    this.isMounted = false;
    this.isLoaded = false;
    this.createTeamForm.reset();
  }
}