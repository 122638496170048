import { JobSpeciality, JobType } from '@clipr/lib';
import { action, makeObservable, observable, override } from 'mobx';
import { assertNotNull, Result } from '../../core';
import { JobModel } from '../../entities';
import { Store } from '../../store/store';
import { JobLevel } from '../../entities/job/jobFeatures';
import { notifySuccess } from '../notifications';
import { ApiMultipartUploadRequest, ApiMultipartUploadRequestParams } from '../../api/apiMultipartUploadRequest';
import { UploadTask } from './uploadTask';

export type MultipartUploadTaskProps = {
  file: File;
  teamId?: string;
  jobId?: string;
  languageCode?: string;
  isPublic?: boolean;
  jobLevel?: JobLevel;
  jobSpeciality?: JobSpeciality;
  videoType?: JobType;
  chunkSize?: number;
  threadsQuantity?: number;
};

export class MultipartUploadTask
  extends UploadTask {
  nodeType = 'MultipartUploadTask';

  readonly file: File;
  readonly chunkSize: number = 1024 * 1024 * 5;
  readonly threadsQuantity: number = 5;

  uploadToken: string | null = null;
  uploadId: string | null = null;
  signedUrls: string[] = [];
  uploadedParts: { part: number, eTag: string }[] = [];

  @observable activeConnections: { [part: number]: ApiMultipartUploadRequest } = {};
  @observable progressCache: {[part: number]: ApiMultipartUploadRequest } = {};

  @override
  get progress() {
    if (!this.isActive)
      return 0;

    const total = this.file.size;
    const uploaded = Object.values(this.progressCache).reduce((acc, request) => acc + request.uploadLoaded, 0);

    return total > 0 && uploaded > 0 ? uploaded / total : 0;
  }

  constructor(props: MultipartUploadTaskProps, store: Store) {
    super(props, store);
    makeObservable(this);

    this.file = props.file;
    this.chunkSize = props.chunkSize || 1024 * 1024 * 250;
    this.threadsQuantity = Math.min(props.threadsQuantity || 5, 15);
  }

  @override
  async start(): Promise<any> {
    this.emit('start');
    this.status = 'fetchingUploadInfo';

    const { file, store } = this;
    const { apiService: api } = store;

    const numberOfParts = Math.ceil(this.file.size / this.chunkSize);
    const [prepareRes, prepareErr] = await api.createMultipartUpload({
      input: {
        numberOfParts,
        fileName: file.name,
        teamId: this.teamId!,
        jobId: this.jobId!
      }
    });

    if (prepareErr)
      return this.handleError(prepareErr, 'Failed to prepare upload.');

    const res = prepareRes?.createMultipartUpload;
    if (!res?.uploadToken) {
      return;
    }

    this.synchronizer.bindToken(res.uploadToken);

    assertNotNull(prepareRes);
    this.signedUrls = res.signedUrls;
    this.uploadToken = res.uploadToken;
    this.uploadId = res.uploadId;

    if (this.signedUrls.length)
      this.sendNext();

    else 
      return this.handleError(prepareErr, 'Failed to prepare upload.');
  }

  @action
  async sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }
  
    if (!this.signedUrls.length) {
      if (!activeConnections) {
        return this.complete();
      }

      return;
    }

    const part = this.signedUrls.length;
    const signedUrl = this.signedUrls.pop();

    if (this.file && signedUrl) {
      const sentSize = (part - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        this.sendNext()
      }

      this.sendChunk(chunk, signedUrl, part, sendChunkStarted)
      .then(() => {
        this.sendNext();
      })
      .catch((error) => {
        if (error.message === 'Cancel') 
          return this.handleCanceled(error);
        return this.handleError(error, 'Failed to upload file.');
      });
    }
  }

  @override
  cancel() {
    Object.values(this.activeConnections).forEach((activeConnection) => activeConnection.cancel());
  }

  sendChunk(chunk: Blob, signedUrl: string, part: number, sendChunkStarted: () => void): Promise<void> {
    return new Promise((resolve, reject) => {
      this.upload(chunk, signedUrl, part, sendChunkStarted)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error)
        })
    });
  }

  @action
  async upload(chunk: Blob, signedUrl: string, part: number, sendChunkStarted: () => void): Promise<void> {
    return new Promise((resolve, reject) => {
      const { store } = this;
      const { apiService: api } = store;
  
      const multipartUploadParams: ApiMultipartUploadRequestParams = {
        part,
        blob: chunk,
        url: signedUrl,
        fileSize: this.file.size,
        contentType: this.file.type
      }
      
      const uploadRequest = api.multipartUploadRequest(multipartUploadParams);

      this.status = 'uploading';
      this.activeConnections[part-1] = uploadRequest;
      this.progressCache[part-1] = uploadRequest;
      sendChunkStarted();

      uploadRequest.start().then(([uploadRes, uploadErr]) => {
        if (uploadErr) {
          reject(uploadErr);
          return;
        }

        const eTag = uploadRes.headers['etag']?.replaceAll('"', "");
        this.uploadedParts.push({ part, eTag });
        delete this.activeConnections[part - 1];
        resolve();
      });
     });
  }

  @action
  async complete() {
    if (!this.uploadId || !this.uploadToken || !this.uploadedParts.length) {
      return this.handleError(new Error('InternalError'), 'Failed to upload file.');
    }

    const { store } = this;
    const { apiService: api } = store;

    const eTags = this.uploadedParts.sort((a, b) => a.part - b.part).map((value) => value.eTag);

    const [, completeErr] = await api.completeMultipartUpload({
      input: {
        uploadId: this.uploadId,
        uploadToken: this.uploadToken,
        eTags
      }
    });

    if (completeErr)
      return this.handleError(completeErr, 'Failed to prepare upload.');

    this.status = 'completed';
    return this.syncJob();
  }

  @action
  handleError(err: any, errMsg: string): Result<JobModel> {
    this.error = err;
    this.errorMessage = errMsg;
    this.status = 'error';
    this.emit('error');
    this.dispatch(
      'NotificationService',
      'notifyError',
      `Could not upload '${this.file.name}'.`);
    Object.values(this.activeConnections).forEach((activeConnection) => activeConnection.cancel());

    return [null, err];
  }

  @action
  handleCanceled(err: Error): Result<JobModel> {
    this.status = 'canceled';
    this.emit('cancel');
    notifySuccess(this, 'Upload canceled.');
    Object.values(this.activeConnections).forEach((activeConnection) => activeConnection.cancel());
    return [null, err];
  }
}