
import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';

import { StoreNode } from '../../../store';
import { Store } from '../../../store/store';
import { DateInputProps, Info, RangeOptions } from './dateInput';

export class DateInputState extends StoreNode {
  readonly nodeType = 'Datepicker';
  constructor(store: Store, props: DateInputProps) {
    super(store, props);
    makeObservable(this);

    const { activeRangeOption, defaultPickerValue } = props;

    this.activeRangeOption = activeRangeOption || null;
    this.previousSelectedRange = activeRangeOption || null;
    this.value = activeRangeOption ? activeRangeOption.getRange() : defaultPickerValue ?? null;
    this.previousSelectedValue = activeRangeOption ? activeRangeOption.getRange() : null;
  }

  @computed get isDirty(): boolean {
    if (
      Array.isArray(this.value) &&
      this.value[0].diff((this.initialValue as [moment.Moment, moment.Moment])?.[0]) &&
      this.value[1].diff((this.initialValue as [moment.Moment, moment.Moment])?.[1])) {
      return true;
    }

    if ((this.value as moment.Moment)?.diff(this.initialValue as moment.Moment)) return true;
    return false;
  }

  @computed get isRangeDatepicker(): boolean {
    return this.resolvedProps.rangeDatePicker ?? true;
  }

  @computed get placeholder(): [string, string] | string | null {
    return this.resolvedProps.placeholder;
  }

  @computed get validationMessage(): string | null {
    return this.resolvedProps.validationMessage;
  }

  @computed get inputReadOnly(): boolean {
    return this.resolvedProps.inputReadOnly || false;
  }

  @computed get allowEmpty(): [boolean, boolean] {
    return this.resolvedProps.allowEmpty || [false, false];
  }

  @computed get isRequired(): boolean {
    return this.resolvedProps.isRequired;
  }

  @computed get disabled(): boolean {
    return this.resolvedProps.disabled || false;
  }

  @computed get datepickerId(): string {
    return this.resolvedProps.id || 'antd-range-picker';
  }

  @computed get format(): string | string[] {
    return this.resolvedProps.format || ['DD/MM/YYYY', 'DDMMYYYY'];
  }

  @computed get shouldDisableDates(): boolean {
    return this.resolvedProps.shouldDisableDates || false;
  }

  @computed get defaultPickerValue(): [moment.Moment, moment.Moment] | moment.Moment | null {
    return this.resolvedProps.defaultPickerValue;
  }
  @computed get rangeOptions(): RangeOptions[] {
    return this.resolvedProps.rangeOptions || [];
  }

  @computed get previousSelectedComputedValue(): [moment.Moment, moment.Moment] | moment.Moment | null {
    return this.previousSelectedValue;
  }
  @computed get activeRangeComputedValue(): [moment.Moment, moment.Moment] | null {
    return this.activeRangeOption?.getRange() || null;
  }

  @computed get error(): string | null {
    if (
      this.isRequired &&
      !this.value &&
      (this.isTouched || this.isSubmitting))
      return 'Required field';

    return null;
  }

  // TODO: !!!!!This is just a workaround, we need to refactor the entire datepicker, give up on antd and remove moment from everywhere
  @computed get formattedValue(): string | null {
    if (!this.value) return null;

    return moment(this.value as moment.Moment).format();
  }

  @observable isTouched: boolean = false;
  @observable isSubmitting: boolean = false;
  @observable open: boolean = false;
  @observable bordered: boolean = false;
  @observable max: moment.Moment | null = null;
  @observable min: moment.Moment | null = moment();
  @observable activeRangeOption: RangeOptions | null = null;
  @observable previousSelectedRange: RangeOptions | null = null;
  @observable mode: [string, string] | string | null = !this.isRangeDatepicker ? 'date' : ['date', 'date'];
  @observable value: [moment.Moment, moment.Moment] | moment.Moment | null = this.activeRangeComputedValue;
  @observable initialValue: [moment.Moment, moment.Moment] | moment.Moment | null = this.defaultPickerValue ?? null;
  @observable previousSelectedValue: [moment.Moment, moment.Moment] | moment.Moment | null = this.activeRangeComputedValue;


  @action
  handleChange = (values: any, _: [string, string] | string) => {
    this.value = values;
    // When default active range is provided value should not be null (e.g. Zoom Calendar where is mandatory to provide a range of maximum 1 month)
    if (this.isRangeDatepicker &&
      (values === null || values[1] === null || values[0] === null)) {
      if (this.props.activeRangeOption) {
        const range = this.props.activeRangeOption.getRange();
        this.value = range;
        this.max = range[1];
        this.activeRangeOption = this.props.activeRangeOption;
      } else {
        this.bordered = false;
        this.activeRangeOption = null;
      }
    }

    this.computeMaxValue(values);
  }

  @action
  private computeMaxValue(values: any) {
    if (this.shouldDisableDates && this.value && values && values[0]) {
      const computedMaxValue = values[0]?.clone().add(31, 'days');
      this.max = computedMaxValue;

      (this.value as [moment.Moment, moment.Moment])[0] = values[0];
      if ((this.value as [moment.Moment, moment.Moment])[1]?.isAfter(this.max)) {
        (this.value as [moment.Moment, moment.Moment])[1] = null as any;
      }
    }
  }

  @action
  handleSubmit = () => {
    this.isSubmitting = true;
    this.invokeHandler('onSubmit');
  }

  @action
  handleOpenChange = (value: boolean) => {
    this.isTouched = true;

    if (value) {
      this.open = value;
      this.bordered = true;
      if (this.activeRangeOption) this.computeMaxValue(this.activeRangeComputedValue);
    }
  }

  @action
  onBackdropClick = () => {
    if (this.open) {
      this.open = false;
      this.mode = !this.isRangeDatepicker ? 'date' : ['date', 'date'];
    }
  }

  @action
  cancel = () => {
    this.open = false;
    this.mode = !this.isRangeDatepicker ? 'date' : ['date', 'date'];
    this.value = this.previousSelectedComputedValue;
    this.activeRangeOption = this.previousSelectedRange;

    if (!this.value) this.bordered = false;
  }

  @action
  apply = () => {
    this.open = false;
    this.mode = !this.isRangeDatepicker ? 'date' : ['date', 'date'];
    this.previousSelectedValue = this.value;
    this.previousSelectedRange = this.activeRangeOption;

    if (!this.value && !this.props.activeRangeOption) this.bordered = false;
    //  If any (or both) of the values are empty when a default range is provided, use the default range when applying the dates
    const value = this.value as [moment.Moment, moment.Moment];
    if ((!this.value || !value[1] || !value[0]) && this.props.activeRangeOption) {
      const range = this.props.activeRangeOption.getRange();
      this.value = range;
      this.max = range[1];
      this.activeRangeOption = this.props.activeRangeOption;
      this.previousSelectedRange = this.props.activeRangeOption;
    }
  }

  @action
  disabledDate = (currentDate: moment.Moment) => {
    if (this.max) {
      return currentDate?.isAfter(this.max);
    }

    if (this.min) {
      return currentDate?.isBefore(this.min);
    }
    return false;
  }

  @action
  setActiveRange = (selectedRange: RangeOptions) => {
    const selectedRangeValues = selectedRange?.getRange();
    const activeRangeValues = this.activeRangeOption?.getRange();

    if (selectedRangeValues && activeRangeValues) {
      // Check if the selected range is the same as the active one and disable it
      const isSameRange = activeRangeValues[0]?.isSame(selectedRangeValues[0]) && activeRangeValues[1]?.isSame(selectedRangeValues[1]);
      if (isSameRange) {
        this.value = null;
        this.activeRangeOption = null;
        return;
      }
    }

    this.activeRangeOption = selectedRange;
    this.handleChange(selectedRangeValues, ['', '']);
  }

  @action
  onCalendarChange = (values: any, dateStrings: [string, string], info: Info) => {
    // When a preset range is selected, and a new value is choosen manually by the user from the picker, make sure to reset the current active range option 
    const activeRange = this.activeRangeComputedValue;
    if (this.value && activeRange) {
      this.activeRangeOption = null;
    }

    if (info.range === 'start') {
      this.computeMaxValue(values);
    }
  }

  @action
  onPanelChange = (_: moment.Moment, mode: [string, string] | string) => {
    this.mode = this.isRangeDatepicker ? [mode[0], mode[1]] : mode;
  }

  @action
  loadValue(value: string | null) {
    if (value) {
      this.value = moment(value, 'YYYY-MM-DD');
      this.initialValue = moment(value, 'YYYY-MM-DD');
    }

    this.min = moment();
  }

  @action
  reset() {
    const { activeRangeOption } = this.props;
    this.activeRangeOption = activeRangeOption || null;
    this.previousSelectedRange = activeRangeOption || null;
    this.value = activeRangeOption ? activeRangeOption.getRange() : null;
    this.initialValue = null;
    this.previousSelectedValue = activeRangeOption ? activeRangeOption.getRange() : null;

    this.open = false;
    this.isSubmitting = false;
    this.isTouched = false;
    this.bordered = false;
    this.max = null;
    this.min = null;
    this.mode = !this.isRangeDatepicker ? 'date' : ['date', 'date'];
  }
}

export const dateInput = (node: StoreNode | Store, props: DateInputProps) => {
  let store: Store;
  if (node instanceof StoreNode)
    store = node.store;
  else
    store = node;

  return new DateInputState(store, props);
}
