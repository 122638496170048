import { EditorState, SelectionState, ContentBlock, Modifier, RawDraftContentState, convertToRaw, convertFromRaw } from 'draft-js';
import createLinkifyPlugin, { extractLinks } from '@draft-js-plugins/linkify';
import createMentionPlugin, { MentionData, defaultTheme as defaultMentionTheme } from '@draft-js-plugins/mention';
import createEmojiPlugin, { defaultTheme as defaultEmojiTheme } from '@draft-js-plugins/emoji';
import { createElement } from 'react';

import { LinkData, Mention } from '../../components/playerComments/draft';
import { EmojiSVGIcon } from '../../components/svg';

type LinkEntityRange = {
  start: number,
  end: number,
  href: string,
  text: string
};

export const LINK = 'LINK';
export const MENTION = 'MENTION';
export const EMOJI = 'EMOJI';

export class DraftService {
  readonly nodeType = 'DraftService';

  createEditorState(content?: RawDraftContentState): EditorState {
    if (!content) return EditorState.createEmpty();
    const contentState = convertFromRaw(content);
    return EditorState.createWithContent(contentState);
  }

  pushEditorState(editorState: EditorState, content: RawDraftContentState): EditorState {
    const contentState = convertFromRaw(content);
    const newEditorState = EditorState.push(editorState, contentState, 'insert-fragment');
    return EditorState.moveFocusToEnd(newEditorState);
  }

  clearEditorState(editorState: EditorState): EditorState {
    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlockMap().toList();
    const updatedSelection = editorState.getSelection().merge({
      anchorKey: blocks.first().get('key'),
      anchorOffset: 0,
      focusKey: blocks.last().get('key'),
      focusOffset: blocks.last().getLength()
    });
    const newContentState = Modifier.removeRange(
      contentState,
      updatedSelection,
      'forward'
    );

    return EditorState.push(editorState, newContentState, 'remove-range');
  }

  modifyContentState(editorState: EditorState): RawDraftContentState {
    const modifiedEditorState = this.applyLinkEntities(editorState);
    const contentState = modifiedEditorState.getCurrentContent();
    return convertToRaw(contentState);
  }

  getCharCount(editorState: EditorState): number {
    const contentState = editorState.getCurrentContent();
    let text = contentState.getPlainText('');

    const links = extractLinks(text);
    if (links) for (const link of links) {
      text = text.replace(link.text, '');
    }

    const { entityMap } = convertToRaw(contentState);
    for (const key in entityMap) {
      const { type, data } = entityMap[key];
      switch (type.toUpperCase()) {
        case MENTION:
          const { name } = data.mention as MentionData;
          text = text.replace(name, '');
          break;
        case EMOJI:
          const { emojiUnicode } = data;
          text = text.replace(emojiUnicode, ' ');
          break;
        default:
          break;
      }
    }

    return text.split('').length;
  }

  isFocused(editorState: EditorState): boolean {
    const selectionState = editorState.getSelection();
    return selectionState.getHasFocus();
  }

  private applyLinkEntities(editorState: EditorState): EditorState {
    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlockMap();
    let newContentState = contentState;

    blocks.forEach(block => {
      if (!block) return;

      const addLinkEntity = (linkEntityRange: LinkEntityRange) => {
        const { start, end, text, href } = linkEntityRange;
        const existingEntityKey = block.getEntityAt(start);

        if (existingEntityKey) {
          const entity = contentState.getEntity(existingEntityKey);
          const hasLinkEntity = entity && entity.getType() === LINK && entity.getData().href === href;
          if (hasLinkEntity) return;
        }

        const data = { link: { href, text } as LinkData };
        const contentStateWithEntity = contentState.createEntity(LINK, 'IMMUTABLE', data);
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const blockKey = block.getKey();
        const selection = new SelectionState({
          anchorKey: blockKey,
          anchorOffset: start,
          focusKey: blockKey,
          focusOffset: end
        });

        newContentState = Modifier.replaceText(newContentState, selection, text, undefined, entityKey);
      };

      this.findLinks(block, addLinkEntity);
    });

    if (!newContentState.equals(contentState)) {
      return EditorState.push(editorState, newContentState, 'apply-entity');
    }

    return editorState;
  }

  linkifyPlugin() {
    return createLinkifyPlugin();
  }

  mentionPlugin() {
    return createMentionPlugin({
      // TODO: fix this mess
      theme: this.suggestionsPluginTheme(MENTION) as any,
      entityMutability: 'IMMUTABLE',
      mentionComponent: Mention
    });
  }

  emojiPlugin() {
    return createEmojiPlugin({
      // TODO: fix this mess
      theme: this.suggestionsPluginTheme(EMOJI, {
        emojiSelect: 'emoji-select',
        emojiSelectButton: 'select-btn',
        emojiSelectButtonPressed: 'select-btn-pressed',
        emojiSelectPopover: 'select-popover',
        emojiSelectPopoverTitle: 'select-title',
        emojiSelectPopoverGroups: 'select-groups',
        emojiSelectPopoverScrollbarOuter: 'select-groups-scrollbar',
        emojiSelectPopoverGroup: 'select-group',
        emojiSelectPopoverGroupTitle: 'group-title',
        emojiSelectPopoverGroupList: 'group-list',
        emojiSelectPopoverGroupItem: 'group-item',
        emojiSelectPopoverEntry: 'group-item-entry',
        emojiSelectPopoverEntryFocused: 'group-item-entry',
        emojiSelectPopoverNav: 'select-nav',
        emojiSelectPopoverNavItem: 'select-nav-item',
        emojiSelectPopoverNavEntry: 'select-nav-entry',
        emojiSelectPopoverNavEntryActive: 'select-nav-entry-active',
        emojiSelectPopoverToneSelectList: 'select-tone-list',
        emojiSelectPopoverToneSelectItem: 'select-tone-item',
      }) as any,
      useNativeArt: true,
      selectButtonContent: createElement(EmojiSVGIcon)
    });
  }

  private findLinks(
    block: ContentBlock,
    callback: (linkEntityRange: LinkEntityRange) => void
  ) {
    const blockText = block.get('text');
    const links = extractLinks(blockText);
    if (!links) return;

    for (const link of links) {
      const linkEntityRange = {
        start: link.index,
        end: link.lastIndex,
        href: link.url,
        text: link.text
      };

      callback(linkEntityRange);
    }
  }

  private suggestionsPluginTheme(type: string, theme?: Object) {
    const pluginKey = type.toLowerCase();
    let defaultTheme;
    if (type === MENTION) defaultTheme = defaultMentionTheme;
    if (type === EMOJI) defaultTheme = defaultEmojiTheme;
    return {
      ...defaultTheme,
      [`${pluginKey}Suggestions`]: 'editor-suggestions',
      [`${pluginKey}SuggestionsEntry`]: 'suggestion-entry',
      [`${pluginKey}SuggestionsEntryFocused`]: 'suggestion-entry',
      [`${pluginKey}SuggestionsEntryText`]: 'text',
      ...theme
    };
  }
}