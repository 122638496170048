import { makeObservable, computed, observable, action } from 'mobx';
import { Editor as DraftEditor, EditorState } from 'draft-js';
import Editor, { EditorPlugin } from '@draft-js-plugins/editor';
import { MentionData, defaultSuggestionsFilter } from '@draft-js-plugins/mention';

import { DraftService } from '../../services/draft';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { JobModel } from '../../entities/job';
import { Team } from '../../entities/team';
import { TeamMember } from '../../entities/teamMember';
import { timeLabel } from '../utils';

import { CommentState } from './commentState';
import { AddCommentInput, AddReplyInput, EditCommentInput } from '@clipr/lib';
import { notifyError, notifyLoading, notifySuccess } from '../../services/notifications';
import { AsyncResult } from '../../core';
import { Comment } from '../../entities/comment';
import { IPlayer } from '../player';
import { SyntheticEvent } from 'react';
import { UserProfile } from '../../entities/userProfile';
import { PlayerMomentView } from '../player/playerMomentView';

type DOMEditor = DraftEditor & {
  editor?: HTMLElement
};

type Props = BindingProps<{
  jobId?: string | null,
  comment?: CommentState,
  isReply?: boolean,
  isEdit?: boolean,
  player?: IPlayer & {
    momentView?: PlayerMomentView
  }
}>;

export class CommentInputState
  extends StoreNode {

  readonly nodeType = 'CommentInputState';

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);
  }

  readonly draft: DraftService = new DraftService();

  readonly charCountMin: number = 2;

  readonly charCountMax: number = 280;

  readonly linkifyPlugin = this.draft.linkifyPlugin();

  readonly mentionPlugin = this.draft.mentionPlugin();

  readonly emojiPlugin = this.draft.emojiPlugin();

  readonly plugins: EditorPlugin[] = [
    this.linkifyPlugin,
    this.mentionPlugin,
    this.emojiPlugin
  ];

  readonly mentionSuggestions = observable.array<MentionData>([], { deep: false });

  domEditor: DOMEditor | null = null;

  @computed get jobId(): string | null {
    return this.resolvedProps.jobId ?? null;
  }

  @computed get comment(): CommentState | null {
    return this.resolvedProps.comment;
  }

  @computed get isReply(): boolean {
    return !!this.resolvedProps.isReply;
  }

  @computed get isEdit(): boolean {
    return !!this.resolvedProps.isEdit;
  }

  @computed get player(): IPlayer & {
    momentView?: PlayerMomentView
  } | null {
    return this.resolvedProps.player ?? null;
  }

  @computed get placeholder(): string {
    if (this.isReply) return 'Reply';
    if (this.isEdit) return 'Edit comment';
    return 'Add a comment';
  }

  @computed get showVideoTimeLabel(): boolean {
    return !(this.isReply || this.isEdit);
  }

  @computed get videoTimeLabel(): string {
    // TODO: replace with player current time
    // const currentTime = this.store...
    const currentTime = this.player?.currentTime ?? 0;
    return timeLabel(currentTime);
  }

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed get user(): UserProfile | null {
    return this.store.user;
  }

  @computed get team(): Team | null {
    const teamId = this.job?.ownerTeamId;
    return this.store.teamManager.getTeam(teamId);
  }

  @computed get mentions(): MentionData[] {
    if (!this.team) return [];
    return this.team?.members.map((member: TeamMember) => ({
      id: member.id,
      name: member.name,
      avatar: member.user?.pictureURL
    } as MentionData));
  }

  @computed get error(): string | null {
    let error = null;

    if (this.charCount > this.charCountMax)
      error = 'Character count exceeded';

    if (this.charCount < this.charCountMin)
      error = `Add at least ${this.charCountMin} characters`;

    return error;
  }

  @observable editorState: EditorState = this.draft.createEditorState();

  @observable charCount: number = 0;

  @observable canSubmit: boolean = false;

  @observable cannotSubmit: boolean = false;

  @observable hasMentionSuggestionsOpen: boolean = false;

  @observable isLoading: boolean = false;

  @action
  attachDOMEditor(component: Editor) {
    this.domEditor = component.editor as DOMEditor;
    this.setEditorStateContent();
  }

  @action
  setEditorStateContent() {
    if (!this.isEdit || !this.comment?.content) return;

    this.clear();
    this.editorState = this.draft.pushEditorState(
      this.editorState,
      this.comment.content
    );

    this.handleCharCount();
  }

  @action
  detachDOMEditor() {
    this.clear();
    this.domEditor = null;
  }

  @action
  handleOpenMentionSuggestions(open: boolean) {
    this.hasMentionSuggestionsOpen = open;
  }

  @action
  handleSearchMentions({ value }: { value: string }) {
    const suggestions = defaultSuggestionsFilter(value, this.mentions);
    this.mentionSuggestions.replace(suggestions);
  }

  @action
  handleChange(editorState: EditorState) {
    this.editorState = editorState;
    this.handleCharCount();
  }

  @action
  handleCharCount() {
    const isFocused = this.draft.isFocused(this.editorState);
    this.charCount = this.draft.getCharCount(this.editorState);
    this.canSubmit = (
      this.charCount >= this.charCountMin &&
      this.charCount <= this.charCountMax
    );
    this.cannotSubmit = (
      (isFocused && this.charCount < this.charCountMin) ||
      this.charCount > this.charCountMax
    );
  }

  @action
  async submit(): AsyncResult<Comment> {
    if (!this.canSubmit) {
      // notifyError(this, `Cannot submit comment: Character limit exceeded.`);
      return [null, new Error(`Comment cannot be submitted in the current state.`)];
    }


    const content = this.draft.modifyContentState(this.editorState);
    const text = JSON.stringify(content);

    if (!this.job || !this.jobId) {
      const err = new Error(`Cannot submit comment because 'job' and 'jobId' are not set.`);
      console.error(err);
      return [null, err];
    }

    if (!this.isReply && !this.isEdit) {
      const addCommentInput: AddCommentInput = {
        jobId: this.jobId,
        videoTime: Math.round(this.player?.currentTime ?? 0), // must be Int
        text
      };

      this.isLoading = true;
      notifyLoading(this, `Posting comment...`);
      const [comm, err] = await this.job?.apiAddComment(addCommentInput);
      if (err) {
        notifyError(this, `An error occured while adding your comment.`);
        this.isLoading = false;
        return [null, err];
      }

      this.isLoading = false;
      notifySuccess(this, `Comment added successfully.`);

      this.broadcast('commentAdded', { commentId: comm?.id, job: this.job });

      this.clear();
      // this.player?.invoke('exitPause');

      return [comm!];
    }

    else if (this.isReply) {
      if (!this.comment?.commentId) {
        notifyError(this, `An error occured while adding your reply.`);
        return [null, new Error(`Comment cannot be submitted because the state is invalid.`)];
      }

      const addReplyInput: AddReplyInput = {
        jobId: this.jobId,
        parentId: this.comment?.commentId,
        text
      };

      this.isLoading = true;
      notifyLoading(this, `Posting reply...`);
      const [comm, err] = await this.job?.apiAddReply(addReplyInput);
      if (err) {
        notifyError(this, `An error occured while adding your reply.`);
        this.isLoading = false;
        return [null, err];
      }

      this.isLoading = false;
      notifySuccess(this, `Reply added successfully.`);
      this.clear();
      // this.player?.invoke('exitPause');

      return [comm!];
    }

    else if (this.isEdit) {
      if (!this.comment?.commentId) {
        notifyError(this, `An error occured while editing your comment.`);
        return [null, new Error(`Comment cannot be edited because the state is invalid.`)];
      }

      const editCommentInput: EditCommentInput = {
        jobId: this.jobId,
        commentId: this.comment?.commentId,
        text
      };

      this.isLoading = true;
      notifyLoading(this, `Updating comment...`);
      const [comm, err] = await this.job?.apiEditComment(editCommentInput);
      if (err) {
        notifyError(this, `An error occured while editing your comment.`);
        this.isLoading = false;
        return [null, err];
      }

      this.isLoading = false;
      notifySuccess(this, `Comment updated successfully.`);
      this.clear();
      // this.player?.invoke('exitPause');

      return [comm!];
    }

    return [null, new Error(`Comment cannot be submitted because the state is invalid.`)];
  }

  @action
  clear() {
    this.editorState = this.draft.clearEditorState(this.editorState);
    this.charCount = 0;
    this.canSubmit = false;
    this.cannotSubmit = false;
  }

  focus() {
    if (!this.domEditor?.focus) return;
    this.domEditor.focus();
  }

  async handleKeyCommand(command: string) {
    try {
      if (command === 'submit') {
        await this.submit();
        return 'handled';
      }

      return 'not-handled';
    } catch (error) {
      return 'not-handled';
    }
  }

  keyBindingFn(evt: KeyboardEvent): string | null {
    switch (evt.keyCode) {
      case 13:
        // if (this.isEdit) return null;
        return 'submit';
      default:
        // @ts-ignore
        return this.mentionPlugin.keyBindingFn(evt);
    }
  }

  @action
  handleFocus(evt: SyntheticEvent<EventTarget>) {
    this.player?.invoke('enterPause');
  }

  @action
  handleBlur(evt: SyntheticEvent<EventTarget>) {
    // this.player?.invoke('exitPause');
  }
};