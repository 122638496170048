import './jobs.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Catalog } from '../layout';
import { CatalogState } from '../layout/catalogState';
import { CatalogFooter } from '../layout/catalogFooter';
import { TrainerJobTableRow } from './trainerJobTableRow';
import { SearchSVGIcon, TrainerLinkSVGIcon } from '../svg';
import { TrainerJobCatalogSource } from '../../entities/trainerJobCatalogSource';

type Props = {
  model: CatalogState;
  source: TrainerJobCatalogSource;
  teamId?: string;
};

export const TrainerJobTableCatalog = observer(
  ({ model, source, teamId }: Props) => {
    const { entities } = source;

    return (
      <Catalog className="job-table-catalog catalog" model={model}>
        {entities.length > 0 && (
          <>
            <table className="job-table job-table-core">
              <tbody>
                {entities.map((job) => {
                  return (
                    <TrainerJobTableRow
                      key={job.id}
                      model={job}
                      teamId={teamId}
                      source={source}
                    />
                  );
                })}
              </tbody>
            </table>

            <CatalogFooter model={model} />
          </>
        )}

        {entities.length === 0 &&
          (!!source.searchFilter ? (
            <div className="empty-box" aria-hidden={model.isLoading}>
              <div className="catalog empty-catalog">
                <div className="icon">
                  <SearchSVGIcon />
                </div>

                <h1 className="headline-1">
                  No results found for "{source.searchFilter}".
                </h1>
                <p>
                  Make sure all words are spelled correctly or try different
                  keywords.
                </p>
              </div>
            </div>
          ) : (
            <div className="empty-box" aria-hidden={model.isLoading}>
              <div className="catalog empty-catalog">
                <div className="icon">
                  <TrainerLinkSVGIcon />
                </div>
                <h1 className="headline-1">
                  Content to be reviewed will appear here
                </h1>
              </div>
            </div>
          ))}
      </Catalog>
    );
  }
);
