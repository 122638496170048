import { computed, makeObservable } from 'mobx';
import { StoreNode } from '../store';
import { Store } from '../store/store';

type Props = {
  teamId: string,
  teamMemberId: string,
  cursor: string,
  index: number
}

/** Stores a pointer to a Job in a catalog list and the pagination info associated with it. */
export class TeamMemberCatalogItem
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
    Object.assign(this, props);
  }

  readonly teamId!: string;
  readonly teamMemberId!: string;
  readonly cursor!: string;
  readonly index!: number;

  @computed get team() {
    return this.store.teamManager.strictGetTeam(this.teamId);
  }

  @computed get teamMember() {
    return this.team?.getMember(this.teamMemberId);
  }
}