import { action, computed, makeObservable, observable } from 'mobx';
import { ApiVariables, ApiQueryOptions } from '../api/apiSchema';
import { assert, assertNotNull, AsyncResult } from '../core';
import { Bookmark, BookmarkProps } from './bookmark';
import { BookmarkList, BookmarkListProps } from './bookmarkList';
import { Store } from '../store/store';
import { StoreNode } from '../store';

const bookmarkThumbnailSize = {
  width: 180,
  height: 100
};

/**
 * Manages Bookmark and BookmarkList entities for a Store.
 */
export class BookmarkManager
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  readonly nodeType: 'BookmarkManager' = 'BookmarkManager';

  /** @alias `Store.apiService` */
  private get api() {
    return this.store.api;
  }

  readonly bookmarkLookup = observable.map<string, Bookmark>();
  @computed
  get bookmarks(): Bookmark[] {
    return [...this.bookmarkLookup.values()];
  }

  readonly bookmarkListLookup = observable.map<string, BookmarkList>();
  @computed
  get bookmarkLists(): BookmarkList[] {
    return [...this.bookmarkListLookup.values()];
  }

  // #region Bookmark methods
  // -------
  private assertHasBookmark(id: string) {
    assert(this.bookmarkLookup.has(id),
      `Store does not contain a Bookmark with id '${id}'.`);
  }

  getBookmark(id: string): Bookmark {
    this.assertHasBookmark(id);
    return this.bookmarkLookup.get(id)!;
  }

  maybeGetBookmark(id: string): Bookmark | null {
    return this.bookmarkLookup.get(id) || null;
  }

  @action
  insertBookmark(props: BookmarkProps): Bookmark {
    // hack to preserve thumbnail
    const existing = this.bookmarkLookup.get(props.id);
    if (existing && existing.thumbnail && !props.thumbnail)
      props.thumbnail = existing.thumbnail;

    const bkm = new Bookmark(props, this.store);
    this.bookmarkLookup.set(bkm.id, bkm);
    return bkm;
  }

  @action
  removeBookmark(id: string): Bookmark {
    const bkm = this.getBookmark(id);
    bkm.dispose();
    this.bookmarkLookup.delete(id);
    return bkm;
  }

  @action
  clearBookmarks(): void {
    this.bookmarkLookup.clear();
  }

  async apiFetchBookmarks(args: ApiVariables<'getBookmarks'>, opts?: ApiQueryOptions): AsyncResult<Bookmark[]> {
    if (!args.thumbnailSize) {
      Object.assign(args, {
        thumbnailSize: [bookmarkThumbnailSize]
      });
    }

    const [bkmLstProps, err] = await this.api.getBookmarks(args, opts);

    if (err)
      return [null, err];
    assertNotNull(bkmLstProps);

    this.clearBookmarks();

    const lists = bkmLstProps.getBookmarks;
    return [lists.edges
      .map((edge: any) => this.insertBookmark(edge.node))];
  }

  async apiCreateBookmark(args: ApiVariables<'createBookmark'>): AsyncResult<Bookmark> {
    const [bkmProps, err] = await this.api.createBookmark(args);
    if (err)
      return [null, err];
    assertNotNull(bkmProps);
    const bkm = this.insertBookmark(bkmProps.createBookmark);
    return [bkm];
  }

  /**
   * Makes a 'deleteBookmark' API call, disposes the object and removes it from the store upon completion.
   * @returns The deleted Bookmark instance.
   */
  async apiDeleteBokmark(args: ApiVariables<'deleteBookmark'>): AsyncResult<Bookmark> {
    const [delRes, err] = await this.api.deleteBookmark(args);
    if (err)
      return [null, err];
    assertNotNull(delRes);

    const bkm = this.removeBookmark(delRes.deleteBookmark.deletedId);
    return [bkm];
  }

  async apiFetchMomentBookmarks(args: ApiVariables<'getMomentBookmarks'>): AsyncResult<Bookmark[]> {

    if (!args.thumbnailSize)
      Object.assign(args, {
        thumbnailSize: [bookmarkThumbnailSize]
      });

    const [query, err] = await this.api.getMomentBookmarks(args);
    if (err)
      return [null, err];

    const bkmArr = query!.getMomentBookmarks.edges.map((edge: any) => {
      return this.insertBookmark(edge.node);
    });
    return [bkmArr];
  }

  async apiFetchJobRelatedBookmarks(args: ApiVariables<'getJobRelatedBookmarks'>, opts?: ApiQueryOptions): AsyncResult<Bookmark[]> {

    if (!args.thumbnailSize) {
      Object.assign(args, {
        thumbnailSize: [bookmarkThumbnailSize]
      });
    }

    const [query, err] = await this.api.getJobRelatedBookmarks(args, opts);
    if (err)
      return [null, err];

    this.clearBookmarks();
    
    const bkmArr = query!.getJobRelatedBookmarks.edges.map((edge: any) => {
      return this.insertBookmark(edge.node);
    });
    return [bkmArr];
  }
  // #endregion

  // #region BookmarkList methods
  // -------
  hasBookmarkList(id: string) {
    return this.bookmarkListLookup.has(id);
  }

  private assertHasBookmarkList(id: string) {
    assert(this.hasBookmarkList(id),
      `Store does not contain a BookmarkList with id '${id}'.`);
  }

  getBookmarkList(id: string): BookmarkList {
    this.assertHasBookmarkList(id);
    return this.bookmarkListLookup.get(id)!;
  }

  @action
  insertBookmarkList(props: BookmarkListProps): BookmarkList {
    const bkmLst = new BookmarkList(props, this.store);
    this.bookmarkListLookup.set(bkmLst.id, bkmLst);
    return bkmLst;
  }

  @action
  removeBookmarkList(id: string): BookmarkList {
    const bkm = this.getBookmarkList(id);
    bkm.dispose();
    this.bookmarkListLookup.delete(id);
    return bkm;
  }

  @action
  clearBookmarkLists(): void {
    this.bookmarkListLookup.clear();
  }


  async apiFetchBookmarkLists(opts?: ApiQueryOptions): AsyncResult<BookmarkList[]> {

    const args: ApiVariables<'getBookmarkLists'> = {
      thumbnailSize: [bookmarkThumbnailSize]
    };

    const [bkmLstProps, err] = await this.api.getBookmarkLists(args, opts);
    if (err)
      return [null, err];
    assertNotNull(bkmLstProps);
    const lists = bkmLstProps.getBookmarkLists;
    this.clearBookmarkLists();

    return [lists.map((list: any) =>
      this.insertBookmarkList(list))];
  }

  async apiFetchBookmarkList(id: string): AsyncResult<BookmarkList> {

    const args: ApiVariables<'getBookmarkList'> = Object.assign({ id }, {
      thumbnailSize: [bookmarkThumbnailSize]
    });

    const [bkmLstProps, err] = await this.api.getBookmarkList(args);
    if (err)
      return [null, err];
    assertNotNull(bkmLstProps);
    this.clearBookmarkLists();

    const bkmLst = this.insertBookmarkList(bkmLstProps.getBookmarkList);
    return [bkmLst];
  }

  async apiCreateBookmarkList(args: ApiVariables<'createBookmarkList'>): AsyncResult<BookmarkList> {
    const [bkmLstProps, err] = await this.api.createBookmarkList(args);
    if (err)
      return [null, err];
    assertNotNull(bkmLstProps);
    const bkmLst = this.insertBookmarkList(bkmLstProps.createBookmarkList);
    return [bkmLst];
  }

  async apiUpdateBookmarkList(args: ApiVariables<'updateBookmarkList'>): AsyncResult<BookmarkList> {
    const [bkmLstProps, err] = await this.api.updateBookmarkList(args);
    if (err)
      return [null, err];
    assertNotNull(bkmLstProps);
    const bkmLst = this.insertBookmarkList(bkmLstProps.updateBookmarkList);
    return [bkmLst];
  }

  /**
   * Makes a 'deleteBookmarkList' API call, disposes the object and removes it from the store upon completion.
   * @returns The deleted BookmarkList instance.
   */
  async apiDeleteBokmarkList(args: ApiVariables<'deleteBookmarkList'>): AsyncResult<BookmarkList> {
    const [delRes, err] = await this.api.deleteBookmarkList(args);
    if (err)
      return [null, err];
    assertNotNull(delRes);

    const bkmLst = this.removeBookmarkList(delRes.deleteBookmarkList.deletedId);

    // remove all associated bookmarks
    bkmLst.bookmarks.forEach(bkm => {
      this.removeBookmark(bkm.id);
    });

    // TEMP HACK
    if (this.bookmarkLists.length === 1)
      await this.apiFetchBookmarks({
        listId: this.bookmarkLists[0].id,
        thumbnailSize: [bookmarkThumbnailSize]
      });

    return [bkmLst];
  }
  // #endregion
}