import './analyticsWidget.scss';

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { AnalyticsWidgetState } from './analyticsWidgetState';
import { Grid, LoadMask } from '../../components';
import { DateInput } from '../../components/input/dateInput/dateInput';
import { OverlaysChunk } from '../../services/overlays';
import { NotificationsChunk } from '../../services/notifications';
import { WidgetErrorPage } from '../../pages/error';
import {
  PerformanceChart,
  TeamPerformanceChart,
  AudienceFocusChart,
  HoursCliprdChart,
  BookmarksChart,
  TopicViewsChart,
  ReactionsChart,
} from '../../components/chart';
import { MetricsWrapper } from '../../components/chart/metricsWrapper/metricsWrapper';
import { useRouteContext } from '../../routes/routeHooks';

import { TRACE } from '../../core/debug/debugMacros';

type Props = {
  model: AnalyticsWidgetState
};

export const AnalyticsWidget = observer(({
  model
}: Props) => {

  TRACE(model, `render()`);

  const routeContext = useRouteContext();
  useEffect(() => {
    model.attached(routeContext);
    return () =>
      model.detached();
  }, [model, routeContext]);

  if (model.error) {
    return <WidgetErrorPage
      error={model.error} />;
  }

  return (
    <main id="analytics-widget"
      className="analytics-widget catalog-page"
      aria-busy={model.isLoading}>

      <section className="page-subheader">
        <div className="flex-left">
          <div className="page-filters">
            <span className="filter-label">Filter by:</span>
            <DateInput
              model={model.dateInput}
              onApply={() => {
                model.handleDateInputChange();
              }} />
          </div>
        </div>
      </section>

      <div className="analytics-viewport">

        <MetricsWrapper model={model.metricsWrapper} />

        <Grid className="charts-grid">
          <li className="grid-item">
            <PerformanceChart model={model.performanceChart} />
          </li>

          <li className="grid-item">
            <ReactionsChart model={model.reactionsChart} />
          </li>

          <li className="grid-item">
            <BookmarksChart model={model.bookmarksChart} />
          </li>

          <li className="grid-item">
            <HoursCliprdChart model={model.hoursCliprdChart} />
          </li>

          <li className="grid-item">
            <AudienceFocusChart model={model.audienceFocusChart} />
          </li>

          <li className="grid-item">
            <TopicViewsChart model={model.topicViewsChart} />
          </li>

          {model.teamId && (<li className="grid-item">
            <TeamPerformanceChart model={model.teamPerformanceChart} />
          </li>)}
        </Grid>
      </div>

      <OverlaysChunk />
      <NotificationsChunk />

      <LoadMask
        className="widget-load-mask partial"
        visible={model.isLoading} />
    </main>
  );
});

export default AnalyticsWidget;