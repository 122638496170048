import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { DateTime } from 'luxon';
import { LiveJobSourceInput, UpdateJobInput, StartLiveJobInput, JobType, JobSpeciality } from '@clipr/lib';
import { AsyncResult, hasKey } from '../../core';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { JobModel, JobVideoTypeList } from '../../entities';
import { input, inputGroup, InputGroupState, InputState } from '../input';
import { getEnrichmentLevelLabel, JobLevel, getDefaultEnrichmentLevel, generateJobLevelOutput, getDefaultJobLevelInput } from '../../entities/job/jobFeatures';
import { fileInput } from '../input/fileInputState';
import { ApiVariables } from '../../api/apiSchema';
import { ApiUploadRequestParams } from '../../api/apiUploadRequest';
import { LanguageItems, getDefaultLanguageValue } from '../../entities/language';
import { getJobSpecialityInput, SpecialityItems, getDefaultJobSpeciality, getJobSpecialityOutput, FilteredSpecialityItems } from '../../entities/job/jobSpeciality';
import { UploadTask } from '../../services/upload/uploadTask';
import { dateInput } from '../input/dateInput/dateInputState';
import { timeInput } from '../input/timeInput/timeInputState';
import { DeleteStreamWindowState } from './deleteStreamWindowState';

// TODO: Major cleanup and tightening up required on this file and component.
export type ScheduleStreamInputs = 'title' | 'enrichmentLevel' | 'speciality' | 'thumbnail' | 'language' | 'date' | 'time' | 'publicStream' | 'streamVia' | 'connectionString' | 'connectionPass' | 'playerSource';

export const scheduleStreamInputs = {
  default: {
    main: ['title', 'speciality', 'language', 'enrichmentLevel', 'videoType', 'thumbnail', 'date', 'time', 'publicStream', 'streamVia', 'connectionString', 'connectionPass', 'playerSource', 'ingestMode'] as ScheduleStreamInputs[],
    metadata: []
  },
  partial: {
    main: ['title', 'speciality', 'language', 'enrichmentLevel', 'videoType', 'date', 'time', 'publicStream', 'streamVia', 'ingestMode'] as ScheduleStreamInputs[],
    metadata: []
  },
  liveStarted: {
    main: ['title', 'speciality', 'language', 'enrichmentLevel', 'videoType', 'thumbnail', 'publicStream', 'streamVia', 'connectionString', 'connectionPass', 'playerSource', 'ingestMode'] as ScheduleStreamInputs[],
    metadata: []
  }
}

type VideoDetailsLayout = 'default';

export class ScheduleStreamWindowState
  extends StoreNode {

  readonly nodeType = 'ScheduleStreamWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(this.windowListener);

    this.inputGroup = inputGroup(this, {
      name: 'window',
      inputs: [
        this.mainGroup,
      ],
      isSubmitDisabled: () =>
        this.inputGroup.inputs.some(input =>
          //@ts-ignore
          input.inputs.some(input => input.status === 'error' && input.isTouched && !input.isFocused)
        )
    });
  }

  readonly window = new WindowState(
    this.store,
    { ignoredTargetOnOutsideClickSelector: 'ant-picker-dropdown' });


  //main fields
  readonly title = input(this, {
    name: 'title',
    isRequired: true,
    showStatusMessage: true,
    error: (input, fallback) =>
      (input.value && input.value.length < 5) ? 'Add at least 5 characters' : fallback
  });

  readonly publicStream = input(this, {
    name: 'publicStream',
    selectorItems: [
      { value: 'public', label: 'Public' },
      { value: 'private', label: 'Private' }
    ]
  });

  readonly ingestMode = input(this, {
    name: 'ingestMode',
    selectorItems: [
      { value: 'automatic', label: 'Automatic' },
      { value: 'manual', label: 'Manual' }
    ],
    disabled: () => !!this.jobId
  });

  readonly streamVia = input(this, {
    name: 'streamVia',
    selectorItems: [
      { value: 'clipr', label: 'CLIPr' },
      { value: 'personal', label: 'My HLS Stream source' }
    ],
    disabled: () => !this.isStreamEditable,
    onChange: (state) => this.onStreamViaChange(state.value)
  })

  readonly connectionString = input(this, {
    name: 'connectionString',
    showStatusMessage: true,
    error: (input, fallback) =>
      (input.value && input.value.length < 5) ? 'Add at least 5 characters' : fallback,
    disabled: true
  });

  readonly connectionPass = input(this, {
    name: 'connectionPass',
    showStatusMessage: true,
    error: (input, fallback) =>
      (input.value && input.value.length < 5) ? 'Add at least 5 characters' : fallback,
    disabled: true
  });

  readonly playerSource = input(this, {
    name: 'playerSource',
    showStatusMessage: true,
    disabled: () => this.shouldPlayerSourceBeReadonly,
    error: (input, fallback) =>
      (input.value && input.value.length < 5) ? 'Add at least 5 characters' : fallback,
  });

  readonly thumbnail = fileInput(this, {
    name: 'thumbnail'
  });

  readonly date = dateInput(this, {
    placeholder: 'mm-dd-yyyy',
    format: ['MM-DD-YYYY', 'MMDDYYY'],
    rangeDatePicker: false,
    disabled: !this.isStreamEditable,
    isRequired: true
  });

  readonly time = timeInput(this, {
    disabled: !this.isStreamEditable,
    isRequired: true
  });

  readonly enrichmentLevel = input(this, {
    name: 'enrichmentLevel',
    isRequired: true,
    selectorItems: () => JobLevel.map(item => ({
      value: item,
      label: getEnrichmentLevelLabel(item)
    })),
    disabled: () => !this.isJobEditable
  });

  readonly videoType = input(this, {
    name: 'videoType',
    selectorItems: JobVideoTypeList,
    placeholder: "Select Video Type",
    disabled: () => !this.isJobEditable,
  });

  readonly speciality = input(this, {
    name: 'speciality',
    isRequired: !this.isSpecialityInputDisabled,
    selectorItems: () => this.teamManager.getTeam(this.teamId)?.publicSafety ? 
    SpecialityItems : 
    FilteredSpecialityItems,
    disabled: () => !this.isJobEditable || this.isSpecialityInputDisabled,
  });

  readonly language = input(this, {
    name: 'language',
    isRequired: true,
    selectorItems: LanguageItems,
    disabled: () => !this.isJobEditable,
    error: (input: InputState) => {
      if (this.speciality.normValue === 'Medical' && input.normValue !== 'en-US')
        return 'Only US English supported';
    },
  });

  readonly mainGroup = inputGroup(this, {
    name: 'window',
    inputs: [
      this.title,
      this.speciality,
      this.language,
      this.enrichmentLevel,
      this.thumbnail,
      this.publicStream,
      this.ingestMode,
      this.streamVia,
      this.connectionPass,
      this.connectionString,
      this.playerSource,
      this.videoType,
      this.time.inputGroup
    ]
  });

  readonly inputGroup: InputGroupState;

  @observable isLoading: boolean = false;
  @observable jobId: string | null = null;
  @observable teamId: string | null = null;
  @observable task: UploadTask | null = null;
  @observable layoutType: VideoDetailsLayout | null = null;

  @computed get teamManager() {
    return this.store.teamManager;
  }
  
  @computed
  get isSpecialityInputDisabled() {
    return this.enrichmentLevel?.value === 'media';
  }

  @computed
  get formIsDirty(): boolean {
    return this.inputGroup.isDirty || this.date.isDirty;
  }

  @computed
  get specialtyTooltipContent(): string {
    return 'Video specialty Select “Medical” for videos in which accuracy in medical transcription is critical. For all other videos, select “Standard.”';
  }

  @computed
  get shouldPlayerSourceBeReadonly(): boolean {
    return (this.streamVia.value === 'clipr' || !this.isStreamEditable || (this.streamVia.value !== 'clipr' && !this.isStreamEditable)) ?? false;
  }

  @computed
  get isStreamEditable(): boolean {
    if (!this.job) return true;

    return this.job?.isLiveStreamWaiting ?? false;
  }

  @computed
  get isJobEditable(): boolean {
    if (!this.job) return true;

    return (this.job?.isLiveStreaming || this.job?.isLiveStreamWaiting) ?? false;
  }

  @computed
  get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed
  get windowTitle(): string {
    return this.jobId ? 'Edit Stream' : 'Schedule Stream';
  }

  @computed
  get mainInputs(): string[] | null {
    let type = this.layoutType;

    if (type && hasKey(scheduleStreamInputs, type))
      return scheduleStreamInputs[type].main
    else
      return null;
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
        if (this.isLoading || this.inputGroup.isSubmitting)
          return;

        this.cancel(false);
        break;

      case 'outsideClick':
        if (this.isLoading || this.inputGroup.isSubmitting)
          return;

        this.cancel(true);
        break;
    }
  };

  @action
  cancel = (withConfirmation: boolean = false) => {
    if (this.inputGroup.isSubmitting || !this.window.isVisible) //|| !this.isVisible
      return;

    if (this.formIsDirty && withConfirmation) {
      this.store.closeWindowConfirmationModal.open({
        onSubmit: () => {
          this.clearState();
          this.close('close');
        },
        modalMessage: 'Are you sure you want to close the video details window? The progress will be lost.'
      })
    } else {
      this.close('close');
    }
  }

  @action
  async init() {
    this.isLoading = true;

    // try to fetch the job
    if (this.jobId) {
      await this.store.jobManager.apiFetchJob(this.jobId!);
      // try to find an active task
      if (!this.job) {
        const activeTask = this.store.uploadService.tasks.find(task => task.syncJobId === this.jobId);
        this.task = activeTask || null;
      }

      if (!this.job && !this.task)
        this.handleFailed();
    }
  }

  @action
  handleFailed() {
    this.close();
    this.isLoading = false;
    notifyError(this, 'Job does not exist.');
  }

  @action
  handleError(msg: string) {
    this.isLoading = false;
    notifyError(this, msg);
  }

  @action
  handleSuccess() {
    this.isLoading = false;
  }

  @action
  async submit() {
    this.date.handleSubmit();
    this.inputGroup.handleSubmit();

    if (this.inputGroup.error || this.date.error) {
      this.inputGroup.handleSubmitReject();
      notifyError(this, 'Fix the validation errors before saving.');
      return;
    }

    if (this.jobId) {
      this.updateLiveJob();
      return;
    }

    this.startLiveJob();
  }

  @action
  async updateLiveJob() {
    const mainData = this.mainGroup.export();

    const {
      title,
      description,
      publicStream,
      connectionPass,
      streamVia,
      playerSource,
      language,
      videoType,
      enrichmentLevel,
      speciality,
      time } = mainData;

    const [fileParams, errFile] = await this.submitThumbnail();
    if (errFile)
      return;

    let updateJobInput: UpdateJobInput = {
      id: this.jobId!,
      title,
      description,
      isPublic: publicStream === 'public' ?? false,

      teamId: this.teamId ?? undefined
    };

    Object.assign(updateJobInput, fileParams);

    // Refresh job and check if job status changed while editing was in progress
    const [, err] = await this.store.jobManager.apiFetchJob(this.jobId!);

    if (err) {
      this.isLoading = false;
      this.inputGroup.handleSubmitReject();
      return notifyError(this, `Cannot get job.`);
    }

    let requests: AsyncResult[] = [];

    // Update stream only when stream is still waiting
    if (this.isStreamEditable) {
      const dateInput = this.date.formattedValue;

      // Build scheduled date/time 
      if (time && dateInput) {
        const { hours, minutes, timezone } = time;
        const zone = `${Number(timezone) >= 0 ? 'UTC+' : 'UTC'}${timezone}`
        const formattedDate = DateTime.fromISO(dateInput, { zone });

        const seconds = 0;
        let scheduledDateTime: string | DateTime =
          formattedDate
            .set({ hour: hours })
            .set({ minute: minutes })
            .set({ second: seconds })
            .toISO();

        updateJobInput = { ...updateJobInput, scheduledDateTime }
      }

      let updateLiveSourceInput: LiveJobSourceInput = {
        password: connectionPass,
        useCliprHLS: streamVia === 'clipr'
      }

      if (mainData.streamVia === 'personal') {
        updateLiveSourceInput = {
          ...updateLiveSourceInput,
          liveStreamUrl: playerSource
        }
      }

      requests.push(this.store.jobManager.apiUpdateLiveJobSource(this.jobId!, updateLiveSourceInput));
    }

    if (this.isJobEditable) {
      updateJobInput = {
        ...updateJobInput,
        languageCode: language,
        videoType,
        features: generateJobLevelOutput(enrichmentLevel) ?? getDefaultJobLevelInput(),
        medicalSpecialty: getJobSpecialityOutput(speciality),
      }
    }

    requests = [
      ...requests,
      this.store.jobManager.apiUpdateJob(updateJobInput)];

    this.isLoading = true;

    const response = await Promise.all(requests);

    if (response.length > 1) {
      const [[, err1], [, err2]] = response;
      if (err1 || err2) {
        this.handleSubmitFailed();
        return;
      }
    } else {
      const [, error] = response;
      if (error) {
        this.handleSubmitFailed();
        return;
      }
    }

    this.isLoading = false;
    this.inputGroup.handleSubmitResolve();
    notifySuccess(this, `Stream was updated.`);
    this.close('jobUpdated');
  }

  @action
  handleSubmitFailed() {
    this.isLoading = false;
    this.inputGroup.handleSubmitReject();
    return notifyError(this, `Failed to update stream.`);
  }

  @action
  async startLiveJob() {
    const mainData = this.mainGroup.export();

    const {
      title,
      description,
      publicStream,
      streamVia,
      language,
      videoType,
      enrichmentLevel,
      speciality,
      ingestMode,
      time } = mainData;


    const liveSource: LiveJobSourceInput = {
      useCliprHLS: streamVia === 'clipr'
    }

    let startLiveJob: StartLiveJobInput = {
      title,
      description,
      isPublic: publicStream === 'public' ?? false,
      source: liveSource,
      teamId: this.teamId!,
      languageCode: language,
      videoType,
      ingestType: ingestMode === 'automatic' ? 'Automatic' : 'Manual',
      features: generateJobLevelOutput(enrichmentLevel) ?? getDefaultJobLevelInput(),
      medicalSpecialty: getJobSpecialityOutput(speciality),
      speciality: this.speciality.value as JobSpeciality ?? undefined
    };

    const dateInput = this.date.formattedValue;

    // Build scheduled date time 
    if (time && dateInput) {
      const { hours, minutes, timezone } = time;
      const zone = `${Number(timezone) >= 0 ? 'UTC+' : 'UTC'}${timezone}`
      const formattedDate = DateTime.fromISO(dateInput, { zone });
      const seconds = 0;
      let scheduledDateTime: string | DateTime =
        formattedDate
          .set({ hour: hours })
          .set({ minute: minutes })
          .set({ second: seconds })
          .toISO();

      startLiveJob = { ...startLiveJob, scheduledDateTime };
    }

    this.isLoading = true;
    const [, err1] = await this.store.jobManager.apiStartLiveJob(startLiveJob);

    if (err1) {
      this.isLoading = false;
      this.inputGroup.handleSubmitReject();
      return notifyError(this, `Failed to start live job.`);
    }

    this.inputGroup.handleSubmitResolve();
    notifySuccess(this, `Live job scheduled.`);
    this.close('jobUpdated');
    this.isLoading = false;
  }

  @action
  async submitThumbnail() {
    if (!this.jobId)
      return [null, null];

    const thumbnailFile = this.thumbnail.export();
    if (!thumbnailFile)
      return [null, null];

    this.isLoading = true;

    const input: ApiVariables<'getJobThumbnailUploadURL'> = {
      input: {
        id: this.jobId,
        filename: thumbnailFile.name
      }
    }
    // Get the upload URL
    const [res, err] = await this.store.apiService.getJobThumbnailUploadURL(input);

    if (err || !res) {
      runInAction(() => this.handleError('Could not retrieve thumbnail upload url.'));
      return [null, true];
    }

    const params = res.getJobThumbnailUploadURL;
    const uploadParams: ApiUploadRequestParams = {
      file: thumbnailFile,
      url: params.uploadUrl,
      data: params.fields
    }

    // Start the upload request
    const uploadRequest = this.store.apiService.uploadRequest(uploadParams);
    const [, uploadErr] = await uploadRequest.start();

    if (uploadErr) {
      runInAction(() => this.handleError('Could not upload thumbnail.'));
      return [null, true];
    }

    const updateParams = {
      posterToken: params.uploadToken,
    };

    return [updateParams, false];
  }

  @action
  async open({ layoutType, teamId, jobId }: { layoutType: VideoDetailsLayout, teamId?: string, jobId?: string }) {
    this.jobId = jobId || null;
    this.teamId = teamId || null;
    this.layoutType = layoutType;

    this.dispatch('Overlays', 'openWindow', { name: 'ScheduleStreamWindow' });
    this.emit('open');
    this.window.open();

    await this.init();
    this.initMainForm();
    this.handleSuccess();
  }

  @action
  initMainForm() {
    this.job ?
      this.loadFromJob() :
      this.initEmptyForm();
  }

  @action
  initEmptyForm() {
    this.title.loadValue(null);
    this.videoType.loadValue(null);
    this.streamVia.loadValue('clipr');
    this.publicStream.loadValue('public');
    this.ingestMode.loadValue('automatic');
    this.thumbnail.loadPreviewUrl(null);
    this.language.loadValue(getDefaultLanguageValue());
    this.speciality.loadValue(getDefaultJobSpeciality());
    this.enrichmentLevel.loadValue(getDefaultEnrichmentLevel());
    this.videoType.loadValue(JobType.Presentation);

    this.date.loadValue(null);

    this.time.loadHour(null);
    this.time.loadMinutes(null);
    this.time.loadTimezone(null);

  }

  @action
  loadFromJob() {
    const { job } = this;
    if (!job)
      return;

    const { liveStream, source } = job;

    this.title.loadValue(job.title);
    this.publicStream.loadValue(job.isPublic ? 'public' : 'private');
    this.thumbnail.loadPreviewUrl(job.posterURL);
    this.connectionPass.loadValue(job.activeLiveStreamKey);
    this.connectionString.loadValue(job.activeLiveIngestUrl);
    this.ingestMode.loadValue(job.liveStream?.ingestType === 'Automatic' ? 'automatic' : 'manual');
    this.streamVia.loadValue(source.liveCliprHLS ? 'clipr' : 'personal');
    this.enrichmentLevel.loadValue(JobLevel.find(item => item === job.enrichmentLevel));
    this.language.loadValue(LanguageItems.find(item => item.value === job.languageCode)?.value);
    this.playerSource.loadValue(source.liveCliprHLS ? job.liveStreamURL : source.liveStreamUrl);
    this.videoType.loadValue(JobVideoTypeList.find(item => item.value === this.job?.videoType)?.value ?? JobType.Presentation);
    this.speciality.loadValue(SpecialityItems.find(item => item.value === getJobSpecialityInput(job || null)));

    // We will remove this once the general refactoring of the dateinput is made
    this.date.setProp('isRequired', this.isStreamEditable);

    // This solution should be reevaluated!!
    this.time.setRequired(this.isStreamEditable);

    if (liveStream?.scheduledDateTime) {
      const scheduledDateTime = DateTime.fromISO(liveStream?.scheduledDateTime, { setZone: true });
      const hours = scheduledDateTime.hour.toString();
      const minutes = scheduledDateTime.minute.toString();

      const { offset } = scheduledDateTime;
      const timezone = offset === 0 ? offset : offset / 60;
      this.date.loadValue(liveStream?.scheduledDateTime);

      this.time.loadHour(hours);
      this.time.loadMinutes(minutes);
      this.time.loadTimezone(timezone);
    }
  }

  @action
  onStreamViaChange(value: string | null) {
    if (!this.job) return;

    const { liveStream } = this.job;

    if (value === 'clipr') {
      this.playerSource.value = liveStream?.playlists.primary ?? null;
      return;
    }

    this.playerSource.value = liveStream?.playlists.external ?? null;
  }

  @action
  async copyServerStreamUrl() {
    const value = this.job?.activeLiveIngestUrl;
    if (value)
      await this.store.ui.clipboard.writeText(value, 'Server URL copied to clipboard!');
  }

  @action
  async copyServerKey() {
    const value = this.job?.activeLiveStreamKey;
    if (value)
      await this.store.ui.clipboard.writeText(value, 'Server key copied to clipboard!');
  }

  @action
  async copyPlayerSource() {
    const value = this.playerSource.value;
    if (value)
      await this.store.ui.clipboard.writeText(value, 'Player source copied to clipboard!');
  }

  @action
  openDeleteStreamWindow = () => {
    this.dispatch('openDeleteStreamWindow', {
      streamId: this.job?.id,
      streamName: this.job?.title,
    })

    const deleteStreamWindow = this.store.deleteStreamWindow;
    const deleteStreamWindowListener = (msg: Message<DeleteStreamWindowState>) => {
      switch (msg.type) {
        case 'streamDeleted':
          this.close('jobUpdated');
          deleteStreamWindow.unlisten(deleteStreamWindowListener);
          break;
        case 'close':
          deleteStreamWindow.unlisten(deleteStreamWindowListener);
          break;
      }
    }

    deleteStreamWindow.listen(deleteStreamWindowListener);
  }

  @action
  clearState() {
    this.task = null;
    this.jobId = null;
    this.layoutType = null;

    this.date.reset();
    this.time.reset();
    this.inputGroup.clear();
  }

  @action
  close(msg: string = 'close') {
    this.emit(msg);
    this.clearState();
    this.window.close();
    this.dispatch('Overlays', 'closeWindow');
  }

  @action
  onTransitionEnd = () => {
    if (!this.window.isVisible)
      this.clearState();
  }
}
