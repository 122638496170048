import React from 'react';
import { observer } from 'mobx-react-lite';
import { OverlaysChunk } from '../../services/overlays';
import { NotificationsChunk } from '../../services/notifications';
import { Maybe } from '../../core';

const DefaultChunks: ChunkName[] = [
  'overlays',
  'notifications'
];

export type ChunkName =
  'overlays' |
  'notifications';

export type ChunksPropValue = Maybe<'all' | 'none' | ChunkName[]>;

type Props = {
  chunks?: ChunksPropValue
}

function unwrapChunkNames(chunks?: ChunksPropValue): ChunkName[] {
  if (!chunks)
    return DefaultChunks;
  if (typeof chunks === 'string') {
    switch (chunks) {
      case 'none':
        return [];

      case 'all':
      default:
        return DefaultChunks;
    }
  }

  return chunks;
}

/** 
 * Manages injection of service components (chunks) responsible for global tasks in the page.
 */
export const PageChunks = observer(({
  chunks = 'all'
}: Props) => {

  let chunkNames = unwrapChunkNames(chunks);

  return (
    <>
      {chunkNames.includes('overlays') &&
        <OverlaysChunk />}

      {chunkNames.includes('notifications') &&
        <NotificationsChunk />}
    </>
  );
});