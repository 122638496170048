import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../../components/overlays';
import { Button } from '../../components/input';
import { CancelUploadWindowState } from './cancelUploadWindowState';

type Props = {
  model: CancelUploadWindowState;
  visible?: boolean;
};

export const CancelUploadWindow = observer(({ model, visible }: Props) => {
  return (
    <Window
      model={model.window}
      visible={visible}
      isLoading={model.isLoading}
      className="cancel-upload-window">
      <WindowHeader model={model.window} title="Cancel upload" />

      <hr />

      <div className="window-content">
        {model.isUploadFinished && (
          <>
            <p className="p-bold">Sorry, meanwhile upload already finished</p>
            {model.isReplacingVideo ? (
              <p>Your video has been replaced</p>
            ) : (
              <p>
                You can check your file in <strong>{model.uploadSource}</strong>{' '}
                and delete it from there.
              </p>
            )}
          </>
        )}

        {!model.isUploadFinished && (
          <>
            <p className="p-bold">
              Are you sure you want to cancel the current upload?
            </p>
            <p>Any progress on this upload will be lost.</p>
          </>
        )}
      </div>

      {!model.isUploadFinished && (
        <>
          <hr />
          <footer className="window-footer">
            <div className="btn-group right">
              <Button
                className="red medium"
                label="Cancel Upload"
                onClick={(evt) => model.submit()}
              />
              <Button
                className="green medium"
                label="Continue Upload"
                onClick={(evt) => model.cancel()}
              />
            </div>
          </footer>
        </>
      )}
    </Window>
  );
});
