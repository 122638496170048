import './media.scss';

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { JobModel } from '../../entities';
import { JobCard } from './jobCard';
import { Catalog, Grid } from '../layout';
import { ICatalogSource } from '../layout/catalog';
import { CatalogState } from '../layout/catalogState';
import { ChevronLeft2SVGIcon, ChevronRight3SVGIcon, MyUploadsSVGIcon } from '../svg';
import { DotSpinner } from '../loader';
import { Case, Switch } from '../layout/switch';
import { Button } from '../input';
import { View } from '../../services/ui/utils';

type Props = {
  model: CatalogState;
  source: ICatalogSource<JobModel>;
  teamId?: string;
};

export const JobCarouselCatalog = observer(({ model, source, teamId }: Props) => {
  const { entities } = source;
  const carousel = model.carouselState;
  const { transformPosition, isAtCarouselEnd, gridStyles, handleNext, handlePrev } = carousel;

  useEffect(() => {
    carousel.updateGridWidth(entities.length)
  }, [entities, carousel]);

  useEffect(() => {
    carousel.setWindowWidth(window.innerWidth)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [window.innerWidth, carousel])

  return (
    <Catalog className="job-card-catalog catalog carousel" model={model}>
      {entities.length > 0 && (
        <>
          <Grid className="catalog-grid catalog-carousel" style={gridStyles}>
            {entities.map((job) => {
              return (
                <li key={job.id} className="grid-item carousel-item catalog-item">
                  <JobCard
                    model={job}
                    target='_blank'
                    teamId={teamId}
                    isExpandable={false}
                    view={View.Carousel} />
                </li>
              );
            })}
          </Grid>

          {transformPosition !== 0 && (
            <Button
              className='carousel-control previous'
              onClick={() => handlePrev()}
              icon={<ChevronLeft2SVGIcon />} />
          )}
          {(!model.isEndOfList || !isAtCarouselEnd) && (
            <Button
              className={`carousel-control next ${model.syncStatus === 'fetchingMore' ? 'loading' : ''}`}
              onClick={() => handleNext()}
              icon={<ChevronRight3SVGIcon />} />
          )}

          <Footer model={model} />
        </>
      )}

      {entities.length === 0 && (
        <div className="empty-box" aria-hidden={model.isLoading}>
          <div className="catalog empty-catalog">
            <div className="icon">
              <MyUploadsSVGIcon />
            </div>
            <h1 className="headline-1">
              {teamId
                ? 'Uploaded content will appear here.'
                : ' Looks like you haven’t uploaded any videos.'}
            </h1>
          </div>
        </div>
      )}
    </Catalog>
  );
});

const Footer = observer(({
  model
}: { model: CatalogState }) => {
  return (
    <div className="catalog-footer">
      <Switch>
        <Case name="fetching"
          className="footer-fetching"
          visible={model.syncStatus === 'fetchingMore'}>
          <DotSpinner />
        </Case>
      </Switch>
    </div>
  );
});