import '../jobs/jobs.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Catalog } from '../layout';
import { CatalogState } from '../layout/catalogState';
import { CatalogFooter } from '../layout/catalogFooter';
import { OneDriveSVGIcon, SearchSVGIcon } from '../svg';
import { LibraryCatalogSource } from '../../entities';
import { LibraryTableRow } from './libraryTableRow';

type Props = {
  model: CatalogState;
  source: LibraryCatalogSource;
};

export const OneDriveTableCatalog = observer(({ model, source }: Props) => {
  const { entities } = source;

  return (
    <Catalog
      className="job-table-catalog one-drive-table-catalog catalog"
      model={model}>
      {entities.length > 0 && (
        <>
          <table className="job-table one-drive-table job-table-core">
            <tbody>
              {entities.map((job) => {
                return (
                  <LibraryTableRow
                    view="onedrive"
                    key={job.id}
                    model={job}
                    source={source}
                  />
                );
              })}
            </tbody>
          </table>

          <CatalogFooter model={model} />
        </>
      )}

      {entities.length === 0 &&
        (!!source.searchFilter ? (
          <div className="empty-box" aria-hidden={model.isLoading}>
            <div className="catalog empty-catalog">
              <div className="icon">
                <SearchSVGIcon />
              </div>

              <h1 className="headline-1">
                No results found for "{source.searchFilter}".
              </h1>
              <p>
                Make sure all words are spelled correctly or try different
                keywords.
              </p>
            </div>
          </div>
        ) : (
          <div className="empty-box" aria-hidden={model.isLoading}>
            <div className="catalog empty-catalog">
              <div className="icon">
                <OneDriveSVGIcon />
              </div>
              <h1 className="headline-1">
                Looks like you haven’t uploaded any videos.
              </h1>
            </div>
          </div>
        ))}
    </Catalog>
  );
});
