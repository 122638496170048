import { action, computed, observable } from 'mobx';
import { Constructor, Result } from '../../core';
import { Error } from '../../core/error';
import { RouteContext } from '../../routes/routeContext';

export interface IPageState {
  isAttached: boolean;
  status: PageStatus;
  routeContext: RouteContext | null;
  error: Error | null;
}

export enum PageStatus {
  Empty = 'Empty',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error'
}

export function PageState<TBase extends Constructor>(Base: TBase) {

  class PageState extends Base implements IPageState {

    @observable isAttached = false;

    @observable routeContext: RouteContext | null = null;
    @observable status: PageStatus = PageStatus.Empty;
    @observable error: Error | null = null;

    @observable protected abortController: AbortController | null = null;

    @computed protected get abortSignal() {
      return this.abortController;
    }

    @action
    protected baseAttached(routeContext: RouteContext | null) {
      this.isAttached = true;
      this.routeContext = routeContext;
      this.abortController = new AbortController();
    }

    @action
    protected baseDetached() {
      this.baseReset();
    }

    @action
    protected baseAbort() {
      const abortCtrl = this.abortController;
      if (abortCtrl)
        abortCtrl.abort();
      this.abortController = null;
    }

    @action
    protected setError(err: Error) {
      this.error = err;
      this.status = PageStatus.Error;
    }

    @action
    protected setLoading() {
      this.error = null;
      this.status = PageStatus.Loading;
    }

    @action
    protected setLoaded() {
      this.error = null;
      this.status = PageStatus.Loaded;
    }

    protected handleError(err: Error): Result<never, Error> {
      this.setError(err);
      return [null, err];
    }

    protected baseReset() {
      this.error = null;
      this.status = PageStatus.Empty;
      this.isAttached = false;
      this.routeContext = null;
      this.baseAbort();
    }
  }

  return PageState;
}