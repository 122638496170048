import { AnalyticsStreamParams, AnalyticsStreamData } from "../analyticsSchema";
import * as Bowser from "bowser";
import { StoreNode } from '../../../store';
import { UserProfileData, JobModel, getVideoTypeLabel } from "../../../entities";
import { getJobSpecialityInput } from "../../../entities/job/jobSpeciality";
import { getEnrichmentLevelLabel } from "../../../entities/job/jobFeatures";
import { InstallContextType } from "../installContext";

export type AnalyticsMainData = {
  os?: {
    name?: string,
    version?: string,
    versionName?: string,
  },
  browser?: {
    name?: string,
    version?: string,
  },
  user?: UserProfileData,
  install?: InstallContextType
}

export type AnalyticsViewContextData = {
  context: {
    teamAnalyticsExclude?: string[] | null;
    team?: {
      id: string | null
    }
  }
}

export type AnalyticsCurrentJobData = {
  job: {
    id: string | null,
    title: string | null,
    language: string | null,
    status: string | null,
    bitrate: 'HD' | 'SD' | '4K' | '8K' | '-',
    videoDuration?: number | null,
    specialty: string | null,
    enrichmentLevel: string | null,
    videoType: string | null,
    source: string | null,
    owner: {
      team?: {
        id: string | null
        name: string | null
      },
      user?: {
        id: string | null
        name: string | null
      }
    },
  }
}

export type AnalyticsPlayerStateData = {
  player: {
    event:
    | 'play'
    | 'autoplay'
    | 'replay'
    | 'pause'
    | 'ended'
    | 'jumpToMoment'
    | 'jumpToComment'
    | 'jumpToReaction'
    | 'jumpToCommentOrReaction'
    | null,
    currentTime: number,
    speakerId: string | null,
    speakerName: string | null,
    activeTopicName: string | null,
    activeTopicId: string | null,
    activeSubTopicNames: string[],
    activeSubTopicIds: string[],
  }
}

export class AnalyticsStream extends StoreNode {
  exchangeData(data: AnalyticsStreamParams[keyof AnalyticsStreamParams] | any): AnalyticsStreamData[keyof AnalyticsStreamData] | null {
    return {
      ...data,
      ...(data?.job && this.currentJobData(data.job)),
      ...this.mainData,
      ...this.viewContextData
    };
  }

  get mainData(): AnalyticsMainData {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const { user, analytics } = this.store;

    return {
      user: user?.export(),
      os: browser.getOS(),
      browser: browser.getBrowser(),
      install: analytics.installContext.export()
    } as AnalyticsMainData
  }

  get viewContextData(): AnalyticsViewContextData {
    const { viewContext } = this.store.analytics;
    return viewContext.export();
  } 

  get playerData(): AnalyticsPlayerStateData {
    const { userPlayerPage, playerWidget } = this.store;
    const player = this.store.widget.isWidgetMode ? playerWidget.player : userPlayerPage.player;

    return {
      player: {
        event: null,
        currentTime: player?.currentTime ?? 0,
        speakerId: player?.momentView?.activeTranscript?.speaker?.id ?? null,
        speakerName: player?.momentView?.activeTranscript?.speaker?.name ?? null,
        activeTopicName: player?.momentView?.activeTopic?.name ?? null,
        activeTopicId: player?.momentView?.activeTopic?.id ?? null,
        activeSubTopicNames: player?.momentView?.activeSubTopics.map(i => i.name),
        activeSubTopicIds: player?.momentView?.activeSubTopics.map(i => i.id),
      }
    }
  }

  currentJobData(job: JobModel): AnalyticsCurrentJobData {
    let jobData: AnalyticsCurrentJobData = {
      job: {
        id: job?.id ?? null,
        title: job?.title ?? null,
        language: job?.languageCode ?? null,
        bitrate: '-',
        specialty: getJobSpecialityInput(job ?? null),
        enrichmentLevel: (job?.enrichmentLevel && getEnrichmentLevelLabel(job.enrichmentLevel)) ?? null,
        videoType: getVideoTypeLabel(job?.videoType ?? null),
        source:
          (job?.source?.type && job?.source.type !== 'ExternalLibrary' ?
            job?.source?.type :
            (job?.source?.externalLibrary?.source ?? null)) ?? null,
        status: job?.status ?? null as string | null,
        owner: {}
      }
    }

    if (job?.durationInMs) {
      jobData.job.videoDuration = job.durationInMs
    }

    if (job?.ownerTeamId) {
      let ownerTeamName: string | null = job?.owner?.team?.name ?? null;

      if(!ownerTeamName) {
        const team = this.store.teamManager.getTeam(job?.ownerTeamId)
        ownerTeamName = team?.name ?? null;
      }

      jobData.job.owner.team = {
        id: job?.ownerTeamId,
        name: ownerTeamName,
      }
    }

    if (job?.ownerUserId) {
      jobData.job.owner.user = {
        id: job?.ownerUserId,
        name: job?.user?.username ?? null
      }
    }

    return jobData;
  }
}
