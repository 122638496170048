import React from 'react';
import './teams.scss';
import { observer } from 'mobx-react-lite';
import { CheckboxInputControlled, RadioGroupInputControlled, TextInputControlled } from '../input';
import { FileInputControlled } from '../input/fileInput';
import { PublicTeamFormState } from './publicTeamFormState';
import { LAYOUT_ICONS } from '../layout/util';

type PublicTeamFormProps = {
  model: PublicTeamFormState;
};

export const PublicTeamForm = observer(({ model }: PublicTeamFormProps) => {

  return <div className="public-team-form scrollable">

    <TextInputControlled
      model={model.displayName}
      label="Display Name"
      className="form-input display-name bmar4"
      showCharacterCount={true} />

    <RadioGroupInputControlled
      label={"Video Gallery Display"}
      description={"Choose how library uploads will be rendered."}
      icons={LAYOUT_ICONS.viewIcons}
      className="horizontal-layout bmar4"
      model={model.view} />

    <RadioGroupInputControlled
      label={"Theme selection"}
      description={"Choose a color theme."}
      icons={LAYOUT_ICONS.themeIcons}
      className="horizontal-layout transparent-icon bmar4"
      model={model.theme} />

    <div className="flex-space-between bmar4">
      <div className="flex-column">
        <CheckboxInputControlled
          className="bmar1"
          model={model.showCover} />
        <FileInputControlled
          model={model.coverImage}
          multiple={true}
          layout={'preview-box'}
          layoutSize={'small'} />
      </div>

      <div className="flex-column">
        <CheckboxInputControlled
          className="bmar1"
          model={model.showLogo} />
        <FileInputControlled
          model={model.displayLogo}
          multiple={true}
          layout={'preview-box'}
          layoutSize={'small'} />
      </div>
    </div>
    <div>
      <TextInputControlled
        model={model.customRedirectUrl}
        label="Open in website url"
        className="form-input redirect-url bmar4" />
    </div>
  </div>
});