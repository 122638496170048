import { action, computed, makeObservable, observable } from 'mobx';
import { StoreNode } from '../../store';
import { Store } from '../../store/store';

/**
 * Cross-browser, observable object for managing the orientation in the app.
 * Based on the experimental Screen Orientation API: Check the Browser compatibility table carefully before using this in production.
 * https://developer.mozilla.org/en-US/docs/Web/API/Screen_Orientation_API
 */
export class OrientationManager
  extends StoreNode {

  readonly nodeType: 'OrientationManager' = 'OrientationManager';

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  onChange: (() => void) | null = null;

  @observable orientationType: OrientationType | null = null;

  @computed
  get isLandscape() {
    if (!this.orientationType)
      return false;

    return ["landscape-primary", "landscape-secondary"].includes(this.orientationType);
  }

  @computed
  get isPortrait() {
    if (!this.orientationType)
      return null;
      
    return ["portrait-primary", "portrait-secondary"].includes(this.orientationType);
  }

  /**
   * Cross-browser, non-observable getter for accessing the orientation from the screen.
   * If you want an observable property, use `orientationType`.
   */
  getOrientation(): ScreenOrientation | null {
    return window.screen?.orientation ?? null;
  }

  getOrientationType(): OrientationType | null {
    const orientation = this.getOrientation();
    return orientation?.type ?? null;
  }

  @action
  register(onChange?: () => void) {
    this.onChange = onChange ?? null;
    const orientation = this.getOrientation();
    this.orientationType = this.getOrientationType();
    orientation?.addEventListener('change', this.handleOrientationChange);
    this.isDisposed = false;
  }

  @action
  dispose() {
    const orientation = window.screen?.orientation ?? null;
    orientation?.removeEventListener('change', this.handleOrientationChange);
    this.onChange = null;
    this.orientationType = null;
    this.isDisposed = true;
  }

  async lockLandscape() {
    this.lock("landscape");
  }
  async lockPortrait() {
    this.lock("portrait");
  }

  async lock(target: OrientationLockType) {
    try {
      // will crash if there are no permissions, for example in an iframe
      // always check for possibility of thrown errors or promise rejections
      // in native APIs
      const orientation = this.getOrientation();
      await orientation?.lock(target);
    } catch (err) {
      // TODO: reinstate the warning after the `lock` call is done safely
      // and not on every fullscreen request, because it unnecessarily reports this
      // to console and sentry

      // console.warn(err);
    }
  }

  async unlock() {
    try {
      const orientation = this.getOrientation();
      orientation?.unlock();
    } catch (err) {
      // TODO: see above
      // console.warn(err);
    }
  }

  @action
  private handleOrientationChange = () => {
    this.orientationType = this.getOrientationType();
    if (this.onChange)
      this.onChange();
  }
}