import { action, computed, makeObservable, observable } from 'mobx';
import debounce from 'lodash/debounce';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { IPlayer } from './playerSchema';

type Props = BindingProps<{
  controller: IPlayer
}>

type PlayerChromeVisibility =
  'Hidden' |
  'Compact' |
  'Visible';

const CHROME_HIDE_TIMEOUT = 3000;

export class PlayerChromeState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  @computed get controller(): IPlayer | null {
    return this.resolvedProps.controller ?? null;
  }

  @observable visibility: PlayerChromeVisibility = 'Visible';

  @computed get showAllRegions() {
    return this.visibility === 'Visible';
  }
  @computed get showCriticalRegions() {
    return (
      this.visibility === 'Compact' ||
      this.visibility === 'Visible');
  }

  @observable isPinned: boolean = false;
  @observable isHovering: boolean = false;
  @observable isRegionHovering: boolean = false;
  @observable isVideoHovering: boolean = false;
  @observable isControlActionActive: boolean = false;
  @observable lastActivityTimestamp: number = 0;

  handlePointerMove = action(() => {
    this.visibility = 'Visible';
    this.handleEventDebounced();
  })

  handlePointerEnter = action(() => {
    this.isHovering = true;
  })

  handlePointerLeave = action(() => {
    this.isHovering = false;

  })

  handleTouchStart = action(() => {
    this.visibility = 'Visible';
    this.isVideoHovering = true;
    this.handleEventDebounced();
  })

  handleRegionPointerEnter = action(() => {
    this.isRegionHovering = true;
  })
  handleRegionPointerLeave = action(() => {
    this.isRegionHovering = false;
  });


  handleVideoPointerEnter = action(() => {
    this.isVideoHovering = true;
  })
  handleVideoPointerLeave = action(() => {
    this.isVideoHovering = false;
  })
  handleVideoClick = action(() => {
    this.controller?.invoke('togglePlay');
  })
  handleVideoDoubleClick = action(() => {
    this.controller?.invoke('toggleFullscreen');
  });

  toggleVisibility = action((visibility: PlayerChromeVisibility) => {
    this.visibility = visibility;

    if (visibility === 'Visible') this.handleRegionPointerEnter();
    if (visibility === 'Compact') this.handleRegionPointerLeave();
  });

  @action
  pin() {
    this.isPinned = true;
  }

  @action
  unpin() {
    this.isPinned = false;
  }

  @action
  togglePin() {
    if (this.isPinned)
      this.unpin();
    else
      this.pin();
  }

  private handleEventDebounced = debounce(() => this.hideAfterDebounce(),
    CHROME_HIDE_TIMEOUT);

  @action
  private hideAfterDebounce(): boolean {
    if (this.isRegionHovering || this.isPinned || this.isControlActionActive) {
      this.handleEventDebounced()
      return false;
    }

    this.visibility = 'Compact';
    return true;
  }

  @action
  startControlAction = () => { //this is a reactive observer to control the player layout based on component level states
    this.isControlActionActive = true;
  }

  @action
  endControlAction = () => {
    this.isControlActionActive = false;
  }
}