import React, { ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { AdsBarState } from './adsBarState';
import { ForwardStepSVGIcon } from '../../svg';
import { AdPodCueType } from '../../playerAdsAdapter';

type Props = {
  model: AdsBarState
}

export const AdsCard = observer(({
  model
}: Props) => {

  const { job, adsAdapter } = model;

  if (!adsAdapter.isActive)
    return null;

  const posterUrl = job?.posterURL;
  const posterElem =
    <div className="poster" style={{ backgroundImage: `url('${posterUrl}')` }} />

  const {
    canSkipRemainingTimeLabel,
    adRemainingTimeLabel
  } = model;

  const contentElem: ReactNode = (() => {

    if (model.canSkip) {
      return (
        <button className="ads-skip-btn" onClick={model.handleSkipButtonClick}>
          <span className="text">Skip Ads</span>
          <span className="icon">
            <ForwardStepSVGIcon />
          </span>
        </button>
      );
    }

    else if (model.isSkippable) {
      if (!canSkipRemainingTimeLabel)
        return null;

      // skip is allowed but the timer hasn't expired
      return (
        <div className="ads-skip-counter">
          <div className="message">
            <span className="time">{canSkipRemainingTimeLabel}</span>
          </div>
          {posterElem}
        </div>
      );
    }

    else if (
      model.isLeadingAd ||
      model.adsAdapter.adPodCueType === AdPodCueType.PostRoll) {

      if (!adRemainingTimeLabel)
        return null;

      return (
        <div className="ads-end-counter">
          <div className="message">
            <div className="content">
              <span>Ad will end in</span>
              <span className="time">{adRemainingTimeLabel}</span>
            </div>
          </div>
        </div>
      );
    }

    else if (model.isLastOrOnlyAd) {
      if (!adRemainingTimeLabel)
        return null;

      const label = (() => {
        switch (model.adsAdapter.adPodCueType) {
          case AdPodCueType.PreRoll:
            return `Video will start in `;

          case AdPodCueType.MidRoll:
            return `Video will resume in `;
        }
      })();
      
      return (
        <div className="ads-watch-counter">
          <div className="message">
            <div className="content">
              <span>{label}</span>
              <span className="time">{adRemainingTimeLabel}</span>
            </div>
          </div>
          {posterElem}
        </div>
      );
    }

  })();

  return (
    <div className="ads-card">
      {contentElem}
    </div>
  );
});

export const AdsInfo = observer(({
  model
}: Props) => {

  const { adsAdapter } = model;
  if (!adsAdapter.isActive)
    return null;

  return (
    <div className="ads-info">
      <span className="ads-label">{model.infoLabel}</span>
      <span className="ads-remaining-time">{model.infoRemainingTimeLabel}</span>
    </div>
  );
});

export const PlayerAdsBar = observer(({
  model
}: Props) => {

  useEffect(() => {
    model.mounted();
    return () =>
      model.unmounted();
  }, [model]);

  return (
    <div id="player-ads-bar">
      <AdsInfo model={model} />
      <AdsCard model={model} />
    </div>
  );
});