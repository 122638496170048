import { Duration, DurationObject } from 'luxon';
import omitBy from 'lodash/omitBy';
import overSome from 'lodash/overSome';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import isNaN from 'lodash/isNaN';

const SHORT_FORMAT = 'mm:ss';
const LONG_FORMAT = 'hh:mm:ss';
const LONG_FORMAT_THRESHOLD = 3600;

/**
 * Returns a formatted duration string based on a provided value in seconds.
 * @param val The timestamp in seconds.
 */
export function timeLabel(val: number): string {
  val = Math.round(val);
  return Duration
    .fromObject({ seconds: val })
    .toFormat(val >= LONG_FORMAT_THRESHOLD ? LONG_FORMAT : SHORT_FORMAT);
}


/**
 * Returns a formatted duration string based on a provided Duration object (in luxon format).
 * @param obj A luxon Duration object. See https://moment.github.io/luxon/docs/class/src/duration.js~Duration.html#static-method-fromObject
 */
export function timeLabelFromObject(obj: DurationObject): string {

  // TODO: test
  let duration = Duration.fromObject(obj);
  return duration
    .toFormat(duration.as('seconds') >= LONG_FORMAT_THRESHOLD ? LONG_FORMAT : SHORT_FORMAT);
}

export function duration(arg?: number | string | DurationObject): Duration {
  switch (typeof arg) {
    case 'number':
      return Duration.fromObject({ seconds: arg });
    case 'string':
      return Duration.fromISO(arg);
    case 'object':
      if (arg !== null)
        return Duration.fromObject(arg);
      break;
    default:
      break;
  }

  return Duration.fromMillis(0);
}

/** 
 * Returns the percentage string of a number representing a ratio. 
 * @param val       The ratio to format, where [0, 1] will map to [0%, 100%]. 
 *                  The value will not be clamped, so if you pass 100 you will actually get 10000%.
 * @param precision The precision to round the output to.
 */
export function percentage(val: number, precision: number = 0): string {
  return (val * 100).toFixed(precision) + '%';
}

/**
 * Returns a JSON object from a decoded base64 string
 * @param base64    The string to be decoded
 */
export function decodeBase64(base64: string, toJson: boolean = false): string | object {
  const stringified = decodeURIComponent(escape(window.atob(base64)));

  if (toJson) return JSON.parse(stringified);

  return stringified;
}

// WE ABSOLUTELY NEED TO RETHINK THIS SOLUTION!!! 
export function resolutionBasedPageSize(width?: number, sideBar?: boolean): number {
  if (width) {
    if (sideBar) {
      if ((width >= 1040 && width < 1296) || (width >= 2048 && width < 3440)) {
        return 18;
      } else if (width >= 3440) {
        return 16;
      }
    } else {
      if ((width >= 815 && width < 1072) || (width >= 1584 && width < 2048)) {
        return 18;
      } else if (width >= 2048) {
        return 14;
      } else if (width >= 3440) {
        return 16;
      }
    }
  }
  return 20;
}

export function ellipseText(text: string, limit: number) {
  if (text?.length > limit) {
    return text?.substring(0, limit - 1) + '...';
  } else {
    return text;
  }
};

/**
 *  Cleanup util function that removes null / undefined values from objects
 */
export function sanitizeObject(payload: any) {
  return omitBy(payload, overSome([isUndefined, isNaN, isNull]));
}


export function isInIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}