import { Maybe, MaybeProps } from '../../core';

export type AuthReplyType =
  'Success' |
  'WaitForRedirect' |
  'AuthorizedRedirect';

export type AuthReplyPayload<T extends AuthReplyType = 'Success'> =
  T extends 'WaitForRedirect' ? null :
  T extends 'AuthorizedRedirect' ? { redirectPath?: Maybe<string> } :
  null;

/** Base class for all success responses returned during the authentication flows. */
export class AuthReply<T extends AuthReplyType = 'Success'> {

  readonly type: T;
  readonly payload: AuthReplyPayload<T>;

  constructor(type: T, payload?: AuthReplyPayload<T>) {
    this.type = type;
    this.payload = payload! || null;
  }
}

export type AuthWaitForRedirectReply =
  AuthReply<'WaitForRedirect'>;

export type AuthAuthorizedRedirectReply =
  AuthReply<'AuthorizedRedirect'>;



export type RegisterReplyProps = MaybeProps<{
  userId: string;
  email: string;
  emailVerified: boolean;
}>

export class RegisterReply {
  readonly userId: string | null;
  readonly email: string | null;
  readonly emailVerified: boolean | null;

  constructor(props: RegisterReplyProps) {
    this.userId = props.userId ?? null;
    this.email = props.userId ?? null;
    this.emailVerified = props.emailVerified ?? null;
  }
}


export type ResetPasswordReplyProps = MaybeProps<{
  destination: string;
  message: string;
}>

export class ResetPasswordReply {
  readonly destination: string | null;
  readonly message: string | null;

  constructor(props: ResetPasswordReplyProps) {
    this.destination = props.destination ?? null;
    this.message = props.message ?? null;
  }
}

export type ResendOnboardLinkReplyProps = MaybeProps<{
  destination: string;
}>

export class ResendOnboardLinkReply {
  readonly destination: string | null;

  constructor(props: ResendOnboardLinkReplyProps) {
    this.destination = props.destination ?? null;
  }
}


export type OnboardReplyProps = MaybeProps<{
  action: AuthReplyType;
}>

export class OnboardReply {
  readonly action: string | null;

  constructor(props: OnboardReplyProps) {
    this.action = props.action ?? null;
  }
}