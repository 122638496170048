import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { CloseSVGIcon, ChevronLeft1SVGIcon } from '../../components/svg';
import { WindowState } from './windowState';
import { Button } from '../input';

type Props = {
  model: WindowState;
  title?: ReactNode;
  showCloseButton?: boolean;
  showBackButton?: boolean;
  info?: ReactNode;
  backAction?: () => void;
};

export const WindowHeader = observer((props: Props) => {
  const { model } = props;

  return (
    <header className="window-header">
      {
        props.backAction &&
        props.showBackButton && (
          <Button
            className="small window-back-btn"
            icon={<ChevronLeft1SVGIcon />}
            onClick={props.backAction}></Button>
        )}
      <h2 className="window-title flex-left">{props.title}</h2>
      <div className="window-header-info flex-center">{props.info}</div>
      {props.showCloseButton && (
        <Button
          className="small window-close-btn"
          icon={<CloseSVGIcon />}
          disabled={model.isLocked}
          onClick={model.handleCloseButtonClick}></Button>
      )}
    </header>
  );
});

(WindowHeader as any).defaultProps = {
  showCloseButton: true,
};
