import { action, makeObservable, observable, runInAction, computed } from 'mobx';
import moment from 'moment';
import { DateTime } from 'luxon';
import { Error, pageError } from '../../core/error';
import { Maybe } from '../../core';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { Team } from '../../entities/team';

import { DateInputState, dateInput } from '../../components/input/dateInput/dateInputState';
import { RangeOptions } from '../../components/input/dateInput/dateInput';
import { 
  ReactionsChartState,
  TopicViewsChartState,
  BookmarksChartState,
  HoursCliprdChartState,
  AudienceFocusChartState,
  TeamPerformanceChartState,
  PerformanceChartState
} from '../../components/chart';
import { ChartPageParams } from '../../components/chart/chartSchema';
import { MetricsWrapperState } from '../../components/chart/metricsWrapper/metricsWrapperState';

export class AnalyticsPageState
  extends StoreNode {

  readonly reactionsChart = new ReactionsChartState(this.store, {
    params: () => (this.chartParams)
  });

  readonly bookmarksChart = new BookmarksChartState(this.store, {
    params: () => (this.chartParams)
  });

  readonly hoursCliprdChart = new HoursCliprdChartState(this.store, {
    params: () => (this.chartParams)
  });

  readonly audienceFocusChart = new AudienceFocusChartState(this.store, {
    params: () => (this.chartParams)
  });

  readonly topicViewsChart = new TopicViewsChartState(this.store, {
    params: () => (this.chartParams)
  });

  readonly teamPerformanceChart = new TeamPerformanceChartState(this.store, {
    params: () => (this.chartParams)
  });

  readonly performanceChart = new PerformanceChartState(this.store, {
    params: () => (this.chartParams)
  });

  readonly metricsWrapper = new MetricsWrapperState(this.store, {
    params: () => (this.chartParams)
  });

  readonly dateInput: DateInputState;
  readonly dateRangeOptions: RangeOptions[] = [{
    name: 'Today',
    getRange: () => {
      return [moment(), moment()];
    }
  },
  {
    name: 'Yesterday',
    getRange: () => {
      return [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
    }
  },
  {
    name: 'Last Week',
    getRange: () => {
      return [moment().subtract(7, 'days'), moment()];
    }
  },
  {
    name: 'Last 30 Days',
    getRange: () => {
      return [moment().subtract(31, 'days'), moment()];
    }
  },
  {
    name: 'This Month',
    getRange: () => {
      return [moment().startOf('month'), moment().endOf('month')]
    }
  }];

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.dateInput = dateInput(this, {
      placeholder: ['mm/dd/yyyy', 'mm/dd/yyyy'],
      format: ['MM/DD/YYYY', 'MMDDYYYY'],
      allowEmpty: [false, false],
      rangeOptions: this.dateRangeOptions,
      activeRangeOption: this.dateRangeOptions[2]
    });
  }

  @observable endDateFilter: DateTime | null = null;
  @observable startDateFilter: DateTime | null = null;
  @observable timezone: string | null = null;
  @observable teamId: string | null = null;
  @observable team: Maybe<Team>;

  @observable isLoading = false;
  @observable error: Error | null = null;

  @observable pageSize: number = 20;


  @computed get chartParams(): ChartPageParams {
    return {
      startDate: this.startDateFilter,
      endDate: this.endDateFilter,
      timezone: this.timezone,
      teamId: this.teamId
    };
  }

  @action
  async mounted(teamId?: string) {
    this.reset();

    this.teamId = teamId ?? null;
    this.team = this.store.teamManager.getTeam(teamId);
    if (!this.team && this.teamId) {
      this.isLoading = true;
      const [team] = await this.store.teamManager.apiFetchTeam({ id: this.teamId });
      runInAction(() => {
        this.team = team;
      });
    }

    if ((this.teamId && !this.team?.hasPermission('ViewAnalytics'))
      || (!this.teamId && !this.store.user?.hasPermission('ViewAnalytics')))
      return this.setError(new Error(`Unauthorized`));

    await this.handleDateFilterChange();
    runInAction(() => {
      this.setLoaded();
    });
  }

  @action
  unmounted() {
    this.reset();
  }

  @action
  async setPageSize(pageSize: number) {
    this.pageSize = pageSize;
  }

  @action
  async handleDateFilterChange() {
    const { value: dateInputValue } = this.dateInput;

    if (dateInputValue) {
      const formattedStartDate = (dateInputValue as [moment.Moment, moment.Moment])[0].format();
      const formattedEndDate = (dateInputValue as [moment.Moment, moment.Moment])[1].format();

      this.timezone = DateTime.fromISO(formattedStartDate).zoneName;

      this.startDateFilter = DateTime
        .fromISO(formattedStartDate, { setZone: true })
        .set({ hour: 0, minute: 0, second: 0 })
        .setZone('UTC', { keepLocalTime: true });

      this.endDateFilter = DateTime
        .fromISO(formattedEndDate, { setZone: true })
        .set({ hour: 23, minute: 59, second: 59})
        .setZone('UTC', { keepLocalTime: true });

    } else {
      this.timezone = null;
      this.endDateFilter = null;
      this.startDateFilter = null;
      
    }

    await this.fetchData();

    // we can disable it because each chart will display its own error
    // if (res.some(([, err]) => !!err))
    //   this.setError();
  }

  @action
  async fetchData() {
    return Promise.all([
      this.topicViewsChart.fetch(),
      this.reactionsChart.fetch(),
      this.bookmarksChart.fetch(),
      this.audienceFocusChart.fetch(),
      this.teamPerformanceChart.fetch(),
      this.hoursCliprdChart.fetch(),
      this.performanceChart.fetch(),
      ...this.metricsWrapper.outputMetrics.map(metricCounter => metricCounter.fetch())
    ]);
  }

  // #region State helpers
  @action
  reset() {
    this.isLoading = false;
    this.error = null;
    this.teamId = null;
    this.dateInput.reset();
  }

  @action
  private setError(error?: Error) {
    if (!error)
      error = pageError('Unknown', 'An error occured while loading the page.');

    console.error(error);

    this.isLoading = false;
    this.error = error;
  }

  @action
  private setLoaded() {
    this.isLoading = false;
    this.error = null;
  }
  // #endregion
}
