import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { useStore } from '../../store/storeHooks';
import { BookmarkBrowser } from '../../components/bookmarks';
import { MasterPage } from '../../components/layout';
import { LoadMask } from '../../components/loader';
import { ErrorPage } from '../error';
import { useWindowResize } from '../../components/hooks';
import { resolutionBasedPageSize } from '../../components/utils';

type Props = {};

export const BookmarksPage = observer((props: Props) => {
  const [width,] = useWindowResize();
  const store = useStore();
  const state = store.bookmarksPage;

  useEffect(() => {
    const pageSize: number = resolutionBasedPageSize(width, true);
    state.setPageSize(pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width])

  useEffect(() => {
    state.pageMounted('bookmarkListCatalog');
    return () => state.pageUnmounted();
  }, [state]);

  if (state.error) 
    return <ErrorPage message={state.error.message} />;

  const showLoadMask = state.isLoading;

  return (
    <MasterPage className="bookmarks-page catalog-page with-padding">
      <Helmet>
        <title>CLIPr - Bookmarks</title>
      </Helmet>
      <BookmarkBrowser model={state.browser} />

      <LoadMask visible={showLoadMask} />
    </MasterPage>
  );
});

export default BookmarksPage;