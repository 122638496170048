import { Config } from '../../config';
import { DebugContext } from './debugSchema';

const enableDebug = Config.debug.enable;
const enableTrace = enableDebug && Config.debug.trace;

export const DEFAULT: DebugContext = {
  color: 'blue',
  label: 'Unknown',
  enableTrace: enableTrace,
  showTimestamp: true,
  alignMessage: true
}

export const STORYBOOK: DebugContext = {
  color: 'blue',
  label: 'Story',
  enableTrace: enableTrace,
  showTimestamp: true,
  alignMessage: true
}

export const RUNTIME: DebugContext = {
  label: 'Runtime',
  color: 'fuchsia',
  enableTrace: enableTrace,
  showTimestamp: true,
  alignMessage: true
}

export const RUNTIME_ERROR: DebugContext = {
  label: 'RuntimeError',
  color: 'red',
  enableTrace: enableTrace,
  showTimestamp: true,
  alignMessage: false
}