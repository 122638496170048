import React, { ReactEventHandler, ReactNode, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { useOverlayState } from '../../overlays/overlayState';
import { Overlay } from '../../overlays/overlay';
import { PlayerState } from '../playerState';
import { useEffect } from 'react';

type Props = {
  model: PlayerState
  id?: string,
  className?: string,
  tooltip?: ReactNode,
  icon?: ReactNode,
  forceHoverOut?: boolean;
  disabled?: boolean;
  onClick?: ReactEventHandler
}

export const ControlButton = observer(({
  id,
  model,
  className,
  tooltip,
  disabled,
  forceHoverOut,
  ...props
}: Props) => {

  const tooltipState = useOverlayState({
    visibility: 'OnHover'
  });

  const [isHovered, setHovered] = useState(false);

  useEffect(() => {
    if (forceHoverOut) {
      tooltipState.handleAnchorMouseLeave();
    }
  }, [forceHoverOut, tooltipState]);

  className = classNames('user-player-control-btn', className, {
    hover: isHovered
  });

  return (
    <button
      id={id}
      className={className}
      ref={tooltipState.anchorRef.ref}
      disabled={disabled}
      {...tooltipState.anchorProps}
      onClick={props.onClick}
      onPointerEnter={() => {
        setHovered(true);
      }}
      onPointerLeave={() => {
        setHovered(false);
      }}>

      <span className="icon">
        {props.icon}
      </span>

      {tooltip &&
        <Overlay model={tooltipState}
          className="user-player-tooltip">
          {tooltip}
        </Overlay>}
    </button>
  );
});