import { computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { ProxyServerConnection } from './proxyServerConnection';
import { Error } from '../../core/error';
import { ProxyClientConnection } from './proxyClientConnection';
import { ProxyFlowName, ProxyFlowResponse, ProxyFlowResponseMessage } from './proxySchema';
import { AsyncResult, Result } from '../../core';
import { AuthPermit } from '../auth/authPermit';
import { LibraryName } from '../libraries/librarySchema';
import { LibraryPermit } from '../libraries/libraryPermit';
import { ClientMode } from '../../kernel/kernelSchema';

/**
 * Handles communication between application instances which need to interact for the purpose
 * of authentication, and in the future might handle other use cases as well.
 */
export class ProxyService
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  @computed get isProxyServerMode() {
    return this.store.kernel.clientMode === ClientMode.ProxyServer;
  }

  @observable serverConnection: ProxyServerConnection | null = null;

  @computed get isServerConnectionOpened() {
    return !!this.serverConnection;
  }

  @observable clientConnection: ProxyClientConnection | null = null;

  @computed get isClientConnectionOpened() {
    return !!this.clientConnection;
  }

  get windowFlowName(): ProxyFlowName | null {
    const { openerWindow } = this.store.kernel;
    const windowName = window.name;
    if (!!openerWindow && windowName) {
      switch (windowName) {
        case 'ProxyAuthorizeServer':
          return ProxyFlowName.Authorize;
        case 'ProxyAuthorizeLibraryServer':
          return ProxyFlowName.AuthorizeLibrary;
        case 'ProxyDeauthorizeServer':
          return ProxyFlowName.Deauthorize;
      }
    }
    return null;
  }

  init() {
    if (this.windowFlowName)
      this.activateProxyServerMode();
  }

  activateProxyServerMode() {
    if (this.isProxyServerMode)
      return;

    // do some checks for security and consistency
    this.store.kernel.setProxyServerMode();
  }

  // #region Server methods
  private async withServerConnection<T extends ProxyFlowName>(
    callback: (conn: ProxyServerConnection) => AsyncResult<ProxyFlowResponse<T>>)
    : AsyncResult<ProxyFlowResponse<T>> {

    if (this.isServerConnectionOpened)
      return [null, new Error('InternalError', `A ProxyServerConnection is already opened.`)];

    if (this.isProxyServerMode)
      return [null, new Error('InternalError', `You cannot open a ProxyClientConnection when the current instance runs in proxy server mode.`)];

    const conn = new ProxyServerConnection(this.store);
    this.serverConnection = conn;

    const result: Result<ProxyFlowResponse<T>> = await callback(conn);

    this.serverConnection = null;

    return result;
  }

  async runAuthorizeServerFlow() {
    return this.withServerConnection(conn =>
      conn.runAuthorizeFlow());
  }

  async runAuthorizeLibraryServerFlow(libraryName: LibraryName) {
    return this.withServerConnection(conn =>
      conn.runAuthorizeLibraryFlow(libraryName));
  }

  async runDeauthorizeServerFlow() {
    return this.withServerConnection(conn =>
      conn.runDeauthorizeFlow());
  }

  abortServerFlow() {
    const conn = this.serverConnection;
    if (conn)
      conn.abort();
  }

  focusServerFlow() {
    const conn = this.serverConnection;
    if (conn)
      conn.focus();
  }
  // #endregion

  // #region Client methods
  private async withClientConnection<T extends ProxyFlowName>(
    callback: (conn: ProxyClientConnection) => AsyncResult<ProxyFlowResponseMessage<T>>)
    : AsyncResult<ProxyFlowResponseMessage<T>> {

    if (this.isClientConnectionOpened)
      return [null, new Error('InternalError', `A ProxyClientConnection is already opened.`)];

    if (!this.isProxyServerMode)
      return [null, new Error('InternalError', `You can only open a ProxyClientConnection when the current instance runs in proxy server mode.`)];

    const conn = new ProxyClientConnection(this.store);
    this.clientConnection = conn;

    const result = await callback(conn);

    this.clientConnection = null;

    return result;
  }

  async sendAuthorizeResponse(permit: AuthPermit) {
    return this.withClientConnection(conn =>
      conn.sendAuthorizeResponse(permit));
  }

  async sendAuthorizeLibraryResponse(libraryPermit: LibraryPermit) {
    return this.withClientConnection(conn =>
      conn.sendAuthorizeLibraryResponse(libraryPermit));
  }

  async sendDeauthorizeResponse() {
    return this.withClientConnection(conn =>
      conn.sendDeauthorizeResponse());
  }
  // #endregion
}