import './layout.scss';
import '../menu/menu.scss';
import '../input/dropdownInput-v1-1.scss';

// @ts-ignore
import CSSVariables from '../menu/menu.scss';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, useState } from 'react';
import { usePopper } from 'react-popper';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Routes, { BackRoutes } from '../../routes';
import { useOutsideClick } from '../../components/hooks';
import { ChevronDownSVGIcon } from '../svg';

const fadeTimeout = {
  enter: parseInt(CSSVariables.windowEnterTimeout),
  exit: parseInt(CSSVariables.windowExitTimeout),
};

type UserAccountDropdownProps = {
  showProfile: boolean;
  showHelp: boolean;
  isOpened: boolean;
  showLogout: boolean;
  onLogoutButtonClick?: React.MouseEventHandler;
  onHelpButtonClick?: React.MouseEventHandler;
  setIsOpened: Dispatch<boolean>;
};

export const UserAccountDropdown = observer(({
  isOpened,
  setIsOpened,
  showProfile,
  showHelp,
  showLogout,
  onLogoutButtonClick,
  onHelpButtonClick
}: UserAccountDropdownProps) => {

  // const [isOpened, setIsOpened] = useState(false);
  const [popperElement, setPopperElement] = useState(null);
  const [anchorElement, setAnchorElement] = useState(null);
  const [arrowElement] = useState(null);

  const { styles, attributes } = usePopper(anchorElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },

      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },

      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom'],
        },
      },
    ],
  });

  useOutsideClick(popperElement, () => setIsOpened(false));

  return (
    <>
      <button
        className={
          'btn btn-core btn-icon small ' +
          (isOpened ? 'is-open' : '')
        }
        ref={setAnchorElement as any}
        onClick={(evt) => {
          setIsOpened(!isOpened);
          evt.stopPropagation();
        }}>
        <span className="icon">
          <ChevronDownSVGIcon />
        </span>
      </button>

      <TransitionGroup>
        {isOpened && (
          <CSSTransition classNames="menu-fade" timeout={fadeTimeout}>
            <div
              className="dropdown-menu menu-popup popup"
              ref={setPopperElement as any}
              style={styles.popper}
              {...attributes.popper}>
              <div className="popup-arrow" style={styles.arrow} />
              <div
                className="menu-items"
                onClick={(evt) => setIsOpened(false)}>

                {showProfile &&
                  (<div className="menu-item">
                    <Link
                      to={
                        BackRoutes[Routes.account].route({ currentPath: document.location.pathname })
                      }
                      className="label">
                      <span className="label-text">Profile</span>
                    </Link>
                  </div>)}

                {showHelp && onHelpButtonClick && (
                  <div className="menu-item open-tutorial-menu-item">
                    <div
                      className="label"
                      onClick={onHelpButtonClick}>
                      <span className="label-text">Help</span>
                    </div>
                  </div>
                )}

                {showLogout && onLogoutButtonClick && (
                  <div className="menu-item">
                    <div
                      className="label"
                      onClick={onLogoutButtonClick}>
                      <span className="label-text">Logout</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
});