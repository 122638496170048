import { makeObservable } from 'mobx';
import { Store } from '../../../store/store';
import { StoreNode } from '../../../store';
import { Error } from '../../../core/error';
import { RouteContext } from '../../../routes/routeContext';
import { AuthFlowName, AuthFlowResponse, IAuthFlow } from '../authFlowSchema';
import { AsyncResult } from '../../../core';
import { AuthStepOrchestrator } from '../authStepOrchestrator';

type Props = {
  routeContext: RouteContext;
}

export type ProxyDeauthorizeServerFlowParams = Props;

export class ProxyDeauthorizeServerFlow
  extends StoreNode
  implements IAuthFlow {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);
  }

  readonly flowName = AuthFlowName.ProxyDeauthorizeServer;

  get routeContext(): RouteContext {
    return this.props.routeContext;
  }

  readonly orchestrator = new AuthStepOrchestrator(this.store);

  async run(): AsyncResult<AuthFlowResponse> {

    const { orchestrator } = this;

    const [, logoutErr] = await orchestrator.logout();
    if (logoutErr)
      return orchestrator.setError(new Error('InternalError', `The provided returned an error while trying to logout.`));

    orchestrator.invalidate();

    return orchestrator.setAwaitRedirect();
  }

  async restoreAfterRedirect(): AsyncResult<AuthFlowResponse> {

    const { authService } = this.store;
    const { orchestrator } = this;

    if (authService.isAuthorized)
      console.warn('Client should not be authorized when calling restoreAfterRedirect');

    // todo: add invalidations, cleanups, etc
    orchestrator.invalidate();

    const [, sendErr] = await orchestrator.sendProxyDeauthorizeResult();
    if (sendErr)
      return orchestrator.setError(sendErr);

    return orchestrator.setAwaitProxyClose();
  }
}