import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { PlayerState } from '../playerState';
import { PlayerBookmarkSVGIcon } from '../../svg';
import { PlayerEditMomentButton } from '../../playerShared/playerEditMomentButton';

type Props = {
  className?: string,
  model: PlayerState,
  showBookmarkButton?: boolean
}

export const ActiveTopicCard = observer(({
  model,
  className,
  showBookmarkButton = false
}: Props) => {
  const {
    showActiveTopic,
    activeTopic
  } = model.momentView;

  const [hovered, setHover] = useState(false);

  if (!showActiveTopic)
    return null;

  className = classNames(
    className,
    'user-player-active-topic-card',
    'user-player-active-item-card',
    {
      'empty': !activeTopic,
    });

  const showGutter = showBookmarkButton;

  const isEditable: boolean = (
    model.job?.hasPermission('EditUserMoment') &&
    !model.isFullscreen &&
    !model.frameset?.isWidgetMode &&
    activeTopic?.userHasEditPermission) ?? false;


  return (
    <div
      id="user-player-active-topic-card"
      data-tutorial-id="user-player-active-topic-card"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={className}>
      {showGutter &&
        <div
          id="bookmark-current-topic"
          data-tutorial-id="bookmark-current-topic"
          className="card-gutter">

          {showBookmarkButton &&
            <button
              id="card-bookmark-btn"
              data-tutorial-id="card-bookmark-btn"
              className="card-bookmark-btn"
              disabled={!activeTopic}
              onClick={evt => model.openBookmarkWindow(activeTopic)}>

              <span className="icon">
                <PlayerBookmarkSVGIcon
                  pressed={model.momentHasBookmark(activeTopic)} />
              </span>
            </button>}
        </div>}

      <div
        className="card-details">
        <div className="card-heading">Current topic</div>
        <div className="card-name">{activeTopic?.name ?? 'No active topic'}</div>
      </div>

      {isEditable && hovered && (
        <PlayerEditMomentButton
          player={model}
          moment={activeTopic}
          permissionMode="always" />
      )}
    </div>
  );
});