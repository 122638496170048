import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { JobModel, MomentSelector, SpeakerModel, Team } from '../../entities';
import { BindingProps, Message, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { PlayerState } from '../player';
import { PlayerFramesetState } from '../playerFrameset/playerFramesetState';
import { PlayerIndexState } from '../playerIndex/playerIndexState';
import { Config } from '../../config';

type Props = BindingProps<{
  player: PlayerState;
  frameset: PlayerFramesetState;
  indexSection: PlayerIndexState;
  momentSelector: MomentSelector;
}>

export class PlayerSpeakerIdController
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    //TODO: reevaluate different ways of reacting to these state changes
    reaction(() => this.player.status, () => {
      if (this.player.status === 'Ended' && this.isSpeakerIdMode) {
        this.player.invoke('replay')
      }
    })
  }

  @observable suppressSpeakerIdWindow: boolean = false;

  //The following 2 properties are part of some isolated hacks that should avoid pausing the video when safari is prone to work its magic
  //TODO: refactor this when a suitable solution is found, it stores the seeking state because safari sends the event but the dom element is not marked as such
  @observable isSeeking = false;
  //TODO: refactor this when a suitable solution is found
  @observable pauseTimeoutId: ReturnType<typeof setTimeout> | null = null;

  @observable isSpeakerIdMode: boolean = false;

  @computed get isWidgetMode(): boolean {
    return this.frameset.isWidgetMode;
  }

  @computed
  get frameset(): PlayerFramesetState {
    return this.resolvedProps.frameset;
  }

  @computed
  get player(): PlayerState {
    return this.resolvedProps.player;
  }

  @computed
  get indexSection(): PlayerIndexState {
    return this.resolvedProps.indexSection;
  }

  @computed
  get selector(): MomentSelector {
    return this.resolvedProps.momentSelector;
  }

  @computed
  get teamId(): string | null {
    return this.player.teamId ?? null;
  }

  @computed
  get team(): Team | null {
    return this.store.teamManager.getTeam(this.teamId) ?? null;
  }

  @computed
  get job(): JobModel | null {
    return this.player?.job ?? null;
  }

  @computed
  get isPublic(): boolean {
    return !this.store.isAuthorized;
  }

  @computed get showSpeakerId(): boolean {
    const { job, team, player, store } = this;

    if (!job || !team)
      return false;

    return ( //TODO: refactor all these messy conditions
      // permissions check
      team.hasPermission('ConfirmSpeakerPrediction') && //|| job?.isOwnerAuthenticated) &&
      team.isSpeakerSuggestEnabled &&
      // suppression flag check
      !store.user?.hasSpeakerSuggestionHidden(job.id) &&
      // job status check
      job.hasPrivateSpeakers &&
      !job.hasManualEnrichmentLevel &&
      job.hasAutomaticEnrichmentLevel &&
      // widget auth check
      !this.isPublic &&
      // player state check
      player.adapter.isActuallyPlaying &&
      !player.isSeeking &&
      !player.isFullscreen &&
      !this.isSeeking &&
      // player ads check
      !player.adsAdapter.isPlaying &&
      !player.adsAdapter.isLoading) &&
      //speaker id state 
      !this.isSpeakerIdMode &&
      // device restriction
      (!this.store.ui.isMobile || // all non-mobile
        (this.store.ui.isMobile && !this.store.orientation.isLandscape)) &&// mobile only on portrait
      //Config 
      Config.speakerId.autoTriggerSpeakerIdFlow
  }

  @computed get showEditSpeakerId(): boolean {
    const { job, team, isPublic } = this;

    if (!job || !team || isPublic)
      return false;

    return team.hasPermission('ConfirmSpeakerPrediction');
  }

  @action
  subscribeToTutorialWindowEvents() {

  }

  @action
  private playerListener = (msg: Message<PlayerState>) => {
    const { type, payload } = msg;
    switch (type) {
      // case 'play':
      // case 'autoplay':
      // case 'activeTranscriptChange':
      case 'timeupdate':
        if (this.suppressSpeakerIdWindow)
          return;

        const moment = payload?.moment ?? this.player.momentView?.activeTranscript;
        if (!moment?.isSpeakerPrivate || !this.showSpeakerId) // TODO: refactor all these messy conditions
          return;

        this.openSpeakerIdConfirmationWindow(this.job?.id);
        break;
      case 'seeking':
        this.isSeeking = true;
        break;
      case 'seeked':
        this.isSeeking = false;
        break;
      default: break;
    }
  }

  @action
  openSpeakerIdConfirmationWindow(jobId?: string) {
    this.pause();

    this.dispatch('openSpeakerIdConfirmationWindow', {
      onSubmit: () => this.enterSpeakerIdMode(),
      onClose: () => this.clearPauseTimeout(),
      targetCount: this.job?.privateSpeakers.length,
      jobId: jobId ?? undefined
    });

    this.suppressSpeakerIdWindow = true;
    this.player.unlisten(this.playerListener);
  }

  @action
  pause() {
    const { ui } = this.store;
    this.player.invoke('enterPause');

    if (ui.isSafari) { // Ugly workaround for an ugly safary bug
      // need to delay a second pause in safari to be sure the player doesn't just remain stuck in pause but playing the video at the same time, thanks to an elaborate act of magic by safari
      this.pauseTimeoutId = setTimeout(() => {
        this.clearPauseTimeout();
        this.player.invoke('enterPause');
      }, 100);
    }

  }

  @action
  clearPauseTimeout() {
    if (this.pauseTimeoutId) {
      clearTimeout(this.pauseTimeoutId);
      this.pauseTimeoutId = null;
    }
  }

  @action
  enterSpeakerIdMode(mode: 'id' | 'edit' = 'id') {
    // store the current selector state of the player before replacing it with the speaker context
    this.selector.save();
    this.isSpeakerIdMode = true;
    if (mode === 'id')
      this.indexSection.enterSpeakerIdMode();
    else if (mode === 'edit') {
      const editSpeaker = this.indexSection.speakerIdForm.currentSpeaker;
      if (!editSpeaker)
        return;
      this.indexSection.enterSpeakerIdEditMode();
      this.setSelectorSpeaker(editSpeaker);
    }

    this.frameset.showSection('Index');
    this.frameset.hideSection('Transcripts');
    this.frameset.hideSection('Comments');
    this.clearPauseTimeout();
  }

  @action
  setSelectorSpeaker(speaker: SpeakerModel) {
    // replace the player selector state with the one needed for speaker id mode
    this.selector.reset();
    this.selector.setMode('Speakers');
    this.selector.toggleSpeaker(speaker);
  }

  @action
  exitSpeakerIdMode() {
    this.isSpeakerIdMode = false;
    this.indexSection.exitSpeakerIdMode();
    // retrieve the original state of the selector after exiting speaker id mode
    this.selector.reset();
    this.selector.load();
  }

  reset() {
    this.isSpeakerIdMode = false;
    this.suppressSpeakerIdWindow = false;
    this.isSeeking = false;
    this.indexSection.exitSpeakerIdMode();
    this.player.unlisten(this.playerListener);
    this.clearPauseTimeout();
  }

  init() {
    this.reset();
    const { job, team, store } = this;
    if (!job || !store || !team)
      return;

    this.suppressSpeakerIdWindow = !(
      // permisions check
      team.hasPermission('ConfirmSpeakerPrediction') && //|| job?.isOwnerAuthenticated) &&
      team.isSpeakerSuggestEnabled &&
      // suppression flag check
      !store.user?.hasSpeakerSuggestionHidden(job.id) &&
      // job status check
      !job.hasManualEnrichmentLevel &&
      job.hasAutomaticEnrichmentLevel &&
      !team.publicSafety &&
      Config.speakerId.autoTriggerSpeakerIdFlow);

    if (!this.suppressSpeakerIdWindow) {
      this.player.listen(this.playerListener);
    }

  }
}