import './media.scss';
import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import {
  assertIsBookmark,
  assertIsJob,
  Bookmark,
  BookmarkList,
  JobModel,
  MomentModel,
} from '../../entities';
import { HTMLDivProps, Maybe } from '../../core';
import { PodcastPlaceholderSvg } from '../svg';

export type ThumbSource =
  | JobModel
  | MomentModel
  | Bookmark
  | BookmarkList
  | string;

type Props = HTMLDivProps<{
  showPlaceholder?: boolean;
  source?: Maybe<ThumbSource | string>;
  aspectRatio?: Maybe<number | boolean>;
}>;

export const Thumb = observer(
  ({ className, aspectRatio, source, showPlaceholder, ...props }: Props) => {
    className = classNames('thumb', className, {
      'aspect-ratio-box': !!aspectRatio,
    });

    let url: string;
    let isAudioSource: boolean = false;
    if (!source) {
      url = '';
    } else if (typeof source === 'string') {
      url = source;
    } else {
      switch (source.nodeType) {
        case 'Job':
          assertIsJob(source);
          url = source?.posterURL!;
          isAudioSource = !!source?.media?.basic;
          break;

        case 'Moment':
          throw new Error(
            'Moment objects are not yet supported for Thumbnails'
          );

        case 'Bookmark':
          assertIsBookmark(source);
          url = source?.job?.posterURL!;
          isAudioSource = !!isAudioSource;
          break;

        case 'BookmarkList':
          url = source?.thumbnailUrlList[0];
          break;

        default:
          throw new Error('Unsupported object type for Thumb');
      }
    }

    if (!url) {
      url = '';
    }

    let style = {};
    if (Number.isFinite(aspectRatio)) {
      style = {
        '--aspect-ratio': aspectRatio,
      };
    }

    const imgStyle = {
      backgroundImage: `url('${url}')`,
    };

    return (
      <div className={className} style={style} {...props}>
        <div className="thumb-img" style={url ? imgStyle : undefined}>
          {((!url &&
            isAudioSource) ||
            showPlaceholder) &&
            <PodcastPlaceholderSvg
              className='podcast-placeholder-size' />}
        </div>
      </div>
    );
  }
);
