import './trackWindow.scss';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { ButtonControlled, DropdownInputControlled, TextInputControlled, ToggleInputControlled } from '../input';
import { TrackWindowState } from '../trackWindow/trackWindowState';
import { SpeakerFormBlock } from '../speakers/speakerFormBlock';

type Props = {
  model: TrackWindowState,
  visible?: boolean
}

export const TrackWindow = observer((props: Props) => {

  const { model } = props;

  return (
    <Window
      model={model.window}
      onExited={model.onTransitionEnd}
      visible={props.visible}
      isLoading={model.isLoading || model.speakerBlock.isLoading}
      className="track-window">
      <WindowHeader
        model={model.window}
        title={
          model.isEditMode ? 'Edit Track' : 'Create Track'
        } />

      <div className="window-content">
        <TextInputControlled
          label={model.clipType.value !== 'Transcript' ? "Track Name*" : "Track Name"}
          placeholder="Enter Track Name"
          model={model.name} />

        <DropdownInputControlled
          label="Clip Type*"
          placeholder="Select Type"
          notDeselectable
          model={model.clipType} />

        <DropdownInputControlled
          label={model.clipType.value === 'Moment' ? "Moment Type*" : "Moment Type"}
          placeholder="Select Moment Type"
          notDeselectable
          model={model.momentType} />

        <DropdownInputControlled
          label="Track Language"
          placeholder="Select Language"
          model={model.language} />

        {/* <div className="input-block-wrapper">
          <DropdownInputControlled
            label={model.clipType.value === 'Transcript' ? 'Speaker*' : 'Speaker'}
            placeholder="Select speaker"
            model={model.speaker}
            withSearch={true}
            notDeselectable />
          <Button
            leftIcon={<PlusSVGIcon />}
            disabled={model.clipType.value !== 'Transcript'}
            onClick={() => model.openSpeakerWindow()} />
        </div> */}

        <SpeakerFormBlock
          model={model.speakerBlock} />
      </div>

      <hr />

      <footer className="flex-space-between vpad4 hpad6">
        <ToggleInputControlled
          className="medium"
          label="Visible to Consumer"
          model={model.visibleToConsumer} />
        <div className="btn-group fill gap2">
          <ButtonControlled
            className="primary btn-outline medium"
            label="Cancel"
            model={model.cancelButton}
            onClick={() => model.cancel()} />

          <ButtonControlled
            className="primary medium"
            label="Save"
            model={model.saveButton}
            onClick={() => model.submit()} />
        </div>
      </footer>
    </Window>
  );
});