import { action, computed, makeObservable, observable } from 'mobx';
import { ClipType, Highlight, Metadata, PageInfo, SearchMoment, SearchMomentEdge, SearchMomentsFilterInput, SearchMomentsQuery } from '@clipr/lib';
import isEmpty from 'lodash/isEmpty';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { AsyncResult, Maybe, Nullable } from '../../core'
import { ApiResponse, ApiVariables } from '../../api/apiSchema';
import { notifyError } from '../../services/notifications';
import { ApiRequestOptions } from '../../api';
import { getCatalogMetadataFilterInput, getCatalogTagsFilterInput } from '../../entities/catalogUtils';
// TODO: uncomment when implemented in the BE
// import { getCatalogMetadataFilterInput, getCatalogTagsFilterInput } from '../../entities/catalogUtils';


type Props = BindingProps<{
  type: ClipType;
}>

type FetchArgs = {
  more?: boolean;
  count?: number
}
export type MomentResultItem = SearchMoment & { highlights?: Maybe<Highlight[]> };

export class MomentResultsCatalog extends StoreNode {
  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);
  }

  readonly pageSize = 13;

  @observable isLoading: boolean = false;
  @observable isFetchingMore: boolean = false;
  @observable isFetchingSuggestions: boolean = false;
  @observable previousSearch: Maybe<string> = null;
  @observable teamId: Nullable<string> = null;
  @observable pageInfo: Nullable<PageInfo> = null;

  @observable metadataFilter: Partial<Metadata> | null = null;
  @observable tagsFilter: string[] | null = null;

  readonly lookup = observable.map<string, MomentResultItem>();
  readonly suggestionsLookup = observable.map<string, MomentResultItem>();
  @computed get type(): ClipType {
    return this.resolvedProps.type;
  }

  @computed
  get moments(): MomentResultItem[] {
    return [...this.lookup.values()];
  }
  @computed
  get suggestions(): MomentResultItem[] {
    return [...this.suggestionsLookup.values()];
  }

  @computed
  get isEmpty(): boolean {
    return isEmpty(this.moments);
  }

  @computed
  get hasNextPage(): boolean {
    if (!this.pageInfo || !this.pageInfo.totalCount) {
      return false;
    }
    return this.pageInfo.totalCount > this.moments.length;
  }

  @action
  private async fetch(args: ApiVariables<'searchMoments'>, opts?: ApiRequestOptions): AsyncResult<SearchMomentsQuery> {
    const [res, err] = await this.store.api.searchMoments(args, opts);

    if (err || !res) {
      return [null, err];
    }

    return [res, null];
  }

  @action
  async fetchMoments(search: Maybe<string>, pageSize: number, opts?: ApiRequestOptions) {
    this.previousSearch = search || undefined;
    this.isLoading = true;

    const args: ApiVariables<'searchMoments'> = this.getFetchArgs({ count: pageSize });

    const [res, err] = await this.fetch(args, opts);

    if (err || !res) {
      notifyError(this, 'Could not fetch search results.');
      return;
    }

    this.lookup.clear();
    this.updateLookup(res);
    this.isLoading = false;
  }

  @action
  async fetchMore(pageSize: number, opts?: ApiRequestOptions) {
    this.isFetchingMore = true;
    const args: ApiVariables<'searchMoments'> = this.getFetchArgs({ more: true, count: pageSize });

    const [res, err] = await this.fetch(args, opts);

    if (err || !res) {
      notifyError(this, 'Could not fetch search results.');
      return;
    }
    this.updateLookup(res);
  }

  @action
  async fetchSuggestions(search: Maybe<string>, opts?: ApiRequestOptions) {
    this.previousSearch = search || undefined;
    this.isFetchingSuggestions = true;

    const args: ApiVariables<'searchMoments'> = this.getFetchArgs({ count: 3 });

    const [res, err] = await this.fetch(args, opts);

    if (err || !res) {
      notifyError(this, 'Could not fetch search suggestions.');
      return;
    }

    this.suggestionsLookup.clear();
    // @ts-ignore
    res.searchMoments.edges.forEach(({ node, highlights }: SearchMomentEdge) => (
      // @ts-ignore
      this.suggestionsLookup.set(node.id, { ...node, highlights })
    ));

    this.isFetchingSuggestions = false;
  }

  @action
  updateLookup(res: ApiResponse<'searchMoments'>) {
    this.pageInfo = res.searchMoments.pageInfo;
    // @ts-ignore
    res.searchMoments.edges.forEach(({ node, highlights }: SearchMomentEdge) => (
      // @ts-ignore
      this.lookup.set(node.id, { ...node, highlights })
    ));

    this.isLoading = false;
    this.isFetchingMore = false;
  }

  @action
  private getFetchArgs({ more, count }: FetchArgs): ApiVariables<'searchMoments'> {
    const args: ApiVariables<'searchMoments'> = {
      thumbnailSize: [{
        width: 250,
        height: 140
      }]
    };
    const filter: SearchMomentsFilterInput = {
      clipType: {
        ne: 'Topic'
      }
    };

    if (this.teamId) {
      filter.teamId = this.teamId;
    }
    if (this.previousSearch) {
      filter._search = this.previousSearch;
    }
    if (this.type === 'Moment') {
      filter.clipType = {
        ne: 'Topic'
      }
    } else {
      filter.clipType = {
        eq: 'Topic',
      }
    }
    filter.clientApp = 'Consumer';

    const metadata = getCatalogMetadataFilterInput(this.metadataFilter);
    if (metadata && metadata.length > 0)
      filter.metadata = metadata;

    const tags = getCatalogTagsFilterInput(this.tagsFilter);
    if (tags && tags.length > 0)
      filter.tags = tags;

    args.filter = filter;

    if (count) {
      args.first = more ? count + 1 : count;
    } else {
      args.first = this.pageSize;
    }

    if (more && this.pageInfo?.endCursor) {
      args.after = this.pageInfo.endCursor;
    }

    return args;
  }
}