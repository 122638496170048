import { ClipType } from "@clipr/lib";
import { Bookmark, MomentKind } from "../../../entities";
import { IAnalyticsStream, AnalyticsStreamParams, AnalyticsEventTypes } from "../analyticsSchema";
import { AnalyticsStream, AnalyticsMainData, AnalyticsCurrentJobData, AnalyticsPlayerStateData } from "./analyticsStream";

export type AnalyticsClipBookmarkStreamParams = Bookmark

export type AnalyticsClipBookmarkStreamData = {
  bookmark: {
    bookmarkId: string,
    bookmarkListId: string,
    momentId: string,
    momentName?: string,
    momentType?: ClipType,
    momentKind?: MomentKind,
    speakerId?: string,
    speakerName?: string,
  }
} 
& AnalyticsPlayerStateData
& AnalyticsCurrentJobData
& AnalyticsMainData

export class AnalyticsClipBookmarkStream extends AnalyticsStream implements IAnalyticsStream<keyof AnalyticsStreamParams> {
  static streamName = AnalyticsEventTypes.ClipBookmarkType

  exchangeData(data: AnalyticsClipBookmarkStreamParams): AnalyticsClipBookmarkStreamData|null {
    const { job } = data;
    
    return {
      ...{ 
        bookmark: {
          bookmarkId: data.id,
          bookmarkListId: data.listId,
          momentId: data.momentId,
          momentName: data.momentStub?.name,
          momentType: data.moment?.clipType,
          momentKind: data.moment?.momentKind,
          speakerId: data.moment?.speaker?.id,
          speakerName: data.moment?.speaker?.name,
        }
      }, 
      ...super.playerData,
      ...super.currentJobData(job!), 
      ...super.mainData,
      ...super.viewContextData
    };
  }
}
