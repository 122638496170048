import './errorPages.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { BasicPage } from '../../components';
import { RouteProps } from 'react-router-dom';
import { History } from 'history';
import { Maybe } from '../../core';
import { UnauthorizedVideoErrorPage } from './unauthorizedVideoErrorPage';

type Props = RouteProps & {
  code?: Maybe<string>;
  message?: Maybe<string>;
  backButtonRoute?: string | ((router: History<any>) => void);
  showBackButton?: boolean;
};

export const ErrorPage = observer(({
  code,
  message,
  showBackButton = true
}: Props) => {
  let errorPage = null;

  switch (code) {
    case 'JobUnavailable':
      errorPage = <UnauthorizedVideoErrorPage code={code} message={message} />;
      break;
    case 'TeamDeleted':
      errorPage = (
        <BasicPage className="error-page center" showBackButton={false}>
          <div className="error-content">
            <div className="error-page-message">
              {message}
            </div>
          </div>
        </BasicPage>
      );
      break;
    default:
      errorPage = (
        <BasicPage className="error-page center" showBackButton={showBackButton}>
          <div className="error-content">
            <div className="error-page-message">
              {message || 'Cannot access page because of an error'}
            </div>
          </div>
        </BasicPage>
      );
      break;
  }

  return errorPage;
});
