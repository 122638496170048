import './bookmarks.scss';

import React, { CSSProperties, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { BookmarkList } from '../../entities/bookmarkList';
import { ThumbGroup } from '../media/thumbGroup';
import { Link } from 'react-router-dom';
import Routes from '../../routes';
import { BookmarkListMenu } from './bookmarkListMenu';
import { BookmarkSVGIcon } from '../svg';
import { Maybe } from '../../core';

type Props = {
  model: BookmarkList;
  /** Sets the name that will be used for display purposes, instead of the model name. */
  name?: string;
  className?: string;
  aspectRatio?: number;
};

export const BookmarkListCard = observer((props: Props) => {
  const { model } = props;

  const isEmpty = model.bookmarkCount === 0;

  const className = classNames(
    'bookmark-list-card link-card',
    props.className,
    {
      'empty empty-card': isEmpty,
    }
  );
  const previewStyle: CSSProperties = {
    ['--aspect-ratio' as any]: props.aspectRatio || 9 / 16,
  };

  const ellipseTitle = (title: string) => {
    if (title?.length > 66) {
      return title?.substring(0, 65) + '...';
    } else {
      return title;
    }
  };
  const link = Routes.bookmarkList(model.id);

  let previewElem: Maybe<ReactNode>;

  if (isEmpty) {
    previewElem = (
      <div className="card-thumbs-empty">
        <div className="bookmark-thumb-icon">
          <span className="icon">
            <BookmarkSVGIcon enabled />
          </span>
        </div>
        <p>Add some Bookmarks to this List</p>
      </div>
    );
  } else {
    previewElem = <ThumbGroup
      showPlaceholder={model.thumbnailUrlList.length === 0}
      sources={model.thumbnailUrlList}
    />;
  }

  return (
    <div className={className}>
      <Link
        to={link as any}
        className="card-preview aspect-ratio-box"
        style={previewStyle}>
        {previewElem}
      </Link>

      <Link to={link as any} className="card-title">
        {ellipseTitle(model.name)}
      </Link>

      <Link to={link as any} className="card-summary">
        {model.bookmarkCountLabel}
      </Link>

      <BookmarkListMenu model={model} />
    </div>
  );
});

(BookmarkListCard as any).propTypes = {
  model: PropTypes.instanceOf(BookmarkList).isRequired,
};
