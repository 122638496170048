import '../auth/authPages.scss';
import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { BasicPage, CliprLogoSVGIcon } from '../../components';
import { Link, Redirect, useLocation } from 'react-router-dom';
import Routes from '../../routes';
import { isNonEmptyString } from '../../core';

export const OAuthVerifiedPage = observer(() => {

  // https://app.clipr.ai/oauth/verified?supportSignUp=true&supportForgotPassword=true&message=Your%20email%20was%20verified.%20You%20can%20continue%20using%20the%20application.&success=true&code=success#

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const success = query.get('success');
  const message = query.get('message');

  if (
    !['true', 'false'].includes(success || '') ||
    !isNonEmptyString(success))
    return <Redirect to={Routes.defaultRoute()} />

  let panelElem: ReactNode;
  if (success === 'true') {

    panelElem = (
      <div className="auth-panel">
        <header className="auth-header">
          <div className="clipr-logo">
            <CliprLogoSVGIcon />
          </div>
          <h1 className="vpad2 bold text-center">Done!</h1>
          <p className="text-center">{message}</p>
        </header>

        <p className="text-center vpad4">
          <Link to={Routes.login()} className="bold">Sign in</Link>
        </p>
      </div>
    );
  } else {

    panelElem = (
      <div className="auth-panel">
        <header className="auth-header">
          <div className="clipr-logo">
            <CliprLogoSVGIcon />
          </div>
          <h2 className="vpad2 bold text-center">Cannot verify your email</h2>
          <p className="text-center">{message}</p>
        </header>
      </div>
    );
  }

  return (
    <BasicPage
      className="auth-page dock"
      showHeader={false}>

      <div className="auth-basic">
        <div className="auth-container">
          {panelElem}
        </div>
      </div>
    </BasicPage>
  );
});

export default OAuthVerifiedPage;