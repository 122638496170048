import React from 'react';
import {
  HotReactionSVGIcon,
  InsightfulReactionSVGIcon,
  FunnyReactionSVGIcon,
  LoveReactionSVGIcon,
  EngagingReactionSVGIcon,
  ConfusingReactionSVGIcon,
  RocketReactionSVGIcon,
  SmartReactionSVGIcon,
  CelebrateReactionSVGIcon,
} from '../svg/reactionSvgIcons';
import {
  LoveBloomSVGIcon,
  ConfusingBloomSVGIcon,
  EngagingBloomSVGIcon,
  FunnyBloomSVGIcon,
  InsightfulBloomSVGIcon,
  HotBloomSVGIcon,
} from '../svg/reactionBloomSvgIcons';
import { InputPlayerReactionName, OutputPlayerReactionName } from './playerReactionSchema';

type ReactionsLookup = {
  component: React.ReactElement,
  tooltip: string
}

type BloomsLookup = {
  component: (props?: React.SVGProps<SVGSVGElement>) => React.ReactElement,
  tooltip: string
}

export const ReactionSVGIconLookup: { [key in InputPlayerReactionName]: ReactionsLookup } = {
  'Love': {
    component: <LoveReactionSVGIcon />,
    tooltip: 'Love/Like'
  },
  'Genius': {
    component: <InsightfulReactionSVGIcon />,
    tooltip: 'Genius'
  },
  'Fire': {
    component: <HotReactionSVGIcon />,
    tooltip: 'Fire'
  },
  'Funny': {
    component: <FunnyReactionSVGIcon />,
    tooltip: 'Funny'
  },
  'Engaging': {
    component: <EngagingReactionSVGIcon />,
    tooltip: 'Hmmm'
  },
  'Confusing': {
    component: <ConfusingReactionSVGIcon />,
    tooltip: 'Confusing'
  }
}

export const OutputReactionsSVGIconLookup: { [key in OutputPlayerReactionName]: ReactionsLookup } = {
  ...ReactionSVGIconLookup,
  'Hot': {
    component: <HotReactionSVGIcon />,
    tooltip: 'Hot'
  },
  'Rocket': {
    component: <RocketReactionSVGIcon />,
    tooltip: 'To the moon'
  },
  'Insightful': {
    component: <InsightfulReactionSVGIcon />,
    tooltip: 'Insightful'
  },
  'Smart': {
    component: <SmartReactionSVGIcon />,
    tooltip: 'Smart'
  },
  'Celebrate': {
    component: <CelebrateReactionSVGIcon />,
    tooltip: 'Celebrate'
  }
}

export const BloomSVGIconLookup: { [key in InputPlayerReactionName]: BloomsLookup } = {
  'Love': {
    component: (props) => <LoveBloomSVGIcon {...props}/>,
    tooltip: 'Love/Like'
  },
  'Genius': {
    component: (props) => <InsightfulBloomSVGIcon {...props}/>,
    tooltip: 'Genius'
  },
  'Fire': {
    component: (props) => <HotBloomSVGIcon {...props}/>,
    tooltip: 'Fire'
  },
  'Funny': {
    component: (props) => <FunnyBloomSVGIcon {...props}/>,
    tooltip: 'Funny'
  },
  'Engaging': {
    component: (props) => <EngagingBloomSVGIcon {...props}/>,
    tooltip: 'Hmmm'
  },
  'Confusing': {
    component: (props) => <ConfusingBloomSVGIcon {...props}/>,
    tooltip: 'Confusing'
  }
}