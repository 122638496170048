import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { PlayerState } from '../playerState';
import { PlayerToggleBar } from './toggleBar';
import { ClickPadding } from './clickPadding';
import {
  ChevronLeft1SVGIcon,
  ShareSVGIcon,
  HelpSVGIconCircle,
} from '../../svg';
// import { backRoute, BackRouteState } from '../../../routes';
import { ToggleButton } from './toggleButton';
import { Placement } from '../../playerTutorial/playerTutorial.consts';
import { PlayerCaptionsComponent } from './playerCaptionsComponent';
// import { LocationDescriptor } from 'history';
// import { useLocation } from 'react-router';
import { WidgetProfileCard } from '../../widgets/widgetProfileCard';
import { useRoutingService } from '../../../services/routing/routingHooks';

type Props = {
  model: PlayerState;
};

export const WidgetHelpButton = observer(({ model }: Props) => {

  return (
    <div className="control-group widget-help-group">
      <ToggleButton
        model={model}
        icon={<HelpSVGIconCircle />}
        placement={Placement.Bottom}
        onClick={(evt) => {
          model.emit('openTutorialWindow');
        }}
        tooltip={'Help'} />
    </div>
  );
});

export const PlayerChromeTop = observer(({ model }: Props) => {
  const { store, job } = model;
  const { user: profile, widgetService } = store;
  const routingService = useRoutingService();

  const router = useHistory();

  const { chrome, playerTutorialIsOnForChromeTop, frameset } = model;
  const indexVisible = frameset?.isSectionVisible('Index');
  const allowShare = model.allowShare ?? true;
  const backRoute = routingService.getBackRoute();

  useEffect(() => {
    playerTutorialIsOnForChromeTop
      ? chrome.handlePointerEnter()
      : chrome.handleRegionPointerLeave();
  }, [playerTutorialIsOnForChromeTop, chrome]);

  const showSubtitles: boolean = model.captionsAreEnabled && model.captionsAreVisible;

  // TODO: remove dependency to playerWidget and choose a place to put the logic, 
  // either fully state, or fully render, preferably fully state
  const showHelpButton: boolean = (
    (store.playerWidget.showHelp ?? true) &&
    job?.isMediaDone &&
    store.playerWidget.showPlayerTutorial &&
    !store.playerWidget.player.isAdsAdapterActive) ?? false;

  const showProfileBar = model.showProfile && widgetService.showProfileCard; // we need the right operand because the css adds margin even if the element is empty

  return (
    <>
      {showSubtitles &&
        !model.isFullscreen &&
        !indexVisible &&
        model.frameset?.isWidgetMode && (
          <PlayerCaptionsComponent chromeTop={true} model={model} />
        )}

      <div
        id="user-player-chrome-top"
        onPointerEnter={chrome.handleRegionPointerEnter}
        onPointerLeave={chrome.handleRegionPointerLeave}>
        <div id="user-player-left-toggle-bar">
          {model.frameset?.isWidgetMode && (
            <>
              {backRoute &&
                <div className="back-button">
                  <ToggleButton
                    model={model}
                    icon={<ChevronLeft1SVGIcon />}
                    tooltip={'Back'}
                    onClick={() => {
                      if (typeof backRoute === 'function')
                        backRoute()
                      else router.push(backRoute);
                    }} />
                </div>}

              {showProfileBar &&
                <div id="player-profile-bar">
                  <WidgetProfileCard
                    showHelp={store.playerWidget.showHelp ?? true}
                    onLoginButtonClick={model.handleLoginButtonClick}
                    onLogoutButtonClick={model.handleLogoutButtonClick}
                    onHelpButtonClick={model.handleHelpButtonClick} />
                </div>}

              {(!profile || !model.showProfile) &&
                showHelpButton &&
                <WidgetHelpButton model={model} />}
            </>
          )}
          {
            model.job?.isPublic &&
            allowShare && (
              <div className="control-group">
                <ToggleButton
                  model={model}
                  icon={<ShareSVGIcon />}
                  tooltip={'Share Video'}
                  onClick={() => model.shareVideo()} />
              </div>
            )}
        </div>

        {!model.frameset?.isWidgetMode &&
          <div className="flex-left self-left">
            <div className="player-edit-mode-heading">Adding a Moment</div>
          </div>}

        <ClickPadding model={model} />

        <div className="flex-right self-right">
          <PlayerToggleBar model={model} />
        </div>
      </div>
    </>
  );
});
