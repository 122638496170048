import React from 'react';
import { observer } from 'mobx-react-lite';
import { BookmarkSVGIcon, PlusSVGIcon } from '../../components/svg';
import { Window, WindowHeader } from '../overlays';
import { BookmarkWindowState } from './bookmarkWindowState';

type Props = {
  model: BookmarkWindowState;
  visible?: boolean;
};

export const BookmarkWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      name="bookmarkListWindow"
      model={model.window}
      visible={props.visible}
      isLoading={model.isLoading}
      className="bookmark-window">
      <WindowHeader model={model.window} title="Add to list" />

      <hr />

      <div className="window-content">
        <div className="bpad2">
          <button
            className="create-list-btn btn-core"
            onClick={model.invokeHandler('createList')}>
            <span className="text">Create a list</span>
            <span className="icon">
              <PlusSVGIcon />
            </span>
          </button>
        </div>

        <hr />

        <ul className="bookmark-list-list vmar4">
          {model.bookmarkLists.map((list) => {
            return (
              <li
                key={list.id}
                className="bookmark-list-item btn-core"
                onClick={(evt) => model.toggleBookmark(list.id)}>
                <div className="label">{list.name}</div>
                <div className="icon">
                  <BookmarkSVGIcon enabled={list.hasTargetId(model.targetId)} />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Window>
  );
});
