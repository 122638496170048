import React from 'react';
import { observer } from 'mobx-react-lite';
import { Placement } from '@popperjs/core';
import { MenuButton } from '../menu';
import { BookmarkList } from '../../entities';
import { ObjectLiteral } from '../../core';
import { MenuItem } from '../input/menuItems';

type Props = {
  model: BookmarkList;
  className?: string | ObjectLiteral;
  shouldRedirectAfterDelete?: boolean;
  placement?: Placement;
};

export const BookmarkListMenu = observer((props: Props) => {
  const { model } = props;

  return (
    <div className="bookmark-list-menu card-menu">
      <MenuButton className={props.className} layout="horizontal" placement={props.placement}>
        <MenuItem
          model={{ value: 'Rename', label: 'Rename' }}
          onClick={(evt) =>
            model.dispatch('openEditBookmarkListWindow', {
              bookmarkListId: model.id,
            })
          }
        />

        <MenuItem
          model={{ value: 'Export to EDL', label: 'Export to EDL' }}
          onClick={(evt) =>
            model.dispatch('openExportToEdlWindow', {
              bookmarkListId: model.id,
            })
          }
        />

        <MenuItem
          model={{ value: 'Delete List', label: 'Delete' }}
          onClick={(evt) =>
            model.dispatch('openDeleteBookmarkListWindow', {
              bookmarkListId: model.id,
              shouldRedirect: props.shouldRedirectAfterDelete,
            })
          }
        />
      </MenuButton>
    </div>
  );
});
