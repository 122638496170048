import { action, makeObservable, observable } from 'mobx';
import { Store } from '../../../store/store';
import { StoreNode } from '../../../store';
import { LoginFormState } from './loginFormState';

type AuthLoginPageMode = 'poster' | 'plain';
export class LoginPageState
  extends StoreNode {

  @observable layout: AuthLoginPageMode = 'poster';

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  readonly form = new LoginFormState(this.store);

  @action
  attached() {

  }

  @action
  detached() {
    this.form.reset();
  }
}