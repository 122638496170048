import identity from 'lodash/identity';
import { computed, makeObservable } from 'mobx';
import { JobModel, MomentSelector } from '..';
import { xor } from '../../core';
import { BindingProps, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { Comment, Reaction } from '../comment';
import { Generic, Moment, MomentModel, SubTopic, Topic, Transcript } from '../moment';
import { Speaker, SpeakerModel } from '../speaker';
import { PlayerItemSource } from './playerItemSource';

type Props = {
  momentId?: string,
  speakerId?: string,
  commentId?: string,
  reactionId?: string
} & BindingProps<{
  source?: PlayerItemSource
}>

export type PlayerItemTargetEntityType =
  'Moment' |
  'Speaker' |
  'Comment' |
  'Reaction' |
  'None';

export type PlayerItemTargetType =
  'Topic' |
  'SubTopic' |
  'Paragraph' |
  'Chapter' |
  'Moment' |
  'ActionItem' |
  'Transcript' |
  'Speaker' |
  'Company' |
  'Comment' |
  'Reaction' |
  'None';

export type PlayerItemPlaybackRole =
  'None' |
  'Topic' |
  'ShadowTopic' |
  'SubTopic' |
  'Speaker' |
  'Highlight' |
  'HighlightTopic' |
  'HighlightSubTopic' |
  'HighlightMoment' |
  'Full';

export type PlayerItemTarget =
  Topic |
  SubTopic |
  Generic |
  Transcript |
  Speaker |
  Moment |
  Comment |
  Reaction;

/**
 * Represents an object like a Moment or a Speaker which has some sort of significance in the context of a Player.
 * Each entity should have a single Item assigned, with various props exposed for determining the behaviour.
 */
export class PlayerItem<T extends PlayerItemTarget = PlayerItemTarget>
  extends StoreNode {

  constructor(store: Store, props?: Partial<Props>) {
    super(store, props);
    makeObservable(this);

    this.momentId = props?.momentId || null;
    this.speakerId = props?.speakerId || null;
    this.commentId = props?.commentId || null;
    this.reactionId = props?.reactionId || null;

    if (!this.jobId || !xor(this.momentId, this.speakerId, this.commentId, this.reactionId))
      console.error(`The input parameters for PlayerItem are invalid. Make sure you specify a 'jobId' and either a 'momentId', a 'speakerId' or a 'commentId', but not more than one of these.`);
  }

  readonly momentId: string | null = null;
  readonly speakerId: string | null = null;
  readonly commentId: string | null = null;
  readonly reactionId: string | null = null;

  get key() {
    return this.targetId;
  }

  @computed
  get source(): PlayerItemSource | null {
    return this.getResolvedProp('source');
  }

  @computed
  get selector(): MomentSelector | null {
    return this.source?.selector || null;
  }

  @computed
  get jobId(): string | null {
    return this.source?.jobId || null;
  }

  @computed
  get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId) || null;
  }


  @computed
  get moment(): MomentModel | null {
    return this.job?.maybeGetMoment(this.momentId) || null;
  }

  @computed
  get speaker(): SpeakerModel | null {
    return this.store.maybeGetSpeaker(this.speakerId) || null;
  }

  @computed
  get comment(): Comment | null {
    return this.job?.getComment(this.commentId) ?? null;
  }

  @computed
  get reaction(): Reaction | null {
    return this.job?.getReaction(this.reactionId) ?? null;
  }


  @computed
  get targetEntityType(): PlayerItemTargetEntityType {

    if (this.momentId)
      return 'Moment';
    if (this.speakerId)
      return 'Speaker';
    if (this.commentId)
      return 'Comment';
    if (this.reactionId)
      return 'Reaction';

    return 'None';
  }

  @computed
  get targetType(): PlayerItemTargetType {

    switch (this.targetEntityType) {
      case 'Moment':
        const { moment } = this;
        if (!moment)
          return 'Moment';
        return moment.clipType;

      case 'Speaker':
        return 'Speaker';
      case 'Comment':
        return 'Comment';
      case 'Reaction':
        return 'Reaction';
    }

    return 'None';
  }

  @computed
  get targetId(): string | null {
    switch (this.targetEntityType) {
      case 'Moment': return this.momentId;
      case 'Speaker': return this.speakerId;
      case 'Comment': return this.commentId;
      case 'Reaction': return this.reactionId;
    }
    return null;
  }

  @computed
  get target(): T | null {
    switch (this.targetEntityType) {
      case 'Moment': return this.moment as T;
      case 'Speaker': return this.speaker as T;
      case 'Comment': return this.comment as T;
      case 'Reaction': return this.reaction as T;
    }
    return null;
  }

  @computed
  get isTopicNode() {
    return this.targetType === 'Topic';
  }
  /** 
   * Returns true if `targetType` is set to `Topic` and there is a valid `Moment` entity 
   * associated with this instance which has a `clipType` of `Topic`.
   */
  @computed
  get isValidTopicNode(): boolean {
    return this.isTopicNode && !!this.moment?.isTopic;
  }

  @computed
  get isSubTopicNode() {
    return this.targetType === 'SubTopic';
  }
  /** 
   * Returns true if `targetType` is set to `SubTopic` and there is a valid `Moment` entity 
   * associated with this instance which has a `clipType` of `SubTopic`.
   */
  @computed
  get isValidSubTopicNode(): boolean {
    return this.isSubTopicNode && !!this.moment?.isSubtopic;
  }

  @computed
  get isTranscriptNode() {
    return this.targetType === 'Transcript';
  }
  @computed
  get isSpeakerNode() {
    return this.targetType === 'Speaker';
  }

  @computed
  get siblingSpeakerItem(): PlayerItem<Speaker> | null {
    return this.source?.getItem<Speaker>(this.moment?.actualSpeakerId) || null;
  }

  @computed
  get parentTopicItem(): PlayerItem<Topic> | null {
    return this.source?.getItem<Topic>(this.moment?.parentTopicId) || null;
  }

  @computed
  get childSubTopicItems(): PlayerItem<SubTopic>[] {
    return this.moment?.childSubTopics
      .map(sub => this.source?.getItem(sub)!)
      .filter(identity) || [];
  }

  // #region Selection queries
  // -------

  @computed
  get isSelected() {
    const { selector, targetId } = this;
    if (!selector || !targetId)
      return false;

    switch (this.targetType) {
      case 'Topic':
        return selector.isTopicSelected(targetId);

      case 'SubTopic':
        return selector.isSubTopicSelected(targetId);

      case 'Speaker':
        return selector.isSpeakerSelected(targetId);
    }

    return false;
  }

  @computed
  get isIndirectlySelected() {
    const { selector, targetId, moment } = this;
    if (!selector || !targetId)
      return false;

    switch (this.targetType) {
      case 'Topic':
      case 'SubTopic':
        return (moment?.actualSpeakerId && selector.isSpeakerSelected(moment?.actualSpeakerId));
    }

    return false;
  }

  @computed
  get areAllChildSubTopicsSelected() {
    const { selector, targetId } = this;
    if (this.targetType !== 'Topic' || !selector || !targetId)
      return false;
    return selector.areAllSubTopicsSelectedForTopic(targetId);
  }

  @computed
  get areNoChildSubTopicsSelected() {
    const { selector, targetId } = this;
    if (this.targetType !== 'Topic' || !selector || !targetId)
      return false;
    return selector.areNoSubTopicsSelectedForTopic(targetId);
  }

  @computed
  get areSomeChildSubTopicsSelected() {
    const { selector, targetId } = this;
    if (this.targetType !== 'Topic' || !selector || !targetId)
      return false;
    return selector.areSomeSubTopicsSelectedForTopic(targetId);
  }

  // #endregion

  @computed
  get useInDirector(): boolean {

    const { selector, targetId } = this;
    if (!selector || !targetId)
      return false;

    switch (selector.mode) {

      case 'Topics':

        switch (this.targetType) {
          case 'Topic':
            return (
              this.isSelected &&
              !this.areSomeChildSubTopicsSelected);

          case 'SubTopic':
            const parentTopicId = this.moment?.parentTopic?.id;
            if (!parentTopicId)
              return false;

            return (
              this.isSelected &&
              !!this.parentTopicItem?.isSelected &&
              selector.areSomeSubTopicsSelectedForTopic(parentTopicId));
        }
        break;

      case 'Speakers':

        const spkId = this.moment?.actualSpeakerId;
        const isSpeakerSelected = !!spkId && selector.selectedSpeakerIds.has(spkId);

        return isSpeakerSelected;
    }

    return false;
  }

  @computed
  get useInReporter() {
    return true;
  }

  @computed
  get showOnPreviewTimeline() {
    // TODO: implement using items
    const { selector, targetId } = this;
    if (!selector || !targetId)
      return false;

    return this.useInDirector;
  }

  @computed
  get playbackRole(): PlayerItemPlaybackRole {

    const { selector, targetId } = this;
    if (!selector || !targetId)
      return 'None';

    if (
      selector.highlightedMoment &&
      selector.highlightedMoment.id === this.targetId) {

      switch (this.targetType) {
        case 'Topic': return 'HighlightTopic';
        case 'SubTopic': return 'HighlightSubTopic';
        case 'Moment': return 'HighlightMoment';
      }

      return 'Highlight';
    }

    const isTopic = this.isTopicNode;
    const isSubTopic = this.isSubTopicNode;
    const isTranscriptNode = this.isTranscriptNode;

    const speaker = this.moment?.actualSpeaker ?? null;
    const forSpeakersMode = (isTopic || isSubTopic || isTranscriptNode) && !!speaker;
    const isSpeakerSelected = selector.isSpeakerSelected(speaker);

    switch (selector.qualifiedMode) {

      case 'Topics':

        switch (this.targetType) {
          case 'Topic':
            if (!this.isSelected)
              return 'None';
            if (this.areSomeChildSubTopicsSelected)
              return 'ShadowTopic';
            return 'Topic';

          case 'SubTopic':
            if (!this.isSelected || !this.parentTopicItem?.isSelected)
              return 'None';
            return 'SubTopic';
        }
        break;

      case 'TopicsVideo':
        switch (this.targetType) {
          case 'Topic':
            return 'Topic';
          case 'SubTopic':
            return 'SubTopic';
        }
        break;

      case 'Speakers':
        return (forSpeakersMode && isSpeakerSelected) ? 'Speaker' : 'None';
      case 'SpeakersVideo':
        return forSpeakersMode ? 'Speaker' : 'None';
    }

    return 'None';
  }

  @computed
  get playbarRenderOrder(): number {

    switch (this.playbackRole) {
      case 'Topic':
      case 'ShadowTopic': return 1;
      case 'Speaker': return 2;
      case 'SubTopic': return 3;

      case 'HighlightTopic': return 4;
      case 'HighlightSubTopic': return 5;
      case 'HighlightMoment': return 6;
      case 'Highlight': return 7;

      case 'Full': return 8;
    }

    return 0;
  }

  @computed
  get showOnPlaybar() {
    return this.playbackRole !== 'None';
  }

  @computed
  get showOnMarkerBar() {
    if (this.job?.isLiveStreaming)
      return false;

    return (
      this.isComment ||
      this.isReaction);
  }

  @computed
  get markerBarRenderOrder(): number {
    // TODO: implement
    return 0;
  }

  @computed
  get showAsActiveTopic(): boolean {
    return (
      this.isValidTopicNode &&
      (this.isSelected || !!this.selector?.isVideoOutputMode));
  }

  @computed
  get showAsActiveSubTopic(): boolean {
    return (
      this.isValidSubTopicNode &&
      (this.isSelected || !!this.selector?.isVideoOutputMode));
  }

  @computed
  get showAsActiveSpeaker(): boolean {
    return (
      this.momentHasSpeaker &&
      (this.isMomentSpeakerSelected || !!this.selector?.isVideoOutputMode));
  }

  @computed
  get showInSpeakerIdForm(): boolean {
    return this.showAsActiveSpeaker && this.momentHasPrivateSpeaker;
  }

  @computed
  get showNextTopicButton(): boolean {
    return !!this.moment && this.playbackRole === 'Topic';
  }
  @computed
  get showNextSubTopicButton(): boolean {
    return !!this.moment && this.playbackRole === 'SubTopic';
  }
  @computed
  get showNextSpeakerButton(): boolean {
    return (this.momentHasSpeaker && this.playbackRole === 'Speaker')
  }


  @computed
  get showInTranscriptsBox(): boolean {
    return this.targetType === 'Transcript' && !!this.moment;
  }

  @computed
  get showInDetailsPanel() {
    return (
      this.targetType === 'Moment' ||
      this.targetType === 'SubTopic') && !!this.moment;
  }

  @computed
  get momentHasSpeaker(): boolean {
    return !!this.moment?.hasSpeaker;
  }

  @computed
  get momentHasPrivateSpeaker(): boolean {
    const { moment } = this;
    if (!moment)
      return false;

    const { hasSpeaker, isSpeakerPrivate } = moment;
    return hasSpeaker && isSpeakerPrivate;
  }

  @computed
  get momentSpeakerId(): string | null {
    return this.moment?.actualSpeakerId || null;
  }
  @computed
  get momentSpeaker(): SpeakerModel | null {
    return this.moment?.actualSpeaker || null;
  }

  @computed
  get isMomentSpeakerSelected(): boolean {
    return this.selector?.isSpeakerSelected(this.momentSpeakerId) || false;
  }

  // #region Index section

  @computed
  get showInIndexSection(): boolean {
    return this.playbackRole !== 'None';
  }
  // #endregion

  // region Comments section 
  @computed
  get showInCommentsSection(): boolean {
    return this.isCommentNode && !!this.comment;
  }

  @computed
  get isCommentNode() {
    return this.targetType === 'Comment';
  }

  @computed
  get isReactionNode() {
    return this.targetType === 'Reaction';
  }


  @computed
  get isComment() {
    return this.isCommentNode && !this.comment?.isReply;
  }

  @computed
  get isReply() {
    return this.isCommentNode && this.comment?.isReply;
  }

  @computed
  get isReaction() {
    return this.isReactionNode;
  }
  // #endregion
}