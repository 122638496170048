import { makeObservable, observable, computed, action } from 'mobx';
import { MomentSource } from '@clipr/lib';
import { JobModel, MomentModel, MomentStub } from '../../entities';
import { BindingProps, Message, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { IPlayer } from '../player';
import { ClipWindowState, OpenClipWindowOptions } from '../momentWindow/clipWindowState';

type Props = BindingProps<{
  source: MomentSource,
  player: IPlayer,
  jobId: string
}>

export class MomentEditorState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.player.listen(
      this.playerListener);
  }

  // #region Resolved props
  // -------
  @computed get source(): MomentSource {
    return this.resolvedProps.source;
  }
  @computed get player(): IPlayer {
    return this.resolvedProps.player;
  }
  @computed get jobId(): string | null {
    return this.resolvedProps.jobId;
  }
  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }
  // #endregion

  @observable currentStub: MomentStub | null = null;
  @observable isEditMode = false;

  @action
  enterEditMode() {
    this.isEditMode = true;
    this.editStartMoment();
  }

  @action
  exitEditMode() {
    this.cancel();
  }

  @action
  toggleEditMode() {
    if (this.isEditMode)
      this.exitEditMode();
    else
      this.enterEditMode();
  }

  @action
  editStartMoment() {
    const { player } = this;

    const stub = new MomentStub(this.store);

    stub.designStartTime = player.currentTime;
    stub.designEndTime = player.currentTime;
    stub.status = 'creatingFromPlayer';

    this.currentStub = stub;
  }

  @action
  editConfirmMoment(opts?: Partial<OpenClipWindowOptions> | null) {

    if (!this.currentStub || !this.jobId) {
      console.warn(`Invalid MomentEditorState for 'editConfirmMoment'. 'currentStub' is `, this.currentStub, `. 'jobId' is `, this.jobId);
      this.cancel();
      return false;
    }

    const window = this.store.clipWindow;
    const listener = (msg: Message<ClipWindowState>) => {
      switch (msg.type) {
        case 'open':
          this.player.invoke('enterPause');
          break;
        case 'momentCreated':
        case 'close':
          window.unlisten(listener);
          if (this.source !== 'User')
            this.player.invoke('exitPause');
          this.cancel();
          break;
      }
    }

    window.listen(listener);
    window.openMomentStub({
      stub: this.currentStub,
      jobId: this.jobId,
      source: this.source,
      options: {
        momentKindLabel: 'moment',
        layout: 'User',
        ...opts
      }
    });

    return true;
  }

  @action
  openEditMomentWindow(moment: MomentModel) {

    if (!this.jobId) {
      console.warn(`Invalid MomentEditorState for 'editConfirmMoment'. 'jobId' is `, this.jobId);
      this.cancel();
      return false;
    }

    const window = this.store.clipWindow;
    const listener = (msg: Message<ClipWindowState>) => {
      switch (msg.type) {
        case 'open':
          this.player.invoke('enterPause');
          break;

        case 'close':
          window.unlisten(listener);
          if (this.source !== 'User')
            this.player.invoke('exitPause');
          this.cancel();
          break;
      }
    }

    window.listen(listener);
    if (moment) {
      window.openMomentEdit({
        momentId: moment.id,
        jobId: moment.jobId,
        source: this.source,
        options: {
          momentKindLabel: 'moment',
          layout: 'User'
        }
      })
    }

    return true;
  }

  @action
  cancel() {
    this.isEditMode = false;
    this.currentStub = null;
  }

  @action
  playerListener = (msg: Message) => {
    const {
      player,
      currentStub: stub
    } = this;

    switch (msg.type) {
      case 'timeupdate':
        if (player.isSeekDragging)
          break;
        if (!stub)
          break;
        if (stub.status === 'creatingFromPlayer') {
          stub.designEndTime = player.currentTime;
        }
        break;

      case 'seekChange':
        if (!stub)
          break;

        if (stub.status === 'creatingFromPlayer') {
          stub.designEndTime = player.currentSeekTime!;
        }
    }
  }

  @action
  reset() {
    this.cancel();
  }
}