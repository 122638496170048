import { AsyncResult } from '../../../core/async';
import { Error } from '../../../core/error';

export type CanAutoplayOptions = {
  muted?: boolean;
  timeout?: number;
  inline?: boolean;
}

export const DefaultCanAutoplayTimeout = 1000;
export const DefaultCanAutoplayOptions: CanAutoplayOptions = {
  muted: false,
  timeout: DefaultCanAutoplayTimeout,
  inline: false
};

export type CanAutoplayResult = AsyncResult<boolean, Error>;