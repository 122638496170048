import './widgets.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { InlineAuthFlowController } from '../../services/auth/controllers/inlineAuthFlowController';
import { WidgetProxyFlowScreen } from './widgetProxyFlowScreen';

type Props = {
  controller: InlineAuthFlowController;
}

export const WidgetInlineAuthFlowMask = observer(({
  controller
}: Props) => {

  const { isFlowRunning } = controller;
  if (!isFlowRunning)
    return null;

  return (
    <WidgetProxyFlowScreen
      flowName={controller.flowName}
      onAbortButtonClick={() => controller.abort()}
      onFocusButtonClick={() => controller.focus()} />
  );
});