import './searchResultsPage.scss';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store/storeHooks';
import { Grid } from '../../components/layout';
import { SearchResultVideoCard } from './searchResultCard';
import { SearchSVGIcon } from '../../components';
import { JobModel } from '../../entities';

const PopularVideos = observer(() => {
  const store = useStore();
  const { jobCatalog } = store.searchResultsPage;
  const videos = jobCatalog.popularVideos;

  return (
    <>
      {videos.length > 0 ? (
        <>
          <div className="separator" />

          <div className="results-cathegory catalog">
            <h3 className="cathegory-title">Try out these popular videos</h3>
            <div className="catalog-content catalog-viewport">
              <Grid className="full-video-results catalog-grid">
                {videos.map((video: JobModel) => (
                  <li key={video.id}>
                    <SearchResultVideoCard model={video} />
                  </li>
                ))}
              </Grid>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
});

export const NoResults = ({ queryString }: { queryString: string }) => {
  return (
    <>
      <div className="catalog empty-catalog">
        <div className="icon">
          <SearchSVGIcon />
        </div>
        <h1 className="headline-1">No results found for "{queryString}".</h1>
        <p>
          Make sure all words are spelled correctly or try different keywords.
        </p>
      </div>

      <PopularVideos />
    </>
  );
};
