import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  GoogleDriveSVGIcon,
  OneDriveSVGIcon,
  ZoomSVGIcon,
} from '../../components';
import { Button } from '../../components/input';
import { useStore } from '../../store/storeHooks';

export const UploadIntegrationControlsBox = observer(() => {
  const store = useStore();
  const { uploadService, libraryService } = store;

  const libraries = libraryService.enabledLibraries;
  if (!libraries.length)
    return null;
    
  return (
    <div className="upload-integration-box">
      {libraries.includes('Google Drive') && (
        <Button
          className="upload-box integration-controls"
          label="Google Drive"
          icon={<GoogleDriveSVGIcon />}
          onClick={() => uploadService.navigateToGoogleDriveTab()} />
      )}

      {libraries.includes('Zoom') && (
        <Button
          className="upload-box integration-controls"
          label="Zoom"
          icon={<ZoomSVGIcon />}
          onClick={() => uploadService.navigateToZoomTab()} />
      )}

      {libraries.includes('OneDrive') && (
        <Button
          className="upload-box integration-controls"
          label="OneDrive"
          icon={<OneDriveSVGIcon />}
          onClick={() => uploadService.navigateToOneDriveTab()} />
      )}
    </div>
  );
});