import React from 'react';
import './uploadPage.scss';
import './externalLibraryUpload.scss';
import '../../components/layout/layout.scss';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../../components/overlays';
import { Button, SearchTextInputControlled } from '../../components/input';
import { GoogleDriveSVGIcon, ZoomSVGIcon, OneDriveSVGIcon, ListViewSVGIcon, GridViewSVGIcon } from '../../components';
import { DateInput } from '../../components/input/dateInput/dateInput';
import { ReactNode } from 'react';
import { CatalogState } from '../../components/layout/catalogState';
import { ExternalLibraryUploadWindowState } from './externalLibraryUploadWindowState';
import { GoogleDriveTab } from '../integrations/googleDriveTab';
import { OneDriveTab } from '../integrations/oneDriveTab';
import { ZoomTab } from '../integrations/zoomTab';
type Props = {
  model: ExternalLibraryUploadWindowState;
  visible?: boolean;
};

export const ExternalLibraryUploadWindow = observer((props: Props) => {
  const { model } = props;

  const ViewSelector = observer(
    ({ model }: { model: ExternalLibraryUploadWindowState }) => {
      let catalog: CatalogState;

      if (model.activeTab === 'Google Drive') {
        catalog = model.googleDriveTabState.catalog;
      } else if (model.activeTab === 'OneDrive') {
        catalog = model.oneDriveTabState.catalog;
      } else if (model.activeTab === 'Zoom') {
        catalog = model.zoomIntegrationTabState.catalog;
      } else {
        return null;
      }

      const icon: ReactNode =
        catalog.viewType === 'grid' ? <ListViewSVGIcon /> : <GridViewSVGIcon />;

      return (
        <Button
          className="large"
          icon={icon}
          onClick={() => catalog.toggleViewType()}
        />
      );
    }
  );

  const SearchComponent = observer(
    ({ model }: { model: ExternalLibraryUploadWindowState }) => {
      const { googleDriveTabState, oneDriveTabState } = model;
      const googleDriveCatalogSource = googleDriveTabState.catalogSource;
      const oneDriveCatalogSource = oneDriveTabState.catalogSource;

      switch (model.activeTab) {
        case 'Google Drive':
          return (
            <div className="search-bar">
              <SearchTextInputControlled
                model={googleDriveCatalogSource.searchBarModel}
                persistentFeedback={false}
                id="search"
                name="search"
                type="text"
                autoComplete="off"
                placeholder="Search"
                showFeedback={false}
                disabled={
                  !model.store.googleDrive.isConnected ||
                  !!model.store.googleDrive.error
                }
                onClear={() =>
                  googleDriveCatalogSource.handleSearchInputChange({})
                }
              />
            </div>
          );
        case 'OneDrive':
          return (
            <div className="search-bar">
              <SearchTextInputControlled
                model={oneDriveCatalogSource.searchBarModel}
                persistentFeedback={false}
                id="search"
                name="search"
                type="text"
                autoComplete="off"
                placeholder="Search"
                showFeedback={false}
                disabled={
                  !model.store.oneDrive.isConnected ||
                  !!model.store.oneDrive.error
                }
                onClear={() => oneDriveCatalogSource.handleSearchInputChange({})}
              />
            </div>
          );

        case 'Zoom':
        default:
          return null;
      }
    }
  );

  return (
    <Window
      className="external-library-upload"
      model={model.window}
      visible={props.visible}
      isLoading={model.isLoading}>
      <WindowHeader model={model.window} title="External Library" />

      <hr />

      <div className={`${model.activeTab === 'Zoom' ? 'zoom-catalog' : ''}
       window-content catalog-page with-padding`}>

        {model.activeTab !== 'Zoom' &&
          ((model.activeTab === 'Google Drive' && model.store.googleDrive.isConnected) ||
            (model.activeTab === 'OneDrive' && model.store.oneDrive.isConnected)) &&
          < div className="search-container">
            <div className="flex-left nav-bar">
              <SearchComponent model={model}></SearchComponent>
            </div>
          </div>}
        <header className="page-header">
          <div className="flex-left tab-navigation medium">
            {model.googleDriveTabState.enabled && (
              <Button
                className="tab"
                label="Drive"
                icon={<GoogleDriveSVGIcon />}
                active={model.activeTab === 'Google Drive'}
                onClick={() => model.navigateToTab('Google Drive')}
              />
            )}
            {model.zoomIntegrationTabState.enabled && (
              <Button
                className="tab"
                label="Zoom"
                icon={<ZoomSVGIcon />}
                active={model.activeTab === 'Zoom'}
                onClick={() => model.navigateToTab('Zoom')}
              />
            )}
            {model.oneDriveTabState.enabled && (
              <Button
                className="tab"
                label="OneDrive"
                icon={<OneDriveSVGIcon />}
                active={model.activeTab === 'OneDrive'}
                onClick={() => model.navigateToTab('OneDrive')}
              />
            )}
          </div>

          <div className="flex-right view-selector">
            {model.activeTab === 'Zoom' && model.store.zoom.isConnected && (
              <div
                className="zoom-date-selector">
                <span className="filter-label">Recordings from:</span>

                <div className="date-wrapper">
                  <DateInput
                    model={model.zoomIntegrationTabState.catalogSource.datepicker}
                    onApply={() => { model.zoomIntegrationTabState.catalogSource.handleDateChange(); }}
                  ></DateInput>
                </div>
              </div>
            )}
            {model.activeTab === 'OneDrive' && model.store.oneDrive.isConnected && (
              <div className="helper-text">
                Recently created videos will take <br /> longer to show up in the
                library.
              </div>
            )}
            {((model.activeTab === 'Google Drive' && model.store.googleDrive.isConnected) ||
              (model.activeTab === 'OneDrive' && model.store.oneDrive.isConnected) ||
              (model.activeTab === 'Zoom' && model.store.zoom.isConnected)) &&
              <ViewSelector model={model} />}
          </div>
        </header>

        {model.activeTab === 'Google Drive' && <GoogleDriveTab model={model.googleDriveTabState} />}
        {model.activeTab === 'Zoom' && <ZoomTab model={model.zoomIntegrationTabState} />}
        {model.activeTab === 'OneDrive' && <OneDriveTab model={model.oneDriveTabState} />}
      </div>
    </Window >
  );
});
