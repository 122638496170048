import '../input.scss';
import '../inputs-v1-1.scss';

import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { FileInputProps } from './fileInputSchema';
import { HTMLDivProps } from '../../../core/reactSchema';
import { PhotoSVGIcon, UserAvatarSVGIcon } from '../../svg/svg';
import { LoadMask } from '../../loader/loadMask';
import { Button } from '../button';

export const AvatarUploadBox = observer(
  ({ className, disabled, layoutSize, onChange, previewUrl, isLoading, onLoad }: FileInputProps) => {
    className = classNames( //className
      'avatar-upload-box',
      layoutSize,
      {
        disabled
      }
    );

    const inputRef = useRef(null);

    const onClick = () => {
      const input = inputRef.current;
      if (input) {
        // @ts-ignore
        input.click();
      }
    };

    return (
      <div className={className}>
        <AvatarPreviewBox
          model={{ pictureURL: previewUrl }}
          isLoading={isLoading}
          onLoad={() => onLoad?.()} />
        {!disabled && !isLoading && (
          <Button
            className="upload-photo-button secondary"
            leftIcon={<PhotoSVGIcon />}
            onClick={onClick} />
        )}
        <input
          ref={inputRef}
          type="file"
          className="file-input"
          role="presentation"
          hidden
          onChange={onChange} />
      </div>
    );
  });

interface IAvatar {
  pictureURL?: string | null
}

type AvatarPreviewBoxProps = HTMLDivProps<{
  model?: IAvatar;
  isLoading?: boolean;
  onLoad?: () => void;
}>;

/**
 * Displays the avatar for the provided Speaker.
 */
export const AvatarPreviewBox = observer(
  ({ model, className, isLoading, onLoad }: AvatarPreviewBoxProps) => {
    const picture = model?.pictureURL;

    const imgRef = useRef<HTMLImageElement>(null);

    className = classNames(
      'avatar-preview-box',
      {
        empty: !picture,
      },
      className
    );

    // TEMP: assume that the img is already loaded
    if (imgRef.current?.src === picture && onLoad) {
      onLoad(); // QUICK FIX: not entirely correct but it serves the purpose for the moment 
    }

    return (
      <div className={className}>
        {picture ? (
          <img
            className="preview-picture"
            src={picture}
            ref={imgRef}
            alt={'Upload avatar preview'}
            onLoad={() => onLoad?.()} />
        ) : (
          <UserAvatarSVGIcon />
        )}
        <LoadMask
          className={'avatar-load-mask spinner small'}
          spinner={'dots3'}
          visible={isLoading} />
      </div>
    );
  }
);
