import {
  action,
  makeObservable,
  observable,
  computed
} from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import {
  input,
  inputGroup,
  InputGroupState,
  InputState,
  DropdownItemObject
} from '../input';
import { notifyError, notifySuccess, notifyWarning } from '../../services/notifications';
import { ApiVariables } from '../../api/apiSchema';
import { assertNotNull } from '../../core';
import { TeamManager } from '../../entities/teamManager';

import Routes from '../../routes';
import { ApiUploadRequestParams } from '../../api';
import { FormEvent } from 'react';
import { AddInviteListState } from './addInviteListState';
import { getJobIndustryLabel, JobIndustry } from '../../entities/job/JobIndustry';
import { Industry } from '@clipr/lib';
import { TeamMetadataState } from '../teams/teamMetadataState';
import { TeamEnrichmentLevelState } from '../teams/teamEnrichmentLevelState';

const FILE_SIZE_IN_MB = 2;
const PHOTO_MAX_SIZE = FILE_SIZE_IN_MB * 1024 * 1024; // 10Mb 
const FILE_TYPES = ['image/jpeg', 'image/png'];

export type RoleItem = DropdownItemObject;

const roleSelectorItems: RoleItem[] = [
  // { value: 'Owner', label: 'Owner', description: 'View, edit, manage users, and manage billing.' },
  { value: 'Admin', label: 'Admin', description: 'Upload, view, edit, manage library users and videos.' },
  { value: 'Trainer', label: 'Trainer', description: 'Upload, view, edit, and manage library videos.' },
  { value: 'Member', label: 'Member', description: 'Upload and edit library videos.' },
  { value: 'Viewer', label: 'Viewer', description: 'View library videos.' },
  // { value: 'Remove Access', label: 'Remove Access' }
];

const privacySelectorItems = [
  { value: 'public', label: 'Shareable', description: 'Visible to all' },
  { value: 'private', label: 'Private', description: 'Visible to members of this organization' }
];

function checkDisabled(input: any, disable: boolean) {
  return (input.loading ||
    input.isSubmitting ||
    disable)
}

export class CreateTeamFormState
  extends StoreNode {

  readonly teamStore = new TeamManager(this.store);
  readonly nodeType = 'CreateTeamForm';
  readonly teamName: InputState;
  readonly publicSafety: InputState;
  readonly teamDescription: InputState;
  readonly teamIndustry: InputState;
  readonly form: InputGroupState;
  readonly saveButton: InputState;
  readonly navButton: InputState;
  readonly addInviteListState: AddInviteListState;
  readonly teamMetadata: TeamMetadataState;
  readonly teamEnrichmentLevel: TeamEnrichmentLevelState;
  readonly teamPrivacy: InputState;

  @observable targetId: string | null = null;
  @observable fileCache: File | null = null;
  @observable isLoading = false;
  @observable isLocked = false;

  @observable isAdvancedSectionExpanded = false;
  @observable isVideoEnrichmentLevelExpanded = false;

  constructor(store: Store) {
    super(store);
    makeObservable(this);
    this.saveButton = input(this, {
      name: 'saveButton',
      disabled: (input: any) => {
        return (
          input.loading ||
          input.isSubmitting ||
          (this.form as any).hasTouchedErrorInputs) ||
          this.isLocked
      }
    });
    this.navButton = input(this, {
      name: 'navButton',
      disabled: (input: any) => {
        return (
          input.loading ||
          input.isSubmitting ||
          (this.form as any).hasTouchedErrorInputs) ||
          !this.isLocked
      }
    });
    this.teamName = input(this, {
      name: 'teamName',
      isRequired: true,
      placeholder: 'Library Name',
      disabled: input => checkDisabled(input, this.isLocked)
    });
    this.teamDescription = input(this, {
      name: 'teamDescription',
      isRequired: false,
      multiline: true,
      placeholder: 'Library Description',
      disabled: input => checkDisabled(input, this.isLocked)
    });
    this.teamIndustry = input(this, {
      name: 'teamIndustry',
      selectorItems: JobIndustry.map(item =>
        ({ value: item, label: getJobIndustryLabel(item) })),
      isRequired: false,
      placeholder: 'Field of activity',
    });
    this.teamPrivacy = input(this, {
      name: 'teamPrivacy',
      isRequired: true,
      selectorItems: privacySelectorItems,
      disabled: true //input => checkDisabled(input, this.isLocked)
    });
    this.publicSafety = input(this, {
      name: 'publicSafety',
      disabled: () => !this.isUserAdminOrTrainer
    });
    this.addInviteListState = new AddInviteListState(store, {
      roleItems: () => this.roleItems,
      checkInviteValidity: this.checkInviteValidity
    });
    this.teamMetadata = new TeamMetadataState(this.store);
    this.teamEnrichmentLevel = new TeamEnrichmentLevelState(this.store);
    this.form = inputGroup(this, {
      name: 'team',
      inputs: () => {
        return [
          this.teamName,
          this.teamDescription,
          this.teamIndustry,
          this.teamPrivacy,
          this.publicSafety,
          this.saveButton,
          this.addInviteListState.inviteInputGroup,
          this.teamMetadata.form,
          this.teamEnrichmentLevel.form
        ]
      },
      disabled: (input: any) => {
        return (
          input.hasLoadingInputs ||
          input.isSubmitting ||
          this.isLocked
        )
      }
    });

    this.teamPrivacy.loadValue('private');
  }

  @computed
  get isUserAdminOrTrainer() {
    return this.store.user?.isAdmin || this.store.user?.isTrainer;
  }

  @computed
  get avatar() {
    if (this.fileCache)
      return URL.createObjectURL(this.fileCache);
    else if (this.target)
      return this.target.pictureURL;
    else
      return null;
  }

  @computed
  get target() {
    return this.teamStore.getTeam(this.targetId);
  }

  @computed
  get roleItems(): RoleItem[] {
    return this.store?.user?.hasPermission('AddTeamTrainer') ?
      roleSelectorItems :
      roleSelectorItems.filter(role => role.value !== 'Trainer');
  }

  @action
  async handleFileInput(e: FormEvent<HTMLInputElement>) {
    const target = e.currentTarget;
    const fileList: FileList | null = target.files;

    if (!(fileList && fileList.length > 0))
      return;

    const [file] = fileList;

    if (file.size >= PHOTO_MAX_SIZE) {
      notifyError(this, `Photo size should be smaller than ${FILE_SIZE_IN_MB}Mb.`);
      return;
    }
    if (!FILE_TYPES.includes(file.type)) {
      notifyError(this, 'The accepted formats are jpeg and png.');
      return;
    }

    this.fileCache = file;
  }

  @action
  async submit() {
    this.isLoading = true;

    this.form.handleSubmit();

    if (this.form.error) {
      this.handleSubmitReject(`Fix the validation errors before submitting.`);
      return;
    }

    const name = this.teamName.value!;
    assertNotNull(name);

    const [, errTeams] = await this.teamStore.apiEnsureTeams();
    if (errTeams) {
      this.handleSubmitReject(`Could not fetch teams.`);
      return;
    }

    const existingTeam = this.teamStore.maybeGetTeamByName(name);
    if (existingTeam) {
      this.handleSubmitReject('Team name already exists. Please try another!');
      return;
    }
console.log(this.publicSafety.value, 'lala')
    const vars: ApiVariables<'createTeam'> = {
      args: {
        name,
        description: this.teamDescription.value || '',
        industry: this.teamIndustry.value as Industry || undefined,
        metadataFields: this.teamMetadata.export() || undefined,
        jobFeatures: this.teamEnrichmentLevel.export() || undefined,
        publicSafety: !!this.publicSafety.value
      }
    };
    // if (this.teamPrivacy.value)
    // vars.args.autoJoinOnSignup = this.teamPrivacy.value === 'public' || undefined;
    // create team request
    const [team, err] = await this.teamStore.apiCreateTeam(vars);
    if (err) {
      this.handleSubmitReject(`Could not create the team '${name}'. Please try again.`);
      return;
    }
    this.targetId = team?.id || null;
    // this.inviteListState.teamId = team?.id || null;
    // upload avatar request 
    if (!this.target) {
      this.handleSubmitReject(`Could not find the team`);
      return;
    }

    await this.submitAvatar();
    // send invite requests
    const hasError = await this.addInviteListState.submitInvites(this.target);

    this.broadcast('teamCreated');
    await this.fetchTeams(); //fetch teams globally

    if (hasError) {
      this.handleSubmitReject('');
      notifyWarning(this, `Library '${name}' created with failed invitations. Navigate to library page for resending them.`)
      this.lock();
    } else {
      this.handleSubmitSuccess(`Library '${name}' created successfully.`);
      this.navToTeam();
    }

  }

  @action
  async fetchTeams(): Promise<any[]> {
    const [, err] = await this.store.teamManager.apiFetchTeams();
    return [err];
  }

  navToTeam() {
    if (this.target)
      this.store.goTo(Routes.teamDashboard(this.target.id));
  }

  checkInviteValidity = (email: string) => {
    if (email === this.store.user?.email)
      return 'Owner already part of the library.';

    return null;
  }

  async submitAvatar() {
    const file = this.fileCache;

    if (file && this.target) {

      // Get the upload URL
      const [res, err] = await this.store.apiService.getAvatarUploadUrl({ teamId: this.target?.id, fileName: file.name });

      if (err) {
        this.handleSubmitReject('Failed to upload the library avatar.');
        return;
      }

      if (res) {
        const params = res.getAvatarUploadUrl;
        const uploadParams: ApiUploadRequestParams = {
          file,
          url: params.uploadUrl,
          data: params.fields
        }

        // Start the upload request
        const uploadRequest = this.store.apiService.uploadRequest(uploadParams);
        const [, uploadErr] = await uploadRequest.start();

        if (uploadErr) {
          this.handleSubmitReject('Failed to upload the library avatar.');
          return;
        }
        // Update the profile picture if the upload was successful
        const [, updateError] = await this.store.apiService.updateTeam({
          args: {
            teamId: this.target.id,
            avatarToken: params.uploadToken
          },
        });
        if (updateError) {
          this.handleSubmitReject('Failed to upload the library avatar.');
          return;
        }
      }
    }
  }

  @action
  init() {
    this.teamMetadata.init();
    this.teamEnrichmentLevel.init();
  }

  @action
  reset() {
    this.form.reset();
    this.addInviteListState.reset();
    this.teamMetadata.reset();
    this.teamEnrichmentLevel.reset();
    this.fileCache = null;
    this.unlock();
  }

  @action
  lock() {
    this.isLocked = true;
    this.addInviteListState.lock();
  }

  @action
  unlock() {
    this.isLocked = false;
    this.addInviteListState.unlock();
  }

  @action
  toggleAdvancedVideoDetails() {
    this.isAdvancedSectionExpanded = !this.isAdvancedSectionExpanded;
  }

  @action
  toggleVideoEnrichmentLevel() {
    this.isVideoEnrichmentLevelExpanded = !this.isVideoEnrichmentLevelExpanded;
  }

  @action
  private handleSubmitReject(msg: string) {
    this.isLoading = false;
    this.form.handleSubmitReject();
    notifyError(this, msg);
  }

  @action
  private handleSubmitSuccess(msg: string) {
    this.isLoading = false;
    this.form.handleSubmitResolve();
    notifySuccess(this, msg);
  }
}
