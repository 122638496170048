import { makeObservable, computed } from 'mobx';
import { ApiQueryName } from '../../../api/apiSchema';
import { BindingProps, StoreNode } from '../../../store';
import { Store } from '../../../store/store';
import { ChartPageParams } from '../chartSchema';
import { MetricCounterDataSource } from './metricCounterDataSource';

type Props = BindingProps<{
  label?: string;
  queryName?: ApiQueryName;
  queryParams?: ChartPageParams;
  display?: boolean;
  valueAccessor?: (apiResult: any) => any;
  outputData?: (result: any) => any;
}>

export class MetricCounterState
  extends StoreNode {

  readonly nodeType = 'MetricCounterState';

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);
  }

  // #region Props
  @computed get label(): string | null {
    return this.getResolvedProp('label') ?? null;
  }

  @computed get queryName(): ApiQueryName | null {
    return this.getResolvedProp('queryName') ?? null;
  }

  @computed get queryParams(): ChartPageParams | null {
    return this.getResolvedProp('queryParams') ?? null;
  }

  @computed get valueAccessor(): any {
    return this.getProp('valueAccessor') ?? null;
  }

  @computed get display(): boolean {
    return this.getResolvedProp('display') ?? true;
  }
  // #endregion

  @computed get outputData(): any {
    const outputDataCallback = this.getProp('outputData') ?? null;

    return (outputDataCallback && outputDataCallback(this.dataSource.value)) || 
      this.dataSource.value;
  }

  readonly dataSource = new MetricCounterDataSource(this.store, {
    queryName: () => this.queryName,
    queryParams: () => this.queryParams,
    valueAccessor: this.valueAccessor
  });

  async fetch() {
    return this.dataSource.fetch();
  }
  abort() {
    this.dataSource.cancel();
  }
}
