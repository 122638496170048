import { action, computed, makeObservable, observable } from 'mobx';
import { capitalCaseTransform } from 'change-case'
import { notifySuccess, notifyError } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { Bookmark, JobModel, MomentModel } from '../../entities';
import { input } from '..';
import { SharedFrom, ShareType, SocialSharePlatforms } from '../../services/analytics/stream';
import { Theme } from '../../services/ui/utils';
import { getPlayerWidgetIFrameCode, getPlayerWidgetIFrameSrc, buildUrlSearchParams, PlayerWidgetIFrameParams } from '../../widgets/playerWidget/playerWidgetUtils';
import { WidgetAuthMode } from '../../services/widget';

export const WINDOW_WIDTH = 1152;
export const WINDOW_HEIGHT = 700;

export enum ShareWindowMode {
  Social = 'social',
  Link = 'link'
};
export type ShareWindowProps = {
  job?: JobModel | null,
  teamId?: string | null,
  bookmark?: Bookmark | null,
  mode?: ShareWindowMode,
  sharedFromLocation: SharedFrom,
  moment?: MomentModel | null,
  theme?: Theme,
  auth?: WidgetAuthMode | null,
  time?: number,
  showIndex?: boolean | null,
  showComments?: boolean | null,
  showTranscript?: boolean | null,
  showProfile?: boolean | null,
  showTopicTags?: boolean | null,
  showHelp?: boolean | null,
  disableReactions?: boolean | null,
  disableComments?: boolean | null,
  disableTranscript?: boolean | null,
  disableIndex?: boolean | null,
  showEmbedIcon?: boolean | null,
  allowFullscreen?: boolean | null,
  allowShare?: boolean | null,
  allowDownload?: boolean | null,
  autoplay?: boolean | null
  customRedirectUrl?: string | null,
}
export class ShareVideoWindowState
  extends StoreNode {

  readonly nodeType = 'ShareVideoWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);

  readonly messageInput = input(this, {
    name: 'message',
    showStatusMessage: true,
  });

  @observable job: JobModel | null = null;
  @observable teamId: string | null = null;
  @observable moment: MomentModel | null = null;
  @observable bookmark: Bookmark | null = null;
  @observable sharedLink: string | null = null;
  @observable mode: ShareWindowMode = ShareWindowMode.Link;
  @observable sharedFromLocation: SharedFrom | null = null;
  @observable theme: Theme = Theme.Default;
  @observable showIndex: boolean | null = null;
  @observable showComments: boolean | null = null;
  @observable showTranscript: boolean | null = null;
  @observable showProfile: boolean | null = null;
  @observable showTopicTags: boolean | null = null;
  @observable showHelp: boolean | null = null;
  @observable disableReactions: boolean | null = null;
  @observable disableComments: boolean | null = null;
  @observable disableTranscript: boolean | null = null;
  @observable disableIndex: boolean | null = null;
  @observable showEmbedIcon: boolean | null = null;
  @observable allowFullscreen: boolean | null = null;
  @observable allowShare: boolean | null = null;
  @observable allowDownload: boolean | null = null;
  @observable auth: WidgetAuthMode | null = null;
  @observable autoplay: boolean | null = null;
  @observable time: number = 0;
  @observable customRedirectUrl: string | null = null;

  @computed
  get isWidgetMode(): boolean {
    return this.store.widget.isWidgetMode;
  }

  @computed
  get message(): string {
    return this.messageInput.value || '';
  }

  @computed
  get title(): string {
    return this.job?.title ?? this.bookmark?.jobStub.title ?? '';
  }
  @computed
  get titleWithMessage(): string {
    return this.title + (this.message && ' - ' + this.message)
  }

  @computed
  get modalTitle(): string {
    let location = null;

    switch (this.sharedFromLocation) {
      case SharedFrom.BookmarkPage: {
        location = 'Bookmark';
        break;
      }
      case SharedFrom.PlayerPage: {
        location = this.moment ? capitalCaseTransform(this.moment.momentKind) : 'Video'
        break;
      }
    }

    return `Share ${location}`;
  }

  @action
  async copyLink() {
    if (!this.sharedLink) return;

    await navigator.clipboard?.writeText(this.sharedLink);
    notifySuccess(this, 'Link Copied');
    this.handleShareDialogClose(SocialSharePlatforms.CopyLink);
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  async copyIFrameEmbedCode() {
    const jobId = this.bookmark?.jobId || this.job?.id;
    const momentId = this.moment?.id ?? this.bookmark?.momentId;
    if (!jobId)
      return;

    const code = getPlayerWidgetIFrameCode({
      jobId,
      momentId,
      time: (this.job?.isLiveStreamWaiting || this.job?.isLiveStreaming) ? 'none' : this.time,
      auth: this.auth,
      theme: this.theme,
      showIndex: this.showIndex,
      showComments: this.showComments,
      showTranscript: this.showTranscript,
      showProfile: this.showProfile,
      showTopicTags: this.showTopicTags,
      showHelp: this.showHelp,
      showEmbedIcon: this.showEmbedIcon,
      disableReactions: this.disableReactions,
      disableComments: this.disableComments,
      disableTranscript: this.disableTranscript,
      disableIndex: this.disableIndex,
      allowFullscreen: this.allowFullscreen,
      allowShare: this.allowShare,
      allowDownload: this.allowDownload,
      autoplay: this.autoplay,
      teamId: this.teamId,
      customRedirect: this.customRedirectUrl
    });

    if (!code)
      return;

    await navigator.clipboard?.writeText(code);
    notifySuccess(this, 'Embed code copied to clipboard!');
    this.handleShareDialogClose(SocialSharePlatforms.CopyEmbedCode);
  }

  @action
  private buildShareLink() {
    const jobId = this.bookmark?.jobId || this.job?.id;
    const momentId = this.moment?.id ?? this.bookmark?.momentId;

    if (!jobId)
      return;

    const { job } = this;
    const args: PlayerWidgetIFrameParams = {
      jobId,
      momentId,
      time: (job?.isLiveStreamWaiting || job?.isLiveStreaming) ? 'none' : this.time,
      auth: job?.isPublic ? WidgetAuthMode.None : null,
      theme: this.theme,
      showIndex: this.showIndex,
      showComments: this.showComments,
      showTranscript: this.showTranscript,
      disableComments: this.disableComments,
      disableTranscript: this.disableTranscript,
      disableIndex: this.disableIndex,
      showProfile: this.showProfile,
      showTopicTags: this.showTopicTags,
      showEmbedIcon: this.showEmbedIcon,
      showHelp: this.showHelp,
      allowFullscreen: this.allowFullscreen,
      allowShare: this.allowShare,
      allowDownload: this.allowDownload,
      autoplay: this.autoplay,
      teamId: this.teamId,
    };

    let url: URL | null = null;

    if (this.customRedirectUrl) {

      let sanitizedRedirectUrl = this.customRedirectUrl;
      if (!/^https?:\/\//i.test(sanitizedRedirectUrl)) {
        sanitizedRedirectUrl = 'https://' + sanitizedRedirectUrl;
      }

      try {
        url = new URL(sanitizedRedirectUrl);
        url = this.sanitizePathname(url);
        url = buildUrlSearchParams(args, url, jobId);
      } catch (ex) {
        notifyError(this, 'Error generating the share link. The provided URL is invalid.');
      }
    } else {
      url = getPlayerWidgetIFrameSrc(args);
    }

    if (url)
      this.sharedLink = url.toString();
  }

  private sanitizePathname(url: URL) {
    const pathname = url.pathname;

    if (pathname.endsWith('/')) {
      url.pathname = pathname.substring(0, pathname.lastIndexOf('/'));
    }

    return url;
  }

  @action
  handleShareDialogClose(socialWindow: SocialSharePlatforms) {
    switch (this.sharedFromLocation) {
      case SharedFrom.BookmarkPage: {
        this.emit('bookmark:share', {
          bookmarkId: this.bookmark?.id,
          bookmarkLinkId: this.bookmark?.list.id,
          momentId: this.bookmark?.momentId,
          momentName: this.bookmark?.actualMoment?.name,
          momentKind: this.bookmark?.actualMoment?.momentKind,
          sharedFrom: SharedFrom.BookmarkPage,
          sharedTo: socialWindow,
          job: this.job || this.bookmark?.actualJob,
          shareType: ShareType.Bookmark
        });
        break;
      }
      case SharedFrom.PlayerPage: {
        let shareType: ShareType;
        if (!this.moment) {
          shareType = ShareType.FullVideo;
        } else {
          shareType = this.moment.momentKind === 'Topic' ? ShareType.Topic : ShareType.Subtopic;
        }
        this.emit('video:share', {
          sharedFrom: SharedFrom.PlayerPage,
          sharedTo: socialWindow,
          job: this.job,
          momentId: this.moment?.id,
          momentName: this.moment?.name,
          momentKind: this.moment?.momentKind,
          shareType
        });
        break;
      }
    }
  }

  @action
  private reset() {
    this.job = null;
    this.teamId = null;
    this.bookmark = null;
    this.sharedLink = null;
    this.moment = null;
    this.mode = ShareWindowMode.Link;
    this.theme = Theme.Default;
    this.sharedFromLocation = null;
    this.customRedirectUrl = null;
    this.messageInput.clear();
  }

  @action
  open(params: ShareWindowProps) {
    this.dispatch('Overlays', 'openWindow', { name: 'ShareVideoWindow' });

    this.job = params.job ?? null;
    this.teamId = params.teamId ?? null;
    this.moment = params.moment ?? null;
    this.bookmark = params.bookmark ?? null;
    this.mode = params.mode ?? ShareWindowMode.Link;
    this.sharedFromLocation = params.sharedFromLocation;
    this.theme = params.theme ?? Theme.Default;
    this.auth = params.auth ?? null;
    this.time = params.time ?? 0;
    this.showIndex = params.showIndex ?? null;
    this.showComments = params.showComments ?? null;
    this.showTranscript = params.showTranscript ?? null;
    this.showProfile = params.showProfile ?? null;
    this.showTopicTags = params.showTopicTags ?? null;
    this.showHelp = params.showHelp ?? null;
    this.disableComments = params.disableComments ?? null;
    this.disableTranscript = params.disableTranscript ?? null;
    this.disableIndex = params.disableIndex ?? null;
    this.showEmbedIcon = params.showEmbedIcon ?? null;
    this.allowShare = params.allowShare ?? null;
    this.allowDownload = params.allowDownload ?? null;
    this.autoplay = params.autoplay ?? null;
    this.allowFullscreen = params.allowFullscreen ?? null;
    this.customRedirectUrl = params.customRedirectUrl ? decodeURIComponent(params.customRedirectUrl) : null;
    this.buildShareLink();
  }

  @action
  close() {
    this.reset();

    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}