import './loader-v1-1.scss';

import React from 'react';
import { ObjectLiteral } from '../../core';
import { CliprSpinner } from './cliprSpinner';
import { DotSpinner } from './dotSpinner';
import { LogoSpinner } from './logoSpinner';
import { useStore } from '../../store/storeHooks';

export type SpinnerType = 'dots3' | 'dots5';

type Props = {
  visible?: boolean;
  className?: string | ObjectLiteral;
  spinner?: SpinnerType;
  logo?: string | null;
  spinnerClassName?: string;
};

export const LoadMask = ({
  visible,
  className,
  spinner,
  spinnerClassName,
  logo,
}: Props) => {

  const store = useStore();
  const computedLogo = logo ?? store.uiService.logo;

  let spinnerElem = () => {
    switch (spinner) {
      case 'dots3':
        return <DotSpinner dots={3} className={spinnerClassName} />;
      case 'dots5':
        return <DotSpinner dots={5} className={spinnerClassName} />;
      default:
        if (!!computedLogo && computedLogo !== 'None')
          return <LogoSpinner logo={computedLogo} />;

        if (computedLogo === 'None')
          return <DotSpinner dots={5} className={spinnerClassName} />;

        return <CliprSpinner />;
    }
  };

  return (
    <div className={`load-mask ${className || ''}`} aria-hidden={!visible}>
      <div className="load-mask-bg" />
      {spinnerElem()}
    </div>
  );
};
