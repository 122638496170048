import { JobModel } from "../../../entities";
import { IAnalyticsStream, AnalyticsStreamParams, AnalyticsEventTypes, AnalyticsEventTriggerType } from "../analyticsSchema";
import { PlayerAnalyzerMetrics } from '../analyzers/playerAnalyzerSchema';
import { AnalyticsCurrentJobData, AnalyticsMainData, AnalyticsPlayerStateData, AnalyticsStream } from "./analyticsStream";

export type AnalyticsPlayerMetricsStreamParams = {
  triggerType: AnalyticsEventTriggerType;
  metrics: PlayerAnalyzerMetrics;
  job: JobModel;
}

export type AnalyticsPlayerMetricsStreamData = {
  triggerType: AnalyticsEventTriggerType;
} &
  PlayerAnalyzerMetrics &
  AnalyticsPlayerStateData &
  AnalyticsCurrentJobData &
  AnalyticsMainData;

export class AnalyticsPlayerMetricsStream
  extends AnalyticsStream
  implements IAnalyticsStream<keyof AnalyticsStreamParams>{

  static streamName = AnalyticsEventTypes.PlayerMetrics;

  exchangeData(data: AnalyticsPlayerMetricsStreamParams): AnalyticsPlayerMetricsStreamData | null {

    return {
      ...super.playerData,
      ...super.currentJobData(data.job),
      ...super.mainData,
      ...super.viewContextData,
      ...data.metrics,
      triggerType: data.triggerType
    };
  }
}