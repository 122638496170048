import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { Config } from '../../config';
import { AuthFlowName } from '../auth/authFlowSchema';
import { consumeSession, isSessionStorageSupported, setSession } from '../storage/sessionStorageHelpers';
import { Result } from '../../core';
import { Error } from '../../core/error';
import { getRelativeUrl } from './routingUtils';
import { IRouteStorage } from '../../routes/routeSchema';

const ServiceConfig = Config.routing;
const ModuleConfig = ServiceConfig.redirect;

const LastPrivatePathKey = ModuleConfig.lastPrivatePathKey;
const AuthFlowKey = ModuleConfig.authFlowKey;

export class StorageManager
  extends StoreNode
  implements IRouteStorage {

  constructor(store: Store) {
    super(store);
    this.load();
  }

  authFlow: AuthFlowName | null = null;
  lastPrivatePath: string | null = null;

  prepareRedirectUrl(path: string): Result<string> {

    const origin = window.location.origin;

    let reqUrl: URL;
    try {
      reqUrl = new URL(path, origin);
    } catch (err) {
      return [null, new Error('RoutingError', `The path you have provided is invalid.`)];
    }

    const { searchParams } = reqUrl;
    const { authFlow, lastPrivatePath } = this;
    if (authFlow)
      searchParams.append(AuthFlowKey, authFlow);
    if (lastPrivatePath)
      searchParams.append(LastPrivatePathKey, lastPrivatePath);

    if (reqUrl.href.length > ServiceConfig.maxUrlLength &&
      !isSessionStorageSupported()) {
      // storage could not be stored neither in the URL nor in session storage
      return [null, new Error('RoutingError', `Unable to prepare the state for the redirect. URL exceeded maximum length and session storage is not supported.`)];
    }

    const outPath = getRelativeUrl(reqUrl);
    return [outPath];
  }

  setAuthFlow(authFlow: AuthFlowName) {
    this.authFlow = authFlow;
    this.sync();
  }

  clearAuthFlow() {
    this.authFlow = null;
    this.sync();
  }

  setLastPrivatePath(path: string) {
    this.lastPrivatePath = path;
    this.sync();
  }

  clearLastPrivateRoute(path: string) {
    this.lastPrivatePath = null;
    this.sync();
  }

  clear() {
    this.authFlow = null;
    this.lastPrivatePath = null;
    this.sync();
  }

  private sync() {
    this.saveValue(AuthFlowKey, this.authFlow);
    this.saveValue(LastPrivatePathKey, this.lastPrivatePath);
  }

  private load(): void {

    const location = window.location;
    const queryParams = new URLSearchParams(location.search);

    this.authFlow =
      this.loadValue(queryParams, AuthFlowKey) as AuthFlowName;

    this.lastPrivatePath =
      this.loadValue(queryParams, LastPrivatePathKey);
  }

  private loadValue(queryParams: URLSearchParams, key: string): string | null {
    // first try to get from the url
    const urlValue = queryParams.get(key);
    if (urlValue)
      return urlValue;

    // if not, try from storage
    const storageValue = consumeSession(getSessionStorageKey(key));
    if (storageValue)
      return storageValue;

    return null;
  }

  private saveValue(key: string, value: string | null): void {
    setSession(getSessionStorageKey(key), value);
  }
}

function getSessionStorageKey(key: string) {
  return ServiceConfig.storageKeyPrefix + key;
}