import React, { CSSProperties } from 'react';
import { observer } from 'mobx-react-lite';
import { IPlayer } from './playerSchema';
import { PodcastPlaceholderSvg } from '../svg';

type Props = {
  model: IPlayer
}

export const PlayerPoster = observer(({
  model
}: Props) => {

  const { job } = model;
  const url = job?.posterURL;

  const styleProps: CSSProperties = {
    'backgroundImage': url ? `url('${url}')` : undefined
  }

  return (
    <div className="player-poster"
      aria-hidden={!model.showPoster}
      style={styleProps}>
      {!url && job?.media?.basic && <PodcastPlaceholderSvg className='podcast-placeholder-size' />}
    </div>
  );
});