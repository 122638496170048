import { action, IReactionOptions, reaction } from 'mobx';
import { v4 as uuid } from 'uuid';
import { Store } from './store';
import { createMessage, MessageHandler } from './message';
import { BindingPropValue, PropChangeListener, PropManager } from './propManager';
import { StoreNodeProxy } from './storeNodeProxy';
import { DebugContext } from '../core/debug';
import pick from 'lodash/pick';

export interface IStoreNode {
  listen(listener: MessageHandler): void;
  unlisten(listener: MessageHandler): void;

  invoke(type: string, payload?: any): void | Promise<void>;
}

type PropNames<TNode extends StoreNode> = keyof TNode['propManager']['props'] & string;
type PropValue<
  T extends PropNames<TNode>,
  TNode extends StoreNode> = TNode['propManager']['props'][T];

export interface StoreNode {
  /** Set to true if the Entity itself has been generated as a Mock. */
  _isMock?: boolean;
  _mocker?: any;
  _debugger?: DebugContext
}

/** Base class for all objects that are part of the store tree. */
// eslint-disable-next-line  no-redeclare
export class StoreNode {

  constructor(store: Store, props?: any) {
    this.store = store || null;
    this.proxy = new StoreNodeProxy<StoreNode>(this, store);

    this.props = props;
    this.propManager = new PropManager(props, this);

    if (process.env.NODE_ENV !== 'production') {
      this._isMock = false;
      this._mocker = null;
      this._debugger = {
        color: '#999',
        label: () => this.nodeType,
        id: () => this.id
      }
    }
  }

  nodeTags: string[] = [];

  protected readonly proxy: StoreNodeProxy<StoreNode>;

  readonly store: Store;
  readonly id: string = uuid();
  readonly nodeType: string = 'Node';

  // @ts-ignore
  readonly props: this['propManager']['props'];
  readonly propManager: PropManager;

  protected get resolvedProps() {
    return this.propManager.resolvedProps;
  }

  @action
  setProp<T extends keyof this['propManager']['props'] & string>(
    name: T,
    value: BindingPropValue<this['propManager']['props']>) {
    this.propManager.set(name, value);
    return this;
  }

  protected getProp<T extends PropNames<this>>(
    name: T,
    fallback?: PropValue<T, this>) {
    return this.propManager.get(name, fallback);
  }

  protected getResolvedProp<T extends PropNames<this>>(
    name: T,
    fallback?: BindingPropValue<this['propManager']['props'][T], [this]>) {
    return this.propManager.getResolved(name, fallback);
  }

  // #region emit
  // -------
  async emit(type: string, payload?: any): Promise<void> {
    await this.proxy.emit(type, payload);
  }
  emitHandler(type: string, payload?: any) {
    return (evt: any) => this.emit(type, payload);
  }
  // #endregion

  // #region broadcast
  // -------
  broadcast(type: string, payload?: any) {
    this.proxy.broadcast(type, payload);
    return this;
  }
  broadcastHandler(type: string, payload?: any) {
    return (evt: any) => this.broadcastHandler(type, payload);
  }
  // #endregion

  // #region dispatch
  // -------
  dispatch(type: string, payload?: any): this;
  dispatch(target: string, type: string, payload?: any): this;

  dispatch(...args: [string, any?] | [string, string, any?]) {

    if (
      typeof args[0] === 'string' &&
      typeof args[1] === 'string') {

      this.proxy.dispatch(args[0], args[1], args[2]);
    } else {
      this.proxy.dispatch(null, args[0], args[1]);
    }

    return this;
  }

  dispatchHandler(type: string, payload?: any) {
    return (evt: any) => this.dispatch(type, payload);
  }
  // #endregion

  listen(listener: MessageHandler<this>) {
    this.proxy.listen(listener as any);
  }

  unlisten(listener: MessageHandler<this>) {
    this.proxy.unlisten(listener as any);
  }

  invoke(type: string, payload?: any, source?: any): void { }
  invokeHandler(type: string, payload?: any) {
    return (evt: any) => this.invoke(type, payload, evt);
  }

  protected receive(handler: MessageHandler, filter: string[] = []) {
    this.proxy.receive(handler, filter);
  }

  isDisposed: boolean = false;
  dispose() {
    this.isDisposed = true;
  }

  protected onPropChange(propName: string, listener: PropChangeListener) {
    this.propManager.onChange(propName, listener);
  }

  protected onPropDiscard(propName: string, listener: PropChangeListener) {
    this.propManager.onDiscard(propName, listener);
  }

  protected autoListen<T extends PropNames<this>>(
    propName: string,
    listener: MessageHandler<PropValue<T, this>>,
    emitUpdates = true) {

    this.onPropChange(propName, (node: PropValue<T, this>, prevNode: PropValue<T, this>) => {

      if (!((node as any) instanceof StoreNode)) {
        if (node)
          console.warn(`'onPropChange' handler invoked by 'autoListener' failed because the resulting node is not a StoreNode.`);
        return;
      }
      node.listen(listener);

      if (emitUpdates && typeof listener === 'function') {
        const msg = createMessage(this, 'emit', 'propChange', {
          previousValue: prevNode,
          value: node
        });

        listener(msg);
      }
    });

    this.onPropDiscard(propName, (node: PropValue<T, this>, nextNode: PropValue<T, this>) => {

      if (!((node as any) instanceof StoreNode)) {
        if (node)
          console.warn(`'onPropDiscard' handler invoked by 'autoListener' failed because the resulting node is not a StoreNode.`);
        return;
      }
      node.unlisten(listener);

      if (emitUpdates && typeof listener === 'function') {
        const msg = createMessage(this, 'emit', 'propDiscard', {
          nextValue: nextNode,
          value: node
        });

        listener(msg);
      }
    });
  }


  protected onFieldChange(propName: string, listener: PropChangeListener) {

    const reactionOpts: IReactionOptions = {
      fireImmediately: true
    }

    reaction(() => (this as any)[propName],
      (value, prevValue) =>
        listener(value, prevValue),
      reactionOpts);

    return this;
  }

  // #region Debug members
  _getState?: (() => Record<string, any>);
  _logState?: (() => void);

  // #endregion
}

if (process.env.NODE_ENV !== 'production') {
  StoreNode.prototype._getState = function() {
    return pick(this, Object.keys(this));
  }

  StoreNode.prototype._logState = function() {
    console.log(this._getState?.());
  }
}