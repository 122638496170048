import '../jobs/jobs.scss';

import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import { paramCase } from 'change-case';

import { PlaySVGIcon } from '../svg';
import {
  IngestingSVGIcon,
  TextInputErrorSVGIcon,
  TextInputWarningSVGIcon,
  LiveSVGIcon
} from '../svg/svg';
import { Thumb } from '../media/thumb';
import { Link } from '../layout';
import { JobCatalogSource, JobModel } from '../../entities';
import { Routes } from '../../routes';
import { Maybe, ObjectLiteral } from '../../core';
import { DotSpinner } from '../loader';
import { MenuButton } from '../menu';
import { JobMenuDropdownOptions } from '.';
import { LibraryTag } from './libraryTag';
import { JobTitle } from './jobTitle';
import { AiTag } from './aiTag';

type Props = {
  model: JobModel;
  teamId?: string;
  className?: string | ObjectLiteral;
  source: JobCatalogSource;
};

export const JobTableRow = observer(
  ({ model, teamId, source, className }: Props) => {
    const isNew = model.createdAtDate.diffNow('hours').as('hours') > -6; // diff will be negative
    const linkRoute: Maybe<string> =
      model.isPublished &&
        model.isMediaAccessible &&
        model.isPublished
        ? Routes.userVideo(model.id)
        : null;

    className = classNames(
      'job-table-row',
      paramCase(model.status || ''),
      paramCase(model.videoType || ''),
      {
        new: isNew,
      }
    );
  
    return (
      <tr
        className={className}
        data-job-id={model.id}
        data-job-status={model.testStatus}>
        <td
          className={
            'thumb-col ' +
            (source.getSortFieldValue('title') !== 'none' && 'active')
          }>
          <Link to={linkRoute!} className="link fill" title={model.title}>
            <RowPreview model={model} isNew={isNew} />
          </Link>
        </td>

        <td
          className={
            'title-col ' +
            (source.getSortFieldValue('title') !== 'none' && 'active')
          }>
          <Link to={linkRoute!} className="link title" title={model.title}>
            <JobTitle
              title={model.title || 'Title not set'}
              isAudioSource={model.isAudioSource}></JobTitle>
          </Link>
        </td>

        <td
          className="ai-processing-col">
          <AiTag
            className="ai-tag-table"
            model={model}
            teamId={teamId} />
        </td>

        <td
          className={
            'duration-col ' +
            (source.getSortFieldValue('durationInMs') !== 'none' &&
              'active')
          }>
          <Link to={linkRoute!} className="link fill">
            <span>{model.durationToTimeCode('card')}</span>
          </Link>
        </td>

        <td
          className={
            'upload-col ' +
            (source.getSortFieldValue('createdAt') !== 'none' && 'active')
          }>
          <Link
            to={linkRoute!}
            className="link fill"
            title={model.createdAtLabel}>
            <span>
              {model.createdAtDate.toLocaleString(DateTime.DATE_MED)}{' '}
              {model.createdAtDate.toLocaleString(DateTime.TIME_SIMPLE)}
            </span>
          </Link>
        </td>

        <td className="options-col">
          <span>
            <LibraryTag model={model} />
          </span>

          {!model.isMediaProcessing && model.hasPermission('UserViewJobMenu') && (
            <MenuButton layout="horizontal">
              <JobMenuDropdownOptions model={model} teamId={teamId} />
            </MenuButton>
          )}
        </td>
      </tr>
    );
  }
);

const RowPreview = observer(
  ({ model, isNew }: { model: JobModel; isNew: boolean }) => {
    let content: Maybe<ReactNode>;
    const isLive = model.isLiveStreaming || model.isLiveStreamWaiting;

    const cx = classNames('card-overlay', {
      done: model.isMediaDone && model.isPublished,
      processing: model.isMediaProcessing,
      live: isLive,
      failed: model.isMediaFailed,
      'unpublish': !model.isPublished
    });

    if (model.isMediaProcessing) {
      content = (
        <>
          <IngestingSVGIcon />
          <DotSpinner className="small" />
        </>
      );
    }

    if (model.isMediaFailed) {
      content = <TextInputErrorSVGIcon />;
    }

    if (
      model.isMediaAccessible) {
      content = (
        <div className="play-icon preview-icon">
          <PlaySVGIcon />
        </div>
      );
    }

    if (!model.isPublished) {
      content = (
        <div className="card-overlay unpublish">
          <TextInputWarningSVGIcon />
          <p>Video is no longer available</p>
        </div>
      );
    }

    if (model.isLiveEnded) {
      content = (
        <>
          <TextInputWarningSVGIcon />
          <p>Live ended. Waiting for manual ingest</p>
        </>
      );
    }

    const thumbClass = classNames('card-thumb dock', {
      'live-card-placeholder': !model.posterURL && isLive && !model.store.uiService.logo
    });

    return (
      <div className="row-preview">
        <Thumb source={model} className={thumbClass} />
        <div className={cx}>{content}</div>
        {isNew && <div className="job-card-new-tag">New</div>}
        {model.isLiveStreaming &&
          <div className="job-card-live-tag">
            <LiveSVGIcon />
            <span>LIVE NOW</span>
          </div>}
        {model.isLiveStreamWaiting &&
          <div className="job-card-live-tag">
            <LiveSVGIcon />
            <span>LIVE</span>
          </div>}
      </div>
    );
  }
);
