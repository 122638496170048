import { action, computed, makeObservable, observable } from 'mobx';

import { Store } from '../../store/store';
import { StoreNode } from '../../store';

import {
  AsyncResult,
  Result
} from '../../core';
import {
  ResetPasswordInput,
  LoginInput,
  OnboardInput,
  RegisterInput,
  ResendOnboardLinkInput,
  SocialLoginProvider,
  AuthContext,
} from './authSchema';
import {
  ResendOnboardLinkReply
} from './authReply';
import {
  AuthPermit
} from './authPermit';
import { AuthStateManager } from './authStateManager';
import { UserProfile } from '../../entities';
import { AuthServiceMocker } from './_mock';
import { Config } from '../../config';
import { PrivateRouteFlow, PrivateRouteFlowParams } from './flows/privateRouteFlow';
import { AuthFlowName, AuthFlowResponse, AuthFlowResponseType, IAuthFlow } from './authFlowSchema';
import { LoginFlow } from './flows/loginFlow';
import { RefreshPermitFlow } from './flows/refreshPermitFlow';
import { OptionalLoginWidgetFlow, OptionalLoginWidgetFlowParams } from './flows/optionalLoginWidgetFlow';
import { Error } from '../../core/error';
import { RouteContext } from '../../routes/routeContext';
import { ProxyLoginFlow, ProxyLoginFlowParams } from './flows/proxyLoginFlow';
import { ProxyAuthorizeServerFlow, ProxyAuthorizeServerFlowParams } from './flows/proxyAuthorizeServerFlow';
import { ProxyDeauthorizeServerFlow, ProxyDeauthorizeServerFlowParams } from './flows/proxyDeauthorizeServerFlow';
import { InlineProxyLogoutFlowParams } from './flows/inlineProxyLogoutFlow';
import { ProxyFlowName } from '../proxy/proxySchema';
import { LogoutFlow } from './flows/logoutFlow';
import { PublicRouteFlowParams } from './flows/publicRouteFlow';
import { PublicWidgetFlowParams } from './flows/publicWidgetFlow';
import { RequiredLoginWidgetFlowParams } from './flows/requiredLoginWidgetFlow';
import { ExternalLoginWidgetFlowParams } from './flows/externalLoginWidgetFlow';
import { ProxyAuthorizeLibraryServerFlow, ProxyAuthorizeLibraryServerFlowParams } from './flows/proxyAuthorizeLibraryServerFlow';
import { ProxyAuthorizeLibraryFlowParams } from './flows/proxyAuthorizeLibraryFlow';
import { LibraryName } from '../libraries/librarySchema';
import { AuthorizeLibraryFlow } from './flows/authorizeLibraryFlow';
import { AuthRouteFlowParams } from './flows/authRouteFlow';
import { Routes } from '../../routes';

import { INIT_DEBUGGER, TRACE } from '../../core/debug/debugMacros';
import { WidgetAuthMode } from '../widget';
import { ClientMode } from '../../kernel/kernelSchema';

export interface AuthService {
  _mocker: AuthServiceMocker
}

// eslint-disable-next-line  no-redeclare
export class AuthService
  extends StoreNode {

  readonly nodeType = 'AuthService';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    INIT_DEBUGGER(this);
  }

  readonly localState =
    new AuthStateManager(this.store);

  @computed get context(): AuthContext | null {
    return this.localState.context;
  }

  @computed get permit(): AuthPermit | null {
    return this.context?.permit ?? null;
  }

  @computed get userProfile(): UserProfile | null {
    return this.context?.profile || null;
  }

  @computed get isOnboarded(): boolean {
    return this.userProfile?.showOnboarding === false;
  }

  @computed get isAuthorized() {
    return !!this.userProfile;
  };

  readonly profileStorageKeys = observable.set<string>();

  @observable initialFlow: IAuthFlow | null = null;
  @computed get initialFlowName() {
    return this.initialFlow?.flowName ?? null;
  }

  @observable currentFlow: IAuthFlow | null = null;
  @computed get currentFlowName() {
    return this.currentFlow?.flowName ?? null;
  }

  @computed get shouldWaitForNextContext(): boolean {
    const { currentFlow } = this;
    if (currentFlow)
      return !currentFlow.isLibraryFlow;

    return !this.initialFlow;
  }

  @computed get canRunFlow(): boolean {
    return !this.currentFlow;
  }

  @action
  init() { }

  async resendOnboardLink(input: ResendOnboardLinkInput)
    : AsyncResult<ResendOnboardLinkReply, any> {

    return [new ResendOnboardLinkReply({
      destination: input.username
    })];
  }

  @action
  clearProfileStorage() {
    const { storage } = this.store;
    const keys = [...this.profileStorageKeys];
    for (let key of keys)
      storage.removeLocal(key);
  }

  /**
   * Sends a signal to the service that a previous `ensureIdToken` call returned a valid token
   * but the client (probably the `ApiService` still wasn't able to use the token for authorization).
   * This will trigger an invalidation of the current authorization state and
   * a redirect to the login page.
   */
  notifyTokenRejected() {
    TRACE(this, `notifyTokenRejected()`);

    const { kernel } = this.store;
    const { routingService } = this.store;

    if (kernel.clientMode === ClientMode.Widget) {
      const redirUrl = routingService.lastWidgetRoute?.relativeUrl ?? null;
      routingService.goTo(redirUrl!); // TODO: add error default
    } else {
      this.runLogoutFlow();
    }
  }



  /**
   * Adds a key to the `profileStorageKeys` repository such that the service knows
   * to delete the key when it is invalidating the authorization.
   */
  @action
  registerProfileStorageKey(key: string) {
    this.profileStorageKeys.add(key);
  }

  consumeForceConnectionFromStorage(): string | null {
    const forceConn = this.store.storage.consumeSession(Config.auth.storageForceConnectionKey);
    if (!forceConn)
      return null;

    const configConns = Config.auth.connections;
    const forceConnKey = Object
      .keys(configConns)
      .find(key => key.toLocaleLowerCase() === forceConn.toLocaleLowerCase());

    if (!forceConnKey)
      return null;

    return forceConnKey;
  }

  @action
  private async executeFlow<T extends IAuthFlow>(flow: T, runCallback: (flow: T) => AsyncResult<AuthFlowResponse>): AsyncResult<AuthFlowResponse> {

    TRACE(this, `executeFlow()`, flow);

    if (this.currentFlow) {
      return [null, new Error('AuthError', `There is another flow which is already running.`)];

    }
    const injector = flow.routeContext?.injector;
    const disabledFlows = injector?.disabledAuthFlows ?? new Set();

    if (disabledFlows.has(flow.flowName))
      return [null, new Error('AuthError', `This flow is disabled for this resource`)];

    if (!this.initialFlow)
      this.initialFlow = flow;

    this.setOrClearCurrentFlow(flow);
    const res = await runCallback(flow);
    this.setOrClearCurrentFlow(null);

    TRACE(this, `Auth flow execution completed`, res);

    return res;
  }

  @action
  private tryExecuteFlowSync<T extends IAuthFlow>(flow: T, runCallback: (flow: T) => Result<AuthFlowResponse>): Result<AuthFlowResponse> {

    TRACE(this, `tryExecuteFlowSync()`, flow);

    if (this.currentFlow)
      return [null, new Error('AuthError', `There is another flow which is already running.`)];

    const injector = flow.routeContext?.injector;
    const disabledFlows = injector?.disabledAuthFlows ?? new Set();

    if (disabledFlows.has(flow.flowName))
      return [null, new Error('AuthError', `This flow is disabled for this resource`)];

    if (!this.initialFlow)
      this.initialFlow = flow;

    this.setOrClearCurrentFlow(flow);
    const res = runCallback(flow);
    this.setOrClearCurrentFlow(null);

    TRACE(this, `Sync auth flow execution completed`, res);

    return res;
  }

  @action
  private setOrClearCurrentFlow(flow: IAuthFlow | null) {
    this.currentFlow = flow;
  }

  async runExternalLoginWidgetFlow(params: ExternalLoginWidgetFlowParams): AsyncResult<AuthFlowResponse> {
    const { ExternalLoginWidgetFlow } = await import('./flows/externalLoginWidgetFlow');
    return this.executeFlow(
      new ExternalLoginWidgetFlow(this.store, params),
      flow => flow.run());
  }

  async runPublicWidgetFlow(params: PublicWidgetFlowParams): AsyncResult<AuthFlowResponse> {
    const { PublicWidgetFlow } = await import('./flows/publicWidgetFlow');
    return this.executeFlow(
      new PublicWidgetFlow(this.store, params),
      flow => flow.run());
  }

  async runOptionalLoginWidgetFlow(params: OptionalLoginWidgetFlowParams): AsyncResult<AuthFlowResponse> {
    return this.executeFlow(
      new OptionalLoginWidgetFlow(this.store, params),
      flow => flow.run());
  }

  async runRequiredlLoginWidgetFlow(params: RequiredLoginWidgetFlowParams): AsyncResult<AuthFlowResponse> {
    const { RequiredLoginWidgetFlow } = await import('./flows/requiredLoginWidgetFlow');
    return this.executeFlow(
      new RequiredLoginWidgetFlow(this.store, params),
      flow => flow.run());
  }

  // #region Application route flows

  async runPublicRouteFlow(params: PublicRouteFlowParams): AsyncResult<AuthFlowResponse> {
    const { PublicRouteFlow } = await import('./flows/publicRouteFlow');
    return this.executeFlow(
      new PublicRouteFlow(this.store, params),
      flow => flow.run());
  }

  async runPrivateRouteFlow(params: PrivateRouteFlowParams): AsyncResult<AuthFlowResponse> {
    return this.executeFlow(
      new PrivateRouteFlow(this.store, params),
      flow => flow.run());
  }

  tryRunPrivateRouteFlowSync(params: PrivateRouteFlowParams): Result<AuthFlowResponse> {
    return this.tryExecuteFlowSync(
      new PrivateRouteFlow(this.store, params),
      flow => flow.tryRunSync());
  }

  async runOnboardRouteFlow(params: PrivateRouteFlowParams): AsyncResult<AuthFlowResponse> {
    const { OnboardRouteFlow } = await import('./flows/onboardRouteFlow');
    return this.executeFlow(
      new OnboardRouteFlow(this.store, params),
      flow => flow.run());
  }


  async runAuthRouteFlow(params: AuthRouteFlowParams): AsyncResult<AuthFlowResponse> {
    const { AuthRouteFlow } = await import('./flows/authRouteFlow');
    return this.executeFlow(
      new AuthRouteFlow(this.store, params),
      flow => flow.run());
  }

  // #endregion

  // #region Application flows
  async runLoginFlow(input: LoginInput): AsyncResult<AuthFlowResponse> {
    const { LoginFlow } = await import('./flows/loginFlow');
    return this.executeFlow(
      new LoginFlow(this.store),
      flow => flow.run(input));
  }

  async runSocialLoginFlow(providerName: SocialLoginProvider): AsyncResult<AuthFlowResponse> {
    const { SocialLoginFlow } = await import('./flows/socialLoginFlow');
    return this.executeFlow(
      new SocialLoginFlow(this.store),
      flow => flow.run(providerName));
  }

  async runRegisterFlow(input: RegisterInput): AsyncResult<AuthFlowResponse> {
    const { RegisterFlow } = await import('./flows/registerFlow');
    return this.executeFlow(
      new RegisterFlow(this.store),
      flow => flow.run(input));
  }

  async runOnboardFlow(input: OnboardInput): AsyncResult<AuthFlowResponse> {
    const { OnboardFlow } = await import('./flows/onboardFlow');
    return this.executeFlow(
      new OnboardFlow(this.store),
      flow => flow.run(input));
  }

  async runResetPasswordFlow(input: ResetPasswordInput): AsyncResult<AuthFlowResponse> {
    const { ResetPasswordFlow } = await import('./flows/resetPasswordFlow');
    return this.executeFlow(
      new ResetPasswordFlow(this.store),
      flow => flow.run(input));
  }

  async runLogoutFlow(): AsyncResult<AuthFlowResponse> {
    const { LogoutFlow } = await import('./flows/logoutFlow');
    return this.executeFlow(
      new LogoutFlow(this.store),
      flow => flow.run());
  }
  // #endregion

  async runRefreshPermitFlow(): AsyncResult<AuthFlowResponse> {
    return this.executeFlow(
      new RefreshPermitFlow(this.store),
      flow => flow.run());
  }

  async runRefreshContextFlow(): AsyncResult<AuthFlowResponse> {
    const { RefreshContextFlow } = await import('./flows/refreshContextFlow');
    return this.executeFlow(
      new RefreshContextFlow(this.store),
      flow => flow.run());
  }

  async runProxyLoginFlow(params: ProxyLoginFlowParams): AsyncResult<AuthFlowResponse> {
    return this.executeFlow(
      new ProxyLoginFlow(this.store, params),
      flow => flow.run());
  }

  async runInlineProxyLogoutFlow(params: InlineProxyLogoutFlowParams): AsyncResult<AuthFlowResponse> {
    const { InlineProxyLogoutFlow } = await import('./flows/inlineProxyLogoutFlow');
    return this.executeFlow(
      new InlineProxyLogoutFlow(this.store, params),
      flow => flow.run());
  }

  async runInlineProxyLoginFlow(params: InlineProxyLogoutFlowParams): AsyncResult<AuthFlowResponse> {
    const { InlineProxyLoginFlow } = await import('./flows/inlineProxyLoginFlow');
    return this.executeFlow(
      new InlineProxyLoginFlow(this.store, params),
      flow => flow.run());
  }

  async runProxyAuthorizeServerFlow(params: ProxyAuthorizeServerFlowParams): AsyncResult<AuthFlowResponse> {
    const { ProxyAuthorizeServerFlow } = await import('./flows/proxyAuthorizeServerFlow');
    return this.executeFlow(
      new ProxyAuthorizeServerFlow(this.store, params),
      flow => flow.run());
  }

  async runProxyAuthorizeLibraryServerFlow(params: ProxyAuthorizeLibraryServerFlowParams): AsyncResult<AuthFlowResponse> {
    const { ProxyAuthorizeLibraryServerFlow } = await import('./flows/proxyAuthorizeLibraryServerFlow');
    return this.executeFlow(
      new ProxyAuthorizeLibraryServerFlow(this.store, params),
      flow => flow.run());
  }

  async runProxyDeauthorizeServerFlow(params: ProxyDeauthorizeServerFlowParams): AsyncResult<AuthFlowResponse> {
    const { ProxyDeauthorizeServerFlow } = await import('./flows/proxyDeauthorizeServerFlow');
    return this.executeFlow(
      new ProxyDeauthorizeServerFlow(this.store, params),
      flow => flow.run());
  }

  async runProxyAuthorizeLibraryFlow(params: ProxyAuthorizeLibraryFlowParams): AsyncResult<AuthFlowResponse> {
    const { ProxyAuthorizeLibraryFlow } = await import('./flows/proxyAuthorizeLibraryFlow');
    return this.executeFlow(
      new ProxyAuthorizeLibraryFlow(this.store, params),
      flow => flow.run());
  }


  async handleOAuthLogout(routeContext: RouteContext): AsyncResult<AuthFlowResponse> {

    const { routingService, proxyService } = this.store;
    const routingStorage = routingService.storage;

    if (proxyService.isProxyServerMode) {

      if (proxyService.windowFlowName === ProxyFlowName.Deauthorize) {

        TRACE(this, `Restoring ProxyDeauthorizeServerFlow`);

        const [flowRes, flowErr] = await this.executeFlow(
          new ProxyDeauthorizeServerFlow(this.store, { routeContext }),
          flow => flow.restoreAfterRedirect());

        TRACE(this, `ProxyDeauthorizeServerFlow restored with result`, [flowRes, flowErr]);

        if (flowErr)
          return [null, flowErr];
        return [flowRes!];
      }

      return [null, new Error('InternalError', `Attempted to run a OAuth proxy callback flow on a proxy server window which was not configured for that flow.`)];
    }

    switch (routingStorage.authFlow) {
      case AuthFlowName.Logout: {

        TRACE(this, `Restoring LogoutFlow`);

        const [flowRes, flowErr] = await this.executeFlow(
          new LogoutFlow(this.store, { routeContext }),
          flow => flow.restoreAfterRedirect());

        TRACE(this, `LogoutFlow restored with result`, [flowRes, flowErr]);

        if (flowErr)
          return [null, flowErr];
        return [flowRes!];
      }
    }

    TRACE(this, `The OAuthLogout handler was invoked without a valid flow. The LogoutFlow will be restored as a default.`);

    const [flowRes, flowErr] = await this.executeFlow(
      new LogoutFlow(this.store, { routeContext }),
      flow => flow.restoreAfterRedirect());

    TRACE(this, `LogoutFlow restored with result`, [flowRes, flowErr]);

    if (flowErr)
      return [null, flowErr];
    return [flowRes!];
  }

  async handleOAuthCallback(routeContext: RouteContext): AsyncResult<AuthFlowResponse> {

    const { routingService, proxyService } = this.store;
    const routingStorage = routingService.storage;

    if (proxyService.isProxyServerMode) {

      if (proxyService.windowFlowName === ProxyFlowName.Authorize) {

        TRACE(this, `Restoring ProxyAuthorizeServerFlow`);

        const [res, err] = await this.executeFlow(
          new ProxyAuthorizeServerFlow(this.store, { routeContext }),
          flow => flow.restoreAfterRedirect());

        TRACE(this, `ProxyAuthorizeServerFlow restored with result`, [res, err]);

        if (err) {
          // TODO: handle error
          console.error('login restore failed');
          return [null, err];
        }

        return [res!];
      }

      return [null, new Error('InternalError', `Attempted to run a OAuth proxy callback flow on a proxy server window which was not configured for that flow.`)];
    }


    switch (routingStorage.authFlow) {
      default:
      case AuthFlowName.Login: {

        TRACE(this, `Restoring LoginFlow`);

        const [res, err] = await this.executeFlow(
          new LoginFlow(this.store),
          flow => flow.restoreAfterRedirect());

        TRACE(this, `LoginFlow restored with result`, [res, err]);

        if (err) {
          // TODO: handle error
          console.error('login restore failed');
          return [null, err];
        }

        return [res!];
      }
    }
  }

  async handleOAuthLibraryCallback(routeContext: RouteContext, libraryName: LibraryName): AsyncResult<AuthFlowResponse> {

    // we don't have access to route state when redirecting through external libraries
    // so we rely on window information
    const { proxyService } = this.store;
    if (proxyService.isProxyServerMode) {

      if (proxyService.windowFlowName === ProxyFlowName.AuthorizeLibrary) {
        // we're in proxy server mode
        // since we might end up here not knowing from the start that we're in proxy server mode,
        // just enter that mode for safety
        this.store.proxyService.activateProxyServerMode();

        TRACE(this, `Restoring ProxyAuthorizeLibraryServerFlow`);

        const [res, err] = await this.executeFlow(
          new ProxyAuthorizeLibraryServerFlow(this.store, {
            routeContext,
            libraryName
          }),
          flow => flow.restoreAfterRedirect());

        TRACE(this, `ProxyAuthorizeLibraryServerFlow restored with result`, [res, err]);

        if (err)
          return [null, err];
        return [res!];

      }

      return [null, new Error('InternalError', `Attempted to run a OAuth proxy callback flow on a proxy server window which was not configured for that flow.`)];

    } else {

      // we're in regular application mode

      TRACE(this, `Restoring AuthorizeLibraryFlow`);

      const [res, err] = await this.executeFlow(
        new AuthorizeLibraryFlow(this.store, {
          routeContext,
          libraryName
        }),
        flow => flow.restoreAfterRedirect());

      TRACE(this, `AuthorizeLibraryFlow restored with result`, [res, err]);

      if (err) {
        // TODO: handle error
        console.error('login restore failed');
        return [null, err];
      }

      return [res!];
    }
  }

  async handleWidgetRoute(routeContext: RouteContext): AsyncResult<AuthFlowResponse> {

    const { widgetService } = this.store;
    const { widgetParams } = widgetService;

    const legacyAuthModeParam = widgetParams.authMode;
    const autoLoginParam = widgetParams.autoLogin;

    switch (legacyAuthModeParam) {
      case WidgetAuthMode.None:
        // convert to public flow
        return this.runPublicWidgetFlow({
          routeContext
        });

      case WidgetAuthMode.Wait:
        // convert to external token
        return this.runExternalLoginWidgetFlow({
          routeContext
        });

      case null:
      default:
        // convert to optional login
        return this.runOptionalLoginWidgetFlow({
          routeContext,
          autoLogin: autoLoginParam
        });
    }

    // eslint-disable-next-line
    return [null, new Error('InternalError', `Unknown flow`)];
  }

  /**
   * Utility for redirecting programatically based on the response of an AuthFlow.
   * You should not use this but rather use the AuthFlowResponseInterpreter in React.
   * Use this only when the React option is not possible, as it is the case with
   * expired tokens during an API call.
   */
  executeFlowResponse(response: AuthFlowResponse): Result<boolean> {

    const { routingService } = this.store;

    switch (response.responseType) {
      case AuthFlowResponseType.RedirectToLoginPage:
        routingService.goTo(Routes.login());
        break;

      case AuthFlowResponseType.RedirectToLastWidgetRoute:
        const redirUrl = routingService.lastWidgetRoute?.relativeUrl ?? null;
        if (!redirUrl)
          return [null, new Error('InternalError', `Invalid redirect state.`)];

        routingService.goTo(redirUrl);
        break;
    }

    return [true];
  }
}