import { action, makeObservable, observable } from 'mobx';

import { CatalogState } from '../../components/layout/catalogState';
import { DeleteTeamWindowState } from '../../components/teams/deleteTeamWindowState';
import { TeamProductWindowState } from '../../components/teams/teamProductWindowState';
import { AsyncResult, Result } from '../../core';
import { Error, pageError } from '../../core/error';
import { Team } from '../../entities';
import { AdminTeamsCatalogSource } from '../../entities/adminTeamsCatalogSource';
import { Message, StoreNode } from '../../store';
import { Store } from '../../store/store';

export type AdminTeamsPageParams = {
};

/** Controller for AdminTeamsPage. */
export class AdminTeamsPageState
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.teamCatalogSource = new AdminTeamsCatalogSource(this.store, {
      pageSize: 20
    });
    this.teamCatalog = new CatalogState(this.store, {
      syncStatus: () => this.teamCatalogSource.syncStatus,
      isEndOfList: () => this.teamCatalogSource.isEndOfList
    });
    this.teamCatalog.listen((msg) => {
      switch (msg.type) {
        case 'scrollToBottom':
        case 'loadMore':
          this.teamCatalogSource.fetchMore();
          break;
      }
    });
  }

  @observable isLoading: boolean = false;
  @observable error: Error | null = null;

  readonly teamCatalogSource: AdminTeamsCatalogSource;
  readonly teamCatalog = new CatalogState(this.store);

  @action
  async pageMounted(params: AdminTeamsPageParams) {
    const { deleteTeamWindow } = this.store;

    deleteTeamWindow.listen(
      this.deleteTeamWindowListener)

    this.reset();
    this.isLoading = true;

    const requests: AsyncResult[] = [
      this.teamCatalogSource.fetch(),
      this.teamCatalogSource.fetchTeamNamespaces()
    ];

    const [[, err1], [, err2]]: Result[] = await Promise.all(requests);

    if (err1 || err2)
      return this.setError(err1 || err2);

    this.setLoaded();
  }

  private deleteTeamWindowListener = action((msg: Message<DeleteTeamWindowState>) => {
    switch (msg.type) {
      case 'team:deleted':
        this.refreshTeams();
        break;
    }
  });

  @action
  pageUnmounted() {
    this.reset();

    this.store.deleteTeamWindow.unlisten(
      this.deleteTeamWindowListener);
  }

  @action
  openTeamProductUpdate(team: Team) {
    const window = this.store.teamProductWindow;
    const listener = (msg: Message<TeamProductWindowState>) => {
      switch (msg.type) {
        case 'teamUpdated':
          this.refreshTeams();
          window.unlisten(listener);
          break;
        case 'close':
          window.unlisten(listener);
          break;
      }
    }
    window.listen(listener);
    window.open({ team })
  }

  // #region State helpers
  @action
  async refreshTeams() {
    const requests: AsyncResult[] = [
      this.teamCatalogSource.fetch(),
      this.teamCatalogSource.fetchTeamNamespaces()
    ];

    return await Promise.all(requests);
  }

  @action
  reset() {
    this.isLoading = false;
    this.error = null;
    this.teamCatalogSource.searchFilter = null;
    this.teamCatalogSource.searchBarModel.clear();
  }

  @action
  private setError(error?: Error) {
    if (!error)
      error = pageError();

    this.isLoading = false;
    this.error = error;
  }

  @action
  private setLoaded() {
    this.isLoading = false;
    this.error = null;
  }
  // #endregion
}