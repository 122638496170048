import { StoreNode } from '../../store';
import { RouteContext } from '../../routes/routeContext';
import { AsyncResult } from '../../core';
import { Permissions } from '../../services/auth/authFlowSchema';
import { Error } from '../../core/error';
import { TeamWidgetRouteParams } from './teamWidgetState';
import { IRouteInjector } from '../../routes/routeSchema';

export class TeamWidgetInjector
  extends StoreNode
  implements IRouteInjector {

  async fetchPermissions(routeContext: RouteContext<TeamWidgetRouteParams>): AsyncResult<Permissions, any> {
    const { TeamPermissionsSource } = await import('../../entities/teamPermissionsSource');
    
    const teamId: string | null = 
      routeContext.params?.teamId ?? 
      routeContext.searchParams.get('teamId') ?? // TODO: check if this is safe
      null;

    if (!teamId)
      return [null, new Error('InternalError', `Unable to get jobId from route params.`)];

    const source = new TeamPermissionsSource(this.store, {
      teamId
    });

    return source.fetchPermissions();
  }
}