export enum PlayerAdsAdapterMessageType {
  Mounted = 'Mounted',
  Unmounted = 'Unmounted',
  RequestBreak = 'RequestBreak',
  RequestResume = 'RequestResume',
  Started = 'Started',
  CanPlay = 'CanPlay'
}

export enum PlayerAdsAdapterInvokeType {
  Skip = 'Skip',
  // the ones below are intentionally lowercase to match the current adapter API
  // we should normalize at some point
  SetAutoplay = 'setAutoplay',
  Play = 'play',
  Pause = 'pause',
  SetVolume = 'setVolume'
}