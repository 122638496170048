import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { ChangeJobOwnerWindowState } from './changeJobOwnerWindowState';
import { Button, TextInputControlled } from '../input';

type Props = {
  model: ChangeJobOwnerWindowState;
  visible?: boolean;
};

export const ChangeJobOwnerWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      className="bookmark-window change-owner-window"
      model={model.window}
      visible={props.visible}
      isLoading={model.isLoading}>
      <WindowHeader model={model.window} title="Change owner" />
      <hr />

      <section className="window-content">
        <TextInputControlled model={model.emailInput} label="Owner" />

        <p>
          Changing the owner of a video will change the permissions and access
          of that video.
        </p>
      </section>

      <hr />

      <footer className="window-footer">
        <div className="btn-group right">
          <Button
            className="primary btn-outline medium"
            label="Cancel"
            disabled={model.isLoading}
            onClick={() => model.close()}
          />

          <Button
            className="primary medium"
            label="Save"
            disabled={model.isLoading}
            onClick={() => model.submit()}
          />
        </div>
      </footer>
    </Window>
  );
});
