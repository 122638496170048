import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { PlayerTutorialStep } from '../playerTutorial/playerTutorialWindowState';

export class PlayerTutorialTopicsWindowState
  extends StoreNode {
  readonly nodeType = 'PlayerTutorialTopicsWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }
  readonly window = new WindowState(this.store);

  @observable
  steps: PlayerTutorialStep[] = [];

  @computed get isWidgetMode(): boolean {
    return this.store.widgetService.getIsWidgetMode();
  }

  @action
  initTutorial(pageNumber: number) {
    this.dispatch('Overlays', 'closeWindow');
    this.dispatch('openPlayerTutorialWindow', {
      steps: this.steps,
      pageNumber
    });
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open(payload: any) {
    this.emit('open');
    this.steps = payload.steps;
    this.dispatch('Overlays', 'openWindow', { name: 'PlayerTutorialTopicsWindow' });
  }

  @action
  close() {
    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}