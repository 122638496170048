import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { StartJobInput, UpdateJobInput, ChangeJobOwnerInput, MutationUpdateJobSourceArgs, StartLiveJobInput, LiveJobSourceInput, LiveStreamChannels } from '@clipr/lib';
import { assert, assertNotNull, AsyncResult, Maybe } from '../core';
import { Store } from '../store/store';
import { StoreNode } from '../store/storeNode';
import { ApiResponse, ApiVariables, ApiQueryOptions } from '../api/apiSchema';
import { MomentModel } from './moment';
import { JobModel, JobProps } from './job';
import { ManagerQueryOptions } from './managerSchema';

export const JobsPosterSize = {
  width: 400
}

export const JobPosterSize = {
  width: 1920
}

/**
 * Manages Job and its related entities for a Store.
 */
export class JobManager
  extends StoreNode {

  readonly nodeType: 'JobManager' = 'JobManager';

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  // #region Aliases
  /** @alias `Store.apiService` */
  private get api() {
    return this.store.api;
  }

  /** @alias `Store.bookmarkManager` */
  private get bookmarkManager() {
    return this.store.bookmarkManager;
  }
  // #endregion

  // #region Collections
  readonly jobLookup = observable.map<string, JobModel>();

  @computed
  get jobIds(): Set<string> {
    return new Set(this.jobLookup.keys());
  }

  @computed
  get jobs(): JobModel[] {
    return [...this.jobLookup.values()];
  }


  readonly adminJobLookup = observable.map<string, JobModel>();
  @computed
  get adminJobs(): JobModel[] {
    return [...this.adminJobLookup.values()];
  }

  @computed
  get momentLookup(): Map<string, MomentModel> {
    return new Map(this.moments.map(mom => [mom.id, mom]));
  }

  @computed
  get moments(): MomentModel[] {
    return this.jobs.reduce(
      (arr, job) => arr.concat(job.moments), [] as MomentModel[]);
  }

  /** 
   * The lookup of Jobs that have been created successfully by the API
   * but are not yet returned by any fetch operation.
   * This lookup is kept separately from `jobLookup` because currently the latter is
   * cleared for each fetch operation and information about transient entities will be lost.
   */
  readonly transientJobLookup = observable.map<string, JobModel>();

  @computed
  get transientJobIds(): Set<string> {
    return new Set(this.transientJobLookup.keys());
  }

  @computed
  get transientJobs(): JobModel[] {
    return [...this.transientJobLookup.values()];
  }
  // #endregion


  private assertHasJob(id: string) {
    assert(this.hasJob(id),
      `Store does not contain a Job with id '${id}'.`);
  }

  hasJob(id: string): boolean {
    return this.jobLookup.has(id);
  }

  getJob(id: string): JobModel {
    this.assertHasJob(id);
    return this.jobLookup.get(id)!;
  }
  maybeGetJob(id: Maybe<string>): JobModel | null {
    if (!id)
      return null;
    return this.jobLookup.get(id) ?? this.transientJobLookup.get(id) ?? null;
  }

  @action
  insertJob(jobData: Partial<JobProps>) {
    const job = new JobModel(jobData, this.store);
    this.jobLookup.set(job.id, job);
    return job;
  }

  @action
  insertTransientJob(jobData: Partial<JobProps>) {
    jobData = {
      ...jobData,
      isTransient: true
    };

    const job = new JobModel(jobData, this.store);
    this.transientJobLookup.set(job.id, job);

    return job;
  }

  @action
  mergeJob(jobData: Partial<JobProps> & { id: string }) { // temp solution: used in order to avoid fetching all the dependencies after every job update
    const jobId = jobData.id;
    const oldJob = this.maybeGetJob(jobId);
    // jobData.poster = oldJob?.poster;
    const job = new JobModel(jobData, this.store);
    runInAction(() => {
      if (oldJob) {
        job.momentLookup.replace(oldJob.momentLookup);
        job.trackLookup.replace(oldJob.trackLookup);
      }
    });
    this.jobLookup.set(job.id, job);
    return job;
  }

  async loadData() {
    return Promise.all([
      this.bookmarkManager.apiFetchBookmarkLists()
    ]);
  }

  async apiFetchJob(id: string, deps = false, opts?: ApiQueryOptions, teamId?: string): AsyncResult<JobModel> {

    const args: ApiVariables<'getJob'> = {
      id,
      posterSize: [JobPosterSize],
      teamId: teamId ?? undefined
    }

    const [jobData, err] = await this.api.getJob(args, opts);
    if (err)
      return [null, err];
    assertNotNull(jobData);

    const job = this.insertJob(jobData.getJob!);
    if (deps)
      await job.fetchDependencies(opts);

    // DEBUG
    this._verifyFetchedJobNotTransient(job.id);

    return [job];
  }

  // TODO: the typings for the switch between returning raw response and returning processed JobModels
  // is kinda overkill. Will try and think of a simpler solution

  async apiFetchJobs(vars: ApiVariables<'getJobs'>, opts?: ManagerQueryOptions<false>)
    : AsyncResult<JobModel[]>;

  async apiFetchJobs(vars: ApiVariables<'getJobs'>, opts?: ManagerQueryOptions<true>)
    : AsyncResult<ApiResponse<'getJobs'>>;

  async apiFetchJobs(vars: ApiVariables<'getJobs'>, opts?: ManagerQueryOptions)
    : AsyncResult<ApiResponse<'getJobs'> | JobModel[]> {

    const [jobsData, err] = await this.store.api.getJobs(vars, opts);
    if (err)
      return [null, err];
    assertNotNull(jobsData);

    const { transientJobLookup } = this;
    const jobEdges = jobsData.getJobs.edges;
    const jobs: JobModel[] = [];

    jobEdges.forEach(edge => {
      const job = this.insertJob(edge.node);

      // the first time a job comes from a `getJobs` operation we consider
      // it to be already propagated into BE and we can delete the transient entity
      if (transientJobLookup.has(job.id))
        transientJobLookup.delete(job.id);

      jobs.push(job);
    });

    // DEBUG
    this._verifyLookupDuplicates();

    if (opts?.rawResponse)
      return [jobsData];

    return [jobs];
  }

  async apiFetchTrainerJobs(vars: ApiVariables<'adminGetJobs'>, opts?: ManagerQueryOptions<false>)
    : AsyncResult<JobModel[]>;

  async apiFetchTrainerJobs(vars: ApiVariables<'adminGetJobs'>, opts?: ManagerQueryOptions<true>)
    : AsyncResult<ApiResponse<'adminGetJobs'>>;

  async apiFetchTrainerJobs(vars: ApiVariables<'adminGetJobs'>, opts?: ManagerQueryOptions)
    : AsyncResult<ApiResponse<'adminGetJobs'> | JobModel[]> {

    const [jobsData, err] = await this.store.api.adminGetJobs(vars, opts);
    if (err)
      return [null, err];
    assertNotNull(jobsData);

    const { transientJobLookup } = this;
    const jobEdges = jobsData.adminGetJobs.edges;
    const jobs: JobModel[] = [];

    jobEdges.forEach(edge => {
      const job = this.insertJob(edge.node);

      // the first time a job comes from a `getJobs` operation we consider
      // it to be already propagated into BE and we can delete the transient entity
      if (transientJobLookup.has(job.id))
        transientJobLookup.delete(job.id);

      jobs.push(job);
    });

    // DEBUG
    this._verifyLookupDuplicates();

    if (opts?.rawResponse)
      return [jobsData];

    return [jobs];
  }

  async apiStartJob(params: StartJobInput): AsyncResult<JobModel> {
    const [jobData, err] = await this.api.startJob({ args: params });
    if (err)
      return [null, err];

    const job = this.insertTransientJob(jobData?.startJob!);

    return [job];
  }

  async apiStartLiveJob(params: StartLiveJobInput): AsyncResult<JobModel> {
    const [jobData, err] = await this.api.startLiveJob({ args: params });
    if (err)
      return [null, err];

    const job = this.insertTransientJob(jobData?.startLiveJob!);

    return [job];
  }

  async apiStopLiveJob(jobId: string, source: LiveStreamChannels): AsyncResult<JobModel> {
    const [jobData, err] = await this.api.stopLiveJob({ jobId, source });
    if (err)
      return [null, err];

    const job = this.insertTransientJob(jobData?.stopLiveJob!);

    return [job];
  }

  async apiUpdateJob(params: UpdateJobInput, apiFetch = false): AsyncResult<JobModel> {
    const [jobData, err] = await this.api.updateJob({
      args: params,
      posterSize: [JobPosterSize],
      teamId: params.teamId
    });

    if (err)
      return [null, err];

    const jobId = jobData?.updateJob?.id;

    if (apiFetch) {
      if (!jobId)
        return [null, new Error('Updated Job ID was not returned')];

      const [, refErr] = await this.apiFetchJob(jobId, true);

      if (refErr)
        return [null, new Error('Updated job could not be fetched')];

      // const job = this.insertJob(jobData?.updateJob!);
      const job = this.getJob(jobId);
      if (job)
        return [job];

      return [null, 'Updated job was not fetched'];
    } else {
      const job = this.mergeJob(jobData?.updateJob!);
      if (job)
        return [job];

      return [null, 'Updated job merge failed'];
    }
  }

  async apiUpdateJobSource(params: MutationUpdateJobSourceArgs, apiFetch = false): AsyncResult<JobModel> {
    const [jobData, err] = await this.api.updateJobSource(params);
    if (err)
      return [null, err];

    const jobId = jobData?.updateJobSource?.id;

    if (apiFetch) {
      if (!jobId)
        return [null, new Error('Updated Job ID was not returned')];

      const [, refErr] = await this.apiFetchJob(jobId, true);

      if (refErr)
        return [null, new Error('Updated job could not be fetched')];

      // const job = this.insertJob(jobData?.updateJob!);
      const job = this.getJob(jobId);
      if (job)
        return [job];

      return [null, 'Updated job was not fetched'];
    } else {
      const job = this.mergeJob(jobData?.updateJobSource!);
      if (job)
        return [job];

      return [null, 'Updated job merge failed'];
    }
  }

  async apiUpdateLiveJobSource(jobId: string, params: LiveJobSourceInput, apiFetch = false): AsyncResult<JobModel> {
    const [jobData, err] = await this.api.updateLiveSource({ jobId, source: params, posterSize: [JobsPosterSize] });
    if (err)
      return [null, err];

    if (apiFetch) {
      if (!jobId)
        return [null, new Error('Updated Job ID was not returned')];

      const [, refErr] = await this.apiFetchJob(jobId, true);

      if (refErr)
        return [null, new Error('Updated job could not be fetched')];

      // const job = this.insertJob(jobData?.updateJob!);
      const job = this.getJob(jobId);
      if (job)
        return [job];

      return [null, 'Updated job was not fetched'];
    } else {
      const job = this.mergeJob(jobData?.updateLiveJobSource!);
      if (job)
        return [job];

      return [null, 'Updated job merge failed'];
    }
  }

  async apiChangeJobOwner(params: ChangeJobOwnerInput): AsyncResult<JobModel> {
    const [jobData, err] = await this.api.changeJobOwner({ args: params });
    if (err)
      return [null, err];

    const job = this.insertJob(jobData?.changeJobOwner!);
    return [job];
  }

  async apiCopyJob(vars: ApiVariables<'copyJob'>): AsyncResult<JobModel> {

    const [jobData, err] = await this.api.copyJob(vars);
    if (err)
      return [null, err];

    const job = this.insertJob(jobData!.copyJob!);
    return [job];
  }

  getMoment(id: string): MomentModel {
    assert(this.momentLookup.has(id),
      `Store does not contain a Moment with id '${id}'.`);
    return this.momentLookup.get(id)!;
  }

  maybeGetMoment(id: string): MomentModel | null {
    return this.momentLookup.get(id) || null;
  }

  /** 
   * **This method is for development / debugging only!**
   * 
   * Checks to see if there are any duplicates between the fetched Job lookup 
   * and the transient Job lookup, and triggers a warning if there are any.
   */
  private _verifyLookupDuplicates(): boolean {

    const {
      transientJobIds: transJobIds,
      jobIds
    } = this;

    const duplicates = new Set<string>();
    for (let transJobId of transJobIds) {
      if (jobIds.has(transJobId))
        duplicates.add(transJobId);
    }

    if (duplicates.size > 0) {
      console.warn(
        `JobManager: Encountered ${duplicates.size} duplicates between fetched jobs and transient jobs:\n` +
        [...duplicates].map(id => `\t${id}\n`));
      return false;
    }

    return true;
  }

  /** 
   * **This method is for development / debugging only!**
   * 
   * Checks to see if a Job that has been just fetched is also found in the transient lookup.
   * This should normally not happen as no operation should be permitted on transient jobs,
   * thus no `fetchJob` should occur. If the `jobId` is also found in the transient lookup then
   * a warning is triggered.
   */
  private _verifyFetchedJobNotTransient(jobId: string): boolean {
    if (this.transientJobIds.has(jobId)) {
      console.warn(
        `JobManager: Fetched job ${jobId} was also found in the transient job lookup.`);
      return false;
    }

    return true;
  }
}