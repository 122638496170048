import {
  action,
  makeObservable,
  computed,
  observable
} from 'mobx';
import { IReactHotkeysProps } from 'react-hot-keys';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { ObjectLiteral } from '../../core';

export type HotkeysConfigItem = {
  keynames: string[],
  description?: string,
  isAvailableInFullscreen?: boolean
}
export type HotkeysControllerProps = IReactHotkeysProps & {
  config: ObjectLiteral<HotkeysConfigItem>
};

export class HotkeysControllerState
  extends StoreNode {

  readonly nodeType = 'HotkeysController';

  constructor(store: Store, props: HotkeysControllerProps) {
    super(store);
    makeObservable(this);

    this.config = props.config;
    this.disabled = props.disabled;
    this.splitKey = props.splitKey;
    this.allowRepeat = props.allowRepeat;
    this.filter = props.filter;
  }

  readonly config: ObjectLiteral<HotkeysConfigItem>;
  @observable disabled?: boolean;
  @observable splitKey?: string;
  @observable allowRepeat?: boolean;
  @observable filter?: (e: KeyboardEvent) => boolean;

  @computed
  get keynames(): string {
    const keys = Object.values(this.config).map(i => i.keynames);
    const withCapsLock = this.addCapsLockSupport(keys);
    return withCapsLock.join(',');
  }

  @action
  onKeyDown(keyname: string, evt: KeyboardEvent) {
    if (keyname === 'space') {
      // After using the UI controls they remain focused
      // Hitting space bar results in undesired fire of the last control pressed
      evt.preventDefault();
    }
    const key = keyname.replace('capslock+', ''); // Replace capslock because it's not in the mapping object
    const handler = Object.entries(this.config).find(([, value]) => value.keynames.includes(key)) ?? null;

    this.emit('onKeyDown', handler);
  }

  @action
  onKeyUp(keyname: string, evt: KeyboardEvent) {
    const key = keyname.replace('capslock+', ''); // Replace capslock because it's not in the mapping object
    const handler = Object.entries(this.config).find(([, value]) => value.keynames.includes(key)) ?? null;

    this.emit('onKeyUp', handler);
  }

  /**
   * Method that adds support for caps lock without adding it to the original mapping.
   * This allows for the modifier support without interfering with the visual shortcut legend
   * @param keys The original array of keys
   * @returns A flattened copy of the original array
   */
  @action
  private addCapsLockSupport(keys: string[][]): string[] {
    return keys.reduce((arr: string[], curr: string[]) => {
      const added = curr.map(i => `capslock+${i}`);
      return [...arr, ...curr, ...added];
    }, keys[0]);
  }
}