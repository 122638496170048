import { computed, makeObservable } from 'mobx';
import { Bookmark as ApiBookmark, BookmarkJobStub, BookmarkMomentStub, ResizedImage } from '@clipr/lib';
import { assert, AsyncResult } from '../core';
import { BookmarkList } from './bookmarkList';
import { JobModel } from './job';
import { MomentModel } from './moment';
import { Store } from '../store/store';
import { StoreNode } from '../store';
import omit from 'lodash/omit';
import { DateTime } from 'luxon';

export type BookmarkTarget =
  MomentModel |
  JobModel;

export type BookmarkType =
  'Topic' |
  'SubTopic' |
  'Paragraph' |
  'Chapter' |
  'Transcript' |
  'Moment' |
  'ActionItem' |
  'Job';

export type BookmarkTargetType =
  'Moment' |
  'Job';

export type BookmarkProps = ApiBookmark;

export function isBookmark(arg: any): arg is Bookmark {
  return (
    arg instanceof Bookmark &&
    arg.nodeType === 'Bookmark');
}

export function assertIsBookmark(arg: any): asserts arg is Bookmark {
  assert(isBookmark(arg),
    `Expected ${arg} to be instance of Bookmark.`);
}

export class Bookmark
  extends StoreNode {

  constructor(props: Partial<BookmarkProps>, store: Store) {
    super(store);
    makeObservable(this);
    Object.assign(this, omit(props, ['moment', 'job']));

    this.jobStubAsJob = new JobModel({ id: props.jobId, ...omit(props.jobStub!, '__typename') }, this.store);
    this.momentStubAsMoment = new MomentModel(omit(props.momentStub!, '__typename'), this.store);
  }

  readonly nodeType: 'Bookmark' = 'Bookmark';

  readonly id!: string;
  readonly createdAt!: string;
  readonly listId!: string;
  readonly jobId!: string;
  readonly momentId!: string;
  readonly thumbnail!: ResizedImage[];

  readonly jobStub!: BookmarkJobStub;
  readonly jobStubAsJob: JobModel;
  readonly momentStub!: BookmarkMomentStub;
  readonly momentStubAsMoment: MomentModel;

  @computed
  get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed
  get thumbnailUrl(): string | null {
    return (this.thumbnail &&
      this.thumbnail.length > 0 &&
      this.thumbnail[0]?.url) || null;
  }

  /** Returns the Job instance from the store if it has been loaded 
   * or the current value of `.jobStubAsJob` otherwise.
   * This is the property you should be using to access the Job associated with the Bookmark
   * because it offers the highest chance of returning you something.
   */
  @computed
  get actualJob(): JobModel | null {
    return this.job || this.jobStubAsJob || null;
  }

  @computed
  get moment(): MomentModel | null {
    return this.job?.getMoment(this.momentId) || null;
  }

  /** 
   * Returns the Moment instance from the store if it has been loaded 
   * or the current value of `.momentStubAsMoment` otherwise. 
   * This is the property you should be using to access the Moment associated with the Bookmark
   * because it offers the highest chance of returning you something.
  */
  @computed
  get actualMoment(): MomentModel | null {
    return this.moment || this.momentStubAsMoment || null;
  }

  @computed
  get list(): BookmarkList {
    return this.store.getBookmarkList(this.listId);
  }


  @computed
  get targetType(): BookmarkTargetType {
    if (this.momentId)
      return 'Moment';
    return 'Job';
  }

  @computed
  get targetId(): string {
    return this.targetType === 'Moment' ?
      this.momentId :
      this.jobId;
  }

  @computed
  get target(): BookmarkTarget | null {
    return this.targetType === 'Moment' ?
      this.actualMoment :
      this.actualJob;
  }

  @computed
  get type(): BookmarkType {
    switch (this.targetType) {
      default:
      case 'Moment':
        return this.actualMoment?.clipType || 'Moment';
      case 'Job':
        return 'Job';
    }
  }

  @computed
  get typeLabel(): string {
    switch (this.targetType) {
      default:
      case 'Moment':
        return this.actualMoment?.typeLabel || 'Moment';
      case 'Job': return 'Full Video';
    }
  }

  @computed
  get createdAtDate(): DateTime {
    return DateTime.fromISO(this.createdAt);
  }

  @computed
  get createdAtLabel(): string {
    return this.createdAtDate.toLocaleString(DateTime.DATE_SHORT);
  }


  @computed
  get videoLabel(): string | null {
    const { moment } = this;
    if (moment)
      return this.actualJob?.title || null;
    return null;
  }

  @computed
  get name(): string | null {

    const refMoment = this.actualMoment;
    const refJob = this.actualJob;

    if (this.momentId) {
      if (refMoment?.clipType === 'Topic' || refMoment?.clipType === 'SubTopic')
        return refMoment?.name || null;
      return refMoment?.momentType || null;
    }

    return refJob?.title || null;
  }

  @computed
  get isPublished(): boolean {
    return !!this.actualJob?.isPublished;
  }

  async apiFetchRelations(): AsyncResult<boolean> {
    if (!this.job) {
      const [job, err1] = await this.store.apiFetchJob(this.jobId);
      if (err1)
        return [null, err1];

      await job!.apiFetchMoments();
      if (this.targetType === 'Moment')
        await job!.apiFetchMoments();
    }
    return [true];
  }
}