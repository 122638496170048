import React from 'react';
import { observer } from 'mobx-react-lite';

type SVGTextProps = React.SVGAttributes<SVGTextElement>;
type OwnProps = {
  /** Horizontal text anchor. */
  textAnchor?: 'start' | 'middle' | 'end' | 'inherit';
  /** Ref passed to the Text SVG element. */
  innerRef?: React.Ref<SVGSVGElement>;
  /** Ref passed to the Text text element */
  innerTextRef?: React.Ref<SVGTextElement>;
  /** dx offset of the text. */
  dx?: string | number;
  /** dy offset of the text. */
  dy?: string | number;
  /** Maximum width to occupy. */
  width: number;
  /** String (or number coercible to one) to be styled and positioned. */
  children?: string | number;
  suffix?: string,
};
export type TextProps = OwnProps & Omit<SVGTextProps, keyof OwnProps>;

export const EllipseText = observer((props: TextProps) => {
  const {
    dx = 0,
    dy = 0,
    textAnchor = 'start',
    innerRef,
    innerTextRef,
    width,
    children,
    suffix = '',
    ...textProps
  } = props;

  const SVG_STYLE = { overflow: 'visible' };
  const { fontSize } = textProps;
  const str: string = children == null ? '' : children.toString();
  const maxStrLength = getMaxStrLength(str, props)

  return (
    <svg ref={innerRef} x={dx} y={dy} fontSize={fontSize} style={SVG_STYLE}>
      <text ref={innerTextRef} {...textProps} textAnchor={textAnchor}>
        <tspan>{getEllipseText(str, suffix, maxStrLength)}</tspan>
      </text>
    </svg>
  );
});

const getEllipseText = (str: string, suffix: string, maxStrLength: number): string => {
  return str.length > maxStrLength ? str.slice(0, maxStrLength) + suffix : str
}

const getMaxStrLength = (str: string, props: TextProps): number => {
  try {
    const {
      width,
      style,
      suffix = '',
    } = props;

    let textEl = document.getElementById('__react_svg_text_ellipse_id') as SVGTextElement | null;

    if (!textEl) {
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      svg.style.width = '0';
      svg.style.height = '0';
      svg.style.position = 'absolute';
      svg.style.top = '-100%';
      svg.style.left = '-100%';
      textEl = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      textEl.id = '__react_svg_text_ellipse_id'
      svg.appendChild(textEl);
      document.body.appendChild(svg);
    }

    Object.assign(textEl.style, style);
    textEl.textContent = str;

    let textLength = textEl.getComputedTextLength();
    let text = textEl.textContent;

    while (textLength > width && text.length > 0) {
      text = text.slice(0, -1);
      textEl.textContent = text + suffix;
      textLength = textEl.getComputedTextLength();
    }

    return text.length;
  } catch (e) {
    return 0;
  }
}
