import { observable, ObservableSet } from 'mobx';

export type MaybeObservableSet<T> =
  Set<T> |
  ObservableSet<T>;

/**
 * Utility for instantiating either a native Set or an observable set, depending on preference.
 */
export function createSet<T>(obs = false) {
  return obs ? observable.set<T>() : new Set<T>();
}