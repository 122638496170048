import identity from 'lodash/identity';
import { action, computed, makeObservable } from 'mobx';
import { Maybe, isNonEmptyString, isIterable } from '../../core';
import { BindingProps, StoreNode, StoreNodeKeyFunc } from '../../store';
import { Store } from '../../store/store';
import {
  InvertedSelectorCore,
  SelectorCore,
  SelectorCoreData
} from '.';

type Props<T extends StoreNode = StoreNode> =
  {
    name?: string,
    keyFunc?: StoreNodeKeyFunc<T>,
    useInvertedSelector?: boolean
  } &
  BindingProps<{
    entities?: Iterable<T>,
    filteredKeys?: Iterable<string>
  }>;

/**
 * Manages the selection for a single "line" that forms the total output of the selector.
 * A "line" refers to any of the selector components for topics, subtopics, moments, etc.
 */
export class EntitySelector<T extends StoreNode = StoreNode>
  extends StoreNode {

  readonly nodeType = 'EntitySelector';

  constructor(store: Store, props?: Maybe<Props>) {
    super(store, props);
    makeObservable(this);
  }

  // #region Props
  // -------
  @computed
  get name(): string | null {
    return this.getProp('name') || null;
  }

  @computed
  get keyFunc(): StoreNodeKeyFunc<T> | null {
    return this.getProp('keyFunc') || null;
  }

  @computed
  get useInvertedSelector(): boolean {
    return this.getProp('useInvertedSelector') || false;
  }

  @computed
  get filteredKeys(): Set<string> | null {
    const data = this.getResolvedProp('filteredKeys');
    if (isIterable(data))
      return new Set(data);
    return null;
  }

  @computed
  get entities(): T[] {
    const data = this.getResolvedProp('entities');
    if (isIterable(data))
      return [...data];
    return [];
  }
  // #endregion

  @computed
  get visibleEntities() {
    return this.entities.filter(ent =>
      this.isFiltered(ent));
  }
  @computed
  get hiddenEntities() {
    return this.entities.filter(ent =>
      !this.isFiltered(ent));
  }

  @computed
  get selectedEntities() {
    return this.entities.filter(ent =>
      this.isSelected(ent));
  }
  @computed
  get unselectedEntities() {
    return this.entities.filter(ent =>
      !this.isSelected(ent));
  }

  @computed
  get isEmpty() {
    return this.selector.isEmpty;
  }

  @computed
  get visibleSelectedEntities() {
    return this.entities.filter(ent =>
      this.isFiltered(ent) && this.isSelected(ent));
  }

  @computed
  get selectedKeys(): Set<string> {
    return new Set(this.selectedEntities
      .map(ent => this.getKey(ent)!)
      .filter(identity));
  }

  readonly selector = new SelectorCore<string>();
  readonly invertedSelector = new InvertedSelectorCore<string>();

  @computed
  private get actualSelector() {
    if (this.useInvertedSelector)
      return this.invertedSelector;
    else
      return this.selector;
  }

  /**
   * If the provided argument is an Entity instance, it will return the instance with the same ID from the 'entities' source, if any.
   * If the provided argument is a string, it will return the Entity instance with that ID, if any.
   */
  get(arg: T | string): T | null {
    if (!arg)
      return null;

    if (typeof arg === 'string')
      return this.entities.find(ent => ent.id === arg) || null;

    if (typeof arg === 'object')
      return this.entities.find(ent => ent.id === arg.id) || null;

    return null;
  }

  getKey(arg: T | string): string | null {
    if (!arg)
      return null;

    if (typeof arg === 'string')
      return arg;

    if (typeof arg === 'object') {
      const { keyFunc } = this;
      if (typeof keyFunc === 'function') {
        const key = keyFunc(arg);
        if (!isNonEmptyString(key)) {
          console.warn(`Tried to apply 'keyFunc' to ${arg._debugger?.label} but the returned result ${key} is not a valid key. They key will be discarded.`);
          return null;
        }

        return key;
      }

      return arg.id;
    }

    return null;
  }

  /** 
   * Extracts the key from the provided argument and runs the specified function.
   * Even if the key cannot be extracted, the function will be run anyways and the result returned.
   * If you want to not run the function when the key is invalid use `runWithKeyOrDiscard`.
   */
  private runQueryWithKey<TResult>(
    arg: T | string,
    func: (key: string | null) => TResult): TResult {
    const key = this.getKey(arg);
    return func(key);
  }

  @action
  private runMutationWithKey<TResult>(arg: T | string, func: (key: string) => TResult): TResult | null {
    const key = this.getKey(arg);
    if (!key)
      return null;
    return func(key);
  }

  @action
  purge() {
    const { entities } = this;
    
    const getDeadKeys = (keys: string[]) => keys.filter(key => {
      return !entities.some(ent => this.getKey(ent) === key);
    });

    getDeadKeys([...this.selector.keys]).forEach(deadKey =>
      this.selector.remove(deadKey));
  }

  isSelected(arg: T | string) {
    return this.runQueryWithKey(arg,
      key => key ? this.actualSelector.isSelected(key) : false);
  }

  isFiltered(arg: T | string) {
    const filter = this.filteredKeys;
    if (!filter)
      return true;

    return this.runQueryWithKey(arg,
      key => key ? filter.has(key) : true);
  }

  @action
  toggle(arg: T | string) {
    return this.runMutationWithKey(arg,
      key => this.actualSelector.toggle(key));
  }
  @action
  select(arg: T | string) {
    return this.runMutationWithKey(arg,
      key => this.actualSelector.select(key));
  }
  @action
  unselect(arg: T | string) {
    return this.runMutationWithKey(arg,
      key => this.actualSelector.unselect(key));
  }
  @action
  selectAll() {
    return this.actualSelector.selectMany(
      this.entities
        .map(ent => this.getKey(ent)!)
        .filter(identity));
  }
  @action
  clear() {
    return this.actualSelector.clear();
  }


  export() {
    this.purge();
    return this.actualSelector.export();
  }

  @action
  import(data: SelectorCoreData<string>) {
    this.clear();
    this.actualSelector.import(data);
    this.purge();
  }

}