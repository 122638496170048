// @ts-ignore
import CSSVariables from './playerFrameset.scss';

import React, { ReactNode } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { observer } from 'mobx-react-lite';
import { PlayerFrameName, PlayerSectionName } from './playerFramesetSchema';
import { PlayerFramesetState } from './playerFramesetState';

const FadeTimeout = {
  enter: parseInt(CSSVariables.frameEnterTimeout),
  exit: parseInt(CSSVariables.frameExitTimeout)
};

export type SectionRenderInfo = {
  visible: boolean,
  frame: PlayerFrameName
}

export type SectionElementFunc =
  (args: SectionRenderInfo) => ReactNode;
export type SectionElement =
  ReactNode | SectionElementFunc;

type Props = {
  model: PlayerFramesetState,
  frame: PlayerFrameName,
  section: PlayerSectionName,
  content: SectionElement,
  frameVisible?: boolean
}

export const FrameWrapper = observer(({
  model,
  frame,
  section,
  content,
  frameVisible = true
}: Props) => {

  const visible = model.isSectionVisible(section);
  const renderInfo: SectionRenderInfo = {
    frame,
    visible
  }

  let elem: ReactNode;
  if (typeof content == 'function')
    elem = content(renderInfo);
  else
    elem = content;

  return (
    <TransitionGroup
      className="frame-wrapper">

      {visible && frameVisible && elem && (
        <CSSTransition
          classNames="menu-fade"
          timeout={FadeTimeout}>
          {elem}
        </CSSTransition>)}
    </TransitionGroup>
  );
});