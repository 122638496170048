import { AnalyticsViewContextData } from '.';
import { PlayerState } from "../../../components";
import { Message } from "../../../store";
import { IAnalyticsStream, AnalyticsStreamParams, AnalyticsEventTypes } from "../analyticsSchema";
import { AnalyticsCurrentJobData, AnalyticsMainData, AnalyticsPlayerStateData, AnalyticsStream } from "./analyticsStream";

export type AnalyticsPlayerStreamParams = Message<PlayerState>

export type AnalyticsPlayerStreamData =
  AnalyticsPlayerStateData &
  AnalyticsCurrentJobData &
  AnalyticsViewContextData &
  AnalyticsMainData;

export class AnalyticsPlayerStream extends AnalyticsStream implements IAnalyticsStream<keyof AnalyticsStreamParams> {
  static streamName = AnalyticsEventTypes.PlayerType

  exchangeData(msg: AnalyticsPlayerStreamParams): AnalyticsPlayerStreamData | null {
    const { type, senderName, sender, payload } = msg;
    const { job } = sender;

    if (!job ||
      (senderName === 'Node' && !['pause', 'ended', 'play'].includes(type))) {
      return null;
    }

    let playerData: AnalyticsPlayerStateData = super.playerData;

    switch (type) {
      case 'play':
      case 'pause':
      case 'ended':
      case 'jumpToMoment':
      case 'jumpToComment':
      case 'jumpToReaction':
      case 'replay':
      case 'autoplay':
        playerData.player.event = type ?? null
        break;
      case 'jumpToCommentOrReaction':
        playerData.player.event = payload?.target?.reaction ? 'jumpToReaction' : 'jumpToComment';
        break;
      default:
        return null;
    }

    return {
      ...playerData,
      ...this.currentJobData(job),
      ...this.mainData,
      ...this.viewContextData
    };
  }
}
