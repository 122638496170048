import './layout.scss';
import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { HTMLDivProps } from '../../core';
import { BasicPageHeader } from './basicPageHeader';
import { ChunksPropValue, PageChunks } from './pageChunks';
import { LoadMask } from '../loader';
import { CliprLogoSVGIcon } from '../svg';
import { useStore } from '../../store/storeHooks';

type Props = HTMLDivProps & {
  id?: string;
  chunks?: ChunksPropValue;
  showHeader?: boolean;
  showLoadMask?: boolean;
  showBackButton?: boolean;
  showWatermark?: boolean;
  logo?: string | null;
};

/**
 * Barebones page with just a header containing a back button at the top.
 */
export const BasicPage = observer(({
  id,
  className,
  chunks,
  showHeader = true,
  showWatermark = true,
  showLoadMask,
  children,
  logo,
  ...props
}: Props) => {
  className = classNames(className, 'basic-page');

  const store = useStore();
  const computedLogo = logo ?? store.uiService.logo;

  return (
    <div className={className} id={id}>
      <div className="basic-page-body page-body">
        {showHeader && <BasicPageHeader {...props} />}

        {children}

        {showWatermark && !computedLogo && (
          <div className="watermark">
            <CliprLogoSVGIcon />
          </div>
        )}
        <LoadMask
          logo={computedLogo}
          visible={showLoadMask} />
      </div>

      <PageChunks chunks={chunks} />
    </div>
  );
});

export const LoadPage = observer(() => {
  return <BasicPage
    showLoadMask={true}
    showHeader={false} />
});