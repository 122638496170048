import './playerComments.scss';

import React, { useRef, useLayoutEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { handler } from '../../core';
import { UserAvatar } from '../layout/userAvatar';
import { ArrowRightSVGIcon, CloseSVGIcon } from '../svg';
import { Button } from '../input';
import { RichText } from './draft';
import { CommentMenu } from './commentMenu';
import { CommentState } from './commentState';
import { CommentInput } from './commentInput';
import { LoadMask } from '../loader/loadMask';

type Props = {
  model: CommentState,
  hidden?: boolean,
  readonly?: boolean,
  isActive?: boolean,
  onClick?: () => void
};

export const CommentCard = observer(({
  model,
  hidden,
  readonly,
  isActive,
  onClick
}: Props) => {
  const { comment } = model;
  const contentRef = useRef(null);
  const className = classNames('comment', {
    'active': isActive || model.isActive,
    'reply': model.isReply && !readonly
  });

  useLayoutEffect(() => {
    const content = contentRef.current;
    if (content) model.attachDOMContent(content);
    return () => model.detachDOMContent();
  }, [model]);

  return (
    <div className={className}
      onPointerEnter={handler(model, 'handlePointerEnter')}
      onPointerLeave={handler(model, 'handlePointerLeave')}
      aria-current={model.isCurrent}
      aria-hidden={hidden}
      aria-readonly={readonly}
      onClick={() => onClick && onClick()}>
      <div className="comment-container">
        <UserAvatar className="comment-user-avatar"
          model={model.user} />

        <p className="comment-user-name">
          {model.ownerName || "Unknown user"}
        </p>

        {!model.isReply && (
          <div className="comment-meta">
            <p className="comment-time">
              <span className="icon"><ArrowRightSVGIcon /></span>
              <span className="text">{model.videoTimeLabel}</span>
            </p>

            {!model.showReplyInput && !readonly && comment.hasPermission('AddReply') && (
              <Button className="reply-btn btn-plain"
                onClick={evt => {
                  evt.stopPropagation();
                  model.toggleReplyInput();
                }} //handler(model, 'toggleReplyInput')
                label="Reply" />
            )}
          </div>
        )}

        <div className="comment-body"
          ref={contentRef}
          aria-hidden={model.showEditInput}>
          <div className="comment-content"
            aria-expanded={model.hasContentExpanded}>
            {model.content && (
              <RichText content={model.content} />
            )}

            {!model.hasContentExpanded && (
              <Button className="see-more-btn btn-plain"
                onClick={evt => {
                  evt.stopPropagation();
                  model.toggleContentExpanded();
                }} //handler(model, 'toggleContentExpanded')
                label="...see more" />
            )}
          </div>

          <div className="comment-menu-btn">
            {!readonly && (
              <CommentMenu
                onOpen={handler(model, 'handleMenuOpened')}
                model={model} />
            )}
          </div>
        </div>

        {model.showEditInput && !readonly && (
          <div className="comment-edit">
            <CommentEditInput model={model} />
          </div>
        )}
      </div>

      {!model.isReply && !readonly && (
        <CommentReplyInput model={model} />
      )}
    </div>
  );
});

const CommentReplyInput = observer((props: Props) => {
  const { model } = props;

  if (!model.replyInput || !model.showReplyInput)
    return null;

  return (
    <div className="comment-reply-input"
      id={`comment-reply-input-${model.comment.id}`}
      aria-expanded={model.showReplyInput}>
      <CommentInput model={model.replyInput} />

      <div className="reply-close">
        <Button className="close-btn"
          onClick={evt => {
            evt.stopPropagation();
            model.toggleReplyInput();
          }} //handler(model, 'toggleReplyInput')
          icon={<CloseSVGIcon />} />
      </div>

      <LoadMask
        visible={model.replyInput?.isLoading}
        className="small transparent"
        spinner="dots3"
        spinnerClassName="medium"
      />
    </div>
  );
});

const CommentEditInput = observer((props: Props) => {
  const { model } = props;

  return (
    <div className="comment-edit-input">
      <CommentInput model={model.editInput} />

      <div className="btn-group">
        <Button className="primary btn-outline small"
          onClick={evt => {
            evt.stopPropagation();
            model.toggleEditInput();
          }} //handler(model, 'toggleEditInput')
          label="Cancel" />

        <Button className="secondary small"
          onClick={evt => {
            evt.stopPropagation();
            model.submitEdit();
          }}//handler(model, 'submitEdit')
          label="Post"
          disabled={!model.editInput?.canSubmit} />
      </div>

      <LoadMask
        visible={model.editInput?.isLoading}
        className="small transparent"
        spinner="dots3"
        spinnerClassName="medium"
      />
    </div>
  );
});