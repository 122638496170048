import { Sdk } from '@clipr/lib';
import { AsyncResult, PromiseType } from '../core';
import { ApiError } from './apiError';

export enum ApiServiceAuthStrategy {
  EnsureToken = 'EnsureToken',
  None = 'None'
}

export enum ApiRequestMode {
  /**
   * The query will be sent without a token regardless of the current auth status
   * and without any waiting for a context if none is set.
   */
  Direct = 'Direct',

  /**
   * If there is a valid AuthorizedAuthContext set, then the query will be sent with a token.
   * If there is an expired AuthorizedAuthContext set then the query will be sent with that expired token.
   * If there is an AnonymousAuthContext or no AuthContext set, then the query will be sent without a token.
   * Use this for situations in which you want to use the token from the current context,
   * but you don't want to trigger a RefreshPermitFlow.
   */
  OptionalAuthorization = 'OptionalAuthorization',

  /**
   * If there is a valid AuthorizedAuthContext set, then the query will be sent with a token.
   * If there is an expired AuthorizedAuthContext or no AuthContext set then a RefreshPermitFlow will be run.
   * If there is an AnonymousAuthContext set, then the query will be sent without a token.
   */
  RequiredAuthorization = 'RequiredAuthorization'
}

export type ApiQueryName = keyof Sdk; //Exclude<keyof Query, '__typename'>;
export type ApiMutationName = keyof Sdk; //Exclude<keyof Mutation, '__typename'>;
export type ApiRequestName =
  ApiQueryName |
  ApiMutationName;

/** Represents the type of the response data object returned if the request succeeded. */
export type ApiResponse<T extends ApiRequestName> =
  PromiseType<ReturnType<Sdk[T]>>;

export type ApiUnwrappedResponse<T extends ApiRequestName> =
  ApiResponse<T> extends { [key in T]: infer TOut } ? TOut : never;

export type ApiVariables<T extends ApiRequestName> =
  Parameters<Sdk[T]>[0];

export type ApiResult<T extends ApiRequestName = any> =
  AsyncResult<ApiResponse<T>, ApiError>;

export type ApiErrorResult =
  [null, ApiError];

export type ApiUnwrappedResult<T extends ApiRequestName> =
  AsyncResult<ApiUnwrappedResponse<T>, ApiError>;


export type ApiMutationInput<T extends ApiRequestName> =
  ApiVariables<T> extends { args: infer TInput } ? TInput : never;

export type ApiResponseTransform<T extends ApiRequestName, TOut> =
  (result: ApiResponse<T>) => TOut;


export type ApiRequestOptions = {
  token?: string;
  signal?: AbortSignal;
  suppressRedirectWhenOffline?: boolean;
  mode?: ApiRequestMode
}

export const DefaultApiRequestOptions: ApiRequestOptions = {
  mode: ApiRequestMode.RequiredAuthorization
}

export type ApiRequestParams<TVars = any> = {
  endpoint: string;
  document: string;
  variables?: TVars;
  token?: string | null;
  signal?: AbortSignal;
}

export type ApiQueryOptions = ApiRequestOptions;
export type ApiMutationOptions = ApiRequestOptions;