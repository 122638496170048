import './menu.scss';
import '../input/buttons-v1-1.scss';

import React, { PropsWithChildren, ReactNode, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { MenuVDotsSVGIcon, MoreSVGIcon } from '../../components/svg';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// @ts-ignore
import CSSVariables from './menu.scss';

import { useOutsideClick } from '../../components/hooks';
import { ObjectLiteral } from '../../core';

export type MenuPopupProps = {

}

export const MenuPopup = observer((props: MenuPopupProps) => {

  return (<div className="menu-popup"></div>);
});




export type MenuButtonProps = PropsWithChildren<{
  layout?: 'horizontal' | 'vertical',
  className?: string | ObjectLiteral,
  svgIcon?: ReactNode,
  placement?: Placement,
}>;


const fadeTimeout = {
  enter: parseInt(CSSVariables.windowEnterTimeout),
  exit: parseInt(CSSVariables.windowExitTimeout)
};

export const MenuButton = observer((props: MenuButtonProps) => {

  const layout = props.layout || 'horizontal';
  const className = classNames(
    'btn btn-core btn-icon medium',
    props.className,
    props.layout || 'horizontal', {

  });

  const iconSvgElem = props.svgIcon ? (
    props.svgIcon
  ) : layout === 'vertical' ? (
    <MenuVDotsSVGIcon />
  ) : (
    <MoreSVGIcon />
  );

  const [isOpened, setIsOpened] = useState(false);

  const [popperElement, setPopperElement] = useState(null);
  const [anchorElement, setAnchorElement] = useState(null);
  const [arrowElement] = useState(null);

  const { styles, attributes } = usePopper(anchorElement, popperElement, {
    placement: props.placement || 'bottom-end',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement
        }
      },

      {
        name: 'offset',
        options: {
          offset: [0, 5],
        }
      },

      {
        name: 'flip',
        options: {
          fallbackPlacements: ['left', 'top', 'bottom'],
        },
      }
    ],
  });

  useOutsideClick(popperElement, () =>
    setIsOpened(false));

  return (
    <>
      <button className={className}
        ref={setAnchorElement as any}
        onClick={evt => {
          setIsOpened(!isOpened);
          evt.stopPropagation();
        }}>

        <span className="icon">
          {iconSvgElem}
        </span>
      </button>

      <TransitionGroup className="transition-group">
        {isOpened && (
          <CSSTransition
            classNames="menu-fade"
            timeout={fadeTimeout}>

            <div className="dropdown-menu menu-popup popup"
              ref={setPopperElement as any}
              style={styles.popper} {...attributes.popper}>
              <div className="popup-arrow" style={styles.arrow} />
              <div className="menu-items" onClick={evt => setIsOpened(false)}>
                {props.children}
              </div>
            </div>
          </CSSTransition>)}
      </TransitionGroup>
    </>
  )
});