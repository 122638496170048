import './svg.scss';
import React from 'react';
import { arc as d3arc } from 'd3-shape';
import clamp from 'lodash/clamp';

const INNER_RADIUS = 24;
const OUTER_RADIUS = 36;
const SIZE = OUTER_RADIUS * 2;
const VIEWBOX = `${-OUTER_RADIUS} ${-OUTER_RADIUS} ${SIZE} ${SIZE}`;

const arc = d3arc()
  .innerRadius(INNER_RADIUS)
  .outerRadius(OUTER_RADIUS)
  .cornerRadius(1.2)
  .padAngle(0.02);

type Props = {
  value: number
}

export function ArcSVGIcon(props: Props) {
  let { value } = props;
  value = clamp(value, 0, 1);

  // should be safe to reuse the static generator
  const arcPath = arc
    .startAngle(0)
    .endAngle(value * Math.PI * 2);

  return (
    <svg className="arg-svg-icon svg-icon dynamic-svg-icon" viewBox={VIEWBOX}>
      <path
        className="fill"
        key={value}
        // @ts-ignore (wrong typings, the path can be generated without any args)
        d={arcPath()} />
    </svg>
  )
}