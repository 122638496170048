import { action, makeObservable, observable, runInAction, computed } from 'mobx';
import isNil from 'lodash/isNil';
import { Message, StoreNode } from '../../store';
import { Nullable } from '../../core';
import { Store } from '../../store/store';
import { WindowState } from '../../components/overlays';
import { notifyError, notifySuccess } from '../../services/notifications';
import { JobModel } from '../../entities';

export type DeleteTrackOpenParams = {
  job: JobModel,
  jobId: string,
  trackId: string
}

export class DeleteTrackState
  extends StoreNode {

  readonly nodeType = 'DeleteTrackState';

  constructor(store: Store, props?: any) {
    super(store, props);
    makeObservable(this);
    this.window.listen(
      this.windowListener);
  }
  @observable job: Nullable<JobModel> = null
  @observable isLoading = false;
  @observable jobId: Nullable<string> = null;
  @observable trackId: Nullable<string> = null;

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close();
        break;
    }
  }
  readonly window = new WindowState(this.store);

  @action
  async deleteSpeaker() {
    if (!this.jobId || !this.trackId || !this.job) return;

    this.isLoading = true;
    const [, err] = await this.store.apiService.deleteTrack({
      jobId: this.jobId,
      trackId: this.trackId
    });

    if (err) {
      return this.handleError();
    } else {
      await this.job.fetchDependencies();
    }
    this.close();

    runInAction(() => {
      this.isLoading = false;
      notifySuccess(this, 'Swimlane removed.');
    })
  }

  @action
  handleError() {
    this.isLoading = false;
    notifyError(this, 'Could not delete swimlane.');
  }

  @action
  open(params: DeleteTrackOpenParams) {
    this.dispatch('Overlays', 'openWindow', { name: 'DeleteTrackPopup' });

    this.job = params.job;
    this.jobId = params.jobId;
    this.trackId = params.trackId;
  }

  @action
  close() {
    this.dispatch('Overlays', 'closeWindow');

    this.job = null;
    this.jobId = null;
    this.trackId = null;
  }

  @computed
  get isTrackEmpty() {
    const count = this.job?.tracks.find(({ id }) => id === this.trackId)?.moments.length;

    return !isNil(count) ? count <= 0 : true;
  }
}