import { action, IReactionOptions, observable, reaction } from 'mobx';
import { Maybe, Nullable } from '../core';

export type Props = {
  [key in PropKey]: any
}

export type PropKey = string;

export type BindingPropValueFunc<T, TContext = any> =
  (ctx: TContext, fallback?: T) => Maybe<T>;

/**
 * Represents a prop value which can be either a static value or a binding value.
 */
export type BindingPropValue<T, TContext = any> =
  BindingPropValueFunc<T, TContext> | Maybe<T>;

/**
 * Represents an object literal of properties which can be either static, binding or any combination of those.
 */
export type BindingProps<TProps, TContext = any> = {
  [key in keyof TProps]?: BindingPropValue<TProps[key], TContext>
}

export type PropChangeListener<T = any> =
  (val: T, prevVal: T) => void;
export type PropDiscardListener<T = any> =
  (val: T, nextVal: T) => void;

/** 
 * Manages static and dynamic props which enables the structured dependency sharing between nodes.
 */
export class PropManager<TProps extends { [key: string]: any } = any> {

  readonly bindingContext: any;

  constructor(props?: TProps, context?: any) {
    this.bindingContext = context || null;

    for (const key in props)
      this.set(key, props[key]);

    this.props = new Proxy({} as any, {
      get: (target, propName) => {
        if (typeof propName !== 'string')
          return null;
        return this.get(propName);
      },
      set: (target, propName, value) => {
        if (typeof propName !== 'string')
          return false;
        this.set(propName, value);
        return true;
      }
    });

    this.resolvedProps = new Proxy({} as any, {
      get: (target, propName) => {
        if (typeof propName !== 'string')
          return null;
        return this.getResolved(propName);
      },
      set: (target, propName) => {
        throw new Error(`You cannot change the value of a resolvedProp.`)
      }
    });
  }

  readonly propLookup = observable.map<PropKey, any>({}, { deep: false });

  readonly props: TProps;
  readonly resolvedProps: TProps;

  @action
  set<T extends keyof TProps & string>(name: T, val: BindingPropValue<TProps[T]>) {
    this.propLookup.set(name, val);
    return this;
  }

  /**
   * Gets the value of the target property as it was specified, without any kind of resolving.
   * An optional fallback value can be provided, which will be returned only if the property name cannot be found in the lookup.
   * This means that if you provided your prop as `null`, it will be returned as such, instead of the fallback.
   * This method always returns `null` instead of `undefined`.
   * @param name      The name of the property for which to get the unresolved value.
   * @param fallback  A value which will be returned if the property name cannot be found in the lookup.
   * @returns         The property value.
   * 
   * @example // Returning a specified value
   * manager.set('prop', 0)
   * manager.get('prop') // -> 0
   * 
   * @example // Returning a fallback when the value was not specified or deleted
   * manager.delete('prop')
   * manager.get('prop', 'fallback') // -> 'fallback'
   * 
   * @example // Returning a value which was set to undefined, instead of the fallback
   * manager.set('prop', undefined)
   * manager.get('prop', 'fallback') // -> null
   * 
   * @example // Returning the exact function when it is specified as the value
   * manager.set('prop', () => 'fn')
   * manager.get('prop') // -> () => 'fn' 
   */
  get<T extends keyof TProps & string>(name: T, fallback?: TProps[T]): BindingPropValue<TProps[T]> {
    if (!this.propLookup.has(name)) {
      if (fallback !== undefined)
        return fallback;
      else
        return null;
    }

    const propVal = this.propLookup.get(name);
    if (propVal === undefined)
      return null;
    return propVal;
  }

  getResolved<T extends keyof TProps & string>(name: T, fallback?: TProps[T]): Nullable<TProps[T]> {
    if (!this.propLookup.has(name)) {
      if (fallback !== undefined)
        return fallback;
      else
        return null;
    }

    const propVal = this.propLookup.get(name);
    return this.resolve(propVal, fallback);
  }

  delete<T extends keyof TProps & string>(name: T) {
    this.propLookup.delete(name);
    return this;
  }

  onChange(propName: string, listener: PropChangeListener) {
    const reactionOpts: IReactionOptions = {
      fireImmediately: true
    }

    reaction(() => this.getResolved(propName),
      (value, prevValue) =>
        listener(value, prevValue),
      reactionOpts);

    return this;
  }

  onDiscard(propName: string, listener: PropDiscardListener) {

    const reactionOpts: IReactionOptions = {
      fireImmediately: false
    }

    reaction(() => this.getResolved(propName),
      (value, prevValue) =>
        listener(prevValue, value),
      reactionOpts);

    return this;
  }

  private resolve<T extends keyof TProps & string>(
    arg: BindingPropValue<TProps[T]> | undefined,
    fallback: Maybe<TProps[T]>): TProps[T] | null {

    if (typeof arg === 'function')
      // @ts-ignore Please shed light on why a 'function' does not have a call signature
      return arg(this.bindingContext, fallback);
    if (arg !== undefined)
      return arg;

    return null;
  }
}
