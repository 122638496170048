import React from 'react';
import './playerTutorial.scss';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { Button } from '../input';
import { CheckSVGIconFill, CloseSVGIcon } from "../svg";
import { CheckboxInput } from '../input';
import { PlayerTutorialConfirmationWindowState } from './playerTutorialConfirmationWindowState';

type Props = {
  model: PlayerTutorialConfirmationWindowState;
  visible?: boolean;
};
export const PlayerTutorialConfirmationWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      className="start-tutorial-window"
      model={model.window}
      visible={props.visible}>

      <WindowHeader model={model.window} />

      <section className="window-content">
        <h1 className="start-tutorial-title">Welcome</h1>
        <p className="start-tutorial-subtitle">Can we show you how to use CLIPr?</p>
        <div className="start-tutorial-btn">
          <div className="action">
            <span className="action-name">Maybe Later</span>
            <Button
              className="btn-icon tutorial-btn medium red"
              onClick={() => model.watchLater()}
              leftIcon={<CloseSVGIcon />} />
          </div>
          <div className="action">
            <span className="action-name">Take the Tour</span>
            <Button
              className="btn-icon tutorial-btn medium green"
              onClick={() => model.initTutorial()}
              leftIcon={<CheckSVGIconFill />} />
          </div>
        </div>
      </section>

      {!model.isWidgetMode &&
        <>
          <hr></hr>
          <footer className="window-footer">
            <CheckboxInput
              name="playerTutorial"
              checked={!model.showPlayerTutorial}
              label={'Don’t show this next time'}
              onChange={(event) => { model.updateUserPermissions((event.target as HTMLInputElement).checked) }}
            />
          </footer>
        </>}
    </Window>
  )
});