import { IAnnotatedTimeRange } from '.';
import { ITimeRegion } from './timeSchema';

export type TimeRangeAdderOptions = Partial<{
  epsilon: number,
  includeEmptyRanges: boolean
}>

export type TimeRangeAdderItem = IAnnotatedTimeRange<{
  value: number
}>;

const DefaultOptions: TimeRangeAdderOptions = {
  epsilon: 0,
  includeEmptyRanges: false
}

export function addTimeRanges(
  input: (ITimeRegion | TimeRangeAdderItem)[],
  opts: TimeRangeAdderOptions = DefaultOptions): TimeRangeAdderItem[] {

  const epsilon = opts.epsilon ?? 0;
  const includeEmptyRanges = opts.includeEmptyRanges ?? false;

  if (!input || input.length === 0)
    return [];

  type Breakpoint = {
    time: number,
    startCount: number,
    endCount: number
  }

  const breakpoints: Breakpoint[] = [];

  for (const range of input) {

    let delta = 1;
    if ('timeRangeMetadata' in range) {
      const metaVal = range.timeRangeMetadata.value;
      delta = Number.isFinite(metaVal) ? metaVal : 1;
    }

    breakpoints.push({
      time: range.startTime,
      startCount: delta,
      endCount: 0
    });

    breakpoints.push({
      time: range.endTime,
      startCount: 0,
      endCount: delta
    });
  }

  // sort the breakpoints in place
  breakpoints.sort((a, b) => a.time - b.time);

  // reduce the breakpoints into a list of merged breakpoints, grouping by time
  // rather than using something like lodash's groupBy, we know that the array is already sorted
  // and for performance we can just compare the current time with the previous breakpoint time
  // and only create new breakpoints when the values differ
  const clusteredBreakpoints: Breakpoint[] = [];

  // keep a cursor which will accumulate on startCount and endCount and will be used
  // to compare time on each iteration, to decide when to create new breakpoints
  let activeBkp: Breakpoint = {
    ...breakpoints[0]
  }
  clusteredBreakpoints.push(activeBkp);

  for (let i = 1; i < breakpoints.length; i++) {
    const bkp = breakpoints[i];
    if (bkp.time > (activeBkp.time + epsilon)) {
      // next clustered 
      activeBkp = {
        ...bkp
      };
      clusteredBreakpoints.push(activeBkp);
    } else {
      // same cluster, increment counters
      activeBkp.startCount += bkp.startCount;
      activeBkp.endCount += bkp.endCount;
    }
  }

  if (clusteredBreakpoints.length < 2)
    // probably one or more regions with the same startTime and endTime were provided
    return [];

  let regions: TimeRangeAdderItem[] = [];
  let countCursor = 0;

  // iterate again through the breakpoints and start building the regions
  for (let i = 0; i < clusteredBreakpoints.length - 1; i++) {

    const startBkp = clusteredBreakpoints[i];
    const endBkp = clusteredBreakpoints[i + 1];

    const startTime = startBkp.time;
    const endTime = endBkp.time;
    const count = startBkp.startCount - startBkp.endCount + countCursor;

    countCursor = count;

    const region: TimeRangeAdderItem = {
      startTime,
      endTime,
      timeRangeMetadata: {
        value: count
      }
    }

    regions.push(region);
  }

  if (!includeEmptyRanges) {
    regions = regions.filter(reg => reg.timeRangeMetadata.value > 0);
  }

  return regions;
}