/**
 * Returns true if the provided object is an instance of DOMException
 * and the error is an AbortError.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/DOMException#exception-AbortError
 */
export function isAbortError(err: any): err is DOMException {

  return (
    (err instanceof DOMException) && (
      // the code and constant appear in MDN as "legacy", so currently I'm unsure
      // whether they will work in every browser or not and if they're futureproof against deprecation
      // this is why the check is so verbose
      (err.code === DOMException.ABORT_ERR) ||
      (err.name === 'AbortError') ||
      (err.message === 'Aborted')));
}