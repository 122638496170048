import React from 'react';
import { observer } from 'mobx-react-lite';
import { DEFAULT_MAX_TAGS_NUMBER, TagSectionState } from './tagSectionState';
import { ExpandToggle } from '../../pages/uploadPage/sharedComponents/expandToggle';
import './tagSection.scss';
import classNames from 'classnames';

type Props = {
  model: TagSectionState;
}

export const TagSection = observer(({ model }: Props) => {
  const { tags, activeTags, isExpanded, isExpandable } = model;

  return (
    <>
      {tags.length > 0 &&
        <div className="tags-container">
          {tags.map((tag, index) => {
            if (index >= DEFAULT_MAX_TAGS_NUMBER && !isExpanded)
              return null;

            const isActive = activeTags.includes(tag);
            const className = classNames(
              'btn-core',
              'btn',
              'tag-chip',
              { 'active': isActive });

            return (
              <button
                key={`${tag}_${index}`}
                type="button"
                onClick={() => {
                  model.manageActiveTag(tag)
                }}
                className={className}>
                <span className="value">#{tag}</span>
              </button>)
          })}

          {isExpandable &&
            <ExpandToggle
              onExpand={() => model.toggleExpandedSection()}
              isExpanded={isExpanded}
              className="expand-btn" />}
        </div>}
    </>
  )
});