import { useEffect, useRef, useState } from 'react';

export function useRefState<T>(initialValue: T) {

  const [state, setState] = useState(initialValue);
  const ref = useRef(state);

  const setReferredState = (value: T) => {

    if (typeof value === 'function') {

      let oldValue;
      setState(oldVal => {
        oldValue = oldVal;
        return value(oldVal)
      });
      ref.current = value(oldValue);

    } else {
      setState(value);
      ref.current = value;
    }
  };

  return [state, ref, setReferredState];
}

export function useOutsideClick(node: HTMLElement | undefined | null, callback: Function, ignoredTargetOnOutsideClick?: HTMLElement | undefined | null) {

  useEffect(() => {

    function handleOutsideClick(evt: MouseEvent) {

      if (node &&
        !node.contains(evt.target as Node) &&
        !ignoredTargetOnOutsideClick?.contains(evt.target as Node) &&
        // TODO: validate. 
        // This is needed because some click event handlers might trigger the clicked element to be unmounted (see the speaker input group buttons)
        (evt.target as Node)?.isConnected &&
        typeof callback === 'function') {

        callback(evt);

      }
    }

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [node, callback]);
}

export function useOutsideScroll(node: HTMLElement | undefined | null, callback: Function) {

  useEffect(() => {

    function handleOutsideScroll(evt: Event) {

      if (node &&
        !node.contains(evt.target as Node) &&
        // TODO: validate. 
        // This is needed because some click event handlers might trigger the clicked element to be unmounted (see the speaker input group buttons)
        (evt.target as Node)?.isConnected &&
        typeof callback === 'function') {

        callback(evt);

      }
    }

    document.addEventListener('wheel', handleOutsideScroll);
    return () => {
      document.removeEventListener('wheel', handleOutsideScroll);
    };
  }, [node, callback]);
}

// mousedown seems to work better than click for catching outside clicks in certain scenarios (i.e. stopPropagation)
export function useOutsideMouseDown(node: HTMLElement | HTMLElement[] | undefined | null, callback: Function) {

  useEffect(() => {

    function handleOutsideClick(evt: MouseEvent) {
      if (node &&
        Array.isArray(node) &&
        node[0]) {
        const filteredNodes: HTMLElement[] = node.filter((element: HTMLElement) => element.contains(evt.target as Node));
        if (filteredNodes.length === 0 &&
          (evt.target as Node)?.isConnected &&
          typeof callback === 'function') {
          callback(evt);
        }
      }


      if (node &&
        !Array.isArray(node) &&
        !node.contains(evt.target as Node) &&
        // TODO: validate. 
        // This is needed because some click event handlers might trigger the clicked element to be unmounted (see the speaker input group buttons)
        (evt.target as Node)?.isConnected &&
        typeof callback === 'function') {

        callback(evt);

      }
    }

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [node, callback]);
}

export function useWindowResize() {
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();

  useEffect(() => {
    function handleWindowResize() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleWindowResize);

    handleWindowResize();

    return () => window.removeEventListener('resize', handleWindowResize);

  }, [])

  return [width, height];
}