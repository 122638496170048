import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { CreateTeamFormState } from '../createTeamForm/createTeamFormState';
import { TeamSettingsPageState } from '../../pages/teamSettingsPage';
import { PhotoSVGIcon, TeamsSVGIcon } from '../svg';
import { Button } from '../input';

type UploadAvatarProps = {
  model: CreateTeamFormState | TeamSettingsPageState;
  disabled?: boolean;
};

export const UploadAvatarInput = observer(
  ({ model, disabled }: UploadAvatarProps) => {
    const inputRef = useRef(null);

    const onClick = () => {
      const input = inputRef.current;
      if (input) {
        // @ts-ignore
        input.click();
      }
    };

    return (
      <div className="upload-avatar-group">
        <div className="upload-avatar team-avatar">
          {model.avatar ? (
            <img className="avatar-thumb" src={model.avatar} alt="avatar" />
          ) : (
            <TeamsSVGIcon />
          )}
        </div>
        {!disabled && (
          <Button
            className="upload-photo-button small secondary"
            leftIcon={<PhotoSVGIcon />}
            onClick={onClick}
          />
        )}
        <input
          ref={inputRef}
          type="file"
          className="file-input"
          role="presentation"
          hidden
          onChange={(e) => model.handleFileInput(e)}
        />
      </div>
    );
  }
);
