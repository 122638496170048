import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { AvatarSVGIcon, LoadMask } from '..';
import { HTMLDivProps, Maybe } from '../../core';
import { MicrophoneSVGIcon } from '../player/components/playerSvg';

type PlayerSpeakerAvatarLayout =
  'idle' |
  'default' |
  'active' |
  'speaking';

interface ISpeakerAvatar {
  displayName?: Maybe<string>,
  pictureURL?: Maybe<string>
}

type Props = HTMLDivProps<{
  model?: Maybe<ISpeakerAvatar>,
  isLoading?: boolean,
  showName?: boolean,
  layout?: PlayerSpeakerAvatarLayout,
  onLoad?: () => void,
  onClick?: () => void,
}>;

/**
 * Displays the avatar for the provided Speaker.
 */
export const PlayerSpeakerAvatar = observer(({
  model,
  className,
  isLoading,
  showName = false,
  layout = 'default',
  onLoad,
  onClick
}: Props) => {

  const picture = model?.pictureURL;
  const displayName = model?.displayName;

  className = classNames('player-speaker-avatar avatar-core', layout, {
    // 'blue': !picture,
    'empty': !picture
  }, className);

  return (
    <div className={className} onClick={() => onClick && onClick()}>
      {picture ? (
        <img className="profile-picture" src={picture} alt={model?.displayName || 'Speaker avatar'} onLoad={() => onLoad && onLoad()} />
      ) : (
          <div className="empty-icon"><AvatarSVGIcon /></div>
        )}
      {layout === 'speaking' && <div className="status-icon"><MicrophoneSVGIcon /></div>}
      {showName && displayName && <div className="name">{displayName}</div>}
      <LoadMask className={'avatar-load-mask spinner small'} spinner={'dots3'} visible={isLoading} />
    </div>
  );
});
