import { makeObservable } from 'mobx';
import { Store } from '../../../store/store';
import { StoreNode } from '../../../store';
import { RouteContext } from '../../../routes/routeContext';
import { AuthFlowName, AuthFlowResponse, IAuthFlow } from '../authFlowSchema';
import { AsyncResult } from '../../../core';
import { AuthStepOrchestrator } from '../authStepOrchestrator';

type Props = {
  routeContext: RouteContext;
}

export type ProxyLoginFlowParams = Props;

export class ProxyLoginFlow
  extends StoreNode
  implements IAuthFlow {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);
  }

  readonly flowName = AuthFlowName.ProxyLogin;

  get routeContext(): RouteContext {
    return this.props.routeContext;
  }

  readonly orchestrator = new AuthStepOrchestrator(this.store);

  async run(): AsyncResult<AuthFlowResponse> {

    const { orchestrator } = this;

    const [, proxyErr] = await orchestrator.proxyAuthorize();
    if (proxyErr)
      return orchestrator.setError(proxyErr);

    const [, contextErr] = await orchestrator.initContextFromPermit();
    if (contextErr)
      return orchestrator.setError(contextErr);

    // previously we were checking permissions here
    // however, as the route is currently set up, the permissions will always be allowed
    // since there is no injector on the actual proxy login route

    // todo: add error checking
    const [, commitErr] = orchestrator.commit();
    if (commitErr)
      return orchestrator.setError(commitErr);

    return orchestrator.setRedirectToLastWidgetRoute();
  }
}