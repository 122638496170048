import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { Button } from '../input';
import { useStore } from '../../store/storeHooks';
import { InfoSVGIcon } from '../svg';
import { InfoModalProps } from './infoModalState';

type Props = InfoModalProps & {
  className?: string;
  size?: 'default' | 'small' | 'large';
  windowClassName?: string
};

export const InfoButton = observer(
  ({ title, content, className, size = 'default', windowClassName }: Props) => {
    const store = useStore();
    className = classNames('info-button', size, className);

    return (
      <Button
        className={className}
        icon={<InfoSVGIcon />}
        onClick={(evt) => {
          evt.stopPropagation();
          store.infoModal.open({
            title,
            content,
            windowClassName
          })
        }}
      />
    );
  }
);
