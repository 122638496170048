import '../authPages.scss';

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import {
  BasicPage,
  TextInputControlled,
  ButtonControlled,
  Feedback,
  LoadMask,
  CliprLogoSVGIcon,
  DropdownInputControlled
} from '../../../components';

import { OnboardPageState } from './onboardPageState';
import { useRouteContext } from '../../../routes/routeHooks';
import { AuthFlowResponseType } from '../../../services/auth/authFlowSchema';
import { AuthFlowResponseInterpreter } from '../../../components/auth';

type Props = {
  model: OnboardPageState
};

export const OnboardPage = observer(({
  model
}: Props) => {

  const routeContext = useRouteContext();
  useEffect(() => {
    model.attached(routeContext);
    return () =>
      model.detached();
  }, [model, routeContext]);

  const { form } = model;
  const response = form.response;

  if (response) {
    switch (response.responseType) {
      case AuthFlowResponseType.RedirectToLastPrivateRoute:
        return <AuthFlowResponseInterpreter response={response} />
    }
  }

  return (
    <BasicPage
      className="register-page auth-page dock poster"
      showHeader={false}
      showWatermark={false}>

      <div className="auth-splash">
        <div className="login-poster auth-poster">
          <div className="clipr-logo">
            <CliprLogoSVGIcon />
          </div>
          <div className="headline">
            <h1>Welcome to CLIPr!</h1>
            <h2>Review just the key moments of video meetings.</h2>
          </div>
        </div>
        <div className="auth-container">
          <div className="auth-panel">
            <header className="auth-header">
              {false && <div className="clipr-logo">
                <CliprLogoSVGIcon />
              </div>}
              <h2 className="vpad2">Before we start, tell us a <br/> bit about yourself.</h2>
            </header>

            <form id="onboardForm"
              className="auth-form"
              onSubmit={form.handleSubmit}>

              <div className="row">
                <Feedback model={form.formGroup} className="center" />
              </div>

              <div className="row">
                <TextInputControlled
                  id="onboardForm_username"
                  label="Username (required)"
                  placeholder="Enter your username"
                  model={form.username} />
              </div>

              <div className="row">
                <TextInputControlled
                  id="onboardForm_firstName"
                  label="First Name (required)"
                  placeholder="Enter your first name"
                  model={form.firstName} />
              </div>

              <div className="row">
                <TextInputControlled
                  id="onboardForm_lastName"
                  label="Last Name"
                  placeholder="Enter your last name"
                  model={form.lastName} />
              </div>

              <div className="row">
                <DropdownInputControlled
                  id="onboardForm_industry"
                  label="Industry"
                  placeholder="Select your industry"
                  model={form.industry} />
              </div>

              <div className="row">
                <DropdownInputControlled
                  id="onboardForm_companySize"
                  label="Company size"
                  placeholder="Select your company size"
                  model={form.companySize} />
              </div>

              <div className="row">
                <TextInputControlled
                  id="onboardForm_jobTitle"
                  label="Job Title"
                  placeholder="Your job title"
                  model={form.jobTitle} />
              </div>

              <div className="row vpad3 btn-group fill form-footer">
                <ButtonControlled
                  id="onboardForm_submit"
                  model={form.submitButton}
                  className="submit-btn primary light"
                  type="submit"
                  label="Continue" />
              </div>

              {/* <p className="text-center">
                <Link to={Routes.logout()}>Sign in</Link> with a different account
              </p> */}
            </form>

            <LoadMask
              className="partial"
              visible={form.showLoadMask}
              spinner="dots5" />
          </div>
        </div>

        {/* <div className="register-poster auth-poster">
          <h1 className="headline">Review just the key moments of video meetings.</h1>
        </div> */}
      </div>
    </BasicPage>
  );
});

export default OnboardPage;