import '../player.scss';

import React, { CSSProperties } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { MomentModel } from '../../../entities';
import { useAnchor } from '../../../services/overlays';
import { PlayerState } from '../playerState';
import { cssPercentage, Maybe, ObjectLiteral } from '../../../core';

type Props = {
  model: PlayerState,
  moment: MomentModel,
  className?: Maybe<string | ObjectLiteral>
}

export const PlayerMoment = observer(({
  // model,
  moment,
  className
}: Props) => {

  // const { momentView } = model;
  className = classNames('player-moment', {

    // 'next': (
    //   moment === momentView.nextGenericMoment ||
    //   moment === momentView.nextTopicMoment),

    // 'topic': moment.isTopic,
    // 'generic': moment.isGeneric,
    // 'transcript': moment.isTranscript,
    // 'speaker': moment.hasSpeaker,
  }, className);

  const [anchorRef] = useAnchor({
    target: moment,
    id: 'userPlayerMoment#' + moment.id
  });

  const style: CSSProperties = {
    width: cssPercentage(moment.durationRatio),
    left: cssPercentage(moment.startTimeRatio)
  }

  return (
    <div
      ref={anchorRef as any}
      className={className}
      style={style} />
  );
});