import { Location } from 'history';
import { RouteProps } from 'react-router-dom';
import { Routes } from '../../../routes/routes';
import {
  IAnalyticsStream,
  AnalyticsStreamParams,
  AnalyticsEventTypes,
} from '../analyticsSchema';
import { AnalyticsStream, AnalyticsMainData } from './analyticsStream';

export type AnalyticsPageViewStreamParams = RouteProps;

export type AnalyticsPageViewStreamData = {
  location: Location | undefined;
} & AnalyticsMainData;

export class AnalyticsPageViewStream extends AnalyticsStream
  implements IAnalyticsStream<keyof AnalyticsStreamParams> {
  static streamName = AnalyticsEventTypes.PageViewType;

  exchangeData(
    data: AnalyticsPageViewStreamParams
  ): AnalyticsPageViewStreamData | null {
    const { location, path } = data;

    switch (path) {
      case Routes.auth:
      case Routes.oauthCallback():
      case Routes.oauthLogout():
      case Routes.oauthVerified():
      case Routes.oauthPassword():
      case Routes.oauthOneDriveCallback():
      case Routes.oauthGoogleDriveCallback():
      case Routes.oauthZoomCallback():
        return null;
      default:
        return {
          location,
          ...this.mainData,
          ...this.viewContextData
        };
    }
  }
}
