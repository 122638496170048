/**
 * Expresses the current state of the element with respect to rendering the current playback position, from the codes in the list below.
 * https://html.spec.whatwg.org/multipage/media.html#ready-states
 */
export enum HTMLMediaReadyState {
  /** 
   * No information is available about the media resource. 
   */
  HAVE_NOTHING = 0,

  /** 
   * Enough of the media resource has been retrieved that the metadata attributes are initialized. 
   * Seeking will no longer raise an exception. 
   */
  HAVE_METADATA = 1,

  /**
   * Data is available for the current playback position, but not enough to actually play more than one frame. 
   */
  HAVE_CURRENT_DATA = 2,

  /** 
   * Data for the current playback position as well as for at least a little bit of time into the future is available  
   * (in other words, at least two frames of video, for example).
   */
  HAVE_FUTURE_DATA = 3,

  /** 
   * Enough data is available—and the download rate is high enough—that the media 
   * can be played through to the end without interruption. 
   */
  HAVE_ENOUGH_DATA = 4
}

/**
 * Expresses the current state of network activity for the element, from the codes in the list below.
 * https://html.spec.whatwg.org/multipage/media.html#network-states
 */
export enum HTMLMediaNetworkState {
  /**
   * The element has not yet been initialized. All attributes are in their initial states.
   */
  NETWORK_EMPTY = 0,

  /**
   * The element's resource selection algorithm is active and has selected a resource, but it is not actually using the network at this time.
   */
  NETWORK_IDLE = 1,

  /**
   * The user agent is actively trying to download data.
   */
  NETWORK_LOADING = 2,

  /**
   * The element's resource selection algorithm is active, but it has not yet found a resource to use.
   */
  NETWORK_NO_SOURCE = 3
}

export type HTMLMediaEventType =
  'abort' |          // Fired when the resource was not fully loaded| but not as the result of an error 
  'canplay' |        // The browser can play the media| but estimates that not enough data has been loaded to play the media up to its end without having to stop for further buffering of content.
  'canplaythrough' | // The browser estimates it can play the media up to its end without stopping for content buffering.
  'complete' |       // The rendering of an OfflineAudioContext is terminated.
  'durationchange' | // The duration attribute has been updated.
  'emptied' |        // The media has become empty; for example| this event is sent if the media has already been loaded (or partially loaded)| and the load() method is called to reload it.
  'ended' |          // Playback has stopped because the end of the media was reached.
  'error' |          // Fired when the resource could not be loaded due to an error.
  'loadeddata' |     // The first frame of the media has finished loading.
  'loadedmetadata' | // The metadata has been loaded.
  'loadstart' |      // Fired when the browser has started to load a resource.
  'pause' |          // Playback has been paused.
  'play' |           // Playback has begun.
  'playing' |        // Playback is ready to start after having been paused or delayed due to lack of data.
  'progress' |       // Fired periodically as the browser loads a resource.
  'ratechange' |     // The playback rate has changed.
  'seeked' |         // A seek operation completed.
  'seeking' |        // A seek operation began.
  'stalled' |        // The user agent is trying to fetch media data| but data is unexpectedly not forthcoming.
  'suspend' |        // Media data loading has been suspended.
  'timeupdate' |     // The time indicated by the currentTime attribute has been updated.
  'volumechange' |   // The volume has changed.
  'waiting';         // Playback has stopped because of a temporary lack of data