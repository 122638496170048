import { action, computed, makeObservable } from 'mobx';
import { input, inputGroup, InputGroupState } from '..';
import { StoreNode } from '../../../store';
import { Store } from '../../../store/store';
import {
  generateHourSelector,
  generateMinuteSelector,
  generateTimezoneSelector,
  getLabel,
  getLabelForOffset,
  getOffset,
  guessTimezone
} from './timeinputUtils';

export type TimeInputProps = {
  timezone?: string;
  hour?: string;
  minutes?: string;
  disabled?: boolean;
  isRequired?: boolean;
}

// TODO: We need to tighten a few things up on this component logic. After the release of course.
export class TimeInputState
  extends StoreNode {
  readonly nodeType = 'TeamInputState';

  constructor(store: Store, props: TimeInputProps) {
    super(store, props);
    makeObservable(this);

    this.inputGroup = inputGroup(this, {
      name: 'time',
      inputs: [
        this.timezone,
        this.hour,
        this.minutes
      ]
    });
  }

  @computed
  get disabled(): boolean {
    return this.resolvedProps.disabled ?? false;
  }

  @computed
  get required(): boolean {
    return this.resolvedProps.isRequired ?? false;
  }

  readonly timezone = input(this, {
    name: 'timezone',
    isRequired: this.required,
    selectorItems: () => generateTimezoneSelector(),
    onChange: () => {
      this.hour.setProp('isRequired', true);
      this.minutes.setProp('isRequired', true)
    },
    disabled: this.disabled,
    placeholder: '-'
  });

  readonly hour = input(this, {
    name: 'hours',
    isRequired: this.required,
    selectorItems: () => generateHourSelector(),
    onChange: () => {
      this.timezone.setProp('isRequired', true);
      this.minutes.setProp('isRequired', true)
    },
    disabled: this.disabled,
    placeholder: '--'
  });

  readonly minutes = input(this, {
    name: 'minutes',
    isRequired: this.required,
    selectorItems: () => generateMinuteSelector(),
    onChange: () => {
      this.hour.setProp('isRequired', true);
      this.timezone.setProp('isRequired', true)
    },
    disabled: this.disabled,
    placeholder: '--'
  });

  readonly inputGroup: InputGroupState;


  // This solution needs to be reevaluated!!
  @action
  setRequired(isRequired: boolean) {
    this.hour.setProp('isRequired', isRequired);
    this.minutes.setProp('isRequired', isRequired);
    this.timezone.setProp('isRequired', isRequired);
  }

  @action
  loadTimezone(offset: number | null) {
    if (offset === null || typeof offset === 'undefined') {
      const systemTimezone = guessTimezone();
      const systemTimezoneLabel = getLabel(systemTimezone);
      const systemTimezoneOffset = getOffset(systemTimezone);

      this.timezone.loadValue(systemTimezoneOffset?.toString());
      this.timezone.setProp('label', systemTimezoneLabel?.split(' ')[0].replace(/[{()}]/g, ''));
      return;
    }

    this.timezone.loadValue(offset.toString());
    this.timezone.setProp('label', getLabelForOffset(offset));
  }

  @action
  loadHour(hour: string | null) {
    this.hour.loadValue(hour);
  }

  @action
  loadMinutes(minutes: string | null) {
    this.minutes.loadValue(minutes);
  }

  @action
  reset() {
    this.inputGroup.reset();
  }
}

export const timeInput = (node: StoreNode | Store, props: TimeInputProps) => {
  let store: Store;
  if (node instanceof StoreNode)
    store = node.store;
  else
    store = node;

  return new TimeInputState(store, props);
}
