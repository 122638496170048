import './player.scss';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { PlayheadTipSVGGlyph } from '../svg/svg';
import { cssPercentage, ObjectLiteral } from '../../core';
import { RefProxy } from '../../store/refProxy';
import classNames from 'classnames';

type Props = React.HTMLProps<HTMLDivElement> & {
  position: number,
  className?: string | ObjectLiteral,
  proxyRef?: RefProxy<HTMLElement>
};

export const Playhead = observer(({
  proxyRef,
  position,
  className,
  ...props
}: Props) => {

  const style = {
    left: cssPercentage(position)
  }

  className = classNames("playhead", className);

  return (
    <div ref={proxyRef?.ref} className={className} style={style} {...props}>
      <div className="playhead-bar" />
      <div className="playhead-tip">
        <PlayheadTipSVGGlyph />
      </div>
    </div>
  );
});