import { match } from 'react-router-dom';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import Routes from '../../routes';
import { Team } from '../../entities/team';

type RedirectType = null | 'url' | 'team' | 'userDashboard' | 'onboardTeam';

interface RouteMatch extends match {
  params: {
    teamId: string;
  };
}

export class DefaultPageState
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  @observable isLoaded: boolean = false;
  @observable lastVisitedLibrary: RouteMatch | null = null;
  @observable redirectTo: RedirectType = null;
  @observable firstTeam: Team | null = null;

  @action
  async pageMounted() {
    this.lastVisitedLibrary = this.store.storage.getLocal(
      'router.lastVisitedLibrary',
      true
    );
    const showOnboarding = this.store.storage.getLocal(
      'user.showOnboarding',
    );

    await this.store.teamManager.apiEnsureTeams();

    if (this.lastVisitedLibrary) {
      const { params, path } = this.lastVisitedLibrary;

      if (showOnboarding) {
        this.redirectTo = 'onboardTeam';
      } else if (this.shouldRedirectToLastVisitedLibrary(path, params.teamId)) {
        this.redirectTo = 'url';
      } else {
        this.store.storage.removeLocal('router.lastVisitedLibrary');
      }
    } else {
      this.firstTeam = this.store.teamManager.teams[0];

      if (showOnboarding) {
        this.redirectTo = 'onboardTeam';
      } else if (this.firstTeam && this.firstTeam.id) {
        this.redirectTo = 'team';
      } else if (this.store.user?.hasPermission('ViewUserDashboard')) {
        this.redirectTo = 'userDashboard';
      }
    }

    runInAction(() => {
      this.isLoaded = true;
    });
  }

  @action
  pageUnmounted() {
    this.firstTeam = null;
    this.redirectTo = null;
    this.isLoaded = false;
  }

  shouldRedirectToLastVisitedLibrary(
    path: string,
    teamId: string
  ): boolean {
    if (
      teamId &&
      path === Routes.teamDashboard() &&
      this.store.teamManager.hasTeam(teamId) &&
      this.store.teamManager.getTeam(teamId)?.hasPermission('ViewTeam')
    ) {
      return true;
    } else if (
      path === Routes.trainerDashboard() &&
      this.store.user?.hasPermission('ViewTrainerDashboard')
    ) {
      return true;
    } else if (
      path === Routes.userDashboard() &&
      this.store.user?.hasPermission('ViewUserDashboard')
    ) {
      return true;
    } else if (
      path === Routes.adminTeamsDashboard() &&
      this.store.user?.hasPermission('AdminGetTeams')
    ) {
      return true;
    }
    return false;
  };
}