
import {
  action,
  makeObservable,
  observable,
  computed
} from 'mobx';
import { Store } from '../../store/store';
import { StoreNode, BindingProps } from '../../store';
import {
  input,
  inputGroup,
  InputGroupState,
  InputState
} from '../input';
import { ApiVariables } from '../../api/apiSchema';
import { assertNotNull } from '../../core';
import { TeamMemberRoleInput } from '@clipr/lib';
import { Team } from '../../entities/team';

import isEmail from 'validator/lib/isEmail';
import { RoleItem } from './createTeamFormState';

type InviteListStateProps = BindingProps<{
  roleItems: RoleItem[],
  checkInviteValidity: (email: string) => string | null
}>

function checkDisabled(input: any, disable: boolean) {
  return (input.loading ||
    input.isSubmitting ||
    disable)
}

export class AddInviteListState
  extends StoreNode {

  readonly includeInviteMessage: InputState;
  readonly inviteMessage: InputState;
  readonly inviteListInputGroup: InputGroupState;
  readonly includeInviteMessageGroup: InputGroupState;
  readonly inviteInputGroup: InputGroupState;

  @observable isLocked = false;

  constructor(store: Store, props?: InviteListStateProps) {
    super(store, props);
    makeObservable(this);
    this.addInvite();
    this.includeInviteMessage = input(this, {
      name: 'includeInviteMessage',
      disabled: input => checkDisabled(input, this.isLocked)
    });
    this.inviteMessage = input(this, {
      name: 'inviteMessage',
      multiline: true,
      label: 'Message',
      isRequired: () => {
        return !this.includeInviteMessage.isEmpty
      },
      disabled: input => checkDisabled(input, this.isLocked)
    });

    this.inviteListInputGroup = inputGroup(this, {
      name: 'inviteListInputGroup',
      inputs: () =>
        this.inviteList.map((el: any) => el.lineGroup),
      disabled: (input: any) => {
        return (
          input.hasLoadingInputs ||
          input.isSubmitting ||
          this.isLocked
        )
      }
    })
    this.includeInviteMessageGroup = inputGroup(this, {
      name: 'includeInviteMessageGroup',
      inputs: [
        this.includeInviteMessage,
        this.inviteMessage,
      ],
      disabled: input => checkDisabled(input, this.isLocked)
    })
    this.inviteInputGroup = inputGroup(this, {
      name: 'inviteInputGroup',
      inputs: [
        this.inviteListInputGroup,
        this.includeInviteMessageGroup,
      ],
      disabled: input => checkDisabled(input, this.isLocked)
    })
  }

  @observable inviteList = observable.array();

  @computed
  get hasError() {
    return !!this.inviteList.find(i => i.error);
  }

  @computed
  get isEmpty() {
    return !this.inviteList.find(i => i.emailInput.value !== null);
  }

  checkIsDuplicateInvite = (email: string) => {
    return this.inviteList.filter(i => i.emailInput.value?.trim() === email).length > 1;
  }

  checkInviteValidity = (email: string) => {
    if (this.checkIsDuplicateInvite(email))
      return 'Duplicate email address';

    if (this.props?.checkInviteValidity)
      return this.props?.checkInviteValidity(email);

    return null;
  }

  @action
  addInvite = () => {
    const addInviteState: AddInviteState = new AddInviteState(this.store, {
      checkInviteValidity: this.checkInviteValidity,
      roleItems: this.props?.roleItems || []
    });
    this.inviteList.push(addInviteState);
  }

  @action
  removeInvite = (i: number) => {
    this.inviteList.splice(i, 1);
  }

  @action
  reset() {
    this.inviteList.clear();
    this.addInvite();
  }

  @action
  async submitInvites(team: Team) {
    if (!team)
      return;

    const message = this.inviteMessage.value!;
    const teamId = team.id;

    assertNotNull(teamId);

    const res = await Promise.all([...this.inviteList.map(async invite => {

      var email: string = invite.emailInput.value?.trim();
      var role: TeamMemberRoleInput = invite.roleInput.value;

      if (!email || !role || !team)
        return [null, null];
      // return [null, 'Prerequisite fail'];

      assertNotNull(email);
      assertNotNull(role);

      const vars: ApiVariables<'inviteTeamMember'> = {
        teamId,
        email,
        role
      };

      if (this.includeInviteMessage.value && message)
        vars.message = message;

      invite.setSyncing(true);
      return team.apiInviteTeamMember(vars);

    })])

    const hasError = res.some(([_, err]) => !!err);

    res.forEach((el, i) => {
      const [res, err] = el;
      this.inviteList[i].setSyncing(false);
      if (err) {
        this.inviteList[i].setSynced(false);
        this.inviteList[i].setError(err.message);
      } else if (res) {
        this.inviteList[i].setSynced(true);
        this.inviteList[i].setError(null);
      }
    })

    return hasError;
  }

  @action
  lock() {
    this.isLocked = true;
    this.inviteList.forEach(item => item.lock());
  }

  @action
  unlock() {
    this.isLocked = false;
    this.inviteList.forEach(item => item.unlock());
  }

}

type InviteStateProps = BindingProps<{
  checkInviteValidity: (email: string) => string | null,
  roleItems: RoleItem[]
}>

export class AddInviteState
  extends StoreNode {

  readonly lineGroup: InputGroupState;
  @observable isSyncing = false;
  @observable isSynced = false;
  @observable isLocked = false;

  @observable error: string | null = null;

  constructor(store: Store, props?: InviteStateProps) {
    super(store, props);
    makeObservable(this);

    this.emailInput = input(this, {
      name: 'email',
      isRequired: false,
      placeholder: 'Email Address',
      disabled: (input: any) => {
        return this.lineGroup.disabled || this.isLocked
      },
      error: input => {
        if (this.isSynced)
          return null;

        const error = this.props?.checkInviteValidity(input.value?.trim());

        if (input.isRequired && input.isEmpty)
          return 'Required field';

        if (!input.isEmpty && !isEmail(input.value!.trim()))
          return 'Invalid email address format';

        if (!input.isEmpty && error)
          return error;

        return null;
      }
    });
    this.roleInput = input(this, {
      name: 'role',
      isRequired: () => {
        return !this.emailInput.isEmpty
      },
      showStatus: (input: any) => {
        return input.isTouched || input.loading || this.emailInput.isTouched
      },
      showStatusMessage: (input: any) => {
        return input.isTouched || input.loading || this.emailInput.isTouched
      },
      disabled: input => checkDisabled(input, this.isLocked),
      placeholder: 'Role',
      selectorItems: () => this.roleItems
    });

    this.lineGroup = inputGroup(this, {
      name: 'inviteLine',
      inputs: [
        this.emailInput,
        this.roleInput
      ],
      disabled: (input: any) => {
        return (
          input.hasLoadingInputs ||
          input.isSubmitting ||
          this.isSynced ||
          this.isSyncing ||
          this.isLocked
        )
      }
    });
  }

  readonly emailInput: InputState;
  readonly roleInput: InputState;

  @computed
  get roleItems() {
    return this.resolvedProps.roleItems
  }

  @action
  setSyncing(value: boolean) {
    this.isSyncing = value;
  }

  @action
  setSynced(value: boolean) {
    this.isSynced = value;
  }

  @action
  setError(value: string | null) {
    this.error = value;
  }

  @action
  lock() {
    this.isLocked = true;
  }

  @action
  unlock() {
    this.isLocked = false;
  }
}