import { AsyncResult } from '../../../core';
import { AuthStepOrchestrator } from '../authStepOrchestrator';
import { AuthPermit } from '../authPermit';
import { AuthError } from '../authError';
import { UserProfile, UserProfileAvatarSize, UserProfileProps } from '../../../entities';
import { ApiRequestMode, ApiVariables } from '../../../api/apiSchema';

type Params = {
  orchestrator: AuthStepOrchestrator;
  permit: AuthPermit;
}

export async function runFetchProfileStep(params: Params): AsyncResult<UserProfile> {

  const { permit, orchestrator } = params;
  const { store } = orchestrator;

  // validate the permit, and if everything is ok, set it locally
  if (!permit || !permit.isValid) {
    return [null, new AuthError('InvalidPermit')];
  }

  const args: ApiVariables<'getProfile'> = {
    avatarSize: [UserProfileAvatarSize]
  };
  
  const [profileRes, profileErr] = await store.api.getProfile(args, {
    token: permit.idToken,
    mode: ApiRequestMode.Direct
  });

  if (profileErr || !profileRes?.getProfile) {
    return [null, new AuthError('FetchProfileError', profileErr?.message || 'Could not get your profile information.', { originalError: profileErr })];
  }

  if (!profileRes.getProfile)
    return [null, new AuthError('FetchProfileError', 'The profile information is not available')];

  const profileProps: UserProfileProps = {
    ...profileRes.getProfile,
    permit
  };

  const profile = new UserProfile(store, profileProps);

  return [profile];
}