import { makeObservable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { ComponentName, ComponentVisibility, IComponentPolicy } from '../../components/componentSchema';
import { EntityName, IEntityPolicy } from '../../entities/entitySchema';

/**
 * Manages all logic related to permissions and visibility for entities and components inside the app.
 */
export class PolicyService
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  getCommandPolicy(name: string) {
    
  }

  getEntityPolicy(name: EntityName): IEntityPolicy {
    return {};
  }

  getComponentPolicy(name: ComponentName): IComponentPolicy {

    // const profile = this.store.user;

    switch (name) {
      case ComponentName.PlayerIndexCardBookmarkButton:
        break;
    }

    return {
      visibility: ComponentVisibility.Visible,
      isDisabled: false,
      isReadOnly: false
    }
  }
}