import { isFiniteNumber, isNonZeroFiniteNumber } from './number';
import { Maybe } from './types';

export interface IVec2 {
  x: number,
  y: number
}

export interface IVec3 {
  x: number,
  y: number,
  z: number
}

export interface ISize2 {
  width: number,
  height: number
}

export interface ISize3 {
  width: number,
  height: number,
  depth: number
}

export class Vec2 {
  constructor(
    readonly x: number = 0, 
    readonly y: number = 0) {
    Object.freeze(this);
  }
}

export function vec2(x: number, y: number) {
  return new Vec2(x, y);
}


export class Vec3 {
  constructor(
    readonly x: number = 0, 
    readonly y: number = 0, 
    readonly z: number = 0) {
    Object.freeze(this);
  }
}

export function vec3(x: number, y: number, z: number) {
  return new Vec3(x, y, z);
}

export class Size2 {

  constructor(
    readonly width: number = 0, 
    readonly height: number = 0) {
    Object.freeze(this);
  }
}

export function size2(width: number, height: number) {
  return new Size2(width, height);
}

export class Size3 {

  constructor(
    readonly width: number = 0, 
    readonly height: number = 0, 
    readonly depth: number = 0) {
    Object.freeze(this);
  }
}

export class Rect {
  
  constructor(
    readonly x: number = 0, 
    readonly y: number = 0, 
    readonly width: number = 0, 
    readonly height: number = 0) {
    Object.freeze(this);
  }

  get x1() {
    return this.x;
  }
  get y1() {
    return this.y;
  }
  get x2() {
    return this.x + this.width;
  }
  get y2() {
    return this.y + this.height;
  }
  get cx() {
    return (this.x2 + this.x1) / 2;
  }
  get cy() {
    return (this.y2 + this.y1) / 2;
  }
}

export function rect(x: number, y: number, width: number, height: number) {
  return new Rect(x, y, width, height);
}

export function scaleRect(r: Rect, s: number) {
  return rect(
    r.x * s,
    r.y * s,
    r.width * s,
    r.height * s);
}

/**
 * Simple method for returning width-to-height ratio.
 * It's better to use this rather than dividing directly such that there's no confusion 
 * regarding what's the numerator and what's the denominator.
 */
export function aspectRatio(width: number, height: number) {
  return width / height;
}

/**
 * Safely computes the ratio between 2 numbers, and returns the default value
 * if any of the arguments is not a finite number or the denominator is 0.
 */
export function getRatio(num?: Maybe<number>, den?: Maybe<number>, def: Maybe<number> = 0): number {
  if (isFiniteNumber(num) && isNonZeroFiniteNumber(den))
    return num / den;
  return def ?? 0;
}