import { action, makeObservable, observable, runInAction } from 'mobx';
import { WindowState } from '../../components/overlays/windowState';
import { notifyError } from '../../services/notifications';
import { Message, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { inputGroup } from '../input/inputGroupState';
import { input } from '../input/inputState';
import { RadioItemProps } from '../input/radioGroupInput';

export type DownloadWindowOpenParams = {
  formatItems: RadioItemProps[]; // required for the moment
  onSubmit: (format: string) => any;
  onCancel?: () => void;
  message?: string;
  title?: string;
  confirmLabel?: string;
  closeLabel?: string;
  isLoading?: boolean;
  defaultValue?: string;
}

export class DownloadWindowState
  extends StoreNode {

  readonly nodeType = 'DownloadWindow';

  constructor(store: Store, props?: any) {
    super(store, props);
    makeObservable(this);
    this.window.listen(
      this.windowListener);
  }
  @observable isLoading: boolean = false;
  @observable onSubmit: ((format: string) => any) | null = null;
  @observable onCancel: (() => void) | null = null;
  @observable message: string | null = null;
  @observable modalSecondaryMessage: string | null = null;
  @observable title: string | null = null;
  @observable confirmLabel: string | null = null;
  @observable closeLabel: string | null = null;
  @observable formatItems: RadioItemProps[] | null = null;
  @observable defaultValue: string | null = null;

  @observable downloadUrl: string | null = null;

  readonly format = input(this, {
    name: 'format',
    isRequired: true,
    selectorItems: () => this.formatItems ?? [],
  });

  readonly inputGroup = inputGroup(this, {
    name: 'window',
    inputs: [
      this.format
    ],
    isSubmitDisabled: () =>
      this.inputGroup.hasTouchedErrorInputs ||
      this.isLoading ||
      this.inputGroup.isSubmitting
  });

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close('close');
        break;
    }
  }
  readonly window = new WindowState(this.store);

  @action
  async submit() {
    const { onSubmit } = this;
    this.isLoading = true;
    const format = this.format.value;

    this.inputGroup.handleSubmit();
    if (this.inputGroup.error || !format || !onSubmit) {
      this.inputGroup.handleSubmitReject();
      notifyError(this, 'Prerequisites failed.');
      return;
    }

    const downloadUrl = await onSubmit(format);

    if (!downloadUrl) {
      this.inputGroup.handleSubmitReject();
      return;
    }

    runInAction(() => this.downloadUrl = downloadUrl);
    this.inputGroup.handleSubmitResolve();
    this.close('close');
  }

  @action
  open(params: DownloadWindowOpenParams) {
    this.dispatch('Overlays', 'openWindow', { name: 'DownloadWindow' });
    this.onSubmit = params.onSubmit ?? null;
    this.onCancel = params.onCancel ?? null;
    this.message = params.message ?? null;
    this.title = params.title ?? null;
    this.confirmLabel = params.confirmLabel ?? null;
    this.closeLabel = params.closeLabel ?? null;
    this.isLoading = params.isLoading ?? false;
    this.formatItems = params.formatItems ?? null;
    this.defaultValue = params.defaultValue ?? null;

    // if no default value is passed, just set the first one as default
    const defaultValue = this.defaultValue ?? this.formatItems?.[0]?.value;
    if (defaultValue)
      this.format.loadValue(defaultValue);
    this.emit('open');
  }

  @action
  close(msg?: string) {
    this.dispatch('Overlays', 'closeWindow');
    if (msg) this.emit(msg);
  }

  @action
  async cancel() {
    if (this.onCancel)
      await this.onCancel();

    this.close('close');
  }

  @action
  onWindowEntered() {
    this.isLoading = false;
  }

  @action
  onWindowExited() {
    this.clearState();
  }

  @action
  clearState() {
    this.onSubmit = null;
    this.onCancel = null;
    this.message = null;
    this.title = null;
    this.confirmLabel = null;
    this.closeLabel = null;
    this.formatItems = null;
    this.defaultValue = null;
    this.isLoading = false;
    this.inputGroup.clear();

    this.clearDownloadUrl();
  }

  @action
  clearDownloadUrl() {
    this.downloadUrl = null;
  }

}