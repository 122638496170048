import { JobModel } from '../../entities';
import { IStoreNode } from '../../store';

/**
 * Abstract interface for players with complex behaviour.
 */
export interface IPlayer
  extends IStoreNode {

  job: JobModel | null;

  isSeeking: boolean;
  isStopped: boolean;
  isPlaying: boolean;
  duration: number;
  hasDuration: boolean;
  currentTime: number;

  isPaused: boolean;
  currentTimeRatio: number | null;

  volume: number;

  isMuted: boolean;
  volumeBeforeMute: number | null;
  isReady: boolean;

  isSeekRequested: boolean;
  isSeekDragging: boolean;
  currentSeekTime: number | null;

  currentSeekTimeRatio: number | null;

  isHoverSeeking: boolean;
  hoverSeekTime: number | null;
  hoverSeekTimeRatio: number | null;

  playheadTime: number;
  playheadTimeRatio: number;
  showPoster: boolean;

  adapter: IPlayerAdapter;
}


/**
 * Abstract interface for all player adapters.
 */
export interface IPlayerAdapter
  extends IStoreNode {

  /** 
   * Returns `true` if `play` has been invoked by the user or through autoPlay.
   * As long as the element is mounted, this is the inverse of `isPaused`.
   * ---
   * Returns `true` immediately after the `play` MediaEvent has been emitted and can return `false` again depending on user interactions.
   * The `play` MediaEvent is emitted when playback is ready to start after having been paused or delayed due to lack of media data.
   * @see `play` https://html.spec.whatwg.org/multipage/media.html#event-media-play
   */
  isPlaying: boolean;

  duration: number;
  hasDuration: boolean;
  currentTime: number;
  bufferedTime: number;
  volume: number;
  isSeeking: boolean;
  isMetadataLoaded: boolean;
  isWaiting: boolean;
  isReady: boolean;
  playbackRate: number;
  isLiveStream: boolean;


  /**
   * The user agent can resume playback of the media data, but estimates that if playback were to be started now, 
   * the media resource could not be rendered at the current playback rate up to its end without having to stop for further buffering of content.
   * Returns `true` after `canplay` MediaEvent has been emitted, but can be set back to `false` depending on the user interactions.
   * @see `canplay` https://html.spec.whatwg.org/multipage/media.html#event-media-canplay
   */
  canPlay: boolean;

  /**
   * The user agent estimates that if playback were to be started now, 
   * the media resource could be rendered at the current playback rate all the way to its end without having to stop for further buffering.
   * It is `true` after `canplaythrough` MediaEvent has been emitted, but can be set back to `false` depending on the user interactions.
   * @see `canplaythrough` https://html.spec.whatwg.org/multipage/media.html#event-media-canplaythrough
   */
  canPlayThrough: boolean;

  /** Stores the 'currentTime' requested before the player has been initialized. */
  initialCurrentTime: number | null;

  /** Stores the 'volume' requested before the player has been initialized. */
  initialVolume: number | null;

  /** Stores the 'playbackRate' requested before the player has been initialized. */
  initialPlaybackRate: number | null;

  /** Stores the 'isPlaying' requested before the player has been initialized. */
  initialIsPlaying: boolean | null;

  reattachDOMVideo(): void;
  initPtsInSec: number;
  videoElement: HTMLVideoElement | null;
}

export interface IPlayerTimeController {
  duration: number | null;
  currentTime: number;
  bufferedTime: number;
  seekableTime: number;
  isRealTimePlayback: boolean;
}

export type PlayParams = {
  jumpToLive?: boolean;
}

export enum PlayerComponentName {
  ExplorerBar = 'ExplorerBar',
  MarkerBar = 'MarkerBar',
  MarkerBarComments = 'MarkerBarComments',
  MarkerBarReactions = 'MarkerBarReactions',
  StatusBar = 'StatusBar',
  ControlsBar = 'ControlsBar',
  ProgressBar = 'ProgressBar',
  ProgressBarMoments = 'ProgressBarMoments',
  AdsBar = 'AdsBar',
  Toolbar = 'Toolbar',
  PlayPauseButton = 'PlayPauseButton',
  AudioTrackButton = 'AudioTrackButton',
  ClosedCaptionsButton = 'ClosedCaptionsButton',
  SeekBackButton = 'SeekBackButton',
  SeekNextButton = 'SeekNextButton',
  PlaybackRateButton = 'PlaybackSpeedButton',
  EmbedButton = 'EmbedButton',
  PinControlsButton = 'PinControlsButton',
  VolumeButton = 'VolumeButton',
  FullscreenButton = 'FullscreenButton',
  ReactionButton = 'ReactionButton',
  NextTopicButton = 'NextTopicButton',
  NextSubTopicButton = 'NextSubTopicButton',
  CommentsSection = 'CommentsSection',
  IndexSection = 'IndexSection',
  TranscriptsSection = 'TranscriptsSection',
  AddMomentButton = 'AddMomentButton',
  MomentEditorGroup = 'MomentEditorGroup',
  ShareButton = 'ShareButon'
}

export enum PlayerCommandName {
  Play,
  Pause,
  JumpToMoment,
  JumpToReaction,
  JumpToComment
}

/**
 * Indicates the tracking status for the principal moment set on the player.
 */
export enum PlayerPrincipalMomentStatus {
  /** Indicates that no moment has been set as principal. */
  MomentNotSet = 'MomentNotSet',

  /** Indicates that the current time of the player is before the start time of the moment */
  PlaybackNotStarted = 'PlaybackNotStarted',

  /** Indicates that the current time of the player is between the time bounds of the moment */
  CurrentlyPlaying = 'CurrentlyPlaying',

  /** Indicates that the current time of the player is after the end time of the moment */
  PlaybackEnded = 'PlaybackEnded'
}