import React from 'react';
import { observer } from 'mobx-react-lite';
import { PlayerTranscriptsState } from './playerTranscriptsState';
import { PlayerMomentTime } from '../playerShared/playerMomentTime';
import { useAnchor } from '../../services/overlays';
import { PlayerEditMomentButton } from '../playerShared/playerEditMomentButton';
import { MomentModel } from '../../entities/moment';
import classNames from 'classnames';
import { SyncStatus } from '../../entities';

type Props = {
  pageModel: PlayerTranscriptsState,
  moment: MomentModel
}

export const PlayerTranscriptBlock = observer((props: Props) => {

  const { pageModel, moment } = props;
  const { player } = pageModel;
  const { momentView } = player;

  const anchorId = 'videoTranscriptMoment#' + moment.id;
  const [anchorRef] = useAnchor({
    target: moment,
    id: anchorId
  });

  const selectedSearchMatch = pageModel.selectedSearchMatch;
  const selectedIndexPair = (moment.id === selectedSearchMatch?.momentId && selectedSearchMatch?.indexPair) || null;
  const searchMatches = pageModel.getMomentMatches(moment.id);
  let textContent = moment.description && searchMatches.length > 0 ?
    generateHighlightedContent(searchMatches, moment.description, selectedIndexPair)
    : moment.description;

  textContent = (momentView.activeTranscript === moment) ?
    <p><mark className="mark-wrapper"><span className="wrapper">{textContent}</span></mark></p> :
    <p>{textContent}</p>

  const job = player?.job;

  let isEditable: boolean = (
    job?.speakersSyncStatus === SyncStatus.Fetched &&
    job?.hasPermission('EditUserMoment') &&
    moment.userHasEditPermission) ?? false;

  const className = classNames('user-player-transcript-box', {
    'active': momentView.activeTranscript === moment,
    'editable': isEditable
  });

  return (
    <div ref={anchorRef as any}
      data-tutorial-id="user-player-transcript-box"
      className={className}
      onClick={player.invokeHandler('jumpToMoment', { moment })}>
      <div className="transcript-gutter">
        <div className="gutter-content">
          <PlayerMomentTime model={moment} />

          {isEditable &&
            <PlayerEditMomentButton
              player={player}
              moment={moment}
              permissionMode="always" />}
        </div>
      </div>

      <div className="transcript-text">{textContent}</div>
    </div>
  )
});

const generateHighlightedContent = (searchMatches: Array<any>, text: string, selectedIndexPair?: [number, number] | null) => {

  const indexPairesArray = searchMatches.map(item => item.indexPair);

  const smallestIndex = Math.min(...indexPairesArray.flat());
  const highestIndex = Math.max(...indexPairesArray.flat());

  return (<>
    {text.slice(0, smallestIndex)}
    {indexPairesArray.map((item, i) => {
      const isSelected = (selectedIndexPair &&
        item[0] === selectedIndexPair[0] &&
        item[1] === selectedIndexPair[1]);
      const className = classNames('highlighted', {
        selected: isSelected
      });
      const id = searchMatches[i].globalIndex;
      return (item && <>
        <span className={className}
          data-match={true}
          data-match-id={id}>
          {text.slice(item[0], item[1])}
        </span>
        {indexPairesArray[i + 1] && <span>
          {text[item[1]] === ' ' ? ' ' : ''}
          {text.slice(item[1], indexPairesArray[i + 1][0])}
        </span>}
      </>
      ) || null
    })}
    {' '}
    {text.slice(highestIndex)}
  </>)
}