import React from 'react';
import { observer } from 'mobx-react-lite';
import { UploadFileState } from './uploadFileState';
import { handler } from '../../core';
import {
  SquareCloseSVGIcon,
  VideoSVGIcon,
} from '../../components/svg';
import {
  Button,
  ButtonControlled,
  DropdownInputControlled,
} from '../../components/input';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

type Props = {
  state: UploadFileState;
  submitDisabled?: boolean;
};

export const UploadFileStageBox = observer((props: Props) => {
  const { state } = props;
  const { stagedItems } = state;

  return <div className="upload-file-stage-box upload-stage-box"
    aria-hidden={stagedItems.length === 0}>
    <header className="upload-stage-box-header">
      <div className="box-title">
        <VideoSVGIcon />
        SELECTED VIDEO FILES:
      </div>
    </header>

    <TransitionGroup component="ul" className="upload-file-staging-list upload-staging-list scrollable">
      {stagedItems.map(item =>
        <CSSTransition
          classNames="item-show"
          timeout={300}
          key={item.id}>
          <li key={item.id} className="upload-file-staging-item">
            <div className="header-row">
              <div className="title">
                {item.file.name}
              </div>

              <button
                className="line-suffix close-icon-btn btn-core"
                type={'button'}
                onClick={handler(state, 'removeFileItem', [item.id])}>
                <SquareCloseSVGIcon />
              </button>

            </div>
            <div className="box-row">
              <DropdownInputControlled
                className={"language-input rmar4"}
                label="Video Language"
                model={item.languageInput}
                notDeselectable={true} />
              
              <DropdownInputControlled
                className={"video-type-input"}
                label="Video Type"
                model={item.videoTypeInput}
                notDeselectable={true} />

                <Button
                  className="primary btn-outline medium"
                  label="Modify Output"
                  onClick={() =>
                    state.dispatch('openUploadJobDetailsWindow', { task: item })
                  } />
            </div>
          </li>
        </CSSTransition>
      )}
    </TransitionGroup>


    <div className="upload-stage-box-footer flex right">
      <ButtonControlled
        model={state.uploadButton}
        disabled={props.submitDisabled || state.form.isSubmitDisabled}
        label="Start File Upload"
        className="primary medium submit-btn"
        onClick={handler(state, 'submit')} />
    </div>
  </div>
})