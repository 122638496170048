import { action, computed, makeObservable, observable } from 'mobx';
import { isTargetScrolledToBottom } from '../../core';
import { Store } from '../../store/store';
import { BindingProps, refProxy, StoreNode } from '../../store';
import { SyncStatus } from '../../store/syncSchema';
import { CarouselState } from './carouselCatalogState';

type Props = BindingProps<{
  syncStatus?: SyncStatus,
  isEndOfList?: boolean,
  viewType?: ViewType,
}>
type ViewType = 'grid' | 'list';

/**
 * Controller for catalogs with infinite scroll.
 */
export class CatalogState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.viewType = props?.viewType as ViewType || 'grid';
  }

  @computed get syncStatus(): SyncStatus {
    return this.resolvedProps.syncStatus;
  }

  @computed get isEndOfList(): boolean {
    return this.resolvedProps.isEndOfList;
  }

  readonly domContainer = refProxy(this);
  readonly carouselState = new CarouselState(this)

  @observable scrollToBottomThreshold = 20;
  @observable isScrolledToBottom = false;
  @observable viewType: ViewType = 'grid';

  @computed get isLoading() {
    return this.syncStatus === 'fetching';
  }
  @computed get isLoadingMore() {
    return this.syncStatus === 'fetchingMore';
  }


  @action
  handleScroll = (evt: React.SyntheticEvent) => {

    let prevToBottom = this.isScrolledToBottom;
    let currToBottom = isTargetScrolledToBottom(
      this.domContainer, this.scrollToBottomThreshold);

    if (prevToBottom !== currToBottom) {
      if (currToBottom)
        this.handleScrollToBottom(evt);

      this.isScrolledToBottom = currToBottom;
    }
  }

  @action
  handleScrollToBottom = (evt: React.SyntheticEvent) => {
    this.emit('scrollToBottom');
  }

  @action
  handleLoadMoreButtonClick = (evt: React.MouseEvent) => {
    this.emit('loadMore');
  }

  @action
  loadMore() {
    this.emit('loadMore');
  }

  @action
  toggleViewType() {
    if (this.viewType === 'grid') {
      this.viewType = 'list';
    } else {
      this.viewType = 'grid';
    }
  }
}


