import { ChartPageParams } from '../chartSchema';

export enum TopicViewsChartMode {
  Topics = 'Topics',
  SubTopics = 'SubTopics',
  MostBookmarkedTopics = 'MostBookmarkedTopics',
  MostBookmarkedSubtopics = 'MostBookmarkedSubtopics',
}


export type TopicViewsChartExternalParams = ChartPageParams;

export type TopicViewsChartInternalParams = {
  mode?: TopicViewsChartMode | null
}

export type TopicViewsChartParams =
  TopicViewsChartInternalParams &
  TopicViewsChartExternalParams;
