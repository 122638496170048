import { NotificationSubscriptionSubscribedEvent } from '@clipr/lib';
import { LocationDescriptor } from 'history';
import { sanitizeObject } from '../components/utils';

import { Maybe, wrapRouteWithQuery } from '../core';
import { queryParamsToString } from '../core/urlUtils';
import { UserPlayerPageQueryParams } from '../pages/userPlayerPage/userPlayerPageSchema';
import { WidgetParams, WidgetParamsSchema } from '../services/widget/widgetParams';
import { getAbsoluteUrl, getRelativeUrl } from '../services/routing/routingUtils';
import { writeParamsToQueryString } from '../entities/params/paramsUtils';

export type UserVideoQueryParams = {
  momentId?: string,
  backButtonRoute?: string
}

export type UserSplashQueryParams = {
  momentId?: string,
  backButtonRoute?: string
}

export type SubscriptionPageParams = {
  token: string,
  type: NotificationSubscriptionSubscribedEvent,
  destination: string
}

export type AuthQueryParams = {};

export type ProxyQueryParams = {
  authConnection?: string;
};


export type LibraryRouteParams = {
  libraryKey: 'google-drive' | 'one-drive' | 'zoom' | ':libraryKey';
}

export type BackRoute = {
  state: BackRouteState,
  teamId?: string | null,
  jobId?: string | null,
  view?: UserDashboardView | null,
  listId?: string | null,
  isWidgetMode?: boolean
}

export type BackRouteState = {
  prevRoute?: string,
  teamId?: string | null,
  view?: UserDashboardView,
  listId?: string,
  rawPrevPath?: string
}

export type RouteParams = {
  id?: string,
  teamId?: string,
  listId?: string,
  view?: UserDashboardView
  currentPath?: string,
  momentId?: string,
  searchKey?: string,
  rawPrevPath?: string;
}

export type UserDashboardView = 'gdrive' | 'zoom' | 'onedrive';
export type UserDashboardQueryParams = {
  view?: UserDashboardView
}

export type BackRouteType = {
  route: (routeParams: RouteParams) => LocationDescriptor<BackRouteState>;
}
export type BackRoutesType = {
  [key: string]: BackRouteType;
}

export type RouteQueryParams = {
  teamId?: string;
  view?: string;
}


export function wrapWidgetRouteWithQuery(route: string, widgetParams?: Maybe<Partial<WidgetParams>>): string {

  if (!widgetParams)
    return route;

  const url = new URL(getAbsoluteUrl(route));
  const widgetQueryString = writeParamsToQueryString(WidgetParamsSchema, widgetParams);
  const widgetQueryParams = new URLSearchParams(widgetQueryString);

  widgetQueryParams.forEach((val, name) =>
    url.searchParams.set(name, val));

  return getRelativeUrl(url);
}

export const Routes = {
  dashboard: '/my/dashboard',
  auth: '/auth',
  'create-new-password': '/auth/create-new-password',
  'forgot-password': '/auth/forgot-password',
  'reset-password': '/auth/reset-password',
  account: '/account',
  video: '/video',
  settings: '/settings',
  users: '/users',
  search: '/search',
  searchChatGpt: '/search/chatGpt',

  // #region Auth routes
  upload: (query?: AuthQueryParams) =>
    wrapRouteWithQuery('/upload', query),

  login: (query?: AuthQueryParams) =>
    wrapRouteWithQuery(`/signin`, query),

  register: (query?: AuthQueryParams) =>
    wrapRouteWithQuery(`/signup`, query),

  onboard: (query?: AuthQueryParams) =>
    wrapRouteWithQuery(`/onboard`, query),

  forgotPassword: (query?: AuthQueryParams) =>
    wrapRouteWithQuery(`/password/forgot`, query),

  logout: (query?: AuthQueryParams) =>
    wrapRouteWithQuery(`/signout`, query),

  oauthCallback: (query?: AuthQueryParams) =>
    wrapRouteWithQuery(`/oauth/callback`, query),

  oauthLogout: (query?: AuthQueryParams) =>
    wrapRouteWithQuery(`/oauth/logout`, query),

  oauthVerified: (query?: AuthQueryParams) =>
    wrapRouteWithQuery(`/oauth/verified`, query),

  oauthPassword: (query?: AuthQueryParams) =>
    wrapRouteWithQuery(`/oauth/password`, query),

  oauthGoogleDriveCallback: (query?: any) => wrapRouteWithQuery(`/google-auth-redirect`, query),
  oauthOneDriveCallback: (query?: any) => wrapRouteWithQuery(`/onedrive-auth-redirect`, query),
  oauthZoomCallback: (query?: any) => wrapRouteWithQuery(`/zoom-auth-redirect`, query),
  // #endregion

  // #region Proxy Routes
  proxyAuthorize: (query?: ProxyQueryParams) =>
    wrapRouteWithQuery(`/proxy/authorize`, query),
  proxyDeauthorize: (query?: ProxyQueryParams) =>
    wrapRouteWithQuery(`/proxy/deauthorize`, query),
  // #endregion

  bookmarks: () => `/user/bookmarks`,
  bookmarkList: (id = ':bookmarkListId') => `/user/bookmarks/list/${id}`,
  bookmark: (id = ':id') => `/user/bookmarks/${id}`,
  analytics: () => `/user/analytics`,

  userDashboard: (query?: UserDashboardQueryParams) =>
    wrapRouteWithQuery(`/my/dashboard`, query),

  userVideo: (jobId = ':jobId', query = '') =>
    `/user/video/${jobId}${query ? `?${query}` : ''}`,

  userVideoWithQuery(jobId = ':jobId', query?: UserVideoQueryParams) {
    return `/user/video/${jobId}?` + queryParamsToString(query);
  },

  userVideoLanding: (jobId = ':jobId', query = '') => `/user/video/${jobId}/splash${query ? `?${query}` : ''}`,
  userSplash: (jobId = ':jobId', query?: UserSplashQueryParams) =>
    `/user/splash/${jobId}?` + queryParamsToString(query),

  userPlayer: (jobId = ':jobId', query?: UserPlayerPageQueryParams) =>
    wrapRouteWithQuery(`/player/${jobId}`, query),

  trainerDashboard: () => `/trainer/dashboard`,
  trainerDashboardFailedJob: (jobId = ':jobId') => `/trainer/dashboard/jobId=${jobId}`,
  trainerVideo: (jobId = ':jobId') => `/video/${jobId}`, //same as the video route, but as callback

  teamDashboard: (teamId = ':teamId') => `/team/${teamId}/dashboard`,
  teamSettings: (teamId = ':teamId') => `/team/${teamId}/settings`,
  teamAnalytics: (teamId = ':teamId') => `/team/${teamId}/analytics`,
  teamLiveStreamQueue: (teamId = ':teamId') => `/team/${teamId}/streams`,
  teamUpload: (teamId = ':teamId') => `/upload/teamId=${teamId}`,
  jobReplace: (jobId = ':jobId') => `/upload/jobId=${jobId}`,
  joinInvitation: (teamId = ':teamId') => `/join/${teamId}`,
  createTeam: () => '/team/createTeam',
  adminTeamsDashboard: () => '/admin/teams/dashboard',

  unsubscribe: (query?: SubscriptionPageParams) => wrapRouteWithQuery(`/unsubscribe`, query),

  legalTerms: () => `/legal/terms`,
  legalPrivacy: () => `/legal/privacy`,
  zoomHelp: () => `/help/zoom`,

  networkError: () => `/error/network`,
  defaultRoute: () => Routes.emptyRoute(),
  emptyRoute: () => '/',

  // #region Widget
  externalLoginWidget: (query?: {}) =>
    wrapRouteWithQuery(`/embed/login/external`, query),
  proxyLoginWidget: (query?: {}) =>
    wrapRouteWithQuery(`/embed/login/proxy`, query),

  playerWidget: (jobId = ':jobId', query?: Partial<WidgetParams>) =>
    wrapWidgetRouteWithQuery(`/widget/${jobId}`, query),

  uploadWidget: (query?: Partial<WidgetParams>) =>
    wrapWidgetRouteWithQuery(`/embed/upload`, query),

  uploadAdvancedWidget: (query?: Partial<WidgetParams>) =>
    wrapWidgetRouteWithQuery(`/embed/upload/advanced`, query),

  teamLibraryWidget: (teamId = ':teamId', query?: Partial<WidgetParams>) =>
    wrapWidgetRouteWithQuery(`/embed/team/${teamId}`, query),

  teamLibraryPublicWidget: (teamId = ':teamId', query?: Partial<WidgetParams>) =>
    wrapWidgetRouteWithQuery(`/embed/team/public/${teamId}`, query),

  teamLiveStreamQueueWidget: (teamId = ':teamId', query?: Partial<WidgetParams>) =>
    wrapWidgetRouteWithQuery(`/embed/team/${teamId}/streams`, query),

  analyticsWidget: (query?: Partial<WidgetParams>) =>
    wrapWidgetRouteWithQuery(`/embed/analytics`, query),

  teamAnalyticsWidget: (teamId = ':teamId', query?: Partial<WidgetParams>) =>
    wrapWidgetRouteWithQuery(`/embed/team/${teamId}/analytics`, query),

  teamSettingsWidget: (teamId = ':teamId') => `/embed/team/${teamId}/settings`,

  librarySearchWidget: () => '/embed/search',
  librarySearchChatGptWidget: () => '/embed/search/chatGpt'
  // #endregion
};

export const backRoute = (backRoute: BackRoute): string | null => {
  const { state, teamId, jobId, view, listId, isWidgetMode = false } = backRoute;

  // if previous route is already defined then return it
  // otherwise construct the previous route based on the given parameters (handling mechanism for page refreshing when the state gets lost)
  if (state?.prevRoute) {
    return state.prevRoute;
  }

  if (isWidgetMode) return null;

  if (jobId && teamId) return Routes.userVideo(jobId, `teamId=${teamId}`);

  if (listId) return Routes.bookmarkList(listId);

  // we are considering that if teamId and view are both set the previous route corresponds to the upload page
  // we should review this workaround and find other solutions
  if (teamId && view) {
    let params: RouteParams = {};
    if (view) params.view = view;
    const search = queryParamsToString(params);

    return `${Routes.teamUpload(teamId)}?${search}`;
  }

  // we are considering that if the view param is set the previous route corresponds to the user dashboard page (integration tab)
  // we should review this workaround and find other solutions
  if (view) {
    const viewQueryParam: UserDashboardQueryParams = { view };
    return Routes.userDashboard(viewQueryParam);
  }

  if (teamId) return Routes.teamDashboard(teamId);

  return Routes.userDashboard();
}

export const BackRoutes: BackRoutesType = {

  [Routes.userVideo()]: {
    route: (routeParams: RouteParams): LocationDescriptor<BackRouteState> => {
      const { id, teamId, view, listId, momentId, currentPath } = routeParams;
      const viewQueryParam: UserDashboardQueryParams = { view };

      let params: RouteParams = { teamId, view, listId, momentId };
      const search = queryParamsToString(sanitizeObject(params));

      // construct the previous route based on the given parameters
      let prevRoute: string;
      if (currentPath) {
        prevRoute = currentPath;
      } else if (listId) {
        prevRoute = Routes.bookmarkList(listId);
      } else if (teamId && view) {
        prevRoute = `${Routes.teamUpload(teamId)}?${search}`
      } else {
        prevRoute = teamId ? Routes.teamDashboard(teamId) : Routes.userDashboard(viewQueryParam);
      }

      return {
        pathname: Routes.userVideo(id),
        search,
        state: {
          prevRoute,
          teamId,
          view,
          listId
        }
      }
    }
  },
  [Routes.userVideoLanding()]: {
    route: (routeParams: RouteParams): LocationDescriptor<BackRouteState> => {
      const { id, teamId, currentPath, view, listId, momentId } = routeParams;
      const viewQueryParam: UserDashboardQueryParams = { view };

      let params: RouteParams = { teamId, view, listId, momentId };
      const search = queryParamsToString(sanitizeObject(params));

      // construct the previous route based on the given parameters
      let prevRoute;
      if (currentPath) {
        prevRoute = `${currentPath}?${search}`;
      } else if (teamId && view) {
        prevRoute = `${Routes.teamUpload(teamId)}?${search}`
      } else if (teamId && !view) {
        prevRoute = Routes.teamDashboard(teamId);
      } else {
        prevRoute = Routes.userDashboard(view ? viewQueryParam : undefined)
      }

      return {
        pathname: Routes.userVideoLanding(id),
        search: search,
        state: {
          rawPrevPath: currentPath,
          prevRoute,
          teamId,
          view,
          listId
        }
      }
    }
  },
  [Routes.playerWidget()]: {
    route: (routeParams: RouteParams): LocationDescriptor<BackRouteState> => {
      const { id, teamId, currentPath, listId, momentId } = routeParams;

      let params: RouteParams = { listId, momentId, teamId };
      const search = queryParamsToString(sanitizeObject(params));

      return {
        pathname: Routes.playerWidget(id),
        search: search,
        state: {
          prevRoute: currentPath,
          teamId,
          listId
        }
      }
    }
  },
  [Routes.userVideoWithQuery()]: {
    route: (routeParams: RouteParams): LocationDescriptor<BackRouteState> => {
      const { id, momentId, listId } = routeParams;
      const search = `${momentId ? `?momentId=${momentId}` : ''}${listId ? `&listId=${listId}` : ''}`;
      return {
        pathname: Routes.userVideo(id),
        search: search,
        state: {
          prevRoute: Routes.bookmarkList(listId),
          listId
        }
      }
    }
  },
  [Routes.createTeam()]: {
    route: (routeParams: RouteParams): LocationDescriptor<BackRouteState> => {
      const { currentPath } = routeParams;
      return {
        pathname: Routes.createTeam(),
        state: {
          prevRoute: currentPath,
        }
      }
    }
  },
  [Routes.teamLiveStreamQueue()]: {
    route: (routeParams: RouteParams): LocationDescriptor<BackRouteState> => {
      const { currentPath, teamId } = routeParams;
      return {
        pathname: Routes.teamLiveStreamQueue(teamId),
        state: {
          prevRoute: currentPath,
        }
      }
    }
  },
  [Routes.account]: {
    route: (routeParams: RouteParams): LocationDescriptor<BackRouteState> => {
      const { currentPath } = routeParams;
      return {
        pathname: Routes.account,
        state: {
          prevRoute: currentPath,
        }
      }
    }
  },
  [Routes.upload()]:
  {
    route: (routeParams: RouteParams): LocationDescriptor<BackRouteState> => {
      const { currentPath, teamId } = routeParams;
      const teamParam = teamId ? `/teamId=${teamId}` : '';
      return {
        pathname: `${Routes.upload()}${teamParam}`,
        state: {
          prevRoute: currentPath,
        }
      }
    }
  },
  [Routes.teamUpload()]: {
    route: (routeParams: RouteParams): LocationDescriptor<BackRouteState> => {
      const { currentPath, teamId } = routeParams;
      return {
        pathname: `${Routes.teamUpload(teamId)}`,
        state: {
          prevRoute: currentPath,
        }
      }
    }
  },
  [Routes.trainerVideo()]: {
    route: (routeParams: RouteParams): LocationDescriptor<BackRouteState> => {
      const { id, teamId, currentPath } = routeParams;

      let params: RouteParams = { teamId };
      const search = queryParamsToString(sanitizeObject(params));

      // construct the previous route based on the given parameters
      let prevRoute: string;
      if (currentPath) {
        prevRoute = currentPath;
      } else {
        prevRoute = teamId ? Routes.teamDashboard(teamId) : Routes.trainerDashboard();
      }

      return {
        pathname: Routes.trainerVideo(id),
        search,
        state: {
          prevRoute,
          teamId,
        }
      }
    }
  },
  [Routes.search]: {
    route: (routeParams: RouteParams): LocationDescriptor<BackRouteState> => {
      const { id, teamId, currentPath, view, listId, searchKey, momentId } = routeParams;
      const viewQueryParam: UserDashboardQueryParams = { view };

      let params: RouteParams = { searchKey, teamId, view, listId };
      const prevRouteSearch = queryParamsToString(sanitizeObject(params));

      if (momentId) params.momentId = momentId;
      const search = queryParamsToString(sanitizeObject(params));

      return {
        pathname: Routes.userVideo(id),
        search: search,
        state: {
          prevRoute: currentPath ? `${currentPath}?${prevRouteSearch}` : Routes.userDashboard(view ? viewQueryParam : undefined),
          teamId,
          view,
          listId
        }
      }
    }
  }

}
