import './speakerWindow.scss';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { SpeakerWindowState } from './speakerWindowState';
import { ButtonControlled, TextInputControlled } from '../input';
import { SpeakerInputGroup } from '../speakers/speakerInputGroup';
import { FileInputControlled } from '../input/fileInput';

type Props = {
  model: SpeakerWindowState;
  visible?: boolean | null;
};

export const SpeakerWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      model={model.window}
      onExited={model.onTransitionEnd}
      visible={props.visible ?? false}
      isLoading={model.isLoading}
      className="speaker-window">
      <WindowHeader
        model={model.window}
        title={model.isCreateMode ? 'Add/Edit Speaker' : 'Edit Speaker'}
      />

      <hr />

      <section className="window-content">
        <div className="speaker-details">
          <FileInputControlled
            layout={'avatar'}
            layoutSize={'small'}
            model={model.speakerAvatar} />

          <SpeakerInputGroup model={model.speaker} />
        </div>

        <div className="speaker-description">
          <TextInputControlled rows={4} model={model.description} />
        </div>

        <div className="speaker-linkedin">
          <TextInputControlled model={model.linkedin} />
        </div>
      </section>

      <hr />

      <footer className="window-footer">
        <div className="left">
          {model.showAddTrack &&
            <ButtonControlled
              className="secondary btn-outline medium"
              label="Create swimlane"
              model={model.addTrackButton}
              onClick={() => model.submitAddTrack()} />}
        </div>

        <div className="right">
          <div className="btn-group ">
            <ButtonControlled
              className="primary btn-outline medium"
              label="Cancel"
              model={model.cancelButton}
              onClick={() => model.cancel()} />
            <ButtonControlled
              className="primary medium"
              label="Save"
              model={model.saveButton}
              onClick={() => model.submit()} />
          </div>
        </div>
      </footer>
    </Window>
  );
});
