import './teams.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { HTMLDivProps, Maybe } from '../../core';
import { Team } from '../../entities/team';
import { UserAvatar } from '../layout/userAvatar';
import max from 'lodash/max';


function getMoreCountString(val: number) {
  val = max([0, val])!;
  if (val <= 0)
    return null;
  if (val < 1000)
    return `+${val}`;
  if (val >= 1000 && val < 1000000) {
    const label = (val / 1000).toFixed(1);
    if (label.includes('.0'))
      return `+${(val / 1000).toFixed(0)}K`;
    return `+${(val / 1000).toFixed(1)}K`;
  }

  // after 1M, fingers crossed to get there
  const label = (val / 1000000).toFixed(1);
  if (label.includes('.0'))
    return `+${(val / 1000000).toFixed(0)}M`;
  return `+${(val / 1000000).toFixed(1)}M`;
}

type Props = HTMLDivProps<{
  model?: Maybe<Team>,
  /** The maximum number of avatars which will be displayed before showing the + sign. */
  maxCount?: number
}>

export const TeamMemberSummary = observer(({
  model,
  maxCount = 4,
  ...props
}: Props) => {
  if (!model)
    return null;

  const {
    members,
    membersCount
  } = model;
  const length = membersCount || members.length || 0;

  const moreCount = max([0, length - maxCount])!;
  const moreCountLabel = getMoreCountString(moreCount);

  return (
    <div className="team-member-summary"
      {...props}>

      {members.slice(0, maxCount).map(mem => {
        const model = mem.user || {
          displayName: mem.userId
        };

        return <UserAvatar key={mem.userId}
          className="team-user-avatar light-blue"
          model={model} />
      })}

      {moreCount ?
        <div className="team-user-avatar avatar-core more-avatar">
          {moreCountLabel}
        </div> : null}
    </div>
  );
});