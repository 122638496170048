import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../../store/store';
import { StoreNode } from '../../../store';
import { AuthFlowName, AuthFlowResponse } from '../authFlowSchema';
import { RouteContext } from '../../../routes/routeContext';
import { Error } from '../../../core/error';
import { AsyncResult, Result } from '../../../core';

export class InlineAuthFlowController
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  @observable response: AuthFlowResponse | null = null;
  @observable error: Error | null = null;

  @computed get flowName() {
    const flowName = this.store.authService.currentFlowName;
    if (
      flowName === AuthFlowName.InlineProxyLogin ||
      flowName === AuthFlowName.InlineProxyLogout)
      return flowName;

    return null;
  }

  @computed get isLoginFlowRunning() {
    return this.flowName === AuthFlowName.InlineProxyLogin;
  }

  @computed get isLogoutFlowRunning() {
    return this.flowName === AuthFlowName.InlineProxyLogout;
  }

  @computed get isFlowRunning() {
    return (
      this.isLoginFlowRunning ||
      this.isLogoutFlowRunning);
  }

  async login(routeContext: RouteContext) {

    if (this.store.authService.currentFlow)
      return [null, new Error('InternalError', `There is another auth flow currently running.`)];

    const [flowRes, flowErr] = await this.store.authService.runInlineProxyLoginFlow({
      routeContext
    });

    if (flowErr)
      return [null, flowErr];

    return this.setResponse(flowRes!);
  }


  async logout(routeContext: RouteContext): AsyncResult<AuthFlowResponse> {

    if (this.store.authService.currentFlow)
      return [null, new Error('InternalError', `There is another auth flow currently running.`)];

    const [flowRes, flowErr] = await this.store.authService.runInlineProxyLogoutFlow({
      routeContext
    });

    if (flowErr)
      return [null, flowErr];

    return this.setResponse(flowRes!);
  }

  abort() {
    this.store.proxyService.abortServerFlow();
  }

  focus() {
    this.store.proxyService.focusServerFlow();
  }

  @action
  reset() {
    this.response = null;
  }

  @action
  private setResponse(response: AuthFlowResponse): Result<AuthFlowResponse> {
    this.response = response;
    return [response];
  }

}