import './trainerTranscripts.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { TrainerTranscriptsState } from './trainerTranscriptsState';
import { TrainerTranscriptBlock } from './transcriptBlock';
import { PlayerSectionOverlay } from '../playerShared/playerSectionOverlay';

type Props = {
  model: TrainerTranscriptsState
}

export const TrainerTranscripts = observer((props: Props) => {
  const { model } = props;
  const { transcriptMoments, showOverlay } = model;

  return (
    <section className="trainer-transcripts">
      {transcriptMoments.length === 0 &&
        <div className="index-empty flex-center"
          aria-hidden={transcriptMoments.length > 0}>
          <div className="message">No topics here.</div>
        </div>}

      <PlayerSectionOverlay
        visible={showOverlay}
        label={"Transcript"}
        status={"processing"} />

      {transcriptMoments.length > 0 &&
        !showOverlay &&
        <ul className="transcript-list">
          {transcriptMoments.map(mom => {
            return (
              <li key={mom.id}
                className="transcript-item">
                <TrainerTranscriptBlock
                  moment={mom}
                  ownerModel={model} />
              </li>
            );
          })}
        </ul>}
    </section>
  );
});