import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, FilterFieldButton, SortFieldButton } from '../input';
import { CatalogState } from '../layout/catalogState';
import { JobCatalogSource, JobVideoTypeList } from '../../entities';
import { MenuItem } from '../input/menuItems';
import Routes from '../../routes';
import { UploadSVGIcon } from '../svg';

type Props = {
  model: CatalogState;
  source: JobCatalogSource;
  teamId?: string;
};

export const JobTableCatalogHeader = observer(
  ({ model, source, teamId }: Props) => {
    const user = model.store.user;
    return (
      <div className="job-table-catalog-header catalog-filters">
        <table className="job-table-header job-table-core">
          <thead>
            <tr>
              <th className="thumb-col"></th>
              <th className="title-col">
                <SortFieldButton label="Title" name="title" source={source} />
              </th>

              <th className="ai-processing-col">
                <Button
                  className="ai-processing-btn"
                  label="AI Processing"
                  name="aiProcessing"
                />
              </th>

              <th className="duration-col">
                <SortFieldButton
                  label="Length"
                  name="durationInMs"
                  source={source}
                />
              </th>

              <th className="upload-col">
                <SortFieldButton
                  label="Upload Date"
                  name="createdAt"
                  source={source}
                />
              </th>

              <th className="type-col">
                <div>
                  <span className="filter-label">Filter by:</span>
                  <FilterFieldButton
                    label={source.getVideoTypeFilterLabel() || 'Type'}
                    selected={!!source.getVideoTypeFilterValue()}>
                    <MenuItem
                      model={{ value: 'All', label: 'All' }}
                      onClick={(evt) => source.setVideoTypeFilterValue(null)}
                      isSelected={!source.getVideoTypeFilterValue()}
                    />

                    {JobVideoTypeList.map((type) => (
                      <MenuItem
                        key={type.value}
                        model={type }
                        onClick={(evt) => source.setVideoTypeFilterValue(type.value)}
                        isSelected={source.getVideoTypeFilterValue() === type.value}
                      />
                    ))}
                  </FilterFieldButton>
                </div>
              </th>

              <th className="user-upload">
                {user?.hasPermission('UserUpload') && (
                  <Button
                    route={Routes.upload()}
                    className="upload-btn secondary medium"
                    icon={<UploadSVGIcon />}
                    label="Upload Video"
                  />
                )}
              </th>
            </tr>
          </thead>
        </table>
      </div>
    );
  }
);
