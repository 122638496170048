import './buttons-v1-1.scss';
import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { ArrowDownSVGIcon } from '../svg';
import { Button } from '../input/button';

export type SortFieldValue =
  'asc' |
  'desc' |
  'none';

export interface ISortableSource {
  getSortFieldValue(field: string): SortFieldValue,

  setSortFieldValue(field: string, val: SortFieldValue): void
}

type Props = {
  className?: string,
  label: string,
  source: ISortableSource,
  name: string,
  disabled?: boolean
}

const NextValue: { [key in SortFieldValue]: SortFieldValue } = {
  'none': 'asc',
  'asc': 'desc',
  'desc': 'none'
}

export const SortFieldButton = observer(({
  className,
  label,
  name,
  source,
  disabled = false
}: Props) => {

  const value = source.getSortFieldValue(name);
  const sortFieldClassNames = classNames('sort-field-btn sort', value, className);

  return (
    <Button className={sortFieldClassNames}
      onClick={evt => source.setSortFieldValue(name, NextValue[value])}
      label={label}
      aria-current={value !== 'none'}
      disabled={disabled}
      rightIcon={!disabled && <ArrowDownSVGIcon />}>
    </Button>
  );
});