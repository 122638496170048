import './radioInput.scss';
import './input.scss';

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { assert, Maybe, ObjectLiteral } from "../../core";
import { InputState } from "./inputState";
import { RadioInput } from "./radioInput";
import { paramCase } from 'change-case';

type Props = {
  value?: string,
  className?: string | ObjectLiteral,
  label?: string,
  disabled?: boolean,
  isFocused?: boolean,
  isHovered?: boolean,
  checked?: boolean,
  readOnly?: boolean,
  name?: string,
  items: RadioItemProps[],
  status?: Maybe<string>,
  statusMessage?: Maybe<string>,
  onChange: any,
  showStatus?: boolean,
  labelPosition?: string,
  showFeedback?: Maybe<boolean>,
  feedbackPosition?: string,
  description?: string,
  descriptionPosition?: string,
  icons?: RadioInputIcon[],
}

export type RadioItemProps = {
  value: string,
  label: string,
  description?: string
  disabled?: boolean
}

export const RadioGroupInput = observer(({
  readOnly,
  value,
  className,
  label,
  disabled,
  isFocused,
  isHovered,
  checked,
  name,
  onChange,
  statusMessage,
  showStatus,
  labelPosition = 'topStart',
  descriptionPosition = 'topStart',
  showFeedback,
  feedbackPosition: messagePosition = 'bottom',
  icons,
  description,
  ...props
}: Props) => {

  className = classNames('radio-group-input input', className, {
    'focus': isFocused,
    'hover': isHovered
  });

  const feedbackElem = statusMessage && showFeedback &&
    <div className={['feedback input-feedback', paramCase(messagePosition)].join(' ')}
      aria-live="polite">
      {statusMessage}
    </div>

  const labelElem = label &&
    <label className={['input-label', paramCase(labelPosition)].join(' ')}>
      {label}
    </label>

  const descriptionElem = description &&
    <div className={['input-description', paramCase(descriptionPosition)].join(' ')}>
      {description}
    </div>

  const getIcon = (item: RadioItemProps) => {
    return icons?.find((radioInputIcon: RadioInputIcon) => radioInputIcon.key === item.value)?.icon;
  }

  return (<div className={className} aria-disabled={disabled}>
    {labelElem}
    {descriptionElem}
    {feedbackElem}
    <div className="radio-group-input-container">
      <div className="radio-group-input-box">
        {props.items.map(item => {
          return (
            <RadioInput
              name={name}
              key={item.value}
              icon={getIcon(item)}
              checked={item.value === value}
              label={item.label}
              disabled={item.disabled || disabled}
              onChange={(evt: any) => onChange(evt, item.value)}
              {...props}
            />
          );
        })}
      </div>
      <div className="radio-group-description-box">
        {props.items.map(item => {
          return <div className={"description-item"} key={item.value}>
            <span className="description-text">{item.description}</span>
          </div>
        })}
      </div>
    </div>
  </div >)

});

export type RadioInputIcon = {
  key: string,
  icon: JSX.Element
}

type ControlledProps =
  React.InputHTMLAttributes<HTMLInputElement> & {
    value?: string,
    label?: string,
    description?: string,
    validator?: 'top' | 'bottom' | 'none',
    model: InputState,
    showFeedback?: Maybe<boolean>,
    icons?: RadioInputIcon[],
    className?: string | ObjectLiteral
  };

/**
 * Wrapper for RadioInput in which the entire state and all the handlers are provided through an InputState object.
 */
export const RadioGroupInputControlled = observer(({
  model,
  className,
  showFeedback = true,
  ...props
}: ControlledProps) => {

  assert(model instanceof InputState,
    `The 'model' passed to RadioInputControlled must be an instance of InputState. Instead received ${model}.`);

  const handleChange = useCallback((evt, value) => {
    model.handleChange(evt, value)
  }, [model]);

  className = model.getClassName('radio-input-controlled', { className });

  return <RadioGroupInput
    className={className}
    name={model.name}
    value={model.value || ''}
    placeholder={model.placeholder}
    disabled={model.disabled}
    label={model.label!}
    items={model.selectorItems}
    isHovered={model.isHovered}
    isFocused={model.isFocused}
    onChange={handleChange}
    onFocus={model.handleFocus}
    onBlur={model.handleBlur}
    onPointerEnter={model.handlePointerEnter}
    onPointerLeave={model.handlePointerLeave}
    status={model.status!}
    statusMessage={model.statusMessage}
    showStatus={model.showStatus}
    showFeedback={model.showStatusMessage}
    {...props} />
});