import { action, makeObservable, observable, runInAction } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { AuthFlowResponse } from '../../services/auth/authFlowSchema';
import { IPageState } from '../pageSchema';
import { RouteContext } from '../../routes/routeContext';
import { Error } from '../../core/error';

export class OAuthCallbackPageState
  extends StoreNode
  implements IPageState {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  @observable isLoading = false;

  @observable flowResponse: AuthFlowResponse | null = null;
  @observable error: Error | null = null;

  @action
  async attached(routeContext: RouteContext) {

    this.reset();
    this.isLoading = true;

    const [res, err] = await this.store.authService.handleOAuthCallback(routeContext);
    if (err)
      this.error = err;
    else
      this.flowResponse = res;

    runInAction(() => {
      this.isLoading = false;
    });
  }

  @action
  detached() {
    this.reset();
  }

  @action
  reset() {
    this.flowResponse = null;
    this.error = null;
    this.isLoading = false;
  }
}