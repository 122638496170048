import React from 'react';
import { observer } from 'mobx-react-lite';
import { ParentSize } from '@visx/responsive';
import { TextProps } from '@visx/text';
import { scaleOrdinal } from '@visx/scale';
import { ThemeConfig } from '@visx/xychart/lib/theme/buildChartTheme';
import { XYSeriesChart } from '../xySeriesChart';
import { DropdownInputControlled } from '../../input';
import { TeamPerformanceChartDatum, TeamPerformanceChartState } from '.';
import { EllipseText } from '../utils/ellipseText';
import { DefaultThemeConfig } from '../utils/defaultThemeConfig';
import { ChartWrapper } from '../chartWrapper';

type Props = {
  model: TeamPerformanceChartState;
};

export const TeamPerformanceChart = observer(({ model }: Props) => {
  const { dataSource } = model;
  const { isFetching: showLoadMask, error } = dataSource;

  // NOTE: do not access model.outputData directly in XYSeriesChart
  // this is because XYSeriesChart is rendered within a callback to ParentSize, which I suspect is run async
  const data = model.outputData;

  const hasData = !!(data?.[0]?.data?.length > 0);
  const showNoDataMask = !hasData && !showLoadMask;

  let overlayMessage;
  if (error) {
    overlayMessage = { code: 'Error', message: 'An error has occured' };
  } else if (showNoDataMask) {
    overlayMessage = { code: 'NoData' };
  }

  const tickLabelPropsObj: Partial<TextProps> = {
    width: 260,
    textAnchor: 'end',
  };

  const themeConfig: ThemeConfig = {
    backgroundColor: '#ffffff',
    tickLength: 8,
    colors: [],
    gridColor: '',
    gridColorDark: '',
  };

  const domain = (data[0]?.data?.map(
    (item: TeamPerformanceChartDatum) => item.date
  ) ?? []) as string[];

  const scaleBandRangeByMaxBandWidthObj = {
    maxBandWidth: 20,
    domain,
  };

  const colorAccessor = (key: TeamPerformanceChartDatum) => {
    const colorScale = scaleOrdinal({
      domain,
      range: DefaultThemeConfig.colors,
    });

    return colorScale(key?.date as string);
  };

  const dropdownInput = <DropdownInputControlled
    className="form-input"
    model={model.chartTypeInput}
    showFeedback={false}
    notDeselectable={true}
    persistentFeedback={false} />;

  const tickComponent = ({ formattedValue, ...tickProps }: any) =>
    <EllipseText
      {...tickProps}
      suffix="..."
      className="chartLabel"
      width="160"
      style={{
        fontWeight: 600,
        fontSize: '12px'
      }}>
      {formattedValue}
    </EllipseText>;

  const dateAxisProps = {
    tickLabelProps: () => tickLabelPropsObj,
    numTicks: data[0]?.data?.length ?? 3,
    tickComponent,
  };

  const valueAxisProps = {
    tickLabelProps: () => tickLabelPropsObj,
    numTicks: 3,
  }

  return (
    <ChartWrapper
      title="Most Active Users"
      tooltipContent="Find out who the most active members in your library are and what types of activities they engage with the most."
      showLoadMask={showLoadMask}
      overlayMessage={overlayMessage}
      tools={dropdownInput}>
      <ParentSize>{(parent) =>
        <XYSeriesChart
          className="horizontalBarChart"
          renderBarSeries={true}
          renderLineSeries={false}
          renderHorizontally={true}
          showLegend={false}
          showGridRows={false}
          showGridColumns={true}
          sharedTooltip={false}
          margin={{ top: 20, right: 20, bottom: 25, left: 190 }}
          dateAxisProps={dateAxisProps}
          valueAxisProps={valueAxisProps}
          colorAccessor={colorAccessor}
          scaleBandRangeByMaxBandWidth={scaleBandRangeByMaxBandWidthObj}
          height={parent.height - 32}
          width={parent.width}
          series={data}
          themeConfig={themeConfig} />
        }</ParentSize>
    </ChartWrapper>
  );
});
