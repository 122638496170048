
import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { IntegrationTabState } from './integrationTabState';
import { Button } from '../../components';
import { LibraryProvider } from '../../services/libraries';

type Props = {
  model: IntegrationTabState;
  library: LibraryProvider;
  helpBtn?: ReactNode
};

export const DisconnectSection = observer(({ model, library, helpBtn }: Props) => {
  return (
    <div className="user-upload flex-right">
      {helpBtn}
      <Button
        className="upload-btn secondary btn-outline medium"
        label="Disconnect Account"
        onClick={() => library.deleteLibrary(model)}
      />
    </div>
  );
});