import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useRouteModel } from './routeHooks';
import { DirectRouteState } from './directRouteState';

import { TRACE } from '../core/debug/debugMacros';

type Props = RouteProps;

export const DirectRoute = observer((props: Props) => {

  const model = useRouteModel((kernel) => new DirectRouteState(kernel.store));

  TRACE(model, `render()`, { props, model });

  return <Route {...props} />
});