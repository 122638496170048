import { Maybe } from '../../../core';
import { AuthError, AuthErrorType } from '../../../services/auth';

export const RegisterErrorCodes: AuthErrorType[] = [
  'UserExists',
  'UsernameExists',
  'InvalidPassword',
  'PasswordTooCommon',
  'PasswordTooWeak',
  'PasswordContainsUserInfo',
  'InvalidRegister',
  'NotAuthorized',
  'NetworkError',
  'ProviderError'
];

export const RegisterEmailSubmitErrorCodes: AuthErrorType[] = [
  'UsernameExists',
  'UserExists'
];

export const RegisterPasswordSubmitErrorCodes: AuthErrorType[] = [
  'InvalidPassword',
  'PasswordTooCommon',
  'PasswordTooWeak',
  'PasswordContainsUserInfo'
];

export const RegisterErrorMessageLookup: { [key in AuthErrorType]?: string } = {

  // Auth0 errors
  // see https://auth0.com/docs/libraries/common-auth0-library-authentication-errors#sign-up

  // `user_exists`: The user you are attempting to sign up has already signed up
  'UserExists':
    `The user you are attempting to sign up has already signed up.`,

  // `username_exists`: The username you are attempting to sign up with is already in use
  'UsernameExists':
    `The username / email you are attempting to sign up with is already in use.`,

  // `invalid_password`: If the password used doesn't comply with the password policy for the connection
  'InvalidPassword':
    `The chosen password does not comply with the password policies for this application.`,

  // `password_dictionary_error`: The chosen password is too common
  'PasswordTooCommon':
    `The chosen password is too common.`,

  // `password_strength_error`: The chosen password is too weak
  'PasswordTooWeak':
    `The chosen password is too weak.`,

  // `password_no_user_info_error`: The chosen password is based on user information
  'PasswordContainsUserInfo':
    `The chosen password cannot be based on other profile information.`,

  // `invalid_signup`: The user your are attempting to sign up is invalid
  'InvalidRegister':
    `The user your are attempting to sign up is invalid`,

  // `unauthorized`: If you cannot sign up for this application. May have to do with the violation of a specific rule
  'NotAuthorized':
    `You are not allowed to sign up for this application.`,


  // general errors
  'NetworkError':
    `Signing up failed because of a network error.`,

  'ProviderError':
    `Signing up failed because of a provider error.`,

  // default error
  'UnknownError':
    `Signing up failed because of an unknown error.`
}

export function getRegisterDefaultErrorMessage(errType?: Maybe<AuthErrorType>): string {
  return RegisterErrorMessageLookup[errType || 'UnknownError']!;
}

export function getRegisterErrorMessage(err?: Maybe<AuthError>): string | null {

  if (err && !(err instanceof AuthError))
    return getRegisterDefaultErrorMessage();

  const errType = err?.type;
  const errMsg = err?.originalMessage;
  const defMsg = getRegisterDefaultErrorMessage(errType);

  if (!err || !errType)
    return null;

  return errMsg || defMsg || null;
}
