import * as React from "react";
import classNames from 'classnames';

export const ConfusingBloomSVGIcon = ({className, ...props}: React.SVGProps<SVGSVGElement>) =>
  <svg
    viewBox="0 0 251 239"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames("confusing-bloom-svg-icon", "svg-icon", className)}
    {...props}>
      <style>
        {
          "\n    .main-bloom {\n      transform: scale(0);\n      transform-origin: center center;\n      animation: pulse 2s 1 alternate;\n    }\n\n    .bg-bloom {\n      transform: scale(0);\n      transform-origin: center center;\n      animation: pulse2 1.6s 1 alternate-reverse;\n      opacity: 0;\n    }\n\n    @keyframes pulse {\n      60% {\n        transform: scale(1);\n        opacity: 1;\n      }\n\n      100% {\n        transform: scale(0);\n        opacity: 0;\n      }\n    }\n\n    @keyframes pulse2 {\n      60% {\n        transform: scale(1);\n        opacity: 1;\n      }\n\n      80% {\n        opacity: 0;\n      }\n\n      100% {\n        transform: scale(0);\n        opacity: 0;\n      }\n    }\n  "
        }
      </style>
      <g className="main-bloom">
        <path
          d="M184.455 119.239C184.455 152.206 157.729 178.932 124.762 178.932C91.7938 178.932 65.0681 152.206 65.0681 119.239C65.0681 86.2708 91.7938 59.5452 124.762 59.5452C157.729 59.5452 184.455 86.2708 184.455 119.239Z"
          fill="#ED4961"
          fillOpacity={0.1}
          stroke="white"
        />
        <path
          d="M124.762 169.4C152.465 169.4 174.923 146.942 174.923 119.239C174.923 91.5353 152.465 69.0774 124.762 69.0774C97.0583 69.0774 74.6003 91.5353 74.6003 119.239C74.6003 146.942 97.0583 169.4 124.762 169.4Z"
          fill="#ED4961"
          fillOpacity={0.15}
        />
        <path
          d="M124.761 161.374C148.032 161.374 166.897 142.509 166.897 119.239C166.897 95.9679 148.032 77.1033 124.761 77.1033C101.491 77.1033 82.626 95.9679 82.626 119.239C82.626 142.509 101.491 161.374 124.761 161.374Z"
          fill="#ED4961"
          fillOpacity={0.2}
        />
        <path
          d="M125.238 152.345C143.523 152.345 158.345 137.523 158.345 119.238C158.345 100.954 143.523 86.1321 125.238 86.1321C106.954 86.1321 92.1321 100.954 92.1321 119.238C92.1321 137.523 106.954 152.345 125.238 152.345Z"
          fill="#ED4961"
          fillOpacity={0.25}
        />
        <g filter="url(#filter0_d_11597:86093)">
          <path
            d="M124.678 128.017C122.046 128.017 119.912 125.883 119.912 123.251V116.898C119.912 114.266 122.046 112.132 124.678 112.132C125.636 112.123 134.208 110.544 134.208 104.19C134.208 101.013 131.032 97.8363 126.266 97.8363C122.389 97.8363 119.912 101.013 119.912 102.602C119.912 105.234 117.779 107.367 115.147 107.367C112.515 107.367 110.382 105.234 110.382 102.602C110.382 94.8532 117.657 88.3057 126.266 88.3057C138.974 88.3057 143.739 97.8077 143.739 105.778C143.739 112.363 140.122 117.395 133.552 119.947C132.123 120.505 130.709 120.88 129.443 121.134V123.251C129.443 125.883 127.31 128.017 124.678 128.017Z"
            fill="#BE1931"
          />
        </g>
        <g filter="url(#filter1_d_11597:86093)">
          <path
            d="M124.678 140.724C127.309 140.724 129.443 138.591 129.443 135.959C129.443 133.327 127.309 131.194 124.678 131.194C122.046 131.194 119.912 133.327 119.912 135.959C119.912 138.591 122.046 140.724 124.678 140.724Z"
            fill="#BE1931"
          />
        </g>
        <g filter="url(#filter2_d_11597:86093)">
          <path
            d="M146.689 135.959C145.561 135.959 144.647 135.02 144.647 133.862V131.067C144.647 129.908 145.561 128.97 146.689 128.97C147.099 128.966 150.773 128.271 150.773 125.475C150.773 124.077 149.412 122.68 147.37 122.68C145.708 122.68 144.647 124.077 144.647 124.776C144.647 125.934 143.732 126.873 142.604 126.873C141.476 126.873 140.562 125.934 140.562 124.776C140.562 121.367 143.68 118.486 147.37 118.486C152.816 118.486 154.858 122.667 154.858 126.174C154.858 129.071 153.308 131.285 150.492 132.408C149.88 132.654 149.274 132.819 148.731 132.931V133.862C148.731 135.02 147.817 135.959 146.689 135.959Z"
            fill="#BE1931"
          />
        </g>
        <g filter="url(#filter3_d_11597:86093)">
          <path
            d="M146.122 142.313C147.438 142.313 148.504 141.246 148.504 139.93C148.504 138.614 147.438 137.547 146.122 137.547C144.806 137.547 143.739 138.614 143.739 139.93C143.739 141.246 144.806 142.313 146.122 142.313Z"
            fill="#BE1931"
          />
        </g>
        <g filter="url(#filter4_d_11597:86093)">
          <path
            d="M103.801 135.959C102.673 135.959 101.759 135.02 101.759 133.862V131.067C101.759 129.908 102.673 128.97 103.801 128.97C104.212 128.966 107.886 128.271 107.886 125.475C107.886 124.077 106.524 122.68 104.482 122.68C102.82 122.68 101.759 124.077 101.759 124.776C101.759 125.934 100.845 126.873 99.7166 126.873C98.5886 126.873 97.6743 125.934 97.6743 124.776C97.6743 121.367 100.792 118.486 104.482 118.486C109.928 118.486 111.97 122.667 111.97 126.174C111.97 129.071 110.42 131.285 107.605 132.408C106.992 132.654 106.386 132.819 105.843 132.931V133.862C105.843 135.02 104.929 135.959 103.801 135.959Z"
            fill="#BE1931"
          />
        </g>
        <g filter="url(#filter5_d_11597:86093)">
          <path
            d="M103.234 142.313C104.55 142.313 105.616 141.246 105.616 139.93C105.616 138.614 104.55 137.547 103.234 137.547C101.918 137.547 100.851 138.614 100.851 139.93C100.851 141.246 101.918 142.313 103.234 142.313Z"
            fill="#BE1931"
          />
        </g>
        <rect
          x={125}
          y={45}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          fill="#BE1931"
        />
        <rect
          x={125}
          y={77.1738}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          fill="#BE1931"
        />
        <rect
          x={125}
          y={70.739}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          fill="#BE1931"
        />
        <rect
          x={125}
          y={64.3044}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          fill="#BE1931"
        />
        <rect
          x={128.217}
          y={193}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(180 128.217 193)"
          fill="#BE1931"
        />
        <rect
          x={128.217}
          y={160.826}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(180 128.217 160.826)"
          fill="#BE1931"
        />
        <rect
          x={128.217}
          y={167.261}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(180 128.217 167.261)"
          fill="#BE1931"
        />
        <rect
          x={128.217}
          y={173.696}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(180 128.217 173.696)"
          fill="#BE1931"
        />
        <rect
          x={199}
          y={119}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(90 199 119)"
          fill="#BE1931"
        />
        <rect
          x={166.826}
          y={119}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(90 166.826 119)"
          fill="#BE1931"
        />
        <rect
          x={173.261}
          y={119}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(90 173.261 119)"
          fill="#BE1931"
        />
        <rect
          x={179.696}
          y={119}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(90 179.696 119)"
          fill="#BE1931"
        />
        <rect
          x={51}
          y={122.217}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(-90 51 122.217)"
          fill="#BE1931"
        />
        <rect
          x={83.1738}
          y={122.217}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-90 83.1738 122.217)"
          fill="#BE1931"
        />
        <rect
          x={76.739}
          y={122.217}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-90 76.739 122.217)"
          fill="#BE1931"
        />
        <rect
          x={70.304}
          y={122.217}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-90 70.304 122.217)"
          fill="#BE1931"
        />
        <rect
          x={177.327}
          y={66.6733}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(45 177.327 66.6733)"
          fill="#BE1931"
        />
        <rect
          x={154.577}
          y={89.4238}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(45 154.577 89.4238)"
          fill="#BE1931"
        />
        <rect
          x={74.9482}
          y={173.601}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(-135 74.9482 173.601)"
          fill="#BE1931"
        />
        <rect
          x={97.6982}
          y={150.851}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-135 97.6982 150.851)"
          fill="#BE1931"
        />
        <rect
          x={177.327}
          y={171.326}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(135 177.327 171.326)"
          fill="#BE1931"
        />
        <rect
          x={154.577}
          y={148.576}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(135 154.577 148.576)"
          fill="#BE1931"
        />
        <rect
          x={70.3987}
          y={68.9497}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(-45 70.3987 68.9497)"
          fill="#BE1931"
        />
        <rect
          x={93.1492}
          y={91.7}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-45 93.1492 91.7)"
          fill="#BE1931"
        />
        <rect
          x={145.175}
          y={68.7202}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(21.8729 145.175 68.7202)"
          fill="white"
        />
        <rect
          x={149.218}
          y={58.6497}
          width={2.36311}
          height={6.43478}
          rx={1.1436}
          transform="rotate(21.8729 149.218 58.6497)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={106.87}
          y={170.478}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-158.127 106.87 170.478)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.3784}
          rx={1.1436}
          transform="matrix(-0.928012 -0.372549 -0.372549 0.928012 105.941 172.798)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={175.366}
          y={139.409}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(111.873 175.366 139.409)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.5957}
          rx={1.1436}
          transform="matrix(-0.37255 0.928012 0.928012 0.37255 178.494 140.665)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={73.6069}
          y={101.103}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-68.1271 73.6069 101.103)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.42869}
          rx={1.1436}
          transform="matrix(0.372549 -0.928012 -0.928012 -0.372549 69.9937 99.6523)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={174.911}
          y={97.812}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(66.8729 174.911 97.812)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.58471}
          rx={1.1436}
          transform="matrix(0.392772 0.919636 0.919636 -0.392772 178.499 96.2795)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={75.8716}
          y={142.681}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-113.127 75.8716 142.681)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={8.48348}
          rx={1.1436}
          transform="matrix(-0.392772 -0.919636 -0.919636 0.392772 72.4053 144.162)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={146.274}
          y={169.143}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(156.873 146.274 169.143)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.43478}
          rx={1.1436}
          transform="matrix(-0.919636 0.392771 0.392771 0.919636 147.32 171.591)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={101.406}
          y={70.1042}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-23.1271 101.406 70.1042)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.43478}
          rx={1.1436}
          transform="matrix(0.919636 -0.392772 -0.392772 -0.919636 99.6348 65.9541)"
          fill="white"
          fillOpacity={0.7}
        />
      </g>
      <g className="bg-bloom">
        <g clipPath="url(#clip0_11597:86093)">
          <path
            d="M148.144 16.7251C147.275 16.7251 146.571 16.0638 146.571 15.248V13.2784C146.571 12.4625 147.275 11.8013 148.144 11.8013C148.46 11.7983 151.289 11.3089 151.289 9.33936C151.289 8.35459 150.24 7.36983 148.668 7.36983C147.389 7.36983 146.571 8.35459 146.571 8.84698C146.571 9.66286 145.867 10.3241 144.999 10.3241C144.13 10.3241 143.427 9.66286 143.427 8.84698C143.427 6.44513 145.827 4.41553 148.668 4.41553C152.861 4.41553 154.434 7.36096 154.434 9.83174C154.434 11.8727 153.24 13.4325 151.072 14.2238C150.601 14.3966 150.134 14.5128 149.716 14.5916V15.248C149.716 16.0638 149.012 16.7251 148.144 16.7251Z"
            fill="#BE1931"
          />
          <path
            d="M148.144 20.6643C149.012 20.6643 149.716 20.0029 149.716 19.1871C149.716 18.3713 149.012 17.71 148.144 17.71C147.276 17.71 146.572 18.3713 146.572 19.1871C146.572 20.0029 147.276 20.6643 148.144 20.6643Z"
            fill="#BE1931"
          />
          <path
            d="M155.407 19.1872C155.035 19.1872 154.733 18.8963 154.733 18.5373V17.6707C154.733 17.3117 155.035 17.0207 155.407 17.0207C155.543 17.0194 156.755 16.8041 156.755 15.9375C156.755 15.5042 156.306 15.0709 155.632 15.0709C155.084 15.0709 154.733 15.5042 154.733 15.7208C154.733 16.0798 154.432 16.3708 154.059 16.3708C153.687 16.3708 153.385 16.0798 153.385 15.7208C153.385 14.664 154.414 13.771 155.632 13.771C157.429 13.771 158.103 15.067 158.103 16.1541C158.103 17.0521 157.591 17.7385 156.662 18.0866C156.46 18.1627 156.26 18.2138 156.081 18.2485V18.5373C156.081 18.8963 155.779 19.1872 155.407 19.1872Z"
            fill="#BE1931"
          />
          <path
            d="M155.22 21.1568C155.654 21.1568 156.006 20.8262 156.006 20.4183C156.006 20.0104 155.654 19.6797 155.22 19.6797C154.786 19.6797 154.434 20.0104 154.434 20.4183C154.434 20.8262 154.786 21.1568 155.22 21.1568Z"
            fill="#BE1931"
          />
          <path
            d="M141.255 19.1872C140.883 19.1872 140.581 18.8963 140.581 18.5373V17.6707C140.581 17.3117 140.883 17.0207 141.255 17.0207C141.391 17.0194 142.603 16.8041 142.603 15.9375C142.603 15.5042 142.154 15.0709 141.48 15.0709C140.931 15.0709 140.581 15.5042 140.581 15.7208C140.581 16.0798 140.28 16.3708 139.907 16.3708C139.535 16.3708 139.233 16.0798 139.233 15.7208C139.233 14.664 140.262 13.771 141.48 13.771C143.277 13.771 143.951 15.067 143.951 16.1541C143.951 17.0521 143.439 17.7385 142.51 18.0866C142.308 18.1627 142.108 18.2138 141.929 18.2485V18.5373C141.929 18.8963 141.627 19.1872 141.255 19.1872Z"
            fill="#BE1931"
          />
          <path
            d="M141.068 21.1568C141.502 21.1568 141.854 20.8262 141.854 20.4183C141.854 20.0104 141.502 19.6797 141.068 19.6797C140.634 19.6797 140.282 20.0104 140.282 20.4183C140.282 20.8262 140.634 21.1568 141.068 21.1568Z"
            fill="#BE1931"
          />
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip1_11597:86093)">
            <path
              d="M132.659 213.042C133.35 211.847 134.878 211.437 136.074 212.127L138.961 213.794C140.157 214.484 140.567 216.013 139.876 217.209C139.629 217.647 138.098 221.956 140.985 223.623C142.428 224.456 144.705 223.846 145.955 221.681C146.972 219.919 146.178 217.961 145.456 217.544C144.26 216.854 143.851 215.325 144.541 214.129C145.232 212.933 146.76 212.523 147.956 213.214C151.477 215.246 152.543 220.269 150.285 224.181C146.951 229.954 141.384 229.627 137.763 227.536C134.771 225.809 133.434 222.846 133.997 219.191C134.119 218.395 134.32 217.655 134.536 217.013L133.574 216.457C132.378 215.767 131.969 214.238 132.659 213.042Z"
              fill="#BE1931"
            />
            <path
              d="M126.886 209.709C126.195 210.905 126.605 212.434 127.801 213.124C128.996 213.814 130.525 213.405 131.216 212.209C131.906 211.013 131.496 209.484 130.301 208.794C129.105 208.104 127.576 208.513 126.886 209.709Z"
              fill="#BE1931"
            />
            <path
              d="M123.277 220.96C123.573 220.447 124.239 220.278 124.765 220.582L126.035 221.315C126.561 221.619 126.748 222.28 126.452 222.793C126.346 222.981 125.698 224.832 126.969 225.565C127.604 225.932 128.596 225.68 129.132 224.752C129.567 223.997 129.211 223.148 128.893 222.965C128.367 222.661 128.18 222 128.476 221.487C128.772 220.975 129.438 220.805 129.965 221.109C131.514 222.004 132.005 224.176 131.037 225.852C129.608 228.327 127.173 228.158 125.58 227.238C124.263 226.478 123.664 225.193 123.892 223.619C123.941 223.276 124.025 222.958 124.117 222.682L123.694 222.437C123.168 222.134 122.981 221.472 123.277 220.96Z"
              fill="#BE1931"
            />
            <path
              d="M120.539 219.035C120.194 219.633 120.399 220.398 120.997 220.743C121.594 221.088 122.359 220.883 122.704 220.285C123.049 219.687 122.844 218.923 122.247 218.578C121.649 218.232 120.884 218.437 120.539 219.035Z"
              fill="#BE1931"
            />
            <path
              d="M134.527 201.474C134.823 200.961 135.489 200.792 136.015 201.096L137.285 201.829C137.811 202.133 137.998 202.795 137.702 203.307C137.596 203.495 136.948 205.346 138.219 206.08C138.854 206.446 139.846 206.195 140.382 205.267C140.817 204.512 140.461 203.663 140.143 203.479C139.617 203.176 139.43 202.514 139.726 202.002C140.022 201.489 140.688 201.32 141.215 201.624C142.764 202.518 143.255 204.69 142.287 206.367C140.858 208.841 138.423 208.672 136.83 207.752C135.513 206.992 134.914 205.707 135.142 204.133C135.191 203.791 135.275 203.472 135.367 203.196L134.944 202.952C134.418 202.648 134.231 201.987 134.527 201.474Z"
              fill="#BE1931"
            />
            <path
              d="M131.789 199.55C131.444 200.147 131.649 200.912 132.247 201.257C132.844 201.602 133.609 201.397 133.954 200.8C134.299 200.202 134.094 199.437 133.497 199.092C132.899 198.747 132.134 198.952 131.789 199.55Z"
              fill="#BE1931"
            />
          </g>
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip2_11597:86093)">
            <path
              d="M55.3331 142.051C54.7347 143.087 53.4098 143.442 52.3734 142.844L49.8715 141.399C48.8351 140.801 48.4801 139.476 49.0785 138.44C49.2925 138.06 50.6197 134.326 48.1178 132.881C46.8669 132.159 44.8938 132.688 43.8104 134.564C42.9289 136.091 43.6169 137.788 44.2424 138.149C45.2788 138.748 45.6338 140.073 45.0354 141.109C44.4371 142.145 43.1121 142.5 42.0757 141.902C39.0247 140.141 38.1004 135.787 40.0576 132.397C42.9465 127.394 47.7714 127.678 50.91 129.49C53.5025 130.986 54.6617 133.555 54.1733 136.722C54.0678 137.411 53.894 138.053 53.7063 138.61L54.54 139.091C55.5764 139.689 55.9315 141.014 55.3331 142.051Z"
              fill="#BE1931"
            />
            <path
              d="M60.3366 144.939C60.9349 143.903 60.5798 142.578 59.5435 141.98C58.5072 141.381 57.1821 141.736 56.5838 142.773C55.9855 143.809 56.3406 145.134 57.3769 145.733C58.4132 146.331 59.7383 145.976 60.3366 144.939Z"
              fill="#BE1931"
            />
            <path
              d="M63.4644 135.189C63.2079 135.633 62.6305 135.78 62.1745 135.517L61.0736 134.881C60.6176 134.618 60.4559 134.044 60.7123 133.6C60.804 133.438 61.3657 131.833 60.2649 131.197C59.7145 130.88 58.8545 131.098 58.3902 131.902C58.0125 132.556 58.3216 133.292 58.5968 133.451C59.0528 133.714 59.2146 134.288 58.9581 134.732C58.7017 135.176 58.1243 135.323 57.6682 135.059C56.3258 134.284 55.9002 132.402 56.739 130.949C57.9771 128.804 60.0877 128.951 61.4686 129.748C62.6094 130.407 63.1288 131.52 62.931 132.884C62.8883 133.181 62.8155 133.457 62.7362 133.696L63.103 133.908C63.559 134.172 63.7208 134.745 63.4644 135.189Z"
              fill="#BE1931"
            />
            <path
              d="M65.8372 136.857C66.1364 136.339 65.9589 135.676 65.4407 135.377C64.9226 135.078 64.26 135.255 63.9609 135.773C63.6617 136.292 63.8392 136.954 64.3574 137.253C64.8755 137.552 65.5381 137.375 65.8372 136.857Z"
              fill="#BE1931"
            />
            <path
              d="M53.7144 152.077C53.4579 152.521 52.8805 152.667 52.4245 152.404L51.3236 151.769C50.8676 151.505 50.7059 150.932 50.9623 150.488C51.054 150.325 51.6157 148.72 50.5149 148.085C49.9645 147.767 49.1045 147.985 48.6402 148.79C48.2625 149.444 48.5716 150.18 48.8468 150.339C49.3028 150.602 49.4646 151.175 49.2081 151.619C48.9517 152.064 48.3743 152.21 47.9182 151.947C46.5758 151.172 46.1502 149.289 46.989 147.836C48.2271 145.692 50.3377 145.838 51.7186 146.635C52.8594 147.294 53.3788 148.408 53.181 149.772C53.1383 150.069 53.0655 150.345 52.9862 150.584L53.353 150.796C53.809 151.059 53.9708 151.632 53.7144 152.077Z"
              fill="#BE1931"
            />
            <path
              d="M56.0872 153.745C56.3864 153.226 56.2089 152.564 55.6907 152.265C55.1726 151.965 54.51 152.143 54.2109 152.661C53.9117 153.179 54.0892 153.842 54.6074 154.141C55.1255 154.44 55.7881 154.263 56.0872 153.745Z"
              fill="#BE1931"
            />
          </g>
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip3_11597:86093)">
            <path
              d="M174.614 124.908C175.212 125.945 174.857 127.27 173.821 127.868L171.319 129.313C170.283 129.911 168.958 129.556 168.359 128.52C168.138 128.145 165.567 125.128 163.065 126.572C161.815 127.295 161.286 129.268 162.369 131.144C163.251 132.671 165.065 132.924 165.69 132.563C166.726 131.964 168.051 132.319 168.65 133.356C169.248 134.392 168.893 135.717 167.857 136.315C164.806 138.077 160.574 136.701 158.616 133.311C155.728 128.307 158.386 124.271 161.524 122.458C164.117 120.962 166.921 121.242 169.419 123.248C169.964 123.685 170.433 124.156 170.821 124.597L171.654 124.115C172.691 123.517 174.016 123.872 174.614 124.908Z"
              fill="#BE1931"
            />
            <path
              d="M179.618 122.02C179.019 120.983 177.694 120.628 176.658 121.227C175.622 121.825 175.267 123.15 175.865 124.186C176.463 125.223 177.788 125.578 178.825 124.979C179.861 124.381 180.216 123.056 179.618 122.02Z"
              fill="#BE1931"
            />
            <path
              d="M172.737 114.436C172.994 114.88 172.832 115.453 172.376 115.717L171.275 116.352C170.819 116.616 170.242 116.469 169.985 116.025C169.89 115.864 168.782 114.575 167.681 115.211C167.13 115.529 166.89 116.383 167.354 117.187C167.732 117.841 168.523 117.941 168.799 117.782C169.255 117.519 169.832 117.666 170.088 118.11C170.345 118.554 170.183 119.127 169.727 119.391C168.385 120.166 166.541 119.593 165.703 118.14C164.465 115.996 165.647 114.241 167.027 113.444C168.168 112.785 169.392 112.892 170.475 113.745C170.711 113.931 170.913 114.132 171.081 114.32L171.448 114.108C171.904 113.845 172.481 113.992 172.737 114.436Z"
              fill="#BE1931"
            />
            <path
              d="M175.368 113.215C175.069 112.697 174.406 112.519 173.888 112.818C173.37 113.117 173.193 113.78 173.492 114.298C173.791 114.816 174.453 114.994 174.972 114.695C175.49 114.396 175.667 113.733 175.368 113.215Z"
              fill="#BE1931"
            />
            <path
              d="M182.487 131.324C182.744 131.768 182.582 132.341 182.126 132.604L181.025 133.24C180.569 133.503 179.992 133.357 179.735 132.912C179.64 132.752 178.532 131.463 177.431 132.099C176.88 132.416 176.64 133.27 177.104 134.074C177.482 134.729 178.273 134.829 178.549 134.67C179.005 134.407 179.582 134.553 179.838 134.997C180.095 135.442 179.933 136.015 179.477 136.278C178.135 137.053 176.291 136.481 175.453 135.028C174.215 132.883 175.397 131.129 176.777 130.331C177.918 129.673 179.142 129.78 180.225 130.633C180.461 130.819 180.663 131.02 180.831 131.208L181.198 130.996C181.654 130.733 182.231 130.879 182.487 131.324Z"
              fill="#BE1931"
            />
            <path
              d="M185.118 130.102C184.819 129.584 184.156 129.407 183.638 129.706C183.12 130.005 182.943 130.668 183.242 131.186C183.541 131.704 184.203 131.882 184.722 131.582C185.24 131.283 185.417 130.621 185.118 130.102Z"
              fill="#BE1931"
            />
          </g>
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip4_11597:86093)">
            <path
              d="M54.7666 22.356C54.0821 21.264 54.4125 19.8242 55.5045 19.1398L58.1406 17.4876C59.2327 16.8031 60.6725 17.1335 61.3569 18.2255C61.6099 18.6204 64.4943 21.7666 67.1304 20.1144C68.4485 19.2883 68.9404 17.1441 67.7013 15.167C66.693 13.5583 64.731 13.357 64.0719 13.7701C62.9799 14.4545 61.5401 14.1242 60.8557 13.0321C60.1712 11.9401 60.5016 10.5003 61.5936 9.81588C64.8084 7.801 69.4167 9.11676 71.6554 12.6887C74.9599 17.961 72.2567 22.409 68.9497 24.4817C66.218 26.1938 63.1897 26.0017 60.4222 23.9397C59.8191 23.4916 59.296 23.0025 58.8613 22.5434L57.9828 23.094C56.8908 23.7784 55.451 23.4481 54.7666 22.356Z"
              fill="#BE1931"
            />
            <path
              d="M49.4942 25.6605C50.1786 26.7524 51.6185 27.0828 52.7105 26.3984C53.8024 25.7141 54.1328 24.2741 53.4484 23.1822C52.764 22.0903 51.3241 21.7599 50.2321 22.4442C49.1402 23.1286 48.8098 24.5686 49.4942 25.6605Z"
              fill="#BE1931"
            />
            <path
              d="M57.1952 33.5536C56.9018 33.0856 57.0535 32.4622 57.534 32.161L58.6939 31.4341C59.1744 31.1329 59.8016 31.2682 60.0949 31.7362C60.2034 31.9054 61.447 33.2491 62.6069 32.5221C63.1869 32.1586 63.4128 31.2302 62.8817 30.3829C62.4496 29.6935 61.5937 29.6166 61.3037 29.7984C60.8232 30.0995 60.196 29.9643 59.9027 29.4963C59.6094 29.0283 59.7611 28.4049 60.2415 28.1037C61.656 27.2172 63.6621 27.7616 64.6216 29.2924C66.0377 31.552 64.8342 33.4865 63.3791 34.3985C62.1771 35.1518 60.8554 35.0845 59.6573 34.2084C59.3962 34.0179 59.1702 33.8095 58.9827 33.6134L58.5962 33.8557C58.1157 34.1568 57.4885 34.0216 57.1952 33.5536Z"
              fill="#BE1931"
            />
            <path
              d="M54.4115 34.9706C54.7537 35.5166 55.4737 35.6818 56.0197 35.3396C56.5656 34.9974 56.7308 34.2774 56.3886 33.7315C56.0464 33.1855 55.3265 33.0203 54.7805 33.3625C54.2345 33.7047 54.0693 34.4247 54.4115 34.9706Z"
              fill="#BE1931"
            />
            <path
              d="M46.0428 15.7597C45.7495 15.2917 45.9012 14.6682 46.3817 14.3671L47.5416 13.6401C48.0221 13.339 48.6492 13.4742 48.9426 13.9422C49.0511 14.1114 50.2947 15.4551 51.4546 14.7282C52.0345 14.3647 52.2604 13.4363 51.7294 12.589C51.2972 11.8995 50.4413 11.8227 50.1513 12.0044C49.6709 12.3056 49.0437 12.1703 48.7504 11.7023C48.457 11.2343 48.6087 10.6109 49.0892 10.3098C50.5037 9.42322 52.5097 9.96766 53.4692 11.4985C54.8854 13.758 53.6818 15.6926 52.2267 16.6046C51.0248 17.3579 49.7031 17.2905 48.5049 16.4144C48.2438 16.224 48.0178 16.0155 47.8304 15.8195L47.4438 16.0618C46.9633 16.3629 46.3362 16.2277 46.0428 15.7597Z"
              fill="#BE1931"
            />
            <path
              d="M43.2592 17.1767C43.6014 17.7226 44.3214 17.8878 44.8673 17.5457C45.4133 17.2035 45.5785 16.4835 45.2363 15.9375C44.8941 15.3916 44.1741 15.2264 43.6282 15.5686C43.0822 15.9107 42.917 16.6307 43.2592 17.1767Z"
              fill="#BE1931"
            />
          </g>
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip5_11597:86093)">
            <path
              d="M148.751 61.6525C149.368 62.7317 148.993 64.1059 147.914 64.7222L145.309 66.2101C144.229 66.8265 142.855 66.4514 142.239 65.3723C142.011 64.9818 139.356 61.8367 136.751 63.3246C135.448 64.0686 134.89 66.1151 136.006 68.0688C136.914 69.6585 138.796 69.9299 139.447 69.5579C140.526 68.9416 141.901 69.3166 142.517 70.3958C143.133 71.4749 142.758 72.8491 141.679 73.4655C138.502 75.2799 134.114 73.8305 132.098 70.3007C129.122 65.0906 131.902 60.9117 135.17 59.0451C137.87 57.5033 140.78 57.8078 143.365 59.9036C143.928 60.3592 144.413 60.851 144.814 61.3105L145.682 60.8147C146.761 60.1983 148.135 60.5734 148.751 61.6525Z"
              fill="#BE1931"
            />
            <path
              d="M153.962 58.6769C153.345 57.5979 151.971 57.2228 150.892 57.8391C149.813 58.4554 149.438 59.8297 150.054 60.9088C150.67 61.9878 152.045 62.3629 153.124 61.7466C154.203 61.1303 154.578 59.756 153.962 58.6769Z"
              fill="#BE1931"
            />
            <path
              d="M146.853 50.7681C147.117 51.2306 146.947 51.8252 146.472 52.0964L145.326 52.7511C144.851 53.0223 144.252 52.8673 143.988 52.4048C143.89 52.2375 142.745 50.8938 141.599 51.5485C141.025 51.8758 140.771 52.7614 141.249 53.5987C141.639 54.28 142.46 54.3878 142.747 54.2241C143.222 53.9529 143.82 54.108 144.085 54.5705C144.349 55.033 144.178 55.6276 143.703 55.8988C142.305 56.6972 140.394 56.0935 139.53 54.5807C138.255 52.3479 139.491 50.5315 140.929 49.7102C142.116 49.0318 143.387 49.1488 144.507 50.0402C144.751 50.2339 144.961 50.4437 145.133 50.64L145.515 50.4218C145.99 50.1506 146.589 50.3056 146.853 50.7681Z"
              fill="#BE1931"
            />
            <path
              d="M149.591 49.5129C149.283 48.9734 148.596 48.7858 148.056 49.094C147.517 49.4021 147.329 50.0893 147.637 50.6288C147.946 51.1683 148.633 51.3559 149.172 51.0477C149.712 50.7396 149.899 50.0524 149.591 49.5129Z"
              fill="#BE1931"
            />
            <path
              d="M156.897 68.3521C157.161 68.8146 156.99 69.4092 156.515 69.6804L155.369 70.3351C154.894 70.6063 154.295 70.4513 154.031 69.9888C153.933 69.8215 152.788 68.4778 151.642 69.1325C151.069 69.4598 150.815 70.3454 151.293 71.1827C151.682 71.864 152.504 71.9718 152.79 71.8081C153.265 71.5369 153.864 71.692 154.128 72.1545C154.392 72.6169 154.222 73.2116 153.747 73.4828C152.349 74.2812 150.438 73.6775 149.574 72.1647C148.298 69.9318 149.534 68.1154 150.972 67.2941C152.16 66.6157 153.431 66.7328 154.55 67.6241C154.794 67.8179 155.004 68.0277 155.177 68.2239L155.559 68.0058C156.034 67.7346 156.633 67.8896 156.897 68.3521Z"
              fill="#BE1931"
            />
            <path
              d="M159.634 67.0969C159.326 66.5574 158.639 66.3698 158.099 66.678C157.56 66.9861 157.372 67.6733 157.681 68.2128C157.989 68.7523 158.676 68.9399 159.215 68.6317C159.755 68.3236 159.942 67.6364 159.634 67.0969Z"
              fill="#BE1931"
            />
          </g>
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip6_11597:86093)">
            <path
              d="M21.7799 63.8893C21.1616 64.5077 20.1592 64.5077 19.5408 63.8893L18.048 62.3966C17.4296 61.7782 17.4296 60.7758 18.048 60.1574C18.2708 59.9301 19.914 57.545 18.4212 56.0522C17.6748 55.3058 16.182 55.3058 15.0624 56.4254C14.1515 57.3364 14.316 58.6646 14.6892 59.0378C15.3076 59.6562 15.3076 60.6586 14.6892 61.277C14.0709 61.8954 13.0685 61.8954 12.4501 61.277C10.6296 59.4565 10.8005 56.209 12.8233 54.1863C15.8088 51.2007 19.1609 52.3136 21.0336 54.1863C22.5805 55.7332 22.913 57.7652 21.9692 59.9085C21.7643 60.3753 21.5202 60.7955 21.2825 61.1527L21.7799 61.6502C22.3983 62.2685 22.3983 63.271 21.7799 63.8893Z"
              fill="#BE1931"
            />
            <path
              d="M24.7655 66.875C25.3838 66.2566 25.3838 65.2541 24.7655 64.6358C24.1472 64.0175 23.1447 64.0175 22.5263 64.6358C21.908 65.2541 21.908 66.2566 22.5263 66.875C23.1447 67.4933 24.1472 67.4933 24.7655 66.875Z"
              fill="#BE1931"
            />
            <path
              d="M28.8173 60.5839C28.5523 60.8489 28.117 60.8432 27.8449 60.5711L27.1881 59.9143C26.916 59.6422 26.9102 59.2069 27.1753 58.9418C27.2707 58.8444 27.9707 57.818 27.3139 57.1612C26.9855 56.8327 26.3372 56.8242 25.8573 57.304C25.4669 57.6945 25.546 58.2722 25.7102 58.4364C25.9823 58.7085 25.988 59.1438 25.723 59.4089C25.458 59.6739 25.0226 59.6682 24.7506 59.3961C23.9496 58.5951 24.0052 57.1857 24.8721 56.3188C26.1516 55.0393 27.6137 55.5417 28.4377 56.3657C29.1184 57.0464 29.2744 57.9307 28.8767 58.8561C28.7904 59.0577 28.6868 59.2388 28.5856 59.3926L28.8045 59.6115C29.0766 59.8835 29.0823 60.3189 28.8173 60.5839Z"
              fill="#BE1931"
            />
            <path
              d="M30.1769 62.2099C30.4861 61.9008 30.4861 61.3995 30.1769 61.0904C29.8678 60.7812 29.3665 60.7812 29.0574 61.0904C28.7482 61.3995 28.7482 61.9008 29.0574 62.2099C29.3665 62.5191 29.8678 62.5191 30.1769 62.2099Z"
              fill="#BE1931"
            />
            <path
              d="M18.7411 70.6603C18.4761 70.9253 18.0408 70.9196 17.7687 70.6475L17.1119 69.9907C16.8398 69.7186 16.8341 69.2833 17.0991 69.0183C17.1945 68.9208 17.8945 67.8944 17.2377 67.2376C16.9093 66.9092 16.261 66.9006 15.7812 67.3805C15.3908 67.7709 15.4698 68.3486 15.634 68.5128C15.9061 68.7849 15.9118 69.2203 15.6468 69.4853C15.3818 69.7503 14.9465 69.7446 14.6744 69.4725C13.8734 68.6715 13.9291 67.2621 14.7959 66.3952C16.0755 65.1157 17.5376 65.6182 18.3616 66.4421C19.0422 67.1228 19.1982 68.0072 18.8006 68.9326C18.7143 69.1341 18.6107 69.3152 18.5095 69.469L18.7284 69.6879C19.0004 69.96 19.0062 70.3953 18.7411 70.6603Z"
              fill="#BE1931"
            />
            <path
              d="M20.1005 72.2864C20.4097 71.9772 20.4097 71.4759 20.1005 71.1668C19.7914 70.8576 19.2901 70.8576 18.9809 71.1668C18.6718 71.4759 18.6718 71.9772 18.9809 72.2864C19.2901 72.5955 19.7914 72.5955 20.1005 72.2864Z"
              fill="#BE1931"
            />
          </g>
        </g>
        <g clipPath="url(#clip7_11597:86093)">
          <path
            d="M71.7162 213.63C72.3021 213.044 73.2517 213.044 73.8376 213.63L75.2518 215.044C75.8376 215.63 75.8376 216.58 75.2518 217.166C75.0407 217.381 73.484 219.641 74.8982 221.055C75.6053 221.762 77.0195 221.762 78.0802 220.701C78.9432 219.838 78.7873 218.58 78.4338 218.226C77.8479 217.641 77.8479 216.691 78.4338 216.105C79.0196 215.519 79.9692 215.519 80.5551 216.105C82.2797 217.83 82.1178 220.906 80.2015 222.823C77.3731 225.651 74.1975 224.597 72.4234 222.823C70.9579 221.357 70.6429 219.432 71.537 217.402C71.7311 216.959 71.9623 216.561 72.1875 216.223L71.7162 215.752C71.1304 215.166 71.1304 214.216 71.7162 213.63Z"
            fill="#BE1931"
          />
          <path
            d="M68.8876 210.802C68.3018 211.388 68.3018 212.337 68.8876 212.923C69.4734 213.509 70.4231 213.509 71.0089 212.923C71.5947 212.337 71.5947 211.388 71.0089 210.802C70.4231 210.216 69.4734 210.216 68.8876 210.802Z"
            fill="#BE1931"
          />
          <path
            d="M65.0492 216.762C65.3003 216.511 65.7127 216.516 65.9705 216.774L66.5927 217.396C66.8505 217.654 66.8559 218.066 66.6048 218.317C66.5144 218.41 65.8513 219.382 66.4735 220.004C66.7847 220.315 67.3988 220.324 67.8534 219.869C68.2233 219.499 68.1484 218.952 67.9928 218.796C67.735 218.538 67.7296 218.126 67.9807 217.875C68.2317 217.624 68.6442 217.629 68.9019 217.887C69.6608 218.646 69.608 219.981 68.7868 220.802C67.5746 222.015 66.1894 221.539 65.4088 220.758C64.764 220.113 64.6162 219.275 64.9929 218.399C65.0747 218.208 65.1728 218.036 65.2687 217.89L65.0613 217.683C64.8036 217.425 64.7981 217.013 65.0492 216.762Z"
            fill="#BE1931"
          />
          <path
            d="M63.7611 215.221C63.4682 215.514 63.4682 215.989 63.7611 216.282C64.054 216.575 64.5288 216.575 64.8217 216.282C65.1146 215.989 65.1146 215.514 64.8217 215.221C64.5288 214.928 64.054 214.928 63.7611 215.221Z"
            fill="#BE1931"
          />
          <path
            d="M74.5951 207.216C74.8462 206.965 75.2586 206.97 75.5164 207.228L76.1386 207.85C76.3964 208.108 76.4018 208.52 76.1507 208.771C76.0603 208.864 75.3972 209.836 76.0194 210.458C76.3305 210.77 76.9447 210.778 77.3993 210.323C77.7692 209.953 77.6943 209.406 77.5387 209.25C77.2809 208.993 77.2755 208.58 77.5266 208.329C77.7776 208.078 78.1901 208.083 78.4478 208.341C79.2067 209.1 79.1539 210.435 78.3327 211.256C77.1205 212.469 75.7353 211.993 74.9547 211.212C74.3099 210.567 74.1621 209.729 74.5388 208.853C74.6206 208.662 74.7187 208.49 74.8146 208.344L74.6072 208.137C74.3495 207.879 74.344 207.467 74.5951 207.216Z"
            fill="#BE1931"
          />
          <path
            d="M73.307 205.675C73.0141 205.968 73.0141 206.443 73.307 206.736C73.5999 207.029 74.0747 207.029 74.3676 206.736C74.6605 206.443 74.6605 205.968 74.3676 205.675C74.0747 205.382 73.5999 205.382 73.307 205.675Z"
            fill="#BE1931"
          />
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip8_11597:86093)">
            <path
              d="M196.914 58.3852C196.316 57.3488 196.671 56.0239 197.707 55.4255L200.209 53.981C201.245 53.3827 202.57 53.7377 203.169 54.7741C203.39 55.1491 205.961 58.1658 208.463 56.7213C209.714 55.9991 210.242 54.026 209.159 52.1496C208.277 50.6228 206.464 50.3699 205.838 50.731C204.802 51.3294 203.477 50.9744 202.878 49.938C202.28 48.9016 202.635 47.5766 203.671 46.9783C206.722 45.2168 210.954 46.5929 212.912 49.9829C215.801 54.9866 213.142 59.0232 210.004 60.8352C207.411 62.332 204.608 62.0518 202.109 60.0452C201.564 59.6091 201.095 59.1376 200.707 58.6969L199.874 59.1783C198.837 59.7766 197.512 59.4216 196.914 58.3852Z"
              fill="#BE1931"
            />
            <path
              d="M191.91 61.274C192.509 62.3103 193.834 62.6654 194.87 62.0671C195.906 61.4688 196.261 60.1436 195.663 59.1073C195.065 58.071 193.74 57.716 192.703 58.3143C191.667 58.9126 191.312 60.2377 191.91 61.274Z"
              fill="#BE1931"
            />
            <path
              d="M198.791 68.8579C198.534 68.4137 198.696 67.8403 199.152 67.577L200.253 66.9415C200.709 66.6782 201.286 66.8248 201.543 67.269C201.638 67.4297 202.746 68.7184 203.847 68.0829C204.398 67.7651 204.639 66.9112 204.174 66.107C203.796 65.4527 203.005 65.3526 202.73 65.5115C202.274 65.7748 201.696 65.6281 201.44 65.184C201.183 64.7398 201.345 64.1664 201.801 63.9031C203.143 63.1281 204.987 63.7008 205.825 65.1537C207.064 67.2981 205.882 69.0528 204.501 69.8501C203.36 70.5087 202.136 70.4017 201.053 69.5483C200.817 69.3629 200.615 69.1618 200.447 68.9736L200.08 69.1854C199.624 69.4486 199.047 69.302 198.791 68.8579Z"
              fill="#BE1931"
            />
            <path
              d="M196.16 70.0789C196.459 70.5971 197.122 70.7746 197.64 70.4754C198.158 70.1763 198.335 69.5137 198.036 68.9956C197.737 68.4774 197.075 68.2999 196.556 68.599C196.038 68.8982 195.861 69.5608 196.16 70.0789Z"
              fill="#BE1931"
            />
            <path
              d="M189.041 51.9702C188.784 51.526 188.946 50.9526 189.402 50.6893L190.503 50.0538C190.959 49.7905 191.536 49.9371 191.793 50.3813C191.888 50.542 192.996 51.8307 194.097 51.1952C194.648 50.8774 194.889 50.0235 194.424 49.2193C194.046 48.565 193.255 48.4649 192.98 48.6238C192.524 48.8871 191.946 48.7405 191.69 48.2963C191.433 47.8521 191.595 47.2787 192.051 47.0154C193.393 46.2404 195.237 46.8131 196.075 48.266C197.314 50.4104 196.132 52.1651 194.751 52.9624C193.61 53.621 192.386 53.514 191.303 52.6606C191.067 52.4752 190.865 52.2741 190.697 52.0859L190.33 52.2977C189.874 52.561 189.297 52.4143 189.041 51.9702Z"
              fill="#BE1931"
            />
            <path
              d="M186.41 53.1912C186.709 53.7094 187.372 53.8869 187.89 53.5877C188.408 53.2886 188.585 52.626 188.286 52.1079C187.987 51.5897 187.325 51.4122 186.806 51.7113C186.288 52.0105 186.111 52.6731 186.41 53.1912Z"
              fill="#BE1931"
            />
          </g>
        </g>
        <g clipPath="url(#clip9_11597:86093)">
          <path
            d="M70.8012 79.3378C70.2885 79.7281 69.5566 79.6288 69.1663 79.116L68.2242 77.8782C67.834 77.3654 67.9333 76.6335 68.4461 76.2432C68.6313 76.0994 70.0673 74.5207 69.1253 73.2828C68.6542 72.6639 67.5643 72.516 66.6359 73.2226C65.8805 73.7974 65.8691 74.7835 66.1046 75.093C66.4948 75.6058 66.3955 76.3377 65.8828 76.7279C65.37 77.1182 64.6381 77.0189 64.2478 76.5061C63.099 74.9965 63.5455 72.6423 65.2228 71.3658C67.6985 69.4817 70.0357 70.6263 71.2176 72.1792C72.1938 73.4619 72.2352 74.9785 71.3338 76.4499C71.1379 76.7705 70.9181 77.0532 70.7091 77.2904L71.0231 77.7029C71.4133 78.2157 71.314 78.9476 70.8012 79.3378Z"
            fill="#BE1931"
          />
          <path
            d="M72.6852 81.8133C73.198 81.4231 73.2973 80.6911 72.9071 80.1784C72.5169 79.6656 71.7849 79.5663 71.2721 79.9565C70.7594 80.3468 70.6601 81.0787 71.0503 81.5915C71.4405 82.1042 72.1725 82.2035 72.6852 81.8133Z"
            fill="#BE1931"
          />
          <path
            d="M76.267 77.6214C76.0472 77.7886 75.7299 77.7413 75.5582 77.5157L75.1437 76.9711C74.972 76.7454 75.011 76.427 75.2307 76.2598C75.3101 76.1981 75.9228 75.518 75.5083 74.9733C75.3011 74.701 74.8286 74.6305 74.4307 74.9334C74.107 75.1797 74.1074 75.6094 74.2111 75.7456C74.3828 75.9712 74.3438 76.2896 74.1241 76.4569C73.9043 76.6241 73.587 76.5768 73.4153 76.3512C72.9098 75.687 73.0901 74.6634 73.8089 74.1164C74.8699 73.3089 75.8877 73.8206 76.4077 74.5039C76.8373 75.0683 76.8636 75.7295 76.4815 76.3658C76.3986 76.5044 76.305 76.6264 76.2158 76.7286L76.354 76.9101C76.5257 77.1357 76.4867 77.4541 76.267 77.6214Z"
            fill="#BE1931"
          />
          <path
            d="M77.0985 78.9435C77.3548 78.7484 77.4045 78.3824 77.2094 78.1261C77.0143 77.8697 76.6483 77.82 76.3919 78.0151C76.1356 78.2102 76.0859 78.5762 76.281 78.8326C76.4761 79.089 76.8421 79.1386 77.0985 78.9435Z"
            fill="#BE1931"
          />
          <path
            d="M67.9115 83.9803C67.6918 84.1475 67.3745 84.1002 67.2028 83.8746L66.7882 83.3299C66.6165 83.1043 66.6555 82.7859 66.8752 82.6186C66.9546 82.557 67.5674 81.8769 67.1529 81.3322C66.9456 81.0599 66.4731 80.9894 66.0752 81.2922C65.7515 81.5386 65.752 81.9683 65.8556 82.1045C66.0273 82.3301 65.9884 82.6485 65.7686 82.8158C65.5488 82.983 65.2316 82.9357 65.0598 82.7101C64.5543 82.0459 64.7346 81.0223 65.4535 80.4753C66.5145 79.6678 67.5323 80.1795 68.0523 80.8628C68.4818 81.4272 68.5081 82.0884 68.1261 82.7246C68.0431 82.8633 67.9495 82.9852 67.8604 83.0875L67.9985 83.269C68.1702 83.4946 68.1313 83.813 67.9115 83.9803Z"
            fill="#BE1931"
          />
          <path
            d="M68.743 85.3024C68.9994 85.1073 69.049 84.7413 68.8539 84.4849C68.6588 84.2286 68.2928 84.1789 68.0365 84.374C67.7801 84.5691 67.7304 84.9351 67.9255 85.1915C68.1206 85.4479 68.4866 85.4975 68.743 85.3024Z"
            fill="#BE1931"
          />
        </g>
        <g clipPath="url(#clip10_11597:86093)">
          <path
            d="M18.9066 166.694C19.1827 167.172 19.0189 167.784 18.5405 168.06L17.3858 168.727C16.9075 169.003 16.296 168.839 16.0198 168.361C15.9176 168.188 14.7311 166.795 13.5764 167.462C12.9991 167.795 12.7551 168.706 13.2551 169.572C13.6619 170.277 14.4991 170.393 14.7878 170.227C15.2661 169.951 15.8776 170.114 16.1538 170.593C16.43 171.071 16.2661 171.683 15.7878 171.959C14.3796 172.772 12.4264 172.137 11.523 170.572C10.1897 168.263 11.4166 166.4 12.8651 165.563C14.0617 164.872 15.3557 165.002 16.5089 165.928C16.7603 166.129 16.9767 166.347 17.1557 166.55L17.5405 166.328C18.0189 166.052 18.6304 166.216 18.9066 166.694Z"
            fill="#BE1931"
          />
          <path
            d="M21.2159 165.361C20.9398 164.882 20.3282 164.719 19.8499 164.995C19.3716 165.271 19.2077 165.882 19.4839 166.361C19.76 166.839 20.3716 167.003 20.8499 166.727C21.3282 166.451 21.4921 165.839 21.2159 165.361Z"
            fill="#BE1931"
          />
          <path
            d="M18.0401 161.861C18.1585 162.066 18.0838 162.33 17.8734 162.452L17.3653 162.745C17.1548 162.867 16.8883 162.799 16.77 162.594C16.7261 162.52 16.2144 161.925 15.7063 162.218C15.4523 162.365 15.3411 162.759 15.5554 163.13C15.7297 163.432 16.0951 163.478 16.2222 163.405C16.4326 163.284 16.6991 163.351 16.8175 163.556C16.9358 163.761 16.8612 164.026 16.6507 164.147C16.0311 164.505 15.1804 164.241 14.7933 163.57C14.2219 162.58 14.7674 161.771 15.4048 161.403C15.9312 161.099 16.4963 161.148 16.9958 161.542C17.1047 161.628 17.1982 161.72 17.2755 161.807L17.4448 161.709C17.6553 161.588 17.9218 161.656 18.0401 161.861Z"
            fill="#BE1931"
          />
          <path
            d="M19.2545 161.297C19.1164 161.058 18.8106 160.976 18.5714 161.114C18.3323 161.252 18.2504 161.558 18.3884 161.797C18.5265 162.036 18.8323 162.118 19.0714 161.98C19.3106 161.842 19.3925 161.536 19.2545 161.297Z"
            fill="#BE1931"
          />
          <path
            d="M22.5401 169.655C22.6585 169.86 22.5838 170.124 22.3734 170.246L21.8653 170.539C21.6548 170.661 21.3883 170.593 21.27 170.388C21.2261 170.314 20.7144 169.719 20.2063 170.012C19.9523 170.159 19.8411 170.553 20.0554 170.924C20.2297 171.226 20.5951 171.273 20.7222 171.199C20.9326 171.078 21.1991 171.145 21.3175 171.35C21.4358 171.555 21.3612 171.82 21.1507 171.942C20.5311 172.299 19.6804 172.035 19.2933 171.364C18.7219 170.375 19.2674 169.565 19.9048 169.197C20.4312 168.893 20.9963 168.942 21.4958 169.336C21.6047 169.422 21.6982 169.514 21.7755 169.601L21.9448 169.504C22.1553 169.382 22.4218 169.45 22.5401 169.655Z"
            fill="#BE1931"
          />
          <path
            d="M23.7545 169.091C23.6164 168.852 23.3106 168.77 23.0714 168.908C22.8323 169.046 22.7504 169.352 22.8884 169.591C23.0265 169.83 23.3323 169.912 23.5714 169.774C23.8106 169.636 23.8925 169.33 23.7545 169.091Z"
            fill="#BE1931"
          />
        </g>
        <g clipPath="url(#clip11_11597:86093)">
          <path
            d="M216.192 194.26C215.573 194.878 214.571 194.878 213.953 194.26L212.46 192.767C211.841 192.149 211.841 191.146 212.46 190.528C212.683 190.3 214.326 187.915 212.833 186.423C212.087 185.676 210.594 185.676 209.474 186.796C208.563 187.707 208.728 189.035 209.101 189.408C209.719 190.027 209.719 191.029 209.101 191.647C208.483 192.266 207.48 192.266 206.862 191.647C205.041 189.827 205.212 186.579 207.235 184.557C210.221 181.571 213.573 182.684 215.445 184.557C216.992 186.104 217.325 188.136 216.381 190.279C216.176 190.746 215.932 191.166 215.694 191.523L216.192 192.021C216.81 192.639 216.81 193.641 216.192 194.26Z"
            fill="#BE1931"
          />
          <path
            d="M219.177 197.245C219.796 196.627 219.796 195.624 219.177 195.006C218.559 194.388 217.557 194.388 216.938 195.006C216.32 195.624 216.32 196.627 216.938 197.245C217.557 197.864 218.559 197.864 219.177 197.245Z"
            fill="#BE1931"
          />
          <path
            d="M223.229 190.954C222.964 191.219 222.529 191.214 222.257 190.941L221.6 190.285C221.328 190.013 221.322 189.577 221.587 189.312C221.683 189.215 222.383 188.188 221.726 187.532C221.397 187.203 220.749 187.195 220.269 187.674C219.879 188.065 219.958 188.643 220.122 188.807C220.394 189.079 220.4 189.514 220.135 189.779C219.87 190.044 219.435 190.039 219.162 189.766C218.361 188.965 218.417 187.556 219.284 186.689C220.564 185.41 222.026 185.912 222.85 186.736C223.53 187.417 223.686 188.301 223.289 189.227C223.202 189.428 223.099 189.609 222.998 189.763L223.216 189.982C223.488 190.254 223.494 190.689 223.229 190.954Z"
            fill="#BE1931"
          />
          <path
            d="M224.589 192.58C224.898 192.271 224.898 191.77 224.589 191.461C224.28 191.152 223.778 191.152 223.469 191.461C223.16 191.77 223.16 192.271 223.469 192.58C223.778 192.889 224.28 192.889 224.589 192.58Z"
            fill="#BE1931"
          />
          <path
            d="M213.153 201.031C212.888 201.296 212.453 201.29 212.181 201.018L211.524 200.361C211.252 200.089 211.246 199.654 211.511 199.389C211.606 199.291 212.306 198.265 211.65 197.608C211.321 197.28 210.673 197.271 210.193 197.751C209.803 198.141 209.882 198.719 210.046 198.883C210.318 199.155 210.324 199.591 210.059 199.856C209.794 200.121 209.358 200.115 209.086 199.843C208.285 199.042 208.341 197.632 209.208 196.766C210.487 195.486 211.949 195.989 212.773 196.812C213.454 197.493 213.61 198.378 213.212 199.303C213.126 199.504 213.023 199.686 212.921 199.839L213.14 200.058C213.412 200.33 213.418 200.766 213.153 201.031Z"
            fill="#BE1931"
          />
          <path
            d="M214.512 202.657C214.822 202.348 214.822 201.846 214.512 201.537C214.203 201.228 213.702 201.228 213.393 201.537C213.084 201.846 213.084 202.348 213.393 202.657C213.702 202.966 214.203 202.966 214.512 202.657Z"
            fill="#BE1931"
          />
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip12_11597:86093)">
            <path
              d="M116.253 171.983C116.811 172.306 117.002 173.019 116.68 173.577L115.902 174.924C115.58 175.482 114.867 175.673 114.309 175.351C114.104 175.236 112.094 174.521 111.316 175.869C110.927 176.542 111.212 177.605 112.222 178.188C113.044 178.663 113.958 178.292 114.152 177.955C114.475 177.397 115.188 177.206 115.746 177.528C116.304 177.85 116.495 178.564 116.173 179.122C115.225 180.765 112.881 181.263 111.055 180.209C108.361 178.653 108.514 176.055 109.489 174.365C110.295 172.969 111.678 172.345 113.384 172.608C113.755 172.665 114.101 172.758 114.4 172.859L114.659 172.41C114.982 171.852 115.695 171.661 116.253 171.983Z"
              fill="#BE1931"
            />
            <path
              d="M117.809 169.289C117.251 168.967 116.537 169.158 116.215 169.716C115.893 170.274 116.084 170.988 116.642 171.31C117.2 171.632 117.914 171.441 118.236 170.883C118.558 170.325 118.367 169.611 117.809 169.289Z"
              fill="#BE1931"
            />
            <path
              d="M112.558 167.605C112.798 167.743 112.876 168.054 112.735 168.3L112.392 168.892C112.251 169.138 111.942 169.225 111.703 169.087C111.615 169.038 110.751 168.735 110.409 169.328C110.238 169.624 110.355 170.087 110.788 170.337C111.141 170.541 111.537 170.374 111.622 170.226C111.764 169.981 112.073 169.893 112.312 170.032C112.551 170.17 112.63 170.481 112.489 170.726C112.071 171.449 111.057 171.678 110.275 171.226C109.12 170.56 109.199 169.423 109.629 168.68C109.983 168.066 110.583 167.786 111.317 167.892C111.477 167.915 111.626 167.955 111.755 167.997L111.869 167.8C112.01 167.554 112.319 167.467 112.558 167.605Z"
              fill="#BE1931"
            />
            <path
              d="M113.457 166.327C113.178 166.166 112.821 166.262 112.66 166.541C112.499 166.82 112.594 167.177 112.873 167.338C113.152 167.499 113.509 167.403 113.67 167.124C113.831 166.845 113.736 166.488 113.457 166.327Z"
              fill="#BE1931"
            />
            <path
              d="M121.652 172.855C121.891 172.993 121.97 173.304 121.828 173.55L121.486 174.142C121.344 174.388 121.035 174.475 120.796 174.337C120.709 174.288 119.844 173.985 119.502 174.578C119.331 174.874 119.449 175.337 119.882 175.587C120.234 175.791 120.63 175.624 120.716 175.476C120.858 175.231 121.166 175.143 121.405 175.282C121.645 175.42 121.724 175.731 121.582 175.976C121.164 176.699 120.151 176.928 119.368 176.476C118.214 175.81 118.292 174.673 118.722 173.93C119.076 173.316 119.676 173.036 120.411 173.142C120.571 173.165 120.719 173.205 120.848 173.247L120.962 173.05C121.104 172.804 121.412 172.717 121.652 172.855Z"
              fill="#BE1931"
            />
            <path
              d="M122.55 171.577C122.271 171.416 121.914 171.512 121.753 171.791C121.592 172.07 121.687 172.427 121.966 172.588C122.246 172.749 122.602 172.653 122.763 172.374C122.924 172.095 122.829 171.738 122.55 171.577Z"
              fill="#BE1931"
            />
          </g>
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip13_11597:86093)">
            <path
              d="M9.00008 106.692C9.00008 107.244 8.55241 107.692 8.00008 107.692L6.66675 107.692C6.11441 107.692 5.66675 107.244 5.66675 106.692C5.66475 106.491 5.33341 104.692 4.00008 104.692C3.33341 104.692 2.66675 105.359 2.66675 106.359C2.66675 107.172 3.33341 107.692 3.66675 107.692C4.21908 107.692 4.66675 108.14 4.66675 108.692C4.66675 109.244 4.21908 109.692 3.66675 109.692C2.04075 109.692 0.666748 108.165 0.666748 106.359C0.666748 103.692 2.66075 102.692 4.33341 102.692C5.71508 102.692 6.77108 103.451 7.30675 104.83C7.42375 105.13 7.50241 105.426 7.55575 105.692L8.00008 105.692C8.55241 105.692 9.00008 106.14 9.00008 106.692Z"
              fill="#BE1931"
            />
            <path
              d="M11.6667 106.692C11.6667 106.14 11.219 105.692 10.6667 105.692C10.1145 105.692 9.66675 106.14 9.66675 106.692C9.66675 107.244 10.1145 107.692 10.6667 107.692C11.219 107.692 11.6667 107.244 11.6667 106.692Z"
              fill="#BE1931"
            />
            <path
              d="M10.6667 102.073C10.6667 102.31 10.4697 102.501 10.2267 102.501L9.64 102.501C9.39697 102.501 9.2 102.31 9.2 102.073C9.19912 101.987 9.05333 101.216 8.46667 101.216C8.17333 101.216 7.88 101.501 7.88 101.93C7.88 102.279 8.17333 102.501 8.32 102.501C8.56303 102.501 8.76 102.693 8.76 102.93C8.76 103.167 8.56303 103.359 8.32 103.359C7.60456 103.359 7 102.704 7 101.93C7 100.787 7.87736 100.359 8.61333 100.359C9.22127 100.359 9.68591 100.684 9.9216 101.275C9.97308 101.403 10.0077 101.53 10.0312 101.644L10.2267 101.644C10.4697 101.644 10.6667 101.836 10.6667 102.073Z"
              fill="#BE1931"
            />
            <path
              d="M12 102.192C12 101.916 11.7761 101.692 11.5 101.692C11.2239 101.692 11 101.916 11 102.192C11 102.468 11.2239 102.692 11.5 102.692C11.7761 102.692 12 102.468 12 102.192Z"
              fill="#BE1931"
            />
            <path
              d="M10.6667 111.073C10.6667 111.31 10.4697 111.501 10.2267 111.501L9.64 111.501C9.39697 111.501 9.2 111.31 9.2 111.073C9.19912 110.987 9.05333 110.216 8.46667 110.216C8.17333 110.216 7.88 110.501 7.88 110.93C7.88 111.279 8.17333 111.501 8.32 111.501C8.56303 111.501 8.76 111.693 8.76 111.93C8.76 112.167 8.56303 112.359 8.32 112.359C7.60456 112.359 7 111.704 7 110.93C7 109.787 7.87736 109.359 8.61333 109.359C9.22127 109.359 9.68591 109.684 9.9216 110.275C9.97308 110.403 10.0077 110.53 10.0312 110.644L10.2267 110.644C10.4697 110.644 10.6667 110.836 10.6667 111.073Z"
              fill="#BE1931"
            />
            <path
              d="M12 111.192C12 110.916 11.7761 110.692 11.5 110.692C11.2239 110.692 11 110.916 11 111.192C11 111.468 11.2239 111.692 11.5 111.692C11.7761 111.692 12 111.468 12 111.192Z"
              fill="#BE1931"
            />
          </g>
        </g>
        <g clipPath="url(#clip14_11597:86093)">
          <path
            d="M238.163 109.336C238.163 108.6 238.759 108.003 239.496 108.003L241.274 108.003C242.01 108.003 242.607 108.6 242.607 109.336C242.61 109.604 243.051 112.003 244.829 112.003C245.718 112.003 246.607 111.114 246.607 109.781C246.607 108.696 245.718 108.003 245.274 108.003C244.537 108.003 243.94 107.406 243.94 106.67C243.94 105.933 244.537 105.336 245.274 105.336C247.442 105.336 249.274 107.372 249.274 109.781C249.274 113.336 246.615 114.67 244.385 114.67C242.543 114.67 241.135 113.658 240.42 111.819C240.264 111.419 240.159 111.024 240.088 110.67L239.496 110.67C238.759 110.67 238.163 110.073 238.163 109.336Z"
            fill="#BE1931"
          />
          <path
            d="M234.607 109.336C234.607 110.072 235.204 110.669 235.94 110.669C236.677 110.669 237.274 110.072 237.274 109.336C237.274 108.6 236.677 108.003 235.94 108.003C235.204 108.003 234.607 108.6 234.607 109.336Z"
            fill="#BE1931"
          />
          <path
            d="M235.94 115.495C235.94 115.179 236.203 114.923 236.527 114.923L237.309 114.923C237.633 114.923 237.896 115.179 237.896 115.495C237.897 115.61 238.092 116.638 238.874 116.638C239.265 116.638 239.656 116.257 239.656 115.685C239.656 115.22 239.265 114.923 239.069 114.923C238.745 114.923 238.483 114.668 238.483 114.352C238.483 114.036 238.745 113.781 239.069 113.781C240.023 113.781 240.829 114.653 240.829 115.685C240.829 117.209 239.66 117.781 238.678 117.781C237.868 117.781 237.248 117.347 236.934 116.559C236.865 116.388 236.819 116.218 236.788 116.066L236.527 116.066C236.203 116.066 235.94 115.81 235.94 115.495Z"
            fill="#BE1931"
          />
          <path
            d="M234.163 115.336C234.163 115.704 234.461 116.003 234.829 116.003C235.198 116.003 235.496 115.704 235.496 115.336C235.496 114.968 235.198 114.669 234.829 114.669C234.461 114.669 234.163 114.968 234.163 115.336Z"
            fill="#BE1931"
          />
          <path
            d="M235.94 103.495C235.94 103.179 236.203 102.923 236.527 102.923L237.309 102.923C237.633 102.923 237.896 103.179 237.896 103.495C237.897 103.61 238.092 104.638 238.874 104.638C239.265 104.638 239.656 104.257 239.656 103.685C239.656 103.22 239.265 102.923 239.069 102.923C238.745 102.923 238.483 102.668 238.483 102.352C238.483 102.036 238.745 101.781 239.069 101.781C240.023 101.781 240.829 102.653 240.829 103.685C240.829 105.209 239.66 105.781 238.678 105.781C237.868 105.781 237.248 105.347 236.934 104.559C236.865 104.388 236.819 104.218 236.788 104.066L236.527 104.066C236.203 104.066 235.94 103.81 235.94 103.495Z"
            fill="#BE1931"
          />
          <path
            d="M234.163 103.336C234.163 103.704 234.461 104.003 234.829 104.003C235.198 104.003 235.496 103.704 235.496 103.336C235.496 102.968 235.198 102.669 234.829 102.669C234.461 102.669 234.163 102.968 234.163 103.336Z"
            fill="#BE1931"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_11597:86093"
          x={106.382}
          y={88.3057}
          width={41.3572}
          height={47.7109}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11597:86093"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11597:86093"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_11597:86093"
          x={115.912}
          y={131.194}
          width={17.5305}
          height={17.5305}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11597:86093"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11597:86093"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_11597:86093"
          x={136.562}
          y={118.486}
          width={22.2959}
          height={25.4729}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11597:86093"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11597:86093"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_11597:86093"
          x={139.739}
          y={137.547}
          width={12.7654}
          height={12.7654}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11597:86093"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11597:86093"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_11597:86093"
          x={93.6743}
          y={118.486}
          width={22.2959}
          height={25.4729}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11597:86093"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11597:86093"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_11597:86093"
          x={96.8511}
          y={137.547}
          width={12.7654}
          height={12.7654}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11597:86093"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11597:86093"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_11597:86093">
          <rect
            width={18.8694}
            height={17.7258}
            fill="white"
            transform="translate(139.233 3.43091)"
          />
        </clipPath>
        <clipPath id="clip1_11597:86093">
          <rect
            width={30}
            height={30}
            fill="white"
            transform="translate(159.228 212.024) rotate(120)"
          />
        </clipPath>
        <clipPath id="clip2_11597:86093">
          <rect
            width={26}
            height={26}
            fill="white"
            transform="translate(32.3066 142.934) rotate(-60)"
          />
        </clipPath>
        <clipPath id="clip3_11597:86093">
          <rect
            width={26}
            height={26}
            fill="white"
            transform="translate(163.865 145.292) rotate(-120)"
          />
        </clipPath>
        <clipPath id="clip4_11597:86093">
          <rect
            width={28}
            height={28}
            fill="white"
            transform="translate(65.5386) rotate(57.9222)"
          />
        </clipPath>
        <clipPath id="clip5_11597:86093">
          <rect
            width={27}
            height={27}
            fill="white"
            transform="translate(137.491 82.7673) rotate(-119.734)"
          />
        </clipPath>
        <clipPath id="clip6_11597:86093">
          <rect
            width={19}
            height={19}
            fill="white"
            transform="translate(5.35938 60.1575) rotate(-45)"
          />
        </clipPath>
        <clipPath id="clip7_11597:86093">
          <rect
            width={18}
            height={18}
            fill="white"
            transform="translate(87.2725 217.166) rotate(135)"
          />
        </clipPath>
        <clipPath id="clip8_11597:86093">
          <rect
            width={26}
            height={26}
            fill="white"
            transform="translate(207.663 38.0022) rotate(60)"
          />
        </clipPath>
        <clipPath id="clip9_11597:86093">
          <rect
            width={14}
            height={14}
            fill="white"
            transform="translate(59.1814 74.9861) rotate(-37.273)"
          />
        </clipPath>
        <clipPath id="clip10_11597:86093">
          <rect
            width={12}
            height={12}
            fill="white"
            transform="translate(13.9456 176.102) rotate(-120)"
          />
        </clipPath>
        <clipPath id="clip11_11597:86093">
          <rect
            width={19}
            height={19}
            fill="white"
            transform="translate(199.771 190.528) rotate(-45)"
          />
        </clipPath>
        <clipPath id="clip12_11597:86093">
          <rect
            width={14}
            height={14}
            fill="white"
            transform="translate(116.729 184.382) rotate(-150)"
          />
        </clipPath>
        <clipPath id="clip13_11597:86093">
          <rect
            width={12}
            height={12}
            fill="white"
            transform="translate(0 112.359) rotate(-90)"
          />
        </clipPath>
        <clipPath id="clip14_11597:86093">
          <rect
            width={16}
            height={16}
            fill="white"
            transform="translate(250.163 101.781) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
