import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  FilterFieldButton,
  IFilterableSource,
  ISortableSource,
  SortFieldButton,
} from '../input';
import { CatalogState } from '../layout/catalogState';
import { ICatalogSource } from '../layout';
import { JobModel, JobStatusList } from '../../entities';
import { MenuItem } from '../input/menuItems';
import { getEnrichmentLevelLabel, JobLevel } from '../../entities/job/jobFeatures';
import { getJobSpecialityLabel, Specialities } from '../../entities/job/jobSpeciality';

type Props = {
  model: CatalogState;
  source: ICatalogSource<JobModel> & ISortableSource & IFilterableSource;
  teamId?: string;
};

export const TrainerJobTableCatalogHeader = observer(
  ({ model, source, teamId }: Props) => {
    return (
      <div className="job-table-catalog-header catalog-filters">
        <table className="job-table-header job-table-core">
          <thead>
            <tr>
              <th className="thumb-col"></th>
              <th className="title-col">
                <SortFieldButton
                  label="Video Title"
                  name="title"
                  source={source}
                />
              </th>

              <th className="level-col">
                <FilterFieldButton
                  label={getEnrichmentLevelLabel(source.getFilterFieldValue('level')) || 'Level'}
                  selected={!!source.getFilterFieldValue('level')}>
                  <MenuItem
                    model={{ value: 'All', label: 'All' }}
                    onClick={(evt) =>
                      source.setFilterFieldValue('level', null)
                    }
                    isSelected={!source.getFilterFieldValue('level')}
                  />
                  {JobLevel.map((type) => (
                    <MenuItem
                      key={type}
                      model={{ value: type, label: getEnrichmentLevelLabel(type) }}
                      onClick={(evt) =>
                        source.setFilterFieldValue('level', type)
                      }
                      isSelected={source.getFilterFieldValue('level') === type}
                    />
                  ))}
                </FilterFieldButton>
              </th>

              <th className="status-col">
                <FilterFieldButton
                  label={source.getFilterFieldValue('status') || 'Status'}
                  selected={!!source.getFilterFieldValue('status')}>
                  <MenuItem
                    model={{ value: 'All', label: 'All' }}
                    onClick={(evt) =>
                      source.setFilterFieldValue('status', null)
                    }
                    isSelected={!source.getFilterFieldValue('status')}
                  />

                  {JobStatusList.map((type) => (
                    <MenuItem
                      key={type}
                      model={{ value: type, label: type }}
                      onClick={(evt) =>
                        source.setFilterFieldValue('status', type)
                      }
                      isSelected={source.getFilterFieldValue('status') === type}
                    />
                  ))}
                </FilterFieldButton>
              </th>

              <th className="type-col">
                <FilterFieldButton
                  label={(source.getFilterFieldValue('speciality') && getJobSpecialityLabel(source.getFilterFieldValue('speciality'))) || 'Speciality'}
                  selected={!!source.getFilterFieldValue('speciality')}>
                  <MenuItem
                    model={{ value: 'All', label: 'All' }}
                    onClick={(evt) =>
                      source.setFilterFieldValue('speciality', null)
                    }
                    isSelected={!source.getFilterFieldValue('speciality')}
                  />

                  {Specialities.map((speciality) => (
                    <MenuItem
                      key={speciality}
                      model={{
                        label: getJobSpecialityLabel(speciality),
                        value: speciality
                      }}
                      onClick={(evt) =>
                        source.setFilterFieldValue('speciality', speciality)
                      }
                      isSelected={
                        source.getFilterFieldValue('speciality') === speciality
                      }
                    />
                  ))}
                </FilterFieldButton>
              </th>
              <th className="uploaded-by-col">
                <SortFieldButton
                  label="Uploaded By"
                  name="uploaderName"
                  source={source}
                />
              </th>

              <th className="upload-col">
                <SortFieldButton
                  label="Uploaded"
                  name="createdAt"
                  source={source}
                />
              </th>
              <th className="owner-col">
                <SortFieldButton
                  label="Owner"
                  name="ownerName"
                  source={source}
                />
              </th>
              <th className="team-col">
                <SortFieldButton
                  label="Found in"
                  name="teamId"
                  source={source}
                  disabled={true}
                />
              </th>

              <th className="options-col" />
            </tr>
          </thead>
        </table>
      </div>
    );
  }
);
