import { action, computed, makeObservable, observable } from 'mobx';
import { assert } from '../../core';

export type SelectorCoreData<TKey> = {
  selectedKeys?: TKey[],
  keyPool?: TKey[]
}

/**
 * Simple abstraction around a selector functionality, which supports 
 * selection / deselection / toggling and querying of selection state.
 */
export class SelectorCore<TKey> {

  /** Gets the set of currently selected keys. */
  readonly selectedKeys = observable.set<TKey>([], { deep: false });

  /** 
   * Gets the set of all keys which can be selected. If this is empty, 
   * all checks against the key pool will not be performed (any key can be selected).
   */
  readonly keyPool = observable.set<TKey>([], { deep: false });

  /** Returns true if the key pool is not empty. */
  @computed get hasKeyPool() {
    return this.keyPool.size > 0;
  }

  /**
   * @alias unselectedKeys 
   */
   get keys(): Set<TKey> {
    return this.selectedKeys;
  }

  /** 
   * Returns true if all the keys from the key pool have been selected. 
   * This returns false if no key pool has been specified. 
   */
  @computed get areAllSelected() {
    return (
      this.keyPool.size > 0 &&
      this.keyPool.size === this.selectedKeys.size);
  }

  @computed get isEmpty() {
    return this.selectedKeys.size === 0;
  }

  /** Creates a new instance of SelectorCore. */
  constructor() {
    makeObservable(this);
  }

  /** Returns true if the key is selected. */
  isSelected(key: TKey) {
    return this.selectedKeys.has(key);
  }

  /** 
   * Adds the key to the selection set. 
   * If a key pool has been specified, the selected key must be part of the key pool, otherwise an error is thrown.
   */
  @action
  select(key: TKey): this {
    if (this.hasKeyPool) {
      assert(this.keyPool.has(key), `Cannot select key '${key}' because it does not appear in the selector's key pool.`);
    }

    this.selectedKeys.add(key);
    return this;
  }

  @action
  selectMany(keys: Iterable<TKey>): this {
    for (const key of keys)
      this.select(key);
    return this;
  }

  /** 
   * Removes the key from the selection set.
   * If the key wasn't selected, the action will be ignored.
   */
  @action
  unselect(key: TKey): this {
    this.selectedKeys.delete(key);
    return this;
  }
  @action
  unselectMany(keys: Iterable<TKey>): this {
    for (const key of keys)
      this.unselect(key);
    return this;
  }

  
  @action
  remove(key: TKey): this {
    this.selectedKeys.delete(key);
    return this;
  }

  @action
  removeMany(keys: Iterable<TKey>): this {
    for (const key of keys)
      this.remove(key);
    return this;
  }

  /** 
   * Selects the key if it hasn't been selected already, and unselects it otherwise.
   */
  @action
  toggle(key: TKey): this {
    if (this.isSelected(key))
      this.unselect(key);
    else
      this.select(key);
    return this;
  }

  /** Clears the current selection state. */
  @action
  clear(): this {
    this.selectedKeys.clear();
    return this;
  }

  @action
  selectAll(): this {
    if (!this.hasKeyPool)
      return this;

    this.keyPool.forEach(key =>
      this.select(key));
    return this;
  }

  /** 
   * Sets a new key pool for the current selector.
   * All keys that have been already selected must be part of the new key pool, otherwise an error will be thrown.
   */
  @action
  setKeyPool(keys: Iterable<TKey>) {
    this.keyPool.replace([...keys]);
    if (!this.hasKeyPool)
      return this;

    // must validate that the current selection does not contain values that are not in the key pool
    // only done if the key pool is set
    for (let key of this.selectedKeys) {
      assert(this.keyPool.has(key), `Cannot set new key pool because the current selection contains key ${key} which is not in the new key pool.`);
    }

    return this;
  }

  /** Clears all the keys from the key pool. */
  @action
  clearKeyPool() {
    this.keyPool.clear();
    return this;
  }

  @action
  reset() {
    this.clearKeyPool();
    this.clear();
  }

  export(): SelectorCoreData<TKey> {
    return {
      selectedKeys: [...this.selectedKeys],
      keyPool: [...this.keyPool]
    }
  }

  @action
  import(data?: SelectorCoreData<TKey>): this {
    this.reset();
    
    if (data?.keyPool)
      this.setKeyPool(data.keyPool);
    if (data?.selectedKeys)
      this.selectMany(data.selectedKeys);
    return this;
  }
}