import './playerComments.scss';

import React, { useLayoutEffect, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { CommentsSVGIcon } from '../svg';
import { PlayerCommentsState } from './playerCommentsState';
import { CommentInput } from './commentInput';
import { CommentThread } from './commentThread';
import { PlayerSectionCloseButton } from '../playerShared/playerSectionCloseButton';
import { PlayerSectionHeaderButton } from '../playerShared/playerSectionHeaderButton';
import { Comment } from '../../entities/comment';
import { LoadMask } from '../loader/loadMask';
import { Button } from '../input';
import { PlayerState } from '../player/playerState';
import { SyncStatus } from '../../entities';
import { ErrorMessageBox } from '../player/components/errorMessageBox';
import { PlayerComponentName } from '../player/playerSchema';
import { useAuthContext } from '../../services/auth/authHooks';

type Props = {
  model: PlayerCommentsState,
  player: PlayerState,
  onClose?: () => void
};

export const PlayerComments = observer(({
  model,
  player,
  onClose
}: Props) => {
  const scrollbarOffset = model.scrollbarWidth
    ? { marginRight: model.scrollbarWidth }
    : undefined;

  useEffect(() => {
    model.mounted();
  }, [model]);


  useLayoutEffect(() => {
    if (!model.scrollbarWidth && model.scrollElementProxy.current) {
      model.setScrollbarWidth(model.scrollElementProxy.current!);
    }
  }, [model]);

  const { commentsSyncStatus } = model;

  const showPlayerCommentsFooter =
    commentsSyncStatus &&
    ![SyncStatus.Error, SyncStatus.Fetching].includes(commentsSyncStatus);

  const isLoading = commentsSyncStatus === SyncStatus.Fetching;
  const isDisabled = player.isComponentDisabled(PlayerComponentName.CommentsSection);

  return (
    <section
      className="comments-section user-player-section vertical"
      ref={model.sectionProxy.ref}
      aria-disabled={isDisabled}
      data-loading={isLoading}
      data-tutorial-id="player-comments"
      id="player-comments">
      <PlayerCommentsSectionBody
        model={model} />

      <PlayerCommentsSectionHeader
        model={model}
        style={scrollbarOffset} />

      {showPlayerCommentsFooter &&
        <PlayerCommentsSectionFooter
          model={model}
          style={scrollbarOffset} />}

      <div className="section-close">
        <PlayerSectionCloseButton
          model={model}
          onClose={onClose} />
      </div>
    </section>
  );
});

type SectionProps = {
  model: PlayerCommentsState,
  style?: object
}

const PlayerCommentsSectionHeader = observer(({
  model,
  style
}: SectionProps) => {
  const { activeComment, isActiveCommentIntoView, shouldHideSection } = model;

  const disableButton =
    !activeComment ||
    isActiveCommentIntoView;

  const commentsHeaderClasses = classNames('user-player-section-header', {
    'hidden-section': shouldHideSection
  });

  return (
    <div className={commentsHeaderClasses} //section-header 
      style={style}
      id="player-comments-header">
      <div className="section-row flex-space-between">
        <div className="section-label">
          <span className="icon"><CommentsSVGIcon /></span>
          <span className="text">Comments</span>
        </div>

        {model.comments.length > 0 &&
          <PlayerSectionHeaderButton
            model={model}
            disabled={disableButton} />}
      </div>
    </div>
  );
});

const PlayerCommentsSectionBody = observer(({
  model
}: SectionProps) => {
  const { commentsSyncStatus, commentThreads, commentInput, shouldHideSection } = model;
  const loaderClass = classNames('user-player-section-load-mask small', {
    'transparent': model.commentInput.isLoading,
    'dark': commentsSyncStatus === SyncStatus.Fetching
  });

  const commentsSectionClass = classNames('user-player-section-body', {
    'section-scroll': !shouldHideSection,
    'hidden-section': shouldHideSection
  });

  const showErrorSection = commentsSyncStatus === SyncStatus.Error;
  const isLoading = commentInput.isLoading || commentsSyncStatus === SyncStatus.Fetching;

  return (
    <div className={commentsSectionClass}
      id="player-comments-scroll"
      onScroll={model.handleScroll}
      ref={model.scrollElementProxy.ref}>

      {!showErrorSection &&
        <>
          {!shouldHideSection &&
            commentThreads.map(commentThread => {
              return (<CommentThread key={commentThread.id}
                model={commentThread}
                isActive={model.isCommentThreadActive(commentThread)}
                onCommentClick={(comment: Comment) => model.player.invoke('jumpToComment', { comment })}
              />)
            })}

          {isLoading &&
            <LoadMask
              visible={isLoading}
              className={loaderClass}
              spinner="dots3"
              spinnerClassName="medium" />}
        </>
      }

      {showErrorSection &&
        <ErrorMessageBox
          content={'Could not fetch comments because of an error.'}
        />
      }
    </div >
  );
});

const PlayerCommentsSectionFooter = observer(({
  model,
  style
}: SectionProps) => {
  const { player, shouldHideSection } = model;

  const authContext = useAuthContext();

  const { job } = model;

  const isLiveStream = job?.isLiveStreamWaiting || job?.isLiveStreaming;
  const showLoginBtn =
    !authContext?.isAuthenticated &&
    authContext?.allowActions &&
    !isLiveStream;

  const commentsFooterClasses = classNames('section-footer', {
    'hidden-section': shouldHideSection
  });

  if (job?.hasPermission('AddComment')) {
    return (
      <div className={commentsFooterClasses}
        id="player-comments-footer"
        style={style}>
        <CommentInput model={model.commentInput} />
      </div>
    );
  }

  if (showLoginBtn)
    return (
      <div className="section-footer login-button"
        id="player-comments-footer">
        <Button
          onClick={player?.handleLoginButtonClick}
          className="primary medium"
          label="Sign In to Comment"
        />
      </div>
    );

  return null;
});