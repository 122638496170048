import './errorPages.scss';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { RouteProps } from 'react-router-dom';
import { ErrorPage } from './errorPage';

type Props = RouteProps;

export const NotFoundErrorPage = observer((props: Props) => {
  return <ErrorPage
    message="The requested page does not exist." />
});

export default NotFoundErrorPage;