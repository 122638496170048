import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { BindingProps, Message, StoreNode } from '../../store';
import { JobModel, Reaction } from '../../entities';
import { InputPlayerReactionName } from './playerReactionSchema';

const TIME_THRESHOLD = 10000;
const COUNT_THRESHOLD = 10;

type Props = BindingProps<{
  jobId?: string | null,
}>

export class LiveReactionsController
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.receive(this.receiver, ['UserPlayerApiClient:addedReaction']);
  }

  readonly reactionsBufferList = observable.map<InputPlayerReactionName, ReactionBuffer>();

  // #region Props
  @computed get jobId(): string | null {
    return this.resolvedProps.jobId ?? null;
  }

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }
  // #endregion

  @computed get reactionBufferEntries(): ReactionBuffer[] {
    return [...this.reactionsBufferList.values()];
  }

  @action
  private receiver = (msg: Message) => {
    switch (msg.type) {
      case 'addedReaction':
        const { reaction } = msg.payload;
        this.registerReaction(reaction as Reaction);
        break;
    }
  }

  @action
  registerReaction(reaction: Reaction) {
    const reactionName: InputPlayerReactionName = reaction.reaction;

    if (!this.reactionsBufferList.has(reactionName)) {
      this.reactionsBufferList.set(reactionName, new ReactionBuffer(this.store, { reaction }))
    }

    const buffer = this.reactionsBufferList.get(reactionName);
    buffer?.addReaction(reaction);
  }

  @action
  reset() {
    this.reactionBufferEntries.forEach((buffer: ReactionBuffer) => buffer.reset());
  }
}

type ReactionBufferProps = {
  reaction: Reaction;
};

class ReactionBuffer
  extends StoreNode {

  constructor(store: Store, { reaction }: ReactionBufferProps) {
    super(store)

    this.reaction = reaction;
  }

  readonly nodeType = 'ReactionBuffer';

  // Last reaction registered in the buffer
  @observable reaction: Reaction;
  @observable reactionsTimeRange: number[] = [];

  @action
  addReaction(reaction: Reaction) {
    const timestamp = Date.now();
    this.reactionsTimeRange.push(timestamp);

    this.reaction = reaction;
    this.checkReactionsThreshold();
  }

  @action
  private checkReactionsThreshold() {
    const len = this.reactionsTimeRange.length;
    const firstIndex = COUNT_THRESHOLD > len ? 0 : (len - COUNT_THRESHOLD);
    const first = this.reactionsTimeRange[firstIndex];
    const last = this.reactionsTimeRange[len - 1];

    if (last - first <= TIME_THRESHOLD) {

      if (len < COUNT_THRESHOLD) {
        this.broadcast('reactionAnimation', { reaction: this.reaction });
        return;
      }

      this.reactionsTimeRange = [];

      this.broadcast('bloomAnimation', { reaction: this.reaction });
      return;
    }

    this.broadcast('reactionAnimation', { reaction: this.reaction });
  }

  @action
  reset() {
    this.reactionsTimeRange = [];
  }
}