import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { RenameJobWindowState } from './renameJobWindowState';
import { Button, TextInputControlled } from '../input';

type Props = {
  model: RenameJobWindowState;
  visible?: boolean;
};

export const RenameJobWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      model={model.window}
      visible={props.visible}
      isLoading={model.isLoading}>
      <WindowHeader model={model.window} title="Rename" />

      <hr />

      <div className="window-content">
        <TextInputControlled
          statusMessage={
            model.titleInput.status === 'error' &&
              model.titleInput.showStatusIcons
              ? model.titleInput.error?.toString()
              : 'Minimum title length 5ch.'
          }
          model={model.titleInput}
          label="Title of video" />
      </div>

      <hr />

      <footer className="window-footer">
        <div className="btn-group right">
          <Button
            className="primary btn-outline medium"
            label="Cancel"
            disabled={model.isLoading}
            onClick={() => model.close()}
          />
          <Button
            className="primary medium"
            label="Save"
            disabled={model.isLoading}
            onClick={() => model.submit()}
          />
        </div>
      </footer>
    </Window>
  );
});
