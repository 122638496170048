import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { Routes } from '../../routes';
import { InputState, input } from '../../components/input';
import { SearchMode } from '../../components/search/searchBarState';
import { isNonEmptyString } from '../../core';
import { getApiTeamError } from '../../api';
import { Error } from '../../core/error';

const defaultGptQuestion = 'What is CLIPr?';

export class ChatGptSearchResultPageState
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.model = input(this, {
      name: 'searchInput',
      onBlur: () => { setTimeout(action(() => { this.isFocused = false; }), 200) }
    });
  }

  readonly model: InputState;
  @observable teamId: string | null = null;
  @observable chatGptSearchResult: string | null = null;
  @observable chatGptSearchKey: string | null = null; 
  @observable isFocused: boolean = false;
  @observable isLoading: boolean = false;
  @observable error: Error | null = null;
  @observable chatGptError: string | null = null;

  @computed get team() {
    return this.store.teamManager.getTeam(this.teamId) ?? null;
  }
  @computed get user() {
    return this.store.user ?? null;
  }
  @computed get defaultQuestion() {
    return this.chatGptSearchKey?.toLowerCase() === defaultGptQuestion.toLowerCase();
  }

  @computed
  get isWidgetMode(): boolean {
    return this.store.widget.isWidgetMode;
  }

  @action
  search = async() => {
    if (!this.chatGptSearchKey || !isNonEmptyString(this.chatGptSearchKey)) {
      return;
    }

    this.isLoading = true;
    
    const profile = this.store.user;
    const userId = !this.teamId ? profile?.id : undefined;
    const [res, err] = await this.store.api.getGPTAnswer({
      input: {
      question: this.chatGptSearchKey!,
      teamId: this.teamId ?? undefined,
      userId: userId ?? undefined
      }
    });

    if (!res || err) {
      this.isLoading = false;
      this.chatGptError =  err?.message ?? null;
      return;
    }

    this.chatGptSearchResult = res.getGPTAnswer.result;

    this.model.clear();
    this.isLoading = false;
  }

  @action
  clear() {
    this.model.clear();
  }

  @action
  async mounted(teamId?: string | null) {
    this.store.searchBar.setSearchMode(SearchMode.Ask);
    const query = new URLSearchParams(window.location.search);
    const searchParam = query.get('searchKey');
    const teamIdParam = query.get('teamId');
    // Set the search value at init for shareable search urls
    this.model.value = searchParam || '';
    this.teamId = teamId || teamIdParam;
    this.chatGptSearchKey = searchParam;

    if (this.teamId && !this.team) {
      const [, teamErr] = await this.store.teamManager.apiFetchTeam({ id: this.teamId });

      if (teamErr) {
        const decodedError = getApiTeamError(teamErr);
        this.error = decodedError;
        return;
      }
    }
    
    this.search();
  }

  @action
  unmounted() {
    this.store.searchBar.reset();
    this.model.clear();
    
    this.chatGptSearchKey = null;
    this.teamId = null;
  }

  @action
  submit = (replaceRoute = false, teamId: string | null, value?: string | null) => {
    if (value)
      this.model.loadValue(value);
    
    if (!this.model.value || !isNonEmptyString(this.model.value)) {
      return;
    }

    this.teamId = teamId;
    this.chatGptSearchKey = this.model.value;
    this.chatGptSearchResult = null;
    this.isFocused = false;

    const { pathname } = window.location;
    if (this.isWidgetMode && pathname === Routes.librarySearchChatGptWidget()) {
      this.search();
      return;
    }

    const { history } = this.store.routingService;
    if (replaceRoute)
      history.replace(this.searchUrl);
    else
      history.push(this.searchUrl);
  }

  @computed
  get searchUrl(): string {
    const path = this.isWidgetMode ? Routes.librarySearchChatGptWidget() : Routes.searchChatGpt;
    
    let params = new URLSearchParams();
    if (this.model.value) {
      params.append('searchKey', this.model.value);
    }
    if (this.teamId) {
      params.append('teamId', this.teamId);
    }

    return `${path}${params ? `?${params}` : ''}`;
  }
}