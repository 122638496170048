import { makeObservable, observable, computed } from 'mobx';
import { BindingProps, StoreNode } from '../../../store';
import { Store } from '../../../store/store';
import { XYSeriesChartState } from '../xySeriesChart/xySeriesChartState';
import { PerformanceChartInternalParams, PerformanceChartParams, PerformanceSeriesData } from './performanceChartSchema';
import { PerformanceDataSource } from './performanceDataSource';

type Props = BindingProps<{
  params?: PerformanceChartParams
}>

export class PerformanceChartState extends StoreNode {
  readonly nodeType = 'PerformanceChartState';

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);
  }

  // #region Props
  @computed get params(): PerformanceChartParams | null {
    return this.getResolvedProp('params') ?? null;
  }
  set params(val: PerformanceChartParams | null) {
    this.setProp('params', val);
  }
  // #endregion

  readonly internalParams: PerformanceChartInternalParams = observable({})

  @computed get outputParams(): PerformanceChartParams {
    const outParams = {
      ...this.internalParams,
      ...this.params
    };

    Object.freeze(outParams);

    return outParams;
  }

  @computed get outputData(): PerformanceSeriesData[] {
    const outputData = [...this.dataSource.data];

    return outputData;
  }

  readonly dataSource = new PerformanceDataSource(this.store, {
    params: () => this.outputParams
  });

  readonly xySeriesChartState = new XYSeriesChartState(this.store);

  async fetch() {
    return this.dataSource.fetch();
  }
  abort() {
    this.dataSource.cancel();
  }
}
