export function onWindowClosed(target: Window, callback: (evt: CustomEvent) => any, abortSignal?: AbortSignal) {
  if (target.closed)
    return;

  const intervalId = setInterval(() => {
    if (target.closed) {
      const evt = new CustomEvent('closed');
      callback(evt);
      clearInterval(intervalId);
    }
  }, 500);

  abortSignal?.addEventListener('abort', () => {
    clearInterval(intervalId);
  });
}