
import './teams.scss';

import React, { ReactEventHandler } from 'react';
import { observer } from 'mobx-react-lite';
import { TeamDictionaryItemState } from './teamDictionaryWindowState';
import { SquareMinusSVGIcon, SquarePlusSVGIcon } from '../svg';
import { TextInputControlled } from '../input';
import { Vocabulary } from '@clipr/lib';

type TeamDictionaryItemProps = {
  isLast: boolean;
  defaultVocabulary?: Vocabulary[] | null;
  model: TeamDictionaryItemState;
  onClickRemove: ReactEventHandler;
  onClickAdd: ReactEventHandler;
};

export const TeamDictionaryItem = observer((props: TeamDictionaryItemProps) => {

  const { model, defaultVocabulary, onClickAdd, onClickRemove } = props;

  const { correctWordInput, misspelledWordsInput, dictionaryInputGroup } = model;

  let suffixElement;
  if (props.isLast) {
    suffixElement = (
      <button
        className="line-suffix list-remove-item-btn btn-core"
        type={'button'}
        disabled={dictionaryInputGroup.disabled}
        onClick={onClickAdd}>
        <SquarePlusSVGIcon />
      </button>
    );
  } else
    suffixElement = (
      <button
        className="line-suffix list-remove-item-btn btn-core"
        type={'button'}
        disabled={dictionaryInputGroup.disabled}
        onClick={onClickRemove}>
        <SquareMinusSVGIcon />
      </button>
    );

  return (
    <>
      <li className="team-dictionary-item">
        <TextInputControlled
          label="Common misspellings"
          model={misspelledWordsInput}
          infoModalTitle={'Misspellings configuration'}
          infoModalContent={<TeamDictionaryItemInfo defaultVocabulary={defaultVocabulary} />}
          className="form-input misspelled-word-input"
        />
        <TextInputControlled
          label="Correct name or word"
          model={correctWordInput}
          infoModalTitle={'Correct word configuration'}
          infoModalContent={'Please leave this field empty if you wish to replace a misspelled word with a space.'}
          className="form-input correct-words-input"
        />
        <div className="line-actions">{suffixElement}</div>
      </li>
    </>
  );
});

type TeamDictionaryInfoProps = {
  defaultVocabulary?: Vocabulary[] | null;
}

export const TeamDictionaryItemInfo = observer((props: TeamDictionaryInfoProps) => {
  const groupedVocabulary = props.defaultVocabulary?.reduce((acc: Vocabulary[], obj) => {
    const found: Vocabulary | null = acc.find((item: Vocabulary) => item.displayAs === obj.displayAs) ?? null;
    if (found) {
      (found as Vocabulary).phrase += `, ${obj.phrase}`;
    } else {
      acc.push({ phrase: obj.phrase, displayAs: obj.displayAs });
    }
    return acc;
  }, []);


  return <div className="team-dictionary-item-info">
    <p>If your entry contains multiple words, separate each word with a hyphen (-). For example, <strong>Andorra-la-Vella</strong> or <strong>Los-Angeles</strong>. Spaces are not allowed.</p>
    <br/>
    <p>For acronyms, any pronounced letters must be separated by a period. The trailing period also needs to be pronounced. If your acronym is plural, you must use a hyphen between the acronym and the 's'. For example, 'CLI' is <strong>C.L.I.</strong> (not <strong>C.L.I</strong>) and 'ABCs' is <strong>A.B.C.-s </strong> (not <strong>A.B.C-s</strong>).</p>
    <br/>
    <p>Do not include digits in this column; numbers must be spelled out. For example, 'VX02Q' is V.X.-zero-two-Q.</p>
    <br/>
    {groupedVocabulary && groupedVocabulary.map((defaultVocabularyPair) => (
      <p><strong>{defaultVocabularyPair.phrase}</strong> is already automatically replaced with {defaultVocabularyPair.displayAs === " " ? 'space' : defaultVocabularyPair.displayAs}.</p>
    ))}
  </div>
});