import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { TrainerTimelineState } from '../trainerTimelineState';
import { TimelineMoment } from './timelineMoment';
import { TrackLane } from '../../../entities';
import { Maybe, ObjectLiteral } from '../../../core';

type LaneProps = {
  lane: TrackLane,
  model: TrainerTimelineState,
  className?: Maybe<string | ObjectLiteral>
}

export const TimelineTrackLane = observer(({ 
  lane, 
  model,
  className
}: LaneProps) => {

  const { moments } = lane;
  className = classNames('track-lane', className, {
    'empty': moments.length === 0,
  });

  return (
    <div className={className}>
      {lane.moments.map(moment =>
        <TimelineMoment
          key={moment.id}
          model={model}
          moment={moment} />
      )}
    </div>
  );
});