import { action, makeObservable } from 'mobx';
import { Store } from '../../../store/store';
import { StoreNode } from '../../../store';
import { ForgotPasswordFormState } from './forgotPasswordFormState';

export class ForgotPasswordPageState
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  readonly form = new ForgotPasswordFormState(this.store);

  @action
  attached() { }

  @action
  detached() {
    this.form.reset();
  }
}