/**
 * Simple utility for wrapping a call in a try..catch block and reporting 
 * the error in the console if any error occurs.
 * Useful for minimal and compact wrapping of 3rd party code which might crash unexpectedly (like Google IMA SDK).
 * TODO: Rename this to 'checked' after you rename the 'checked' in the async module to 'checkedAsync'.
 */
export function safe<TReturn = any>(fn: () => TReturn): TReturn | null {
  try {
    return fn();
  } catch (err) {
    console.error(`An error occured within a safe wrapper:`, err);
    return null;
  }
}