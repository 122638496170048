import { InputGroupState } from './inputGroupState';
import { InputState } from './inputState';

/**
 * Returns a 'disabled' binding prop callback for a button state
 * which will return true when the form is submitting or has touched error inputs.
 */
export function submitButtonDisabled(form: InputGroupState) {

  return (input: InputState) =>
      (!!input.loading ||
      input.isSubmitting ||
      form.hasVisibleErrorInputs ||
      !form.isChanged) && 
      (form.isDirty || form.isEmpty); // if form is in initial state and is not empty the submit should be active
}