import { changeCase } from '../../core';
import { StringCase } from '../../core/stringSchema';
import { URLQueryParamsSource } from '../../core/urlUtils';
import { ParamsReader } from './paramsReader';
import { ParamsData, ParamsSchema } from './paramsSchema';
import { ParamsWriter } from './paramsWriter';

export function readParamsFromQueryString<TParams extends ParamsData = ParamsData>(schema: ParamsSchema<TParams>, queryString: string, useDefaults = true): TParams {

  const paramsReader = new ParamsReader({ schema });
  const params = paramsReader.readFromQueryString(queryString, useDefaults);

  return params;
}

export function writeParamsToQueryString<TParams extends ParamsData = ParamsData>(schema: ParamsSchema<TParams>, data: TParams): string {

  const paramsWriter = new ParamsWriter({ schema });
  const queryString = paramsWriter.writeToQueryString(data);

  return queryString;
}

export type GetQueryParamsLookupOptions = Partial<{
  keyCase: StringCase
}>

export const GetQueryParamsLookupDefaultOptions: GetQueryParamsLookupOptions = {
  keyCase: StringCase.Unchanged
}

/** 
 * Converts the data in a queryString or URLSearchParams object to a Map.
 * Optionally you can change the string case of each key (to help with case insensitive lookup). 
 */
export function getQueryParamsLookup(query: URLQueryParamsSource, opts: GetQueryParamsLookupOptions = GetQueryParamsLookupDefaultOptions): Map<string, string> {
  opts = {
    ...GetQueryParamsLookupDefaultOptions,
    ...opts
  };

  const queryParams = new URLSearchParams(query);
  const queryKeys = [...queryParams.keys()];
  const lookup = new Map<string, string>();

  for (const key of queryKeys) {
    const casedKey = changeCase(key, opts.keyCase);
    const value = queryParams.get(key);
    if (!value)
      continue;

    lookup.set(casedKey, value);
  }

  return lookup;
}