import { JobSourceType, JobSpeciality, JobType, MutationUpdateJobSourceArgs, StartJobInput } from '@clipr/lib';
import { action, computed, makeObservable, observable } from 'mobx';
import isURL from 'validator/lib/isURL';

import { input, inputGroup, InputGroupState, InputState } from '../../components/input';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { AsyncResult } from '../../core';
import isEmpty from 'lodash/isEmpty';
import { EnrichmentItem, generateJobLevelOutput, getDefaultEnrichmentLevel, getDefaultJobLevelInput, getEnrichmentLevelItemTooltip, getEnrichmentLevelLabel, JobLevel } from '../../entities/job/jobFeatures';
import { JobModel } from '../../entities/job';
import { DefaultSpecialityItem, FilteredSpecialityItems, getJobSpecialityInput, getJobSpecialityOutput, SpecialityItems } from '../../entities/job/jobSpeciality';
import { DefaultLanguageItem, getDefaultLanguageValue, getLanguageInputItem, LanguageItems } from '../../entities/language';
import { Team, JobVideoTypeList } from '../../entities';

type Props = BindingProps<{
  teamId?: string,
  jobId?: string,
}>;

export type LinkStatus = 'pending' | 'staged' | 'submitted' | 'syncing' | 'error';

export class UploadLinkItemState
  extends StoreNode {

  readonly nodeType = 'UploadLinkItemState';

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);
    // submit form

    this.levelInput = input(this, {
      name: 'level',
      placeholder: 'Choose an option',
      selectorItems: () => JobLevel.map(item => ({
        value: item,
        label: getEnrichmentLevelLabel(item),
        tooltip: getEnrichmentLevelItemTooltip(item)
      })),
      showStatusMessage: true,
      isRequired: false,
      statusMessage: (input: InputState) =>
        input.status === 'error' && input.showStatusIcons ? input.error?.toString() : '',
      export: (self: InputState) => self.normValue,
      disabled: () => !!this.jobId
    });

    this.specialityInput = input(this, {
      name: 'speciality',
      placeholder: 'Choose an option',
      selectorItems: () => this.team?.publicSafety ? 
        SpecialityItems : 
        FilteredSpecialityItems,
      showStatusMessage: true,
      isRequired: () => !this.isSpecialityInputDisabled,
      statusMessage: (input: InputState) =>
        input.status === 'error' && input.showStatusIcons ? input.error?.toString() : '',
      error: (input: InputState) => {
        if (isEmpty(input.value) && !this.isSpecialityInputDisabled) 
          return 'Required field'; },
      export: (self: InputState) => self.normValue,
      disabled: () => !!this.jobId,
      onChange: (input: InputState) => {
        if (input.normValue === 'Medical') {
          const lang = getLanguageInputItem(getDefaultLanguageValue());
          this.languageInput.loadValue(lang);
        }
      }
    });

    this.languageInput = input(this, {
      name: 'language',
      placeholder: 'Choose an option',
      selectorItems: LanguageItems,
      showStatusMessage: true,
      isRequired: true,
      statusMessage: (input: InputState) =>
        input.status === 'error' && input.showStatusIcons ? input.error?.toString() : '',
      error: (input: InputState) => {
        if (isEmpty(input.value)) return 'Required field';
        if (this.specialityInput.normValue === 'Medical' && input.normValue !== 'en-US')
          return 'Only US English supported';
      },
      export: (self: InputState) => self.normValue,
      disabled: () => !!this.jobId || this.specialityInput.normValue === 'Medical'
    });

    this.videoTypeInput = input(this, {
      name: 'videoType',
      placeholder: 'Choose an option',
      selectorItems: JobVideoTypeList,
      showStatusMessage: true,
      export: (self: InputState) => self.normValue,
      disabled: () => !!this.jobId
    });

    this.linkInput = input(this, {
      name: 'link',
      placeholder: 'Paste your Video URL here',
      // label: 'Video URL',
      isRequired: true,
      error: (input: InputState) => {
        if (this.isStaged)
          return null;
        if (input.value)
          return this.checkLinkValidity(input.value);

        return null;
      }
    });

    this.hasPasswordInput = input(this, {
      name: 'hasPassword',
      label: "My video is password protected"
    });

    this.passwordInput = input(this, {
      name: 'password',
      placeholder: 'Type in video pwd',
      label: "URL Password",
      error: (input: InputState) => {
        if (this.hasPasswordInput.value && isEmpty(input.value))
          return 'Password required';

        return null;
      }
    });

    this.form = inputGroup(this, {
      name: 'languages',
      inputs: [
        this.specialityInput,
        this.linkInput,
        this.languageInput,
        this.levelInput,
        this.videoTypeInput,
        this.passwordInput
      ],
      isSubmitDisabled: () => {
        return (
          this.form.isSubmitting ||
          this.form.hasErrorInputs ||
          this.linkInput.isEmpty ||
          !!(this.hasPasswordInput.value && this.passwordInput.isEmpty)
        );
      }
    });
  }

  @observable status: LinkStatus = 'pending';

  @observable error: any = null;

  // submit list
  readonly form: InputGroupState;

  readonly hasPasswordInput: InputState;
  readonly passwordInput: InputState;

  readonly linkInput: InputState;
  readonly languageInput: InputState;
  readonly levelInput: InputState;
  readonly specialityInput: InputState;
  readonly videoTypeInput: InputState;

  @computed get isPending(): boolean {
    return this.status === 'pending';
  }

  @computed get isStaged(): boolean {
    return this.status === 'staged';
  }

  @computed get isSyncing(): boolean {
    return this.status === 'syncing';
  }

  @computed get isCompleted() {
    return this.status === 'submitted';
  }

  @computed get isFailed() {
    return this.status === 'error';
  }

  @computed get showInStaging(): boolean {
    return this.isSyncing || this.isStaged;
  }

  // #region Resolved props
  @computed get teamId(): string | null {
    return this.resolvedProps.teamId;
  }

  @computed get team(): Team | null {
    return this.store.teamManager.getTeam(this.teamId);
  }

  @computed get jobId(): string | null {
    return this.resolvedProps.jobId;
  }
  // #endregion

  @computed get job() {
    return this.store.jobManager.maybeGetJob(this.jobId);
  }

  @computed get hasDuplicateServerError() {
    return this.isFailed && this.error?.message === 'Job with Source URL already exists';
  }

  @computed
  get isSpecialityInputDisabled() {
    return this.levelInput?.value === 'media';
  }

  @action
  setStaged() {
    this.status = 'staged';
  }

  @action
  setSubmitted() {
    this.status = 'submitted';
  }

  @action
  setSyncing() {
    this.status = 'syncing';
    this.error = null;
  }

  @action
  setError(err?: any) {
    this.status = 'error';
    this.error = err;
  }

  @action
  async submit(submitAsDuplicate: boolean = false): AsyncResult<JobModel> {

    const formData = this.form.export();

    if (this.jobId) {
      const updateJobSourceParams: MutationUpdateJobSourceArgs = {
        jobId: this.jobId,
        source: {
          type: JobSourceType.Url,
          url: this.linkInput.value!,
          password: this.passwordInput.value || undefined
        }
      }

      this.setSyncing();
      const [job, jobErr] = await this.store.apiUpdateJobSource(updateJobSourceParams);

      if (jobErr) {
        this.setError(jobErr);
        return [null, jobErr];
      }

      this.broadcast('linkSubmitted', { job, teamId: this.teamId, jobId: this.jobId });
      this.setSubmitted();
      return [job!];
    } else {
      const startJobParams: StartJobInput = {
        source: {
          type: JobSourceType.Url,
          url: formData.link,
          password: this.passwordInput.value || undefined
        },
        teamId: this.teamId || undefined,
        languageCode: formData.language || undefined,
        forceDuplicate: submitAsDuplicate || undefined,
        features: (formData.level &&
          generateJobLevelOutput(formData.level as EnrichmentItem)) || getDefaultJobLevelInput(),
        medicalSpecialty: getJobSpecialityOutput(formData.speciality) || undefined,
        speciality: this.specialityInput.value as JobSpeciality || undefined,
        videoType: this.videoTypeInput.value as JobType
      }

      this.setSyncing();
      const [job, jobErr] = await this.store.apiStartJob(startJobParams);

      if (jobErr) {
        this.setError(jobErr);
        return [null, jobErr];
      }

      this.broadcast('linkSubmitted', { job, teamId: this.teamId, jobId: this.jobId });
      this.setSubmitted();
      return [job!];
    }

  }

  checkIsDuplicateLink = (link: string) => {
    const { uploadPage } = this.store;
    const { linkComponent: state } = uploadPage;
    return state.linkInputList.filter(i => i.linkInput.value === link).length > 1;
  }

  checkLinkValidity = (link: string) => {
    const isValidUrl = isURL(link || '', { protocols: ['http', 'https', 'ftp', 's3'] });
    if (link && !isValidUrl)
      return 'Link error. Provide a direct video URL.';
    if (this.checkIsDuplicateLink(link))
      return 'Duplicate video link';
    // const isYouTubeUrl = !!input.value!.match(/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))/g);
    // if (isYouTubeUrl) return 'YouTube does not allow direct upload from links. Please email YouTube video links to recap@clipr.ai and we will manually upload your video.';
    return null;
  }

  stage = () => {
    const { job, team } = this;

    //init specialityInput
    if (job && job.medicalSpecialty !== undefined) {
      this.specialityInput.loadValue(SpecialityItems.find(item =>
        item.value === getJobSpecialityInput(this.job || null)
      ));
    } else {
      this.specialityInput.loadValue(this.team?.publicSafety ? 
        'PublicSafety' : 
        DefaultSpecialityItem?.value);
    }

    //init languageInput
    if (job?.languageCode) {
      this.languageInput.loadValue(getLanguageInputItem(job.languageCode));
    } else {
      this.languageInput.loadValue(DefaultLanguageItem);
    }

    //init levelInput
    if (job?.enrichmentLevel) {
      this.levelInput.loadValue(JobLevel.find((value) => value === job.enrichmentLevel));
    } else if (team?.enrichmentLevel) {
      this.levelInput.loadValue(JobLevel.find((value) => value === team.enrichmentLevel));
    } else {
      this.levelInput.loadValue(getDefaultEnrichmentLevel());
    }

    //init videoTypeInput
    if (job?.videoType) {
      this.videoTypeInput.loadValue(JobVideoTypeList.find(item => item.value === job.videoType) ?? JobType.Presentation);
    } else {
      this.videoTypeInput.loadValue(JobType.Presentation);
    }

    this.setStaged();
  }
}