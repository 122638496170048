
export enum Theme {
  Dark = 'Dark',
  Light = 'Light',
  Default = 'Default'
}

export enum View {
  List = 'List',
  Grid = 'Grid',
  Carousel = 'Carousel'
}

export type ThemeParam = 'light' | 'dark' | 'default';
export type ViewParam = 'grid' | 'list' | 'carousel';

export const toThemeParam = (theme: Theme): ThemeParam => {
  switch (theme) {
    case Theme.Light:
      return 'light';
    case Theme.Dark:
      return 'dark';
    default:
    case Theme.Default:
      return 'default';
  }
}

export const fromThemeParam = (themeParam: string | null): Theme => {
  const theme = themeParam?.toLocaleLowerCase();

  switch (theme) {
    case 'light':
      return Theme.Light;
    case 'dark':
      return Theme.Dark;
    default:
    case 'default':
      return Theme.Default;
  }
}

export const toViewParam = (view: View): ViewParam => {
  switch (view) {
    default:
    case View.Grid:
      return 'grid';
    case View.List:
      return 'list';
    case View.Carousel:
      return 'carousel';
  }
}

export const fromViewParam = (viewParam: string | null): View => {
  const view = viewParam?.toLocaleLowerCase();

  switch (view) {
    default:
    case 'grid':
      return View.Grid;
    case 'list':
      return View.List;
    case 'carousel':
      return View.Carousel;
  }
}

