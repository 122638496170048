import { DeleteNotificationsSubscriptionSubscribedEventInput, NotificationSubscriptionSubscribedEvent } from '@clipr/lib';
import { action, makeObservable, observable } from 'mobx';
import { ApiRequestMode } from '../../api';
import { notifyError } from '../../services/notifications';
import { StoreNode } from '../../store';
import { Store } from '../../store/store';

export class UnsubscribePageState extends StoreNode {
  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  @observable notificationLabel: string | null = null;
  @observable unsubscribedSuccessfully: boolean = false;
  @observable isLoading: boolean = false;
  @observable token: string | null = null;
  @observable email: string | null = null;
  @observable subscribedEvent: NotificationSubscriptionSubscribedEvent | null = null;

  @action
  mounted(search: string) {
    const query = new URLSearchParams(search);

    const eventType = query.get('type');
    const destination = query.get('destination');
    const token = query.get('token');

    if (destination) this.email = atob(destination);
    if (token) this.token = token;
    if (eventType) this.subscribedEvent = eventType as NotificationSubscriptionSubscribedEvent;

    switch (eventType) {
      case 'JobDone':
        this.notificationLabel = 'Video Processed';
        break;

      case 'JobWaiting':
        this.notificationLabel = 'Video Processing';
        break;

      case 'JobDownloadFailed':
        this.notificationLabel = 'Video Processing Failed';
        break;

      case 'TeamInvitation':
        this.notificationLabel = 'Team Invite';
        break;

      case 'TeamIsPublic':
        this.notificationLabel = 'Team goes shareable';
        break;
    }
  }

  @action
  async unsubscribe() {
    this.isLoading = true;

    let args: DeleteNotificationsSubscriptionSubscribedEventInput;

    if (this.subscribedEvent) {
      args = { token: this.token ?? '', subscribedEvent: this.subscribedEvent }
      const [, err] = await this.store.api.deleteNotificationsSubscriptionSubscribedEvent({ args }, { 
        mode: ApiRequestMode.Direct
      });

      this.isLoading = false;

      if (err) {
        notifyError(this, `Something went wrong.`);
        return;
      }

      this.unsubscribedSuccessfully = true;
    }
  }

  @action
  reset() {
    this.token = null;
    this.email = null;
    this.isLoading = false;
    this.subscribedEvent = null;
    this.notificationLabel = null;
    this.unsubscribedSuccessfully = false;
  }
}
