import { INIT_DEBUGGER, TRACE } from '../../core/debug/debugMacros';

import { action, computed, makeObservable, observable, observe, reaction } from 'mobx';
import throttle from 'lodash/throttle';
import clamp from 'lodash/clamp';
import debounce from 'lodash/debounce';

import { AddReactionInput, LiveStreamActivePlaylist, MomentSource, UpdateJobInput, WatchMode } from '@clipr/lib';
import { JobModel, Moment, MomentModel, MomentStub, Reaction } from '../../entities';
import { Store } from '../../store/store';
import { BindingProps, Message, RefProxy, refProxy, StoreNode } from '../../store';
import { MomentSelector } from '../../entities/moments/momentSelector';
import { IPlayer, IPlayerAdapter, PlayParams, PlayerPrincipalMomentStatus } from './playerSchema';
import { PlayerDirector } from './playerDirector';
import { PlayerMomentView } from './playerMomentView';
import { PlayerReporter } from './playerReporter';
import { MediaInfo } from '../../entities/media';
import { MomentEditorState } from '../momentEditor';
import { PlayerItemSource } from '../../entities/player/playerItemSource';
import { getPlayerWidgetIFrameCode } from '../../widgets/playerWidget/playerWidgetUtils';
import { notifyError, notifySuccess } from '../../services/notifications';
import { PlayerFramesetState } from '../playerFrameset/playerFramesetState';
import { BookmarkWindowState } from '../bookmarks/bookmarkWindowState';
import { durationToString } from '../../core/time';
import { MarkerBarState } from './components/markerBarState';
import { AsyncResult, getEventOffset, isFiniteNumber, isNonEmptyString, Maybe, PointerEventLike } from '../../core';
import { PlayerComponentName } from './playerSchema';
import { ApiRequestOptions } from '../../api/apiSchema';
import { InputPlayerReactionName } from '../playerReactions/playerReactionSchema';
import { PlayerReactionAnimationsState } from '../playerReactionAnimations/playerReactionAnimationsState';
import { ShareVideoWindowState, ShareWindowMode, ShareWindowProps } from '../jobs';
import { SharedFrom } from '../../services/analytics/stream';
import { MediaStreamMonitor } from '../../entities/media/mediaStreamMonitor';
import { PlayerTutorialWindowState } from '../playerTutorial/playerTutorialWindowState';
import { ComponentVisibility, IComponentPolicy } from '../componentSchema';
import { AdsBarState } from './components/adsBarState';
import { randomArrayElement } from '../../core/random';
import { PlayerAdapterState } from '../playerAdapter';
import { PlayerAdsAdapterState } from '../playerAdsAdapter';
import { PlayerLiveAdapter } from './playerLiveAdapter';
import { PlayheadState } from './playheadState';
import { PlayerChromeState } from './playerChromeState';
import { PlayerCaptionState } from './playerCaptionsState';
import { PlayerProgressBarState } from './playerProgressBarState';
import { PlayerVolumeButtonState } from './playerVolumeButtonState';
import { PlayerReactionButtonState } from './playerReactionButtonState';
import { PlayerAdsAdapterMessageType } from '../playerAdsAdapter/playerAdsAdapterSchema';
import { Config } from '../../config';
import { ConfigAutoplayPolicy } from '../../config/configSchema';
import { OpenClipWindowOptions } from '../momentWindow/clipWindowState';
import { EditorWindowState } from '../editorWindow/editorWindowState';

type Props = {
  adapter?: PlayerAdapterState | null;
} & BindingProps<{
  jobId: string;
  momentSelector: MomentSelector;
  frameset?: PlayerFramesetState | null;
  widgetMode?: boolean | null;
  speakerIdMode?: boolean | null;
  showCommentsBar?: boolean | null;
  suppressLiveReactions?: boolean | null;
  time?: number;
  teamId?: string | null;
  showIndex?: boolean,
  showComments?: boolean,
  showTranscript?: boolean,
  showProfile?: boolean,
  showTopicTags?: boolean,
  showEmbedIcon?: boolean,
  media?: MediaInfo,
  showReactionButton?: boolean;
  showAddMomentButton?: boolean;
  allowFullscreen?: boolean | null;
  playlistType?: LiveStreamActivePlaylist;
  allowShare?: boolean | null;
  allowDownload?: boolean | null;
  showHelp?: boolean | null;
  disableReactions?: boolean | null;
  disableComments?: boolean | null;
  disableTranscript?: boolean | null;
  disableIndex?: boolean | null;
  customRedirectUrl?: string | null;
  autoplay?: boolean | null;
  playerSource?: string | null;
  playerTutorialHighlightedComponents?: string[];
  openMomentWindowOptions?: Partial<OpenClipWindowOptions>;
  momentSource?: MomentSource;
}>


type PlaybackRateOption = {
  label: string,
  rate: number,
  iconLabel: string
}

type PlayerStatus =
  'Idle' |
  'Playing' |
  'Waiting' |
  'Seeking' |
  'Paused' |
  'Ended' |
  'Stopped';

const SEEK_DEBOUNCE_WAIT = 200;
const ADD_REACTION_THROTTLE_TIME = 300;

export class PlayerState
  extends StoreNode
  implements IPlayer {

  readonly nodeType = 'PlayerState';

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    INIT_DEBUGGER(this, {
      color: 'maroon',
      enableTrace: false
    });

    this.adapter = props?.adapter ?? new PlayerAdapterState(store, {
      media: () => this.media,
      jobId: () => this.jobId
    });
    this.adapter.listen(
      this.adapterListener);

    this.adsAdapter = new PlayerAdsAdapterState(this.store, {
      contentVideoElement: () => this.adapter.videoElement
    });
    this.adsAdapter.listen(
      this.adsAdapterListener);

    this.playhead = new PlayheadState(store, {
      player: () => this,
      containerRef: () => this.progressBarRef
    });
    this.playhead.listen(
      this.playheadListener);

    this.progressBar = new PlayerProgressBarState(store, {
      player: () => this,
      containerRef: () => this.progressBarRef
    });
    this.progressBar.listen(
      this.progressBarListener);

    this.playerLiveAdapter = new PlayerLiveAdapter(store, {
      jobId: () => this.jobId,
      isActive: () => this.isLiveStream,
      adapter: () => this.adapter
    });

    this.playerLiveAdapter.listen(
      this.playerLiveAdapterListener);

    this.itemSource = new PlayerItemSource(store, {
      jobId: () => this.jobId,
      selector: () => this.selector
    });

    this.momentView = new PlayerMomentView(store, {
      player: () => this,
      source: () => this.itemSource
    });

    this.momentDirector = new PlayerDirector(store, {
      player: () => this,
      moments: () => this.momentDirectorMoments,
      enabled: () => this.momentDirectorEnabled,
      passageLength: 0.0001
    });

    this.momentReporter = new PlayerReporter(store, {
      player: () => this,
      enabled: () => this.momentReporterEnabled,
      moments: () => []
    });

    this.momentEditor = new MomentEditorState(this.store, {
      player: () => this,
      jobId: () => this.jobId,
      source: () => this.momentSource
    });

    this.commentsBar = new MarkerBarState(this.store, {
      jobId: () => this.jobId,
      player: () => this,
    });

    this.adsBar = new AdsBarState(this.store, {
      player: () => this
    });

    this.mediaStreamMonitor = new MediaStreamMonitor(this.store, {
      streamURL: () => this.job?.media.liveStreamUrl,
      enabled: () => this.isMonitorEnabled
    });

    //TODO: should refactor these obeserves to reactions
    observe(this.momentView, 'activeTopic', () => {
      this.emit('activeTopicChange', { moment: this.momentView.activeTopic });
    });

    observe(this.momentView, 'lastActiveSubtopic', () => {
      this.emit('lastActiveSubtopicChange', { moment: this.momentView.lastActiveSubtopic });
    });

    observe(this.momentView, 'activeTranscript', () => {
      this.emit('activeTranscriptChange', { moment: this.momentView.activeTranscript });
    });

    observe(this.momentView, 'activeComment', () => {
      this.emit('activeCommentChange', { comment: this.momentView.activeComment });
    });

    this.mediaStreamMonitor.listen(
      this.mediaStreamMonitorListener);

    reaction(() => this.isFullscreen, () =>
      this.handleFullscreenChange());

    reaction(() => this.momentView.activeTranscriptSpeakerId, () => {
      this.emit('activeSpeakerChange', { speaker: this.momentView.activeTranscriptSpeaker })
    });

    reaction(() => this.principalMomentPlaybackStatus, (curr, prev) =>
      this.handlePrincipalMomentStatusReaction(curr, prev));
  }

  private mediaStreamMonitorListener = async (msg: Message<MediaStreamMonitor>) => {
    const { type } = msg;

    switch (type) {
      case 'active':
        // if (!this.adapter.isMetadataLoaded)
        //   this.adapter.reattachDOMVideo();
        break;
      case 'inactive':
        this.playerLiveAdapter.setStreamEnded();
        this.mediaStreamMonitor.reset(); // if the stream is ended, the monitor should stop
        break;
    }
  }

  @observable tutorialMode: boolean = false;

  readonly itemSource: PlayerItemSource;
  readonly momentView: PlayerMomentView;
  readonly momentDirector: PlayerDirector;
  readonly momentReporter: PlayerReporter;
  readonly momentEditor: MomentEditorState;
  readonly commentsBar: MarkerBarState;
  readonly adsBar: AdsBarState;

  readonly componentRef = refProxy<HTMLDivElement>(this);
  readonly progressBarRef = refProxy<HTMLDivElement>(this);

  readonly reactionAnimations = new PlayerReactionAnimationsState(this.store);

  readonly mediaStreamMonitor: MediaStreamMonitor;

  readonly adapter: PlayerAdapterState;
  readonly adsAdapter: PlayerAdsAdapterState;
  readonly playerLiveAdapter: PlayerLiveAdapter;
  readonly playhead: PlayheadState;

  // #region Props
  // -------
  @computed get selector(): MomentSelector | null {
    return this.resolvedProps.momentSelector ?? null;
  }

  @computed get playlistType(): LiveStreamActivePlaylist | null {
    return this.resolvedProps.playlistType ?? null;
  }

  @computed get playerSource(): string | null {
    return this.resolvedProps.playerSource ?? null;
  }

  @computed get jobId(): string | null {
    return this.resolvedProps.jobId ?? null;
  }

  @computed get time(): number {
    return this.resolvedProps.time ?? 0;
  }

  @computed get frameset(): PlayerFramesetState | null {
    return this.resolvedProps.frameset ?? null;
  }

  @computed get widgetMode(): boolean | null {
    return this.resolvedProps.widgetMode ?? null;
  }
  @computed get speakerIdMode(): boolean | null {
    return this.resolvedProps.speakerIdMode ?? null;
  }

  @computed get showCommentsBar(): boolean {
    return this.resolvedProps.showCommentsBar ?? false;
  }

  @computed get showIndex(): boolean {
    return this.resolvedProps.showIndex ?? true;
  }

  @computed get showComments(): boolean {
    return this.resolvedProps.showComments ?? true;
  }

  @computed get showTranscript(): boolean {
    return this.resolvedProps.showTranscript ?? true;
  }

  @computed get disableComments(): boolean {
    return this.resolvedProps.disableComments ?? false;
  }

  @computed get disableTranscript(): boolean {
    return this.resolvedProps.disableTranscript ?? false;
  }

  @computed get disableIndex(): boolean {
    return this.resolvedProps.disableIndex ?? false;
  }

  @computed get showProfile(): boolean {
    return this.resolvedProps.showProfile ?? true;
  }

  @computed get showTopicTags(): boolean {
    return this.resolvedProps.showTopicTags ?? true;
  }
  @computed get showEmbedIcon(): boolean {
    return this.resolvedProps.showEmbedIcon ?? true;
  }

  @computed get showReactionButton(): boolean {
    return this.resolvedProps.showReactionButton ?? true;
  }

  @computed get showAddMomentButton(): boolean | null {
    return this.resolvedProps.showAddMomentButton ?? null;
  }

  @computed get showShareButton(): boolean {
    return this.resolvedProps.showShareButton ?? false;
  }

  @computed get allowFullscreen(): boolean | null {
    return (this.resolvedProps.allowFullscreen && !this.store.ui.isIOS) ?? null;
  }

  @computed get allowShare(): boolean | null {
    return this.resolvedProps.allowShare ?? null;
  }

  @computed get allowDownload(): boolean | null {
    return this.resolvedProps.allowDownload ?? null;
  }

  @computed get showHelp(): boolean | null {
    return this.resolvedProps.showHelp ?? null;
  }

  @computed get disableReactions(): boolean | null {
    return this.resolvedProps.disableReactions ?? null;
  }

  @computed get autoplay(): boolean | null {
    return this.resolvedProps.autoplay ?? null;
  }

  @computed get customRedirectUrl(): string | null {
    return this.resolvedProps.customRedirectUrl ?? null;
  }

  @computed get suppressLiveReactions(): boolean {
    return this.resolvedProps.suppressLiveReactions ?? false;
  }

  @computed get openMomentWindowOptions(): Partial<OpenClipWindowOptions> | null {
    return this.resolvedProps.openMomentWindowOptions ?? null;
  }

  @computed get momentSource(): Partial<MomentSource> | null {
    return this.resolvedProps.momentSource ?? null;
  }
  // #endregion

  @observable isMounted: boolean = false;
  @observable lockOrientation: boolean = false;
  @observable isUiPinned = false;

  /**
   * Reference to the `Moment` which was opened explicitly in the player,
   * from a bookmark, shared topic / subtopic, etc.
   * The player needs to pause when this moment ends.
   */
  @observable principalMoment: Moment | null = null;

  /**
   * Returns a `PlayerPrincipalMomentStatus` value indicating the relationship
   * between the current `principalMoment` and the player current time.
   * @see `PlayerPrincipalMomentStatus` docs for  for more details.
   */
  @computed
  get principalMomentPlaybackStatus(): PlayerPrincipalMomentStatus {
    const mom = this.principalMoment;
    const time = this.currentTime;

    if (!mom)
      return PlayerPrincipalMomentStatus.MomentNotSet;

    if (time < mom.startTime)
      return PlayerPrincipalMomentStatus.PlaybackNotStarted;

    if (time > mom.endTime)
      return PlayerPrincipalMomentStatus.PlaybackEnded;

    return PlayerPrincipalMomentStatus.CurrentlyPlaying;
  }

  @computed
  get activeAdapter(): IPlayerAdapter {
    if (this.adsAdapter.isActive)
      return this.adsAdapter;
    return this.adapter;
  }

  @computed get playbackRateOptions(): PlaybackRateOption[] {
    return (this.isLiveStream && this.isRealTime) ?
      [
        { label: '0.5', rate: 0.5, iconLabel: '0.5x' },
        { label: '0.75', rate: 0.75, iconLabel: '0.75x' },
        { label: 'Normal', rate: 1.0, iconLabel: '1x' },
      ] :
      [
        { label: '0.5', rate: 0.5, iconLabel: '0.5x' },
        { label: '0.75', rate: 0.75, iconLabel: '0.75x' },
        { label: 'Normal', rate: 1.0, iconLabel: '1x' },
        { label: '1.25', rate: 1.25, iconLabel: '1.25x' },
        { label: '1.5', rate: 1.5, iconLabel: '1.5x' },
        { label: '1.75', rate: 1.75, iconLabel: '1.75x' },
        { label: '2', rate: 2.0, iconLabel: '2x' }
      ];
  }

  readonly chrome = new PlayerChromeState(this.store, {
    controller: () => this
  });

  readonly playerCaption = new PlayerCaptionState(this.store, {
    adapter: () => this.adapter
  });

  readonly progressBar: PlayerProgressBarState;

  readonly volumeButton = new PlayerVolumeButtonState(this.store, {
    adapter: () => this.activeAdapter
  });

  readonly reactionButton = new PlayerReactionButtonState(this.store, {

  });

  @observable lastUserAction: string | null = null;
  @observable showLastActionFeedback: boolean = false;

  @computed get isLiveStream(): boolean {
    return !!this.adapter?.isLiveStream;
  }

  @computed get isLiveStreamRunning(): boolean {
    return this.isLiveStream && !this.playerLiveAdapter.isHlsStreamCompleted;
  }

  @computed get isRealTime() { return this.isLiveStream && this.playerLiveAdapter.isRealTime }
  @computed get isSeekable() {
    if (this.isLiveStream)
      return this.playerLiveAdapter.isSeekable;

    return true;
  }

  @computed get isPlaying() { return this.activeAdapter.isPlaying; }
  @computed get duration() {
    if (this.isLiveStream)
      return this.playerLiveAdapter.duration;

    return this.activeAdapter.duration;
  }
  @computed get hasDuration() { return this.activeAdapter.hasDuration; }
  @computed get currentTime() {
    if (this.isLiveStream)
      return this.playerLiveAdapter.currentTime;

    return this.activeAdapter.currentTime;
  }
  @computed get bufferedTime() {
    if (this.isLiveStream)
      return this.playerLiveAdapter.bufferedTime;

    return this.activeAdapter.bufferedTime;
  }
  @computed get captionsAreEnabled() { return this.playerCaption.enabled; }
  @computed get captionsAreVisible() { return this.playerCaption.showCaptions; }
  @computed get captionText() { return this.playerCaption.captionText; }

  @computed get isPaused() { return !this.isPlaying; }

  @computed
  get currentTimeRatio() {
    if (this.isLiveStream)
      return this.playerLiveAdapter.currentTimeRatio;

    if (this.hasDuration)
      return (this.currentTime / this.duration) || 0;
    return 0;
  }

  @computed
  get bufferedTimeRatio() {
    if (this.isLiveStream)
      return this.playerLiveAdapter.bufferedTimeRatio;

    if (this.hasDuration)
      return (this.bufferedTime / this.duration) || 0;

    return 0;
  }

  @computed
  get volume() {
    return this.activeAdapter.volume;
  }

  @computed get playbackRate() {
    return this.activeAdapter.playbackRate;
  }
  @computed get playbackRateItem(): PlaybackRateOption | null {
    return this.playbackRateOptions.find(opt => opt.rate === this.playbackRate) ?? null;
  }

  @computed get isSeeking(): boolean {
    return this.activeAdapter.isSeeking;
  }

  @computed get isWaiting(): boolean {
    return this.activeAdapter.isWaiting;
  }

  @computed get isAudioSource(): boolean {
    return this.media?.basic ? true : false;
  }

  @computed get teamId(): string | null {
    return this.resolvedProps.teamId ?? null;
  }

  /** 
   * The number of times the `play` event has been emitted by the adapter since the last reset.
   * According to the docs, the play event is fired when the `paused` property is changed from `true` to `false`, 
   * as a result of the `play` method, or the `autoplay` attribute.
   * This field doesn't track the number of times the `play` method has been invoked on this controller.
   * Use `playInvokeCount` for that.
   */
  @observable playCount: number = 0;

  /**
   * The number of times the `play` method has been invoked on this controller since the last reset.
   * This field doesn't track the number of times the `play` event has been emitted by the adapter.
   * Use `playCount` for that.
   */
  @observable playInvokeCount: number = 0;

  @computed get isStopped(): boolean {
    return (
      // no play request has been made and autoplay didn't kick in
      (this.playCount === 0 && this.playInvokeCount === 0 && !this.adsAdapter.isStarted) ||
      this.isEnded);
  }

  @observable isEnded = false;
  @observable isMuted = false;
  @observable volumeBeforeMute: number | null = null;
  @observable isReady = false;

  @observable isReplay = false;

  /**
   * Indicates if a seek operation has been requested to the player.
   * Useful when seek dragging has been debounced, but you want to keep the playhead in the last seeked position,
   * which will differ from player's currentTime until the seek is completed.
   */
  @observable isSeekRequested = false;

  /** True while the user is dragging the playhead in any of the sections where a playhead is available. */
  @observable isSeekDragging = false;

  /** UI only state representing the current seek position while the user is dragging the playhead or while seeking has been requested to the player. */
  @observable currentSeekTime: number | null = null;

  @observable wasPlayingBeforeEnterPause = false;
  @observable isPauseEntered = false;

  // Hover seek
  @observable isHoverSeeking = false;
  @observable hoverSeekTime = 0;

  @computed
  get status(): PlayerStatus {
    if (this.isWaiting)
      return 'Waiting';
    if (this.isSeeking)
      return 'Seeking';
    if (this.isPlaying)
      return 'Playing';
    if (this.isEnded)
      return 'Ended';
    if (this.isStopped)
      return 'Stopped';
    if (this.isPaused)
      return 'Paused';

    return 'Idle';
  }

  @computed
  get displayStatus(): PlayerStatus {
    if (this.isWaiting)
      return 'Waiting';
    if (this.isPlaying)
      return 'Playing';
    if (this.isEnded)
      return 'Ended';
    if (this.isStopped)
      return 'Stopped';
    if (this.isPaused)
      return 'Paused';
    if (this.isSeeking)
      return 'Seeking';

    return 'Idle';
  }

  /**
   * If true it means the video is stopped or ended and the player 
   * should display a poster image, if available.
   */
  @computed
  get showPoster(): boolean {
    if (this.isAdsAdapterActive)
      return false;

    if (this.speakerIdMode)
      return this.isAudioSource;

    return (
      this.status === 'Stopped' ||
      this.status === 'Ended' ||
      this.isAudioSource);
  }

  @computed
  get currentSeekTimeRatio(): number {

    if (this.currentSeekTime === null)
      return 0;

    if (this.isLiveStream)
      return this.playerLiveAdapter.getTimeRatio(this.currentSeekTime);

    if (this.hasDuration)
      return (this.currentSeekTime / this.duration) || 0;

    return 0;
  }

  @computed
  get hoverSeekTimeRatio(): number {

    if (this.isLiveStream)
      return this.playerLiveAdapter.getTimeRatio(this.hoverSeekTime);

    if (this.hasDuration)
      return (this.hoverSeekTime / this.duration) || 0;
    return 0;
  }

  /**
   * Gets the display position of the playhead, depending on the current seeking (dragging) state,
   * and also clamps it in [0, 1].
   */
  @computed
  get playheadTime(): number {

    let position;
    if (this.isSeekDragging || this.isSeekRequested) {
      if (this.currentSeekTime === null)
        return 0;

      position = this.currentSeekTime;
    } else {
      position = this.currentTime;
    }

    return clamp(position, 0, this.duration);
  }

  @computed
  get playheadTimeRatio(): number {

    if (this.isRealTime)
      return 1;

    if (this.isLiveStream)
      return this.playerLiveAdapter.playheadTimeRatio(this.playheadTime);

    if (this.hasDuration)
      return (this.playheadTime / this.duration) || 0;
    return 0;
  }

  @computed get isAdsAdapterActive() {
    return this.adsAdapter.isActive;
  }

  @computed get isAdPlaybackMode() {
    return this.isAdsAdapterActive && this.adsAdapter.hasAd;
  }

  @computed get isMobile() {
    return !!this.store.ui.isMobile;
  }

  @computed get activeCaptionItem() {
    return this.playerCaption.activeCaptionTrack;
  }

  @computed get hasMultipleCaptionTracks(): boolean {
    return this.playerCaption.hasMultipleCaptionTracks;
  }

  @computed get activeAudioTrack() {
    return this.adapter.playerAudioTracks.activeAudioTrack;
  }

  @computed get hasMultipleAudioTracks(): boolean {
    return this.adapter.playerAudioTracks.hasMultipleAudioTracks;
  }

  @computed get showReactionsButton(): boolean {
    if (!this.isComponentVisible(PlayerComponentName.ReactionButton))
      return false;

    if (this.isEditMode || this.isLiveStream) { //|| !this.job?.isMediaDone
      return false;
    }

    return !this.disableReactions ?? false;
  }

  @computed get showAddClip(): boolean {
    if (!this.isComponentVisible(PlayerComponentName.MomentEditorGroup))
      return false;
    return this.job?.isDone ?? false;
  }

  @computed get isEnrichmentProcessing(): boolean {
    return (this.job?.isEnrichmentProcessing || this.job?.isEnrichmentPending) ?? false;
  }

  @computed get isTranscriptProcessing(): boolean {
    return (this.job?.isTranscriptProcessing || this.job?.isTranscriptPending) ?? false;
  }

  @computed get momentStub(): MomentStub | null {
    return this.momentEditor.currentStub ?? null;
  }

  @computed get showLiveWaitingScreen(): boolean {
    const { job } = this;

    if (!job)
      return false;

    return (job.isLiveStreamWaiting &&
      !(this.isLivePlaybackActive || this.playerLiveAdapter.isPlaybackCompleted)) ?? false; // to avoid showing the waiting screen when stream ended (the job status coincides)
  }

  @computed get showLiveNotStreamingScreen(): boolean {
    const { job } = this;

    if (!job)
      return false;

    return (
      (this.playlistType === LiveStreamActivePlaylist.Primary && !job.isPlaylistActive(LiveStreamActivePlaylist.Primary)) ||
      (this.playlistType === LiveStreamActivePlaylist.Secondary && !job.isPlaylistActive(LiveStreamActivePlaylist.Secondary)) ||
      (!job.isPlaylistActive(LiveStreamActivePlaylist.Primary) && job.isPlaylistSelected(LiveStreamActivePlaylist.Primary)) ||
      (!job.isPlaylistActive(LiveStreamActivePlaylist.Secondary) && job.isPlaylistSelected(LiveStreamActivePlaylist.Secondary)) ||
      (job.isLive && !job.playlists?.primaryActive && !job.playlists?.secondaryActive)) && !job.isLiveProcessingDone;
  }

  @computed get showLiveEndedScreen(): boolean {
    return ((this.job?.isLiveProcessing || this.job?.isLiveStreamEnded) && !this.isLivePlaybackActive) ?? false;
  }

  @computed get isWidgetMode(): boolean {
    return this.store.widgetService.getIsWidgetMode(this.widgetMode);
  }

  // TODO: fix OverlayState and this should remove the need for these props
  @computed get playerTutorialIsOnForChromeTop(): boolean {
    return this.resolvedProps.playerTutorialHighlightedComponents?.indexOf('TOOLS') >= 0;
  }

  @computed get playerTutorialIsOnForReactions(): boolean {
    return this.resolvedProps.playerTutorialHighlightedComponents?.indexOf('REACTIONS') >= 0;
  }

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed get media(): MediaInfo | null {
    return this.resolvedProps.media || this.job?.media || null;
  }

  @computed
  private get momentDirectorMoments(): MomentModel[] {
    return this.itemSource.directorMoments;
  }

  @computed
  private get momentDirectorEnabled(): boolean {
    switch (this.selector?.outputMode) {
      case 'Topics':
      case 'Speakers':
        return true;
    }
    return false;
  }

  @computed
  private get momentReporterEnabled(): boolean {
    return true;
  }

  @computed get isFullscreen() {
    return this.store.fullscreen.fullscreenElement === this.componentRef.node;
  }

  @computed
  get currentTimeLabel(): string {

    let time;
    if (this.isSeekDragging || this.isSeekRequested) {
      time = this.currentSeekTime ?? 0;
    } else
      time = this.currentTime;

    return durationToString(time, 'time');
  }

  @computed
  get durationLabel(): string {
    return durationToString(this.duration, 'time');
  }

  @computed
  get liveOffsetLabel(): string {

    const { playerLiveAdapter } = this;

    let time;
    if (this.isSeekDragging || this.isSeekRequested) {
      time = playerLiveAdapter.duration - (this.currentSeekTime ?? 0);
    } else
      time = playerLiveAdapter.duration - this.currentTime;

    return (Math.round(time) > 0 ? '-' : '') + durationToString(time, 'time');
  }

  @computed get showLiveReactions() { return this.isRealTime && !this.suppressLiveReactions; } //&& this.controller.isPlaying;

  // is current live stream connected and playable - media derived
  @computed get isLivePlaybackActive(): boolean {
    const { playerLiveAdapter } = this;

    return !!this.job?.isLive &&
      playerLiveAdapter.isPlaybackActive;
  }

  @computed get isMonitorEnabled(): boolean {
    const { playerLiveAdapter } = this;
    const { isHlsStreamCompleted } = playerLiveAdapter;
    const { job } = this;
    if (!job)
      return false;

    // this will be simplified
    return this.isMounted && //safe measure
      this.isLivePlaybackActive &&
      job.isLiveStreamProcessing &&
      !isHlsStreamCompleted;
  }

  @computed get currentAbsoluteTimeByDate(): number | null {
    const { playerLiveAdapter } = this;
    if (this.isLiveStream)
      return playerLiveAdapter.currentAbsoluteTimeByDate;

    return this.currentTime;
  }

  @computed get currentAbsoluteTimeBySN(): number | null {
    const { playerLiveAdapter } = this;
    if (this.isLiveStream)
      return playerLiveAdapter.currentAbsoluteTimeBySN || null;

    return this.currentTime;
  }

  @computed get currentAbsoluteTime(): number | null {
    const { playerLiveAdapter } = this;
    if (this.isLiveStream)
      return playerLiveAdapter.absoluteCurrentTime || null;

    return this.currentTime;
  }

  @computed get reactionTime(): number | null {
    const { playerLiveAdapter } = this;
    if (this.isLiveStream)
      return playerLiveAdapter.liveReactionTime || null;

    return this.currentTime;
  }

  @computed get isEditMode() {
    return this.momentEditor.isEditMode;
  }


  @action
  async mounted() {
    TRACE(this, `mounted()`);

    this.emit('Mounted');
    this.setMounted();
    this.registerListeners();
  }

  @action
  registerListeners() {
    this.store.playerTutorialWindow.listen(this.playerTutorialWindowListener);
    this.store.orientation.register(this.handleOrientationChange);
    this.store.editorWindow.listen(this.editorWindowListener)
  }

  @action
  openEditorWindow = (fileName: string, mode: 'notes' | 'report') => {
    this.dispatch('openEditorWindow', { data: mode === 'notes' ? this.job?.publicSafetyNotes : this.job?.publicSafetyReport, fileName, jobId: this.jobId, mode });
    this.invoke('enterPause');
  }

  private editorWindowListener = action((msg: Message<EditorWindowState>) => {
    switch (msg.type) {
      case 'editor:save:notes':
        if (!this.jobId) {
          return;
        }
        
       this.updateJob({
        id: this.jobId,
        title: this.job?.title,
        publicSafetyNotes: msg.payload
       })
      break;

      case 'editor:save:report':
        if (!this.jobId) {
          return;
        }
    
       this.updateJob({
        id: this.jobId,
        title: this.job?.title,
        publicSafetyReport: msg.payload
       })
      break;
    }
  });

  @action 
  async updateJob(args: UpdateJobInput) {
    await this.store.apiService.updateJob({
      args
    });

    await this.store.apiFetchJob(args.id, true);
  }

  @action
  unregisterListeners() {
    this.store.orientation.dispose();
    this.store.playerTutorialWindow.unlisten(this.playerTutorialWindowListener);
  }

  @action
  unmounted() {
    TRACE(this, 'unmounted()');

    this.emit('Unmounted');
    this.setUnmounted();
    this.unregisterListeners();
    this.lockOrientation = false;
  }

  @action
  reset() {
    TRACE(this, `reset()`);

    this.momentEditor.reset();
    this.momentDirector.reset();

    this.resetPlayState();
    this.playerLiveAdapter.reset();
    this.isReplay = false;
    this.adsAdapter.reset();

    this.mediaStreamMonitor.reset();
    this.playerCaption.reset();
  }

  @action
  resetPlayState() {
    this.playCount = 0;
    this.playInvokeCount = 0;
    this.isEnded = false;
    this.chrome.endControlAction();
  }

  @action
  private setMounted() {
    this.isMounted = true;
  }

  @action
  private setUnmounted() {
    this.isMounted = false;
  }

  @action
  invokeHandler = (type: string, payload: any = null) =>
    (evt: React.SyntheticEvent) => this.invoke(type, payload);

  @action
  invoke = async (type: string, payload: any = null) => {

    TRACE(this, `Invoke`, type, payload);

    const { adapter, activeAdapter } = this;

    // Log the last action made by the user for visual feedback
    this.toggleLastActionFeedback(type);

    switch (type) {

      case 'autoplay':
        // TODO: check and fix
        if (this.isLiveStream)
          this.playerLiveAdapter.invoke('autoplay');
        else {
          // TODO: check that this still does the same thing as the old version
          // (with the autoplay handling in the adapter)
          adapter.invoke('setAutoplay', { autoplay: true });
          adapter.invoke('play');
        }
        break;

      case 'play':
        this.play(payload);
        break;

      case 'pause':
        if (this.isLiveStream)
          this.playerLiveAdapter.invoke('pause');
        else
          activeAdapter.invoke('pause');
        break;

      case 'disableAutoplay':
        activeAdapter.invoke('setAutoplay', { autoplay: false });
        break;

      case 'stop':
        this.invoke('pause');
        this.resetPlayState();
        break;

      case 'end':
        this.invoke('pause');
        this.resetPlayState();
        this.isEnded = true;
        break;

      case 'enterPause':
        if (this.isPlaying)
          this.wasPlayingBeforeEnterPause = true;

        this.isPauseEntered = true;
        activeAdapter.invoke('pause');
        break;

      case 'exitPause':
        if (this.wasPlayingBeforeEnterPause)
          activeAdapter.invoke('play', payload);

        this.isPauseEntered = false;
        this.wasPlayingBeforeEnterPause = false;
        break;

      case 'togglePlay':
        if (this.isPlaying)
          this.invoke('pause');
        else
          this.invoke('play');
        break;

      case 'toggleRealTimeMode':
        this.enterRealTime();
        break;

      case 'setPlaybackRate':
        if (this.isLiveStream)
          this.playerLiveAdapter.invoke('setPlaybackRate', payload);
        else
          activeAdapter.invoke('setPlaybackRate', payload);
        break;

      case 'mute':
        this.isMuted = true;
        this.volumeBeforeMute = this.volume;
        activeAdapter.invoke('setVolume', { volume: 0 });
        break;

      case 'unmute':
        activeAdapter.invoke('setVolume', { volume: this.volumeBeforeMute || 0.5 });

        this.isMuted = false;
        this.volumeBeforeMute = null;
        break;

      case 'setVolume':
        activeAdapter.invoke('setVolume', { volume: payload });
        this.isMuted = false;
        break;

      case 'toggleMute':
        if (this.isMuted || this.volume === 0)
          this.invoke('unmute');
        else
          this.invoke('mute');
        break;

      case 'seek':
        this.seek(payload.time, true);
        break;

      case 'seekStart':
        this.isSeekDragging = true;
        this.seek(payload.time, true);
        break;

      case 'seekEnd':
        this.seek(payload.time, false);
        this.isSeekDragging = false;
        break;

      case 'seekStepForward':
        this.seekStepForward();
        break;
      case 'seekStepBackward':
        this.seekStepBackward();
        break;

      case 'seekToEnd':
        if (this.hasDuration)
          this.seek(this.duration);
        break;

      case 'hoverSeek':
        this.hoverSeekTime = payload.time;
        break;

      case 'hoverSeekStart':
        this.isHoverSeeking = true;
        this.hoverSeekTime = payload.time;
        break;

      case 'hoverSeekEnd':
        this.isHoverSeeking = false;
        // state.hoverSeekTime = 0;
        break;

      case 'jumpToMoment': {
        const { moment } = payload;
        const momentTime = this.isLiveStream ?
          this.playerLiveAdapter.convertToRelativeTime(moment.startTime) :
          payload.moment.startTime;
        this.seek(momentTime);

        if (payload.setAsPrincipal)
          this.principalMoment = moment;
        else
          this.principalMoment = null;
      } break;

      case 'jumpToComment':
        const commentTime = this.isLiveStream ?
          this.playerLiveAdapter.convertToRelativeTime(payload?.comment?.videoTime) :
          payload?.comment?.videoTime;
        this.seek(commentTime);
        break;

      case 'jumpToReaction':
        const reactionTime = this.isLiveStream ?
          this.playerLiveAdapter.convertToRelativeTime(payload?.reaction?.videoTime) :
          payload?.reaction?.videoTime;
        this.seek(reactionTime);
        break;

      case 'jumpToCommentOrReaction':
        let commentOrReactionTime;
        if (this.isLiveStream)
          commentOrReactionTime = this.playerLiveAdapter.convertToRelativeTime(payload?.target?.videoTime)
        else if (!!payload?.target?.isLive && !!this.job?.source.liveCliprHLS) {
          commentOrReactionTime = payload?.target?.videoTime - this.adapter.initPtsInSec;
        } else {
          commentOrReactionTime = payload?.target?.videoTime;
        }
        // console.log(commentOrReactionTime);
        this.seek(commentOrReactionTime);
        break;

      case 'enableCaptions':
        this.playerCaption.enableCaptions();
        break;

      case 'disableCaptions':
        this.playerCaption.disableCaptions();
        break;

      case 'reattach':
        this.adapter.reattachDOMVideo();
        break;

      case 'toggleCaptions':
        this.playerCaption.toggleCaptions();
        break;

      case 'exitRealTime':
        this.exitRealTime();
        break;
      case 'enterRealTime':
        this.enterRealTime();
        break;
      case 'updateRealTime':
        this.updateRealTime();
        break;


      case 'replay':
        this.applyReplayInitialSeek();
        this.invoke('play');
        break;

      case 'toggleFullscreen':

        this.lockOrientation = true;

        try {
          await this.store.fullscreen.toggleFullscreen(
            this.componentRef.node);
        } catch {
          this.lockOrientation = false;
        }
        break;

      case 'enterFullscreen':
        await this.store.fullscreen.ensureFullscreen(
          this.componentRef.node);
        break;

      case 'exitFullscreen':
        await this.store.fullscreen.ensureNotFullscreen();
        break;

      // added to work with trainer page
      case 'clearMomentStub':
        this.momentEditor.exitEditMode();
        break;

      case 'startMomentStub':
        // TODO: use this as an example to think of a command policy system
        if (this.isEnrichmentProcessing || this.job?.status === 'InProgress')
          return;
        this.momentEditor.enterEditMode();
        break;

      case 'confirmMomentStub':
        if (this.job?.status === 'InProgress' || !this.isEditMode)
          return;
        this.momentEditor.editConfirmMoment(this.openMomentWindowOptions);
        break;

      default:
        break;
    }
    // These events are emitted from the adapterListener as well
    // TODO check if this emit is still necessary
    if (!['play', 'pause'].includes(type)) {
      this.emit(type, payload);
    }

    // emit an aggregate seekChange event
    switch (type) {
      case 'seek':
      case 'seekStart':
      case 'seekEnd':
      case 'seekStepForward':
      case 'seekStepBackward':
      case 'jumpToMoment':
        this.emit('seekChange', { time: this.currentSeekTime });
        break;
    }
  }

  @action
  async handleFullscreenChange() {

    if (!this.componentRef.node)
      return;

    if (this.isFullscreen && this.lockOrientation) {
      // await this.store.orientation.lockPortrait();
      // this.store.orientation.unlock();
      await this.store.orientation.lockLandscape();
    }

    if (!this.isFullscreen)
      this.store.orientation.unlock();

    this.lockOrientation = false;
  }

  /**
   * MobX reaction handler for `principalMomentStatus`.
   */
  protected handlePrincipalMomentStatusReaction = (curr: PlayerPrincipalMomentStatus, prev: PlayerPrincipalMomentStatus) => {

    if (curr === PlayerPrincipalMomentStatus.PlaybackEnded) {
      this.invoke('pause');
      this.principalMoment = null;
    }
  }

  @action
  enterEditMode() {
    return this.momentEditor.enterEditMode();
  }

  @action
  exitEditMode() {
    return this.momentEditor.exitEditMode();
  }

  @action
  toggleEditMode() {
    return this.momentEditor.toggleEditMode();
  }

  momentHasBookmark(moment?: MomentModel | null): boolean {
    return moment?.hasBookmark ?? false;
  }


  @action
  openBookmarkWindow(moment?: MomentModel | null) {
    if (!moment)
      return false;

    const window = this.store.bookmarkWindow;
    const listener = (msg: Message<BookmarkWindowState>) => {
      switch (msg.type) {
        case 'open':
          this.invoke('enterPause');
          break;
        case 'close':
          window.unlisten(listener);
          // this.invoke('exitPause');
          break;
      }
    }

    window.listen(listener);
    window.openMoment(moment.id, moment.jobId);

    return true;
  }

  // #region Event handlers
  // -------
  handleTimelineClick = (evt: React.MouseEvent) => {
    this.seek(
      this.getTimeFromEvent(evt, this.progressBarRef));
  }

  handleLoginButtonClick = () => {
    this.emit('requestLogin');
  }

  handleLogoutButtonClick = () => {
    this.emit('requestLogout');
  }

  handleHelpButtonClick = () => {
    this.emit('openTutorialWindow');
  }
  // #endregion

  async copyIFrameEmbedCode() {
    const { jobId } = this;
    const { ui } = this.store;
    if (!jobId)
      return;

    const { job } = this;
    const code = getPlayerWidgetIFrameCode({
      jobId,
      time: (job?.isLiveStreamWaiting || job?.isLiveStreaming) ? 'none' : this.time,
      theme: ui.theme,
      showIndex: this.showIndex,
      showComments: this.showComments,
      showTranscript: this.showTranscript,
      showProfile: this.showProfile,
      showTopicTags: this.showTopicTags,
      showEmbedIcon: this.showEmbedIcon,
      showHelp: this.showHelp,
      disableReactions: this.disableReactions,
      disableComments: this.disableComments,
      disableTranscript: this.disableTranscript,
      disableIndex: this.disableIndex,
      allowFullscreen: this.allowFullscreen,
      allowShare: this.allowShare,
      allowDownload: this.allowDownload,
      autoplay: this.autoplay,
      teamId: this.teamId,
      customRedirect: this.customRedirectUrl,
    });

    if (!code)
      return;

    await navigator.clipboard?.writeText(code);
    notifySuccess(this, 'Embed code copied to clipboard!');
  }

  @action
  applyDirectorInitialSeek(): boolean {

    const moments = this.momentDirectorMoments || [];
    if (moments.length > 0) {
      this.seek(moments[0].startTime);
      return true;
    }

    return false;
  }

  @action
  applyReplayInitialSeek(): boolean {
    const dirApp = this.applyDirectorInitialSeek();
    if (!dirApp)
      this.seek(0);
    return true;
  }

  isComponentVisible(name: PlayerComponentName) {
    const policy = this.getComponentPolicy(name);
    return policy.visibility === ComponentVisibility.Visible;
  }

  isComponentReadOnly(name: PlayerComponentName) {
    const policy = this.getComponentPolicy(name);
    return !!policy.isReadOnly;
  }

  isComponentDisabled(name: PlayerComponentName) {
    if (this.getComponentPolicy) {
      const policy = this.getComponentPolicy(name);
      return !!policy.isDisabled;
    }

    return true;
  }

  getComponentPolicy(name: PlayerComponentName): IComponentPolicy {
    const isAdMode = this.isAdPlaybackMode;
    const isMobile = this.isMobile;

    const policy: IComponentPolicy = {
      visibility: ComponentVisibility.Visible,
      isReadOnly: false,
      isDisabled: false
    }

    const hide = () => {
      policy.visibility = ComponentVisibility.Hidden;
    }
    const readOnly = () => {
      policy.isReadOnly = true;
    }
    const disable = () => {
      policy.isDisabled = true;
    }

    switch (name) {
      case PlayerComponentName.PlayPauseButton:
        break;

      case PlayerComponentName.SeekBackButton:
        if (isAdMode || !this.isSeekable)
          hide();
        break;

      case PlayerComponentName.SeekNextButton:
        if (isAdMode || !this.isSeekable)
          hide();
        break;

      case PlayerComponentName.PlaybackRateButton:
        // just because the lousy google ima sdk doesn't support it
        // TODO: maybe add a `allowPlaybackRateChange` in the adapter interface
        // and read it from there
        if (isAdMode || !this.isSeekable)
          hide();
        break;

      case PlayerComponentName.VolumeButton:
        // just because this piece of shit sdk which is google ima sdk causes
        // idiotic crashes with TypeErrors thrown from their internal code for
        // perfectly valid calls on perfectly valid instances  of their stupid objects
        // TODO: maybe add a `allowVolumeChange` in the adapter interface
        // and read it from there
        if (isAdMode || isMobile)
          hide();
        break;

      case PlayerComponentName.ClosedCaptionsButton:
        const hasCaptions = this.playerCaption.hasCaptions;
        const hideCaptionsForVOD = !this.isLiveStream && !this.job?.isTranscriptDone;
        if (isAdMode || !hasCaptions || hideCaptionsForVOD || this.playerLiveAdapter.isHlsStreamCompleted)
          hide();
        break;

      case PlayerComponentName.AudioTrackButton:
        if (isAdMode || !this.hasMultipleAudioTracks || this.playerLiveAdapter.isHlsStreamCompleted)
          hide();
        break;

      case PlayerComponentName.ReactionButton:
        if (isAdMode || this.showReactionButton === false)
          hide();
        break;

      case PlayerComponentName.ProgressBar:
        if (isAdMode)
          readOnly();
        break;

      case PlayerComponentName.MarkerBar:
        if (isAdMode)
          hide();
        break;

      case PlayerComponentName.ExplorerBar:
        if (isAdMode)
          hide();
        break;

      case PlayerComponentName.ProgressBarMoments:
        if (isAdMode)
          hide();
        break;

      case PlayerComponentName.AdsBar:
        if (!isAdMode)
          hide();
        break;

      case PlayerComponentName.CommentsSection: {
        if (isAdMode)
          disable();
        break;
      }

      case PlayerComponentName.TranscriptsSection: {
        if (isAdMode)
          disable();
        break;
      }

      case PlayerComponentName.IndexSection: {
        if (isAdMode)
          disable();
        break;
      }

      case PlayerComponentName.Toolbar: {
        if (isAdMode)
          disable();
        break;
      }

      case PlayerComponentName.AddMomentButton: {
        if (
          isAdMode ||
          this.job?.status === 'InProgress' ||
          this.showAddMomentButton === false)
          hide();

        // TODO: use this as use case and find an elegant solution to cover all scenarios
        const defaultShow = (
          this.isWidgetMode &&
          this.job?.hasPermission('EditUserMoment') &&
          this.showAddClip &&
          !this.isMobile);

        if (!defaultShow && this.showAddMomentButton !== true)
          hide();

        break;
      }

      case PlayerComponentName.MomentEditorGroup: {
        if (isAdMode)
          hide();
        break;
      }
    }

    return policy;
  }

  // #region Queries / Mutations
  async addReaction(name: InputPlayerReactionName)
    : AsyncResult<Reaction> {

    const { job, jobId } = this;
    if (!job || !jobId || !this.reactionTime) {
      notifyError(this, 'Could not add your reaction.');
      return [null, new Error('No job active')];
    }

    const time = Math.round(this.reactionTime);

    // console.log(Math.round(this.currentTimeAbsDate || 0), this.currentTimeAbsDate);
    // console.log(Math.round(this.currentTimeAbsSN || 0), this.currentTimeAbsSN);
    // console.log(time, this.currentAbsoluteTime);

    if (!isFiniteNumber(time)) {
      notifyError(this, 'Could not add your reaction.');
      return [null, new Error(`Player doesn't have a valid time.`)];
    }

    const input: AddReactionInput = {
      jobId,
      reaction: name,
      videoTime: time,
      watchMode: this.isLiveStream ? WatchMode.Live : WatchMode.Static,
    };

    const [reaction, err] = await job.apiAddReaction(input);

    this.invoke('exitPause');
    if (err || !reaction) {
      notifyError(this, 'Could not add your reaction.');
      return [null, err];
    }

    if (!this.isLiveStream)
      notifySuccess(this, 'Reaction added successfully');
    this.broadcast('reactionAdded', { reactionName: name, job: this.job });
    return [reaction];
  }

  throttledAddReaction = throttle((name) => this.addReaction(name), ADD_REACTION_THROTTLE_TIME, {
    leading: true,
    trailing: false
  })
  // #endregion

  @action
  shareVideo(moment?: MomentModel) {
    this.invoke('enterPause');

    const { shareVideoWindow: window, ui } = this.store;
    const listener = (msg: Message<ShareVideoWindowState>) => {
      switch (msg.type) {
        case 'close':
          window.unlisten(listener);
          this.invoke('exitPause');
          break;
      }
    }
    window.listen(listener);

    const args: ShareWindowProps = {
      mode: ShareWindowMode.Social,
      job: this.job,
      teamId: this.teamId,
      sharedFromLocation: SharedFrom.PlayerPage,
      moment,
      theme: ui.theme,
      showIndex: this.showIndex,
      showComments: this.showComments,
      showTranscript: this.showTranscript,
      showProfile: this.showProfile,
      showTopicTags: this.showTopicTags,
      showEmbedIcon: this.showEmbedIcon,
      showHelp: this.showHelp,
      disableReactions: this.disableReactions,
      disableComments: this.disableComments,
      disableTranscript: this.disableTranscript,
      disableIndex: this.disableIndex,
      allowFullscreen: this.allowFullscreen,
      allowShare: this.allowShare,
      allowDownload: this.allowDownload,
      autoplay: this.autoplay,
      time: this.time,
      customRedirectUrl: this.customRedirectUrl
    };
    window.open(args);
  }

  @action
  handleOrientationChange = () => {
    const orientation = this.store.orientation;
    const orientationType = orientation.getOrientationType();
    if (this.isWidgetMode || !orientationType)
      return;

    if (["landscape-primary", "landscape-secondary"].includes(orientationType))
      this.invoke('enterFullscreen');
    else if (['portrait-primary', "portrait-secondary"].includes(orientationType))
      this.invoke('exitFullscreen');
  }


  // #region Query methods
  // -------
  getTimeFromRatio(ratio: number) {

    if (this.isLiveStream)
      return this.playerLiveAdapter.getTimeFromRatio(ratio);

    if (this.hasDuration)
      return (ratio * this.duration) || 0;
    return 0;
  }

  getTimeFromEvent(evt: PointerEventLike, containerRef?: Maybe<React.RefObject<HTMLElement> | RefProxy<HTMLElement>>) {

    if (!containerRef || !containerRef.current)
      return 0;

    const container = containerRef.current;
    const offset = getEventOffset(evt, container);

    return this.getTimeFromRatio(
      clamp(offset.x / container.offsetWidth, 0, 1))
  }

  getTimeRatio(time: number) {

    if (this.isLiveStream)
      return this.playerLiveAdapter.getTimeRatio(time);

    if (this.hasDuration)
      return (time / this.duration) || 0;
    return 0;
  }
  // #endregion

  @action
  private playerTutorialWindowListener = (msg: Message<PlayerTutorialWindowState>) => {
    switch (msg.type) {
      case 'open':
        this.tutorialMode = true;
        break;
      case 'close':
        this.tutorialMode = false;
        break;
    }
  }

  // #region API / seek
  @action
  seek = (time: number, debounce = false) => {

    if (!this.isSeekable)
      return;

    this.currentSeekTime = time;
    if (debounce) {
      this.adapterSeekDebounced(time);
      return;
    }

    // debounce is falsy, so cancel any previously debounced functions
    // otherwise the seek call might be triggered twice (one from the call below and one from a previously debounced call, if any)
    this.adapterSeekDebounced.cancel();
    this.adapterSeek(time);

    // clear flags
    this.principalMoment = null;
  }

  @action
  seekStep = (delta: number) => {
    if (!this.hasDuration)
      return;

    let time = clamp((this.currentSeekTime || this.currentTime) + delta, 0, this.duration);
    this.seek(time, false);
  }

  @action
  seekStepForward() {
    if (this.isLiveStream && this.isRealTime)
      return;

    return this.seekStep(10);
  }

  @action
  seekStepBackward() {
    return this.seekStep(-10);
  }

  @action
  adapterSeek = (time: number) => {

    if (this.adapter.currentTime === time) {
      // This is fix for a bug on some videos where the video element is stuck on 'seeking'
      // thus at load if time === 0 it will be equal to currentTime and it won't set it again

      // NOTE: this was initially fixed on https://github.com/cliprai/clipr/pull/721
      // and the check was put on PlayerAdapterState.seek
      // however this was causing issues when having a `time` parameter set on a widget with a value of 0
      // which was causing an automatic seek which in turn was discarded because `currentTime` was also 0
      // which ultimately caused the `playheadTime` to remain 0 because `isSeekRequested` was true and no `seeked`
      // event came back from PlayerAdapter
      return;
    }

    if (this.isLiveStream) {
      this.playerLiveAdapter.invoke('seek', { time });
      this.isSeekRequested = true;
      return;
    }

    this.adapter.invoke('seek', { time });

    this.isSeekRequested = true;

    const { trainerVideoPage } = this.store;
    trainerVideoPage.timeline.playhead.scrollIntoView();
  }

  adapterSeekDebounced = debounce((time: number) => this.adapterSeek(time),
    SEEK_DEBOUNCE_WAIT);

  @action
  enterRealTime = () => {
    if (!this.isLiveStream)
      return;

    this.playerLiveAdapter.invoke('enterRealTime');
  }

  @action
  exitRealTime = () => {
    if (!this.isLiveStream)
      return;

    this.playerLiveAdapter.invoke('exitRealTime');
  }

  @action
  updateRealTime = () => {
    if (!this.isLiveStream)
      return;

    this.playerLiveAdapter.invoke('updateRealTime');
  }
  // #endregion


  // #region Listeners
  // -------
  @action
  playheadListener = (msg: Message<PlayheadState>) => {

    switch (msg.type) {
      case 'seek':
      case 'seekStart':
      case 'seekEnd':
        this.invoke(msg.type, msg.payload);
        break;

      default: break;
    }
  }

  @action
  progressBarListener = (msg: Message<PlayerProgressBarState>) => {

    switch (msg.type) {
      case 'seek':
      case 'seekStart':
      case 'seekEnd':
        this.invoke(msg.type, msg.payload);
        break;

      default: break;
    }
  }

  @action
  handleLivePlaybackCompleted = async () => {
    const { job } = this;
    if (!job)
      return;

    const [res, err] = await this.store.apiFetchJob(job.id);

    if (!res && err)
      console.warn('job could not be fetched:', err);

    if (job.isMediaDone || job.isMediaFailed) {
      // console.log('media ready - reattach video for VOD');
      this.reset();
      this.adapter.reattachDOMVideo(); // after stream playback is completed, reload media if VOD is ready
    }

    if (res)
      await res.apiFetchReactions();
  }

  @action
  adapterListener = async (msg: Message<PlayerAdapterState>) => {

    // if (msg.type !== 'timeupdate')
    //   TRACE(this, `Adapter message`, msg.type, msg.payload);

    switch (msg.type) {
      case 'play':

        // a safe measure to make sure at first play the live is seeked to real time
        if (this.isLiveStream && this.playCount === 0) {
          // console.log('at first play', {
          //   seekableStart: this.playerLiveAdapter.liveSeekableStart,
          //   liveSeekableEnd: this.playerLiveAdapter.appliedLiveCurrentTime,
          //   seekableEnd: this.playerLiveAdapter.seekableEnd,
          //   currentTime: this.playerLiveAdapter.currentTime
          // });
          this.playerLiveAdapter.seekToLiveEdge();
        }

        this.playCount++;
        this.isEnded = false;
        break;

      case 'playing':
        this.isEnded = false;
        break;

      case 'pause':
        break;

      case 'seeking':
        this.isEnded = false;
        this.emit('seeking');
        break;

      case 'seeked':
        this.isEnded = false;
        this.isSeekRequested = false;
        if (!this.isSeekDragging)
          this.currentSeekTime = null;

        this.emit('seeked');
        break;

      case 'ended':
        if (this.isLiveStream) {
          // console.log('playback completed - media');
          this.playerLiveAdapter.invoke('playbackCompleted');
        }

        this.adsAdapter.signalVideoEnded();
        this.isEnded = true;
        break;

      case 'endOfStream':
        if (this.isLiveStream) {
          // console.log('EOS');
          this.playerLiveAdapter.setStreamEnded();
        }
        break;

      case 'videoDetached':
        this.reset();
        break;

      case 'loadedmetadata':
        if (this.adapter.videoElement) // the textStream might be initiated or added later during the stream
          this.adapter.videoElement.textTracks.onaddtrack = this.playerCaption.initMediaCaptions;
        break;
      case 'loadeddata':
        this.playerCaption.initMediaCaptions(); // the textStream might be initiated or added later during the stream
        break;

      default: break;
    }

    this.emit(msg.type, msg.payload);
  }
  // #endregion

  @action
  adsAdapterListener = (msg: Message<PlayerAdsAdapterState>) => {
    TRACE(this, `AdsAdapter message`, msg.type, msg.payload);

    switch (msg.type) {
      case PlayerAdsAdapterMessageType.RequestBreak:
        this.adapter.invoke('pause');
        break;

      case PlayerAdsAdapterMessageType.RequestResume:
        if (!this.isEnded)
          this.adapter.invoke('play');
        break;
    }
  }

  @action
  playerLiveAdapterListener = (msg: Message<PlayerLiveAdapter>) => {
    switch (msg.type) {
      case 'playbackCompleted':
        this.handleLivePlaybackCompleted();
        break;
    }
  }

  private toggleLastActionFeedback(action: string) {
    this.lastUserAction = action;
    this.showLastActionFeedback = true;

    this.debouncedFeedbackToggle();
  }
  private debouncedFeedbackToggle = debounce(() => this.showLastActionFeedback = false, 350);

  @action
  handleVolumeSliderChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.adapter.invoke('setVolume', { volume: parseFloat(evt.target.value) });
  }

  @action
  applyTimeParam(time: number | null) {

    if (!isFiniteNumber(time))
      return false;

    if (this.hasDuration && time > this.duration)
      return false;

    this.invoke('seek', { time });
    return true;
  }


  @action
  setAdTagUrl(url: string) {
    this.adsAdapter.setAdTagUrl(url);
  }

  @action
  async loadAdTagUrl(reqOpts?: ApiRequestOptions) {
    TRACE(this, `Loading ads from team settings`);

    const { store, teamId, job } = this;
    if (!teamId) {
      TRACE(this, `Cannot load ads because no 'teamId' is available`);
      return;
    }

    // TODO: cleanup this paranoid check
    if (job?.liveStream || job?.media.liveStreamUrl || job?.source.type === 'Live') {
      TRACE(this, `Ads will not be loaded because the job is a live one`);
      return;
    }

    const [res, err] = await store.api.runQuery('getTeam_getAds', { id: teamId }, reqOpts);
    if (err) {
      TRACE(this, `An error occurred while loading ads from team settings:`, err);
      return [null, err];
    }

    // we do nothing in case there's an error with getting the ads
    const adTagUrls = res?.getTeam.adTagUrls;
    if (!Array.isArray(adTagUrls)) {
      TRACE(this, `'getTeam_getAds' query for ads was loaded but the returned 'adTagUrls' object is not an array`, adTagUrls);
      return;
    }

    const adTagUrl = randomArrayElement(adTagUrls);
    if (!isNonEmptyString(adTagUrl)) {
      TRACE(this, `'getTeam_getAds' query for ads was loaded but no valid URL has been randomly selected from 'adTagUrls' object`, adTagUrl);
      return;
    }

    this.setAdTagUrl(adTagUrl);
  }

  async start() {
    TRACE(this, `start()`);

    const autoplay = this.autoplay ?? true;
    const { adsAdapter } = this;
    const { autoplayPolicy } = Config.player;

    // in an ideal world, the bastard google ima sdk would tell you if autoplay will work or not
    // in a semi-ideal world, even if the sdk doesn't tell you that, at least it will let you play
    // the ad again on user action after a failed autoplay attempt
    // but obviously we live in a world in which the "best" programmers in the world don't tell you
    // if autoplay will work and also they won't let you play the ad again on user action after a failed autoplay attempt
    // as always, if you want to do something right, you have to do it yourself

    // we can find out if autoplay is allowed for non-muted and muted scenarios in parallel

    TRACE(this, `Detecting if autoplay is allowed`);
    const [canAutoplay] = await this.store.autoplay.canAutoplayVideo({ timeout: 2000 });
    TRACE(this, `Detected that autoplay is ${canAutoplay ? 'allowed' : 'restricted'}`);

    adsAdapter.setAdWillAutoplay(canAutoplay ?? false);
    adsAdapter.setAdWillAutoplayMuted(false);

    const useAutoplay = (() => {
      switch (autoplayPolicy) {
        case ConfigAutoplayPolicy.Disabled:
          return false;
        case ConfigAutoplayPolicy.Standard:
          return canAutoplay && autoplay;
        case ConfigAutoplayPolicy.Force:
          return true;
      }
    })();

    TRACE(this, `Loading the ads adapter`);

    const [adsLoaded, adsLoadErr] = await adsAdapter.load();
    if (!adsLoaded || adsLoadErr)
      TRACE(this, `Ads adapter loading failed`);
    else
      TRACE(this, `Ads adapter loading succeeded`);

    if (useAutoplay) {

      TRACE(this, `Will try to autoplay because the browser allows it and it was requested by the current configuration`);

      let playAds = adsAdapter.adTagUrl && adsLoaded && !adsLoadErr;
      let playVideo = !playAds;

      if (playAds) {

        TRACE(this, `Autoplay the ads because we have an ad tag URL set and the ads adapter load was completed successfully`);

        this.adapter.pause();

        const [adsStarted, adsStartErr] = adsAdapter.start();
        if (!adsStarted || adsStartErr) {
          TRACE(this, `Autoplaying the ads failed, will proceed to autoplay the main video`);
          playVideo = true;
        } else {
          TRACE(this, `Autoplaying the ads succeeded`);
          // it should already be false, but set it for safety
          playVideo = false;
        }
      }

      if (playVideo) {
        // the play promise might hang indefinitely if the source is messed up or timing out
        // thus we wait on the result only internally for logging
        // but the output of the `start` function won't wait on the start result
        // in the future this should be handled in a more predictable way,
        // maybe by waiting for the first non-error event on the player

        TRACE(this, `Autoplay the video`);

        (async () => {
          const [, err] = await this.adapter.play();

          if (err) {
            TRACE(this, `Autoplay failed`);
            return [null, err];
          }

          TRACE(this, `Autoplay success`);
        })();

        TRACE(this, `Autoplay call completed (not waiting on it)`);
      }
    }
  }

  async play(params: PlayParams) {

    if (this.isEnded)
      this.isReplay = true;

    const { isReplay } = this;
    const isFirst = this.isStopped && !this.isEnded; // TODO: create another var for this

    this.isEnded = false;
    this.playInvokeCount++;

    if (this.isLiveStream) {
      this.playerLiveAdapter.invoke('play', params);
    }
    else {
      if (isFirst && !isReplay && this.adsAdapter.adTagUrl) {
        this.adsAdapter.start();
      }
      else {
        await this.activeAdapter.invoke('play');
      }
    }
  }
}