import './teams.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  Button,
  TextInput
} from '../input';
import { TeamDictionaryState } from './teamDictionaryState';
import { PencilSVGIcon } from '../svg';

type Props = {
  model: TeamDictionaryState;
};

export const TeamDictionary = observer(({ model }: Props) => {

  return (
    <div className="team-dictionary">
      <div className="team-dictionary-header">
        CLIPr does not always know how names, jargon, and other special words in your videos are correctly spelled. Add commonly-misspelled words or names here to help train our AI:
        <Button
          className="team-dictionary-header-edit-btn"
          icon={<PencilSVGIcon />}
          onClick={() =>
            model.dispatch('openTeamDictionaryWindow', {
              teamId: model.teamId
            })
          } />
      </div>
      <TextInput
        className="form-input"
        value={model.team?.vocabularyAsText}
        showFeedback={false}
        multiline
        disabled
      />
    </div>
  );
});
