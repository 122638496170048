import './bookmarks.scss';

import React, { CSSProperties, ReactNode } from 'react';
import { LocationDescriptor } from 'history';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import Routes, { BackRoutes, RouteParams } from '../../routes';
import { PlaySVGIcon, TextInputWarningSVGIcon } from '../../components/svg/svg';
import { Bookmark } from '../../entities/bookmark';
import { MomentModel } from '../../entities/moment';
import { MenuItem } from '../input/menuItems';
import { Link } from '../layout';
import { Thumb } from '../media/thumb';
import { MenuButton } from '../menu';
import { Maybe } from '../../core';
import { SharedFrom } from '../../services/analytics/stream';

type Props = {
  model: Bookmark;
  moment?: MomentModel;
  aspectRatio?: number;
};

export const BookmarkCard = observer((props: Props) => {
  const { model } = props;

  const routeParams: RouteParams = {
    id: model.jobId,
    momentId: model.momentId,
    listId: model.listId,
  };
  const linkRoute: LocationDescriptor | null = model.isPublished
    ? BackRoutes[Routes.userVideoWithQuery()].route(routeParams)
    : null;

  const cx = classNames('job-card', 'bookmark-card', 'video', {
    'status-is-unpublish': !model.isPublished,
  });

  const previewStyle: CSSProperties = {
    ['--aspect-ratio' as any]: props.aspectRatio || 9 / 16,
  };

  const ellipseTitle = (title: string) => {
    if (title?.length > 66) {
      return title?.substring(0, 65) + '...';
    } else {
      return title;
    }
  };

  let previewElem: Maybe<ReactNode>;

  if (!model.isPublished) {
    previewElem = (
      <div className="card-status progress dock">
        <Thumb source={model.thumbnailUrl || ''} className="card-thumb dock" />
        <div className="card-overlay unpublish">
          <TextInputWarningSVGIcon />
          <p>Video is no longer available</p>
          <p className="small">
            If you think this is an error, please{' '}
            <a href={'mailto:' + process.env.REACT_APP_EMAIL_QA}>
              Contact Us &#8594;
            </a>
          </p>
        </div>
      </div>
    );
  } else {
    previewElem = (
      <div className="card-status progress dock status-done">
        <Thumb
          source={model.thumbnailUrl || model}
          className="card-thumb dock"
        />
        <div className="play-icon">
          <PlaySVGIcon />
        </div>
      </div>
    );
  }

  return (
    <div
      className={cx}
      data-job-id={model.jobId}
      data-moment-id={model.momentId}>
      <Link
        to={linkRoute as any}
        className="card-preview aspect-ratio-box"
        style={previewStyle}>
        {previewElem}
      </Link>

      <Link to={linkRoute as any} className="card-title">
        {ellipseTitle(model.jobStub?.title || model.job?.title || '')}
      </Link>

      <Link to={linkRoute as any} className="card-clip-type">
        <span className="text">{model.momentStub.clipType}</span>
      </Link>
      <Link to={linkRoute as any} className="card-clip-name">
        <span className="text">
          {model.momentStub.momentType || model.momentStub.name}
        </span>
      </Link>

      <div className="card-menu">
        <MenuButton>
          {model.actualJob?.isPublic && (
            <MenuItem
              model={{ value: 'Share', label: 'Share' }}
              onClick={(evt) => {
                model.dispatch('openShareVideoWindow', {
                  bookmark: model,
                  sharedFromLocation: SharedFrom.BookmarkPage,
                });
              }}
            />
          )}

          <MenuItem
            model={{ value: 'Download a bookmark', label: 'Download a bookmark' }}
            onClick={(evt) => {
              model?.dispatch('openDownloadJobWindow', {
                jobId: model.jobId,
                startTime: model.moment?.startTime,
                endTime: model.moment?.endTime,
              })
            }}
          />

          <MenuItem
            model={{ value: 'Remove', label: 'Remove' }}
            onClick={(evt) => {
              model.dispatch('deleteBookmark', {
                bookmarkId: model.id,
              });
            }}
          />

          <MenuItem
            model={{ value: 'Add to list', label: 'Add to list' }}
            onClick={(evt) => {
              model.dispatch('openBookmarkWindow', {
                bookmarkId: model.id,
              });
            }}
          />
        </MenuButton>
      </div>
    </div>
  );
});
