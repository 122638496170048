import '../jobs/jobs.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Catalog } from '../layout';
import { CatalogState } from '../layout/catalogState';
import { CatalogFooter } from '../layout/catalogFooter';
import { ZoomSVGIcon } from '../svg';
import { LibraryCatalogSource } from '../../entities';
import { LibraryTableRow } from './libraryTableRow';

type Props = {
  model: CatalogState;
  source: LibraryCatalogSource;
};

export const ZoomTableCatalog = observer(({ model, source }: Props) => {
  const { entities } = source;

  return (
    <Catalog
      className="job-table-catalog zoom-table-catalog catalog"
      model={model}>
      {entities.length > 0 && (
        <>
          <table className="job-table zoom-table job-table-core">
            <tbody>
              {entities.map((job) => {
                return (
                  <LibraryTableRow
                    view="zoom"
                    key={job.id}
                    model={job}
                    source={source}
                  />
                );
              })}
            </tbody>
          </table>

          <CatalogFooter model={model} />
        </>
      )}

      {entities.length === 0 && (
        <div className="empty-box" aria-hidden={model.isLoading}>
          <div className="catalog empty-catalog">
            <div className="icon">
              <ZoomSVGIcon />
            </div>
            <h1 className="headline-1">
              Looks like you don't have any recordings for the selected date range.
            </h1>
          </div>
        </div>
      )}
    </Catalog>
  );
});
