import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { MouseEvent } from 'react';

import { Maybe } from '../../../core';
import { StoreNode } from '../../../store';
import { Store } from '../../../store/store';
import {
  assertValidLoginInput,
  AuthError,
  AuthWaitForRedirectReply,
  LoginInput,
  SocialLoginProvider
} from '../../../services/auth';
import {
  input,
  inputGroup
} from '../../../components';
import {
  submitButtonDisabled
} from '../../../components/input/inputHelpers';
import { getLoginErrorMessage, LoginPasswordSubmitErrorCodes, LoginUsernameSubmitErrorCodes } from './loginErrors';

export class LoginFormState
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  @observable error: AuthError | null = null;
  @observable reply: AuthWaitForRedirectReply | null = null;

  @computed get isWaitingForRedirect() {
    return this.reply?.type === 'WaitForRedirect';
  }
  @computed get isSubmitting() {
    return this.formGroup.isSubmitting;
  }
  @computed get showLoadMask() {
    return this.isSubmitting || this.isWaitingForRedirect;
  }

  readonly formGroup = inputGroup(this, {
    name: 'loginForm',
    inputs: () => [
      this.username,
      this.password,
      this.submitButton
    ],
    error: (form) => {
      const err = this.error;
      const errType = err?.type;
      const errMsg = getLoginErrorMessage(err);
      if (errMsg)
        return errMsg;

      if (
        LoginUsernameSubmitErrorCodes.includes(errType!) ||
        LoginPasswordSubmitErrorCodes.includes(errType!)) {
        return null; // pass-through
      }

      if (form.hasErrorInputs)
        return true;
    },

    showStatus: (form) => !!form.error,
    showStatusMessage: (form) => form.showStatus
  });

  // #region Username
  readonly username = input(this, {
    name: 'email',
    isRequired: true,
    error: (input, fallback) => {
      if (!input.value?.trim())
        return 'Required field';
      if (this.usernameSubmitError)
        return this.usernameSubmitError;
      return fallback;
    },
    showStatus: (input) =>
      input.isSubmitRejected || !!this.usernameSubmitError,
    showStatusMessage: (input) =>
      input.showStatus && !this.usernameSubmitError,
    onChange: () => this.clearError()
  });

  @computed get usernameSubmitError(): Maybe<string> {
    if (!this.username.isChangedSinceLastSubmit &&
      LoginUsernameSubmitErrorCodes.includes(this.error?.type!)) {
      return this.error?.message || null;
    }
    return null;
  }
  // #endregion

  // #region Password
  readonly password = input(this, {
    name: 'password',
    isRequired: true,
    showContentVisibilityButton: true,
    error: (input, fallback) => {
      if (!input.value)
        return 'Required field';
      if (this.passwordSubmitError)
        return this.passwordSubmitError;
      return fallback;
    },
    showStatus: (input) =>
      input.isSubmitRejected || !!this.passwordSubmitError,
    showStatusMessage: (input) =>
      input.showStatus && !this.passwordSubmitError,
    onChange: () => this.clearError()
  });

  @computed get passwordSubmitError(): Maybe<string> {
    if (!this.password.isChangedSinceLastSubmit &&
      LoginPasswordSubmitErrorCodes.includes(this.error?.type!)) {
      return this.error?.message || null;
    }
    return null;
  }
  // #endregion

  // #region Submit
  readonly submitButton = input(this, {
    name: 'submit',
    disabled: submitButtonDisabled(this.formGroup)
  });

  @action
  handleSubmit = async (evt: React.FormEvent) => {
    evt.preventDefault();

    this.error = null;
    this.formGroup.handleSubmit();
    if (this.formGroup.error) {
      this.formGroup.handleSubmitReject();
      return false;
    }

    const input: LoginInput = {
      username: this.username.value?.trim()!,
      password: this.password.value!
    }

    assertValidLoginInput(input);

    const { auth } = this.store;
    const [, err] = await auth.runLoginFlow(input);

    if (err) {

      runInAction(() =>
        this.error = err);
      this.formGroup.handleSubmitReject();
      return;
    }

    this.formGroup.handleSubmitResolve();
  }
  // #endregion

  @action
  handleSocialLoginButtonClick = (evt: MouseEvent, provider: SocialLoginProvider) => {
    this.store.auth.runSocialLoginFlow(provider);
  }

  // #region Reset
  @action
  clearError() {
    this.error = null;
  }

  @action
  reset() {
    this.formGroup.reset();
    this.error = null;
    this.reply = null;
  }
  // #endregion
}