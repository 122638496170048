import { action, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { DemoService } from '../../services/demo';
import { Nullable } from '../../core';
import { PlayerTutorialSelectors } from './playerTutorial.consts';

export class PlayerTutorialHelpButtonTooltipState
  extends StoreNode {
  readonly nodeType = 'PlayerTutorialHelpButtonTooltip';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);
  readonly demoService: DemoService = new DemoService([PlayerTutorialSelectors.HelpBtn]);

  @observable anchorElement: Nullable<HTMLElement> = null;

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open() {
    this.anchorElement = document.querySelector(`[data-tutorial-id=${PlayerTutorialSelectors.HelpBtn}]`);
    this.demoService.highlightComponents([PlayerTutorialSelectors.HelpBtn]);
    this.demoService.toggleCustomClass(PlayerTutorialSelectors.HelpBtn, 'help-button-custom', true);
    this.dispatch('Overlays', 'openWindow', { name: 'PlayerTutorialHelpButtonTooltip' });
  }

  @action
  close() {
    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
    this.demoService.highlightComponents([]);
    this.demoService.toggleCustomClass(PlayerTutorialSelectors.HelpBtn, 'help-button-custom', false);
  }
}