import * as React from "react";
import classNames from 'classnames';

export const EngagingBloomSVGIcon = ({className, ...props}: React.SVGProps<SVGSVGElement>) =>
  <svg
    viewBox="0 0 251 239"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames("engaging-bloom-svg-icon", "svg-icon", className)}
    {...props}>
      <style>
        {
          "\n    .main-bloom {\n      transform: scale(0);\n      transform-origin: center center;\n      animation: pulse 2s 1 alternate;\n    }\n\n    .bg-bloom {\n      transform: scale(0);\n      transform-origin: center center;\n      animation: pulse2 1.6s 1 alternate-reverse;\n      opacity: 0;\n    }\n\n    @keyframes pulse {\n      60% {\n        transform: scale(1);\n        opacity: 1;\n      }\n\n      100% {\n        transform: scale(0);\n        opacity: 0;\n      }\n    }\n\n    @keyframes pulse2 {\n      60% {\n        transform: scale(1);\n        opacity: 1;\n      }\n\n      80% {\n        opacity: 0;\n      }\n\n      100% {\n        transform: scale(0);\n        opacity: 0;\n      }\n    }\n  "
        }
      </style>
      <g className="main-bloom">
        <path
          d="M184.455 119.239C184.455 152.206 157.729 178.932 124.762 178.932C91.7938 178.932 65.0681 152.206 65.0681 119.239C65.0681 86.2708 91.7938 59.5452 124.762 59.5452C157.729 59.5452 184.455 86.2708 184.455 119.239Z"
          fill="#FFCB4C"
          fillOpacity={0.1}
          stroke="white"
        />
        <path
          d="M124.762 169.4C152.465 169.4 174.923 146.942 174.923 119.239C174.923 91.5353 152.465 69.0774 124.762 69.0774C97.0583 69.0774 74.6003 91.5353 74.6003 119.239C74.6003 146.942 97.0583 169.4 124.762 169.4Z"
          fill="#FFCB4C"
          fillOpacity={0.15}
        />
        <path
          d="M124.761 161.374C148.032 161.374 166.897 142.509 166.897 119.239C166.897 95.9679 148.032 77.1033 124.761 77.1033C101.491 77.1033 82.626 95.9679 82.626 119.239C82.626 142.509 101.491 161.374 124.761 161.374Z"
          fill="#FFCB4C"
          fillOpacity={0.2}
        />
        <path
          d="M124.761 152.345C143.046 152.345 157.868 137.523 157.868 119.238C157.868 100.954 143.046 86.1321 124.761 86.1321C106.477 86.1321 91.655 100.954 91.655 119.238C91.655 137.523 106.477 152.345 124.761 152.345Z"
          fill="#FFCB4C"
          fillOpacity={0.25}
        />
        <rect
          x={125}
          y={45}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          fill="#F19020"
        />
        <rect
          x={125}
          y={77.1738}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          fill="#F19020"
        />
        <rect
          x={125}
          y={70.739}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          fill="#F19020"
        />
        <rect
          x={125}
          y={64.3044}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          fill="#F19020"
        />
        <rect
          x={128.217}
          y={193}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(180 128.217 193)"
          fill="#F19020"
        />
        <rect
          x={128.217}
          y={160.826}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(180 128.217 160.826)"
          fill="#F19020"
        />
        <rect
          x={128.217}
          y={167.261}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(180 128.217 167.261)"
          fill="#F19020"
        />
        <rect
          x={128.217}
          y={173.696}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(180 128.217 173.696)"
          fill="#F19020"
        />
        <rect
          x={199}
          y={119}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(90 199 119)"
          fill="#F19020"
        />
        <rect
          x={166.826}
          y={119}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(90 166.826 119)"
          fill="#F19020"
        />
        <rect
          x={173.261}
          y={119}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(90 173.261 119)"
          fill="#F19020"
        />
        <rect
          x={179.696}
          y={119}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(90 179.696 119)"
          fill="#F19020"
        />
        <rect
          x={51}
          y={122.217}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(-90 51 122.217)"
          fill="#F19020"
        />
        <rect
          x={83.1738}
          y={122.217}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-90 83.1738 122.217)"
          fill="#F19020"
        />
        <rect
          x={76.739}
          y={122.217}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-90 76.739 122.217)"
          fill="#F19020"
        />
        <rect
          x={70.304}
          y={122.217}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-90 70.304 122.217)"
          fill="#F19020"
        />
        <rect
          x={177.327}
          y={66.6733}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(45 177.327 66.6733)"
          fill="#F19020"
        />
        <rect
          x={154.577}
          y={89.4238}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(45 154.577 89.4238)"
          fill="#F19020"
        />
        <rect
          x={74.9482}
          y={173.601}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(-135 74.9482 173.601)"
          fill="#F19020"
        />
        <rect
          x={97.6982}
          y={150.851}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-135 97.6982 150.851)"
          fill="#F19020"
        />
        <rect
          x={177.327}
          y={171.326}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(135 177.327 171.326)"
          fill="#F19020"
        />
        <rect
          x={154.577}
          y={148.576}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(135 154.577 148.576)"
          fill="#F19020"
        />
        <rect
          x={70.3989}
          y={68.9497}
          width={3.21739}
          height={12.8696}
          rx={1.1436}
          transform="rotate(-45 70.3989 68.9497)"
          fill="#F19020"
        />
        <rect
          x={93.1492}
          y={91.7}
          width={3.21739}
          height={3.21739}
          rx={1.1436}
          transform="rotate(-45 93.1492 91.7)"
          fill="#F19020"
        />
        <rect
          x={145.175}
          y={68.7202}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(21.8729 145.175 68.7202)"
          fill="white"
        />
        <rect
          x={149.218}
          y={58.6497}
          width={2.36311}
          height={6.43478}
          rx={1.1436}
          transform="rotate(21.8729 149.218 58.6497)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={106.87}
          y={170.478}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-158.127 106.87 170.478)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.3784}
          rx={1.1436}
          transform="matrix(-0.928012 -0.372549 -0.372549 0.928012 105.941 172.798)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={175.366}
          y={139.409}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(111.873 175.366 139.409)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.5957}
          rx={1.1436}
          transform="matrix(-0.37255 0.928012 0.928012 0.37255 178.494 140.665)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={73.6069}
          y={101.103}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-68.1271 73.6069 101.103)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.42869}
          rx={1.1436}
          transform="matrix(0.372549 -0.928012 -0.928012 -0.372549 69.9934 99.6523)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={174.911}
          y={97.812}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(66.8729 174.911 97.812)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.58471}
          rx={1.1436}
          transform="matrix(0.392772 0.919636 0.919636 -0.392772 178.499 96.2795)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={75.8716}
          y={142.681}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-113.127 75.8716 142.681)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={8.48348}
          rx={1.1436}
          transform="matrix(-0.392772 -0.919636 -0.919636 0.392772 72.4053 144.162)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={146.274}
          y={169.143}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(156.873 146.274 169.143)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.43478}
          rx={1.1436}
          transform="matrix(-0.919636 0.392771 0.392771 0.919636 147.32 171.591)"
          fill="white"
          fillOpacity={0.7}
        />
        <rect
          x={101.406}
          y={70.1042}
          width={2.36311}
          height={18.9049}
          rx={1.1436}
          transform="rotate(-23.1271 101.406 70.1042)"
          fill="white"
        />
        <rect
          width={2.36311}
          height={6.43478}
          rx={1.1436}
          transform="matrix(0.919636 -0.392772 -0.392772 -0.919636 99.6348 65.9541)"
          fill="white"
          fillOpacity={0.7}
        />
      </g>
      <g className="bg-bloom">
        <g filter="url(#filter0_d_11596:86088)">
          <path
            d="M125.74 144.18C140.654 144.18 152.743 132.091 152.743 117.177C152.743 102.263 140.654 90.1736 125.74 90.1736C110.826 90.1736 98.7366 102.263 98.7366 117.177C98.7366 132.091 110.826 144.18 125.74 144.18Z"
            fill="#FFCB4C"
          />
        </g>
        <path
          d="M120.219 123.559C119.988 123.375 119.809 123.124 119.723 122.822C119.51 122.092 119.93 121.364 120.66 121.201C127.853 119.579 132.696 123.366 132.901 123.528C133.511 124.014 133.63 124.87 133.176 125.442C132.721 126.01 131.86 126.079 131.252 125.596C131.077 125.462 127.244 122.541 121.432 123.852C120.997 123.949 120.552 123.826 120.219 123.559V123.559Z"
          fill="#65471B"
        />
        <path
          d="M117.987 112.113C119.851 112.113 121.362 110.224 121.362 107.894C121.362 105.564 119.851 103.675 117.987 103.675C116.123 103.675 114.612 105.564 114.612 107.894C114.612 110.224 116.123 112.113 117.987 112.113Z"
          fill="#65471B"
        />
        <path
          d="M135.866 113.8C137.731 113.8 139.242 111.911 139.242 109.581C139.242 107.251 137.731 105.362 135.866 105.362C134.002 105.362 132.491 107.251 132.491 109.581C132.491 111.911 134.002 113.8 135.866 113.8Z"
          fill="#65471B"
        />
        <path
          d="M124.59 145.977C124.59 145.977 126.6 145.324 126.86 143.829C127.135 142.285 125.869 141.976 125.869 141.976C125.869 141.976 127.522 141.645 127.73 139.79C127.926 138.041 126.363 137.625 126.363 137.625C126.363 137.625 127.904 136.99 127.977 135.18C128.037 133.657 126.396 132.912 126.396 132.912C126.396 132.912 134.399 130.973 135.222 130.782C136.041 130.591 137.318 129.805 136.92 128.091C136.524 126.376 135.007 126.315 134.224 126.498C133.439 126.681 123.512 128.985 120.087 129.783L117.801 130.313C116.943 130.515 116.554 130.139 117.159 129.5C117.966 128.649 118.483 127.707 118.662 126.144C118.851 124.5 118.294 122.47 117.974 121.682C117.38 120.219 116.378 119.062 115.22 118.665C113.414 118.046 112.13 119.175 112.771 121.145C113.73 124.09 113.101 126.506 111.447 127.964C107.557 131.39 105.748 133.833 106.952 139.04C108.267 144.719 113.905 148.374 119.583 147.059L124.59 145.977Z"
          fill="#F19020"
        />
        <path
          d="M111.914 100.233C111.654 100.093 111.433 99.8773 111.293 99.5993C110.95 98.9195 111.23 98.1268 111.917 97.833C118.694 94.9246 124.147 97.7647 124.375 97.887C125.063 98.2539 125.341 99.0736 124.997 99.72C124.653 100.363 123.821 100.587 123.133 100.225C122.938 100.125 118.635 97.9505 113.161 100.3C112.753 100.473 112.292 100.435 111.914 100.233V100.233ZM131.19 106.19C130.942 106.034 130.736 105.804 130.615 105.516C130.318 104.814 130.651 104.044 131.358 103.794C138.317 101.355 143.565 104.557 143.785 104.695C144.444 105.108 144.666 105.945 144.28 106.566C143.896 107.186 143.048 107.352 142.388 106.944C142.198 106.83 138.057 104.369 132.434 106.342C132.011 106.487 131.555 106.415 131.19 106.19V106.19Z"
          fill="#65471B"
        />
        <g clipPath="url(#clip0_11596:86088)">
          <path
            d="M148.668 20.1807C153.589 20.1807 157.579 16.4331 157.579 11.8102C157.579 7.18731 153.589 3.4397 148.668 3.4397C143.747 3.4397 139.758 7.18731 139.758 11.8102C139.758 16.4331 143.747 20.1807 148.668 20.1807Z"
            fill="#FFCB4C"
          />
          <path
            d="M146.846 13.7887C146.77 13.7316 146.711 13.6538 146.683 13.5602C146.612 13.3337 146.751 13.1082 146.992 13.0575C149.365 12.5548 150.963 13.7286 151.031 13.7789C151.232 13.9295 151.272 14.1949 151.122 14.3722C150.972 14.5485 150.688 14.5696 150.487 14.4199C150.429 14.3786 149.164 13.4731 147.247 13.8793C147.103 13.9093 146.956 13.8714 146.846 13.7887V13.7887Z"
            fill="#65471B"
          />
          <path
            d="M146.11 10.2405C146.725 10.2405 147.223 9.65503 147.223 8.93277C147.223 8.21051 146.725 7.625 146.11 7.625C145.495 7.625 144.996 8.21051 144.996 8.93277C144.996 9.65503 145.495 10.2405 146.11 10.2405Z"
            fill="#65471B"
          />
          <path
            d="M152.01 10.7635C152.625 10.7635 153.123 10.178 153.123 9.45572C153.123 8.73346 152.625 8.14795 152.01 8.14795C151.394 8.14795 150.896 8.73346 150.896 9.45572C150.896 10.178 151.394 10.7635 152.01 10.7635Z"
            fill="#65471B"
          />
          <path
            d="M148.289 20.7377C148.289 20.7377 148.952 20.5354 149.038 20.072C149.128 19.5934 148.711 19.4974 148.711 19.4974C148.711 19.4974 149.256 19.395 149.325 18.8199C149.389 18.2778 148.874 18.1488 148.874 18.1488C148.874 18.1488 149.382 17.9518 149.406 17.391C149.426 16.9188 148.885 16.6879 148.885 16.6879C148.885 16.6879 151.525 16.0867 151.797 16.0276C152.067 15.9685 152.489 15.7248 152.357 15.1935C152.227 14.6617 151.726 14.643 151.468 14.6996C151.209 14.7563 147.933 15.4707 146.803 15.7179L146.049 15.8823C145.765 15.9449 145.637 15.8282 145.837 15.6302C146.103 15.3663 146.273 15.0743 146.333 14.5898C146.395 14.0802 146.211 13.451 146.106 13.2067C145.91 12.7532 145.579 12.3948 145.197 12.2717C144.601 12.0797 144.177 12.4297 144.389 13.0403C144.705 13.9532 144.498 14.7021 143.952 15.1541C142.668 16.2162 142.071 16.9735 142.469 18.5875C142.903 20.3478 144.763 21.4807 146.637 21.073L148.289 20.7377Z"
            fill="#F19020"
          />
          <path
            d="M144.106 6.55821C144.02 6.51488 143.947 6.44792 143.901 6.36175C143.788 6.15101 143.88 5.90531 144.107 5.81422C146.343 4.91267 148.142 5.79305 148.218 5.83096C148.445 5.9447 148.537 6.19877 148.423 6.39917C148.31 6.59859 148.035 6.66801 147.808 6.55575C147.743 6.52473 146.324 5.85066 144.517 6.57889C144.383 6.63256 144.231 6.62075 144.106 6.55821V6.55821ZM150.466 8.40465C150.385 8.3564 150.317 8.285 150.277 8.19588C150.179 7.97825 150.289 7.73944 150.522 7.66214C152.818 6.90584 154.55 7.89848 154.622 7.94132C154.84 8.06934 154.913 8.32882 154.786 8.52135C154.659 8.71338 154.379 8.76508 154.162 8.63853C154.099 8.60308 152.732 7.84038 150.877 8.45192C150.737 8.49673 150.587 8.47457 150.466 8.40465V8.40465Z"
            fill="#65471B"
          />
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip1_11596:86088)">
            <path
              d="M127.178 210.84C123.266 217.616 125.587 226.28 132.363 230.192C139.139 234.104 147.803 231.782 151.715 225.006C155.627 218.231 153.305 209.566 146.53 205.654C139.754 201.742 131.09 204.064 127.178 210.84Z"
              fill="#FFCB4C"
            />
            <path
              d="M137.995 213.74C138.139 213.684 138.3 213.668 138.46 213.709C138.848 213.804 139.068 214.185 138.951 214.56C137.801 218.253 134.81 219.46 134.683 219.511C134.302 219.66 133.882 219.49 133.741 219.134C133.602 218.778 133.797 218.369 134.176 218.219C134.282 218.175 136.615 217.2 137.544 214.215C137.614 213.992 137.786 213.822 137.995 213.74V213.74Z"
              fill="#65471B"
            />
            <path
              d="M143.781 215.729C143.292 216.576 143.753 217.758 144.812 218.369C145.871 218.98 147.125 218.789 147.614 217.942C148.103 217.095 147.642 215.913 146.583 215.302C145.524 214.691 144.27 214.882 143.781 215.729Z"
              fill="#65471B"
            />
            <path
              d="M138.324 223.41C137.835 224.257 138.297 225.439 139.356 226.05C140.414 226.661 141.669 226.47 142.158 225.623C142.647 224.776 142.185 223.594 141.127 222.983C140.068 222.372 138.813 222.563 138.324 223.41Z"
              fill="#65471B"
            />
            <path
              d="M126.663 209.846C126.663 209.846 126.433 210.93 127.044 211.441C127.673 211.971 128.146 211.477 128.146 211.477C128.146 211.477 127.862 212.315 128.65 212.896C129.394 213.443 129.993 212.842 129.993 212.842C129.993 212.842 129.877 213.709 130.68 214.217C131.356 214.644 132.125 214.094 132.125 214.094C132.125 214.094 130.907 218.238 130.778 218.662C130.65 219.085 130.672 219.871 131.555 220.14C132.438 220.41 132.864 219.736 132.986 219.333C133.109 218.928 134.666 213.813 135.202 212.048L135.561 210.87C135.694 210.428 135.967 210.35 136.098 210.792C136.274 211.382 136.566 211.864 137.229 212.355C137.927 212.872 138.995 213.152 139.437 213.213C140.257 213.327 141.046 213.175 141.53 212.753C142.285 212.095 142.109 211.216 141.046 210.99C139.456 210.653 138.523 209.734 138.295 208.6C137.758 205.934 137.123 204.471 134.442 203.652C131.516 202.76 128.377 204.363 127.485 207.288L126.663 209.846Z"
              fill="#F19020"
            />
            <path
              d="M150.771 216.086C150.903 216.004 151.059 215.961 151.222 215.97C151.621 215.993 151.908 216.328 151.861 216.717C151.405 220.559 148.684 222.291 148.569 222.363C148.221 222.579 147.776 222.491 147.573 222.165C147.37 221.84 147.487 221.403 147.832 221.186C147.929 221.123 150.045 219.738 150.414 216.635C150.442 216.404 150.581 216.205 150.771 216.086V216.086ZM143.009 223.281C143.144 223.21 143.303 223.176 143.466 223.197C143.862 223.246 144.125 223.6 144.053 223.986C143.336 227.788 140.504 229.332 140.384 229.396C140.024 229.587 139.585 229.468 139.404 229.13C139.223 228.793 139.37 228.364 139.728 228.171C139.83 228.115 142.035 226.879 142.613 223.806C142.658 223.577 142.81 223.388 143.009 223.281V223.281Z"
              fill="#65471B"
            />
          </g>
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip2_11596:86088)">
            <path
              d="M60.0836 143.96C63.474 138.087 61.462 130.578 55.5896 127.188C49.7172 123.797 42.2083 125.809 38.8178 131.682C35.4274 137.554 37.4395 145.063 43.3118 148.454C49.1842 151.844 56.6932 149.832 60.0836 143.96Z"
              fill="#FFCB4C"
            />
            <path
              d="M50.7086 141.446C50.5837 141.495 50.444 141.508 50.3057 141.473C49.9696 141.391 49.7785 141.061 49.8802 140.736C50.8767 137.535 53.4688 136.489 53.5792 136.445C53.9092 136.315 54.2735 136.463 54.3953 136.772C54.516 137.08 54.3471 137.435 54.0187 137.564C53.9264 137.603 51.9049 138.448 51.0996 141.034C51.0388 141.228 50.8895 141.375 50.7086 141.446V141.446Z"
              fill="#65471B"
            />
            <path
              d="M45.6943 139.722C46.1181 138.988 45.7179 137.964 44.8004 137.434C43.883 136.904 42.7957 137.07 42.3719 137.804C41.9481 138.538 42.3482 139.562 43.2657 140.092C44.1832 140.622 45.2705 140.456 45.6943 139.722Z"
              fill="#65471B"
            />
            <path
              d="M50.4231 133.066C50.8469 132.332 50.4467 131.307 49.5292 130.777C48.6117 130.248 47.5244 130.413 47.1006 131.147C46.6768 131.882 47.077 132.906 47.9945 133.436C48.912 133.965 49.9993 133.8 50.4231 133.066Z"
              fill="#65471B"
            />
            <path
              d="M60.5295 144.821C60.5295 144.821 60.7292 143.881 60.1999 143.439C59.6544 142.98 59.2446 143.408 59.2446 143.408C59.2446 143.408 59.4905 142.682 58.8072 142.178C58.163 141.703 57.6438 142.224 57.6438 142.224C57.6438 142.224 57.7439 141.473 57.0481 141.033C56.462 140.663 55.7956 141.14 55.7956 141.14C55.7956 141.14 56.8512 137.548 56.9632 137.18C57.0745 136.814 57.0552 136.133 56.2897 135.9C55.5243 135.666 55.1557 136.249 55.0496 136.599C54.9431 136.95 53.5937 141.383 53.1291 142.913L52.8184 143.933C52.7028 144.317 52.4661 144.384 52.3523 144.001C52.2005 143.49 51.9469 143.072 51.3723 142.646C50.7679 142.198 49.8418 141.956 49.459 141.903C48.7479 141.804 48.0647 141.936 47.6451 142.302C46.9906 142.872 47.1435 143.634 48.0646 143.83C49.4423 144.121 50.2506 144.918 50.4489 145.901C50.9137 148.212 51.4643 149.479 53.7883 150.189C56.3233 150.962 59.0441 149.573 59.8172 147.038L60.5295 144.821Z"
              fill="#F19020"
            />
            <path
              d="M39.6358 139.413C39.5215 139.484 39.3862 139.521 39.245 139.513C38.8993 139.494 38.6508 139.204 38.6914 138.866C39.0867 135.536 41.4447 134.035 41.5449 133.973C41.8457 133.785 42.2316 133.862 42.4078 134.145C42.5832 134.426 42.4821 134.805 42.1832 134.993C42.0993 135.048 40.2648 136.248 39.9455 138.937C39.9209 139.137 39.8011 139.31 39.6358 139.413V139.413ZM46.3633 133.177C46.2457 133.239 46.1081 133.268 45.9674 133.25C45.6234 133.208 45.3959 132.901 45.4584 132.566C46.0797 129.271 48.5338 127.933 48.638 127.878C48.9505 127.712 49.3307 127.815 49.4875 128.108C49.644 128.4 49.5169 128.772 49.2063 128.939C49.1179 128.988 47.2076 130.059 46.7061 132.722C46.667 132.921 46.5352 133.084 46.3633 133.177V133.177Z"
              fill="#65471B"
            />
          </g>
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip3_11596:86088)">
            <path
              d="M178.642 121.749C175.252 115.876 167.743 113.864 161.871 117.255C155.998 120.645 153.986 128.154 157.377 134.027C160.767 139.899 168.276 141.911 174.148 138.521C180.021 135.13 182.033 127.621 178.642 121.749Z"
              fill="#FFCB4C"
            />
            <path
              d="M171.778 128.611C171.758 128.744 171.7 128.871 171.6 128.974C171.361 129.224 170.979 129.224 170.749 128.973C168.475 126.51 168.865 123.742 168.882 123.625C168.935 123.274 169.245 123.032 169.573 123.081C169.901 123.131 170.123 123.454 170.071 123.804C170.059 123.903 169.78 126.076 171.617 128.067C171.754 128.216 171.807 128.419 171.778 128.611V128.611Z"
              fill="#65471B"
            />
            <path
              d="M167.778 132.092C167.354 131.358 166.267 131.192 165.35 131.722C164.432 132.251 164.032 133.276 164.456 134.01C164.88 134.744 165.967 134.91 166.884 134.38C167.802 133.85 168.202 132.826 167.778 132.092Z"
              fill="#65471B"
            />
            <path
              d="M164.378 124.668C163.954 123.934 162.867 123.768 161.949 124.298C161.032 124.828 160.632 125.852 161.055 126.586C161.479 127.32 162.567 127.486 163.484 126.956C164.401 126.427 164.802 125.402 164.378 124.668Z"
              fill="#65471B"
            />
            <path
              d="M179.611 121.793C179.611 121.793 178.897 121.151 178.25 121.388C177.579 121.631 177.745 122.2 177.745 122.2C177.745 122.2 177.239 121.624 176.461 121.963C175.728 122.284 175.92 122.994 175.92 122.994C175.92 122.994 175.319 122.532 174.59 122.914C173.977 123.237 174.056 124.052 174.056 124.052C174.056 124.052 171.473 121.342 171.211 121.062C170.95 120.782 170.35 120.458 169.766 121.005C169.18 121.55 169.501 122.162 169.751 122.428C170.001 122.696 173.166 126.081 174.259 127.248L174.987 128.028C175.261 128.319 175.202 128.558 174.813 128.465C174.294 128.341 173.806 128.352 173.149 128.637C172.459 128.936 171.787 129.617 171.549 129.922C171.108 130.488 170.88 131.146 170.987 131.692C171.154 132.544 171.89 132.793 172.521 132.093C173.462 131.046 174.556 130.744 175.506 131.064C177.74 131.816 179.113 131.973 180.89 130.316C182.827 128.507 182.984 125.456 181.176 123.519L179.611 121.793Z"
              fill="#F19020"
            />
            <path
              d="M164.481 137.184C164.485 137.318 164.45 137.454 164.372 137.572C164.183 137.862 163.807 137.932 163.535 137.728C160.849 135.721 160.728 132.928 160.724 132.81C160.712 132.456 160.972 132.16 161.305 132.149C161.636 132.138 161.913 132.415 161.927 132.768C161.932 132.868 162.054 135.056 164.224 136.678C164.385 136.799 164.474 136.989 164.481 137.184V137.184ZM162.444 128.24C162.439 128.373 162.395 128.506 162.31 128.619C162.101 128.896 161.722 128.94 161.463 128.718C158.92 126.532 158.988 123.738 158.992 123.62C159.005 123.267 159.284 122.989 159.617 123C159.948 123.01 160.206 123.306 160.195 123.659C160.194 123.76 160.166 125.95 162.221 127.715C162.374 127.849 162.45 128.045 162.444 128.24V128.24Z"
              fill="#65471B"
            />
          </g>
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip4_11596:86088)">
            <path
              d="M50.5545 25.9139C54.4326 32.1015 62.5924 33.9737 68.78 30.0955C74.9676 26.2174 76.8397 18.0576 72.9616 11.8701C69.0835 5.68251 60.9237 3.81033 54.7361 7.68843C48.5486 11.5665 46.6764 19.7264 50.5545 25.9139Z"
              fill="#FFCB4C"
            />
            <path
              d="M57.6742 18.2607C57.6907 18.1173 57.7482 17.9775 57.8511 17.8635C58.0989 17.5851 58.5098 17.5699 58.7677 17.8306C61.3109 20.3929 60.9991 23.387 60.9852 23.5142C60.9421 23.8936 60.6179 24.1657 60.2625 24.1259C59.9085 24.0853 59.6562 23.7459 59.6984 23.3679C59.7083 23.2607 59.9236 20.9109 57.8685 18.8402C57.7151 18.6849 57.6502 18.4685 57.6742 18.2607V18.2607Z"
              fill="#65471B"
            />
            <path
              d="M61.843 14.3585C62.3278 15.1319 63.5044 15.2678 64.4712 14.6619C65.4379 14.056 65.8286 12.9378 65.3438 12.1644C64.859 11.3909 63.6824 11.2551 62.7157 11.861C61.749 12.4669 61.3583 13.5851 61.843 14.3585Z"
              fill="#65471B"
            />
            <path
              d="M65.7925 22.2152C66.2772 22.9886 67.4539 23.1245 68.4206 22.5186C69.3873 21.9127 69.778 20.7945 69.2933 20.0211C68.8085 19.2476 67.6319 19.1118 66.6651 19.7177C65.6984 20.3236 65.3077 21.4418 65.7925 22.2152Z"
              fill="#65471B"
            />
            <path
              d="M49.5102 25.904C49.5102 25.904 50.3036 26.5679 50.9915 26.2873C51.7035 25.9998 51.5028 25.394 51.5028 25.394C51.5028 25.394 52.0699 25.9942 52.8937 25.598C53.6702 25.2243 53.4364 24.4676 53.4364 24.4676C53.4364 24.4676 54.1006 24.9417 54.8703 24.5015C55.518 24.1304 55.4004 23.2559 55.4004 23.2559C55.4004 23.2559 58.286 26.0718 58.5791 26.3636C58.8713 26.6541 59.5296 26.9795 60.137 26.3684C60.746 25.7582 60.3765 25.1131 60.0971 24.8357C59.8173 24.5577 56.2794 21.0381 55.058 19.8245L54.2435 19.0141C53.9368 18.7107 53.9918 18.4514 54.4141 18.5364C54.9772 18.6498 55.5022 18.619 56.1974 18.2871C56.9286 17.938 57.6259 17.1788 57.8697 16.8414C58.3222 16.2145 58.5414 15.498 58.405 14.9143C58.1924 14.0039 57.3901 13.7651 56.7393 14.5428C55.767 15.7067 54.601 16.074 53.566 15.7671C51.1329 15.0441 49.6489 14.9287 47.8016 16.7823C45.7876 18.8046 45.7371 22.094 47.7595 24.108L49.5102 25.904Z"
              fill="#F19020"
            />
            <path
              d="M65.1928 8.74975C65.183 8.60532 65.2153 8.45754 65.2942 8.32726C65.4871 8.00812 65.8886 7.918 66.1894 8.12695C69.1582 10.1821 69.3979 13.1831 69.4066 13.3098C69.4332 13.6906 69.1654 14.019 68.8076 14.0441C68.4515 14.0691 68.1421 13.782 68.1135 13.4024C68.1042 13.2954 67.8875 10.9446 65.4894 9.28444C65.3114 9.16009 65.2074 8.95906 65.1928 8.74975V8.74975ZM67.7338 18.2961C67.734 18.1528 67.7758 18.0072 67.8637 17.8824C68.0778 17.5766 68.4842 17.5146 68.7714 17.743C71.5933 19.9958 71.6294 23.006 71.6291 23.1329C71.6292 23.5137 71.3397 23.8237 70.9816 23.8251C70.6246 23.8267 70.3349 23.5181 70.3328 23.1385C70.3307 23.0296 70.2747 20.6717 67.994 18.8517C67.8241 18.714 67.7353 18.5063 67.7338 18.2961V18.2961Z"
              fill="#65471B"
            />
          </g>
        </g>
        <g opacity={0.5}>
          <g clipPath="url(#clip5_11596:86088)">
            <path
              d="M152.95 58.3909C149.458 52.2763 141.67 50.1507 135.555 53.6431C129.441 57.1355 127.315 64.9235 130.807 71.038C134.3 77.1526 142.088 79.2782 148.202 75.7858C154.317 72.2934 156.442 64.5054 152.95 58.3909Z"
              fill="#FFCB4C"
            />
            <path
              d="M145.788 65.4836C145.767 65.6211 145.706 65.7535 145.602 65.8593C145.353 66.1177 144.956 66.1162 144.718 65.8549C142.369 63.2858 142.787 60.4133 142.806 60.2914C142.862 59.9275 143.185 59.6781 143.526 59.7305C143.866 59.7836 144.095 60.1206 144.04 60.4831C144.026 60.5859 143.726 62.8415 145.624 64.9176C145.766 65.0733 145.82 65.2843 145.788 65.4836V65.4836Z"
              fill="#65471B"
            />
            <path
              d="M141.618 69.0791C141.181 68.3148 140.053 68.1375 139.098 68.6832C138.143 69.2288 137.722 70.2907 138.159 71.055C138.595 71.8194 139.723 71.9966 140.679 71.451C141.634 70.9054 142.055 69.8434 141.618 69.0791Z"
              fill="#65471B"
            />
            <path
              d="M138.123 61.3535C137.686 60.5892 136.558 60.4119 135.603 60.9576C134.647 61.5032 134.227 62.5651 134.663 63.3295C135.1 64.0938 136.228 64.2711 137.184 63.7254C138.139 63.1798 138.559 62.1178 138.123 61.3535Z"
              fill="#65471B"
            />
            <path
              d="M153.956 58.4418C153.956 58.4418 153.218 57.7708 152.544 58.014C151.847 58.2629 152.016 58.8545 152.016 58.8545C152.016 58.8545 151.493 58.2539 150.684 58.6031C149.921 58.9325 150.117 59.6708 150.117 59.6708C150.117 59.6708 149.495 59.1879 148.736 59.5816C148.098 59.9136 148.176 60.7608 148.176 60.7608C148.176 60.7608 145.507 57.9339 145.236 57.6412C144.966 57.3498 144.345 57.0103 143.736 57.5752C143.125 58.1391 143.455 58.7751 143.714 59.0534C143.972 59.3324 147.242 62.863 148.371 64.0804L149.124 64.8933C149.407 65.1977 149.344 65.4454 148.941 65.3468C148.403 65.2154 147.895 65.2243 147.213 65.5167C146.494 65.8242 145.793 66.5282 145.544 66.8436C145.084 67.4298 144.844 68.1115 144.953 68.6793C145.121 69.5648 145.885 69.8266 146.543 69.1029C147.525 68.0199 148.663 67.712 149.648 68.0485C151.964 68.841 153.389 69.0107 155.242 67.2977C157.263 65.4287 157.441 62.2615 155.572 60.2412L153.956 58.4418Z"
              fill="#F19020"
            />
            <path
              d="M138.17 74.3508C138.173 74.4904 138.136 74.6315 138.055 74.7539C137.857 75.0538 137.466 75.1248 137.185 74.9116C134.405 72.8144 134.293 69.9136 134.289 69.7911C134.279 69.4232 134.55 69.1173 134.896 69.1072C135.24 69.0973 135.526 69.3861 135.539 69.7529C135.544 69.8564 135.66 72.1299 137.905 73.824C138.071 73.9508 138.164 74.1486 138.17 74.3508V74.3508ZM136.098 65.0529C136.092 65.191 136.046 65.3296 135.956 65.4464C135.738 65.7326 135.344 65.7762 135.076 65.5448C132.446 63.263 132.53 60.3614 132.536 60.2391C132.551 59.8722 132.842 59.585 133.187 59.5978C133.531 59.6103 133.798 59.919 133.785 60.2849C133.782 60.3898 133.743 62.6638 135.869 64.5073C136.027 64.6467 136.105 64.8503 136.098 65.0529V65.0529Z"
              fill="#65471B"
            />
          </g>
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip6_11596:86088)">
            <path
              d="M24.7722 66.1353C28.2761 62.6315 28.2761 56.9506 24.7722 53.4467C21.2683 49.9428 15.5874 49.9428 12.0836 53.4467C8.57971 56.9506 8.57971 62.6315 12.0836 66.1353C15.5874 69.6392 21.2683 69.6392 24.7722 66.1353Z"
              fill="#FFCB4C"
            />
            <path
              d="M18.6301 62.5878C18.5327 62.5986 18.4316 62.5818 18.3405 62.531C18.1188 62.4094 18.0464 62.1399 18.1797 61.9298C19.4885 59.859 21.516 59.6108 21.6022 59.6007C21.8598 59.5716 22.0889 59.7448 22.1165 59.9859C22.1434 60.2262 21.9572 60.4445 21.7008 60.474C21.6284 60.4837 20.0415 60.6979 18.9839 62.3713C18.9044 62.4963 18.7712 62.5721 18.6301 62.5878V62.5878Z"
              fill="#65471B"
            />
            <path
              d="M15.4166 60.4228C15.8545 59.9848 15.7658 59.186 15.2184 58.6385C14.671 58.0911 13.8721 58.0024 13.4342 58.4404C12.9962 58.8783 13.0849 59.6772 13.6323 60.2246C14.1797 60.772 14.9786 60.8608 15.4166 60.4228Z"
              fill="#65471B"
            />
            <path
              d="M20.0137 56.6186C20.4517 56.1806 20.363 55.3818 19.8156 54.8343C19.2681 54.2869 18.4693 54.1982 18.0313 54.6362C17.5933 55.0741 17.6821 55.873 18.2295 56.4204C18.7769 56.9678 19.5758 57.0566 20.0137 56.6186Z"
              fill="#65471B"
            />
            <path
              d="M24.924 66.8277C24.924 66.8277 25.2428 66.2022 24.9528 65.7898C24.6546 65.3625 24.2844 65.5872 24.2844 65.5872C24.2844 65.5872 24.5953 65.121 24.2083 64.6363C23.8433 64.1795 23.3783 64.4489 23.3783 64.4489C23.3783 64.4489 23.591 63.9376 23.1831 63.4954C22.8394 63.1233 22.2788 63.3338 22.2788 63.3338C22.2788 63.3338 23.7033 60.998 23.8519 60.7599C23.9996 60.5225 24.115 60.0377 23.6186 59.7287C23.1226 59.4186 22.7521 59.7608 22.611 59.9877C22.4695 60.215 20.6786 63.089 20.0613 64.0809L19.6489 64.7426C19.4948 64.9915 19.3149 64.9945 19.3071 64.7023C19.2966 64.3127 19.1966 63.9701 18.8716 63.5607C18.5297 63.13 17.9218 62.7841 17.6617 62.674C17.1784 62.4699 16.6712 62.4337 16.3058 62.6124C15.736 62.8912 15.6998 63.4581 16.3129 63.7704C17.2303 64.2369 17.6501 64.9524 17.6042 65.6834C17.4952 67.4024 17.6441 68.4014 19.1503 69.3419C20.7935 70.367 22.9767 69.9013 24.0019 68.2581L24.924 66.8277Z"
              fill="#F19020"
            />
            <path
              d="M11.1988 59.0585C11.1047 59.0869 11.0021 59.088 10.904 59.0555C10.6636 58.9764 10.5431 58.7245 10.6356 58.4939C11.5444 56.2185 13.4928 55.6046 13.5753 55.5796C13.8231 55.5042 14.081 55.6315 14.1519 55.8643C14.2224 56.0961 14.0795 56.3443 13.8328 56.4208C13.7634 56.4432 12.2415 56.9432 11.5074 58.7812C11.4522 58.9178 11.335 59.0171 11.1988 59.0585V59.0585ZM17.127 55.9293C17.0322 55.9509 16.9296 55.9453 16.8337 55.9061C16.5989 55.811 16.4963 55.5516 16.6038 55.3269C17.6655 53.1187 19.6509 52.6381 19.7349 52.619C19.9868 52.5612 20.2357 52.7056 20.2909 52.9422C20.3462 53.1781 20.1861 53.4165 19.9353 53.4755C19.8636 53.4934 18.3126 53.8883 17.455 55.6729C17.3897 55.8061 17.2658 55.8964 17.127 55.9293V55.9293Z"
              fill="#65471B"
            />
          </g>
        </g>
        <g clipPath="url(#clip7_11596:86088)">
          <path
            d="M68.8814 211.503C65.5619 214.822 65.5619 220.204 68.8814 223.523C72.2008 226.843 77.5827 226.843 80.9022 223.523C84.2216 220.204 84.2216 214.822 80.9022 211.503C77.5827 208.183 72.2008 208.183 68.8814 211.503Z"
            fill="#FFCB4C"
          />
          <path
            d="M74.7002 214.863C74.7924 214.853 74.8882 214.869 74.9745 214.917C75.1845 215.032 75.2531 215.288 75.1269 215.487C73.887 217.449 71.9661 217.684 71.8845 217.693C71.6405 217.721 71.4234 217.557 71.3973 217.328C71.3718 217.101 71.5482 216.894 71.7911 216.866C71.8597 216.857 73.363 216.654 74.365 215.068C74.4403 214.95 74.5665 214.878 74.7002 214.863V214.863Z"
            fill="#65471B"
          />
          <path
            d="M77.7446 216.914C77.3297 217.329 77.4138 218.086 77.9324 218.605C78.451 219.123 79.2078 219.207 79.6227 218.793C80.0377 218.378 79.9536 217.621 79.435 217.102C78.9164 216.584 78.1596 216.5 77.7446 216.914Z"
            fill="#65471B"
          />
          <path
            d="M73.3894 220.518C72.9745 220.933 73.0585 221.69 73.5772 222.209C74.0958 222.727 74.8526 222.811 75.2675 222.397C75.6824 221.982 75.5984 221.225 75.0798 220.706C74.5611 220.188 73.8044 220.104 73.3894 220.518Z"
            fill="#65471B"
          />
          <path
            d="M68.7373 210.847C68.7373 210.847 68.4354 211.439 68.7101 211.83C68.9926 212.235 69.3433 212.022 69.3433 212.022C69.3433 212.022 69.0488 212.464 69.4154 212.923C69.7612 213.356 70.2017 213.1 70.2017 213.1C70.2017 213.1 70.0002 213.585 70.3866 214.004C70.7123 214.356 71.2433 214.157 71.2433 214.157C71.2433 214.157 69.8938 216.37 69.7531 216.595C69.6131 216.82 69.5038 217.279 69.974 217.572C70.4439 217.866 70.795 217.542 70.9286 217.327C71.0626 217.111 72.7593 214.389 73.3441 213.449L73.7348 212.822C73.8808 212.586 74.0512 212.584 74.0586 212.86C74.0685 213.229 74.1633 213.554 74.4712 213.942C74.7951 214.35 75.371 214.678 75.6175 214.782C76.0753 214.975 76.5558 215.01 76.9019 214.84C77.4418 214.576 77.4761 214.039 76.8952 213.743C76.0262 213.301 75.6284 212.623 75.6719 211.931C75.7751 210.302 75.6341 209.356 74.2071 208.465C72.6504 207.494 70.5822 207.935 69.6109 209.492L68.7373 210.847Z"
            fill="#F19020"
          />
          <path
            d="M81.7403 218.207C81.8294 218.18 81.9266 218.179 82.0196 218.21C82.2473 218.285 82.3615 218.523 82.2738 218.742C81.4129 220.897 79.567 221.479 79.4889 221.503C79.2541 221.574 79.0098 221.454 78.9427 221.233C78.8758 221.013 79.0112 220.778 79.2449 220.706C79.3107 220.685 80.7525 220.211 81.4479 218.47C81.5003 218.34 81.6113 218.246 81.7403 218.207V218.207ZM76.1241 221.171C76.2139 221.151 76.3112 221.156 76.402 221.193C76.6244 221.283 76.7216 221.529 76.6198 221.742C75.614 223.834 73.733 224.289 73.6535 224.307C73.4149 224.362 73.179 224.225 73.1267 224.001C73.0744 223.778 73.2261 223.552 73.4636 223.496C73.5315 223.479 75.0009 223.105 75.8134 221.414C75.8752 221.288 75.9926 221.202 76.1241 221.171V221.171Z"
            fill="#65471B"
          />
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip8_11596:86088)">
            <path
              d="M192.886 61.5449C196.276 67.4173 203.785 69.4293 209.657 66.0389C215.53 62.6485 217.542 55.1395 214.151 49.2671C210.761 43.3948 203.252 41.3828 197.38 44.7732C191.507 48.1636 189.495 55.6726 192.886 61.5449Z"
              fill="#FFCB4C"
            />
            <path
              d="M199.75 54.6827C199.77 54.5501 199.828 54.4224 199.928 54.32C200.167 54.0701 200.549 54.0698 200.779 54.3203C203.053 56.7837 202.663 59.5517 202.646 59.6692C202.593 60.0198 202.283 60.2614 201.955 60.2125C201.627 60.1629 201.405 59.8394 201.457 59.4901C201.469 59.391 201.748 57.2176 199.911 55.227C199.774 55.0776 199.721 54.8747 199.75 54.6827V54.6827Z"
              fill="#65471B"
            />
            <path
              d="M203.75 51.2019C204.174 51.936 205.261 52.1016 206.178 51.5719C207.096 51.0422 207.496 50.0177 207.072 49.2837C206.648 48.5497 205.561 48.384 204.644 48.9137C203.726 49.4434 203.326 50.4679 203.75 51.2019Z"
              fill="#65471B"
            />
            <path
              d="M207.15 58.6255C207.574 59.3595 208.661 59.5252 209.579 58.9955C210.496 58.4658 210.896 57.4413 210.473 56.7073C210.049 55.9732 208.962 55.8076 208.044 56.3373C207.127 56.867 206.726 57.8915 207.15 58.6255Z"
              fill="#65471B"
            />
            <path
              d="M191.917 61.5002C191.917 61.5002 192.631 62.143 193.278 61.9058C193.949 61.663 193.783 61.0941 193.783 61.0941C193.783 61.0941 194.289 61.6701 195.067 61.3303C195.8 61.0096 195.608 60.2995 195.608 60.2995C195.608 60.2995 196.209 60.7618 196.938 60.3793C197.551 60.0567 197.472 59.2413 197.472 59.2413C197.472 59.2413 200.055 61.9514 200.317 62.2321C200.578 62.5115 201.178 62.8356 201.762 62.289C202.348 61.7432 202.027 61.1322 201.777 60.8654C201.527 60.5979 198.362 57.2127 197.27 56.0455L196.541 55.2661C196.267 54.9742 196.326 54.7354 196.715 54.8285C197.234 54.9527 197.722 54.9418 198.379 54.6572C199.069 54.3579 199.741 53.6768 199.979 53.372C200.42 52.8055 200.648 52.1479 200.541 51.6017C200.374 50.7497 199.638 50.5011 199.008 51.2009C198.066 52.2482 196.972 52.5498 196.022 52.2302C193.788 51.4773 192.415 51.3203 190.638 52.9781C188.701 54.787 188.544 57.8377 190.353 59.7747L191.917 61.5002Z"
              fill="#F19020"
            />
            <path
              d="M207.047 46.1099C207.043 45.9755 207.078 45.8395 207.156 45.7213C207.345 45.4316 207.721 45.3615 207.993 45.5655C210.679 47.5725 210.8 50.3654 210.804 50.4833C210.816 50.8375 210.556 51.1333 210.223 51.1446C209.892 51.1557 209.615 50.8789 209.601 50.5257C209.596 50.4261 209.474 48.2373 207.304 46.6161C207.144 46.4947 207.054 46.3046 207.047 46.1099V46.1099ZM209.084 55.0541C209.089 54.9211 209.133 54.7874 209.218 54.6745C209.427 54.398 209.806 54.3542 210.065 54.5758C212.608 56.7613 212.54 59.5558 212.536 59.6735C212.523 60.027 212.244 60.3049 211.912 60.2941C211.58 60.2835 211.322 59.9875 211.333 59.6351C211.334 59.534 211.362 57.3441 209.307 55.5785C209.154 55.4449 209.078 55.2492 209.084 55.0541V55.0541Z"
              fill="#65471B"
            />
          </g>
        </g>
        <g clipPath="url(#clip9_11596:86088)">
          <path
            d="M72.7635 81.274C75.669 79.0628 76.2318 74.9149 74.0206 72.0094C71.8094 69.1039 67.6615 68.5411 64.756 70.7523C61.8505 72.9635 61.2877 77.1115 63.4989 80.017C65.7101 82.9224 69.858 83.4852 72.7635 81.274Z"
            fill="#FFCB4C"
          />
          <path
            d="M68.6304 78.0753C68.5582 78.0735 68.486 78.0512 68.4245 78.0051C68.2747 77.8944 68.2485 77.6904 68.3666 77.5502C69.5274 76.1679 71.0324 76.1875 71.0964 76.1887C71.2873 76.193 71.4374 76.3421 71.4337 76.5209C71.4295 76.699 71.2719 76.84 71.0818 76.8361C71.028 76.836 69.8481 76.8352 68.9101 77.9523C68.8397 78.0357 68.7349 78.0778 68.6304 78.0753V78.0753Z"
            fill="#65471B"
          />
          <path
            d="M66.4985 76.1763C66.8617 75.8999 66.8761 75.3079 66.5306 74.8539C66.1851 74.4 65.6106 74.2561 65.2474 74.5325C64.8843 74.8089 64.8699 75.4009 65.2154 75.8549C65.5608 76.3088 66.1353 76.4528 66.4985 76.1763Z"
            fill="#65471B"
          />
          <path
            d="M70.2319 73.8538C70.5951 73.5774 70.6095 72.9854 70.264 72.5314C69.9185 72.0775 69.344 71.9336 68.9808 72.21C68.6177 72.4864 68.6033 73.0784 68.9488 73.5324C69.2942 73.9863 69.8687 74.1302 70.2319 73.8538Z"
            fill="#65471B"
          />
          <path
            d="M72.8059 81.7945C72.8059 81.7945 73.1006 81.3694 72.9297 81.0396C72.7543 80.698 72.4618 80.8254 72.4618 80.8254C72.4618 80.8254 72.7349 80.5159 72.5004 80.1236C72.2791 79.7539 71.9129 79.9045 71.9129 79.9045C71.9129 79.9045 72.1189 79.5523 71.8649 79.189C71.6508 78.8833 71.2206 78.9814 71.2206 78.9814C71.2206 78.9814 72.4921 77.417 72.6242 77.2579C72.7556 77.0992 72.8878 76.7567 72.556 76.4819C72.2246 76.2063 71.9201 76.4195 71.7946 76.5712C71.6689 76.7231 70.0765 78.6441 69.5275 79.3073L69.1608 79.7495C69.0236 79.916 68.892 79.9004 68.9152 79.6862C68.9462 79.4007 68.9071 79.1407 68.7103 78.8095C68.5034 78.4612 68.0938 78.1484 67.9148 78.0422C67.5821 77.8453 67.2154 77.7686 66.9309 77.863C66.4872 78.01 66.4046 78.4204 66.8214 78.7092C67.4449 79.1407 67.6806 79.7046 67.5747 80.2339C67.3248 81.4782 67.3345 82.2224 68.3411 83.0583C69.4393 83.9696 71.0795 83.8458 71.9909 82.7476L72.8059 81.7945Z"
            fill="#F19020"
          />
          <path
            d="M63.554 74.7622C63.4826 74.7736 63.4075 74.7643 63.3391 74.7308C63.1714 74.6493 63.1084 74.4534 63.1988 74.2942C64.0877 72.7228 65.5712 72.4676 65.6339 72.4575C65.8223 72.427 65.998 72.5455 66.0267 72.7226C66.0552 72.8988 65.9263 73.0658 65.7386 73.0972C65.6857 73.1067 64.5249 73.3211 63.8069 74.5904C63.753 74.6846 63.6576 74.7455 63.554 74.7622V74.7622ZM68.1925 73.0647C68.1212 73.0711 68.0468 73.0569 67.9807 73.0188C67.8187 72.926 67.7694 72.7265 67.8702 72.5731C68.8642 71.066 70.3614 70.9117 70.4246 70.9061C70.6143 70.8888 70.7817 71.0189 70.7986 71.1972C70.8156 71.3749 70.6751 71.5331 70.4861 71.5513C70.432 71.5573 69.2604 71.6919 68.4575 72.91C68.3966 73.0008 68.2972 73.0545 68.1925 73.0647V73.0647Z"
            fill="#65471B"
          />
        </g>
        <g clipPath="url(#clip10_11596:86088)">
          <path
            d="M20.7656 165.236C19.2007 162.526 15.7351 161.597 13.0247 163.162C10.3144 164.727 9.3858 168.192 10.9506 170.903C12.5154 173.613 15.9811 174.542 18.6914 172.977C21.4017 171.412 22.3304 167.946 20.7656 165.236Z"
            fill="#FFCB4C"
          />
          <path
            d="M17.5974 168.403C17.5881 168.464 17.5613 168.523 17.5155 168.57C17.405 168.686 17.2288 168.686 17.1224 168.57C16.073 167.433 16.253 166.156 16.261 166.101C16.2853 165.94 16.4284 165.828 16.58 165.851C16.731 165.873 16.8338 166.023 16.8098 166.184C16.8039 166.23 16.6752 167.233 17.5232 168.152C17.5865 168.221 17.6109 168.314 17.5974 168.403V168.403Z"
            fill="#65471B"
          />
          <path
            d="M15.7515 170.009C15.5559 169.67 15.0541 169.594 14.6307 169.839C14.2072 170.083 14.0225 170.556 14.2181 170.895C14.4137 171.233 14.9155 171.31 15.339 171.065C15.7624 170.821 15.9471 170.348 15.7515 170.009Z"
            fill="#65471B"
          />
          <path
            d="M14.182 166.583C13.9864 166.244 13.4845 166.168 13.0611 166.412C12.6376 166.657 12.4529 167.13 12.6485 167.468C12.8441 167.807 13.346 167.884 13.7694 167.639C14.1929 167.395 14.3776 166.922 14.182 166.583Z"
            fill="#65471B"
          />
          <path
            d="M21.2129 165.256C21.2129 165.256 20.8835 164.959 20.5845 165.069C20.2751 165.181 20.3516 165.444 20.3516 165.444C20.3516 165.444 20.1181 165.178 19.7591 165.335C19.4207 165.483 19.5091 165.81 19.5091 165.81C19.5091 165.81 19.232 165.597 18.8955 165.774C18.6123 165.922 18.6491 166.299 18.6491 166.299C18.6491 166.299 17.4569 165.048 17.336 164.918C17.2153 164.789 16.9384 164.64 16.6688 164.892C16.3985 165.144 16.5467 165.426 16.6621 165.549C16.7776 165.673 18.2382 167.235 18.7424 167.774L19.0786 168.133C19.2053 168.268 19.1777 168.378 18.9982 168.335C18.7588 168.278 18.5334 168.283 18.2305 168.414C17.9119 168.553 17.6015 168.867 17.4918 169.008C17.2883 169.269 17.1833 169.573 17.2326 169.825C17.3095 170.218 17.6495 170.333 17.9403 170.01C18.3748 169.526 18.8799 169.387 19.3184 169.535C20.3492 169.882 20.983 169.955 21.803 169.189C22.697 168.355 22.7697 166.947 21.9349 166.053L21.2129 165.256Z"
            fill="#F19020"
          />
          <path
            d="M14.2297 172.36C14.2316 172.422 14.2155 172.484 14.1797 172.539C14.0921 172.673 13.9187 172.705 13.7932 172.611C12.5536 171.685 12.4976 170.395 12.4958 170.341C12.4903 170.178 12.6101 170.041 12.7638 170.036C12.9167 170.031 13.0447 170.158 13.0511 170.321C13.0534 170.367 13.1097 171.378 14.111 172.126C14.1853 172.182 14.2267 172.27 14.2297 172.36V172.36ZM13.2897 168.231C13.2874 168.293 13.2672 168.355 13.2276 168.407C13.1312 168.534 12.9562 168.555 12.8367 168.452C11.6632 167.444 11.6945 166.154 11.6966 166.099C11.7025 165.936 11.8313 165.808 11.9846 165.813C12.1375 165.818 12.2569 165.955 12.2518 166.117C12.251 166.164 12.2384 167.175 13.1869 167.989C13.2575 168.051 13.2924 168.141 13.2897 168.231V168.231Z"
            fill="#65471B"
          />
        </g>
        <g clipPath="url(#clip11_11596:86088)">
          <path
            d="M219.184 196.506C222.688 193.002 222.688 187.321 219.184 183.817C215.68 180.313 209.999 180.313 206.495 183.817C202.992 187.321 202.992 193.002 206.495 196.506C209.999 200.01 215.68 200.01 219.184 196.506Z"
            fill="#FFCB4C"
          />
          <path
            d="M213.042 192.958C212.945 192.969 212.843 192.952 212.752 192.901C212.531 192.78 212.458 192.51 212.592 192.3C213.9 190.229 215.928 189.981 216.014 189.971C216.272 189.942 216.501 190.115 216.528 190.356C216.555 190.597 216.369 190.815 216.113 190.844C216.04 190.854 214.453 191.068 213.396 192.742C213.316 192.867 213.183 192.942 213.042 192.958V192.958Z"
            fill="#65471B"
          />
          <path
            d="M209.828 190.793C210.266 190.355 210.178 189.556 209.63 189.009C209.083 188.461 208.284 188.373 207.846 188.811C207.408 189.249 207.497 190.048 208.044 190.595C208.592 191.142 209.39 191.231 209.828 190.793Z"
            fill="#65471B"
          />
          <path
            d="M214.426 186.989C214.864 186.551 214.775 185.752 214.227 185.205C213.68 184.657 212.881 184.569 212.443 185.007C212.005 185.445 212.094 186.243 212.641 186.791C213.189 187.338 213.988 187.427 214.426 186.989Z"
            fill="#65471B"
          />
          <path
            d="M219.336 197.198C219.336 197.198 219.655 196.573 219.365 196.16C219.066 195.733 218.696 195.958 218.696 195.958C218.696 195.958 219.007 195.491 218.62 195.007C218.255 194.55 217.79 194.819 217.79 194.819C217.79 194.819 218.003 194.308 217.595 193.866C217.251 193.494 216.691 193.704 216.691 193.704C216.691 193.704 218.115 191.368 218.264 191.13C218.412 190.893 218.527 190.408 218.03 190.099C217.534 189.789 217.164 190.131 217.023 190.358C216.881 190.585 215.09 193.459 214.473 194.451L214.061 195.113C213.907 195.362 213.727 195.365 213.719 195.073C213.709 194.683 213.608 194.34 213.283 193.931C212.942 193.5 212.334 193.154 212.074 193.044C211.59 192.84 211.083 192.804 210.718 192.983C210.148 193.262 210.112 193.828 210.725 194.141C211.642 194.607 212.062 195.323 212.016 196.054C211.907 197.773 212.056 198.772 213.562 199.712C215.205 200.737 217.389 200.272 218.414 198.628L219.336 197.198Z"
            fill="#F19020"
          />
          <path
            d="M205.611 189.429C205.517 189.457 205.414 189.458 205.316 189.426C205.075 189.347 204.955 189.095 205.047 188.864C205.956 186.589 207.905 185.975 207.987 185.95C208.235 185.875 208.493 186.002 208.564 186.235C208.634 186.466 208.491 186.715 208.245 186.791C208.175 186.814 206.653 187.314 205.919 189.152C205.864 189.288 205.747 189.387 205.611 189.429V189.429ZM211.539 186.3C211.444 186.321 211.341 186.316 211.246 186.276C211.011 186.181 210.908 185.922 211.016 185.697C212.077 183.489 214.063 183.008 214.147 182.989C214.399 182.932 214.648 183.076 214.703 183.313C214.758 183.548 214.598 183.787 214.347 183.846C214.275 183.864 212.724 184.259 211.867 186.043C211.802 186.176 211.678 186.267 211.539 186.3V186.3Z"
            fill="#65471B"
          />
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip12_11596:86088)">
            <path
              d="M117.281 169.426C114.119 167.6 110.076 168.683 108.25 171.845C106.424 175.007 107.508 179.051 110.67 180.876C113.832 182.702 117.875 181.619 119.701 178.456C121.526 175.294 120.443 171.251 117.281 169.426Z"
              fill="#FFCB4C"
            />
            <path
              d="M115.927 174.474C115.954 174.541 115.961 174.616 115.942 174.691C115.898 174.872 115.72 174.975 115.545 174.92C113.821 174.383 113.258 172.987 113.235 172.928C113.165 172.75 113.244 172.554 113.411 172.489C113.576 172.424 113.767 172.514 113.837 172.691C113.858 172.741 114.313 173.83 115.706 174.263C115.81 174.296 115.889 174.376 115.927 174.474V174.474Z"
              fill="#65471B"
            />
            <path
              d="M114.999 177.174C114.604 176.946 114.052 177.161 113.767 177.655C113.482 178.149 113.571 178.735 113.966 178.963C114.362 179.191 114.913 178.975 115.199 178.481C115.484 177.987 115.395 177.402 114.999 177.174Z"
              fill="#65471B"
            />
            <path
              d="M111.415 174.627C111.02 174.399 110.468 174.615 110.183 175.109C109.898 175.603 109.987 176.188 110.382 176.416C110.777 176.645 111.329 176.429 111.614 175.935C111.899 175.441 111.81 174.856 111.415 174.627Z"
              fill="#65471B"
            />
            <path
              d="M117.744 169.185C117.744 169.185 117.239 169.078 117 169.363C116.753 169.656 116.984 169.877 116.984 169.877C116.984 169.877 116.593 169.745 116.321 170.113C116.066 170.46 116.346 170.739 116.346 170.739C116.346 170.739 115.942 170.685 115.705 171.06C115.506 171.375 115.762 171.734 115.762 171.734C115.762 171.734 113.828 171.166 113.63 171.106C113.433 171.046 113.066 171.056 112.941 171.468C112.815 171.88 113.129 172.079 113.317 172.136C113.506 172.193 115.893 172.92 116.717 173.17L117.266 173.337C117.473 173.4 117.509 173.527 117.303 173.588C117.028 173.67 116.803 173.807 116.574 174.116C116.332 174.442 116.202 174.94 116.173 175.146C116.12 175.529 116.191 175.897 116.388 176.123C116.695 176.475 117.105 176.393 117.211 175.897C117.368 175.155 117.797 174.72 118.326 174.613C119.57 174.363 120.253 174.067 120.635 172.815C121.051 171.45 120.303 169.985 118.938 169.569L117.744 169.185Z"
              fill="#F19020"
            />
            <path
              d="M114.833 180.436C114.871 180.497 114.891 180.57 114.887 180.646C114.876 180.832 114.72 180.966 114.538 180.944C112.745 180.732 111.937 179.462 111.903 179.408C111.802 179.246 111.844 179.038 111.996 178.943C112.148 178.849 112.351 178.903 112.453 179.064C112.482 179.109 113.128 180.097 114.576 180.269C114.684 180.282 114.777 180.347 114.833 180.436V180.436ZM111.475 176.813C111.508 176.877 111.524 176.951 111.514 177.027C111.491 177.212 111.326 177.334 111.146 177.301C109.372 176.966 108.651 175.645 108.622 175.589C108.532 175.42 108.588 175.216 108.746 175.131C108.903 175.047 109.103 175.115 109.193 175.283C109.219 175.33 109.796 176.359 111.23 176.629C111.337 176.65 111.425 176.721 111.475 176.813V176.813Z"
              fill="#65471B"
            />
          </g>
        </g>
        <g opacity={0.7}>
          <g clipPath="url(#clip13_11596:86088)">
            <path
              d="M11.3394 106.359C11.3394 103.229 8.80238 100.692 5.67277 100.692C2.54316 100.692 0.00610313 103.229 0.00610327 106.359C0.0061034 109.488 2.54316 112.025 5.67277 112.025C8.80238 112.025 11.3394 109.488 11.3394 106.359Z"
              fill="#FFCB4C"
            />
            <path
              d="M7.01193 107.517C6.97326 107.566 6.9206 107.603 6.85726 107.621C6.70393 107.666 6.55126 107.578 6.51693 107.425C6.1766 105.915 6.97126 104.899 7.00526 104.856C7.10726 104.728 7.28693 104.703 7.40693 104.798C7.52626 104.894 7.5406 105.074 7.43926 105.202C7.41126 105.239 6.79826 106.043 7.07326 107.263C7.0936 107.354 7.06793 107.447 7.01193 107.517V107.517Z"
              fill="#65471B"
            />
            <path
              d="M4.61002 107.986C4.61002 107.595 4.21364 107.277 3.72469 107.277C3.23573 107.277 2.83936 107.595 2.83936 107.986C2.83936 108.377 3.23573 108.694 3.72469 108.694C4.21364 108.694 4.61002 108.377 4.61002 107.986Z"
              fill="#65471B"
            />
            <path
              d="M4.96403 104.234C4.96403 103.842 4.56765 103.525 4.07869 103.525C3.58974 103.525 3.19336 103.842 3.19336 104.234C3.19336 104.625 3.58974 104.942 4.07869 104.942C4.56765 104.942 4.96403 104.625 4.96403 104.234Z"
              fill="#65471B"
            />
            <path
              d="M11.7162 106.6C11.7162 106.6 11.5792 106.178 11.2656 106.124C10.9416 106.066 10.8766 106.332 10.8766 106.332C10.8766 106.332 10.8072 105.985 10.4179 105.941C10.0509 105.9 9.96356 106.228 9.96356 106.228C9.96356 106.228 9.83023 105.905 9.45056 105.889C9.13089 105.877 8.97456 106.221 8.97456 106.221C8.97456 106.221 8.56756 104.542 8.52756 104.369C8.48756 104.197 8.32256 103.929 7.96289 104.013C7.60289 104.096 7.59023 104.414 7.62856 104.578C7.66689 104.743 8.15056 106.826 8.3179 107.545L8.42923 108.025C8.47156 108.205 8.39256 108.286 8.25856 108.159C8.07989 107.99 7.88223 107.882 7.55423 107.844C7.20923 107.804 6.78323 107.921 6.6179 107.988C6.3109 108.113 6.06823 108.323 5.9849 108.566C5.8549 108.945 6.0919 109.215 6.50523 109.08C7.12323 108.879 7.63023 109.011 7.93623 109.358C8.65523 110.174 9.16789 110.554 10.2606 110.301C11.4522 110.025 12.2192 108.842 11.9432 107.651L11.7162 106.6Z"
              fill="#F19020"
            />
            <path
              d="M2.11706 109.26C2.08773 109.315 2.0424 109.361 1.98406 109.39C1.8414 109.462 1.67506 109.404 1.6134 109.259C1.00306 107.837 1.59906 106.693 1.62473 106.645C1.70173 106.501 1.87373 106.442 2.0094 106.515C2.1444 106.587 2.1914 106.761 2.1154 106.906C2.0944 106.947 1.63806 107.85 2.13106 108.998C2.1674 109.084 2.1594 109.181 2.11706 109.26V109.26ZM3.36706 105.215C3.33439 105.267 3.28606 105.31 3.22573 105.336C3.0784 105.398 2.91673 105.328 2.8644 105.18C2.3524 103.719 3.02439 102.618 3.05339 102.572C3.14006 102.434 3.31573 102.387 3.44606 102.468C3.57606 102.549 3.61106 102.727 3.52539 102.865C3.50139 102.905 2.98506 103.774 3.39906 104.954C3.42939 105.043 3.41439 105.138 3.36706 105.215V105.215Z"
              fill="#65471B"
            />
          </g>
        </g>
        <g clipPath="url(#clip14_11596:86088)">
          <path
            d="M235.043 109.781C235.043 113.953 238.426 117.336 242.599 117.336C246.772 117.336 250.155 113.953 250.155 109.781C250.155 105.608 246.772 102.225 242.599 102.225C238.426 102.225 235.043 105.608 235.043 109.781Z"
            fill="#FFCB4C"
          />
          <path
            d="M240.813 108.236C240.865 108.171 240.935 108.121 241.019 108.097C241.224 108.037 241.427 108.155 241.473 108.359C241.927 110.372 240.867 111.727 240.822 111.784C240.686 111.955 240.447 111.988 240.287 111.861C240.127 111.734 240.108 111.493 240.243 111.323C240.281 111.274 241.098 110.201 240.731 108.575C240.704 108.453 240.739 108.329 240.813 108.236V108.236Z"
            fill="#65471B"
          />
          <path
            d="M244.016 107.611C244.016 108.133 244.544 108.556 245.196 108.556C245.848 108.556 246.377 108.133 246.377 107.611C246.377 107.09 245.848 106.667 245.196 106.667C244.544 106.667 244.016 107.09 244.016 107.611Z"
            fill="#65471B"
          />
          <path
            d="M243.544 112.614C243.544 113.135 244.072 113.558 244.724 113.558C245.376 113.558 245.905 113.135 245.905 112.614C245.905 112.092 245.376 111.669 244.724 111.669C244.072 111.669 243.544 112.092 243.544 112.614Z"
            fill="#65471B"
          />
          <path
            d="M234.541 109.459C234.541 109.459 234.724 110.021 235.142 110.094C235.574 110.171 235.66 109.816 235.66 109.816C235.66 109.816 235.753 110.279 236.272 110.337C236.761 110.392 236.878 109.955 236.878 109.955C236.878 109.955 237.056 110.386 237.562 110.406C237.988 110.423 238.196 109.964 238.196 109.964C238.196 109.964 238.739 112.203 238.792 112.433C238.846 112.663 239.066 113.02 239.545 112.908C240.025 112.798 240.042 112.373 239.991 112.154C239.94 111.935 239.295 109.157 239.072 108.199L238.924 107.559C238.867 107.319 238.972 107.21 239.151 107.38C239.389 107.605 239.653 107.75 240.09 107.8C240.55 107.853 241.118 107.697 241.339 107.608C241.748 107.441 242.072 107.161 242.183 106.837C242.356 106.332 242.04 105.972 241.489 106.152C240.665 106.42 239.989 106.244 239.581 105.781C238.622 104.693 237.939 104.187 236.482 104.524C234.893 104.892 233.87 106.469 234.238 108.058L234.541 109.459Z"
            fill="#F19020"
          />
          <path
            d="M247.34 105.912C247.379 105.839 247.44 105.777 247.517 105.738C247.708 105.642 247.929 105.721 248.012 105.913C248.825 107.809 248.031 109.335 247.996 109.399C247.894 109.591 247.664 109.669 247.484 109.573C247.304 109.477 247.241 109.244 247.342 109.051C247.37 108.997 247.979 107.793 247.321 106.261C247.273 106.147 247.284 106.018 247.34 105.912V105.912ZM245.673 111.305C245.717 111.236 245.781 111.178 245.862 111.145C246.058 111.061 246.274 111.155 246.344 111.353C247.026 113.3 246.13 114.768 246.092 114.829C245.976 115.014 245.742 115.076 245.568 114.968C245.395 114.861 245.348 114.623 245.462 114.439C245.494 114.385 246.183 113.227 245.631 111.653C245.59 111.535 245.61 111.408 245.673 111.305V111.305Z"
            fill="#65471B"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_11596:86088"
          x={94.7366}
          y={90.1736}
          width={62.0068}
          height={62.0068}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11596:86088"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11596:86088"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_11596:86088">
          <rect
            width={18.8694}
            height={17.7258}
            fill="white"
            transform="translate(139.233 3.43091)"
          />
        </clipPath>
        <clipPath id="clip1_11596:86088">
          <rect
            width={30}
            height={30}
            fill="white"
            transform="translate(159.228 212.024) rotate(120)"
          />
        </clipPath>
        <clipPath id="clip2_11596:86088">
          <rect
            width={26}
            height={26}
            fill="white"
            transform="translate(32.3066 142.934) rotate(-60)"
          />
        </clipPath>
        <clipPath id="clip3_11596:86088">
          <rect
            width={26}
            height={26}
            fill="white"
            transform="translate(163.865 145.292) rotate(-120)"
          />
        </clipPath>
        <clipPath id="clip4_11596:86088">
          <rect
            width={28}
            height={28}
            fill="white"
            transform="translate(65.5386) rotate(57.9222)"
          />
        </clipPath>
        <clipPath id="clip5_11596:86088">
          <rect
            width={27}
            height={27}
            fill="white"
            transform="translate(137.491 82.7673) rotate(-119.734)"
          />
        </clipPath>
        <clipPath id="clip6_11596:86088">
          <rect
            width={19}
            height={19}
            fill="white"
            transform="translate(5.35938 60.1575) rotate(-45)"
          />
        </clipPath>
        <clipPath id="clip7_11596:86088">
          <rect
            width={18}
            height={18}
            fill="white"
            transform="translate(87.2725 217.166) rotate(135)"
          />
        </clipPath>
        <clipPath id="clip8_11596:86088">
          <rect
            width={26}
            height={26}
            fill="white"
            transform="translate(207.663 38.0022) rotate(60)"
          />
        </clipPath>
        <clipPath id="clip9_11596:86088">
          <rect
            width={14}
            height={14}
            fill="white"
            transform="translate(59.1814 74.9861) rotate(-37.273)"
          />
        </clipPath>
        <clipPath id="clip10_11596:86088">
          <rect
            width={12}
            height={12}
            fill="white"
            transform="translate(13.9456 176.102) rotate(-120)"
          />
        </clipPath>
        <clipPath id="clip11_11596:86088">
          <rect
            width={19}
            height={19}
            fill="white"
            transform="translate(199.771 190.528) rotate(-45)"
          />
        </clipPath>
        <clipPath id="clip12_11596:86088">
          <rect
            width={14}
            height={14}
            fill="white"
            transform="translate(116.729 184.382) rotate(-150)"
          />
        </clipPath>
        <clipPath id="clip13_11596:86088">
          <rect
            width={12}
            height={12}
            fill="white"
            transform="translate(0 112.359) rotate(-90)"
          />
        </clipPath>
        <clipPath id="clip14_11596:86088">
          <rect
            width={16}
            height={16}
            fill="white"
            transform="translate(250.163 101.781) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
