import { action, computed, makeObservable, observable } from 'mobx';
import { Config } from '../../config';
import { AsyncIterableRelay, Result } from '../../core';
import { INIT_DEBUGGER, TRACE } from '../../core/debug/debugMacros';
import { Error } from '../../core/error';
import { UserProfile } from '../../entities';
import { StoreNode } from '../../store';
import { Store } from '../../store/store';
import { AuthPermit } from './authPermit';
import { AuthContext, AuthContextType } from './authSchema';
import { isTokenValid } from './authUtils';

const ServiceConfig = Config.auth;

const STORAGE_ID_TOKEN_EXPIRES_KEY = ServiceConfig.storageIdTokenExpiresKey;
const STORAGE_IS_AUTHENTICATED_KEY = ServiceConfig.storageIsAuthenticatedKey;

export type AuthStorageData = {
  idTokenExpires: number;
  isAuthenticated: boolean;
}

/**
 * Exposes methods for setting and querying the authorization state which is kept in localStorage.
 * The purpose of this object is to keep the minimally required information in order
 * to prevent unnecessary requests to Auth0 in order to check if the user is still 
 * authenticated or not, or if a token needs to be refreshed or not.
 * The only type of information allowed to be stored is token expiry timestamps 
 * and simple flags regarding the current session status.
 * Keeping actual tokens in localStorage should be avoided.
 */
export class AuthStateManager
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    INIT_DEBUGGER(this, { color: 'blueviolet' });
  }

  readonly nodeType: 'AuthStateManager' = 'AuthStateManager';

  @observable context: AuthContext | null = null;

  @computed get permit(): AuthPermit | null {
    return this.context?.permit ?? null;
  }
  @computed get profile(): UserProfile | null {
    return this.context?.profile ?? null;
  }

  @computed get hasContext() {
    return !!this.context;
  }
  @computed get hasAuthenticatedContext(): boolean {
    return !!this.context?.isAuthenticated;
  }
  @computed get hasAnonymousContext(): boolean {
    return !!this.context?.isAnonymous;
  }
  @computed get hasValidAuthenticatedContext(): boolean {
    return this.hasAuthenticatedContext && !!this.context?.isValid;
  }
  @computed get hasInvalidAuthenticatedContext(): boolean {
    return this.hasAuthenticatedContext && !this.context?.isValid;
  }

  private readonly contextIterableRelay = new AsyncIterableRelay<AuthContext | null>();
  private get contextIterable(): AsyncIterable<AuthContext | null> {
    return this.contextIterableRelay.iterable;
  }

  get hasValidAuthenticatedStorageState(): boolean {
    const storageData = this.getStorageData();
    return (
      !!storageData?.isAuthenticated &&
      isTokenValid(storageData.idTokenExpires));
  }

  get hasValidStorageState(): boolean {
    return this.hasValidAuthenticatedStorageState;
  }

  /**
   * Returns the current context as it is, without checking if it's valid 
   * or syncing it with the storage. 
   * Use this when you want to check the current state of the context yourself.
   */
  getContext() {
    TRACE(this, `getContext()`, this.context);
    return this.context;
  }

  @action
  setContext(context: AuthContext): Result<true> {
    TRACE(this, `setContext()`, context);

    if (!context?.isValid)
      return [null, new Error('AuthError', `Cannot set an invalid context.`)];

    switch (context.type) {
      case AuthContextType.Authenticated:
        // permit should exist and be valid since the check below succeeded
        // if anything goes wrong here with the permit then something went horribly wrong

        const { idTokenExpires } = context.permit;
        this.setStorageData({
          idTokenExpires,
          isAuthenticated: true
        });
        break;

      case AuthContextType.Anonymous:
        // for anonymous contexts we never read the state from storage
        // the context is always generated on the fly for each route
        this.clearStorageData();
        break;
    }

    return this.setOrClearContext(context);
  }

  @action
  clearContext(): Result<true> {
    TRACE(this, `clearContext()`, this.context);

    // TODO: move here
    this.store.authService.clearProfileStorage();

    this.clearStorageData();
    return this.setOrClearContext(null);
  }

  /**
   * Call this to make sure that storage is valid and up to date.
   */
  @action
  consolidate() {
    if (!this.hasValidStorageState)
      this.clearStorageData();
  }

  /**
   * Returns a Promise which is resolved when the next valid AuthContext is set on the local state.
   * The promise won't be resolved if there are any invalidations until the next valid AuthContext arrives.
   * If you also want to take into account invalidations, use `waitForNextContextOrInvalidation`.
   */
  async waitForNextContext(): Promise<AuthContext> {
    TRACE(this, `waitForNextContext()`);

    for await (const context of this.contextIterable) {
      TRACE(this, `Iteration`, context);

      if (context) {
        TRACE(this, `Awaiting resolved`, context);
        return context;
      }
    }

    throw new Error('InternalError', `The context iterable was finished which should never happen.`);
  }

  /**
   * Returns a Promise which is resolved on the next update of the current context, 
   * be it a valid AuthContext or an invalidation.
   */
  async waitForNextContextOrInvalidation() {
    TRACE(this, `waitForNextContextOrInvalidation()`);

    for await (const context of this.contextIterable) {
      TRACE(this, `Iteration and awaiting resolved`, context);
      return context;
    }

    throw new Error('InternalError', `The context iterable was finished which should never happen.`);
  }

  private setStorageData(data: AuthStorageData) {
    const { storage } = this.store;

    // serialize the values directly (encode = true)
    storage.setLocal(
      STORAGE_ID_TOKEN_EXPIRES_KEY, data.idTokenExpires, true);
    storage.setLocal(
      STORAGE_IS_AUTHENTICATED_KEY, data.isAuthenticated, true);
  }

  /**
   * Reads / sanitizes the values from localStorage.
   * If any kind of issue is detected with the any of the keys (missing values, wrong data types),
   * the entire state is discarded as a whole and `null` is returned.
   */
  private getStorageData(): AuthStorageData | null {
    const { storage } = this.store;

    // get the values as strings (decode = false)
    // we'll decode them manually in the rest of the code
    const idTokenExpiresVal =
      storage.getLocal(STORAGE_ID_TOKEN_EXPIRES_KEY, false);
    const isAuthenticatedVal =
      storage.getLocal(STORAGE_IS_AUTHENTICATED_KEY, false);

    // if either one of the required state values is not set, we consider the state to be invalid
    if (
      !idTokenExpiresVal ||
      !isAuthenticatedVal)
      return null;

    // decode the values
    const idTokenExpires = parseInt(idTokenExpiresVal);
    const isAuthenticated = JSON.parse(isAuthenticatedVal);

    // if there's any value which we don't expect, return null for safety
    // usage of this function should dictate that if null is returned, then the state should be cleared
    if (
      !Number.isFinite(idTokenExpires) ||
      typeof isAuthenticated !== 'boolean')
      return null;

    const data: AuthStorageData = {
      idTokenExpires,
      isAuthenticated
    }

    // we will also store this in the cache
    Object.freeze(data);

    return data;
  }

  private clearStorageData() {
    const { storage } = this.store;

    storage.removeLocal(STORAGE_ID_TOKEN_EXPIRES_KEY);
    storage.removeLocal(STORAGE_IS_AUTHENTICATED_KEY);
  }

  /**
   * Single entry point for updating the AuthContext in the entire application.
   * Will also yield the context to the iterable relay.
   */
  private setOrClearContext(context: AuthContext | null): Result<true> {
    this.context = context;
    this.contextIterableRelay.next(context);

    this.__debugRegisterExpiryHelper();

    return [true];
  }

  private __debugRegisterExpiryHelper() {

    const { context } = this;
    // debug helper to see when the token expires
    if (process.env.NODE_ENV !== 'production') {

      this.__traceExpiredTimeoutIds.forEach(timeoutId =>
        clearTimeout(timeoutId));
      this.__traceExpiredTimeoutIds = [];

      const timeoutIds = this.__traceExpiredTimeoutIds;

      if (context && context.permit) {
        const timeout = context.permit.idTokenExpires * 1000 - (+new Date());

        for (let i = 1; i <= 10; i++) {
          timeoutIds.push(setTimeout(() => {
            TRACE(this, `AuthPermit[${context.permit.id}] expiring in ${i}s`)
          }, timeout - i * 1000));
        }

        for (let i = 1; i <= 10; i++) {
          timeoutIds.push(setTimeout(() => {
            TRACE(this, `AuthPermit[${context.permit.id}] expired ${i}s ago`)
          }, timeout + i * 1000));
        }

        timeoutIds.push(setTimeout(() => {
          TRACE(this, `AuthPermit[${context.permit.id}] expired`)
        }, timeout));
      }
    }
  }

  private __traceExpiredTimeoutIds: ReturnType<typeof setTimeout>[] = [];
}