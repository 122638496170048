import { action, computed, makeObservable, observable } from 'mobx';
import { inputGroup, InputGroupState } from '../input';
import { BindingProps, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { AdsItemState } from './adsItemState';
import { Team } from '../../entities';

type Props = BindingProps<{
  teamId?: string
}>

export class AdsSettingsSectionState
  extends StoreNode {
  readonly form: InputGroupState;

  @observable isLocked = false;

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.form = inputGroup(this, {
      name: 'adsLinksForm',
      inputs: () => this.vastUrls.map((el: AdsItemState) => el.urlInput),
      disabled: (input: any) => {
        return (
          input.hasLoadingInputs ||
          input.isSubmitting ||
          this.isLocked
        )
      }
    });
  }

  @observable vastUrls: AdsItemState[] = [];

  @computed
  get teamId(): string | null {
    return this.resolvedProps.teamId ?? null;
  }

  @computed
  get hasError(): boolean {
    return !!this.vastUrls.find(i => i.error);
  }

  @computed
  get isEmpty(): boolean {
    return !this.vastUrls.find(i => i.urlInput.value !== null);
  }

  @computed get team(): Team | null {
    return this.store.teamManager.getTeam(this.teamId) ?? null;
  }

  // checkIsDuplicateLink = (url: string) => {
  //   return this.vastUrls.filter(i => i.urlInput.value?.trim() === url).length > 1;
  // }

  // checkLinkValidity = (url: string) => {
  //   if (this.checkIsDuplicateLink(url))
  //     return 'Duplicate URL';

  //   return null;
  // }

  @action
  scrollToBottom() {
    const elem = document.getElementById('ads-list-group');

    if (!elem)
      return;

    elem.scroll({ top: elem.scrollHeight, behavior: 'smooth' });
  }

  @action
  init() {
    if (!this.team) {
      console.error('No valid library found');
      return;
    }

    const { adTagUrls } = this.team;

    if (adTagUrls) {
      this.vastUrls = adTagUrls.map((vastUrl: string) => {
        const adsItemState: AdsItemState = new AdsItemState(this.store, {
          initialValue: vastUrl
        });

        return adsItemState;
      });
    }

    this.addVastUrl();
    this.form.saveInitialInputs();
  }

  @action
  addVastUrl = () => {
    const adsItemState: AdsItemState = new AdsItemState(this.store);
    this.vastUrls.push(adsItemState);

    // Triggers a state change that will be catched by the use effect hook
    this.vastUrls = [...this.vastUrls];
  }

  @action
  removeVastUrl = (i: number) => {
    this.vastUrls.splice(i, 1);
  }

  @action
  reset() {
    this.vastUrls = [];
  }

  @action
  lock() {
    this.isLocked = true;
    this.vastUrls.forEach(item => item.lock());
  }

  @action
  unlock() {
    this.isLocked = false;
    this.vastUrls.forEach(item => item.unlock());
  }

  @action
  export(): string[] {
    const output: string[] = [];

    this.vastUrls.forEach(
      (item: AdsItemState) => {
        if (item.urlInput.value)
          output.push(item.urlInput.value);
      });

    return output;
  }
}
