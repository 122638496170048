const KILO_R = 1000;
const KIBI_R = 1024;
const MEGA_R = 1000000;
const MEBI_R = 1048576;
const GIGA_R = 1000000000;
const GIBI_R = 1073741824;

export class FileSizeInfo {

  readonly size: number = 0;
  constructor(size: number) {
    this.size = size;
  }

  get bytes() { return this.size; }
  get bits() { return this.size * 8; }

  get kilobytes() { return this.bytes / KILO_R; }
  get kibibytes() { return this.bytes / KIBI_R; }
  get kilobits() { return this.bits / KILO_R; }
  get kibibits() { return this.bits / KIBI_R; }

  get megabytes() { return this.bytes / MEGA_R; }
  get mebibytes() { return this.bytes / MEBI_R; }
  get megabits() { return this.bits / MEGA_R; }
  get mebibits() { return this.bits / MEBI_R; }

  get gigabytes() { return this.bytes / GIGA_R; }
  get gibibytes() { return this.bytes / GIBI_R; }
  get gigabits() { return this.bits / GIGA_R; }
  get gibibits() { return this.bits / GIBI_R; }

  get displayUnit() {
    return this.displayUnitPrefix + 'B';
  }

  get displayUnitPrefix() {

    let bytes = this.bytes;
    if (bytes < KILO_R)
      return '';
    if (bytes < MEGA_R)
      return 'K';
    if (bytes < GIGA_R)
      return 'M';

    return 'G';
  }

  get displaySize() {

    let bytes = this.bytes;
    if (bytes < KILO_R)
      return bytes;
    if (bytes < MEGA_R)
      return bytes / KILO_R;
    if (bytes < GIGA_R)
      return bytes / MEGA_R;

    return bytes / GIGA_R;
  }

  toString() {
    return this.displaySize.toFixed(2) + ' ' + this.displayUnit;
  }
}