import { ChartPageParams } from '../chartSchema';
import { LineSeriesProps } from '../xySeriesChart';

export type HoursCliprdChartExternalParams = ChartPageParams;

export type HoursCliprdChartInternalParams = {
  mode?: HoursCliprdChartMode | null
};

export type HoursCliprdChartParams =
  HoursCliprdChartInternalParams &
  HoursCliprdChartExternalParams;

export type HoursCliprdChartDatum = LineSeriesProps;

export enum HoursCliprdChartMode {
  Processed = 'Processed',
  Viewed = 'Viewed',
  Saved = 'Saved'
}

export type HoursCliprdData = {
  date: string;
  value: number;
}
