import { action, computed, makeObservable, observable } from 'mobx';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { JobLiveAggregateStatus, JobModel } from '../../entities';
import { LiveStreamActivePlaylist, LiveJobSourceInput } from '@clipr/lib';
import { PlayerState } from '..';
import { JobLiveStatusMonitor } from '../../entities/jobLiveStatusMonitor';
import { MediaInfo } from '../../entities/media';
import { LiveNotStreamingWindowState } from './liveNotStreamingWindowState';

export class LiveFeedWindowState extends StoreNode {
  readonly nodeType = 'liveFeedWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(this.windowListener);
    this.jobLiveStatusMonitor.listen(
      this.jobLiveStatusMonitorListener);
  }

  readonly window = new WindowState(this.store);
  private readonly jobLiveStatusMonitor = new JobLiveStatusMonitor(this.store, {
    jobId: () => this.jobId
  });

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading || this.isLoading)
          return;
        this.close();
        break;
    }
  };

  @action
  private liveNotStreamingWindowListener = (msg: Message<LiveNotStreamingWindowState>) => {
    const { playerGroup } = this;

    if (!playerGroup)
      return;
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        for (const player of playerGroup) {
          player.invoke('autoplay');
        }
        break;
    }
  };

  @observable isLoading: boolean = false;
  @observable changedOnce: boolean = false;
  @observable jobId: string | null = null;
  @observable teamId: string | null = null;
  @observable showFeedPlayer: boolean = false;
  @observable playlistType: LiveStreamActivePlaylist | null = null;
  @observable playerGroup: PlayerState[] | null = null;
  private abortController: AbortController | null = null;

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @action
  open({ jobId, teamId }: { jobId: string, teamId: string }) {
    this.jobId = jobId;
    this.teamId = teamId;

    this.emit('open');
    this.dispatch('Overlays', 'openWindow', { name: 'LiveFeedWindow' });

    this.init();
  }

  @action
  private async load() {
    this.abortLoading();
    this.isLoading = true;

    const {
      store,
      jobId
    } = this;

    if (!jobId) {
      this.isLoading = false;
      console.error('No job id provided');
      notifyError(this, 'Could not load job');
      return;
    }

    const abortCtrl = new AbortController();
    this.abortController = abortCtrl;

    const reqOpts = {
      signal: abortCtrl.signal
    };

    const [, error] = await store.apiFetchJob(jobId, false, reqOpts);

    // if one of the requests is aborted, just stop and reset loading
    if (error) {
      console.error(this, error);
      notifyError(this, 'Could not load job');
      this.isLoading = false;
    }

    const { job } = this;

    if (!job) {
      notifyError(this, 'Could not load job');
      console.error('The job could not be retrieved from the store');
      this.isLoading = false;
      return;
    }

    // if the job is a live job start a polling routine
    // for checking when the job's state changes, until it ends
    if (job.isLive && job.liveAggregateStatus !== JobLiveAggregateStatus.ProcessingDone)
      this.jobLiveStatusMonitor.start();

    this.isLoading = false;

    this.playerGroup?.forEach((player: PlayerState) => {
      if (job.isPlaylistActive(player.playlistType!))
        player.invoke('autoplay');
    });
  }

  @action
  init() {
    const { job } = this;

    if (!job)
      return;

    this.playerGroup = Object.values(LiveStreamActivePlaylist).map((value: LiveStreamActivePlaylist) => {
      const media = new MediaInfo({ ...job.media, liveStreamUrl: job?.getPlaylistSource(value) })

      const player = new PlayerState(this.store, {
        jobId: () => this.jobId,
        media: () => media,
        playlistType: () => value,
        allowShare: false,
        suppressLiveReactions: true
      });

      return player;
    });

    this.load();
  }

  @action
  async changeLiveSource(liveStreamActivePlaylist: LiveStreamActivePlaylist) {

    if (!this.jobId) {
      notifyError(this, 'Could not load job');
      console.error(this, 'No job id provided');
      return;
    }

    if (!this.job?.isPlaylistActive(liveStreamActivePlaylist)) {
      this.dispatch('openLiveNotStreamingWindow', {
        jobId: this.jobId,
        liveStreamActivePlaylist
      });

      this.store.liveNotStreamingWindow.listen(
        this.liveNotStreamingWindowListener);
      return;
    }

    this.isLoading = true;

    const updateLiveSourceInput: LiveJobSourceInput = {
      liveStreamActivePlaylist
    }

    const [, err] = await this.store.jobManager.apiUpdateLiveJobSource(this.jobId, updateLiveSourceInput);

    if (err) {
      notifyError(this, 'There was an error changing the live feed');
      this.isLoading = false;
      return;
    }

    await this.load();
    this.isLoading = false;
    notifySuccess(this, `Stream was updated.`);
  }

  @action
  private abortLoading() {
    const lastAbortCtrl = this.abortController;
    if (lastAbortCtrl)
      lastAbortCtrl.abort();

    this.jobLiveStatusMonitor.cancel();
  }

  private jobLiveStatusMonitorListener = async (msg: Message<JobLiveStatusMonitor>) => {
    const { type, payload } = msg;
    const { job, playerGroup } = this;

    if (!job || !playerGroup)
      return;

    switch (type) {
      case 'Change':
        const status = payload?.status;
        const hasUrl = payload?.hasUrl;
        const liveStreamUrlChanged = payload?.liveStreamUrlChanged;
        const playlistChanged = payload?.playlistChanged;

        switch (status) {
          case JobLiveAggregateStatus.Streaming:
            if (!hasUrl) { // prepare loading the player only when url is provided
              this.jobLiveStatusMonitor.start();
              return;
            }

            for (const player of playerGroup) {
              if (player.isPlaying)
                player.invoke('enterPause');

              await this.load(); // it triggers a jobLiveStatusMonitor start()

              if (liveStreamUrlChanged || playlistChanged) {
                player.invoke('reattach');
              }

              if (player.wasPlayingBeforeEnterPause)
                player.invoke('exitPause');
            }

            break;

          default: // waiting or ended (ingest processing, done, failed)
            if (!job) return;

            const [res, err] = await this.store.apiFetchJob(job.id);

            if (!res || err)
              console.warn('Job fetch failed at:', status)

            if (job.liveAggregateStatus !== JobLiveAggregateStatus.ProcessingDone) {
              this.jobLiveStatusMonitor.start();
            }
            break;
        }
        break;
    }
  }

  @action
  close() {
    this.emit('close');
    this.changedOnce = false;
    this.showFeedPlayer = false;
    this.playlistType = null;
    this.abortLoading();
    this.jobLiveStatusMonitor.reset();
    this.dispatch('Overlays', 'closeWindow');
  }
}
