import './searchBar.scss';

import { observer } from 'mobx-react-lite';
import React, { FormEvent, SyntheticEvent, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Highlight, Metadata } from '@clipr/lib';

import { BackRoutes, RouteParams, Routes } from '../../routes';
import { MomentResultItem } from '../../pages/searchResultsPage/momentResultsCatalog';
import { sanitizeHtml } from '../../pages/searchResultsPage/util';
import { useStore } from '../../store/storeHooks';
import { SearchTextInputControlled } from '../input';
import { JobModel } from '../../entities';
import { SearchResultsState } from '../../pages/searchResultsPage';
import { SearchBarState } from './searchBarState';
import { ChatGptLogoSVGIcon } from '../svg';

enum SearchMode {
  Initialized = 'Initialized',
  Ask = 'Ask',
  Suggestions = 'Suggestions'
}

type SuggestionProps = {
  model: MomentResultItem;
  teamId?: string | null;
};

type Props = {
  size: string | null;
  defaultValue?: string;
  teamId?: string | null;
  metadata?: Partial<Metadata> | null;
  tags?: string[] | null;
  replaceRouteOnSubmit?: boolean;
};

const DefaultSearchBarProps: Props = {
  size: 'small',
}

export const SearchBar = observer((
  props: Props = DefaultSearchBarProps
) => {
  const { teamId, metadata, tags, replaceRouteOnSubmit } = props;

  const store = useStore();
  const searchBarState = store.searchBar;
  const state = searchBarState.state;
  
  const { setTeamId, searchMode, cliprGptEnabled } = searchBarState;

  useEffect(() => {
    // TODO: this badly needs a refactoring
    setTeamId(teamId ?? null);

    if (searchMode === SearchMode.Suggestions || !cliprGptEnabled) {
      const { jobCatalog } = state as SearchResultsState;
      jobCatalog.teamId = teamId ?? null;
      jobCatalog.metadataFilter = metadata ?? null;
      jobCatalog.tagsFilter = tags ?? null;
  
      const { momentCatalog } = state as SearchResultsState;;
      momentCatalog.teamId = teamId ?? null;
      momentCatalog.metadataFilter = metadata ?? null;
      momentCatalog.tagsFilter = tags ?? null;
  
      const { topicCatalog } = state as SearchResultsState;;
      topicCatalog.teamId = teamId ?? null;
      topicCatalog.metadataFilter = metadata ?? null;
      topicCatalog.tagsFilter = tags ?? null;
    }

  }, [ teamId, state, metadata, tags, searchMode, cliprGptEnabled, setTeamId ]);

  return (
    <>
      { searchMode === SearchMode.Initialized && cliprGptEnabled &&
      <OptionsSearchBar state={searchBarState} /> }
      {(searchMode === SearchMode.Suggestions || !cliprGptEnabled) &&
      <SuggestionSearchBar 
        cliprGptEnabled={cliprGptEnabled}
        state={searchBarState.state as SearchResultsState}
        teamId={teamId}
        onClear={(evt) => searchBarState.onClear(evt, replaceRouteOnSubmit)}
        onSubmit={(evt) => searchBarState.onSubmit(evt, replaceRouteOnSubmit)} /> }
    </>
  );
}
);

type SuggestionSearchBarProps = {
  state: SearchResultsState;
  cliprGptEnabled?: boolean;
  teamId?: string | null;
  onSubmit: (evt: FormEvent, replaceRouteOnSubmit: boolean) => void;
  onClear: (evt: SyntheticEvent) => void;
}

const SuggestionSearchBar = observer(({ state, teamId, cliprGptEnabled, onSubmit, onClear } : SuggestionSearchBarProps) => {
  return (
    <form 
    className="search-form" 
    onSubmit={(evt) => onSubmit(evt, false)}>
    <div className="search-bar">
      <SearchTextInputControlled
        model={state.model}
        alwaysShowClear={!!cliprGptEnabled}
        persistentFeedback={false}
        id="search"
        name="search"
        type="text"
        autoComplete="off"
        placeholder="Search"
        showFeedback={false}
        onClear={onClear}
      />
    </div>
    {state.showSuggestions && (
      <div className="search-results">
        {(state as SearchResultsState).jobCatalog.suggestions.map((model: JobModel) => (
          <Link to={BackRoutes[Routes.userVideo()].route({ id: model.id, teamId: teamId as string | undefined })} key={model.id}>
            <div className="suggestion">
              Video:{' '}
              {model.highlights
                ? sanitizeHtml(model.highlights[0].snippets[0])
                : model.title}
            </div>
          </Link>
        ))}
        {state.topicCatalog.suggestions.map((i: MomentResultItem) => (
          <Suggestion model={i} key={i.id} teamId={teamId} />
        ))}
        {state.momentCatalog.suggestions.map((i: MomentResultItem) => (
          <Suggestion model={i} key={i.id} teamId={teamId} />
        ))}
      </div>
    )}
  </form>
  )
});

const Suggestion = (props: SuggestionProps) => {
  // TODO: replace with 'typeLabel' if model can be set created using a MomentModel instance
  const type = props.model.momentType || props.model.clipType;
  const { teamId } = props;
  const routeParams: RouteParams = { id: props.model.jobId, momentId: props.model.id, teamId: teamId as string | undefined };
  return (
    <Link
      to={BackRoutes[Routes.userVideo()].route(routeParams)}>
      <div className="suggestion">
        {props.model.highlights
          ?.sort(compareFields)
          .map(({ field, snippets }: Highlight, index: number) => (
            <div key={index + props.model.id}>
              {type === 'Topic' ? (
                <TopicSuggestion field={field} snippets={snippets} />
              ) : (
                <MomentSuggestion
                  field={field}
                  snippets={snippets}
                  type={type} />
              )}
            </div>
          ))}
      </div>
    </Link>
  );
};

const TopicSuggestion = ({ field, snippets }: Highlight) => (
  <>
    {(field === 'name' || field === 'description') && ( // TODO: check
      <div className="snippet">Topic: “{sanitizeHtml(snippets[0])}”</div>
    )}
    {field === 'keywords' && (
      <div className="snippet">
        <small>Keywords: {sanitizeHtml(snippets[0])}</small>
      </div>
    )}
  </>
);

const MomentSuggestion = ({
  snippets,
  type,
}: Highlight & {
  type: string;
}) => (
  <>
    <div className="snippet">Moment: {type}</div>
    <div className="snippet">
      <small>“{sanitizeHtml(snippets[0])}“</small>
    </div>
  </>
);

type OptionsBarProps = {
  state: SearchBarState
}

const OptionsSearchBar = observer(({ state } : OptionsBarProps) => {

  return (
    <div className="search-form">
     <div className="search-bar">
      <SearchTextInputControlled
        model={state.model}
        persistentFeedback={false}
        id="search"
        name="search"
        type="text"
        autoComplete="off"
        placeholder="Search Everything, Ask Anything"
        showFeedback={false}
        icon={<ChatGptLogoSVGIcon/>}
        onClear={state.onClear} />
      </div>
      {state.showOptions && (
      <div className="search-results">
        <div 
          onClick={(evt) => state.handleOnSearchClick(evt)}
          className="option">
          Search "{state.model.value}"
        </div>
        <div 
          onClick={(evt) => state.handleOnAskClick(evt)}
          className="option">
            {`Ask "${state.model.value}" (Some videos may be processing)`}
        </div>
        <div 
          onClick={(evt) => state.handleOnAskClick(evt, 'What is Clipr?')}
          className="option">
          What is Clipr?
        </div>
      </div>)}
    </div>
  )
})

const compareFields = (a: any, b: any) => {
  if (a.field === 'name' || a.field === 'description') {
    // TODO: check
    return -1;
  }
  return 0;
};
