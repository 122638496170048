import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { DateTime } from 'luxon';
import { ApiResponse, ApiVariables, ApiRequestOptions } from '../../../api/apiSchema';
import { AsyncResult, Result } from '../../../core';
import { BindingProps, StoreNode } from '../../../store';
import { IChartDataSource } from '../chartSchema';
import { Store } from '../../../store/store';
import { error, Error } from '../../../core/error';
import { parseKeenJsonResult } from '../utils/parseKeenJsonResult';
import { ApiError } from '../../../api/apiError';
import { sumSeries } from '../utils/sumSeries';
import {
  PerformanceData,
  PerformanceChartParams,
  PerformanceSeriesData,
} from './performanceChartSchema';
import { Datum } from '../xySeriesChart';

type Props = BindingProps<{
  params: PerformanceChartParams;
  suppressFetchOnParamsChange?: boolean;
}>;

export class PerformanceDataSource extends StoreNode
  implements IChartDataSource<PerformanceSeriesData> {
  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.onPropChange('params', this.handleParamsChange);
  }

  // #region Props
  @computed get params(): PerformanceChartParams | null {
    return this.getResolvedProp('params') ?? null;
  }
  set params(val: PerformanceChartParams | null) {
    this.setProp('params', val);
  }

  @computed get suppressFetchOnParamsChange(): boolean | null {
    return this.getResolvedProp('suppressFetchOnParamsChange') ?? null;
  }
  set suppressFetchOnParamsChange(val: boolean | null) {
    this.setProp('suppressFetchOnParamsChange', val);
  }
  // #endregion

  // #region Handlers
  private handleParamsChange = () => {
    //this.fetch();
  };
  // #endregion

  readonly data = observable.array<PerformanceSeriesData>([], { deep: false });

  private abortController: AbortController | null = null;

  @observable isFetching = false;
  @observable error: Error | null = null;

  @action
  reset() {
    this.abort();

    this.isFetching = false;
    this.error = null;
    this.abortController = null;

    this.data.clear();
  }

  @action
  async fetch(): AsyncResult {
    this.reset();

    this.isFetching = true;

    const abortCtrl = new AbortController();
    this.abortController = abortCtrl;

    const { params } = this;
    if (!params) return [null];

    const startDateArg = params.startDate?.toISO();
    const endDateArg = params.endDate?.toISO();
    const timezoneArg = params.timezone ?? undefined;
    const teamIdArg = params.teamId ?? undefined;

    if (!startDateArg || !endDateArg)
      return [null, error('Unknown', 'Invalid parameters')];

    const reqArgs: ApiVariables<'keenBatch'> = {
      args: {
        start: startDateArg,
        end: endDateArg,
        timezone: timezoneArg,
        teamId: teamIdArg,
      },
    };

    const api = this.store.api;
    const reqOpts: ApiRequestOptions = {
      signal: abortCtrl.signal,
    };

    let reqPromise = api.runQuery('keen_getPerformance', reqArgs, reqOpts);

    const [res, err] = await reqPromise;
    if (err)
      // @ts-ignore TODO: make ApiError extend our own Error class
      return this.handleError(err);

    const [data, insertErr] = this.insert(res!);
    if (insertErr) return this.handleError(insertErr);

    runInAction(() => {
      this.isFetching = false;
    });

    return [data!];
  }

  cancel(): void {
    this.abort();
  }

  @observable isBatchRequesting = false;

  notifyBatchRequestStarted() {
    this.isBatchRequesting = true;
  }
  notifyBatchRequestCompleted() {
    this.isBatchRequesting = false;
  }

  @action
  insert(
    res: ApiResponse<'keen_getPerformance'>
  ): Result<PerformanceSeriesData[]> {
    const apiPlaysResultStr = res.keenBatch.plays?.result;
    const apiVideoSharesResultStr = res.keenBatch.videoShares?.result;
    const apiBookmarksResultStr = res.keenBatch.bookmarks?.result;
    const apiTotalReactionsResultStr = res.keenBatch.totalDailyReactions?.result;

    const [apiPlaysResult, parsePlaysErr] = parseKeenJsonResult<Datum[]>(apiPlaysResultStr);
    const [apiVideoSharesResult, parseVideoSharesErr] = parseKeenJsonResult<Datum[]>(apiVideoSharesResultStr);
    const [apiBookmarksResult, parseBookmarksErr] = parseKeenJsonResult<Datum[]>(apiBookmarksResultStr);
    const [apiTotalReactionsResult, parseTotalReactionsErr] = parseKeenJsonResult<Datum[]>(apiTotalReactionsResultStr);
    const totalEngagementRessult = sumSeries({
      apiVideoSharesResult,
      apiBookmarksResult,
      apiTotalReactionsResult,
    });

    const data = this.handleApiData({
      apiPlaysResult,
      apiVideoSharesResult,
      apiBookmarksResult,
      apiTotalReactionsResult,
      totalEngagementRessult,
    });

    if (
      parsePlaysErr ||
      parseVideoSharesErr ||
      parseBookmarksErr ||
      parseTotalReactionsErr
    )
      return this.handleError(
        parsePlaysErr ||
        parseVideoSharesErr ||
        parseBookmarksErr ||
        parseTotalReactionsErr
      );

    this.data.replace(data ?? []);
    return [data ?? []];
  }

  private abort() {
    const lastAbortCtrl = this.abortController;

    if (lastAbortCtrl) 
      lastAbortCtrl.abort();

    this.abortController = null;
  }

  @action
  private handleError(err?: Error | string): Result {
    if (!err) 
      err = new Error();

    if (typeof err === 'string') 
      err = new Error('Unknown', err);

    if (err instanceof ApiError && err.type === 'RequestAborted') {
      return [null, err];
    } else {
      console.error(`An error occured in PerformanceDataSource: `, err);

      this.isFetching = false;
      this.abort();
      this.error = err;

      return [null, err];
    }
  }

  @action
  private handleApiResponse(data: any): PerformanceData {
    return {
      date: DateTime.fromISO(data?.timeframe?.start)
        .setLocale('en-US')
        .toLocaleString(DateTime.DATE_SHORT),
      value: data?.value ?? 0,
    };
  }

  @action
  handleApiData({
    apiPlaysResult,
    apiVideoSharesResult,
    apiBookmarksResult,
    apiTotalReactionsResult,
    totalEngagementRessult,
  }: any): PerformanceSeriesData[] {
    return [
      {
        dataKey: 'Plays',
        data:
          apiPlaysResult?.map((data: any) => this.handleApiResponse(data)) ??
          [],
      },
      {
        dataKey: 'Shares',
        data:
          apiVideoSharesResult?.map((data: any) =>
            this.handleApiResponse(data)
          ) ?? [],
      },
      {
        dataKey: 'Bookmarks',
        data:
          apiBookmarksResult?.map((data: any) =>
            this.handleApiResponse(data)
          ) ?? [],
      },
      {
        dataKey: 'Reactions',
        data:
          apiTotalReactionsResult?.map((data: any) =>
            this.handleApiResponse(data)
          ) ?? [],
      },
      {
        dataKey: 'Total Engagement',
        data:
          totalEngagementRessult?.map((data: any) =>
            this.handleApiResponse(data)
          ) ?? [],
      },
    ];
  }
}
