import { FormEvent } from 'react';
import { action, makeObservable, observable, runInAction, computed } from 'mobx';
import { GalleryDisplay, Industry, TeamUpdateInput, Theme } from '@clipr/lib';
import { UpdateTeamMemberInput } from '@clipr/lib/dist/generated/graphql';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { Maybe } from '../../core';
import { Team } from '../../entities/team';
import { TeamMember } from '../../entities/teamMember';
import { TeamInvitation } from '../../entities/teamInvitation';
import {
  input,
  InputState,
  inputGroup,
  InputGroupState,
} from '../../components/input';
import { notifyError, notifySuccess } from '../../services/notifications';
import { ApiUploadRequestParams, getApiTeamError } from '../../api';
import { TeamMemberCatalogSource } from '../../entities/teamMemberCatalogSource';
import { CatalogState } from '../../components/layout/catalogState';
import { RoleItem } from '../../components/createTeamForm/createTeamFormState';
import { Error, pageError } from '../../core/error';
import { Routes } from '../../routes';
import { getJobIndustryLabel, JobIndustry } from '../../entities/job/JobIndustry';
import { TeamMetadataState } from '../../components/teams/teamMetadataState';
import { TeamEnrichmentLevelState } from '../../components/teams/teamEnrichmentLevelState';
import { PublicTeamFormState } from '../../components/teams/publicTeamFormState';
import { MakeTeamPublicWindowState } from '../../components/teamSettings';
import { TeamInviteCatalogSource } from '../../entities/teamInviteCatalogSource';
import { BulkInvitesWindowState } from '../../components/teams/bulkInvitesWindowState';
import { AdsSettingsSectionState } from '../../components/teams/adsSettingsSectionState';
import { sanitizeObject } from '../../components/utils';
import { mapFromValueToDisplay } from '../../components/layout/util';
import { TeamDictionaryState } from '../../components/teams/teamDictionaryState';
import { TeamDictionaryWindowState } from '../../components/teams/teamDictionaryWindowState';
import { GenerativeAiFormState } from '../../components/teams/generativeAiFormState';
import { TogglePublicSafetyWindowState } from '../../components/teamSettings/togglePublicSafetyWindowState';

const FILE_SIZE_IN_MB = 2;
const PHOTO_MAX_SIZE = FILE_SIZE_IN_MB * 1024 * 1024;
const FILE_TYPES = ['image/jpeg', 'image/png'];

export const roleSelectorItems = [
  // { value: 'Owner', label: 'Owner', description: 'View, edit, manage users, and manage billing.' },
  { value: 'Admin', label: 'Admin', description: 'Upload, view, edit, manage library users and videos.' },
  { value: 'Trainer', label: 'Trainer', description: 'Upload, view, edit, and manage library videos.' },
  { value: 'Member', label: 'Member', description: 'Upload and edit library videos.' },
  { value: 'Viewer', label: 'Viewer', description: 'View library videos.' },
  { value: 'Remove Access', label: 'Remove Access', description: '', className: 'red' }
];

type Props = {
  teamId: string;
}

type Tab = 'Active' | 'Pending';

export type RoleProps = 'Admin' | 'Member' | 'Viewer' | 'Remove Access';

export class TeamSettingsPageState
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.teamPrivacy = input(this, {
      name: 'teamPrivacy',
      selectorItems: [
        { value: 'public', label: 'Shareable', description: 'Visible to all' },
        { value: 'private', label: 'Private', description: 'Visible to members of this organization' }
      ]
    });
    this.teamName = input(this, {
      name: 'teamName',
      isRequired: true,
      placeholder: 'Library Name',
    });
    this.teamDescription = input(this, {
      name: 'teamDescription',
      isRequired: false,
      multiline: true,
      placeholder: 'Library Description',
    });
    this.teamIndustry = input(this, {
      name: 'teamIndustry',
      selectorItems: JobIndustry.map(item =>
        ({ value: item, label: getJobIndustryLabel(item) })),
      isRequired: false,
      placeholder: 'Field of activity',
    });
    this.mainForm = inputGroup(this, {
      name: 'mainForm',
      inputs: () => {
        return [
          this.teamName,
          this.teamDescription,
          this.teamIndustry
        ]
      }
    });

    this.advancedDetailsForm = inputGroup(this, {
      name: 'advancedDetailsForm',
      inputs: () => {
        return [
          this.teamMetadata.form
        ]
      }
    });

    this.enrichmentLevelForm = inputGroup(this, {
      name: 'enrichmentLevelForm',
      inputs: () => {
        return [
          this.teamEnrichmentLevel.form
        ]
      }
    });

    this.adsUrlsForm = inputGroup(this, {
      name: 'adsUrlsForm',
      inputs: () => {
        return [
          this.adsSettingsSectionState.form
        ]
      }
    });

    this.form = inputGroup(this, {
      name: 'team',
      inputs: () => {
        return [
          this.mainForm,
          this.advancedDetailsForm,
          this.enrichmentLevelForm,
          this.publicTeamForm.form,
          this.generativeAiForm.form,
          this.adsUrlsForm
        ]
      }
    });

    this.teamInviteCatalogSource = new TeamInviteCatalogSource(this.store, {
      teamId: () => this.team?.id,
      pageSize: 20
    });
    this.teamInviteCatalog = new CatalogState(this.store, {
      syncStatus: () => this.teamInviteCatalogSource.syncStatus,
      isEndOfList: () => this.teamInviteCatalogSource.isEndOfList
    });
    this.teamInviteCatalog.listen((msg) => {
      switch (msg.type) {
        case 'scrollToBottom':
        case 'loadMore':
          if (this.team)
            this.fetchTeamInvitations(true);
          break;
      }
    });

    this.teamMemberCatalogSource = new TeamMemberCatalogSource(this.store, {
      teamId: () => this.team?.id,
      pageSize: 20
    });
    this.teamMemberCatalog = new CatalogState(this.store, {
      syncStatus: () => this.teamMemberCatalogSource.syncStatus,
      isEndOfList: () => this.teamMemberCatalogSource.isEndOfList
    });
    this.teamMemberCatalog.listen((msg) => {
      switch (msg.type) {
        case 'scrollToBottom':
        case 'loadMore':
          if (this.team)
            this.fetchTeamMembers(true);
          break;
      }
    });

    this.teamMetadata = new TeamMetadataState(this.store, {
      teamId: () => this.team?.id,
    });

    this.teamEnrichmentLevel = new TeamEnrichmentLevelState(this.store, {
      teamId: () => this.team?.id,
    });

    this.publicTeamForm = new PublicTeamFormState(this.store, {
      teamId: () => this.team?.id,
    });

    this.generativeAiForm = new GenerativeAiFormState(this.store, {
      teamId: () => this.team?.id,
      refreshTeam: () => this.refreshTeam()
    });

    this.adsSettingsSectionState = new AdsSettingsSectionState(this.store, {
      teamId: () => this.team?.id
    });

    this.teamDictionary = new TeamDictionaryState(this.store, {
      teamId: () => this.team?.id
    });

     window.onbeforeunload = (evt: BeforeUnloadEvent) => {
      if (this.form.isDirty) {
        evt.preventDefault();
        evt.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
      }
    }

    const { history } = this.store.routingService;
    history.block(() => {
      if (this.form.isDirty) {
        return 'You have unsaved changes. Are you sure you want to leave this page?';
      }
    });
  }

  readonly teamMemberCatalogSource!: TeamMemberCatalogSource;
  readonly teamInviteCatalogSource!: TeamInviteCatalogSource;
  readonly teamInviteCatalog!: CatalogState;
  readonly teamMemberCatalog!: CatalogState;

  readonly teamName: InputState;
  readonly mainForm: InputGroupState;
  readonly form: InputGroupState;
  readonly advancedDetailsForm: InputGroupState;
  readonly enrichmentLevelForm: InputGroupState;
  readonly adsUrlsForm: InputGroupState;
  readonly teamDescription: InputState;
  readonly teamIndustry: InputState;
  readonly teamPrivacy: InputState;

  readonly teamMetadata: TeamMetadataState;
  readonly teamEnrichmentLevel: TeamEnrichmentLevelState;
  readonly publicTeamForm: PublicTeamFormState;
  readonly generativeAiForm: GenerativeAiFormState;
  readonly adsSettingsSectionState: AdsSettingsSectionState;
  readonly teamDictionary: TeamDictionaryState;

  @observable activeTab: Tab = 'Active';
  @observable team: Maybe<Team>;
  @observable isLoading: boolean = true;
  @observable error: Error | null = null;

  @observable isAdvancedSectionExpanded: boolean = false;
  @observable isChatGptSectionExpanded: boolean = false;
  @observable isDictionaryExpanded: boolean = false;
  @observable isVideoEnrichmentLevelExpanded: boolean = false;
  @observable isPublicTeamFormExpanded: boolean = false;
  @observable isAdsSettingsExpanded: boolean = false;

  @computed
  get isUserAdminOrTrainer() {
    return this.store.user?.isAdmin || this.store.user?.isTrainer;
  }

  @computed
  get isWidgetMode() {
    return this.store.widgetService.isWidgetMode;
  }

  @computed
  get roleItems(): RoleItem[] {
    return this.store?.user?.hasPermission('AddTeamTrainer') ?
      roleSelectorItems :
      roleSelectorItems.filter(role => role.value !== 'Trainer');
  }

  private bulkInvitesListener = (msg: Message<BulkInvitesWindowState>) => {
    switch (msg.type) {
      case 'bulkInvitesWindow:invitesSent':
        this.refreshList();
        break;
    }
  }

  private teamDictionaryUpdatesListener = (msg: Message<TeamDictionaryWindowState>) => {
    switch (msg.type) {
      case 'teamDictionaryWindow:dictionaryUpdated':
        this.refreshTeam();
        break;
    }
  }

  @action
  initForm() {
    if (!this.team) return;
    this.teamName.loadValue(this.team.name);
    this.teamDescription.loadValue(this.team.description);
    this.teamIndustry.loadValue(this.team.industry);

    this.teamMetadata.init();
    this.teamEnrichmentLevel.init();
    this.publicTeamForm.init();
    this.generativeAiForm.init();
    this.adsSettingsSectionState.init();
  }

  @action
  refreshForm() {
    this.form.clear();
    this.teamMetadata.reset();
    this.teamEnrichmentLevel.reset();
    this.publicTeamForm.reset();
    this.generativeAiForm.reset();
    this.adsSettingsSectionState.reset();
    this.advancedDetailsForm.clear();
  }

  @action
  async pageMounted({ teamId }: Props) {
    this.store.bulkInvitesWindow.listen(
      this.bulkInvitesListener);

    this.store.teamDictionaryWindow.listen(
      this.teamDictionaryUpdatesListener);
  
    this.teamInviteCatalogSource.setSortFieldValue('role', 'asc', false);
    this.teamMemberCatalogSource.setSortFieldValue('role', 'asc', false);

    this.isLoading = true;
    this.team = this.store.teamManager.getTeam(teamId);
    if (!this.team) {
      const [team, teamErr] = await this.store.teamManager.apiFetchTeam({ id: teamId });

      if (teamErr || !team) {
        const decodedError = getApiTeamError(teamErr);
        return this.setError(decodedError);
      }

      runInAction(() => {
        this.team = team;
      });
    }

    runInAction(() => {
      if (!this.team?.userPermissions?.includes('EditTeam'))
        return this.setError(new Error(`Unauthorized`));
    });

    await this.refreshList();
    runInAction(() => {
      this.isLoading = false;
      this.initForm();
    });
  }

  @action
  async changeActiveTab(tab: Tab) {
    this.activeTab === 'Active' ?
      this.teamMemberCatalogSource.resetSearchField() :
      this.teamInviteCatalogSource.resetSearchField();

    this.activeTab = tab;
    await this.refreshList();
  }

  @action
  pageUnmounted() {
    this.team = null;
    this.teamInviteCatalogSource.reset();
    this.teamMemberCatalogSource.reset();

    this.store.bulkInvitesWindow.unlisten(
      this.bulkInvitesListener);
    this.store.teamDictionaryWindow.unlisten(
      this.teamDictionaryUpdatesListener);
    window.onbeforeunload = null;

    this.isAdvancedSectionExpanded = false;
    this.isChatGptSectionExpanded = false;
    this.isVideoEnrichmentLevelExpanded = false;
    this.isPublicTeamFormExpanded = false;
    this.isAdsSettingsExpanded = false;

    this.refreshForm();
  }

  @action
  openTeamInviteWindow() {
    this.store.teamMembersWindow.openTeamEdit(this.team?.id || '', 'invitations');
    const listener = async (msg: any) => {
      if (msg.type === 'teamMembersWindow:invitesSent') {
        await this.refreshList();
        this.store.teamMembersWindow.unlisten(listener);
      }
    }
    this.store.teamMembersWindow.listen(listener);
  }

  @action
  toggleAdvancedVideoDetails() {
    this.isAdvancedSectionExpanded = !this.isAdvancedSectionExpanded;
  }

  @action
  toggleChatGptSection() {
    this.isChatGptSectionExpanded = !this.isChatGptSectionExpanded;
  }

  @action
  toggleDictionary() {
    this.isDictionaryExpanded = !this.isDictionaryExpanded;
  }

  @action
  toggleVideoEnrichmentLevel() {
    this.isVideoEnrichmentLevelExpanded = !this.isVideoEnrichmentLevelExpanded;
  }

  @action
  togglePublicTeamForm() {
    this.isPublicTeamFormExpanded = !this.isPublicTeamFormExpanded;
  }

  @action
  toggleAdsSettings() {
    this.isAdsSettingsExpanded = !this.isAdsSettingsExpanded;
  }

  @action
  async setInvitedMemberRole(teamInvitation: Maybe<TeamInvitation>, role: RoleProps) {
    if (!this.team || !teamInvitation) return;

    if (role === 'Remove Access') {
      this.store.removeAccessPopup.open({
        teamId: this.team.id,
        email: teamInvitation?.email ?? undefined,
      });

      const listener = async (msg: any) => {
        if (msg.type === 'removedAccess') {
          await this.refreshList();
        }
        this.store.removeAccessPopup.unlisten(listener);
      }
      this.store.removeAccessPopup.listen(listener);
    } else {
      const [, err] = await this.store.apiService.updateTeamInvitation({
        teamId: this.team.id,
        email: teamInvitation?.email!,
        role: role
      });
      if (err) {
        this.handleError('Could not update the invitation role.');
        return;
      }
      notifySuccess(this, 'Invitation role updated.');
      await this.refreshList();
    }
  }


  @action
  async setTeamMemberRole(teamMember: TeamMember, role: RoleProps) {
    if (!this.team || !teamMember) return;

    if (role === 'Remove Access') {
      this.store.removeAccessPopup.open({
        teamId: this.team.id,
        email: teamMember.user?.email ?? undefined,
        userId: teamMember.userId || undefined
      });

      const listener = async (msg: any) => {
        if (msg.type === 'removedAccess') {
          await this.refreshList();
        }
        this.store.removeAccessPopup.unlisten(listener);
      }
      this.store.removeAccessPopup.listen(listener);
    } else {
      const [, err] = await this.store.apiService.updateTeamMemberRole({
        teamId: this.team.id,
        userId: teamMember.user!.id,
        role: role
      });
      if (err) {
        this.handleError('Could not update the user role.');
        return;
      }
      notifySuccess(this, 'User role updated.');
      await this.refreshList();
    }
  }

  @action
  async submit() {
    this.isLoading = true;
    this.form.handleSubmit();

    if (this.form.error) {
      this.handleSubmitReject(`Fix the validation errors before submitting.`);
      this.isLoading = false;
      return;
    }

    const name = this.teamName.value || '';

    const [, errTeams] = await this.store.teamManager.apiEnsureTeams();
    if (errTeams) {
      this.handleSubmitReject(`Could not fetch teams.`);
      return;
    }

    const existingTeam = this.store.teamManager.maybeGetTeamByName(name);
    if (existingTeam && existingTeam.id !== this.team?.id) {
      this.handleSubmitReject('Team name already exists. Please try another!');
      return;
    }

    const vars: { args: TeamUpdateInput } = {
      args: {
        teamId: this.team?.id!,
        name: name,
        description: this.teamDescription.value || '',
        sendVideoProcessedNotificationToAllMembers: Boolean(this.generativeAiForm.chatGptEmailNotifications.value),
        generativeAiSettings: (this.generativeAiForm.generativeAiProvider ||  this.team?.generativeAiProvider) ?
        {
          provider: (this.generativeAiForm.generativeAiProvider ?? this.team?.generativeAiProvider)!,
          summarizationEnabled: this.team?.chatGptSummaryEnabled ?? false,
          actionItemsExtractionEnabled: this.team?.actionItemsExtractionEnabled ?? false,
          cliprGptEnabled: this.team?.cliprGptEnabled ?? false,
        }: undefined,
        industry: this.teamIndustry.value as Industry || undefined,
        jobFeatures: this.teamEnrichmentLevel.export() || undefined,
        displayOptions: {
          galleryDisplay: mapFromValueToDisplay(this.publicTeamForm.view.value),
          theme:
            this.publicTeamForm.theme.value === 'lightTheme' ?
              Theme.Light :
              Theme.Dark
        }
      }
    };

    const publicTeamData = this.publicTeamForm.export();
    const metadataFields = this.teamMetadata.export();
    const adTagUrls = this.adsSettingsSectionState.export();

    Object.assign(vars.args, publicTeamData, { metadataFields }, { adTagUrls });

    // cover image upload
    const coverData = await this.publicTeamForm.getTeamCoverUploadToken();
    if (coverData)
      Object.assign(vars.args, coverData);

    // logo image upload
    const logoData = await this.publicTeamForm.getTeamLogoUploadToken();
    if (logoData)
      Object.assign(vars.args, logoData);

    // Update team request
    const [, err] = await this.store.apiService.updateTeam(vars);
    if (err) {
      this.handleSubmitReject(`Could not update the library '${name}'. Please try again.`);
      return;
    }

    await this.fetchTeam();

    runInAction(() => {
      this.handleSubmitSuccess(`Library '${name}' updated successfully`);
    })
  }

  @action
  private handleSubmitSuccess(msg: string) {
    this.isLoading = false;
    this.refreshForm();
    this.initForm();
    this.form.handleSubmitResolve();
    notifySuccess(this, msg);
  }

  @action
  private handleSubmitReject(msg: string) {
    this.isLoading = false;
    this.form.handleSubmitReject();
    notifyError(this, msg);
  }

  @action
  private handleError(msg: string) {
    this.isLoading = false;
    notifyError(this, msg);
  }

  @action
  async handleFileInput(e: FormEvent<HTMLInputElement>) {
    const target = e.currentTarget;
    const fileList: FileList | null = target.files;
    if (fileList && fileList.length > 0) {
      const [file] = fileList;

      if (file.size >= PHOTO_MAX_SIZE) {
        this.handleUploadError(target, `Photo size should be smaller than ${FILE_SIZE_IN_MB}Mb.`);
        return;
      }
      if (!FILE_TYPES.includes(file.type)) {
        this.handleUploadError(target, 'The accepted formats are jpeg and png.');
        return;
      }

      this.isLoading = true;
      // Get the upload URL
      const [res, err] = await this.store.apiService.getAvatarUploadUrl({ teamId: this.team?.id!, fileName: file.name });

      if (err) {
        runInAction(() => this.handleUploadError(target, 'Could not upload.'));
        return;
      }

      if (res) {
        const params = res.getAvatarUploadUrl;
        const uploadParams: ApiUploadRequestParams = {
          file,
          url: params.uploadUrl,
          data: params.fields
        }

        // Start the upload request
        const uploadRequest = this.store.apiService.uploadRequest(uploadParams);
        const [, uploadErr] = await uploadRequest.start();

        if (uploadErr) {
          runInAction(() => this.handleUploadError(target, 'Could not upload.'));
          return;
        }

        // Update the profile picture if the upload was successful
        const [, updateError] = await this.store.apiService.updateTeam({
          args: {
            teamId: this.team?.id!,
            avatarToken: params.uploadToken,
          },
        });
        if (updateError) {
          runInAction(() => this.handleUploadError(target, 'Could not update the profile picture.'));
          return;
        }

        const [fetchTeamErr] = await this.fetchTeam();

        if (fetchTeamErr) {
          return this.handleUploadError(target, 'Could not fetch team.');
        }
        runInAction(() => {
          notifySuccess(this, 'Your photo has been uploaded.');
          this.isLoading = false;
        })
      }
    }
  }

  @action
  async fetchTeam(): Promise<any[]> {
    if (!this.team) return ['Could not fetch team.'];
    const [team, err] = await this.store.teamManager.apiFetchTeam({ id: this.team?.id! });
    if (team) {
      await this.refreshList();
      runInAction(() => {
        this.team = team;
      })
    }
    return [err];
  }

  @action
  async refreshList() {
    this.activeTab === 'Active' ?
      await this.fetchTeamMembers() :
      await this.fetchTeamInvitations();
  }

  @action
  async fetchTeamMembers(more: boolean = false) {
    const [, err] = !more ?
      await this.teamMemberCatalogSource.fetch() :
      await this.teamMemberCatalogSource.fetchMore();
    if (err) {
      notifyError(this, 'Could not fetch team members.');
      return [null];
    }
  }

  @action
  async fetchTeamInvitations(more: boolean = false) {
    const [, err] = !more ?
      await this.teamInviteCatalogSource.fetch() :
      await this.teamInviteCatalogSource.fetchMore();
    if (err) {
      notifyError(this, 'Could not fetch team invites.');
      return [null];
    }
  }

  @action
  handleUploadError(target: HTMLInputElement, message: string) {
    notifyError(this, message);
    target.value = '';
    this.isLoading = false;
  }

  @action
  private setError(error?: Error) {
    if (!error)
      error = pageError();

    this.isLoading = false;
    this.error = error;
  }

  @action
  async toggleTeamMemberAnalytics(teamMember: TeamMember, include: boolean) {
    if (!this.team) {
      this.handleError('Could not update team member. Plese try again later.');
      return;
    }

    this.isLoading = true;

    const input: UpdateTeamMemberInput = {
      userId: teamMember.id,
      teamId: this.team.id,
      teamAnalytics: {
        add: include ? this.team.id : undefined,
        remove: !include ? this.team.id : undefined
      }
    }

    const [, updateError] = await this.store.apiService.updateTeamMember({ args: { ...input }});

    if (updateError) {
      this.handleError('Could not update team member. Plese try again later.');
      return;
    }

    this.isLoading = false;
    this.refreshList();
  }

  @action
  makeTeamPublic() {
    if (!this.team) return;

    const window = this.store.makeTeamPublicWindow;
    const listener = (msg: Message<MakeTeamPublicWindowState>) => {
      switch (msg.type) {
        case 'success':
          this.refreshTeam();
          break;
        case 'close':
          window.unlisten(listener);
          break;
      }
    }
    window.listen(listener);
    window.open({
      teamId: this.team?.id
    })
  }

  @action
  togglePublicSafety() {
    if (!this.team) return;

    const window = this.store.togglePublicSafetyWindow;
    const listener = (msg: Message<TogglePublicSafetyWindowState>) => {
      switch (msg.type) {
        case 'success':
          this.refreshTeam();
          break;
        case 'close':
          window.unlisten(listener);
          break;
      }
    }
    window.listen(listener);
    window.open({
      teamId: this.team?.id,
      publicSafety: !this.team.publicSafety
    })
  }

  @action
  async refreshTeam() {
    this.isLoading = true;
    const [team, err] = await this.store.teamManager.apiFetchTeam({ id: this.team?.id || '' });

    if (err) {
      this.handleError('Could not fetch the updated team. Please refresh the page.');
    }
    this.team = team;

    await this.refreshList();
    runInAction(() => {
      this.isLoading = false;
      this.refreshForm();
      this.initForm();
    });
  }

  @action
  async copyIframeCode() {
    await navigator.clipboard?.writeText(this.iframeEmbedCode);
    notifySuccess(this, 'Embed code copied to clipboard!');
  }

  @computed
  get avatar() {
    return this.team?.pictureURL || '';
  }

  @computed
  get isChanged(): boolean {
    return this.form.isDirty || this.form.arrayOfInputsChanged;
  }

  // Copied from playerWidgetUtils
  @computed
  get iframeEmbedCode(): string {

    const iframe = document.createElement('iframe');
    const view = mapFromValueToDisplay(this.publicTeamForm.view.value).toLocaleLowerCase();

    const theme =
      this.publicTeamForm.theme.value === 'darkTheme' ?
        'dark' :
        'light';
    const showTeamName = true;
    const showSourceBadge = true;
    const showProfile = true;
    const showTopicTags = true;
    const allowShare = true;
    const allowDownload = true;
    const showHelp = true;
    const disableReactions = false;
    const disableComments = false;
    const disableTranscript = false;
    const disableIndex = false;
    const sort = 'none';
    const customRedirect = this.publicTeamForm.customRedirectUrl.value ? decodeURIComponent(this.publicTeamForm.customRedirectUrl.value) : null;

    const args = sanitizeObject({
      view,
      theme,
      showTeamName,
      showSourceBadge,
      showProfile,
      showTopicTags,
      showHelp,
      disableReactions,
      disableComments,
      disableTranscript,
      disableIndex,
      allowShare,
      allowDownload,
      customRedirect,
      sort
    });

    const libraryRoute =
      Routes.teamLibraryWidget(this.team?.id, args);

    const embedRoute = window.location.origin + libraryRoute;

    iframe.src = embedRoute;
    iframe.width = '1280';
    iframe.height = mapFromValueToDisplay(this.publicTeamForm.view.value) === GalleryDisplay.Carousel ? '250' : '720';
    iframe.title = this.team?.name ?? 'Team Widget';
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allow', 'autoplay; encrypted-media; picture-in-picture; clipboard-write; fullscreen');
    iframe.setAttribute('sandbox', 'allow-same-origin allow-scripts allow-popups allow-forms allow-popups-to-escape-sandbox allow-downloads allow-orientation-lock');

    let code = iframe.outerHTML;

    // // DIRTY HACK TO REVERSE URL ESCAPING
    code = code.replaceAll(/&amp;/gi, '&');
    return code;
  }
}