import React from 'react';
import { observer } from 'mobx-react-lite';
import { PlayerMask } from '..';
import { PlayerState } from '../playerState';
import classNames from 'classnames';

type Props = {
  model: PlayerState
}

export const PlayerMasks = observer(({
  model
}: Props) => {

  const { displayStatus: status, isLiveStream } = model;
  const className = classNames("player-masks", {
    "has-replay": status === 'Ended' && !isLiveStream,
    "has-play": status === 'Paused' || status === 'Idle' || status === 'Stopped',
    "has-loading": status === 'Waiting' || status === 'Seeking'
  });

  return (
    <div className={className}>
      <PlayerMask
        className={`${model.frameset?.isWidgetMode ? 'widget-mode-mask' : ''}`}
        visible={status === 'Ended' && !isLiveStream}
        maskType="Replay"
        onClick={evt => model.invoke('replay')} />

      <PlayerMask
        className={`${model.frameset?.isWidgetMode ? 'widget-mode-mask' : ''}`}
        visible={status === 'Paused' || status === 'Idle' || status === 'Stopped'}
        maskType="Play"
        onClick={evt => model.invoke('play')} />

      <PlayerMask
        className={`${model.frameset?.isWidgetMode ? 'widget-mode-mask' : ''}`}
        visible={status === 'Waiting' || status === 'Seeking'}
        maskType="Loading" />
    </div>
  );
});