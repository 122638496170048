import { action, computed, makeObservable } from 'mobx';
import { Metadata } from '@clipr/lib';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { Maybe } from '../../core';
import { AuthContextType } from '../auth';
import { WidgetParams, WidgetParamsSchema, WidgetRouteParams } from './widgetParams';
import { readParamsFromQueryString } from '../../entities/params/paramsUtils';
import { ClientMode } from '../../kernel/kernelSchema';
import { RouteContext } from '../../routes/routeContext';
import { WidgetAuthMode } from './widgetSchema';
import { Theme, View } from '../ui/utils';
import { INIT_DEBUGGER, TRACE } from '../../core/debug/debugMacros';

export class WidgetService
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    INIT_DEBUGGER(this, { color: 'deepgreen' });
    TRACE(this, `constructor()`);
  }

  readonly nodeType = 'WidgetService';

  get isInIFrame() {
    try {
      return (window !== window.parent);
    } catch (err) { }
    return false;
  }

  @computed get isWidgetMode() {
    return this.store.kernel.clientMode === ClientMode.Widget;
  }

  @computed
  get showProfileCard() {
    // we could write a big `if` for the logic below, but why sacrifice readability?
    // it's also similar to the logic found in the WidgetProfileCard itself
    const { context } = this.store.authService;
    if (!context || !this.isWidgetMode)
      return false;

    switch (context.type) {
      case AuthContextType.Authenticated:
        return true;
      case AuthContextType.Anonymous:
        return context.allowActions;
    }

    return false;
  }

  @computed
  get queryString(): string | null {
    return this.store.routingService.pageSearch;
  }

  @computed
  get queryParams(): URLSearchParams | null {
    return this.store.routingService.pageSearchParams;
  }

  @computed
  get widgetRouteParams(): WidgetRouteParams | null {
    const { routingService } = this.store;
    return routingService.firstWidgetRoute?.params ?? null;
  }

  /**
   * Returns the parsed widget params or the default value if it is defined.
   * If you want a list of parsed widget params without defaults, use `requestedWidgetParams`.
   */
  @computed
  get widgetParams(): WidgetParams {
    return readParamsFromQueryString(WidgetParamsSchema, this.queryString ?? '', true);
  }

  /**
   * Returns the parsed widget params as requested by the client, without defaults.
   * If you want a list of parsed widget params including default values, use `widgetParams`.
   */
  @computed
  get requestedWidgetParams(): WidgetParams {
    return readParamsFromQueryString(WidgetParamsSchema, this.queryString ?? '', false);
  }

  @computed
  get teamId(): string | null {
    return this.widgetRouteParams?.teamId ?? this.widgetParams?.teamId ?? null;
  }

  @computed
  get jobId(): string | null {
    return this.widgetRouteParams?.jobId ?? null;
  }

  // TODO: lousy solution for these pesky filters
  // they definitely should not apply globally to all widgets
  // but since we need them EVERYWHERE this is temporarily better than copy pasting
  // them in 9123772146124218327432 other places
  @computed
  get metadataFilter(): Partial<Metadata> | null {
    return this.widgetParams?.meta ?? null;
  }

  @computed
  get tagsFilter(): string[] | null {
    return this.widgetParams?.tags ?? null;
  }

  @computed
  get authConnection(): string | null {
    return this.widgetParams?.authConnection ?? null;
  }

  @computed
  get authMode(): WidgetAuthMode | null {
    return this.widgetParams?.authMode ?? null;
  }

  @computed
  get theme(): Theme | null {
    return this.widgetParams?.theme ?? null;
  }

  @computed
  get view(): View | null {
    return this.widgetParams?.view ?? null;
  }

  @computed
  get logo(): string | null {
    return this.widgetParams?.logo ?? null;
  }

  @computed
  get allowShare(): boolean | null {
    return this.widgetParams?.allowShare ?? null;
  }

  @computed
  get allowDownload(): boolean | null {
    return this.widgetParams?.allowDownload ?? null;
  }

  @computed
  get showHelp(): boolean | null {
    return this.widgetParams?.showHelp ?? null;
  }

  @computed
  get disableReactions(): boolean | null {
    return this.widgetParams?.disableReactions ?? null;
  }

  @computed
  get disableComments(): boolean | null {
    return this.widgetParams?.disableComments ?? null;
  }

  @computed
  get disableTranscript(): boolean | null {
    return this.widgetParams?.disableTranscript ?? null;
  }

  @computed
  get disableIndex(): boolean | null {
    return this.widgetParams?.disableIndex ?? null;
  }

  @computed
  get customRedirectUrl(): string | null {
    return this.widgetParams?.customRedirect ?? null;
  }

  @computed
  get showTeamName(): boolean | null {
    return this.widgetParams?.showTeamName ?? null;
  }

  @computed
  get showSourceBadge(): boolean | null {
    return this.widgetParams?.showSourceBadge ?? null;
  }

  @computed
  get showProfile(): boolean | null {
    return this.widgetParams?.showProfile ?? null;
  }

  @computed
  get showTopicTags(): boolean | null {
    return this.widgetParams?.showTopicTags ?? null;
  }

  @action
  init() {
    if (this.isInIFrame)
      this.activateWidgetMode();

    TRACE(this, `Initialized widget service with state: \n`, {
      widgetParams: this.widgetParams,
      requestedWidgetParams: this.requestedWidgetParams,
      widgetRouteParams: this.widgetRouteParams,
      teamId: this.teamId,
      jobId: this.jobId
    });
  }

  @action
  activateWidgetMode() {

    if (this.isWidgetMode)
      return;

    this.store.kernel.setWidgetMode();

    // once the widget mode is entered for the first time we suppress the GTM buttons once and for all
    // (we won't restore them once exiting the widget mode)
    const { ui } = this.store;
    ui.suppressFeedbackButton();
    ui.suppressHelpButton();

    const { theme, view, logo } = this;
    if (theme)
      ui.setTheme(theme);
    else
      ui.setTheme(Theme.Default);

    if (view)
      ui.setView(view);
    if (logo)
      ui.setLogo(this.logo);
  }

  handleWidgetRoute(routeContext: RouteContext) {
    this.activateWidgetMode();
  }

  getIsWidgetMode(val?: Maybe<boolean>) {
    if (typeof val === 'boolean')
      return val;
    return this.isWidgetMode;
  }
}