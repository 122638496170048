import React from 'react';

export const WidgetUploadSVGIcon = () => <svg className="widget-upload-svg-icon svg-icon" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path className="fill" d="M9.94863 4.97038V11.9574H7.95233V4.97038H4.95789L8.95048 0.977783L12.9431 4.97038H9.94863Z" />
  <path className="fill" d="M15.9376 16.9482H1.96352C1.67705 16.9482 1.40456 16.8254 1.21491 16.6108C1.02526 16.3962 0.937426 16.1107 0.972361 15.8262L1.97051 7.84105C2.03339 7.34098 2.4586 6.96667 2.96167 6.96667H5.95611V8.96297H3.84303L3.09442 14.9519H14.8067L14.0581 8.96297H11.945V6.96667H14.9394C15.4425 6.96667 15.8677 7.34098 15.9296 7.84105L16.9278 15.8262C16.9637 16.1107 16.8749 16.3962 16.6852 16.6108C16.4966 16.8254 16.2241 16.9482 15.9376 16.9482Z" />
</svg>

export const WidgetSuccessSVGIcon = () =>
  <svg className="widget-success-svg-icon svg-icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#7FBA7A" />
    <path d="M8.6875 10.9108L6.9375 9.08789L5.625 10.4551L8.6875 13.6452L14.375 7.7207L13.0625 6.35352L8.6875 10.9108Z" fill="white" />
  </svg>

export const WidgetErrorSVGIcon = () => <svg className="widget-error-svg-icon svg-icon" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
  <circle cx="8" cy="8" r="8" fill="#F23F3F" />
  <path className="fill" d="M12.5217 9.61605L8.75607 3.74643C8.40212 3.19585 7.60574 3.19585 7.2518 3.74643L3.47637 9.61605C3.09293 10.2158 3.52553 10.9925 4.23342 10.9925H11.7843C12.4823 10.9925 12.9051 10.2158 12.5217 9.61605ZM9.36565 8.95731C9.53279 9.12445 9.53279 9.39975 9.36565 9.57672C9.27716 9.66521 9.16901 9.70453 9.06086 9.70453C8.95271 9.70453 8.83473 9.66521 8.75607 9.57672L7.99902 8.81967L7.24197 9.57672C7.15348 9.66521 7.04533 9.70453 6.93718 9.70453C6.82903 9.70453 6.71105 9.66521 6.63239 9.57672C6.46525 9.40958 6.46525 9.13429 6.63239 8.95731L7.38944 8.20026L6.63239 7.44321C6.46525 7.27607 6.46525 7.00078 6.63239 6.8238C6.79953 6.65666 7.07483 6.65666 7.2518 6.8238L8.00885 7.58085L8.7659 6.8238C8.93304 6.65666 9.20834 6.65666 9.38531 6.8238C9.55245 6.99094 9.55245 7.26624 9.38531 7.44321L8.62826 8.20026L9.36565 8.95731Z" />
</svg>
