import { action, computed, makeObservable } from 'mobx';
import { DateTime } from 'luxon';
import moment from 'moment';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { dateInput, DateInputState } from '../../components/input/dateInput/dateInputState';
import { RangeOptions } from '../../components/input/dateInput/dateInput';
import {
  ReactionsChartState,
  TopicViewsChartState,
  BookmarksChartState,
  HoursCliprdChartState,
  AudienceFocusChartState,
  TeamPerformanceChartState,
  PerformanceChartState,
} from '../../components/chart';
import { Team } from '../../entities';
import { Error } from '../../core/error';
import { AnalyticsWidgetDateRangePresets } from '.';
import { ChartPageParams } from '../../components/chart/chartSchema';
import { MetricsWrapperState } from '../../components/chart/metricsWrapper/metricsWrapperState';
import { RouteContext } from '../../routes/routeContext';
import { WidgetState } from '../widgetStateMixin';
import { INIT_DEBUGGER, TRACE } from '../../core/debug/debugMacros';

export class AnalyticsWidgetState
  extends WidgetState(StoreNode) {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    INIT_DEBUGGER(this, {
      color: 'deeppink'
    });

    this.dateInput = dateInput(this, {
      placeholder: ['mm/dd/yyyy', 'mm/dd/yyyy'],
      format: ['MM/DD/YYYY', 'MMDDYYYY'],
      allowEmpty: [false, false],
      rangeOptions: this.dateRangeOptions,
      activeRangeOption: this.dateRangeOptions[2]
    });
  }

  readonly nodeType = 'AnalyticsWidget';

  private abortController: AbortController | null = null;

  readonly dateInput: DateInputState;
  readonly dateRangeOptions: RangeOptions[] = AnalyticsWidgetDateRangePresets;

  @computed get teamId(): string | null {
    return this.store.widgetService.teamId;
  }

  @computed get team(): Team | null {
    return this.store.teamManager.getTeam(this.teamId);
  }

  /** 
   * The value of `dateInput` filter with values converted to `luxon.DateTime` objects.
   * If either of the [start, end] values are invalid `null` is returned and a warning is thrown.
   */
  @computed
  get dateInputValue(): [DateTime, DateTime] | null {
    const { dateInput } = this;
    const value = dateInput?.value as [moment.Moment, moment.Moment];
    if (!value)
      return null;

    const startJsDate = value[0].format();
    const startDate = DateTime.fromISO(startJsDate, { setZone: true });

    const endJsDate = value[1].format();
    const endDate = DateTime.fromISO(endJsDate, { setZone: true });

    if (!startDate.isValid || !endDate.isValid) {
      console.warn(`DateTime objects extracted from 'dateInput' are not valid.`);
      return null;
    }

    return [
      startDate,
      endDate
    ];
  }

  /** The `luxon.DateTime` value for the start date filter, with the time normalized to be at the start of the day. */
  @computed
  get startDateFilter(): DateTime | null {
    const { dateInputValue } = this;
    if (!dateInputValue)
      return null;

    let startDate = dateInputValue[0];
    startDate = startDate
      .set({
        hour: 0,
        minute: 0,
        second: 0
      })
      .setZone('UTC', { keepLocalTime: true });

    return startDate;
  }

  /** The `luxon.DateTime` value for the end date filter, with the time normalized to be at the end of the day. */
  @computed
  get endDateFilter(): DateTime | null {
    const { dateInputValue } = this;
    if (!dateInputValue)
      return null;

    let endDate = dateInputValue[1];
    endDate = endDate
      .set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999
      })
      .setZone('UTC', { keepLocalTime: true });

    return endDate;
  }

  @computed
  get timezone(): string | null {
    const { dateInputValue } = this;
    if (!dateInputValue)
      return null;

    const date = dateInputValue[0];
    const timezone = date.zoneName;

    return timezone;
  }

  @computed 
  get chartParams(): ChartPageParams {
    return {
      startDate: this.startDateFilter,
      endDate: this.endDateFilter,
      timezone: this.timezone,
      teamId: this.teamId
    };
  }

  // #region Chart states
  readonly reactionsChart = new ReactionsChartState(this.store, {
    params: () => (this.chartParams)
  });

  readonly bookmarksChart = new BookmarksChartState(this.store, {
    params: () => (this.chartParams)
  });

  readonly hoursCliprdChart = new HoursCliprdChartState(this.store, {
    params: () => (this.chartParams)
  });

  readonly audienceFocusChart = new AudienceFocusChartState(this.store, {
    params: () => (this.chartParams)
  });

  readonly topicViewsChart = new TopicViewsChartState(this.store, {
    params: () => (this.chartParams)
  });

  readonly teamPerformanceChart = new TeamPerformanceChartState(this.store, {
    params: () => (this.chartParams)
  });

  readonly performanceChart = new PerformanceChartState(this.store, {
    params: () => (this.chartParams)
  });

  readonly metricsWrapper = new MetricsWrapperState(this.store, {
    params: () => (this.chartParams)
  });
  // #endregion

  @action
  async attached(routeContext: RouteContext) {
    TRACE(this, `attached()`, { routeContext }, '\n', this.__traceState);

    this.reset();
    this.baseAttached(routeContext);

    this.emit('Mounted', {
      teamId: this.teamId
    });

    this.setLoading();

    const { teamId } = this;
    if (!this.team && teamId) {
      const [team, err] = await this.store.teamManager.apiFetchTeam({
        id: teamId
      });

      if (err || !team)
        return this.setError(new Error('ApiError', 'Failed to get team information.'));
    }

    await this.load();
  }

  @action
  detached() {
    TRACE(this, `detached()`, this.__traceState);

    this.baseDetached();
    this.reset();
    
    this.emit('Unmounted');
  }

  private async load() {
    TRACE(this, `load()`, this.__traceState);

    this.abort();
    this.setLoading();

    if (!this.isAttached)
      return this.setError(new Error('InternalError'));

    await this.fetch();

    this.setLoaded();
  }

  /** Fetches the data required for the rendering of the widget. */
  @action
  private async fetch() {
    return Promise.all([
      this.topicViewsChart.fetch(),
      this.reactionsChart.fetch(),
      this.bookmarksChart.fetch(),
      this.audienceFocusChart.fetch(),
      this.teamPerformanceChart.fetch(),
      this.performanceChart.fetch(),
      this.hoursCliprdChart.fetch(),
      ...this.metricsWrapper.outputMetrics.map(metricCounter => metricCounter.fetch())
    ]);
  }

  /** Aborts all direct and indirect async operations (mostly network requests) associated with the widget. */
  private abort() {

    const lastAbortCtrl = this.abortController;
    if (lastAbortCtrl)
      lastAbortCtrl.abort();

    this.topicViewsChart.abort();
    this.reactionsChart.abort();
    this.bookmarksChart.abort();
    this.audienceFocusChart.abort();
    this.teamPerformanceChart.abort();
    this.performanceChart.abort();
    this.hoursCliprdChart.abort();
    this.metricsWrapper.outputMetrics
      .forEach(metricCounter => metricCounter.abort());
  }

  @action
  async handleDateInputChange() {
    await this.fetch();
  }

  @action
  reset() {
    this.baseReset();
  }

  private get __traceState() {
    return {
      ...this.__baseTraceState
    }
  }
}