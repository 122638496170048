import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

type FileDownloadLinkProps = {
  actionUrl: string;
  onClick: () => void;
}

export const FileDownloadLink = observer(
  ({ actionUrl, onClick }: FileDownloadLinkProps) => {

    const linkRef = useRef<HTMLAnchorElement>(null);
    useEffect(() => {
      linkRef.current?.click();
      onClick?.();
    }, [actionUrl, onClick]);

    return <a
      className="file-download-link"
      ref={linkRef}
      href={actionUrl}
      // target="_blank"
      aria-hidden={true}
    >
      Download
    </a>
  })