import { computed, makeObservable, observable } from 'mobx';
import { Store } from '../store/store';
import { StoreNode } from '../store';
import { timestampNorm } from '../core/time';


export type MomentStubStatus =
  'creatingFromPlayer' |
  'editing';

type Props = {

}

/**
 * Represents a Moment object which is in the process of being created by the user.
 */
export class MomentStub
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  readonly nodeType: 'MomentStub' = 'MomentStub';

  @observable designStartTime = 0;
  @observable designEndTime = 0;

  @observable status: MomentStubStatus | null = null;

  /** Returns the canonical start time, which is guaranteed to be less than or equal to endTime. */
  @computed get startTime() {
    return Math.min(this.designStartTime, this.designEndTime);
  }

  @computed get startTimeNorm() {
    return timestampNorm(this.startTime);
  }

  /** Returns the canonical end time, which is guaranteed to be greater than or equal to startTime. */
  @computed get endTime() {
    return Math.max(this.designStartTime, this.designEndTime);
  }

  @computed get endTimeNorm() {
    return timestampNorm(this.endTime);
  }

  /** Returns the canonical duration, which is guaranteed to be greater than or equal to 0. */
  @computed get duration() {
    return this.endTime - this.startTime;
  }
}