import React from 'react';
import { makeObservable, observable, computed, action } from 'mobx';
import clamp from 'lodash/clamp';
import throttle from 'lodash/throttle';
import findLast from 'lodash/findLast';

import { TrackModel, MomentModel } from '../../entities';
import { Store } from '../../store/store';
import { BindingProps, refProxy, Message, StoreNode } from '../../store';
import { getEventOffset, PointerEventLike } from '../../core';
import { MultipleClipEditWindowState } from '../momentWindow/multipleClipEditWindowState';
import { SpeakerWindowState } from '../speakerWindow/speakerWindowState';
import { notifyError, notifyLoading, notifySuccess } from '../../services/notifications';
import { TrackWindowState } from '../trackWindow/trackWindowState';
import { DeleteMomentsPopupState } from '../momentWindow/deleteMomentsPopupState';
import { ClipWindowState } from '../momentWindow/clipWindowState';
import { MergeTracksWindowState } from '../trackWindow/mergeTracksWindowState';
import { PlayheadState } from '../player/playheadState';
import { ConfirmationModalState } from '../../pages/trainerVideoPage/confirmationModalState';
import { PlayerState } from '../player/playerState';

type Props = BindingProps<{
  jobId: string;
  player: PlayerState;
}>

export const getSelectionTimeInterval = (prevMoment: MomentModel, currMoment: MomentModel) => {
  let timeDataset = [prevMoment.startTime, prevMoment.endTime, currMoment.startTime, currMoment.endTime];
  let min = Math.min(...timeDataset);
  let max = Math.max(...timeDataset);

  return [min, max];
}

export class TrainerTimelineState
  extends StoreNode {

  readonly nodeType: 'TrainerTimeline' = 'TrainerTimeline';

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.playhead = new PlayheadState(store, {
      player: () => this.player,
      containerRef: () => this.containerProxy,
    });
    this.playhead.listen(
      this.player.playheadListener);
  }

  readonly playhead: PlayheadState;

  // #region Resolved props
  @computed get player(): PlayerState {
    return this.resolvedProps.player;
  }
  @computed get jobId(): string {
    return this.resolvedProps.jobId;
  }
  @computed get job() {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed get isEnrichmentProcessing(): boolean {
    return this.player.isEnrichmentProcessing;
  }

  @computed get isTranscriptProcessing(): boolean {
    return this.player.isTranscriptProcessing;
  }

  @computed get duration() {
    return this.player.duration;
  }
  // #endregion

  // #region React ref proxies
  // -------
  readonly viewportProxy = refProxy(this);

  readonly containerProxy = refProxy(this);
  readonly surfaceProxy = refProxy(this);
  readonly sidebarProxy = refProxy(this);
  readonly rulerProxy = refProxy(this);
  // #endregion

  readonly sourcesFilter = observable.set<string>(['Trainer', 'User', 'Clipr']);

  readonly selectedTracks = observable.array<TrackModel>();

  readonly selectedMomentIds = observable.array<string>();

  @observable zoom = 1;
  @observable pan = 0;
  readonly minZoom = 0.25;
  readonly maxZoom = 8;

  @observable confidence = 100;
  readonly minConfidence = 0;
  readonly maxConfidence = 100;

  @observable selectedTrackId: string | null = null;
  @observable syncingTrackId: string | null = null;
  // Keyboard selected track for timeline navigation
  @observable highlightedTrack: TrackModel | null = null;

  @computed
  get durationLayout(): number {
    return Math.max(Math.round(this.duration), this.duration)
  }

  @computed
  get isEditMomentsActive() {
    return this.selectedMomentIds.length > 0;
  }

  @computed
  get lastSelectedMomentId() {
    const lastElemIndex = this.selectedMomentIds?.length - 1;
    if (lastElemIndex >= 0)
      return this.selectedMomentIds[lastElemIndex];

    return null;
  }

  // #region Keyboard navigation related helpers
  // -------
  @computed
  get currentPlayingMoment(): MomentModel | null {
    if (!this.highlightedTrack) return null;

    /**
     * Round the current time to the nearest 2 decimal float
     * to fix the inconsistency when setting currentTime on the video
     */
    const round = (num: number) => {
      var m = Number((Math.abs(num) * 100).toPrecision(15));
      return Math.round(m) / 100 * Math.sign(num);
    }
    const time = round(this.player.currentTime);
    const moment = this.highlightedTrack.moments.find(moment => moment.startTime <= time && moment.endTime >= time);

    return moment || null;
  }

  @computed
  get nextMomentOnTrack(): MomentModel | null {
    if (!this.highlightedTrack) return null;

    if (this.currentPlayingMoment) {
      const curr = this.highlightedTrack.moments.findIndex(moment =>
        this.currentPlayingMoment?.id === moment.id);
      const nextIndex = curr < this.highlightedTrack.moments.length - 1 ? curr + 1 : -1;

      return this.highlightedTrack.moments[nextIndex];
    }

    const currentTime = this.player.currentTime;
    return this.highlightedTrack.moments.find(moment => moment.startTime > currentTime) || null;
  }
  @computed
  get previousMomentOnTrack(): MomentModel | null {
    if (!this.highlightedTrack) return null;

    if (this.currentPlayingMoment) {
      const curr = this.highlightedTrack.moments.findIndex(moment =>
        this.currentPlayingMoment?.id === moment.id);
      const prevIndex = curr > 0 ? curr - 1 : 0;

      return this.highlightedTrack.moments[prevIndex];
    }

    const currentTime = this.player.currentTime;
    const reversed = [...this.highlightedTrack.moments];
    reversed.reverse();
    return reversed.find(moment => moment.endTime < currentTime) || null;
  }

  @computed
  get nextTranscriptBelowConfidence(): MomentModel | null {
    const transcripts = this.job?.transcriptMoments;
    const currentTime = this.player.currentTime;

    return transcripts?.find(moment =>
      moment.startTime > currentTime &&
      moment.minConfidencePercentage &&
      moment.minConfidencePercentage <= this.confidence
    ) ?? null;
  }

  @computed
  get previousTranscriptBelowConfidence(): MomentModel | null {
    const transcripts = this.job?.transcriptMoments;
    const currentTime = this.player.currentTime;
    const currentTranscript = this.player.momentView.activeTranscript;

    if (!transcripts)
      return null;

    const previousTranscript = findLast(transcripts.filter(moment =>
      moment.startTime < currentTime &&
      moment.id !== currentTranscript?.id &&
      moment.minConfidencePercentage &&
      moment.minConfidencePercentage <= this.confidence
    )) ?? null;

    if (!previousTranscript && currentTranscript)
      return currentTranscript;

    return previousTranscript;
  }
  // #endregion

  @action
  clearMultipleEdit() {
    this.selectedTrackId = null;
    this.selectedMomentIds.clear();
  }

  @action
  invoke = (type: string, payload?: any) => {

    switch (type) {

      case 'addSpeaker':
        const addSpeakerWindow = this.store.speakerWindow;
        const addSpeakerWindowListener = (msg: Message<SpeakerWindowState>) => {
          switch (msg.type) {
            case 'open':
              this.player.invoke('enterPause');
              break;

            case 'close':
              addSpeakerWindow.unlisten(addSpeakerWindowListener);
              this.player.invoke('exitPause');
              break;
          }
        }
        addSpeakerWindow.listen(addSpeakerWindowListener);
        addSpeakerWindow.openCreate(payload.jobId);
        break;

      case 'addTrack':
        const addTrackWindow = this.store.trackWindow;
        const addTrackWindowListener = (msg: Message<TrackWindowState>) => {
          switch (msg.type) {
            case 'open':
              this.player.invoke('enterPause');
              break;

            case 'close':
              addTrackWindow.unlisten(addTrackWindowListener);
              this.player.invoke('exitPause');
              break;
          }
        }
        addTrackWindow.listen(addTrackWindowListener);
        addTrackWindow.openCreate(payload.jobId);
        break;

      case 'editTrack':
        const trackWindow = this.store.trackWindow;
        const trackWindowListener = (msg: Message<TrackWindowState>) => {
          switch (msg.type) {
            case 'open':
              this.player.invoke('enterPause');
              break;

            case 'close':
              trackWindow.unlisten(trackWindowListener);
              this.player.invoke('exitPause');
              break;
          }
        }

        const speaker = this.job?.getTrack(payload.trackId);
        if (!speaker) {
          notifyError(this, `Assigned track id was not found ${payload.trackId}.`);
          return;
        }
        trackWindow.listen(trackWindowListener);
        trackWindow.openEdit(payload.trackId, payload.jobId);
        break;

      case 'mergeTracks':
        const mergeTracksWindow = this.store.mergeTracksWindow;
        const mergeTracksWindowListener = (msg: Message<MergeTracksWindowState>) => {
          switch (msg.type) {
            case 'open':
              this.player.invoke('enterPause');
              break;

            case 'close':
              mergeTracksWindow.unlisten(mergeTracksWindowListener);
              this.player.invoke('exitPause');
              break;
          }
        }

        if (!this.job?.getTrack(payload.trackId)) {
          notifyError(this, `Assigned track id was not found ${payload.trackId}.`);
          return;
        }
        mergeTracksWindow.listen(mergeTracksWindowListener);
        mergeTracksWindow.openMerge(payload.trackId, payload.jobId);
        break;

      case 'duplicateTrack':
        const duplicationTrack = this.job?.getTrack(payload.trackId) ?? null;
        if (!this.jobId || !duplicationTrack)
          return;

        if (this.job?.isDone && !!duplicationTrack?.visibleToConsumer) {
          const confirmationModal = this.store.confirmationModal;
          const confirmationModalListener = (msg: Message<ConfirmationModalState>) => {
            switch (msg.type) {
              case 'open':
                this.player.invoke('enterPause');
                break;
              case 'close':
                confirmationModal.unlisten(confirmationModalListener);
                this.player.invoke('exitPause');
                break;
            }
          }
          confirmationModal.listen(confirmationModalListener);
          this.dispatch('openConfirmationModal', {
            title: 'Track duplication failed',
            message: 'This swimlane could not be duplicated because the track is Active and the video has status Done. Please inactivate the track or change the video status before duplication, so the validations can be resumed.',
            closeLabel: 'Cancel',
            layout: 'info',
            isLoading: false
          });
        } else {
          const duplicateTrackConfirmationModal = this.store.confirmationModal;
          const duplicateTrackConfirmationModalListener = (msg: Message<ConfirmationModalState>) => {
            switch (msg.type) {
              case 'open':
                this.player.invoke('enterPause');
                break;
              case 'close':
                duplicateTrackConfirmationModal.unlisten(duplicateTrackConfirmationModalListener);
                this.player.invoke('exitPause');
                break;
            }
          }
          duplicateTrackConfirmationModal.listen(duplicateTrackConfirmationModalListener);
          duplicateTrackConfirmationModal.open({
            onSubmit: () => this.duplicateTrack(duplicationTrack.id),
            modalMessage: `Are you sure you want duplicate the ${duplicationTrack.name} track?`,
            title: 'Duplicate Track',
            isLoading: false
          });
        }
        break;
      case 'toggleSourceOption':
        const source = payload.sourceName;
        if (this.sourcesFilter.has(source))
          this.sourcesFilter.delete(source)
        else
          this.sourcesFilter.add(source);
        break;

      case 'openMultipleClipEditorWindow':
        this.player.invoke('clearMomentStub');

        const window = this.store.multipleClipEditWindow;
        const listener = (msg: Message<MultipleClipEditWindowState>) => {
          switch (msg.type) {
            case 'open':
              this.player.invoke('enterPause');
              break;
            case 'close':
              window.unlisten(listener);
              this.player.invoke('exitPause');
              this.player.invoke('clearMomentStub');
              break;
            case 'momentsUpdated':
            case 'momentsDeleted':
            case 'momentUpdated':
            case 'momentDeleted':
            case 'momentCreated':
              window.unlisten(listener);
              this.player.invoke('exitPause');
              this.player.invoke('clearMomentStub');
              this.clearMultipleEdit();
              break;
          }
        }

        window.listen(listener);
        const momentList = this.selectedMomentIds.reduce((arr, curr) => {
          const moment = this.job?.getMoment(curr);
          if (moment)
            arr.push(moment);
          return arr;
        }, [] as MomentModel[]) || [];

        window.openEdit({
          momentList,
          jobId: this.jobId,
          source: 'Trainer'
        });
        break;

      case 'openClipEditorWindow':
        const clipId = payload?.momentId || null;
        this.player.invoke('clearMomentStub');

        const clipWindow = this.store.clipWindow;
        const clipWindowListener = (msg: Message<ClipWindowState>) => {
          switch (msg.type) {
            case 'open':
              this.player.invoke('enterPause');
              break;
            case 'close':
              clipWindow.unlisten(clipWindowListener);
              this.player.invoke('exitPause');
              this.player.invoke('clearMomentStub');
              break;
            case 'momentsUpdated':
            case 'momentsDeleted':
            case 'momentUpdated':
            case 'momentDeleted':
            case 'momentCreated':
              clipWindow.unlisten(clipWindowListener);
              this.player.invoke('exitPause');
              this.player.invoke('clearMomentStub');
              this.clearMultipleEdit();
              break;
          }
        }

        clipWindow.listen(clipWindowListener);
        if (clipId)
          clipWindow.openMomentEdit({
            momentId: clipId,
            jobId: this.jobId,
            source: 'Trainer',
            options: {
              momentKindLabel: 'clip'
            }
          });
        break;

      case 'setZoom':
        this.setZoom(payload.value);
        break;
      case 'setConfidence':
        this.setConfidence(payload.value);
        break;

      case 'openMomentsDeletePopup':
        this.player.invoke('clearMomentStub');

        const deleteMomentsPopup = this.store.deleteMomentsPopup;
        const deleteMomentsPopupListener = (msg: Message<DeleteMomentsPopupState>) => {
          switch (msg.type) {
            case 'open':
              this.player.invoke('enterPause');
              break;

            case 'close':
              deleteMomentsPopup.unlisten(deleteMomentsPopupListener);
              this.player.invoke('exitPause');
              this.player.invoke('clearMomentStub');
              break;

            case 'momentsDeleted':
              deleteMomentsPopup.unlisten(deleteMomentsPopupListener);
              this.player.invoke('exitPause');
              this.player.invoke('clearMomentStub');
              this.clearMultipleEdit();
              break;
          }
        }
        deleteMomentsPopup.listen(deleteMomentsPopupListener);
        deleteMomentsPopup.open({
          jobId: this.jobId,
          momentIds: this.selectedMomentIds
        });
        break;

      case 'updateTrackVisibility':
        const track = payload?.track || null;
        this.updateTrackVisiblity(track);
        break;

      case 'nextTrack': {
        if (!this.job) break;

        const curr = this.job.tracks.findIndex(track => track.id === this.highlightedTrack?.id) || 0;
        const nextIndex = curr < this.job.tracks.length - 1 ? curr + 1 : curr;
        this.highlightedTrack = this.job.tracks[nextIndex];

        this.scrollHighlightedTrackIntoView(nextIndex);
        break;
      }
      case 'previousTrack': {
        if (!this.job) break;

        const curr = this.job.tracks.findIndex(track => track.id === this.highlightedTrack?.id) || 0;
        const prevIndex = curr > 0 ? curr - 1 : 0;
        this.highlightedTrack = this.job.tracks[prevIndex];

        this.scrollHighlightedTrackIntoView(prevIndex);
        break;
      }
      case 'jumpToNextMomentInTrack': {
        if (!this.highlightedTrack || !this.job || !this.nextMomentOnTrack) break;

        this.player.invoke('seek', {
          time: this.nextMomentOnTrack.startTime
        })
        break;
      }
      case 'jumpToPreviousMomentInTrack': {
        if (!this.highlightedTrack || !this.job || !this.previousMomentOnTrack) break;

        this.player.invoke('seek', {
          time: this.previousMomentOnTrack.startTime
        });
        break;
      }
      case 'jumpToNextTranscriptBelowConfidence': {
        if (!this.job || !this.nextTranscriptBelowConfidence) break;

        this.player.invoke('seek', {
          time: this.nextTranscriptBelowConfidence.startTime
        });
        break;
      }

      case 'jumpToPreviousTranscriptBelowConfidence': {
        if (!this.job || !this.previousTranscriptBelowConfidence) break;

        this.player.invoke('seek', {
          time: this.previousTranscriptBelowConfidence.startTime
        });
        break;
      }

      default:
        // pageController.dispatch(type, payload);
        break;
    }
  }

  @action
  duplicateTrack = async (trackId: string) => {
    if (!this.job)
      return;

    const [res, err] = await this.job.apiDuplicateTrack({
      jobId: this.job.id,
      trackId: trackId
    });

    if (err)
      notifyError(this, err);

    const oldTrack = this.job.getTrack(trackId);
    const newJob = res;

    if (newJob)
      notifySuccess(this, `Track ${oldTrack?.name} was duplicated as ${newJob.name}.`);
    else
      notifyError(this, 'Something went wrong.');
  }

  @action
  scrollHighlightedTrackIntoView(index: number) {
    const sidebar = this.sidebarProxy.current;
    if (!sidebar) return;
    const children = sidebar.children[0].childNodes;
    const item = children[index] as HTMLElement;

    const sidebarTop = sidebar.scrollTop + sidebar.offsetTop;
    const isInView = sidebarTop < item.offsetTop &&
      sidebar.offsetHeight + sidebarTop > item.offsetTop + item.offsetHeight

    if (!isInView) {
      sidebar.scrollTo({ top: item.offsetTop - sidebar.offsetTop })
    }
  }

  @action
  async updateTrackVisiblity(track: TrackModel) {

    if (this.syncingTrackId)
      return;

    const { job, visibleToConsumer, hasTopicsWithChildSubtopics, hasUncontainedSubtopics } = track;
    if (!job)
      return;

    const hasActiveDuplicate = job.checkIfActiveDuplicateForTrack(track);

    if (job.isDone && !visibleToConsumer && hasActiveDuplicate)
      return notifyError(this, `An active ${track.trackKind} track already exists.`);

    if (job.isDone && hasTopicsWithChildSubtopics && visibleToConsumer)
      return notifyError(this, `The track contains topics with children subtopics.`);

    if (job.isDone && hasUncontainedSubtopics && !visibleToConsumer)
      return notifyError(this, `The track contains subtopics without or that exceed parent topics.`);

    this.syncingTrackId = track.id;

    notifyLoading(this, 'Updating track visiblity.')

    const [, err] = await this.job?.apiUpdateTrack({
      args: {
        jobId: job.id,
        trackId: track.id,
        speakerId: track.speaker?.id,
        visibleToConsumer: !track.visibleToConsumer
      }
    }) || [null];

    if (err)
      notifyError(this, `Track visibility could not be updated.`);
    else
      notifySuccess(this, 'Track visibility updated successfully.');

    this.syncingTrackId = null;

  }

  @action
  handleSidebarVerticalScroll = (evt: React.SyntheticEvent) => {
    this.surfaceProxy.current!.scrollTop = this.sidebarProxy.current!.scrollTop;
  }

  @action
  handleHorizontalScroll = (evt: React.SyntheticEvent) => {

  }

  @action
  handleSurfaceWheelNative = (evt: WheelEvent) => {
    // native instead of React handler because
    // https://github.com/facebook/react/issues/14856

    if (!evt.altKey)
      return;

    evt.preventDefault();
    evt.stopPropagation();

    if (evt.deltaY > 0) {
      this.setZoom(this.zoom * 0.75);
    } else {
      this.setZoom(this.zoom / 0.75);
    }
  }

  @action
  handleViewportWheel = (evt: React.WheelEvent) => {

    if (evt.altKey)
      return;

    let left;

    if (Math.abs(evt.deltaY) > Math.abs(evt.deltaX))
      left = evt.deltaY > 0 ? -50 : 50;
    else
      left = evt.deltaX;

    const viewport = this.viewportProxy.current;
    viewport!.scrollBy({
      top: 0,
      left: left,
      behavior: 'auto'
    });
  }

  @action
  private setZoom(val: number) {
    this.zoom = clamp(val, this.minZoom, this.maxZoom);
  }

  @action
  private setConfidence(val: number) {
    this.confidence = clamp(val, this.minConfidence, this.maxConfidence);
  }

  @action
  // factory for SeekEvent payload
  seekPayload = (evt: PointerEventLike) => ({
    time: this.player.getTimeFromEvent(evt, this.containerProxy)
  });

  @action
  handleTimelineMomentClick = (evt: React.MouseEvent<HTMLElement>, moment: MomentModel) => {

    if (evt.ctrlKey || evt.metaKey) {
      const elemIndex = this.selectedMomentIds.indexOf(moment.id);
      if (elemIndex === -1) {
        if (this.selectedMomentIds.length === 0) //if first selected enter edit mode and lock track
          this.selectedTrackId = moment.trackId;

        if (moment.trackId !== this.selectedTrackId)
          return;

        this.selectedMomentIds.push(moment.id);
      } else
        this.selectedMomentIds.splice(elemIndex, 1);

      if (this.selectedMomentIds.length === 0) //if empty exit edit mode and clear locked track
        this.selectedTrackId = null;
    } else if (evt.shiftKey) {

      if (this.selectedMomentIds.length === 0) //if first selected enter edit mode and lock track
        this.selectedTrackId = moment.trackId;

      if (moment.trackId !== this.selectedTrackId)
        return;

      const track = this.job?.getTrack(moment.trackId);
      const lastSelectedMoment = this.lastSelectedMomentId && this.job?.getMoment(this.lastSelectedMomentId);

      if (lastSelectedMoment) {
        let [startTime, endTime] = getSelectionTimeInterval(lastSelectedMoment, moment);
        const momentArray = track?.moments.reduce((arr, curr) => {
          if (curr.startTime >= startTime && curr.endTime <= endTime)
            arr.push(curr)
          return arr;
        }, [] as MomentModel[]) || []

        momentArray?.forEach(el => {
          if (this.selectedMomentIds.indexOf(el?.id) === -1)
            this.selectedMomentIds.push(el?.id)
        })

      } else {
        this.selectedMomentIds.push(moment?.id);
      }

    } else {
      this.player.playhead.emit('seek', { time: moment.startTime });

      if (!this.isEditMomentsActive)
        this.invoke('openClipEditorWindow', { momentId: moment.id });
    }
  }

  @action
  handleRulerClick = (evt: React.MouseEvent<HTMLElement>) => {
    const domRuler = this.rulerProxy.node;
    if (!domRuler)
      return;

    const offset = getEventOffset(evt, domRuler);
    const position = offset.x / domRuler!.offsetWidth;

    this.emit('seekRelative', { position });
  }

  @observable isPanning = false;

  @action
  handleSurfacePointerDown = (evt: React.PointerEvent<HTMLElement>) => {

    const viewport = this.viewportProxy.current;
    if (!viewport)
      return;

    const offset = getEventOffset(evt, viewport);

    const panStartX = offset.x;
    const panStartY = offset.y;
    const panStartScrollX = viewport!.scrollLeft;
    const target = evt.target as HTMLElement;

    // If clicked on the ruler or a track lane then seek the player to that time
    // TODO: implement more robust behaviour for this detection, rather than relying on the class or element type (which might change at any moment)
    if (target.classList.contains('track-lane') || target.nodeName === 'rect') {
      this.player.playhead.emit('seek', this.seekPayload(evt));
    }

    let canceled = false;
    const cancel = action(() => {
      this.isPanning = false;

      document.removeEventListener('pointermove', handleRootPointerMove);
      document.removeEventListener('pointerup', handleRootPointerUp);
      document.removeEventListener('pointerleave', handleRootPointerLeave);

      canceled = true;
    })

    const handleRootPointerMove = action((evt: PointerEvent) => {
      if (canceled || !viewport)
        return;
      this.isPanning = true;

      const offset = getEventOffset(evt, viewport);
      const dx = offset.x - panStartX;
      const dy = offset.y - panStartY;

      viewport!.scrollLeft = panStartScrollX - dx;

      // do not prevent clicks from shaky hands
      if (Math.abs(dx) > 10 && Math.abs(dy) > 10) {
        evt.stopPropagation();
        evt.preventDefault();
      }
    })

    const handleRootPointerUp = (evt: PointerEvent) => {
      cancel();

      if (!viewport)
        return;

      const offset = getEventOffset(evt, viewport);
      const dx = offset.x - panStartX;
      const dy = offset.y - panStartY;

      // do not prevent clicks from shaky hands
      if (Math.abs(dx) > 10 && Math.abs(dy) > 10) {
        evt.stopPropagation();
        evt.preventDefault();
      }
    }

    const handleRootPointerLeave = (evt: PointerEvent) => {
      cancel();
    }

    document.addEventListener('pointermove', handleRootPointerMove);
    document.addEventListener('pointerup', handleRootPointerUp);
    document.addEventListener('pointerleave', handleRootPointerLeave);
  }

  @action
  handleKeyDown = (evt: KeyboardEvent) => {
    const window = this.store.multipleClipEditWindow;

    if (evt.keyCode === 27 && !window.isVisible) {
      evt.preventDefault();
      this.clearMultipleEdit();
    }
  }

  @action
  mounted() {
    const viewport = this.viewportProxy.node;
    if (!viewport)
      return;

    this.clearMultipleEdit();

    viewport.addEventListener('wheel', this.handleSurfaceWheelNative, { passive: false });
    document.addEventListener('keydown', this.handleKeyDown);
  }

  @action
  unmounted() {
    const viewport = this.viewportProxy.node;
    if (!viewport)
      return;

    this.clearMultipleEdit();

    viewport!.removeEventListener('wheel', this.handleSurfaceWheelNative);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  @action
  handleJumpToNextTranscriptBelowConfidence = () => {
    this.invoke('jumpToNextTranscriptBelowConfidence');
  }

  @action
  handleJumpToPreviousTranscriptBelowConfidence = () => {
    this.invoke('jumpToPreviousTranscriptBelowConfidence');
  }

  @action
  handleNavigateTranscripts = (navigateBackwards: boolean = false) => {
    if (navigateBackwards) {
      this.handleJumpToPreviousTranscriptBelowConfidence();
      return;
    }

    this.handleJumpToNextTranscriptBelowConfidence();
  }

  throttledNavigateForwardTranscripts = throttle(
    () => this.handleNavigateTranscripts(),
    500,
    {
      leading: true,
      trailing: false
    });

  throttledNavigateBackwardTranscripts = throttle(
    () => this.handleNavigateTranscripts(true),
    500,
    {
      leading: true,
      trailing: false
    });
}