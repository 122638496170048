import { Result } from '../core';
import { Error, error } from '../core/error';
import { ApiError } from './apiError';

export function getApiResultJobError(result: Result[]): Error | null {
  const unpublishedErr = result.some(res => res[1]?.message === 'Unauthorized - UNPUBLISHED');
  if (unpublishedErr)
    return error('JobUnavailable');

  const unauthorizedErr = result.some(res => res[1]?.message === 'Unauthorized');
  if (unauthorizedErr)
    return error('JobUnauthorized');

  const deletedErr = result.some(res => res[1]?.message?.indexOf('Job is deleted') === 0);
  if (deletedErr)
    return error('JobDeleted');

  const unknownErr = result.some(res => !!res[1]);
  if (unknownErr)
    return error('Unknown', result[0][1]?.message);

  return null;
}

export function getApiTeamError(apiError: ApiError | null): Error {
  if (!apiError) return error('Unknown');

  if (apiError.message.includes('Team is deleted'))
    return error('TeamDeleted');

  if (apiError.message.includes('Unauthorized'))
    return error('Unauthorized');

  //TODO: treat other cases
  return error('Unknown');
}

export function apiResultIsAborted(result: Result<any, ApiError>): boolean {
  return result[1]?.type === 'RequestAborted';
}

export function batchApiResultIsAborted(result: Result<any, ApiError>[]): boolean {
  return result.some(res => apiResultIsAborted(res));
}