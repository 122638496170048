import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { DropdownInput, ToggleLayout, ToggleInput } from '../input';
import {
  TeamSettingsPageState,
  RoleProps,
} from '../../pages/teamSettingsPage/teamSettingsPageState';
import { LoadMask } from '../loader';
import { Catalog } from '../layout/catalog';
import { CatalogFooter } from '../layout/catalogFooter';
import { TeamsSVGIcon } from '../svg';

type Props = {
  model: TeamSettingsPageState;
};

export const TeamMembersTable = observer(({ model }: Props) => {
  const { teamMemberCatalogSource } = model;

  const { sortField, roleFilter, items } = teamMemberCatalogSource;
  return (
    <Catalog
      className="team-members-catalog catalog catalog-page"
      model={model.teamMemberCatalog}
      hasExternalLoading>
      <>
        <LoadMask visible={teamMemberCatalogSource.isLoading} />
        {(!items ||
          items?.length === 0) &&
          !teamMemberCatalogSource.isLoading && (
            <div className="empty-box" aria-hidden={model.isLoading}>
              <div className="catalog empty-catalog">
                <div className="icon">
                  <TeamsSVGIcon />
                </div>
                <h1 className="headline-1">
                  Members will appear here
                </h1>
              </div>
            </div>
          )}
        {items && items.length > 0 && (
          <>
            <table className="team-members team-members-core">
              <tbody>
                {items?.map((item, index) => {
                  if (!item) return null;

                  return (
                    <tr
                      className={`team-members-row ${!item.teamMember?.userId && 'is-pending'
                        }`}
                      key={index}>
                      <td
                        className={
                          'name-col ' + (sortField === 'name' && 'active')
                        }>
                        <span>{item.teamMember?.user?.name || '-'}</span>
                      </td>
                      <td
                        className={
                          'username-col ' +
                          (sortField === 'username' && 'active')
                        }>
                        <span>{item.teamMember?.user?.username || '-'}</span>
                      </td>
                      <td
                        className={
                          'email-col ' + (sortField === 'email' && 'active')
                        }>
                        <span>{item.teamMember?.user?.email || '-'}</span>
                      </td>
                      <td
                        className={
                          'role-col ' + (roleFilter && 'active')
                        }>
                        <DropdownInput
                          className=""
                          showFeedback={false}
                          value={item.teamMember?.role || '-'}
                          items={model.roleItems}
                          persistentFeedback={false}
                          notDeselectable
                          onChange={(e: SyntheticEvent, value: RoleProps) =>
                            model.setTeamMemberRole(item.teamMember, value)
                          }
                          disabled={item.teamMember?.role === 'Owner'}
                        />
                      </td>
                      <td className={'analytics-col'}>

                        <ToggleInput
                          className="medium"
                          toggled={!item.teamMember?.teamAnalyticsExclude}
                          toggleLayout={ToggleLayout.Buttons}
                          toggledLabel='Include'
                          notToggledLabel='Exclude'
                          name={'includeAnalytics'}
                          onChange={() => { 
                            model.toggleTeamMemberAnalytics(item.teamMember, !item.teamMember.teamAnalyticsExclude) 
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {!teamMemberCatalogSource.isLoading &&
              <CatalogFooter
                model={model.teamMemberCatalog} />}
          </>
        )}
      </>
    </Catalog>
  );
});
