import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useRouteModel } from './routeHooks';
import { AuthFlowResponseType } from '../services/auth/authFlowSchema';
import { AuthFlowResponseInterpreter } from '../components/auth';
import { LoadPage } from '../components';
import { AuthRouteState } from './authRouteState';

import { TRACE } from '../core/debug/debugMacros';

type Props = RouteProps;

export const AuthRoute = observer((props: Props) => {

  const model = useRouteModel((kernel) => new AuthRouteState(kernel.store));

  TRACE(model, `render()`, { props, model });

  const { flowResponse } = model;
  if (model.isLoading)
    return <LoadPage />

  switch (flowResponse?.responseType) {
    case AuthFlowResponseType.PassThroughAuthRoute:
      break;

    default:
      return (
        <AuthFlowResponseInterpreter
          response={flowResponse}
          error={model.error} />
      );
  }

  return <Route {...props} />;
});