import { AsyncResult } from '../core';
import { AsyncLoader } from '../core/async/asyncLoader';
import { Error } from '../core/error';
import { Kernel } from './kernel';
import { ServiceName } from './serviceSchema';

export class ServiceLoader {

  constructor(kernel: Kernel) {
    this.kernel = kernel;
  }

  readonly kernel: Kernel;

  private readonly loaders = new Map<ServiceName, AsyncLoader>();
  private readonly services = new Map<ServiceName, any>();

  async load(name: ServiceName) {

    const { services } = this;
    if (services.has(name))
      return services.get(name);

    let callback = async (): AsyncResult => {

      const serviceInst = await (async () => {
        switch (name) {
          case ServiceName.Resources:
            const { ResourceService } = await import('../services/resources/resourceService');
            return new ResourceService(this.kernel.store);

          case ServiceName.Vendor:
            const { VendorService } = await import('../services/vendor/vendorService');
            return new VendorService(this.kernel.store);
        }
      })();

      if (serviceInst) {
        this.services.set(name, serviceInst);
        return [serviceInst];
      }

      return [null, new Error('InternalError', `An error occured while trying to load the "${name}" service.`)];
    }

    const { loaders } = this;
    let loader = loaders.get(name);
    if (!loader) {
      loader = new AsyncLoader(callback);
    }

    const [serviceInst] = await loader.load();
    return serviceInst;
  }
}