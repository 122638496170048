import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { UploadFileState } from './uploadFileState';
import { handler } from '../../core';
import {
  CloudUploadSVGIcon,
  TextInputErrorSVGIcon
} from '../../components/svg';
import {
  Button
} from '../../components/input';

type Props = {
  state: UploadFileState;
};

export const UploadFileBox = observer((props: Props) => {
  const { state } = props;

  const className = classNames('upload-file-box upload-box', {
    hover: state.isHovered,
    'drag-hover': state.isDragHovered,
    'drag-error': state.isDragHovered && state.dragError
  });

  const iconElem =
    state.isDragHovered && state.dragError ? (
      <TextInputErrorSVGIcon />
    ) : (
      <CloudUploadSVGIcon />
    );

  return (
    <div
      className={className}
      onDrop={handler(state, 'handleDrop')}
      onPointerEnter={handler(state, 'handlePointerEnter')}
      onPointerLeave={handler(state, 'handlePointerLeave')}>
      <div className="upload-file-wrapper">
        <div className="upload-icon">{iconElem}</div>

        <div className="text-row">
          {state.isDragHovered ? (
            state.dragError ? (
              state.dragErrorMessage
            ) : (
              state.dragDropMessage
            )
          ) : (
            <>
              <span>Drag and Drop files</span>

              <small>or</small>

              <Button
                label="Select Files"
                className="secondary medium file-upload-btn"
              />
            </>
          )}
        </div>

        <input
          type="file"
          className="upload-file-region file-input"
          role="presentation"
          multiple={state.jobId ? false : true}
          onDragEnter={handler(state, 'handleDragEnter')}
          onDragLeave={handler(state, 'handleDragLeave')}
          onChange={handler(state, 'handleFileChange')}
          onClick={handler(state, 'handleFileClick')}
          disabled={state.isDisabled}
        />
      </div>
    </div>
  );
});

