import './momentCloud.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { MomentCloudState } from './momentCloudState';
import { TopicNodeElement } from './topicNodeElement';
import { SpeakerNodeElement } from './speakerNodeElement';
import { EdgeElement } from './edgeElement';

type Props = {
  model: MomentCloudState
}

const CanvasItems = observer(({
  model
}: Props) => {

  const { graph } = model;

  return (

    <ul className="canvas-items">

      {graph.topicSpeakerEdges.map(edge => {
        return (
          <li key={edge.key}
            className="edge-item">
            <EdgeElement model={edge} />
          </li>
        );
      })}

      {graph.topicNodes.map(node => {
        const style = {
          transform: `translate(${node.renderX}px, ${node.renderY}px)`
        };

        return (
          <li key={node.key}
            className="topic-item node-item"
            style={style}>
            <TopicNodeElement
              model={node}
              parentModel={model} />
          </li>
        );
      })}

      {graph.speakerNodes.map(node => {
        const style = {
          transform: `translate(${node.renderX}px, ${node.renderY}px)`
        };

        return (
          <li key={node.key}
            className="speaker-item node-item"
            style={style}>

            <SpeakerNodeElement
              model={node}
              parentModel={model} />
          </li>
        );
      })}
    </ul>
  );
});

export const MomentCloudCanvas = observer(({
  model
}: Props) => {

  const className = classNames('moment-cloud-canvas');

  const sceneStyle = {
    transform: `translate(${model.pan.x}px, ${model.pan.y}px) scale(${model.zoom})`
  }
  const sceneClassName = classNames('canvas-scene', {
    'disable-transitions': model.disableSceneTransitions
  });

  return (
    <div className={className}
      id={model.refProxy.domId}
      ref={model.refProxy.ref}>

      <div ref={model.viewportRefProxy.ref}
        className="canvas-viewport"
        onPointerDown={evt => model.handleViewportPointerDown(evt)}
        onWheel={evt => model.handleViewportWheel(evt)}>

        <div className={sceneClassName} style={sceneStyle}>
          <CanvasItems model={model} />
        </div>
      </div>
    </div >
  );
});