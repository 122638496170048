import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import { computed, makeObservable } from 'mobx';
import { Moment as MomentProps, MomentSource, ClipType, MomentMetadata, UserMomentPermission, MomentConfidence, MomentWordConfidence } from '@clipr/lib';
import { timeLabel } from '../components/utils';
import { shortDurationLabel } from '../store/formatters';
import { Store } from '../store/store';
import { StoreNode } from '../store';
import { JobModel } from './job';
import { SpeakerModel } from './speaker';
import { Bookmark } from './bookmark';
import { BookmarkList } from './bookmarkList';
import { MomentKind } from './momentSchema';
import { TrackModel } from './track';
import { ITimeRegion, timestampNorm } from '../core/time';
import { IJobDependency } from './jobSchema';

export type { Moment as MomentProps } from '@clipr/lib';

const checkPositive = (val: number, prop: string, moment: MomentModel) => {
  if (!(val > 0))
    console.warn(`Moment#${moment.id} has an invalid ${prop} ${val}.`);
  return val;
}

const checkRatio = (val: number, prop: string, moment: MomentModel) => {
  // TODO: exclude in production
  if (!(val >= 0 && val <= 1))
    console.warn(`Moment#${moment.id} has an invalid ${prop} ${val}.`);
  return val;
}

export const momentSorter = (a: MomentModel, b: MomentModel) => a.startTime - b.startTime;

export type Moment = MomentModel;

export type Topic = MomentModel & {
  clipType: 'Topic',
  isTopic: true
}
export type SubTopic = MomentModel & {
  clipType: 'SubTopic',
  isSubtopic: true
}
export type Transcript = MomentModel & {
  clipType: 'Transcript',
  isTranscript: true
}
export type Generic = MomentModel & {
  clipType: 'Moment',
  isGeneric: true
}

export type MomentCreatedBy =
  'clipr' | 'trainer';

export class MomentModel
  extends StoreNode
  implements ITimeRegion, IJobDependency {

  constructor(props: Partial<MomentProps>, store: Store) {
    super(store);
    makeObservable(this);
    Object.assign(this, omit(props, [
      'job'
    ]));

    this.userPermissions = props.userPermissions ?? [];
  }

  readonly nodeType: 'Moment' = 'Moment';

  readonly id!: string;
  readonly archivedAt!: string | null;
  readonly createdAt!: string;
  readonly createdBy!: MomentCreatedBy;
  readonly clipType!: ClipType;
  readonly name!: string;
  readonly momentType!: string;
  readonly keywords!: string[];
  readonly startTime!: number;
  readonly endTime!: number;
  readonly description!: string | null;
  readonly summary!: string | null;
  readonly importance!: number;
  readonly sentiment!: string;
  readonly jobId!: string;
  readonly source!: MomentSource;
  readonly speakerId!: string;
  readonly trackId!: string;
  readonly languageCode!: string;
  readonly visibleToConsumer!: string;
  readonly userPermissions!: UserMomentPermission[];
  readonly metadata!: MomentMetadata;
  readonly confidence!: MomentConfidence | null;
  readonly wordConfidence!: Array<MomentWordConfidence> | null;

  get minConfidence(): number | null {
    if (!this.confidence)
      return null;

    return this.confidence.min;
  }

  get avgConfidence(): number | null {
    if (!this.confidence)
      return null;

    return this.confidence.avg;
  }

  get computedMinConfidence(): number | null {
    if (!this.confidence || this.wordConfidenceNorm.length === 0)
      return null;

    if (this.minConfidence === 1) {
      return 1;
    }

    const min = Math.min(...this.wordConfidenceNorm.map(item => item.confidence));

    return min;
  }

  get minConfidencePercentage(): number | null {
    if (this.computedMinConfidence === null)
      return null;

    return Math.ceil(this.computedMinConfidence * 100);
  }

  get computedAvgConfidence(): number | null {
    if (!this.confidence || this.wordConfidenceNorm.length === 0)
      return null;

    if (this.avgConfidence === 1) {
      return 1;
    }

    const sum = this.wordConfidenceNorm.reduce((acc, curr) => acc + curr.confidence, 0);

    return (sum / this.wordConfidenceNorm.length);
  }

  get avgConfidencePercentage(): number | null {
    if (this.computedAvgConfidence === null)
      return null;

    return Math.ceil(this.computedAvgConfidence * 100);
  }

  get wordConfidenceNorm(): MomentWordConfidence[] {
    if (!this.wordConfidence)
      return [];

    return this.wordConfidence.filter(item => item.confidence > 0);
  }

  @computed
  get userHasEditPermission(): boolean {
    return this.userPermissions.includes(UserMomentPermission.Edit);
  }

  @computed
  get userHasViewPermission(): boolean {
    return this.userPermissions.includes(UserMomentPermission.View);
  }

  @computed
  get isOwnerAuthenticated(): boolean {
    return this.store.user?.id === this.createdBy;
  }

  @computed
  get speaker(): SpeakerModel | null {
    if (this.isTranscript)
      return this.store.speakerManager.speakers.find(speaker => speaker.id === this.track?.speakerId) || null;
    else
      return this.store.speakerManager.speakers.find(speaker => speaker.id === this.speakerId) || null;
  }

  @computed
  get extractor(): string | null {
    return this.metadata?.extractor || null;
  }

  @computed
  get job(): JobModel | null {
    return this.store.getJob(this.jobId) || null;
  }

  @computed
  get track(): TrackModel | null {
    return this.job?.getTrack(this.trackId) || null
  }

  @computed
  get duration(): number {
    return checkPositive(this.endTime - this.startTime,
      'duration', this);
  }

  @computed
  get isTopic(): boolean {
    return this.clipType === 'Topic';
  }

  @computed
  get isSubtopic(): boolean {
    return this.clipType === 'SubTopic';
  }

  @computed
  get isTranscript(): boolean {
    return this.clipType === 'Transcript';
  }

  @computed
  get isGeneric(): boolean {
    return !this.isTopic && !this.isTranscript;
  }

  @computed
  get hasSpeaker(): boolean {
    return !!(this.speakerId || this.speaker?.id);
  }

  @computed
  get actualSpeaker(): SpeakerModel | null {
    return this.store.maybeGetSpeaker(this.actualSpeakerId);
  }

  @computed
  get actualSpeakerId(): string | null {
    return this.speakerId ?? this.speaker?.id ?? null;
  }

  @computed
  get isSpeakerPublic(): boolean {
    return !!this.actualSpeaker?.isPublic;
  }

  @computed
  get isSpeakerPrivate(): boolean {
    return (this.actualSpeaker && !this.actualSpeaker.isPublic) ?? false;
  }

  @computed
  get momentKind(): MomentKind {
    switch (this.clipType) {
      case 'Topic':
      case 'SubTopic':
      case 'Transcript':
      case 'Paragraph':
      case 'Chapter':
        return this.clipType;
    }
    return 'Generic';
  }

  @computed
  get shouldHaveParentClip(): boolean {
    return this.clipType === 'SubTopic';
  }

  @computed
  get parent(): MomentModel | null {
    const overlappingTopics = this.job?.getActiveTopicsOverlappingInterval(this.startTimeNorm, this.endTimeNorm);
    return overlappingTopics?.find(el => el.id !== this.id) ?? null;
  }

  @computed
  get containingParent(): MomentModel | null {
    const overlappingTopics = this.job?.getActiveTopicsContainingInterval(this.startTimeNorm, this.endTimeNorm);
    return overlappingTopics?.find(el => el.id !== this.id) ?? null;
  }

  @computed
  get parentTopic(): Topic | null {
    return this.parent as Topic;
  }
  @computed
  get parentTopicId(): string | null {
    return this.parent?.id || null;
  }

  @computed
  get hasContainingParentTopic(): boolean {
    return !!this.containingParent;
  }

  @computed
  get isUncontainedSubtopic(): boolean {
    return this.isSubtopic && !this.hasContainingParentTopic;
  }

  @computed
  get childSubTopics(): SubTopic[] {
    const subTopics = (this.job?.activeSubtopics || []) as SubTopic[];
    return subTopics.filter(sub => sub.parentTopic?.id === this.id) || [];
  }

  @computed
  get hasChildSubtopics(): boolean {
    return this.childSubTopics.length > 0;
  }

  // get startTime and endTime normalized to the player unit of measure (rounded to sec)
  @computed
  get startTimeNorm(): number {
    return timestampNorm(this.startTime);
  }

  @computed
  get endTimeNorm(): number {
    return timestampNorm(this.endTime);
  }

  /** The start time as a ratio of the video duration. */
  @computed
  get startTimeRatio(): number {
    if (!this.job)
      return 0; // TODO: warn

    return checkRatio(this.startTime / this.job?.videoDuration,
      'startTimeRatio', this);
  }

  @computed
  get startTimeLabel(): string {
    return timeLabel(this.startTime);
  }

  /** The end time as a ratio of the video duration. */
  @computed
  get endTimeRatio(): number {
    if (!this.job)
      return 0; // TODO: warn

    return checkRatio(this.endTime / this.job?.videoDuration,
      'endTimeRatio', this);
  }

  @computed
  get endTimeLabel(): string {
    return timeLabel(this.endTime);
  }

  /** The moment's duration as a ratio of the video duration. */
  @computed
  get durationRatio(): number {
    if (!this.job)
      return 0; // TODO: warn

    return checkRatio(this.duration / this.job?.videoDuration,
      'duration', this);
  }

  @computed
  get durationLabel(): string {
    return timeLabel(this.duration);
  }

  @computed
  get shortDurationLabel() {
    return shortDurationLabel(this.duration);
  }

  /** Gets the BookmarkLists which contain this Moment. */
  @computed
  get bookmarkLists(): BookmarkList[] {
    return this.bookmarks.map(bkm => bkm.list);
  }

  /** Gets the Bookmarks which contain this Moment. */
  @computed
  get bookmarks(): Bookmark[] {
    return this.store.bookmarks.filter(bkm => bkm.momentId === this.id);
  }

  @computed
  get hasBookmark(): boolean {

    return this.job?.relatedMomentBookmarks
      .some(bkm => bkm.momentId === this.id) || false;
  }

  /**
   * Verifies both the actual value set on the current instance for the consumer visibility
   * but also the visibility value set on the parent Track, if available.
   * ---
   * Truth table:
   * 
   * | Moment | Track | Output
   * | ---    | ---   | ---
   * | false  | null  | false
   * | false  | false | false
   * | false  | true  | false
   * | true   | null  | true
   * | true   | false | false
   * | true   | true  | true
   */
  @computed
  get isActuallyVisibleToConsumer() {
    const { track } = this;
    const trackVisible = track ? track.visibleToConsumer : true;
    return this.visibleToConsumer && trackVisible;
  }

  @computed
  get typeLabel(): string {
    return this.momentType || this.clipType || 'Moment';
  }

  getApiInput() {
    return pickBy(Object.assign({}, this), v => v !== null);
  }
}
