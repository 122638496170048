import { ReactNode } from 'react';
import { WidgetParams } from '../../services/widget/widgetParams';

export type TeamWidgetParamDescriptor = {
  // eslint-disable-next-line
  name: keyof WidgetParams | 'auth' | 'meta.${metadataField}' | '/public/' | '/[teamId]' | 'authConnection',
  description: ReactNode,
  type?: 'boolean' | 'string' | 'number' | 'string[]' | string[],
  values?: string[] | string,
  required?: boolean,
  defaultValue?: string,
  paramType?: 'query' | 'path'
}

export const TeamWidgetParamsDescription: TeamWidgetParamDescriptor[] = [
  {
    name: '/public/',
    description: 'To be used when the library is shareable to avoid the authentication wall; To be excluded altogether for private libraries',
    paramType: 'path',
    required: false
  },
  {
    name: '/[teamId]',
    description: 'The library ID',
    paramType: 'path',
    type: 'string',
    required: true
  },
  {
    name: 'view',
    description: 'The layout of the library\'s videos',
    values: ['list', 'grid', 'carousel'],
    type: ['list', 'grid', 'carousel'],
    defaultValue: 'grid',
  },
  {
    name: 'theme',
    description: 'The theme of the widget',
    values: ['light', 'dark'],
    type: ['light', 'dark'],
    defaultValue: 'light',
  },
  {
    name: 'tags',
    description: [
      'List of tags, separated by comma, to match against the tags field of videos.',
      'e.g.: tags=test1,test2,test3'
    ],
    type: 'string[]',
  },
  {
    // eslint-disable-next-line
    name: 'meta.${metadataField}',
    description: [
      'Filtering by metadata. Keys and values are case insensitive.',
      '** List of supported metadata fields are: startTime, endTime, session, track, level, featured, location, publishDate'
    ],
    type: 'string',
  },
  {
    name: 'sort',
    description: [
      'Sorting ascending or descending by the sort order numbers assigned to your videos. In order to change the position of a video within a layout, please access the video details window in the library dashboard and assign a sort number to your videos.'
    ],
    type: ['asc', 'desc', 'none'],
    values: ['asc', 'desc', 'none']
  },
  {
    name: 'showTeamName',
    description: 'Display / hide the library name',
    type: 'boolean',
    defaultValue: 'true',
  },
  {
    name: 'showSourceBadge',
    description: 'Display / hide the source badge in thumbnail',
    type: 'boolean',
    defaultValue: 'true',
  },
  {
    name: 'showProfile',
    description: 'Display / hide the user profile',
    type: 'boolean',
    defaultValue: 'true',
  },
  {
    name: 'showTopicTags',
    description: 'To enable / disable the tags on topics',
    type: 'boolean',
    defaultValue: 'true'
  },
  {
    name: 'allowShare',
    description: 'To enable / disable share video and share moments buttons',
    type: 'boolean',
    defaultValue: 'true'
  },
  {
    name: 'allowDownload',
    description: 'To enable / disable download transcript button',
    type: 'boolean',
    defaultValue: 'true'
  },
  {
    name: 'showHelp',
    description: 'Display / hide the help button',
    type: 'boolean',
    defaultValue: 'true'
  },
  {
    name: 'disableReactions',
    description: 'Display / hide the reactions button',
    type: 'boolean',
    defaultValue: 'true'
  },
  {
    name: 'disableComments',
    description: 'Allow / not allow the user to toggle the comment`s section',
    type: 'boolean',
    defaultValue: 'true'
  },
  {
    name: 'disableTranscript',
    description: 'Allow / not allow the user to toggle the transcript`s section',
    type: 'boolean',
    defaultValue: 'true'
  },
  {
    name: 'disableIndex',
    description: 'Allow / not allow the user to toggle the index`s section',
    type: 'boolean',
    defaultValue: 'true'
  },
  // TODO: THIS IS REUSED, MAKE A SEPARATE VARIABLE, DO NOT COPY PASTE !!!!!!!!!!!!!!!!!
  {
    name: 'auth',
    description: [
      'The Authentication mode',
      'When not specified it will display shareable resources and will also allow the users to authenticate in the widget. If the resource is private, a "Connect" button will be displayed, requiring the user to sign in in order to view the resource',
      '"wait" - will display a spinner until a JWT token will be received via postMessage',
      '"none" - will only enable viewing of shareable resources and won\'t allow the users to authenticate in the widget'
    ],
    type: ['wait', 'none'],
    values: ['wait', 'none'],
  },
  // TODO: since the typings are not shared and there's no time for refactoring, I had to copy paste this as well
  {
    name: 'autoLogin',
    description: 'If not specified or set to true it will attempt to auto login the user based on a previous session in CLIPr, regardless of whether that was in the widget or in the CLIPr app. If set to false then when first accessing the widget it will behave as if the user is not authenticated',
    type: 'boolean',
    defaultValue: 'true'
  },
  {
    name: 'authConnection',
    description: 'If specified it will bypass the normal CLIPr login screen, and use the specified Auth0 connection directly',
    type: 'string'
  },
  {
    name: 'logo',
    description: [
      'To replace the CLIPr logo with a desired logo; the path to the logo should be URL encoded before passing it to the iframe.',
      '** logo=none will display only the dots'
    ],
    type: 'string'
  },
  {
    name: 'customRedirect',
    description: 'An url to be used as the origin for the shared links. The given url is appended with all the widget params needed to construct an embed widget on the target url page',
    type: 'string'
  }
]