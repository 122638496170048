import './trainerTimeline.scss';

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { PlayheadAdapter } from '../player';
import { TimelineRuler } from './components/timelineRuler';
import { TimelineToolbar, EditMomentsToolbar } from './components/timelineToolbar';
import { TimelineTrack } from './components/timelineTrack';
import { TrainerTimelineState } from './trainerTimelineState';
import { MenuButton } from '../../components/menu';
import { VisibleOnSVGIcon, VisibleOffSVGIcon } from '../svg';
import { Tooltip } from '../overlays/tooltip';
import { PlayerSectionOverlay } from '../playerShared/playerSectionOverlay';

type Props = {
  model: TrainerTimelineState;
};

const TrackColor = {
  Topic: '#FFCE73',
  SubTopic: '#6C5DD3',
  Paragraph: '#C4C4C4',  // TODO (@frontend): set a different color
  Chapter: '#FFCE73',  // TODO (@frontend): set a different color
  Transcript: '#C4C4C4',
  Moment: '#2FC7A2',
  ActionItem: '#FFCE73',  // TODO (@frontend): set a different color
};

export const TrainerTimeline = observer((props: Props) => {
  const { model } = props;
  const { job, isTranscriptProcessing } = model;
  const multipleTranscriptTracks = model?.job?.transcriptTracks && model?.job?.transcriptTracks.length > 1;

  const secwidth = 10 * model.zoom;
  const width = model.durationLayout * secwidth;

  useEffect(() => {
    model.mounted();
    return () => model.unmounted();
  }, [model]);

  return (
    <section className="trainer-timeline">
      {model.isEditMomentsActive ?
        <EditMomentsToolbar
          model={model} /> :
        <TimelineToolbar
          model={model} />}

      <div className="timeline-content">

        <PlayerSectionOverlay
          visible={isTranscriptProcessing}
          label={"Transcripts"}
          status={"processing"} />

        <aside className="sidebar">
          <div className="sidebar-gutter" />
          <div
            className="sidebar-body"
            ref={model.sidebarProxy.ref}
            onScroll={model.handleSidebarVerticalScroll}>

            <div className="sidebar-tracks">
              {job?.tracks.map((track) => {

                const className = `track-label track-row ${!track.visibleToConsumer ? 'user-hidden' : 'user-visible'}`
                const { lanes } = track;
                return (
                  <div
                    key={track.id}
                    className={className}
                    aria-selected={track?.id === model.selectedTrackId || track?.id === model.highlightedTrack?.id}
                    data-lanes={lanes.length}
                    style={{
                      // @ts-ignore
                      '--track-lanes': lanes.length,
                      '--track-color': TrackColor[track.type]
                    }}
                    onClick={(evt) => { }}>

                    <span className="track-name">
                      {track.displayName}
                      {(track.type === 'Transcript' && track.speaker?.scope !== 'public') &&
                        <span className="empty-label">(Private)</span>}
                      {track.moments.length === 0 &&
                        <span className="empty-label">(Empty)</span>}
                    </span>

                    <div className="track-controls right">
                      <Tooltip content={track.visibleToConsumer ? 'Hide entire track from consumers' : 'Show entire track to consumers'}>
                        <button className="visibility-button btn-core"
                          role="checkbox"
                          aria-checked={model.syncingTrackId === track.id ? !track.visibleToConsumer : track.visibleToConsumer}
                          onClick={() => model.invoke('updateTrackVisibility', { track })}>
                          {model.syncingTrackId === track.id ?
                            track.visibleToConsumer ? <VisibleOffSVGIcon /> : <VisibleOnSVGIcon /> :
                            track.visibleToConsumer ? <VisibleOnSVGIcon /> : <VisibleOffSVGIcon />}
                        </button>
                      </Tooltip>

                      <MenuButton
                        layout="horizontal"
                        className="track-menu">
                        <button
                          className="menu-item-btn btn-core"
                          onClick={() => {
                            model.invoke('editTrack', {
                              jobId: job.id,
                              trackId: track.id
                            });
                          }}
                        >
                          Edit
                        </button>

                        {track.speaker &&
                          track.moments.length > 0 &&
                          multipleTranscriptTracks &&
                          <button
                            className="menu-item-btn btn-core"
                            onClick={() => {
                              model.invoke('mergeTracks', {
                                trackId: track.id,
                                jobId: job.id
                              });
                            }}
                          >
                            Merge with
                          </button>}

                        {track.isDuplicable &&
                          <button
                            className="menu-item-btn btn-core"
                            onClick={() => {
                              model.invoke('duplicateTrack', {
                                trackId: track.id,
                                jobId: job.id
                              });
                            }}
                          >
                            Duplicate
                          </button>}

                        <button
                          className="menu-item-btn btn-core"
                          onClick={() => {
                            model.dispatch('openDeleteTrackPopup', {
                              job: job,
                              jobId: job.id,
                              trackId: track.id,
                            });
                          }}
                        >
                          Delete
                        </button>
                      </MenuButton>
                    </div>

                  </div>
                );
              })}
            </div>
          </div>
        </aside>

        <div
          ref={model.viewportProxy.ref}
          className="timeline-viewport"
          aria-grabbed={model.isPanning}
          onPointerDown={(evt) => model.handleSurfacePointerDown(evt)}
          onScroll={model.handleHorizontalScroll}
          onWheel={model.handleViewportWheel}>

          <div
            ref={model.containerProxy.ref}
            className="timeline-container"
            style={{ width: width + 'px' }}>

            <TimelineRuler
              model={model}
              unitWidth={secwidth} />

            <div
              className="timeline-track-viewport"
              ref={model.surfaceProxy.ref}>

              <div className="timeline-tracks">
                {job?.tracks.map((track) => {
                  return (
                    <TimelineTrack
                      key={track.id}
                      track={track}
                      model={model} />
                  );
                })}
              </div>
            </div>

            <PlayheadAdapter
              proxyRef={model.playhead.componentRef}
              model={model.playhead}
              containerRef={model.containerProxy} />
          </div>
        </div>
      </div>
    </section>
  );
});