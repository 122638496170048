import React, { ReactNode } from 'react';
import { PageBackButton } from './pageBackButton';
import { CloseBackButtonSVGIcon, ChevronLeft1SVGIcon } from '../svg/svg';
import { useRoutingService } from '../../services/routing/routingHooks';
import classNames from 'classnames';

type Props = {
  leftArrow?: boolean;
  text?: string;
  children?: ReactNode;
  onBackButtonClick?: () => void;
  stretchContent?: boolean;
}

/**
 * Header to be used for splash pages (those which contain a single specific functionality, like upload or video topic selector)
 */
export const SplashPageHeader = (props: Props) => {
  const { text, leftArrow, onBackButtonClick, stretchContent } = props;
  const routingService = useRoutingService();

  const backButtonRoute = routingService.getBackRoute();

  return (
    <header className="splash-page-header">
      <div className={
        classNames('flex-left', { 'self-stretch': stretchContent })}>
        {(backButtonRoute || onBackButtonClick) &&
          <PageBackButton
            className={leftArrow ? 'left-arrow-back-btn' : 'close-back-btn'}
            leftIcon={leftArrow ? <ChevronLeft1SVGIcon /> : <CloseBackButtonSVGIcon />}
            route={backButtonRoute || onBackButtonClick} />}
        {text && <div className="headline">{text}</div>}
        {props.children}
      </div>
    </header >
  );
}