
import './teams.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { TeamDictionaryItemState, TeamDictionaryWindowState } from './teamDictionaryWindowState';
import { TeamDictionaryItem } from './teamDictionaryItem';
import { Button, ButtonControlled } from '../input';

type Props = {
  model: TeamDictionaryWindowState;
  visible?: boolean;
};

export const TeamDictionaryWindow = observer((props: Props) => {
  const { model } = props;
  const { submit, teamDictionaryList, team, removeDictionaryitem, addDictionaryItem } = model;

  return (
    <Window
      model={model.window}
      visible={props.visible}
      className="team-dictionary-window"
      isLoading={model.isLoading}>
      <WindowHeader model={model.window} title="Dictionary" />

      <div className="window-content">
        <p>
          Enter the correct spelling of names, words, and terminology below, and the common misspellings that might be expected to occur if they were not corrected. This will help CLIPr to spell correctly when ingesting your videos.
        </p>

        <ul className="team-dictionary-list">
          {teamDictionaryList.map((model: TeamDictionaryItemState, i: number) => {
            return (
              <TeamDictionaryItem
                model={model}
                key={i}
                defaultVocabulary={team?.defaultVocabulary}
                isLast={teamDictionaryList.length - 1 === i}
                onClickRemove={() => removeDictionaryitem(i)}
                onClickAdd={() => addDictionaryItem()}
              />
            );
          })}
        </ul>
        <div className="btn-group right">
          <Button
            className="primary btn-outline team-dictionary-btn  large"
            label="Cancel"
            disabled={model.isLoading}
            onClick={() => model.close()}
          />

          <ButtonControlled
            className="primary team-dictionary-btn large"
            label="Done"
            model={model.submitButton}
            onClick={() => submit()}
          />
        </div>
      </div>

    </Window>
  );
});
