import { useCallback } from 'react';
import { Maybe } from '../../core';
import { StoreNode } from '../../store';

type Params = {
  target: Maybe<StoreNode>,
  id: string
}

export const useAnchor = ({ target, id }: Params) => {

  const anchorRef = useCallback((node: HTMLElement) => {
    if (!target)
      return;
      
    // TODO: might fail when the node gets updated
    if (node !== null) {
      target.dispatch('Overlays', 'anchorMounted', { id: id, node: node });

    } else {
      target.dispatch('Overlays', 'anchorUnmounted', { id: id });
    }
  }, [target, id]);

  if (!target)
    return [null, id];

  return [anchorRef, id];
}