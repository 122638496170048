import { action, makeObservable, observable } from 'mobx';
import { Store } from '../store/store';
import { StoreNode } from '../store';
import { IComponentState } from '../components';
import { RouteContext } from './routeContext';
import { AuthFlowResponse } from '../services/auth/authFlowSchema';
import { RouteType } from './routeSchema';
import { AsyncResult } from '../core';
import { Error } from '../core/error';
import { RouteState } from './routeStateMixin';

import { INIT_DEBUGGER, TRACE } from '../core/debug/debugMacros';

export class WidgetRouteState
  extends RouteState(StoreNode)
  implements IComponentState {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    INIT_DEBUGGER(this, { color: 'teal' });
    TRACE(this, `constructor()`);
  }

  readonly nodeType = 'WidgetRoute';
  readonly routeType = RouteType.Widget;

  @observable flowResponse: AuthFlowResponse | null = null;

  @action
  async attached(routeContext: RouteContext) {
    TRACE(this, `attached()`, { routeContext }, this.__traceState);

    this.reset();
    this.baseAttached(routeContext);

    this.setLoading();

    const { authService, routingService, widgetService } = this.store;

    widgetService.handleWidgetRoute(routeContext);
    routingService.routeAttached(routeContext);

    const [res, err] = await authService.handleWidgetRoute(routeContext);
    if (err)
      return this.setError(err);

    this.setFlowResponse(res!);
  }

  @action
  detached() {
    TRACE(this, `detached()`, this.__traceState);

    this.baseDetached();
    this.reset();

    const { routingService } = this.store;
    routingService.routeDetached();
  }

  @action
  reset() {
    this.baseReset();
    this.flowResponse = null;
  }

  @action
  setFlowResponse(res: AuthFlowResponse) {
    this.setLoaded();
    this.flowResponse = res;
  }

  @action
  handleErrorBackButtonRoute = async () => {
    this.error = null;
    return this.logout();
  }

  @action
  handleLogoutButtonClick = () => {
    this.error = null;
    return this.logout();
  }

  @action
  private async logout(): AsyncResult<AuthFlowResponse> {

    const { routeContext } = this;
    if (!this.isAttached || !routeContext)
      return [null, new Error('InvalidComponentState')];

    const [res, err] = await this.store.authService.runInlineProxyLogoutFlow({
      routeContext: this.routeContext!
    });

    if (err) {
      this.setError(err);
      return [null, err];
    }

    this.setFlowResponse(res!);
    return [res!];
  }

  private get __traceState() {
    return {
      ...this.__baseTraceState,
      flowResponse: this.flowResponse
    }
  }
}