import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../../components/overlays';
import { Button } from '../../components/input';
import { DeleteTrackState } from '.';

type Props = {
  model: DeleteTrackState;
  visible?: boolean;
};

export const DeleteTrackPopup = observer(({ model, visible }: Props) => {
  return (
    <Window
      model={model.window}
      visible={visible}
      className="delete-track-window"
      isLoading={model.isLoading}>
      <WindowHeader title="Delete" model={model.window} />

      <hr />

      <section className="window-content">
        <p>
          {' '}
          {model.isTrackEmpty
            ? 'Are you sure you want to delete this swimlane? This action cannot be undone.'
            : `This swimlane could not be deleted because there are one or more moments still associated to it. Please delete all moments in the swimlane before deleting the swimlane itself.`}
        </p>
      </section>

      <hr />

      <footer className="window-footer ">
        <div className="btn-group right">
          {model.isTrackEmpty ? (
            <>
              <Button
                label="Cancel"
                className="primary btn-outline"
                onClick={() => model.close()}
                disabled={model.isLoading}
              />

              <Button
                label="Delete"
                className="red"
                onClick={() => model.deleteSpeaker()}
                disabled={model.isLoading}
              />
            </>
          ) : (
            <Button
              label="Cancel"
              className="primary"
              onClick={() => model.close()}
              disabled={model.isLoading}
            />
          )}
        </div>
      </footer>
    </Window>
  );
});
