import { Location } from 'history';
import { match as Match, matchPath } from 'react-router-dom';
import { ObjectLiteral } from '../core';
import { PageName } from '../pages/pageSchema';
import { getRelativeUrl } from '../services/routing/routingUtils';
import { WidgetName } from '../widgets/widgetSchema';
import { IRouteInjector, RouteComponentType, RouteDescriptor, RouteType } from './routeSchema';

type Props = {
  location: Location;
  descriptor: RouteDescriptor;
}

export class RouteContext<TParams extends ObjectLiteral = ObjectLiteral> {

  constructor(props: Props) {

    const { location, descriptor } = props;

    this.descriptor = descriptor;
    this.location = location;
    this.match = matchPath(location.pathname, descriptor.path);
    this.searchParams = new URLSearchParams(this.location.search);

    Object.freeze(this);
  }

  readonly descriptor: RouteDescriptor;
  readonly location: Location<any>;

  get key() {
    return this.location.key;
  }

  get state() {
    return this.location.state;
  }

  readonly match: Match<TParams> | null;
  get params(): TParams | null {
    return this.match?.params ?? null;
  }

  get relativeUrl(): string {
    return getRelativeUrl(this.location);
  }

  get injector(): IRouteInjector | null {
    
    const { injector } = this.descriptor;
    if (typeof injector === 'function')
      return injector();

    return injector ?? null;
  }

  get routeType(): RouteType {
    return this.descriptor.routeType;
  }

  get widgetName(): WidgetName | null {
    return this.descriptor.widgetName ?? null;
  }

  get pageName(): PageName | null {
    return this.descriptor.pageName ?? null;
  }

  get componentType(): RouteComponentType | null {
    if (this.widgetName)
      return RouteComponentType.Widget;
    if (this.pageName)
      return RouteComponentType.Page;
    return null;
  }

  readonly searchParams: URLSearchParams;
}