import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ForgotPasswordPage } from '../pages/auth';
import { PageName } from '../pages/pageSchema';
import { LibraryName } from '../services/libraries/librarySchema';
import { Store } from '../store/store';
import { WidgetName } from '../widgets/widgetSchema';
import { Routes } from './routes';
import { RouteDescriptor, RouteType } from './routeSchema';

const DefaultPage = React.lazy(() => import('../pages/default/defaultPage'));

const LoginPage = React.lazy(() => import('../pages/auth/login/loginPage'));
const RegisterPage = React.lazy(() => import('../pages/auth/register/registerPage'));
const OnboardPage = React.lazy(() => import('../pages/auth/onboard/onboardPage'));
const LogoutPage = React.lazy(() => import('../pages/auth/logout/logoutPage'));

const OAuthCallbackPage = React.lazy(() => import('../pages/oauth/oauthCallbackPage'));
const OAuthLogoutPage = React.lazy(() => import('../pages/oauth/oauthLogoutPage'));
const OAuthVerifiedPage = React.lazy(() => import('../pages/oauth/oauthVerifiedPage'));
const OAuthPasswordPage = React.lazy(() => import('../pages/oauth/oauthPasswordPage'));
const OAuthLibraryCallbackPage = React.lazy(() => import('../pages/oauth/oauthLibraryCallbackPage'));

const UnsubscribePage = React.lazy(() => import('../pages/account/subscriptionsPage'));
const ProfilePage = React.lazy(() => import('../pages/account/profilePage'));

const AdminTeamsPage = React.lazy(() => import('../pages/adminTeamsPage/adminTeamsPage'));
const AnalyticsPage = React.lazy(() => import('../pages/analyticsPage/analyticsPage'));
const BookmarksPage = React.lazy(() => import('../pages/bookmarksPage/bookmarksPage'));
const BookmarkListPage = React.lazy(() => import('../pages/bookmarksPage/bookmarkListPage'));
const CreateTeamPage = React.lazy(() => import('../pages/createTeamPage/createTeamPage'));
const LiveStreamQueuePage = React.lazy(() => import('../pages/liveStreamQueuePage/liveStreamQueuePage'));
const SearchResultsPage = React.lazy(() => import('../pages/searchResultsPage/searchResultsPage'));
const ChatGptSearchResultsPage = React.lazy(() => import('../pages/chatGptSearchResultPage/chatGptSearchResultPage'));
const TeamDashboardPage = React.lazy(() => import('../pages/teamDashboardPage/teamDashboardPage'));
const TeamSettingsPage = React.lazy(() => import('../pages/teamSettingsPage/teamSettingsPage'));
const TrainerDashboardPage = React.lazy(() => import('../pages/trainerDashboardPage/trainerDashboardPage'));
const TrainerVideoPage = React.lazy(() => import('../pages/trainerVideoPage/trainerVideoPage'));
const UploadPage = React.lazy(() => import('../pages/uploadPage/uploadPage'));
const UserDashboardPage = React.lazy(() => import('../pages/userDashboardPage/userDashboardPage'));
const UserPlayerPage = React.lazy(() => import('../pages/userPlayerPage/userPlayerPage'));
const UserSplashPage = React.lazy(() => import('../pages/userSplashPage/userSplashPage'));
const JoinInvitationPage = React.lazy(() => import('../pages/joinInvitationPage'));

const ProxyAuthorizePage = React.lazy(() => import('../pages/proxy/proxyAuthorizePage'));
const ProxyDeauthorizePage = React.lazy(() => import('../pages/proxy/proxyDeauthorizePage'));

const AnalyticsWidget = React.lazy(() => import('../widgets/analyticsWidget/analyticsWidget'));
const LiveStreamQueueWidget = React.lazy(() => import('../widgets/liveStreamQueueWidget/liveStreamQueueWidget'));
const PlayerWidget = React.lazy(() => import('../widgets/playerWidget/playerWidget'));
const TeamWidget = React.lazy(() => import('../widgets/teamWidget/teamWidget'));
const UploadWidget = React.lazy(() => import('../widgets/uploadWidget/uploadWidget'));
const UploadCompactWidget = React.lazy(() => import('../widgets/uploadWidget/uploadCompactWidget'));
const ExternalLoginWidget = React.lazy(() => import('../widgets/auth/externalLogin/externalLoginWidget'));
const ProxyLoginWidget = React.lazy(() => import('../widgets/auth/proxyLogin/proxyLoginWidget'));

const TermsOfServicePage = React.lazy(() => import('../pages/legal/termsOfServicePage'));
const PrivacyPolicyPage = React.lazy(() => import('../pages/legal/privacyPolicyPage'));
const ZoomHelpPage = React.lazy(() => import('../pages/legal/zoomHelpPage'));

const NetworkErrorPage = React.lazy(() => import('../pages/error/networkErrorPage'));
const NotFoundErrorPage = React.lazy(() => import('../pages/error/notFoundErrorPage'));

export function getRouteLookup(store: Store): Record<string, RouteDescriptor> {

  const RouteLookup: Record<string, RouteDescriptor> = {};

  type GeneratorFuncElement = (props: RouteComponentProps) => React.ReactElement;
  type GeneratorFuncProps = Omit<RouteDescriptor, 'routeType' | 'element' | 'path'>;
  type GeneratorFunc = (
    path: string,
    element: GeneratorFuncElement,
    props?: PageGeneratorFuncProps) => void;

  type PageGeneratorFuncProps = Omit<RouteDescriptor, 'routeType' | 'element' | 'path' | 'pageName'>;
  type PageGeneratorFunc = (
    path: string,
    element: GeneratorFuncElement,
    pageName: PageName,
    props?: PageGeneratorFuncProps) => void;

  type WidgetGeneratorFunc = (
    path: string,
    element: GeneratorFuncElement,
    widgetName: WidgetName,
    props?: PageGeneratorFuncProps) => void;

  const register = (
    routeType: RouteType,
    path: string,
    element: GeneratorFuncElement,
    props?: GeneratorFuncProps) => {

    RouteLookup[path] = {
      path,
      element,
      routeType,
      exact: true,
      ...props
    };
  }

  const Private: PageGeneratorFunc = (path, element, pageName, props) => {
    register(RouteType.Private, path, element, {
      pageName,
      ...props
    });
  }

  const Public: PageGeneratorFunc = (path, element, pageName, props) => {
    register(RouteType.Public, path, element, {
      pageName,
      ...props
    });
  }

  const Auth: PageGeneratorFunc = (path, element, pageName, props) => {
    register(RouteType.Auth, path, element, {
      pageName,
      ...props
    });
  }

  const Widget: WidgetGeneratorFunc = (path, element, widgetName, props) => {
    register(RouteType.Widget, path, element, {
      widgetName,
      ...props
    });
  }

  const Onboard: PageGeneratorFunc = (path, element, pageName, props) => {
    register(RouteType.Onboard, path, element, {
      pageName,
      ...props
    });
  }

  const Direct: GeneratorFunc = (path, element, props) => {
    register(RouteType.Direct, path, element, props);
  }


  Private(Routes.defaultRoute(),
    () => <DefaultPage />,
    PageName.Default);

  Auth(
    Routes.login(),
    () => <LoginPage model={store.loginPage} />,
    PageName.Login);

  Auth(
    Routes.register(),
    () => <RegisterPage model={store.registerPage} />,
    PageName.Register);

  Onboard(
    Routes.onboard(),
    () => <OnboardPage model={store.onboardPage} />,
    PageName.Onboard);

  Auth(
    Routes.forgotPassword(),
    () => <ForgotPasswordPage model={store.forgotPasswordPage} />,
    PageName.ResetPassword);
    
  Direct(
    Routes.logout(),
    () => <LogoutPage />);

  Direct(
    Routes.oauthCallback(),
    () => <OAuthCallbackPage />)

  Direct(
    Routes.oauthLogout(),
    () => <OAuthLogoutPage />)

  Direct(
    Routes.oauthVerified(),
    () => <OAuthVerifiedPage />)

  Direct(
    Routes.oauthPassword(),
    () => <OAuthPasswordPage />)


  Private(
    Routes.unsubscribe(),
    () => <UnsubscribePage model={store.unsubscribePage} />,
    PageName.Subscriptions)

  Private(
    Routes.trainerDashboard(),
    () => <TrainerDashboardPage model={store.trainerDashboardPage} />,
    PageName.TrainerDashboard);

  Private(
    Routes.trainerDashboardFailedJob(),
    () => <TrainerDashboardPage model={store.trainerDashboardPage} />,
    PageName.TrainerDashboard);

  Private(
    Routes.trainerVideo(),
    () => <TrainerVideoPage model={store.trainerVideoPage} />,
    PageName.TrainerPlayer);

  Private(
    Routes.userDashboard(),
    () => <UserDashboardPage state={store.userDashboardPage} />,
    PageName.UserDashboard);

  Private(
    Routes.createTeam(),
    () => <CreateTeamPage />,
    PageName.CreateTeam);

  Private(
    Routes.search,
    () => <SearchResultsPage />,
    PageName.SearchResults);

  Private(
    Routes.searchChatGpt,
    () => <ChatGptSearchResultsPage />,
    PageName.ChatGptSearchResults);
  

  Private(
    Routes.joinInvitation(),
    () => <JoinInvitationPage />,
    PageName.JoinInvitation);


  Private(
    Routes.bookmarks(),
    () => <BookmarksPage />,
    PageName.Bookmarks);

  Private(
    Routes.bookmarkList(),
    () => <BookmarkListPage />,
    PageName.BookmarkList);

  Private(
    Routes.analytics(),
    () => <AnalyticsPage />,
    PageName.Analytics);

  Private(
    Routes.teamUpload(),
    () => <UploadPage />,
    PageName.Upload);

  Private(
    Routes.jobReplace(),
    () => <UploadPage />,
    PageName.Upload);

  Private(
    Routes.upload(),
    () => <UploadPage />,
    PageName.Upload);

  Private(
    Routes.userVideoLanding(),
    () => <UserSplashPage model={store.userSplashPage} />,
    PageName.UserSplash);

  Private(
    Routes.userSplash(),
    () => <UserSplashPage model={store.userSplashPage} />,
    PageName.UserSplash);

  Private(
    Routes.userVideo(),
    () => <UserPlayerPage model={store.userPlayerPage} />,
    PageName.UserPlayer);

  Private(
    Routes.userPlayer(),
    () => <UserPlayerPage model={store.userPlayerPage} />,
    PageName.UserPlayer);

  Private(
    Routes.teamDashboard(),
    () => <TeamDashboardPage />,
    PageName.TeamDashboard);


  Private(
    Routes.teamSettings(),
    () => <TeamSettingsPage model={store.teamSettingsPage} />,
    PageName.TeamSettings);

  Private(
    Routes.teamAnalytics(),
    () => <AnalyticsPage />,
    PageName.TeamAnalytics); // todo: check if it should match the route or the page type

  Private(
    Routes.teamLiveStreamQueue(),
    () => <LiveStreamQueuePage model={store.liveStreamQueuePage} />,
    PageName.LiveStreamQueue);

  Private(
    Routes.adminTeamsDashboard(),
    () => <AdminTeamsPage model={store.adminTeamsPage} />,
    PageName.AdminTeams);

  Private(
    Routes.account,
    () => <ProfilePage model={store.profilePage} />,
    PageName.Profile);


  Direct(
    Routes.proxyAuthorize(),
    () => <ProxyAuthorizePage />);

  Direct(
    Routes.proxyDeauthorize(),
    () => <ProxyDeauthorizePage />);

  Direct(
    Routes.oauthZoomCallback(),
    () => <OAuthLibraryCallbackPage libraryName={LibraryName.Zoom} />)

  Direct(
    Routes.oauthOneDriveCallback(),
    () => <OAuthLibraryCallbackPage libraryName={LibraryName.OneDrive} />)

  Direct(
    Routes.oauthGoogleDriveCallback(),
    () => <OAuthLibraryCallbackPage libraryName={LibraryName.GoogleDrive} />)

  Direct(
    Routes.proxyLoginWidget(),
    () => <ProxyLoginWidget />);

  Direct(
    Routes.externalLoginWidget(),
    () => <ExternalLoginWidget />);


  Widget(
    Routes.playerWidget(),
    () => <PlayerWidget model={store.playerWidget} />,
    WidgetName.Player,
    {
      injector: () => store.playerWidgetInjector,
    });

  Widget(
    Routes.teamLibraryWidget(),
    () => <TeamWidget model={store.teamLibraryWidget} />,
    WidgetName.Team,
    {
      injector: () => store.teamWidgetInjector,
    });

  Widget(
    Routes.teamLibraryPublicWidget(),
    () => <TeamWidget model={store.teamLibraryWidget} />,
    WidgetName.Team,
    {
      injector: () => store.teamWidgetInjector,
    });

  Widget(
    Routes.uploadWidget(),
    () => <UploadCompactWidget model={store.uploadCompactWidget} />,
    WidgetName.Upload,
    {
      injector: () => store.uploadWidgetInjector,
    });

  Widget(
    Routes.uploadAdvancedWidget(),
    () => <UploadWidget model={store.uploadWidget} />,
    WidgetName.AdvancedUpload,
    {
      injector: () => store.uploadWidgetInjector,
    });

  Widget(
    Routes.teamLiveStreamQueueWidget(),
    () => <LiveStreamQueueWidget model={store.liveStreamQueueWidget} />,
    WidgetName.LiveStreamQueue,
    {
      injector: () => store.liveStreamQueueWidgetInjector,
    });

  Widget(
    Routes.analyticsWidget(),
    () => <AnalyticsWidget model={store.analyticsWidget} />,
    WidgetName.Analytics,
    {
      injector: () => store.analyticsWidgetInjector,
    });

  Widget(
    Routes.teamAnalyticsWidget(),
    () => <AnalyticsWidget model={store.analyticsWidget} />,
    WidgetName.TeamAnalytics,
    {
      injector: () => store.analyticsWidgetInjector,
    });

  Widget(
    Routes.teamSettingsWidget(),
    () => <TeamSettingsPage model={store.teamSettingsPage} />,
    WidgetName.TeamSettings,
    {
      injector: () => store.teamWidgetInjector,
    });

  Widget(
    Routes.librarySearchWidget(),
    () => <SearchResultsPage />,
    WidgetName.SearchResults,
    {
      injector: () => store.teamWidgetInjector,
    });

  Widget(
    Routes.librarySearchChatGptWidget(),
    () => <ChatGptSearchResultsPage />,
    WidgetName.ChatGptSearchResults,
    {
      injector: () => store.teamWidgetInjector,
    });


  Public(
    Routes.legalTerms(),
    () => <TermsOfServicePage />,
    PageName.TermsOfService)

  Public(
    Routes.legalPrivacy(),
    () => <PrivacyPolicyPage />,
    PageName.PrivacyPolicy);

  Public(
    Routes.zoomHelp(),
    () => <ZoomHelpPage />,
    PageName.ZoomHelp);


  Direct(
    Routes.networkError(),
    () => <NetworkErrorPage />)

  Direct(
    '*',
    () => <NotFoundErrorPage />,
    {
      exact: false
    });

  return RouteLookup;
}