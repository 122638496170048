import React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip, Window, WindowHeader } from '../overlays';
import { Button, TextInputControlled, DropdownInputControlled, RadioGroupInputControlled } from '../input';
import { FileInputControlled } from '../input/fileInput';
import classNames from 'classnames';
import { getJobLevelInputLabel } from '../../entities/job/jobFeatures';
import { DateInput } from '../input/dateInput/dateInput';
import { ScheduleStreamInputs, ScheduleStreamWindowState } from './scheduleStreamWindowState';
import { TimeInput } from '../input/timeInput/timeInput';
import { CopyTextSVGIcon, InfoSVGIcon, ThrashSVGIcon } from '..';

import './scheduleStreamWindow.scss';
import { EnrichmentLevelInfoTooltip } from '../infoTooltip/enrichmentLevelInfoTooltip';
import { VisibilityInfoTooltip } from '../infoTooltip/visibilityInfoTooltip';
import { IngestInfoTooltip } from '../infoTooltip/ingestInfoTooltip';

type Props = {
  model: ScheduleStreamWindowState;
  visible?: boolean;
};

export const ScheduleStreamWindow = observer((props: Props) => {
  const { model } = props;
  const {
    layoutType,
    window,
    isLoading,
    windowTitle,
    mainInputs,
    jobId,
    inputGroup,
    formIsDirty,
  } = model;

  const { isSubmitting, isSubmitDisabled } = inputGroup;

  const className = classNames("schedule-stream-window grid-layout-window", layoutType)

  return (
    <Window
      className={className}
      model={window}
      onExited={model.onTransitionEnd}
      visible={props.visible}
      isLoading={isLoading}>
      <WindowHeader model={window} title={windowTitle} />

      <hr />

      <section className="window-content">

        <div className="form-section form-column-flex main">
          {mainInputs?.map((val, i) => generateInput(val as keyof ScheduleStreamInputs, model, i))}
        </div>

      </section>

      <hr />
      <footer className="window-footer">
        {jobId &&
          <div className="left">
            <Button
              className="btn link link-red"
              icon={<ThrashSVGIcon />}
              label="Delete"
              disabled={isSubmitting}
              onClick={model.openDeleteStreamWindow} />
          </div>
        }

        <div className="btn-group right">
          <Button
            className="primary btn-outline medium"
            label="Cancel"
            disabled={isSubmitting}
            onClick={() => model.close()} />

          <Button
            className="primary medium"
            label="Save"
            disabled={
              isSubmitDisabled ||
              isSubmitting ||
              !formIsDirty
            }
            onClick={() => model.submit()} />
        </div>
      </footer>
    </Window>
  );
});

const generateInput = (inputName: keyof ScheduleStreamInputs, model: ScheduleStreamWindowState, i: number) => {
  const {
    job,
    title,
    enrichmentLevel,
    speciality,
    language,
    videoType,
    thumbnail,
    date,
    time,
    connectionPass,
    connectionString,
    publicStream,
    ingestMode,
    playerSource,
    specialtyTooltipContent,
    streamVia } = model;

  switch (inputName) {
    case 'title':
      return (
        <TextInputControlled
          model={title}
          label={"Video Title*"}
          placeholder="Enter name"
          statusMessage={
            title.status === 'error' &&
              title.showStatusIcons
              ? title.error?.toString()
              : 'Minimum title length 5ch.'
          }
          key={i} />
      );
    case 'enrichmentLevel':
      return (
        <DropdownInputControlled
          label={getJobLevelInputLabel()}
          placeholder="Select Value"
          model={enrichmentLevel}
          notDeselectable={true}
          tooltipContent={<EnrichmentLevelInfoTooltip />}
          key={i} />
      );
    case 'speciality':
      return (
        <DropdownInputControlled
          className={"speciality-input rmar4"}
          label="Video Speciality"
          model={speciality}
          notDeselectable={true}
          tooltipContent={specialtyTooltipContent}
          key={i} />
      )
    case 'language':
      return (
        <DropdownInputControlled
          label="Video Language"
          model={language}
          notDeselectable={true}
          key={i} />
      )
    case 'videoType':
      return (
        <DropdownInputControlled
          label="Video Type"
          model={videoType}
          notDeselectable={true}
          key={i} />
      )
    case 'thumbnail':
      return (
        <div className="file-input-wrapper input-wrapper" key={i}>
          <div className="input-label">Thumbnail</div>
          <div className="input-details">Upload or select a cover image.</div>
          <div className="flex">
            <FileInputControlled
              model={thumbnail}
              multiple={true}
              layout={'preview-box'}
              layoutSize={'small'} />

            <div className="right flex-column">
              <div className="input-info">1920x1080 recommended, up to 2MB, JPG or PNG</div>
              <FileInputControlled
                model={thumbnail}
                multiple={true}
                layout={'button'}
                layoutSize={'small'}
                label={'Change Thumbnail'} />
            </div>
          </div>
        </div>
      )
    case 'date':
      return (
        <div className="toggle-input-wrapper input-wrapper stream" key={i}>
          <div className="input-label small uppercase light">Date*</div>
          <DateInput
            model={date} />
        </div>
      )
    case 'time':
      return (
        <div className="input-group input-wrapper time-input">
          <TimeInput
            model={time} />
        </div>
      )
    case 'publicStream':
      return (
        <div className="input-group radio-group">
          <label className="input-group-label">
            Visibility
            <Tooltip
              content={<VisibilityInfoTooltip showPublicInfoSection={true} />}
              className="popup-tip"
              trigger="click">
              <Button className="info-button" icon={<InfoSVGIcon />} />
            </Tooltip>
          </label>
          <RadioGroupInputControlled
            className="form-input team-privacy"
            model={publicStream} />
        </div>
      )
    case 'streamVia':
      return (
        <div className="input-group radio-group">
          <label className="input-group-label">
            Player Playback HLS
          </label>
          <RadioGroupInputControlled
            className="form-input team-privacy"
            model={streamVia} />
        </div>
      )
    case 'connectionString':
      return (
        <div className="copyable-input">
          <TextInputControlled
            model={connectionString}
            label={"Stream Server"}
            placeholder="Enter Stream Server"
            statusMessage={
              connectionString.status === 'error' &&
                connectionString.showStatusIcons
                ? connectionString.error?.toString()
                : 'Minimum Stream Server string length 5ch.'
            }
            key={i} />
          <Button
            className="btn icon-btn small settings"
            icon={< CopyTextSVGIcon />}
            onClick={() =>
              model.copyServerStreamUrl()
            } />
        </div>
      )
    case 'connectionPass':
      return (
        <div className="copyable-input">
          <TextInputControlled
            model={connectionPass}
            label={"Stream Key"}
            placeholder="Enter Stream Key"
            statusMessage={
              connectionPass.status === 'error' &&
                connectionPass.showStatusIcons
                ? connectionPass.error?.toString()
                : 'Minimum Stream Key length 5ch.'
            }
            key={i} />
          <Button
            className="btn icon-btn small settings"
            icon={< CopyTextSVGIcon />}
            onClick={() =>
              model.copyServerKey()
            } />
        </div>
      )
    case 'playerSource':
      return (
        <div className="copyable-input">
          <TextInputControlled
            model={playerSource}
            label={"Player Source"}
            placeholder="Add Player Source"
            statusMessage={
              playerSource.status === 'error' &&
                playerSource.showStatusIcons
                ? playerSource.error?.toString()
                : 'Minimum Player Source length 5ch.'
            }
            key={i} />
          {job?.liveStreamURL &&
            <Button
              className="btn icon-btn small settings"
              icon={< CopyTextSVGIcon />}
              onClick={() =>
                model.copyPlayerSource()
              } />}
        </div>
      )

    case 'ingestMode':
      return (
        <div className="input-group">
          <label className="input-group-label">
            Ingest
            <Tooltip
              content={<IngestInfoTooltip />}
              className="popup-tip"
              trigger="click">
              <Button className="info-button" icon={<InfoSVGIcon />} />
            </Tooltip>
          </label>
          <RadioGroupInputControlled
            className="form-input team-privacy"
            model={ingestMode} />
        </div>
      )
  }
};
