import React from 'react';
import { observer } from 'mobx-react-lite';
import { TeamEnrichmentLevelState } from './teamEnrichmentLevelState';
import { CheckboxInputControlled } from '../input/checkboxInput';

type TeamEnrichmentLevelListProps = {
  model: TeamEnrichmentLevelState;
};

export const TeamEnrichmentLevelList = observer(({ model }: TeamEnrichmentLevelListProps) => {
  const { enrichmentLevelItems } = model;
  return <div className="team-enrichment-level-list">
    {enrichmentLevelItems.map(item => <div key={item.id} className="team-enrichment-level-item">
      <CheckboxInputControlled model={item} />
    </div>)}
  </div>
});