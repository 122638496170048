import './userDashboardPage.scss';

import { observer } from 'mobx-react-lite';
import React, { ReactNode, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import {
  GoogleDriveSVGIcon,
  GridViewSVGIcon,
  ListViewSVGIcon,
  MyUploadsSVGIcon,
  OneDriveSVGIcon,
  ZoomSVGIcon,
} from '../../components';
import { DateInput } from '../../components/input/dateInput/dateInput';
import { Button, SearchTextInputControlled } from '../../components/input';
import { MasterPage } from '../../components/layout';
import { CatalogState } from '../../components/layout/catalogState';
import { SearchBar } from '../../components/search';
import { useStore } from '../../store/storeHooks';
import { ErrorPage } from '../error';
import { GoogleDriveTab } from '../integrations/googleDriveTab';
import { MyUploads } from './myUploadsTab';
import { OneDriveTab } from '../integrations/oneDriveTab';
import {
  UserDashboardPageParams,
  UserDashboardPageState,
} from './userDashboardPageState';
import { ZoomTab } from '../integrations/zoomTab';
import { useWindowResize } from '../../components/hooks';
import { resolutionBasedPageSize } from '../../components/utils';

export type Props = {
  state: UserDashboardPageState;
};

export const UserDashboardPage = observer((props: Props) => {
  const [width] = useWindowResize();
  const params = useParams<UserDashboardPageParams>();
  const store = useStore();
  const state = store.userDashboardPage;
  const libraries = store.libraryService.enabledLibraries;

  const showLoadMask = state.isLoading;

  useEffect(() => {
    const pageSize: number = resolutionBasedPageSize(width, true);
    state.setPagesize(pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    state.mounted(params);
    return () => state.unmounted();
  }, [state, params]);

  if (state.error) return <ErrorPage message={state.error.message} />;

  return (
    <MasterPage
      className="user-dashboard-page catalog-page with-padding"
      showLoadMask={showLoadMask}
      searchBar={<SearchComponent model={state} />}>
      <Helmet>
        <title>CLIPr - My Uploads</title>
      </Helmet>
      <header className="page-header">
        <div className="flex-left tab-navigation medium">
          <Button
            className="tab"
            label="My Uploads"
            icon={<MyUploadsSVGIcon />}
            active={state.activeTab === 'My Uploads'}
            onClick={() => state.navigateToTab('My Uploads')}
          />

          {store.user?.hasPermission('UserUpload') && (
            <>
              {libraries.includes(
                'Google Drive'
              ) && (
                  <Button
                    className="tab"
                    label="Drive"
                    icon={<GoogleDriveSVGIcon />}
                    active={state.activeTab === 'Google Drive'}
                    onClick={() => state.navigateToTab('Google Drive')}
                  />
                )}
              {libraries.includes(
                'Zoom'
              ) && (
                  <Button
                    className="tab"
                    label="Zoom"
                    icon={<ZoomSVGIcon />}
                    active={state.activeTab === 'Zoom'}
                    onClick={() => state.navigateToTab('Zoom')}
                  />
                )}
              {libraries.includes(
                'OneDrive'
              ) && (
                  <Button
                    className="tab"
                    label="OneDrive"
                    icon={<OneDriveSVGIcon />}
                    active={state.activeTab === 'OneDrive'}
                    onClick={() => state.navigateToTab('OneDrive')}
                  />
                )}
            </>
          )}
        </div>

        <div className="flex-right view-selector">
          {state.activeTab === 'Zoom' && state.store.zoom.isConnected && (
            <div className="zoom-date-selector">
              <span className="filter-label">Recordings from:</span>

              <div className="date-wrapper">
                <DateInput
                  model={state.zoomIntegrationTabState.catalogSource.datepicker}
                  onApply={() => {
                    state.zoomIntegrationTabState.catalogSource.handleDateChange();
                  }}></DateInput>
              </div>
            </div>
          )}
          {state.activeTab === 'OneDrive' && state.store.oneDrive.isConnected && (
            <div className="helper-text">
              Recently created videos will take <br /> longer to show up in the
              library.
            </div>
          )}
          <ViewSelector model={state} />
        </div>
      </header>

      {state.activeTab === 'My Uploads' && <MyUploads />}
      {state.activeTab === 'Google Drive' && (
        <GoogleDriveTab model={state.googleDriveTabState} />
      )}
      {state.activeTab === 'Zoom' && (
        <ZoomTab model={state.zoomIntegrationTabState} />
      )}
      {state.activeTab === 'OneDrive' && (
        <OneDriveTab model={state.oneDriveTabState} />
      )}
    </MasterPage>
  );
});

const SearchComponent = observer(
  ({ model }: { model: UserDashboardPageState }) => {
    const { googleDriveTabState, oneDriveTabState } = model;
    const googleDriveCatalogSource = googleDriveTabState.catalogSource;
    const oneDriveCatalogSource = oneDriveTabState.catalogSource;

    switch (model.activeTab) {
      case 'My Uploads':
        return <SearchBar key="user-search" size="small" defaultValue="" />;

      case 'Google Drive':
        return (
          <div className="search-bar">
            <SearchTextInputControlled
              model={googleDriveCatalogSource.searchBarModel}
              persistentFeedback={false}
              id="search"
              name="search"
              type="text"
              autoComplete="off"
              placeholder="Search"
              showFeedback={false}
              disabled={
                !model.store.googleDrive.isConnected ||
                !!model.store.googleDrive.error
              }
              onClear={() =>
                googleDriveCatalogSource.handleSearchInputChange({})
              }
            />
          </div>
        );
      case 'OneDrive':
        return (
          <div className="search-bar">
            <SearchTextInputControlled
              model={oneDriveCatalogSource.searchBarModel}
              persistentFeedback={false}
              id="search"
              name="search"
              type="text"
              autoComplete="off"
              placeholder="Search"
              showFeedback={false}
              disabled={
                !model.store.oneDrive.isConnected ||
                !!model.store.oneDrive.error
              }
              onClear={() => oneDriveCatalogSource.handleSearchInputChange({})}
            />
          </div>
        );

      case 'Zoom':
      default:
        return null;
    }
  }
);

const ViewSelector = observer(
  ({ model }: { model: UserDashboardPageState }) => {
    let catalog: CatalogState;

    if (model.activeTab === 'My Uploads') {
      catalog = model.jobCatalog;
    } else if (model.activeTab === 'Google Drive') {
      catalog = model.googleDriveTabState.catalog;
    } else if (model.activeTab === 'OneDrive') {
      catalog = model.oneDriveTabState.catalog;
    } else if (model.activeTab === 'Zoom') {
      catalog = model.zoomIntegrationTabState.catalog;
    } else {
      return null;
    }
    const icon: ReactNode =
      catalog.viewType === 'grid' ? <ListViewSVGIcon /> : <GridViewSVGIcon />;

    return (
      <Button
        className="large"
        icon={icon}
        onClick={() => catalog.toggleViewType()}
      />
    );
  }
);

export default UserDashboardPage;