
import React from 'react';
import { Tooltip } from '../overlays/tooltip';
import { BoxesSVGIcon, CommentsSVGIcon, /*CommentsSVGIcon,*/ TranscriptSVGIcon } from '../svg';
import { ToggleButton } from '../player/components/toggleButton';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

type Props = {
  showTranscripts: boolean;
  showIndex: boolean;
  showComments: boolean;
}
export const ToggleBarTutorialComponent = (props: Props) => {
  const itemsRef = useRef<Array<HTMLElement | null>>([])
  const [index, setIndex] = useState(0);
  const [iconsLookup, setIconsLookup] = useState<(() => JSX.Element)[]>([]);
  const [tooltips, setTooltips] = useState<string[]>([]);

  const { showTranscripts, showIndex, showComments } = props;

  const mouseOverEvent = new MouseEvent('mouseover', {
    view: window,
    bubbles: true,
    cancelable: true
  });
  const mouseOutEvent = new MouseEvent('mouseout', {
    view: window,
    bubbles: true,
    cancelable: true
  });

  useEffect(() => {
    const icons: (() => JSX.Element)[] = [];
    const tooltips: string[] = [];

    if (showTranscripts) {
      tooltips.push('Transcript');
      icons.push(TranscriptSVGIcon);
    }
    if (showIndex) {
      tooltips.push('Timeline View');
      icons.push(BoxesSVGIcon);
    }
    if (showComments) {
      tooltips.push('Comments')
      icons.push(CommentsSVGIcon);
    }

    setIconsLookup(icons);
    setTooltips(tooltips);
  }, [showTranscripts, showComments, showIndex])


  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, iconsLookup.length);
  }, [iconsLookup]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const length = itemsRef.current.length - 1;

    if (index <= length && length > 0) {
      timeout = setTimeout(() => {
        if (index > 0) itemsRef.current[index - 1]?.dispatchEvent(mouseOutEvent);
        if (index === 0) itemsRef.current[length]?.dispatchEvent(mouseOutEvent);
        itemsRef.current[index]?.dispatchEvent(mouseOverEvent);
        setIndex(index => index + 1);

        if (index === length) {
          setIndex(0);
        }
      }, 1000);
    }

    if (length === 0) {
      itemsRef.current[length]?.dispatchEvent(mouseOverEvent);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [index, mouseOverEvent, mouseOutEvent]);


  return (
    <div id="tutorial-user-player-toggle-bar">
      <ul className="control-group">
        {
          iconsLookup.map((Icon, index) => {
            return (
              <Tooltip
                key={tooltips[index]}
                className="tutorial-player-tooltip"
                arrowClassName="tutorial-player-tooltip-arrow"
                content={tooltips[index]}>
                <li
                  ref={el => itemsRef.current[index] = el}>
                  <ToggleButton
                    icon={<Icon />}
                    pressed={false}
                  />
                </li>
              </Tooltip>
            );
          })
        }
      </ul>
    </div>
  )
}