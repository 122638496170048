import '../media/media.scss';
import './manualIngestWindow.scss';

import React, { CSSProperties } from 'react';
import { observer } from 'mobx-react-lite';
import { LiveStreamChannels } from '@clipr/lib';
import { Window, WindowHeader } from '../overlays';
import { Button } from '../input';
import { getJobLiveStreamPlaylistLabel } from '../../entities/job/jobLiveStreamPlaylist';
import { ManualIngestWindowState } from './manualIngestWindowState';
import { LiveSVGIcon } from '..';
import { Thumb } from '../media';
import classNames from 'classnames';

type Props = {
  model: ManualIngestWindowState;
  visible?: boolean;
};

export const ManualIngestWindow = observer((props: Props) => {
  const { model, visible } = props;
  const { job, window, isLoading } = model;

  const previewStyle: CSSProperties = {
    ['--aspect-ratio' as any]: 9 / 16,
  };

  const liveClass = classNames('card-thumb dock', {
    'live-card-placeholder': !job?.posterURL && !model.store.uiService.logo,
  });

  return (
    <Window
      className="manual-ingest-window"
      model={window}
      visible={visible}
      isLoading={isLoading}>
      <WindowHeader
        model={window}
        title="Manage Manual Ingestion" />

      <hr />

      <section className="window-content">
        <div className="description">
          <p className="title">Manual ingestion - Select your feed</p>
          <p className="content">Choose the version you want CLIPr to process, and hit “Ingest.” You will be notified when the recording is ready.</p>
        </div>

        <div className="ingest-section">
          {Object.values(LiveStreamChannels)?.map((value: LiveStreamChannels) => {

            if (!job || !value)
              return null;

            return (
              <div
                key={getJobLiveStreamPlaylistLabel(value)}
                className="ingest-section-container">

                <div className="ingest-section-card">
                  <div
                    className="card-preview aspect-ratio-box"
                    style={previewStyle}>

                    <div className="card-status progress dock status-done">
                      <Thumb
                        source={job}
                        className={liveClass} />

                      <div className="job-card-live-tag">
                        <LiveSVGIcon />
                        <span className="uppercase">
                          Live Ended
                        </span>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="select-feed tmar4">
                  <p className="field-name">{`${getJobLiveStreamPlaylistLabel(value)} Feed`}</p>
                  <Button
                    className="primary small"
                    label="Ingest"
                    disabled={job.isMediaProcessing || job.isInProgress || !job.recordingAreAvailable(value)}
                    onClick={() => model.startIngest(value as LiveStreamChannels)} />
                </div>

                <div className="tmar4">
                  <p className="field-name small">HLS Stream Feed:</p>
                  <p className="field-value">{job.getPlaylistSource(value)}</p>
                </div>
              </div>
            )
          })}
        </div>
      </section>
    </Window>
  );
});
