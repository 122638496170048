import './layout.scss';
import '../input/buttons-v1-1.scss';

import React, { ReactChild } from 'react';
import { observer } from 'mobx-react-lite';

import { HTMLProps } from '../../core';
import { UserCard } from './userCard';
import { PageBackButton } from './pageBackButton';
import { useStore } from '../../store/storeHooks';
import { Button, HelpSVGIconCircle } from '..';

type Props = HTMLProps & {
  searchBar?: ReactChild;
};

export const MasterHeader = observer(({ searchBar, ...props }: Props) => {
  const store = useStore();
  const isMobile = store.ui.isMobile;

  return (
    <div className="master-header-nav">
      <div className="flex-left nav-bar">
        <PageBackButton />
        {searchBar && <div className="header-job-search">{searchBar}</div>}
      </div>

      <div className="flex-right">
        {!isMobile &&
          <Button
            className="help-button"
            leftIcon={<HelpSVGIconCircle />}
            onClick={() => store.postRegistrationWindow.open()}
          />}
        <UserCard
          showLogout={true}
          showHelp={false}
          showProfile={true}
          onLogoutButtonClick={() => store.logout()} />
      </div>
    </div>
  );
});
