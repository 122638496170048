import { computed, makeObservable } from 'mobx';
import { Store } from '../store/store';
import { StoreNode } from '../store';
import { assertNotNull } from '../core';
import { NotificationsSubscriptionConnection, ResizedImage, User as ApiUserProps, GenerativeAiSettings } from '@clipr/lib';
import { PermissionType } from './permission';
import { IUser, UserRole } from './userSchema';
import { AuthPermit } from '../services/auth/authPermit';
import pick from 'lodash/pick';

export const UserProfileAvatarSize = {
  width: 100,
  height: 100
}

export type UserProfileData = {
  id: string;
  email: string;
  username: string | null;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  createdAt: string | null;
  enabled: boolean | null;
  status: string | null;
  roles: string[];
  teamAnalyticsExclude: string[] | null;
}

export type UserProfileProps = ApiUserProps & {
  permit: AuthPermit
};

export class UserProfile
  extends StoreNode
  implements IUser {

  constructor(store: Store, props: UserProfileProps) {
    super(store, props);
    makeObservable(this);
    Object.assign(this, props);

    assertNotNull(props?.permit,
      `The property 'permit' is required when you create a UserProfile instance.`);

    this.permissions = props.permissions || [];
    this.showPlayerTutorial = props.showPlayerTutorial ?? true;

    // make immutable
    Object.freeze(this);
  }

  /** The AuthPermit from which roles and permissions will be read. */
  private readonly permit!: AuthPermit;

  readonly id!: string;
  readonly email!: string;
  readonly createdAt?: string | null;
  readonly enabled?: boolean | null;
  readonly status?: string | null;
  readonly name?: string | null;
  readonly username?: string | null;
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  // readonly picture?: string | null;
  readonly avatar?: ResizedImage[];
  readonly preferredEmail?: string | null;
  readonly permissions!: string[];
  readonly roles!: string[];
  readonly isFoundingMember?: boolean | null;
  readonly showOnboarding?: boolean | null;
  readonly jobTitle?: string | null;
  readonly industry?: string | null;
  readonly companySize?: string | null;
  readonly emailVerified?: boolean | null;
  readonly showPlayerTutorial?: boolean | null;
  readonly userNotificationSubscriptions?: NotificationsSubscriptionConnection;
  readonly hideSpeakerSuggestions?: [string];
  readonly teamAnalyticsExclude?: string[];
  readonly generativeAiSettings?: GenerativeAiSettings;

  get displayName(): string | null {
    return (
      this.username ||
      this.name ||
      this.email ||
      this.id ||
      null);
  }

  get pictureURL(): string | null {
    if (!this.avatar)
      return null;

    return (this.avatar.length > 0 && this.avatar[0].url) || null;
  }

  hasPermission(type: PermissionType): boolean {
    const { permissions } = this;

    switch (type) {
      case 'UserEditJob':
        console.warn(`Permission ${type} is not valid in a global context.`);
        return false;

      case 'UserUpload':
        return permissions.includes('CreateJob');

      case 'ViewUploadPage':
        return permissions.includes('CreateJob');

      case 'ViewUserDashboard':
        return permissions.includes('CreateJob');

      case 'ViewUserVideoPage':
        return permissions.includes('CreateJob');

      case 'CreateTeam':
        return permissions.includes('CreateTeam');

      case 'BulkInviteTeammates':
        return permissions.includes('BulkInviteTeammates');

      case 'ViewTrainerDashboard':
        return permissions.includes('ViewTrainerDashboard');

      case 'UserCanUploadInTeams':
        return this.store.teamManager.teams.filter(team => team.hasPermission('CreateJob')).length > 0;

      case 'UserCanAddToTeams':
        return this.store.teamManager.teams.filter(team => team.hasPermission('AddJob')).length > 0;

      case 'UpdateSpeaker':
        return permissions.includes('UpdateSpeaker');

      case 'ChangeOwner':
        return permissions.includes('ChangeOwner');

      case 'ViewSettingsPage':
        return false;

      case 'ViewAdminUsersPage':
        return false;

      case 'AddTeamTrainer':
        return permissions.includes('AddTeamTrainer');

      case 'ViewAnalytics':
        return permissions.includes('ViewAnalytics');

      case 'AdminGetTeams':
        return permissions.includes('AdminGetTeams');
    }

    return false;
  }

  hasRole(role: UserRole) {
    return this.roles?.includes(role);
  }

  hasSpeakerSuggestionHidden(jobId: string): boolean {
    if (!this.hideSpeakerSuggestions)
      return false;

    return this.hideSpeakerSuggestions.includes(jobId)
  }

  @computed
  get isAdmin() {
    return this.roles?.includes('Admin');
  }

  @computed
  get isTrainer() {
    return this.roles?.includes('Trainer');
  }

  @computed 
  get cliprGptEnabled() {
    return this.generativeAiSettings?.cliprGptEnabled ?? false;
  }


  export(): UserProfileData {
    return pick(this, [
      'id',
      'email',
      'username',
      'name',
      'firstName',
      'lastName',
      'createdAt',
      'enabled',
      'status',
      'roles'
    ]) as UserProfileData;
  }
}