import { makeObservable } from 'mobx';
import { Store } from '../../../store/store';
import { StoreNode } from '../../../store';
import { AuthStepOrchestrator } from '../authStepOrchestrator';
import { LoginInput } from '../authSchema';
import { AsyncResult } from '../../../core';
import { AuthFlowName, AuthFlowResponse, IAuthFlow } from '../authFlowSchema';

export class LoginFlow
  extends StoreNode
  implements IAuthFlow {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  readonly flowName = AuthFlowName.Login;
  readonly orchestrator = new AuthStepOrchestrator(this.store);

  async run(input: LoginInput): AsyncResult<AuthFlowResponse> {

    const { routingService } = this.store;
    const { orchestrator } = this;

    routingService.setStorageAuthFlow(AuthFlowName.Login);

    const [, loginErr] = await orchestrator.login(input);
    if (loginErr)
      return orchestrator.setError(loginErr);

    // then wait for a redirect
    return orchestrator.setAwaitRedirect();
  }

  async restoreAfterRedirect(): AsyncResult<AuthFlowResponse> {

    const { orchestrator } = this;

    const [, contextErr] = await orchestrator.initContextFromHashAndCommit();
    if (contextErr)
      return orchestrator.setRedirectToLoginPage({ error: contextErr });

    return orchestrator.setRedirectToLastPrivateRoute();
  }
}