import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { action, computed, makeObservable, observable } from 'mobx';
import { AsyncResult, IAsyncTask, Nullable } from '../core';
import { Store } from '../store/store';
import { StoreNode } from '../store/storeNode';

export type ApiUploadRequestParams = {
  url: string,
  file: File,
  data?: FormField[]
}

type FormField = {
  key: string,
  value: string
}

export class ApiUploadRequest
  extends StoreNode
  implements IAsyncTask {

  readonly url: string;
  readonly file: File;
  readonly data?: FormField[];

  @observable promise: Nullable<AsyncResult> = null;

  @observable error: any = null;
  @observable isActive: boolean = false;

  cancelToken?: CancelTokenSource;

  @computed
  get uploadProgress(): number {
    return this.uploadLoaded / this.uploadTotal;
  }

  @observable uploadTotal: number = 0;
  @observable uploadLoaded: number = 0;

  constructor(params: ApiUploadRequestParams, store: Store) {
    super(store);
    makeObservable(this);

    // didn't go with Object.assign to avoid confusing TS
    this.url = params.url;
    this.file = params.file;
    this.data = params.data;
  }

  cancel() {
    this.cancelToken?.cancel();
  }

  private async startRequest(): AsyncResult {

    this.isActive = true;

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    this.cancelToken = source;

    const { file, data, url } = this;

    const formData = new FormData();

    if (data) {
      for (let pair of data)
        formData.append(pair.key, pair.value);

      // console.log(formData);
    }
    formData.append('file', file);

    const config: AxiosRequestConfig = {
      cancelToken: source.token,
      onUploadProgress: this.handleUploadProgress
    };

    try {

      let resData = await axios.post(url, formData, config);
      this.isActive = false;

      return [resData];

    } catch (err) {
      this.error = err;
      this.isActive = false;
      
      if (axios.isCancel(err)) {
        return [null, new Error('Cancel')];
      }
      return [null, err];
    }
  }

  async start(): AsyncResult {
    // wrapper around startRequest which intercepts the promise
    const promise = this.startRequest();
    this.promise = promise;
    return promise;
  }

  @action
  private handleUploadProgress = (evt: ProgressEvent<EventTarget>) => {

    if (evt.lengthComputable) {
      this.uploadLoaded = evt.loaded;
      this.uploadTotal = evt.total;
    }
  }
}