import React from 'react';
import { observer } from 'mobx-react-lite';
import { PlayerState } from '../playerState';
import { PlayerCaptionsComponent } from './playerCaptionsComponent';

type Props = {
  model: PlayerState
  showSubtitles?: boolean;
}

/**
 * Represents a transparent region that fills up space inside different control regions
 * and reacts to hover and click events as if they were directly occuring on the video element in itself,
 * triggering the hover animation and toggling of play/pause when clicked.
 * We use this such that we don't have to add a `stopPropagation` on each click event inside the control
 * surfaces if we were to add a single root click event to achieve the same behaviour.
 */
export const ClickPadding = observer(({
  model,
  showSubtitles
}: Props) => {

  const { chrome } = model;
  return (
    <div className="user-player-click-padding"
      onClick={chrome.handleVideoClick}
      onDoubleClick={chrome.handleVideoDoubleClick}
      onPointerEnter={chrome.handleVideoPointerEnter}
      onPointerLeave={chrome.handleVideoPointerLeave} >
      {showSubtitles &&
        model.captionsAreEnabled &&
        model.captionsAreVisible &&
        <PlayerCaptionsComponent
          model={model} />
      }</div>
  );
});