import { makeObservable, observable, computed } from 'mobx';
import { BindingProps, StoreNode } from '../../../store';
import { Store } from '../../../store/store';
import { BookmarksChartDatum, BookmarksChartInternalParams, BookmarksChartParams } from './bookmarksChartSchema';
import { BookmarksDataSource } from './bookmarksDataSource';

type Props = BindingProps<{
  params?: BookmarksChartParams
}>

export class BookmarksChartState
  extends StoreNode {

  readonly nodeType = 'BookmarksChartState';

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);
  }

  // #region Props
  @computed get params(): BookmarksChartParams | null {
    return this.getResolvedProp('params') ?? null;
  }
  set params(val: BookmarksChartParams | null) {
    this.setProp('params', val);
  }
  // #endregion

  readonly internalParams: BookmarksChartInternalParams = observable({
    
  })

  @computed get outputParams(): BookmarksChartParams {
    const outParams = {
      ...this.internalParams,
      ...this.params
    };

    Object.freeze(outParams);
    return outParams;
  }

  @computed get outputData(): BookmarksChartDatum[] {
    return [...this.dataSource.data];
  }

  readonly dataSource = new BookmarksDataSource(this.store, {
    params: () => this.outputParams
  });

  async fetch() {
    return this.dataSource.fetch();
  }
  abort() {
    this.dataSource.cancel();
  }
}