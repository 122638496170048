
import './playerIndex.scss';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { areConnected } from '../../entities/moments/momentFilters';
import { PlayerIndexState } from './playerIndexState';
import { PlayerIndexCard } from './playerIndexCard';
import { SubTopic, Topic } from '../../entities/moment';
import { PlayerIndexProps } from './playerIndex';

export const PlayerIndexTopicList = observer(({
  model,
}: PlayerIndexProps) => {

  const { player } = model;
  const { itemSource } = player;
  const topics = itemSource.indexSectionTopics;

  return (<ul
    data-tutorial-id="user-player-topic-item"
    className="user-player-clips-list">
    {topics.map((topic) =>
      <PlayerIndexTopicItem model={model}
        key={topic.id}
        topic={topic} />
    )}
  </ul>)
})

type PlayerIndexTopicItemProps = {
  model: PlayerIndexState,
  topic: Topic
}

const PlayerIndexTopicItem = observer(({
  model,
  topic
}: PlayerIndexTopicItemProps) => {

  const { player } = model;
  const { itemSource } = player;
  const subTopics = itemSource.indexSectionSubTopics;
  const isTopicActive = model.activeTopic?.id === topic.id;
  const subTopicList = subTopics.filter(mom => areConnected(mom, topic));

  return (
    <li
      className={`user-player-topic-item 
    ${subTopicList?.length === 0 ? 'empty' : ''}`}
      key={topic.id}>

      <PlayerIndexCard
        model={model}
        moment={topic}
        onExpand={model.expandIndexCard}
        isActive={isTopicActive}
        isExpanded={topic.id === model.expandedClipId}
        onClick={() => player.invoke('jumpToMoment', { moment: topic })}
      />

      <PlayerIndexSubTopicList
        model={model}
        topic={topic} />

    </li>)
});

const PlayerIndexSubTopicList = observer(({
  model,
  topic
}: PlayerIndexTopicItemProps) => {

  const { player } = model;
  const { itemSource } = player;
  const subTopics = itemSource.indexSectionSubTopics;
  const activeSubtopics = model.activeSubtopics;
  const subTopicList = subTopics.filter(mom => areConnected(mom, topic));

  return (<ul className="user-player-subtopic-list">
    {subTopicList?.map(subTopic => {
      const isActive = !!activeSubtopics?.find((value: SubTopic) => value.id === subTopic.id);

      return <PlayerIndexSubTopicItem
        key={subTopic.id}
        model={model}
        subTopic={subTopic}
        isActive={isActive} />
    })}
  </ul>)
})

type PlayerIndexSubTopicItemProps = {
  model: PlayerIndexState,
  subTopic: SubTopic,
  isActive: boolean
}

const PlayerIndexSubTopicItem = observer(({
  model,
  subTopic,
  isActive
}: PlayerIndexSubTopicItemProps) => {

  const { player } = model;

  return (
    <li className="user-player-subtopic-item"
      key={subTopic.id}>

      <PlayerIndexCard
        model={model}
        moment={subTopic}
        onExpand={model.expandIndexCard}
        isActive={isActive}
        isExpanded={subTopic.id === model.expandedClipId}
        onClick={() => player.invoke('jumpToMoment', { moment: subTopic })} />
    </li>
  )
})