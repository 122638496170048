import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Nullable } from '../../core';
import { Store } from '../../store/store';
import { StoreNode, BindingProps, Message } from '../../store';
import { Team } from '../../entities/team';
import { InputState, inputGroup, InputGroupState, input } from '../input';
import { notifyError, notifySuccess, notifyLoading } from '../../services/notifications';
import { TeamMemberRoleInput } from '@clipr/lib';
import { CatalogState } from '../layout/catalogState';
import { SyncStatus } from '../../store/syncSchema';
import { RoleItem } from '../createTeamForm/createTeamFormState';
import { TeamMembersWindowMode } from './teamMembersWindowState';
import { TeamMember } from '../../entities/teamMember';
import { TeamInvitation } from '../../entities/teamInvitation';
import { RemoveAccessPopupState } from './removeAccessPopupState';

function checkDisabled(input: any, disable: boolean) {
  return (input.loading ||
    input.isSubmitting ||
    disable)
}

const roleSelectorItems = [
  { value: 'Admin', label: 'Admin', description: 'Upload, view, edit, manage library users and videos.' },
  { value: 'Trainer', label: 'Trainer', description: 'Upload, view, edit, and manage library videos.' },
  { value: 'Member', label: 'Member', description: 'Upload and edit library videos.' },
  { value: 'Viewer', label: 'Viewer', description: 'View library videos.' },
  { value: 'Remove Access', label: 'Remove Access', description: '', className: 'red' }
];

export type MemberTargetType = TeamMember | TeamInvitation;

export type MemberItemTargetType = 'member' | 'invitation';

type TeamMembersListProps = BindingProps<{
  syncStatus?: SyncStatus,
  isEndOfList?: boolean,
  fetch?: () => any,
  fetchMore?: () => any
}>
export class TeamMemberCatalogState
  extends StoreNode {

  readonly memberListInputGroup: InputGroupState;
  readonly memberInputGroup: InputGroupState;

  readonly memberCatalog: CatalogState;

  @observable isLoading = false;
  @observable isSyncing = false;
  @observable isLocked = false;
  @observable mode: TeamMembersWindowMode | null = null;
  @observable teamId: string | null = null;

  constructor(store: Store, props?: TeamMembersListProps) {
    super(store, props);
    makeObservable(this);

    this.memberListInputGroup = inputGroup(this, {
      name: 'inviteListInputGroup',
      inputs: () =>
        this.memberList.map((el: any) => el.lineGroup),
      disabled: (input: any) => {
        return (
          input.hasLoadingInputs ||
          input.isSubmitting ||
          this.isLocked
        )
      }
    })
    this.memberInputGroup = inputGroup(this, {
      name: 'inviteInputGroup',
      inputs: [
        this.memberListInputGroup,
      ],
      disabled: input => checkDisabled(input, this.isLocked)
    })

    this.memberCatalog = new CatalogState(this.store, {
      syncStatus: () => this.resolvedProps?.syncStatus,
      isEndOfList: () => this.resolvedProps?.isEndOfList
    });
    this.memberCatalog.listen((msg) => {
      switch (msg.type) {
        case 'scrollToBottom':
        case 'loadMore':
          this.props?.fetchMore();
          break;
      }
    });
  }

  memberList = observable.array();

  @computed
  get team(): Nullable<Team> {
    return this.store.teamManager.getTeam(this.teamId);
  }

  @computed
  get isEndOfMemberList(): boolean {
    return this.resolvedProps?.isEndOfList
  }

  @action
  addMemberState = (member: MemberTargetType, type?: MemberItemTargetType) => {
    const memberState: TeamMemberItemState = new TeamMemberItemState(this.store, {
      type: type,
      handleUpdate: type === 'member' ?
        (id, role) =>
          this.handleUpdateTeamMemberRole(id, role) :
        (id, role) =>
          this.handleUpdateTeamInvitation(id, role),
      handleDelete: type === 'member' ?
        id =>
          this.handleDeleteTeamMember(id) :
        id =>
          this.handleDeleteTeamInvitation(id)
    });
    memberState.teamId = this.teamId;
    memberState.target = member || null;
    memberState.mode = this.mode || null;
    memberState.initValues();
    this.memberList.push(memberState);
    return memberState;
  }

  @action
  async handleUpdateTeamMemberRole(id: string, role: TeamMemberRoleInput) {
    this.isLoading = true;
    const member = this.team?.getMember(id);
    notifyLoading(this, "Updating Member Role...");
    const name = member?.name || null;
    const [, err] = await this.team?.apiUpdateTeamMemberRole({
      teamId: this.team?.id,
      userId: member?.userId!,
      role: role
    }) || [null];

    runInAction(async () => {
      if (err) {
        this.isLoading = false;
        notifyError(this, `Library Member ${name} update failed.`);
        return;
      }
      this.isLoading = false;
      notifySuccess(this, `Library Member ${name} updated successfully.`);
      await Promise.all([
        this.props?.fetch(),
        this.team?.apiFetchInvitations()
      ])
    })
  }

  @action
  async handleUpdateTeamInvitation(id: string, role: TeamMemberRoleInput) {
    this.isLoading = true;
    const invitation = this.team?.getInvitation(id);
    notifyLoading(this, "Updating Invitation...");
    const name = invitation?.name;
    const [, err] = await this.team?.apiUpdateTeamInvitation({
      teamId: this.team?.id,
      email: name!,
      role: role
    }) || [null];
    if (err) {
      this.isLoading = false;
      notifyError(this, `Invitation for ${name} update failed.`);
      return;
    }
    await Promise.all([
      this.props?.fetch(),
      this.team?.apiFetchInvitations()
    ])
    notifySuccess(this, `Invitation for ${name} updated successfully.`);
    this.isLoading = false;
  }

  @action
  invoke = (type: string, payload?: any) => {

    switch (type) {
      case 'openRemoveAccess':
        if (!this.team) return;
        const removeAccessPopup = this.store.removeAccessPopup;
        const removeAccessPopupListener = (msg: Message<RemoveAccessPopupState>) => {
          switch (msg.type) {
            case 'open':
              break;

            case 'close':
              removeAccessPopup.unlisten(removeAccessPopupListener);
              break;

            case 'removedAccess':
              this.fetchCatalogEntities();
              removeAccessPopup.unlisten(removeAccessPopupListener);
              break;
          }
        }
        removeAccessPopup.listen(removeAccessPopupListener);
        if (payload?.id)
          removeAccessPopup.open({
            teamId: this.team?.id,
            userId: payload?.id
          });
        else if (payload?.email)
          removeAccessPopup.open({
            teamId: this.team?.id,
            email: payload?.email
          });
        break;
    }
  }

  async fetchCatalogEntities() {
    this.isSyncing = true;
    await Promise.all([
      this.props?.fetch(),
      this.team?.apiFetchInvitations()
    ]);
    this.isSyncing = false;
  }

  @action
  async handleDeleteTeamMember(id: string) {
    if (!this.team?.id)
      return;

    this.invoke('openRemoveAccess', { id });
  }

  @action
  async handleDeleteTeamInvitation(id: string) {
    const invitation = this.team?.getInvitation(id);
    if (!this.team?.id || !invitation)
      return;

    this.invoke('openRemoveAccess', { email: invitation.email });
  }

  @action
  reset() {
    this.memberList.clear();
  }

  @action
  lock() {
    this.isLocked = true;
    this.memberList.forEach(item => item.lock());
  }

  @action
  unlock() {
    this.isLocked = false;
    this.memberList.forEach(item => item.unlock());
  }

}

type TeamMemberItemProps = {
  type?: MemberItemTargetType,
  handleDelete?: (id: string) => any,
  handleUpdate?: (id: string, role: TeamMemberRoleInput) => any
};

export class TeamMemberItemState
  extends StoreNode {

  readonly lineGroup: InputGroupState;
  readonly type: MemberItemTargetType;
  // readonly memberName: InputState;
  readonly roleInput: InputState;

  @observable isSyncing = false;
  @observable isSynced = false;
  @observable isError = false;
  @observable isLocked = false;

  @observable target: Nullable<MemberTargetType> = null;
  @observable teamId: Nullable<string> = null;
  @observable mode: Nullable<TeamMembersWindowMode> = null;

  constructor(store: Store, props?: TeamMemberItemProps) {
    super(store, props);
    makeObservable(this);

    this.roleInput = input(this, {
      name: 'role',
      showStatusMessage: false,
      disabled: input => {
        return checkDisabled(input, this.isLocked) || this.target?.role.toLowerCase() === 'owner';
      },
      placeholder: 'Role',
      selectorItems: () => this.roleItems,
      onChange: async input => this.handleRoleChange(input)
    });
    this.lineGroup = inputGroup(this, {
      name: 'inviteLine',
      inputs: [
        // this.memberName,
        this.roleInput
      ],
      disabled: (input: any) => {
        return (
          input.hasLoadingInputs ||
          input.isSubmitting ||
          this.isSynced ||
          this.isSyncing ||
          this.isLocked
        )
      }
    });

    this.type = props?.type || 'member';
  }

  @computed
  get roleItems(): RoleItem[] {
    return this.store?.user?.hasPermission('AddTeamTrainer') ?
      roleSelectorItems :
      roleSelectorItems.filter(role => role.value !== 'Trainer');
  }

  @computed
  get team(): Nullable<Team> {
    return this.store.teamManager.getTeam(this.teamId);
  }

  @computed
  get targetName() {
    return this.target?.name || null
  }

  @computed
  get isInvitation() {
    return this.type === 'invitation';
  }

  @action
  async handleRoleChange(input: any) {
    const value = input?.value || null;

    switch (value) {
      case 'Remove Access':
        await this.props?.handleDelete(this.target?.id);
        break;
      default:
        await this.props?.handleUpdate(this.target?.id, value);
        break;
    }
  }

  @action
  initValues() {
    this.roleInput.value = this.target?.role || null;
    // this.memberName.value = this.targetName || null;
  }

  @action
  setSyncing(value: boolean) {
    this.isSyncing = value;
  }

  @action
  setSynced(value: boolean) {
    this.isSynced = value;
  }

  @action
  setError(value: boolean) {
    this.isError = value;
  }

  @action
  lock() {
    this.isLocked = true;
  }

  @action
  unlock() {
    this.isLocked = false;
  }
}