import * as uuid from 'uuid';
import { DocumentNode, print } from 'graphql';
import { WSAuth } from './apiWSClient';

type WSSubsState =
  'Pending' |
  'Started' |
  'Active' |
  'Error' |
  'Ended' |
  'Completed';

type WSSubscriptionProps = {
  id?: string;
  document: DocumentNode;
  context: WebSocket;
  vars?: any;
  auth: WSAuth | null;
  prototype?: WSSubscription;
}

export class WSSubscription {
  constructor(props: WSSubscriptionProps) {
    this.id = props.id || uuid.v4();
    this.document = props.document;
    this.vars = props.vars;
    this.context = props.context;
    this.auth = props.auth;
    this.prototype = props.prototype || null;
  }
  readonly id: string;
  readonly document: DocumentNode;
  readonly vars: any;
  readonly context: WebSocket;
  readonly auth: WSAuth | null;
  readonly prototype: WSSubscription | null;

  private error: any;

  state: WSSubsState = 'Pending';

  readonly dataHistory: any[] = [];

  async start() {
    const payload = {
      data: JSON.stringify({ query: print(this.document), variables: this.vars })
    }

    if (this.auth)
      Object.assign(payload, {
        extensions: { authorization: this.auth },
      })

    await this.context.send(JSON.stringify({
      id: this.id,
      type: 'start',
      payload: payload
    }));

    this.setStarted();
  }

  async stop() {
    if (this.state === 'Started' || this.state === 'Active') {
      await this.context!.send(JSON.stringify({
        id: this.id,
        type: 'stop'
      }));
    }
    this.setEnded();
  }

  public updateHistory(data: any) {
    this.dataHistory.push(data);
  }

  public setStarted() {
    this.state = 'Started';
  }

  public setEnded() {
    this.state = 'Ended';
  }

  public setCompleted() {
    this.state = 'Completed';
  }

  public setError(error?: any) {
    this.error = error;
    this.state = 'Error';
  }

  public setActive() {
    this.state = 'Active';
  }

  get isStarted(): boolean {
    return this.state === 'Started';
  }

  get isError(): boolean {
    return this.state === 'Error';
  }

  get isActive(): boolean {
    return this.state === 'Active';
  }

  get errorMessage() {
    return this.error?.message || this.error?.errorType || 'Unknown error';
  }

  public clone(context: WebSocket) {
    const props: WSSubscriptionProps = {
      document: this.document,
      vars: this.vars,
      context: context,
      auth: this.auth,
      prototype: this
    }
    return new WSSubscription(props);
  }

}