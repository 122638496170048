import { JobSpeciality } from '@clipr/lib';
import { ReactNode } from 'react';
import { DropdownItemObject } from '../../components/input/dropdownInput';
import { camelPad } from '../../core/string';
import { JobModel } from '../job';

type JobSpecialityOutput = 'PRIMARYCARE' | undefined;

export const Specialities: JobSpeciality[] = [
  'Standard',
  'Medical',
  'PublicSafety'
];

export const getJobSpecialityLabel = (speciality: JobSpeciality): string => {
  switch (speciality) {
    case 'PublicSafety':
      return 'Public Safety';
    default:
      return camelPad(speciality);
  }
}

export const getJobSpecialityTooltip = (speciality: JobSpeciality): ReactNode => {
  switch (speciality) {
    case 'Medical':
      return 'The Medical Video Speciality is currently only supported for US English videos';
    default: return null;
  }
}

export const getDefaultJobSpeciality = (): JobSpeciality => {
  return 'Standard';
}

export const getJobSpecialityInputLabel = (): string => {
  return 'Job Speciality';
}

export const SpecialityItems: DropdownItemObject[] = Specialities
  .map(item => ({ value: item, label: getJobSpecialityLabel(item), tooltip: getJobSpecialityTooltip(item) }));

export const FilteredSpecialityItems: DropdownItemObject[] = SpecialityItems.filter((item) => item.value !== 'PublicSafety');

export const DefaultSpecialityItem: DropdownItemObject | null = SpecialityItems.find(({ value }) => value === getDefaultJobSpeciality()) || null;

export const getJobSpecialityOutput = (speciality: JobSpeciality | null): JobSpecialityOutput => {
  return speciality === 'Medical' ? 'PRIMARYCARE' : undefined;
}

export const getJobSpecialityInput = (job: JobModel | null): JobSpeciality | null => {
  if (!job?.hasTranscriptLevel) 
    return null; 

  if (job.medicalSpecialty === 'PRIMARYCARE') {
    return 'Medical';
  }

  return job.speciality;
}