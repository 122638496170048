import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { DataContext } from '@visx/xychart';
import { RenderTooltipParams } from '@visx/xychart/lib/components/Tooltip';
import { scaleOrdinal } from '@visx/scale';
import { GlyphProps } from '../xySeriesChart';

type Props = RenderTooltipParams<object> & {
  showTotal?: boolean;
  showTooltipTitle?: boolean;
  showItemIcon?: boolean;
  showItemText?: boolean;
  showItemValue?: boolean;
  icons?: TooltipIcons[];
  callbacks?: TooltipCallbacks
};
export type TooltipCallbacks = {
  value: (item: TooltipItem) => number | string;
}
type TooltipItem = {
  datum: TooltipDatum;
  key: string;
};
type TooltipDatum = {
  date: string;
  value: number;
};
type TooltipIcons = {
  dataKey: string;
  glyph?: GlyphProps;
};

export const DefaultTooltip = observer(({ tooltipData, ...props }: Props) => {
  if (!tooltipData)
    return null;

  const { nearestDatum, datumByKey } = tooltipData;
  const dataKey = nearestDatum?.key;
  const currentDatum = nearestDatum?.datum as TooltipDatum;
  const date = currentDatum.date;
  const data = datumByKey ?? {};
  const {
    showTooltipTitle,
    showTotal,
    showItemIcon,
    showItemText,
    showItemValue,
    icons,
    callbacks
  } = props
  const valueCallback = callbacks?.value;

  const total = showTotal && Object.values(data).reduce(
    (prev, item) => {
      const { datum } = item as TooltipItem
      return prev + datum.value;
    },
    0
  );

  const { colorScale } = useContext(DataContext);

  const shapeScale = scaleOrdinal<string, React.FC | GlyphProps>({
    domain: icons?.map((datum) => {
      return datum.dataKey;
    }),
    range: icons?.map((datum) => {
      return datum.glyph!;
    }),
  });

  return (
    <div className="chart-tooltip">
      {showTooltipTitle && <div>{date}</div>}
      <div className="tooltip-items">
        {Object.values(data).map((item) => {
          const { datum, key } = item as TooltipItem
          const isActiveItem = key === dataKey;
          const color = colorScale?.(key);
          const CustomItemIcon = shapeScale(key);
          const value = 
            (valueCallback && 
            (typeof valueCallback == "function") && 
            valueCallback({ datum, key })) || 
            datum.value

          return (
            <div
              className={classNames("tooltip-item", { active: isActiveItem })}
              key={key}>
              {showItemIcon && CustomItemIcon &&
                <span className="item-icon">
                  <CustomItemIcon />
                </span>}
              {showItemText && <span className="item-text" style={{ color }}>
                {key}
              </span>}
              {showItemValue && <span className="item-value">{value}</span>}
            </div>
          );
        })}
      </div>
      {showTotal && <div className="total">Total: {total}</div>}
    </div>
  );
});
