import React, { ReactEventHandler, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import {
  TextInputControlled
} from '../input';

import {
  CheckSVGIcon,
  CloseSVGIcon,
  SquareMinusSVGIcon,
  SquarePlusSVGIcon,
} from '../svg';
import { AdsSettingsSectionState } from './adsSettingsSectionState';
import { AdsItemState } from './adsItemState';

type AdsSettingsSectionProps = {
  model: AdsSettingsSectionState;
  className?: string;
};

type AdsItemprops = {
  isLast: boolean;
  model: AdsItemState;
  onClickRemove: ReactEventHandler;
  onClickAdd: ReactEventHandler;
};

export const AdsSettingsSection = observer((props: AdsSettingsSectionProps) => {
  const { model } = props;
  const { vastUrls, removeVastUrl, addVastUrl } = model;

  // We need to subscribe to the vastUrls changes
  // Trying to scroll to bottom right after adding a new element won't work
  // The new element is rendered just after scroll to bottom is triggered
  useEffect(() => {
    if (!vastUrls.length)
      return;

    model.scrollToBottom();
  }, [vastUrls, model]);

  if (!model || !vastUrls || vastUrls.length === 0)
    return null;

  return (
    <>
      <div
        id="ads-list-group"
        className="ads-list-group scrollable">
        <ul>
          {vastUrls.map((model: AdsItemState, i: number) => {
            return (
              <AdsItem
                model={model}
                key={model.id}
                isLast={i === vastUrls.length - 1}
                onClickRemove={() => removeVastUrl(i)}
                onClickAdd={() => addVastUrl()}
              />
            );
          })}
        </ul>
      </div>
    </>
  );
});

const AdsItem = observer((props: AdsItemprops) => {
  const { model, onClickRemove, onClickAdd } = props;

  if (!model) return null;

  const { error, isSynced, urlInput } = model;

  let suffixElement;
  if (isSynced && !error) {
    suffixElement = (
      <div className="line-suffix success-icon">
        <CheckSVGIcon />
      </div>
    );
  } else if (error) {
    suffixElement = (
      <div className="line-suffix error-icon">
        <CloseSVGIcon />
      </div>
    );
  } else if (props.isLast) {
    suffixElement = (
      <button
        className="line-suffix list-remove-item-btn btn-core"
        type={'button'}
        disabled={urlInput.disabled}
        onClick={onClickAdd}>
        <SquarePlusSVGIcon />
      </button>
    );
  } else
    suffixElement = (
      <button
        className="line-suffix list-remove-item-btn btn-core"
        type={'button'}
        disabled={urlInput.disabled}
        onClick={onClickRemove}>
        <SquareMinusSVGIcon />
      </button>
    );

  return (
    <>
      <li
        className="add-vast-url-link-line">
        <TextInputControlled
          model={urlInput}
          className="form-input vast-url-input"
        />
        <div className="line-actions">{suffixElement}</div>
      </li>
      {error && <div className="vast-url-error">{error}</div>}
    </>
  );
});

