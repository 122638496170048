import { action, makeObservable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';

export class BookmarkBrowserState
  extends StoreNode {

  readonly nodeType = 'BookmarkBrowser';

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  @action
  openCreateListWindow() {
    this.store.bookmarkListWindow.openCreate();
  }
}