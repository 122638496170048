import '../../components/overlays/overlays.scss';

import React, { useState, PropsWithChildren, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { usePopper } from 'react-popper';
import { useOutsideClick, useOutsideScroll } from '../../components/hooks';

// @ts-ignore
import CSSVariables from '../../components/css/palette.scss';
import { Maybe } from '../../core';
import classNames from 'classnames';
import { Placement } from '@popperjs/core/lib';
import { Options as OffsetOptions } from '@popperjs/core/lib/modifiers/offset';

type TooltipProps = PropsWithChildren<{
  id?: string;
  className?: Maybe<string>,
  arrowClassName?: Maybe<string>,
  content?: string | ReactNode,
  placement?: Placement,
  offset?: OffsetOptions["offset"],
  allowedAutoPlacements?: Placement[],
  fallbackPlacements?: Placement[],
  trigger?: 'click' | 'hover'
}>;

const fadeTimeout = {
  enter: parseInt(CSSVariables.windowEnterTimeout),
  exit: parseInt(CSSVariables.windowExitTimeout)
};

export const Tooltip = observer(({
  placement = 'top',
  offset = [0, 8],
  id,
  allowedAutoPlacements,
  fallbackPlacements,
  trigger = 'hover',
  ...props
}: TooltipProps) => {

  const [isVisible, setIsVisible] = useState(false);
  const [popperElement, setPopperElement] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const className = classNames('tooltip', props.className);
  const arrowClassName = classNames('tooltip-arrow', props.arrowClassName);

  const isTriggerHover = trigger === 'hover';
  const isTriggerClick = trigger === 'click';

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    strategy: 'fixed',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement
        }
      },

      {
        name: 'offset',
        options: {
          offset: offset,
        }
      },

      {
        name: 'flip',
        options: {
          allowedAutoPlacements: allowedAutoPlacements || ['top', 'bottom'],
          fallbackPlacements: fallbackPlacements || ['top', 'left', 'right', 'bottom'],
        },
      }
    ],
  });

  useOutsideClick(popperElement, () =>
    setIsVisible(false));

  useOutsideScroll(popperElement, () =>
    setIsVisible(false));

  return (
    <>
      <span className={classNames('ref-element', {
        'active': isVisible
      })}
        ref={setReferenceElement as any}
        onMouseEnter={() => isTriggerHover && setIsVisible(true)}
        onMouseLeave={() => isTriggerHover && setIsVisible(false)}
        onClick={() => isTriggerClick && setIsVisible(true)}>
        {props.children}
      </span>

      <TransitionGroup>
        {isVisible && (
          <CSSTransition
            classNames="menu-fade"
            timeout={fadeTimeout}>
            <div
              id={id}
              className={className}
              ref={setPopperElement as any}
              style={styles.popper} {...attributes.popper}>

              <div className={arrowClassName} ref={setArrowElement as any} style={styles.arrow} />

              <div className="tooltip-content">{props.content}</div>
            </div>
          </CSSTransition>)}
      </TransitionGroup>
    </>
  );
});
