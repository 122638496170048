import { DateTime } from 'luxon';
import { action, makeObservable, observable } from 'mobx';
import moment from 'moment';

import { ExternalLibraryFilesFilterInput } from '@clipr/lib';

import { ApiVariables } from '../../api/apiSchema';
import { dateInput, DateInputState } from '../../components/input/dateInput/dateInputState';
import { Nullable } from '../../core';
import { BindingProps } from '../../store';
import { Store } from '../../store/store';
import { LibraryCatalogSource } from './libraryCatalogSource';

type Props = BindingProps<{
  pageSize?: number,
}>

export class ZoomCatalogSource
  extends LibraryCatalogSource {

  readonly nodeType: 'ZoomCatalogSource' = 'ZoomCatalogSource';
  readonly datepicker: DateInputState;
  constructor(store: Store, props?: Props) {
    super(store, { library: store.zoom, ...props });
    makeObservable(this);

    this.datepicker = dateInput(this, {
      bordered: true,
      placeholder: ['dd/mm/yyyy', 'dd/mm/yyyy'],
      shouldDisableDates: true,
      validationMessage: 'Maximum allowed range is one month',
      format: ['DD/MM/YYYY', 'DDMMYYYY'],
      activeRangeOption: {
        name: 'Last 30 Days',
        getRange: () => {
          return [moment().subtract(31, 'days'), moment()];
        }
      },
      rangeOptions: [
        {
          name: 'Last 30 Days',
          getRange: () => {
            return [moment().subtract(31, 'days'), moment()];
          }
        },
        {
          name: 'Yesterday',
          getRange: () => {
            return [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
          }
        },
        {
          name: 'Today',
          getRange: () => {
            return [moment(), moment()];
          }
        }
      ]
    });
  }

  @observable startDate: Nullable<string> = DateTime.local().minus({ month: 1 }).toISODate();
  @observable endDate: Nullable<string> = DateTime.local().toISODate();

  @action
  handleDateChange() {
    const value = this.datepicker.value as [moment.Moment, moment.Moment];
    const startDateMoment = (value && value[0]) || DateTime.local().minus({ month: 1 }).toISODate();
    const endDateMoment = (value && value[1]) || DateTime.local().toISODate();
    this.startDate = (moment(startDateMoment).format('YYYY-MM-DD').toString()) || null;
    this.endDate = (moment(endDateMoment).format('YYYY-MM-DD').toString()) || null;
    this.fetch();
  }

  // #region Overwritten class methods
  // -------
  getFetchArgs(more: boolean = false) {
    const value = this.datepicker.value as [moment.Moment, moment.Moment];
    const startDateMoment = (value && value[0]) || DateTime.local().minus({ month: 1 }).toISODate();
    const endDateMoment = (value && value[1]) || DateTime.local().toISODate();
    this.startDate = (moment(startDateMoment).format('YYYY-MM-DD').toString()) || null;
    this.endDate = (moment(endDateMoment).format('YYYY-MM-DD').toString()) || null;

    let args: ApiVariables<'getExternalLibrary'> = {
      id: this.store.zoom.library?.id
    };

    let filter: ExternalLibraryFilesFilterInput = {
      startDate: this.startDate as string,
      endDate: this.endDate as string
    };

    if (this.sortField && this.sortOrder) {
      args.sort = {
        field: this.sortField,
        order: this.sortOrder
      }
    }

    if (Object.keys(filter).length > 0) {
      args.filter = filter;
    }

    args.first = this.pageSize;

    if (more && this.lastItemCursor) {
      args.after = this.lastItemCursor;
    }

    return args;
  }

  reset() {
    this.endDate = DateTime.local().toISODate();
    this.startDate = DateTime.local().minus({ month: 1 }).toISODate();
    this.clearMultipleSelection();
    this.fetch();
  }
  // #endregion
}

