import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { VideoDetailsWindowState } from './videoDetailsWindowState';
import { Button, TextInputControlled, DropdownInputControlled, ToggleInputControlled, ToggleLayout } from '../input';
import { FileInputControlled } from '../input/fileInput';
import classNames from 'classnames';
import { getJobLevelInputLabel } from '../../entities/job/jobFeatures';
import { ExpandToggle } from '../../pages/uploadPage/sharedComponents/expandToggle';

type Props = {
  model: VideoDetailsWindowState;
  visible?: boolean;
};

export const VideoDetailsWindow = observer((props: Props) => {
  const { model } = props;
  const className = classNames("video-details-window grid-layout-window",
    {
      [model.layoutType as string]: !model.teamId
    });

  const isExpanded = !model.isAdvancedSectionExpandable || model.isAdvancedSectionExpanded;
  const isExpandable = model.isAdvancedSectionExpandable;

  return (
    <Window
      className={className}
      model={model.window}
      onExited={model.onTransitionEnd}
      visible={props.visible}
      isLoading={model.isLoading}>
      <WindowHeader model={model.window} title="Video Details" />

      <hr />

      <section className="window-content">

        <div className="form-section form-column-flex main">
          {model.mainInputs?.map((val, i) => generateInput(val as keyof VideoDetailsWindowState, model, i))}
          {model.streamedOnText &&
            <div className="input-wrapper">
              <div className="text streamed-label">{model.streamedOnText}</div>
            </div>}
        </div>

        {model.hasAdvancedInputs &&
          <>
            <hr />
            <div className={`form-section advanced ${isExpanded ? 'expanded' : 'collapsed'}`}>
              <div className={`flex-space-between ${isExpandable ? 'actionable' : ''}`}
                onClick={() => isExpandable && model.toggleAdvancedSection()}>
                <div className="title">Advanced Details</div>
                {model.isAdvancedSectionExpandable &&
                  <ExpandToggle className={"speaker-list-expand-btn"}
                    isExpanded={model.isAdvancedSectionExpanded} />}
              </div>

              <div className="form-grid scrollable expandable"
                aria-hidden={!isExpanded}>
                {model.advancedInputs?.map((val, i) => generateInput(val as keyof VideoDetailsWindowState, model, i))}
              </div>
            </div>
          </>
        }
      </section>
      <hr />
      <footer className="window-footer">
        <div className="btn-group right">
          <Button
            className="primary btn-outline medium"
            label="Cancel"
            disabled={model.inputGroup.isSubmitting}
            onClick={() => model.close()}
          />

          <Button
            className="primary medium"
            label="Save"
            disabled={
              model.inputGroup.isSubmitDisabled ||
              model.inputGroup.isSubmitting
            }
            onClick={() => model.submit()}
          />
        </div>
      </footer>
    </Window>
  );
});

const generateInput = (inputName: keyof VideoDetailsWindowState, model: VideoDetailsWindowState, i: number) => {
  switch (inputName) {
    case 'title':
      return (
        <TextInputControlled
          model={model.title}
          label={"Video Title*"}
          placeholder="Enter name"
          statusMessage={
            model.title.status === 'error' &&
              model.title.showStatusIcons
              ? model.title.error?.toString()
              : 'Minimum title length 5ch.'
          }
          key={i} />
      );
    case 'enrichmentLevel':
      return (
        <DropdownInputControlled
          label={getJobLevelInputLabel()}
          placeholder="Select Value"
          model={model.enrichmentLevel}
          notDeselectable={true}
          key={i} />
      );
    case 'sortOrder':
      if (model.teamId)
        return (
          <TextInputControlled
            model={model.sortOrder}
            label={"Sort Order"}
            placeholder="Add video sort order"
            statusMessage={
              model.sortOrder.status === 'error' &&
                model.sortOrder.showStatusIcons
                ? model.sortOrder.error?.toString()
                : ''
            }
            key={i} />
        );
      break;
    case 'keywords':
      return (
        <TextInputControlled
          model={model.keywords}
          label={"Video Keywords"}
          placeholder="Add keywords separated by comma"
          statusMessage={model.keywords.error?.toString()}
          key={i} />
      );
    case 'tags':
      return (
        <TextInputControlled
          model={model.tags}
          label={"Video Tags"}
          placeholder="Add tags separated by comma"
          statusMessage={model.tags.error?.toString()}
          key={i} />
      );
    case 'description':
      return (
        <TextInputControlled
          model={model.description}
          label="Video Description"
          rows={5}
          placeholder="Enter description"
          disabled={model.isJobInProgress && model.chatGptSummaryEnabled}
          key={i} />
      )
    case 'speciality':
      return (
        <DropdownInputControlled
          className={"speciality-input rmar4"}
          label="Video Speciality"
          model={model.speciality}
          notDeselectable={true}
          key={i} />
      )
    case 'language':
      return (
        <DropdownInputControlled
          label="Video Language"
          model={model.language}
          notDeselectable={true}
          key={i} />
      )
    case 'videoType':
      return (
        <DropdownInputControlled
          label="Video Type"
          model={model.videoType}
          notDeselectable={true}
          key={i} />
      )
    case 'isPublic':
      return (
        <div className="toggle-input-wrapper input-wrapper" key={i}>
          <ToggleInputControlled
            className="medium"
            model={model.isPublic}
            toggleLayout={ToggleLayout.Buttons}
            toggledLabel='Shareable'
            notToggledLabel='Private'
            label="Visibility" />
        </div>
      )
    case 'thumbnail':
      return (
        <div className="file-input-wrapper input-wrapper" key={i}>
          <div className="input-label">Thumbnail</div>
          <div className="input-details">Upload or select a cover image.</div>
          <div className="flex">
            <FileInputControlled
              model={model.thumbnail}
              multiple={true}
              layout={'preview-box'}
              layoutSize={'small'} />

            <div className="right flex-column">
              <div className="input-info">1920x1080 recommended, up to 2MB, JPG or PNG</div>
              {model.isJobCompleted &&
                <FileInputControlled
                  model={model.thumbnail}
                  multiple={true}
                  layout={'button'}
                  layoutSize={'small'}
                  label={'Change Thumbnail'} />}

            </div>
          </div>
        </div>
      )
    default:
      const inputDisplayName = model.getInputLabel(inputName);
      if (!model[inputName])
        return null;

      return (
        <TextInputControlled
          model={model[inputName]}
          label={inputDisplayName}
          statusMessage={
            model[inputName].status === 'error' &&
              model[inputName].showStatusIcons
              ? model[inputName].error?.toString()
              : 'Maximum length 50ch.'
          }
          showCharacterCount={true}
          charCountMax={50}
          placeholder={`Add ${inputDisplayName}`}
          key={i} />
      )
  }
};
