import './player.scss';

import React from 'react';
import classNames from 'classnames';
import { HTMLDivProps, Maybe, ObjectLiteral } from '../../core';
import { PlaySVGIcon, ReplaySVGIcon } from '../svg';
import { LoadMask } from '../loader';

export type PlayerMaskType =
  'Play' |
  'Replay' |
  'Loading';

type Props = HTMLDivProps<{
  visible?: boolean,
  className?: string | ObjectLiteral,
  maskType?: Maybe<PlayerMaskType>
}>

export const PlayerMask = ({
  visible = false,
  className,
  maskType,
  ...props
}: Props) => {

  className = classNames('player-mask', className);

  const contentElem = (() => {
    switch (maskType) {
      case 'Play':
        return (
          <div className="play-mask mask-content">
            <div className="play-icon mask-icon">
              <PlaySVGIcon />
            </div>
          </div>
        );

      case 'Replay':
        return (
          <div className="replay-mask mask-content">
            <div className="replay-icon mask-icon">
              <ReplaySVGIcon />
            </div>
          </div>
        );

      case 'Loading':
        return (
          <LoadMask
            visible={true}
            className="load-mask mask-content"
            spinner="dots3" />
        );
    }
  })();

  return (
    <div className={className}
      aria-hidden={!visible ? true : undefined}
      {...props}>

      {contentElem}
    </div>
  )
}