import { JobFeatures, JobFeatureStatus, StartJobFeaturesInput } from '@clipr/lib';
import { ReactNode } from 'react';
import { assert } from '../../core';

// eslint-disable-next-line  no-redeclare
export type JobLevel =
  'media' |
  'transcript' |
  'automaticEnrichment' |
  'manualEnrichment';

  // eslint-disable-next-line  no-redeclare
export const JobLevel: JobLevel[] = [
  'media',
  'transcript',
  'automaticEnrichment',
  'manualEnrichment',
];

export type EnrichmentItem = Exclude<keyof JobFeatures, '__typename'>;
export const EnrichmentItems: EnrichmentItem[] = [
  'media',
  'transcript',
  'automaticEnrichment',
  'manualEnrichment'
];

export enum EnrichmentEnum {
  Media = 'media',
  Transcript = 'transcript',
  AutomaticEnrichment = 'automaticEnrichment',
  ManualEnrichment = 'manualEnrichment'
}

export const getEnrichmentLevelLabel = (level: EnrichmentItem): string => {
  switch (level) {
    case 'automaticEnrichment':
      return 'AI Enrichment';
    case 'manualEnrichment':
      return 'Custom Enrichment';//'Manual Enrichment'
    case 'transcript':
      return 'Transcript';
    case 'media':
      return 'Video Processing';
    
    default:
      return level;
  }
}

export const getEnrichmentLevelItemTooltip = (level: EnrichmentItem): ReactNode => {
  switch (level) {
    case 'automaticEnrichment':
      return null;
    case 'manualEnrichment':
      return "Custom Enrichment costs $35 per upload hour. This feature adds human review to ensure the accuracy of CLIPr's native AI processing. Editors review CLIPr’s AI output to verify accuracy and clarify instances where people spoke too quickly or indistinctly or brand terms are spelled differently from the common words they resemble. It improves the quality level of the output and is recommended for larger or more technically-oriented audiences.";
    case 'transcript':
      return null;
    default:
      return null;
  }
}

export const getEnrichmentLevel = (features: JobFeatures): EnrichmentItem | null => {
  for (let i = EnrichmentItems.length - 1; i >= 0; i--) {
    let key: EnrichmentItem = EnrichmentItems[i];

    if (features[key] !== 'NotRequested')
      return key;
  }

  return null;
}

export const getDefaultEnrichmentLevel = (): EnrichmentItem => {
  return 'automaticEnrichment';
}

export const getDefaultJobLevelInput = (): StartJobFeaturesInput => {
  return {
    media: true,
    transcript: false,
    automaticEnrichment: false,
    manualEnrichment: false
  };
}

export const getJobLevelInputLabel = (): string => {
  return 'Enrichment Level';
}

export const generateJobLevelOutput = (feature: EnrichmentItem): StartJobFeaturesInput => {
  const output: StartJobFeaturesInput = {
    media: false,
    transcript: false,
    automaticEnrichment: false,
    manualEnrichment: false
  };

  for (let i = 0; i <= EnrichmentItems.length - 1; i++) {
    let key: EnrichmentItem = EnrichmentItems[i];
    output[key] = true;
    if (key === feature)
      return output;
  }

  return output;
}

export const getDefaultJobLevelOutput = () => {
  const defaultJobLevel = getDefaultEnrichmentLevel();
  return generateJobLevelOutput(defaultJobLevel);
}

export const generateJobLevelInput = (currentLevel: EnrichmentItem, level: EnrichmentItem, status: JobFeatureStatus): JobFeatures => {
  const output: JobFeatures = {
    media: 'Pending',
    transcript: 'Pending',
    automaticEnrichment: 'Pending',
    manualEnrichment: 'Pending'
  };

  const currentIndex = EnrichmentItems.indexOf(currentLevel);
  const levelIndex = EnrichmentItems.indexOf(level);
  assert(currentIndex <= levelIndex, 'Invalid input data');

  for (let i = 0; i <= EnrichmentItems.length - 1; i++) {
    let key: EnrichmentItem = EnrichmentItems[i];
    if (i > levelIndex)
      output[key] = 'NotRequested';
    else if (i === currentIndex)
      output[key] = status;
    else if (i < currentIndex)
      output[key] = 'Done';
    else if (i > currentIndex)
      output[key] = 'Pending';
  }

  return output;
}
