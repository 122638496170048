import './uploadWidget.scss';

import React, { ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { UploadCompactWidgetState } from './uploadCompactWidgetState';
import { Button, LoadMask } from '../../components';
import { percentage } from '../../core';
import { WidgetErrorSVGIcon, WidgetSuccessSVGIcon, WidgetUploadSVGIcon } from './components/uploadWidgetSVG';
import { UserAvatar } from '../../components/layout/userAvatar';
import { RouteContext } from '../../routes/routeContext';
import { useRouteContext } from '../../routes/routeHooks';

import { TRACE } from '../../core/debug/debugMacros';

type Props = {
  model: UploadCompactWidgetState
}

export const UploadCompactWidget = observer(({
  model
}: Props) => {

  TRACE(model, `render()`);

  const routeContext = useRouteContext<RouteContext>();
  useEffect(() => {
    model.mounted(routeContext);
    return () =>
      model.unmounted();
  }, [model, routeContext]);

  if (model.isLoading) {
    return (
      <div className="screen-content">
        <LoadMask className="partial" spinner="dots5" visible={true} />
      </div>
    );
  }

  const status = model.status;
  const screenElem: ReactNode = (() => {

    switch (status) {

      case 'Error':
        return (
          <div className="screen-content error-screen hgrid-2-left">
            <div className="flex-left self-center">
              <span className="action-message error">{model.error?.message ?? 'An error occured'}</span>
            </div>

            <div className="flex-right self-right">
              <div className="status-icon"><WidgetErrorSVGIcon /></div>
            </div>
          </div>
        );

      case 'AwaitingUpload':
        // active after the entire authentication process is completed and the user can upload
        return (
          <div className="screen-content hgrid-2-left">
            <div className="flex-left grid-left self-left">
              <div className="user-info">
                <UserAvatar className="gray" />
                <span className="username"> {model.store.user?.displayName}</span>
              </div>
            </div>

            <div className="flex-left grid-left self-left">
              <span className="action-message">Select the video you want to upload</span>
            </div>

            <div className="flex-right grid-right self-right">
              <button className="btn btn-core secondary btn-upload">
                <input type="file"
                  className="widget-upload-file-input"
                  onChange={evt => model.handleFileInputChange(evt)} />
                <div className="icon">
                  <WidgetUploadSVGIcon />
                </div>
                Upload Video
              </button>
            </div>
          </div>
        );

      case 'Uploading':
        // active while the file is uploading
        return (
          <div className="screen-content hgrid-2-left">
            <div className="flex-left grid-left self-left">
              <div className="user-info">
                <UserAvatar className="gray" />
                <span className="username"> {model.store.user?.displayName}</span>.
              </div>
            </div>

            <div className="flex-left grid-left self-left">
              <span className="action-message">Uploading</span>
            </div>

            <div className="flex-right grid-right self-right">
              {percentage(model.uploadTask?.progress)}
            </div>

            <UploadFileProgressBox
              className={"grid-left-right"}
              progress={model.uploadTask?.progress || 0} />
          </div>
        );

      case 'UploadSuccess':
        return (
          <div className="screen-content success-screen hgrid-2-left">

            <div className="flex-center grid-left">
              <div className="action-message success">Uploaded - We are Processing Your video.</div>
            </div>
            <div className="flex-right grid-right self-right">
              <div className="status-icon"><WidgetSuccessSVGIcon /></div>
            </div>

            <div className="flex-center grid-left relative-container">
              <a className="btn btn-core secondary btn-outline small upload-widget-btn"
                target="_blank"
                rel="noopener noreferrer"
                href={model.jobUrl ?? '#'}>
                <span className="text">Preview</span>
              </a>
              <Button
                className="primary btn-outline small upload-widget-btn"
                label="Copy Embed Code"
                onClick={() => model.copyIFrameEmbedCode()} />
              <span className="link-notification" aria-hidden={!model.linkCopied}>Link copied!</span>
            </div>
          </div >
        );

      case 'UploadError':
        return (
          <div className="screen-content error-screen hgrid-2-left ">
            <div className="flex-left self-center">
              <span className="action-message error">Upload error</span>
            </div>

            <div className="flex-right self-right">
              <div className="status-icon"><WidgetErrorSVGIcon /></div>
            </div>
          </div>
        );
    }

  })();

  return (
    <div className="upload-widget widget">
      <div className="widget-content">
        {screenElem}
      </div>
    </div>
  );
});

export const UploadFileProgressBox = observer(({ progress, className }: { progress: number | string, className?: string }) => {

  className = classNames("file-progress-bar-box", className);
  progress = percentage(typeof progress === 'number' ? Math.min(progress, 1) : progress);

  return (
    <div className={className}>
      <div className="progress-bar" style={{ width: `${progress}` }} />
    </div>
  );
});

export default UploadCompactWidget;