/**
 * Takes ratio values in [0,1] and returns a CSS percentage value.
 */
export function cssPercentage(val: any) {
  if (Number.isFinite(val))
    return val * 100 + '%';
  else if (typeof val === 'string' && val)
    return val;
  return undefined;
}

export function cssPixel(val: any) {
  if (Number.isFinite(val))
    return val + 'px';
  if (typeof val === 'string' && val)
    return val;
  return undefined;
}