import './createTeamForm.scss';

import React, { ReactEventHandler } from 'react';
import { observer } from 'mobx-react-lite';

import {
  DropdownInputControlled,
  TextInputControlled,
  ToggleInputControlled,
} from '../input';

import {
  CheckSVGIcon,
  CloseSVGIcon,
  SquareMinusSVGIcon,
  SquarePlusSVGIcon,
} from '../svg';
import { AddInviteListState, AddInviteState } from './addInviteListState';

type AddInviteListProps = {
  addButtonRight: boolean;
  model: AddInviteListState;
  className?: string;
};

type AddInviteItemProps = {
  isLast: boolean;
  model: AddInviteState;
  onClickRemove: ReactEventHandler;
  onClickAdd: ReactEventHandler;
};

type IncludeMessageSection = {
  model: AddInviteListState;
};

export const AddInviteListGroup = observer((props: AddInviteListProps) => {
  const { model } = props;

  if (!model) return null;

  return (
    <>
      <div
        className={
          'invite-list-headline ' + (props.className ? props.className : null)
        }>
        <p>Invite friends or colleagues</p>
        <ToggleInputControlled
          name="includeInviteMessage"
          label="Include message"
          className="small"
          model={model.includeInviteMessage}
        />
      </div>
      <div className="invite-list-group">
        <AddInviteList model={model} addButtonRight={false} />

        <IncludeMessageSection model={model} />
      </div>
    </>
  );
});

const AddInviteList = observer((props: AddInviteListProps) => {
  const { model } = props;
  const { inviteList, removeInvite, addInvite } = model;

  if (!model || !inviteList || inviteList.length === 0) return null;
  const maxInvites = 5;
  return (
    <ul className="add-invite-list">
      {inviteList.map((model: any, i: number) => {
        return (
          <AddInviteItem
            model={model}
            key={i}
            isLast={inviteList.length - 1 === i && i + 1 < maxInvites}
            onClickRemove={() => removeInvite(i)}
            onClickAdd={() => addInvite()}
          />
        );
      })}
    </ul>
  );
});

const AddInviteItem = observer((props: AddInviteItemProps) => {
  const { model, onClickRemove, onClickAdd } = props;

  if (!model) return null;

  const { error, isSynced, lineGroup, emailInput, roleInput } = model;

  let suffixElement;
  if (isSynced && !error) {
    suffixElement = (
      <div className="line-suffix success-icon">
        <CheckSVGIcon />
      </div>
    );
  } else if (error) {
    suffixElement = (
      <div className="line-suffix error-icon">
        <CloseSVGIcon />
      </div>
    );
  } else if (props.isLast) {
    suffixElement = (
      <button
        className="line-suffix list-remove-item-btn btn-core"
        type={'button'}
        disabled={lineGroup.disabled}
        onClick={onClickAdd}>
        <SquarePlusSVGIcon />
      </button>
    );
  } else
    suffixElement = (
      <button
        className="line-suffix list-remove-item-btn btn-core"
        type={'button'}
        disabled={lineGroup.disabled}
        onClick={onClickRemove}>
        <SquareMinusSVGIcon />
      </button>
    );

  return (
    <>
      <li className="add-invite-line">
        <TextInputControlled
          model={emailInput}
          className="form-input email-input"
        />
        <DropdownInputControlled
          showFeedback={true}
          persistentFeedback={false}
          className="form-input role-input"
          notDeselectable
          model={roleInput}
        />
        <div className="line-actions">{suffixElement}</div>
      </li>
      {error && <div className="invite-error">{error}</div>}
    </>
  );
});

// eslint-disable-next-line  no-redeclare
const IncludeMessageSection = observer((props: IncludeMessageSection) => {
  const { model } = props;

  if (!model) return null;

  return model.includeInviteMessage.value ? (
    <>
      <hr />

      <div className="include-message-section tmar4">
        <TextInputControlled
          model={model.inviteMessage}
          className="form-input include-message"
          rows={4}
          placeholder="Add your Message"
        />
      </div>
    </>
  ) : null;
});
