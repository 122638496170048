import { History } from 'history';
import { action, computed, makeObservable, observable } from 'mobx';
import { v4 as uuid } from 'uuid';

import { ChangeJobOwnerInput, MutationUpdateJobSourceArgs, StartJobInput, UpdateJobInput } from '@clipr/lib';

import { ApiVariables, ApiQueryOptions } from '../api/apiSchema';
import { ApiService } from '../api/apiService';
import Routes from '../routes';
import { BookmarkListWindowState } from '../components/bookmarks/bookmarkListWindowState';
import { BookmarkWindowState } from '../components/bookmarks/bookmarkWindowState';
import {
  DeleteBookmarkListWindowState
} from '../components/bookmarks/deleteBookmarkListWindowState';
import {
  AddJobToTeamWindowState, CopyJobWindowState, DeleteJobWindowState,
  IngestFromExternalLibraryWindowState, RemoveJobFromCliprWindowState,
  RemoveJobFromTeamWindowState, UploadThumbnailWindowState, JobViewStatusWindowState, VideoDetailsWindowState,
  ShareVideoWindowState,
} from '../components/jobs';
import { ChangeJobOwnerWindowState } from '../components/jobs/changeJobOwnerWindowState';
import { RenameJobWindowState } from '../components/jobs/renameJobWindowState';
import { VideoInformationWindowState } from '../components/jobs/videoInformationWindowState';
import { MasterSidebarState } from '../components/layout/masterSidebarState';
import { JobErrorsWindowState } from '../components/momentWindow/jobErrorsWindowState';
import { ClipWindowState } from '../components/momentWindow/clipWindowState';
import { CloseWindowConfirmationModalState } from '../components/momentWindow/closeWindowConfirmationModalState';
import { DeleteMomentsPopupState } from '../components/momentWindow/deleteMomentsPopupState';
import { MultipleClipEditWindowState } from '../components/momentWindow/multipleClipEditWindowState';
import { CommentDeleteWindowState } from '../components/playerComments/commentDeleteWindowState';
import { SpeakerWindowState } from '../components/speakerWindow/speakerWindowState';
import { TeamMembersWindowState } from '../components/teams';
import { BulkInvitesWindowState } from '../components/teams/bulkInvitesWindowState';
import { LeaveTeamWindowState } from '../components/teams/leaveTeamWindowState';
import { RemoveAccessPopupState } from '../components/teams/removeAccessPopupState';
import { MergeTracksWindowState } from '../components/trackWindow/mergeTracksWindowState';
import { TrackWindowState } from '../components/trackWindow/trackWindowState';
import { assertNotNull, AsyncResult } from '../core';
import { Maybe } from '../core/types';
import { Bookmark, BookmarkProps } from '../entities/bookmark';
import { BookmarkList, BookmarkListProps } from '../entities/bookmarkList';
import { LocationDescriptor } from 'history';
import { TrainerKeyboardShortcutsWindowState } from '../pages/trainerVideoPage';
import { BookmarkManager } from '../entities/bookmarkManager';
import { JobModel, JobProps } from '../entities/job';
import { JobManager } from '../entities/jobManager';
import { MomentModel } from '../entities/moment';
import { MOMENT_SELECTOR_STATE_KEY } from '../entities/moments/momentSelector';
import { SpeakerModel, SpeakerProps } from '../entities/speaker';
import { SpeakerManager } from '../entities/speakerManager';
import { TeamManager } from '../entities/teamManager';
import { UserManager } from '../entities/userManager';
import { UserProfile } from '../entities/userProfile';
import { ProfilePageState } from '../pages/account/profilePageState';
import { ForgotPasswordPageState } from '../pages/auth/forgotPassword';
import { LoginPageState } from '../pages/auth/login';
import { OAuthCallbackPageState } from '../pages/oauth';
import { OAuthLogoutPageState } from '../pages/oauth/oauthLogoutPageState';
import { OnboardPageState } from '../pages/auth/onboard';
import { RegisterPageState } from '../pages/auth/register';
import { BookmarksPageState } from '../pages/bookmarksPage';
import { CreateTeamPageState } from '../pages/createTeamPage';
import { DefaultPageState } from '../pages/default/defaultPageState';
import { SearchResultsState } from '../pages/searchResultsPage';
import { TeamDashboardPageState } from '../pages/teamDashboardPage';
import { TeamSettingsPageState } from '../pages/teamSettingsPage';
import { TrainerDashboardPageState } from '../pages/trainerDashboardPage';
import { DeleteTrackState, TrainerVideoPageState } from '../pages/trainerVideoPage';
import { ConfirmationModalState } from '../pages/trainerVideoPage/confirmationModalState';
import { UploadPageState } from '../pages/uploadPage/uploadPageState';
import { UserDashboardPageState } from '../pages/userDashboardPage';
import {
  CredentialsExpiredWindowState
} from '../pages/userDashboardPage/credentialsExpiredWindowState';
import {
  ZoomDateSelectorWindowState
} from '../pages/userDashboardPage/zoomDateSelectorWindowState';
import { UserPlayerPageState } from '../pages/userPlayerPage/userPlayerPageState';
import { UserSplashPageState } from '../pages/userSplashPage/userSplashPageState';
import { AnalyticsService } from '../services/analytics';
import { AuthService } from '../services/auth/authService';
import { LibraryService } from '../services/libraries/libraryService';
import { NetworkService } from '../services/network';
import { NotificationService, notifyError, notifySuccess } from '../services/notifications';
import { OverlayService } from '../services/overlays';
import { ProxyService } from '../services/proxy';
import { RoutingService } from '../services/routing';
import { StorageService } from '../services/storage';
import { UIService } from '../services/ui';
import { UploadService } from '../services/upload/uploadService';
import { WidgetService } from '../services/widget';
import { PlayerWidgetState } from '../widgets/playerWidget';
import { UploadCompactWidgetState } from '../widgets/uploadWidget';
import { StoreNodeProxy } from './storeNodeProxy';
import { createMessage, Message, MessageHandlerWaitMode, StoreNode } from './tree';
import { TeamWidgetState } from '../widgets/teamWidget';
import { PlayerTutorialWindowState } from '../components/playerTutorial/playerTutorialWindowState';
import { PlayerTutorialConfirmationWindowState } from '../components/playerTutorial/playerTutorialConfirmationWindowState';
import { PlayerTutorialTopicsWindowState } from '../components/playerTutorial/playerTutorialTopicsWindowState';
import { PlayerTutorialHelpButtonTooltipState } from '../components/playerTutorial/playerTutorialHelpButtonState';
import { DiagnosticsService } from '../services/diagnostics/diagnosticsService';
import { AnalyticsPageState } from '../pages/analyticsPage/analyticsPageState';
import { PolicyService } from '../services/policy';
import { ExternalLibraryUploadWindowState } from '../pages/uploadPage/externalLibraryUploadWindowState';
import { MakeTeamPublicWindowState } from '../components/teamSettings';
import { CancelUploadWindowState } from '../pages/uploadPage/cancelUploadWindowState';
import { InfoModalState } from '../components/infoModal';
import { AnalyticsWidgetState } from '../widgets/analyticsWidget';
import { LiveStreamQueueWidgetState } from '../widgets/liveStreamQueueWidget/liveStreamQueueWidgetState';
import { AdminTeamsPageState } from '../pages/adminTeamsPage';
import { UnsubscribePageState } from '../pages/account/subscriptionsPageState';
import { UploadWidgetState } from '../widgets/uploadWidget/uploadWidgetState';
import { DeleteStreamWindowState } from '../components/jobs/deleteStreamWindowState';
import { LiveFeedWindowState } from '../components/jobs/liveFeedWindowState';
import { LiveStreamQueuePageState } from '../pages/liveStreamQueuePage/liveStreamQueuePageState';
import { ScheduleStreamWindowState } from '../components/jobs/scheduleStreamWindowState';
import { StreamAssetsWindowState } from '../components/jobs/streamAssetsWindowState';
import { StreamQueueSettingsWindowState } from '../components/jobs/streamQueueSettingsWindowState';
import { DownloadWindowState } from '../components/download/downloadWindowState';
import { TeamProductWindowState } from '../components/teams/teamProductWindowState';
import { UploadJobDetailsWindowState } from '../pages/uploadPage/uploadJobDetailsWindowState';
import { DeleteTeamWindowState } from '../components/teams/deleteTeamWindowState';
import { Kernel } from '../kernel/kernel';
import { ManualIngestWindowState } from '../components/jobs/manualIngestWindowState';
import { LiveNotStreamingWindowState } from '../components/jobs/liveNotStreamingWindowState';
import { SpeakerIdConfirmationWindowState } from '../components/speakerId/speakerIdConfirmationWindowState';
import { PlayerWidgetInjector } from '../widgets/playerWidget/playerWidgetInjector';
import { TeamWidgetInjector } from '../widgets/teamWidget/teamWidgetInjector';
import { UploadWidgetInjector } from '../widgets/uploadWidget/uploadWidgetInjector';
import { AnalyticsWidgetInjector } from '../widgets/analyticsWidget/analyticsWidgetInjector';
import { LiveStreamQueueWidgetInjector } from '../widgets/liveStreamQueueWidget/liveStreamQueueWidgetInjector';
import { PostRegistrationTutorialState } from '../pages/auth/register/postRegistrationTutorialState';
import { INIT_DEBUGGER, TRACE } from '../core/debug/debugMacros';
import { CancelAccountWindowState } from '../pages/account/cancelAccountWindowState';
import { DownloadJobWindowState } from '../components/jobs/downloadJobWindowState';
import { ToggleChatGptWindowState } from '../components/teamSettings/toggleChatGptWindowState';
import { ExportToEdlWindowState } from '../components/jobs/exportToEdlWindowState';
import { TeamDictionaryWindowState } from '../components/teams/teamDictionaryWindowState';
import { DownloadJobSlidesWindowState } from '../components/jobs/downloadJobSlidesWindowState';
import { SearchBarState } from '../components/search/searchBarState';
import { ChatGptSearchResultPageState } from '../pages/chatGptSearchResultPage/chatGptSearchResultPageState';
import { EditorWindowState } from '../components/editorWindow/editorWindowState';
import { TogglePublicSafetyWindowState } from '../components/teamSettings/togglePublicSafetyWindowState';

export class Store {

  readonly nodeType: 'Store' = 'Store';
  readonly id = uuid();

  constructor() {
    makeObservable(this);

    INIT_DEBUGGER(this, { color: 'red' });
    TRACE(this, `constructor()`);
  }

  readonly kernel = new Kernel(this);

  get history(): History {
    return this.routing.history;
  }

  @observable isInitialized = true;

  @computed get user(): UserProfile | null {
    return this.auth.userProfile;
  };
  @computed get isAuthorized() {
    return this.auth.isAuthorized;
  }

  @observable
  isCloseTabWarningEnabled: boolean = false;

  protected readonly nodeLookup = observable.map<string, StoreNodeProxy>();
  @computed
  protected get nodes() {
    return [...this.nodeLookup.values()];
  }

  // #region Entities
  @computed
  get jobs(): JobModel[] {
    return this.jobManager.jobs;
  }

  @computed
  get adminJobs(): JobModel[] {
    return this.jobManager.adminJobs;
  }

  @computed
  get bookmarks(): Bookmark[] {
    return this.bookmarkManager.bookmarks;
  }

  @computed
  get bookmarkLists(): BookmarkList[] {
    return this.bookmarkManager.bookmarkLists;
  }

  @computed
  get moments(): MomentModel[] {
    return this.jobManager.moments;
  }
  // #endregion

  // #region Services
  readonly apiService = new ApiService(this);
  get api() { return this.apiService; }

  readonly authService = new AuthService(this);
  get auth() { return this.authService; }

  readonly notificationService = new NotificationService(this);
  get notifications() { return this.notificationService; }

  readonly uploadService = new UploadService(this);
  get upload() { return this.uploadService; }

  readonly overlayService = new OverlayService(this);
  get overlays() { return this.overlayService; }

  readonly routingService = new RoutingService(this);
  get router() { return this.routingService; }
  get routing() { return this.routingService; }

  readonly analyticsService = new AnalyticsService(this);
  get analytics() { return this.analyticsService; }

  readonly networkService = new NetworkService(this);
  get network() { return this.networkService; }

  readonly storageService = new StorageService(this);
  get storage() { return this.storageService; }

  readonly widgetService = new WidgetService(this);
  get widget() { return this.widgetService; }

  readonly libraryService = new LibraryService(this);
  get googleDrive() { return this.libraryService.googleDriveService; }
  get oneDrive() { return this.libraryService.oneDriveService; }
  get zoom() { return this.libraryService.zoomService; }

  readonly uiService = new UIService(this);
  get ui() { return this.uiService; }

  readonly proxyService = new ProxyService(this);
  get proxy() { return this.proxyService; }

  readonly diagnosticsService = new DiagnosticsService(this);
  get diagnostics() { return this.diagnosticsService; }

  readonly policyService = new PolicyService(this);
  get policy() { return this.policyService; }

  get resourceService() { return this.kernel.resourceService; }
  get resource() { return this.resourceService; }

  get vendorService() { return this.kernel.vendorService; }
  get vendor() { return this.vendorService; }

  // #endregion

  // #region Service component shortcuts
  get fullscreen() {
    return this.uiService.fullscreen;
  }
  get orientation() {
    return this.uiService.orientation;
  }
  get autoplay() {
    return this.uiService.autoplay;
  }
  // #endregion


  // #region Managers
  readonly bookmarkManager = new BookmarkManager(this);
  readonly jobManager = new JobManager(this);
  readonly teamManager = new TeamManager(this);
  readonly userManager = new UserManager(this);
  readonly speakerManager = new SpeakerManager(this);
  // #endregion

  // #region Components
  readonly pageSidebar = new MasterSidebarState(this);
  readonly searchBar = new SearchBarState(this);
  // #endregion

  // #region Pages
  readonly defaultPage = new DefaultPageState(this);
  readonly loginPage = new LoginPageState(this);
  readonly registerPage = new RegisterPageState(this);
  readonly onboardPage = new OnboardPageState(this);
  readonly forgotPasswordPage = new ForgotPasswordPageState(this);
  readonly oauthCallbackPage = new OAuthCallbackPageState(this);
  readonly oauthLogoutPage = new OAuthLogoutPageState(this);
  readonly unsubscribePage = new UnsubscribePageState(this);

  readonly userSplashPage = new UserSplashPageState(this);
  readonly userPlayerPage = new UserPlayerPageState(this);
  readonly userDashboardPage = new UserDashboardPageState(this);
  readonly trainerVideoPage = new TrainerVideoPageState(this);
  readonly trainerDashboardPage = new TrainerDashboardPageState(this);
  readonly teamDashboardPage = new TeamDashboardPageState(this);
  readonly teamSettingsPage = new TeamSettingsPageState(this);
  readonly liveStreamQueuePage = new LiveStreamQueuePageState(this);
  readonly adminTeamsPage = new AdminTeamsPageState(this);
  readonly uploadPage = new UploadPageState(this);
  readonly bookmarksPage = new BookmarksPageState(this);
  readonly searchResultsPage = new SearchResultsState(this);
  readonly chatGptSearchResultPageState = new ChatGptSearchResultPageState(this);
  readonly createTeamPage = new CreateTeamPageState(this);
  readonly profilePage = new ProfilePageState(this);
  readonly analyticsPage = new AnalyticsPageState(this);
  // #endregion

  // #region Windows
  readonly editorWindow = new EditorWindowState(this);
  readonly bookmarkWindow = new BookmarkWindowState(this);
  readonly bookmarkListWindow = new BookmarkListWindowState(this);
  readonly deleteBookmarkListWindow = new DeleteBookmarkListWindowState(this);
  readonly shareVideoWindow = new ShareVideoWindowState(this);
  readonly multipleClipEditWindow = new MultipleClipEditWindowState(this);
  readonly teamMembersWindow = new TeamMembersWindowState(this);
  readonly teamDictionaryWindow = new TeamDictionaryWindowState(this);
  readonly copyJobWindow = new CopyJobWindowState(this);
  readonly addJobToTeamWindow = new AddJobToTeamWindowState(this);
  readonly removeJobFromTeamWindow = new RemoveJobFromTeamWindowState(this);
  readonly deleteJobWindow = new DeleteJobWindowState(this);
  readonly downloadJobWindow = new DownloadJobWindowState(this);
  readonly exportToEdlWindow = new ExportToEdlWindowState(this);
  readonly deleteStreamWindow = new DeleteStreamWindowState(this);
  readonly removeJobFromCliprWindow = new RemoveJobFromCliprWindowState(this);
  readonly ingestFromExternalLibraryWindow = new IngestFromExternalLibraryWindowState(this);
  readonly renameJobWindow = new RenameJobWindowState(this);
  readonly changeJobOwnerWindow = new ChangeJobOwnerWindowState(this);
  readonly playerTutorialHelpButtonTooltip = new PlayerTutorialHelpButtonTooltipState(this);
  readonly playerTutorialWindow = new PlayerTutorialWindowState(this, {});
  readonly postRegistrationWindow = new PostRegistrationTutorialState(this);
  readonly playerTutorialTopicsWindow = new PlayerTutorialTopicsWindowState(this);
  readonly playerTutorialConfirmationWindowState = new PlayerTutorialConfirmationWindowState(this);
  readonly speakerWindow = new SpeakerWindowState(this);
  readonly trackWindow = new TrackWindowState(this);
  readonly deleteTrack = new DeleteTrackState(this);
  readonly removeAccessPopup = new RemoveAccessPopupState(this);
  readonly leaveTeamWindow = new LeaveTeamWindowState(this)
  readonly videoInformationWindow = new VideoInformationWindowState(this);
  readonly externalLibraryUploadWindow = new ExternalLibraryUploadWindowState(this);
  readonly makeTeamPublicWindow = new MakeTeamPublicWindowState(this);
  readonly toggleChatGptWindow = new ToggleChatGptWindowState(this);
  readonly togglePublicSafetyWindow = new TogglePublicSafetyWindowState(this);
  readonly cancelUploadWindow = new CancelUploadWindowState(this);
  readonly deleteMomentsPopup = new DeleteMomentsPopupState(this);
  readonly clipWindow = new ClipWindowState(this);
  readonly bulkInvitesWindow = new BulkInvitesWindowState(this);
  readonly closeWindowConfirmationModal = new CloseWindowConfirmationModalState(this);
  readonly confirmationModal = new ConfirmationModalState(this);
  readonly mergeTracksWindow = new MergeTracksWindowState(this);
  readonly jobErrorsWindow = new JobErrorsWindowState(this);
  readonly commentDeleteWindow = new CommentDeleteWindowState(this);
  readonly jobViewStatusWindow = new JobViewStatusWindowState(this);
  readonly credentialsExpiredWindow = new CredentialsExpiredWindowState(this);
  readonly zoomDateSelectorWindow = new ZoomDateSelectorWindowState(this);
  readonly uploadThumbnailWindow = new UploadThumbnailWindowState(this);
  readonly videoDetailsWindow = new VideoDetailsWindowState(this);
  readonly scheduleStreamWindow = new ScheduleStreamWindowState(this);
  readonly assetsWindow = new StreamAssetsWindowState(this);
  readonly liveFeedWindow = new LiveFeedWindowState(this);
  readonly liveNotStreamingWindow = new LiveNotStreamingWindowState(this);
  readonly manualIngestWindow = new ManualIngestWindowState(this);
  readonly streamQueueSettingsWindow = new StreamQueueSettingsWindowState(this);
  readonly trainerKeyboardShortcutsWindow = new TrainerKeyboardShortcutsWindowState(this);
  readonly infoModal = new InfoModalState(this);
  readonly downloadWindow = new DownloadWindowState(this);
  readonly downloadJobSlidesWindow = new DownloadJobSlidesWindowState(this);
  readonly teamProductWindow = new TeamProductWindowState(this);
  readonly uploadJobDetailsWindow = new UploadJobDetailsWindowState(this);
  readonly deleteTeamWindow = new DeleteTeamWindowState(this);
  readonly speakerIdWindow = new SpeakerIdConfirmationWindowState(this);
  readonly cancelAccountWindow = new CancelAccountWindowState(this);
  // #endregion

  // #region Widgets
  readonly playerWidget = new PlayerWidgetState(this);
  readonly playerWidgetInjector = new PlayerWidgetInjector(this);
  readonly uploadWidget = new UploadWidgetState(this);
  readonly uploadCompactWidget = new UploadCompactWidgetState(this);
  readonly uploadWidgetInjector = new UploadWidgetInjector(this);
  readonly teamLibraryWidget = new TeamWidgetState(this);
  readonly teamWidgetInjector = new TeamWidgetInjector(this);
  readonly liveStreamQueueWidget = new LiveStreamQueueWidgetState(this);
  readonly liveStreamQueueWidgetInjector = new LiveStreamQueueWidgetInjector(this);
  readonly analyticsWidget = new AnalyticsWidgetState(this);
  readonly analyticsWidgetInjector = new AnalyticsWidgetInjector(this);

  // #endregion

  /**
   * Runs the initialization tasks for the Store, including fetching required data.
   */
  @action
  init() {

    const {
      diagnosticsService,

      routingService,
      proxyService,
      authService,
      analyticsService,
      widgetService
    } = this;

    // configure services
    authService.registerProfileStorageKey(MOMENT_SELECTOR_STATE_KEY);

    // initialize Services
    if (process.env.NODE_ENV !== 'development')
      diagnosticsService.init();

    routingService.init();
    proxyService.init();
    authService.init();
    analyticsService.init();
    widgetService.init();

    this.isInitialized = true;
  }

  dispose() {
    this.auth.dispose();
  }

  // #region Routing
  // -------

  /** Shortcut for `RoutingService.goTo`. */
  goTo(route: string | LocationDescriptor<any>) {
    this.routing.goTo(route);
  }

  // #endregion

  // #region Jobs
  // -------
  hasJob(id: string) {
    return this.jobManager.hasJob(id);
  }

  getJob(id: string) {
    return this.jobManager.getJob(id);
  }
  maybeGetJob(id: Maybe<string>) {
    return this.jobManager.maybeGetJob(id);
  }

  @action
  insertJob(jobData: JobProps) {
    return this.jobManager.insertJob(jobData);
  }

  async apiFetchJob(id: string, deps = false, opts?: ApiQueryOptions) {
    return this.jobManager.apiFetchJob(id, deps, opts);
  }

  async apiStartJob(params: StartJobInput): AsyncResult<JobModel> {
    return this.jobManager.apiStartJob(params);
  }

  async apiUpdateJob(params: UpdateJobInput): AsyncResult<JobModel> {
    return this.jobManager.apiUpdateJob(params);
  }

  async apiUpdateJobSource(params: MutationUpdateJobSourceArgs): AsyncResult<JobModel> {
    return this.jobManager.apiUpdateJobSource(params);
  }

  async apiChangeJobOwner(params: ChangeJobOwnerInput): AsyncResult<JobModel> {
    return this.jobManager.apiChangeJobOwner(params);
  }

  getMoment(id: string) {
    return this.jobManager.getMoment(id);
  }
  // #endregion

  // #region Bookmark
  // -------
  getBookmark(id: string) {
    return this.bookmarkManager.getBookmark(id);
  }
  maybeGetBookmark(id: string) {
    return this.bookmarkManager.maybeGetBookmark(id);
  }

  @action
  insertBookmark(props: BookmarkProps) {
    return this.bookmarkManager.insertBookmark(props);
  }

  @action
  removeBookmark(id: string) {
    return this.bookmarkManager.removeBookmark(id);
  }

  async apiFetchBookmarks(args: ApiVariables<'getBookmarks'>, opts?: ApiQueryOptions): AsyncResult<Bookmark[]> {
    return this.bookmarkManager.apiFetchBookmarks(args, opts);
  }

  async apiCreateBookmark(args: ApiVariables<'createBookmark'>): AsyncResult<Bookmark> {
    return this.bookmarkManager.apiCreateBookmark(args);
  }

  async apiDeleteBokmark(args: ApiVariables<'deleteBookmark'>): AsyncResult<Bookmark> {
    return this.bookmarkManager.apiDeleteBokmark(args);
  }

  async apiFetchMomentBookmarks(args: ApiVariables<'getMomentBookmarks'>): AsyncResult<Bookmark[]> {
    return this.bookmarkManager.apiFetchMomentBookmarks(args);
  }

  async apiFetchJobRelatedBookmarks(args: ApiVariables<'getJobRelatedBookmarks'>, opts?: ApiQueryOptions): AsyncResult<Bookmark[]> {
    return this.bookmarkManager.apiFetchJobRelatedBookmarks(args, opts);
  }

  // #endregion

  // #region BookmarkList
  // -------
  hasBookmarkList(id: string) {
    return this.bookmarkManager.hasBookmarkList(id);
  }

  getBookmarkList(id: string): BookmarkList {
    return this.bookmarkManager.getBookmarkList(id);
  }

  @action
  insertBookmarkList(props: BookmarkListProps): BookmarkList {
    return this.bookmarkManager.insertBookmarkList(props);
  }

  @action
  removeBookmarkList(id: string): BookmarkList {
    return this.bookmarkManager.removeBookmarkList(id);
  }

  async apiFetchBookmarkLists(opts?: ApiQueryOptions): AsyncResult<BookmarkList[]> {
    return this.bookmarkManager.apiFetchBookmarkLists(opts);
  }

  async apiFetchBookmarkList(id: string): AsyncResult<BookmarkList> {
    return this.bookmarkManager.apiFetchBookmarkList(id);
  }

  async apiCreateBookmarkList(args: ApiVariables<'createBookmarkList'>): AsyncResult<BookmarkList> {
    return this.bookmarkManager.apiCreateBookmarkList(args);
  }

  async apiUpdateBookmarkList(args: ApiVariables<'updateBookmarkList'>): AsyncResult<BookmarkList> {
    return this.bookmarkManager.apiUpdateBookmarkList(args);
  }

  async apiDeleteBokmarkList(args: ApiVariables<'deleteBookmarkList'>): AsyncResult<BookmarkList> {
    return this.bookmarkManager.apiDeleteBokmarkList(args);
  }
  // #endregion

  // #region Speakers
  // -------
  hasSpeaker(id: string) {
    return this.speakerManager.hasSpeaker(id);
  }

  getSpeaker(id: string) {
    return this.speakerManager.getSpeaker(id);
  }
  maybeGetSpeaker(id: Maybe<string>) {
    return this.speakerManager.maybeGetSpeaker(id);
  }

  @action
  insertSpeaker(speakerData: SpeakerProps) {
    return this.speakerManager.insertSpeaker(speakerData);
  }

  async apiFetchSpeakers(args: ApiVariables<'getSpeakers'>, opts?: ApiQueryOptions) {
    return this.speakerManager.apiFetchSpeakers(args, opts);
  }

  async apiFetchSpeaker(args: ApiVariables<'getSpeaker'>, opts?: ApiQueryOptions) {
    return this.speakerManager.apiFetchSpeaker(args, opts);
  }

  async apiAddSpeaker(args: ApiVariables<'addSpeaker'>): AsyncResult<SpeakerModel> {
    return this.speakerManager.apiAddSpeaker(args);
  }

  async apiUpdateSpeaker(args: ApiVariables<'updateSpeaker'>): AsyncResult<SpeakerModel> {
    return this.speakerManager.apiUpdateSpeaker(args);
  }
  // #endregion

  // #region Tree
  // -------
  @action
  register<TNode extends StoreNode = StoreNode>(proxy: StoreNodeProxy<TNode>) {
    const { node } = proxy;
    this.nodeLookup.set(node.id, proxy as any);
    return proxy;
  }

  @action
  unregister(node: StoreNode) {
    this.nodeLookup.delete(node.id);
  }

  @action
  nodeBroadcast(msg: Message) {
    const proxy = this.nodeLookup.get(msg.sender.id);
    assertNotNull(proxy, `Received a 'broadcast' request from node ${msg.sender.id} which is not registered into the Store.`);

    this.nodes.forEach(proxy => {
      if (typeof proxy.receiver === 'function' &&
        proxy.receiveFilter.includes(msg.fullType)) {
        proxy.receiver(msg);
      }
    });
  }

  @action
  async nodeEmit(msg: Message, waitMode: MessageHandlerWaitMode = MessageHandlerWaitMode.None): Promise<void> {

    const proxy = this.nodeLookup.get(msg.sender.id);
    assertNotNull(proxy, `Received an 'emit' request from node ${msg.sender.id} which is not registered into the Store.`);

    const listeners = proxy.listeners;

    switch (waitMode) {

      case MessageHandlerWaitMode.Serial:
        for (const listener of listeners)
          await listener(msg);
        return;

      case MessageHandlerWaitMode.Parallel:
        await Promise.all(
          listeners.map(listener => listener(msg)));
        return;

      case MessageHandlerWaitMode.First:
        await Promise.any(
          listeners.map(listener => listener(msg)));
        return;

      default:
      case MessageHandlerWaitMode.None:
        listeners.forEach(listener =>
          listener(msg));
        return;
    }
  }

  @action
  async nodeDispatch(msg: Message) {
    const { payload } = msg;

    if (msg.target) {
      switch (msg.target) {
        case 'Notifications':
        case 'NotificationService':
          this.notificationService.invoke(msg.type, msg.payload);
          break;

        case 'Overlays':
        case 'OverlayService':
          this.overlayService.invoke(msg.type, msg.payload);
          break;

        case 'Routing':
        case 'RoutingService':
          this.routingService.invoke(msg.type, msg.payload);
          break;
      }
      return;
    }

    switch (msg.type) {

      case 'deleteBookmark': {
        const bkm = this.getBookmark(msg.payload.bookmarkId);

        const [, err] = await this.apiDeleteBokmark({
          bookmarkId: bkm.id,
          listId: bkm.listId
        });

        if (err)
          return notifyError(msg.sender, 'Could not remove bookmark.');
        notifySuccess(msg.sender, 'Bookmark removed.');
        await this.apiFetchBookmarkList(bkm.listId);

      } break;

      case 'openBookmarkInUserPlayer': {
        const bkmId = payload.bookmarkId;
        const bkm = this.maybeGetBookmark(bkmId);
        if (!bkm || !bkm.jobId)
          break;

        let query: string | undefined;
        if (bkm.momentId)
          query = `momentId=${bkm.momentId}`;

        this.goTo(Routes.userVideo(bkm.jobId, query));
      } break;

      case 'openBookmarkWindow':
        this.bookmarkWindow.openBookmark(payload.bookmarkId);
        break;

      case 'openEditBookmarkListWindow':
        this.bookmarkListWindow.openEdit(payload.bookmarkListId);
        break;

      case 'openDeleteBookmarkListWindow':
        this.deleteBookmarkListWindow.open({
          bookmarkListId: payload.bookmarkListId,
          shouldRedirect: payload.shouldRedirect
        });
        break;

      case 'openShareVideoWindow':
        this.shareVideoWindow.open({
          bookmark: payload.bookmark,
          mode: payload.mode,
          sharedFromLocation: payload.sharedFromLocation,
          job: payload.job,
          teamId: payload.teamId,
          moment: payload.moment
        });
        break;

      case 'openCopyJobWindow':
        this.copyJobWindow.open({
          jobId: payload.jobId,
          teamId: payload.teamId
        });
        break;

      case 'openAddJobToTeamWindow':
        this.addJobToTeamWindow.open({
          jobId: payload.jobId
        });
        break;

      case 'openRemoveJobFromTeamWindow':
        this.removeJobFromTeamWindow.open({
          jobId: payload.jobId,
          teamId: payload.teamId
        });
        break;

      case 'openDeleteJobWindow':
        this.deleteJobWindow.open({
          jobId: payload.jobId,
          teamId: payload.teamId
        });
        break;

      case 'openDownloadJobWindow':
        this.downloadJobWindow.open({
          jobId: payload.jobId,
          teamId: payload.teamId,
          startTime: payload.startTime,
          endTime: payload.endTime
        });
        break;

      case 'openExportToEdlWindow':
        this.exportToEdlWindow.open({
          bookmarkListId: payload.bookmarkListId
        });
        break;

      case 'openDownloadTranscriptWindow':
        this.downloadWindow.open({
          message: payload.message,
          onSubmit: payload.onSubmit,
          formatItems: payload.formatItems,
          title: payload.title
        });
        break;

      case 'openDownloadJobSlidesWindow': 
        this.downloadJobSlidesWindow.open({
          jobId: payload.jobId
        })
        break;

      case 'openDeleteStreamWindow':
        this.deleteStreamWindow.open({
          streamId: payload.streamId,
          streamName: payload.streamName
        });
        break;
      case 'openRemoveJobFromCliprWindow':
        this.removeJobFromCliprWindow.open({
          jobId: payload.jobId,
          teamId: payload.teamId
        });
        break;

      case 'openIngestFromExternalLibraryWindow':
        this.ingestFromExternalLibraryWindow.open(payload);
        break;

      case 'openRenameJobWindow':
        this.renameJobWindow.open({
          page: payload.page,
          jobId: payload.jobId,
          title: payload.title
        });
        break;

      case 'openChangeJobOwnerWindow':
        this.changeJobOwnerWindow.open({
          jobId: payload.jobId
        });
        break;

      case 'openPostRegistrationTutorial': {
        this.postRegistrationWindow.open();
        break;
      }

      case 'openPlayerTutorialWindow':
        this.playerTutorialWindow.open({
          steps: payload.steps,
          pageNumber: payload.pageNumber
        });
        break;

      case 'openPlayerTutorialHelpButtonTooltip':
        this.playerTutorialHelpButtonTooltip.open();
        break;

      case 'openPlayerTutorialConfirmationWindow':
        this.playerTutorialConfirmationWindowState.open({
          steps: payload.steps
        });
        break;

      case 'openPlayerTutorialTopicsWindow':
        this.playerTutorialTopicsWindow.open({
          steps: payload.steps,
        });
        break;

      case 'openDeleteTrackPopup':
        this.deleteTrack.open({
          job: payload.job,
          jobId: payload.jobId,
          trackId: payload.trackId
        });
        break;

      case 'openLeaveTeamWindow':
        this.leaveTeamWindow.open({
          teamId: payload.teamId
        });
        break;

      case 'openDeleteTeamWindow':
        this.deleteTeamWindow.open({
          teamId: payload.teamId
        });
        break;

      case 'openToggleChatGptWindow':
        this.toggleChatGptWindow.open({
          teamId: payload.teamId,
          provider: payload.provider,
          summarizationEnabled: payload.summarizationEnabled,
          actionItemsExtractionEnabled: payload.actionItemsExtractionEnabled,
          chatGptEnabled: payload.chatGptEnabled,
          description: payload.description,
          button: payload.button,
          header: payload.header
        });
        break;

      case 'openTogglePublicSafetyWindow':
        this.togglePublicSafetyWindow.open({
          teamId: payload.teamId,
          publicSafety: payload.publicSafety
        });
        break;

      case 'openVideoInformationWindow':
        this.videoInformationWindow.open({
          jobId: payload.jobId
        });
        break;

      case 'openCredentialsExpiredWindow':
        this.credentialsExpiredWindow.open({
          library: payload.library
        });
        break;

      case 'openUploadThumbnailWindow':
        this.uploadThumbnailWindow.open({
          jobId: payload.jobId
        });
        break;

      case 'openTrainerKeyboardShortcutsWindow':
        this.trainerKeyboardShortcutsWindow.open();
        break;

      case 'enableCloseTabWarning':
        if (this.isCloseTabWarningEnabled)
          break; // already enabled

        window.onbeforeunload = (evt: BeforeUnloadEvent) => {
          evt.preventDefault();
          evt.returnValue = '';
        }

        this.isCloseTabWarningEnabled = true;
        break;

      case 'disableCloseTabWarning':
        window.onbeforeunload = null;
        this.isCloseTabWarningEnabled = false;
        break;

      case 'openVideoDetailsWindow':
        this.videoDetailsWindow.open({
          jobId: payload.jobId,
          teamId: payload.teamId,
          layoutType: payload.layoutType || 'partial'
        });
        break;

      case 'openScheduleStreamWindow':
        this.scheduleStreamWindow.open({
          jobId: payload.jobId,
          teamId: payload.teamId,
          layoutType: payload.layoutType || 'default'
        });
        break;

      case 'openAssetsWindow':
        this.assetsWindow.open({
          teamId: payload.teamId,
          jobId: payload.jobId
        });
        break;

      case 'openliveFeedWindow':
        this.liveFeedWindow.open({
          jobId: payload.jobId,
          teamId: payload.teamId,
        });
        break;

      case 'openLiveNotStreamingWindow':
        this.liveNotStreamingWindow.open({
          jobId: payload.jobId,
          liveStreamActivePlaylist: payload.liveStreamActivePlaylist,
        });
        break;

      case 'openManualIngestWindow':
        this.manualIngestWindow.open({
          jobId: payload.jobId,
          teamId: payload.teamId,
        });
        break;

      case 'openStreamQueueSettingsWindow':
        this.streamQueueSettingsWindow.open({
          teamId: payload.teamId,
        });
        break;

      case 'openConfirmationModal':
        this.confirmationModal.open({
          onSubmit: payload.onSubmit,
          onCancel: payload.onCancel,
          onClose: payload.onClose,
          modalMessage: payload.message,
          modalSecondaryMessage: payload.secondaryMessage,
          title: payload.title,
          confirmLabel: payload.confirmLabel,
          closeLabel: payload.closeLabel,
          isLoading: payload.isLoading,
          layout: payload.layout
        });
        break;
      case 'openJobViewStatusWindow':
        this.jobViewStatusWindow.open({
          jobId: payload.jobId,
          teamId: payload.teamId,
          isPublished: payload.isPublished
        });
        break;

      case 'openExternalLibraryUploadWindow':
        this.externalLibraryUploadWindow.open({
          activeTab: payload.activeTab
        });
        break;

      case 'makeTeamPublicWindow':
        this.makeTeamPublicWindow.open({
          teamId: payload.teamId
        });
        break;

      case 'openCancelUploadWindow':
        this.cancelUploadWindow.open({
          task: payload.task
        });
        break;

      case 'openInfoModal':
        this.infoModal.open({
          title: payload.title,
          content: payload.content,
          windowClassName: payload.windowClassName
        });
        break;

      case 'openTeamProductWindow':
        this.teamProductWindow.open({
          team: payload.team
        });
        break;

      case 'openUploadJobDetailsWindow':
        this.uploadJobDetailsWindow.open({
          task: payload.task
        })
        break;
      case 'openSpeakerIdConfirmationWindow':
        this.speakerIdWindow.open({
          onSubmit: payload.onSubmit,
          onCancel: payload.onCancel,
          onClose: payload.onClose,
          onSuppress: payload.onSuppress,
          targetCount: payload.targetCount,
          jobId: payload.jobId
        });
        break;

      case 'openCancelAccountWindow':
        this.cancelAccountWindow.open();
        break;

      case 'openTeamDictionaryWindow':
        this.teamDictionaryWindow.open(payload.teamId);
        break;

      case 'openEditorWindow':
        this.editorWindow.open({
          data: payload.data,
          fileName: payload.fileName,
          mode: payload.mode
        });
        break;
    }
  }

  @action
  dispatch(target: string, type: string, payload: any) {
    const msg = createMessage(null as any, 'dispatch', type, payload);
    msg.target = target;
    this.nodeDispatch(msg);
    return this;
  }
  // #endregion

  logout() {
    this.routingService.goTo(Routes.logout());
  }

  private async _revertOnboarding() {
    await this.api.updateProfile({
      args: {
        showOnboarding: true
      },
    });
  }

  private async _revertPlayerTutorial() {
    await this.api.updateProfile({
      args: {
        showPlayerTutorial: true
      },
    });
  }
}