import { action, makeObservable } from 'mobx';
import { Store } from '../../../store/store';
import { StoreNode, Message } from '../../../store';
import { WindowState } from '../../../components/overlays/windowState';

export class PostRegistrationTutorialState
  extends StoreNode {
  readonly nodeType = 'PostRegistrationTutorialWindow';
  constructor(store: Store, props?: any) {
    super(store, props);
    makeObservable(this);
    this.window.listen(
      this.windowListener);
  }

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  readonly window = new WindowState(this.store);

  @action
  open() {
    this.dispatch('Overlays', 'openWindow', { name: 'PostRegistrationTutorialWindow' });
  }

  @action
  close() {
    this.dispatch('Overlays', 'closeWindow');
  }
}