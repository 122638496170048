import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { Thumb, ThumbSource } from './thumb';

type Props = {
  sources: ThumbSource[];
  showPlaceholder?: boolean;
}

export const ThumbGroup = observer((props: Props) => {
  const { sources, showPlaceholder } = props;

  let visibleSources = sources.slice(0, 4);
  let visibleLen = visibleSources.length;

  const className = classNames('thumb-group', {
    'empty': visibleLen === 0
  });

  return (
    <div className={className}
      data-items={visibleLen}>

      {visibleSources.map(src =>
        <div key={typeof src === 'string' ? src : src.id} className="thumb-container">
          <Thumb
            source={src} />
        </div>
      )}
      {visibleSources.length === 0 &&
        showPlaceholder &&
        <Thumb
          showPlaceholder={true} />}
    </div>
  )
});