import './widgets.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '../input';
import { AuthFlowName } from '../../services/auth/authFlowSchema';

type Props = {
  flowName?: AuthFlowName | null;
  showAbortButton?: boolean,
  onAbortButtonClick?: React.MouseEventHandler;
  showFocusButton?: boolean;
  onFocusButtonClick?: React.MouseEventHandler;
}

export const WidgetProxyFlowScreen = observer(({
  flowName,
  showAbortButton = true,
  onAbortButtonClick,
  showFocusButton = true,
  onFocusButtonClick
}: Props) => {

  const message: string = (() => {
    switch (flowName) {
      default:
      case AuthFlowName.ProxyLogin:
      case AuthFlowName.InlineProxyLogin:
        return 'Go to the newly opened tab and create an account or sign in.';

      case AuthFlowName.InlineProxyLogout:
        return 'You are being signed out in a separate tab.';
    }
  })();

  return (
    <div className="widget-proxy-flow-screen">
      <div className="screen-content">

        <span className="action-message text-center">
          {message}
        </span>

        <div className="action-buttons flex-center gap4">
          {showAbortButton &&
            <Button
              className="secondary"
              label="Cancel"
              onClick={onAbortButtonClick} />}

          {showFocusButton &&
            <Button
              className="primary"
              label="Focus window"
              onClick={onFocusButtonClick} />}
        </div>

      </div>
    </div>
  );
});