import Auth0, { Auth0Error } from 'auth0-js';
import { makeObservable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { AsyncResult, isNonEmptyString, Maybe, Result } from '../../core';
import { Auth0ChangePasswordResponseUnsafe, Auth0DecodedHash, Auth0SignUpOptionsUnsafe, Auth0SignUpResponseUnsafe } from '../../vendor';
import { Config } from '../../config';
import { Routes } from '../../routes';
import { AuthError, AuthErrorType } from './authError';
import { createAuthPermitFromHash } from './auth0Utils';
import { AuthPermit } from './authPermit';
import { AuthReply, AuthWaitForRedirectReply, ResetPasswordReply } from './authReply';
import { assertValidChangePasswordInput, assertValidLoginInput, assertValidRegisterInput } from './authUtils';
import { ResetPasswordInput, LoginInput, RegisterInput, SocialLoginProvider } from './authSchema';
import { getAbsoluteUrl } from '../routing/routingUtils';


export type Auth0LoginErrorType =
  'access_denied' |
  'invalid_user_password' |
  'mfa_invalid_code' |
  'mfa_registration_required' |
  'mfa_required' |
  'password_leaked' |
  'PasswordHistoryError' |
  'PasswordStrengthError' |
  'too_many_attempts' |
  'unauthorized';

export type Auth0SignUpErrorType =
  'invalid_password' |
  'invalid_signup' |
  'password_dictionary_error' |
  'password_no_user_info_error' |
  'password_strength_error' |
  'user_exists' |
  'username_exists' |
  'unauthorized';


const AUTH0_GOOGLE_CONNECTION_ENABLED: boolean = true;
const AUTH0_GOOGLE_CONNECTION: string | null =
  'google-oauth2';

const AUTH0_FACEBOOK_CONNECTION_ENABLED: boolean = true;
const AUTH0_FACEBOOK_CONNECTION: string | null =
  'facebook';

const AUTH0_LINKEDIN_CONNECTION_ENABLED: boolean = true;
const AUTH0_LINKEDIN_CONNECTION: string | null =
  'linkedin';

const AUTH0_TWITTER_CONNECTION_ENABLED: boolean = true;
const AUTH0_TWITTER_CONNECTION: string | null =
  'twitter';

const AUTH0_MICROSOFT_CONNECTION_ENABLED: boolean = true;
const AUTH0_MICROSOFT_CONNECTION: string | null =
  'windowslive';


type Props = {

}

const ModuleConfig = Config.auth.auth0;

const getUrl = (route: string) => {
  return window.location.origin + route;
}

const AUTH0_CALLBACK_REDIRECT_URL =
  getUrl(Routes.oauthCallback());

const AUTH0_CONNECTION: string =
  'Password-Authentication';

// const AUTH0_LOGOUT_REDIRECT_URL =
//   getUrl(Routes.oauthLogout());

/**
 * This component contains wrappers around the auth0 methods to make them return AsyncResults
 * and also some additional utilities.
 */
export class Auth0Adapter
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  readonly client = new Auth0.WebAuth({
    domain: ModuleConfig.domain,
    clientID: ModuleConfig.clientId,
    redirectUri: AUTH0_CALLBACK_REDIRECT_URL,
    responseType: ModuleConfig.responseType,
    // TODO: might not be needed since we're passing data through the query string, our own way,
    // because Auth0 SDK was probably written on a boat somewhere and they cannot provide
    // a consistent way of passing data between redirects
    __tryLocalStorageFirst: true
  });

  // #region `login` adapter

  // #region Login
  // -------

  /**
   * Makes a call to `WebAuth.login` and returns a standard AsyncResult promise.
   * This method should only return if a client error occurs, otherwise a redirect is expected.
   */
  async login(input: LoginInput): AsyncResult<AuthWaitForRedirectReply, AuthError> {

    assertValidLoginInput(input);

    const { routingService } = this.store;
    const [redirPath, redirErr] = routingService.prepareRedirectUrl(
      Routes.oauthCallback());

    if (redirErr)
      return [null, redirErr];

    const redirectUrl = getAbsoluteUrl(redirPath!);

    const params: Auth0.CrossOriginLoginOptions = {
      email: input.username,
      password: input.password,
      realm: AUTH0_CONNECTION,
      redirectUri: redirectUrl
    }

    try {

      // promisify
      await new Promise((resolve, reject) => {
        this.client.login(params, (err, result) => {
          if (err)
            return reject(err);
          resolve(result);
        });
      });
    } catch (err) {
      // rollback any storage which might have been set by `prepareRedirectData`
      return [null, this.unwrapLoginError(err as Auth0.Auth0Error)];
    }

    return [new AuthReply('WaitForRedirect')];
  }

  /**
   * Attempts to extract information from an Auth0Error which might be used to return specific kinds of errors which
   * can be used in validation or other specific scenarios.
   * Otherwise, a generic `ProviderError` type is returned, with the message set to the original error description.
   * 
   * ---
   * The following error codes are supported, according to the official docs:
   * https://auth0.com/docs/libraries/common-auth0-library-authentication-errors#log-in
   * ---
   * 
   * Auth0 error code               | Description
   * ---                            | ---
   * access_denied	                | When using web-based authentication, the resource server denies access per OAuth2 specifications
   * invalid_user_password	        | The username and/or password used for authentication are invalid
   * mfa_invalid_code	              | The multi-factor authentication (MFA) code provided by the user is invalid/expired
   * mfa_registration_required	    | The administrator has required multi-factor authentication, but the user has not enrolled
   * mfa_required	                  | The user must provide the multi-factor authentication code to authenticate
   * password_leaked	              | If the password has been leaked and a different one needs to be used
   * PasswordHistoryError	          | The password provided for sign up/update has already been used (reported when password history feature is enabled)
   * PasswordStrengthError	        | The password provided does not match the connection's strength requirements
   * too_many_attempts  	          | The account is blocked due to too many attempts to sign in
   * unauthorized	                  | The user you are attempting to sign in with is blocked
   */
  private unwrapLoginError(err: Auth0Error) {
    let errCode = err.code as Auth0LoginErrorType;
    let errType = ((): AuthErrorType | null => {
      switch (errCode) {
        case 'access_denied':
          return 'AccessDenied';
        case 'unauthorized':
          return 'NotAuthorized';

        case 'password_leaked':
          return 'PasswordLeaked';

        case 'too_many_attempts':
          return 'TooManyAttempts';

        case 'invalid_user_password':
          return 'WrongCredentials';
      }
      return null;
    })();

    // sometimes description might be an object in case of validation errors because... why not?
    const message = isNonEmptyString(err.description) ? err.description : null;
    if (errType) {
      return new AuthError(errType, message, {
        innerError: err
      });
    }
    return new AuthError('ProviderError', message, {
      innerError: err
    });
  }
  // #endregion

  // #region `register` adapter
  async signUp(input: RegisterInput): AsyncResult<Auth0SignUpResponseUnsafe, AuthError> {
    
    const params = this.getSignUpOptions(input);
    try {
      const result: Auth0SignUpResponseUnsafe = await new Promise((resolve, reject) => {
        this.client.signup(params, (err, result) => {
          if (err)
            return reject(err);
          resolve(result);
        });
      });
      return [result];

    } catch (err) {
      // console.error('Error in Auth0AuthProvider.register: ', err);
      return [null, this.unwrapSignUpError(err as Auth0.Auth0Error)];
    }
  }

  private getSignUpOptions(input: RegisterInput): Auth0SignUpOptionsUnsafe {

    assertValidRegisterInput(input);

    const options: Auth0SignUpOptionsUnsafe = {
      email: input.email,
      password: input.password,
      connection: AUTH0_CONNECTION!, // todo: add handling for null
      given_name: input.firstName,
      family_name: input.lastName,
      name: input.fullName,
    }

    return options;
  }

  /**
   * Attempts to extract information from an Auth0Error which might be used to return specific kinds of errors which
   * can be used in validation or other specific scenarios.
   * Otherwise, a generic `ProviderError` type is returned, with the message set to the original error description.
   * ---
   * The following error codes are supported, according to the official docs:
   * https://auth0.com/docs/libraries/common-auth0-library-authentication-errors#sign-up
   * ---
   * 
   * Auth0 error code               | Description
   * ---                            | ---
   * `invalid_password`	            | If the password used doesn't comply with the password policy for the connection
   * `invalid_signup`	              | The user your are attempting to sign up is invalid
   * `password_dictionary_error`	  | The chosen password is too common
   * `password_no_user_info_error`	| The chosen password is based on user information
   * `password_strength_error`	    | The chosen password is too weak
   * `unauthorized`	                | If you cannot sign up for this application. May have to do with the violation of a specific rule
   * `user_exists`	                | The user you are attempting to sign up has already signed up
   * `username_exists`	            | The username you are attempting to sign up with is already in use
   */
  private unwrapSignUpError(err: Auth0Error) {
    let errCode = err.code as Auth0SignUpErrorType;
    let errType = ((): AuthErrorType | null => {
      switch (errCode) {
        case 'invalid_signup':
          return 'InvalidRegister';
        case 'unauthorized':
          return 'NotAuthorized';

        // username errors
        case 'user_exists':
          return 'UserExists';
        case 'username_exists':
          return 'UsernameExists';

        // password errors
        case 'invalid_password':
          return 'InvalidPassword';
        case 'password_dictionary_error':
          return 'PasswordTooCommon';
        case 'password_strength_error':
          return 'PasswordTooWeak';
        case 'password_no_user_info_error':
          return 'PasswordContainsUserInfo';
      }
      return null;
    })();

    // sometimes description might be an object in case of validation errors because... why not?
    const message = isNonEmptyString(err.description) ? err.description : null;
    if (errType) {
      return new AuthError(errType, message, {
        innerError: err
      });
    }
    return new AuthError('ProviderError', message, {
      innerError: err
    });
  }
  // #endregion

  // #region `checkSession` adapter

  /**
   * Wrapper around `WebAuth.checkSession` which returns a Promise of an AuthPermit
   * or an AuthError otherwise.
   */
  async checkSession(): AsyncResult<AuthPermit> {
    // native call params
    const params: Auth0.CheckSessionOptions = {
      prompt: 'none'
    }

    let result: Auth0DecodedHash | null = null;
    try {
      // promisified call
      result = await new Promise((resolve, reject) => {
        this.client.checkSession(params, (err, result) => {
          if (err)
            return reject(err);
          resolve(result);
        });
      });

    } catch (rawErr) {
      const msg = (rawErr as any).error_description;
      const err = new AuthError('ProviderError', msg, {
        providerError: rawErr
      });
      // console.error('Error in Auth0AuthProvider.refreshSession: ', err);
      return [null, err];
    }

    const [permit, permitErr] = createAuthPermitFromHash(result);
    if (permitErr)
      return [null, new AuthError('InvalidPermit', 'Session was refreshed but the returned permit is invalid', { originalError: permitErr })];

    return [permit!, null];
  }
  // #endregion

  // #region `parseHash` adapter

  async parseHash(): AsyncResult<AuthPermit, AuthError> {

    let result: Auth0DecodedHash | null = null;
    try {
      // promisify
      result = await new Promise((resolve, reject) => {
        this.client.parseHash(async (err, result) => {
          if (err)
            return reject(err);
          resolve(result);
        });
      });

    } catch (rawErr) {
      const msg = (rawErr as any).error_description;
      const err = new AuthError('ProviderError', msg || 'Authentication failed because of an unknown provider error.', {
        providerError: rawErr
      });
      return [null, err];
    }

    const [permit, permitErr] = createAuthPermitFromHash(result);
    if (permitErr)
      return [null, new AuthError('InvalidPermit', 'Session was initialized but the returned permit is invalid', { originalError: permitErr })];

    return [permit!, null];
  }

  // #endregion

  // #region `authorize` adapter
  authorize(): Result<AuthWaitForRedirectReply, AuthError> {

    const { routingService } = this.store;
    const [redirPath, redirErr] = routingService.prepareRedirectUrl(
      Routes.oauthCallback());

    if (redirErr)
      return [null, redirErr];

    const redirectUrl = getAbsoluteUrl(redirPath!);

    this.client.authorize({
      prompt: 'none',
      redirectUri: redirectUrl
    });

    return [new AuthReply('WaitForRedirect')];
  }
  // #endregion


  // #region `authorize` adapter
  async logout(): AsyncResult<AuthWaitForRedirectReply, AuthError> {

    const { routingService } = this.store;
    const [redirPath, redirErr] = routingService.prepareRedirectUrl(
      Routes.oauthLogout());

    if (redirErr)
      return [null, redirErr];

    const redirectUrl = getAbsoluteUrl(redirPath!);

    this.client.logout({
      returnTo: redirectUrl
    });

    return [new AuthReply('WaitForRedirect')];
  }
  // #endregion


  // #region `changePassword` adapter
  /**
   * Calls `WebAuth.changePassword`, which sends an email to the user with a link to reset the password.
   * Should be used by both `ForgotPassword` flow in auth and `ChangePassword` flow in account management.
   * @param input
   */
  async changePassword(input: ResetPasswordInput): AsyncResult<ResetPasswordReply, AuthError> {

    assertValidChangePasswordInput(input);

    const params: Auth0.ChangePasswordOptions = {
      email: input.email,
      // @ts-ignore TODO: add behaviour for when connection is disabled
      connection: AUTH0_CONNECTION,
    }

    let result: Auth0ChangePasswordResponseUnsafe | null = null;
    try {
      // promisify
      result = await new Promise((resolve, reject) => {

        this.client.changePassword(params, (err, result?: Maybe<Auth0ChangePasswordResponseUnsafe>) => {
          if (err)
            return reject(err);
          resolve(result!);
        });
      });
    } catch (err) {
      // console.error('Error in Auth0AuthProvider.changePassword: ', err);
      return [null, this.unwrapChangePasswordError(err as Auth0.Auth0Error)];
    }

    return [new ResetPasswordReply({
      message: result,
      destination: input.email
    }), null];
  }

  private unwrapChangePasswordError(err: Auth0Error) {
    // forward the error message
    return new AuthError('ProviderError', err.description, {
      providerError: err
    });
  }
  // #endregion


  socialLogin(providerName: SocialLoginProvider) {

    const { routingService } = this.store;
    const [redirPath, redirErr] = routingService.prepareRedirectUrl(
      Routes.oauthCallback());

    if (redirErr)
      return [null, redirErr];

    const redirectUrl = getAbsoluteUrl(redirPath!);

    let conn: string | null = '';
    let enabled: boolean = true;
    
    switch (providerName.toLowerCase()) {
      case 'google':
        enabled = !!AUTH0_GOOGLE_CONNECTION_ENABLED;
        conn = AUTH0_GOOGLE_CONNECTION;
        break;

      case 'facebook':
        enabled = !!AUTH0_FACEBOOK_CONNECTION_ENABLED;
        conn = AUTH0_FACEBOOK_CONNECTION;
        break;

      case 'linkedin':
        enabled = !!AUTH0_LINKEDIN_CONNECTION_ENABLED;
        conn = AUTH0_LINKEDIN_CONNECTION;
        break;

      case 'twitter':
        enabled = !!AUTH0_TWITTER_CONNECTION_ENABLED;
        conn = AUTH0_TWITTER_CONNECTION;
        break;

      case 'microsoft':
        enabled = !!AUTH0_MICROSOFT_CONNECTION_ENABLED;
        conn = AUTH0_MICROSOFT_CONNECTION;
        break;

      case 'external:poly': {
        const config = Config.auth.connections['External:Poly'];
        enabled = config.enabled;
        conn = config.auth0.connection;
      } break;

      default:
        return [null, new AuthError('ConnectionNotSupported')];
    }

    if (!enabled)
      return [null, new AuthError('ConnectionNotClientEnabled')];

    if (!isNonEmptyString(conn))
      return [null, new AuthError('ConnectionNotProperlyConfigured')];

    this.client.authorize({
      connection: conn,
      redirectUri: redirectUrl
    });

    return [new AuthReply('WaitForRedirect')];
  }
}