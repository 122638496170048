export const ClipWindowTrainerInputs = {
  topic: ['name', 'sentiment', 'speaker', 'importance', 'keywords', 'description', 'language', 'track'],
  subtopic: ['name', 'sentiment', 'keywords', 'speaker', 'language', 'importance', 'track'],
  moment: ['momentType', 'sentiment', 'name', 'keywords', 'speaker', 'importance', 'description', 'language', 'track'],
  transcript: ['sentiment', 'keywords', 'speaker', 'importance', 'description', 'language', 'track'],
  paragraph: ['sentiment', 'keywords', 'speaker', 'importance', 'description', 'language', 'track'],
  actionitem: ['sentiment', 'keywords', 'speaker', 'importance', 'description', 'language', 'track'],
  // chapter: ['name', 'sentiment', 'keywords', 'speaker', 'importance', 'description', 'language', 'track']
}

export const ClipWindowUserInputs = {
  topic: ['name', 'sentiment', 'speaker', 'importance', 'keywords', 'description', 'language'],
  subtopic: ['name', 'sentiment', 'keywords', 'speaker', 'language', 'importance'],
  moment: ['momentType', 'sentiment', 'name', 'keywords', 'speaker', 'importance', 'description', 'language'],
  transcript: ['sentiment', 'keywords', 'speaker', 'importance', 'description', 'language'],
  actionitem: ['sentiment', 'keywords', 'speaker', 'importance', 'description', 'language'],
  // paragraph: ['sentiment', 'keywords', 'speaker', 'importance', 'description', 'language'],
  // chapter: ['name', 'sentiment', 'keywords', 'speaker', 'importance', 'description', 'language']
}