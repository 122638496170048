import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../../store/store';
import { Message, StoreNode } from '../../../store';
import { PlayerDirector, PlayerState } from '../../../components';
import { isFiniteNumber } from '../../../core';
import { PlayerAnalyzerMetrics, PlayerAnalyzerWatchRange } from './playerAnalyzerSchema';
import { IAnnotatedTimeRange } from '../../../core/time';
import { addTimeRanges } from '../../../core/time/timeRegionAdder';
import { JobModel, Moment } from '../../../entities';

type Props = {
  jobId?: string | null;
  player?: PlayerState | null;
}

export class PlayerAnalyzer
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.autoListen('player',
      this.playerListener);
  }

  // #region Props
  @computed({ keepAlive: true })
  get player(): PlayerState | null {
    return this.getProp('player') ?? null;
  }

  @computed
  get jobId(): string | null {
    return this.getProp('jobId') ?? null;
  }

  @computed
  get director(): PlayerDirector | null {
    return this.player?.momentDirector ?? null;
  }

  @computed
  get job(): JobModel | null {
    return this.store.jobManager.maybeGetJob(this.jobId) ?? null;
  }
  // #endregion

  /** 
   * Cached `Player.currentTime` used to know when to end the range
   * when a seek occurs, because `Player.currentTime` when `seeking` event is triggered 
   * is the time at which the player will seek to.
   * This is updated at each `timeupdate` player event.
   */
  @observable
  currentTime: number | null = null;

  @observable
  currentStartTime: number | null = null;

  @observable
  currentPlaybackRate: number | null = null;

  @observable
  manualTopicJumpCount = 0;

  @observable
  manualSubTopicJumpCount = 0;

  @observable
  autoTopicJumpCount = 0;

  @observable
  autoSubTopicJumpCount = 0;

  @observable
  seekCount = 0;

  readonly watchRanges = observable.array<PlayerAnalyzerWatchRange>([], { deep: false });

  @computed
  get addedWatchRanges(): IAnnotatedTimeRange[] {
    return addTimeRanges(this.watchRanges);
  }

  @computed
  get watchRatio() {
    const { player } = this;
    if (!player?.hasDuration)
      return 0;
    return this.watchDuration / player?.duration;
  }

  @computed
  get watchDuration() {
    return this.addedWatchRanges.reduce((acc, range) => acc + (range.endTime - range.startTime), 0);
  }

  @computed
  get totalWatchDuration() {
    const { player } = this;
    if (!player?.hasDuration)
      return 0;
    return this.watchRanges.reduce((acc, range) => acc + (range.endTime - range.startTime), 0);
  }


  @computed
  get metrics(): PlayerAnalyzerMetrics | null {
    const { player, job } = this;
    if (!player)
      return null;

    const {
      watchRanges,
      watchRatio,
      watchDuration,
      totalWatchDuration,
      seekCount,
      manualTopicJumpCount,
      manualSubTopicJumpCount,
      autoTopicJumpCount,
      autoSubTopicJumpCount
    } = this;

    const savedRatio = 1 - watchRatio;
    const videoDuration = job?.videoDuration ?? 0;
    const savedDuration = videoDuration > 0 ? (videoDuration - watchDuration) : 0;
    const rewatchRatio = Math.min(1, totalWatchDuration / watchDuration) ?? 1;

    return {
      watchRanges: [...watchRanges],
      watchRatio,
      savedRatio,
      watchDuration,
      savedDuration,
      totalWatchDuration,
      rewatchRatio,
      seekCount,
      manualTopicJumpCount,
      manualSubTopicJumpCount,
      autoTopicJumpCount,
      autoSubTopicJumpCount
    };
  }

  @action
  private playerListener = (msg: Message<PlayerState>) => {

    // parse the message to start and commit ranges
    const time = msg.sender.currentTime;
    switch (msg.type) {
      case 'playing':
        this.startRange();
        break;

      case 'pause':
      case 'stalled':
      case 'seeking':
      case 'ended':
        this.commitRange();
        break;

      case 'setPlaybackRate':
        this.startRange();
        break;
    }

    // parse the message to update individual counters
    switch (msg.type) {

      case 'jumpToMoment':
        this.handleJumpToMoment(msg);
        break;

      case 'seekEnd':
        this.seekCount++;
        break;

      case 'timeupdate':
        this.currentTime = time;
        break;
    }
  }

  @action
  private directorListener = (msg: Message<PlayerDirector>) => {

    // parse the message to update individual counters
    switch (msg.type) {
      case 'jumpToMoment':
        this.handleJumpToMoment(msg);
        break;
    }
  }

  private handleJumpToMoment = (msg: Message<PlayerDirector | PlayerState>) => {
    if (!msg || msg.type !== 'jumpToMoment')
      return;

    const { payload } = msg;
    const moment: Moment = payload?.moment;

    if (!moment)
      return;

    if (payload?.source === 'PlayerDirector') {
      switch (moment.clipType) {
        case 'Topic':
          this.autoTopicJumpCount++;
          break;

        case 'SubTopic':
          this.autoSubTopicJumpCount++;
          break;
      }
    } else {
      switch (moment.clipType) {
        case 'Topic':
          this.manualTopicJumpCount++;
          break;
        case 'SubTopic':
          this.manualSubTopicJumpCount++;
          break;
      }
    }
  }

  @action
  private startRange(): boolean {

    const { player } = this;
    const time = player?.currentTime;

    if (!isFiniteNumber(time))
      return false;

    if (isFiniteNumber(this.currentStartTime)) {
      this.commitRange();
      console.warn(`You called PlayerAnalyzer.startRange() while another range was still monitoring.`);
    }

    this.currentStartTime = time;
    this.currentPlaybackRate = player?.playbackRate ?? 1;

    return true;
  }

  @action
  private commitRange() {

    const startTime = this.currentStartTime;
    const playbackRate = this.currentPlaybackRate ?? 1;
    const endTime = this.currentTime;

    if (
      !isFiniteNumber(startTime) ||
      !isFiniteNumber(endTime))
      return;

    this.watchRanges.push({
      startTime,
      endTime,
      playbackRate
    });

    this.currentStartTime = null;
  }

  @action
  reset() {
    this.currentTime = null;
    this.currentStartTime = null;
    this.currentPlaybackRate = null;

    this.seekCount = 0;
    this.manualTopicJumpCount = 0;
    this.manualSubTopicJumpCount = 0;
    this.autoTopicJumpCount = 0;
    this.autoSubTopicJumpCount = 0;

    this.emit('Unmounted');
    this.watchRanges.clear();
  }
}