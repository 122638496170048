import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../../components/overlays';
import { Button } from '../../components/input';
import { RemoveAccessPopupState } from './removeAccessPopupState';

type Props = {
  model: RemoveAccessPopupState;
  visible?: boolean;
};

export const RemoveAccessPopup = observer(({ model, visible }: Props) => {
  return (
    <Window model={model.window} visible={visible} isLoading={model.isLoading}>
      <WindowHeader title="Delete" model={model.window} />

      <hr />

      <section className="window-content">
        <p className="p-bold">
          Are you sure you want to remove access for {model.targetName}?
        </p>
        <p>Doing so means this member will no longer be a part of your library.</p>
      </section>

      <hr />

      <footer className="window-footer">
        <div className="btn-group right">
          <Button
            label="Cancel"
            className="primary btn-outline medium"
            onClick={() => model.close()}
            disabled={model.isLoading}
          />

          <Button
            label="Remove"
            className="red medium"
            onClick={() => model.submit()}
            disabled={model.isLoading}
          />
        </div>
      </footer>
    </Window>
  );
});
