import './momentWindow.scss';
import '../overlays/overlays.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { PlusSVGIcon, ThrashSVGIcon } from '../svg';
import {
  Button,
  SliderInputControlled,
  TextInputControlled,
  CheckboxInputControlled,
  DropdownInputControlled,
} from '../input';

import { MultipleClipEditWindowState } from './multipleClipEditWindowState';
import { Window, WindowHeader } from '../overlays';

type Props = {
  visible: boolean;
  model: MultipleClipEditWindowState;
};

export const MultipleClipEditWindow = observer(
  ({
    visible,
    model
  }: Props) => {

    return (
      <Window
        className={"moment-window"}
        model={model.window}
        onExited={model.onTransitionEnd}
        visible={visible}
        isLoading={model.inputGroup.isSubmitting}>
        <WindowHeader
          model={model.window}
          title={`${model.editModelList?.length} moments selected`} />
        <hr />

        <form className="window-content window-form form">
          <MultipleClipEditFormContent model={model} />
        </form>

        <hr />

        <footer className="window-footer moment-buttons">
          <div className="col left">
            <div className="btn-group left gap2">
              <CheckboxInputControlled
                model={model.visibleToConsumer}
                label={'Show clip on consumer product'}
              />
              {false && (
                <Button
                  className="delete-btn transparent"
                  label="Delete"
                  leftIcon={<ThrashSVGIcon />}
                  disabled={model.inputGroup.isSubmitting}
                  onClick={model.delete}
                />
              )}
            </div>
            {model.trackModel && !model.trackModel?.visibleToConsumer && (
              <div className="notification-box">
                <div className="error-notification">
                  Swimlane hidden!
                </div>
              </div>
            )}
          </div>

          <div className="col right">
            <div className="btn-group">
              <Button
                label="Cancel"
                className="primary btn-outline medium form-cancel-btn"
                onClick={() => model.cancel()}
                disabled={model.inputGroup.isSubmitting} />

              <Button
                label="Save"
                className="primary medium form-submit-btn"
                onClick={() => model.submit()}
                disabled={
                  model.inputGroup.isSubmitDisabled ||
                  model.inputGroup.isSubmitting
                } />
            </div>
          </div>
        </footer>
      </Window>
    );
  }
);

type FormProps = {
  model: MultipleClipEditWindowState;
};

export const MultipleClipEditFormContent = observer(({ model }: FormProps) => {
  const importanceValue = model.importance?.value || 50;

  return (
    <>
      <div className="form-grid ">
        <DropdownInputControlled
          model={model.clipType}
          label="Clip type*"
          placeholder="Select clip type"
        />

        <DropdownInputControlled
          model={model.momentType}
          label="Moment type*"
          placeholder="Select moment type"
        />

        {model.clipType.value !== 'Transcript' && (
          <TextInputControlled
            model={model.name}
            label="Name"
            placeholder="Enter Name"
          />
        )}

        <DropdownInputControlled
          model={model.sentiment}
          label="Sentiment"
          placeholder="Select sentiment"
        />

        <div className="input-block-wrapper">
          <DropdownInputControlled
            model={model.speaker}
            placeholder="Select speaker"
            label={model.clipType.value === 'Transcript' ? 'Speaker*' : 'Speaker'}
            withSearch={true}
          />
          <Button
            leftIcon={<PlusSVGIcon />}
            onClick={() => model.openSpeakerWindow()} />
        </div>

        <TextInputControlled
          model={model.keywords}
          label="Keywords"
          placeholder="Enter keywords"
        />

        <DropdownInputControlled
          model={model.language}
          label="Language"
          placeholder="Select Default Language"
        />

        <div className="importance-slider">
          <div className="range-value">
            <span className="text" style={{ left: `${importanceValue}%` }}>
              {importanceValue}
            </span>
          </div>
          <SliderInputControlled model={model.importance} label="Importance" />
          <div className="range-labels">
            <span className="text">Least</span>
            <span className="text">Most</span>
          </div>
        </div>

        <div className="input-block-wrapper">
          <DropdownInputControlled
            model={model.track}
            placeholder="Select track"
            label={'Track*'}
            notDeselectable
            withSearch={true}
          />
          <Button
            leftIcon={<PlusSVGIcon />}
            onClick={() => model.openTrackWindow()} />
        </div>
      </div>

      <TextInputControlled
        model={model.description}
        label="Description"
        placeholder="Enter description"
      />
    </>
  );
});
