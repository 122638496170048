import difference from 'lodash/difference';
import { action, computed, makeObservable, observable } from 'mobx';
import { assert } from '../../core';

export type InvertedSelectorCoreData<TKey> = {
  unselectedKeys?: TKey[],
  keyPool?: TKey[]
}

/**
 * Same API as SelectorCore, but the storage of the data is inverted,
 * meaning that only unselected keys are stored, and by default keys that have not been
 * explicitly unselected are considered to be selected.
 */
export class InvertedSelectorCore<TKey> {

  /** Gets the set of currently selected keys. */
  readonly unselectedKeys = observable.set<TKey>([], { deep: false });

  /** 
   * Gets the set of all keys which can be selected. If this is empty, 
   * all checks against the key pool will not be performed (any key can be selected).
   */
  readonly keyPool = observable.set<TKey>([], { deep: false });

  /**
   * @alias unselectedKeys 
   */
  get keys(): Set<TKey> {
    return this.unselectedKeys;
  }

  /** Returns true if the key pool is not empty. */
  @computed get hasKeyPool() {
    return this.keyPool.size > 0;
  }

  /** 
   * Returns true if all the keys from the key pool have been selected. 
   * This returns false if no key pool has been specified. 
   */
  @computed get areAllSelected() {
    return this.unselectedKeys.size === 0;
  }

  @computed get isEmpty() {
    return (
      this.hasKeyPool &&
      difference([...this.keyPool], [...this.unselectedKeys]).length === 0);
  }

  /** Creates a new instance of SelectorCore. */
  constructor() {
    makeObservable(this);
  }

  /** Returns true if the key is selected. */
  isSelected(key: TKey) {
    return !this.unselectedKeys.has(key);
  }

  /** 
   * Adds the key to the selection set. 
   * If a key pool has been specified, the selected key must be part of the key pool, otherwise an error is thrown.
   */
  @action
  select(key: TKey): this {
    if (this.hasKeyPool) {
      assert(this.keyPool.has(key), `Cannot select key '${key}' because it does not appear in the selector's key pool.`);
    }
    this.unselectedKeys.delete(key);
    return this;
  }
  @action
  selectMany(keys: Iterable<TKey>): this {
    for (const key of keys)
      this.select(key);
    return this;
  }


  /** 
   * Removes the key from the selection set.
   * If the key wasn't selected, the action will be ignored.
   */
  @action
  unselect(key: TKey): this {
    this.unselectedKeys.add(key);
    return this;
  }
  @action
  unselectMany(keys: Iterable<TKey>): this {
    for (const key of keys)
      this.unselect(key);
    return this;
  }


  @action
  remove(key: TKey): this {
    this.unselectedKeys.delete(key);
    return this;
  }

  @action
  removeMany(keys: Iterable<TKey>): this {
    for (const key of keys)
      this.remove(key);
    return this;
  }



  /** 
   * Selects the key if it hasn't been selected already, and unselects it otherwise.
   */
  @action
  toggle(key: TKey): this {
    if (this.isSelected(key))
      this.unselect(key);
    else
      this.select(key);
    return this;
  }

  /** Clears the current selection state. */
  @action
  clear(): this {
    this.unselectedKeys.clear();
    return this;
  }

  @action
  selectAll(): this {
    if (!this.hasKeyPool)
      return this;

    this.keyPool.forEach(key =>
      this.select(key));
    return this;
  }

  /** 
   * Sets a new key pool for the current selector.
   * All keys that have been already selected must be part of the new key pool, otherwise an error will be thrown.
   */
  @action
  setKeyPool(keys: Iterable<TKey>) {
    this.keyPool.replace([...keys]);
    if (!this.hasKeyPool)
      return this;

    // must validate that the current selection does not contain values that are not in the key pool
    // only done if the key pool is set
    for (let key of this.unselectedKeys) {
      assert(this.keyPool.has(key), `Cannot set new key pool because the current selection contains key ${key} which is not in the new key pool.`);
    }

    return this;
  }

  /** Clears all the keys from the key pool. */
  @action
  clearKeyPool() {
    this.keyPool.clear();
    return this;
  }

  @action
  reset() {
    this.clearKeyPool();
    this.clear();
  }

  export(): InvertedSelectorCoreData<TKey> {
    return {
      unselectedKeys: [...this.unselectedKeys],
      keyPool: [...this.keyPool]
    }
  }

  @action
  import(data?: InvertedSelectorCoreData<TKey>): this {
    this.reset();

    if (data?.keyPool)
      this.setKeyPool(data.keyPool);
    if (data?.unselectedKeys)
      this.unselectMany(data.unselectedKeys);
    return this;
  }
}