import moment from 'moment';
import { RangeOptions } from '../../components/input/dateInput/dateInput';

export type AnalyticsWidgetRouteParams = {
  teamId?: string
}

export const AnalyticsWidgetDateRangePresets: RangeOptions[] = [{
  name: 'Today',
  getRange: () => {
    return [moment(), moment()];
  }
},
{
  name: 'Yesterday',
  getRange: () => {
    return [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
  }
},
{
  name: 'Last Week',
  getRange: () => {
    return [moment().subtract(7, 'days'), moment()];
  }
},
{
  name: 'Last 30 Days',
  getRange: () => {
    return [moment().subtract(31, 'days'), moment()];
  }
},
{
  name: 'This Month',
  getRange: () => {
    return [moment().startOf('month'), moment().endOf('month')]
  }
}];