import '../input.scss';
import '../inputs-v1-1.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { FileInputProps } from './fileInputSchema';

export const UploadButton = observer(
  ({ className, disabled, label, layoutSize, onChange }: FileInputProps) => {
    className = classNames('btn btn-core primary btn-outline text-btn file-input-btn-upload', //className
    layoutSize, 
    {
      disabled: disabled
    });

    return (
      <button className={className}>
        <input type="file"
          className="widget-upload-file-input"
          disabled={disabled}
          onChange={evt => onChange?.(evt)} />
        <div className="text">{label}</div>
      </button>)
  });