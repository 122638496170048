import './momentCloud.scss';

import { action, computed, makeObservable, observable } from 'mobx';
import clamp from 'lodash/clamp';
import * as d3 from 'd3';
// @ts-ignore
import { bboxCollide } from 'd3-bboxCollide';
import { Store } from '../../store/store';
import { BindingProps, Message, refProxy, StoreNode } from '../../store';
import { JobModel, MomentSelector } from '../../entities';
import { Error } from '../../core/error';
import { CloudGraph } from './cloudGraph';
import { getEventOffset, isNonEmptyString, rect, Rect, size2, Size2, Vec2, vec2 } from '../../core';
import { CloudNode } from './cloudNode';
import { CloudEdge } from './cloudEdge';
import { hash } from '../../core/hash/hash';
import { aspectRatio, scaleRect } from '../../core/math';

export type MomentCloudMode =
  'Topics' |
  'Speakers' |
  'Companies';

type Props = BindingProps<{
  jobId: string,
  selector: MomentSelector
}>;

export class MomentCloudState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.graph = new CloudGraph(store, {
      jobId: () => this.jobId,
      selector: () => this.selector
    });
    this.graph.listen(this.graphListener);

    this.onFieldChange('graphStateHash',
      this.handleGraphStateHashChange)

    this.onFieldChange('graphItems',
      this.handleGraphItemsChange);
  }

  @observable isLoading: boolean = true;
  @observable error: Error | null = null;

  @computed get jobId(): string | null {
    return this.getResolvedProp('jobId') || null;
  }

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed get selector(): MomentSelector | null {
    return this.getResolvedProp('selector') || null;
  }

  readonly graph: CloudGraph;

  @observable renderPhase:
    'Idle' |
    'Compute' |
    'Ready' = 'Idle';

  @computed get mode(): MomentCloudMode | null {
    return this.selector?.mode || null;
  }

  @computed get graphItems(): (CloudNode | CloudEdge)[] {
    return [
      ...this.graph.nodes,
      ...this.graph.edges
    ];
  }

  @computed get graphStateHash(): string {
    const stateData = this.graph.nodes
      .filter(node => node.modelType !== 'SubTopic')
      .map(node => [
        node.key,
        +node.isSelected,
        +node.isExpanded,
        +node.isAccented,
        +node.isVisible
      ])
      .join('');

    return hash(stateData);
  }

  readonly nodeLayoutCache = new Map<string, Map<string, Vec2>>();
  readonly viewportRefProxy = refProxy(this, 'viewport');

  @observable isPanning = false;

  @observable simulation: d3.Simulation<CloudNode, CloudEdge> | null = null;
  @observable viewportSize: Size2 | null = null;
  @observable sceneRect: Rect | null = null;

  readonly refProxy = refProxy(this, 'root');
  readonly svgRefProxy = refProxy<SVGElement>(this, 'svg');

  @observable isMounted = false;

  @observable zoom = 1;
  @observable pan: Vec2 = vec2(0, 0);

  @computed get disableSceneTransitions() {
    return this.isPanning;
  }

  @computed get showEmptyMessage(): boolean {
    return isNonEmptyString(this.emptyMessage);
  }
  @computed get emptyMessage(): string | null {
    if (this.graph.visibleNodes.length > 0)
      return null;

    switch (this.mode) {
      case 'Topics':
        return `There are no available topics for this video.`;
      case 'Speakers':
        return `There are no available speakers for this video.`;
    }
    return null;
  }


  @action
  async mounted() {
    this.reset();
    this.isMounted = true;
  }

  @action
  unmounted() {
    this.reset();
    this.isMounted = false;
  }

  private handleGraphStateHashChange = () => {
    this.graph.requestUpdate();
  }

  private handleGraphItemsChange = () => {
    this.reset();
    this.update();
  }

  private graphListener = (msg: Message<CloudGraph>) => {
    switch (msg.type) {
      case 'update':
        this.update();
        this.fitContents();
    }
  }

  @action
  selectAll() {
    this.graph.selectAll();
    this.update();
  }

  @action
  unselectAll() {
    this.graph.unselectAll();
    this.update();
  }

  @action
  private initSimulation() {

    if (!this.isMounted)
      return;
      
    // heavy duty safeguard
    if (this.simulation)
      this.destroySimulation();

    const networkCenter = d3.forceCenter()
      .x(0)
      .y(0)
      .strength(1)

    const forceX = d3
      .forceX(100)
      .strength(0.8)

    const forceY = d3
      .forceY(100)
      .strength(1)

    const collide = bboxCollide((d: CloudNode) => {
      let { width, height } = d;
      switch (d.modelType) {
        case 'Speaker':
          width *= 1.4;
          height *= 1.4;
          break;

        default:
          width *= 1.1;
          height *= 1.1;
          break;
      }

      return [[-width / 2, -height / 2], [width / 2, height / 2]];
    })
      .strength(1)
      .iterations(2);

    this.simulation = d3.forceSimulation<CloudNode>()
      .velocityDecay(0.6)
      .force('center', networkCenter)
      .force('x', forceX)
      .force('y', forceY)
      .force('charge', d3.forceManyBody().strength(5000).distanceMax(1))
      .force('collideBox', collide)
      .force('radial', d3.forceRadial((d: CloudNode) => aspectRatio(d.width, d.height) < 1.5 ? 100 : 500)
        .x(0)
        .y(0)
        .strength((d: CloudNode) => aspectRatio(d.width, d.height) < 1.5 ? 1 : 0))
  }

  @action
  private destroySimulation() {
    if (!this.simulation)
      return;

    this.simulation
      .stop()
      .force('link', d3.forceLink([]))
      .nodes([]);
    this.simulation = null;
  }

  @action
  update() {
    if (!this.isMounted)
      return;

    if (!this.simulation)
      this.initSimulation();

    const sim = this.simulation;
    const graph = this.graph;

    if (!sim || !graph || !graph.allNodesHaveSizeInfo)
      return;

    const edges = graph.visibleEdges;
    const nodes = graph.visibleNodes;

    nodes.forEach(node => {
      node.fx = undefined;
      node.fy = undefined;
    });

    const lastSelNode = graph.lastSelectedNode;
    if (lastSelNode) {
      lastSelNode.fx = lastSelNode.x;
      lastSelNode.fy = lastSelNode.y;
    }

    // check the cache for an existing layout
    const hash = this.graphStateHash;
    const cache = this.nodeLayoutCache;

    let fromCache = false;
    let useCache = graph.allNodesHaveSizeInfo;

    if (useCache && cache.has(this.graphStateHash)) {
      const stateData = cache.get(hash)
      let valid = true;

      nodes.forEach(node => {
        const nodeData = stateData?.get(node.id);
        if (!nodeData) {
          valid = false;
          return false;
        }

        node.x = nodeData.x;
        node.y = nodeData.y;
      });

      fromCache = valid;
    }

    if (!fromCache) {
      sim
        .stop()
        .alpha(1)
        .alphaTarget(0)
        .nodes(nodes)
        .force('link', d3.forceLink(edges)
          .strength(d => d.strength)
          .distance(d => d.distance)
          .iterations(2))
        .restart()
        .tick(500);

      // populate the cache with the new data
      const stateData = new Map<string, Vec2>();
      nodes.forEach(node => {
        stateData.set(node.id, vec2(node.x || 0, node.y || 0));
      });

      cache.set(hash, stateData);
    }

    nodes.forEach(node => node.sync());
  }

  private computeViewportSize() {
    const contentRect = this.refProxy.current?.getBoundingClientRect();
    this.viewportSize = size2(contentRect?.width || 0, contentRect?.height || 0);
  }

  @action
  private computeSceneRect() {

    const { visibleNodes } = this.graph;
    let x1 = Number.POSITIVE_INFINITY;
    let y1 = Number.POSITIVE_INFINITY;
    let x2 = Number.NEGATIVE_INFINITY;
    let y2 = Number.NEGATIVE_INFINITY;

    visibleNodes.forEach(node => {
      if (node.x1 < x1) x1 = node.x1;
      if (node.y1 < y1) y1 = node.y1;
      if (node.x2 > x2) x2 = node.x2;
      if (node.y2 > y2) y2 = node.y2;
    });

    this.sceneRect = rect(
      x1,
      y1,
      x2 - x1,
      y2 - y1);
  }

  @action
  private setZoom(val: number) {
    this.computeSceneRect();
    this.computeViewportSize();

    this.zoom = clamp(val, 0.4, 4);
    this.setPan(this.pan);
  }

  @action
  private setPan(val: Vec2) {
    this.computeSceneRect();
    this.computeViewportSize();

    const size = this.viewportSize;
    const rect = this.sceneRect;
    const zoom = this.zoom;

    if (!size || !rect || !zoom)
      return;

    const zoomRect = scaleRect(rect, zoom);

    const x1b = -size.width / 2 - zoomRect.x1;
    const y1b = -size.height / 2 - zoomRect.y1;

    const x2b = size.width / 2 - zoomRect.x2;
    const y2b = size.height / 2 - zoomRect.y2;

    const x = x2b < x1b ?
      clamp(val.x, x2b, x1b) :
      ((x1b + x2b) / 2);

    const y = y2b < y1b ?
      clamp(val.y, y2b, y1b) :
      ((y1b + y2b) / 2);

    this.pan = vec2(x, y);
  }

  @action
  zoomIn() {
    this.setZoom(Math.round(this.zoom * 5) / 5 + 0.2);
  }

  @action
  zoomOut() {
    this.setZoom(Math.round(this.zoom * 5) / 5 - 0.2);
  }

  @action
  fitContents() {
    this.computeSceneRect();
    this.computeViewportSize();

    const size = this.viewportSize;
    const rect = this.sceneRect;
    const zoom = this.zoom;

    if (!size || !rect || !zoom)
      return;

    const newZoom = clamp(Math.min(
      size.width / rect.width,
      size.height / rect.height), 0, 1);

    const newPan = vec2(
      -rect.cx * newZoom,
      -rect.cy * newZoom);

    this.setZoom(newZoom);
    this.setPan(newPan);
  }

  @action
  handleViewportWheel = action((evt: React.WheelEvent) => {
    if (evt.deltaY < 0)
      this.zoomIn();
    else
      this.zoomOut();
  })

  @action
  handleViewportPointerDown = action((evt: React.PointerEvent) => {

    const viewport = this.viewportRefProxy.current;
    if (!viewport)
      return;

    const offset = getEventOffset(evt, viewport);

    const panStartX = offset.x;
    const panStartY = offset.y;

    const panCurrX = this.pan.x;
    const panCurrY = this.pan.y;

    let canceled = false;
    const cancel = action(() => {
      this.isPanning = false;

      document.removeEventListener('pointermove', handleRootPointerMove);
      document.removeEventListener('pointerup', handleRootPointerUp);
      document.removeEventListener('pointerleave', handleRootPointerLeave);

      canceled = true;
    })

    const apply = (evt: PointerEvent) => {

      const offset = getEventOffset(evt, viewport);
      const dx = offset.x - panStartX;
      const dy = offset.y - panStartY;

      // do not prevent clicks from shaky hands
      if (Math.abs(dx) > 10 && Math.abs(dy) > 10) {
        evt.stopPropagation();
        evt.preventDefault();
      }


      this.setPan(
        vec2(panCurrX + dx, panCurrY + dy));
    }

    const handleRootPointerMove = action((evt: PointerEvent) => {
      if (canceled || !viewport)
        return;
      this.isPanning = true;
      apply(evt);
    })

    const handleRootPointerUp = action((evt: PointerEvent) => {
      cancel();
      if (!viewport)
        return;
      apply(evt);
    })

    const handleRootPointerLeave = (evt: PointerEvent) => {
      cancel();
    }

    document.addEventListener('pointermove', handleRootPointerMove);
    document.addEventListener('pointerup', handleRootPointerUp);
    document.addEventListener('pointerleave', handleRootPointerLeave);
  })

  @action
  reset() {
    this.destroySimulation();
    this.nodeLayoutCache.clear();
  }
}