import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { SpeakerModel, SpeakerProps } from './speaker';
import { StoreNode } from '../store/storeNode';
import { Store } from '../store/store';
import { assert, AsyncResult, Maybe } from '../core';
import { ApiVariables, ApiQueryOptions } from '../api/apiSchema';

const speakerPictureSize = {
  width: 100,
  height: 100
}

// export function isSpeaker(arg: any): arg is SpeakerModel {
//   return (
//     arg instanceof SpeakerModel &&
//     arg.nodeType === 'Speaker');
// }

// export function assertIsSpeaker(arg: any): asserts arg is SpeakerModel {
//   assert(isSpeaker(arg),
//     `Expected ${arg} to be instance of SpeakerModel.`);
// }

const speakerSorter = (a: SpeakerModel, b: SpeakerModel) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase());

export class SpeakerManager
  extends StoreNode {

  readonly nodeType: 'SpeakerManager' = 'SpeakerManager';

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  // collections
  readonly speakerLookup = observable.map<string, SpeakerModel>();

  @computed
  get speakers(): SpeakerModel[] {
    return [...this.speakerLookup.values()];
  }

  @computed
  get publicSpeakers(): SpeakerModel[] {
    let speakerItems = [...this.speakerLookup.values()];
    return speakerItems
      .filter(i => i.isPublic)
      .sort(speakerSorter)
  }

  @computed
  private get api() {
    return this.store.api;
  }

  // #region Speakers
  // -------
  private assertHasSpeaker(id: string) {
    assert(this.hasSpeaker(id),
      `Store does not contain a Speaker with id '${id}'.`);
  }

  hasSpeaker(id: string): boolean {
    return this.speakerLookup.has(id);
  }

  getSpeaker(id: string): SpeakerModel {
    this.assertHasSpeaker(id);
    return this.speakerLookup.get(id)!;
  }
  maybeGetSpeaker(id: Maybe<string>): SpeakerModel | null {
    if (!id)
      return null;
    return this.speakerLookup.get(id) || null;
  }

  getJobSpeakers(jobId: string) {
    return this.speakers.filter(speaker =>
      speaker.scope === 'public' ||
      speaker.scope === 'JOB#' + jobId);
  }

  getJobPrivateSpeakers(jobId: string) {
    return this.speakers.filter(speaker =>
      speaker.scope === 'JOB#' + jobId);
  }

  @action
  insertSpeaker(speakerData: SpeakerProps) {
    const speaker = new SpeakerModel(speakerData, this.store);
    this.speakerLookup.set(speaker.id, speaker);
    return speaker;
  }

  async apiFetchSpeakers(args: ApiVariables<'getSpeakers'>, opts?: ApiQueryOptions)
    : AsyncResult<boolean> {

    const [speakersRes, err] = await this.api.getSpeakers(args, opts);
    if (err)
      return [null, err];

    runInAction(() => {
      const items = speakersRes?.getSpeakers || [];
      items.length > 0 && items.forEach(item => {
        this.insertSpeaker(item!);
      });
    });

    return [true];
  }

  async apiBatchFetchSpeakers(args: ApiVariables<'batchGetSpeakers'>, opts?: ApiQueryOptions)
    : AsyncResult<boolean> {

    if (!args.pictureSize)
      Object.assign(args, {
        pictureSize: [speakerPictureSize]
      })

    const [speakersRes, err] = await this.api.batchGetSpeakers(args, opts);
    if (err)
      return [null, err];

    runInAction(() => {
      const items = speakersRes?.batchGetSpeakers || [];
      items.length > 0 && items.forEach(item => {
        this.insertSpeaker(item!);
      });
    });

    return [true];
  }

  async apiFetchSpeaker(args: ApiVariables<'getSpeaker'>, opts?: ApiQueryOptions)
    : AsyncResult<boolean> {

    if (!args.pictureSize)
      Object.assign(args, {
        pictureSize: [speakerPictureSize]
      })

    const [speakersRes, err] = await this.api.getSpeaker(args, opts);
    if (err)
      return [null, err];

    const item = speakersRes?.getSpeaker || null;

    this.insertSpeaker(item!);

    return [true];
  }

  async apiAddSpeaker(args: ApiVariables<'addSpeaker'>)
    : AsyncResult<SpeakerModel> {

    const [speakerData, err] = await this.api.addSpeaker(args);
    if (err)
      return [null, err];

    const speaker = this.insertSpeaker(speakerData?.addSpeaker!);

    return [speaker];
  }

  async apiUpdateSpeaker(args: ApiVariables<'updateSpeaker'>)
    : AsyncResult<SpeakerModel> {

    const [speakerData, err] = await this.api.updateSpeaker(args);
    if (err)
      return [null, err];

    const speaker = this.insertSpeaker(speakerData?.updateSpeaker!);

    return [speaker];
  }
  // #endregion
}