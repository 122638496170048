import pull from 'lodash/pull';
import { Store } from './store';
import { createMessage, Message, MessageHandler, MessageHandlerWaitMode, MessageMode } from './message';
import { StoreNode } from './storeNode';

/** 
 * Provides functionalities for communicating with the node and storing information about it
 * without exposing node's internals or requiring objects to actually implement special behaviour.
 */
export class StoreNodeProxy<TNode extends StoreNode = StoreNode> {

  node: TNode;
  store: Store | null;

  listeners: MessageHandler<TNode>[] = [];

  receiver: MessageHandler | null = null;
  receiveFilter: string[] = [];

  constructor(node: TNode, store?: Store | null) {
    this.node = node;
    this.store = store || null;
    if (store)
      store.register(this);
  }

  private createMessage(mode: MessageMode, type: string, payload?: any): Message<TNode> {
    return createMessage(this.node, mode, type, payload);
  }

  async emit(type: string, payload?: any, waitMode: MessageHandlerWaitMode = MessageHandlerWaitMode.None): Promise<void> {
    const msg = this.createMessage('emit', type, payload);

    if (!this.store) {
      console.warn('NO STORE');

      // TODO: remove
      const listeners = this.listeners;
      listeners.forEach(listener =>
        listener(msg));
      return;
    }

    await this.store.nodeEmit(msg, waitMode);
  }

  broadcast(type: string, payload?: any): boolean {
    if (!this.store)
      return false;

    const msg = this.createMessage('broadcast', type, payload);
    this.store.nodeBroadcast(msg);
    return true;
  }

  dispatch(target: string | null, type: string, payload?: any) {
    if (!this.store)
      return false;

    const msg = this.createMessage('dispatch', type, payload);
    msg.target = target;
    this.store.nodeDispatch(msg);
    return true;
  }

  listen(listener: MessageHandler<TNode>): boolean {
    if (this.listeners.includes(listener)) {
      console.warn(`Listener is already registered.`);
      return false;
    }

    this.listeners.push(listener);
    return true;
  }

  unlisten(listener: MessageHandler<TNode>): boolean {
    pull(this.listeners, listener);
    return true;
  }

  /** Registers the node's own message handler for messages that are broadcast from other nodes. */
  receive(receiver: MessageHandler, filter: string[] = []) {
    this.receiver = receiver;
    this.receiveFilter = filter;
  }
}