import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link as RouterLink } from 'react-router-dom';
import { LinkProps } from 'react-router-dom';
import { Maybe } from '../../core';

type Props = LinkProps & {
  enabled?: boolean,
  to?: Maybe<LinkProps['to']>
}

/** Wrapper around react router Link which makes it usable in real life scenarios. */
export const Link = observer(({
  enabled = true,
  children,
  to,
  component,
  replace,
  ...props
}: Props) => {

  if (!to)
    enabled = false;

  if (enabled) {
    return (
      <RouterLink
        to={to}
        component={component}
        replace={replace}
        {...props}>

        {children}
      </RouterLink>
    )
  }

  return (
    <div {...props as any}>
      {children}
    </div>
  );
});