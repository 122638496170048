import './uploadWidget.scss';

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { UploadWidgetState } from './uploadWidgetState';
import { LoadMask } from '../../components';
import { UserAvatar } from '../../components/layout/userAvatar';
import { UploadIntegrationControlsBox } from '../../pages/uploadPage/uploadIntegrationControlsBox';
import { PageChunks } from '../../components/layout/pageChunks';
import { UploadFileBox } from '../../pages/uploadPage/uploadFileBox';
import { UploadLinkBox } from '../../pages/uploadPage/uploadLinkBox';
import { UploadEmailText } from '../../pages/uploadPage/uploadEmailBox';
import { UploadFileProgressBox } from '../../pages/uploadPage/uploadFileProgressBox';
import { UploadFileStageBox } from '../../pages/uploadPage/uploadFileStageBox';
import { UploadLinkStageBox } from '../../pages/uploadPage/uploadLinkStageBox';
import { UploadLinkSuccessBox } from '../../pages/uploadPage/uploadLinkSuccessBox';
import { useRouteContext } from '../../routes/routeHooks';

import { TRACE } from '../../core/debug/debugMacros';

type Props = {
  model: UploadWidgetState;
}

export const UploadWidget = observer(({
  model
}: Props) => {

  TRACE(model, `render()`);

  const routeContext = useRouteContext();
  useEffect(() => {
    model.mounted(routeContext);
    return () =>
      model.unmounted();
  }, [model, routeContext]);

  if (model.isLoading) {
    return (
      <div className="screen-content">
        <LoadMask className="partial" spinner="dots5" visible={true} />
      </div>
    );
  }

  return (
    <div className="upload-widget widget">
      <div className="widget-content">
        <UploadComponent model={model} />
      </div>

      <PageChunks />
    </div>
  );
});

const UploadComponent = observer(({ model }: Props) => {
  const { team, uploadManager } = model;
  const {
    fileComponent,
    linkComponent,
    showStaging,
    maxStagingItemsNumber,
    isReplace,
    stageRef,
  } = uploadManager;

  const className = classNames('upload-boxes vflex', {
    'show-staging': showStaging,
  });

  const style = {
    '--max-staging-item-count': maxStagingItemsNumber,
    '--link-staging-item-count': Math.min(
      linkComponent.stagedItems.length,
      maxStagingItemsNumber
    ),
    '--file-staging-item-count': Math.min(
      fileComponent.stagedItems.length,
      maxStagingItemsNumber
    ),
  } as any;

  const submitDisabled =
    isReplace && (fileComponent.replaceLocked || linkComponent.replaceLocked);

  return (
    <div className="splash-page-content page-content vflex hcenter vcenter">
      <div className={className} id="upload-page-frameset">
        <div className="left-frameset">
          <div className="user-box">
            <div className="user-info">
              <UserAvatar className="gray" />
              <span className="username"> {model.store.user?.displayName}</span>
            </div>

            {team && (
              <div className="team-info">
                <span className="team-label uppercase">Uploading in</span>
                <span>{team?.name}</span>
              </div>
            )}
          </div>

          <UploadFileBox state={fileComponent} />
          <UploadLinkBox state={linkComponent} />

          <UploadIntegrationControlsBox />
          <UploadEmailText />
        </div>

        <div className="right-frameset" ref={stageRef.ref} style={style}>
          <TransitionGroup component={null}>
            {fileComponent.teamGroupedTasks.map((item) => (
              <CSSTransition
                in={true}
                appear={true}
                timeout={300}
                key={item.groupId}>
                <UploadFileProgressBox
                  key={item.groupId}
                  model={item}
                  state={fileComponent} />
              </CSSTransition>
            ))}
          </TransitionGroup>

          <CSSTransition
            timeout={300}
            className="staging-box-show"
            in={fileComponent.showStagingForm}>
            <UploadFileStageBox
              state={fileComponent}
              submitDisabled={submitDisabled} />
          </CSSTransition>

          <CSSTransition
            timeout={300}
            className="staging-box-show"
            in={linkComponent.showStagingForm}>
            <UploadLinkStageBox
              state={linkComponent}
              submitDisabled={submitDisabled} />
          </CSSTransition>

          <CSSTransition
            timeout={500}
            className="staging-box-show"
            appear={true}
            unmountOnExit
            in={linkComponent.showProgressBox}>
            <UploadLinkSuccessBox />
          </CSSTransition>
        </div>
      </div>
    </div>
  );
});

export default UploadWidget;