/**
 * Hacky, debug only function to get the name of the currently executing function.
 */
export function _debugGetCalleeName(level: number = 0): string | null {
  // inspired from https://stackoverflow.com/a/39337724/478593
  const err = new Error('DebugGetCalleeNameFakeError');
  const stack = err.stack;
  if (!stack)
    return null;

  return stack.split('\n')[2 + level]
    // " at functionName ( ..." => "functionName"
    .replace(/^\s+at\s+(.+?)\s.+/g, '$1');
}