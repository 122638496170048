import './input.scss';
import './inputs-v1-1.scss';

import assert from 'assert';
import { paramCase } from 'change-case';
import React, { ReactNode, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { InputState } from './inputState';
import { HTMLInputProps } from '../../core/reactSchema';
import { InputProps } from './inputSchema';
import { Button } from '.';
// import { ObjectLiteral } from '../../core';
import {
  TextInputWarningSVGIcon,
  TextInputSuccessSVGIcon,
  TextInputErrorSVGIcon,
  VisibleOnSVGIcon,
  VisibleOffSVGIcon,
} from '../svg';
import { CSSTransition } from 'react-transition-group';

// @ts-ignore
import CSSVariables from './input.scss';
import { InfoButton } from '../infoModal';

type Props = Omit<HTMLInputProps, 'value' | 'placeholder'> &
  InputProps<string> & {
    multiline?: boolean;
    rows?: number;
    charCountMin?: number;
    charCountMax?: number;
    showCharacterCount?: boolean;
    infoModalTitle?: string;
    infoModalContent?: string | ReactNode;
    infoModalClassName?: string;
  };

export const TextInput = observer(
  ({
    id,
    multiline,
    value,
    className,
    label,
    disabled,
    isFocused,
    isHovered,
    isRequired,
    showStatus,
    showErrorIcon = true,
    showWarningIcon = true,
    showSuccessIcon = true,
    status,
    statusMessage: feedback,
    showLabel = true,
    persistentLabel = false,
    labelPosition = 'topStart',
    showFeedback = true,
    persistentFeedback = true,
    feedbackPosition = 'bottom',
    placeholder,
    type = 'text',
    readOnly = false,
    rows = 2,
    children,
    charCountMin = 0,
    charCountMax = Infinity,
    showCharacterCount = false,
    infoModalContent,
    infoModalTitle,
    infoModalClassName,
    ...props
  }: Props) => {
    className = classNames('text-input input', className, {
      multiline: multiline,
      focus: isFocused,
      hover: isHovered,
    });

    const fadeTimeout = {
      enter: parseInt(CSSVariables.feedbackEnterTimeout),
      exit: parseInt(CSSVariables.feedbackExitTimeout),
    };

    placeholder = (!disabled && placeholder) || null;

    const charCount = value?.length || 0;

    const charCountClass = classNames('count', {
      'green-count': charCount >= charCountMin && charCount <= charCountMax,
      'red-count': charCount < charCountMin || charCount > charCountMax
    });

    const characterCounter = showCharacterCount ? <div className="character-count left-inner-bottom-inner">
      <span className={charCountClass}>
        {charCount}
      </span>
      /{charCountMax}ch.
    </div> : null;

    let inputElem;
    const inputElemClassNames = classNames('native-input native-text-input', {
      'read-only': readOnly,
    });

    if (multiline) {
      inputElem = (
        // @ts-ignore
        <textarea
          id={id}
          data-focus-id={id}
          role="presentation"
          className={inputElemClassNames}
          rows={rows}
          value={value || ''}
          disabled={disabled}
          placeholder={placeholder || ''}
          {...props}
        />
      );
    } else {
      inputElem = (
        <input
          id={id}
          type={type}
          data-focus-id={id}
          role="presentation"
          className={inputElemClassNames}
          value={value || ''}
          disabled={disabled}
          placeholder={placeholder || ''}
          readOnly={readOnly}
          {...props}
        />
      );
    }

    let labelElem = (persistentLabel || (showLabel && label)) && (
      <label
        className={['input-label', paramCase(labelPosition!)].join(' ')}
        id={id && id + '_label'}
        htmlFor={id}>
        {showLabel && label}

        {infoModalContent &&
          <InfoButton
            title={infoModalTitle}
            content={infoModalContent}
            windowClassName={infoModalClassName} />
        }
      </label>
    );

    let feedbackElem = (
      <CSSTransition
        in={!!(showFeedback && feedback)}
        classNames="feedback-fade"
        unmountOnExit={!persistentFeedback}
        timeout={fadeTimeout}>
        <div
          className={[
            'feedback input-feedback',
            paramCase(feedbackPosition!),
          ].join(' ')}
          id={id && id + '_feedback'}
          data-status={status}
          aria-live="polite">
          {showFeedback && feedback}
        </div>
      </CSSTransition>
    );

    return (
      <div className={className} role="textbox" aria-disabled={disabled}>
        <div className="text-input-box input-box"></div>

        {inputElem}
        {labelElem}
        {feedbackElem}
        {characterCounter}
        {children}
      </div>
    );
  }
);

type ControlledProps = Props & {
  model: InputState;
};

/**
 * Wrapper for TextInput in which the entire state and all the handlers are provided through an InputState object.
 */
export const TextInputControlled = observer(
  ({ model, className, children, type = 'text', ...props }: ControlledProps) => {
    assert(
      model instanceof InputState,
      `The 'model' passed to TextInputControlled must be an instance of InputState. Instead received ${model}.`
    );

    const handleChange = useCallback(
      (evt) => {
        model.handleChange(evt, evt.target.value);
      },
      [model]
    );
    const [inputType, setInputType] = useState(type);

    const toggleContentVisibility = useCallback(() => {
      if (inputType === 'password') {
        setInputType('text');
      } else {
        setInputType('password');
      }
    }, [inputType]);

    const getPasswordIcon = () => {
      if (!model.showContentVisibilityButton) return null;

      return (
        <Button
          icon={
            inputType === 'password' ? (
              <VisibleOnSVGIcon />
            ) : (
              <VisibleOffSVGIcon />
            )
          }
          onClick={() => toggleContentVisibility()}
        />
      );
    };

    const getStatusIcon = () => {
      if (!model.showStatusIcons) return null;

      let statusIcon = null;

      switch (model.status) {
        case 'error':
          statusIcon = <TextInputErrorSVGIcon />;
          break;
        case 'warning':
          statusIcon = <TextInputWarningSVGIcon />;
          break;
        case 'success':
          statusIcon = <TextInputSuccessSVGIcon />;
          break;
      }

      return statusIcon;
    };

    const getRightIcons = () => {
      if ((!model.showStatusIcons || !getStatusIcon()) && !model.showContentVisibilityButton) return null;

      return (
        <div className="right-gutter flex-right status-icon rpad4">
          {getStatusIcon()}
          {getPasswordIcon()}
        </div>
      );
    };

    className = model.getClassName('text-input', { className });

    return (
      <TextInput
        className={className}
        value={model.value || ''}
        placeholder={model.placeholder || undefined}
        disabled={model.disabled}
        label={model.label!}
        multiline={model.multiline}
        isHovered={model.isHovered}
        isFocused={model.isFocused}
        isRequired={model.isRequired}
        onChange={handleChange}
        onFocus={model.handleFocus}
        onBlur={model.handleBlur}
        onPointerEnter={model.handlePointerEnter}
        onPointerLeave={model.handlePointerLeave}
        status={model.status}
        statusMessage={model.statusMessage}
        showStatus={model.showStatus}
        showFeedback={model.showStatusMessage}
        labelPosition={model.labelPosition || undefined}
        feedbackPosition={model.feedbackPosition || undefined}
        charCountMax={model.charCountMax || undefined}
        type={inputType}
        {...props}>
        {children}
        {getRightIcons()}
      </TextInput>
    );
  }
);
