import { action, makeObservable, observable, runInAction } from 'mobx';
import { Maybe } from '../../core';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';

export class RemoveJobFromCliprWindowState
  extends StoreNode {

  readonly nodeType = 'RemoveJobFromCliprWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);

  @observable isLoading: boolean = false;

  jobId: Maybe<string>;
  teamId: Maybe<string>;

  @action
  async submit() {
    this.isLoading = true;

    const [, err] = await this.store.apiService.archiveJob({ id: this.jobId || '' });

    if (err) {
      notifyError(this, 'Could not remove the video.');
      return this.handleSubmitFinally();
    }

    this.store.bookmarkManager.bookmarkListLookup.clear();
    await this.store.bookmarkManager.apiFetchBookmarkLists();

    runInAction(() => {
      notifySuccess(this, 'Video removed from CLIPr.');

      // TODO: remove hard dependencies to other pages
      this.teamId ?
        this.store.teamDashboardPage.mounted({ teamId: this.teamId }) :
        this.store.userDashboardPage.mounted({});

      this.handleSubmitFinally();
      this.emit('JobRemoved')
    });
  }

  handleSubmitFinally() {
    runInAction(() => {
      this.isLoading = false;
      this.close();
    })
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open({ jobId, teamId }: { jobId: string, teamId?: string }) {
    this.dispatch('Overlays', 'openWindow', { name: 'RemoveJobFromCliprWindow' });

    this.jobId = jobId;
    this.teamId = teamId;
  }

  @action
  close() {
    this.jobId = null;

    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}