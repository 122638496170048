import React, { ReactEventHandler, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { HTMLInputProps } from '../../core';
import { InputProps } from '../input/inputSchema';
import { SearchSVGIcon } from '../../pages/userSplashPage/userSplashSvg';
import { Button } from '../input/button';
import { ChatGptLogoSVGIcon, CloseSVGIcon } from '../svg';
import { InputState, TextInput, TextInputControlled } from '../input';
import classNames from 'classnames';
import { ChevronLeftSVGIcon, ChevronRightSVGIcon } from '../player/components/playerSvg';
import { useOutsideClick } from '../hooks';
import { CSSTransition } from 'react-transition-group';
import { PlayerSearchMode } from '../playerTranscripts';
import { useStore } from '../../store/storeHooks';
import { PlayerSearchBarSuggestions } from './playerSearchBarSuggestions';

type Props =
  Omit<HTMLInputProps, 'value' | 'placeholder'> &
  InputProps<string> & {
    onClear?: ReactEventHandler,
    className?: string
  }

export const PlayerSearchBar = observer(({
  onClear,
  className,
  ...props
}: Props) => {
  className = classNames('user-player-search-bar', className);
  return (
    <TextInput
      persistentFeedback={false}
      className={className}
      {...props}>

      <div className="left-gutter flex-center lpad4">

        {props.value || props.isFocused ?
          <Button className="dark disc tiny clear-search-btn"
            icon={<CloseSVGIcon />}
            onClick={onClear} /> :
          <SearchSVGIcon />}
      </div>
    </TextInput>
  );
});

type ControlledProps =
  Props & {
    model: InputState,
    resultIndex?: number,
    numberOfResults?: number,
    playerSearchMode?: PlayerSearchMode,
    isCliprGptEnabled?: boolean,
    isAiProcessing?: boolean;
    navigateFwd?: () => void,
    navigateBack?: () => void,
    onChatGptMode?: (chatGptSearchKey: string) => void,
    onTranscriptMode?: () => void
  };

export const PlayerSearchBarControlled = observer(({
  model,
  className,
  resultIndex,
  numberOfResults,
  playerSearchMode,
  isCliprGptEnabled,
  persistentFeedback = false,
  isAiProcessing,
  onClear,
  navigateFwd,
  navigateBack,
  onChatGptMode,
  onTranscriptMode,
  ...props
}: ControlledProps) => {
  // TODO: Refactor this as some point and define an internal state
  const isInitializedMode = isCliprGptEnabled && playerSearchMode === PlayerSearchMode.Initialized;
  const isTranscriptMode = !isCliprGptEnabled || playerSearchMode === PlayerSearchMode.Transcript;
  const isAskMode = isCliprGptEnabled && playerSearchMode === PlayerSearchMode.Ask;

  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownElement, setDropdownElement] = useState(null);

  const store = useStore();
  const isWidgetMode = store.widgetService.isWidgetMode;
  const showSuggestions = model.value && showDropdown;
  const showIcon = props.value || model.value || model.isFocused;

  useEffect(() => {
    setShowDropdown(!!(model.value && isCliprGptEnabled && isInitializedMode));
  }, [model.value, isCliprGptEnabled, isInitializedMode]);


  const handleOnAskClick = (evt: React.MouseEvent, value?: string) => {
    evt.stopPropagation();
    onChatGptMode?.(value ?? model.value!);
    setShowDropdown(false);
  }

  const handleOnSearchClick = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    onTranscriptMode?.();
    setShowDropdown(false);
  }

  const handleOnFocus = (evt: React.FocusEvent) => {
    evt.stopPropagation();
    setShowDropdown(!!(model.value && isCliprGptEnabled && isInitializedMode));
  }

  const onKeyDown = (evt: React.KeyboardEvent) => {
    evt.key === 'Enter' && isTranscriptMode && navigateFwd && navigateFwd();
   
    if (isAskMode && model.value && evt.key === 'Enter') {
      onChatGptMode?.(model.value);
      // Workaround to hide the keyboard on mobile
      (evt.target as any).blur?.();
    }
  }

  useOutsideClick(dropdownElement, () => setShowDropdown(false), document.getElementById('search-in-transcript'));
  className = classNames('user-player-search-bar', className, {
    'transcript-mode': isTranscriptMode,
    'widget-mode': isWidgetMode
  });

  return (
    <>
      <TextInputControlled
        id="search-in-transcript"
        data-tutorial-id="search-in-transcript"
        model={model}
        className={className}
        persistentFeedback={persistentFeedback}
        onFocus={handleOnFocus}
        aria-disabled={isAiProcessing && isAskMode}
        onKeyDown={onKeyDown}
        {...props}>
        {showSuggestions && 
          <PlayerSearchBarSuggestions
            model={model}
            isAiProcessing={isAiProcessing ?? false}
            isWidgetMode={isWidgetMode}
            setDropdownElement={setDropdownElement}
            handleOnAskClick={handleOnAskClick}
            handleOnSearchClick={handleOnSearchClick}
          />}
        <div
          id="search-icon"
          data-tutorial-id="search-icon"
          className="left-gutter flex-center lpad4">
          <div className="icons">
            <>
              <CSSTransition
                in={!!(showIcon || (isTranscriptMode && isCliprGptEnabled))}
                classNames="icon-fade"
                unmountOnExit={true}
                timeout={300}>
                <button className="clear-search-btn"
                  onClick={onClear}>
                  <CloseSVGIcon />
                </button>
              </CSSTransition>
              <CSSTransition
                in={!(showIcon)}
                classNames="icon-fade"
                unmountOnExit={true}
                timeout={300}>
                <div className="search-icon-box">
                  {isCliprGptEnabled ? <ChatGptLogoSVGIcon /> : <SearchSVGIcon />}
                </div>
              </CSSTransition>
            </>
          </div>
        </div>
        {(model.value || props.value) && isTranscriptMode &&
          <div className="right-gutter flex-center lpad3 rpad3">
            {<div className="search-index-box">{`${numberOfResults ? resultIndex : 0}/${numberOfResults}`}</div>}
            <div className="search-navigation-box">
              <button className={`search-btn navigate-back ${resultIndex === 1 ? 'disabled' : ''}`}
                onClick={() => navigateBack && navigateBack()}
                disabled={resultIndex === 1}>
                <span className="icon"><ChevronLeftSVGIcon /></span>
              </button>
              <button className={`search-btn navigate-fwd ${resultIndex === numberOfResults ? 'disabled' : ''}`}
                onClick={() => navigateFwd && navigateFwd()}
                disabled={resultIndex === numberOfResults}>
                <span className="icon"><ChevronRightSVGIcon /></span>
              </button>
            </div>
          </div>}
      </TextInputControlled>
    </>
  );
});

