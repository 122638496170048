import * as uuid from 'uuid';
import sleep from 'sleep-promise';
import { DocumentNode } from 'graphql';
import { TimeTracker } from '../core/time/timeTracker';
import { WSSubscription } from './apiWSSubscription';

const WS_LOGS = false;
const WS_KA_TIMEOUT = 120000;

export type WSAuth = {
  Authorization?: string;
  'x-api-key'?: string;
  host: string;
}

type WSClientProps = {
  id?: string;
  token?: string | null,
  key: string,
  endpoint: string,
  host: string,
  onConnectionError?: () => void,
  onConnectionOpened?: () => void,
  onStart?: () => void,
  onData?: (data: any) => void,
  onError?: () => void,
  onComplete?: () => void,
}

export class WSClient {
  // one ws instance represents a connection that can handle multiple subscriptions
  private ws: WebSocket | null = null;
  private connectionOpened: boolean = false;
  private connectionError: string | null = null;
  private connectionTimeoutMs: number = 0;
  private maxRetryCount: number = 5;
  private retryCount: number = 0;
  private subscriptions: WSSubscription[] = [];
  private cachedSubscriptions: WSSubscription[] = []; // this represents the list of subscriptions that were active when at connection failure

  private readonly id: string;
  private readonly authorization: WSAuth | null;
  private readonly endpoint: string;
  private readonly realTimeEndpoint: string;
  private readonly token?: string | null;
  private readonly onConnectionError?: () => void;
  private readonly onConnectionOpened?: () => void;
  private readonly onStart?: () => void;
  private readonly onData?: (data: any) => void;
  private readonly onError?: () => void;
  private readonly onComplete?: () => void;

  private readonly tracker = new TimeTracker({
    intervalInMs: 1000,
    onIncrement: () => this.handleTrackerIncrement()
  })

  constructor(props: WSClientProps) {
    this.id = props.id || uuid.v4();
    this.endpoint = props.endpoint;
    this.token = props.token;

    this.realTimeEndpoint = this.endpoint.replace('https', 'wss');

    this.authorization = Object.assign({
      host: props.host.replace('https://', '').replace('/graphql', ''),
    }, props.token ? {
      Authorization: props.token,
    } : {
      'x-api-key': props.key
    })

    this.onConnectionError = props.onConnectionError;
    this.onConnectionOpened = props.onConnectionOpened;
    this.onStart = props.onStart;
    this.onData = props.onData;
    this.onError = props.onError;
    this.onComplete = props.onComplete;
  }

  // Public region
  public async subscribe(subscribeDocument: DocumentNode, vars: any): Promise<string> {

    if (!this.ws || this.connectionError)
      return 'Error';

    const sub = new WSSubscription({
      document: subscribeDocument,
      context: this.ws,
      vars: vars,
      auth: this.authorization
    });
    this.subscriptions.push(sub);
    sub.start();

    await this.waitForSubscription(sub.id);
    return sub.id;
  }

  public async open() {
    // when there is a connection that is still not closed it should be closed or wait until it is closed 
    await this.closeConnection();
    this.reset();
    await this.connect();
  }

  public async close() {
    await this.unsubscribeAll();
    await this.closeConnection();
    this.reset();
  }

  // unsubscribe all subscriptions
  public async unsubscribeAll() {
    if (this.ws && this.ws.readyState !== WebSocket.CLOSING && this.ws.readyState !== WebSocket.CLOSED) {
      this.subscriptions.forEach(sub => sub.stop());
    }
    await this.waitForUnsubscription();
  }

  public async connect() {
    const ws = new WebSocket(this.createWsEndpoint(), 'graphql-ws');

    ws.addEventListener('open', this.openListener);
    ws.addEventListener('message', this.messageListener);

    this.ws = ws;
    await this.waitForConnection();
  }

  // Private Region
  private async reset(atRetry = false) {
    this.connectionOpened = false;
    this.connectionError = '';
    this.connectionTimeoutMs = 0;
    this.ws = null;
    this.subscriptions = [];
    if (!atRetry) {
      this.clearCachedSubscriptions();
      this.resetRetryCount();
    }
  }

  private cacheSubscriptions() {
    this.cachedSubscriptions = this.subscriptions.filter(sub => sub.isActive);
  }

  private resubscribeCache() {
    this.cachedSubscriptions.forEach(sub => {
      const clone = sub.clone(this.ws!);
      clone.start();
      this.subscriptions.push(clone);
    });

    this.clearCachedSubscriptions();
  }

  private clearCachedSubscriptions() {
    this.cachedSubscriptions = [];
  }

  private async closeConnection() {
    if (this.ws && this.ws.readyState !== WebSocket.CLOSING && this.ws.readyState !== WebSocket.CLOSED) {
      this.tracker.stop();
      this.ws.removeEventListener('open', this.openListener);
      this.ws.removeEventListener('message', this.messageListener);
      this.ws.close();
    }

    if (this.ws && this.ws.readyState !== WebSocket.CLOSED)
      await this.waitForClosedConnection();
  }

  private getSubscriptionById(id: string | null): WSSubscription | null {
    return this.subscriptions.find(o => o.id === id) || null;
  }

  private incrementRetryCount() {
    this.retryCount += 1;
  }

  private resetRetryCount() {
    this.retryCount = 0;
  }

  private async retryConnect() {
    if (this.retryCount > this.maxRetryCount) {
      await this.unsubscribeAll();
      await this.closeConnection();
      return;
    }

    //init retry
    this.cacheSubscriptions();
    this.incrementRetryCount();

    // make sure there are no leftovers
    await this.unsubscribeAll();
    await this.closeConnection();

    this.reset(true);
    await this.connect();
  }

  private openListener = (ev: any) => {
    WS_LOGS && console.log('WS connection open', ev);
    this.ws?.send(JSON.stringify({ type: 'connection_init' }));
  }

  private messageListener = (ev: any) => {
    const body = JSON.parse(ev.data as string);
    WS_LOGS && console.log('ws message', body);
    if (!body)
      return;

    switch (body.type) {
      case 'connection_error':
        this.handleConnectionError(body);
        break;
      case 'connection_ack':
        this.handleConnectionSuccess(body);
        break;
      case 'ka':
        this.handleConnectionKeepAlive(body);
        break;
      case 'start_ack':
        this.handleSubscriptionStart(body);
        break;
      case 'data':
        this.handleSubscriptionData(body);
        break;
      case 'complete':
        this.handleSubscriptionComplete(body);
        break;
      case 'error':
        this.handleSubscriptionError(body);
        break;
    }
  }

  private getErrorMessage(body: any): string {
    return body.payload.errors[0].message || body.payload.errors[0].errorType || 'Server error';
  }

  private handleConnectionError(body: any) {
    this.connectionError = this.getErrorMessage(body);
    this.onConnectionError?.();
    this.retryConnect();
  }

  private handleConnectionSuccess(body: any) {
    // const payload = body?.payload;
    this.connectionOpened = true;
    this.connectionTimeoutMs = WS_KA_TIMEOUT; //payload?.connectionTimeoutMs ||
    this.tracker.start();
    this.resetRetryCount();
    this.resubscribeCache();
    this.onConnectionOpened?.();
  }

  private handleConnectionKeepAlive(body: any) {
    WS_LOGS && console.log('ka reset');
    this.tracker.restart();
  }

  private handleSubscriptionStart(body: any) {
    WS_LOGS && console.log('subscription start', body);
    const sub = this.getSubscriptionById(body?.id || null);
    sub?.setActive();
    this.onStart?.();
  }

  private handleSubscriptionData(body: any) {
    const sub = this.getSubscriptionById(body?.id || null);
    sub?.updateHistory(body.payload.data);
    this.onData?.(body.payload.data);
  }

  private handleSubscriptionComplete(body: any) {
    const sub = this.getSubscriptionById(body?.id || null);
    sub?.setCompleted();
    this.onComplete?.();
  }

  private handleSubscriptionError(body: any) {
    const sub = this.getSubscriptionById(body?.id || null);
    sub?.setError(body?.payload?.errors[0]);
    this.onError?.();
  }

  private handleTrackerIncrement() {
    WS_LOGS &&  // a log when no timeout but connection is closed
      (this.tracker.timeInMs <= this.connectionTimeoutMs) &&
      (this.ws?.readyState === WebSocket.CLOSED || this.ws?.readyState === WebSocket.CLOSING) &&
      console.warn('connection closed - no timeout');

    if (this.tracker.timeInMs > this.connectionTimeoutMs) {
      WS_LOGS && console.error('connection keep alive timeout');
      this.tracker.stop();
      this.handleConnectionTimeout();
    }
  }

  private async handleConnectionTimeout() {
    // await this.unsubscribeAll();
    // await this.closeConnection();
    await this.retryConnect();
  }

  private createWsEndpoint(): string {
    // const encodedHeader = Buffer.from(JSON.stringify(this.authorization)).toString('base64');
    // const encodedPayload = Buffer.from(JSON.stringify({})).toString('base64');
    // return `${this.realTimeEndpoint}?header=${encodedHeader}&payload=${encodedPayload}`;
    return this.realTimeEndpoint;
  }

  private async waitForConnection() {
    await this.waitForCondition(() => this.connectionError !== '' || this.connectionOpened, 'Connection open timeout');
    if (!this.connectionError && !this.connectionOpened) {
      this.connectionError = 'Connection timeout';
      WS_LOGS && console.error('WS connection error:', this.connectionError);
    }
  }

  private async waitForSubscription(subscriptionId: string) {
    const sub = this.getSubscriptionById(subscriptionId);
    await this.waitForCondition(() => !this.getSubscriptionById(subscriptionId)?.isStarted, 'Subscription registration timeout');
    if (sub?.isError) {
      WS_LOGS && console.error('WS Subscription:', sub?.errorMessage);
    }
  }

  private async waitForUnsubscription() {
    await this.waitForCondition(() => this.subscriptions.filter(sub => sub.isActive).length === 0, 'Subscriptions still active');
  }

  private async waitForClosedConnection() {
    await this.waitForCondition(() => this.ws?.readyState === WebSocket.CLOSED, 'Connection close timeout');
    if (this.ws?.readyState !== WebSocket.CLOSED) {
      WS_LOGS && console.error('Connection could not be closed');
    }
    if (this.ws?.readyState === WebSocket.CLOSED) {
      WS_LOGS && console.log('Connection closed');
    }
  }

  private async waitForCondition(fn: () => boolean, message: string = '', timeout = 5000, sleepTime = 100) {
    let waitTime = 0;
    while (!fn() && waitTime < timeout) {
      await sleep(sleepTime);
      waitTime += sleepTime;
    }

    if (waitTime >= timeout) {
      WS_LOGS && console.error('WS Timeout:', message || 'Condition timeout');
    }
  }
}