import { action, computed, makeObservable, observable } from 'mobx';
import { ChangeEvent, KeyboardEvent, MouseEvent, PointerEvent } from 'react';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { IPlayerAdapter } from './playerSchema';
import clamp from 'lodash/clamp';

type Props = BindingProps<{
  adapter: IPlayerAdapter
}>

/**
 * Handles events occuring on the volume button and slider and exposes properties
 * for creating the appropriate UX for the button.
 */
export class PlayerVolumeButtonState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  @computed
  get adapter(): IPlayerAdapter | null {
    return this.getResolvedProp('adapter');
  }

  @observable volumeBeforeMute: number | null = null;

  @computed get volume() {
    return this.adapter?.volume ?? 0;
  }
  @computed get isMuted() {
    return this.volume === 0;
  }

  @observable volumeBeforeDrag: number | null = null;
  @observable isDragging = false;

  @observable volumeBeforeKey: number | null = null;
  @observable isKeying = false;

  handleKeyDown = action((evt: KeyboardEvent) => {
    if (this.isKeying)
      // keydown is fired continuously while the key is being pressed
      // if the key is already pressed, ignore the event
      return;

    // notify the state that a key is being held and store the current volume
    this.volumeBeforeKey = this.volume;
    this.isKeying = true;
  })

  handleKeyUp = action((evt: KeyboardEvent) => {
    this.isKeying = false;
    if (this.isMuted && this.volumeBeforeKey !== 0)
      this.volumeBeforeMute = this.volumeBeforeKey;
    this.volumeBeforeKey = null;
  })

  handleSliderPointerDown = action((evt: PointerEvent) => {
    if (this.isDragging)
      // for multi-pointer scenarios ignore the second pointer
      return;

    this.volumeBeforeDrag = this.volume;
    this.isDragging = true;
  })

  handleSliderPointerUp = action((evt: PointerEvent) => {
    this.isDragging = false;
    if (this.isMuted && this.volumeBeforeDrag !== 0)
      this.volumeBeforeMute = this.volumeBeforeDrag;
    this.volumeBeforeDrag = null;
  })

  handleSliderChange = action((evt: ChangeEvent<HTMLInputElement>) => {
    const volume = clamp(parseFloat(evt.target.value), 0, 1) ?? 0;
    if (!this.isDragging) {

    }

    this.adapter?.invoke('setVolume', { volume });
  })

  handleButtonClick = action((evt: MouseEvent) => {
    if (this.isMuted)
      this.unmute();
    else
      this.mute();
  })

  @action
  mute() {
    this.volumeBeforeMute = this.volume;
    this.adapter?.invoke('setVolume', { volume: 0 });
  }

  @action
  unmute() {
    this.adapter?.invoke('setVolume', { volume: this.volumeBeforeMute || 0.5 });
    this.volumeBeforeMute = null;
  }

  @action
  toggleMute() {

    if (this.isMuted || this.volume === 0)
      this.unmute();
    else
      this.mute();
  }
}