import './overlays.scss';
// @ts-ignore
import CSSVariables from './overlays.scss';

import React, { CSSProperties, PropsWithChildren, useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { WindowState } from './windowState';
import { EnterHandler, ExitHandler } from 'react-transition-group/Transition';
import { LoadMask } from '../loader';

export type WindowProps = PropsWithChildren<{
  /** Internal name for identifying the window */
  name?: string;

  /**
   * If true, the window will become automatically visible when the `UIService.activeWindowName` property
   * equals the `name` of the window, only if the `visible` property is not false.
   * @default true
   */
  visibleWhenActive?: boolean;

  title?: string;
  className?: string;
  visible?: boolean;
  isLoading?: boolean;

  /**
   * If true, the window will send a `closeWindow` command to the UIService when the user taps outside of the window area.
   * @default true
   */
  closeOnOutsideClick?: boolean;

  closeOnEscKey?: boolean;
  onExited?: ExitHandler<undefined | HTMLElement>;
  onEntered?: EnterHandler<undefined | HTMLElement>;

  showHeader?: boolean;
  footerButtons?: React.ReactNode;
  innerRef?: any;
  style?: CSSProperties;

  model: WindowState;
}>;

const fadeTimeout = {
  enter: parseInt(CSSVariables.windowEnterTimeout),
  exit: parseInt(CSSVariables.windowExitTimeout),
};

export const Window = observer(
  ({ model, onEntered, onExited, innerRef, style, closeOnOutsideClick, ...props }: WindowProps) => {
    const className = classNames(
      'window modal-window',
      { 'window-loading': props.isLoading },
      props.className
    );

    useEffect(() => {
      model.handleMounted();
      return () => model.handleUnmounted();
    }, [model]);

    return (
      <TransitionGroup data-window-name={props.name}>
        {props.visible && (
          <CSSTransition classNames="window-fade" timeout={fadeTimeout}>
            <div className="window-mask" />
          </CSSTransition>
        )}

        {props.visible && (
          <CSSTransition
            classNames="window-fade-slide"
            onExited={onExited}
            onEntered={onEntered}
            timeout={fadeTimeout}>
            <div
              style={style}
              ref={innerRef}
              className="window-container">
              <div className="window-outer">
                <div className={className} ref={model.refProxy.ref}>
                  {props.children}

                  <LoadMask
                    visible={props.isLoading}
                    className="window-load small"
                    spinner="dots5"
                    spinnerClassName="medium"
                  />
                </div>
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    );
  }
);
