import { action, makeObservable, observable } from 'mobx';
import { ReactNode } from 'react';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../../components/overlays';

export type InfoModalProps = {
  title?: string,
  content?: ReactNode,
  windowClassName?: string
}

export class InfoModalState
  extends StoreNode {

  readonly nodeType: 'InfoModal' = 'InfoModal';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
        this.close();
        break;
      case 'outsideClick':
        this.emit('close');
        this.close();
        break;
    }
  }
  readonly window = new WindowState(this.store);
  @observable title: string | null = null;
  @observable content: ReactNode | null = null;
  @observable windowClassName: string | null = null;

  @action
  open({ title, content, windowClassName }: InfoModalProps) {
    this.dispatch('Overlays', 'openWindow', { name: 'InfoModal' });
    this.emit('open');

    this.title = title || 'HELP';
    this.content = content;
    this.windowClassName = windowClassName ?? null;
  }

  @action
  close() {
    this.dispatch('Overlays', 'closeWindow');

    this.title = null;
    this.content = null;
    this.windowClassName = null;
  }
}