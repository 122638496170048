export type StorageValue = string | number | boolean | object;

export type CookieSameSite = 
  'Strict' | 
  'Lax' | 
  'None';

export type Cookie = {
  name: string,
  value: string,
  path?: string,
  domain?: string,
  secure?: boolean,
  sameSite?: CookieSameSite
}

export enum StorageType {
  LocalStorage = 'LocalStorage',
  SessionStorage = 'SessionStorage',
  Cookie = 'Cookie'
}