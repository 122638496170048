

import React from 'react';
import { observer } from 'mobx-react-lite';
import { SocialLoginProvider } from '../../../services/auth';
import {
  FacebookSVGIcon,
  GoogleSVGIcon,
  LinkedInSVGIcon,
  MicrosoftSVGIcon,
  TwitterSVGIcon
} from '../../../components';

type Props = {
  provider: SocialLoginProvider
}

export const SocialLoginIcon = observer(({
  provider
}: Props) => {

  switch (provider) {
    case 'Google':
      return <GoogleSVGIcon />

    case 'Facebook':
      return <FacebookSVGIcon />

    case 'LinkedIn':
      return <LinkedInSVGIcon />

    case 'Twitter':
      return <TwitterSVGIcon />

    case 'Microsoft':
      return <MicrosoftSVGIcon />
  }

  return null;
});