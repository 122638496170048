import { TeamMemberRole } from '@clipr/lib';
import { computed, makeObservable } from 'mobx';
import { StoreNode } from '../store';
import { Store } from '../store/store';
import { TeamInvitation } from './teamInvitation';

type Props = {
  teamId: string,
  email: string,
  role: TeamMemberRole,
  cursor: string,
  index: number
}

export class TeamInviteCatalogItem
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
    Object.assign(this, props);
  }

  readonly email!: string;
  readonly teamId!: string;
  readonly role!: TeamMemberRole;
  readonly cursor!: string;
  readonly index!: number;

  @computed get team() {
    return this.store.teamManager.strictGetTeam(this.teamId);
  }

  @computed get teamInvitation() {
    return this.team?.invitations.find((value: TeamInvitation) => value.email === this.email);
  }
}