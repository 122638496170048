import { action, computed, makeObservable } from 'mobx';
import { MouseEvent } from 'react';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { OverlayState } from '../overlays/overlayState';
import { PlayerChromeState } from './playerChromeState';

type Props = BindingProps<{
  chrome: PlayerChromeState
}>

export class PlayerReactionButtonState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  @computed
  get chrome(): PlayerChromeState | null {
    return this.getResolvedProp('chrome');
  }

  readonly panelState = new OverlayState(this.store, {
    visibility: 'OnClick',
    visibilityTrigger: () => (this.chrome?.visibility !== 'Visible' ? false : undefined)
  });

  handleButtonClick = action((evt: MouseEvent) => {

  })
}