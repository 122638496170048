import { action, computed, makeObservable, observable } from 'mobx';
import { Nullable } from '../../core';
import { Store } from '../../store/store';
import { BindingProps, Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { DemoService } from '../../services/demo';
import {
  Placement,
  PlayerTutorialSelectors,
} from './playerTutorial.consts';

export type PlayerTutorialWindowProps = {
  pageNumber?: number;
  totalPages?: number;
  title?: Nullable<string>;
  description?: Nullable<string>;
  anchorElement?: Nullable<Element>;
  placement?: Placement;
  content?: Nullable<string>;
  offset?: [number, number];
}

export type TutorialCustomClass = {
  class: string;
  elementId: string;
  value: boolean;
}

export type PlayerTutorialStep = PlayerTutorialWindowProps & {
  showComponentForTutorial?: () => void;
  id: string | string[];
  anchor: string;
  customClass?: TutorialCustomClass | TutorialCustomClass[];
  customAction?: () => void;
}

type Props = BindingProps<{
  highlightComponentForTutorial?: (component: string) => void;
}>

export class PlayerTutorialWindowState
  extends StoreNode {

  readonly nodeType = 'PlayerTutorialWindow';

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store, { closeOnOutsideClick: false });
  readonly demoService: DemoService = new DemoService(Object.values(PlayerTutorialSelectors));
  readonly userPlayerPageState = this.store.userPlayerPage;
  readonly playerWidget = this.store.playerWidget;

  @computed get isWidgetMode(): boolean {
    return this.store.widgetService.getIsWidgetMode();
  }
  @observable currentPage: number = 1;
  @observable totalPages: number = 10;
  @observable description: Nullable<string> = null;
  @observable content: Nullable<string> = null;
  @observable offset: [number, number] = [0, 0];
  @observable placement: Placement = Placement.TopStart;
  @observable anchorElement: Nullable<Element> = null;
  @observable steps: PlayerTutorialStep[] = [];
  @observable currentStep: PlayerTutorialStep | null = null;

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open(payload: any) {
    this.emit('open');
    this.steps = payload.steps;

    const pageNumber = payload.pageNumber;
    const params: PlayerTutorialWindowProps = this.getParamsByPageAndExecuteActions(payload.steps, pageNumber ? pageNumber : 1);
    this.setPage(params);

    this.dispatch('Overlays', 'openWindow', { name: 'PlayerTutorialWindow' });
  }

  @action
  close() {
    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');

    this.demoService.highlightComponents([]);
    // Remove custom classes from the highlighted components when exiting the tour
    this.steps.forEach((step: PlayerTutorialStep) => {
      const { customClass } = step;
      if (customClass) {
        if (!Array.isArray(customClass)) {
          this.demoService.toggleCustomClass(customClass?.elementId, customClass.class, false);
        } else {
          for (const item of customClass) {
            this.demoService.toggleCustomClass(item.elementId, item.class, false);
          }
        }
      }
    });
  }

  @action
  nextPage(): void {
    const nextPage = this.currentPage + 1;
    const params = this.getParamsByPageAndExecuteActions(this.steps, nextPage);
    this.setPage(params);
  }

  @action
  prevPage(): void {
    const nextPage = this.currentPage - 1;
    const params = this.getParamsByPageAndExecuteActions(this.steps, nextPage);
    this.setPage(params);
  }

  @action
  showHelpTooltip(): void {
    // Close current window
    if (!this.isWidgetMode) this.emit('close');
    this.dispatch('Overlays', 'closeWindow');

    // Reset tour state
    this.demoService.highlightComponents([]);

    // Open tooltip for help button / tutorial topics if in widget mode
    this.isWidgetMode ?
      this.dispatch('openPlayerTutorialTopicsWindow', { steps: this.steps }) :
      this.dispatch('openPlayerTutorialHelpButtonTooltip');
  }

  @action
  private setPage(params: PlayerTutorialWindowProps): void {
    const { pageNumber, description, content, anchorElement, totalPages, placement, offset } = params;

    this.description = description || null;
    this.content = content || null;
    this.offset = offset || [0, 0];
    this.totalPages = totalPages || 10;
    this.placement = placement || Placement.TopStart;
    this.anchorElement = anchorElement || null;
    this.currentPage = pageNumber || this.currentPage + 1;
  }

  private getParamsByPageAndExecuteActions(steps: PlayerTutorialStep[], step: number): PlayerTutorialWindowProps {
    const currentStep: PlayerTutorialStep = steps[step - 1];
    const { pageNumber, description, id, anchor, placement, offset, customClass, showComponentForTutorial, customAction, content } = currentStep;

    // Remove custom classes from the previous highlighted components
    if (this.currentStep) {
      const currentStepCustomClass = this.currentStep.customClass;

      if (currentStepCustomClass) {
        if (!Array.isArray(currentStepCustomClass)) {
          this.demoService.toggleCustomClass(currentStepCustomClass.elementId, currentStepCustomClass.class, false);
        } else {
          for (const item of currentStepCustomClass) {
            this.demoService.toggleCustomClass(item.elementId, item.class, false);
          }
        }
      }
    }

    // Add custom classes to the highlighted components
    if (customClass) {
      if (!Array.isArray(customClass)) {
        this.demoService.toggleCustomClass(customClass.elementId, customClass.class, true);
      } else {
        for (const item of customClass) {
          this.demoService.toggleCustomClass(item.elementId, item.class, true);
        }
      }
    }

    if (customAction) { customAction(); }

    // Workaround until we find a better solution for OverlayState
    if (showComponentForTutorial) showComponentForTutorial();

    this.demoService.highlightComponents(Array.isArray(id) ? id : [id]);

    // Add get element by if just as a fallback mechanism
    const anchorElement = document.querySelector(`[data-tutorial-id=${anchor}]`) ?? document.getElementById(anchor);
    this.currentStep = currentStep;

    return {
      pageNumber,
      totalPages: steps?.length,
      description,
      placement,
      anchorElement,
      offset,
      content
    }
  }
}

export const playerTutorial = (node: StoreNode | Store, props: Props) => {
  let store: Store;
  if (node instanceof StoreNode)
    store = node.store;
  else
    store = node;

  return new PlayerTutorialWindowState(store, props);
}