import React from 'react';
import { observer } from 'mobx-react-lite';
import { OAuthCallbackPageState } from './oauthCallbackPageState';
import { usePageModel } from '../pageHooks';
import { AuthFlowResponseInterpreter } from '../../components/auth';

export const OAuthCallbackPage = observer(() => {

  const model = usePageModel((kernel) => new OAuthCallbackPageState(kernel.store));
  return (
    <AuthFlowResponseInterpreter
      response={model.flowResponse}
      error={model.error} />
  );
});

export default OAuthCallbackPage;