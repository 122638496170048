import React from 'react';
import { observer } from 'mobx-react-lite';
import { PlayerState } from '../playerState';
import { PageBackButton } from '../../layout/pageBackButton';
import { useStore } from '../../../store/storeHooks';
import { useRoutingService } from '../../../services/routing/routingHooks';

type Props = {
  model?: PlayerState,
}

export const PlayerLiveNotStreamingScreen = observer((props: Props) => {
  const { model } = props;
  const { job, playlistType } = model || {};
  const poster = job?.posterURL ?? '/img/LiveThumbnailPlaceholder.png';

  const store = useStore();
  const { logo } = store.uiService;

  let coverStyle = {};

  if (!logo && poster) {
    coverStyle = {
      backgroundImage: `url('${poster}')`,
    };
  }

  const routingService = useRoutingService();
  const showBackButton = routingService.getBackRoute();
  const showLiveTag = job?.source.liveStreamActivePlaylist === playlistType;

  return (
    <div className="live-cover" style={coverStyle}>
      <div id="player-live-not-streaming-screen"
        className="player-live-status-screen">
        {showBackButton &&
          <div className="live-back-button">
            <PageBackButton />
          </div>}

        {showLiveTag &&
          <div className="player-live-tag">Live</div>}
        <span className="info-live">Feed not streaming</span>
      </div>
    </div>
  );
});