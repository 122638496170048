import { StoreNode } from '../../store';
import { RouteContext } from '../../routes/routeContext';
import { PlayerWidgetRouteParams } from './playerWidgetState';
import { AsyncResult } from '../../core';
import { Permissions } from '../../services/auth/authFlowSchema';
import { Error } from '../../core/error';
import { IRouteInjector } from '../../routes/routeSchema';

export class PlayerWidgetInjector
  extends StoreNode
  implements IRouteInjector {

  async fetchPermissions(routeContext: RouteContext<PlayerWidgetRouteParams>): AsyncResult<Permissions, any> {
    const { JobPermissionsSource } = await import('../../entities/jobPermissionsSource');

    const jobId: string | null = routeContext.params?.jobId ?? null;
    if (!jobId)
      return [null, new Error('InternalError', `Unable to get jobId from route params.`)];

    const source = new JobPermissionsSource(this.store, {
      jobId
    });

    return source.fetchPermissions();
  }
}