import '../auth/authPages.scss';
import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { BasicPage, CliprLogoSVGIcon } from '../../components';
import { Link, Redirect, useLocation } from 'react-router-dom';
import Routes from '../../routes';
import { isNonEmptyString } from '../../core';

export const OAuthPasswordPage = observer(() => {

  // https://app.clipr.ai/oauth/password?success=true&message=You%20can%20now%20login%20to%20the%20application%20with%20the%20new%20password
  // https://app.clipr.ai/oauth/password?message=This%20URL%20can%20be%20used%20only%20once&success=false

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const success = query.get('success');
  const message = query.get('message');

  if (
    !['true', 'false'].includes(success || '') || 
    !isNonEmptyString(success))
    return <Redirect to={Routes.defaultRoute()} />

  let panelElem: ReactNode;
  if (success === 'true') {

    panelElem = (
      <div className="auth-panel">
        <header className="auth-header">
          <div className="clipr-logo">
            <CliprLogoSVGIcon />
          </div>
          <h1 className="vpad2 bold text-center">Done!</h1>
          <p className="text-center">{message}</p>
        </header>

        <p className="text-center vpad4">
          <Link to={Routes.login()} className="bold">Sign in</Link>
        </p>
      </div>
    );
  } else {

    panelElem = (
      <div className="auth-panel">
        <header className="auth-header">
          <div className="clipr-logo">
            <CliprLogoSVGIcon />
          </div>
          <h2 className="vpad2 bold text-center">Cannot reset password</h2>
          <p className="text-center">{message}</p>
        </header>
        <p className="text-center vpad4">
          <Link to={Routes.forgotPassword()} className="bold">Try again</Link>
        </p>
      </div>
    );
  }

  return (
    <BasicPage
      className="auth-page dock"
      showHeader={false}>

      <div className="auth-basic">
        <div className="auth-container">
          {panelElem}
        </div>
      </div>
    </BasicPage>
  );
});

export default OAuthPasswordPage;