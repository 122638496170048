import { GalleryDisplay } from '@clipr/lib';
import React from 'react';
import { DarkModeSVGIcon, GridViewSVGIcon, LightModeSVGIcon, ListViewSVGIcon, CarouselViewSVGIcon } from '../svg/svg';

export const LAYOUT_ICONS = {
  themeIcons: [
    {
      key: 'darkTheme', icon: <DarkModeSVGIcon />
    },
    {
      key: 'lightTheme', icon: <LightModeSVGIcon />
    }
  ],
  viewIcons: [
    {
      key: 'gridDisplay', icon: <GridViewSVGIcon />
    },
    {
      key: 'listDisplay', icon: <ListViewSVGIcon />
    },
    {
      key: 'carouselDisplay', icon: <CarouselViewSVGIcon />
    }
  ]
}

export const mapDisplayToValue = (view?: GalleryDisplay): 'gridDisplay' | 'listDisplay' | 'carouselDisplay' => {
  switch (view) {
    case 'Carousel':
      return 'carouselDisplay';
    case 'List':
      return 'listDisplay';
    default:
    case 'Grid':
      return 'gridDisplay';
  }
}

export const mapFromValueToDisplay = (view: string | null): GalleryDisplay => {
  switch (view) {
    case 'carouselDisplay':
      return GalleryDisplay.Carousel;
    case 'listDisplay':
      return GalleryDisplay.List;
    default:
    case 'gridDisplay':
      return GalleryDisplay.Grid;
  }
}