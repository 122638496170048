import { action, computed, makeObservable, observable } from 'mobx';
import pick from 'lodash/pick';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { Error, pageError } from '../../core/error';
import { JobModel } from '../../entities';
import { MomentSelector } from '../../entities/moments';
import { Routes } from '../../routes';
import { MomentCloudMode, MomentCloudState } from '../../components/momentCloud';
import { Maybe } from '../../core';
import { PlayerItemSource } from '../../entities/player/playerItemSource';
import { MomentSplashState } from '../../components/momentSplash';
import { getApiResultJobError } from '../../api';

export type UserSplashPageParams = {
  jobId?: string
}

type Props = BindingProps<{
  selector?: MomentSelector,
  suppressApiQueries?: boolean
}>

export type SplashPageSections =
  'Topics' |
  'SubTopics' |
  'Speakers';

export class UserSplashPageState
  extends StoreNode {

  readonly nodeType: 'UserSplashPage' = 'UserSplashPage';

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.defaultSelector = new MomentSelector(store, {
      jobId: () => this.jobId
    });

    this.momentCloud = new MomentCloudState(store, {
      jobId: () => this.jobId,
      selector: () => this.selector
    });
    this.momentSplash = new MomentSplashState(store, {
      jobId: () => this.jobId,
      selector: () => this.selector
    });

    this.playerItemSource = new PlayerItemSource(store, {
      jobId: () => this.jobId,
      selector: () => this.selector
    });
  }

  readonly defaultSelector: MomentSelector;

  @computed
  get selector(): MomentSelector {
    const propSelector = this.getResolvedProp('selector');
    if (!propSelector)
      return this.defaultSelector;

    return propSelector;
  }

  @computed get suppressApiQueries(): boolean {
    return this.getResolvedProp('suppressApiQueries', false);
  }

  @observable isLoading: boolean = true;
  @observable error: Error | null = null;
  @observable skipPage: boolean = false;

  @observable jobId: string | null = null;
  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  readonly momentCloud: MomentCloudState;
  readonly momentSplash: MomentSplashState;
  readonly playerItemSource: PlayerItemSource;

  @computed get mode() {
    return this.momentCloud.mode;
  }
  @action
  setMode(mode: MomentCloudMode) {
    this.selector.setMode(mode);
  }

  @observable isExperimentalMode = false;

  @action
  toggleExperimentalMode() {
    this.isExperimentalMode = !this.isExperimentalMode;
  }

  /** Notifies the state that the landing page has been mounted and triggers the initialization tasks. */
  @action
  async mounted(params?: Maybe<UserSplashPageParams>) {

    const { jobId } = params || {};
    if (!jobId)
      return this.setError();

    const { store } = this;
    this.jobId = jobId;
    this.isLoading = true;

    if (!this.suppressApiQueries) {
      const [res, apiErr] = await store.apiFetchJob(jobId, true);

      const err = getApiResultJobError([[res, apiErr]]);
      if (err)
        return this.setError(err);
    }

    // check if this is the response that came after the last request,  otherwise ignore it
    if (jobId !== this.jobId) {
      console.warn(`Response for Job '${jobId}' has been discarded.`);
      return false;
    }

    if (!this.job)
      return this.setError();

    if (!this.job.isPublished)
      return this.setError(new Error('JobUnavailable'));

    this.broadcast('videoSplashLoaded', {
      jobId,
      title: this.job?.title,
      path: Routes.userSplash(jobId)
    });

    this.selector.load();
    this.selector.exitVideoMode();

    if (this.shouldSkipPage()) {
      this.setSkipPage();
      return;
    }

    // not needed as page should be skipped
    // if (this.selector.mode === 'Topics' && !this.selector.hasTopics) 
    //   this.selector.setMode('Speakers');

    if (this.selector.mode === 'Speakers' && !this.selector.hasSpeakers)
      this.selector.setMode('Topics');

    this.setLoaded();
  }

  /** Notifies the state that the landing page has been unmounted and triggers the cleanup tasks. */
  @action
  unmounted() {
    this.selector.save();
    this.reset();

    // TEMP: fix to prevent the graph briefly being displayed before the load mask appears
    this.isLoading = true;
  }

  // #region State helpers
  @action
  reset() {
    this.selector.reset();
    this.skipPage = false;
    this.jobId = null;
    this.isLoading = false;
    this.error = null;
  }

  @action
  private setError(error?: Error) {
    if (!error)
      error = pageError();

    this.isLoading = false;
    this.error = error;
  }

  @action
  private setLoaded() {
    this.isLoading = false;
    this.error = null;
  }
  // #endregion
  @action
  private setSkipPage(): void {
    if (!this.jobId)
      return;

    this.skipPage = true;
  }

  @action
  shouldSkipPage(): boolean {
    if (!this.selector.hasTopics) //&& !this.selector.hasSpeakers
      return true;

    // const search = new URLSearchParams(window.location.search);
    // const teamId = decodeURI(search.get('teamId') || '');

    // if (teamId)
    //   return true;

    return false;
  }

  private _exportJobData() {

    let data: any = pick(this.job, [
      'id',
      'title',
      'status'
    ]);

    data.moments = this.job?.moments.map(mom => {

      return pick(mom, [
        'id',
        'momentType',
        'startTime',
        'endTime',
        'description',
        'jobId',
        'source',
        'speakerId',
        'trackId'
      ]);
    });

    data.speakers = this.job?.speakers.map(spk => {

      return pick(spk, [
        'id',
        'jobId',
        'name',
        'description',
        'picture',
        'scope'
      ]);
    });

    return data;
  }

  getSectionVisibility = (section: SplashPageSections) => {
    switch (section) {
      case 'Speakers':
        return this.selector.hasSpeakers;
      case 'Topics':
        return this.selector.hasTopics;
      case 'SubTopics':
        return this.selector.hasSubTopics;
      default:
        return true;
    }
  }
}