import { makeObservable, observable, computed, action } from 'mobx';
import { BindingProps, StoreNode } from '../../../store';
import { Store } from '../../../store/store';
import { HoursCliprdChartMode, HoursCliprdChartDatum, HoursCliprdChartInternalParams, HoursCliprdChartParams } from './hoursCliprdChartSchema';
import { HoursCliprdDataSource } from './hoursCliprdDataSource';
import { input, InputState } from '../../input';

type Props = BindingProps<{
  params?: HoursCliprdChartParams
}>

export class HoursCliprdChartState
  extends StoreNode {

  readonly nodeType = 'HoursCliprdChartState';
  readonly chartTypeInput: InputState;

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.chartTypeInput = input(this, {
      name: 'audienceFocusTypes',
      onChange: this.handleChartTypeChange,
      selectorItems: [
        { value: HoursCliprdChartMode.Processed, label: 'Processed' },
        { value: HoursCliprdChartMode.Viewed, label: 'Viewed' },
        { value: HoursCliprdChartMode.Saved, label: 'Saved' },
      ],
    });

    this.chartTypeInput.loadValue(HoursCliprdChartMode.Processed);
  }

  // #region Props
  @computed get params(): HoursCliprdChartParams | null {
    return this.getResolvedProp('params') ?? null;
  }
  set params(val: HoursCliprdChartParams | null) {
    this.setProp('params', val);
  }
  // #endregion

  readonly internalParams: HoursCliprdChartInternalParams = observable({
    mode: HoursCliprdChartMode.Processed
  })

  @computed get outputParams(): HoursCliprdChartParams {
    const outParams = {
      ...this.internalParams,
      ...this.params
    };

    Object.freeze(outParams);
    return outParams;
  }

  @computed get outputData(): HoursCliprdChartDatum[] {
    return [...this.dataSource.data];
  }

  readonly dataSource = new HoursCliprdDataSource(this.store, {
    params: () => this.outputParams
  });

  @action
  private handleChartTypeChange = async (input: InputState, evt: React.ChangeEvent) => {
    const mode = input.value as HoursCliprdChartMode;
    if (!mode)
      return;
    this.internalParams.mode = mode;
    this.dataSource.fetch();
  }

  async fetch() {
    return this.dataSource.fetch();
  }
  abort() {
    this.dataSource.cancel();
  }
}