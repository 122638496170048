import './infoModal.scss';

import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { InfoModalState } from './infoModalState';

type Props = {
  model: InfoModalState;
  visible?: boolean;
};

export const InfoModal = observer(({ model, visible }: Props) => {
  const className = classNames('info-modal-window', model.windowClassName);

  return (
    <Window
      model={model.window}
      visible={visible}
      className={className}>
      <WindowHeader model={model.window} title={model.title} />

      <hr />

      <div className="window-content">{model.content}</div>
    </Window>
  );
});
