import './playerReactionAnimations.scss';

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { BloomSVGIconLookup, ReactionSVGIconLookup } from '../playerReactions/reactionSvgIconLookup';
import { PlayerReactionAnimationsState, ReactionAnimations } from './playerReactionAnimationsState';

type Props = {
  model: PlayerReactionAnimationsState
};

export const PlayerReactionAnimations = observer(({
  model
}: Props) => {
  useEffect(() => {
    model.mounted();
    window.addEventListener('blur', () => model.handleBlur());
    
    return () => {
      model.unmounted();
      window.removeEventListener('blur', () => model.handleBlur());
    }
  }, [model]);

  return (
    <div className="reaction-animations">
      <div className="bloom-animations">
        {model.bloomReactions?.map((reactionModel) => {
          const { reaction, animationParams } = reactionModel;

          return (BloomSVGIconLookup[reaction.reaction].component({
              key: `bloom-reaction-${reaction.id}`,
              className: classNames('bloom-position-' + animationParams.bloomPosition),
              onAnimationEnd: () => {
                model.removeReaction(reaction.id, ReactionAnimations.BloomAnimation)
              },
            }));
        })}
      </div>

      <div className="flame-animations">
        {model.flameReactions.map((reactionModel) => {
          const { reaction, animationParams } = reactionModel;

          return (<div 
            key={`reaction-flame-${reaction.id}`} 
            className={classNames('reaction-animation-container', 'reaction-animation-traj-' + animationParams.flameTraj)}
            onAnimationEnd={() => {
              model.removeReaction(reaction.id, ReactionAnimations.FlameAnimation)
            }}>
              <div 
              className="reaction-animation">
                {ReactionSVGIconLookup[reaction.reaction].component}
              </div>
            </div>);
        })}
      </div>
    </div>
  );
});
