import { action, makeObservable, computed } from 'mobx';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import {
  input,
  inputGroup,
} from '../../components/input';
import { fileInput } from '../../components/input/fileInputState';
import fromPairs from 'lodash/fromPairs';
import identity from 'lodash/identity';
import { ApiUploadRequestParams } from '../../api';
import { notifyError } from '../../services/notifications';
import { Theme } from '@clipr/lib';
import { mapDisplayToValue } from '../layout/util';

type PublicTeamFormStateProps = BindingProps<{
  teamId?: string;
}>;

export class PublicTeamFormState
  extends StoreNode {

  constructor(store: Store, props?: PublicTeamFormStateProps) {
    super(store, props);
    makeObservable(this);
  }

  @computed get teamId(): string {
    return this.resolvedProps.teamId;
  }

  @computed get team() {
    return this.store.teamManager.getTeam(this.teamId);
  }

  // readonly autoJoinOnSignup = input(this, {
  //   name: 'autoJoinOnSignup',
  //   placeholder: 'Autojoin on Sign-up',
  // });

  readonly displayName = input(this, {
    name: 'displayName',
    placeholder: 'Display Name',
    charCountMax: 100
  });

  readonly showCover = input(this, {
    name: 'showCover',
    label: 'Cover Image'
  });

  readonly view = input(this, {
    name: 'view',
    selectorItems: [
      { value: 'gridDisplay', label: 'Grid' },
      { value: 'listDisplay', label: 'List' },
      { value: 'carouselDisplay', label: 'Carousel' }
    ]
  });

  readonly theme = input(this, {
    name: 'theme',
    selectorItems: [
      { value: 'darkTheme', label: 'Dark' },
      { value: 'lightTheme', label: 'Light' }
    ]
  });

  readonly coverImage = fileInput(this, {
    name: 'coverImage',
    isRequired: () => !!this.showCover.value,
    disabled: () => !this.showCover.value
  });

  readonly showLogo = input(this, {
    name: 'showLogo',
    label: 'Display Logo'
  });

  readonly displayLogo = fileInput(this, {
    name: 'displayLogo',
    isRequired: () => !!this.showLogo.value,
    disabled: () => !this.showLogo.value
  });

  readonly customRedirectUrl = input(this, {
    name: 'customRedirectUrl',
    placeholder: 'Website Url'
  })

  readonly form = inputGroup(this, {
    name: 'enrichmentLevelForm',
    inputs: () => {
      return [
        // this.autoJoinOnSignup,
        this.displayName,
        this.showCover,
        this.coverImage,
        this.showLogo,
        this.displayLogo,
        this.view,
        this.theme,
        this.customRedirectUrl
      ]
    },
    export: () => {
      return fromPairs(
        this.form.inputs.map(input => {
          if (['showLogo', 'showCover'].includes(input.name)) //'autoJoinOnSignup',
            return [input.name, input.export() ?? false];
          if (['coverImage', 'displayLogo', 'view', 'theme'].includes(input.name))
            return null;

          if (['displayName', 'customRedirectUrl'].includes(input.name))
            return [input.name, input.export()];

          return [input.name, input.export() || undefined];
        }).filter(identity) as any
      );
    }
  });

  init() {
    const displayOption = mapDisplayToValue(this.team?.viewDisplayOption);
    this.view.loadValue(displayOption);

    const isLightTheme = this.team?.themeOption === Theme.Light;
    isLightTheme ? this.theme.loadValue('lightTheme') : this.theme.loadValue('darkTheme');

    this.displayName.loadValue(this.team?.displayName);
    this.showCover.loadValue(!!this.team?.showCover);
    this.showLogo.loadValue(!!this.team?.showLogo);
    this.coverImage.loadPreviewUrl(this.team?.coverURL || null);
    this.displayLogo.loadPreviewUrl(this.team?.logoURL || null);
    this.customRedirectUrl.loadValue(this.team?.customRedirectUrl || null)
  }

  reset() {
    this.form.clear();
  }

  export(): any {
    return this.exportPublicTeamFormData();
  }

  @action
  private exportPublicTeamFormData(): any {
    return this.form.export();
  }

  async getTeamCoverUploadToken() {
    const file = this.coverImage.export();

    if (!file || !this.team || !this.showCover.value) {
      return null;
    }

    // Get the upload URL
    const [res, err] = await this.store.apiService.getCoverUploadUrl({ teamId: this.team?.id, fileName: file.name });

    if (err) {
      // this.handleSubmitReject('Failed to update the cover image');
      notifyError(this, 'Failed to update the cover image.');
      return null;
    }

    if (res) {
      const params = res.getCoverUploadUrl;
      const uploadParams: ApiUploadRequestParams = {
        file,
        url: params?.uploadUrl,
        data: params?.fields
      }

      // Start the upload request
      const uploadRequest = this.store.apiService.uploadRequest(uploadParams);
      const [, uploadErr] = await uploadRequest.start();

      if (uploadErr) {
        notifyError(this, 'Failed to update the cover image.');
        return null;
      }

      return {
        coverToken: params?.uploadToken
      }
    }
  }

  async updateTeamCoverImage() {
    if (!this.team || !this.showLogo.value)
      return;
    const coverData = await this.getTeamCoverUploadToken();

    // Update the team cover picture if the upload was successful
    const [, updateError] = await this.store.apiService.updateTeam({
      args: {
        teamId: this.team.id,
        coverToken: coverData?.coverToken || undefined,
      },
    });

    if (updateError) {
      notifyError(this, 'Failed to update the cover image.');
      return;
    }
  }

  async getTeamLogoUploadToken() {
    const file = this.displayLogo.export();

    if (!file || !this.team || !this.showLogo.value) {
      return null;
    }

    // Get the upload URL
    const [res, err] = await this.store.apiService.getLogoUploadUrl({ teamId: this.team?.id, fileName: file.name });

    if (err) {
      // this.handleSubmitReject('Failed to update the logo image');
      notifyError(this, 'Failed to update the logo image.');
      return null;
    }

    if (res) {
      const params = res.getLogoUploadUrl;
      const uploadParams: ApiUploadRequestParams = {
        file,
        url: params?.uploadUrl,
        data: params?.fields
      }

      // Start the upload request
      const uploadRequest = this.store.apiService.uploadRequest(uploadParams);
      const [, uploadErr] = await uploadRequest.start();

      if (uploadErr) {
        notifyError(this, 'Failed to update the logo image.');
        return null;
      }

      return {
        logoToken: params?.uploadToken
      }
    }
  }

  async updateTeamLogoImage() {
    if (!this.team || !this.showLogo.value)
      return;

    const logoData = await this.getTeamLogoUploadToken();
    // Update the team logo picture if the upload was successful
    const [, updateError] = await this.store.apiService.updateTeam({
      args: {
        teamId: this.team.id,
        logoToken: logoData?.logoToken || undefined,
      },
    });
    if (updateError) {
      notifyError(this, 'Failed to update the logo image.');
      return;
    }
  }
}
