import { Auth0Error } from 'auth0-js';
import { Maybe } from '../../core';
import { Error, ErrorCode } from '../../core/error';
import { InvalidPasswordMessage, getRegisterInvalidPasswordErrorMessage } from './auth0Utils';

export type AuthErrorType =
  'AuthError' |

  'RedirectExpected' |
  'InvalidPermit' |

  'FetchProfileError' |

  /** Indicates that a valid `AuthContext` could not be created from the current state. */
  'CreateContextError' |

  'AccessDenied' |
  'NotAuthorized' |
  'TooManyAttempts' |

  /** Indicates that the requested provider connection is not implemented. */
  'ConnectionNotSupported' |

  /** 
   * Indicates that a particular provider connection (Database, Google, Facebook, etc) 
   * is supported but not enabled in the client. 
   */
  'ConnectionNotClientEnabled' |

  /** 
   * Indicates that a particular provider connection (Database, Google, Facebook, etc) 
   * is supported but not configured properly. Probably the connection name is not set.
   */
  'ConnectionNotProperlyConfigured' |


  /** Indicates that the username does not exist in the backend. */
  'UsernameNotFound' |
  /** Indicates that the password is wrong in an authentication flow. */
  'WrongPassword' |

  /** Indicates that the username or email and password combination is wrong, without specifying which one is wrong. */
  'WrongCredentials' |



  'InvalidRegister' |


  /** The password doesn't comply with the password policies. */
  'InvalidPassword' |
  /** The chosen password is too common. */
  'PasswordTooCommon' |
  /** The chosen password is based on user information. */
  'PasswordTooWeak' |
  /** The chosen password is too weak. */
  'PasswordContainsUserInfo' |
  /** Indicates that a new password and it's confirmation dont' match. */
  'PasswordMismatch' |
  /** If the password has been leaked and a different one needs to be used. */
  'PasswordLeaked' |

  /** The username does not match the validation rules. */
  'InvalidUsername' |
  /** The user you are attempting to sign up has already signed up. */
  'UserExists' |
  /** The username you are attempting to sign up with is already in use. */
  'UsernameExists' |


  /** The email address does not match the validation rules. */
  'InvalidEmail' |
  /** The email address you are attempting to sign up with is already in use. */
  'EmailExists' |


  'NetworkError' |
  'ProviderError' |
  'UnknownError' |
  'ApiError';


export type AuthPasswordRule = {
  code: string
  format?: number[]
  items: {
    code: string,
    message: string,
    verified: string
  }[]
  message: string
  verified: boolean
}

export type Auth0OriginalError = Auth0Error & {
  description: {
    rules: AuthPasswordRule[]
  }
}

function getMessage(type: Maybe<AuthErrorType>) {

  switch (type) {
    case 'UsernameNotFound':
      return `Username does not exist`;
    case 'WrongPassword':
      return `The password is invalid`;
    case 'WrongCredentials':
      return `The username / password combination is invalid.`;

    // username / email errors
    case 'InvalidEmail':
      return `This email is invalid`;
    case 'InvalidUsername':
      return `This username or email is invalid`;
    case 'EmailExists':
      return `Email already exists`;
    case 'UserExists':
      return `User already exists`;
    case 'UsernameExists':
      return `Username already exists`;

    // password errors
    case 'InvalidPassword':
      return `The password is invalid`;
    case 'PasswordTooCommon':
      return `The chosen password is too common.`;
    case 'PasswordTooWeak':
      return `The chosen password is too weak.`;
    case 'PasswordContainsUserInfo':
      return `The chosen password is based on user information.`;
  }

  return `An unknown error occurred.`;
}

/** Base class for all errors returned during the authentication flows. */
export class AuthError
  extends Error {

  readonly type: AuthErrorType;
  readonly props: any;
  readonly originalMessage: string | null;
  readonly innerError: Auth0OriginalError | null;
  readonly data: InvalidPasswordMessage;

  constructor(type?: AuthErrorType, message?: Maybe<string>, props?: any) {
    super(type as ErrorCode, message ?? getMessage(type));

    this.type = type || 'AuthError';
    this.originalMessage = message || null;
    this.innerError = props?.innerError ?? null;

    this.data = (() => {
      switch (type) {
        case 'InvalidPassword':
          return getRegisterInvalidPasswordErrorMessage(this.innerError)
        default:
          return null;
      }
    })();
  }
}