import { action, makeObservable, observable, runInAction, computed } from 'mobx';
import { Nullable, Maybe } from '../../core';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { GetJobPermissionsQuery, JobPermission } from '@clipr/lib';

export class RemoveJobFromTeamWindowState
  extends StoreNode {

  readonly nodeType = 'RemoveJobFromTeamWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);

  jobId: Nullable<string> = null;
  teamId: Nullable<string> = null;
  teamName: Nullable<string> = null;
  teamIsOwner: boolean = false;

  @observable isLoading: boolean = false;

  @observable owner: JobPermission | undefined = undefined;

  @computed
  get isWidgetMode() {
    return this.store.widgetService.isWidgetMode;
  }

  @action
  async init() {
    if (!this.jobId || !this.teamId) {
      return this.handleSubmitFinally();
    }
    this.isLoading = true;
    this.teamName = this.getTeamDetails(this.teamId)?.name || null;

    const [permissions, err] = await this.store.apiService.getJobPermissions({ jobId: this.jobId });

    if (err) {
      notifyError(this, 'Something went wrong.');
      return this.handleSubmitFinally();
    }
    runInAction(() => {
      this.owner = this.getOwner(permissions);
      this.isLoading = false;
    })
  }

  getTeamDetails(teamId: Nullable<string>) {
    return this.store.teamManager.teams.map(
      (team) => ({ id: team.id, name: team.name })
    ).find((team) => team.id === teamId);
  }

  getOwner(permissions: Maybe<GetJobPermissionsQuery>) {
    return permissions?.getJobPermissions?.find(permission => permission?.role === 'Owner');
  }

  @action
  async submit() {
    this.isLoading = true;

    const [, err] = await this.store.apiService.removeJobFromTeam({
      jobId: this.jobId || '',
      teamId: this.teamId || ''
    });

    if (err) {
      notifyError(this, err.message);
    } else {
      // TODO: remove hard dependencies to other pages
      if (this.isWidgetMode) {
        this.store.teamLibraryWidget.refreshJobs();
      } else {
        await this.store.teamDashboardPage.mounted({ teamId: this.teamId || '' });
      }
      notifySuccess(this, 'Video removed from library.');
    }
    this.emit('JobRemoved')
    return this.handleSubmitFinally();
  }

  handleSubmitFinally() {
    runInAction(() => {
      this.isLoading = false;
      this.close();
    })
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open({ jobId, teamId }: { jobId: string, teamId: string }) {
    this.dispatch('Overlays', 'openWindow', { name: 'RemoveJobFromTeamWindow' });

    this.jobId = jobId;
    this.teamId = teamId;
    this.init();
  }

  @action
  close() {
    this.jobId = null;
    this.teamId = null;
    this.teamName = null;
    this.teamIsOwner = false;

    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}