import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { JobViewStatusWindowState } from './jobViewStatusWindowState';
import { Button } from '../input';

type Props = {
  model: JobViewStatusWindowState;
  visible?: boolean;
};

export const JobViewStatusWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      model={model.window}
      visible={props.visible}
      isLoading={model.isLoading}>
      <WindowHeader model={model.window} title={model.isPublished ? 'Unpublish video' : 'Publish video'} />

      <hr />

      <div className="window-content">
        <p className="p-bold">Are you sure you want to {model.isPublished ? 'unpublish' : 'publish'} this video?</p>
        {model.isPublished ? 
          <p>Unpublishing the video means that it can no longer be streamed by end users (regardless of if the user is the video owner or not).</p>
          : <p>Publishing the video means that it will be again available for streaming by end users.</p>
        }
      </div>

      <hr />

      <footer className="window-footer">
        <div className="btn-group right">
          <Button
            className="primary btn-outline medium"
            label="Cancel"
            onClick={() => model.close()}
          />
          <Button
            className="primary medium"
            label={model.isPublished ? 'Unpublish' : 'Publish'}
            onClick={() => model.submit()}
          />
        </div>
      </footer>
    </Window>
  );
});
