import { DocumentNode, print } from 'graphql';

import {
  GetBookmarkListDocument,
  GetBookmarkListsDocument,
  GetBookmarksDocument,
  GetMomentsDocument,
  GetMomentBookmarksDocument,
  GetUploadUrlDocument,
  GetJobDocument,
  GetSpeakersDocument,
  GetJobPermissionsDocument,
  GetJobsDocument,
  GetJobRelatedBookmarksDocument,
  GetJobBookmarksDocument,
  GetTeamDocument,
  GetTeamMembersDocument,
  GetTeamInvitationsDocument,
  GetTeamsDocument,
  GetProfileDocument,
  AdminGetJobsDocument,
  SearchMomentsDocument,
  GetSpeakerDocument,
  BatchGetSpeakersDocument,
  GetTracksDocument,
  AdminGetProfileDocument,
  GetCommentsDocument,
  ViewerDocument,
  GetExternalLibraryDocument,
  GetReactionsDocument,
  GetJob_CheckLiveStatusDocument,
  GetTeam_CheckPermissionsDocument,
  KeenQueryDocument,
  KeenBatchDocument,
  Keen_GetTopicViewsDocument,
  Keen_GetSubTopicViewsDocument,
  Keen_GetMostBookmarkedTopicsDocument,
  Keen_GetMostBookmarkedSubtopicsDocument,
  Keen_GetReactionsDocument,
  Keen_GetBookmarksDocument,
  Keen_GetTeamUserUploadsDocument,
  Keen_GetTeamUserEditsDocument,
  Keen_GetTeamUserBookmarksDocument,
  Keen_GetTeamUserReactionsDocument,
  Keen_GetHoursCliprdProcessedDocument,
  Keen_GetHoursCliprdViewedDocument,
  Keen_GetHoursCliprdSavedDocument,
  Keen_GetVideoAddToTeamDocument,
  Keen_GetPlaysDocument,
  Keen_GetVideoSharesDocument,
  Keen_GetTotalDailyReactionsDocument,
  Keen_GetReactionsByJobSourceDocument,
  Keen_GetPerformanceDocument,
  Keen_GetTotalUniqueUploadsDocument,
  Keen_GetTotalPlaysDocument,
  Keen_GetTotalTopicsDocument,
  Keen_GetTotalReactionsDocument,
  Keen_GetTotalSharesDocument,
  Keen_GetTotalBookmarksDocument,
  Keen_GetAvgWatchTimeDocument,
  GetNotificationSubscriptionEmailEventTypesDocument,
  UploadWidget_GetTeamDocument,
  GetMomentDocument,
  GetJobTranscriptDocument,
  GetTeamTagsDocument,
  GetTeam_GetAdsDocument,
  GetTeamNamespacesDocument,
  GetJob_CheckPermissionsDocument,
  GetDownloadLinkDocument
} from '@clipr/lib';

import { ApiQueryName } from './apiSchema';

export const ApiQueries: { [key in ApiQueryName]?: string } = {};

const query = (name: ApiQueryName, doc: DocumentNode) => {
  return ApiQueries[name] = print(doc);
};

query('getBookmarks', GetBookmarksDocument);
query('getBookmarkLists', GetBookmarkListsDocument);
query('getBookmarkList', GetBookmarkListDocument);
query('getMoments', GetMomentsDocument);
query('getMoment', GetMomentDocument);
query('getMomentBookmarks', GetMomentBookmarksDocument);
query('getJobBookmarks', GetJobBookmarksDocument);
query('getJobRelatedBookmarks', GetJobRelatedBookmarksDocument);
query('getUploadUrl', GetUploadUrlDocument);
query('getJob', GetJobDocument);
query('getJobPermissions', GetJobPermissionsDocument);
query('getJobs', GetJobsDocument);
query('getSpeakers', GetSpeakersDocument);
query('getSpeaker', GetSpeakerDocument);
query('batchGetSpeakers', BatchGetSpeakersDocument);
query('getTracks', GetTracksDocument);
query('getTeam', GetTeamDocument);
query('getTeam_getAds', GetTeam_GetAdsDocument);
query('getTeamMembers', GetTeamMembersDocument);
query('getTeamInvitations', GetTeamInvitationsDocument);
query('getTeamNamespaces', GetTeamNamespacesDocument);
query('getTeamTags', GetTeamTagsDocument);
query('getTeams', GetTeamsDocument);
query('getProfile', GetProfileDocument);
query('getNotificationSubscriptionEmailEventTypes', GetNotificationSubscriptionEmailEventTypesDocument);
query('adminGetJobs', AdminGetJobsDocument);
query('searchMoments', SearchMomentsDocument);
query('adminGetProfile', AdminGetProfileDocument);
query('getComments', GetCommentsDocument);
query('getReactions', GetReactionsDocument);
query('viewer', ViewerDocument);
query('getExternalLibrary', GetExternalLibraryDocument);
query('getJob_checkLiveStatus', GetJob_CheckLiveStatusDocument);
query('getJob_checkPermissions', GetJob_CheckPermissionsDocument);
query('getTeam_checkPermissions', GetTeam_CheckPermissionsDocument);
query('keenQuery', KeenQueryDocument);
query('keenBatch', KeenBatchDocument);
query('keen_getTopicViews', Keen_GetTopicViewsDocument);
query('keen_getSubTopicViews', Keen_GetSubTopicViewsDocument);
query('keen_getMostBookmarkedTopics', Keen_GetMostBookmarkedTopicsDocument);
query('keen_getMostBookmarkedSubtopics', Keen_GetMostBookmarkedSubtopicsDocument);
query('keen_getReactions', Keen_GetReactionsDocument);
query('keen_getBookmarks', Keen_GetBookmarksDocument);
query('keen_getTeamUserUploads', Keen_GetTeamUserUploadsDocument);
query('keen_getTeamUserEdits', Keen_GetTeamUserEditsDocument);
query('keen_getTeamUserBookmarks', Keen_GetTeamUserBookmarksDocument);
query('keen_getTeamUserReactions', Keen_GetTeamUserReactionsDocument);
query('keen_getHoursCliprdProcessed', Keen_GetHoursCliprdProcessedDocument);
query('keen_getHoursCliprdViewed', Keen_GetHoursCliprdViewedDocument);
query('keen_getHoursCliprdSaved', Keen_GetHoursCliprdSavedDocument);
query('keen_getVideoAddToTeam', Keen_GetVideoAddToTeamDocument);
query('keen_getPlays', Keen_GetPlaysDocument);
query('keen_getVideoShares', Keen_GetVideoSharesDocument);
query('keen_getTotalDailyReactions', Keen_GetTotalDailyReactionsDocument);
query('keen_getReactionsByJobSource', Keen_GetReactionsByJobSourceDocument);
query('keen_getPerformance', Keen_GetPerformanceDocument);
query('keen_getTotalUniqueUploads', Keen_GetTotalUniqueUploadsDocument);
query('keen_getTotalPlays', Keen_GetTotalPlaysDocument);
query('keen_getTotalTopics', Keen_GetTotalTopicsDocument);
query('keen_getTotalReactions', Keen_GetTotalReactionsDocument);
query('keen_getTotalShares', Keen_GetTotalSharesDocument);
query('keen_getTotalBookmarks', Keen_GetTotalBookmarksDocument);
query('keen_getAvgWatchTime', Keen_GetAvgWatchTimeDocument);
query('uploadWidget_getTeam', UploadWidget_GetTeamDocument);
query('getJobTranscript', GetJobTranscriptDocument);
query('getDownloadLink', GetDownloadLinkDocument);
