import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { Message, refProxy, StoreNode } from '../../store';

type WindowProps = {
  closeOnOutsideClick?: boolean;
  ignoredTargetOnOutsideClickSelector?: string;
}
export class WindowState
  extends StoreNode {

  @observable isVisible = false;
  @observable isLocked = false;

  constructor(store: Store, props?: WindowProps) {
    super(store, props);
    makeObservable(this);

    this.refProxy.listen(
      this.refProxyListener);
  }

  @computed
  get ignoredTargetOnOutsideClick(): string {
    return this.resolvedProps.ignoredTargetOnOutsideClickSelector;
  }

  @computed
  get closeOnOutsideClick() {
    return this.resolvedProps.closeOnOutsideClick;
  }
  readonly refProxy = refProxy(this);

  @action
  open() {
    this.isVisible = true;
  }

  @action
  close() {
    this.isVisible = false;
  }

  @action
  lock() {
    this.isLocked = true;
  }

  @action
  unlock() {
    this.isLocked = false;
  }

  @action
  handleCloseButtonClick = (evt: React.MouseEvent) => {
    this.emit('close');
  }

  @action
  handleMounted() { }

  @action
  handleUnmounted() { }

  @action
  private handleOpened() {
    // console.log('window opened');
    // document.addEventListener('click', this.handleNativeRootClick);

    // mousedown seems to work better in certain scenarios, such as accidentally releasing the click outside of the window / handlers with stop propagation etc.
    document.addEventListener('mousedown', this.handleNativeRootClick);
  }

  @action
  private handleClosed() {
    // console.log('window closed');
    // document.removeEventListener('click', this.handleNativeRootClick);
    document.removeEventListener('mousedown', this.handleNativeRootClick);
  }

  private handleNativeRootClick = (evt: MouseEvent) => {
    const node = this.refProxy.node;

    let ignoredTarget: HTMLElement | Element | null = null;
    if (this.ignoredTargetOnOutsideClick) {
      ignoredTarget = document.getElementsByClassName(this.ignoredTargetOnOutsideClick)[0];
    }

    if (node &&
      !node.contains(evt.target as Node) &&
      !ignoredTarget?.contains(evt.target as Node) &&
      // TODO: validate. 
      // This is needed because some click event handlers might trigger the clicked element to be unmounted (see the speaker input group buttons)
      (evt.target as Node)?.isConnected &&
      this.closeOnOutsideClick !== false) {
      this.emit('outsideClick');
    }
  }

  private refProxyListener = (msg: Message) => {
    switch (msg.type) {
      case 'mounted':
        this.handleOpened();
        break;

      case 'unmounted':
        this.handleClosed();
        break;
    }
  }
}