import { Metadata, MetadataFilterInput } from '@clipr/lib';
import { isNonEmptyString, isValue } from '../core';

export function getCatalogMetadataFilterInput(metadataFilter: Partial<Metadata> | null): MetadataFilterInput[] | null {

  const metadata: MetadataFilterInput[] = [...Object.entries(metadataFilter ?? {})]
    .filter(([key, value]) => isValue(value))
    .map(([field, value]) => {
      return {
        field,
        value: value!
      }
    });

  if (metadata.length > 0)
    return metadata;

  return null;
}

export function getCatalogTagsFilterInput(tagsFilter: string[] | null): string[] | null {
  const tags = [...(tagsFilter ?? [])].filter(tag => isNonEmptyString(tag));
  if (tags.length > 0)
    return tags;
  return null;
}