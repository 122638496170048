import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { Button, DropdownInputControlled } from '../input';
import { IngestFromExternalLibraryWindowState } from '.';
import { getJobLevelInputLabel } from '../../entities/job/jobFeatures';
import { getJobSpecialityInputLabel } from '../../entities/job/jobSpeciality';
import { JobLevelInputTooltip, VideoSpecialtyInputTooltip } from '../../pages/uploadPage/uploadJobDetailsWindow';

type Props = {
  model: IngestFromExternalLibraryWindowState;
  visible?: boolean;
};

export const IngestFromExternalLibraryWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      model={model.window}
      visible={props.visible}
      isLoading={model.isLoading}>
      <WindowHeader model={model.window} title="Ingest Video" />

      <hr />

      <div className="window-content">
        <p className="p-bold">
          {model.multipleSelection
            ? 'Are you sure you want to ingest the selected videos?'
            : `Are you sure you want to ingest video ${
                model.videoTitle || ''
              } ?`}
        </p>
        <p>This action will start the video analysis.</p>

        <p>&nbsp;</p>

        <DropdownInputControlled
          label="Video Language"
          model={model.languageInput}
          notDeselectable={true}
        />

        <DropdownInputControlled
          label={getJobLevelInputLabel()}
          model={model.levelInput}
          tooltipContent={<JobLevelInputTooltip />}
          notDeselectable={true}
        />

        <DropdownInputControlled
          label={getJobSpecialityInputLabel()}
          model={model.specialityInput}
          disabled={model.isSpecialityInputDisabled}
          tooltipContent={<VideoSpecialtyInputTooltip />}
          notDeselectable={true}
        />

        <DropdownInputControlled
          label="Video Type"
          model={model.videoTypeInput}
          notDeselectable={true}
        />
      </div>

      <hr />

      <footer className="window-footer">
        <div className="btn-group right">
          <Button
            className="primary btn-outline medium"
            label="Cancel"
            disabled={model.isLoading}
            onClick={() => model.close()}
          />
          <Button
            className="green medium"
            label="Confirm"
            disabled={
              model.isLoading ||
              !model.languageInput.value ||
              model.form.isSubmitDisabled
            }
            onClick={() => model.submit()}
          />
        </div>
      </footer>
    </Window>
  );
});
