import React from 'react';

export const sanitizeHtml = (snippet: string) => {
  const regExp = /(<em>.+?<\/em>)/g;
  return <>{wrapTags(snippet, regExp)}</>;
};

export const wrapTags = (text: string, regex: RegExp) => {
  const textArray = text.split(regex);
  return (
    <>
      {textArray.map((str, index) => {
        if (regex.test(str)) {
          const node = document.createElement('DIV');
          node.innerHTML = str;
          return (
            <span key={index} className="highlighted">
              {node.textContent || node.innerText || ''}
            </span>
          );
        }
        return str;
      })}
    </>
  );
};
