import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { GalleryDisplay, GenerativeAiSettingsInput, Industry, Metadata, ResizedImage, Team as ApiTeamProps, TeamDisplayOptions, TeamJobFeatures, TeamProduct, TeamSpeakerSuggest, Theme, Vocabulary } from '@clipr/lib';
import identity from 'lodash/identity';
import { DateTime } from 'luxon';
import { Store } from '../store/store';
import { StoreNode } from '../store';
import { assertNotNull, AsyncResult, Nullable, Maybe } from '../core'
import { TeamMember, TeamMemberProps } from './teamMember';
import { ApiVariables } from '../api/apiSchema';
import { TeamInvitation, TeamInvitationProps } from './teamInvitation';
import { EnrichmentItem, EnrichmentItems } from './job/jobFeatures';
import { JobMetadataField } from './job/jobMetadata';

export const teamMemberAvatarSize = {
  width: 100,
  height: 100
}

export type TeamProps = ApiTeamProps;

export type TeamPermission =
  'CreateJob' | 'EditTeam' | 'ViewTeam' | 'RemoveJob' | 'ViewAnalytics' | 'ConfirmSpeakerPrediction' | 'AddJob';

type MemberType = TeamMember | TeamInvitation;

const roleOrder = ['owner', 'admin', 'trainer', 'member', 'viewer']

export const membersSorter = (a: MemberType, b: MemberType) => roleOrder.indexOf(a?.role?.toLowerCase()) - roleOrder.indexOf(b?.role?.toLowerCase());

export class Team
  extends StoreNode {

  constructor(store: Store, props?: TeamProps) {
    super(store, props);
    makeObservable(this);
    Object.assign(this, props);
  }

  readonly id!: string;
  readonly name!: string;
  readonly description!: string | null;
  readonly avatar?: ResizedImage[];
  readonly displayName?: string;
  readonly logo?: ResizedImage[];
  readonly cover?: ResizedImage[];
  readonly namespace?: string;
  readonly customRedirectUrl?: string | null;

  readonly ownerName!: string;
  readonly ownerEmail!: string;
  readonly uploadsCount!: number;
  readonly lastUploadAt!: string;

  readonly showCover?: boolean;
  readonly showLogo?: boolean;
  readonly displayOptions?: TeamDisplayOptions;
  readonly displaySortOrder?: boolean;

  readonly autoJoinOnSignup!: boolean | null;
  readonly isPublic!: boolean | null;
  readonly generativeAiSettings!: GenerativeAiSettingsInput;
  readonly sendVideoProcessedNotificationToAllMembers!: boolean | null;
  readonly membersCount!: number | null;
  readonly metadataFields!: Metadata | null;
  readonly adTagUrls!: string[] | null;
  readonly industry!: Industry | null;
  readonly teamProduct!: TeamProduct;

  readonly userPermissions!: ApiTeamProps['userPermissions'];
  readonly memberLookup = observable.map<string, TeamMember>();
  readonly invitationLookup = observable.map<string, TeamInvitation>();

  readonly jobFeatures: Maybe<TeamJobFeatures>;
  readonly speakerSuggest: Maybe<TeamSpeakerSuggest>;
  readonly vocabulary: Maybe<Vocabulary[]>
  readonly defaultVocabulary: Maybe<Vocabulary[]>;
  readonly publicSafety: Maybe<boolean>;

  @computed
  get publicSafetyActivated(): boolean {
    return this.publicSafety ?? false;
  }

  @computed
  get vocabularyAsText(): string | null {
    return this.vocabulary?.map(item => `${item.phrase} -> ${item.displayAs === ' ' ?  'Replaced with space' : item.displayAs}`).join('\n') ?? null;
  }

  @computed
  get defaultVocabularyPharses(): string[] | null {
    return this.defaultVocabulary?.map(item => item.phrase) ?? null;
  }

  @computed
  get pictureURL(): string | null {
    return (this.avatar &&
      this.avatar.length > 0 &&
      this.avatar[0]?.url) || null;
  }

  @computed
  get coverURL(): string | null {
    return (this.cover &&
      this.cover.length > 0 &&
      this.cover[0]?.url) || null;
  }

  @computed
  get logoURL(): string | null {
    return (this.logo &&
      this.logo.length > 0 &&
      this.logo[0]?.url) || null;
  }

  @computed get viewDisplayOption(): GalleryDisplay | undefined {
    return this.displayOptions?.galleryDisplay;
  }

  @computed get themeOption(): Theme | undefined {
    return this.displayOptions?.theme;
  }

  @computed get members() {
    return [...this.memberLookup.values()];
  }

  @computed get invitations() {
    return [...this.invitationLookup.values()].sort(membersSorter);
  }

  @computed get invitationsCount() {
    return this.invitationLookup.size;
  }

  @computed get displayMembers() {
    return [...this.members, ...this.invitations].sort(membersSorter);
  }

  @computed get lastUploadDate() {
    return DateTime.fromISO(this.lastUploadAt);
  }

  // @computed get isPublic() {
  //   return !!this.autoJoinOnSignup;
  // }

  @computed get chatGptSummaryEnabled() {
    return this.generativeAiSettings?.summarizationEnabled ?? false;
  }

  @computed get actionItemsExtractionEnabled() {
    return this.generativeAiSettings?.actionItemsExtractionEnabled ?? false;
  }

  @computed get cliprGptEnabled() {
    return this.generativeAiSettings?.cliprGptEnabled ?? false;
  }

  @computed get isSendVideoProcessedNotificationToAllMembers() {
    return this.sendVideoProcessedNotificationToAllMembers ?? false;
  }

  @computed get generativeAiProvider() {
    return this.generativeAiSettings?.provider ?? false;
  }
  
  @computed get isSpeakerSuggestEnabled(): boolean {
    return !!this.speakerSuggest?.enabled;
  }

  @computed get enrichmentLevel() {
    if (!this.jobFeatures)
      return null;

    for (let i = EnrichmentItems.length - 1; i >= 0; i--) {
      let key: EnrichmentItem = EnrichmentItems[i];

      if (this.jobFeatures[key] === true)
        return key;
    }

    return null;
  }

  @computed get visibleMetadataFields(): JobMetadataField[] | null {
    if (!this.metadataFields)
      return null;

    return Object.keys(this.metadataFields).map(key => {
      const metadataKey = key as keyof Metadata;
      return this.metadataFields?.[metadataKey] ? key : null;
    })
      .filter(identity) as JobMetadataField[];
  }

  getMetadataFieldAlias(field: keyof Metadata): string | null {
    if (!this.metadataFields)
      return null;

    return this.metadataFields[field] || null;
  }

  maybeGetMember(id: Maybe<string>): Nullable<TeamMember> {
    if (!id)
      return null;
    return this.memberLookup.get(id) || null;
  }

  getMember(id: string): TeamMember {
    assertNotNull(id);
    return this.memberLookup.get(id)!;
  }

  maybeGetInvitation(id: Maybe<string>): Nullable<TeamInvitation> {
    if (!id)
      return null;
    return this.invitationLookup.get(id) || null;
  }

  getInvitation(id: string): TeamInvitation {
    assertNotNull(id);
    return this.invitationLookup.get(id)!;
  }

  /** Gets the TeamMember entity which matches the current authenticated user. */
  @computed get currentUserMember(): Nullable<TeamMember> {
    return this.members.find(mem => mem.userId === this.store.user?.id) || null;
  }

  @action
  insertMember(props: TeamMemberProps) {
    const { user, ...restProps } = props;

    if (user)
      this.store.userManager.insertUser(user);

    const member = new TeamMember(this.store, restProps);
    this.memberLookup.set(member.id, member);
    return member;
  }

  @action
  removeMember(id: string) {
    this.memberLookup.delete(id);
  }

  async apiFetchMembers(args?: ApiVariables<'getTeamMembers'>): AsyncResult<TeamMember[]> {

    args = Object.assign({}, args, { id: this.id, avatarSize: teamMemberAvatarSize });

    const [membersRes, err] = await this.store.api.getTeamMembers(args);
    if (err)
      return [null, err];
    assertNotNull(membersRes);

    return runInAction(() => {
      this.memberLookup.clear();
      const membersArr = membersRes.getTeamMembers.edges.map(e => e.node);
      return [membersArr
        .map(member => this.insertMember(member))];
    });
  }

  async apiUpdateTeamMemberRole(args: ApiVariables<'updateTeamMemberRole'>): AsyncResult<TeamMember> {
    const [membersRes, err] = await this.store.api.updateTeamMemberRole(args);
    if (err)
      return [null, err];

    return runInAction(() => {
      const teamMember = this.insertMember(membersRes?.updateTeamMemberRole!);

      return [teamMember];
    });

  }

  async apiDeleteTeamMember(args: ApiVariables<'deleteTeamMember'>): AsyncResult<boolean> {
    const [, err] = await this.store.api.deleteTeamMember(args);
    if (err)
      return [null, err];

    // if (res)
    //   runInAction(() =>
    //     this.removeMember(res.deleteTeamMember?.userId))

    return [true];
  }

  @action
  insertInvitation(props: TeamInvitationProps) {
    const invitation = new TeamInvitation(this.store, props);

    this.invitationLookup.set(invitation.id, invitation);
    return invitation;
  }

  @action
  removeInvitation(id: string) {
    this.invitationLookup.delete(id);
  }

  async apiInviteTeamMember(args: ApiVariables<'inviteTeamMember'>): AsyncResult<TeamInvitation | TeamMember> {
    const [invitationProps, err] = await this.store.api.inviteTeamMember(args);
    if (err)
      return [null, err];

    const payload = invitationProps?.inviteTeamMember;

    if (!payload)
      return [null, new Error('No response returned for the invitation request')]

    const invitation = payload?.invitation && this.insertInvitation(payload.invitation);
    const member = payload?.member && this.insertMember(payload.member);

    if (invitation)
      return [invitation];

    if (member)
      return [member];

    return [null, new Error('The invitation didn\'t return a valid response.')]
  }

  async apiUpdateTeamInvitation(args: ApiVariables<'updateTeamInvitation'>): AsyncResult<TeamInvitation> {
    const [invitationRes, err] = await this.store.api.updateTeamInvitation(args);
    if (err)
      return [null, err];

    return runInAction(() => {
      const teamInvitation = this.insertInvitation(invitationRes?.updateTeamInvitation!);

      return [teamInvitation];
    });
  }

  async apiDeleteTeamInvitation(args: ApiVariables<'deleteTeamInvitation'>): AsyncResult<boolean> {
    const [, err] = await this.store.api.deleteTeamInvitation(args);
    if (err)
      return [null, err];

    // if (res)
    //   runInAction(() =>
    //     this.removeInvitation(res.deleteTeamInvitation.email))

    return [true];
  }

  async apiFetchInvitations(args?: ApiVariables<'getTeamInvitations'>): AsyncResult<TeamInvitation[]> {

    args = Object.assign({}, args, { id: this.id });

    const [invitationsRes, err] = await this.store.api.getTeamInvitations(args);
    if (err)
      return [null, err];
    assertNotNull(invitationsRes);

    return runInAction(() => {
      this.invitationLookup.clear();
      const invitationsArr = invitationsRes.getTeamInvitations.edges.map(e => e.node);
      const invitationEntityArr = invitationsArr
        .map(invitation => this.insertInvitation(invitation))
      return [invitationEntityArr];
    });
  }

  hasPermission(type: TeamPermission): boolean {
    const { userPermissions } = this;
    if (!userPermissions)
      return false;

    switch (type) {
      case 'AddJob':
        return userPermissions.includes('AddJob');
      case 'CreateJob':
        return userPermissions.includes('CreateJob');
      case 'EditTeam':
        return userPermissions.includes('EditTeam');
      case 'ViewTeam':
        return userPermissions.includes('ViewTeam');
      case 'RemoveJob':
        return userPermissions.includes('RemoveJob');
      case 'ViewAnalytics':
        return userPermissions.includes('ViewAnalytics');
      case 'ConfirmSpeakerPrediction':
        return userPermissions.includes('ConfirmSpeakerPrediction');
      default:
        return false;
    }
  }

}
