import { AddedCommentDocument, WatchMode } from '@clipr/lib';
import { action, computed, makeObservable, observable } from 'mobx';
import { WSClient } from '../../api/apiWSClient';
import { isValidUrl } from '../../core/urlUtils';
import { Comment, Reaction } from '../../entities/comment';

import { Store } from '../../store/store';
import { StoreNode } from '../../store/storeNode';

const WS_LOGS = false;

const GRAPHQL_CLIENT_WS_ENDPOINT = process.env.REACT_APP_API_WS_ENDPOINT;
const GRAPHQL_CLIENT_APPSYNC_ENDPOINT = process.env.REACT_APP_API_APPSYNC_ENDPOINT;
const API_KEY = process.env.REACT_APP_API_PUBLIC_KEY;

export class PlayerApiClient
  extends StoreNode {

  readonly store: Store;

  constructor(store: Store, props?: any) {
    super(store, props);
    makeObservable(this);
    this.store = store;
  }

  readonly nodeType = 'UserPlayerApiClient';

  @observable client: WSClient | null = null;

  @computed
  get authToken(): string | null {
    return this.store.auth.permit?.idToken ?? null;
  }

  @computed
  get apiKey(): string | null {
    return API_KEY ?? null;
  }

  @computed
  get isPublic(): boolean {
    return !this.authToken;
  }

  @computed
  get endpoint(): string | null {
    return GRAPHQL_CLIENT_WS_ENDPOINT ?? null;
  }

  @computed
  get host(): string | null {
    return GRAPHQL_CLIENT_APPSYNC_ENDPOINT?.replace('https://', '').replace('/graphql', '') ?? null
  }

  @computed
  get httpsUri(): string | null {
    const { endpoint } = this;
    const origin = window.location.origin;

    if (!endpoint)
      return null;

    if (isValidUrl(endpoint))
      return endpoint;
    else
      return origin + endpoint;
  }

  @computed
  get wsUri(): string | null {
    let wsUri = this.httpsUri;
    return wsUri?.replace('https', 'wss') ?? null; //.replace('appsync', 'appsync-realtime') || null;
  }

  @computed
  get authorization() {
    return {
      Authorization: this.authToken ?? 'public',
      host: this.host,
    }
  }

  @action
  onConnectionError = () => {
    WS_LOGS && console.log('ws connection error');
  }

  @action
  onConnectionOpen = () => {
    WS_LOGS && console.log('ws connection open');
  }

  @action
  onStart = () => {
    WS_LOGS && console.log('ws start');
    //TODO: should fetch the data that might be missed during a timeout - should determine based on the document
  }

  @action
  onData = (data: any) => {
    WS_LOGS && console.log('WS Data received', data);
    Object.keys(data).forEach(key => {
      const value = data[key];
      switch (key) {
        case 'addedComment':
          if (!value)
            return;

          const job = this.store.maybeGetJob(value.jobId);
          if (value.reaction) { // TEMP: for filtering out comments;
            let reaction;
            if (value.watchMode === WatchMode.Live)
              reaction = new Comment(this.store, value) as Reaction;
            else if (value.watchMode === WatchMode.Static) {
              reaction = job?.insertReaction(value) || null;
              job?.setAddedReaction(reaction);
            }

            this.broadcast('addedReaction', { reaction });
            WS_LOGS && console.log(reaction);
          } //else {
          //   const comment = job?.insertComment(value) || null;
          //   console.log(comment);
          //   this.broadcast('addedComment', { comment });
          // }
          break;
      }
    })
  }

  @action
  onError = () => {
    WS_LOGS && console.log('ws error');
  }

  @action
  onComplete = () => {
    WS_LOGS && console.log('ws complete');
  }

  @action
  ensureClient = async () => {
    if (!this.client)
      await this.initClient();
  }

  private async initClient() {
    if (!this.httpsUri || !this.host)
      return;

    this.client = new WSClient({
      endpoint: this.httpsUri,
      host: this.host,
      token: this.authToken,
      key: this.apiKey!,
      onConnectionError: this.onConnectionError,
      onConnectionOpened: this.onConnectionOpen,
      onStart: this.onStart,
      onData: this.onData,
      onError: this.onError,
    });
  }

  @action
  async subscribeToAddReactions(args: any) {
    await this.ensureClient();
    await this.client?.open();
    await this.client?.subscribe(
      AddedCommentDocument,
      { jobId: args.jobId }
    );
  }

  async unsubscribe() {
    await this.client?.close();
  }

}
