import React from 'react';
import { observer } from 'mobx-react-lite';
import { TeamSettingsPageState } from '../../pages/teamSettingsPage/teamSettingsPageState';
import { FilterFieldButton } from '../input';
import { MenuItem } from '../input/menuItems';
import { TeamMemberRole } from '@clipr/lib';
import { SortButton } from './sortButton';

type Props = {
  model: TeamSettingsPageState;
};

export const TeamInvitesHeader = observer(({ model }: Props) => {
  const { teamInviteCatalogSource } = model;

  const filterByRoleValue = teamInviteCatalogSource.getFilterFieldValue('role');

  return (
    <div className="team-invites-catalog-header catalog-filters">
      <table className="team-invites-core">
        <thead>
          <tr>
            <th className="email-col">
              <SortButton label="Email" sortField="email" model={model} />
            </th>

            <th className="role-col">
              <FilterFieldButton
                label={
                  filterByRoleValue ?
                    filterByRoleValue :
                    'Role'
                }
                selected={!!filterByRoleValue}>
                {Object.values(TeamMemberRole).map((type) => (
                  <MenuItem
                    key={type}
                    model={{ value: type, label: type }}
                    onClick={() =>
                      teamInviteCatalogSource.setFilterFieldValue('role', type)
                    }
                    isSelected={
                      filterByRoleValue === type
                    }
                  />
                ))}
              </FilterFieldButton>
            </th>
          </tr>
        </thead>
      </table>
    </div>
  );
});
