import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { Maybe } from '../../../core';
import { TextInputErrorSVGIcon, TextInputWarningSVGIcon } from '../../svg';

export type ChartOverlayMessageProps = {
  code?: Maybe<string>;
  message?: Maybe<string>;
  showMessage?: boolean;
  showIcon?: boolean;
}

export const ChartOverlayMessage = observer((props: ChartOverlayMessageProps) => {
  const { 
    code, 
    message, 
    showMessage = true,
    showIcon = true
  } = props;

  let messageIcon: ReactNode;
  let defaultMessage: string;

  const cx = classNames('overlay-message', {
    error: code === 'Error'
  });

  switch (code) {
    case 'Error':
      defaultMessage = 'Unable to get data from server';
      messageIcon = <TextInputErrorSVGIcon />;
      break;

    default:
    case 'NoData':
      defaultMessage = 'No data to display';
      messageIcon = <TextInputWarningSVGIcon />;
      break;
  }

  return <div className={cx}>
    {showIcon && messageIcon}
    {showMessage && <p><b>{message || defaultMessage}</b></p>}
  </div>;
});
