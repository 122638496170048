import React from 'react';
import './playerTutorial.scss';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { PlayerTutorialTopicsWindowState } from './playerTutorialTopicsWindowState';

type Props = {
  model: PlayerTutorialTopicsWindowState;
  visible?: boolean;
};
export const PlayerTutorialTopicsWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      className="topics-tutorial-window"
      model={model.window}
      visible={props.visible}>

      <WindowHeader
        model={model.window}
        title="HELP" />

      <hr></hr>

      <section className="window-content">
        <ul>
          {model.steps?.map((step: any) => {
            return <li
              key={step.title}
              onClick={() => model.initTutorial(step.pageNumber)}>{step.title}</li>
          })}
        </ul>
      </section>
    </Window>
  )
});