import { LibraryName } from './librarySchema';

export type LibraryPermitData = {
  code: string;
  libraryName: LibraryName;
}

export class LibraryPermit {

  constructor(data: LibraryPermitData) {
    this.libraryName = data.libraryName;
    this.code = data.code;
  }

  readonly libraryName: LibraryName;
  readonly code: string;
}