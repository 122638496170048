import './momentCloud.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { cssPixel, HTMLDivProps } from '../../core';

type Props = HTMLDivProps<{
  x1: number,
  y1: number,
  x2: number,
  y2: number
}>

function getStyle(x1: number, y1: number, x2: number, y2: number) {
  
  const dx = x2 - x1;
  const dy = y2 - y1;

  const width = Math.sqrt(
    Math.pow(dx, 2) +
    Math.pow(dy, 2));

  const rotate = Math.atan2(dy, dx) * 180 / Math.PI;
  return {
    'width': cssPixel(width),
    'transform': `translate(${cssPixel(x1)}, ${cssPixel(y1)}) rotate(${rotate}deg)`
  } as React.CSSProperties;
}

export const EdgeLine = observer(({
  x1,
  y1,
  x2,
  y2,
  ...props
}: Props) => {

  return (
    <div style={getStyle(x1, y1, x2, y2)} {...props} />
  );
});