import { action, computed, makeObservable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { Error } from '../../core/error';
import { LibraryProvider } from '../../services/libraries';
import { UploadManager } from '../../entities/uploadManager';
import { Team } from '../../entities';
import { RouteContext } from '../../routes/routeContext';
import { WidgetState } from '../widgetStateMixin';

import { INIT_DEBUGGER, TRACE } from '../../core/debug/debugMacros';

export class UploadWidgetState
  extends WidgetState(StoreNode) {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    INIT_DEBUGGER(this, {
      color: 'deeppink'
    });
  }

  readonly nodeType = 'UploadWidget';

  readonly uploadManager: UploadManager = new UploadManager(this.store);

  @computed get team(): Team | null {
    return this.store.teamManager.getTeam(this.teamId);
  }

  @computed get teamId(): string | null {
    return this.store.widgetService.teamId;
  }

  /** Notifies the state that the page has been mounted and triggers the initialization tasks. */
  @action
  async mounted(routeContext: RouteContext) {
    TRACE(this, `attached()`, { routeContext }, '\n', this.__traceState);

    this.reset();
    this.baseAttached(routeContext);

    this.emit('Mounted', {
      teamId: this.teamId
    });

    this.setLoading();

    const { uploadService } = this.store;

    const { teamId } = this;
    if (teamId)
      this.uploadManager.setTeamId(teamId);

    if (!this.team && teamId) {
      const [team, err] = await this.store.teamManager.apiFetchTeam({
        id: teamId
      });

      if (err || !team)
        return this.setError(new Error('ApiError', 'Failed to get library information.'));
    }

    uploadService.openLibraryUploadWindowOnMount();

    this.setLoaded();
  }

  /** Notifies the state that the page has been unmounted and triggers the cleanup tasks. */
  @action
  unmounted() {
    TRACE(this, `detached()`, this.__traceState);

    this.baseDetached();
    this.reset();

    this.emit('Unmounted');
  }

  @action
  reset() {
    this.baseReset();
  }

  @action
  async authorizeLibrary(library: LibraryProvider) {
    const { routeContext } = this;
    if (!routeContext)
      return [null, new Error('InternalError', `No route context.`)];

    const [res, err] = await this.store.authService.runProxyAuthorizeLibraryFlow({
      routeContext,
      libraryName: library.libraryName
    });

    if (err)
      return [null, err];
    return [res];
  }

  private get __traceState() {
    return {
      ...this.__baseTraceState
    }
  }
}