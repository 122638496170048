import toInteger from 'lodash/toInteger';
import { Comment } from '../comment';

/**
 * @param comments The input comments array, sorted in ascending order.
 */
export const lastActiveComment = <T extends Comment>(comments: T[], time: number): T | null => {
  // iterate backwards to short circuit early
  for (let i = comments.length - 1; i >= 0; i--) {
    const comment = comments[i];

    if (!Number.isFinite(comment?.videoTime))
      continue;

    if (time >= toInteger(comment.videoTime) &&
      time <= (toInteger(comment.videoTime) + 5))
      return comment;
  }
  return null;
}

/**
 * @param comments The input comments array, sorted in ascending order.
 */
export const firstActiveComment = <T extends Comment>(comments: T[], time: number): T | null => {
  let resComment = null;
  // iterate backwards to short circuit early
  for (let i = comments.length - 1; i >= 0; i--) {
    const comment = comments[i];

    if (!Number.isFinite(comment?.videoTime))
      continue;

    if (time >= toInteger(comment.videoTime) &&
      time <= (toInteger(comment.videoTime) + 5))
      if (!resComment ||
        (resComment && resComment.videoTime! === comment.videoTime!))
        resComment = comment;
  }
  return resComment;
}


/**
 * @param comments The input comments array, sorted in ascending order.
 */
export const allActiveComments = <T extends Comment>(comments: T[], time: number): T[] => {
  let resComment = [];
  // iterate backwards to short circuit early
  for (let i = comments.length - 1; i >= 0; i--) {
    const comment = comments[i];

    if (!Number.isFinite(comment?.videoTime))
      continue;

    if (time >= toInteger(comment.videoTime) &&
      time <= (toInteger(comment.videoTime) + 5)) {
      resComment.push(comment);
    }
  }
  return resComment;
}

// /**
//  * @param comments The input comments array, sorted in ascending order.
//  */
// export const activeComments = <T extends Comment>(comments: T[], time: number): T[] => {
//   const resArr = [];
//   // iterate backwards to short circuit early
//   for (let i = comments.length - 1; i >= 0; i--) {
//     const comment = comments[i];
//     const refComment = resArr[0] ?? null;

//     if (!Number.isFinite(comment?.videoTime))
//       continue;

//     if (time >= toInteger(comment.videoTime) &&
//       time <= (toInteger(comment.videoTime) + 5))
//       if (!refComment || refComment?.videoTime! === comment.videoTime!)
//         resArr.push(comment);
//   }
//   return resArr;
// }