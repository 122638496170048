import './media.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Catalog, Grid } from '../layout';
import { CatalogState } from '../layout/catalogState';
import { CatalogFooter } from '../layout/catalogFooter';
import { ZoomSVGIcon } from '../svg';
import { ZoomCatalogSource } from '../../entities';
import { LibraryCard } from './libraryCard';

type Props = {
  model: CatalogState;
  source: ZoomCatalogSource;
};

export const ZoomCatalog = observer(({ model, source }: Props) => {
  const { entities } = source;

  return (
    <Catalog className="job-card-catalog catalog" model={model}>
      {entities.length > 0 && (
        <>
          <Grid className="catalog-grid">
            {entities.map((externalFile) => {
              return (
                <li key={externalFile.id} className="grid-item catalog-item">
                  <LibraryCard
                    model={externalFile}
                    source={source}
                    view={'zoom'}
                  />
                </li>
              );
            })}
          </Grid>

          <CatalogFooter model={model} />
        </>
      )}

      {entities.length === 0 && (
        <div className="empty-box" aria-hidden={model.isLoading}>
          <div className="catalog empty-catalog">
            <div className="icon">
              <ZoomSVGIcon />
            </div>
            <h1 className="headline-1">
              Looks like you don't have any recordings for the selected date
              range.
            </h1>
          </div>
        </div>
      )}
    </Catalog>
  );
});
