import { action, makeObservable, observable, runInAction } from 'mobx';
import { input, inputGroup } from '../../../components/input';
import { assertNotNull, checked, Maybe, MaybeProps, Nullable } from '../../../core';
import { AuthError, ResendOnboardLinkReply } from '../../../services/auth';
import { ResendOnboardLinkInput } from '../../../services/auth/authSchema';
import { assertValidResendOnboardLinkInput } from '../../../services/auth/authUtils';
import { StoreNode } from '../../../store';
import { Store } from '../../../store/store';

export class LinkExpiredFormState
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  @observable username: Maybe<string> = null;

  @observable reply: Nullable<ResendOnboardLinkReply> = null;
  @observable error: Nullable<AuthError> = null;

  readonly formGroup = inputGroup(this, {
    name: 'linkExpiredForm',
    inputs: () => [
      this.submitButton
    ],
    error: () => {
      switch (this.error?.type) {
        case 'UsernameNotFound':
          return `Cannot resend link because the destination email is invalid.`;

        case 'NetworkError':
          return 'Cannot resend link because of a network error.';
        case 'ProviderError':
          return 'Cannot resend link because of a provider error.';

        default:
          if (this.error) {
            return 'Cannot resend link because of an unknown error';
          }
      }
    },
  });

  // #region Submit
  readonly submitButton = input(this, {
    name: 'submit'
  });

  @action
  handleSubmit = async (evt: React.FormEvent) => {
    evt.preventDefault();

    this.error = null;
    this.formGroup.handleSubmit();
    if (this.formGroup.error) {
      this.formGroup.handleSubmitReject();
      return false;
    }

    const input: MaybeProps<ResendOnboardLinkInput> = {
      username: this.username
    }

    assertValidResendOnboardLinkInput(input);

    const { auth } = this.store;
    const [rep, err] = await checked(() =>
      auth.resendOnboardLink(input));

    if (err) {
      runInAction(() =>
        this.error = err);
      this.formGroup.handleSubmitReject();
      return false;
    }

    assertNotNull(rep);

    runInAction(() =>
      this.reply = rep);
    this.formGroup.handleSubmitResolve();
    return true;
  }

  @action
  loadUsername(val: string) {
    this.username = val;
  }

  @action
  handleResubmit = async (evt: React.FormEvent) => {
    evt.preventDefault();
    this.reset();
    await this.handleSubmit(evt);
  }
  // #endregion

  // #region Reset
  @action
  clearError() {
    this.error = null;
  }

  @action
  reset() {
    this.formGroup.reset();
    this.error = null;
    this.reply = null;
  }
  // #endregion
}