import { action, makeObservable, observable } from "mobx";
import { Message, StoreNode } from '../../store';
import { Store } from "../../store/store";
import { AnalyticsViewContextData } from "./stream";

/**
 * Class that keeps track of the viewing context of a team and the window location.
 */
export class ViewContext extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  @observable teamId: string | null = null;

  init() {
    this.store.teamDashboardPage.listen(this.listener);
    this.store.uploadPage.listen(this.listener);
    this.store.userPlayerPage.listen(this.listener);
    this.store.teamLibraryWidget.listen(this.listener);
    this.store.playerWidget.listen(this.listener);
    this.store.uploadCompactWidget.listen(this.listener);
    this.store.uploadWidget.listen(this.listener);
  }

  attachPlayerAnalyzerListener() {
    this.store.analytics.playerAnalyzer?.listen(this.listener);
  }

  detachPlayerAnalyzerListener() {
    this.store.analytics.playerAnalyzer?.unlisten(this.listener);
  }

  private listener = (msg: Message) => {
    const { type, payload, sender } = msg;

    switch (type) {
      case 'Mounted':
        this.setTeamId(payload.teamId ?? null);
        break;
      case 'Unmounted': {
        if (sender !== this.store.playerWidget && sender !== this.store.userPlayerPage) {
          this.clearTeamId();
        }
        break;
      }
      default:
        break;
    }
  }

  @action
  private setTeamId(teamId: string | null) {
    this.teamId = teamId;
  }

  @action
  private clearTeamId() {
    this.teamId = null;
  }

  export(): AnalyticsViewContextData {
    return {
      context: {
        teamAnalyticsExclude: this.store.user?.teamAnalyticsExclude,
        team: {
          id: this.teamId
        }
      }
    }
  }
}
