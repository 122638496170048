import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '../input';
import { DownloadJobSlidesWindowState } from './downloadJobSlidesWindowState';
import { FileDownloadLink } from '../download/fileDownloadLink';
import { Window, WindowHeader } from '../overlays';

type Props = {
  model: DownloadJobSlidesWindowState;
  visible?: boolean;
};

export const DownloadJobSlidesWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      model={model.window}
      visible={props.visible}
      isLoading={model.isLoading}>
      <WindowHeader model={model.window} title="Download Video Slides" />

      <hr />

      <div className="window-content">
        <p className="p-bold">Are you sure you want to download the video slides?</p>
      </div>

      <hr />

      <footer className="window-footer">
        <div className="btn-group right">
          <Button
            className="primary btn-outline medium"
            label="Cancel"
            onClick={() => model.close()}
          />
          <Button
            className="red medium"
            label="Download"
            onClick={() => model.submit()}
          />
        </div>
      </footer>

      {model.downloadUrl &&
        <FileDownloadLink
          actionUrl={model.downloadUrl}
          onClick={() => model.handleSubmitFinally()}
        />}
    </Window>
  );
});
