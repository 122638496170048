import React from 'react';
import { observer } from 'mobx-react-lite';
import { JobModel } from '../../entities';
import { GoogleDriveSVGIcon, OneDriveSVGIcon, ZoomSVGIcon } from '../svg';

export const LibraryTag = observer(({ model }: { model: JobModel }) => {
  if (model.source.type !== 'ExternalLibrary') return null;

  const source = model.source.externalLibrary?.source;

  switch (source) {
    case 'GoogleDrive':
      return (
        <div className="external-library-tag">
          <GoogleDriveSVGIcon />
          <span>Drive</span>
        </div>
      );
    case 'OneDrive':
      return (
        <div className="external-library-tag">
          <OneDriveSVGIcon />
          <span>Drive</span>
        </div>
      );
    case 'Zoom':
      return (
        <div className="external-library-tag">
          <ZoomSVGIcon />
          <span>Zoom</span>
        </div>
      );
    default:
      return null;
  }
});
