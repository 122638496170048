import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, ButtonControlled, TextInputControlled } from '../input';
import { BookmarkListWindowState } from './bookmarkListWindowState';
import { Window, WindowHeader } from '../overlays';

type Props = {
  model: BookmarkListWindowState;
  visible?: boolean;
};

export const BookmarkListWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      name="bookmarkListForm"
      className="bookmark-window"
      model={model.window}
      visible={props.visible}
      isLoading={model.isLoading}>
      <WindowHeader
        model={model.window}
        title={model.mode === 'edit' ? 'Edit list' : 'New list'}
      />

      <hr />

      <div className="window-content">
        <TextInputControlled
          label="List name"
          placeholder="Enter bookmark list name"
          persistentLabel={true}
          persistentFeedback={true}
          feedbackPosition="bottom"
          maxLength={100}
          showFeedback
          showStatus
          statusMessage={
            model.nameInput.status === 'error' &&
              model.nameInput.showStatusIcons
              ? model.nameInput.error?.toString()
              : 'Max 100 characters'
          }
          model={model.nameInput}
        />
      </div>

      <hr />

      <footer className="window-footer">
        <div className="btn-group right">
          <Button
            className="primary btn-outline medium"
            label="Cancel"
            onClick={() => model.cancel()}
          />

          <ButtonControlled
            model={model.saveButton}
            className="primary medium"
            label="Save"
            onClick={() => model.submit()}
          />
        </div>
      </footer>
    </Window>
  );
});
