import { Metadata, Order } from '@clipr/lib';
import { Theme, View } from '../ui/utils';
import { WidgetAuthMode } from './widgetSchema';
import { ParamsSchema, paramsSchema } from '../../entities/params';
import { NullableProps } from '../../core';

export type WidgetRouteParams = {
  jobId?: string | null,
  teamId?: string | null
}

export type WidgetParams = {
  authMode: WidgetAuthMode | null;
  authConnection: string | null;
  autoLogin: boolean | null;
  token: string | null;

  customRedirect: string | null;
  allowShare: boolean;
  allowDownload: boolean;

  meta: Metadata | null;
  tags: string[] | null;
  theme: Theme | null;
  sort: Order | null;
  view: View | null;
  logo: string | null;
  showTeamName: boolean;
  showSourceBadge: boolean;

  allowFullscreen: boolean;
  time: number | null;
  autoplay: boolean | null;
  momentId: string | null;
  source: string | null;
  teamId: string | null;
  showIndex: boolean | null;
  showComments: boolean | null;
  showTranscript: boolean | null;
  showProfile: boolean;
  showTopicTags: boolean;
  showEmbedIcon: boolean;
  showHelp: boolean;
  disableReactions: boolean;
  disableComments: boolean;
  disableTranscript: boolean;
  disableIndex: boolean;
};

export type RequestedWidgetParams = NullableProps<WidgetParams>;

export const WidgetParamsSchema = paramsSchema()

  .enum('authMode', { enum: WidgetAuthMode, aliasKeys: ['auth'] })
  .string('authConnection', { aliasKeys: ['auth_connection'] })
  .boolean('autoLogin', { defaultValue: true })
  .string('token')

  .string('customRedirect')
  .boolean('allowShare', { defaultValue: true })
  .boolean('allowDownload', { defaultValue: true })

  .string('meta.startTime')
  .string('meta.endTime')
  .string('meta.session')
  .string('meta.track')
  .string('meta.level')
  .string('meta.featured')
  .string('meta.location')
  .string('meta.publishDate')
  .string('tags', { isArray: true })
  .enum('theme', { enum: Theme })
  .enum('view', { enum: View })
  .enum('sort', { enum: Order })
  .string('logo')
  .boolean('showTeamName', { defaultValue: true })
  .boolean('showSourceBadge', { defaultValue: true })

  .boolean('allowFullscreen', { defaultValue: true })
  .float('time', { aliasKeys: ['t'] })
  .boolean('autoplay', { aliasKeys: ['ap'] })
  .string('source')
  .string('momentId', { aliasKeys: ['moment_id'] })
  .string('teamId')
  .boolean('showIndex')
  .boolean('showComments')
  .boolean('showTranscript')
  .boolean('showProfile', { defaultValue: true })
  .boolean('showTopicTags', { defaultValue: true })
  .boolean('showEmbedIcon', { defaultValue: true })
  .boolean('showHelp', { defaultValue: true })
  .boolean('disableReactions', { defaultValue: false })
  .boolean('disableComments', { defaultValue: false })
  .boolean('disableTranscript', { defaultValue: false })
  .boolean('disableIndex', { defaultValue: false })

  .schema as ParamsSchema<WidgetParams>;