import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { DataContext } from '@visx/xychart';
import { Legend, LegendItem, LegendLabel } from '@visx/legend';
import { scaleOrdinal, ScaleTypeToD3Scale } from '@visx/scale';
import classNames from 'classnames';
import { GlyphProps } from '../xySeriesChart';

export type ChartLegendProps = {
  showLabel?: Boolean;
  showGlyph?: Boolean;
  showLineColor?: Boolean;
  toggleData?: Boolean;
  legendItems?: LegendItemProps[];
  onClickLegendItem?: (dataKey: string) => void;
};

export type LegendItemProps = {
  dataKey: string;
  glyph?: GlyphProps;
  color?: string;
};

type ColorScale = ScaleTypeToD3Scale<string, string>['ordinal'] | undefined

export const ChartLegend = observer((props: ChartLegendProps) => {
  const {
    legendItems,
    showGlyph,
    showLabel,
    showLineColor,
    toggleData,
    onClickLegendItem,
  } = props;
  const chartContext = useContext(DataContext);
  const { colorScale: initialColorScale, dataRegistry } = chartContext;
  let colorScale: ColorScale = initialColorScale;

  const legendGlyphSize = 15;

  const shapeScale = scaleOrdinal<string, React.FC | GlyphProps>({
    domain: legendItems?.map(({ dataKey }) => dataKey),
    range: legendItems?.map(({ glyph }) => glyph!),
  });

  const colors: string[] = legendItems?.map(({ color }) => color!).filter(Boolean) || [];

  if (colors?.length > 0) {
    colorScale = scaleOrdinal<string, string>({
      domain: legendItems?.map(({ dataKey }) => dataKey),
      range: colors,
    });
  }

  const handleLegendItemClick = (dataKey: string) => {
    if (!(toggleData && onClickLegendItem)) 
      return;

    onClickLegendItem(dataKey);
  };

  return (
    <Legend scale={shapeScale}>
      {(labels) => (
        <div className="legend-items">
          {labels.map((label, i) => {
            const { datum } = label;
            const color = colorScale?.(datum) ?? '';
            const CustomLegendIcon = shapeScale(datum);
            const itemClassName = classNames('visx-legend-item', 'legend-item', {
              'active-legend-item': !!dataRegistry?.get(datum),
              pointer: toggleData,
            })

            return (
              <LegendItem
                key={`legend-quantile-${i}`}
                margin="0 2rem 0 0"
                flexDirection="row"
                className={itemClassName}
                onClick={() => handleLegendItemClick(datum)}>
                {showGlyph && CustomLegendIcon &&
                  <CustomLegendIcon
                    color={color}
                    width={legendGlyphSize}
                    height={legendGlyphSize} />}

                {showLabel && (
                  <LegendLabel
                    align="left"
                    margin="0 0 0 5px"
                    className="visx-legend-label legend-label">
                    {label.text}
                  </LegendLabel>
                )}

                {showLineColor && (
                  <span
                    className="legend-line-color"
                    style={{ backgroundColor: color }}>
                  </span>
                )}
              </LegendItem>
            );
          })}
        </div>
      )}
    </Legend>
  );
});
