
import './dateInput.scss';
import moment from 'moment';
import React from 'react';
import { RangeOptions } from './dateInput';

type DateInputFooterProps = {
  validationMessage?: string;
  options?: RangeOptions[];
  value?: [moment.Moment, moment.Moment] | moment.Moment;
  activeRangeOption?: RangeOptions;
  isRangePicker?: boolean;
  setActiveRange?: (activeRange: RangeOptions) => void;
  onApply?: () => void;
  handleChange?: (dates: any, dateStrings: [string, string] | string) => void;
  cancel?: () => void;
  apply?: () => void;
}

export const DateInputFooter = ({ cancel, apply, validationMessage, options, value, activeRangeOption, onApply, setActiveRange, isRangePicker }: DateInputFooterProps) => {

  const setRange = (option: RangeOptions) => {
    if (setActiveRange) setActiveRange(option);
  }

  const applyHanlder = () => {
    if (apply) apply();
    if (onApply) onApply()
  }

  return (
    <div className="datepicker-footer">
      <div className="select-period">
        {isRangePicker && options && options?.length > 0 && options.map((option: RangeOptions) =>
          <button
            className={`${option.name === activeRangeOption?.name ? 'active' : ''} period`}
            key={option.name}
            onClick={() => setRange(option)}>
            {option.name}
          </button>
        )}
      </div>

      <div className="confirmation">

        {validationMessage &&
          <span className="message">
            {validationMessage}
          </span>}

        <div className="buttons">
          {isRangePicker && value &&
            <div className="range">
              {`${moment((value as [moment.Moment, moment.Moment])[0]).format('DD/MM/YYYY')}-${moment((value as [moment.Moment, moment.Moment])[1]).format('DD/MM/YYYY')}`}
            </div>}

          <button className="cancel" onClick={cancel}>Cancel</button>
          <button className="apply" onClick={applyHanlder}>Apply</button>
        </div>

      </div>
    </div>
  )
}