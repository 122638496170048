import { action, computed, makeObservable, observable } from 'mobx';
import identity from 'lodash/identity';
import max from 'lodash/max';
import min from 'lodash/min';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { JobModel, MomentModel, MomentSelector, MomentSelectorMode, Speaker, SpeakerModel, SubTopic, Topic } from '../../entities';
import { areConnected } from '../../entities/moments/momentFilters';
import { CloudNode } from './cloudNode';
import { CloudEdge } from './cloudEdge';

type Props = BindingProps<{
  jobId: string,
  selector: MomentSelector
}>

export class CloudGraph
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  @computed
  get jobId(): string | null {
    return this.resolvedProps.jobId || null;
  }

  @computed
  get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed
  get selector(): MomentSelector | null {
    return this.getResolvedProp('selector');
  }

  @computed
  get mode(): MomentSelectorMode | null {
    return this.selector?.mode || null;
  }

  @computed
  get nodes(): CloudNode[] {
    return [
      ...this.speakerNodes,
      ...this.subTopicNodes,
      ...this.topicNodes
    ];
  }

  @computed
  get minSpeakerDuration() {
    return min(this.speakerNodes.map(spkNode => spkNode.duration)) || 0;
  }

  @computed
  get maxSpeakerDuration(): number {
    return max(this.speakerNodes.map(spkNode => spkNode.duration)) || 0;
  }

  @computed
  get minTopicDuration() {
    return min(this.topicNodes.map(topNode => topNode.duration)) || 0;
  }

  @computed
  get maxTopicDuration(): number {
    return max(this.topicNodes.map(topNode => topNode.duration)) || 0;
  }


  @computed
  get edges(): CloudEdge[] {
    return [
      ...this.topicSpeakerEdges
    ]
  }

  @computed
  get speakerNodes(): CloudNode<'Speaker'>[] {
    const { store, selector } = this;
    return this.job?.speakers
      .map(spk => {
        return new CloudNode(store, {
          model: spk,
          graph: this,
          isSelected: () => selector?.isSpeakerSelected(spk),
          isFiltered: () => selector?.isSpeakerFiltered(spk)
        });
      }) || [];
  }

  @computed
  get topicNodes(): CloudNode<'Topic'>[] {

    const { store, selector } = this;
    return this.job?.topicMoments
      .filter(top => top.visibleToConsumer && (!!top.name || !!top.description))
      .map(top => {

        return new CloudNode(store, {
          model: top,
          graph: this,
          isSelected: () => selector?.isTopicSelected(top),
          isFiltered: () => selector?.isTopicFiltered(top)
        });
      }) || [];
  }

  @computed
  get subTopicNodes(): CloudNode<'SubTopic'>[] {

    const { store, selector } = this;
    return this.job?.subtopicMoments
      .filter(sub => sub.visibleToConsumer && (!!sub.name || !!sub.description))
      .map(sub => {

        return new CloudNode(store, {
          model: sub,
          graph: this,
          isSelected: () => selector?.isSubTopicSelected(sub),
          isFiltered: () => selector?.isSubTopicFiltered(sub)
        });
      }) || [];
  }

  @computed
  get topicSpeakerEdges(): CloudEdge[] {

    return this.topicNodes
      .map(topNode => {

        return this.speakerNodes
          .filter(spkNode => topNode.isConnectedToSpeaker(spkNode.key))
          .map(spkNode => {

            return new CloudEdge(this.store, {
              sourceId: topNode.key,
              targetId: spkNode.key,
              graph: this
            });
          });
      })
      .flat() || [];
  }

  @computed
  get visibleNodes(): CloudNode[] {
    return [
      ...this.topicNodes,
      ...this.speakerNodes
    ].filter(node => !!node && node.isVisible);
  }

  @computed
  get visibleEdges(): CloudEdge[] {
    return [
      ...this.topicSpeakerEdges
    ].filter(edge => !!edge && !!edge.source && !!edge.target);
  }


  @computed
  get selectedNodes(): CloudNode[] {
    return this.nodes.filter(node => node.isSelected);
  }

  @computed get selectedTopicNodes(): CloudNode<'Topic'>[] {
    return this.selectedNodes
      .filter(node => node.modelType === 'Topic');
  }
  @computed get selectedTopicIds(): Set<string> {
    return new Set(this.selectedTopicNodes
      .map(node => node.key));
  }
  @computed get selectedTopics(): MomentModel[] {
    return [...this.selectedTopicIds]
      .map(id => this.job?.maybeGetMoment(id)!)
      .filter(identity);
  }

  @computed get selectedSubTopicNodes(): CloudNode<'Topic'>[] {
    return this.selectedNodes
      .filter(node => node.modelType === 'SubTopic');
  }
  @computed get selectedSubTopicIds(): Set<string> {
    return new Set(this.selectedNodes
      .filter(node => node.modelType === 'SubTopic')
      .map(node => node.key));
  }
  @computed get selectedSubTopics(): MomentModel[] {
    return [...this.selectedSubTopicIds]
      .map(id => this.job?.maybeGetMoment(id)!)
      .filter(identity);
  }


  @computed get selectedSpeakerIds(): Set<string> {
    return new Set(this.selectedNodes
      .filter(node => node.modelType === 'Speaker')
      .map(node => node.key));
  }
  @computed get selectedSpeakers(): SpeakerModel[] {
    return [...this.selectedSubTopicIds]
      .map(id => this.store.maybeGetSpeaker(id)!)
      .filter(identity);
  }

  @computed get selectedMomentIds(): Set<string> {
    return new Set([
      ...this.selectedTopicIds,
      ...this.selectedSubTopicIds
    ]);
  }
  @computed get selectedMoments(): MomentModel[] {
    return [...this.selectedMomentIds]
      .map(id => this.job?.maybeGetMoment(id)!)
      .filter(identity);
  }

  @computed get lastSelectedNode(): CloudNode {
    return this.selectedNodes
      .slice()
      .sort((a, b) => b.lastSelectedAt! - a.lastSelectedAt!)[0] || null;
  }

  readonly sizeInfoPromiseLookup = observable.map<string, Promise<void>>();

  @computed get allNodesHaveSizeInfo() {
    const topics = this.topicNodes;
    const speakers = this.speakerNodes;

    return (
      (topics.length + speakers.length) > 0 &&
      (topics.length > 0 ? topics.every(node => node.hasSizeInfo) : true) &&
      (speakers.length > 0 ? speakers.every(node => node.hasSizeInfo) : true));
  }

  getNode(id: string) {
    return this.nodes.find(node => node.key === id) || null;
  }

  getSpeakerNode(id: string): CloudNode<'Speaker'> {
    return this.speakerNodes.find(spk => spk.key === id) as CloudNode<'Speaker'> || null;
  }
  getTopicNode(id: string): CloudNode<'Topic'> {
    return this.topicNodes.find(top => top.key === id) as CloudNode<'Topic'> || null;
  }
  getSubTopicNode(id: string): CloudNode<'SubTopic'> {
    return this.subTopicNodes.find(sub => sub.key === id) as CloudNode<'SubTopic'> || null;
  }

  getSubTopicsForTopic(id: string): CloudNode[] {

    const topic = this.getTopicNode(id);
    if (!topic)
      return [];

    return this.subTopicNodes.filter(sub => areConnected(sub.model, topic.model));
  }

  /**
   * Undirected check.
   */
  hasEdgeBetween(aId: string, bId: string) {
    return this.edges.some(edge =>
      (edge.sourceId === aId && edge.targetId === bId) ||
      (edge.targetId === aId && edge.sourceId === bId));
  }

  isConnectedToSelectedNode(id: string) {
    return this.edges.some(edge =>
      (edge.sourceId === id && edge.targetNode?.isSelected) ||
      (edge.targetId === id && edge.sourceNode?.isSelected));
  }

  isConnectedToAccentedNode(id: string) {
    return this.edges.some(edge =>
      (edge.sourceId === id && edge.targetNode?.isAccented) ||
      (edge.targetId === id && edge.sourceNode?.isAccented));
  }


  @action
  selectAll() {

    switch (this.mode) {
      case 'Topics':
        this.topicNodes.map(node => node.select());
        break;

      case 'Speakers':
        this.speakerNodes.map(node => node.select());
        break;
    }
  }

  @action
  unselectAll() {
    this.topicNodes.map(node => node.unselect());
    this.speakerNodes.map(node => node.unselect());
  }

  async nodeMounted(model: CloudNode) {
    const promise = model.computeSizeInfo();
    const stack = this.sizeInfoPromiseLookup;

    stack.set(model.key, promise);
    await promise;
    stack.delete(model.key);

    if (stack.size === 0)
      this.emit('update');
  }

  nodeUnmounted(model: CloudNode) {
    const stack = this.sizeInfoPromiseLookup;
    stack.delete(model.key);

    if (stack.size === 0)
      this.emit('update');
  }

  requestUpdate() {

    // ignore the request when there's no data
    // TOOD: a single update might need to be run for cleanup purposes
    if (this.nodes.length === 0)
      return;

    // only update if there is no pending update request from the size info stack
    // otherwise ignore the current request, because an update will occur anyways when the stack gets emptied
    if (this.sizeInfoPromiseLookup.size === 0)
      this.emit('update');
  }

  @action
  selectNode(node: CloudNode) {
    const selector = this.selector;
    if (!selector)
      return;

    switch (node.modelType) {
      case 'Topic':
        selector.selectTopic(node.model as Topic);
        break;
      case 'SubTopic':
        selector.selectSubTopic(node.model as SubTopic);
        break;
      case 'Speaker':
        selector.selectSpeaker(node.model as Speaker);
        break;
    }
  }

  @action
  unselectNode(node: CloudNode) {
    const selector = this.selector;
    if (!selector)
      return;

    switch (node.modelType) {
      case 'Topic':
        selector.unselectTopic(node.model as Topic);
        break;
      case 'SubTopic':
        selector.unselectSubTopic(node.model as SubTopic);
        break;
      case 'Speaker':
        selector.unselectSpeaker(node.model as Speaker);
        break;
    }
  }
}