import { MultipartUploadTask } from './multipartUploadTask';
import { action, computed, makeObservable, observable } from 'mobx';
import groupBy from 'lodash/groupBy';
import { JobSpeciality, JobType } from '@clipr/lib';
import { assert, isLargeFile } from '../../core';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { UploadTask } from './uploadTask';
import { JobLevel } from '../../entities/job/jobFeatures';
import { GroupCriteria, UploadTaskGroup } from './uploadTaskGroup';

type ExternalLibraryUploadView = 'gdrive' | 'zoom' | 'onedrive';

export type UploadFile = {
  file: File;
  languageCode?: string;
  isPublic?: boolean;
  jobLevel?: JobLevel;
  jobSpeciality?: JobSpeciality;
  videoType: JobType
};

export type TaskProps = {
  teamId?: string;
  jobId?: string;
};

export class UploadService
  extends StoreNode {

  readonly nodeType = 'UploadService';

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  readonly tasks = observable.array<UploadTask>([], { deep: false });

  @computed get pendingTasks() {
    return this.tasks.filter(task => task.isActive || task.isFailed);
  }

  @computed get activeTasks() {
    return this.tasks.filter(task => task.isActive);
  }

  @computed get completedTasks() {
    return this.tasks.filter(task => task.isCompleted);
  }

  @action
  createTask(file: UploadFile, { teamId, jobId }: TaskProps): UploadTask {
    return this.createTaskCore(file, teamId, jobId);
  }

  @action
  createTasks(uploadFiles: UploadFile[], { teamId, jobId }: TaskProps): UploadTask[] {
    const tasks = uploadFiles.map(uploadFile =>
      this.createTaskCore(uploadFile, teamId, jobId));
    return tasks;
  }

  @computed
  get tasksGroupedByTeam(): UploadTaskGroup[] {
    const criteria: GroupCriteria = 'teamId';
    const groups = groupBy(this.tasks, criteria);
    return Object.keys(groups).map(key => {
      const value = groups[key];
      const taskGroup = new UploadTaskGroup(this.store, {
        groupBy: criteria,
        groupId: key,
        tasks: value
      });
      return taskGroup;
    });
  }

  private createTaskCore(uploadFile: UploadFile, teamId?: string, jobId?: string) {
    const { file, languageCode, isPublic, jobLevel, jobSpeciality, videoType } = uploadFile;

    const task = isLargeFile(file) ?
    new MultipartUploadTask({
      file,
      teamId,
      jobId,
      languageCode,
      isPublic,
      jobLevel,
      jobSpeciality,
      videoType
    }, this.store) :
    new UploadTask({
      file,
      teamId,
      jobId,
      languageCode,
      isPublic,
      jobLevel,
      jobSpeciality,
      videoType
    }, this.store);

    this.tasks.push(task);
    task.listen(this.taskListener);
    task.start();
    return task;
  }

  private taskListener = (msg: Message<UploadTask>) => {
    const task = msg.sender;

    switch (msg.type) {
      case 'start':
        this.dispatch('enableCloseTabWarning');
        break;

      case 'discard':
        assert(this.tasks.includes(task),
          `Received an 'discard' message from ${task.nodeType} which is not part of the service's task list.`);
        assert(task.status === 'error',
          `When aborting an UploadTask it must be in an 'error' state.`);

        this.tasks.remove(task);
        this.broadcast('taskDiscarded', { task });
        break;

      case 'completed':
        const { job, teamId, jobId } = msg.payload;
        if (this.activeTasks.length === 0)
          this.dispatch('disableCloseTabWarning');

        this.broadcast('taskCompleted', { job, teamId, jobId });
        this.emit('upload:taskCompleted', { job });

        break;

      case 'cancel':
        if (this.activeTasks.length === 0)
          this.dispatch('disableCloseTabWarning');
        break;
    }
  }

  private navigateToLibraryTab(libraryKey: string | null) {
    const url = new URL(window.location.href);
    const queryParams = url.searchParams;

    if (libraryKey)
      queryParams.set('view', libraryKey);
    else
      queryParams.delete('view');

    url.search = queryParams.toString();
    this.store.history.replace(url.pathname + url.search);
  }


  navigateToGoogleDriveTab() {
    this.navigateToLibraryTab('gdrive');
  }

  navigateToZoomTab() {
    this.navigateToLibraryTab('zoom');
  }

  navigateToOneDriveTab() {
    this.navigateToLibraryTab('onedrive');
  }

  clearLibraryTabNavigation() {
    this.navigateToLibraryTab(null);
  }


  private openLibraryUploadWindow(libraryLabel: string) { // TODO: get rid of this label mess!!!!
    return this.dispatch('openExternalLibraryUploadWindow', { activeTab: libraryLabel });
  }

  @action
  openLibraryUploadWindowOnMount() {

    const queryParams = new URLSearchParams(window.location.search);
    const view = queryParams.get('view') as ExternalLibraryUploadView;

    if (!view)
      return;

    if (view === 'zoom' && this.store.zoom.enabled)
      return this.openLibraryUploadWindow('Zoom');

    if (view === 'onedrive' && this.store.oneDrive.enabled)
      return this.openLibraryUploadWindow('OneDrive');

    if (view === 'gdrive' && this.store.googleDrive.enabled)
      return this.openLibraryUploadWindow('Google Drive');
  }
}