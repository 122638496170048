import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { PlayerIndexState } from '../playerIndex/playerIndexState';
import { PlayerTranscriptsState } from '../playerTranscripts/playerTranscriptsState';
import { PlayerCommentsState } from '../playerComments/playerCommentsState';

type PlayerSectionHeaderButtonProps = {
  model: PlayerTranscriptsState | PlayerIndexState | PlayerCommentsState,
  className?: string,
  disabled?: boolean
  id?: string;
}

export const PlayerSectionHeaderButton = observer(({
  model,
  disabled = false,
  className,
  id
}: PlayerSectionHeaderButtonProps) => {

  className = classNames('user-player-section-header-btn', className)

  return <button
    id={id}
    data-tutorial-id="jump-to-current-index"
    className={className}
    onClick={() => model.scrollToCurrent()}
    disabled={disabled}>
    Jump To Current
  </button>
})