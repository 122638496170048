import './infoTooltip.scss';

import React from 'react';

export const IngestInfoTooltip = () => {
  return (
    <div
      className="info-modal-tooltip job-details">

      <div className="tooltip-content">
        <p className="title">Manual</p>
        <p className="description">You will be able to select the recording you prefer for CLIPr to ingest.</p>

        <p className="title">Automatic</p>
        <p className="description">CLIPr will ingest the feed that was last used.</p>
      </div>
    </div>
  );
}
