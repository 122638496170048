import { action, makeObservable, observable, runInAction, computed } from 'mobx';
import { Maybe } from '../../core';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';

export class DeleteJobWindowState
  extends StoreNode {

  readonly nodeType = 'DeleteJobWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);

  @observable isLoading: boolean = false;

  jobId: Maybe<string>;
  teamId: Maybe<string>;

  @computed
  get isWidgetMode() {
    return this.store.widgetService.isWidgetMode;
  }

  @action
  async submit() {
    this.isLoading = true;

    const [, err] = await this.store.apiService.archiveJob({ id: this.jobId || '' });

    if (err) {
      notifyError(this, 'Could not delete the video.');
      return this.handleSubmitFinally();
    }

    this.store.bookmarkManager.bookmarkListLookup.clear();
    await this.store.bookmarkManager.apiFetchBookmarkLists();

    runInAction(() => {
      // TODO: remove hard dependencies to other pages
      // also, on a broader level as discussed previously, no component should interact with pages directly
      notifySuccess(this, 'Video deleted.');
      if (this.teamId) {
        if (this.isWidgetMode) {
          this.store.teamLibraryWidget.refreshJobs();
        } else {
          this.store.teamDashboardPage.refreshJobs();
        }
      } else {
        this.store.userDashboardPage.mounted({});
        this.store.trainerDashboardPage.refreshJobs();
      }
      this.handleSubmitFinally();
      this.emit('JobDeleted')
    });
  }

  handleSubmitFinally() {
    runInAction(() => {
      this.isLoading = false;
      this.close();
    })
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open({ jobId, teamId }: { jobId: string, teamId?: string }) {
    this.dispatch('Overlays', 'openWindow', { name: 'DeleteJobWindow' });

    this.jobId = jobId;
    this.teamId = teamId;
  }

  @action
  close() {
    this.jobId = null;
    this.teamId = null;

    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}