import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Maybe } from '../../core';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { JobModel, JobVideoTypeList } from '../../entities';
import { input, inputGroup } from '../input';
import { JobType, UpdateJobInput } from '@clipr/lib';
import Routes from '../../routes';

export class VideoInformationWindowState extends StoreNode {
  readonly nodeType = 'VideoInformationWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(this.windowListener);
  }

  readonly window = new WindowState(this.store);

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading || this.inputGroup.isSubmitting)
          return;
        this.close();
        break;
    }
  };

  readonly titleInput = input(this, {
    name: 'title',
    isRequired: true,
  });

  readonly videoTypeInput = input(this, {
    name: 'videoType',
    showStatusMessage: true,
    selectorItems: JobVideoTypeList,
  });

  readonly inputGroup = inputGroup(this, {
    name: 'window',
    inputs: [
      this.titleInput,
      this.videoTypeInput
    ]
  });

  @observable isLoading: boolean = false;
  @observable isSubmitting: boolean = false;
  @observable jobId: Maybe<string>;
  @observable job: Maybe<JobModel>;

  @computed get streamedOnText() {
    return this.job?.streamedOnLabel;
  }

  @action
  async init() {
    this.isLoading = true;

    const job = this.store.jobManager.maybeGetJob(this.jobId!);
    this.job = job;
    if (this.job) {
      this.handleSuccess();
    }

    if (!this.job) {
      const [job, err] = await this.store.jobManager.apiFetchJob(this.jobId!);

      runInAction(() => {
        if (err) {
          this.handleFailed();
        } else {
          this.job = job;
        }
        this.handleSuccess();
      });
    }
  }

  @action
  handleFailed() {
    this.close();
    notifyError(this, 'Video no longer exists.');
    this.isLoading = false;
  }

  @action
  handleSuccess() {
    this.titleInput.value = this.job?.title || null;
    this.videoTypeInput.value = this.job?.videoType || null;
    this.isLoading = false;
  }

  @action
  async submit() {
    if (!this.jobId) return;

    const input: UpdateJobInput = {
      id: this.jobId,
      videoType: this.videoTypeInput.value as JobType || undefined,
      title: this.titleInput.value || undefined,
    };
    this.isLoading = true;

    const [, err] = await this.store.jobManager.apiUpdateJob(input);
    if (err) {
      runInAction(() => this.isLoading = false);
      return notifyError(this, `Failed to update video.`);
    }

    runInAction(() => this.isLoading = false);
    notifySuccess(this, `Updated video.`);
    this.close('jobUpdated');
  }

  @action
  open({ jobId }: { jobId: string }) {
    this.dispatch('Overlays', 'openWindow', { name: 'VideoInformationWindow' });
    this.emit('open');
    this.jobId = jobId;
    this.init();
  }

  @action
  clearState() {
    if (this.store.history.location.pathname === Routes.trainerDashboardFailedJob(this.jobId!)) {
      this.store.history.replace(Routes.trainerDashboard());
    }
    this.jobId = null;
    this.titleInput.value = null;
    this.videoTypeInput.value = null;
  }

  @action
  close(msg: string = 'close') {
    this.emit(msg);
    this.dispatch('Overlays', 'closeWindow');
  }

  @action
  onTransitionEnd = () => {
    this.clearState();
  }
}
