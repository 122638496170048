import React, { ReactElement } from 'react';
import { MomentWordConfidence } from '@clipr/lib';
import { getWordConfidenceThresholdColor } from '../../entities/momentUtils';

export const generateTranscriptText = (text: string, wordConfidence: MomentWordConfidence[]) => {
  let output: ReactElement = <></>;
  let currentIndex = 0;

  wordConfidence.forEach((item, index) => {
    const { word, confidence } = item;
    let el;

    const startIndex = text.indexOf(word, currentIndex);

    if (startIndex < 0) { // word not found
      if (index === wordConfidence.length - 1) { // last word in collection
        el = <>
          {el}
          {text.slice(currentIndex)}
        </>
        output = <>
          {output}
          {el}
        </>
      }
      return;
    }

    const endIndex = startIndex + word.length;
    const filler = text.slice(currentIndex, startIndex);

    currentIndex = endIndex;

    const normConfidence = Math.ceil(confidence * 100);
    const color = getWordConfidenceThresholdColor(normConfidence);

    if (!color) {
      el = word;
    }

    if (color) {
      el = <span style={{ color: color }}>{word}</span>
    }

    if (index === wordConfidence.length - 1) {
      el = <>
        {el}
        {text.slice(endIndex)}
      </>
    }

    output = <>
      {output}
      {filler}
      {el}
    </>
  });

  return output;
}