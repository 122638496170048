import React from 'react';
import { observer } from 'mobx-react-lite';
import { ParentSize } from '@visx/responsive';
import { PerformanceChartState } from './performanceChartState';
import { XYSeriesChartControlled } from '../xySeriesChart';
import { PerformanceSeriesData } from './performanceChartSchema';
import { DefaultThemeConfig } from '../utils/defaultThemeConfig';
import { ChartWrapper } from '../chartWrapper';

type Props = {
  model: PerformanceChartState;
};

export const PerformanceChart = observer(({ model }: Props) => {
  const { dataSource, xySeriesChartState } = model;
  const teamId = model.params?.teamId;
  const { isFetching: showLoadMask, error } = dataSource;

  // NOTE: do not access model.outputData directly in XYSeriesChart
  // this is because XYSeriesChart is rendered within a callback to ParentSize, which I suspect is run async
  const data: PerformanceSeriesData[] = model.outputData;

  const hasData = !!(data?.[0]?.data?.length > 0);
  const showNoDataMask = !hasData && !showLoadMask;

  let overlayMessage;
  if (error) {
    overlayMessage = { code: 'Error', message: 'An error has occured' };
  } else if (showNoDataMask) {
    overlayMessage = { code: 'NoData' };
  }

  const chartTitle = `${teamId ? 'Library' : 'My'} performance`;
  const tooltipContent = teamId ?
    `Understand how popular your library's content is by tracking engagement it generated with performance metrics over time.` :
    `Understand how popular your content is by tracking engagement it generated with performance metrics over time.`;

  const legendProps = {
    toggleData: true,
    showLabel: true,
    showLineColor: true,
  }

  return (
    <ChartWrapper
      title={chartTitle}
      tooltipContent={tooltipContent}
      showLoadMask={showLoadMask}
      overlayMessage={overlayMessage}>
      <ParentSize>{(parent) =>
        <XYSeriesChartControlled
          model={xySeriesChartState}
          series={data}
          legend={legendProps}
          themeConfig={DefaultThemeConfig}
          height={parent.height - 32}
          width={parent.width}
          curve="basis" />
      }</ParentSize>
    </ChartWrapper>
  );
});
