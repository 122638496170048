import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ApiQueryName, ApiResponse, ApiVariables, ApiRequestOptions } from '../../../api/apiSchema';
import { AsyncResult, Result } from '../../../core';
import { BindingProps, StoreNode } from '../../../store';
import { ChartPageParams } from '../chartSchema';
import { Store } from '../../../store/store';
import { error, Error } from '../../../core/error';
import { ApiError } from '../../../api/apiError';
import { IMetricCounterDataSource } from '../metricCounterSchema';

type Props = BindingProps<{
  queryName?: ApiQueryName;
  queryParams?: ChartPageParams;
  valueAccessor?: (apiResult: any) => any;
}>

export class MetricCounterDataSource
  extends StoreNode
  implements IMetricCounterDataSource<number> {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);
  }

  // #region Props
  @computed get queryName(): ApiQueryName | null {
    return this.getResolvedProp('queryName') ?? null;
  }

  @computed get queryParams(): ChartPageParams | null {
    return this.getResolvedProp('queryParams') ?? null;
  }

  @computed get valueAccessor(): any {
    return this.getProp('valueAccessor') ?? null;
  }
  // #endregion

  @observable value = 0;

  private abortController: AbortController | null = null;

  @observable isFetching = false;
  @observable error: Error | null = null;

  @action
  reset() {
    this.abort();

    this.isFetching = false;
    this.error = null;
    this.abortController = null;

    this.value = 0;
  }

  @action
  async fetch(): AsyncResult {

    this.reset();

    const abortCtrl = new AbortController();
    this.abortController = abortCtrl;

    const { queryName, queryParams } = this;
    if (!(queryName && queryParams))
      return [null];

    this.isFetching = true;

    const startDateArg = queryParams.startDate?.toISO();
    const endDateArg = queryParams.endDate?.toISO();
    const timezoneArg = queryParams.timezone ?? undefined;
    const teamIdArg = queryParams.teamId ?? undefined;

    if (!startDateArg || !endDateArg)
      return [null, error('Unknown', 'Invalid parameters')];

    const reqArgs: ApiVariables<'keenBatch'> = {
      args: {
        start: startDateArg,
        end: endDateArg,
        timezone: timezoneArg,
        teamId: teamIdArg
      }
    };

    const api = this.store.api;
    const reqOpts: ApiRequestOptions = {
      signal: abortCtrl.signal
    };

    const [res, err] = await api.runQuery(queryName, reqArgs, reqOpts);

    if (err) {
      // @ts-ignore TODO: make ApiError extend our own Error class
      return this.handleError(err);
    }

    const [value, insertErr] = this.insertResponse(res!);
    if (insertErr)
      return this.handleError(insertErr);

    runInAction(() => {
      this.isFetching = false;
    });

    return [value!];
  }

  cancel(): void {
    this.abort();
  }

  @action
  private insertResponse(res: ApiResponse<any>): Result<number> {
    const [result, parseErr] = (this.valueAccessor && this.valueAccessor(res)) || [null];

    if (parseErr)
      return this.handleError(parseErr);

    this.value = result;
    return [result];
  }

  private abort() {

    const lastAbortCtrl = this.abortController;
    if (lastAbortCtrl)
      lastAbortCtrl.abort();
    this.abortController = null;
  }

  @action
  private handleError(err?: Error | string): Result {
    if (!err)
      err = new Error();
    if (typeof err === 'string')
      err = new Error('Unknown', err);

    if (err instanceof ApiError && err.type === 'RequestAborted') {
      // for aborted requests we only return the error but we don't set it on the instance and we do not repor it as error
      return [null, err];

    } else {
      console.error(`An error occured in MetricCounterDataSource: `, err);

      this.isFetching = false;
      this.abort();
      this.error = err;

      return [null, err];
    }
  }
}
