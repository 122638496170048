import { ExternalLibrarySource } from "@clipr/lib";
import { JobModel } from "../../../entities";
import { IAnalyticsStream, AnalyticsStreamParams, AnalyticsEventTypes } from "../analyticsSchema";
import { AnalyticsStream, AnalyticsMainData, AnalyticsCurrentJobData } from "./analyticsStream";

export type AnalyticsVideoUploadStreamParams = {
  job: JobModel,
  externalLibrarySource: ExternalLibrarySource
}

export type AnalyticsVideoUploadStreamData = AnalyticsCurrentJobData & AnalyticsMainData

export class AnalyticsVideoUploadStream extends AnalyticsStream implements IAnalyticsStream<keyof AnalyticsStreamParams> {
  static streamName = AnalyticsEventTypes.VideoUploadType

  exchangeData(data: AnalyticsVideoUploadStreamParams): AnalyticsVideoUploadStreamData|null {
    return {
      ...super.currentJobData(data.job), 
      ...super.mainData,
      ...super.viewContextData,
    };
  }
}
