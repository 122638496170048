import { computed, makeObservable } from 'mobx';
import { Store } from '../store/store';
import { BindingProps, StoreNode } from '../store';
import { ApiRequestOptions } from '../api/apiSchema';
import { JobModel } from './job';
import { LiveStreamActivePlaylist } from '@clipr/lib';

type Props = BindingProps<{
  jobId?: string | null;
}>

export class JobLiveStatusMonitor
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  @computed get jobId(): string | null {
    return this.getResolvedProp('jobId', false) ?? null;
  }

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  start() {
    this.refresh();
  }

  cancel() {
    this.cancelTimer();
    this.cancelRequest();
  }

  reset() {
    this.cancel();
  }

  playlistChanged(jobCheck: JobModel, currJob: JobModel): boolean {
    const liveStreamPlaylists = Object.values(LiveStreamActivePlaylist);
    for (let playlist of liveStreamPlaylists) {
      if (jobCheck.source.liveStreamActivePlaylist === playlist &&
        jobCheck.isPlaylistActive(playlist) !== currJob.isPlaylistActive(playlist)) {
        return true;
      }
    }

    return false;
  }

  private async refresh() {

    // safety cleanup
    this.cancel();

    const abortCtrl = new AbortController();
    this.abortController = abortCtrl;

    const apiService = this.store.api;
    const jobId = this.jobId;
    const currJob = this.job;
    if (!jobId || !currJob) {
      this.startTimer();
      return false;
    }

    const reqOpts: ApiRequestOptions = {
      signal: abortCtrl.signal
    }

    const [res, err] = await apiService.runQuery('getJob_checkLiveStatus', { id: jobId }, reqOpts);
    if (err) {
      this.emit('Error', { error: err });
      this.startTimer();
      return false;
    }

    const jobCheckData = res?.getJob!;
    const jobCheck = new JobModel(jobCheckData, this.store);

    const playlistChanged = this.playlistChanged(jobCheck, currJob);

    if (jobCheck.liveAggregateStatus !== currJob.liveAggregateStatus ||
      jobCheck.liveStreamURL !== currJob.liveStreamURL ||
      playlistChanged) {

      // console.log('CHANGED CHECK', {
      //   oldStatus: currJob.liveAggregateStatus,
      //   newStatus: jobCheck.liveAggregateStatus
      // });

      this.emit('Change', {
        status: jobCheck.liveAggregateStatus,
        hasUrl: !!jobCheck.liveStreamURL,
        liveStreamUrlChanged: jobCheck.liveStreamURL !== currJob.liveStreamURL,
        playlistChanged
      });
    } else {
      this.startTimer();
    }
  }

  private timerId: ReturnType<typeof setTimeout> | null = null;
  private abortController: AbortController | null = null;

  private startTimer() {
    this.cancelTimer();

    this.timerId = setTimeout(() => {
      this.refresh();
    }, 4000);
  }

  private cancelTimer() {
    const timerId = this.timerId;
    if (timerId) {
      clearTimeout(timerId);
      this.timerId = null;
    }
  }
  private cancelRequest() {
    const abortCtrl = this.abortController;
    if (abortCtrl) {
      abortCtrl.abort();
      this.abortController = null;
    }
  }
}