import { matchPath } from 'react-router-dom';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { Maybe } from '../../core';
import { notifyError, notifyLoading, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { AnalyticsEventTypes } from '../../services/analytics/analyticsSchema';
import Routes from '../../routes';
import { Team } from '../../../../../libs/lib/dist';

export type TeamModel = {
  id: string,
  name: string,
  checked: boolean,
  owner: boolean,
  pictureURL: Maybe<string>,
}
export class AddJobToTeamWindowState
  extends StoreNode {

  readonly nodeType = 'AddJobToTeamWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);

  @observable isLoading: boolean = false;
  @observable isSubmitting: boolean = false;
  @observable teams: TeamModel[] | null = [];

  @observable updatedOnce: boolean = false;
  @observable jobId: Maybe<string>;

  @action
  async init() {
    this.isLoading = true;
    const [teams, err1] = await this.store.teamManager.apiFetchTeams();
    const [permissions, err2] = await this.store.apiService.getJobPermissions({ jobId: this.jobId || '' });

    if (err1 || err2) {
      notifyError(this, 'Could not fetch your libraries.');
      runInAction(() => {
        this.isLoading = false;
        this.close();
      })
      return;
    }
    runInAction(() => {
      this.teams = teams?.filter(team => team.userPermissions?.includes('CreateJob'))
        .map(({ id, name, avatar }) => {
          const pictureURL = avatar && avatar[0].url;
          const hasPermissions = this.checkPermissions(id, permissions?.getJobPermissions || []);

          return {
            id,
            name,
            pictureURL: pictureURL,
            checked: !!hasPermissions,
            owner: hasPermissions?.role === 'Owner'
          }
        }).sort((a: Team, b: Team) => a.name.toLocaleLowerCase() >= b.name.toLocaleLowerCase() ? 1 : -1) || null;
      this.isLoading = false;
    })
  }

  checkPermissions(teamId: string, permissions: any[]) {
    return permissions?.find((permission) => teamId === permission.teamId);
  }

  @action
  async submit(teamId: string) {
    this.isSubmitting = true;
    this.isLoading = true;

    let updateOperation, teamIndex, message;
    const team = this.teams?.find(({ id }, index) => { teamIndex = index; return id === teamId });

    if (!team || typeof teamIndex !== 'number') {
      notifyError(this, 'Could not update.');
      return this.handleSubmitFinally();
    }

    if (team?.checked) {
      updateOperation = (...args: any) => this.store.apiService.removeJobFromTeam(args[0]);
      message = 'Video removed.';
    } else {
      updateOperation = (...args: any) => this.store.apiService.addJobToTeam(args[0]);
      message = 'Video added.';
    }
    notifyLoading(this, 'Updating libraries');
    const [, err] = await updateOperation({ jobId: this.jobId, teamId: teamId });

    if (err) {
      notifyError(this, err.message);
    } else {
      if (!team?.checked) {
        this.store.analyticsService.registerEvent(
          AnalyticsEventTypes.VideoAddToTeamType,
          {
            jobId: this.jobId,
            teamId: teamId
          }
        )
      }

      notifySuccess(this, message);
      this.updatedOnce = true;
      this.updateTeam(teamIndex);
    }

    this.handleSubmitFinally();
    this.emit('jobAdded');
  }

  handleSubmitFinally() {
    runInAction(() => {
      this.isSubmitting = false;
      this.isLoading = false;
    })
  }

  updateTeam(index: number) {
    runInAction(() => {
      if (!this.teams) return;
      this.teams[index].checked = !this.teams[index].checked;

      if (!this.jobId) return;

      const job = this.store.jobManager.getJob(this.jobId);
      const idx = job?.foundInTeams?.findIndex(team => team.id === this.teams![index].id);
      if (idx > -1) {
        job?.foundInTeams?.splice(idx, 1);
      } else {
        job.foundInTeams?.push(this.teams[index]);
      }
    })
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open({ jobId }: { jobId: string }) {
    this.dispatch('Overlays', 'openWindow', { name: 'AddJobToTeamWindow' });

    this.jobId = jobId;
    this.init();
  }

  @action
  close() {
    this.teams = null;
    this.jobId = null;

    // TODO Remove page dependencies
    if (this.updatedOnce) {
      const isOnTeamDashboard = matchPath(window.location.pathname, Routes.teamDashboard());
      if (isOnTeamDashboard) {
        this.store.teamDashboardPage.refreshJobs();
      }
      const isWidgetMode = this.store.widgetService.isWidgetMode;
      if (isWidgetMode) {
        this.store.teamLibraryWidget.refreshJobs();
      }
    }
    this.updatedOnce = false;

    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}