import { AuthIdTokenPayload, AuthPermit } from '../auth/authPermit';
import { LibraryPermit } from '../libraries/libraryPermit';
import { LibraryName } from '../libraries/librarySchema';

export enum ProxyFlowName {
  Authorize = 'Authorize',
  AuthorizeLibrary = 'AuthorizeLibrary',
  Deauthorize = 'Deauthorize'
}

export const ProxyFlowNames = new Set(Object.keys(ProxyFlowName) as (keyof typeof ProxyFlowName)[]);

export enum ProxyFlowMessageType {
  ProxyFlowResponse = 'ProxyFlowResponse'
}

export type ProxyFlowResponse<T extends ProxyFlowName = ProxyFlowName> = {
  flowName: T;
  data: ProxyFlowResponseData<T>;
}

export type ProxyFlowResponseData<T extends ProxyFlowName = ProxyFlowName> =
  T extends ProxyFlowName.Authorize ? ProxyAuthorizeResponseData :
  T extends ProxyFlowName.AuthorizeLibrary ? ProxyAuthorizeLibraryResponseData :
  T extends ProxyFlowName.Deauthorize ? ProxyDeauthorizeResponseData :
  never;

export type ProxyAuthorizeResponseData = {
  permit: AuthPermit;
}

export type ProxyAuthorizeLibraryResponseData = {
  libraryPermit: LibraryPermit;
}

export type ProxyDeauthorizeResponseData = {};

export type ProxyDeauthorizeLibraryResponseData = {};


export type ProxyFlowResponseMessage<T extends ProxyFlowName = ProxyFlowName> = {
  type: ProxyFlowMessageType.ProxyFlowResponse;
  payload: ProxyFlowResponseMessagePayload<T>;
}

export type ProxyFlowResponseMessagePayload<T extends ProxyFlowName = ProxyFlowName> = {
  flowName: T;
  data: ProxyFlowResponseMessageData<T>;
}

export type ProxyFlowResponseMessageData<T extends ProxyFlowName = ProxyFlowName> =
  T extends ProxyFlowName.Authorize ? ProxyAuthorizeResponseMessageData :
  T extends ProxyFlowName.AuthorizeLibrary ? ProxyAuthorizeLibraryResponseMessageData :
  T extends ProxyFlowName.Deauthorize ? ProxyDeauthorizeResponseMessageData:
  never;

export type ProxyAuthorizeResponseMessageData = {
  permit: {
    idToken: string;
    idTokenPayload: AuthIdTokenPayload; 
  };
}

export type ProxyAuthorizeLibraryResponseMessageData = {
  libraryPermit: {
    libraryName: LibraryName;
    code: string; 
  };
}

export type ProxyDeauthorizeResponseMessageData = {};

