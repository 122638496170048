import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { PlayerTranscriptsState } from '../playerTranscripts/playerTranscriptsState';
import { HTMLButtonProps } from '../../core/reactSchema';
import { Placement } from '../playerTutorial/playerTutorial.consts';
import { useOverlayState } from '../overlays/overlayState';
import { Tooltip } from '../overlays/tooltip';

type Props = HTMLButtonProps<{
  model?: PlayerTranscriptsState
  className?: string,
  tooltip?: string | null,
  placement?: Placement,
  icon?: ReactNode,
  pressed?: boolean
}>

export const PlayerActionButton = observer(({
  model,
  className,
  tooltip,
  placement,
  icon,
  pressed,
  ...props
}: Props) => {

  const tooltipState = useOverlayState();

  className = classNames('player-action-btn', className);

  const buttonElement = (
    <button className={className}
      ref={tooltipState.anchorRef.ref}
      aria-pressed={pressed}
      {...tooltipState.anchorProps}
      {...props}>

      <span className="icon">
        {icon}
      </span>
    </button>)

  if (tooltip)
    return (
      <Tooltip
        content={tooltip}
        className={"player-action-tooltip"}>
        {buttonElement}
      </Tooltip>)

  return buttonElement;
});