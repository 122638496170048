import { action, makeObservable, observable, runInAction } from 'mobx';
import { Nullable } from '../../core';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import Routes from '../../routes';

export class DeleteBookmarkListWindowState
  extends StoreNode {

  readonly nodeType = 'DeleteBookmarkListWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);

  @observable isLoading: boolean = false;
  @observable bookmarkListId: Nullable<string> = null;
  @observable shouldRedirect: boolean = false;

  @action
  async submit() {
    if (!this.bookmarkListId) return;

    this.isLoading = true;

    const [, err] = await this.store.apiDeleteBokmarkList({ id: this.bookmarkListId });
    if (err)
      return notifyError(this, 'Could not delete bookmark list.');

    this.handleSubmitFinally();
    notifySuccess(this, 'Bookmark list deleted.');
  }

  handleSubmitFinally() {
    runInAction(() => {
      this.isLoading = false;
      if (this.shouldRedirect) this.store.goTo(Routes.bookmarks());
      this.close();
    })
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open({ bookmarkListId, shouldRedirect = false }: { bookmarkListId: string, shouldRedirect?: boolean }) {
    this.dispatch('Overlays', 'openWindow', { name: 'DeleteBookmarkListWindow' });

    this.bookmarkListId = bookmarkListId;
    this.shouldRedirect = shouldRedirect;
  }

  @action
  close() {
    this.bookmarkListId = null;
    this.shouldRedirect = false;

    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}