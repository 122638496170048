// import isArray from 'lodash/isArray';
import isEmail from 'validator/lib/isEmail';
import { assert, assertObject, isFiniteNumber, isNonEmptyString, Maybe, MaybeProps } from '../../core';
import { getNowSeconds } from '../../core/time';
import { AUTH_MIN_PASSWORD_LENGTH } from './authConfig';
import { AuthIdTokenPayload } from './authPermit';
import { ResetPasswordInput, LoginInput, RegisterInput, ResendOnboardLinkInput, JwtTokenData } from './authSchema';

const TokenExpiryDeltaSeconds = 5;

/** Utility for checking the expiration timestamp against the current one. */
export function isTokenValid(expires: Maybe<number>): boolean {
  if (!expires)
    return false;
  return expires > getNowSeconds() + TokenExpiryDeltaSeconds;
}

export function isIdTokenDataValid(data?: Maybe<JwtTokenData>): data is AuthIdTokenPayload {
  if (!data)
    return false;

  return (
    isNonEmptyString(data.aud) &&
    isFiniteNumber(data.exp) &&
    isFiniteNumber(data.iat) &&
    isNonEmptyString(data.iss) &&
    // isNonEmptyString(data.nonce) &&
    isNonEmptyString(data.sub)
    // isArray(data['https://clipr.ai/roles'])
  );
}


export function isValidPassword(password: Maybe<string>): password is string {
  return !!password && password.length >= AUTH_MIN_PASSWORD_LENGTH;
}


export function assertValidLoginInput(
  input?: Maybe<MaybeProps<LoginInput>>): asserts input is LoginInput {

  assertObject(input,
    `LoginInput is not a valid object.`);

  const {
    username,
    password
  } = input;

  assert(isNonEmptyString(username || ''),
    `Invalid 'username' in LoginInput.`);
  assert(isNonEmptyString(password),
    `Invalid 'password' in LoginInput.`);
}

export function assertValidRegisterInput(
  input?: Maybe<MaybeProps<RegisterInput>>): asserts input is RegisterInput {

  assertObject(input,
    `RegisterInput is not a valid object.`);

  const {
    email,
    // password
  } = input;

  assert(isEmail(email?.trim() || ''),
    `Invalid 'email' in RegisterInput.`);
  // assert(isValidPassword(password),
  //   `Invalid 'password' in RegisterInput.`);
}


export function assertValidChangePasswordInput(
  input?: Maybe<MaybeProps<ResetPasswordInput>>): asserts input is ResetPasswordInput {

  assertObject(input,
    `ChangePasswordInput is not a valid object.`);

  const {
    email
  } = input;

  assert(isEmail(email?.trim() || ''),
    `Invalid 'email' in ChangePasswordInput.`);
}


export function assertValidResendOnboardLinkInput(
  input?: Maybe<MaybeProps<ResendOnboardLinkInput>>): asserts input is ResendOnboardLinkInput {

  assertObject(input,
    `ResendOnboardLinkInput is not a valid object.`);

  const {
    username
  } = input;

  assert(isNonEmptyString(username),
    `Invalid 'username' in ResendOnboardLinkInput.`);
}