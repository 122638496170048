import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { PlayerState } from '../playerState';
import { InputPlayerReactionNames } from '../../playerReactions/playerReactionSchema';
import { ReactionSVGIconLookup } from '../../playerReactions/reactionSvgIconLookup';

const VIDEO_WIDTH_THRESHOLD = 1080;

type Props = {
  model: PlayerState;
};

export const LiveReactionsBar = observer(({ model }: Props) => {
  const {
    adapter: { clientWidth },
    isWidgetMode,
  } = model;

  const className = classNames('live-reactions-bar', {
    'widget-mode': isWidgetMode,
    small: !clientWidth || clientWidth < VIDEO_WIDTH_THRESHOLD,
    large: clientWidth && clientWidth >= VIDEO_WIDTH_THRESHOLD,
  });

  return (
    <div className={className}>
      {InputPlayerReactionNames.map((name) => {
        const iconElem = ReactionSVGIconLookup[name];

        return (
          <button
            key={name}
            className="player-reactions-menu-btn"
            onClick={(evt) => model.throttledAddReaction(name)}>
            <span className="icon">{iconElem.component}</span>
          </button>
        );
      })}
    </div>
  );
});
