import { computed, makeObservable } from 'mobx';
import { MomentModel } from '../../entities';
import { BindingProps, Message, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { IPlayer } from './playerSchema';

type Props = BindingProps<{
  player?: IPlayer,
  moments?: MomentModel[],
  enabled?: boolean
}>

export class PlayerReporter
  extends StoreNode {

  constructor(store: Store, props?: BindingProps<Props>) {
    super(store, props);
    makeObservable(this);

    this.autoListen('player', this.playerListener);
  }

  @computed({ keepAlive: true })
  private get moments(): MomentModel[] | null {
    return this.resolvedProps.moments ?? null;
  }

  @computed({ keepAlive: true })
  private get player(): IPlayer | null {
    return this.resolvedProps.player ?? null;
  }

  @computed({ keepAlive: true })
  private get enabled(): boolean {
    return this.resolvedProps.enabled ?? true;
  }

  private playerListener = (msg: Message) => {
    const { player } = this;
    if (!player)
      return;

    switch (msg.type) {
      case 'timeupdate':
      case 'play':
        this.handlePlayerTimeUpdate(player.currentTime);
        break;
    }
  }

  private handlePlayerTimeUpdate = (time: number): void => {
    
  }
}