import { action, computed, makeObservable, observable } from 'mobx';
import { GetJobsFilterInput, JobEdge, Metadata, PageInfo } from '@clipr/lib';
import isEmpty from 'lodash/isEmpty';
import { Store } from '../../store/store';
import { StoreNode } from '../../store';
import { Maybe } from '../../core'
import { ApiResponse, ApiResult, ApiVariables } from '../../api/apiSchema';
import { notifyError } from '../../services/notifications';
import { ApiRequestOptions } from '../../api';
import { getCatalogMetadataFilterInput, getCatalogTagsFilterInput } from '../../entities/catalogUtils';
import { JobModel, JobProps } from '../../entities/job';

type Props = never;

type FetchArgs = {
  more?: boolean;
  count?: number;
  noSearchTerm?: boolean;
}

export class JobResultsCatalog extends StoreNode {
  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }
  readonly pageSize = 13;

  @observable isLoading: boolean = false;
  @observable isFetchingMore: boolean = false;
  @observable isFetchingSuggestions: boolean = false;
  @observable previousSearch: Maybe<string> = null;
  @observable teamId: string | null = null;
  @observable pageInfo: PageInfo | null = null;

  @observable metadataFilter: Partial<Metadata> | null = null;
  @observable tagsFilter: string[] | null = null;

  readonly lookup = observable.map<string, JobModel>();
  readonly suggestionsLookup = observable.map<string, JobModel>();
  readonly popularVideosLookup = observable.map<string, JobModel>();

  @computed
  get jobs(): JobModel[] {
    return [...this.lookup.values()];
  }
  @computed
  get popularVideos(): JobModel[] {
    return [...this.popularVideosLookup.values()];
  }
  @computed
  get suggestions(): JobModel[] {
    return [...this.suggestionsLookup.values()];
  }

  @computed
  get isEmpty(): boolean {
    return isEmpty(this.jobs);
  }

  @computed get hasNextPage(): boolean {
    if (!this.pageInfo || !this.pageInfo.totalCount) {
      return false;
    }
    return this.pageInfo.totalCount > this.jobs.length;
  }

  // TODO: all the data about jobs should be all moved to the jobManager
  // Lot of inconsistencies arised when I tried to add actions to the search results cards
  // We are dealing with two different job catalogs which have the same purpose
  // This action was needed for the "copy job" functionality
  @action
  insertJob(jobData: Partial<JobProps>) {
    const job = new JobModel(jobData, this.store);
    this.lookup.set(job.id, job);
    return job;
  }

  @action
  private async fetch(args: ApiVariables<'getJobs'>, opts?: ApiRequestOptions): ApiResult<'getJobs'> {
    const { jobManager } = this.store;
    const [res, err] = await jobManager.apiFetchJobs(args, {
      ...opts,
      rawResponse: true
    });

    if (err || !res) {
      return [null, err];
    }

    return [res];
  }

  @action
  async fetchVideos(search: Maybe<string>, pageSize: number, opts?: ApiRequestOptions) {
    this.previousSearch = search || undefined;
    this.isLoading = true;

    const args: ApiVariables<'getJobs'> = this.getFetchArgs({ count: pageSize });

    const [res, err] = await this.fetch(args, opts);

    if (err || !res) {
      notifyError(this, 'Could not fetch search results.');
      return;
    }

    this.lookup.clear();
    this.updateLookup(res);
    this.isLoading = false;
  }

  @action
  async fetchMore(pageSize: number, opts?: ApiRequestOptions) {
    this.isFetchingMore = true;
    const args: ApiVariables<'getJobs'> = this.getFetchArgs({ more: true, count: pageSize });

    const [res, err] = await this.fetch(args, opts);

    if (err || !res) {
      notifyError(this, 'Could not fetch search results.');
      return;
    }

    this.updateLookup(res);
  }

  @action
  async fetchSuggestions(search: Maybe<string>, opts?: ApiRequestOptions) {
    this.previousSearch = search || undefined;
    this.isFetchingSuggestions = true;

    const args: ApiVariables<'getJobs'> = this.getFetchArgs({ count: 3 });

    const [res, err] = await this.fetch(args, opts);

    if (err || !res) {
      notifyError(this, 'Could not fetch search suggestions.');
      return;
    }

    this.suggestionsLookup.clear();
    // @ts-ignore
    res.getJobs.edges.forEach(({ node, highlights }: JobEdge) => {
      const jobData = {
        ...node,
        highlights
      }
      const job = new JobModel(jobData, this.store);
      return this.suggestionsLookup.set(node.id, job);
    });

    this.isFetchingSuggestions = false;
  }

  @action
  async fetchPopularVideos(opts?: ApiRequestOptions) {
    const args: ApiVariables<'getJobs'> = this.getFetchArgs({ count: 7, noSearchTerm: true });

    const [res, err] = await this.fetch(args, opts);

    if (err || !res) {
      notifyError(this, 'Could not fetch popular videos.');
      return;
    }

    this.popularVideosLookup.clear();
    // @ts-ignore
    res.getJobs.edges.forEach(({ node, highlights }: JobEdge) => {
      const jobData = {
        ...node,
        highlights
      }
      const job = new JobModel(jobData, this.store);
      return this.popularVideosLookup.set(node.id, job);
    });
  }

  @action
  updateLookup(res: ApiResponse<'getJobs'>) {
    this.pageInfo = res.getJobs.pageInfo;
    // TODO: remove after refactoring when all props become partial
    // right now TS will complain because media policy and cookieCredentials are not requested
    // and they should not be requested
    // @ts-ignore
    res.getJobs.edges.forEach(({ node, highlights }: JobEdge) => {
      const jobData = {
        ...node,
        highlights
      }
      const job = new JobModel(jobData, this.store);
      return this.lookup.set(job.id, job);
    });

    this.isLoading = false;
    this.isFetchingMore = false;
  }


  @action
  getFetchArgs({ more, count, noSearchTerm }: FetchArgs): ApiVariables<'getJobs'> {
    const args: ApiVariables<'getJobs'> = {
      posterSize: [{
        width: 250,
        height: 140
      }]
    };
    const filter: GetJobsFilterInput = {
      status: {
        in: ['Waiting', 'Done'],
      }
    };

    if (this.teamId) {
      filter.teamId = this.teamId;
    }
    if (this.previousSearch && !noSearchTerm) {
      filter._search = this.previousSearch;
    }

    const metadata = getCatalogMetadataFilterInput(this.metadataFilter);
    if (metadata && metadata.length > 0)
      filter.metadata = metadata;

    const tags = getCatalogTagsFilterInput(this.tagsFilter);
    if (tags && tags.length > 0)
      filter.tags = tags;

    args.filter = filter;

    if (count) {
      args.first = more ? count + 1 : count;
    } else {
      args.first = this.pageSize;
    }

    if (more && this.pageInfo?.endCursor) {
      args.after = this.pageInfo.endCursor;
    }

    return args;
  }

}
