import { computed, makeObservable } from 'mobx';
import { Store } from '../store/store';
import { StoreNode } from '../store';
import { NotificationsSubscription, ResizedImage, GenerativeAiSettingsInput, User as ApiUserProps } from '@clipr/lib';
import { IUser } from './userSchema';

export type UserProps = Omit<ApiUserProps, '__typename'>;

export class User
  extends StoreNode
  implements IUser {

  constructor(store: Store, props?: Partial<UserProps>) {
    super(store, props);
    makeObservable(this);
    Object.assign(this, props);
  }

  readonly id!: string;
  readonly email?: string | null;
  readonly username?: string | null;
  readonly name?: string | null;
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  readonly createdAt?: string | null;
  readonly enabled?: boolean | null;
  readonly status?: string | null;
  readonly groups?: string[];
  readonly picture?: string | null;
  readonly avatar?: ResizedImage[];
  readonly teamAnalyticsExclude?: string[];
  readonly generativeAiSettings!: GenerativeAiSettingsInput;
  readonly notificationsSubscriptions?: NotificationsSubscription[] | null;

  get displayName(): string | null {
    return (
      this.username ||
      this.name ||
      this.email ||
      this.id ||
      null);
  }

  get pictureURL(): string | null {
    if (!this.avatar)
      return this.picture ?? null;

    return (this.avatar.length > 0 && this.avatar[0].url) || null;
  }

  @computed get chatGptSummaryEnabled() {
    return this.generativeAiSettings?.summarizationEnabled ?? false;
  }

  @computed get actionItemsExtractionEnabled() {
    return this.generativeAiSettings?.actionItemsExtractionEnabled ?? false;
  }

  @computed get cliprGptEnabled() {
    return this.generativeAiSettings?.cliprGptEnabled ?? false;
  }

  @computed get generativeAiProvider() {
    return this.generativeAiSettings?.provider ?? null;
  }
}