import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { HTMLDivProps } from '../../core';

type Props = HTMLDivProps<{
  value?: string
}>

/** Switches between elements with nice transitions. */
export const Switch = observer(({
  value,
  className,
  children,
  ...props
}: Props) => {
  className = classNames('switch', className);

  return (
    <div className={className}
      {...props}>

      {children}
    </div>
  );
});

type CaseProps = HTMLDivProps<{
  name?: string,
  visible?: boolean
}>

export const Case = observer(({
  name,
  visible,
  className,
  children,
  ...props
}: CaseProps) => {
  className = classNames('switch-case', className);

  return (
    <div className={className}
      aria-hidden={!visible}
      {...props}>

      {children}
    </div>
  );
});