import { Datum } from "../xySeriesChart";

type Props = {
  [id: string]: Datum[] | undefined | null;
};

export function sumSeries(series: Props) {
  const data = Object.values(series).flat() as Datum[];

  return data.reduce(
    (
      series: Datum[],
      { value: currentValue, timeframe: currentTimeframe }: Datum
    ) => {
      const existingDatumId = series.findIndex(
        ({ timeframe }) =>
          timeframe.start === currentTimeframe.start &&
          timeframe.end === currentTimeframe.end
      );

      if (existingDatumId >= 0) {
        series[existingDatumId].value += currentValue;
      } else {
        series.push({ value: currentValue, timeframe: currentTimeframe });
      }

      return series;
    },
    []
  );
}
