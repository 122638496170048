import Cookies from 'js-cookie';

export type Cookie = {
  name: string,
  value: string,
  path?: string
}

export const setCookies = (cookies: Cookie[]) => {
  cookies.forEach(({ name, value, path }) => {
    Cookies.set(name, value, {
      path: path ?? '/',
      domain: process.env.REACT_APP_MEDIA_COOKIE_DOMAIN,
      secure: true,
      sameSite: 'None'
    });
  });
};
