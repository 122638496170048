
import './streamAssetsWindow.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { Button, RadioGroupInputControlled } from '../input';
import { TextInput } from '../input';
import { StreamQueueSettingsWindowState } from './streamQueueSettingsWindowState';
import { LAYOUT_ICONS } from '../layout/util';

type Props = {
  model: StreamQueueSettingsWindowState;
  visible?: boolean;
};

export const StreamQueueSettingsWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      className='stream-assets-winow embed-settings'
      model={model.window}
      visible={props.visible}>
      <WindowHeader model={model.window} title="Embed Settings" />

      <hr />

      <section className="window-content">

        <RadioGroupInputControlled
          label={"Theme selection"}
          description={"Choose a color theme."}
          icons={LAYOUT_ICONS.themeIcons}
          className="horizontal-layout transparent-icon bmar4"
          model={model.theme} />

        <hr />

        <div className="embed-code-section">
          <TextInput
            label="All Videos from this library"
            className="form-input embed-code"
            value={model.iframeEmbedCode}
            showFeedback={false}
            rows={6}
            multiline
            disabled />
          <Button
            className="secondary btn-outline small"
            label="Copy Code"
            onClick={() => model.copyPageIframeCode()} />
        </div>
      </section>
    </Window >
  );
});
