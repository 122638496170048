import { action, computed, makeObservable, observable } from 'mobx';
import { Message, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { WindowState } from '../../components/overlays';
import { UploadFileItemState } from './uploadFileItemState';
import { UploadLinkItemState } from './uploadLinkItemState';
import { input, InputState } from '../../components';

type UploadItem = UploadFileItemState | UploadLinkItemState;

export class UploadJobDetailsWindowState
  extends StoreNode {

  readonly nodeType = 'UploadJobDetailsWindow';

  constructor(store: Store, props?: any) {
    super(store, props);
    makeObservable(this);
    this.window.listen(
      this.windowListener);
  }

  @observable isLoading = false;
  @observable task: UploadItem | null = null;

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close();
        break;
    }
  }

  readonly window = new WindowState(this.store);

  levelInput: InputState | null = null;
  specialityInput: InputState | null = null;

  @computed
  get isLoaded() {
    return !!this.task && !!this.levelInput && !!this.specialityInput;
  }

  @computed
  get isSpecialityInputDisabled() {
    return this.levelInput?.value === 'media';
  }

  async submit() {
    if (!this.task) return;
    this.task.levelInput.loadValue(this.levelInput?.value);
    this.task.specialityInput.loadValue(!this.isSpecialityInputDisabled ? this.task.specialityInput.value : undefined, true);
    this.close();
  }

  @action
  init(task: UploadItem) {
    this.task = task;
    this.levelInput = this.task.levelInput;
    this.levelInput.loadValue(this.task.levelInput.value);
    this.specialityInput = this.task.specialityInput;
    this.specialityInput.loadValue(!this.isSpecialityInputDisabled ? this.task.specialityInput.value : undefined);
  }

  @action copyInputState(originalInput: InputState): InputState {
    const { name, placeholder, selectorItems, showStatusMessage, isRequired, statusMessage, disabled } = originalInput;
    
    return input(this, {
      name,
      placeholder,
      selectorItems,
      showStatusMessage,
      isRequired,
      statusMessage,
      disabled
    })
  }

  @action
  reset() {
    this.task = null;
    this.levelInput = null;
    this.specialityInput = null;
  }

  @action
  open({ task }: { task: UploadItem }) {
    this.dispatch('Overlays', 'openWindow', { name: 'UploadJobDetailsWindow' });
    this.init(task);
  }

  @action
  close() {
    this.dispatch('Overlays', 'closeWindow');
    this.reset();
  }
}