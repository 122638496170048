import React from 'react';
import { observer } from 'mobx-react-lite';
import { ChatGptLogoSVGIcon } from '../svg';
import classNames from 'classnames';
import { DotSpinner } from '../loader';
import { InputState } from '../input';
import { ellipseText } from '../utils';


type Props = {
  model: InputState;
  isAiProcessing: boolean;
  isWidgetMode: boolean;
  setDropdownElement: any;
  handleOnAskClick:  (evt: React.MouseEvent, value?: string) => void;
  handleOnSearchClick: (evt: React.MouseEvent) => void;
}

export const PlayerSearchBarSuggestions= observer(({
  model,
  isWidgetMode, 
  isAiProcessing,
  setDropdownElement,
  handleOnAskClick,
  handleOnSearchClick
}: Props) => {

  const searchSuggestionsClassname = classNames('search-suggestions', {
    'widget-mode': isWidgetMode
  });

  const aiProcessingTag = <div className="job-card-ai-tag processing">
    <ChatGptLogoSVGIcon /> 
    <div className="spinner-container">
      Still processing 
      <DotSpinner 
      className="short-animation" 
      dots={3} />
    </div>
  </div>;

  return (
    <div
      ref={setDropdownElement as any}
      className={searchSuggestionsClassname}>
      <div
        onClick={(evt) => handleOnSearchClick(evt)}
        className="suggestion">
        Search "{model.value}"
      </div>
      <div
        aria-disabled={isAiProcessing}
        onClick={(evt) => handleOnAskClick(evt)}
        className="suggestion">
        Ask "{model.value ? ellipseText(model.value, 20) : ''}"
        {isAiProcessing && aiProcessingTag}
      </div>
      <div
        onClick={(evt) => handleOnAskClick(evt, 'What is Clipr?')}
        className="suggestion">
        What is Clipr?
      </div>
    </div>
  );
});

