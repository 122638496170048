import { UpdateProfileInput } from '@clipr/lib';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Config } from '../../config';
import { AsyncResult } from '../../core/async/async';
import { JobModel } from '../../entities/job';
import { Speaker } from '../../entities/speaker';
import { Team } from '../../entities/team';
import { TrackModel } from '../../entities/track';
import { notifyError, notifyLoading, notifySuccess } from '../../services/notifications';
import { BindingProps } from '../../store/propManager';
import { Store } from '../../store/store';
import { StoreNode } from '../../store/storeNode';
import { input, inputGroup } from '../input';
import { SpeakerFormBlockState, SpeakerFormMode } from '../speakers/speakerFormBlockState';

type Props = BindingProps<{
  onEdit?: () => void;
  shouldFetchSpeakers?: boolean | null;
  disabledSpeakerSuggestion?: boolean | null;
}>

export enum SpeakerIdMode {
  Confirm = 'confirm',
  Select = 'select',
  Edit = 'edit'
}

export class SpeakerIdFormState
  extends StoreNode {

  readonly nodeType = 'SpeakerId';

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  readonly submitButton = input(this, {
    name: 'saveButton',
    disabled: (input: any) => this.inputGroup.isSubmitDisabled
  });

  readonly cancelButton = input(this, {
    name: 'cancelButton',
    disabled: (input: any) => {
      return (
        input.loading ||
        input.isSubmitting)
    }
  });

  readonly editButton = input(this, {
    name: 'cancelButton',
    disabled: (input: any) => {
      return (
        input.loading ||
        input.isSubmitting ||
        !this.isEditMode)
    }
  });

  readonly inputGroup = inputGroup(this, {
    name: "speaker",
    inputs: () => [
      this.submitButton,
      this.cancelButton,
      ...this.speakerBlock.inputGroup.inputs
    ],
    isSubmitDisabled: () =>
      this.inputGroup.hasVisibleError ||
      this.inputGroup.isSubmitting ||
      !this.inputGroup.isDirty ||
      this.speakerBlock.isPending ||
      this.speakerBlock.isConfirmed ||
      this.speakerBlock.isEmpty
  });

  readonly speakerBlock = new SpeakerFormBlockState(this.store, {
    shouldFetchSpeaker: () => this.shouldFetchSpeakers ?? true,
    error: () => {
      if (this.speakerBlock.isEmpty)
        return 'Required field';
    },
    mode: () => (this.isConfirmMode || this.isEditMode || this.isLocked) ? SpeakerFormMode.Confirm : SpeakerFormMode.Create,
    onConfirm: async () => await this.confirm(),
    teamId: () => this.teamId ?? null,
    jobId: () => this.jobId ?? null
  });

  @observable jobId: string | null = null;
  @observable teamId: string | null = null;

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed get team(): Team | null {
    return this.store.teamManager.getTeam(this.teamId);
  }

  @computed get onEdit(): (() => void) | null {
    return this.resolvedProps.onEdit ?? null;
  }

  @observable speakerList: Speaker[] = [];

  @observable mode: SpeakerIdMode = SpeakerIdMode.Select;
  @observable stepIndex: number = 0;
  @observable isFinished: boolean = false;

  @observable isLoading: boolean = false;
  @observable isLocked: boolean = false;
  @observable stepChangeTimeoutId: ReturnType<typeof setTimeout> | null = null;
  @observable hasSubmittedConfirmation: boolean = false;

  @computed get shouldFetchSpeakers() {
    return this.resolvedProps.shouldFetchSpeakers;
  }

  @computed get disabledSpeakerSuggestion() {
    return this.resolvedProps.disabledSpeakerSuggestion;
  }

  @computed get speakerCount() {
    return this.speakerList.length;
  }

  @computed get confirmLabel(): string | null {
    const { currentSpeaker } = this;
    const matchPercentageDisplay = currentSpeaker?.matchPercentageDisplay ?? null;
    if (matchPercentageDisplay)
      return matchPercentageDisplay + ' Chances this is';
    return null;
  }

  @computed get speakerCountDisplay() {
    return this.speakerList.length.toString() + ' ' + (this.speakerList.length === 1 ? 'speaker' : 'speakers');
  }

  @computed get stepCount(): number {
    return this.speakerCount;
  }

  @computed get stepIndexDisplay(): number {
    if (this.stepCount === 0)
      return 0;

    return this.stepIndex + 1;
  }

  @computed get currentSpeaker(): Speaker | null {
    if (this.speakerList.length >= (this.stepIndex + 1))
      return this.speakerList[this.stepIndex];

    return null;
  }

  @computed get currentSpeakerIdTrack(): TrackModel | null {
    const id = this.currentSpeaker?.id ?? null;
    return this.job?.maybeGetTranscriptTrackBySpeaker(id) ?? null;
  }

  @computed get isConfirmMode(): boolean {
    return this.mode === SpeakerIdMode.Confirm;
  }

  @computed get isSelectMode(): boolean {
    return this.mode === SpeakerIdMode.Select;
  }

  @computed get isEditMode(): boolean {
    return this.mode === SpeakerIdMode.Edit;
  }

  @computed get showSubmitButton(): boolean {
    if (this.isLocked || this.showEditLayout)
      return false;
    return true;
  }

  @computed get showEditButton(): boolean {
    if (this.showEditLayout)
      return true;
    return false;
  }

  @computed get showSkipButton(): boolean {
    if (this.isLocked || this.showEditLayout)
      return false;
    return true;
  }

  @computed get showStepIndex(): boolean {
    if (this.isEditMode)
      return false;
    return true;
  }

  @computed get showEditLayout(): boolean {
    return this.isEditMode && !this.speakerBlock.isEditConfirmed;
  }

  @action
  async submit() {
    if (!this.speakerBlock.speakerModelId) {
      this.dispatch('openConfirmationModal', {
        onSubmit: () => this.submitAddNewSpeaker(),
        title: 'Create Speaker',
        secondaryMessage: Config.speakerId.speakerIdConfirmationMessage,
        confirmLabel: 'Confirm',
        closeLabel: 'Cancel'
      });
    } else {
      await this.confirm();
    }
  }

  @action
  async submitAddNewSpeaker() {
    this.inputGroup.handleSubmit();
    notifyLoading(this, 'Adding new speaker');
    const [, err] = await this.speakerBlock.submit();

    if (err || !this.speakerBlock.speakerModelId) {
      this.inputGroup.handleSubmitReject();
      notifyError(this, err ?? 'Speaker is not available');
      return;
    }

    if (this.job &&
      this.team &&
      this.currentSpeakerIdTrack &&
      this.speakerBlock.speakerModelId) {
      const args = {
        jobId: this.job.id,
        speakerId: this.speakerBlock.speakerModelId,
        trackId: this.currentSpeakerIdTrack.id,
        teamId: this.team.id
      }

      const [, err] = await this.store.apiService.confirmSpeakerPrediction({ args });

      if (!err) {
        notifySuccess(this, 'Speaker confirmed');
        this.inputGroup.handleSubmitResolve();
        this.hasSubmittedConfirmation = true;
        // this.stepChangeTimeoutId = setTimeout(() => this.incrementStep(), 2000);
        this.incrementStep();
      } else {
        notifyError(this, 'Speaker could not be confirmed');
        this.inputGroup.handleSubmitReject();
      }
    }
  }

  @action
  async confirm() {
    this.clearStepChangeTimeout();
    this.inputGroup.handleSubmit();
    this.isLoading = true;

    if (
      !this.job ||
      !this.team ||
      !this.currentSpeakerIdTrack ||
      !this.speakerBlock.speakerModelId) {
      this.inputGroup.handleSubmitReject();
      this.isLoading = false;
      this.isLocked = false;
      return;
    }

    const args = {
      jobId: this.job.id,
      speakerId: this.speakerBlock.speakerModelId,
      trackId: this.currentSpeakerIdTrack.id,
      teamId: this.team.id
    }

    const [res, err] = await this.store.apiService.confirmSpeakerPrediction({ args });

    if (!err) {
      this.inputGroup.handleSubmitResolve();
      notifySuccess(this, 'Speaker confirmed');
      this.hasSubmittedConfirmation = true;
      this.isLocked = true;
      this.speakerBlock.setConfirmed();
      this.stepChangeTimeoutId = setTimeout(() => this.incrementStep(), 2000);
    } else {
      notifyError(this, 'Speaker could not be confirmed');
      this.inputGroup.handleSubmitReject();
    }

    this.isLoading = false;
    return res;
  }

  @action
  cancel() {
    // skip step
    this.skipStep();
  }

  @action
  edit() {
    // this.speakerBlock.setEditConfirmed(); // activate if edit in the same context is needed
    this.onEdit?.()
  }

  @action
  skipStep() {
    this.incrementStep();
  }

  @action
  async incrementStep() {
    this.clearStepChangeTimeout();
    let stepIndex = (this.stepIndex + 1) % this.stepCount;

    if (stepIndex === 0) {
      await this.handleFinished();
    } else {
      this.speakerBlock.reset();
      this.changeStepByIndex(stepIndex);
    }

    this.isLoading = false;
    this.isLocked = false;
  }

  @action
  async handleFinished() {
    this.isLoading = true;

    let requests = [];

    if (!this.isEditMode)
      requests.push(this.updateUserPermissions())

    if (this.hasSubmittedConfirmation)
      requests.push(this.job?.fetchDependencies());

    await Promise.all(requests);

    runInAction(() => this.isFinished = true);
  }

  @action
  async updateUserPermissions(): AsyncResult<boolean> {

    if (!this.jobId)
      return [false];

    const params: { args: UpdateProfileInput } = {
      args: {
        hideSpeakerSuggestion: this.jobId,
      }
    };

    // TODO: replace with unified flow
    await this.store.api.updateProfile(params);
    await this.store.authService.runRefreshContextFlow();

    return [true]
  }

  @action
  clearStepChangeTimeout() {
    if (this.stepChangeTimeoutId) {
      clearTimeout(this.stepChangeTimeoutId);
      this.stepChangeTimeoutId = null;
    }
  }

  @action
  async changeStepByIndex(stepIndex: number) {
    this.stepIndex = stepIndex;
    this.emit('change', { speaker: this.currentSpeaker });

    if (this.currentSpeaker) {

      if (this.isEditMode) {
        await this.speakerBlock.load({
          speakerId: this.currentSpeaker.id
        });
        this.speakerBlock.setConfirmed();
        return;
      }

      if (this.currentSpeaker.hasMatch && !this.disabledSpeakerSuggestion) {
        this.mode = SpeakerIdMode.Confirm;
        await this.speakerBlock.load({
          speakerId: this.currentSpeaker.matchId!
        });
        return;
      }

      this.mode = SpeakerIdMode.Select;

    } else {
      console.error('No speaker found for the provided step index');
    }

  }

  @action
  async init(speakerList: Speaker[] = [], jobId?: string | null, teamId?: string | null) {
    this.speakerList = speakerList;
    this.jobId = jobId ?? null;
    this.teamId = teamId ?? null;
    await this.changeStepByIndex(0);
  }

  @action
  reset() {
    this.speakerList = [];
    this.stepIndex = 0;
    this.isLocked = false;
    this.isLoading = false;
    this.jobId = null;
    this.teamId = null;
    this.isFinished = false;
    this.hasSubmittedConfirmation = false;
    this.mode = SpeakerIdMode.Select;

    this.clearStepChangeTimeout();
    this.speakerBlock.reset();
  }

  @action
  setFinished() {
    this.isFinished = true;
  }

  @action
  openEditMode() {
    this.mode = SpeakerIdMode.Edit;
  }

}