import { action, computed, makeObservable, observable } from 'mobx';
import { ApiVariables } from '../../api/apiSchema';
import { assertNotNull, Nullable } from '../../core';
import { JobModel } from '../../entities';
import { Team } from '../../entities/team';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Message, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { input, inputGroup } from '../input';
import { WindowState } from '../overlays';

export type CopyJobWindowOpenParams = {
  jobId: string,
  teamId?: string
}

export class CopyJobWindowState
  extends StoreNode {

  readonly nodeType = 'CopyJobWindow';

  constructor(store: Store, props?: any) {
    super(store, props);
    makeObservable(this);
    this.window.listen(
      this.windowListener);
  }

  @observable
  isLoading = false;

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close();
        break;
    }
  }

  @observable jobId: Nullable<string> = null;
  @computed get job(): Nullable<JobModel> {
    return this.store.maybeGetJob(this.jobId);
  }

  @observable teamId: Nullable<string> = null;
  @computed get team(): Nullable<Team> {
    return this.store.teamManager.getTeam(this.teamId);
  }

  readonly window = new WindowState(this.store);

  readonly titleInput = input(this, {
    name: 'title',
    isRequired: true,
    showStatusMessage: true,
    error: (input, fallback) =>
      (input.value && input.value.length < 5) ? 'Add at least 5 characters' : fallback
  });

  readonly submitButton = input(this, {
    name: 'saveButton',
    disabled: (input: any) => {
      return (
        input.loading ||
        input.isSubmitting ||
        this.form.hasTouchedErrorInputs);
    }
  });

  readonly form = inputGroup(this, {
    name: 'moment',
    inputs: [
      this.titleInput,
      this.submitButton
    ],
    isSubmitDisabled: (form) => {
      return form.hasErrorInputs;
    }
  });

  async submit() {
    this.isLoading = true;

    this.form.handleSubmit();
    this.window.lock();

    if (this.form.error) {
      this.form.handleSubmitReject();
      this.window.unlock();
      return;
    }

    const title = this.titleInput.value!;

    assertNotNull(this.jobId);

    const vars: ApiVariables<'copyJob'> = {
      title: title,
      jobId: this.jobId
    };
    if (this.teamId)
      vars.teamId = this.teamId;

    const [job, err] = await this.store.jobManager.apiCopyJob(vars);
    if (err) {
      this.emit('error');
      this.handleSubmitReject(`Could not copy video '${title}'. Please try again.`);

      return;
    }

    this.emit('success', { job });
    this.handleSubmitSuccess(`Copy '${title}' is processing.`);
  }

  @action
  private handleSubmitReject(msg: string) {
    this.isLoading = false;

    this.form.handleSubmitReject();
    this.window.unlock();

    notifyError(this, msg);
  }

  @action
  private handleSubmitSuccess(msg: string) {
    this.isLoading = false;

    if (this.teamId) {
      this.store.teamDashboardPage.jobCatalogSource.fetch();
    } else {
      this.store.userDashboardPage.jobCatalogSource.fetch();
      this.store.trainerDashboardPage.refreshJobs();
    }
    this.form.handleSubmitResolve();
    this.window.unlock();

    this.close();

    notifySuccess(this, msg);
  }

  @action
  cancel() {
    this.close();
  }

  @action
  open(params: CopyJobWindowOpenParams) {
    this.dispatch('Overlays', 'openWindow', { name: 'CopyJobWindow' });

    this.jobId = params.jobId;
    this.teamId = params.teamId || null;

    assertNotNull(this.jobId);
    assertNotNull(this.job,
      `Cannot open CopyJobWindow for a Job which hasn't been loaded.`);

    this.titleInput.loadValue('Copy of ' + (this.job.title || 'untitled'));
  }

  @action
  close() {
    this.dispatch('Overlays', 'closeWindow');

    this.jobId = null;
    this.form.clear();
  }
}