import { action, computed, makeObservable } from 'mobx';
import { JobModel } from '../../entities';
import { BindingProps, Message, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { PlayerChromeState } from '../player/playerChromeState';
import { PlayerState } from '../player';
import { PlayerFramesetState } from '../playerFrameset/playerFramesetState';
import { Placement, PlayerTutorialStepsContent, PlayerTutorialSelectors } from './playerTutorial.consts';
import { PlayerTutorialConfirmationWindowState } from './playerTutorialConfirmationWindowState';
import { PlayerTutorialTopicsWindowState } from './playerTutorialTopicsWindowState';
import { PlayerTutorialStep, PlayerTutorialWindowState } from './playerTutorialWindowState';
import { Theme } from '../../services/ui/utils';

type Props = BindingProps<{
  player?: PlayerState | null;
  chrome?: PlayerChromeState | null;
  frameset?: PlayerFramesetState | null;
  job?: JobModel;
}>

const transcripts = {
  title: PlayerTutorialStepsContent['Transcripts'].title,
  description: PlayerTutorialStepsContent['Transcripts'].content,
  id: PlayerTutorialSelectors.PlayerTranscripts,
  anchor: PlayerTutorialSelectors.PlayerTranscripts,
  customClass: [{
    class: 'transcript-box-tutorial',
    elementId: PlayerTutorialSelectors.UserPlayerTranscriptBox,
    value: true
  }]
}

const topics = {
  title: PlayerTutorialStepsContent['Topics'].title,
  description: PlayerTutorialStepsContent['Topics'].content,
  id: PlayerTutorialSelectors.TopicIndex,
  anchor: PlayerTutorialSelectors.TopicIndex,
  customClass: {
    class: 'tutorial-opacity',
    elementId: PlayerTutorialSelectors.UserPlayerTopicItem,
    value: true
  },
}

const index = {
  title: PlayerTutorialStepsContent['TopicIndex'].title,
  description: PlayerTutorialStepsContent['TopicIndex'].content,
  id: PlayerTutorialSelectors.EditTopicIndex,
  anchor: PlayerTutorialSelectors.EditTopicIndex,
}

const selectTopic = {
  title: PlayerTutorialStepsContent['SelectTopic'].title,
  description: PlayerTutorialStepsContent['SelectTopic'].content,
  id: PlayerTutorialSelectors.UserPlayerTopicItem,
  anchor: PlayerTutorialSelectors.SubtopicCard,
  customAction: () => { document.getElementById('user-player-clip-scroll')?.scrollTo(0, 0); },
  customClass: [{
    class: 'user-player-topic-box-tutorial',
    elementId: PlayerTutorialSelectors.UserPlayerTopicItem,
    value: true
  },
  {
    class: 'active',
    elementId: PlayerTutorialSelectors.UserPlayerTopicItem,
    value: true
  }]
}

const currentTopic = {
  title: PlayerTutorialStepsContent['CurrentTopic'].title,
  description: PlayerTutorialStepsContent['CurrentTopic'].content,
  id: PlayerTutorialSelectors.JumpToCurrentIndex,
  anchor: PlayerTutorialSelectors.JumpToCurrentIndex,
  customClass: {
    class: 'jump-to-current',
    elementId: PlayerTutorialSelectors.JumpToCurrentIndex,
    value: true
  },
}

const addAClip = {
  title: PlayerTutorialStepsContent['AddClip'].title,
  description: PlayerTutorialStepsContent['AddClip'].content,
  id: PlayerTutorialSelectors.AddAClipBtn,
  anchor: PlayerTutorialSelectors.AddAClipBtn
}

const comments = {
  title: PlayerTutorialStepsContent['Reactions'].title,
  description: PlayerTutorialStepsContent['Reactions'].content,
  id: [PlayerTutorialSelectors.ReactionsBtn, PlayerTutorialSelectors.PlayerComments,],
  anchor: PlayerTutorialSelectors.PlayerComments,
}

const search = {
  title: PlayerTutorialStepsContent['Search'].title,
  description: PlayerTutorialStepsContent['Search'].content,
  id: [PlayerTutorialSelectors.SearchInTranscript, PlayerTutorialSelectors.SearchIcon],
  anchor: PlayerTutorialSelectors.SearchInTranscript,
}

const bookmark = {
  title: PlayerTutorialStepsContent['Bookmark'].title,
  description: PlayerTutorialStepsContent['Bookmark'].content,
  id: [PlayerTutorialSelectors.BookmarkCardDetails, PlayerTutorialSelectors.BookmarkBtn],
  anchor: PlayerTutorialSelectors.BookmarkCardDetails,
  customClass: [{
    class: 'tutorial-opacity',
    elementId: PlayerTutorialSelectors.UserPlayerActiveTopicCard,
    value: true
  }, {
    class: 'bookmark-btn-tutorial',
    elementId: PlayerTutorialSelectors.BookmarkBtn,
    value: true
  }],
}

const tools = {
  title: PlayerTutorialStepsContent['Tools'].title,
  description: PlayerTutorialStepsContent['Tools'].content,
  id: PlayerTutorialSelectors.UserToggleBar,
  anchor: PlayerTutorialSelectors.UserToggleBar
}


export class PlayerTutorialController
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  @computed get isWidgetMode(): boolean {
    return this.frameset.isWidgetMode;
  }

  @computed
  get chrome(): PlayerChromeState {
    return this.resolvedProps.chrome;
  }

  @computed
  get frameset(): PlayerFramesetState {
    return this.resolvedProps.frameset;
  }

  @computed
  get player(): PlayerState {
    return this.resolvedProps.player;
  }

  @computed
  get job(): JobModel {
    return this.resolvedProps.job;
  }

  @computed
  get isLightTheme(): boolean {
    const { uiService } = this.store;
    return uiService.theme === Theme.Light;
  }

  openPlayerTutorial(window: string): void {
    this.player.invoke('enterPause');
    this.chrome.toggleVisibility('Visible');
    const steps: PlayerTutorialStep[] = this.defineTutorialSteps();
    this.dispatch(window, {
      steps: steps
    });

    this.subscribeToTutorialWindowEvents();
  }


  @action
  subscribeToTutorialWindowEvents() {
    const { chrome } = this.player;
    const playerTutorialWindow = this.store.playerTutorialWindow;
    const playerTutorialWindowListener = (msg: Message<PlayerTutorialWindowState>) => {
      switch (msg.type) {
        case 'open':
          chrome.toggleVisibility('Visible');
          if (this.isWidgetMode) {
            chrome.pin();
          }
          break;
        case 'close':
          this.emit('close');
          if (this.isWidgetMode) {
            chrome.unpin();
          }
          chrome.toggleVisibility('Compact');
          playerTutorialWindow.unlisten(playerTutorialWindowListener);
          break;
      }
    }
    playerTutorialWindow.listen(playerTutorialWindowListener);


    if (this.isWidgetMode) {
      const playerTutorialConfirmationWindow = this.store.playerTutorialConfirmationWindowState;
      const playerTutorialConfirmationWindowListener = (msg: Message<PlayerTutorialConfirmationWindowState>) => {
        switch (msg.type) {
          case 'watchLater':
            this.emit('watchLater');
            break;
          case 'close':
            this.emit('close');
            chrome.unpin();
            playerTutorialConfirmationWindow.unlisten(playerTutorialConfirmationWindowListener);
            break;
        }
      }
      playerTutorialConfirmationWindow.listen(playerTutorialConfirmationWindowListener);


      const playerTutorialTopicsWindow = this.store.playerTutorialTopicsWindow;
      const playerTutorialTopicsWindowListener = (msg: Message<PlayerTutorialTopicsWindowState>) => {
        switch (msg.type) {
          case 'open':
            // just for safety
            playerTutorialWindow.unlisten(playerTutorialWindowListener);

            playerTutorialWindow.listen(playerTutorialWindowListener);
            break;
          case 'close':
            this.emit('close')
            playerTutorialTopicsWindow.unlisten(playerTutorialTopicsWindowListener);
        }
      }
      playerTutorialTopicsWindow.listen(playerTutorialTopicsWindowListener);
    }
  }

  defineTutorialSteps(): PlayerTutorialStep[] {
    let step = 1;
    const steps: PlayerTutorialStep[] = [];

    const transcriptsVisible = this.frameset?.isSectionVisible('Transcripts');
    const indexVisible = this.frameset?.isSectionVisible('Index');
    const commentsVisible = this.frameset?.isSectionVisible('Comments');

    const transcriptIsAvailable =
      this.frameset?.isSectionAvailable('Transcripts') &&
      this.player.momentView.transcriptMoments.length > 0;

    const indexIsAvailable =
      this.frameset?.isSectionAvailable('Index') &&
      this.player.itemSource.indexSectionTopics.length > 0;

    const commentsIsAvailable = this.frameset?.isSectionAvailable('Comments');

    // Define transcript step
    if (transcriptIsAvailable) {
      // Make sure the section is opened
      if (!transcriptsVisible) this.frameset?.toggleSection('Transcripts');

      if (this.isLightTheme) {
        transcripts.customClass.push({
          class: 'white-bg',
          elementId: PlayerTutorialSelectors.PlayerTranscripts,
          value: true
        });
      }

      steps.push({
        ...transcripts,
        pageNumber: step,
        showComponentForTutorial: () => this.emit('show', []),
        placement: Placement.TopStart,
        offset: this.isWidgetMode ? [0, 0] : [0, 10]
      });

      step++;
    }

    // Define index step
    if (indexIsAvailable) {
      // Make sure the section is opened
      if (!indexVisible) this.frameset?.toggleSection('Index');

      steps.push({
        ...topics,
        pageNumber: step,
        showComponentForTutorial: () => this.emit('show', []),
        placement: Placement.LeftStart,
        offset: this.isWidgetMode ? [0, -75] : [0, 10]
      });

      step++;

      // Define edit index button step
      if (!this.isWidgetMode) {
        steps.push({
          ...index,
          pageNumber: step,
          placement: Placement.BottomEnd,
          offset: [0, 10],
          showComponentForTutorial: () => this.emit('show', []),
        });
        step++;
      }

      // Define index card step
      steps.push({
        ...selectTopic,
        pageNumber: step,
        placement: this.isWidgetMode ? Placement.Left : Placement.Bottom,
        offset: this.isWidgetMode ? [-60, 0] : [0, 0],
        showComponentForTutorial: () => this.emit('show', []),
      });
      step++;

      // Define jump to current button step
      steps.push({
        ...currentTopic,
        pageNumber: step,
        placement: Placement.BottomEnd,
        offset: [0, 20],
        showComponentForTutorial: () => this.emit('show', []),
      });
      step++;
    }

    // Define add a clip button step
    if (this.job?.hasPermission('EditUserMoment') && this.player.showAddClip) {
      steps.push({
        ...addAClip,
        id: this.isWidgetMode ? PlayerTutorialSelectors.AddAClipBtnWidget : addAClip.id,
        anchor: this.isWidgetMode ? PlayerTutorialSelectors.AddAClipBtnWidget : addAClip.anchor,
        pageNumber: step,
        placement: this.isWidgetMode ? Placement.TopStart : Placement.TopEnd,
        offset: [0, 10],
        showComponentForTutorial: () => this.emit('show', []),
      });
      step++;
    }

    // Define comments step
    if (commentsIsAvailable || this.isWidgetMode) {
      // Make sure comments section is opened
      if (!commentsVisible) this.frameset?.toggleSection('Comments');

      let title = comments.title;
      let description = comments.description;

      if (!commentsIsAvailable) {
        title = '→ Reactions';
        description = `Reactions to key moments can help guide your CLIPr team's activities.`;
      } else if (!this.job.hasPermission('AddComment')) {
        description = `Comments and reactions to key moments can help guide your CLIPr team's activities.`;
      }

      steps.push({
        pageNumber: step,
        ...comments,
        description,
        title,
        anchor: this.isWidgetMode ? PlayerTutorialSelectors.ReactionsBtn : PlayerTutorialSelectors.PlayerComments,
        showComponentForTutorial: () => this.emit('show', ['REACTIONS']),
        placement: this.isWidgetMode ? Placement.Right : Placement.Left,
        offset: this.isWidgetMode ? [-165, -180] : [-150, 30],
        content: 'playerReactionsComponent'
      });
      step++;
    }

    // Define search in transcript step
    if (transcriptIsAvailable && !this.isWidgetMode) {

      steps.push({
        ...bookmark,
        pageNumber: step,
        showComponentForTutorial: () => this.emit('show', []),
        placement: Placement.TopStart,
        offset: [0, 10]
      });
      step++;
    }

    if (this.player.momentView.showActiveTopic) {
      if (this.isLightTheme) {
        search.id.push(PlayerTutorialSelectors.PlayerTranscriptsSection);
      }

      steps.push({
        ...search,
        pageNumber: step,
        placement: Placement.TopStart,
        offset: [0, 10],
        showComponentForTutorial: () => this.emit('show', []),
      });

      step++;
    }

    // Define tools step
    steps.push({
      ...tools,
      pageNumber: step,
      showComponentForTutorial: () => this.emit('show', ['TOOLS']),
      placement: Placement.BottomStart,
      content: 'toggleBarComponent',
      offset: [0, 30]
    });

    return steps;
  }
}