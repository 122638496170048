import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { JobModel, MomentSelector, Speaker, SubTopic, Topic } from '../../entities';
import React from 'react';

const GENERIC_SELECTOR_MIN_VISIBLE = 10;
const TOPIC_SELECTOR_MIN_VISIBLE = 10;

type Props = BindingProps<{
  jobId: string
  selector: MomentSelector
}>

export class MomentSplashState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  @computed get jobId(): string | null {
    return this.getResolvedProp('jobId') || null;
  }

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed
  get selector(): MomentSelector | null {
    return this.getResolvedProp('selector');
  }


  @observable
  isTopicListExpanded: boolean = false;

  @action
  toggleTopicListExpansion() {
    this.isTopicListExpanded = !this.isTopicListExpanded;
  }
  @action
  collapseTopicList() {
    this.isTopicListExpanded = false;
  }


  @computed
  get isTopicListExpandable() {
    const { selector } = this;
    if (!selector)
      return false;
      
    return this.topics.length > TOPIC_SELECTOR_MIN_VISIBLE;
  }

  @computed
  get topics(): Topic[] {
    return this.selector?.topics || [];
  }
  @computed
  get selectedTopics(): Topic[] {
    return this.selector?.selectedTopics || [];
  }
  @computed
  get filteredTopics(): Topic[] {
    return this.selector?.filteredTopics || [];
  }
  @computed
  get visibleTopics(): Topic[] {
    
    const topics = this.filteredTopics;
    const expandable = this.isTopicListExpandable;
    const expanded = this.isTopicListExpanded;
    const visibleCount = (expandable && !expanded) ? TOPIC_SELECTOR_MIN_VISIBLE : topics.length;

    return topics.slice(0, visibleCount);
  }

  @action
  handleTopicClick = (evt: React.MouseEvent, target: Topic) => {
    this.selector?.toggleTopic(target);
  }





  @observable
  isSubTopicListExpanded: boolean = false;

  @action
  toggleSubTopicListExpansion() {
    this.isSubTopicListExpanded = !this.isSubTopicListExpanded;
  }
  @action
  collapseSubTopicList() {
    this.isSubTopicListExpanded = false;
  }

  @computed
  get isSubTopicListExpandable() {
    const { selector } = this;
    if (!selector)
      return false;

    return selector.selectedSubTopics.length > GENERIC_SELECTOR_MIN_VISIBLE;
  }

  @computed
  get subTopics(): SubTopic[] {
    return this.selector?.subTopics || [];
  }
  @computed
  get selectedSubTopics(): SubTopic[] {
    return this.selector?.selectedSubTopics || [];
  }
  @computed
  get filteredSubTopics(): SubTopic[] {
    return this.selector?.filteredSubTopics || [];
  }

  @computed
  get visibleSubTopics(): SubTopic[] {
    
    const subTopics = this.filteredSubTopics;
    const expandable = this.isSubTopicListExpandable;
    const expanded = this.isSubTopicListExpanded;
    const visibleCount = (expandable && !expanded) ? GENERIC_SELECTOR_MIN_VISIBLE : subTopics.length;

    return subTopics.slice(0, visibleCount);
  }

  @action
  handleSubTopicClick = (evt: React.MouseEvent, target: SubTopic) => {
    this.selector?.toggleSubTopic(target);
  }

  


  
  @observable
  isSpeakerListExpanded: boolean = false;

  @action
  toggleSpeakerListExpansion() {
    this.isSpeakerListExpanded = !this.isSpeakerListExpanded;
  }
  @action
  collapseSpeakerList() {
    this.isSpeakerListExpanded = false;
  }

  @computed
  get isSpeakerListExpandable() {
    const { selector } = this;
    if (!selector)
      return false;

    return selector.selectedSpeakers.length > GENERIC_SELECTOR_MIN_VISIBLE;
  }

  @computed
  get speakers(): Speaker[] {
    return this.selector?.speakers || [];
  }
  @computed
  get selectedSpeakers(): Speaker[] {
    return this.selector?.selectedSpeakers || [];
  }
  @computed
  get filteredSpeakers(): Speaker[] {
    return this.selector?.filteredSpeakers || [];
  }

  @computed
  get visibleSpeakers(): Speaker[] {
    
    const speakers = this.filteredSpeakers;
    const expandable = this.isSpeakerListExpandable;
    const expanded = this.isSpeakerListExpanded;
    const visibleCount = (expandable && !expanded) ? GENERIC_SELECTOR_MIN_VISIBLE : speakers.length;

    return speakers.slice(0, visibleCount);
  }

  @action
  handleSpeakerClick = (evt: React.MouseEvent, target: Speaker) => {
    this.selector?.toggleSpeaker(target);
  }
  
  @action
  selectAll() {
    this.selector?.selectAll();
  }

  @action
  unselectAll() {
    this.selector?.unselectAll();
  }

  @action
  collapseLists() {
    this.collapseTopicList();
    this.collapseSubTopicList();
  }
}