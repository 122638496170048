import { useEffect } from 'react';
import { useModel, UseModelCreateCallback } from '../components/componentHooks';
import { useRouteContext } from '../routes/routeHooks';
import { IPageState } from './pageSchema';

export function usePageModel<T extends IPageState>(createCallback: UseModelCreateCallback<T>): T {

  const model = useModel(createCallback);
  const routeContext = useRouteContext();

  useEffect(() => {
    model.attached(routeContext);
    return () =>
      model.detached();
  }, [model, routeContext]);

  return model;
}