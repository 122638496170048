export type Sentiment =
  'POSITIVE' |
  'NEGATIVE' |
  'NEUTRAL' |
  'MIXED';

export const Sentiments: Sentiment[] = [
  'POSITIVE',
  'NEGATIVE',
  'NEUTRAL',
  'MIXED'
];

export const getSentimentLabel = (sentiment: Sentiment): string => {
  switch (sentiment) {
    case 'POSITIVE':
      return 'Positive';
    case 'NEGATIVE':
      return 'Negative';
    case 'NEUTRAL':
      return 'Neutral';
    case 'MIXED':
      return 'Mixed';
    default:
      return sentiment;
  }
}