import { makeObservable, observable, action, computed } from 'mobx';
import { BindingProps, StoreNode } from '../../../store';
import { Store } from '../../../store/store';
import { input, InputState } from '../../input';
import { WordData } from '../wordCloud/wordCloudSchema';
import { TopicViewsDataSource } from './topicViewsDataSource';
import { TopicViewsChartInternalParams, TopicViewsChartMode, TopicViewsChartParams } from './topicViewsChartSchema';

type Props = BindingProps<{
  params?: TopicViewsChartParams
}>

export class TopicViewsChartState
  extends StoreNode {

  readonly nodeType = 'TopicViewsChartState';
  readonly chartTypeInput: InputState;

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.chartTypeInput = input(this, {
      name: 'audienceFocusTypes',
      onChange: this.handleChartTypeChange,
      selectorItems: [
        { value: TopicViewsChartMode.Topics, label: 'Topics' },
        { value: TopicViewsChartMode.SubTopics, label: 'Sub-Topics' },
        { value: TopicViewsChartMode.MostBookmarkedTopics, label: 'Most Bookmarked Topics' },
        { value: TopicViewsChartMode.MostBookmarkedSubtopics, label: 'Most Bookmarked Subtopics' },
      ],
    });

    this.chartTypeInput.loadValue(TopicViewsChartMode.Topics);
  }

  // #region Props
  @computed get params(): TopicViewsChartParams | null {
    return this.getResolvedProp('params') ?? null;
  }
  set params(val: TopicViewsChartParams | null) {
    this.setProp('params', val);
  }
  // #endregion

  readonly internalParams: TopicViewsChartInternalParams = observable({
    mode: TopicViewsChartMode.Topics
  })

  @computed get outputParams(): TopicViewsChartParams {
    const outParams = {
      ...this.internalParams,
      ...this.params
    };

    Object.freeze(outParams);
    return outParams;
  }
  
  @computed get outputData(): WordData[] {
    return [...this.dataSource.data];
  }
  
  readonly dataSource = new TopicViewsDataSource(this.store, {
    params: () => this.outputParams
  });

  @observable isMounted = false;

  async mounted() {
    this.isMounted = true;
  }

  unmounted() {
    this.isMounted = false;
  }

  @action
  private handleChartTypeChange = async (input: InputState, evt: React.ChangeEvent) => {

    const mode = input.value as TopicViewsChartMode;
    if (!mode)
      return;
    this.internalParams.mode = mode;
    this.dataSource.fetch();
  }

  async fetch() {
    return this.dataSource.fetch();
  }
  abort() {
    this.dataSource.cancel();
  }
}