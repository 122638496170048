import { JobSourceType, MutationUpdateJobSourceArgs, StartJobInput, UpdateJobInput } from '@clipr/lib';
import { action, computed, makeObservable, observable } from 'mobx';
import { ApiVariables } from '../../api/apiSchema';
import { ApiUploadRequestParams } from '../../api/apiUploadRequest';
import { decodeBase64 } from '../../components/utils';
import { isNonEmptyString } from '../../core';
import { StoreNode } from '../../store';
import { Store } from '../../store/store';
import { JobModel } from '../job';
import { generateJobLevelOutput, getDefaultJobLevelInput } from './jobFeatures';
import { getJobSpecialityOutput } from './jobSpeciality';
import { JobStub, JobStubProps } from './jobStub';

type JobSyncStatus = 'pending' | 'syncing' | 'synced';

type Props = {
  jobData?: JobStubProps,
  teamId?: string
}
/*
* Job entity synchronizer
*/
export class JobSynchronizer
  extends StoreNode {

  constructor(props: Partial<Props>, store: Store) {
    super(store);
    makeObservable(this);

    const { jobData } = props;

    this.jobData = props.jobData || null;
    this.teamId = props.teamId || null;

    if (jobData && jobData.id)
      this.initModel(jobData);
  }

  readonly teamId: string | null = null;

  @observable jobData: JobStubProps | null = null;
  @observable syncModel: JobStub | null = null;
  @observable syncModelId: string | null = null;
  @observable uploadToken: string | null = null;
  @observable status: JobSyncStatus = 'pending';
  @observable replacingJob: boolean = false;

  @computed
  get isPending() {
    return this.status === 'pending';
  }

  @computed
  get jobId() {
    return this.syncModelId || null;
  }

  @computed get job(): JobModel | null {
    if (!this.jobId)
      return null

    return this.store.maybeGetJob(this.jobId);
  }

  @action
  initModel(jobData: JobStubProps) {
    if (this.syncModel)
      return;

    this.syncModelId = jobData.id || null;
    if (this.syncModelId) this.replacingJob = true;
    this.syncModel = new JobStub(jobData, this.store);
  }

  @action
  updateSyncModel(jobData: JobStubProps) {
    Object.assign(this.jobData, jobData);

    if (this.jobData)
      this.syncModel = new JobStub(this.jobData, this.store);
  }

  @action
  replaceSyncModel(jobData: JobStubProps) {
    this.jobData = jobData;
    this.syncModel = new JobStub(jobData, this.store);
  }

  @action
  bindToken(token: string) {
    this.uploadToken = token;
    this.setTokenData(token);
    this.setSyncing();
  }

  @action
  setSyncing() {
    this.status = 'syncing';
  }

  @action
  setSynced() {
    this.status = 'synced';
  }

  @action
  private setTokenData(token: string) {
    const decodedToken = decodeBase64(token!, true) as any ?? null;
    this.syncModelId = decodedToken?.id || null;
    const props = { id: decodedToken?.id };

    if (!this.jobData?.title)
      Object.assign(props, {
        title: decodedToken?.title
      });

    this.updateSyncModel(props);
  }

  generateStartJobParams(): StartJobInput | null {
    if (!this.uploadToken || !this.syncModel)
      return null;

    const { syncModel } = this;
    return {
      source: {
        type: JobSourceType.Upload
      },
      uploadToken: this.uploadToken,
      teamId: this.teamId ?? undefined,
      title: syncModel.title ?? undefined,
      languageCode: syncModel.languageCode ?? undefined,
      isPublic: syncModel.isPublic ?? undefined,
      description: syncModel.description ?? undefined,
      keywords: ((syncModel.keywords as string) || '')
        .split(',')
        .map(x => x.trim())
        .filter(x => isNonEmptyString(x)),
      tags: ((syncModel.tags as string) || '')
        .split(',')
        .map(x => x.trim())
        .filter(x => isNonEmptyString(x)),
      features: (syncModel.enrichmentLevel && generateJobLevelOutput(syncModel.enrichmentLevel)) ?? getDefaultJobLevelInput(),
      medicalSpecialty: syncModel.speciality && getJobSpecialityOutput(syncModel.speciality),
      speciality: syncModel.speciality,
      metadata: syncModel.metadata,
      videoType: syncModel.videoType
    }
  }

  generateUpdateJobParams(): UpdateJobInput | null {
    if (!this.uploadToken || !this.syncModel || !this.syncModelId)
      return null;

    const { syncModel } = this;
    return {
      id: this.syncModelId,
      title: syncModel.title ?? undefined,
      isPublic: syncModel.isPublic ?? undefined,
      description: syncModel.description ?? undefined,
      keywords: ((syncModel.keywords as string) || '')
        .split(',')
        .map(x => x.trim())
        .filter(x => isNonEmptyString(x)),
      tags: ((syncModel.tags as string) || '')
        .split(',')
        .map(x => x.trim())
        .filter(x => isNonEmptyString(x)),
      posterToken: syncModel.posterToken || undefined,
      metadata: syncModel.metadata,
      videoType: syncModel.videoType
    }
  }

  generateUpdateJobThumbnailParams(): UpdateJobInput | null {
    if (!this.uploadToken || !this.syncModel || !this.syncModelId || !this.syncModel?.posterToken)
      return null;

    return {
      id: this.syncModelId,
      posterToken: this.syncModel?.posterToken || undefined,
    }
  }

  generateUpdateJobSourceParams(): MutationUpdateJobSourceArgs | null {
    if (!this.uploadToken || !this.syncModel || !this.syncModelId)
      return null;

    return {
      source: {
        type: JobSourceType.Upload,
      },
      jobId: this.syncModelId,
      uploadToken: this.uploadToken
    }
  }

  @action
  async submitThumbnailUpload() {
    if (!this.jobId)
      return [null, true];

    const thumbnailFile = this.syncModel?.thumbnail;
    if (!thumbnailFile)
      return [null, null];

    const input: ApiVariables<'getJobThumbnailUploadURL'> = {
      input: {
        id: this.jobId,
        filename: thumbnailFile.name
      }
    }

    // Get the upload URL
    const [res, err] = await this.store.apiService.getJobThumbnailUploadURL(input);

    if (err || !res)
      return [null, true];

    const params = res.getJobThumbnailUploadURL;
    const uploadParams: ApiUploadRequestParams = {
      file: thumbnailFile,
      url: params.uploadUrl,
      data: params.fields
    }

    // Start the upload request
    const uploadRequest = this.store.apiService.uploadRequest(uploadParams);
    const [, uploadErr] = await uploadRequest.start();

    if (uploadErr)
      return [null, true];

    const updateParams = {
      posterToken: params.uploadToken,
    }

    this.updateSyncModel(updateParams);

    return [updateParams, false];
  }

  async submitStartJob() {
    const input = this.generateStartJobParams();

    if (!input)
      return [null, 'Request error.'];

    const [job, jobErr] = await this.store.apiStartJob(input);

    if (jobErr)
      return [null, jobErr];

    const [, errFile] = await this.submitUpdateJobThumbnail();

    if (errFile)
      console.error('File upload error:', errFile);

    return [job, null];
  }

  async submitUpdateJob() {
    const [, errFile] = await this.submitThumbnailUpload();

    if (errFile)
      console.error('File upload error:', errFile);

    const input = this.generateUpdateJobParams();

    if (!input)
      return [null, 'Request error.'];

    const [job, jobErr] = await this.store.apiUpdateJob(input);

    if (jobErr)
      return [null, jobErr];


    return [job, null];
  }

  async submitUpdateJobThumbnail() {
    const [, errFile] = await this.submitThumbnailUpload();

    if (errFile)
      return [null, new Error('File upload error:' + errFile)];

    const input = this.generateUpdateJobThumbnailParams();

    if (!input)
      return [null, 'Request error.'];

    const [job, jobErr] = await this.store.apiUpdateJob(input);

    if (jobErr)
      return [null, jobErr];

    return [job, null];
  }

  async submitUpdateJobSource() {

    const input = this.generateUpdateJobSourceParams();

    if (!input)
      return [null, 'Request error.'];

    const [job, jobErr] = await this.store.apiUpdateJobSource(input);

    if (jobErr)
      return [null, jobErr];

    return [job, null];
  }
}