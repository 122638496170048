import { action, computed, makeObservable, observable } from 'mobx';
import { duration } from '../../components/utils';
import { isDefinedObject, Maybe, Nullable, ObjectLiteral } from '../../core';
import { getTimeRegionsMergedDuration } from '../../core/time';
import { fromMoments, JobModel, MomentModel } from '..';
import { Store } from '../../store/store';
import { EntitySelector, SelectorCoreData } from '../selector';
import { BindingProps, StoreNode } from '../../store';
import { Speaker, SpeakerModel } from '../speaker';
import { SubTopic, Topic } from '../moment';
import { areConnected, matches } from './momentFilters';
import intersectionBy from 'lodash/intersectionBy';
import { speakerMatches } from '../speakers';

export const MOMENT_SELECTOR_STATE_KEY = 'momentSelector.state';

const DEFAULT_MODE: MomentSelectorMode = 'Topics';
const ALLOWED_MODES: Set<MomentSelectorMode> = new Set([
  'Topics',
  'Speakers'
]);

type Props = {
  jobId?: Nullable<string>;
}

export type MomentSelectorMode =
  'Topics' |
  'Speakers' |
  'Companies';

export type MomentSelectorQualifiedMode =
  'Video' |
  'Topics' |
  'TopicsVideo' |
  'Speakers' |
  'SpeakersVideo' |
  'Companies' |
  'CompaniesVideo';

export type MomentSelectorOutput = {

  mode: MomentSelectorMode,
  isVideoModeEnabled: boolean,

  topicGroupKeys: Set<string>,
  genericGroupKeys: Set<string>,
  speakerGroupKeys: Set<string>
}

export type MomentSelectorData = {
  mode: MomentSelectorMode,
  isVideoModeEnabled: boolean,

  topicSelector: SelectorCoreData<string>,
  subTopicSelector: SelectorCoreData<string>,
  speakerSelector: SelectorCoreData<string>
}

export class MomentSelector
  extends StoreNode {

  constructor(store: Store, props?: BindingProps<Props>) {
    super(store, props);
    makeObservable(this);

    this.topicSelector = new EntitySelector(this.store, {
      name: 'TopicSelector',
      entities: () => this.topics,
      filteredKeys: () => this.topicFilteredKeys
    });

    this.subTopicSelector = new EntitySelector(this.store, {
      name: 'SubTopicSelector',
      entities: () => this.subTopics,
      filteredKeys: () => this.subTopicFilteredKeys,
      useInvertedSelector: true
    });

    this.speakerSelector = new EntitySelector(this.store, {
      name: 'SpeakerSelector',
      entities: () => this.speakers,
      filteredKeys: () => this.speakerFilteredKeys
    });
  }

  @observable mode: MomentSelectorMode = 'Topics';
  @observable isVideoModeEnabled = false;

  @observable highlightedMomentId: string | null = null;

  @computed
  get highlightedMoment() {
    return this.job?.maybeGetMoment(this.highlightedMomentId);
  }

  @computed get isTopicsMode() {
    return this.mode === 'Topics';
  }
  @computed get isSpeakersMode() {
    return this.mode === 'Speakers';
  }
  @computed get isCompaniesMode() {
    return this.mode === 'Companies';
  }

  @computed get outputMode() {
    if (this.isEmpty || !this.mode || this.isVideoModeEnabled)
      return 'Video';
    return this.mode;
  }

  @computed get isTopicsOutputMode() {
    return this.outputMode === 'Topics';
  }
  @computed get isSpeakersOutputMode() {
    return this.outputMode === 'Speakers';
  }
  @computed get isCompaniesOutputMode() {
    return this.outputMode === 'Companies';
  }
  @computed get isVideoOutputMode() {
    return this.outputMode === 'Video';
  }

  @computed get qualifiedMode(): MomentSelectorQualifiedMode {
    return ((this.mode || '') + (this.isVideoOutputMode ? 'Video' : '')) as MomentSelectorQualifiedMode;
  }

  @computed get isImplicitVideoOutputMode() {
    return this.isEmpty || !this.mode;
  }

  @observable searchQuery: string | null = null;

  @action
  setSearchQuery(query: string) {
    this.searchQuery = query || null;
  }

  @action
  clearSearchQuery() {
    this.searchQuery = null;
  }

  // #region Resolved props
  @computed
  get jobId() {
    return this.resolvedProps.jobId;
  }
  // #endregion

  @computed
  get job(): Nullable<JobModel> {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed
  get isEmpty() {
    switch (this.mode) {
      case 'Topics':
        return this.topicSelector.isEmpty;

      case 'Speakers':
        return this.speakerSelector.isEmpty;
    }

    return true;
  }

  @computed
  get queryable() {
    return fromMoments(this.moments);
  }

  @computed
  get moments(): MomentModel[] {
    return this.job?.visibleMoments
      .filter(mom => !!mom.name || !!mom.description) || [];
  }

  @computed
  get speakers(): Speaker[] {
    return this.job?.speakers || [];
  }

  @computed
  get hasSpeakers(): boolean {
    return this.speakers.length > 0;
  }

  @computed
  get generics(): MomentModel[] {
    return this.moments.filter(mom => mom.isGeneric);
  }
  @computed
  get transcripts(): MomentModel[] {
    return this.moments.filter(mom => mom.isTranscript);
  }

  @action
  setHighlightedMoment(moment: MomentModel | string | null) {
    let id: string | null = null;
    if (isDefinedObject(moment))
      id = moment.id;
    else if (typeof moment === 'string')
      id = moment;

    this.highlightedMomentId = id;
  }

  @action
  clearHighlightedMoment() {
    this.highlightedMomentId = null;
  }

  // #region Topic selector
  // -------
  readonly topicSelector: EntitySelector<Topic>;

  @observable topicSearchQuery: string | null = null;

  @computed
  get topics(): Topic[] {
    return this.moments.filter(mom => mom.isTopic) as Topic[];
  }

  @computed
  get hasTopics(): boolean {
    return this.topics.length > 0;
  }

  @computed
  get selectedTopics(): Topic[] {
    return this.topicSelector.selectedEntities;
  }
  @computed
  get filteredTopics(): Topic[] {
    return this.topicSelector.visibleEntities;
  }
  @computed
  get filteredSelectedTopics(): Topic[] {
    return intersectionBy(
      this.selectedTopics,
      this.filteredTopics,
      top => top.id);
  }

  @computed
  get topicFilteredKeys(): Set<string> | null {

    let data = this.topics;

    const topQuery = this.topicSearchQuery;
    if (topQuery)
      data = data.filter(matches(topQuery));

    const query = this.searchQuery;
    if (query)
      data = data.filter(matches(query));

    return new Set(data.map(sub => sub.id));
  }

  isTopicSelected(top: Topic | string): boolean {
    return this.topicSelector.isSelected(top);
  }
  isTopicFiltered(top: Topic | string): boolean {
    return this.topicSelector.isFiltered(top);
  }

  @action
  toggleTopic(top: Topic | string) {
    this.topicSelector.toggle(top);
  }
  @action
  selectTopic(top: Topic | string) {
    this.topicSelector.select(top);
  }
  @action
  unselectTopic(top: Topic | string) {
    this.topicSelector.unselect(top);
  }
  @action
  selectAllTopics() {
    this.topicSelector.selectAll();
  }
  @action
  clearTopics() {
    this.topicSelector.clear();
  }

  @action
  setTopicSearchQuery(query: string) {
    this.topicSearchQuery = query || null;
  }
  @action
  clearTopicSearchQuery() {
    this.topicSearchQuery = null;
  }
  // #endregion



  // #region SubTopic selector
  // -------
  readonly subTopicSelector: EntitySelector<SubTopic>;

  @observable subTopicSearchQuery: string | null = null;

  @computed
  get subTopics(): SubTopic[] {
    return this.moments.filter(mom => mom.isSubtopic) as SubTopic[];
  }

  @computed
  get hasSubTopics(): boolean {
    return this.subTopics.length > 0;
  }

  @computed
  get selectedSubTopics(): SubTopic[] {
    return this.subTopicSelector.selectedEntities;
  }
  @computed
  get filteredSubTopics(): SubTopic[] {
    return this.subTopicSelector.visibleEntities;
  }
  @computed
  get filteredSelectedSubTopics(): SubTopic[] {
    throw new Error('NotImplemented');
    // return this.subTopicSelector.visibleEntities;
  }

  @computed
  get subTopicFilteredKeys(): Set<string> | null {

    let data = this.subTopics
      // only show subtopics connected to visible selected topics
      .filter(sub => this.filteredSelectedTopics.some(top => areConnected(sub, top)));

    // suppress search for now

    // const subQuery = this.subTopicSearchQuery;
    // if (subQuery)
    //   data = data.filter(matches(subQuery));

    // const query = this.searchQuery;
    // if (query)
    //   data = data.filter(matches(query));

    return new Set(data.map(sub => sub.id));
  }

  isSubTopicSelected(top: SubTopic | string): boolean {
    return this.subTopicSelector.isSelected(top);
  }
  isSubTopicFiltered(top: SubTopic | string): boolean {
    return this.subTopicSelector.isFiltered(top);
  }

  @action
  toggleSubTopic(top: SubTopic | string) {
    this.subTopicSelector.toggle(top);
  }
  @action
  selectSubTopic(top: SubTopic | string) {
    this.subTopicSelector.select(top);
  }
  @action
  unselectSubTopic(top: SubTopic | string) {
    this.subTopicSelector.unselect(top);
  }
  @action
  selectAllSubTopics() {
    this.subTopicSelector.selectAll();
  }
  @action
  clearSubTopics() {
    this.subTopicSelector.clear();
  }


  @action
  setSubTopicSearchQuery(query: string) {
    this.subTopicSearchQuery = query || null;
  }
  @action
  clearSubTopicSearchQuery() {
    this.subTopicSearchQuery = null;
  }

  @action
  selectSubTopicDefaults() {
    this.selectAllSubTopics();
  }

  getSubTopicsForTopic(topic: Topic | string): SubTopic[] {
    return this.topicSelector.get(topic)?.childSubTopics || [];
  }
  getSelectedSubTopicsForTopic(topic: Topic | string): SubTopic[] {
    return this.getSubTopicsForTopic(topic)
      .filter(sub => this.isSubTopicSelected(sub));
  }

  areAllSubTopicsSelectedForTopic(topic: Topic | string): boolean {
    return this.getSubTopicsForTopic(topic)
      .every(sub => this.isSubTopicSelected(sub));
  }
  areNoSubTopicsSelectedForTopic(topic: Topic | string): boolean {
    return !this.getSubTopicsForTopic(topic)
      .some(sub => this.isSubTopicSelected(sub));
  }
  /**
   * Returns true if some, **but not all**, subtopics are selected for the provided subtopic.
   */
  areSomeSubTopicsSelectedForTopic(topic: Topic | string): boolean {
    return (
      !this.areAllSubTopicsSelectedForTopic(topic) &&
      this.getSubTopicsForTopic(topic)
        .some(sub => this.isSubTopicSelected(sub)));
  }
  // #endregion


  // #region Speaker selector
  // -------
  readonly speakerSelector: EntitySelector<Speaker>;

  @computed
  get selectedSpeakerIds(): Set<string> {
    return this.speakerSelector.selectedKeys;
  }

  @computed
  get selectedSpeakers(): Speaker[] {
    return this.speakerSelector.selectedEntities;
  }

  @computed
  get filteredSpeakers(): Speaker[] {
    return this.speakerSelector.visibleEntities;
  }
  @computed
  get filteredSelectedSpeakers(): Speaker[] {
    return intersectionBy(
      this.selectedSpeakers,
      this.filteredSpeakers,
      top => top.id);
  }

  @computed
  get speakerFilteredKeys(): Set<string> | null {

    let data = this.speakers;

    const query = this.searchQuery;
    if (query)
      data = data.filter(speakerMatches(query));

    return new Set(data.map(sub => sub.id));
  }

  isSpeakerSelected(speaker: Maybe<SpeakerModel | string>) {
    if (!speaker)
      return false;
    return this.speakerSelector.isSelected(speaker);
  }
  isSpeakerFiltered(speaker: Maybe<SpeakerModel | string>) {
    if (!speaker)
      return false;
    return this.speakerSelector.isFiltered(speaker);
  }

  @action
  toggleSpeaker(speaker: SpeakerModel | string) {
    return this.speakerSelector.toggle(speaker);
  }
  @action
  selectSpeaker(speaker: SpeakerModel | string) {
    return this.speakerSelector.select(speaker);
  }
  @action
  unselectSpeaker(speaker: SpeakerModel | string) {
    return this.speakerSelector.unselect(speaker);
  }
  @action
  selectAllSpeakers() {
    return this.speakerSelector.selectAll();
  }
  @action
  clearSpeakers() {
    return this.speakerSelector.clear();
  }
  // #endregion


  @action
  selectAll() {
    switch (this.mode) {
      case 'Topics':
        this.selectAllTopics();
        break;

      case 'Speakers':
        this.selectAllSpeakers();
        break;
    }
  }

  @action
  unselectAll() {
    this.clearSpeakers();
    this.selectSubTopicDefaults();
    this.clearTopics();
  }

  @computed
  get selectedTotalDuration() {
    return duration(getTimeRegionsMergedDuration(this.selectedTopics));
  }

  @computed
  get selectedTotalSavedDuration() {
    const { job } = this;
    if (!job)
      return duration(0);
    return duration(
      (job.videoDuration - this.selectedTotalDuration.as('seconds')) || 0);
  }

  @action
  clear() {
    this.unselectAll();

    this.clearSubTopicSearchQuery();
    this.clearTopicSearchQuery();
  }

  @action
  setMode(mode: MomentSelectorMode | null) {
    this.mode = (mode && ALLOWED_MODES.has(mode)) ? mode : DEFAULT_MODE;
  }

  @action
  setModeToTopics() {
    this.setMode('Topics');
  }
  @action
  setModeToSpeakers() {
    this.setMode('Speakers');
  }

  @action
  enterVideoMode() {
    this.isVideoModeEnabled = true;
  }
  @action
  exitVideoMode() {
    this.isVideoModeEnabled = false;
  }




  @action
  reset() {
    this.clear();
    this.setHighlightedMoment(null);
    this.setModeToTopics();
  }

  export(): MomentSelectorData {
    this.purge();

    return {
      mode: this.mode,
      isVideoModeEnabled: this.isVideoModeEnabled,

      topicSelector: this.topicSelector.export(),
      subTopicSelector: this.subTopicSelector.export(),
      speakerSelector: this.speakerSelector.export()
    }
  }

  @action
  import(data: MomentSelectorData): this {

    this.clear();

    this.setMode(data.mode);
    this.isVideoModeEnabled = data.isVideoModeEnabled;

    if (data.topicSelector)
      this.topicSelector.import(data.topicSelector);
    if (data.subTopicSelector)
      this.subTopicSelector.import(data.subTopicSelector);
    if (data.speakerSelector)
      this.speakerSelector.import(data.speakerSelector);

    this.purge();
    return this;
  }

  @action
  serialize(): string {
    return JSON.stringify(this.export());
  }

  @action
  deserialize(data?: string | null): this {
    if (!data) {
      return this;
    }

    this.import(JSON.parse(data));
    return this;
  }

  save() {
    const { storage } = this.store;
    if (!this.jobId)
      return false;

    const stateStr = storage.getLocal(MOMENT_SELECTOR_STATE_KEY);
    let state: ObjectLiteral<MomentSelectorData> = {};
    if (stateStr) {
      try {
        state = JSON.parse(stateStr);
      } catch (e) {
        state = {};
      }
    }

    if (!isDefinedObject(state))
      state = {};

    state[this.jobId] = this.export();

    storage.setLocal(MOMENT_SELECTOR_STATE_KEY, JSON.stringify(state));
  }

  @action
  load() {
    const { storage } = this.store;
    if (!this.jobId)
      return false;

    const stateStr = storage.getLocal(MOMENT_SELECTOR_STATE_KEY);
    let state: ObjectLiteral<MomentSelectorData> = {};
    if (stateStr) {
      try {
        state = JSON.parse(stateStr);
      } catch (e) {
        state = {};
      }
    }

    if (!isDefinedObject(state))
      state = {};

    const data = state[this.jobId];
    if (isDefinedObject(data))
      this.import(data);
  }


  /** Removes all selector keys that don't match any moments. */
  @action
  purge() {
    this.topicSelector.purge();
    this.subTopicSelector.purge();
    this.speakerSelector.purge();
  }

  /** Invoked when a moment has been newly added or updated. */
  @action
  handleMomentMutated(momId: string) {
    const mom = this.job?.maybeGetMoment(momId);
    if (!mom)
      return;

    switch (this.outputMode) {
      case 'Topics':
        if (mom.isTopic) {
          this.topicSelector.select(mom as Topic);
          break;
        }

        if (mom.isSubtopic) {
          this.subTopicSelector.select(mom as SubTopic);
          break;
        }
        break;

      case 'Speakers':
        if (mom.hasSpeaker && mom.actualSpeakerId) {
          this.speakerSelector.select(mom.actualSpeakerId);
          break;
        }
        break;
    }

    this.purge();
  }

  handleMomentDeleted(momId: string) {
    this.purge();
  }
}