import classNames from 'classnames';
import { LocationDescriptor } from 'history';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { BackRoutes, RouteParams, Routes } from '../../routes';
import { DotSpinner, PlaySVGIcon, TextInputWarningSVGIcon, VisibleOnSVGIcon } from '../../components';
import { Thumb } from '../../components/media/thumb';
import { useStore } from '../../store/storeHooks';
import { Maybe } from '../../core';
import { MomentResultItem } from './momentResultsCatalog';
import { sanitizeHtml } from './util';
import { Link } from '../../components/layout';
import { JobModel } from '../../entities';
import { WidgetAuthMode } from '../../services/widget';

type VideoCardProps = {
  model: JobModel;
};

type MomentCardProps = {
  model: MomentResultItem;
};

type Props = {
  isLoading?: boolean;
  className?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const SeeMoreCard = observer((props: Props) => {
  const previewStyle: CSSProperties = {
    ['--aspect-ratio' as any]: 9 / 16,
  };
  return (
    <div
      className={`search-result-card empty-card see-more ${props.className}`}>
      <button
        onClick={props.onClick}
        className="card-preview aspect-ratio-box"
        style={previewStyle}>
        <div className="card-thumbs-empty">
          {props.isLoading ? (
            <DotSpinner className="orange" />
          ) : (
            <>
              <div className="bookmark-thumb-icon">
                <span className="icon">
                  <VisibleOnSVGIcon />
                </span>
              </div>
              <p>See more</p>
            </>
          )}
        </div>
      </button>
    </div>
  );
});

export const SearchResultVideoCard = ({ model }: VideoCardProps) => {
  const store = useStore();
  const { search } = useLocation();

  const teamId = new URLSearchParams(search).get('teamId') || undefined;
  const posterURL =
    (model.poster && model.poster.length > 0 && model.poster[0]?.url) || '';
  const isWidgetMode = store.widget.isWidgetMode;
  let linkRoute;

  if (!model.isPublished) {
    linkRoute = null;
  } else if (isWidgetMode) {
    const auth = store.widgetService.authMode;
    linkRoute = Routes.playerWidget(model.id, { teamId, authMode: auth });
  } else {
    linkRoute = Routes.userVideo(model.id, teamId ? `teamId=${teamId}` : '');
  }

  const cx = classNames('job-card', 'search-result-card', 'video', {
    'status-is-unpublish': !model.isPublished
  });

  const previewStyle: CSSProperties = {
    ['--aspect-ratio' as any]: 9 / 16,
  };

  let previewElem: Maybe<ReactNode>;

  if (!model.isPublished) {
    previewElem = (
      <div className="card-status progress dock">
        <Thumb source={posterURL} className="card-thumb dock" />
        <div className="card-overlay unpublish">
          <TextInputWarningSVGIcon />
          <p>Video is no longer available</p>
          <p className="small">If you think this is an error, please <a href={'mailto:' + process.env.REACT_APP_EMAIL_QA}>Contact Us &#8594;</a></p>
        </div>
      </div>
    );
  } else {
    previewElem = (
      <div className="card-status progress dock status-done">
        <Thumb
          showPlaceholder={model.media?.basic && !posterURL ? true : false}
          source={posterURL} className="card-thumb dock" />
        <div className="play-icon">
          <PlaySVGIcon />
        </div>
      </div>
    );
  }


  return (
    <div className={cx}>
      <Link
        to={linkRoute as any}
        className="card-preview aspect-ratio-box"
        style={previewStyle}>
        {previewElem}
      </Link>
      <Link
        to={linkRoute as any}
        className="card-title search-result-video-source">
        <div className="snippet">
          {model.highlights
            ? sanitizeHtml(model.highlights[0].snippets[0])
            : model.title}
        </div>
      </Link>
    </div>
  );
};

export const SearchResultMomentCard = observer(({ model }: MomentCardProps) => {
  const store = useStore();
  const { search, pathname } = useLocation();
  const teamId = new URLSearchParams(search).get('teamId') || undefined;
  const searchKey = new URLSearchParams(search).get('searchKey') || undefined;
  const isWidgetMode = store.widget.isWidgetMode;
  let linkRoute;

  if (isWidgetMode) {
    const auth = store.widgetService.authMode || WidgetAuthMode.None;
    linkRoute = Routes.playerWidget(model.jobId, {
      teamId,
      momentId: model.id,
      authMode: auth
    });
  } else {
    const routeParams: RouteParams = {
      id: model.jobId,
      momentId: model.id,
      teamId,
      currentPath: pathname,
      searchKey,
    };
    linkRoute = BackRoutes[Routes.search].route(routeParams);
  }

  const thumbnailURL =
    (model.thumbnail &&
      model.thumbnail.length > 0 &&
      model.thumbnail[0]?.url) ||
    '';

  const previewStyle: CSSProperties = {
    ['--aspect-ratio' as any]: 9 / 16,
  };

  return (
    <div className="search-result-card topic">
      <Link
        to={linkRoute as LocationDescriptor}
        className="card-preview aspect-ratio-box"
        style={previewStyle}>
        <div className="card-status progress dock status-done">
          <Thumb source={thumbnailURL} className="card-thumb dock" />
          <div className="play-icon">
            <PlaySVGIcon />
          </div>
        </div>
      </Link>

      <Link
        to={linkRoute as LocationDescriptor}
        className="card-title search-result-video-source">
        {model.clipType === 'Moment'
          ? `Moment: ${model.momentType}`
          : model.clipType}
      </Link>

      <Link
        to={linkRoute as LocationDescriptor}
        className="card-clip-type search-result-video-title">
        <span className="text">{model.job?.title}</span>
      </Link>

      <Link
        to={linkRoute as LocationDescriptor}
        className="card-clip-type snippet-container">
        {model.highlights?.map(({ field, snippets }, index) => (
          <div key={index}>
            {(field === 'name' || field === 'description') && (
              <div className="snippet">“{sanitizeHtml(snippets[0])}”</div>
            )}
            {field === 'keywords' && (
              <div className="snippet">
                <em>Keywords: {sanitizeHtml(snippets[0])}</em>
              </div>
            )}
          </div>
        ))}
      </Link>
    </div>
  );
});
