import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { PlayerState } from '../playerState';
import { PlayerSpeakerAvatar } from '../../playerShared/playerSpeakerAvatar';

type Props = {
  className?: string,
  model: PlayerState,
  showAvatar?: boolean
}

export const ActiveSpeakerCard = observer(({
  model,
  className
}: Props) => {
  const {
    showActiveSpeaker,
    activeSpeaker
  } = model.momentView;

  if (!showActiveSpeaker)
    return null;

  className = classNames(
    className,
    'user-player-active-speaker-card',
    'user-player-active-item-card',
    {
      'empty': !activeSpeaker
    });

  return (
    <div className={className}>
      <div className="card-gutter">
        <PlayerSpeakerAvatar
          layout="idle"
          model={activeSpeaker} />
      </div>

      <div className="card-details">
        <div className="card-heading">Current speaker</div>
        <div className="card-name">{activeSpeaker?.name ?? 'No active speaker'}</div>
      </div>
    </div>
  );
});
