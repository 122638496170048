import './playerIndex.scss';
import React, { CSSProperties, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { BoxesSVGIcon } from '../svg';
import { PlayerIndexState } from './playerIndexState';
import { PlayerSectionHeaderButton } from '../playerShared/playerSectionHeaderButton';
import { PlayerSectionCloseButton } from '../playerShared/playerSectionCloseButton';
import { ExpandToggle } from '../playerShared/expandToggle';
import { PlayerIndexTopicList } from './playerIndexTopicList';
import { SpeakerAvatarList } from './playerIndexSpeakerList';
import { PlayerSectionOverlay } from '../playerShared/playerSectionOverlay';
import { LoadMask } from '../../components';
import { SyncStatus } from '../../entities';
import { ErrorMessageBox } from '../player/components/errorMessageBox';
import { PlayerComponentName } from '../player/playerSchema';
import { PlayerSpeakers } from '../playerSpeakers/playerSpeakers';
import { SpeakerIdNotification } from '../speakerId/speakerIdNotification';
import { SpeakerIdForm } from '../speakerId/speakerIdForm';

export type PlayerIndexProps = {
  model: PlayerIndexState,
  layout?: string,
  showHeader?: boolean
  className?: string,
  onClose?: () => void
}

export const PlayerIndex = observer(({
  model,
  layout = 'vertical',
  showHeader = true,
  className,
  onClose
}: PlayerIndexProps) => {

  useEffect(() => {
    model.mounted();
    return () =>
      model.unmounted();
  }, [model]);

  className = classNames('user-player-index user-player-section page-section', className, layout, {
    'with-header': showHeader,
    'expanded-header': model.isSpeakerListExpanded,
    'with-notification': model.showSpeakerIdNotification
  });
  const isLoading = model.momentsSyncStatus === SyncStatus.Fetching;
  const isDisabled = model.player.isComponentDisabled(PlayerComponentName.IndexSection);

  if (model.isSpeakerIdMode) {
    return (
      <PlayerSpeakers
        model={model.playerSpeakers}
        onClose={() => {
          const { speakerIdController } = model;
          model.frameset?.hideSection('Index');
          speakerIdController?.exitSpeakerIdMode();
        }} />)
  }

  const indexStyle: CSSProperties = {
    ['--max-speaker-list-height' as any]: model.maxSpeakerListHeight + 'px',
    ['--speaker-list-transition-duration' as any]: model.transitionDuration + 's'
  };

  return (
    <section
      id="topic-index"
      data-tutorial-id="topic-index"
      className={className}
      aria-disabled={isDisabled}
      data-loading={isLoading}
      ref={model.sectionProxy.ref}
      style={indexStyle}>

      {model.showSpeakerIdNotification &&
        <div className="player-section-notification">
          <SpeakerIdNotification />
        </div>}

      <PlayerIndexSectionBody
        model={model} />

      <PlayerIndexSectionHeader
        model={model} />

      <PlayerSectionCloseButton
        model={model}
        onClose={onClose} />

      <PlayerSectionOverlay
        visible={model.showOverlay}
        label={"Topic Index"}
        status={"processing"}
      />
    </section>
  );
});

const SpeakerAvatarListBlock = observer(({
  model,
}: PlayerIndexProps) => {

  const {
    isSpeakerListExpanded: isExpanded,
    toogleSpeakerList: onExpand
  } = model;

  const speakerModelList = model.activeSpeakerList;

  const className = classNames("user-player-speaker-list ", {
    empty: speakerModelList?.length === 0,
    expanded: isExpanded
  });

  if (!speakerModelList)
    return null;

  return <div className={className}>
    {speakerModelList?.length > 0 &&
      <SpeakerAvatarList model={model} />}

    {(speakerModelList?.length > 7 || model.showSpeakerIdForm) &&
      <div className="speaker-list-actions">
        <ExpandToggle onExpand={onExpand}
          className={"speaker-list-expand-btn"}
          isExpanded={isExpanded} />
      </div>}

    {model.showSpeakerIdForm && <div className="speaker-form">
      <SpeakerIdForm model={model.speakerIdForm} />
    </div>}

    {speakerModelList?.length === 0 &&
      <div className="speakers-empty flex-center">
        <div className="message">No speakers here.</div>
      </div>}
  </div>
});

const PlayerIndexSectionHeader = observer(({
  model,
}: PlayerIndexProps) => {

  const { isJumpToCurrentDisabled } = model;

  const className = classNames('user-player-section-header', {
    expanded: model.isSpeakerListExpanded
  });

  return <header className={className}
    id="user-player-index-section-header">

    <div className="section-row flex-space-between">
      <h3 className="section-heading">
        <span className="icon"><BoxesSVGIcon /></span>
        <span className="text">Topics index</span>
      </h3>
      <PlayerSectionHeaderButton
        id="jump-to-current-index"
        model={model}
        disabled={isJumpToCurrentDisabled} />
    </div>

    <div className="section-row">
      <SpeakerAvatarListBlock
        model={model} />
    </div>
  </header>
})

const PlayerIndexSectionBody = observer(({
  model,
}: PlayerIndexProps) => {

  const { player } = model;
  const { itemSource } = player;
  const topics = itemSource.indexSectionTopics;
  const { momentsSyncStatus } = model;

  return <div className="user-player-section-body"
    id="user-player-clip-scroll"
    ref={model.scrollElementProxy.ref}
    onScroll={model.handleScroll}>

    {momentsSyncStatus !== SyncStatus.Error &&
      <>
        <div className="index-empty flex-center"
          aria-hidden={topics.length > 0}>
          <div className="message">No topics here.</div>
        </div>

        <PlayerIndexTopicList model={model} />

        {momentsSyncStatus === SyncStatus.Fetching && (
          <LoadMask
            className="page-load-mask player-background user-player-section-load-mask small"
            spinner="dots3"
            spinnerClassName="medium"
            visible={momentsSyncStatus === SyncStatus.Fetching} />
        )}
      </>
    }

    {momentsSyncStatus === SyncStatus.Error &&
      <ErrorMessageBox
        content={'Could not fetch moments because of an error.'}
      />
    }
  </div>
})