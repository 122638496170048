import { StoreNode } from '../../../store';
import { AuthStepOrchestrator } from '../authStepOrchestrator';
import { AsyncResult } from '../../../core';
import { AuthFlowName, AuthFlowResponse, IAuthFlow } from '../authFlowSchema';
import { ClientMode } from '../../../kernel/kernelSchema';
import { Error } from '../../../core/error';
import { AnalyticsEventTypes } from '../../analytics/analyticsSchema';

export class LogoutFlow
  extends StoreNode
  implements IAuthFlow {

  readonly flowName = AuthFlowName.Logout;
  readonly orchestrator = new AuthStepOrchestrator(this.store);

  async run(): AsyncResult<AuthFlowResponse> {

    const { routingService } = this.store;

    const { orchestrator } = this;
    const { clientMode } = this.store.kernel;

    if (clientMode === ClientMode.Widget)
      return [null, new Error('InternalError', `The Logout flow is not supported in widget mode.`)];

    routingService.clearStorage();
    routingService.setStorageAuthFlow(AuthFlowName.Logout);

    // TODO: move this crap outta here
    this.store.analyticsService.registerEvent(
      AnalyticsEventTypes.AuthType,
      {
        type: 'logout'
      }
    )

    orchestrator.invalidate();

    const [, logoutErr] = await orchestrator.logout();
    if (logoutErr)
      return orchestrator.setError(logoutErr);

    // then wait for a redirect
    return orchestrator.setAwaitRedirect();
  }

  async restoreAfterRedirect(): AsyncResult<AuthFlowResponse> {

    const { orchestrator } = this;

    // todo: add error checking
    orchestrator.invalidate();

    return orchestrator.setRedirectToLoginPage();
  }

  async restoreAfterRedirectAsDefault(): AsyncResult<AuthFlowResponse> {
    return this.restoreAfterRedirect();
  }
}