import './widgetErrorPages.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { PageBackButton, TextInputWarningSVGIcon } from '../../components';
import { RouteProps } from 'react-router-dom';
import { WidgetProfileCard } from '../../components/widgets/widgetProfileCard';
import { Error } from '../../core/error';
import { History } from 'history';

type Props = RouteProps & {
  error: Error;
  showBackButton?: boolean;
  backButtonRoute?: string | ((router: History<any>) => void) | null;
  onLogoutButtonClick?: React.MouseEventHandler;
  onLoginButtonClick?: React.MouseEventHandler;
};

export const WidgetErrorPage = observer(({
  error,
  showBackButton,
  backButtonRoute,
  onLogoutButtonClick,
  onLoginButtonClick
}: Props) => {

  const message = error.message;
  return (
    <div className="dock flex-center widget-error-page">
      {backButtonRoute && (
        <div className="tpad4 lpad4 back flex-left gap4">
          {showBackButton && backButtonRoute &&
            <PageBackButton
              className="mr-2"
              route={backButtonRoute} />}

          <WidgetProfileCard
            onLogoutButtonClick={onLogoutButtonClick}
            onLoginButtonClick={onLoginButtonClick} />
        </div>
      )}

      <TextInputWarningSVGIcon />
      <p>
        {message ?? 'Cannot access page because of an error.'}
      </p>
      <p className="small">If you think this is an error, please <a href={'mailto:' + process.env.REACT_APP_EMAIL_QA}>Contact Us &#8594;</a></p>
    </div>
  );
});