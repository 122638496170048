import '../authPages.scss';

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import {
  BasicPage,
  ButtonControlled,
  Feedback,
  TextInputControlled,
  CliprLogoSVGIcon,
  LoadMask,
  Link
} from '../../../components';

import { RegisterPageState } from './registerPageState';
import { Routes } from '../../../routes';
import { SocialLoginBox } from '../social';
import { InvalidPasswordFeedback } from '.';
import { AuthFlowResponseType } from '../../../services/auth/authFlowSchema';
import { AuthFlowResponseInterpreter } from '../../../components/auth';

type Props = {
  model: RegisterPageState
} & Partial<RouteComponentProps>;

export const RegisterPage = observer(({
  model
}: Props) => {

  useEffect(() => {
    model.pageMounted();
    return () =>
      model.pageUnmounted();
  }, [model]);

  const { form } = model;
  const response = form.response;

  if (response) {
    switch (response.responseType) {
      case AuthFlowResponseType.RedirectToLastPrivateRoute:
        return <AuthFlowResponseInterpreter response={response} />
    }
  }

  return (
    <BasicPage
      className={`login-page auth-page dock ${model.layout}`}
      showHeader={false}
      showWatermark={false}>

      <div className="auth-splash">
        {model.layout === 'poster' && <div className="login-poster auth-poster">
          <div className="clipr-logo">
            <CliprLogoSVGIcon />
          </div>
          <div className="headline">
            <h1>Welcome to CLIPr!</h1>
            <h2>Review just the key moments of video meetings.</h2>
          </div>

        </div>}
        <div className="auth-container">
          <div className="login-panel auth-panel">
            <header className="login-header auth-header">
              {model.layout !== 'poster' && <div className="clipr-logo">
                <CliprLogoSVGIcon />
              </div>}
              <h2>
                Create your free account
              </h2>
              <p className="action-message">Start finding the moments that matter most</p>
            </header>

            <div className="text-separator">Sign up with</div>

            <div className="login-social auth-social">
              <SocialLoginBox
                onProviderClick={form.handleSocialLoginButtonClick} />
            </div>

            <div className="text-separator">or sign up with email</div>

            <form id="registerForm"
              className="login-form auth-form"
              onSubmit={form.handleSubmit}>

              <div className="row">
                <Feedback model={form.formGroup}
                  message={<InvalidPasswordFeedback message={form.formGroup.statusMessage} />}
                  id="registerForm_feedback"
                  aria-live="polite"
                  data-status={form.formGroup.status}
                  data-error-code={form.error}
                  className="text-center" />
              </div>

              <div className="row">
                <TextInputControlled
                  id="registerForm_firstName"
                  label="First Name"
                  model={form.firstName} />
              </div>

              <div className="row">
                <TextInputControlled
                  id="registerForm_lastName"
                  label="Last Name"
                  model={form.lastName} />
              </div>

              <div className="row">
                <TextInputControlled
                  id="registerForm_email"
                  label="Email Address"
                  model={form.email} />
              </div>

              <div className="row">
                <TextInputControlled
                  id="registerForm_password"
                  type="password"
                  label="Password"
                  model={form.password} />
              </div>
              <div className="row text-center">
                <p>By signing up you are agreeing to CLIPR's <br />
                  <Link to={Routes.legalTerms()} target="_blank">Terms of Service</Link>
                  <span>and</span>
                  <Link to={Routes.legalPrivacy()} target="_blank">Privacy Policy</Link></p>
              </div>

              <div className="row vpad2 btn-group fill form-footer">
                <ButtonControlled
                  id="registerForm_submit"
                  model={form.submitButton}
                  className="submit-btn primary light"
                  type="submit"
                  label="Sign Up" />
              </div>
            </form>

            <p className="register-signin text-center">
              <span>Already have an account?</span>
              <Link to={Routes.login()}>Sign in</Link>
            </p>

            <p className="register-signin text-center">
              <span>Questions?</span>
              <a href={`mailto:email@product@clipr.ai?subject=Question from CLIPr's signup form`}>Contact us</a>
            </p>

            <LoadMask
              className="partial"
              visible={form.showLoadMask}
              spinner="dots5" />
          </div>
        </div>
      </div>
    </BasicPage>
  );
});

export default RegisterPage;