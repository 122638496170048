import React from 'react';
import { observer } from 'mobx-react-lite';
import { ParentSize } from '@visx/responsive';
import { ReactionsChartState } from './reactionsChartState';
import { XYSeriesChart, XYSeriesChartControlled } from '../xySeriesChart';
import {
  InputPlayerReactionName,
  InputPlayerReactionNames,
} from '../../playerReactions/playerReactionSchema';
import {
  HotReactionSVGIcon,
  InsightfulReactionSVGIcon,
  FunnyReactionSVGIcon,
  LoveReactionSVGIcon,
  EngagingReactionSVGIcon,
  ConfusingReactionSVGIcon,
} from '../../svg/reactionSvgIcons';
import { ChartLegendProps } from '../utils/chartLegend';
import { GlyphProps } from '../xySeriesChart';
import { DefaultThemeConfig } from '../utils/defaultThemeConfig';
import { DefaultTooltip } from '../utils/defaultTooltip';
import { ChartWrapper } from '../chartWrapper';
import { DropdownInputControlled } from '../..';
import { ReactionChartMode } from './reactionsChartSchema';

type Props = {
  model: ReactionsChartState;
};

export const ReactionsChart = observer(({ model }: Props) => {
  const { dataSource } = model;
  const teamId = model.params?.teamId;
  const { isFetching: showLoadMask, error } = dataSource;

  // NOTE: do not access model.outputData directly in XYSeriesChart
  // this is because XYSeriesChart is rendered within a callback to ParentSize, which I suspect is run async
  const data = model.outputData;

  const hasData = !!(data?.[0]?.data?.length > 0);
  const showNoDataMask = !hasData && !showLoadMask;

  let overlayMessage;
  if (error) {
    overlayMessage = { code: 'Error', message: 'An error has occured' };
  } else if (showNoDataMask) {
    overlayMessage = { code: 'NoData' };
  }

  const mappedIcons = InputPlayerReactionNames.map(
    (key: InputPlayerReactionName) => {
      return {
        dataKey: key,
        glyph: getReactionIcon(key),
      };
    }
  );

  const legendItemsByReactionType: ChartLegendProps = {
    showGlyph: true,
    showLineColor: true,
    showLabel: false,
    legendItems: mappedIcons,
  };

  const legendItemsBySource: ChartLegendProps = {
    toggleData: true,
    showLabel: true,
    showLineColor: true,
  };

  const tooltipContent = teamId
    ? `Monitor reactions your library's videos generated over time to understand how your library’s content made viewers feel.`
    : `Monitor your viewers' reactions over time to understand how your content made them feel.`;

  const chartTitle = `${teamId ? 'Library' : 'My'} Engagement - Reactions`;

  const dropdownInput = (
    <DropdownInputControlled
      className="form-input"
      model={model.chartTypeInput}
      showFeedback={false}
      notDeselectable={true}
      persistentFeedback={false} />
  );

  return (
    <ChartWrapper
      title={chartTitle}
      tooltipContent={tooltipContent}
      showLoadMask={showLoadMask}
      overlayMessage={overlayMessage}
      tools={dropdownInput}>
      <ParentSize>
        {(parent) =>
          model.dataSource.mode === ReactionChartMode.JobSource ? (
            <XYSeriesChartControlled
              model={model.xySeriesChartState}
              series={data}
              legend={legendItemsBySource}
              themeConfig={DefaultThemeConfig}
              height={parent.height - 32}
              width={parent.width}
              curve="basis" />
          ) : (
            <XYSeriesChart
              height={parent.height - 32}
              width={parent.width}
              series={data}
              legend={legendItemsByReactionType}
              curve="basis"
              renderLineSeries={true}
              renderTooltip={(renderTooltipParams) => (
                <DefaultTooltip
                  {...renderTooltipParams}
                  showTooltipTitle={true}
                  showTotal={true}
                  showItemIcon={true}
                  showItemValue={true}
                  icons={mappedIcons} />
              )}
              themeConfig={DefaultThemeConfig} />
          )
        }
      </ParentSize>
    </ChartWrapper>
  );
});

const getReactionIcon = (reaction: InputPlayerReactionName): GlyphProps => {
  switch (reaction) {
    case 'Love':
      return () => <LoveReactionSVGIcon />;
    case 'Genius':
      return () => <InsightfulReactionSVGIcon />;
    case 'Fire':
      return () => <HotReactionSVGIcon />;
    case 'Funny':
      return () => <FunnyReactionSVGIcon />;
    case 'Engaging':
      return () => <EngagingReactionSVGIcon />;
    case 'Confusing':
      return () => <ConfusingReactionSVGIcon />;

    default:
      return () => <></>;
  }
};
