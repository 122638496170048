import { Error } from '../error';
import { Result } from '../types';

export type AsyncStatus =
  'idle' |
  'loading' |
  'error' |
  'success';

/** Async version of ResultTuple, which returns a Promise. */
export type AsyncResult<TResult = any, TError = any> =
  Promise<Result<TResult, TError>>;

export interface IAsyncTask<TResult = any, TError = any> {

  readonly isActive: boolean;
  error: TError;
  result?: TResult;

  start(): AsyncResult<TResult, TError>;
}

export type PromiseType<T> = T extends PromiseLike<infer U> ? U : T;

/**
 * Utility for returning a Promise that resolves after the provided milliseconds.
 */
export async function sleep<T>(timeout: number, val?: T): Promise<T> {
  return new Promise((res) => {
    setTimeout(() => res(val!), timeout);
  });
}

/**
 * Wraps an async method into a try catch block which will return an errored AsyncResult if any error occurs.
 */
export async function checked<TResult, TError>(fn: (...args: any[]) => AsyncResult<TResult, TError>): AsyncResult<TResult, any> {

  try {
    return await fn();
  } catch (err) {

    if (process.env.NODE_ENV !== 'production') {
      console.error('Checked function call failed! ', err);
    }

    // TODO: maybe add some error reporting features
    return [null, err];
  }
}

export async function timed<TResult>(fn: () => AsyncResult<TResult, any>, timeout: number): AsyncResult<TResult, any> {
  return new Promise(async (resolve) => {
    const timeoutId = setTimeout(() => {
      const err = new Error('Timeout', `The requested operation timed out.`);
      resolve([null, err]);
    }, timeout);

    const res = await fn();
    clearTimeout(timeoutId);
    resolve(res);
  });
}