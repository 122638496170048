import '../player.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { NotificationMessageErrorSVGIcon } from '../..';

type Props = {
  content?: string;
  className?: string;
};

export const ErrorMessageBox = observer((props: Props) => {

  return (
    <div className={`error-message-box ${props.className}`}>
      <NotificationMessageErrorSVGIcon />
      <div className="content">
        {props.content}
      </div>
    </div>
  );
});
