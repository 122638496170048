import React from 'react';
import { observer } from 'mobx-react-lite';
import { Word, WordCloudChartPropsBase, WordData, WordMouseEventHandler } from './wordCloudSchema';
import { scaleLog } from '@visx/scale';
import { Wordcloud } from '@visx/wordcloud';
import { Text } from '@visx/text';
import { getRotationDegree } from './wordCloudUtils';

const colors = ['#143059', '#2F6B9A', '#82a6c2'];

type Props =
  WordCloudChartPropsBase &
  Partial<{
    onWordMouseEnter: WordMouseEventHandler,
    onWordMouseLeave: WordMouseEventHandler,
    onWordMouseMove: WordMouseEventHandler
  }>;

/**
 * VISX Wordcloud is expensive to render, and if it's included directly into a component which is rendered very often
 * you will end up in some big performance issues. The point with this component is to make it render the inner Wordcloud
 * only when necessary.
 */
export const WordCloudContainer = observer(({
  words = [],
  width,
  height,
  spiralType = 'rectangular',
  withRotation = false,
  font = 'Impact',
  wordPadding = 2,
  onWordMouseEnter,
  onWordMouseLeave,
  onWordMouseMove
}: Props) => {

  const fontScale = scaleLog({
    domain: [
      Math.min(...words.map(w => w.value)),
      Math.max(...words.map(w => w.value))
    ],
    range: [16, 60]
  });

  const fontSizeSetter = (datum: WordData) => fontScale(datum.value);

  const fixedValueGenerator = () => 0.5;

  return (
    <Wordcloud
      words={words}
      width={width ?? 400}
      height={height ?? 400}
      fontSize={fontSizeSetter}
      font={font}
      padding={wordPadding}
      spiral={spiralType}
      rotate={withRotation ? getRotationDegree : 0}
      random={fixedValueGenerator}>

      {cloudWords => {
        return cloudWords.map((word: Word, i) => {
          return word.text && (
            <g key={word.text}
              className="word-cloud-chart-word"
              onMouseEnter={evt => onWordMouseEnter?.(evt, word)}
              onMouseLeave={evt => onWordMouseLeave?.(evt, word)}
              onMouseMove={evt => onWordMouseMove?.(evt, word)}>

              <Text
                key={Math.random()}
                fill={colors[i % colors.length]}
                textAnchor={'middle'}
                transform={`translate(${word.x}, ${word.y}) rotate(${word.rotate})`}
                fontSize={word.size}
                fontFamily={word.font}>

                {word.text}
              </Text>
            </g>
          );
        })
      }
      }
    </Wordcloud>
  );
});