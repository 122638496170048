import React from 'react';
import { observer } from 'mobx-react-lite';
import { DropdownInputControlled } from '..';
import { TimeInputState } from './timeInputState';

import './timeInput.scss';

type Props = {
  model: TimeInputState;
};

export const TimeInput = observer(({ model }: Props) => {

  return (
    <div className="time-input-container">
      <DropdownInputControlled
        model={model.hour}
        notDeselectable={true}
        label="HH*" />

      <DropdownInputControlled
        model={model.minutes}
        notDeselectable={true}
        label="MM*" />

      <DropdownInputControlled
        className="timezone"
        model={model.timezone}
        notDeselectable={true}
        label="Time Zone*" />
    </div>
  )
})