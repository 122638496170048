import { Maybe } from './types';
import { assert } from './assert';
import { isDefined } from './types';
import pickBy from 'lodash/pickBy';

export type ObjectLiteral<TValue = any> = { [key: string]: TValue };

export function isValue<T>(arg: Maybe<T>): arg is Exclude<T, null | undefined> {
  return arg !== null && arg !== undefined;
}

export function isDefinedObject<TArg>(arg: TArg): arg is NonNullable<TArg> & object {
  return (
    typeof arg === 'object' &&
    arg !== null);
}

export function assertIsDefinedObject<TArg>(arg: TArg): asserts arg is NonNullable<TArg> & object {
  assert(isDefinedObject(arg),
    `Expected ${arg} to be a non-null object.`);
}

export function assertObjectHasKey(obj: ObjectLiteral, key: string | number | symbol) {
  assert(key in obj,
    `Expected ${obj} to contain key ${String(key)}.`);
}

/* Simple wrapper for returning null if the argument is undefined. */
export function nullable<T>(arg: T): T | null {
  if (arg === undefined)
    return null;
  return arg;
}

export function hasKey<O>(obj: O, key: keyof any): key is keyof O {
  return key in obj
}

export function sanitize<T>(obj: any): T {
  return pickBy(obj, val => isDefined(val)) as T;
}

type CallOrReturnCallback<T, TArgs extends any[] = any[]> = (...args: TArgs) => T;

export function callOrReturn<T, TArgs extends any[] = any[]>(val: T | CallOrReturnCallback<T, TArgs>, args?: TArgs): T {
  if (typeof val === 'function') {
    const valFunc = val as CallOrReturnCallback<T, TArgs>;
    return valFunc(...args as TArgs ?? []);
  }
  return val;
}