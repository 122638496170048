import '../menu/menu.scss';

import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { usePopper } from 'react-popper';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';

import { useOutsideMouseDown, useOutsideScroll } from '../hooks';
import { MoreSVGIcon } from '../svg'
import { CommentState } from './commentState';

// @ts-ignore
import CSSVariables from '../menu/menu.scss';

type Props = {
  model: CommentState,
  className?: string,
  onOpen?: (isOpened: boolean) => void
};

const fadeTimeout = {
  enter: parseInt(CSSVariables.windowEnterTimeout),
  exit: parseInt(CSSVariables.windowExitTimeout)
};

export const CommentMenu = observer((props: Props) => {
  const { model, onOpen } = props;
  const { comment } = model;

  const [anchorElement, setAnchorElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isOpened, setIsOpened] = useState(false);

  const className = classNames('comment-menu', props?.className, {
    'active': isOpened
  });

  const { styles, attributes } = usePopper(anchorElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        }
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom', 'bottom-start'],
        },
      }
    ],
  });

  useOutsideMouseDown(popperElement, () => setIsOpened(false));
  useOutsideScroll(popperElement, () => setIsOpened(false));

  useEffect(() => {
    if (onOpen) onOpen(isOpened);
  }, [onOpen, isOpened]);

  const canEdit = comment.hasPermission('EditComment');
  const canDelete = comment.hasPermission('DeleteComment');

  if (!canEdit && !canDelete)
    return null;

  return (
    <>
      <button className={className}
        ref={setAnchorElement as any}
        onClick={evt => {
          setIsOpened(!isOpened);
          evt.stopPropagation();
        }}>
        <span className="icon">
          <MoreSVGIcon />
        </span>
      </button>

      <TransitionGroup>
        {isOpened && (
          <CSSTransition classNames="menu-fade"
            timeout={fadeTimeout}>
            <div className="comment-menu-popup"
              ref={setPopperElement as any}
              style={styles.popper}
              {...attributes.popper}>
              <div className="menu-items"
                onClick={() => setIsOpened(false)}>

                {canDelete &&
                  <button className="menu-item"
                    onClick={evt => {
                      model.openDeleteWindow();
                      setIsOpened(false);
                      evt.stopPropagation();
                    }}//handler(model, 'openDeleteWindow')
                  >
                    Delete
                  </button>}

                {canEdit &&
                  <button className="menu-item"
                    onClick={evt => {
                      model.toggleEditInput();
                      setIsOpened(false);
                      evt.stopPropagation();
                    }} //handler(model, 'toggleEditInput')>
                  >
                    Edit
                  </button>}
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
});