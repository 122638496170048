import './loader-v1-1.scss';

import React from 'react';
import classNames from 'classnames';

type Props = {
  logo?: string;
  className?: string;
};

export const LogoSpinner = (props: Props) => {
  const { className, logo } = props;
  const clsx = classNames('logo-spinner', className);

  const addDefaultImageSource = (evt: any) => {
    evt.preventDefault();
    evt.target.src = '/img/transparent.png';
  }

  return (
    <div className={clsx}>
      <img
        alt=''
        onError={(evt) => addDefaultImageSource(evt)}
        src={logo}
        className="spinner-logo">
      </img>

      <div className="spinner-bullets">
        <div className="bullet" />
        <div className="bullet" />
        <div className="bullet" />
        <div className="bullet" />
        <div className="bullet" />
      </div>
    </div>
  );
};
