import './media.scss';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';

import { Maybe } from '../../core';
import { TextInputErrorSVGIcon, TextInputWarningSVGIcon, LiveSVGIcon } from '../svg/svg';
import { DotSpinner } from '../loader';
import { JobModel } from '../../entities';
import { Thumb } from '../media/thumb';
import { PlaySVGIcon } from '../svg';

export const TrainerJobCardPreview = observer(
  ({ model }: { model: JobModel }) => {

    let previewElem: Maybe<ReactNode>;
    const mediaAggregatedStatus = model.mediaAggregatedStatus;
    const liveClass = classNames('card-thumb dock', {
      'live-card-placeholder': !model.posterURL || !model.store.uiService.logo,
    });

    switch (mediaAggregatedStatus) {
      case 'IngestProcessing':
        previewElem = (
          <div className="row-preview">
            <Thumb source={model} className="card-thumb dock" />
            <DotSpinner dots={3} />
          </div>
        );
        break;

      case 'Failed':
        previewElem = (
          <div className="row-preview">
            <Thumb source={model} className="card-thumb dock" />

            <div className="error-icon preview-icon">
              <TextInputErrorSVGIcon />
            </div>
          </div>
        );
        break;

      case 'LiveStreaming':
        previewElem = (
          <div className="row-preview">
            <Thumb source={model} className={liveClass} />
            {model.isPublished &&
              <div className="job-card-live-tag">
                <LiveSVGIcon />
                <span>LIVE NOW</span>
              </div>}
            {!model.isPublished &&
              <div className="job-card-unpublish-tag">Unpublished</div>}
          </div>
        )
        break;

      case 'LiveWaiting':
        previewElem = (
          <div className="row-preview">
            <Thumb source={model} className={liveClass} />
            {model.isPublished &&
              <div className="job-card-live-tag">
                <LiveSVGIcon />
                <span>LIVE</span>
              </div>}
            {!model.isPublished &&
              <div className="job-card-unpublish-tag">Unpublished</div>}
          </div>
        )
        break;

      case 'LiveEnded':
        previewElem = (
          <div className="row-preview">
            <Thumb source={model} className={liveClass} />
            {model.isPublished &&
              <div className="job-card-live-tag">
                <LiveSVGIcon />
                <span>LIVE ENDED</span>
              </div>}
            {!model.isPublished &&
              <div className="job-card-unpublish-tag">Unpublished</div>}
          </div>
        )
        break;

      default:
      case 'IngestCompleted':
        previewElem = (
          <div className="row-preview">
            <Thumb source={model} className="card-thumb dock" />

            <div className="play-icon preview-icon">
              <PlaySVGIcon />
            </div>

            {!model.isPublished &&
              <div className="job-card-unpublish-tag">Unpublished</div>}
          </div>
        );
        break;
    }

    if (!model.isPublished && !!!model.posterURL && !model.isLive) {
      previewElem = (
        <div className="row-preview">
          <Thumb source={model} className="card-thumb dock" />
          <div className="card-overlay unpublish">
            <TextInputWarningSVGIcon />
            <p>Video is no longer available</p>
          </div>
        </div>
      );
    }

    if (model.isTransient) {
      previewElem = (
        <div className="row-preview">
          <Thumb source={model} className="card-thumb dock" />
          <DotSpinner dots={3} />
        </div>
      );
    }

    return <>{previewElem}</>;
  }
);