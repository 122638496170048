import { computed, makeObservable } from 'mobx';
import { TeamMember as ApiTeamMember, TeamMemberRole } from '@clipr/lib';
import { Store } from '../store/store';
import { StoreNode } from '../store';
import { Nullable } from '../core';
import { User } from './user';
import { Team } from './team';

export type TeamMemberProps = ApiTeamMember;

export class TeamMember
  extends StoreNode {

  constructor(store: Store, props?: TeamMemberProps) {
    super(store, props);
    makeObservable(this);

    Object.assign(this, props);
    this.id = props?.userId!;
  }
  readonly id!: string;
  readonly userId!: string;
  readonly teamId!: string;
  readonly role!: TeamMemberRole;

  @computed get user(): Nullable<User> {
    return this.store.userManager.getUser(this.userId);
  }
  @computed get strictUser(): Nullable<User> {
    return this.store.userManager.strictGetUser(this.userId);
  }

  @computed get team(): Nullable<Team> {
    return this.store.teamManager.getTeam(this.teamId);
  }
  @computed get strictTeam(): Nullable<Team> {
    return this.store.teamManager.strictGetTeam(this.teamId);
  }
  @computed get name(): Nullable<string> {
    return this.user?.name || this.user?.username || this.user?.email || this.user?.id || this.userId;
  }

  @computed get teamAnalyticsExclude(): boolean {
    return this.user?.teamAnalyticsExclude?.includes(this.teamId) ?? false;
  }
}