import React from 'react';
import { observer } from 'mobx-react-lite';
import { BoxesSVGIcon, CommentsSVGIcon, /*CommentsSVGIcon,*/ TranscriptSVGIcon } from '../../svg';
import { PlayerState } from '../playerState';
import { ToggleButton } from './toggleButton';

type Props = {
  model: PlayerState
}

export const PlayerToggleBar = observer(({
  model
}: Props) => {

  const { frameset } = model;
  const transcriptsVisible = frameset?.isSectionVisible('Transcripts');
  const indexVisible = frameset?.isSectionVisible('Index');
  const commentsVisible = frameset?.isSectionVisible('Comments');

  const toggleTranscripts = () => {
    if (!transcriptsVisible && frameset?.isWidgetMode) {
      model.invoke('disableCaptions');
      model.playerCaption.deactivateCaptionTrack();
    }

    frameset?.toggleSection('Transcripts');
  }

  const toggleComments = () => {
    if (!commentsVisible && frameset?.isWidgetMode && indexVisible)
      frameset?.toggleSection('Index');

    frameset?.toggleSection('Comments');
  }

  const toggleIndex = () => {
    if (!indexVisible && frameset?.isWidgetMode && commentsVisible)
      frameset?.toggleSection('Comments');

    frameset?.toggleSection('Index');
  }

  return (
    <div
      id="user-player-toggle-bar"
      data-tutorial-id="user-player-toggle-bar">
      <div className="control-group">
        {frameset?.isSectionAvailable('Transcripts') && (
          <ToggleButton
            id="player-toggle-transcripts-btn"
            model={model}
            icon={<TranscriptSVGIcon />}
            pressed={transcriptsVisible}
            onClick={() => toggleTranscripts()}
            tooltip={transcriptsVisible ? 'Hide Transcripts' : 'Show Transcripts'} />
        )}

        {frameset?.isSectionAvailable('Index') && (
          <ToggleButton
            id="player-toggle-index-btn"
            model={model}
            icon={<BoxesSVGIcon />}
            pressed={indexVisible}
            onClick={evt => toggleIndex()}
            tooltip={indexVisible ? 'Hide Topic Index' : 'Show Topic Index'} />
        )}

        {frameset?.isSectionAvailable('Comments') && (
          <ToggleButton
            id="player-toggle-comments-btn"
            model={model}
            icon={<CommentsSVGIcon />}
            pressed={commentsVisible}
            onClick={evt => toggleComments()}
            tooltip={commentsVisible ? 'Hide Comments' : 'Show Comments'} />
        )}
      </div>
    </div>
  );
});