import { action, computed, makeObservable, observable } from 'mobx';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { Maybe } from '../../core'
import { Routes } from '../../routes';
import { InputState, input } from '../../components/input';
import { JobResultsCatalog } from './jobResultsCatalog';
import { MomentResultsCatalog } from './momentResultsCatalog';
import { ApiRequestOptions } from '../../api';
import { Team } from '../../entities';
import { WidgetService } from '../../services/widget';
import { Metadata } from '@clipr/lib';
import { CopyJobWindowState } from '../../components/jobs';
import { SearchMode } from '../../components/search/searchBarState';

export class SearchResultsState
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.model = input(this, {
      name: 'searchInput',
      onChange: this.handleChange,
      onFocus: this.handleFocus,
      onBlur: () => { setTimeout(action(() => { this.isFocused = false; }), 200) }
    });

    this.jobCatalog = new JobResultsCatalog(this.store);
    this.momentCatalog = new MomentResultsCatalog(this.store, { type: 'Moment' });
    this.topicCatalog = new MomentResultsCatalog(this.store, { type: 'Topic' });
  }

  readonly jobCatalog: JobResultsCatalog;
  readonly momentCatalog: MomentResultsCatalog;
  readonly topicCatalog: MomentResultsCatalog;

  readonly model: InputState;

  @observable pageSize: number = 20;
  @observable teamId: Maybe<string> = null;

  @observable queryString: Maybe<string> = ''; // URL search query param
  @observable isFocused: boolean = false;

  @computed
  get metadataFilter(): Partial<Metadata> | null {
    return this.store.widgetService.metadataFilter;
  }

  @computed
  get tagsFilter(): string[] | null {
    return this.store.widgetService.tagsFilter;
  }

  @computed get team(): Team | null {
    return this.store.teamManager.getTeam(this.teamId) ?? null;
  }

  @action
  clear() {
    this.clearSuggestions();
    this.search();
  }

  @action
  clearSuggestions() {
    this.jobCatalog.suggestionsLookup.clear();
    this.topicCatalog.suggestionsLookup.clear();
    this.momentCatalog.suggestionsLookup.clear();
  }

  @action handleFocus = (e: any) => {
    this.isFocused = true;
    if (!this.hasSuggestions) {
      this.handleChange(e);
    }
  }

  @action
  handleChange = async (e: any) => {
    const { value } = e;
  
    if (value && value.length >= 3) {
      this.debouncedFetch();
    } else {
      this.clearSuggestions();
    }
  }

  @action
  async fetchSuggestions(opts?: ApiRequestOptions) {
    return await Promise.all([
      this.jobCatalog.fetchSuggestions(this.model.value, opts),
      this.topicCatalog.fetchSuggestions(this.model.value, opts),
      this.momentCatalog.fetchSuggestions(this.model.value, opts),
    ]);
  };

  @action
  async setPageSize(pageSize: number) {
    if (pageSize !== this.pageSize) {
      this.pageSize = pageSize;
      await this.debouncedSearch();
    }
  }

  debouncedSearch = debounce(() => this.search(), 500);
  debouncedFetch = debounce(this.fetchSuggestions, 500);

  @action
  async fetchVideos(pageSize: number, opts?: ApiRequestOptions) {
    return this.jobCatalog.fetchVideos(this.model.value, pageSize, opts);
  }

  @action
  async fetchMoments(pageSize: number, opts?: ApiRequestOptions) {
    return this.momentCatalog.fetchMoments(this.model.value, pageSize, opts);
  }

  @action
  async fetchTopics(pageSize: number, opts?: ApiRequestOptions) {
    return this.topicCatalog.fetchMoments(this.model.value, pageSize, opts);
  }

  @action
  async search(opts?: ApiRequestOptions) {
    return await Promise.all([
      this.fetchVideos(this.pageSize, opts),
      this.fetchTopics(this.pageSize, opts),
      this.fetchMoments(this.pageSize, opts)
    ]);
  }

  @action
  private widgetServiceListener = (msg: Message<WidgetService>) => {
    // TODO: check why this is here
    // it was emitted from widget service when an error occurred during authentication
    // but it was caught from the raw auth0 message
    switch (msg.type) {
      case 'loginRequired':
        this.store.router.history.goBack();
        break;
      default:
        break;
    }
  }

  @action
  async mounted(teamId?: Maybe<string>) {
    this.store.searchBar.setSearchMode(SearchMode.Suggestions);

    // TODO: fix this mess

    const query = new URLSearchParams(window.location.search);
    const searchParam = query.get('searchKey');
    const teamIdParam = query.get('teamId');
    // Set the search value at init for shareable search urls
    this.model.value = searchParam || '';
    this.teamId = teamId || teamIdParam;
    this.jobCatalog.teamId = this.teamId;
    this.topicCatalog.teamId = this.teamId;
    this.momentCatalog.teamId = this.teamId;
    this.queryString = searchParam;

    const tagsFilter = this.tagsFilter;
    if (tagsFilter) {
      this.jobCatalog.tagsFilter = tagsFilter;
      this.momentCatalog.tagsFilter = tagsFilter;
      this.topicCatalog.tagsFilter = tagsFilter;
    }

    const metadataFilter = this.metadataFilter;
    if (metadataFilter) {
      this.jobCatalog.metadataFilter = metadataFilter;
      this.momentCatalog.metadataFilter = metadataFilter;
      this.topicCatalog.metadataFilter = metadataFilter;
    }

    this.fetchData();
    this.bindExternalListeners();
  }

  private fetchData() {
    if (!this.isLoading) {
      this.search();
    }

    this.jobCatalog.fetchPopularVideos();
  }

  private bindExternalListeners() {
    const { store } = this;
    const { renameJobWindow, deleteJobWindow, copyJobWindow, removeJobFromTeamWindow, removeJobFromCliprWindow, uploadThumbnailWindow, videoDetailsWindow } = store;
    renameJobWindow.listen(
      this.mutationWindowListener);
    copyJobWindow.listen(
      this.copyJobWindowListener);
    deleteJobWindow.listen(
      this.mutationWindowListener);
    removeJobFromTeamWindow.listen(
      this.mutationWindowListener);
    removeJobFromCliprWindow.listen(
      this.mutationWindowListener);
    uploadThumbnailWindow.listen(
      this.mutationWindowListener);
    videoDetailsWindow.listen(
      this.mutationWindowListener);
  }

  private unbindExternalListeners() {
    const { store } = this;
    const { renameJobWindow, deleteJobWindow, copyJobWindow, removeJobFromTeamWindow, removeJobFromCliprWindow, uploadThumbnailWindow, videoDetailsWindow } = store;
    renameJobWindow.unlisten(
      this.mutationWindowListener);
    copyJobWindow.unlisten(
      this.copyJobWindowListener);
    deleteJobWindow.unlisten(
      this.mutationWindowListener);
    removeJobFromTeamWindow.unlisten(
      this.mutationWindowListener);
    removeJobFromCliprWindow.unlisten(
      this.mutationWindowListener);
    uploadThumbnailWindow.unlisten(
      this.mutationWindowListener);
    videoDetailsWindow.unlisten(
      this.mutationWindowListener);
  }

  private copyJobWindowListener = action(async (msg: Message<CopyJobWindowState>) => {
    switch (msg.type) {
      case 'success':
        // TODO: all the data about jobs should be all moved to the jobManager
        // Lot of inconsistencies arised when I tried to add actions to the search results cards
        // We are dealing with two different job catalogs which have the same purpose
        this.jobCatalog.insertJob(msg.payload.job);
        break;
    }
  });

  private mutationWindowListener = action(async (msg: Message) => {
    switch (msg.type) {
      case 'JobDeleted':
      case 'JobRemoved':
      case 'ThumbnailUpdated':
      case 'JobUpdated':
      case 'JobRenamed':
        this.search();
        break;
    }
  });

  @action
  unmounted() {
    this.queryString = null;
    this.model.clear();
    this.teamId = null;
    this.jobCatalog.teamId = null;
    this.topicCatalog.teamId = null;
    this.momentCatalog.teamId = null;
    this.clearSuggestions();
    this.jobCatalog.popularVideosLookup.clear();
    this.store.searchBar.reset();
    this.unbindExternalListeners();
  }

  @action
  submit(replaceRoute = false, teamId: string | null) {
    this.teamId = teamId;
    this.queryString = this.model.value;
    this.clearSuggestions();
    this.isFocused = false;

    const { history } = this.store.routingService;
    if (replaceRoute)
      history.replace(this.searchUrl);
    else
      history.push(this.searchUrl);
  }

  @computed
  get searchUrl(): string {
    const path = this.isWidgetMode ? Routes.librarySearchWidget() : Routes.search;
    let params = new URLSearchParams();
    if (this.model.value) {
      params.append('searchKey', this.model.value);
    }
    if (this.teamId) {
      params.append('teamId', this.teamId);
    }

    return `${path}${params ? `?${params}` : ''}`;
  }

  @computed
  get showSuggestions(): boolean {
    return !!(
      this.isFocused &&
      this.hasSuggestions &&
      !this.isLoadingSuggestions &&
      this.model.value &&
      this.model.value.length >= 3 &&
      !this.isWidgetMode
    );
  }

  @computed
  get hasSuggestions(): boolean {
    return !isEmpty(this.jobCatalog.suggestions) ||
      !isEmpty(this.topicCatalog.suggestions) ||
      !isEmpty(this.momentCatalog.suggestions);
  }

  @computed
  get isLoadingSuggestions(): boolean {
    return this.jobCatalog.isFetchingSuggestions ||
      this.topicCatalog.isFetchingSuggestions ||
      this.momentCatalog.isFetchingSuggestions;
  }

  @computed
  get hasResults(): boolean {
    return !this.jobCatalog.isEmpty || !this.topicCatalog.isEmpty || !this.momentCatalog.isEmpty;
  }

  @computed
  get isLoading(): boolean {
    return this.jobCatalog.isLoading || this.topicCatalog.isLoading || this.momentCatalog.isLoading;
  }

  @computed
  get isWidgetMode(): boolean {
    return this.store.widget.isWidgetMode;
  }
}