import React from 'react';
import { observer } from 'mobx-react-lite';
import { JobModel } from '../../entities'
import { useStore } from '../../store/storeHooks';
import { GenerativeAiProvider } from '@clipr/lib';
import classNames from 'classnames';
import { ChatGptLogoSVGIcon } from '../svg';
import { DotSpinner } from '../loader';

type Props = {
  model: JobModel;
  teamId?: string;
  className?: string;
}

export const AiTag = observer(({
  model,
  teamId,
  className
}: Props) => {

  const store = useStore();
  let gptProvider: GenerativeAiProvider | null = null;

  const getUserProvider = () => { 
    const generativeAiSettings = (store.profilePage.localUser ?? store.user)?.generativeAiSettings;
    if (!generativeAiSettings?.cliprGptEnabled)
      return null;

    return generativeAiSettings?.provider;
  }

  const getTeamProvider = (teamId: string) => {
    const generativeAiSettings = store.teamManager.getTeam(teamId)?.generativeAiSettings;
    if (!generativeAiSettings?.cliprGptEnabled)
      return null;

    return generativeAiSettings?.provider;
  }

  const getOwnerProvider = () => {
    const generativeAiSettings = (model.owner?.team ?? model.owner?.user)?.generativeAiSettings;
    if (!generativeAiSettings?.cliprGptEnabled)
      return null;

    return model.owner?.team?.generativeAiProvider || model.owner?.user?.generativeAiProvider;
  }

  gptProvider = getUserProvider() || getOwnerProvider();

  if (teamId) {
    gptProvider = getTeamProvider(teamId);
  }

  if (!gptProvider)
    return<></>;

  const isAiProcessing = model.isAiProcessing(gptProvider);
  const isAiDone = model.isAiDone(gptProvider);
  const aiProcessingTagClass = classNames(className, 
    'job-card-ai-tag', {
    'processing': isAiProcessing
  });

  const aiProcessingText = isAiProcessing ?
  <div className="spinner-container">
    Still processing 
    <DotSpinner 
    className="short-animation" 
    dots={3} />
  </div> : 'done';

  if (!isAiProcessing && !isAiDone)
    return <></>;

  return (
    <div className={aiProcessingTagClass}>
      <ChatGptLogoSVGIcon /> {aiProcessingText}
    </div>
  )
  });