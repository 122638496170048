import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import isEmail from 'validator/lib/isEmail';
import { input, inputGroup, submitButtonDisabled } from '../../../components/input';
import { assertNotNull, checked, Maybe } from '../../../core';
import { AuthError, AuthErrorType } from '../../../services/auth';
import { AuthFlowResponse } from '../../../services/auth/authFlowSchema';
import { ResetPasswordInput } from '../../../services/auth/authSchema';
import { assertValidChangePasswordInput } from '../../../services/auth/authUtils';
import { StoreNode } from '../../../store';
import { Store } from '../../../store/store';

const UsernameSubmitErrors: AuthErrorType[] = [
  'InvalidUsername',
  'UsernameNotFound'
]

export class ForgotPasswordFormState
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }
  
  @observable flowResponse: AuthFlowResponse | null = null;
  @observable error: AuthError | null = null;

  @computed get isSubmitting() {
    return this.formGroup.isSubmitting;
  }
  @computed get showLoadMask() {
    return this.isSubmitting;
  }

  readonly formGroup = inputGroup(this, {
    name: 'forgotPasswordForm',
    inputs: () => [
      this.username,
      this.submitButton
    ],
    error: (form) => {
      const errorType = this.error?.type;

      if (UsernameSubmitErrors.includes(errorType!)) {
        return null; // pass-through
      }

      if (form.hasErrorInputs)
        return true;

      switch (this.error?.type) {
        case 'NetworkError':
          return 'Cannot send reset password link because of a network error';
        case 'ProviderError':
          return 'Cannot send reset password link because of a provider error';
        default:
          if (this.error)
            return 'Cannot send reset password link because of an unknown error';
      }
    },
    showStatus: (form) => !!form.error,
    showStatusMessage: (input) => input.showStatus
  });

  // #region Email field
  readonly username = input(this, {
    name: 'username',
    isRequired: true,
    error: (input, fallback) => {
      if (!input.value?.trim())
        return 'Required field';
      if (!isEmail(input.value?.trim() || ''))
        return 'Valid email required';
      if (this.usernameSubmitError)
        return this.usernameSubmitError;
      return fallback;
    },
    showStatus: (input) => input.isSubmitRejected || !!this.usernameSubmitError,
    showStatusMessage: (input) => input.showStatus,
    onChange: () => this.clearError()
  });

  @computed get usernameSubmitError(): Maybe<string> {
    if (!this.username.isChangedSinceLastSubmit &&
      UsernameSubmitErrors.includes(this.error?.type!)) {
      return this.error?.message || null;
    }
    return null;
  }
  // #endregion

  // #region Submit
  readonly submitButton = input(this, {
    name: 'submit',
    disabled: submitButtonDisabled(this.formGroup)
  });

  @action
  handleSubmit = async (evt: React.FormEvent) => {
    evt.preventDefault();

    this.error = null;
    this.formGroup.handleSubmit();
    if (this.formGroup.error) {
      this.formGroup.handleSubmitReject();
      return false;
    }

    const input: ResetPasswordInput = {
      email: this.username.value?.trim()!
    }

    assertValidChangePasswordInput(input);

    const { auth } = this.store;
    const [res, err] = await checked(() =>
      auth.runResetPasswordFlow(input));

    if (err) {
      runInAction(() =>
        this.error = err);
      this.formGroup.handleSubmitReject();
      return false;
    }

    assertNotNull(res);

    runInAction(() =>
      this.flowResponse = res);

    this.formGroup.handleSubmitResolve();
    return true;
  }
  // #endregion

  // #region Reset
  @action
  clearError() {
    this.error = null;
  }

  @action
  reset() {
    this.formGroup.reset();
    this.error = null;
    this.flowResponse = null;
  }
  // #endregion
}