import { makeObservable, computed } from 'mobx';
import toNumber from 'lodash/toNumber';
import { BindingProps, StoreNode } from '../../../store';
import { Store } from '../../../store/store';
import { ChartPageParams } from '../chartSchema';
import { MetricCounterState } from '../metricCounter/metricCounterState';
import { ApiResponse } from '../../../api/apiSchema';
import { parseKeenJsonResult } from '../../../components/chart/utils/parseKeenJsonResult';
import { durationToString } from '../../../core/time';
import { Team } from '../../../entities/team';

type Props = BindingProps<{
  params?: ChartPageParams;
}>

export class MetricsWrapperState
  extends StoreNode {

  readonly nodeType = 'MetricsWrapperState';

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);
  }

  @computed get queryParams(): ChartPageParams {
    return this.getResolvedProp('params') ?? null;
  }

  @computed get teamId(): string | null {
    return this.queryParams?.teamId || null;
  }
  @computed get team(): Team | null {
    return this.store.teamManager.getTeam(this.teamId);
  }

  readonly metrics = [ 
    new MetricCounterState(this.store, {
      label: 'Uploads',
      queryName: 'keen_getTotalUniqueUploads',
      queryParams: () => (this.queryParams),
      valueAccessor: (result: ApiResponse<'keen_getTotalUniqueUploads'>) => {
        const apiResultStr = result.keenBatch.totalUniqueUploads?.result;
        const [apiResult, parseErr] = parseKeenJsonResult<number>(apiResultStr);
        const data = toNumber(apiResult) ?? 0;
      
        return [data, parseErr];
      },
      outputData: (data: any) => {
        return data?.toLocaleString(undefined, { maximumFractionDigits: 2 })
      },
    }),
    new MetricCounterState(this.store, {
      label: 'Plays',
      queryName: 'keen_getTotalPlays',
      queryParams: () => (this.queryParams),
      valueAccessor: (result: ApiResponse<'keen_getTotalPlays'>) => {
        const apiResultStr = result.keenBatch.totalPlays?.result;
        const [apiResult, parseErr] = parseKeenJsonResult<number>(apiResultStr);
        const data = toNumber(apiResult) ?? 0;
      
        return [data, parseErr];
      },
      outputData: (data: any) => {
        return data?.toLocaleString(undefined, { maximumFractionDigits: 2 })
      },
    }),
    new MetricCounterState(this.store, {
      label: 'Avg. Watch Time',
      queryName: 'keen_getAvgWatchTime',
      queryParams: () => (this.queryParams),
      valueAccessor: (result: ApiResponse<'keen_getAvgWatchTime'>) => {
        const apiResultStr = result.keenBatch.avgWatchTime?.result;
        const [apiResult, parseErr] = parseKeenJsonResult<number>(apiResultStr);
        const data = toNumber(apiResult) ?? 0;
      
        return [data, parseErr];
      },
      outputData: (data: any) => {
        return data && durationToString(data, 'time');
      },
    }),
    new MetricCounterState(this.store, {
      label: 'Library Members',
      display: () => (!!this.team),
      outputData: () => {
        if (!this.team) 
          return null;

        const {
          members,
          membersCount
        } = this.team;
        const length = membersCount || members?.length || 0;

        return length.toLocaleString(undefined, { maximumFractionDigits: 2 });
      },
    }),
    new MetricCounterState(this.store, {
      label: 'Topics Viewed',
      queryName: 'keen_getTotalTopics',
      queryParams: () => (this.queryParams),
      valueAccessor: (result: ApiResponse<'keen_getTotalTopics'>) => {
        const apiResultStr = result.keenBatch.totalTopics?.result;
        const [apiResult, parseErr] = parseKeenJsonResult<number>(apiResultStr);
        const data = toNumber(apiResult) ?? 0;
      
        return [data, parseErr];
      },
      outputData: (data: any) => {
        return data?.toLocaleString(undefined, { maximumFractionDigits: 2 })
      },
    }),
    new MetricCounterState(this.store, {
      label: 'Reactions',
      queryName: 'keen_getTotalReactions',
      queryParams: () => (this.queryParams),
      valueAccessor: (result: ApiResponse<'keen_getTotalReactions'>) => {
        const apiResultStr = result.keenBatch.totalReactions?.result;
        const [apiResult, parseErr] = parseKeenJsonResult<number>(apiResultStr);
        const data = toNumber(apiResult) ?? 0;
      
        return [data, parseErr];
      },
      outputData: (data: any) => {
        return data?.toLocaleString(undefined, { maximumFractionDigits: 2 })
      },
    }),
    new MetricCounterState(this.store, {
      label: 'Shares',
      queryName: 'keen_getTotalShares',
      queryParams: () => (this.queryParams),
      valueAccessor: (result: ApiResponse<'keen_getTotalShares'>) => {
        const apiResultStr = result.keenBatch.totalShares?.result;
        const [apiResult, parseErr] = parseKeenJsonResult<number>(apiResultStr);
        const data = toNumber(apiResult) ?? 0;
      
        return [data, parseErr];
      },
      outputData: (data: any) => {
        return data?.toLocaleString(undefined, { maximumFractionDigits: 2 })
      },
    }),
    new MetricCounterState(this.store, {
      label: 'Bookmarks',
      queryName: 'keen_getTotalBookmarks',
      queryParams: () => (this.queryParams),
      valueAccessor: (result: ApiResponse<'keen_getTotalBookmarks'>) => {
        const apiResultStr = result.keenBatch.totalBookmarks?.result;
        const [apiResult, parseErr] = parseKeenJsonResult<number>(apiResultStr);
        const data = toNumber(apiResult) ?? 0;
      
        return [data, parseErr];
      },
      outputData: (data: any) => {
        return data?.toLocaleString(undefined, { maximumFractionDigits: 2 })
      },
    }),
  ];

  @computed get outputMetrics(): MetricCounterState[] {
    return this.metrics.filter((metricCounter) => metricCounter.display);
  }

}
