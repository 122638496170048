import { action, computed, makeObservable, observable } from 'mobx';
import { History } from 'history';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { PlayerState } from '../../components';
import { AsyncResult, Result } from '../../core';
import { MomentSelector } from '../../entities/moments/momentSelector';
import { JobModel, MomentModel, JobLiveAggregateStatus } from '../../entities';
import { Routes } from '../../routes';
import { Error } from '../../core/error';
import { ClipWindowState } from '../../components/momentWindow/clipWindowState';
import { PlayerFramesetState } from '../../components/playerFrameset/playerFramesetState';
import { PlayerIndexState } from '../../components/playerIndex/playerIndexState';
import { PlayerTranscriptsState } from '../../components/playerTranscripts/playerTranscriptsState';
import { PlayerSectionName } from '../../components/playerFrameset/playerFramesetSchema';
import { ApiRequestOptions, batchApiResultIsAborted, getApiResultJobError } from '../../api';
import { PlayerCommentsState } from '../../components/playerComments';
import { PlayerTutorialController } from '../../components/playerTutorial/playerTutorialController';
import { JobLiveStatusMonitor } from '../../entities/jobLiveStatusMonitor';
import { PlayerApiClient } from '../../components/player/playerApiClient';
import { notifyError } from '../../services/notifications';
import { SyncStatus } from '../../entities/job';
import { PlayerSpeakerIdController } from '../../components/playerSpeakers/playerSpeakerIdController';
import { SectionStatus } from '../../pages/userPlayerPage/userPlayerPageState';
import { AuthFlowResponse } from '../../services/auth/authFlowSchema';
import { RouteContext } from '../../routes/routeContext';
import { InlineAuthFlowController } from '../../services/auth/controllers/inlineAuthFlowController';
import { IRouteStorage } from '../../routes/routeSchema';
import { WidgetState } from '../widgetStateMixin';
import { PlayerWidgetAdapter } from './playerWidgetAdapter';

import { INIT_DEBUGGER, TRACE } from '../../core/debug/debugMacros';

export type PlayerWidgetRouteParams = {
  jobId: string
}

export class PlayerWidgetState
  extends WidgetState(StoreNode) {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    INIT_DEBUGGER(this, {
      color: 'deeppink'
    });

    this.defaultSelector = new MomentSelector(store, {
      jobId: () => this.jobId
    });

    this.player = new PlayerState(store, {
      jobId: () => this.jobId,
      teamId: () => this.teamId,
      time: () => this.time,
      momentSelector: () => this.selector,
      frameset: () => this.frameset,
      showIndex: () => this.showIndex,
      showComments: () => this.showComments,
      showTranscript: () => this.showTranscript,
      showProfile: () => this.showProfile,
      showTopicTags: () => this.showTopicTags,
      showEmbedIcon: () => this.showEmbedIcon,
      showHelp: () => this.showHelp,
      disableReactions: () => this.disableReactions,
      disableComments: () => this.disableComments,
      disableTranscript: () => this.disableTranscript,
      disableIndex: () => this.disableIndex,
      allowFullscreen: () => this.allowFullscreen,
      allowShare: () => this.allowShare,
      allowDownload: () => this.allowDownload,
      autoplay: () => this.autoplay,
      customRedirectUrl: () => this.customRedirectUrl,
      playerTutorialHighlightedComponents: () => this.playerTutorialHighlightedComponents,
      speakerIdMode: () => this.isSpeakerIdMode
    });

    this.playerTutorialController = new PlayerTutorialController(this.store, {
      player: () => this.player,
      frameset: () => this.frameset,
      chrome: () => this.player.chrome,
      job: () => this.job
    });

    this.playerWidgetAdapter = new PlayerWidgetAdapter(this.store, {
      player: () => this.player
    });

    this.playerSpeakerIdController = new PlayerSpeakerIdController(this.store, {
      player: () => this.player,
      frameset: () => this.frameset,
      indexSection: () => this.indexSection,
      momentSelector: () => this.selector
    });

    this.indexSection = new PlayerIndexState(this.store, {
      // TODO: check if it can be deleted
      playerTutorialHighlightedComponents: () => this.playerTutorialHighlightedComponents,
      jobId: () => this.jobId,
      player: () => this.player,
      frameset: () => this.frameset,
      teamId: () => this.teamId,
      speakerIdController: () => this.playerSpeakerIdController
    });

    this.player.listen(
      this.playerListener);

    this.jobLiveStatusMonitor.listen(
      this.jobLiveStatusMonitorListener);

    this.frameset.hideSection('Toolbar');
    this.frameset.hideSection('Index');
    this.frameset.hideSection('Transcripts');
    this.frameset.hideSection('Comments');
  }

  readonly nodeType: 'PlayerWidget' = 'PlayerWidget';

  private abortController: AbortController | null = null;

  readonly player: PlayerState;

  readonly defaultSelector: MomentSelector;
  readonly playerTutorialController: PlayerTutorialController;
  readonly playerWidgetAdapter: PlayerWidgetAdapter;
  readonly playerSpeakerIdController: PlayerSpeakerIdController;
  readonly indexSection: PlayerIndexState;

  readonly inlineAuthFlowController = new InlineAuthFlowController(this.store);

  readonly frameset = new PlayerFramesetState(this.store, {
    widgetMode: true,
    tutorialMode: () => this.player.tutorialMode,
    getSectionAvailability: () => this.getSectionAvailability,
    getSectionStatus: () => this.getSectionStatus
  });

  readonly transcriptsSection = new PlayerTranscriptsState(this.store, {
    // TODO: check if it can be deleted
    playerTutorialHighlightedComponents: () => this.playerTutorialHighlightedComponents,
    jobId: () => this.jobId,
    player: () => this.player,
    frameset: () => this.frameset,
    teamId: () => this.teamId
  });
  readonly commentsSection = new PlayerCommentsState(this.store, {
    jobId: () => this.jobId,
    player: () => this.player,
    frameset: () => this.frameset
  });

  readonly localApiClient = new PlayerApiClient(this.store);

  private readonly jobLiveStatusMonitor = new JobLiveStatusMonitor(this.store, {
    jobId: () => this.jobId
  });

  @computed get teamId(): string | null {
    return this.widgetService.teamId;
  }

  @computed get selector(): MomentSelector {
    const propSelector = this.getResolvedProp('selector');
    if (!propSelector)
      return this.defaultSelector;

    return propSelector;
  }

  @computed get showPlayerTutorial() {
    if (!this.job)
      return false;

    return (
      [SyncStatus.Fetched, SyncStatus.Empty].includes(this.job?.momentsSyncStatus) && [SyncStatus.Fetched, SyncStatus.Empty].includes(this.job?.commentsSyncStatus));
  }

  @computed get allowFullscreen(): boolean {
    return this.widgetParams.allowFullscreen;
  }

  @computed get allowShare(): boolean {
    return this.widgetParams.allowShare;
  }

  @computed get allowDownload(): boolean {
    return this.widgetParams.allowDownload;
  }

  @computed get showHelp(): boolean {
    return this.widgetParams.showHelp;
  }

  @computed get disableReactions(): boolean {
    return this.widgetParams.disableReactions;
  }

  @computed get disableComments(): boolean {
    return this.widgetParams.disableComments;
  }

  @computed get disableTranscript(): boolean {
    return this.widgetParams.disableTranscript;
  }

  @computed get disableIndex(): boolean {
    return this.widgetParams.disableIndex;
  }

  @computed get autoplay(): boolean | null {
    return this.widgetParams.autoplay;
  }

  @computed get customRedirectUrl(): string | null {
    // TODO: normalize the naming
    return this.widgetParams.customRedirect ?? null;
  }

  @computed get showIndex(): boolean {
    const { job } = this;
    if (!job)
      return false;

    const showIndex = this.widgetParams.showIndex === null ? true : !!this.widgetParams.showIndex;
    return showIndex && !this.widgetParams.disableIndex && !job.isLiveStreaming && !job.isLiveStreamWaiting && !job.isLiveProcessing && !job.isLiveStreamEnded;
  }

  @computed get showComments(): boolean {
    const { job } = this;
    if (!job)
      return false;

    return !!this.widgetParams.showComments &&
      !this.widgetParams.disableComments &&
      !job.isLiveStreaming &&
      !job.isLiveStreamWaiting &&
      !job.isLiveProcessing &&
      !job.isLiveStreamEnded;
  }

  @computed get showTranscript(): boolean {
    const { job } = this;
    if (!job)
      return false;

    return !!this.widgetParams.showTranscript &&
      !this.widgetParams.disableTranscript &&
      !job.isLiveStreaming &&
      !job.isLiveStreamWaiting &&
      !job.isLiveProcessing &&
      !job.isLiveStreamEnded;
  }

  @computed get showProfile(): boolean {
    // TODO: normalize defaults
    return this.widgetParams.showProfile ?? true;
  }

  @computed get showTopicTags(): boolean {
    // TODO: normalize defaults
    return this.widgetParams.showTopicTags ?? true;
  }

  @computed get momentId(): string | null {
    return this.widgetParams.momentId ?? this.routeContext?.searchParams.get('momentId') ?? null;
  }

  @computed get time(): number {
    return this.widgetParams.time ?? 0;
  }

  @computed get showEmbedIcon(): boolean {
    return this.widgetParams.showEmbedIcon ?? true;
  }

  @computed get isAdPlaybackMode(): boolean {
    return this.player.isAdPlaybackMode;
  }

  @computed get isSpeakerIdMode(): boolean {
    return this.playerSpeakerIdController.isSpeakerIdMode;
  }

  @computed get jobId(): string | null {
    return this.routeContext?.params?.jobId ?? null;
  }

  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @observable playerTutorialHighlightedComponents: string[] = [];

  /** Notifies the state that the page has been mounted and triggers the initialization tasks. */
  @action
  async attached(routeContext: RouteContext<PlayerWidgetRouteParams>) {
    TRACE(this, `attached()`, { routeContext }, '\n', this.__traceState);

    this.reset();
    this.baseAttached(routeContext);

    this.emit('Mounted', {
      params: routeContext.params,
      teamId: this.teamId
    });

    this.bindExternalListeners();

    this.setLoading();

    this.store.uiService.setThemeVariation('Inverse');

    const { jobId } = this;
    if (!jobId)
      return this.setError(`Invalid job requested.`);

    this.frameset.init();

    await this.load();

    this.localApiClient.subscribeToAddReactions({
      jobId: this.jobId
    });
  }

  /** Notifies the state that the page has been unmounted and triggers the cleanup tasks. */
  @action
  detached() {
    TRACE(this, `detached()`);

    // this.selector.save(); // if you want to reactivate this make sure !this.playerSpeakerIdController?.isSpeakerIdMode

    this.baseDetached();
    this.reset();

    this.emit('Unmounted');
  }

  @action
  async load(refetchForPermissions: boolean = false) {
    TRACE(this, `load()`, this.__traceState);

    this.setLoading();

    const { authService } = this.store;
    const {
      store,
      jobId
    } = this;

    if (!this.isAttached || !jobId)
      return this.setError(new Error('InternalError'));

    const abortCtrl = new AbortController();
    this.abortController = abortCtrl;

    const reqOpts: ApiRequestOptions = {
      signal: abortCtrl.signal as any // WTF TS?????
    };

    let requests: AsyncResult[] = [
      store.apiFetchJob(jobId, true, reqOpts)
    ];

    const response: Result[] = await Promise.all(requests);

    // if one of the requests is aborted, just stop and reset loading
    if (batchApiResultIsAborted(response))
      return this.setLoadAborted();

    const err = getApiResultJobError(response);
    if (err)
      return this.setError(err);

    if (this.teamId) {
      // no error checking because video might be public but team might be private 
      await store.teamManager.apiFetchTeam({
        id: this.teamId
      });
    }

    // no error checking here since ads are optional
    await this.player.loadAdTagUrl(reqOpts);

    if (!this.job)
      return this.setError();

    if (this.showIndex)
      this.frameset.showSectionOnce('Index');

    // We cannot show both index and comments sections at once 
    // Index section has priority over comments section
    if (this.showComments && !this.showIndex)
      this.frameset.showSectionOnce('Comments');

    if (this.showTranscript)
      this.frameset.showSectionOnce('Transcripts');

    const { job } = this;

    // Fetch current moment
    const momentId = this.momentId;
    let selectedMoment;
    if (momentId) {
      const [moment,] = await job?.apiFetchMoment(momentId, reqOpts);

      if (moment) {
        selectedMoment = moment.getMoment;
      }
    }

    this.broadcast('videoLoaded', {
      jobId,
      title: this.job?.title,
      path: Routes.userVideo(jobId)
    });

    // if the job is a live job start a polling routine
    // for checking when the job's state changes, until it ends
    if (job.isLive && job.liveAggregateStatus !== JobLiveAggregateStatus.ProcessingDone)
      this.jobLiveStatusMonitor.start();

    this.setLoaded();

    // If we're refetching as a side effect of a login/logout action
    // we should avoid unnecessary seek / autoplay

    // TODO: REFACTOR!!
    if (!refetchForPermissions) {
      const { player, selector } = this;

      // autoplay will be read from the param and handled inside the start func
      await player.start();

      //the initial seek makes sense only for a static video mode
      if (!this.job.isLiveStreaming) {
        // handle time
        if (selectedMoment) {
          const moment = new MomentModel(selectedMoment, store);
          selector.enterVideoMode();
          selector.setHighlightedMoment(moment);
          player.invoke('jumpToMoment', {
            moment,
            setAsPrincipal: true
          });
        } else if (this.time !== null) {
          player.applyTimeParam(this.time);
        } else {
          player.applyDirectorInitialSeek();
        }
      }
    }

    this.playerSpeakerIdController.init();

    let jobDependeciesRequests = [this.fetchMomentsAndDependecies(job, reqOpts)];

    if (authService.context?.isAuthenticated) {
      jobDependeciesRequests = [
        ...jobDependeciesRequests,
        this.fetchBookmarks(jobId, reqOpts),
      ];
    }

    // Load comments and reactions
    if (!job.isLiveStreaming) {
      jobDependeciesRequests = [
        ...jobDependeciesRequests,
        this.fetchReactionsAndComments(job, reqOpts)]
    }

    await Promise.all(jobDependeciesRequests);
  }

  async fetchReactionsAndComments(job: JobModel, reqOpts?: any) {
    await Promise.allSettled([
      job.fetchComments(reqOpts),
      job.fetchReactions(reqOpts)
    ]);
  }

  @action
  async fetchBookmarks(jobId: string, reqOpts: any) {
    const [[, err1], [, err2]] = await Promise.all([
      this.store.apiFetchJobRelatedBookmarks({ jobId, first: 100 }, reqOpts),
      this.store.apiFetchBookmarkLists(reqOpts)]);

    if (err1 || err2) {
      notifyError(this, `Could not fetch bookmarks because of an error.`);
    }
  }

  @action
  async fetchMomentsAndDependecies(job: JobModel, reqOpts: any) {
    // Load moments, tracks and speakers
    await job.fetchMoments(reqOpts);

    const [, err2] = await job.apiBatchFetchSpeakers(reqOpts);
    if (err2) {
      notifyError(this, 'Could not fetch speakers because of an error.');
    }
  }

  @action
  private abortLoading() {
    const lastAbortCtrl = this.abortController;
    if (lastAbortCtrl)
      lastAbortCtrl.abort();

    this.jobLiveStatusMonitor.cancel();
  }

  private bindExternalListeners() {
    const { store } = this;
    store.clipWindow.listen(
      this.clipWindowListener);
  }

  private unbindExternalListeners() {
    const { store } = this;
    store.clipWindow.unlisten(
      this.clipWindowListener);
  }

  @action
  private async inlineLogin() {
    const { routeContext } = this;
    if (!this.isAttached || !routeContext)
      return [null, new Error('InvalidComponentState')];

    this.player.invoke('enterPause');
    const [flowRes, flowErr] = await this.inlineAuthFlowController.login(routeContext);
    if (flowErr)
      return [null, flowErr];

    this.dispatch('Overlays', 'closeWindow');
    await this.load(true);
    this.player.invoke('exitPause', { jumpToLive: true });

    return [flowRes!];

  }

  @action
  private async inlineLogout(): AsyncResult<AuthFlowResponse> {

    const { authService } = this.store;
    const { routeContext } = this;
    if (!this.isAttached || !routeContext)
      return [null, new Error('InvalidComponentState')];

    this.player.invoke('enterPause');
    const [flowRes, flowErr] = await this.inlineAuthFlowController.logout(routeContext);
    if (flowErr)
      return [null, flowErr];

    this.dispatch('Overlays', 'closeWindow');

    if (!authService.context) {
      // we have been logged out and a redirect should be expected
      // TODO: add check to see that a redirect was returned
      return [flowRes!];
    }

    await this.load(true);
    this.player.invoke('exitPause', { jumpToLive: true });

    return [flowRes!];
  }


  @action
  handleErrorBackButtonRoute = async (router: History<IRouteStorage>) => {
    this.error = null;
    return this.inlineLogout();
  }

  // #region Tutorial

  @action
  openTutorialWindow(): void {
    this.playerTutorialController.openPlayerTutorial('openPlayerTutorialConfirmationWindow');
    this.subscribeToTutorialController();
  }

  @action
  subscribeToTutorialController(): void {
    const playerTutorialControllerListener = (msg: Message<PlayerTutorialController>) => {
      switch (msg.type) {
        case 'show':
          this.showComponentForTutorial(msg.payload);
          break;

        case 'watchLater':
          this.frameset.hideSection('Index');
          this.frameset.hideSection('Transcripts');
          this.frameset.hideSection('Comments');
          this.playerTutorialController.unlisten(playerTutorialControllerListener);
          break;

        case 'close':
          this.showComponentForTutorial([]);

          if (!this.showIndex) this.frameset.hideSection('Index');
          if (!this.showComments) this.frameset.hideSection('Comments');
          if (!this.showTranscript) this.frameset.hideSection('Transcripts');
          this.playerTutorialController.unlisten(playerTutorialControllerListener);
          break;
      }
    }

    this.playerTutorialController.listen(playerTutorialControllerListener);
  }

  // Just temporary until we find a better solution for OverlayState
  @action
  showComponentForTutorial(componentName: string | string[]): void {
    this.playerTutorialHighlightedComponents = [];
    if (!Array.isArray(componentName)) {
      this.playerTutorialHighlightedComponents.push(componentName);
      return;
    }
    this.playerTutorialHighlightedComponents = componentName;
  }
  // #endregion


  private clipWindowListener = (msg: Message<ClipWindowState>) => {
    const { type, payload } = msg;
    switch (type) {
      case 'momentCreated':
      case 'momentUpdated':
        this.selector.handleMomentMutated(payload.momentId);
        break;

      case 'momentDeleted':
        this.selector.handleMomentDeleted(payload.momentId);
        break;

      default: break;
    }
  }

  private playerListener = (msg: Message<PlayerState>) => {
    const { type } = msg;
    switch (type) {
      case 'activeTranscriptChange':
        this.transcriptsSection.scrollToCurrent();
        break;
      case 'activeTopicChange':
        this.indexSection.scrollToCurrentMoment();
        break;
      case 'lastActiveSubtopicChange':
        this.indexSection.scrollToCurrentMoment();
        break;
      case 'activeCommentChange':
        this.commentsSection.scrollToCurrent();
        break;

      case 'openTutorialWindow':
        this.openTutorialWindow();
        break;

      case 'requestLogin':
        this.inlineLogin();
        break;

      case 'requestLogout':
        this.inlineLogout();
        break;

      default: break;
    }
  }

  private jobLiveStatusMonitorListener = async (msg: Message<JobLiveStatusMonitor>) => {
    const { type, payload } = msg;
    const { player } = this;

    switch (type) {
      case 'Change':
        const status = payload?.status;
        const hasUrl = payload?.hasUrl;
        const liveStreamUrlChanged = payload?.liveStreamUrlChanged;
        // console.log('LiveStatusMonitor.Change', {
        //   job: this.job,
        //   jobId: this.jobId,
        //   liveStatus: this.job?.liveStatus,
        //   status: this.job?.testStatus,
        //   jobStatus: status,
        //   isLivePlaybackActive: this.player.isLivePlaybackActive
        // });
        switch (status) {
          case JobLiveAggregateStatus.Streaming:
            if (!hasUrl) { // prepare loading the player only when url is provided
              this.jobLiveStatusMonitor.start();
              return;
            }

            if (player.isPlaying)
              player.invoke('enterPause');

            await this.load(); // it triggers a jobLiveStatusMonitor start()

            if (liveStreamUrlChanged) {
              player.invoke('reattach');
            }

            if (player.wasPlayingBeforeEnterPause)
              player.invoke('exitPause');

            // TODO: for safety I left it like this, but we need to refactor these calls
            // only removed the useless `if` on `DEFAULT_ENABLE_AUTOPLAY` which was always `true`
            // FOR REVIEWERS: please check that the logic that happens within this call 
            // remains unchanged as compared to the version before the ads implementation and autoplay rewiring
            player.invoke('autoplay');
            break;

          default: // waiting or ended (ingest processing, done, failed)
            if (!this.job)
              return;

            await this.store.apiFetchJob(this.job.id, false);

            if (this.job.isLive && this.job.liveAggregateStatus !== JobLiveAggregateStatus.ProcessingDone)
              this.jobLiveStatusMonitor.start();

            if (this.job.liveAggregateStatus === JobLiveAggregateStatus.ProcessingDone)
              await this.job.fetchReactions();
            break;
        }

        break;
    }
  }

  // #region State helpers
  @action
  reset() {
    this.baseReset();

    this.unbindExternalListeners();

    this.inlineAuthFlowController.reset();
    this.store.uiService.clearThemeVariation();

    this.player.reset();
    this.selector.reset();
    this.frameset.reset();
    this.localApiClient.unsubscribe();

    this.indexSection.reset();
    this.transcriptsSection.reset();
    this.commentsSection.reset();
    this.playerSpeakerIdController.reset();
  }

  getSectionStatus = (section: PlayerSectionName): SectionStatus => {

    switch (section) {
      case 'Comments': {
        const { commentsSyncStatus, commentThreads } = this.commentsSection;
        if (commentsSyncStatus === SyncStatus.Fetching) {
          return SectionStatus.Loading;
        }

        if (commentsSyncStatus === SyncStatus.Error) {
          return SectionStatus.Error;
        }

        if (!commentThreads.length) {
          return SectionStatus.Empty;
        }

        return SectionStatus.NotEmpty;
      }

      case 'Index': {
        // To be implemented
        return SectionStatus.NotEmpty;
      }

      case 'Transcripts': {
        // To be implemented
        return SectionStatus.NotEmpty;
      }

      default:
        return SectionStatus.NotEmpty;
    }
  }

  getSectionAvailability = (section: PlayerSectionName): boolean => {

    const { job } = this;

    if (!job)
      return false;

    if (
      job.isLiveStreamWaiting ||
      job.isLiveStreaming ||
      job.isMediaProcessing ||
      job.isLiveEnded ||
      this.player.isLivePlaybackActive) {
      if (section !== 'Player')
        return false;
    }

    switch (section) {
      case 'Index':
        return !(
          job.isEnrichmentNotRequested &&
          (job.isTranscriptPending || job.isTranscriptProcessing)) &&
          !this.disableIndex

      case 'Transcripts': {
        return !this.disableTranscript;
      }

      case 'Comments':
        return !(job.isLiveStreaming || job.isLiveStreamWaiting || this.disableComments);

      default:
        return true;
    }
  }
  // #endregion

  private get __traceState() {
    return {
      ...this.__baseTraceState,
      allowFullscreen: this.allowFullscreen,
      allowShare: this.allowShare,
      allowDownload: this.allowDownload,
      showHelp: this.showHelp,
      disableReactions: this.disableReactions,
      disableComments: this.disableComments,
      disableTranscript: this.disableTranscript,
      disableIndex: this.disableIndex

    }
  }
}
