import { action, makeObservable, observable } from 'mobx';
import { ApiVariables } from '../../api/apiSchema';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Message, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { WindowState } from '../overlays';
import { GenerativeAiProvider } from '@clipr/lib';

type ChatGptWindowProps = {
  provider: GenerativeAiProvider;
  summarizationEnabled: boolean;
   actionItemsExtractionEnabled: boolean;
   chatGptEnabled: boolean;
   description: string;
   teamId?: string;
   button?: string;
   header: string;
}

export class ToggleChatGptWindowState
  extends StoreNode {

  readonly nodeType = 'ToggleChatGptWindow';

  constructor(store: Store, props?: any) {
    super(store, props);
    makeObservable(this);
    this.window.listen(
      this.windowListener);
  }

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close();
        break;
    }
  }

  @observable isLoading = false;
  @observable teamId: string | null = null;
  @observable summarizationEnabled: boolean = false;
  @observable actionItemsExtractionEnabled: boolean = false;
  @observable chatGptEnabled: boolean = false;
  @observable provider: GenerativeAiProvider =  GenerativeAiProvider.Amazon;
  @observable description: string = '';
  @observable header: string = '';
  @observable button: string = '';

  readonly window = new WindowState(this.store);

  async submit() {
    this.isLoading = true;

    if (this.teamId) {
      this.updateTeamChatGptOptions();
      return;
    }

    this.updateUserChatGptOptions();
  }

  @action 
  private async updateTeamChatGptOptions() {
    const vars: ApiVariables<'updateTeam'> = {
      args: {
        teamId: this.teamId || '',
        generativeAiSettings: {
          summarizationEnabled: this.summarizationEnabled,
          actionItemsExtractionEnabled: this.actionItemsExtractionEnabled,
          cliprGptEnabled: this.chatGptEnabled,
          provider: this.provider
        }
      }
    };

    const [, err] = await this.store.teamManager.updateTeam(vars);
    if (err) {
      this.emit('error');
      this.handleSubmitReject(`Could not update library. Please try again.`);

      return;
    }

    this.emit('success');
    this.handleSubmitSuccess(`Library updated.`);
  }

  @action 
  private async updateUserChatGptOptions() {
    const vars: ApiVariables<'updateProfile'> = {
      args: {
        generativeAiSettings: {
          summarizationEnabled: this.summarizationEnabled,
          actionItemsExtractionEnabled: this.actionItemsExtractionEnabled,
          cliprGptEnabled: this.chatGptEnabled,
          provider: this.provider
        }
      }
    };

    const [, err] = await this.store.api.updateProfile(vars);
    if (err) {
      this.emit('error');
      this.handleSubmitReject(`Could not update profile. Please try again.`);

      return;
    }

    this.emit('success');
    this.handleSubmitSuccess(`Profile updated.`);
  }

  @action
  private handleSubmitReject(msg: string) {
    this.isLoading = false;

    notifyError(this, msg);
  }

  @action
  private handleSubmitSuccess(msg: string) {
    this.isLoading = false;
    this.close();

    notifySuccess(this, msg);
  }

  @action
  open({  
    provider, 
    summarizationEnabled, 
    actionItemsExtractionEnabled, 
    chatGptEnabled,
    description,
    header, 
    button,
    teamId }: ChatGptWindowProps) {
    this.dispatch('Overlays', 'openWindow', { name: 'ToggleChatGptWindow' });
    this.teamId = teamId ?? null;
    this.button = button ?? 'Enable';
    this.header = header;
    this.provider = provider;
    this.description = description;
    this.summarizationEnabled = summarizationEnabled;
    this.actionItemsExtractionEnabled = actionItemsExtractionEnabled;
    this.chatGptEnabled = chatGptEnabled;
  } 

  @action
  close() {
    this.dispatch('Overlays', 'closeWindow');
    this.emit('close');

    this.teamId = null;
  }
}