import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  IFilterableSource,
  ISortableSource,
  SortFieldButton,
  FilterFieldButton,
} from '../input';
import { CatalogState } from '../layout/catalogState';
import { ICatalogSource } from '../layout';
import { Team } from '../../entities';
import { MenuItem } from '../input/menuItems';
import {
  getTeamProductLabel,
  TeamProductValues,
} from '../../entities/teamUtils';
import { AdminTeamsCatalogSource } from '../../entities/adminTeamsCatalogSource';

type Props = {
  model: CatalogState;
  source: ICatalogSource<Team> & ISortableSource & IFilterableSource;
};

export const AdminTeamsCatalogHeader = observer(({ model, source }: Props) => {

  const productLabel: string = getTeamProductLabel(source.getFilterFieldValue('teamProduct')) || 'Product';
  const prefixLabel: string = source.getFilterFieldValue('teamNamespace') || 'Prefix';

  return (
    <div className="admin-teams-table-catalog-header catalog-filters">
      <table className="admin-teams-table-header admin-teams-table-core">
        <thead>
          <tr>
            <th className="thumb-col"></th>
            <th className="name-col">
              <SortFieldButton label="Library Name" name="name" source={source} />
            </th>
            <th className="owner-name-col">
              <SortFieldButton
                label="Owner Name"
                name="ownerName"
                source={source}
              />
            </th>
            <th className="email-col">
              <SortFieldButton
                label="Email Address"
                name="ownerEmail"
                source={source}
              />
            </th>
            <th className="uploads-col">
              <SortFieldButton
                label="Uploads"
                name="uploadsCount"
                source={source}
              />
            </th>
            <th className="last-upload-col">
              <SortFieldButton
                label="Recent Upload"
                name="lastUploadAt"
                source={source}
              />
            </th>
            <th className="members-col">
              <SortFieldButton
                label="Members"
                name="membersCount"
                source={source}
              />
            </th>
            <th className="product-col">
              <FilterFieldButton
                label={productLabel}
                selected={!!source.getFilterFieldValue('teamProduct')}>
                <MenuItem
                  model={{ value: 'All', label: 'All' }}
                  onClick={() =>
                    source.setFilterFieldValue('teamProduct', null)
                  }
                  isSelected={!source.getFilterFieldValue('teamProduct')}
                />

                {TeamProductValues.map((type) => (
                  <MenuItem
                    key={type}
                    model={type}
                    onClick={(evt) =>
                      source.setFilterFieldValue('teamProduct', type)
                    }
                    isSelected={
                      source.getFilterFieldValue('teamProduct') === type
                    }
                  />
                ))}
              </FilterFieldButton>
            </th>

            <th className="prefix-col">
              <FilterFieldButton
                label={prefixLabel}
                selected={!!source.getFilterFieldValue('teamNamespace')}>
                <MenuItem
                  model={{ value: 'All', label: 'All' }}
                  onClick={(evt) =>
                    source.setFilterFieldValue('teamNamespace', null)
                  }
                  isSelected={
                    !source.getFilterFieldValue('teamNamespace')}
                />

                {(source as AdminTeamsCatalogSource).teamNamespaces.map((teamNamespace) => (
                  <MenuItem
                    key={teamNamespace}
                    model={teamNamespace}
                    onClick={(evt) =>
                      source.setFilterFieldValue('teamNamespace', teamNamespace)
                    }
                    isSelected={
                      source.getFilterFieldValue('teamNamespace') === teamNamespace
                    }
                  />
                ))}

                <MenuItem
                  model={{ value: 'None', label: 'None' }}
                  onClick={(evt) =>
                    source.setFilterFieldValue('teamNamespace', 'None')
                  }
                  isSelected={source.getFilterFieldValue('teamNamespace') === 'None'}
                />
              </FilterFieldButton>
            </th>
            <th className="options-col"></th>
          </tr>
        </thead>
      </table>
    </div>
  );
});
