import { action, makeObservable, observable } from 'mobx';
import { Store } from '../store/store';
import { StoreNode } from '../store';
import { AuthFlowResponse, AuthFlowResponseType } from '../services/auth/authFlowSchema';
import { RouteContext } from './routeContext';
import { RouteType } from './routeSchema';
import { RouteState } from './routeStateMixin';

import { INIT_DEBUGGER, TRACE } from '../core/debug/debugMacros';

export class AuthRouteState
  extends RouteState(StoreNode) {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    INIT_DEBUGGER(this, { color: 'teal' });
    TRACE(this, `constructor()`);
  }

  readonly nodeType = 'AuthRoute';
  readonly routeType = RouteType.Auth;

  @observable flowResponse: AuthFlowResponse | null = null;

  @action
  async attached(routeContext: RouteContext) {
    TRACE(this, `attached()`, { routeContext }, this.__traceState);

    this.reset();
    this.baseAttached(routeContext);

    this.setLoading();

    const { authService, routingService } = this.store;
    routingService.routeAttached(routeContext);

    const [res, err] = await authService.runAuthRouteFlow({
      routeContext
    });

    if (err) {
      // TODO: can be better and safer
      if (err.code === 'Forbidden')
        return this.setError(err);

      return this.setFlowResponse({
        responseType: AuthFlowResponseType.RedirectToLoginPage,
        error: err
      });
    }

    return this.setFlowResponse(res!);
  }

  @action
  detached() {
    TRACE(this, `detached()`, this.__traceState);

    this.baseDetached();
    this.reset();

    const { routingService } = this.store;
    routingService.routeDetached();
  }

  @action
  reset() {
    this.baseReset();
    this.flowResponse = null;
  }

  @action
  setFlowResponse(res: AuthFlowResponse) {
    this.setLoaded();
    this.flowResponse = res;
  }
  
  private get __traceState() {
    return {
      ...this.__baseTraceState,
      flowResponse: this.flowResponse
    }
  }
}