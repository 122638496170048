import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { PlayerSectionName } from './playerFramesetSchema';
import { SectionStatus } from '../../pages/userPlayerPage/userPlayerPageState';

type Props = BindingProps<{
  widgetMode?: boolean | null,
  tutorialMode?: boolean | null,
  getSectionAvailability?: (section: PlayerSectionName) => boolean,
  getSectionStatus?: (section: PlayerSectionName) => SectionStatus
}>

const Frames: PlayerSectionName[] = [
  'Player',
  'Toolbar',
  'Transcripts',
  'Index',
  'Comments'
];

export class PlayerFramesetState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  @computed private get widgetMode(): boolean | null {
    return this.getResolvedProp('widgetMode', null);
  }

  @computed private get getSectionAvailablilty(): (section: PlayerSectionName) => boolean {
    return this.getResolvedProp('getSectionAvailability', null);
  }

  @computed private get sectionStatus(): (section: PlayerSectionName) => SectionStatus {
    return this.getResolvedProp('getSectionStatus', null);
  }

  @computed get tutorialMode(): boolean | null {
    return this.getResolvedProp('tutorialMode', null);
  }

  @computed get isWidgetMode(): boolean {
    return this.store.widgetService.getIsWidgetMode(this.widgetMode);
  }

  readonly visibleFrames = observable.set<PlayerSectionName>(Frames);
  readonly visibleOnceSections = observable.set<PlayerSectionName>();

  @action
  toggleSection(name: PlayerSectionName) {
    const sections = this.visibleFrames;
    if (sections.has(name))
      sections.delete(name);
    else {
      if (!this.isSectionAvailable(name))
        return;
      sections.add(name);
    }
  }

  @action
  hideSection(name: PlayerSectionName) {
    return this.visibleFrames.delete(name);
  }
  @action
  showSection(name: PlayerSectionName) {

    if (!this.isSectionAvailable(name))
      return;

    return this.visibleFrames.add(name);
  }
  @action
  setSectionVisibility(name: PlayerSectionName, visibility: boolean) {
    if (!visibility)
      this.hideSection(name);
    else
      this.showSection(name);
  }

  @action
  showSectionOnce(name: PlayerSectionName) {
    if (this.visibleOnceSections.has(name))
      return;

    this.visibleOnceSections.add(name);
    return this.showSection(name);
  }

  @action
  resetVisibleOnceSections() {
    this.visibleOnceSections.clear();
  }

  isSectionVisible(name: PlayerSectionName) {
    return this.visibleFrames.has(name);
  }

  isSectionAvailable(name: PlayerSectionName) {
    if (this.getSectionAvailablilty)
      return this.getSectionAvailablilty(name);

    return true;
  }

  getSectionStatus(name: PlayerSectionName) {
    if (this.sectionStatus)
      return this.sectionStatus(name);

    return SectionStatus.NotEmpty;
  }

  @action
  init() {
    if (this.isWidgetMode) {
      this.visibleFrames.replace(Frames);
      this.hideSection('Transcripts');
      this.hideSection('Index');
      this.hideSection('Comments');
      this.hideSection('Toolbar');
    } else {
      this.visibleFrames.replace(Frames);
      Frames.forEach(item => {
        !this.isSectionAvailable(item) ?
          this.hideSection(item) :
          this.showSection(item);
      });
    }
  }

  @action
  reset() {
    this.resetVisibleOnceSections();
  }
}