import { action, makeObservable, observable } from 'mobx';

import { Store } from '../../../store/store';
import { StoreNode } from '../../../store';
import { RegisterFormState } from './registerFormState';

type AuthRegisterPageMode = 'poster' | 'plain';
export class RegisterPageState
  extends StoreNode {

  @observable layout: AuthRegisterPageMode = 'poster';

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  readonly form = new RegisterFormState(this.store);

  @action
  pageMounted() { }

  @action
  pageUnmounted() {
    this.form.reset();
  }
}