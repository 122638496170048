import './speakers.scss';
import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { SpeakerModel } from '../../entities';
import { SpeakerCard } from './speakerCard';

type Props = {
  model?: Iterable<SpeakerModel>,
  disabledIds?: Iterable<string>,
  selectedIds?: Iterable<string>,
  onCardClick?: (evt: SyntheticEvent, target: SpeakerModel) => void
}

export const SpeakerCardList = observer(({
  model = [],
  disabledIds = [],
  selectedIds = [],
  onCardClick
}: Props) => {

  const disabledIdSet = new Set(disabledIds);
  const selectedIdSet = new Set(selectedIds);

  return (
    <ul className="speaker-card-list">
      {[...model].map(speaker => {

        return (
          <li key={speaker.id}
            className="speaker-card-list-item">

            <SpeakerCard
              model={speaker}
              disabled={disabledIdSet.has(speaker.id)}
              selected={selectedIdSet.has(speaker.id)}
              onClick={onCardClick && (evt => onCardClick(evt, speaker))} />
          </li>
        );
      })}
    </ul>
  );
});