import '../jobs/jobs.scss';

import React, { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import { paramCase } from 'change-case';

import { PlaySVGIcon, ZoomPlaceholderSVGIcon /* CodeSVGIcon */ } from '../svg';
import {
  IngestingSVGIcon,
  TextInputErrorSVGIcon,
  TextInputWarningSVGIcon,
} from '../svg/svg';
import { Thumb } from './thumb';
import { Link } from '../layout';
import {
  BackRoutes,
  RouteParams,
  Routes,
  UserDashboardView,
} from '../../routes';
import { Maybe, ObjectLiteral } from '../../core';
import { DotSpinner } from '../loader';
import { Button, CheckboxInput } from '../input';
import { LibraryCatalogItem, LibraryCatalogSource } from '../../entities';
import { Tooltip } from '../overlays';
import { UploadPageParams } from '../../pages';

type Props = {
  model: LibraryCatalogItem;
  className?: string | ObjectLiteral;
  view?: UserDashboardView;
  source: LibraryCatalogSource;
};

export const LibraryTableRow = observer(
  ({ model, source, view, className }: Props) => {
    const { isWidgetMode } = model.store.widget;
    // Define next / previous routes
    const params = useParams<UploadPageParams>();
    const { search } = window.location;
    const query = new URLSearchParams(search);
    const teamId = params?.teamId || query.get('teamId') || undefined;
    const routeParams: RouteParams = {
      id: model.job?.id,
      view: view as UserDashboardView,
      teamId,
    };
    let linkRoute: Maybe<LocationDescriptor> = null;

    if (!isWidgetMode && model.isIngested && model.job?.isPublished) {
      linkRoute = BackRoutes[Routes.userVideoLanding()].route(routeParams);
    }
    const linkTarget = model.store.widget.isWidgetMode ? '_blank' : undefined;

    const { hasMultipleSelection, fileSelectionLookup } = source;
    const isSelected = fileSelectionLookup.has(model.id);

    className = classNames('job-table-row', paramCase(model.status || ''), {
      selected: isSelected,
    });

    return (
      <tr className={className}>
        <td className="select-col">
          {model.isNotProcessed && (
            <CheckboxInput
              name="select"
              checked={isSelected}
              onChange={() => source.updateFileSelection(model)}
            />
          )}
        </td>
        <td
          className={
            'thumb-col ' +
            (source.getSortFieldValue('name') !== 'none' && 'active')
          }>
          <Link to={linkRoute!} target={linkTarget} className="link fill" title={model.name}>
            <RowPreview model={model} />
          </Link>
        </td>

        <td
          className={
            'title-col ' +
            (source.getSortFieldValue('name') !== 'none' && 'active')
          }>
          <Link to={linkRoute!} target={linkTarget} className="link title" title={model.name}>
            <span title={model.name}>{model.name}</span>
          </Link>
        </td>

        <td className="duration-col">
          <Link to={linkRoute!} target={linkTarget} className="link fill">
            <span>{model.durationToTimeCode('card')}</span>
          </Link>
        </td>

        <td
          className={
            'upload-col ' +
            (source.getSortFieldValue('modifiedTime') !== 'none' && 'active')
          }>
          <Link to={linkRoute!} target={linkTarget} className="link fill" title={model.createdAt}>
            <span>
              {model.createdAtDate.toLocaleString(DateTime.DATE_MED)}{' '}
              {model.createdAtDate.toLocaleString(DateTime.TIME_SIMPLE)}
            </span>
          </Link>
        </td>

        <td className="options-col">
          {model.isNotProcessed && (
            <Button
              label="Ingest"
              className="primary btn-outline small"
              onClick={() => model.startIngest(teamId)}
              disabled={hasMultipleSelection}
            />
          )}
          {model.isIngesting && (
            <div className="row-info">
              <IngestingSVGIcon />
              <div className="row-info-status">
                <b>CLIPr</b> is <b>ingesting</b> your video.
                <br />
                This will take about half the length <br /> of the video.
              </div>
            </div>
          )}
          {model.isFailed && (
            <Button
              label="Retry"
              className="green small"
              onClick={() => model.retryIngest()}
            />
          )}
          {model.isIngested && model.job?.isPublished && (
            <Button
              label="Added to CLIPr"
              className="green btn-outline small"
              onClick={() => isWidgetMode ? null : model.store.goTo(linkRoute!)}
            />
            // <>
            //   <Tooltip content="Preview">
            //     <Button
            //       icon={<PlaySVGIcon />}
            //       className="preview-button"
            //       route={linkRoute || '#'}
            //       target="_blank"
            //     />
            //   </Tooltip>
            //   <Tooltip content="Copy Embed Code">
            //     <Button
            //       className="embed-button"
            //       icon={<CodeSVGIcon />}
            //       onClick={() => model.copyIFrameEmbedCode()}
            //     />
            //   </Tooltip>
            // </>
          )}

          {!model.isAvailableForIngest && (
            <Tooltip
              className="not-supported-tooltip"
              arrowClassName="not-supported-tooltip-arrow"
              content={'Format currently not supported'}>
              <Button
                label="Not Supported"
                className="red btn-outline small not-supported-btn"
                onClick={() => null}
              />
            </Tooltip>
          )}
          {!model.status && !model.canDownload && (
            <Tooltip
              className="popup-tip"
              content={
                "We're sorry, but this video cannot be ingested because the owner has not permitted global sharing on Google Drive. Please ask them to change permissions on the video, or download a copy to your computer and upload it directly."
              }>
              <Button
                label="Ingest"
                className="primary btn-outline small"
                onClick={() => null}
                disabled={true}
              />
            </Tooltip>
          )}
        </td>
      </tr>
    );
  }
);

const RowPreview = observer(
  ({ model }: { model: LibraryCatalogItem }) => {
    let content: Maybe<ReactNode>;

    const cx = classNames('card-overlay', {
      'not-ingested': !model.status,
      done: model.isIngested && model.job?.isPublished,
      processing: model.isIngesting,
      failed: model.isFailed,
      unpublish: model.isIngested && !model.job?.isPublished,
    });

    if (model.isIngesting) {
      content = (
        <>
          <IngestingSVGIcon />
          <DotSpinner className="small" />
        </>
      );
    }
    if (model.isFailed) {
      content = <TextInputErrorSVGIcon />;
    }
    if (model.isIngested) {
      content = (
        <div className="play-icon preview-icon">
          <PlaySVGIcon />
        </div>
      );
    }

    if (model.isIngested && !model.job?.isPublished) {
      content = (
        <div className="card-overlay unpublish">
          <TextInputWarningSVGIcon />
          <p>Video is no longer available</p>
        </div>
      );
    }

    const thumbnail = model.thumbnail || model.jobModel?.posterURL;

    return (
      <div className="row-preview">
        {thumbnail || model.libraryType !== 'Zoom' ? (
          <Thumb source={thumbnail} className="card-thumb dock" />
        ) : (
          <div className="thumb card-thumb dock">
            <div className="thumb-img">
              <ZoomPlaceholderSVGIcon />
            </div>
          </div>
        )}
        <div className={cx}>{content}</div>
      </div>
    );
  }
);
