import { makeObservable } from 'mobx';
import { Store } from '../../store/store';
import { CatalogState } from '../../components/layout/catalogState';
import { ZoomCatalogSource } from '../../entities';
import { IntegrationTabState } from './integrationTabState';
import { LibraryProvider } from '../../services/libraries';
import { BindingProps } from '../../store';

type ViewType = 'grid' | 'list';
type Props = BindingProps<{
  pageSize: number,
  libraryService: LibraryProvider,
  defaultSearchValue?: string
  viewType?: ViewType;
}>

export class ZoomIntegrationTabState
  extends IntegrationTabState {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.catalogSource = new ZoomCatalogSource(this.store, {
      pageSize: props?.pageSize as number || 20,
    });
    this.catalogSource.listen((msg) => {
      switch (msg.type) {
        case 'credentialsExpired': {
          if (!this.credentialsExpired) {
            this.credentialsExpired = true;
            this.handleCredentialsExpired();
          }
          break;
        }
      }
    });

    this.catalog = new CatalogState(this.store, {
      syncStatus: () => this.catalogSource.syncStatus,
      isEndOfList: () => this.catalogSource.isEndOfList,
      viewType: this.props.viewType,
    });
    this.catalog.listen((msg: any) => {
      switch (msg.type) {
        case 'scrollToBottom':
        case 'loadMore':
          if (!this.catalogSource.isEndOfList) {
            this.catalogSource.fetchMore();
          }
          break;
      }
    });
  }

  readonly catalogSource: ZoomCatalogSource;
  readonly catalog: CatalogState;
}