import { action, IObservableArray, makeObservable, observable } from 'mobx';
import { createArray } from '../../core/array';

type TrackerProps = {
  intervalInMs: number,
  onIncrement?: () => void
}

type TimeTrackerStatus = 'running' | 'paused' | 'stopped';

export class TimeTracker {

  constructor(props?: TrackerProps) {
    makeObservable(this);
    this.incrementInterval = props?.intervalInMs || 50;
    this.onIncrement = props?.onIncrement || null;
  }

  readonly history: IObservableArray = createArray<number>(true) as IObservableArray;
  readonly onIncrement: (() => void) | null;

  @observable timeInMs: number = 0;
  @observable incrementInterval: number = 0;
  @observable interval: NodeJS.Timeout | null = null;
  @observable status: TimeTrackerStatus = 'stopped';

  get timeInSec() {
    return this.timeInMs / 1000;
  }

  initInterval() {
    if (!this.interval)
      this.interval = setInterval(() => this.incr(), this.incrementInterval);
  }

  clearInterval() {
    if (this.interval)
      clearInterval(this.interval);
    this.interval = null;
  }

  @action
  incr() {
    this.timeInMs += this.incrementInterval;
    this.onIncrement?.();
  }

  start() {
    this.initInterval();
    this.status = 'running';
  }

  pause() {
    this.clearInterval();
    this.status = 'paused';
  }

  stop() {
    if (this.status === 'stopped')
      return;
    this.clearInterval();
    this.status = 'stopped';
    this.history.push(this.timeInMs);
    this.timeInMs = 0;
  }

  reset() {
    this.clearInterval();
    this.history.clear();
    this.timeInMs = 0;
    this.status = 'stopped';
    this.interval = null;
  }

  restart() {
    this.stop();
    this.start();
  }

  setTime(valueInMs: number) {
    this.timeInMs = valueInMs;
  }

  setIncrementInterval(valueInMs: number) {
    this.incrementInterval = valueInMs;
  }
}