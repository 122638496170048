import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { Button } from '../input';
import { RemoveJobFromCliprWindowState } from './removeJobFromCliprWindowState';

type Props = {
  model: RemoveJobFromCliprWindowState;
  visible?: boolean;
};

export const RemoveJobFromCliprWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      model={model.window}
      visible={props.visible}
      isLoading={model.isLoading}>
      <WindowHeader model={model.window} title="Remove from CLIPr" />

      <hr />

      <div className="window-content">
        <p className="p-bold">Are you sure you want to remove this video?</p>
        <p>This action cannot be undone.</p>
      </div>

      <hr />

      <footer className="window-footer">
        <div className="btn-group right">
          <Button
            className="primary btn-outline medium"
            label="Cancel"
            onClick={() => model.close()}
          />
          <Button
            className="red medium"
            label="Remove"
            onClick={() => model.submit()}
          />
        </div>
      </footer>
    </Window>
  );
});
