import { makeObservable, observable, action, computed } from 'mobx';

import { Store } from '../../store/store';
import { StoreNode, Message } from '../../store';
import { WindowState } from '../overlays';
import { CommentState } from './commentState';
import { JobModel } from '../../entities';
import { notifyError, notifyLoading, notifySuccess } from '../../services/notifications';
import { AsyncResult } from '../../core';
import { Comment } from '../../entities/comment';

export type CommentDeleteOpenParams = {
  comment: CommentState
};

export class CommentDeleteWindowState
  extends StoreNode {

  readonly nodeType = 'CommentDeleteWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(this.windowListener);
  }

  readonly window = new WindowState(this.store);

  @observable comment: CommentState | null = null;

  @observable title: string | null = null;

  @observable isLoading: boolean = false;

  @computed get job(): JobModel | null {
    return this.comment?.job ?? null;
  }

  @action
  async submit()
    : AsyncResult<Comment> {

    const comment = this.comment;

    const job = comment?.job;
    const jobId = this.comment?.jobId ?? null;
    const commentId = this.comment?.commentId ?? null;

    if (!job || !commentId || !jobId) {
      notifyError(this, `An error occured while deleting your comment.`);
      return [null, new Error(`Comment cannot be deleted because the state is invalid.`)];
    }

    this.isLoading = true;

    notifyLoading(this, `Deleting comment...`);
    const [comm, err] = await job.apiDeleteComment({
      jobId,
      commentId
    });

    if (err) {
      notifyError(this, `An error occured while deleting your comment.`);
      this.isLoading = false;
      return [null, err];
    }

    notifySuccess(this, `Comment deleted.`);
    this.isLoading = false;
    this.close();
    return [comm!];
  }

  @action
  open(params: CommentDeleteOpenParams) {
    this.dispatch('Overlays', 'openWindow', { name: this.nodeType });
    this.comment = params.comment || null;

    if (!this.comment) return;
    const type = this.comment.isReply ? 'reply' : 'comment';
    this.title = `Are you sure you want to delete this ${type}?`;
    this.emit('open');
  }

  @action
  close(msg?: string) {
    this.dispatch('Overlays', 'closeWindow');
    this.comment = null;
    this.title = null;
    if (msg) this.emit(msg);
    this.emit('close');
  }

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close('close');
        break;
    }
  }
};