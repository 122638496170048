import '../input.scss';
import '../inputs-v1-1.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { CloudUploadSVGIcon, TextInputErrorSVGIcon } from '../../svg/svg';
import { FileInputProps } from './fileInputSchema';
import { LoadMask } from '../../loader/loadMask';

export const PreviewUploadBox = observer(
  ({
    id,
    multiple = false,
    value,
    className,
    label,
    disabled,
    isFocused,
    isHovered,
    isRequired,
    showStatus,
    status,
    statusMessage: feedback,
    showLabel = true,
    persistentLabel = false,
    labelPosition = 'topStart',
    showFeedback = true,
    persistentFeedback = true,
    feedbackPosition = 'bottom',
    placeholder,
    type = 'file',
    readOnly = false,
    children,
    //file specific
    isDragHovered,
    dragError,
    dragErrorMessage,
    previewUrl,
    isLoading = false,
    onLoad,
    handleDrop,
    handlePointerEnter,
    handlePointerLeave,
    handleDragEnter,
    handleDragLeave,
    handleFileClick,
    layout,
    layoutSize,
    ...props
  }: FileInputProps) => {

    className = classNames('file-input-box', layoutSize, { // input-box
      hover: isHovered,
      'drag-hover': isDragHovered,
      'drag-error': isDragHovered && dragError,
      error: (showStatus && status === 'error')
    });

    let inputElem;
    const inputElemClassNames = classNames('upload-file-region file-input', //className
    {
      'read-only': readOnly,
    });

    inputElem = (
      <input
        id={id}
        type="file"
        className={inputElemClassNames}
        role="presentation"
        multiple={multiple}
        //@ts-ignore
        onDragEnter={handleDragEnter}
        //@ts-ignore
        onDragLeave={handleDragLeave}
        disabled={disabled}
        {...props} />
    );

    const iconElem =
      isDragHovered && dragError ? (
        <TextInputErrorSVGIcon />
      ) : (
        <CloudUploadSVGIcon />
      );

    return (
      <div
        className={className}
        aria-disabled={disabled}
        //@ts-ignore
        onDrop={handleDrop}
        onPointerEnter={handlePointerEnter}
        onPointerLeave={handlePointerLeave}
        style={{ ['--aspect-ratio' as any]: 9 / 16 }}>
        <div className="file-input-wrapper">
          {(!previewUrl && !isDragHovered) &&
            <DragAndDropFilePlaceholder />}
          {previewUrl && (
            <div className="image-preview">
              <img src={previewUrl}
                alt="preview thumbnail"
                onLoad={() => onLoad?.()} />
              <LoadMask
                className={'avatar-load-mask spinner small partial'}
                spinner={'dots3'}
                visible={isLoading} />
            </div>
          )}

          <div className="drag-overlay">
            <div className="upload-icon">{iconElem}</div>
            <span className="text-label">
              {dragError
                ? dragErrorMessage
                : 'Drop your file here'}
            </span>
          </div>

          {inputElem}
        </div>
      </div>
    );
  });

export const DragAndDropFilePlaceholder = () => {
  return <div className="drag-drop-placeholder">
    <div className="upload-icon">
      <CloudUploadSVGIcon />
    </div>
    <span className="text-label">Upload image</span>
  </div>
}