import { action, makeObservable, observable } from 'mobx';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { WindowState } from '../overlays/windowState';
import { LiveJobSourceInput, LiveStreamActivePlaylist } from '@clipr/lib';

export class LiveNotStreamingWindowState
  extends StoreNode {

  readonly nodeType = 'LiveNotStreamingWindow';

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  readonly window = new WindowState(this.store);

  @observable jobId: string | null = null;
  @observable isLoading: boolean = false;
  @observable liveStreamActivePlaylist: LiveStreamActivePlaylist | null = null;


  @action
  async submit() {
    if (!this.jobId || !this.liveStreamActivePlaylist) {
      notifyError(this, 'Could not load job');
      console.error(this, 'No job id provided');
      return;
    }

    this.isLoading = true;

    const updateLiveSourceInput: LiveJobSourceInput = {
      liveStreamActivePlaylist: this.liveStreamActivePlaylist
    }

    const [, err] = await this.store.jobManager.apiUpdateLiveJobSource(this.jobId, updateLiveSourceInput);

    if (err) {
      notifyError(this, 'There was an error changing the live feed');
      this.isLoading = false;
      return;
    }

    this.isLoading = false;
    notifySuccess(this, `Stream was updated.`);
    this.close();
  }

  @action
  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        this.close();
        break;
    }
  }

  @action
  open({ jobId, liveStreamActivePlaylist }: { jobId: string, liveStreamActivePlaylist: LiveStreamActivePlaylist }) {
    this.dispatch('Overlays', 'openWindow', { name: 'LiveNotStreamingWindow' });

    this.jobId = jobId;
    this.liveStreamActivePlaylist = liveStreamActivePlaylist;
  }

  @action
  close() {
    this.jobId = null;
    this.liveStreamActivePlaylist = null;

    this.emit('close');
    this.dispatch('Overlays', 'closeWindow');
  }
}