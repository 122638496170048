export type ConfigAuthConnection = {
  enabled: boolean;
  showSocialLoginButton?: boolean;
  allowForcing?: boolean;
  auth0: {
    connection: string;
  }
}

export enum ConfigAutoplayPolicy {
  /** Autoplay will be used if allowed by the browser and by player configuration. */
  Standard = 'Standard',

  /** Autoplay will always be used. This is mostly for debugging and testing. */
  Force = 'Force',

  /** Autoplay will never be used. */
  Disabled = 'Disabled'
}

export type GlobalConfig = {
  /** Configuration for AuthService */
  auth: {

    minPasswordLength: number;

    storageIdTokenExpiresKey: string;
    storageIsAuthenticatedKey: string;
    storageForceConnectionKey: string;

    connections: Record<string, ConfigAuthConnection>;

    auth0: {
      domain: string;
      clientId: string;
      responseType: string;
      defaultConnection: string;
      redirectUrlDataMaxOutputLength: number;
      redirectUrlDataKey: string;
    }
  },

  player: {
    /** 
     * The number of seconds which need to pass after a live stream ended 
     * until the appropriate live ended message is displayed. This only applies to manual ingestion mode.
     */
    liveStreamEndedMessageDelta: number;

    autoplayPolicy: ConfigAutoplayPolicy;
  },

  routing: {
    maxUrlLength: number;
    storageKeyPrefix: string;
    redirect: {
      lastPrivatePathKey: string;
      authFlowKey: string;
    }
  },

  liveFeedPage: {
    activeStreamTooltipMessage: string;
  }
  
  speakerId: {
    autoTriggerSpeakerIdFlow: boolean;
    speakerIdConfirmationMessage: string;
  }

  storage: {
    testKey: string;
    testValue: string;
  }

  vendor: {
    react: {
      useStrictMode: boolean;
    },

    googleImaSdk: {
      url: string;
    },

    textEditor: {
      apiKey: string;
    }
  },

  debug: {
    // TODO: make better
    enable: boolean;
    trace: boolean;
    traceFilter: string[];
  }
}