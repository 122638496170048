import './notifications.scss';

import React, { ReactEventHandler } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { INotification, NotificationType } from './notificationService';
import { Nullable } from '../../core';
import {
  NotificationMessageCheckSVGIcon,
  NotificationMessageErrorSVGIcon,
  NotificationMessageWarningSVGIcon,
  NotificationCloseSVGIcon,
} from '../../components';

type Props = {
  notification?: Nullable<INotification>;
  visible?: boolean;
  onClose?: ReactEventHandler;
};

type BadgeIconProps = {
  type?: NotificationType;
};

const NotificationBadgeIcon = (props: BadgeIconProps) => {
  let badgeIcon = null;

  switch (props.type) {
    case 'success':
      badgeIcon = <NotificationMessageCheckSVGIcon />;
      break;
    case 'error':
      badgeIcon = <NotificationMessageErrorSVGIcon />;
      break;
    case 'warning':
      badgeIcon = <NotificationMessageWarningSVGIcon />;
      break;
    default:
      break;
  }

  return (
    badgeIcon && (
      <div className="notification-badge-icon flex-center">{badgeIcon}</div>
    )
  );
};

export const NotificationMessage = observer((props: Props) => {
  const { notification } = props;
  if (!notification) return null;

  const className = classNames('app-notification', notification.type, {
    visible: props.visible,
  });

  return (
    <div className={className} aria-hidden={!props.visible}>
      <NotificationBadgeIcon type={notification.type} />
      <div className="content">
        {notification.content}
        {notification.description && (
          <div className="description">{notification.description}</div>
        )}
      </div>
      <div className="close" onClick={props.onClose}>
        <NotificationCloseSVGIcon />
      </div>
    </div>
  );
});
