import React from 'react';
import { observer } from 'mobx-react-lite';
import { Case, Switch } from './switch';
import { Button } from '../input';
import { DotSpinner } from '../loader';
import { CatalogState } from './catalogState';

type Props = {
  model: CatalogState
}

export const CatalogFooter = observer(({
  model
}: Props) => {
  return (
    <div className="catalog-footer">
      <Switch>
        <Case name="endOfList"
          className="footer-end"
          visible={model.syncStatus === 'idle' && model.isEndOfList}>
          End of list
        </Case>

        <Case name="idle"
          className="footer-idle"
          visible={model.syncStatus === 'idle' && !model.isEndOfList}>
          <Button className="rounded secondary"
            label="Load more"
            onClick={model.handleLoadMoreButtonClick}>Load more</Button>
        </Case>

        <Case name="fetching"
          className="footer-fetching"
          visible={model.syncStatus === 'fetchingMore'}>
          <DotSpinner />
        </Case>
      </Switch>
    </div>
  );
});