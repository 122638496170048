import { timeRegionContains, timeRegionEquals, timeRegionsOverlapStrict } from '../../core/time/timeUtils';
import { MomentModel } from '../moment';
import { MomentEdge, MomentEdgeFlags, MomentEdgeFlagsInverseLookup } from './momentEdge';
import { MomentNode, MomentNodeFlags } from './momentNode';

type Moment = MomentModel;

class FieldValueLookup<T = string> {

  readonly lookup = new Map<T, Set<MomentNode>>();
  add(node: MomentNode, val: T) {
    let valSet = this.lookup.get(val);
    if (!valSet) {
      valSet = new Set();
      this.lookup.set(val, valSet);
    }

    valSet.add(node);
    return this;
  }
}

export class MomentGraph {

  constructor(data: Moment[]) {
    
    this.data = data;
    this.build();
  }

  readonly data: Moment[];

  private nodesInitialized = false;
  private readonly nodeLookup = new Map<string, MomentNode>();
  private readonly nodeList: MomentNode[] = [];

  private edgesInitialized = false;
  private readonly edgeLookup = new Map<string, Map<string, MomentEdge>>();
  private readonly edgeList: MomentEdge[] = [];

  private readonly nameLookup =
    new FieldValueLookup<string>();

  private readonly speakerIdLookup =
    new FieldValueLookup<string>();

  get edges() {
    return this.edgeList;
  }
  get nodes() {
    return this.nodeList;
  }

  getNode(id: string): MomentNode | null {
    return this.nodeLookup.get(id) || null;
  }
  getEdge(srcId: string, tgtId: string): MomentEdge | null {
    return this.edgeLookup.get(srcId)?.get(tgtId) || null;
  }

  private initNodes() {

    const lookup = this.nodeLookup;
    const list = this.nodeList;

    const nameLookup = this.nameLookup;

    const data = this.data.slice();
    data.sort((a, b) => a.startTime - b.startTime);

    for (let i = 0; i < data.length; i++) {
      const moment = data[i];
      const id = moment.id;
      const node = new MomentNode(i, moment);

      // register fields in the lookup
      const name = moment.name;
      if (name)
        nameLookup.add(node, name);

      if (moment.isTopic)
        node.flags.add(MomentNodeFlags.Topic);
      if (moment.isTranscript)
        node.flags.add(MomentNodeFlags.Transcript);
      if (moment.isGeneric)
        node.flags.add(MomentNodeFlags.Generic);

      // add the node to the lookup objects
      lookup.set(id, node);
      list.push(node);
    }

    this.nodesInitialized = true;
    return this;
  }

  private initEdges() {
    this.initConnectivityEdges();
    this.initLookupEdges();
    this.edgesInitialized = true;
  }

  private initConnectivityEdges() {

    const nodes = this.nodeList;

    for (let i = 0; i < nodes.length; i++) {

      const source = nodes[i];
      const sourceMoment = source.moment;
      const sourceId = sourceMoment.id;
      const sourceStart = sourceMoment.startTime;
      const sourceEnd = sourceMoment.endTime;

      // initialize connectivity edges
      // because of the sorting we can always iterate only to the right of the array

      for (let j = i + 1; j < nodes.length; j++) {

        const target = nodes[j];
        const targetMoment = target.moment;
        const targetId = targetMoment.id;
        const targetStart = targetMoment.startTime;
        const targetEnd = targetMoment.endTime;

        // shortcut
        const flag = (flag: MomentEdgeFlags) =>
          this.addEdgeFlag(source, target, flag, true);

        if (timeRegionsOverlapStrict(sourceMoment, targetMoment)) {

          flag(MomentEdgeFlags.Connected);

          if (timeRegionContains(sourceMoment, targetMoment))
            flag(MomentEdgeFlags.Contains);
          if (timeRegionContains(targetMoment, sourceMoment))
            flag(MomentEdgeFlags.Contained);
          if (timeRegionEquals(targetMoment, sourceMoment))
            flag(MomentEdgeFlags.SameRegion);

          if (sourceStart <= targetStart)
            flag(MomentEdgeFlags.StartsBefore);
          if (sourceStart >= targetStart)
            flag(MomentEdgeFlags.StartsAfter);
          if (sourceStart === targetStart)
            flag(MomentEdgeFlags.SameStart);

          if (sourceEnd <= targetEnd)
            flag(MomentEdgeFlags.EndsBefore);
          if (sourceEnd >= targetEnd)
            flag(MomentEdgeFlags.EndsAfter);
          if (sourceEnd === targetEnd)
            flag(MomentEdgeFlags.SameEnd);

          if (targetStart === sourceEnd) {
            // inner moment starts immediately after the outer one ends
            // we can add some StartsAfter flags
            flag(MomentEdgeFlags.LastBefore);
          }

        } else {
          // because we sorted the source data in ascending order,
          // if the regions do not overlap, we can assert that the moment in the inner loop
          // starts after the moment in the outer loop ends (strictly, because the region overlap check includes equality)

          // once we reach this non-overlap branch we only need one iteration to add the edges for StartsAfter / EndsBefore flags
          // then we can break out of the loop because the remaining moments 
          // are guaranteed not to be connected to the outer loop moment

          if (targetStart < sourceEnd) {
            // just a quick defensive check
            console.error(`Failed to initialize MomentGraph. Expected Moment#${targetId} to start after the end of Moment#${sourceId}.`);
            return;
          }

          flag(MomentEdgeFlags.LastBefore);
          break;
        }
      }
    }
  }

  private initLookupEdges() {

    const descLookup = this.nameLookup;

    for (const [, nodeSet] of descLookup.lookup) {
      // add flags for same description 
      // first convert it to array because we'll iterate over it in pairs
      const sameDescNodes = [...nodeSet];
      const len = sameDescNodes.length;

      for (let i = 0; i < len; i++) {
        for (let j = i + 1; j < len; j++) {
          this.addEdgeFlag(sameDescNodes[i], sameDescNodes[j], MomentEdgeFlags.SameDescription, true);
        }
      }
    }
  }

  private commitNodes() {
    this.nodeList.forEach(node => node.commit());
  }
  private commitEdges() {
    this.edgeList.forEach(edge => edge.commit());
  }


  private addEdge(
    source: MomentNode,
    target: MomentNode) {

    let srcEdges = this.edgeLookup.get(source.key);
    if (!srcEdges) {
      srcEdges = new Map();
      this.edgeLookup.set(source.key, srcEdges);
    }

    let edge = srcEdges.get(target.key);
    if (!edge) {
      edge = new MomentEdge(this.edgeList.length, source, target);

      // register the edge onto the nodes
      source.edges.push(edge);
      source.outgoingEdges.push(edge);
      target.edges.push(edge);
      target.incomingEdges.push(edge);

      // register the edge into the graph
      srcEdges.set(target.key, edge);
      this.edgeList.push(edge);
    }

    return edge;
  }

  private addEdgeFlag(
    source: MomentNode,
    target: MomentNode,
    flag: MomentEdgeFlags,
    inverse = false) {

    const edge = this.addEdge(source, target);
    edge.flags.add(flag);

    if (inverse) {
      // add the corresponding inverse flag to the inverse pair
      // NOTE!: always specify the `inverse` flag explicitly as `false`
      // to prevent infinite recursions if the default value of the parameter would change
      this.addEdgeFlag(target, source, MomentEdgeFlagsInverseLookup[flag], false);
    }
  }

  private build() {
    this.initNodes();
    this.initEdges();
    this.commitNodes();
    this.commitEdges();
  }
}