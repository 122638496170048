import React from 'react';
import { observer } from 'mobx-react-lite';
import { PlayerState } from '../playerState';
import { PlayerExplorerBar } from './explorerBar';
import { PlayerMarkerBar } from './markerBar';
import { PlayerPlaybar } from './playerPlaybar';
import { PlayerControlsBar } from './controlsBar';
import { PlayerCaptionsComponent } from './playerCaptionsComponent'
import { PlayerComponentName } from '../playerSchema';
import { PlayerAdsBar } from './adsBar';

type Props = {
  model: PlayerState
}

/**
 * The entire bottom region of the player housing the comments / progress / control bars.
 * The name is used by YouTube, so if it works for them, it works for us.
 */
export const PlayerChromeBottom = observer(({
  model
}: Props) => {

  const showAdsBar = model.isComponentVisible(PlayerComponentName.AdsBar);

  const indexVisible = model.frameset?.isSectionVisible('Index');
  const showSubtitles = model.captionsAreEnabled && model.captionsAreVisible;

  const { chrome } = model;
  return (
    <div id="user-player-chrome-bottom"
      onPointerEnter={chrome.handleRegionPointerEnter}
      onPointerLeave={chrome.handleRegionPointerLeave}>

      {(indexVisible || model.isFullscreen) &&
        showSubtitles &&
        <PlayerCaptionsComponent
          model={model} />
      }

      <PlayerExplorerBar
        model={model} />
      {/* 
      <UserPlayerStatusBar
        model={model} /> */}

      <PlayerMarkerBar
        model={model.commentsBar} />

      {showAdsBar &&
        <PlayerAdsBar
          model={model.adsBar} />}

      <PlayerPlaybar
        model={model}
        policy={model.getComponentPolicy(PlayerComponentName.ProgressBar)} />

      <PlayerControlsBar
        model={model} />
    </div>
  );
});