import * as React from 'react';

export const ChevronRightSVGIcon = () =>
  <svg className="chevron-right-svg-icon svg-icon" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L5 5L1 9" className="stroke" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

export const ChevronLeftSVGIcon = () =>
  <svg className="chevron-left-svg-icon svg-icon" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 9L1 5L5 1" className="stroke" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

export const ChevronDownSVGIcon = () =>
  <svg className="chevron-down-svg-icon svg-icon" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="stroke" d="M9 1L5 5L1 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

export const MicrophoneSVGIcon = () =>
  <svg className="microphone-svg-icon svg-icon" viewBox="0 0 10 10" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path fill="#0B0434" d="M4.99604 6.3787C6.03503 6.3787 6.87856 5.53517 6.87856 4.49618V2.09932C6.87856 1.06033 6.03503 0.216797 4.99604 0.216797C3.95706 0.216797 3.11353 1.06033 3.11353 2.09932V4.50647C3.11353 5.53517 3.95706 6.3787 4.99604 6.3787Z" />
    <path fill="#0B0434" d="M8.42169 4.50679C8.42169 4.25991 8.22624 4.06445 7.97935 4.06445C7.73246 4.06445 7.53701 4.25991 7.53701 4.50679C7.53701 5.90582 6.39515 7.04768 4.99612 7.04768C3.59709 7.04768 2.45524 5.90582 2.45524 4.50679C2.45524 4.25991 2.25978 4.06445 2.0129 4.06445C1.76601 4.06445 1.57056 4.25991 1.57056 4.50679C1.57056 6.2453 2.877 7.68547 4.55378 7.9015V9.11537H3.96743C3.72054 9.11537 3.52508 9.31082 3.52508 9.55771C3.52508 9.80459 3.72054 10 3.96743 10H6.01454C6.26142 10 6.45688 9.80459 6.45688 9.55771C6.45688 9.31082 6.26142 9.11537 6.01454 9.11537H5.42818V7.89121C7.11524 7.67519 8.42169 6.2453 8.42169 4.50679Z" />
  </svg>

export const TagSVGIcon = () =>
  <svg className="tag-svg-icon svg-icon" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="fill" d="M14.3333 0H10.1047C9.30331 0 8.54931 0.312 7.98266 0.878656L0.568656 8.29334C0.207344 8.65466 0 9.15466 0 9.668C0 10.1787 0.207344 10.6787 0.568656 11.04L4.96066 15.432C5.32134 15.7927 5.82134 16 6.33466 16C6.84531 16 7.34531 15.7927 7.70666 15.4313L15.1213 8.01669C15.688 7.45066 16 6.69666 16 5.89534V1.66666C16 0.747344 15.2527 0 14.3333 0ZM12.3333 5.33334C11.414 5.33334 10.6667 4.586 10.6667 3.66669C10.6667 2.74734 11.414 2.00003 12.3333 2.00003C13.2527 2.00003 14 2.74734 14 3.66666C14 4.586 13.2527 5.33334 12.3333 5.33334Z" />
  </svg>

export const ForwardSVGIcon = () =>
  <svg className="forward-svg-icon svg-icon" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="fill" d="M10.6638 2.58482V0.818395C10.6638 0.331105 11.2463 0.0700524 11.6114 0.392012L17.6095 5.787C18.1224 6.24819 18.1311 7.05744 17.6269 7.52732L11.6201 13.0964C11.255 13.4357 10.6638 13.1747 10.6638 12.6787V10.4424C10.6638 9.76364 10.1075 9.21544 9.43812 9.21544C4.63091 9.19803 2.24034 10.5381 0.875541 11.7824C0.466972 12.1566 -0.167607 11.7302 0.041024 11.2168C1.95348 6.50924 5.87402 4.20332 9.4816 3.86396C10.1249 3.80304 10.6638 3.24614 10.6638 2.58482Z" />
  </svg>

export const CliprSVGIcon = () =>
  <svg className="clipr-svg-icon svg-icon" viewBox="0 0 46 16" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path className="fill" d="M2.93738 4.68571C3.48677 3.84163 4.21385 3.18367 5.12189 2.7102C6.02993 2.23673 7.03252 2 8.12966 2C8.91217 2 9.65719 2.13061 10.3631 2.39184C11.069 2.65306 11.695 3.0449 12.2378 3.56735L10.9174 5.42857C10.4935 5.09061 10.0403 4.82775 9.55611 4.63673C9.07194 4.44571 8.58124 4.35102 8.08076 4.35102C7.47268 4.35102 6.9184 4.4898 6.41792 4.76735C5.91744 5.0449 5.51314 5.42531 5.2034 5.9102C4.89366 6.3951 4.70618 6.94204 4.64097 7.55102H2C2.07662 6.4849 2.38799 5.5298 2.93738 4.68571ZM5.11374 13.298C4.21222 12.8294 3.48677 12.1763 2.93738 11.3388C2.38799 10.5012 2.07662 9.54776 2 8.48163H4.65727C4.72248 9.10204 4.91322 9.65224 5.22785 10.1306C5.54249 10.609 5.94842 10.9829 6.44238 11.249C6.93634 11.5151 7.48246 11.649 8.08076 11.649C8.65623 11.649 9.20888 11.5298 9.73544 11.2898C10.262 11.0498 10.7429 10.7396 11.1782 10.3592L12.4498 12.2531C11.8743 12.8196 11.2157 13.2522 10.4772 13.551C9.7387 13.8498 8.94478 14 8.09706 14C7.0097 14 6.01526 13.7665 5.11374 13.298Z" />
    <path className="fill" d="M13.803 13.7386V2.31006H16.4113V13.7386H13.803ZM17.3406 13.7386V11.5182H21.9052V13.7386H17.3406ZM16.1375 13.7386V11.5182H21.9052V13.7386H16.1375Z" />
    <path className="fill" d="M23.5845 13.7386V2.31006H26.1928V13.7386H23.5845Z" />
    <path className="fill" d="M30.9364 8.15496H32.0776C32.8601 8.15496 33.4388 7.97047 33.8138 7.59985C34.1887 7.22924 34.3762 6.77863 34.3762 6.24475C34.3762 5.7223 34.1887 5.27659 33.8138 4.90598C33.4388 4.53536 32.8601 4.35087 32.0776 4.35087H28.377V2.31006H32.3384C33.2839 2.31006 34.1121 2.48149 34.8245 2.82434C35.5369 3.1672 36.083 3.63741 36.4629 4.23659C36.8427 4.83577 37.0335 5.51006 37.0335 6.26108C37.0335 7.0121 36.8411 7.68638 36.4547 8.28557C36.0684 8.88475 35.5206 9.35169 34.8082 9.68965C34.0958 10.0276 33.2627 10.1958 32.3058 10.1958H30.9364V8.15496ZM28.377 13.7386V5.28149H30.9853V13.7386H28.377Z" />
    <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M40.8999 10.4502V11H40.8999V13.7199H38.3999V6.20888H40.8999V6.50004H40.8999V6.99079V10.4502ZM41.8249 6.54139V9.29773C42.5381 8.72952 43.3649 8.55045 43.8999 8.55045V6.2002C43.165 6.20018 42.4302 6.33242 41.8249 6.54139Z" />
  </svg>

export const MinusSVGIcon = () => {
  return (
    <svg className="minus-svg-icon svg-icon" viewBox="0 0 9 2" xmlns="http://www.w3.org/2000/svg">
      <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M0.5 1C0.5 0.447715 0.947715 0 1.5 0H7.5C8.05228 0 8.5 0.447715 8.5 1C8.5 1.55228 8.05228 2 7.5 2H1.5C0.947715 2 0.5 1.55228 0.5 1Z" />
    </svg>
  );
}

export const PlusIcon = () => {
  return (
    <svg className="plus-svg-icon svg-icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="fill" d="M8.0925 1.90744C8.0925 0.853988 8.94649 -5.62746e-07 9.99994 0C11.0534 -8.03923e-07 11.9074 0.853987 11.9074 1.90744V8.09256L18.0925 8.09256C19.146 8.09256 19.9999 8.94655 19.9999 10C19.9999 11.0534 19.146 11.9074 18.0925 11.9074L11.9074 11.9074L11.9074 18.0926C11.9074 19.146 11.0534 20 9.99994 20C8.94649 20 8.0925 19.146 8.0925 18.0926L8.0925 11.9074H1.90737C0.853927 11.9074 -6.11155e-05 11.0534 -6.10352e-05 10C-6.11155e-05 8.94655 0.853927 8.09256 1.90737 8.09256H8.0925L8.0925 1.90744Z" />
    </svg>
  )
}

export const PlusSVGIcon = () =>
  <svg className="plus-svg-icon svg-icon" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M8 0C7.44772 0 7 0.447715 7 1V7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H7V15C7 15.5523 7.44772 16 8 16C8.55229 16 9 15.5523 9 15V9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H9V1C9 0.447715 8.55229 0 8 0Z" />
  </svg>