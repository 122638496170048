import React, { ReactNode } from 'react';

export type LinkData = {
  href: string,
  text: string
};

type Props = {
  className?: string,
  href: string,
  children?: ReactNode
};

export const Link = (props: Props) => {
  const { className, href, children } = props;

  return (
    <a className={className}
      href={href}
      rel="noopener noreferrer"
      target="_blank">
      {children}
    </a>
  );
};