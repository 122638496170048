import assert from 'assert';
import { input, inputGroup, InputGroupState, InputState } from '../input';
import { notifyError, notifySuccess } from '../../services/notifications';
import { BindingProps, StoreNode } from '../../store';
import { SpeakerModel } from '../../entities';
import { Store } from '../../store/store';
import { action, computed, makeObservable, observable } from 'mobx';
import { SpeakerAddInput, SpeakerUpdateInput } from '@clipr/lib/dist/generated/graphql';
import { SpeakerFormMode } from './speakerFormBlockState';

type SpeakerInputGroupMode =
  'add' |
  'edit';

type Props = BindingProps<{
  disabled?: boolean | null;
  isRequired?: boolean | null;
  error?: string | null;
  selectorItems?: any[] | null;
  type: 'selector' | 'editor';
  onSubmit?: ((id: string) => void) | null;
  onSelect?: ((id: string | null) => void) | null;
  onAddSpeaker?: (() => void) | null;
  onCancel?: ((id: string) => void) | null;
  layout?: SpeakerFormMode | null;
}>

export class SpeakerInputGroupState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.selector = input(this, {
      name: 'selector',
      selectorItems: () => this.selectorItems,
      export: (self: InputState) => self.normValue,
      error: (input) => {
        if (this.error)
          return this.error;
        if (this.isRequired)
          return input.isEmpty && input.isTouched ? `Required field` : null;
        return null;
      },
      // @ts-ignore
      onChange: (input: InputState) => {
        if (props?.onSelect)
          props.onSelect(input.normValue)
      },
      disabled: (input: any) =>
        input.isSubmitting ||
        this.disabled
    });

    this.editor = input(this, {
      name: 'editor',
      isRequired: true,
      error: (input, fallback) => {
        if (this.error)
          return this.error;
        if (this.mode !== null)
          return input.isEmpty ? `Required field` : fallback;
        return fallback;
      },
      disabled: (input: any) =>
        input.isSubmitting ||
        this.disabled
    });

    this.editButton = input(this, {
      name: 'editButton',
      disabled: (input: any) =>
        input.isSubmitting ||
        this.disabled
    });

    this.addButton = input(this, {
      name: 'addButton',
      disabled: (input: any) =>
        input.isSubmitting ||
        this.disabled
    });

    this.cancelButton = input(this, {
      name: 'cancelButton',
      disabled: (input: any) =>
        input.isSubmitting ||
        this.disabled
    });

    this.inputGroup = inputGroup(this, {
      name: 'speaker',
      showStatus: true,
      inputs: () => {
        if (!this.mode)
          return [this.selector, this.addButton, this.editButton]
        return [this.editor, this.cancelButton];
      },
      error: () => {
        if (!this.mode)
          return this.selector.error;
        else
          return this.editor.error;
      },
      isSubmitDisabled: (self) => {
        if (this.mode !== null)
          return self.hasErrorInputs;
        return false;
      },
      export: () => {
        assert(!this.mode,
          `Cannot export form data from SpeakerInputModel because it is in add/edit mode.`);

        const speakerId = this.selector.normValue;
        if (speakerId === 'multipleValues')
          return speakerId;

        if (speakerId) {
          const speaker = this.store.speakerManager.getSpeaker(speakerId);
          assert(speaker,
            `Speaker ${speakerId} does not exist in the speaker list.`);

          return speaker.id;
        }
      }
    })

    this.completeInputGroup = inputGroup(this, {
      name: 'allInputs',
      showStatus: true,
      inputs: () => [
        this.editor,
        this.selector,
        this.addButton,
        this.editButton,
        this.cancelButton]
    })
  }

  @observable editSpeaker: SpeakerModel | null = null;
  @observable mode: SpeakerInputGroupMode | null = null;

  readonly selector: InputState;
  readonly editor: InputState;
  readonly inputGroup: InputGroupState;
  readonly completeInputGroup: InputGroupState;

  readonly addButton: InputState;
  readonly editButton: InputState;
  readonly cancelButton: InputState;

  @computed get disabled(): boolean {
    return this.resolvedProps.disabled ?? false;
  }
  
  @computed get layout(): SpeakerFormMode {
    return this.resolvedProps.layout ?? SpeakerFormMode.Edit;
  }

  @computed get error(): string | null {
    return this.resolvedProps.error;
  }

  @computed get selectorItems(): any[] {
    return this.resolvedProps.selectorItems ?? []
  }

  @computed get isRequired(): boolean {
    return this.resolvedProps.isRequired ?? false;
  }

  @computed get speakerModelId(): string | null {
    if (this.mode === 'add')
      return null;

    return this.selector.normValue
  }

  @computed get speakerModel(): SpeakerModel | null {
    if (this.mode === 'add')
      return null;

    return this.store.maybeGetSpeaker(this.speakerModelId);
  }

  @computed get name(): string | null {
    if (this.mode)
      return this.editor.value;

    return this.selector.displayValue;
  }

  @action
  openEditSpeakerMode = () => {
    this.mode = 'edit';
    this.editSpeaker = this.store.speakerManager.maybeGetSpeaker(this.speakerModelId);
    assert(this.editSpeaker,
      `Speaker with id ${this.speakerModelId} could not be opened for editing.`);

    this.editor.loadValue(this.editSpeaker.name);
  }

  @action
  openAddSpeakerMode = () => {
    this.mode = 'add';
    this.props.onAddSpeaker?.();
  }

  @action
  cancelMode() {
    this.inputGroup.clear();
    this.mode = null;
    this.props.onCancel?.();
  }

  @action
  clear() {
    this.completeInputGroup.clear();
    this.mode = null;
  }

  async submit() {
    const { editor } = this;
    const speakerName = editor.value;

    assert(this.mode,
      `Cannot submit SpeakerInputModel form if no mode has been set.`);

    this.inputGroup.handleSubmit();
    if (this.inputGroup.error) {
      this.inputGroup.handleSubmitReject();
      notifyError(this, 'Fix the validation errors before saving.');
      return;
    }

    switch (this.mode) {

      case 'add':
        try {
          const addArgs: SpeakerAddInput = {
            name: speakerName!
          }
          
          const [res] = await this.store.speakerManager.apiAddSpeaker({
            args: addArgs
          });

          this.inputGroup.handleSubmitResolve();
          notifySuccess(this, `Added speaker ${speakerName}.`);

          this.cancelMode();
          this.selector.loadValue({
            value: res?.id,
            label: res?.name
          });

          this.props.onSubmit(res?.id);
        } catch (e) {

          console.error('Error while adding a new speaker: ', e);

          this.inputGroup.handleSubmitReject();
          notifyError(this, `Failed to add speaker ${speakerName}.`);
        }
        break;

      case 'edit':
        assert(this.editSpeaker,
          `Cannot submit SpeakerInputModel in edit mode if no SpeakerModel has been loaded.`);
        const oldName = this.editSpeaker.name;

        try {
          const updateArgs: SpeakerUpdateInput = {
            id: this.editSpeaker.id,
            name: speakerName!
          }
          const [res] = await this.store.speakerManager.apiUpdateSpeaker({
            args: updateArgs
          });

          this.inputGroup.handleSubmitResolve();
          notifySuccess(this, `Speaker ${oldName} was renamed to ${speakerName}.`);
          this.cancelMode();
          this.selector.loadValue({
            value: res?.id,
            label: res?.name
          });

        } catch (e) {
          this.inputGroup.handleSubmitReject();
          notifyError(this, `Failed to rename speaker ${oldName} to ${speakerName}.`);
        }
        break;

      default:
        break;
    }

  }
}