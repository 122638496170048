import React, { PropsWithChildren, Suspense } from 'react';
import { observer } from 'mobx-react-lite';
import { LoadPage } from '../components';

type Props = PropsWithChildren<{}>;

export const Lazy = observer((props: Props) => {

  return (
    <Suspense fallback={<LoadPage />}>
      {props.children}
    </Suspense>
  );
});