import React from 'react';
import { observer } from 'mobx-react-lite';
import { TeamSettingsPageState } from '../../pages/teamSettingsPage/teamSettingsPageState';
import { TeamMembersHeader } from './teamMembersHeader';
import { TeamMembersTable } from './teamMembersTable';
import { Button } from '../input';
import { TeamInvitesTable } from './teamInvitesTable';
import { TeamInvitesHeader } from './teamInvitesHeader';
import { TeamSearchComponent } from './teamSearchComponent';

type Props = {
  model: TeamSettingsPageState;
};

export const TeamMembers = observer(({ model }: Props) => {
  return (
    <div className="block input-group">
      <div className="flex-container">
        <div className="flex-space-between flex-center members-header">
          <div className="tab-navigation">
            <Button
              className={`tab ${model.activeTab === 'Active' ? ' active' : ''}`}
              label="Members"
              onClick={() => (model.changeActiveTab('Active'))}
            />
            <Button
              className={`tab ${model.activeTab === 'Pending' ? ' active' : ''}`}
              label="Invitations"
              onClick={() => (model.changeActiveTab('Pending'))}
            />
          </div>

          <div className="right-side">
            <TeamSearchComponent model={model} />

            <Button
              className="secondary large"
              label="Invite"
              onClick={() => model.openTeamInviteWindow()}
            />
          </div>
        </div>

        {model.activeTab === 'Active' &&
          (
            <>
              <TeamMembersHeader model={model} />
              <TeamMembersTable model={model} />
            </>)}


        {model.activeTab === 'Pending' &&
          (
            <>
              <TeamInvitesHeader model={model} />
              <TeamInvitesTable model={model} />
            </>)}
      </div>
    </div>
  );
});
