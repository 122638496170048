
import clamp from 'lodash/clamp';
import { Duration } from 'luxon';
import { action, computed, makeObservable } from 'mobx';
import { isFiniteNumber } from '../../../core';
import { JobModel } from '../../../entities';
import { BindingProps, StoreNode } from '../../../store';
import { Store } from '../../../store/store';
import { PlayerAdsAdapterState } from '../../playerAdsAdapter';
import { PlayerAdsAdapterInvokeType } from '../../playerAdsAdapter/playerAdsAdapterSchema';
import { PlayerState } from '../playerState';

type Props = BindingProps<{
  player: PlayerState
}>

export enum AdsBarCardLayout {
  /** Display during a pre-roll or mid-roll break, for a non-skippable ad which is not the last in the break. */
  StartingAdWatchCountdown = 'LeadAdWatchCountdown',
  /** Display during a pre-roll or mid-roll break, for a non-skippable ad which is the last in the break. */
  LastAdWatchCountdown = 'LastAdWatchCountdown',
  /** Display during a post-roll break, for a non-skippable ad which is not the last in the break. */
  StartingAdEndCountdown = 'LeadAdEndCountdown',
  /** Display during a post-roll break, for a non-skippable ad which is the last in the break. */
  LastAdEndCountdown = 'LastAdEndCountdown',

}

export class AdsBarState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  @computed
  get player(): PlayerState {
    return this.resolvedProps.player;
  }

  @computed
  get job(): JobModel | null {
    return this.player.job ?? null;
  }

  @computed
  get adsAdapter(): PlayerAdsAdapterState {
    return this.player.adsAdapter;
  }

  @computed
  get isSkippable(): boolean {
    return this.adsAdapter.isSkippable;
  }

  @computed
  get canSkip(): boolean {
    return this.adsAdapter.canSkip;
  }

  @computed
  get canSkipRemainingTime() {
    return Math.ceil(clamp(
      (this.adsAdapter.canSkipAfter ?? 0) -
      (this.adsAdapter.currentTime ?? 0), 0, Number.POSITIVE_INFINITY));
  }

  @computed
  get canSkipRemainingTimeLabel(): string | null {
    return Math.ceil(this.canSkipRemainingTime).toString();
  }

  @computed
  get adRemainingTime(): number | null {
    const time = this.adsAdapter.remainingTime;
    if (!isFiniteNumber(time) || time < 0)
      return null;
    return Math.ceil(time);
  }

  @computed
  get adRemainingTimeLabel(): string | null {
    return this.adRemainingTime?.toString() ?? null;
  }

  /**
   * Returns true if the current ad is not the last in a multi-ad break.
   * If the ad break contains a single ad then this will return false.
   */
  @computed
  get isLeadingAd(): boolean {
    const { adsAdapter } = this;
    const { adIndex, adPodSize } = adsAdapter;

    return (
      isFiniteNumber(adIndex) &&
      isFiniteNumber(adPodSize) &&
      adPodSize > 1 &&
      adIndex < adPodSize - 1);
  }

  @computed
  get isLastOrOnlyAd(): boolean {
    const { adsAdapter } = this;
    return (
      isFiniteNumber(adsAdapter.adIndex) &&
      isFiniteNumber(adsAdapter.adPodSize) &&
      adsAdapter.adIndex >= adsAdapter.adPodSize - 1);
  }

  @computed
  get isSingleAdPod() {
    const { adsAdapter } = this;
    return adsAdapter.adPodSize === 1;
  }

  @computed
  get isMultiAdPod() {
    const { adsAdapter } = this;
    return adsAdapter.adPodSize! > 1;
  }

  @computed
  get infoLabel(): string | null {
    const { adIndex, adPodSize } = this.adsAdapter;
    if (
      !isFiniteNumber(adIndex) ||
      !isFiniteNumber(adPodSize))
      return null;

    if (this.isSingleAdPod)
      return `Ad`;
    if (this.isMultiAdPod)
      return `Ad ${adIndex + 1} of ${adPodSize}`;
    return `Ad`;
  }

  @computed
  get infoRemainingTimeLabel(): string | null {
    const time = this.adRemainingTime;
    if (!isFiniteNumber(time) || time < 0)
      return null;

    const dur = Duration.fromObject({ seconds: Math.ceil(time) });
    return dur.toFormat('m:ss'); // hopefully we won't have ads longer than an hour
  }

  @action
  async mounted() {

  }

  @action
  unmounted() {

  }

  @action
  handleSkipButtonClick = () => {
    this.adsAdapter.invoke(PlayerAdsAdapterInvokeType.Skip);
  }
}