import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../overlays';
import { AddJobToTeamWindowState } from './addJobToTeamWindowState';
import { CheckboxInput } from '../input';
import { TeamAvatar } from '../teams';

type Props = {
  model: AddJobToTeamWindowState;
  visible?: boolean;
};

export const AddJobToTeamWindow = observer((props: Props) => {
  const { model } = props;

  return (
    <Window
      model={model.window}
      visible={props.visible}
      className="add-job-to-team-window"
      isLoading={model.isLoading}>
      <WindowHeader model={model.window} title="Add to library" />

      <hr />
      <div className="window-content">
        <div className="scrollable">
          <ul className={'team-list' + (model.isLoading ? ' loading' : '')}>
            {model.teams && model.teams.length > 0 ? (
              model.teams.map((team) => {
                return (
                  <li key={team.id} className="list-item">
                    <TeamAvatar
                      className="icon"
                      model={team}
                      onClick={() => !team.owner && model.submit(team.id)}
                    />
                    <div
                      className="team-name caption-1"
                      onClick={() => !team.owner && model.submit(team.id)}>
                      {team.name}
                      {team.owner && (
                        <small>
                          <i>&nbsp;&nbsp;(Owner)</i>
                        </small>
                      )}
                    </div>
                    <div className="icon">
                      <CheckboxInput
                        checked={team.checked}
                        name={team.id}
                        disabled={team.owner || model.isSubmitting}
                        onChange={() => model.submit(team.id)}
                      />
                    </div>
                  </li>
                );
              })
            ) : (
              <p>No available libraries found.</p>
            )}
          </ul>
        </div>
      </div>
    </Window>
  );
});
