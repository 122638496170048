import './input.scss';
import assert from 'assert';
import { paramCase } from 'change-case';
import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Duration } from 'luxon';
import toInteger from 'lodash/toInteger';
import classNames from 'classnames';
import { InputState } from './inputState';
import { HTMLInputProps } from '../../core/reactSchema';
import { InputProps } from './inputSchema';
// import { ObjectLiteral } from '../../core';

type Props =
  Omit<HTMLInputProps, 'value' | 'placeholder' | 'onChange'> &
  InputProps<number> & {
    multiline?: boolean,
    rows?: number,
    onChange?: (evt: React.SyntheticEvent, value: number) => void
  };

export const TimepointInput = observer(({
  id,
  multiline,
  value,
  className,
  label,
  disabled,
  isFocused,
  isHovered,
  isRequired,
  showStatus,
  status,
  statusMessage: feedback,
  showLabel = true,
  persistentLabel = false,
  labelPosition = 'topStart',
  showFeedback = true,
  persistentFeedback = false,
  feedbackPosition = 'bottom',
  placeholder,
  readOnly = false,
  min = 0,
  max = 359999,
  children,
  onChange,
  ...props
}: Props) => {

  className = classNames('timepoint-input input', className, {
    'multiline': multiline,
    'focus': isFocused,
    'hover': isHovered
  });

  let inputElem;
  const inputElemClassNames = classNames('native-input native-number-input',
    { 'read-only': readOnly });

  const duration = Duration.fromObject({ seconds: value || 0 }).shiftTo('hours', 'minutes', 'seconds');
  const hours = Duration.fromObject({ hours: duration.hours }).toFormat('hh');
  const minutes = Duration.fromObject({ minutes: duration.minutes }).toFormat('mm');
  const seconds = Duration.fromObject({ seconds: duration.seconds }).toFormat('ss');

  const handleChange = (evt: any, unit: string) => {
    if (!onChange) return;
    if ((unit === 'm' || unit === 's') && evt.target.value > 59) return;

    const hours = unit === 'h' ? evt.target.value : duration.hours;
    const minutes = unit === 'm' ? evt.target.value : duration.minutes;
    const seconds = unit === 's' ? evt.target.value : duration.seconds;

    const value = Duration.fromObject({ hours: hours || 0, minutes: minutes || 0, seconds: seconds || 0 }).shiftTo('seconds').seconds;
    if (value < min || value > max)
      return;
    onChange(evt, value);
  }

  inputElem = (
    <div className="timepoint-input-group">
      <input className={inputElemClassNames} role="presentation" type="number" placeholder="hh" min={0} max={99} disabled={disabled} readOnly={readOnly} value={hours} onChange={evt => handleChange(evt, 'h')} {...props}/>
      <span className="separator">:</span>
      <input className={inputElemClassNames} role="presentation" type="number" placeholder="mm" min={0} max={59} disabled={disabled} readOnly={readOnly} value={minutes} onChange={evt => handleChange(evt, 'm')} {...props}/>
      <span className="separator">:</span>
      <input className={inputElemClassNames} role="presentation" type="number" placeholder="ss" min={0} max={59} disabled={disabled} readOnly={readOnly} value={seconds} onChange={evt => handleChange(evt, 's')} {...props}/>
    </div>
  );

  let labelElem = (persistentLabel || (showLabel && label)) &&
    <label className={['input-label', paramCase(labelPosition!)].join(' ')}
      id={id && id + '_label'}
      htmlFor={id}>
      {showLabel && label}
    </label>

  let feedbackElem = (persistentFeedback || (showFeedback && feedback)) &&
    <div className={['feedback input-feedback', paramCase(feedbackPosition!)].join(' ')}
      id={id && id + '_feedback'}
      data-status={status}
      aria-live="polite">
      {showFeedback && feedback}
    </div>

  return (
    <div className={className}
      role="textbox"
      aria-disabled={disabled}>

      <div className="timepoint-input-box input-box"></div>

      {inputElem}
      {labelElem}
      {feedbackElem}

      {children}
    </div>
  );
});

type ControlledProps =
  Props & {
    model: InputState,
  };

/**
 * Wrapper for TextInput in which the entire state and all the handlers are provided through an InputState object.
 */
export const TimepointInputControlled = observer(({
  model,
  className,
  children,
  ...props
}: ControlledProps) => {

  assert(model instanceof InputState,
    `The 'model' passed to TextInputControlled must be an instance of InputState. Instead received ${model}.`);

  const handleChange = useCallback((evt, value) => {
    model.handleChange(evt, value);
  }, [model]);

  className = model.getClassName('text-input', { className });
  const value = toInteger(model.value);

  return <TimepointInput
    className={className}
    value={value || 0}
    placeholder={model.placeholder || undefined}
    disabled={model.disabled}
    label={model.label!}
    multiline={model.multiline}
    isHovered={model.isHovered}
    isFocused={model.isFocused}
    isRequired={model.isRequired}
    onChange={handleChange}
    onFocus={model.handleFocus}
    onBlur={model.handleBlur}
    onPointerEnter={model.handlePointerEnter}
    onPointerLeave={model.handlePointerLeave}
    status={model.status}
    statusMessage={model.statusMessage}
    showStatus={model.showStatus}
    showFeedback={model.showStatusMessage}
    labelPosition={model.labelPosition || undefined}
    feedbackPosition={model.feedbackPosition || undefined}
    {...props}>

    {children}
  </TimepointInput>
});