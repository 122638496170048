const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const formatDate = (str: string) => {
  const d = new Date(str);
  const day = d.getDate();
  const month = months[d.getMonth()];
  let hour = d.getHours();
  const meridiam = hour >= 12 ? 'pm' : 'am';
  hour = hour ? hour % 12 : 12;

  return `${month} ${day}, ${hour || '12'}${meridiam}`;
};

export const formatToHms = (seconds: number) => {
  const d = new Date(0);
  const s = Math.floor(seconds);
  d.setSeconds(s);
  return d.toISOString().substr(11, 8);
};

export const formatToSeconds = (h: number | string, m: number | string, s: number | string) =>
  Number(h) * 60 * 60 + Number(m) * 60 + Number(s);

export const toKebabCase = (str: string) =>
  str.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase());

export const propsToCss = (props: any) => {
  let css = '';
  Object.keys(props)
    .filter(
      (key) => typeof props[key] === 'string' || typeof props[key] === 'number'
    )
    .map((key) => ({ key, cased: toKebabCase(key) }))
    .forEach(({ key, cased }) => (css += `${cased}: ${props[key]};`));

  return css;
};

export const isEmptyValue = (val: any) =>
  (typeof val === 'string' && val.trim() === '') ||
  val === null ||
  val === undefined ||
  (typeof val === 'boolean' && val === false);

export default formatDate;
