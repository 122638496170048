import { action, computed, makeObservable, observable } from 'mobx';

import { StoreNode, Message } from '../../store';
import { JobCatalogSource, JobModel, Team } from '../../entities';
import { CatalogState } from '../../components/layout/catalogState';
import { Store } from '../../store/store';
import { JobSourceType } from '@clipr/lib';
import { ScheduleStreamWindowState } from '../../components/jobs/scheduleStreamWindowState';
import { StreamAssetsWindowState } from '../../components/jobs/streamAssetsWindowState';
import { getApiTeamError } from '../../api';
import { pageError, Error } from '../../core/error';
import { LiveFeedWindowState } from '../../components/jobs/liveFeedWindowState';
type Props = {

}

export type LiveStreamQueuePageParams = {
  teamId: string;
}

export class LiveStreamQueuePageState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.jobCatalogSource = new JobCatalogSource(this.store, {
      teamId: () => this.teamId,
      jobFilter: (job: JobModel) => job.isLive && job.ownerTeamId === this.teamId,
      pageSize: 20
    });

    this.jobCatalog = new CatalogState(this.store, {
      syncStatus: () => this.jobCatalogSource.syncStatus,
      isEndOfList: () => this.jobCatalogSource.isEndOfList
    });

    this.jobCatalog.listen((msg) => {
      switch (msg.type) {
        case 'scrollToBottom':
        case 'loadMore':
          this.jobCatalogSource.fetchMore();
          break;
      }
    });
  }

  @observable isLoading: boolean = true;
  @observable error: Error | null = null;
  @observable teamId: string | null = null;

  readonly jobCatalogSource: JobCatalogSource;
  readonly jobCatalog: CatalogState;

  @computed get teamManager() {
    return this.store.teamManager;
  }

  @computed
  get isWidgetMode(): boolean {
    return this.store.widget.isWidgetMode;
  }

  @computed
  get team(): Team | null {
    return this.teamManager.getTeam(this.teamId);
  }

  @action
  async mounted(params: LiveStreamQueuePageParams, queryString: string) {
    const { assetsWindow, scheduleStreamWindow, liveFeedWindow } = this.store;

    assetsWindow.listen(
      this.streamAssetsWindowListener);
    scheduleStreamWindow.listen(
      this.scheduleStreamWindowListener);
    liveFeedWindow.listen(
      this.liveFeedWindowListener);

    this.isLoading = true;

    const { teamId } = params;
    this.teamId = teamId;

    if (!this.team) {
      const [, teamErr] = await this.store.teamManager.apiFetchTeam({ id: teamId });
      if (teamErr) {
        const decodedError = getApiTeamError(teamErr);
        return this.setError(decodedError);
      }
    }

    this.jobCatalogSource.setFilterFieldValue('sourceType', JobSourceType.Live, false);
    this.jobCatalogSource.setSortFieldValue('scheduled', 'asc', false);

    const [, jobsErr] = await this.jobCatalogSource.fetch();
    if (jobsErr)
      return this.setError(jobsErr);

    this.setLoaded();
  }

  @action
  unmounted() {
    this.reset();
  }

  private scheduleStreamWindowListener = action((msg: Message<ScheduleStreamWindowState>) => {
    switch (msg.type) {
      case 'jobUpdated':
        this.refreshJobs();
        break;
    }
  });

  private liveFeedWindowListener = action((msg: Message<LiveFeedWindowState>) => {
    switch (msg.type) {
      case 'jobUpdated':
        this.refreshJobs();
        break;
    }
  });

  private streamAssetsWindowListener = action((msg: Message<StreamAssetsWindowState>) => {
    switch (msg.type) {
      case 'jobUpdated':
        this.refreshJobs();
        break;
    }
  });

  @action
  async refreshJobs() {
    this.isLoading = true;
    await this.jobCatalogSource.fetch();
    this.isLoading = false;
    return;
  }

  @action
  setError(error?: Error) {
    if (!error)
      error = pageError();

    this.isLoading = false;
    this.error = error;
  }

  @action
  setLoaded() {
    this.isLoading = false;
    this.error = null;
  }

  @action
  reset() {
    this.isLoading = false;
    this.error = null;
    this.jobCatalogSource.reset();

    const { assetsWindow, scheduleStreamWindow, liveFeedWindow } = this.store;

    assetsWindow.unlisten(
      this.streamAssetsWindowListener)
    scheduleStreamWindow.unlisten(
      this.scheduleStreamWindowListener);
    liveFeedWindow.unlisten(
      this.liveFeedWindowListener);
  }
}