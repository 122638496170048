import './media.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Catalog, Grid } from '../layout';
import { CatalogState } from '../layout/catalogState';
import { CatalogFooter } from '../layout/catalogFooter';
import { GoogleDriveSVGIcon, SearchSVGIcon } from '../svg';
import { LibraryCatalogSource } from '../../entities';
import { LibraryCard } from './libraryCard';

type Props = {
  model: CatalogState;
  source: LibraryCatalogSource;
};

export const GoogleDriveCatalog = observer(({ model, source }: Props) => {
  const { entities } = source;

  return (
    <Catalog className="job-card-catalog catalog" model={model}>
      {entities.length > 0 && (
        <>
          <Grid className="catalog-grid">
            {entities.map((externalFile) => {
              return (
                <li key={externalFile.id} className="grid-item catalog-item">
                  <LibraryCard
                    model={externalFile}
                    source={source}
                    view={'gdrive'}
                  />
                </li>
              );
            })}
          </Grid>

          <CatalogFooter model={model} />
        </>
      )}

      {entities.length === 0 &&
        (!!source.searchFilter ? (
          <div className="empty-box" aria-hidden={model.isLoading}>
            <div className="catalog empty-catalog">
              <div className="icon">
                <SearchSVGIcon />
              </div>

              <h1 className="headline-1">
                No results found for "{source.searchFilter}".
              </h1>
              <p>
                Make sure all words are spelled correctly or try different
                keywords.
              </p>
            </div>
          </div>
        ) : (
          <div className="empty-box" aria-hidden={model.isLoading}>
            <div className="catalog empty-catalog">
              <div className="icon">
                <GoogleDriveSVGIcon />
              </div>
              <h1 className="headline-1">
                Looks like you haven’t uploaded any videos.
              </h1>
            </div>
          </div>
        ))}
    </Catalog>
  );
});
