import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../../components/overlays';
import { TrainerKeyboardShortcutsWindowState } from './trainerKeyboardShortcutsWindowState';
import { hotkeys } from './trainerHotkeys';
import { HotkeysConfigItem } from '../../components/hotkeys';

type Props = {
  model: TrainerKeyboardShortcutsWindowState;
  visible?: boolean;
};

export const TrainerKeyboardShortcutsWindow = observer(
  ({ model, visible }: Props) => {
    return (
      <Window
        model={model.window}
        visible={visible}
        className="shortcuts-window"
        isLoading={model.isLoading}>
        <WindowHeader title="Trainer Shortcuts" model={model.window} />

        <hr />

        <section className="window-content">
          <div className="hotkeys-table">
            {Object.values(hotkeys).map(
              (hotkey: HotkeysConfigItem, index: number) =>
                hotkey.description && (
                  <div key={index} className="row">
                    <div className="description">{hotkey.description}</div>
                    <div className="shortcut">{hotkey.keynames.join(', ')}</div>
                  </div>
                )
            )}
            <div className="row">
              <div className="description">Start mass selection</div>
              <div className="shortcut">shift+click</div>
            </div>
          </div>
        </section>
      </Window>
    );
  }
);
