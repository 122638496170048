import { observable, ObservableMap } from 'mobx';

export type MaybeObservableMap<K, V> =
  Map<K, V> |
  ObservableMap<K, V>;

/**
 * Utility for instantiating either a native Map or an observable map, depending on preference.
 */
export function createMap<K, V>(obs = false) {
  return obs ? observable.map<K, V>() : new Map<K, V>();
}

export interface IMap<TKey, TVal> {

  // new(): IMap<TKey, TVal>;
  // new <TKey, TVal>(entries?: readonly (readonly [TKey, TVal])[] | null): IMap<TKey, TVal>;

  size: number;
  [Symbol.iterator](): IterableIterator<[TKey, TVal]>;

  get(key: TKey): TVal | undefined;
  set(key: TKey, val: TVal): IMap<TKey, TVal>;
  has(key: TKey): boolean;
  delete(key: TKey): boolean;

  values(): IterableIterator<TVal>;
  keys(): IterableIterator<TKey>;
  entries(): IterableIterator<[TKey, TVal]>;

  clear(): void;
  forEach(func: (val: TVal, key: TKey, map: IMap<TKey, TVal>) => void, thisArg?: any): void;
}


/**
 * Tries to retrieve a value from a map, and sets it to a default value if it does not exist.
 * NOTE:  This checks the existence of the value by strict comparison to undefined, in order to avoid
 *        an (almost) unnecessary call to Map.has before calling Map.get.
 *        This means that if you have a key set to `undefined` in your map, it will be overwritten.
 *        If you want the safe version (but in theory a little bit slower) use `mapGetOrSetSafe`.
 */
export function mapGetOrSet<TKey, TVal>(map: Map<TKey, TVal>, key: TKey, defVal: TVal): TVal {
  let val = map.get(key);
  if (val === undefined) {
    map.set(key, defVal);
    return defVal;
  }

  return val;
}

/**
 * Same as `mapGetOrSet`, but safely checks for the existence of the key before overwriting it.
 * This can return `undefined` if the (key,value) pair has been set that way.
 */
export function mapGetOrSetSafe<TKey, TVal>(map: Map<TKey, TVal>, key: TKey, defVal: TVal): TVal | undefined {
  if (map.has(key))
    return map.get(key);

  map.set(key, defVal);
  return defVal;
}

export type MapSetFunc<TKey, TVal> = (currVal: TVal | undefined, key: TKey, map: Map<TKey, TVal>) => TVal;

/**
 * Shortcut to set the value in a Map using a callback function based on the existing value, if any.
 * Useful in scenarios like counting algorithms, in which keys must be initialized with 0 and then incremented.
 */
export function mapSetWithFunc<TKey, TVal>(map: Map<TKey, TVal>, key: TKey, val: MapSetFunc<TKey, TVal>, defVal?: TVal) {
  let currVal = map.get(key);
  if (currVal === undefined)
    currVal = defVal;

  const nextVal = val(currVal, key, map);
  map.set(key, nextVal);

  return nextVal;
}

/**
 * Increments the value at a particular key with the specified delta if the key exists.
 * Otherwise it is initialized with the value set to delta.
 * @returns The new incremented value at the specified key.
 */
export function mapIncrement<TKey>(map: Map<TKey, number>, key: TKey, delta = 1): number {
  return mapSetWithFunc(map, key, (currVal) => (currVal ?? 0) + delta, 0);
}