import { action, computed, makeObservable, observable } from 'mobx';
import { JobType, UpdateJobInput } from '@clipr/lib';
import { input, InputState } from '../../components/input';
import { TrainerTimelineState } from '../../components/trainerTimeline';
import { TrainerTranscriptsState } from '../../components/trainerTranscripts';
import { assertNotNull, Result } from '../../core';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Store } from '../../store/store';
import { Message, StoreNode } from '../../store';
import { JobModel, JobVideoTypeList, ValidatorResponse } from '../../entities';
import { Error, pageError } from '../../core/error';
import { VideoInformationWindowState } from '../../components/jobs/videoInformationWindowState';
import { JobErrorsWindowState } from '../../components/momentWindow/jobErrorsWindowState';
import { ConfirmationModalState } from './confirmationModalState';
import { AnalyticsEventTypes } from '../../services/analytics/analyticsSchema';
import { TrainerHotkeysController } from './trainerHotkeysController';
import { getApiResultJobError } from '../../api';
import { PlayerState } from '../../components/player';

const VideoPrivacyItems = [
  'Shareable',
  'Private'
];

const VideoStatusItems = [
  'Waiting',
  'InReview',
  'Updated',
  'Done'
];

export type TrainerVideoPageParams = {
  jobId: string
}

export class TrainerVideoPageState
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);

    this.player = new PlayerState(store, {
      jobId: () => this.jobId,
      allowShare: false,
      showReactionButton: false,
      showAddMomentButton: true,
      momentSource: 'Trainer',
      openMomentWindowOptions: {
        layout: 'Trainer'
      }
    });

    this.timeline = new TrainerTimelineState(store, {
      jobId: () => this.jobId,
      player: () => this.player
    });

    this.transcripts = new TrainerTranscriptsState(store, {
      jobId: () => this.jobId,
      player: () => this.player
    });

    this.videoPrivacyInput = input(this, {
      name: 'videoPrivacy',
      selectorItems: VideoPrivacyItems,
      onChange: this.handleVideoPrivacyInputChange
    });
    this.videoStatusInput = input(this, {
      name: 'videoStatus',
      selectorItems: VideoStatusItems,
      onChange: this.handleVideoStatusInputChange
    });
    this.videoTypeInput = input(this, {
      name: 'videoType',
      selectorItems: JobVideoTypeList,
      onChange: this.handleVideoTypeInputChange
    });

    this.hotkeys = new TrainerHotkeysController(this.store, this);
  }

  @observable isLoading = false;
  @observable error: Error | null = null;

  @observable jobId: string | null = null;
  @computed get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed get isEnrichmentProcessing(): boolean {
    return this.player.isEnrichmentProcessing;
  }

  @computed get isTranscriptProcessing(): boolean {
    return this.player.isTranscriptProcessing;
  }

  @computed get isJobFailed(): boolean {
    return this.job?.isFailed ?? false;
  }

  @computed get isJobInProgress(): boolean {
    return this.job?.isInProgress ?? false;
  }

  readonly player: PlayerState;
  readonly transcripts: TrainerTranscriptsState;
  readonly timeline: TrainerTimelineState;

  readonly videoPrivacyInput: InputState;
  readonly videoStatusInput: InputState;
  readonly videoTypeInput: InputState;

  readonly hotkeys: TrainerHotkeysController;

  /** Notifies the state that the page has been mounted and triggers the initialization tasks. */
  @action
  async load(params: TrainerVideoPageParams, queryString?: string) {

    this.reset();
    this.isLoading = true;

    // TODO: I added this but I also commented it because there are a few minor issues
    // with some texts on the windows (which would be dark)
    // also I wanted to minimize the impact of the changes on the trainers, so we can enable this line
    // in the next iteration, after we fix the issues

    // this.store.uiService.setThemeVariation('Inverse');
    
    const { jobId } = params;
    // const query = new URLSearchParams(queryString);

    const { store } = this;
    if (!jobId)
      return this.setError();

    this.jobId = jobId;

    const response: Result[] = await Promise.all([
      store.apiFetchJob(jobId, true),
      store.apiFetchSpeakers({ jobId })
    ]);

    const err = getApiResultJobError(response);
    if (err)
      return this.setError(err);

    // check if this is the response that came after the last request, otherwise ignore it
    if (jobId !== this.jobId) {
      console.warn(`Job ${jobId} has been discarded.`);
      return false;
    }

    if (!this.job)
      return this.setError();

    this.videoPrivacyInput.loadValue(this.job?.isPublic ? 'Shareable' : 'Private');
    this.videoStatusInput.loadValue(this.job?.status);
    this.videoTypeInput.loadValue(this.job?.videoType);

    this.player.chrome.pin();

    this.setLoaded();
  }

  @action
  invoke = (type: string, payload?: any) => {

    switch (type) {
      case 'openVideoInformationWindow':
        if (!this.jobId) return;
        this.player.invoke('clearMomentStub');
        const videoInformationWindow = this.store.videoInformationWindow;
        const videoInformationWindowListener = (msg: Message<VideoInformationWindowState>) => {
          switch (msg.type) {
            case 'open':
              this.player.invoke('enterPause');
              break;

            case 'close':
              videoInformationWindow.unlisten(videoInformationWindowListener);
              this.player.invoke('exitPause');
              this.player.invoke('clearMomentStub');
              this.timeline.clearMultipleEdit(); // until handled by id
              this.videoStatusInput.value = this.job?.status || null;
              this.videoTypeInput.value = this.job?.videoType || null;
              break;

            case 'jobUpdated':
              videoInformationWindow.unlisten(videoInformationWindowListener);
              this.player.invoke('exitPause');
              this.player.invoke('clearMomentStub');
              this.timeline.clearMultipleEdit(); // until handled by id
              this.videoStatusInput.value = this.job?.status || null;
              this.videoTypeInput.value = this.job?.videoType || null;
              break;
          }
        }
        videoInformationWindow.listen(videoInformationWindowListener);
        videoInformationWindow.open({
          jobId: this.jobId
        });
        break;
      case 'openJobErrorsWindow':
        const { jobId } = this;
        const { clipList, multipleActiveTrackTypes } = payload;
        if (!jobId)
          return;
        this.player.invoke('clearMomentStub');
        const jobErrorsWindow = this.store.jobErrorsWindow;
        const jobErrorsWindowListener = (msg: Message<JobErrorsWindowState>) => {
          switch (msg.type) {
            case 'open':
              this.player.invoke('enterPause');
              break;

            case 'close':
              jobErrorsWindow.unlisten(jobErrorsWindowListener);
              this.player.invoke('exitPause');
              this.player.invoke('clearMomentStub');
              break;
          }
        }
        jobErrorsWindow.listen(jobErrorsWindowListener);
        jobErrorsWindow.open({
          jobId,
          clipList,
          multipleActiveTrackTypes,
        });
        break;
      case 'openJobPrivacyConfirmationWindow':
        if (!this.jobId) return;
        this.player.invoke('clearMomentStub');
        const jobPrivacyConfirmationModal = this.store.confirmationModal;
        const jobPrivacyConfirmationModalListener = (msg: Message<ConfirmationModalState>) => {
          switch (msg.type) {
            case 'open':
              this.player.invoke('enterPause');
              break;

            case 'close':
              jobPrivacyConfirmationModal.unlisten(jobPrivacyConfirmationModalListener);
              this.player.invoke('exitPause');
              this.player.invoke('clearMomentStub');
              this.videoPrivacyInput.value = this.job?.isPublic ? 'Shareable' : 'Private';
              break;
          }
        }
        jobPrivacyConfirmationModal.listen(jobPrivacyConfirmationModalListener);
        jobPrivacyConfirmationModal.open({
          onSubmit: this.updateJobPrivacy,
          modalMessage: `Are you sure you want to make the video ${this.videoPrivacyInput.value}?`,
          title: 'Job Update Confirmation'
        });
        break;
      default:
        break;
    }

  }


  /** Notifies the state that the page has been unmounted and triggers the cleanup tasks. */
  @action
  unload() {
    this.reset();
  }

  @action
  handleVideoStatusInputChange = async () => {
    assertNotNull(this.jobId);
    const { job } = this;

    const value = this.videoStatusInput.value;
    const input: UpdateJobInput = {
      id: this.jobId,
      status: value as any
    }

    if (value === 'Done') {
      if (!job)
        return notifyError(this, `Failed to set video status to '${value}'.`);

      const validator: ValidatorResponse | null = job.validateSetToDone();
      const { error, moments, multipleActiveTrackTypes } = validator;

      if (error) {
        this.videoStatusInput.value = job.status ?? null;
        this.invoke('openJobErrorsWindow', { clipList: moments, multipleActiveTrackTypes });
        return;
      }
      // if (clipErrorList.length > 0) {
      //   this.videoStatusInput.value = this.job?.status || null;
      //   this.invoke('openJobErrorsWindow', { clipErrorList });
      //   return;
      // }
    }

    const [, err] = await this.store.jobManager.apiUpdateJob(input);
    if (err) {
      this.videoStatusInput.value = this.job?.status || null;
      return notifyError(this, `Failed to set video status to '${value}'.`);
    }
    notifySuccess(this, `Changed video status to '${value}'.`);
  }

  @action
  handleVideoTypeInputChange = async () => {
    assertNotNull(this.jobId);

    const value = this.videoTypeInput.value;
    const input: UpdateJobInput = {
      id: this.jobId,
      videoType: value as JobType
    }
    const videoTypeLabel = JobVideoTypeList.find(i => i.value === value)?.label;

    const [, err] = await this.store.jobManager.apiUpdateJob(input);
    if (err) {
      this.videoTypeInput.value = this.job?.videoType || null;
      return notifyError(this, `Failed to set video type to '${videoTypeLabel}'.`);
    }
    notifySuccess(this, `Changed video type to '${videoTypeLabel}'.`);
  }

  @action
  handleVideoPrivacyInputChange = async () => {
    this.invoke('openJobPrivacyConfirmationWindow', { job: this.job });
  }

  @action
  updateJobPrivacy = async () => {
    assertNotNull(this.jobId);
    const value = this.videoPrivacyInput.value;
    const input: UpdateJobInput = {
      id: this.jobId,
      isPublic: value === 'Shareable' ? true : false
    }
    const [, err] = await this.store.jobManager.apiUpdateJob(input);
    if (err) {
      this.videoPrivacyInput.value = this.job?.isPublic ? 'Shareable' : 'Private';
      return notifyError(this, `Failed to set video privacy to '${value}'.`);
    }

    if (value === 'Shareable') {
      this.store.analyticsService.registerEvent(
        AnalyticsEventTypes.VideoMakePublicType,
        {
          jobId: this.jobId,
          job: this.job as JobModel
        }
      )
    }

    notifySuccess(this, `Video privacy changed to '${value}'.`);
  }

  // #region State helpers
  @action
  reset() {
    
    // TODO: see comment in `load`
    // this.store.uiService.setThemeVariation('Default');

    this.videoTypeInput.reset();
    this.videoStatusInput.reset();

    this.player.reset();
    this.jobId = null;
    this.isLoading = false;
    this.error = null;
  }

  @action
  private setError(error?: Error) {
    if (!error)
      error = pageError();

    this.isLoading = false;
    this.error = error;
  }

  @action
  private setLoaded() {
    this.isLoading = false;
    this.error = null;
  }
  // #endregion
}
