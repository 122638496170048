import { action, makeObservable, observable, runInAction } from 'mobx';
import { Message, StoreNode } from '../../store';
import { TeamProduct, TeamUpdateInput } from '@clipr/lib';
import { Nullable } from '../../core';
import { Store } from '../../store/store';
import { WindowState } from '../../components/overlays';
import { notifyError, notifySuccess } from '../../services/notifications';
import { Team } from '../../entities/team';
import { InputState, input } from '../input'
import { getTeamProductSelectorItems } from '../../entities/teamUtils';

export class TeamProductWindowState
  extends StoreNode {

  readonly nodeType = 'TeamProductWindow';

  constructor(store: Store, props?: any) {
    super(store, props);
    makeObservable(this);

    this.window.listen(
      this.windowListener);
  }

  @observable isLoading: boolean = false;
  @observable team: Nullable<Team> = null;

  readonly teamProductModel: InputState = input(this, {
    name: 'teamProduct',
    selectorItems: getTeamProductSelectorItems()
  });

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close();
        break;
    }
  }
  readonly window = new WindowState(this.store);

  @action
  async updateTeamProduct() {
    if (!this.team) return;

    this.isLoading = true;
    const args: TeamUpdateInput = {
      teamId: this.team.id,
      teamProduct: this.teamProductModel.value as TeamProduct
    }
    const [, err] = await this.store.teamManager.updateTeam({ args });

    if (err) {
      notifyError(this, 'Could not update library.');
      return;
    }

    runInAction(() => {
      this.emit('teamUpdated');
      this.isLoading = false;
    });

    notifySuccess(this, 'Library updated.');
    this.close();
  }

  @action
  open({ team }: { team: Team }) {
    this.dispatch('Overlays', 'openWindow', { name: 'TeamProductWindow' });
    this.team = team;
    this.teamProductModel.loadValue(team.teamProduct);
  }

  @action
  close() {
    this.dispatch('Overlays', 'closeWindow');
    this.emit('close');

    this.team = null;
  }

}