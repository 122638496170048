import './playerSpeakers.scss';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { PlayerSectionCloseButton } from '../playerShared/playerSectionCloseButton';
import { SyncStatus } from '../../entities';
import { PlayerSpeakersState } from './playerSpeakersState';
import { SpeakerIdForm } from '../speakerId/speakerIdForm';
import { LoadMask } from '../loader/loadMask';

export type PlayerSpeakersProps = {
  model: PlayerSpeakersState,
  layout?: string,
  showHeader?: boolean
  className?: string,
  onClose?: () => void
}

export const PlayerSpeakers = observer(({
  model,
  layout = 'vertical',
  showHeader = true,
  className,
  onClose
}: PlayerSpeakersProps) => {

  className = classNames('player-speakers user-player-section page-section', className, layout, {
    'with-header': showHeader,
  });
  const isLoading = model.momentsSyncStatus === SyncStatus.Fetching;

  useEffect(() => {
    model.mounted();
    return () =>
      model.unmounted();
  }, [model]);

  return (
    <section
      id="player-speakers"
      data-tutorial-id="player-speakers"
      className={className}
      data-loading={isLoading}
      ref={model.sectionProxy.ref}>

      <PlayerSpeakerHeaderSection model={model} />

      <PlayerSpeakersSectionBody model={model} />

      <PlayerSectionCloseButton
        model={model}
        onClose={onClose} />
    </section>
  );
});

const PlayerSpeakersSectionBody = observer(({
  model,
}: PlayerSpeakersProps) => {
  const { speakerIdForm, isLoading } = model;

  return <div className="player-section-body">
    <SpeakerIdForm model={speakerIdForm} />
    {isLoading && (
      <LoadMask
        className="page-load-mask player-background user-player-section-load-mask small"
        spinner="dots3"
        spinnerClassName="medium"
        visible={isLoading} />
    )}
  </div>
});

const PlayerSpeakerHeaderSection = observer(({
  model
}: PlayerSpeakersProps) => {

  const { speakerIdForm } = model;

  const speakerCount = speakerIdForm.speakerCountDisplay;

  return <header className="player-section-header"
    id="user-player-index-section-header">

    <div className="section-row flex-space-between">
      <h3 className="section-heading">
        <span className="text">Help us make this clip better</span>
      </h3>
    </div>
    <div className="section-row">
      <div className="section-subheading">
        {"We've found "}
        <span className="bold">{speakerCount}</span>
        {" in this video and would appreciate your help to identify them."}
      </div>
    </div>
  </header>
});