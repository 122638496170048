export enum Placement {
  TopStart = 'top-start',
  Top = 'top',
  TopEnd = 'top-end',
  BottomStart = 'bottom-start',
  Bottom = 'bottom',
  BottomEnd = 'bottom-end',
  RightStart = 'right-start',
  Right = 'right',
  RightEnd = 'right-end',
  LeftStart = 'left-start',
  Left = 'left',
  LeftEnd = 'left-end'
}

export const PlayerTutorialSelectors = {
  PlayerTranscripts: 'user-player-transcript-scroll',
  TopicIndex: 'topic-index',
  EditTopicIndex: 'edit-topic-index-btn',
  UserPlayerTopicItem: 'user-player-topic-item',
  JumpToCurrentIndex: 'jump-to-current-index',
  SubtopicCard: 'subtopic-card',
  AddAClipBtn: 'add-a-clip-btn',
  AddAClipBtnWidget: 'add-clip-btn-widget',
  PlayerComments: 'player-comments',
  SearchInTranscript: 'search-in-transcript',
  SearchIcon: 'search-icon',
  PlayerTranscriptsSection: 'player-transcripts',
  BookmarkCardDetails: 'bookmark-card-details',
  UserToggleBar: 'user-player-toggle-bar',
  BookmarkCurrentTopic: 'bookmark-current-topic',
  UserPlayerActiveTopicCard: 'user-player-active-topic-card',
  BookmarkBtn: 'card-bookmark-btn',
  ReactionsBtn: 'reactions-button',
  HelpBtn: 'tutorial-help-button',
  PlayerReactionsPanel: 'player-reactions-panel',
  PlayerReactionsMenu: 'player-reactions-menu',
  UserPlayerTranscriptBox: 'user-player-transcript-box',
};


export const PlayerTutorialStepsContent = {
  'Transcripts': {
    title: '→ Transcript and Speakers',
    content: 'CLIPr identifies each speaker and creates a full transcript.'
  },
  'Topics': {
    title: '→ Topics and Subtopics',
    content: 'AI and machine learning identify Topics and Subtopics for fast browse and search.'
  },
  'TopicIndex': {
    title: '→ Choose what to watch',
    content: 'Open the full topic index to choose which Topics and Subtopics to watch.'
  },
  'SelectTopic': {
    title: '→ Select topic to play',
    content: 'Scroll and click on any Topic to play it.'
  },
  'CurrentTopic': {
    title: '→ Jump to current topic',
    content: 'Jump to the current Topic when video is playing.'
  },
  'AddClip': {
    title: '→ Add Subtopics',
    content: `Add Subtopics to CLIPr's AI output.`
  },
  'Reactions': {
    title: '→ Reactions & Comments',
    content: `Add comments and reactions to key moments so your library can collaborate and take action.`
  },
  'Search': {
    title: '→ Searching Words, Names & Concepts',
    content: `Quickly find specific words, names and concepts in the transcript.`
  },
  'Bookmark': {
    title: '→ Bookmark and share a Topic, or Subtopic',
    content: 'Store and share bookmarks in your personalized video library.'
  },
  'Tools': {
    title: '→ Tools Control Center',
    content: 'Turn CLIPr tools on/off to use what matters most to you.'
  }
}



