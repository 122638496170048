import assert from 'assert';
import { ReactEventHandler, ReactNode } from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames/dedupe';
import { BindingProps, StoreNode } from '../../store';
import { Store } from '../../store/store';
import { InputState } from './inputState';

type InputLikeState = InputState | InputGroupState;

export type InputGroupStateProps = {
  name: string,
} &

  Partial<{
    onSubmit: ReactEventHandler
    onSubmitResolve: ReactEventHandler,
    onSubmitReject: ReactEventHandler
  }> &

  BindingProps<{

    inputs: InputLikeState[],
    initialInputs: InputLikeState[],

    showStatus: boolean,
    showStatusMessage: boolean,
    status: ReactNode,
    statusMessage: ReactNode,

    info: ReactNode,
    error: ReactNode,
    warning: ReactNode,
    success: ReactNode,
    loading: ReactNode,

    disabled: boolean,
    readOnly: boolean,

    isSubmitDisabled: boolean,
    export: string | number | object
  }, InputGroupState>;


/**
 * Allows grouping of inputs and exposes queries and methods for the group as a whole.
 */
export class InputGroupState
  extends StoreNode {

  constructor(store: Store, props: InputGroupStateProps) {
    super(store, props);
    makeObservable(this);

    const { name } = props;
    assert(typeof name === 'string' && name.length > 0,
      `InputGroup name must be a non-empty string.`);

    this.name = name;
  }

  readonly name: string;
  initialInputs: InputLikeState[] | null = null;

  @computed get inputs(): InputLikeState[] {
    return this.getResolvedProp('inputs', []);
  }
  @computed get readOnly(): boolean { return false; }

  @computed get isFocused(): boolean { return this.hasFocusedInputs; }
  @computed get hasFocusedInputs(): boolean {
    return this.inputs.some(input => input.isFocused);
  }

  @computed get focusCount(): number { return this.inputFocusCount; }
  @computed get inputFocusCount() {
    return this.inputs.reduce((sum, input) => sum + input.focusCount, 0);
  }

  @computed get isChanged(): boolean { return this.hasChangedInputs; }
  @computed get hasChangedInputs() {
    return this.inputs.some(input => input.isChanged);
  }

  @computed get changeCount(): number { return this.inputChangeCount; }
  @computed get inputChangeCount() {
    return this.inputs.reduce((sum, input) => sum + input.changeCount, 0);
  }


  @computed get isDirty(): boolean { return this.hasDirtyInputs }
  @computed get hasDirtyInputs() {
    return this.inputs.some(input => input.isDirty);
  }

  // Checks if the array of inputs has changed since initialization
  @computed get arrayOfInputsChanged(): boolean {
    if (!this.initialInputs) {
      return this.inputs.some(input => (input as InputGroupState).arrayOfInputsChanged);
    }

    return (
      (!isEqual(this.initialInputs, this.inputs)) ||
      this.inputs.some(input => (input as InputGroupState).arrayOfInputsChanged));
  }

  @computed get isEmpty(): boolean { return !this.hasNonEmptyInputs; }
  @computed get hasNonEmptyInputs() {
    return this.inputs.some(input => !input.isEmpty);
  }

  @computed get hasInfoInputs() {
    return this.inputs.some(input => input.info);
  }
  @computed get hasLoadingInputs() {
    return this.inputs.some(input => input.loading);
  }

  @computed get hasSuccessInputs() {
    return this.inputs.some(input => input.success);
  }

  @computed get hasWarningInputs() {
    return this.inputs.some(input => input.warning);
  }

  @computed get hasErrorInputs() {
    return this.inputs.some(input => input.error);
  }

  @computed get hasVisibleError(): boolean {
    return this.inputs.some(input => input.hasVisibleError);
  }

  @computed get isTouched(): boolean { return this.hasTouchedInputs; }
  @computed get hasTouchedInputs(): boolean {
    return this.inputs.some(input => input.isTouched);
  }

  @computed get isChangedSinceLastSubmit(): boolean {
    return this.hasChangedSinceLastSubmitInputs;
  }

  @computed get hasTouchedErrorInputs() {
    return this.inputs.some(input => input.isTouched && input.error);
  }
  @computed get hasChangedSinceLastSubmitInputs() {
    return this.inputs.some(input => input.isChangedSinceLastSubmit);
  }
  /** 
   * Returns true if there are inputs which have both an 'error' set and 'showStatus' or 'showStatusMessage' set to true. 
   * Useful to decide if the submit button should be disabled, such that it won't be disabled if no obvious error is displayed to the user
   * (even if the data inside the form might be invalid).
   */
  @computed get hasVisibleErrorInputs() {
    return this.inputs.some(input => input.error && (input.showStatus || input.showStatusMessage));
  }

  @computed get hasDisabledInputs() {
    return this.inputs.some(input => input.disabled);
  }

  @computed get hasReadOnlyInputs() {
    return this.inputs.some(input => input.readOnly);
  }

  @observable isSubmitting = false;
  @observable isSubmitRejected = false;
  @observable isSubmitResolved = false;
  @computed get isSubmitCompleted() { return this.isSubmitResolved || this.isSubmitRejected }
  @observable submitCount = 0;
  @observable submitResolveCount = 0;
  @observable submitRejectCount = 0;

  @computed get isSubmittedOnce() { return this.submitCount > 0; }
  @computed get isSubmitResolvedOnce() { return this.submitResolveCount > 0; }
  @computed get isSubmitRejectedOnce() { return this.submitRejectCount > 0; }

  @computed get isSubmitDisabled() {
    return this.getResolvedProp('isSubmitDisabled',
      this.hasTouchedErrorInputs);
  }

  @computed get canSubmit() {
    return !this.hasLoadingInputs && !this.hasErrorInputs;
  }

  @computed get error(): ReactNode | null {
    return this.getResolvedProp('error', (this.hasErrorInputs));
  }
  @computed get warning(): ReactNode | null {
    return this.getResolvedProp('warning');
  }
  @computed get success(): ReactNode | null {
    return this.getResolvedProp('success');
  }
  @computed get loading(): ReactNode | null {
    return this.getResolvedProp('loading');
  }
  @computed get info(): ReactNode | null {
    return this.getResolvedProp('info');
  }

  @computed
  get showStatus(): boolean {
    return this.getResolvedProp('showStatus', this.hasTouchedInputs)
  }

  @computed
  get showStatusMessage(): boolean {
    return this.getResolvedProp('showStatusMessage', this.hasTouchedInputs);
  }

  @computed
  get disabled() {

    const fallback: boolean = (
      this.hasLoadingInputs ||
      this.hasTouchedErrorInputs ||
      this.isSubmitting);

    return this.getResolvedProp('disabled', fallback);
  }

  @computed get status() {
    const fallback = (() => {
      if (this.loading) return 'loading';
      if (this.error) return 'error';
      if (this.warning) return 'warning';
      if (this.success) return 'success';
      if (this.info) return 'info';
      return null;
    })();
    return this.getResolvedProp('status', fallback);
  }

  @computed get statusMessage() {
    const fallback = (() => {
      switch (this.status) {
        case 'loading': return this.loading;
        case 'error': return this.error;
        case 'warning': return this.warning;
        case 'success': return this.success;
        default: return this.info;
      }
    })();
    return this.getResolvedProp('statusMessage', fallback);
  }

  getClassName(props?: any, extra?: any) {

    let status = (this.showStatus && this.status) || '';
    let statusMessage = (this.showStatusMessage && this.statusMessage) || '';

    return classNames(
      extra,
      props?.className,
      status,
      {
        'status': !!status,
        'status-message': !!statusMessage,
        'focus': this.hasFocusedInputs,
        'dirty': this.hasDirtyInputs,
        'touched': this.hasTouchedInputs,
        'changed': this.hasChangedInputs
      });
  }

  /**
   * Function that stores the initial value of the provided array of inputs
   * Used to keep track of the initial value 
   * in case any comparison with the actual value is needed
   */

  @action
  saveInitialInputs() {
    this.initialInputs = [...this.inputs];
  }

  @action
  reset() {
    Object.assign(this, {
      submitCount: 0,
      rejectCount: 0,
    });
    this.inputs.forEach(input => input.reset());
  }

  @action
  clear() {
    this.reset();
    this.inputs.forEach(input => input.clear());
  }

  export(): any {
    const fallback = fromPairs(
      this.inputs.map(input => [input.name, input.export()]));
    return this.getResolvedProp('export', fallback);
  }

  @action
  handleSubmit() {
    this.isSubmitting = true;
    this.isSubmitRejected = false;
    this.isSubmitResolved = false;
    this.submitCount++;
    this.inputs.forEach(input =>
      input.handleSubmit());
  }

  @action
  handleSubmitResolve() {
    this.isSubmitting = false;
    this.isSubmitResolved = true;
    this.submitResolveCount++;
    this.inputs.forEach(input =>
      input.handleSubmitResolve());
  }

  @action
  handleSubmitReject() {
    this.isSubmitting = false;
    this.isSubmitRejected = true;
    this.submitRejectCount++;
    this.inputs.forEach(input =>
      input.handleSubmitReject());
  }
}

/** Helper method for creating a new InputState object for the provided StoreNode. */
export const inputGroup = (node: StoreNode, props: InputGroupStateProps) => {
  return new InputGroupState(node.store, props);
}