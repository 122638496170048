import { StoreNode } from '../../store';
import { RouteContext } from '../../routes/routeContext';
import { AsyncResult } from '../../core';
import { AuthFlowName, Permissions } from '../../services/auth/authFlowSchema';
import { Error } from '../../core/error';
import { TeamWidgetRouteParams } from '../teamWidget/teamWidgetState';
import { IRouteInjector } from '../../routes/routeSchema';

export class LiveStreamQueueWidgetInjector
  extends StoreNode
  implements IRouteInjector {

  readonly disabledAuthFlows = new Set<AuthFlowName>([
    AuthFlowName.PublicRoute
  ]);

  async fetchPermissions(routeContext: RouteContext<TeamWidgetRouteParams>): AsyncResult<Permissions, any> {
    const { TeamPermissionsSource } = await import('../../entities/teamPermissionsSource');
    
    const teamId: string | null = routeContext.params?.teamId ?? null;
    if (!teamId)
      return [null, new Error('InternalError', `Unable to get jobId from route params.`)];

    const source = new TeamPermissionsSource(this.store, {
      teamId
    });

    return source.fetchLivePermissions();
  }
}