import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Order, PageInfo, ExternalLibraryFilesOrderByOption, ExternalLibraryFilesFilterInput } from '@clipr/lib';
import debounce from 'lodash/debounce';
import { assertNotNull, AsyncResult, Nullable } from '../../core';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { ApiResult, ApiVariables } from '../../api/apiSchema';
import { SyncStatus } from '../../store/syncSchema';
import { SortFieldValue } from '../../components/input/sortFieldBtn';
import { input, InputState } from '../../components';
import { LibraryCatalogItem } from './libraryCatalogItem';
import { LibraryServiceBase } from '../../services/libraries/libraryServiceBase';


type Props = BindingProps<{
  pageSize?: number,
  library: LibraryServiceBase,
  defaultSearchValue?: string
}>

export class LibraryCatalogSource
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);

    this.pageSize = this.props.pageSize;
    this.searchBarModel = input(this, {
      name: 'searchInput',
      value: this.defaultSearchValue,
      onChange: (e) => this.handleSearchInputChange(e),
    });
  }

  @computed get libraryService(): LibraryServiceBase {
    return this.resolvedProps.library;
  }
  // #endregion

  readonly items = observable.array<LibraryCatalogItem>();
  readonly searchBarModel: InputState;
  readonly fileSelectionLookup = observable.map<string, LibraryCatalogItem>();

  @computed
  get defaultSearchValue() {
    return this.resolvedProps.defaultSearchValue || null;
  }

  @observable hasFetchedOnce: boolean = false;
  @observable syncStatus: SyncStatus = 'idle';
  @observable searchFilter: Nullable<string> = null;
  @observable pageSize: number;
  @observable sortField: Nullable<ExternalLibraryFilesOrderByOption> = 'modifiedTime';
  @observable sortOrder: Nullable<Order> = 'desc';


  @observable pageInfo: Nullable<PageInfo> = null;

  @computed get lastItemCursor(): Nullable<string> {
    return this.pageInfo?.endCursor || null;
  }

  @computed get entities(): LibraryCatalogItem[] {
    return this.items;
  }

  @computed get availableforIngestEntities(): LibraryCatalogItem[] {
    return this.entities.filter((entity: LibraryCatalogItem) => entity.isNotProcessed);
  }

  @computed get isEndOfList(): boolean {
    return this.pageInfo ? !this.pageInfo.hasNextPage : false;
  }

  @computed
  get fileSelectionIds(): string[] {
    return [...this.fileSelectionLookup.keys()];
  }
  @computed
  get hasMultipleSelection(): boolean {
    return this.fileSelectionIds.length > 0;
  }
  @computed
  get hasAllAvailableForIngestFilesSelected(): boolean {
    return this.fileSelectionLookup.size === this.availableforIngestEntities.length;
  }

  getSortFieldValue(field: ExternalLibraryFilesOrderByOption) {
    if (this.sortField !== field)
      return 'none';
    return this.sortOrder!;
  }

  @action
  setSortFieldValue(field: ExternalLibraryFilesOrderByOption, val?: SortFieldValue) {
    if (!val || val === 'none') {
      this.sortField = null;
      this.sortOrder = null;
    } else {
      this.sortField = field;
      this.sortOrder = val;
    }
    this.fetch();
  }

  @action
  setPagesizeValue(pageSize: number) {
    if (pageSize !== this.pageSize) {
      this.pageSize = pageSize;
      this.fetchDebounced();
    }
  }

  @action
  handleSearchInputChange(evt: any) {
    this.searchFilter = evt.value || null;
    this.fetchDebounced();
  }
  fetchDebounced = debounce(() => this.fetch(), 500);

  /** Makes a fresh fetch call, clearing any previous data and pagination state. */
  @action
  async fetch()
    : AsyncResult<LibraryCatalogItem[] | null> {
    if (!this.libraryService.isConnected) return [null];
    this.syncStatus = 'fetching';

    const [res, err] = await this.fetchRaw();


    if (err)
      return [null, err];

    assertNotNull(res);

    const retItems: LibraryCatalogItem[] = [];
    runInAction(() => {
      this.items.clear();

      const files = res.viewer?.externalLibrary.files;
      if (!files) return;
      this.pageInfo = files.pageInfo || null;

      if (!files.edges) return;
      files.edges.forEach((edge, i) => {
        const item = new LibraryCatalogItem(this.store, {
          ...edge.node,
          index: i,
          library: this.libraryService
        });

        this.items.push(item);
        retItems.push(item);
      });
    });

    return [retItems];
  }

  @action
  async fetchMore()
    : AsyncResult<LibraryCatalogItem[] | null> {
    if (!this.libraryService.isConnected) return [null];
    this.syncStatus = 'fetchingMore';

    const [res, err] = await this.fetchRaw(true);
    if (err)
      return [null, err];

    assertNotNull(res);

    const retItems: LibraryCatalogItem[] = [];
    runInAction(() => {
      const files = res.viewer?.externalLibrary.files;
      if (!files) return;
      this.pageInfo = files.pageInfo || null;

      if (!files.edges) return;
      files.edges.forEach((edge, i) => {
        const item = new LibraryCatalogItem(this.store, {
          ...edge.node,
          index: i,
          library: this.libraryService
        });

        this.items.push(item);
      });
    });
    return [retItems];
  }

  @action
  getFetchArgs(more: boolean = false) {
    let args: ApiVariables<'getExternalLibrary'> = {
      id: this.libraryService.library?.id
    };
    let filter: ExternalLibraryFilesFilterInput = {};

    if (this.sortField && this.sortOrder) {
      args.sort = {
        field: this.sortField,
        order: this.sortOrder
      }
    }

    if (this.searchFilter) {
      filter.q = this.searchFilter || undefined;
    }

    if (Object.keys(filter).length > 0) {
      args.filter = filter;
    }

    args.first = this.pageSize;

    if (more && this.lastItemCursor) {
      args.after = this.lastItemCursor;
    }

    return args;
  }

  @action
  private async fetchRaw(more: boolean = false): ApiResult<'getExternalLibrary'> {
    const args: ApiVariables<'getExternalLibrary'> = this.getFetchArgs(more);

    const [res, err] = await this.store.api.getExternalLibrary(args);

    if (err) {
      runInAction(() => {
        this.syncStatus = 'error';
        if (this.libraryService.isConnected) {
          this.libraryService.setError(err);
        }
      });
      return [null, err];
    }

    runInAction(() => {
      this.syncStatus = 'idle';
      this.libraryService.error = null;
    });

    assertNotNull(res);

    if (res.viewer?.externalLibrary.files?.invalidCredentials) {
      this.emit('credentialsExpired');
    }

    this.hasFetchedOnce = true;
    return [res, null];
  }

  @action
  updateFileSelection(file: LibraryCatalogItem) {
    if (!(file instanceof LibraryCatalogItem)) return;

    if (this.fileSelectionLookup.has(file.id)) {
      return this.fileSelectionLookup.delete(file.id);
    }

    return this.fileSelectionLookup.set(file.id, file);
  }

  @action
  clearMultipleSelection() {
    this.fileSelectionLookup.clear();
  }

  @action
  selectAllFilesAction() {
    if (!this.hasMultipleSelection) return this.selectAllFiles();
    return this.clearMultipleSelection();
  }

  @action
  selectAllFiles() {
    const filesAvailableForIngest = this.entities.filter((file: LibraryCatalogItem) => file.isNotProcessed);
    filesAvailableForIngest.forEach((file: LibraryCatalogItem) => this.fileSelectionLookup.set(file.id, file));
  }

  @action
  reset() {
    if (this.defaultSearchValue) {
      this.searchBarModel.value = this.defaultSearchValue;
      this.searchFilter = this.defaultSearchValue;
    } else {
      this.searchBarModel.clear();
      this.searchFilter = null;
    }
    this.clearMultipleSelection();
    this.fetch();
  }
}

