import { JobModel, MomentKind } from "../../../entities";
import { IAnalyticsStream, AnalyticsStreamParams, AnalyticsEventTypes } from "../analyticsSchema";
import { AnalyticsStream, AnalyticsMainData, AnalyticsCurrentJobData, AnalyticsPlayerStateData } from "./analyticsStream";

export enum ShareType {
  FullVideo = 'fullVideo',
  Topic = 'topic',
  Subtopic = 'subtopic',
  Bookmark = 'bookmark',
}

export enum SharedFrom {
  BookmarkPage = 'bookmarkPage',
  PlayerPage = 'playerPage',
};
export enum SocialSharePlatforms {
  Whatsapp = 'whatsapp',
  Facebook = 'facebook',
  Twitter = 'twitter',
  Linkedin = 'linkedin',
  Tumblr = 'tumblr',
  Reddit = 'reddit',
  CopyLink = 'copyLink',
  CopyEmbedCode = 'copyEmbedCode',
};
export type AnalyticsVideoShareStreamParams = {
  bookmarkListId: string | null,
  bookmarkId: string | null,
  momentId: string | null,
  momentName: string | null,
  momentKind: MomentKind | null,
  sharedFrom: SharedFrom,
  sharedTo: SocialSharePlatforms,
  shareType: ShareType,
  job: JobModel,
}

export type AnalyticsVideoShareStreamData = {
  bookmarkListId: string | null,
  bookmarkId: string | null,
  momentId: string | null,
  momentName: string | null,
  momentKind: MomentKind | null,
  sharedFrom: SharedFrom,
  sharedTo: SocialSharePlatforms,
  shareType: ShareType,
}
  & AnalyticsPlayerStateData
  & AnalyticsCurrentJobData
  & AnalyticsMainData

export class AnalyticsVideoShareStream extends AnalyticsStream implements IAnalyticsStream<keyof AnalyticsStreamParams> {
  static streamName = AnalyticsEventTypes.VideoShareType

  exchangeData(data: AnalyticsVideoShareStreamParams): AnalyticsVideoShareStreamData | null {
    return {
      ...{
        bookmarkListId: data?.bookmarkListId ?? null,
        bookmarkId: data?.bookmarkId ?? null,
        momentId: data?.momentId ?? null,
        momentName: data?.momentName ?? null,
        momentKind: data?.momentKind ?? null,
        sharedFrom: data?.sharedFrom,
        sharedTo: data?.sharedTo,
        shareType: data?.shareType
      },
      ...super.playerData,
      ...super.currentJobData(data.job),
      ...super.mainData,
      ...super.viewContextData
    };
  }
}
