import { action, makeObservable, observable, computed } from 'mobx';
import { Message, StoreNode } from '../../store';
import { Nullable } from '../../core';
import { Store } from '../../store/store';
import { WindowState } from '../../components/overlays';
import { notifyError, notifySuccess, notifyLoading } from '../../services/notifications';
import { Team } from '../../entities/team';
import { MemberItemTargetType } from './teamMemberCatalogState';

export type RemoveAccessOpenParams = {
  teamId: string,
  userId?: string,
  email?: string
}

export class RemoveAccessPopupState
  extends StoreNode {

  readonly nodeType = 'RemoveAccessPopup';

  constructor(store: Store, props?: any) {
    super(store, props);
    makeObservable(this);
    this.window.listen(
      this.windowListener);
  }
  @observable isLoading: boolean = false;
  @observable targetType: Nullable<MemberItemTargetType> = null;

  teamId: Nullable<string> = null;
  @observable userId: Nullable<string> = null;
  @observable email: Nullable<string> = null;

  @computed
  get team(): Nullable<Team> {
    return this.store.teamManager.getTeam(this.teamId);
  }

  @computed
  get targetName() {

    if (this.userId) {
      let target = this.team?.members.find(o => o.userId === this.userId);
      return target?.user?.username;
    }

    return this.email;
  }

  private windowListener = (msg: Message<WindowState>) => {
    switch (msg.type) {
      case 'close':
      case 'outsideClick':
        if (this.isLoading)
          return;
        this.close();
        break;
    }
  }
  readonly window = new WindowState(this.store);

  @action
  async submit() {
    if (!this.teamId) return;
    this.isLoading = true;
    if (this.userId) {
      await this.handleSubmitRemoveMember();
      await this.team?.apiFetchMembers();
    } else if (this.email) {
      await this.handleSubmitRemoveInvitation();
      await this.team?.apiFetchInvitations();
    }
    this.isLoading = false;
    this.close('removedAccess');
  }

  @action
  async handleSubmitRemoveInvitation() {
    notifyLoading(this, "Removing Invitation...");
    const name = this.targetName;
    const [, err] = await this.team?.apiDeleteTeamInvitation({ //or open modal
      teamId: this.team?.id,
      email: this.email!
    }) || [null];

    if (err) {
      notifyError(this, `Invitation for ${name} removal failed.`);
      return;
    }

    notifySuccess(this, `Invitation for ${name} was removed successfully.`);
  }

  @action
  async handleSubmitRemoveMember() {
    notifyLoading(this, "Removing Library Member...");
    const name = this.targetName;
    const [, err] = await this.team?.apiDeleteTeamMember({ //or open modal
      teamId: this.team?.id,
      userId: this.userId!
    }) || [null];

    if (err) {
      notifyError(this, `Library member ${name} removal failed.`);
      return;
    }

    notifySuccess(this, `Library member ${name} was removed successfully.`);
  }

  @action
  open(params: RemoveAccessOpenParams) {
    this.dispatch('Overlays', 'openWindow', { name: 'RemoveAccessPopup' });

    this.teamId = params.teamId;
    this.userId = params.userId || null;
    this.email = params.email || null;
  }

  @action
  close(msg?: string) {
    this.dispatch('Overlays', 'closeWindow');

    this.teamId = null;
    this.userId = null;
    this.email = null;

    if (msg) this.emit(msg);
  }
}