import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Store } from '../store/store';
import { StoreNode } from '../store';
import { Team, TeamProps } from './team';
import { assert, assertNotNull, AsyncResult, Maybe } from '../core';
import { ApiVariables } from '../api/apiSchema';
import { ApiRequestOptions } from '../api';

export const TeamAvatarSize = {
  width: 100,
  height: 100
}

export const TeamCoverSize = {
  width: 200
}

export const TeamLogoSize = {
  width: 200
}

export const TeamListDefaultLimit = 1000;

/**
 * Manages Team and TeamList entities for a Store.
 */
export class TeamManager
  extends StoreNode {

  constructor(store: Store) {
    super(store);
    makeObservable(this);
  }

  readonly nodeType: 'TeamManager' = 'TeamManager';

  /** @alias `Store.apiService` */
  private get api() {
    return this.store.api;
  }

  readonly teamLookup = observable.map<string, Team>();
  readonly adminTeamLookup = observable.map<string, Team>();

  @computed
  get teams(): Team[] {
    return [...this.teamLookup.values()];
  }
  // This is necessary because apiFetchTeam inserts a new Team at the end of the map
  @computed
  get teamsSortedByName(): Team[] {
    return this.teams.sort((a, b) => a.name > b.name ? 1 : -1);
  }
  @computed
  get adminTeams(): Team[] {
    return [...this.adminTeamLookup.values()];
  }

  // #region Team methods
  // -------

  private assertHasTeam(id: string) {
    assert(this.teamLookup.has(id),
      `Store does not contain a Library with id '${id}'.`);
  }
  private assertHasAdminTeam(id: string) {
    assert(this.adminTeamLookup.has(id),
      `Store does not contain an Admin Library with id '${id}'.`);
  }

  hasTeam(id?: Maybe<string>): boolean {
    if (!id)
      return false;
    return this.teamLookup.has(id);
  }

  strictGetTeam(id: string): Team {
    this.assertHasTeam(id);
    return this.teamLookup.get(id)!;
  }
  strictGetAdminTeam(id: string): Team {
    this.assertHasAdminTeam(id);
    return this.adminTeamLookup.get(id)!;
  }
  getTeam(id?: Maybe<string>): Team | null {
    if (!id)
      return null;
    return this.teamLookup.get(id) || null;
  }

  maybeGetTeamByName(name: Maybe<string>): Team | null {
    if (!name)
      return null;
    return this.teams.find(t => t.name === name) || null;
  }

  @action
  insertTeam(props: TeamProps, replace: boolean = true) {
    const team = new Team(this.store, props);

    if (!replace && this.teamLookup.has(team.id)) {
      return this.teamLookup.get(team.id) as Team;
    }
    this.teamLookup.set(team.id, team);

    return team;
  }

  @action
  insertAdminTeam(props: TeamProps) {
    const team = new Team(this.store, props);
    this.adminTeamLookup.set(team.id, team);
    return team;
  }

  @action
  removeTeam(id: string): Team | null {
    const team = this.getTeam(id);
    if (!team)
      return null;

    team.dispose();
    this.teamLookup.delete(id);
    return team;
  }

  async apiFetchTeam(args: ApiVariables<'getTeam'>, opts?: ApiRequestOptions): AsyncResult<Team> {

    if (!args.avatarSize)
      Object.assign(args, {
        avatarSize: [TeamAvatarSize]
      });

    if (!args.coverSize)
      Object.assign(args, {
        coverSize: [TeamCoverSize]
      });

    if (!args.logoSize)
      Object.assign(args, {
        logoSize: [TeamLogoSize]
      });

    const [teamRes, err] = await this.api.getTeam(args, opts);
    if (err || !teamRes)
      return [null, err];

    return [this.insertTeam(teamRes.getTeam)];
  }

  async apiCreateTeam(args: ApiVariables<'createTeam'>): AsyncResult<Team> {
    const [teamProps, err] = await this.api.createTeam(args);
    if (err)
      return [null, err];
    assertNotNull(teamProps);
    const team = this.insertTeam(teamProps.createTeam);
    return [team];
  }

  @observable fetchTeamsResolvedOnce = false;
  // To be returned for concurrent requests against the API at load
  @observable firstFetchTeamsPromise: AsyncResult<Team[]> | null = null;

  @action
  async apiFetchTeams(args: ApiVariables<'getTeams'> = {}, replace: boolean = true): AsyncResult<Team[]> {

    if (!args.avatarSize)
      Object.assign(args, {
        avatarSize: [TeamAvatarSize]
      });

    if (!args.coverSize)
      Object.assign(args, {
        coverSize: [TeamCoverSize]
      });

    if (!args.logoSize)
      Object.assign(args, {
        logoSize: [TeamLogoSize]
      });

    if (!args.first) {
      Object.assign(args, {
        first: TeamListDefaultLimit
      });
    }

    if (!args.sort) {
      Object.assign(args, {
        sort: {
          field: 'name',
          order: 'asc'
        }
      });
    }

    const [teamsRes, err] = await this.api.getTeams(args);
    if (err) {
      return [null, err];
    }

    return runInAction(() => {
      const teamsArr = teamsRes?.getTeams.edges || [];
      const teams = teamsArr
        .map((edge: any) => this.insertTeam(edge.node, replace));

      this.fetchTeamsResolvedOnce = true;

      return [teams];
    });
  }

  async apiEnsureTeams(args?: ApiVariables<'getTeams'>): AsyncResult<Team[]> {
    if (!this.fetchTeamsResolvedOnce) {
      // TODO Replace this with AsyncLoader
      if (!this.firstFetchTeamsPromise)
        this.firstFetchTeamsPromise = this.apiFetchTeams();

      return await this.firstFetchTeamsPromise;
    }
    return [this.teams.slice()];
  }

  async updateTeam(args: ApiVariables<'updateTeam'>, apiFetch: boolean = false): AsyncResult<Team | null> {
    const [teamData, err] = await this.api.updateTeam(args);

    if (err) {
      return [null, err];
    }

    const teamId = teamData?.updateTeam.id ?? '';
    if (apiFetch) {
      const [, teamErr] = await this.apiFetchTeam({ id: teamId });

      if (teamErr) {
        return [null, new Error('Updated team could not be fetched')];
      }
    }

    return [this.getTeam(teamId)];
  }
  // #endregion
}