import { RouteContext } from '../routes/routeContext';

export interface IPageState {
  attached(routeContext: RouteContext, componentParams?: any): void | Promise<void>;
  detached(): void;
}

export enum PageName {
  Default = 'Default',
  Login = 'Login',
  Logout = 'Logout',
  Register = 'Register',
  Onboard = 'Onboard',
  ResetPassword = 'ResetPassword',
  UserPlayer = 'UserPlayer',
  UserSplash = 'UserSplash',
  UserDashboard = 'UserDashboard',
  Upload = 'Upload',
  TrainerPlayer = 'TrainerPlayer',
  TrainerDashboard = 'TrainerDashboard',
  TeamSettings = 'TeamSettings',
  TeamDashboard = 'TeamDashboard',
  SearchResults = 'SearchResults',
  ChatGptSearchResults = 'ChatGptSearchResults',
  LiveStreamQueue = 'LiveStreamQueue',
  CreateTeam = 'CreateTeam',
  Bookmarks = 'Bookmarks',
  BookmarkList = 'BookmarkList',
  Analytics = 'Analytics',
  TeamAnalytics = 'TeamAnalytics',
  AdminTeams = 'AdminTeams',
  Profile = 'Profile',
  Subscriptions = 'Subscriptions',
  TermsOfService = 'TermsOfService',
  PrivacyPolicy = 'PrivacyPolicy',
  ZoomHelp = 'ZoomHelp',
  JoinInvitation = 'JoinInvitation',
}