import { makeObservable } from 'mobx';
import { Store } from '../../../store/store';
import { StoreNode } from '../../../store';
import { AuthStepOrchestrator } from '../authStepOrchestrator';
import { AuthFlowName, AuthFlowResponse, IAuthFlow } from '../authFlowSchema';
import { AuthenticatedAuthContext } from '../authenticatedAuthContext';
import { AsyncResult, Result } from '../../../core';
import { RouteContext } from '../../../routes/routeContext';
import { Error } from '../../../core/error';
import { ClientMode } from '../../../kernel/kernelSchema';

type Props = {
  routeContext: RouteContext;
}

export type PrivateRouteFlowParams = Props;

export class PrivateRouteFlow
  extends StoreNode
  implements IAuthFlow {

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);
  }

  readonly flowName = AuthFlowName.PrivateRoute;
  readonly orchestrator = new AuthStepOrchestrator(this.store);

  async run(): AsyncResult<AuthFlowResponse> {

    const { orchestrator } = this;
    const { state } = orchestrator;

    if (this.store.kernel.clientMode !== ClientMode.Application)
      return orchestrator.setError(new Error('Forbidden', `Access is forbidden.`));

    let currContext = state.getContext();
    let nextContext: AuthenticatedAuthContext | null = null;

    if (
      currContext &&
      currContext.isAuthenticated &&
      currContext.isValid) {

      // local state indicates that the user might be authenticated and initial auto login already performed
      nextContext = currContext;

    } else {

      // no authenticated context, try and get one
      const [context, contextErr] = await orchestrator.initContextFromSessionAndCommit();
      if (contextErr)
        // probably not authenticated
        return this.orchestrator.setRedirectToLoginPage();

      nextContext = context!;
    }

    const profile = nextContext.profile;
    if (profile.showOnboarding)
      return this.orchestrator.setRedirectToOnboardPage();
    else
      return this.orchestrator.setAuthorized();
  }

  tryRunSync(): Result<AuthFlowResponse> {

    const { orchestrator } = this;
    const { state } = orchestrator;

    let currContext = state.getContext();
    if (!currContext?.isAuthenticated)
      return orchestrator.setError(new Error('AuthError', `The user is not authenticated.`));

    const profile = currContext.profile;
    if (profile.showOnboarding)
      return this.orchestrator.setRedirectToOnboardPage();
    else
      return this.orchestrator.setAuthorized();
  }

}