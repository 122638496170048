import { ExternalLibrarySource } from '@clipr/lib';
import { LibraryLabels } from '../../services/libraries/libraryServiceBase';


export const getJobExternalSource = (externalLibrarySource: ExternalLibrarySource): LibraryLabels | null => {
  switch (externalLibrarySource) {
    case 'GoogleDrive':
      return 'Google Drive';
    case 'OneDrive':
      return 'OneDrive';
    case 'Zoom':
      return 'Zoom'
    default:
      return null;
  }
}


