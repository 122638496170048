import React from 'react';
import { observer } from 'mobx-react-lite';
import { MetricsWrapperState } from './metricsWrapperState';
import { MetricCounter } from '../metricCounter';

type Props = {
  model: MetricsWrapperState;
};

export const MetricsWrapper = observer(({ model }: Props) => {
  return (
    <ul className="metric-counters-grid">
      {model.outputMetrics.map((metricCounter, key) => {
        return (
          <li className="grid-item" key={key}>
            <MetricCounter model={metricCounter} />
          </li>
        );
      })}
    </ul>
  );
});
