import { StoreNode } from './storeNode';
import { Nullable } from '../core';

export type MessageMode =
  /** Message that should reach all nodes in the tree. */
  'broadcast' |
  /** Message that should propagate through the parent hierarchy in the tree. */
  'emit' |
  /** Message that should reach the store. */
  'dispatch';

export type Message<TNode extends StoreNode = StoreNode> = {
  type: string,
  payload: any,
  mode: MessageMode,
  fullType: string,
  sender: TNode,
  senderName: Nullable<string>,
  target?: Nullable<string>
};

export type SerializedMessage = {
  type: string,
  payload: any,
  mode?: MessageMode,
  fullType?: string,
  senderName?: string
};

export type MessageHandler<TNode extends StoreNode = StoreNode> =
  (msg: Message<TNode>) => boolean | void | Promise<boolean> | Promise<void>;

export enum MessageHandlerWaitMode {
  None = 'None',
  Serial = 'Serial',
  Parallel = 'Parallel',
  First = 'First'
}

export function createMessage<TNode extends StoreNode = StoreNode>(
  node: StoreNode | null,
  mode: MessageMode,
  type: string,
  payload?: any,
  source?: any): Message<TNode> {

  const prefix = node?.nodeType;
  const msg: Message<TNode> = {
    type,
    payload,
    mode,
    fullType: (prefix ? (prefix + ':') : '') + type,
    // @ts-ignore
    sender: node || null,
    senderName: node?.nodeType || null,
    source: source || null
  };

  return msg;
}

export function serializeMessage(msg: Message) {

  return {
    type: msg.type,
    payload: msg.payload,
    mode: msg.mode,
    fullType: msg.fullType,
    senderName: msg.senderName
  };
}

export function deserializeMessage(data: SerializedMessage) {

  return createMessage(
    null,
    data.mode || 'emit',
    data.type,
    data.payload);
}

export type Memo = {
  type: string;
  payload?: any;
  error?: Error;
}