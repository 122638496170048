import { makeObservable, observable, action, computed } from 'mobx';
import { BindingProps, StoreNode } from '../../../store';
import { Store } from '../../../store/store';
import { input, InputState } from '../../input';
import { AudienceFocusDataSource } from './audienceFocusChartDataSource';
import { AudienceFocusChartInternalParams, AudienceFocusChartMode, AudienceFocusChartParams, AudienceFocusChartData } from './audienceFocusChartSchema';

type Props = BindingProps<{
  params?: AudienceFocusChartParams
}>

export class AudienceFocusChartState
  extends StoreNode {

  readonly nodeType = 'AudienceFocusChartState';
  readonly chartTypeInput: InputState;

  constructor(store: Store, props: Props) {
    super(store, props);
    makeObservable(this);

    this.chartTypeInput = input(this, {
      name: 'audienceFocusTypes',
      onChange: this.handleChartTypeChange,
      selectorItems: [
        { value: AudienceFocusChartMode.Topics, label: 'Topics' },
        { value: AudienceFocusChartMode.SubTopics, label: 'Sub-Topics' },
        { value: AudienceFocusChartMode.MostBookmarkedTopics, label: 'Most Bookmarked Topics' },
        { value: AudienceFocusChartMode.MostBookmarkedSubtopics, label: 'Most Bookmarked Subtopics' },
      ],
    });

    this.chartTypeInput.loadValue(AudienceFocusChartMode.Topics);
  }

  // #region Props
  @computed get params(): AudienceFocusChartParams | null {
    return this.getResolvedProp('params') ?? null;
  }
  set params(val: AudienceFocusChartParams | null) {
    this.setProp('params', val);
  }
  // #endregion

  readonly internalParams: AudienceFocusChartInternalParams = observable({
    mode: AudienceFocusChartMode.Topics
  })

  @computed get outputParams(): AudienceFocusChartParams {
    const outParams = {
      ...this.internalParams,
      ...this.params
    };

    Object.freeze(outParams);
    return outParams;
  }
  
  @computed get outputData(): AudienceFocusChartData {
    return [...this.dataSource.data];
  }
  
  readonly dataSource = new AudienceFocusDataSource(this.store, {
    params: () => this.outputParams
  });

  @observable isMounted = false;

  async mounted() {
    this.isMounted = true;
  }

  unmounted() {
    this.isMounted = false;
  }

  @action
  private handleChartTypeChange = async (input: InputState, evt: React.ChangeEvent) => {

    const mode = input.value as AudienceFocusChartMode;
    if (!mode)
      return;
    this.internalParams.mode = mode;
    this.dataSource.fetch();
  }

  async fetch() {
    return this.dataSource.fetch();
  }
  abort() {
    this.dataSource.cancel();
  }
}