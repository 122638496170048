const WORD_CONFIDENCE_BASE_COLOR = "#0057ff";

const getWordColorMixByConfidence = (confidence: number) => {
  return `color-mix(in srgb, ${WORD_CONFIDENCE_BASE_COLOR} ${100 - confidence}%, #d7e2f8)`;
}

const getMomentColorMixByConfidence = (confidence: number) => {
  return `color-mix(in srgb, #fa2020 ${100 - confidence}%, #fb535338)`;
}

export const getMomentConfidenceThresholdColor = (confidence: number): string | null => {
  if (confidence < 100) {
    return getMomentColorMixByConfidence(confidence);
  }
  return null;
}

export const getWordConfidenceThresholdColor = (confidence: number): string | null => {
  if (confidence < 100) {
    return getWordColorMixByConfidence(confidence);
  }
  return null;
}