import './momentCloud.scss';

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { MomentCloudState } from './momentCloudState';
import { Maybe } from '../../core';
import { FitSVGIcon, MinusSVGIcon, PlusSVGIcon } from './momentCloudSvg';
import { MomentCloudCanvas } from './momentCloudCanvas';

type Props = {
  model?: Maybe<MomentCloudState>
}

export const MomentCloud = observer(({
  model
}: Props) => {

  useEffect(() => {
    model?.mounted();
    return () =>
      model?.unmounted();
  }, [model]);

  if (!model)
    // TODO: add warning  
    return null;

  return (
    <div className="moment-cloud moment-cloud-children">
      <MomentCloudCanvas model={model} />

      <div className="comp-overlays">

        <div className="comp-selector">
          <button className="select-all-btn select-btn"
            onClick={() => model.selectAll()}>
            Select all
          </button>

          <button className="clear-selection-btn select-btn"
            onClick={() => model.unselectAll()}>
            Clear selection
          </button>
        </div>

        <div className="comp-controls">
          {/* <button className="fullscreen-btn control-btn">
            <span className="icon">
              <FullscreenSVGIcon />
            </span>
          </button>

          <div className="separator" /> */}

          <button className="zoom-fit-btn control-btn"
            onClick={evt => model.fitContents()}>
            <span className="icon">
              <FitSVGIcon />
            </span>
          </button>

          <div className="zoom-buttons">
            <button className="zoom-out-btn zoom-btn control-btn"
              onClick={evt => model.zoomOut()}>
              <span className="icon">
                <MinusSVGIcon />
              </span>
            </button>

            <button className="zoom-in-btn zoom-btn control-btn"
              onClick={evt => model.zoomIn()}>
              <span className="icon">
                <PlusSVGIcon />
              </span>
            </button>
          </div>

          <div className="zoom-label">
            {Math.round(model.zoom * 100) + '%'}
          </div>
        </div>

        <div className="comp-empty-message"
          aria-hidden={!model.showEmptyMessage}>
          {model.emptyMessage}
        </div>
      </div>
    </div>
  );
});