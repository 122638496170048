import { action, computed, makeObservable, observable } from 'mobx';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { IPlayerAdapter } from './playerSchema';
import { PlayerAdapterState } from '../playerAdapter';

type Props = BindingProps<{
  adapter: IPlayerAdapter
}>

export class PlayerCaptionState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }
  @observable enabled: boolean = false;

  @computed get adapter(): PlayerAdapterState {
    return this.getResolvedProp('adapter');
  }
  @computed get captionText(): string | TextTrackCue[] | null {
    return this.captionsText ?? null;
  }
  @computed get showCaptions(): boolean {
    return this.showCue ?? false;
  }

  @action
  disableCaptions = () => {
    this.enabled = false;
  }

  @action
  enableCaptions = () => {
    this.enabled = true;
  }

  @action
  toggleCaptions = () => {
    this.enabled = !this.enabled;
  }

  // captions
  @observable captionsText: string | TextTrackCue[] | null = null;
  @observable showCue: boolean = false;
  @observable captionTracks: TextTrack[] = [];
  @observable activeCaptionTrack: TextTrack | null = null;

  @computed get hasCaptions(): boolean {
    return this.captionTracks.length > 0;
  }

  @computed get hasMultipleCaptionTracks(): boolean {
    return this.captionTracks.length > 1;
  }

  reset() {
    // captions
    this.captionsText = null;
    this.showCue = false;
    this.captionTracks = [];
    this.activeCaptionTrack = null;
    this.enabled = false;
    this.deactivateCaptionTrack();
  }

  @action
  initMediaCaptions = () => {
    const video = this.adapter.videoElement;
    if (!video)
      return;
    const captionTracks: TextTrack[] = [];

    for (var i = 0; i < video.textTracks.length; i++) {
      const track = video.textTracks[i];

      if (this.activeCaptionTrack !== track && track.mode !== 'disabled')
        track.mode = 'hidden';

      if (['captions', 'subtitles', 'metadata'].includes(track.kind.toLowerCase()) && track.mode !== 'disabled' && track.label !== 'id3') {
        captionTracks.push(track);
      }

    };

    this.captionTracks = [...new Set(captionTracks)];
  }

  @action
  toggleCaptionTrack() {
    if (this.activeCaptionTrack)
      this.deactivateCaptionTrack();
    else {
      const track = this.captionTracks[0];
      if (track)
        this.activateCaptionTrack(track);
    }
  }

  @action
  activateCaptionTrack = (track: TextTrack) => {
    this.deactivateCaptionTrack();
    if (track.cues) {
      for (const cue of track.cues) {
        // cue.onenter = this.cueEnter;
        cue.onexit = this.cueExit;
      }
    }
    track.oncuechange = () => this.handleCueChange(track);

    this.hideCaptionTracks();
    this.showCaptionTrack(track);
    this.activeCaptionTrack = track;

    this.handleCueChange(track); // in order to instantly update the current cue

    this.ensureActiveHlsSubtitleTrack(track);
  }

  @action
  ensureActiveHlsSubtitleTrack(track: TextTrack) {
    const hls = this.adapter.hls;

    if (!hls)
      return;

    const subtitleTracks = hls.subtitleTracks ?? [];

    if (track.id !== 'transcript') {
      const currentTrack = subtitleTracks.find(subTrack => subTrack.id.toString() === track.id);
      if (currentTrack)
        hls.subtitleTrack = currentTrack.id;
      else
        hls.subtitleTrack = -1;
    }

  }

  @action
  clearActiveHlsSubtitleTrack() {
    const hls = this.adapter.hls;

    if (!hls || hls?.subtitleTrack < 0)
      return;

    hls.subtitleTrack = -1;
  }

  @action
  deactivateCaptionTrack = () => {
    const track = this.activeCaptionTrack;
    if (!track)
      return;

    if (track.cues) {
      for (const cue of track.cues) {
        cue.onenter = null;
        cue.onexit = null;
      }
    }
    track.oncuechange = null;
    this.activeCaptionTrack = null;
    this.showCue = false;
    this.captionsText = null;
    this.hideCaptionTracks();

    this.clearActiveHlsSubtitleTrack();
  }

  @action
  hideCaptionTracks() {
    this.captionTracks.forEach(track => track.mode = 'hidden');
  }

  @action
  showCaptionTrack(track: TextTrack) {
    track.mode = 'showing';
  }

  @action
  handleCueChange = (track: TextTrack) => {
    this.captionsText = [];
    if (track.activeCues) {
      Object.values(track.activeCues).forEach(cue => {
        if (Array.isArray(this.captionsText))
          this.captionsText.push(cue as TextTrackCue);
      })
    }

    if (this.captionsText.length === 0)
      this.captionsText = null;

    if (this.captionsText) {
      this.showCue = true;
    }
  }

  @action
  onCaptionTrackLoad = (track: TextTrack) => {
    track.mode = 'hidden';
    const cues = track.cues;

    if (cues) {
      for (const cue of cues) {
        // cue.onenter = this.cueEnter;
        cue.onexit = this.cueExit;
      }
    }
  }

  @action
  cueEnter = (event: any) => {
    this.showCue = true;
    this.captionsText = event?.target.text;
  }

  @action
  cueExit = () => {
    this.showCue = false;
    this.captionsText = null;
  }
}