import './momentWindow.scss';
import '../overlays/overlays.scss';
import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import toInteger from 'lodash/toInteger';
import { Duration } from 'luxon';
import { ThrashSVGIcon, ClockSVGIcon, PlusSVGIcon } from '../svg';
import {
  Button,
  SliderInputControlled,
  TextInputControlled,
  CheckboxInputControlled,
  TimepointInputControlled,
  DropdownInputControlled,
} from '../input';
import { Maybe } from '../../core';
import { ClipWindowState } from './clipWindowState';
import { InfoButton } from '../infoModal';
import { Window, WindowHeader } from '../overlays';

const duration = (timestamp: Maybe<number>) =>
  Duration.fromObject({ seconds: timestamp || 0 }).toFormat('hh:mm:ss');

type Props = {
  visible: boolean;
  model: ClipWindowState;
};

export const ClipWindow = observer(
  ({
    visible,
    model
  }: Props) => {

    if (!model) return null;

    const startTime = toInteger(model.startTime.value);
    const endTime = toInteger(model.endTime.value);
    const className = classNames("moment-window");

    return (
      <Window
        className={className}
        model={model.window}
        onExited={model.onTransitionEnd}
        visible={visible}
        isLoading={model.isLoading}>
        <WindowHeader
          model={model.window}
          title={model.isCreateMode ?
            `Add new ${model.momentKindLabel}` :
            `Edit ${model.momentKindLabel}`}
          info={!model.isExpanded && (
            <div className="moment-interval">
              <span className="start-time">{duration(startTime)}</span>
              <span className="separator">-</span>
              <span className="end-time">{duration(endTime)}</span>
            </div>
          )} />
        <hr />
        <form className="window-content window-form form">
          <ClipFormBasic model={model} />

          {model.isExpanded && <ClipFormExtension model={model} />}
        </form>

        <hr />

        <footer className="window-footer moment-buttons">
          <div className="col left">
            <div className="btn-group left gap2">
              {model.isExpanded && model.source !== 'User' && (
                <CheckboxInputControlled
                  className="medium"
                  model={model.visibleToConsumer}
                  label={`Show ${model.momentKindLabel} on consumer product`}
                />
              )}
              {model.isEditMode && (
                <Button
                  className="delete-btn transparent"
                  label="Delete"
                  leftIcon={<ThrashSVGIcon />}
                  disabled={model.inputGroup.isSubmitting}
                  onClick={() => model.delete()}
                />
              )}
            </div>
            {model.source !== 'User' &&
              model.trackModel &&
              !model.trackModel?.visibleToConsumer && (
                <div className="notification-box">
                  <div className="error-notification">
                    Swimlane hidden!
                  </div>
                </div>
              )}
          </div>

          <div className="col right">
            <div className="btn-group">
              <Button
                label="Cancel"
                className="primary medium btn-outline form-cancel-btn"
                onClick={() => model.cancel()}
                disabled={model.inputGroup.isSubmitting}
              />

              <Button
                label="Save"
                className="primary medium form-submit-btn"
                onClick={() => model.submit()}
                disabled={
                  model.inputGroup.isSubmitDisabled ||
                  model.inputGroup.isSubmitting ||
                  !model.inputGroup.isDirty
                } />
            </div>
          </div>
        </footer>
      </Window>
    );
  }
);

type ClipFormBasic = {
  model: ClipWindowState;
};

// eslint-disable-next-line  no-redeclare
export const ClipFormBasic = observer(({ model }: ClipFormBasic) => {
  return (
    <>
      <div className="form-grid gap6">
        <DropdownInputControlled
          model={model.clipType}
          placeholder={`Select ${model.momentKindLabel} type`}
          label={`${model.momentKindLabel} type*`}
          disabled={model.layout === 'User' || model.job?.isEnrichmentProcessing} />

        <div className="form-flex">
          <TimepointInputControlled
            model={model.startTime}
            min={0}
            label="Start"
            placeholder="Start Time" />
          <span className="input-conjuction">to</span>
          <TimepointInputControlled
            model={model.endTime}
            min={0}
            label="End"
            placeholder="End time" />
        </div>
      </div>
      <div className="notification-box">
        {model.showErrorNotification && (
            <div className="error-notification">
              {model.clipBasicInputGroup?.error}
            </div>
          )}
      </div>
    </>
  );
});

type ClipFormExtensionProps = {
  model: ClipWindowState;
};

export const ClipFormExtension = observer(
  ({ model }: ClipFormExtensionProps) => {
    const { inputs } = model;

    if (!model || !inputs) return null;

    return (
      <>
        {model.shouldHaveParentClip && model.parent && (
          <div className="form-row form-grid gap6">
            <div className="row-left">
              <div className="label">Parent of {model.momentKindLabel}</div>
              <div className="text">
                {model.parent?.clipType}: <span>{model.parent?.name}</span>
              </div>
            </div>
            <div className="row-right">
              <span className="icon">
                <ClockSVGIcon />
              </span>
              <span className="start-time">
                {duration(model.parent?.startTimeNorm)}
              </span>
              <span className="separator">-</span>
              <span className="end-time">
                {duration(model.parent?.endTimeNorm)}
              </span>
            </div>
          </div>
        )}
        {model.shouldHaveExtractor && model.editModel && (
          <div className="form-row form-grid gap6">
            <div className="row-left">
              <div className="label">Source</div>
              <div className="text">
                {model.editModel.extractor ? (
                  <span>{model.editModel.extractor}</span>
                ) : (
                  <span>Not Available</span>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="form-grid main">
          {model.inputs?.map((val, i) => getInput(val, model, i))}
        </div>

        <TextInputControlled
          model={model.description}
          label={
            model.clipType.value === 'Transcript'
              ? 'Description*'
              : 'Description'
          }
          placeholder="Enter description" />

        <TextInputControlled
          model={model.summary}
          label="Summary"
          placeholder="Enter summary" />
      </>
    );
  }
);

const getInput = (inputName: string, model: ClipWindowState, i: number) => {
  switch (inputName) {
    case 'name':
      const type = model.clipType.value;
      return (
        <TextInputControlled
          model={model.name}
          label={`${type} Name` + (model.name.isRequired ? `*` : null)}
          showCharacterCount
          placeholder="Enter name"
          key={i} />);
    case 'momentType':
      return (
        <DropdownInputControlled
          model={model.momentType}
          placeholder="Select moment type"
          label="Moment type*"
          key={i} />);
    case 'sentiment':
      return (
        <DropdownInputControlled
          model={model.sentiment}
          placeholder="Select sentiment"
          label="Sentiment"
          key={i} />);
    case 'speaker':
      return (
        <div className="input-block-wrapper" key={i}>
          <DropdownInputControlled
            model={model.speaker}
            placeholder="Select speaker"
            label={model.clipType.value === 'Transcript' ? 'Speaker*' : 'Speaker'}
            key={i}
            withSearch={true}
            notDeselectable={model.clipType.value === 'Transcript'} />
          {model.isTrainerLayout && <Button
            leftIcon={<PlusSVGIcon />}
            onClick={() => model.openSpeakerWindow()} />}
        </div>);
    case 'keywords':
      return (
        <TextInputControlled
          model={model.keywords}
          label="Keywords"
          placeholder="Enter keywords"
          key={i} />);
    case 'importance':
      const importanceValue = model.importance.value ?? 50;
      return (
        <div className="importance-slider" key={i}>
          <div className="range-value">
            <span className="text" style={{ left: `${importanceValue}%` }}>
              {importanceValue}
            </span>
          </div>
          <SliderInputControlled
            model={model.importance}
            label="Importance"
            infoButton={
              <InfoButton
                className="light-theme"
                size="small"
                title="Importance"
                content={<ImportanceInfoContent />} />} />
          <div className="range-labels">
            <span className="text">Least</span>
            <span className="text">Most</span>
          </div>
        </div>);
    // case 'description':
    //   return
    //   <TextInputControlled
    //     model={model.description}
    //     feedbackPosition="topEnd"
    //     label="Description"
    //     placeholder="Enter description" />

    case 'language':
      return (
        <DropdownInputControlled
          label="Language"
          placeholder="Select Default Language"
          model={model.language}
          key={i} />);

    case 'track':
      return (
        <div className="input-block-wrapper" key={i}>
          <DropdownInputControlled
            label="Track*"
            placeholder="Select Track"
            model={model.track}
            notDeselectable
            key={i} />
          <Button
            leftIcon={<PlusSVGIcon />}
            onClick={() => model.openTrackWindow()} />
        </div>);
    default:
      return null;
  }
};

const ImportanceInfoContent = () => (
  <>
    <p>
      Help train CLIPr's AI and machine learning to deliver more accurate
      results.
    </p>
    <br />
    <ul>
      <li>
        How important is this moment, compared to the others in this video?
      </li>
      <li>Use the slider to rate it.</li>
    </ul>
  </>
);
