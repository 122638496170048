import { StartJobFeaturesInput } from '@clipr/lib';
import { action, computed, IObservableArray, makeObservable } from 'mobx';
import { createArray } from '../../core/array';
import { generateJobLevelOutput, getDefaultEnrichmentLevel, getDefaultJobLevelInput, getEnrichmentLevelLabel, JobLevel } from '../../entities/job/jobFeatures';
import { BindingProps } from '../../store/propManager';
import { Store } from '../../store/store';
import { StoreNode } from '../../store/storeNode';
import { inputGroup } from '../input/inputGroupState';
import { input, InputState } from '../input/inputState';

type EnrichmentLevelStateProps = BindingProps<{
  teamId?: string;
}>;

export class TeamEnrichmentLevelState
  extends StoreNode {

  disabled = (input: InputState): boolean => {
    if (input.name === 'media')
      return true;

    if (input.name === 'transcript'
      //@ts-ignore
        && this.automaticEnrichmentInput.normValue === true) {
        //@ts-ignore
        this.transcriptEnrichmentInput.value = true;
        return true;
      }

    if (input.name !== 'manualEnrichment'
      //@ts-ignore
      && this.manualEnrichmentInput.normValue === true) {
      //@ts-ignore
      this.automaticEnrichmentInput.value = true;
      return true;
    }

    return false;
  }

  constructor(store: Store, props?: EnrichmentLevelStateProps) {
    super(store, props);
    makeObservable(this);
  }

  @computed get teamId(): string {
    return this.resolvedProps.teamId;
  }

  @computed get team() {
    return this.store.teamManager.getTeam(this.teamId);
  }

  readonly enrichmentLevelItems: IObservableArray<InputState>
    = createArray<InputState>(true) as IObservableArray;

  @computed get automaticEnrichmentInput(): InputState {
    return this.enrichmentLevelItems.find(input => input.name === 'automaticEnrichment')!;
  }

  @computed get manualEnrichmentInput(): InputState {
    return this.enrichmentLevelItems.find(input => input.name === 'manualEnrichment')!;
  }

  @computed get transcriptEnrichmentInput(): InputState {
    return this.enrichmentLevelItems.find(input => input.name === 'transcript')!;
  }

  readonly form = inputGroup(this, {
    name: 'enrichmentLevelForm',
    inputs: () => {
      return [
        ...this.enrichmentLevelItems
      ]
    }
  });

  init() {
    JobLevel.forEach(item => {
      const defaultValue = this.team?.enrichmentLevel ?? getDefaultEnrichmentLevel();
      const defaultValueObject = generateJobLevelOutput(defaultValue);
      const value = defaultValueObject[item];
      const inputState = input(this, {
        name: item,
        label: getEnrichmentLevelLabel(item),
        disabled: (input: InputState) => this.disabled(input),
      });
      inputState.loadValue(value);
      this.enrichmentLevelItems.push(inputState);
    });
  }

  reset() {
    this.enrichmentLevelItems.clear();
  }

  export(): StartJobFeaturesInput {
    return this.exportEnrichmentLevel();
  }

  @action
  private exportEnrichmentLevel(): StartJobFeaturesInput {
    const enrichmentLevelData = this.form.export();
    const defaultOptions = getDefaultJobLevelInput();

    return { ...defaultOptions, ...enrichmentLevelData };
  }
}