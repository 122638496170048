import KeenTracking from 'keen-tracking';
import { StoreNode } from '../../../store';
import { Store } from '../../../store/store';
import { AnalyticsStreamData, IAnalyticsProvider } from '../analyticsSchema';
import { Result } from '../../../core';
import { Error } from '../../../core/error';

const ENABLED = process.env.REACT_APP_ENABLE_KEEN?.toLocaleLowerCase() === 'true';

type Props = {
  projectId: string;
  writeKey: string;
}

export class KeenAnalyticsProvider
  extends StoreNode
  implements IAnalyticsProvider {

  static providerName = 'KeenAnalyticsProvider'

  readonly client: KeenTracking | null;
  readonly projectId: string
  readonly writeKey: string

  constructor(store: Store, props: Props) {
    super(store, props);

    this.projectId = props.projectId;
    this.writeKey = props.writeKey;
    this.client = this.init();
  }

  // Configure a client instance
  private init() {
    if (!ENABLED)
      return null;

    return new KeenTracking({
      projectId: this.projectId,
      writeKey: this.writeKey
    });
  }

  async registerEvent<EventKey extends keyof AnalyticsStreamData>(type: EventKey, payload: AnalyticsStreamData[EventKey]) {
    if (!this.client)
      return;

    try {
      return await this.client.recordEvent(type, payload as Object)
    } catch (e) {
      //console.error(e);
    }
  }

  sendBeacon<TKey extends keyof AnalyticsStreamData>(type: TKey, payload: AnalyticsStreamData[TKey]): Result<boolean, Error> {
    const { network } = this.store;
    const url = this.buildWriteApiUrl(type);
    const data = payload;

    return network.sendBeacon(url, data);
  }

  private buildWriteApiUrl<EventKey extends keyof AnalyticsStreamData>(type: EventKey) {
    return `https://api.keen.io/3.0/projects/${this.projectId}/events/${type}?api_key=${this.writeKey}`;
  }
}
