import { NotificationsSubscription, NotificationSubscriptionEmailEventType, NotificationSubscriptionSubscribedEvent, UpdateNotificationSubscriptionInput } from '@clipr/lib';
import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import validator from 'validator';
import { input, inputGroup, InputState } from '../../components';
import { createArray } from '../../core/array';
import { Store } from '../../store/store';
import { StoreNode } from '../../store/storeNode';
import { BindingProps } from '../../store';

type Props = BindingProps<{
  userNotificationSubscriptions: NotificationsSubscription[];
  notificationSubscriptionsTypeEmail?: NotificationSubscriptionEmailEventType[] | null;
}>
export class SubscribedEventsState
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  readonly notificationSubscriptionItems: IObservableArray<InputState>
    = createArray<InputState>(true) as IObservableArray;

  readonly form = inputGroup(this, {
    name: 'notificationSubscriptionForm',
    inputs: () => {
      return [
        ...this.notificationSubscriptionItems
      ]
    }
  });

  @computed get notificationSubscriptionsTypeEmail(): NotificationSubscriptionEmailEventType[] | null {
    return this.resolvedProps.notificationSubscriptionsTypeEmail ?? null;
  }

  @computed get userNotificationSubscriptions(): NotificationsSubscription[] | null {
    return this.resolvedProps.userNotificationSubscriptions;
  }

  @observable notificationSubscriptionId: string | null = null;

  @action
  init() {
    const userSubscriptions: NotificationsSubscription | undefined =
      this.userNotificationSubscriptions?.find((value: NotificationsSubscription) =>
        validator.isEmail(value.destination)
      );

    this.notificationSubscriptionId = userSubscriptions?.id ?? null;
    this.notificationSubscriptionsTypeEmail?.forEach(item => {
      const inputState = input(this, {
        name: item.value,
        label: item.name,
        onChange: this.onChange
      });
      userSubscriptions?.subscribedEvents?.find((event: NotificationSubscriptionSubscribedEvent) => event === item.value) ?
        inputState.loadValue(true) :
        inputState.loadValue(false);

      this.notificationSubscriptionItems.push(inputState);
    });
  }

  @action
  reset() {
    this.notificationSubscriptionItems.clear();
  }

  @action
  onChange = () => {
    this.emit('notificationPreferencesChanged');
  }

  export(): UpdateNotificationSubscriptionInput {
    return this.exportNotificationSubscriptions();
  }

  @action
  private exportNotificationSubscriptions(): UpdateNotificationSubscriptionInput {
    let outputData: UpdateNotificationSubscriptionInput = { id: '', subscribedEvents: [] };

    const notificationSubscriptions = this.form.export();
    const subscribedEvents: NotificationSubscriptionSubscribedEvent[] = [];

    Object.keys(notificationSubscriptions).forEach(key => {
      const field = notificationSubscriptions[key];
      if (field === true) {
        subscribedEvents.push(key as NotificationSubscriptionSubscribedEvent);
      }
    });

    outputData = { ...outputData, subscribedEvents, id: this.notificationSubscriptionId || '' }

    return outputData;
  }
}