import { Maybe, Result } from "../../core"
import { Message } from "../../store"
import { JobModel } from "../../entities";
import { AnalyticsMainData, AnalyticsCurrentJobData } from "./stream/analyticsStream"
import { AnalyticsCommentInputStreamParams, AnalyticsCommentInputStreamData } from "./stream/analyticsCommentInputStream"
import { AnalyticsPlayerReactionStreamParams, AnalyticsPlayerReactionStreamData } from "./stream/analyticsPlayerReactionStream"
import { AnalyticsPlayerStreamParams, AnalyticsPlayerStreamData } from "./stream/analyticsPlayerStream"
import { AnalyticsClipBookmarkStreamParams, AnalyticsClipBookmarkStreamData } from "./stream/analyticsClipBookmarkStream"
import { AnalyticsPageViewStreamParams, AnalyticsPageViewStreamData } from "./stream/analyticsPageViewStream"
import { AnalyticsVideoShareStreamData, AnalyticsVideoShareStreamParams, AnalyticsVideoUploadStreamData, AnalyticsVideoUploadStreamParams } from "./stream"
import { Error } from '../../core/error';
import { AnalyticsPlayerMetricsStreamData, AnalyticsPlayerMetricsStreamParams } from './stream/analyticsPlayerMetricsStream';

export interface IAnalyticsProvider {
  registerEvent<EventKey extends keyof AnalyticsStreamData>(type: EventKey, payload: AnalyticsStreamData[EventKey]): any
  sendBeacon<TKey extends keyof AnalyticsStreamData>(type: TKey, payload: AnalyticsStreamData[TKey]): Result<boolean, Error>
}
export interface IAnalyticsStream<EventKey extends keyof AnalyticsStreamParams> {
  exchangeData(payload: AnalyticsStreamParams[EventKey] | Message | Object): AnalyticsStreamData[EventKey] | null
}

export type AnalyticsProviders = {
  [id: string]: IAnalyticsProvider;
}

export type AnalyticsStreamsList = {
  [id: string]: IAnalyticsStream<keyof AnalyticsStreamParams>;
}

export enum AnalyticsEventTypes {
  AuthType = 'authentication',
  PageViewType = 'pageView',
  VideoMakePublicType = 'videoMakePublic',
  UserAddToTeamType = 'userAddToTeam',
  MomentCreatedType = 'momentCreated',
  MomentEditedType = 'momentEdited',
  ClipCreatedType = 'clipCreated',
  ClipEditedType = 'clipEdited',
  VideoAddToTeamType = 'videoAddToTeam',
  VideoUploadType = 'videoUpload',
  VideoBookmarkType = 'videoBookmark',
  ClipBookmarkType = 'clipBookmark',
  PlayerType = 'player',
  PlayerReactionType = 'playerReaction',
  CommentInputType = 'commentInput',
  VideoShareType = 'videoShare',
  PlayerMetrics = 'playerMetrics'
}

export type AnalyticsStreamParams = {
  [AnalyticsEventTypes.AuthType]: AuthPayload,
  [AnalyticsEventTypes.PageViewType]: AnalyticsPageViewStreamParams,
  [AnalyticsEventTypes.UserAddToTeamType]: UserAddToTeamPayload,
  [AnalyticsEventTypes.VideoMakePublicType]: VideoMakePublicParams,
  [AnalyticsEventTypes.MomentCreatedType]: MomentCreatedParams,
  [AnalyticsEventTypes.MomentEditedType]: MomentEditedParams,
  [AnalyticsEventTypes.ClipCreatedType]: ClipCreatedParams,
  [AnalyticsEventTypes.ClipEditedType]: ClipEditedParams,
  [AnalyticsEventTypes.VideoAddToTeamType]: VideoAddToTeamParams,
  [AnalyticsEventTypes.VideoUploadType]: AnalyticsVideoUploadStreamParams,
  [AnalyticsEventTypes.VideoBookmarkType]: VideoBookmarkParams,
  [AnalyticsEventTypes.ClipBookmarkType]: AnalyticsClipBookmarkStreamParams,
  [AnalyticsEventTypes.PlayerType]: AnalyticsPlayerStreamParams,
  [AnalyticsEventTypes.PlayerReactionType]: AnalyticsPlayerReactionStreamParams,
  [AnalyticsEventTypes.CommentInputType]: AnalyticsCommentInputStreamParams,
  [AnalyticsEventTypes.VideoShareType]: AnalyticsVideoShareStreamParams,
  [AnalyticsEventTypes.PlayerMetrics]: AnalyticsPlayerMetricsStreamParams
}

export type AnalyticsStreamData = {
  [AnalyticsEventTypes.AuthType]: AuthPayload,
  [AnalyticsEventTypes.PageViewType]: AnalyticsPageViewStreamData,
  [AnalyticsEventTypes.UserAddToTeamType]: UserAddToTeamPayload,
  [AnalyticsEventTypes.VideoMakePublicType]: VideoMakePublicData,
  [AnalyticsEventTypes.MomentCreatedType]: MomentCreatedData,
  [AnalyticsEventTypes.MomentEditedType]: MomentEditedData,
  [AnalyticsEventTypes.ClipCreatedType]: ClipCreatedData,
  [AnalyticsEventTypes.ClipEditedType]: ClipEditedData,
  [AnalyticsEventTypes.VideoAddToTeamType]: VideoAddToTeamData,
  [AnalyticsEventTypes.VideoUploadType]: AnalyticsVideoUploadStreamData,
  [AnalyticsEventTypes.VideoBookmarkType]: VideoBookmarkData,
  [AnalyticsEventTypes.ClipBookmarkType]: AnalyticsClipBookmarkStreamData,
  [AnalyticsEventTypes.PlayerType]: AnalyticsPlayerStreamData,
  [AnalyticsEventTypes.PlayerReactionType]: AnalyticsPlayerReactionStreamData,
  [AnalyticsEventTypes.CommentInputType]: AnalyticsCommentInputStreamData,
  [AnalyticsEventTypes.VideoShareType]: AnalyticsVideoShareStreamData,
  [AnalyticsEventTypes.PlayerMetrics]: AnalyticsPlayerMetricsStreamData
}

export type AnalyticsStreamName = keyof AnalyticsStreamData;

type AuthPayload = {
  source?: string,
  type:
  | 'register'
  | 'login'
  | 'logout'
}
  & AnalyticsMainData

type UserAddToTeamPayload = {
  teamId: string | undefined
}
  & AnalyticsMainData

type VideoMakePublicParams = {
  jobId: string | null,
  job: JobModel
}
type VideoMakePublicData = {
  jobId: string | null
}
  & AnalyticsCurrentJobData
  & AnalyticsMainData

type MomentCreatedParams = {
  jobId: string | null,
  momentId: string | null | undefined,
  job: JobModel
}
type MomentCreatedData = {
  jobId: string | null,
  momentId: string | null | undefined
}
  & AnalyticsCurrentJobData
  & AnalyticsMainData

type MomentEditedParams = {
  jobId: string | null,
  momentId: string | null | undefined,
  job: JobModel
}
type MomentEditedData = {
  jobId: string | null,
  momentId: string | null | undefined
}
  & AnalyticsCurrentJobData
  & AnalyticsMainData

type ClipCreatedParams = {
  momentId: string | null | undefined,
  job: JobModel
}
type ClipCreatedData = {
  momentId: string | null | undefined
}
  & AnalyticsCurrentJobData
  & AnalyticsMainData

type ClipEditedParams = {
  momentId: string | null | undefined,
  job: JobModel
}
type ClipEditedData = {
  momentId: string | null | undefined
}
  & AnalyticsCurrentJobData
  & AnalyticsMainData

type VideoAddToTeamParams = {
  jobId: Maybe<string>,
  teamId: string
}
type VideoAddToTeamData = {
  jobId: Maybe<string>,
  teamId: string
}
  & AnalyticsMainData

type VideoBookmarkParams = {
  job: JobModel
}
type VideoBookmarkData = AnalyticsMainData & AnalyticsCurrentJobData

export enum AnalyticsEventTriggerType {
  ExitPage = 'ExitPage', 
  CloseTab = 'CloseTab'
}