import { computed, makeObservable } from 'mobx';
import omit from 'lodash/omit';
import { DateTime } from 'luxon';
import { RawDraftContentState } from 'draft-js';
import { Comment as ApiCommentProps, UserStub as ApiCommentUserStub, WatchMode } from '@clipr/lib';
import { MaybePartial } from '../core';
import { timeLabel } from '../components/utils';
import { Store } from '../store/store';
import { StoreNode } from '../store/storeNode';
import { JobModel } from './job';
import { IJobDependency } from './jobSchema';
import { User } from './user';
import { PermissionType } from './permission';
import { InputPlayerReactionName } from '../components/playerReactions/playerReactionSchema';

export type CommentProps = Omit<ApiCommentProps, '__typename'>;

export type CommentUserStub = Omit<ApiCommentUserStub, '__typename'>;

export type Reaction = Comment & {
  reaction: InputPlayerReactionName | string;
}

export type ReactionProps = CommentProps & {
  reaction: InputPlayerReactionName | string;
}

export class Comment
  extends StoreNode
  implements IJobDependency {

  readonly nodeType: 'Comment' = 'Comment';

  constructor(store: Store, props?: MaybePartial<CommentProps>) {
    super(store, props);
    makeObservable(this);

    // TODO: discuss if we should auto-assign the ID in the StoreNode constructor and if this
    // will cause any issues
    // @ts-ignore
    this.id = props?.id;

    // TODO: discuss if this is better than Object.assign
    // for this entity if you just use Object.assign you will run into a problem because
    // props.user will be the actual stub (naming issue) while this.user is the actual User entity
    // retrieved from the store
    // so to prevent situations like this it might be better to explicitly assign the props
    this.jobId = props?.jobId ?? null;
    this.userId = props?.userId ?? null;
    this.videoTime = props?.videoTime ?? null;
    this.createdAt = props?.createdAt ?? null;
    this.parentId = props?.parentId ?? null;
    this.text = props?.text ?? null;
    this.watchMode = props?.watchMode ?? null;
    this.reaction = (props?.reaction as InputPlayerReactionName) ?? null;

    this.userStub = props?.user ?? null;
    this.userStubAsUser = new User(this.store, omit(props?.user!, '__typename'));
  }

  readonly jobId: string | null = null;
  readonly userId: string | null = null;
  readonly videoTime: number | null = null;
  readonly createdAt: string | null = null;
  readonly parentId: string | null = null;
  readonly text: string | null = null;
  readonly watchMode: WatchMode | null = null;
  readonly reaction: InputPlayerReactionName | null = null;

  readonly userStub!: CommentUserStub | null;
  readonly userStubAsUser: User | null;

  @computed
  get user(): User | null {
    return this.store.userManager.getUser(this.userId) ?? null;
  }

  /** Returns the Job instance from the store if it has been loaded 
   * or the current value of `.jobStubAsJob` otherwise.
   * This is the property you should be using to access the Job associated with the Bookmark
   * because it offers the highest chance of returning you something.
   */
  @computed
  get actualUser(): User | null {
    return this.user ?? this.userStubAsUser ?? null;
  }

  @computed
  get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId);
  }

  @computed
  get isVisibleToUser() {
    return true;
  }

  @computed
  get createdAtDate(): DateTime | null {
    if (!this.createdAt)
      return null;
    return DateTime.fromISO(this.createdAt);
  }

  @computed
  get videoTimeRatio(): number | null {
    const time = this.videoTime;
    const jobDur = this.job?.videoDuration ?? null;

    if (!Number.isFinite(time) || !jobDur)
      return null;

    return time! / jobDur;
  }

  @computed
  get parent(): Comment | null {
    return this.job?.getComment(this.parentId) ?? null;
  }

  @computed
  get content(): RawDraftContentState | null {
    if (!this.text)
      return null;

    try {
      return JSON.parse(this.text);
    } catch (e) {
      return null;
    }
  }

  @computed
  get isReply(): boolean {
    return !!this.parentId;
  }

  @computed
  get isReaction(): boolean {
    return !!this.reaction;
  }

  @computed
  get isComment(): boolean {
    return !this.isReaction;
  }

  @computed
  get videoTimeLabel(): string {
    return timeLabel(this.videoTime ?? 0);
  }

  @computed
  get isStatic(): boolean {
    return this.watchMode === WatchMode.Static;
  }

  @computed
  get isLive(): boolean {
    return this.watchMode === WatchMode.Live;
  }

  hasPermission(type: PermissionType): boolean {
    switch (type) {
      case 'EditComment':
        return this.job?.hasPermission('EditAnyComment') || this.userProfileIsOwner;
      case 'DeleteComment':
        return this.job?.hasPermission('DeleteAnyComment') || this.userProfileIsOwner;
      case 'AddReply':
        return this.job?.hasPermission('AddComment') ?? false;
    }

    console.warn(`PermissionType '${type}' is not valid for a Comment entity.`);
    return false;
  }

  @computed
  get userProfileIsOwner(): boolean {
    return this.store.user?.id === this.userId;
  }
}