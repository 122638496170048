import './player.scss';
import '../playerShared/playerComponents.scss';

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { PlayerAdapter } from '../playerAdapter';
import { PlayerState } from './playerState';
import { PlayerMasks } from './components';
import { PlayerChromeBottom } from './components/playerChromeBottom';
import { PlayerChromeTop } from './components/playerChromeTop';
import { UserPlayerPoster } from './components/playerPoster';
import { PlayerLiveWaitingScreen } from './components/playerLiveWaitingScreen';
import { PlayerLiveEndedScreen } from './components/playerLiveEndedScreen';
import { PlayerChromeLeft } from './components/playerChromeLeft';
import { PlayerReactionAnimations } from '../playerReactionAnimations/playerReactionAnimations';
import { PlayerLiveNotStreamingScreen } from './components/playerLiveNotStreamingScreen';
import { PlayerAdsAdapter } from '../playerAdsAdapter';

type Props = {
  model: PlayerState,
}

export const Player = observer((props: Props) => {
  const { model } = props;
  const {
    selector,
    job,
    showLiveWaitingScreen,
    showLiveEndedScreen,
    showLiveNotStreamingScreen
  } = model;
  const chrome = model.chrome;
  const widget = model.store.widget;

  let chromeVis = chrome.visibility;
  if (model.isEditMode)
    chromeVis = 'Visible';

  const className = classNames('user-player', {
    'playing': !model.isPaused,
    'paused': model.isPaused,
    'video-mode': selector?.isVideoModeEnabled,
    'topics-mode': selector?.mode === 'Topics',
    'speakers-mode': selector?.mode === 'Speakers',
    'edit-mode': model.isEditMode,
    'chrome-visible': chromeVis === 'Visible',
    'chrome-hidden': chromeVis === 'Hidden',
    'chrome-compact': chromeVis === 'Compact',
    'chrome-hover': chrome.isHovering,
    'video-hover': chrome.isVideoHovering,
    'region-hover': chrome.isRegionHovering,
    'show-marker-bar': model.itemSource.markerBarItems.length > 0,
    'show-status-bar': widget.isWidgetMode,
    'optional-explorer': model.frameset?.isSectionVisible('Index') && !model.isFullscreen,
    'live-waiting': job?.isLiveStreamWaiting,
    'live-active': job?.isLiveStreaming,
    'live-ended': job?.isLiveStreamProcessing,
    'ads-active': model.isAdsAdapterActive
  });

  useEffect(() => {
    model.mounted();
    return () =>
      model.unmounted();
  }, [model]);

  const showPlayer = !showLiveWaitingScreen && !showLiveEndedScreen && !showLiveNotStreamingScreen;

  return (
    <div id="user-player"
      data-fullscreen-mode={model.isFullscreen}
      className={className}
      onPointerMove={chrome.handlePointerMove}
      onTouchStart={chrome.handleTouchStart}
      ref={model.componentRef.ref}
      data-test-ad-tag-url={model.adsAdapter.adTagUrl}>

      {showLiveWaitingScreen &&
        <PlayerLiveWaitingScreen
          model={model} />}

      {showLiveEndedScreen &&
        <PlayerLiveEndedScreen
          job={job}
          model={model} />}

      {showLiveNotStreamingScreen &&
        !showLiveEndedScreen &&
        !showLiveWaitingScreen &&
        <PlayerLiveNotStreamingScreen
          model={model} />}

      {showPlayer &&
        <>
          <div id="user-player-video"
            onClick={chrome.handleVideoClick}
            onDoubleClick={chrome.handleVideoDoubleClick}
            onPointerEnter={chrome.handleVideoPointerEnter}
            onPointerLeave={chrome.handleVideoPointerLeave}>

            <PlayerAdapter
              model={model.adapter} />
            <PlayerAdsAdapter
              model={model.adsAdapter} />
          </div>

          <UserPlayerPoster
            model={model} />

          <PlayerMasks
            model={model} />

          <div id="user-player-gradient-top" />

          {model.showLiveReactions &&
            <PlayerReactionAnimations
              model={model.reactionAnimations} />}

          <PlayerChromeTop
            model={model} />

          {
            model.isLiveStream &&
            !model.suppressLiveReactions &&
            <PlayerChromeLeft
              model={model} />}

          <div id="user-player-gradient-bottom" />
          <PlayerChromeBottom
            model={model} />
        </>
      }
    </div>
  );
});