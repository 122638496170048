import { Industry, JobSpeciality, Maybe, Metadata, JobType } from '@clipr/lib';
import { makeObservable } from 'mobx';
import { Store } from '../../store/store';
import { StoreNode } from '../../store/storeNode';
import { JobLevel } from './jobFeatures';

export type JobStubProps = {
  id?: string
  title?: string;
  description?: string;
  languageCode?: string;
  speciality?: JobSpeciality;
  isPublic?: boolean;
  thumbnail?: File;
  enrichmentLevel?: JobLevel;
  industry?: string;
  metadata?: Maybe<Metadata>;
  /** @todo convert to string[] */
  keywords?: string;
  /** @todo convert to string[] */
  tags?: string;
  sortOrder?: number;
  posterURL?: string;
  posterToken?: string;
  videoType?: JobType;
}

/**
 * Represents a Job object which is in the process of being created by the user.
 */

export class JobStub
  extends StoreNode {

  constructor(props: Partial<JobStubProps>, store: Store) {
    super(store);
    makeObservable(this);

    Object.assign(this, props);
  }

  readonly nodeType: 'Job' = 'Job';

  readonly id!: string;
  readonly languageCode!: string;
  readonly lastView!: string;
  readonly title!: string;
  readonly description!: string;
  readonly isPublic!: boolean;
  readonly speciality?: JobSpeciality;
  readonly videoType!: JobType;

  readonly thumbnail?: File;
  readonly enrichmentLevel?: JobLevel;

  readonly industry?: Industry;
  readonly metadata?: Metadata;
  /** @todo convert to string[] */
  readonly keywords?: string;
  /** @todo convert to string[] */
  readonly tags?: string;
  readonly sortOrder?: number;

  readonly posterURL?: string;
  readonly posterToken?: string;
}