import { action, computed, makeObservable, observable } from 'mobx';
import { CSSProperties } from 'react';
import { CatalogState } from './catalogState';

const ItemWidth = 240;
const GridGap = 16;

export class CarouselState {
  constructor(model: CatalogState) {
    makeObservable(this);

    this.catalog = model;
  }

  readonly catalog: CatalogState;

  @observable transformPosition: number = 0;
  @observable itemWidth: number = ItemWidth;
  @observable gridGap: number = GridGap;
  @observable gridWidth: number = 0;
  @observable windowWidth: number = 0;


  @computed
  get gridStyles(): CSSProperties {
    return {
      transition: 'transform 0.15s',
      transform: `translateX(${this.transformPosition}px)`,
      width: `${this.gridWidth}px`
    }
  }

  @computed
  get isAtCarouselEnd() {
    return this.gridWidth + this.transformPosition <= this.windowWidth - this.gridGap * 2;
  }

  @action
  updateGridWidth(numberOfEntities: number) {
    this.setGridWidth(numberOfEntities * this.itemWidth + (numberOfEntities - 1) * this.gridGap);
  }

  @action
  setGridWidth(width: number) {
    this.gridWidth = width;
  }

  @action
  setWindowWidth(width: number) {
    this.windowWidth = width;
  }

  @action
  handleNext = () => {
    if (this.catalog.syncStatus === 'fetchingMore') {
      return;
    }

    let position = this.transformPosition - this.itemWidth - this.gridGap;
    const willBeAtTheEnd = this.gridWidth + position <= this.windowWidth;

    if (willBeAtTheEnd) {

      if (!this.catalog.isEndOfList) {
        position = this.windowWidth - this.gridWidth - GridGap * 2;
        this.catalog.loadMore();
      } else {
        position = this.windowWidth - this.gridWidth - GridGap * 3;
      }

    }
    this.transformPosition = position;
  }

  @action
  handlePrev = () => {
    let position = this.transformPosition + this.itemWidth > 0 ? 0 : this.transformPosition + this.itemWidth + this.gridGap;

    if (this.isAtCarouselEnd) {
      position += 2 * this.gridGap;
    }
    this.transformPosition = position;
  }
}