import { makeObservable } from 'mobx';
import { StoreNode } from '../../store';
import { Store } from '../../store/store';
import { Cookie, StorageValue } from './storageSchema';
import {
  fromStorageValue,
  isCookieSupported,
  isLocalStorageSupported,
  setCookies,
  toStorageValue
} from './storageHelpers';
import {
  isSessionStorageSupported
} from './sessionStorageHelpers';

import { Result } from '../../core';
import { consumeSession, getSession, removeSession, setSession, setSessionTo } from './sessionStorageHelpers';

export class StorageService
  extends StoreNode {

  readonly nodeType: 'StorageService' = 'StorageService';

  constructor(store: Store) {
    super(store, {});
    makeObservable(this);
  }

  get hasLocalStorage(): boolean {
    return isLocalStorageSupported();
  }
  get hasSessionStorage(): boolean {
    return isSessionStorageSupported();
  }
  get hasCookies(): boolean {
    return isCookieSupported();
  }

  setLocal(key: string, val: StorageValue | null, encode = false) {
    if (!isLocalStorageSupported())
      return;
    localStorage.setItem(key,
      toStorageValue(val, encode));
  }

  getLocal(key: string, decode?: false): string | null;
  getLocal<T extends StorageValue = StorageValue>(key: string, decode?: true): T | null;
  getLocal<T extends StorageValue = StorageValue>(key: string, decode = false): T | null {
    if (!this.hasLocalStorage)
      return null;
    return fromStorageValue(
      localStorage.getItem(key), decode) as T;
  }
  removeLocal(key: string) {
    if (!this.hasLocalStorage)
      return null;
    return localStorage.removeItem(key);
  }

  // #region SessionStorage helpers

  setSession(key: string, val: StorageValue | null, encode = false): Result<boolean> {
    return setSession(key, val, encode);
  }

  /**
   * Writes to the session storage of the provided Window.
   * This behavior is currently at the stub level in this Service, because implementation model might change.
   */
  setSessionTo(target: Window, key: string, val: StorageValue | null, encode = false) {
    return setSessionTo(target, key, val, encode);
  }

  getSession(key: string, decode?: false): string | null;
  getSession<T extends StorageValue = StorageValue>(key: string, decode?: true): T | null;
  getSession<T extends StorageValue = StorageValue>(key: string, decode = false): T | null {
    return getSession<T>(key, decode as any);
  }

  removeSession(key: string) {
    return removeSession(key);
  }

  consumeSession(key: string, decode?: false): string | null;
  consumeSession<T extends StorageValue = StorageValue>(key: string, decode?: true): T | null;
  consumeSession<T extends StorageValue = StorageValue>(key: string, decode = false): T | null {
    return consumeSession<T>(key, decode as any);
  }
  // #endregion

  setLocalOrSession(key: string, val: StorageValue | null, encode = false) {
    if (this.hasLocalStorage)
      return this.setLocal(key, val, encode);
    if (this.hasSessionStorage)
      return this.setSession(key, val, encode);
  }

  getLocalOrSession(key: string, decode?: false): string | null;
  getLocalOrSession<T extends StorageValue = StorageValue>(key: string, decode?: true): T | null;
  getLocalOrSession<T extends StorageValue = StorageValue>(key: string, decode = false): T | null {
    if (this.hasLocalStorage)
      return this.getLocal<T>(key, decode as any);

    else if (this.hasSessionStorage)
      return this.getSession<T>(key, decode as any);

    return null;
  }

  setCookies(cookies: Cookie[], verify = false): boolean {
    return setCookies(cookies, verify);
  }
}