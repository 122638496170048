import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../../components/overlays';
import { Button } from '../../components';
import { CredentialsExpiredWindowState } from './credentialsExpiredWindowState';

type Props = {
  model: CredentialsExpiredWindowState;
  visible?: boolean;
};

export const CredentialsExpiredWindow = observer((props: Props) => {
  const { model } = props;
  const libraryLabel = model.library?.libraryLabel || '';
  const isWidgetMode = model.store.widget.isWidgetMode;

  return (
    <Window
      model={model.window}
      visible={props.visible}
      isLoading={model.isLoading}>
      <WindowHeader
        model={model.window}
        title={`Your ${libraryLabel} Session Expired`}
      />

      <hr />

      <div className="window-content">
        <p>{`To regain access to your ${libraryLabel} library please confirm your ${libraryLabel} credentials.`}</p>
      </div>

      <hr />

      <footer className="window-footer">
        <div className="btn-group right">
          {isWidgetMode ? (
            <Button
              className="green medium"
              label="Confirm credentials"
              onClick={() => {
                if (!model.library) return;

                model.store.uploadWidget.authorizeLibrary(model.library);
                model.close();
              }}
            />
          ) : (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a href={model.library?.authUrl}>
              <Button className="green medium" label="Confirm credentials" />
            </a>
          )}
        </div>
      </footer>
    </Window>
  );
});
