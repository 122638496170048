import './downloadWindow.scss';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Window, WindowHeader } from '../../components/overlays';
import classNames from 'classnames';
import { Button } from '../input/button';
import { DownloadWindowState } from './downloadWindowState';
import { RadioGroupInputControlled } from '../input/radioGroupInput';
import { FileDownloadLink } from './fileDownloadLink';

type Props = {
  model: DownloadWindowState;
  visible?: boolean;
};

export const DownloadWindow = observer(
  ({ model, visible }: Props) => {
    const className = classNames('download-window');

    const closeBtnElem = <Button
      label={model.closeLabel ?? 'Cancel'}
      className={classNames('btn-outline medium primary')}
      onClick={() => model.cancel()}
      disabled={model.isLoading}
    />

    const submitBtnElem = <Button
      label={model.confirmLabel ?? 'Download'}
      className={classNames('medium primary')}
      onClick={() => model.submit()}
      disabled={model.isLoading}
    />

    return (
      <Window
        onEntered={() => model.onWindowEntered()}
        onExited={() => model.onWindowExited()}
        model={model.window}
        isLoading={model.isLoading}
        visible={visible}
        className={className}>

        <WindowHeader
          title={model.title ?? 'Download'}
          model={model.window}
        />

        <hr />

        <section className="window-content">
          <p className="p-bold">
            {model.message ?? 'Select format'}
          </p>
          <RadioGroupInputControlled
            className="form-input format"
            model={model.format} />
        </section>

        <hr />

        <footer className="window-footer">
          <div className="col left"></div>
          <div className="col right">
            <div className="btn-group">
              {closeBtnElem}
              {submitBtnElem}
            </div>
          </div>
        </footer>

        {model.downloadUrl &&
          <FileDownloadLink
            actionUrl={model.downloadUrl}
            onClick={() => model.clearDownloadUrl()}
          />}
      </Window>
    );
  }
);
