import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import { Link } from '../layout';
import { Team } from '../../entities';
import { Routes } from '../../routes';
import { ObjectLiteral } from '../../core';
import { ICatalogSource } from '../layout/catalog';
import { IFilterableSource } from '../input';
import { ISortableSource } from '../input/sortFieldBtn';
import { TeamAvatar } from '../teams';
import { MenuButton } from '../menu';
import { MenuItem } from '../input/menuItems';

type Props = {
  model: Team;
  route?: string;
  className?: string | ObjectLiteral;
  source: ICatalogSource<Team> & ISortableSource & IFilterableSource;
};

type AdminTeamDropdownOptionsProps = {
  model: Team;
};

export const AdminTeamsTableRow = observer(
  ({
    model,
    route = Routes.teamDashboard(model.id),
    source,
    className,
  }: Props) => {
    let linkRoute: string = route;

    className = classNames('admin-teams-table-row');

    return (
      <tr className={className}>
        <td
          className={classNames('thumb-col', {
            active: source.getSortFieldValue('name') !== 'none',
          })}
        >
          <Link to={linkRoute!} className="link fill" title={model.name}>
            <TeamAvatar className="icon" model={model} />
          </Link>
        </td>

        <td
          className={classNames('name-col', {
            active: source.getSortFieldValue('name') !== 'none',
          })}
        >
          <div>
            <Link to={linkRoute!} className="link title" title={model.name}>
              <span>{model.name}</span>
            </Link>
          </div>
        </td>

        <td
          className={classNames('owner-name-col', {
            active: source.getSortFieldValue('ownerName') !== 'none',
          })}
        >
          <Link to={linkRoute!} className="link fill" title={model.ownerName}>
            <span>{model.ownerName}</span>
          </Link>
        </td>

        <td
          className={classNames('email-col', {
            active: source.getSortFieldValue('ownerEmail') !== 'none',
          })}
        >
          <Link to={linkRoute!} className="link fill" title={model.ownerEmail}>
            <span>{model.ownerEmail}</span>
          </Link>
        </td>

        <td
          className={classNames('uploads-col', {
            active: source.getSortFieldValue('uploadsCount') !== 'none',
          })}
        >
          <Link to={linkRoute!} className="link fill">
            <span>{model.uploadsCount}</span>
          </Link>
        </td>

        <td
          className={classNames('last-upload-col', {
            active: source.getSortFieldValue('lastUploadAt') !== 'none',
          })}
        >
          <Link to={linkRoute!} className="link fill">
            <span>
              {model.lastUploadAt ? (
                <>
                  {model.lastUploadDate.toLocaleString(DateTime.DATE_MED)}{' '}
                  {model.lastUploadDate.toLocaleString(DateTime.TIME_SIMPLE)}
                </>
              ) : (
                '-'
              )}
            </span>
          </Link>
        </td>

        <td
          className={classNames('members-col', {
            active: source.getSortFieldValue('membersCount') !== 'none',
          })}
        >
          <Link to={linkRoute!} className="link fill">
            <span>{model.membersCount}</span>
          </Link>
        </td>

        <td
          className={classNames('product-col', {
            active: !!source.getFilterFieldValue('teamProduct'),
          })}
        >
          <Link to={linkRoute!} className="link fill">
            <span>{model.teamProduct}</span>
          </Link>
        </td>

        <>
          <td
            className={classNames('prefix-col', {
              active: !!source.getFilterFieldValue('teamNamespace'),
            })}
          >
            <Link to={linkRoute!} className="link fill">
              <span>{model.namespace ?? 'None'}</span>
            </Link>
          </td>

          <td className="options-col">
            <MenuButton>
              <AdminTeamDropdownOptions model={model} />
            </MenuButton>
          </td>
        </>
      </tr >
    );
  }
);

const AdminTeamDropdownOptions = observer(
  (props: AdminTeamDropdownOptionsProps) => {
    const { model } = props;
    const { adminTeamsPage } = model.store;

    return (
      <>
        <MenuItem
          isSelected={false}
          model={{ value: 'Change Library Product', label: 'Change Library Product' }}
          onClick={() => adminTeamsPage.openTeamProductUpdate(model)} />

        <MenuItem
          isSelected={false}
          model={{
            value: 'Delete',
            label: 'Delete',
          }}
          onClick={() =>
            model.dispatch('openDeleteTeamWindow', {
              teamId: model.id,
            })
          } />
      </>
    );
  }
);
