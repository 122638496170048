import React from 'react';
import { observer } from 'mobx-react-lite';
import { ParentSize } from '@visx/responsive';
import { XYSeriesChart } from '../xySeriesChart';
import { BookmarkSVGIcon } from '../../svg/svg';
import { BookmarksChartState } from './bookmarksChartState';
import { ChartLegendProps } from '../utils/chartLegend';
import { ChartWrapper } from '../chartWrapper';

type Props = {
  model: BookmarksChartState;
};

export const BookmarksChart = observer(({ model }: Props) => {
  const { dataSource } = model;
  const teamId = model.params?.teamId;
  const { isFetching: showLoadMask, error } = dataSource;

  // NOTE: do not access model.outputData directly in XYSeriesChart
  // this is because XYSeriesChart is rendered within a callback to ParentSize, which I suspect is run async
  const data = model.outputData;

  const hasData = !!(data?.[0]?.data?.length > 0);
  const showNoDataMask = !hasData && !showLoadMask;

  let overlayMessage;
  if (error) {
    overlayMessage = { code: 'Error', message: 'An error has occured' };
  } else if (showNoDataMask) {
    overlayMessage = { code: 'NoData' };
  }

  const legendProps: ChartLegendProps = {
    showGlyph: true,
    showLineColor: true,
    showLabel: false,
    legendItems: [
      { dataKey: 'Bookmarks', glyph: () => <BookmarkSVGIcon enabled={true} /> },
    ],
  };

  const chartTitle = `${teamId ? 'Library' : 'My'} Engagement - Bookmarks`;
  const tooltipContent = teamId ?
    `Monitor viewers' bookmarks over time to understand the key moments in your library content viewers keep coming back to.` :
    `Monitor your viewers' bookmarks over time to understand the key moments viewers keep coming back to.`;

  return (
    <ChartWrapper
      title={chartTitle}
      tooltipContent={tooltipContent}
      showLoadMask={showLoadMask}
      overlayMessage={overlayMessage}>
      <ParentSize>{(parent) =>
        <XYSeriesChart
          height={parent.height - 32}
          width={parent.width}
          series={data}
          legend={legendProps}
          curve={'basis'}
          renderLineSeries={true} />
      }</ParentSize>
    </ChartWrapper>
  );
});
