import './wordCloudChart.scss';

import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { Word, WordCloudChartPropsBase, WordMouseEventHandler } from './wordCloudSchema';
import { WordCloudContainer } from './wordCloudContainer';
import { defaultRenderTooltip } from './wordCloudUtils';
import { UseTooltipParams } from '@visx/tooltip/lib/hooks/useTooltip';

type Props = WordCloudChartPropsBase;

let tooltipTimeout: number;

export const WordCloudChart = observer(({
  id,
  className,
  words,
  width,
  height,
  spiralType = 'archimedean',
  withRotation = false,
  font = 'Impact',
  wordPadding = 2,
  showTooltip = true,
  renderTooltip = defaultRenderTooltip
}: Props) => {

  className = classNames('chart chart-wordcloud wordcloud', className);

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip: showTooltipAction,
    hideTooltip: hideTooltipAction,
    updateTooltip: updateTooltipAction
  }: UseTooltipParams<Word> = useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  })

  const handleMouseEnter: WordMouseEventHandler = useCallback((evt: React.MouseEvent<SVGElement>, datum: Word) => {

    if (tooltipTimeout)
      clearTimeout(tooltipTimeout);

    const coords = localPoint((evt.target as SVGElement).ownerSVGElement!, evt);

    showTooltipAction({
      tooltipLeft: coords?.x,
      tooltipTop: coords?.y,
      tooltipData: {
        originalText: datum.originalText,
        value: datum.value,
      }
    });
  }, [showTooltipAction]);

  const handleMouseLeave = useCallback((evt: React.MouseEvent, datum: Word) => {
    // tooltipTimeout = window.setTimeout(() => {
    hideTooltipAction();
    // }, 300);
  }, [hideTooltipAction]);

  const handleMouseMove = useCallback((evt: React.MouseEvent, datum: Word) => {
      const coords = localPoint((evt.target as SVGElement).ownerSVGElement!, evt);

      updateTooltipAction({
        tooltipOpen: true,
        tooltipLeft: coords?.x,
        tooltipTop: coords?.y,
        tooltipData: {
          originalText: datum.originalText,
          value: datum.value,
        }
      });
  }, [updateTooltipAction]);

  return (
    <div
      id={id}
      className={className}
      ref={containerRef}>

      <WordCloudContainer
        words={words}
        width={width}
        height={height}
        spiralType={spiralType}
        withRotation={withRotation}
        font={font}
        wordPadding={wordPadding}
        onWordMouseEnter={handleMouseEnter}
        onWordMouseLeave={handleMouseLeave}
        onWordMouseMove={handleMouseMove} />

      {showTooltip && tooltipOpen && tooltipData && tooltipLeft != null && tooltipTop != null && (
        <TooltipInPortal
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          className={'wordcloud-tooltip'}>

          {renderTooltip({ tooltipData })}
        </TooltipInPortal>)}
    </div>
  )
});
