import { isDefinedObject } from '../../core/object';

/** 
 * Returns true if the argument can be interpreted as being a `google.ima.AdErrorEvent`.
 * The check is based more on observation and documentation since `AdErrorEvent` is not a class 
 * and there's no available check in the SDK.
 */
export function isGoogleImaAdErrorEvent(arg: any): arg is google.ima.AdErrorEvent {

  return (
    isDefinedObject(arg) &&
    arg.type === google.ima.AdErrorEvent.Type.AD_ERROR &&
    typeof arg.getError === 'function' &&
    typeof arg.getUserRequestContext === 'function');
}

export function isGoogleImaSdkLoaded(): boolean {
  return (
    typeof google === 'object' &&
    google !== null &&
    typeof google.ima === 'object' &&
    google.ima !== null &&
    isDefinedObject(google.ima.settings));
}

/** 
 * Safe getter for the root Google IMA SDK object.
 * If the SDK has not been loaded then `null` will be returned.
 * This method doesn't fetch the SDK itself. Use `VendorService.googleImaSdk` for that.
 */
export function getGoogleImaSdk(): typeof google.ima | null {
  if (!isGoogleImaSdkLoaded())
    return null;
  return google.ima;
}