import { computed, makeObservable } from 'mobx';
import { Store } from '../../store/store';
import { BindingProps, StoreNode } from '../../store';
import { JobModel, MomentSelector } from '..';
import { PlayerItem, PlayerItemTarget } from './playerItem';
import { Speaker, SpeakerModel } from '../speaker';
import { MomentModel, SubTopic, Topic } from '../moment';
import { Maybe, Nullable } from '../../core';
import { getTimeRegionsMergedDuration } from '../../core/time';
import { Comment, Reaction } from '../comment';
import identity from 'lodash/identity';
type Props = BindingProps<{
  jobId: string,
  selector: MomentSelector,
}>

export class PlayerItemSource
  extends StoreNode {

  constructor(store: Store, props?: Props) {
    super(store, props);
    makeObservable(this);
  }

  @computed
  get selector(): MomentSelector | null {
    return this.getResolvedProp('selector') || null;
  }

  @computed
  get jobId(): string | null {
    return this.getResolvedProp('jobId') || null;
  }
  @computed
  get job(): JobModel | null {
    return this.store.maybeGetJob(this.jobId) || null;
  }

  @computed
  get lastAddedReaction(): Nullable<PlayerItem> {
    const { job, store } = this;
    if (!job)
      return null;

    if (job.addedReaction?.id) {
      return new PlayerItem(store, {
        source: this,
        reactionId: job.addedReaction?.id
      });
    }

    return null;
  }

  @computed
  get itemLookup(): Map<string, PlayerItem> {

    const { job, store } = this;
    if (!job)
      return new Map();

    const momentEntries: [string, PlayerItem][] = job.visibleMoments
      .filter(mom => !!mom.name || !!mom.description)
      .map(mom =>
        [mom.id, new PlayerItem(store, {
          source: this,
          momentId: mom.id
        })]);

    const speakerEntries: [string, PlayerItem][] = job.speakers
      .filter(spk => !!spk.name)
      .map(spk =>
        [spk.id, new PlayerItem(store, {
          source: this,
          speakerId: spk.id
        })]);

    const commentEntries: [string, PlayerItem][] = job.comments
      .map(com =>
        [com.id, new PlayerItem(store, {
          source: this,
          commentId: com.id
        })]);

    const reactionEntries: [string, PlayerItem][] = job.reactions
      .map(rct =>
        [rct.id, new PlayerItem(store, {
          source: this,
          reactionId: rct.id
        })]);

    return new Map([
      ...momentEntries,
      ...speakerEntries,
      ...commentEntries,
      ...reactionEntries
    ]);
  }

  @computed
  get items(): PlayerItem[] {
    return [...this.itemLookup.values()];
  }
  @computed
  get topicItems(): PlayerItem<Topic>[] {
    return this.items.filter(item => item.targetType === 'Topic') as PlayerItem<Topic>[];
  }
  @computed
  get subTopicItems(): PlayerItem<SubTopic>[] {
    return this.items.filter(item => item.targetType === 'SubTopic') as PlayerItem<SubTopic>[];
  }
  @computed
  get speakerItems(): PlayerItem<Speaker>[] {
    return this.items.filter(item => item.targetType === 'Speaker') as PlayerItem<Speaker>[];
  }
  @computed
  get commentItems(): PlayerItem<Comment>[] {
    return this.items.filter(item => item.targetType === 'Comment') as PlayerItem<Comment>[];
  }
  @computed
  get reactionItems(): PlayerItem<Reaction>[] {
    return this.items.filter(item => item.targetType === 'Reaction') as PlayerItem<Reaction>[];
  }

  @computed
  get directorItems() {
    return this.items.filter(item => item.useInDirector);
  }

  @computed
  get directorMoments(): MomentModel[] {
    return this.directorItems
      .filter(item => item.targetEntityType === 'Moment' && !!item.moment)
      .map(item => item.moment!);
  }

  @computed
  get directorMomentsDuration() {
    return getTimeRegionsMergedDuration(this.directorMoments);
  }

  @computed
  get directorMomentsDurationRatio() {
    if (!this.job)
      return 0;
    return this.directorMomentsDuration / this.job.videoDuration;
  }

  @computed
  get directorMomentsSavedDuration() {
    if (!this.job)
      return 0;
    return this.job.videoDuration - this.directorMomentsDuration;
  }

  @computed
  get directorMomentsSavedDurationRatio() {
    if (!this.job)
      return 0;
    return this.directorMomentsSavedDuration / this.job.videoDuration;
  }

  @computed
  get reporterItems() {
    return this.items.filter(item => item.useInReporter);
  }

  @computed
  get playDuration() {
    if (!this.job)
      return 0;
    if (this.directorMomentsDuration === 0)
      return this.job.videoDuration;
    return this.directorMomentsDuration;
  }

  @computed
  get playDurationRatio() {
    if (!this.job)
      return 0;
    return this.playDuration / this.job.videoDuration;
  }

  @computed
  get playbarItems(): PlayerItem[] {
    return this.items
      .filter(item => item.showOnPlaybar)
      .sort((a, b) => a.playbarRenderOrder - b.playbarRenderOrder);
  }
  @computed
  get playbarTopicItems(): PlayerItem<Topic>[] {
    return this.playbarItems.filter(item => item.isTopicNode) as PlayerItem<Topic>[];
  }
  @computed
  get playbarSubTopicItems(): PlayerItem<SubTopic>[] {
    return this.playbarItems.filter(item => item.isSubTopicNode) as PlayerItem<SubTopic>[];
  }


  @computed
  get markerBarItems(): PlayerItem[] {
    return this.items
      .filter(item => item.showOnMarkerBar)
      .sort((a, b) => a.markerBarRenderOrder - b.markerBarRenderOrder)
  }

  // #region Index section

  @computed
  get indexSectionItems(): PlayerItem[] {
    return this.items
      .filter(item => item.showInIndexSection);
  }

  @computed
  get indexSectionTopicItems(): PlayerItem<Topic>[] {
    return this.indexSectionItems.filter(item => item.isTopicNode) as PlayerItem<Topic>[];
  }
  @computed
  get indexSectionTopics(): Topic[] {
    return this.indexSectionTopicItems
      .map(item => item.moment as Topic)
      .filter(identity);
  }

  @computed
  get indexSectionSubTopicItems(): PlayerItem<SubTopic>[] {
    return this.indexSectionItems.filter(item => item.isSubTopicNode) as PlayerItem<SubTopic>[];
  }
  @computed
  get indexSectionSubTopics(): SubTopic[] {
    return this.indexSectionSubTopicItems
      .map(item => item.moment as SubTopic)
      .filter(identity);
  }
  // #endregion


  getItem<T extends PlayerItemTarget>(arg: Maybe<string | T>): PlayerItem<T> | null {

    let id = null;
    if (typeof arg === 'string')
      id = arg;
    else if (arg instanceof MomentModel || arg instanceof SpeakerModel)
      id = arg.id;

    if (!id)
      return null;

    return this.itemLookup.get(id) as PlayerItem<T> || null;
  }
}