import { ExternalLibrarySource, JobStatus, JobType } from '@clipr/lib';
import { JobModel } from './job';

export const JobStatusList: JobStatus[] = [
  'InProgress',
  'Waiting',
  'InReview',
  'Updated',
  'Done',
  'Failed',
  'LiveStreaming',
  'LiveEnded',
  'LiveReady'
];

export type JobTypeItem = {
  value: JobType,
  label: string
}

export type JobFilter = (job: JobModel) => boolean;

export enum JobDomainType {
  User = 'User',
  Team = 'Team'
}

export type JobDomain = {
  type: JobDomainType;
  teamId?: string;
  library?: ExternalLibrarySource;
  isExternal?: boolean;
};

export const JobVideoTypeList: JobTypeItem[] = [
  {
    value: 'Meeting',
    label: 'Meeting'
  },
  {
    value: 'Presentation',
    label: 'Presentation'
  },
  {
    value: 'AllHandsMeeting',
    label: 'All-hands meeting',
  }
];

export const getVideoTypeLabel = (value: JobType | null): string | null => {
  return JobVideoTypeList.find(i => i.value === value)?.label || null;
}
export const getVideoTypeValue = (label: string | null): JobType | null => {
  return JobVideoTypeList.find(i => i.label === label)?.value || null;
}

export interface IJobDependency {
  id: string,
  jobId: string | null
}

export enum JobLiveStatus {
  Waiting = 'Waiting',
  Streaming = 'Streaming',
  Ended = 'Ended'
}

export enum JobLiveAggregateStatus {
  NotReady = 'NotReady',
  Waiting = 'Waiting',
  Streaming = 'Streaming',
  LiveEnded = 'LiveEnded',
  Processing = 'Processing',
  ProcessingFailed = 'ProcessingFailed',
  ProcessingDone = 'ProcessingDone'
}


export enum MediaAggregatedStatus {
  LiveStreaming = 'LiveStreaming',
  LiveWaiting = 'LiveWaiting',
  LiveEnded = 'LiveEnded',
  Failed = 'Failed',
  IngestProcessing = 'IngestProcessing',
  IngestCompleted = 'IngestCompleted'
}

export const getLiveStatusLabel = (liveStatus: JobLiveAggregateStatus | 'Status' | null) => {
  switch (liveStatus) {
    case JobLiveAggregateStatus.NotReady:
      return 'Not Ready';
    case JobLiveAggregateStatus.Waiting:
      return 'Ready';
    case JobLiveAggregateStatus.Streaming:
      return 'Live now';
    case JobLiveAggregateStatus.LiveEnded:
      return 'Live Ended';
    case JobLiveAggregateStatus.ProcessingFailed:
      return 'Failed';
    case JobLiveAggregateStatus.ProcessingDone:
      return 'Done';
    case JobLiveAggregateStatus.Processing:
      return 'In Progress';
    default:
      return null;
  }
}