import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store/storeHooks';
import { JobCardCatalog, JobTableCatalog } from '../../components/media';
import {
  Button,
  FilterFieldButton,
  SortFieldButton,
} from '../../components/input';
import Routes from '../../routes';
import { JobVideoTypeList } from '../../entities';
import { UploadSVGIcon } from '../../components';
import { MenuItem } from '../../components/input/menuItems';
import { JobTableCatalogHeader } from '../../components/media/jobTableCatalogHeader';

export const MyUploads = observer(() => {
  const store = useStore();
  const state = store.userDashboardPage;
  const source = state.jobCatalogSource;
  const showLoadMask = state.isLoading;
  const { user } = store;

  return (
    <>
      <section className="page-subheader">
        {state.jobCatalog.viewType === 'grid' ? (
          <>
            <div className="flex-left">
              <div className="page-filters">
                <span className="filter-label">Filter by:</span>
                <FilterFieldButton
                  label={source.getVideoTypeFilterLabel() || 'Type'}
                  selected={!!source.getVideoTypeFilterValue()}>
                  <MenuItem
                    model={{ value: 'All', label: 'All' }}
                    onClick={() => source.setVideoTypeFilterValue(null)}
                    isSelected={!source.getVideoTypeFilterValue()}
                  />
                  {JobVideoTypeList.map((type) => (
                    <MenuItem
                      key={type.value}
                      model={type}
                      onClick={() => source.setVideoTypeFilterValue(type.value)}
                      isSelected={source.getVideoTypeFilterValue() === type.value}
                    />
                  ))}
                </FilterFieldButton>

                <div className="divider"></div>

                <span className="filter-label">Sort by:</span>

                <SortFieldButton label="Title" name="title" source={source} />

                <SortFieldButton
                  label="Upload Date"
                  name="createdAt"
                  source={source}
                />

                <SortFieldButton
                  label="Length"
                  name="durationInMs"
                  source={source}
                />
              </div>
            </div>

            <div className="user-upload flex-right">
              {user?.hasPermission('UserUpload') && (
                <Button
                  route={Routes.upload()}
                  className="upload-btn secondary medium"
                  icon={<UploadSVGIcon />}
                  label="Upload Video"
                />
              )}
            </div>
          </>
        ) : (
          <JobTableCatalogHeader
            model={state.jobCatalog}
            source={state.jobCatalogSource}
          />
        )}
      </section>

      {!showLoadMask &&
        (state.jobCatalog.viewType === 'grid' ? (
          <JobCardCatalog
            model={state.jobCatalog}
            source={state.jobCatalogSource}
          />
        ) : (
          <JobTableCatalog
            model={state.jobCatalog}
            source={state.jobCatalogSource}
          />
        ))}
    </>
  );
});
