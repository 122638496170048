/** @deprecated Use `| null` instead. */
export type Nullable<T> = T | null;

export type Maybe<T> = T | null | undefined;
export type MaybePartial<T> = Maybe<Partial<T>>;

export type MaybeProps<T> = {
  [key in keyof T]?: Maybe<T[key]>
};

export type NullableProps<T> = {
  [key in keyof T]?: T[key] | null;
};

export type RequiredProps<T> = {
  [P in keyof Required<T>]: NonNullable<T[P]>;
}

export type Enum = Record<string, string | number>;

/** Generic type for expressing a tuple that can be returned from an operation which can also return an error. */
export type Result<TResult = any, TError = any> =
  [TResult] |
  [TResult, null] |
  [null, TError];

export type Key = string | number | symbol;

export type Constructor<T = {}> = new (...args: any[]) => T;

export function certifyType<T>(arg: any): asserts arg is T { }

export function isNullOrUndefined(arg: any): arg is null | undefined {
  return arg === null || arg === undefined;
}

export function isDefined<T>(arg: any): arg is Exclude<T, null | undefined> {
  return !isNullOrUndefined(arg);
}